import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import route, { ROUTE_DASHBOARD } from "../route";

const Profile = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "g360-Profile" */ "pages/dashboardPages/g360/Profile")
  )
);
const Account = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "g360-Account" */ "pages/dashboardPages/g360/Account")
  )
);
const Campaign = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "g360-Campaign" */ "pages/dashboardPages/g360/Campaign")
  )
);
const Adgroup = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "g360-Adgroup" */ "pages/dashboardPages/g360/Adgroup")
  )
);
const Customer = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "g360-Customer" */ "pages/dashboardPages/g360/Customer")
  )
);
const Ad = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "g360-Ad" */ "pages/dashboardPages/g360/Ad"))
);
const Report = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "g360-Report" */ "pages/dashboardPages/g360/Report")
  )
);

const g360Routes = [
  route("/g360/profile", Profile, ROUTE_DASHBOARD, false),
  route("/g360/account", Account, ROUTE_DASHBOARD, false),
  route("/g360/campaign", Campaign, ROUTE_DASHBOARD, false),
  route("/g360/adgroup", Adgroup, ROUTE_DASHBOARD, false),
  route("/g360/customer", Customer, ROUTE_DASHBOARD, false),
  route("/g360/ad", Ad, ROUTE_DASHBOARD, false),
  route("/g360/report", Report, ROUTE_DASHBOARD, false),
];

export default g360Routes;
