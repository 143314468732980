import { Popover } from "antd";
import ActionIconRender from "components/ActionIconRender";

export default function LookTableColumnUserListShow(props) {
  const { userList = [], icon: Icon, text = "", IconRender = true } = props;

  const userListRender = () => (
    <div className="userListRender">
      {userList?.map(({ name, title }) => (
        <p className="mb-0">
          {Icon ? <Icon className="mr-8" /> : <ActionIconRender className="mr-8" iconType="user" />}
          {name || title}
        </p>
      ))}
    </div>
  );

  return (
    <div className="flex-between">
      {userList && userList.length > 0 ? (
        IconRender ? (
          <>
            {userList.length} {text}
            <Popover content={userListRender} trigger="click" placement="bottom">
              {Icon ? <Icon /> : <ActionIconRender iconType="user" />}
            </Popover>
          </>
        ) : (
          <Popover
            content={userListRender}
            trigger="hover"
            placement="bottom"
            overlayStyle={{ pointerEvents: "none" }}
          >
            {userList.length} {text}
          </Popover>
        )
      ) : (
        0
      )}
    </div>
  );
}
