import LookTable from "components/LookTable";
import PopoverWrapper from "components/popoverWrapper";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useEffect, useState } from "react";
import { EllipsisOutlined, FacebookFilled, TeamOutlined } from "@ant-design/icons";
import { Form, Input, Radio } from "antd";
const { Button, Group } = Radio;

export default function LaunchCampaignCreation() {
  const renderData = [
    {
      id: 1,
      name: "Priyansh",
      objective: 1,
      special_ad: 1,
      adset: "Adset",
      adset2: "T(2)",
      creatives: "Creatives",
      cType: 0,
      budget: "Budget",
      bType: 0,
      bidStrategy: 1,
    },
    {
      id: 2,
      name: "Razat",
      objective: 1,
      special_ad: 2,
      adset: "Adset",
      adset2: "C(2)",
      creatives: "Creatives",
      cType: 0,
      budget: "Budget",
      bType: 0,
      bidStrategy: 3,
    },
    {
      id: 3,
      name: "Robert",
      objective: 1,
      special_ad: 2,
      adset: "Adset",
      adset2: "T(2)",
      creatives: "Creatives",
      cType: 0,
      budget: "Budget",
      bType: 0,
      bidStrategy: 3,
    },
    {
      id: 4,
      name: "Tony",
      objective: 1,
      special_ad: 2,
      adset: "Adset",
      adset2: "T(2)",
      creatives: "Creatives",
      cType: 0,
      budget: "Budget",
      bType: 0,
      bidStrategy: 3,
    },
    {
      id: 5,
      name: "Strange",
      objective: 1,
      special_ad: 2,
      adset: "Adset",
      adset2: "T(2)",
      creatives: "Creatives",
      cType: 0,
      budget: "Budget",
      bType: 0,
      bidStrategy: 3,
    },
  ];

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [dataSource, setDataSource] = useState(renderData);

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };

  const initialColumns = [
    {
      title: "S. No.",
      dataIndex: "id",
      key: "id",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 40,
      minWidth: 40,
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      type: "text",
      editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Ojective",
      dataIndex: "objective",
      key: "objective",
      type: "select",
      editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Special Ad",
      dataIndex: "special_ad",
      key: "special_ad",
      type: "select",
      editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Ad set",
      dataIndex: "adset",
      key: "adset",
      type: "text",
      editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "C-type",
      dataIndex: "cType",
      key: "cType",
      type: "switch",
      editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (value, actionProps, otherProps) => (
        <LookTableColumnRender
          title={
            <Form.Item
              style={{
                margin: 0,
              }}
              name="budget"
            >
              <div className="launch-budget-wrapper d-flex">
                <Input placeholder="Enter budget (USD)" className="editable-input" />
                {actionProps.cType === 1 && (
                  <>
                    <div className="budget-extra-inputs">
                      <input className="editable-input" placeholder="Min" />
                      <input className="editable-input" placeholder="Max" />
                    </div>
                  </>
                )}
              </div>
            </Form.Item>
          }
        />
      ),
      width: 180,
      minWidth: 180,
    },
    {
      title: "B-type",
      dataIndex: "bType",
      key: "bType",
      type: "switch",
      editable: true,
      width: 140,
      minWidth: 140,
    },
    {
      title: "Bid startegy",
      dataIndex: "bidStrategy",
      key: "bidStrategy",
      type: "select",
      editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Ad set",
      dataIndex: "adset2",
      key: "adset2",
      type: "text",
      editable: true,
      width: 104,
      minWidth: 104,
    },
    {
      title: "Creatives",
      dataIndex: "creatives",
      key: "creatives",
      type: "text",
      editable: true,
      width: 104,
      minWidth: 104,
    },
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        const poverWrapperOptions = [
          {
            option: "edit",
            title: "Edit",
            optionProps: {
              onClick: () => {
                // showDrawerUpdate(actionProps);
                console.log("Here", actionProps);
              },
            },
          },
          {
            option: "delete",
            title: "Delete",
            optionProps: {
              // onClick: () => onOpenDeleteModal([actionProps]),
            },
          },
        ];
        return (
          <span className="d-table pointer">
            <PopoverWrapper options={poverWrapperOptions}>
              <EllipsisOutlined className="pop-info" />
            </PopoverWrapper>
          </span>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const [tempColumns, setTempColumns] = useState(initialColumns);

  useEffect(() => {
    if (dataSource.some((item) => item.bType === 1)) {
      setTempColumns(initialColumns.map((item) => item.dataIndex === "adset2"));
    }
  }, [tempColumns]);

  // useEffect(() => {
  //   let isHidden = dataSource.some(item => item.bType === 1)
  //   if(isHidden) {
  //     setTempColumns([...tempColumns, {
  //       title: "Bid startegy",
  //       dataIndex: "bidStrategy",
  //       key: "bidStrategy",
  //       type: "select",
  //       editable: true,
  //       width: 124,
  //       minWidth: 124,
  //       hidden: true
  //     },])
  //   }
  // }, [tempColumns])

  console.log({ dataSource, tempColumns });

  const tableProps = {
    initialColumns: tempColumns,
    recordData: dataSource,
  };

  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => onOpenDeleteModal(e),
    },
  ];

  return (
    <div>
      <LookTable
        isEditable
        tableProps={tableProps}
        dataSource={dataSource}
        setDataSource={setDataSource}
        scrollHeight="calc(100vh - 211px)"
        isRowSelection
        bulkActions={bulkActions}
        contextData={{
          editSelectFieldData: {
            objective: [
              { id: 1, name: "Objective 1" },
              { id: 2, name: "Objective 2" },
              { id: 3, name: "Objective 3" },
            ],
            special_ad: [
              { id: 1, name: "No" },
              { id: 2, name: "Yes" },
            ],
            adset: [
              { id: 1, name: "Adset 1" },
              { id: 2, name: "Adset 2" },
              { id: 3, name: "Adset 3" },
            ],
            budget: [
              { id: 1, name: "Budget 1" },
              { id: 2, name: "Budget 2" },
              { id: 3, name: "Budget 3" },
            ],
            bidStrategy: [
              { id: 1, name: "bidStrategy 1" },
              { id: 2, name: "bidStrategy 2" },
              { id: 3, name: "bidStrategy 3" },
            ],
          },

          switchFieldData: {
            cType: [
              { label: "ABO", value: 0 },
              { label: "CBO", value: 1 },
            ],
            bType: [
              { label: "Daily", value: 0 },
              { label: "Lifetime", value: 1 },
            ],
          },
        }}
      />
    </div>
  );
}
