import { USER_SETTING_DEBUG_MODE } from "actions/actionTypes";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";

export default function DebugModeSelection() {
  const dispatch = useDispatch();
  const { debugMode = false } = useSelector((state) => state?.userSettings) || {};

  function handleMode(e) {
    dispatch({ type: USER_SETTING_DEBUG_MODE, payload: e });
  }

  return (
    <div>
      Debug Mode <Switch size="small" checked={debugMode} onChange={handleMode} />
    </div>
  );
}
