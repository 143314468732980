import { Link, useLocation, useRouteMatch } from "react-router-dom";
import cx from "classnames";

export default function LookLinksTabs(props) {
  const location = useLocation();
  const routeMatch = useRouteMatch();
  const { linkTabs, basePath = "" } = props;
  return (
    <div className="look-tabs left-bar">
      {linkTabs.map(({ icon: Icon, title, link = "/" }, index) => {
        const baseLink = basePath ? basePath : routeMatch.path;
        return (
          <Link
            key={"tabContentBtn" + index}
            to={baseLink + link}
            className={cx("ant-btn", {
              active:
                "/" + location.pathname.split("/")[location.pathname.split("/").length - 1] ===
                link,
            })}
          >
            {Icon && <Icon />}
            <span>{title}</span>
          </Link>
        );
      })}
    </div>
  );
}
