import {
  TeamOutlined,
  ToolOutlined,
  SettingOutlined,
  ApartmentOutlined,
  PullRequestOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";
import { usePermissionCheck } from "hooks";
import {
  USERS_MANAGES_ROLES,
  USERS_MANAGES_AGENCIES,
  USERS_MANAGES_USERS,
  USERS_MANAGES_WORKSPACES,
  USERS_MANAGES_PRODUCTS,
  UMS_SUPER_ADMIN,
  UMS_AGENCY_ADMIN,
  USER_WITH_NO_ACTION,
} from "../../UserPermission/userPermissionList";

export default function useUmsPath() {
  const { havePermission: isViewOnly } = usePermissionCheck(USER_WITH_NO_ACTION);

  const umsPath = [
    {
      name: "UMS",
      link: "/ums",
      icon: TeamOutlined,
      subMenu: [
        // {
        //   subMenuName: "Dashboard",
        //   subMenuLink: "/main",
        //   subMenuIcon: UserOutlined,
        //   permission: UMS_AGENCY_ADMIN,
        // },
        {
          subMenuName: "Agency",
          subMenuLink: "/agency",
          subMenuIcon: SettingOutlined,
          permission: UMS_AGENCY_ADMIN,
        },
        {
          subMenuName: "Agencies",
          subMenuLink: "/agencies",
          subMenuIcon: (e) => <ActionIconRender iconType="appstore" {...e} />,
          permission: UMS_SUPER_ADMIN,
        },
        {
          subMenuName: "Workspaces",
          subMenuLink: "/workspaces",
          subMenuIcon: ApartmentOutlined,
          permission: UMS_AGENCY_ADMIN,
        },
        {
          subMenuName: "Teams",
          subMenuLink: "/teams",
          subMenuIcon: TeamOutlined,
          permission: [UMS_AGENCY_ADMIN, USERS_MANAGES_WORKSPACES],
        },
        {
          subMenuName: "Users",
          subMenuLink: "/users",
          subMenuIcon: UserOutlined,
          permission: UMS_AGENCY_ADMIN,
        },
        {
          subMenuName: "Products",
          subMenuLink: "/products",
          subMenuIcon: UserOutlined,
          permission: UMS_SUPER_ADMIN,
        },

        // {
        //   subMenuName: "Assets",
        //   subMenuIcon: () => <ActionIconRender iconType="team" />,
        //   permission: UMS_AGENCY_ADMIN,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Ad Accounts",
        //       subMenuLink: "/assets/ad-accounts",
        //       subMenuIcon: () => <ActionIconRender iconType="team" />,
        //       permission: UMS_AGENCY_ADMIN,
        //     },
        //   ],
        // },
        {
          subMenuName: "Workspace Settings",
          subMenuLink: "/workspace-settings",
          subMenuIcon: SettingOutlined,
          permission: UMS_AGENCY_ADMIN,
        },
        {
          subMenuName: "Role and Permissions",
          subMenuLink: "/role-permissions",
          subMenuIcon: SettingOutlined,
          permission: UMS_AGENCY_ADMIN,
        },
      ],
    },
    {
      name: "Configuration",
      link: "/configuration",
      icon: ToolOutlined,
      subMenu: [
        { subMenuName: "Profile", subMenuLink: "/profile", subMenuIcon: UserOutlined },
        {
          subMenuName: "Agency",
          subMenuLink: "/agency",
          subMenuIcon: SettingOutlined,
          permission: USERS_MANAGES_AGENCIES,
        },
        {
          subMenuName: "Agencies",
          subMenuLink: "/agencies",
          subMenuIcon: (e) => <ActionIconRender iconType="appstore" {...e} />,
          permission: USERS_MANAGES_AGENCIES,
        },
        {
          subMenuName: "User Management",
          subMenuLink: "/user-management",
          subMenuIcon: UserOutlined,
          permission: [USERS_MANAGES_USERS, USERS_MANAGES_WORKSPACES],
        },
        {
          subMenuName: "Workspace Manage",
          subMenuLink: "/workspace-manage",
          subMenuIcon: UserOutlined,
          permission: USERS_MANAGES_WORKSPACES,
        },
        // UNCOMMENT
        {
          subMenuName: "Roles & Permissions",
          subMenuLink: "/roles",
          subMenuIcon: PullRequestOutlined,
          permission: USERS_MANAGES_ROLES,
        },
        {
          subMenuName: "Products",
          subMenuLink: "/products",
          subMenuIcon: PullRequestOutlined,
          permission: USERS_MANAGES_PRODUCTS,
        },
        {
          subMenuName: "Teams",
          subMenuLink: "/teams",
          subMenuIcon: (e) => <ActionIconRender iconType="usergroup-add" {...e} />,
          isVisible: !isViewOnly,
        },
        // {
        //   subMenuName: "Launch Proxy",
        //   subMenuLink: "/launch-proxy",
        //   subMenuIcon: PullRequestOutlined,
        //   isVisible: !isViewOnly,
        // },
        // {
        //   subMenuName: "Permissions",
        //   subMenuLink: "/permissions",
        //   subMenuIcon: SafetyOutlined,
        // },
        // {
        //   subMenuName: "Billing",
        //   subMenuLink: "/billing",
        //   subMenuIcon: PicRightOutlined,
        // },
        // {
        //   subMenuName: "Security",
        //   subMenuLink: "/security",
        //   subMenuIcon: SettingOutlined,
        // },
        // {
        //   subMenuName: "Products",
        //   subMenuLink: "/products",
        //   subMenuIcon: SettingOutlined,
        // },
        // {
        //   subMenuName: "Notifications",
        //   subMenuLink: "/notifications",
        //   subMenuIcon: SettingOutlined,
        // },
        // {
        //   subMenuName: "Audit Logs",
        //   subMenuLink: "/audit-logs",
        //   subMenuIcon: SettingOutlined,
        // },
        // {
        //   subMenuName: "Integrations",
        //   subMenuLink: "/integrations",
        //   subMenuIcon: SettingOutlined,
        // },
        // {
        //   subMenuName: "Settings",
        //   subMenuLink: "/settings",
        //   subMenuIcon: SettingOutlined,
        // },
        // {
        //   subMenuName: "Services",
        //   subMenuLink: "/services",
        //   subMenuIcon: ApartmentOutlined,
        // },
        {
          subMenuName: "Generate Invite Code",
          subMenuLink: "/generate-invite-code",
          subMenuIcon: ApartmentOutlined,
          permission: USERS_MANAGES_USERS,
        },
      ].filter(({ isVisible = true }) => isVisible),
    },
  ];

  return { umsPath };
}
