import { Col, Row, Form, Card } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import React from "react";

function MultipleBenchmarkCard(props) {
  const {
    selectedCreateBenchmark = {},
    sasPlatformsByUser = [],
    data = "",
    sasCountries_loading = false,
    sasCountries = [],
    index,
    setSelectedCreateBenchmark = () => {},
    isUpdate = false,
  } = props;

  const handleInput = (name, value) => {
    setSelectedCreateBenchmark((pre) => {
      let temp = JSON.parse(JSON.stringify(pre));
      temp[index] = { ...temp[index], [name]: value };
      return temp;
    });
  };

  const findProductName = sasPlatformsByUser?.find((item) => item?.id === data?.platformId);

  return (
    <Card
      className="mb-12"
      style={{
        margin: isUpdate ? "10px 190px 10px 190px" : "0",
        borderRadius: "9px",
        background: "#141414",
      }}
    >
      <Row gutter={12}>
        <Col span={24}>
          <p>Values for the {findProductName?.name}</p>
        </Col>
        <Col span={24}>
          <Form.Item label="Country" className="look-form-input">
            <SelectInput
              className="mb-0"
              search
              placeholder="Select Country"
              name="countryId"
              loader={sasCountries_loading}
              value={selectedCreateBenchmark?.countryId}
              options={sasCountries?.map(({ id, name }) => ({
                name: name,
                value: id,
              }))}
              onChange={(e) => handleInput("countryId", e)}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="RSoC Lpctr" className="look-form-input">
            <LookTextInput
              number
              name="rsocLpctr"
              className="look-form-input mb-0"
              placeholder="RSoC Lpctr"
              value={selectedCreateBenchmark?.rsocLpctr}
              onChange={(e) => handleInput("rsocLpctr", parseFloat(e ? e : 0))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="RSoC Rpl" className="look-form-input">
            <LookTextInput
              number
              name="rsocRpl"
              className="look-form-input mb-0"
              placeholder="RSoC Rpl"
              value={selectedCreateBenchmark?.rsocRpl}
              onChange={(e) => handleInput("rsocRpl", parseFloat(e ? e : 0))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="AFD Lpctr" className="look-form-input">
            <LookTextInput
              number
              name="afdLpctr"
              className="look-form-input mb-0"
              placeholder="AFD Lpctr"
              value={selectedCreateBenchmark?.afdLpctr}
              onChange={(e) => handleInput("afdLpctr", parseFloat(e ? e : 0))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="AFD Rpl" className="look-form-input">
            <LookTextInput
              number
              name="afdRpl"
              className="look-form-input mb-0"
              placeholder="AFD Rpl"
              value={selectedCreateBenchmark?.afdRpl}
              onChange={(e) => handleInput("afdRpl", parseFloat(e ? e : 0))}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}

export default MultipleBenchmarkCard;
