import { Button, Checkbox, Col, Form, Input, Radio, Row, Segmented, Select, Space } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookTextInput from "components/forms/LookTextInput";
import { useLaunchContext } from "components/Launch/Context";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import LookDrawer from "components/LookDrawer";
import LookPagination from "components/LookPagination";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { PlusOutlined } from "@ant-design/icons";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import cx from "cx";
import { Formik } from "formik";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { CreativeSelectionKey_Body } from "pages/dashboardPages/VS/VSContent/CreativesDrawer/AdGroup";
import { useEffect, useRef, useState } from "react";
import {
  CT_ADD_BODY,
  CT_BODIES_BY_CATEGORY,
  GQL_ADD_FEATURED_BODIES,
  GQL_CT_DELETE_BODY,
} from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";

import * as yup from "yup";
import useAiHooks from "../hooks/useAiHooks";
import { useSelector } from "react-redux";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import LookModal, { useLookModal } from "components/LookModal";
import TagModal from "../CreativeUpload/TagModal";
import { FEATURED, MY_BODIES } from "pages/dashboardPages/Creatives/creativesEnum";
import {
  GQL_API_RESPONSE_STATUS_FAILURE,
  GQL_API_RESPONSE_STATUS_SUCCESS,
  GQL_API_RESPONSE_STATUS_WARNING,
} from "shared/SharedKeys";
import { RECORD_UPDATED } from "shared/enum/apiResponse";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import ForceDeleteModal from "../ForceDeleteModal";

function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    btnText = "Add",
    loading = false,
    label = "",
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="body"
                label={label}
                type="textarea"
                className="look-form-input"
                placeholder={"Enter the " + label?.toLowerCase()}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default function Body(props) {
  const {
    id,
    title = "",
    onClose = () => {},
    persistKey,
    groupSelection = false,
    selectedCreatives,
    handleSelectedCreatives,
    setSelectedData,
    selectedData,
    launch = false,
    campaignIndex,
    adSetindex,
    productId,
    categoryId,
    categoryName,
    loading_bodyAIData,
    bodyAIData,
    isTemp,
    error_bodyAIData,
    findCommonDynamicAds = () => {},
  } = props;
  const { handleAdgroup, campaignObject } = useLaunchContext();
  const category_Id = categoryId;
  const [searchMode, setSearchMode] = useState("normal");
  const label = "Body";
  const { Search } = Input;
  const [tags, setTags] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [visibleForceDeleteModal, setVisibleForceDeleteModal] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [showAddBody, setShowAddBody] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRange } = userSettings || {};
  const [searchItem, setSearchItem] = useState("");
  const [createdbyMe, setCreatedByMe] = useState("All");
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const onChange = (checked) => {
    setCreatedByMe(checked);
  };
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const queryVars = {
    productId,
    categoryId: category_Id,
    createdByMe: createdbyMe === MY_BODIES,
    featuredBodies: createdbyMe === FEATURED,
    page: currentPage,
    size: pageSize,
    // FOR FUTURE USE
    // fromDate: dateRange?.rangeDates[0],
    // toDate: dateRange?.rangeDates[1],
  };
  const variables = {
    productId,
    categoryId: category_Id,
    categoryName,
  };

  const [fetchBody, { data, loading: loading_fetch, refetch }] = useCustomLazyQuery(
    CT_BODIES_BY_CATEGORY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...queryVars,
      },
      onCompleted: (e) => {
        const element = e?.ctGetCreativeBodies;
        if (element?.status === "SUCCESS") {
          setRecordData(element?.data);
          setTotalCount(e?.ctGetCreativeBodies?.pageInfo?.totalRecords);
        } else {
          openNotification({ type: "error", message: element?.message, key: "body-creatives" });
        }
      },
      onError(e) {
        openNotification({
          type: "error",
          message: "Something is wrong, Unable to fetch bodies",
          key: "body-creatives",
        });
      },
    }
  );
  useEffect(() => {
    fetchBody();
  }, []);

  const [ctAddBody, { loading: loading_update }] = useCustomMutation(CT_ADD_BODY, {
    variables: { productId },
    onCompleted: (e) => {
      const element = e?.ctAddCreativeBodies;
      const { status, data, message } = element;
      if (status === "SUCCESS") {
        const { addedCreatives, alreadyPresent } = data?.[0] || {};
        setShowAddBody(false);
        resetFormRef.current();
        let messages = message;
        if (alreadyPresent?.length > 0) {
          // const finalData = { body: alreadyPresent[0] };
          refetch();
          messages = `Creatives ${alreadyPresent[0]?.body} is already present`;
          openNotification({ type: "error", message: messages });
        } else {
          // const finalData = { body: addedCreatives?.[0] };
          refetch();
          openNotification({ type: "success", message: messages });
        }
      } else {
        openNotification({ type: "error", message, key: "desc-creatives" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: "Failed to add titles" });
    },
  });

  const [selectedBody, setSelectedBody] = useState([]);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => onOpenDeleteModal(e),
    },
    {
      option: "important",
      title: "Mark Favourite",
      onClick: (e) =>
        ctAddFeatured({
          variables: { bodies: e, productId, categoryId, categoryName },
        }),
    },
  ];

  const onSubmitDeleteModal = () => {
    deleteCall({
      variables: {
        bodyIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: false,
      },
    });
  };
  const onSubmitForceDeleteModal = () => {
    deleteCall({
      variables: {
        bodyIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: true,
      },
    });
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };
  const onCloseForceDeleteModal = () => {
    setDeleteModalPropsData([]);
    setVisibleForceDeleteModal(false);
  };
  const [ctAddFeatured, { loading: loading_featured }] = useCustomMutation(
    GQL_ADD_FEATURED_BODIES,
    {
      onCompleted: (e) => {
        const element = e?.ctAddFeaturedCreativeBodies;
        if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
          openNotification({
            type: "success",
            message: element?.message || RECORD_UPDATED,
            key: "success",
          });
          refetch();
          closeTagModal();
          setTags([]);
        } else if (element?.status === GQL_API_RESPONSE_STATUS_FAILURE) {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const resetFormRef = useRef(() => {});
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;

    ctAddBody({
      variables: { ...variables, bodies: values?.body },
    });
  }
  const initialValues = { body: "" };
  const validationSchema = yup.object({
    body: yup.string().required().label(label),
  });
  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    onClose: () => {
      setShowAddBody(false);
      setSelectedBody([]);
    },
    label,
  };
  const tagDataExtractor = (id) => {
    const tagsArray = recordData?.filter((data) => data?.body?.id === id)?.[0]?.body?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };
  const initialColumns = [
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
  ];

  const renderData = [];

  recordData?.forEach((element, index) => {
    renderData.push({ ...element, key: index });
  });

  const tableMainProps = {
    refetch,
    loading: loading_fetch,
    persistKey,
    tableProps: { initialColumns, recordData: renderData },
    filterProps: { persistSearch: false },
  };
  const debugContent = [
    { isRow: true, contentObjects: [{ recordData }, { data }, { renderData }] },
  ];

  const [deleteCall, { loading: deleteLoader }] = useCustomMutation(GQL_CT_DELETE_BODY, {
    onCompleted(e) {
      const element = e?.ctDeleteCreativeBodies;
      setDeleteModalVisible(false);
      if (element?.status === "SUCCESS") {
        refetch();
        openNotification({ type: "success", message: element?.message });
        setSelectedBody([]);
      } else if (element?.status === GQL_API_RESPONSE_STATUS_WARNING && isUmsAgencyAdmin) {
        setVisibleForceDeleteModal(true);
      } else {
        openNotification({
          type: "error",
          message: element?.message,
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Failed to delete bodies",
      });
    },
  });

  function selectedRowsClear() {
    setSelectedBody([]);
    setTags([]);
  }

  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });
  const bulkActionProps = {
    selectedRows: selectedBody,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const deleteModalProps = {
    title: "Delete Creatives",
    message: "Do you really want to delete this creatives?",
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };
  const forceDeleteModalProps = {
    title: "Delete Creatives",
    visible: visibleForceDeleteModal,
    onSubmit: onSubmitForceDeleteModal,
    onCancel: onCloseForceDeleteModal,
    okokText: "Delete",
    loader: deleteLoader,
  };
  const [visibleBodyDrawer, setVisibleBodyDrawer] = useState(false);
  const onCloseBodyDrawer = () => {
    setVisibleBodyDrawer(false);
  };
  const drawerAdsBody = {
    title: "Add Body",
    icon: () => <ActionIconRender className="mr-10" iconType="font-colors" />,
    size: 1200,
    visible: visibleBodyDrawer,
    onClose: onCloseBodyDrawer,
  };
  function callApiforAi(selectedData) {
    ctAddBody({
      variables: { ...variables, bodies: selectedData },
    });
    onCloseBodyDrawer();
  }
  const hooksProps = {
    loading_AIData: loading_bodyAIData,
    creativesAIData: bodyAIData,
    setSelectedData: setSelectedBody,
    selectedData: selectedBody,
    creativeSelectionKey: CreativeSelectionKey_Body,
    loading: loading_fetch,
    onClose,
    addData: callApiforAi,
    label: "body",
  };
  const aiGeneratedDrawer = useAiHooks({ ...hooksProps });
  return (
    <div className={!groupSelection && !launch ? cx("p-20") : ""}>
      {visilbleTagModal &&
        tagModal(
          <TagModal
            variables={{
              productId,
              categoryId,
              categoryName,
            }}
            files={deleteModalPropsData}
            creatives_refetch={refetch}
            setTags={setTags}
            setTagsData={setTagsData}
            tags={tags}
            tagsData={tagsData}
            closeTagModal={closeTagModal}
            selectedRowsClear={selectedRowsClear}
            creativeType="BODY"
          />
        )}
      {visibleForceDeleteModal && (
        <LookModal {...forceDeleteModalProps}>
          <ForceDeleteModal />
        </LookModal>
      )}
      <div className={!groupSelection ? cx("d-flex  justify-content-between mt-10") : "mt-20"}>
        <div className={`d-flex creative-table-search ${groupSelection ? "mb-20" : ""}`}>
          <div
            className={cx("look-table-search", "d-flex")}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #404040",
            }}
          >
            {searchMode === "tags" ? (
              <Select
                mode={"tags"}
                allowClear={false}
                bordered={false}
                placeholder={"Search by tags"}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setSearchItem(e);
                }}
                maxTagCount="responsive"
                showArrow={false}
              />
            ) : (
              <Search
                className="creative-tag-search"
                placeholder={`Search by body`}
                suffix={null}
                onSearch={(e) => {
                  fetchBody({
                    variables: {
                      search: e,
                    },
                  });
                }}
                onChange={(e) => {
                  setSearchItem(e?.target?.value);
                }}
                style={{
                  width: "100%",
                }}
              />
            )}
            {/* add tags icon */}
            <LookButton
              tooltipTitle={
                searchMode === "tags"
                  ? "Click here to switch to normal mode"
                  : "Click here to search"
              }
              className="filter-btn ml-8"
              iconType={searchMode === "tags" ? "close" : "tagSwitch"}
              onClick={() => {
                setSearchMode((prevMode) => (prevMode === "tags" ? "normal" : "tags"));
              }}
            />
            <LookButton
              tooltipTitle="Click here to search"
              className="filter-btn"
              iconType="search"
              onClick={() => {
                fetchBody({
                  variables: {
                    ...(searchMode !== "tags" ? { search: searchItem } : { tags: searchItem }),
                  },
                });
              }}
            />
          </div>
          <Segmented
            className="custom-segmented ml-8"
            options={["All", MY_BODIES, FEATURED]}
            value={createdbyMe}
            onChange={onChange}
          />
        </div>
        <div className="d-flex">
          {/* <DateRangePersist isTimezone={false} /> */}

          <LookButton
            // tooltipTitle="Take snapshots"
            iconType="image"
            className="ml-12"
            onClick={() => {
              setVisibleBodyDrawer(true);
            }}
          >
            AI Generated
          </LookButton>
        </div>
      </div>

      <div className={cx("creative-text-list-view", { launch, groupSelection })}>
        <>
          <LookDrawer {...drawerAdsBody}>{visibleBodyDrawer && aiGeneratedDrawer}</LookDrawer>
          {(!launch || !groupSelection) && (
            <>
              <LookTableBulkAction {...bulkActionProps} />
              <LookTableRecordDeleteModal {...deleteModalProps} />
            </>
          )}
          {
            <div className="mt-20">
              {showAddBody ? (
                <MainForm {...formProps} />
              ) : (
                <div
                  className={cx("look-radio-ad", "d-flex")}
                  style={{ alignItems: "center", color: "#696969" }}
                  onClick={() => setShowAddBody(true)}
                >
                  <div className="mr-10">
                    <PlusOutlined />
                  </div>
                  <div>Click here to add a new body...</div>
                </div>
              )}
            </div>
          }

          <Checkbox.Group
            onChange={(e) => {
              if (launch && !groupSelection) {
                const args = !isTemp ? [campaignIndex, adSetindex] : [];
                handleAdgroup({ body: e }, ...args);
              } else if (groupSelection) {
                handleSelectedCreatives(CreativeSelectionKey_Body, e);
                setSelectedData({
                  ...selectedData,
                  body: [
                    ...(selectedData?.body || []),
                    {
                      id: e,
                      body: renderData?.find((i) => i.body?.id === e)?.body,
                    },
                  ],
                });
              } else {
                setSelectedBody(e);
              }
            }}
            value={
              groupSelection
                ? selectedCreatives?.[CreativeSelectionKey_Body]
                : launch
                ? campaignObject[campaignIndex]?.adSets[adSetindex]?.adCreatives?.body
                : selectedBody
            }
            name={CreativeSelectionKey_Body}
            style={{ width: "100%" }}
          >
            {loading_fetch ? (
              <Loader center elementCenter title="Loading Bodies..." />
            ) : (
              renderData?.map(({ body }) => (
                <div
                  className={cx("look-radio-ad flex justify-content-between", {
                    active:
                      groupSelection || launch
                        ? isTemp
                          ? findCommonDynamicAds(CreativeSelectionKey_Body, body?.id)
                          : selectedCreatives?.[CreativeSelectionKey_Body]?.includes(body?.id)
                        : selectedBody?.includes(body?.id),
                  })}
                >
                  <Checkbox value={groupSelection ? body?.id : launch ? body?.body : body?.id}>
                    {body?.body}
                  </Checkbox>
                  <div className="flex">
                    <ActionIconRender
                      style={{ marginRight: "8px", color: "white" }}
                      iconType="tagSwitch"
                      onClick={() => {
                        tagDataExtractor(body?.id);
                        openTagModal();
                        setDeleteModalPropsData([{ id: body?.id, tags: body?.tags || [] }]);
                      }}
                    />
                    <ActionIconRender
                      style={{ marginRight: "8px", color: "white" }}
                      iconType="delete"
                      onClick={() => onOpenDeleteModal(body?.id)}
                    />
                  </div>
                </div>
              ))
            )}
          </Checkbox.Group>

          {/* <LookTable {...tableMainProps} /> */}
        </>
        {/* ( !launch && (
        <Radio.Group
          onChange={(e) => {

            handleSelectedCreatives(CreativeSelectionKey_Body, e.target.value);
            setSelectedData({
              ...selectedData,
              body: {
                id: e.target.value,
                body: renderData?.find((i) => i.id === e.target.value)?.body,
              },
            });
          }}
          value={selectedCreatives?.[CreativeSelectionKey_Body]}
          name={CreativeSelectionKey_Body}
          style={{ width: "100%" }}
        >
          {renderData?.map(({ id, body }) => (
            <div
              className={cx("look-radio-ad", {
                active: selectedCreatives?.[CreativeSelectionKey_Body] === id,
              })}
            >
              <Radio value={id}>{body}</Radio>
            </div>
          ))}
        </Radio.Group>
        ) )} */}
      </div>
      <div className={`p-8 look-bottom-pagination ${cx({ "mb-0": launch })}`}>
        {/* <Pagination
          defaultCurrent={1}
          total={totalCount}
          pageSize={pageSize}
          current={currentPage}
          onChange={(page, size) => handlePageChange(page, size)}
          pageSizeOptions={["10", "20", "50"]}
          showSizeChanger
        /> */}
        <LookPagination
          totalCount={totalCount}
          onChange={handlePageChange}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </div>
      <DebugPre content={debugContent} />
    </div>
  );
}
