import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  F360_VIEW_ASSET_AD_ACCOUNTS,
  FB360_AGENCY_ADMIN,
  UMS_SUPER_ADMIN,
} from "../../shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const AdAccounts = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "fb360-AdAccounts" */ "pages/dashboardPages/fb360/AdAccounts")
  )
);
const Ads = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Ads" */ "pages/dashboardPages/fb360/Ads"))
);
const AdsCheater = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Creatives" */ "pages/dashboardPages/fb360/Creatives")
  )
);
const Adsets = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Adsets" */ "pages/dashboardPages/fb360/Adsets"))
);
const BM = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Bm" */ "pages/dashboardPages/fb360/Bm"))
);
const Campaigns = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "fb360-Campaigns" */ "pages/dashboardPages/fb360/Campaigns")
  )
);
const FacebookDailyReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookDailyReport" */ "pages/dashboardPages/fb360/Reports/FacebookDailyReport"
    )
  )
);
const FacebookHourlyReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookHourlyReport" */ "pages/dashboardPages/fb360/Reports/FacebookHourlyReport"
    )
  )
);
const FacebookHourOfTheDayReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookHourOfTheDayReport" */ "pages/dashboardPages/fb360/Reports/FacebookHourOfTheDayReport"
    )
  )
);
const FacebookReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "fb360-FacebookReport" */ "pages/dashboardPages/fb360/Reports/FacebookReport"
    )
  )
);
const Profile = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "fb360-Profile" */ "pages/dashboardPages/fb360/Profile")
  )
);

const BmReport = lazy(()=>
  loadbleLazy(() =>
    import(/* webpackChunkName: "fb360-Bm-Reports" */ "pages/dashboardPages/fb360/BmReports")
  )
)

const fb360Routes = [
  route("/fb360/profile", Profile, ROUTE_DASHBOARD, false),
  route("/fb360/ad-accounts", AdAccounts, ROUTE_DASHBOARD, false),
  route("/fb360/bms", BM, ROUTE_DASHBOARD, false, FB360_AGENCY_ADMIN),
  route("/fb360/campaigns", Campaigns, ROUTE_DASHBOARD, false),
  route("/fb360/adsets", Adsets, ROUTE_DASHBOARD, false),
  route("/fb360/ads", Ads, ROUTE_DASHBOARD, false),
  route("/fb360/creatives", AdsCheater, ROUTE_DASHBOARD, false, FB360_AGENCY_ADMIN),
  route("/fb360/reports", FacebookReport, ROUTE_DASHBOARD, false),
  route("/fb360/daily", FacebookDailyReport, ROUTE_DASHBOARD, false),
  route("/fb360/hourly", FacebookHourlyReport, ROUTE_DASHBOARD, false),
  route(
    "/fb360/hour-of-the-day",
    FacebookHourOfTheDayReport,
    ROUTE_DASHBOARD,
    false,
    "vs_view_agency_reporting"
  ),
  route("/fb360/bm-reports", BmReport,  ROUTE_DASHBOARD, false),
];

export default fb360Routes;
