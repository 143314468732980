import DebugPre from "components/DebugPre";
import { useLaunchContext } from "components/Launch/Context";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { LAUNCH_SMART_CREATIVE_ENUM } from "shared/enum/iCampaignLaunchEnumArray";

export default function LaunchCampaignView(props) {
  const { finalSubmissions, campsData } = props;
  console.log(props);
  const {
    campaignObject,
    launchDataAdAccount_data,
    isTiktokPlatformLaunch,

    data_adAccount,
  } = useLaunchContext();
  const initialColumns = [
    {
      title: "Name",
      dataIndex: "campaignName",
      key: "campaignName",
      // type: "text",
      // editable: true,
      width: 124,
      minWidth: 124,
      render: (value) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Objective",
      dataIndex: "objective",
      key: "objective",
      // type: "select",
      // editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Ad Account",
      dataIndex: "adAccount",
      key: "adAccount",
      // type: "text",
      // editable: true,
      render: () => (
        <LookTableColumnRender
          title={
            isTiktokPlatformLaunch
              ? data_adAccount?.tiktok_getAccounts?.find(
                  (item) => item.account_id === finalSubmissions?.advertiserId
                )?.name
              : launchDataAdAccount_data?.launchDataAdAccount?.adAccountsData.find(
                  (item) => item.adAccount === finalSubmissions?.accountId
                )?.name
          }
        />
      ),
      width: 124,
      minWidth: 124,
    },
    {
      title: "Ad sets",
      dataIndex: "adSets",
      key: "adSets",
      // type: "text",
      // editable: true,
      render: (value) => <LookTableColumnRender title={value?.length} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Ads",
      dataIndex: "adSets",
      key: "adSets",
      // type: "text",
      // editable: true,
      render: (value) => {
        let totalAds = 0;
        value?.forEach(({ ads }) => {
          totalAds += ads?.length;
        });
        return <LookTableColumnRender title={totalAds} />;
      },
      width: 124,
      minWidth: 124,
    },
    {
      title: "Dynamic Adsets",
      dataIndex: "dynamicAdset",
      key: "dynamicAdset",
      // type: "text",
      // editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "C-type",
      dataIndex: "cType",
      key: "cType",

      width: 124,
      minWidth: 124,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
    },
    ...(!isTiktokPlatformLaunch
      ? [
          {
            title: "B-type",
            dataIndex: "budget_type",
            key: "budget_type",

            width: 150,
            minWidth: 150,
          },
          {
            title: "Bid Strategy",
            dataIndex: "bidStrategy",
            key: "bidStrategy",

            width: 200,
            minWidth: 200,
          },
        ]
      : []),
  ];

  const tableProps = {
    initialColumns: initialColumns,
    recordData: isTiktokPlatformLaunch
      ? campaignObject?.map((data, index) => ({
          ...data,
          campaignName: isTiktokPlatformLaunch
            ? campsData?.campaigns?.[index]?.campaignName
            : campsData?.[index]?.name,
          campaignIndex: index,
          dynamicAdset: isTiktokPlatformLaunch
            ? campsData?.campaigns[0]?.adSets?.filter(
                (i) => i?.creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM
              )?.length
            : campsData?.adSets?.filter((i) => i.isDynamicCreative).length,
        }))
      : campsData
          ?.flatMap((item) => item.campaigns)
          ?.map((data, index) => ({
            ...data,
            campaignName: data?.name,
            campaignIndex: index,
            dynamicAdset: data?.adSets?.filter((i) => i.isDynamicCreative).length,
          })),
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [
        { isTiktokPlatformLaunch, campaignObject },
        { cL: campsData?.length, campsData },
      ],
    },
  ];

  return (
    <>
      <LookTable
        className="launchTable"
        isTableFilter={false}
        isSrNo
        tableProps={tableProps}
        // dataSource={dataSource}
        // setDataSource={setDataSource}
        // scrollHeight="calc(100vh - 211px)"
        isRowSelection
        // bulkActions={bulkActions}
      />
      <DebugPre content={debugContent} />
    </>
  );
}
