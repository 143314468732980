export const LookChartPatternColors = [
  "#FF6B6B",
  "#e8bf1a",
  "#83cb6b",
  "#4D96FF",
  "#BD4291",
  "#53BF9D",
  "#293462",
  "#FF06B7",
  "#FF2626",
  "#006E7F",
  "#81B214",
  "#00C897",
  "#595260",
  "#420516",
  "#8B9A46",
  "#F15412",
  "#3A0088",
  "#4A772F",
  "#AF8BAF",
  "#FEED30",
];
