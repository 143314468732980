import { forwardRef, useEffect } from "react";
import { MainForm } from "./view";
import { LIST_BIT, STORY_BIT } from "./view/PageBits";
import { useApiToStoreContext } from "hooks/useApiToStore/context";

// Enums for render content form
export const CMS_POST_TYPE_LIST = "List";
export const CMS_POST_TYPE_STORY = "Story";

// Enums for content view type
export const CMS_POST_CONTENT_VIEW_TYPE_CREATE = "create";
export const CMS_POST_CONTENT_VIEW_TYPE_EDIT = "edit";
export const CMS_POST_CONTENT_VIEW_TYPE_CRS = "CRS_VIEW";

const PostHandle = forwardRef((props, ref) => {
  /**
   * This use in CMS and CRS level
   * For CMS post type create/edit
   * For CRS post type CRS_VIEW
   */

  const { cmsDoimnsFetch, cmsCountriesFetch, cmsProductsFetch, cmsStoresFetch } =
    useApiToStoreContext();

  const { view = CMS_POST_CONTENT_VIEW_TYPE_CREATE, postType = "" } = props;

  useEffect(() => {
    if (
      view === CMS_POST_CONTENT_VIEW_TYPE_EDIT &&
      (postType === LIST_BIT || postType === STORY_BIT)
    ) {
      cmsCountriesFetch();
    }
    cmsProductsFetch();
    cmsStoresFetch();
    cmsDoimnsFetch();
  }, []);

  return (
    <div>
      <MainForm {...props} ref={ref} />
    </div>
  );
});

export default PostHandle;
