import { Form } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import { useCMSPostHandle } from "components/PostHandle/view/Context";

export default function SeoHandle() {
  const {
    inputs: { metaTitle = "", metaDescription = "", metaKeywords = "" },
    handleInput = () => {},
  } = useCMSPostHandle();

  const inputCollection = [
    {
      label: `Meta Title ( ${metaTitle ? metaTitle?.length : 0} / 60 )`,
      name: "metaTitle",
      value: metaTitle,
      onChange: handleInput,
    },
    {
      type: "textarea",
      label: `Meta Description ( ${metaDescription ? metaDescription?.length : 0} / 160 )`,
      name: "metaDescription",
      value: metaDescription,
      onChange: handleInput,
    },
    { label: "Meta Keywords", name: "metaKeywords", value: metaKeywords, onChange: handleInput },
  ];
  return (
    <Form layout="vertical">
      {inputCollection.map((data, index) => (
        <LookTextInput key={"inputCollection" + index} {...data} />
      ))}
    </Form>
  );
}
