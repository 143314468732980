import { Image } from "antd";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";
import React, { useState } from "react";

function DetailView(props) {
  const { image, video, title } = props;

  const [playModal, setPlayModal] = useState(false);

  return (
    <div>
      <h3 className="mb-20">{title}</h3>
      {image && <Image src={image?.s3Url} preview={false} />}
      {video?.s3Url && (
        <>
          <LookModal
            title="Play Video"
            visible={playModal}
            onCancel={() => setPlayModal(false)}
            footer={false}
          >
            {playModal && <video className="w-100" src={video?.s3Url} controls autoPlay />}
          </LookModal>
          <div className="position-absolute-center z-index-2 overlay">
            <LookButton
              className="mr-8"
              tooltipTitle="Play video"
              iconType="play"
              type="primary"
              onClick={() => setPlayModal(true)}
            >
              Play Video
            </LookButton>
          </div>
          <Image width={850} height={750} src={video?.thumbUrl} preview={false} />
        </>
      )}
    </div>
  );
}

export default DetailView;
