import { gql } from "@apollo/client";

export const COMMENTS_GET_ALL_MESSAGES_BY_CATEGORY_ID = gql`
  query Comment_getAllMessageByCategoryId(
    $categoryId: ID!
    $parentId: ID
    $countryId: ID
    $platformId: [ID]
    $teamId: [ID]
  ) {
    comment_getAllMessageByCategoryId(
      categoryId: $categoryId
      parentId: $parentId
      countryId: $countryId
      platformId: $platformId
      teamId: $teamId
    ) {
      data {
        date
        from
        id
        isDeleted
        isEdit
        isSeen
        like_dislikeInfo
        mentionUser
        message
        parentId
        platform
        reportId
        reportType
        seen
        tableType
        taskId
        team
        timeZone
        to
        totalComments
        totalDislike
        totalLike
        totalReply
        totalUnseenComments
        user {
          id
          name
        }
      }
      message
    }
  }
`;

export const GET_COMMENTS_COUNTS_BY_CATEGORY_ID = gql`
  query Comment_getCommentCountByCategoryId {
    comment_getCommentCountByCategoryId {
      data
      message
      status
    }
  }
`;

export const COMMENETS_GET_ALL_MESSAGE = gql`
  query Comment_getAllMessage(
    $reportId: ID!
    $parentId: ID
    $reportType: String
    $tableType: String
  ) {
    comment_getAllMessage(
      reportId: $reportId
      parentId: $parentId
      reportType: $reportType
      tableType: $tableType
    ) {
      data {
        date
        from
        id
        message
        parentId
        like_dislikeInfo
        reportId
        reportType
        isSeen
        tableType
        timeZone
        to
        taskId
        totalComments
        totalDislike
        totalLike
        totalReply
        totalUnseenComments
        user {
          id
          name
        }
      }
      message
    }
  }
`;

export const GET_COMMENETS_COUNTS = gql`
  query Comment_getCommentCount($reportType: String, $tableType: String) {
    comment_getCommentCount(reportType: $reportType, tableType: $tableType) {
      data
      message
      status
    }
  }
`;

export const CREATE_COMMENETS = gql`
  mutation Comment_create($data: commentInput) {
    comment_create(data: $data) {
      message
      data {
        categoryId
        countryId
        date
        from
        id
        isDeleted
        isEdit
        isSeen
        message
        parentId
        platform
        reportId
        reportType
        seen
        tableType
        team
        timeZone
        to
        totalComments
        totalDislike
        totalLike
        totalReply
        totalUnseenComments
        user {
          id
          name
        }
      }
      status
    }
  }
`;

export const EDIT_COMMENT = gql`
  mutation Comment_edit(
    $commentId: ID!
    $message: String!
    $categoryId: ID
    $countryId: ID
    $platform: [platformInput]
    $team: [teamInput]
    $mentionUser: [ID]
  ) {
    comment_edit(
      categoryId: $categoryId
      commentId: $commentId
      countryId: $countryId
      message: $message
      platform: $platform
      team: $team
      mentionUser: $mentionUser
    ) {
      message
      data {
        categoryId
        countryId
        seen
        tableType
        totalDislike
        totalLike
        totalReply
        totalUnseenComments
        date
        from
        id
        isDeleted
        isEdit
        isSeen
        message
        parentId
        platform
        reportId
        reportType
        tableType
        team
        timeZone
        to
        totalComments
        totalDislike
        totalLike
        totalReply
        totalUnseenComments
        user {
          id
          name
        }
      }
      status
    }
  }
`;

export const COMMENETS_LIKES_DISLIKES = gql`
  mutation Comment_like_dislike($id: ID!, $totalDislike: ID, $totalLike: ID) {
    comment_like_dislike(id: $id, totalDislike: $totalDislike, totalLike: $totalLike) {
      data {
        date
        from
        id
        message
        parentId

        reportId
        reportType
        tableType
        timeZone
        to
        totalComments
        totalDislike
        totalLike
        totalReply
        totalUnseenComments
        user {
          id
          user_name
        }
      }
      status
      message
    }
  }
`;

export const COMMENETS_DELETE = gql`
  mutation Comment_delete($id: ID) {
    comment_delete(id: $id) {
      data {
        date
        from
        id
        message
        parentId
        reportId
        reportType
        tableType
        timeZone
        to
        totalComments
        totalDislike
        totalLike
        totalReply
        totalUnseenComments
        user {
          id
          name
        }
      }
      message
      status
    }
  }
`;

export const CLICK_UP_TASK_CREATION = gql`
  mutation Comment_create_clickup_task(
    $commentId: ID!
    $dueDate: Date!
    $name: String!
    $team_id: ID!
    $userId: ID!
    $priority: Int
    $description: String
  ) {
    comment_create_clickup_task(
      commentId: $commentId
      dueDate: $dueDate
      name: $name
      team_id: $team_id
      userId: $userId
      priority: $priority
      description: $description
    ) {
      data
      message
      status
    }
  }
`;

export const COMMENT_SEEN = gql`
  mutation Comment_seen($seenId: [ID]) {
    comment_seen(seenId: $seenId)
  }
`;

export const CLICKUP_GET_AUTH_CODE_STATUS = gql`
  query Clickup_getStatusAndToken {
    clickup_getStatusAndToken
  }
`;

export const GET_CLICKUP_AUTH_CODE_CS = gql`
  query Clickup_getAuthorizationCode {
    clickup_getAuthorizationCode
  }
`;

export const CREATE_CLICKUP_API_TOKEN_For_CS = gql`
  mutation Clickup_createApiToken($code: String!) {
    clickup_createApiToken(code: $code)
  }
`;

export const GET_CLICKUP_API_TOKEN_For_CS = gql`
  mutation Clickup_createApiToken($code: String!) {
    clickup_createApiToken(code: $code)
  }
`;

export const GET_CLICKUP_TEAMS = gql`
  query Comment_get_clickup_team {
    comment_get_clickup_team
  }
`;

export const COMMENTS_MARK_AS_UNREAD = gql`
  mutation Comment_unseen($seenId: ID!) {
    comment_unseen(seenId: $seenId)
  }
`;
