import {
  CURRENCY_TYPE_INR,
  CURRENCY_TYPE_INR_SYMBOL,
  CURRENCY_TYPE_USD,
  CURRENCY_TYPE_USD_SYMBOL,
} from "shared/SharedKeys";

const currencySymbolCheck = (type) => {
  switch (type) {
    case CURRENCY_TYPE_INR:
      return CURRENCY_TYPE_INR_SYMBOL;

    case CURRENCY_TYPE_USD:
      return CURRENCY_TYPE_USD_SYMBOL;

    default:
      return "";
  }
};

export default currencySymbolCheck;
