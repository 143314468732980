import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import TempUserToken, { TempUserTokenFull } from "./tempUserToken";
import route, { ROUTE_PUBLIC, ROUTE_DEFAULT, ROUTE_PRIVATE } from "./route";

import dashboardRoutes from "./routesPath/dashboardRoutes";
import virlSparksRoutes from "./routesPath/virlSparksRoutes";
import cmsRoutes from "./routesPath/cmsRoutes";
import awicRoutes from "./routesPath/awicRoutes";
import iCampaignsRoutes from "./routesPath/iCampaignsRoutes";
import useSasRoutes from "./routesPath/useSasRoutes";
import creativeRoutes from "./routesPath/creativeRoutes";
import umsRoutes from "./routesPath/umsRoutes";
import twitterRoutes from "./routesPath/twitterRoutes";
import adLibraryRoutes from "./routesPath/adLibraryRoutes";
import g360Routes from "./routesPath/g360Routes";
import slackRoutes from "./routesPath/slackRoutes";
import trackerRoutes from "./routesPath/trackerRoutes";
import tiktokRoutes from "./routesPath/tiktokRoutes";
import pinterestRoutes from "./routesPath/pinterestRoutes";
import fb360Routes from "./routesPath/fb360Routes";
import hrmsRoutes from "./routesPath/hrmsRoutes";
import useScorecardRoutes from "./routesPath/useScorecardRoutes";

import launchRoutes from "./routesPath/launchRoutes";
import { useSelector } from "react-redux";
import { USER_WITH_NO_ACTION } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import useConfigurationRoutes from "./routesPath/useConfigurationRoutes";
import useVaroziRoutes from "./routesPath/useVaroziRoutes";
import dmsRoutes from "./routesPath/dmsRoutes";
import useUnicornRoutes from "./routesPath/useUnicornRoutes";
import crsRoutes from "./routesPath/crsRoutes";
import recommendedRuleRoutes from "./routesPath/recommendedRuleRoutes";
import useleadgenRoutes from "./routesPath/useLeadgenRoutes";
import googleLaunchRoutes from "./routesPath/googleLaunchRoutes";
import industryInsightsRoutes from "./routesPath/industryInsightsRoutes";
import OAuthFbInsightsCallback from "pages/FbInsightsCallBack/OAuthFbInsightsCallBack";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";

const Login = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "login" */ "pages/formPages/login"))
);
const SignUp = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "signUp" */ "pages/formPages/signUp"))
);
const OTP = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "otp" */ "pages/formPages/otp"))
);
const ForgotPassword = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "forgotPassword" */ "pages/formPages/forgotPassword")
  )
);
const ResetPassword = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "resetPassword" */ "pages/formPages/resetPassword"))
);

const Page404NotFound = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Page404NotFound" */ "pages/Page404NotFound"))
);
const CreateAgency = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "createAgency" */ "pages/createAgency"))
);
const TermsOfService = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "TermsOfService" */ "pages/TermsOfService"))
);
const PrivacyPolicy = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "PrivacyPolicy" */ "pages/PrivacyPolicy"))
);
const Invitation = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Invitation" */ "pages/Invitation"))
);
const OAuthCallBack = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "CallBack" */ "pages/CallBack"))
);

const OAuthTwitterCallback = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "OAuthTwitterCallback" */ "pages/dashboardPages/Twitter/OAuthTwitterCallback"
    )
  )
);
const OAuthSlackChannel = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "OAuthSlackCallback" */ "pages/dashboardPages/Slack/OAuthSlackCallback"
    )
  )
);
const OAuthTiktokCallback = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "OAuthTiktokCallback" */ "pages/dashboardPages/TikTok/OAuthTiktokCallback"
    )
  )
);
const OAuthPinterestCallback = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "OAuthPinterestCallback" */ "pages/dashboardPages/Pinterest/OAuthPinterestCallback"
    )
  )
);
const OAuthFbCallback = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "FbCallBack" */ "pages/FbCallBack"))
);
const OAuthUnicornCallback = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "OAuthUnicornCallback" */ "pages/dashboardPages/Unicorn/OAuthUnicornCallback"
    )
  )
);

export default function useRoutes() {
  const { agency_id, user_id } = useSelector((state) => state?.userReducer?.me) || {};
  const { havePermission: isWithoutAction } = usePermissionCheck(USER_WITH_NO_ACTION);

  const { isViewMilestone = false } = useSelector((state) => state?.userReducer.me) || {};

  const { scorecardRoutes = [] } = useScorecardRoutes();
  const { configurationRoutes = [] } = useConfigurationRoutes();
  const { varoziRoutes = [] } = useVaroziRoutes();
  const { sasRoutes } = useSasRoutes();
  const { leadgenRoutes } = useleadgenRoutes();

  const { unicornRoutes } = useUnicornRoutes();

  const isMilestonesRoute = isViewMilestone ? unicornRoutes : [];

  const isLaunchRoutes = agency_id === 1 && !isWithoutAction ? launchRoutes : [];
  const isGoogleLaunchRoutes = agency_id === 1 && !isWithoutAction ? googleLaunchRoutes : [];

  const isCreativeRoutes = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id) ? [] : creativeRoutes;
  const routes = [
    route("/", Login, ROUTE_PUBLIC),
    // route("/sign-up", SignUp, ROUTE_PUBLIC),
    route("/otp", OTP, ROUTE_PUBLIC),

    route("/forgot-password", ForgotPassword, ROUTE_PUBLIC),
    route("/reset-password", ResetPassword, ROUTE_PUBLIC),
    route("/create-agency", CreateAgency, ROUTE_PRIVATE),
    route("/temp-user-token", TempUserToken, ROUTE_PRIVATE),
    route("/temp-user-token-full", TempUserTokenFull, ROUTE_PRIVATE),
    route("/invitation", Invitation, ROUTE_PRIVATE),
    route("/accept_invitation/:code", SignUp, ROUTE_DEFAULT),

    ...leadgenRoutes,
    ...industryInsightsRoutes,
    // ...dmsRoutes,
    // ...f360Routes,
    ...fb360Routes,
    // ...iTaskRoutes,
    // ...iQlicksRoutes,
    ...hrmsRoutes,
    ...dashboardRoutes,
    ...g360Routes,
    ...configurationRoutes,
    ...varoziRoutes,
    ...sasRoutes,
    ...virlSparksRoutes,
    ...cmsRoutes,
    ...awicRoutes,
    ...iCampaignsRoutes,
    ...twitterRoutes,
    ...isCreativeRoutes,
    ...umsRoutes,
    ...dmsRoutes,
    ...adLibraryRoutes,
    ...slackRoutes,
    ...tiktokRoutes,
    ...trackerRoutes,
    ...pinterestRoutes,
    ...scorecardRoutes,
    ...isMilestonesRoute,
    ...isLaunchRoutes,
    ...isGoogleLaunchRoutes,
    ...crsRoutes,
    ...recommendedRuleRoutes,
    route("/google/redirect", OAuthCallBack, ROUTE_DEFAULT),
    route("/facebook/redirect", OAuthFbCallback, ROUTE_DEFAULT),
    route("/facebook/industry-insights-redirect", OAuthFbInsightsCallback, ROUTE_DEFAULT),
    route("/tiktok/redirect", OAuthTiktokCallback, ROUTE_DEFAULT),
    route("/pinterest/redirect", OAuthPinterestCallback, ROUTE_DEFAULT),
    route("/twitter/redirect", OAuthTwitterCallback, ROUTE_DEFAULT),
    route("/clickup/redirect", OAuthUnicornCallback, ROUTE_DEFAULT),
    route("/slack/redirect", OAuthSlackChannel, ROUTE_DEFAULT),
    route("/slack/redirect-subs", OAuthSlackChannel, ROUTE_DEFAULT),
    route("/privacy-policy", PrivacyPolicy, ROUTE_DEFAULT),
    route("/terms-Of-service", TermsOfService, ROUTE_DEFAULT),
    route("/404", Page404NotFound, ROUTE_DEFAULT),
  ];

  return { routes };
}
