import { lazy } from "react";
import route from "../route";
import loadbleLazy from "utils/loadbleLazyWrapper";

const MainDashboard = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "mainDashboard" */ "pages/dashboardPages/mainDashboard")
  )
);
const ScrapeAd = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "ScrapeAd" */ "pages/dashboardPages/ScrapeAd"))
);
const ScrapeAdPost = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "ScrapeAdPost" */ "pages/dashboardPages/ScrapeAdPost")
  )
);
const HelpContact = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "HelpContact" */ "pages/dashboardPages/HelpContact")
  )
);

const dashboardRoutes = [
  route("/dashboard", MainDashboard),
  route("/dashboard/scrape-ad-list", ScrapeAd),
  route("/dashboard/scrape-ad-create", ScrapeAdPost),
  route("/dashboard/help-contact", HelpContact),
];

export default dashboardRoutes;
