import route, { ROUTE_DASHBOARD } from "../route";
import {
  Ads,
  Domains,
  Pages,
  Category,
  Notification,
  NotificationSettings,
} from "pages/dashboardPages/IndustryInsights";

const industryInsightsRoutes = [
  route("/industryInsights/ads", Ads, ROUTE_DASHBOARD),
  route("/industryInsights/domains", Domains, ROUTE_DASHBOARD),
  route("/industryInsights/pages", Pages, ROUTE_DASHBOARD),
  route("/industryInsights/category", Category, ROUTE_DASHBOARD),
  route("/industryInsights/all-notification", Notification, ROUTE_DASHBOARD),
  route("/industryInsights/notification-settings", NotificationSettings, ROUTE_DASHBOARD),
];

export default industryInsightsRoutes;
