import ActionIconRender from "components/ActionIconRender";
import { PieChartOutlined } from "@ant-design/icons";
import { RC_AGENCY_PERMISSION } from "shared/UserPermission/userPermissionList";
import { SAS_USER } from "shared/UserPermission/userPermissionList";

const recommendationPath = [
  {
    name: "Recommendations",
    link: "/recommended-rules",
    icon: (e) => <ActionIconRender iconType="like" {...e} />,
    subMenu: [
      {
        subMenuName: "Recommendations",
        subMenuLink: "/",
        subMenuIcon: PieChartOutlined,
        permission: [RC_AGENCY_PERMISSION, SAS_USER],
      },
    ],
  },
];

export default recommendationPath;
