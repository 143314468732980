import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
function DetailView(props) {
  const { visible = false, name = "", onClose } = props;

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={name}
    >
      {visible && (
        <LookDrawerMainSideBar
          tabContent={[
            // {
            //   hoverTitle: "Performance Report",
            //   icon: () => <ActionIconRender iconType="pic-left" />,
            //   component: <PerformanceReport {...props} />,
            //   noSpace: true,
            // },
            // {
            //   hoverTitle: "Ad Set Report",
            //   icon: () => <ActionIconRender iconType="notification" />,
            //   component: <AdSetReport isCampaignFiltered {...props} />,
            //   noSpace: true,
            // },
            // {
            //   hoverTitle: "Ads Report",
            //   icon: () => <ActionIconRender iconType="notification" />,
            //   component: <AdsReport isCampaignFiltered {...props} />,
            //   noSpace: true,
            // },
            // {
            //   hoverTitle: "Creatives",
            //   icon: () => <ActionIconRender iconType="notification" />,
            //   component: <Creatives {...props} />,
            //   noSpace: true,
            // },
          ]}
        />
      )}
    </LookDrawer>
  );
}

export default DetailView;
