import { Col, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import React from "react";

export default function MainPointsModal(props) {
  const { setSelectedItems = () => {} } = props;
  return (
    <div>
      <Row gutter={12}>
        <Col span={24}>
          <LookTextInput
            name="mainPoints"
            label="MainPoint"
            className="look-form-input"
            placeholder="Enter mainpoints"
            onChange={(e) => {
              setSelectedItems(e.target.value);
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
