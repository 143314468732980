import { USER_SETTING_MAIN_BULK_ACTION_CLEAR } from "actions/actionTypes";
import { useDispatch } from "react-redux";

export default function useDispatchFunctionsHooks() {
  const dispatch = useDispatch();

  function clearBulkAction() {
    dispatch({ type: USER_SETTING_MAIN_BULK_ACTION_CLEAR });
  }

  return { clearBulkAction };
}
