import { Col, Row, Tooltip } from "antd";
import { OverviewCard } from "pages/dashboardPages/mainDashboard/UserRevenuData/Cards";
import React, { useState } from "react";
import { openNotification } from "utils";
import LookButton from "components/LookButton";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { useSelector } from "react-redux";
import { GQL_GET_LAUNCHED_BY_DATE } from "shared/gql/gqlSchema/launchGql";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import SelectInput from "components/forms/SelectInput";

function Overview({ serviceType, productId, selectedPlatform, setSelectedPlatform = () => {} }) {
  const [overviewData, setOverviewData] = useState({});
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRange } = userSettings || {};

  const {
    data: OVERVIEW_SCORE_DATA,
    loading: OVERVIEW_SCORE_LOADING,
    error: OVERVIEW_SCORE_ERROR,
    refetch: OVERVIEW_SCORE_REFETCH,
  } = useCustomQuery(GQL_GET_LAUNCHED_BY_DATE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.launchedByDate;
      setOverviewData(element);
    },
    variables: {
      productId,
      platformType: selectedPlatform,
      selectedDate: dateRange?.rangeDates[0],
      endDate: dateRange?.rangeDates[1],
    },
    onError: (e) => {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const { totalAds = 0, totalCampaigns = 0, totalLaunches = 0 } = overviewData || {};

  const overview = [
    {
      iconType: "adIcon",
      title: "Ads",
      value: (
        <>
          <Tooltip title={totalAds}>{totalAds}</Tooltip>
        </>
      ),
    },
    {
      iconType: "notification",
      title: "Campaigns",
      value: (
        <>
          <Tooltip title={totalCampaigns}>{totalCampaigns}</Tooltip>
        </>
      ),
    },
    {
      iconType: "rocket",
      title: "Launches",
      value: (
        <>
          <Tooltip title={totalLaunches}>{totalLaunches}</Tooltip>
        </>
      ),
    },
  ];

  const renderSkeleton = () => (
    <>
      <div>
        <Row gutter={4}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  const renderContent = () => (
    <>
      <div>
        <Row gutter={4}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard isSmall {...data} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  return (
    <div>
      <div className="flex-between mb-10">
        <h1>{serviceType} Launch Overview</h1>
        <div className="flex-between">
          <div className="mr-10" style={{ width: 150 }}>
            <SelectInput
              className="mb-0"
              search
              placeholder="Select Platform"
              name="Platform"
              value={selectedPlatform}
              options={[
                { id: "FACEBOOK", name: "Facebook" },
                { id: "TIKTOK", name: "Tiktok" },
              ].map(({ id, name }) => ({ name: name, value: id }))}
              onChange={(e, f) => setSelectedPlatform(e)}
            />
          </div>
          <LookButton
            onClick={() => OVERVIEW_SCORE_REFETCH()}
            iconType="reload"
            size="small"
            className="mr-10"
          />
          <DateRangePersist isTimezone={false} />
        </div>
      </div>
      {OVERVIEW_SCORE_LOADING ? renderSkeleton() : renderContent()}
    </div>
  );
}

export default Overview;
