import LookCollapse from "components/LookCollapse";
import LaunchCampaignCreation from "../LaunchCampaignCreation";
import LaunchCreatives from "../LaunchCreatives";
import LaunchProgressBar from "../LaunchProgressBar";
import LaunchTargeting from "../LaunchTargeting";

export default function LaunchMainView(props) {
  return (
    <>
      <LaunchProgressBar />
      <div className="px-24">
        <LaunchCreatives />
        <LookCollapse colapseData={[{ title: "Targeting", component: LaunchTargeting }]} />
        <LookCollapse
          colapseData={[{ title: "Campaign Creation", component: LaunchCampaignCreation }]}
        />
      </div>
    </>
  );
}
