import { Segmented } from "antd";
import {
  ADS_TYPE_ADACCOUNT,
  ADS_TYPE_CATEGORY,
  ADS_TYPE_CONTENT,
  ADS_TYPE_DEFAULT,
  ADS_TYPE_PLATFORM,
  ADS_TYPE_USER,
} from "pages/dashboardPages/Creatives/creativesEnum";
import React from "react";
import { useCreativeReportingContext } from "../../Context";

function Header() {
  const {
    adsType = ADS_TYPE_DEFAULT,
    loading = false,
    isVS = false,
    onAdsTypeChange = () => {},
  } = useCreativeReportingContext();

  let options = [ADS_TYPE_DEFAULT, ADS_TYPE_CATEGORY, ADS_TYPE_PLATFORM, ADS_TYPE_ADACCOUNT];

  if (isVS) options = [ADS_TYPE_DEFAULT, ADS_TYPE_CONTENT, ADS_TYPE_ADACCOUNT];
  return (
    <div className="mt-10 mb-10 mr-2 text-right">
      <Segmented
        className="custom-segmented"
        options={options}
        value={adsType}
        disabled={loading}
        onChange={(e) => onAdsTypeChange(e)}
      />
    </div>
  );
}

export default Header;
