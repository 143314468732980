import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { USERS_MANAGES_USERS } from "../../shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const AdAccounts = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Twitter-AdAccounts" */ "pages/dashboardPages/Twitter/AdAccounts")
  )
);
const Adsets = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Twitter-Adsets" */ "pages/dashboardPages/Twitter/Adsets")
  )
);
const Campaigns = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Twitter-Campaigns" */ "pages/dashboardPages/Twitter/Campaigns")
  )
);
const FundingSources = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Twitter-FundingSources" */ "pages/dashboardPages/Twitter/FundingSources"
    )
  )
);
const Users = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Twitter-Users" */ "pages/dashboardPages/Twitter/Users")
  )
);

const twitterRoutes = [
  route("/twitter/users", Users, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
  route("/twitter/ad-accounts", AdAccounts, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
  route("/twitter/campaigns", Campaigns, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
  route("/twitter/funding-sources", FundingSources, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
  route("/twitter/adsets", Adsets, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
];

export default twitterRoutes;
