import { Col, Form, Radio, Row, Space } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import React from "react";
import { COMPLIANCE_ENUM } from "../creativesEnum";

function ComplianceModal(props) {
  const { complianceValue, setComplianceValue } = props;
  return (
    <div>
      <Form layout="vertical">
        <Row className="mb-8">
          <SelectInput
            label="Compliance Status"
            placeholder="Compliance Status"
            name="complianceStatus"
            options={COMPLIANCE_ENUM}
            value={complianceValue?.compliance}
            onChange={(e) => {
              setComplianceValue((prev) => ({ ...prev, compliance: e }));
            }}
          />
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <LookTextInput
              type="textarea"
              name="complianceDescription"
              label="Notes"
              className="look-form-input"
              value={complianceValue?.complianceDescription}
              onChange={(e) => {
                setComplianceValue((prev) => ({ ...prev, complianceDescription: e.target.value }));
              }}
              placeholder="Enter notes"
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ComplianceModal;
