import {
  RetweetOutlined,
  PicLeftOutlined,
  GlobalOutlined,
  ApartmentOutlined,
  LaptopOutlined,
  MediumOutlined,
  UserOutlined,
  BookOutlined,
} from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";
import { CustomeIconWrapper } from "..";
import { ViralSparksIcon } from "../../../components/svgComponents";
import {
  VS_VIEW_ADVERTISER_PANEL,
  VS_VIEW_AGENCY,
  VS_VIEW_BRAND,
  VS_VIEW_CONTENT,
  VS_VIEW_USER,
  VS_VIEW_PLATFORM,
  VS_VIEW_REPORTING,
  VS_VIEW_PERFORMANCE,
  UMS_SUPER_ADMIN,
} from "../../UserPermission/userPermissionList";
import { useSelector } from "react-redux";
import useLaunchpathsObject from "./useLaunchpathsObject";
import { faqSubMenuPath } from "./commonPath";

export default function useViralSparksPath() {
  const agency_id = useSelector((state) => state?.userReducer?.me?.agency_id);
  const { launchpathsObject } = useLaunchpathsObject({ permission: VS_VIEW_REPORTING });
  const isLaunchRoutes = agency_id === 1 ? [launchpathsObject] : [];
  const viralSparksPath = [
    {
      name: "Viral Sparks",
      link: "/vs",
      icon: () => (
        <CustomeIconWrapper className="size-17">
          <ViralSparksIcon />
        </CustomeIconWrapper>
      ),
      subMenu: [
        {
          subMenuName: "Reports",
          subMenuLink: "/reports/facebook",
          subMenuIcon: () => <ActionIconRender iconType="duration" />,
          permission: VS_VIEW_REPORTING,
        },
        {
          subMenuName: "Advertisers",
          subMenuLink: "/advertisers",
          subMenuIcon: RetweetOutlined,
          permission: VS_VIEW_ADVERTISER_PANEL,
        },
        {
          subMenuName: "Agencies",
          subMenuLink: "/agencies",
          subMenuIcon: PicLeftOutlined,
          permission: VS_VIEW_AGENCY,
        },
        {
          subMenuName: "Brands",
          subMenuLink: "/brands",
          subMenuIcon: RetweetOutlined,
          permission: VS_VIEW_BRAND,
        },
        {
          subMenuName: "Users",
          subMenuLink: "/users",
          subMenuIcon: PicLeftOutlined,
          permission: VS_VIEW_USER,
        },
        {
          subMenuName: "Platform",
          subMenuLink: "/platform",
          subMenuIcon: PicLeftOutlined,
          permission: VS_VIEW_PLATFORM,
        },
        {
          subMenuName: "Content",
          subMenuLink: "/content",
          subMenuIcon: PicLeftOutlined,
          permission: VS_VIEW_CONTENT,
        },
        {
          subMenuName: "Co-Pilot",
          subMenuIcon: () => <ActionIconRender iconType="copilot" />,
          isCollapsible: true,
          permission: VS_VIEW_REPORTING,
          subMenus: [
            {
              subMenuName: "Rules",
              subMenuLink: "/copilot/rules",
              subMenuIcon: () => <ActionIconRender iconType="rules" />,
            },
            {
              subMenuName: "All Rules",
              subMenuLink: "/copilot/allrules",
              subMenuIcon: () => <ActionIconRender iconType="rules" />,
            },
          ],
        },
        // {
        //   subMenuName: "Reports",
        //   subMenuIcon: PicLeftOutlined,
        //   permission: VS_VIEW_REPORTING,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Campaign",
        //       subMenuLink: "/report/campaign",
        //       subMenuIcon: PicLeftOutlined,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //     {
        //       subMenuName: "Country",
        //       subMenuLink: "/report/country",
        //       subMenuIcon: GlobalOutlined,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //     {
        //       subMenuName: "OS",
        //       subMenuLink: "/report/os",
        //       subMenuIcon: LaptopOutlined,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //     {
        //       subMenuName: "Device",
        //       subMenuLink: "/report/device",
        //       subMenuIcon: ApartmentOutlined,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //     {
        //       subMenuName: "Medium",
        //       subMenuLink: "/report/medium",
        //       subMenuIcon: MediumOutlined,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //     {
        //       subMenuName: "User",
        //       subMenuLink: "/report/user",
        //       subMenuIcon: UserOutlined,
        //       permission: VS_VIEW_PERFORMANCE,
        //     },
        //     {
        //       subMenuName: "Article",
        //       subMenuLink: "/report/article",
        //       subMenuIcon: BookOutlined,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //   ],
        // },

        // {
        //   subMenuName: "New Facebook",
        //   subMenuIcon: () => <ActionIconRender iconType="facebook-outlined" />,
        //   permission: VS_VIEW_REPORTING,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Report",
        //       subMenuLink: "/reports/facebook",
        //       subMenuIcon: () => <ActionIconRender iconType="duration" />,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //   ],
        // },
        // commented below four routes temprarily
        // {
        //   subMenuName: "Pinterest",
        //   subMenuIcon: () => <ActionIconRender iconType="pinterest" />,
        //   permission: VS_VIEW_REPORTING,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Report",
        //       subMenuLink: "/reports/pinterest",
        //       subMenuIcon: () => <ActionIconRender iconType="duration" />,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //   ],
        // },

        // {
        //   subMenuName: "Twitter",
        //   subMenuIcon: () => <ActionIconRender iconType="twitter" />,
        //   permission: VS_VIEW_REPORTING,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Report",
        //       subMenuLink: "/reports/twitter",
        //       subMenuIcon: () => <ActionIconRender iconType="duration" />,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //   ],
        // },

        // {
        //   subMenuName: "Taboola",
        //   subMenuIcon: () => <ActionIconRender iconType="taboola" />,
        //   permission: VS_VIEW_REPORTING,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Report",
        //       subMenuLink: "/reports/taboola",
        //       subMenuIcon: () => <ActionIconRender iconType="duration" />,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //   ],
        // },

        // {
        //   subMenuName: "Google",
        //   subMenuIcon: () => <ActionIconRender iconType="google" />,
        //   permission: VS_VIEW_REPORTING,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Report",
        //       subMenuLink: "/reports/google",
        //       subMenuIcon: () => <ActionIconRender iconType="duration" />,
        //       permission: VS_VIEW_REPORTING,
        //     },
        //   ],
        // },

        {
          subMenuName: "Performance",
          subMenuIcon: () => <ActionIconRender iconType="lineChart" />,
          permission: VS_VIEW_PERFORMANCE,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Country",
              subMenuLink: "/report/performance/countries",
              subMenuIcon: () => <ActionIconRender iconType="global" />,
              permission: VS_VIEW_PERFORMANCE,
            },
            {
              subMenuName: "Os",
              subMenuLink: "/report/performance/operating-systems",
              subMenuIcon: () => <ActionIconRender iconType="laptop" />,
              permission: VS_VIEW_PERFORMANCE,
            },
            {
              subMenuName: "Device",
              subMenuLink: "/report/performance/devices",
              subMenuIcon: () => <ActionIconRender iconType="apartment" />,
              permission: VS_VIEW_PERFORMANCE,
            },
            {
              subMenuName: "Medium",
              subMenuLink: "/report/performance/mediums",
              subMenuIcon: () => <ActionIconRender iconType="medium" />,
              permission: VS_VIEW_PERFORMANCE,
            },
            {
              subMenuName: "User",
              subMenuLink: "/report/performance/users",
              subMenuIcon: () => <ActionIconRender iconType="user" />,
              permission: VS_VIEW_PERFORMANCE,
            },
            {
              subMenuName: "Article",
              subMenuLink: "/report/performance/articles",
              subMenuIcon: () => <ActionIconRender iconType="book" />,
              permission: VS_VIEW_PERFORMANCE,
            },
          ],
        },
        ...isLaunchRoutes,
        // ...faqSubMenuPath,
      ],
    },
  ];
  return { viralSparksPath };
}
