import { MOODBOARD_TEMPLATE_TYPE_REQUEST } from "../creativesEnum";
import RequestList from "../RequestListing";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import useTemplateDrawer from "../CreativeHooks/useTemplateDrawer";
import { usePermissionCheck } from "hooks";
import {
  CREATIVE_TRACKING_BUSINESS_DEVELOPER,
  CREATIVE_TRACKING_CONTENT_WRITER,
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  CREATIVE_TRACKING_MEDIA_BUYER,
} from "shared/UserPermission/userPermissionList";
import { FETCH_REQUEST_TRACKING } from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";

function CreativeRequestList(props) {
  const { createTemplateForm, createTemplate } = useTemplateDrawer();
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const { havePermission: isBusinessDeveloper } = usePermissionCheck(
    CREATIVE_TRACKING_BUSINESS_DEVELOPER
  );
  //add hooks for permission
  const { havePermission: isMediaBuyer } = usePermissionCheck(CREATIVE_TRACKING_MEDIA_BUYER);
  const { havePermission: isContentWriter } = usePermissionCheck(CREATIVE_TRACKING_CONTENT_WRITER);
  const { havePermission: isGraphicDesigner } = usePermissionCheck(
    CREATIVE_TRACKING_GRAPHIC_DESIGNER
  );
  const permissionProps = {
    isMediaBuyer,
    isCreativeDirector,
    isGraphicDesigner,
    isContentWriter,
    isBusinessDeveloper,
  };
  const createBtnProps = {
    createBtn: true,
    createBtnText: "Raise Template Request",
    createBtnHandler: () =>
      createTemplate({
        isCategory: true,
        ...permissionProps,
        isCreativeRequest: false,
        requestListrefetch: data_refetch,
      }),
  };
  const [creativesRequestFetch, { data, loading: data_loading, refetch: data_refetch }] =
    useCustomLazyQuery(FETCH_REQUEST_TRACKING, {
      onCompleted: (e) => {},
      onError(e) {
        openNotification({ type: "error", message: e?.message, key: "mb-res-err" });
      },
    });

  return (
    <div>
      {createTemplateForm()}
      {!isGraphicDesigner && <DashboardHeader title="Template Request" {...createBtnProps} />}

      <RequestList
        actionType={MOODBOARD_TEMPLATE_TYPE_REQUEST}
        {...permissionProps}
        creativesRequestFetch={creativesRequestFetch}
        data_refetch={data_refetch}
        requestListData={data}
        data_loading={data_loading}
      />
    </div>
  );
}
export default CreativeRequestList;
