import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MainComments from "./mainComments";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
export default function useLookComment(props) {
  const { umsCurrentWorkspaceTeamsFetch, umsPlatformsFetch } = useApiToStoreContext();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [replyOrCommentText, setReplyOrCommentText] = useState("Comments");
  const location = useLocation();
  const urlString = location?.search;
  const params = new URLSearchParams(urlString);
  const history = useHistory();

  const messageId = params?.get("messageId");
  const reportId = params?.get("reportId");
  const drawerVisible = params?.get("drawerVisible");
  const reportTypeParams = params?.get("reportType");
  const tableTypeParams = params?.get("tableType");
  const from = params?.get("from");
  const to = params?.get("to");
  const timeZone = params?.get("timeZone");

  const clearSearchParams = () => {
    history.replace({
      pathname: history.location.pathname,
      search: "",
    });
  };

  useEffect(() => {
    if (visibleDrawer) {
      umsCurrentWorkspaceTeamsFetch();
      umsPlatformsFetch();
    }
  }, [visibleDrawer]);

  const [rowProps, setRowProps] = useState({});

  const showDrawer = (data) => {
    setVisibleDrawer(true);
    setRowProps(data);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    if (messageId && reportId && reportTypeParams && tableTypeParams && from && to && timeZone) {
      clearSearchParams();
    }
  };

  const ReplyOrCommentHeaderCallBackHandler = (text) => {
    setReplyOrCommentText(text);
    return text;
  };

  // provide the breadcrumbs to the header, default we will provide "Comments".
  // We can get from the MainComment.js as a callbackfn wether it's a "Comment" or "Replies". [reason being it is dynamic according to replies click]
  // first part of the breadcrumb will get from where the comment section added [reason being it is dynamic]
  const drawerProps = {
    textBreadcrumb: [...(rowProps?.breadCrumbs || [""]), replyOrCommentText],
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 520,
    noBodySpace: true,
  };

  useEffect(() => {
    if (messageId && reportId && reportTypeParams && tableTypeParams && from && to && timeZone) {
      showDrawer({
        rowData: {
          reportId: reportId,
          reportType: reportTypeParams,
          tableType: tableTypeParams,
          messageId: messageId,
          from: from,
          to: to,
          timeZone: timeZone,
        },
      });
    }
  }, []);

  function commentDrawer() {
    return (
      <>
        <LookDrawer {...drawerProps}>
          {visibleDrawer && (
            <>
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Comments",
                    icon: () => <ActionIconRender iconType="comment-message" />,
                    noSpace: true,
                    component: visibleDrawer && (
                      <MainComments
                        rowProps={rowProps}
                        visibleDrawer={visibleDrawer}
                        params={params}
                        setVisibleDrawer={setVisibleDrawer}
                        drawerVisible={drawerVisible}
                        replyOrCommentBreadCrumb={ReplyOrCommentHeaderCallBackHandler}
                      />
                    ),
                  },
                ]}
              />
            </>
          )}
        </LookDrawer>
      </>
    );
  }

  return { showDrawer, commentDrawer };
}
