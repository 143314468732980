import cx from "classnames";
import dashboardPath from "./path/dashboardPath";
// import iTaskPath from "./path/iTaskPath";
// import dmsPath from "./path/cfmsPath";
import useF360Path from "./path/f360Path";
// import searchArbitragePath from "./path/searchArbitragePath";
import cmsPath from "./path/cmsPath";
import awicPath from "./path/awicPath";
import iCampaigns from "./path/iCampaigns";
import twitterPath from "./path/twitterPath";
import adLibraryPath from "./path/adLibraryPath";
import g360Path from "./path/g360Path";
// import slackPath from "./path/slackPath";
import trackerPath from "./path/trackerPath";
import tiktokPath from "./path/tiktokPath";
import pinterestPath from "./path/pinterestPath";
import useFb360Path from "./path/fb360Path";
import userPath from "./path/userPath";
import hrmsPath from "./path/hrmsPath";
import useScorecardPath from "./path/useScorecardPath";
import useLeadgenPath from "./path/useLeadgenPath";
import useSasPath from "./path/useSasPath";
import useViralSparksPath from "./path/viralSparksPath";
import useUmsPath from "./path/useUmsPath";
import useVaroziPath from "./path/useVaroziPath";
import toolsPath from "./path/toolsPath";
import useUnicornPath from "./path/useUnicornPath";
import crsPath from "./path/crsPath";
import { useSelector } from "react-redux";
import creativePath from "./path/creativePath";
import recommendedRulePath from "./path/recommendedRulePath";
import industryInsightsPath from "./path/industryInsightsPath";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";

// import launchPath from "./path/launchPath";
const customeIconClassName = "anticon anticon-schedule side-menu-item-img";
export const CustomeIconWrapper = ({ children, className = "" }) => (
  <span className={cx(customeIconClassName, className)}>{children}</span>
);
export const SubIconWrapper = ({ children, className = "" }) => (
  <span className={cx("anticon anticon-team", className)}>{children}</span>
);

export default function useDashboardRoutes() {
  const { leadgenPath = [] } = useLeadgenPath();
  const { sasPath = [] } = useSasPath();
  const { viralSparksPath } = useViralSparksPath();
  const { fb360Path = [] } = useFb360Path();
  const { scorecardPath = [] } = useScorecardPath();
  const { unicornPath = [] } = useUnicornPath();
  const { umsPath = [] } = useUmsPath();
  const { varoziPath = [] } = useVaroziPath();
  const { isViewMilestone = false, user_id } = useSelector((state) => state?.userReducer.me) || {};
  const isMilestonesRoute = isViewMilestone ? unicornPath : [];

  const isCreativePath = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id) ? [] : creativePath;

  const DashboardRoutesPath = [
    ...dashboardPath,
    // ...iTaskPath,
    // ...dmsPath,
    // ...iQlicksPath, // - UNCOMMENT
    // ...f360Path,
    ...fb360Path,
    ...g360Path,
    ...leadgenPath,
    ...sasPath,
    ...hrmsPath,
    ...viralSparksPath,
    ...cmsPath,
    ...awicPath,
    ...iCampaigns,
    // ...twitterPath,
    ...pinterestPath,
    // ...slackPath,FOR FUTURE DEVELOPMENT
    ...tiktokPath,
    ...adLibraryPath,
    ...varoziPath,
    ...trackerPath,
    ...isCreativePath,
    ...umsPath,
    ...toolsPath,
    ...userPath,
    ...crsPath,
    ...scorecardPath,
    ...isMilestonesRoute,
    ...recommendedRulePath,
    ...industryInsightsPath,
    // ...launchPath,
  ];
  return { DashboardRoutesPath };
}
