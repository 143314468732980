import { Col, Row } from "antd";
import React, { useState } from "react";
import { faqSASData } from "./faq";

export default function FAQ() {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="faq-container">
      <div className="faq-header">
        <h2 className="faq-title">Frequently Asked Questions</h2>
      </div>
      <div className="faq-list">
        {faqSASData?.map(({ question, answer }, index) => (
          <div key={index} className="faq-item">
            <Row gutter={12}>
              <Col>
                <h3
                  className={`faq-question ${expandedIndex === index ? "expanded" : ""}`}
                  onClick={() => handleToggle(index)}
                >
                  {index + 1}. {question}
                </h3>
                {expandedIndex === index && <p className="faq-answer">{answer}</p>}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
}
