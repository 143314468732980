import { Col, Form, Row } from "antd";
import LookButton from "components/LookButton";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";
import { Formik } from "formik";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React from "react";
import { useSelector } from "react-redux";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_TEXT } from "shared/SharedKeys/launchKeys";
import { GET_VOLUUM_CATEGORY } from "shared/gql/gqlSchema/fb360Gql";
import { GET_SAS_CATEGORIES } from "shared/gql/gqlSchema/sasGql";
import { GET_VS_ALL_CONTENT_BY_ADVETTISER } from "shared/gql/gqlSchema/viralSparksGql";
import MediaItem from "../../Category/CreativeBuilder/MediaItem";

export default function AddMediaModal(props) {
  const {
    intialValues = {},
    validationSchema = {},
    mediaTypeName = () => {},
    mediaType = "",
    categories = [],
    setCategories = () => {},
    handleCloseDrawer = () => {},
    handleMediaSubmit = () => {},
    uploadView = () => {},
    uploadLoading = false,
    modalUploadData = [],
    setModalUploadData = () => {},
    loading = false,
    isMediaLibrary = false,
    isCreativeBuilder = false,
    isMediaLibrary_cb = false,
  } = props;

  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);

  const [fetchVsContent, { loading: vs_content__loading, vs_content_refetch }] = useCustomLazyQuery(
    GET_VS_ALL_CONTENT_BY_ADVETTISER,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.vs_contentGetAllByAdvertiserId?.data?.map((element) => ({
          name: element?.nickname,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    }
  );

  const [fetchSasCategory, { loading: sas_loading, error, refetch }] = useCustomLazyQuery(
    GET_SAS_CATEGORIES,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.sasCategories?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    }
  );
  const [fetchLeadgen, { loading: leadgen_loading, leadgen_error, leadgen_refetch }] =
    useCustomLazyQuery(GET_VOLUUM_CATEGORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.voluum_get_category?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });

  const onSubmit = (values) => {
    handleMediaSubmit(values);
  };
  const handleChange = (data, index) => {
    const updatedItem = { ...modalUploadData[index], file_name: data };
    setModalUploadData((prev) => {
      const newArray = [...prev];
      newArray[index] = updatedItem;
      return newArray;
    });
  };

  return (
    <>
      <Formik initialValues={intialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form layout="vertical">
            <>
              {mediaType === CREATIVE_TYPE_TEXT && (
                <Row gutter={12}>
                  <Col span={24}>
                    <LookTextInput
                      formik
                      name="text"
                      label="Enter Text"
                      placeholder="Enter text here"
                      className="look-form-input"
                      type="textarea"
                    />
                  </Col>
                </Row>
              )}
              {values?.fileName?.length && (
                <Row gutter={12}>
                  <Col span={24}>
                    <LookTextInput
                      formik
                      name="filename"
                      required
                      label="File Name"
                      placeholder="Enter File name"
                      className="look-form-input"
                    />
                  </Col>
                </Row>
              )}
              {!isMediaLibrary && !isCreativeBuilder && !isMediaLibrary_cb && (
                <>
                  {values?.productId === PRODUCT_ID_VS && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          formik
                          name="advertiser"
                          label="Advertiser"
                          required
                          placeholder="Select Advertiser"
                          search
                          className="look-form-input"
                          onChange={(name, value) => {
                            setFieldValue("advertiser", value);

                            fetchVsContent({ variables: { advertiser_id: value } });
                          }}
                          options={vsAdvertiser?.map(({ id, name }) => ({ name, value: id }))}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row gutter={12}>
                    <Col span={24}>
                      <SelectInput
                        formik
                        name="productId"
                        label="Product"
                        required
                        placeholder="Select products"
                        className="look-form-input"
                        value={values?.productId?.toString()}
                        onChange={(name, value) => {
                          setFieldValue("productId", parseInt(value));
                          if (parseInt(value) === PRODUCT_ID_SAS) {
                            fetchSasCategory();
                          } else if (parseInt(value) === PRODUCT_ID_LEADGEN) {
                            fetchLeadgen();
                          }
                        }}
                        options={productList?.map((prod) => ({
                          name: prod?.name,
                          value: prod?.id,
                        }))}
                      />
                    </Col>
                  </Row>
                  {values?.productId && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          formik
                          name="categoryId"
                          required
                          search
                          label="Category"
                          placeholder="Select category"
                          className="look-form-input"
                          loader={vs_content__loading || sas_loading || leadgen_loading}
                          onChange={(name, value) => {
                            setFieldValue("categoryId", value);
                            setFieldValue(
                              "categoryName",
                              categories?.find((category) => category?.value === value)?.name
                            );
                          }}
                          options={categories}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <>
                {modalUploadData?.length === 0 && (
                  <>
                    {!(mediaType === CREATIVE_TYPE_TEXT) && (
                      <div style={{ height: "150px" }} className="look-form-input">
                        {uploadLoading ? <Loader elementCenter /> : uploadView()}
                      </div>
                    )}
                  </>
                )}

                {modalUploadData?.map((data, index) => {
                  return (
                    <div className="pb-10 d-flex align-items-center justify-content-between">
                      {/* TODO THIS INLINE CSS WILL BE  REMOVE  */}
                      <div key={"mediaItems" + index} style={{ width: "200px" }} className="mr-8">
                        <MediaItem
                          // Spread the 'data' object as separate props
                          {...data}
                          creativeType={mediaType}
                          creative={{
                            id: index,
                            thumbUrl: data?.thumbUrl,
                            url:
                              mediaType === CREATIVE_TYPE_IMAGE
                                ? data?.public_url
                                : data?.video_url,
                          }}
                          isTemplateForm
                          setModalUploadData={setModalUploadData}
                          isMediaUploadModal
                          addMediaModalindex={index}
                        />
                      </div>
                      {/* TODO THIS INLINE CSS WILL BE  REMOVE  */}
                      <div style={{ width: "calc(100% - 200px)" }}>
                        <p className="mb-0 mb-5" style={{ color: " #ACACAC" }}>
                          File name
                        </p>
                        <LookTextInput
                          name="fileName"
                          type="text"
                          className="mb-2"
                          value={data?.file_name || ""}
                          onChange={(e) => {
                            handleChange(e.target.value, index);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
              <Row gutter={12}></Row>
              <Form.Item>
                <Row gutter={12}>
                  <Col span={12}>
                    <LookButton
                      htmlType="cancel"
                      block
                      type="default"
                      onClick={() => handleCloseDrawer()}
                    >
                      Cancel
                    </LookButton>
                  </Col>
                  <Col span={12}>
                    <LookButton
                      htmlType="submit"
                      block
                      type="primary"
                      onClick={handleSubmit}
                      loading={loading}
                    >
                      Upload {mediaTypeName(mediaType)}
                    </LookButton>
                  </Col>
                </Row>
              </Form.Item>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
}
