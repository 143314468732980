import LookButton from "components/LookButton";
import React, { useRef, useState, useEffect } from "react";

const CustomAudioPlayer = ({ audioData, audioIndex, index, setAudio, id, audioUrl }) => {
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handleLoadedMetadata = () => {
      setDuration(audioElement.duration);
    };

    audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audioElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [audioRef]);

  const playAudio = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  const pauseAudio = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  return (
    <div className="custom-audio-player">
      <audio className="audio-player-creative-builder" ref={audioRef}>
        <source src={audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {/* <audio controls>
        <source src={audioUrl} type="audio/mpeg" />
      </audio> */}
      <div className="main-controls-div">
        <div className="controls">
          <span>{duration.toFixed(2)} s</span>
        </div>
        <div className="controls-icons">
          <LookButton
            size="small"
            tooltipTitle={isPlaying ? "Pause Audio" : "Play Audio"}
            iconType={isPlaying ? "pause" : "play"}
            style={{ border: "none" }}
            onClick={() => {
              isPlaying ? pauseAudio() : playAudio();
            }}
          />
          {audioData.length > 1 && (
            <LookButton
              style={{ border: "none" }}
              size="small"
              tooltipTitle="Delete This Element"
              iconType="delete-filled"
              onClick={() => {
                // removeAudioFromNestedAudioList(audioIndex,index )
                setAudio((audio) => {
                  const updatedItems = [...audio];
                  updatedItems[audioIndex] = audio?.[audioIndex]?.filter(
                    (audioData) => audioData?.id !== id
                  );
                  return updatedItems;
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CustomAudioPlayer;
