import React, { useEffect, useRef, useState } from "react";
import CanvasActions from "./CanvasActions";
import { Col, Row } from "antd";
import { throttle } from "lodash";
import useUploadFiles from "../useUploadFiles";
import { templates } from "./contant";

export default function CanvasComponent({ filesBlob, getImageResponse }) {
  const [backgroundColor, setBackgroundColor] = useState("ffffff"); // Default black background
  const [textFillColor, setTextFillColor] = useState("#ffffff"); // Default text color
  const [template, setTemplate] = useState(0);
  const [images, setImages] = useState([]);
  const [uploading, seUploading] = useState(false);
  const [canvastext, setCanvasText] = useState([]);
  const canvasRef = useRef(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState();
  const [isDraggingImage, setIsDraggingImage] = useState(false);
  // Track initial position for dragging
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [initialMouseY, setInitialMouseY] = useState(0);
  const [initialImageXOffset, setInitialImageXOffset] = useState(0);
  const [initialImageYOffset, setInitialImageYOffset] = useState(0);
  const [dragSpeed] = useState(2);
  // template
  const [filteredTemplates, setTemplates] = useState([]);
  const [constTemplates] = useState(templates);

  const { handleBlobUpload, uploadLoading } = useUploadFiles({ name: "image" });

  const handleUpload = async () => {
    seUploading(true);
    const timestamp = Date.now(); // Get the current timestamp
    const collageName = `${timestamp}-collage.png`; // Unique thumbnail name with timestamp

    const canvas = canvasRef.current;
    const targetWidth = 1080;
    const targetHeight = 1080;

    // Create a new canvas with the desired resolution
    const newCanvas = document.createElement("canvas");
    newCanvas.width = targetWidth;
    newCanvas.height = targetHeight;
    const ctx = newCanvas.getContext("2d");

    // Draw the original canvas image onto the new canvas with the desired resolution
    ctx.drawImage(canvas, 0, 0, canvas?.width, canvas?.height, 0, 0, targetWidth, targetHeight);

    // Convert the new canvas to a blob
    newCanvas.toBlob(async (blob) => {
      if (blob) {
        await handleBlobUpload([blob], [collageName]).then((data) => {
          getImageResponse(data);
        });
        seUploading(false);
      }
    });
  };

  function calculateCropValues(img, xOffset = 0, yOffset = 0, dWidth, dHeight) {
    const sourceAspectRatio = img.width / img.height;
    const destinationAspectRatio = dWidth / dHeight;
    let sx, sy, sWidth, sHeight;
    if (sourceAspectRatio > destinationAspectRatio) {
      // Source is wider than destination, crop from left and right
      sWidth = img?.height * destinationAspectRatio;
      sHeight = img?.height;
      sx = (img?.width - sWidth) / 2;
      sy = 0;
    } else {
      // Source is taller than destination, crop from top and bottom
      sWidth = img?.width;
      sHeight = img?.width / destinationAspectRatio;
      sx = 0;
      sy = (img?.height - sHeight) / 2;
    }
    return { img, sx: sx + xOffset, sy: sy + yOffset, sWidth, sHeight };
  }

  const drawImages = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = backgroundColor;
    const templateImageObject =
      filteredTemplates?.length &&
      filteredTemplates[template].template_features?.template.filter(
        (element) => element?.type === "image"
      );

    const loadedImages = await Promise.all(
      templateImageObject?.map(async (element, index) => {
        if (images[index]) {
          const { img, xOffset, yOffset } = images[index];
          return calculateCropValues(
            img,
            xOffset * dragSpeed,
            yOffset * dragSpeed,
            element?.width,
            element?.height
          );
        }
        return {};
      })
    );

    const loadText = () =>
      canvastext.map((element, index) => {
        new Promise((resolve) => {
          ctx.fillStyle = element?.color || textFillColor;
          ctx.font = element?.font || "16px Arial";
          // ctx.textAlign = element?.textAlign || "center";
          ctx.fillText(element?.text || "", element?.x_cordinate, element?.y_cordinate);
          resolve();
        });
      });

    filteredTemplates?.length &&
      filteredTemplates[template].template_features?.template.forEach(async (element, index) => {
        switch (element.type) {
          case "image":
            const { img, sx, sy, sWidth, sHeight } = loadedImages.shift();
            if (img) {
              ctx.drawImage(
                img,
                sx,
                sy,
                sWidth,
                sHeight,
                element.x_cordinate,
                element.y_cordinate,
                element?.width,
                element?.height
              );
            }
            break;
          case "box":
            ctx.fillStyle = element?.color;
            ctx.globalAlpha = element?.transparency;
            ctx.fillRect(
              element?.x_cordinate,
              element?.y_cordinate,
              element?.width,
              element?.height
            );
            ctx.globalAlpha = 1;
            break;
          case "text":
            await loadText();
            break;
          default:
            break;
        }
      });
  };

  const shuffleImages = () => {
    const shuffledFiles = [...filesBlob];
    for (let i = shuffledFiles.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledFiles[i], shuffledFiles[j]] = [shuffledFiles[j], shuffledFiles[i]]; // Swap elements randomly
    }
    const shuffled = shuffledFiles.map(({ img }) => {
      return { img, xOffset: 0, yOffset: 0 };
    });
    setImages(shuffled);
  };

  const handleColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleTemplateChange = (e) => {
    setTemplate(e);
  };
  const handleTextFillColorChange = (e) => {
    setTextFillColor(e.target.value);
  };

  const throttledHandleRedraw = throttle(() => {
    drawImages();
  }, 1000);

  const handleTextChange = (index, text) => {
    setCanvasText((prevText) => {
      const updatedText = [...prevText];
      updatedText[index].text = text;
      return updatedText;
    });
  };

  const handleDownloadCanvas = async () => {
    const canvas = canvasRef.current;
    const targetWidth = 1080;
    const targetHeight = 1080;

    // Create a new canvas with the desired resolution
    const newCanvas = document.createElement("canvas");
    newCanvas.width = targetWidth;
    newCanvas.height = targetHeight;
    const ctx = newCanvas.getContext("2d");

    // Draw the original canvas image onto the new canvas with the desired resolution
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, targetWidth, targetHeight);

    // Convert the new canvas to a blob
    newCanvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "canvas-image.png";
        link.click();
        URL.revokeObjectURL(url);
      }
    });
  };

  // const handleMouseMove = (event) => {
  //   const canvas = canvasRef.current;
  //   const canvasRect = canvas.getBoundingClientRect();

  //   const currentMouseX = (event.clientX - canvasRect.left) * (canvas.width / canvasRect.width);
  //   const currentMouseY = (event.clientY - canvasRect.top) * (canvas.height / canvasRect.height);

  //   // Calculating in steps of 10 pixels
  //   if (isDraggingImage) {
  //     const offsetX = dragSpeed * Math.floor((initialMouseX - currentMouseX) / dragSpeed);
  //     const offsetY = dragSpeed * Math.floor((initialMouseY - currentMouseY) / dragSpeed);

  //     // Update offsets for the selected image
  //     setImages((prevImages) => {
  //       const updatedImages = [...prevImages];
  //       updatedImages[selectedImageIndex].xOffset = initialImageXOffset + offsetX;
  //       updatedImages[selectedImageIndex].yOffset = initialImageYOffset + offsetY;
  //       return updatedImages;
  //     });

  //     throttledHandleRedraw();
  //   }
  // };

  const handleMouseMove = (event) => {
    const canvas = canvasRef.current;
    const canvasRect = canvas?.getBoundingClientRect();

    const currentMouseX = (event?.clientX - canvasRect?.left) * (canvas?.width / canvasRect?.width);
    const currentMouseY =
      (event?.clientY - canvasRect?.top) * (canvas?.height / canvasRect?.height);

    // Calculating in steps of 10 pixels
    if (isDraggingImage) {
      const offsetX = dragSpeed * Math.floor((initialMouseX - currentMouseX) / dragSpeed);
      const offsetY = dragSpeed * Math.floor((initialMouseY - currentMouseY) / dragSpeed);

      // Update offsets for the selected image
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages[selectedImageIndex].xOffset = initialImageXOffset + offsetX;
        updatedImages[selectedImageIndex].yOffset = initialImageYOffset + offsetY;
        return updatedImages;
      });

      throttledHandleRedraw();
    }
  };

  const handleMouseDown = (event) => {
    setIsDraggingImage(true);
    const canvas = canvasRef.current;
    const canvasRect = canvas.getBoundingClientRect();

    const mouseX = (event.clientX - canvasRect.left) * (canvas.width / canvasRect.width);
    const mouseY = (event.clientY - canvasRect.top) * (canvas.height / canvasRect.height);

    const templateImageObject =
      filteredTemplates?.length &&
      filteredTemplates[template].template_features?.template.filter(
        (element) => element.type === "image"
      );

    let selectedImageIndex = null;

    templateImageObject?.forEach((element, index) => {
      if (
        mouseX > element?.x_cordinate &&
        mouseX < element?.x_cordinate + element?.width &&
        mouseY > element?.y_cordinate &&
        mouseY < element?.y_cordinate + element?.height
      ) {
        selectedImageIndex = index;
        return;
      }
    });
    // Store initial position and offsets
    setInitialMouseX(mouseX);
    setInitialMouseY(mouseY);
    if (
      images[selectedImageIndex] &&
      images[selectedImageIndex].xOffset &&
      images[selectedImageIndex].yOffset
    ) {
      setInitialImageXOffset(images[selectedImageIndex].xOffset);
      setInitialImageYOffset(images[selectedImageIndex].yOffset);
    }
    // set selected image index
    setSelectedImageIndex(selectedImageIndex);
  };

  const handleMouseUp = () => {
    setIsDraggingImage(false);
  };

  useEffect(() => {
    const totalImages = filesBlob?.length;
    const filteredTemplate = constTemplates.filter((element) => {
      return element?.noOfImages <= totalImages;
    });
    setTemplates(filteredTemplate);
  }, [constTemplates, filesBlob]);

  useEffect(() => {
    filteredTemplates?.length && filesBlob?.length && throttledHandleRedraw();
  }, [images, textFillColor]);

  useEffect(() => {
    setImages(filesBlob);
    if (filteredTemplates?.length) {
      const templateTextObject = filteredTemplates[template].template_features?.template.filter(
        (element) => element.type === "text"
      );
      setCanvasText([...templateTextObject]);
    }
    shuffleImages();
    filteredTemplates?.length && filesBlob?.length && throttledHandleRedraw();
  }, [template, filteredTemplates, filesBlob]);

  useEffect(() => {
    filteredTemplates?.length && filesBlob?.length && throttledHandleRedraw();
  }, [canvastext]);

  return (
    <Row gutter={24}>
      <Col span={8}>
        <CanvasActions
          // backgroundColor={backgroundColor}
          onColorChange={handleColorChange}
          onTemplateChange={handleTemplateChange}
          template={template}
          textFillColor={textFillColor}
          onTextFillColorChange={handleTextFillColorChange}
          textArray={canvastext}
          onTextChange={handleTextChange}
          onShuffleImages={shuffleImages}
          handleDownloadCanvas={handleDownloadCanvas}
          handleUpload={handleUpload}
          uploadLoading={uploading}
          images={images}
          filteredTemplates={filteredTemplates}
        />
      </Col>
      <Col span={16}>
        <canvas
          ref={canvasRef}
          width={800}
          height={600}
          style={{
            border: "1px solid",
            backgroundColor: `${backgroundColor}`,
            width: "100%",
            height: "100%",
            cursor: "grab",
          }}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        ></canvas>
      </Col>
    </Row>
  );
}
