import TabRender from "components/TabRender";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import {
  ADD_MOODBOARD_ANGLES,
  ADD_MOODBOARD_COPY_POINTS,
  ADD_MOODBOARD_CTA,
  ADD_MOODBOARD_HOOKS,
  ADD_MOODBOARD_PAIN_POINTS,
  ADD_MOODBOARD_SCRIPTS,
  CREATE_REQUEST_TRACKING,
  DELETE_MOODBOARD_ANGLE,
  DELETE_MOODBOARD_COPYPOINT,
  DELETE_MOODBOARD_CTA,
  DELETE_MOODBOARD_HOOKS,
  DELETE_MOODBOARD_PAINPOINT,
  DELETE_MOODBOARD_SCRIPTS,
  GET_CREATIVE_COMMENT,
  GET_MOODBOARD_ANGLES,
  GET_MOODBOARD_COPY_POINTS,
  GET_MOODBOARD_CTA,
  GET_MOODBOARD_HOOKS,
  GET_MOODBOARD_PAIN_POINTS,
  GET_MOODBOARD_SCRIPTS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import MoodBoard from "../Category/CreateMoodBoard";
import RaiseRequestForm from "../Category/RaiseRequest";
import CreativeCommentForm from "../CreativeComments/CreativeCommentForm";
import {
  MOODBOARD_CREATIVE_TYPE_REQUEST,
  MOODBOARD_TEMPLATE_TYPE_REQUEST,
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COMPLIANCE,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_PAIN_POINTS,
  MOODBOARD_TYPE_SCRIPT,
  STATUS_CLOSED,
  STATUS_DONE,
  STATUS_DONE_CD,
  STATUS_SUBMITTED_CD,
} from "../creativesEnum";
import Media from "../MediaLibrary/Media";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import ComplianceForm from "../Category/CreateMoodBoard/ComplianceForm";
import { useSelector } from "react-redux";
import { API_STORE_VIRALSPARKS_ADVERTISE } from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
function RequestView(props) {
  const {
    categoryId,
    categoryName,
    // // isGraphicDesigner = false,
    // // isMediaBuyer = false,
    // // isCreativeDirector = false,
    requestData,
    requestType,
    isCreativeRequest,
    onCloseDrawer = () => {},
    isGraphicDesigner = false,
    requestListrefetch = () => {},
  } = props;
  const [activeTab, setActiveTab] = useState("RequestDetails");
  const users = useSelector((state) => state?.userReducer?.me);
  const [activeMoodboardTab, setActiveMoodboardTab] = useState("CopyPoints");
  const [allowedStatus, setAllowedStatus] = useState();
  const { apiCall: vsAdvertiserFetch = () => {}, refetch: vsAdvertiserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_ADVERTISE
  );
  const tabProps = {
    id: categoryId,
    name: categoryName,
    productId: requestData?.productId,
    isRequestView: true,
  };

  useEffect(() => {
    vsAdvertiserFetch();
  }, []);

  const tabContentMoodboard = [
    {
      id: "CopyPoints",
      noSpace: true,
      hoverTitle: "CopyPoints",
      icon: (e) => <ActionIconRender iconType="rectangleList" {...e} />,
      component: activeMoodboardTab === "CopyPoints" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_COPY_POINTS}
          query={GET_MOODBOARD_COPY_POINTS}
          mutation={ADD_MOODBOARD_COPY_POINTS}
          apiDataType="ctGetCopyPoints"
          placeHolderKey="CopyPoints"
          {...tabProps}
          deleteQuery={DELETE_MOODBOARD_COPYPOINT}
        />
      ),
    },
    {
      id: "Angles",
      noSpace: true,
      hoverTitle: "Angles",
      icon: (e) => <ActionIconRender iconType="sunAngle" {...e} />,
      component: activeMoodboardTab === "Angles" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_ANGLES}
          query={GET_MOODBOARD_ANGLES}
          apiDataType="ctGetAngles"
          placeHolderKey="Angles"
          mutation={ADD_MOODBOARD_ANGLES}
          {...tabProps}
          deleteQuery={DELETE_MOODBOARD_ANGLE}
        />
      ),
    },
    {
      id: "Hooks",
      noSpace: true,
      hoverTitle: "Hooks",
      icon: (e) => <ActionIconRender iconType="hook" {...e} />,
      component: activeMoodboardTab === "Hooks" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_HOOKS}
          query={GET_MOODBOARD_HOOKS}
          placeHolderKey="Hooks"
          mutation={ADD_MOODBOARD_HOOKS}
          apiDataType="ctGetHooks"
          {...tabProps}
          deleteQuery={DELETE_MOODBOARD_HOOKS}
        />
      ),
    },
    {
      id: "PainPoints",
      hoverTitle: "Pain Points",
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="pain" {...e} />,
      component: activeMoodboardTab === "PainPoints" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_PAIN_POINTS}
          query={GET_MOODBOARD_PAIN_POINTS}
          placeHolderKey="PainPoints"
          mutation={ADD_MOODBOARD_PAIN_POINTS}
          deleteQuery={DELETE_MOODBOARD_PAINPOINT}
          apiDataType="ctGetPainPoints"
          {...tabProps}
        />
      ),
    },
    {
      id: "CTA",
      noSpace: true,
      hoverTitle: "CTA",
      icon: (e) => <ActionIconRender iconType="cta" {...e} />,
      component: activeMoodboardTab === "CTA" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_CTA}
          query={GET_MOODBOARD_CTA}
          mutation={ADD_MOODBOARD_CTA}
          placeHolderKey="CTA"
          apiDataType="ctGetCTAs"
          deleteQuery={DELETE_MOODBOARD_CTA}
          {...tabProps}
        />
      ),
    },
    {
      id: "Scripts",
      hoverTitle: "Scripts",
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="script-outline" {...e} />,
      component: activeMoodboardTab === "Scripts" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_SCRIPT}
          query={GET_MOODBOARD_SCRIPTS}
          apiDataType="ctGetScripts"
          placeHolderKey="Scripts"
          mutation={ADD_MOODBOARD_SCRIPTS}
          {...tabProps}
          isRequestView
          deleteQuery={DELETE_MOODBOARD_SCRIPTS}
        />
      ),
    },
    {
      id: "Compliance",
      hoverTitle: "Compliance",
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="compliance" {...e} />,
      component: activeMoodboardTab === "Compliance" && (
        <ComplianceForm
          actionType={MOODBOARD_TYPE_COMPLIANCE}
          query={GET_MOODBOARD_CTA}
          placeHolderKey="Compliance"
          apiDataType="ctGetCTAs"
          {...tabProps}
        />
      ),
    },
  ];
  const [allComments, setAllComments] = useState([]);
  const { loading: comments_loading, refetch: comments_refetch } = useCustomQuery(
    GET_CREATIVE_COMMENT,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const comments = e?.ctGetCreativeRequestHistory?.data;
        if (comments) {
          setAllComments(comments);
        }
      },
      variables: {
        requestId: requestData?.id,
        productId: requestData?.productId,
        categoryId: requestData?.categoryId,
      },
    }
  );
  const [raiseRequest, { loading }] = useCustomMutation(CREATE_REQUEST_TRACKING, {
    onCompleted(e) {
      const element = e?.ctAddRequest;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        onCloseDrawer();
        requestListrefetch();
      } else {
        openNotification({ type: "error", message: element?.message, key: "add-req-error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });
  function handleCloseStatus(status) {
    if (status === STATUS_SUBMITTED_CD) {
      if (props?.requestData?.createdBy?.toString() === users?.user_id) return STATUS_CLOSED;
      else return STATUS_DONE_CD;
    } else if (status === STATUS_DONE_CD) {
      if (props?.requestData?.createdBy?.toString() === users?.user_id) return STATUS_CLOSED;
      else return STATUS_DONE;
    } else {
      return status;
    }
  }
  function handleSubmit(requestData) {
    const {
      advertiser,
      assignTo,
      offer,
      priority,
      expectedDueDate,
      productId,
      status,
      categoryId,
      created_date = "",
      categoryName,
      category_id = "",
      category_name = "",
      template_urls = [],
      request_id = "",
      request_name = "",
      creative_type = "",
      requestStatus = "",
      templateIds = [],
      created_by,
      deadline,
      imageIds = [],
      videoIds = [],
      ...requestDetailProps
    } = requestData;
    const finalRequestStatus =
      requestStatus && requestStatus.includes(".") ? requestStatus.split(".")[1] : requestStatus;
    const visibleStatus = allowedStatus?.find(
      (status) => status?.assigned_status === handleCloseStatus(finalRequestStatus)
    )?.visible_status;
    const variables = {
      requestInput: {
        advertiser,
        assignTo: { userId: parseInt(assignTo?.user_id), name: assignTo?.name },
        offer,
        expectedDueDate,
        priority,
        categoryId,
        categoryName,
        productId,
        templateIds,
        imageIds,
        videoIds,
        history: [` status from ${props?.status} to ${visibleStatus}`],
        requestStatus: handleCloseStatus(finalRequestStatus),
        requestType: isCreativeRequest
          ? MOODBOARD_CREATIVE_TYPE_REQUEST
          : MOODBOARD_TEMPLATE_TYPE_REQUEST,
        requestDetails: {
          ...requestDetailProps,
          requestName: request_name,
          creativeType: creative_type,
        },
        ...(requestData?.id && { id: requestData?.id }),
      },
    };
    const validation = requestData?.creatives?.length > 0;

    if (validation) {
      raiseRequest({
        variables,
      });
    } else {
      openNotification({ type: "error", message: "Please upload creatives before submitting" });
    }
  }
  const renderCommentView = () => {
    return (
      <CreativeCommentForm
        refetch={comments_refetch}
        loading={comments_loading}
        allComments={allComments}
        setAllComments={setAllComments}
        {...props}
        // {...permissionProps}
        requestData={requestData}
      />
    );
  };
  const tabContent = [
    {
      id: "RequestDetails",
      title: "Request Details",
      hoverTitle: "Request Details",
      noSpace: true,
      component: activeTab === "RequestDetails" && (
        <div className="creative-request-container">
          <div className="creative-content-view">
            <RaiseRequestForm {...props} isRequestView handleSubmit={handleSubmit} />
          </div>
          <div className="creative-comment-view">{renderCommentView()}</div>
        </div>
      ),
    },
    {
      id: "Moodboard",
      title: "Moodboard",
      hoverTitle: "Moodboard",
      noSpace: true,
      component: activeTab === "Moodboard" && requestType !== MOODBOARD_TEMPLATE_TYPE_REQUEST && (
        <div className="creative-request-container">
          <div className="creative-content-view">
            <MoodBoard
              actionType={MOODBOARD_TYPE_SCRIPT}
              query={GET_MOODBOARD_SCRIPTS}
              mutation={ADD_MOODBOARD_SCRIPTS}
              deleteQuery={DELETE_MOODBOARD_SCRIPTS}
              apiDataType="ctGetScripts"
              placeHolderKey="Scripts"
              {...props}
              id={categoryId}
              name={categoryName}
              requestScripts={requestData?.scripts?.map((script) => script?.id)}
              isRequestView
              handleSubmit={handleSubmit}
            />
          </div>
          <div className="creative-comment-view"> {renderCommentView()}</div>
        </div>
      ),
    },
    {
      id: "CreativeReview",
      title: isGraphicDesigner ? "Upload Creatives" : "Creative Review",
      hoverTitle: "Creative Review",
      noSpace: true,
      component: activeTab === "CreativeReview" && (
        <div className="creative-request-container">
          <div className="creative-content-view">
            <Media
              {...props}
              allowedStatus={allowedStatus}
              setAllowedStatus={setAllowedStatus}
              isRequestView
              requestType
              handleSubmit={handleSubmit}
              creativeType={requestData?.creative_type}
            />
            {/* {requestType === MOODBOARD_TEMPLATE_TYPE_REQUEST ? (
              <TemplateUpload
                {...props}
                isRequestView
                allowedStatus={allowedStatus}
                setAllowedStatus={setAllowedStatus}
                handleSubmit={handleSubmit}
              />
            ) : (
              <Media
                {...props}
                allowedStatus={allowedStatus}
                setAllowedStatus={setAllowedStatus}
                isRequestView
                handleSubmit={handleSubmit}
                creativeType={requestData?.creative_type}
              />
            )} */}
          </div>
          <div className="creative-comment-view"> {renderCommentView()}</div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <TabRender
        className="top-bar"
        fullBtn
        noOperations
        tabContent={tabContent}
        value={activeTab}
        onChange={setActiveTab}
      />
      {/* using tabs and drawer side bar together, will fix it in future */}
      {activeTab === "Moodboard" && requestType === MOODBOARD_TEMPLATE_TYPE_REQUEST && (
        <>
          <div className="creative-request-container">
            <div className="creative-content-view">
              <LookDrawerMainSideBar
                value={activeMoodboardTab}
                onChange={setActiveMoodboardTab}
                tabContent={tabContentMoodboard}
              />
            </div>
            <div className="creative-comment-view"> {renderCommentView()}</div>
          </div>
        </>
      )}
    </div>
  );
}
export default RequestView;
