export const MOODBOARD_TYPE_COPY_POINTS = "COPY_POINTS";
export const MOODBOARD_TYPE_ANGLES = "ANGLES";
export const MOODBOARD_TYPE_HOOKS = "HOOKS";
export const MOODBOARD_TYPE_PAIN_POINTS = "PAIN_POINTS";
export const MOODBOARD_TYPE_SCRIPT = "SCRIPTS";
export const MOODBOARD_TYPE_COMPLIANCE = "COMPLIANCE";
export const MOODBOARD_TYPE_CTA = "CTA";
export const MOODBOARD_TYPE_DESCRIPTION = "DESCRIPTION";
export const MOODBOARD_TYPE_LANGUAGE = "LANGUAGE";
export const MOODBOARD_CREATIVE_TYPE_REQUEST = "CREATIVE";
export const MOODBOARD_TEMPLATE_TYPE_REQUEST = "TEMPLATE";

// Status for Creative Tracking
export const STATUS_NEW = "NEW";
export const STATUS_IN_PROGRESS = "IN_PROGRESS";
export const STATUS_ASSIGNED = "ASSIGNED";
export const STATUS_FOR_APPROVAL = "FOR_APPROVAL";
export const STATUS_SUBMITTED = "SUBMITTED";
export const STATUS_NEEDS_CHANGE = "NEEDS_CHANGE";
export const STATUS_CLOSED = "CLOSED";
export const STATUS_ASSIGNED_CD = "ASSIGNED_CD";
export const STATUS_ASSIGNED_MB = "ASSIGNED_MB";
export const STATUS_FOR_APPROVAL_CD = "FOR_APPROVAL_CD";
export const STATUS_FOR_APPROVAL_MB = "FOR_APPROVAL_MB";
export const STATUS_IN_PROGRESS_CD = "IN_PROGRESS_CD";
export const STATUS_IN_PROGRESS_GD = "IN_PROGRESS_GD";
export const STATUS_IN_PROGRESS_MB = "IN_PROGRESS_MB";
export const STATUS_NEEDS_CHANGES_CD = "NEEDS_CHANGES_CD";
export const STATUS_NEEDS_CHANGES_MB = "NEEDS_CHANGES_MB";
export const STATUS_NEEDS_CHANGES_GD = "NEEDS_CHANGES_GD";
export const STATUS_NEW_CD = "NEW_CD";
export const STATUS_NEW_GD = "NEW_GD";
export const STATUS_NEW_MB = "NEW_MB";
export const STATUS_SUBMITTED_CD = "SUBMITTED_CD";
export const STATUS_SUBMITTED_GD = "SUBMITTED_GD";
export const STATUS_SUBMITTED_MB = "SUBMITTED_MB";
export const STATUS_DONE_CD = "DONE_CD";
export const STATUS_DONE = "DONE";

//User of different levels
export const USER_LEVEL_L1 = "L1";
export const USER_LEVEL_L2 = "L2";
export const USER_LEVEL_L3 = "L3";

//Comments enum
export const COMMENT_CHANGE_TYPE_COMMENT = "COMMENT";
export const COMMENT_CHANGE_TYPE_DELETE = "DELETE";
export const COMMENT_CHANGE_TYPE_UPDATE = "UPDATE";
export const STATUS_SUCCEDDED = "succeeded";
export const STATUS_PLANNED = "planned";
export const STATUS_RENDERING = "rendering";
export const STATUS_TRANSCRIBING = "transcribing";
export const STATUS_FAILED = "failed";

export const COMPLIANCE_ENUM = [
  // { name: "EXCEPTION", value: "EXCEPTION" },
  { name: "NON COMPLIANT", value: "NO" },
  // { name: "PARTIALLY", value: "PARTIALLY" },
  // { name: "PENDING", value: "PENDING" },
  { name: "COMPLIANT", value: "YES" },
];

export const FILTER_TYPE_CREATOR = "CREATOR";
export const FILTER_TYPE_CATEGORY = "CATEGORY";
export const FILTER_TYPE_DOMAINS = "DOMAINS";
export const FILTER_TYPE_USER = "USER";
export const FILTER_TYPE_SUBIDS = "SUB_ID";

//enum for ads type
export const ADS_TYPE_DEFAULT = "Ads";
export const ADS_TYPE_CATEGORY = "Category";
export const ADS_TYPE_CONTENT = "Content";
export const ADS_TYPE_PLATFORM = "Platform";
export const ADS_TYPE_USER = "User";
export const ADS_TYPE_ADACCOUNT = "AdAccount";

//enum for rev&spend api unique Keys
export const ADS_TYPE_CATEGORY_UNIQUE_KEY = "category_id";
export const ADS_TYPE_ADACCOUNT_UNIQUE_KEY = "adaccount_id";
export const ADS_TYPE_ADACCOUNT_UNIQUE_KEY_LEADGEN = "ad_account_id";
export const ADS_TYPE_ADACCOUNT_UNIQUE_KEY_LEADGEN_SPEND = "adaccount_id";
export const ADS_TYPE_PLATFORM_UNIQUE_KEY = "platform_id";
export const ADS_TYPE_DEFAULT_UNIQUE_KEY_SAS = "adId";
export const ADS_TYPE_DEFAULT_UNIQUE_KEY = "ad_id";

//enum for sort
export const SORT_TYPE_ASC = "ASC";
export const SORT_TYPE_DESC = "DESC";

//enum for creativeType

export const MY_IMAGES = "My Images";
export const MY_VIDEOS = "My Videos";
export const MY_TITLES = "My Headlines";
export const MY_BODIES = "My Bodies";
export const MY_DESC = "My Descriptions";
export const FEATURED = "Featured";
export const RECOMMENDED = "Recommended";
export const SELECTED_IMAGES = "Selected Images";
export const SELECTED_VIDEOS  = "Selected Videos";
export const SELECTED_ADGROUPS = "Selected Adgroups";

//createive builder ENUMS

export const SCRIPT = "SCRIPT";
export const VISUAL = "VISUAL";


