import { Button, Form, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";

import React, { useEffect, useImperativeHandle, useState } from "react";

import ExistingRule from "./ExistingRules";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { useLookTableParentRuleEngin } from "hooks/lookTableParentContext";

export default function AddRule(props) {
  // console.log(props);
  const [visible, setVisible] = useState(false);
  const [ids, setIds] = useState(null);

  // console.log(bulkRuleIds);
  const { ruleEgineChildRef } = useLookTableParentRuleEngin();

  function callBulkRuleData(selectedRecordData = [], extraProps = {}) {
    const {} = extraProps;
    setVisible(true);
    setIds(selectedRecordData);
  }
  useImperativeHandle(ruleEgineChildRef, () => {
    return {
      callBulkRuleData,
      testRule: (e) => {
        setVisible(true);
      },
    };
  });

  return (
    <div>
      <ExistingRule {...props} data={ids} />
    </div>
  );
}
