import { Col, Form, Row, Button } from "antd";
import { Tabs } from "antd/lib";
import TextArea from "antd/lib/input/TextArea";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";
import React, { useState } from "react";
import { MOODBOARD_TYPE_SCRIPT } from "../../creativesEnum";

function MainForm(props) {
  const {
    values = {},
    setValues = () => {},
    onClose = () => {},
    onSubmit = () => {},
    isCreateForm,
    actionType,
    tabContent,
    onChange,
    setIsEdit = () => {},
    placeHolderKey,
    setIsCreateForm = () => {},
    isCopyPoints,
    setActiveKey = () => {},
    activeKey,
  } = props;
  const [error, setErrors] = useState({});
  const errorCheck = () => {
    let err = {};
    if (!values.name) {
      err.name = "field cannot be empty";
    }
    if (Object.keys(err).length === 0) {
      onSubmit();
    } else {
      setErrors(err);
    }
  };
  return (
    <div
      className={cx("createForm", "w-100 px-12 pb-12", { isCreateForm })}
      style={{
        borderTop: "1px solid #303030",
        borderBottom: "1px solid #303030",
        paddingTop: "7px",
      }}
    >
      {
        <Form layout="vertical">
          <div
            className="d-flex mr-20"
            onClick={() => {
              setIsCreateForm(true);

              if (activeKey !== null) {
                setValues({});
                setActiveKey(null);
                setIsEdit(false);
              }
            }}
          >
            <div style={{ width: 26 }}>
              <ActionIconRender iconType="plus" className="mt-10" onChange={() => setValues({})} />
            </div>
            <div className="flex-grow-1 pt-5">
              <TextArea
                autoSize
                value={isCreateForm ? values?.name : ""}
                className={cx("moodBoard_form")}
                error={error.name}
                placeholder={`Click here to Add New ${placeHolderKey}`}
                onChange={(e) => {
                  if (e.target.value.length < 100) {
                    setValues((prevItems) => ({ ...prevItems, name: e.target.value }));
                  } else {
                    setErrors((prev) => ({
                      ...prev,
                      name: "Word limit exceeded",
                    }));
                  }
                }}
              />
              {error?.name && <span className="text-left error-text">{error?.name}</span>}
              {isCreateForm && !isCopyPoints && (
                <TextArea
                  autoSize
                  value={values?.description}
                  className={cx("moodBoard_form description")}
                  placeholder="Add Description"
                  onChange={(e) =>
                    setValues((prevItems) => ({ ...prevItems, description: e.target.value }))
                  }
                />
              )}
            </div>
          </div>
          {actionType === MOODBOARD_TYPE_SCRIPT && isCreateForm && (
            <div style={{ paddingLeft: "28px" }}>
              <Tabs
                className={cx("scriptTabs", { isEdit: true })}
                defaultActiveKey="1"
                size="large"
                items={tabContent}
                onChange={onChange}
              />
            </div>
          )}

          {isCreateForm && (
            <Row justify="end">
              <Col span={4}>
                <Button block onClick={onClose}>
                  Cancel
                </Button>
              </Col>
              <Col span={4} className="mr-12">
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="ml-10 "
                  onClick={errorCheck}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      }
    </div>
  );
}

export default MainForm;
