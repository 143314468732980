import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { Tooltip, Switch } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  VS_IS_FORECAST_SET,
  VS_IS_FORECAST_UNSET,
} from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { UMS_SUPER_ADMIN } from "shared/UserPermission/userPermissionList";
import SelectInput from "components/forms/SelectInput";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useApiToStore } from "hooks";
import {
  API_STORE_VIRALSPARKS_ADVERTISE,
  API_STORE_VIRALSPARKS_ALL_USERS,
  API_STORE_VIRALSPARKS_PLATFORM,
} from "hooks/useApiToStore/keys";
import { GQL_VS_PERFORMANCE_DOMAIN_REPORTS } from "shared/gql/gqlSchema/viralSparksGql";
import useVSAdvertiserHandler from "../../../useVSAdvertiserHandler";
import { useDispatch } from "react-redux";

import {
  GET_F360_AD_REPORT,
  GET_F360_CATEGORY_REPORT,
  GET_F360_VS_CATEGORY_REPORT,
} from "shared/gql/gqlSchema/fb360Gql";
import { addAccountGroup } from "../VsReportUtils";
import useColumns from "../useColumns";
import { numberParse } from "utils";
import { PLATFORM_ID_FACEBOOK } from "shared/SharedKeys";

export default function Domains(props) {
  const { createDrawerProps = {}, campaign_id } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const [domainReport, setDomainReport] = useState([]);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const withUser = params?.userID ? { userId: params?.userID } : {};
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);
  const [selectedUser, setSelectedUser] = useState("all");
  const { columns } = useColumns({});

  const { vsAdvertiser = [] } = userSettingData || {};

  const {
    selectedVSAdvertiserIsAll = true,
    selectedVSAdvertiser = "",
    vsIsForecast,
    vs_users = [],
  } = useSelector((state) => state?.vsReducer) || {};

  const isAdvertiser = selectedVSAdvertiserIsAll ? {} : { advertiser_id: selectedVSAdvertiser };

  function handleForecast(e) {
    dispatch({ type: e ? VS_IS_FORECAST_SET : VS_IS_FORECAST_UNSET });
  }

  const isUser = selectedUser === "all" ? {} : { userId: selectedUser };
  const isWorkspaceAdmin =
    permissions.filter((name) => name === UMS_SUPER_ADMIN).length > 0 ? true : false;

  const { apiCall: vsPlatformsFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_PLATFORM);
  const { apiCall: vsAdvertiserFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ADVERTISE);

  const { apiCall: vsUsersFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ALL_USERS);

  const { handleAdvertiserSelection } = useVSAdvertiserHandler();
  const withAccount = params?.accID ? { adAccountId: params?.accID } : {};

  useEffect(() => {
    if (isWorkspaceAdmin) {
      vsUsersFetch();
    }
    vsPlatformsFetch();
    vsAdvertiserFetch();
  }, [selectedVSAdvertiser]);

  const {
    loading: loading_VS_PERFORMANCE_DOMAIN_REPORT,
    error: error_VS_FIND_VS_COUNTRY_REPORT,
    refetch: refetch_PERFORMANCE_DOMAIN_REPORT,
  } = useCustomQuery(GQL_VS_PERFORMANCE_DOMAIN_REPORTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_performanceDomainReports;
      if (element) {
        setDomainReport(element);
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      platform_id: PLATFORM_ID_FACEBOOK,
      is_forecast: true,
      ...isAdvertiser,
    },
  });

  const loading = loading_VS_PERFORMANCE_DOMAIN_REPORT;

  function refetch() {
    refetch_PERFORMANCE_DOMAIN_REPORT();
  }

  const initialColumns = [
    {
      title: "Domain Name",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 220,
      minWidth: 200,
      searchType: "string",
    },
    ...columns,
  ];

  let finalMergeData = [];

  if (!loading) {
    domainReport?.forEach((element, index) => {
      const {
        impressions = 0,
        sessions = 0,
        pageviews = 0,
        utmCampaign,
        adName = "",
        user_revenue = 0,
        content_nickname = "",
        vs_category = {},
        // other values
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        result = 0,
        status = null,
        daily_budget,
      } = element;

      const spendUpdate = spendFB?.toFixed(2);
      const revenueUpdate = user_revenue;
      const budgetForSorter =
        daily_budget === null || daily_budget === undefined ? -1 : daily_budget; // -1 for CBO to last

      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(revenueUpdate) / parseFloat(spendUpdate)) * 100 || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(result) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;

      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;

      finalMergeData.push({
        ...element,
        key: index,
        content_nickname: content_nickname || vs_category?.nickname,
        utmCampaign: utmCampaign || adName,
        TotalRevenue: numberParse(revenueUpdate, { toFixed: true }),
        EPC: numberParse(EPC, { toFixed: true }),
        IPP: numberParse(IPP, { toFixed: true }),
        RPM: numberParse(RPM, { toFixed: true }),
        PRPM: numberParse(PRPM, { toFixed: true }),
        PPS: numberParse(PPS, { toFixed: true }),
        clicksFB,
        result,
        impressionsFB,
        budgetForSorter,
        spendFB: spendUpdate,
        PROFIT: numberParse(PROFIT),
        PROFIT_VIEW: numberParse(PROFIT, { toFixed: true }),
        CPC: numberParse(CPC),
        CPC_VIEW: numberParse(CPC, { toFixed: true, toFixedUpTo: 3 }),
        CPC_FB: numberParse(CPC_FB),
        CPC_FB_VIEW: numberParse(CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
        ROI: numberParse(ROI),
        ROI_VIEW: numberParse(ROI, { toFixed: true }),
        EPC_VIEW: numberParse(EPC, { toFixed: true, toFixedUpTo: 3 }),
        CTR: numberParse(CTR),
        CTR_VIEW: numberParse(CTR, { toFixed: true }),
        DISC: numberParse(DISC),
        DISC_VIEW: numberParse(DISC, { toFixed: true }),
        PRPM_VIEW: numberParse(PRPM, { toFixed: true }),
        CPR: numberParse(CPR),
        CPR_VIEW: numberParse(CPR, { toFixed: true }),
        PPS_VIEW: numberParse(PPS, { toFixed: true }),
        CPM: numberParse(CPM),
        CPM_VIEW: numberParse(CPM, { toFixed: true }),
        status,
      });
    });
  }

  const finalFilterData = filterData(finalMergeData)?.filter(({ brand_name = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (brand_name?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0,
    total_spendFB = 0,
    total_clicks = 0,
    total_impressionsFB = 0,
    total_result = 0;

  for (const {
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    result = 0,
    pageviews = 0,
  } of finalFilterData) {
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageviews || 0);
    total_spendFB += JSON.parse(spendFB || 0);
    total_clicks += JSON.parse(clicksFB || 0);
    total_impressionsFB += JSON.parse(impressionsFB || 0);
    total_result += JSON.parse(result || 0);
  }

  const total_EPC = total_TotalRevenue / total_sessions;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicks;
  const total_ROI = (total_TotalRevenue / total_spendFB) * 100;
  const total_CTR = (total_clicks / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_result;
  const total_DISC = total_sessions / total_clicks;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const summaryData = {
    TotalRevenue: numberParse(total_TotalRevenue, { toFixed: true }),
    EPC: numberParse(total_EPC, { toFixed: true, toFixedUpTo: 3 }),
    sessions: numberParse(total_sessions),
    IPP: numberParse(total_IPP, { toFixed: true }),
    RPM: numberParse(total_RPM, { toFixed: true }),
    PRPM: numberParse(total_PRPM, { toFixed: true }),
    PPS: numberParse(total_PPS, { toFixed: true }),
    spendFB: numberParse(total_spendFB, { toFixed: true }),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: numberParse(total_ROI, { toFixed: true }),
    CPC: numberParse(total_CPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC_FB: numberParse(total_CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    result: numberParse(total_result),
    CPR: numberParse(total_CPR, { toFixed: true }),
    clicksFB: numberParse(total_clicks),
    DISC: numberParse(total_DISC, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
    impressionsFB: numberParse(total_impressionsFB),
  };

  const rightOptions = (
    <>
      <div className="mr-12">
        <Tooltip
          title={`${vsIsForecast ? "Forecast" : "Min"} Revenue - Switch to ${
            vsIsForecast ? "Min" : "Forecast"
          }`}
        >
          <Switch checked={vsIsForecast} onChange={handleForecast} />
        </Tooltip>
      </div>

      {isWorkspaceAdmin && (
        <div className="mr-12" style={{ width: 150 }}>
          <Tooltip title="User">
            <span>
              <SelectInput
                search
                name="selectedUser"
                className="mb-0"
                placeholder="Select User"
                value={selectedUser}
                options={[
                  { name: "All", value: "all" },
                  ...vs_users?.map(({ id, name }) => ({ name, value: id })),
                ]}
                onChange={setSelectedUser}
              />
            </span>
          </Tooltip>
        </div>
      )}

      <div style={{ width: 150 }}>
        <Tooltip title="Advertiser">
          <span>
            <SelectInput
              search
              name="selectedAdvertiser"
              className="mb-0"
              placeholder="Select Advertiser"
              value={selectedVSAdvertiserIsAll ? "all" : selectedVSAdvertiser}
              options={[
                { name: "All", value: "all" },
                ...vsAdvertiser?.map(({ id, name }) => ({ name, value: id })),
              ]}
              onChange={(e) => handleAdvertiserSelection(e, true)}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },

    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
    rightOptions,
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [
        // { vsCampaign: vsIsForecast ? campaignForecastReport : campaignMinReport },
        { domainReport },
        { finalFilterData },
        { summaryData },
      ],
    },
  ];

  return (
    <div>
      {/* <DetailView {...drawerPropsUpdate} {...rowPropsData} /> */}
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
