import { useRef } from "react";
import * as yup from "yup";
import MainForm from "../MainForm";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { UPDATE_SAS_CATEGORY } from "shared/gql/gqlSchema/sasGql";

export default function UpdateForm(props) {
  const {
    id,
    isSensitive: propIsSensitive,
    name: propName,
    vertical: propVertical,
    dataRender,
    onClose = () => {},
    setDataRender = () => {},
  } = props;

  const resetFormRef = useRef(() => {});

  const [sasCategoryUpdate, { loading }] = useCustomMutation(UPDATE_SAS_CATEGORY, {
    onCompleted(e) {
      const elementData = e?.sasCategoryUpdate;
      const updatedItem = { ...elementData, key: elementData.id };

      const temp = JSON.parse(JSON.stringify(dataRender));
      const updateIndex = dataRender.findIndex(({ id }) => id === elementData.id);
      temp[updateIndex] = updatedItem;
      setDataRender(temp);
      onClose();
      openNotification({ type: "success", message: "Record Update Successfully" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit(values, { setSubmitting, resetForm }) {
    const { isSensitive, name } = values;
    resetFormRef.current = resetForm;

    const capitalized = name
      .split(" ")
      .map((it) => it.charAt(0).toUpperCase() + it.slice(1))
      .join(" ");

    sasCategoryUpdate({
      variables: { id, ...values, isSensitive: Boolean(isSensitive), name: capitalized },
    });
  }

  const initialValues = {
    name: propName,
    isSensitive: Number(propIsSensitive),
    verticalId: propVertical?.id,
  };

  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
    verticalId: yup.string().required().label("Vertical"),
  });

  const formProps = { initialValues, validationSchema, onSubmit, id, loading, onClose };
  return (
    <div>
      <MainForm btnText="Update" type="edit" {...formProps} />
    </div>
  );
}
