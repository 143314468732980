import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import { useState } from "react";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import PreformanceReport from "./PerformanceReport";
import AdgroupReport from "../../Adgroup";
export default function DetailView(props) {
  const { visible = false, onClose } = props;
  let name = "";
  const isCommonReports = [
    {
      id: "adgroup-report",
      hoverTitle: "Adgroup Report",
      icon: () => <ActionIconRender iconType="adIcon" />,
      component: <AdgroupReport {...props} outerDate={true} />,
      noSpace: true,
    },
    {
      id: "hourly-performance",
      hoverTitle: "Hourly Performance",
      icon: () => <ActionIconRender iconType="duration" />,
      component: <PreformanceReport type="isHourly" {...props} outerDate={true} />,
      noSpace: true,
    },
    {
      id: "hourOfTheDay_performance",
      hoverTitle: "Hour of the day Performance",
      icon: () => <ActionIconRender iconType="time-circle" />,
      component: <PreformanceReport type="isHourOfTheDay" {...props} outerDate={true} />,
      noSpace: true,
    },
    {
      id: "daily-performance",
      hoverTitle: "Daily Performance",
      icon: () => <ActionIconRender iconType="lineChart" />,
      component: <PreformanceReport type="isDaily" {...props} />,
      noSpace: true,
    },
  ];
  const tabContent = [...isCommonReports];
  const [activeTab, setActiveTab] = useState(tabContent[0]?.id);
  const selectedTab = tabContent.filter(({ id }) => id === activeTab)[0];
  return (
    <LookDrawer
      icon={(e) => <ActionIconRender iconType="select" {...e} />}
      size={1540}
      visible={visible}
      onClose={onClose}
      title={
        <>
          <b>{selectedTab?.hoverTitle}</b> - {name}
        </>
      }
    >
      {visible && (
        <LookDrawerMainSideBar value={activeTab} onChange={setActiveTab} tabContent={tabContent} />
      )}
    </LookDrawer>
  );
}
