import { GET_FILE_CATEGORIES } from "shared/gql/gqlSchema/mediaGql";
import { API_STORE_FILE_CATEGORIES } from "./keys";

export default function useMediaApis(typeKey) {
  switch (typeKey) {
    case API_STORE_FILE_CATEGORIES:
      return {
        api: GET_FILE_CATEGORIES,
        elementKey: "fileCategories",
        storeKey: "fileCategories",
      };

    default:
      return false;
  }
}
