import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
} from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import AdGroup from "components/Launch/Creatives/AdGroup";
import Body from "components/Launch/Creatives/Body";
import CreativeUpload, { CreativeVideoUpload } from "components/Launch/Creatives/CreativeUpload";
import Description from "components/Launch/Creatives/Description";
import Title from "components/Launch/Creatives/Title";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import TabRender from "components/TabRender";
import { usePermissionCheck } from "hooks";

import React, { useState } from "react";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import { SAS_WORKSPACE_ADMIN } from "shared/UserPermission/userPermissionList";

import KeywordTable from "../Table";
import UpdateForm from "../UpdateForm";

function UpdateKeyword(props) {
  // const { visible = false, onClose = () => {} } = props;
  const { havePermission: isWorkSpaceAdmin } = usePermissionCheck(SAS_WORKSPACE_ADMIN);
  console.log(props);
  const {
    visible = false,
    onClose = () => {},
    name = "",
    groupSelection = false,
    serviceType,
    title,
    submitView,
    selectedData,
  } = props;
  const [activeTab, setActiveTab] = useState(isWorkSpaceAdmin ? "Overview" : "Creative-Upload");

  const overView =
    isWorkSpaceAdmin && !groupSelection
      ? [
          {
            id: "Overview",
            hoverTitle: "Overview",
            icon: () => <ActionIconRender iconType="pic-left" />,
            component: <UpdateForm {...props} />,
          },
          {
            hoverTitle: "Keyword Ideas",
            icon: () => <ActionIconRender iconType="keywordIcon" />,
            component: <KeywordTable {...props} />,
            noSpace: true,
          },
        ]
      : [];
  const hoverTitleAppend = groupSelection ? "" : "Upload";
  const isAdGroup = groupSelection
    ? []
    : [
        {
          id: "AdGroup",
          hoverTitle: `AdGroup ${hoverTitleAppend}`,
          icon: () => <ActionIconRender iconType="font-colors" />,
          component: <AdGroup {...props} serviceType={serviceType} isKeyword={true} />,
        },
      ];

  const tabContent = props.category
    ? [
        ...overView,
        {
          id: "Creative-Upload",
          title: groupSelection ? `Creative ${hoverTitleAppend}` : undefined,
          hoverTitle: `Creative ${hoverTitleAppend}`,
          icon: () => <ActionIconRender iconType="picture" />,
          component: (
            <CreativeUpload
              {...props}
              creativeType={CREATIVE_TYPE_IMAGE}
              serviceType={serviceType}
              isKeyword={true}
            />
          ),
        },
        // {
        //   id: "Thumb-Upload",
        //   hoverTitle: "Thumb Upload",
        //   icon: () => <ActionIconRender iconType="picture" />,
        //   component: <CreativeUpload {...props} creativeType={CREATIVE_TYPE_THUMBNAIL} />,
        // },
        {
          id: "Video-Upload",
          title: groupSelection ? `Video ${hoverTitleAppend}` : undefined,
          hoverTitle: `Video ${hoverTitleAppend}`,
          icon: () => <ActionIconRender iconType="video-camera" />,
          component: (
            <CreativeVideoUpload
              {...props}
              creativeType={CREATIVE_TYPE_VIDEO}
              serviceType={serviceType}
              isKeyword={true}
            />
          ),
        },
        {
          id: "Title",
          hoverTitle: "Title",
          icon: () => <ActionIconRender iconType="font-size" />,
          component: (
            <Title
              {...props}
              persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
              serviceType={serviceType}
              isKeyword={true}
            />
          ),
        },
        {
          id: "Body",
          title: groupSelection ? `Body ${hoverTitleAppend}` : undefined,

          hoverTitle: `Body ${hoverTitleAppend}`,
          icon: () => <ActionIconRender iconType="font-colors" />,
          component: (
            <Body
              {...props}
              persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
              serviceType={serviceType}
              isKeyword={true}
            />
          ),
        },
        {
          id: "Description",
          hoverTitle: `Description ${hoverTitleAppend}`,
          icon: () => <ActionIconRender iconType="align-center" />,
          component: (
            <Description
              {...props}
              persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3}
              serviceType={serviceType}
              isKeyword={true}
            />
          ),
        },
        ...isAdGroup,
      ]
    : [...overView];

  const selectedTab = tabContent.filter(({ id }) => id === activeTab)[0];
  const drawerProps = {
    title: `Manage Category${name ? " - " + name : ""}${
      selectedTab?.hoverTitle ? " - " + selectedTab?.hoverTitle : ""
    }`,
    icon: () => <ActionIconRender className="mr-10" iconType="priority" />,
    size: 1200,
    visible,
    onClose,
  };

  // const drawerProps = {
  //   title: `Update Keyword : ${props?.name}`,
  //   icon: () => <ActionIconRender className="mr-10" iconType="keywordIcon" />,
  //   size: 900,
  //   visible,
  //   onClose,
  // };

  return (
    <LookDrawer {...drawerProps}>
      {visible && (
        <>
          {groupSelection ? (
            <div className="d-flex h-100" style={{ flexWrap: "wrap" }}>
              <div
                style={{
                  flex: "0 0 33%",
                  maxWidth: "33%",
                  borderRight: "1px solid #424242",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <CardCreativeAdGroup {...selectedData} />
              </div>
              <div style={{ flex: "0 0 67%", maxWidth: "67%" }}>
                <TabRender
                  className="top-bar creative-drawer-tab"
                  fullBtn
                  contentClassName="px-20"
                  tabContent={tabContent}
                  value={activeTab}
                  onChange={setActiveTab}
                />
                <div className="px-12">{submitView}</div>
              </div>
            </div>
          ) : (
            <LookDrawerMainSideBar
              value={activeTab}
              onChange={setActiveTab}
              tabContent={tabContent}
            />
          )}
        </>
      )}
    </LookDrawer>
  );
}

export default UpdateKeyword;
