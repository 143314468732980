import { Form, Button, Alert, Row, Col } from "antd";
import { Formik } from "formik";
import Loader from "components/loader";
import SelectInput from "components/forms/SelectInput";
import LookTextInput from "components/forms/LookTextInput";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { GET_SAS_KEYWORD_SOURCES, GET_SAS_LANGUAGES } from "shared/gql/gqlSchema/sasGql";
import { useSelector } from "react-redux";

export default function MainForm(props) {
  const {
    id: propId = "",
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    isError,
    btnText = "Add",
    loading = false,
  } = props;

  const { userSettingData } = useSelector((state) => state?.userSettings) || {};
  const { sasCategories = [] } = userSettingData || {};

  const {
    data: KEYWORD_SOURCE_DATA = [],
    loading: KEYWORD_SOURCE_LOADING,
    error: KEYWORD_SOURCE_ERROR,
  } = useCustomQuery(GET_SAS_KEYWORD_SOURCES, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: LANGUAGE_DATA = [],
    loading: LANGUAGE_LOADING,
    error: LANGUAGE_ERROR,
  } = useCustomQuery(GET_SAS_LANGUAGES);

  const allKeywordSources = KEYWORD_SOURCE_DATA?.sasKeywordSources;

  if (KEYWORD_SOURCE_LOADING) {
    return <Loader className="text-center" />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {/* <pre> {JSON.stringify({ initialValues, values }, null, 2)} </pre> */}
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="name"
                label="Name"
                type={propId ? "text" : "textarea"}
                className="look-form-input"
                placeholder="Enter the keywords ( Every new line is new keyword )"
              />
            </Col>
            <Col span={12}>
              <Form.Item label="Category" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  disabled={propId}
                  placeholder="Select Category"
                  name="categoryId"
                  value={values.categoryId}
                  options={sasCategories?.map(({ id, name }) => ({ name: name, value: id }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Keyword Source" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Keyword Source"
                  name="keywordSourceId"
                  value={values.keywordSourceId}
                  options={allKeywordSources?.map(({ id, sourceName }) => ({
                    name: sourceName,
                    value: id,
                  }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Status" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  name="status"
                  value={values.status}
                  options={[
                    { id: 1, name: "ACTIVE" },
                    { id: 2, name: "INACTIVE" },
                  ].map(({ id, name }) => ({ name: name, value: name }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Language" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  search
                  formik
                  placeholder="Select Language"
                  name="languageId"
                  value={values.languageId}
                  options={LANGUAGE_DATA?.SasLanguages?.map(({ id, name }) => ({
                    name: name,
                    value: id,
                  }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Is Sensitive" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  name="isSensitive"
                  value={values.isSensitive}
                  options={[
                    { id: 0, name: "False" },
                    { id: 1, name: "True" },
                  ].map(({ id, name }) => ({ name: name, value: id }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
