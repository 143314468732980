import { Form, Button, Alert, Row, Col } from "antd";
import { Formik } from "formik";
import Loader from "components/loader";
import SelectInput from "components/forms/SelectInput";
import LookTextInput from "components/forms/LookTextInput";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    onClose = () => {},
    loading = false,
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {/* <pre> {JSON.stringify({ initialValues, values }, null, 2)} </pre> */}
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="name"
                label="Name"
                className="look-form-input"
                placeholder="Enter the name"
              />
            </Col>
            <Col span={12}>
              <Form.Item label="Is Sensitive" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  name="isSensitive"
                  value={values.isSensitive}
                  options={[
                    { id: 0, name: "False" },
                    { id: 1, name: "True" },
                  ].map(({ id, name }) => ({ name: name, value: id }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
