import { Card, Image } from "antd";
import LookButton from "components/LookButton";
import cx from "cx";

export default function CardCreativeBuilderCreativeDisplay(props) {
  const { className = "", title = "", image } = props;
  return (
    <Card className={cx("card-cb-cerative-display p-ch-0", className)}>
      <div>
        <div className="image-view">
          <Image preview={false} src={image} />
        </div>
        <div className="content d-flex">
          <div className="flex-grow-1">
            <h2 className="title">{title}</h2>
          </div>
          <div>
            <div className="action">
              <LookButton tooltipTitle="Edit" iconType="edit" type="link" />
              <LookButton tooltipTitle="Delete" iconType="delete" type="link" danger />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
