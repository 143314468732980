import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import route, { ROUTE_DASHBOARD } from "../route";

const Dashboard = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Creatives-Dashboard" */ "pages/dashboardPages/Creatives/Dashboard")
  )
);
const CreativeReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "CreativeReports" */ "pages/dashboardPages/Creatives/CreativeReports"
    )
  )
);
const CreativeRequestList = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "CreativeRequest" */ "pages/dashboardPages/Creatives/CreativeRequest"
    )
  )
);
const TemplateRequestList = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TemplateRequest" */ "pages/dashboardPages/Creatives/TemplateRequest"
    )
  )
);
const CreativeUpload = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "CreativeUpload" */ "pages/dashboardPages/Creatives/CreativeUpload")
  )
);
const CreativeBuilder = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "CreativeBuilder" */ "pages/dashboardPages/Creatives/Category/CreativeBuilder"
    )
  )
);
const MediaLibrary = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "MediaLibraryNew" */ "pages/dashboardPages/Creatives/MediaLibraryNew"
    )
  )
);

const creativeRoutes = [
  route("/creative/creative-dashboard", Dashboard, ROUTE_DASHBOARD, false),

  route("/creative/creative-upload/:serviceType", CreativeUpload, ROUTE_DASHBOARD, false),
  route("/creative/creative-upload/", CreativeUpload, ROUTE_DASHBOARD, false),
  route("/creative/creative-reports", CreativeReports, ROUTE_DASHBOARD, false),
  route("/creative/creative-request", CreativeRequestList, ROUTE_DASHBOARD, false),
  route("/creative/template-request", TemplateRequestList, ROUTE_DASHBOARD, false),
  route("/creative/creative-builder", CreativeBuilder, ROUTE_DASHBOARD, false),
  route("/creative/media-library", MediaLibrary, ROUTE_DASHBOARD, false),
];

export default creativeRoutes;
