import LookModal from "components/LookModal";
import { useState } from "react";
import { openNotification } from "utils";
import UpdateBudget from "./UpdateBudget";

export const UPDATE_UPDATE_NODE_CAMPAIGN = "CAMPAIGN";
export const UPDATE_UPDATE_NODE_ADSET = "ADSET";
export const UPDATE_UPDATE_NODE_AD = "AD";

export default function useUpdateFbAssetBudget(node, extraProps = {}) {
  const { lengthCheck = 15 } = extraProps;
  const [visibleBulkBudgetDrawer, setVisibleBulkBudgetDrawer] = useState(false);
  const [reportType, setReportType] = useState("");
  const [mainData, setMainData] = useState([]);
  const showBulkBudgetDrawer = (bulkData, report_type) => {
    let ids = [];
    bulkData?.forEach(({ campaignId, adsetId, adId }) => {
      if (node === UPDATE_UPDATE_NODE_CAMPAIGN) {
        campaignId && ids.push(campaignId);
      } else if (node === UPDATE_UPDATE_NODE_ADSET) {
        adsetId && ids.push(adsetId);
      } else if (node === UPDATE_UPDATE_NODE_AD) {
        adId && ids.push(adId);
      }
    });
    if (ids.length > lengthCheck) {
      return openNotification({ type: "warning", message: "Please select max 15 records" });
    }
    setReportType(report_type);
    setVisibleBulkBudgetDrawer(true);
    setMainData(bulkData);
  };
  const onCloseBulkBudgetDrawer = () => {
    setVisibleBulkBudgetDrawer(false);
    setMainData([]);
    setReportType("");
  };

  const modalUpdateBudgetPropsUpdate = {
    title: "Update Budget",
    visible: visibleBulkBudgetDrawer,
    onCancel: onCloseBulkBudgetDrawer,
    okokText: "Update Budget",
    footer: false,
  };

  const renderUpdateBudgetModal = () => {
    return (
      <LookModal {...modalUpdateBudgetPropsUpdate}>
        <UpdateBudget
          onCloseDrawerBudget={onCloseBulkBudgetDrawer}
          fbIds={mainData?.map(({ campaignId, adsetId, adId }) => {
            if (node === UPDATE_UPDATE_NODE_CAMPAIGN) {
              return campaignId;
            } else if (node === UPDATE_UPDATE_NODE_ADSET) {
              return adsetId;
            } else if (node === UPDATE_UPDATE_NODE_AD) {
              return adId;
            }
          })}
          node={node}
          reportType={reportType}
        />
      </LookModal>
    );
  };

  return { renderUpdateBudgetModal, showBulkBudgetDrawer, onCloseBulkBudgetDrawer };
}
