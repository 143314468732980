import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import { openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { UPDATE_FB_ASSEST_BUDGET } from "shared/gql/gqlSchema/f360Gql";
import { TAG_ADD_TAGS } from "shared/gql/gqlSchema/tagsGql";

export default function UpdateBudget(props) {
  const {
    campaignId = "",
    dailyBudget,
    lifeTimeBudget,
    onCloseDrawerBudget = () => {},
    fbIds = [],
    node,
    reportType = "",
  } = props;
  const [addSASTags] = useCustomMutation(TAG_ADD_TAGS);
  const [isError, setIsError] = useState("");
  const resetFormRef = useRef(() => {});

  const showUpdateStatus = (element) => {
    const errorCount = element.reduce((acc, val) => {
      if (!val.success) {
        acc += 1;
      }
      return acc;
    }, 0);
    let type = "";
    if (errorCount === 0) {
      type = "success";
    } else if (errorCount === element.length) {
      type = "error";
    } else {
      type = "warning";
    }

    openNotification({
      key: "lg-active-pause-status",
      type: type,
      className: "lg-active-pause-status",
      message: (
        <div>
          <h4 className="lg-message-heading pb-6" style={{ color: "black" }}>
            Budget Updated
          </h4>
          <div className="lg-message-content">
            {element?.map(({ assetId, message, success }) => (
              <div>
                <b style={success ? {} : { color: "red" }} className="id">
                  {assetId}
                </b>
                <p className="status mb-12">{message}</p>
              </div>
            ))}
          </div>
        </div>
      ),
    });
  };
  const [updateCampaignBudget, { loading }] = useCustomMutation(UPDATE_FB_ASSEST_BUDGET, {
    onCompleted(e) {
      // refetch();
      // resetFormRef.current();
      const element = e?.updateFbAssetBudget;
      onCloseDrawerBudget();
      const result = [];
      if (element) {
        element.forEach((d) => {
          if (d.success) {
            result.push(d?.assetId);
          }
          // openNotification({
          //   type: d?.success ? "success" : "error",
          //   message: d?.message || d?.success ? "Budget Updated" : "Something went wrong",
          //   description: d?.assetId,
          // });
        });

        showUpdateStatus(element);
        addSASTags({
          variables: {
            identifier: result,
            tags: "Budget Updated",
            type: `sas-${reportType}`,
          },
        });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
    variables: { fbIds, node },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;

    updateCampaignBudget({ variables: { ...values } });
    clearError();
  }
  let budget = "";
  let isLifeTime = false;

  if (dailyBudget !== null && lifeTimeBudget !== null) {
    if (dailyBudget > lifeTimeBudget) {
      budget = dailyBudget;
      isLifeTime = false;
    } else if (dailyBudget < lifeTimeBudget) {
      budget = lifeTimeBudget;
      isLifeTime = true;
    }
  }
  if (dailyBudget && (lifeTimeBudget === undefined || lifeTimeBudget === null)) {
    budget = dailyBudget;
    isLifeTime = false;
  }
  if ((dailyBudget === undefined || dailyBudget === null) && lifeTimeBudget) {
    budget = lifeTimeBudget;
    isLifeTime = true;
  }

  const initialValues = {
    budget: budget,
    isLifeTime: isLifeTime,
  };

  const validationSchema = yup.object({
    budget: yup.number().required().label("budget"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    btnText: "Update",
  };

  return (
    <>
      <MainFrom {...formProps} />
      {/* <pre>{JSON.stringify({ props }, null, 2)}</pre> */}
    </>
  );
}
