import * as types from "../actions/actionTypes";

const defaultInitilization = {
  selectedUnicornTeam: "all",
  selectedUnicornStatus: [],
  selectedUnicornDepartment: "all",
  selectedUnicornLob: "all",
};

export default function unicornReducer(state = defaultInitilization, action) {
  switch (action.type) {
    case types.UNICORN_TEAM_SELECTION:
      return { ...state, selectedUnicornTeam: action.payload };
    case types.UNICORN_STATUS_SELECTION:
      return { ...state, selectedUnicornStatus: action.payload };
    case types.UNICORN_DEPARTMENT_SELECTION:
      return { ...state, selectedUnicornDepartment: action.payload };
    case types.UNICORN_LOB_SELECTION:
      return { ...state, selectedUnicornLob: action.payload };
    case types.UNICORN_CLEAR_SETTINGS:
      return { ...defaultInitilization };
    default:
      return state;
  }
}
