import { Form, Button, Alert, Row, Col, Switch, DatePicker } from "antd";
import { Formik } from "formik";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";
import SelectInput from "components/forms/SelectInput";
import moment from "moment";
import { dateTimeFormatWithoutSecond } from "reducers/userSettings";
import { C_TYPE_ABO, C_TYPE_CBO } from "shared/enum/launchKeysAndEnum";
import { specialAdCategoriesOptions } from "shared/SharedKeys/launchKeys";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
    setStartDateTime = () => {},
    startDateTime,
    endDateTime,
    loading_adaccounts = false,
    loading_pages = false,
    setEndDateTime = () => {},
    adaccountsData = [],
    setSelectedAdaccount,
    pagesData = [],
    isMultiAccountClone,
    sasTrackingUrls = [],
    loading_fetch_sasTrackingUrl = false,
  } = props;

  const statusInputs = [
    { status: "ACTIVE", value: "ACTIVE" },
    { status: "PAUSED", value: "PAUSED" },
    { status: "INHERITED_FROM_SOURCE", value: "INHERITED_FROM_SOURCE" },
  ];

  const budgetInputs = [
    { budgetType: "ABO", value: C_TYPE_ABO },
    { budgetType: "CBO", value: C_TYPE_CBO },
  ];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          {isMultiAccountClone && (
            <Row gutter={12}>
              <Col span={8}>
                <SelectInput
                  label="Adaccount"
                  name="adaccount"
                  placeholder="Select Adaccount"
                  loader={loading_adaccounts}
                  search
                  onChange={(e) => {
                    setFieldValue("adaccount", e);
                    setSelectedAdaccount(e);
                  }}
                  options={adaccountsData?.map(({ name, adaccount_id }) => ({
                    name,
                    value: adaccount_id,
                  }))}
                  className="look-form-input"
                />
              </Col>
              <Col span={8}>
                <SelectInput
                  label="Page"
                  name="page"
                  placeholder="Select page"
                  loader={loading_pages}
                  onChange={(e) => {
                    setFieldValue("page", e);
                  }}
                  search
                  disabled={!values?.adaccount}
                  options={pagesData?.pages?.map((data) => ({
                    name: data?.name,
                    value: data?.id,
                  }))}
                  className="look-form-input"
                />
              </Col>
              <Col span={8}>
                <SelectInput
                  label="Pixel"
                  name="pixel"
                  placeholder="Select pixel"
                  loader={loading_pages}
                  disabled={!values?.adaccount}
                  onChange={(e) => {
                    setFieldValue("pixel", e);
                  }}
                  search
                  options={pagesData?.pixels?.map((data) => ({
                    name: data?.name,
                    value: data?.id,
                  }))}
                  className="look-form-input"
                />
              </Col>
            </Row>
          )}
          <Row gutter={12}>
            <Col span={12}>
              <LookTextInput
                formik
                name="name"
                label="Name"
                className="look-form-input"
                placeholder="Enter name"
              />
            </Col>
            <Col span={12}>
              <LookTextInput
                formik
                type="number"
                name="count"
                label="Count"
                className="look-form-input"
                placeholder="Enter count"
              />
            </Col>
            <Col span={12}>
              <SelectInput
                label="Budget Optimization"
                name="budgetOptimization"
                placeholder="Select Budget Optimization"
                onChange={(e) => {
                  setFieldValue("budgetOptimization", e);
                }}
                options={budgetInputs?.map(({ budgetType, value }) => ({
                  name: budgetType,
                  value,
                }))}
                className="look-form-input"
              />
            </Col>
            <Col span={8}>
              <LookTextInput
                formik
                type="number"
                name="budget"
                label="Budget (in USD)"
                className="look-form-input"
                placeholder="Enter budget"
              />
            </Col>
            <Col span={4}>
              <div>
                <p className="mb-8">Budget</p>
                <Switch
                  checkedChildren="Lifetime"
                  unCheckedChildren="Daily"
                  size="medium"
                  checked={values.isLifeTime}
                  onChange={(e) => {
                    setFieldValue("isLifeTime", e);
                  }}
                />
              </div>
            </Col>
          </Row>

          {!isMultiAccountClone && (
            <Col span={12}>
              <SelectInput
                label="Status"
                name="status"
                placeholder="Select Status"
                onChange={(e) => setFieldValue("status", e)}
                options={statusInputs.map(({ status, value }) => ({
                  name: status,
                  value,
                }))}
                className="look-form-input"
              />
            </Col>
          )}

          {isMultiAccountClone && (
            <Row gutter={15}>
              <Col span={12}>
                <SelectInput
                  formik
                  search
                  label="Tracking Url"
                  name="trackingUrl"
                  loader={loading_fetch_sasTrackingUrl}
                  placeholder="Select Tracking Url"
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  options={sasTrackingUrls?.map(({ domain }) => ({
                    name: domain,
                    value: domain,
                  }))}
                  className="look-form-input"
                />
              </Col>
            </Row>
          )}

          {!isMultiAccountClone && (
            <>
              <Col span={12}>
                <b style={{ color: "red" }}>Note : Date and time is UTC based</b>
              </Col>
              <Form.Item label="Schedule Start Date" className="look-form-input">
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  showNow={false}
                  format="YYYY-MM-DD HH:mm"
                  value={startDateTime ? moment(startDateTime, dateTimeFormatWithoutSecond) : null}
                  onChange={(e, f) => {
                    setStartDateTime(f);
                  }}
                />
              </Form.Item>
              <Form.Item label="Schedule End Date" className="look-form-input">
                <DatePicker
                  showTime={{ format: "HH:mm" }}
                  showNow={false}
                  format="YYYY-MM-DD HH:mm"
                  value={endDateTime ? moment(endDateTime, dateTimeFormatWithoutSecond) : null}
                  onChange={(e, f) => {
                    setEndDateTime(f);
                  }}
                />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
