import { Button, Col, Form, Row } from "antd";
import LookButton from "components/LookButton";
import { useLookModal } from "components/LookModal";
import {
  CMS_POST_CONTENT_VIEW_TYPE_CREATE,
  CMS_POST_CONTENT_VIEW_TYPE_EDIT,
} from "components/PostHandle";
import SelectInput from "components/forms/SelectInput";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import useCmsAdvertiserApis from "./useCmsAdvertiserApis";
import useTrackerApis from "hooks/useApiToStore/useTrackerApis";
import { useEffect } from "react";
const AdvertiserTagForm = (props) => {
  const {
    initialValues,
    submitButtonLabel = "Create CTA",
    validationSchema,
    handleTagCreate,
    closeModal,
    loading = false,
  } = props;
  const {
    Tracker_Countries,
    Tracker_Countries_loading,
    Tracker_Advertisers,
    Tracker_Advertisers_loading,
    Tracker_NetworkAccounts,
    Tracker_NetworkAccounts_loading,
  } = useSelector((state) => state?.userSettings?.userSettingData) || {};
  const { trackerNetworkAccountsRefetch, trackerAdvertisersRefetch } = useTrackerApis();

  useEffect(() => {
    trackerNetworkAccountsRefetch();
    trackerAdvertisersRefetch();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleTagCreate}
    >
      {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item label="Network" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Network"
                  name="network_acc_id"
                  value={values.network_acc_id}
                  options={Tracker_NetworkAccounts?.map(({ id, name }) => ({
                    name: name,
                    value: id,
                  }))}
                  onChange={setFieldValue}
                  showArrow={true}
                  isAlert={!Tracker_NetworkAccounts?.length && true}
                  alertMessage={<>Please contact admin</>}
                  disabled={Tracker_NetworkAccounts_loading}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Advertiser" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Advertiser"
                  name="advertiserID"
                  value={values.advertiserID}
                  options={Tracker_Advertisers?.map(({ id, name }) => ({
                    name: name,
                    value: id,
                  }))}
                  onChange={(name, value, data) => {
                    setFieldValue(name, value);
                    setFieldValue("advertiserName", data?.children);
                  }}
                  showArrow={true}
                  isAlert={!Tracker_Advertisers?.length && true}
                  alertMessage={<>Please contact admin</>}
                  disabled={Tracker_Advertisers_loading}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Country" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Country"
                  name="country"
                  value={values.country}
                  options={Tracker_Countries?.map(({ id, name }) => ({
                    name: name,
                    value: id,
                  }))}
                  onChange={setFieldValue}
                  showArrow={true}
                  isAlert={!Tracker_Countries?.length && true}
                  alertMessage={<>Please contact admin</>}
                  disabled={Tracker_Countries_loading}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Button block className="submit-btn " onClick={closeModal}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="submit"
                block
                type="primary"
                className="submit-btn"
                onClick={handleSubmit}
                disabled={loading}
                loading={loading}
              >
                {submitButtonLabel}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export function UpdateAdvertiserTag(props) {
  const {
    closeModal,
    visibleModal = false,
    cmsContentTagList = [],
    setCmsContentTagList = () => {},
    islink_of_article = {},
  } = props;

  const initialValues = {
    advertiserName: props?.advertiser_name,
    advertiserID: props?.advertiser_id,
    network_acc_id: props?.network_acc_id,
    country: props?.country,
  };

  const validationSchema = yup.object({
    network_acc_id: yup.string().required().label("Network"),
    advertiserID: yup.string().required().label("Advertiser"),
  });

  const { callCmsUpdateAdvertiser, loading_cmsUpdateAdvertiser } = useCmsAdvertiserApis();
  const handleTagCreate = (values) => {
    const payloadData = {
      id: props?.id,
      tagId: props?.tagId,
      article_id: props?.article_id,
      article_type: props?.articleType,
      network_acc_id: values?.network_acc_id,
      advertiser_id: values?.advertiserID,
      advertiser_name: values?.advertiserName,
      country: values?.country,
    };

    const updatedArticleTag = cmsContentTagList?.map((tag) => {
      if (tag.id === props?.id) {
        return payloadData;
      } else {
        return {
          id: tag?.id,
          tagId: tag?.tagId,
          article_id: tag?.article_id,
          article_type: tag?.article_type,
          network_acc_id: tag?.network_acc_id,
          advertiser_id: tag?.advertiser_id,
          advertiser_name: tag?.advertiser_name,
          country: tag?.country,
        };
      }
    });
    // const variables = {
    //   advertiser: updatedArticleTag,
    //   ...islink_of_article,
    // };
    setCmsContentTagList(updatedArticleTag);
    closeModal();
    // callCmsUpdateAdvertiser({
    //   onCompleted: (e) => {
    //     const element = e?.CMS_Update_Advertiser;
    //     if (element) {
    //       // will be used in future
    //       // let temp = JSON.parse(JSON.stringify(cmsContentTagList));
    //       // const currentIndex = temp.findIndex((d) => d?.id === element?.id);
    //       // if (currentIndex !== -1) {
    //       //   temp[currentIndex] = { ...element, tagId: parseInt(element?.tagId) };
    //       //   setCmsContentTagList(temp);
    //       // }
    //       closeModal();
    //     }
    //   },
    //   variables: { ...variables },
    // });
  };

  return (
    <>
      {visibleModal && (
        <AdvertiserTagForm
          {...{
            initialValues,
            validationSchema,
            handleTagCreate,
            closeModal,
            submitButtonLabel: "Update Tag",
            loading: loading_cmsUpdateAdvertiser,
          }}
        />
      )}
    </>
  );
}

export function CreateTag(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const { userSettingData } = userSettings || {};
  const { CMS_Domains = [] } = userSettingData || {};
  const {
    view,
    mainFormId,
    articleType,
    cmsContentTagList = [],
    setCmsContentTagList = () => {},
    islink_of_article = {},
  } = props;

  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Create CTA",
    footer: false,
  });

  const { callCmsCreateAdvertiser, loading_cmsCreateAdvertiser } = useCmsAdvertiserApis();

  const initialValues = { advertiserName: "", advertiserID: "", network_acc_id: "", country: "" };
  const validationSchema = yup.object({
    network_acc_id: yup.string().required().label("Network"),
    advertiserID: yup.string().required().label("Advertiser"),
  });

  const createTagUsingApi = (values) => {
    let tagId = 1;
    if (cmsContentTagList?.length > 0) {
      tagId = cmsContentTagList[cmsContentTagList?.length - 1]?.tagId + 1 || 1;
    }

    const payloadData = {
      tagId,
      article_id: mainFormId,
      article_type: articleType,
      network_acc_id: values?.network_acc_id,
      advertiser_id: values?.advertiserID,
      advertiser_name: values?.advertiserName,
      country: values?.country,
    };

    setCmsContentTagList((pre) => [...pre, payloadData]);
    closeModal();
    // will remove in future
    // callCmsCreateAdvertiser({
    //   onCompleted: (e) => {
    //     const element = e?.CMS_Create_Advertiser;
    //     if (element) {
    //       setCmsContentTagList((pre) => [
    //         ...pre,
    //         ...element?.map((data) => ({ ...data, tagId: parseInt(data?.tagId) })),
    //       ]);
    //       closeModal();
    //     }
    //   },
    //   variables: { advertiser: payloadData, ...islink_of_article },
    // });
  };

  const handleTagCreate = (values) => {
    if (view === CMS_POST_CONTENT_VIEW_TYPE_CREATE) {
      setCmsContentTagList((prev = []) => {
        let tagId = 1;

        if (prev?.length > 0) {
          tagId = prev[prev?.length - 1]?.tagId + 1 || 1;
        }

        return [
          ...prev,
          {
            tagId,
            article_type: articleType,
            network_acc_id: values?.network_acc_id,
            advertiser_id: values?.advertiserID,
            advertiser_name: values?.advertiserName,
            country: values?.country,
          },
        ];
      });
      closeModal();
    } else if (view === CMS_POST_CONTENT_VIEW_TYPE_EDIT) {
      createTagUsingApi(values);
    }
  };

  return (
    <div className="cta-main-header">
      <LookButton block onClick={openModal} iconType="plus">
        Add new CTA
      </LookButton>

      {visibleModal &&
        modal(
          <AdvertiserTagForm
            {...{
              initialValues,
              validationSchema,
              handleTagCreate,
              closeModal,
              loading: loading_cmsCreateAdvertiser,
            }}
          />
        )}
    </div>
  );
}
