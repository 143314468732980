import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  CMS_MANAGE_AWIC_COMPANY,
  CMS_MANAGE_AWIC_NETWORK,
  CMS_MANAGE_AWIC_NETWORK_ACCOUNT,
  CMS_MANAGE_AWIC_PAYMENT,
  CMS_MANAGE_AWIC_POC,
  CMS_MANAGE_AWIC_STORE_EVERFLOW,
} from "../../shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const Network = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Network" */ "pages/dashboardPages/Awic/Network"))
);
const Companies = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Companies" */ "pages/dashboardPages/Awic/Companies")
  )
);
const PaymentDetails = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "PaymentDetails" */ "pages/dashboardPages/Awic/PaymentDetails")
  )
);
const NetworkAccounts = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "NetworkAccounts" */ "pages/dashboardPages/Awic/NetworkAccounts")
  )
);
const PointOfContact = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "PointOfContact" */ "pages/dashboardPages/Awic/PointOfContact")
  )
);
const StoreEverflow = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "StoreEverflow" */ "pages/dashboardPages/Awic/StoreEverflow")
  )
);

const awicRoutes = [
  route("/awic/network", Network, ROUTE_DASHBOARD, false, CMS_MANAGE_AWIC_NETWORK),
  route("/awic/companies", Companies, ROUTE_DASHBOARD, false, CMS_MANAGE_AWIC_COMPANY),
  route("/awic/payment-details", PaymentDetails, ROUTE_DASHBOARD, false, CMS_MANAGE_AWIC_PAYMENT),
  route(
    "/awic/network-accounts",
    NetworkAccounts,
    ROUTE_DASHBOARD,
    false,
    CMS_MANAGE_AWIC_NETWORK_ACCOUNT
  ),
  route("/awic/point-of-contact", PointOfContact, ROUTE_DASHBOARD, false, CMS_MANAGE_AWIC_POC),
  route("/awic/store", StoreEverflow, ROUTE_DASHBOARD, false, CMS_MANAGE_AWIC_STORE_EVERFLOW),
];

export default awicRoutes;
