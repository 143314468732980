import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useRef, useState } from "react";
import { openNotification } from "utils";
import {
  CLICK_UP_TASK_CREATION,
  COMMENETS_DELETE,
  COMMENETS_GET_ALL_MESSAGE,
  COMMENETS_LIKES_DISLIKES,
  COMMENTS_MARK_AS_UNREAD,
  COMMENT_SEEN,
  CREATE_COMMENETS,
  GET_CLICKUP_AUTH_CODE_CS,
  GET_CLICKUP_TEAMS,
  COMMENTS_GET_ALL_MESSAGES_BY_CATEGORY_ID,
  EDIT_COMMENT,
} from "shared/gql/gqlSchema/commentsGql";
import MessageCard from "./MessageCard";
import copy from "copy-to-clipboard";
import Loader from "components/loader";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import { requestAddedItemsData, requestItemsData } from "shared/enum/crsKeysAndEnum";
import { Avatar, Divider, Tooltip, Row, Col } from "antd";
import ClickUpTaskForm from "./ClickUpForm/ClickUpTaskForm";
import { useLookModal } from "components/LookModal";
import { debounce } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import popupWindow from "utils/popupWindow";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { useRecallBackInitial } from "hooks/callBackHooks";
import { useCheckClickupLogin } from "pages/dashboardPages/Unicorn/hooks";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import cx from "cx";
import SelectInput from "components/forms/SelectInput";
import { GET_SAS_ASSET_COUNTRIES } from "shared/gql/gqlSchema/sasGql";
import { SAS_COMMENT_SERVICE_TEAMS_ENUM } from "shared/enum/teamKeyAndEnum";
import { SAS_COMMENT_SERVICE_PLATFORMS_ENUM } from "shared/enum/platformsKeyAndEnum";
import LookMentionInput from "components/forms/LookMentionInput";
import CommentFilters from "./components/CommentFilters";

// Unit Component : extra icon selector component - platform and team selectors are same so common component created
const CommentExtraIconSelectorInput = ({
  selected = [], // [{value: '', name: ''}]
  selectedCallback,
  enumList,
  selectorLabel,
  multiSelect = false,
}) => {
  const handleIconSelect = (val) => {
    let updatedSelectedItems = [];
    if (multiSelect) {
      if (selected?.length && selected?.find((it) => it?.value === val?.value)) {
        updatedSelectedItems = selected?.filter((it) => it?.value !== val?.value);
      } else {
        updatedSelectedItems = selected?.length ? [...selected, val] : [val];
      }
    } else {
      updatedSelectedItems = [val];
    }
    selectedCallback(updatedSelectedItems);
  };

  return (
    <div className="d-flex">
      <span className="label-color">{selectorLabel}</span>
      {enumList.map((it) => (
        <LookButton
          key={it?.value}
          size="small"
          tooltipTitle={it?.name}
          iconType={it?.icon}
          onClick={() => handleIconSelect(it)}
          className={cx("no-border-btn", {
            isSelected: selected?.findIndex(({ value }) => value === it?.value) > -1,
          })}
        />
      ))}
    </div>
  );
};

function MainComments(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const meData = useSelector((state) => state?.userReducer?.me);
  const { isClickupUserLoggedIn = false } = userSettings || {};
  const {
    rowProps = {},
    visibleDrawer = false,
    params,
    drawerVisible = false,
    replyOrCommentBreadCrumb = () => {},
  } = props;
  const {
    selectedRequestData = {},
    rowData = {},
    dateRangeTimezone = {},
    setCountsCommentsData = () => {},
    commentsCountsData = [],
    reportId,
    extraCommentSelector,
    listingGql = COMMENETS_GET_ALL_MESSAGE,
    defaultPlatform = [],
    isFiltersNeeded = false,
  } = rowProps;
  const { umsCurrentWorkspaceUsersFetch = () => {} } = useApiToStoreContext();
  const workSpaceUsers = useSelector(
    (state) => state?.userSettings?.userSettingData?.umsCurrentWorkspaceUsers
  );

  const { clickup_checkLoginStatus, isClickupLoggedIn_loading, isClickupLoggedIn_refetch } =
    useCheckClickupLogin();

  const dateFormat = "YYYY-MM-DD";
  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Creating as Task",
    footer: false,
  });

  const { id = 0 } = selectedRequestData;
  const location = useLocation();
  const [messageIndex, setMessageIndex] = useState(null);
  const [unSeenComments, setUnSeenComments] = useState([]);
  const [isReplies, setIsReplies] = useState(false);
  const [allComments, setAllComments] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [allReplies, setAllReplies] = useState([]);
  const [comment, setComment] = useState("");
  const colorItems = [...requestItemsData, ...requestAddedItemsData];
  const [teamsValue, setTeamsValue] = useState("");
  const [isClickUpTask, setIsClickUpTask] = useState(false);
  const [currentElementForClickUp, setCurrentElementForClickUp] = useState({});
  const [error, setError] = useState("");
  const [currentData, setCurrentData] = useState({});
  const [scrollFlag, setScrollFlag] = useState(false);
  const [commentsLoadedFlag, setCommentsLoadedFlag] = useState(false);
  const [intialValueFlag, setIntialValueFlag] = useState(false);
  const [markAsUnreadIds, setMarkAsUnreadIds] = useState([]);
  const [initialValues, setInitialValues] = useState({
    assignedTo: "",
    description: "",
    priority: undefined,
    expectedDueDate: "",
  });
  const [selectedPlatform, setSelectedPlatform] = useState(defaultPlatform);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedEditingMessage, setSelectedEditingMessage] = useState(null);
  const [selectedMentionedUsers, setSelectedMentionedUsers] = useState([]);

  const [commentFilterOpenStatus, setCommentFilterOpenStatus] = useState(false);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);

  const validationSchema = yup.object({
    expectedDueDate: yup
      .date()
      .nullable("Expected Due Date is required")
      .required("Expected Due Date is required")
      .label("Expected Due Date"),
  });
  const commentRefs = useRef([]);
  const repliesContainerRef = useRef(null);
  const [extractedValue] = location?.pathname.substring(1).split("/");
  const { loading: loading_teams, refetch: refetch_teams } = useCustomQuery(GET_CLICKUP_TEAMS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const teamsList = e?.comment_get_clickup_team;
      const findTeam = teamsList?.find((item) => item?.name == `${extractedValue}/`);
      setTeamsValue(findTeam?.value);
    },
  });

  useRecallBackInitial(isClickupLoggedIn_refetch);

  const getKeyAndGqlForListing = () => {
    let obj = {};
    switch (listingGql) {
      case "COMMENTS_GET_ALL_MESSAGES_BY_CATEGORY_ID":
        obj = {
          gqlList: COMMENTS_GET_ALL_MESSAGES_BY_CATEGORY_ID,
          gqlKey: "comment_getAllMessageByCategoryId",
          gqlVariables: {
            categoryId: rowProps?.rowData?.categoryId || rowProps?.categoryId,
            // here we are setting the priority of countryID coming from props first and then from it's own data
            countryId: rowProps?.countryId || rowProps?.rowData?.countryId,
          },
        };
        break;
      default:
        obj = {
          gqlList: COMMENETS_GET_ALL_MESSAGE,
          gqlKey: "comment_getAllMessage",
          gqlVariables: {
            reportId: drawerVisible ? rowProps?.rowData?.reportId : rowProps?.reportId,
            reportType: drawerVisible ? rowProps?.rowData?.reportType : rowProps?.reportType,
            tableType: drawerVisible ? rowProps?.rowData?.tableType : rowProps?.tableType,
          },
        };
        break;
    }
    return obj;
  };

  const { gqlList, gqlKey, gqlVariables } = getKeyAndGqlForListing();

  const [
    getAllMessages,
    { loading: loading_COMMENETS_GET_ALL_MESSAGE, refetch: refetch_COMMENETS_GET_ALL_MESSAGE },
  ] = useCustomLazyQuery(gqlList, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[gqlKey]?.data;
      if (isReplies) {
        setAllReplies([...element]);
      }
      setAllComments([...element]);
      setCommentsLoadedFlag(true);
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "commnet-service-error" });
    },
  });

  const [getAllCountriesByCampaignID] = useCustomLazyQuery(GET_SAS_ASSET_COUNTRIES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      setAllCountries([]);
      const element = e?.sasAssetCountries;
      if (element?.length > 1) {
        const allCountries = element?.map((it) => ({
          value: it?.country?.id,
          name: it?.country?.name,
        }));
        setAllCountries(allCountries);
      }
    },
  });

  useEffect(() => {
    replyOrCommentBreadCrumb("Comments");
    umsCurrentWorkspaceUsersFetch();
    clickup_checkLoginStatus();
  }, []);

  useEffect(() => {
    setAllComments([]);
    const platformIds =
      selectedFilterOptions?.find((it) => it?.key === "Platform")?.selected || undefined;
    const teamIds = selectedFilterOptions?.find((it) => it?.key === "Team")?.selected || undefined;
    callGetAllMessages({
      platformId: platformIds,
      teamId: teamIds,
    });
  }, [selectedFilterOptions]);

  useEffect(() => {
    if (commentsLoadedFlag) {
      const scrollIndex = allComments?.findIndex(
        (item) => item?.id === rowProps?.rowData?.messageId
      );
      scrollToIndex(scrollIndex);
      setScrollFlag(true);
    }
  }, [commentsLoadedFlag]);

  const [
    likeDislikeMessages,
    { loading: loading_COMMENETS_LIKES_DISLIKES, refetch: refetch_COMMENETS_LIKES_DISLIKES },
  ] = useCustomMutation(COMMENETS_LIKES_DISLIKES, {
    // variables: variables,
    onCompleted: (e) => {
      refetchMessages();
    },
    onError(e) {
      const updateFunction = (prevState, index, field) => {
        const updatedState = [...prevState];
        updatedState[index] = {
          ...updatedState?.[index],
          [field]: updatedState?.[index]?.[field] - 1,
        };
        return updatedState;
      };

      const setFunction = (prevState, index, field) => {
        const updatedState = updateFunction(prevState, index, field);
        return isReplies ? setAllReplies(updatedState) : setAllComments(updatedState);
      };

      if (currentData?.name === "like") {
        setFunction(
          isReplies
            ? (allReplies, currentData?.index, "totalLike")
            : (allComments, currentData?.index, "totalLike")
        );
      } else {
        setFunction(
          isReplies
            ? (allReplies, currentData?.index, "totalDislike")
            : (allComments, currentData?.index, "totalDislike")
        );
      }
      openNotification({ type: "error", message: e?.message, key: "commnet-service-error" });
    },
  });

  // function scrollToLastComment() {
  //   const lastComment = commentRefs?.current?.[commentRefs?.current?.length - 1];
  //   lastComment?.scrollIntoView({ behavior: "smooth", block: "end" });
  // }

  const [addNewComment, { loading: loading_newComment }] = useCustomMutation(CREATE_COMMENETS, {
    onCompleted: (e) => {
      const element = e?.comment_create?.data;
      setCurrentElementForClickUp({ ...element });
      if (element) {
        const correctElement = { ...element, totalDislike: 0, totalLike: 0, totalReply: 0 };
        if (isClickUpTask) {
          setAllComments((prev) => [correctElement, ...prev]);
          clearInputs();
        } else {
          if (isReplies) {
            setAllReplies((prev) => [correctElement, ...prev]);
            clearInputs();
          }
          setAllComments((prev) => [correctElement, ...prev]);
          clearInputs();
        }
      }
      setIntialValueFlag(true);
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [editComment, { loading: loading_editComment }] = useCustomMutation(EDIT_COMMENT, {
    onCompleted: (e) => {
      const element = e?.comment_edit?.data;
      setCurrentElementForClickUp({ ...element });
      if (element) {
        // creating the updated comment object
        const updatedCommentsListAfterEditMessage = {
          ...selectedEditingMessage,
          message: comment,
          platform: getPlatformKeyValueObj(),
          team: getTeamKeyValueObj(),
          countryId: getCountryKeyValueObj(),
          mentionUser: getMentionedUsersKeyValueObj(),
          isEdit: true,
        };

        const callbackForUpdateAllComments = (prev) => {
          return [
            ...prev.slice(0, selectedEditingMessage.index),
            updatedCommentsListAfterEditMessage,
            ...prev.slice(selectedEditingMessage.index + 1),
          ];
        };

        if (isClickUpTask) {
          // setting the updated comment object of the editing comment in-place of allComments
          setAllComments(callbackForUpdateAllComments);
          clearInputs();
        } else {
          if (isReplies) {
            setAllReplies(callbackForUpdateAllComments);
            clearInputs();
          }
          // setting the updated comment object of the editing comment in-place of allComments
          setAllComments(callbackForUpdateAllComments);
          clearInputs();
        }
      }
      setIntialValueFlag(true);
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const clearInputs = () => {
    setComment("");
    setSelectedTeam([]);
    setSelectedPlatform([]);
    setSelectedCountry(null);
    setSelectedEditingMessage(null);
    setSelectedMentionedUsers([]);
  };

  const [deleteComment, { loading: loading_COMMENETS_DELETE }] =
    useCustomMutation(COMMENETS_DELETE);

  const [commentSeen, { loading: loading_COMMENT_SEEN }] = useCustomMutation(COMMENT_SEEN, {
    onCompleted: (e) => {
      setUnSeenComments([]);
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "commnet-service-error" });
    },
  });

  const [
    createClickUpTask,
    { loading: loading_CLICK_UP_TASK_CREATION, refetch: refetch_CLICK_UP_TASK_CREATION },
  ] = useCustomMutation(CLICK_UP_TASK_CREATION, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.comment_create_clickup_task;
      openNotification({ type: "success", message: "Task Added Successfully" });
      handleCloseModal();
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Click Up task not created. Teams not Authorised!",
      });
      handleCloseModal();
    },
  });

  const [
    markAsUnread,
    { loading: loading_COMMENTS_MARK_AS_UNREAD, refetch: refetch_COMMENTS_MARK_AS_UNREAD },
  ] = useCustomMutation(COMMENTS_MARK_AS_UNREAD, {
    // variables: variables,
    onCompleted: (e) => {
      const element = e?.comment_unseen;
      if (element) {
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "commnet-service-error" });
    },
  });

  const scrollToIndex = (index) => {
    const elementToFocus = commentRefs?.current[index];
    if (elementToFocus) {
      const elementToScrollTo = commentRefs?.current[index];
      elementToScrollTo.scrollIntoView({ behavior: "smooth" });
      elementToFocus.classList.add("highlighted-element-comments");
      setTimeout(() => {
        elementToFocus.classList.remove("highlighted-element-comments");
      }, 3000);
    }
  };

  const callGetAllMessages = (extraVariables = {}) => {
    getAllMessages({
      variables: { ...gqlVariables, ...extraVariables },
    });
  };

  useEffect(() => {
    if (!scrollFlag) {
      callGetAllMessages();
      // hit getAllCountriesByCampaignID only if country selector provided
      if (extraCommentSelector?.includes("country")) {
        getAllCountriesByCampaignID({
          variables: {
            assetId: rowProps?.rowData?.campaignId || rowProps?.campaignId,
            assetType: "campaignId",
            from:
              rowProps?.rowData?.dateRangeTimezone?.rangeDates[0] ||
              rowProps?.dateRangeTimezone?.rangeDates[0],
            timezone:
              rowProps?.rowData?.dateRangeTimezone?.timeZone?.value ||
              rowProps?.dateRangeTimezone?.timeZone?.value,
            to:
              rowProps?.rowData?.dateRangeTimezone?.rangeDates[1] ||
              rowProps?.dateRangeTimezone?.rangeDates[1],
          },
        });
      }
    }
  }, [scrollFlag]);

  const handleDelete = (data, isReplyParentMessage) => {
    const { id } = data;
    deleteComment({
      variables: { id },
      onCompleted: (_) => {
        const cb = (prev) => prev?.filter((obj) => obj.id !== data?.id);
        if (isReplies) {
          setAllReplies(cb);
          if (isReplyParentMessage) handleBack();
        } else {
          setAllComments(cb);
        }
        openNotification({ type: "success", message: "Comment Deleted Successfully" });
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message, key: "commnet-service-error" });
      },
    });
  };

  const handleSubmitForm = (values) => {
    createClickUpTask({
      variables: {
        commentId: currentElementForClickUp?.id,
        name: currentElementForClickUp?.message,
        team_id: `${teamsValue}`,
        userId: values?.assignedTo,
        description: values?.description,
        priority: values?.priority,
        dueDate: moment(values?.expectedDueDate).format("YYYY-MM-DD"),
      },
    });
  };

  const [
    getClickupAuthorizationCode,
    { data: CS_GET_CLICKUP_AUTH_CODE_DATA = [], loading: CS_GET_CLICKUP_AUTH_CODE_LOADING },
  ] = useCustomLazyQuery(GET_CLICKUP_AUTH_CODE_CS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      popupWindow(e?.clickup_getAuthorizationCode, "Authorise", 600, 800);
    },
  });

  const getPlatformKeyValueObj = () => {
    return selectedPlatform?.length
      ? selectedPlatform?.map((it) => ({ id: it.value, name: it.name }))
      : null;
  };

  const getTeamKeyValueObj = () => {
    return selectedTeam?.length
      ? selectedTeam?.map((it) => ({ id: it.value, name: it.name }))
      : null;
  };

  const getCountryKeyValueObj = () => {
    // if country ID is selected from dropdown then set it otherwise set the priority of countryID coming from props first and then from it's own data
    if (selectedCountry) {
      return selectedCountry;
    } else {
      return rowProps?.countryId || rowProps?.rowData?.countryId;
    }
  };

  const getMentionedUsersKeyValueObj = () => {
    // if country ID is selected from dropdown then set it otherwise set the priority of countryID coming from props first and then from it's own data
    if (selectedMentionedUsers?.length) {
      return selectedMentionedUsers?.map((it) => it?.id);
    } else {
      return null;
    }
  };

  const handleComment = () => {
    // if comment is in edit mode then run editComment gql otherwise createComment
    if (selectedEditingMessage?.id) {
      editComment({
        variables: {
          commentId: selectedEditingMessage?.id,
          message: comment.trim(),
          categoryId: selectedEditingMessage?.categoryId,
          countryId: selectedEditingMessage?.countryId,
          platform: getPlatformKeyValueObj(),
          team: getTeamKeyValueObj(),
          mentionUser: getMentionedUsersKeyValueObj(),
        },
      });
      return;
    }

    addNewComment({
      variables: {
        data: {
          parentId: isReplies ? messageIndex?.id : undefined,
          message: comment.trim(),
          reportId: drawerVisible ? rowProps?.rowData?.reportId : rowProps?.reportId,
          reportType: drawerVisible ? rowProps?.rowData?.reportType : rowProps?.reportType,
          tableType: drawerVisible ? rowProps?.rowData?.tableType : rowProps?.tableType,
          from: drawerVisible ? rowProps?.rowData?.from : dateRangeTimezone?.rangeDates?.[0],
          to: drawerVisible ? rowProps?.rowData?.to : dateRangeTimezone?.rangeDates?.[1],
          timeZone: drawerVisible
            ? rowProps?.rowData?.timeZone
            : dateRangeTimezone?.timeZone?.value,
          platform: getPlatformKeyValueObj(),
          team: getTeamKeyValueObj(),
          categoryId: rowProps?.rowData?.categoryId || rowProps?.categoryId,
          countryId: getCountryKeyValueObj(),
          mentionUser: getMentionedUsersKeyValueObj(),
          commentUrl: createLinkForComment(null, false),
        },
      },
    });
  };

  const refetchMessages = () => {
    refetch_COMMENETS_GET_ALL_MESSAGE();
  };

  const handleBack = () => {
    setIsReplies(false);
    replyOrCommentBreadCrumb("Comments");
    setAllReplies([]);
    setAllComments([]);
    callGetAllMessages();
  };

  const handleLikeDislike = (data, name, index) => {
    setCurrentData({ data, name, index });
    const { id, totalDislike, totalLike } = data;
    const updateReaction = (prevState, index, reactionType, reactionBoolean) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState?.[index],
        [reactionType]: updatedState?.[index]?.[reactionType] + 1,
        // [reactionBoolean]:updatedState?.[index]?.[reactionBoolean]?.find((item)=>item?.userId===meData?.user_id)
      };
      return updatedState;
    };

    if (isReplies) {
      setAllReplies((prevState) =>
        name === "like"
          ? updateReaction(prevState, index, "totalLike")
          : updateReaction(prevState, index, "totalDislike")
      );
    } else {
      setAllComments((prevState) =>
        name === "like"
          ? updateReaction(prevState, index, "totalLike", "like_dislikeInfo")
          : updateReaction(prevState, index, "totalDislike", "like_dislikeInfo")
      );
    }
    likeDislikeMessages({
      variables:
        name === "like"
          ? {
              id,
              totalLike: totalLike + 1,
            }
          : {
              id,
              totalDislike: totalDislike + 1,
            },
    });
  };

  function handleRequestForm() {
    setCurrentElementForClickUp({});
    setIsClickUpTask(true);
    if (isClickupUserLoggedIn) {
      if (comment?.length) {
        addNewComment({
          variables: {
            data: {
              message: comment.trim(),
              reportId: drawerVisible ? rowProps?.rowData?.reportId : rowProps?.reportId,
              reportType: drawerVisible ? rowProps?.rowData?.reportType : rowProps?.reportType,
              tableType: drawerVisible ? rowProps?.rowData?.tableType : rowProps?.tableType,
              from: drawerVisible ? rowProps?.rowData?.from : dateRangeTimezone?.rangeDates?.[0],
              to: drawerVisible ? rowProps?.rowData?.to : dateRangeTimezone?.rangeDates?.[1],
              timeZone: drawerVisible
                ? rowProps?.rowData?.timeZone
                : dateRangeTimezone?.timeZone?.value,
            },
          },
        });
        openModal();
      } else {
        setError("Please enter a value");
      }
    } else {
      getClickupAuthorizationCode();
    }
  }
  const handleMarkAsRead = (id) => {
    setMarkAsUnreadIds((prev) => (!prev?.includes(id) ? [...prev, id] : prev));

    const currentIndex = allComments?.findIndex((item) => item?.id === id);

    if (currentIndex !== -1) {
      setAllComments((prev) => {
        const updatedComments = [...prev];
        updatedComments[currentIndex] = { ...prev[currentIndex], isSeen: false };
        return updatedComments;
      });

      markAsUnread({ variables: { seenId: id } });
    } else {
      console.error("Comment not found with id:", id);
    }
  };

  const handleCommentSeen = (commentIndex) => {
    // Check if comment is unseen
    if (!allComments?.[commentIndex]?.isSeen && allComments?.[commentIndex]?.isSeen !== undefined) {
      // Set list of unSeen comments

      if (!unSeenComments?.includes(allComments?.[commentIndex]?.id)) {
        if (unSeenComments) {
          setUnSeenComments((prev) => {
            return [...prev, allComments?.[commentIndex]?.id];
          });
        }
      }
    }
  };

  const debounceSetUnSeenComments = debounce(
    (unSeenComments) => {
      //filter mark as unread values from seen values
      if (unSeenComments?.length) {
        commentSeen({ variables: { seenId: unSeenComments } });
      }
    },
    [1000]
  );

  useEffect(() => {
    // call if unSeencomment list changes
    if (visibleDrawer && unSeenComments?.length > 0) {
      const idsWithoutMarkAsUnread = unSeenComments?.filter((x) => !markAsUnreadIds?.includes(x));
      setAllComments((prevComments) =>
        prevComments?.map((obj) =>
          idsWithoutMarkAsUnread?.includes(obj?.id) ? { ...obj, isSeen: true } : obj
        )
      );

      debounceSetUnSeenComments(idsWithoutMarkAsUnread);
    }
  }, [unSeenComments]);

  const handleButtonClick = (data) => {
    setIsReplies(true);
    replyOrCommentBreadCrumb("Replies");
    setMessageIndex(data);
    callGetAllMessages({ parentId: data?.id });
  };

  const handleEditClick = (editingMsgData) => {
    setSelectedEditingMessage(editingMsgData);

    if (editingMsgData?.message) {
      setComment(editingMsgData?.message);
    }

    if (editingMsgData?.platform?.length) {
      setSelectedPlatform(
        editingMsgData?.platform?.map((it) => ({ value: it?.id || it?.value, ...it }))
      );
    }

    if (editingMsgData?.team?.length) {
      setSelectedTeam(editingMsgData?.team?.map((it) => ({ value: it?.id || it?.value, ...it })));
    }

    if (editingMsgData?.mentionUser?.length) {
      setSelectedMentionedUsers(
        editingMsgData?.mentionUser?.map((it) => ({
          value: it?.id || it?.value,
          key: it?.id || it?.value,
          label: it?.name,
        }))
      );
    }
  };

  const onFilterSubmitHandler = (data) => {
    setSelectedFilterOptions(data);
  };

  const onFilterResetHandler = () => {
    setSelectedFilterOptions([]);
  };

  const onCancelClick = () => {
    clearInputs();
  };

  const handleCloseModal = (data) => {
    setCurrentElementForClickUp({});
    setIsClickUpTask(false);
    clearInputs();
    closeModal();
    setIntialValueFlag(false);
    setMarkAsUnreadIds([]);
    setInitialValues({
      assignedTo: "",
      description: "",
      priority: undefined,
      expectedDueDate: "",
    });
  };

  // useEffect(() => {
  //   setIntialValueFlag(true);
  // }, [currentElementForClickUp]);

  useEffect(() => {
    if (intialValueFlag) {
      const baseUrl = window?.location?.href;
      const url = new URL(baseUrl);
      //-----------this will use in future-------------
      // setInitialValues((prevValues) => ({
      //   ...prevValues,
      //   description: `${url.href}?reportId=${currentElementForClickUp?.reportId}&messageId=${
      //     currentElementForClickUp?.id
      //   }&drawerVisible=true&reportType=${currentElementForClickUp?.reportType}&tableType=${
      //     currentElementForClickUp?.tableType
      //   }&from=${moment(currentElementForClickUp?.from).format(dateFormat)}&to=${moment(
      //     currentElementForClickUp?.to
      //   ).format(dateFormat)}&timeZone=${currentElementForClickUp?.timeZone}`,
      // }));
    }
  }, [intialValueFlag]);

  // this function use to create link for each comment on "copy to clipboard" and also will send in payload on adding new comment (commentUrl).
  // default value set to '$$' because on comment creation we don't have messageId so we will send $$ and on backend it will be replaced by generated messageId and then will save in DB.
  // we mainly need commentUrl for redirecting to comment section on notification click
  const createLinkForComment = (messageId, isHostRequired = true) => {
    if (!messageId) messageId = "$$";
    let baseUrl = window?.location?.href;
    const drawerVisible = params?.get("drawerVisible");
    if (baseUrl) {
      let url = new URL(baseUrl);
      url.searchParams.set("messageId", messageId);
      url.searchParams.set("drawerVisible", drawerVisible ? drawerVisible : true);
      url.searchParams.set(
        "reportId",
        drawerVisible ? params?.get("reportId") : rowProps?.reportId
      );
      url.searchParams.set(
        "reportType",
        drawerVisible ? params?.get("reportType") : rowProps?.reportType
      );
      url.searchParams.set(
        "tableType",
        drawerVisible ? params?.get("tableType") : rowProps?.tableType
      );
      url.searchParams.set(
        "from",
        drawerVisible ? params?.get("from") : dateRangeTimezone?.rangeDates?.[0]
      );
      url.searchParams.set(
        "to",
        drawerVisible ? params?.get("to") : dateRangeTimezone?.rangeDates?.[1]
      );
      url.searchParams.set(
        "timeZone",
        drawerVisible ? params?.get("timeZone") : dateRangeTimezone?.timeZone?.value
      );
      return decodeURIComponent(isHostRequired ? url.href : url?.pathname + url?.search);
    }
    return null;
  };

  const copyToClipboard = (messageId) => {
    const preparedUrl = createLinkForComment(messageId);
    if (preparedUrl) {
      copy(preparedUrl);
      openNotification({ type: "success", message: "Link copied to clipboard  " });
    }
  };

  useEffect(() => {
    // only change outer comments count if it is only comments but not replies
    if (!isReplies && allComments?.length) {
      const totalComments = allComments?.length;
      const totalUnseenComments = allComments?.filter((item) => !item?.isSeen);
      const totalUnseenCommentsLength = totalUnseenComments?.length;

      const isIdPresent = commentsCountsData?.some((obj) => obj?._id === reportId);
      if (totalComments > -1) {
        if (!isIdPresent) {
          const newObj = {
            _id: reportId,
            counts: [
              {
                userId: [],
                count: 0,
              },
            ],
            totalRecords: totalComments,
            totalUnSeen: totalUnseenCommentsLength,
          };
          const updatedState = [...commentsCountsData, newObj];
          setCountsCommentsData(updatedState);
        } else {
          setCountsCommentsData(
            (prevState) => {
              const updatedState = prevState?.map((obj) => {
                if (obj?._id === reportId) {
                  return {
                    ...obj,
                    totalRecords: totalComments,
                    totalUnSeen: totalUnseenCommentsLength,
                  };
                }
                return obj;
              });
              return updatedState;
            }
            // }
          );
        }
      }
    }
  }, [allComments]);

  //this useEffect will find the where we scroll and returning the index of scrolled comments
  useEffect(() => {
    if (allComments?.length) {
      const handleIntersection = (commentIndex, entry) => {
        if (entry.isIntersecting && allComments?.length) {
          handleCommentSeen(commentIndex);
        }
      };
      const observer = new IntersectionObserver(
        (entries) => {
          entries?.forEach((entry) => {
            const commentIndex = entry?.target?.getAttribute("comment-index");
            handleIntersection(commentIndex, entry);
          });
        },
        { root: null, rootMargin: "0px", threshold: 0.5 }
      );

      commentRefs?.current?.forEach((ref) => {
        if (ref) {
          observer?.observe(ref);
        }
      });

      return () => {
        commentRefs?.current?.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref);
          }
        });
      };
    }
  }, [allComments]);

  const handleFocus = () => {
    if (!comment.trim()) {
      setError("Please enter a value");
    } else {
      setError("");
    }
  };

  function detailMessageView(data) {
    const nameArray = data?.user?.name?.split(" ");
    const userInitials = nameArray?.map((data) => data.charAt(0));

    const findUser = data?.like_dislikeInfo?.find((item) => item.userId === meData?.user_id);
    return (
      <div className="message-card-container">
        <div className="message-card-spread">
          <div className="d-flex justify-content-between">
            <LookButton onClick={() => handleBack()}>
              <ActionIconRender iconType="backIcon" />
              Back
            </LookButton>
            <div>
              <span className="mr-5" onClick={() => copyToClipboard(data?.id)}>
                <Tooltip title="Copy Comment Link">
                  <ActionIconRender iconType="link-icon" />
                </Tooltip>
              </span>
              <span
                onClick={() => {
                  handleDelete(data, true);
                }}
              >
                <ActionIconRender iconType="delete" />
              </span>
            </div>
          </div>
          <div className="title-container">
            <div className="title">
              <Avatar
                style={{
                  verticalAlign: "middle",
                  width: "32px",
                  height: "32px",
                }}
              >
                {userInitials}
              </Avatar>
              <div className="name-date">
                <span className="name">{data?.user?.name}</span>
                <span className="date">
                  {moment(data?.from).format(dateFormat)} -{moment(data?.to).format(dateFormat)} (
                  {data?.timeZone})
                </span>
              </div>
            </div>
            <div className="sr-no">#{data?.index + 1}</div>
          </div>
          <div className="description">{data?.message}</div>
          <div className="footer">
            <div className="footer-actions">
              <div className="like-dislike">
                <span
                  className="like"
                  onClick={() => {
                    handleLikeDislike(props, "like");
                  }}
                >
                  <ActionIconRender iconType={findUser?.isLike ? "like" : "like-outlined"} />
                  <span className="mb-0 ml-4">{data?.totalLike}</span>
                </span>
                <span
                  className="dislike"
                  onClick={() => {
                    handleLikeDislike(props, "dislike");
                  }}
                >
                  <ActionIconRender iconType={findUser?.isDisLike ? "dislike-filled" : "dislike"} />
                  <span className="mb-0 ml-4">{data?.totalDislike}</span>
                </span>
              </div>
              <div className="replies">
                <span className="mb-0">{data?.totalReply}</span>
                <span className="mb-0 mr-2 ml-2">Replies</span>
              </div>
              <div className="task">
                <ActionIconRender iconType="click-up" />
              </div>
            </div>
            <div className="message-time">Just now</div>
          </div>
        </div>
      </div>
    );
  }

  const selectTeamHandler = (val) => {
    setSelectedTeam(val);
  };

  const selectPlatformHandler = (val) => {
    setSelectedPlatform(val);
  };

  const selectCountryHandler = (val) => {
    setSelectedCountry(val);
  };

  const renderActionsOnSend = () => {
    return (
      <>
        <LookButton
          className="mb-5"
          // disabled={loading_COMMENETS_GET_ALL_MESSAGE}
          loading={loading_newComment}
          type="primary"
          iconType="send"
          size="small"
          onClick={handleComment}
          disabled={!!!comment.trim()}
          style={{ width: "24", height: "24" }}
        />
        <Divider className="mb-5 mt-0" />
        <LookButton
          size="small"
          type="link"
          iconType="click-up"
          onClick={() => handleRequestForm()}
          style={{ width: "24", height: "24" }}
        />
      </>
    );
  };

  const renderActionsOnEdit = () => {
    return (
      <>
        <LookButton
          className="mb-5"
          loading={loading_editComment}
          type="link"
          iconType="save"
          size="small"
          onClick={handleComment}
          disabled={!!!comment.trim()}
          style={{ width: "24", height: "24" }}
        />
        <Divider className="mb-5 mt-0" />
        <LookButton
          type="link"
          size="small"
          iconType="close"
          onClick={onCancelClick}
          style={{ width: "24", height: "24", color: "#d9d9d9" }}
        />
      </>
    );
  };

  return (
    <div className="comments-container">
      {!isReplies && (
        <>
          {allComments?.length > 0 && isFiltersNeeded && (
            <Row justify="end" align="bottom" className="mt-10 mb-10">
              <Col span={2}>
                <CommentFilters
                  isPopoverOpened={commentFilterOpenStatus}
                  filterOpenStatusHandler={setCommentFilterOpenStatus}
                  selectedFilters={selectedFilterOptions}
                  filteringOptions={[
                    { label: "Platform", options: [...SAS_COMMENT_SERVICE_PLATFORMS_ENUM] },
                    { label: "Team", options: [...SAS_COMMENT_SERVICE_TEAMS_ENUM] },
                  ]}
                  onSubmitHandler={onFilterSubmitHandler}
                  onResetHandler={onFilterResetHandler}
                />
              </Col>
            </Row>
          )}
          <div className="comment-view position-relative">
            {loading_COMMENETS_GET_ALL_MESSAGE && allComments?.length === 0 ? (
              <Loader title="Fetching comments..." elementCenter center />
            ) : allComments?.length === 0 ? (
              <div className="text-center pt-100">
                <div style={{ transform: "translateX(20px)" }}>
                  <ActionIconRender iconType="tablePlaceholder" />
                </div>
                <p style={{ fontSize: "24px" }}>
                  <b>No comments Found...</b>
                </p>
              </div>
            ) : (
              <>
                {allComments?.map((data, index) => (
                  <div className="message-card-container">
                    <div
                      key={index}
                      className={cx(
                        "message-card",
                        selectedEditingMessage?.id === data?.id ? "active-message" : ""
                      )}
                      ref={(ref) => (commentRefs.current[index] = ref)}
                      comment-index={index}
                    >
                      <MessageCard
                        index={index}
                        colorItems={colorItems}
                        setIsReplies={setIsReplies}
                        handleButtonClick={handleButtonClick}
                        {...data}
                        handleLikeDislike={handleLikeDislike}
                        handleDelete={handleDelete}
                        copyToClipboard={copyToClipboard}
                        loading={loading_COMMENETS_LIKES_DISLIKES}
                        meData={meData}
                        handleMarkAsRead={handleMarkAsRead}
                        isReplies={isReplies}
                        handleDeleteLoading={loading_COMMENETS_DELETE}
                        handleEditClick={handleEditClick}
                        isEditModeOn={selectedEditingMessage?.id === data?.id}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}

      {isReplies && (
        <div ref={repliesContainerRef} className="comment-view position-relative">
          {isReplies && detailMessageView(messageIndex)}
          <Divider />
          {loading_COMMENETS_GET_ALL_MESSAGE ? (
            <div className="text-center">
              <Loader title="Fetching replies..." />
            </div>
          ) : allReplies?.length === 0 ? (
            <div className="text-center pt-50">
              <div style={{ transform: "translateX(20px)" }}>
                <ActionIconRender iconType="tablePlaceholder" />
              </div>
              <p style={{ fontSize: "24px" }}>
                <b>No Replies Found...</b>
              </p>
            </div>
          ) : (
            isReplies &&
            allReplies?.map((data, index) => (
              <div className="message-card-container">
                <div key={index} className="message-card">
                  <MessageCard
                    index={index}
                    handleButtonClick={handleButtonClick}
                    colorItems={colorItems}
                    setIsReplies={setIsReplies}
                    handleDelete={handleDelete}
                    {...data}
                    handleLikeDislike={handleLikeDislike}
                    copyToClipboard={copyToClipboard}
                    isReplies={isReplies}
                    meData={meData}
                    handleEditClick={handleEditClick}
                    isEditModeOn={selectedEditingMessage?.id === data?.id}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      )}
      <div className="input-view">
        <div className="comment-input-wrapper ">
          <div className="input-holder">
            {extraCommentSelector?.length > 0 && (
              <>
                <div className="d-flex justify-content-between mb-12">
                  {extraCommentSelector.includes("team") && (
                    <div>
                      <CommentExtraIconSelectorInput
                        selected={selectedTeam}
                        selectedCallback={selectTeamHandler}
                        enumList={SAS_COMMENT_SERVICE_TEAMS_ENUM}
                        selectorLabel={"Team: "}
                        multiSelect
                      />
                    </div>
                  )}
                  {extraCommentSelector.includes("platform") && (
                    <div>
                      <CommentExtraIconSelectorInput
                        selected={selectedPlatform}
                        selectedCallback={selectPlatformHandler}
                        enumList={SAS_COMMENT_SERVICE_PLATFORMS_ENUM}
                        selectorLabel={"Platform: "}
                      />
                    </div>
                  )}
                </div>

                <Row>
                  {allCountries &&
                    allCountries?.length > 0 &&
                    extraCommentSelector.includes("country") && (
                      <Col span={24}>
                        <SelectInput
                          search
                          selectClassName="w-100  mb-10"
                          placeholder="Select Country"
                          value={selectedCountry}
                          onChange={selectCountryHandler}
                          options={allCountries}
                        />
                      </Col>
                    )}
                </Row>
              </>
            )}

            <LookMentionInput
              name="comment_mention"
              placeholder="Add Comments"
              type="textarea"
              value={comment}
              mentioned={selectedMentionedUsers}
              onChange={setComment}
              onFocus={handleFocus}
              error={!comment.length ? error : ""}
              searchList={workSpaceUsers?.map((it) => ({
                key: it?.id,
                value: it?.name,
                label: it?.name,
              }))}
              onSelectedMentions={setSelectedMentionedUsers}
            />
          </div>

          {visibleModal &&
            modal(
              <ClickUpTaskForm
                workSpaceUsers={workSpaceUsers}
                initialValues={initialValues}
                validationSchema={validationSchema}
                handleSubmitForm={handleSubmitForm}
                handleCloseModal={handleCloseModal}
                loadingNewComment={loading_newComment}
                loadingClickUpTask={loading_CLICK_UP_TASK_CREATION}
              />
            )}
          <div className="comment-actions mt-15">
            {!selectedEditingMessage?.id ? renderActionsOnSend() : renderActionsOnEdit()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainComments;
