import DrawerFooterBotton from "components/Button/DrawerFooterBotton";
import CampainSettingsTab from "./CampainSettingsTab";
import MainSteper from "./MainSteper";
import TargettingsTab from "./TargettingsTab";
import { useMemo, useState } from "react";
import BiddingBudgetTab from "./BiddingBudgetTab";
import AdGroupAdTab from "./AdGroupAdTab";
import AdAccountModal from "./AdAccountSelection";
import { useGoogleLaunchV1Context } from "../Context";

export default function MainView(props) {
  const [formStep, setFormStep] = useState(0);
  const { campaignObject, targetingObject, launchName, trackingUrl } = useGoogleLaunchV1Context();
  const { loading_g360_bulk_launch = false } = props;
  /**
   * renderTabContent -> Form steps and data views
   *
   * CampainSettingsTab: having campaign objective and campaign type
   * TargettingsTab: having Targetting views
   * BiddingBudgetTab: having Bidding, Target CPA and Budget
   * AdGroupAdTab: here ad listing and create new add views
   */

  const { submitLaunch } = useGoogleLaunchV1Context();

  const renderTabContent = () => (
    <>
      {formStep === 0 && <CampainSettingsTab />}
      {formStep === 1 && <TargettingsTab />}
      {formStep === 2 && <BiddingBudgetTab />}
      {formStep === 3 && <AdGroupAdTab />}
    </>
  );

  const invalidLaunch = useMemo(() => {
    let adLevelValidatidate = [];
    let checkVar = true;
    if (formStep === 0) {
      checkVar = campaignObject[0]?.name === "" || launchName === "";
    }
    if (formStep === 1) {
      checkVar = trackingUrl ? false : true;
    }
    if (formStep === 2) {
      checkVar = false;
    }
    if (formStep === 3) {
      campaignObject?.forEach(({ adGroups }) => {
        adGroups?.forEach(({ name = "", ads }) => {
          adLevelValidatidate?.push(!(ads?.length > 0 && name));
        });
      });
      return adLevelValidatidate?.some((valid) => valid !== false);
    }
    return checkVar;
  }, [launchName, campaignObject, targetingObject, formStep, trackingUrl]);

  const submitButtonAction = () => {
    /**
     * This function is use for got to next tab and in final for submit launch
     */

    if (formStep < 3) {
      setFormStep(formStep + 1);
    } else if (formStep === 3) {
      submitLaunch();
    }
  };

  const backNextActions = [
    {
      label: "Back",
      onClick: () => (formStep > 0 ? setFormStep(formStep - 1) : null),
      size: "large",
    },
    {
      label: formStep === 3 ? "Publish Launch" : "Next",
      type: "primary",
      size: "large",
      htmlType: "submit",
      onClick: submitButtonAction,
      disabled: invalidLaunch,
      loading: loading_g360_bulk_launch,
    },
  ];

  return (
    <>
      <AdAccountModal />
      <div className="googleLaunch">
        <MainSteper step={formStep} />
        {renderTabContent()}
      </div>
      <DrawerFooterBotton buttons={backNextActions} />
    </>
  );
}
