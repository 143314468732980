import { SLACK_GET_WORKSPACES } from "shared/gql/gqlSchema/slackGql";
import { API_STORE_SLACK_WORKSPACES } from "./keys";

export default function useSlackApis(typeKey) {
  switch (typeKey) {
    case API_STORE_SLACK_WORKSPACES:
      return {
        api: SLACK_GET_WORKSPACES,
        elementKey: "Notif_GetWorkspaces",
        storeKey: "notifWorkspaces",
      };

    default:
      return false;
  }
}
