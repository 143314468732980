import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import { DUPLICATE_CAMPS, GET_CAMPAIGN_DETAILS } from "shared/gql/gqlSchema/leadgenGql";
import { openNotification } from "utils";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { REPORT_TYPE_AD_ADSET } from "shared/SharedKeys";
import Loader from "components/loader";

export default function DuplicateCampaigns(props) {
  const {
    campaignId: propCampaignId = "",
    status,
    name: propName = "",
    onCloseCampaignModal = () => {},
    adsetId = "",
    reportType = "",
  } = props;

  const [campaignDetails, setCampaignDetails] = useState({});
  const [isError, setIsError] = useState("");
  const [duplicateCampaigns, { loading }] = useCustomMutation(DUPLICATE_CAMPS, {
    onCompleted(e) {
      const element = e?.copyCampaignsAdsetsAds;
      onCloseCampaignModal();
      const { message = "", success = false, title = "" } = element || {};
      openNotification({
        type: success ? "success" : "error",
        message: success ? "Campaign cloned successfully" : title || message,
      });
    },
    onError(e) {
      openNotification({
        type: "error",
        message: e.message,
      });
    },
  });

  const [
    fetch_campaignDetails,
    { loading: loading_fetchCampaignDetails, refetch: refetch_fetchCampaignDetails },
  ] = useCustomLazyQuery(GET_CAMPAIGN_DETAILS, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (e) => {
      setCampaignDetails(e?.campaignDetails);
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  useEffect(() => {
    if (reportType === REPORT_TYPE_AD_ADSET) {
      fetch_campaignDetails({ variables: { adsetId } });
    }
  }, []);
  const resetFormRef = useRef(() => {});

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const { count, budget, isLifeTime, prefix, status, suffix, name, budgetOptimization } = values;
    let submitValues = {};
    switch (isLifeTime) {
      case true:
        submitValues = {
          camp: reportType === REPORT_TYPE_AD_ADSET ? campaignDetails?.campaignId : propCampaignId,
          count,
          lifetimeBudget: budget,
          prefix,
          suffix,
          status,
          name,
          budgetOptimization,
        };
        break;

      default:
        submitValues = {
          camp: reportType === REPORT_TYPE_AD_ADSET ? campaignDetails?.campaignId : propCampaignId,
          count,
          dailyBudget: budget,
          prefix,
          suffix,
          status,
          name,
          budgetOptimization,
        };
        break;
    }
    duplicateCampaigns({
      variables: {
        ...submitValues,
      },
    });
    clearError();
  }
  const initialValues = {
    name: reportType === REPORT_TYPE_AD_ADSET ? campaignDetails?.campaignName : propName,
    budget: "",
    isLifeTime: false,
    status,
    budgetOptimization: "",
  };

  const validationSchema = yup.object({
    budget: yup.number().required().label("budget"),
    count: yup.number().required().label("count"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    btnText: "Create",
    reportType,
  };

  return (
    <>
      {loading_fetchCampaignDetails ? (
        <Loader elementCenter className="mt-25" />
      ) : (
        <MainFrom {...formProps} />
      )}
    </>
  );
}
