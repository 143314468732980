import { gql } from "@apollo/client";

export const GQL_RE_ADDUSERPLATFORMRULE = gql`
  mutation Re_addUserPlatformRule(
    $userId: Int
    $cronCommand: String!
    $groupBy: groupBy
    $reportType: report_type
    $platform: platform
    $product: Int
    $action: String!
    $all: [Object!]!
    $name: String!
    $timezone: String!
    $report_timeline: String!
    $message: String
    $ids: Array
    $category_name: Array
    $exclusion_name: Array
    $is_orType: Boolean
    $budget: Int
    $budget_type: String
    $reporting_days: Int
    $comparision: Array
    $strategy_id: String
    $last_x_days: Int
    $is_pulse: Boolean
    $exclude_pulse: Boolean
    $country: Array
    $ad_account_ids: Array
    $bid: Object
  ) {
    re_addUserPlatformRule(
      userId: $userId
      cron_command: $cronCommand
      groupBy: $groupBy
      report_type: $reportType
      platform: $platform
      product: $product
      action: $action
      all: $all
      name: $name
      timezone: $timezone
      report_timeline: $report_timeline
      message: $message
      ids: $ids
      category_name: $category_name
      exclusion_name: $exclusion_name
      is_orType: $is_orType
      budget: $budget
      budget_type: $budget_type
      reporting_days: $reporting_days
      strategy_id: $strategy_id
      comparision: $comparision
      last_x_days: $last_x_days
      is_pulse: $is_pulse
      exclude_pulse: $exclude_pulse
      country: $country
      ad_account_ids: $ad_account_ids
      bid: $bid
    ) {
      message
      status
    }
  }
`;
export const GQL_RE_ADDSTRATEGY = gql`
  mutation Mutation(
    $name: String!
    $country: Array!
    $s_tag: String!
    $platform: platform
    $product: Int
  ) {
    re_addStrategy(
      name: $name
      country: $country
      s_tag: $s_tag
      platform: $platform
      product: $product
    ) {
      message
      status
    }
  }
`;
export const GQL_RE_GETTAGS = gql`
  query Re_getTag {
    re_getTag {
      s_tag
      name
    }
  }
`;

export const GQL_RE_GETRULE = gql`
  query Re_getRule(
    # $groupBy: groupBy
    # $reportType: report_type
    $platform: platform
    $product: Int
  ) {
    re_getRule(
      # groupBy: $groupBy
      # report_type: $reportType
      platform: $platform
      product: $product
    ) {
      id
      user_id
      name
      action
      all
      cron_command
      timezone
      report_timeline
      message
      ids
      status
      previousStatus
      category_name
      exclusion_name
      is_orType
      budget
      budget_type
      reporting_days
      nextOccurrence
      previousOccurrence
      processed_count
      error_count
      created_at
      groupBy
      platform
      report_type
      comparision
      last_x_days
      is_pulse
      exclude_pulse
      country
      ad_account_ids
      bid
    }
  }
`;
export const GQL_RE_GETALLSTARTEGY = gql`
  query Query($platform: platform, $product: Int) {
    re_getAllStrategy(platform: $platform, product: $product) {
      id
      name
      country
      s_tag
    }
  }
`;
export const GQL_RE_GETRULEBYSTRATEGY = gql`
  query Re_getRulebyStartegy($platform: platform, $product: Int, $strategy_id: ID) {
    re_getRulebyStartegy(platform: $platform, product: $product, strategy_id: $strategy_id) {
      id
      user_id
      name
      action
      all
      cron_command
      timezone
      report_timeline
      message
      ids
      status
      previousStatus
      category_name
      exclusion_name
      is_orType
      budget
      reporting_days
      nextOccurrence
      previousOccurrence
      processed_count
      error_count
      created_at
      groupBy
      report_type
      strategy_id
      platform
    }
  }
`;
export const GQL_RE_GETUSERACTIVITY = gql`
  query Re_getuserActivity(
    $rule_id: ID!
    $page: Int!
    $perPage: Int!
    $from: Date
    $to: Date
    $user_id: ID
  ) {
    re_getuserActivity(
      rule_id: $rule_id
      page: $page
      perPage: $perPage
      from: $from
      to: $to
      user_id: $user_id
    ) {
      activity {
        rule_id
        user_id
        name
        action
        groupBy
        activity
        created_at
      }
      page
      perPage
      totalDocuments
      totalPages
    }
  }
`;
export const GQL_RE_GETUSERACTIVITYBYASSET = gql`
  query Re_getuserActivityByAsset($rule_id: ID!, $page: Int!, $perPage: Int!, $asset_id: [ID!]) {
    re_getuserActivityByAsset(
      rule_id: $rule_id
      page: $page
      perPage: $perPage
      asset_id: $asset_id
    ) {
      activity {
        rule_id
        user_id
        name
        action
        groupBy
        activity
        created_at
      }
      page
      perPage
      totalDocuments
      totalPages
    }
  }
`;
export const GQL_RE_UPDATERULE = gql`
  mutation Re_updateRule(
    $id: String!
    $name: String!
    $groupBy: String!
    $report_type: report_type
    $all: [Object]
    $cronCommand: String
    $action: String
    $timezone: String
    $report_timeline: String
    $message: String
    $ids: Array
    $category_name: Array
    $exclusion_name: Array
    $is_orType: Boolean
    $budget: Int
    $budget_type: String
    $reporting_days: Int
    $platform: platform
    $comparision: Array
    $last_x_days: Int
    $is_pulse: Boolean
    $exclude_pulse: Boolean
    $country: Array
    $ad_account_ids: Array
    $bid: Object
  ) {
    re_updateRule(
      id: $id
      name: $name
      all: $all
      cron_command: $cronCommand
      action: $action
      timezone: $timezone
      report_timeline: $report_timeline
      message: $message
      ids: $ids
      category_name: $category_name
      exclusion_name: $exclusion_name
      is_orType: $is_orType
      budget: $budget
      budget_type: $budget_type
      reporting_days: $reporting_days
      groupBy: $groupBy
      report_type: $report_type
      platform: $platform
      comparision: $comparision
      last_x_days: $last_x_days
      is_pulse: $is_pulse
      exclude_pulse: $exclude_pulse
      country: $country
      ad_account_ids: $ad_account_ids
      bid: $bid
    ) {
      message
      status
    }
  }
`;
export const GQL_RE_UPDATESTRATEGY = gql`
  mutation Re_updateStrategy($strategy_id: ID!, $name: String, $country: Array, $s_tag: String) {
    re_updateStrategy(strategy_id: $strategy_id, name: $name, country: $country, s_tag: $s_tag) {
      message
      status
    }
  }
`;
export const GQL_RE_COPY_RULES = gql`
  mutation Mutation($strategy_id: ID!, $rule_ids: [String]!) {
    re_copyRules(strategy_id: $strategy_id, rule_ids: $rule_ids) {
      message
      status
    }
  }
`;
export const GQL_RE_UPDATERULESTATUS = gql`
  mutation Re_updateRuleStatus(
    $id: String!
    $status: String!
    $groupBy: String!
    $rule_paused: Boolean!
    $rule_ids: [ID]
  ) {
    re_updateRuleStatus(
      id: $id
      status: $status
      groupBy: $groupBy
      rule_paused: $rule_paused
      rule_ids: $rule_ids
    ) {
      message
      status
    }
  }
`;
export const GQL_RE_DELETESTARTEGY = gql`
  mutation Re_deleteStrategy($strategy_id: ID!) {
    re_deleteStrategy(strategy_id: $strategy_id) {
      message
      status
    }
  }
`;
export const GQL_RE_DELETERULE = gql`
  mutation Re_deleteRule($id: String!, $groupBy: String, $rule_ids: [ID]) {
    re_deleteRule(id: $id, groupBy: $groupBy, rule_ids: $rule_ids) {
      message
      status
    }
  }
`;
export const GQL_RE_MANUALRUN = gql`
  query Re_manualrun($id: ID!, $name: String!) {
    re_manualrun(id: $id, name: $name) {
      message
      status
    }
  }
`;
export const GQL_SAS_CONTRIES = gql`
  query SasCountries {
    SasCountries {
      value: id
      name
      iso_code
    }
  }
`;
export const GQL_SAS_CATEGORIES = gql`
  query SasCategories($isRsfc: Boolean) {
    sasCategories(isRsfc: $isRsfc) {
      value: id
      name
    }
  }
`;
export const GQL_RE_GETADMINRULE = gql`
  query Re_getAdminRule($platform: platform, $product: Int) {
    re_getAdminRule(platform: $platform, product: $product) {
      id
      user_id
      name
      action
      all
      cron_command
      timezone
      report_timeline
      message
      ids
      status
      previousStatus
      category_name
      exclusion_name
      is_orType
      budget
      budget_type
      reporting_days
      nextOccurrence
      previousOccurrence
      processed_count
      error_count
      created_at
      groupBy
      platform
      report_type
      comparision
      last_x_days
      is_pulse
      country
      ad_account_ids
      bid
    }
  }
`;
