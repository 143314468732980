import { useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";
import alasql from "alasql";
import cx from "classnames";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { secondsToHMS } from "utils";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import { GET_F360_CATEGORY_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import { GET_VOLUUM_CATEGORY_REPORT } from "shared/gql/gqlSchema/tiktok";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";

export default function CategoryReport(props) {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN, userId } = props;

  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const [spendReport, setSpendReport] = useState([]);
  const [revenueReport, setRevenueReport] = useState([]);

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const {
    loading: loading_GET_GET_F360_CATEGORY_REPORT,
    refetch: refetch_GET_GET_F360_CATEGORY_REPORT,
  } = useCustomQuery(GET_F360_CATEGORY_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.fb360_categoryReporting;
      if (element) {
        setSpendReport(
          element?.map((item) => ({
            ...item,
            campaignId: item?.campaign_id,
            adAccountId: item?.adaccount_id,
            clicksFB: item?.inline_link_clicks,
            spendFB: item?.spend,
          }))
        );
      }
    },
    variables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      user_id: userId,
    },
  });

  const {
    loading: loading_GET_VOLUUM_CATEGORY_REPORT,
    refetch: refetch_GET_VOLUUM_CATEGORY_REPORT,
  } = useCustomQuery(GET_VOLUUM_CATEGORY_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.voluum_category_report;
      if (element) {
        setRevenueReport(
          element?.map((item) => ({
            ...item,
            campaignId: item?.campaign_id,
            adsetId: item?.ad_set_id,
            adId: item?.ad_id,
            adAccountId: item?.ad_account_id,
          }))
        );
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      user_id: userId,
    },
  });

  const loading = loading_GET_GET_F360_CATEGORY_REPORT || loading_GET_VOLUUM_CATEGORY_REPORT;

  function refetch() {
    refetch_GET_GET_F360_CATEGORY_REPORT();
    refetch_GET_VOLUUM_CATEGORY_REPORT();
  }

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const initialColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return <LookTableColumnRender title={value} isProfit={PROFIT} />;
      },
      fixed: "left",
      width: 190,
      minWidth: 190,
    },

    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revenue - b?.revenue,
    },

    {
      title: "X Revenue",
      dataIndex: "x_revenue",
      key: "x_revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.x_revenue - b?.x_revenue,
    },

    {
      title: "Ringba Revenue",
      dataIndex: "payout_amount",
      key: "payout_amount",
      hidden: true,
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.payout_amount - b?.payout_amount,
    },

    {
      title: "Actual Revenue",
      dataIndex: "actualRevenue",
      key: "actualRevenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.actualRevenue - b?.actualRevenue,
    },

    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value, otherProps) => {
        const profit = `$${value.toFixed(2)}`;
        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit?.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
    },

    {
      title: "Actual Gross Margin",
      dataIndex: "actualGrossMargin",
      key: "actualGrossMargin",
      render: (value, otherProps) => {
        const profit = `$${value.toFixed(2)}`;
        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.actualGrossMargin - b?.actualGrossMargin,
    },

    {
      title: "ROAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROAS - b?.ROAS,
    },

    {
      title: "Visits",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
    },
    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
    },
    {
      title: "Clicks (V)",
      dataIndex: "revClicks",
      key: "revClicks",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.revClicks - b?.revClicks,
    },
    {
      title: "FB Results",
      dataIndex: "result",
      key: "result",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.result - b?.result,
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.conversions - b?.conversions,
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
    },
    {
      title: "PAYOUT",
      dataIndex: "PAYOUT",
      key: "PAYOUT",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.PAYOUT - b?.PAYOUT,
    },

    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
    },
    {
      title: "EPV",
      dataIndex: "EPV",
      key: "EPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPV - b?.EPV,
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPC - b?.CPC,
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPR - b?.CPR,
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPC - b?.EPC,
    },

    {
      title: "Call Count",
      dataIndex: "call_count",
      key: "call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.call_count - b?.call_count,
      searchType: "number",
    },
    {
      title: "Converted Calls",
      dataIndex: "converted_calls",
      key: "converted_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.converted_calls - b?.converted_calls,
      searchType: "number",
    },
    {
      title: "Avg Handle Time",
      dataIndex: "avg_handle_time",
      key: "avg_handle_time",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Call Length",
      dataIndex: "call_length",
      key: "call_length",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Blocked Calls",
      dataIndex: "blocked_calls",
      key: "blocked_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.blocked_calls - b?.blocked_calls,
      searchType: "number",
    },
    {
      title: "Duplicate Calls",
      dataIndex: "duplicate_calls",
      key: "duplicate_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.duplicate_calls - b?.duplicate_calls,
      searchType: "number",
    },
    {
      title: "Ended Calls",
      dataIndex: "ended_calls",
      key: "ended_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.ended_calls - b?.ended_calls,
      searchType: "number",
    },
    {
      title: "Connected Call Count",
      dataIndex: "connected_call_count",
      key: "connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.connected_call_count - b?.connected_call_count,
      searchType: "number",
    },
    {
      title: "Non Connected Calls",
      dataIndex: "non_connected_call_count",
      key: "non_connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.non_connected_call_count - b?.non_connected_call_count,
      searchType: "number",
    },
    {
      title: "RPC (Call)",
      dataIndex: "RPC_CALL",
      key: "RPC_CALL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.RPC_CALL - b?.RPC_CALL,
      searchType: "number",
    },
    {
      title: "CPR (Call)",
      dataIndex: "CPR_CALL",
      key: "CPR_CALL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.CPR_CALL - b?.CPR_CALL,
      searchType: "number",
    },
    {
      title: "AD_CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
    },
    {
      title: "LP_CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
    },
    {
      title: "V Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.visits - b?.visits,
    },
  ];

  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    finalMerge = alasql(
      "SELECT *, b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b  ON a.category.id = b.category.id",
      [spendReport, revenueReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        revenue = 0,
        visits = 0,
        revClicks = 0,
        conversions = 0,
        result = 0,
        ad_set_id,
        adset_id,
        adset_name = "",
        call_count = 0,
        converted_calls = 0,
        payout_amount = 0,
        avg_handle_time = 0,
        blocked_calls = 0,
        call_length = 0,
        connected_call_count = 0,
        duplicate_calls = 0,
        ended_calls = 0,
        non_connected_call_count = 0,
        daily_budget,
        category = {},
        x_revenue = 0,
      } = element;
      const addSpendPer = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;
      const spendUpdate = (spendFB * addSpendPer).toFixed(2);
      const combinedRevenue = revenue + payout_amount;
      const updatedRevenue = parseFloat(combinedRevenue) + parseFloat(x_revenue);
      const budgetForSorter =
        daily_budget === null || daily_budget === undefined ? -1 : daily_budget; // -1 for CBO to last

      const actualRevenue = (revenue ?? 0) + (x_revenue ?? 0);
      const actualGrossMargin = parseFloat(actualRevenue) - parseFloat(spendUpdate);

      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;
      const PROFIT = parseFloat(updatedRevenue) - parseFloat(spendUpdate);
      const ROAS = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const CPC = parseFloat(spendUpdate) / parseFloat(revClicks);
      const PAYOUT = parseFloat(updatedRevenue) / parseFloat(conversions);
      const EPV = parseFloat(updatedRevenue) / parseFloat(clicksFB);
      const EPC = parseFloat(updatedRevenue) / parseFloat(revClicks);
      const AD_CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(clicksFB)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const CPR = parseFloat(spendUpdate) / parseFloat(conversions);
      const RPC_CALL = parseFloat(updatedRevenue) / parseFloat(call_count);
      const CPR_CALL = parseFloat(spendUpdate) / parseFloat(call_count);

      finalMergeData.push({
        ...element,
        key: index,
        visits,
        revenue: updatedRevenue,
        clicksFB,
        impressionsFB,
        result,
        revClicks,
        conversions,
        budgetForSorter,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROAS: valueCheck(ROAS),
        CR: valueCheck(CR),
        PAYOUT: valueCheck(PAYOUT),
        CPV: valueCheck(CPV),
        EPV: valueCheck(EPV),
        CPC: valueCheck(CPC),
        EPC: valueCheck(EPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        CPR: valueCheck(CPR),
        adsetName: adset_name,
        adsetId: ad_set_id || adset_id,
        call_count,
        converted_calls,
        avg_handle_time,
        blocked_calls,
        call_length,
        connected_call_count,
        duplicate_calls,
        ended_calls,
        non_connected_call_count,
        RPC_CALL: valueCheck(RPC_CALL),
        CPR_CALL: valueCheck(CPR_CALL),
        name: category?.name || "",
        actualGrossMargin: valueCheck(actualGrossMargin),
        actualRevenue: valueCheck(actualRevenue),
      });
    });
  }
  const finalFilterData = finalMergeData.filter(({ name = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (!searchValue || name?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_revenue = 0,
    total_visits = 0,
    total_revClicks = 0,
    total_conversions = 0,
    total_result = 0,
    total_call_count = 0,
    total_converted_call = 0,
    total_avg_handled_time = 0,
    total_blocked_calls = 0,
    total_call_length = 0,
    total_connected_call_count = 0,
    total_duplicate_calls = 0,
    total_ended_calls = 0,
    total_non_connected_call = 0,
    total_actual_revenue = 0,
    total_RPC_call = 0,
    total_CPR_call = 0,
    total_payout_amount = 0,
    total_x_revenue = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    revenue = 0,
    visits = 0,
    revClicks = 0,
    conversions = 0,
    result = 0,
    call_count = 0,
    converted_calls = 0,
    avg_handle_time = 0,
    call_length = 0,
    blocked_calls = 0,
    connected_call_count = 0,
    duplicate_calls = 0,
    ended_calls = 0,
    non_connected_call_count = 0,
    RPC_CALL = 0,
    CPR_CALL = 0,
    payout_amount = 0,
    actualRevenue = 0,
    x_revenue = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_revenue += parseFloat(revenue || 0);
    total_visits += parseFloat(visits || 0);
    total_revClicks += parseFloat(revClicks || 0);
    total_conversions += parseFloat(conversions || 0);
    total_result += parseFloat(result || 0);
    total_call_count += parseFloat(call_count || 0);
    total_converted_call += parseFloat(converted_calls || 0);
    total_avg_handled_time += parseFloat(avg_handle_time || 0);
    total_call_length += parseFloat(call_length || 0);
    total_blocked_calls += parseFloat(blocked_calls || 0);
    total_connected_call_count += parseFloat(connected_call_count || 0);
    total_duplicate_calls += parseFloat(duplicate_calls || 0);
    total_ended_calls += parseFloat(ended_calls || 0);
    total_non_connected_call += parseFloat(non_connected_call_count || 0);
    total_RPC_call += parseFloat(RPC_CALL || 0);
    total_CPR_call += parseFloat(CPR_CALL || 0);
    total_x_revenue += parseFloat(x_revenue || 0);
    total_payout_amount += parseFloat(payout_amount || 0);
    total_actual_revenue += parseFloat(actualRevenue || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spendFB);
  const total_actual_gross_margin = parseFloat(total_actual_revenue) - parseFloat(total_spendFB);
  const totalData = finalFilterData?.length;
  const total_ROAS = (parseFloat(total_PROFIT) / parseFloat(total_spendFB)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_CPV = parseFloat(total_spendFB) / parseFloat(total_clicksFB);
  const total_EPV = parseFloat(total_revenue) / parseFloat(total_clicksFB);
  const total_CPC = parseFloat(total_spendFB) / parseFloat(total_revClicks);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_AD_CTR = (parseFloat(total_clicksFB) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_clicksFB)) * 100;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;
  const total_CPR = parseFloat(total_spendFB) / parseFloat(total_conversions);
  const avg_RPC_call = parseFloat(total_RPC_call / totalData);
  const avg_CPR_call = parseFloat(total_CPR_call / totalData);

  const summaryData = {
    spendFB: valueCheck(total_spendFB, true),
    clicksFB: valueCheck(total_clicksFB, true),
    impressionsFB: valueCheck(total_impressionsFB, true),
    revenue: valueCheck(total_revenue, true),
    x_revenue: valueCheck(total_x_revenue, true),
    visits: valueCheck(total_visits, true),
    revClicks: valueCheck(total_revClicks, true),
    conversions: valueCheck(total_conversions, true),
    result: valueCheck(total_result, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROAS: valueCheck(total_ROAS, true),
    CR: valueCheck(total_CR, true),
    PAYOUT: valueCheck(total_PAYOUT, true),
    payout_amount: valueCheck(total_payout_amount, true),
    CPV: valueCheck(total_CPV, true),
    EPV: valueCheck(total_EPV, true),
    CPC: valueCheck(total_CPC, true),
    EPC: valueCheck(total_EPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    CPR: valueCheck(total_CPR, true),
    call_count: total_call_count,
    converted_calls: total_converted_call,
    avg_handle_time: secondsToHMS(total_avg_handled_time),
    call_length: secondsToHMS(total_call_length),
    blocked_calls: total_blocked_calls,
    connected_call_count: total_connected_call_count,
    duplicate_calls: total_duplicate_calls,
    ended_calls: total_ended_calls,
    non_connected_call_count: total_non_connected_call,
    RPC_CALL: valueCheck(avg_RPC_call, true),
    CPR_CALL: valueCheck(avg_CPR_call, true),
    actualGrossMargin: valueCheck(total_actual_gross_margin, true),
    actualRevenue: valueCheck(total_actual_revenue, true),
  };

  const tableMainProps = {
    exportCSV: true,
    isDateRange: true,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
    persistKey,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
