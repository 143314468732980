/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const urlValidateRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const urlYupValidate = yup
  .string()
  .matches(
    urlValidateRegex,
    "Website should be a valid URL Example: http://example.com, https://example.com"
  )
  .required();
export default urlYupValidate;
