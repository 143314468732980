import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { useEffect, useState } from "react";
import DebugPre from "components/DebugPre";
import { Checkbox, Col, Image, Pagination, Row, Segmented, Select, Skeleton, Tooltip } from "antd";
import cx from "cx";
import {
  CT_IMAGES_BY_CATEGORY,
  GQL_ADD_FEATURED_IMAGES,
  GQL_CT_DELETE_IMAGES,
} from "shared/gql/gqlSchema/creativeGql";
import useUploadFiles from "./useUploadFiles";
import { CREATIVE_MIME_TYPE_IMAGE, CREATIVE_TYPE_IMAGE } from "shared/SharedKeys/launchKeys";
import {
  CreativeSelectionKey_Image,
  CreativeSelectionKey_Thumbnail,
} from "pages/dashboardPages/VS/VSContent/CreativesDrawer/AdGroup";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import LookModal, { useLookModal } from "components/LookModal";
import EditImage from "../GoogleCreativeImagesUpload/EditImage";
import LookButton from "components/LookButton";
import { useLaunchContext } from "components/Launch/Context";
import Collage from "./Collage";
import ComplianceModal from "pages/dashboardPages/Creatives/Compliance/ComplianceModal";
import {
  MEDIA_ADD_CREATIVE_IMAGE,
  UPDATE_IMAGE_COMPLANCE,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import TagModal from "./TagModal";
import PopoverWrapper from "components/popoverWrapper";
import ActionIconRender from "components/ActionIconRender";
import {
  FEATURED,
  MY_IMAGES,
  RECOMMENDED,
  SELECTED_IMAGES,
} from "pages/dashboardPages/Creatives/creativesEnum";
import {
  GQL_API_RESPONSE_STATUS_FAILURE,
  GQL_API_RESPONSE_STATUS_SUCCESS,
  GQL_API_RESPONSE_STATUS_WARNING,
} from "shared/SharedKeys";
import { RECORD_UPDATED } from "shared/enum/apiResponse";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import ForceDeleteModal from "../ForceDeleteModal";
import { useSelector } from "react-redux";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import moment from "moment";
import useRecommendedHooks from "../hooks/useRecommendedHooks";
import { dateFormat } from "reducers/userSettings";
// import { tempImages } from "./Collage/contant";

export { default as CreativeVideoUpload } from "./CreativeVideoUpload";

function MediaItem(props) {
  const {
    selected = false,
    groupSelection,
    onSelect,
    onOpenDeleteModal,
    refetch,
    launch,
    uploading = false,
    onOpenCompliantModal = () => {},
    setImages,
    newTags = [],
    handleClickDownload,
    imageId,
    s3Url,
    setisEdit,
    compliance,
    tagDataExtractor = () => {},
    openTagModal = () => {},
    setDeleteModalPropsData = () => {},
    handleFeaturedCreatives = () => {},
    setComplianceValue = () => {},
  } = props;

  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Edit Image",
    footer: false,
    width: "80vw",
  });
  const isSelection = { onClick: onSelect };
  const poverWrapperOptions = [
    {
      option: "notification",
      title: "Compliance",
      optionProps: {
        onClick: () => {
          onOpenCompliantModal([imageId]);
          setComplianceValue((prev) => ({
            ...prev,
            compliance: compliance?.complianceStatus,
            complianceDescription: compliance?.notes,
          }));
        },
      },
    },
    {
      option: "download",
      title: "Download Image",
      optionProps: {
        onClick: () => handleClickDownload([s3Url], CREATIVE_MIME_TYPE_IMAGE),
      },
    },
    {
      option: "edit",
      title: "Edit Image",
      optionProps: {
        onClick: () => openModal([imageId]),
      },
    },
    {
      option: "tag",
      title: "Edit Tags",
      optionProps: {
        onClick: () => {
          tagDataExtractor(imageId);
          setImages([{ id: imageId, s3Url, tags: newTags || [] }]);
          openTagModal();
          setDeleteModalPropsData([{ id: imageId, tags: newTags || [] }]);
        },
      },
    },
    {
      option: "delete",
      title: "Delete Image",
      optionProps: {
        onClick: () => onOpenDeleteModal([imageId]),
      },
    },
    {
      option: "important",
      title: "Favorite",
      optionProps: {
        onClick: () => {
          handleFeaturedCreatives([imageId]);
        },
      },
    },
  ];

  const complianceIcon = (status) => {
    switch (status) {
      case "YES":
        return "greenTick";
      case "NO":
        return "redCross";
      case "EXCEPTION":
        return "yellowTick";
      case "PARTIALLY":
        return "orangeTick";
      case "PENDING":
        return "grayTick";
      default:
        return "grayTick";
    }
  };

  return (
    <div>
      {uploading ? (
        <div className={cx("creative-image-selection ant-image-ration-1-1", { selected })}>
          <Skeleton.Image className="w-100" style={{ height: "200px" }} active={true} />
        </div>
      ) : (
        <>
          <div
          // {...isSelection}
          // className={cx("creative-image-selection ant-image-ration-1-1", { selected })}
          >
            <Image src={s3Url} />
            {/* {selected && (
            <div className="position-absolute-center z-index-2 overlay">
              <ActionIconRender iconType="check" />
            </div>
          )} */}
          </div>
        </>
      )}

      <div className="creative-edit">
        <div className="d-flex">
          <Checkbox className="creative-select" onChange={onSelect} checked={selected} />
        </div>
        <div className="d-flex">
          {!uploading && (
            <>
              <Tooltip title={compliance?.complianceStatus || "PENDING"}>
                <ActionIconRender
                  iconType={complianceIcon(compliance?.complianceStatus || "PENDING")}
                  style={{ margin: "3px 3px 0px 0px" }}
                />
              </Tooltip>
              <Tooltip title="More">
                <PopoverWrapper options={poverWrapperOptions}>
                  <ActionIconRender iconType="more" style={{ fontSize: "15px" }} />
                </PopoverWrapper>
              </Tooltip>
              {visibleModal &&
                modal(
                  <EditImage
                    src={s3Url}
                    creativeType={props?.creativeType}
                    categoryName={props?.categoryName}
                    productId={props?.productId}
                    categoryId={props?.id}
                    subCategory={props?.subCategory}
                    verticalId={props?.verticalId}
                    closeModal={closeModal}
                    refetch={refetch}
                  />
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default function CreativeUpload(props) {
  const {
    title = "",
    hasCreativeUploadPermission = false,
    creativeType,
    groupSelection = false,
    selectedCreatives,
    setSelectedData,
    selectedData,
    isTiktokPlatformLaunch,
    handleSelectedCreatives = () => {},
    categoryId,
    productId,
    subCategory,
    subCategoryId,
    verticalId,
    categoryName,
    isKeyword,
    keyword_title,
    launch,
    keyword_body,
    adSetindex,
    campaignIndex,
    isTemp,
    findCommonDynamicAds = () => {},
    isSimilarImage = false,
    handleClickDownload = () => {},
  } = props;

  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { handleAdgroup, campaignObject, removeDuplicatesforObj } = useLaunchContext();
  const [visibleCompliantModal, setVisibleCompliantModal] = useState(false);
  const [complianceValue, setComplianceValue] = useState({});
  const [tagsData, setTagsData] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [groupIds, setgroupIds] = useState([]);
  const [createdbyMe, setCreatedByMe] = useState("All");
  const [images, setImages] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [tags, setTags] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [handleFileUpload, setHandleFileUpload] = useState(() => {});
  const [visibleForceDeleteModal, setVisibleForceDeleteModal] = useState(false);
  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 4 }, () => 10));
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);

  const defaultDate = [moment().subtract(7, "d").format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);
  const [timeZone, setTimeZone] = useState({ value: dateRangeTimezone?.timeZone?.value });
  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  const onChange = (value) => {
    setCreativesImages([]);
    setCreatedByMe(value);
  };

  const [creativesImages, setCreativesImages] = useState([]);

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const {
    modal,
    visibleModal: visibleCollageModal,
    openModal,
    closeModal,
  } = useLookModal({
    title: "Create Collage",
    footer: false,
    width: "88vw",
  });

  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });

  const setImage = (e, tag = 0) => {
    setImages(e);
    if (tag) {
      openTagModal();
      return;
    }
    openModal();
  };
  const selectedAds = campaignObject?.[campaignIndex]?.adSets[adSetindex]?.adCreatives?.image || [];

  let queryVars = {
    productId,
    categoryId,
    createdByMe: createdbyMe === MY_IMAGES,
    page: currentPage,
    size: pageSize,
    featuredImages: createdbyMe === FEATURED,
    // FOR FUTURE USE
    // ...subCategoryId,
    // ...(verticalId && { verticalId }),
  };

  const variables = {
    productId,
    categoryId,
    categoryName,
    // ...subCategory,
    // ...(verticalId && { verticalId }),
  };
  const { creatives_loading, fetchFbCreatives } = useRecommendedHooks({
    queryVars: {
      category_id: categoryId,
      product_id: productId,
      page: currentPage,
      size: pageSize,
      start: rangeDates[0],
      end: rangeDates[1],
      timezone: timeZone?.value,
      group_by: CREATIVE_TYPE_IMAGE,
    },
    setCreatives: setCreativesImages,
    setTotalCount,
  });
  function handleCompliance() {
    ctImageCompliance({
      variables: {
        categoryId,
        productId,
        imageIds: complianceValue?.id,
        complianceStatus: complianceValue?.compliance,
        notes: complianceValue?.complianceDescription,
      },
    });
  }

  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalVisible(true);
    setDeleteModalPropsData(rowProps);
  };
  const onCloseCompliantModal = () => {
    setVisibleCompliantModal(false);
    setComplianceValue({});
    // setCampaignModalPropsData([]);
  };
  const onOpenCompliantModal = (id) => {
    setVisibleCompliantModal(true);
    setComplianceValue((prev) => ({ ...prev, id }));
  };
  const complianceModalProps = {
    visible: visibleCompliantModal,
    onCancel: onCloseCompliantModal,
    title: "Compliance Details",
    onSubmit: () => handleCompliance(),
    okText: "Submit",
  };

  const handleFeaturedCreatives = (id) => {
    ctAddFeatured({
      variables: { images: id, productId, categoryId, categoryName },
    });
  };

  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => {
        onOpenDeleteModal(e?.map((img) => img?.id));
      },
    },
    {
      option: "collage",
      title: "Collage Maker",
      onClick: (e) => {
        setImage(e);
      },
    },
    {
      option: "tag",
      title: "Add tags",
      onClick: (e) => {
        setIsUpload(false);
        setImage(e, 1);
      },
    },
    {
      option: "download",
      title: "Download",
      onClick: (e) => {
        handleClickDownload(
          e?.map((data) => data?.r2Url),
          CREATIVE_MIME_TYPE_IMAGE
        );
      },
    },
    {
      option: "notification",
      title: "Compliance",
      onClick: (e) => {
        onOpenCompliantModal(e?.map((data) => data?.id));
      },
    },
    {
      option: "important",
      title: "Mark Favourite",
      onClick: (e) => {
        handleFeaturedCreatives(e?.map((data) => data?.id));
      },
    },
  ];
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const onSubmitDeleteModal = () => {
    deleteCall({
      variables: {
        imageIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: false,
      },
    });
  };
  const onSubmitForceDeleteModal = () => {
    deleteCall({
      variables: {
        imageIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: true,
      },
    });
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };
  const onCloseForceDeleteModal = () => {
    setDeleteModalPropsData([]);
    setVisibleForceDeleteModal(false);
  };

  const [fetchImages, { loading: creativesImages_loading, refetch: creativesImage_refetch }] =
    useCustomLazyQuery(CT_IMAGES_BY_CATEGORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ctGetCreativeImages?.data;
        if (element) {
          setCreativesImages(
            element?.map((data) => {
              const { image, ...propswithoutImage } = data;
              return { ...propswithoutImage, ...image };
            })
          );

          setTotalCount(e?.ctGetCreativeImages?.pageInfo?.totalRecords);
        }
      },
      variables: { ...queryVars },
    });

  const [deleteCall, { loading: deleteLoader }] = useCustomMutation(GQL_CT_DELETE_IMAGES, {
    onCompleted(e) {
      const element = e?.ctDeleteCreativeImages;
      setDeleteModalVisible(false);
      if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
        creativesImage_refetch();
        openNotification({ type: "success", message: element?.message });
        selectedRowsClear();
      } else if (element?.status === GQL_API_RESPONSE_STATUS_WARNING && isUmsAgencyAdmin) {
        setVisibleForceDeleteModal(true);
      } else {
        openNotification({
          type: "error",
          message: element?.message,
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Deleted Not Success",
      });
    },
  });
  const [ctImageCompliance, { loading: loading_Compliance_Add, refetch: refetch_Compliance_Add }] =
    useCustomMutation(UPDATE_IMAGE_COMPLANCE, {
      variables: variables,
      onCompleted: (e) => {
        const element = e?.ctUpdateImageCompliance;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          setComplianceValue({});
          setVisibleCompliantModal(false);
          creativesImage_refetch();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [ctAddImages, { loading: loading_Add, refetch: creativesImage_added_refetch }] =
    useCustomMutation(MEDIA_ADD_CREATIVE_IMAGE, {
      variables: variables,
      onCompleted: (e) => {
        const element = e?.ctAddCreativeImages;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          creativesImage_refetch();
          closeTagModal();
          setTags([]);
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  useEffect(() => {
    if (createdbyMe === RECOMMENDED) {
      fetchFbCreatives();
    } else {
      fetchImages({
        variables: {
          createdByMe: createdbyMe === MY_IMAGES,
          featuredVideos: createdbyMe === FEATURED,
        },
      });
    }
  }, [createdbyMe]);

  const getImageResponse = (imagesData) => {
    ctAddImages({
      variables: {
        categoryId,
        categoryName,
        productId,
        images: imagesData?.map(
          (data, index) => ({ imageUrl: data?.public_url, imageName: fileName[index] })
          // tags: !tags?.commonTags?.length ? (tags?.length ? [...tags[index]] : []) : [],
        ),
        // commonTags: tags?.commonTags,
      },
    });
  };

  const debugContent = [{ isRow: true, contentObjects: [{ creativesImages }] }];
  const [selectedImages, setSelectedImages] = useState(launch ? selectedAds : []);
  const mediaItemProps = {
    groupSelection,
    creativeType,
    setImages,
    openTagModal,
    hasCreativeUploadPermission,
    complianceValue,
    setComplianceValue,
    visibleCompliantModal,
    setVisibleCompliantModal,
    handleCompliance,
    isEdit,
    setisEdit,
  };

  const { uploadView, uploadLoading: s3Loading } = useUploadFiles({
    name: categoryName,
    getImageResponse,
    creativeType,
    creativesImage_refetch,
    loading: loading_Add,
    setSelectedImages,
    setCreativesImages,
    setFileName,
    setFile: setImages,
    setIsUpload,
  });

  const handleChange = (newValue) => {
    setTags(newValue);
  };

  const handleSelectImage = (id) => {
    let data;
    if (selectedImages?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedImages(selectedImages?.filter((img) => img?.id !== id));
      data = selectedImages?.filter((img) => img?.id !== id);
    } else {
      setSelectedImages([
        ...selectedImages,
        {
          id: creativesImages.filter((img) => img?.id === id)[0].id,
          s3Url: creativesImages.filter((img) => img?.id === id)[0].r2Url,
          tags: creativesImages.filter((img) => img?.id === id)[0].tags,
          name: creativesImages.filter((img) => img?.id === id)[0]?.name,
        },
      ]);
      data = [
        ...selectedImages,
        {
          id: creativesImages.filter((img) => img?.id === id)[0]?.id,
          s3Url: creativesImages.filter((img) => img?.id === id)[0]?.r2Url,
          tags: creativesImages.filter((img) => img?.id === id)[0]?.tags,
          name: creativesImages.filter((img) => img?.id === id)[0]?.name,
        },
      ];
    }
    if (launch) {
      const args = !isTemp ? [campaignIndex, adSetindex] : [];
      handleAdgroup({ image: data }, ...args);
    }
  };

  function selectedRowsClear() {
    setSelectedImages([]);
  }
  const [ctAddFeatured, { loading: loading_featured }] = useCustomMutation(
    GQL_ADD_FEATURED_IMAGES,
    {
      onCompleted: (e) => {
        const element = e?.ctAddFeaturedCreativeImages;
        if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
          openNotification({
            type: "success",
            message: element?.message || RECORD_UPDATED,
            key: "success",
          });
          creativesImage_refetch();
          closeTagModal();
          setTags([]);
        } else if (element?.status === GQL_API_RESPONSE_STATUS_FAILURE) {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const bulkActionProps = {
    selectedRows: selectedImages,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const deleteModalProps = {
    title: "Delete Creatives",
    message: "Do you really want to delete this creatives?",
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };
  const forceDeleteModalProps = {
    title: "Delete Creatives",
    visible: visibleForceDeleteModal,
    onSubmit: onSubmitForceDeleteModal,
    onCancel: onCloseForceDeleteModal,
    okokText: "Delete",
    loader: deleteLoader,
  };
  let ads = [];
  if (launch) {
    ads =
      campaignObject[campaignIndex]?.adSets[adSetindex]?.adCreatives?.image?.map((data) => ({
        ...data,
      })) || [];
  }
  const finalImages = launch
    ? createdbyMe === SELECTED_IMAGES
      ? removeDuplicatesforObj(
          selectedAds?.map((data) => ({ ...data, r2Url: data?.s3Url })),
          "id"
        )
      : !isTiktokPlatformLaunch
      ? creativesImages?.sort((a, b) => {
          const isPresentInAdsA = ads?.findIndex(({ id }) => id === a?.id) !== -1;
          const isPresentInAdsB = ads?.findIndex(({ id }) => id === b?.id) !== -1;

          if (isPresentInAdsA && !isPresentInAdsB) {
            return -1; // a comes before b
          } else if (!isPresentInAdsA && isPresentInAdsB) {
            return 1; // b comes before a
          }

          return 0; // no change in order
        })
      : creativesImages
    : creativesImages;

  useEffect(() => {
    if (!visibleCollageModal) {
      selectedRowsClear();
    }
  }, [visibleCollageModal]);

  const tagDataExtractor = (id) => {
    const tagsArray = finalImages?.filter((data) => data?.id === id)?.[0]?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };

  return (
    <div className={!groupSelection && !launch ? cx("p-20") : ""}>
      <div className={cx("creatives-media-list", { launch })}>
        <div
          className={cx("d-flex mb-20", {
            " mb-20 mt-20": launch,
            " mt-20 mb-20": groupSelection,
          })}
        >
          <Tooltip title="Click search button to search">
            <ActionIconRender iconType="info-circle" className="mr-10 d-flex align-items-center" />
          </Tooltip>
          <div
            className={cx("look-table-search", "d-flex")}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #404040",
            }}
          >
            <Select
              mode="tags"
              bordered={false}
              placeholder={"Search by tags"}
              style={{ width: "200px" }}
              onChange={handleChange}
              maxTagCount="responsive"
            />
            <LookButton
              tooltipTitle="Click here to search"
              className="filter-btn ml-8"
              iconType="search"
              onClick={() => {
                fetchImages({
                  variables: {
                    tags,
                    createdByMe: createdbyMe === MY_IMAGES,
                    featuredVideos: createdbyMe === FEATURED,
                  },
                });
              }}
            />
          </div>
          <Segmented
            className="custom-segmented ml-10"
            options={[
              "All",
              MY_IMAGES,
              FEATURED,
              RECOMMENDED,
              ...(launch ? [SELECTED_IMAGES] : []),
            ]}
            value={createdbyMe}
            onChange={onChange}
          />
          {createdbyMe === RECOMMENDED && (
            <DateRangePersist
              isTimezone
              {...{
                noStore: true,
                defaultDate: defaultDate,
                defaultTimeZone: dateRangeTimezone?.timeZone?.value,
                onChange: handleDateRange,
              }}
            />
          )}
        </div>
        <LookModal {...complianceModalProps}>
          {visibleCompliantModal && (
            <ComplianceModal
              setComplianceValue={setComplianceValue}
              complianceValue={complianceValue}
            />
          )}
        </LookModal>
        <div
          className={cx("creative-media", { launch: launch && !groupSelection, groupSelection })}
        >
          <Row gutter={[24, 24]}>
            {
              <Col span={4} xs={12} sm={8} md={6} xl={6}>
                <div
                  style={{ height: "100%", minHeight: "200px" }}
                  onClick={() => {
                    if (!launch) setSelectedImages([]);
                  }}
                >
                  {uploadView()}
                </div>
              </Col>
            }

            {!launch && !groupSelection && (
              <>
                <LookTableBulkAction {...bulkActionProps} label="Selected Images" />
                <LookTableRecordDeleteModal {...deleteModalProps} />
              </>
            )}
            {visibleForceDeleteModal && (
              <LookModal {...forceDeleteModalProps}>
                <ForceDeleteModal />
              </LookModal>
            )}
            {(creativesImages_loading || s3Loading || creatives_loading) &&
              skeletonSize?.map((e) => (
                <Col span={4} xs={12} sm={8} md={6} xl={6}>
                  <div className={cx("creative-image-selection ant-image-ration-1-1")}>
                    <Skeleton.Image
                      className="w-100"
                      style={{ width: "100%", height: "200px" }}
                      active={true}
                    />
                  </div>
                </Col>
              ))}
            {finalImages?.map((data, index) => {
              const selectedImg = launch
                ? campaignObject[campaignIndex]?.adSets[adSetindex]?.adCreatives?.image
                    ?.map((i) => i.id)
                    ?.includes(data?.id)
                : selectedImages?.filter((img) => img.id === data?.id);

              return (
                <Col span={4} key={"mediaItems" + index} xs={12} sm={8} md={6} xl={6}>
                  <MediaItem
                    s3Url={data?.r2Url}
                    imageId={data?.id}
                    newTags={data?.tags}
                    compliance={data?.compliance}
                    {...props}
                    {...mediaItemProps}
                    refetch={creativesImage_refetch}
                    onOpenDeleteModal={onOpenDeleteModal}
                    onCloseDeleteModal={onCloseDeleteModal}
                    tagDataExtractor={tagDataExtractor}
                    onOpenCompliantModal={onOpenCompliantModal}
                    setComplianceValue={setComplianceValue}
                    openTagModal={openTagModal}
                    setDeleteModalPropsData={setDeleteModalPropsData}
                    handleFeaturedCreatives={handleFeaturedCreatives}
                    onSelect={() => {
                      if (groupSelection) {
                        handleSelectedCreatives(
                          creativeType === CREATIVE_TYPE_IMAGE
                            ? CreativeSelectionKey_Image
                            : CreativeSelectionKey_Thumbnail,
                          data?.id
                        );
                        setSelectedData({
                          ...selectedData,
                          image: [
                            ...selectedData?.image,
                            {
                              id: data?.id,
                              s3Url: data?.r2Url,
                            },
                          ],
                        });
                      } else {
                        handleSelectImage(data?.id);
                      }
                    }}
                    selected={
                      groupSelection
                        ? selectedCreatives?.[CreativeSelectionKey_Image]?.includes(data?.id)
                        : launch
                        ? isTemp
                          ? findCommonDynamicAds(CREATIVE_TYPE_IMAGE, data?.id)
                          : selectedImg
                        : selectedImg?.length > 0
                    }
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        {visibleCollageModal &&
          modal(<Collage files={images} getImageResponse={getImageResponse} />)}
        {visilbleTagModal &&
          tagModal(
            <TagModal
              files={images}
              variables={variables}
              creatives_refetch={creativesImage_refetch}
              setTags={setTags}
              tagsData={tagsData}
              setTagsData={setTagsData}
              tags={tags}
              closeTagModal={closeTagModal}
              selectedRowsClear={selectedRowsClear}
              creativeType={CREATIVE_TYPE_IMAGE}
            />
          )}

        {/* <Pagination
              showSizeChanger
              pageSizeOptions={pageSizeOptions}
              defaultCurrent={1}
              current={mainPaginationPage && mainPaginationPage}
              total={mainPaginationTotal}
              // defaultPageSize={defaultPageSize}
              pageSize={mainPaginationSize && mainPaginationSize}
              onChange={(page, size) =>}
            /> */}

        <DebugPre content={debugContent} />
      </div>
      {/* todo temproray fix remove look-bottom-pagination */}
      {createdbyMe !== SELECTED_IMAGES && (
        <div className="p-8 look-bottom-pagination ">
          <Pagination
            defaultCurrent={1}
            total={totalCount}
            pageSize={pageSize}
            current={currentPage}
            onChange={(page, size) => handlePageChange(page, size)}
            pageSizeOptions={["10", "20", "50"]}
            showSizeChanger
          />
        </div>
      )}
    </div>
  );
}
