import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Tooltip } from "antd";
import {
  CREATE_CMS_AWIC_RULES,
  DELETE_CMS_AWIC_RULES,
  GET_CMS_AWIC_RULES,
} from "../../../../shared/gql/gqlSchema/cmsGql";
import * as yup from "yup";
import { Formik, ErrorMessage, Field } from "formik";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import SelectInput from "components/forms/SelectInput";
import ActionIconRender from "components/ActionIconRender";
import { openNotification } from "utils";
import DebugPre from "components/DebugPre";

export const MainDataContext = createContext(null);

const FormikSelect = ({ name, onChange, ...rest }) => {
  return (
    <div>
      <SelectInput
        {...rest}
        className="mb-0"
        search
        name={name}
        onChange={(e) => onChange(name, e)}
      />
      <ErrorMessage name={name} component="div" className="text-left error-text" />
    </div>
  );
};

function RenderForm(props) {
  const { setFieldValue, values, initialValues } = props;
  const mainDataContext = useContext(MainDataContext);
  const { CMS_Countries = [] } = useSelector((state) => state?.userSettings?.userSettingData) || {};
  const { filterdDomains, filterStores, filterCMS_Products } = mainDataContext;
  const { awic_network_accounts } =
    filterdDomains.filter(({ id }) => values?.domain_id === id)[0] || {};

  return (
    <>
      <div className="d-flex align-items-center awic-rule-foem-render">
        <span>If</span>
        <FormikSelect
          // label="domain"
          name="domain_id"
          placeholder="Select Domain"
          value={values?.domain_id}
          onChange={(name, value) => {
            setFieldValue(name, value);
            setFieldValue("awic_network_account_id", "");
          }}
          options={filterdDomains.map(({ id, name }) => ({ name, value: id }))}
        />
        <span>and</span>
        <FormikSelect
          // label="Store"
          name="store_id"
          placeholder="Select store"
          value={values?.store_id}
          onChange={setFieldValue}
          options={filterStores.map(({ id, name }) => ({ name, value: id }))}
        />
        <span>and</span>
        <SelectInput
          formik
          className="mb-0"
          name="country_id"
          placeholder="Select Copuntry"
          onChange={setFieldValue}
          options={CMS_Countries.map(({ id, name }) => ({ name, value: id }))}
        />
        <span>and</span>
        <FormikSelect
          // label="product_id"
          name="product_id"
          placeholder="Select product"
          value={values?.product_id}
          onChange={setFieldValue}
          options={filterCMS_Products?.map(({ id, name }) => ({ name, value: id }))}
        />
        <span>than use</span>
        <FormikSelect
          // label="awic_network_account_id"
          name="awic_network_account_id"
          placeholder="Select network"
          value={values?.awic_network_account_id}
          onChange={setFieldValue}
          options={awic_network_accounts?.map(({ id, name }) => ({ name, value: id }))}
        />
        <span>network accounts.</span>
      </div>
    </>
  );
}

function Creation() {
  const mainDataContext = useContext(MainDataContext);
  const {
    fieldItemsTemplate,
    toggleAdd,
    article_id,
    article_type,
    awicRulList,
    setAwicRulList,
    setAddNew,
  } = mainDataContext;

  const [CMS_CreateDomain, { loading }] = useCustomMutation(CREATE_CMS_AWIC_RULES, {
    onCompleted(e) {
      // resetFormRef.current();
      const element = e?.CMS_Create_Awic_Rules;

      if (element) {
        setAwicRulList([element, ...awicRulList]);
        setAddNew(false);
      }
      openNotification({ type: "success", message: "Record Added Successfully" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
    variables: {
      article_id,
      article_type,
    },
  });

  const initialValues = fieldItemsTemplate;
  const validationSchema = yup.object({
    awic_network_account_id: yup.string().required().label("awic_network_account_id"),
    domain_id: yup.string().required().label("domain_id"),
    store_id: yup.string().required().label("store_id"),
  });

  function onSubmit(values, { resetForm }) {
    CMS_CreateDomain({
      variables: {
        ...values,
      },
    });
  }

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => {
          const formProps = { setFieldValue, values, initialValues };
          return (
            <>
              <RenderForm {...formProps} />
              <Button
                type="link"
                onClick={handleSubmit}
                icon={<ActionIconRender iconType="check" />}
              />
              <Button
                type="link"
                onClick={toggleAdd}
                danger
                icon={<ActionIconRender iconType="close" />}
              />
              <DebugPre content={[{ contentObjects: [{ values }] }]} />
              {/* <pre>{JSON.stringify({ values }, null, 2)}</pre> */}
            </>
          );
        }}
      </Formik>
    </>
  );
}

function RenderListContent(props) {
  const {
    id,
    domain,
    store,
    product,
    awic_network_account,
    country,
    deleteRules = () => {},
  } = props;
  const mainDataContext = useContext(MainDataContext);
  const { filterdDomains, filterStores, filterCMS_Products } = mainDataContext;
  const { awic_network_accounts } = filterdDomains.filter(({ id }) => domain?.id === id)[0] || {};
  const { CMS_Countries = [] } = useSelector((state) => state?.userSettings?.userSettingData) || {};
  const selectedNetwork = awic_network_accounts?.filter(
    ({ id }) => awic_network_account?.id === id
  )[0];
  const selecedDomain = filterdDomains?.filter(({ id }) => domain?.id === id)[0];
  const selecedStore = filterStores?.filter(({ id }) => store?.id === id)[0];
  const selecedProduct = filterCMS_Products?.filter(({ id }) => product?.id === id)[0];
  const selecedCountry = CMS_Countries?.filter(({ id }) => country?.id === id)[0];
  return (
    <div className="d-flex">
      <p className="flex-grow-1 pr-6">
        If <b>{selecedDomain?.name}</b> and <b>{selecedStore?.name}</b>{" "}
        {country && (
          <>
            than <b>{selecedCountry?.name}</b>
          </>
        )}{" "}
        {product && (
          <>
            than <b>{selecedProduct?.name}</b>
          </>
        )}{" "}
        than use <b>{selectedNetwork?.name}</b> network accounts.
      </p>
      <Tooltip title="Delete Rule" placement="right">
        <Button
          type="link"
          danger
          onClick={() => deleteRules([id])}
          icon={<ActionIconRender iconType="delete" />}
        />
      </Tooltip>
    </div>
  );
}

export default function NetworkRules(props) {
  const { domains, article_id, article_type } = props;
  const [deleteIds, setDeleteIds] = useState();

  const [addNew, setAddNew] = useState(false);
  // const [addNew, setAddNew] = useState(true);
  const [awicRulList, setAwicRulList] = useState([]);
  const toggleAdd = () => setAddNew((prev) => !prev);
  // const [rulesList, setRulesList] = useState([]);
  // const [rulesAdditionList, setRulesAdditionList] = useState([]);
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);

  const { data, loading } = useCustomQuery(GET_CMS_AWIC_RULES, {
    variables: {
      article_id,
      article_type,
    },
    onCompleted: (e) => {
      const element = e?.CMS_Awic_Rules;

      if (element) {
        setAwicRulList(element);
      }
    },
  });

  const [CMS_Delete_Awic_Rule, { loading: loading_DELETE_CMS_AWIC_RULES }] = useCustomMutation(
    DELETE_CMS_AWIC_RULES,
    {
      onCompleted: (e) => {
        openNotification({ type: "success", message: "Record Deleted" });
        setAwicRulList(
          awicRulList.filter(({ id }) => !(deleteIds.filter((data) => data === id).length > 0))
        );
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  function deleteRules(ids = []) {
    setDeleteIds(ids);
    CMS_Delete_Awic_Rule({
      variables: {
        id: ids,
      },
    });
  }

  const {
    CMS_Domains = [],
    CMS_Domains_loading,
    CMS_Stores = [],
    CMS_Products = [],
  } = userSettingData || {};

  const filterdDomains = CMS_Domains.filter(
    ({ id }) => domains.filter((domainsData) => domainsData === id).length > 0
  );

  const filterStores = CMS_Stores;
  const filterCMS_Products = CMS_Products;
  // const domainSelectProps = {
  //   search: true,
  //   placeholder: "Select Domain",
  //   // value: domains,
  //   // onChange,
  //   options: filterdDomains.map(({ id, name }) => ({ name, value: id })),
  // };

  const fieldItemsTemplate = {
    awic_network_account_id: undefined,
    domain_id: undefined,
    product_id: "",
    store_id: undefined,
    country_id: undefined,
  };
  const contextProps = {
    filterdDomains,
    filterStores,
    filterCMS_Products,
    fieldItemsTemplate,
    toggleAdd,
    article_id,
    article_type,
    awicRulList,
    setAwicRulList,
    setAddNew,
  };

  return (
    <>
      <MainDataContext.Provider value={contextProps}>
        {/* <RenderForm /> */}
        {/* <SelectInput {...domainSelectProps} /> */}

        {addNew ? (
          <Creation />
        ) : (
          <Button
            type="link"
            onClick={toggleAdd}
            icon={<ActionIconRender iconType="plus-circle" />}
          />
        )}

        {awicRulList &&
          awicRulList?.map((data, index) => (
            <RenderListContent key={"awicRulList" + data} {...data} deleteRules={deleteRules} />
          ))}
        <DebugPre content={[{ contentObjects: [{ awicRulList }] }]} />
      </MainDataContext.Provider>
      <DebugPre
        content={[
          {
            isRow: true,
            contentObjects: [{ awicRulList }, { CMS_Domains }, { domains }, { filterdDomains }],
          },
        ]}
      />
    </>
  );
}
