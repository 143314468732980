import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "cx";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
} from "shared/SharedKeys";
const valueCheck = (value, checkTotal = false) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(2);
  return valueFloat;
};
export default function useTiktokVoluumColums(props) {
  const { callDetailView, reportType = "" } = props || {};

  let nameTitle = "";
  let nameObject = "";
  let idTitle = "";
  let idObject = "";

  if (REPORT_TYPE_AD_ACCOUNT === reportType) {
    nameTitle = "Ad Account";
    nameObject = "adaccountName";
    idTitle = "Ad Account Id";
    idObject = "adAccountId";
  }
  if (REPORT_TYPE_AD === reportType) {
    nameTitle = "Ad";
    nameObject = "ad_name";
    idTitle = "Ad Id";
    idObject = "adId";
  }
  if (REPORT_TYPE_AD_ADSET === reportType) {
    nameTitle = "Adset";
    nameObject = "adgroup_name";
    idTitle = "Ad Group Id";
    idObject = "adsetId";
  }
  if (REPORT_TYPE_CAMPAIGN === reportType) {
    nameTitle = "Campaign";
    nameObject = "campaign_name";
    idTitle = "campaignId";
    idObject = "campaignId";
  }

  const tiktokVoluumColumns = [
    {
      title: cx({ [nameTitle]: nameTitle }, "Name"),
      dataIndex: nameObject,
      key: nameObject,
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={value}
          isClick={callDetailView ? () => callDetailView(otherProps) : undefined}
        />
      ),
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: idTitle,
      dataIndex: idObject,
      key: idObject,
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 190,
      minWidth: 190,
      searchType: "string",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revenue - b?.revenue,
      searchType: "number",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spend - b?.spend,
      searchType: "number",
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value = 0, otherProps) => {
        // const profit = `$${value.replace("-", "")}`;
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      searchType: "number",
    },

    {
      title: "ROI",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROI - b?.ROI,
      searchType: "number",
    },

    {
      title: "clicks (P)",
      dataIndex: "clicksTiktok",
      key: "clicksTiktok",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicksTiktok - b?.clicksTiktok,
      searchType: "number",
    },
    {
      title: "Clicks (V)",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.conversions - b?.conversions,
      searchType: "number",
    },

    {
      title: "Reach",
      dataIndex: "reach",
      key: "reach",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.reach - b?.reach,
      searchType: "number",
    },

    {
      title: "CPR (V)",
      dataIndex: "CPR_V",
      key: "CPR_V",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_V - b?.CPR_V,
      searchType: "number",
    },

    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },

    {
      title: "AD CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },

    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPC - b?.CPC,
      searchType: "number",
    },

    {
      title: "E CPC",
      dataIndex: "E_CPC",
      key: "E_CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.E_CPC - b?.E_CPC,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressionsTiktok",
      key: "impressionsTiktok",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsTiktok - b?.impressionsTiktok,
      searchType: "number",
    },

    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },

    {
      title: "V Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.visits - b?.visits,
      searchType: "number",
    },
  ];

  return { tiktokVoluumColumns };
}

