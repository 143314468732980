import PlatformCards from "components/LaunchTarget/PlatformCards";
import FacebookForm from "../FacebookForm";
import TiktokForm from "../TiktokForm";

function MainForm(props) {
  const {
    setTemplatePlatforms = () => {},
    templatePlatforms,
    platformsData,
    step,
    setStep = () => {},
  } = props;

  /**
   *This function is use to select platform
   */
  function platforms() {
    return (
      <>
        <h3> Choose Platforms</h3>
        <div className="d-flex">
          {platformsData?.templatePlatforms?.map(({ name, id }) => (
            <>
              <PlatformCards
                icon={name}
                id={id}
                setTemplatePlatforms={setTemplatePlatforms}
                setStep={setStep}
              />
            </>
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      {templatePlatforms === "" || step === 0 ? (
        platforms()
      ) : platformsData?.templatePlatforms?.filter((item) => item?.id === templatePlatforms)[0]
          ?.name === "tiktok" && step === 1 ? (
        <TiktokForm {...props} />
      ) : (
        <FacebookForm {...props} />
      )}
    </>
  );
}

export default MainForm;
