import { CLICKUP_USER_LOGGED_IN } from "actions/actionTypes";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { useDispatch } from "react-redux";
import { CLICKUP_CHECK_LOGIN_STATUS } from "shared/gql/gqlSchema/scorecardGql";
import { openNotification } from "utils";

export function useCheckClickupLogin() {
  const dispatch = useDispatch();
  const [
    clickup_checkLoginStatus,
    { loading: isClickupLoggedIn_loading, refetch: isClickupLoggedIn_refetch },
  ] = useCustomLazyQuery(CLICKUP_CHECK_LOGIN_STATUS, {
    notifyOnNetworkStatusChange: true,
    onCompleted(e) {
      const element = e?.clickup_checkLoginStatus;
      dispatch({ type: CLICKUP_USER_LOGGED_IN, payload: element });
    },
    onError: (e) => {
      openNotification({ type: "error", message: e?.message });
    },
  });

  return {
    clickup_checkLoginStatus,
    isClickupLoggedIn_loading,
    isClickupLoggedIn_refetch,
  };
}
