import {
  UserOutlined,
  ApartmentOutlined,
  HomeOutlined,
  GroupOutlined,
  BranchesOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { GoogleCircleFilled } from "@ant-design/icons";
import { GOOGLE_VIEW_TEST } from "shared/UserPermission/userPermissionList";

const g360 = [
  {
    name: "G360",
    link: "/g360",
    icon: GoogleCircleFilled,
    subMenu: [
      {
        subMenuName: "Profile",
        subMenuLink: "/profile",
        subMenuIcon: UserOutlined,
        permission: GOOGLE_VIEW_TEST,
      },
      {
        subMenuName: "Account",
        subMenuLink: "/account",
        subMenuIcon: ApartmentOutlined,
        permission: GOOGLE_VIEW_TEST,
      },
      {
        subMenuName: "Customer",
        subMenuLink: "/customer",
        subMenuIcon: BranchesOutlined,
        permission: GOOGLE_VIEW_TEST,
      },
      {
        subMenuName: "Campaign",
        subMenuLink: "/campaign",
        subMenuIcon: HomeOutlined,
        permission: GOOGLE_VIEW_TEST,
      },
      {
        subMenuName: "Adgroup",
        subMenuLink: "/adgroup",
        subMenuIcon: GroupOutlined,
        permission: GOOGLE_VIEW_TEST,
      },
      {
        subMenuName: "Ad",
        subMenuLink: "/ad",
        subMenuIcon: BranchesOutlined,
        permission: GOOGLE_VIEW_TEST,
      },
      {
        subMenuName: "Reports",
        subMenuLink: "/report",
        subMenuIcon: FileDoneOutlined,
        permission: GOOGLE_VIEW_TEST,
      },
    ],
  },
];

export default g360;
