import { PicLeftOutlined, RiseOutlined } from "@ant-design/icons";
import { GoogleCircleFilled } from "@ant-design/icons";
import {
  PERMISSION_VOLUUM_VIEW,
  TIKTOK_USER,
  VOLUUM_VIEW,
} from "shared/UserPermission/userPermissionList";
import {
  F360_MANAGE_LEADGEN_REPORT,
  USER_WITH_NO_ACTION,
} from "../../UserPermission/userPermissionList";
import { useSelector } from "react-redux";
import useLaunchpathsObject from "./useLaunchpathsObject";
import { usePermissionCheck } from "hooks";
import ActionIconRender from "components/ActionIconRender";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";
import { TikTokIcon } from "components/svgComponents";
import { CustomeIconWrapper } from "..";

export default function useLeadgenPath() {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);
  const agency_id = useSelector((state) => state?.userReducer?.me?.agency_id);
  const { havePermission: isWithoutAction } = usePermissionCheck(USER_WITH_NO_ACTION);

  const { launchpathsObject } = useLaunchpathsObject({ permission: PERMISSION_VOLUUM_VIEW });

  const isLaunchRoutes = agency_id === 1 && !isWithoutAction ? [launchpathsObject] : [];
  const isLandingUrl = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
    ? []
    : [
        {
          subMenuName: "Landing Urls",
          subMenuLink: "/landing-urls",
          subMenuIcon: PicLeftOutlined,
          permission: PERMISSION_VOLUUM_VIEW,
        },
      ];

  const googleLaunchPath = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
    ? []
    : [
        {
          subMenuName: "Google Launch",
          subMenuIcon: GoogleCircleFilled,
          permission: [F360_MANAGE_LEADGEN_REPORT],
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "List",
              subMenuLink: "/google-launch/list",
              subMenuIcon: () => <ActionIconRender iconType="launchList" />,
              permission: [F360_MANAGE_LEADGEN_REPORT],
            },
            {
              subMenuName: "Code snippet",
              subMenuLink: "/google-launch/snippet",
              subMenuIcon: () => <ActionIconRender iconType="launchList" />,
              permission: [F360_MANAGE_LEADGEN_REPORT],
            },
            {
              subMenuName: "Campaign Creation",
              subMenuLink: "/google-launch/campaign",
              subMenuIcon: () => <ActionIconRender iconType="rocket" />,
              permission: [F360_MANAGE_LEADGEN_REPORT],
            },
          ],
        },
      ];

  const leadgenPath = [
    {
      name: "Leadgen",
      link: "/leadgen",
      icon: RiseOutlined,
      subMenu: [
        {
          subMenuName: "Global Reports",
          subMenuLink: "/global-reports",
          subMenuIcon: () => <ActionIconRender iconType="global" />,
          permission: [VOLUUM_VIEW],
        },

        {
          subMenuName: "Facebook Reports",
          subMenuLink: "/facebook-reports",
          subMenuIcon: PicLeftOutlined,
          permission: [VOLUUM_VIEW],
        },
        {
          subMenuName: "Tiktok Reports",
          subMenuLink: "/tiktok-reports",
          subMenuIcon: () => (
            <CustomeIconWrapper>
              <TikTokIcon />
            </CustomeIconWrapper>
          ),
          permission: [VOLUUM_VIEW],
        },
        {
          subMenuName: "Google Reports",
          subMenuLink: "/google-reports",
          subMenuIcon: GoogleCircleFilled,
          permission: [VOLUUM_VIEW],
        },
        {
          subMenuName: "Publishers",
          subMenuLink: "/publishers",
          subMenuIcon: () => <ActionIconRender iconType="team" />,
          permission: [VOLUUM_VIEW],
        },
        {
          subMenuName: "Co-Pilot",
          subMenuIcon: () => <ActionIconRender iconType="copilot" />,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Rules",
              subMenuLink: "/copilot/rules",
              subMenuIcon: () => <ActionIconRender iconType="rules" />,
            },
            {
              subMenuName: "All Rules",
              subMenuLink: "/copilot/allrules",
              subMenuIcon: () => <ActionIconRender iconType="rules" />,
            },
          ],
          permission: [VOLUUM_VIEW],
        },
        ...googleLaunchPath,

        // removed for now
        // {
        //   subMenuName: "Mobile View",
        //   subMenuLink: "/campaigns-mob",
        //   subMenuIcon: PicLeftOutlined,
        //   permission: [F360_MANAGE_LEADGEN_REPORT],
        // },
        // {
        //   subMenuName: "Google Reports",
        //   subMenuLink: "/google-reports",
        //   subMenuIcon: GoogleCircleFilled,
        //   permission: [F360_MANAGE_LEADGEN_REPORT],
        // },
        // {
        //   subMenuName: "Mobile View",
        //   subMenuLink: "/campaigns-mob",
        //   subMenuIcon: PicLeftOutlined,
        //   permission: [F360_MANAGE_LEADGEN_REPORT],
        // },
        {
          subMenuName: "X-Revenue",
          subMenuLink: "/x-revenue",
          subMenuIcon: PicLeftOutlined,
          permission: [PERMISSION_VOLUUM_VIEW],
        },
        {
          subMenuName: "Category",
          subMenuLink: "/category",
          subMenuIcon: PicLeftOutlined,
          permission: PERMISSION_VOLUUM_VIEW,
        },
        {
          subMenuName: "SubIds",
          subMenuLink: "/subId",
          subMenuIcon: PicLeftOutlined,
          permission: PERMISSION_VOLUUM_VIEW,
        },
        ...isLandingUrl,
        ...isLaunchRoutes,

        {
          subMenuName: "Video Analysis",
          subMenuLink: "/video-analysis",
          subMenuIcon: PicLeftOutlined,
          permission: PERMISSION_VOLUUM_VIEW,
        },

        // ...faqSubMenuPath,
      ],
    },
  ];
  return { leadgenPath };
}
