import { gql } from "@apollo/client";

export const GET_SLACK_AUTH_URL = gql`
  query Notif_GetSignInUrl($redirectUri: String!) {
    Notif_GetSignInUrl(redirectUri: $redirectUri) {
      status
      data
      message
    }
  }
`;

export const GET_SLACK_SUBS = gql`
  query {
    Notif_GetSlackSubs {
      id
      channelId
      channelName
      teamId
      teamName

      workspace {
        isAuthed
      }

      lookServices {
        id
        name
      }
      subsNotificationEventList {
        id

        notificationTypes {
          type
          id
        }
        eventTypes {
          eventType
          id
        }
      }
    }
  }
`;

export const GET_SLACK_NOTIFICATIONS = gql`
  mutation Notif_MuteOrUnmuteSubs($id: ID, $isMuted: Boolean) {
    Notif_MuteOrUnmuteSubs(id: $id, isMuted: $isMuted) {
      status
    }
  }
`;

export const DELETE_SLACK_NOTIFICATIONS = gql`
  mutation Notif_DeleteSubscription($id: ID) {
    Notif_DeleteSubscription(id: $id) {
      id
    }
  }
`;

const NOTIFY_SLACK_CHANNEL_RESPONSE = gql`
  fragment Notif_SlackChannel on Notif_SlackChannel {
    channelId
    name
    numOfMembers
    teamId
  }
`;

export const SLACK_REDIRECT = gql`
  ${NOTIFY_SLACK_CHANNEL_RESPONSE}
  query Notif_GetChannels($code: String, $redirectUri: String) {
    Notif_GetChannels(code: $code, redirectUri: $redirectUri) {
      status
      message
      data {
        ...Notif_SlackChannel
      }
    }
  }
`;

export const SLACK_GET_CHANNELS_V2 = gql`
  ${NOTIFY_SLACK_CHANNEL_RESPONSE}
  query Notif_GetChannels2($teamId: String) {
    Notif_GetChannels2(teamId: $teamId) {
      status
      message
      data {
        ...Notif_SlackChannel
      }
    }
  }
`;

export const SLACK_GET_AUTHENTICATED = gql`
  query Notif_GetAuthenticated($redirectUri: String!, $teamId: String!) {
    Notif_GetAuthenticated(redirectUri: $redirectUri, teamId: $teamId)
  }
`;

const NOTIFY_SLACK_USER_ACCESS_TOKEN_RESPONSE = gql`
  fragment Notif_UserAccessToken on Notif_UserAccessToken {
    appId
    authedUserId
    teamId
    teamName
    userAccessToken
  }
`;

export const SLACK_GET_ACCESS_TOKEN = gql`
  ${NOTIFY_SLACK_USER_ACCESS_TOKEN_RESPONSE}
  query Notif_GetAccessToken($code: String!, $redirectUri: String!) {
    Notif_GetAccessToken(code: $code, redirectUri: $redirectUri) {
      status
      message
      data {
        ...Notif_UserAccessToken
      }
    }
  }
`;

export const SLACK_GET_AUTHENTICATED_TOKEN = gql`
  ${NOTIFY_SLACK_USER_ACCESS_TOKEN_RESPONSE}
  query Notif_GetAuthenticatedToken($code: String!, $redirectUri: String!) {
    Notif_GetAuthenticatedToken(code: $code, redirectUri: $redirectUri) {
      status
      message
      data {
        ...Notif_UserAccessToken
      }
    }
  }
`;

export const SLACK_SAVE_SUBSCRIPTIONS = gql`
  mutation Notif_SaveSubscriptions(
    $eventsAndNotificationsIds: [Notif_SubsNotificationEventsIds]
    $slackSubs: Notif_SlackSubsInput
  ) {
    Notif_SaveSubscriptions(
      eventsAndNotificationsIds: $eventsAndNotificationsIds
      slackSubs: $slackSubs
    ) {
      data {
        agencyId
      }
      message
      status
    }
  }
`;

export const SLACK_UPDATE_NOTIFICATION = gql`
  mutation Notif_UpdateSubscription(
    $id: ID!
    $slackSubs: Notif_SlackSubsInput
    $eventsAndNotificationsIds: [Notif_SubsNotificationEventsIds]
  ) {
    Notif_UpdateSubscription(
      id: $id
      slackSubs: $slackSubs
      eventsAndNotificationsIds: $eventsAndNotificationsIds
    ) {
      data {
        agencyId
      }
      message
      status
    }
  }
`;

export const SLACK_GET_SERVICE = gql`
  query Notif_GetServices {
    Notif_GetServices {
      id
      name
      version
      NotificationTypeList {
        id
        type
        eventTypeList {
          id
          eventType
          template
        }
      }
    }
  }
`;

const NOTIFY_WORKSPACE_RESPONSE = gql`
  fragment Notif_Workspace on Notif_Workspace {
    isAuthed
    teamId
    teamName
  }
`;

export const SLACK_GET_WORKSPACES = gql`
  ${NOTIFY_WORKSPACE_RESPONSE}
  query Notif_GetWorkspaces {
    Notif_GetWorkspaces {
      ...Notif_Workspace
    }
  }
`;

export const SLACK_SYNC_USERS_HRMS = gql`
  mutation SYNC_SLACK {
    syncSlackMembers
  }
`;
