import * as types from "../actions/actionTypes";

export default function f360Reducer(state = {}, action) {
  switch (action.type) {
    case types.F360_CONFIG:
      return { ...state, f360Config: action.payload };
    case types.F360_CONFIG_CLEAR:
      return { ...state, f360Config: undefined };
    default:
      return state;
  }
}
