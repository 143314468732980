import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { Tooltip, Switch } from "antd";
import { useSelector } from "react-redux";
import alasql from "alasql";
import cx from "classnames";
import { useParams } from "react-router";
import DetailView from "./DetailView";
import {
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  VS_IS_FORECAST_SET,
  VS_IS_FORECAST_UNSET,
} from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { UMS_SUPER_ADMIN } from "shared/UserPermission/userPermissionList";
import SelectInput from "components/forms/SelectInput";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useApiToStore } from "hooks";
import {
  API_STORE_VIRALSPARKS_ADVERTISE,
  API_STORE_VIRALSPARKS_ALL_USERS,
  API_STORE_VIRALSPARKS_PLATFORM,
} from "hooks/useApiToStore/keys";
import useVSAdvertiserHandler from "../../../useVSAdvertiserHandler";
import { useDispatch } from "react-redux";
import { VS_CAMPAIGN_LIVE_REPORT } from "shared/gql/gqlSchema/viralSparksGql";
import { GET_REPORT_BY_CAMPAIGN } from "shared/gql/gqlSchema/leadgenGql";
import useColumns from "../useColumns";
import ActionIconRender from "components/ActionIconRender";
import { Button } from "antd/lib";
import useUpdateFbAssetBudget, { UPDATE_UPDATE_NODE_CAMPAIGN } from "hooks/useUpdateFbAssetBudget";
import {
  UPDATE_SPEND_STATUS_NODE_CAMPAIGN,
  UPDATE_SPEND_STATUS_TYPE_ACTIVE,
  UPDATE_SPEND_STATUS_TYPE_PAUSED,
  useFbActivePauseState,
} from "hooks/spendActivePauseStatusUpdateHooks";

import UpdateCapmaignBudget from "./UpdateCapmaignBudget";
import LookDrawer from "components/LookDrawer";
import LookModal from "components/LookModal";
import UpdateBulkCampaignBudget from "./UpdateBulkCampaignBudget";
import { SPEND_INCREASE_PERCENT_VS } from "shared/enum/commonKeysandEnum";

export default function Campaigns(props) {
  const { createDrawerProps = {}, adAccountIdOnly, f360AdAccountId } = props;

  const { renderUpdateBudgetModal, showBulkBudgetDrawer, onCloseBulkBudgetDrawer } =
    useUpdateFbAssetBudget(UPDATE_UPDATE_NODE_CAMPAIGN);
  const params = useParams();
  const dispatch = useDispatch();
  const [spendReport, setSpendReport] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const withUser = params?.userID ? { userId: params?.userID } : {};
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);
  const [selectedUser, setSelectedUser] = useState("all");
  const [campaignForecastReport, setCampaignForecastReport] = useState([]);
  const [campaignMinReport, setCampaignMinReport] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const { callFbAssetStateSubmit, loading: callFbAssetState_loading } = useFbActivePauseState(
    UPDATE_SPEND_STATUS_NODE_CAMPAIGN,
    { setSpendReport }
  );
  const { vsAdvertiser = [] } = userSettingData || {};
  const [visibleBudgetDrawer, setVisibleBudgetDrawer] = useState(false);
  const [visibleBulkBudgetDrawer, setVisibleBulkBudgetDrawer] = useState(false);

  const showBudgetDrawer = (e) => {
    setRowProps(e);
    setVisibleBudgetDrawer(true);
  };
  const onCloseDrawerBudget = () => {
    setRowProps({});
    setVisibleBudgetDrawer(false);
  };
  const showBudgetBulkDrawer = (e) => {
    setRowProps(e);
    console.log(e);
    setVisibleBulkBudgetDrawer(true);
  };
  const onCloseDrawerBulkBudget = () => {
    setRowProps({});
    setVisibleBulkBudgetDrawer(false);
  };

  const {
    selectedVSAdvertiserIsAll = true,
    selectedVSAdvertiser = "",
    vsIsForecast,
    vs_users = [],
  } = useSelector((state) => state?.vsReducer) || {};

  const isAdvertiser = selectedVSAdvertiserIsAll ? {} : { advertiser_id: selectedVSAdvertiser };
  const isVendor = selectedVSAdvertiserIsAll ? {} : { vendorId: selectedVSAdvertiser };

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        const { status, campaignId, dailyBudget, lifeTimeBudget } = actionProps;

        const callStatus =
          status !== "PAUSED" ? UPDATE_SPEND_STATUS_TYPE_PAUSED : UPDATE_SPEND_STATUS_TYPE_ACTIVE;

        return (
          <div className="action-column d-flex justify-content-around">
            <div className="actions flex-center">
              <Tooltip title="Daily Budget">
                <span>
                  {dailyBudget === null || dailyBudget === undefined ? (
                    <span className="table-tag">ABO</span>
                  ) : (
                    dailyBudget
                  )}
                </span>
              </Tooltip>
            </div>
            <div className="more-actions flex-around">
              {campaignId ? (
                <Tooltip title="Update Status">
                  <Switch
                    disabled={callFbAssetState_loading}
                    size="small"
                    checked={actionProps.status !== "PAUSED"}
                    onClick={() => callFbAssetStateSubmit(callStatus, [actionProps])}
                  />
                </Tooltip>
              ) : (
                <Switch disabled size="small" />
              )}
              {dailyBudget || lifeTimeBudget ? (
                <Tooltip title="Update Budget">
                  <Button
                    className="ml-12 p-0 h-auto w-auto"
                    type="link"
                    onClick={() => showBudgetDrawer(actionProps)}
                    icon={<ActionIconRender iconType="dollar" />}
                  />
                </Tooltip>
              ) : (
                <Button
                  className="ml-12 p-0 h-auto w-auto"
                  type="link"
                  icon={<ActionIconRender iconType="dollar" />}
                  disabled
                />
              )}
            </div>
          </div>
        );
      },

      fixed: "right",
      width: 190,
      minWidth: 190,
    },
  ];

  function handleForecast(e) {
    dispatch({ type: e ? VS_IS_FORECAST_SET : VS_IS_FORECAST_UNSET });
  }

  const isUser = selectedUser === "all" ? {} : { userId: selectedUser };
  const isWorkspaceAdmin =
    permissions.filter((name) => name === UMS_SUPER_ADMIN).length > 0 ? true : false;

  const { apiCall: vsPlatformsFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_PLATFORM);
  const { apiCall: vsAdvertiserFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ADVERTISE);

  const { apiCall: vsUsersFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ALL_USERS);

  const { handleAdvertiserSelection } = useVSAdvertiserHandler();

  const withAccount = params?.accID
    ? { adAccountId: params?.accID }
    : adAccountIdOnly
    ? { adAccountId: f360AdAccountId }
    : {};
  const { columns, status } = useColumns();

  useEffect(() => {
    if (isWorkspaceAdmin) {
      vsUsersFetch();
    }
    vsPlatformsFetch();
    vsAdvertiserFetch();
  }, []);

  const {
    loading: loading_GET_REPORT_BY_CAMPAIGN,

    refetch: refetch_GET_REPORT_BY_CAMPAIGN,
  } = useCustomQuery(GET_REPORT_BY_CAMPAIGN, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.reportByCampaign;
      if (element) {
        setSpendReport(element);
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      ...withAccount,
      ...withUser,
      productId: 2,
      ...isVendor,
    },
  });

  const [
    vs_campaignLiveReportsFromTo,
    {
      loading: loading_GET_REVENUE_BY_CAMPAIGN,

      refetch: refetch_GET_REVENUE_BY_CAMPAIGN,
    },
  ] = useCustomLazyQuery(VS_CAMPAIGN_LIVE_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_campaignLiveReportsFromTo;
      if (element) {
        vsIsForecast ? setCampaignForecastReport(element) : setCampaignMinReport(element);
      }
    },
  });

  const loading = loading_GET_REPORT_BY_CAMPAIGN || loading_GET_REVENUE_BY_CAMPAIGN;

  function refetch() {
    setCampaignMinReport([]);
    setCampaignForecastReport([]);
    refetch_GET_REPORT_BY_CAMPAIGN();
    refetch_GET_REVENUE_BY_CAMPAIGN();
  }
  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };
  useEffect(() => {
    if (campaignForecastReport.length === 0 && vsIsForecast) {
      vs_campaignLiveReportsFromTo({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone.value,
          is_forecast: vsIsForecast,
          platform_id: "1",
          adaccount_id: adAccountIdOnly,
          ...isAdvertiser,
          ...(isUser || withUser),
        },
      });
    } else if (campaignMinReport.length === 0 && !vsIsForecast) {
      vs_campaignLiveReportsFromTo({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone.value,
          is_forecast: vsIsForecast,
          adaccount_id: adAccountIdOnly,
          platform_id: "1",
          ...isAdvertiser,
          ...(isUser || withUser),
        },
      });
    }
    setFirstFetch(false);
  }, [vsIsForecast]);

  useEffect(() => {
    if (!firstFetch) {
      setCampaignMinReport([]);
      setCampaignForecastReport([]);
      vs_campaignLiveReportsFromTo({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone.value,
          is_forecast: vsIsForecast,
          adaccount_id: adAccountIdOnly,
          platform_id: "1",
          ...isAdvertiser,
          ...(isUser || withUser),
        },
      });
    }
  }, [dateRangeTimezone, selectedUser, selectedVSAdvertiser]);

  const initialColumns = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      render: (value, otherProps) => (
        <LookTableColumnRender isClick={() => showDrawerUpdate(otherProps)} title={value} />
      ),
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: "Campaign Id",
      dataIndex: "campaignId",
      key: "campaignId",
      render: (value, otherProps) => (
        <LookTableColumnRender isClick={() => showDrawerUpdate(otherProps)} title={value} />
      ),
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    ...columns,
    ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];
  // Left join because it is only for Tribo Assertive not shinez
  if (!loading) {
    let query = "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.campaign_id = b.campaignId";

    finalMerge = alasql(query, [
      vsIsForecast ? campaignForecastReport : campaignMinReport,
      spendReport,
    ]);

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        impressions = 0 | "0",
        leadsFB = 0,
        total_revenue = 0 | "0",
        user_revenue = 0,
        sessions = 0 | "0",
        pageviews = 0 | "0",
        status = null,
        name = "",
      } = element;

      const spendUpdate = (spendFB * SPEND_INCREASE_PERCENT_VS).toFixed(3);
      // const revenueUpdate = (TotalRevenue * 0.94).toFixed(2);

      const revenueUpdate = user_revenue;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(leadsFB) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        key: index,
        campaignName: name || "",
        TotalRevenue: valueCheck(revenueUpdate),

        clicksFB,
        leadsFB,
        impressionsFB,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        PROFIT_VIEW: valueCheck(PROFIT, true),
        CPC: valueCheck(CPC),
        CPC_VIEW: valueCheck(CPC, true),
        CPC_FB: valueCheck(CPC_FB),
        CPC_FB_VIEW: valueCheck(CPC_FB, true),
        ROI: valueCheck(ROI),
        ROI_VIEW: valueCheck(ROI, true),
        EPC: valueCheck(EPC),
        EPC_VIEW: valueCheck(EPC, true),
        CTR: valueCheck(CTR),
        CTR_VIEW: valueCheck(CTR, true),
        DISC: valueCheck(DISC),
        DISC_VIEW: valueCheck(DISC, true),
        IPP: valueCheck(IPP),
        RPM: valueCheck(RPM),
        PRPM: valueCheck(PRPM),
        PRPM_VIEW: valueCheck(PRPM, true),
        CPR: valueCheck(CPR),
        CPR_VIEW: valueCheck(CPR, true),
        PPS: valueCheck(PPS),
        PPS_VIEW: valueCheck(PPS, true),
        CPM: valueCheck(CPM),
        CPM_VIEW: valueCheck(CPM, true),
        status,
      });
    });
  }

  const finalFilterData = filterData(finalMergeData).filter(
    ({ campaignName = "", campaignId = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";

      const searchCheck =
        campaignName?.toLowerCase()?.includes(searchValue) ||
        campaignId?.toLowerCase()?.includes(searchValue);

      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }
      return false;
    }
  );

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_leadsFB = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    leadsFB = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageViews = 0,
  } of finalFilterData) {
    total_spendFB += JSON.parse(spendFB || 0);
    total_clicksFB += JSON.parse(clicksFB || 0);
    total_impressionsFB += JSON.parse(impressionsFB || 0);
    total_leadsFB += JSON.parse(leadsFB || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageViews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicksFB;
  const total_ROI = (total_PROFIT / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicksFB / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_leadsFB;
  const total_DISC = total_sessions / total_clicksFB;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setVisibleDetailDrawer(false);
  };
  const drawerUpdateBudgetPropsUpdate = {
    title: "Update CampaignBudget",
    visible: visibleBudgetDrawer,
    onCancel: onCloseDrawerBudget,
    footer: false,
  };
  const modalUpdateBulkBudgetPropsUpdate = {
    title: "Update Campaign Budget",
    visible: visibleBulkBudgetDrawer,
    onCancel: onCloseDrawerBulkBudget,
    footer: false,
  };
  const drawerPropsUpdate = {
    title: "Ad Account",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const summaryData = {
    TotalRevenue: valueCheck(total_TotalRevenue, true),
    spendFB: valueCheck(total_spendFB, true),
    PROFIT: total_PROFIT.toFixed(3),
    ROI: valueCheck(total_ROI, true),
    EPC: valueCheck(total_EPC, true),
    CPC: valueCheck(total_CPC, true),
    CPC_FB: valueCheck(total_CPC_FB, true),
    CTR: valueCheck(total_CTR, true),
    leadsFB: valueCheck(total_leadsFB, true),
    CPR: valueCheck(total_CPR, true),
    sessions: valueCheck(total_sessions, true),
    clicksFB: valueCheck(total_clicksFB, true),
    DISC: valueCheck(total_DISC, true),
    IPP: valueCheck(total_IPP, true),
    RPM: valueCheck(total_RPM, true),
    PRPM: valueCheck(total_PRPM, true),
    PPS: valueCheck(total_PPS, true),

    CPM: valueCheck(total_CPM, true),
  };

  const rightOptions = (
    <>
      <div className="mr-12">
        <Tooltip
          title={`${vsIsForecast ? "Forecast" : "Min"} Revenue - Switch to ${
            vsIsForecast ? "Min" : "Forecast"
          }`}
        >
          <Switch checked={vsIsForecast} onChange={handleForecast} />
        </Tooltip>
      </div>

      {isWorkspaceAdmin && (
        <div className="mr-12" style={{ width: 150 }}>
          <Tooltip title="User">
            <span>
              <SelectInput
                search
                name="selectedUser"
                className="mb-0"
                placeholder="Select User"
                value={selectedUser}
                options={[
                  { name: "All", value: "all" },
                  ...vs_users?.map(({ id, name }) => ({ name, value: id })),
                ]}
                onChange={setSelectedUser}
              />
            </span>
          </Tooltip>
        </div>
      )}

      <div style={{ width: 150 }}>
        <Tooltip title="Advertiser">
          <span>
            <SelectInput
              search
              name="selectedAdvertiser"
              className="mb-0"
              placeholder="Select Advertiser"
              value={selectedVSAdvertiserIsAll ? "all" : selectedVSAdvertiser}
              options={[
                { name: "All", value: "all" },
                ...vsAdvertiser?.map(({ id, name }) => ({ name, value: id })),
              ]}
              onChange={(e) => handleAdvertiserSelection(e, true)}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );
  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => callFbAssetStateSubmit(UPDATE_SPEND_STATUS_TYPE_ACTIVE, e),
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => callFbAssetStateSubmit(UPDATE_SPEND_STATUS_TYPE_PAUSED, e),
    },

    {
      option: "dollar",
      title: "Budget Update",
      onClick: (e) => showBudgetBulkDrawer(e, "facebook-postback-campaign"),
    },
  ];

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },

    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
    rightOptions,
    status,
    bulkActions,
  };

  const debugContent = [
    // { isRow: true, contentObjects: [{ finalFilterData }] },
    {
      isRow: true,
      contentObjects: [
        { vsCampaign: vsIsForecast ? campaignForecastReport : campaignMinReport },
        { spendReport },
        { finalFilterData },
      ],
    },
  ];

  return (
    <div>
      <DetailView {...drawerPropsUpdate} {...rowPropsData} />
      {visibleBudgetDrawer && (
        <LookModal {...drawerUpdateBudgetPropsUpdate}>
          <UpdateCapmaignBudget {...rowPropsData} />
        </LookModal>
      )}
      {visibleBulkBudgetDrawer && (
        <LookModal {...modalUpdateBulkBudgetPropsUpdate}>
          <UpdateBulkCampaignBudget
            rowProps={rowPropsData}
            onCloseDrawerBudget={onCloseDrawerBulkBudget}
          />
        </LookModal>
      )}
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
