import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Tabs, Tooltip } from "antd";
import { OverviewCard } from "pages/dashboardPages/mainDashboard/UserRevenuData/Cards";
import { API_STORE_SAS_PLATFORM_BY_USER } from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
import { useSelector } from "react-redux";
import SASRevenueData from "./SASRevenueData";
import {
  SAS_FACEBOOK_PLATFORM_ID,
  SAS_GOOGLE_DISPLAY,
  SAS_TABOOLA_PLATFORM_ID,
  SAS_TIKTOK_PLATFORM_ID,
} from "pages/dashboardPages/SAS/sasKeysAndEnum";
import LookButton from "components/LookButton";

export const definedDashboardPlatformApis = [
  SAS_TIKTOK_PLATFORM_ID,
  SAS_FACEBOOK_PLATFORM_ID,
  SAS_TABOOLA_PLATFORM_ID,
  SAS_GOOGLE_DISPLAY,
];

function SASDashboardData() {
  const { apiCall: platformFetch = () => {}, refetch: platformRefetch } = useApiToStore(
    API_STORE_SAS_PLATFORM_BY_USER
  );

  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);
  const { sasPlatformsByUser = [], sasPlatformsByUser_loading } = userSettingData || {};

  const childRef = useRef();

  useEffect(() => {
    platformFetch();
  }, []);

  // function refetchListData() {
  //   childRef.current.refetch();
  // }

  const overview = Array(4).fill("");

  const renderSkeleton = () => (
    <>
      <div>
        <Row gutter={28}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  const platformData = sasPlatformsByUser?.map(({ id, name }) => ({
    id,
    name,
  }));

  const filteredPlatform = platformData?.filter((item) =>
    definedDashboardPlatformApis.includes(Number(item?.id))
  );

  const platformItems = filteredPlatform?.map((element) => ({
    key: element.id,
    label: (
      <span>
        {/* {element.icon} */}
        {element.name}
      </span>
    ),
    children: <SASRevenueData ref={childRef} {...element} />,
  }));

  return (
    <div>
      {sasPlatformsByUser_loading ? (
        renderSkeleton()
      ) : (
        <div className="scorecard-tabs">
          {platformItems.length > 0 && (
            <Tabs
              items={platformItems}
              // tabBarExtraContent={
              //   <Tooltip title="Refetch">
              //     <LookButton
              //       onClick={() => refetchListData()}
              //       size="small"
              //       iconType="reload"
              //       className="mr-20"
              //     />
              //   </Tooltip>
              // }
            />
          )}
        </div>
      )}
    </div>
  );
}

export default SASDashboardData;
