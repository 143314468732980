import { Checkbox, Form, Button, Tooltip } from "antd";
import { useState } from "react";
import AddForm from "pages/dashboardPages/Cms/Categories/AddForm";
import cx from "classnames";
import { useCustomQuery } from "hooks/apolloClientHooks";
import Loader from "components/loader";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import LookTextInput from "components/forms/LookTextInput";
import { GET_CMS_CATEGORIES_ID_NAME } from "shared/gql/gqlSchema/cmsGql";

export default function CategoriesSelection(props) {
  const { selectedCategories = [], onChange = () => {} } = props;
  const [search, setSearch] = useState("");
  const [categories, setCategoreis] = useState([]);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  // const [selectedIds, setSelectedIds] = useState([]);

  const {
    // data: data_GET_CMS_CATEGORIES,
    loading: loading_GET_CMS_CATEGORIES,
    // error: error_GET_CMS_CATEGORIES,
    refetch,
  } = useCustomQuery(GET_CMS_CATEGORIES_ID_NAME, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      let categories = [];
      e?.CMS_Categories?.forEach((e) => {
        categories.push({ id: e?.id, name: e.name });
        e?.children_category?.forEach((e) => {
          categories.push({ id: e?.id, name: e.name, isChildrenCategory: true });
        });
      });
      setCategoreis(categories);
    },
  });

  const isSelected = (id) => selectedCategories.includes(id);
  const toggleSelected = (id) => (e) => {
    const updatedId = isSelected(id)
      ? [...selectedCategories?.filter((testId) => testId !== id)]
      : [...selectedCategories, id];
    onChange(updatedId);
  };

  // const filterCategories = categories?.filter((p) => {
  //   if (p?.name?.includes(search) || p?.name?.category?.name?.includes(search)) {
  //     return true;
  //   }
  //   return false;
  // });
  const filterCategories = categories?.filter(({ name = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (name?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  const showDrawer = () => {
    setVisibleDrawer(true);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const drawerProps = {
    title: "Add New Category",
    icon: () => <ActionIconRender iconType="appstore" />,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 700,
  };

  return (
    <div className="position-relative">
      <LookDrawer {...drawerProps}>
        <AddForm refetch={refetch} allCategories={categories} />
      </LookDrawer>
      {loading_GET_CMS_CATEGORIES && <Loader elementCenter />}
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex-grow-1">
            <LookTextInput
              name="search"
              value={search}
              className="mb-0"
              placeholder="Search category"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Tooltip title="Add">
            <Button
              type="link"
              onClick={showDrawer}
              icon={<ActionIconRender iconType="plus-circle" />}
            />
          </Tooltip>
        </div>
      </Form>
      <div className="overflow-auto position-relative" style={{ minHeight: 100, maxHeight: 262 }}>
        {filterCategories?.map(
          ({ id, name = "", children_category = [], isChildrenCategory = false }) => (
            <div className={cx({ "pl-24": isChildrenCategory })}>
              <Checkbox checked={isSelected(id)} onChange={toggleSelected(id)}>
                {name}
              </Checkbox>
              {/* {children_category &&
              children_category.map(({ name, id }) => (
                <div className="pl-24">
                  <Checkbox checked={isSelected(id)} onChange={toggleSelected(id)}>
                    {name}
                  </Checkbox>
                </div>
              ))} */}
            </div>
          )
        )}
      </div>
    </div>
  );
}
