import {
  USER_SETTING_SCORECARD_BY_MONTH,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import { DatePicker, Form, Tooltip } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dateMonthYearFormat } from "reducers/userSettings";

export default function MonthSelectPersist(props) {
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN, disabledMonths = () => {} } = props;
  const dispatch = useDispatch();
  const yearMonthInput = useSelector((state) => state?.userSettings?.yearMonthInput);
  const selectedMonth =
    yearMonthInput && yearMonthInput[persistKey] ? yearMonthInput[persistKey] : null;

  function onMonthChange(e) {
    dispatch({
      type: USER_SETTING_SCORECARD_BY_MONTH,
      payload: { ...yearMonthInput, [persistKey]: e },
    });
  }

  return (
    <div>
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex">
            <Form.Item className="mb-0 ml-10">
              <Tooltip title="Select Month">
                <DatePicker
                  style={{ width: 150 }}
                  picker="month"
                  value={selectedMonth ? moment(selectedMonth, dateMonthYearFormat) : null}
                  onChange={(e, f) => onMonthChange(f)}
                  allowClear={false}
                  disabledDate={disabledMonths}
                />
              </Tooltip>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
