import SelectInput from "components/forms/SelectInput";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { TimePicker, Row, Col, Checkbox, DatePicker, Switch } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_MONTHLY,
  INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_WEEKLY,
  INDUSTRY_ISIGHTS_TYPE_DOMAIN,
  industryInsightsDays,
  industryInsightsDefaultNotificationPreferences,
  industryInsightsDefaultNotifyAbout,
  industryInsightsFrequencies,
} from "shared/enum/industryInsightsKeysAndEnums";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import {
  INDUSTRY_INSIGHTS_ADD_NOTIFICATION,
  INDUSTRY_INSIGHTS_DEFAULT_NOTIFICATION,
  INDUSTRY_INSIGHTS_UPDATE_DEFAULT_NOTIFICATION,
} from "shared/gql/gqlSchema/industryInsightsGql";
import { FAILURE, SUCCESS } from "shared/enum/commonKeysandEnum";
import { openNotification } from "utils";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import { dateFormat } from "reducers/userSettings";
import { useSelector } from "react-redux";

function NotificationSettings() {
  const DefaultTimeFormat = "HH:mm";
  const defaultFrequencyData = { name: "DAILY", value: ["DAILY"] };
  const defaultDayData = { name: "Monday", value: ["MONDAY"] };
  const [defaultTime, setDefaultTime] = useState("02:00");
  const [frequency, setFrequency] = useState([]);
  const [day, setDay] = useState(defaultDayData);
  const [date, setDate] = useState(moment().format(dateFormat));
  const [preferences, setPreferences] = useState([]);
  const [notifyAbout, setNotifyAbout] = useState([]);
  const [tobeNotified, setTobeNotified] = useState(false);

  const [defaultNotificationData, setDefaultNotificationData] = useState({});
  const userSelector = useSelector((state) => state?.userReducer);
  const { user: storeUser } = userSelector || {};

  const onChange = (checkedValues, extraProps) => {
    if (extraProps === "notify") {
      setNotifyAbout(checkedValues);
    } else {
      setPreferences(checkedValues);
    }
  };
  const handleDateChange = (date, dateString) => {
    setDate(dateString);
  };

  const handleTimeChange = (time, timeString) => {
    setDefaultTime(timeString);
  };

  const handleFrequency = (name, value) => {
    setFrequency({ name, value: [value] });
  };

  const handleDay = (name, value) => {
    setDay({ name, value: [value] });
  };

  useEffect(() => {
    if (defaultNotificationData !== null) {
      setPreferences(defaultNotificationData?.preference);
      setNotifyAbout(defaultNotificationData?.notifyAbout);
      setFrequency([defaultNotificationData?.defaultSetting?.default_frequency?.split(".")[1]]);
      const defaultDate = moment(defaultNotificationData?.defaultSetting?.date, dateFormat);
      setDate(defaultDate.isValid() ? defaultDate : moment().format(dateFormat));
      const getDay = defaultNotificationData?.defaultSetting?.day;
      setDay(getDay !== null && getDay !== undefined ? [getDay] : defaultDayData);
      setDefaultTime(
        defaultNotificationData?.defaultSetting?.time !== null &&
          defaultNotificationData?.defaultSetting?.time !== undefined
          ? defaultNotificationData?.defaultSetting?.time
          : "02:00"
      );
      setTobeNotified(defaultNotificationData?.toBeNotify);
    } else {
      setPreferences([]);
      setNotifyAbout([]);
      setFrequency([]);
      setDay(defaultDayData);
      setDefaultTime("02:00");
      setDate(moment().format(dateFormat));
      setTobeNotified(false);
    }
  }, [defaultNotificationData]);
  const { loading: loading_GET_DEFAULT_NOTIFICATION, refetch: refetch_GET_DEFAULT_NOTIFICATION } =
    useCustomQuery(INDUSTRY_INSIGHTS_DEFAULT_NOTIFICATION, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ii_DefaultNotifications;
        if (element) {
          if (element?.status === SUCCESS) {
            setDefaultNotificationData(element?.data);
          } else if (element?.status === FAILURE) {
            openNotification({ type: "error", message: element?.message, key: "error-add" });
          }
        }
      },
      onError: (err) => {
        openNotification({ type: "error", message: err.message });
      },
    });

  const refetch = () => {
    refetch_GET_DEFAULT_NOTIFICATION();
  };

  const [addNotification, { loading: loading_addNotification, refetch: refetch_addNotification }] =
    useCustomMutation(INDUSTRY_INSIGHTS_ADD_NOTIFICATION, {
      context: { headers: { "x-auth-email": storeUser?.email } },
      onCompleted: (e) => {
        const element = e?.ii_addNotification;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [
    updateNotification,
    { loading: loading_updateNotification, refetch: refetch_updateNotification },
  ] = useCustomMutation(INDUSTRY_INSIGHTS_UPDATE_DEFAULT_NOTIFICATION, {
    onCompleted: (e) => {
      const element = e?.ii_updateDefaultNotification;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        setPreferences([]);
        setNotifyAbout([]);
        setFrequency([]);
        setDefaultNotificationData({});
        setDay([]);
        refetch();
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const handleDisable = () => {
    if (
      (notifyAbout !== defaultNotificationData?.notifyAbout ||
        preferences !== defaultNotificationData?.preference ||
        defaultTime !== defaultNotificationData?.defaultSetting?.time,
      frequency?.value?.[0] !==
        defaultNotificationData?.defaultSetting?.default_frequency?.split(".")[1],
      date !== defaultNotificationData?.defaultSetting?.date,
      day !== defaultNotificationData?.defaultSetting?.day)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const addDefaultNotification = () => {
    const addNotificationVaribles = {
      day:
        defaultNotificationData?.defaultSetting?.default_frequency?.split(".")[1] ===
        INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_WEEKLY
          ? day?.value?.[0]
          : undefined,
      defaultFrequency: frequency?.value?.[0] || frequency?.[0],
      time: defaultTime,
      notifyAbout: notifyAbout,
      preference: preferences,
      isDefault: true,
      searchType: INDUSTRY_ISIGHTS_TYPE_DOMAIN,
      date:
        defaultNotificationData?.defaultSetting?.default_frequency?.split(".")[1] ===
        INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_MONTHLY
          ? date
          : undefined,
      toBeNotify: tobeNotified,
    };
    if (defaultNotificationData !== null) {
      updateNotification({ variables: addNotificationVaribles });
    } else {
      addNotification({ variables: addNotificationVaribles });
    }
  };

  return (
    <div>
      {loading_GET_DEFAULT_NOTIFICATION ? (
        <Loader title="Fetching Default Notification Setting..." elementCenter center />
      ) : (
        <>
          <DashboardHeader />
          <div className="mt-24 ml-24 mb-0 ">
            <div className="justify-content-between d-flex">
              <div>
                <h2 className="mb-0">Default Settings</h2>
                <p>Default setup of your notification</p>
              </div>
              <div className="mr-20">
                <p className="mb-0">To be notified</p>
                <Switch checked={tobeNotified} onChange={() => setTobeNotified(!tobeNotified)} />
              </div>
            </div>
            <div>
              <Row gutter={15}>
                <Col span={8}>
                  <div>
                    <p>Default Frequency</p>
                    <SelectInput
                      className="mb-0"
                      search
                      placeholder="Select Frequency"
                      name="frequency"
                      value={frequency}
                      options={industryInsightsFrequencies?.map(({ value, name }) => ({
                        name,
                        value,
                      }))}
                      onChange={(name, value) => {
                        handleFrequency(name, value?.value);
                      }}
                    />
                  </div>
                </Col>
                {(frequency?.value?.[0] || frequency?.[0]) ===
                INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_WEEKLY ? (
                  <Col span={8}>
                    <div>
                      <p>Day</p>
                      <SelectInput
                        className="mb-0"
                        search
                        placeholder="Select Day"
                        name="day"
                        value={day}
                        options={industryInsightsDays?.map(({ value, name }) => ({
                          name,
                          value,
                        }))}
                        onChange={(name, value) => {
                          handleDay(name, value?.value);
                        }}
                      />
                    </div>
                  </Col>
                ) : (
                  <></>
                )}

                {(frequency?.value?.[0] || frequency?.[0]) ===
                INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_MONTHLY ? (
                  <Col
                    span={
                      (frequency?.value?.[0] || frequency?.[0]) ===
                      INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_MONTHLY
                        ? 4
                        : 8
                    }
                  >
                    <div>
                      <p>Date</p>
                      <DatePicker
                        onChange={handleDateChange}
                        defaultValue={moment(date, dateFormat)}
                        format={dateFormat}
                        placeholder="Please Enter a Date"
                        allowClear={false}
                      />
                    </div>
                  </Col>
                ) : (
                  <></>
                )}

                <Col span={8}>
                  <p>Default Time</p>
                  <div className="">
                    <TimePicker
                      onChange={handleTimeChange}
                      defaultValue={moment(defaultTime, DefaultTimeFormat)}
                      value={moment(defaultTime, DefaultTimeFormat)}
                      format={DefaultTimeFormat}
                      allowClear={false}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {defaultNotificationData?.preference?.length || defaultNotificationData === null ? (
            <div className="mt-24 ml-24 mb-0">
              <h2 className="mb-0">Notification Preferences</h2>
              <p>Where you want to be notified</p>

              <div>
                <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                  onChange={onChange}
                  defaultValue={defaultNotificationData?.preference}
                >
                  <Row>
                    {industryInsightsDefaultNotificationPreferences?.map(
                      ({ name, value, description }) => (
                        <>
                          <Col span={24}>
                            <Checkbox value={value}>
                              <h3 className="mb-0">{name}</h3>
                              <p>{description}</p>
                            </Checkbox>
                          </Col>
                        </>
                      )
                    )}
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          ) : (
            <></>
          )}
          {defaultNotificationData?.preference?.length || defaultNotificationData === null ? (
            <div className="mt-24 ml-24 mb-0">
              <h2 className="mb-0">Notify about</h2>
              <p>
                We’ll always let you know about important changes, but you pick what else you want
                to hear about.
              </p>

              <div>
                <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => onChange(e, "notify")}
                  defaultValue={defaultNotificationData?.notifyAbout}
                >
                  <Row>
                    {industryInsightsDefaultNotifyAbout?.map(({ name, value, description }) => (
                      <>
                        <Col span={24}>
                          <Checkbox value={value}>
                            <h3 className="mb-0">{name}</h3>
                            <p>{description}</p>
                          </Checkbox>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="flex flex-end mr-50 mb-10 position-fixed">
            <LookButton
              type="primary"
              disabled={handleDisable()}
              onClick={addDefaultNotification}
              loading={loading_GET_DEFAULT_NOTIFICATION}
            >
              Save Changes
            </LookButton>
          </div>
        </>
      )}
    </div>
  );
}

export default NotificationSettings;
