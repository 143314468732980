import AdAccounts from "./AdAccounts";
import Campaigns from "./Campaigns";
import LookTabRouting from "components/LookTabRouting";
import ActionIconRender from "components/ActionIconRender";
import { VS_VIEW_REPORTING } from "shared/UserPermission/userPermissionList";
import AdSets from "./AdSets";
import Ads from "./Ads";

export default function VSTaboolaReport() {
  const tabContent = [
    {
      link: "/ad-account",
      route: "/ad-account",
      title: "Ad Accounts",
      component: AdAccounts,
      icon: () => <ActionIconRender iconType="user" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/campaigns",
      route: "/campaigns",
      title: "Campaigns",
      component: Campaigns,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/ad-sets",
      route: "/ad-sets",
      title: "Ad Sets",
      component: AdSets,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/ads",
      route: "/ads",
      title: "Ads",
      component: Ads,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: VS_VIEW_REPORTING,
    },
  ];

  return (
    <div>
      <LookTabRouting dashboardHeader={{ title: "Taboola Reports" }} tabs={tabContent} />
    </div>
  );
}
