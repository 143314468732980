import DebugPre from "components/DebugPre";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import Loader from "components/loader";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function CategoryNotAssign(props) {
  const { title } = props;
  return (
    <div className="text-center">
      <DashboardHeader title={title} />
      <div className="p-40">
        <h2>
          In order to view report, please assign Category to Landing URLs.
          <br />
          If you see this as error, please contact #tech-support.
        </h2>
        <Link to="/leadgen/landing-urls">Click here to assign category</Link>
      </div>
    </div>
  );
}

export default function CategoryAssignCheck(props) {
  const { voluumIsCategoriesAssignedFetch } = useApiToStoreContext();
  const { renderItem, title = "Reports" } = props;
  const { voluum_is_categories_assigned, voluum_is_categories_assigned_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ voluum_is_categories_assigned, voluum_is_categories_assigned_loading }],
    },
  ];

  const renderData = () =>
    voluum_is_categories_assigned_loading ? (
      <Loader fullScreen center />
    ) : voluum_is_categories_assigned ? (
      renderItem
    ) : (
      <CategoryNotAssign title={title} />
    );

  useEffect(() => {
    voluumIsCategoriesAssignedFetch();
  }, []);

  return (
    <>
      {renderData()}
      <DebugPre content={debugContent} />
    </>
  );
}
