import { useEffect, useState } from "react";
import alasql from "alasql";
import { Tooltip, Button, Switch, Form } from "antd";
import useColumns from "./useColumns";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import LookTable from "components/LookTable";
import {
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  VS_IS_FORECAST_SET,
  VS_IS_FORECAST_UNSET,
} from "actions/actionTypes";
import {
  FB360_BULK_UPDATE_STATUS_BY_NAME,
  GET_FB_360_REPORT_BY_AD_NAME,
  PINTEREST_CAMPAIGN_GET_REPORT,
  VS_CAMPAIGN_REPORT,
  VS_REVENUE_CRON_STATUS,
} from "shared/gql/gqlSchema/viralSparksGql";
import SelectInput from "components/forms/SelectInput";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { openNotification } from "utils";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import Loader from "components/loader";
import VSCampaignReportDetailView from "./VSCampaignReportDetailView";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useVSAdvertiserHandler, {
  VSCheckDefalulSelectedAdvertiser,
} from "../useVSAdvertiserHandler";
import LookModal from "components/LookModal";
import { TAG_ADD_TAGS } from "shared/gql/gqlSchema/tagsGql";
import {
  API_STORE_VIRALSPARKS_ADVERTISE,
  API_STORE_VIRALSPARKS_PLATFORM,
} from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { VS_KEY_CAMPAIGN } from "shared/SharedKeys";
import { SPEND_INCREASE_PERCENT_VS } from "shared/enum/commonKeysandEnum";

function MainReport(props) {
  const [tags, setTags] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const { handleAdvertiserSelection } = useVSAdvertiserHandler();
  const [multiRow, setMultiRow] = useState([]);

  const {
    selectedVSAdvertiserIsAll = true,
    selectedVSAdvertiser = "",
    vsIsForecast,
  } = useSelector((state) => state?.vsReducer) || {};

  function handleForecast(e) {
    dispatch({ type: e ? VS_IS_FORECAST_SET : VS_IS_FORECAST_UNSET });
  }
  const { columns } = useColumns();

  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const { userReport = false } = props;
  const userSettings = useSelector((state) => state?.userSettings);

  const { userSettingData } = userSettings || {};

  const {
    vsAdvertiser = [],
    vsAdvertiser_loading = false,
    vsPlatforms = [],
    vsPlatforms_loading = false,
  } = userSettingData || {};

  const [selectedPlatform, setSelectedPlatform] = useState("all");

  const { dateRangeTimezone } = useSelector((state) => state?.userSettings) || {};

  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const { selectedRowsClear } = useLookTableParentContext();

  const persistColumns = useSelector((state) => state?.userSettings?.columns?.[persistKey]) || [];

  const {
    data: data_VS_REVENUE_CRON_STATUS,
    loading: loading_VS_REVENUE_CRON_STATUS,
    error: error_VS_REVENUE_CRON_STATUS,
    refetch: refetch_VS_REVENUE_CRON_STATUS,
  } = useCustomQuery(VS_REVENUE_CRON_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: { id: 1 },
  });

  const [campaignForecastReport, setCampaignForecastReport] = useState([]);
  const [campaignMinReport, setCampaignMinReport] = useState([]);

  const [spendReport, setSpendReport] = useState([]);
  const userIdForSpendReport = userReport ? { user_id: params?.userId } : {};
  const isPlatform = selectedPlatform === "all" ? {} : { platform_id: selectedPlatform };
  const isAdvertiser = selectedVSAdvertiserIsAll ? {} : { advertiser_id: selectedVSAdvertiser };
  const isVendor = selectedVSAdvertiserIsAll ? {} : { vendorId: selectedVSAdvertiser };

  let reportSettings = {
    spendApi: GET_FB_360_REPORT_BY_AD_NAME,
    spendApiKey: "fb360_adReportingByName",
    spendVariables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      ...userIdForSpendReport,
      // ...isVendor,
    },
  };

  if (selectedPlatform === "2") {
    reportSettings = {
      spendApi: PINTEREST_CAMPAIGN_GET_REPORT,
      spendApiKey: "pinterest_getCampaignReport",
      spendVariables: {
        start: dateRangeTimezone?.rangeDates[0],
        end: dateRangeTimezone?.rangeDates[1],
        timezone: dateRangeTimezone?.timeZone?.value,
      },
    };
  }

  const [
    getCampaignReports,
    {
      data: data_GET_REPORT_DATA,
      loading: loading_GET_REPORT_DATA,
      error: error_GET_REPORT_DATA,
      refetch: refetch_GET_REPORT_DATA,
    },
  ] = useCustomLazyQuery(VS_CAMPAIGN_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_campaignReportsFromTo;
      if (element) {
        vsIsForecast ? setCampaignForecastReport(element) : setCampaignMinReport(element);
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      is_forecast: vsIsForecast,
      ...isAdvertiser,
      ...isPlatform,
    },
  });

  const [
    getFbSpendReport,
    {
      data: data_GET_AD_REPORT,
      loading: loading_GET_AD_REPORT,
      error: error_GET_AD_REPORT,
      refetch: refetch_GET_AD_REPORT,
    },
  ] = useCustomLazyQuery(reportSettings.spendApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[reportSettings.spendApiKey];
      if (element) {
        if (reportSettings.spendApiKey === "fb360_adReportingByName") {
          setSpendReport(
            element?.map((data) => ({
              ...data,
              spendFB: data?.spend,
              clicksFB: data?.clicks,
              impressionsFB: data?.impressions,
              adName: data?.ad_name,
              leadsFB: data?.result,
            }))
          );
        } else {
          setSpendReport(element);
        }
      }
    },
    variables: {
      ...reportSettings.spendVariables,
    },
  });

  const [
    getPinterestSpendReport,
    {
      loading: loading_GET_PINTEREST_REPORT,
      error: error_GET_PINTEREST_REPORT,
      refetch: refetch_GET_PINTEREST_REPORT,
    },
  ] = useCustomLazyQuery(PINTEREST_CAMPAIGN_GET_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.pinterest_getCampaignReport;
      if (element) {
        setSpendReport(element);
      }
    },
    variables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
    },
  });

  const loading = loading_GET_REPORT_DATA || loading_GET_AD_REPORT || loading_GET_PINTEREST_REPORT;
  const refetch = () => {
    setCampaignMinReport([]);
    setCampaignForecastReport([]);
    refetch_GET_REPORT_DATA({
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      is_forecast: vsIsForecast,
      ...isAdvertiser,
      ...isPlatform,
    });

    switch (selectedPlatform) {
      case "2":
        refetch_GET_PINTEREST_REPORT();
        break;
      default:
        refetch_GET_AD_REPORT();
    }
    refetch_VS_REVENUE_CRON_STATUS();
  };

  const [fb360_bulkUpdateStatusByName, { loading: fb360_bulkUpdateStatusByName_loading }] =
    useCustomMutation(FB360_BULK_UPDATE_STATUS_BY_NAME, {
      onCompleted(e) {
        const success = e?.fb360_bulkUpdateStatusByName?.success;
        const error = e?.fb360_bulkUpdateStatusByName?.error;
        if (success?.length > 0) {
          openNotification({
            type: "success",
            message: success?.map(({ message }) => message)?.join(","),
          });
        }
        if (error?.length > 0) {
          openNotification({
            type: "error",
            message: error?.map(({ message }) => message)?.join(","),
          });
        }
        selectedRowsClear();
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    });

  function updateSelectedStatus(rowData, status) {
    const updateArray = rowData
      ?.filter(({ adName }) => adName)
      ?.map(({ adName, adaccount_id }) => ({ name: adName, adaccount_id }));

    if (updateArray && updateArray?.length > 0) {
      if (updateArray.length > 10) {
        return openNotification({ type: "warning", message: "Please select max 10 records" });
      }
      fb360_bulkUpdateStatusByName({
        variables: { status, asset: VS_KEY_CAMPAIGN, updateArray },
      });
    }
  }

  const actions = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        const { adName } = actionProps;
        return (
          <>
            {adName && (
              <>
                <Tooltip title="Active">
                  <Button
                    type="link"
                    onClick={() => updateSelectedStatus([actionProps], "ACTIVE")}
                    icon={<ActionIconRender iconType="play" />}
                  />
                </Tooltip>
                <Tooltip title="Pause">
                  <Button
                    type="link"
                    danger
                    onClick={() => updateSelectedStatus([actionProps], "PAUSED")}
                    icon={<ActionIconRender iconType="pause" />}
                  />
                </Tooltip>
              </>
            )}
          </>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const initialColumns = [
    {
      title: "Campaign Name",
      dataIndex: "utmCampaign",
      key: "utmCampaign",

      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return (
          <LookTableColumnRender
            title={value}
            isProfit={PROFIT}
            isClick={() => showDrawerUpdate(otherProps)}
          />
        );
      },
      width: 280,
      minWidth: 280,
      fixed: "left",
      searchType: "string",
    },
    {
      title: "Adaccount Id",
      dataIndex: "adaccount_id",
      key: "adaccount_id",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDrawerUpdate(otherProps)} />
      ),
      width: 170,
      minWidth: 130,
      hidden: true,
      searchType: "string",
    },
    {
      title: "Brand Name",
      dataIndex: "brand_name",
      key: "brand_name",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDrawerUpdate(otherProps)} />
      ),
      width: 170,
      minWidth: 130,
      searchType: "string",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDrawerUpdate(otherProps)} />
      ),
      width: 200,
      minWidth: 200,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      searchType: "string",
    },
    ...columns,
    ...actions,
  ];

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(3);
    return valueFloat;
  };

  let finalMerge = [];
  let finalMergeData = [];
  // Left join because of different brands..from fb combined spend of all brands
  if (!loading) {
    let query = "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.utm_campaign = b.adName";

    finalMerge = alasql(query, [
      vsIsForecast ? campaignForecastReport : campaignMinReport,
      spendReport,
    ]);

    // eslint-disable-next-line no-unused-expressions
    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        leadsFB = 0,
        impressions = 0 | "0",
        sessions = 0 | "0",
        pageviews = 0 | "0",
        utm_campaign,
        adName = "",
        user_revenue = 0,
        status = null,
      } = element;

      const spendUpdate = (spendFB * SPEND_INCREASE_PERCENT_VS).toFixed(3);
      // const revenueUpdate = (TotalRevenue * 0.94).toFixed(2);

      const revenueUpdate = user_revenue;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(leadsFB) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        utmCampaign: utm_campaign || adName,
        TotalRevenue: valueCheck(revenueUpdate),
        clicksFB,
        leadsFB,
        impressionsFB,
        key: index,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        PROFIT_VIEW: valueCheck(PROFIT, true),
        CPC: valueCheck(CPC),
        CPC_VIEW: valueCheck(CPC, true),
        CPC_FB: valueCheck(CPC_FB),
        CPC_FB_VIEW: valueCheck(CPC_FB, true),
        ROI: valueCheck(ROI),
        ROI_VIEW: valueCheck(ROI, true),
        EPC: valueCheck(EPC),
        EPC_VIEW: valueCheck(EPC, true),
        CTR: valueCheck(CTR),
        CTR_VIEW: valueCheck(CTR, true),
        DISC: valueCheck(DISC),
        DISC_VIEW: valueCheck(DISC, true),
        IPP: valueCheck(IPP),
        RPM: valueCheck(RPM),
        PRPM: valueCheck(PRPM),
        PRPM_VIEW: valueCheck(PRPM, true),
        CPR: valueCheck(CPR),
        CPR_VIEW: valueCheck(CPR, true),
        PPS: valueCheck(PPS),
        PPS_VIEW: valueCheck(PPS, true),
        CPM: valueCheck(CPM),
        CPM_VIEW: valueCheck(CPM, true),
        status,
      });
    });
  }

  const finalFilterData = filterData(finalMergeData)?.filter(({ utmCampaign = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (utmCampaign?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_leadsFB = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    leadsFB = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageViews = 0,
  } of finalFilterData) {
    total_spendFB += JSON.parse(spendFB || 0);
    total_clicksFB += JSON.parse(clicksFB || 0);
    total_impressionsFB += JSON.parse(impressionsFB || 0);
    total_leadsFB += JSON.parse(leadsFB || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageViews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicksFB;
  const total_ROI = (total_PROFIT / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicksFB / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_leadsFB;
  const total_DISC = total_sessions / total_clicksFB;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => updateSelectedStatus(e, "ACTIVE"),
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => updateSelectedStatus(e, "PAUSED"),
    },
    {
      option: "tag",
      title: "Add Tag",
      onClick: (e) => {
        setMultiRow(e);
        setModalVisible(true);
      },
    },
  ];

  const summaryData = {
    TotalRevenue: valueCheck(total_TotalRevenue, true),
    spendFB: valueCheck(total_spendFB, true),
    PROFIT: total_PROFIT.toFixed(3),
    ROI: valueCheck(total_ROI, true),
    EPC: valueCheck(total_EPC, true),
    CPC: valueCheck(total_CPC, true),
    CPC_FB: valueCheck(total_CPC_FB, true),
    CTR: valueCheck(total_CTR, true),
    leadsFB: valueCheck(total_leadsFB, true),
    CPR: valueCheck(total_CPR, true),
    sessions: valueCheck(total_sessions, true),
    clicksFB: valueCheck(total_clicksFB, true),
    DISC: valueCheck(total_DISC, true),
    IPP: valueCheck(total_IPP, true),
    RPM: valueCheck(total_RPM, true),
    PRPM: valueCheck(total_PRPM, true),
    PPS: valueCheck(total_PPS, true),
    CPM: valueCheck(total_CPM, true),
  };
  const tableStatus = (
    <div className="d-inline-flex" style={{ fontSize: 12 }}>
      <p className="mb-0">
        Revenue Synced&nbsp;
        {data_VS_REVENUE_CRON_STATUS?.cron_status_time}
        {moment
          .utc(data_VS_REVENUE_CRON_STATUS?.vs_cronStatus?.cron_status_time)
          .from(moment().tz("UTC"))}
      </p>
    </div>
  );

  const rightOptions = (
    <>
      <div className="mr-12">
        <Tooltip
          title={`${vsIsForecast ? "Forecast" : "Min"} Revenue - Switch to ${
            vsIsForecast ? "Min" : "Forecast"
          }`}
        >
          <Switch checked={vsIsForecast} onChange={handleForecast} />
        </Tooltip>
      </div>

      {vsPlatforms && vsPlatforms?.length > 1 && (
        <div className="mr-12" style={{ width: 150 }}>
          <SelectInput
            search
            name="selectedPlatform"
            className="mb-0"
            placeholder="Select Platform"
            value={selectedPlatform}
            options={[
              { name: "All", value: "all" },
              ...vsPlatforms?.map(({ id, name }) => ({ name, value: id })),
            ]}
            onChange={setSelectedPlatform}
          />
        </div>
      )}
      <div style={{ width: 150 }}>
        <SelectInput
          search
          name="selectedAdvertiser"
          className="mb-0"
          placeholder="Select Advertiser"
          value={selectedVSAdvertiserIsAll ? "all" : selectedVSAdvertiser}
          options={[
            { name: "All", value: "all" },
            ...vsAdvertiser?.map(({ id, name }) => ({ name, value: id })),
          ]}
          onChange={(e) => handleAdvertiserSelection(e, true)}
        />
      </div>
    </>
  );

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    status: tableStatus,
    rightOptions,

    bulkActions,
    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    // uniqueKey,
  };

  const debugContent = [
    // { isRow: true, contentObjects: [{ vsPlatforms }] },
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    {
      isRow: true,
      contentObjects: [
        { vsCampaign: vsIsForecast ? campaignForecastReport : campaignMinReport },
        { spendReport },
      ],
    },
  ];
  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({ ...rowProps });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => setVisibleDetailDrawer(false);

  const drawerdetailsViewPropsUpdate = {
    title: "Performance",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const [addTagsViralSparks, { data: data_Tags, loading: loading_Tags }] = useCustomMutation(
    TAG_ADD_TAGS,
    {
      onCompleted: (e) => {
        openNotification({ type: "success", message: "Tags added sucessfully" });
      },
      onError: (e) => {
        openNotification({ type: "error", message: "Something went wrong..!" });
      },
    }
  );

  const onSubmitUpdateModal = () => {
    const identifier = multiRow.map((el) => el.utmCampaign);
    addTagsViralSparks({ variables: { identifier, tags, type: "vs" } });
    setModalVisible(false);
  };
  const onCloseDrawerUpdate = () => {
    setModalVisible(false);
    setMultiRow([]);
  };

  const modalProps = {
    visible: isModalVisible,
    onSubmit: onSubmitUpdateModal,
    onCancel: onCloseDrawerUpdate,
    okokText: "Add Tags",
    disabled: tags.length === 0,
    loader: loading_Tags,
  };

  useEffect(() => {
    if (campaignForecastReport.length === 0 && vsIsForecast) {
      getCampaignReports({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone?.value,
          is_forecast: vsIsForecast,
          ...isAdvertiser,
          ...isPlatform,
        },
      });
    } else if (campaignMinReport.length === 0 && !vsIsForecast) {
      getCampaignReports({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone?.value,
          is_forecast: vsIsForecast,
          ...isAdvertiser,
          ...isPlatform,
        },
      });
    }
    switch (selectedPlatform) {
      case "2":
        getPinterestSpendReport();
        break;
      default:
        getFbSpendReport();
    }
    setFirstFetch(false);
  }, [vsIsForecast, selectedPlatform]);

  useEffect(() => {
    if (!firstFetch) {
      setCampaignMinReport([]);
      setCampaignForecastReport([]);
      getCampaignReports({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone?.value,
          is_forecast: vsIsForecast,
          ...isAdvertiser,
          ...isPlatform,
        },
      });
      switch (selectedPlatform) {
        case "2":
          getPinterestSpendReport();
          break;
        default:
          getFbSpendReport();
      }
    }
  }, [dateRangeTimezone, selectedPlatform, selectedVSAdvertiser]);

  return (
    <div>
      <VSCampaignReportDetailView
        {...drawerdetailsViewPropsUpdate}
        {...rowPropsData}
        selectedPlatform={selectedPlatform}
        timezone={dateRangeTimezone?.timeZone?.value}
      />
      <LookModal {...modalProps}>
        <Form layout="vertical">
          <SelectInput
            name="text"
            label="Tags"
            className="look-form-input"
            placeholder="Enter Tags"
            mode="tags"
            value={tags}
            onChange={(name) => {
              setTags(name);
            }}
          />
        </Form>
      </LookModal>

      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default function VSCampaignReport() {
  const { apiCall: vsPlatformsFetch = () => {}, refetch: vsPlatformsRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_PLATFORM
  );
  const { apiCall: vsAdvertiserFetch = () => {}, refetch: vsAdvertiserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_ADVERTISE
  );

  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const debugContent = [{ isRow: true, contentObjects: [{ vsAdvertiser_loading, vsAdvertiser }] }];
  const headerProps = { title: "Campaign Report" };

  useEffect(() => {
    vsPlatformsFetch();
    vsAdvertiserFetch();
  }, []);
  return (
    <div>
      <VSCheckDefalulSelectedAdvertiser />
      <DashboardHeader {...headerProps} />
      {vsAdvertiser?.length === 0 && vsAdvertiser_loading ? (
        <Loader center />
      ) : vsAdvertiser && vsAdvertiser?.length > 0 ? (
        <MainReport />
      ) : null}
      <DebugPre content={debugContent} />
    </div>
  );
}
