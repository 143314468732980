import loaderSpin from "../../assets/images/loader-spin.gif";
import flagloaderSpin from "../../assets/images/flag-loader-spin2.gif";

import loaderBlink from "../../assets/images/loader-blink.gif";
import { LoadingOutlined } from "@ant-design/icons";

import cx from "classnames";

function Loader(props) {
  const {
    className = "",
    center = false,
    bgBlur = false,
    fullScreen = false,
    blink = false,
    elementCenter = true,
    elementBottomCenter = false,
    largeLoader = false,
    rightCenter = false,
    spiner = false,
    title = "",
  } = props;
  return (
    <div
      className={cx("main-loader", className, {
        fullScreen,
        bgBlur,
        "text-center": center,
        elementCenter,
        rightCenter,
        elementBottomCenter,
      })}
    >
      <div>
        {title && <h1 className="title">{title}</h1>}
        {spiner ? (
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        ) : (
          <img
            className={cx("main-loader", { largeLoader })}
            // style={{ height: "70px" }}
            src={blink ? loaderBlink : loaderSpin}
            alt="loader"
          />
        )}
      </div>
    </div>
  );
}

export default Loader;
