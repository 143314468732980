import { useCustomMutation } from "hooks/apolloClientHooks";

import { G360_BULK_STATUS_UPDATE } from "shared/gql/gqlSchema/g360Gql";

import { openNotification } from "utils";
export function groupForStatusGoogle(inputArray, reportType) {
  const res = [];
  if (reportType === "adset") {
    reportType = "adgroup";
  }
  reportType = reportType + "_id";
  for (let i of inputArray) {
    if (i.customer_id === null || i.customer_id === undefined) {
      continue;
    }
    const has = res.findIndex((el) => el.customer_id === i.customer_id);
    if (has === -1) {
      res.push({
        customer_id: i.customer_id,
        [reportType]: [i[reportType]],
      });
    } else {
      res[has] = {
        customer_id: i.customer_id,
        [reportType]: [...res[has][reportType], i[reportType]],
      };
    }
  }
  return res;
}
export default function useGoogleBulkStatusUpdateMuatation(refetch) {
  const [updateBulkStatus, { loading: loading_update }] = useCustomMutation(
    G360_BULK_STATUS_UPDATE,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.g_bulkStatusUpdate;
        refetch();
        openNotification({ message: element, type: "success" });
      },
    }
  );

  return { updateBulkStatus, loading_update };
}
