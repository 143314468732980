import {
  FacebookOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  PicRightOutlined,
  UserOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import {
  F360_VIEW_ASSET_AD_ACCOUNTS,
  F360_VIEW_CAMPAIGNS,
  F360_VIEW_CATEGORY,
  F360_VIEW_PROVIDERS,
  F360_MANAGE_CONNECT_ACCOUNT,
} from "../../UserPermission/userPermissionList";
import { useSelector } from "react-redux";

const useF360Path = () => {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);
  const subMenu =
    user_id !== "142"
      ? [
          {
            subMenuName: "Profile",
            subMenuLink: "/profile",
            subMenuIcon: UserOutlined,
            permission: F360_MANAGE_CONNECT_ACCOUNT,
          },
          {
            subMenuName: "Assets",
            subMenuLink: "/assets",
            subMenuIcon: DatabaseOutlined,
            permission: F360_VIEW_ASSET_AD_ACCOUNTS,
          },
          // - UNCOMMENT
          // {
          //   subMenuName: "Ad Library",
          //   subMenuLink: "/ad-library",
          //   subMenuIcon: IdcardOutlined,
          // },
          {
            subMenuName: "Providers",
            subMenuLink: "/providers",
            subMenuIcon: IdcardOutlined,
            permission: F360_VIEW_PROVIDERS,
          },
          {
            subMenuName: "Campaigns",
            subMenuLink: "/campaigns",
            subMenuIcon: PicRightOutlined,
            permission: F360_VIEW_CAMPAIGNS,
          },
          // {
          //   subMenuName: "Category",
          //   subMenuLink: "/category",
          //   subMenuIcon: PicRightOutlined,
          //   permission: F360_VIEW_CATEGORY,
          // },
          // {
          //   subMenuName: "Landing Urls",
          //   subMenuLink: "/landing-urls",
          //   subMenuIcon: PicRightOutlined,
          //   permission: F360_VIEW_CAMPAIGNS,
          // },
          // {
          //   subMenuName: "Launch",
          //   subMenuIcon: RocketOutlined,
          //   permission: F360_VIEW_CAMPAIGNS,
          //   isCollapsible: true,
          //   subMenus: [
          //     {
          //       subMenuName: "Bucket",
          //       subMenuLink: "/launch/bucket",
          //       subMenuIcon: PicRightOutlined,
          //       permission: F360_VIEW_CAMPAIGNS,
          //     },
          //     {
          //       subMenuName: "Targeting",
          //       subMenuLink: "/launch/target",
          //       subMenuIcon: PicRightOutlined,
          //       permission: F360_VIEW_CAMPAIGNS,
          //     },
          //     {
          //       subMenuName: "List",
          //       subMenuLink: "/launch/list",
          //       subMenuIcon: PicRightOutlined,
          //       permission: F360_VIEW_CAMPAIGNS,
          //     },
          //   ],
          // },

          // - UNCOMMENT
          // {
          //   subMenuName: "Launch",
          //   subMenuLink: "/launch",
          //   subMenuIcon: IdcardOutlined,
          // },
          // {
          //   subMenuName: "Automation",
          //   subMenuLink: "/automation",
          //   subMenuIcon: IdcardOutlined,
          // },
          // {
          //   subMenuName: "Strategies",
          //   subMenuLink: "/strategies",
          //   subMenuIcon: IdcardOutlined,
          // },
          // {
          //   subMenuName: "Compliance",
          //   subMenuLink: "/compliance",
          //   subMenuIcon: IdcardOutlined,
          // },
          // {
          //   subMenuName: "Recomendations",
          //   subMenuLink: "/recomendations",
          //   subMenuIcon: IdcardOutlined,
          // },
        ]
      : [];

  const f360Path = [
    {
      name: "F360",
      link: "/f360",
      icon: FacebookOutlined,
      subMenu,
    },
  ];
  return { f360Path };
};

export default useF360Path;
