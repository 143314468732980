import { GlobalOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { Button, Form, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import Loader from "components/loader";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookModal from "components/LookModal";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PLATFORM_TYPE_FACEBOOK, PLATFORM_TYPE_TIKTOK } from "shared/enum/launchKeysAndEnum";
import {
  DELETE_LAUNCH_TEMPLATE,
  DUPLICATE_LAUNCH_TEMPLATE,
  FETCH_TIKTOK_LAUNCH_TEMPLATE,
  LAUNCH_GET_TEMPLATE,
  LAUNCH_PLATFORM_DATA,
} from "shared/gql/gqlSchema/launchGql";
import { openNotification } from "utils";
import AddForm from "./AddForm";
import UpdateForm from "./UpdateForm";

export default function LaunchTarget(props) {
  const [visibleDrawerAdd, setVisibleDrawerAdd] = useState(false);
  const { productId } = props;
  const [visibleDrawerUpdate, setVisibleDrawerUpdate] = useState(false);
  const [rowProps, setRowProps] = useState({});
  const [visibleDuplicateModal, setVisibleDuplicateModal] = useState(false);
  const [duplicateModalPropsData, setDuplicateModalPropsData] = useState();
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  // const templatePlatform =
  //   useSelector((state) => state?.launchReducer?.templatePlatform) || "facebook";
  const [step, setStep] = useState(0);
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData();
    setDeleteModalVisible(false);
  };
  const [platformFetch, { data: platformsData, loading: PLATFORM_LOADING }] = useCustomLazyQuery(
    LAUNCH_PLATFORM_DATA,
    {
      onCompleted: (e) => {
        // if (e?.templatePlatforms?.filter((item) => item?.name === templatePlatforms)[0]?.id) {
        // fetchFbTemplate();
        // } else {
        //   fetchTiktokTemplate();
        // }
        // setTemplatePlatforms(
        //   e?.templatePlatforms?.filter((item) => item.name === "facebook")[0]?.id
        // );
      },
    }
  );

  useEffect(() => {
    platformFetch();
  }, []);

  const [templatePlatforms, setTemplatePlatforms] = useState(PLATFORM_TYPE_FACEBOOK);
  const [fetchFbTemplate, { data: FbData, refetch: FbRefetch, loading: FbLoading }] =
    useCustomLazyQuery(LAUNCH_GET_TEMPLATE, {
      notifyOnNetworkStatusChange: true,
    });
  const [
    fetchTiktokTemplate,
    { data: TiktokData, refetch: RefetchTiktokTemplate, loading: LoadingTiktokTemplate },
  ] = useCustomLazyQuery(FETCH_TIKTOK_LAUNCH_TEMPLATE, {
    notifyOnNetworkStatusChange: true,
  });

  // if (templatePlatforms === "facebook") {
  //   fetchFbTemplate();
  // } else if (templatePlatforms === "tiktok") {
  //   fetchTiktokTemplate();
  // }

  useEffect(() => {
    if (!PLATFORM_LOADING) {
      if (templatePlatforms === PLATFORM_TYPE_FACEBOOK) {
        fetchFbTemplate({
          variables: {
            productId,
            platformId: platformsData?.templatePlatforms?.filter(
              (item) => item?.name === templatePlatforms
            )[0]?.id,
          },
        });
      } else if (templatePlatforms === PLATFORM_TYPE_TIKTOK) {
        fetchTiktokTemplate({
          variables: {
            productId,
            platformId: platformsData?.templatePlatforms?.filter(
              (item) => item?.name === templatePlatforms
            )[0]?.id,
          },
        });
      }
    }
  }, [templatePlatforms]);

  const showDrawerAdd = () => {
    setVisibleDrawerAdd(true);
  };

  const onCloseDrawerAdd = () => {
    setVisibleDrawerAdd(false);
  };

  const onOpenDuplicateModal = (rowProps) => {
    setVisibleDuplicateModal(true);
    setDuplicateModalPropsData(rowProps);
  };

  const onCloseDuplicateModal = () => {
    setVisibleDuplicateModal(false);
    setDuplicateModalPropsData(null);
  };

  const showDrawerUpdate = (rowProps) => {
    if (templatePlatforms === "facebook") {
      setRowProps(rowProps);
    } else {
      setRowProps({ tiktokProps: rowProps });
    }
    setVisibleDrawerUpdate(true);
  };

  const onCloseDrawerUpdate = () => {
    setRowProps([]);
    setVisibleDrawerUpdate(false);
  };
  const [delData, { loading: deleteLoader }] = useCustomMutation(DELETE_LAUNCH_TEMPLATE, {
    onCompleted(e) {
      if (templatePlatforms === PLATFORM_TYPE_FACEBOOK) {
        FbRefetch();
      } else {
        RefetchTiktokTemplate();
      }
      setDeleteModalVisible(false);
      openNotification({ type: "success", message: "Record Deleted Success" });
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Deleted Not Success",
        description: e.message,
      });
    },
  });

  const [duplicateLaunchTemplate, { loading: duplicateLaunchTemplateLoader }] = useCustomMutation(
    DUPLICATE_LAUNCH_TEMPLATE,
    {
      onCompleted(e) {
        if (templatePlatforms === PLATFORM_TYPE_FACEBOOK) {
          FbRefetch();
        } else {
          RefetchTiktokTemplate();
        }
        setVisibleDuplicateModal(false);
        openNotification({ type: "success", message: "Duplicate created successfully!" });
      },
      onError(e) {
        openNotification({
          type: "error",
          message: "Something went wrong!",
          description: e.message,
        });
      },
    }
  );

  const onSubmitDuplicateModal = () => {
    duplicateLaunchTemplate({
      variables: {
        template_id: duplicateModalPropsData?.id,
        platform_type: templatePlatforms.toLocaleUpperCase(),
      },
    });
  };

  const onSubmitDeleteModal = () => {
    delData({
      variables: {
        id: deleteModalPropsData.id,
        platform_type: templatePlatforms.toLocaleUpperCase(),
      },
    });
  };
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };

  const actions = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        return (
          <div className="action-column d-flex justify-content-around">
            <div className="more-actions flex-around">
              <Tooltip title="Duplicate">
                <Button
                  className="ml-12 p-0 h-auto w-auto"
                  type="link"
                  icon={<ActionIconRender iconType="copy" />}
                  onClick={() => onOpenDuplicateModal(actionProps)}
                />
              </Tooltip>
              <Tooltip title="Update">
                <Button
                  className="ml-12 p-0 h-auto w-auto"
                  type="link"
                  icon={<ActionIconRender iconType="edit" />}
                  onClick={() => showDrawerUpdate(actionProps)}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  className="ml-12 p-0 h-auto w-auto"
                  type="link"
                  icon={<ActionIconRender iconType="delete" />}
                  onClick={() => onOpenDeleteModal(actionProps)}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const columns = [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (value, otherProps) => <LookTableColumnRender title={value} />,
    //   width: 130,
    //   minWidth: 130,
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 130,
      minWidth: 130,
      searchable: true,
    },
    {
      title: "Budget",
      dataIndex: "dailyBudget",
      key: "dailyBudget",
      render: (value, otherProps) => <LookTableColumnRender title={otherProps?.budget} />,
      width: 130,
      minWidth: 130,
    },

    ...(templatePlatforms === "facebook"
      ? [
          {
            title: "Bid Strategy",
            dataIndex: "bidStrategy",
            key: "bidStrategy",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 130,
            minWidth: 130,
          },
        ]
      : [
          {
            title: "Bid Type",
            dataIndex: "bidType",
            key: "bidType",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 130,
            minWidth: 130,
          },
          {
            title: "Bid",
            dataIndex: "bidPrice",
            key: "bidPrice",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 130,
            minWidth: 130,
          },
        ]),
    // {
    //   title: "Created By",
    //   dataIndex: "createdBy",
    //   key: "createdBy",
    //   render: (value, otherProps) => <LookTableColumnRender title={value} />,
    //   width: 130,
    //   minWidth: 130,
    // },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (value, otherProps) => <LookTableColumnRender title={value} />,
    //   width: 130,
    //   minWidth: 130,
    // },

    ...actions,
  ];

  const createBtnProps = {
    createBtn: true,
    createBtnText: "Add Template",
    createBtnHandler: showDrawerAdd,
  };

  let renderData = [];
  if (templatePlatforms === PLATFORM_TYPE_FACEBOOK) {
    FbData?.launchTemplates?.map((element) => {
      renderData.push({ ...element, key: element.id });
    });
  } else {
    TiktokData?.tiktokLaunchTemplates?.map((element) => {
      renderData.push({ ...element, key: element.id });
    });
  }
  const finalFilterData = renderData.filter(({ name = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (name?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  const tableProps = { initialColumns: columns, recordData: finalFilterData };
  const drawerPropsAdd = {
    title: (
      <>
        {step === 1 && <ArrowLeftOutlined onClick={() => setStep(0)} className="mr-10" />}
        Add Template
      </>
    ),
    visible: visibleDrawerAdd,
    onClose: onCloseDrawerAdd,

    size: 900,
    titleFun: setStep,
  };
  const drawerPropsUpdate = {
    title: "Edit Template",
    visible: visibleDrawerUpdate,
    onClose: onCloseDrawerUpdate,
    icon: GlobalOutlined,
    size: 700,
  };

  const bulkCloneModalProps = {
    visible: visibleDuplicateModal,
    onSubmit: onSubmitDuplicateModal,
    onCancel: onCloseDuplicateModal,
    disabled: duplicateLaunchTemplateLoader,
    okokText: "Submit",
    loader: duplicateLaunchTemplateLoader,
  };

  return (
    <div>
      {PLATFORM_LOADING ? (
        <Loader />
      ) : (
        <>
          <LookTableRecordDeleteModal {...deleteModalProps} />
          <LookModal {...bulkCloneModalProps}>
            <Form className="mt-20" layout="vertical">
              <h3>Do you want to create duplicate of the selected target?</h3>
            </Form>
          </LookModal>
          <LookDrawer {...drawerPropsUpdate}>
            {visibleDrawerUpdate && (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Edit Template",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <UpdateForm
                        productId={productId}
                        onClose={onCloseDrawerUpdate}
                        {...rowProps}
                        refetch={FbRefetch}
                        tiktokrefetch={RefetchTiktokTemplate}
                        platformsData={platformsData}
                        templatePlatforms={
                          platformsData?.templatePlatforms?.filter(
                            (item) => item?.name === templatePlatforms
                          )[0]?.id
                        }
                      />
                    ),
                  },
                ]}
              />
            )}
          </LookDrawer>
          <LookDrawer {...drawerPropsAdd}>
            {visibleDrawerAdd && (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add Template",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <AddForm
                        productId={productId}
                        onClose={onCloseDrawerAdd}
                        refetch={FbRefetch}
                        tiktokrefetch={RefetchTiktokTemplate}
                        platformsData={platformsData}
                        step={step}
                        setStep={setStep}
                        // templatePlatforms={
                        //   platformsData?.templatePlatforms?.filter(
                        //     (item) => item?.name === templatePlatforms
                        //   )[0]?.id
                        // }
                      />
                    ),
                  },
                ]}
              />
            )}
          </LookDrawer>
          <DashboardHeader title="Targeting Templates" {...createBtnProps} />
          <LookTable
            tableProps={tableProps}
            refetch={templatePlatforms === "facebook" ? FbRefetch : RefetchTiktokTemplate}
            loading={templatePlatforms === "facebook" ? FbLoading : LoadingTiktokTemplate}
            rightOptions={
              <div style={{ width: "150px" }}>
                <SelectInput
                  name="platforms"
                  value={
                    platformsData?.templatePlatforms?.filter(
                      (item) => item?.name === templatePlatforms
                    )[0]?.name
                  }
                  className="mb-0"
                  onChange={(e) => {
                    setTemplatePlatforms(e);
                  }}
                  options={platformsData?.templatePlatforms?.map(({ name, id }) => ({
                    name,
                    value: name,
                  }))}
                />
              </div>
            }
          />
        </>
      )}
    </div>
  );
}
