import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { useEffect, useState } from "react";
import DebugPre from "components/DebugPre";
import * as yup from "yup";
import { Button, Col, Row, Segmented, Skeleton } from "antd";
import cx from "cx";
import {
  CREATIVE_MIME_TYPE_IMAGE,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import {
  CREATIVE_BUILDER_FETCH_TEMPLATES,
  CREATIVE_BUILDER_UPLOAD_TEMPLATE,
  CT_DELETE_TEMPLATES,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import useUploadFiles from "components/Launch/Creatives/CreativeUpload/useUploadFiles";
import ActionIconRender from "../../../../../components/ActionIconRender";
import LookModal from "components/LookModal";
import TemplateForm from "./TemplateForm";
import MediaItem from "./MediaItem";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { useSelector } from "react-redux";
import UpdateCreativeBuilder from "./Update/UpdateCreativeBuilder";
import {
  STATUS_ASSIGNED,
  STATUS_ASSIGNED_CD,
  STATUS_CLOSED,
  STATUS_DONE,
  STATUS_DONE_CD,
  STATUS_IN_PROGRESS_GD,
  STATUS_NEEDS_CHANGES_CD,
  STATUS_NEEDS_CHANGES_GD,
  STATUS_SUBMITTED,
  STATUS_SUBMITTED_CD,
  STATUS_SUBMITTED_GD,
} from "../../creativesEnum";
import SelectInput from "components/forms/SelectInput";
import CreativesModal from "./Update/CreativesModal";
import { GET_BLOB_FOR_CREATIVES } from "shared/gql/gqlSchema/umsGql";
import LookButton from "components/LookButton";
export default function TemplateUpload(props) {
  const {
    categoryId = "",
    categoryName = "",
    productId,
    userLevel,
    status,
    requestData,
    isRequestView = false,
    isGraphicDesigner,
    isCreativeBuilder = false,
    onCloseDrawer = () => {},
    handleSubmit = () => {},
    setSelectedRowsData = () => {},
    isCreativeDirector,
    isMediaBuyer,
    isTemplateForm,
    visibleModal,
    setVisibleModal = () => {},
  } = props;
  const { ctGetRequestStatusConfig = {} } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const [mimeType, setMimeType] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [finalVariantsData, setFinalVariantsData] = useState([]);
  const [mutationData, setMutationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [allowedStatus, setAllowedStatus] = useState();
  const [createdbyMe, setCreatedByMe] = useState("All");
  const [creativeType, setCreativeType] = useState(CREATIVE_TYPE_IMAGE);
  const [templates, setTemplates] = useState([]);
  const [activeParentTab, setActiveParentTab] = useState("creative-builder");
  const [visible, setVisible] = useState(false);
  const [rowPropsData, setRowProps] = useState({});
  const [errors, setErrors] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [showVariantState, setShowVariantState] = useState(false);

  let activeParentTabTitle = "";
  if (activeParentTab === "creative-preview") activeParentTabTitle = "Creative Preview";
  const onChange = (value) => {
    setCreatedByMe(value);
  };
  const showDrawer = (rowProps = {}) => {
    const { templateType } = rowProps;
    setRowProps({
      ...rowProps,
      templateType:
        templateType && templateType.includes(".") ? templateType?.split(".")[1] : templateType,
    });
    setVisible(true);
  };
  const onClose = () => {
    setRowProps({});
    setVisible(false);
    setActiveParentTab("creative-builder");
    setFinalVariantsData([]);
  };
  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 4 }, () => 10));
  const [creativesImages, setCreativesImages] = useState([]);
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalVisible(true);
    setDeleteModalPropsData(rowProps);
  };
  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => {
        if (isRequestView) {
          onOpenDeleteModal(e?.map((img) => img?.id));
        } else {
          onOpenDeleteModal(e?.map((img) => img?.id));
        }
      },
    },
    {
      option: "download",
      title: "Download",
      onClick: (e) => {
        handleClickDownload(
          e?.map((data) => data?.s3Url),
          CREATIVE_MIME_TYPE_IMAGE
        );
      },
    },
  ];
  const onSubmitDeleteModal = () => {
    if (!isRequestView) {
      deleteCall({ variables: { templateIds: deleteModalPropsData } });
    } else {
      setSelectedRowsData((prevItems) => ({
        ...prevItems,
        requestData: {
          ...prevItems?.requestData,
          templateUrls: prevItems?.requestData?.templateUrls?.filter(
            (url, index) => !deleteModalPropsData?.includes(index + 1)
          ),
        },
      }));
      setDeleteModalVisible(false);
    }
  };
  function base64ToImageUrl(base64String) {
    return `data:${mimeType};base64,${base64String}`;
  }
  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to the HTML document
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
  const [getBlob, { loading: loading_blob }] = useCustomMutation(GET_BLOB_FOR_CREATIVES, {
    onCompleted: (e) => {
      const element = e?.getBlob;
      if (element) {
        for (let i = 0; i < element.length; i++) {
          const blob = element[i]?.blob;
          const imageUrl = base64ToImageUrl(blob, mimeType);
          downloadFile(
            imageUrl,
            `lookfinity_creatives.${mimeType === CREATIVE_MIME_TYPE_IMAGE ? "jpeg" : "mp4"}`
          );
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });
  async function handleClickDownload(urls, mimeType) {
    let linkData = [];
    for (const fileUrl of urls) {
      try {
        const response = await fetch(fileUrl);
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          downloadFile(
            url,
            `lookfinity_creatives.${mimeType === CREATIVE_MIME_TYPE_IMAGE ? "jpeg" : "mp4"}`
          );
        }
      } catch (error) {
        linkData.push(fileUrl);
      }
    }
    if (linkData?.length > 0) {
      setMimeType(mimeType);
      getBlob({ variables: { urls: linkData } });
    }
  }
  function handleChangeStatus(assignedStatus) {
    const config = ctGetRequestStatusConfig?.[userLevel];
    if (!config) {
      return null;
    }
    const { statuses, allowed_statuses } = config;
    const index = statuses.findIndex((status) => status?.assigned_status === assignedStatus);
    if (index === -1) {
      return null;
    }
    const allowedIndex = allowed_statuses
      .filter((allowed) => allowed?.from === index.toString())
      .map((item) => item?.to);
    allowedIndex.push(index);
    const resultingStatuses = allowedIndex.map((items) => statuses[items]);
    setAllowedStatus(resultingStatuses);
  }
  const requestStatus = requestData?.requestStatus;
  const splitResult =
    requestStatus && requestStatus.includes(".") ? requestStatus.split(".")[1] : requestStatus;

  useEffect(() => {
    handleChangeStatus(splitResult);
  }, []);

  useEffect(() => {
    if (activeParentTab === "temporary-creative") {
      setShowVariantState(true);
    } else {
      setShowVariantState(false);
    }
  }, [activeParentTab]);
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };
  const [callTemplates, { loading: templates_loading, refetch: templates_refetch }] =
    useCustomLazyQuery(CREATIVE_BUILDER_FETCH_TEMPLATES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ctGetCreativeTemplates?.data;
        if (element) {
          setTemplates(element);
          // setTotalCount(e?.clPagedImages?.pageInfo?.totalCount);
        }
      },
      // variables: { ...queryVars },
    });
  const [deleteCall, { loading: deleteLoader }] = useCustomMutation(CT_DELETE_TEMPLATES, {
    onCompleted(e) {
      const element = e?.ctDeleteTemplates;
      setDeleteModalVisible(false);
      if (element?.status === "SUCCESS") {
        openNotification({ type: "success", message: element?.message });
        selectedRowsClear();
        templates_refetch();
      } else {
        openNotification({
          type: "error",
          message: element?.message,
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Deleted Not Success",
      });
    },
  });
  const [ctAddTemplate, { loading: loading_Add, refetch: creativesTemplate_refetch }] =
    useCustomMutation(CREATIVE_BUILDER_UPLOAD_TEMPLATE, {
      onCompleted: (e) => {
        const element = e?.ctAddCreativeTemplates;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          if (isRequestView) {
            const templateIds = element?.data?.map((ele) => ele?.id);
            // setSelectedRowsData((prevItems) => ({ ...prevItems, templateIds: templateIds }));
            handleSubmit({ ...requestData, templateIds });
          } else {
            templates_refetch();
          }
          setVisibleModal(false);
          setCreativesImages([]);
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  useEffect(() => {
    if (!isRequestView) callTemplates();
  }, []);
  const [categories, setCategories] = useState([]);
  const debugContent = [{ isRow: true, contentObjects: [{ creativesImages }] }];
  const [selectedImages, setSelectedImages] = useState([]);
  function selectedRowsClear() {
    setSelectedImages([]);
  }
  function extractTemplateId(inputString) {
    const templateIdRegex = /"template_id"\s*:\s*"([^"]+)"/;
    const match = inputString.match(templateIdRegex);
    if (match && match[1]) {
      return match[1];
    } else {
      return "null";
    }
  }
  useEffect(() => {
    if (!isRequestView) callTemplates();
  }, []);
  const getImageResponse = (imagesData) => {
    setCreativesImages(imagesData);
  };
  function handleMedia() {
    const variables = {
      categoryId,
      categoryName,
      templateUrls: requestData?.templateUrls?.map((data, index) => {
        const { r2Url, ...rest } = data;
        return { url: r2Url, ...rest };
      }),
      productId,
    };
    ctAddTemplate({ variables });
  }
  const { uploadView, uploadLoading } = useUploadFiles({
    getImageResponse,
    creativeType,
    loading: loading_Add,
    setSelectedImages,
    setCreativesImages,
  });
  const finalTemplates = isRequestView
    ? requestData?.templateUrls?.map((data, index) => ({ ...data, id: index + 1 }))
    : templates;
  const handleSelectImage = (id) => {
    if (selectedImages?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedImages(selectedImages?.filter((img) => img?.id !== id));
      // data = selectedImages?.filter((img) => img?.id !== id);
    } else {
      setSelectedImages([
        ...selectedImages,
        {
          id: finalTemplates.filter((img) => img?.id === id)[0]?.id,
          s3Url: finalTemplates.filter((img) => img?.id === id)[0]?.r2Url,
        },
      ]);
      // data = [
      //   ...selectedImages,
      //   {
      //     id: creativesImages.filter((img) => img?.id === id)[0]?.id,
      //     s3Url: creativesImages.filter((img) => img?.id === id)[0]?.s3Url,
      //   },
      // ];
    }
  };
  const canEditStatus = [
    STATUS_SUBMITTED_GD,
    STATUS_ASSIGNED_CD,
    STATUS_IN_PROGRESS_GD,
    STATUS_NEEDS_CHANGES_GD,
    STATUS_NEEDS_CHANGES_CD,
  ];

  const getModifications = (data) => {
    const regex = /"modifications":\s*{[^}]+}/;
    const match = data?.match(regex);
    let newData;
    if (match) {
      const modificationsString = match[0];
      const modifications = JSON.parse(`{${modificationsString}}`);
      newData = modifications;
    }
    return JSON.stringify(newData?.modifications);
  };
  const onSubmitAddTemplate = (values, { resetForm }) => {
    if (creativesImages?.length > 0) {
      const templates = creativesImages?.map((data, index) => ({
        r2Url: creativeType === CREATIVE_TYPE_IMAGE ? data?.public_url : data?.videoUrl,
        templateId: extractTemplateId(values?.api),
        templateType: values?.creativeType,
        name: values?.templateName,
        templateThumb: data?.thumbUrl,
        templateDetails: {
          api: values?.api,
          sourceCode: values?.sourceCode,
          modifications: getModifications(values?.api),
        },
      }));
      if (isRequestView) {
        setSelectedRowsData((prevItems) => ({
          ...prevItems,
          requestData: {
            ...prevItems?.requestData,
            templateUrls: [
              ...(prevItems?.requestData?.templateUrls ? prevItems?.requestData?.templateUrls : []),
              ...templates,
            ],
          },
        }));
        onCloseAddTemplate();
      } else {
        ctAddTemplate({
          variables: {
            createdByMe: createdbyMe === "My Images", // Fix variable name here
            ...values,
            ...(!values?.categoryId && { categoryId: "00" }),
            ...(!values?.categoryName && { categoryName: "generic" }),
            ...(!values?.productId && { productId: "00" }),
            templateUrls: creativesImages?.map((data, index) => {
              const template = {
                url:
                  values?.creativeType === CREATIVE_TYPE_IMAGE ? data?.public_url : data?.videoUrl,
                templateId: extractTemplateId(values?.api),
                templateType: values?.creativeType,
                name: values?.templateName,
                templateDetails: {
                  api: values?.api,
                  sourceCode: values?.sourceCode,
                  modifications: getModifications(values?.api),
                },
              };
              return {
                ...template,
                ...(values?.creativeType === CREATIVE_TYPE_VIDEO && {
                  templateThumb: data?.thumbUrl,
                }),
              };
            }),
          },
        });
      }
    } else {
      setErrors("Please add creatives");
    }
  };
  const onCloseAddTemplate = () => {
    setVisibleModal(false);
    setCreativesImages([]);
    setCategories([]);
  };
  const bulkActionProps = {
    selectedRows: selectedImages,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const deleteModalProps = {
    title: "Delete Templates",
    message: "Do you really want to delete this templates?",
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
  };
  const modalProps = {
    title: "Add Creatives",
    visible: visibleModal,
    footer: false,
    onCancel: onCloseAddTemplate,
  };
  const initialValues = {
    product: "",
    advertiser: "",
    categoryId,
    categoryName,
    templateName: "",
    api: "",
    creativeType: "",
    sourceCode: "",
    description: "",
  };
  //name: yup.string().trim().required().label("Name")
  const validationSchema = yup.object({
    // advertiser: yup.string().required().label("Advertiser"),
    templateName: yup.string().required().label("Template Name"),
    // categoryId: yup.string().required().label("Category"),
    creativeType: yup.string().required().label("Creative Type"),
    api: yup.string().required().label("API"),
    sourceCode: yup.string().required().label("Source Code"),
  });

  const formProps = {
    initialValues,
    onSubmit: onSubmitAddTemplate,
    uploadView,
    uploadLoading,
    creativeType,
    setCreativeType,
    handleSelectImage,
    creativesImages,
    onCloseAddTemplate,
    setCreativesImages,
    categories,
    setCategories,
    isRequestView,
    loading_Add,
    validationSchema,
    errors,
  };

  // xs={2} sm={4} md={6} lg={8} xl={10}
  const gridColumnSizes = isRequestView
    ? { xs: 24, md: 24, lg: 12, xl: 12 }
    : { xs: 24, md: 12, lg: 8, xl: 6 };

  function handleDisableBtn() {
    if (isCreativeDirector) {
      return (
        splitResult === STATUS_DONE_CD ||
        splitResult === STATUS_CLOSED ||
        status?.toUpperCase() === STATUS_ASSIGNED
      );
    } else if (isGraphicDesigner) {
      return (
        splitResult === STATUS_DONE_CD ||
        splitResult === STATUS_DONE ||
        splitResult === STATUS_CLOSED ||
        status?.toUpperCase() === STATUS_SUBMITTED
      );
    } else if (isMediaBuyer) {
      return splitResult === STATUS_CLOSED;
    } else {
      return false;
    }
  }
  return (
    <>
      {visibleModal && (
        <LookModal {...modalProps}>
          <TemplateForm {...formProps} />
        </LookModal>
      )}
      <LookTableBulkAction {...bulkActionProps} label="Selected Images" />
      <div className={cx("creative-builder-media-list", { isRequestView })}>
        <div
          className={`d-flex p-20`}
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          {isRequestView ? (
            <div style={{ width: "130px" }}>
              <SelectInput
                name="status"
                options={
                  allowedStatus?.map((status, index) => ({
                    value: status?.assigned_status,
                    name: status?.visible_status,
                  })) || []
                }
                value={splitResult}
                onChange={(e) =>
                  setSelectedRowsData((prevItems) => ({
                    ...prevItems,
                    requestData: {
                      ...prevItems.requestData,
                      requestStatus: e,
                    },
                  }))
                }
              />
            </div>
          ) : (
            <Segmented
              className="custom-segmented"
              options={["All", "My Templates"]}
              value={createdbyMe}
              onChange={onChange}
            />
          )}
          <LookButton
            tooltipTitle="Refetch"
            className="filter-btn ml-8"
            size="small"
            iconType="reload"
            disabled={templates_loading}
            iconTypeProps={{ spin: templates_loading }}
            onClick={() => templates_refetch()}
          />
        </div>
        <div className={cx("content-view", { request: true })}>
          <Row
            gutter={[15, 15]}
            className={cx("creative-builder-grid-listing", {
              normal: !isRequestView,
              isRequestView,
            })}
          >
            {isGraphicDesigner && canEditStatus?.includes(splitResult) && !isCreativeBuilder && (
              <Col {...gridColumnSizes}>
                <div
                  className="w-100 h-100"
                  style={{
                    borderRadius: "6px",
                    border: "2px dashed #404040",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setVisibleModal(true)}
                >
                  <div>
                    <div
                      className="text-center"
                      style={{
                        color: "#ACACAC",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <ActionIconRender iconType="cloudupload" style={{ fontSize: "52px" }} />
                      <h3>Upload Templates</h3>
                    </div>
                  </div>
                  <p>Supported Platform : Creatomate </p>
                </div>
              </Col>
            )}

            {(finalTemplates?.length === 0 || finalTemplates === undefined) &&
            (!isGraphicDesigner || !isRequestView) &&
            !loading_Add &&
            !templates_loading ? (
              <div className="text-center pt-100" style={{ marginLeft: "220px" }}>
                <div style={{ transform: "translateX(20px)" }}>
                  <ActionIconRender iconType="tablePlaceholder" />
                </div>
                <p style={{ fontSize: "1.5rem" }}>
                  <b>No Creatives Found...</b>
                </p>
              </div>
            ) : (
              finalTemplates?.map((data, index) => {
                const selectedImg = selectedImages?.filter((img) => img.id === data?.id);
                return (
                  <Col {...gridColumnSizes}>
                    <MediaItem
                      {...data}
                      {...props}
                      handleClickDownload={handleClickDownload}
                      onOpenDeleteModal={onOpenDeleteModal}
                      canEditStatus={canEditStatus?.includes(splitResult) && isGraphicDesigner}
                      isCreativeBuilder
                      creativeType={
                        isRequestView ? data?.templateType : data?.templateType?.split(".")[1]
                      }
                      creative={{
                        id: data?.id,
                        thumbUrl: data?.templateThumb,
                        url: data?.r2Url,
                      }}
                      showDrawer={showDrawer}
                      // onClick={() => {
                      //   handleSelectImage();
                      // }}
                      selected={selectedImg.length > 0}
                      selectedDataId={data?.id}
                      handleSelectImage={handleSelectImage}
                    />
                  </Col>
                );
              })
            )}
            {templates_loading && (
              <>
                {skeletonSize?.map((e) => (
                  <Col span={6}>
                    <div className={cx("creative-image-selection ant-image-ration-1-1")}>
                      <Skeleton.Image
                        className="w-100"
                        style={{ width: "100%", height: "235px" }}
                        active={true}
                      />
                    </div>
                  </Col>
                ))}
              </>
            )}

            <LookTableRecordDeleteModal {...deleteModalProps} />
          </Row>
        </div>
        {isRequestView && status !== STATUS_CLOSED && (
          <div className="drawer-footer-btn-content">
            <div className="flex-grow-1">
              <Row gutter={12}>
                <Col span={12}>
                  <Button
                    htmlType="cancel"
                    block
                    type="default"
                    className="submit-btn"
                    onClick={onCloseDrawer}
                    // disabled={loading}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    htmlType="submit"
                    block
                    type="primary"
                    className="submit-btn"
                    onClick={() =>
                      splitResult === STATUS_SUBMITTED_CD
                        ? handleMedia()
                        : handleSubmit(requestData)
                    }
                    disabled={handleDisableBtn() || loading_Add}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <DebugPre content={debugContent} />
      </div>
      {/* <div className="p-8 look-bottom-pagination">
          <Pagination
            defaultCurrent={1}
            total={totalCount}
            pageSize={pageSize}
            current={currentPage}
            onChange={(page, size) => handlePageChange(page, size)}
            pageSizeOptions={["10", "20", "50"]}
            showSizeChanger
          />
        </div>  */}
      <LookDrawer size={1081} visible={visible} onClose={onClose} title={"Creative Template"}>
        {visible && (
          <LookDrawerMainSideBar
            value={activeParentTab}
            onChange={setActiveParentTab}
            tabContent={[
              {
                id: "creative-builder",
                noSpace: true,
                hoverTitle: "Creative Builder",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: (
                  <UpdateCreativeBuilder
                    {...props}
                    {...rowPropsData}
                    onClose={onClose}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                    mutationData={mutationData}
                    setMutationData={setMutationData}
                    setActiveParentTab={setActiveParentTab}
                    setFinalVariantsData={setFinalVariantsData}
                    finalVariantsData={finalVariantsData}
                    setShowVariantState={setShowVariantState}
                  />
                ),
              },
              {
                id: "creative-preview",
                noSpace: true,
                hoverTitle: "Saved Creatives",
                icon: () => <ActionIconRender iconType="image" />,
                component: (
                  <CreativesModal
                    isPreview
                    {...rowPropsData}
                    mutationData={mutationData}
                    setMutationData={setMutationData}
                    setFinalVariantsData={setFinalVariantsData}
                    finalVariantsData={finalVariantsData}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                  />
                ),
              },
              {
                id: "temporary-creative",
                noSpace: true,
                hoverTitle: "In Progress Creatives",
                icon: () => <ActionIconRender iconType="approval" />,
                component: (
                  <CreativesModal
                    {...rowPropsData}
                    showVariants={showVariantState}
                    mutationData={mutationData}
                    setMutationData={setMutationData}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                    setActiveParentTab={setActiveParentTab}
                  />
                ),
              },
            ]}
          />
        )}
      </LookDrawer>
    </>
  );
}
