import { VOLUUM_VIEW } from "shared/UserPermission/userPermissionList";
import LookTabRouting from "components/LookTabRouting";
import ActionIconRender from "components/ActionIconRender";
import Facebook from "./Facebook";
import Tiktok from "./Tiktok";

export default function FacebookReports() {
  const tabContent = [
    {
      link: "/facebook",
      route: "/facebook",
      title: "Facebook",
      component: Facebook,
      icon: () => <ActionIconRender iconType="user" />,
      permission: [VOLUUM_VIEW],
    },
    {
      link: "/tiktok",
      route: "/tiktok",
      title: "Tiktok",
      component: Tiktok,
      icon: () => <ActionIconRender iconType="user" />,
      permission: [VOLUUM_VIEW],
    },
  ];

  return (
    <div>
      <LookTabRouting dashboardHeader={{ title: "Video Analysis" }} tabs={tabContent} />
    </div>
  );
}
