import { useCustomQuery } from "hooks/apolloClientHooks";
import { useState } from "react";
import {
  GET_VOLUUM_ADACCOUNT_HOURLY_REPORT,
  GET_VOLUUM_ADSET_BY_CAMPAIGN_REPORT,
  GET_VOLUUM_ADSET_HOURLY_REPORT,
  GET_VOLUUM_AD_BY_ADSET_REPORT,
  GET_VOLUUM_AD_BY_CAMPAIGN_REPORT,
  GET_VOLUUM_AD_HOURLY_REPORT,
  GET_VOLUUM_CAMPAIGN_HOURLY_REPORT,
  GET_VOLUUM_REPORT_BY_GROUP,
} from "shared/gql/gqlSchema/tiktok";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
} from "shared/SharedKeys";

export const TIKTOK_VOLUUM_REPORT_QUERY = "Voluum";
export const SAS_REPORT_QUERY_PERFORMANCE = "Performance";

function groupBy(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return { type: REPORT_TYPE_AD_ACCOUNT };
    case REPORT_TYPE_AD:
      return { type: REPORT_TYPE_AD };
    case REPORT_TYPE_AD_ADSET:
      return { type: REPORT_TYPE_AD_ADSET };
    case REPORT_TYPE_CAMPAIGN:
      return { type: REPORT_TYPE_CAMPAIGN };
    default: {
      return {};
    }
  }
}

function performanceQueryType(type, assetId, platform_id) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return {
        query: GET_VOLUUM_ADACCOUNT_HOURLY_REPORT,
        element: "voluum_adAccountHourlyReport",
        variables: {
          adaccount_ids: assetId,
          platform_id,
        },
      };
    case REPORT_TYPE_AD:
      return {
        query: GET_VOLUUM_AD_HOURLY_REPORT,
        element: "voluum_adHourlyReport",
        variables: {
          ad_ids: assetId,
          platform_id,
        },
      };
    case REPORT_TYPE_AD_ADSET:
      return {
        query: GET_VOLUUM_ADSET_HOURLY_REPORT,
        element: "voluum_add_setHourlyReport",
        variables: {
          adset_ids: assetId,
          platform_id,
        },
      };
    case REPORT_TYPE_CAMPAIGN:
      return {
        query: GET_VOLUUM_CAMPAIGN_HOURLY_REPORT,
        element: "voluum_campaignHourlyReport",
        variables: {
          campaign_ids: assetId,
          platform_id,
        },
      };

    default: {
      return {};
    }
  }
}

function campaignQueryType(type, platform_id) {
  switch (type) {
    case REPORT_TYPE_AD:
      return {
        query: GET_VOLUUM_AD_BY_CAMPAIGN_REPORT,
        element: "voluum_get_ad_by_campaign",
        variables: {
          platform_id,
        },
      };

    case REPORT_TYPE_AD_ADSET:
      return {
        query: GET_VOLUUM_ADSET_BY_CAMPAIGN_REPORT,
        element: "voluum_get_adset_by_campaign",
        variables: {
          platform_id,
        },
      };

    default: {
      return {};
    }
  }
}

export default function useLeadgenReportsQuery(type, extraParams = {}) {
  const {
    variables,
    from,
    to,
    timezone,
    isPerformanceHourly = false,
    isCampaignPerformanceReport = false,
    isAdsetPerformanceReport = false,
    platform_id,
  } = extraParams;
  const [revenueReport, setRevenueReport] = useState([]);

  let queryObject = {
    query: GET_VOLUUM_REPORT_BY_GROUP,
    element: "voluum_revenue_by_group",
    variables: {
      platform_id,
    },
  };

  if (isPerformanceHourly) {
    queryObject = performanceQueryType(type, variables?.ids, platform_id);
  }

  if (isCampaignPerformanceReport) {
    queryObject = campaignQueryType(type, platform_id);
  }

  if (isAdsetPerformanceReport) {
    queryObject = {
      query: GET_VOLUUM_AD_BY_ADSET_REPORT,
      element: "voluum_get_ad_by_ad_set",
      variables: {
        platform_id,
      },
    };
  }

  const { loading: loading_revenue, refetch } = useCustomQuery(queryObject?.query, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const reportElement = e?.[queryObject?.element];

      if (reportElement) {
        setRevenueReport(
          reportElement?.map((d) => ({
            ...d,
            campaignId: d?.campaign_id,
            adsetId: d?.ad_set_id,
            adId: d?.ad_id,
            adAccountId: d?.ad_account_id,
          }))
        );
      }
    },

    variables: {
      from,
      to,
      timezone,
      ...groupBy(type),
      ...variables,
      ...queryObject?.variables,
    },
  });

  const refetch_revenue = () => {
    setRevenueReport([]);
    refetch();
  };

  return {
    loading_revenue,
    refetch_revenue,
    revenueReport,
    setRevenueReport,
  };
}
