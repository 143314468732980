import { Row, Col, Radio, Slider, Checkbox, Button, Form } from "antd";
import cx from "classnames";
import SelectInput from "components/forms/SelectInput";
import LookTable from "components/LookTable";
import { useLaunchContext } from "../Context";
import { useLaunchDispatchHooks } from "hooks/dispatchHooks";
import { useSelector } from "react-redux";
import DebugPre from "components/DebugPre";
import {
  LAUNCH_TARGETING_STATE_AGE,
  LAUNCH_TARGETING_STATE_COUNTRY,
  LAUNCH_TARGETING_STATE_CTA,
  LAUNCH_TARGETING_STATE_GENDER,
  LAUNCH_TARGETING_STATE_LANGUAGE,
  LAUNCH_TARGETING_STATE_OPTIMIZATION,
  LAUNCH_TARGETING_STATE_PLACEMENT_ADD_AND_SITES,
  LAUNCH_TARGETING_STATE_PLACEMENT_FEEDS,
  LAUNCH_TARGETING_STATE_PLACEMENT_STORIES_AND_REELS,
  LAUNCH_TARGETING_STATE_PLATFORM,
} from "../LaunchKeys";
import PlacemetFields from "./PlacemetFields";

export default function LaunchTargeting() {
  const { launchType } = useLaunchContext();
  const { targeting = {}, setTargeting } = useLaunchDispatchHooks(launchType);

  const { userSettingData } = useSelector((state) => state?.userSettings) || {};
  const {
    targetingCountries = [],
    targetingCountries_loading = false,
    launchConfig,
    launchConfig_loading,
  } = userSettingData || {};

  function gender() {
    const genderList = [
      { name: "All", value: "all" },
      { name: "Male", value: 1 },
      { name: "Female", value: 2 },
    ];
    return (
      <div className="mb-10">
        <p>GENDER</p>
        <Radio.Group
          //  name={name}
          value={
            targeting[LAUNCH_TARGETING_STATE_GENDER]
              ? targeting[LAUNCH_TARGETING_STATE_GENDER]
              : undefined
          }
          onChange={(e, v) => {
            console.log({ e, v });
            setTargeting(LAUNCH_TARGETING_STATE_GENDER, e?.target?.value);
          }}
        >
          {genderList.map(({ value, name }) => (
            <Radio value={value}>{name}</Radio>
          ))}
        </Radio.Group>
      </div>
    );
  }
  function devices() {
    return (
      <div className="mb-10">
        <p>DEVICES</p>
        <Checkbox.Group
          value={targeting?.device}
          options={[
            { label: "Desktop", value: "DESKTOP" },
            { label: "Mobile", value: "MOBILE" },
          ]}
          onChange={(e) => setTargeting("device", e)}
        />

        {/* <Checkbox
          checked={targeting?.device === "DESKTOP"}
          onClick={() => setTargeting("device", "DESKTOP")}
        >
          <ActionIconRender iconType="desktop" className="mr-8" />
          Desktop
        </Checkbox>
        <Checkbox
          checked={targeting?.device === "MOBILE"}
          onClick={() => setTargeting("device", "MOBILE")}
        >
          <ActionIconRender iconType="mobile" className="mr-8" />
          Mobile
        </Checkbox> */}
      </div>
    );
  }

  function platforms() {
    const platformList = [
      { label: "Instagram", value: "INSTAGRAM" },
      { label: "Facebook", value: "FACEBOOK" },
      { label: "Messenger", value: "MESSANGER" },
      { label: "Audience Network", value: "AUDIENCE-NETWORK" },
    ];
    return (
      <div className="mb-10">
        <p>PLATFORM</p>
        <Checkbox.Group
          options={platformList}
          value={
            targeting[LAUNCH_TARGETING_STATE_PLATFORM]
              ? targeting[LAUNCH_TARGETING_STATE_PLATFORM]
              : undefined
          }
          onChange={(e) => setTargeting(LAUNCH_TARGETING_STATE_PLATFORM, e)}
        />
      </div>
    );
  }

  function placements() {
    const DESTINATION_KEY_FACEBOOK_POSITION = "facebook_positions";
    const DESTINAATION_KEY_INSTAGRAM_POSITION = "instagram_positions";
    const DESTINAATION_KEY_AUDIENCE_NETWORK_POSITIONS = "audience_network_positions";
    const DESTINAATION_KEY_MESSENGER_POSITIONS = "messenger_positions";

    const feeds = [
      { name: "Facebook Feed", value: "feed", destinationKey: DESTINATION_KEY_FACEBOOK_POSITION },
      { name: "Instagram feed", value: "ig-feed", destinationValue: "feed" },
      {
        name: "Facebook Marketplace",
        value: "marketplace",
        destinationKey: DESTINATION_KEY_FACEBOOK_POSITION,
      },
      { name: "Facebook video feeds", value: "video_feeds" },
      {
        name: "Facebook right column",
        value: "right_hand_column",
        destinationKey: DESTINATION_KEY_FACEBOOK_POSITION,
      },
      {
        name: "Instagram Explore",
        value: "explore",
        destinationKey: DESTINAATION_KEY_INSTAGRAM_POSITION,
      },
      {
        name: "Instagram Shop",
        value: "shop",
        destinationKey: DESTINAATION_KEY_INSTAGRAM_POSITION,
      },
      { name: "Messenger inbox", value: "" },
      { name: "Facebook groups feed", value: "" },
      { name: "Facebook Business Explore", value: "" },
    ];

    // publisher_platforms: facebook, instagram, messenger, audience_network

    // facebook_positions: feed, right_hand_column, instant_article, marketplace, video_feeds, story, search, instream_video

    // instagram_positions: stream, story, shop, explore, reels

    // audience_network_positions: classic, rewarded_video

    // messenger_positions: messenger_home, sponsored_messages, story

    const storiesAndReals = [
      {
        name: "Facebook Stories",
        value: "story",
        destinationKey: DESTINATION_KEY_FACEBOOK_POSITION,
      },
      {
        name: "Messenger Stories",
        value: "ms-story",
        destinationValue: "story",
        destinationKey: DESTINAATION_KEY_MESSENGER_POSITIONS,
      },
      {
        name: "Instagram Reels",
        value: "ig-reels",
        destinationValue: "reels",
        destinationKey: DESTINAATION_KEY_INSTAGRAM_POSITION,
      },
      { name: "Facebook Reels", value: "reels", destinationKey: DESTINATION_KEY_FACEBOOK_POSITION },
    ];

    const appsAndSites = [
      { name: "Audience Network native, banner and interstitial", value: "" },
      { name: "Audience Network rewarded videos", value: "" },
      { name: "Audience Network in-stream videos", value: "" },
    ];

    return (
      <div className="mb-10">
        <p>PLACEMENTS</p>
        {/* targeting = {}, setTargeting */}
        <Row>
          <Col span={8}>
            <PlacemetFields
              titleField="Feed"
              placementKey={LAUNCH_TARGETING_STATE_PLACEMENT_FEEDS}
              optionData={feeds}
            />
            {/* <div className={cx({ "pl-24": false })}>
              <Checkbox>
                <strong>Feed</strong>
              </Checkbox>
            </div>
            <Checkbox.Group
              options={feeds.map(({ name, value, ...rest }) => ({ label: name, value, ...rest }))}
              onChange={(e, b) => {
                console.log({ e, b });
              }}
            /> */}
          </Col>
          <Col span={8}>
            <PlacemetFields
              titleField="Stories and Reels"
              placementKey={LAUNCH_TARGETING_STATE_PLACEMENT_STORIES_AND_REELS}
              optionData={storiesAndReals}
            />
            <PlacemetFields
              titleField="Apps and Sites"
              placementKey={LAUNCH_TARGETING_STATE_PLACEMENT_ADD_AND_SITES}
              optionData={appsAndSites}
            />
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
    );
  }

  function countries() {
    return (
      <div className="mb-10">
        <p>COUNTRY</p>
        <SelectInput
          className="mb-0"
          multiple
          search
          placeholder="Select Countries"
          value={
            targeting[LAUNCH_TARGETING_STATE_COUNTRY]
              ? targeting[LAUNCH_TARGETING_STATE_COUNTRY]
              : undefined
          }
          onChange={(e) => setTargeting(LAUNCH_TARGETING_STATE_COUNTRY, e)}
          options={targetingCountries?.map(({ country_code, name }) => ({
            name,
            value: country_code,
          }))}
          loader={targetingCountries?.length === 0 && targetingCountries_loading}
        />
      </div>
    );
  }

  function language() {
    const languageList = [
      { id: 1, name: "Urdu" },
      { id: 2, name: "Hindi" },
      { id: 3, name: "Arabic" },
      { id: 4, name: "English" },
      { id: 5, name: "French" },
    ];
    return (
      <div className="mb-10">
        <SelectInput
          className="mb-0"
          label="LANGUAGE"
          multiple
          search
          placeholder="Select language"
          value={
            targeting[LAUNCH_TARGETING_STATE_LANGUAGE]
              ? targeting[LAUNCH_TARGETING_STATE_LANGUAGE]
              : undefined
          }
          onChange={(e) => setTargeting(LAUNCH_TARGETING_STATE_LANGUAGE, e)}
          options={languageList?.map(({ id, name }) => ({ name: name, value: id }))}
        />
      </div>
    );
  }

  function optimization() {
    return (
      <div className="mb-10">
        <SelectInput
          className="mb-0"
          label="OPTIMIZATION"
          search
          placeholder="Select a optimization"
          value={
            targeting[LAUNCH_TARGETING_STATE_OPTIMIZATION]
              ? targeting[LAUNCH_TARGETING_STATE_OPTIMIZATION]
              : undefined
          }
          onChange={(e) => setTargeting(LAUNCH_TARGETING_STATE_OPTIMIZATION, e)}
          options={launchConfig?.ad_set?.optimization_goal?.map(({ name, value }) => ({
            name,
            value,
          }))}
        />
      </div>
    );
  }

  function attribution() {
    const attributionList = [];
    return (
      <div className="mb-10">
        <SelectInput
          className="mb-0"
          label="ATTRIBUTION"
          search
          placeholder="Select a attribution"
          options={attributionList?.map(({ id, name }) => ({ name: name, value: id }))}
        />
      </div>
    );
  }

  function cta() {
    return (
      <div className="mb-10">
        <SelectInput
          className="mb-0"
          label="CTA"
          search
          value={
            targeting[LAUNCH_TARGETING_STATE_CTA]
              ? targeting[LAUNCH_TARGETING_STATE_CTA]
              : undefined
          }
          onChange={(e) => setTargeting(LAUNCH_TARGETING_STATE_CTA, e)}
          placeholder="Select CTA"
          options={launchConfig?.ad?.call_to_action?.map(({ name, value }) => ({ name, value }))}
        />
      </div>
    );
  }

  //Table start

  const renderData = [
    {
      id: 1,
      name: "Priyansh",
      objective: 1,
      special_ad: 1,
      adset: "Adset",
      ctype: "CType",
      budget: "Budget",
      btype: "Btype",
      bidStrategy: 1,
    },
  ];

  const initialColumns = [
    { title: "Name", dataIndex: "name", key: "name", width: 124, minWidth: 124 },
    { title: "Ojective", dataIndex: "objective", key: "objective", width: 124, minWidth: 124 },
    { title: "Special Ad", dataIndex: "special_ad", key: "special_ad", width: 124, minWidth: 124 },
    { title: "Ad set", dataIndex: "adset", key: "adset", width: 124, minWidth: 124 },
    { title: "C-type", dataIndex: "ctype", key: "ctype", width: 124, minWidth: 124 },
    { title: "Budget", dataIndex: "budget", key: "budget", width: 124, minWidth: 124 },
    { title: "B-type", dataIndex: "btype", key: "btype", width: 124, minWidth: 124 },
    {
      title: "Bid startegy",
      dataIndex: "bidStrategy",
      key: "bidStrategy",
      width: 124,
      minWidth: 124,
    },
  ];

  const tableProps = {
    initialColumns,
    recordData: renderData,
  };
  const debugContent = [{ isRow: true, contentObjects: [{ targetingCountries }] }];
  return (
    <div>
      <Form layout="vertical">
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Row gutter={[12, 12]}>
              <Col span={10}>{gender()}</Col>
              <Col span={14}>
                <div>
                  <p>AGE</p>
                  <Slider
                    onChange={(e) => {
                      setTargeting(LAUNCH_TARGETING_STATE_AGE, e);
                    }}
                    range
                    value={
                      targeting[LAUNCH_TARGETING_STATE_AGE]
                        ? targeting[LAUNCH_TARGETING_STATE_AGE]
                        : undefined
                    }
                    defaultValue={[20, 50]}
                  />
                </div>
              </Col>
              <Col span={10}>{devices()}</Col>
              <Col span={14}>{platforms()} </Col>
              <Col span={24}>{placements()}</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[12, 12]}>
              <Col span={24}>{countries()}</Col>
              <Col span={24}>{language()}</Col>
              <Col span={12}>{optimization()}</Col>
              <Col span={12}>{attribution()}</Col>
              <Col span={24}>{cta()}</Col>
            </Row>
          </Col>
        </Row>
        <Button type="primary">Reset</Button>
        <Button type="primary">Save & Next</Button>
      </Form>
      <LookTable tableProps={tableProps} scrollHeight="calc(100vh - 211px)" />
      <DebugPre content={debugContent} />
    </div>
  );
}
