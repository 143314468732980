import { useCustomMutation } from "hooks/apolloClientHooks";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { UPDATE_FB_ASSEST_STATUS } from "shared/gql/gqlSchema/f360Gql";
import { openNotification } from "utils";

export const UPDATE_SPEND_STATUS_TYPE_ACTIVE = "ACTIVE";
export const UPDATE_SPEND_STATUS_TYPE_PAUSED = "PAUSED";

export const UPDATE_SPEND_STATUS_NODE_CAMPAIGN = "CAMPAIGN";
export const UPDATE_SPEND_STATUS_NODE_ADSET = "ADSET";
export const UPDATE_SPEND_STATUS_NODE_AD = "AD";

//!* Make sure to get "status" and main id ("adId" / "campaignId" / "adsetId") out of nesting in spend report

export function useFbActivePauseState(
  node,
  { fbStatusCallBack = () => {}, setSpendReport = () => {}, lengthCheck = 15 }
) {
  const { selectedRowsClear } = useLookTableParentContext();
  function updateReport(e) {
    setSpendReport((pre) => {
      const temp = JSON.parse(JSON.stringify(pre)).map((tData) => {
        const newDataIndex = e?.findIndex(({ assetId }) => {
          if (node === UPDATE_SPEND_STATUS_NODE_CAMPAIGN) {
            return assetId === tData?.campaignId;
          } else if (node === UPDATE_SPEND_STATUS_NODE_ADSET) {
            return assetId === tData?.adsetId;
          } else if (node === UPDATE_SPEND_STATUS_NODE_AD) {
            return assetId === tData?.adId;
          }
          return false;
        });

        const isNewStatus = newDataIndex !== -1 ? { status: e[newDataIndex]?.status } : {};

        return { ...tData, ...isNewStatus };
      });

      return temp;
    });
  }

  const [updateFbAssetStatus, { loading }] = useCustomMutation(UPDATE_FB_ASSEST_STATUS, {
    onCompleted(e) {
      const element = e?.updateFbAssetStatus;
      if (element) {
        fbStatusCallBack(element);
        updateReport(element);
        selectedRowsClear(); //* This will remove selected rows from bulk selection
      }
      openNotification({
        // type: element?.success ? "success" : "error",
        key: "lg-active-pause-status",
        type: "success",
        className: "lg-active-pause-status",
        message: element?.map(({ assetId, status }) => (
          <div>
            <p className="id">{assetId}</p>
            <p className="status">
              <b>Status:</b> {status}
            </p>
          </div>
        )),
      });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function callFbAssetStateSubmit(status, selectedData = []) {
    let ids = [];
    selectedData?.forEach(({ campaignId, adsetId, adId }) => {
      if (node === UPDATE_SPEND_STATUS_NODE_CAMPAIGN) {
        campaignId && ids.push(campaignId);
      } else if (node === UPDATE_SPEND_STATUS_NODE_ADSET) {
        adsetId && ids.push(adsetId);
      } else if (node === UPDATE_SPEND_STATUS_NODE_AD) {
        adId && ids.push(adId);
      }
    });
    if (ids.length > lengthCheck) {
      return openNotification({ type: "warning", message: "Please select max 15 records" });
    }

    const payload = { fbIds: ids, status, node };

    updateFbAssetStatus({ variables: payload });
  }

  return { callFbAssetStateApi: updateFbAssetStatus, callFbAssetStateSubmit, loading };
}
