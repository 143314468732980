import * as types from "../actions/actionTypes";

export default function iCampaignsReducer(state = {}, action) {
  switch (action.type) {
    case types.I_CAMPAIGNS_LAUNCH_AD_ACCOUNT_SELECTION:
      return { ...state, adAccount: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_RESPONSE_ID:
      return { ...state, responseIds: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_CAMPAIGN_STATES:
      return { ...state, campaignStates: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_AD_SET_STATES:
      return { ...state, adSetStates: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_LINK_DATA_STATES:
      return { ...state, linkData: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_TARGETING_STATES:
      return { ...state, targetingStates: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_CREATIVE_STATES:
      return { ...state, creativeStates: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_AD_STATES:
      return { ...state, adStates: action.payload };

    case types.I_CAMPAIGNS_LAUNCH_CREATIVE_IMAGES:
      return { ...state, creativeImages: action.payload };

    default:
      return state;
  }
}
