import { Button, Col, Modal, Row } from "antd";
import { useLaunchContext } from "components/Launch/Context";
import LookButton from "components/LookButton";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS } from "shared/SharedKeys";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import React from "react";
import LaunchCampaignView from "./LaunchCampaignView";
import LaunchView from "./LaunchView";

function LaunchModal(props) {
  const { isModalVisible, handleCancel = () => {}, handleOk = () => {}, url, formData } = props;

  const {
    submitLaunch,
    generateCampaignObject,
    productId,
    sasLaunch_loading,
    tiktokLaunch_loading = false,
    visibleModal,
    setVisibleModal,
    generateTiktokCampaignObject,
    isTiktokPlatformLaunch,
    instantLaunch,
    submission,
    submitTiktokLaunch,
  } = useLaunchContext();
  const { campCounter = "1" } = submission;
  const isF360AdAccountUser = !isTiktokPlatformLaunch;

  // const [addTrackerUrl, { loading: ADD_TRACKING_URL_LOADING }] = useCustomMutation(
  //   LAUNCH_ADD_TRACKER_URL,
  //   {
  //     variables: {
  //       ad_account: submission?.finalSubmissions[0]?.accountId,
  //       urls: url?.map((item) => ({
  //         landing_url: item?.name,
  //         tracker_url: item?.links,
  //       })),
  //     },
  //   }
  // );

  const showModal = () => {
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  const campsData = isTiktokPlatformLaunch
    ? generateTiktokCampaignObject(
        submission?.finalSubmissions?.advertiserId,
        submission?.pixel,
        submission?.finalSubmissions?.identityId,
        submission?.finalSubmissions?.identityType,

        parseInt(campCounter)
      )
    : formData?.flatMap?.((data) =>
        data?.adAccount?.flatMap((item) =>
          item?.pixelValue?.length > 0
            ? item?.pixelValue.map((pixelValue) =>
                generateCampaignObject(
                  submission?.alias,
                  item?.pageId,

                  productId === PRODUCT_ID_SAS ? item?.pixel[0] : pixelValue,
                  item?.epcKey,
                  parseInt(campCounter),
                  productId === PRODUCT_ID_LEADGEN ? item?.url : item?.tracking_url,
                  item?.beneficiary,
                  item?.payor,
                  item?.campaignStatus,
                  item?.alias,
                  item?.domainNickName
                )
              )
            : generateCampaignObject(
                submission?.alias,
                item?.pageId,

                productId === PRODUCT_ID_SAS ? item?.pixel[0] : item?.pixelValue,
                item?.epcKey,
                parseInt(campCounter),
                productId === PRODUCT_ID_LEADGEN ? item?.url : item?.tracking_url,
                item?.beneficiary,
                item?.payor,
                item?.campaignStatus,
                item?.alias,
                item?.domainNickName
              )
        )
      );

  // generateCampaignObject(
  //   formData?.[0]?.alias,
  //   formData?.[0]?.pageId,
  //   formData?.[0]?.pixel,
  //   formData?.[0]?.epcKey,
  //   parseInt(campCounter)
  // );
  function onSubmit() {
    if (productId === PRODUCT_ID_LEADGEN && !instantLaunch) {
      // addTrackerUrl();
    }

    if (isTiktokPlatformLaunch) {
      submitTiktokLaunch(
        submission.finalSubmissions,
        submission.name,
        generateTiktokCampaignObject(
          submission?.finalSubmissions?.[0]?.advertiserId,
          submission?.finalSubmissions?.[0]?.pixelId,
          submission?.finalSubmissions?.[0]?.identityId,
          submission?.finalSubmissions?.[0]?.identityType,
          submission?.finalSubmissions?.[0]?.pageId || "",
          parseInt(campCounter),
          productId === PRODUCT_ID_SAS ? submission.finalSubmissions?.[0]?.url : ""
        )?.campaigns
      );
    } else {
      // const campaignResults = formData?.flatMap?.((data) =>
      //   data?.adAccount?.flatMap((item) =>
      //     item?.pixelValue?.length > 0
      //       ? item?.pixelValue.map(
      //           (pixelValue) =>
      //             generateCampaignObject(
      //               submission?.alias,
      //               item?.pageId,

      //               productId === PRODUCT_ID_SAS ? item?.pixel[0] : pixelValue,
      //               item?.epcKey,
      //               parseInt(campCounter),
      //               productId === PRODUCT_ID_LEADGEN ? item?.url : item?.tracking_url,
      //               item?.beneficiary,
      //               item?.payor,
      //               item?.campaignStatus,
      //               item?.alias,
      //               item?.domainNickName
      //             )?.campaigns
      //         )
      //       : generateCampaignObject(
      //           submission?.alias,
      //           item?.pageId,

      //           productId === PRODUCT_ID_SAS ? item?.pixel[0] : item?.pixelValue,
      //           item?.epcKey,
      //           parseInt(campCounter),
      //           productId === PRODUCT_ID_LEADGEN ? item?.url : item?.tracking_url,
      //           item?.beneficiary,
      //           item?.payor,
      //           item?.campaignStatus,
      //           item?.alias,
      //           item?.domainNickName
      //         )?.campaigns
      //   )
      // );

      submitLaunch(
        submission.finalSubmissions,
        submission.name,
        campsData?.map?.((data) => data?.campaigns)
      );
    }
  }

  const modalProps = {
    visible: visibleModal,
    onSubmit: onSubmit,
    onCancel: closeModal,
    title: "Token Confirmation",
    message: "Could you please confirm if the token has been fetched?",
    okText: "Ok",
    danger: false,
    loaderBtn: sasLaunch_loading,
  };

  return (
    <div>
      <LookTableRecordDeleteModal {...modalProps} />
      {isModalVisible && (
        <Modal
          width={1180}
          title={`Launch`}
          className="profile-update-modal"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
        >
          <LaunchView campsData={campsData} />

          <LaunchCampaignView campsData={campsData} {...submission} />

          <Row className="mt-24" gutter={[12, 12]}>
            <Col span={12}>
              <Button
                onClick={handleCancel}
                disabled={(isF360AdAccountUser && sasLaunch_loading) || tiktokLaunch_loading}
                block
                className="submit-btn"
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <LookButton
                block
                type="primary"
                className="submit-btn"
                size="large"
                loading={(isF360AdAccountUser && sasLaunch_loading) || tiktokLaunch_loading}
                onClick={() => {
                  isF360AdAccountUser ? onSubmit() : showModal();
                }}
              >
                Yes, Launch !
              </LookButton>
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
}

export default LaunchModal;
