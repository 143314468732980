import { Button, Col, Form, Row } from "antd";
import SelectInput from "components/forms/SelectInput";
import React, { useEffect } from "react";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import {
  SAS_GET_BENCHMARK_FROM_CATEGORY,
  SAS_UPDATE_BENCHMARK_FROM_CATEGORY,
} from "shared/gql/gqlSchema/sasGql";
import { openNotification } from "utils";
import MultipleBenchmarkCard from "./BenchmarkCard";
import Loader from "components/loader";

function BenchmarkForm(props) {
  const {
    onSubmitBenchmark = () => {},
    selectedCreateBenchmark = [],
    selectedCreateBenchmarkFields = {},
    setSelectedCreateBenchmark = () => {},
    sasPlatformsByUser = [],
    sasPlatformsByUser_loading = false,
    sasCountries_loading = false,
    isUpdate = false,
    id = "",
    sasCountries = [],
    onClose = () => {},
    activeTab = "",
  } = props;

  const [
    getSasBenchmarkById,
    { loading: loading_GET_BENCHMARK_FROM_CATEGORY, refetch: refetch_GET_BENCHMARK_FROM_CATEGORY },
  ] = useCustomLazyQuery(SAS_GET_BENCHMARK_FROM_CATEGORY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const platformOutputs = e?.sasCategoryBenchMark?.platformOutput || [];
      if (platformOutputs) {
        const newData = platformOutputs?.map(({ country, platform, ...rest }) => ({
          countryId: country?.id,
          platformId: platform?.id,
          ...rest,
        }));
        setSelectedCreateBenchmark([...selectedCreateBenchmark, ...newData]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  useEffect(() => {
    setSelectedCreateBenchmark([]);
  }, [activeTab]);

  useEffect(() => {
    if (isUpdate) {
      getSasBenchmarkById({ variables: { categoryId: id } });
    }
  }, []);

  const [updateBenchmark, { loading: loading_UPDATE_BENCHMARK_FROM_CATEGORY }] = useCustomMutation(
    SAS_UPDATE_BENCHMARK_FROM_CATEGORY,
    {
      onCompleted: (e) => {
        const platformOutputs = e?.sasCategoryBenchMarkUpdate?.platformOutput || [];
        if (platformOutputs) {
          const newData = platformOutputs?.map(({ country, platform, ...rest }) => ({
            countryId: country?.id,
            platformId: platform?.id,
            ...rest,
          }));
          setSelectedCreateBenchmark([...newData]);
        }

        openNotification({ type: "success", message: "Rule Updated Successfully" });
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const onSubmit = () => {
    const variables = selectedCreateBenchmark?.map(({ __typename, ...rest }) => ({ ...rest }));
    updateBenchmark({
      variables: {
        categoryId: id,
        platformInputs: variables,
      },
    });
  };

  function handleCancle() {
    setSelectedCreateBenchmark([]);
    onClose();
  }

  return isUpdate && loading_GET_BENCHMARK_FROM_CATEGORY ? (
    <Loader />
  ) : (
    <Form layout="vertical" onFinish={onSubmitBenchmark}>
      <Row gutter={12} style={{ padding: isUpdate ? "10px 190px 10px 190px" : "0" }}>
        <Col span={24}>
          <Form.Item label="Platform" className="look-form-input">
            <SelectInput
              className="mb-0"
              search
              multiple
              placeholder="Select Platform"
              name="platformId"
              loader={sasPlatformsByUser_loading}
              value={selectedCreateBenchmark?.map((d) => d?.platformId)}
              options={sasPlatformsByUser?.map(({ id, name }) => ({
                name: name,
                value: id,
              }))}
              onChange={(e) => {
                let temp = [];
                e.forEach((d) => {
                  const findingIndex = selectedCreateBenchmark?.findIndex(
                    (p) => p.platformId === d
                  );
                  if (findingIndex === -1) {
                    temp.push({ platformId: d, ...selectedCreateBenchmarkFields });
                  } else {
                    temp.push(selectedCreateBenchmark[findingIndex]);
                  }
                  selectedCreateBenchmark?.includes(d);
                });
                setSelectedCreateBenchmark(temp);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {selectedCreateBenchmark?.map((data, index) => (
        <MultipleBenchmarkCard
          data={data}
          index={index}
          selectedCreateBenchmark={isUpdate ? data : selectedCreateBenchmark}
          sasPlatformsByUser={sasPlatformsByUser}
          sasCountries_loading={sasCountries_loading}
          sasCountries={sasCountries}
          setSelectedCreateBenchmark={setSelectedCreateBenchmark}
          isUpdate={isUpdate}
        />
      ))}
      {isUpdate ? (
        <Row
          gutter={12}
          style={{
            margin: isUpdate ? "10px 190px 10px 190px" : "0",
          }}
        >
          <Col span={12}>
            <Form.Item>
              <Button
                block
                className="submit-btn"
                onClick={() => {
                  handleCancle();
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                className="submit-btn"
                onClick={onSubmit}
                disabled={loading_UPDATE_BENCHMARK_FROM_CATEGORY}
              >
                {loading_UPDATE_BENCHMARK_FROM_CATEGORY ? <Loader /> : "Update"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Form>
  );
}

export default BenchmarkForm;
