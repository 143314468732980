import { valueCheck } from "..";
import { Col, Row, Skeleton, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { OverviewCalculatedCard, OverviewCard } from "../Cards";
import cx from "classnames";
import { useState } from "react";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { FIND_VS_REVENUE_BY_USER } from "shared/gql/gqlSchema/viralSparksGql";
import { GET_REPORT_BY_USER_DASHBOARD } from "shared/gql/gqlSchema/leadgenGql";
import DebugPre from "components/DebugPre";
import { numCompactShort } from "utils";
import { SPEND_INCREASE_PERCENT_VS } from "shared/enum/commonKeysandEnum";

export default function VSRevenueData() {
  const userId = useSelector((state) => state?.userReducer?.me?.user_id);
  const selectedWorkspace = useSelector((state) => state?.userSettings?.selectedWorkspace);
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);

  const [spendReport, setSpendReport] = useState([]);
  const [revenueReport, setRevenueReport] = useState([]);

  const apiProps = { userId };

  const {
    data: data_GET_REPORT_BY_USER,
    loading: loading_GET_REPORT_BY_USER,
    error: error_GET_REPORT_BY_USER,
    refetch: refetch_GET_REPORT_BY_USER,
  } = useCustomQuery(GET_REPORT_BY_USER_DASHBOARD, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.reportByUser;

      if (element) {
        setSpendReport(element);
      }
    },

    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      workspaceId: selectedWorkspace,
      ...apiProps,
    },
  });

  const {
    data: data_FIND_VS_REVENUE_BY_USER,
    loading: loading_FIND_VS_REVENUE_BY_USER,
    error: error_FIND_VS_REVENUE_BY_USER,
    refetch: refetch_FIND_VS_REVENUE_BY_USER,
  } = useCustomQuery(FIND_VS_REVENUE_BY_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.findVSRevenueByUser;
      if (element) {
        setRevenueReport(element);
      }
    },

    variables: {
      startDate: dateRangeTimezone?.rangeDates[0],
      endDate: dateRangeTimezone?.rangeDates[1],
      timeZone: dateRangeTimezone?.timeZone?.value,

      ...apiProps,
    },
  });

  const loading = loading_GET_REPORT_BY_USER || loading_FIND_VS_REVENUE_BY_USER;

  let finalMerge = [{}];
  let finalMergeData = [];
  if (!loading) {
    if (revenueReport[0]) {
      finalMerge[0] = { ...finalMerge[0], ...revenueReport[0] };
    }

    if (spendReport[0]) {
      finalMerge[0] = { ...finalMerge[0], ...spendReport[0] };
    }

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        leadsFB = 0,
        impressions = 0 | "0",
        TotalRevenue = 0 | "0",
        sessions = 0 | "0",
        pageViews = 0 | "0",
        utmCampaign,
        adName = "",
        agencyRevenue = 0,
      } = element;
      const spendUpdate = (spendFB * SPEND_INCREASE_PERCENT_VS).toFixed(3);
      const revenueUpdate = agencyRevenue;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(leadsFB) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageViews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        utmCampaign: utmCampaign || adName,
        TotalRevenue: valueCheck(revenueUpdate),
        jugaad: parseFloat(TotalRevenue), // will remove in future
        clicksFB,
        leadsFB,
        impressionsFB,
        key: index,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        CPC: valueCheck(CPC),
        CPC_FB: valueCheck(CPC_FB),
        ROI: valueCheck(ROI),
        EPC: valueCheck(EPC),
        CTR: valueCheck(CTR),
        DISC: valueCheck(DISC),
        IPP: valueCheck(IPP),
        RPM: valueCheck(RPM),
        PRPM: valueCheck(PRPM),
        CPR: valueCheck(CPR),
        PPS: valueCheck(PPS),
        CPM: valueCheck(CPM),
      });
    });
  }
  let total_spendFB = 0,
    total_jugaad = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_leadsFB = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    leadsFB = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageViews = 0,
    jugaad = 0,
  } of finalMergeData) {
    total_spendFB += JSON.parse(spendFB || 0);
    total_clicksFB += JSON.parse(clicksFB || 0);
    total_impressionsFB += JSON.parse(impressionsFB || 0);
    total_leadsFB += JSON.parse(leadsFB || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageViews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicksFB;
  const total_ROI = (total_PROFIT / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicksFB / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_leadsFB;
  const total_DISC = total_sessions / total_clicksFB;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const overview = [
    {
      iconType: "creditCard",
      title: "Spend",
      value: "$" + numCompactShort(valueCheck(total_spendFB, true), 2),
      // valueBy: "per day",
    },
    {
      iconType: "barChartLine",
      title: "Gross Margin",
      value: (
        <Tooltip title={total_PROFIT.toFixed(2)}>
          <span
            className={cx({
              "text-primary": total_PROFIT > 0,
              "text-danger": total_PROFIT < 0,
            })}
          >
            ${numCompactShort(total_PROFIT.toFixed(1).replace("-", ""), 2)}
          </span>
        </Tooltip>
      ),
      // valueBy: "per day",
    },
    {
      iconType: "dollar",
      title: "Revenue",
      value: "$" + numCompactShort(valueCheck(total_TotalRevenue, true), 2),
      // valueBy: "per day",
    },
    {
      iconType: "rise",
      title: "ROI",
      value: <>{valueCheck(total_ROI, true)}%</>,
      // valueBy: "per day",
    },
  ];

  const overviewCalculatedData = [
    {
      title: "Clicks",
      value: numCompactShort(valueCheck(total_clicksFB, true), 2),
    },

    {
      title: "CPC",
      value: numCompactShort(valueCheck(total_CPC, true), 2),
    },
    {
      title: "CPC FB",
      value: numCompactShort(valueCheck(total_CPC_FB, true), 2),
    },
    {
      title: "CTR",
      value: numCompactShort(valueCheck(total_CTR, true), 2) + "%",
    },
    {
      title: "CPR",
      value: numCompactShort(valueCheck(total_CPR, true), 2) + "%",
    },
    {
      title: "EPC",
      value: numCompactShort(valueCheck(total_EPC, true), 2),
    },
  ];

  // if (loading) return <Loader fullScreen />;
  // if (loading) return "loading";

  const renderSkeleton = () => (
    <>
      <div>
        <Row gutter={28}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Row gutter={21}>
          {overviewCalculatedData.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overviewCalculatedData.length}
              xl={24 / overviewCalculatedData.length}
              key={"overviewCalculatedData" + index}
            >
              <OverviewCalculatedCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
  const renderContent = () => (
    <>
      <div>
        <Row gutter={28}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard {...data} />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Row gutter={21}>
          {overviewCalculatedData.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overviewCalculatedData.length}
              xl={24 / overviewCalculatedData.length}
              key={"overviewCalculatedData" + index}
            >
              <OverviewCalculatedCard {...data} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  return (
    <div>
      {loading ? renderSkeleton() : renderContent()}
      <DebugPre
        content={[
          {
            isRow: true,
            contentObjects: [{ finalMerge }, { finalMergeData }],
          },
          {
            isRow: true,
            contentObjects: [{ spendReport }, { revenueReport }],
          },
        ]}
      />
    </div>
  );
}
