import { DatePicker, Skeleton } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import cx from "cx";
import { useHistory } from "react-router-dom";

function LookDashboardLineSeparatorCard(props) {
  let history = useHistory();
  const { iconType, name = "", value = "", color = "", loading = false, requestType } = props;

  function handleClick() {
    history.push(`/crs/article-requests?filter=${requestType}`);
  }
  /**
   * contains objects {icon name, value }
   */
  const content = () =>
    loading ? (
      <Skeleton avatar active paragraph={{ rows: 1 }} />
    ) : (
      <>
        {iconType && (
          <ActionIconRender
            iconType={iconType}
            style={{ color, fontSize: 14, paddingTop: "4px" }}
          />
        )}
        <div className="line-seprator-card-item-name-value">
          <p className="name">{name}</p>
          <p className="value">{value}</p>
        </div>
      </>
    );

  return (
    <div
      onClick={!loading && handleClick}
      className={cx("line-seprator-card-item", { "cursor-pointer": !loading })}
    >
      {content()}
    </div>
  );
}

export default function LookDashboardLineSeparatorCards(props) {
  /**
   * listData required object array
   * contains objects {icon, name, value }
   */
  const { loading = "", label = "", listData = [], setRangeDates, handleReload } = props;

  const rendercardList = () =>
    listData?.map((data, index) => (
      <LookDashboardLineSeparatorCard
        requestType={data?.key}
        key={"rendercardList" + index}
        {...data}
        loading={loading}
      />
    ));

  const handleDateRange = (e) => {
    setRangeDates(e.rangeDates);
  };

  const dateRangeProps = {
    noStore: true,
    disabledTimeZone: true,
    onChange: handleDateRange,
  };

  return (
    <div className="line-seprator-card">
      <div className="line-seprator-card-label">
        <h2 className="p-0 m-0">{label}</h2>
        <div className="d-flex align-items-center">
          <DateRangePersist {...dateRangeProps} />
          <LookButton
            className="ml-10 border-0"
            iconType="reload"
            tooltipTitle="Refetch"
            // type="primary"
            size="small"
            onClick={handleReload}
          />
        </div>
      </div>
      <div className="item-wrapper">{rendercardList()}</div>
    </div>
  );
}
