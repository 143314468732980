import { Button, Switch, Tooltip } from "antd";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

import LookModal from "components/LookModal";
import LookTable from "components/LookTable";

import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useState, useImperativeHandle } from "react";
import {
  GQL_RE_DELETERULE,
  GQL_RE_GETADMINRULE,
  GQL_RE_MANUALRUN,
  GQL_RE_UPDATERULESTATUS,
} from "shared/gql/gqlSchema/ruleGql";
import { openNotification } from "utils";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import UpdateRuleForm from "../UpdateRules";
import NewRuleForm from "../NewRules";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import moment from "moment";
import RuleActivity from "../RuleActivityLogs";
import SelectInput from "components/forms/SelectInput";
import { useSelector } from "react-redux";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_TIKTOK,
  RULE_PLATFORM_FB,
  RULE_PLATFORM_TIKTOK,
  RULE_PLATFORM_VAROZI,
} from "shared/SharedKeys";
import { dateTimeFormatRuleEngine } from "reducers/userSettings";

export default function ExistingAdminRule(props) {
  const { productId, platform, userId } = props;
  const [data, setdata] = useState([]);
  const [ruleType, setruleType] = useState("");
  const [idtype, setIdtype] = useState("");
  const [updateData, setupdateData] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [ids, setIds] = useState(null);
  const [DrawerVisible, setDrawerVisible] = useState(false);
  const [AssetType, setAssetType] = useState("all");
  const [actionType, setactionType] = useState("all");
  const [platformType, setplatformType] = useState("all");
  const super_admin = useSelector((state) => state?.userReducer?.me?.lf_superadmin);
  const search = useSelector(
    (state) => state?.userSettings?.mainSearch?.[USER_SETTING_TABLE_PERSIST_KEY_MAIN]?.search
  );

  const [clgetRule, { loading: loading_clgetRule, refetch: clruleFetch }] = useCustomLazyQuery(
    GQL_RE_GETADMINRULE,
    {
      variables: {
        // groupBy: groupBy,
        // reportType: reportType,
        platform:
          platform.length > 1
            ? "all"
            : platform[0]?.id === PLATFORM_ID_FACEBOOK
            ? RULE_PLATFORM_FB
            : platform[0]?.id === PLATFORM_ID_TIKTOK
            ? RULE_PLATFORM_TIKTOK
            : RULE_PLATFORM_VAROZI,
        product: productId,
      },
      onCompleted: (e) => {
        const element = e?.re_getAdminRule;
        if (element) {
          let res = [];
          for (let i in element) {
            res.push({
              id: element[i].id,
              user_id: element[i].user_id,
              actions: element[i].action,
              period: element[i].cron_command,
              name: element[i].name,
              rules: element[i].all,
              timezone: element[i].timezone,
              report_timeline: element[i].report_timeline,
              message: element[i].message,
              ids: element[i].ids,
              status: element[i].status,
              category_name: element[i].category_name,
              exclusion_name: element[i].exclusion_name,
              is_orType: element[i].is_orType,
              budget: element[i].budget,
              budget_type: element[i].budget_type,
              reporting_days: element[i].reporting_days,
              lifespan: element[i].lifespan,
              nextOccurrence:
                element[i].nextOccurrence === null
                  ? null
                  : moment(element[i].nextOccurrence).format(dateTimeFormatRuleEngine),
              previousOccurrence:
                element[i].previousOccurrence === null
                  ? null
                  : moment(element[i].previousOccurrence).format(dateTimeFormatRuleEngine),
              processed_count: element[i].processed_count,
              error_count: element[i].error_count,
              previousStatus: element[i].previousStatus,
              platform: element[i].platform,
              product: element[i].productId,
              groupBy: element[i].groupBy,
              reportType: element[i].report_type,
              created_at:
                element[i].created_at === null
                  ? null
                  : moment(element[i].created_at).format(dateTimeFormatRuleEngine),
              comparision: element[i].comparision,
              last_x_days: element[i].last_x_days,
              is_pulse: element[i].is_pulse,
              country: element[i].country,
              ad_account_ids: element[i].ad_account_ids,
              bid: element[i].bid,
            });
          }
          // console.log(res);
          setdata(res);
        }
      },
      onError(e) {
        setdata([]);
      },
    }
  );
  useEffect(() => {
    clgetRule();
  }, []);
  const finalDataWithGbyCheck =
    AssetType !== "all" ? data.filter(({ groupBy }) => groupBy === AssetType) : data;
  const finaDataWithActionCheck =
    actionType !== "all"
      ? finalDataWithGbyCheck.filter(({ actions }) => actions === actionType)
      : finalDataWithGbyCheck;
  const finalDatawithPlatformCheck =
    platform.length > 0 && platformType !== "all"
      ? finaDataWithActionCheck.filter(({ platform }) => platform === platformType)
      : finaDataWithActionCheck;

  const finaldatabysearch = search
    ? finalDatawithPlatformCheck.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
    : finalDatawithPlatformCheck;

  const [cldeleteRule, { loading: loading_DeleteGroups }] = useCustomMutation(GQL_RE_DELETERULE, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_deleteRule;
      if (element) {
        openNotification({ type: "success", message: "Rule deleted Successfully" });
        clruleFetch();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [clupdateRuleStatus] = useCustomMutation(GQL_RE_UPDATERULESTATUS, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_updateRuleStatus;
      if (element) {
        openNotification({ type: "success", message: "Rule status updated Successfully" });
        clruleFetch();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [clmanualRunRule, { loading: manualrun_loading }] = useCustomLazyQuery(GQL_RE_MANUALRUN, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_updateRuleStatus;
      if (element) {
        openNotification({ type: "success", message: e?.message });
        clruleFetch();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const handleManualRun = (otherProps) => {
    clmanualRunRule({
      variables: {
        id: otherProps?.id,
        name: otherProps?.name,
      },
    });
  };
  const handleStatus = (e, otherProps) => {
    if (e) {
      clupdateRuleStatus({
        variables: {
          id: otherProps?.id,
          name: otherProps?.name,
          status: "NOTinqueue",
          groupBy: otherProps?.groupBy,
          rule_paused: false,
        },
      });
    } else {
      clupdateRuleStatus({
        variables: {
          id: otherProps?.id,
          name: otherProps?.name,
          status: "paused",
          groupBy: otherProps?.groupBy,
          rule_paused: true,
        },
      });
    }
  };

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    cldeleteRule({
      variables: {
        id: deleteModalPropsData?.id,
        name: deleteModalPropsData?.name,
        groupBy: deleteModalPropsData?.groupBy,
      },
    });
    setDeleteModalVisible(false);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  function showFilterSearch(data) {
    if (data) {
      setDrawerVisible(true);
      setruleType("add");
      setupdateData(data);
    } else {
      setDrawerVisible(true);
      setruleType("add");
    }
  }
  function updateFilterSearch(data) {
    setDrawerVisible(true);
    setruleType("update");
    setupdateData(data);
  }
  function onClose() {
    setDrawerVisible(false);
    setupdateData(false);
  }

  const drawerProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 800,
    visible: DrawerVisible,
    onClose,
  };
  const initialColumns = [
    {
      title: "Rule name",
      dataIndex: "name",
      key: "name",
      searchable: true,
      searchType: "string",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={value}
          isClick={() => {
            updateFilterSearch(otherProps);
          }}
        />
      ),
      width: 160,
      minWidth: 160,
    },
    {
      title: "User",
      dataIndex: "user_id",
      key: "user_id",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      width: 160,
      minWidth: 160,
      // render: (value, otherProps) => <LookTableColumnRender title={otherProps?.action} />,
    },
    {
      title: "Last Activity On",
      dataIndex: "previousOccurrence",
      key: "previousOccurrence",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Next Activity On",
      dataIndex: "nextOccurrence",
      key: "nextOccurrence",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Rule Action",
      dataIndex: "actions",
      key: "actions",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={
            value === "ACTIVE" || value === "ENABLE"
              ? "Turn On"
              : value === "PAUSED" || value === "DISABLE"
              ? "Turn Off"
              : value
          }
        />
      ),
      width: 90,
      minWidth: 160,
    },

    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Apply At",
      dataIndex: "groupBy",
      key: "groupBy",
      // searchable: true,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Actions",
      dataIndex: "action",
      nofilter: true,
      render: (value, otherProps) => {
        return (
          <>
            {super_admin ? (
              <>
                <Tooltip title="Copy Rule">
                  <Button
                    className="h-auto"
                    type="link"
                    icon={<ActionIconRender iconType="copy" />}
                    onClick={
                      () => showFilterSearch(otherProps)
                      // deleteRule(otherProps.name)
                    }
                  />
                </Tooltip>
                <Tooltip title="Manual Run">
                  <Button
                    className="h-auto"
                    type="link"
                    icon={<ActionIconRender iconType="play" />}
                    disabled={manualrun_loading}
                    onClick={() => handleManualRun(otherProps)}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    className="h-auto"
                    type="link"
                    icon={<ActionIconRender iconType="delete" />}
                    onClick={
                      () => onOpenDeleteModal(otherProps)
                      // deleteRule(otherProps.name)
                    }
                  />
                </Tooltip>
              </>
            ) : null}
            <Tooltip title={super_admin ? undefined : "not allowed"} placement="left">
              <Switch
                className="mr-8"
                disabled={!super_admin}
                style={{ backgroundColor: "#141414" }}
                checked={otherProps.status !== "paused"}
                onChange={(e) => handleStatus(e, otherProps)}
              />
            </Tooltip>
          </>
        );
      },
      fixed: "right",
      width: super_admin ? 160 : 60,
    },
  ];
  const rightOptions = (
    <>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Status">
          <span>
            <SelectInput
              search
              name="status"
              className="mb-0"
              placeholder="Select status"
              value={AssetType}
              options={[
                { name: "All Assets", value: "all" },
                { name: "Campaign", value: "campaign" },
                { name: "Adset", value: "adset" },
                { name: "Ad", value: "ad" },
              ]}
              onChange={setAssetType}
            />
          </span>
        </Tooltip>
      </div>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Status">
          <span>
            <SelectInput
              search
              name="actions"
              className="mb-0"
              placeholder="Select status"
              value={actionType}
              options={[
                { name: "All Actions", value: "all" },
                {
                  name: "Turn On",
                  value: platformType === RULE_PLATFORM_TIKTOK ? "ENABLE" : "ACTIVE",
                },
                {
                  name: "Turn Off",
                  value: platformType === RULE_PLATFORM_TIKTOK ? "DISABLE" : "PAUSED",
                },
                { name: "Increase Budget", value: "INCREASE" },
                { name: "Decrease Budget", value: "DECREASE" },
              ]}
              onChange={setactionType}
            />
          </span>
        </Tooltip>
      </div>
      {platform.length > 1 && (
        <div className="mr-12" style={{ width: 150 }}>
          <Tooltip title="Platform">
            <span>
              <SelectInput
                search
                name="platform"
                className="mb-0"
                placeholder="Select Platform"
                value={platformType}
                options={[
                  { name: "All Platforms", value: "all" },
                  { name: "Facebook", value: "fb" },
                  { name: "Tiktok", value: "tiktok" },
                ]}
                onChange={setplatformType}
              />
            </span>
          </Tooltip>
        </div>
      )}
    </>
  );
  const tableProps = {
    tableProps: { initialColumns, recordData: finaldatabysearch },
    rightOptions,
    isSearchFilter: true,
  };
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_DeleteGroups,
  };

  return (
    <>
      <DashboardHeader
        title="Rules"
        // createBtn
        // createBtnText="Add New Rule"
        // createBtnType="add"
        // createBtnIconType=""
        // createBtnHandler={showFilterSearch}
      />
      <LookTable
        isRowSelection={false}
        {...tableProps}
        loading={loading_clgetRule}
        refetch={clruleFetch}
      />
      <LookTableRecordDeleteModal {...deleteModalProps} />

      <div>
        <LookDrawer {...drawerProps}>
          {DrawerVisible &&
            (updateData && ruleType === "update" ? (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add/Update",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <UpdateRuleForm
                        close={() => onClose()}
                        data={updateData}
                        refetch={clruleFetch}
                        platform={platform}
                        fromAllRules={true}
                        productId={productId}
                      />
                    ),
                  },
                  // {
                  //   hoverTitle: "Rule Linked Items",
                  //   icon: () => <ActionIconRender iconType="ordered-list" />,
                  //   component: <RuleLinkedItems close={() => onClose()} ids={ids} />,
                  // },
                  {
                    hoverTitle: "Rule Linked Activity",
                    icon: () => <ActionIconRender iconType="notification" />,
                    component: (
                      <RuleActivity
                        close={() => onClose()}
                        rule_id={updateData?.id}
                        user_id={updateData?.user_id}
                      />
                    ),
                  },
                ]}
              />
            ) : null)}
        </LookDrawer>
      </div>
    </>
  );
}
