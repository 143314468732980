import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  UMS_AGENCY_ADMIN,
  UMS_SUPER_ADMIN,
  UMS_TEAM_ADMIN,
  USERS_MANAGES_WORKSPACES,
} from "shared/UserPermission/userPermissionList";
// import {
//   USERS_MANAGES_AGENCIES,
//   USERS_MANAGES_PRODUCTS,
//   USERS_MANAGES_ROLES,
//   USERS_MANAGES_USERS,
//   USERS_MANAGES_WORKSPACES,
// } from "../../shared/UserPermission/userPermissionList";

import route, { ROUTE_DASHBOARD } from "../route";

const UMSAgencies = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMS-UMSAgencies" */ "pages/dashboardPages/UMS/UMSAgencies")
  )
);
const UMSDashboard = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMSDashboard" */ "pages/dashboardPages/UMS/UMSDashboard")
  )
);
const UMSProducts = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMSProducts" */ "pages/dashboardPages/UMS/UMSProducts")
  )
);
const UMSSettings = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMSSettings" */ "pages/dashboardPages/UMS/UMSSettings")
  )
);
const UMSTeams = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMS-UMSTeams" */ "pages/dashboardPages/UMS/UMSTeams")
  )
);
const UMSUsers = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "UMSUsers" */ "pages/dashboardPages/UMS/UMSUsers"))
);
const UMSWorkspaces = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMSWorkspaces" */ "pages/dashboardPages/UMS/UMSWorkspaces")
  )
);
const UMSWorkspaceSettings = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "UMSWorkspaceSettings" */ "pages/dashboardPages/UMS/UMSWorkspaceSettings"
    )
  )
);
const UMSAgency = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMSAgency" */ "pages/dashboardPages/UMS/UMSAgency")
  )
);
const UMSCustomRole = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "UMSCustomRole" */ "pages/dashboardPages/UMS/UMSCustomRole")
  )
);
const ProfileDetails = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "ProfileDetails" */ "pages/dashboardPages/UMS/Profile/ProfileDetails"
    )
  )
);
const Security = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Security" */ "pages/dashboardPages/UMS/Profile/Security")
  )
);
const Settings = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Settings" */ "pages/dashboardPages/UMS/Profile/Settings")
  )
);

const umsRoutes = [
  route("/ums/main", UMSDashboard, ROUTE_DASHBOARD, false, UMS_AGENCY_ADMIN),

  route("/ums/agency", UMSAgency, ROUTE_DASHBOARD, false, UMS_AGENCY_ADMIN),
  route("/ums/workspaces", UMSWorkspaces, ROUTE_DASHBOARD, false, UMS_AGENCY_ADMIN),
  route("/ums/users", UMSUsers, ROUTE_DASHBOARD, false, [UMS_AGENCY_ADMIN, UMS_TEAM_ADMIN]),
  route("/ums/teams", UMSTeams, ROUTE_DASHBOARD, false, [
    UMS_AGENCY_ADMIN,
    USERS_MANAGES_WORKSPACES,
  ]),
  route("/ums/settings", UMSSettings, ROUTE_DASHBOARD, false, UMS_AGENCY_ADMIN),
  // route("/ums/assets/ad-accounts", AdAccounts, ROUTE_DASHBOARD, false, UMS_AGENCY_ADMIN),
  route("/user/profile", ProfileDetails, ROUTE_DASHBOARD, false),
  route("/user/security", Security, ROUTE_DASHBOARD, false),
  route("/user/settings", Settings, ROUTE_DASHBOARD, false),
  route("/ums/workspace-settings", UMSWorkspaceSettings, ROUTE_DASHBOARD, false),
  route("/ums/role-permissions", UMSCustomRole, ROUTE_DASHBOARD, false),
  // Super Admin permission
  route("/ums/agencies", UMSAgencies, ROUTE_DASHBOARD, false, UMS_SUPER_ADMIN),
  route("/ums/products", UMSProducts, ROUTE_DASHBOARD, false, UMS_SUPER_ADMIN),

  // route("/configuration/profile", Profile, ROUTE_DASHBOARD, false),
  // route("/configuration/agency", Agency, ROUTE_DASHBOARD, false, USERS_MANAGES_AGENCIES),
  // route("/configuration/agencies", UMSAgencies, ROUTE_DASHBOARD, false, USERS_MANAGES_AGENCIES),
  // route("/configuration/user-management", UserManagement, ROUTE_DASHBOARD, false, [
  //   USERS_MANAGES_USERS,
  //   USERS_MANAGES_WORKSPACES,
  // ]),
  // route("/configuration/permissions", Permissions, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
  // // route("/configuration/services", Services),
  // route("/configuration/roles", Roles, ROUTE_DASHBOARD, true, USERS_MANAGES_ROLES),
  // route("/configuration/products", UMSProducts, ROUTE_DASHBOARD, true, USERS_MANAGES_PRODUCTS),
  // route("/configuration/teams", UMSTeams, ROUTE_DASHBOARD, true),

  // route(
  //   "/configuration/workspace-manage",
  //   UMSWorkspaceManage,
  //   ROUTE_DASHBOARD,
  //   true,
  //   USERS_MANAGES_WORKSPACES
  // ),
  // route(
  //   "/configuration/generate-invite-code",
  //   GeneratenviteCode,
  //   ROUTE_DASHBOARD,
  //   true,
  //   USERS_MANAGES_USERS
  // ),
];

export default umsRoutes;
