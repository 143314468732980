import { openNotification } from "../../utils";

export default function useFileUpload() {

  // const [
  //   s3MultiFile,
  //   {
  //     data: data_S3_MULTI_FILE = [],
  //     loading: loading_S3_MULTI_FILE,
  //     error: error_S3_MULTI_FILE,
  //     refetch: refetch__S3_MULTI_FILE,
  //   },
  // ] = useLazyQuery(S3_MULTI_FILE, {
  //   notifyOnNetworkStatusChange: true,

  //   onCompleted: (e) => {
  //     // setImage(e?.s3MultiFile[0]?.public_url);
  //   },
  //   onError(e) {
  //     openNotification({ type: "error", message: e.message });
  //   },
  // });
  // const [s3MultiFileUpload, { loading: loading_S3_MULTI_FILE_UPLOAD }] = useMutation(
  //   S3_MULTI_FILE_UPLOAD,
  //   {
  //     onCompleted(e) {
  //       // openNotification({ type: "success", message: "Generate Upload Url Success" });
  //       setUploadFileLoader(true);
  //       fileUploading(e?.s3MultiFileUpload);
  //     },
  //     onError(e) {
  //       openNotification({ type: "error", message: e.message });
  //     },
  //   }
  // );

  // function fileUploading(fileData) {
  //   const requestPromise = [];

  //   const uploadPaths = [];

  //   fileData.forEach(({ url, path }, index) => {
  //     uploadPaths.push(path);
  //     var config = {
  //       method: "put",
  //       url: url,
  //       headers: {
  //         "Content-Type": "",
  //       },
  //       data: selectedFile[index],
  //     };
  //     requestPromise.push(
  //       axios(config)
  //         .then(function (response) {
  //           // openNotification({ type: "success", message: "Upload File To Server success" });
  //           setUploadFileLoader(false);
  //           // uploadoadFile_Stage3(path);
  //         })
  //         .catch(function (error) {
  //           console.log("razat error", error);
  //           openNotification({ type: "error", message: error.message });
  //           setUploadFileLoader(false);
  //         })
  //     );
  //   });

  //   Promise.all(requestPromise)
  //     .then((e) => {
  //       console.log("Promise then", e);

  //       s3MultiFile({
  //         variables: {
  //           path: uploadPaths,
  //         },
  //       });
  //     })
  //     .catch((e) => {
  //       console.log("Promise catch", e);
  //     });
  // }

  // function uploadFile_Stage1(selectedFileObject) {
  //   let tt = [];

  //   // for (const [key, value] of Object.entries(selectedFile)) {
  //   for (const [key, value] of Object.entries(selectedFileObject)) {
  //     // console.log(`${key}: ${value.name}`);
  //     tt.push(value.name);
  //   }

  //   s3MultiFileUpload({
  //     variables: {
  //       file_name: tt,
  //       service_name: "CMS",
  //     },
  //   });
  // }

  // const handleCompressedUpload = (e) => {
  //   const image = e.target.files[0];
  //   const imageType = image?.type || "";
  //   if (
  //     !(
  //       imageType === "image/png" ||
  //       imageType === "image/gif" ||
  //       imageType === "image/jpeg" ||
  //       imageType === "image/jpg"
  //     )
  //   ) {
  //     openNotification({ type: "error", message: "Only jpg/jpeg and png files are allowed!" });
  //   }
  //   if (!image) {
  //     return null;
  //   }
  //   if (image) {
  //     new Compressor(image, {
  //       quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
  //       success: (compressedResult) => {
  //         // compressedResult has the compressed file.
  //         // Use the compressed file to upload the images to your server.

  //         setSelectedFile([compressedResult]);
  //         uploadFile_Stage1([compressedResult]);
  //       },
  //     });
  //   }
  // };

  // const commonLoading =
  //   loading_S3_MULTI_FILE_UPLOAD || uploadingFileLoader || loading_S3_MULTI_FILE;

  // /*
  //  * loading_S3_MULTI_FILE_UPLOAD for Genrating upload url
  //  * uploadingFileLoader for Uploading File
  //  * loading_S3_MULTI_FILE for Genrating final url
  //  */

  // function test() {
  //   console.log("test razat");
  // }
  const test = "t";
  return { test };
}
