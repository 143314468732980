import { CALL_BACK_REFETCH_FALSE, CALL_BACK_REFETCH_TRUE } from "actions/actionTypes";
import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

const CALLBACK_RECALL = "callBack-RECALL";

export function useRecallBackInitial(recall = () => {}) {
  const dispatch = useDispatch();
  const refetch_required = useSelector((el) => el?.callBackReducer?.refetch_onCallback);

  useEffect(() => {
    if (refetch_required) {
      recall();
      dispatch({ type: CALL_BACK_REFETCH_FALSE });
    }
  }, [refetch_required]);

  useEffect(() => {
    window.addEventListener("storage", checkUserData);
    function checkUserData() {
      const item = localStorage.getItem(CALLBACK_RECALL);
      if (item) {
        if (item === "true") dispatch({ type: CALL_BACK_REFETCH_TRUE });
        else dispatch({ type: CALL_BACK_REFETCH_FALSE });
        localStorage.removeItem(CALLBACK_RECALL);
      }
    }

    return () => {
      window.removeEventListener("storage", checkUserData);
      dispatch({ type: CALL_BACK_REFETCH_FALSE });
    };
  }, []);
  return null;
}

export function useCallbackClose() {
  let int = useRef();
  function onClose() {
    clearTimeout(int.current);
    localStorage.setItem(CALLBACK_RECALL, true);
    window.close();
  }

  useEffect(() => {
    const cleanup = int.current;
    return () => clearTimeout(cleanup);
  }, []);
  return { onClose };
}
