import { USER_SETTING_DATA } from "actions/actionTypes";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useApiToStoreQuery(props) {
  const {
    api,
    variables = {},
    elementKey,
    elementKeyLevel2,
    storeKey,
    objectType = false,
    directData = false,
    newResponse = false,
  } = props || {};
  const dispatch = useDispatch();
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);

  const [apiCall, { data, loading, refetch }] = useCustomLazyQuery(api, {
    notifyOnNetworkStatusChange: true,
    variables: { ...variables },
  });

  useEffect(() => {
    let finalData;
    if (newResponse) {
      if (directData) {
        finalData = userSettingData?.[storeKey]
          ? { [storeKey]: data !== null ? data : userSettingData?.[storeKey] }
          : { [storeKey]: data };
      } else {
        const element = data?.[elementKey]?.data;
        const mainElement = elementKeyLevel2 ? element?.[elementKeyLevel2] : element;
        if (objectType) {
          finalData = userSettingData?.[storeKey]
            ? { [storeKey]: mainElement !== null ? mainElement : userSettingData?.[storeKey] }
            : { [storeKey]: mainElement };
        } else {
          finalData = userSettingData?.[storeKey]
            ? {
                [storeKey]: mainElement?.length >= 0 ? mainElement : userSettingData?.[storeKey],
              }
            : { [storeKey]: mainElement };
        }
      }
    } else {
      if (directData) {
        finalData = userSettingData?.[storeKey]
          ? { [storeKey]: data !== null ? data : userSettingData?.[storeKey] }
          : { [storeKey]: data };
      } else {
        const element = data?.[elementKey];
        const mainElement = elementKeyLevel2 ? element?.[elementKeyLevel2] : element;
        if (objectType) {
          finalData = userSettingData?.[storeKey]
            ? { [storeKey]: mainElement !== null ? mainElement : userSettingData?.[storeKey] }
            : { [storeKey]: mainElement };
        } else {
          finalData = userSettingData?.[storeKey]
            ? {
                [storeKey]: mainElement?.length >= 0 ? mainElement : userSettingData?.[storeKey],
              }
            : { [storeKey]: mainElement };
        }
      }
    }

    dispatch({
      type: USER_SETTING_DATA,
      payload: { ...userSettingData, ...finalData, [storeKey + "_loading"]: loading },
    });
  }, [loading, data]);

  function setDataSource(data) {
    dispatch({ type: USER_SETTING_DATA, payload: { ...userSettingData, [storeKey]: data } });
  }

  return { apiCall, data, setDataSource, loading, refetch };
}
