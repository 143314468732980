import { Result } from "antd";
import { Link } from "react-router-dom";

export default function Page404NotFound() {
  return (
    <div style={{ minHeight: "100vh" }} className="h-100 dashboard-content">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/" className="ant-btn">
            Back Home
          </Link>
        }
      />
    </div>
  );
}
