import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { GET_CLONE_CAMP_LIST } from "shared/gql/gqlSchema/launchGql";
import { openNotification } from "utils";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

function CloneListing(props) {
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const [cloneListingData, setCloneListingData] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  const [rangeDates, setRangeDates] = useState(dateRangeTimezone?.rangeDates);
  const [timeZone, setTimeZone] = useState(dateRangeTimezone?.timeZone);
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  let finalFilterData = [];

  const { loading: loading_CLONE_LISTING, refetch: refetch_CLONE_LISTING } = useCustomQuery(
    GET_CLONE_CAMP_LIST,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.clonedCampaigns;
        if (element) {
          setCloneListingData(element);
        }
      },
      onError: (err) => {
        openNotification({ type: "error", message: err.message });
      },
      variables: {
        fromDate: rangeDates[0],
        toDate: rangeDates[1],
      },
    }
  );

  const refetch = () => {
    refetch_CLONE_LISTING();
  };
  const loading = loading_CLONE_LISTING;

  const initialColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.name - b?.name,
      searchType: "string",
    },
    {
      title: "Campaign Id",
      dataIndex: "fbCampaignId",
      key: "fbCampaignId",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.fbCampaignId - b?.fbCampaignId,
      searchType: "number",
    },
    {
      title: "Budget Type",
      dataIndex: "budgetType",
      key: "budgetType",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      nofilter: true,
      minWidth: 120,
      sorter: (a, b) => a?.budgetType - b?.budgetType,
      searchType: "sting",
    },
    {
      title: "Budget Optimization",
      dataIndex: "budgetOptimization",
      key: "budgetOptimization",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.budgetOptimization - b?.budgetOptimization,
      searchType: "string",
    },
    {
      title: "Scheduled Start Time",
      dataIndex: "scheduledStartTime",
      key: "scheduledStartTime",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.scheduledStartTime - b?.scheduledStartTime,
      searchType: "string",
    },
    {
      title: "Scheduled End Time",
      dataIndex: "scheduledEndTime",
      key: "scheduledEndTime",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.scheduledEndTime - b?.scheduledEndTime,
      searchType: "string",
    },
    {
      title: "Clone Of",
      dataIndex: "cloneOf",
      key: "cloneOf",
      render: (value) => <LookTableColumnRender title={value} />,
      nofilter: true,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.clonedOf - b?.clonedOf,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.status - b?.status,
      searchType: "string",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={otherProps?.currency ? `${otherProps?.currency} ${value / 100}` : value / 100}
        />
      ),
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.budget - b?.budget,
      fixed: "right",
      searchType: "number",
    },
  ];

  if (!loading) {
    finalFilterData = outerFilterData(filterData(cloneListingData), {
      filterKeys: ["name", "fbCampaignId"],
    });
  }

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    isRecommendation: true,
    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData },
    persistKey,
    dateRangeProps: {
      onChange: handleDateRange,
    },
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ cloneListingData }, { finalFilterData }],
    },
  ];

  return (
    <div>
      <DashboardHeader title="Clone list" />
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default CloneListing;
