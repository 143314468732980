import { useParams } from "react-router";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { TIKTOK_REDIRECT } from "shared/gql/gqlSchema/sasGql";
import Loader from "components/loader";
import { openNotification } from "utils";

export function useTiktokRedirect({ redirectType }) {
  const [callTiktokRedirect, { loading: loadingTiktokRedirect }] = useCustomLazyQuery(
    TIKTOK_REDIRECT,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        window.open(e?.tiktok_redirect?.data?.url);
      },
      onError: (err) => {
        openNotification({ type: "error", message: err.message, key: "tiktok-redirect-err" });
      },
    }
  );

  function tiktokRedirect(id) {
    callTiktokRedirect({ variables: { asset_id: id, type: redirectType } });
  }
  return { tiktokRedirect, loadingTiktokRedirect };
}

export default function TiktokRedirect({ redirectType }) {
  const params = useParams();
  const { id } = params;

  const {
    loading: loading_TIKTOK_REDIRECT,
    error: error_TIKTOK_REDIRECT,
    refetch: refetch_TIKTOK_REDIRECT,
  } = useCustomQuery(TIKTOK_REDIRECT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      console.log(e);
      window.open(e?.tiktok_redirect?.data?.url, "_self");
    },
    onFailure: (err) => {
      console.log(err.message);
    },
    variables: {
      asset_id: id,
      type: redirectType,
    },
  });

  if (loading_TIKTOK_REDIRECT) {
    return <Loader bgBlur fullScreen center />;
  }

  return (
    <div className="h-50 w-100 flex-center">
      <div>
        <h1 style={{ fontSize: "2rem" }}>Redirecting to {redirectType}...</h1>
        <div className="text-center">
          <Loader />
        </div>
      </div>
    </div>
  );
}
