import { createContext, useContext, useState } from "react";
import { SortableItem } from "./sortableHoc";
import LookModal from "components/LookModal";
import { SortableList } from "components/SortableList";

export const LookDragableOrderListContext = createContext(null);

export function useLookDragableOrderList() {
  const providerValues = useContext(LookDragableOrderListContext);
  return { ...providerValues };
}

/**
 * ? theme
 * * bordered-box - used in (CMS)
 */

export default function LookDragableOrderList(props) {
  const {
    theme = "bordered-box",
    value = [],
    onChange = () => {},
    isEditable = false,
    isRemove = false,
    switchAction = false,
  } = props;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletePropsData, setDeletePropsData] = useState();

  const onOpenDeleteModal = (deleteProps) => {
    setDeletePropsData(deleteProps);
    setDeleteModalVisible(true);
  };

  const onCloseDeleteModal = () => {
    setDeletePropsData();
    setDeleteModalVisible(false);
  };

  const setMenu = (index) => {
    const newItems = value?.map((data, ind) =>
      index === ind ? { ...data, is_menu: !data.is_menu } : data
    );
    onChange(newItems);
  };

  const removeItem = () => {
    const refreshValue = value?.filter((data, index) => deletePropsData !== index);
    onChange(refreshValue);
    setDeleteModalVisible(false);
  };

  const modalProps = {
    visible: deleteModalVisible,
    onCancel: onCloseDeleteModal,
    onSubmit: removeItem,
    okokText: "Delete",
  };

  const dragItems = () => {
    return (
      <div className="drag-item-wrapper">
        <LookModal {...modalProps}>
          <p> Are you sure you want to delete this item? </p>
        </LookModal>

        <SortableList
          items={value?.map((data) => ({ ...data }))}
          onChange={onChange}
          renderItem={(item, index) => (
            <SortableList.Item id={item.id}>
              <SortableItem
                key={`item-${item.id}`}
                index={index}
                itemData={item}
                mainIndex={index}
                remove={() => onOpenDeleteModal(index)}
                menuSet={() => setMenu(index)}
              />
            </SortableList.Item>
          )}
        />
        {/* <SortableContainer onSortEnd={onSortEnd}>
          {value?.map((item, index) => (
            <SortableItem
              key={`item-${item.id}`}
              index={index}
              itemData={item}
              mainIndex={index}
              remove={() => onOpenDeleteModal(index)}
              menuSet={() => setMenu(index)}
            />
          ))}
        </SortableContainer> */}
      </div>
    );
  };

  return (
    <LookDragableOrderListContext.Provider
      value={{ theme, setMainData: onChange, isEditable, isRemove, switchAction }}
    >
      <div className={`drag-theme-${theme}`}>
        <div className="position-relative LookDragableOrder">{dragItems()}</div>
      </div>
    </LookDragableOrderListContext.Provider>
  );
}
