import { Form, Select, Input, Button, Checkbox } from "antd";

const { Option } = Select;

function AddUserForm() {
  return (
    <Form layout="vertical">
      <div className="flex-between">
        <Form.Item label="First name" className="w-48">
          <Input placeholder="Enter your first name" />
        </Form.Item>
        <Form.Item label="Last name" className="w-48">
          <Input placeholder="Enter your last name" />
        </Form.Item>
      </div>
      <Form.Item label="Email Id">
        <Input placeholder="Enter email id" />
      </Form.Item>
      <Form.Item label="Password">
        <Input placeholder="Enter password" />
      </Form.Item>
      <div className="flex-between">
        <Form.Item label="Workspace" className="w-48">
          <Select
            placeholder="Select workspace"
            // onChange={handleChange}
          >
            <Option value="Developer">Developer</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Workspace Role" className="w-48">
          <Select
            placeholder="Select role in workspace"
            //  onChange={handleChange}
          >
            <Option value="12">12</Option>
          </Select>
        </Form.Item>
      </div>
      <div className="mb-10 ta-left">
        <Checkbox checked="true">Send login info in email</Checkbox>
      </div>
      <div className="mb-20 ta-left">
        <Checkbox>Ask user to change password after first login</Checkbox>
      </div>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ backgroundColor: "#52C41A", color: "#ffffff" }}
          className="light-bold-clr"
        >
          Add User
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ backgroundColor: "#ffffff", color: "#52C41A", borderColor: "#52C41A" }}
          className="light-bold-clr"
        >
          Skip for now
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AddUserForm;
