import {
  FontColorsOutlined,
  ApartmentOutlined,
  UserAddOutlined,
  ClusterOutlined,
  BankOutlined,
  ShopOutlined,
  GoldOutlined,
} from "@ant-design/icons";
import {
  CMS_MANAGE_AWIC_COMPANY,
  CMS_MANAGE_AWIC_NETWORK,
  CMS_MANAGE_AWIC_NETWORK_ACCOUNT,
  CMS_MANAGE_AWIC_PAYMENT,
  CMS_MANAGE_AWIC_POC,
  CMS_MANAGE_AWIC_STORE_EVERFLOW,
} from "../../UserPermission/userPermissionList";

const awicPath = [
  {
    name: "AWIC",
    link: "/awic",
    icon: FontColorsOutlined,
    subMenu: [
      {
        subMenuName: "Network",
        subMenuLink: "/network",
        subMenuIcon: ApartmentOutlined,
        permission: CMS_MANAGE_AWIC_NETWORK,
      },
      {
        subMenuName: "Companies",
        subMenuLink: "/companies",
        subMenuIcon: GoldOutlined,
        permission: CMS_MANAGE_AWIC_COMPANY,
      },
      {
        subMenuName: "Payment Details",
        subMenuLink: "/payment-details",
        subMenuIcon: BankOutlined,
        permission: CMS_MANAGE_AWIC_PAYMENT,
      },
      {
        subMenuName: "Network Accounts",
        subMenuLink: "/network-accounts",
        subMenuIcon: ClusterOutlined,
        permission: CMS_MANAGE_AWIC_NETWORK_ACCOUNT,
      },
      {
        subMenuName: "Point Of Contact",
        subMenuLink: "/point-of-contact",
        subMenuIcon: UserAddOutlined,
        permission: CMS_MANAGE_AWIC_POC,
      },
      {
        subMenuName: "Store",
        subMenuLink: "/store",
        subMenuIcon: ShopOutlined,
        permission: CMS_MANAGE_AWIC_STORE_EVERFLOW,
      },
    ],
  },
];

export default awicPath;
