import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  INDUSTRY_INSIGHTS_DELETE_NOTIFICATIONS,
  INDUSTRY_INSIGHTS_GET_NOTIFICATIONS,
  INDUSTRY_INSIGHTS_PAGE_NOTIFICATION_UPDATE,
  INDUSTRY_INSIGHTS_UPDATE_DEFAULT_NOTIFICATION,
  INDUSTRY_INSIGHTS_UPDATE_DOMAINS_NOTIFICATION,
} from "shared/gql/gqlSchema/industryInsightsGql";
import { openNotification } from "utils";
import PopoverWrapper from "components/popoverWrapper";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { Switch } from "antd";
import LookModal from "components/LookModal";
import NotifyMe from "../../NotifyMePopup";
import * as yup from "yup";
import {
  INDUSTRY_ISIGHTS_STATUS_ACTIVE,
  INDUSTRY_ISIGHTS_TYPE_DOMAIN,
} from "shared/enum/industryInsightsKeysAndEnums";

// import MainForm from "./MainForm";

function Notification() {
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const dateFormat = "YYYY-MM-DD";
  const defaultDateDaily = [moment().format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState(defaultDateDaily);
  const [timeZone, setTimeZone] = useState(dateRangeTimezone?.timeZone);
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);

  const [visibleNotifyMeModal, setVisibleNotifyMeModal] = useState(false);
  const [notifyAbout, setNotifyAbout] = useState([]);
  const [defaultTime, setDefaultTime] = useState("02:00");
  const [rowData, setRowData] = useState({});
  const [prefrences, setPrefrences] = useState([]);
  const [rowIds, setRowIds] = useState([]);
  const resetFormRef = useRef(() => {});

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const [getNotificationsData, setNotificationsData] = useState([]);

  const { loading: loading_GET_NOTIFICATIONS, refetch: refetch_GET_NOTIFICATIONS } = useCustomQuery(
    INDUSTRY_INSIGHTS_GET_NOTIFICATIONS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ii_Notifications?.data;
        if (element) {
          setNotificationsData(element);
        }
      },
      onError: (err) => {
        openNotification({ type: "error", message: err.message });
      },
      variables: {
        fromDate: rangeDates[0],
        toDate: rangeDates[1],
      },
    }
  );

  const loading = loading_GET_NOTIFICATIONS;

  const refetch = () => {
    refetch_GET_NOTIFICATIONS();
  };

  const [
    updateDomianNotification,
    { loading: loading_updateDomianNotification, refetch: refetch_updateDomianNotification },
  ] = useCustomMutation(INDUSTRY_INSIGHTS_UPDATE_DOMAINS_NOTIFICATION, {
    onCompleted: (e) => {
      const element = e?.ii_updateKeyDomNotification;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        refetch();
        onCloseNotifyMeFormModal();
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [
    updatePageNotification,
    { loading: loading_updatePageNotification, refetch: refetch_updatePageNotification },
  ] = useCustomMutation(INDUSTRY_INSIGHTS_PAGE_NOTIFICATION_UPDATE, {
    onCompleted: (e) => {
      const element = e?.ii_updatePageNotification;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        refetch();
        onCloseNotifyMeFormModal();
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [
    deleteNotification,
    { loading: loading_deleteNotification, refetch: refetch_deleteNotification },
  ] = useCustomMutation(INDUSTRY_INSIGHTS_DELETE_NOTIFICATIONS, {
    onCompleted: (e) => {
      const element = e?.ii_deleteNotification;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        refetch();
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const showNotifyMeFormModal = (rowData) => {
    setNotifyAbout([...(rowData?.notify_about || [])]);
    setPrefrences([...(rowData?.preference || [])]);
    setDefaultTime(rowData?.time || "02:00");
    setRowData(rowData);
    setVisibleNotifyMeModal(true);
  };

  const onCloseNotifyMeFormModal = () => {
    setVisibleNotifyMeModal(false);
    let defaultTime = "";
    setDefaultTime(defaultTime);
    setNotifyAbout([]);
    setPrefrences([]);
    setRowData({});
    setRowIds([]);
  };

  const notifyMeFormInitialValues = {
    frequency: rowData?.frequency,
    day: rowData?.day,
  };

  const notifyMeFormValidationSchema = yup.object({
    frequency: yup.string().required().label("Frequency"),
    day: yup.string().nullable().required().label("Day"),
  });

  function onNotifyFormValueSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const isDomain = rowData?.type?.toUpperCase() === INDUSTRY_ISIGHTS_TYPE_DOMAIN;
    const rowDataIds = isDomain
      ? { keyDomIds: rowData?.key_dom_id }
      : { pageIds: rowData?.key_dom_id };
    const addNotificationVaribles = {
      day: values?.day,
      defaultFrequency: values?.frequency,
      time: defaultTime,
      notifyAbout: notifyAbout,
      preference: prefrences,
      searchType: rowData?.type,
      ...rowDataIds,
    };

    isDomain
      ? updateDomianNotification({ variables: addNotificationVaribles })
      : updatePageNotification({ variables: addNotificationVaribles });
  }

  const notifyMeFormProps = {
    initialValues: notifyMeFormInitialValues,
    validationSchema: notifyMeFormValidationSchema,
    onSubmit: onNotifyFormValueSubmit,
    onClose: onCloseNotifyMeFormModal,
    notifyAbout: notifyAbout,
    setNotifyAbout,
    defaultType: "",
    prefrences: prefrences,
    setPrefrences,
    defaultTime,
    setDefaultTime,
    btnText: "Edit",
  };

  const updateNotification = (actionProps) => {
    const isDomain = actionProps?.type?.toUpperCase() === INDUSTRY_ISIGHTS_TYPE_DOMAIN;
    isDomain
      ? updateDomianNotification({
          variables: {
            toBeNotify: actionProps?.to_be_notify === INDUSTRY_ISIGHTS_STATUS_ACTIVE ? false : true,
            keyDomIds: actionProps?.key_dom_id,
          },
        })
      : updatePageNotification({
          variables: { toBeNotify: !actionProps?.to_be_notify, pageIds: actionProps?.key_dom_id },
        });
  };

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps, { index }) => {
        const poverWrapperOptions = [
          {
            option: "notification",
            title: (
              <>
                Pause Notification
                <Switch
                  size="small"
                  className="ml-4"
                  checked={actionProps?.to_be_notify === INDUSTRY_ISIGHTS_STATUS_ACTIVE}
                  onChange={(e) => ""}
                />
              </>
            ),
            optionProps: {
              onClick: () => {
                updateNotification(actionProps);
              },
            },
          },
          {
            option: "notification",
            title: "Notify me",
            optionProps: {
              onClick: () => showNotifyMeFormModal(actionProps),
            },
          },
          {
            option: "delete",
            title: "Delete",
            optionProps: {
              onClick: () =>
                deleteNotification({
                  variables: {
                    notificationId: actionProps?.id,
                    searchType: actionProps?.type.toUpperCase(),
                  },
                }),
            },
          },
        ];
        return (
          <>
            <PopoverWrapper options={poverWrapperOptions}>
              <EllipsisOutlined className="pop-info" />
            </PopoverWrapper>
          </>
        );
      },
      fixed: "right",
      width: 124,
      minWidth: 124,
    },
  ];
  const initialColumns = [
    {
      title: "Sr. No",
      dataIndex: "srNumber",
      key: "srNumber",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 60,
      minWidth: 60,
      searchType: "string",
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      searchType: "string",
    },
    {
      title: "Entity Name",
      dataIndex: "entity_name",
      key: "entity_name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.entity_name?.localeCompare(b?.entity_name),
      searchType: "string",
    },
    {
      title: "Last Alert",
      dataIndex: "last_alert",
      key: "last_alert",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: (a, b) => new Date(a.last_alert) - new Date(b.last_alert),
      searchType: "string",
    },

    {
      title: "Created On",
      dataIndex: "created_on",
      key: "created_on",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      searchType: "string",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      searchType: "string",
    },
    {
      title: "Status",
      dataIndex: "to_be_notify",
      key: "to_be_notify",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      searchType: "string",
    },
    ...action,
  ];

  const finalMergeData = [];
  getNotificationsData?.forEach((element, index) => {
    const {
      type = "",
      entity_name = "",
      last_alert = "",
      created_on = "",
      frequency = "",
      to_be_notify = false,
    } = element;

    finalMergeData.push({
      ...element,
      key: index,
      type,
      entity_name,
      last_alert: moment(last_alert).format("YYYY-MM-DD"),
      created_on: moment(created_on).format("YYYY-MM-DD"),
      frequency: frequency?.split(".")?.[1],
      to_be_notify: to_be_notify ? "ACTIVE" : "INACTIVE",
      srNumber: index + 1,
    });
  });

  const dataRender = outerFilterData(filterData(finalMergeData), {
    filterKeys: ["type"],
  });
  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    isRecommendation: true,
    defaultPagination: true,
    isRowSelection: false,
    refetch,
    loading,
    tableProps: {
      initialColumns,
      recordData: dataRender,
    },
    persistKey,
    dateRangeProps: {
      noStore: true,
      defaultDate: rangeDates,
      onChange: handleDateRange,
    },
  };
  const notifyMeModalProps = {
    title: "Edit frequency",
    visible: visibleNotifyMeModal,
    footer: null,
    onCancel: onCloseNotifyMeFormModal,
  };
  return (
    <div>
      <DashboardHeader title="Industry Insights" />
      <LookTable {...tableMainProps} />
      {visibleNotifyMeModal && (
        <LookModal {...notifyMeModalProps}>
          <NotifyMe {...notifyMeFormProps} />
        </LookModal>
      )}
    </div>
  );
}

export default Notification;
