import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import alasql from "alasql";
import { Button, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import SelectInput from "components/forms/SelectInput";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useApiToStore } from "hooks";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GET_TIKTOK_USER_REPORT, GET_VOLUUM_USER_REPORT } from "shared/gql/gqlSchema/tiktok";
import columns from "./columns";

function TiktokLeadgenUserReports(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude, searchFilter } = userSettings || {};
  const { userReport = false, type = "" } = props;
  const params = useParams();
  const [spendReport, setSpendReport] = useState([]);
  const [userReports, setUserReports] = useState([]);
  const userIdForSpendReport = userReport ? { userId: params?.userId } : {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const [selectedVendor, setSelectedVendor] = useState("all");
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);
  const { sasVendors_dropdown = [] } = userSettingData || {};
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const [rowPropsData, setRowProps] = useState({});

  let finalMerge = [];
  let finalMergeData = [];

  const { loading: loading_GET_VOLUUM_USER_REPORT, refetch: refetch_GET_VOLUUM_USER_REPORT } =
    useCustomQuery(GET_VOLUUM_USER_REPORT, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.voluum_get_user_report;
        if (element) {
          setUserReports(element);
        }
      },
      variables: {
        from: dateRangeTimezone?.rangeDates[0],
        to: dateRangeTimezone?.rangeDates[1],
        timezone: dateRangeTimezone?.timeZone?.value,
        platform_id: 7,
        is_detailed: true,
      },
    });

  const {
    data: data_GET_TIKTOK_USER_REPORT,
    loading: loading_GET_TIKTOK_USER_REPORT,
    refetch: refetch_GET_TIKTOK_USER_REPORT,
  } = useCustomQuery(GET_TIKTOK_USER_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktok_getUserReport;
      if (element) {
        setSpendReport(element);
      }
    },
    variables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
    },
  });

  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone?.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };

  const onCloseDetailDrawer = () => {
    setRowProps({});
    setVisibleDetailDrawer(false);
  };

  const drawerPropsUpdate = {
    title: "User Report",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const refetch = () => {
    refetch_GET_VOLUUM_USER_REPORT();
    refetch_GET_TIKTOK_USER_REPORT();
  };

  const loading = loading_GET_VOLUUM_USER_REPORT || loading_GET_TIKTOK_USER_REPORT;

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, { userId }) => (
        <div className="text-center">
          {userId && (
            <Tooltip title="View Details">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="export" />}
                onClick={() =>
                  window.open(`/sas/reports/tiktok-daily/user-report/${userId}/ad-account`)
                }
              />
            </Tooltip>
          )}
        </div>
      ),
      fixed: "right",
      width: 100,
      minWidth: 100,
    },
  ];

  const initialColumns = [
    {
      title: "User",
      dataIndex: "userName",
      key: "userName",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={value}
          {...otherProps}
          isClick={() => showDrawerUpdate(otherProps)}
        />
      ),
      width: 170,
      minWidth: 130,
      sorter: true,
      fixed: "left",
      searchType: "string",
    },
    ...columns,
    // ...action,
  ];

  if (!loading) {
    let query = "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.userId = b.user_id";
    finalMerge = alasql(query, [spendReport, userReports]);

    finalMerge?.forEach((element, index) => {
      const {
        spend = 0,
        clicksTiktok = 0,
        impressionsTiktok = 0,
        revenue = 0,
        searches = 0,
        clicks = 0,
        category = {},
        userName = "",
        user = {},
      } = element;

      const spendUpdate = spend.toFixed(2);
      const PROFIT = parseFloat(revenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CPC = parseFloat(spendUpdate) / parseFloat(clicks);
      const CPR_V = parseFloat(spendUpdate) / parseFloat(clicks);

      const RPV = parseFloat(revenue) / parseFloat(clicksTiktok);
      const RPL = parseFloat(revenue) / parseFloat(clicks);
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksTiktok);

      const AD_CTR = (parseFloat(clicksTiktok) / parseFloat(impressionsTiktok)) * 100 || 0;
      const LP_CTR = (parseFloat(searches) / parseFloat(clicksTiktok)) * 100;
      const CR = (parseFloat(clicks) / parseFloat(clicksTiktok)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsTiktok)) * 1000;

      finalMergeData.push({
        ...element,
        key: index,
        impressionsTiktok,
        spend: spendUpdate,
        categoryName: category?.name,
        userName: userName || user?.name,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        CPV: valueCheck(CPV),
        CPC: valueCheck(CPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        RPL: valueCheck(RPL),
        CPR_V: valueCheck(CPR_V),
        RPV: valueCheck(RPV),
      });
    });
  }

  const filterWithoutRevenueAndSpend = finalMergeData?.filter(
    ({ spend = 0, revenue = 0 }) => spend != 0 || revenue != 0
  );

  const finalFilterData = filterData(filterWithoutRevenueAndSpend).filter(({ userName = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (userName?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_spend = 0,
    total_clicksTikTok = 0,
    total_impressionsTiktok = 0,
    total_revenue = 0,
    total_clicks = 0,
    total_searches = 0,
    total_sessions = 0;

  for (const {
    spend = 0,
    clicksTiktok = 0,
    impressionsTiktok = 0,
    revenue = 0,
    clicks = 0,
    searches = 0,
    sessions = 0,
  } of finalFilterData) {
    total_spend += parseFloat(spend || 0);
    total_clicksTikTok += parseFloat(clicksTiktok || 0);
    total_impressionsTiktok += parseFloat(impressionsTiktok || 0);
    total_revenue += parseFloat(revenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_searches += parseFloat(searches || 0);
    total_sessions += parseFloat(sessions || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spend);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spend)) * 100 || 0;
  const total_CPV = parseFloat(total_spend) / parseFloat(total_clicksTikTok);
  const total_CPC = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_AD_CTR =
    (parseFloat(total_clicksTikTok) / parseFloat(total_impressionsTiktok)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_searches) / parseFloat(total_clicksTikTok)) * 100;
  const total_CPM = (parseFloat(total_spend) / parseFloat(total_impressionsTiktok)) * 1000;
  const total_CPR_V = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_RPV = parseFloat(total_revenue) / parseFloat(total_clicksTikTok);
  const total_RPL = parseFloat(total_revenue) / parseFloat(total_clicks);
  const total_CR = (parseFloat(total_clicks) / parseFloat(total_clicksTikTok)) * 100;

  const summaryData = {
    spend: valueCheck(total_spend, true),
    clicksTikTok: valueCheck(total_clicksTikTok, true),
    impressionsTiktok: valueCheck(total_impressionsTiktok),
    revenue: valueCheck(total_revenue, true),
    clicks: valueCheck(total_clicks, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    CPV: valueCheck(total_CPV, true),
    CPC: valueCheck(total_CPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    searches: valueCheck(total_searches),
    sessions: valueCheck(total_sessions),
    CPR_V: valueCheck(total_CPR_V, true),
    RPV: valueCheck(total_RPV, true),
    // CPL: valueCheck(total_CPL, true),
    RPL: valueCheck(total_RPL, true),
  };

  const rightOptions = (
    <>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Vendor">
          <span>
            <SelectInput
              search
              name="selectedVendor"
              className="mb-0"
              placeholder="Select Vendor"
              value={selectedVendor}
              options={[
                { name: "All", value: "all" },
                ...sasVendors_dropdown?.map(({ id, name }) => ({ name, value: id })),
              ]}
              onChange={setSelectedVendor}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );

  const tableMainProps = {
    isSearchFilter: true,
    searchInclude: true,
    filterProps: { subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value" },
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    rightOptions,
    // bulkActions,
    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    // uniqueKey,
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ userReports }, { spendReport }],
    },
  ];

  return (
    <div>
      {/* <DetailView {...drawerPropsUpdate} {...rowPropsData} type={type} /> */}
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default TiktokLeadgenUserReports;
