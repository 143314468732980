import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { numberParse } from "utils";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import GlobalReportDetailView from "./DetailView";
import { LEADGEN_GLOBAL_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import cx from "cx";

function GlobalReport(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const [globalReport, setGlobalReport] = useState([]);
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const [rowPropsData, setRowProps] = useState({});
  const [rangeDates, setRangeDates] = useState(dateRangeTimezone?.rangeDates);
  const [timeZone, setTimeZone] = useState(dateRangeTimezone?.timeZone);
  const { filterData } = useLookTableFilterData(persistKey);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  let finalMergeData = [];

  const { loading: loading_LEADGEN_GLOBAL_REPORT, refetch: refetch_LEADGEN_GLOBAL_REPORT } =
    useCustomQuery(LEADGEN_GLOBAL_REPORT, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.leadgen_globalReport;
        if (element) {
          setGlobalReport(element);
        }
      },
      variables: {
        from: rangeDates[0],
        to: rangeDates[1],
        timezone: timeZone?.value,
      },
    });

  const showDetailDrawer = (rowProps = {}) => {
    setRowProps({
      ...rowProps,
    });
    setVisibleDetailDrawer(true);
  };

  const onCloseDetailDrawer = () => {
    setRowProps({});
    setVisibleDetailDrawer(false);
  };

  const drawerPropsUpdate = {
    title: "User Report",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const refetch = () => {
    refetch_LEADGEN_GLOBAL_REPORT();
  };
  const loading = loading_LEADGEN_GLOBAL_REPORT;

  const initialColumns = [
    {
      title: "Category name",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (value, otherProps) => {
        return (
          <LookTableColumnRender
            title={value}
            {...otherProps}
            isClick={() => showDetailDrawer(otherProps)}
          />
        );
      },
      fixed: "left",
      width: 190,
      minWidth: 190,
      searchType: "string",
    },
    {
      title: "FB Clicks",
      dataIndex: "f_clicks",
      key: "f_clicks",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDetailDrawer(otherProps)} />
      ),
      width: 170,
      minWidth: 130,
      sorter: (a, b) => a?.f_clicks - b?.f_clicks,
      searchType: "string",
    },
    {
      title: "FB Impressions",
      dataIndex: "f_impressions",
      key: "f_impressions",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDetailDrawer(otherProps)} />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.f_impressions - b?.f_impressions,
      searchType: "number",
    },

    {
      title: "FB Inline Link Clicks",
      dataIndex: "f_inline_link_clicks",
      key: "f_inline_link_clicks",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDetailDrawer(otherProps)} />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.f_inline_link_clicks - b?.f_inline_link_clicks,
      searchType: "number",
    },

    {
      title: "FB Results",
      dataIndex: "f_results",
      key: "f_results",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDetailDrawer(otherProps)} />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.f_results - b?.f_results,
      searchType: "number",
    },
    {
      title: "FB Revenue clicks",
      dataIndex: "f_rev_clicks",
      key: "f_rev_clicks",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDetailDrawer(otherProps)} />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.f_rev_clicks - b?.f_rev_clicks,
      searchType: "number",
    },
    {
      title: "FB Revenue conversions",
      dataIndex: "f_rev_conversions",
      key: "f_rev_conversions",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDetailDrawer(otherProps)} />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.f_rev_conversions - b?.f_rev_conversions,
      searchType: "number",
    },
    {
      title: "X Revenue",
      dataIndex: "rev_x_revenue",
      key: "rev_x_revenue",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={`$${numberParse(value, { toFixed: true })}`}
          isClick={() => showDetailDrawer(otherProps)}
        />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.rev_x_revenue - b?.rev_x_revenue,
      searchType: "number",
    },
    {
      title: "FB Payout Amount",
      dataIndex: "f_rev_payout_amount",
      key: "f_rev_payout_amount",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={`$${numberParse(value, { toFixed: true })}`}
          isClick={() => showDetailDrawer(otherProps)}
        />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.f_rev_payout_amount - b?.f_rev_payout_amount,
      searchType: "number",
    },
    {
      title: "FB Revenue",
      dataIndex: "f_rev_revenue",
      key: "f_rev_revenue",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={`$${numberParse(value, { toFixed: true })}`}
          isClick={() => showDetailDrawer(otherProps)}
        />
      ),
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.f_rev_revenue - b?.f_rev_revenue,
      searchType: "number",
    },
    {
      title: "FB Spend",
      dataIndex: "f_spend",
      key: "f_spend",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={`$${numberParse(value, { toFixed: true })}`}
          isClick={() => showDetailDrawer(otherProps)}
        />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.f_spend - b?.f_spend,
      searchType: "number",
    },
    {
      title: "FB Gross Margin",
      dataIndex: "FB_PROFIT",
      key: "FB_PROFIT",
      render: (value, otherProps) => {
        const profit = `$${value.toFixed(2)}`;
        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },

      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.FB_PROFIT - b?.FB_PROFIT,
      searchType: "number",
    },

    {
      title: "FB Revenue visits",
      dataIndex: "f_rev_visits",
      key: "f_rev_visits",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={numberParse(value)}
          isClick={() => showDetailDrawer(otherProps)}
        />
      ),
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.f_rev_visits - b?.f_rev_visits,
      searchType: "number",
    },
    {
      title: "Tiktok clicks",
      dataIndex: "t_clicks",
      key: "t_clicks",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.t_clicks - b?.t_clicks,
      searchType: "number",
    },
    {
      title: "Tiktok conversions",
      dataIndex: "t_conversions",
      key: "t_conversions",
      render: (value, otherProps) => <LookTableColumnRender title={value ?? 0} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.t_conversions - b?.t_conversions,
      searchType: "number",
    },
    {
      title: "Tiktok impressions",
      dataIndex: "t_impressions",
      key: "t_impressions",
      render: (value) => <LookTableColumnRender title={value ?? 0} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.t_impressions - b?.t_impressions,
      searchType: "number",
    },
    {
      title: "Tiktok reach",
      dataIndex: "t_reach",
      key: "t_reach",
      render: (value) => <LookTableColumnRender title={value ?? 0} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.t_reach - b?.t_reach,
      searchType: "number",
    },
    {
      title: "Tiktok Payout Amount",
      dataIndex: "t_rev_payout_amount",
      key: "t_rev_payout_amount",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={`$${numberParse(value, { toFixed: true })}`}
          isClick={() => showDetailDrawer(otherProps)}
        />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.t_rev_payout_amount - b?.t_rev_payout_amount,
      searchType: "number",
    },
    {
      title: "Tiktok Revenue",
      dataIndex: "t_rev_revenue",
      key: "t_rev_revenue",
      render: (value) => (
        <LookTableColumnRender title={`$${numberParse(value, { toFixed: true })}`} />
      ),
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.t_rev_revenue - b?.t_rev_revenue,
      searchType: "number",
    },
    {
      title: "Tiktok spend",
      dataIndex: "t_spend",
      key: "t_spend",
      render: (value) => (
        <LookTableColumnRender title={`$${numberParse(value, { toFixed: true })}`} />
      ),
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.t_spend - b?.t_spend,
      searchType: "number",
    },
    {
      title: "Tiktok Gross Margin",
      dataIndex: "TT_PROFIT",
      key: "TT_PROFIT",
      render: (value, otherProps) => {
        const profit = `$${value.toFixed(2)}`;
        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },

      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.TT_PROFIT - b?.TT_PROFIT,
      searchType: "number",
    },
    {
      title: "Tiktok Revenue clicks",
      dataIndex: "t_rev_clicks",
      key: "t_rev_clicks",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.t_rev_clicks - b?.t_rev_clicks,
      searchType: "number",
    },
    {
      title: "Tiktok Revenue conversions",
      dataIndex: "t_rev_conversions",
      key: "t_rev_conversions",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.t_rev_conversions - b?.t_rev_conversions,
      searchType: "number",
    },
    {
      title: "Tiktok Revenue visits",
      dataIndex: "t_rev_visits",
      key: "t_rev_visits",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.t_rev_visits - b?.t_rev_visits,
      searchType: "number",
    },
  ];

  if (!loading) {
    finalMergeData = globalReport?.map((element, index) => {
      const {
        f_clicks = 0,
        f_impressions = 0,
        f_inline_link_clicks = 0,
        f_results = 0,
        f_spend = 0,
        landing_url = "",
        f_rev_clicks = 0,
        f_rev_conversions = 0,
        f_rev_revenue = 0,
        f_rev_visits = 0,
        t_rev_clicks = 0,
        t_rev_conversions = 0,
        t_rev_revenue = 0,
        t_rev_visits = 0,
        t_clicks = 0,
        t_conversions = 0,
        t_impressions = 0,
        t_reach = 0,
        t_spend = 0,
        rev_x_revenue = 0,
        f_rev_payout_amount = 0,
        t_rev_payout_amount = 0,
        category = {},
      } = element;
      const FB_PROFIT = parseFloat(f_rev_revenue ?? 0) - parseFloat(f_spend);
      const TT_PROFIT = parseFloat(t_rev_revenue ?? 0) - parseFloat(t_spend);
      return {
        ...element,
        key: index,
        categoryName: category?.name,
        FB_PROFIT: numberParse(FB_PROFIT),
        TT_PROFIT: numberParse(TT_PROFIT),
        f_clicks,
        f_impressions,
        f_inline_link_clicks,
        f_results,
        f_spend,
        f_rev_clicks,
        f_rev_conversions,
        f_rev_revenue,
        f_rev_visits,
        t_rev_clicks,
        t_rev_conversions,
        t_rev_revenue,
        t_rev_visits,
        t_clicks,
        t_conversions,
        t_impressions,
        t_reach,
        t_spend,
        rev_x_revenue,
        f_rev_payout_amount,
        t_rev_payout_amount,
      };
    });
  }

  const finalFilterData = filterData(finalMergeData)?.filter(({ categoryName = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (categoryName?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_f_clicks = 0,
    total_f_impressions = 0,
    total_f_inline_link_clicks = 0,
    total_f_results = 0,
    total_f_spend = 0,
    total_f_rev_clicks = 0,
    total_f_rev_conversions = 0,
    total_f_rev_revenue = 0,
    total_f_rev_visits = 0,
    total_t_clicks = 0,
    total_t_conversions = 0,
    total_t_impressions = 0,
    total_t_reach = 0,
    total_t_spend = 0,
    total_t_rev_clicks = 0,
    total_t_rev_conversions = 0,
    total_t_rev_revenue = 0,
    total_t_rev_visits = 0,
    total_rev_x_revenue = 0,
    total_f_rev_payout_amount = 0,
    total_t_rev_payout_amount = 0;

  for (const {
    f_clicks = 0,
    f_impressions = 0,
    f_inline_link_clicks = 0,
    f_results = 0,
    f_spend = 0,
    f_rev_clicks = 0,
    f_rev_conversions = 0,
    f_rev_revenue = 0,
    f_rev_visits = 0,
    t_clicks = 0,
    t_conversions = 0,
    t_impressions = 0,
    t_reach = 0,
    t_spend = 0,
    t_rev_clicks = 0,
    t_rev_conversions = 0,
    t_rev_revenue = 0,
    t_rev_visits = 0,
    rev_x_revenue = 0,
    f_rev_payout_amount = 0,
    t_rev_payout_amount = 0,
  } of finalFilterData) {
    total_f_clicks += parseFloat(f_clicks || 0);
    total_f_impressions += parseFloat(f_impressions || 0);
    total_f_inline_link_clicks += parseFloat(f_inline_link_clicks || 0);
    total_f_results += parseFloat(f_results || 0);
    total_f_spend += parseFloat(f_spend || 0);
    total_f_rev_clicks += parseFloat(f_rev_clicks || 0);
    total_f_rev_conversions += parseFloat(f_rev_conversions || 0);
    total_f_rev_revenue += parseFloat(f_rev_revenue || 0);
    total_f_rev_visits += parseFloat(f_rev_visits || 0);
    total_t_clicks += parseFloat(t_clicks || 0);
    total_t_conversions += parseFloat(t_conversions || 0);
    total_t_impressions += parseFloat(t_impressions || 0);
    total_t_reach += parseFloat(t_reach || 0);
    total_t_spend += parseFloat(t_spend || 0);
    total_t_rev_clicks += parseFloat(t_rev_clicks || 0);
    total_t_rev_conversions += parseFloat(t_rev_conversions || 0);
    total_t_rev_revenue += parseFloat(t_rev_revenue || 0);
    total_t_rev_visits += parseFloat(t_rev_visits || 0);
    total_rev_x_revenue += parseFloat(rev_x_revenue || 0);
    total_f_rev_payout_amount += parseFloat(f_rev_payout_amount || 0);
    total_t_rev_payout_amount += parseFloat(t_rev_payout_amount || 0);
  }

  const total_FB_PROFIT = parseFloat(total_f_rev_revenue) - parseFloat(total_f_spend);
  const total_TT_PROFIT = parseFloat(total_t_rev_revenue) - parseFloat(total_t_spend);

  const summaryData = {
    f_clicks: numberParse(total_f_clicks),
    f_impressions: numberParse(total_f_impressions),
    f_inline_link_clicks: numberParse(total_f_inline_link_clicks, { toFixed: true }),
    f_results: numberParse(total_f_results),
    f_spend: numberParse(total_f_spend, { toFixed: true }),
    f_rev_clicks: numberParse(total_f_rev_clicks),
    f_rev_conversions: numberParse(total_f_rev_conversions),
    f_rev_revenue: numberParse(total_f_rev_revenue, { toFixed: true }),
    f_rev_visits: numberParse(total_f_rev_visits),
    t_clicks: numberParse(total_t_clicks),
    t_conversions: numberParse(total_t_conversions),
    t_impressions: numberParse(total_t_impressions),
    t_reach: numberParse(total_t_reach),
    t_spend: numberParse(total_t_spend, { toFixed: true }),
    t_rev_clicks: numberParse(total_t_rev_clicks),
    t_rev_conversions: numberParse(total_t_rev_conversions),
    t_rev_revenue: numberParse(total_t_rev_revenue, { toFixed: true }),
    t_rev_visits: numberParse(total_t_rev_visits),
    FB_PROFIT: numberParse(total_FB_PROFIT, { toFixed: true }),
    TT_PROFIT: numberParse(total_TT_PROFIT, { toFixed: true }),
    rev_x_revenue: numberParse(total_rev_x_revenue, { toFixed: true }),
    f_rev_payout_amount: numberParse(total_f_rev_payout_amount, { toFixed: true }),
    t_rev_payout_amount: numberParse(total_t_rev_payout_amount, { toFixed: true }),
  };

  const tableMainProps = {
    isSearchFilter: true,
    searchInclude: true,
    filterProps: { subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value" },
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    defaultPagination: true,
    refetch,
    loading,
    tableProps: {
      initialColumns,
      recordData: finalFilterData,
      summaryData,
    },
    persistKey,
    dateRangeProps: {
      onChange: handleDateRange,
    },
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ globalReport }, { finalMergeData }],
    },
  ];

  return (
    <div>
      <GlobalReportDetailView {...drawerPropsUpdate} {...rowPropsData} />
      <DashboardHeader title="Global Report" />
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default GlobalReport;
