export default function popupWindow(url, title, w, h) {
  var left = window.screen.width / 2 - w / 2;
  var top = window.screen.height / 2 - h / 2;
  return window.open(
    url,
    title,
    "toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );
}
