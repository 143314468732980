import { PINTEREST_USER } from "shared/UserPermission/userPermissionList";
import { CustomeIconWrapper } from "..";
import { PinterestIcon } from "../../../components/svgComponents";
import { UserOutlined, TeamOutlined } from "@ant-design/icons";

const pinterestPath = [
  {
    name: "Pinterest",
    link: "/pinterest",
    icon: () => (
      <CustomeIconWrapper>
        <PinterestIcon />
      </CustomeIconWrapper>
    ),
    subMenu: [
      {
        subMenuName: "Users",
        subMenuLink: "/users",
        subMenuIcon: UserOutlined,
        permission: PINTEREST_USER,
      },
      {
        subMenuName: "Accounts",
        subMenuLink: "/accounts",
        subMenuIcon: TeamOutlined,
        permission: PINTEREST_USER,
      },
    ],
  },
];

export default pinterestPath;
