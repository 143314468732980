import { Form, Select, Input, Button } from "antd";

const { Option } = Select;

function ContactInformationForm() {
  return (
    <Form layout="vertical">
      <Form.Item label="Address">
        <Input placeholder="Enter the address of your company" />
      </Form.Item>
      <div className="flex-between">
        <Form.Item label="Your Role" className="w-48">
          <Select
            defaultValue="+91"
            //  onChange={handleChange}
            style={{ width: "20%", marginRight: "2%" }}
          >
            <Option value="+90">+90</Option>
          </Select>
          <Input placeholder="Enter phone" style={{ width: "78%" }} />
        </Form.Item>
        <Form.Item label="Skype" className="w-48">
          <Input placeholder="Enter skype id" />
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ backgroundColor: "#52C41A", color: "#ffffff" }}
          className="light-bold-clr"
        >
          Save Contact Information
        </Button>
      </Form.Item>
    </Form>
  );
}
export default ContactInformationForm;
