import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "classnames";
import { numberParse } from "utils";

const columns = [
  {
    title: "Revenue",
    dataIndex: "TotalRevenue",
    key: "TotalRevenue",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.TotalRevenue - b?.TotalRevenue,
  },

  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    render: (value) => (
      <LookTableColumnRender title={<>${numberParse(value, { toFixed: true })}</>} />
    ),
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.spend - b?.spend,
  },
  {
    title: "Gross Margin",
    dataIndex: "PROFIT",
    key: "PROFIT",
    render: (value, otherProps) => {
      const profit = `$${value.toFixed(2)}`;
      // const tooltip = `$${value}`;
      return (
        <LookTableColumnRender
          title={
            <strong
              className={cx({
                "text-primary": parseFloat(value) > 0,
                "text-danger": parseFloat(value) < 0,
              })}
            >
              {profit.replace("-", "")}
            </strong>
          }
        />
      );
    },
    width: 100,
    minWidth: 100,
    sorter: (a, b) => a?.PROFIT - b?.PROFIT,
    searchType: "number",
  },
  {
    title: "ROI",
    dataIndex: "ROI",
    key: "ROI",
    render: (value) => (
      <LookTableColumnRender title={numberParse(value, { toFixed: true }) + "%"} />
    ),
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.ROI - b?.ROI,
  },
  {
    title: "EPC",
    dataIndex: "EPC",
    key: "EPC",
    render: (value) => (
      <LookTableColumnRender title={numberParse(value, { toFixed: true, toFixedUpTo: 3 })} />
    ),
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.EPC - b?.EPC,
  },
  {
    title: "CPC",
    dataIndex: "CPC",
    key: "CPC",
    render: (value) => (
      <LookTableColumnRender title={numberParse(value, { toFixed: true, toFixedUpTo: 3 })} />
    ),
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.CPC - b?.CPC,
  },
  {
    title: "CPC(FB)",
    dataIndex: "CPC_FB",
    key: "CPC_FB",
    render: (value) => (
      <LookTableColumnRender title={numberParse(value, { toFixed: true, toFixedUpTo: 3 })} />
    ),
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.CPC_FB - b?.CPC_FB,
  },
  {
    title: "CTR",
    dataIndex: "CTR",
    key: "CTR",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.CTR - b?.CTR,
  },
  {
    title: "FB Results",
    dataIndex: "result",
    key: "result",
    render: (value) => <LookTableColumnRender title={numberParse(value)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.result - b?.result,
  },
  {
    title: "CPR",
    dataIndex: "CPR",
    key: "CPR",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.CPR - b?.CPR,
  },
  {
    title: "Sessions",
    dataIndex: "sessions",
    key: "sessions",
    render: (value) => <LookTableColumnRender title={numberParse(value)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.sessions - b?.sessions,
  },
  {
    title: "Clicks",
    dataIndex: "clicksFB",
    key: "clicksFB",
    render: (value) => <LookTableColumnRender title={numberParse(value)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.clicksFB - b?.clicksFB,
  },
  {
    title: "Disc",
    dataIndex: "DISC",
    key: "DISC",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.DISC - b?.DISC,
  },
  {
    title: "PPS",
    dataIndex: "PPS",
    key: "PPS",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.PPS - b?.PPS,
  },
  {
    title: "IPP",
    dataIndex: "IPP",
    key: "IPP",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.IPP - b?.IPP,
    searchType: "number",
  },
  {
    title: "PRPM",
    dataIndex: "PRPM",
    key: "PRPM",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.PRPM - b?.PRPM,
  },
  {
    title: "Impressions (FB)",
    dataIndex: "impressionsFB",
    key: "impressionsFB",
    render: (value) => <LookTableColumnRender title={numberParse(value)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
  },
  {
    title: "CPM",
    dataIndex: "CPM",
    key: "CPM",
    render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.CPM - b?.CPM,
  },
];

export default columns;
