import { Button, Card, Image } from "antd";
import ActionIconRender from "components/ActionIconRender";
import placeholder from "assets/images/placeholder.png";
import DebugPre from "components/DebugPre";

export default function CardViralsparksBrandCard(props) {
  const { logo = "", brandName, updateBrand = () => {}, website, canManage = false } = props;

  return (
    <Card className="card-vs-brand theme-parent p-ch-0">
      <div className="image-view">
        <Image preview={false} src={logo || placeholder} alt={brandName} />
      </div>
      <div className="content-btn flex justify-content-around" style={{ minHeight: 32 }}>
        <div className="flex-center">
          <a href={website} target="_blank" rel="noreferrer">
            Preview
          </a>
        </div>
        {canManage && (
          <Button type="link" onClick={updateBrand} icon={<ActionIconRender iconType="setting" />}>
            Settings
          </Button>
        )}
      </div>
      <DebugPre content={[{ contentObjects: [{ props }] }]} />
    </Card>
  );
}
