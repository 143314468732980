import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { UPDATE_CAMPAIGN_BUDGET } from "shared/gql/gqlSchema/leadgenGql";
import { openNotification } from "utils";

export default function UpdateCapmaignBudget(props) {
  const { refetch = () => {}, campaignId = "", dailyBudget, lifeTimeBudget } = props;
  const [isError, setIsError] = useState("");

  const resetFormRef = useRef(() => {});
  const [updateCampaignBudget, { loading }] = useCustomMutation(UPDATE_CAMPAIGN_BUDGET, {
    onCompleted(e) {
      // refetch();
      // resetFormRef.current();
      const element = e?.updateCampaignBudget;
      openNotification({ type: element?.success ? "success" : "error", message: element?.message });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;

    const { total, isLifeTime } = values;

    const submitValues = {
      campaignFBId: campaignId,
      isLifeTime,
      budget: total,
    };
    console.log(submitValues);

    updateCampaignBudget({
      variables: {
        ...submitValues,
      },
    });
    clearError();
  }
  let budget = "";
  let isLifeTime = false;

  if (dailyBudget !== null && lifeTimeBudget !== null) {
    if (dailyBudget > lifeTimeBudget) {
      budget = dailyBudget;
      isLifeTime = false;
    } else if (dailyBudget < lifeTimeBudget) {
      budget = lifeTimeBudget;
      isLifeTime = true;
    }
  }
  if (dailyBudget && (lifeTimeBudget === undefined || lifeTimeBudget === null)) {
    budget = dailyBudget;
    isLifeTime = false;
  }
  if ((dailyBudget === undefined || dailyBudget === null) && lifeTimeBudget) {
    budget = lifeTimeBudget;
    isLifeTime = true;
  }

  const initialValues = {
    budget: 0,
    isLifeTime: isLifeTime,
    total: 0,
  };

  const validationSchema = yup.object({
    budget: yup.number().required().label("budget"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    btnText: "Update",
    budgetValue: budget,
  };

  return (
    <>
      <MainFrom {...formProps} />
      {/* <pre>{JSON.stringify({ props }, null, 2)}</pre> */}
    </>
  );
}
