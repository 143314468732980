import React, { useEffect, useRef, useState } from "react";
import { PinturaEditor } from "@pqina/react-pintura";
import "@pqina/pintura/pintura.css";
import { getEditorDefaults } from "@pqina/pintura";
import video from "./Example.mp4";
// Import Pintura Video extension dependencies
import {
  setPlugins,
  createDefaultImageWriter,
  createDefaultMediaWriter,
  imageStateToCanvas,
} from "@pqina/pintura";

import "@pqina/pintura-video/pinturavideo.css";
import {
  plugin_trim_locale_en_gb,
  plugin_trim,
  createDefaultVideoWriter,
  createMediaStreamEncoder,
} from "@pqina/pintura-video";
import { numberParse, openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { GET_BLOB_FOR_CREATIVES } from "shared/gql/gqlSchema/umsGql";
import { Row } from "antd";
import LookButton from "components/LookButton";
import ActionIconRender from "components/ActionIconRender";
import Loader from "components/loader";
import useUploadFiles from "../CreativeUpload/useUploadFiles";
import { MEDIA_ADD_VIDEO_CLIP } from "shared/gql/gqlSchema/creativeDashboardGql";
import axios from "axios";

setPlugins(plugin_trim);

export default function EditVideo(props) {
  const {
    videoData = {},
    setSelectedVideo = () => {},
    selectedVideo = [],
    setLoading = () => {},
    loading = false,
    openPintura = false,
    result,
    setResult = () => {},
    trimmingDuration = "",
    categoryId = "",
    productId = "",
    categoryName = "",
    setMedia = () => {},
    setPinturaOpen = () => {},
    setMainJson = () => {},
    creativeBuilderV2,
  } = props;
  const canvasRef = useRef();
  const videoRef = useRef();
  const [duration, setDuration] = useState(0);
  const [trimmingPercentage, setTrimmingPercentage] = useState(1);
  const editorRef = useRef(null);

  function durationToEndPercentage(trimmingDuration, templateDuration) {
    const trimDuration = parseFloat(trimmingDuration);
    const totalDuration = templateDuration;

    if (isNaN(trimDuration) || isNaN(totalDuration) || trimDuration < 0 || totalDuration <= 0) {
      return 1;
    }
    const endPercentage = trimDuration / totalDuration;
    if (endPercentage === 1) {
      return 1;
    }
    const trimmingLength = parseFloat(endPercentage.toFixed(2));
    return trimmingLength;
  }

  useEffect(() => {
    const endPercentage = durationToEndPercentage(videoData?.voiceoverDuration, duration);
    setTrimmingPercentage(endPercentage);
  }, [duration]);

  const [videoBlobData, setVideoBlobData] = useState("");

  const videoUrl = videoData?.r2Url || videoData?.urlData;
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const response = await axios.get(videoUrl, { responseType: "arraybuffer" });
        const imageBuffer = Buffer.from(response.data, "binary");
        const base64Image = imageBuffer.toString("base64");
        const imageUrl = base64ToImageUrl(base64Image, mimeType);

        setVideoBlobData(imageUrl);
      } catch (error) {
        console.error("Error fetching video data:", error);
      }
    };

    fetchVideoData();
  }, [videoUrl]);

  const editorDefaults = getEditorDefaults({
    // src: { video },

    imageTrim: [[0, trimmingPercentage]],
    onLoad: (media) => {
      setDuration(media.duration);
    },
    locale: {
      ...plugin_trim_locale_en_gb,
    },
    imageWriter: createDefaultMediaWriter(
      {
        targetSize: {
          width: 400,
        },
      },
      [
        createDefaultImageWriter(),
        createDefaultVideoWriter({
          encoder: createMediaStreamEncoder({
            imageStateToCanvas,
          }),
        }),
      ]
    ),
  });

  const mimeType = "video/mp4";

  function base64ToImageUrl(base64String, mimeType) {
    return `data:${mimeType};base64,${base64String}`;
  }

  const getImageBlob = async () => {
    let linkData = [];
    try {
      const response = await fetch(videoUrl);
      if (response.ok) {
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result;
          const base64Data = base64Image.split(",")[1]; // Extract base64 data

          // Use the base64Data to render image
          const videoData = base64ToImageUrl(base64Data, mimeType);
          setSelectedVideo(videoData);
          setLoading(false);
        };
        reader.readAsDataURL(blob);
      } else {
      }
    } catch (error) {
      linkData.push(videoData?.urlData);
    }
    if (linkData?.length > 0) {
      // getBlob({ variables: { urls: videoUrl } });
    }
  };

  useEffect(() => {
    setResult("");
    setLoading(true);
    getImageBlob();
  }, [openPintura]);

  const { handleBlobUpload, uploadLoading } = useUploadFiles({});

  const s3FileUploadThrougBlob = (blobData) => {
    return new Promise((resolve, reject) => {
      handleBlobUpload(
        blobData?.map(({ blob }) => blob),
        blobData?.map(({ url }, i) => url)
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const [clAddVideo, { loading: loading_Add, refetch: Add_refetch }] = useCustomMutation(
    MEDIA_ADD_VIDEO_CLIP,
    {
      onCompleted: (e) => {
        const element = e?.ctAddVideoClip;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Video Trimmed Successfully",
            key: "success",
          });

          setMedia((prevMedia) => {
            const updatedItems = [...prevMedia];
            const pinturaIndex = videoData?.pinturaIndex;
            const index = updatedItems?.[pinturaIndex]?.findIndex(
              (mediaData) => mediaData?.id === videoData?.id
            );

            if (index !== -1) {
              const updatedItem = {
                ...updatedItems?.[pinturaIndex]?.[index],
                id: element?.data?.[0]?.id,
                urlData: element?.data?.[0]?.r2Url,
              };

              updatedItems[pinturaIndex][index] = updatedItem;
            }

            return updatedItems;
          });

          setLoading(false);
          setPinturaOpen(false);
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const captureCanvasFrame = (videoElement, canvasElement) => {
    return new Promise((resolve, reject) => {
      const canvasContext = canvasElement.getContext("2d");
      canvasContext.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

      canvasElement.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          openNotification({
            type: "error",
            message: "Something is wrong, Please add video again",
          });
          reject();
        }
      });
    });
  };

  const generateThumbnailFromUrl = async (videoUrl) => {
    return new Promise(async (resolve, reject) => {
      try {
        const videoElement = document.createElement("video");
        videoElement.crossOrigin = "anonymous";
        videoElement.oncanplaythrough = async () => {
          const canvasElement = document.createElement("canvas");
          document.body.appendChild(canvasElement);

          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;

          const capturedBlob = await captureCanvasFrame(videoElement, canvasElement);

          // Clean up video element and canvas
          videoElement.pause();
          videoElement.src = "";
          videoElement.load();
          document.body.removeChild(canvasElement);

          const thumbnailUrl = URL.createObjectURL(capturedBlob);
          resolve({ blob: capturedBlob, thumbnailUrl });
        };

        videoElement.onerror = (error) => {
          reject(error);
        };

        videoElement.src = videoUrl;
        videoElement.muted = true;
        videoElement.play();
      } catch (error) {
        reject(error);
      }
    });
  };

  const getImageResponse = (videoResponse, thumbResponse, duration) => {
    const r2Urls = {
      r2Urls: videoResponse?.map(({ public_url }) => ({
        videoUrl: public_url,
        thumbUrl: thumbResponse?.[0]?.public_url,
      })),
    };

    if (creativeBuilderV2) {
      setMainJson((prevMedia) => {
        const updatedItems = [...prevMedia];
        const pinturaIndex = videoData?.pinturaIndex;
        if (pinturaIndex < updatedItems.length) {
          const index = updatedItems?.[pinturaIndex]?.video?.findIndex((mediaData) => {
            return mediaData?.id === videoData?.id;
          });
          if (index !== -1) {
            const timeStamp = new Date().getMilliseconds();
            const updatedVideo = {
              id: timeStamp,
              r2Url: videoResponse?.[0]?.public_url,
              duration: parseFloat(duration),
              trimmed: true,
            };
            updatedItems[pinturaIndex] = {
              ...updatedItems[pinturaIndex],
              video: [
                updatedVideo, // Insert the updated video
                ...updatedItems?.[pinturaIndex]?.video, // Keep items after the replaced one
              ],
            };
          }
        }
        return [...updatedItems];
      });
      setLoading(false);
      setPinturaOpen(false);
    } else {
      clAddVideo({
        variables: { ...r2Urls, categoryId, categoryName, productId },
      });
    }
  };

  const uploadModifiedImage = async ({ data, imageState = {}, totalVideoDuration = 0 }) => {
    setLoading(true);
    const start = imageState?.trim?.[0]?.[0] * totalVideoDuration;
    const end = imageState?.trim?.[0]?.[1] * totalVideoDuration;
    const duration = numberParse(end - start, { toFixed: true });

    try {
      fetch(data)
        .then((response) => response.blob())
        .then(async (blob) => {
          const timestamp = Date.now();
          const urlName = `modified-video-${timestamp}.mp4`;

          const resolvedBLOB = await s3FileUploadThrougBlob([{ blob: blob, url: urlName }]);

          // Generate thumbnail from the video URL
          generateThumbnailFromUrl(resolvedBLOB?.[0]?.public_url)
            .then(async (result) => {
              const thumbName = `modified-image-${timestamp}.png`;
              const resolvedThumbBLOB = await s3FileUploadThrougBlob([
                { blob: result.blob, url: thumbName },
              ]);
              // Send both responses to getImageResponse
              getImageResponse(resolvedBLOB, resolvedThumbBLOB, duration);
            })
            .catch((error) => {
              console.error("Thumbnail generation error:", error);
            });
        })
        .catch((error) => console.error("Blob creation error:", error));
    } catch (err) {
      console.error("Error in uploadModifiedImage:", err);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
          <Loader />
        </div>
      ) : (
        <div style={{ height: "70vh" }}>
          <PinturaEditor
            ref={editorRef}
            {...editorDefaults}
            // src={selectedVideo}
            src={videoBlobData}
            imageCropAspectRatio={1}
            onProcess={({ dest, imageState }) => {
              const totalVideoDuration = editorRef?.current?.editor?.getDuration();
              uploadModifiedImage({
                data: dest && URL?.createObjectURL(dest),
                imageState,
                totalVideoDuration: totalVideoDuration,
              });
            }}
            trimEnableFramePreview
            trimEnableSplit={false}
            utils={["trim"]}
            trimEnableMediaClipper
          />

          <canvas ref={canvasRef} style={{ width: "0px", height: "0px" }} />
        </div>
      )}

      {!!result.length && (
        <div>
          <Row justify="center" align="middle">
            {" "}
            <h1>Trimmed Output</h1>
          </Row>
          <Row justify="center" align="end">
            {" "}
            <LookButton
              onClick={() => {
                setResult("");
              }}
            >
              reset
              <ActionIconRender iconType="sync" />
            </LookButton>
          </Row>

          <Row justify="center" align="middle">
            <p>
              <video controls src={result} />
            </p>
          </Row>
        </div>
      )}
    </div>
  );
}
