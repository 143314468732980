import { PRODUCT_ID_LEADGEN, PRODUCT_ID_RSOC, PRODUCT_ID_SAS } from "shared/SharedKeys";
//these are the type of formaulae enums, comaprision type or rule type
export const RULE_ENGINE_FORMULAE_TYPE_COMAPARISION = "comparsion_fields";
export const RULE_ENGINE_FORMULAE_TYPE_RULE = "rule_fields";
export default function formulaeEnum(productId, type) {
  const sasFormulaes = [
    { name: "P ROAS", value: "$.PROI" },
    { name: "P Revenue", value: "$.PREVENUE" },
    { name: "P Margin", value: "$.PPROFIT" },
    { name: "RPL", value: "$.RPL" },
    { name: "RPV", value: "$.RPV" },
    { name: "RPC", value: "$.RPC" },
    { name: "CPL", value: "$.CPL" },
    { name: "CPV", value: "$.CPV" },
    { name: "BenchMark RPL", value: "$.BRPL" },
    { name: "BenchMark LPCTR", value: "$.BLPCTR" },
    { name: "BenchMark CPC", value: "$.BCPC" },
    { name: "C_INDEX", value: "$.C_INDEX" },
  ];
  const sasFormulaes_comparision = [
    { name: "P ROAS", value: "PROI" },
    { name: "P Revenue", value: "PREVENUE" },
    { name: "P Margin", value: "PPROFIT" },
    { name: "RPL", value: "RPL" },
    { name: "RPV", value: "RPV" },
    { name: "RPC", value: "RPC" },
    { name: "CPL", value: "CPL" },
    { name: "CPV", value: "CPV" },
    { name: "BenchMark RPL", value: "BRPL" },
    { name: "BenchMark LPCTR", value: "BLPCTR" },
    { name: "BenchMark CPC", value: "BCPC" },
    { name: "C_INDEX", value: "C_INDEX" },
  ];
  const rsocFormulaes = [
    { name: "RPL", value: "$.RPL" },
    { name: "RPV", value: "$.RPV" },
    { name: "RPC", value: "$.RPC" },
    { name: "CPL", value: "$.CPL" },
    { name: "CPV", value: "$.CPV" },
    { name: "BenchMark RPL", value: "$.BRPL" },
    { name: "BenchMark LPCTR", value: "$.BLPCTR" },
    { name: "BenchMark CPC", value: "$.BCPC" },
    { name: "C_INDEX", value: "$.C_INDEX" },
  ];
  const rsocFormulaes_comparision = [
    { name: "RPL", value: "RPL" },
    { name: "RPV", value: "RPV" },
    { name: "RPC", value: "RPC" },
    { name: "CPL", value: "CPL" },
    { name: "CPV", value: "CPV" },
    { name: "BenchMark RPL", value: "BRPL" },
    { name: "BenchMark LPCTR", value: "BLPCTR" },
    { name: "BenchMark CPC", value: "BCPC" },
    { name: "C_INDEX", value: "C_INDEX" },
  ];
  const lgFormulaes = [
    { name: "Visits", value: "$.visits" },
    { name: "CPM", value: "$.CPM" },
    { name: "RPC", value: "$.RPC" },
    { name: "Call Count", value: "$.callCount" },
    { name: "EPC", value: "$.EPC" },
    { name: "CPV", value: "$.CPV" },
  ];
  const lgFormulaes_comparision = [
    { name: "Visits", value: "visits" },
    { name: "CPM", value: "CPM" },
    { name: "RPC", value: "RPC" },
    { name: "Call Count", value: "callCount" },
    { name: "EPC", value: "EPC" },
    { name: "CPV", value: "CPV" },
  ];
  const vsFormulaes = [
    { name: "CTR", value: "$.CTR" },
    { name: "EPC", value: "$.EPC" },
    { name: "IPP", value: "$.IPP" },
    { name: "RPM", value: "$.RPM" },
    { name: "CPM", value: "$.CPM" },
    { name: "PPS", value: "$.PPS" },
    { name: "PRPM", value: "$.PRPM" },
    { name: "CPLC", value: "$.CPLC" },
    { name: "Disc", value: "$.Disc" },
  ];
  const vsFormulaes_comparision = [
    { name: "CTR", value: "CTR" },
    { name: "EPC", value: "EPC" },
    { name: "IPP", value: "IPP" },
    { name: "RPM", value: "RPM" },
    { name: "CPM", value: "CPM" },
    { name: "PPS", value: "PPS" },
    { name: "PRPM", value: "PRPM" },
    { name: "Disc", value: "Disc" },
  ];
  if (type === RULE_ENGINE_FORMULAE_TYPE_COMAPARISION) {
    return productId === PRODUCT_ID_SAS
      ? sasFormulaes_comparision
      : productId === PRODUCT_ID_RSOC
      ? rsocFormulaes_comparision
      : productId === PRODUCT_ID_LEADGEN
      ? lgFormulaes_comparision
      : vsFormulaes_comparision;
  } else {
    return productId === PRODUCT_ID_SAS
      ? sasFormulaes
      : productId === PRODUCT_ID_RSOC
      ? rsocFormulaes
      : productId === PRODUCT_ID_LEADGEN
      ? lgFormulaes
      : vsFormulaes;
  }
}
