import { gql } from "@apollo/client";
import { ASSET_UPDATE_RESPONSE_RESPONSE } from "./gqlSchema/commonResponseFragements";

export const GET_ME_ID = gql`
  query {
    me {
      id
    }
  }
`;
export const GET_ME_USERS = gql`
  query {
    me {
      users {
        id
        name
      }
    }
  }
`;
export const GET_ME = gql`
  query {
    me {
      id
      name
      lf_superadmin
      agency_id
      # permissions {
      #   name
      # }
      permissions
      platforms {
        id
        name
      }
      agency {
        name
      }
      # roles {
      #   id
      #   name
      # }
      role
      workspaces {
        id
        title
        description
        is_personal
        # creator {
        #   name
        # }
        # departments {
        #   title
        #   description
        #   creator {
        #     name
        #   }
        # }
      }
      picture
    }
  }
`;

export const GET_WORKSPACES_BY_USER = gql`
  query WorkspaceByUser($userId: ID!) {
    WorkspaceByUser(user_id: $userId) {
      id
      title
    }
  }
`;

export const SYNC_CAMPAIGN_INSIGHTS_LAST_3HR = gql`
  mutation syncCampaignInsightsLast3Hr($fbCampaignId: String!) {
    syncCampaignInsightsLast3Hr(fbCampaignId: $fbCampaignId)
  }
`;

export const UPDATE_AD_SET_STATUS = gql`
  ${ASSET_UPDATE_RESPONSE_RESPONSE}
  mutation updateAdSetStatus($adSetFBId: String!, $status: String!) {
    updateAdSetStatus(adSetFBId: $adSetFBId, status: $status) {
      ...AssetUpdateResponse
    }
  }
`;

export const ASSIGN_AD_ACCOUNT_TO_USER = gql`
  mutation assignAdAccountToUser($adAccountId: ID!, $userId: ID!, $workspaceId: ID!) {
    assignAdAccountToUser(adAccountId: $adAccountId, userId: $userId, workspaceId: $workspaceId)
  }
`;

export const ADD_DOMAIN_AND_PATTERN = gql`
  mutation addDomainAndPattern($domainUrl: String!, $pattern: String!) {
    addDomainAndPattern(domainUrl: $domainUrl, pattern: $pattern)
  }
`;

export const ADD_PAGE = gql`
  mutation addPage(
    $countryCodes: [String]!
    $query: String!
    $workspaceIds: [ID]!
    $domainUrl: String
    $pattern: String
  ) {
    addPage(
      countryCodes: $countryCodes
      query: $query
      workspaceIds: $workspaceIds
      domainUrl: $domainUrl
      pattern: $pattern
    ) {
      id
    }
  }
`;

export const GET_ACCOUNT_DOMAINS = gql`
  query domains($account: ID) {
    domains(account: $account) {
      id
      name
      account_id
      account_type
      address_id
      autorenew
      domain_expires
      domain_id
      notes
      privacy
      status
      type
      whoisguard
      user {
        id
        name
      }
    }
  }
`;

export const ASSIGN_BRAND_TO_USER = gql`
  mutation assignBrandToUser($userId: ID!, $revShare: Float!, $brandId: ID!, $fromDate: String!) {
    assignBrandToUser(userId: $userId, revShare: $revShare, brandId: $brandId, fromDate: $fromDate)
  }
`;

// export const DELETE_VIRAL_SPARKS_SELECTED_AGENCY = gql`
//   mutation search_arbitrage_deleteSelectedDomain($domain: [Int]) {
//     search_arbitrage_deleteSelectedDomain(domain: $domain)
//   }
// `;

export const DELETE_SEARCH_ARBITRAGE_DOMAIN = gql`
  mutation search_arbitrage_deleteDomain($domain: Int) {
    search_arbitrage_deleteDomain(domain: $domain)
  }
`;

export const GET_SEARCH_ARBITRAGE_CONTENT = gql`
  query getContents {
    contents {
      id
      title
      category_id
      category_name
      content_domains {
        id
        domain_id
      }
    }
  }
`;

export const SUBCATEGORY_DROPDOWN = gql`
  query subcategorydropdown($agency_id: Int) {
    subcategoriesDropDown(agency_id: $agency_id) {
      id
      user_id
      category_title
    }
  }
`;

export const CREATE_AGENCY = gql`
  mutation CreateAgency($contact_no: String!, $email: String!, $name: String!) {
    CreateAgency(contact_no: $contact_no, email: $email, name: $name) {
      id
      name
    }
  }
`;

export const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($description: String, $is_personal: Boolean!, $title: String!) {
    CreateWorkspace(description: $description, is_personal: $is_personal, title: $title) {
      id
    }
  }
`;

export const GENERATE_INVITATION = gql`
  mutation GenerateInvitation {
    GenerateInvitation {
      agency {
        id
      }
      code
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($code: String!) {
    AcceptInvitation(code: $code)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($name: String, $picture: String) {
    UpdateUser(name: $name, picture: $picture) {
      picture
    }
  }
`;

export const GET_ALL_USERS_ID_NAME = gql`
  query users {
    users {
      id
      name
      is_active
    }
  }
`;

export const ASSIGN_ROLE_TO_USER = gql`
  mutation AddUserRole($role_id: ID!, $user_id: ID!) {
    AddUserRole(role_id: $role_id, user_id: $user_id)
  }
`;

export const ADD_USER_ROLE = gql`
  mutation AddUserRole($role_id: ID!, $user_id: ID!) {
    AddUserRole(role_id: $role_id, user_id: $user_id)
  }
`;

export const WORKSPACES_BY_AGENCY = gql`
  query agency($id: ID) {
    agency(id: $id) {
      id
      workspaces {
        id
        title
        description
        users {
          id
          name
        }
        # agency {
        #   name
        # }
      }
    }
  }
`;

export const DEPARTMENTS_BY_AGENCY = gql`
  query agency($id: ID) {
    agency(id: $id) {
      id
      departments {
        id
        title
        description
      }
    }
  }
`;

export const ADD_PERMISSION_TO_ROLE = gql`
  mutation AddPermissionToRole($permission_id: ID!, $role_id: ID!) {
    AddPermissionToRole(permission_id: $permission_id, role_id: $role_id)
  }
`;

export const REMOVE_PERMISSION_FROM_ROLE = gql`
  mutation RemovePermissionFromRole($permission_id: ID!, $role_id: ID!) {
    RemovePermissionFromRole(permission_id: $permission_id, role_id: $role_id)
  }
`;

export const GET_ALL_REPORT_DATA = gql`
  query getAllReportData($page: Int, $size: Int) {
    getAllReportData(page: $page, size: $size) {
      reportId
      domain
      utmCampaign
      utmSource
      utmMedium
      utmTerm
      utmContent
      TotalRevenue
      impressions
      pageViews
      sessions
      date
    }
  }
`;

export const GET_REPORT_BY_CAMPAIGN_NAME = gql`
  query reportByCampaignName(
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $workspaceId: ID!
  ) {
    reportByCampaignName(
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      workspaceId: $workspaceId
    ) {
      campaignName
      clicksFB
      impressionsFB
      leadsFB
      spendFB
    }
  }
`;

export const GET_CMS_STORIES = gql`
  query CMS_Stories($page: Int, $size: Int) {
    CMS_Stories(page: $page, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        title
        sub_title
        description
        image
        agency_id
        categories {
          id
          name
        }
        content
        domains {
          id
          name
        }
        is_feature_post
        is_private
        pub_date
        slug
        status
        tags
      }
    }
  }
`;

export const GET_CMS_STORE_ID_NAME_DESCRIPTION = gql`
  query CMS_Stores {
    CMS_Stores {
      id
      name
      description
    }
  }
`;

export const GET_CMS_ALL_BLOCKS_ID_NAME = gql`
  query CMS_All_Blocks {
    CMS_All_Blocks {
      id
      title
    }
  }
`;

export const GET_CMS_STORE_NAME_BY_BRANDS = gql`
  query CMS_Brand($id: ID!) {
    CMS_Brand(id: $id) {
      id
      stores {
        id
        name
      }
    }
  }
`;

export const GET_CMS_BRANDS = gql`
  query CMS_Brands {
    CMS_Brands {
      id
      name
      description
      slug
      website
      image
      categories {
        id
        name
      }
      stores {
        id
        name
      }
      is_private
      is_store
      status
    }
  }
`;

export const GET_CMS_AWIC_STORE_EVERFLOWS = gql`
  query CMS_Awic_Store_Everflows {
    CMS_Awic_Store_Everflows {
      store_id
      store_name
      everflow_data {
        awic_network_account {
          id
          name
        }
        country {
          id
          name
        }
        domain {
          id
          domain_url
        }
        everflow_domain
        everflow_offer
        weight
      }
    }
  }
`;

export const ADD_CMS_AWIC_STORE_EVERFLOW = gql`
  mutation CMS_Create_Awic_Store_Everflow(
    $everflow_input: [everflow_input]
    $store_id: ID
    $store_name: String
  ) {
    CMS_Create_Awic_Store_Everflow(
      everflow_input: $everflow_input
      store_id: $store_id
      store_name: $store_name
    ) {
      store_id
    }
  }
`;

export const DELETE_AWIC_STORE_EVERFLOW = gql`
  mutation CMS_Delete_Awic_Store_Everflow($store_id: [ID]) {
    CMS_Delete_Awic_Store_Everflow(store_id: $store_id)
  }
`;

export const GET_DEPARTMENT_CONGIFURATION = gql`
  query departmentConfiguration($department_id: ID!, $meta_name: String!) {
    departmentConfiguration(department_id: $department_id, meta_name: $meta_name)
  }
`;
export const UPDATE_DEPARTMENT_CONGIFURATION = gql`
  mutation departmentConfiguration($department_id: ID!, $meta_name: String!, $meta_value: String!) {
    departmentConfiguration(
      department_id: $department_id
      meta_name: $meta_name
      meta_value: $meta_value
    )
  }
`;

export const GET_WORKSPACE_CONGIFURATION = gql`
  query workspaceConfiguration($workspace_id: ID!, $meta_name: String!) {
    workspaceConfiguration(workspace_id: $department_id, meta_name: $meta_name)
  }
`;
export const UPDATE_WORKSPACE_CONGIFURATION = gql`
  mutation workspaceConfiguration($workspace_id: ID!, $meta_name: String!, $meta_value: String!) {
    workspaceConfiguration(
      workspace_id: $department_id
      meta_name: $meta_name
      meta_value: $meta_value
    )
  }
`;

export const GET_GLOBAL_CONGIFURATION = gql`
  query globalConfiguration($meta_name: String!) {
    globalConfiguration(meta_name: $meta_name)
  }
`;

export const UPDATE_GLOBAL_CONGIFURATION = gql`
  mutation updateGlobalConfiguration($meta_name: String!, $meta_value: String!) {
    updateGlobalConfiguration(meta_name: $meta_name, meta_value: $meta_value)
  }
`;
