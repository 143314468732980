import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";
import alasql from "alasql";
import cx from "classnames";
import { fbAdsManagerRedirect, numberParse, secondsToHMS } from "utils";
import { useParams } from "react-router";
import DetailView from "./DetailView";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import {
  F360_MANAGE_LEADGEN_REPORT,
  USER_WITH_NO_ACTION,
} from "shared/UserPermission/userPermissionList";
import {
  PRODUCT_ID_LEADGEN,
  REPORT_SAS_RSOC_FB_TAG_TYPE_ADSET,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_GENERAL,
} from "shared/SharedKeys";
import { usePermissionCheck } from "hooks";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import { defaultFilterColumns } from "../defaultFilterColumns";
import {
  MULTI_SEARCH_FILTER_TYPE_NUMBER,
  useLookTableFilterData,
} from "components/LookTable/LookTableFilterSearch/functions";
import {
  GET_F360_ADGROUP_REPORT,
  GET_F360_ADSET_GROUPED_REPORT,
} from "shared/gql/gqlSchema/fb360Gql";
import {
  ADBY_REPORT_TYPE_ADACCOUNT,
  ADBY_REPORT_TYPE_CAMPAIGN,
} from "hooks/facebookHooks/useFacebookSpendReportsQuery";
import LookModal from "components/LookModal";
import UpdateCampaignBudget from "components/ReportUtils/UpdateCampaignBudget";
import {
  ActionsForFacebook,
  useUpdateFB360Status,
} from "components/ReportUtils/ActionsForFacebook";
import { fbGroupForStatus } from "components/ReportUtils/UpdateCampaignBudget/utils";
import { useNewLeadgenReportsQuery } from "hooks/tiktokHooks";
import DuplicateCampaigns from "../DuplicateCampaigns";
import { Button, Tooltip } from "antd";
import RuleLinkedItems from "components/LookTable/RuleEngine/RuleLinkedItems";
import NewRuleForm from "components/LookTable/RuleEngine/NewRules";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import { GET_COMMENETS_COUNTS } from "shared/gql/gqlSchema/commentsGql";
import { COMMENTS_ADSETREPORT, COMMENTS_LEADGEN } from "shared/enum/commentsKeysAndEnum";
import useLookComment from "components/CommentHook";
import LookButton from "components/LookButton";
import UpdateAdSetBid from "./UpdateBidAmount";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";
import RingbaCallReport from "../RingbaCallReport";
import {
  LEADGEN_FACEBOOK_PLATFORM_ID,
  LEADGEN_REPORT_ASSET_TYPE_ADSET,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
} from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import BulkDetailView from "./BulkDetailView";

export default function AdSets(props) {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const {
    isCampaignFiltered = false,
    isBulkCampaignFiltered = false,
    isAdAccountFiltered = false,
    isBulkAdAccountFiltered = false,
    performance = false,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    bulkProps = [],
  } = props;
  const params = useParams();
  const [spendReport, setSpendReport] = useState([]);
  const [adsetModalVisible, setAdsetModalVisible] = useState(false);
  const [ruleSetting, setRuleSetting] = useState(null);
  const [visible, setVisible] = useState(false);
  const { filterData } = useLookTableFilterData(persistKey);

  const [rowPropsData, setRowProps] = useState({});
  const [visibleBudgetDrawer, setVisibleBudgetDrawer] = useState(false);
  const { updateFb360Status } = useUpdateFB360Status(REPORT_TYPE_AD_ADSET, {
    setSpendReport,
  });
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userSettings = useSelector((state) => state?.userSettings);
  const [visibleBulkBudgetDrawer, setVisibleBulkBudgetDrawer] = useState(false);
  const [visibleBulkBidDrawer, setVisibleBulkBidDrawer] = useState(false);
  const [visibleRingbaCallDrawer, setVisibleRingbaCallDrawer] = useState(false);
  const [visibleBulkDetailDrawer, setVisibleBulkDetailDrawer] = useState(false);
  const [rowBulkPropsData, setRowBulkPropsData] = useState([]);
  const { selectedRowsClear } = useLookTableParentContext();

  const { havePermission: isWithoutAction } = usePermissionCheck(USER_WITH_NO_ACTION);
  const { commentDrawer, showDrawer } = useLookComment();
  const [unseenComment, setUnseenComment] = useState(false);
  const [commentsCountsData, setCountsCommentsData] = useState([]);
  const sizeMd = 124;
  const sizeXs = 70;
  const sizeS = 90;
  const {
    selectedWorkspace,
    dateRangeTimezone,
    mainSearchWithInclude = false,
  } = userSettings || {};

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const withUser = params?.userID ? { user_id: params?.userID } : {};
  const spendDateApiVariables = {
    start: dateRangeTimezone?.rangeDates[0],
    end: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };

  const revenueDateRangeParams = {
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };

  let reportSettings = {
    reportApi: GET_F360_ADGROUP_REPORT,
    reportApiKey: "fb360_adsetReporting",
  };

  if (isCampaignFiltered || isBulkCampaignFiltered || isBulkAdAccountFiltered || params?.accID) {
    reportSettings = {
      reportApi: GET_F360_ADSET_GROUPED_REPORT,
      reportApiKey: "fb360_adsetGroupedReporting",
    };
  }

  function getSpendAssetParams() {
    if (isCampaignFiltered || isBulkCampaignFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_CAMPAIGN,
        asset_id: isCampaignFiltered
          ? [props?.campaignId]
          : bulkProps?.map(({ campaignId }) => campaignId),
      };
    } else if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return { asset_type: ADBY_REPORT_TYPE_ADACCOUNT, asset_id: [params?.accID] };
    } else {
      return {};
    }
  }

  const spendExtraParams = getSpendAssetParams();

  // Filter Finalfilter Data Id's used this ids in (GET_COMMENETS_COUNTS) api to get counts for same data
  const [
    getCommentsCounts,
    { loading: COMMENETS_GET_ALL_MESSAGE_loading, refetch: COMMENETS_GET_ALL_MESSAGE_refetch },
  ] = useCustomLazyQuery(GET_COMMENETS_COUNTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      reportType: COMMENTS_ADSETREPORT,
      tableType: COMMENTS_LEADGEN,
    },
    onCompleted: (e) => {
      const element = e?.comment_getCommentCount?.data;
      if (element) {
        setCountsCommentsData(element);
      }
    },
  });

  useEffect(() => {
    getCommentsCounts();
  }, []);

  const { loading: loading_GET_REPORT_BY_AD_SET, refetch: refetch_GET_REPORT_BY_AD_SET } =
    useCustomQuery(reportSettings.reportApi, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[reportSettings.reportApiKey];
        if (element) {
          setSpendReport(
            element?.map((item) => ({
              ...item,
              adsetId: item?.adset_id,
              adsetName: item?.adset_name,
              spendAdsetId: item?.adset_id,
              spendAccountId: item?.adaccount_id,
              clicksFB: item?.inline_link_clicks,
              spendFB: item?.spend,
            }))
          );
        }
      },
      variables: { ...spendDateApiVariables, ...spendExtraParams, ...withUser },
    });

  // const isFilteredReportParams = isCampaignFiltered
  //   ? { asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN, asset_id: [props?.campaignId] }
  //   : params?.accID
  //   ? { asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT, asset_id: [params?.accID] }
  //   : {};
  // const isBulkFilteredReportParams = isBulkCampaignFiltered
  //   ? {
  //       asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
  //       asset_id: bulkProps?.map(({ campaignId }) => campaignId),
  //     }
  //   : {};

  function getRevenueAssetParams() {
    if (isCampaignFiltered || isBulkCampaignFiltered) {
      return {
        asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
        asset_id: isCampaignFiltered
          ? [props?.campaignId]
          : bulkProps?.map(({ campaignId }) => campaignId),
      };
    } else if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return { asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT, asset_id: [params?.accID] };
    } else {
      return {};
    }
  }

  const revenueExtraParams = getRevenueAssetParams();

  const { loading_revenue, refetch_revenue, revenueReport } = useNewLeadgenReportsQuery(
    REPORT_TYPE_AD_ADSET,
    {
      ...revenueDateRangeParams,
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      variables: {
        ...withUser,
        ...revenueExtraParams,
      },
    }
  );

  const loading = loading_GET_REPORT_BY_AD_SET || loading_revenue;

  const refetch = () => {
    refetch_GET_REPORT_BY_AD_SET();
    refetch_revenue();
  };

  const action = performance
    ? []
    : permissions?.findIndex((name) => name === F360_MANAGE_LEADGEN_REPORT) !== -1 &&
      !isWithoutAction
    ? [
        {
          title: "Action",
          dataIndex: "action",
          nofilter: true,
          render: (action, actionProps) => (
            <div className="flex-center">
              <Tooltip title="Ringba Call Report">
                <Button
                  className="mr-10"
                  type="link"
                  onClick={() => showRingbaCallDrawer(actionProps)}
                  icon={<ActionIconRender iconType="barChartLine" />}
                />
              </Tooltip>
              <ActionsForFacebook
                reportType={REPORT_TYPE_AD_ADSET}
                actionProps={actionProps}
                showBudgetDrawer={showBudgetDrawer}
                setSpendReport={setSpendReport}
              />
            </div>
          ),
          fixed: "right",
          width: 130,
          minWidth: 130,
        },
      ]
    : [];

  const lastColumns = [
    {
      title: "Budget",
      dataIndex: "daily_budget",
      key: "daily_budget",
      render: (value, actionProps) => {
        const { campaign_id, adset_id, daily_budget, lifetime_budget, campaignId, adsetId } =
          actionProps;

        return (
          <div className="action-column d-flex justify-content-around">
            {(campaign_id || adset_id || campaignId || adsetId) && (
              <div className="actions flex-center">
                <Tooltip
                  title={
                    daily_budget !== 0
                      ? "Daily Budget"
                      : lifetime_budget !== 0
                      ? "Lifetime Budget"
                      : "CBO"
                  }
                >
                  <span>
                    {(daily_budget === 0 || daily_budget === null || daily_budget === undefined) &&
                    (lifetime_budget === 0 ||
                      lifetime_budget === undefined ||
                      lifetime_budget === null) ? (
                      <span className="table-tag">CBO</span>
                    ) : (
                      "$" + (daily_budget || lifetime_budget)
                    )}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
      fixed: "right",
      width: 100,
      minWidth: 100,
      defaultSortOrder: "descend",
      searchType: MULTI_SEARCH_FILTER_TYPE_NUMBER,
      sorter: (a, b) => a?.budgetForSorter - b?.budgetForSorter,
    },
  ];

  const initialColumns = [
    {
      title: "Ad Set Name",
      dataIndex: "finalAdsetName",
      key: "finalAdsetName",
      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return (
          <LookTableColumnRender
            title={value}
            {...otherProps}
            isProfit={PROFIT}
            isClick={
              LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
                ? undefined
                : () => showDrawerUpdate(otherProps)
            }
          />
        );
      },
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: "Ad Set Id",
      dataIndex: "adsetId",
      key: "adsetId",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      minWidth: 170,
      searchType: "number",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeS,
      minWidth: sizeS,
      sorter: (a, b) => a?.revenue - b?.revenue,
      searchType: "number",
    },
    {
      title: "Payout",
      dataIndex: "PAYOUT",
      key: "PAYOUT",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.PAYOUT - b?.PAYOUT,
    },
    {
      title: "Ringba Revenue",
      dataIndex: "payout_amount",
      key: "payout_amount",
      hidden: true,
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.payout_amount - b?.payout_amount,
    },
    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeS,
      minWidth: sizeS,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
      searchType: "number",
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value, otherProps) => {
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      searchType: "number",
    },
    {
      title: "ROAS",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => (
        <LookTableColumnRender title={numberParse(value, { toFixed: true }) + "%"} />
      ),
      width: sizeS,
      minWidth: sizeS,
      sorter: (a, b) => a?.ROI - b?.ROI,
      searchType: "number",
      defaultSortOrder: "ascend",
    },
    {
      title: "Visits",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
      searchType: "number",
    },
    {
      title: "Clicks",
      dataIndex: "revClicks",
      key: "revClicks",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.revClicks - b?.revClicks,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
      searchType: "number",
    },
    {
      title: "FB Results",
      dataIndex: "result",
      key: "result",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.result - b?.result,
      searchType: "number",
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.conversions - b?.conversions,
      searchType: "number",
    },
    {
      title: "Call Count",
      dataIndex: "call_count",
      key: "call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: sizeS,
      minWidth: sizeS,
      sorter: (a, b) => a?.call_count - b?.call_count,
      searchType: "number",
    },
    {
      title: "Converted Calls",
      dataIndex: "converted_calls",
      key: "converted_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.converted_calls - b?.converted_calls,
      searchType: "number",
    },
    {
      title: "Avg Handle Time",
      dataIndex: "avg_handle_time",
      key: "avg_handle_time",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Call Length",
      dataIndex: "call_length",
      key: "call_length",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Blocked Calls",
      dataIndex: "blocked_calls",
      key: "blocked_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.blocked_calls - b?.blocked_calls,
      searchType: "number",
    },
    {
      title: "Duplicate Calls",
      dataIndex: "duplicate_calls",
      key: "duplicate_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.duplicate_calls - b?.duplicate_calls,
      searchType: "number",
    },
    {
      title: "Ended Calls",
      dataIndex: "ended_calls",
      key: "ended_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.ended_calls - b?.ended_calls,
      searchType: "number",
    },
    {
      title: "Connected Call Count",
      dataIndex: "connected_call_count",
      key: "connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.connected_call_count - b?.connected_call_count,
      searchType: "number",
    },
    {
      title: "Non Connected Calls",
      dataIndex: "non_connected_call_count",
      key: "non_connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.non_connected_call_count - b?.non_connected_call_count,
      searchType: "number",
    },
    {
      title: "RPC (Call)",
      dataIndex: "RPC_CALL",
      key: "RPC_CALL",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.RPC_CALL - b?.RPC_CALL,
      searchType: "number",
    },
    {
      title: "CPR (Call)",
      dataIndex: "CPR_CALL",
      key: "CPR_CALL",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.CPR_CALL - b?.CPR_CALL,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "Bid Amount",
      dataIndex: "bid_amount",
      key: "bid_amount",
      render: (value) => <LookTableColumnRender title={value} />,
      width: sizeMd,
      minWidth: sizeMd,
      sorter: (a, b) => a?.bid_amount - b?.bid_amount,
      searchType: "number",
    },
    {
      title: "Bid Strategy",
      dataIndex: "bid_strategy",
      key: "bid_strategy",
      render: (value) => <LookTableColumnRender title={value} />,
      width: sizeMd,
      minWidth: sizeMd,
      sorter: (a, b) => a?.bid_strategy?.localeCompare(b?.bid_strategy),
      searchType: "string",
    },

    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },
    {
      title: "EPV",
      dataIndex: "EPV",
      key: "EPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.EPV - b?.EPV,
      searchType: "number",
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.CPC - b?.CPC,
      searchType: "number",
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.CPR - b?.CPR,
      searchType: "number",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      hidden: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      searchType: "string",
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.EPC - b?.EPC,
      searchType: "number",
    },
    {
      title: "AD_CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP_CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: sizeXs,
      minWidth: sizeXs,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },
    {
      title: "V Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.visits - b?.visits,
      searchType: "number",
    },
    {
      title: "Comments (new)",
      dataIndex: "Comments",
      key: "Comments",
      hidden: true,
      render: (value, otherProps) => {
        return (
          <>
            <LookButton
              disabled={COMMENETS_GET_ALL_MESSAGE_loading}
              className="w-auto h-auto"
              type="link"
              onClick={() =>
                /*Here we are providing row value, row Other data, date-timezone,commentsCountRefetch
               commentsCountLoading, report type, table type and current report ID */
                showDrawer({
                  value,
                  rowData: otherProps,
                  dateRangeTimezone,
                  setCountsCommentsData,
                  loading: loading,
                  reportType: COMMENTS_ADSETREPORT,
                  tableType: COMMENTS_LEADGEN,
                  reportId: otherProps?.adsetId,
                })
              }
              iconType="comment-message"
            />
            {COMMENETS_GET_ALL_MESSAGE_loading ? (
              <ActionIconRender iconType="reload" spin={COMMENETS_GET_ALL_MESSAGE_loading} />
            ) : (
              <>
                &nbsp;{value?.totalRecords} ({value?.totalUnSeen})
              </>
            )}
          </>
        );
      },
      width: sizeS,
      minWidth: sizeS,
      searchType: "string",
    },
    ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];
  let fullAndFinalMergewithComents = [];
  if (!loading) {
    finalMerge = alasql(
      "SELECT *, b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b ON a.adsetId = b.adsetId",
      [spendReport, revenueReport]
    );
    if (commentsCountsData?.length) {
      fullAndFinalMergewithComents = alasql(
        "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.adsetId = b._id",
        [finalMerge, commentsCountsData]
      );
    }
    const finalData = commentsCountsData?.length === 0 ? finalMerge : fullAndFinalMergewithComents;
    finalData?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        revenue = 0,
        visits = 0,
        revClicks = 0,
        conversions = 0,
        result = 0,
        ad_set_id,
        adset_id,
        revAdAccountId,
        spendAccountId,
        adset_name = "",
        adsetName = "",
        call_count = 0,
        converted_calls = 0,
        payout_amount = 0,
        avg_handle_time = 0,
        blocked_calls = 0,
        call_length = 0,
        connected_call_count = 0,
        duplicate_calls = 0,
        ended_calls = 0,
        non_connected_call_count = 0,
        daily_budget,
        totalRecords = 0,
        totalSeen = 0,
        totalUnSeen = 0,
        bid_amount = 0,
      } = element;

      const addSpendPer = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;
      const spendUpdate = (spendFB * addSpendPer).toFixed(2);
      const combinedRevenue = revenue + payout_amount;
      const budgetForSorter =
        daily_budget === null || daily_budget === undefined ? -1 : daily_budget; // -1 for CBO to last

      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const CPC = parseFloat(spendUpdate) / parseFloat(revClicks);
      const PROFIT = parseFloat(combinedRevenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const PAYOUT = parseFloat(combinedRevenue) / parseFloat(conversions);
      const EPV = parseFloat(combinedRevenue) / parseFloat(clicksFB);
      const EPC = parseFloat(combinedRevenue) / parseFloat(revClicks);
      const AD_CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(clicksFB)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const CPR = parseFloat(spendUpdate) / parseFloat(conversions);
      const RPC_CALL = parseFloat(combinedRevenue) / parseFloat(call_count);
      const CPR_CALL = parseFloat(spendUpdate) / parseFloat(call_count);

      finalMergeData.push({
        ...element,
        key: index,
        visits,
        revenue: combinedRevenue,
        clicksFB,
        impressionsFB,
        result,
        revClicks,
        conversions,
        budgetForSorter,
        spendFB: spendUpdate,
        PROFIT: numberParse(PROFIT),
        ROI: numberParse(ROI),
        CR: numberParse(CR),
        PAYOUT: numberParse(PAYOUT),
        CPV: numberParse(CPV),
        EPV: numberParse(EPV),
        CPC: numberParse(CPC),
        EPC: numberParse(EPC),
        AD_CTR: numberParse(AD_CTR),
        LP_CTR: numberParse(LP_CTR),
        CPM: numberParse(CPM),
        CPR: numberParse(CPR),
        finalAdsetName: adsetName || adset_name,
        adsetId: ad_set_id || adset_id,
        adAccountId: spendAccountId || revAdAccountId,
        call_count,
        converted_calls,
        avg_handle_time,
        blocked_calls,
        call_length,
        connected_call_count,
        duplicate_calls,
        ended_calls,
        non_connected_call_count,
        RPC_CALL: numberParse(RPC_CALL),
        CPR_CALL: numberParse(CPR_CALL),
        Comments: { totalRecords, totalSeen, totalUnSeen },
      });
    });
  }

  const filterDataWithUnseenComment = unseenComment
    ? finalMergeData?.filter(({ Comments = {} }) => Comments.totalUnSeen != 0)
    : finalMergeData;

  const finalFilterData = filterData(filterDataWithUnseenComment).filter(
    ({ finalAdsetName = "", adsetId = "", status = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";

      const searchCheck =
        finalAdsetName?.toLowerCase()?.includes(searchValue) ||
        adsetId?.toLowerCase()?.includes(searchValue) ||
        status?.toLowerCase()?.includes(searchValue);

      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }

      return false;
    }
  );

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_revenue = 0,
    total_visits = 0,
    total_revClicks = 0,
    total_conversions = 0,
    total_result = 0,
    total_call_count = 0,
    total_converted_call = 0,
    total_avg_handled_time = 0,
    total_blocked_calls = 0,
    total_call_length = 0,
    total_connected_call_count = 0,
    total_duplicate_calls = 0,
    total_ended_calls = 0,
    total_non_connected_call = 0,
    total_payout_amount = 0,
    total_RPC_call = 0,
    total_CPR_call = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    revenue = 0,
    visits = 0,
    revClicks = 0,
    conversions = 0,
    result = 0,
    call_count = 0,
    converted_calls = 0,
    avg_handle_time = 0,
    call_length = 0,
    blocked_calls = 0,
    connected_call_count = 0,
    duplicate_calls = 0,
    ended_calls = 0,
    non_connected_call_count = 0,
    payout_amount = 0,
    RPC_CALL = 0,
    CPR_CALL = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_revenue += parseFloat(revenue || 0);
    total_visits += parseFloat(visits || 0);
    total_revClicks += parseFloat(revClicks || 0);
    total_conversions += parseFloat(conversions || 0);
    total_result += parseFloat(result || 0);
    total_call_count += parseFloat(call_count || 0);
    total_converted_call += parseFloat(converted_calls || 0);
    total_avg_handled_time += parseFloat(avg_handle_time || 0);
    total_call_length += parseFloat(call_length || 0);
    total_blocked_calls += parseFloat(blocked_calls || 0);
    total_connected_call_count += parseFloat(connected_call_count || 0);
    total_duplicate_calls += parseFloat(duplicate_calls || 0);
    total_ended_calls += parseFloat(ended_calls || 0);
    total_non_connected_call += parseFloat(non_connected_call_count || 0);
    total_payout_amount += parseFloat(payout_amount || 0);
    total_RPC_call += parseFloat(RPC_CALL || 0);
    total_CPR_call += parseFloat(CPR_CALL || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spendFB);
  const totalData = finalFilterData?.length;
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spendFB)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_CPV = parseFloat(total_spendFB) / parseFloat(total_clicksFB);
  const total_EPV = parseFloat(total_revenue) / parseFloat(total_clicksFB);
  const total_CPC = parseFloat(total_spendFB) / parseFloat(total_revClicks);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_AD_CTR = (parseFloat(total_clicksFB) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_clicksFB)) * 100;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;
  const total_CPR = parseFloat(total_spendFB) / parseFloat(total_conversions);
  const avg_RPC_call = parseFloat(total_RPC_call / totalData);
  const avg_CPR_call = parseFloat(total_CPR_call / totalData);

  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_AD_ADSET);
        updateFb360Status("ACTIVE", groupedIds);
      },
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_AD_ADSET);
        updateFb360Status("PAUSED", groupedIds);
      },
    },
    {
      option: "dollar",
      title: "Budget Update",
      onClick: (e) => showBudgetBulkDrawer(e, "facebook-postback-adset"),
    },

    {
      option: "rules",
      title: "Rule-Engine",
      onClick: (e) => {
        const assetIds = e?.map((item) => {
          return { id: item.adsetId, name: item.name };
        });

        setRuleSetting({
          report_type: "general",
          groupBy: "adset",
          platform: "fb",
          product: 1,
          data: assetIds,
        });
        setVisible(true);
      },
    },
    {
      option: "duplicate",
      title: "Duplicate Campaign",
      onClick: (e) => {
        showAdsetModal(e);
      },
    },
    {
      option: "eye",
      title: "Redirect Adsets",
      onClick: (e) => {
        const t = Object.groupBy(e, (el) => el.adaccount_id);
        for (var k in t) {
          fbAdsManagerRedirect({
            reportType: "adset",
            adaccountId: k,
            id: e?.map((el) => el.adset_id).join(","),
          });
        }
      },
    },
    {
      option: "advertiser",
      title: "Bid Update",
      onClick: (e) => showBidBulkDrawer(e, "facebook-postback-adset"),
    },
    {
      option: "unordered-list",
      title: "Detailed report",
      onClick: (e) => {
        showBulkDetailDrawer(e);
      },
    },
  ];

  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone?.value,
      workspaceId: selectedWorkspace,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };

  const onCloseDetailDrawer = () => {
    // setRowProps({});
    setVisibleDetailDrawer(false);
  };

  const showBulkDetailDrawer = (rowProps = []) => {
    setRowBulkPropsData(rowProps);
    selectedRowsClear();
    setVisibleBulkDetailDrawer(true);
  };

  const onCloseBulkDetailDrawer = () => {
    setRowBulkPropsData([]);
    setVisibleBulkDetailDrawer(false);
  };

  const showRingbaCallDrawer = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone?.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleRingbaCallDrawer(true);
  };

  const onCloseRingbaCallDrawer = () => {
    setRowProps({});
    setVisibleRingbaCallDrawer(false);
  };

  const drawerPropsUpdate = {
    title: "Adset",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const bulkDetailViewProps = {
    title: "Adset",
    visible: visibleBulkDetailDrawer,
    onClose: onCloseBulkDetailDrawer,
    size: 1000,
  };

  const ringbaDrawerProps = {
    title: "Ringba Call Report",
    visible: visibleRingbaCallDrawer,
    onClose: onCloseRingbaCallDrawer,
    size: 1000,
  };

  const showBudgetDrawer = (e) => {
    setRowProps({ details: [e], reportType: REPORT_TYPE_AD_ADSET, is_bulk: false, refetch });
    setVisibleBudgetDrawer(true);
  };

  const onCloseDrawerBudget = () => {
    setRowProps({});
    setVisibleBudgetDrawer(false);
  };

  const showBudgetBulkDrawer = (e) => {
    setRowProps({ details: e, reportType: REPORT_TYPE_AD_ADSET, is_bulk: true, refetch });
    setVisibleBulkBudgetDrawer(true);
  };

  const onCloseDrawerBulkBudget = () => {
    setRowProps({});
    setVisibleBulkBudgetDrawer(false);
  };

  const showBidBulkDrawer = (e) => {
    setRowProps({ details: e, reportType: REPORT_TYPE_AD_ADSET, is_bulk: true, refetch });
    setVisibleBulkBidDrawer(true);
  };

  const onCloseDrawerBulkBid = () => {
    setRowProps({});
    selectedRowsClear();
    setVisibleBulkBidDrawer(false);
  };

  const modalUpdateBulkBidPropsUpdate = {
    title: "Update Ad Set Bid",
    visible: visibleBulkBidDrawer,
    onCancel: onCloseDrawerBulkBid,
    okokText: "Update Bid",
    footer: false,
  };

  const modalUpdateBudgetPropsUpdate = {
    title: "Update AdSet Budget",
    visible: visibleBudgetDrawer,
    okokText: "Update Budget",
    onCancel: onCloseDrawerBudget,
    footer: false,
  };

  const modalUpdateBulkBudgetPropsUpdate = {
    title: "Update Campaign Budget",
    visible: visibleBulkBudgetDrawer,
    onCancel: onCloseDrawerBulkBudget,
    okokText: "Update Budget",
    footer: false,
  };

  const showAdsetModal = (rowData = []) => {
    setAdsetModalVisible(true);
    setRowProps(rowData?.[0]);
  };
  const onCloseAdsetModal = () => {
    setAdsetModalVisible(false);
  };

  const adsetModalProps = {
    visible: adsetModalVisible,
    onCancel: onCloseAdsetModal,
    title: "Duplicate Campaign",
    okokText: "Create",
    footer: false,
  };
  const rightOptions = (
    <>
      <div className="mr-10">
        <Tooltip title="Status">
          <ActionIconRender
            className={cx({ "text-primary": unseenComment })}
            iconType="comment-message"
            style={{ fontSize: "20px" }}
            onClick={() => {
              setUnseenComment(!unseenComment);
            }}
          />
        </Tooltip>
      </div>
    </>
  );
  const summaryData = {
    spendFB: numberParse(total_spendFB, { toFixed: true }),
    clicksFB: numberParse(total_clicksFB, { toFixed: true }),
    impressionsFB: numberParse(total_impressionsFB, { toFixed: true }),
    revenue: numberParse(total_revenue, { toFixed: true }),
    visits: numberParse(total_visits, { toFixed: true }),
    revClicks: numberParse(total_revClicks, { toFixed: true }),
    conversions: numberParse(total_conversions, { toFixed: true }),
    result: numberParse(total_result, { toFixed: true }),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: numberParse(total_ROI, { toFixed: true }),
    CR: numberParse(total_CR, { toFixed: true }),
    PAYOUT: numberParse(total_PAYOUT, { toFixed: true }),
    CPV: numberParse(total_CPV, { toFixed: true }),
    EPV: numberParse(total_EPV, { toFixed: true }),
    CPC: numberParse(total_CPC, { toFixed: true }),
    EPC: numberParse(total_EPC, { toFixed: true }),
    AD_CTR: numberParse(total_AD_CTR, { toFixed: true }),
    LP_CTR: numberParse(total_LP_CTR, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
    CPR: numberParse(total_CPR, { toFixed: true }),
    call_count: total_call_count,
    converted_calls: total_converted_call,
    avg_handle_time: secondsToHMS(total_avg_handled_time),
    call_length: secondsToHMS(total_call_length),
    blocked_calls: total_blocked_calls,
    connected_call_count: total_connected_call_count,
    duplicate_calls: total_duplicate_calls,
    ended_calls: total_ended_calls,
    non_connected_call_count: total_non_connected_call,
    payout_amount: numberParse(total_payout_amount, { toFixed: true }),
    RPC_CALL: numberParse(avg_RPC_call, { toFixed: true }),
    CPR_CALL: numberParse(avg_CPR_call, { toFixed: true }),
  };

  const isRuleSettings = isWithoutAction
    ? {}
    : {
        ruleSetting: {
          groupBy: REPORT_TYPE_AD_ADSET,
          reportType: REPORT_TYPE_GENERAL,
          productId: PRODUCT_ID_LEADGEN,
          platform: "fb",
        },
      };

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    defaultFilterColumns,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },
    tableProps: {
      initialColumns,
      lastColumns,
      recordData: finalFilterData,
      summaryData,
    },
    loading,
    refetch,
    defaultPagination: true,
    rightOptions,
    persistKey,
    bulkActions: isWithoutAction ? [] : bulkActions,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalFilterData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
  ];
  function onClose() {
    setRuleSetting(null);
    setVisible(false);
  }
  const ruledraweProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 800,
    visible,
    onClose,
  };
  return (
    <div>
      <DetailView {...drawerPropsUpdate} {...rowPropsData} />
      <BulkDetailView {...bulkDetailViewProps} bulkProps={rowBulkPropsData} />
      <RingbaCallReport
        {...ringbaDrawerProps}
        {...rowPropsData}
        asset={LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET}
      />
      {!loading && commentDrawer()}
      <LookModal {...adsetModalProps}>
        {adsetModalVisible && (
          <DuplicateCampaigns
            {...rowPropsData}
            onCloseCampaignModal={onCloseAdsetModal}
            reportType={REPORT_TYPE_AD_ADSET}
          />
        )}
      </LookModal>
      {visibleBudgetDrawer && (
        <LookModal {...modalUpdateBudgetPropsUpdate}>
          <UpdateCampaignBudget
            {...rowPropsData}
            refetch={refetch}
            close={onCloseDrawerBudget}
            tagType={REPORT_SAS_RSOC_FB_TAG_TYPE_ADSET}
          />
        </LookModal>
      )}

      {visibleBulkBudgetDrawer && (
        <LookModal {...modalUpdateBulkBudgetPropsUpdate}>
          <UpdateCampaignBudget
            {...rowPropsData}
            refetch={refetch}
            close={onCloseDrawerBulkBudget}
            tagType={REPORT_SAS_RSOC_FB_TAG_TYPE_ADSET}
          />
        </LookModal>
      )}
      {visibleBulkBidDrawer && (
        <LookModal {...modalUpdateBulkBidPropsUpdate}>
          <UpdateAdSetBid
            {...rowPropsData}
            refetch={refetch}
            close={onCloseDrawerBulkBid}
            asset={LEADGEN_REPORT_ASSET_TYPE_ADSET}
            tagType={REPORT_SAS_RSOC_FB_TAG_TYPE_ADSET}
          />
        </LookModal>
      )}
      {ruleSetting && (
        <LookDrawer {...ruledraweProps}>
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Add/Update",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: (
                  <NewRuleForm
                    close={() => onClose()}
                    // {...props}
                    productId={ruleSetting?.product}
                    ids={ruleSetting?.data?.map(({ id }) => id)}
                    platform={ruleSetting?.platform}
                    grpby={ruleSetting?.groupBy}
                    report_type={ruleSetting?.report_type}
                    idtype={"inc"}
                    // copy={update}
                  />
                ),
              },
              {
                hoverTitle: "Rule Linked Items",
                icon: () => <ActionIconRender iconType="ordered-list" />,
                component: <RuleLinkedItems close={() => onClose()} ids={ruleSetting?.data} />,
              },
            ]}
          />
        </LookDrawer>
      )}
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
