import { Button, Col, Divider, Input, Radio, Row, Select, Space, Switch } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import { useLaunchContext } from "components/Launch/Context";
import AvatarUpload from "components/Launch/Creatives/AvatarUpload";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useRef, useState } from "react";
import {
  LAUNCH_CUSTOM_CONVERSION_TYPE,
  LAUNCH_CUSTOM_EVENT_ENUM,
  LAUNCH_CUSTOM_EVENT_TYPE,
} from "shared/enum/iCampaignLaunchEnumArray";
import {
  EUROPEAN_COUNTRIES,
  PLATFORM_TYPE_FACEBOOK,
  PLATFORM_TYPE_TIKTOK,
  SERVICE_TYPE_LEAD_GEN,
  SERVICE_TYPE_SAS,
  SERVICE_TYPE_VS,
  TIKTOK_AVATAR_URL,
} from "shared/enum/launchKeysAndEnum";
import { PlusOutlined } from "@ant-design/icons";
import {
  GQL_FETCH_AD_ACCOUNT_CACHE,
  LAUNCH_CUSTOM_CONVERSIONS_PIXELS,
  LAUNCH_GET_TIKTOK_PIXELS,
  LAUNCH_PAGE_REQUESTS,
  LAUNCH_TIKTOK_GET_IDENTITY_NAME,
} from "shared/gql/gqlSchema/launchGql";
import { openNotification } from "utils";
import LeadgenUrlDropdown from "./LeadgenUrlDropdown";
import cx from "cx";
import useAddNewIdentity from "components/Launch/launchHooks/useAddNewIdentity";
import Loader from "components/loader";
import LookButton from "components/LookButton";

function MultiAdAccountForm(props) {
  const {
    data,
    setFormData = () => {},
    showAdaccountList,
    bucketData,
    isPageId,
    profileData,
    sasTrackingLoading,
    sasTrackingUrl,
    multiAdaccount_data,
    ad,
    index,
    profileId,
    adAccountsData,
    inValidAdAccountIndex,
    // mainPageData,
    adAccountIndex,
    show_beneficiary,
  } = props;

  const [expandedIndex, setExpandedIndex] = useState(0);
  const {
    serviceType,
    vsUrlPlatform,
    productId,
    vsBrands,
    isTiktokPlatformLaunch,
    instantLaunch,
    call_vsBrandGetByDomain,
  } = useLaunchContext();
  const [customConversions, setCustomConversions] = useState(
    data?.customConversionId ? LAUNCH_CUSTOM_CONVERSION_TYPE : LAUNCH_CUSTOM_EVENT_TYPE
  );

  const [rowProps, setRowProps] = useState({});
  const [tiktokIdentityAvatar, setTiktokIdentityAvatar] = useState("");
  const [visibleCreativeDrawer, setVisibleCreativeDrawer] = useState(false);
  const [identityName, setIdentityName] = useState("");
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setIdentityName(event.target.value);
  };
  const { addNewIdentity, data_identity_id, loading_identity_id } = useAddNewIdentity();
  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  const updateData = (key, value) => {
    if (isTiktokPlatformLaunch) {
      setFormData((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedAdAccounts = {
          ...updatedItems[adAccountIndex],
          [key]: value,
        };
        updatedItems[adAccountIndex] = updatedAdAccounts;

        return updatedItems;
      });
    } else {
      setFormData((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index] };
        const updatedAdAccounts = [...updatedItem.adAccount];
        updatedAdAccounts[adAccountIndex] = {
          ...updatedAdAccounts[adAccountIndex],
          [key]: value,
          ...(key === "customConversionId" && { customEventStr: "" }),
          ...(key === "customEventStr" && { customConversionId: "" }),
        };
        updatedItem.adAccount = updatedAdAccounts;
        updatedItems[index] = updatedItem;

        return updatedItems;
      });
    }
  };

  const showCreativeDrawer = (e, advertiserId) => {
    setRowProps({ displayname: e, advertiserId });
    setVisibleCreativeDrawer(true);
  };
  const onCloseCreativeDrawer = () => {
    setRowProps({});
    setIdentityName();
    setTiktokIdentityAvatar();
    setVisibleCreativeDrawer(false);
  };
  const creativeDrawerProps = {
    visible: visibleCreativeDrawer,
    onClose: onCloseCreativeDrawer,
    textBreadcrumb: ["Add Avatar"],
    size: 1200,
  };
  const [
    callIdentityNames,
    { data: data_identityNames, loading: loading_identityNames, refetch: refetch_identityNames },
  ] = useCustomLazyQuery(LAUNCH_TIKTOK_GET_IDENTITY_NAME, {
    notifyOnNetworkStatusChange: true,
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  // const [addNewIdentity, { loading: loading_identity_id }] = useCustomMutation(
  //   LAUNCH_TIKTOK_ADD_IDENTITY_NAME,
  //   {
  //     onCompleted: (e) => {
  //       const element = e?.addNewIdentityId;
  //       if (element?.status === "Successful") {
  //         // creatiVideo_refetch();
  //         setTimeout(() => {
  //           refetch_identityNames();
  //           onCloseCreativeDrawer();
  //         }, 1000);

  //         openNotification({ type: "success", message: element?.message });
  //       } else {
  //         openNotification({ type: "error", message: element?.message });
  //       }
  //     },
  //     onError(e) {
  //       openNotification({ type: "error", message: e?.addNewIdentityId?.message });
  //     },
  //   }
  // );
  useEffect(() => {
    if (data_identity_id?.addNewIdentityId?.status === "Successful") {
      setTimeout(() => {
        refetch_identityNames();
        onCloseCreativeDrawer();
      }, 1000);
    }
  }, [data_identity_id]);

  function onSubmitAddIdentity() {
    addNewIdentity({
      variables: {
        displayName: rowProps?.displayname,
        advertiserIds: [rowProps?.advertiserId],
        imageUri: tiktokIdentityAvatar,
      },
    });
    setRowProps();
    setIdentityName("");
  }

  // const initialColumns = [
  //   {
  //     title: "Landing Url",
  //     dataIndex: "name",
  //     key: "name",
  //     render: (value) => <LookTableColumnRender title={value} />,
  //     width: 170,
  //     minWidth: 170,
  //     // sorter: (a, b) => a?.url?.localeCompare(b?.url),
  //   },
  //   {
  //     title: "url",
  //     dataIndex: "url",
  //     key: "url",
  //     render: (value, otherProps) => (
  //       <LeadgenUrlDropdown setUrl={setUrl} url={url} otherProps={otherProps} />
  //     ),
  //     width: 185,
  //     minWidth: 185,
  //   },
  //   {
  //     title: "urlParams",
  //     dataIndex: "urlParams",
  //     key: "urlParams",
  //     render: (value, otherProps) => {
  //       return (
  //         <LookTextInput
  //           name="urlParams"
  //           // label="Url Params"
  //           placeholder="Enter url parameters"
  //           className="look-form-input mx-0 my-12"
  //           value={url?.find((item) => item.index === otherProps?.urlIndex)?.params}
  //           onChange={(e) =>
  //             setUrl((prevItems) => {
  //               if (prevItems.length === 0) {
  //                 return [
  //                   {
  //                     index: otherProps?.urlIndex,
  //                     params: e?.target?.value,
  //                     name: otherProps?.name,
  //                   },
  //                 ];
  //               }

  //               const updatedItems = prevItems?.map((item) =>
  //                 item?.index === otherProps?.urlIndex
  //                   ? { ...item, params: e?.target?.value, name: otherProps?.name }
  //                   : item
  //               );

  //               const matchingItem = prevItems?.find(
  //                 (item) => item?.index === otherProps?.urlIndex
  //               );

  //               if (!matchingItem) {
  //                 updatedItems.push({
  //                   index: otherProps?.urlIndex,
  //                   params: e?.target?.value,
  //                   name: otherProps?.name,
  //                 });
  //               }
  //               return updatedItems;
  //             })
  //           }
  //         />
  //       );
  //     },
  //     width: 185,
  //     minWidth: 185,
  //   },
  // ];
  const [fetchPages, { data: data_Pages, loading: loading_Pages, refetch: refetch_Pages }] =
    useCustomLazyQuery(LAUNCH_PAGE_REQUESTS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // stetCustomConversionsList(e?.customConversionsPixels);
        setFormData((prevItems) => {
          const updatedItems = [...prevItems];
          const updatedAdAccounts = {
            ...updatedItems[adAccountIndex],
            tiktokPages: e?.tiktokGetPages?.data,
          };
          updatedItems[adAccountIndex] = updatedAdAccounts;

          return updatedItems;
        });
        openNotification({ type: "success", message: "Pixel fetch Successful" });
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  const [fetchPixels, { data: data_pixels, loading: loading_pixels, refetch: refetch_pixels }] =
    useCustomLazyQuery(LAUNCH_GET_TIKTOK_PIXELS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        updateData("tiktokPixels", e?.tiktokLaunchPixels);
        // openNotification({ type: "success", message: "Pixel fetch Successful" });
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  // useEffect(() => {
  //   if (refetch_identityData) {
  //     refetch_identityNames();
  //   }
  // }, [refetch_identityData]);

  const [customConversionsPixels, { data: data_customconversion, loading: loading_fbpixels }] =
    useCustomLazyQuery(LAUNCH_CUSTOM_CONVERSIONS_PIXELS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        setFormData((prevItems) => {
          const updatedItems = [...prevItems];
          const updatedItem = { ...updatedItems[index] };
          const updatedAdAccounts = [...updatedItem.adAccount];
          updatedAdAccounts[adAccountIndex] = {
            ...updatedAdAccounts[adAccountIndex],
            customConversions: e?.customConversionsPixels,
          };
          updatedItem.adAccount = updatedAdAccounts;
          updatedItems[index] = updatedItem;

          return updatedItems;
        });
        // openNotification({ type: "success", message: "Pixel fetch Successful" });
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  useEffect(() => {
    if (!data?.isDuplicate && !isTiktokPlatformLaunch && data !== undefined) {
      if (
        data?.customConversions === undefined ||
        Object.keys(data?.customConversions)?.length === 0
      ) {
        customConversionsPixels({
          variables: {
            accountId: "act_" + data?.adAccount,
            hard_sync: false,
            product_id: productId,
            profileId,
          },
        }).then(() => {
          if (data?.adAccount && !data?.pageId) {
            fetchadAccountCache({
              variables: {
                ad_account: `${data?.adAccount}`,
                product_id: productId,
                platform_type: isTiktokPlatformLaunch
                  ? PLATFORM_TYPE_TIKTOK?.toUpperCase()
                  : PLATFORM_TYPE_FACEBOOK.toUpperCase(),
              },
            });
          }
        });
      }
    }
  }, [data?.adAccount]);

  const [fetchadAccountCache, { data: adAccountCache, loading: fetchadAccountCache_loading }] =
    useCustomLazyQuery(GQL_FETCH_AD_ACCOUNT_CACHE, {
      onCompleted: (e) => {
        const element = e?.adAccountCache?.payload;
        if (element) {
          delete element?.alias;
          setFormData((prevItems) => {
            const updatedItems = [...prevItems];
            const updatedItem = { ...updatedItems[index] };
            const updatedAdAccounts = [...updatedItem.adAccount];

            // We are filtering the page IDs. If any of the pages are deleted, they will be removed from the selected array provided by the caching API.
            const finalFilteredPageIds = [];
            data?.customConversions?.pages?.forEach((dataPage) => {
              if (dataPage?.id === element?.page_id || element?.page_id?.[0]?.value) {
                finalFilteredPageIds.push(dataPage);
              }
            });
            const updatedPageId = finalFilteredPageIds?.map(({ id, name }) => ({
              name: name + " (" + id + ")",
              value: id,
            }));
            // We are filtering the pixel IDs. If any of the pixels are deleted, they will be removed from the selected array provided by the caching API.
            const finalFilteredPixels = [];
            element?.pixel?.forEach((elementPixel) => {
              data?.customConversions?.pixels?.forEach((dataPixel) => {
                if (dataPixel.id === elementPixel) {
                  finalFilteredPixels.push(dataPixel);
                }
              });
            });
            const updatedPixelValues = finalFilteredPixels?.map(({ id, name }) => ({
              name: name + " (" + id + ")",
              value: id,
            }));

            updatedAdAccounts[adAccountIndex] = {
              ...updatedAdAccounts[adAccountIndex],
              pixel: isTiktokPlatformLaunch ? [element?.pixel] : updatedPixelValues,
              customConversionId: element?.custom_conversion_id,
              customEventStr: element?.custom_event_str,
              customEventType: element?.custom_event_type,
              epcKey: element?.epc_key,
              identityId: element?.identity_id,
              identityType: element?.identity_type,
              pageId: isTiktokPlatformLaunch ? element?.page_id : updatedPageId,
              pixelValue: element?.pixel_value,
              campCounter: element?.camp_counter,
              beneficiary: element?.beneficiary,
              payor: element?.payor,
              tracking_url: element?.tracking_url,
            };
            updatedItem.adAccount = updatedAdAccounts;
            updatedItems[index] = updatedItem;

            return updatedItems;
          });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    });

  useEffect(() => {
    if (isTiktokPlatformLaunch && data !== undefined) {
      if (data?.adAccount) {
        fetchPixels({
          variables: {
            advertiserId: data?.adAccount,
          },
        });
        if (isPageId) {
          fetchPages({
            variables: {
              advertiserId: data?.adAccount,
            },
          });
        }
      }
    }
  }, [data?.adAccount]);
  const currentAdAccount = isTiktokPlatformLaunch
    ? multiAdaccount_data?.filter(({ account_id }) => account_id === data?.adAccount)?.[0]
    : multiAdaccount_data
        ?.filter(({ id, res }) => id === profileId)?.[0]
        ?.res?.filter(({ adaccount_id }) => adaccount_id === data?.adAccount)?.[0];
  return (
    <div>
      {showAdaccountList ? (
        <div>
          <div
            className={cx("adAccount_dropdown", {
              inValid: inValidAdAccountIndex,
            })}

            // style={{
            //   maxWidth: data?.adAccount ? "calc(100% - 40px)" : "100%",
            // }}
          >
            <SelectInput
              search
              // loading={f360AdAccount_loading}
              // refetch={f360AdAccount_refetch}
              size="small"
              placeholder="Select AdAccount"
              name="adAccount"
              className="mb-0"
              value={data?.adAccount}
              options={
                adAccountsData?.length > 0
                  ? adAccountsData?.map((item) => ({
                      name: isTiktokPlatformLaunch
                        ? item?.name
                        : `${item?.name} - (${item?.timezone_name})`,
                      value: isTiktokPlatformLaunch ? item?.account_id : item?.adaccount_id,
                    }))
                  : []
              }
              onChange={(e) => {
                if (!isTiktokPlatformLaunch) {
                  setFormData((prevItems) => {
                    const updatedItems = [...prevItems];
                    const updatedItem = { ...updatedItems[index] };
                    const updatedAdAccounts = [...updatedItem.adAccount];
                    updatedAdAccounts[adAccountIndex] = {
                      adAccount: e,
                      customConversions: {},
                      url: [],
                      countries: [],
                      multiCountries: false,
                      campaignStatus: "PAUSED",
                    };
                    updatedItem.adAccount = updatedAdAccounts;
                    updatedItems[index] = updatedItem;

                    return updatedItems;
                  });
                  // fetchadAccountCache({
                  //   variables: { ad_account: e, product_id: productId },
                  // });
                  // if (e) {
                  //   customConversionsPixels({
                  //     variables: {
                  //       accountId: "act_" + e,
                  //       hard_sync: true,
                  //       product_id: productId,
                  //     },
                  //   });
                  // }
                } else {
                  setFormData((prevItems) => {
                    const updatedItems = [...prevItems];
                    const updatedAdAccounts = {
                      ...updatedItems[adAccountIndex],
                      adAccount: e,
                      identityType: "",
                      identityId: "",
                      tracking_url: "",
                    };
                    updatedItems[adAccountIndex] = updatedAdAccounts;

                    return updatedItems;
                  });
                }
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <LookDrawer {...creativeDrawerProps} className="no-body-space">
            {visibleCreativeDrawer && (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add Creatives",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    noSpace: true,
                    component: (
                      <AvatarUpload
                        launch
                        {...rowProps}
                        campaignIndex="0"
                        productId={productId}
                        groupSelection
                        onClose={onCloseCreativeDrawer}
                        setTiktokIdentityAvatar={setTiktokIdentityAvatar}
                        tiktokIdentityAvatar={tiktokIdentityAvatar}
                        onSubmitAddIdentity={onSubmitAddIdentity}
                        loading_identity_id={loading_identity_id}
                      />
                    ),
                  },
                ]}
              />
            )}
          </LookDrawer>
          <div className="launch-adaccount">
            <div
              className={isTiktokPlatformLaunch ? "d-flex mb-10" : "d-flex mb-30"}
              style={{ alignItems: "center" }}
            >
              {profileId && (
                <div className="flex-grow-1 flex justify-content-between">
                  <p className="mb-0">
                    {profileData?.fb360_profileGet?.filter(({ id }) => id === profileId)?.[0]?.name}{" "}
                    /{`${currentAdAccount?.name} (${currentAdAccount?.timezone_name})`}
                  </p>
                  <LookButton
                    iconType="reload"
                    tooltipTitle="Re-Sync Page, Pixel and other entities from facebook"
                    tooltipPlacement="left"
                    size="small"
                    tooltipDefaultOpen
                    className="border-0 w-auto"
                    onClick={() => {
                      customConversionsPixels({
                        variables: {
                          accountId: "act_" + data?.adAccount,
                          hard_sync: true,
                          product_id: productId,
                          profileId,
                        },
                      });
                    }}
                  />
                </div>
              )}
              {isTiktokPlatformLaunch && <p className="mb-0">{currentAdAccount?.name}</p>}
            </div>
            <Row gutter={[12, 12]}>
              {isTiktokPlatformLaunch && isPageId && (
                <Col span={12}>
                  <SelectInput
                    size="large"
                    label="Page Id"
                    search
                    placeholder="Select page"
                    name="pageId"
                    value={data?.pageId}
                    options={data?.tiktokPages?.list?.map(({ page_id }) => ({
                      name: page_id,
                      value: page_id,
                    }))}
                    onChange={(e) => {
                      updateData("pageId", e);
                    }}
                    loader={loading_Pages}
                  />
                </Col>
              )}
              {!isTiktokPlatformLaunch && (
                <Col span={12}>
                  <SelectInput
                    search
                    size="large"
                    label="Page Id"
                    placeholder="Select page"
                    name="pageId"
                    value={data?.pageId}
                    options={data?.customConversions?.pages?.map(({ id, name }) => ({
                      name: name + " (" + id + ")",
                      value: id,
                    }))}
                    onChange={(e) => {
                      updateData("pageId", e);
                    }}
                    loader={loading_fbpixels}
                  />
                </Col>
              )}

              <Col span={isTiktokPlatformLaunch && !isPageId ? 24 : 12}>
                {isTiktokPlatformLaunch && (
                  <SelectInput
                    label="Pixel"
                    size="large"
                    search
                    placeholder="Select Pixel"
                    name="pixel"
                    value={data?.pixel}
                    loading={loading_pixels}
                    loader={loading_pixels}
                    options={
                      isTiktokPlatformLaunch
                        ? data?.tiktokPixels?.map(({ pixelId, pixelName }) => ({
                            name: pixelName,
                            value: pixelId,
                          }))
                        : data?.customConversions?.pixels?.map(({ id, name }) => ({
                            name: name + " (" + id + ")",
                            value: id,
                          }))
                    }
                    onChange={(e) => {
                      updateData("pixel", e);
                    }}
                  />
                )}
                {!isTiktokPlatformLaunch && (
                  <SelectInput
                    label="Pixel"
                    search
                    multiple
                    size="large"
                    placeholder="Select Pixel"
                    name="pixel"
                    value={data?.pixel}
                    // loading={loading_pixels}
                    options={data?.customConversions?.pixels?.map(({ id, name }) => ({
                      name: name + " (" + id + ")",
                      value: id,
                    }))}
                    onChange={(e) => {
                      updateData("pixel", e);
                    }}
                    loader={loading_fbpixels}
                  />
                )}
              </Col>
            </Row>
            {!isTiktokPlatformLaunch && (
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <SelectInput
                    label="Custom Event Type"
                    search
                    size="large"
                    placeholder="Select custom event type"
                    name="customEventType"
                    value={data?.customEventType}
                    options={LAUNCH_CUSTOM_EVENT_ENUM}
                    onChange={(e) => {
                      updateData("customEventType", e);
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[12, 12]}>
              {data?.customEventType === "OTHER" && (
                <Col span={24}>
                  <p className="mb-5">Custom Events</p>
                  <Radio.Group
                    onChange={(e) => setCustomConversions(e.target.value)}
                    value={customConversions}
                    className="mb-20"
                  >
                    <Space direction="horizontal">
                      <Radio value={LAUNCH_CUSTOM_CONVERSION_TYPE}>
                        {LAUNCH_CUSTOM_CONVERSION_TYPE}
                      </Radio>
                      <Radio value={LAUNCH_CUSTOM_EVENT_TYPE}>{LAUNCH_CUSTOM_EVENT_TYPE}</Radio>
                    </Space>
                  </Radio.Group>
                </Col>
              )}
            </Row>
            {isTiktokPlatformLaunch && (
              <Row gutter={12}>
                <Col span={12}>
                  <SelectInput
                    label="Identity Type"
                    search
                    size="large"
                    placeholder="Select identity type"
                    name="identityType"
                    value={data?.identityType}
                    options={ad?.identity_type}
                    onChange={(e) => {
                      updateData("identityType", e);

                      if (e === "CUSTOMIZED_USER") {
                        callIdentityNames({
                          variables: {
                            advertiserId: data?.adAccount,
                            identityType: e,
                          },
                        });
                      }
                    }}

                    // loader={loading_customConversionsPixels}
                  />
                </Col>
                {data?.identityType === "CUSTOMIZED_USER" && data?.adAccount && (
                  <>
                    <Col span={12}>
                      <p style={{ marginBottom: "5px" }}> Identity Name</p>
                      <Select
                        style={{
                          width: "100%",
                        }}
                        size="large"
                        value={data?.identityId}
                        onChange={(e) => {
                          updateData("identityId", e);
                        }}
                        placeholder="Select Identity Name"
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: "8px 0",
                              }}
                            />
                            <Space
                              style={{
                                padding: "0 12px 12px",
                              }}
                            >
                              {loading_identity_id ? (
                                <Loader elementCenter />
                              ) : (
                                <>
                                  <Input
                                    placeholder="Please enter identity name"
                                    ref={inputRef}
                                    value={identityName}
                                    onChange={onNameChange}
                                  />
                                  <Button
                                    type="text"
                                    className="identity-button"
                                    icon={<PlusOutlined />}
                                    disabled={!identityName || loading_identity_id}
                                    onClick={() =>
                                      addNewIdentity({
                                        variables: {
                                          displayName: identityName,
                                          advertiserIds: data?.adAccount,
                                          imageUri: TIKTOK_AVATAR_URL,
                                        },
                                      })
                                    }
                                  >
                                    Add Identity
                                  </Button>
                                </>
                              )}
                            </Space>
                          </>
                        )}
                        options={data_identityNames?.tiktokLaunchIdentityId?.map((identity) => ({
                          label: identity?.displayName,
                          value: identity?.identityId,
                        }))}
                      />
                    </Col>
                  </>
                )}
              </Row>
            )}
            <Row>
              <>
                <Col span={24}></Col>
                {data?.customEventType === "OTHER" && (
                  <>
                    {customConversions !== "Custom Conversions" ? (
                      <Col span={24}>
                        <SelectInput
                          label="Custom Events"
                          search
                          size="large"
                          placeholder="Select Custom Events"
                          name="customEventStr"
                          value={data?.customEventStr}
                          options={data?.customConversions?.customEvents?.map((item) => ({
                            name: item,
                            value: item,
                          }))}
                          onChange={(e) => {
                            updateData("customEventStr", e);
                          }}
                          loader={loading_fbpixels}
                        />
                      </Col>
                    ) : (
                      <Col span={24}>
                        <SelectInput
                          label="Custom Conversion"
                          search
                          size="large"
                          placeholder="Select Custom Conversion"
                          name="customConversionId"
                          value={data?.customConversionId}
                          options={data?.customConversions?.customConversions?.map(
                            ({ id, name }) => ({
                              name: name,
                              value: name,
                            })
                          )}
                          onChange={(e) => {
                            updateData("customConversionId", e);
                          }}
                          loader={loading_fbpixels}
                        />
                      </Col>
                    )}
                  </>
                )}
              </>
            </Row>
            {(data?.multiCountries
              ? data?.countries?.some((item) => EUROPEAN_COUNTRIES.includes(item))
              : show_beneficiary) && (
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <LookTextInput
                    name="beneficiary"
                    label="Beneficiary"
                    size="large"
                    placeholder="Enter beneficiary"
                    value={data?.beneficiary}
                    onChange={(e) => {
                      updateData("beneficiary", e.target.value);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <LookTextInput
                    name="payor"
                    label="Payor"
                    size="large"
                    className="mb-0"
                    placeholder="Enter payor"
                    value={data?.payor}
                    onChange={(e) => {
                      updateData("payor", e.target.value);
                    }}
                  />
                </Col>
              </Row>
            )}

            {/* {serviceType === SERVICE_TYPE_LEAD_GEN && (
        <div className="mb-20">
          <LookTable
            tableProps={{
              initialColumns: initialColumns,
              recordData: bucketData?.bucket_content?.map((data, index) => ({
                ...data,
                key: index,
                urlIndex: index,
                adAccount: adAccount,
              })),
            }}
            isTableFilter={false}
            scrollHeight="calc(100vh - 211px)"
            isRowSelection
          />
        </div>
      )} */}

            {serviceType === SERVICE_TYPE_VS && (
              <Row gutter={[12, 12]}>
                <>
                  <Col span={12}>
                    <SelectInput
                      label="EPC Key"
                      search
                      size="large"
                      placeholder="Select EPC KEY"
                      name="epcKey"
                      value={data?.epcKey}
                      options={vsUrlPlatform?.map(({ epc_key }) => ({
                        name: epc_key,
                        value: epc_key,
                      }))}
                      onChange={(e) => {
                        updateData("epcKey", e);
                      }}
                      loader={loading_fbpixels}
                    />
                  </Col>

                  {data?.epcKey && (
                    <Col span={12}>
                      <SelectInput
                        name="pixelValue"
                        size="large"
                        mode="tags"
                        label="Pixel Value"
                        className="look-form-input"
                        value={data?.pixelValue}
                        onChange={(e) => {
                          updateData("pixelValue", e);
                        }}
                        // placeholder="Enter old password"
                      />
                    </Col>
                  )}
                </>
              </Row>
            )}
            {
              <Col span={12}>
                {instantLaunch && serviceType === SERVICE_TYPE_LEAD_GEN ? (
                  <LookTextInput
                    name="tracking_url"
                    label="Tracking Url"
                    size="large"
                    className="look-form-input"
                    placeholder="Enter tracking url"
                    value={data?.tracking_url}
                    onChange={(e) => {
                      updateData("tracking_url", e?.target?.value);
                    }}
                  />
                ) : (
                  serviceType !== SERVICE_TYPE_LEAD_GEN && (
                    <SelectInput
                      label={serviceType === SERVICE_TYPE_SAS ? "Tracking url" : "Domain"}
                      search
                      size="large"
                      placeholder={
                        serviceType === SERVICE_TYPE_SAS ? "Select tracking url" : "Select domain"
                      }
                      name="tracking_url"
                      value={data?.tracking_url}
                      options={
                        serviceType === SERVICE_TYPE_SAS
                          ? sasTrackingUrl?.sasTrackingUrls?.map(({ domain }) => ({
                              name: domain,
                              value: domain,
                            }))
                          : vsBrands?.vs_brandGetByAdvertiser?.map((item) => ({
                              name: item?.name,
                              value: item?.website,
                            }))
                      }
                      onChange={(e) => {
                        updateData("tracking_url", e);
                        setFormData((prevItems) => {
                          const updatedItems = [...prevItems];
                          const updatedItem = { ...updatedItems[index] };
                          const updatedAdAccounts = [...updatedItem.adAccount];
                          updatedAdAccounts[adAccountIndex] = {
                            ...updatedAdAccounts[adAccountIndex],
                            tracking_url: e,
                            ...(serviceType === SERVICE_TYPE_VS && {
                              domainNickName: vsBrands?.vs_brandGetByAdvertiser?.filter(
                                (brands) => brands?.website === e
                              )?.[0]?.nickname,
                            }),
                          };
                          updatedItem.adAccount = updatedAdAccounts;
                          updatedItems[index] = updatedItem;

                          return updatedItems;
                        });
                      }}
                      loader={sasTrackingLoading}
                    />
                  )
                )}
              </Col>
            }
          </div>
          {!isTiktokPlatformLaunch && (
            <div className="d-flex mb-20">
              <div className="d-flex">
                <p className="mr-5 mb-0">Campaign Status</p>
                <Switch
                  checked={data?.campaignStatus === "ACTIVE"}
                  onChange={(e) => updateData("campaignStatus", e ? "ACTIVE" : "PAUSED")}
                />
              </div>
            </div>
          )}

          {serviceType === SERVICE_TYPE_LEAD_GEN && (
            <div className="mb-20">
              {/* <LookTable
                tableProps={{
                  initialColumns: initialColumns,
                  recordData: bucketData?.bucket_content?.map((data, index) => ({
                    ...data,
                    key: index,
                    urlIndex: index,
                    adAccount: data?.adAccount,
                  })),
                }}
                isTableFilter={false}
                scrollHeight="calc(100vh - 211px)"
                // isRowSelection
              /> */}
              {bucketData?.bucket_content?.map((bucketData, bucketIndex) => {
                const isExpanded = expandedIndex === bucketIndex;
                const urlData = data?.url?.find(({ index }) => index === bucketIndex);

                const handleToggleExpand = () => {
                  handleToggle(bucketIndex);
                };

                const handleUrlParamsChange = (e) => {
                  const newValue = e.target.value;
                  setFormData((prevItems) => {
                    const updatedItems = [...prevItems];
                    const updatedItem = { ...updatedItems[index] };
                    const updatedAdAccounts = [...updatedItem.adAccount];
                    // const updatedUrlItems = updatedAdAccounts[adAccountIndex]?.url?.map((item) =>
                    //   item.index === index
                    //     ? { ...item, params: newValue, name: bucketData.name }
                    //     : item
                    // );
                    let updatedUrlItems = [];
                    if (updatedAdAccounts[adAccountIndex]?.url?.length === 0) {
                      updatedUrlItems.push({
                        index: bucketIndex,
                        params: e.target.value,
                        name: bucketData?.name,
                      });

                      updatedItem.adAccount = updatedAdAccounts;
                    } else {
                      updatedUrlItems = updatedAdAccounts[adAccountIndex]?.url?.map((item) =>
                        item?.index === bucketIndex
                          ? { ...item, params: newValue, name: bucketData?.name }
                          : item
                      );

                      const matchingItemIndex = updatedAdAccounts[adAccountIndex]?.url?.find(
                        (item) => item?.index === bucketIndex
                      );

                      if (!matchingItemIndex) {
                        updatedUrlItems?.push({
                          index: bucketIndex,
                          params: newValue,
                          name: bucketData.name,
                        });

                        // updatedItems.push(updatedItem);
                      }
                    }
                    updatedAdAccounts[adAccountIndex] = {
                      ...updatedAdAccounts[adAccountIndex],
                      url: updatedUrlItems,
                    };
                    updatedItem.adAccount = updatedAdAccounts;
                    updatedItems[index] = updatedItem;
                    return updatedItems;
                  });
                };

                return (
                  <div key={index} className="launch_profiles">
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <div
                          className={cx("adAccount", {
                            expanded: isExpanded,
                            inValid: !urlData?.links || !urlData?.params,
                          })}
                          onClick={handleToggleExpand}
                        >
                          {isExpanded ? (
                            <UpOutlined className="mr-10" />
                          ) : (
                            <DownOutlined className="mr-10" />
                          )}
                          <p className="mb-0">Urls & Params</p>
                        </div>
                        {isExpanded && (
                          <div
                            className={cx("launch_tracking_url_params", {
                              inValid: !urlData?.links || !urlData?.params,
                            })}
                          >
                            <p className="mb-5"> Tracking Url</p>
                            <LeadgenUrlDropdown
                              url={urlData}
                              otherProps={{ ...bucketData, urlIndex: bucketIndex }}
                              {...props}
                            />
                            <p className="mb-5"> URL Params</p>
                            <LookTextInput
                              size="large"
                              name="urlParams"
                              placeholder="Enter url parameters"
                              className="mb-0"
                              value={urlData?.params}
                              onChange={handleUrlParamsChange}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          )}
          {/* {serviceType === SERVICE_TYPE_LEAD_GEN && (
            <div className="mb-20">
              <LookTable
                tableProps={{
                  initialColumns: initialColumns,
                  recordData: bucketData?.bucket_content?.map((data, index) => ({
                    ...data,
                    key: index,
                    urlIndex: index,
                    adAccount: data?.adAccount || "",
                  })),
                }}
                isTableFilter={false}
                scrollHeight="calc(100vh - 211px)"
                isRowSelection
              />
            </div>
          )} */}
        </>
      )}
    </div>
  );
}

export default MultiAdAccountForm;
