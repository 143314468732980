import { Col, Row } from "antd";
import LookButton from "components/LookButton";

export default function DrawerFooterBotton(props) {
  const { buttons = [] } = props;
  const colSpan = buttons?.length === 0 ? 24 : parseInt(24 / buttons?.length);

  const renderButtons = () =>
    buttons?.map((buttonData) => {
      const {
        label = "",
        className = "",
        onClick,
        size,
        type,
        disabled = false,
        htmlType,
        loading,
      } = buttonData;
      return (
        <Col span={colSpan}>
          <LookButton
            className={className}
            onClick={onClick}
            block
            size={size}
            type={type}
            disabled={disabled}
            htmlType={htmlType}
            loading={loading}
          >
            {label}
          </LookButton>
        </Col>
      );
    });

  return (
    <div className="drawer-footer-btn-content">
      <div className="flex-grow-1">
        <Row gutter={12}>{renderButtons()}</Row>
      </div>
    </div>
  );
}
