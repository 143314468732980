import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  ADLIB_VIEW_CARD,
  ADLIB_VIEW_COMPETITOR,
  ADLIB_VIEW_DOMAIN,
  ADLIB_VIEW_KEYWORD,
  ADLIB_VIEW_LANDING_PAGE,
  ADLIB_VIEW_PAGE,
  SAS_USER,
} from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const AdLibraryCompetiters = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdLibraryCompetiters" */ "pages/dashboardPages/AdLibrary/AdLibraryCompetiters"
    )
  )
);
const AdLibraryCreatives = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdLibraryCreatives" */ "pages/dashboardPages/AdLibrary/AdLibraryCreatives"
    )
  )
);
const AdLibraryDomains = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdLibraryDomains" */ "pages/dashboardPages/AdLibrary/AdLibraryDomains"
    )
  )
);
const AdLibraryKeywords = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdLibraryKeywords" */ "pages/dashboardPages/AdLibrary/AdLibraryKeywords"
    )
  )
);
const AdLibraryLandingPages = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdLibraryLandingPages" */ "pages/dashboardPages/AdLibrary/AdLibraryLandingPages"
    )
  )
);
const AdLibraryPages = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "AdLibraryPages" */ "pages/dashboardPages/AdLibrary/AdLibraryPages")
  )
);

const adLibraryRoutes = [
  route(
    "/ad-library/competiters",
    AdLibraryCompetiters,
    ROUTE_DASHBOARD,
    false,
    ADLIB_VIEW_COMPETITOR
  ),
  route("/ad-library/creatives", AdLibraryCreatives, ROUTE_DASHBOARD, false, ADLIB_VIEW_CARD),
  route("/ad-library/domains", AdLibraryDomains, ROUTE_DASHBOARD, false, ADLIB_VIEW_DOMAIN),
  route("/ad-library/keywords", AdLibraryKeywords, ROUTE_DASHBOARD, false, [
    ADLIB_VIEW_KEYWORD,
    SAS_USER,
  ]),
  route("/ad-library/pages", AdLibraryPages, ROUTE_DASHBOARD, false, ADLIB_VIEW_PAGE),
  route(
    "/ad-library/landing-pages",
    AdLibraryLandingPages,
    ROUTE_DASHBOARD,
    false,
    ADLIB_VIEW_LANDING_PAGE
  ),
];

export default adLibraryRoutes;
