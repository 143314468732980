import { Button } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { openNotification } from "utils";
import Compressor from "compressorjs";
import ResizeCall from "./resizeCall";
import { S3_MULTI_FILE, S3_MULTI_FILE_UPLOAD } from "shared/gql/gqlSchema/mediaGql";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";
import { useApiToStore } from "hooks";
import { API_STORE_CMS_IMAGES } from "hooks/useApiToStore/keys";

export default function FileHandle(props) {
  const { apiCall: imagesSizes = () => {}, refetch: imagesSizesRefetch } =
    useApiToStore(API_STORE_CMS_IMAGES);
  const {
    image,
    setImage = () => {},
    serviceType = "CMS",
    resize = true,
    disabled = false,
    chooseNew = false,
  } = props;

  useEffect(() => {
    if (resize && image) {
      imagesSizes();
    }
  }, [resize, image]);

  const [selectedFile, setSelectedFile] = useState();
  const [uploadingFileLoader, setUploadFileLoader] = useState(false);

  const [
    s3MultiFile,
    {
      data: data_S3_MULTI_FILE = [],
      loading: loading_S3_MULTI_FILE,
      error: error_S3_MULTI_FILE,
      refetch: refetch__S3_MULTI_FILE,
    },
  ] = useCustomLazyQuery(S3_MULTI_FILE, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (e) => {
      setImage(e?.s3MultiFile[0]?.public_url);
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function fileSelect(e) {
    setSelectedFile(e.target.files);
    uploadFile_Stage1(e.target.files);
  }

  const [s3MultiFileUpload, { loading: loading_S3_MULTI_FILE_UPLOAD }] = useCustomMutation(
    S3_MULTI_FILE_UPLOAD,
    {
      onCompleted(e) {
        // openNotification({ type: "success", message: "Generate Upload Url Success" });
        setUploadFileLoader(true);
        fileUploading(e?.s3MultiFileUpload);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  function fileUploading(fileData) {
    const requestPromise = [];

    const uploadPaths = [];

    fileData.forEach(({ url, path }, index) => {
      uploadPaths.push(path);
      var config = {
        method: "put",
        url: url,
        headers: { "Content-Type": "" },
        data: selectedFile[index],
      };

      requestPromise.push(
        axios(config)
          .then(function (response) {
            // openNotification({ type: "success", message: "Upload File To Server success" });
            setUploadFileLoader(false);
            // uploadoadFile_Stage3(path);
            // console.log("CMS response", response);
          })
          .catch(function (error) {
            // console.log("error", error);
            openNotification({ type: "error", message: error.message });
            setUploadFileLoader(false);
          })
      );
    });

    Promise.all(requestPromise)
      .then((e) => {
        // console.log("Promise then", e);

        s3MultiFile({ variables: { path: uploadPaths } });
      })
      .catch((e) => {
        // console.log("Promise catch", e);
      });
  }

  function uploadFile_Stage1(selectedFileObject) {
    let tt = [];

    // for (const [key, value] of Object.entries(selectedFile)) {
    for (const [key, value] of Object.entries(selectedFileObject)) {
      // console.log(`${key}: ${value.name}`);
      tt.push(value.name);
    }

    s3MultiFileUpload({ variables: { file_name: tt, service_name: serviceType } });
  }
  const s3MultiFileQueryProps = {
    data_S3_MULTI_FILE,
    loading_S3_MULTI_FILE,
    error_S3_MULTI_FILE,
    refetch__S3_MULTI_FILE,
  };

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    const imageType = image?.type || "";
    if (
      !(
        imageType === "image/png" ||
        imageType === "image/gif" ||
        imageType === "image/jpeg" ||
        imageType === "image/jpg"
      )
    ) {
      openNotification({ type: "error", message: "Only jpg/jpeg and png files are allowed!" });
    }
    if (!image) {
      return null;
    }
    if (image) {
      new Compressor(image, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.

          setSelectedFile([compressedResult]);
          console.log("CMS", { image, compressedResult });
          uploadFile_Stage1([compressedResult]);
        },
      });
    }
  };

  const commonLoading =
    loading_S3_MULTI_FILE_UPLOAD || uploadingFileLoader || loading_S3_MULTI_FILE;

  /*
   * loading_S3_MULTI_FILE_UPLOAD for Genrating upload url
   * uploadingFileLoader for Uploading File
   * loading_S3_MULTI_FILE for Genrating final url
   */

  return (
    <>
      <div className="image-file-upload-handel">
        {commonLoading && (
          <div class="progress">
            <div class="indeterminate"></div>
          </div>
        )}
        {image ? (
          <div className="view-image">
            {
              // TODO : Need to check if this is used somewhere else
            }
            <img src={image} alt="featured-image" className="w-100" />
          </div>
        ) : (
          <label
            className={cx("cms-image-selection", { "cursor-no-drop": commonLoading || disabled })}
          >
            <p className="text-center mt-40 mb-2">
              <ActionIconRender className="font-size-22" iconType="plus"></ActionIconRender>
              <br />
              Choose New Image
            </p>
            <input
              type="file"
              disabled={commonLoading || disabled}
              // multiple
              accept="image/png, image/gif, image/jpeg, image/jpg"
              required
              // onChange={fileSelect}
              onChange={(event) => handleCompressedUpload(event)}
            />
          </label>
        )}

        {image && (
          <>
            {chooseNew ? (
              <label
                className={cx("cms-image-selection text-label", {
                  "cursor-no-drop": commonLoading || disabled,
                })}
              >
                <p>Choose New Image</p>
                <input
                  type="file"
                  disabled={commonLoading || disabled}
                  // multiple
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  required
                  // onChange={fileSelect}
                  onChange={(event) => handleCompressedUpload(event)}
                />
              </label>
            ) : (
              <>
                <Button className="px-0" type="link" onClick={() => setImage("")}>
                  Remove this image
                </Button>
              </>
            )}
          </>
        )}
        {/* {image && resize && (
          <>
            <ResizeCall image={image} imagesSizesRefetch={imagesSizesRefetch} />
          </>
        )} */}
      </div>
    </>
  );
}
