import { useCustomQuery } from "hooks/apolloClientHooks";
import { useState } from "react";
import {
  GET_VOLUUM_ADACCOUNT_REPORT,
  GET_VOLUUM_ADSET_REPORT,
  GET_VOLUUM_AD_REPORT,
  GET_VOLUUM_CAMPAIGN_REPORT,
  GET_VOLUUM_HOURLY_REPORT,
  GET_VOLUUM_DAILY_REPORT, //this
  GET_VOLUUM_HOUR_OF_THE_DAY_REPORT, //this
} from "shared/gql/gqlSchema/tiktok";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_PERFORMANCE_IS_DAILY,
  REPORT_TYPE_PERFORMANCE_IS_HOURLY,
  REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY,
} from "shared/SharedKeys";

export const TIKTOK_VOLUUM_REPORT_QUERY = "Voluum";
export const SAS_REPORT_QUERY_PERFORMANCE = "Performance";

function queryType(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return {
        query: GET_VOLUUM_ADACCOUNT_REPORT,
        element: "voluum_ad_account_reporting",
      };
    case REPORT_TYPE_AD:
      return {
        query: GET_VOLUUM_AD_REPORT,
        element: "voluum_ad_reporting",
      };
    case REPORT_TYPE_AD_ADSET:
      return {
        query: GET_VOLUUM_ADSET_REPORT,
        element: "voluum_ad_set_reporting",
      };
    case REPORT_TYPE_CAMPAIGN:
      return {
        query: GET_VOLUUM_CAMPAIGN_REPORT,
        element: "voluum_campaign_reporting",
      };

    default: {
      return {};
    }
  }
}

function performanceQueryType(performanceType) {
  switch (performanceType) {
    case REPORT_TYPE_PERFORMANCE_IS_DAILY:
      return {
        query: GET_VOLUUM_DAILY_REPORT,
        element: "voluum_daily_report",
      };

    case REPORT_TYPE_PERFORMANCE_IS_HOURLY:
      return {
        query: GET_VOLUUM_HOURLY_REPORT,
        element: "voluum_hourly_report",
      };
    case REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY:
      return {
        query: GET_VOLUUM_HOUR_OF_THE_DAY_REPORT,
        element: "voluum_hour_of_day_report",
      };
    default: {
      return {};
    }
  }
}

export default function useNewLeadgenReportsQuery(type, extraParams = {}) {
  const { variables, from, to, timezone, performanceType, platform_id, asset, asset_id } =
    extraParams;
  const [revenueReport, setRevenueReport] = useState([]);

  let queryObject = queryType(type);

  if (performanceType) {
    queryObject = performanceQueryType(performanceType);
  }
  const { loading: loading_revenue, refetch } = useCustomQuery(queryObject?.query, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const reportElement = e?.[queryObject?.element];

      if (reportElement) {
        setRevenueReport(
          reportElement?.map((d) => ({
            ...d,
            campaignId: d?.campaign_id,
            adsetId: d?.ad_set_id,
            adId: d?.ad_id,
          }))
        );
      }
    },

    variables: {
      from,
      to,
      timezone,
      platform_id,
      asset,
      asset_id,
      ...variables,
      ...queryObject?.variables,
    },
  });

  const refetch_revenue = () => {
    setRevenueReport([]);
    refetch();
  };

  return {
    loading_revenue,
    refetch_revenue,
    revenueReport,
    setRevenueReport,
  };
}
