import { useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";
import cx from "classnames";
import alasql from "alasql";
import { fbAdsManagerRedirect, secondsToHMS } from "utils";
import { useParams } from "react-router";
import DetailView from "./DetailView";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomQuery } from "hooks/apolloClientHooks";
import {
  F360_MANAGE_LEADGEN_REPORT,
  USER_WITH_NO_ACTION,
} from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import { PRODUCT_ID_LEADGEN, REPORT_TYPE_AD, REPORT_TYPE_GENERAL } from "shared/SharedKeys";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import { GET_F360_AD_GROUPED_REPORT, GET_F360_AD_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import {
  ADBY_REPORT_TYPE_ADACCOUNT,
  ADBY_REPORT_TYPE_CAMPAIGN,
  ADBY_REPORT_TYPE_ADSET,
} from "hooks/facebookHooks/useFacebookSpendReportsQuery";
import {
  ActionsForFacebook,
  useUpdateFB360Status,
} from "components/ReportUtils/ActionsForFacebook";
import { fbGroupForStatus } from "components/ReportUtils/UpdateCampaignBudget/utils";
import { useNewLeadgenReportsQuery } from "hooks/tiktokHooks";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import NewRuleForm from "components/LookTable/RuleEngine/NewRules";
import RuleLinkedItems from "components/LookTable/RuleEngine/RuleLinkedItems";
import ActionIconRender from "components/ActionIconRender";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";
import { Button, Tooltip } from "antd";
import RingbaCallReport from "../RingbaCallReport";
import {
  LEADGEN_FACEBOOK_PLATFORM_ID,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_AD,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
} from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
export default function Ads(props) {
  const {
    isCampaignFiltered = false,
    isBulkCampaignFiltered = false,
    isAdsetFiltered = false,
    isBulkAdsetFiltered = false,
    isAdAccountFiltered = false,
    isBulkAdAccountFiltered = false,
    performance = false,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    bulkProps = [],
  } = props;
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const params = useParams();

  const [spendReport, setSpendReport] = useState([]);
  const { updateFb360Status } = useUpdateFB360Status(REPORT_TYPE_AD, {
    setSpendReport,
  });
  const [ruleSetting, setRuleSetting] = useState(null);
  const [visible, setVisible] = useState(false);
  const { havePermission: isWithoutAction } = usePermissionCheck(USER_WITH_NO_ACTION);
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const [visibleRingbaCallDrawer, setVisibleRingbaCallDrawer] = useState(false);

  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userSettings = useSelector((state) => state?.userSettings);

  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const withUser = params?.userID ? { user_id: params?.userID } : {};

  const spendDateApiVariables = {
    start: dateRangeTimezone?.rangeDates[0],
    end: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };

  const revenueDateApiVariables = {
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };

  let reportSettings = {
    reportApi: GET_F360_AD_REPORT,
    reportApiKey: "fb360_adReporting",
  };

  if (
    isCampaignFiltered ||
    isBulkCampaignFiltered ||
    isBulkAdsetFiltered ||
    isAdAccountFiltered ||
    isBulkAdAccountFiltered ||
    params?.accID
  ) {
    reportSettings = {
      reportApi: GET_F360_AD_GROUPED_REPORT,
      reportApiKey: "fb360_adGroupedReporting",
    };
  }

  // if (isCampaignFiltered || params?.accID) {
  //   reportSettings = {
  //     reportVariables: {
  //       ...spendDateApiVariables,
  //       asset_id: params?.accID || props?.campaignId,
  //       asset_type: params?.accID ? ADBY_REPORT_TYPE_ADACCOUNT : ADBY_REPORT_TYPE_CAMPAIGN,
  //     },
  //   };
  // }

  function getSpendAssetParams() {
    if (isCampaignFiltered || isBulkCampaignFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_CAMPAIGN,
        asset_id: isCampaignFiltered
          ? [props?.campaignId]
          : bulkProps?.map(({ campaignId }) => campaignId),
      };
    } else if (isAdsetFiltered || isBulkAdsetFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_ADSET,
        asset_id: isAdsetFiltered ? [props?.adsetId] : bulkProps?.map(({ adsetId }) => adsetId),
      };
    } else if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return { asset_type: ADBY_REPORT_TYPE_ADACCOUNT, asset_id: [params?.accID] };
    } else {
      return {};
    }
  }

  const spendExtraParams = getSpendAssetParams();

  const { loading: loading_GET_REPORT_BY_AD, refetch: refetch_GET_REPORT_BY_AD } = useCustomQuery(
    reportSettings.reportApi,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[reportSettings.reportApiKey];
        if (element) {
          setSpendReport(
            element?.map((item) => ({
              ...item,
              adId: item?.ad_id,
              adName: item?.ad_name,
              spendAdId: item?.ad_id,
              spendAccountId: item?.adaccount_id,
              clicksFB: item?.inline_link_clicks,
              spendFB: item?.spend,
            }))
          );
        }
      },
      variables: {
        ...spendDateApiVariables,
        ...spendExtraParams,
        ...withUser,
      },
    }
  );

  // const isExtraParams = isCampaignFiltered
  //   ? { asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN, asset_id: [props?.campaignId] }
  //   : params?.accID
  //   ? { asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT, asset_id: [params?.accID] }
  //   : {};

  function getRevenueAssetParams() {
    if (isCampaignFiltered || isBulkCampaignFiltered) {
      return {
        asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
        asset_id: isCampaignFiltered
          ? [props?.campaignId]
          : bulkProps?.map(({ campaignId }) => campaignId),
      };
    } else if (isAdsetFiltered || isBulkAdsetFiltered) {
      return {
        asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
        asset_id: isAdsetFiltered ? [props?.adsetId] : bulkProps?.map(({ adsetId }) => adsetId),
      };
    } else if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return { asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT, asset_id: [params?.accID] };
    } else {
      return {};
    }
  }

  const revenueExtraParams = getRevenueAssetParams();

  const { loading_revenue, refetch_revenue, revenueReport } = useNewLeadgenReportsQuery(
    REPORT_TYPE_AD,
    {
      ...revenueDateApiVariables,
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      variables: {
        ...withUser,
        ...revenueExtraParams,
      },
    }
  );

  const loading = loading_GET_REPORT_BY_AD || loading_revenue;

  function refetch() {
    refetch_GET_REPORT_BY_AD();
    refetch_revenue();
  }

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const action = performance
    ? []
    : permissions?.findIndex((name) => name === F360_MANAGE_LEADGEN_REPORT) !== -1 &&
      !isWithoutAction
    ? [
        {
          title: "Action",
          dataIndex: "action",
          nofilter: true,
          render: (action, actionProps) => (
            <div className="flex-center">
              <Tooltip title="Ringba Call Report">
                <Button
                  className="mr-10"
                  type="link"
                  onClick={() => showRingbaCallDrawer(actionProps)}
                  icon={<ActionIconRender iconType="barChartLine" />}
                />
              </Tooltip>
              <ActionsForFacebook
                reportType={REPORT_TYPE_AD}
                actionProps={actionProps}
                setSpendReport={setSpendReport}
              />
            </div>
          ),
          // render: (action, actionProps) => {
          //   const { adId, status } = actionProps;
          //   const callStatus =
          //     status !== "PAUSED"
          //       ? UPDATE_SPEND_STATUS_TYPE_PAUSED
          //       : UPDATE_SPEND_STATUS_TYPE_ACTIVE;

          //   return (
          //     <div className="text-center">
          //       {adId ? (
          //         <Tooltip title="Update" mouseEnterDelay={1}>
          //           <Switch
          //             disabled={callFbAssetState_loading}
          //             size="small"
          //             checked={actionProps.status !== "PAUSED"}
          //             onClick={() => callFbAssetStateSubmit(callStatus, [actionProps])}
          //           />
          //         </Tooltip>
          //       ) : (
          //         <Switch disabled size="small" />
          //       )}
          //       <Tooltip title="Duplicate Asset" mouseEnterDelay={1}>
          //         <Button
          //           className="ml-12 p-0 h-auto w-auto"
          //           type="link"
          //           onClick={() => onOpenDeleteModal(actionProps)}
          //           icon={<ActionIconRender iconType="duplicate" />}
          //         />
          //       </Tooltip>
          //     </div>
          //   );
          // },
          fixed: "right",
          width: 130,
          minWidth: 130,
        },
      ]
    : [];
  const initialColumns = [
    {
      title: "Ad Name",
      dataIndex: "finalAdName",
      key: "finalAdName",
      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return (
          <LookTableColumnRender
            title={value}
            {...otherProps}
            isProfit={PROFIT}
            isClick={
              LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
                ? undefined
                : () => showDrawerUpdate(otherProps)
            }
          />
        );
      },
      fixed: "left",
      width: 300,
      minWidth: 300,
    },
    {
      title: "Ad Id",
      dataIndex: "adId",
      key: "adId",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      minWidth: 170,
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revenue - b?.revenue,
    },
    {
      title: "Payout",
      dataIndex: "PAYOUT",
      key: "PAYOUT",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.PAYOUT - b?.PAYOUT,
    },
    {
      title: "Ringba Revenue",
      dataIndex: "payout_amount",
      key: "payout_amount",
      hidden: true,
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.payout_amount - b?.payout_amount,
    },
    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      // render: (value) => <LookTableColumnRender title={value} />,
      render: (value = 0, otherProps) => {
        // const profit = `$${value.replace("-", "")}`;
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
    },
    {
      title: "ROAS",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROI - b?.ROI,
      defaultSortOrder: "ascend",
    },
    {
      title: "Visits",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
    },
    {
      title: "Clicks",
      dataIndex: "revClicks",
      key: "revClicks",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.revClicks - b?.revClicks,
    },
    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
    },
    {
      title: "FB Results",
      dataIndex: "result",
      key: "result",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.result - b?.result,
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.conversions - b?.conversions,
    },
    {
      title: "Call Count",
      dataIndex: "call_count",
      key: "call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.call_count - b?.call_count,
      searchType: "number",
    },
    {
      title: "Converted Calls",
      dataIndex: "converted_calls",
      key: "converted_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.converted_calls - b?.converted_calls,
      searchType: "number",
    },
    {
      title: "Avg Handle Time",
      dataIndex: "avg_handle_time",
      key: "avg_handle_time",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Call Length",
      dataIndex: "call_length",
      key: "call_length",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Blocked Calls",
      dataIndex: "blocked_calls",
      key: "blocked_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.blocked_calls - b?.blocked_calls,
      searchType: "number",
    },
    {
      title: "Duplicate Calls",
      dataIndex: "duplicate_calls",
      key: "duplicate_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.duplicate_calls - b?.duplicate_calls,
      searchType: "number",
    },
    {
      title: "Ended Calls",
      dataIndex: "ended_calls",
      key: "ended_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.ended_calls - b?.ended_calls,
      searchType: "number",
    },
    {
      title: "Connected Call Count",
      dataIndex: "connected_call_count",
      key: "connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.connected_call_count - b?.connected_call_count,
      searchType: "number",
    },
    {
      title: "Non Connected Calls",
      dataIndex: "non_connected_call_count",
      key: "non_connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.non_connected_call_count - b?.non_connected_call_count,
      searchType: "number",
    },
    {
      title: "RPC (Call)",
      dataIndex: "RPC_CALL",
      key: "RPC_CALL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.RPC_CALL - b?.RPC_CALL,
      searchType: "number",
    },
    {
      title: "CPR (Call)",
      dataIndex: "CPR_CALL",
      key: "CPR_CALL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.CPR_CALL - b?.CPR_CALL,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
    },
    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
    },
    {
      title: "EPV",
      dataIndex: "EPV",
      key: "EPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPV - b?.EPV,
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPC - b?.CPC,
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPR - b?.CPR,
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPC - b?.EPC,
    },
    {
      title: "AD_CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
    },
    {
      title: "LP_CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
    },
    {
      title: "V Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.visits - b?.visits,
    },

    ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];

  if (!loading) {
    finalMerge = alasql(
      "SELECT *, b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b ON a.adId = b.adId",
      [spendReport, revenueReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        revenue = 0,
        visits = 0,
        revClicks = 0,
        conversions = 0,
        result = 0,
        ad_name = "",
        adName = "",
        ad_id,
        adId,
        call_count = 0,
        converted_calls = 0,
        payout_amount = 0,
        avg_handle_time = 0,
        blocked_calls = 0,
        call_length = 0,
        connected_call_count = 0,
        duplicate_calls = 0,
        ended_calls = 0,
        non_connected_call_count = 0,
        revAdAccountId,
        spendAccountId,
      } = element;

      const addSpendPer = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;
      const spendUpdate = (spendFB * addSpendPer).toFixed(2);

      const combinedRevenue = revenue + payout_amount;
      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;
      const CPC = parseFloat(spendUpdate) / parseFloat(revClicks);
      const PROFIT = parseFloat(combinedRevenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const PAYOUT = parseFloat(combinedRevenue) / parseFloat(conversions);
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const EPV = parseFloat(combinedRevenue) / parseFloat(clicksFB);
      const EPC = parseFloat(combinedRevenue) / parseFloat(revClicks);
      const AD_CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(clicksFB)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const CPR = parseFloat(spendUpdate) / parseFloat(conversions);
      const RPC_CALL = parseFloat(combinedRevenue) / parseFloat(call_count);
      const CPR_CALL = parseFloat(spendUpdate) / parseFloat(call_count);

      finalMergeData.push({
        ...element,
        adId: ad_id || adId,
        finalAdName: ad_name || adName,
        key: index,
        visits,
        revenue: combinedRevenue,
        clicksFB,
        impressionsFB,
        result,
        revClicks,
        conversions,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        PAYOUT: valueCheck(PAYOUT),
        CPV: valueCheck(CPV),
        EPV: valueCheck(EPV),
        CPC: valueCheck(CPC),
        EPC: valueCheck(EPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        CPR: valueCheck(CPR),
        call_count,
        converted_calls,
        avg_handle_time,
        blocked_calls,
        call_length,
        connected_call_count,
        duplicate_calls,
        ended_calls,
        non_connected_call_count,
        RPC_CALL: valueCheck(RPC_CALL),
        CPR_CALL: valueCheck(CPR_CALL),
        adAccountId: spendAccountId || revAdAccountId,
      });
    });
  }

  const finalFilterData = finalMergeData.filter(({ adName = "", adId = "", status = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";

    const searchCheck =
      adName?.toLowerCase()?.includes(searchValue) ||
      adId?.toLowerCase()?.includes(searchValue) ||
      status?.toLowerCase()?.includes(searchValue);

    if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
      return true;
    }

    return false;
  });

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_revenue = 0,
    total_visits = 0,
    total_revClicks = 0,
    total_conversions = 0,
    total_result = 0,
    total_call_count = 0,
    total_converted_call = 0,
    total_avg_handled_time = 0,
    total_blocked_calls = 0,
    total_call_length = 0,
    total_connected_call_count = 0,
    total_duplicate_calls = 0,
    total_ended_calls = 0,
    total_non_connected_call = 0,
    total_payout_amount = 0,
    total_RPC_call = 0,
    total_CPR_call = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    revenue = 0,
    visits = 0,
    revClicks = 0,
    conversions = 0,
    result = 0,
    call_count = 0,
    converted_calls = 0,
    avg_handle_time = 0,
    call_length = 0,
    blocked_calls = 0,
    connected_call_count = 0,
    duplicate_calls = 0,
    ended_calls = 0,
    non_connected_call_count = 0,
    payout_amount = 0,
    RPC_CALL = 0,
    CPR_CALL = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_revenue += parseFloat(revenue || 0);
    total_visits += parseFloat(visits || 0);
    total_revClicks += parseFloat(revClicks || 0);
    total_conversions += parseFloat(conversions || 0);
    total_result += parseFloat(result || 0);
    total_call_count += parseFloat(call_count || 0);
    total_converted_call += parseFloat(converted_calls || 0);
    total_avg_handled_time += parseFloat(avg_handle_time || 0);
    total_call_length += parseFloat(call_length || 0);
    total_blocked_calls += parseFloat(blocked_calls || 0);
    total_connected_call_count += parseFloat(connected_call_count || 0);
    total_duplicate_calls += parseFloat(duplicate_calls || 0);
    total_ended_calls += parseFloat(ended_calls || 0);
    total_non_connected_call += parseFloat(non_connected_call_count || 0);
    total_payout_amount += parseFloat(payout_amount || 0);
    total_RPC_call += parseFloat(RPC_CALL || 0);
    total_CPR_call += parseFloat(CPR_CALL || 0);
  }

  const totalData = finalFilterData?.length;
  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spendFB);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spendFB)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_CPV = parseFloat(total_spendFB) / parseFloat(total_clicksFB);
  const total_EPV = parseFloat(total_revenue) / parseFloat(total_clicksFB);
  const total_CPC = parseFloat(total_spendFB) / parseFloat(total_revClicks);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_AD_CTR = (parseFloat(total_clicksFB) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_clicksFB)) * 100;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;
  const total_CPR = parseFloat(total_spendFB) / parseFloat(total_conversions);
  const avg_RPC_call = parseFloat(total_RPC_call / totalData);
  const avg_CPR_call = parseFloat(total_CPR_call / totalData);

  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_AD);
        updateFb360Status("ACTIVE", groupedIds);
      },
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_AD);
        updateFb360Status("PAUSED", groupedIds);
      },
    },
    {
      option: "rules",
      title: "Rule-Engine",
      onClick: (e) => {
        const assetIds = e?.map((item) => {
          return { id: item.adId, name: item.adName };
        });

        setRuleSetting({
          report_type: "general",
          groupBy: "ad",
          platform: "fb",
          product: 1,
          data: assetIds,
        });
        setVisible(true);
      },
    },
    {
      option: "eye",
      title: "Redirect Ads",
      onClick: (e) => {
        const t = Object.groupBy(e, (el) => el.adaccount_id);
        for (var k in t) {
          fbAdsManagerRedirect({
            reportType: "ad",
            adaccountId: k,
            id: e?.map((el) => el.ad_id).join(","),
          });
        }
      },
    },
  ];

  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone?.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setRowProps({});
    setVisibleDetailDrawer(false);
  };

  const showRingbaCallDrawer = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone?.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleRingbaCallDrawer(true);
  };

  const onCloseRingbaCallDrawer = () => {
    setRowProps({});
    setVisibleRingbaCallDrawer(false);
  };
  const drawerPropsUpdate = {
    title: "Ads",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const ringbaDrawerProps = {
    title: "Ringba Call Report",
    visible: visibleRingbaCallDrawer,
    onClose: onCloseRingbaCallDrawer,
    size: 1000,
  };

  const summaryData = {
    spendFB: valueCheck(total_spendFB, true),
    clicksFB: valueCheck(total_clicksFB, true),
    impressionsFB: valueCheck(total_impressionsFB, true),
    revenue: valueCheck(total_revenue, true),
    visits: valueCheck(total_visits, true),
    revClicks: valueCheck(total_revClicks, true),
    conversions: valueCheck(total_conversions, true),
    result: valueCheck(total_result, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    PAYOUT: valueCheck(total_PAYOUT, true),
    CPV: valueCheck(total_CPV, true),
    EPV: valueCheck(total_EPV, true),
    CPC: valueCheck(total_CPC, true),
    EPC: valueCheck(total_EPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    CPR: valueCheck(total_CPR, true),
    call_count: total_call_count,
    converted_calls: total_converted_call,
    avg_handle_time: secondsToHMS(total_avg_handled_time),
    call_length: secondsToHMS(total_call_length),
    blocked_calls: total_blocked_calls,
    connected_call_count: total_connected_call_count,
    duplicate_calls: total_duplicate_calls,
    ended_calls: total_ended_calls,
    non_connected_call_count: total_non_connected_call,
    payout_amount: valueCheck(total_payout_amount, true),
    RPC_CALL: valueCheck(avg_RPC_call, true),
    CPR_CALL: valueCheck(avg_CPR_call, true),
  };
  const isRuleSettings = isWithoutAction
    ? {}
    : {
        ruleSetting: {
          groupBy: REPORT_TYPE_AD,
          reportType: REPORT_TYPE_GENERAL,
          productId: PRODUCT_ID_LEADGEN,
          platform: "fb",
        },
      };

  const tableMainProps = {
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
    persistKey,
    bulkActions: isWithoutAction ? [] : bulkActions,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
  ];
  function onClose() {
    setRuleSetting(null);
    setVisible(true);
  }
  const ruledraweProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 800,
    visible,
    onClose,
  };
  return (
    <div>
      <DetailView {...drawerPropsUpdate} {...rowPropsData} />
      <RingbaCallReport
        {...ringbaDrawerProps}
        {...rowPropsData}
        asset={LEADGEN_VOLUUM_REPORT_ASSET_TYPE_AD}
      />
      {ruleSetting && (
        <LookDrawer {...ruledraweProps}>
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Add/Update",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: (
                  <NewRuleForm
                    close={() => onClose()}
                    // {...props}
                    productId={ruleSetting?.product}
                    ids={ruleSetting?.data?.map(({ id }) => id)}
                    platform={ruleSetting?.platform}
                    grpby={ruleSetting?.groupBy}
                    report_type={ruleSetting?.report_type}
                    idtype={"inc"}
                    // copy={update}
                  />
                ),
              },
              {
                hoverTitle: "Rule Linked Items",
                icon: () => <ActionIconRender iconType="ordered-list" />,
                component: <RuleLinkedItems close={() => onClose()} ids={ruleSetting?.data} />,
              },
            ]}
          />
        </LookDrawer>
      )}
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
