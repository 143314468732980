import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { Col, Row, Segmented, Skeleton } from "antd";
import LookButton from "components/LookButton";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { AreaChartOutlined } from "@ant-design/icons";
import { openNotification } from "utils";
import { useSelector } from "react-redux";
import { GQL_GET_LAUNCH_USER_STATS } from "shared/gql/gqlSchema/launchGql";

export default function UserStats({ productId, selectedPlatform }) {
  const [userGraphData, setUserGraphData] = useState([]);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRange } = userSettings || {};

  const {
    data: USER_GRAPH_DATA = [],
    loading: USER_GRAPH_LOADING,
    error: USER_GRAPH_ERROR,
    refetch: USER_GRAPH_REFETCH,
  } = useCustomQuery(GQL_GET_LAUNCH_USER_STATS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.launchUserStats;
      if (element) {
        setUserGraphData(element?.map((item) => ({ ...item, username: item?.user?.name })));
      }
    },
    onError: (e) => {
      openNotification({ type: "error", message: e?.message });
    },
    variables: {
      productId,
      platformType: selectedPlatform,
      startDate: dateRange?.rangeDates[0],
      endDate: dateRange?.rangeDates[1],
    },
  });

  const config = {
    data: userGraphData,
    xField: "username",
    yField: "adCount",
    width: 500,
    height: 250,
    // seriesField: "adCount",
    color: ["#E8E8E8", "#49AA19"],
    smooth: true,
  };

  const renderSkeleton = () => (
    <Row gutter={28}>
      <Col span={24} className="mb-20" key={"overview"}>
        <div className="flex-center">
          <Skeleton.Node style={{ height: 250, width: 300 }} className="light" active={true}>
            <AreaChartOutlined
              active
              style={{
                fontSize: 40,
                color: "#bfbfbf",
              }}
            />
          </Skeleton.Node>
        </div>
      </Col>
    </Row>
  );

  return (
    <div className="overview-card overview-card-sm">
      <div className="flex-between">
        <h3>User Stats</h3>
        <div className="d-flex mr-10">
          <LookButton
            onClick={() => USER_GRAPH_REFETCH()}
            size="small"
            iconType="reload"
            className="ml-10"
          />
        </div>
      </div>

      {USER_GRAPH_LOADING ? (
        renderSkeleton()
      ) : userGraphData?.length ? (
        <Column {...config} />
      ) : (
        <div style={{ height: 250 }} className="h-100 flex-center">
          <h3>No data for the selected date</h3>
        </div>
      )}
    </div>
  );
}
