import * as types from "../actions/actionTypes";

export default function g360Reducer(state = { refetch_google: false }, action) {
  switch (action.type) {
    case types.G360_PROFILE_REFETCH_TRUE:
      return { refetch_google: true };
    case types.G360_PROFILE_REFETCH_FALSE:
      return { refetch_google: false };
    default:
      return state;
  }
}
