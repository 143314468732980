import PerformanceReport from "./PerformanceReport";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
export default function DetailView(props) {
  const { visible = false, name = "", onClose } = props;
  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={name}
    >
      {visible && (
        <div className="f-performance-tab">
          <div className="side">
            <div className="icon-wrap active">
              <ActionIconRender iconType="pic-left" />
            </div>
          </div>
          <div className="content">
            <PerformanceReport {...props} />
          </div>
        </div>
      )}
    </LookDrawer>
  );
}
