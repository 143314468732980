import moment from "moment";
import * as types from "../actions/actionTypes";

export const dateFormat = "YYYY-MM-DD";
export const dateMonthYearFormat = "YYYY-MM";
export const dateYearFormat = "YYYY";
export const dateQuarterFormat = "YYYY-[Q]Q";
export const dateOnlyQuarterFormat = "[Q]Q";

export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const dateTimeFormatRuleEngine = "YYYY-MM-DD, HH:mm:ss";
export const dateTimeFormatWithoutSecond = "YYYY-MM-DD HH:mm";
export const defaultDate = [moment().format(dateFormat), moment().format(dateFormat)];
export const defaultMonth = moment().format(dateMonthYearFormat);
export const defaultquarter = moment().format(dateQuarterFormat);
export const defaultDateOnly = moment().format(dateFormat);
export const defaultTimeZone = "America/Los_Angeles";
export const defaultITaskTimeZone = "Asia/Kolkata";
export const defaultPagination = {
  main: { page: 1, size: 100 },
  level1: { page: 1, size: 100 },
  level2: { page: 1, size: 100 },
  level3: { page: 1, size: 100 },
  level4: { page: 1, size: 100 },
  level5: { page: 1, size: 100 },
  level6: { page: 1, size: 100 },
  level7: { page: 1, size: 100 },
  level8: { page: 1, size: 100 },
  level9: { page: 1, size: 100 },
  level10: { page: 1, size: 100 },
  level11: { page: 1, size: 100 },
  level12: { page: 1, size: 100 },
  level13: { page: 1, size: 100 },
  level14: { page: 1, size: 100 },
  level15: { page: 1, size: 100 },
};

export const defaultMonthKeys = {
  main: defaultMonth,
  level1: defaultMonth,
  level2: defaultMonth,
  level3: defaultMonth,
  level4: defaultMonth,
};

export const defaultDateKeys = {
  main: defaultDateOnly,
  level1: defaultDateOnly,
  level2: defaultDateOnly,
  level3: defaultDateOnly,
  level4: defaultDateOnly,
};

export const defaultQuarterKeys = {
  main: defaultquarter,
  level1: defaultquarter,
  level2: defaultquarter,
  level3: defaultquarter,
  level4: defaultquarter,
};

export const DEFAULT_TABLE_ROW_SIZE_VALUE = "small";
export const DEFAULT_THEME_LIGHT = false;

const defaultInitilization = {
  dateRangeTimezone: { rangeDates: defaultDate, timeZone: { value: defaultTimeZone } },
  dateRange: { rangeDates: defaultDate },
  defaultITaskTimeZone: { value: defaultITaskTimeZone },
  yearMonthInput: defaultMonthKeys,
  dateInput: defaultDateKeys,
  quarterInput: defaultQuarterKeys,
  selectedWorkspace: null,
  multiSelectedWorkspace: [],
  selectedWorkspaceWithAll: true,
  mainSearchWithInclude: true,
  tableRowSize: DEFAULT_TABLE_ROW_SIZE_VALUE,
  isThemeLight: DEFAULT_THEME_LIGHT,
  selectedVSAdvertiser: "",
  selectedVSAdvertiserIsAll: true,
  columns: {},
  customColumns: {},
  reportScalable: false,
  isClickupUserLoggedIn: false,
};

export default function userSettings(state = defaultInitilization, action) {
  switch (action.type) {
    case types.USER_SETTING_DEBUG_MODE:
      return { ...state, debugMode: action.payload };
    case types.REPORT_SCALABLE:
      return { ...state, reportScalable: action.payload };

    case types.MILESTONE_PENDING:
      return { ...state, milestonePending: action.payload };

    // case types.IS_FOR_ME_FILTER:
    //   return { ...state, isForMe: action.payload };

    case types.CLICKUP_USER_LOGGED_IN:
      return { ...state, isClickupUserLoggedIn: action.payload };

    case types.WORKSPACE_SELECTION:
      return { ...state, selectedWorkspace: action.payload };

    case types.MULTI_WORKSPACE_SELECTION:
      return { ...state, multiSelectedWorkspace: action.payload };

    case types.WORKSPACE_SELECTION_WITH_ALL_FALSE:
      return { ...state, selectedWorkspace: action.payload, selectedWorkspaceWithAll: false };

    case types.WORKSPACE_WITH_ALL:
      return { ...state, selectedWorkspaceWithAll: action.payload };

    case types.DATE_RANGE_TIMEZONE:
      return { ...state, dateRangeTimezone: action.payload };

    case types.DATE_RANGE:
      return { ...state, dateRange: action.payload };

    case types.USER_SETTING_DEFAULT_TIMEZONE:
      return { ...state, defaultTimezone: action.payload };

    case types.USER_SETTING_DEFAULT_I_TASK_TIMEZONE:
      return { ...state, defaultITaskTimeZone: action.payload };

    case types.USER_SETTING_CURRENCY:
      return { ...state, currency: action.payload };

    case types.USER_SETTING_MAIN_SEARCH:
      return { ...state, mainSearch: action.payload };

    case types.USER_SETTING_MAIN_SEARCH_CLEAR:
      return { ...state, mainSearch: undefined };

    case types.USER_SETTING_MAIN_BULK_ACTION:
      return { ...state, mainBulkAction: action.payload };

    case types.USER_SETTING_MAIN_BULK_ACTION_CLEAR:
      return { ...state, mainBulkAction: undefined };

    case types.USER_SETTING_MAIN_SEARCH_WITH_INCLUDE_BIT:
      return { ...state, mainSearchWithInclude: action.payload };

    case types.USER_SETTING_MAIN_PAGINATION:
      return { ...state, mainPagination: action.payload };

    case types.USER_SETTING_TABLE_ROW_SIZE:
      return { ...state, tableRowSize: action.payload };

    case types.USER_SETTING_THEME_LIGHT:
      return { ...state, isThemeLight: action.payload };

    case types.USER_SETTING_SORTER:
      return { ...state, sorter: action.payload };

    case types.USER_SETTING_MAIN_USER:
      return { ...state, mainUser: action.payload };

    case types.USER_SETTING_REPORT_FROM_HOUR:
      return { ...state, reportFromHour: action.payload };

    case types.USER_SETTING_REPORT_FROM_HOUR_CLEAR:
      return { ...state, reportFromHour: null };

    case types.USER_SETTING_SCORECARD_BY_MONTH:
      return { ...state, yearMonthInput: action.payload };

    case types.USER_SETTING_SCORECARD_BY_YEAR:
      return { ...state, yearInput: action.payload };

    case types.USER_SETTING_SCORECARD_BY_DATE:
      return {
        ...state,
        dateInput: action.payload,
      };

    case types.USER_SETTING_SCORECARD_BY_QUARTER:
      return {
        ...state,
        quarterInput: action.payload,
      };

    case types.USER_SETTING_CREATE_NEW:
      return { ...state, createNew: action.payload };

    case types.USER_SETTING_SORTER_CLEAR:
      return { ...state, sorter: undefined };

    case types.USER_SETTING_MAIN_PAGINATION_CLEAR:
      return { ...state, mainPagination: defaultPagination };

    case types.USER_SETTING_MAIN_SEARCH_AND_PAGINATION_CLEAR:
      return { ...state, mainSearch: undefined, mainPagination: defaultPagination };

    case types.USER_SETTING_SET_COLUMNS:
      return { ...state, columns: action.payload };

    case types.USER_SETTING_CCUSTOM_COLUMNS_SET:
      return { ...state, customColumns: action.payload };

    case types.USER_SETTING_CLEAR_THINGS_ON_ROUTE_CHANGES:
      return {
        ...state,
        mainSearch: undefined,
        mainPagination: defaultPagination,
        mainBulkAction: undefined,
        createNew: false,
      };

    case types.USER_SETTING_AGENCY_USERS:
      return { ...state, agencyUsers: action.payload };

    case types.USER_SETTING_DATA:
      return { ...state, userSettingData: action.payload };

    case types.USER_SETTING_OBJECT:
      return { ...state, ...action.payload };

    case types.USER_SETTING_VS_AGENCIES:
      return { ...state, vsAgencies: action.payload };

    //media
    case types.USER_SETTING_MEDIA_CATRGORY:
      return { ...state, mediaCatrgory: action.payload };

    case types.USER_SETTING_MEDIA_ITEMS:
      return { ...state, mediaItems: action.payload };

    case types.USER_SETTING_MEDIA_ITEMS_NEXT_TOKEN:
      return { ...state, mediaItemsNextToken: action.payload };

    case types.USER_SETTING_SEARCH_FILTER:
      return { ...state, searchFilter: action.payload };

    case types.USER_SETTING_DEFAULT_FILTER:
      return { ...state, defaultFilter: action.payload };

    case types.USER_SETTING_DEFAULT_FILTER_BOOLEAN:
      return { ...state, defaultFilterBoolean: action.payload };

    case types.CLEAR_USER_SETTINGS:
      return { ...defaultInitilization };

    default:
      return state;
  }
}
