/**
 * Ledgen spend update 7% to 5% from 1 July 2023
 */
export const LEADGEN_REPORT_UPDATE_SPEND = 1.05;

export const LEADGEN_REPORT_UPDATE_SPEND_7_PER = 1.07;

/**
 * Table column widths for reports
 */
export const TABLE_COLUMN_WIDTH_LG_LABEL = 300;
export const TABLE_COLUMN_WIDTH_XS_REPORT_FORMULA = 70;
export const TABLE_COLUMN_WIDTH_SM_REPORT_FORMULA = 90;
export const TABLE_COLUMN_WIDTH_M_REPORT_FORMULA = 110;
export const TABLE_COLUMN_WIDTH_L_REPORT_FORMULA = 140;
