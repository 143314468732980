import { Button, Col, Row, Segmented } from "antd";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";
import { useCustomMutation } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import { GQL_CL_DELETE_IMAGE_TAG, GQL_CL_DELETE_VIDEO_TAG } from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";
import { ADD_CT_TAGS } from "shared/gql/gqlSchema/creativeDashboardGql";
//TODO : ye avatar upload me use hora h, or avatar ki api change honi h
export default function AddTag({
  files,
  variables,
  creatives_refetch = () => {},
  handleFileUpload,
  setTags,
  closeTagModal,
  selectedRowsClear,
}) {
  const [images, setImages] = useState([]);
  const [isCommonTag, setCommonTag] = useState(false);
  const [commonTag, setCommonTagValue] = useState([]);
  const [tags, setTag] = useState([]);
  const [videoType] = useState(variables?.imageType === CREATIVE_TYPE_VIDEO);
  const [key] = useState(variables?.imageType === CREATIVE_TYPE_VIDEO ? "videos" : "images");
  const [keyUrl] = useState(variables?.imageType === CREATIVE_TYPE_VIDEO ? "videoUrl" : "imageUrl");
  const [disabled, setDisabled] = useState();

  const [ctAddTags, { loading: loading_add_tag }] = useCustomMutation(ADD_CT_TAGS, {
    // variables: variables, THIS SCHEMA IS UPDATED SO THESE OTHER VARIBLES IS NOT REQUIRED
    onCompleted: (e) => {
      const element = e?.ctAddTags;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        creatives_refetch();
        selectedRowsClear();
        closeTagModal();
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const getImageResponse = (variables) => {
    // console.log(variables);
    // ctAddTags({
    //   variables: { ...variables },
    // });
  };

  const getDeletedTags = async (prevTag, newTags, index) => {
    // deleted tags
    const deletedTag = prevTag?.filter((tagObj) => !newTags?.includes(tagObj.tag));
    const { tag } = deletedTag[0];
    // Add those tags with have id means thore are coming from api
    if (deletedTag[0].id) {
      const deletedTagWithoutGrouping = [
        ...tags,
        { creativeId: parseInt(images[index].id), tags: [{ id: parseInt(deletedTag[0].id), tag }] },
      ];
      // Grouped deleted Tags
      const groupedDeletedTags = deletedTagWithoutGrouping.reduce((acc, tag) => {
        const existingGroup = acc?.find((group) => group?.creativeId === tag?.creativeId);
        if (existingGroup) {
          existingGroup["tags"] = [...existingGroup.tags, tag?.tags[0]];
        } else {
          acc?.push({ creativeId: tag.creativeId, tags: tag?.tags });
        }
        return acc;
      }, []);
      setTag(groupedDeletedTags);
    }
  };

  const handleTag = async (value, index) => {
    // If tags already exists for the index, create a new copy of the 'tags' state
    const newTags = value;
    const updateTag = [...images];
    const prevTags = updateTag.filter((e, id) => id === index);
    // if new tag added
    if (prevTags[0].tags?.length < newTags?.length) {
      updateTag[index]["tags"].push({ tag: newTags?.pop() });
      setImages(updateTag);
    } else {
      //  remove tag
      const prevTag = prevTags[0].tags?.map((tag, id) => tag);
      const filteredTags = prevTag?.filter((tagObj) => newTags?.includes(tagObj.tag));
      const newImageObj = [...images];
      newImageObj[index]["tags"] = filteredTags;
      setImages(newImageObj);
      // group tag which are already saved (got from api)
      await getDeletedTags(prevTag, newTags, index);
    }
  };

  useEffect(() => {
    setImages(files);
  }, [files]);

  const handleSaveTag = async () => {
    if (isCommonTag) {
      const variable = {
        [key]: images?.map((data) => ({
          [keyUrl]: data?.r2Url,
        })),
        commonTags: commonTag,
      };
      getImageResponse(variable);
    } else {
      const variable = {
        [key]: images?.map((data) => ({
          [keyUrl]: data?.r2Url,
          tags: data?.tags.map((data) => data?.tag),
        })),
      };
      getImageResponse(variable);
    }
  };

  return (
    <div style={{ height: "65vh" }}>
      <Row gutter={24} className="">
        <Col span={12} className="mb-14">
          <Segmented
            className="segment-container"
            size="small"
            value={isCommonTag}
            onChange={() => setCommonTag((prev) => !prev)}
            options={[
              { label: "Separate", value: false },
              { label: "Common", value: true },
            ]}
          />
        </Col>
        <Col span={24}>
          {isCommonTag && (
            <SelectInput
              className="mb-14 mt-14 "
              mode="tags"
              placeholder="Add common tags"
              value={commonTag}
              onChange={(e) => setCommonTagValue(e)}
            />
          )}
        </Col>
      </Row>
      <div className="pr-14" style={{ height: "84%", overflowY: "auto" }}>
        <Row gutter={24}>
          {images.map((image, index) => (
            <Col span={isCommonTag ? 6 : 24}>
              <Row gutter={isCommonTag ? 6 : 24} align="middle">
                <Col span={isCommonTag ? 24 : 8}>
                  {videoType ? (
                    <video
                      id={index}
                      src={image?.r2Url}
                      alt="not found"
                      style={{ objectFit: "cover" }}
                      width={152}
                      height={152}
                      className="w-100"
                    />
                  ) : (
                    <img
                      id={index}
                      src={image?.r2Url}
                      alt="not found"
                      style={{ objectFit: "cover" }}
                      width={152}
                      height={152}
                      className="w-100 "
                    />
                  )}
                </Col>
                {!isCommonTag && (
                  <Col span={16}>
                    <div>
                      <p className="mb-0 mb-5 ml-14" style={{ color: " #ACACAC" }}>
                        Tags
                      </p>
                      <SelectInput
                        showArrow={false}
                        className="ml-14 mr-14 w-100 select-fixed-height"
                        mode="tags"
                        value={image?.tags?.map(({ tag }) => tag)}
                        onChange={(e) => handleTag(e, index)}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          ))}
        </Row>
      </div>

      <div className="bottom-buttons">
        <Row gutter={24} style={{ width: "100%" }}>
          <Col span={12}>
            <Button block className="submit-btn" onClick={() => closeTagModal && closeTagModal()}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSaveTag}
              loading={loading_add_tag || disabled}
              disabled={!images?.length}
            >
              {loading_add_tag || disabled ? <Loader /> : "Save"}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
