import { Layout, Button, Dropdown } from "antd";
import cx from "classnames";
import ActionIconRender from "components/ActionIconRender";
import LookLinksTabs from "components/LookLinksTabs";
import { Link } from "react-router-dom";
import fbbutton from "assets/images/social-media/fb_button.svg";
import LookButton from "components/LookButton";
import google_button from "assets/images/social-media/google_button.png";

const { Header } = Layout;

function Tabs(props) {
  const { tabContent, activeTab, setActiveTab } = props;
  return (
    <div className="look-tabs left-bar">
      {tabContent.map(({ id, title, icon: Icon }) => (
        <>
          <Button
            key={"tabContentBtn" + id}
            style={{ border: "none" }}
            className={cx({ active: activeTab === id })}
            onClick={() => setActiveTab(id)}
            icon={Icon && <Icon />}
          >
            {title}
          </Button>
        </>
      ))}
    </div>
  );
}

function SelectionOptions(props) {
  const { label = "", options = [] } = props;

  /**
   * 
   * options example
   * 
    {
      iconType: "edit",
      title: "Edit",
      optionProps: {
        onClick: () => {},
      },
    },
   */
  const items = options?.map((data, key) => {
    const { iconType, option, title, optionProps } = data;
    return {
      key,
      label: (
        <div>
          <LookButton iconType={iconType || option} block {...optionProps}>
            {title}
          </LookButton>
        </div>
      ),
    };
  });

  return (
    <>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <LookButton
          className="creationOptionBtn"
          type="primary"
          //TEMPORARY INLINE STYLE
          style={{
            minWidth: 100,
            height: 48,
            border: 0,
            borderRadius: 0,
            marginRight: -20,
            fontSize: "16px",
            fontWeight: "500",
          }}
          s
        >
          <span className="label">{label}</span>
          <ActionIconRender iconType="down" />
          {/* <DownOutlined /> */}
        </LookButton>
      </Dropdown>
    </>
  );
}

export default function DashboardHeader(props) {
  const {
    title = "",
    linkTitle = {},
    linkTabs = [],
    tabContent = [],
    activeTab = 1,
    setActiveTab = () => {},
    icon,
    createBtn = false,
    createBtnText = "",
    createBtnType = "add",
    createBtnIconType = "",
    createBtnHandler = () => {},
    createBtnDisable = false,
    basePath = "",
    createMultiBtns,
  } = props;
  const tabProps = { tabContent, activeTab, setActiveTab, icon };

  let CreateBtnIcon = () => <ActionIconRender iconType="plus" />;
  if (createBtnType === "back") CreateBtnIcon = () => <ActionIconRender iconType="left" />;

  return (
    <Header className="header">
      <div className="flex" style={{}}>
        {title && <div className="mr-10">{title}</div>}
        {/* linkTitle is used if we need a title with some routing */}
        {linkTitle && (
          <Link to={linkTitle?.path}>
            <div className=" mr-15">{linkTitle?.title}</div>{" "}
          </Link>
        )}
        {tabContent.length > 0 && <Tabs {...tabProps} />}
        {linkTabs.length > 0 && <LookLinksTabs linkTabs={linkTabs} basePath={basePath} />}
      </div>
      {createBtn &&
        (createBtnType === "facebook" ? (
          <Button
            style={{
              minWidth: 100,
              height: 48,
              border: 0,
              borderRadius: 0,
              marginRight: -34,
              marginTop: -4,
            }}
            disabled={createBtnDisable}
            onClick={createBtnHandler}
          >
            <img
              style={{
                minWidth: 100,
                height: 50,
                border: 0,
                borderRadius: 0,
                marginTop: -2,
              }}
              src={fbbutton}
              alt="fbButton"
            />
          </Button>
        ) : createBtnType === "google" ? (
          <Button
            style={{
              minWidth: 100,
              height: 48,
              border: 0,
              borderRadius: 0,
              marginRight: -34,
              marginTop: -4,
            }}
            disabled={createBtnDisable}
            onClick={createBtnHandler}
          >
            <img
              style={{
                minWidth: 100,
                height: 54,
                border: 0,
                borderRadius: 0,
                marginTop: -4,
                marginRight: -4,
              }}
              src={google_button}
              alt="google_button"
            />
          </Button>
        ) : (
          <Button
            icon={
              createBtnIconType ? (
                <ActionIconRender iconType={createBtnIconType} />
              ) : (
                <CreateBtnIcon style={{ fontSize: 20 }} />
              )
            }
            style={{
              minWidth: 100,
              height: 48,
              border: 0,
              borderRadius: 0,
              marginRight: -20,
              fontSize: "16px",
              fontWeight: "500",
            }}
            type="primary"
            disabled={createBtnDisable}
            onClick={createBtnHandler}
          >
            {createBtnText}
          </Button>
        ))}
      {createMultiBtns && <SelectionOptions {...createMultiBtns} />}
    </Header>
  );
}
