import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import { openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";
import {
  ASSET_STATUS_NODE_ENUM,
  UPDATE_BULK_AD_SET_BUDGET,
} from "shared/gql/gqlSchema/viralSparksGql";

export default function UpdateBulkCampaignBudget(props) {
  const {
    refetch = () => {},
    spendFB: propSpendFB,
    rowProps,
    onCloseDrawerBudget = () => {},
  } = props;
  const [isError, setIsError] = useState("");
  console.log(props);

  const resetFormRef = useRef(() => {});

  const [updateCampaignBudget, { loading }] = useCustomMutation(UPDATE_BULK_AD_SET_BUDGET, {
    onCompleted(e) {
      // resetFormRef.current();
      const element = e?.updateFbAssetBudget;
      onCloseDrawerBudget();
      openNotification({ type: element?.success ? "success" : "error", message: element?.message });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const { budget, isLifeTime } = values;
    const submitValues = {
      fbIds: rowProps.map((i) => i.campaignId),
      isLifeTime,
      budget: budget,
      node: ASSET_STATUS_NODE_ENUM[2].name,
    };

    console.log(submitValues);

    updateCampaignBudget({
      variables: {
        ...submitValues,
      },
    });
    clearError();
  }

  // let budget = "";
  let countIsLifeTime = 0;
  let countDailyBudget = 0;
  rowProps.map(({ dailyBudget, lifeTimeBudget }) => {
    if (dailyBudget !== null && lifeTimeBudget !== null) {
      if (dailyBudget > lifeTimeBudget) {
        countDailyBudget++;
        // isLifeTime = false;
      } else if (dailyBudget < lifeTimeBudget) {
        // budget = lifeTimeBudget;
        countIsLifeTime++;
      }
    }
    if (dailyBudget && (lifeTimeBudget === undefined || lifeTimeBudget === null)) {
      // budget = dailyBudget;
      // isLifeTime = false;
      countDailyBudget++;
    }
    if ((dailyBudget === undefined || dailyBudget === null) && lifeTimeBudget) {
      // budget = lifeTimeBudget;
      // isLifeTime = true;
      countIsLifeTime++;
    }
  });

  const initialValues = {
    budget: 0,
    isLifeTime: countIsLifeTime > countDailyBudget,

    // isBudgetSpend: budget === propSpendFB,
  };

  const validationSchema = yup.object({
    budget: yup.number().required().label("budget"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    propSpendFB,
    btnText: "Update",
  };

  return (
    <>
      <MainFrom {...formProps} />
      {/* <pre>{JSON.stringify({ props }, null, 2)}</pre> */}
    </>
  );
}
