import React, { useState } from "react";
import { CREATIVE_TYPE_VIDEO, CREATIVE_TYPE_IMAGE } from "shared/SharedKeys/launchKeys";
import cx from "cx";
import { Checkbox, Col, Image, Row } from "antd";
import LookModal from "components/LookModal";
import LookButton from "components/LookButton";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import LookCreativeReports from "../CreativeReports";
import CreativeReports from "../CreativeReports";

function AdsByCreatives(props) {
  const { creativesPresent, platform, name } = props;
  const [selectedImages, setSelectedImages] = useState([]);
  const [playModal, setPlayModal] = useState(false);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [rowProps, setRowProps] = useState([]);

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  function detectContentType(url) {
    // Get the file extension from the URL
    const fileExtension = url.split(".").pop().toLowerCase();

    // Define common image file extensions
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    // Define common video file extensions
    const videoExtensions = ["mp4", "avi", "mov", "wmv", "mkv"];

    // Check if the file extension matches an image or video extension
    if (imageExtensions.includes(fileExtension)) {
      return CREATIVE_TYPE_IMAGE;
    } else if (videoExtensions.includes(fileExtension)) {
      return CREATIVE_TYPE_VIDEO;
    } else {
      return "unknown";
    }
  }
  // Initialize an array to store filtered creatives
  const filteredCreatives = creativesPresent?.reduce((result, { id, s3Url }) => {
    // Calculate the content type of the current creative
    const contentType = detectContentType(s3Url);

    // Check if the content type is not "unknown"
    if (contentType !== "unknown") {
      // If it's an image, set the creative media type to IMAGE; otherwise, set it to VIDEO

      // Create an object with the desired properties and push it into the result array
      result.push({
        id,
        s3Url,
        creativeMediaType: contentType,
      });
    }
    return result;
  }, []);
  const handleSelectImage = (id, type) => {
    if (selectedImages?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedImages(selectedImages?.filter((img) => img?.id !== id));
    } else {
      setSelectedImages([
        ...selectedImages,
        {
          id: filteredCreatives.filter((img) => img?.id === id)[0]?.id,
          s3Url: filteredCreatives.filter((img) => img?.id === id)[0]?.s3Url,
          type: filteredCreatives.filter((img) => img?.id === id)[0]?.creativeMediaType,
        },
      ]);
    }
  };
  const bulkActions = [
    {
      option: "pic-left",
      title: "Show Reports",
      onClick: () => {
        showDrawer();
      },
    },
  ];
  function selectedRowsClear() {
    setSelectedImages([]);
  }
  const bulkActionProps = {
    selectedRows: selectedImages,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const onClose = () => {
    setVisibleDrawer(false);
    setSelectedImages([]);
    setRowProps([]);
  };
  const drawerProps = {
    textBreadcrumb: [name, `Creative`, "Reports"],
    size: 900,
    noBodySpace: true,
    visible: visibleDrawer,
    onClose: onClose,
  };

  return (
    <>
      <LookTableBulkAction {...bulkActionProps} />
      <LookDrawer {...drawerProps}>
        {visibleDrawer && (
          <CreativeReports
            selectedCreatives={rowProps?.length === 0 ? selectedImages : rowProps}
            performanceDateFilter
          />
        )}
      </LookDrawer>
      <div className={cx("creative-media")}>
        <Row gutter={[24, 24]}>
          {filteredCreatives?.map((data, index) => {
            const onSelect = () => {
              handleSelectImage(data?.id, data?.creativeMediaType);
            };
            const selected = selectedImages?.filter((img) => img.id === data?.id)?.length > 0;
            const isSelection = { onClick: onSelect };
            return (
              <>
                <Col span={4} key={"mediaItems" + index} xs={12} sm={8} md={6} xl={4}>
                  <div
                    {...isSelection}
                    className={cx("creative-image-selection ant-image-ration-1-1")}
                  >
                    {data?.creativeMediaType === CREATIVE_TYPE_VIDEO ? (
                      <>
                        <div className="d-flex " style={{ justifyContent: "center" }}>
                          <video
                            style={{
                              objectFit: "contain",
                              top: 0,
                              left: 0,
                              background: "#000",
                              height: "120px",
                            }}
                            src={data?.s3Url}
                          />
                        </div>
                      </>
                    ) : (
                      <Image className="" src={data?.s3Url} preview={false} />
                    )}
                  </div>
                  {playModal && data?.creativeMediaType === CREATIVE_TYPE_VIDEO && (
                    <LookModal
                      title="Play Video"
                      visible={playModal}
                      onCancel={() => {
                        setPlayModal(false);
                        setRowProps([]);
                      }}
                      footer={false}
                    >
                      {playModal && (
                        <video
                          height={"300px"}
                          className="w-100"
                          src={rowProps?.[0]?.s3Url}
                          controls
                          autoPlay
                        />
                      )}
                    </LookModal>
                  )}
                  <div className={cx("creative-edit")}>
                    <div className="d-flex">
                      <Checkbox
                        className="creative-select"
                        onChange={onSelect}
                        checked={selected}
                      />
                    </div>

                    <div className="d-flex">
                      <LookButton
                        size="small"
                        tooltipTitle="ShowReports"
                        iconType="pic-left"
                        style={{ border: "none" }}
                        onClick={() => {
                          showDrawer();
                          setRowProps([
                            { id: data?.id, s3Url: data?.s3Url, type: data?.creativeMediaType },
                          ]);
                        }}
                      />
                      {data?.creativeMediaType === CREATIVE_TYPE_VIDEO && (
                        <LookButton
                          size="small"
                          tooltipTitle="Play video"
                          iconType="play"
                          style={{ border: "none", zIndex: "2" }}
                          onClick={() => {
                            setPlayModal(true);
                            setRowProps([{ s3Url: data?.s3Url }]);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
      </div>
    </>
  );
}

export default AdsByCreatives;
