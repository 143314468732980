import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import LookTable from "components/LookTable";
import React, { useEffect } from "react";
import { REPORT_TYPE_LIVE } from "shared/SharedKeys";
import { useCreativeReportingContext } from "../../Context";

function CategoryReports({ categoryColumns }) {
  const {
    finalMerge,
    summaryData,
    revenueTiktokCategoryFetch,
    spendTiktokCategoryFetch,
    revenueFbCategoryFetch,
    spendFbCategoryFetch,
    tiktokVariables,
    isSAS,
    isVS,
    spendDetailedVariables,
    fbRevVariables,
    fbSpendVariables,
    isGlobalReport,
    setSpendDetailedReport,
    setRevDetailedReport,
    filters,
    isLeadgen,
    isFbReport,
    refetch_tiktok_category_rev,
    refetch_spend_fb_category,
    refetch_fb_category_rev,
    refetch_spend_category_tiktok,
    loading,
    revenueVSLiveContnetFetch,
    loading_revenueVSLiveContnetFetch,
    refetch_revenueVSLiveContnetFetch,
  } = useCreativeReportingContext();
  const tableCategoryProps = {
    initialColumns: categoryColumns,
    recordData: finalMerge,
    summaryData,
    spendDetailedVariables,
    isLeadgen,
  };
  const refetch_fb_category = () => {
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    if (isSAS) {
      refetch_tiktok_category_rev();
      refetch_spend_fb_category();
    }
    if (isVS) {
      refetch_spend_fb_category();
      refetch_revenueVSLiveContnetFetch();
    } else {
      refetch_fb_category_rev();
      refetch_spend_fb_category();
    }
  };

  const refetch_tiktok_category = () => {
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    refetch_tiktok_category_rev();
    refetch_spend_category_tiktok();
  };
  useEffect(() => {
    if (filters?.length > 0) {
      setSpendDetailedReport([]);
      setRevDetailedReport([]);
      const spendFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.spendApikey]: data.id } : null
      );
      const revFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.revApikey]: data.id } : null
      );
      if (isFbReport) {
        if (isSAS) {
          revenueTiktokCategoryFetch({
            variables: {
              ...tiktokVariables,
              ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
              ...Object.assign({}, ...revFilterVariables),
            },
          });
        } else if (isVS) {
          revenueVSLiveContnetFetch({
            variables: {
              ...fbRevVariables,
              group_by: fbRevVariables?.group_by,
              is_forecast: fbRevVariables?.isForecast,
              ...Object.assign({}, ...spendFilterVariables),
            },
          });
        } else {
          revenueFbCategoryFetch({
            variables: {
              ...fbRevVariables,
              ...Object.assign({}, ...spendFilterVariables),
            },
          });
        }
        spendFbCategoryFetch({
          variables: {
            ...fbSpendVariables,
            ...Object.assign({}, ...spendFilterVariables),
          },
        });
      } else {
        if (isLeadgen) {
          revenueFbCategoryFetch({
            variables: fbRevVariables,
            ...Object.assign({}, ...revFilterVariables),
          });
        } else {
          revenueTiktokCategoryFetch({
            variables: tiktokVariables,
            ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
            ...Object.assign({}, ...revFilterVariables),
          });
        }

        spendTiktokCategoryFetch({
          variables: tiktokVariables,
          ...Object.assign({}, ...spendFilterVariables),
        });
      }
    } else {
      if (isFbReport) {
        if (isSAS) {
          revenueTiktokCategoryFetch({ variables: tiktokVariables });
        } else if (isVS) {
          revenueVSLiveContnetFetch({
            variables: {
              ...fbRevVariables,
              group_by: fbRevVariables?.group_by,
              is_forecast: fbRevVariables?.isForecast,
            },
          });
        } else {
          revenueFbCategoryFetch({ variables: fbRevVariables });
        }
        spendFbCategoryFetch({ variables: fbSpendVariables });
      } else {
        if (isLeadgen) {
          revenueFbCategoryFetch({ variables: fbRevVariables });
        } else {
          revenueTiktokCategoryFetch({
            variables: tiktokVariables,
          });
        }
        spendTiktokCategoryFetch({ variables: spendDetailedVariables });
      }
    }
  }, []);

  return (
    <div>
      <LookTable
        tableProps={tableCategoryProps}
        refetch={isFbReport ? refetch_fb_category : refetch_tiktok_category}
        loading={loading}
        defaultPagination
        persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
        sizes={{ reduceExtraScrollHeightBy: 131 }} //used to reduce table height because summary card was taking the extra space at top
      />
    </div>
  );
}

export default CategoryReports;
