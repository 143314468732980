import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Row, Tabs } from "antd";
import * as yup from "yup";
import LookTextInput from "components/forms/LookTextInput";
import {
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_LANGUAGE,
  MOODBOARD_TYPE_PAIN_POINTS,
  MOODBOARD_TYPE_SCRIPT,
} from "../../creativesEnum";
import Loader from "components/loader";
import cx from "cx";
import ScriptTab from "../../Category/CreateMoodBoard/ScriptTab";
import {
  GET_MOODBOARD_ANGLES,
  GET_MOODBOARD_COPY_POINTS,
  GET_MOODBOARD_CTA,
  GET_MOODBOARD_HOOKS,
  GET_MOODBOARD_PAIN_POINTS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import MoodboardData from "./MoodboardData";
import ScriptDetailedTabs from "./ScriptDetailedTabs";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import SelectInput from "components/forms/SelectInput";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { Formik } from "formik";
import { useSelector } from "react-redux";

function AddMoodboardDataModal({
  actionType,
  id = [],
  name = "",
  productId,
  placeHolderKey,
  loading = false,
  onClose = () => {},
  addMoodboardData,
  setAddMoodboardData = () => {},
  vsAdvertiser,
  vsAdvertiser_loading = false,
  addCreativeAnalysis,
  fetchVsContent = () => {},
  sas_loading = false,
  leadgen_loading = false,
  vs_content_loading = false,
  serviceType = "",
  handleAddMoodboardData = () => {},
}) {
  const [activeTab, setActiveTab] = useState("1");

  const { sasCategories, voluumCategories } = useSelector(
    (state) => state?.userSettings?.userSettingData
  );

  const tabProps = {
    id: addMoodboardData?.categoryId,
    name: addMoodboardData?.categoryName,
    productId,
    values: addMoodboardData,
    setValues: setAddMoodboardData,
    // category: addMoodboardData?.categoryId,
    isCreateForm: true,
  };
  const onChange = (key) => {
    setActiveTab(key);
  };

  const tabContent = [
    {
      key: "1",
      label: "Copy Points",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_COPY_POINTS}
          query={GET_MOODBOARD_COPY_POINTS}
          apiType="ctGetCopyPoints"
          {...tabProps}
          paramstype="copyPoints"
          label="Copy Points"
        />
      ),
    },
    {
      key: "2",
      label: "Angles",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_ANGLES}
          query={GET_MOODBOARD_ANGLES}
          apiType="ctGetAngles"
          {...tabProps}
          paramstype="angles"
          label="Angles"
        />
      ),
    },
    {
      key: "3",
      label: "Hooks",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_HOOKS}
          query={GET_MOODBOARD_HOOKS}
          apiType="ctGetHooks"
          {...tabProps}
          paramstype="hooks"
          label="Hooks"
        />
      ),
    },
    {
      key: "4",
      label: "Pain Points",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_PAIN_POINTS}
          query={GET_MOODBOARD_PAIN_POINTS}
          apiType="ctGetPainPoints"
          {...tabProps}
          paramstype="painPoints"
          label="Pain Points"
        />
      ),
    },
    {
      key: "5",
      label: "CTA",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_CTA}
          query={GET_MOODBOARD_CTA}
          apiType="ctGetCTAs"
          {...tabProps}
          paramstype="ctas"
          label="CTA"
        />
      ),
    },
    {
      key: "6",
      label: "Language",
      children: (
        <ScriptTab
          query={GET_MOODBOARD_CTA}
          type={MOODBOARD_TYPE_LANGUAGE}
          {...tabProps}
          paramstype="language"
          label="Language"
        />
      ),
    },
  ];

  const handleCategories = (serviceType) => {
    switch (parseInt(serviceType)) {
      case PRODUCT_ID_LEADGEN:
        return voluumCategories;

      case PRODUCT_ID_SAS:
        return sasCategories;

      default:
        break;
    }
  };
  const finalCategories = handleCategories(serviceType);

  const initialValues = { advertiser: "", categoryId: "", title: "", description: "" };

  const advertiserValidation =
    productId === PRODUCT_ID_VS ? { advertiser: yup.string().required().label("Advertiser") } : {};

  const validationSchema = yup.object({
    // ...advertiserValidation,
    categoryId: yup.string().required().label("Category"),
    title: yup.string().required().label(`${placeHolderKey} title`),
    // description: yup.string().required().label(`${placeHolderKey} description`),
  });

  return (
    <>
      {loading ? (
        <Loader elementCenter center />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleAddMoodboardData}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form layout="vertical">
              {
                <>
                  {productId === PRODUCT_ID_VS && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          name="advertiser"
                          label="Advertiser"
                          formik
                          placeholder="Select Advertiser"
                          className="look-form-input"
                          search
                          loader={vsAdvertiser_loading}
                          // value={addMoodboardData?.advertiser}
                          onChange={(name, value) => {
                            setFieldValue("advertiser", value);
                            setAddMoodboardData((prev) => ({
                              ...prev,
                              advertiser: value,
                            }));
                            if (productId === PRODUCT_ID_VS) {
                              fetchVsContent({ variables: { advertiser_id: value } });
                            }
                          }}
                          options={vsAdvertiser?.map(({ id, name }) => ({ name, value: id }))}
                        />
                      </Col>
                    </Row>
                  )}
                  {((addMoodboardData?.advertiser && productId === PRODUCT_ID_VS) || productId) && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          name="categoryId"
                          label="Category"
                          search
                          formik
                          placeholder="Select Category"
                          className="look-form-input"
                          options={finalCategories?.map(({ name, id }) => ({ name, value: id }))}
                          // value={addMoodboardData?.categoryId}
                          loader={vs_content_loading || sas_loading || leadgen_loading}
                          onChange={(name, value) => {
                            setFieldValue("categoryId", value);
                            setAddMoodboardData((prev) => ({
                              ...prev,
                              categoryId: value,
                              categoryName: finalCategories?.find(
                                (category) => category?.id === value
                              )?.name,
                            }));
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              }
              <Row gutter={12}>
                <Col span={24}>
                  <LookTextInput
                    name="title"
                    label={`${placeHolderKey} title`}
                    formik
                    // value={addMoodboardData?.title}
                    // onChange={(e) => {
                    //   setFieldValue("title", e);
                    //   setAddMoodboardData((prev) => ({ ...prev, title: e.target.value }));
                    // }}
                    placeholder="Enter title"
                  />
                </Col>
              </Row>
              {actionType !== MOODBOARD_TYPE_COPY_POINTS && (
                <Row gutter={12}>
                  <Col span={24}>
                    <LookTextInput
                      type="textarea"
                      name="description"
                      formik
                      label={`${placeHolderKey} description`}
                      // value={addMoodboardData?.desc}
                      // onChange={(e) => {
                      //   setFieldValue("description", e);
                      //   setAddMoodboardData((prev) => ({ ...prev, desc: e.target.value }));
                      // }}
                      placeholder="Enter description"
                    />
                  </Col>
                </Row>
              )}
              {actionType === MOODBOARD_TYPE_SCRIPT && addMoodboardData?.categoryId && (
                <div>
                  <Tabs
                    className={cx("scriptTabs", { isEdit: true })}
                    defaultActiveKey="1"
                    size="large"
                    items={tabContent}
                    onChange={onChange}
                  />
                </div>
              )}
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item>
                    <Button block className="submit-btn" onClick={onClose}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      block
                      type="primary"
                      className="submit-btn"
                      onClick={handleSubmit}
                      disabled={
                        loading ||
                        (!addMoodboardData?.language && actionType === MOODBOARD_TYPE_SCRIPT)
                      }
                    >
                      {loading ? <Loader /> : `Add ${placeHolderKey}`}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

AddMoodboardDataModal.propTypes = {
  actionType: PropTypes.string.isRequired,
  id: PropTypes.array,
  name: PropTypes.string,
  productId: PropTypes.string.isRequired,
  creativeId: PropTypes.string,
  placeHolderKey: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  dataKey: PropTypes.string,
  apiKey: PropTypes.string,
  moodboardSelection: PropTypes.bool,
  selectedItems: PropTypes.array,
  creativeType: PropTypes.string,
  setSelectedItems: PropTypes.func,
  creativeData: PropTypes.object,
  setCreativeData: PropTypes.func,
  onClose: PropTypes.func,
  addMoodboardData: PropTypes.object,
  setAddMoodboardData: PropTypes.func,
};

export default AddMoodboardDataModal;
