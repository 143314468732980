import { gql } from "@apollo/client";

const CMS_DOMAIN_RESPONSE = gql`
  fragment Domain on Domain {
    id
    name
    agency_id
    affiliate_id
    analytics_id
    apple_touch_icon
    cdn_domain
    tracking_domain
    dms_id
    is_private
    favicon
    fb_page_id
    fb_pixels
    keyword
    logo
    logo_light
    og_image
    description
    domain_url
    header_script
    footer_script
    ads_file
    robot_file
    lists {
      id
      title
    }
    status
    socialProfiles
    categories {
      id
      name
      domain_category {
        is_menu
      }
    }
  }
`;

const CMS_STORE_LIMITED_FOR_REDUX_STORE_RESPONSE = gql`
  fragment limited_store_data on Store {
    id
    name
  }
`;

export const CMS_STORE_LIMITED_FOR_REDUX_STORE = gql`
  ${CMS_STORE_LIMITED_FOR_REDUX_STORE_RESPONSE}
  query CMS_Stores {
    CMS_Stores {
      ...limited_store_data
    }
  }
`;

const CMS_PRODUCT_LIMITED_FOR_REDUX_STORE_RESPONSE = gql`
  fragment limited_product_data on cms_Product {
    id
    name
  }
`;

export const CMS_PRODUCT_LIMITED_FOR_REDUX_STORE = gql`
  ${CMS_PRODUCT_LIMITED_FOR_REDUX_STORE_RESPONSE}
  query CMS_All_Products {
    CMS_All_Products {
      ...limited_product_data
    }
  }
`;

const CMS_DOMAIN_LIMITED_FOR_REDUX_STORE_RESPONSE = gql`
  fragment limited_domain_data on Domain {
    id
    name
    tracking_domain
    awic_network_accounts {
      id
      name
    }
  }
`;

export const GET_CMS_DOMAINS = gql`
  ${CMS_DOMAIN_RESPONSE}
  query CMS_Domains {
    CMS_Domains {
      ...Domain
    }
  }
`;

export const GET_CMS_DOMAIN_LIST = gql`
  query CMS_Domains {
    CMS_Domains {
      id
      name
    }
  }
`;

export const GET_CMS_GROUPED_DOMAINS = gql`
  query CMS_Get_Domain_Group(
    $column: String
    $order: String
    $page: Int
    $search: String
    $size: Int
  ) {
    CMS_Get_Domain_Group(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        pageInfo {
          totalCount
          totalPages
        }
        groupDomains {
          categories {
            name
          }
          domains {
            id
            name
          }
          id
          name
        }
      }
      message
      status
    }
  }
`;

export const GET_CMS_GROUPED_DOMAINS_LISTING = gql`
  query CMS_Get_Domain_Group(
    $column: String
    $order: String
    $page: Int
    $search: String
    $size: Int
  ) {
    CMS_Get_Domain_Group(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        groupDomains {
          id
          name
        }
      }
      message
      status
    }
  }
`;

export const GET_CMS_GROUPED_DOMAINS_BY_ID = gql`
  query CMS_Get_Domain_Group_ById($id: ID!) {
    CMS_Get_Domain_Group_ById(id: $id) {
      data {
        domains {
          domain_url
          id
        }
      }
      message
      status
    }
  }
`;

export const DELETE_CMS_GROUPED_DOMAINS = gql`
  mutation CMS_Delete_Domain_Group($id: ID) {
    CMS_Delete_Domain_Group(id: $id) {
      data
      message
      status
    }
  }
`;

export const CREATE_CMS_DOMAIN_GROUP = gql`
  mutation CMS_Create_Domain_Group($name: String!, $domain_id: [ID]) {
    CMS_Create_Domain_Group(name: $name, domain_id: $domain_id) {
      data {
        id
        name
        domains {
          id
          name
        }
        categories {
          name
        }
      }
      message
      status
    }
  }
`;

export const UPDATE_CMS_DOMAIN_GROUP = gql`
  mutation CMS_Update_Domain_Group($name: String, $id: ID, $domain_id: [ID]) {
    CMS_Update_Domain_Group(name: $name, id: $id, domain_id: $domain_id) {
      data {
        id
        name
        domains {
          id
          name
        }
        categories {
          name
        }
      }
      status
      message
    }
  }
`;

export const GET_CMS_DOMAIN = gql`
  ${CMS_DOMAIN_RESPONSE}
  query CMS_Domain($id: ID!) {
    CMS_Domain(id: $id) {
      ...Domain
    }
  }
`;

export const ADD_CMS_DOMAINS = gql`
  ${CMS_DOMAIN_RESPONSE}
  mutation CMS_CreateDomain(
    $ads_file: String
    $affiliate_id: String
    $analytics_id: String
    $apple_touch_icon: String
    $categories: [category_domain]
    $cdn_domain: String
    $description: String
    $dms_id: Int
    $domain_url: String
    $favicon: String
    $fb_page_id: String
    $fb_pixels: String
    $footer_script: String
    $header_script: String
    $is_private: Boolean
    $keyword: String
    $logo: String
    $logo_light: String
    $name: String
    $og_image: String
    $robot_file: String
    $social_profile: Object
    $status: String
    $tracking_domain: String
  ) {
    CMS_CreateDomain(
      ads_file: $ads_file
      affiliate_id: $affiliate_id
      analytics_id: $analytics_id
      apple_touch_icon: $apple_touch_icon
      categories: $categories
      cdn_domain: $cdn_domain
      description: $description
      dms_id: $dms_id
      domain_url: $domain_url
      favicon: $favicon
      fb_page_id: $fb_page_id
      fb_pixels: $fb_pixels
      footer_script: $footer_script
      header_script: $header_script
      is_private: $is_private
      keyword: $keyword
      logo: $logo
      logo_light: $logo_light
      name: $name
      og_image: $og_image
      robot_file: $robot_file
      social_profile: $social_profile
      status: $status
      tracking_domain: $tracking_domain
    ) {
      ...Domain
    }
  }
`;

export const UPDATE_CMS_DOMAINS = gql`
  ${CMS_DOMAIN_RESPONSE}
  mutation CMS_UpdateDomain(
    $ads_file: String
    $affiliate_id: String
    $analytics_id: String
    $apple_touch_icon: String
    $categories: [category_domain]
    $cdn_domain: String
    $description: String
    $dms_id: Int
    $domain_url: String
    $favicon: String
    $fb_page_id: String
    $fb_pixels: String
    $footer_script: String
    $header_script: String
    $id: ID!
    $is_private: Boolean
    $keyword: String
    $logo: String
    $logo_light: String
    $name: String
    $og_image: String
    $robot_file: String
    $social_profile: Object
    $status: String
    $tracking_domain: String
  ) {
    CMS_UpdateDomain(
      ads_file: $ads_file
      affiliate_id: $affiliate_id
      analytics_id: $analytics_id
      apple_touch_icon: $apple_touch_icon
      categories: $categories
      cdn_domain: $cdn_domain
      description: $description
      dms_id: $dms_id
      domain_url: $domain_url
      favicon: $favicon
      fb_page_id: $fb_page_id
      fb_pixels: $fb_pixels
      footer_script: $footer_script
      header_script: $header_script
      id: $id
      is_private: $is_private
      keyword: $keyword
      logo: $logo
      logo_light: $logo_light
      name: $name
      og_image: $og_image
      robot_file: $robot_file
      social_profile: $social_profile
      status: $status
      tracking_domain: $tracking_domain
    ) {
      ...Domain
    }
  }
`;

export const CMS_DOMAIN_LIMITED_FOR_REDUX_STORE = gql`
  ${CMS_DOMAIN_LIMITED_FOR_REDUX_STORE_RESPONSE}
  query CMS_Domains {
    CMS_Domains {
      ...limited_domain_data
      domain_url
    }
  }
`;

export const GET_CMS_DOMAINS_ID_NAME = gql`
  query CMS_Domains {
    CMS_Domains {
      id
      name
    }
  }
`;

export const GET_CMS_DOMAINS_ID_NAME_EVERFLOW_NETWORKACCOUNT = gql`
  query CMS_Domains {
    CMS_Domains {
      id
      name
      tracking_domain
      awic_network_accounts {
        id
        name
      }
    }
  }
`;

const AWIC_RULE_RESPONSE = gql`
  fragment Awic_Rule on Awic_Rule {
    id
    article_id
    article_type
    awic_network_account {
      id
    }
    domain {
      id
    }
    product {
      id
    }
    store {
      id
    }
    country {
      id
    }
  }
`;

const LIST_RESPONSE_PRODUCT = gql`
  fragment product on product {
    id
    name
  }
`;

const LIST_RESPONSE_BLOCK = gql`
  fragment block on block {
    id
    title
  }
`;

export const GET_CMS_AWIC_RULES = gql`
  ${AWIC_RULE_RESPONSE}
  query CMS_Awic_Rules($article_id: ID!, $article_type: String!) {
    CMS_Awic_Rules(article_id: $article_id, article_type: $article_type) {
      ...Awic_Rule
    }
  }
`;

export const CREATE_CMS_AWIC_RULES = gql`
  ${AWIC_RULE_RESPONSE}
  mutation CMS_Create_Awic_Rules(
    $article_id: ID!
    $article_type: Article_type!
    $awic_network_account_id: ID
    $domain_id: ID
    $product_id: ID
    $store_id: ID
    $country_id: ID
  ) {
    CMS_Create_Awic_Rules(
      article_id: $article_id
      article_type: $article_type
      awic_network_account_id: $awic_network_account_id
      domain_id: $domain_id
      product_id: $product_id
      store_id: $store_id
      country_id: $country_id
    ) {
      ...Awic_Rule
    }
  }
`;

export const UPDATE_CMS_AWIC_RULES = gql`
  ${AWIC_RULE_RESPONSE}
  mutation CMS_Update_Awic_Rule(
    $article_id: ID!
    $article_type: Article_type!
    $awic_network_account_id: ID
    $domain_id: ID
    $id: ID!
    $product_id: ID
    $store_id: ID
  ) {
    CMS_Update_Awic_Rule(
      article_id: $article_id
      article_type: $article_type
      awic_network_account_id: $awic_network_account_id
      domain_id: $domain_id
      id: $id
      product_id: $product_id
      store_id: $store_id
    ) {
      ...Awic_Rule
    }
  }
`;

export const DELETE_CMS_AWIC_RULES = gql`
  mutation CMS_Delete_Awic_Rule($id: [ID!]) {
    CMS_Delete_Awic_Rule(id: $id)
  }
`;

export const DELETE_CMS_BLOCK = gql`
  mutation CMS_DeleteBlock($id: [ID!]) {
    CMS_DeleteBlock(id: $id)
  }
`;

export const GET_CMS_STORE = gql`
  query CMS_Stores {
    CMS_Stores {
      id
      name
      agency_id
      brands {
        id
        name
      }
      categories {
        id
        name
      }
      countries {
        id
        iso_code
        name
      }
      description
      image
      is_private
      is_brand
      offer_links {
        offer_link
        product_id
        store_id
      }
      slug
      status
      website
    }
  }
`;

export const GET_CMS_BLOCKS = gql`
  query CMS_Blocks($page: Int, $size: Int, $search: String) {
    CMS_Blocks(page: $page, size: $size, search: $search) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        title
        description
        image
        is_private
        status
        lists {
          id
          title
        }
      }
    }
  }
`;

export const GET_CMS_BLOCKS_V2 = gql`
  query CMS_Blocks($page: Int, $size: Int, $search: String) {
    CMS_Blocks(page: $page, size: $size, search: $search) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        title
        description
      }
    }
  }
`;

export const ADD_CMS_BRAND = gql`
  mutation CMS_CreateBrand(
    $category_id: [Int]
    $description: String
    $image: String
    $is_private: Boolean
    $is_store: Boolean
    $name: String
    $status: String
    $store_id: [Int]
    $website: String
  ) {
    CMS_CreateBrand(
      category_id: $category_id
      description: $description
      image: $image
      is_private: $is_private
      is_store: $is_store
      name: $name
      status: $status
      store_id: $store_id
      website: $website
    ) {
      id
    }
  }
`;

export const UPDATE_CMS_BRAND = gql`
  mutation CMS_UpdateBrand(
    $id: ID!
    $category_id: [Int]
    $description: String
    $image: String
    $is_private: Boolean
    $is_store: Boolean
    $name: String
    $status: String
    $store_id: [Int]
    $website: String
  ) {
    CMS_UpdateBrand(
      id: $id
      category_id: $category_id
      description: $description
      image: $image
      is_private: $is_private
      is_store: $is_store
      name: $name
      status: $status
      store_id: $store_id
      website: $website
    ) {
      id
    }
  }
`;

export const DELETE_CMS_BRAND = gql`
  mutation CMS_DeleteBrand($id: [ID!]) {
    CMS_DeleteBrand(id: $id)
  }
`;

export const GET_CMS_BRANDS_PAGINATE = gql`
  query CMS_Brands_Paginate($page: Int, $search: String, $size: Int) {
    CMS_Brands_Paginate(page: $page, search: $search, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        name
        description
        slug
        website
        image
        categories {
          id
          name
        }
        stores {
          id
          name
        }
        is_private
        is_store
        status
      }
    }
  }
`;

export const GET_CMS_STORE_ID_NAME = gql`
  query CMS_Stores {
    CMS_Stores {
      id
      name
    }
  }
`;

export const ADD_CMS_CATEGORIES = gql`
  mutation CMS_CreateCategory(
    $description: String
    $image: String
    $is_private: Boolean
    $name: String
    $parent_category_id: Int
    # $slug: String
    $status: String
  ) {
    CMS_CreateCategory(
      description: $description
      image: $image
      is_private: $is_private
      name: $name
      parent_category_id: $parent_category_id
      # slug: $slug
      status: $status
    ) {
      id
    }
  }
`;

export const UPDATE_CMS_CATEGORIES = gql`
  mutation CMS_UpdateCategory(
    $id: ID!
    $description: String
    $image: String
    $is_private: Boolean
    $name: String
    $parent_category_id: Int
    # $slug: String
    $status: String
  ) {
    CMS_UpdateCategory(
      id: $id
      description: $description
      image: $image
      is_private: $is_private
      name: $name
      parent_category_id: $parent_category_id
      # slug: $slug
      status: $status
    ) {
      id
    }
  }
`;

export const DELETE_CMS_CATEGORIES = gql`
  mutation CMS_DeleteCategory($id: [ID!]) {
    CMS_DeleteCategory(id: $id)
  }
`;

export const GET_CMS_CATEGORIES = gql`
  query {
    CMS_Categories {
      id
      name
      description
      slug
      status
      image
      is_private
      children_category {
        id
        name
        description
        slug
        status
        image
        is_private
      }
    }
  }
`;

export const DELETE_CMS_DOMAINS = gql`
  mutation CMS_DeleteDomain($id: [ID!]) {
    CMS_DeleteDomain(id: $id)
  }
`;

export const DELETE_CMS_LIST = gql`
  mutation CMS_DeleteList($id: [ID!]) {
    CMS_DeleteList(id: $id)
  }
`;

export const DOMAIN_ASSIGN_TO_LIST = gql`
  mutation CMS_CreateDomainList($domain_id: [ID], $list_id: [ID]) {
    CMS_CreateDomainList(domain_id: $domain_id, list_id: $list_id) {
      id
    }
  }
`;

export const GET_CMS_LISTS = gql`
  query CMS_Lists(
    $domain_id: ID
    $page: Int
    $size: Int
    $search: String
    $user_id: ID
    $order: Order
    $column: String
    $list_type: List_Type
  ) {
    CMS_Lists(
      domain_id: $domain_id
      page: $page
      size: $size
      search: $search
      user_id: $user_id
      order: $order
      column: $column
      list_type: $list_type
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        title
        agency_id
        blocks {
          id
          title
        }
        categories {
          id
          name
        }
        categoryString
        description
        domains {
          id
          name
        }

        image
        is_private
        list_type
        pub_date
        slug
        status
        tags
        skimlink
        updatedAt
      }
    }
  }
`;

export const DELETE_CMS_PAGE = gql`
  mutation CMS_Delete_page($id: [ID!]) {
    CMS_Delete_page(id: $id)
  }
`;

export const GET_CMS_PAGES = gql`
  query CMS_Pages($domain_id: ID) {
    CMS_Pages(domain_id: $domain_id) {
      id
      title
      agency_id
      # content
      domains {
        id
        name
      }
      is_private
      pub_date
      slug
      status
    }
  }
`;

export const DELETE_CMS_PRODUCT = gql`
  mutation CMS_DeleteProduct($id: [ID!]) {
    CMS_DeleteProduct(id: $id)
  }
`;

export const GET_BASIC_CMS_PRODUCTS = gql`
  query CMS_Products($page: Int, $size: Int, $search: String) {
    CMS_Products(page: $page, size: $size, search: $search) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        name

        description
        image
        agency_id
        categories {
          id
          name
        }
        # content
        # domains {
        #   id
        #   name
        # }
        # is_feature_post
        is_private
        # pub_date
        slug
        status
        tags
      }
    }
  }
`;

export const ADD_CMS_SIDES = gql`
  mutation CMS_CreateSides($body: String, $is_good: Boolean) {
    CMS_CreateSides(body: $body, is_good: $is_good) {
      id
    }
  }
`;

export const UPDATE_CMS_SIDES = gql`
  mutation CMS_UpdateSides($id: ID!, $body: String, $is_good: Boolean) {
    CMS_UpdateSides(id: $id, body: $body, is_good: $is_good) {
      id
    }
  }
`;

export const DELETE_CMS_SIDE = gql`
  mutation CMS_DeleteSide($id: ID!) {
    CMS_DeleteSide(id: $id)
  }
`;

export const GET_CMS_SIDES = gql`
  query CMS_Sides {
    CMS_Sides {
      id
      body
      is_good
    }
  }
`;

export const DELETE_CMS_REVIEW = gql`
  mutation CMS_DeleteReview($id: [ID!]) {
    CMS_DeleteReview(id: $id)
  }
`;

export const GET_CMS_REVIEWS = gql`
  query CMS_Reviews {
    CMS_Reviews {
      id
      rating
      product {
        name
      }
      status
    }
  }
`;

export const ADD_CMS_STORE = gql`
  mutation CMS_CreateStore(
    $description: String
    $image: String
    $is_private: Boolean
    $name: String
    $status: String
    $website: String
    $category_id: [Int]
    $country_id: [Int]
    $is_brand: Boolean
  ) {
    CMS_CreateStore(
      description: $description
      image: $image
      is_private: $is_private
      name: $name
      status: $status
      website: $website
      category_id: $category_id
      country_id: $country_id
      is_brand: $is_brand
    ) {
      id
    }
  }
`;

export const UPDATE_CMS_STORE = gql`
  mutation CMS_UpdateStore(
    $id: ID!
    $description: String
    $image: String
    $is_private: Boolean
    $name: String
    $status: String
    $website: String
    $category_id: [Int]
    $country_id: [Int]
    $is_brand: Boolean
  ) {
    CMS_UpdateStore(
      id: $id
      description: $description
      image: $image
      is_private: $is_private
      name: $name
      status: $status
      website: $website
      category_id: $category_id
      country_id: $country_id
      is_brand: $is_brand
    ) {
      id
    }
  }
`;

export const DELETE_CMS_STORE = gql`
  mutation CMS_DeleteStore($id: [ID!]) {
    CMS_DeleteStore(id: $id)
  }
`;

export const GET_CMS_COUNTRIES = gql`
  query CMS_Countries {
    CMS_Countries {
      id
      iso_code
      name
    }
  }
`;

export const DELETE_CMS_STORY = gql`
  mutation CMS_DeleteStory($id: [ID!]) {
    CMS_DeleteStory(id: $id)
  }
`;

export const DOMAIN_ASSIGN_TO_STORIES = gql`
  mutation CMS_CreateDomain_Story($domain_id: [ID], $story_id: [ID]) {
    CMS_CreateDomain_Story(domain_id: $domain_id, story_id: $story_id)
  }
`;

export const GET_BASIC_CMS_STORIES = gql`
  query CMS_Stories(
    $domain_id: ID
    $page: Int
    $size: Int
    $search: String
    $user_id: ID
    $order: Order
    $column: String
  ) {
    CMS_Stories(
      domain_id: $domain_id
      page: $page
      size: $size
      search: $search
      user_id: $user_id
      order: $order
      column: $column
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        title
        sub_title
        image
        agency_id
        categories {
          id
          name
        }
        # content
        domains {
          id
          name
        }
        is_feature_post
        is_private
        pub_date
        slug
        status
        tags
        skimlink
      }
    }
  }
`;

export const GET_CMS_CATEGORIES_ID_NAME = gql`
  query {
    CMS_Categories {
      id
      name
      children_category {
        id
        name
      }
      domain_category {
        id
        domain_id
        is_menu
        category_id
      }
    }
  }
`;

export const GET_CATEGORIES_BY_DOMAIN = gql`
  query CMS_ParentCategoriesByDomain($domain_id: [ID!]) {
    CMS_ParentCategoriesByDomain(domain_id: $domain_id) {
      id
      name
      children_category {
        id
        name
      }
    }
  }
`;

export const GET_ALL_CMS_LISTS_ID_NAME = gql`
  query CMS_Type_Of_List($type: List_type) {
    CMS_Type_Of_List(type: $type) {
      id
      title
    }
  }
`;

export const GET_ALL_CMS_LISTS_FOR_VS_CONTENT = gql`
  query CMS_Type_Of_List($type: List_type) {
    CMS_Type_Of_List(type: $type) {
      id
      title
      image
      slug
      description
    }
  }
`;

export const GET_CMS_ALL_PRODUCTS_ID_NAME = gql`
  query CMS_All_Products {
    CMS_All_Products {
      id
      name
    }
  }
`;

export const GET_CMS_ALL_BLOCKS_ID_NAME_DESCRIPTION = gql`
  query CMS_All_Blocks {
    CMS_All_Blocks {
      id
      title
      description
      image
    }
  }
`;

export const GET_CMS_ALL_PRODUCTS_ID_NAME_DESCRIPTION = gql`
  query CMS_All_Products {
    CMS_All_Products {
      id
      name
      description
      image
    }
  }
`;

export const GET_CMS_BRANDS_ID_NAME = gql`
  query CMS_Brands {
    CMS_Brands {
      id
      name
    }
  }
`;

export const ADD_CMS_BLOCK = gql`
  mutation CMS_CreateBlock(
    $title: String
    $description: String
    $image: String
    $is_private: Boolean
    $list_id: [Int]
    $status: String
  ) {
    CMS_CreateBlock(
      title: $title
      description: $description
      image: $image
      is_private: $is_private
      list_id: $list_id
      status: $status
    ) {
      id
      title
    }
  }
`;

export const ADD_CMS_LIST = gql`
  mutation CMS_CreateList(
    $category_id: [Int]
    $description: String
    $domain_id: [Int]
    $image: String
    $is_private: Boolean
    $is_feature_post: Boolean
    $list_type: List_type
    $status: String
    $tags: [String]
    $title: String
    $product_id: [ID]
    $block_id: [ID]
    $bottom_line: String
    $pub_date: String
    $cta_button_type: Int
    $meta_description: String
    $meta_keywords: String
    $meta_title: String
    $skimlink: Boolean
    $ncid: ID
    $language_id: Int
    $domain_group_type: Group_type
    $group_id: Int
    $list_cons: [String]
    $list_pros: [String]
    $sub_title: String
  ) {
    CMS_CreateList(
      category_id: $category_id
      description: $description
      domain_id: $domain_id
      image: $image
      is_private: $is_private
      is_feature_post: $is_feature_post
      list_type: $list_type
      status: $status
      tags: $tags
      title: $title
      product_id: $product_id
      block_id: $block_id
      bottom_line: $bottom_line
      pub_date: $pub_date
      cta_button_type: $cta_button_type
      meta_description: $meta_description
      meta_keywords: $meta_keywords
      meta_title: $meta_title
      skimlink: $skimlink
      ncid: $ncid
      language_id: $language_id
      domain_group_type: $domain_group_type
      group_id: $group_id
      list_cons: $list_cons
      list_pros: $list_pros
      sub_title: $sub_title
    ) {
      id
      slug
    }
  }
`;

export const ADD_CMS_REVIEW = gql`
  mutation CMS_CreateReview(
    $content: String
    $is_private: Boolean
    $product_id: Int
    $rating: String
    $status: String
  ) {
    CMS_CreateReview(
      content: $content
      is_private: $is_private
      product_id: $product_id
      rating: $rating
      status: $status
    ) {
      id
    }
  }
`;

export const ADD_CMS_RODUCT = gql`
  mutation CMS_Create_Page(
    $content: String
    $domain_id: [Int]
    $is_private: Boolean
    $pub_date: Date
    $status: String
    $title: String
  ) {
    CMS_Create_Page(
      content: $content
      domain_id: $domain_id
      is_private: $is_private
      pub_date: $pub_date
      status: $status
      title: $title
    ) {
      id
    }
  }
`;

export const CMS_CREATE_PRODUCT = gql`
  mutation CMS_CreateProduct(
    $brand_id: Int
    $category_id: [Int]
    $description: String
    $image: String
    $is_private: Boolean
    $list_id: [Int]
    $name: String
    $product_type_id: Int
    $status: String
    $tags: [String]
    $offer_link: [input_offer_link]
    $product_cons: [String]
    $product_pros: [String]
    $starRating: Float
    $language_id: Int
    $highlight: [Highlights]
    $is_review_number: Int
    $is_review: Boolean
    $cta_button: String
    $type_of_product: Type_Of_Product
    $cta_description: String
  ) {
    CMS_CreateProduct(
      brand_id: $brand_id
      category_id: $category_id
      description: $description
      image: $image
      is_private: $is_private
      list_id: $list_id
      name: $name
      product_type_id: $product_type_id
      status: $status
      tags: $tags
      offer_link: $offer_link
      product_cons: $product_cons
      product_pros: $product_pros
      starRating: $starRating
      language_id: $language_id
      highlight: $highlight
      is_review_number: $is_review_number
      is_review: $is_review
      cta_button: $cta_button
      type_of_product: $type_of_product
      cta_description: $cta_description
    ) {
      id
      name
      type_of_product
      is_private
    }
  }
`;

export const CMS_CREATE_STORY = gql`
  mutation CMS_CreateStory(
    $category_id: [Int]
    $content: String
    $sub_title: String
    $image: String
    $is_feature_post: Boolean
    $is_private: Boolean
    $status: String
    $domain_id: [Int]
    $tags: [String]
    $title: String
    $pub_date: String
    $product_id: [Int]
    $store_id: [Int]
    $meta_description: String
    $meta_keywords: String
    $meta_title: String
    $skimlink: Boolean
    $ncid: ID
    $language_id: Int
  ) {
    CMS_CreateStory(
      category_id: $category_id
      content: $content
      sub_title: $sub_title
      image: $image
      is_feature_post: $is_feature_post
      is_private: $is_private
      status: $status
      domain_id: $domain_id
      tags: $tags
      title: $title
      pub_date: $pub_date
      product_id: $product_id
      store_id: $store_id
      meta_description: $meta_description
      meta_keywords: $meta_keywords
      meta_title: $meta_title
      skimlink: $skimlink
      ncid: $ncid
      language_id: $language_id
    ) {
      id
      slug
    }
  }
`;

export const GET_CMS_AWIC_NETWORK = gql`
  query CMS_Awic_Network($id: ID!) {
    CMS_Awic_Network(id: $id) {
      id
      name
      description
      type
      categories {
        id
        name
      }
    }
  }
`;

export const GET_CMS_BLOCK = gql`
  query CMS_Block($id: ID!) {
    CMS_Block(id: $id) {
      id
      title
      description
      image
      is_private
      status
      lists {
        id
        title
      }
    }
  }
`;

export const GET_CMS_LIST = gql`
  query CMS_List($id: ID!) {
    CMS_List(id: $id) {
      id
      title
      agency_id
      bottom_line
      description
      image
      is_private
      is_feature_post
      list_type
      slug
      status
      tags
      pub_date
      cta_button_type
      meta_description
      meta_keywords
      meta_title
      skimlink
      ncid
      list_cons
      list_pros
      domain_group_type
      sub_title

      groups {
        id
      }

      blocks {
        id
        title
        is_private
      }

      categories {
        id
        name
      }

      domains {
        id
        name
      }

      products {
        id
        name
        is_private
        type_of_product
        description
      }

      language {
        id
        name
      }
    }
  }
`;

export const GET_CMS_ALL_LISTS_PRODUCTS = gql`
  query CMS_List($id: ID!) {
    CMS_List(id: $id) {
      id
      title
      sub_title
      description
      list_cons
      list_pros

      groups {
        id
      }

      blocks {
        id
        title
        is_private
      }

      categories {
        id
        name
      }

      domains {
        id
        name
      }

      products {
        name
        description
        image
        product_cons
        product_pros
        highlights {
          description
          id
          name
        }
        starRating
        is_review
        is_review_number
        cta_button
        type_of_product
        cta_description
      }
    }
  }
`;

export const GET_CMS_PAGE = gql`
  query CMS_Page($id: ID!) {
    CMS_Page(id: $id) {
      id
      title
      agency_id
      content
      domains {
        id
        name
      }
      is_private
      pub_date
      slug
      status
    }
  }
`;

export const GET_CMS_PRODUCT = gql`
  query CMS_Product($id: ID!) {
    CMS_Product(id: $id) {
      id
      name
      description
      image
      agency_id
      categories {
        id
        name
      }
      # content
      offer_links {
        store_id
        offer_link
        offer_price
        strike_price
        currency
      }
      is_private
      lists {
        id
        title
      }
      slug
      status
      tags
      brand {
        id
        name
      }
      product_cons
      product_pros
      language {
        id
        name
      }
      highlights {
        description
        id
        name
      }
      starRating
      is_review
      is_review_number
      cta_button
      type_of_product
      cta_description
    }
  }
`;

export const GET_CMS_REVIEW = gql`
  query CMS_Review($id: ID!) {
    CMS_Review(id: $id) {
      id
      content
      is_private
      product {
        id
      }
      rating
      status
    }
  }
`;

export const GET_CMS_STORE_NAME_WEBSITE_BY_BRANDS = gql`
  query CMS_Brand($id: ID!) {
    CMS_Brand(id: $id) {
      id
      stores {
        id
        name
        website
      }
    }
  }
`;

export const GET_CMS_STORY = gql`
  query CMS_Story($id: ID!) {
    CMS_Story(id: $id) {
      id
      title
      sub_title
      image
      agency_id
      content
      categories {
        id
        name
      }
      domains {
        id
        name
      }
      is_feature_post
      is_private
      pub_date
      slug
      status
      tags
      products {
        id
        name
      }
      stores {
        id
      }
      meta_description
      meta_keywords
      meta_title
      skimlink
      ncid
      language {
        id
        name
      }
    }
  }
`;

export const UPDATE_CMS_BLOCK = gql`
  mutation CMS_UpdateBlock(
    $description: String
    $id: ID!
    $image: String
    $is_private: Boolean
    $list_id: [Int]
    $status: String
    $title: String
  ) {
    CMS_UpdateBlock(
      description: $description
      id: $id
      image: $image
      is_private: $is_private
      list_id: $list_id
      status: $status
      title: $title
    ) {
      id
      title
      is_private
    }
  }
`;

export const UPDATE_CMS_LIST = gql`
  mutation CMS_UpdateList(
    $id: ID!
    $category_id: [Int]
    $description: String
    $domain_id: [Int]
    $image: String
    $is_private: Boolean
    $is_feature_post: Boolean
    $list_type: List_type
    $status: String
    $tags: [String]
    $title: String
    $product_id: [ID]
    $block_id: [ID]
    $slug: String
    $bottom_line: String
    $pub_date: String
    $cta_button_type: Int
    $meta_description: String
    $meta_keywords: String
    $meta_title: String
    $skimlink: Boolean
    $ncid: ID
    $language_id: Int
    $list_cons: [String]
    $list_pros: [String]
    $domain_group_type: Group_type
    $group_id: Int
    $sub_title: String
  ) {
    CMS_UpdateList(
      id: $id
      category_id: $category_id
      description: $description
      domain_id: $domain_id
      image: $image
      is_private: $is_private
      is_feature_post: $is_feature_post
      list_type: $list_type
      status: $status
      tags: $tags
      title: $title
      product_id: $product_id
      block_id: $block_id
      slug: $slug
      bottom_line: $bottom_line
      pub_date: $pub_date
      cta_button_type: $cta_button_type
      meta_description: $meta_description
      meta_keywords: $meta_keywords
      meta_title: $meta_title
      skimlink: $skimlink
      ncid: $ncid
      language_id: $language_id
      list_cons: $list_cons
      list_pros: $list_pros
      domain_group_type: $domain_group_type
      group_id: $group_id
      sub_title: $sub_title
    ) {
      id
      status
    }
  }
`;

export const UPDATE_CMS_PAGE = gql`
  mutation CMS_Update_page(
    $content: String
    $domain_id: [Int]
    $id: ID
    $is_private: Boolean
    $pub_date: Date
    $status: String
    $title: String
    $slug: String
  ) {
    CMS_Update_page(
      content: $content
      domain_id: $domain_id
      id: $id
      is_private: $is_private
      pub_date: $pub_date
      status: $status
      title: $title
      slug: $slug
    ) {
      id
    }
  }
`;

export const UPDATE_CMS_PRODUCT = gql`
  mutation CMS_UpdateProduct(
    $id: ID!
    $brand_id: Int
    $category_id: [Int]
    $description: String
    $image: String
    $is_private: Boolean
    $list_id: [Int]
    $name: String
    $product_type_id: Int
    $status: String
    $tags: [String]
    $offer_link: [input_offer_link]
    $product_cons: [String]
    $product_pros: [String]
    $slug: String
    $starRating: Float
    $language_id: Int
    $highlight: [Highlights]
    $is_review_number: Int
    $is_review: Boolean
    $cta_button: String
    $type_of_product: Type_Of_Product
    $cta_description: String
  ) {
    CMS_UpdateProduct(
      id: $id
      brand_id: $brand_id
      category_id: $category_id
      description: $description
      image: $image
      is_private: $is_private
      list_id: $list_id
      name: $name
      product_type_id: $product_type_id
      status: $status
      tags: $tags
      offer_link: $offer_link
      product_cons: $product_cons
      product_pros: $product_pros
      slug: $slug
      starRating: $starRating
      language_id: $language_id
      highlight: $highlight
      is_review_number: $is_review_number
      is_review: $is_review
      cta_button: $cta_button
      type_of_product: $type_of_product
      cta_description: $cta_description
    ) {
      id
      name
      type_of_product
      is_private
    }
  }
`;

export const UPDATE_CMS_REVIEW = gql`
  mutation CMS_UpdateReview(
    $id: ID!
    $content: String
    $is_private: Boolean
    $product_id: Int
    $rating: String
    $status: String
  ) {
    CMS_UpdateReview(
      id: $id
      content: $content
      is_private: $is_private
      product_id: $product_id
      rating: $rating
      status: $status
    ) {
      id
    }
  }
`;

export const UPDATE_CMS_STORY = gql`
  mutation CMS_UpdateStory(
    $category_id: [Int]
    $content: String
    $sub_title: String
    $id: ID!
    $image: String
    $is_feature_post: Boolean
    $is_private: Boolean
    $slug: String
    $status: String
    $domain_id: [Int]
    $tags: [String]
    $title: String
    $pub_date: String
    $product_id: [Int]
    $store_id: [Int]
    $meta_description: String
    $meta_keywords: String
    $meta_title: String
    $skimlink: Boolean
    $ncid: ID
    $language_id: Int
  ) {
    CMS_UpdateStory(
      category_id: $category_id
      content: $content
      sub_title: $sub_title
      id: $id
      image: $image
      is_feature_post: $is_feature_post
      is_private: $is_private
      slug: $slug
      status: $status
      domain_id: $domain_id
      tags: $tags
      title: $title
      pub_date: $pub_date
      product_id: $product_id
      store_id: $store_id
      meta_description: $meta_description
      meta_keywords: $meta_keywords
      meta_title: $meta_title
      skimlink: $skimlink
      ncid: $ncid
      language_id: $language_id
    ) {
      id
    }
  }
`;

export const CMS_IMAGES = gql`
  query CMS_Images {
    CMS_Images {
      id
      sizes
    }
  }
`;

const CMS_USER_RESPONSE = gql`
  fragment CmsUser on cmsUser {
    id
    user_name
    domains {
      id
      name
    }
  }
`;

export const GET_CMS_USERS = gql`
  ${CMS_USER_RESPONSE}
  query CMS_Users($page: Int, $search: String, $size: Int) {
    CMS_Users(page: $page, search: $search, size: $size) {
      data {
        ...CmsUser
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CREATE_CMS_USER = gql`
  ${CMS_USER_RESPONSE}
  mutation CMS_Create_User($domain_id: [ID], $id: ID!, $user_name: String) {
    CMS_Create_User(domain_id: $domain_id, id: $id, user_name: $user_name) {
      ...CmsUser
    }
  }
`;

export const UPDATE_CMS_USER = gql`
  ${CMS_USER_RESPONSE}
  mutation CMS_Update_User($domain_id: [ID], $id: ID!, $user_name: String) {
    CMS_Update_User(domain_id: $domain_id, id: $id, user_name: $user_name) {
      ...CmsUser
    }
  }
`;

export const DELETE_CMS_USER = gql`
  mutation CMS_Delete_User($id: [ID]) {
    CMS_Delete_User(id: $id)
  }
`;

const CMS_AGENCY_RESPONSE = gql`
  fragment CmsAgency on cmsAgency {
    id
    name
  }
`;

export const GET_CMS_AGENCIES = gql`
  ${CMS_AGENCY_RESPONSE}
  query CMS_Agencies {
    CMS_Agencies {
      ...CmsAgency
    }
  }
`;

export const CREATE_CMS_AGENCY = gql`
  ${CMS_AGENCY_RESPONSE}
  mutation CMS_Create_Agency($id: ID!, $name: String) {
    CMS_Create_Agency(id: $id, name: $name) {
      ...CmsAgency
    }
  }
`;

export const UPDATE_CMS_AGENCY = gql`
  ${CMS_AGENCY_RESPONSE}
  mutation CMS_Update_Agency($id: ID!, $name: String) {
    CMS_Update_Agency(id: $id, name: $name) {
      ...CmsAgency
    }
  }
`;

export const DELETE_CMS_AGENCY = gql`
  mutation CMS_Delete_Agency($id: [ID]) {
    CMS_Delete_Agency(id: $id)
  }
`;

const CMS_DOMAIN_BANNER_RESPONSE = gql`
  fragment Domain_Banner on Domain_Banner {
    id
    image
    image_size_id
    lander_link
    product_id
    domain_id
    store_id
    list_id
    banner_type
  }
`;

export const GQL_CMS_GET_BANNER = gql`
  ${CMS_DOMAIN_BANNER_RESPONSE}
  query CMS_Get_Banner($domain_id: ID, $product_id: ID, $store_id: ID, $list_id: ID) {
    CMS_Get_Banner(
      domain_id: $domain_id
      product_id: $product_id
      store_id: $store_id
      list_id: $list_id
    ) {
      ...Domain_Banner
    }
  }
`;

export const GQL_CMS_CREATE_BANNER = gql`
  ${CMS_DOMAIN_BANNER_RESPONSE}
  mutation CMS_Create_Banner(
    $banner_image: [banner_image]
    $domain_id: String
    $image_size_id: String
    $is_cobranded: Boolean
    $language_id: String
    $placeholder: String
    $product_id: String
    $store_id: String
    $list_id: String
    $banner_type: Banner_type
  ) {
    CMS_Create_Banner(
      banner_image: $banner_image
      domain_id: $domain_id
      image_size_id: $image_size_id
      is_cobranded: $is_cobranded
      language_id: $language_id
      placeholder: $placeholder
      product_id: $product_id
      store_id: $store_id
      list_id: $list_id
      banner_type: $banner_type
    ) {
      ...Domain_Banner
    }
  }
`;

export const GQL_CMS_UPDATE_BULK_BANNER = gql`
  ${CMS_DOMAIN_BANNER_RESPONSE}
  mutation CMS_Update_BulkBanner(
    $banner_image: [banner_image]
    $banner_type: Banner_type
    $list_id: String
    $product_id: String
  ) {
    CMS_Update_BulkBanner(
      banner_image: $banner_image
      banner_type: $banner_type
      list_id: $list_id
      product_id: $product_id
    ) {
      ...Domain_Banner
    }
  }
`;

export const GQL_CMS_UPDATE_BANNER = gql`
  ${CMS_DOMAIN_BANNER_RESPONSE}
  mutation CMS_Update_Banner(
    $id: ID!
    $domain_id: String
    $image: String!
    $image_size_id: String
    $is_cobranded: Boolean
    $lander_link: String!
    $language_id: String
    $placeholder: String
    $product_id: String
    $store_id: String
  ) {
    CMS_Update_Banner(
      id: $id
      domain_id: $domain_id
      image: $image
      image_size_id: $image_size_id
      is_cobranded: $is_cobranded
      lander_link: $lander_link
      language_id: $language_id
      placeholder: $placeholder
      product_id: $product_id
      store_id: $store_id
    ) {
      ...Domain_Banner
    }
  }
`;

export const GQL_CMS_DELETE_BANNER = gql`
  mutation CMS_Delete_Banner($id: ID!) {
    CMS_Delete_Banner(id: $id)
  }
`;

const CMS_ADVERTISER_DATA_RESPONSE = gql`
  fragment advertiser_data on advertiser_data {
    id
    advertiser_id
    advertiser_name
    article_id
    article_type
    country
    id
    network_acc_id
    tagId
  }
`;

export const GQL_CMS_GET_ADVERTISER = gql`
  ${CMS_ADVERTISER_DATA_RESPONSE}
  query CMS_Get_Advertiser($article_id: ID, $article_type: Article) {
    CMS_Get_Advertiser(article_id: $article_id, article_type: $article_type) {
      ...advertiser_data
    }
  }
`;

/**
 ** $advertiser: [advertiser]
 *
 * advertiser_id: String!
 * advertiser_name: String!
 * article_id: ID!
 * article_type: Article!
 * country: String
 * network_acc_id: String!
 * tagId: ID!
 */

export const GQL_CMS_CREATE_ADVERTISER = gql`
  ${CMS_ADVERTISER_DATA_RESPONSE}
  mutation CMS_Create_Advertiser($advertiser: [advertiser], $link_of_article: String) {
    CMS_Create_Advertiser(advertiser: $advertiser, link_of_article: $link_of_article) {
      ...advertiser_data
    }
  }
`;

export const GQL_CMS_UPDATE_ADVERTISER = gql`
  ${CMS_ADVERTISER_DATA_RESPONSE}
  mutation CMS_Update_Advertiser($advertiser: [advertiser_update], $link_of_article: String) {
    CMS_Update_Advertiser(advertiser: $advertiser, link_of_article: $link_of_article) {
      ...advertiser_data
    }
  }
`;

export const GQL_CMS_DELETE_ADVERTISER = gql`
  mutation CMS_Delete_Advertise($id: ID!) {
    CMS_Delete_Advertise(id: $id)
  }
`;

export const GQL_CMS_ARTICLE_CLONE = gql`
  mutation CMS_CloneStory($id: [ID]!, $count: Int) {
    CMS_CloneStory(id: $id, count: $count)
  }
`;
