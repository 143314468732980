import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import LookButton from "components/LookButton";
import LookCategoryReports from "components/LookCategoryReports";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { VOLUUM_GET_SUBIDS } from "shared/gql/gqlSchema/fb360Gql";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "../../leadgenKeysAndEnums";

export default function SubIdReports() {
  const [rowPropsData, setRowProps] = useState({});
  const [subIdReport, setSubIdReport] = useState([]);
  const [visibleCreativesDrawer, setVisibleCreativesDrawer] = useState(false);
  const showCreativesDrawer = (rowProps) => {
    setRowProps({ ...rowProps, subId: rowProps?.sub_id });
    setVisibleCreativesDrawer(true);
  };
  const onCloseCreativesDrawer = () => {
    setRowProps({});
    setVisibleCreativesDrawer(false);
  };
  const actions = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        return (
          <>
            <LookButton
              tooltipTitle="Show Creatives"
              className="h-auto"
              iconType="image"
              onClick={() => showCreativesDrawer(actionProps)}
            />
          </>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const initialColumns = [
    {
      title: "Sub ID",
      dataIndex: "sub_id",
      key: "sub_id",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (value) => <LookTableColumnRender title={value?.name} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    ...actions,
  ];
  const { loading, refetch } = useCustomQuery(VOLUUM_GET_SUBIDS, {
    onCompleted: (e) => {
      const element = e?.voluum_get_subids;
      setSubIdReport(element);
    },
    variables: {
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
    },
  });

  const tableProps = {
    initialColumns,
    recordData: subIdReport,
  };
  const creativeDrawerProps = {
    title: rowPropsData?.name,
    visibleDrawer: visibleCreativesDrawer,
    onCloseReportsDrawer: onCloseCreativesDrawer,
    size: 1200,
  };

  return (
    <>
      {visibleCreativesDrawer && (
        <LookCategoryReports subIdReports {...rowPropsData} {...creativeDrawerProps} />
      )}

      <DashboardHeader title="Sub ID" />

      <LookTable loading={loading} tableProps={tableProps} refetch={refetch} />
    </>
  );
}
