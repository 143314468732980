import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { ClickToCopyButton, openNotification } from "utils";
import { G360_LAUNCH_LISTING } from "shared/gql/gqlSchema/googleLaunch";
import { SUCCESS } from "shared/enum/commonKeysandEnum";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

export const GOOGLE_LAUNCH_LIST_TYPE_LAUNCHER = "launch_list";
export const GOOGLE_LAUNCH_LIST_TYPE_SNIPPET = "launch_snippet";

function GoogleLaunchListing(props) {
  const { listType = "" } = props;
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const [listingData, setListingData] = useState([]);
  const [rangeDates, setRangeDates] = useState(dateRangeTimezone?.rangeDates);
  const [timeZone, setTimeZone] = useState(dateRangeTimezone?.timeZone);
  const { filterData } = useLookTableFilterData(persistKey);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  let finalFilterData = [];

  const { loading: loading_LISTING, refetch: refetch_LISTING } = useCustomQuery(
    G360_LAUNCH_LISTING,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.gl_getLaunchDetails;
        if (element) {
          setListingData(element);
        }
      },
      onError: (err) => {
        openNotification({ type: "error", message: err.message });
      },
      variables: {
        fromDate: rangeDates[0],
        toDate: rangeDates[1],
      },
    }
  );

  const refetch = () => {
    refetch_LISTING();
  };
  const loading = loading_LISTING;

  const initialColumns = [
    ...(listType === GOOGLE_LAUNCH_LIST_TYPE_LAUNCHER
      ? [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            fixed: "left",
            width: 160,
            minWidth: 160,
            sorter: (a, b) => a?.name - b?.name,
            searchType: "string",
          },
          {
            title: "Id",
            dataIndex: "id",
            key: "id",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            fixed: "left",
            width: 160,
            minWidth: 160,
            sorter: (a, b) => a?.id - b?.id,
            hidden: true,
            searchType: "string",
          },
          {
            title: "Created By",
            dataIndex: "createdBy",
            key: "createdBy",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            fixed: "left",
            width: 160,
            minWidth: 160,
            sorter: (a, b) => a?.createdBy - b?.createdBy,
            searchType: "string",
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value, otherProps) => {
              // we are getting "LaunchStatus.SUCCESS" from backend
              const status = value.split(".")[1];

              return (
                <LookTableColumnRender
                  className={status === SUCCESS ? "text-primary" : "text-danger"}
                  title={status}
                />
              );
            },
            fixed: "left",
            width: 160,
            minWidth: 160,
            sorter: (a, b) => a?.status - b?.status,
            searchType: "string",
          },
        ]
      : [
          {
            title: "Conversion Name",
            dataIndex: "conversion_name",
            key: "conversion_name",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            fixed: "left",
            width: 160,
            minWidth: 160,
            sorter: (a, b) => a?.conversion_name - b?.conversion_name,
            searchType: "string",
          },
          {
            title: "Code snippet",
            dataIndex: "code_snippet",
            key: "code_snippet",
            render: (value, otherProps) => (
              <ClickToCopyButton
                copyText={value}
                successText={
                  <div>
                    <p className="mb-0">
                      <b>Code snippet copied</b>
                    </p>
                    <code className="line-height-1" style={{ fontSize: ".7em" }}>
                      {value}
                    </code>
                  </div>
                }
                tooltipText="Copy to clipboard"
              />
            ),
            fixed: "left",
            width: 160,
            minWidth: 160,
          },
        ]),
  ];

  if (!loading) {
    finalFilterData = filterData(listingData)?.filter(({ name = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";
      if (name?.toLowerCase()?.includes(searchValue)) {
        return true;
      }
      return false;
    });
  }

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    isRecommendation: true,
    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData },
    persistKey,
    dateRangeProps: {
      onChange: handleDateRange,
    },
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ listingData }, { finalFilterData }],
    },
  ];

  return (
    <div>
      <DashboardHeader title="Launch list" />
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default GoogleLaunchListing;
