import { Route, Redirect, useLocation } from "react-router-dom";
import { useUser } from "../utils";
import Loader from "../components/loader";
import DashboardLayout from "../components/layouts/DashboardLayout";

import { useSelector, useDispatch } from "react-redux";
import { Suspense, useEffect, useImperativeHandle } from "react";
import {
  ME_DATA,
  USER_SETTING_CLEAR_THINGS_ON_ROUTE_CHANGES,
  USER_SETTING_DEFAULT_FILTER,
  USER_SETTING_SEARCH_FILTER,
  WORKSPACE_PRODUCTS_DATA,
  WORKSPACE_SELECTION,
} from "../actions/actionTypes";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { GET_ME } from "shared/gql";
import { DashboardRouteContext } from "./context";
// import useCallTimezone from "./useCallTimezone";
import CallTimezone from "./useCallTimezone";
import ChatGpt from "components/ChatGPTpopup/ChatGpt";
import { SC_IS_DEPARTMENT_ADMIN, SC_IS_VIEW_MILESTONES } from "shared/gql/gqlSchema/scorecardGql";
import { useAwsAuth } from "shared/AwsAuth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { usePermissionCheck } from "hooks";
import useUnicornReducerDateSetter from "pages/dashboardPages/Unicorn/useUnicornReducerDateSetter";
import useScorecardReducerDateSetter from "pages/dashboardPages/Scorecard/useScorecardReducerDateSetter";
import useWorkspaceProducts from "hooks/queriesAndMutationsHooks/umsQueriesAndHooks/useWorkspaceProducts";
import useUnicornReducerStatusSetter from "pages/dashboardPages/Unicorn/useUnicornReducerStatusSetter";

export function PublicRoute({ component: Component, ...rest }) {
  const { loading, loggedIn } = useUser();

  if (loading) return <Loader fullScreen />;

  return (
    <Route
      {...rest}
      render={(props) => (!loggedIn ? <Component {...props} /> : <Redirect to="/dashboard" />)}
    />
  );
}

export function PrivateRoute({ component: Component, ...rest }) {
  const { awsAuthRef } = useAwsAuth();
  const history = useHistory();

  useImperativeHandle(awsAuthRef, () => {
    return {
      redirect: (e) => history.push(e),
    };
  });
  const { loading, loggedIn } = useUser();

  if (loading) return <Loader fullScreen />;

  return (
    <Route
      {...rest}
      render={(props) => (loggedIn ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
}

const useMeSetup = () => {
  const dispatch = useDispatch();
  const selectedWorkspace = useSelector((state) => state?.userSettings?.selectedWorkspace);
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions) || [];
  const meData = useSelector((state) => state?.userReducer?.me);
  const [SC_isDepartmentAdmin, { loading: SCORECARD_ADMIN_LOADING }] = useCustomLazyQuery(
    SC_IS_DEPARTMENT_ADMIN,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.SC_isDepartmentAdmin;
        dispatch({
          type: ME_DATA,
          payload: {
            ...meData,
            isDepartmentAdmin: element,
          },
        });
      },
    }
  );

  const [SC_isViewMilestones, { loading: SC_IS_VIEW_MILESTONES_LOADING }] = useCustomLazyQuery(
    SC_IS_VIEW_MILESTONES,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.SC_isViewMilestones;
        dispatch({
          type: ME_DATA,
          payload: {
            ...meData,
            isViewMilestone: element,
          },
        });
      },
    }
  );

  const { data, loading, refetch, error } = useCustomQuery(GET_ME, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      SC_isDepartmentAdmin();
      SC_isViewMilestones();
      const element = e?.me;
      if (element) {
        dispatch({
          type: ME_DATA,
          payload: {
            ...meData,
            userLoading: false,
            user_id: element.id,
            name: element.name,
            agency_id: element.agency_id,
            permissions: element.permissions,
            agency_name: element.agency?.name,
            role: element.roles,
            workspace: element.workspaces,
            lf_superadmin: element.lf_superadmin,
            platforms: element?.platforms,
          },
        });

        if (!selectedWorkspace) {
          dispatch({ type: WORKSPACE_SELECTION, payload: element.workspaces[0]?.id });
        } else if (element.workspaces && element.workspaces.length === 1) {
          dispatch({ type: WORKSPACE_SELECTION, payload: element.workspaces[0]?.id });
        } else if (element.workspaces && element.workspaces.length > 1 && selectedWorkspace) {
          if (element.workspaces?.filter(({ id }) => selectedWorkspace === id).length === 0) {
            dispatch({ type: WORKSPACE_SELECTION, payload: element.workspaces[0]?.id });
          }
        }
      }
    },
  });

  useEffect(() => {
    if (loading && permissions.length === 0) {
      dispatch({ type: ME_DATA, payload: { ...meData, userLoading: true } });
    }
  }, [data, loading, dispatch]);
  return { loading, refetch };
};

export function DashboardRoute({ component: Component, permission, ...rest }) {
  const { awsAuthRef } = useAwsAuth();
  const history = useHistory();
  const selectedWorkspace = useSelector((state) => state?.userSettings?.selectedWorkspace);

  useImperativeHandle(awsAuthRef, () => {
    return {
      redirect: (e) => history.push(e),
    };
  });

  const { loading, loggedIn } = useUser();
  const location = useLocation();
  const dispatch = useDispatch();

  const { checkUnicornDate } = useUnicornReducerDateSetter();
  const { checkScorecardDate } = useScorecardReducerDateSetter();
  const { checkUnicornStatus } = useUnicornReducerStatusSetter();
  checkUnicornStatus();
  checkUnicornDate();
  checkScorecardDate();

  const { loading: meSetupLoading, refetch: meApiRefetch } = useMeSetup();

  const { callFetchWorkspaceProducts = () => {}, loading_workspaceProducts = false } =
    useWorkspaceProducts();

  useEffect(() => {
    if (selectedWorkspace) {
      callFetchWorkspaceProducts(selectedWorkspace, {
        callBackResponseOnComplete: (e) => {
          dispatch({ type: WORKSPACE_PRODUCTS_DATA, payload: e });
        },
      });
    }
  }, [selectedWorkspace]);

  // const {
  //   // loading: timeZoneSetupLoading
  // } = useCallTimezone();

  /**
   * dispatch({ type: USER_SETTING_SEARCH_FILTER, payload: {} }); Crear Filter on Route Change
   */

  useEffect(() => {
    dispatch({ type: USER_SETTING_CLEAR_THINGS_ON_ROUTE_CHANGES });
    // dispatch({ type: USER_SETTING_SEARCH_FILTER, payload: {} });
    dispatch({ type: USER_SETTING_DEFAULT_FILTER, payload: {} });
  }, [location]);

  // const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const { havePlatformPermissionCheck } = usePermissionCheck();
  const { agency_id, userLoading } = useSelector((state) => state?.userReducer?.me) || {};

  if (!loggedIn && !loading) return <Redirect to="/" />;

  if (permission) {
    // if (typeof permission === "object") {
    //   const validPermission = permissions?.filter(
    //     (name) => permission?.filter((data) => name === data).length > 0
    //   );
    //   if (validPermission && validPermission?.length === 0) return <Redirect to="/" />;
    // } else {
    //   const validPermission = permissions?.filter((name) => name === permission);
    //   if (validPermission && validPermission?.length === 0) return <Redirect to="/" />;
    // }

    if (!havePlatformPermissionCheck(permission)) {
      return <Redirect to="/" />;
    }
  }

  return (
    <>
      <CallTimezone />
      {loading || meSetupLoading ? (
        <Loader fullScreen />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            loggedIn ? (
              !(agency_id || userLoading) ? (
                <Redirect to="/create-agency" />
              ) : (
                <DashboardRouteContext.Provider value={{ meApiRefetch }}>
                  <DashboardLayout>
                    <Suspense fallback={<div className="header-nav-loader"></div>}>
                      {userLoading && <Loader className="bg-white" fullScreen />}
                      <ChatGpt />
                      <Component {...props} />
                    </Suspense>
                  </DashboardLayout>
                </DashboardRouteContext.Provider>
              )
            ) : (
              <Redirect to="/" />
            )
          }
        />
      )}
    </>
  );
}
