import { useState } from "react";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import moment from "moment";
import alasql from "alasql";
import cx from "classnames";
import { useSelector } from "react-redux";
import {
  GET_REPORT_PER_AD_SET_ID,
  // GET_REVENUE_PER_AD_SET_ID,
  REPORT_AD_SET_BY_CAMPAIGNS,
  REVENUE_AD_SET_BY_CAMPAIGNS,
} from "shared/gql/gqlSchema/leadgenGql";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import LookTable from "components/LookTable";
import { GET_SAS_DAILY_PERFORMANCE_REPORT } from "shared/gql/gqlSchema/sasGql";
import { SPEND_INCREASE_PERCENT_VS } from "shared/enum/commonKeysandEnum";

export default function PerformanceReport(props) {
  const { adsetId = "", revAdSetId = "", timezone: timeZoneProps } = props;
  const dateFormat = "YYYY-MM-DD";

  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });
  const [spendReport, setSpendReport] = useState([]);
  const [revenueReport, setRevenueReport] = useState([]);

  const defaultDate = [
    moment().subtract(8, "d").format(dateFormat),
    moment().subtract(1, "d").format(dateFormat),
  ];

  const [rangeDates, setRangeDates] = useState([...defaultDate]);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const dateTimezoneApiVariables = {
    from: rangeDates[0],
    to: rangeDates[1],
    timezone: timeZone.value,
  };

  let reportSettings = {
    reportApi: GET_REPORT_PER_AD_SET_ID,
    reportApiKey: "reportPerAdSetId",
    revenueApi: GET_SAS_DAILY_PERFORMANCE_REPORT,
    revenueApiKey: "sasDailyPerformanceReport",
    reportVariables: {
      ...dateTimezoneApiVariables,
      adSetId: adsetId,
    },
    revnueVariables: {
      ...dateTimezoneApiVariables,
      assetId: revAdSetId,
      groupBy: "adsetId",
    },
  };

  const {
    loading: loading_GET_REPORT_PER_AD_SET_ID,
    error: error_GET_REPORT_PER_AD_SET_ID,
    refetch: refetch_GET_REPORT_PER_AD_SET_ID,
  } = useCustomQuery(reportSettings.reportApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[reportSettings.reportApiKey];
      if (element) {
        setSpendReport(element);
      }
    },
    variables: {
      ...reportSettings.reportVariables,
    },
  });

  const {
    loading: loading_GET_REVENUE_PER_AD_SET_ID,
    error: error_GET_REVENUE_PER_AD_SET_ID,
    refetch: refetch_GET_REVENUE_PER_AD_SET_ID,
  } = useCustomQuery(reportSettings.revenueApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[reportSettings.revenueApiKey];
      if (element) {
        setRevenueReport(element);
      }
    },
    variables: {
      ...reportSettings.revnueVariables,
    },
  });

  const loading = loading_GET_REPORT_PER_AD_SET_ID || loading_GET_REVENUE_PER_AD_SET_ID;
  const refetch = () => {
    refetch_GET_REPORT_PER_AD_SET_ID();
    refetch_GET_REVENUE_PER_AD_SET_ID();
  };

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const initialColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return <LookTableColumnRender title={value} isProfit={PROFIT} />;
      },
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Ad Set Id",
      dataIndex: "adsetId",
      key: "adsetId",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 190,
      minWidth: 190,
      searchType: "string",
    },

    {
      title: "Country",
      dataIndex: "countryCode",
      key: "countryCode",
      render: (value) => <LookTableColumnRender title={value} />,
      hidden: true,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.countryCode - b?.countryCode,
      searchType: "string",
    },

    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      render: (value) => <LookTableColumnRender title={value} />,
      hidden: true,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.device - b?.device,
      searchType: "string",
    },

    {
      title: "Revenue",
      dataIndex: "estimate_revenue",
      key: "estimate_revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.estimate_revenue - b?.estimate_revenue,
      searchType: "number",
    },

    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
      searchType: "number",
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value = 0, otherProps) => {
        // const profit = `$${value.replace("-", "")}`;
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      searchType: "number",
    },

    {
      title: "ROI",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROI - b?.ROI,
      searchType: "number",
    },

    {
      title: "Sessions",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
      searchType: "number",
    },

    {
      title: "Searches",
      dataIndex: "searches",
      key: "searches",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.searches - b?.searches,
      searchType: "number",
    },

    {
      title: "Leads (V)",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",
    },

    {
      title: "Leads (FB)",
      dataIndex: "leadsFB",
      key: "leadsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.leadsFB - b?.leadsFB,
      searchType: "number",
    },

    {
      title: "CPR (V)",
      dataIndex: "CPR_V",
      key: "CPR_V",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_V - b?.CPR_V,
      searchType: "number",
    },

    {
      title: "CPR (FB)",
      dataIndex: "CPR_FB",
      key: "CPR_FB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_FB - b?.CPR_FB,
      searchType: "number",
    },

    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },
    {
      title: "RPV",
      dataIndex: "RPV",
      key: "RPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPV - b?.RPV,
      searchType: "number",
    },
    {
      title: "RPL",
      dataIndex: "RPL",
      key: "RPL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPL - b?.RPL,
      searchType: "number",
    },

    {
      title: "AD CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
      searchType: "number",
    },

    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },

    {
      title: "V Visits",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",
    },
    {
      title: "Life Time Budget",
      dataIndex: "lifeTimeBudget",
      key: "lifeTimeBudget",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 140,
      minWidth: 140,
      sorter: (a, b) => a?.lifeTimeBudget - b?.lifeTimeBudget,
      searchType: "number",
    },
    {
      title: "Daily Budget",
      dataIndex: "dailyBudget",
      key: "dailyBudget",
      render: (value) => (
        <LookTableColumnRender
          title={
            value === null || value === undefined ? (
              <span className="table-tag mr-10">CBO</span>
            ) : (
              value
            )
          }
        />
      ),
      width: 105,
      minWidth: 105,
      sorter: (a, b) => a?.dailyBudget - b?.dailyBudget,
      fixed: "right",
      searchType: "number",
    },

    // ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    finalMerge = alasql("SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.date = b.date", [
      revenueReport,
      spendReport,
    ]);
    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        leadsFB = 0,
        estimate_revenue = 0,
        searches = 0,
        clicks = 0,
        country = {},
        category = {},
      } = element;

      const spendUpdate = (spendFB * SPEND_INCREASE_PERCENT_VS).toFixed(2);

      const PROFIT = parseFloat(estimate_revenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CPC = parseFloat(spendUpdate) / parseFloat(clicks);

      const CPR_V = parseFloat(spendUpdate) / parseFloat(clicks);
      const CPR_FB = parseFloat(spendUpdate) / parseFloat(leadsFB);
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const RPV = parseFloat(estimate_revenue) / parseFloat(clicksFB);
      // const CPL = parseFloat(spendUpdate) / parseFloat(clicks);
      const RPL = parseFloat(estimate_revenue) / parseFloat(clicks);
      const AD_CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(searches) / parseFloat(clicksFB)) * 100;
      const CR = (parseFloat(clicks) / parseFloat(clicksFB)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;

      finalMergeData.push({
        ...element,
        key: index,
        clicksFB,
        impressionsFB,
        leadsFB,
        // adsetId: ad?.adset?.adsetId,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        CPV: valueCheck(CPV),
        CPC: valueCheck(CPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        RPL: valueCheck(RPL),
        CPR_V: valueCheck(CPR_V),
        CPR_FB: valueCheck(CPR_FB),
        RPV: valueCheck(RPV),
        categoryName: category?.name || "",
        countryCode: country?.iso_code || "",
      });
    });
  }

  const finalFilterDataSearch = finalMergeData.filter(
    ({ name = "", adsetId = "", status = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";
      if (
        name?.toLowerCase()?.includes(searchValue) ||
        adsetId?.toLowerCase()?.includes(searchValue) ||
        status?.toLowerCase()?.includes(searchValue)
      ) {
        return true;
      }
      return false;
    }
  );

  const finalFilterData = finalFilterDataSearch?.sort(function (a, b) {
    let keyA = new Date(a.date);
    let keyB = new Date(b.date);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_estimate_revenue = 0,
    total_clicks = 0,
    total_searches = 0,
    total_sessions = 0,
    total_leadsFB = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    estimate_revenue = 0,
    clicks = 0,
    leadsFB = 0,
    searches = 0,
    sessions = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_estimate_revenue += parseFloat(estimate_revenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_leadsFB += parseFloat(leadsFB || 0);
    total_searches += parseFloat(searches || 0);
    total_sessions += parseFloat(sessions || 0);
  }

  const total_PROFIT = parseFloat(total_estimate_revenue) - parseFloat(total_spendFB);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spendFB)) * 100 || 0;
  const total_CPV = parseFloat(total_spendFB) / parseFloat(total_clicksFB);
  const total_CPC = parseFloat(total_spendFB) / parseFloat(total_clicks);
  const total_AD_CTR = (parseFloat(total_clicksFB) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_searches) / parseFloat(total_clicksFB)) * 100;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;
  const total_CPR_V = parseFloat(total_spendFB) / parseFloat(total_clicks);
  const total_CPR_FB = parseFloat(total_spendFB) / parseFloat(total_leadsFB);
  const total_RPV = parseFloat(total_estimate_revenue) / parseFloat(total_clicksFB);
  // const total_CPL = parseFloat(total_spendFB) / parseFloat(total_clicks);
  const total_RPL = parseFloat(total_estimate_revenue) / parseFloat(total_clicks);
  const total_CR = (parseFloat(total_clicks) / parseFloat(total_clicksFB)) * 100;

  const summaryData = {
    spendFB: valueCheck(total_spendFB, true),
    clicksFB: valueCheck(total_clicksFB, true),
    impressionsFB: valueCheck(total_impressionsFB),
    estimate_revenue: valueCheck(total_estimate_revenue, true),
    clicks: valueCheck(total_clicks, true),
    leadsFB: valueCheck(total_leadsFB),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    CPV: valueCheck(total_CPV, true),
    CPC: valueCheck(total_CPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    searches: valueCheck(total_searches),
    sessions: valueCheck(total_sessions),
    CPR_V: valueCheck(total_CPR_V, true),
    CPR_FB: valueCheck(total_CPR_FB, true),
    RPV: valueCheck(total_RPV, true),
    // CPL: valueCheck(total_CPL, true),
    RPL: valueCheck(total_RPL, true),
  };

  const tableMainProps = {
    exportCSV: true,
    persistKey,
    isLineChart: true,
    isRowSelection: false,
    isDateRange: true,
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },

    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
