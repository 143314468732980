import { useAwsAuth } from "shared/AwsAuth";

function Logout(props) {
  const { userLogOut } = useAwsAuth();

  const handleLogout = () => {
    userLogOut();
  };

  return (
    <button className="logout_btn" onClick={handleLogout}>
      Log Out
    </button>
  );
}

export default Logout;
