export const BUCKET_STATUS_LAUNCHED = "LAUNCHED";
export const BUCKET_STATUS_PENDING = "PENDING";
export const BUCKET_STATUS_READY_TO_LAUNCH = "READY_TO_LAUNCH";

export const BUCKET_STATUS_ENUM = [
  { name: "Launched", value: BUCKET_STATUS_LAUNCHED },
  { name: "Pending", value: BUCKET_STATUS_PENDING },
  { name: "Ready to launch", value: BUCKET_STATUS_READY_TO_LAUNCH },
];

export const BUDGET_TYPE_DAILY = "DAILY";
export const BUDGET_TYPE_LIFE_TIME = "LIFE_TIME";

export const BUDGET_TYPE_ENUM = [
  { name: "Daily", value: BUDGET_TYPE_DAILY },
  { name: "Life Time", value: BUDGET_TYPE_LIFE_TIME },
];

export const C_TYPE_CBO = "CBO"; // Campaign Level Budget
export const C_TYPE_ABO = "ABO"; // AdSet Level Budget
export const INHERETED_FROM_SOURCE = "INHERETED_FROM_SOURCE";

export const C_TYPE_ENUM = [
  { name: C_TYPE_CBO, value: C_TYPE_CBO },
  { name: C_TYPE_ABO, value: C_TYPE_ABO },
];

export const BID_STRATEGY_COST_CAP = "COST_CAP";
export const BID_STRATEGY_LOWEST_COST_WITHOUT_CAP = "LOWEST_COST_WITHOUT_CAP";
export const BID_STRATEGY_LOWEST_COST_WITH_BID_CAP = "LOWEST_COST_WITH_BID_CAP";

export const BID_STRATEGY_ENUM = [
  { name: "Cost Cap", value: BID_STRATEGY_COST_CAP },
  { name: "Lowest Cost Without Cap", value: BUDGET_TYPE_LIFE_TIME },
  { name: "Lowest Cost With Bid Cap", value: BID_STRATEGY_LOWEST_COST_WITH_BID_CAP },
];

export const OPTIMIZATION_LAUNCH_GOAL_ENUM = [
  { name: "Ad recall lift", value: "AD_RECALL_LIFT" },
  { name: "App installs", value: "APP_INSTALLS" },
  { name: "App installs and offsite conversions", value: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS" },
  { name: "Conversations", value: "CONVERSATIONS" },
  { name: "Derived events", value: "DERIVED_EVENTS" },
  { name: "Engaged users", value: "ENGAGED_USERS" },
  { name: "Event responses", value: "EVENT_RESPONSES" },
  { name: "Impressions", value: "IMPRESSIONS" },
  { name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
  { name: "Lead generation", value: "LEAD_GENERATION" },
  { name: "Link clicks", value: "LINK_CLICKS" },
  { name: "None", value: "NONE" },
  { name: "Offsite conversions", value: "OFFSITE_CONVERSIONS" },
  { name: "Page likes", value: "PAGE_LIKES" },
  { name: "Post engagement", value: "POST_ENGAGEMENT" },
  { name: "Quality call", value: "QUALITY_CALL" },
  { name: "Quality lead", value: "QUALITY_LEAD" },
  { name: "Reach", value: "REACH" },
  { name: "Thruplay", value: "THRUPLAY" },
  { name: "Value", value: "VALUE" },
  { name: "Visit instagram profile", value: "VISIT_INSTAGRAM_PROFILE" },
  { name: "In App Value", value: "IN_APP_VALUE" },
  { name: "Messaging Appointment Conversion", value: "MESSAGING_APPOINTMENT_CONVERSION" },
  { name: "Messaging Purchase Conversion", value: "MESSAGING_PURCHASE_CONVERSION" },
  { name: "Subscribers", value: "SUBSCRIBERS" },
];
export const CONTENT_TYPE_ARTICLE = "ARTICLE";
export const CONTENT_TYPE_CATEGORY = "CATEGORY";
export const CONTENT_TYPE_KEYWORD = "KEYWORD";
export const CONTENT_TYPE_CAMPAIGN = "CAMPAIGN";
export const CONTENT_TYPE_GROUPS = "GROUP";

export const CONTENT_TYPE_ENUM = [
  { name: "Artical", value: CONTENT_TYPE_ARTICLE },
  { name: "Category", value: CONTENT_TYPE_CATEGORY },
  { name: "Keyword", value: CONTENT_TYPE_KEYWORD },
];

export const SERVICE_TYPE_LEAD_GEN = "LEAD_GEN";
export const SERVICE_TYPE_SAS = "SAS";
export const SERVICE_TYPE_VS = "VS";
export const SERVICE_TYPE_LEAD_GENERATION = "LeadGen";
export const SERVICE_TYPE_SEARCH_ARBITAGE = "SearchArbitrage";
// update while pushing code to sandbox to Viral Sparks
export const SERVICE_TYPE_VIRAL_SPARKS = "ViralSparks";

export const SERVICE_TYPE_ENUM = [
  { name: "LEAD GEN", value: SERVICE_TYPE_LEAD_GEN },
  { name: "SAS", value: SERVICE_TYPE_SAS },
  { name: "VS", value: SERVICE_TYPE_VS },
];
export const ENROLL_STATUS_ENUM = [
  { name: "OPT_IN", value: "OPT_IN" },
  { name: "OPT_OUT", value: "OPT_OUT" },
];

export const LAUNCH_OBJECTIVE_ENUM = [
  { name: "App installs", value: "APP_INSTALLS" },
  { name: "Brand awareness", value: "BRAND_AWARENESS" },
  { name: "Event responses", value: "EVENT_RESPONSES" },
  { name: "Lead generation", value: "LEAD_GENERATION" },
  { name: "Link clicks", value: "LINK_CLICKS" },
  { name: "Local awareness", value: "LOCAL_AWARENESS" },
  { name: "Messages", value: "MESSAGES" },
  { name: "Offer claims", value: "OFFER_CLAIMS" },
  { name: "Page likes", value: "PAGE_LIKES" },
  { name: "Post engagement", value: "POST_ENGAGEMENT" },
  { name: "Product catalog sales", value: "PRODUCT_CATALOG_SALES" },
  { name: "Reach", value: "REACH" },
  { name: "Store visits", value: "STORE_VISITS" },
  { name: "Video views", value: "VIDEO_VIEWS" },
  { name: "Outcome App Promotion", value: "OUTCOME_APP_PROMOTION" },
  { name: "Outcome Awareness", value: "OUTCOME_AWARENESS" },
  { name: "Outcome Engagement", value: "OUTCOME_ENGAGEMENT" },
  { name: "Outcome Leads", value: "OUTCOME_LEADS" },
  { name: "Outcome Sales", value: "OUTCOME_SALES" },
  { name: "Outcome Traffic", value: "OUTCOME_TRAFFIC" },
  { name: "website Conversions", value: "WEBSITE_CONVERSIONS" },
  { name: "Conversions", value: "CONVERSIONS" },
];

export const LAUNCH_CALL_TO_ACTION_ENUM = [
  { name: "Add to cart", value: "ADD_TO_CART" },
  { name: "Apply now", value: "APPLY_NOW" },
  { name: "Contact us", value: "CONTACT_US" },
  { name: "Follow page", value: "FOLLOW_PAGE" },
  { name: "Follow user", value: "FOLLOW_USER" },
  { name: "Get offer", value: "GET_OFFER" },
  { name: "Learn More", value: "LEARN_MORE" },
  { name: "Sign up", value: "SIGN_UP" },
  { name: "No Button", value: "NO_BUTTON" },
  { name: "Watch More", value: "WATCH_MORE" },
];

export const BID_STRATEGY_LIST = [
  { name: "Highest Volume", value: "LOWEST_COST_WITHOUT_CAP" },
  { name: " Cost Cap", value: "COST_CAP" },
  { name: "Lowest Cost With Bid Cap", value: "LOWEST_COST_WITH_BID_CAP" },
];
export const PLATFORM_TYPE_FACEBOOK = "facebook";
export const PLATFORM_TYPE_TIKTOK = "tiktok";
export const PLATFORM_TYPE_INSTAGRAM = "instagram";
export const PLATFORM_TYPE_MESSENGER = "messenger";
export const PLATFORM_TYPE_ALL = "All Platforms";
export const EUROPEAN_COUNTRIES = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "GB",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
  "UK",
];

export const LAUNCH_CAMPAIGN_ACTIVE = "ACTIVE";
export const LAUNCH_CAMPAIGN_PAUSED = "PAUSED";

export const TIKTOK_AVATAR_URL =
  "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/Asasasasas/image/WInIN_1703244550367.jpeg";

export const CONVERSION_ACTION_TYPE_ENUM = [
  { name: "Webpage", value: "WEBPAGE" },
  { name: "Click to Call", value: "CLICK_TO_CALL" },
];

// GOOGLE LAUNCH KEYS
// GL => Google Launch

export const MAXIMIZE_CONVERSIONS = "MAXIMIZE_CONVERSIONS";
export const TARGET_CPA = "TARGET_CPA";
export const TARGET_ROAS = "TARGET_ROAS";

export const BIDDING_STRATEGY_ENUM = [
  { label: "Conversions", name: "Maximize Conversions", value: MAXIMIZE_CONVERSIONS },
  { label: "CPA", name: "Maximize CPA", value: TARGET_CPA },
  { label: "ROAS", name: "Target ROAS", value: TARGET_ROAS },
];

export const GL_STATUS_PAUSED = "PAUSED";

export const GL_STATUS_ENUM = [
  { name: "Enabled", value: "ENABLED" },
  { name: "Paused", value: GL_STATUS_PAUSED },
  { name: "Removed", value: "REMOVED" },
  { name: "Unknown", value: "UNKNOWN" },
  { name: "Unspecified", value: "UNSPECIFIED" },
];
