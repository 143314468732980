import { useEffect, useState } from "react";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import {
  LAUNCH_PLATFORM_DATA,
  LAUNCH_REQUESTS,
  LAUNCH_TIKTOK_REQUESTS,
} from "shared/gql/gqlSchema/launchGql";
import {
  LAUNCH_PLATFORM_SELECTION,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import { useSelector } from "react-redux";
import LookTable from "components/LookTable";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import DebugPre from "components/DebugPre";
import LookDrawer from "components/LookDrawer";
import Campaigns from "./DetailView/Campaigns";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import { useDispatch } from "react-redux";
import useColumn from "./useColumn";

export default function LaunchListing(props) {
  const { serviceType, productId } = props;
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const [launchListData, setLaunchListData] = useState([]);
  const userSettings = useSelector((state) => state?.userSettings);
  const [ads, setAds] = useState([]);
  const [adsets, setAdsets] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [rowPropsData, setRowProps] = useState({});
  const [activeTab, setActiveTab] = useState("campaigns");
  const dispatch = useDispatch();
  const templatePlatform =
    useSelector((state) => state?.launchReducer?.templatePlatform) || "facebook";
  const [templatePlatforms, setTemplatePlatforms] = useState(templatePlatform);

  const { dateRangeTimezone } = userSettings || {};

  const [platformFetch, { data: platformsData, loading: PLATFORM_LOADING }] = useCustomLazyQuery(
    LAUNCH_PLATFORM_DATA,
    {
      onCompleted: (e) => {
        // setTemplatePlatforms(
        //   e?.templatePlatforms?.filter((item) => item.name === "facebook")[0]?.id
        // );
      },
    }
  );

  useEffect(() => {
    platformFetch();
  }, []);
  const isTiktokPlatformLaunch = templatePlatforms === "tiktok";

  const [fetchFbLaunch, { data, loading, refetch }] = useCustomLazyQuery(LAUNCH_REQUESTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.launchRequests;
      if (element) {
        setLaunchListData(element?.map((data, index) => ({ ...data, key: index })));
      }
    },

    variables: {
      from_date: dateRangeTimezone?.rangeDates[0],
      to_date: dateRangeTimezone?.rangeDates[1],
      product_id: props?.productId,
    },
  });
  const [fetchTiktokLaunch, { data: tiktokData, loading: tiktokLoading, refetch: tiktokRefetch }] =
    useCustomLazyQuery(LAUNCH_TIKTOK_REQUESTS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.tiktokLaunchRequests;
        if (element) {
          setLaunchListData(element?.map((data, index) => ({ ...data, key: index })));
        }
      },

      variables: {
        from_date: dateRangeTimezone?.rangeDates[0],
        to_date: dateRangeTimezone?.rangeDates[1],
      },
    });
  useEffect(() => {
    if (!PLATFORM_LOADING) {
      if (!isTiktokPlatformLaunch) {
        fetchFbLaunch();
      } else {
        fetchTiktokLaunch();
      }
    }
  }, [templatePlatforms]);

  const finalFilterData = launchListData.filter(({ name = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (name?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  const showDrawer = (rowProps = {}) => {
    setRowProps(rowProps);
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setRowProps({});
    setVisibleDrawer(false);
  };
  const { columns } = useColumn({
    isTiktokPlatformLaunch,
    serviceType,
    showDrawer,
    templatePlatforms,
    productId,
  });

  const tableProps = { initialColumns: columns, recordData: finalFilterData };
  const debugContent = [{ isRow: true, contentObjects: [{ finalFilterData }] }];

  const tabContent = [
    {
      id: "campaigns",
      noSpace: true,
      hoverTitle: "Campaigns",
      icon: () => <ActionIconRender iconType="notification" />,
      component: (
        <Campaigns
          {...rowPropsData}
          campaigns={campaigns}
          setCampaigns={setCampaigns}
          isTiktokPlatformLaunch={isTiktokPlatformLaunch}
        />
      ),
    },
  ];

  const selectedTab = tabContent.filter(({ id }) => id === activeTab)[0];

  const drawerProps = {
    title: `Launch : ${rowPropsData?.name} - ${
      activeTab === "campaigns"
        ? campaigns?.length
        : activeTab === "adsets"
        ? adsets?.length
        : ads?.length
    } entries in ${selectedTab?.hoverTitle}`,
    icon: () => <ActionIconRender className="mr-10" iconType="notification" />,
    size: 900,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
  };

  return (
    <>
      <DashboardHeader title="Launch" />
      <LookDrawer {...drawerProps}>
        {visibleDrawer && (
          <LookDrawerMainSideBar
            value={activeTab}
            onChange={setActiveTab}
            tabContent={tabContent}
          />
        )}
      </LookDrawer>
      <LookTable
        rightOptions={
          <div style={{ width: "150px" }}>
            <SelectInput
              name="platforms"
              value={templatePlatforms}
              className="mb-0"
              onChange={(e) => {
                setTemplatePlatforms(e);
                dispatch({
                  type: LAUNCH_PLATFORM_SELECTION,
                  payload: e,
                });
              }}
              options={platformsData?.templatePlatforms?.map(({ name, id }) => ({
                name,
                value: name,
              }))}
            />
          </div>
        }
        isSrNo
        isDateRange
        defaultPagination
        refetch={isTiktokPlatformLaunch ? tiktokRefetch : refetch}
        loading={loading || tiktokLoading}
        tableProps={tableProps}
        uniqueKey={isTiktokPlatformLaunch ? "launched-listing-tiktok" : "launched-listing-fb"}
      />
      <DebugPre content={debugContent} />
    </>
  );
}
