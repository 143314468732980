import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { GQL_API_RESPONSE_STATUS_SUCCESS } from "shared/SharedKeys";
import {
  SAS_CREATE_EXTERNAL_CHANNEL,
  SAS_UPDATE_EXTERNAL_CHANNEL,
} from "shared/gql/gqlSchema/sasGql";
import { useSelector } from "react-redux";
import LookModal from "components/LookModal";

export default function AddForm(props) {
  const {
    onClose = () => {},
    dataSource = [],
    setDataSource = () => {},
    refetch = () => {},
  } = props;
  const resetFormRef = useRef(() => {});
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);
  const [formData, setFormData] = useState({});

  const {
    sasPlatforms = [],
    sasCategories = [],
    sasVendors_dropdown = [],
  } = useSelector((state) => state?.userSettings?.userSettingData) || {};

  const [createChannel, { loading: channel_loading }] = useCustomMutation(
    SAS_CREATE_EXTERNAL_CHANNEL,
    {
      onCompleted(e) {
        const element = e?.sasCreateExternalChannel;
        if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
          if (element?.isExist) {
            setVisibleConfirmModal(true);
          } else {
            setDataSource([{ ...element?.data, key: element?.data?.id }, ...dataSource]);
          }
          onClose();
          resetFormRef.current();
          openNotification({ type: "success", message: "Record Added Success" });
        } else {
          openNotification({
            type: "error",
            message: element?.message,
            key: "ch-add-error-response",
          });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  const [updateExisting, { loading: update_loading }] = useCustomMutation(
    SAS_UPDATE_EXTERNAL_CHANNEL,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.sasUpdateExternalChannel;
        if (element.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
          if (element) {
            refetch();
            onClose();
            resetFormRef.current();
            setVisibleConfirmModal(false);
            openNotification({
              type: "success",
              message: "Record Added Success",
              key: "ex-ch-update-success",
            });
          }
        } else {
          setVisibleConfirmModal(false);
          openNotification({
            type: "error",
            message: element?.message,
            key: "ex-ch-update-error-response",
          });
        }
      },
    }
  );

  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    setFormData(values);
    const { name, isRestricted, platformId, categoryId, channelId, vendorId, styleId } = values;

    const payload = {
      channel: channelId,
      isRestricted,
      name,
      platformId,
      categoryId,
      vendorId,
      styleId,
    };
    createChannel({ variables: { ...payload } });
  }

  const initialValues = {
    name: "",
    channelId: "",
    isRestricted: false,
    categoryId: null,
    platformId: null,
    styleId: "",
    vendorId: null,
  };
  const validationSchema = yup.object({
    name: yup.string().trim().required().label("Name"),
    channelId: yup.string().required().label("Channel Id"),
    categoryId: yup.string().required().label("Category Id"),
    platformId: yup.string().required().label("Platform Id"),
    styleId: yup.string().required().label("Style Id"),
    vendorId: yup.string().required().label("Vendor Id"),
  });

  const submitConfirmModal = () => {
    const { name, isRestricted, platformId, categoryId, channelId, vendorId, styleId } = formData;

    updateExisting({
      variables: {
        channel: channelId,
        isRestricted,
        name,
        platformId,
        categoryId,
        vendorId,
        styleId,
      },
    });
  };

  const confirmModalProps = {
    visible: visibleConfirmModal,
    title: "Confirm Update",
    onCancel: () => setVisibleConfirmModal(false),
    okokText: "Confirm",
    loader: update_loading,
    onSubmit: submitConfirmModal,
  };

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    channel_loading,
    update_loading,
    sasPlatforms,
    sasCategories,
    sasVendors_dropdown,
  };

  return (
    <>
      <LookModal {...confirmModalProps}>
        <h4> Category already exist against this category, Do you want to update exitsing one? </h4>
      </LookModal>
      <MainFrom {...formProps} />
    </>
  );
}
