import { Col, Row } from "antd";
import LookButton from "components/LookButton";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import UpdateCategory from "pages/dashboardPages/SAS/Collections/Categories/Category/UpdateCategory";
import UpdateKeyword from "pages/dashboardPages/SAS/Collections/Keywords/Keyword/UpdateKeyword";
import { useState } from "react";
import { SERVICE_TYPE_SAS } from "shared/enum/launchKeysAndEnum";
import {
  GQL_CL_ADD_GROUP,
  GQL_CL_CREATIVE_GROUP,
  GQL_CL_DELETE_GROUPS,
} from "shared/gql/gqlSchema/mediaGql";
import { PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import { openNotification } from "utils";
import CreativesDrawer from "..";

export const CreativeSelectionKey_Image = "image_id";
export const CreativeSelectionKey_Description = "description_id";
export const CreativeSelectionKey_Thumbnail = "thumbnail_id";
export const CreativeSelectionKey_Title = "title_id";
export const CreativeSelectionKey_Video = "video_id";
export const CreativeSelectionKey_Body = "body_id";

export default function AdGroup(props) {
  const { serviceType, isKeyword } = props;
  const [visibleCreativeDrawer, setVisibleCreativeDrawer] = useState(false);

  const showCreativeDrawer = (rowProps = {}) => {
    setVisibleCreativeDrawer(true);
  };

  const onCloseCreativeDrawer = () => {
    setVisibleCreativeDrawer(false);
  };

  const creativeDrawerProps = {
    visible: visibleCreativeDrawer,
    onClose: onCloseCreativeDrawer,
    size: 1200,
  };

  const [adGroupDataSource, setAdGroupDataSource] = useState([]);

  const { loading, refetch } = useCustomQuery(GQL_CL_CREATIVE_GROUP, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.clCreativeGroups?.data;
      if (element) {
        setAdGroupDataSource(element);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
    variables: {
      categoryId: props?.id,
      productId: serviceType === "VS" ? PRODUCT_ID_VS : PRODUCT_ID_SAS,
    },
  });
  console.log("adgroup", adGroupDataSource);
  const [clAddGroups, { loading: loading_clAddGroups }] = useCustomMutation(GQL_CL_ADD_GROUP, {
    onCompleted: (e) => {
      const element = e?.clAddGroups;
      if (element) {
        setAdGroupDataSource([element, ...adGroupDataSource]);
        openNotification({ type: "success", message: "Record added" });
        onCloseCreativeDrawer();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [callDeleteGroups, { loading: loading_DeleteGroups }] = useCustomMutation(
    GQL_CL_DELETE_GROUPS,
    {
      onCompleted: (e) => {
        const element = e?.clDeleteGroups;
        if (element) {
          refetch();
          onCloseDeleteModal();
          openNotification({ type: "success", message: "Record deleted" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    callDeleteGroups({ variables: { groupIds: [deleteModalPropsData?.id] } });
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  /**
   * selectedCreatives state is for Add Group
   */

  const [selectedCreatives, setSelectedCreatives] = useState({
    // description_id: "",
    // image_id: "",
    // thumbnail_id: "",
    // title_id: "",
    // video_id: "",
    // body_id: "",
  });

  const [selectedData, setSelectedData] = useState({
    body: {},
    description: {},
    image: {},
    video: {},
    title: {},
  });

  /**
   *
   * @param {*} key
   * @param {*} id
   */

  function handleSelectedCreatives(key, id) {
    if (key === "image_id") {
      selectedCreatives?.video_id
        ? setSelectedCreatives({ ...selectedCreatives, [key]: id, video_id: "" })
        : setSelectedCreatives({ ...selectedCreatives, [key]: id });
    } else if (key === "video_id") {
      selectedCreatives?.image_id
        ? setSelectedCreatives({ ...selectedCreatives, [key]: id, image_id: "" })
        : setSelectedCreatives({ ...selectedCreatives, [key]: id });
    } else setSelectedCreatives({ ...selectedCreatives, [key]: id });
  }
  const { image_id, video_id, description_id, body_id, title_id } = selectedCreatives;
  function submitAdGroupData() {
    let finalData = {};
    if (image_id === "" && video_id) {
      finalData = { video_id, description_id, body_id, title_id };
    } else if (video_id === "" && image_id) {
      finalData = { image_id, description_id, body_id, title_id };
    } else {
      finalData = { ...selectedCreatives };
    }
    console.log("fcvgh", finalData);
    clAddGroups({
      variables: {
        inputs: {
          ...finalData,
          category_id: props?.id,
          product_id: serviceType === "VS" ? PRODUCT_ID_VS : PRODUCT_ID_SAS,
        },
      },
    });
  }

  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_DeleteGroups,
  };
  const creativeProps = {
    selectedCreatives: selectedCreatives,
    handleSelectedCreatives: handleSelectedCreatives,
    setSelectedData: setSelectedData,
    selectedData: selectedData,
    adGroupDataSource: adGroupDataSource,
    onOpenDeleteModal: onOpenDeleteModal,
  };

  return (
    <>
      <LookTableRecordDeleteModal {...deleteModalProps} />
      {serviceType === SERVICE_TYPE_SAS ? (
        isKeyword ? (
          <UpdateKeyword
            {...creativeProps}
            {...props}
            {...creativeDrawerProps}
            serviceType={serviceType}
            groupSelection
            submitView={
              <>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <LookButton
                      onClick={onCloseCreativeDrawer}
                      block
                      className="submit-btn"
                      size="large"
                    >
                      Cancel
                    </LookButton>
                  </Col>
                  <Col span={12}>
                    <LookButton
                      disabled={
                        !loading_clAddGroups &&
                        selectedCreatives?.body_id &&
                        selectedCreatives?.title_id
                          ? false
                          : true
                      }
                      onClick={submitAdGroupData}
                      block
                      type="primary"
                      className="submit-btn"
                      size="large"
                    >
                      Submit
                    </LookButton>
                  </Col>
                </Row>
              </>
            }
          />
        ) : (
          <UpdateCategory
            {...creativeProps}
            {...props}
            {...creativeDrawerProps}
            serviceType={serviceType}
            groupSelection
            submitView={
              <>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <LookButton
                      onClick={onCloseCreativeDrawer}
                      block
                      className="submit-btn"
                      size="large"
                    >
                      Cancel
                    </LookButton>
                  </Col>
                  <Col span={12}>
                    <LookButton
                      disabled={
                        !loading_clAddGroups &&
                        selectedCreatives?.body_id &&
                        selectedCreatives?.title_id
                          ? false
                          : true
                      }
                      onClick={submitAdGroupData}
                      block
                      type="primary"
                      className="submit-btn"
                      size="large"
                    >
                      Submit
                    </LookButton>
                  </Col>
                </Row>
              </>
            }
          />
        )
      ) : (
        <CreativesDrawer
          {...creativeProps}
          {...props}
          {...creativeDrawerProps}
          groupSelection
          serviceType={serviceType}
          submitView={
            <>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <LookButton
                    onClick={onCloseCreativeDrawer}
                    block
                    className="submit-btn"
                    size="large"
                  >
                    Cancel
                  </LookButton>
                </Col>
                <Col span={12}>
                  <LookButton
                    disabled={
                      !loading_clAddGroups &&
                      selectedCreatives?.body_id &&
                      selectedCreatives?.title_id
                        ? false
                        : true
                    }
                    onClick={submitAdGroupData}
                    block
                    type="primary"
                    className="submit-btn"
                    size="large"
                  >
                    Submit
                  </LookButton>
                </Col>
              </Row>
            </>
          }
        />
      )}
      <Row gutter={[15, 15]} style={{ height: "auto" }}>
        <Col xs={12} sm={12} md={8} xl={6}>
          <div className="h-100">
            <LookButton
              className="ad-group-add-btn"
              iconType="plus-circle"
              onClick={showCreativeDrawer}
            />
          </div>
        </Col>
        {adGroupDataSource?.map((data) => (
          <Col xs={12} sm={12} md={8} xl={6}>
            <CardCreativeAdGroup
              {...data}
              deleteButton={
                <LookButton
                  className="h-initial w-initial"
                  type="link"
                  danger
                  tooltipTitle="Delete Group"
                  iconType="delete"
                  onClick={() => onOpenDeleteModal(data)}
                />
              }
            />
          </Col>
        ))}
      </Row>
      {/* <DebugPre content={debugContent} /> */}
    </>
  );
}
