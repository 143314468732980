import React, { useEffect, useState } from "react";

// react-pintura
import { PinturaEditor } from "@pqina/react-pintura";

// pintura
import "@pqina/pintura/pintura.css";

import {
  // plugins
  setPlugins,
  plugin_annotate,
  plugin_crop,
  plugin_decorate,
  plugin_filter,
  plugin_finetune,
  plugin_frame,
  plugin_redact,
  plugin_resize,
  plugin_fill,
  plugin_sticker,
  processDefaultImage,
  getEditorDefaults,
} from "@pqina/pintura";

import { openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";
import useUploadFiles from "../CreativeUpload/useUploadFiles";
import Loader from "components/loader";
import { GET_BLOB_FOR_CREATIVES } from "shared/gql/gqlSchema/umsGql";
import { MEDIA_ADD_CREATIVE_IMAGE } from "shared/gql/gqlSchema/creativeDashboardGql";
// import { setLicenseKey } from "@pqina/pintura";

// // Set the license key before using any other Pintura functions or components
// setLicenseKey(process.env.REACT_APP_PQINA_NPM_KEY);

setPlugins(
  plugin_annotate,
  plugin_crop,
  plugin_decorate,
  plugin_filter,
  plugin_finetune,
  plugin_frame,
  plugin_redact,
  plugin_resize,
  plugin_fill,
  plugin_sticker
);

const editorDefaults = getEditorDefaults({
  imageWriter: {
    // apply redaction to source image
    preprocessImageSource: async (src, options, onprogress, state) => {
      const { dest } = await processDefaultImage(src, {
        imageRedaction: [...state.redaction],
      });
      return dest;
    },

    // remove redaction from state
    preprocessImageState: (imageState) => {
      imageState.redaction = [];
      return imageState;
    },
  },
});

export default function EditImage({
  src,
  creativeType,
  categoryName,
  productId,
  categoryId,
  verticalId,
  subCategory,
  closeModal,
  refetch = () => {},
}) {
  // inline
  const [loading, setLoading] = useState(true);
  const [saving, setImageSaving] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const variables = {
    productId,
    categoryId,
    categoryName,
    // ...subCategory,
    // ...(verticalId && { verticalId }),
  };

  const [clAddImages, { loading: loading_Add }] = useCustomMutation(MEDIA_ADD_CREATIVE_IMAGE, {
    variables: variables,
    onCompleted: (e) => {
      const element = e?.ctAddCreativeImages;
      if (element) {
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: "Record added",
            key: "creative-upload-success",
          });
          setLoading(false);
          closeModal && closeModal();
          refetch && refetch();
        } else {
          openNotification({
            type: "error",
            message: element?.message,
            key: "creative-upload-error",
          });
        }
      }
      setImageSaving(false);
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });

  const mimeType = "image/png";

  function base64ToImageUrl(base64String, mimeType) {
    return `data:${mimeType};base64,${base64String}`;
  }

  const [getBlob, { loading: loading_blob }] = useCustomMutation(GET_BLOB_FOR_CREATIVES, {
    variables: variables,
    onCompleted: (e) => {
      const element = e?.getBlob;
      if (element) {
        const imageUrl = base64ToImageUrl(element[0].blob, mimeType);
        setSelectedImages(imageUrl);
        setLoading(false);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });
  const getImageResponse = (imagesData) => {
    clAddImages({
      variables: { images: imagesData?.map((data) => ({ imageUrl: data?.public_url, tags: [] })) },
    });
  };

  const getImageBlob = async () => {
    try {
      const response = await fetch(src);
      if (response.ok) {
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result;
          const base64Data = base64Image.split(",")[1]; // Extract base64 data

          // Use the base64Data to render image
          const imageUrl = base64ToImageUrl(base64Data, mimeType);
          setSelectedImages(imageUrl);
          setLoading(false);
        };
        reader.readAsDataURL(blob);
      }
    } catch (error) {
      getBlob({ variables: { urls: src } });
    }
  };

  const { handleBlobUpload, uploadLoading } = useUploadFiles({
    name: categoryName,
    // getImageResponse,
    creativeType,
    loading: loading_Add,
  });

  const s3FileUploadThrougBlob = (blobData) => {
    return new Promise((resolve, reject) => {
      handleBlobUpload(
        blobData?.map(({ blob }) => blob),
        blobData?.map(({ url }, i) => url)
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const uploadModifiedImage = async (image) => {
    try {
      setImageSaving(true);
      fetch(image)
        .then((response) => response.blob())
        .then(async (blob) => {
          // Use the blob here as needed
          let finalDataFromBlob = [];
          const timestamp = Date.now(); // Get the current timestamp
          const urlName = `modified-image-${timestamp}.png`; // Unique URL name with timestamp
          const resolvedBLOB = await s3FileUploadThrougBlob([{ blob: blob, url: urlName }]);
          finalDataFromBlob = resolvedBLOB;
          getImageResponse(finalDataFromBlob);
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      getImageBlob();
    })();
  }, []);

  return (
    <div>
      <div style={{ height: "70vh" }}>
        {loading ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
            <Loader />
          </div>
        ) : (
          <PinturaEditor
            {...editorDefaults}
            src={selectedImages}
            onProcess={({ dest }) => uploadModifiedImage(dest && URL?.createObjectURL(dest))}
            stickers={["🎉", "😄", "👍", "👎", "🍕"]}
            loaderror={(error) => console.error({ error })}
            processerror={(error) => console.error({ error })}
            disabled={saving}
          />
        )}
      </div>
    </div>
  );
}
