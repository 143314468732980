import { useState, useRef, useEffect } from "react";
import { Button, Switch, Tooltip } from "antd";
import { useLookDragableOrderList } from ".";
import cx from "classnames";
import LookTextInput from "components/forms/LookTextInput";
import ActionIconRender from "components/ActionIconRender";
import { SortableList } from "components/SortableList";

function RenderInput(props) {
  const { value, onChange = () => {}, extitEditMode = () => {} } = props;

  const inpRef = useRef(null);

  const [field, setField] = useState(value);
  function handleField(e) {
    setField(e.target.value);
  }
  useEffect(() => {
    inpRef.current.focus();
  }, []);
  function handleMainData() {
    if (field.trim()) {
      setField("");
      onChange(field.trim());
      extitEditMode();
    }
  }

  function handleFieldSubmit(e) {
    if (e.key === "Enter") {
      handleMainData();
    }
  }

  return (
    <div className="d-flex">
      <LookTextInput
        ref={inpRef}
        className="flex-grow-1 mb-0"
        inputClassName="border-0 px-6"
        value={field}
        onChange={handleField}
        onKeyDown={handleFieldSubmit}
      />
      <Tooltip title="Set">
        <Button type="link" onClick={handleMainData} icon={<ActionIconRender iconType="check" />} />
      </Tooltip>
      <Tooltip title="Cancel">
        <Button
          className="h-auto"
          type="link"
          danger
          onClick={extitEditMode}
          icon={<ActionIconRender iconType="close" />}
        />
      </Tooltip>
    </div>
  );
}

export const SortableItem = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const {
    mainIndex,
    itemData,
    menuSet = () => {},
    remove = () => {},
    editItem = () => setIsEditMode(true),
  } = props;
  const { theme, switchAction, isEditable, isRemove, setMainData } = useLookDragableOrderList();

  function setFieldItem(value, index) {
    if (typeof itemData === "string") {
      setMainData((pre) => {
        const temp = JSON.parse(JSON.stringify(pre));
        temp[index] = value;
        return temp;
      });
    }
  }

  let name = "";
  if (typeof itemData === "string") name = itemData;
  if (typeof itemData === "object") name = itemData.name;

  console.log("itemData", itemData);

  return (
    <div className={cx("LookDragableOrderItem", `theme-${theme}`)}>
      <div className="item-contnet">
        <SortableList.DragHandle className={cx("main-content", { isEditMode })}>
          {isEditMode ? (
            <RenderInput
              value={name}
              onChange={(e) => {
                setFieldItem(e, mainIndex);
              }}
              extitEditMode={() => setIsEditMode(false)}
            />
          ) : (
            <p className="value">{name}</p>
          )}
        </SortableList.DragHandle>
        {!isEditMode && (isEditable || isRemove) && (
          <div className="actions">
            {isEditable && (
              <Tooltip title="Edit">
                <Button
                  className="h-auto"
                  type="link"
                  onClick={editItem}
                  icon={<ActionIconRender iconType="edit" />}
                />
              </Tooltip>
            )}
            {switchAction && (
              <Tooltip title="Menu">
                <Switch size="small" checked={itemData.is_menu} onChange={menuSet} />
              </Tooltip>
            )}
            {isRemove && (
              <Tooltip title="Remove">
                <Button
                  className="h-auto"
                  type="link"
                  onClick={remove}
                  icon={<ActionIconRender iconType="close" />}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
