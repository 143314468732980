import {
  USER_SETTING_SCORECARD_BY_YEAR,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import { DatePicker, Form, Tooltip } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dateYearFormat } from "reducers/userSettings";

export default function YearSelectPersist(props) {
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;
  const dispatch = useDispatch();
  const yearInput = useSelector((state) => state?.userSettings?.yearInput);
  const selectedYear = yearInput && yearInput[persistKey] ? yearInput[persistKey] : null;

  useEffect(() => {
    dispatch({
      type: USER_SETTING_SCORECARD_BY_YEAR,
      payload: { ...yearInput, [persistKey]: moment().format(dateYearFormat) },
    });
  }, []);

  function onYearChange(e) {
    dispatch({
      type: USER_SETTING_SCORECARD_BY_YEAR,
      payload: { ...yearInput, [persistKey]: e },
    });
  }

  return (
    <div>
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex">
            <Form.Item className="mb-0 ml-10">
              <Tooltip title="Select Year">
                <DatePicker
                  style={{ width: 150 }}
                  picker="year"
                  value={selectedYear ? moment(selectedYear, dateYearFormat) : null}
                  onChange={(e, f) => onYearChange(f)}
                  allowClear={false}
                />
              </Tooltip>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
