import { gql } from "@apollo/client";

const CRS_REQUEST_RESPONSE = gql`
  fragment CRS_Request on CRS_Request {
    title
    advertiser
    article_category
    assigned_to
    clickbait_slider
    cms_story_id
    continents
    countries {
      id
      name
    }
    categories {
      id
      name
    }
    language {
      id
      name
    }
    department
    focus_keywords
    id
    is_price
    is_rating
    language {
      id
      name
    }
    level_1
    level_1_notes
    level_2
    level_2_notes
    level_3
    number_of_words
    objective
    persona {
      id
      name
    }
    priority
    readability_grade
    reference_context
    status
    targeted_group
    tone {
      id
      name
    }
    topic
    type
    variations
    websites {
      id
      name
    }
    due_date
    level_1_user {
      name
      id
    }
    level_2_user {
      name
      id
    }
    level_3_user {
      name
      id
    }
    gender
    updatedAt
  }
`;

export const GQL_CRS_CREATE_REQUEST = gql`
  ${CRS_REQUEST_RESPONSE}
  mutation CRS_CreateRequest($request: CRS_RequestInput) {
    CRS_CreateRequest(request: $request) {
      data {
        ...CRS_Request
      }
      message
      status
    }
  }
`;
export const GQL_UPDATE_REQUEST = gql`
  ${CRS_REQUEST_RESPONSE}
  mutation CRS_UpdateRequest($id: ID!, $request: CRS_UpdateRequest) {
    CRS_UpdateRequest(id: $id, request: $request) {
      message
      status
      data {
        ...CRS_Request
      }
    }
  }
`;

const CRS_TONES_RESPONSE = gql`
  fragment CRS_Tones on CRS_Tones {
    id
    name
  }
`;

const CRS_CONFIGS_RESPONSE = gql`
  ${CRS_TONES_RESPONSE}
  fragment CRS_Configs on CRS_Configs {
    tones_configs {
      ...CRS_Tones
    }
  }
`;

export const GQL_GET_CONFIGS = gql`
  ${CRS_CONFIGS_RESPONSE}
  query CRS_GetConfigs {
    CRS_GetConfigs {
      data {
        ...CRS_Configs
      }
      message
      status
    }
  }
`;

export const GQL_GET_REQUESTS = gql`
  ${CRS_REQUEST_RESPONSE}
  query CRS_GetRequests(
    $column: String
    $order: CRS_Order
    $page: Int
    $search: String
    $size: Int
    $status: [CRS_RequestStatus]
    $recent: Boolean
    $from: Date
    $to: Date
    $priority: crs_request_priority
    $filter: CRS_FilterType
  ) {
    CRS_GetRequests(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      status: $status
      recent: $recent
      from: $from
      to: $to
      priority: $priority
      filter: $filter
    ) {
      message
      status
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...CRS_Request
      }
    }
  }
`;

export const GQL_GET_REQUEST = gql`
  ${CRS_REQUEST_RESPONSE}
  query CRS_GetRequest($id: ID!) {
    CRS_GetRequest(id: $id) {
      message
      status
      data {
        ...CRS_Request
      }
    }
  }
`;

// const CRS_CMS_LIST_RESPONSE = gql`
//   fragment CRS_CmsList on CRS_CmsList {
//     block_id
//     bottom_line
//     category_id
//     cta_button_type
//     description
//     domain_id
//     image
//     is_feature_post
//     is_private
//     list_type
//     meta_description
//     meta_keywords
//     meta_title
//     product_id
//     pub_date
//     skimlink
//     status
//     tags
//     title
//   }
// `;
// const CRS_CMS_STORY_RESPONSE = gql`
//   fragment CRS_CmsStory on CRS_CmsStory {
//     category_id
//     content
//     domain_id
//     image
//     is_feature_post
//     is_private
//     meta_description
//     meta_keywords
//     meta_title
//     product_id
//     pub_date
//     skimlink
//     status
//     store_id
//     sub_title
//     tags
//     title
//   }
// `;

// const CRS_CMS_RESPONSE = gql`
//   ${CRS_CMS_STORY_RESPONSE}
//   ${CRS_CMS_LIST_RESPONSE}
//   fragment CRS_Cms on CRS_Cms {
//     list {
//       ...CRS_CmsList
//     }
//     story {
//       ...CRS_CmsStory
//     }
//   }
// `;

// export const GQL_GET_ARTICLE = gql`
//   ${CRS_CMS_RESPONSE}
//   query crs_GetArticle($reqId: ID!) {
//     crs_GetArticle(reqId: $reqId) {
//       message
//       status
//       data {
//         ...CRS_Cms
//       }
//     }
//   }
// `;

export const GQL_UPDATE_ARTICLE = gql`
  mutation CRS_UpdateArticle($action: CRS_StatusActions, $cms: CRS_CmsInput, $reqId: ID!) {
    CRS_UpdateArticle(action: $action, cms: $cms, reqId: $reqId) {
      message
      status
      data {
        cms_id
        type
      }
    }
  }
`;

export const CRS_CREATE_COMMENT = gql`
  mutation CRS_CreateComment($requestId: ID!, $comment: String) {
    CRS_CreateComment(requestId: $requestId, comment: $comment) {
      message
      status
      data {
        comment
        level
        user {
          id
          name
          picture
        }
        date
      }
    }
  }
`;

export const CRS_GET_COMMENT = gql`
  query CRS_GetComments($requestId: ID!) {
    CRS_GetComments(requestId: $requestId) {
      data {
        comment
        date
        level
        user {
          picture
          id
          name
        }
        assigned_to
        action_type
        user_id
        user_name
      }
      message
      status
    }
  }
`;

export const CRS_GET_CONTINENTS = gql`
  query umsContinents {
    umsContinents {
      continent_code
      countries {
        currency_code
        currency_name
        id
        iso_code
        name
      }
      id
      name
    }
  }
`;

export const CRS_GET_REQUEST_SUMMARY = gql`
  query CRS_GetRequestSummary($from: Date, $to: Date) {
    CRS_GetRequestSummary(from: $from, to: $to) {
      data {
        APPROVED_L1
        APPROVED_L2
        ARTICLE_IN_REVIEW
        ASSIGNED
        CHANGES_REQUESTED
        DRAFT
        PENDING
        PUBLISHED
        REQUEST_IN_REVIEW
        SUBMITTED
      }
      message
      status
    }
  }
`;

export const CRS_GET_PERSONA = gql`
  query CRS_GetPersona {
    CRS_GetPersona {
      data {
        id
        name
      }
      message
      status
    }
  }
`;

export const CRS_CREATE_TONE = gql`
  mutation CRS_CreateTone($name: String) {
    CRS_CreateTone(name: $name) {
      data {
        name
        id
      }
      message
      status
    }
  }
`;

export const CRS_GET_TONES = gql`
  query CRS_GetTones {
    CRS_GetTones {
      data {
        id
        name
      }
      message
      status
    }
  }
`;

export const CRS_DELETE_REQUEST = gql`
  mutation CRS_DeleteRequest($ids: [ID]) {
    CRS_DeleteRequest(ids: $ids) {
      message
      status
    }
  }
`;

const CRS_PERSONAS_RESPONSE = gql`
  fragment CRS_Personas on CRS_Personas {
    id
    name
  }
`;

export const GQL_CRS_GET_PERSONAS = gql`
  ${CRS_PERSONAS_RESPONSE}
  query CRS_GetPersonas {
    CRS_GetPersonas {
      message
      status
      data {
        ...CRS_Personas
      }
    }
  }
`;

export const GQL_CRS_CREATE_PERSONAS = gql`
  ${CRS_PERSONAS_RESPONSE}
  mutation CRS_CreatePersonas($name: String) {
    CRS_CreatePersonas(name: $name) {
      message
      status
      data {
        ...CRS_Personas
      }
    }
  }
`;

export const GQL_CRS_GET_PROMPT = gql`
  query CRS_GetPrompt($crsPromptId: ID) {
    CRS_GetPrompt(id: $crsPromptId) {
      data {
        language {
          name
          code
          id
        }
        prompts
        websites {
          ads_file
          id
        }
        countries {
          name
        }
      }
      message
      status
    }
  }
`;
