import cx from "classnames";
import { Fragment } from "react";

const steps = [
  { step: 1, name: "Launch" },
  { step: 2, name: "Targeting" },
  { step: 3, name: "Destination" },
  { step: 4, name: "Review" },
];

function StepView(props) {
  const { name, step, activeStep = 1, index } = props;
  return (
    <div className={cx("step-view", { active: step <= activeStep })}>
      <p className="step-num">{step}</p>
      <p className="name">{name}</p>
    </div>
  );
}

export default function LaunchProgressBar() {
  const activeStep = 2;

  function renderSteps() {
    return steps.map((data, index) => {
      const stepLength = steps.length;
      const lineWidth = 100 / (stepLength - 1);
      const lineStyle = {
        width: `calc(${lineWidth}% + var(--widthSub))`,
        left: `calc(${lineWidth * index}% + var(--leftSub))`,
      };
      return (
        <Fragment key={"steps" + index}>
          <div className="step-wrapper">
            <StepView {...data} activeStep={activeStep} index={index} />
          </div>
          {index !== stepLength - 1 && (
            <span
              className={cx("step-line", `step-${data.step}`, { active: data.step < activeStep })}
              style={lineStyle}
            />
          )}
        </Fragment>
      );
    });
  }

  return (
    <div className="launch-progress-bar">
      <div className="position-relative blocks">{renderSteps()}</div>
    </div>
  );
}
