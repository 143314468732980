export const LEADGEN_FACEBOOK_PLATFORM_ID = 1;
export const LEADGEN_GOOGLE_PLATFORM_ID = 3;

export const LEADGEN_REPORT_ASSET_TYPE_ADACCOUNT = "ADACCOUNT";
export const LEADGEN_REPORT_ASSET_TYPE_AD = "AD";
export const LEADGEN_REPORT_ASSET_TYPE_ADSET = "ADSET";
export const LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN = "CAMPAIGN";
export const LEADGEN_REPORT_ASSET_TYPE_ADGROUP = "ADGROUP";

export const LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT = "AD_ACCOUNT";
export const LEADGEN_VOLUUM_REPORT_ASSET_TYPE_AD = "AD";
export const LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET = "AD_SET";
export const LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN = "CAMPAIGN";

export const FB360_BUDGET_TYPE = "bid_amount";
