import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import React, { useState } from "react";
import UpdateForm from "./UpdateForm";
import { useLaunchContext } from "components/Launch/Context";
import { Segmented, Switch } from "antd";
import AdgroupSelection from "./AdgroupSelection";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import {
  CONTENT_TYPE_GROUPS,
  C_TYPE_CBO,
  ENROLL_STATUS_ENUM,
  SERVICE_TYPE_LEAD_GEN,
} from "shared/enum/launchKeysAndEnum";
import cx from "cx";
import AddTiktokFields from "./AddTiktok_AD_Fields";
import AvatarUpload from "components/Launch/Creatives/AvatarUpload";
import { LAUNCH_SMART_CREATIVE_ENUM } from "shared/enum/iCampaignLaunchEnumArray";
import useUpdateAdsetFilter from "./useUpdateAdsetFilter";
import DebugPre from "components/DebugPre";

function UpdateAdsetForm(props) {
  const {
    titleCamapignName = "",
    campaignObject,
    isValidAdset = () => {},
    isValidDate = () => {},
    campaignProps,
    serviceType,
  } = props;
  const { handleCampaignAdsetAdset, isTiktokPlatformLaunch, instantLaunch, bucketData, inputs } =
    useLaunchContext();
  // console.log(campaignProps);

  const [filterData, setFilterData] = useState([]);

  const { openFilter, filterView } = useUpdateAdsetFilter({ filterData, setFilterData });

  const isGroup = bucketData?.content_type === CONTENT_TYPE_GROUPS;
  const [visibleDrawerUpdate, setVisibleDrawerUpdate] = useState(false);
  const [visibleAdGroupDrawer, setVisibleAdGroupDrawer] = useState(false);
  const [visibleCreativeDrawer, setVisibleCreativeDrawer] = useState(false);
  const [rowProps, setRowProps] = useState({});
  const { copyAdset, removeAdSet } = useLaunchContext();
  const { setAdGroupData, productId } = useLaunchContext();
  // const [selectedData, setSelectedData] = useState();
  const showDrawerUpdate = (rowProps) => {
    setRowProps({ targetingtemplate: rowProps, campaignIndex: rowProps?.campaignIndex });
    setVisibleDrawerUpdate(true);
  };
  const onCloseDrawerUpdate = () => {
    setRowProps({});
    setVisibleDrawerUpdate(false);
  };

  const showAdGroupDrawer = (rowProps) => {
    setRowProps(rowProps);
    setVisibleAdGroupDrawer(true);
  };
  const onCloseAdGroupDrawer = () => {
    setRowProps({});
    setAdGroupData({});
    setVisibleAdGroupDrawer(false);
  };
  const showCreativeDrawer = (rowProps) => {
    setRowProps(rowProps);
    setVisibleCreativeDrawer(true);
  };
  const onCloseCreativeDrawer = () => {
    setRowProps({});
    setVisibleCreativeDrawer(false);
  };

  const initialColumns = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      width: 124,
      minWidth: 124,
      render: (value) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Adset Name",
      dataIndex: "adSetName",
      key: "adSetName",
      width: 124,
      minWidth: 124,
      render: (value) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
      render: (value, otherProps) => {
        const isDynamic = isTiktokPlatformLaunch
          ? otherProps?.creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM
          : otherProps?.isDynamicCreative;
        const isEdited = isTiktokPlatformLaunch
          ? otherProps?.edited
          : otherProps?.targeting?.edited;

        return (
          <LookTableColumnRender
            tooltip={
              !isValidAdset(otherProps) && (
                <>
                  Please select {isDynamic ? "Creatives" : "Adgroups"}
                  <br />
                </>
              )
            }
            title={
              <strong
                className={cx({
                  "text-danger": !isValidAdset(otherProps),
                })}
              >
                {isEdited ? `Edited` : `Template`}
              </strong>
            }
          />
        );
      },
      width: 124,
      minWidth: 124,
    },
    {
      title: "Dynamic",
      dataIndex: "isDynamicCreative",
      key: "isDynamicCreative",
      width: 124,
      minWidth: 124,
      render: (value, otherProps) => (
        <>
          {
            <Switch
              checked={
                isTiktokPlatformLaunch
                  ? otherProps?.creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM
                  : otherProps?.isDynamicCreative
              }
              onClick={(e) => {
                if (isTiktokPlatformLaunch) {
                  const newCreativeMaterialMode =
                    e === true ? LAUNCH_SMART_CREATIVE_ENUM : "CUSTOM";
                  handleCampaignAdsetAdset(otherProps?.campaignIndex, otherProps?.adSetindex, {
                    creativeMaterialMode: newCreativeMaterialMode,
                  });
                } else {
                  const adSetData =
                    e === true
                      ? { isDynamicCreative: true, dof: "OPT_OUT" }
                      : { isDynamicCreative: false, dof: "OPT_IN" };
                  handleCampaignAdsetAdset(
                    otherProps?.campaignIndex,
                    otherProps?.adSetindex,
                    adSetData
                  );
                }
              }}
            />
          }
        </>
      ),
    },
    {
      title: "Creatives",
      dataIndex: "creatives",
      key: "creatives",
      width: 130,
      minWidth: 130,
      render: (value, otherProps) => {
        const isDynamic = isTiktokPlatformLaunch
          ? otherProps?.creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM
          : otherProps?.isDynamicCreative;
        //check if ads exists
        const adCreatives = otherProps?.adCreatives;
        const hasImageOrVideo = adCreatives?.image?.length || adCreatives?.video?.length;
        const hasTitle = adCreatives?.title?.length > 0;
        const hasBody = adCreatives?.body?.length > 0;
        const hasCreatives = isTiktokPlatformLaunch
          ? hasImageOrVideo && hasTitle
          : hasImageOrVideo && hasTitle && hasBody;
        const customAds = isDynamic ? !otherProps?.isDynamicTemp : !otherProps?.isNonDynamicTemp;
        const creativesTitle = inputs.creative_auto_select ? "Auto" : customAds ? "Custom" : "";
        const creativesName = isDynamic ? "Creatives" : "Adgroups";
        const result = `${creativesName} ${creativesTitle} (${
          isDynamic ? (hasCreatives ? 1 : 0) : otherProps?.ads?.length
        })`;

        return (
          <div className="d-flex" style={{ alignItems: "center" }}>
            {result}
            <LookButton
              className="icons"
              onClick={() =>
                isDynamic ? showAdGroupDrawer(otherProps) : showCreativeDrawer(otherProps)
              }
              iconType="edit"
            />
          </div>
        );
      },
    },
    ...(isTiktokPlatformLaunch
      ? []
      : [
          {
            title: "Standard Enhancement",
            dataIndex: "standardEnhancement",
            key: "standardEnhancement",
            width: 140,
            minWidth: 140,
            render: (value, otherProps) => {
              return (
                // <SelectInput
                //   name="specialIndustries"
                //   className="mb-0"
                //   value={otherProps?.dof}
                //   onChange={(e) =>
                //     handleCampaignAdsetAdset(campaignIndex, otherProps?.adSetindex, {
                //       dof: e,
                //     })
                //   }
                //   options={ENROLL_STATUS_ENUM}
                // />
                <Segmented
                  onChange={(e) =>
                    handleCampaignAdsetAdset(otherProps?.campaignIndex, otherProps?.adSetindex, {
                      dof: e,
                    })
                  }
                  value={otherProps?.dof}
                  options={ENROLL_STATUS_ENUM?.map(({ name, value }) => ({ label: name, value }))}
                />
              );
            },
          },
        ]),

    {
      title: "Budget",

      dataIndex: "budget",
      key: "budget",
      render: (value, otherProps) => {
        return (
          <LookTableColumnRender
            title={
              campaignObject[otherProps?.campaignIndex]?.cType === C_TYPE_CBO
                ? "Campaign Level"
                : isTiktokPlatformLaunch
                ? value
                : otherProps?.targeting?.budget
            }
          />
        );
      },
      width: 124,
      minWidth: 124,
    },
    // {
    //   title: "Bid Strategy",
    //   dataIndex: "bidStrategy",
    //   key: "bidStrategy",
    //   width: 124,
    //   minWidth: 124,
    //   render: (value, { targeting }) => <LookTableColumnRender title={targeting?.bidStrategy} />,
    // },
    // {
    //   title: "Bid",
    //   dataIndex: "bid",
    //   key: "bid",
    //   width: 124,
    //   minWidth: 124,
    //   render: (value, { targeting }) => <LookTableColumnRender title={targeting?.bid} />,
    // },
    {
      title: "Action",
      dataIndex: "action",

      render: (action, actionProps) => {
        const { isDuplicate = false } = actionProps;
        return (
          <>
            <span>
              <LookButton
                className="h-auto w-auto mr-8"
                type="link"
                iconType="edit"
                tooltipTitle="Edit"
                onClick={() => showDrawerUpdate(actionProps)}
              />
              <LookButton
                className="h-auto w-auto"
                type="link"
                iconType="copy"
                tooltipTitle="Duplicate"
                onClick={() => copyAdset(actionProps?.campaignIndex, actionProps?.adSetindex)}
              />
              {isDuplicate && (
                <LookButton
                  className="h-auto w-auto ml-8"
                  type="link"
                  danger
                  iconType="delete"
                  tooltipTitle="Remove"
                  onClick={() => removeAdSet(actionProps?.campaignIndex, [actionProps?.adSetindex])}
                />
              )}
            </span>
          </>
        );
      },

      fixed: "right",
      width: 100,
    },
  ];

  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) =>
        removeAdSet(
          e?.map(({ campaignIndex }) => campaignIndex),
          e?.map(({ adSetindex }) => adSetindex),
          true
        ),
    },
  ];

  function filterRecord(recordData) {
    let temp = [...recordData];

    temp = temp.filter((currentData) => {
      if (filterData?.length > 0) {
        let isValidCountryFilter = true,
          isValidDevicePlatformFilter = true,
          isValiduserOsFilter = true;

        const countryFilterIndex = filterData?.findIndex(
          ({ filterName }) => filterName === "country"
        );
        const devicePlatformsFilterIndex = filterData?.findIndex(
          ({ filterName }) => filterName === "devicePlatforms"
        );
        const userOsFilterIndex = filterData?.findIndex(
          ({ filterName }) => filterName === "userOs"
        );

        if (countryFilterIndex !== -1) {
          const countryFilter = filterData[countryFilterIndex]?.value;

          if (countryFilter?.length > 0) {
            const filterList =
              currentData?.targeting?.template?.geo_locations?.countries?.filter((v) =>
                countryFilter?.includes(v)
              ) || [];

            isValidCountryFilter = filterList?.length > 0;
          }
        }

        if (devicePlatformsFilterIndex !== -1) {
          const devicePlatformFilter = filterData[devicePlatformsFilterIndex]?.value;

          if (devicePlatformFilter?.length > 0) {
            isValidDevicePlatformFilter = devicePlatformFilter?.every((v) =>
              currentData?.targeting?.template?.device_platforms?.includes(v)
            );
          }
        }

        if (userOsFilterIndex !== -1) {
          const userOsFilter = filterData[userOsFilterIndex]?.value;
          if (userOsFilter?.length > 0) {
            isValiduserOsFilter = userOsFilter?.every((v) =>
              currentData?.targeting?.template?.user_os?.includes(v)
            );
          }
        }

        return isValidCountryFilter && isValidDevicePlatformFilter && isValiduserOsFilter;
      } else {
        return true;
      }

      return false;
    });

    return temp;
  }

  const tableProps = {
    initialColumns: initialColumns,
    recordData: filterRecord(
      campaignProps?.flatMap((campaigns, campaignIndex) => {
        // Initialize adSetindex for each campaign
        return campaigns?.adSets?.map((data, index) => ({
          ...data,
          key: campaignIndex + "-" + index,
          campaignName: "campaign-" + parseInt(campaignIndex + 1),
          campaignIndex: campaigns?.campaignIndex,
          adSetName: `adset-${data?.adSetindex}`,
        }));
      })
    ),
  };

  // console.log(
  //   campaignProps?.flatMap((campaigns, campaignIndex) => {
  //     // Initialize adSetindex for each campaign
  //     return campaigns?.adSets?.map((data, index) => ({
  //       ...data,
  //       key: campaignIndex + "-" + index,
  //       campaignName: data?.name,
  //       campaignIndex: campaigns?.campaignIndex,
  //       adSetName: `adset-${data?.adSetindex}`,
  //     }));
  //   })

  const drawerPropsUpdate = {
    title: `Edit ${rowProps?.name}`,
    maskClosable: false,
    visible: visibleDrawerUpdate,
    onClose: onCloseDrawerUpdate,
    size: 1200,
  };
  const titleAdSetName =
    campaignObject[rowProps?.campaignIndex]?.adSets[rowProps?.adSetindex]?.name || "";
  const adGroupDrawerProps = {
    textBreadcrumb: [titleCamapignName, titleAdSetName],
    visible: visibleAdGroupDrawer,
    onClose: onCloseAdGroupDrawer,
    size: 1200,
  };
  const creativeDrawerProps = {
    textBreadcrumb: [titleCamapignName, titleAdSetName],
    visible: visibleCreativeDrawer,
    onClose: onCloseCreativeDrawer,
    size: 1200,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ campaignProps }, { recordData: tableProps?.recordData }] },
  ];

  return (
    <div>
      <LookDrawer {...creativeDrawerProps} className="no-body-space">
        {visibleCreativeDrawer && (
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Add Creatives",
                icon: () => <ActionIconRender iconType="pic-left" />,
                noSpace: true,
                component: (
                  <AdgroupSelection
                    {...rowProps}
                    isTemp={false}
                    // campaignIndex={campaignIndex}
                    productId={productId}
                    isKeyword={isGroup}
                    isTiktokPlatformLaunch={isTiktokPlatformLaunch}
                  />
                ),
              },
              /**
               *  Add Avatar on ad level
               */
              {
                hoverTitle: "Add Avatar",
                icon: () => <ActionIconRender iconType="pic-left" />,

                component: (
                  <AvatarUpload
                    launch
                    {...rowProps}
                    // campaignIndex={campaignIndex}
                    productId={productId}
                    groupSelection
                    isLaunchCampaign
                  />
                ),
              },
              /**
               *  Add deeplink on ad level
               */
              {
                hoverTitle: "Add link",
                icon: () => <ActionIconRender iconType="pic-left" />,

                component: (
                  <AddTiktokFields
                    {...rowProps}
                    // campaignIndex={campaignIndex}
                    productId={productId}
                  />
                ),
              },
            ]}
          />
        )}
      </LookDrawer>
      <LookDrawer {...adGroupDrawerProps}>
        <div className="creatives-drawer">
          {visibleAdGroupDrawer && (
            <LookDrawerMainSideBar
              tabContent={[
                {
                  hoverTitle: "Add Creatives",
                  icon: () => <ActionIconRender iconType="pic-left" />,
                  noSpace: true,
                  component: (
                    <AdgroupSelection
                      {...rowProps}
                      isTemp={false}
                      onClose={onCloseAdGroupDrawer}
                      // campaignIndex={campaignIndex}
                      productId={productId}
                      isKeyword={isGroup}
                      dynamic
                      isTiktokPlatformLaunch={isTiktokPlatformLaunch}
                    />
                  ),
                },
              ]}
            />
          )}
        </div>
      </LookDrawer>
      <LookDrawer {...drawerPropsUpdate}>
        <LookDrawerMainSideBar
          tabContent={[
            {
              hoverTitle: "Edit Targeting",
              icon: () => <ActionIconRender iconType="pic-left" />,
              component: (
                <>
                  {visibleDrawerUpdate && (
                    <UpdateForm
                      onClose={onCloseDrawerUpdate}
                      {...rowProps}
                      // campaignIndex={campaignIndex}
                      handleCampaignAdsetAdset={handleCampaignAdsetAdset}
                    />
                  )}
                </>
              ),
              noSpace: true,
            },
          ]}
        />
      </LookDrawer>
      {filterView()}
      <LookTable
        isTableFilter={serviceType !== SERVICE_TYPE_LEAD_GEN}
        filterProps={{
          persistSearch: false,
          showResizeHideColumns: false,
          actionButtons: [{ iconType: "filter", onClick: openFilter, title: "filter" }],
        }}
        persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
        tableProps={tableProps}
        isSrNo
      />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default UpdateAdsetForm;
