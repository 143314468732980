import React from "react";
import { Mentions } from "antd";
import parse from "html-react-parser";

const LookMentionInput = React.forwardRef((props, ref) => {
  const {
    formik = false,
    name,
    placeholder,
    value,
    mentioned = [], // [{key:'', value: '', label: ''}]
    onChange,
    onFocus,
    error,
    searchList,
    onSelectedMentions = () => {},
  } = props;

  const onOptionSelect = ({ key, value, label }) => {
    const obj = {
      id: +key,
      name: value,
      mention: "@" + value,
    };
    onSelectedMentions((prev) => [...prev, obj]);
  };

  const onInputChange = (e) => {
    // remove mention if name don't match with the already mentioned names
    let obj = [];
    if (mentioned?.length) {
      mentioned.forEach((it) => {
        if (e.includes(it?.mention)) obj.push(it);
      });
    }
    onSelectedMentions(obj);
    onChange(e);
  };

  const renderError = (error) => <div className="text-left error-text">{parse(error)}</div>;

  return (
    <>
      <Mentions
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
        onFocus={onFocus}
        onSelect={onOptionSelect}
        options={searchList}
      />
      {!formik && error && renderError(error)}
    </>
  );
});

export default LookMentionInput;
