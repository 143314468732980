import { gql } from "@apollo/client";

export const GQL_GL_LAUNCH_CONFIG = gql`
  query gl_launchConfig {
    gl_launchConfig {
      campaign
    }
  }
`;

export const G360_BULK_LAUNCH = gql`
  mutation gl_bulkLaunch(
    $accountData: [gl_accountData]
    $camps: [gl_campaignInput]!
    $imageMedia: [ImageMedia]
    $name: String!
    $productId: ID
    $targeting: Targeting
  ) {
    gl_bulkLaunch(
      accountData: $accountData
      camps: $camps
      imageMedia: $imageMedia
      name: $name
      productId: $productId
      targeting: $targeting
    )
  }
`;

export const G360_GET_COUNTRIES = gql`
  query gl_getCountries {
    gl_getCountries {
      criteriaId
      id
      name
    }
  }
`;

export const G360_GET_LANGUAGES = gql`
  query gl_getLanguages {
    gl_getLanguages {
      criteriaId
      id
      languageCode
      languageName
    }
  }
`;

export const G360_LAUNCH_LISTING = gql`
  query gl_getLaunchDetails($fromDate: String, $toDate: String) {
    gl_getLaunchDetails(fromDate: $fromDate, to_date: $toDate) {
      createdBy
      id
      name
      status
      conversion_name
      code_snippet
    }
  }
`;
