import userReducer from "./userReducer.js";
import f360Reducer from "./f360Reducer";
import cmsReducer from "./cmsReducer";
import userSettings from "./userSettings";
import tableConfigReducer from "./tableConfigReducer";
import iCampaignsReducer from "./iCampaignsReducer";
import launchReducer from "./launchReducer";
import g360Reducer from "./g360Reducer.js";
import { combineReducers } from "redux";
import vsReducer from "./vsReducer.js";
import twitterReducer from "./twitterReducer.js";
import slackReducer from "./slackReducer.js";
import callBackReducer from "./callBackReducer.js";
import scorecardReducer from "./scorecardReducer.js";
import unicornReducer from "./unicornReducer.js";

const rootReducer = combineReducers({
  userReducer,
  f360Reducer,
  tableConfigReducer,
  cmsReducer,
  userSettings,
  iCampaignsReducer,
  launchReducer,
  g360Reducer,
  vsReducer,
  twitterReducer,
  slackReducer,
  callBackReducer,
  scorecardReducer,
  unicornReducer,
});

export default rootReducer;
