import { Button, Switch, Tooltip } from "antd";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import LookTable from "components/LookTable";

import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useState, useImperativeHandle } from "react";
import {
  GQL_RE_DELETESTARTEGY,
  GQL_RE_GETALLSTARTEGY,
  GQL_RE_GETTAGS,
} from "shared/gql/gqlSchema/ruleGql";
import { openNotification } from "utils";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import moment from "moment";
import SelectInput from "components/forms/SelectInput";
import { useSelector } from "react-redux";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { RULE_PLATFORM_TIKTOK } from "shared/SharedKeys";
import ExistingRule from "../ExistingRules";
import NewStrategyForm from "../NewStrategies";
import UpdateStrategy from "./updateRuleStrategy";

export default function ExistingStrategy(props) {
  const { productId, platform, userId } = props;
  const [data, setdata] = useState([]);
  const [Do, setDo] = useState("");
  const [update, setupdate] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [country, setcountry] = useState("all");
  const search = useSelector(
    (state) => state?.userSettings?.mainSearch?.[USER_SETTING_TABLE_PERSIST_KEY_MAIN]?.search
  );

  const [clgetallStrategy, { loading: loading_clgetRule, refetch: clruleFetch }] =
    useCustomLazyQuery(GQL_RE_GETALLSTARTEGY, {
      variables: {
        platform: platform,
        product: productId,
      },
      onCompleted: (e) => {
        const element = e?.re_getAllStrategy;
        if (element) {
          let res = [];
          for (let i in element) {
            res.push({
              id: element[i].id,
              country: element[i].country,
              s_tag: element[i].s_tag,
              name: element[i].name,
            });
          }
          // console.log(res);
          setdata(res);
        }
      },
      onError(e) {
        setdata([]);
      },
    });

  useEffect(() => {
    clgetallStrategy();
  }, []);
  const finalDataWithCountryCheck =
    country !== "all" ? data.filter((item) => item.country.includes(country)) : data;

  const finaldatabysearch = search
    ? finalDataWithCountryCheck.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
    : finalDataWithCountryCheck;

  const [cldeleteStrategy, { loading: loading_DeleteGroups }] = useCustomMutation(
    GQL_RE_DELETESTARTEGY,
    {
      variables: {},
      onCompleted: (e) => {
        const element = e?.re_deleteStrategy;
        if (element) {
          openNotification({ type: "success", message: "Strategy deleted Successfully" });
          clruleFetch();
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    cldeleteStrategy({
      variables: {
        strategy_id: deleteModalPropsData?.id,
      },
    });
    setDeleteModalVisible(false);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  function showFilterSearch(data) {
    if (data) {
      setVisible(true);
      setDo("add");
      setupdate(data);
    } else {
      setVisible(true);
      setDo("add");
    }
  }

  function updateFilterSearch(data) {
    setVisible(true);
    setDo("update");
    setupdate(data);
  }
  function onClose() {
    setVisible(false);
    setupdate(false);
  }

  const drawerProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 1000,
    visible,
    onClose,
  };
  const initialColumns = [
    {
      title: "Strategy Name",
      dataIndex: "name",
      key: "name",
      searchable: true,
      searchType: "string",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={value}
          isClick={() => {
            updateFilterSearch(otherProps);
          }}
        />
      ),
      width: 160,
      minWidth: 160,
    },
    {
      title: "country",
      dataIndex: "country",
      key: "country",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => (
        <LookTableColumnRender title={value?.length > 0 ? value.join(", ") : value} />
      ),
    },
    {
      title: "tag",
      dataIndex: "s_tag",
      key: "s_tag",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
    },

    {
      title: "Actions",
      dataIndex: "action",
      nofilter: true,
      render: (value, otherProps) => {
        return (
          <>
            <Tooltip title="Delete">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="delete" />}
                onClick={
                  () => onOpenDeleteModal(otherProps)
                  // deleteRule(otherProps.name)
                }
              />
            </Tooltip>

            {/* <Switch
              className="mr-8"
              style={{ backgroundColor: "#141414" }}
              checked={otherProps.status !== "paused"}
              onChange={(e) => handleStatus(e, otherProps)}
            /> */}
          </>
        );
      },
      fixed: "right",
      width: 80,
    },
  ];
  const rightOptions = (
    <>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Country">
          <span>
            <SelectInput
              search
              name="Country"
              className="mb-0"
              placeholder="Select Country"
              value={country}
              options={[
                { name: "Australia", value: "Australia" },
                { name: "USA", value: "USA" },
                { name: "New Zealand", value: "New Zealand" },
                { name: "Canada", value: "Canada" },
                { name: "India", value: "India" },
                { name: "England", value: "England" },
                { name: "All Countries", value: "all" },
              ]}
              onChange={setcountry}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );
  const tableProps = {
    tableProps: { initialColumns, recordData: finaldatabysearch },
    rightOptions,
    isSearchFilter: true,
  };
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_DeleteGroups,
  };

  return (
    <>
      <DashboardHeader
        title="Strategies"
        createBtn
        createBtnText="Add New Strategy"
        createBtnType="add"
        // createBtnIconType=""
        createBtnHandler={showFilterSearch}
      />
      <LookTable
        isRowSelection={false}
        {...tableProps}
        loading={loading_clgetRule}
        refetch={clruleFetch}
      />
      <LookTableRecordDeleteModal {...deleteModalProps} />

      <div>
        <LookDrawer {...drawerProps}>
          {visible &&
            (update && Do === "update" ? (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Update",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <UpdateStrategy
                        close={() => onClose()}
                        {...props}
                        refetch={clruleFetch}
                        platform={platform}
                        strategy={update}
                        allStrategies={data.map((item) => {
                          return { name: item.name, value: item.id };
                        })}
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <NewStrategyForm close={() => onClose()} {...props} refetch={clruleFetch} />
                    ),
                  },
                ]}
              />
            ))}
        </LookDrawer>
      </div>
    </>
  );
}
