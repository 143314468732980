import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";
import alasql from "alasql";
import cx from "classnames";
import { fbAdsManagerRedirect, secondsToHMS } from "utils";
import { useParams } from "react-router-dom";
import DetailView from "./DetailView";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import {
  MULTI_SEARCH_FILTER_TYPE_NUMBER,
  useLookTableFilterData,
} from "components/LookTable/LookTableFilterSearch/functions";
import {
  F360_MANAGE_LEADGEN_REPORT,
  USER_WITH_NO_ACTION,
} from "shared/UserPermission/userPermissionList";
import {
  PRODUCT_ID_LEADGEN,
  REPORT_SAS_RSOC_FB_TAG_TYPE_CAMPAIGN,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_GENERAL,
} from "shared/SharedKeys";
import { usePermissionCheck } from "hooks";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import { defaultFilterColumns } from "../defaultFilterColumns";
import DuplicateCampaigns from "../DuplicateCampaigns";
import LookModal from "components/LookModal";
import {
  GET_F360_CAMPAIGN_GROUPED_REPORT,
  GET_F360_CAMPAIGN_REPORT,
} from "shared/gql/gqlSchema/fb360Gql";
import UpdateCampaignBudget from "components/ReportUtils/UpdateCampaignBudget";
import {
  ActionsForFacebook,
  useUpdateFB360Status,
} from "components/ReportUtils/ActionsForFacebook";
import { fbGroupForStatus } from "components/ReportUtils/UpdateCampaignBudget/utils";
import { useNewLeadgenReportsQuery } from "hooks/tiktokHooks";
import { ADBY_REPORT_TYPE_ADACCOUNT } from "hooks/facebookHooks/useFacebookSpendReportsQuery";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { Button, Tooltip } from "antd";
import RuleLinkedItems from "components/LookTable/RuleEngine/RuleLinkedItems";
import NewRuleForm from "components/LookTable/RuleEngine/NewRules";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import useLookComment from "components/CommentHook";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";
import { GET_COMMENETS_COUNTS } from "shared/gql/gqlSchema/commentsGql";
import { COMMENTS_CAMPAIGNREPORT, COMMENTS_LEADGEN } from "shared/enum/commentsKeysAndEnum";
import LookButton from "components/LookButton";
import RingbaCallReport from "../RingbaCallReport";
import {
  LEADGEN_FACEBOOK_PLATFORM_ID,
  LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
} from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import BulkDetailView from "./BulkDetailView";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
export default function Campaigns(props) {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);
  const { isBulkAdAccountFiltered = false, isAdAccountFiltered = false, bulkProps = [] } = props;

  const params = useParams();

  const [spendReport, setSpendReport] = useState([]);
  const [campaignModalVisible, setCampaignModalVisible] = useState(false);
  const [ruleSetting, setRuleSetting] = useState(null);
  const [visible, setVisible] = useState(false);

  const [rowPropsData, setRowProps] = useState({});
  const [rowBulkPropsData, setRowBulkPropsData] = useState([]);
  const [visibleBudgetDrawer, setVisibleBudgetDrawer] = useState(false);
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const [visibleBulkDetailDrawer, setVisibleBulkDetailDrawer] = useState(false);
  const { updateFb360Status } = useUpdateFB360Status(REPORT_TYPE_CAMPAIGN, {
    setSpendReport,
  });
  const { commentDrawer, showDrawer } = useLookComment();

  const [visibleBulkBudgetDrawer, setVisibleBulkBudgetDrawer] = useState(false);

  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userSettings = useSelector((state) => state?.userSettings);
  const [visibleRingbaCallDrawer, setVisibleRingbaCallDrawer] = useState(false);

  const { dateRangeTimezone, mainSearchWithInclude, searchFilter } = userSettings || {};

  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);

  const { [persistKey]: searchFilterState } = searchFilter || {};

  const { havePermission: isWithoutAction } = usePermissionCheck(USER_WITH_NO_ACTION);

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const withUser = params?.userID ? { user_id: params?.userID } : {};
  const [commentsCountsData, setCountsCommentsData] = useState([]);
  const [unseenComment, setUnseenComment] = useState(false);
  const { selectedRowsClear } = useLookTableParentContext();

  // Filter Finalfilter Data Id's used this ids in (GET_COMMENETS_COUNTS) api to get counts for same data
  const [
    getCommentsCounts,
    { loading: COMMENETS_GET_ALL_MESSAGE_loading, refetch: COMMENETS_GET_ALL_MESSAGE_refetch },
  ] = useCustomLazyQuery(GET_COMMENETS_COUNTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      reportType: COMMENTS_CAMPAIGNREPORT,
      tableType: COMMENTS_LEADGEN,
    },
    onCompleted: (e) => {
      const element = e?.comment_getCommentCount?.data;
      if (element) {
        setCountsCommentsData(element);
      }
    },
  });

  useEffect(() => {
    getCommentsCounts();
  }, []);

  let reportSettings = {
    reportApi: GET_F360_CAMPAIGN_REPORT,
    reportApiKey: "fb360_campaignReporting",
  };

  if (isAdAccountFiltered || isBulkAdAccountFiltered || params?.accID) {
    reportSettings = {
      reportApi: GET_F360_CAMPAIGN_GROUPED_REPORT,
      reportApiKey: "fb360_campaignGroupedReporting",
      // reportVariables: {
      //   asset_id: params?.accID,
      //   asset_type: ADBY_REPORT_TYPE_ADACCOUNT,
      // },
      // revenueVariables: {
      //   asset_id: [params?.accID],
      //   asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
      // },
    };
  }

  function getSpendAssetParams() {
    if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return { asset_type: ADBY_REPORT_TYPE_ADACCOUNT, asset_id: [params?.accID] };
    } else {
      return {};
    }
  }

  const spendExtraParams = getSpendAssetParams();

  const { loading: loading_GET_REPORT_BY_CAMPAIGN, refetch: refetch_GET_REPORT_BY_CAMPAIGN } =
    useCustomQuery(reportSettings.reportApi, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[reportSettings.reportApiKey];
        if (element) {
          setSpendReport(
            element?.map((item) => ({
              ...item,
              campaignId: item?.campaign_id,
              campaignName: item?.campaign_name,
              spendCampaignId: item?.campaign_id,
              spendAccountId: item?.adaccount_id,
              clicksFB: item?.inline_link_clicks,
              spendFB: item?.spend,
            }))
          );
        }
      },
      variables: {
        start: dateRangeTimezone?.rangeDates[0],
        end: dateRangeTimezone?.rangeDates[1],
        timezone: dateRangeTimezone?.timeZone?.value,
        ...spendExtraParams,
        ...withUser,
      },
    });

  function getRevenueAssetParams() {
    if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return { asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT, asset_id: [params?.accID] };
    } else {
      return {};
    }
  }

  const revenueExtraParams = getRevenueAssetParams();

  const { loading_revenue, refetch_revenue, revenueReport } = useNewLeadgenReportsQuery(
    REPORT_TYPE_CAMPAIGN,
    {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      variables: {
        ...revenueExtraParams,
        ...withUser,
      },
    }
  );

  const refetch = () => {
    refetch_GET_REPORT_BY_CAMPAIGN();
    refetch_revenue();
  };

  const loading = loading_GET_REPORT_BY_CAMPAIGN || loading_revenue;

  const action =
    permissions?.findIndex((name) => name === F360_MANAGE_LEADGEN_REPORT) !== -1 && !isWithoutAction
      ? [
          {
            title: "Action",
            dataIndex: "action",
            nofilter: true,
            render: (action, actionProps) => (
              <div className="flex-center">
                <Tooltip title="Ringba Call report">
                  <Button
                    className="mr-10"
                    type="link"
                    onClick={() => showRingbaCallDrawer(actionProps)}
                    icon={<ActionIconRender iconType="barChartLine" />}
                  />
                </Tooltip>
                <ActionsForFacebook
                  reportType={REPORT_TYPE_CAMPAIGN}
                  actionProps={actionProps}
                  showBudgetDrawer={showBudgetDrawer}
                  setSpendReport={setSpendReport}
                />
              </div>
            ),
            fixed: "right",
            width: 100,
            minWidth: 100,
          },
        ]
      : [];

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const lastColumns = [
    {
      title: "Budget",
      dataIndex: "daily_budget",
      key: "daily_budget",
      render: (value, actionProps) => {
        const { campaign_id, adset_id, daily_budget, lifetime_budget, campaignId, adsetId } =
          actionProps;

        return (
          <div className="action-column d-flex justify-content-around">
            {(campaign_id || adset_id || campaignId || adsetId) && (
              <div className="actions flex-center">
                <Tooltip
                  title={
                    daily_budget !== 0
                      ? "Daily Budget"
                      : lifetime_budget !== 0
                      ? "Lifetime Budget"
                      : "ABO"
                  }
                >
                  <span>
                    {(daily_budget === 0 || daily_budget === null || daily_budget === undefined) &&
                    (lifetime_budget === 0 ||
                      lifetime_budget === undefined ||
                      lifetime_budget === null) ? (
                      <span className="table-tag">ABO</span>
                    ) : (
                      "$" + (daily_budget || lifetime_budget)
                    )}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
      fixed: "right",
      width: 100,
      minWidth: 100,
      defaultSortOrder: "descend",
      searchType: MULTI_SEARCH_FILTER_TYPE_NUMBER,
      sorter: (a, b) => a?.budgetForSorter - b?.budgetForSorter,
    },
  ];

  const initialColumns = [
    {
      title: "Campaign Name",
      dataIndex: "finalCampaignName",
      key: "finalCampaignName",
      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return (
          <LookTableColumnRender
            title={value}
            {...otherProps}
            isProfit={PROFIT}
            isClick={
              LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
                ? undefined
                : () => showDrawerUpdate(otherProps)
            }
          />
        );
      },
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },

    {
      title: "Campaign Id",
      dataIndex: "campaignId",
      key: "campaignId",
      render: (value) => <LookTableColumnRender title={value} />,
      // hidden: true,
      width: 170,
      minWidth: 170,
      searchType: "string",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      hidden: true,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      searchType: "string",
    },

    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revenue - b?.revenue,
      searchType: "number",
    },
    {
      title: "Payout",
      dataIndex: "PAYOUT",
      key: "PAYOUT",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.PAYOUT - b?.PAYOUT,
    },
    {
      title: "Ringba Revenue",
      dataIndex: "payout_amount",
      key: "payout_amount",
      hidden: true,
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.payout_amount - b?.payout_amount,
    },
    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
      searchType: "number",
    },

    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      // render: (value) => <LookTableColumnRender title={value} />,
      render: (value, otherProps) => {
        // const profit = `$${value.replace("-", "")}`;
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },

      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      searchType: "number",
    },
    {
      title: "ROAS",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROI - b?.ROI,
      searchType: "number",
      defaultSortOrder: "ascend",
    },
    {
      title: "Visits",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
      searchType: "number",
    },
    {
      title: "Clicks",
      dataIndex: "revClicks",
      key: "revClicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.revClicks - b?.revClicks,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
    },
    {
      title: "FB Results",
      dataIndex: "result",
      key: "result",
      render: (value) => <LookTableColumnRender title={value} />,

      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.result - b?.result,
      searchType: "number",
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: (value) => <LookTableColumnRender title={value} />,

      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.conversions - b?.conversions,
      searchType: "number",
    },
    {
      title: "Call Count",
      dataIndex: "call_count",
      key: "call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.call_count - b?.call_count,
      searchType: "number",
    },
    {
      title: "Converted Calls",
      dataIndex: "converted_calls",
      key: "converted_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.converted_calls - b?.converted_calls,
      searchType: "number",
    },
    {
      title: "Avg Handle Time",
      dataIndex: "avg_handle_time",
      key: "avg_handle_time",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Call Length",
      dataIndex: "call_length",
      key: "call_length",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Blocked Calls",
      dataIndex: "blocked_calls",
      key: "blocked_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.blocked_calls - b?.blocked_calls,
      searchType: "number",
    },
    {
      title: "Duplicate Calls",
      dataIndex: "duplicate_calls",
      key: "duplicate_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.duplicate_calls - b?.duplicate_calls,
      searchType: "number",
    },
    {
      title: "Ended Calls",
      dataIndex: "ended_calls",
      key: "ended_calls",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.ended_calls - b?.ended_calls,
      searchType: "number",
    },
    {
      title: "Connected Call Count",
      dataIndex: "connected_call_count",
      key: "connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.connected_call_count - b?.connected_call_count,
      searchType: "number",
    },
    {
      title: "Non Connected Calls",
      dataIndex: "non_connected_call_count",
      key: "non_connected_call_count",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.non_connected_call_count - b?.non_connected_call_count,
      searchType: "number",
    },
    {
      title: "RPC (Call)",
      dataIndex: "RPC_CALL",
      key: "RPC_CALL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.RPC_CALL - b?.RPC_CALL,
      searchType: "number",
    },
    {
      title: "CPR (Call)",
      dataIndex: "CPR_CALL",
      key: "CPR_CALL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 150,
      minWidth: 150,
      sorter: (a, b) => a?.CPR_CALL - b?.CPR_CALL,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },
    {
      title: "EPV",
      dataIndex: "EPV",
      key: "EPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPV - b?.EPV,
      searchType: "number",
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,

      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPC - b?.CPC,
      searchType: "number",
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPR - b?.CPR,
      searchType: "number",
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPC - b?.EPC,
      searchType: "number",
    },
    {
      title: "AD_CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP_CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,

      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },
    {
      title: "V Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.visits - b?.visits,
      searchType: "number",
    },
    {
      title: "Comments (new)",
      dataIndex: "Comments",
      key: "Comments",
      hidden: true,
      render: (value, otherProps) => {
        return (
          <>
            <LookButton
              disabled={COMMENETS_GET_ALL_MESSAGE_loading}
              className="w-auto h-auto"
              type="link"
              onClick={() =>
                /*Here we are providing row value, row Other data, date-timezone,commentsCountRefetch
               commentsCountLoading, report type, table type and current report ID */
                showDrawer({
                  value,
                  rowData: otherProps,
                  dateRangeTimezone,
                  setCountsCommentsData,
                  loading: loading,
                  reportType: COMMENTS_CAMPAIGNREPORT,
                  tableType: COMMENTS_LEADGEN,
                  reportId: otherProps?.campaignId,
                })
              }
              iconType="comment-message"
            />
            {COMMENETS_GET_ALL_MESSAGE_loading ? (
              <ActionIconRender iconType="reload" spin={COMMENETS_GET_ALL_MESSAGE_loading} />
            ) : (
              <>
                &nbsp;{value?.totalRecords} ({value?.totalUnSeen})
              </>
            )}
          </>
        );
      },
      width: 120,
      minWidth: 120,
      searchType: "string",
    },
    ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];
  let fullAndFinalMergewithComents = [];

  if (!loading) {
    finalMerge = alasql(
      "SELECT *, b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b ON a.campaignId = b.campaignId",
      [spendReport, revenueReport]
    );
    if (commentsCountsData?.length) {
      fullAndFinalMergewithComents = alasql(
        "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.campaignId = b._id",
        [finalMerge, commentsCountsData]
      );
    }
    const finalData = commentsCountsData?.length === 0 ? finalMerge : fullAndFinalMergewithComents;
    finalData?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        revenue = 0,
        visits = 0,
        revClicks = 0,
        conversions = 0,
        result = 0,
        campaign_name = "",
        campaignName = "",
        campaign_id,
        call_count = 0,
        converted_calls = 0,
        payout_amount = 0,
        avg_handle_time = 0,
        blocked_calls = 0,
        call_length = 0,
        connected_call_count = 0,
        duplicate_calls = 0,
        ended_calls = 0,
        non_connected_call_count = 0,
        daily_budget,
        revAdAccountId,
        spendAccountId,
        totalRecords = 0,
        totalSeen = 0,
        totalUnSeen = 0,
      } = element;

      const addSpendPer = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;

      const spendUpdate = (spendFB * addSpendPer).toFixed(2);
      const combinedRevenue = revenue + payout_amount;
      const budgetForSorter =
        daily_budget === null || daily_budget === undefined ? -1 : daily_budget;

      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;
      const CPC = parseFloat(spendUpdate) / parseFloat(revClicks);
      const PROFIT = parseFloat(combinedRevenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const PAYOUT = parseFloat(combinedRevenue) / parseFloat(conversions);
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const EPV = parseFloat(combinedRevenue) / parseFloat(clicksFB);
      const EPC = parseFloat(combinedRevenue) / parseFloat(revClicks);
      const AD_CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(clicksFB)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const CPR = parseFloat(spendUpdate) / parseFloat(conversions);
      const RPC_CALL = parseFloat(combinedRevenue) / parseFloat(call_count);
      const CPR_CALL = parseFloat(spendUpdate) / parseFloat(call_count);

      finalMergeData.push({
        ...element,
        key: index,
        visits,
        revenue: combinedRevenue,
        payout_amount,
        clicksFB,
        impressionsFB,
        result,
        revClicks,
        conversions,
        budgetForSorter,
        finalCampaignName:  campaign_name || campaignName,
        campaignId: campaign_id,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        PROFIT_VIEW: valueCheck(PROFIT, true),
        ROI: valueCheck(ROI),
        ROI_VIEW: valueCheck(ROI, true),
        CR: valueCheck(CR),
        CR_VIEW: valueCheck(CR, true),
        PAYOUT: valueCheck(PAYOUT),
        PAYOUT_VIEW: valueCheck(PAYOUT, true),
        CPV: valueCheck(CPV),
        CPV_VIEW: valueCheck(CPV, true),
        EPV: valueCheck(EPV),
        EPV_VIEW: valueCheck(EPV, true),
        CPC: valueCheck(CPC),
        CPC_VIEW: valueCheck(CPC, true),
        EPC: valueCheck(EPC),
        EPC_VIEW: valueCheck(EPC, true),
        AD_CTR: valueCheck(AD_CTR),
        AD_CTR_VIEW: valueCheck(AD_CTR, true),
        LP_CTR: valueCheck(LP_CTR),
        LP_CTR_VIEW: valueCheck(LP_CTR, true),
        CPM: valueCheck(CPM),
        CPM_VIEW: valueCheck(CPM, true),
        CPR: valueCheck(CPR),
        CPR_VIEW: valueCheck(CPR, true),
        call_count,
        converted_calls,
        avg_handle_time,
        blocked_calls,
        call_length,
        connected_call_count,
        duplicate_calls,
        ended_calls,
        non_connected_call_count,
        RPC_CALL: valueCheck(RPC_CALL),
        CPR_CALL: valueCheck(CPR_CALL),
        adAccountId: spendAccountId || revAdAccountId,
        Comments: { totalRecords, totalSeen, totalUnSeen },
      });
    });
  }

  const filterDataWithUnseenComment = unseenComment
    ? finalMergeData?.filter(({ Comments = {} }) => Comments.totalUnSeen != 0)
    : finalMergeData;

  const finalFilterData = filterData(filterDataWithUnseenComment).filter(
    ({ finalCampaignName = "", campaignId = "", status = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";
      const searchCheck =
        finalCampaignName?.toLowerCase()?.includes(searchValue) ||
        campaignId?.toLowerCase()?.includes(searchValue) ||
        status?.toLowerCase()?.includes(searchValue);
      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }
      return false;
    }
  );

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_revenue = 0,
    total_visits = 0,
    total_revClicks = 0,
    total_conversions = 0,
    total_result = 0,
    total_call_count = 0,
    total_converted_call = 0,
    total_avg_handled_time = 0,
    total_blocked_calls = 0,
    total_call_length = 0,
    total_connected_call_count = 0,
    total_duplicate_calls = 0,
    total_ended_calls = 0,
    total_non_connected_call = 0,
    total_payout_amount = 0,
    total_RPC_call = 0,
    total_CPR_call = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    revenue = 0,
    visits = 0,
    revClicks = 0,
    conversions = 0,
    result = 0,
    call_count = 0,
    converted_calls = 0,
    avg_handle_time = 0,
    call_length = 0,
    blocked_calls = 0,
    connected_call_count = 0,
    duplicate_calls = 0,
    ended_calls = 0,
    non_connected_call_count = 0,
    payout_amount = 0,
    RPC_CALL = 0,
    CPR_CALL = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_revenue += parseFloat(revenue || 0);
    total_visits += parseFloat(visits || 0);
    total_revClicks += parseFloat(revClicks || 0);
    total_conversions += parseFloat(conversions || 0);
    total_result += parseFloat(result || 0);
    total_call_count += parseFloat(call_count || 0);
    total_converted_call += parseFloat(converted_calls || 0);
    total_avg_handled_time += parseFloat(avg_handle_time || 0);
    total_call_length += parseFloat(call_length || 0);
    total_blocked_calls += parseFloat(blocked_calls || 0);
    total_connected_call_count += parseFloat(connected_call_count || 0);
    total_duplicate_calls += parseFloat(duplicate_calls || 0);
    total_ended_calls += parseFloat(ended_calls || 0);
    total_non_connected_call += parseFloat(non_connected_call_count || 0);
    total_payout_amount += parseFloat(payout_amount || 0);
    total_RPC_call += parseFloat(RPC_CALL || 0);
    total_CPR_call += parseFloat(CPR_CALL || 0);
  }

  const totalData = finalFilterData?.length;
  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spendFB);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spendFB)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_CPV = parseFloat(total_spendFB) / parseFloat(total_clicksFB);
  const total_EPV = parseFloat(total_revenue) / parseFloat(total_clicksFB);
  const total_CPC = parseFloat(total_spendFB) / parseFloat(total_revClicks);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_AD_CTR = (parseFloat(total_clicksFB) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_clicksFB)) * 100;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;
  const total_CPR = parseFloat(total_spendFB) / parseFloat(total_conversions);
  const avg_RPC_call = parseFloat(total_RPC_call / totalData);
  const avg_CPR_call = parseFloat(total_CPR_call / totalData);

  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_CAMPAIGN);
        updateFb360Status("ACTIVE", groupedIds);
      },
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_CAMPAIGN);
        updateFb360Status("PAUSED", groupedIds);
      },
    },
    {
      option: "dollar",
      title: "Budget Update",
      onClick: (e) => showBudgetBulkDrawer(e, "facebook-postback-adset"),
    },

    {
      option: "rules",
      title: "Rule-Engine",
      onClick: (e) => {
        const assetIds = e?.map((item) => {
          return { id: item.campaignId, name: item.name };
        });

        setRuleSetting({
          report_type: "general",
          groupBy: "campaign",
          platform: "fb",
          product: 1,
          data: assetIds,
        });
        setVisible(true);
      },
    },
    {
      option: "duplicate",
      title: "Duplicate Campaign",
      onClick: (e) => {
        showcampaignModal(e);
      },
    },
    {
      option: "eye",
      title: "Redirect Campaigns",
      onClick: (e) => {
        const t = Object.groupBy(e, (el) => el.adaccount_id);
        for (var k in t) {
          fbAdsManagerRedirect({
            reportType: "campaign",
            adaccountId: k,
            id: e?.map((el) => el.campaign_id).join(","),
          });
        }
      },
    },

    {
      option: "unordered-list",
      title: "Detailed report",
      onClick: (e) => {
        showBulkDetailDrawer(e);
      },
    },
  ];

  const showcampaignModal = (rowData = []) => {
    setCampaignModalVisible(true);
    setRowProps(rowData?.[0]);
  };
  const onCloseCampaignModal = () => {
    setCampaignModalVisible(false);
  };

  const campaignModalProps = {
    visible: campaignModalVisible,
    onCancel: onCloseCampaignModal,
    title: "Duplicate campaign",
    okokText: "Create",
    footer: false,
  };
  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setVisibleDetailDrawer(false);
  };

  const showBulkDetailDrawer = (rowProps = []) => {
    setRowBulkPropsData(rowProps);
    selectedRowsClear();
    setVisibleBulkDetailDrawer(true);
  };

  const onCloseBulkDetailDrawer = () => {
    setRowBulkPropsData([]);
    setVisibleBulkDetailDrawer(false);
  };

  const showRingbaCallDrawer = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone?.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleRingbaCallDrawer(true);
  };

  const onCloseRingbaCallDrawer = () => {
    setRowProps({});
    setVisibleRingbaCallDrawer(false);
  };

  const drawerdetailsViewPropsUpdate = {
    title: "Campaign",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const bulkDetailViewProps = {
    title: "Campaign",
    visible: visibleBulkDetailDrawer,
    onClose: onCloseBulkDetailDrawer,
    size: 1000,
  };

  const ringbaDrawerProps = {
    title: "Ringba Call Report",
    visible: visibleRingbaCallDrawer,
    onClose: onCloseRingbaCallDrawer,
    size: 1000,
  };

  const showBudgetDrawer = (e) => {
    setRowProps({ details: [e], reportType: REPORT_TYPE_CAMPAIGN, is_bulk: false, refetch });
    setVisibleBudgetDrawer(true);
  };

  const onCloseDrawerBudget = () => {
    setRowProps({});
    setVisibleBudgetDrawer(false);
  };

  const showBudgetBulkDrawer = (e) => {
    setRowProps({ details: e, reportType: REPORT_TYPE_CAMPAIGN, is_bulk: true, refetch });
    setVisibleBulkBudgetDrawer(true);
  };
  const onCloseDrawerBulkBudget = () => {
    setRowProps({});
    setVisibleBulkBudgetDrawer(false);
  };

  const modalUpdateBudgetPropsUpdate = {
    title: "Update Campaign Budget",
    okokText: "Update Budget",
    visible: visibleBudgetDrawer,
    onCancel: onCloseDrawerBudget,
    footer: false,
  };

  const modalUpdateBulkBudgetPropsUpdate = {
    title: "Update Campaign Budget",
    visible: visibleBulkBudgetDrawer,
    onCancel: onCloseDrawerBulkBudget,
    okokText: "Update Budget",
    footer: false,
  };
  const rightOptions = (
    <>
      <div className="mr-10">
        <Tooltip title="Status">
          <ActionIconRender
            className={cx({ "text-primary": unseenComment })}
            iconType="comment-message"
            style={{ fontSize: "20px" }}
            onClick={() => {
              setUnseenComment(!unseenComment);
            }}
          />
        </Tooltip>
      </div>
    </>
  );

  const summaryData = {
    spendFB: valueCheck(total_spendFB, true),
    clicksFB: valueCheck(total_clicksFB, true),
    impressionsFB: valueCheck(total_impressionsFB, true),
    revenue: valueCheck(total_revenue, true),
    visits: valueCheck(total_visits, true),
    revClicks: valueCheck(total_revClicks, true),
    conversions: valueCheck(total_conversions, true),
    result: valueCheck(total_result, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    PAYOUT: valueCheck(total_PAYOUT, true),
    CPV: valueCheck(total_CPV, true),
    EPV: valueCheck(total_EPV, true),
    CPC: valueCheck(total_CPC, true),
    EPC: valueCheck(total_EPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    CPR: valueCheck(total_CPR, true),
    call_count: total_call_count,
    converted_calls: total_converted_call,
    avg_handle_time: secondsToHMS(total_avg_handled_time),
    call_length: secondsToHMS(total_call_length),
    blocked_calls: total_blocked_calls,
    connected_call_count: total_connected_call_count,
    duplicate_calls: total_duplicate_calls,
    ended_calls: total_ended_calls,
    non_connected_call_count: total_non_connected_call,
    payout_amount: valueCheck(total_payout_amount, true),
    RPC_CALL: valueCheck(avg_RPC_call, true),
    CPR_CALL: valueCheck(avg_CPR_call, true),
  };

  const isRuleSettings = isWithoutAction
    ? {}
    : {
        ruleSetting: {
          groupBy: REPORT_TYPE_CAMPAIGN,
          reportType: REPORT_TYPE_GENERAL,
          productId: PRODUCT_ID_LEADGEN,
          platform: "fb",
        },
      };

  const tableMainProps = {
    isCustomColumn: true,
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: { subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value" },
    tableProps: {
      initialColumns,
      lastColumns,
      recordData: finalFilterData,
      summaryData,
    },
    loading,
    refetch,
    persistKey,
    defaultFilterColumns,
    defaultPagination: true,
    rightOptions,
    bulkActions: isWithoutAction ? [] : bulkActions,
  };
  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalFilterData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
    {
      isRow: true,
      contentObjects: [{ searchFilterState }],
    },
  ];
  function onClose() {
    setRuleSetting(null);
    setVisible(false);
  }
  const ruledraweProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 800,
    visible,
    onClose,
  };

  console.log({ rowPropsData });

  return (
    <div>
      <DetailView {...drawerdetailsViewPropsUpdate} {...rowPropsData} />
      <BulkDetailView {...bulkDetailViewProps} bulkProps={rowBulkPropsData} />

      <RingbaCallReport
        {...ringbaDrawerProps}
        {...rowPropsData}
        asset={LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN}
      />
      {!loading && commentDrawer()}

      <LookModal {...campaignModalProps}>
        {campaignModalVisible && (
          <DuplicateCampaigns
            {...rowPropsData}
            onCloseCampaignModal={onCloseCampaignModal}
            reportType={REPORT_TYPE_CAMPAIGN}
          />
        )}
      </LookModal>
      {visibleBudgetDrawer && (
        <LookModal {...modalUpdateBudgetPropsUpdate}>
          <UpdateCampaignBudget
            {...rowPropsData}
            refetch={refetch}
            close={onCloseDrawerBudget}
            tagType={REPORT_SAS_RSOC_FB_TAG_TYPE_CAMPAIGN}
          />
        </LookModal>
      )}

      {visibleBulkBudgetDrawer && (
        <LookModal {...modalUpdateBulkBudgetPropsUpdate}>
          <UpdateCampaignBudget
            {...rowPropsData}
            refetch={refetch}
            close={onCloseDrawerBulkBudget}
            tagType={REPORT_SAS_RSOC_FB_TAG_TYPE_CAMPAIGN}
          />
        </LookModal>
      )}
      {ruleSetting && (
        <LookDrawer {...ruledraweProps}>
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Add/Update",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: (
                  <NewRuleForm
                    close={() => onClose()}
                    // {...props}
                    productId={ruleSetting?.product}
                    ids={ruleSetting?.data?.map(({ id }) => id)}
                    platform={ruleSetting?.platform}
                    grpby={ruleSetting?.groupBy}
                    report_type={ruleSetting?.report_type}
                    idtype={"inc"}
                    // copy={update}
                  />
                ),
              },
              {
                hoverTitle: "Rule Linked Items",
                icon: () => <ActionIconRender iconType="ordered-list" />,
                component: <RuleLinkedItems close={() => onClose()} ids={ruleSetting?.data} />,
              },
            ]}
          />
        </LookDrawer>
      )}
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
