import Campaigns from "../Campaigns";
import AdSets from "../AdSets";
import Ads from "../Ads";
import { Route, useParams } from "react-router";
import ActionIconRender from "components/ActionIconRender";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

export default function AdAccountReports() {
  const params = useParams();
  const basePath = "/leadgen/reports/ad-account";

  const accountTabContent = [
    {
      id: "campaigns",
      link: `/${params?.accID}/campaigns`,
      route: `/:accID/campaigns`,
      title: "Campaigns",
      component: Campaigns,
      icon: () => <ActionIconRender iconType="notification" />,
    },
    {
      id: "adSets",
      link: `/${params?.accID}/ad-sets`,
      route: `/:accID/ad-sets`,
      title: "Ad Sets",
      component: AdSets,
      icon: () => <ActionIconRender iconType="notification" />,
    },
    {
      id: "ads",
      link: `/${params?.accID}/ads`,
      route: `/:accID/ads`,
      title: "Ads",
      component: Ads,
      icon: () => <ActionIconRender iconType="notification" />,
    },
  ];

  const headerProps = {
    title: "Reports",
    linkTabs: accountTabContent,
    basePath,
  };

  return (
    <div>
      <DashboardHeader {...headerProps} />
      {accountTabContent.map(({ link = "/", route = "/", component: Component }, index) => (
        <Route key={index} path={basePath + route} component={() => <Component />} />
      ))}
    </div>
  );
}
