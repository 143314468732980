import LookDrawer from "../../../LookDrawer";
import View from "./View";

export default function Preview(props) {
  const { visible = false, title = "", onClose } = props;
  return (
    <LookDrawer
      className="no-body-space"
      size={1100}
      visible={visible}
      onClose={onClose}
      title={title}
    >
      {visible && <View {...props} />}
    </LookDrawer>
  );
}
