import { useSelector } from "react-redux";
import {
  GET_VS_ALL_ADVERTISER,
  GET_VS_ALL_AGENCY_FOR_REDUCER,
  GET_VS_ALL_BRAND,
  GET_VS_ALL_PLATFORM,
  GET_VS_ALL_USER_DROPDOWN,
  GET_VS_LANGUAGE,
  GET_VS_USER,
  VS_URL_BUILDER_SETTINGS_DATA,
} from "shared/gql/gqlSchema/viralSparksGql";
import {
  API_STORE_VIRALSPARKS_ADVERTISE,
  API_STORE_VIRALSPARKS_AGENCY,
  API_STORE_VIRALSPARKS_ALL_USERS,
  API_STORE_VIRALSPARKS_BRAND,
  API_STORE_VIRALSPARKS_LANGUAGE,
  API_STORE_VIRALSPARKS_PLATFORM,
  API_STORE_VIRALSPARKS_URL_BUILDER_SETTINGS_DATA,
  API_STORE_VIRALSPARKS_USER,
} from "./keys";

export default function useViralSparksApis(typeKey) {
  const { user_id } = useSelector((state) => state?.userReducer?.me) || {};
  switch (typeKey) {
    case API_STORE_VIRALSPARKS_AGENCY:
      return {
        api: GET_VS_ALL_AGENCY_FOR_REDUCER,
        elementKey: "vs_agencyGetAll",
        storeKey: "vsAgency",
      };

    case API_STORE_VIRALSPARKS_LANGUAGE:
      return { api: GET_VS_LANGUAGE, elementKey: "vs_languagesGetAll", storeKey: "vsLangauges" };

    case API_STORE_VIRALSPARKS_USER:
      return {
        api: GET_VS_USER,
        elementKey: "vs_userGetById",
        storeKey: "vsLoginUser",
        variables: { id: user_id },
        objectType: true,
      };

    case API_STORE_VIRALSPARKS_URL_BUILDER_SETTINGS_DATA:
      return {
        api: VS_URL_BUILDER_SETTINGS_DATA,
        elementKey: "vs_user_settings_data",
        storeKey: "vsUserSettings",
        directData: true,
      };

    case API_STORE_VIRALSPARKS_BRAND:
      return { api: GET_VS_ALL_BRAND, elementKey: "vs_brandGetAll", storeKey: "vsBrands" };

    case API_STORE_VIRALSPARKS_PLATFORM:
      return { api: GET_VS_ALL_PLATFORM, elementKey: "vs_platformGetAll", storeKey: "vsPlatforms" };

    case API_STORE_VIRALSPARKS_ADVERTISE:
      return {
        api: GET_VS_ALL_ADVERTISER,
        elementKey: "vs_advertiserGetAll",
        storeKey: "vsAdvertiser",
      };

    case API_STORE_VIRALSPARKS_ALL_USERS:
      return {
        api: GET_VS_ALL_USER_DROPDOWN,
        elementKey: "vs_userGetAll",
        storeKey: "vs_users",
      };

    default:
      return false;
  }
}
