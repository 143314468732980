import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache, HttpLink } from "@apollo/client";
import { useSelector } from "react-redux";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

const cache = new InMemoryCache();

export const defaultOptions = {
  watchQuery: { errorPolicy: "ignore" },
  query: { errorPolicy: "all" },
};

export default function ApolloProviderWraper({ children }) {
  const selector = useSelector((state) => state?.userReducer?.user?.jwtToken);
  const pseudoUser = useSelector((state) => state?.userReducer.me?.["pseudo-user"]);
  const isPseudoUser = useSelector((state) => state?.userReducer.me?.isPseudoUser);
  const selectedWorkspace = useSelector((state) => state?.userSettings?.selectedWorkspace);
  const authorized = true;
  const pseudoUserHeader = pseudoUser && isPseudoUser ? { "pseudo-user": pseudoUser } : {};
  let workspaceHeader = {};
  if (selectedWorkspace) {
    workspaceHeader = { "x-auth-workspace": selectedWorkspace };
  }

  const authLink = new ApolloLink((operation, forward) => {
    const customHeaders = operation.getContext().hasOwnProperty("headers")
      ? operation.getContext().headers
      : {};
    if (authorized) {
      operation.setContext({
        headers: {
          Authorization: selector,
          "app-version": process.env.REACT_APP_VERSION,
          "request-source": "web",
          ...pseudoUserHeader,
          ...workspaceHeader,
          ...customHeaders,
        },
      });
    }
    return forward(operation);
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_GRAPHQL_SUBSCRIPTION_ENDPOINT_URL,
      connectionParams: () => {
        return {
          Authorization: selector,
        };
      },
    })
  );
  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    ApolloLink.from([authLink, httpLink])
  );
  const client = new ApolloClient({
    link: splitLink,
    cache: cache,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
