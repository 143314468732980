import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3 } from "actions/actionTypes";
import LookTable from "components/LookTable";
import React, { useEffect } from "react";
import { REPORT_TYPE_LIVE } from "shared/SharedKeys";
import { useCreativeReportingContext } from "../../Context";

function PlatformReports({ platformColumns }) {
  const {
    finalMerge,
    summaryData,
    setSpendPlatformDetailedReport,
    setRevPlatformDetailedReport,
    refetch_fb_platform_rev,
    refetch_spend_fb_platform,
    refetch_tiktok_platform_rev,
    refetch_spend_tiktok_platform,
    loading,
    fbRevVariables,
    fbSpendVariables,
    serviceType,
    revPlatformTiktokVariables,
    spendDetailedVariables,
    isLeadgen,
    revenueTiktokPlatformFetch,
    spendTiktokPlatformFetch,
    revenueFbPlatformFetch,
    spendFbPlatformFetch,
    tiktokVariables,
    filters,
    setSpendDetailedReport,
    setRevDetailedReport,
    isGlobalReport,
    group_id,
  } = useCreativeReportingContext();
  useEffect(() => {
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    setSpendPlatformDetailedReport([]);
    setRevPlatformDetailedReport([]);
    if (filters?.length === 0) {
      revenueTiktokPlatformFetch({
        variables: {
          ...revPlatformTiktokVariables,
          groupId: group_id,
          productId: serviceType,
        },
      });
      spendTiktokPlatformFetch({ variables: spendDetailedVariables });
      if (isLeadgen) {
        revenueFbPlatformFetch({ variables: fbRevVariables });
      }
      spendFbPlatformFetch({ variables: fbSpendVariables });
    } else {
      const spendFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.spendApikey]: data.id } : null
      );
      const revFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.revApikey]: data.id } : null
      );
      revenueTiktokPlatformFetch({
        variables: {
          ...revPlatformTiktokVariables,
          groupId: group_id,
          productId: serviceType,
          ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
          ...Object.assign({}, ...revFilterVariables),
        },
      });
      spendTiktokPlatformFetch({
        variables: { ...fbSpendVariables, ...Object.assign({}, ...spendFilterVariables) },
      });
      if (isLeadgen) {
        revenueFbPlatformFetch({
          variables: { ...fbRevVariables, ...Object.assign({}, ...spendFilterVariables) },
        });
      }
      spendFbPlatformFetch({
        variables: { ...fbSpendVariables, ...Object.assign({}, ...spendFilterVariables) },
      });
    }
  }, []);

  const refetch_platform = () => {
    setSpendPlatformDetailedReport({ fb: [], tiktok: [] });
    setRevPlatformDetailedReport({ fb: [], tiktok: [] });
    if (isLeadgen) {
      refetch_fb_platform_rev();
    }
    refetch_spend_fb_platform();
    refetch_tiktok_platform_rev();
    refetch_spend_tiktok_platform();
  };

  const tablePlatformProps = {
    initialColumns: platformColumns,
    recordData: finalMerge,
    summaryData,
  };
  return (
    <div>
      <LookTable
        tableProps={tablePlatformProps}
        refetch={refetch_platform}
        loading={loading}
        defaultPagination
        persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3}
        sizes={{ reduceExtraScrollHeightBy: 131 }} //used to reduce table height because summary card was taking the extra space at top
      />
    </div>
  );
}

export default PlatformReports;
