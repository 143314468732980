import { useState } from "react";
import MainSidebar from "./MainSidebar";
import ChildSidebar from "./ChildSidebar";
import { Layout } from "antd";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import { useAwsAuth } from "shared/AwsAuth";
import useDashboardRoutes from "shared/useDashboardRoutes";

const { Content } = Layout;

function DashboardLayout(props) {
  const { DashboardRoutesPath } = useDashboardRoutes();
  const { refreshTokenIfNeeded } = useAwsAuth();

  refreshTokenIfNeeded();

  const location = useLocation();
  const { children } = props;
  const [isChildSidebar, setIsChildSidebar] = useState(false);

  function toggleChildSidebar() {
    setIsChildSidebar((previous) => !previous);

    isChildSidebar
      ? document.body.classList.add("isChildSideBar")
      : document.body.classList.remove("isChildSideBar");
  }

  const subMenus = DashboardRoutesPath?.filter(({ link }) => location.pathname.includes(link));

  const { subMenu = [], name = "", icon, link } = subMenus[0] || {};

  const childSidebarSize = 200;
  const childSidebarHaldlerProps = { childSidebarSize, isChildSidebar, toggleChildSidebar };
  const childSidebarProps = {
    childSidebarSize,
    isChildSidebar,
    title: name,
    icon,
    mainLink: link,
    subMenus: subMenu,
  };

  return (
    <>
      <Layout>
        <MainSidebar mainMenus={DashboardRoutesPath} {...childSidebarHaldlerProps} />
        <ChildSidebar {...childSidebarProps} />
        <Layout>
          <Content className={cx("dashboard-content")}>{children}</Content>
        </Layout>
      </Layout>
    </>
  );
}
export default DashboardLayout;
