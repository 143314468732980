import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import LookTable from "components/LookTable";
import React, { useEffect } from "react";
import { REPORT_TYPE_LIVE } from "shared/SharedKeys";
import { useCreativeReportingContext } from "../../Context";

function AdReports({ adColumns }) {
  const {
    finalMerge,
    summaryData,
    loading,
    setSpendDetailedReport,
    setRevDetailedReport,
    refetch_tiktok_rev,
    refetch_spend_fb,
    refetch_fb_rev,
    refetch_spend_tiktok,
    isFbReport,
    isSAS,
    isVS,
    tiktokVariables,
    revenueTiktokFetch,
    revenueFbFetch,
    spendTiktokFetch,
    spendFbFetch,
    fbRevVariables,
    fbSpendVariables,
    isLeadgen,
    spendDetailedVariables,
    filters,
    isGlobalReport,

    revenueVSLiveAdFetch,
    loading_revenueVSLiveAdFetch,
    refetch_revenueVSLiveAdFetch,
  } = useCreativeReportingContext();
  const tableProps = {
    initialColumns: adColumns,
    recordData: finalMerge,
    summaryData,
  };
  const refetch_fb = () => {
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    if (isSAS) {
      refetch_tiktok_rev();
      refetch_spend_fb();
    }
    if (isVS) {
      refetch_revenueVSLiveAdFetch();
      refetch_spend_fb();
    } else {
      refetch_fb_rev();
      refetch_spend_fb();
    }
  };

  const refetch_tiktok = () => {
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    if (isSAS) {
      refetch_tiktok_rev();
      refetch_spend_tiktok();
    } else {
      refetch_fb_rev();
      refetch_spend_tiktok();
    }
  };

  useEffect(() => {
    if (filters?.length > 0) {
      setSpendDetailedReport([]);
      setRevDetailedReport([]);
      const spendFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.spendApikey]: data.id } : null
      );
      const revFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.revApikey]: data.id } : null
      );
      const finalVarSpend = {
        ...spendDetailedVariables,
        ...Object.assign({}, ...spendFilterVariables),
      };
      const finalVarRev = { ...tiktokVariables, ...Object.assign({}, ...revFilterVariables) };
      if (isFbReport) {
        if (isSAS) {
          revenueTiktokFetch({
            variables: {
              ...tiktokVariables,
              ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
              ...Object.assign({}, ...revFilterVariables),
            },
          });
        } else if (isVS) {
          revenueVSLiveAdFetch({
            variables: {
              ...fbRevVariables,
              group_by: fbRevVariables?.groupBy,
              is_forecast: fbRevVariables?.isForecast,
              ...Object.assign({}, ...spendFilterVariables),
            },
          });
        } else {
          revenueFbFetch({
            variables: {
              ...fbRevVariables,
              ...Object.assign({}, ...spendFilterVariables),
            },
          });
        }
        spendFbFetch({
          variables: {
            ...fbSpendVariables,
            ...Object.assign({}, ...spendFilterVariables),
          },
        });
      } else {
        if (isLeadgen) {
          revenueFbFetch({
            variables: fbRevVariables,
            ...Object.assign({}, ...revFilterVariables),
          });
        } else {
          spendTiktokFetch({ variables: finalVarSpend });
        }
        revenueTiktokFetch({ variables: finalVarRev });
      }
    } else {
      if (isFbReport) {
        if (isSAS) {
          revenueTiktokFetch({ variables: tiktokVariables });
        } else if (isVS) {
          revenueVSLiveAdFetch({
            variables: {
              ...fbRevVariables,
              group_by: fbRevVariables?.groupBy,
              is_forecast: fbRevVariables?.isForecast,
            },
          });
        } else {
          revenueFbFetch({ variables: fbRevVariables });
        }
        spendFbFetch({ variables: fbSpendVariables });
      } else {
        if (isLeadgen) {
          revenueFbFetch({ variables: fbRevVariables });
        } else {
          revenueTiktokFetch({
            variables: tiktokVariables,
          });
        }
        spendTiktokFetch({ variables: spendDetailedVariables });
      }
    }
  }, []);
  return (
    <div>
      <LookTable
        tableProps={tableProps}
        refetch={isFbReport ? refetch_fb : refetch_tiktok}
        loading={loading}
        defaultPagination
        persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
        sizes={{ reduceExtraScrollHeightBy: 131 }} //used to reduce table height because summary card was taking the extra space at top
      />
    </div>
  );
}

export default AdReports;
