import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import {
  GQL_GET_FB_RECOMMENDED_CREATIVES,
  GQL_GET_TIKTOK_RECOMMENDED_CREATIVES,
} from "shared/gql/gqlSchema/creativeGql";

function useRecommendedHooks({
  queryVars,
  setCreatives = () => {},
  setTotalCount = () => {},
  creativeType,
}) {
  const [
    fetchTiktokCreatives,
    { loading: creatives_tiktok_loading, refetch: creatives_fb_refetch },
  ] = useCustomLazyQuery(GQL_GET_TIKTOK_RECOMMENDED_CREATIVES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktok_categoryRecommendedCreatives?.data;
      setTotalCount(e?.tiktok_categoryRecommendedCreatives?.pageInfo?.totalRecords);
      if (element) {
        setCreatives((prev) => [
          ...prev,
          ...element?.map((data) => {
            const { video_url, ct_video_id = "", video_thumb_url = "" } = data;
            return {
              id: ct_video_id,
              currentThumb: { r2Url: video_thumb_url },
              r2Url: video_url,
            };
          }),
        ]);

        setTotalCount(e?.pageInfo?.totalCount);
      }
    },
    variables: { ...queryVars },
  });
  const [fetchFbCreatives, { loading: creatives_fb_loading, refetch: creatives_refetch }] =
    useCustomLazyQuery(GQL_GET_FB_RECOMMENDED_CREATIVES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.fb360_categoryRecommendedCreative?.data;
        if (element) {
          setTotalCount(e?.pageInfo?.totalCount);
          setCreatives((prev) => [
            ...prev,
            ...element?.map((data) => {
              const { creative_url_object = {} } = data;
              const creatives =
                creativeType === CREATIVE_TYPE_VIDEO
                  ? creative_url_object?.videos?.[0]?.urls?.[0]
                  : creative_url_object?.images?.[0]?.urls?.[0];
              return {
                id: creatives?.id,
                currentThumb: { r2Url: creatives?.thumbUrl },
                r2Url: creatives?.r2Url,
              };
            }),
          ]);
        }
      },
      variables: { ...queryVars },
    });
  const loading = creatives_fb_loading || creatives_tiktok_loading;

  return { creatives_loading: loading, creatives_refetch, fetchFbCreatives, fetchTiktokCreatives };
}

export default useRecommendedHooks;
