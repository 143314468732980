import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Pagination,
  Row,
  Segmented,
  Select,
  Typography,
} from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";
import LookDrawer from "components/LookDrawer";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { PlusOutlined } from "@ant-design/icons";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import cx from "cx";
import { Formik } from "formik";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { CreativeSelectionKey_Body } from "pages/dashboardPages/VS/VSContent/CreativesDrawer/AdGroup";
import { useEffect, useRef, useState } from "react";
import { openNotification } from "utils";
import * as yup from "yup";
import {
  MEDIA_ADD_TEXT,
  MEDIA_DELETE_TEXT,
  MEDIA_GET_TEXT,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { useLookModal } from "components/LookModal";
import TagModal from "components/Launch/Creatives/CreativeUpload/TagModal";
import { usePermissionCheck } from "hooks";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import DeleteModal from "../DeleteModal";

function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    btnText = "Add",
    loading = false,
    label = "",
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="body"
                label={label}
                type="textarea"
                className="look-form-input"
                placeholder={"Enter the " + label?.toLowerCase()}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default function TextUpload(props) {
  const {
    creativeType = "",
    requestData = {},
    textIndex,
    setTexts = () => {},
    texts,
    textName,
    isCreativeBuilder = false,
    isMediaLibrary = false,
    isMediaLibraryMain = false,
    refetchTrigger = "",
    dateRange = [],
    createdByMe = false,
    isSearch,
    searchItem,
    searchMode = "",
    setIsSearch = () => {},
    handleRefetch = () => {},
    currentPage,
    pageSize,
    setTotalCount = () => {},
    refetchFlag,
  } = props;

  const {
    // persistKey,
    productId = "0",
    categoryId = "0",
    categoryName = "generic",
  } = requestData;

  const label = "Text";

  const [recordData, setRecordData] = useState([]);
  const [showAddText, setshowAddText] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [selectedText, setSelectedText] = useState(texts?.[textIndex]?.map((text) => text?.id));
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const { Paragraph } = Typography;

  const { Search } = Input;

  const queryVars = !isMediaLibraryMain
    ? {
        productId,
        categoryId,
        page: currentPage,
        size: pageSize,
        createdByMe,
      }
    : {
        page: currentPage,
        size: pageSize,
        createdByMe,
      };
  const variables = {
    productId,
    categoryId,
    categoryName,
  };

  const [fetchText, { data, loading: loading_fetch, refetch }] = useCustomLazyQuery(
    MEDIA_GET_TEXT,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ctGetTexts?.data;
        if (element) {
          setRecordData(element);
          setTotalCount(e?.ctGetTexts?.pageInfo?.totalRecords);
          setIsSearch(false);
          // setSearchItem();
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setRecordData([]);
        setIsSearch(false);
      },
    }
  );

  const [ctAddText, { loading: loading_update }] = useCustomMutation(MEDIA_ADD_TEXT, {
    onCompleted: (e) => {
      const element = e?.ctAddText?.data;
      setRecordData([...element, ...recordData]);
      setshowAddText(false);
      resetFormRef.current();
      // refetch();
      openNotification({ type: "success", message: label + " added" });
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const bulkActions = [
    {
      option: "tagSwitch",
      title: "Edit tags",
      onClick: (e) => {
        openTagModal();

        setDeleteModalPropsData(e?.map((item) => ({ id: item, tags: data?.tags || [] })));
      },
    },
  ];
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const onSubmitDeleteModal = () => {
    if (isMediaLibraryMain) {
      deleteCall({
        variables: {
          _ids: selectedCategory?.id,
          categoryId: selectedCategory?.productCategoryId,
          productId: selectedCategory?.productId,
          forceDelete: false,
        },
      });
    } else {
      deleteCall({
        variables: {
          _ids: deleteModalPropsData,
          categoryId,
          productId,
        },
      });
    }
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setSelectedCategory({});
    setDeleteModalVisible(false);
  };

  const resetFormRef = useRef(() => {});
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    ctAddText({
      variables: { ...variables, texts: values?.body },
    });
  }
  const initialValues = { body: "" };
  const validationSchema = yup.object({
    body: yup.string().required().label(label),
  });
  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    onClose: () => {
      setshowAddText(false);
      setSelectedText([]);
    },
    label,
  };

  const initialColumns = [
    {
      title: "Body",
      dataIndex: "body",
      key: "body",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
  ];

  const renderData = [];

  recordData?.forEach((element, index) => {
    renderData.push({ ...element, key: index });
  });

  const tableMainProps = {
    refetch,
    loading: loading_fetch,
    // persistKey,
    tableProps: { initialColumns, recordData: renderData },
    filterProps: { persistSearch: false },
  };
  const debugContent = [
    { isRow: true, contentObjects: [{ recordData }, { data }, { renderData }] },
  ];

  const [deleteCall, { loading: deleteLoader }] = useCustomMutation(MEDIA_DELETE_TEXT, {
    onCompleted(e) {
      const element = e?.ctDeleteText;
      setDeleteModalVisible(false);
      if (element?.status === "SUCCESS") {
        refetch();
        openNotification({ type: "success", message: "Record Deleted Success" });
        setSelectedText([]);
      } else {
        openNotification({
          type: "error",
          message: "Record Deleted Not Success",
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Deleted Not Success",
      });
    },
  });

  const bulkActionProps = {
    selectedRows: selectedText,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };

  const deleteModalProps = {
    title: "Delete Text",
    message: "Do you really want to delete this text ?",
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
    categoriesList: deleteModalPropsData,
    setSelectedCategory: setSelectedCategory,
    customView: isMediaLibraryMain ? true : false,
    selectedCategory: selectedCategory,
  };
  const [visibleBodyDrawer, setVisibleBodyDrawer] = useState(false);
  const onCloseBodyDrawer = () => {
    setVisibleBodyDrawer(false);
  };
  const drawerAdsBody = {
    title: "Add Body",
    icon: () => <ActionIconRender className="mr-10" iconType="font-colors" />,
    size: 1200,
    visible: visibleBodyDrawer,
    onClose: onCloseBodyDrawer,
  };

  useEffect(() => {
    fetchText({
      variables: {
        ...(isMediaLibraryMain && { isGlobal: true }),

        ...queryVars,
      },
    });
  }, []);
  useEffect(() => {
    fetchText({
      variables: {
        ...(isMediaLibraryMain && { isGlobal: true }),
        ...queryVars,
        toDate: dateRange[1],
        fromDate: dateRange[0],
      },
    });
  }, [refetchTrigger, dateRange, createdByMe, refetchFlag, pageSize, currentPage]);

  useEffect(() => {
    if (isSearch) {
      fetchText({
        variables: {
          ...queryVars,
          ...(searchMode !== "tags" ? { search: searchItem } : { tags: searchItem }),
        },
      });
    }
  }, [searchMode, isSearch, searchItem]);

  useEffect(() => {
    // if (creativeType === CREATIVE_TYPE_TEXT) {
    //   // const updatedText=
    //   const updatedData = recordData
    //     ?.filter((data) => selectedText?.includes?.(data?.id))
    //     ?.map((text, index) => ({
    //       uniqueId: uniqueId,
    //       uniqueName: uniqueName,
    //       id: text?.id,
    //       text: text?.text,
    //     }));
    //   setDataBus(updatedData);
    // }
    if (!isMediaLibrary && recordData?.length > 0) {
      setTexts((text) => {
        const updatedMedia = [...text];
        updatedMedia[textIndex] = [
          ...recordData
            ?.filter((data) => selectedText?.includes?.(data?.id))
            ?.map((text, index) => ({
              id: text?.id,
              text: text?.name,
              urlData: text?.text,
              type: creativeType.toLowerCase(),
              name: textName,
            })),
        ];
        return updatedMedia;
      });
    }
  }, [selectedText]);

  function selectedRowsClear() {
    setDeleteModalPropsData([]);
    setSelectedText([]);
    setSelectedText([]);
    setTags([]);
  }

  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });

  const tagDataExtractor = (id) => {
    const tagsArray = renderData?.filter((data) => data?.id === id)?.[0]?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };

  const eventBublingPrevention = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      {visilbleTagModal &&
        tagModal(
          <TagModal
            variables={{
              productId,
              categoryId,
              categoryName,
            }}
            files={deleteModalPropsData}
            creatives_refetch={refetch}
            setTags={setTags}
            tags={tags}
            tagsData={tagsData}
            setTagsData={setTagsData}
            closeTagModal={closeTagModal}
            selectedRowsClear={selectedRowsClear}
            creativeType="TEXT"
          />
        )}

      <div className={cx("creative-text-list-view p-20 pb-0")}>
        <>
          <LookDrawer {...drawerAdsBody}>{visibleBodyDrawer}</LookDrawer>
          {(isMediaLibrary || isMediaLibraryMain) && (
            <>
              <LookTableBulkAction {...bulkActionProps} />
              {deleteModalVisible && <DeleteModal {...deleteModalProps} />}
            </>
          )}
          {/* {!isMediaLibraryMain && (
            <div className="mt-20">
              {showAddText ? (
                <MainForm {...formProps} />
              ) : (
                <div
                  className={cx("look-radio-ad", "d-flex")}
                  style={{ alignItems: "center", color: "#696969" }}
                  onClick={() => setshowAddText(true)}
                >
                  <div className="mr-10">
                    <PlusOutlined />
                  </div>
                  <div>Click here to add a new text...</div>
                </div>
              )}
            </div>
          )} */}

          <Checkbox.Group
            onChange={(e) => {
              setSelectedText(e);
            }}
            value={selectedText}
            name={CreativeSelectionKey_Body}
            style={{ width: "100%" }}
          >
            {loading_fetch ? (
              <Loader center elementCenter title="Loading Texts..." />
            ) : renderData.length === 0 ? (
              <div className="text-center margin-0-auto">
                <div style={{ transform: "translateX(20px)" }}>
                  <ActionIconRender iconType="tablePlaceholder" />
                </div>
                <p className="no-text">
                  <b>No Texts Found...</b>
                </p>
              </div>
            ) : (
              renderData?.map((data, index) => (
                <div
                  className={cx("look-radio-ad border-0 flex justify-content-between", {
                    active: selectedText?.includes(data?.id),
                  })}
                >
                  <Checkbox
                    disabled={
                      isCreativeBuilder &&
                      selectedText?.length === 1 &&
                      selectedText?.[0] === data?.id
                    }
                    value={data?.id}
                  >
                    <Paragraph
                      style={{ color: "#e8e8e8" }}
                      ellipsis={{
                        rows: 3,
                        expandable: true,
                        symbol: "more",
                        onExpand: (event) => eventBublingPrevention(event),
                      }}
                    >
                      {data?.name}
                    </Paragraph>
                  </Checkbox>
                  <div className="flex">
                    <ActionIconRender
                      style={{ marginRight: "8px", color: "white" }}
                      iconType="tagSwitch"
                      onClick={() => {
                        tagDataExtractor(data?.id);
                        openTagModal();
                        setDeleteModalPropsData([{ id: data?.id, tags: data?.tags || [] }]);
                      }}
                    />
                    <ActionIconRender
                      style={{ marginRight: "8px", color: "white" }}
                      iconType="delete"
                      onClick={() => {
                        const updatedArray = data?.categoryId?.map((element) => ({
                          ...element,
                          id: data?.id,
                        }));
                        isMediaLibraryMain
                          ? onOpenDeleteModal(updatedArray)
                          : onOpenDeleteModal(data?.id);
                      }}
                    />
                  </div>
                </div>
              ))
            )}
          </Checkbox.Group>
        </>
      </div>
      <DebugPre content={debugContent} />
    </div>
  );
}
