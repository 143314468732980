import Routes from "./routes";
import RemberLogin from "./shared/RemberLogin";
import { GlobalStyle } from "./shared/style";
import ApolloProviderWraper from "./shared/ApolloProviderWraper";
import { useEffect, useState } from "react";
import AwsAuth from "./shared/AwsAuth";
import "moment-timezone";
import { ApiToStoreContextProvider } from "hooks/useApiToStore/context";
import {
  LookTableParentContextProvider,
  LookTableParentRuleEngineContextProvider,
} from "hooks/lookTableParentContext";

function App() {
  const [stopRender, setStopRender] = useState(true);

  useEffect(() => {
    let os = "";
    const BODY_CLASS_LIST = document?.body?.classList;
    const { userAgent = "" } = window?.navigator;
    if (userAgent.indexOf("Win") !== -1) os = "win-os";
    if (userAgent.indexOf("Mac") !== -1) os = "mac-os";
    if (userAgent.indexOf("X11") !== -1) os = "unix-os";
    if (userAgent.indexOf("Linux") !== -1) os = "linux-os";
    BODY_CLASS_LIST.add(os);

    if (process?.env?.REACT_APP_ENVIRONMENT === "production") {
      if (window?.location?.protocol === "http:") {
        window.location.href = window.location.href.replace("http:", "https:");
      } else {
        setStopRender(false);
      }
    }
  }, []);

  if (process?.env?.REACT_APP_ENVIRONMENT === "production" && stopRender) {
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <div className="App">
        <ApolloProviderWraper>
          <AwsAuth>
            <ApiToStoreContextProvider>
              <LookTableParentContextProvider>
                <LookTableParentRuleEngineContextProvider>
                  <RemberLogin />
                  {/* <ThemeToggle /> */}
                  <Routes />
                </LookTableParentRuleEngineContextProvider>
              </LookTableParentContextProvider>
            </ApiToStoreContextProvider>
          </AwsAuth>
        </ApolloProviderWraper>
      </div>
    </>
  );
}

export default App;
