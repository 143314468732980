import { useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import moment from "moment";
import alasql from "alasql";
import cx from "classnames";
import { useSelector } from "react-redux";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { GET_REPORT_PER_USER, GET_REVENUE_PER_USER } from "shared/gql/gqlSchema/leadgenGql";
import { useCustomQuery } from "hooks/apolloClientHooks";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";

export default function PerformanceReport(props) {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const {
    user_id: userId = "",
    workspaceId,
    timezone: timeZoneProps,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  } = props;
  const dateFormat = "YYYY-MM-DD";
  const [spendReport, setSpendReport] = useState([]);
  const [revenueReport, setRevenueReport] = useState([]);

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });

  const defaultDate = [moment().subtract(7, "d").format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const comonPayloadProps = {
    from: rangeDates[0],
    to: rangeDates[1],
    timezone: timeZone.value,
    workspaceId,
    userId,
  };

  const {
    loading: loading_GET_REPORT_PER_USER,
    error: error_GET_REPORT_PER_USER,
    refetch: refetch_GET_REPORT_PER_USER,
  } = useCustomQuery(GET_REPORT_PER_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.reportPerUser;
      if (element) {
        setSpendReport(element);
      }
    },
    variables: { ...comonPayloadProps },
  });

  const {
    loading: loading_GET_REVENUE_PER_USER,
    error: error_GET_REVENUE_PER_USER,
    refetch: refetch_GET_REVENUE_PER_USER,
  } = useCustomQuery(GET_REVENUE_PER_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.revenuePerUser;
      if (element) {
        setRevenueReport(element);
      }
    },
    variables: { ...comonPayloadProps },
  });

  const refetch = () => {
    refetch_GET_REPORT_PER_USER();
    refetch_GET_REVENUE_PER_USER();
  };

  const loading = loading_GET_REPORT_PER_USER || loading_GET_REVENUE_PER_USER;

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const initialColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value, { PROFIT }) => {
        return <LookTableColumnRender title={value} isProfit={PROFIT} />;
      },
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Visits",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
    },
    {
      title: "Clicks",
      dataIndex: "clicksVL",
      key: "clicksVL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.clicksVL - b?.clicksVL,
    },
    {
      title: "FB Results",
      dataIndex: "leadsFB",
      key: "leadsFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.leadsFB - b?.leadsFB,
      inChart: true,
    },
    {
      title: "Conversions",
      dataIndex: "conversionsVL",
      key: "conversionsVL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.conversionsVL - b?.conversionsVL,
    },
    {
      title: "Revenue",
      dataIndex: "revenueVL",
      key: "revenueVL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.revenueVL - b?.revenueVL,
      inChart: true,
    },
    {
      title: "X-Revenue",
      dataIndex: "xRevenue",
      key: "xRevenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.xRevenue - b?.xRevenue,
    },
    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
      inChart: true,
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      // render: (value) => <LookTableColumnRender title={value} />,
      render: (value, otherProps) => {
        // const profit = `$${value.replace("-", "")}`;
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      inChart: true,
    },
    {
      title: "ROI",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.ROI - b?.ROI,
      inChart: true,
    },
    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.CR - b?.CR,
    },
    {
      title: "PAYOUT",
      dataIndex: "PAYOUT",
      key: "PAYOUT",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.PAYOUT - b?.PAYOUT,
    },
    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.CPV - b?.CPV,
    },
    {
      title: "EPV",
      dataIndex: "EPV",
      key: "EPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.EPV - b?.EPV,
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.CPC - b?.CPC,
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.CPR - b?.CPR,
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.EPC - b?.EPC,
    },
    {
      title: "AD_CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
    },
    {
      title: "LP_CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.CPM - b?.CPM,
    },
    {
      title: "V Visits",
      dataIndex: "visitsVL",
      key: "visitsVL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.visitsVL - b?.visitsVL,
    },
    {
      title: "Days",
      dataIndex: "Days",
      key: "Days",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Start",
      dataIndex: "Start",
      key: "Start",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
  ];

  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    finalMerge = alasql(
      "SELECT * FROM ? as a FULL OUTER JOIN ? as b  ON a.userId = b.userId and a.date = b.date",
      [revenueReport, spendReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        revenueVL = 0,
        visitsVL = 0,
        clicksVL = 0,
        conversionsVL = 0,
        leadsFB = 0,
        user,
        xRevenue = 0,
      } = element;
      const addSpendPer = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;
      const spendUpdate = (spendFB * addSpendPer).toFixed(2);

      const updatedRevenu = parseFloat(revenueVL) + parseFloat(xRevenue || 0);

      const PROFIT = parseFloat(updatedRevenu) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CR = (parseFloat(conversionsVL) / parseFloat(clicksVL)) * 100;
      const PAYOUT = parseFloat(updatedRevenu) / parseFloat(conversionsVL);
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const EPV = parseFloat(updatedRevenu) / parseFloat(clicksFB);
      const CPC = parseFloat(spendUpdate) / parseFloat(clicksVL);
      const EPC = parseFloat(updatedRevenu) / parseFloat(clicksVL);
      const AD_CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(clicksVL) / parseFloat(clicksFB)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const CPR = parseFloat(spendUpdate) / parseFloat(conversionsVL);

      finalMergeData.push({
        ...element,
        key: index,
        revenueVL: updatedRevenu,
        visitsVL,
        clicksFB,
        impressionsFB,
        leadsFB,
        clicksVL,
        conversionsVL,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        PAYOUT: valueCheck(PAYOUT),
        CPV: valueCheck(CPV),
        EPV: valueCheck(EPV),
        CPC: valueCheck(CPC),
        EPC: valueCheck(EPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        CPR: valueCheck(CPR),
        username: user?.name,
      });
    });
  }
  const finalFilterData = finalMergeData.filter(({ username = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (username?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_revenueVL = 0,
    total_visitsVL = 0,
    total_clicksVL = 0,
    total_conversionsVL = 0,
    total_leadsFB = 0,
    total_xRevenue = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    revenueVL = 0,
    visitsVL = 0,
    clicksVL = 0,
    conversionsVL = 0,
    leadsFB = 0,
    xRevenue = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_revenueVL += parseFloat(revenueVL || 0);
    total_visitsVL += parseFloat(visitsVL || 0);
    total_clicksVL += parseFloat(clicksVL || 0);
    total_conversionsVL += parseFloat(conversionsVL || 0);
    total_leadsFB += parseFloat(leadsFB || 0);
    total_xRevenue += parseFloat(xRevenue || 0);
  }

  const total_PROFIT = parseFloat(total_revenueVL) - parseFloat(total_spendFB);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spendFB)) * 100 || 0;
  const total_CR = (parseFloat(total_conversionsVL) / parseFloat(total_clicksVL)) * 100;
  const total_PAYOUT = parseFloat(total_revenueVL) / parseFloat(total_conversionsVL);
  const total_CPV = parseFloat(total_spendFB) / parseFloat(total_clicksFB);
  const total_EPV = parseFloat(total_revenueVL) / parseFloat(total_clicksFB);
  const total_CPC = parseFloat(total_spendFB) / parseFloat(total_clicksVL);
  const total_EPC = parseFloat(total_revenueVL) / parseFloat(total_clicksVL);
  const total_AD_CTR = (parseFloat(total_clicksFB) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_clicksVL) / parseFloat(total_clicksFB)) * 100;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;
  const total_CPR = parseFloat(total_spendFB) / parseFloat(total_conversionsVL);

  const summaryData = {
    spendFB: valueCheck(total_spendFB, true),
    clicksFB: valueCheck(total_clicksFB, true),
    impressionsFB: valueCheck(total_impressionsFB, true),
    revenueVL: valueCheck(total_revenueVL, true),
    xRevenue: valueCheck(total_xRevenue, true),
    visitsVL: valueCheck(total_visitsVL, true),
    clicksVL: valueCheck(total_clicksVL, true),
    conversionsVL: valueCheck(total_conversionsVL, true),
    leadsFB: valueCheck(total_leadsFB, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    PAYOUT: valueCheck(total_PAYOUT, true),
    CPV: valueCheck(total_CPV, true),
    EPV: valueCheck(total_EPV, true),
    CPC: valueCheck(total_CPC, true),
    EPC: valueCheck(total_EPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    CPR: valueCheck(total_CPR, true),
  };

  const tableMainProps = {
    exportCSV: true,
    persistKey,
    isLineChart: true,
    isRowSelection: false,
    isDateRange: true,
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },

    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
