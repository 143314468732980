import { SettingOutlined } from "@ant-design/icons";
import { TIKTOK_USER } from "shared/UserPermission/userPermissionList";
import { CustomeIconWrapper } from "..";
import { TikTokIcon } from "../../../components/svgComponents";
import { UserOutlined, TeamOutlined, PicLeftOutlined } from "@ant-design/icons";

const tiktokPath = [
  {
    name: "TikTok",
    link: "/tiktok",
    icon: () => (
      <CustomeIconWrapper>
        <TikTokIcon />
      </CustomeIconWrapper>
    ),
    subMenu: [
      // {
      //   subMenuName: "Users",
      //   subMenuLink: "/users",
      //   subMenuIcon: UserOutlined,
      //   permission: TIKTOK_USER,
      // },
      {
        subMenuName: "Accounts",
        subMenuLink: "/accounts",
        subMenuIcon: TeamOutlined,
        permission: TIKTOK_USER,
      },
      {
        subMenuName: "Reports",
        subMenuLink: "/tiktok-reports",
        subMenuIcon: TeamOutlined,
        permission: TIKTOK_USER,
      },
      {
        subMenuName: "BC Members",
        subMenuLink: "/bc-members",
        subMenuIcon: TeamOutlined,
        permission: TIKTOK_USER,
      },
      {
        subMenuName: "Category",
        subMenuLink: "/category",
        subMenuIcon: PicLeftOutlined,
        permission: TIKTOK_USER,
      },
      {
        subMenuName: "Landing URLs",
        subMenuLink: "/landing-urls",
        subMenuIcon: PicLeftOutlined,
        permission: TIKTOK_USER,
      },
    ],
  },
];

export default tiktokPath;
