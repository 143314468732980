import { CMS_LIST_TYPE_BLOCK, CMS_LIST_TYPE_PRODUCT } from "components/PostHandle/view/PageBits";
import {
  CMS_DOMAIN_LIMITED_FOR_REDUX_STORE,
  CMS_IMAGES,
  CMS_PRODUCT_LIMITED_FOR_REDUX_STORE,
  CMS_STORE_LIMITED_FOR_REDUX_STORE,
  GET_ALL_CMS_LISTS_FOR_VS_CONTENT,
  GET_CMS_CATEGORIES_ID_NAME,
  GET_CMS_COUNTRIES,
} from "shared/gql/gqlSchema/cmsGql";
import {
  API_STORE_CMS_CATEGORY,
  API_STORE_CMS_COUNTRIES,
  API_STORE_CMS_DOMAINS,
  API_STORE_CMS_IMAGES,
  API_STORE_CMS_LIST_TYPE_BLOCK,
  API_STORE_CMS_LIST_TYPE_PRODUCT,
  API_STORE_CMS_PRODUCTS,
  API_STORE_CMS_STORES,
} from "./keys";
import useApiToStoreQuery from "./useApiToStoreQuery";

export function useCmsApiParams() {
  const {
    apiCall: cmsDoimnsFetch = () => {},
    refetch: cmsDoimnsRefetch,
    setDataSource: cmsDoimnsSetDataSource,
  } = useApiToStoreQuery({
    api: CMS_DOMAIN_LIMITED_FOR_REDUX_STORE,
    elementKey: "CMS_Domains",
    storeKey: "CMS_Domains",
  });

  const {
    apiCall: cmsCategoriesFetch = () => {},
    refetch: cmsCategoriesRefetch,
    setDataSource: cmsCategoriesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_CMS_CATEGORIES_ID_NAME,
    elementKey: "CMS_Categories",
    storeKey: "CMS_Categories",
  });

  const {
    apiCall: cmsCountriesFetch = () => {},
    refetch: cmsCountriesRefetch,
    setDataSource: cmsCountriesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_CMS_COUNTRIES,
    elementKey: "CMS_Countries",
    storeKey: "CMS_Countries",
  });

  const {
    apiCall: cmsProductsFetch = () => {},
    refetch: cmsProductsRefetch,
    setDataSource: cmsProductsSetDataSource,
  } = useApiToStoreQuery({
    api: CMS_PRODUCT_LIMITED_FOR_REDUX_STORE,
    elementKey: "CMS_All_Products",
    storeKey: "CMS_Products",
  });

  const {
    apiCall: cmsStoresFetch = () => {},
    refetch: cmsStoresRefetch,
    setDataSource: cmsStoresSetDataSource,
  } = useApiToStoreQuery({
    api: CMS_STORE_LIMITED_FOR_REDUX_STORE,
    elementKey: "CMS_Stores",
    storeKey: "CMS_Stores",
  });

  const {
    apiCall: cmsBlockListFetch = () => {},
    refetch: cmsBlockListRefetch,
    setDataSource: cmsBlockListSetDataSource,
  } = useApiToStoreQuery({
    api: GET_ALL_CMS_LISTS_FOR_VS_CONTENT,
    elementKey: "CMS_Type_Of_List",
    storeKey: "cmsListType_vsContent" + CMS_LIST_TYPE_BLOCK,
    variables: { type: CMS_LIST_TYPE_BLOCK },
  });

  const {
    apiCall: cmsImagesSizesFetch = () => {},
    refetch: cmsImagesSizesRefetch,
    setDataSource: cmsImagesSizesSetDataSource,
  } = useApiToStoreQuery({ api: CMS_IMAGES, elementKey: "CMS_Images", storeKey: "cmsImageSizes" });

  return {
    cmsDoimnsFetch,
    cmsDoimnsRefetch,
    cmsDoimnsSetDataSource,

    cmsCategoriesFetch,
    cmsCategoriesRefetch,
    cmsCategoriesSetDataSource,

    cmsCountriesFetch,
    cmsCountriesRefetch,
    cmsCountriesSetDataSource,

    cmsProductsFetch,
    cmsProductsRefetch,
    cmsProductsSetDataSource,

    cmsStoresFetch,
    cmsStoresRefetch,
    cmsStoresSetDataSource,

    cmsBlockListFetch,
    cmsBlockListRefetch,
    cmsBlockListSetDataSource,

    cmsImagesSizesFetch,
    cmsImagesSizesRefetch,
    cmsImagesSizesSetDataSource,
  };
}

export default function useCmsApis(typeKey) {
  //WILL REMOVE AND REPLACE
  switch (typeKey) {
    case API_STORE_CMS_CATEGORY:
      return {
        api: GET_CMS_CATEGORIES_ID_NAME,
        elementKey: "CMS_Categories",
        storeKey: "CMS_Categories",
      };

    case API_STORE_CMS_COUNTRIES:
      return {
        api: GET_CMS_COUNTRIES,
        elementKey: "CMS_Countries",
        storeKey: "CMS_Countries",
      };

    case API_STORE_CMS_PRODUCTS:
      return {
        api: CMS_PRODUCT_LIMITED_FOR_REDUX_STORE,
        elementKey: "CMS_All_Products",
        storeKey: "CMS_Products",
      };

    case API_STORE_CMS_STORES:
      return {
        api: CMS_STORE_LIMITED_FOR_REDUX_STORE,
        elementKey: "CMS_Stores",
        storeKey: "CMS_Stores",
      };

    case API_STORE_CMS_DOMAINS:
      return {
        api: CMS_DOMAIN_LIMITED_FOR_REDUX_STORE,
        elementKey: "CMS_Domains",
        storeKey: "CMS_Domains",
      };

    case API_STORE_CMS_LIST_TYPE_BLOCK:
      return {
        api: GET_ALL_CMS_LISTS_FOR_VS_CONTENT,
        elementKey: "CMS_Type_Of_List",
        storeKey: "cmsListType_vsContent" + CMS_LIST_TYPE_BLOCK,
        variables: { type: CMS_LIST_TYPE_BLOCK },
      };

    case API_STORE_CMS_LIST_TYPE_PRODUCT:
      return {
        api: GET_ALL_CMS_LISTS_FOR_VS_CONTENT,
        elementKey: "CMS_Type_Of_List",
        storeKey: "cmsListType_vsContent" + CMS_LIST_TYPE_PRODUCT,
        variables: { type: CMS_LIST_TYPE_PRODUCT },
      };

    case API_STORE_CMS_IMAGES:
      return { api: CMS_IMAGES, elementKey: "CMS_Images", storeKey: "cmsImageSizes" };
    default:
      return false;
  }
}
