import { Button, Checkbox, Col, Collapse, Row, Tabs } from "antd";
import ActionIconRender from "components/ActionIconRender";
import Loader from "components/loader";
import cx from "cx";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { GET_MOODBOARD_CTA } from "shared/gql/gqlSchema/creativeDashboardGql";
import { GQL_GET_MOODBOARD_BY_CATEGORY } from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";
import {
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_DESCRIPTION,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_LANGUAGE,
  MOODBOARD_TYPE_PAIN_POINTS,
  MOODBOARD_TYPE_SCRIPT,
} from "../../creativesEnum";
import ScriptDetailedTabs from "./ScriptDetailedTabs";

function SelectMoodboardDataModal(props) {
  const {
    actionType,
    id = [],
    productId,
    placeHolderKey,
    loading = false,
    apiKey = "",
    selectedItems,
    setSelectedItems = () => {},
    onClose = () => {},
    handleAddAnalysis = () => {},
  } = props;
  const [activeKey, setActiveKey] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const isDesc = actionType === MOODBOARD_TYPE_DESCRIPTION;
  // optimize the code in future
  const [moodBoardData, setMoodboardData] = useState([]);

  const handleCollapseChange = (keys, data) => {
    setActiveKey(keys);
    setIsVisible(false);
  };
  const [activeScriptTab, setActiveScriptTab] = useState("1");

  const [fetchMoodboardData, { loading: loading_moodboard, refetch: refetch_moodboard }] =
    useCustomLazyQuery(GQL_GET_MOODBOARD_BY_CATEGORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ctGetMoodboardByCategory;
        if (element?.data?.length > 0) {
          setMoodboardData(element?.data?.[0]?.[apiKey]);
        } else {
          setMoodboardData([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const finalLoading = loading_moodboard;

  useEffect(() => {
    fetchMoodboardData({
      variables: { categoryIds: id, productId },
    });
  }, []);

  const handleSelectedItems = (id) => {
    if (selectedItems?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedItems(selectedItems?.filter((img) => img?.id !== id));
    } else {
      setSelectedItems([
        {
          id: moodBoardData?.filter((img) => img?.id === id)[0].id,
          // tags: creativeData?.filter((img) => img?.id === id)[0].tags,
        },
      ]);
    }
  };
  const onChangeScriptTabs = (key) => {
    setActiveScriptTab(key);
  };
  const ScriptDetailedTabsContent = [
    {
      key: "1",
      label: "Copy Points",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_COPY_POINTS}
          paramstype="copyPoints"
          label="Copy Points"
          data={moodBoardData?.[activeKey]?.["copyPoints"]}
        />
      ),
    },
    {
      key: "2",
      label: "Angles",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_ANGLES}
          paramstype="angles"
          label="Angles"
          data={moodBoardData?.[activeKey]?.["angles"]}
        />
      ),
    },
    {
      key: "3",
      label: "Hooks",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_HOOKS}
          paramstype="hooks"
          label="Hooks"
          data={moodBoardData?.[activeKey]?.["hooks"]}
        />
      ),
    },
    {
      key: "4",
      label: "Pain Points",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_PAIN_POINTS}
          paramstype="painPoints"
          label="Pain Points"
          data={moodBoardData?.[activeKey]?.["painPoints"]}
        />
      ),
    },
    {
      key: "5",
      label: "CTA",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_CTA}
          query={GET_MOODBOARD_CTA}
          paramstype="ctas"
          label="CTA"
          data={moodBoardData?.[activeKey]?.["ctas"]}
        />
      ),
    },
    {
      key: "6",
      label: "Language",
      children: (
        <ScriptDetailedTabs
          query={GET_MOODBOARD_CTA}
          type={MOODBOARD_TYPE_LANGUAGE}
          paramstype="language"
          label="Language"
          data={[moodBoardData?.[activeKey]?.["language"]]}
        />
      ),
    },
  ];
  const contentView = () => (
    <>
      {finalLoading ? (
        <Loader center />
      ) : moodBoardData?.length === 0 && !isDesc ? (
        <div className="text-center pt-100">
          <div style={{ transform: "translateX(20px)" }}>
            <ActionIconRender iconType="tablePlaceholder" />
          </div>
          <h3 className="font-size-16">
            <b>This Image/Video has no {placeHolderKey}</b>
          </h3>
        </div>
      ) : (
        <>
          {moodBoardData?.map((moodBoardData, index) => (
            <>
              <div
                className={cx(
                  "moodboardData",
                  {
                    active: activeKey == index + "",
                  },
                  { copyPoint: actionType === MOODBOARD_TYPE_COPY_POINTS }
                )}
              >
                <div className="d-flex flex-grow-1">
                  <Checkbox
                    checked={
                      selectedItems?.filter((img) => img.id === moodBoardData?.id)?.length > 0
                    }
                    style={{ marginTop: "12px", marginLeft: "12px", marginRight: "0px" }}
                    value={moodBoardData?.id}
                    onClick={(e) => handleSelectedItems(e.target.value)}
                  />

                  <Collapse
                    className="flex-grow-1"
                    collapsible="header"
                    accordion
                    activeKey={activeKey}
                    onChange={(e) => handleCollapseChange(e, moodBoardData)}
                  >
                    <Collapse.Panel
                      header={moodBoardData?.name}
                      key={index.toString()}
                      collapsible={
                        actionType === MOODBOARD_TYPE_COPY_POINTS ||
                        (moodBoardData?.description === null &&
                          actionType !== MOODBOARD_TYPE_SCRIPT)
                          ? "disabled"
                          : undefined
                      }
                    >
                      <div>
                        {actionType !== MOODBOARD_TYPE_COPY_POINTS && (
                          <>
                            {(moodBoardData?.description !== null ||
                              actionType === MOODBOARD_TYPE_SCRIPT) && (
                              <>
                                <p className="moodboardDesc">
                                  {isVisible
                                    ? moodBoardData?.description
                                    : moodBoardData?.description?.substring(0, 170)}
                                </p>
                                {!isVisible && moodBoardData?.description?.length > 190 && (
                                  <p
                                    sty
                                    className="moodboardDataDesc"
                                    onClick={() => setIsVisible(true)}
                                  >
                                    ...more
                                  </p>
                                )}
                              </>
                            )}
                            {actionType === MOODBOARD_TYPE_SCRIPT && (
                              <div className="pb-20">
                                <Tabs
                                  className="ScriptDetailedTabs"
                                  defaultAacctiveKey="1"
                                  items={ScriptDetailedTabsContent}
                                  onChange={(e) => onChangeScriptTabs(e)}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </>
          ))}

          <div className="creativeAnalysisBtn">
            <Row gutter={12}>
              <Col span={12}>
                <Button
                  htmlType="cancel"
                  block
                  type="default"
                  className="submit-btn"
                  onClick={() => {
                    onClose();
                    setIsVisible(false);
                    setActiveKey(null);
                    setSelectedItems([]);
                  }}
                  // disabled={loading}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  onClick={() => handleAddAnalysis()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
  return (
    <>
      <div className={cx("creative-builder-moodboard", `${"children-moodboard"}`)}>
        <div className="content-view">{contentView()}</div>
      </div>
    </>
  );
}

export default SelectMoodboardDataModal;
