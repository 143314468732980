import { useCustomQuery } from "hooks/apolloClientHooks";
import { useState } from "react";
import {
  GET_F360_ADGROUP_REPORT,
  GET_F360_ADSET_GROUPED_REPORT,
  GET_F360_AD_ACCOUNT_REPORT,
  GET_F360_AD_GROUPED_REPORT,
  GET_F360_AD_REPORT,
  GET_F360_CAMPAIGN_GROUPED_REPORT,
  GET_F360_CAMPAIGN_REPORT,
  GET_F360_DAILY_ADGROUP_REPORT,
  GET_F360_DAILY_AD_ACCOUNT_REPORT,
  GET_F360_DAILY_AD_REPORT,
  GET_F360_DAILY_CAMPAIGN_REPORT,
  GET_F360_DETAIL_ADSET_DAILY_REPORT,
  GET_F360_DETAIL_ADSET_HOUR_OF_DAY_REPORT,
  GET_F360_DETAIL_ADSET_HOUR_REPORT,
  GET_F360_DETAIL_AD_ACCOUNT_DAILY_REPORT,
  GET_F360_DETAIL_AD_ACCOUNT_HOUR_OF_DAY_REPORT,
  GET_F360_DETAIL_AD_ACCOUNT_HOUR_REPORT,
  GET_F360_DETAIL_AD_DAILY_REPORT,
  GET_F360_DETAIL_AD_HOUR_OF_DAY_REPORT,
  GET_F360_DETAIL_AD_HOUR_REPORT,
  GET_F360_DETAIL_CAMPAIGN_DAILY_REPORT,
  GET_F360_DETAIL_CAMPAIGN_HOUR_OF_DAY_REPORT,
  GET_F360_DETAIL_CAMPAIGN_HOUR_REPORT,
  GET_F360_HOURLY_ADGROUP_REPORT,
  GET_F360_HOURLY_AD_ACCOUNT_REPORT,
  GET_F360_HOURLY_AD_REPORT,
  GET_F360_HOURLY_CAMPAIGN_REPORT,
  GET_F360_HOUR_OF_THE_DAY_ADGROUP_REPORT,
  GET_F360_HOUR_OF_THE_DAY_AD_ACCOUNT_REPORT,
  GET_F360_HOUR_OF_THE_DAY_AD_REPORT,
  GET_F360_HOUR_OF_THE_DAY_CAMPAIGN_REPORT,
} from "shared/gql/gqlSchema/fb360Gql";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_AD_ADSET_PERFORMANCE,
  REPORT_TYPE_AD_PERFORMANCE,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_CAMPAIGN_PERFORMANCE,
  REPORT_TYPE_IS_DAILY,
  REPORT_TYPE_IS_HOURLY,
  REPORT_TYPE_IS_HOUR_OF_THE_DAY,
  REPORT_TYPE_PERFORMANCE_IS_HOURLY,
  REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY,
} from "shared/SharedKeys";

export const ADBY_REPORT_TYPE_AD = "AD";
export const ADBY_REPORT_TYPE_ADSET = "ADSET";
export const ADBY_REPORT_TYPE_CAMPAIGN = "CAMPAIGN";
export const ADBY_REPORT_TYPE_ADACCOUNT = "ADACCOUNT";
// for ringba
export const ADBY_REPORT_TYPE_CATEGORY = "CATEGORY";
export const ADBY_REPORT_TYPE_USER = "USER";

function apiQueryAndElement(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return { query: GET_F360_AD_ACCOUNT_REPORT, element: "fb360_adaccountReporting" };
    case REPORT_TYPE_AD:
      return { query: GET_F360_AD_REPORT, element: "fb360_adReporting" };
    case REPORT_TYPE_AD_ADSET:
      return { query: GET_F360_ADGROUP_REPORT, element: "fb360_adsetReporting" };
    case REPORT_TYPE_CAMPAIGN:
      return { query: GET_F360_CAMPAIGN_REPORT, element: "fb360_campaignReporting" };
    default: {
      return {};
    }
  }
}

function apiDetailQueryAndElement(type, detailReportType) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      switch (detailReportType) {
        case REPORT_TYPE_PERFORMANCE_IS_HOURLY:
          return {
            query: GET_F360_DETAIL_AD_ACCOUNT_HOUR_REPORT,
            element: "fb360_adaccountDetailHour",
          };
        case REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY:
          return {
            query: GET_F360_DETAIL_AD_ACCOUNT_HOUR_OF_DAY_REPORT,
            element: "fb360_adaccountDetailHourOfDay",
          };
        default:
          return {
            query: GET_F360_DETAIL_AD_ACCOUNT_DAILY_REPORT,
            element: "fb360_adaccountDetailDay",
          };
      }

    case REPORT_TYPE_CAMPAIGN:
      switch (detailReportType) {
        case REPORT_TYPE_PERFORMANCE_IS_HOURLY:
          return {
            query: GET_F360_DETAIL_CAMPAIGN_HOUR_REPORT,
            element: "fb360_campaignDetailHour",
          };
        case REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY:
          return {
            query: GET_F360_DETAIL_CAMPAIGN_HOUR_OF_DAY_REPORT,
            element: "fb360_campaignDetailHourOfDay",
          };
        default:
          return {
            query: GET_F360_DETAIL_CAMPAIGN_DAILY_REPORT,
            element: "fb360_campaignDetailDay",
          };
      }

    case REPORT_TYPE_AD_ADSET:
      switch (detailReportType) {
        case REPORT_TYPE_PERFORMANCE_IS_HOURLY:
          return {
            query: GET_F360_DETAIL_ADSET_HOUR_REPORT,
            element: "fb360_adsetDetailHour",
          };
        case REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY:
          return {
            query: GET_F360_DETAIL_ADSET_HOUR_OF_DAY_REPORT,
            element: "fb360_adsetDetailHourOfDay",
          };
        default:
          return {
            query: GET_F360_DETAIL_ADSET_DAILY_REPORT,
            element: "fb360_adsetDetailDay",
          };
      }

    case REPORT_TYPE_AD:
      switch (detailReportType) {
        case REPORT_TYPE_PERFORMANCE_IS_HOURLY:
          return {
            query: GET_F360_DETAIL_AD_HOUR_REPORT,
            element: "fb360_adDetailHour",
          };
        case REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY:
          return {
            query: GET_F360_DETAIL_AD_HOUR_OF_DAY_REPORT,
            element: "fb360_adDetailHourOfDay",
          };
        default:
          return {
            query: GET_F360_DETAIL_AD_DAILY_REPORT,
            element: "fb360_adDetailDay",
          };
      }
    default: {
      return {};
    }
  }
}

function adReportQueryAndElement(type) {
  switch (type) {
    case REPORT_TYPE_AD_PERFORMANCE:
      return { query: GET_F360_AD_GROUPED_REPORT, element: "fb360_adGroupedReporting" };
    case REPORT_TYPE_AD_ADSET_PERFORMANCE:
      return { query: GET_F360_ADSET_GROUPED_REPORT, element: "fb360_adsetGroupedReporting" };
    case REPORT_TYPE_CAMPAIGN_PERFORMANCE:
      return { query: GET_F360_CAMPAIGN_GROUPED_REPORT, element: "fb360_campaignGroupedReporting" };
    default: {
      return {};
    }
  }
}

function assetType(type) {
  switch (type) {
    case ADBY_REPORT_TYPE_CAMPAIGN:
      return { asset_type: ADBY_REPORT_TYPE_CAMPAIGN };
    case ADBY_REPORT_TYPE_ADSET:
      return { asset_type: ADBY_REPORT_TYPE_ADSET };
    case ADBY_REPORT_TYPE_AD:
      return { asset_type: ADBY_REPORT_TYPE_AD };
    case ADBY_REPORT_TYPE_ADACCOUNT:
      return { asset_type: ADBY_REPORT_TYPE_ADACCOUNT };

    default: {
      return {};
    }
  }
}

function dailyApiQueryAndElement(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return { query: GET_F360_DAILY_AD_ACCOUNT_REPORT, element: "fb360_adaccountDay" };
    case REPORT_TYPE_AD:
      return { query: GET_F360_DAILY_AD_REPORT, element: "fb360_adDay" };
    case REPORT_TYPE_AD_ADSET:
      return { query: GET_F360_DAILY_ADGROUP_REPORT, element: "fb360_adsetDay" };
    case REPORT_TYPE_CAMPAIGN:
      return { query: GET_F360_DAILY_CAMPAIGN_REPORT, element: "fb360_campaignDay" };
    default: {
      return {};
    }
  }
}

function hourlyApiQueryAndElement(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return { query: GET_F360_HOURLY_AD_ACCOUNT_REPORT, element: "fb360_adaccountHour" };
    case REPORT_TYPE_AD:
      return { query: GET_F360_HOURLY_AD_REPORT, element: "fb360_adHour" };
    case REPORT_TYPE_AD_ADSET:
      return { query: GET_F360_HOURLY_ADGROUP_REPORT, element: "fb360_adsetHour" };
    case REPORT_TYPE_CAMPAIGN:
      return { query: GET_F360_HOURLY_CAMPAIGN_REPORT, element: "fb360_campaignHour" };
    default: {
      return {};
    }
  }
}

function hourOfTheDayApiQueryAndElement(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return {
        query: GET_F360_HOUR_OF_THE_DAY_AD_ACCOUNT_REPORT,
        element: "fb360_adaccountHourOfDay",
      };
    case REPORT_TYPE_AD:
      return { query: GET_F360_HOUR_OF_THE_DAY_AD_REPORT, element: "fb360_adHourOfDay" };
    case REPORT_TYPE_AD_ADSET:
      return { query: GET_F360_HOUR_OF_THE_DAY_ADGROUP_REPORT, element: "fb360_adsetHourOfDay" };
    case REPORT_TYPE_CAMPAIGN:
      return {
        query: GET_F360_HOUR_OF_THE_DAY_CAMPAIGN_REPORT,
        element: "fb360_campaignHourOfDay",
      };
    default: {
      return {};
    }
  }
}

export default function useFacebookSpendReportsQuery(type, extraParams = {}) {
  const {
    variables,
    from,
    to,
    timezone,
    isDetailView = false,
    isAdReport = false,
    typeOfReport = "",
    detailReportType = "",
  } = extraParams;

  const [spendReport, setSpendReport] = useState([]);
  const { adBy = "" } = variables;

  let queryObject = {};

  switch (typeOfReport) {
    case REPORT_TYPE_IS_DAILY:
      queryObject = dailyApiQueryAndElement(type);
      break;

    case REPORT_TYPE_IS_HOURLY:
      queryObject = hourlyApiQueryAndElement(type);
      break;

    case REPORT_TYPE_IS_HOUR_OF_THE_DAY:
      queryObject = hourOfTheDayApiQueryAndElement(type);
      break;

    default:
      queryObject = isDetailView
        ? apiDetailQueryAndElement(type, detailReportType)
        : isAdReport
        ? adReportQueryAndElement(detailReportType)
        : apiQueryAndElement(type);
      break;
  }

  const { loading: loading_spend, refetch } = useCustomQuery(queryObject?.query, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[queryObject?.element];
      if (element) {
        setSpendReport(
          element?.map((d) => ({
            ...d,
            clicksFB: d?.clicks,
            inlineClicks: d?.inline_link_clicks,
            spendFB: d?.spend,
          }))
        );
      }
    },

    variables: { start: from, timezone: timezone, end: to, ...assetType(adBy), ...variables },
  });

  const refetch_spend = () => {
    setSpendReport([]);
    refetch();
  };

  return { loading_spend, refetch_spend, spendReport, setSpendReport };
}
