import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { LaunchContext } from "../Context";
import LaunchMainView from "../LaunchMainView";
import { getLaunchTitle } from "../LaunchUtils";
import cx from "cx";
import {
  API_STORE_F360_LAUNCH_CONFIG,
  API_STORE_F360_TARGETING_COUNTRIES,
} from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
import { useEffect } from "react";

export function LaunchMainPage(props) {
  const { launchType = "" } = props;

  const { apiCall: targetingCountriesFetch = () => {}, refetch: targetingCountriesRefetch } =
    useApiToStore(API_STORE_F360_TARGETING_COUNTRIES);

  const { apiCall: launchConfigFetch = () => {}, refetch: launchConfigRefetch } = useApiToStore(
    API_STORE_F360_LAUNCH_CONFIG
  );
  useEffect(() => {
    launchConfigFetch();
    targetingCountriesFetch();
  }, []);

  return (
    <>
      <LaunchContext.Provider value={{ launchType }}>
        <DashboardHeader title={cx("Launch", getLaunchTitle(launchType))} />
        <LaunchMainView />
      </LaunchContext.Provider>
    </>
  );
}
