import { Pagination } from "antd";
import React, { useState } from "react";

function LookPagination(props) {
  const defaultPageSizeOptions = ["10", "15", "20", "50", "100", "200", "500"];

  const { totalCount = 1, currentPage = 1, pageSize = 1, onChange = () => {} } = props;

  return (
    <Pagination
      defaultCurrent={1}
      pageSize={pageSize}
      current={currentPage}
      onChange={onChange}
      pageSizeOptions={defaultPageSizeOptions}
      showSizeChanger
      total={totalCount}
    />
  );
}

export default LookPagination;
