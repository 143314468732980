import { Pagination, Row } from "antd";
import Col from "antd/es/grid/col";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import LookDrawer from "components/LookDrawer";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import cx from "cx";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PLATFORM_TYPE_TIKTOK } from "shared/enum/launchKeysAndEnum";
import {
  GET_REPORTS_CATEGORY_BY_CREATIVE,
  GET_REPORTS_CATEGORY_BY_SUBIDS,
  GET_TIKTOK_REPORTS_CATEGORY_BY_CREATIVE,
  GET_TIKTOK_CREATIVES_BY_CAMPAIGN,
} from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";

function LookCategoryReports(props) {
  const {
    isKeyword = false,
    serviceType,
    id,
    name,
    category,
    content_type,
    visibleDrawer,
    platformType,
    onCloseReportsDrawer,
    subId,
    reportsByCampaign = false,
    subIdReports = false,
  } = props;

  const userSettings = useSelector((state) => state?.userSettings);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { dateRangeTimezone } = userSettings || {};
  const dateFormat = "YYYY-MM-DD";
  const defaultDate = [moment().subtract(7, "d").format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);
  const [timeZone, setTimeZone] = useState({ value: dateRangeTimezone?.timeZone?.value });
  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  const isTiktokCategory = platformType === PLATFORM_TYPE_TIKTOK;
  let categoryId = id;
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const [reportData, setReportData] = useState([]);

  const [callReports, { loading: loading_Reports, refetch: refetch_reports }] = useCustomLazyQuery(
    GET_REPORTS_CATEGORY_BY_CREATIVE,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        setReportData(e?.reportCategoryByCreative?.data);
        setTotalCount(e?.reportCategoryByCreative?.data?.length);
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [callReportsBySubId, { loading: loading_subId_Reports, refetch: refetch_subId_ReportS }] =
    useCustomLazyQuery(GET_REPORTS_CATEGORY_BY_SUBIDS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        setReportData(e?.creativeBySubId?.data);
        setTotalCount(e?.data_subId_Reports?.data?.length);
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  const [callTiktokReports, { loading: loading_Tiktok_Reports, refetch: refetch_Tiktok_reports }] =
    useCustomLazyQuery(GET_TIKTOK_REPORTS_CATEGORY_BY_CREATIVE, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        setReportData(e?.tiktok_getCategoryByCreativeReport?.data);
        setTotalCount(e?.tiktok_getCategoryByCreativeReport?.data?.length);
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [
    callTiktokReportsByCampaign,
    { loading: loading_Tiktok_Campaign_Reports, refetch: refetch_Tiktok_Campaign_reports },
  ] = useCustomLazyQuery(GET_TIKTOK_CREATIVES_BY_CAMPAIGN, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      setReportData(e?.tiktok_getCreativesByCampaignId?.data);
      // setTotalCount(e?.tiktok_getCategoryByCreativeReport?.data?.length);
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  useEffect(() => {
    if (isTiktokCategory) {
      if (reportsByCampaign) {
        callTiktokReportsByCampaign({
          variables: {
            campaignId: props?.campaignId,
            start: rangeDates[0],
            end: rangeDates[1],
            timezone: timeZone?.value,
          },
        });
      } else
        callTiktokReports({
          variables: {
            categoryId,
            start: rangeDates[0],
            page: currentPage - 1,
            productId: 1,
            size: pageSize,
            end: rangeDates[1],
            timezone: timeZone?.value,
          },
        });
    } else if (subIdReports) {
      callReportsBySubId({ variables: { page: currentPage - 1, size: pageSize, subId: subId } });
    } else {
      callReports({
        variables: {
          categoryId,
          from: rangeDates[0],
          page: currentPage - 1,
          productId: 1,
          size: pageSize,
          to: rangeDates[1],
        },
      });
    }
  }, [rangeDates, pageSize, timeZone, currentPage]);

  return (
    <>
      <LookDrawer size={1200} visible={visibleDrawer} onClose={onCloseReportsDrawer} title={name}>
        {visibleDrawer && (
          <div className={cx("creative-media", { isCategoryReports: true })}>
            <div
              className="mb-20 d-flex"
              style={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              <LookButton
                tooltipTitle="Refetch"
                className="filter-btn ml-8"
                size="small"
                iconType="reload"
                disabled={loading_Reports}
                iconTypeProps={{ spin: loading_Reports }}
                onClick={() => {
                  isTiktokCategory ? refetch_Tiktok_reports() : refetch_reports();
                }}
              />
              {!subIdReports && (
                <DateRangePersist
                  isTimezone={isTiktokCategory}
                  {...{
                    noStore: true,
                    defaultDate: defaultDate,
                    defaultTimeZone: dateRangeTimezone?.timeZone?.value,
                    onChange: handleDateRange,
                  }}
                />
              )}
            </div>

            {loading_Reports ||
            loading_Tiktok_Reports ||
            loading_subId_Reports ||
            loading_Tiktok_Campaign_Reports ? (
              <Loader />
            ) : (
              <Row gutter={[15, 15]} style={{ height: "auto" }}>
                {isTiktokCategory
                  ? reportData
                      ?.filter(({ video_url }) => video_url)
                      ?.map((data, index) => {
                        const {
                          video_url = "",
                          video_thumb_url = "",
                          spend = "",
                          ad_text = "",
                          ad_id = "",
                        } = data;
                        const finalData = {
                          spend,
                          title: { title: ad_text, id: ad_id },
                          video: {
                            s3Url: video_url,
                            thumbUrl: video_thumb_url,
                            id: index + 1,
                          },
                        };
                        return (
                          <Col xs={12} sm={12} md={8} xl={6}>
                            <CardCreativeAdGroup {...finalData} creativeReports />
                          </Col>
                        );
                      })
                  : reportData
                      ?.filter(({ creativeImage }) => creativeImage?.s3Url)
                      ?.map((data, index) => {
                        const { creativeImage, adTitle, adBody, spend, pageId } = data;
                        let finalData = {
                          spend,
                          title: { title: adTitle?.title, id: adTitle?.id },
                          body: { body: adBody?.body, id: adBody?.id },
                          pageId,
                        };

                        if (!creativeImage?.videoId) {
                          finalData = {
                            ...finalData,
                            image: {
                              s3Url: creativeImage?.s3Url,
                              id: creativeImage?.id,
                              imageUrl: creativeImage?.imageUrl,
                              imageHash: creativeImage?.imageHash,
                            },
                          };
                        } else {
                          finalData = {
                            ...finalData,
                            video: {
                              s3Url: creativeImage?.s3Url,
                              id: creativeImage?.videoId,
                              thumbUrl: creativeImage?.thumbnailUrl,
                            },
                          };
                        }
                        return (
                          <Col xs={12} sm={12} md={8} xl={6}>
                            <CardCreativeAdGroup {...finalData} creativeReports={!subIdReports} />
                          </Col>
                        );
                      })}
              </Row>
            )}
            {!reportsByCampaign && (
              <div className="p-8  look-bottom-pagination ">
                <Pagination
                  defaultCurrent={1}
                  total={totalCount}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={(page, size) => handlePageChange(page, size)}
                  pageSizeOptions={["10", "20", "50"]}
                  showSizeChanger
                />
              </div>
            )}
          </div>
        )}
      </LookDrawer>
    </>
  );
}

export default LookCategoryReports;
