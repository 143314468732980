import { Form, Select } from "antd";
import SelectInput from "../../../forms/SelectInput";

const { Option } = Select;

export default function NetworkTypeHandle(props) {
  const { value, onChange = () => {} } = props;

  const options = [
    {
      name: "Network",
      value: "Network",
    },
    {
      name: "Direct",
      value: "Direct",
    },
  ];
  return (
    <div className="position-relative">
      <Form layout="vertical">
        <Form.Item label="Network Type" className="look-form-input">
          {/* <Select
            name="roles"
            className="w-100"
            placeholder="Select Type"
            onChange={onChange}
            value={value}
            // showSearch
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
          >
            {options.map(({ name, value }) => (
              <Option key={value} value={value}>
                {name}
              </Option>
            ))}
          </Select> */}

          <SelectInput
                name="roles"
                className="mb-0"
                value={value}
                placeholder="Select Type"
                options={options?.map(({ name, value }) => ({ name: name, value: value }))}
                onChange={onChange}
              />
        </Form.Item>
      </Form>

      {/* <pre>{JSON.stringify({ data }, null, 2)}</pre> */}
    </div>
  );
}
