import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CTA_BUTTON_TYPES } from "../config";
import {
  BLOCK_BIT,
  LIST_BIT,
  NETWORK_BIT,
  PAGE_BIT,
  PRODUCT_BIT,
  REVIEW_BIT,
  STORY_BIT,
} from "../PageBits";
import { detectValueType, openNotification, urlValidateRegex } from "utils";
import DebugPre from "../../../DebugPre";
import TabRender from "../../../TabRender";
import LookTextInput from "../../../forms/LookTextInput";
import { Button, Dropdown, Form, Menu, Tooltip, Switch, Rate, Segmented } from "antd";
import Loader from "../../../loader";
import Preview from "../Preview";
import NetworkRules from "../../SubComponents/NetworkRules";
import ReactQuillForm from "../../../ReactQuillForm";
import StoreOfferLinksHandle from "../../SubComponents/StoreOfferLinksHandle";
import SidesHandle from "../../SubComponents/SidesHandle";
import { CategoriesSelection, CollapseWraper } from "../../SubComponents";
import NetworkTypeHandle from "../../SubComponents/NetworkTypeHandle";
import FileHandle from "../../../CmsComponents/FileHandle";
import DomiansHandle from "../../SubComponents/DomiansHandle";
import ListTypeHandle from "../../SubComponents/ListTypeHandle";
import ListHandle from "../../SubComponents/ListHandle";
import ProductHandle from "../../SubComponents/ProductHandle";
import SelectInput from "../../../forms/SelectInput";
import TagsHandle from "../../SubComponents/TagsHandle";
import BrandHandle from "../../SubComponents/BrandHandle";
import VisibilityHandle from "../../SubComponents/VisibilityHandle";
import SwitchHandle from "../../SubComponents/SwitchHandle";
import PublishDateHandle from "../../SubComponents/PublishDateHandle";
import ActionIconRender from "../../../ActionIconRender";
import { ADD_CMS_AWIC_NETWORK, UPDATE_CMS_AWIC_NETWORK } from "shared/gql/gqlSchema/awicGql";
import {
  ADD_CMS_BLOCK,
  ADD_CMS_LIST,
  ADD_CMS_REVIEW,
  ADD_CMS_RODUCT,
  CMS_CREATE_PRODUCT,
  CMS_CREATE_STORY,
  GET_CMS_AWIC_NETWORK,
  GET_CMS_BLOCK,
  GET_CMS_CATEGORIES_ID_NAME,
  GET_CMS_LIST,
  GET_CMS_PAGE,
  GET_CMS_PRODUCT,
  GET_CMS_REVIEW,
  GET_CMS_STORE_NAME_WEBSITE_BY_BRANDS,
  GET_CMS_STORY,
  UPDATE_CMS_BLOCK,
  UPDATE_CMS_LIST,
  UPDATE_CMS_PAGE,
  UPDATE_CMS_PRODUCT,
  UPDATE_CMS_REVIEW,
  UPDATE_CMS_STORY,
} from "shared/gql/gqlSchema/cmsGql";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { CMSPostHandleContext } from "../Context";
import SeoHandle from "components/PostHandle/SubComponents/SeoHandle";
import {
  CMS_POST_CONTENT_VIEW_TYPE_CREATE,
  CMS_POST_CONTENT_VIEW_TYPE_CRS,
  CMS_POST_CONTENT_VIEW_TYPE_EDIT,
  CMS_POST_TYPE_LIST,
  CMS_POST_TYPE_STORY,
} from "components/PostHandle";
import { GQL_UPDATE_ARTICLE } from "shared/gql/gqlSchema/crsGql";
import {
  GQL_API_RESPONSE_STATUS_FAILURE,
  GQL_API_RESPONSE_STATUS_SUCCESS,
} from "shared/SharedKeys";
import CampaingHandle from "components/PostHandle/SubComponents/CampaignHandle";
import { useSelector } from "react-redux";
import cx from "cx";
import { CRS_STATUS_ACTIONS_DRAFT } from "shared/enum/crsKeysAndEnum";
import {
  CmsContentSelectedTagListHandlerView,
  CmsContentTagListHandlerView,
} from "components/ReactQuillForm/reactQuillFormTagsConfigs";
import useCmsAddBanner from "components/CmsComponents/CMSBanner/useCmsAddBanner";
import useCmsUpdateBanner from "components/CmsComponents/CMSBanner/useCmsUpdateBanner";

import { CMS_BANNER_TYPE_PRODUCT } from "components/CmsComponents/CMSBanner/useCmsBannerCrudHook";
import { STRING } from "shared/enum/typeOfEnum";
import SelectStoreHandle from "components/PostHandle/SubComponents/SelectStoreHandle";
import LanguageHandle from "components/PostHandle/SubComponents/LanguageHandle";

import {
  CMS_ARTICLE_TYPE_LIST,
  CMS_ARTICLE_TYPE_PRODUCT,
  CMS_ARTICLE_TYPE_STORY,
} from "shared/enum/cmsKeysAndEnum";
import useCmsAdvertiserApis from "components/ReactQuillForm/serviceComponents/useCmsAdvertiserApis";

const MainForm = forwardRef((props, ref) => {
  const {
    postType = "",
    basePath,
    view = CMS_POST_CONTENT_VIEW_TYPE_CREATE,
    postHandleStatusButtonAction = () => {},
    isNested = false,
    id,
    reqId, // CRS REQUEST ID
    refetch: parentRefetch = () => {},
    close = () => {},
    renderCommentView = () => {},
    renderPromptView = () => {},
    disabledSubmit = false,
  } = props;

  const [cmsContentTagList, setCmsContentTagList] = useState([]);
  const { callCmsUpdateAdvertiser, loading_cmsUpdateAdvertiser } = useCmsAdvertiserApis();

  const dateFormat = "YYYY-MM-DD";
  const history = useHistory();
  const params = useParams();

  const quillContentRef = useRef(null);
  const [contentElemetTags, setContentElemetTags] = useState([]);
  const mainFormId = isNested ? id : params?.id;

  const [bannerImages, setBannerImages] = useState([]);
  const [articleLink, setArticleLink] = useState();

  let bannerType = CMS_BANNER_TYPE_PRODUCT;

  const { bannerAddView, addBanners, loading_addCmsBanner } = useCmsAddBanner({
    type: bannerType,
    bannerImages,
    setBannerImages,
  });

  const { fetchBanners, bannerUpdateView } = useCmsUpdateBanner({
    typeId: mainFormId,
    type: bannerType,
    bannerImages,
    setBannerImages,
  });

  useEffect(() => {
    if (view === CMS_POST_CONTENT_VIEW_TYPE_EDIT) {
      if (postType === PRODUCT_BIT) {
        fetchBanners(mainFormId, {
          onCompleted: (e) => {
            const element = e?.CMS_Get_Banner;

            if (element) {
              const bannerData = element.map(({ id, image, lander_link }) => ({
                id,
                image,
                lander_link,
              }));
              setBannerImages(bannerData);
            }
          },
        });
      }
    }
  }, [mainFormId]);

  const POST_ACTION_VIEW_EDITOR = "Editor";
  const POST_ACTION_VIEW_COMMENT = "Comment & Updates";
  const [postActionView, setPostActionView] = useState(POST_ACTION_VIEW_EDITOR);

  const [fetchedStores, setFetchStores] = useState([]);
  const [offersData, setOffersData] = useState({});
  const [offerLink, setOfferLink] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const userSettings = useSelector((state) => state?.userSettings);

  const { selectedWorkspace, userSettingData } = userSettings || {};
  const { CMS_Domains = [] } = userSettingData || {};

  function handleOffersData(e, id, index) {
    const numberInputCheck = e.target.name === "offer_price" || e.target.name === "strike_price";
    const newValue = numberInputCheck ? parseFloat(e.target.value) : e.target.value;
    setOffersData({ ...offersData, [id]: { ...offersData[id], [e.target.name]: newValue } });
    let tempData = [];
    fetchedStores.forEach(({ id }) => {
      tempData.push({
        store_id: id,
        offer_link: offersData[id]?.offer_link || "",
        offer_price: parseFloat(offersData[id]?.offer_price) || null,
        strike_price: parseFloat(offersData[id]?.strike_price) || null,
      });
    });
    tempData[index][e.target.name] = newValue;
    setOfferLink(tempData);
  }

  const [CMS_Brand, { loading: loading_CMS_STORE }] = useCustomLazyQuery(
    GET_CMS_STORE_NAME_WEBSITE_BY_BRANDS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        let temp = {};
        let tempData = [];
        e?.CMS_Brand?.stores?.forEach(({ id, website = "" }) => {
          temp[id] = { ...offersData[id], offer_link: website };
          tempData.push({ store_id: id, offer_link: website });
        });
        setOfferLink(tempData);
        setOffersData({ ...offersData, ...temp });
        setFetchStores(e?.CMS_Brand?.stores);
      },
    }
  );

  const [CMS_Brand_Feched, { loading: loading_CMS_STORE_feached }] = useCustomLazyQuery(
    GET_CMS_STORE_NAME_WEBSITE_BY_BRANDS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        let temp = {};
        let tempData = [];
        e?.CMS_Brand?.stores?.forEach(({ id, website = "" }) => {
          temp[id] = { ...offersData[id], offer_link: website };
          tempData.push({ store_id: id, offer_link: website });
        });
        setFetchStores(e?.CMS_Brand?.stores);
      },
    }
  );
  const { callCmsCreateAdvertiser, loading_cmsCreateAdvertiser } = useCmsAdvertiserApis();
  const {
    data: data_GET_CMS_CATEGORIES,
    loading: loading_GET_CMS_CATEGORIES,
    refetch: refetch_GET_CMS_CATEGORIES,
  } = useCustomQuery(GET_CMS_CATEGORIES_ID_NAME, {
    notifyOnNetworkStatusChange: true,
  });

  function fetchBrandDomains(id, isFetch = false) {
    isFetch ? CMS_Brand_Feched({ variables: { id } }) : CMS_Brand({ variables: { id } });
  }

  const [image, setImage] = useState("");
  const [inputs, setInputs] = useState({
    title: "",
    subTitle: "",
    slug: "",
    rating: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
  });
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [domains, setDomains] = useState([]);
  const [language, setLanguage] = useState("");
  const [campaignID, setCampaignId] = useState("");
  const [lists, setLists] = useState([]);
  const [listType, setListType] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [isFeaturePost, setIsFeaturePost] = useState(false);
  const [publishDate, setPublishDate] = useState(moment().format(dateFormat));
  const [brand, setBrand] = useState();
  const [products, setProducts] = useState([]);
  const [singleProduct, setSingleProduct] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [store, setStore] = useState([]);
  const [ctaButtonType, setCtaButtonType] = useState(CTA_BUTTON_TYPES[0].value);
  const [networkType, setNetworkType] = useState("");
  const [status, setStatus] = useState();
  const [starRating, setStarRating] = useState(0);
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const [productPros, setProductProps] = useState([]);
  const [productCons, setProductCons] = useState([]);
  const [skimlink, setSkimlink] = useState(false);

  // CMS api's variables
  let createApi = "",
    getApi = "",
    updateApi = "",
    listTypeBit = "",
    createApiKey = "",
    updateApiKey = "";

  //CRS api's variables

  // let crsGetAPi = "";
  // let crsGetAPi = GQL_GET_ARTICLE;

  let crsUpdateApi = GQL_UPDATE_ARTICLE;
  // crsGetAPi/crsUpdateApi handle with fixed api's with having typed based conditions

  if (postType === STORY_BIT) {
    createApi = CMS_CREATE_STORY;
    getApi = GET_CMS_STORY;
    updateApi = UPDATE_CMS_STORY;
  }
  if (postType === PRODUCT_BIT) {
    createApi = CMS_CREATE_PRODUCT;
    createApiKey = "CMS_CreateProduct";
    getApi = GET_CMS_PRODUCT;
    updateApi = UPDATE_CMS_PRODUCT;
    updateApiKey = "CMS_UpdateProduct";
    listTypeBit = "Product";
  }
  if (postType === LIST_BIT) {
    createApi = ADD_CMS_LIST;
    getApi = GET_CMS_LIST;
    updateApi = UPDATE_CMS_LIST;
  }
  if (postType === BLOCK_BIT) {
    createApi = ADD_CMS_BLOCK;
    createApiKey = "CMS_CreateBlock";
    getApi = GET_CMS_BLOCK;
    updateApi = UPDATE_CMS_BLOCK;
    updateApiKey = "CMS_UpdateBlock";
    listTypeBit = "Block";
  }
  if (postType === PAGE_BIT) {
    createApi = ADD_CMS_RODUCT;
    getApi = GET_CMS_PAGE;
    updateApi = UPDATE_CMS_PAGE;
  }
  if (postType === NETWORK_BIT) {
    createApi = ADD_CMS_AWIC_NETWORK;
    getApi = GET_CMS_AWIC_NETWORK;
    updateApi = UPDATE_CMS_AWIC_NETWORK;
  }
  if (postType === REVIEW_BIT) {
    createApi = ADD_CMS_REVIEW;
    getApi = GET_CMS_REVIEW;
    updateApi = UPDATE_CMS_REVIEW;
  }

  // const finalGetApi = view === CMS_POST_CONTENT_VIEW_TYPE_CRS ? crsGetAPi : getApi;
  // const getApiDefalutPayload =
  //   view === CMS_POST_CONTENT_VIEW_TYPE_CRS
  //     ? {
  //         // HERE WILL ADD CRS GET PAYLOAD dependennd api from yaman end
  //       }
  //     : { id: mainFormId };

  // const finalGetApi = view === CMS_POST_CONTENT_VIEW_TYPE_CRS ? crsGetAPi : getApi;

  // useEffect(() => {
  //   if (view === CMS_POST_CONTENT_VIEW_TYPE_EDIT) {
  //     callGetApi();
  //   }

  //   if (view === CMS_POST_CONTENT_VIEW_TYPE_CRS) {
  //     /**
  //      * WILL ADD
  //      * Here fetch CRS already added data with using callGetApi()
  //      * callGetApi() having condition (like passing crs id and set local data CRS based ) for handling content data
  //      *
  //      *
  //      */
  //     callGetApi({ variables: { reqId } });
  //   }
  // }, [view]);

  useEffect(() => {
    if (view === CMS_POST_CONTENT_VIEW_TYPE_EDIT || view === CMS_POST_CONTENT_VIEW_TYPE_CRS) {
      callGetApi();
    }
  }, [view]);

  const [callGetApi, { loading: loading_getApi = false, refetch: refetchGetApi }] =
    useCustomLazyQuery(getApi, {
      notifyOnNetworkStatusChange: true,
      variables: { id: mainFormId },

      onCompleted: (e) => {
        let element;
        if (postType === STORY_BIT) {
          element = e?.CMS_Story;
          setImage(element?.image);
          setContent(element?.content);
          setInputs({
            ...inputs,
            title: element?.title,
            subTitle: element?.sub_title,
            slug: element?.slug,
            metaTitle: element?.meta_title,
            metaDescription: element?.meta_description,
            metaKeywords: element?.meta_keywords,
          });
          setCategories(element?.categories?.map(({ id }) => id));
          setTags(element?.tags);
          // IN CRS domains to be fixed currently geeting different
          setDomains(element?.domains?.map(({ id }) => id));
          setCampaignId(element?.ncid);
          setPublishDate(element?.pub_date);
          setStatus(element?.status);
          setIsPrivate(element?.is_private);
          setIsFeaturePost(element?.is_feature_post);
          setSingleProduct(element?.product?.id);
          setStore(element?.stores?.map(({ id }) => id));
          // setProducts(element?.products);
          setSkimlink(element?.skimlink);
          setProducts(element?.products?.map((data) => data?.id));
          setLanguage(element?.language?.id);
        }
        if (postType === PRODUCT_BIT) {
          element = e?.CMS_Product;
          setImage(element?.image);
          setDescription(element?.description);
          setInputs({ ...inputs, title: element?.name, slug: element?.slug });
          setCategories(element?.categories?.map(({ id }) => id));
          setTags(element?.tags);
          setStatus(element?.status);
          setIsPrivate(element?.is_private);
          setBrand(element?.brand?.id);
          setLists(element?.lists?.map(({ id }) => id));

          if (element?.brand?.id) {
            fetchBrandDomains(element?.brand?.id, true);
          }
          setProductProps(element?.product_pros);
          setProductCons(element?.product_cons);
          setStarRating(element?.starRating);
          setLanguage(element?.language?.id);

          if (element?.offer_links) {
            let offerTemp = [];

            let temp = {};
            element?.offer_links?.forEach(
              ({ store_id, offer_link, offer_price, strike_price, currency }) => {
                offerTemp.push({ store_id, offer_link, offer_price, strike_price, currency });
                temp[store_id] = { offer_link, offer_price, strike_price, currency };
              }
            );
            setOfferLink(offerTemp);
            setOffersData(temp);
          }
        }
        if (postType === LIST_BIT) {
          // if (view === CMS_POST_CONTENT_VIEW_TYPE_CRS) {
          //   element = e?.crs_GetArticle?.data?.list;
          // } else {
          //   element = e?.CMS_List;
          // }

          element = e?.CMS_List;
          setImage(element?.image);
          setContent(element?.content);
          setDescription(element?.description);
          setConclusion(element?.bottom_line);
          setInputs({
            ...inputs,
            title: element?.title,
            slug: element?.slug,
            metaTitle: element?.meta_title,
            metaDescription: element?.meta_description,
            metaKeywords: element?.meta_keywords,
          });
          setCategories(element?.categories?.map(({ id }) => id));
          setTags(element?.tags);
          setListType(element?.list_type);
          element?.list_type && setActiveTab(element?.list_type);
          setDomains(element?.domains?.map(({ id }) => id));
          setCampaignId(element?.ncid);
          setStatus(element?.status);
          setIsPrivate(element?.is_private);
          setIsFeaturePost(element?.is_feature_post);
          // setProducts(element?.products); // Backup for new flow
          // setBlocks(element?.blocks);
          setProducts(element?.products?.map((data) => data?.id));
          setBlocks(element?.blocks?.map((data) => data?.id));
          setPublishDate(element?.pub_date);
          setCtaButtonType(element?.cta_button_type);
          setSkimlink(element?.skimlink);
          setLanguage(element?.language?.id);
        }
        if (postType === BLOCK_BIT) {
          element = e?.CMS_Block;
          setImage(element?.image);
          setDescription(element?.description);
          setInputs({ ...inputs, title: element?.title });
          setLists(element?.lists?.map(({ id }) => id));
          setStatus(element?.status);
          setIsPrivate(element?.is_private);
        }

        if (postType === PAGE_BIT) {
          element = e?.CMS_Page;
          setContent(element?.content);
          setInputs({ ...inputs, title: element?.title, slug: element?.slug });
          setDomains(element?.domains?.map(({ id }) => id));
          setPublishDate(element?.pub_date);
          setStatus(element?.status);
          setIsPrivate(element?.is_private);
        }

        if (postType === NETWORK_BIT) {
          element = e?.CMS_Awic_Network;
          setNetworkType(element?.type);
          setDescription(element?.description);
          setInputs({ ...inputs, title: element?.name });
        }
        if (postType === REVIEW_BIT) {
          element = e?.CMS_Review;
          setContent(element?.content);
          setInputs({ ...inputs, rating: element?.rating });
          setStatus(element?.status);
          setIsPrivate(element?.is_private);
          setSingleProduct(element?.product?.id);
        }
        isNested && parentRefetch();
      },
    });

  const [CREATE_POST, { loading: createLoading }] = useCustomMutation(createApi, {
    onCompleted(e) {
      openNotification({ type: "success", message: postType + " added successfully" });
      let id = 0;

      const whenDone = (recivedId) => {
        close();

        if (isNested) {
          parentRefetch();
          return null;
        }
        if (recivedId !== 0) {
          history.push(basePath + "/view/" + recivedId);
        } else {
          history.push(basePath);
        }
      };

      const addTags = (recivedId, addTagsExtraProps = {}) => {
        const { isLink = false, slug } = addTagsExtraProps;

        // will remove in future
        // let link_of_articleData = [];
        // let islink_of_article = {};
        // if (isLink) {
        //   function parseLinkOfArticle(domainData = []) {
        //     return domainData?.map(
        //       (domainMapData) => `https://${domainMapData?.domain_url}/${slug}`
        //     );
        //   }
        //   if (detectValueType(domains) === "string") {
        //     link_of_articleData = parseLinkOfArticle(CMS_Domains.filter((d) => domains === d?.id));
        //   }
        //   if (detectValueType(domains) === "array") {
        //     link_of_articleData = parseLinkOfArticle(
        //       CMS_Domains.filter((d) => domains.includes(d.id))
        //     );
        //   }
        //   if (listType === "Product") {
        //     // link_of_article is an array of string
        //     islink_of_article = { link_of_article: link_of_articleData[0] };
        //   } else {
        //     islink_of_article = { link_of_article: link_of_articleData };
        //   }
        // }
        // setArticleLink(islink_of_article);

        const mainTagData = cmsContentTagList?.map((data) => ({ ...data, article_id: recivedId }));

        callCmsCreateAdvertiser({
          onCompleted: (e) => {
            const element = e?.CMS_Create_Advertiser;
            if (element) {
              whenDone(recivedId);
            }
          },
          variables: { advertiser: mainTagData, ...articleLink },
        });
      };

      // in future change
      // if (postType === STORY_BIT) {
      //   id = e?.CMS_CreateStory?.id;
      //   if (cmsContentTagList?.length > 0) {
      //     return addTags(id, { isLink: true, slug: e?.CMS_CreateStory?.slug });
      //   }
      // }

      if (postType === PRODUCT_BIT) {
        id = e?.CMS_CreateProduct?.id;
        if (bannerImages && bannerImages?.length > 0) {
          addBanners(e?.CMS_CreateProduct?.id, bannerImages, {
            onCompleted: (e) => {
              const element = e?.CMS_Create_Banner;

              if (element) {
                // if (callCmsCreateAdvertiser?.length > 0) {
                //   return addTags(id);
                // }

                close();
                if (isNested) {
                  parentRefetch();
                  return null;
                }
                if (id !== 0) {
                  history.push(basePath + "/view/" + id);
                } else {
                  history.push(basePath);
                }
              }
            },
            onError(e) {
              openNotification({
                type: "error",
                message: "Banners not added",
                description: e?.message,
              });
              close();
              if (isNested) {
                parentRefetch();
                return null;
              }
              if (id !== 0) {
                history.push(basePath + "/view/" + id);
              } else {
                history.push(basePath);
              }
            },
          });

          return null;
        }
        // in future change
        // if (cmsContentTagList?.length > 0) {
        //   return addTags(id);
        // }
        // isNested && setListValues((prev) => [...prev, e?.CMS_CreateProduct]); // Backup for new flow
      }
      if (postType === LIST_BIT) {
        id = e?.CMS_CreateList?.id;
        if (cmsContentTagList?.length > 0) {
          return addTags(id, { isLink: true, slug: e?.CMS_CreateList?.slug });
        }
      }
      if (postType === BLOCK_BIT) {
        id = e?.CMS_CreateBlock?.id;
        // isNested && setListValues((prev) => [...prev, e?.CMS_CreateBlock]); // Backup for new flow
      }
      if (postType === PAGE_BIT) id = e?.CMS_Create_Page?.id;
      if (postType === NETWORK_BIT) id = e?.CMS_Create_Awic_Network?.id;
      if (postType === REVIEW_BIT) id = e?.CMS_CreateReview?.id;
      close();
      if (isNested) {
        parentRefetch();
        return null;
      }
      if (id !== 0) {
        history.push(basePath + "/view/" + id);
      } else {
        history.push(basePath);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [UPDATE_POST, { loading: updateLoading }] = useCustomMutation(updateApi, {
    onCompleted(e) {
      refetchGetApi();
      // Backup for new flow
      // if (postType === PRODUCT_BIT) {
      //   isNested &&
      //     setListValues((prev) => {
      //       // Create a shallow copy of the previous array
      //       const prevValues = [...prev];
      //       // Check if the index is valid
      //       if (indexForEdit >= 0 && indexForEdit < prevValues.length) {
      //         // Update the element at the specified index with e.CMS_CreateProduct
      //         prevValues[indexForEdit] = e?.CMS_UpdateProduct;
      //       } else {
      //         // Handle invalid index here, e.g., show an error message
      //         console.error("Invalid index:", indexForEdit);
      //       }
      //       // Return the updated array
      //       return prevValues;
      //     });
      // }
      // if (postType === BLOCK_BIT) {
      //   isNested &&
      //     setListValues((prev) => {
      //       const prevValues = [...prev];
      //       if (indexForEdit >= 0 && indexForEdit < prevValues.length) {
      //         prevValues[indexForEdit] = e?.CMS_UpdateBlock;
      //       } else {
      //         console.error("Invalid index:", indexForEdit);
      //       }
      //       return prevValues;
      //     });
      // }
      const tagWithoutTypename = cmsContentTagList?.map((tag) => ({
        id: tag?.id,
        tagId: tag?.tagId,
        article_id: tag?.article_id,
        article_type: tag?.article_type,
        network_acc_id: tag?.network_acc_id,
        advertiser_id: tag?.advertiser_id,
        advertiser_name: tag?.advertiser_name,
        country: tag?.country,
      }));
      const variables = {
        advertiser: tagWithoutTypename,
        ...articleLink,
      };
      if (postType === LIST_BIT) {
        callCmsUpdateAdvertiser({
          onCompleted: (e) => {
            // const element = e?.CMS_Update_Advertiser;
            // if (element) {
            //   // will be used in future
            //   // let temp = JSON.parse(JSON.stringify(cmsContentTagList));
            //   // const currentIndex = temp.findIndex((d) => d?.id === element?.id);
            //   // if (currentIndex !== -1) {
            //   //   temp[currentIndex] = { ...element, tagId: parseInt(element?.tagId) };
            //   //   setCmsContentTagList(temp);
            //   // }
            // }
          },
          variables: { ...variables },
        });
      }

      close();
      openNotification({ type: "success", message: postType + " Update Success" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [CRS_UPDATE_POST, { loading: crsUpdateLoading }] = useCustomMutation(crsUpdateApi, {
    onCompleted(e) {
      const element = e?.CRS_UpdateArticle;

      if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
        openNotification({
          type: "success",
          message: element?.message,
          key: "create-success-response",
        });
      } else if (element?.status === GQL_API_RESPONSE_STATUS_FAILURE) {
        openNotification({
          type: "error",
          message: element?.message,
          key: "create-error-response",
        });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const loading = createLoading || updateLoading || loading_cmsCreateAdvertiser;

  const { title, subTitle, slug, rating, metaTitle, metaDescription, metaKeywords } = inputs;

  let createVariables = {};

  if (postType === STORY_BIT) {
    const editProps = view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ? { id: mainFormId, slug } : {};

    createVariables = {
      content,
      sub_title: subTitle,
      image,
      status,
      tags,
      title,
      domain_id: domains && domains?.length > 0 ? domains?.map((i) => Number(i)) : [],
      category_id: categories && categories.length > 0 ? categories.map((i) => Number(i)) : [],
      pub_date: publishDate,
      is_private: isPrivate,
      is_feature_post: isFeaturePost,
      // product_id: products?.map((data) => Number(data?.id)), //BACKUP for new flow
      product_id: products?.map((data) => Number(data)),
      store_id: store?.map((i) => Number(i)),
      meta_title: metaTitle,
      meta_description: metaDescription,
      meta_keywords: metaKeywords,
      skimlink,
      ncid: campaignID,
      language_id: Number(language),
      ...editProps,
    };
  }

  if (postType === PRODUCT_BIT) {
    const editProps = view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ? { id: mainFormId, slug } : {};
    createVariables = {
      description,
      image,
      status,
      tags,
      list_id: lists && lists.length > 0 ? lists.map((i) => Number(i)) : [],
      name: title,
      category_id: categories && categories.length > 0 ? categories.map((i) => Number(i)) : [],
      is_private: isPrivate,
      offer_link: offerLink,
      brand_id: parseInt(brand),
      product_pros: productPros,
      product_cons: productCons,
      starRating,
      language_id: Number(language),
      ...editProps,
    };
  }
  let productBlock = { product_id: [], block_id: [] };
  if (listType === "Block") {
    // productBlock = { product_id: [], block_id: blocks?.map(({ id }) => id) }; //BACKUP for new flow
    productBlock = { product_id: [], block_id: blocks };
  }
  if (listType === "Product") {
    // productBlock = { product_id: products?.map(({ id }) => id), block_id: [] }; //BACKUP for new flow
    productBlock = { product_id: products, block_id: [] };
  }

  if (postType === LIST_BIT) {
    const editProps = view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ? { id: mainFormId, slug } : {};
    createVariables = {
      description,
      image,
      list_type: listType,
      status,
      tags,
      title,
      domain_id:
        domains && domains?.length > 0 && typeof domains !== STRING
          ? domains?.map((i) => Number(i))
          : "",
      category_id: categories && categories.length > 0 ? categories.map((i) => Number(i)) : [],
      is_private: isPrivate,
      is_feature_post: isFeaturePost,
      ...productBlock,
      bottom_line: conclusion,
      pub_date: publishDate,
      cta_button_type: ctaButtonType,
      meta_title: metaTitle,
      meta_description: metaDescription,
      meta_keywords: metaKeywords,
      skimlink,
      ncid: campaignID,
      language_id: Number(language),
      ...editProps,
    };
  }

  if (postType === BLOCK_BIT) {
    const editProps = view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ? { id: mainFormId } : {};
    createVariables = {
      description,
      image,
      status,
      title,
      list_id: lists && lists.length > 0 ? lists.map((i) => Number(i)) : [],
      is_private: isPrivate,
      ...editProps,
    };
  }

  if (postType === PAGE_BIT) {
    const editProps = view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ? { id: mainFormId, slug } : {};
    createVariables = {
      content,
      status,
      title,
      domain_id: domains && domains.length > 0 ? domains.map((i) => Number(i)) : [],
      pub_date: publishDate,
      is_private: isPrivate,
      ...editProps,
    };
  }

  // Assign the function to the ref
  useImperativeHandle(ref, () => ({
    handleCreate,
  }));

  if (postType === REVIEW_BIT) {
    const editProps = view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ? { id: mainFormId } : {};
    createVariables = {
      content,
      status,
      product_id: parseInt(singleProduct),
      is_private: isPrivate,
      rating,
      ...editProps,
    };
  }
  if (postType === NETWORK_BIT) {
    const editProps = view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ? { id: mainFormId } : {};
    createVariables = { name: title, description, type: networkType, ...editProps };
  }

  const validateUrl = (url) => {
    return String(url).toLowerCase().match(urlValidateRegex) ? true : false;
  };

  function handleCreate(props) {
    const finalAction = props?.action ? props.action : CRS_STATUS_ACTIONS_DRAFT;
    if (postType === PRODUCT_BIT) {
      const tempOfferLink = offerLink.map((data) => {
        const isValidLink = validateUrl(data.offer_link);
        return { ...data, isValidLink };
      });
      const haveError = tempOfferLink.filter(({ isValidLink = false }) => !isValidLink);
      if (haveError.length !== 0) {
        openNotification({ type: "error", message: "Offer link is not valid" });
        return null;
      }
    }
    if (postType === STORY_BIT || postType === LIST_BIT || postType === PAGE_BIT) {
      if (domains?.length === 0) {
        openNotification({ type: "error", message: "Please select domain" });
        return null;
      }
    }
    if (postType === LIST_BIT) {
      if (listType === "") {
        openNotification({ type: "error", message: "Please select list type" });
        return null;
      }
    }
    if (view === CMS_POST_CONTENT_VIEW_TYPE_CREATE) {
      CREATE_POST({
        variables: {
          ...createVariables,
          ...(typeof domains === STRING && { domain_id: [Number(domains)] }),
          status: props?.status,
        },
      });
    }
    if (view === CMS_POST_CONTENT_VIEW_TYPE_EDIT) {
      UPDATE_POST({
        variables: {
          ...createVariables,
          ...(typeof domains === STRING && { domain_id: [Number(domains)] }),
          staus: props?.status,
        },
      });
    }
    if (view === CMS_POST_CONTENT_VIEW_TYPE_CRS) {
      const {
        ncid, //SKIP THIS
        ...createVariablesRest
      } = createVariables || {};

      const isListPayload =
        postType === CMS_POST_TYPE_LIST ? { list: { ...createVariablesRest } } : {};
      const isStoryPayload =
        postType === CMS_POST_TYPE_STORY ? { story: { ...createVariablesRest } } : {};

      const payload = {
        cms: {
          ...isListPayload,
          ...isStoryPayload,
          //  status
        },
        action: finalAction,
        reqId,
      };

      CRS_UPDATE_POST({ variables: payload });
    }
  }

  function handleInput(e) {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  const renderTitle = () => (
    <div className="cms-title-input">
      <LookTextInput
        name="title"
        type="textarea"
        value={title}
        className="mb-0"
        placeholder="Add title"
        autoSize={{ minRows: 1, maxRows: 2 }}
        onChange={handleInput}
      />
    </div>
  );
  const renderRating = () => (
    <CollapseWraper title="Rating">
      <LookTextInput
        name="rating"
        value={rating}
        className="mb-0"
        placeholder="Add rating"
        onChange={handleInput}
      />
    </CollapseWraper>
  );
  const renderSlug = () => (
    <CollapseWraper title="Slug">
      <LookTextInput
        name="slug"
        value={slug}
        className="mb-0"
        placeholder="Add slug"
        onChange={handleInput}
      />
    </CollapseWraper>
  );
  const renderSubTitle = () => (
    <Form layout="vertical">
      <LookTextInput
        name="subTitle"
        type="textarea"
        value={subTitle}
        className="cms-sub-title-input"
        placeholder="Add sub title"
        onChange={handleInput}
      />
    </Form>
  );
  const renderContent = () => (
    <Form layout="vertical">
      <ReactQuillForm
        loading={loading_getApi}
        ref={quillContentRef}
        contentElemetTags={contentElemetTags}
        setContentElemetTags={setContentElemetTags}
        placeholder="Enter Content Here"
        className="cms-react-quill full-page"
        value={content}
        onChange={setContent}
      />
    </Form>
  );

  const [counterTagDelete, setCounterTagDelete] = useState(0);

  useEffect(() => {
    if (counterTagDelete !== 0) {
      handleCreate({ status });
    }
  }, [counterTagDelete]);

  useEffect(() => {
    let link_of_articleData = [];
    let islink_of_article = {};
    if (postType === STORY_BIT || postType === LIST_BIT) {
      function parseLinkOfArticle(domainData = []) {
        return domainData?.map((domainMapData) => `https://${domainMapData?.domain_url}/${slug}`);
      }

      if (detectValueType(domains) === "string") {
        link_of_articleData = parseLinkOfArticle(CMS_Domains.filter((d) => domains === d.id));
      }
      if (detectValueType(domains) === "array") {
        link_of_articleData = parseLinkOfArticle(CMS_Domains.filter((d) => domains.includes(d.id)));
      }
      if (listType === "Product") {
        // link_of_article is an array of string
        islink_of_article = { link_of_article: link_of_articleData[0] };
      } else {
        islink_of_article = { link_of_article: link_of_articleData };
      }
      setArticleLink(islink_of_article);
    }
  }, [postType, domains]);

  const renderTagView = (isRender = false) => {
    if (!isRender) return null;

    let articleType = "";

    if (postType === STORY_BIT) articleType = CMS_ARTICLE_TYPE_STORY;
    if (postType === PRODUCT_BIT) articleType = CMS_ARTICLE_TYPE_PRODUCT;
    if (postType === LIST_BIT) articleType = CMS_ARTICLE_TYPE_LIST;

    return (
      <CollapseWraper title="CTA List">
        <CmsContentTagListHandlerView
          {...{
            isLink: postType === STORY_BIT || postType === LIST_BIT,
            slug,
            domains,
            mainFormId,
            articleType,
            view,
            cmsContentTagList,
            setCmsContentTagList,
            listType: listType,

            onDelete: (tagName) => {
              quillContentRef?.current?.handleRemoveAllOccurrences(tagName);
              setCounterTagDelete(counterTagDelete + 1);
            },
          }}
          applyTag={quillContentRef?.current?.handleApplyTagFromSelectedText}
          removeCurrentTags={quillContentRef?.current?.handleRemoveAllOccurrences}
        />
        {/* BACKUP FOR SELECTED LIST */}
        <CmsContentSelectedTagListHandlerView
          tags={contentElemetTags}
          removeTagItem={quillContentRef?.current?.handleRemoveTag}
        />
      </CollapseWraper>
    );
  };

  useEffect(() => {
    if (!loading_getApi) {
      quillContentRef?.current?.parseQuillContentAndUpdateList();
    }
  }, [loading_getApi]);

  const renderDescription = () => (
    <Form layout="vertical">
      <ReactQuillForm
        loading={loading_getApi}
        ref={quillContentRef}
        contentElemetTags={contentElemetTags}
        setContentElemetTags={setContentElemetTags}
        placeholder="Enter Content Here"
        className="cms-react-quill"
        value={description}
        onChange={setDescription}
      />
    </Form>
  );

  const renderProductLits = () => (
    <CollapseWraper title="Products">
      <ProductHandle value={singleProduct} onChange={setSingleProduct} />
    </CollapseWraper>
  );

  const renderCategories = () => (
    <CollapseWraper title="Categories">
      <CategoriesSelection selectedCategories={categories} onChange={setCategories} />
    </CollapseWraper>
  );

  const renderList = () => <ListHandle type={listTypeBit} lists={lists} onChange={setLists} />;

  function handleListType(e) {
    setActiveTab(e);
    setListType(e);
  }

  const renderListType = () => (
    <CollapseWraper title="List Type">
      <ListTypeHandle listType={listType} onChange={handleListType} />
    </CollapseWraper>
  );

  const rendeNetworkType = () => (
    <CollapseWraper title="Network Type">
      <NetworkTypeHandle value={networkType} onChange={setNetworkType} />
    </CollapseWraper>
  );

  const renderThumbnail = () => (
    <CollapseWraper title="Thumbnail">
      <FileHandle image={image} setImage={setImage} />
    </CollapseWraper>
  );

  const renderCampaign = () => (
    <CollapseWraper title="Campaign">
      <CampaingHandle value={campaignID} onChange={setCampaignId} />
    </CollapseWraper>
  );

  const renderDomain = () => (
    <CollapseWraper title="Domain">
      <DomiansHandle
        domains={domains}
        onChange={setDomains}
        listType={listType}
        postType={postType}
      />
    </CollapseWraper>
  );

  const renderLanguage = () => (
    <CollapseWraper title="Language">
      <LanguageHandle language={language} onChange={setLanguage} />
    </CollapseWraper>
  );

  // const renderTrackerFields = () => (
  //   <CollapseWraper title="Tracker Fields">
  //     <TrackerFieldsHandle domains={domains} onChange={setDomains} />
  //   </CollapseWraper>
  // );

  const handleBrand = (e) => {
    setBrand(e);
    fetchBrandDomains(e);
  };

  const renderBrands = () => (
    <CollapseWraper title="Brands">
      <BrandHandle value={brand} onChange={handleBrand} />
    </CollapseWraper>
  );

  const renderTags = () => (
    <CollapseWraper title="Tags">
      {!loading_getApi && <TagsHandle tags={tags} onChange={setTags} />}
    </CollapseWraper>
  );
  const renderCTA_Type = () => (
    <CollapseWraper title="CTA Type">
      <SelectInput options={CTA_BUTTON_TYPES} value={ctaButtonType} onChange={setCtaButtonType} />
    </CollapseWraper>
  );

  const contentTab =
    postType === STORY_BIT
      ? [
          {
            id: "Content",
            title: "Content",
            component: <div className="cms-sub-content">{renderSubTitle()}</div>,
          },
        ]
      : [];

  const listTab =
    postType === BLOCK_BIT || postType === PRODUCT_BIT
      ? [
          {
            id: "List",
            title: "List",
            component: <div className="cms-sub-content">{renderList()}</div>,
          },
        ]
      : [];

  const sidesTab =
    postType === PRODUCT_BIT
      ? [
          {
            id: "sides",
            title: "Pros/Cons",
            component: (
              <div className="cms-sub-content">
                <SidesHandle />
              </div>
            ),
          },
        ]
      : [];

  const offerLinkTab =
    postType === PRODUCT_BIT
      ? [
          {
            id: "offerLinks",
            title: "Offer Links",
            component: (
              <div className="cms-sub-content">
                <StoreOfferLinksHandle
                  stores={fetchedStores}
                  values={offersData}
                  onChange={handleOffersData}
                />
              </div>
            ),
          },
        ]
      : [];

  const productTab =
    (postType === LIST_BIT && listType === "Product") || postType === STORY_BIT
      ? [
          {
            id: "Product",
            title: "Product",
            component: (
              <div className="cms-sub-content">
                <SelectStoreHandle
                  type="product"
                  placeholder="Select Product"
                  mode="multiple"
                  value={products}
                  onChange={setProducts}
                />
              </div>
            ),
          },
        ]
      : [];

  const storeTab =
    postType === STORY_BIT
      ? [
          {
            id: "store",
            title: "Store",
            component: (
              <div className="cms-sub-content">
                <SelectStoreHandle
                  type="store"
                  placeholder="Select Store"
                  mode="multiple"
                  value={store}
                  onChange={setStore}
                />
              </div>
            ),
          },
        ]
      : [];

  const conclusionTab =
    postType === LIST_BIT && listType === "Product"
      ? [
          {
            id: "conclusion",
            title: "Conclusion",
            component: (
              <div className="cms-sub-content">
                <ReactQuillForm
                  placeholder="Enter Conclusion Here"
                  className="cms-react-quill"
                  value={conclusion}
                  onChange={setConclusion}
                />
              </div>
            ),
          },
        ]
      : [];

  const blockTab =
    postType === LIST_BIT && listType === "Block"
      ? [
          {
            id: "Block",
            title: "Block",
            component: (
              <div className="cms-sub-content">
                <SelectStoreHandle
                  type="block"
                  placeholder="Select Block"
                  mode="multiple"
                  value={blocks}
                  onChange={setBlocks}
                />
              </div>
            ),
          },
        ]
      : [];

  let article_type = "";
  if (postType === LIST_BIT) article_type = "List";
  if (postType === STORY_BIT) article_type = "Story";

  const networkRulesTab =
    view === CMS_POST_CONTENT_VIEW_TYPE_EDIT && (postType === LIST_BIT || postType === STORY_BIT)
      ? [
          {
            id: "networkRules",
            title: "Network Rules",
            component: (
              <div className="cms-sub-content">
                <NetworkRules
                  article_type={article_type}
                  article_id={params?.id}
                  domains={domains}
                />
              </div>
            ),
          },
        ]
      : [];

  const seoTab =
    postType === LIST_BIT || postType === STORY_BIT
      ? [
          {
            id: "seo",
            title: "Seo",
            component: (
              <div className="cms-sub-content">
                <SeoHandle />
              </div>
            ),
          },
        ]
      : [];

  const bannerTab =
    postType === PRODUCT_BIT
      ? [
          {
            id: "banner",
            title: "Banner",
            component: (
              <div className="cms-sub-content">
                {view === CMS_POST_CONTENT_VIEW_TYPE_CREATE ? bannerAddView() : bannerUpdateView()}

                {/* <BannerHandle /> */}
              </div>
            ),
          },
        ]
      : [];
  const tabContent = [
    ...contentTab,
    ...listTab,
    ...offerLinkTab,
    ...sidesTab,
    ...productTab,
    ...storeTab,
    ...conclusionTab,
    ...blockTab,
    ...seoTab,
    ...networkRulesTab,
    ...bannerTab,
  ];

  const showPreviewDrawer = (rowProps = {}) => {
    setRowProps({ ...rowProps, allCategories: data_GET_CMS_CATEGORIES?.CMS_Categories || [] });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setRowProps({});
    setVisibleDetailDrawer(false);
  };

  const previewDrawerProps = { visible: visibleDetailDrawer, onClose: onCloseDetailDrawer };

  const renderStarRating = () => (
    <CollapseWraper title={`Rating (${starRating})`}>
      <Rate allowHalf onChange={setStarRating} value={starRating} style={{ fontSize: 26 }} />
    </CollapseWraper>
  );

  const renderPublish = () => (
    <CollapseWraper title="Publish">
      <div>
        {postType !== NETWORK_BIT && (
          <div>
            <div className="flex-between mb-16">
              {(postType === STORY_BIT || postType === LIST_BIT || postType === PAGE_BIT) && (
                <PublishDateHandle publishDate={publishDate} onChange={setPublishDate} />
              )}
              {view === CMS_POST_CONTENT_VIEW_TYPE_EDIT && (
                <div>
                  <p className="mb-0">
                    Status: <b>{status}</b>
                    {status && (
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item onClick={() => setStatus("Draft")}>Draft</Menu.Item>
                            <Menu.Item onClick={() => setStatus("Published")}>Published</Menu.Item>
                          </Menu>
                        }
                      >
                        <Tooltip title="Edit Status">
                          <Button
                            type="link"
                            className="h-auto p-0"
                            icon={<ActionIconRender iconType="edit" />}
                          />
                        </Tooltip>
                      </Dropdown>
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className="flex-between">
              <VisibilityHandle isPrivate={isPrivate} onChange={setIsPrivate} />
              {(postType === STORY_BIT || postType === LIST_BIT) && (
                <SwitchHandle
                  title="Feature Post"
                  value={isFeaturePost}
                  onChange={setIsFeaturePost}
                />
              )}
            </div>
            {(postType === LIST_BIT || postType === STORY_BIT) && (
              <div>
                <p>
                  Skimlink: <Switch size="small" checked={skimlink} onChange={setSkimlink} />
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        <div className="flex-between">
          {view === CMS_POST_CONTENT_VIEW_TYPE_CREATE && (
            <>
              {postType !== NETWORK_BIT && (
                <div>
                  <Button onClick={() => handleCreate({ status: "Draft" })} disabled={loading}>
                    Save Draft
                  </Button>
                </div>
              )}
              <Button
                type="primary"
                disabled={loading}
                onClick={() => handleCreate({ status: "Published" })}
              >
                Publish
              </Button>
            </>
          )}
          {(view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ||
            view === CMS_POST_CONTENT_VIEW_TYPE_CRS) && (
            <>
              <Button
                type="link"
                className="px-0"
                onClick={() => showPreviewDrawer(createVariables)}
              >
                Preview
              </Button>
              <Tooltip
                title={
                  disabledSubmit
                    ? "You can't update"
                    : view === CMS_POST_CONTENT_VIEW_TYPE_CRS
                    ? "Click here to save as draft"
                    : "Click here to update"
                }
              >
                <Button
                  disabled={loading_getApi || loading || crsUpdateLoading || disabledSubmit}
                  type="primary"
                  onClick={() => handleCreate({ status })}
                >
                  {view === CMS_POST_CONTENT_VIEW_TYPE_CRS ? "Save Draft" : "Update"}
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </CollapseWraper>
  );

  const contextProps = {
    productPros,
    setProductProps,
    productCons,
    setProductCons,
    inputs,
    handleInput,
  };

  return (
    <>
      <div>
        <CMSPostHandleContext.Provider value={contextProps}>
          <Preview postType={postType} {...previewDrawerProps} {...rowPropsData} />
          {loading_getApi && <Loader fullScreen />}
          <div
            className={cx("post-handle-layout", {
              typeCrs: view === CMS_POST_CONTENT_VIEW_TYPE_CRS,
            })}
          >
            <div className="form-content position-relative">
              <div className="form-content-view">
                <div className="position-relative">
                  {postType !== REVIEW_BIT && renderTitle()}
                  {(postType === STORY_BIT || postType === PAGE_BIT || postType === REVIEW_BIT) &&
                    renderContent()}
                  {(postType === PRODUCT_BIT || postType === LIST_BIT || postType === BLOCK_BIT) &&
                    renderDescription()}
                </div>
                {postType === NETWORK_BIT && (
                  <div className="px-100">
                    <Form layout="vertical">
                      <LookTextInput
                        height={400}
                        name="subTitle"
                        type="textarea"
                        value={description}
                        className="mb-0"
                        placeholder="Add description"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form>
                  </div>
                )}

                <div className="cms-sub-content-wrapper">
                  <TabRender
                    className="top-bar"
                    fullBtn
                    tabContent={tabContent}
                    value={activeTab}
                    onChange={setActiveTab}
                  />
                </div>
                <DebugPre
                  content={[
                    { contentObjects: [{ offersData, offerLink }] },
                    {
                      maxHeight: "auto",
                      contentObjects: [
                        {
                          status,
                          ctaButtonType,
                          store,
                          products,
                          fetchedStores,
                          networkType,
                          blocks,
                          brand,
                          image,
                          isPrivate,
                          listType,
                          domains,
                          tags,
                          content,
                          inputs,
                          categories,
                        },
                      ],
                    },
                  ]}
                />
              </div>
              {view === CMS_POST_CONTENT_VIEW_TYPE_CRS && (
                <div>{postHandleStatusButtonAction()}</div>
              )}
            </div>

            <div className="form-action">
              {view === CMS_POST_CONTENT_VIEW_TYPE_CRS && (
                <div className="d-flex justify-content-between align-items-center">
                  {renderPromptView()}
                  <div className="segment-view">
                    <div className="d-table ml-auto">
                      <Segmented
                        options={[POST_ACTION_VIEW_EDITOR, POST_ACTION_VIEW_COMMENT]}
                        value={postActionView}
                        onChange={setPostActionView}
                      />
                    </div>
                  </div>
                </div>
              )}
              {view === CMS_POST_CONTENT_VIEW_TYPE_CRS && (
                <div className={cx({ "d-none": postActionView === POST_ACTION_VIEW_EDITOR })}>
                  {renderCommentView()}
                </div>
              )}
              <div className="action-items">
                {postActionView === POST_ACTION_VIEW_EDITOR && (
                  <>
                    {renderPublish()}
                    {postType === PRODUCT_BIT && renderStarRating()}
                    {
                      // for now tag are only available for listType
                      // postType === STORY_BIT ||
                      // postType === PRODUCT_BIT ||
                      postType === LIST_BIT && listType === "Product" && renderTagView(true)
                    }
                    {(view === CMS_POST_CONTENT_VIEW_TYPE_EDIT ||
                      view === CMS_POST_CONTENT_VIEW_TYPE_CRS) &&
                      (postType === STORY_BIT ||
                        postType === PRODUCT_BIT ||
                        postType === LIST_BIT ||
                        postType === PAGE_BIT) &&
                      renderSlug()}
                    {!(
                      postType === PAGE_BIT ||
                      postType === NETWORK_BIT ||
                      postType === REVIEW_BIT
                    ) && renderThumbnail()}
                    {
                      // Show campaing field only to this worksapce 139
                      selectedWorkspace === 139 &&
                        (postType === STORY_BIT || postType === LIST_BIT) &&
                        renderCampaign()
                    }
                    {postType === LIST_BIT && renderListType()}
                    {(postType === STORY_BIT || postType === LIST_BIT || postType === PAGE_BIT) &&
                      renderDomain()}

                    {(postType === STORY_BIT ||
                      postType === LIST_BIT ||
                      postType === PRODUCT_BIT) &&
                      renderLanguage()}
                    {postType === REVIEW_BIT && renderRating()}
                    {postType === REVIEW_BIT && renderProductLits()}
                    {postType === PRODUCT_BIT && renderBrands()}
                    {postType === LIST_BIT && listType === "Product" && renderCTA_Type()}
                    {(postType === STORY_BIT ||
                      postType === PRODUCT_BIT ||
                      postType === LIST_BIT) &&
                      renderTags()}
                    {postType === NETWORK_BIT && rendeNetworkType()}
                    {(postType === STORY_BIT ||
                      postType === PRODUCT_BIT ||
                      postType === LIST_BIT ||
                      (postType === NETWORK_BIT && networkType === "Direct")) &&
                      renderCategories()}
                    {/* {(postType === STORY_BIT || postType === LIST_BIT || postType === PAGE_BIT) &&
                      renderTrackerFields()} */}
                  </>
                )}
              </div>
            </div>
          </div>
        </CMSPostHandleContext.Provider>
      </div>
    </>
  );
});

export default MainForm;
