import { Button, Card, Image } from "antd";

export default function CardLaunchAdSet(props) {
  const { title } = props;
  const tempImage =
    "https://images.unsplash.com/photo-1657795442089-80deda8a09f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=340&q=80";
  return (
    <Card className="card-launch-ad-set">
      <div className="d-flex">
        <div>
          <Image />
        </div>
        <div>
          <h2 className="title">{title}</h2>
          <p className="sponsored">Sponsored</p>
        </div>
      </div>
      <div>
        <p className="description">
          Better products start with better teamwork. Figma is the only design platform that lets
          you...<span>more</span>
        </p>
        <div>
          <Image src={tempImage} />
        </div>
        <p className="site-link">Figma.com</p>
        <div className="d-flex">
          <div className="flex-grow-1">
            <p className="bottom-description">
              Try Figma today and experience the difference for yourself...more
            </p>
          </div>
          <Button className="btn-more">Learn More</Button>
        </div>
      </div>
    </Card>
  );
}
