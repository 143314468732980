import { notification } from "antd";
import cx from "cx";

export default function openNotification({
  type = "open",
  message = "",
  description = "",
  large = false,
  className = "",
  ...extraProps
}) {
  notification[type]({ message, description, className: cx(className, { large }), ...extraProps });
}
