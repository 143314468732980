import { Form } from "antd";
import { CMS_LIST_TYPES } from "components/PostHandle/view/PageBits";
import SelectInput from "../../../forms/SelectInput";

export default function ListTypeHandle(props) {
  const { listType, onChange = () => {} } = props;

  return (
    <div className="position-relative">
      <Form layout="vertical">
        <Form.Item className="look-form-input mb-0">
          <SelectInput
            className="mb-0"
            name="listType"
            value={listType}
            placeholder="Select List Type"
            onChange={onChange}
            options={CMS_LIST_TYPES}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
