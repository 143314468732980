import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  CREATIVE_TRACKING_WORKSPACE_ADMIN,
  DMS_USER,
  UMS_SUPER_ADMIN,
} from "shared/UserPermission/userPermissionList";

import route, { ROUTE_DASHBOARD } from "../route";

const S2SBusinessManager = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "BusinessManager" */ "pages/dashboardPages/Tools/S2S/BusinessManager"
    )
  )
);
const S2SLineOfBusiness = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "LineOfBusiness" */ "pages/dashboardPages/Tools/S2S/LineOfBusiness")
  )
);
const S2SPixels = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "S2S-Pixels" */ "pages/dashboardPages/Tools/S2S/Pixels")
  )
);
const S2SVertical = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Vertical" */ "pages/dashboardPages/Tools/S2S/Vertical")
  )
);
const ToolDomains = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "ToolDomains" */ "pages/dashboardPages/Tools/DMS/ToolDomains")
  )
);
const ToolUsers = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "ToolUsers" */ "pages/dashboardPages/Tools/DMS/ToolUsers")
  )
);
const BigSpy = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "BigSpy" */ "pages/dashboardPages/SAS/Configuration/BigSpy")
  )
);

const dmsRoutes = [
  route("/dms/accounts", ToolUsers, ROUTE_DASHBOARD, false, UMS_SUPER_ADMIN),
  route("/dms/domains", ToolDomains, ROUTE_DASHBOARD, false, DMS_USER),
  route("/dms/pixels", S2SPixels, ROUTE_DASHBOARD, false, UMS_SUPER_ADMIN),
  route("/dms/line-of-business", S2SLineOfBusiness, ROUTE_DASHBOARD, false, UMS_SUPER_ADMIN),
  route("/dms/verticals", S2SVertical, ROUTE_DASHBOARD, false, UMS_SUPER_ADMIN),
  route("/dms/business-manager", S2SBusinessManager, ROUTE_DASHBOARD, false, UMS_SUPER_ADMIN),
  route("/dms/bigspy", BigSpy, ROUTE_DASHBOARD, true, [
    UMS_SUPER_ADMIN,
    CREATIVE_TRACKING_WORKSPACE_ADMIN,
  ]),
];

export default dmsRoutes;
