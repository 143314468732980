import { Col, Form, Row } from "antd";
import LookButton from "components/LookButton";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { removeObjectByIndex } from "utils";

export default function StructuredSnippetsForm(props) {
  const {
    close = () => {},
    addNew = () => {},
    update = () => {},
    structuralSnippets,
    editIndex,
    setEditIndex,
  } = props;

  const editMode = editIndex !== -1;

  const { googleLaunchConfig = {} } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const { campaign = {} } = googleLaunchConfig;
  const { header: header_dropdown = [] } = campaign;
  const defaultData = { header: "", values: ["", "", ""] };
  const [inputs, setInputs] = useState(defaultData);

  useEffect(() => {
    if (editMode) {
      setInputs(structuralSnippets[editIndex]);
    } else {
      setInputs(defaultData);
    }
  }, [editIndex]);

  const { header, values = [] } = inputs;

  const headerLanguage = [{ name: "English", value: "English" }];

  function handleInputs(name, value) {
    setInputs((pre) => ({ ...pre, [name]: value }));
  }

  function handleValues(index, value) {
    let temp = JSON.parse(JSON.stringify(values));
    temp[index] = value;
    handleInputs("values", temp);
  }
  function addNewValue(index, value) {
    let temp = JSON.parse(JSON.stringify(values));
    temp.push("");
    handleInputs("values", temp);
  }
  const onDeleteValue = (index) => {
    let temp = JSON.parse(JSON.stringify(values));
    temp = removeObjectByIndex(temp, index);
    handleInputs("values", temp);
  };

  const renderValues = () => (
    <div>
      {values?.map((value, index) => {
        const count = index + 1;
        return (
          <div className="d-flex align-items-end">
            <LookTextInput
              label={`Value ${count}`}
              className="flex-grow-1 mb-0"
              placeholder={`Enter value ${count}`}
              value={value}
              onChange={(e) => handleValues(index, e.target.value)}
            />
            {index > 2 && (
              <LookButton
                type="link"
                danger
                iconType="delete"
                onClick={() => onDeleteValue(index)}
              />
            )}
          </div>
        );
      })}
    </div>
  );

  function onSubmitCreate() {
    setInputs(defaultData);
    addNew(inputs);
    close();
  }

  function onSubmitUpdate() {
    close();
    update(editIndex, inputs);
    setEditIndex(-1);
  }
  return (
    <div>
      <Form layout="vertical">
        <div>
          <Row gutter={10}>
            <Col span={10}>
              {/* English is default and this is not used in Api */}
              <SelectInput label="Header" options={headerLanguage} value="English" />
            </Col>
            <Col span={14}>
              <SelectInput
                search
                label="Header type"
                value={header}
                options={header_dropdown}
                onChange={(e) => handleInputs("header", e)}
              />
            </Col>
          </Row>
        </div>
        {renderValues()}
        <LookButton type="dashed" block onClick={addNewValue}>
          Click here to add new value
        </LookButton>
        <div>
          <LookButton onClick={close}>Cancel</LookButton>
          <LookButton onClick={editMode ? onSubmitUpdate : onSubmitCreate}>
            {editMode ? "Update" : "Create"}
          </LookButton>
        </div>
      </Form>
    </div>
  );
}
