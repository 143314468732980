import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { usePermissionCheck } from "hooks";
import React, { useState } from "react";
import {
  ADD_MOODBOARD_ANGLES,
  ADD_MOODBOARD_COPY_POINTS,
  ADD_MOODBOARD_CTA,
  ADD_MOODBOARD_HOOKS,
  ADD_MOODBOARD_PAIN_POINTS,
  ADD_MOODBOARD_SCRIPTS,
  DELETE_MOODBOARD_CTA,
  DELETE_MOODBOARD_PAINPOINT,
  GET_MOODBOARD_ANGLES,
  GET_MOODBOARD_COPY_POINTS,
  GET_MOODBOARD_CTA,
  GET_MOODBOARD_HOOKS,
  GET_MOODBOARD_PAIN_POINTS,
  GET_MOODBOARD_SCRIPTS,
  DELETE_MOODBOARD_SCRIPTS,
  DELETE_MOODBOARD_HOOKS,
  DELETE_MOODBOARD_ANGLE,
  DELETE_MOODBOARD_COPYPOINT,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import {
  CREATIVE_TRACKING_CONTENT_WRITER,
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  CREATIVE_TRACKING_MEDIA_BUYER,
} from "shared/UserPermission/userPermissionList";
import MoodBoard from "../Category/CreateMoodBoard";
import ComplianceForm from "../Category/CreateMoodBoard/ComplianceForm";
import MoodBoardDashboard from "../Category/CreateMoodBoard/MoodBoardDashboard";
import {
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COMPLIANCE,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_PAIN_POINTS,
  MOODBOARD_TYPE_SCRIPT,
} from "../creativesEnum";
function useMoodBoardDrawer() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState("MoodBoard Dashboard");
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const { havePermission: isMediaBuyer } = usePermissionCheck(CREATIVE_TRACKING_MEDIA_BUYER);
  const { havePermission: isContentWriter } = usePermissionCheck(CREATIVE_TRACKING_CONTENT_WRITER);
  const { havePermission: isGraphicDesigner } = usePermissionCheck(
    CREATIVE_TRACKING_GRAPHIC_DESIGNER
  );
  const permissionProps = {
    isMediaBuyer,
    isCreativeDirector,
    isGraphicDesigner,
  };
  const createMoodBoard = (data) => {
    setVisibleDrawer(true);
    setSelectedRowsData(data);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    setSelectedRowsData([]);
    setActiveTab("CopyPoints");
  };
  const drawerProps = {
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 1200,
    textBreadcrumb: ["Creative Moodboard", selectedRowsData?.name, activeTab],
  };
  const tabContent = [
    {
      id: "MoodBoard Dashboard",
      hoverTitle: "MoodBoard Dashboard",
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="dashboard" {...e} />,
      component: activeTab === "MoodBoard Dashboard" && (
        <MoodBoardDashboard
          actionType={MOODBOARD_TYPE_COMPLIANCE}
          query={GET_MOODBOARD_CTA}
          // placeHolderKey="Compliance"
          apiDataType="ctGetCTA"
          {...selectedRowsData}
          {...permissionProps}
        />
      ),
    },
    {
      id: "CopyPoints",
      noSpace: true,
      hoverTitle: "CopyPoints",
      icon: (e) => <ActionIconRender iconType="rectangleList" {...e} />,
      component: activeTab === "CopyPoints" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_COPY_POINTS}
          query={GET_MOODBOARD_COPY_POINTS}
          mutation={ADD_MOODBOARD_COPY_POINTS}
          apiDataType="ctGetCopyPoints"
          placeHolderKey="Copy Points"
          creativeType="COPY_POINT"
          {...selectedRowsData}
          deleteQuery={DELETE_MOODBOARD_COPYPOINT}
          {...permissionProps}
        />
      ),
    },
    {
      id: "Angles",
      noSpace: true,
      hoverTitle: "Angles",
      icon: (e) => <ActionIconRender iconType="sunAngle" {...e} />,
      component: activeTab === "Angles" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_ANGLES}
          query={GET_MOODBOARD_ANGLES}
          apiDataType="ctGetAngles"
          placeHolderKey="Angles"
          creativeType="ANGLE"
          mutation={ADD_MOODBOARD_ANGLES}
          {...selectedRowsData}
          deleteQuery={DELETE_MOODBOARD_ANGLE}
          {...permissionProps}
        />
      ),
    },
    {
      id: "Hooks",
      noSpace: true,
      hoverTitle: "Hooks",
      icon: (e) => <ActionIconRender iconType="hook" {...e} />,
      component: activeTab === "Hooks" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_HOOKS}
          query={GET_MOODBOARD_HOOKS}
          placeHolderKey="Hooks"
          creativeType="HOOK"
          mutation={ADD_MOODBOARD_HOOKS}
          apiDataType="ctGetHooks"
          {...selectedRowsData}
          deleteQuery={DELETE_MOODBOARD_HOOKS}
          {...permissionProps}
        />
      ),
    },
    {
      id: "PainPoints",
      hoverTitle: "Pain Points",
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="pain" {...e} />,
      component: activeTab === "PainPoints" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_PAIN_POINTS}
          query={GET_MOODBOARD_PAIN_POINTS}
          placeHolderKey="Pain Points"
          creativeType="PAIN_POINT"
          mutation={ADD_MOODBOARD_PAIN_POINTS}
          deleteQuery={DELETE_MOODBOARD_PAINPOINT}
          apiDataType="ctGetPainPoints"
          {...selectedRowsData}
          {...permissionProps}
        />
      ),
    },

    {
      id: "CTA",
      noSpace: true,
      hoverTitle: "CTA",
      icon: (e) => <ActionIconRender iconType="cta" {...e} />,
      component: activeTab === "CTA" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_CTA}
          query={GET_MOODBOARD_CTA}
          mutation={ADD_MOODBOARD_CTA}
          placeHolderKey="CTA"
          apiDataType="ctGetCTAs"
          creativeType="CTA"
          deleteQuery={DELETE_MOODBOARD_CTA}
          {...selectedRowsData}
          {...permissionProps}
        />
      ),
    },
    {
      id: "Scripts",
      hoverTitle: "Scripts",
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="script-outline" {...e} />,
      component: activeTab === "Scripts" && (
        <MoodBoard
          actionType={MOODBOARD_TYPE_SCRIPT}
          query={GET_MOODBOARD_SCRIPTS}
          apiDataType="ctGetScripts"
          placeHolderKey="Scripts"
          mutation={ADD_MOODBOARD_SCRIPTS}
          {...selectedRowsData}
          creativeType="SCRIPT"
          deleteQuery={DELETE_MOODBOARD_SCRIPTS}
          {...permissionProps}
        />
      ),
    },

    {
      id: "Compliance",
      hoverTitle: "Compliance",
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="compliance" {...e} />,
      component: activeTab === "Compliance" && (
        <ComplianceForm
          actionType={MOODBOARD_TYPE_COMPLIANCE}
          query={GET_MOODBOARD_CTA}
          placeHolderKey="Compliance"
          apiDataType="ctGetCTAs"
          creativeType="COMPLIANCE"
          {...selectedRowsData}
          onCloseDrawer={onCloseDrawer}
          {...permissionProps}
        />
      ),
    },
  ];
  function createForm() {
    return (
      <>
        <LookDrawer {...drawerProps}>
          {visibleDrawer && (
            <LookDrawerMainSideBar
              value={activeTab}
              onChange={setActiveTab}
              tabContent={tabContent}
            />
          )}
        </LookDrawer>
      </>
    );
  }
  return { createForm, createMoodBoard };
}

export default useMoodBoardDrawer;
