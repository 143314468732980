import { ApartmentOutlined } from "@ant-design/icons";
import { CustomeIconWrapper } from "..";
import { ICampaignsIcon } from "../../../components/svgComponents";
import { I_CAMPAIGNS_LAUNCH } from "../../UserPermission/userPermissionList";

const awicPath = [
  {
    name: "iCampaigns",
    link: "/i-campaigns",
    icon: () => (
      <CustomeIconWrapper>
        <ICampaignsIcon />
      </CustomeIconWrapper>
    ),
    subMenu: [
      {
        subMenuName: "Launch",
        subMenuLink: "/launch",
        subMenuIcon: ApartmentOutlined,
        permission: I_CAMPAIGNS_LAUNCH,
      },
    ],
  },
];

export default awicPath;
