import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import {
  GQL_CMS_CREATE_BANNER,
  GQL_CMS_DELETE_BANNER,
  GQL_CMS_GET_BANNER,
  GQL_CMS_UPDATE_BANNER,
} from "shared/gql/gqlSchema/cmsGql";
import { openNotification } from "utils";

export const CMS_BANNER_TYPE_DOMAIN = "DOMAIN";
// PRODUCT is changed to small cases for banner only
export const CMS_BANNER_TYPE_PRODUCT = "Product";
export const CMS_BANNER_TYPE_STORE = "STORE";
export const CMS_BANNER_TYPE_LIST = "LIST";

export default function useCmsBannerCrudHook(props = {}) {
  const { type = "" } = props;
  const [
    call_fetchCmsBanner,
    { loading: loading_fetchCmsBanner, refetch: refetch_fetchCmsBanner },
  ] = useCustomLazyQuery(GQL_CMS_GET_BANNER, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [call_addCmsBanner, { loading: loading_addCmsBanner, refetch: refetch_addCmsBanner }] =
    useCustomMutation(GQL_CMS_CREATE_BANNER, {
      onCompleted: (e) => {
        const element = e?.CMS_Create_Banner;
        if (element) {
        }
      },
      onError(e) {
        openNotification({ type: "error", message: "Banners not added", description: e?.message });
      },
    });

  const [
    call_updateCmsBanner,
    { loading: loading_updateCmsBanner, refetch: refetch_updateCmsBanner },
  ] = useCustomMutation(GQL_CMS_UPDATE_BANNER, {
    onCompleted: (e) => {
      const element = e?.clAddGroups;
      if (element) {
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [call_deleteCmsBanner, { loading: loading_deleteCmsBanner }] = useCustomMutation(
    GQL_CMS_DELETE_BANNER,
    {
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const isDomain = type === CMS_BANNER_TYPE_DOMAIN;
  const isProduct = type === CMS_BANNER_TYPE_PRODUCT;
  const isStore = type === CMS_BANNER_TYPE_STORE;
  const isList = type === CMS_BANNER_TYPE_LIST;

  const parseId = (id) => {
    if (isDomain) {
      return { domain_id: id };
    } else if (isProduct) {
      return { product_id: id };
    } else if (isStore) {
      return { store_id: id };
    } else if (isList) {
      return { list_id: id };
    }
    return;
  };

  function fetchBanners(typeId, otherData = {}) {
    let payload = { ...parseId(typeId) };
    call_fetchCmsBanner({ variables: payload, ...otherData });
  }
  function addBanners(typeId, banner_image, otherData = {}) {
    let payload = { ...parseId(typeId), banner_image };
    call_addCmsBanner({ variables: payload, ...otherData });
  }
  function updateBanner(bannerId, typeId, banner_image, bannerLanderLink, otherData = {}) {
    let payload = {
      ...parseId(typeId),
      id: bannerId,
      image: banner_image,
      lander_link: bannerLanderLink,
    };
    call_updateCmsBanner({ variables: payload, ...otherData });
  }

  return {
    fetchBanners,
    call_fetchCmsBanner,
    loading_fetchCmsBanner,
    refetch_fetchCmsBanner,

    addBanners,
    call_addCmsBanner,
    loading_addCmsBanner,
    refetch_addCmsBanner,

    updateBanner,
    call_updateCmsBanner,
    loading_updateCmsBanner,
    refetch_updateCmsBanner,

    call_deleteCmsBanner,
    loading_deleteCmsBanner,
  };
}
