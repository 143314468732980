import React from "react";
import { Col, Row } from "antd";
import UserStats from "./UserStats";

function LaunchGraph(props) {
  return (
    <div>
      <h1>Graphs</h1>
      <Row gutter={24}>
        <Col span={24}>
          <UserStats {...props} />
        </Col>
      </Row>
    </div>
  );
}

export default LaunchGraph;
