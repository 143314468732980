import { gql } from "@apollo/client";
import { CL_VIDEO_RESPONSE } from "./mediaGql";

const CREATIVE_RESPONSE = gql`
  fragment Creative on Creative {
    adBody {
      id
      body
      complianceStatus
    }
    adTitle {
      id
      title
      complianceStatus
    }
    creativeImage {
      id
      complianceStatus
      imageHash
      imageUrl
      videoId
      s3Url
    }
  }
`;

const CREATIVE_REPORTING_REVENUE_RESPONSE = gql`
  fragment voluum_report on voluum_report {
    creative_group_id
    video_group_id
    image_group_id
    platform_id
    ad_id
    clicks
    category {
      id
      name
    }
    payout_amount
    ad_name
    revenue
    ad_account_id
    visits
    x_revenue
    conversions
  }
`;

const CREATIVE_ANALYSIS_RESPONSE = gql`
  fragment ctAnalysisObject on ctAnalysisObject {
    angleId {
      id
      name
      description
    }
    cpId {
      id
      name
    }
    extendedFields
    creativeType
    notes
    ctaId {
      id
      name
      description
    }
    hookId {
      id
      name
      description
    }
    imageId {
      id
      name
      r2Url
    }
    videoId {
      id
    }
    ppId {
      id
      name
      description
    }

    scriptId {
      id
      name
      angles {
        id
        name
        description
      }
      copyPoints {
        id
        name
      }
      ctas {
        id
        name
        description
      }
      painPoints {
        id
        name
        description
      }
      language {
        id
        name
        code
      }
      hooks {
        id
        name
        description
      }
      description
    }
  }
`;
const MOODBOARD_RESPONSE = gql`
  fragment ctGetMoodboard on ctGetMoodboard {
    angle {
      id
      name
      description
    }
    copyPoint {
      id
      name
    }
    cta {
      id
      name
      description
    }
    hook {
      id
      name
      description
    }
    painPoint {
      id
      name
      description
    }
    script {
      angles {
        id
        name
        description
      }
      copyPoints {
        id
        name
      }
      ctas {
        id
        name
        description
      }
      painPoints {
        id
        name
        description
      }
      language {
        id
        name
        code
      }
      hooks {
        id
        name
        description
      }
      id
      name
      description
    }
  }
`;
const CREATIVE_DETAILED_REPORTING_SPEND_RESPONSE = gql`
  fragment FB360_Reporting on FB360_Reporting {
    creative_group_id
    video_group_id
    image_group_id
    ad_name
    ad_id
    category_id
    category_name
    spend
    page_id
    platform_id
    platform_name
    clicks
    impressions
    result
    landing_page
    inline_link_clicks
    creative_url_object {
      groups {
        images {
          urls {
            id
            r2Url
          }
        }
        groupType
        videos {
          urls {
            id
            r2Url
            thumbUrl
          }
        }
        bodies {
          id
          text
        }
        titles {
          id
          text
        }
        descriptions {
          id
          text
        }
      }
      images {
        urls {
          id
          r2Url
        }
      }
      videos {
        urls {
          id
          r2Url
          thumbUrl
        }
        videoType
      }
    }
  }
`;
const CREATIVE_REPORTING_SPEND_RESPONSE = gql`
  fragment FB360_Reporting on FB360_Reporting {
    creative_group_id
    video_group_id
    image_group_id
    ad_name
    ad_id
    category_id
    category_name
    spend
    platform_id
    platform_name
    clicks
    impressions
    result
    inline_link_clicks
    creative_url_object {
      groups {
        images {
          urls {
            id
            r2Url
          }
        }
        groupType
        videos {
          urls {
            id
            r2Url
            thumbUrl
          }
        }
        bodies {
          id
          text
        }
        titles {
          id
          text
        }
        descriptions {
          id
          text
        }
      }
      images {
        urls {
          id
          r2Url
        }
      }
      videos {
        urls {
          id
          r2Url
          thumbUrl
        }
        videoType
      }
    }
  }
`;
// const CREATIVE_DETAILED_REPORTING_SPEND_RESPONSE = gql`
//   fragment FB360_Reporting on FB360_Reporting {
//     creative_group_id
//     video_group_id
//     image_group_id
//     category {
//       id
//       name
//     }
//     spend
//   }
// `;

export const GET_REPORTS_CATEGORY_BY_SUBIDS = gql`
  query creativeBySubId($page: Int!, $size: Int!, $subId: ID!) {
    creativeBySubId(page: $page, subId: $subId, size: $size) {
      data {
        adBody {
          body
          complianceStatus
          id
        }
        adTitle {
          title
          complianceStatus
          id
        }
        creativeImage {
          complianceStatus
          id
          imageHash
          imageUrl
          s3Url
          videoId
          thumbnailUrl
        }
      }
    }
  }
`;

export const GET_REPORTS_CATEGORY_BY_CREATIVE = gql`
  query reportCategoryByCreative(
    $categoryId: ID!
    $from: String!
    $page: Int!
    $productId: ID!
    $size: Int!
    $to: String!
  ) {
    reportCategoryByCreative(
      categoryId: $categoryId
      from: $from
      page: $page
      productId: $productId
      size: $size
      to: $to
    ) {
      data {
        spend
        pageId
        adBody {
          body
          complianceStatus
          id
        }
        adTitle {
          title
          complianceStatus
          id
        }
        creativeImage {
          complianceStatus
          id
          imageHash
          imageUrl
          s3Url
          videoId
          thumbnailUrl
        }
        id
      }
      hasNext
    }
  }
`;

export const GET_TIKTOK_REPORTS_CATEGORY_BY_CREATIVE = gql`
  query tiktok_getCategoryByCreativeReport(
    $categoryId: ID!
    $end: Date
    $page: Int
    $productId: ID!
    $size: Int
    $start: Date!
    $timezone: String
  ) {
    tiktok_getCategoryByCreativeReport(
      categoryId: $categoryId
      end: $end
      page: $page
      productId: $productId
      start: $start
      size: $size
      timezone: $timezone
    ) {
      data {
        category_id
        spend
        video_thumb_url
        video_url
        ad_text
        ad_id
      }
      message
    }
  }
`;
export const GET_TIKTOK_ADACCOUNT_REPORTS = gql`
  query tiktok_getCreativeAdaccountReport(
    $category_id: [ID!]
    $creator_id: [ID!]
    $end: Date
    $group_by: creative_fetch_type
    $group_id: String
    $product_id: ID!
    $start: Date!
    $timezone: String
    $user_id: [ID!]
  ) {
    tiktok_getCreativeAdaccountReport(
      category_id: $category_id
      creator_id: $creator_id
      end: $end
      group_by: $group_by
      group_id: $group_id
      product_id: $product_id
      start: $start
      timezone: $timezone
      user_id: $user_id
    ) {
      adaccount_id
      adaccount_name
      ad_id
      spend
    }
  }
`;
export const GET_SAS_ADACCOUNT_REPORTS = gql`
  query sasCreativeAdaccountReport(
    $categoryId: [ID]
    $creatorId: [ID]
    $from: Date!
    $groupBy: sasCreativeType
    $groupId: String
    $platformId: ID!
    $productId: ID!
    $timezone: String!
    $to: Date!
    $userId: [ID]
  ) {
    sasCreativeAdaccountReport(
      categoryId: $categoryId
      creatorId: $creatorId
      from: $from
      groupBy: $groupBy
      groupId: $groupId
      productId: $productId
      to: $to
      timezone: $timezone
      userId: $userId
      platformId: $platformId
    ) {
      adaccount_id
      adId
      estimate_revenue
      sessions
      searches
    }
  }
`;

export const GET_TIKTOK_CREATIVES_BY_CAMPAIGN = gql`
  query tiktok_getCreativesByCampaignId(
    $campaignId: String!
    $end: Date
    $start: Date!
    $timezone: String
  ) {
    tiktok_getCreativesByCampaignId(
      campaignId: $campaignId
      end: $end
      start: $start
      timezone: $timezone
    ) {
      data {
        category_id
        spend
        video_thumb_url
        video_url
        ad_text
        ad_id
      }
      message
    }
  }
`;

export const GET_CREATIVE_BY_CAMPAIGNS = gql`
  ${CREATIVE_RESPONSE}
  query creativeByCampaigns($campaignIds: [String]!) {
    creativeByCampaigns(campaignIds: $campaignIds) {
      ...Creative
    }
  }
`;

export const GET_CREATIVE_BY_ADSETS = gql`
  ${CREATIVE_RESPONSE}
  query creativeByAdSets($adSetIds: [String]!) {
    creativeByAdSets(adSetIds: $adSetIds) {
      ...Creative
    }
  }
`;

export const GET_AI_CREATIVES = gql`
  query AI_adgroup_data($param: String!) {
    AI_adgroup_data(param: $param) {
      description
      headline
      primary_text
    }
  }
`;

const CREATIVE_IMAGE_RESPONSE = gql`
  fragment clCreativeImage on clCreativeImage {
    id
    imageHash
    s3Url
    pHash
  }
`;

const CREATIVE_IMAGE_DATA_RESPONSE = gql`
  ${CREATIVE_IMAGE_RESPONSE}
  fragment clImageData on clImageData {
    creative {
      ...clCreativeImage
    }
    tags {
      id
      tag
    }
  }
`;
const IMAGE_DATA_RESPONSE = gql`
  fragment ctImageComplianceObject on ctImageComplianceObject {
    compliance {
      complianceStatus
      notes
    }
    image {
      id
      r2Url
      name
      tags {
        id
        name
      }
    }
  }
`;
const VIDEO_DATA_RESPONSE = gql`
  fragment ctVideoComplianceObject on ctVideoComplianceObject {
    compliance {
      complianceStatus
      notes
    }
    video {
      id
      r2Url
      name
      currentThumb {
        id
        r2Url
      }
      tags {
        id
        name
      }
    }
    thumbCompliance {
      complianceStatus
    }
  }
`;

const CREATIVE_VIDEO_DATA_RESPONSE = gql`
  ${CL_VIDEO_RESPONSE}
  fragment clVideoData on clVideoData {
    creative {
      ...clCreativeVideo
    }
    tags {
      id
      tag
    }
  }
`;

export const GQL_CT_DELETE_IMAGES = gql`
  mutation ctDeleteCreativeImages(
    $categoryId: ID!
    $productId: ID!
    $imageIds: [String]!
    $forceDelete: Boolean!
  ) {
    ctDeleteCreativeImages(
      categoryId: $categoryId
      productId: $productId
      imageIds: $imageIds
      forceDelete: $forceDelete
    ) {
      message
      status
    }
  }
`;

export const CT_IMAGES_BY_CATEGORY = gql`
  ${IMAGE_DATA_RESPONSE}
  query ctGetCreativeImages(
    $categoryId: ID!
    $column: String
    $createdByMe: Boolean
    $featuredImages: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $productId: ID!
    $search: String
    $size: Int
    $toDate: String
    $tags: [String]
  ) {
    ctGetCreativeImages(
      categoryId: $categoryId
      column: $column
      createdByMe: $createdByMe
      featuredImages: $featuredImages
      fromDate: $fromDate
      order: $order
      page: $page
      productId: $productId
      search: $search
      size: $size
      toDate: $toDate
      tags: $tags
    ) {
      data {
        ...ctImageComplianceObject
      }
      pageInfo {
        totalPages
        totalRecords
      }
    }
  }
`;
export const CT_VIDEOS_BY_CATEGORY = gql`
  ${VIDEO_DATA_RESPONSE}
  query ctGetCreativeVideos(
    $categoryId: ID!
    $column: String
    $createdByMe: Boolean
    $featuredVideos: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $productId: ID!
    $search: String
    $size: Int
    $toDate: String
    $tags: [String]
  ) {
    ctGetCreativeVideos(
      categoryId: $categoryId
      column: $column
      createdByMe: $createdByMe
      featuredVideos: $featuredVideos
      fromDate: $fromDate
      order: $order
      page: $page
      productId: $productId
      search: $search
      size: $size
      toDate: $toDate
      tags: $tags
    ) {
      data {
        ...ctVideoComplianceObject
      }
      pageInfo {
        totalPages
        totalRecords
      }
    }
  }
`;
export const CL_IMAGES_BY_CATEGORY = gql`
  ${CREATIVE_IMAGE_DATA_RESPONSE}
  query clPagedImages(
    $categoryId: ID!
    $productId: ID!
    $inputs: PaginationInput!
    $subCategoryId: ID
    $createdByMe: Boolean
  ) {
    clPagedImages(
      categoryId: $categoryId
      productId: $productId
      inputs: $inputs
      subCategoryId: $subCategoryId
      createdByMe: $createdByMe
    ) {
      data {
        ...clImageData
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CL_ADD_IMAGES = gql`
  ${CREATIVE_IMAGE_DATA_RESPONSE}
  mutation clAddCreativeImages(
    $categoryId: ID!
    $categoryName: String!
    $commonTags: [String]
    $imageType: clImageType
    $images: [imageObject]!
    $productId: ID!
    $subCategory: subcategoryInput
    $verticalId: ID
  ) {
    clAddCreativeImages(
      categoryId: $categoryId
      categoryName: $categoryName
      commonTags: $commonTags
      imageType: $imageType
      images: $images
      productId: $productId
      subCategory: $subCategory
      verticalId: $verticalId
    ) {
      message
      status
      data {
        ...clImageData
      }
    }
  }
`;

const CREATIVE_VIDEO_RESPONSE = gql`
  fragment clCreativeVideo on clCreativeVideo {
    id
    fbVideoId
    s3Url
    thumbUrl
  }
`;

export const CL_VIDEOS_BY_CATEGORY = gql`
  ${CREATIVE_VIDEO_DATA_RESPONSE}
  query clPagedVideos(
    $categoryId: ID!
    $productId: ID!
    $inputs: PaginationInput!
    $subCategoryId: ID
    $createdByMe: Boolean
  ) {
    clPagedVideos(
      categoryId: $categoryId
      productId: $productId
      inputs: $inputs
      subCategoryId: $subCategoryId
      createdByMe: $createdByMe
    ) {
      data {
        ...clVideoData
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CL_ADD_VIDEOS = gql`
  ${CREATIVE_VIDEO_DATA_RESPONSE}
  mutation clAddCreativeVideos(
    $categoryId: ID!
    $categoryName: String!
    $commonTags: [String]
    $productId: ID!
    $subCategory: subcategoryInput
    $verticalId: ID
    $videos: [videoObject]!
  ) {
    clAddCreativeVideos(
      categoryId: $categoryId
      categoryName: $categoryName
      commonTags: $commonTags
      productId: $productId
      subCategory: $subCategory
      verticalId: $verticalId
      videos: $videos
    ) {
      data {
        ...clVideoData
      }
      status
      message
    }
  }
`;

const CREATIVE_TITLE_RESPONSE = gql`
  fragment clCreativeTitle on clCreativeTitle {
    id
    title
  }
`;
const CREATIVE_ADD_TITLE_RESPONSE = gql`
  fragment ctTitleObject on ctTitleObject {
    addedCreatives {
      id
      title
    }
    alreadyPresent {
      id
      title
    }
  }
`;
const CT_TITLE_RESPONSE = gql`
  fragment ctTitleComplianceObject on ctTitleComplianceObject {
    compliance {
      complianceStatus
      notes
    }

    title {
      id
      tags {
        id
        name
      }
      title
    }
  }
`;

export const CL_TITLES_BY_CATEGORY = gql`
  ${CREATIVE_TITLE_RESPONSE}
  query clPagedTitle(
    $categoryId: ID!
    $productId: ID!
    $inputs: PaginationInput!
    $subCategoryId: ID
    $createdByMe: Boolean
  ) {
    clPagedTitle(
      categoryId: $categoryId
      productId: $productId
      inputs: $inputs
      subCategoryId: $subCategoryId
      createdByMe: $createdByMe
    ) {
      data {
        ...clCreativeTitle
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CL_ADD_TITLES = gql`
  ${CREATIVE_TITLE_RESPONSE}
  mutation clAddTitle(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $titles: [String]!
    $verticalId: ID
    $subCategory: subcategoryInput
  ) {
    clAddTitle(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      titles: $titles
      verticalId: $verticalId
      subCategory: $subCategory
    ) {
      data {
        ...clCreativeTitle
      }
    }
  }
`;

export const CT_ADD_TITLES = gql`
  ${CREATIVE_ADD_TITLE_RESPONSE}
  mutation ctAddCreativeTitles(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $titles: [String]!
  ) {
    ctAddCreativeTitles(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      titles: $titles
    ) {
      data {
        ...ctTitleObject
      }
      message
      status
    }
  }
`;
export const CT_GET_TITLES = gql`
  ${CT_TITLE_RESPONSE}
  query ctGetCreativeTitles(
    $categoryId: ID!
    $column: String
    $createdByMe: Boolean
    $featuredTitles: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $productId: ID!
    $search: String
    $tags: [String]
    $toDate: String
  ) {
    ctGetCreativeTitles(
      categoryId: $categoryId
      productId: $productId
      featuredTitles: $featuredTitles
      column: $column
      createdByMe: $createdByMe
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      tags: $tags
      toDate: $toDate
    ) {
      data {
        ...ctTitleComplianceObject
      }
      message
      status
      pageInfo {
        totalPages
        totalRecords
      }
    }
  }
`;

export const GQL_CT_DELETE_TITLES = gql`
  mutation ctDeleteCreativeTitles(
    $categoryId: ID!
    $productId: ID!
    $titleIds: [String]!
    $forceDelete: Boolean!
  ) {
    ctDeleteCreativeTitles(
      categoryId: $categoryId
      productId: $productId
      titleIds: $titleIds
      forceDelete: $forceDelete
    ) {
      message
      status
    }
  }
`;

export const CL_TITLES_TEMPLATES = gql`
  ${CREATIVE_TITLE_RESPONSE}
  query clTitleTemplates($productId: ID!) {
    clTitleTemplates(productId: $productId) {
      data {
        ...clCreativeTitle
      }
      message
      status
    }
  }
`;

export const CL_ADD_TITLE_TEMPLATES = gql`
  ${CREATIVE_TITLE_RESPONSE}
  mutation clAddTitleTemplate($productId: ID!, $title: String!) {
    clAddTitleTemplate(productId: $productId, title: $title) {
      data {
        ...clCreativeTitle
      }
      message
      status
    }
  }
`;

const CREATIVE_BODY_RESPONSE = gql`
  fragment clCreativeBody on clCreativeBody {
    id
    body
  }
`;
const CREATIVE_CT_BODIES_RESPONSE = gql`
  fragment ctBodyComplianceObject on ctBodyComplianceObject {
    body {
      body
      id
      tags {
        id
        name
      }
    }
    compliance {
      complianceStatus
      notes
    }
  }
`;
const CREATIVE_CT_DESC_RESPONSE = gql`
  fragment ctDescComplianceObject on ctDescComplianceObject {
    description {
      description
      id
      tags {
        id
        name
      }
    }
    compliance {
      complianceStatus
      notes
    }
  }
`;
export const CL_ADD_BODIES_TEMPLATES = gql`
  ${CREATIVE_BODY_RESPONSE}
  mutation clAddBodyTemplate($productId: ID!, $body: String!) {
    clAddBodyTemplate(productId: $productId, body: $body) {
      data {
        ...clCreativeBody
      }
      message
      status
    }
  }
`;

export const CL_BODIES_BY_CATEGORY = gql`
  ${CREATIVE_BODY_RESPONSE}
  query clPagedBody(
    $categoryId: ID!
    $productId: ID!
    $inputs: PaginationInput!
    $subCategoryId: ID
    $createdByMe: Boolean
  ) {
    clPagedBody(
      categoryId: $categoryId
      productId: $productId
      inputs: $inputs
      subCategoryId: $subCategoryId
      createdByMe: $createdByMe
    ) {
      data {
        ...clCreativeBody
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;
export const CT_BODIES_BY_CATEGORY = gql`
  ${CREATIVE_CT_BODIES_RESPONSE}
  query ctGetCreativeBodies(
    $categoryId: ID!
    $createdByMe: Boolean
    $featuredBodies: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $productId: ID!
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetCreativeBodies(
      categoryId: $categoryId
      createdByMe: $createdByMe
      featuredBodies: $featuredBodies
      fromDate: $fromDate
      order: $order
      page: $page
      productId: $productId
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      data {
        ...ctBodyComplianceObject
      }
      message
      status
      pageInfo {
        totalPages
        totalRecords
      }
    }
  }
`;
export const CT_DESC_BY_CATEGORY = gql`
  ${CREATIVE_CT_DESC_RESPONSE}
  query ctGetCreativeDescs(
    $categoryId: ID!
    $createdByMe: Boolean
    $featuredDescs: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $productId: ID!
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetCreativeDescs(
      categoryId: $categoryId
      createdByMe: $createdByMe
      featuredDescs: $featuredDescs
      fromDate: $fromDate
      order: $order
      page: $page
      productId: $productId
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      data {
        ...ctDescComplianceObject
      }
      message
      status
      pageInfo {
        totalPages
        totalRecords
      }
    }
  }
`;
export const CL_ADD_BODY = gql`
  ${CREATIVE_BODY_RESPONSE}
  mutation clAddBody(
    $bodies: [String]!
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $verticalId: ID
    $subCategory: subcategoryInput
  ) {
    clAddBody(
      bodies: $bodies
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      verticalId: $verticalId
      subCategory: $subCategory
    ) {
      data {
        ...clCreativeBody
      }
    }
  }
`;

export const CT_ADD_BODY = gql`
  mutation ctAddCreativeBodies(
    $bodies: [String]!
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddCreativeBodies(
      bodies: $bodies
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
    ) {
      message
      status
      data {
        addedCreatives {
          body
          id
          tags {
            id
            name
          }
        }
        alreadyPresent {
          body
          id
          tags {
            id
            name
          }
        }
      }
    }
  }
`;
export const CT_ADD_DESC = gql`
  mutation ctAddCreativeDescs(
    $descs: [String]!
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddCreativeDescs(
      descs: $descs
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
    ) {
      message
      status
      data {
        addedCreatives {
          description
          id
          tags {
            id
            name
          }
        }
        alreadyPresent {
          description
          id
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export const CL_BODIES_TEMPLATES = gql`
  ${CREATIVE_BODY_RESPONSE}
  query clBodyTemplates($productId: ID!) {
    clBodyTemplates(productId: $productId) {
      data {
        ...clCreativeBody
      }
      message
      status
    }
  }
`;
const CREATIVE_DESCRIPTION_RESPONSE = gql`
  fragment clCreativeDescription on clCreativeDescription {
    id
    description
  }
`;

export const CL_GET_DESCRIPTION = gql`
  ${CREATIVE_DESCRIPTION_RESPONSE}
  query clPagedDescription(
    $categoryId: ID!
    $productId: ID!
    $inputs: PaginationInput!
    $subCategoryId: ID
    $createdByMe: Boolean
  ) {
    clPagedDescription(
      categoryId: $categoryId
      productId: $productId
      inputs: $inputs
      subCategoryId: $subCategoryId
      createdByMe: $createdByMe
    ) {
      data {
        ...clCreativeDescription
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CL_ADD_DESCRIPTION = gql`
  ${CREATIVE_DESCRIPTION_RESPONSE}
  mutation clAddDescription(
    $categoryId: ID!
    $categoryName: String!
    $descriptions: [String]!
    $productId: ID!
    $verticalId: ID
    $subCategory: subcategoryInput
  ) {
    clAddDescription(
      categoryId: $categoryId
      categoryName: $categoryName
      descriptions: $descriptions
      productId: $productId
      verticalId: $verticalId
      subCategory: $subCategory
    ) {
      data {
        ...clCreativeDescription
      }
    }
  }
`;

export const CL_GET_DESCRIPTIONTEMPLATES = gql`
  ${CREATIVE_DESCRIPTION_RESPONSE}
  query clDescriptionTemplates($productId: ID!) {
    clDescriptionTemplates(productId: $productId) {
      data {
        ...clCreativeDescription
      }
      message
      status
    }
  }
`;
export const CT_GET_TEMPLATES = gql`
  query ct_getCommonTemplate($productId: ID!, $creativeType: [CREATIVE_TYPE]!) {
    ct_getCommonTemplate(productId: $productId, creativeType: $creativeType) {
      data {
        creative
        creativeType
        id
      }
      message
      status
    }
  }
`;
export const CT_ADD_TEMPLATES = gql`
  mutation ct_addCommonTemplate(
    $productId: ID!
    $creativeType: CREATIVE_TYPE!
    $creative: String!
  ) {
    ct_addCommonTemplate(productId: $productId, creativeType: $creativeType, creative: $creative) {
      data {
        creative
        id
      }
      message
      status
    }
  }
`;
export const CL_ADD_DESCRIPTION_TEMPLATES = gql`
  ${CREATIVE_DESCRIPTION_RESPONSE}
  mutation clAddDescTemplate($productId: ID!, $description: String!) {
    clAddDescTemplate(productId: $productId, description: $description) {
      data {
        ...clCreativeDescription
      }
      message
      status
    }
  }
`;

export const GQL_CT_DELETE_DESCRIPTIONS = gql`
  mutation ctDeleteCreativeDesc(
    $categoryId: ID!
    $productId: ID!
    $descIds: [String]!
    $forceDelete: Boolean!
  ) {
    ctDeleteCreativeDesc(
      categoryId: $categoryId
      productId: $productId
      descIds: $descIds
      forceDelete: $forceDelete
    ) {
      message
      status
    }
  }
`;

export const GQL_CT_DELETE_BODY = gql`
  mutation ctDeleteCreativeBodies(
    $categoryId: ID!
    $productId: ID!
    $bodyIds: [String]!
    $forceDelete: Boolean!
  ) {
    ctDeleteCreativeBodies(
      categoryId: $categoryId
      productId: $productId
      bodyIds: $bodyIds
      forceDelete: $forceDelete
    ) {
      message
      status
    }
  }
`;

// export const CLIMAHE_TITLE_BODY_DESCRIPTION_COMBINE = gql`
//   ${CREATIVE_IMAGE_RESPONSE}
//   query combineCreative($categoryId: ID!) {
//     clImages(categoryId: $categoryId) {
//       ...clCreativeImage
//     }
//     clTitles(categoryId: $categoryId) {
//       title
//     }
//     clBodies(categoryId: $categoryId) {
//       body
//     }
//   }
// `;

export const GQL_IMG_SCRAPER_GET_CREATIVES = gql`
  query imgScraper_getCreatives(
    $country_code: String
    $keyword: String!
    $language_code: String
    $offset: Int
    $source: String
  ) {
    imgScraper_getCreatives(
      country_code: $country_code
      keyword: $keyword
      language_code: $language_code
      offset: $offset
      source: $source
    ) {
      status
      data
    }
  }
`;

export const GQL_CL_SEARCH_PINTEREST_IMAGES = gql`
  query clSearchPinterestImages($key: String!, $scrollNum: Int!) {
    clSearchPinterestImages(key: $key, scrollNum: $scrollNum) {
      status
      data
      message
      length
    }
  }
`;

export const GQL_CL_DELETE_IMAGE_TAG = gql`
  mutation ClUpdateImageTags($creativeTags: [inputTags]!, $process: PROCESS) {
    clUpdateImageTags(creativeTags: $creativeTags, process: $process) {
      message
      status
    }
  }
`;

export const GQL_CL_DELETE_VIDEO_TAG = gql`
  mutation ClUpdateVideoTags($creativeTags: [inputTags]!, $process: PROCESS) {
    clUpdateVideoTags(creativeTags: $creativeTags, process: $process) {
      message
      status
    }
  }
`;

export const GQL_VOLUUM_CREATIVE_REVENUE_REPORTING = gql`
  ${CREATIVE_REPORTING_REVENUE_RESPONSE}
  query voluum_creativeReporting(
    $end: Date
    $group_by: creative_fetch_type
    $start: Date
    $timezone: String
    $platform_id: ID
    $category_id: [ID]
    $creator_id: [ID]
    $user_id: [ID]
    $sub_id: [ID]
  ) {
    voluum_creativeReporting(
      end: $end
      start: $start
      group_by: $group_by
      timezone: $timezone
      platform_id: $platform_id
      creator_id: $creator_id
      category_id: $category_id
      user_id: $user_id
      sub_id: $sub_id
    ) {
      ...voluum_report
    }
  }
`;

export const GQL_VS_CREATIVE_REVENUE_REPORTING = gql`
  query vs_creativeLiveReportsFromTo(
    $from: Date
    $to: Date
    $timezone: String
    $platformId: ID
    $advertiserId: ID
    $isForecast: Boolean
    $groupBy: CREATIVEGROUP!
  ) {
    vs_creativeLiveReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platformId
      advertiser_id: $advertiserId
      is_forecast: $isForecast
      group_by: $groupBy
    ) {
      impressions
      pageviews
      sessions
      user_revenue
      creative_group_id
      image_group_id
      video_group_id
    }
  }
`;

export const GQL_FB360_SPEND_REPORTING = gql`
  ${CREATIVE_REPORTING_SPEND_RESPONSE}
  query fb360_creativeReporting(
    $end: Date
    $user_id: [ID!]
    $group_by: creative_fetch_type
    $start: Date
    $timezone: String
    $product_id: ID
    $category_id: [ID!]
    $creator_id: [ID!]
    $sub_id: [String]
    $order: FB360_ORDER
  ) {
    fb360_creativeReporting(
      end: $end
      start: $start
      group_by: $group_by
      timezone: $timezone
      product_id: $product_id
      category_id: $category_id
      creator_id: $creator_id
      user_id: $user_id
      sub_id: $sub_id
      order: $order
    ) {
      ...FB360_Reporting
    }
  }
`;

export const GQL_FB360_DETAILED_SPEND_REPORTING = gql`
  ${CREATIVE_DETAILED_REPORTING_SPEND_RESPONSE}
  query fb360_creativeDetailedReporting(
    $asset_type: FB360_ASSET
    $category_id: [ID]
    $creator_id: [ID]
    $end: Date
    $group_by: creative_fetch_type
    $group_id: ID
    $product_id: ID
    $start: Date
    $timezone: String
    $user_id: [ID]
  ) {
    fb360_creativeDetailedReporting(
      asset_type: $asset_type
      category_id: $category_id
      creator_id: $creator_id
      end: $end
      group_by: $group_by
      group_id: $group_id
      product_id: $product_id
      start: $start
      timezone: $timezone
      user_id: $user_id
    ) {
      ...FB360_Reporting
    }
  }
`;
export const GQL_FB360_DETAILED_CATEGORY_SPEND_REPORTING = gql`
  ${CREATIVE_REPORTING_SPEND_RESPONSE}
  query fb360_creativeCategoryReporting(
    $category_id: [ID]
    $creator_id: [ID]
    $end: Date
    $group_by: creative_fetch_type
    $group_id: ID
    $product_id: ID
    $start: Date
    $timezone: String
    $user_id: [ID]
  ) {
    fb360_creativeCategoryReporting(
      category_id: $category_id
      creator_id: $creator_id
      end: $end
      group_by: $group_by
      group_id: $group_id
      product_id: $product_id
      start: $start
      timezone: $timezone
      user_id: $user_id
    ) {
      ...FB360_Reporting
    }
  }
`;
export const GQL_FB360_DETAILED_PLATFORM_SPEND_REPORTING = gql`
  ${CREATIVE_REPORTING_SPEND_RESPONSE}
  query fb360_creativePlatformReporting(
    $category_id: [ID]
    $creator_id: [ID]
    $end: Date
    $group_by: creative_fetch_type
    $group_id: ID
    $product_id: ID
    $start: Date
    $timezone: String
    $user_id: [ID]
  ) {
    fb360_creativePlatformReporting(
      category_id: $category_id
      creator_id: $creator_id
      end: $end
      group_by: $group_by
      group_id: $group_id
      product_id: $product_id
      start: $start
      timezone: $timezone
      user_id: $user_id
    ) {
      ...FB360_Reporting
    }
  }
`;

export const GQL_VOLUUM_DETAILED_REVENUE_REPORTING = gql`
  ${CREATIVE_REPORTING_REVENUE_RESPONSE}
  query voluum_creativeDetailedReporting(
    $end: Date
    $group_by: creative_fetch_type
    $start: Date
    $timezone: String
    $asset_type: voluum_asset
    $group_id: ID
    $platform_id: ID
    $category_id: [ID]
    $creator_id: [ID]
    $user_id: [ID]
  ) {
    voluum_creativeDetailedReporting(
      end: $end
      start: $start
      group_by: $group_by
      group_id: $group_id
      asset_type: $asset_type
      timezone: $timezone
      platform_id: $platform_id
      category_id: $category_id
      creator_id: $creator_id
      user_id: $user_id
    ) {
      ...voluum_report
    }
  }
`;

export const GQL_TIKTOK_COMPLIANCE_UPDATE = gql`
  mutation tiktok_updateComplianceStatus(
    $id: String!
    $status: tiktok_complianceStatus!
    $type: tiktok_complianceType!
    $description: String
    $product_id: ID
  ) {
    tiktok_updateComplianceStatus(
      id: $id
      status: $status
      type: $type
      description: $description
      product_id: $product_id
    ) {
      message
      status
      data
    }
  }
`;

export const GQL_VOLUUM_PLATFORM_REVENUE_REPORTING = gql`
  ${CREATIVE_REPORTING_REVENUE_RESPONSE}
  query voluum_creativePlatformReporting(
    $category_id: [ID]
    $creator_id: [ID]
    $from: Date
    $group_by: creative_fetch_type
    $group_id: ID
    $platform_id: [ID]
    $sub_id: [ID]
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    voluum_creativePlatformReporting(
      category_id: $category_id
      creator_id: $creator_id
      from: $from
      group_by: $group_by
      group_id: $group_id
      platform_id: $platform_id
      sub_id: $sub_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...voluum_report
    }
  }
`;
export const GQL_VOLUUM_CATEGORY_REVENUE_REPORTING = gql`
  ${CREATIVE_REPORTING_REVENUE_RESPONSE}
  query voluum_creativeCategoryReporting(
    $asset_type: voluum_asset
    $category_id: [ID]
    $creator_id: [ID]
    $from: Date
    $group_by: creative_fetch_type
    $group_id: ID
    $platform_id: ID
    $sub_id: [ID]
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    voluum_creativeCategoryReporting(
      asset_type: $asset_type
      category_id: $category_id
      creator_id: $creator_id
      from: $from
      group_by: $group_by
      group_id: $group_id
      platform_id: $platform_id
      sub_id: $sub_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...voluum_report
    }
  }
`;
export const GQL_GET_CREATIVE_ANALYSIS = gql`
  ${CREATIVE_ANALYSIS_RESPONSE}
  query ctGetCreativeAnalysis($creativeIds: [ID]!, $creativeType: MEDIA_TYPE!) {
    ctGetCreativeAnalysis(creativeIds: $creativeIds, creativeType: $creativeType) {
      data {
        ...ctAnalysisObject
      }
      message
      status
    }
  }
`;

export const GQL_ADD_CREATIVE_ANALYSIS = gql`
  ${CREATIVE_ANALYSIS_RESPONSE}
  mutation ctAddCreativeAnalysis(
    $creativeId: String!
    $creativeType: MEDIA_TYPE!
    $requestInput: CreativeRequestInput!
  ) {
    ctAddCreativeAnalysis(
      creativeId: $creativeId
      creativeType: $creativeType
      requestInput: $requestInput
    ) {
      data {
        ...ctAnalysisObject
      }
      message
      status
    }
  }
`;
export const GQL_GET_MOODBOARD_BY_CATEGORY = gql`
  ${MOODBOARD_RESPONSE}
  query ctGetMoodboardByCategory($categoryIds: [ID]!, $productId: ID!) {
    ctGetMoodboardByCategory(categoryIds: $categoryIds, productId: $productId) {
      data {
        ...ctGetMoodboard
      }
      message
      status
    }
  }
`;
export const GQL_PRODUCT_WISE_USER = gql`
  query product($id: ID!) {
    product(id: $id) {
      users {
        id
        name
      }
    }
  }
`;

export const CT_ADD_AVATAR = gql`
  mutation ctAddCreativeAvatars(
    $avatars: [ctAvatarInputObject]!
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddCreativeAvatars(
      avatars: $avatars
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
    ) {
      message
      status
    }
  }
`;

export const CT_GET_AVATAR = gql`
  query CtGetCreativeAvatars(
    $categoryId: ID!
    $productId: ID!
    $column: String
    $createdByMe: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetCreativeAvatars(
      categoryId: $categoryId
      productId: $productId
      column: $column
      createdByMe: $createdByMe
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
      data {
        id
        name
        r2Url
        tags {
          id
          name
        }
      }
    }
  }
`;

export const GQL_FB360_CREATIVE_ADACCOUNT_REPORTING = gql`
  query fb360_creativeAdaccountReporting(
    $category_id: [ID]
    $creator_id: [ID]
    $end: Date
    $group_by: creative_fetch_type
    $group_id: ID
    $product_id: ID
    $start: Date
    $sub_id: [String]
    $timezone: String
    $user_id: [ID]
  ) {
    fb360_creativeAdaccountReporting(
      category_id: $category_id
      creator_id: $creator_id
      end: $end
      group_by: $group_by
      group_id: $group_id
      product_id: $product_id
      start: $start
      sub_id: $sub_id
      timezone: $timezone
      user_id: $user_id
    ) {
      adaccount_id
      adaccount_name
      users {
        name
        id
      }
      spend
      clicks
      impressions
      result
    }
  }
`;

export const GET_COMMON_COMPLIANCE = gql`
  query ct_getCommonCompliance(
    $categoryId: [ID]!
    $creativeIds: [creativeIdInput]!
    $creativeType: CREATIVE_TYPE!
    $productId: ID!
  ) {
    ct_getCommonCompliance(
      categoryId: $categoryId
      productId: $productId
      creativeType: $creativeType
      creativeIds: $creativeIds
    ) {
      data {
        category {
          name
          productCategoryId
        }
        imageCompliance {
          complianceStatus
          notes
          image {
            id
            name
            r2Url
          }
        }
        videoCompliance {
          complianceStatus
          notes
          video {
            id
            name
            r2Url
          }
        }
        groupCompliance {
          static {
            bodyId {
              id
              bodyCompliance {
                complianceStatus
                body {
                  id
                  body
                }
              }
            }
            titleId {
              id
              titleCompliance {
                complianceStatus
                title {
                  id
                  title
                }
              }
            }
            videoId {
              id
              videoCompliance {
                complianceStatus
                video {
                  id
                  name
                }
              }
            }
            id
            imageId {
              id
              imageCompliance {
                complianceStatus
                image {
                  r2Url
                  name
                  categoryId {
                    name
                    productCategoryId
                  }
                }
              }
            }
            descId {
              id
              descCompliance {
                complianceStatus
                description {
                  description
                  id
                  categoryId {
                    name
                    productCategoryId
                  }
                }
              }
            }
          }
          dynamic {
            bodies {
              bodies {
                bodyCompliance {
                  complianceStatus
                  body {
                    id
                    body
                  }
                }
              }
            }

            descriptions {
              descriptions {
                descCompliance {
                  complianceStatus
                  description {
                    id
                    description
                  }
                }
              }
            }

            images {
              images {
                id
                r2Url
                imageCompliance {
                  complianceStatus
                  image {
                    id
                    r2Url
                  }
                }
              }
            }
            titles {
              titles {
                id
                titleCompliance {
                  complianceStatus
                  title {
                    id
                    title
                  }
                }
              }
            }
            videos {
              videos {
                id
                r2Url
                videoCompliance {
                  complianceStatus
                  video {
                    id
                    name
                    r2Url
                  }
                }
              }
            }
          }

          carousel {
            groups {
              id
              titleId {
                titleCompliance {
                  complianceStatus
                  title {
                    id
                    title
                  }
                }
              }
              videoId {
                videoCompliance {
                  complianceStatus
                  video {
                    id
                    name
                  }
                }
              }
              imageId {
                imageCompliance {
                  complianceStatus
                  image {
                    r2Url
                    name
                    categoryId {
                      name
                      productCategoryId
                    }
                  }
                }
              }
              descId {
                descCompliance {
                  complianceStatus
                  description {
                    description
                    id
                    categoryId {
                      name
                      productCategoryId
                    }
                  }
                }
              }
              bodyId {
                bodyCompliance {
                  complianceStatus
                  body {
                    body
                    id
                  }
                  categoryId {
                    name
                    productCategoryId
                  }
                }
              }
            }
            categoryId {
              name
              productCategoryId
            }
          }
        }
      }
    }
  }
`;

export const GQL_ADD_COMMON_COMPLIANCE = gql`
  mutation ctAddCreativeCompliance(
    $categoryIds: [ID]!
    $complainceObject: ctComplianceObject!
    $creativeType: CREATIVE_TYPE!
    $productId: ID!
  ) {
    ctAddCreativeCompliance(
      categoryIds: $categoryIds
      complainceObject: $complainceObject
      creativeType: $creativeType
      productId: $productId
    ) {
      data {
        body {
          body {
            body
            categoryId {
              name
              productCategoryId
            }
            createdBy
            id
            tags {
              id
              name
            }
          }
          categoryId {
            name
            productCategoryId
          }
          complianceStatus
          createdBy
        }
        category {
          name
          productCategoryId
        }
        description {
          categoryId {
            name
            productCategoryId
          }
          complianceStatus
          description {
            description
            id
          }
        }
        image {
          categoryId {
            name
            productCategoryId
          }
          complianceStatus
          image {
            id
            name
            r2Url
          }
        }
        title {
          title {
            id
            title
          }
          complianceStatus
          categoryId {
            name
            productCategoryId
          }
        }
        video {
          video {
            r2Url
            name
            id
            categoryId {
              name
              productCategoryId
            }
            allThumbs {
              id
              r2Url
            }
          }
          complianceStatus
          categoryId {
            name
            productCategoryId
          }
        }
      }
      message
      status
    }
  }
`;

export const GQL_GET_COMPLIANCE_STATUS = gql`
  query ct_getComplianceStatus($creativeIds: [creativeIdInput]!, $creativeType: CREATIVE_TYPE!) {
    ct_getComplianceStatus(creativeIds: $creativeIds, creativeType: $creativeType) {
      data {
        creativeId
        status
      }
      message
      status
    }
  }
`;

export const GQL_ADD_FEATURED_IMAGES = gql`
  mutation ctAddFeaturedCreativeImages(
    $categoryId: ID!
    $categoryName: String!
    $images: [String]!
    $productId: ID!
  ) {
    ctAddFeaturedCreativeImages(
      categoryId: $categoryId
      categoryName: $categoryName
      images: $images
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
        }
      }
      message
      status
    }
  }
`;
export const GQL_ADD_FEATURED_VIDEOS = gql`
  mutation ctAddFeaturedCreativeVideos(
    $categoryId: ID!
    $categoryName: String!
    $videos: [String]!
    $productId: ID!
  ) {
    ctAddFeaturedCreativeVideos(
      categoryId: $categoryId
      categoryName: $categoryName
      videos: $videos
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
          video {
            id
            name
          }
        }
      }
      message
      status
    }
  }
`;
export const GQL_ADD_FEATURED_TITLES = gql`
  mutation ctAddFeaturedCreativeTitles(
    $categoryId: ID!
    $categoryName: String!
    $titles: [String]!
    $productId: ID!
  ) {
    ctAddFeaturedCreativeTitles(
      categoryId: $categoryId
      categoryName: $categoryName
      titles: $titles
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
        }
      }
      message
      status
    }
  }
`;

export const GQL_ADD_FEATURED_BODIES = gql`
  mutation ctAddFeaturedCreativeBodies(
    $categoryId: ID!
    $categoryName: String!
    $bodies: [String]!
    $productId: ID!
  ) {
    ctAddFeaturedCreativeBodies(
      categoryId: $categoryId
      categoryName: $categoryName
      bodies: $bodies
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
        }
      }
      message
      status
    }
  }
`;

export const GQL_ADD_FEATURED_DESC = gql`
  mutation ctAddFeaturedCreativeDescs(
    $categoryId: ID!
    $categoryName: String!
    $descs: [String]!
    $productId: ID!
  ) {
    ctAddFeaturedCreativeDescs(
      categoryId: $categoryId
      categoryName: $categoryName
      descs: $descs
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
        }
      }
      message
      status
    }
  }
`;

export const GQL_ADD_FAETURED_GROUPS = gql`
  mutation ctAddFeaturedCreativeGroups(
    $categoryId: ID!
    $categoryName: String!
    $groups: [String]!
    $productId: ID!
  ) {
    ctAddFeaturedCreativeGroups(
      categoryId: $categoryId
      categoryName: $categoryName
      groups: $groups
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
        }
      }
      message
      status
    }
  }
`;

export const GQL_GET_TIKTOK_RECOMMENDED_CREATIVES = gql`
  query tiktok_categoryRecommendedCreatives(
    $category_id: ID!
    $end: Date!
    $page: Int
    $product_id: ID
    $size: Int
    $start: Date!
    $timezone: String
  ) {
    tiktok_categoryRecommendedCreatives(
      category_id: $category_id
      end: $end
      page: $page
      product_id: $product_id
      size: $size
      start: $start
      timezone: $timezone
    ) {
      data {
        ct_video_id
        video_url
        video_thumb_url
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GQL_CREATIVE_SIMILAR_VIDEO = gql`
  query ct_getSimilarVideos($videoId: ID!) {
    ct_getSimilarVideos(videoId: $videoId) {
      originalVideo {
        id
      }
      currentVideo {
        id
      }
      data {
        similarityPercentage
        video {
          id
          name
          r2Url
          allThumbs {
            id
            r2Url
          }
        }
      }
    }
  }
`;
export const GQL_MERGE_CREATIVES = gql`
  mutation ctManualMerging(
    $forceMerge: Boolean
    $videoIds: [ID]!
    $videoToBeMergedIn: ID
    $videosToMerge: [ID]
  ) {
    ctManualMerging(
      forceMerge: $forceMerge
      videoIds: $videoIds
      videoToBeMergedIn: $videoToBeMergedIn
      videosToMerge: $videosToMerge
    ) {
      data {
        differentVideos {
          id
          name
          r2Url
          allThumbs {
            id
            r2Url
          }
        }
        remainingVideo {
          id
          name
          r2Url
          allThumbs {
            id
            r2Url
          }
        }
      }
      message
      status
    }
  }
`;
export const GQL_GET_FB_RECOMMENDED_CREATIVES = gql`
  query fb360_categoryRecommendedCreative(
    $category_id: ID
    $end: Date!
    $group_by: creative_fetch_type
    $page: Int
    $product_id: ID
    $size: Int
    $start: Date!
    $timezone: String
  ) {
    fb360_categoryRecommendedCreative(
      category_id: $category_id
      end: $end
      page: $page
      product_id: $product_id
      size: $size
      start: $start
      timezone: $timezone
      group_by: $group_by
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        creative_url_object {
          videos {
            urls {
              id
              r2Url
              thumbUrl
            }
          }
          images {
            urls {
              id
              r2Url
            }
          }
        }
      }
    }
  }
`;

export const GQL_GET_SUBTITLE_VARIATIONS_CB = gql`
  query ctSubtitleConfigs {
    ctSubtitleConfigs
  }
`;
