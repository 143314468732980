import { LockOutlined, SettingOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
const userPath = [
  {
    name: "User",
    isVisible: false,
    link: "/user",
    icon: TeamOutlined,
    subMenu: [
      {
        subMenuName: "Profile",
        subMenuLink: "/profile",
        subMenuIcon: UserOutlined,
      },
      {
        subMenuName: "Security",
        subMenuLink: "/security",
        subMenuIcon: LockOutlined,
      },
      {
        subMenuName: "Settings",
        subMenuLink: "/settings",
        subMenuIcon: SettingOutlined,
      },
    ],
  },
];

export default userPath;
