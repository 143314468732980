import { Row, Col, Image, Form, Button, Popover } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import cx from "cx";
import { Formik } from "formik";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GQL_IMG_SCRAPER_GET_CREATIVES } from "shared/gql/gqlSchema/creativeGql";
import * as yup from "yup";
import useUploadFiles from "../CreativeUpload/useUploadFiles";
import { openNotification } from "utils";
import { S3_MULTI_FILE_UPLOAD } from "shared/gql/gqlSchema/mediaGql";
import EditImage from "./EditImage";
import { useLookModal } from "components/LookModal";
import LookButton from "components/LookButton";
import { MEDIA_ADD_CREATIVE_IMAGE } from "shared/gql/gqlSchema/creativeDashboardGql";

const SOURCE_ENUM = [
  // { name: "Pinterest", value: "pinterest.com" },
  { name: "Google", value: "google.com" },
];

function MediaItem(props) {
  const {
    image = "",
    selected = false,
    onSelect,
    setCreativesImages,
    source,
    creativeType,
    categoryName,
    productId,
    categoryId,
    verticalId,
    subCategory,
  } = props;

  const [hide, setHide] = useState(false);
  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Edit Image",
    footer: false,
    width: "80vw",
  });

  function pushJpgToPng() {
    setCreativesImages((pre) => [...pre, image?.replace("jpg", "png")]);
  }

  return (
    <Col span={4} className={cx({ "d-none": hide })} xs={12} sm={8} md={6} xl={4}>
      <Popover
        className="ant-popover-open"
        content={
          <img
            src={image}
            alt="Preview"
            style={{ maxWidth: "400px", maxHeight: "400px", objectFit: "contain" }}
          />
        }
        placement="left"
        mouseEnterDelay={0.8}
        overlayStyle={{ pointerEvents: "none" }} // Apply pointer-events: none
      >
        <div
          className={cx("creative-image-selection ant-image-ration-1-1", { selected })}
          onClick={onSelect}
        >
          <Image
            className=""
            src={image}
            preview={false}
            onError={() => {
              setHide(true);
              if (source && source === "pinterest.com" && image?.includes("jpg")) {
                pushJpgToPng();
              }
            }}
          />
          {selected && (
            <div className="position-absolute-center z-index-2 overlay">
              <ActionIconRender iconType="check" />
            </div>
          )}
        </div>
      </Popover>
      <p>
        <LookButton className="w-100" onClick={() => openModal()}>
          Edit Image
        </LookButton>
      </p>
      {visibleModal &&
        modal(
          <EditImage
            src={image}
            creativeType={creativeType}
            categoryName={categoryName}
            productId={productId}
            categoryId={categoryId}
            subCategory={subCategory}
            verticalId={verticalId}
            closeModal={closeModal}
          />
        )}
    </Col>
  );
}

function FilterForm(props) {
  const { initialValues, validationSchema, onSubmit, loading = false } = props;

  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);

  const {
    adlibCountry = [],
    adlibCountry_loading = false,
    sasLanguages = [],
    sasLanguages_loading = false,
  } = userSettingData || {};

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={values.source === "pinterest.com" ? 10 : 5}>
              <LookTextInput
                formik
                name="keyword"
                label="Keyword"
                className="look-form-input"
                placeholder="Enter keyword"
              />
            </Col>

            <Col span={values.source === "pinterest.com" ? 10 : 5}>
              <SelectInput
                label="Source"
                formik
                search
                placeholder="source"
                name="source"
                value={values.source}
                options={SOURCE_ENUM}
                onChange={setFieldValue}
              />
            </Col>
            {values.source !== "pinterest.com" && (
              <>
                <Col span={5}>
                  <SelectInput
                    label="Country"
                    formik
                    search
                    placeholder="Country"
                    name="country_code"
                    value={values.country_code}
                    options={adlibCountry?.map(({ name, iso_code }) => ({ name, value: iso_code }))}
                    onChange={setFieldValue}
                  />
                </Col>
                <Col span={5}>
                  <SelectInput
                    label="Language"
                    formik
                    search
                    placeholder="Select language"
                    name="language_code"
                    value={values.language_code}
                    options={sasLanguages?.map(({ name, isoCode }) => ({ name, value: isoCode }))}
                    onChange={setFieldValue}
                  />
                </Col>
              </>
            )}
            <Col span={4}>
              <Form.Item label=" ">
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  loading={loading}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Filter
                  {/* {loading ? <Loader /> : btnText} */}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
function removeDuplicates(arr) {
  return [...new Set(arr)];
}
export default function GoogleCreativeImagesUpload(props) {
  const { name, creativeType, categoryId, productId, subCategory, verticalId, categoryName } =
    props;

  const [call_imgScraper_getCreatives, { loading: loading_imgScraper_getCreatives }] =
    useCustomLazyQuery(GQL_IMG_SCRAPER_GET_CREATIVES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.imgScraper_getCreatives;
        if (element) {
          setCreativesImages((pre) => removeDuplicates([...pre, ...element?.data]));
          setCounter((pre) => pre + 1);
        }
      },
      onError(e) {},
    });

  // removing this api, as told by Siddhant Gaba

  // const [call_clSearchPinterestImages, { loading: loading_clSearchPinterestImages }] =
  //   useCustomLazyQuery(GQL_CL_SEARCH_PINTEREST_IMAGES, {
  //     notifyOnNetworkStatusChange: true,
  //     onCompleted: (e) => {
  //       const element = e?.clSearchPinterestImages;
  //       if (element) {
  //         setCreativesImages((pre) => removeDuplicates([...pre, ...element?.data]));
  //         setCounter((pre) => pre + 1);
  //       }
  //     },
  //     onError(e) {},
  //   });

  function onSubmit(values) {
    const { keyword, source, ...rest } = values;
    /**
     * setCreativesImages([]); for Clear already fetched images for initial call or when changes in filter
     * setFormStateCopy(values); use for make a copy of formik data and used when click on Load More
     * setCounter(0); Same like setCreativesImages([]) reset
     */

    setCreativesImages([]);
    setFormStateCopy(values);

    if (source === "pinterest.com") {
      setCounter(1);
      // call_clSearchPinterestImages({ variables: { key: keyword, scrollNum: 1 } });
    } else {
      setCounter(0);
      call_imgScraper_getCreatives({ variables: { ...rest, keyword, offset: 0 } });
    }
  }

  function callLoadMore() {
    const { keyword, source } = formStateCopy;
    if (source === "pinterest.com") {
      // call_clSearchPinterestImages({ variables: { key: keyword, scrollNum: counter } });
    } else {
      call_imgScraper_getCreatives({ variables: { ...formStateCopy, offset: counter * 10 } });
    }
  }

  const [formStateCopy, setFormStateCopy] = useState({});
  const [counter, setCounter] = useState(0);

  const initialValues = {
    country_code: "US" || "",
    keyword: name || "",
    language_code: "en",
    source: SOURCE_ENUM[0].value,
  };

  useEffect(() => {
    if (name) {
      onSubmit(initialValues);
    }
  }, [name]);

  const validationSchema = yup.object({ keyword: yup.string().required().label("Keyword") });

  const formProps = { initialValues, validationSchema, onSubmit };

  const [selectedImages, setSelectedImages] = useState([]);

  const [creativesImages, setCreativesImages] = useState([]);

  const handleSelectImage = (image) => {
    if (selectedImages?.map((v) => v === image)?.includes(true)) {
      setSelectedImages(selectedImages?.filter((img) => img !== image));
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };
  const renderCreativeData = () => (
    <>
      {creativesImages?.map((data, index) => {
        const selectedImg = selectedImages?.filter((img) => img === data);
        return (
          <MediaItem
            key={"mediaItems" + index}
            image={data}
            onSelect={() => handleSelectImage(data)}
            selected={selectedImg?.length > 0}
            setCreativesImages={setCreativesImages}
            source={formStateCopy?.source}
            creativeType={creativeType}
            categoryName={categoryName}
            productId={productId}
            categoryId={categoryId}
            subCategory={subCategory}
            verticalId={verticalId}
          />
        );
      })}
    </>
  );
  const variables = {
    productId,
    categoryId,
    categoryName,
  };

  const [clAddImages, { loading: loading_Add }] = useCustomMutation(MEDIA_ADD_CREATIVE_IMAGE, {
    variables: variables,
    onCompleted: (e) => {
      const element = e?.ctAddCreativeImages;

      if (element) {
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: "Record added",
            key: "creative-upload-success",
          });
          setSelectedImages([]);
        } else {
          openNotification({
            type: "error",
            message: element?.message,
            key: "creative-upload-error",
          });
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });
  const getImageResponse = (imagesData) => {
    clAddImages({ variables: { images: imagesData?.map((data) => data?.public_url) } });
  };

  const { handleBlobUpload, uploadLoading } = useUploadFiles({
    name: categoryName,
    // getImageResponse,
    creativeType,
    loading: loading_Add,
  });

  const [s3MultiFileUpload, { loading: loading_S3_MULTI_FILE_UPLOAD }] = useCustomMutation(
    S3_MULTI_FILE_UPLOAD,
    {
      onCompleted(e) {
        const element = e?.s3MultiFileUpload;
        // if (element) {
        //   getImageResponse(element);
        // }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
      variables: { service_name: "CREATIVE", fileType: creativeType, category: categoryName },
    }
  );

  const combineLoading = uploadLoading || loading_S3_MULTI_FILE_UPLOAD || loading_Add;

  function getFileNameFromLink(link) {
    var startIndex = link?.lastIndexOf("/") + 1;
    var endIndex = link?.indexOf("?") !== -1 ? link.indexOf("?") : link.length;
    var fileName = link?.substring(startIndex, endIndex);
    return fileName;
  }

  const s3FileUploadThroughURL = (linkData) => {
    return new Promise((resolve, reject) => {
      s3MultiFileUpload({ variables: { source_img_url: linkData } })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const s3FileUploadThrougBlob = (blobData) => {
    return new Promise((resolve, reject) => {
      handleBlobUpload(
        blobData?.map(({ blob }) => blob),
        blobData?.map(({ url }, i) => getFileNameFromLink(url))
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleConvertImages = async (imageUrls) => {
    try {
      let blobData = [];
      let linkData = [];

      (async function () {
        for await (const url of imageUrls) {
          await fetch(url)
            .then(async (responseBlob) => {
              const blob = await responseBlob.blob();
              blobData?.push({ url, blob });
              // return await responseBlob.blob();
            })
            .catch((error) => {
              console.error("Catch", error);
              linkData?.push(url);
            });
        }
      })().then(async () => {
        let finalDataFromURL = [];
        let finalDataFromBlob = [];
        let finalCombinedData = [];
        if (linkData?.length > 0) {
          const resolvedURL = await s3FileUploadThroughURL(linkData);
          finalDataFromURL = resolvedURL?.data?.s3MultiFileUpload;
        }
        if (blobData?.length > 0) {
          const resolvedBLOB = await s3FileUploadThrougBlob(blobData);
          finalDataFromBlob = resolvedBLOB;
        }
        if (finalDataFromBlob && finalDataFromURL) {
          finalCombinedData = [...finalDataFromBlob, ...finalDataFromURL];
        } else if (finalDataFromBlob) {
          finalCombinedData = finalDataFromBlob;
        } else if (finalDataFromURL) {
          finalCombinedData = finalDataFromURL;
        }
        getImageResponse(finalCombinedData);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const bulkActions = [
    { option: "upload", title: "Upload", onClick: handleConvertImages, loading: combineLoading },
  ];
  const bulkActionProps = {
    selectedRows: selectedImages,
    onDismiss: () => setSelectedImages([]),
    actionOptions: bulkActions,
  };
  return (
    <>
      <LookTableBulkAction {...bulkActionProps} label="Selected Images" />
      <FilterForm
        {...formProps}
        loading={
          // (loading_clSearchPinterestImages && counter === 1) ||
          loading_imgScraper_getCreatives && counter === 0
        }
      />
      <div
        className="px-24 overflow-auto"
        style={{ margin: "0 -24px 24px", height: "calc(100vh - 240px)" }}
      >
        <Row gutter={[24, 24]}>{renderCreativeData()}</Row>
      </div>
      {creativesImages?.length > 0 && (
        <Button
          loading={loading_imgScraper_getCreatives} // || loading_clSearchPinterestImages}
          htmlType="submit"
          block
          type="primary"
          onClick={callLoadMore}
        >
          Load More...
        </Button>
      )}
    </>
  );
}
