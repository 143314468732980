import { useState } from "react";
import alasql from "alasql";
import { useSelector } from "react-redux";
import {
  GET_REPORT_PER_AD_NAME,
  VS_PERFORMANCE_REPORT_DAILY,
} from "shared/gql/gqlSchema/viralSparksGql";
import moment from "moment";
import { useCustomQuery } from "hooks/apolloClientHooks";
import columns from "./columns";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { SPEND_INCREASE_PERCENT_VS } from "shared/enum/commonKeysandEnum";

export default function VSPerformance(props) {
  const {
    utmc_id,
    selectedAdvertiser: propSelectedAdvertiser,
    selectedPlatform: propSelectedPlatform,
    timezone: timeZoneProps,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    utmMedium,
    utm_campaign = "",
    adName = "",
  } = props;

  const dateFormat = "YYYY-MM-DD";
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const currentPlatform =
    propSelectedPlatform === "all" ? {} : { platform_id: propSelectedPlatform };

  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const defaultDate = [moment().subtract(7, "d").format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const [dailyReport, setDailyReport] = useState([]);
  const [spendReport, setSpendReport] = useState([]);
  const {
    data: data_GET_REPORT_DATA,
    loading: loading_GET_REPORT_DATA,
    error: error_GET_REPORT_DATA,
    refetch: refetch_GET_REPORT_DATA,
  } = useCustomQuery(VS_PERFORMANCE_REPORT_DAILY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_performaceReportsDay;
      if (element) {
        setDailyReport(element);
      }
    },
    variables: {
      advertiser_id: propSelectedAdvertiser,
      from: rangeDates[0],
      timezone: timeZone?.value,
      to: rangeDates[1],
      utmc_id,
      ...currentPlatform,
    },
  });

  const {
    data: data_GET_REPORT_BY_AD_NAME,
    loading: loading_GET_REPORT_BY_AD_NAME,
    error: error_GET_REPORT_BY_AD_NAME,
    refetch: refetch_GET_REPORT_BY_AD_NAME,
  } = useCustomQuery(GET_REPORT_PER_AD_NAME, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.reportPerAdName;
      if (element) {
        setSpendReport(element);
      }
    },
    variables: {
      from: rangeDates[0],
      to: rangeDates[1],
      timezone: timeZone?.value,
      userId: user_id,
      adName: utm_campaign || adName,
    },
  });

  const loading = loading_GET_REPORT_DATA || loading_GET_REPORT_BY_AD_NAME;
  const refetch = () => {
    refetch_GET_REPORT_DATA();
    refetch_GET_REPORT_BY_AD_NAME();
  };

  const initialColumns = [
    {
      title: "Date",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (value, { PROFIT }) => {
        return <LookTableColumnRender title={value} isProfit={PROFIT} />;
      },
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    ...columns,
  ];

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return parseFloat(valueFloat.toFixed(3));
    return valueFloat;
  };

  let finalMerge = [];
  let finalMergeData = [];

  if (!loading) {
    finalMerge = alasql(
      "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.report_time = b.date order by b.date, a.report_time",
      [dailyReport, spendReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        leadsFB = 0,
        impressions = 0 | "0",
        sessions = 0 | "0",
        pageViews = 0 | "0",
        utm_campaign,
        adName = "",
        user_revenue = 0,
        report_time = "",
        date = "",
      } = element;

      const spendUpdate = (spendFB * SPEND_INCREASE_PERCENT_VS).toFixed(3);
      // const revenueUpdate = (TotalRevenue * 0.94).toFixed(2);

      const revenueUpdate = user_revenue;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(leadsFB) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageViews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;

      finalMergeData.push({
        ...element,
        utmCampaign: utm_campaign || adName,
        TotalRevenue: valueCheck(revenueUpdate),
        clicksFB,
        leadsFB,
        impressionsFB,
        key: index,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT, true),
        CPC: valueCheck(CPC, true),
        CPC_FB: valueCheck(CPC_FB, true),
        ROI: valueCheck(ROI, true),
        EPC: valueCheck(EPC, true),
        CTR: valueCheck(CTR, true),
        DISC: valueCheck(DISC, true),
        IPP: valueCheck(IPP, true),
        RPM: valueCheck(RPM, true),
        PRPM: valueCheck(PRPM, true),
        CPR: valueCheck(CPR, true),
        PPS: valueCheck(PPS, true),
        CPM: valueCheck(CPM, true),
        dateTime: report_time || date,
      });
    });
  }
  const finalFilterData = finalMergeData
    ?.filter(({ utmCampaign = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";
      if (utmCampaign?.toLowerCase()?.includes(searchValue)) {
        return true;
      }
      return false;
    })
    .sort(function (a, b) {
      let keyA = new Date(a.dateTime);
      let keyB = new Date(b.dateTime);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_leadsFB = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    leadsFB = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageViews = 0,
  } of finalFilterData) {
    total_spendFB += JSON.parse(spendFB || 0);
    total_clicksFB += JSON.parse(clicksFB || 0);
    total_impressionsFB += JSON.parse(impressionsFB || 0);
    total_leadsFB += JSON.parse(leadsFB || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageViews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicksFB;
  const total_ROI = (total_PROFIT / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicksFB / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_leadsFB;
  const total_DISC = total_sessions / total_clicksFB;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const summaryData = {
    TotalRevenue: valueCheck(total_TotalRevenue, true),
    spendFB: valueCheck(total_spendFB, true),
    PROFIT: total_PROFIT.toFixed(3),
    ROI: valueCheck(total_ROI, true),
    EPC: valueCheck(total_EPC, true),
    CPC: valueCheck(total_CPC, true),
    CPC_FB: valueCheck(total_CPC_FB, true),
    CTR: valueCheck(total_CTR, true),
    leadsFB: valueCheck(total_leadsFB, true),
    CPR: valueCheck(total_CPR, true),
    sessions: valueCheck(total_sessions, true),
    clicksFB: valueCheck(total_clicksFB, true),
    DISC: valueCheck(total_DISC, true),
    IPP: valueCheck(total_IPP, true),
    RPM: valueCheck(total_RPM, true),
    PRPM: valueCheck(total_PRPM, true),
    PPS: valueCheck(total_PPS, true),
    CPM: valueCheck(total_CPM, true),
  };

  const tableProps = { initialColumns, recordData: finalFilterData, summaryData };

  return (
    <div>
      <LookTable
        isLineChart
        persistKey={persistKey}
        isRowSelection={false}
        isDateRange
        dateRangeProps={{
          noStore: true,
          defaultDate: defaultDate,
          defaultTimeZone: timeZoneProps,
          onChange: handleDateRange,
        }}
        defaultPagination
        refetch={refetch}
        loading={loading}
        tableProps={tableProps}
      />

      <DebugPre
        content={[
          {
            isRow: true,
            contentObjects: [{ finalMerge }, { finalMergeData }],
          },
          {
            isRow: true,
            contentObjects: [{ dailyReport }, { spendReport }],
          },
        ]}
      />
    </div>
  );
}
