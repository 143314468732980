import {
  USER_SETTING_REPORT_FROM_HOUR,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import { Form, Select, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const { Option } = Select;

export default function HourSelectPersist(props) {
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;
  const dispatch = useDispatch();
  const reportFromHour = useSelector((state) => state?.userSettings?.reportFromHour);
  const selectedHour =
    reportFromHour && reportFromHour[persistKey] ? reportFromHour[persistKey] : null;

  let hours = [];

  for (let i = 1; i < 24; i++) {
    hours.push({ name: i, id: i });
  }

  function handle(e) {
    dispatch({
      type: USER_SETTING_REPORT_FROM_HOUR,
      payload: { ...reportFromHour, [persistKey]: e },
    });
  }

  return (
    <div style={{ width: 150 }}>
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex-grow-1">
            <Form.Item className="look-form-input mb-0">
              <Tooltip title="Select Hours">
                <Select
                  name="user"
                  className="w-100"
                  placeholder="Select Hours"
                  onChange={handle}
                  showSearch
                  value={selectedHour}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={null}>Full day</Option>
                  {hours?.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name} hours
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
