import { CacheBuster } from "components/CacheBuster";
import React, { Suspense } from "react";
import { Switch, BrowserRouter as Router, Redirect } from "react-router-dom";
import useRoutes from "./useRoutes";

function RouteResolver({ key, ...data }) {
  let Component = data.type;
  return <Component {...data} />;
}

function Routes() {
  const { routes } = useRoutes();
  // const location = useLocation();

  return (
    <Suspense fallback={<div className="header-nav-loader"></div>}>
      <Router>
        <Switch>
          {routes.map((route) => (
            <RouteResolver key={route.key} {...route} />
          ))}
          <Redirect from="*" to="/404" />
        </Switch>
      </Router>
    </Suspense>
  );
}

export default Routes;
