import { IdcardOutlined } from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";
import { usePermissionCheck } from "hooks";
import { useSelector } from "react-redux";
import {
  SCORECARD_VIEW_ADMIN,
  SCORECARD_VIEW_PRODUCT,
  SCORECARD_VIEW_USER,
} from "shared/UserPermission/userPermissionList";

export default function useScorecardPath() {
  const { havePermission: isCompanyAdmin } = usePermissionCheck(SCORECARD_VIEW_ADMIN);
  const { havePermission: isProductAdmin } = usePermissionCheck(SCORECARD_VIEW_PRODUCT);
  const { isDepartmentAdmin = false } = useSelector((state) => state?.userReducer.me) || {};

  const scorecardPath = [
    {
      name: "Scorecard",
      link: "/scorecard",
      icon: IdcardOutlined,
      subMenu: [
        {
          subMenuName: "Dashboard",
          subMenuLink: "/scorecard-dashboard",
          subMenuIcon: () => <ActionIconRender iconType="dashboard" />,
          permission: [SCORECARD_VIEW_USER, SCORECARD_VIEW_PRODUCT],
        },
        {
          subMenuName: "Company",
          subMenuLink: "/company",
          subMenuIcon: () => <ActionIconRender iconType="team" />,
          permission: SCORECARD_VIEW_ADMIN,
        },
        {
          subMenuName: "Line of Business",
          subMenuLink: "/line-of-business",
          subMenuIcon: () => <ActionIconRender iconType="team" />,
          permission: SCORECARD_VIEW_ADMIN,
        },
        {
          subMenuName: "Department",
          subMenuLink: "/department",
          subMenuIcon: () => <ActionIconRender iconType="collection" />,
          isVisible: isDepartmentAdmin || isCompanyAdmin || isProductAdmin,
        },
        {
          subMenuName: "User",
          subMenuLink: "/scorecard-user",
          subMenuIcon: () => <ActionIconRender iconType="user" />,
          isVisible: isDepartmentAdmin || isCompanyAdmin || isProductAdmin,
        },
        {
          subMenuName: "My Report",
          subMenuLink: "/my-report",
          subMenuIcon: () => <ActionIconRender iconType="lineChart" />,
          isVisible: !(isCompanyAdmin || isProductAdmin),
          permission: SCORECARD_VIEW_USER,
        },
        {
          subMenuName: "Dashboard (Beta)",
          subMenuLink: "/new-scorecard-dashboard",
          subMenuIcon: () => <ActionIconRender iconType="dashboard" />,
          permission: [SCORECARD_VIEW_USER, SCORECARD_VIEW_PRODUCT],
        },
      ].filter(({ isVisible = true }) => isVisible),
    },
  ];

  return { scorecardPath };
}
