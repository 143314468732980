export const defaultFilterColumns = [
  {
    label: "Spend",
    column: "spendFB",
    filterOptions: [
      {
        label: "below 1",
        filterType: "<",
        value: "1",
      },
      {
        label: "more than 1",
        filterType: ">",
        value: "1",
      },
      {
        label: ">=1 and <20",
        filterType: "range",
        rangeMin: "1",
        rangeMax: "20",
      },
      {
        label: ">=20 and <100",
        filterType: "range",
        rangeMin: "20",
        rangeMax: "100",
      },
      {
        label: "more than 200",
        filterType: ">",
        value: "200",
      },
    ],
  },

  {
    label: "Gross Margin",
    column: "PROFIT",
    filterOptions: [
      {
        label: "Less than 0",
        filterType: "<",
        value: "0",
      },
      {
        label: ">=0 and <100",
        filterType: "range",
        rangeMin: "0",
        rangeMax: "100",
      },
      {
        label: ">=100 and <200",
        filterType: "range",
        rangeMin: "100",
        rangeMax: "200",
      },
      {
        label: "More than 200",
        filterType: ">",
        value: "200",
      },
    ],
  },
  {
    label: "ROAS",
    column: "ROI",
    filterOptions: [
      {
        label: "Less than 0",
        filterType: "<",
        value: "0",
      },
      {
        label: "> 0",
        filterType: ">",
        value: "0",
      },
      {
        label: ">= 0 and <20",
        filterType: "range",
        rangeMin: "0",
        rangeMax: "20",
      },
      {
        label: ">=20 and <50",
        filterType: "range",
        rangeMin: "20",
        rangeMax: "50",
      },
      {
        label: "Above 50",
        filterType: ">",
        value: "50",
      },
      {
        label: "Above 100",
        filterType: ">",
        value: "100",
      },
    ],
  },
  {
    label: "Status",
    column: "status",
    filterOptions: [
      {
        label: "Active",
        filterType: "includeSearch",
        value: ["active"],
      },
      {
        label: "Paused",
        filterType: "includeSearch",
        value: ["paused"],
      },
      {
        label: "In Review",
        filterType: "includeSearch",
        value: ["review"],
      },
    ],
  },

  {
    label: "Bid Strategy",
    column: "bid_strategy",
    filterOptions: [
      {
        label: "Target cost",
        filterType: "includeSearch",
        value: ["TARGET_COST"],
      },
      {
        label: "Lowest cost with bid cap",
        filterType: "includeSearch",
        value: ["LOWEST_COST_WITH_BID_CAP"],
      },
      {
        label: "Lowest cost with min ROAS",
        filterType: "includeSearch",
        value: ["LOWEST_COST_WITH_MIN_ROAS"],
      },
      {
        label: "Cost cap",
        filterType: "includeSearch",
        value: ["COST_CAP"],
      },
      {
        label: "Bid cap",
        filterType: "includeSearch",
        value: ["BID CAP"],
      },
      {
        label: "Lowest cost without cap",
        filterType: "includeSearch",
        value: ["LOWEST_COST_WITHOUT_CAP"],
      },
    ],
  },
];
