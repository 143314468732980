import { useGoogleLaunchV1Context } from "components/Launch/GoogleLaunchV1/Context";
import LookButton from "components/LookButton";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";

export default function useColumn(props = {}) {
  const { editAd = () => {} } = props;
  const { duplicateAd = () => {}, removeAd = () => {} } = useGoogleLaunchV1Context();
  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, { index }) => (
        <>
          <LookButton
            tooltipTitle="Duplicate"
            iconType="copy"
            size="small"
            onClick={() => duplicateAd({ adIndex: index })}
          />
          <LookButton
            tooltipTitle="Edit"
            iconType="edit"
            size="small"
            onClick={() => editAd(index)}
          />
          <LookButton
            tooltipTitle="Delete"
            iconType="delete"
            size="small"
            onClick={() => removeAd({ adIndex: index })}
          />
        </>
      ),
      fixed: "right",
      width: 124,
      minWidth: 124,
    },
  ];

  const columns = [
    {
      title: "Headlines",
      dataIndex: "headlines",
      key: "headlines",
      searchable: true,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Desctiption",
      dataIndex: "desctiption",
      key: "desctiption",
      searchable: true,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Final URL",
      dataIndex: "finalUrls",
      key: "finalUrls",
      searchable: true,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    ...action,
  ];
  return { columns };
}
