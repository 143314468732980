import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6,
} from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import AdGroup from "components/Launch/Creatives/AdGroup";

import Body from "components/Launch/Creatives/Body";
import CreativeUpload, { CreativeVideoUpload } from "components/Launch/Creatives/CreativeUpload";
import Description from "components/Launch/Creatives/Description";
import Title from "components/Launch/Creatives/Title";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import TabRender from "components/TabRender";
import { useState } from "react";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";

export default function CreativesDrawer(props) {
  const {
    visible = false,
    onClose = () => {},
    title,
    groupSelection = false,
    submitView,
    selectedData,
    serviceType,
  } = props;

  const hoverTitleAppend = groupSelection ? "" : "Upload";

  const isAdGroup = groupSelection
    ? []
    : [
        {
          id: "AdGroup",
          hoverTitle: `AdGroup ${hoverTitleAppend}`,
          icon: () => <ActionIconRender iconType="font-colors" />,
          component: <AdGroup {...props} serviceType={serviceType} />,
        },
      ];

  const tabContent = [
    {
      id: "Creative-Upload",
      title: groupSelection ? `Creative ${hoverTitleAppend}` : undefined,
      hoverTitle: `Creative ${hoverTitleAppend}`,
      icon: (e) => <ActionIconRender iconType="picture" {...e} />,
      component: (
        <CreativeUpload {...props} creativeType={CREATIVE_TYPE_IMAGE} serviceType={serviceType} />
      ),
    },
    {
      id: "Video-Upload",
      title: groupSelection ? `Video ${hoverTitleAppend}` : undefined,
      hoverTitle: `Video ${hoverTitleAppend}`,
      icon: (e) => <ActionIconRender iconType="video-camera" {...e} />,
      component: (
        <CreativeVideoUpload
          {...props}
          creativeType={CREATIVE_TYPE_VIDEO}
          serviceType={serviceType}
        />
      ),
    },
    {
      id: "Title",
      title: groupSelection ? `Headline ${hoverTitleAppend}` : undefined,
      hoverTitle: `Headline ${hoverTitleAppend}`,
      icon: (e) => <ActionIconRender iconType="font-size" {...e} serviceType={serviceType} />,
      component: (
        <Title
          {...props}
          persistKey={
            groupSelection
              ? USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1
              : USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2
          }
          serviceType={serviceType}
        />
      ),
    },
    {
      id: "Body",
      title: groupSelection ? `Primary text ${hoverTitleAppend}` : undefined,
      hoverTitle: `Primary text ${hoverTitleAppend}`,
      icon: (e) => <ActionIconRender iconType="font-colors" {...e} />,
      component: (
        <Body
          {...props}
          persistKey={
            groupSelection
              ? USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3
              : USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4
          }
          serviceType={serviceType}
        />
      ),
    },
    {
      id: "Description",
      // title: groupSelection ? `Description ${hoverTitleAppend}` : undefined,
      hoverTitle: `Description ${hoverTitleAppend}`,
      icon: () => <ActionIconRender iconType="align-center" />,
      component: (
        <Description
          {...props}
          persistKey={
            groupSelection
              ? USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5
              : USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6
          }
          serviceType={serviceType}
        />
      ),
    },
    ...isAdGroup,
  ];

  const [activeTab, setActiveTab] = useState(tabContent[0]?.id);
  const selectedTab = tabContent.filter(({ id }) => id === activeTab)[0];

  return (
    <LookDrawer
      size={1200}
      visible={visible}
      onClose={onClose}
      textBreadcrumb={[groupSelection ? "Ad Group" : "Vs Content", title, selectedTab?.hoverTitle]}
    >
      {visible && (
        <>
          {groupSelection ? (
            <div className="d-flex h-100" style={{ flexWrap: "wrap" }}>
              <div
                style={{
                  flex: "0 0 33%",
                  maxWidth: "33%",
                  borderRight: "1px solid #424242",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <CardCreativeAdGroup {...selectedData} />
              </div>
              <div style={{ flex: "0 0 67%", maxWidth: "67%" }}>
                <TabRender
                  className="top-bar creative-drawer-tab"
                  fullBtn
                  contentClassName="px-20"
                  tabContent={tabContent}
                  value={activeTab}
                  onChange={setActiveTab}
                />
                <div className="px-12">{submitView}</div>
              </div>
            </div>
          ) : (
            <LookDrawerMainSideBar
              value={activeTab}
              onChange={setActiveTab}
              tabContent={tabContent}
            />
          )}
        </>
      )}
    </LookDrawer>
  );
}
