import PerformanceReport from "./PerformanceReport";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import { useState } from "react";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { REPORT_TYPE_AD, REPORT_TYPE_AD_ADSET, REPORT_TYPE_CAMPAIGN } from "shared/SharedKeys";
import TiktokLeadgenSpendRevenueReport from "..";
import LookCategoryReports from "components/LookCategoryReports";
import { PLATFORM_TYPE_TIKTOK } from "shared/enum/launchKeysAndEnum";
import {
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
} from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";

export default function DetailView(props) {
  const {
    visible = false,
    ad_name,
    adId,
    adgroup_name,
    adsetId,
    campaign_name,
    campaignId,
    onClose,
    reportType,
  } = props;

  let name = "";

  let otherReports = [];
  if (REPORT_TYPE_AD === reportType) {
    name = ad_name || adId;
    otherReports = [];
  }
  if (REPORT_TYPE_AD_ADSET === reportType) {
    name = adgroup_name || adsetId;
    otherReports = [
      {
        id: "ad",
        hoverTitle: "Ad Report",
        icon: () => <ActionIconRender iconType="adIcon" />,
        component: (
          <TiktokLeadgenSpendRevenueReport
            adBy="ADSET"
            tiktokAssetId={adsetId}
            reportType={REPORT_TYPE_AD}
            revenueVariables={{
              asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
              asset_id: [adsetId],
            }}
            isAdsetPerformanceReport
            alasqlQuery="SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.adId = b.ad_id"
          />
        ),
        noSpace: true,
      },
    ];
  }
  if (REPORT_TYPE_CAMPAIGN === reportType) {
    name = campaign_name || campaign_name;

    otherReports = [
      {
        id: "adSet",
        hoverTitle: "Ad Set Report",
        icon: () => <ActionIconRender iconType="adIcon" />,
        component: (
          <TiktokLeadgenSpendRevenueReport
            adBy="CAMPAIGN"
            tiktokAssetId={campaignId}
            revenueVariables={{
              asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
              asset_id: [campaignId],
            }}
            reportType={REPORT_TYPE_AD_ADSET}
            isCampaignPerformanceReport
            alasqlQuery="SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.adsetId = b.ad_set_id"
          />
        ),
        noSpace: true,
      },
      {
        id: "ad",
        hoverTitle: "Ad Report",
        icon: () => <ActionIconRender iconType="adIcon" />,
        component: (
          <TiktokLeadgenSpendRevenueReport
            adBy="CAMPAIGN"
            tiktokAssetId={campaignId}
            revenueVariables={{
              asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
              asset_id: [campaignId],
            }}
            reportType={REPORT_TYPE_AD}
            isCampaignPerformanceReport
            alasqlQuery="SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.adId = b.ad_id"
          />
        ),
        noSpace: true,
      },
    ];
  }

  const tabContent = [
    ...otherReports,
    // {
    //   id: "performance",
    //   hoverTitle: "Performance Report",
    //   icon: () => <ActionIconRender iconType="lineChart" />,
    //   component: <PerformanceReport {...props} />,
    //   noSpace: true,
    // },
    {
      id: "performance",
      hoverTitle: "Performance Report",
      icon: () => <ActionIconRender iconType="duration" />,
      component: <PerformanceReport isPerformanceHourly {...props} />,
      noSpace: true,
    },
    {
      id: "Creatives",
      hoverTitle: "Creative Report",
      icon: () => <ActionIconRender iconType="duration" />,
      component: (
        <LookCategoryReports reportsByCampaign platformType={PLATFORM_TYPE_TIKTOK} {...props} />
      ),
      noSpace: true,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabContent[0]?.id);

  const selectedTab = tabContent.filter(({ id }) => id === activeTab)[0];
  return (
    <LookDrawer
      icon={(e) => <ActionIconRender iconType="select" {...e} />}
      size={1540}
      visible={visible}
      onClose={onClose}
      title={
        <>
          <b>{selectedTab?.hoverTitle}</b> - {name}
        </>
      }
    >
      {visible && (
        <LookDrawerMainSideBar value={activeTab} onChange={setActiveTab} tabContent={tabContent} />
      )}
    </LookDrawer>
  );
}
