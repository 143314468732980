import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { GET_LAUNCHED_CAMPAIGNS, GET_LAUNCHED_TIKTOK_CAMPAIGNS } from "shared/gql/gqlSchema/sasGql";
import Adset from "../../Adsets";

function Campaigns({ id = "", campaigns, setCampaigns = () => {}, isTiktokPlatformLaunch }) {
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1;
  const [visibleAdsetDrawer, setVisibleAdsetDrawer] = useState(false);
  const [rowPropsData, setRowProps] = useState({});
  const showAdsetDrawer = (rowProps) => {
    setRowProps(rowProps);
    setVisibleAdsetDrawer(true);
  };
  const onCloseAdsetDrawer = () => {
    setRowProps();
    setVisibleAdsetDrawer(false);
  };

  const [
    fetchFbCampaigns,
    {
      data: data_GET_CAMPAIGN_DATA,
      loading: loading_GET_CAMPAIGN_DATA,
      error: error_GET_CAMPAIGN_DATA,
      refetch: refetch_GET_CAMPAIGN_DATA,
    },
  ] = useCustomLazyQuery(GET_LAUNCHED_CAMPAIGNS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.launchedCampaigns;
      if (element) {
        setCampaigns(element);
      }
    },
  });

  const [
    fetchTiktokCampaigns,
    {
      data: data_GET_TIKTOK_CAMPAIGN_DATA,
      loading: loading_GET_TIKTOK_CAMPAIGN_DATA,
      error: error_GET_TIKTOK_CAMPAIGN_DATA,
      refetch: refetch_GET_TIKTOK_CAMPAIGN_DATA,
    },
  ] = useCustomLazyQuery(GET_LAUNCHED_TIKTOK_CAMPAIGNS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktokLaunchedCampaign;
      if (element) {
        setCampaigns(element);
      }
    },
  });
  useEffect(() => {
    if (isTiktokPlatformLaunch) {
      fetchTiktokCampaigns({
        variables: {
          launchId: id,
        },
      });
    } else {
      fetchFbCampaigns({
        variables: {
          launchId: id,
        },
      });
    }
  }, [isTiktokPlatformLaunch]);

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const initialColumns = [
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showAdsetDrawer(otherProps)} />
      ),
      width: 90,
      minWidth: 90,
      sorter: true,
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, otherProps) => <LookTableColumnRender title={otherProps?.payload?.name} />,
      width: 90,
      minWidth: 90,
      sorter: true,
    },
    {
      title: "Campaign ID",
      dataIndex: "campaignId",
      key: "campaignId",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={isTiktokPlatformLaunch ? otherProps?.campaignId : otherProps?.fbCampaignId}
          isClick={() => showAdsetDrawer(otherProps)}
        />
      ),
      width: 90,
      minWidth: 90,
    },

    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.countryName - b?.countryName,
    },

    {
      title: "Updated Date",
      dataIndex: "updatedDate",
      key: "updatedDate",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.updatedDate - b?.updatedDate,
    },
  ];

  const drawerAdsetProps = {
    title: "Adsets",
    icon: () => <ActionIconRender className="mr-10" iconType="notification" />,
    size: 1050,
    visible: visibleAdsetDrawer,
    onClose: onCloseAdsetDrawer,
  };
  const tableMainProps = {
    refetch: isTiktokPlatformLaunch ? refetch_GET_TIKTOK_CAMPAIGN_DATA : refetch_GET_CAMPAIGN_DATA,
    loading: loading_GET_CAMPAIGN_DATA || loading_GET_TIKTOK_CAMPAIGN_DATA,
    tableProps: { initialColumns, recordData: campaigns },
    isRowSelection: false,
  };

  return (
    <div>
      <LookDrawer {...drawerAdsetProps}>
        {visibleAdsetDrawer && (
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Adsets",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: (
                  <Adset
                    onClose={onCloseAdsetDrawer}
                    {...rowPropsData}
                    launchId={id}
                    isTiktokPlatformLaunch={isTiktokPlatformLaunch}
                  />
                ),
              },
            ]}
          />
        )}
      </LookDrawer>
      <LookTable {...tableMainProps} isSrNo />
    </div>
  );
}

export default Campaigns;
