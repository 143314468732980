import PerformanceReport from "./PerformanceReport";
import AdSetReport from "../../AdSets";
import AdsReport from "../../Ads";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_8,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_9,
} from "actions/actionTypes";
import { LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import {
  CountryRegionReport,
  CountryReport,
  DeviceOsReport,
  PlacementDeviceOsReport,
  PlacementReport,
  RegionReport,
} from "../../DetailReports";
import {
  REPORT_TYPE_PERFORMANCE_IS_DAILY,
  REPORT_TYPE_PERFORMANCE_IS_HOURLY,
  REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY,
} from "shared/SharedKeys";
import Creatives from "../../Creatives";
function DetailView(props) {
  const { visible = false, campaignName = "", onClose, adAccountId, campaign_id } = props;

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={campaignName}
    >
      {visible && (
        <LookDrawerMainSideBar
          tabContent={[
            {
              hoverTitle: "Daily",
              icon: () => <ActionIconRender iconType="lineChart" />,
              component: (
                <PerformanceReport performanceType={REPORT_TYPE_PERFORMANCE_IS_DAILY} {...props} />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Hourly",
              icon: () => <ActionIconRender iconType="duration" />,
              component: (
                <PerformanceReport performanceType={REPORT_TYPE_PERFORMANCE_IS_HOURLY} {...props} />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Hour of the day",
              icon: () => <ActionIconRender iconType="barChartLine" />,
              component: (
                <PerformanceReport
                  performanceType={REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Ad Set Report",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <AdSetReport
                  isCampaignFiltered
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Ads Report",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <AdsReport
                  isCampaignFiltered
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Creatives",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <Creatives persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3} {...props} />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Country",
              icon: () => <ActionIconRender iconType="global" />,
              component: (
                <CountryReport
                  type={LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN}
                  assetId={campaign_id}
                  adAccountId={adAccountId}
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Region",
              icon: () => <ActionIconRender iconType="global" />,
              component: (
                <CountryRegionReport
                  type={LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN}
                  assetId={campaign_id}
                  adAccountId={adAccountId}
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Placement",
              icon: () => <ActionIconRender iconType="priority" />,
              component: (
                <PlacementReport
                  type={LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN}
                  assetId={campaign_id}
                  adAccountId={adAccountId}
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Device OS",
              icon: () => <ActionIconRender iconType="laptop" />,
              component: (
                <DeviceOsReport
                  type={LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN}
                  assetId={campaign_id}
                  adAccountId={adAccountId}
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_8}
                  {...props}
                />
              ),
              noSpace: true,
            },

            {
              hoverTitle: "Placement Device OS",
              icon: () => <ActionIconRender iconType="apartment" />,
              component: (
                <PlacementDeviceOsReport
                  type={LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN}
                  assetId={campaign_id}
                  adAccountId={adAccountId}
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_9}
                  {...props}
                />
              ),
              noSpace: true,
            },
          ]}
        />
      )}
    </LookDrawer>
  );
}

export default DetailView;


