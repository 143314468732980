import { useCustomMutation } from "hooks/apolloClientHooks";
import { GQL_CL_UPDATE_VIDEO, GQL_CT_ADD_VIDEO_THUMBS } from "shared/gql/gqlSchema/mediaGql";
import { openNotification } from "utils";
import useUploadFiles from "./CreativeUpload/useUploadFiles";
import { RECORD_UPDATED } from "shared/enum/apiResponse";

export { default as CreativeVideoUpload } from "./CreativeUpload/CreativeVideoUpload";

export default function UploadThumb(props) {
  const {
    id,
    categoryId,
    categoryName = "",
    creativeType,
    groupSelection = false,
    creatiVideo_refetch,
    onClose = () => {},
    videoId,
    productId,
  } = props;

  const [ctUpdateVideo, { loading: loading_update }] = useCustomMutation(GQL_CT_ADD_VIDEO_THUMBS, {
    variables: { videoId: id || videoId, currentThumb: true },
    onCompleted: (e) => {
      const element = e?.ctAddVideoThumbs?.data;
      if (element) {
        creatiVideo_refetch();
        openNotification({ type: "success", message: RECORD_UPDATED });
        onClose();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const getImageResponse = (imagesData) => {
    ctUpdateVideo({ variables: { thumbUrl: imagesData[0]?.public_url } });
  };

  const { uploadView } = useUploadFiles({
    name: categoryName?.replace(" ", "-"),
    getImageResponse,
    creativeType,
  });

  return <div>{!groupSelection && uploadView()}</div>;
}
