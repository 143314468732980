import { gql } from "@apollo/client";

const FB_GROUPS_RESPONSE = gql`
  fragment FbGroup on FBGroup {
    id
    name
    slots
    url
  }
`;

export const GET_FB_GROUPS = gql`
  ${FB_GROUPS_RESPONSE}
  query fbGroups($fbProfileId: ID, $userId: ID) {
    fbGroups(fbProfileId: $fbProfileId, userId: $userId) {
      ...FbGroup
    }
  }
`;

export const GQL_AD_ACCOUNT_SYSTEM_TOKEN_STATUS = gql`
  query adAccountSystemTokenStatus($id: ID!) {
    adAccountSystemTokenStatus(id: $id) {
      error
      isValid
      status
    }
  }
`;

export const ADD_FB_GROUPS = gql`
  ${FB_GROUPS_RESPONSE}
  mutation addFBGroup($name: String!, $slots: Int!, $url: String!) {
    addFBGroup(name: $name, slots: $slots, url: $url) {
      ...FbGroup
    }
  }
`;

export const SYNC_FB_GROUPS = gql`
  mutation syncFBGroups($fbProfileId: ID!) {
    syncFBGroups(fbProfileId: $fbProfileId)
  }
`;

export const POST_TO_FB_GROUPS = gql`
  mutation postToFBGroup($fbProfileId: ID!, $groupId: ID!, $link: String!, $message: String!) {
    postToFBGroup(fbProfileId: $fbProfileId, groupId: $groupId, link: $link, message: $message)
  }
`;
const AD_ACCOUNT_ID_NAME_RESPONSE = gql`
  fragment AdAccountResponse_ID_Name on AdAccount {
    id
    accountId
    name
  }
`;

const AD_ACCOUNT_ID_NAME_CURRENCY_RESPONSE = gql`
  ${AD_ACCOUNT_ID_NAME_RESPONSE}

  fragment AdAccountResponse_ID_Name_Currency on AdAccount {
    ...AdAccountResponse_ID_Name
    currency
  }
`;
const AD_ACCOUNT_FULL_RESPONSE = gql`
  ${AD_ACCOUNT_ID_NAME_RESPONSE}
  fragment AdAccountResponse_Full on AdAccount {
    ...AdAccountResponse_ID_Name
    accountCreatedTime
    accountId
    balance
    bm {
      id
      name
      isDisabled
    }
    currency
    fundingSource
    legalEntity
    overallSpend
    rating
    spendLimit
    status
    timeZone
    user {
      id
      name
    }
    warmUpStatus
    alias
  }
`;

export const GET_AD_ACCOUNT_BY_USER_ID_NAME = gql`
  ${AD_ACCOUNT_ID_NAME_RESPONSE}
  query adAccountsByUser($userId: ID!, $workspaceId: ID) {
    adAccountsByUser(userId: $userId, workspaceId: $workspaceId) {
      ...AdAccountResponse_ID_Name
    }
  }
`;

export const GET_AD_ACCOUNT_BY_USER_ID_NAME_CURRENCY = gql`
  ${AD_ACCOUNT_ID_NAME_CURRENCY_RESPONSE}
  query adAccountsByUser($userId: ID!, $workspaceId: ID) {
    adAccountsByUser(userId: $userId, workspaceId: $workspaceId) {
      ...AdAccountResponse_ID_Name_Currency
    }
  }
`;

const CREATIVE_IMAGE_RESPONSE = gql`
  fragment CreativeImage on CreativeImage {
    id
    complianceStatus
    imageUrl
    videoId
    imageHash
  }
`;

export const GET_CREATIVE_IMAGE_IMAGE_BY_AD_ACCOUNT = gql`
  ${CREATIVE_IMAGE_RESPONSE}
  query creativeImageByAdAccount($adAccountId: String!, $page: Int!, $size: Int!) {
    creativeImageByAdAccount(adAccountId: $adAccountId, page: $page, size: $size) {
      totalCount
      totalPages
      data {
        ...CreativeImage
      }
    }
  }
`;

const UPLOAD_IMAGE_TO_FB_ACCOUNT_RESPONSE = gql`
  fragment ImageUploadResponse on ImageUploadResponse {
    hash
    message
    success
    title
    url
  }
`;

export const UPLOAD_IMAGE_TO_FB_ACCOUNT = gql`
  ${UPLOAD_IMAGE_TO_FB_ACCOUNT_RESPONSE}
  mutation uploadImageToFBAccount($accountId: String!, $bytes: String!) {
    uploadImageToFBAccount(accountId: $accountId, bytes: $bytes) {
      ...ImageUploadResponse
    }
  }
`;

const LAUNCH_CONFIG_RESPONSE = gql`
  fragment launchConfig on launchConfig {
    ad
    campaign
    ad_set
    targeting
    status
    validation
  }
`;

export const GET_LAUNCH_CONFIG = gql`
  ${LAUNCH_CONFIG_RESPONSE}
  query launchConfig {
    launchConfig {
      ...launchConfig
    }
  }
`;

const TARGETING_COUNTRIES_RESPONSE = gql`
  fragment TargetingCountries on TargetingCountries {
    key
    country_code
    name
    supports_city
    supports_region
  }
`;

export const GET_TARGETING_COUNTRIES = gql`
  ${TARGETING_COUNTRIES_RESPONSE}
  query targetingCountries {
    targetingCountries {
      ...TargetingCountries
    }
  }
`;

/**
 * * LaunchResponse {
 * * id: ID
 * * message: String
 * * success: Boolean
 * * title: String
 * }
 */

const LAUNCH_RESPONSE = gql`
  fragment LaunchResponse on LaunchResponse {
    id
    message
    success
    title
  }
`;

export const CREATE_LAUNCH_CAMPAIGN = gql`
  ${LAUNCH_RESPONSE}
  mutation launchCampaign($accountId: String!, $campaignInput: CampaignInput!) {
    launchCampaign(accountId: $accountId, campaignInput: $campaignInput) {
      ...LaunchResponse
    }
  }
`;

// type CampaignInput {
//   budget: Float
//   endTime: String
//   name: String!
//   objective: CAMPAIGN_OBJECTIVE!
//   specialAdCategories: [String]!
//   startTime: String
//   status: ASSET_STATUS!
//  }

// enum CAMPAIGN_OBJECTIVE {
//   APP_INSTALLS
//   BRAND_AWARENESS
//   CONVERSIONS
//   EVENT_RESPONSES
//   LEAD_GENERATION
//   LINK_CLICKS
//   LOCAL_AWARENESS
//   MESSAGES
//   OFFER_CLAIMS
//   PAGE_LIKES
//   POST_ENGAGEMENT
//   PRODUCT_CATALOG_SALES
//   REACH
//   STORE_VISITS
//   VIDEO_VIEWS
// }

// enum ASSET_STATUS {
//   ACTIVE
//   ARCHIVED
//   DELETED
//   PAUSED
// }

export const CREATE_LAUNCH_AD_SET = gql`
  ${LAUNCH_RESPONSE}
  mutation launchAdSet(
    $accountId: String!
    $adSetInput: AdSetInput!
    $targetingInput: TargetingInput!
  ) {
    launchAdSet(accountId: $accountId, adSetInput: $adSetInput, targetingInput: $targetingInput) {
      ...LaunchResponse
    }
  }
`;

/*
type AdSetInput {
  bidAmount: Int!
*  billingEvent: BILLING_EVENT_ENUM!
  campaignId: String
  dailyBudget: Float!
  name: String!
  optimizationGoal: OPTIMIZATION_GOAL_ENUM!
  promotedObject: PromotedObjectInput
 * status: ASSET_STATUS!
  }


* billingEvent: BILLING_EVENT_ENUM!
campaignId: String
dailyBudget: Float!
name: String!
* optimizationGoal: OPTIMIZATION_GOAL_ENUM!
promotedObject: PromotedObjectInput
* status: ASSET_STATUS!
}


* billingEvent: BILLING_EVENT_ENUM!
enum BILLING_EVENT_ENUM {
APP_INSTALLS
CLICKS
IMPRESSIONS
LINK_CLICKS
LISTING_INTERACTION
OFFER_CLAIMS
PAGE_LIKES
POST_ENGAGEMENT
PURCHASE
THRUPLAY
}

* enum OPTIMIZATION_GOAL_ENUM {
  AD_RECALL_LIFT
  APP_INSTALLS
  APP_INSTALLS_AND_OFFSITE_CONVERSIONS
  CONVERSATIONS
  DERIVED_EVENTS
  ENGAGED_USERS
  EVENT_RESPONSES
  IMPRESSIONS
  LANDING_PAGE_VIEWS
  LEAD_GENERATION
  LINK_CLICKS
  NONE
  OFFSITE_CONVERSIONS
  PAGE_LIKES
  POST_ENGAGEMENT
  QUALITY_CALL
  QUALITY_LEAD
  REACH
  THRUPLAY
  VALUE
  VISIT_INSTAGRAM_PROFILE
  }

*  type PromotedObjectInput {
*    customEventType: CUSTOM_EVENT_ENUM
    pixelId: String!
    }

*    enum CUSTOM_EVENT_ENUM {
      ACHIEVEMENT_UNLOCKED
      ADD_PAYMENT_INFO
      ADD_TO_CART
      ADD_TO_WISHLIST
      COMPLETE_REGISTRATION
      CONTACT
      CONTENT_VIEW
      CUSTOMIZE_PRODUCT
      D2_RETENTION
      D7_RETENTION
      DONATE
      FIND_LOCATION
      INITIATED_CHECKOUT
      LEAD
      LEVEL_ACHIEVED
      LISTING_INTERACTION
      MESSAGING_CONVERSATION_STARTED_7D
      OTHER
      PURCHASE
      RATE
      SCHEDULE
      SEARCH
      SERVICE_BOOKING_REQUEST
      SPENT_CREDITS
      START_TRIAL
      SUBMIT_APPLICATION
      SUBSCRIBE
      TUTORIAL_COMPLETION
      }

*      enum ASSET_STATUS {
        ACTIVE
        ARCHIVED
        DELETED
        PAUSED
        }

*/
export const CREATE_LAUNCH_AD = gql`
  ${LAUNCH_RESPONSE}
  mutation launchAd($accountId: String!, $adInput: AdInput!, $creativeInput: CreativeInput!) {
    launchAd(accountId: $accountId, adInput: $adInput, creativeInput: $creativeInput) {
      ...LaunchResponse
    }
  }
`;

/**
 * * createdBy: ID;
 * * createdDate: String;
 * * id: ID;
 * * isPersonal: Boolean;
 * * name: String;
 * * template: JSON;
 */

const FB_TEMPLATE_RESPONSE = gql`
  fragment FBTemplate on FBTemplate {
    id
    name
    isPersonal
    createdBy
    createdDate
    template
  }
`;

/**
 * ? FBTemplateInput
 *
 * * description: String
 * * id: ID
 * * isPersonal: Boolean!
 * * name: String!
 * * template: PayloadInput
 * * workspaceId: ID!
 */

/**
 * ? PayloadInput
 *
 * * adName: String
 * * adSetName: String
 * * campaignName: String
 * * targeting: TargetingInput
 */

export const CREATE_FB_TEMPLATE = gql`
  ${FB_TEMPLATE_RESPONSE}
  mutation addFbTemplate($input: FBTemplateInput!) {
    addFbTemplate(input: $input) {
      ...FBTemplate
    }
  }
`;

export const GET_FB_TEMPLATE = gql`
  ${FB_TEMPLATE_RESPONSE}
  query fbTemplates($workspaceId: ID!) {
    fbTemplates(workspaceId: $workspaceId) {
      ...FBTemplate
    }
  }
`;

export const ADD_SEARCH_KEYWORD = gql`
  mutation addSearchKeyword(
    $countryCodes: [String]!
    $query: String!
    $workspaceIds: [ID]!
    $domainUrl: String
    $pattern: String
  ) {
    addSearchKeyword(
      countryCodes: $countryCodes
      query: $query
      workspaceIds: $workspaceIds
      domainUrl: $domainUrl
      pattern: $pattern
    ) {
      id
    }
  }
`;

const AD_ACCOUNT_RESPONSE = gql`
  fragment AdAccount on AdAccount {
    accountCreatedTime
    accountId
    id
    name
    balance
    currency
    fundingSource
    legalEntity
    overallSpend
    spendLimit
    overallSpend
    rating
    status
    timeZone
    warmUpStatus
    bm {
      name
    }
    user {
      id
      name
    }
    vendor {
      id
      name
    }
    alias
  }
`;

export const GET_AD_ACCOUNT = gql`
  ${AD_ACCOUNT_RESPONSE}
  query AD_ACCOUNT($page: Int!, $size: Int!, $bmId: ID) {
    adAccounts(page: $page, size: $size, bmId: $bmId) {
      data {
        ...AdAccount
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_AD_ACCOUNT_PROFILE = gql`
  ${AD_ACCOUNT_RESPONSE}
  query AD_ACCOUNT($fbProfileId: ID, $page: Int!, $size: Int!) {
    adAccounts(fbProfileId: $fbProfileId, page: $page, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...AdAccount
      }
    }
  }
`;

export const ARCHIVED_AD_ACCOUNT = gql`
  mutation archiveAdAccount($adAccountId: ID!) {
    archiveAdAccount(adAccountId: $adAccountId)
  }
`;

export const SYNC_AD_ACCOUNT_INFO = gql`
  mutation syncAdAccountInfo($adAccountId: ID!) {
    syncAdAccountInfo(adAccountId: $adAccountId)
  }
`;

export const SYNC_CAMPAIGNS = gql`
  mutation syncCampaigns($adAccountId: ID!) {
    syncCampaigns(adAccountId: $adAccountId)
  }
`;

export const SYNC_INSIGHTS = gql`
  mutation syncInsights($adAccountId: ID!, $date: String!) {
    syncInsights(adAccountId: $adAccountId, date: $date)
  }
`;

export const UPDATE_BUSINESS_MANAGERS = gql`
  mutation updateBMToken($bmId: ID!, $input: TokenInput!) {
    updateBMToken(bmId: $bmId, input: $input)
  }
`;

const BMS_RESPONSE = gql`
  fragment Bms on Bm {
    adAccountsCount
    twoFaStatus
    verificationStatus
    id
    bmId
    name
    isDisabled
    token {
      id
      status
    }
  }
`;

export const GET_BUSINESS_MANAGERS = gql`
  ${BMS_RESPONSE}
  query bms($page: Int!, $size: Int!) {
    bms(page: $page, size: $size) {
      data {
        ...Bms
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const SYNC_BUSINESS_MANAGER_ACCOUNTS = gql`
  mutation syncBMAdAccounts($bmID: ID!) {
    syncBMAdAccounts(bmID: $bmID)
  }
`;

export const SYNC_BUSINESS_MANAGER_ARCHIVE = gql`
  mutation archiveBM($bmID: ID!) {
    archiveBM(bmID: $bmID)
  }
`;

const FB_PROFILES_RESPONSE = gql`
  fragment FBProfile on FBProfile {
    email
    phone
    name
    isAdmin
    id
    createdOn
    password
    price
    device
    location
    groups {
      id
      name
    }
    provider {
      id
      name
      email
      phoneNumber
    }
    token {
      id
      status
    }
    user {
      id
      name
    }
    username
    profileStatus
    profilePic
  }
`;

export const GET_FB_PROFILES_ID_NAME_GROUP = gql`
  ${FB_PROFILES_RESPONSE}
  query fbProfiles($page: Int!, $size: Int!) {
    fbProfiles(page: $page, size: $size) {
      data {
        ...FBProfile
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_FB_PROFILES = gql`
  ${FB_PROFILES_RESPONSE}
  query fbProfiles($page: Int!, $size: Int!) {
    fbProfiles(page: $page, size: $size) {
      data {
        ...FBProfile
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

const FB_PAGES_RESPONSE = gql`
  fragment FBPage on FBPage {
    coverPic
    description
    id
    name
    pageCreationDate
    pageId
    profilePic
    status
    rating
    bm {
      id
    }
  }
`;

export const GET_FB_PAGES = gql`
  ${FB_PAGES_RESPONSE}
  query FB_PAGES($page: Int!, $size: Int!) {
    fbPages(page: $page, size: $size) {
      data {
        ...FBPage
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_FB_PAGES_PROFILE = gql`
  ${FB_PAGES_RESPONSE}
  query FB_PAGES($fbProfileId: ID) {
    fbPages(fbProfileId: $fbProfileId) {
      ...FBPage
    }
  }
`;

const FB_PIXELS_RESPONSE = gql`
  fragment FBPixel on FBPixel {
    id
    name
    pixelCreationDate
    pixelId
    bm {
      name
    }
  }
`;

export const GET_FB_PIXELS = gql`
  ${FB_PIXELS_RESPONSE}
  query fbPixels($page: Int!, $size: Int!) {
    fbPixels(page: $page, size: $size) {
      data {
        ...FBPixel
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const ADD_FB_PROFILE = gql`
  mutation AddFBProfile($input: ProfileInput!) {
    addFBProfile(input: $input)
  }
`;

export const ASSIGN_PROFILE_TO_USER = gql`
  mutation assignProfileToUser($fbProfileId: ID!, $userId: ID!) {
    assignProfileToUser(fbProfileId: $fbProfileId, userId: $userId)
  }
`;

export const UPDATE_FB_PROFILE_STATUS = gql`
  mutation updateFBProfileStatus($fbProfileId: ID!, $profileStatus: ProfileStatus!) {
    updateFBProfileStatus(fbProfileId: $fbProfileId, profileStatus: $profileStatus)
  }
`;

const PROVIDERS_RESPONSE = gql`
  fragment Provider on Provider {
    email
    name
    phoneNumber
    id
  }
`;

export const GET_PROVIDERS = gql`
  ${PROVIDERS_RESPONSE}
  query providers($page: Int, $size: Int) {
    providers(page: $page, size: $size) {
      data {
        ...Provider
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const UPDATE_FB_PEOFILE = gql`
  mutation updateFBProfile($id: ID!, $input: ProfileInput!) {
    updateFBProfile(id: $id, input: $input)
  }
`;

export const DELETE_FB_PROFILE = gql`
  mutation deleteFBProfile($id: ID!) {
    deleteFBProfile(id: $id)
  }
`;

export const REFRESH_FB_PROFILE = gql`
  mutation refreshFBProfileData($fbProfileId: ID!) {
    refreshFBProfileData(fbProfileId: $fbProfileId)
  }
`;

export const ADD_ADMIN_FB_PROFILE = gql`
  mutation addAdminFBProfile($email: String!, $providerId: ID!, $token: String!) {
    addAdminFBProfile(email: $email, providerId: $providerId, token: $token)
  }
`;

export const UPDATE_FB_PROFILE_TOKEN = gql`
  mutation updateFBProfileToken($fbProfileId: ID!, $input: TokenInput!) {
    updateFBProfileToken(fbProfileId: $fbProfileId, input: $input)
  }
`;

export const GET_ADMIN_PROFILES = gql`
  ${FB_PROFILES_RESPONSE}
  query adminProfiles($page: Int!, $size: Int!) {
    adminProfiles(page: $page, size: $size) {
      data {
        ...FBProfile
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const ADD_PROVIDER = gql`
  mutation addProvider($name: String!, $email: String!, $phoneNumber: String!) {
    addProvider(name: $name, email: $email, phoneNumber: $phoneNumber)
  }
`;

export const UPDATE_PROVIDER = gql`
  mutation updateProvider($id: ID!, $name: String!, $email: String!, $phoneNumber: String!) {
    updateProvider(id: $id, name: $name, email: $email, phoneNumber: $phoneNumber)
  }
`;

export const DELETE_PPROVIDER = gql`
  mutation deleteProvider($id: ID!) {
    deleteProvider(id: $id)
  }
`;

export const GET_F360_CONFIG = gql`
  query f360Config {
    f360Config {
      adAccountStatus {
        id
        value
      }
      profileStatus {
        id
        value
      }
      tokenStatus {
        id
        value
      }
    }
  }
`;

/**
 * ? TrackerCategory
 * * id: ID
 * * name: String
 * * nickName: String
 */

const TRACKER_CATEGORY_RESPONSE = gql`
  fragment TrackerCategory on TrackerCategory {
    id
    name
  }
`;

export const GET_TRACKER_CATEGORIES = gql`
  ${TRACKER_CATEGORY_RESPONSE}
  query TrackerCategories {
    TrackerCategories {
      ...TrackerCategory
    }
  }
`;
export const GET_SUBIDS = gql`
  query SubIds {
    SubIds {
      name
      id
      user {
        name
      }
    }
  }
`;

/**
 * ?  type TCategoryInput {
 * *  id: ID
 * *  name: String!
 * * }

 *  Without @param id input new Entry 
 */

export const ADD_UPDATE_TRACKER_CATEGORY = gql`
  ${TRACKER_CATEGORY_RESPONSE}
  mutation saveUpdateTCategory($input: TCategoryInput) {
    saveUpdateTCategory(input: $input) {
      ...TrackerCategory
    }
  }
`;

// export const UPDATE_CAMPAIGN_CATEGORY = gql`
//   mutation updateCampaignCategory($categoryId: ID, $fbCampaignIds: [String]) {
//     updateCampaignCategory(categoryId: $categoryId, fbCampaignIds: $fbCampaignIds)
//   }
// `;

export const UPDATE_CAMPAIGN_CATEGORY = gql`
  mutation updateCampaignCategory($campaignIds: [ID]!, $categoryId: ID!) {
    updateCampaignCategory(campaignIds: $campaignIds, categoryId: $categoryId)
  }
`;

/**
 * ? CampaignCategory
 * * adAccountName: String
 * * campaignId: String
 * * category: String
 * * id: ID
 * * name: String
 */

const CAMPAIGN_CATEGORY_RESPONSE = gql`
  fragment CampaignCategory on CampaignCategory {
    adAccountName
    campaignId
    id
    name
  }
`;

export const GET_CAMPAIGN_CATEGORY = gql`
  ${CAMPAIGN_CATEGORY_RESPONSE}
  query campaignsAndCategory {
    campaignsAndCategory {
      ...CampaignCategory
    }
  }
`;

export const LANDING_URLS = gql`
  query LandingUrls {
    LandingUrls {
      id
      url
      category {
        id
        name
      }
      adAccount {
        fbAdAccountId
        name
      }
    }
  }
`;

export const ASSIGN_CATEGORY_TO_LANDING_URLS = gql`
  mutation assignCategoryToLandingUrls($categoryId: ID!, $landingUrlIds: [ID]!) {
    assignCategoryToLandingUrls(categoryId: $categoryId, landingUrlIds: $landingUrlIds)
  }
`;

export const IS_CATEGORY_ASSIGNED = gql`
  query isCategoryAssigned {
    isCategoryAssigned
  }
`;

export const ASSIGN_VENDOR_TO_ACCOUNT = gql`
  mutation assignVendorToAccount($accountIds: [ID]!, $vendorId: ID!, $productId: ID!) {
    assignVendorToAccount(accountIds: $accountIds, vendorId: $vendorId, productId: $productId)
  }
`;

// assetId:ID
// success:Boolean
// message:String
// status:FBAssetStatus

const ASSET_UPDATE_RESPONSE = gql`
  fragment AssetUpdateResponse on AssetUpdateResponse {
    assetId
    success
    message
    status
  }
`;

export const UPDATE_FB_ASSEST_STATUS = gql`
  ${ASSET_UPDATE_RESPONSE}
  mutation updateFbAssetStatus(
    $status: FBAssetStatus!
    $fbIds: [String]!
    $node: AssetStatusNode!
  ) {
    updateFbAssetStatus(status: $status, fbIds: $fbIds, node: $node) {
      ...AssetUpdateResponse
    }
  }
`;

export const UPDATE_FB_ASSEST_BUDGET = gql`
  ${ASSET_UPDATE_RESPONSE}
  mutation updateFbAssetBudget(
    $budget: Int!
    $fbIds: [String]!
    $isLifeTime: Boolean!
    $node: AssetStatusNode!
  ) {
    updateFbAssetBudget(budget: $budget, fbIds: $fbIds, isLifeTime: $isLifeTime, node: $node) {
      ...AssetUpdateResponse
    }
  }
`;

export const F360_AD_HOURLY_REPORTS = gql`
  query ReportPerAdIdHourly(
    $adId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdIdHourly(adId: $adId, from: $from, timezone: $timezone, to: $to, userId: $userId) {
      adId
      clicksFB
      date
      impressionsFB
      leadsFB
      name
      objective
      spendFB
    }
  }
`;

export const GET_REPORT_PER_AD_ACCOUNT_HOURLY = gql`
  query ReportPerAdAccountHourly(
    $adAccountId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdAccountHourly(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      adAccountId
      clicksFB
      date
      impressionsFB
      leadsFB
      objective
      spendFB
    }
  }
`;

export const UPDATE_ALIAS_ADACCOUNT = gql`
  mutation updateAdAccountAlias($adAccountId: ID!, $alias: String!) {
    updateAdAccountAlias(adAccountId: $adAccountId, alias: $alias)
  }
`;

export const ADACCOUNT_BY_BM = gql`
  query adAccountSpendByMonth($month: Int!, $year: Int!, $bmId: ID, $userId: ID) {
    adAccountSpendByMonth(month: $month, year: $year, bmId: $bmId, userId: $userId) {
      spendFB
      adAccount {
        fbAdAccountId
        name
      }
    }
  }
`;
