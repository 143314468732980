import * as types from "../actions/actionTypes";

export default function vsReducer(
  state = { selectedVSAdvertiser: "", selectedVSAdvertiserIsAll: true, vsIsForecast: true },
  action
) {
  switch (action.type) {
    case types.VS_ADVERTISER_FORCE_SET:
      return { ...state, selectedVSAdvertiser: action.payload };

    case types.VS_ADVERTISER_SELECTION:
      return { ...state, selectedVSAdvertiser: action.payload, selectedVSAdvertiserIsAll: false };

    case types.VS_ADVERTISER_SELECTION_WITH_ALL:
      return { ...state, selectedVSAdvertiserIsAll: true, selectedVSAdvertiser: "" };

    case types.VS_IS_FORECAST_SET:
      return { ...state, vsIsForecast: true };
    case types.VS_IS_FORECAST_UNSET:
      return { ...state, vsIsForecast: false };

    default:
      return state;
  }
}
