import { gql } from "@apollo/client";

export const G360_GET_GOOGLE_AUTH_URL = gql`
  query g_getUrl {
    g_getUrl
  }
`;

export const G360_CONNENCT_GOOGLE_ACCOUNT_BY_CODE = gql`
  mutation g_connectAndSyncAccount($code: String!) {
    g_connectAndSyncAccount(code: $code)
  }
`;
export const G360_GET_GOOGLE_USER_PROFILE = gql`
  query G_userProfileGetAll {
    g_userProfileGetAll {
      email
      id
      name
      profile_id
    }
  }
`;

export const G360_UNLINK_GOOGLE_PROFILE = gql`
  mutation G_profileUnlink($profile_id: [ID!]!) {
    g_profileUnlink(profile_id: $profile_id)
  }
`;

export const G360_GET_GOOGLE_ACCOUNT = gql`
  query Query {
    g_accountUser
  }
`;

export const G360_GET_GOOGLE_CUSTOMER = gql`
  query Query {
    g_customerUser
  }
`;

export const G360_CUSTOMER_SYNC = gql`
  mutation G_customerSyncSwitch($customer_id: [ID], $is_sync_on: Boolean) {
    g_customerSyncSwitch(customer_id: $customer_id, is_sync_on: $is_sync_on)
  }
`;

export const G360_GET_GOOGLE_CAMPAIGNS = gql`
  query G_campaignUser {
    g_campaignUser {
      name
      status
      serving_status
      optimisation_score
      bidding_strategy_type
      budget
      customer_name
      campaign_id
      start_date
      end_date
      last_sync
    }
  }
`;

export const G360_GET_GOOGLE_ADGROUP = gql`
  query G_adgroupUser {
    g_adgroupUser {
      name
      campaign_name
      customer_name
      adgroup_id
      status
      type
      default_max_cpc
      last_sync
    }
  }
`;

export const G360_GET_USER_REPORT = gql`
  query g_userReporting($from: Date, $to: Date, $timezone: String, $is_detailed: Boolean) {
    g_userReporting(from: $from, to: $to, timezone: $timezone, is_detailed: $is_detailed) {
      clicks
      cost
      impressions
      userId: user_id
      user {
        id
        name
      }
    }
  }
`;

export const G350_GET_ADGROUP_BYCUSTOMER = gql`
  query g_adgroupByCustomer($customer_id: ID!) {
    g_adgroupByCustomer(customer_id: $customer_id)
  }
`;
export const G350_GET_AD_BYCUSTOMER = gql`
  query g_adByCustomer($customer_id: ID!) {
    g_adByCustomer(customer_id: $customer_id)
  }
`;
export const G350_GET_AD_BYCAMPAIGN = gql`
  query g_adByCampaign($campaign_id: ID!) {
    g_adByCampaign(campaign_id: $campaign_id)
  }
`;
export const G350_GET_AD_BYADGROUP = gql`
  query g_adByAdgroup($adgroup_id: ID!) {
    g_adByAdgroup(adgroup_id: $adgroup_id)
  }
`;
export const G350_GET_ADGROUP_BYCAMPAIGN = gql`
  query g_adgroupByCampaign($campaign_id: ID!) {
    g_adgroupByCampaign(campaign_id: $campaign_id)
  }
`;
export const G350_GET_CAMPAIGN_BYCUSTOMER = gql`
  query g_campaignByCustomer($customer_id: ID!) {
    g_campaignByCustomer(customer_id: $customer_id)
  }
`;

export const G360_GET_GOOGLE_ADS = gql`
  query G_adsUser {
    g_adsUser {
      urls
      customer_name
      campaign_name
      adgroup_name
      status
      last_sync
      strength
      type
      headlines
      descriptions
    }
  }
`;

export const G360_GET_ADGROUP_REPORTING = gql`
  query g_adgroupReporting(
    $asset: G_ASSET
    $assetId: [ID]
    $from: Date
    $timezone: String
    $to: Date
  ) {
    g_adgroupReporting(asset: $asset, asset_id: $assetId, from: $from, timezone: $timezone, to: $to)
  }
`;

export const G360_GET_CAMPAIGN_REPORTING = gql`
  query g_campaignReporting(
    $from: Date
    $to: Date
    $timezone: String
    $asset: G_ASSET
    $assetId: [ID]
  ) {
    g_campaignReporting(
      from: $from
      to: $to
      timezone: $timezone
      asset: $asset
      asset_id: $assetId
    )
  }
`;

export const G360_GET_CUSTOMER_REPORTING = gql`
  query g_customerReporting($from: Date, $to: Date, $timezone: String) {
    g_customerReporting(from: $from, to: $to, timezone: $timezone)
  }
`;

export const G360_BULK_STATUS_UPDATE = gql`
  mutation g_bulkStatusUpdate($asset: G_ASSET, $status: G_STATUS, $updateArray: [G_Update]) {
    g_bulkStatusUpdate(asset: $asset, status: $status, updateArray: $updateArray)
  }
`;

export const G360_BULK_KEYWORD_STATUS_UPDATE = gql`
  mutation g_bulkKeywordStatusUpdate($asset: G_ASSET, $status: G_STATUS, $updateArray: [G_Update]) {
    g_bulkKeywordStatusUpdate(asset: $asset, status: $status, updateArray: $updateArray)
  }
`;

export const G360_HOURLY_REPORTING = gql`
  query g_hourlyReporting(
    $from: Date
    $to: Date
    $timezone: String
    $asset_id: [ID]
    $asset: G_ASSET
  ) {
    g_hourlyReporting(from: $from, to: $to, timezone: $timezone, asset_id: $asset_id, asset: $asset)
  }
`;

export const G360_DAILY_REPORTING = gql`
  query g_dailyReporting(
    $from: Date
    $to: Date
    $timezone: String
    $asset_id: [ID]
    $asset: G_ASSET
  ) {
    g_dailyReporting(from: $from, to: $to, timezone: $timezone, asset_id: $asset_id, asset: $asset)
  }
`;

export const G360_HOUROFDAY_REPORTING = gql`
  query g_hourOfDayReporting(
    $from: Date
    $to: Date
    $timezone: String
    $asset_id: [ID]
    $asset: G_ASSET
  ) {
    g_hourOfDayReporting(
      from: $from
      to: $to
      timezone: $timezone
      asset_id: $asset_id
      asset: $asset
    )
  }
`;

export const G360_CUSTOMER_SET_OCID = gql`
  mutation g_customerSetOcid($customer_id: ID!, $ocid: ID!) {
    g_customerSetOcid(customer_id: $customer_id, ocid: $ocid)
  }
`;

export const G360_BULK_NAME_UPDATE = gql`
  mutation g_bulkNameUpdate($updateArray: [G_Update], $asset: G_ASSET, $name: String) {
    g_bulkNameUpdate(updateArray: $updateArray, asset: $asset, name: $name)
  }
`;

export const G360_BULK_TARGET_CPA_UPDATE = gql`
  mutation g_bulkTargetCpaUpdate(
    $asset: G_ASSET
    $updateArray: [G_Update]
    $value: Float
    $type: G_ACTION_TYPES
  ) {
    g_bulkTargetCpaUpdate(asset: $asset, updateArray: $updateArray, value: $value, type: $type)
  }
`;

export const G360_BULK_TARGET_ROAS_UPDATE = gql`
  mutation g_bulkTargetRoasUpdate(
    $asset: G_ASSET
    $updateArray: [G_Update]
    $value: Float
    $type: G_ACTION_TYPES
  ) {
    g_bulkTargetRoasUpdate(asset: $asset, updateArray: $updateArray, value: $value, type: $type)
  }
`;

export const G360_BULK_MAX_CONVERSION = gql`
  mutation g_bulkMaximumConversion($asset: G_ASSET, $updateArray: [G_Update], $value: Float) {
    g_bulkMaximumConversion(asset: $asset, updateArray: $updateArray, value: $value)
  }
`;

export const G360_BULK_BID_TARGET_ROAS_UPDATE = gql`
  mutation g_bulkBidTargetRoasUpdate($asset: G_ASSET, $updateArray: [G_Update], $value: Float) {
    g_bulkBidTargetRoasUpdate(asset: $asset, updateArray: $updateArray, value: $value)
  }
`;

export const G360_BULK_BUDGET_UPDATE = gql`
  mutation g_bulkBudgetUpdate($budgetType: G_BUDGET_TYPE, $updateArray: [G_Update], $value: Float) {
    g_bulkBudgetUpdate(budgetType: $budgetType, updateArray: $updateArray, value: $value)
  }
`;

export const G360_GET_ALL_PROFILE = gql`
  query g_profileGetAll {
    g_profileGetAll {
      id
      name
    }
  }
`;

export const G360_GET_ALL_USER_PROFILE = gql`
  query G_userProfileGetAll {
    g_userProfileGetAll {
      id
      name
    }
  }
`;

export const G360_GET_ADACCOUNT = gql`
  query g_customerUser($profile_id: ID) {
    g_customerUser(profile_id: $profile_id)
  }
`;

export const G360_AD_REPORTING = gql`
  query g_adReporting($asset: G_ASSET, $assetId: [ID], $from: Date, $timezone: String, $to: Date) {
    g_adReporting(asset: $asset, asset_id: $assetId, from: $from, timezone: $timezone, to: $to)
  }
`;

export const G360_KEYWORD_REPORT = gql`
  query g_keywordViewReporting(
    $asset: G_ASSET
    $assetId: [ID]
    $from: Date
    $timezone: String
    $to: Date
  ) {
    g_keywordViewReporting(
      asset: $asset
      asset_id: $assetId
      from: $from
      timezone: $timezone
      to: $to
    )
  }
`;

export const G360_BIDDING_STRATEGY_REPORTING = gql`
  query g_biddingStrategyReporting(
    $asset: G_ASSET
    $assetId: [ID]
    $from: Date
    $timezone: String
    $to: Date
  ) {
    g_biddingStrategyReporting(
      asset: $asset
      asset_id: $assetId
      from: $from
      timezone: $timezone
      to: $to
    )
  }
`;

export const G360_SEARCH_TERM_VIEW_REPORTING = gql`
  query g_searchTermViewReporting(
    $asset: G_ASSET
    $assetId: [ID]
    $timezone: String
    $from: Date
    $to: Date
  ) {
    g_searchTermViewReporting(
      asset: $asset
      asset_id: $assetId
      timezone: $timezone
      from: $from
      to: $to
    )
  }
`;

export const G360_DYNAMIC_SEARCH_TERM_VIEW_REPORTING = gql`
  query g_dynamicAdsSearchTermViewReporting(
    $asset: G_ASSET
    $from: Date
    $assetId: [ID]
    $timezone: String
    $to: Date
  ) {
    g_dynamicAdsSearchTermViewReporting(
      asset: $asset
      from: $from
      asset_id: $assetId
      timezone: $timezone
      to: $to
    )
  }
`;
