//CMS
export const API_STORE_CMS_CATEGORY = "API_STORE_CMS_CATEGORY";
export const API_STORE_CMS_COUNTRIES = "API_STORE_CMS_COUNTRIES";
export const API_STORE_CMS_PRODUCTS = "API_STORE_CMS_PRODUCTS";
export const API_STORE_CMS_STORES = "API_STORE_CMS_STORES";
export const API_STORE_CMS_DOMAINS = "API_STORE_CMS_DOMAINS";
export const API_STORE_CMS_IMAGES = "API_STORE_CMS_IMAGES";
export const API_STORE_CMS_LIST_TYPE_BLOCK = "API_STORE_CMS_LIST_TYPE_BLOCK";
export const API_STORE_CMS_LIST_TYPE_PRODUCT = "API_STORE_CMS_LIST_TYPE_PRODUCT";

//UMS
export const API_STORE_UMS_PRODUCTS = "API_STORE_UMS_PRODUCTS";
export const API_STORE_UMS_SERVICES = "API_STORE_UMS_SERVICES";
export const API_STORE_UMS_USERS = "API_STORE_UMS_USERS";
export const API_STORE_UMS_USERS_BY_AGENCY = "API_STORE_UMS_USERS_BY_AGENCY";

//SAS
export const API_STORE_SAS_PLATFORM_BY_USER = "API_STORE_SAS_PLATFORM_BY_USER";
export const API_STORE_SAS_PLATFORM = "API_STORE_SAS_PLATFORM";
export const API_STORE_SAS_FEEDS = "API_STORE_SAS_FEEDS";
export const API_STORE_SAS_FEED_DATA = "API_STORE_SAS_FEED_DATA";
export const API_STORE_SAS_LANGUAGES = "API_STORE_SAS_LANGUAGES";
export const API_STORE_SAS_COUNTRIES = "API_STORE_SAS_COUNTRIES";
export const API_STORE_SAS_LOOK_CATEGORIES = "API_STORE_SAS_LOOK_CATEGORIES";
export const API_STORE_SAS_AGENCY_USERS = "API_STORE_SAS_AGENCY_USERS";
export const API_STORE_SAS_CATEGORIES = "API_STORE_SAS_CATEGORIES";
export const API_STORE_SAS_PIXELS = "API_STORE_SAS_PIXELS";
export const API_STORE_SAS_VERTICALS = "API_STORE_SAS_VERTICALS";
export const API_STORE_SAS_VENDOR_DROPDOWN = "API_STORE_SAS_VENDOR_DROPDOWN";
export const API_STORE_SAS_VENDOR_DATA = "API_STORE_SAS_VENDOR_DATA";
export const API_STORE_SAS_USER = "API_STORE_SAS_USER";
export const API_STORE_SAS_USER_WITH_PLATFORM = "API_STORE_SAS_USER_WITH_PLATFORM";

//F360
export const API_STORE_F360_USER_AD_ACCOUNTS = "API_STORE_F360_USER_AD_ACCOUNTS";
export const API_STORE_F360_LAUNCH_CONFIG = "API_STORE_F360_LAUNCH_CONFIG";
export const API_STORE_F360_TARGETING_COUNTRIES = "API_STORE_F360_TARGETING_COUNTRIES";
export const API_STORE_F360_CONFIG = "API_STORE_F360_CONFIG";
export const API_STORE_F360_TRACKER_CATEGORIES = "API_STORE_F360_TRACKER_CATEGORIES";
export const API_STORE_F360_IS_CATEGORY_ASSIGNED = "API_STORE_F360_IS_CATEGORY_ASSIGNED";

//FB360
export const API_STORE_FB360_CATEGORIES = "API_STORE_F360_CATEGORIES";

//Launch
export const API_STORE_TARGETING_LOCALES = "API_STORE_TARGETING_LOCALES";
export const API_STORE_LAUNCH_TEMPLATE = "API_STORE_LAUNCH_TEMPLATE";
export const API_STORE_BUCKETS = "API_STORE_BUCKETS";

//Media
export const API_STORE_FILE_CATEGORIES = "API_STORE_FILE_CATEGORIES";

//iTask
export const API_STORE_I_TASK_SERVICE_PRIORITY = "API_STORE_I_TASK_SERVICE_PRIORITY";
export const API_STORE_I_TASK_SERVICE_STATUS = "API_STORE_I_TASK_SERVICE_STATUS";
export const API_STORE_I_TASK_SERVICE_STATUS_ROCK = "API_STORE_I_TASK_SERVICE_STATUS_ROCK";

//Ad Library
export const API_STORE_ADLIB_DOMAIN_DROPDOWN = "API_STORE_ADLIB_DOMAIN_DROPDOWN";
export const API_STORE_ADLIB_PAGE_DROPDOWN = "API_STORE_ADLIB_PAGE_DROPDOWN";
export const API_STORE_ADLIB_COUNTRIES = "API_STORE_ADLIB_COUNTRIES";

//ViralSparks
export const API_STORE_VIRALSPARKS_AGENCY = "API_STORE_VIRALSPARKS_AGENCY";
export const API_STORE_VIRALSPARKS_BRAND = "API_STORE_VIRALSPARKS_BRAND";
export const API_STORE_VIRALSPARKS_LANGUAGE = "API_STORE_VIRALSPARKS_LANGUAGE";
export const API_STORE_VIRALSPARKS_USER = "API_STORE_VIRALSPARKS_USER";
export const API_STORE_VIRALSPARKS_URL_BUILDER_SETTINGS_DATA =
  "API_STORE_VIRALSPARKS_URL_BUILDER_SETTINGS_DATA";
export const API_STORE_VIRALSPARKS_PLATFORM = "API_STORE_VIRALSPARKS_PLATFORM";
export const API_STORE_VIRALSPARKS_ADVERTISE = "API_STORE_VIRALSPARKS_ADVERTISE";
export const API_STORE_VIRALSPARKS_ALL_USERS = "API_STORE_VIRALSPARKS_ALL_USERS";

//Slack
export const API_STORE_SLACK_WORKSPACES = "API_STORE_SLACK_WORKSPACES";
