import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "classnames";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { VS_REVENUE_CRON_STATUS } from "shared/gql/gqlSchema/viralSparksGql";
import moment from "moment";
// import { usePermissionCheck } from "hooks";
// import { VS_VIEW_ALL_REPORTING } from "shared/UserPermission/userPermissionList";

const valueCheck = (value, checkTotal = false) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(3);
  return valueFloat;
};
//

export default function useColumns() {
  // const { havePermission: isAdmin } = usePermissionCheck(VS_VIEW_ALL_REPORTING);
  const isAdmin = false;

  const { data: data_VS_REVENUE_CRON_STATUS } = useCustomQuery(VS_REVENUE_CRON_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: { id: 1 },
  });
  const status = (
    <div className="d-inline-flex" style={{ fontSize: 12 }}>
      <p className="mb-0">
        Revenue Synced&nbsp;
        {data_VS_REVENUE_CRON_STATUS?.cron_status_time}
        {moment
          .utc(data_VS_REVENUE_CRON_STATUS?.vs_cronStatus?.cron_status_time)
          .from(moment().tz("UTC"))}
      </p>
    </div>
  );
  const adminRevenue = isAdmin
    ? [
        {
          title: "Agency Revenue",
          dataIndex: "TotalRevenue",
          key: "TotalRevenue",
          render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
          width: 130,
          minWidth: 130,
          sorter: (a, b) => a?.TotalRevenue - b?.TotalRevenue,
          inChart: true,
          // shouldCellUpdate: () => false,
        },
      ]
    : [];

  const columns = [
    ...adminRevenue,
    {
      title: "Revenue",
      dataIndex: "TotalRevenue",
      key: "TotalRevenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.TotalRevenue - b?.TotalRevenue,
      inChart: true,
      searchType: "number",
      // shouldCellUpdate: () => false,
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (value) => <LookTableColumnRender title={<>${valueCheck(value, true)}</>} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.spend - b?.spend,
      inChart: true,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value, otherProps) => {
        const profit = `$${value.toFixed(3)}`;

        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      inChart: true,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "ROI",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.ROI - b?.ROI,
      inChart: true,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.EPC - b?.EPC,
      inChart: true,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPC - b?.CPC,
      inChart: true,
      searchType: "number",
      // shouldCellUpdate: () => false,
    },
    // {
    //   title: "CPC(FB)",
    //   dataIndex: "CPC_FB",
    //   key: "CPC_FB",
    //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    //   width: 130,
    //   minWidth: 130,
    //   sorter: (a, b) => a?.CPC_FB - b?.CPC_FB,
    //   searchType: "number",
    //   // shouldCellUpdate: () => false,
    // },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CTR - b?.CTR,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPR - b?.CPR,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "Disc",
      dataIndex: "DISC",
      key: "DISC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.DISC - b?.DISC,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "PPS",
      dataIndex: "PPS",
      key: "PPS",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PPS - b?.PPS,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "IPP",
      dataIndex: "IPP",
      key: "IPP",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.IPP - b?.IPP,
      searchType: "number",
    },
    {
      title: "PRPM",
      dataIndex: "PRPM",
      key: "PRPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PRPM - b?.PRPM,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",

      // shouldCellUpdate: () => false,
    },
  ];
  return { columns, status };
}
