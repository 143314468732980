import { USER_SETTING_THEME_LIGHT } from "actions/actionTypes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_THEME_LIGHT } from "reducers/userSettings";
import ThemeContext from "./context";

export const HEADER_HEIGHT = 50;
export const HEADER_CREATE_BTN_HEIGHT = 32;
export const TABLE_FILTER_HEIGHT = 50;
export const TABLE_HEADER_HEIGHTT = 40;
export const TABLE_SUMMARY_HEIGHTT = 40;
export const PAGINATION_WRAPPER_HEIGHT = 55;

function Theme({ children }) {
  const dispatch = useDispatch();
  const { isThemeLight = DEFAULT_THEME_LIGHT } = useSelector((state) => state?.userSettings) || {};
  // const [isThemeLight, setIsThemeLight] = useState(false);
  const BODY_CLASS_LIST = document.body.classList;

  function toggleTheme() {
    dispatch({ type: USER_SETTING_THEME_LIGHT, payload: !isThemeLight });
    // localStorage.setItem("isThemeLight", !isThemeLight);
    // setIsThemeLight(!isThemeLight);
    isThemeLight ? BODY_CLASS_LIST.add("theme-dark") : BODY_CLASS_LIST.remove("theme-dark");
    BODY_CLASS_LIST.add("transitionOff");
    setTimeout(() => BODY_CLASS_LIST.remove("transitionOff"), 200);
  }

  useEffect(() => {
    if (!isThemeLight) {
      BODY_CLASS_LIST.add("theme-dark");
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ isThemeLight, toggleTheme }}>{children}</ThemeContext.Provider>
  );
}

export default Theme;
