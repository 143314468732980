import { Button, Card, Checkbox, Image, Tooltip } from "antd";
import DebugPre from "components/DebugPre";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cx from "cx";
import { useState } from "react";
import { LAUNCH_CALL_TO_ACTION_ENUM } from "shared/enum/launchKeysAndEnum.js";
import ActionIconRender from "components/ActionIconRender";
import { GROUP_TYPE_STATIC } from "shared/SharedKeys/launchKeys";
import nofile from "assets/images/placeholder-images/nofile.png";
import PopoverWrapper from "components/popoverWrapper";
import { MoreOutlined } from "@ant-design/icons";
const settings = {
  dots: false,
  infinite: false,
  centerMode: true,
  centerPadding: "60px",
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
};
export default function CardCreativeAdGroup(props) {
  const {
    className = "",
    isDynamic = false,
    selected = false,
    selectedCallback = () => {},
    counter,
    isCta,
    image,
    video,
    ctaType = {},
    pageId,
    adDomain = {},
    adPage = {},
    body: bodyObject,
    title: titleObject,
    description: descriptionObject,
    creativeReports = false,
    spend,
    onlyContent = false, // only use for creative tacking
    isGroup = false,
    groupType,
    multiImages = [],
    multiVideos = [],
    hoverContent = () => {},
    ctaLink = "",
    poverWrapperOptions,
    isGobalReport = false,
    isMultiCreatives = false,
    isDetailedView = false,
    isIndustryInsights = false,
  } = props;
  const { title = "" } = titleObject || {};
  const { body = "" } = bodyObject || {};
  const { description = "" } = descriptionObject || {};
  const [playModal, setPlayModal] = useState(false);
  const titleContent = () => (
    <div className="d-flex">
      <p className="title-text flex-grow-1 mb-0">
        <Tooltip title={title}>{title}</Tooltip>
      </p>
    </div>
  );
  const bodyContent = () => (
    <div className="body-content position-relative">
      <p className="body-text">
        <Tooltip title={body}>{body}</Tooltip>
      </p>
      {isCta && (
        <div className="position-relative">
          <Button size="small" style={{ fontSize: 12 }}>
            {LAUNCH_CALL_TO_ACTION_ENUM?.filter((d) => d?.value === isCta)[0]?.name}
          </Button>
          <span
            className="position-absolute w-100 h-100 d-block"
            style={{ zIndex: 2, top: 0, left: 0 }}
          />
        </div>
      )}
    </div>
  );
  const imageContent = (image) => (
    <>
      {/* {selection && selection} */}
      <div className="image-wrapper image-hover-action-wrapper" onClick={selectedCallback}>
        {isGroup && <div className="hover-content  z-index-2 overlay">{hoverContent()}</div>}
        {image?.s3Url && (
          <>
            {/* Type: Image */}
            <Image src={image?.s3Url} preview={false} />
          </>
        )}
        {video?.s3Url && (
          <>
            {playModal && (
              <>
                <LookModal
                  title="Play Video"
                  visible={playModal}
                  onCancel={() => {
                    setPlayModal(false);
                  }}
                  footer={false}
                >
                  {playModal && <video className="w-100" src={video?.s3Url} controls autoPlay />}
                </LookModal>
              </>
            )}
            {(!onlyContent || isIndustryInsights) && (
              <div className="creative-card-video">
                <LookButton
                  className="mr-8"
                  tooltipTitle="Play video"
                  iconType="play"
                  type="primary"
                  onClick={() => {
                    if (isDetailedView) {
                      setPlayModal(true);
                    } else if (!isIndustryInsights) {
                      setPlayModal(true);
                    }
                  }}
                ></LookButton>
              </div>
            )}
            <Image src={video?.thumbUrl} preview={false} />
          </>
        )}
        <div className="empty" />
      </div>
    </>
  );
  const renderMultiImageContent = () => (
    <Slider {...settings}>
      {multiImages?.map((image) => {
        return imageContent(image);
      })}
    </Slider>
  );
  const debugContent = [{ isRow: true, contentObjects: [{}] }];
  if (onlyContent) {
    return (
      <div className={cx("creative-adgroup-card", { "pd-10": isGroup })}>
        {isGroup && (
          <>
            <div className="image-content-details">
              {!isDynamic && (
                <div className="d-flex justify-content-between  w-100">
                  <div className="d-flex flex-grow-1">
                    {!isDetailedView && (
                      <div className="mr-15">
                        <Checkbox />
                      </div>
                    )}
                    <div className="d-flex flex-grow-1 ">
                      <div>
                        <Image
                          src={adPage?.pageProfilePictureUrl}
                          preview={false}
                          style={{ width: 40, height: 40, borderRadius: 6 }}
                        />
                      </div>
                      <div className="flex-grow-1 ml-10">
                        <h3 className="mb-0">{isIndustryInsights ? adPage?.name : "Brand Name"}</h3>
                        <div> {groupType || GROUP_TYPE_STATIC} </div>
                      </div>
                    </div>
                  </div>
                  {!(isGobalReport || isDetailedView) && (
                    <div className="ml-5">
                      <PopoverWrapper options={poverWrapperOptions}>
                        <MoreOutlined className="pop-info" />
                      </PopoverWrapper>
                    </div>
                  )}
                </div>
              )}
              {bodyContent()}
            </div>
          </>
        )}

        <div>
          {isMultiCreatives ? renderMultiImageContent() : imageContent(image)}
          {!isGroup && <div className="hover-content  z-index-2 overlay">{hoverContent()}</div>}
        </div>
        {isGroup && (
          //added by Nishtha temporarily
          <div className={cx({ "d-flex align-items-start": isIndustryInsights })}>
            <div className="" style={{ maxHeight: "60" }}>
              <div className="domain-name mb-0 pb-o" onClick={() => window.open(ctaLink, "_blank")}>
                {adDomain?.domain}
              </div>
              {titleContent()}
              <div className="description">
                <p className="title-text flex-grow-1 mb-0">
                  <Tooltip title={description}>{description}</Tooltip>
                </p>
              </div>
            </div>
            {isIndustryInsights && (
              <LookButton className="mt-10" onClick={() => window.open(ctaLink, "_blank")}>
                {ctaType?.value?.split("_")?.join(" ")}
              </LookButton>
            )}
          </div>
        )}
      </div>
    );
  }
  return (
    <Card
      className={cx(
        "creative-adgroup-card theme-parent p-ch-0",
        { "primary-border": selected },
        className
      )}
    >
      <div>
        <div className="pt-15 px-15 py-4">
          <div className="d-flex justify-content-between">
            <div className="mr-12">
              <div className="temp-circle" />
            </div>
            {
              <div className={cx("creativeCards", { creativeReports })}>
                {creativeReports ? (
                  <>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      tooltip={`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${pageId}&sort_data[direction]=desc&sort_data[mode]=relevancy_monthly_grouped&search_type=page&media_type=all`}
                      href={`https://www.facebook.com/ads/library/?active_status=all&ad_type=all&country=ALL&view_all_page_id=${pageId}&sort_data[direction]=desc&sort_data[mode]=relevancy_monthly_grouped&search_type=page&media_type=all`}
                    >
                      <ActionIconRender className="mr-10" iconType="global" /> {pageId}
                    </a>
                  </>
                ) : (
                  <h2 className="title-head">Facebook page...</h2>
                )}
                <p className="mb-4">Sponsored</p>
              </div>
            }
            {}
            <div className="d-flex" style={{ gap: "10px" }}>
              <Checkbox checked={selected} onClick={selectedCallback} />
              <PopoverWrapper options={poverWrapperOptions}>
                <MoreOutlined className="pop-info" />
              </PopoverWrapper>
            </div>
          </div>
          {bodyContent()}
        </div>
        <DebugPre content={debugContent} />
        {imageContent(image)}
        <div className="px-15">{titleContent()}</div>
        {!creativeReports && (
          <div className="d-flex py-15 px-15">
            <p className="title-text flex-grow-1 mb-0">
              <Tooltip title={description}>{description}</Tooltip>
            </p>
            {counter}
          </div>
        )}
        {creativeReports && (
          <div className="d-flex p-10">
            <span className="mr-10">Spend</span>
            <span>{spend}</span>
          </div>
        )}
      </div>
    </Card>
  );
}
