import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { useState } from "react";
import {
  GET_TIKTOK_ACCOUNT_REPORT,
  GET_TIKTOK_AD_REPORT,
  GET_TIKTOK_ADGROUP_REPORT,
  GET_TIKTOK_CAMPAIGN_REPORT,
  GET_TIKTOK_ADGROUP_HOURLY_REPORT,
  GET_TIKTOK_PERFORMANCE_REPORT,
} from "shared/gql/gqlSchema/tiktok";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_AD_ADSET_PERFORMANCE,
  REPORT_TYPE_AD_PERFORMANCE,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_CAMPAIGN_PERFORMANCE,
} from "shared/SharedKeys";

function apiQueryAndElement(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return { query: GET_TIKTOK_ACCOUNT_REPORT, element: "tiktok_getAccountReport" };
    case REPORT_TYPE_AD:
      return { query: GET_TIKTOK_AD_REPORT, element: "tiktok_getAdReport" };
    case REPORT_TYPE_AD_ADSET:
      return { query: GET_TIKTOK_ADGROUP_REPORT, element: "tiktok_getAdgroupReport" };
    case REPORT_TYPE_CAMPAIGN:
      return { query: GET_TIKTOK_CAMPAIGN_REPORT, element: "tiktok_getCampaignReport" };
    // case REPORT_TYPE_AD_PERFORMANCE:
    //   return {
    //     query: GET_TIKTOK_ADGROUP_HOURLY_REPORT,
    //     element: "tiktok_getHourlyReport",
    //     hourlyType: "ADGROUP",
    //   };
    // case REPORT_TYPE_AD_ADSET_PERFORMANCE:
    //   return {
    //     query: GET_TIKTOK_ADGROUP_HOURLY_REPORT,
    //     element: "tiktok_getHourlyReport",
    //     hourlyType: "AD",
    //   };
    // case REPORT_TYPE_CAMPAIGN_PERFORMANCE:
    //   return {
    //     query: GET_TIKTOK_ADGROUP_HOURLY_REPORT,
    //     element: "tiktok_getHourlyReport",
    //     hourlyType: "CAMPAIGN",
    //   };

    default: {
      return {};
    }
  }
}

function hourlyType(type) {
  switch (type) {
    case REPORT_TYPE_AD:
      return { hourlyType: "AD" };
    case REPORT_TYPE_AD_ADSET:
      return { hourlyType: "ADGROUP" };
    case REPORT_TYPE_CAMPAIGN:
      return { hourlyType: "CAMPAIGN" };

    default: {
      return {};
    }
  }
}

function reportType(type) {
  switch (type) {
    case REPORT_TYPE_AD:
      return { reportType: "AD" };
    case REPORT_TYPE_AD_ADSET:
      return { reportType: "ADGROUP" };
    case REPORT_TYPE_CAMPAIGN:
      return { reportType: "CAMPAIGN" };

    default: {
      return {};
    }
  }
}

function hourlyQueryType(type) {
  switch (type) {
    case REPORT_TYPE_AD:
      return {
        query: GET_TIKTOK_ADGROUP_HOURLY_REPORT,
        element: "tiktok_getHourlyReport",
        hourlyType: "ADGROUP",
      };
    case REPORT_TYPE_AD_ADSET:
      return {
        query: GET_TIKTOK_ADGROUP_HOURLY_REPORT,
        element: "tiktok_getHourlyReport",
        hourlyType: "AD",
      };
    case REPORT_TYPE_CAMPAIGN:
      return {
        query: GET_TIKTOK_ADGROUP_HOURLY_REPORT,
        element: "tiktok_getHourlyReport",
        hourlyType: "CAMPAIGN",
      };

    default: {
      return {};
    }
  }
}

export default function useTiktokSpendReportsQuery(type, extraParams = {}) {
  const {
    variables,
    from,
    to,
    timezone,
    isPerformanceDaily = false,
    isPerformanceHourly = false,
  } = extraParams;

  const [spendReport, setSpendReport] = useState([]);

  let queryObject = apiQueryAndElement(type);

  if (isPerformanceDaily) {
    queryObject = {
      query: GET_TIKTOK_PERFORMANCE_REPORT,
      element: "tiktok_getDailyPerformanceReport",
    };
  }

  if (isPerformanceHourly) {
    queryObject = hourlyQueryType(type);
  }

  const isPerformanceType = isPerformanceHourly
    ? { ...hourlyType(type) }
    : isPerformanceDaily
    ? { ...reportType(type) }
    : {};

  const [getSpendReport, { loading: loading_spend, refetch }] = useCustomLazyQuery(
    queryObject?.query,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[queryObject?.element];
        if (element) {
          setSpendReport(element?.map((d) => ({ ...d, date: d?.report_time })));
        }
      },

      variables: {
        start: from,
        timezone: timezone,
        end: to,
        ...isPerformanceType,
        ...variables,
      },
    }
  );

  const refetch_spend = () => {
    setSpendReport([]);
    refetch();
  };

  return { getSpendReport, loading_spend, refetch_spend, spendReport, setSpendReport };
}
