import { Collapse } from "antd";
import cx from "cx";

const { Panel } = Collapse;

export default function CollapseWraper({
  title = "",
  children,
  className = "",
  extra = "",
  isOpen,
  collapsible = "header",
  onChange = () => {},
}) {
  if (isOpen === true || isOpen === false) {
    return (
      <Collapse
        onChange={onChange}
        defaultActiveKey={["1"]}
        activeKey={isOpen ? ["1"] : []}
        className={cx(className, "mb-16")}
        collapsible={collapsible}
      >
        <Panel header={title} key="1" extra={extra}>
          {children}
        </Panel>
      </Collapse>
    );
  }
  return (
    <Collapse
      onChange={onChange}
      defaultActiveKey={["1"]}
      className={cx(className, "mb-16")}
      collapsible={collapsible}
    >
      <Panel header={title} key="1" extra={extra}>
        {children}
      </Panel>
    </Collapse>
  );
}
