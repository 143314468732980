import PerformanceReport from "./PerformanceReport";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import { useState } from "react";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import CategoryReport from "../../CategoryReport";
import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
} from "actions/actionTypes";
function DetailView(props) {
  const { visible = false, username = "", userId, onClose } = props;

  const [activeTab, setActiveTab] = useState("performance-report");

  const tabContent = [
    {
      id: "performance-report",
      hoverTitle: "Performance Report",
      icon: () => <ActionIconRender iconType="pic-left" />,
      component: (
        <PerformanceReport {...props} persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1} />
      ),
      noSpace: true,
    },
    {
      id: "category-report",
      hoverTitle: "Category Report",
      icon: () => <ActionIconRender iconType="pic-left" />,
      component: (
        <CategoryReport
          {...props}
          isPerformance
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
          userId={userId}
        />
      ),
      noSpace: true,
    },
  ];

  const currentActiveIndex = tabContent?.findIndex(({ id }) => id === activeTab);

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      textBreadcrumb={[username, tabContent[currentActiveIndex]?.hoverTitle]}
    >
      {visible && (
        <LookDrawerMainSideBar value={activeTab} onChange={setActiveTab} tabContent={tabContent} />
      )}
    </LookDrawer>
  );
}

export default DetailView;
