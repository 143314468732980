import { Checkbox, Tabs } from "antd";
import LookButton from "components/LookButton";
import { Image } from "antd";
import React, { useState } from "react";
import {
  CREATIVE_TYPE_BODY,
  CREATIVE_TYPE_DESCRIPTION,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_TITLE,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import AdgroupDetails from "./AdgroupDetails";
import nofile from "assets/images/placeholder-images/nofile.png";

function AdgroupDetailView(props) {
  const {
    creative_url_object = {},
    handleComplianceIcon = () => {},
    isComplianceModal = false,
    setSelectedCreatives = () => {},
    selectedCreatives = {},
    isFbReport = false,
    title = [],
    setVisibleChildCompliantModal = () => {},
    video_url = "",
    video_thumb_url = "",
  } = props;
  const { groups = [] } = creative_url_object || {};
  const {
    videos = [],
    images = [],
    titles = [],
    descriptions = [],
    bodies = [],
  } = groups?.[0] || [];
  const [activeTabs, setActiveTabs] = useState("1");
  const imageUrls = images?.[0]?.urls;
  const videoUrls = isFbReport
    ? videos?.[0]?.urls
    : [{ r2Url: video_url, thumbUrl: video_thumb_url }];
  const checkCompliance = (arr, selectedCompliance) => arr?.length === selectedCompliance?.length;

  const isSelectedGroup =
    (bodies?.length === 0 || checkCompliance(bodies, selectedCreatives?.bodyCompliance)) &&
    (titles?.length === 0 || checkCompliance(titles, selectedCreatives?.titleCompliance)) &&
    (imageUrls?.length === 0 || checkCompliance(imageUrls, selectedCreatives?.imageCompliance)) &&
    (videoUrls?.length === 0 || checkCompliance(videoUrls, selectedCreatives?.videoCompliance)) &&
    (descriptions?.length === 0 ||
      checkCompliance(descriptions, selectedCreatives?.descriptionCompliance));

  const handleSelectAll = () => {
    if (isSelectedGroup) {
      setSelectedCreatives({});
    } else {
      setSelectedCreatives({
        ...(bodies?.length > 0 && {
          bodyCompliance: bodies?.map((data) => ({
            creativeId: data?.id,
            actionType: CREATIVE_TYPE_BODY,
          })),
        }),
        ...(titles?.length > 0 && {
          titleCompliance: titles?.map((data) => ({
            creativeId: data?.id,
            actionType: CREATIVE_TYPE_TITLE,
          })),
        }),
        ...(imageUrls?.length > 0 && {
          imageCompliance: imageUrls?.map((data) => ({
            creativeId: data?.id,
            actionType: CREATIVE_TYPE_IMAGE,
          })),
        }),
        ...(videoUrls?.length > 0 && {
          videoCompliance: videoUrls?.map((data) => ({
            creativeId: data?.id,
            actionType: CREATIVE_TYPE_VIDEO,
          })),
        }),
        ...(descriptions?.length > 0 && {
          descriptionCompliance: descriptions?.map((data) => ({
            creativeId: data?.id,
            actionType: CREATIVE_TYPE_DESCRIPTION,
          })),
        }),
      });
    }
  };
  const tabContents = [
    {
      key: "1",
      label: "Creatives",
      children: activeTabs === "1" && (
        <AdgroupDetails
          imageUrls={imageUrls}
          videoUrls={videoUrls}
          isCreative
          handleComplianceIcon={handleComplianceIcon}
          isComplianceModal={isComplianceModal}
          setSelectedCreatives={setSelectedCreatives}
          selectedCreatives={selectedCreatives}
          isImage={imageUrls?.length > 0}
          isVideo={videoUrls?.length > 0}
          actionType={imageUrls?.length > 0 ? CREATIVE_TYPE_IMAGE : CREATIVE_TYPE_VIDEO}
          setVisibleChildCompliantModal={setVisibleChildCompliantModal}
        />
      ),
    },
    {
      key: "2",
      label: "Headline",
      children: activeTabs === "2" && (
        <AdgroupDetails
          renderData={isFbReport ? titles : [{ id: title, text: title }]}
          handleComplianceIcon={handleComplianceIcon}
          isComplianceModal={isComplianceModal}
          setSelectedCreatives={setSelectedCreatives}
          selectedCreatives={selectedCreatives}
          actionType={CREATIVE_TYPE_TITLE}
          setVisibleChildCompliantModal={setVisibleChildCompliantModal}
        />
      ),
    },
    ...(isFbReport
      ? [
          {
            key: "3",
            label: "Primary Text",
            children: activeTabs === "3" && (
              <AdgroupDetails
                renderData={bodies}
                handleComplianceIcon={handleComplianceIcon}
                isComplianceModal={isComplianceModal}
                setSelectedCreatives={setSelectedCreatives}
                selectedCreatives={selectedCreatives}
                isTitle
                actionType={CREATIVE_TYPE_BODY}
                setVisibleChildCompliantModal={setVisibleChildCompliantModal}
              />
            ),
          },
          {
            key: "4",
            label: "Description",
            children: activeTabs === "4" && (
              <AdgroupDetails
                renderData={descriptions}
                handleComplianceIcon={handleComplianceIcon}
                isComplianceModal={isComplianceModal}
                setSelectedCreatives={setSelectedCreatives}
                selectedCreatives={selectedCreatives}
                actionType={CREATIVE_TYPE_DESCRIPTION}
                isDesc
                setVisibleChildCompliantModal={setVisibleChildCompliantModal}
              />
            ),
          },
        ]
      : []),
  ];
  return (
    <div>
      {isComplianceModal && (
        <>
          <div className="d-flex mb-8">
            <Checkbox
              className="mr-8"
              onClick={() => handleSelectAll()}
              checked={isSelectedGroup}
            />
            Select the entire adgroup
          </div>
          <div className="image-content-details">
            <div className="d-flex justify-content-between align-items-center mb-4  w-100">
              <div className="d-flex flex-grow-1 ">
                <div>
                  <Image
                    src={nofile}
                    preview={false}
                    style={{ width: 40, height: 40, borderRadius: 6 }}
                  />
                </div>
                <div className="flex-grow-1 ml-10 mb-24">
                  <h3 className="mb-0">Brand Name</h3>
                  <div> Static </div>
                </div>
              </div>
              <div className="ml-5">
                <LookButton
                  onClick={() => setVisibleChildCompliantModal(true)}
                  disabled={Object.keys(selectedCreatives)?.length === 0}
                  block
                >
                  Mark Compliant
                </LookButton>
              </div>
            </div>
          </div>
        </>
      )}
      <Tabs
        className="ScriptDetailedTabs"
        defaultActiveKey="1"
        items={tabContents}
        onChange={(e) => setActiveTabs(e)}
      />
    </div>
  );
}

export default AdgroupDetailView;
