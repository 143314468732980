import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { Form, Input, Segmented, Button, Switch, Row, Col } from "antd";
import { useLaunchContext } from "components/Launch/Context";
import LookButton from "components/LookButton";
import LookDrawer, { useLookDrawer } from "components/LookDrawer";
import { useEffect, useState } from "react";
import UpdateAdsetForm from "./UpdateAdset/index";
import { EditOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import {
  BID_STRATEGY_LIST,
  BUDGET_TYPE_ENUM,
  CONTENT_TYPE_GROUPS,
  C_TYPE_ENUM,
  SERVICE_TYPE_LEAD_GEN,
} from "shared/enum/launchKeysAndEnum";
import AdAccount from "./AdAccount";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import cx from "cx";
import {
  LAUNCH_COUNTRIES_ENUM,
  LAUNCH_CUSTOM_CREATIVES,
  LAUNCH_SMART_CREATIVE_ENUM,
  LAUNCH_SPECIAL_AD_CATEGORIES,
} from "shared/enum/iCampaignLaunchEnumArray";
import AddName from "./AddName";
import { useSelector } from "react-redux";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { CONTINUES_DRAFT, LAUNCH_STATUS_HAVE_FAILURE } from "components/Launch";
import { useLocation } from "react-router-dom";
import TabRender from "components/TabRender";
import AdgroupSelection from "./UpdateAdset/AdgroupSelection";
import DuplicateCampaign from "./DuplicateCampaign";

export default function LaunchCampaignCreationView(props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    campaignObject,
    handleCampaignObject,
    copyCampaign,
    removeCampaign,
    serviceType,
    targettingTemplateData,
    leadgen_name,
    setLeadgen_name,
    isTiktokPlatformLaunch,
    bucketData,
    chooseDraftOrNew,
    callEditTemplate,
    draftData,
    copySuccessCampaign,
    filterCampaignShowsOnlyFailed,
    getBackFilteredSuccessCampaigns,
    instantLaunch = false,
    adSetwise = false,
    setAdsetWise = () => {},
    isDynamicCreatives = false,
    setIsDynamicCreatives = () => {},
    productId,
    setCampaignObject = () => {},
    urlSplited,
    setUrlSplited = () => {},
  } = useLaunchContext();
  const hasGlobalEditPermission = campaignObject?.every(
    (campaign) => campaign?.categoryId === campaignObject?.[0]?.categoryId
  );
  const {
    isValidAdset = () => {},
    isValidDate = () => {},
    isValid = () => {},
    setFormStep = () => {},
    setIsModalVisible = () => {},
  } = props;
  const [visibleDrawerUpdate, setVisibleDrawerUpdate] = useState(false);
  const [visibleDrawerAdAccount, setVisibleDrawerAdAccount] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [rowProps, setRowProps] = useState();
  const isGroup = bucketData?.content_type === CONTENT_TYPE_GROUPS;
  const [activeTabs, setActiveTabs] = useState("Campaigns");
  const [invalidLaunch, setInvalidLaunch] = useState(true);
  const [duplicateCount, setDuplicateCount] = useState(1);
  const [visibleDuplicateCamp, setVisibleDuplicateCamp] = useState(false);
  const { userSettingData = {}, selectedWorkspace } =
    useSelector((state) => state?.userSettings) || {};

  const { tiktokLaunchFetch = () => {}, tiktokLaunchRefetch } = useApiToStoreContext();

  const { tiktokLaunchConfig, launchConfig } = userSettingData;
  const { campaign } = tiktokLaunchConfig || {};
  const { campaign: fb_campaign } = launchConfig || {};
  const { objective: objective_config = [] } = fb_campaign || {};
  const { objective_type, special_industries, budget_mode } = campaign || {};
  const onOpen = (e) => {
    setRowProps(e);
  };
  const handleLaunch = () => {
    let invalidCount = 0;
    for (const obj in campaignObject) {
      if (isValid(campaignObject[obj]?.adSets)) {
        invalidCount++;
      }
      if (isTiktokPlatformLaunch && !campaignObject[obj]?.objectiveType) {
        invalidCount++;
      }
    }
    if (invalidCount > 0 || (serviceType === SERVICE_TYPE_LEAD_GEN && leadgen_name === "")) {
      setInvalidLaunch(true);

      // openNotification({
      //   type: "error",
      //   message: "Sorry, You can't proceed to launch until the errors are fixed.",
      // });
    } else {
      setInvalidLaunch(false);
      setFormStep(2);
    }
  };

  useEffect(() => {
    handleLaunch();
  }, [campaignObject, leadgen_name]);

  const { drawer, openDrawer, visibleDrawer } = useLookDrawer({
    onOpen,
    size: 1200,
    noBodySpace: true,
  });

  const showDrawerUpdate = (rowProps) => {
    setRowProps(rowProps);

    setVisibleDrawerUpdate(true);
  };
  const onCloseDrawerUpdate = () => {
    setRowProps({});
    setVisibleDrawerUpdate(false);
  };
  const showDrawerAdAccount = () => {
    // setRowProps(rowProps);

    setVisibleDrawerAdAccount(true);
  };
  const onCloseDrawerAdAccount = () => {
    // setRowProps({});
    setVisibleDrawerAdAccount(false);
  };
  const showModal = () => {
    setVisibleModal(true);
  };
  const onCloseModal = () => {
    setVisibleModal(false);
  };
  const onSubmitAddName = (e) => {
    setLeadgen_name(e);

    setVisibleModal(false);
  };

  const showDuplicateModel = (index) => {
    setVisibleDuplicateCamp(true);
    setRowProps(index);
  };

  const closeDuplicateModal = () => {
    setVisibleDuplicateCamp(false);
  };

  const submitDuplicateCampaign = (e) => {
    setDuplicateCount(e);
    copyCampaign(rowProps, e);
    setVisibleDuplicateCamp(false);
  };

  useEffect(() => {
    if (isTiktokPlatformLaunch) {
      tiktokLaunchFetch();
    }
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Bid Strategy",
      dataIndex: "bidStrategy",
      key: "bidStrategy",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Bid",
      dataIndex: "bid",
      key: "bid",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Campaign Objective",
      dataIndex: "objective",
      key: "objective",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Optimization Goal",
      dataIndex: "optimizationGoal",
      key: "optimizationGoal",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
  ];

  const tiktokColumns = [
    {
      title: "Objective Type",
      dataIndex: "objectiveType",
      key: "objectiveType",
      // type: "select",
      // editable: true,
      render: (value, otherProps) => (
        <SelectInput
          name="objectiveType"
          className="mb-0"
          value={value}
          search
          onChange={(e) => handleCampaignObject(otherProps?.campaignIndex, { objectiveType: e })}
          options={objective_type}
        />
      ),
      width: 185,
      minWidth: 185,
    },
    {
      title: "Special Industries",
      dataIndex: "specialIndustries",
      key: "specialIndustries",
      render: (value, otherProps) => (
        <SelectInput
          name="specialIndustries"
          multiple
          className="mb-0"
          value={value}
          onChange={(e) =>
            handleCampaignObject(otherProps?.campaignIndex, { specialIndustries: e })
          }
          options={special_industries}
        />
      ),
      // type: "text",
      // editable: true,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (value, otherProps) =>
        otherProps.cType === "CBO" ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name="budget"
          >
            <div className="launch-budget-wrapper d-flex">
              <Input
                placeholder="Enter budget (USD)"
                type="number"
                className="editable-input"
                value={value}
                controls={false}
                onChange={(e) =>
                  handleCampaignObject(otherProps?.campaignIndex, {
                    budget: parseFloat(e.target.value),
                  })
                }
              />
            </div>
          </Form.Item>
        ) : (
          "AdSet Level"
        ),
      width: 120,
      minWidth: 120,
    },
    {
      title: "Budget Mode",
      dataIndex: "budgetMode",
      key: "budgetMode",
      render: (value, otherProps) => (
        <>
          {otherProps.cType === "CBO" ? (
            <SelectInput
              search
              className="mb-0"
              onChange={(e) => handleCampaignObject(otherProps?.campaignIndex, { budgetMode: e })}
              value={value}
              options={budget_mode}
            />
          ) : (
            "AdSet Level"
          )}
        </>
      ),
      width: 150,
      minWidth: 150,
    },
  ];

  const tiktokTargetingColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "BudgetMode",
      dataIndex: "budgetMode",
      key: "budgetMode",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Optimization Goal",
      dataIndex: "optimizationGoal",
      key: "optimizationGoal",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value || otherProps?.template?.optimizationGoal} />
      ),
      width: 124,
      minWidth: 124,
    },
  ];

  const initialColumns = [
    {
      title: () => {
        if (serviceType === SERVICE_TYPE_LEAD_GEN) {
          return (
            <div
              className="d-flex"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div> Campaign Name </div>
              <div>
                <Button type="link" onClick={() => showModal()} icon={<EditOutlined />} />
              </div>
            </div>
          );
        } else {
          return "Campaign Name";
        }
      },
      dataIndex: "campaignName",
      key: "campaignName",
      width: 300,
      minWidth: 300,
      render: (value, otherProps) => {
        if (serviceType === SERVICE_TYPE_LEAD_GEN) {
          return (
            <LookTableColumnRender
              title={leadgen_name ? `${leadgen_name}-c${otherProps?.campaignIndex + 1}` : ""}
            />
          );
        } else {
          return (
            <div className="launch-budget-wrapper d-flex">
              <Input
                placeholder="Enter name"
                className="editable-input"
                value={otherProps?.name}
                onChange={(e) => {
                  handleCampaignObject(
                    otherProps?.campaignIndex,
                    {
                      name: e.target.value,
                    },
                    { edited: true }
                  );
                }}
              />
            </div>
          );
        }
      },
    },
    {
      title: "C-type",
      dataIndex: "cType",
      key: "cType",
      render: (value, otherProps) => (
        <>
          <Segmented
            onChange={(e) => handleCampaignObject(otherProps?.campaignIndex, { cType: e })}
            value={value}
            options={C_TYPE_ENUM?.map(({ name, value }) => ({ label: name, value }))}
          />
        </>
      ),
      width: 124,
      minWidth: 124,
    },
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
      // type: "text",
      // editable: true,
      width: 124,
      minWidth: 124,
      render: (value, otherProps) => {
        return (
          <div className="d-flex" style={{ alignItems: "center" }}>
            <LookTableColumnRender
              tooltip={
                isValid(otherProps?.adSets)
                  ? "Please ensure that creatives are selected and scheduled for a start date is not before today"
                  : "All Set"
              }
              title={
                <strong className={cx(isValid(otherProps?.adSets) ? "text-danger" : "")}>
                  {otherProps?.adSets?.map((item) => item?.targeting?.edited).includes(true)
                    ? `Edited (${otherProps?.adSets?.length})`
                    : `Template (${otherProps?.adSets?.length})`}
                </strong>
              }
            />
          </div>
        );
      },
    },

    ...(isTiktokPlatformLaunch
      ? tiktokColumns
      : [
          {
            title: "Campaign Objective",
            dataIndex: "objective",
            key: "objective",
            // type: "select",
            // editable: true,
            render: (value, otherProps) => (
              <SelectInput
                name="objective"
                className="mb-0"
                value={value}
                onChange={(e) => handleCampaignObject(otherProps?.campaignIndex, { objective: e })}
                options={objective_config}
              />
            ),
            width: 185,
            minWidth: 185,
          },
          {
            title: "Ad Categories",
            dataIndex: "specialAdCategories",
            key: "specialAdCategories",
            // type: "select",
            // editable: true,
            render: (value, otherProps) => (
              <SelectInput
                name="specialAdCategories"
                multiple
                className="mb-0"
                value={value}
                onChange={
                  (e) => {
                    if (e.includes("NONE")) {
                      // setAdCategories(["NONE"]);
                      handleCampaignObject(otherProps?.campaignIndex, {
                        specialAdCategories: ["NONE"],
                        specialAdCategoryCountry: [],
                      });
                    } else {
                      // setAdCategories(e);

                      handleCampaignObject(otherProps?.campaignIndex, { specialAdCategories: e });
                    }
                  }

                  // ? handleCampaignObject(otherProps?.campaignIndex, { specialAdCategories: [] })
                  // : handleCampaignObject(otherProps?.campaignIndex, { specialAdCategories: e })
                }
                options={LAUNCH_SPECIAL_AD_CATEGORIES}
              />
            ),
            width: 185,
            minWidth: 185,
          },
          {
            title: "Ad Country",
            dataIndex: "specialAdCategoryCountry",
            key: "specialAdCategoryCountry",
            // type: "select",
            // editable: true,
            render: (value, otherProps) => {
              if (
                !otherProps?.specialAdCategories ||
                otherProps.specialAdCategories.includes("NONE")
              ) {
                return null;
              }

              return (
                <SelectInput
                  name="specialAdCategoryCountry"
                  search
                  multiple
                  className="mb-0"
                  value={value}
                  onChange={(e) => {
                    handleCampaignObject(otherProps?.campaignIndex, {
                      specialAdCategoryCountry: e,
                    });
                  }}
                  options={LAUNCH_COUNTRIES_ENUM}
                />
              );
            },

            width: 185,
            minWidth: 185,
          },

          {
            title: "Budget",
            dataIndex: "budget",
            key: "budget",
            render: (value, otherProps) =>
              otherProps.cType === "CBO" ? (
                <Form.Item
                  style={{
                    margin: 0,
                  }}
                  name="budget"
                >
                  <div className="launch-budget-wrapper d-flex">
                    <Input
                      placeholder="Enter budget (USD)"
                      type="number"
                      className="editable-input"
                      value={value}
                      controls={false}
                      onChange={(e) =>
                        handleCampaignObject(otherProps?.campaignIndex, {
                          budget: parseFloat(e.target.value),
                        })
                      }
                    />
                  </div>
                </Form.Item>
              ) : (
                "AdSet Level"
              ),
            width: 120,
            minWidth: 120,
          },
          {
            title: "Budget-type",
            dataIndex: "budget_type",
            key: "budget_type",
            render: (value, otherProps) => (
              <>
                {otherProps.cType === "CBO" ? (
                  <Segmented
                    onChange={(e) =>
                      handleCampaignObject(otherProps?.campaignIndex, { budget_type: e })
                    }
                    value={value}
                    options={BUDGET_TYPE_ENUM?.map(({ name, value }) => ({ label: name, value }))}
                  />
                ) : (
                  "AdSet Level"
                )}
              </>
            ),
            width: 150,
            minWidth: 150,
          },
          {
            title: "Bid Strategy",
            dataIndex: "bidStrategy",
            key: "bidStrategy",
            render: (value, otherProps) => (
              <>
                {otherProps.cType === "CBO" ? (
                  <SelectInput
                    name="bidStrategy"
                    className="mb-0"
                    value={value}
                    onChange={(e) =>
                      handleCampaignObject(otherProps?.campaignIndex, { bidStrategy: e })
                    }
                    options={BID_STRATEGY_LIST?.map(({ name, value }) => ({ name, value }))}
                  />
                ) : (
                  "AdSet Level"
                )}
              </>
            ),
            width: 200,
            minWidth: 200,
          },
        ]),

    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        const { isDuplicate = false } = actionProps;
        return (
          <span>
            <LookButton
              className="h-auto w-auto"
              type="link"
              iconType="copy"
              tooltipTitle="Duplicate"
              onClick={() => {
                showDuplicateModel(actionProps?.campaignIndex);
              }}
            />
            {isDuplicate && (
              <LookButton
                className="h-auto w-auto ml-8"
                type="link"
                danger
                iconType="delete"
                tooltipTitle="Remove"
                onClick={() => removeCampaign([actionProps?.campaignIndex])}
              />
            )}
          </span>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  // useEffect(() => {
  //   if (dataSource.some((item) => item.bType === 1)) {
  //     setTempColumns(initialColumns.map((item) => item.dataIndex === "adset2"));
  //   }
  // }, [tempColumns]);

  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) =>
        removeCampaign(
          e?.map((data) => {
            if (data?.isDuplicate) return data?.campaignIndex;
          }),
          true
        ),
    },
  ];

  /**
   * Push index to recordData for handle camapign
   */
  // const [count, setCount] = useState(0);
  let validCount = 0;
  for (const obj in campaignObject) {
    if (isValid(campaignObject[obj]?.adSets)) {
      validCount++;
    }
  }

  const modalProps = {
    title: "Add Name",
    visible: visibleModal,
    onCancel: onCloseModal,
    okokText: "Submit",
    onSubmit: onSubmitAddName,
    footer: null,
  };

  const duplicateModelProps = {
    title: "Duplicate of Campaign",
    visible: visibleDuplicateCamp,
    onCancel: closeDuplicateModal,
    okokText: "Duplicate",
    onSubmit: submitDuplicateCampaign,
    footer: null,
  };
  const campaignData = campaignObject?.map((data, index) => ({
    ...data,
    key: index,
    campaignName: "campaign-" + parseInt(index + 1),
    campaignIndex: index,
    adSets: data.adSets?.map((adset, adsetIndex) => ({
      ...adset,
      adSetindex: adsetIndex,
    })),
  }));
  const tableProps = {
    initialColumns: initialColumns,
    recordData: campaignData,
  };
  const tableTargetingProps = { initialColumns: columns, recordData: [targettingTemplateData] };
  const tiktokTableTargetingProps = {
    initialColumns: tiktokTargetingColumns,
    recordData: [targettingTemplateData],
  };

  const titleCamapignName = campaignObject?.[rowProps?.campaignIndex]?.viewTitle || "";

  const drawerPropsUpdate = {
    textBreadcrumb: [titleCamapignName],
    visible: visibleDrawerUpdate,
    onClose: onCloseDrawerUpdate,
    size: 1200,
  };
  const drawerPropsAdAccount = {
    textBreadcrumb: [titleCamapignName],
    visible: visibleDrawerAdAccount,
    onClose: onCloseDrawerAdAccount,
    size: 700,
  };

  const message =
    serviceType === SERVICE_TYPE_LEAD_GEN && leadgen_name === "" ? " and campaign name" : "";

  const editTemplate = () =>
    chooseDraftOrNew === CONTINUES_DRAFT ? (
      <LookButton
        tooltipTitle="Change Template"
        type="text"
        iconType="edit"
        onClick={callEditTemplate}
      />
    ) : null;

  const handleBulkSelection = (e) => {
    setRowProps(e);
  };

  const tabContents = [
    {
      id: "Campaigns",
      title: `Campaigns`,
      hoverTitle: `Campaigns`,
      icon: (e) => <ActionIconRender iconType="notification" {...e} />,
      component: (
        <>
          <LookTable
            sizes={{ reduceExtraScrollHeightBy: 300 }}
            isTableFilter={false}
            isSrNo
            persistKey={USER_SETTING_TABLE_PERSIST_KEY_MAIN}
            // isEditable
            bulkActions={bulkActions}
            tableProps={tableProps}
            // dataSource={dataSource}
            // setDataSource={setDataSource}
            handleBulkSelection={handleBulkSelection}
            scrollHeight="calc(100vh - 211px)"
            isRowSelection
          />
        </>
      ),
    },
    {
      id: "Adsets-for-Campaigns",
      title: `Adsets`,
      hoverTitle: `Adsets`,
      icon: (e) => <ActionIconRender iconType="font-colors" {...e} />,
      component: (
        <UpdateAdsetForm
          campaignProps={
            rowProps?.length > 0
              ? campaignObject
                  .map((data, index) => {
                    const shouldMap = rowProps.find((rowData) => rowData?.campaignIndex === index);
                    if (shouldMap !== undefined) {
                      return {
                        ...data,
                        // add campaignName along with campaign Index in future
                        campaignName: "campaign-" + parseInt(index + 1),
                        campaignIndex: shouldMap?.campaignIndex,
                        adSets: data.adSets?.map((adset, adsetIndex) => ({
                          ...adset,
                          adSetindex: adsetIndex,
                        })),
                      };
                    }
                    return null;
                  })
                  .filter(Boolean)
              : campaignData
          }
          serviceType={serviceType}
          campaignObject={campaignObject}
          titleCamapignName={titleCamapignName}
          isValidAdset={isValidAdset}
          isValidDate={isValidDate}
        />
      ),
    },
  ];

  const labelSwitch = ({ label, value, onChange = () => {}, disabled = false }) => (
    <div className="d-flex mr-10">
      <p className="mr-10">{label}</p>
      <Switch checked={value} disabled={disabled} onChange={onChange} />
    </div>
  );

  const campaignCreativeView = () => (
    <div className="mx-20 d-flex">
      <div className="mr-10">
        <p>Campaigns & Creatives</p>
      </div>

      {labelSwitch({
        label: "Url Params",
        value: urlSplited,
        onChange: setUrlSplited,
      })}
      {labelSwitch({
        label: "Dynamic",
        value: isDynamicCreatives,
        disabled: !hasGlobalEditPermission,
        onChange: (e) => {
          const finalCampaign = campaignObject?.map((campaigns) => ({
            ...campaigns,
            adSets: (campaigns.adSets || []).map((adSets) => {
              return {
                ...adSets,
                ...(isTiktokPlatformLaunch
                  ? {
                      creativeMaterialMode:
                        e === true ? LAUNCH_SMART_CREATIVE_ENUM : LAUNCH_CUSTOM_CREATIVES,
                    }
                  : { isDynamicCreative: e }),
              };
            }),
          }));
          setCampaignObject(finalCampaign);
          setIsDynamicCreatives(e);
        },
      })}
    </div>
  );
  const addCreativeActionCard = () => (
    <div className="w-100 mb-20 addCreativeActionCard">
      <div
        className="text-center add-action"
        style={{ cursor: !hasGlobalEditPermission && "no-drop" }}
        onClick={
          hasGlobalEditPermission
            ? () =>
                openDrawer({
                  campaignIndex: 0,
                  adSetindex: 0,
                  isKeyword: isGroup,
                  isTemp: true,
                  oneToOneAds: adSetwise,
                  isTiktokPlatformLaunch,
                })
            : undefined
        }
      >
        <div>
          <PlusOutlined />
        </div>
        <div>Add Creatives Globally</div>
      </div>
    </div>
  );

  return (
    (bucketData || instantLaunch) && (
      <div>
        <AddName
          name={leadgen_name}
          setName={setLeadgen_name}
          modalProps={modalProps}
          onClose={onCloseModal}
          onSubmitAddName={onSubmitAddName}
          visibleModal={visibleModal}
        />
        {visibleDuplicateCamp && <DuplicateCampaign {...duplicateModelProps} closeDuplicateModal />}

        <LookDrawer {...drawerPropsAdAccount} className="no-body-space">
          {visibleDrawerAdAccount && (
            <LookDrawerMainSideBar
              tabContent={[
                {
                  hoverTitle: "Edit Targeting",
                  icon: () => <ActionIconRender iconType="pic-left" />,
                  noSpace: true,
                  component: (
                    <AdAccount
                      onClose={onCloseDrawerAdAccount}
                      {...rowProps}
                      campaignObject={campaignObject}
                      titleCamapignName={titleCamapignName}
                    />
                  ),
                },
              ]}
            />
          )}
        </LookDrawer>
        {visibleDrawer &&
          drawer(
            <AdgroupSelection
              {...rowProps}
              // campaignIndex={campaignIndex}
              productId={productId}
              isKeyword={isGroup}
              dynamic={isDynamicCreatives}
              isTiktokPlatformLaunch={isTiktokPlatformLaunch}
            />
          )}

        <LookDrawer {...drawerPropsAdAccount} className="no-body-space">
          {visibleDrawerAdAccount && (
            <LookDrawerMainSideBar
              tabContent={[
                {
                  hoverTitle: "Edit Targeting",
                  icon: () => <ActionIconRender iconType="pic-left" />,
                  noSpace: true,
                  component: (
                    <AdAccount
                      onClose={onCloseDrawerAdAccount}
                      {...rowProps}
                      campaignObject={campaignObject}
                      titleCamapignName={titleCamapignName}
                    />
                  ),
                },
              ]}
            />
          )}
        </LookDrawer>
        {campaignCreativeView()}
        {addCreativeActionCard()}

        <div className="mt-15 mx-20 mb-10 flex align-items-center justify-content-between">
          <div>
            <p className="mb-0">Campaign & Adsets</p>
            {(validCount > 0 || (serviceType === SERVICE_TYPE_LEAD_GEN && leadgen_name === "")) && (
              <p className="mt-0 mb-0" style={{ color: "red" }}>
                Adjust adsets{message} before launching
              </p>
            )}
          </div>

          <div>
            {draftData?.success_camps_indexes &&
              draftData?.success_camps_indexes?.length > 0 &&
              JSON.parse(queryParams?.get("status")) === LAUNCH_STATUS_HAVE_FAILURE && (
                <>
                  <Segmented
                    onChange={(e) => {
                      e === "All campaigns"
                        ? getBackFilteredSuccessCampaigns()
                        : filterCampaignShowsOnlyFailed();
                      // copySuccessCampaign?.length > 0
                      //   ? getBackFilteredSuccessCampaigns()
                      //   : filterCampaignShowsOnlyFailed()
                    }}
                    value={copySuccessCampaign?.length > 0 ? "Failed campaigns" : "All campaigns"}
                    disabled={isTiktokPlatformLaunch}
                    options={[
                      { name: "All", value: "All campaigns" },
                      { name: "Failed campaigns", value: "Failed campaigns" },
                    ]?.map(({ name, value }) => ({ label: name, value }))}
                  />
                </>
              )}
          </div>
        </div>
        <div>
          <TabRender
            className={cx("no-tab-space no-operations ")}
            fullBtn
            tabContent={tabContents}
            value={activeTabs}
            onChange={setActiveTabs}
          />
        </div>
        <div className={`p-8  h-initial w-initial mb-15`}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item>
                <Button onClick={() => setFormStep(1)} block className="submit-btn" size="large">
                  Previous Page
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={invalidLaunch}
                  onClick={() => setIsModalVisible(true)}
                >
                  Launch
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    )
  );
}
