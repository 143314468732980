import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { Badge } from "antd";
import SelectInput from "components/forms/SelectInput";
import {
  ME_DATA,
  MULTI_WORKSPACE_SELECTION,
  USER_SETTING_DATA,
  WORKSPACE_SELECTION,
  WORKSPACE_SELECTION_WITH_ALL_FALSE,
  WORKSPACE_WITH_ALL,
} from "actions/actionTypes";
import { useHistory } from "react-router-dom";

export default function WorkspaceSelectPersist(props) {
  const history = useHistory();
  const { withAll = false, multiple = false } = props;
  const dispatch = useDispatch();
  const meData = useSelector((state) => state?.userReducer?.me);
  const workspace = useSelector((state) => state?.userReducer?.me?.workspace);
  const { selectedWorkspace, multiSelectedWorkspace, selectedWorkspaceWithAll, userSettingData } =
    useSelector((state) => state?.userSettings) || {};

  const { users = [], users_loading = false } = userSettingData || {};

  useEffect(() => {
    if (!selectedWorkspace) {
      dispatch({ type: WORKSPACE_SELECTION, payload: workspace[0]?.id });
    }
  }, []);

  const isWithAllBit = withAll && workspace?.length > 1;

  function handleSelect(e) {
    if (multiple) {
      dispatch({ type: MULTI_WORKSPACE_SELECTION, payload: e });
    } else {
      if (isWithAllBit) {
        if (e === "all") {
          dispatch({ type: WORKSPACE_WITH_ALL, payload: true });
        } else {
          dispatch({ type: WORKSPACE_SELECTION_WITH_ALL_FALSE, payload: e });
        }
      } else {
        dispatch({ type: WORKSPACE_SELECTION, payload: e });
        dispatch({ type: ME_DATA, payload: { ...meData, permissions: [] } });
        dispatch({ type: USER_SETTING_DATA, payload: { users, users_loading } });
      }
    }
    history.push("/");
  }

  const isAllOption = isWithAllBit ? [{ name: "All", value: "all" }] : [];

  const options = [
    ...isAllOption,
    ...workspace?.map(({ id, title }) => ({ name: title, value: id })),
  ];

  const allProps = {
    search: true,
    multiple,
    placeholder: "Workspace",
    className: cx("table-filter-wrokspace w-100 mb-0", { multiple }),
    options,
    value: multiple
      ? multiSelectedWorkspace
      : selectedWorkspaceWithAll && isWithAllBit
      ? "all"
      : selectedWorkspace,
    onChange: (e) => handleSelect(e),
  };

  const MultiBadgeWrapper =
    multiple && multiSelectedWorkspace && multiSelectedWorkspace?.length > 1 ? Badge : Fragment;
  return (
    <div>
      {workspace && workspace.length > 1 && (
        <div className="table-filter-wrokspace-wrapper mb-10" style={{ width: 200 }}>
          <MultiBadgeWrapper count={multiSelectedWorkspace?.length} size="small">
            <SelectInput {...allProps} />
          </MultiBadgeWrapper>
        </div>
      )}
    </div>
  );
}
