import React, { useEffect, useMemo, useState } from "react";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import {
  INDUSTRY_INSIGHTS_ADS,
  INDUSTRY_INSIGHTS_GET_KEYWORDS_DOMAINS,
  INDUSTRY_INSIGHTS_GET_PAGES,
} from "shared/gql/gqlSchema/industryInsightsGql";
import { openNotification } from "utils";
import { useSelector } from "react-redux";
import LookTable from "components/LookTable";
import { Pagination, Segmented } from "antd";
import { ADS_TYPE_STATIC, cs_AdTypeEnum } from "../industryInsightsEnum";
import useCustomeView from "./useCustomeView";
import LookDrawer from "components/LookDrawer";
import FilterPopup from "./FilterPopup";
import { FAILURE, SUCCESS } from "shared/enum/commonKeysandEnum";
import { LAUNCH_COUNTRIES_ENUM } from "shared/enum/iCampaignLaunchEnumArray";
import AdsDetailedDrawer from "./AdsDetailedDrawer";
import LookModal from "components/LookModal";
import AddToCreativeLib from "./AddToCreativeLib";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { industryInsightsFilterPopupCategoryLabels as LABELS } from "shared/enum/industryInsightsKeysAndEnums";

function Ads(props) {
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [adsType, setAdsType] = useState(ADS_TYPE_STATIC);
  const [totalCount, setTotalCount] = useState();
  const [adsData, setAdsData] = useState([]);
  const [rowProps, setRowProps] = useState([]);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { viewAdsProp = false, filteredData = {} } = props;
  const [visibleCategoryModal, setVisibleCategoryModal] = useState(false);
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  // domains & pages data coming from API which will be used to create filter categories & its subvalues
  const [keywordDomainsData, setKeywordDomainsData] = useState([]);
  const [keywordPagesData, setKeywordPagesData] = useState([]);
  const [infiniteScrollObject, setInfiniteScrollObject] = useState({});
  const [searchObject, setSearchObject] = useState({ category: "", searchValue: "" });
  const [filterAPIsLoader, setFilterAPIsLoader] = useState(false);

  // state that holds all selected filters which can used to later on filter data
  const [globalSelectedFilters, setGlobalSelectedFilters] = useState(
    viewAdsProp ? filteredData : {}
  );

  const [isFilterActive, setIsFilterActive] = useState(false);

  const domainsAPIInitialVariables = {
    fromDate: dateRangeTimezone?.rangeDates[0],
    toDate: dateRangeTimezone?.rangeDates[1],
    column: "name",
    size: 5,
    page: 1,
    order: "ASC",
  };
  const [
    fetchDomainsCategoryData,
    { loading: loading_GET_KEYWORDS_DOMAINS, refetch: refetch_GET_KEYWORDS_DOMAINS },
  ] = useCustomLazyQuery(INDUSTRY_INSIGHTS_GET_KEYWORDS_DOMAINS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      setFilterAPIsLoader(false);
      const element = e?.ii_KeywordsDomains;
      if (element) {
        if (element?.status === SUCCESS) {
          if (searchObject.firstSearchPage) {
            setKeywordDomainsData(element?.data?.data);
          } else setKeywordDomainsData((state) => [...state, ...element?.data?.data]);
        } else if (element?.status === FAILURE) {
          openNotification({ type: "error", message: element?.message, key: "error-add" });
        }
      }
    },
    onError: (err) => {
      setFilterAPIsLoader(false);
      openNotification({ type: "error", message: err.message });
    },
    variables: {
      ...domainsAPIInitialVariables,
    },
  });

  const pagesAPIInitialVariables = {
    fromDate: dateRangeTimezone?.rangeDates[0],
    toDate: dateRangeTimezone?.rangeDates[1],
    column: "page_name",
    size: 5,
    page: 1,
    order: "ASC",
  };

  const [fetchPagesCategoryData, { loading: loading_GET_PAGES, refetch: refetch_GET_PAGES }] =
    useCustomLazyQuery(INDUSTRY_INSIGHTS_GET_PAGES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        setFilterAPIsLoader(false);
        const element = e?.ii_Pages;
        if (element) {
          if (element?.status === SUCCESS) {
            if (searchObject.firstSearchPage) {
              setKeywordPagesData(element?.data?.data);
            } else setKeywordPagesData((state) => [...state, ...element?.data?.data]);
          } else if (element?.status === FAILURE) {
            openNotification({ type: "error", message: element?.message, key: "error-add" });
          }
        }
      },
      onError: (err) => {
        setFilterAPIsLoader(false);
        openNotification({ type: "error", message: err.message });
      },
      variables: {
        ...pagesAPIInitialVariables,
      },
    });

  // call the pages & domains API every time the scroll ends
  useEffect(() => {
    setFilterAPIsLoader(true);
    if (infiniteScrollObject.category === LABELS.PAGES && infiniteScrollObject.page) {
      fetchPagesCategoryData({
        variables: {
          ...pagesAPIInitialVariables,
          page: infiniteScrollObject.page,
        },
      });
    } else if (infiniteScrollObject.category === LABELS.DOMAINS && infiniteScrollObject.page) {
      fetchDomainsCategoryData({
        variables: {
          ...domainsAPIInitialVariables,
          page: infiniteScrollObject.page,
        },
      });
    }
  }, [infiniteScrollObject]);

  // handles search based API calling
  useEffect(() => {
    setFilterAPIsLoader(true);
    if (searchObject.category === LABELS.PAGES) {
      fetchPagesCategoryData({
        variables: {
          ...pagesAPIInitialVariables,
          search: searchObject.searchValue,
          page: infiniteScrollObject.page,
        },
      });
    } else if (searchObject.category === LABELS.DOMAINS) {
      fetchDomainsCategoryData({
        variables: {
          ...domainsAPIInitialVariables,
          search: searchObject.searchValue,
          page: infiniteScrollObject.page,
        },
      });
    }
  }, [searchObject, infiniteScrollObject]);

  //handle ads api
  const [fetchIndustryInsightsAds, { loading: ads_loading, refetch: ads_refetch }] =
    useCustomLazyQuery(INDUSTRY_INSIGHTS_ADS, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const element = e?.cs_Ads?.data;
        setTotalCount(e?.cs_Ads?.pageInfo?.totalRecords);
        setAdsData(element);
      },
      onError: (e) => {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const initialVariables = useMemo(() => {
    const getIDsOfSelectedFiltersForEachCategory = (globalSelectedFilters) => {
      const result = {};

      Object.keys(globalSelectedFilters).forEach((category) => {
        if (category === LABELS.COUNTRIES) {
          result[category] = globalSelectedFilters[category].map((item) => item?.value);
        } else if (category === LABELS.DOMAINS) {
          result[category] = globalSelectedFilters[category].map((item) => item?.id);
        } else if (category === LABELS.PAGES) {
          result[category] = globalSelectedFilters[category].map((item) => item?.pageId);
        }
      });

      return result;
    };

    // structured required payload for the API
    const globalFilterVars = getIDsOfSelectedFiltersForEachCategory(globalSelectedFilters);

    return {
      fromDate: dateRangeTimezone?.rangeDates[0],
      toDate: dateRangeTimezone?.rangeDates[1],
      page: currentPage,
      size: pageSize,
      adType: adsType.toLowerCase(),
      search,
      ...(Object.keys(globalFilterVars).length > 0 && {
        filters: {
          countryCodes: globalFilterVars.Country,
          domains: globalFilterVars.Domain,
          pages: globalFilterVars.Pages,
        },
      }),
    };
  }, [dateRangeTimezone, currentPage, pageSize, adsType, globalSelectedFilters, search]);
  useEffect(() => {
    const vl = initialVariables;
    fetchIndustryInsightsAds({
      variables: { ...vl },
    });
    setKeywordDomainsData([]);
    setKeywordPagesData([]);
  }, [
    dateRangeTimezone?.rangeDates,
    currentPage,
    pageSize,
    adsType,
    globalSelectedFilters,
    search,
  ]);

  // handle active days functionality of particular ad
  const getActiveDays = ({ adDeliveryStartTime = "" }) => {
    if (adDeliveryStartTime === "") {
      return "-";
    }
    const date1 = new Date(adDeliveryStartTime);
    const date2 = new Date();
    const differenceMs = date2 - date1;
    const differenceDays = differenceMs / (1000 * 3600 * 24);

    return Math.round(differenceDays);
  };

  // custome ads view
  const { customeView } = useCustomeView({
    adsData,
    adsType,
    loading: ads_loading,
    setVisibleDrawer,
    setRowProps,
    getActiveDays,
    setVisibleCategoryModal,
  });

  const rightOptions = (
    <div>
      <Segmented options={cs_AdTypeEnum} value={adsType} onChange={setAdsType} />
    </div>
  );
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    setRowProps([]);
  };

  const drawerProps = {
    title: `Ad Id:${rowProps?.adId}`,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 1094,
  };

  const filterCategoryData = {
    keywordDomainsData,
    keywordPagesData,
    launchCountries: LAUNCH_COUNTRIES_ENUM.map((it, index) => {
      return { ...it, id: index + 1, isSelected: false };
    }),
  };

  const onCancel = () => {
    setVisibleCategoryModal(false);
    setRowProps({});
  };
  const addToCreativeLibProps = {
    title: "Save to Creative Library",
    visible: visibleCategoryModal,
    onCancel: onCancel,
    maskClosable: false,
    footer: null,
  };

  return (
    <div className="industry-insights">
      {visibleDrawer && (
        <LookDrawer {...drawerProps}>
          <AdsDetailedDrawer {...rowProps} adsType={adsType} getActiveDays={getActiveDays} />
        </LookDrawer>
      )}
      {visibleCategoryModal && (
        <LookModal {...addToCreativeLibProps}>
          <AddToCreativeLib
            {...rowProps}
            visibleCategoryModal={visibleCategoryModal}
            onCancel={onCancel}
          />
        </LookModal>
      )}
      <LookTable
        persistKey={persistKey}
        rightOptions={rightOptions}
        leftOptions={
          <FilterPopup
            setGlobalSelectedFilters={setGlobalSelectedFilters}
            globalSelectedFilters={globalSelectedFilters}
            filterCategoryData={filterCategoryData}
            setIsFilterActive={setIsFilterActive}
            isFilterActive={isFilterActive}
            setInfiniteScrollObject={setInfiniteScrollObject}
            setSearchObject={setSearchObject}
            loader={filterAPIsLoader}
          />
        }
        refetch={ads_refetch}
        loading={ads_loading}
        customeView={customeView}
        isDateRange
      />
      <div className="p-8 look-bottom-pagination ">
        <Pagination
          defaultCurrent={1}
          total={totalCount}
          pageSize={pageSize}
          current={currentPage}
          onChange={(page, size) => handlePageChange(page, size)}
          pageSizeOptions={["10", "20", "50"]}
          showSizeChanger
          showQuickJumper
        />
      </div>
    </div>
  );
}

export default Ads;
