import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "classnames";
import moment from "moment";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { VS_REVENUE_CRON_STATUS } from "shared/gql/gqlSchema/viralSparksGql";
import { REPORT_TYPE_AD, REPORT_TYPE_AD_ADSET, REPORT_TYPE_CAMPAIGN } from "shared/SharedKeys";
import { numberParse } from "utils";

export default function useColumns({ node = "", timeZone = "" }) {
  const isAdmin = false;
  const adminRevenue = isAdmin
    ? [
        {
          title: "Agency Revenue",
          dataIndex: "TotalRevenue",
          key: "TotalRevenue",
          render: (value) => (
            <LookTableColumnRender title={numberParse(value, { toFixed: true })} />
          ),
          width: 130,
          minWidth: 130,
          sorter: (a, b) => a?.TotalRevenue - b?.TotalRevenue,
        },
      ]
    : [];

  const isBidColumn =
    node === REPORT_TYPE_AD_ADSET
      ? [
          {
            title: "Bid Amount",
            dataIndex: "bid_amount",
            key: "bid_amount",
            render: (value) => <LookTableColumnRender title={value} />,
            width: 130,
            minWidth: 130,
            sorter: (a, b) => a?.bid_amount - b?.bid_amount,
            searchType: "number",
          },
          {
            title: "Bid Strategy",
            dataIndex: "bid_strategy",
            key: "bid_strategy",
            render: (value) => <LookTableColumnRender title={value} />,
            width: 200,
            minWidth: 200,
            sorter: (a, b) => a?.bid_strategy - b?.bid_strategy,
            searchType: "string",
          },
        ]
      : [];

  const isStatusColumn =
    node === REPORT_TYPE_AD_ADSET || node === REPORT_TYPE_AD || node === REPORT_TYPE_CAMPAIGN
      ? [
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value) => <LookTableColumnRender title={value} />,
            width: 100,
            minWidth: 100,
            sorter: (a, b) => a?.status?.localeCompare(b?.status),
            searchType: "string",
          },
          {
            title: "Created date",
            dataIndex: "created_time",
            key: "created_time",
            render: (value) => {
              const dateTime = moment(value).format("YYYY-MM-DD");
              return <LookTableColumnRender title={dateTime} />;
            },
            width: 130,
            minWidth: 130,
            sorter: (a, b) => a?.created_time - b?.created_time,
          },
          {
            title: "Run time",
            dataIndex: "runTime",
            key: "runTime",
            render: (value, otherProps) => {
              let renderTime = moment()
                .tz(timeZone?.timeZone?.value)
                .diff(moment.utc(otherProps?.created_time), "days");
              return <LookTableColumnRender title={`${renderTime} day(s)`} />;
            },
            width: 130,
            minWidth: 130,
            sorter: (a, b) => a?.created_time - b?.created_time,
          },
        ]
      : [];

  const columns = [
    ...adminRevenue,
    {
      title: "Revenue",
      dataIndex: "TotalRevenue",
      key: "TotalRevenue",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.TotalRevenue - b?.TotalRevenue,
      searchType: "number",
    },
    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => (
        <LookTableColumnRender title={<>${numberParse(value, { toFixed: true })}</>} />
      ),
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
      searchType: "number",
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value, otherProps) => {
        const profit = `$${value.toFixed(2)}`;

        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      searchType: "number",
    },
    {
      title: "ROAS",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => (
        <LookTableColumnRender title={numberParse(value, { toFixed: true }) + "%"} />
      ),
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.ROI - b?.ROI,
      searchType: "number",
    },
    ...isStatusColumn,
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => (
        <LookTableColumnRender title={numberParse(value, { toFixed: true, toFixedUpTo: 3 })} />
      ),
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.EPC - b?.EPC,
      searchType: "number",
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => (
        <LookTableColumnRender title={numberParse(value, { toFixed: true, toFixedUpTo: 3 })} />
      ),
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPC - b?.CPC,
      searchType: "number",
    },
    {
      title: "CPC(FB)",
      dataIndex: "CPC_FB",
      key: "CPC_FB",
      render: (value) => (
        <LookTableColumnRender title={numberParse(value, { toFixed: true, toFixedUpTo: 3 })} />
      ),
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPC_FB - b?.CPC_FB,
      searchType: "number",
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CTR - b?.CTR,
      searchType: "number",
    },
    {
      title: "FB Results",
      dataIndex: "result",
      key: "result",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.result - b?.result,
      searchType: "number",
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPR - b?.CPR,
      searchType: "number",
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",
    },
    {
      title: "Clicks",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
      searchType: "number",
    },
    {
      title: "Disc",
      dataIndex: "DISC",
      key: "DISC",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.DISC - b?.DISC,
      searchType: "number",
    },
    {
      title: "PPS",
      dataIndex: "PPS",
      key: "PPS",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PPS - b?.PPS,
      searchType: "number",
    },
    {
      title: "IPP",
      dataIndex: "IPP",
      key: "IPP",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.IPP - b?.IPP,
      searchType: "number",
    },
    {
      title: "PRPM",
      dataIndex: "PRPM",
      key: "PRPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PRPM - b?.PRPM,
      searchType: "number",
    },
    ...isBidColumn,
    {
      title: "Impressions (FB)",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
      searchType: "number",
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },
  ];
  return { columns };
}
