import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7,
} from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import Creatives from "pages/dashboardPages/Leadgen/Reports/FacebookReports/Creatives";
import {
  VSAdDaily,
  VSDevicesReport,
  VSMediumsReport,
  VSOperatingSystemsReport,
  VSAdHourOfDay,
} from "pages/dashboardPages/VS/VSReports";
import { REPORT_TYPE_AD_ADSET } from "shared/SharedKeys";
import VSCountriesReport from "../../../CountryReports";
import VSPerformanceReport from "../../../PerformanceReport";
import AdsReport from "../../Ads";

function DetailView(props) {
  const { visible = false, adsetName = "", onClose } = props;

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={adsetName}
    >
      {visible && (
        <LookDrawerMainSideBar
          tabContent={[
            // {
            //   hoverTitle: "Ads Report",
            //   icon: () => <ActionIconRender iconType="notification" />,
            //   component: <Ads isCampaignFiltered {...props} />,
            //   noSpace: true,
            // },
            {
              id: "Hourly-Report",
              hoverTitle: "Hourly Report",
              icon: () => <ActionIconRender iconType="duration" />,
              component: (
                <VSPerformanceReport
                  {...props}
                  isHourly
                  type={REPORT_TYPE_AD_ADSET}
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
                />
              ),
              noSpace: true,
            },

            {
              id: "daily-Report",
              hoverTitle: "Daily Report",
              icon: () => <ActionIconRender iconType="barChartLine" />,
              component: (
                <VSPerformanceReport
                  {...props}
                  type={REPORT_TYPE_AD_ADSET}
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5}
                />
              ),
              noSpace: true,
            },

            {
              hoverTitle: "Ads Report",
              icon: () => <ActionIconRender iconType="adIcon" />,
              component: (
                <AdsReport
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
                  isAdsetPerformanceReport
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Creatives",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <Creatives persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3} {...props} />
              ),
              noSpace: true,
            },
            // {
            //   hoverTitle: "Country",
            //   icon: () => <ActionIconRender iconType="global" />,
            //   component: (
            //     <VSCountriesReport
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
            //       {...props}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   id: "Device-Report",
            //   hoverTitle: "Device Report",
            //   icon: () => <ActionIconRender iconType="apartment" />,
            //   component: (
            //     <VSDevicesReport
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   id: "OS-Report",
            //   hoverTitle: "OS Report",
            //   icon: () => <ActionIconRender iconType="laptop" />,
            //   component: (
            //     <VSOperatingSystemsReport
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   id: "Medium-Report",
            //   hoverTitle: "Medium Report",
            //   icon: () => <ActionIconRender iconType="global" />,
            //   component: (
            //     <VSMediumsReport
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   id: "Daily-Report",
            //   hoverTitle: "Daily Report",
            //   icon: () => <ActionIconRender iconType="barChartLine" />,
            //   component: (
            //     <VSAdDaily
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   id: "Hour-of-the-Day-Report",
            //   hoverTitle: "Hour of the Day Report",
            //   icon: () => <ActionIconRender iconType="duration" />,
            //   component: (
            //     <VSAdHourOfDay
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7}
            //     />
            //   ),
            //   noSpace: true,
            // },
          ]}
        />
      )}
    </LookDrawer>
  );
}

export default DetailView;
