import { Button, Col, Row, Skeleton } from "antd";
import useUploadFiles from "components/Launch/Creatives/CreativeUpload/useUploadFiles";
import cx from "cx";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React, { Fragment, useRef, useState } from "react";
import {
  CREATIVE_MIME_TYPE_IMAGE,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";
import { useEffect } from "react";
import MediaItem from "../../Category/CreativeBuilder/MediaItem";
import {
  MOODBOARD_TEMPLATE_TYPE_REQUEST,
  STATUS_ASSIGNED,
  STATUS_ASSIGNED_CD,
  STATUS_CLOSED,
  STATUS_DONE,
  STATUS_DONE_CD,
  STATUS_IN_PROGRESS_GD,
  STATUS_NEEDS_CHANGES_GD,
  STATUS_SUBMITTED,
  STATUS_SUBMITTED_CD,
  STATUS_SUBMITTED_GD,
} from "../../creativesEnum";
import ActionIconRender from "components/ActionIconRender";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { GET_BLOB_FOR_CREATIVES } from "shared/gql/gqlSchema/umsGql";
import { useLookModal } from "components/LookModal";
import TagModal from "components/Launch/Creatives/CreativeUpload/TagModal";
import Masonry from "react-masonry-css";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import {
  MEDIA_GET_IMAGE_CLIPS,
  MEDIA_GET_VIDEO_CLIPS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import DeleteModal from "../DeleteModal";
function MediaUpload(props) {
  const {
    creativeType,
    addApis,
    getApis,
    deleteApis,
    isRequestView = false,
    onCloseDrawer = () => {},
    handleSubmit = () => {},
    setTotalCount = () => {},
    requestData = {},
    isGraphicDesigner = false,
    setSelectedRowsData = () => {},
    splitResult,
    status,
    setMedia = () => {},
    media,
    mediaIndex,
    mediaName,
    mediaDuration,
    isCreativeBuilder,
    isCreativeDirector,
    isMediaBuyer,
    currentPage,
    pageSize,
    isMediaLibrary_cb = false,
    creativesImages = [],
    setCreativesImages = () => {},
    createdByMe = false,
    setRowProps = () => {},
    requestType,
    isMediaLibrary = false,
    isSearch,
    searchItem,
    searchMode = "",
    setIsSearch = () => {},
    setSearchItem = () => {},
    isMediaLibraryMain = false,
    isMediaDrawer = false,
    modalUploadData = [],
    setModalUploadData = () => {},
    refetchTrigger = "",
    dateRange = [],
    refetchFlag,
    creativeBuilderV2 = false,
    setMainJson = () => {},
    finalCreativesData = [],
    visuals = [],
    setVisuals = () => {},
    isCreativeBuilderVisual = false,
    creativeByVisual = false,
    mediaRefs,
  } = props;
  const { categoryId = "", productId = "", categoryName = "" } = requestData;
  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 4 }, () => 10));
  const [selectedImages, setSelectedImages] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [mimeType, setMimeType] = useState();
  const [tagsData, setTagsData] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  function selectedRowsClear() {
    setSelectedImages([]);
  }

  const [ctAddMedia, { loading: loading_Add, refetch: creativesTemplate_refetch }] =
    useCustomMutation(addApis, {
      onCompleted: (e) => {
        const element =
          creativeType === CREATIVE_TYPE_IMAGE
            ? isRequestView
              ? e?.ctAddCreativeImages
              : e?.ctAddImageClip
            : isRequestView
            ? e?.ctAddCreativeVideos
            : e?.ctAddVideoClip;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          if (isRequestView) {
            let creativeurls = [];
            const addedCreatives = element?.data?.[0]?.addedCreatives;
            const alreadyPresentCreatives = element?.data?.[0]?.alreadyPresentCreatives;
            if (creativeType === CREATIVE_TYPE_IMAGE) {
              if (addedCreatives?.length > 0) {
                creativeurls = addedCreatives.flatMap((ele) => ele?.id);
                // Now you can use the creativeurls array as needed
              }
              if (alreadyPresentCreatives?.length > 0) {
                creativeurls.push(
                  ...(element?.data?.[0]?.alreadyPresentCreatives?.flatMap((ele) => ele?.id) || [])
                );
              }
            } else {
              creativeurls = element?.data?.map((video) => video.id);
            }
            const dataToSubmit =
              creativeType === CREATIVE_TYPE_IMAGE
                ? { imageIds: creativeurls }
                : { videoIds: creativeurls };
            setSelectedRowsData((prevItems) => ({ ...prevItems, ...dataToSubmit }));
            handleSubmit({ ...requestData, ...dataToSubmit });
          }

          setCreativesImages((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems.push(...element?.data);
            return updatedItems;
          });
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  function base64ToImageUrl(base64String) {
    return `data:${mimeType};base64,${base64String}`;
  }
  function downloadFile(url, filename) {
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", filename);

    // Append to the HTML document
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
  const [getBlob, { loading: loading_blob }] = useCustomMutation(GET_BLOB_FOR_CREATIVES, {
    onCompleted: (e) => {
      const element = e?.getBlob;
      if (element) {
        for (let i = 0; i < element.length; i++) {
          const blob = element[i]?.blob;
          const imageUrl = base64ToImageUrl(blob, mimeType);
          downloadFile(
            imageUrl,
            `lookfinity_creatives.${mimeType === CREATIVE_MIME_TYPE_IMAGE ? "jpeg" : "mp4"}`
          );
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });
  async function handleClickDownload(urls, mimeType) {
    let linkData = [];
    for (const fileUrl of urls) {
      try {
        const response = await fetch(fileUrl);
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          downloadFile(
            url,
            `lookfinity_creatives.${mimeType === CREATIVE_MIME_TYPE_IMAGE ? "jpeg" : "mp4"}`
          );
        }
      } catch (error) {
        linkData.push(fileUrl);
      }
    }
    if (linkData?.length > 0) {
      setMimeType(mimeType);
      getBlob({ variables: { urls: linkData } });
    }
  }

  const [mediaDelete, { loading: deleteLoader }] = useCustomMutation(deleteApis, {
    onCompleted(e) {
      creativeType === CREATIVE_TYPE_IMAGE ? refetch_GET_IMAGE_CLIPS() : refetch_GET_VIDEO_CLIPS();
      const element = e?.ctDeleteVideoClip?.data;
      if (
        e?.ctDeleteImageClip?.status === "SUCCESS" ||
        e?.ctDeleteVideoClip?.status === "SUCCESS"
      ) {
        if (creativeType === CREATIVE_TYPE_VIDEO) {
          setCreativesImages((prev) => {
            return prev.filter((data) => !element?.includes(data?.id));
          });
        }
        openNotification({ type: "success", message: "Record Deleted Success" });
        setDeleteModalVisible(false);
      } else {
        openNotification({
          type: "error",
          message: "Record Deleted Not Success",
          description: e.message,
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Deleted Not Success",
        description: e.message,
      });
    },
  });

  const bulkDelete = !isMediaLibraryMain
    ? [
        {
          option: "delete",
          title: "Delete",
          onClick: (e) => {
            onOpenDeleteModal(e?.map((img) => img?.id));
          },
        },
      ]
    : [];
  const bulkActions = [
    ...bulkDelete,
    // {
    //   option: "download",
    //   title: "Download",
    //   onClick: (e) => {
    //     handleClickDownload(
    //       e?.map((data) => data?.s3Url),
    //       CREATIVE_MIME_TYPE_IMAGE
    //     );
    //   },
    // },

    {
      option: "tagSwitch",
      title: "Edit tags",
      onClick: (e) => {
        openTagModal();
        setDeleteModalPropsData(e?.map((data) => ({ id: data?.id, tags: data?.tags || [] })));
      },
    },
  ];
  const bulkActionProps = {
    selectedRows: selectedImages,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };

  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });

  const tagDataExtractor = (id) => {
    const tagsArray = creativesImages?.filter((data) => data?.id === id)?.[0]?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };

  const getImageResponse = (imagesData) => {
    const urls =
      creativeType === CREATIVE_TYPE_IMAGE
        ? { r2Urls: imagesData.map((data) => ({ imageUrl: data?.public_url })) }
        : { r2Urls: imagesData.map(({ videoUrl }) => ({ videoUrl })) };
    const submitObj = {
      categoryId,
      categoryName,
      ...urls,
      productId,
    };
    if (isRequestView) {
      setSelectedRowsData((prevItems) => ({
        ...prevItems,
        requestData: {
          ...prevItems?.requestData,
          creatives: [
            ...(prevItems?.requestData?.creatives ? prevItems?.requestData?.creatives : []),
            ...imagesData.map((media) => ({
              r2Url: creativeType === CREATIVE_TYPE_IMAGE ? media?.public_url : media?.videoUrl,
              ...(creativeType !== CREATIVE_TYPE_IMAGE && {
                currentThumb: {
                  r2Url: media?.thumbUrl,
                },
              }),
            })),
          ],
        },
      }));
    } else {
      ctAddMedia({ variables: { ...submitObj } });
    }
  };
  const { uploadView, uploadLoading } = useUploadFiles({
    isCreativeTracking: true,
    getImageResponse,
    creativeType,
    loading: loading_Add,
    setMedia,
    setCreativesImages,
  });

  function getVideoLengths(videoURLs) {
    const videoLength = [];
    return new Promise((resolve, reject) => {
      const loadVideo = (url) => {
        return new Promise((resolve, reject) => {
          const video = document.createElement("video");
          video.preload = "metadata";
          video.onloadedmetadata = () => {
            resolve(video.duration);
          };
          video.onerror = (error) => {
            reject(error);
          };
          video.src = url;
        });
      };

      // Recursive function to load videos sequentially
      const loadVideosSequentially = async (urls) => {
        if (urls.length === 0) {
          resolve(videoLength);
          return;
        }

        const { r2Url, id } = urls.shift(); // Get the first URL
        try {
          const duration = await loadVideo(r2Url); // Load the video
          videoLength?.push({ id, r2Url, duration: duration || 0 }); // Store the duration
          await loadVideosSequentially(urls); // Recursively load the rest of the URLs
        } catch (error) {
          reject(error);
        }
      };
      loadVideosSequentially([...videoURLs]); // Start loading videos sequentially
    });
  }

  const handleSelectImage = async (id, index) => {
    if (creativeBuilderV2) {
      const creativeBuilderSelectedV2 = finalCreativesData?.[mediaIndex]?.video
        ?.map((i) => i?.id === id)
        ?.includes(true);
      const creativeBuilderByVisualSlected = visuals?.map((i) => i?.id === id)?.includes(true);
      if (isCreativeBuilderVisual && creativeBuilderByVisualSlected) {
        const filteredVisuals = visuals?.filter((item) => item.id != id);
        setVisuals(filteredVisuals);

        const filterdMainJson = finalCreativesData?.map((item) => ({
          ...item,
          video: item?.video?.filter((item) => item?.id != id),
        }));
        setMainJson(filterdMainJson);
      } else if (creativeBuilderSelectedV2) {
        setMainJson((prevMedia) => {
          const updatedMedia = [...prevMedia];
          const mediaIndexItems = updatedMedia[mediaIndex]?.video || [];
          updatedMedia[mediaIndex] = {
            ...updatedMedia[mediaIndex],
            video: mediaIndexItems.filter((img) => img?.id !== id),
          };
          return [...updatedMedia];
        });
      } else {
        if (isCreativeBuilderVisual) {
          const video = [
            {
              id: creativesImages.filter((img) => img?.id === id)[0]?.id,
              r2Url: creativesImages.filter((img) => img?.id === id)[0]?.r2Url,
              duration: 0,
            },
          ];
          const videoWithDuration = await getVideoLengths(video);
          setVisuals(() => {
            const prevElement = [...visuals];

            const updatedElements = [...prevElement, ...videoWithDuration];
            return updatedElements;
          });

          const newUpdatedElement = finalCreativesData?.map((item) => ({
            ...item,
            video: [...item.video, ...videoWithDuration],
          }));
          setMainJson(newUpdatedElement);
        } else {
          const video = [
            {
              id: creativesImages.filter((img) => img?.id === id)[0]?.id,
              r2Url: creativesImages.filter((img) => img?.id === id)[0]?.r2Url,
              duration: 0,
            },
          ];
          const videoWithDuration = await getVideoLengths(video);
          setMainJson((pre) => {
            let temp = JSON.parse(JSON.stringify(pre));
            temp[mediaIndex] = {
              ...temp[mediaIndex],
              video: [...temp?.[mediaIndex]?.video, ...videoWithDuration],
            };

            return temp;
          });
        }
      }
    } else {
      const isSelected = media?.[mediaIndex]?.map((i) => i?.id === id)?.includes(true);
      if (isSelected) {
        setMedia((prevMedia) => {
          const updatedMedia = [...prevMedia];
          const mediaIndexItems = updatedMedia[mediaIndex] || [];
          updatedMedia[mediaIndex] = mediaIndexItems.filter((img) => img?.id !== id);
          return updatedMedia;
        });

        setRowProps((rowProps) => {
          const updatedMedia = [...rowProps.media];
          const mediaIndexItems = updatedMedia[mediaIndex] || [];
          updatedMedia[mediaIndex] = mediaIndexItems.filter((img) => img?.id !== id);
          return { ...rowProps, media: updatedMedia };
        });
      } else {
        setMedia((prevMedia) => {
          const updatedMedia = [...prevMedia];
          const mediaIndexItems = updatedMedia[mediaIndex] || [];
          updatedMedia[mediaIndex] = [
            ...mediaIndexItems,
            {
              id: creativesImages.filter((img) => img?.id === id)[0]?.id,
              urlData: creativesImages.filter((img) => img?.id === id)[0]?.r2Url,
              type: creativeType.toLowerCase(),
              name: mediaName,
              duration: mediaDuration,
            },
          ];
          return updatedMedia;
        });

        setRowProps((rowProps) => {
          const updatedMedia = [...rowProps?.media];
          const mediaIndexItems = updatedMedia[mediaIndex] || [];
          updatedMedia[mediaIndex] = [
            ...mediaIndexItems,
            {
              id: creativesImages.filter((img) => img?.id === id)[0]?.id,
              s3Url: creativesImages.filter((img) => img?.id === id)[0]?.r2Url,
              type: creativeType.toLowerCase(),
            },
          ];
          return { ...rowProps, media: updatedMedia };
        });
      }
    }
  };

  const handleMediaSelect = (id) => {
    if (selectedImages?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedImages(selectedImages?.filter((img) => img?.id !== id));
      // data = selectedImages?.filter((img) => img?.id !== id);
    } else {
      setSelectedImages([
        ...selectedImages,
        {
          id: finalImages.filter((img) => img?.id === id)[0]?.id,
          s3Url: finalImages.filter((img) => img?.id === id)[0]?.r2Url,
        },
      ]);
    }
  };

  function handleMedia() {
    const variables = {
      categoryId,
      categoryName,
      ...(creativeType === CREATIVE_TYPE_IMAGE && {
        images: requestData?.creatives?.map((data) => data?.r2Url),
      }),
      ...(creativeType === CREATIVE_TYPE_VIDEO && {
        videos: requestData?.creatives?.map(({ r2Url, currentThumb }) => ({
          videoUrl: r2Url,
          thumbUrl: currentThumb?.r2Url,
        })),
      }),
      productId,
    };

    ctAddMedia({ variables });
  }

  const handleOnCompleteData = (e) => {
    const isImage = creativeType === CREATIVE_TYPE_IMAGE;
    const element = isImage ? e?.ctGetImageClips : e?.ctGetVideoClips;
    if (element?.status === "SUCCESS") {
      setCreativesImages(element?.data);
      setIsSearch(false);
      setSearchItem([]);
      setTotalCount(element?.pageInfo?.totalRecords);
    } else if (element?.status === "FAILURE") {
      openNotification({ type: "error", message: element?.message, key: "error" });
      setIsSearch(false);
    }
  };

  const getDataVariables =
    isMediaLibraryMain && !isMediaDrawer
      ? {
          page: currentPage,
          size: pageSize,
          isGlobal: true,
          createdByMe,
          toDate: dateRange[1],
          fromDate: dateRange[0],
        }
      : {
          categoryId,
          productId,
          page: currentPage,
          size: pageSize,
          createdByMe,
          toDate: dateRange[1],
          fromDate: dateRange[0],
        };
  const [
    fetchImages,
    {
      loading: loading_GET_IMAGE_CLIPS,
      error: error_GET_IMAGE_CLIPS,
      refetch: refetch_GET_IMAGE_CLIPS,
    },
  ] = useCustomLazyQuery(MEDIA_GET_IMAGE_CLIPS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: handleOnCompleteData,
    onError(e) {
      openNotification({ type: "error", message: e?.message });
      setIsSearch(false);
    },
    variables: { ...getDataVariables },
  });

  const [
    fetchVideos,
    {
      loading: loading_GET_VIDEO_CLIPS,
      error: error_GET_VIDEO_CLIPS,
      refetch: refetch_GET_VIDEO_CLIPS,
    },
  ] = useCustomLazyQuery(MEDIA_GET_VIDEO_CLIPS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ctGetVideoClips;
      if (element?.status === "SUCCESS") {
        setCreativesImages(element?.data);
        setIsSearch(false);
        setSearchItem([]);
        setTotalCount(element?.pageInfo?.totalRecords);
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
        setIsSearch(false);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
      setIsSearch(false);
    },
    variables: { ...getDataVariables },
  });
  useEffect(() => {
    if (!isRequestView && isSearch) {
      const isImage = creativeType === CREATIVE_TYPE_IMAGE;
      const queryVariables = {
        ...(searchMode !== "tags" ? { search: searchItem } : { tags: searchItem }),
      };
      const fetchAPiMethod = isImage ? fetchImages : fetchVideos;
      fetchAPiMethod({
        variables: { ...queryVariables },
      });
    }
  }, [searchMode, isSearch, searchItem]);

  useEffect(() => {
    const isImage = creativeType === CREATIVE_TYPE_IMAGE;
    const fetchAPiMethod = isImage ? fetchImages : fetchVideos;
    fetchAPiMethod();
  }, [creativeType, refetchTrigger, refetchFlag]);

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const onSubmitDeleteModal = () => {
    if (isCreativeBuilder || isMediaLibrary) {
      mediaDelete({
        variables: {
          _ids: deleteModalPropsData,
          categoryId,
          productId,
          forceDelete: false,
        },
      });
    } else if (isMediaLibraryMain) {
      mediaDelete({
        variables: {
          _ids: selectedCategory?.id,
          categoryId: selectedCategory?.productCategoryId,
          productId: selectedCategory?.productId,
          forceDelete: false,
        },
      });
    } else {
      setSelectedRowsData((prevItems) => ({
        ...prevItems,
        requestData: {
          ...prevItems?.requestData,
          creatives: prevItems?.requestData?.creatives?.filter(
            (url, index) => !deleteModalPropsData?.includes(index + 1)
          ),
        },
      }));
      setSelectedImages([]);
      setDeleteModalVisible(false);
    }
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setSelectedCategory({});
    setDeleteModalVisible(false);
  };

  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
    categoriesList: deleteModalPropsData,
    setSelectedCategory: setSelectedCategory,
    customView: isMediaLibraryMain ? true : false,
    selectedCategory: selectedCategory,
  };

  const finalImages = isRequestView
    ? requestData?.creatives?.map((data, index) => ({ ...data, id: index + 1 }))
    : creativesImages;
  const canEditStatus = [
    STATUS_SUBMITTED_GD,
    STATUS_ASSIGNED_CD,
    STATUS_IN_PROGRESS_GD,
    STATUS_NEEDS_CHANGES_GD,
  ];
  function handleDisableBtn() {
    if (isCreativeDirector) {
      return (
        splitResult === STATUS_DONE_CD ||
        splitResult === STATUS_CLOSED ||
        status?.toUpperCase() === STATUS_ASSIGNED
      );
    } else if (isGraphicDesigner) {
      return (
        splitResult === STATUS_DONE_CD ||
        splitResult === STATUS_DONE ||
        splitResult === STATUS_CLOSED ||
        status?.toUpperCase() === STATUS_SUBMITTED
      );
    } else if (isMediaBuyer) {
      return splitResult === STATUS_CLOSED;
    } else {
      return false;
    }
  }

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  const skeletonView = (skeletonViewProps = {}) => {
    const { withCol = false } = skeletonViewProps;

    const Wrapper = withCol ? Col : Fragment;
    const wrapperProps = withCol ? { span: 6 } : {};

    return skeletonSize?.map((e) => (
      <Wrapper {...wrapperProps}>
        <div className={cx("creative-image-selection ant-image-ration-1-1")}>
          <Skeleton.Image
            className="w-100"
            style={{ width: "100%", height: "235px" }}
            active={true}
          />
        </div>
      </Wrapper>
    ));
  };

  return (
    <>
      <div
        className={cx(
          { "creative-builder-media-modal": creativeByVisual },
          { "": !creativeByVisual }
        )}
      >
        <div className="content-view masonry-content">
          {visilbleTagModal &&
            tagModal(
              <TagModal
                variables={{
                  productId,
                  categoryId,
                  categoryName,
                }}
                files={deleteModalPropsData}
                creatives_refetch={
                  creativeType === CREATIVE_TYPE_IMAGE
                    ? refetch_GET_IMAGE_CLIPS
                    : refetch_GET_VIDEO_CLIPS
                }
                setTags={setTags}
                tags={tags}
                tagsData={tagsData}
                setTagsData={setTagsData}
                closeTagModal={closeTagModal}
                selectedRowsClear={selectedRowsClear}
                creativeType={creativeType === CREATIVE_TYPE_IMAGE ? "IMAGE_CLIP" : "VIDEO_CLIP"}
              />
            )}
          <LookTableBulkAction {...bulkActionProps} label={`Selected ${creativeType}`} />
          <>
            {loading_Add || loading_GET_VIDEO_CLIPS || loading_GET_IMAGE_CLIPS || uploadLoading ? (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                columnClassName="my-masonry-grid_column"
              >
                {skeletonView()}
              </Masonry>
            ) : (
              <></>
            )}
            {(finalImages?.length === 0 || finalImages === undefined) &&
            (!isGraphicDesigner || !isRequestView) &&
            !loading_Add &&
            (!loading_GET_VIDEO_CLIPS || !loading_GET_IMAGE_CLIPS) &&
            !isMediaLibrary &&
            !isCreativeBuilder ? (
              <div className="text-center margin-0-auto" style={{ height: "240px" }}>
                <div style={{ transform: "translateX(20px)" }}>
                  <ActionIconRender iconType="tablePlaceholder" />
                </div>
                <p style={{ fontSize: "1.5rem" }}>
                  <b>No Media Found...</b>
                </p>
              </div>
            ) : (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                columnClassName="my-masonry-grid_column"
              >
                {finalImages?.map((data, index) => {
                  const selectedImg =
                    isCreativeBuilderVisual && creativeBuilderV2
                      ? visuals?.filter((img) => img.id === data?.id)
                      : creativeBuilderV2
                      ? finalCreativesData?.[mediaIndex]?.video?.filter(
                          (item) => item.id === data?.id
                        )
                      : isCreativeBuilder
                      ? media?.[mediaIndex]?.filter((img) => img.id === data?.id)
                      : selectedImages?.filter((img) => img.id === data?.id);
                  return (
                    <MediaItem
                      // {...data}
                      ref={(ref) => (mediaRefs.current[index] = ref)}
                      media-index={data?.id}
                      creativeType={creativeType}
                      handleClickDownload={handleClickDownload}
                      isRequestView={isRequestView}
                      isMediaLibrary_cb={isMediaLibrary_cb}
                      tags={tags}
                      tagDataExtractor={tagDataExtractor}
                      setTags={setTags}
                      openTagModal={openTagModal}
                      setDeleteModalPropsData={setDeleteModalPropsData}
                      creative={{
                        id: data?.id,
                        thumbUrl: data?.currentThumb?.r2Url,
                        url: data?.r2Url,
                        tags: data?.tags,
                        categoryId: data?.categoryId,
                      }}
                      canEditStatus={canEditStatus?.includes(splitResult) && isGraphicDesigner}
                      onOpenDeleteModal={onOpenDeleteModal}
                      // onClick={() => {
                      //   isCreativeBuilder ? handleSelectImage(data?.id) : handleMediaSelect(data?.id);
                      // }}
                      selected={selectedImg?.length > 0}
                      isCreativeBuilder={isCreativeBuilder}
                      selectedImgData={data}
                      selectedData={media?.[mediaIndex] || finalCreativesData?.[mediaIndex]?.video}
                      isMediaLibraryMain={isMediaLibraryMain}
                      selectedDataId={data?.id}
                      handleSelectImage={handleSelectImage}
                      handleMediaSelect={handleMediaSelect}
                      modalUploadData={modalUploadData}
                      setModalUploadData={setModalUploadData}
                      name={data?.name}
                      finalCreativesData={finalCreativesData}
                      creativeBuilderV2={creativeBuilderV2}
                      setMainJson={setMainJson}
                    />
                  );
                })}
              </Masonry>
            )}
          </>
        </div>
      </div>

      {isRequestView && status !== STATUS_CLOSED && (
        <div className="drawer-footer-btn-content">
          <div className="flex-grow-1">
            <Row gutter={12}>
              <Col span={12}>
                <Button
                  htmlType="cancel"
                  block
                  type="default"
                  className="submit-btn"
                  onClick={onCloseDrawer}
                  disabled={uploadLoading}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={() =>
                    splitResult === STATUS_SUBMITTED_CD &&
                    requestType !== MOODBOARD_TEMPLATE_TYPE_REQUEST
                      ? handleMedia()
                      : handleSubmit({ ...requestData, status })
                  }
                  disabled={handleDisableBtn() || uploadLoading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <DeleteModal {...deleteModalProps} />
    </>
  );
}

export default MediaUpload;
