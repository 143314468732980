import ActionIconRender from "components/ActionIconRender";
import {
  ADLIB_VIEW_CARD,
  ADLIB_VIEW_COMPETITOR,
  ADLIB_VIEW_DOMAIN,
  ADLIB_VIEW_KEYWORD,
  ADLIB_VIEW_LANDING_PAGE,
  ADLIB_VIEW_PAGE,
} from "shared/UserPermission/userPermissionList";

const adLibraryPath = [
  {
    name: "Ad Library",
    link: "/ad-library",
    icon: (e) => <ActionIconRender {...e} iconType="font-colors" />,
    subMenu: [
      {
        subMenuName: "Pages",
        subMenuLink: "/pages",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: ADLIB_VIEW_PAGE,
      },
      {
        subMenuName: "Domains",
        subMenuLink: "/domains",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: ADLIB_VIEW_DOMAIN,
      },
      {
        subMenuName: "Keywords",
        subMenuLink: "/keywords",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: ADLIB_VIEW_KEYWORD,
      },
      {
        subMenuName: "Creatives",
        subMenuLink: "/creatives",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: ADLIB_VIEW_CARD,
      },
      {
        subMenuName: "Competiters",
        subMenuLink: "/competiters",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: ADLIB_VIEW_COMPETITOR,
      },
      {
        subMenuName: "Landing Pages",
        subMenuLink: "/landing-pages",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: ADLIB_VIEW_LANDING_PAGE,
      },
    ],
  },
];

export default adLibraryPath;

// () => <ActionIconRender iconType="team" />
