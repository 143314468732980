export const defaultFilterColumns = [
  {
    label: "CPV",
    column: "CPV",
    filterOptions: [
      {
        label: "Above $0.50",
        filterType: ">",
        value: "0.50",
      },
      {
        label: "Under $0.50",
        filterType: "<",
        value: "0.50",
      },
      {
        label: "Under $0.30",
        filterType: "<",
        value: "0.30",
      },
      {
        label: "Under $0.15",
        filterType: "<",
        value: "0.15",
      },
    ],
  },

  {
    label: "CPM",
    column: "CPM",
    filterOptions: [
      {
        label: "High CPM (> $20)",
        filterType: ">",
        value: "20",
      },
      {
        label: "Low CPM (< $20)",
        filterType: "<",
        value: "10",
      },
    ],
  },
  {
    label: "ROI",
    column: "ROI",
    filterOptions: [
      {
        label: "Less than 0",
        filterType: "<",
        value: "0",
      },
      {
        label: ">= 0 and <20",
        filterType: "range",
        rangeMin: "0",
        rangeMax: "20",
      },
      {
        label: ">=20 and <50",
        filterType: "range",
        rangeMin: "20",
        rangeMax: "50",
      },
      {
        label: "Above 50",
        filterType: ">",
        value: "50",
      },
    ],
  },
  {
    label: "Status",
    column: "status",
    filterOptions: [
      {
        label: "Enable",
        filterType: "includeSearch",
        value: ["enable"],
      },
      {
        label: "Limit",
        filterType: "includeSearch",
        value: ["limit"],
      },
      {
        label: "Disable",
        filterType: "includeSearch",
        value: ["disable"],
      },
    ],
  },
];
