import axios from "axios";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useRef, useState } from "react";
import { S3_MULTI_FILE, S3_MULTI_FILE_UPLOAD } from "shared/gql/gqlSchema/mediaGql";
import { openNotification } from "utils";
import { PlusOutlined } from "@ant-design/icons";
import { SERVICE_TYPE_SAS } from "shared/enum/launchKeysAndEnum";
import ActionIconRender from "components/ActionIconRender";
import { PaperClipOutlined } from "@ant-design/icons";
import { CREATIVE_MIME_TYPE_DOCUMENT } from "shared/SharedKeys/launchKeys";

export default function useUploadDocument(props = {}) {
  const {
    name = "",
    // serviceType = "DOCUMENT",
    disabled = false,
    getDocumentResponse = () => {},
    loading = false,
    actionType,
    handleClickDownload = () => {},
    setDocuments = () => {},
    document = [],
    setFileNames = () => {},
    isDocumentUploader = false,
  } = props;

  const [uploadingDocumentLoader, setUploadDocumentLoader] = useState(false);
  const fileInputRef = useRef(null);
  const [
    s3MultiFile,
    {
      data: data_S3_MULTI_FILE = [],
      loading: loading_S3_MULTI_FILE,
      error: error_S3_MULTI_FILE,
      refetch: refetch_S3_MULTI_FILE,
    },
  ] = useCustomLazyQuery(S3_MULTI_FILE, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (e) => {
      // setDocument("");
      const newReponse = e?.s3MultiFile.map((item, index) => ({
        ...item,
        file_name: "dummytext" + index + 1,
      }));
      getDocumentResponse(newReponse);
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [s3MultiFileUpload, { loading: loading_S3_MULTI_FILE_UPLOAD }] = useCustomMutation(
    S3_MULTI_FILE_UPLOAD,
    {
      onCompleted(e) {
        setUploadDocumentLoader(true);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  const s3FinalUploadedData = (uploadPaths) => {
    return new Promise((resolve, reject) => {
      s3MultiFile({ variables: { path: uploadPaths } })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  async function fileUploading(fileData, selectedFile) {
    const requestPromise = [];
    const uploadPaths = [];

    fileData?.forEach(({ url, path }, index) => {
      uploadPaths.push(path);

      var config = {
        method: "put",
        url: url,
        headers: { "Content-Type": "" },
        data: selectedFile[index],
      };

      requestPromise.push(
        axios(config)
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            openNotification({ type: "error", message: error.message });
          })
      );
    });

    return await Promise.all(requestPromise)
      .then(async (e) => {
        setUploadDocumentLoader(false);
        const finalData = await s3FinalUploadedData(uploadPaths);
        return finalData;
      })
      .catch((e) => {
        setUploadDocumentLoader(false);
      });
  }

  function shortenFileName(filename, maxLength) {
    if (filename?.length < maxLength) {
      return filename;
    } else {
      const fileExtension = filename?.split(".").pop();
      return filename?.substring(0, maxLength) + " ... ." + fileExtension;
    }
  }

  async function uploadDocument(selectedDocumentFiles) {
    try {
      const fileNames = Array.from(selectedDocumentFiles).map((file) => file.name);
      const fileUrlData = await s3FileUpload(fileNames);
      setFileNames((prev) => [...prev, ...fileNames]);
      const links = [...fileUrlData?.data?.s3MultiFileUpload];
      // const fileData = Array.from(selectedDocumentFiles);

      const fileUploaded = await fileUploading(links, selectedDocumentFiles);
      return fileUploaded?.data?.s3MultiFile;
    } catch (e) {
      console.log({ e });
    }
  }

  const s3FileUpload = (fileNames) => {
    return new Promise((resolve, reject) => {
      s3MultiFileUpload({
        variables: {
          file_name: fileNames,
          service_name: SERVICE_TYPE_SAS,
          category: name,
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleUpload = async (e) => {
    const files = e.target.files;
    if (files?.length === 0) {
      return null;
    }

    const data = await uploadDocument(files);

    if (data) {
      setDocuments((prev) => [...data, ...prev]);
    }
    if (isDocumentUploader) {
      fileInputRef.current.value = null;
    }
  };

  const commonLoading =
    loading || loading_S3_MULTI_FILE_UPLOAD || uploadingDocumentLoader || loading_S3_MULTI_FILE;

  const documentInputProps = {
    accept:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  };

  return {
    uploadView: () => (
      <div style={{ height: "100%" }}>
        <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
          <div className="d-flex" style={{ alignItems: "center" }}>
            <div className="mr-20" style={{ fontWeight: "600" }}>
              {actionType === "REQUESTFORM" ? "Attachments" : "Compliance Document"}
            </div>
            <div
              className="d-flex px-16"
              style={{
                alignItems: "center",
                border: "1px solid #595959",
                width: "99px",
                height: "32px",
              }}
            >
              <div className="mr-8">
                <PlusOutlined />
              </div>
              <div>Upload </div>
              <input
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                disabled={commonLoading || disabled}
                ref={isDocumentUploader ? fileInputRef : undefined}
                required
                multiple
                onChange={(event) => {
                  handleUpload(event);
                }}
                {...documentInputProps}
              />
            </div>
          </div>
        </label>

        {commonLoading && (
          <div class="progress" style={{ width: "260px" }}>
            <div class="indeterminate"></div>
          </div>
        )}

        {document?.map(({ url, path }) => {
          return (
            <div
              className="d-flex mt-10"
              style={{
                width: "320px",
                padding: "6px 12px",
                background: " #404040",
                borderRadius: "3px",
                justifyContent: "space-between",
              }}
            >
              <div>
                <PaperClipOutlined className="mr-8" />
                {shortenFileName(path, 35)}
              </div>
              <div className="d-flex justify-content-between mt-5" style={{ width: "35px" }}>
                <ActionIconRender
                  iconType="download"
                  onClick={() => handleClickDownload([url], CREATIVE_MIME_TYPE_DOCUMENT)}
                />
                <ActionIconRender
                  iconType="delete"
                  onClick={() =>
                    setDocuments((prevItems) => {
                      const updatedItems = [...prevItems];
                      const newItems = updatedItems?.filter((item) => item?.url !== url);
                      return newItems;
                    })
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    ),
  };
}
