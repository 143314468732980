import { Button, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";

export default function LookDrawerHeaderContent(props) {
  const { title, icon: Icon, extraContent = "" } = props;

  return (
    <div
      className={cx("look-drawer-header-content flex-between", { "extra-content": !extraContent })}
    >
      <div className="w-100">
        {Icon && <Icon className="mr-8" />}
        {title && title}
      </div>
      {extraContent && <div className="mr-10">{extraContent}</div>}
      {/* <div>
        <Tooltip placement="left" title="help">
          <Button
            type="primary"
            className="help-btn"
            icon={<ActionIconRender iconType="question" />}
          />
        </Tooltip>
      </div> */}
    </div>
  );
}
