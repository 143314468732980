import { Checkbox } from "antd";
import { useLaunchContext } from "components/LaunchView/Context";
import cx from "cx";
import { useLaunchDispatchHooks } from "hooks/dispatchHooks";

export default function PlacemetFields(props) {
  const { launchType } = useLaunchContext();
  const { targeting = {}, setTargeting } = useLaunchDispatchHooks(launchType);

  const { placementKey, titleField, optionData = [] } = props;

  const { [placementKey]: value = [] } = targeting || {};

  if (placementKey === undefined) return null;
  return (
    <div className="mb-22">
      <div className={cx({ "pl-24": false })}>
        <Checkbox onChange>
          <strong>{titleField}</strong>
        </Checkbox>
      </div>
      <div className="pl-8">
        <Checkbox.Group
          value={value}
          options={optionData.map(({ name, value, ...rest }) => ({ label: name, value, ...rest }))}
          onChange={(e) => {
            setTargeting(placementKey, e);
          }}
        />
      </div>
    </div>
  );
}
