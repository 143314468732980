import { Tag } from "antd";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { TAG_DELETE_TAGS, TAG_GET_IDENTIFIER_TAG } from "shared/gql/gqlSchema/tagsGql";

export default function VSTags(props) {
  const { data: tag_data } = useCustomQuery(TAG_GET_IDENTIFIER_TAG, {
    variables: {
      identifier: props?.utmCampaign,
      type: "vs",
    },
  });
  const [deleteTag, { data: tag_updated_data = [] }] = useCustomMutation(TAG_DELETE_TAGS);
  const tags = tag_data?.tag_getTags;
  const onClose = (el) => {
    deleteTag({
      variables: {
        id: tags.id,
        tags: el,
      },
    });
  };
  return (
    <div>
      <h2>Tags</h2>
      <div>
        {tags?.tags?.length === 0 ? (
          <h4>No Tags Added</h4>
        ) : (
          tags?.tags?.map((el) => {
            return (
              <Tag
                color="#13B044"
                key={el}
                onClose={() => {
                  onClose(el);
                }}
                closable={true}
              >
                {el}
              </Tag>
            );
          })
        )}
      </div>
    </div>
  );
}
