import * as types from "../actions/actionTypes";

export default function cmsReducer(state = {}, action) {
  switch (action.type) {
    case types.CMS_DATA:
      return { ...state, cmsData: action.payload };
    case types.CMS_SETTINGS:
      return { ...state, cmsSettings: action.payload };
    case types.CMS_IMAGE_RESIZE_LIST:
      return { ...state, cmsImageResizeList: action.payload };
    case types.CMS_ARTICLE_SETTINGS:
      return { ...state, cmsArticleSettings: action.payload };
    case types.CMS_ARTICLE_SETTINGS_CLEAR:
      return { ...state, cmsArticleSettings: {} };
    case types.CMS_SETTINGS_CLEAR:
      return {
        ...state,
        cmsData: undefined,
        cmsSettings: undefined,
        cmsImageResizeList: undefined,
      };
    default:
      return state;
  }
}
