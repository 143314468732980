import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { GoogleLaunchV1Context } from "./Context";
import MainView from "./MainView";
import { useEffect, useState } from "react";
import { deepClone, openNotification, removeObjectByIndex } from "utils";
import DebugPre from "components/DebugPre";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { G360_BULK_LAUNCH } from "shared/gql/gqlSchema/googleLaunch";
import { googleLaunchManualBiddingStrategy, googleLaunchMatchTypeData } from "./googleLaunchConfig";
import { CONVERSION_ACTION_TYPE_ENUM, GL_STATUS_PAUSED } from "shared/enum/launchKeysAndEnum";
import { useHistory } from "react-router-dom";

export default function GoogleLaunchV1(props) {
  const { serviceName, serviceType, productId, serviceRoute } = props;
  const defaultCampaignObject = [
    {
      name: "",
      status: GL_STATUS_PAUSED,
      conversionType: CONVERSION_ACTION_TYPE_ENUM[0].value,
      adGroups: [{ name: "", status: GL_STATUS_PAUSED, ads: [] }],
      amountMicros: 0,
      enhancedCpcEnabled: false,
      bids: 0,
      biddingStrategy: googleLaunchManualBiddingStrategy,
    },
  ];

  const [adAccountObject, setAdAccountObject] = useState({});
  const [imageArray, setImageArray] = useState([]);
  const defaultTargetingObject = {
    languageConstant: [],
    locationInfoGeoTargetConstant: [],
    negativeKeywords: [],
  };
  const [matchType, setMatchType] = useState(googleLaunchMatchTypeData[0].value);
  const [keywords, setKeywords] = useState([]);
  const [launchName, setLaunchName] = useState("");
  const [trackingUrl, setTrackingUrl] = useState("");
  const [negativeKeywords, setNegativeKeywords] = useState([]);
  const [campaignObject, setCampaignObject] = useState(defaultCampaignObject); // main campaign object state
  const [targetingObject, setTargetingObject] = useState(defaultTargetingObject);

  const { googleLaunchConfigFetch, googleLaunchCountriesFetch, googleLaunchLanguagesFetch } =
    useApiToStoreContext();
  const history = useHistory();

  const [g360_bulk_launch, { loading: loading_g360_bulk_launch }] = useCustomMutation(
    G360_BULK_LAUNCH,
    {
      onCompleted: (e) => {
        openNotification({ type: "success", message: "Launch successful" });
        history.push(`/${serviceRoute}/google-launch/list`);
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  useEffect(() => {
    googleLaunchConfigFetch();
    googleLaunchCountriesFetch();
    googleLaunchLanguagesFetch();
  }, []);
  const handleCampaignLevelData = (object = {}, extraProps = {}) => {
    /**
     * This function is use to update camapign level data
     * Default it update first index of campaign with default campaignIndex = 0
     * Default update first index because Google Launch V1 Support only one campaign
     *
     */

    const { campaignIndex = 0 } = extraProps;
    let temp = deepClone(campaignObject);
    temp[campaignIndex] = { ...temp[campaignIndex], ...object };
    setCampaignObject(temp);
  };

  const keywordInsert = (data) => {
    let temp = [];

    data?.map((dataItem) =>
      temp?.push({ status: GL_STATUS_PAUSED, keyword: { text: dataItem, matchType } })
    );
    return temp;
  };

  const negativeKeywordInsert = (data) => {
    let temp = [];

    data?.map((dataItem) => temp?.push({ text: dataItem, matchType }));
    return temp;
  };

  const handleTargetingLevelData = (name, value) => {
    /**
     * This function is use to update targeting level data
     * Right now as version 1 of googlelaunch supports only one launch at a time(one campaign and one adgroup)
     */
    let temp = deepClone(targetingObject);
    temp[name] = value;
    setTargetingObject(temp);
  };

  const handleAdgroupLevelData = (object = {}, extraProps = {}) => {
    /**
     * This function is use to update AdGroup level data
     * Default it update first index of campaign/adgroup with default campaignIndex = 0 and adGroupsIndex = 0
     * Default update first index because Google Launch V1 Support only one Campaign and 1 AdGroup
     */

    const { campaignIndex = 0, adGroupsIndex = 0 } = extraProps;
    let temp = deepClone(campaignObject);
    temp[campaignIndex].adGroups[adGroupsIndex] = {
      ...temp[campaignIndex].adGroups[adGroupsIndex],
      ...object,
    };
    setCampaignObject(temp);
  };

  const handleAdLevelData = (object = {}, extraProps = {}) => {
    /**
     * This function is use to update Ads level data
     * adIndex is compulsory
     *
     * Default it update first index of campaign/adgroup with default campaignIndex = 0 and adGroupsIndex = 0
     * Default update first index because Google Launch V1 Support only one Campaign and 1 AdGroup
     */

    const { campaignIndex = 0, adGroupsIndex = 0, adIndex } = extraProps;
    let temp = deepClone(campaignObject);
    temp[campaignIndex].adGroups[adGroupsIndex].ads[adIndex] = {
      ...temp[campaignIndex].adGroups[adGroupsIndex].ads[adIndex],
      ...object,
    };
    setCampaignObject(temp);
  };

  const pushNewAd = (object = {}, extraProps = {}) => {
    /**
     * This function is use to add new Ads
     * Default it update first index of campaign/adgroup with default campaignIndex = 0 and adGroupsIndex = 0
     * Default update first index because Google Launch V1 Support only one Campaign and 1 AdGroup
     */

    let temp = deepClone(campaignObject);
    const { campaignIndex = 0, adGroupsIndex = 0 } = extraProps;

    temp[campaignIndex].adGroups[adGroupsIndex].ads.push(object);
    setCampaignObject(temp);
  };

  const duplicateAd = (adDuplicateProps = {}) => {
    /**
     * This function is use to duplicate/copy ad
     * Default it update first index of campaign/adgroup with default campaignIndex = 0 and adGroupsIndex = 0
     * Default update first index because Google Launch V1 Support only one Campaign and 1 AdGroup
     */

    const { campaignIndex = 0, adGroupsIndex = 0, adIndex } = adDuplicateProps;
    let temp = deepClone(campaignObject);
    let copyDataData = campaignObject[campaignIndex]?.adGroups[adGroupsIndex]?.ads?.[adIndex];
    let newAdData = campaignObject[campaignIndex]?.adGroups[adGroupsIndex]?.ads.slice(
      0,
      adIndex + 1
    );
    newAdData.push(copyDataData);
    temp[campaignIndex].adGroups[adGroupsIndex].ads.slice(adIndex + 1).forEach((data) => {
      newAdData.push(data);
    });
    temp[campaignIndex].adGroups[adGroupsIndex].ads = newAdData;
    setCampaignObject(temp);
  };

  const removeAd = (removeAdProps = {}) => {
    /**
     * This function is use to remove ad
     * Default it update first index of campaign/adgroup with default campaignIndex = 0 and adGroupsIndex = 0
     * Default update first index because Google Launch V1 Support only one Campaign and 1 AdGroup
     */
    const { campaignIndex = 0, adGroupsIndex = 0, adIndex } = removeAdProps;

    let temp = deepClone(campaignObject);
    let ads = temp[campaignIndex].adGroups[adGroupsIndex].ads;

    ads = removeObjectByIndex(ads, adIndex);
    temp[campaignIndex].adGroups[adGroupsIndex].ads = ads;
    setCampaignObject(temp);
  };

  const genarateFinalCampaignObject = () => {
    /**
     * genarateFinalCampaignObject
     * It's generate final campaign object that used to submit google launch
     */

    return campaignObject?.map((item, index) => {
      const {
        amountMicros = 0,
        enhancedCpcEnabled = false,
        bids = 0,
        biddingStrategy,
        ...rest
      } = item;

      const strategy =
        biddingStrategy === googleLaunchManualBiddingStrategy
          ? { manualCpc: { enhancedCpcEnabled } }
          : { bids: { type: biddingStrategy, value: bids } };

      return {
        ...rest,
        campaignBudget: { deliveryMethod: "STANDARD", amountMicros: amountMicros * 1000000 }, //amountMicros multiplies with 1000000
        ...strategy,
        advertisingChannelType: "SEARCH", // Fixed right now
        adGroups: campaignObject?.[index]?.adGroups?.map((adGroups) => ({
          ...adGroups,

          ...(keywords && { keywords: keywordInsert(keywords) }),
          type_: "SEARCH_STANDARD", // mandatory type_, always remain same
          ads: adGroups?.ads?.map((adsContent) => {
            const { status, ...ads } = adsContent;
            return {
              status,
              ad: {
                ...(ads?.finalUrls && { finalUrls: [ads?.finalUrls] }),
                type_: "RESPONSIVE_SEARCH_AD", // mandatory type_, always remain same
                responsiveSearchAd: { descriptions: ads?.descriptions, headlines: ads?.headlines },
                ...(ads?.structuralSnippets?.length > 0 && {
                  structuralSnippets: ads?.structuralSnippets,
                }),
                ...(ads?.callouts?.length > 0 && { callouts: { callouts: ads?.callouts } }),
              },
            };
          }),
        })),
      };
    });
  };

  const submitLaunch = () => {
    /**
     * finalObject is the payload for g360_bulk_launch
     */

    const isLanguage =
      targetingObject?.languageConstant?.length > 0
        ? { languageInfo: { languageConstant: targetingObject?.languageConstant } }
        : {};

    const isNegativeKeywords =
      targetingObject?.negativeKeywords?.length > 0
        ? {
            negativeKeywords: { keyword: negativeKeywordInsert(targetingObject?.negativeKeywords) },
          }
        : {};

    const isLocationInfo =
      targetingObject?.locationInfoGeoTargetConstant?.length > 0
        ? { locationInfo: { geoTargetConstant: targetingObject?.locationInfoGeoTargetConstant } }
        : {};
    let finalObject = {
      camps: genarateFinalCampaignObject(),
      imageMedia: imageArray,
      targeting: { ...isLanguage, ...isNegativeKeywords, ...isLocationInfo },
      name: launchName,
      productId: productId,
      accountData: [
        {
          accountId: adAccountObject?.accountId,
          profileId: adAccountObject?.profileId,
          trackingUrl: [trackingUrl],
        },
      ],
    };
    g360_bulk_launch({ variables: finalObject });
  };
  /**
   * contextData contains all states and datasets
   */
  const contextData = {
    serviceName,
    serviceType,
    productId,

    campaignObject, // main campaign object state
    setCampaignObject, // main campaign object setState

    handleCampaignLevelData, // It will update camapign level object of data
    handleAdgroupLevelData, // It will update adGroups level object of data
    handleAdLevelData, // It will update ad level object of data
    handleTargetingLevelData, // it will update targeting object
    pushNewAd,
    duplicateAd,
    removeAd,

    targetingObject, // main state for targeting data object
    setTargetingObject, // main set state for targeting data object

    adAccountObject,
    setAdAccountObject,

    imageArray, // main state for image object
    setImageArray, // main set state for image object

    launchName, // main state for launch name
    setLaunchName, // main set state for launch name

    trackingUrl,
    setTrackingUrl,

    /**
     * states for targeting tab data
     */
    matchType,
    setMatchType,
    keywords,
    setKeywords,
    negativeKeywords,
    setNegativeKeywords,

    /**
     * targeting tab states end here
     */

    submitLaunch, // It's main launch submittion function
  };
  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ adAccountObject, campaignObject, targetingObject, imageArray }],
    },
  ];

  return (
    <GoogleLaunchV1Context.Provider value={contextData}>
      <DashboardHeader title="Create new campaign" />
      {/* All view render inside MainView */}

      <MainView loading_g360_bulk_launch={loading_g360_bulk_launch} />
      <DebugPre content={debugContent} />
    </GoogleLaunchV1Context.Provider>
  );
}
