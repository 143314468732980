import { Button, Checkbox, Col, Form, Row } from "antd";
import Loader from "components/loader";
import cx from "cx";
import React from "react";

function useAiHooks({
  loading_AIData,
  creativesAIData,
  setSelectedData,
  selectedData,
  creativeSelectionKey,
  loading,
  onClose,
  addData,
  label,
}) {
  return (
    <div>
      <div className="p-20">
        <h3> {`AI generated ${label}`}</h3>
        {loading_AIData ? (
          <Loader />
        ) : creativesAIData?.AI_adgroup_data?.primary_text?.length > 0 ? (
          <div>
            <Checkbox.Group
              onChange={(e) => {
                setSelectedData(e);
              }}
              value={selectedData}
              name={creativeSelectionKey}
              style={{ width: "100%" }}
            >
              {creativesAIData?.AI_adgroup_data?.primary_text?.map((body) => (
                <div className={cx("look-radio-ad", {})}>
                  <Checkbox value={body}>{body}</Checkbox>
                </div>
              ))}
            </Checkbox.Group>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item>
                  <Button block className="submit-btn" onClick={onClose}>
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    block
                    type="primary"
                    className="submit-btn"
                    // onClick={() => {
                    //   clAddBody({
                    //     variables: { ...variables, bodies: selectedData },
                    //   });
                    // }}
                    onClick={() => {
                      addData(selectedData);
                    }}
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Add"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          <>No data found...</>
        )}
      </div>
    </div>
  );
}

export default useAiHooks;
