import {
  SC_GET_ALL_DEPARTMENT_FOR_UNICORN_DROPDOWN,
  SC_GET_ALL_LOB_DROPDOWN,
  SC_GET_ALL_STRATEGIC_OUTCOMES,
  SC_GET_ALL_USERS,
  SC_GET_ALL_VERIFYING_MILESTONE_DROPDOWN,
} from "shared/gql/gqlSchema/scorecardGql";
import useApiToStoreQuery from "./useApiToStoreQuery";

export default function useUnicornApiParams() {
  const {
    apiCall: fetchAllLobs = () => {},
    refetch: refetchAllLobs,
    setDataSource: lobSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_ALL_LOB_DROPDOWN,
    elementKey: "SC_getLineOfBusiness",
    storeKey: "SC_LineOfBusiness",
  });

  const {
    apiCall: unicornVerifyingMilestonesFetch = () => {},
    refetch: unicornVerifyingMilestonesRefetch,
    setDataSource: unicornVerifyingMilestonesSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_ALL_VERIFYING_MILESTONE_DROPDOWN,
    elementKey: "SC_get_All_v_milestone",
    storeKey: "SC_VerifyingMilestones",
  });

  const {
    apiCall: unicornDepartmentsFetch = () => {},
    refetch: unicornDepartmentsRefetch,
    setDataSource: unicornDepartmentsSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_ALL_DEPARTMENT_FOR_UNICORN_DROPDOWN,
    elementKey: "SC_getAllDepartmentForUnicorn",
    storeKey: "SC_UnicornDepartments",
  });

  const {
    apiCall: unicornUsersFetch = () => {},
    refetch: unicornUsersRefetch,
    setDataSource: unicornUsersSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_ALL_USERS,
    elementKey: "SC_getAlluser",
    storeKey: "SC_UnicornUsers",
  });

  const {
    apiCall: unicornStrategicOutcomeFetch = () => {},
    refetch: unicornStrategicOutcomeRefetch,
    setDataSource: unicornStrategicOutcomeSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_ALL_STRATEGIC_OUTCOMES,
    elementKey: "SC_get_all_strategic",
    storeKey: "SC_StrategicOutcomes",
  });

  return {
    fetchAllLobs,
    refetchAllLobs,
    lobSetDataSource,

    unicornVerifyingMilestonesFetch,
    unicornVerifyingMilestonesRefetch,
    unicornVerifyingMilestonesSetDataSource,

    unicornDepartmentsFetch,
    unicornDepartmentsRefetch,
    unicornDepartmentsSetDataSource,

    unicornUsersFetch,
    unicornUsersRefetch,
    unicornUsersSetDataSource,

    unicornStrategicOutcomeFetch,
    unicornStrategicOutcomeRefetch,
    unicornStrategicOutcomeSetDataSource,
  };
}
