import RuleFields from "../RuleHandler/index";
import SelectInput from "components/forms/SelectInput";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { GQL_RE_UPDATERULE } from "shared/gql/gqlSchema/ruleGql";
import { openNotification } from "utils";
import LookTextInput from "components/forms/LookTextInput";

import { Button, Col, Form, Radio, Row, Space, Switch } from "antd";
import React, { useEffect, useState } from "react";
import ActionIconRender from "components/ActionIconRender";
import moment from "moment";
import parser from "cron-parser";
import {
  PRODUCT_ID_LEADGEN,
  PRODUCT_ID_RSOC,
  PRODUCT_ID_SAS,
  PRODUCT_ID_VS,
  REPORT_TYPE_DAILY,
  RULE_PLATFORM_FB,
  RULE_PLATFORM_TABOOLA,
  RULE_PLATFORM_TIKTOK,
  RULE_PLATFORM_VAROZI,
} from "shared/SharedKeys";
import { useSelector } from "react-redux";
import PulseRuleForm from "../PulseRules";
import formulaeEnum, { RULE_ENGINE_FORMULAE_TYPE_COMAPARISION } from "../RuleHandler/formulaeEnum";
export default function UpdateRuleForm(props) {
  const { close = () => {}, data = {}, refetch, platform, fromAllRules, productId } = props;

  const [action, setAction] = useState();
  const [period, setperiod] = useState();
  const [life, setlife] = useState("manual");
  const [name, setname] = useState();
  const [Categoryname, setCategoryname] = useState([]);
  const [Exclusionname, setExclusionname] = useState([]);
  const [timezone, settimezone] = useState();
  const [rules, setRules] = useState([]);
  const [id, setid] = useState();
  const [timeline, settimeline] = useState();
  const [reporting_days, setreporting_days] = useState();
  const [manual, setmanual] = useState();
  const [message, setmessage] = useState();
  const [servicedown, setServicedown] = useState("alert");
  const [Assetids, setAssetids] = useState([]);
  const [adAccountIds, setadAccountIds] = useState([]);
  const [orType, setorType] = useState();
  const [budget, setbudget] = useState();
  const [budget_type, setbudget_type] = useState();
  const [bid, setbid] = useState({ value: null, type: "" });
  const [reportType, setreportType] = useState();
  const [groupBy, setgroupBy] = useState();
  const [pltf, setpltf] = useState(null);
  const [nextoccurrences, setnextOccurrences] = useState([]);
  const [comparison, setComparsion] = useState([]);
  const [last_x_days, setlast_x_days] = useState();
  const [is_pulse, setis_pulse] = useState(false);
  const [exclude_pulse, setexclude_pulse] = useState(false);
  const formulae_fields = formulaeEnum(productId, RULE_ENGINE_FORMULAE_TYPE_COMAPARISION);
  const cronOption = [
    { name: "5 minutes", value: "*/5 * * * *" },
    { name: "15 minutes", value: "*/15 * * * *" },
    { name: "30 minutes", value: "*/30 * * * *" },
    { name: "1 hour", value: "0 * * * *" },
    { name: "2 hours", value: "0 */2 * * *" },
    { name: "4 hours", value: "0 */4 * * *" },
    { name: "8 hours", value: "0 */8 * * *" },
    { name: "Custom", value: "Custom" },
  ];
  const super_admin = useSelector((state) => state?.userReducer?.me?.lf_superadmin);

  useEffect(() => {
    if (data) {
      setRules(data.rules);
      setname(data.name);
      setAction(data.actions);
      for (let i of cronOption) {
        if (toString(data.period) === toString(i.value)) {
          setperiod(data.period);
          break;
        } else {
          setmanual(data.period);
          setperiod("Custom");
        }
      }
      setid(data.id);
      settimezone(data.timezone);
      settimeline(data.report_timeline);
      setmessage(data.message);
      setAssetids(data.ids);
      setCategoryname(data.category_name);
      setExclusionname(data.exclusion_name);
      setorType(data.is_orType ? data.is_orType : false);
      setbudget(data.budget);
      setbudget_type(data.budget_type);
      setreporting_days(data.reporting_days);
      setreportType(data.reportType);
      setgroupBy(data.groupBy);
      setpltf(data.platform);
      setComparsion(data.comparision);
      setlast_x_days(data.last_x_days);
      setis_pulse(data.is_pulse);
      setadAccountIds(data.ad_account_ids);
      setexclude_pulse(data.exclude_pulse);
      setbid(data.bid);
    }
  }, [data]);

  const [clupdateRule] = useCustomMutation(GQL_RE_UPDATERULE, {
    variables: {
      groupBy: groupBy,
      platform: pltf,
    },
    onCompleted: (e) => {
      const element = e?.re_updateRule;
      if (element) {
        openNotification({ type: "success", message: "Rule updated Successfully" });
        close();
        refetch();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  function updateRule() {
    if (fromAllRules && !super_admin) {
      openNotification({ type: "error", message: "This action is not allowed to you" });
      return;
    }
    let check;
    let budgetcheck;
    if (orType) {
      check = rules.some((item) => item.all?.some((i) => i.path === ""));
    } else {
      check = rules.some((item) => item.path === "");
      budgetcheck = rules.some((item) => item.path.includes("$.budget"));
    }

    if (check) {
      openNotification({ type: "error", message: "Fields are missing in rule condition" });
    } else if (budget > 0 && !budgetcheck) {
      openNotification({ type: "error", message: "Must add a budget limit in rule condition" });
    } else {
      clupdateRule({
        variables: {
          all: rules,
          name: name,
          action: action,
          cronCommand: manual ? manual : period,
          id: id,
          timezone: timezone,
          report_timeline: timeline,
          message: message,
          ids: Assetids,
          category_name: Categoryname,
          exclusion_name: Exclusionname,
          is_orType: orType,
          budget: budget,
          budget_type: budget_type,
          lifespan: life,
          reporting_days: reporting_days,
          report_type: reportType,
          groupBy: groupBy,
          comparision: comparison,
          last_x_days: last_x_days,
          ad_account_ids: adAccountIds,
          exclude_pulse: exclude_pulse,
          bid: bid,
        },
      });
    }
  }
  // this is a limit for rule name filter, 22 is the limit
  const handleName = () => {
    if (Categoryname.length < 22) {
      setCategoryname([...Categoryname, ""]);
    }
  };
  const handleNameChange = (index, value, index2) => {
    const updatedList = [...Categoryname];
    if (index2 !== undefined) {
      updatedList[index].or[index2] = value;
    } else {
      updatedList[index] = value;
    }
    setCategoryname(updatedList);
  };

  const handleNameRemove = (index, index2) => {
    const updatedList = [...Categoryname];

    if (index2 !== undefined) {
      if (updatedList[index].or.length === 2) {
        updatedList[index].or.splice(index2, 1);
        updatedList[index] = "";
      } else {
        updatedList[index].or.splice(index2, 1);
      }
    } else {
      updatedList.splice(index, 1);
    }

    setCategoryname(updatedList);
  };

  const handleComparison = () => {
    setComparsion([...comparison, { value: null, operator: "" }]);
  };

  const handleComparsionvalue = (index, e) => {
    const updatedList = [...comparison];
    updatedList[index].value = parseFloat(e);
    setComparsion(updatedList);
  };
  const handleComparsionOperation = (index, value) => {
    const updatedList = [...comparison];
    updatedList[index].operator = value;
    setComparsion(updatedList);
  };
  const handleComparisionRemove = (index) => {
    const updatedList = [...comparison];
    updatedList.splice(index, 1);
    setComparsion(updatedList);
  };
  const handleORNameChange = (index) => {
    const updatedList = [...Categoryname];
    if (updatedList[index].or && updatedList[index].or?.length < 4) {
      updatedList[index] = { or: [...updatedList[index].or, ""] };
    } else {
      updatedList[index] = { or: ["", ""] };
    }
    setCategoryname(updatedList);
  };
  const handleExc = () => {
    // if (Exclusionname.length < 16) {
    setExclusionname([...Exclusionname, ""]);
    // }
  };
  const handleExcChange = (index, value, index2) => {
    const updatedList = [...Exclusionname];
    if (index2 !== undefined) {
      updatedList[index].or[index2] = value;
    } else {
      updatedList[index] = value;
    }
    setExclusionname(updatedList);
  };

  const handleExcRemove = (index, index2) => {
    const updatedList = [...Exclusionname];

    if (index2 !== undefined) {
      if (updatedList[index].or.length === 2) {
        updatedList[index].or.splice(index2, 1);
        updatedList[index] = "";
      } else {
        updatedList[index].or.splice(index2, 1);
      }
    } else {
      updatedList.splice(index, 1);
    }

    setExclusionname(updatedList);
  };

  const handleORExcChange = (index) => {
    const updatedList = [...Exclusionname];
    if (updatedList[index].or && updatedList[index].or?.length < 4) {
      updatedList[index] = { or: [...updatedList[index].or, ""] };
    } else {
      updatedList[index] = { or: ["", ""] };
    }
    setExclusionname(updatedList);
  };
  const handleRuleAddition = () => {
    if (!orType && rules.length !== 0) {
      setorType(true);
      setRules([
        { all: [...rules] },
        { all: [{ fact: "information", operator: "", value: null, path: "" }] },
      ]);
    } else if (orType && rules.length === 2) {
      console.log("no allowed more");
    } else {
      setorType(false);
      setRules([...rules, { fact: "information", operator: "", value: null, path: "" }]);
    }
  };

  const handleRuleConditionAddition = (index) => {
    if (orType) {
      const updatedRules = [...rules];
      updatedRules[index] = {
        ...updatedRules[index],
        all: [
          ...updatedRules[index].all,
          { fact: "information", operator: "", value: null, path: "" },
        ],
      };
      setRules(updatedRules);
    } else {
      if (action === "ACTIVE" || action === "ENABLE") {
        setRules([
          ...rules,
          { fact: "information", operator: "notEqual", value: action, path: "$.status" },
          { fact: "information", operator: "", value: null, path: "" },
        ]);
      } else if (action === "PAUSED" || action === "DISABLE") {
        setRules([
          ...rules,
          { fact: "information", operator: "notEqual", value: action, path: "$.status" },
          { fact: "information", operator: "", value: null, path: "" },
        ]);
      } else {
        setRules([...rules, { fact: "information", operator: "", value: null, path: "" }]);
      }
    }
  };

  const handleRuleComparisionCondition = (index) => {
    if (orType) {
      const updatedRules = [...rules];
      updatedRules[index] = {
        ...updatedRules[index],
        all: [
          ...updatedRules[index].all,
          {
            fact: "information",
            operator: "",
            value: { fact: "information", path: "" },
            path: "",
          },
        ],
      };
      setRules(updatedRules);
    } else {
      setRules([
        ...rules,
        {
          fact: "information",
          operator: "",
          value: { fact: "information", path: "" },
          path: "",
        },
      ]);
    }
  };

  const handleRemoveRule = (index, index2) => {
    const updatedRules = [...rules];
    updatedRules[index].all?.splice(index2, 1);
    setRules(updatedRules);
  };
  const handleRemoveRuleSet = (index) => {
    let updatedRules = [...rules];
    if (orType) {
      updatedRules.splice(index, 1);
      updatedRules = updatedRules[0].all;
      setRules(updatedRules);
      setorType(false);
    } else {
      updatedRules.splice(index, 1);
      setRules(updatedRules);
    }
  };
  const handleRuleChange = (index, updatedRule, index2) => {
    const updatedRules = [...rules];
    if (orType) {
      updatedRules[index].all[index2] = updatedRule;
      setRules(updatedRules);
    } else {
      updatedRules[index] = updatedRule;
      setRules(updatedRules);
    }
  };
  const handleAdAccountIds = () => {
    // if (Assetids.length < 10) {
    setadAccountIds([...adAccountIds, ""]);
    // }
  };
  const handleAdAccountIdChange = (index, value) => {
    const updatedIds = [...adAccountIds];
    updatedIds[index] = value;
    setadAccountIds(updatedIds);
  };
  const handleAdAccountIdsRemove = (index, value) => {
    const updatedIds = [...adAccountIds];
    updatedIds.splice(index, 1);
    setadAccountIds(updatedIds);
  };
  const handleIds = () => {
    // if (Assetids.length < 10) {
    setAssetids([...Assetids, ""]);
    // }
  };
  const handleIdChange = (index, value) => {
    const updatedIds = [...Assetids];
    updatedIds[index] = value;
    setAssetids(updatedIds);
  };
  const handleIdsRemove = (index, value) => {
    const updatedIds = [...Assetids];
    updatedIds.splice(index, 1);
    setAssetids(updatedIds);
  };

  const handleManualInputChange = (e) => {
    setmanual(e.target.value);
  };
  function cronDetails() {
    if (nextoccurrences.length > 8) {
      setnextOccurrences([]);
    }
    const newOccurrences = [];
    const interval = manual
      ? parser.parseExpression(manual, {
          tz: "utc",
          currentDate: new Date(),
        })
      : "* * * * *";
    for (let i = 0; i < 10; i++) {
      const occurrence = interval.next().toDate();
      const dat = moment(occurrence).format("YYYY-MM-DD, HH:mm:ss");
      newOccurrences.push(dat);
    }
    setnextOccurrences((prevOccurrences) => [...prevOccurrences, ...newOccurrences]);
  }

  const currentDate =
    reportType === REPORT_TYPE_DAILY
      ? moment()
          .tz(timezone ? timezone : "utc")
          .subtract(2, "days")
          .format("YYYY-MM-DD")
      : moment()
          .tz(timezone ? timezone : "utc")
          .format("YYYY-MM-DD");
  const date1 =
    reportType === REPORT_TYPE_DAILY
      ? moment()
          .tz(timezone ? timezone : "utc")
          .subtract(3, "days")
          .format("YYYY-MM-DD")
      : moment()
          .tz(timezone ? timezone : "utc")
          .subtract(1, "days")
          .format("YYYY-MM-DD");
  const date2 =
    reportType === REPORT_TYPE_DAILY
      ? moment()
          .tz(timezone ? timezone : "utc")
          .subtract(4, "days")
          .format("YYYY-MM-DD")
      : moment()
          .tz(timezone ? timezone : "utc")
          .subtract(2, "days")
          .format("YYYY-MM-DD");
  const date3 =
    reportType === REPORT_TYPE_DAILY
      ? moment()
          .tz(timezone ? timezone : "utc")
          .subtract(reporting_days ? reporting_days + 2 : 2, "days")
          .format("YYYY-MM-DD")
      : moment()
          .tz(timezone ? timezone : "utc")
          .subtract(reporting_days ? reporting_days : 0, "days")
          .format("YYYY-MM-DD");
  const currentDate2 = moment()
    .tz(timezone ? timezone : "utc")
    .format("YYYY-MM-DD hh:mm:ss");
  const date4 = moment()
    .tz(timezone ? timezone : "utc")
    .subtract(reporting_days ? reporting_days : 0, "hour")
    .format("YYYY-MM-DD hh:mm:ss");
  return (
    <div>
      <div className="d-flex mb-10 justify-content-between">
        <h3>General Details</h3>
        {super_admin ? (
          <div className="d-flex mt-5">
            <h4>Is it a Pulse rule?</h4>
            <Switch
              style={{ marginLeft: "10px" }}
              className="mr-8"
              // style={{ backgroundColor: "#141414" }}
              checked={is_pulse}
              disabled={false}
              onChange={(e) => setis_pulse(e)}
            />
          </div>
        ) : null}
      </div>
      {is_pulse ? (
        <PulseRuleForm close={() => close()} refetch={refetch} copy={data} platform={platform} />
      ) : (
        <>
          <Form layout="vertical">
            <div className="mb-10 justify-content-between">
              <h4>Name </h4>
              <div>
                <LookTextInput
                  className="mb-0"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Enter Unique Rule Name"
                />
              </div>
            </div>
            {platform.length > 1 && (
              <div className="mb-10 justify-content-between">
                <h4>Select Platform </h4>
                <Radio.Group
                  onChange={(e) => setpltf(e.target.value)}
                  value={pltf}
                  className="mb-20"
                >
                  <Space direction="horizontal">
                    <Radio value={"fb"}>Facebook</Radio>
                    <Radio value={"tiktok"}>Tiktok</Radio>
                  </Space>
                </Radio.Group>
              </div>
            )}
            <div className="mb-10 justify-content-between">
              <h4>Action </h4>
              {/* <Radio.Group onChange={(e) => setAction(e.target.value)} value={action} className="mb-20">
            <Space direction="horizontal">
              {platform === "fb" || platform === "varozi" ? (
                <>
                  <Radio value={"Active"}>Turn On</Radio>
                  <Radio value={"Pause"}>Turn Off</Radio>
                  <Radio value={"BUDGET = SPEND"}>Budget equal to spend</Radio>
                </>
              ) : platform === "tiktok" ? (
                <>
                  <Radio value={"Enable"}>Turn On</Radio>
                  <Radio value={"Disable"}>Turn Off</Radio>
                </>
              ) : platform === "taboola" ? (
                <>
                  <Radio value={"Run"}>Turn On</Radio>
                  <Radio value={"Pause"}>Turn Off</Radio>
                </>
              ) : null}
              <Radio value={"INCREASE"}>Increase Budget</Radio>
              <Radio value={"DECREASE"}>Decrease Budget</Radio>
              <Radio value={"notification"}>Send Notifications Only</Radio>
            </Space>
          </Radio.Group> */}
              <div>
                {productId === PRODUCT_ID_SAS || productId === PRODUCT_ID_RSOC ? (
                  <SelectInput
                    onChange={(value) => setAction(value)}
                    value={action}
                    options={[
                      { name: "Notification", value: "notification" },
                      {
                        name: "Turn On",
                        value:
                          pltf === RULE_PLATFORM_TIKTOK
                            ? "ENABLE"
                            : pltf === RULE_PLATFORM_FB
                            ? "ACTIVE"
                            : "RUNNING",
                      },
                      {
                        name: "Turn Off",
                        value: pltf === RULE_PLATFORM_TIKTOK ? "DISABLE" : "PAUSED",
                      },
                      { name: "Budget eqaul to spend", value: "BUDGET = SPEND" },
                      { name: "Budget Increase", value: "INCREASE" },
                      { name: "Budget Decrease", value: "DECREASE" },
                      { name: "Pause Last X Days", value: "PAUSE_LAST_X_DAYS" },
                    ]}
                  />
                ) : productId === PRODUCT_ID_LEADGEN ? (
                  <SelectInput
                    onChange={(value) => setAction(value)}
                    value={action}
                    options={[
                      { name: "Notification", value: "notification" },
                      {
                        name: "Turn On",
                        value:
                          pltf === RULE_PLATFORM_TIKTOK
                            ? "ENABLE"
                            : pltf === RULE_PLATFORM_FB
                            ? "ACTIVE"
                            : "RUNNING",
                      },
                      {
                        name: "Turn Off",
                        value: pltf === RULE_PLATFORM_TIKTOK ? "DISABLE" : "PAUSED",
                      },
                      { name: "Budget Increase", value: "INCREASE" },
                      { name: "Budget Decrease", value: "DECREASE" },
                    ]}
                  />
                ) : productId === PRODUCT_ID_VS ? (
                  <SelectInput
                    onChange={(value) => setAction(value)}
                    value={action}
                    options={[
                      { name: "Notification", value: "notification" },
                      {
                        name: "Turn On",
                        value:
                          pltf === RULE_PLATFORM_TIKTOK
                            ? "ENABLE"
                            : pltf === RULE_PLATFORM_FB
                            ? "ACTIVE"
                            : "RUNNING",
                      },
                      {
                        name: "Turn Off",
                        value: pltf === RULE_PLATFORM_TIKTOK ? "DISABLE" : "PAUSED",
                      },
                      { name: "Budget eqaul to spend", value: "BUDGET = SPEND" },
                      { name: "Budget Increase", value: "INCREASE" },
                      { name: "Budget Decrease", value: "DECREASE" },
                      { name: "Bid Increase", value: "BID INCREASE" },
                      { name: "Bid Decrease", value: "BID DECREASE" },
                    ]}
                  />
                ) : null}
              </div>
            </div>
            {action === "notification" ? (
              <div className="mb-10 justify-content-between">
                <h4>Custom Message </h4>
                <div>
                  <LookTextInput
                    className="mb-0"
                    value={message}
                    onChange={(e) => setmessage(e.target.value)}
                    placeholder="Add a new custom message"
                  />
                </div>
              </div>
            ) : action === "INCREASE" || action === "DECREASE" ? (
              <div className="mb-10 justify-content-between">
                <h4>Budget Value </h4>
                <Row span={24} gutter={12}>
                  <Col span={16}>
                    <LookTextInput
                      normalNumberInput
                      className="mb-0"
                      value={budget}
                      onChange={(e) => setbudget(e)}
                      placeholder="Add budget value you wish to change"
                    />
                  </Col>
                  <Col span={8}>
                    <SelectInput
                      onChange={(value) => setbudget_type(value)}
                      placeholder="Select budget type"
                      value={budget_type}
                      options={[
                        { name: "Number", value: "int" },
                        { name: "Percentage", value: "percent" },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
            ) : action === "PAUSE_LAST_X_DAYS" ? (
              <div className="mb-10 justify-content-between">
                <h4>Number of days</h4>
                <div>
                  <LookTextInput
                    normalNumberInput
                    className="mb-0"
                    value={last_x_days}
                    onChange={(e) => setlast_x_days(e)}
                    placeholder="Add number of days for comparision"
                  />
                </div>
              </div>
            ) : action === "BID INCREASE" || action === "BID DECREASE" ? (
              <div className="mb-10 justify-content-between">
                <h4>Bid Value </h4>
                <Row span={24} gutter={12}>
                  <Col span={16}>
                    <LookTextInput
                      normalNumberInput
                      className="mb-0"
                      value={bid?.value}
                      onChange={(e) => setbid({ ...bid, value: parseFloat(e) })}
                      placeholder="Add bid value you wish to change"
                    />
                  </Col>
                  <Col span={8}>
                    <SelectInput
                      onChange={(e) => setbid({ ...bid, type: e })}
                      value={bid?.type}
                      options={[
                        { name: "Number", value: "int" },
                        { name: "Percentage", value: "percent" },
                      ]}
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
            <div className="mb-10 justify-content-between">
              <h4>Apply On </h4>
              <Radio.Group
                onChange={(e) => setgroupBy(e.target.value)}
                value={groupBy}
                className="mb-20"
              >
                <Space direction="horizontal">
                  <Radio value={"campaign"}>Campaigns</Radio>
                  <Radio value={"adset"}>Adsets</Radio>
                  <Radio value={"ad"}>Ads</Radio>
                </Space>
              </Radio.Group>
            </div>

            <div className="mb-10">
              <Row span={24} gutter={12}>
                <Col span={12}>
                  <h4>Rule Frequency </h4>
                  <div>
                    <SelectInput
                      placeholder="Select rule running frequency"
                      onChange={(value) => {
                        setperiod(value);
                      }}
                      value={period}
                      options={[
                        { name: "5 minutes", value: "*/5 * * * *" },
                        { name: "15 minutes", value: "*/15 * * * *" },
                        { name: "30 minutes", value: "*/30 * * * *" },
                        { name: "1 hour", value: "0 * * * *" },
                        { name: "2 hours", value: "0 */2 * * *" },
                        { name: "4 hours", value: "0 */4 * * *" },
                        { name: "8 hours", value: "0 */8 * * *" },
                        { name: "12 hours", value: "0 */12 * * *" },
                        { name: "Custom", value: "Custom" },
                      ]}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <h4>Lifespan of Rule </h4>
                  <div>
                    <SelectInput
                      placeholder="Select a lifespan of rule"
                      onChange={(value) => {
                        setlife(value);
                      }}
                      value={life}
                      options={[
                        { name: "6 hours", value: 0.25 },
                        { name: "8 hours", value: 0.33 },
                        { name: "12 hours", value: 0.5 },
                        { name: "1 Day", value: 1 },
                        { name: "2 Days", value: 2 },
                        { name: "Until manually turned off", value: "manual" },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {period === "Custom" ? (
              <div className=" mb-10 ">
                <h4>Custom Cron </h4>
                <Row span={24} gutter={12}>
                  <Col span={21}>
                    <LookTextInput
                      className="mb-0"
                      value={manual}
                      onChange={handleManualInputChange}
                      placeholder="Enter your own cron command"
                    />
                  </Col>
                  <Col span={3}>
                    <Button onClick={() => cronDetails()}>
                      Details <ActionIconRender iconType="down" />
                    </Button>
                  </Col>
                </Row>
                <Row span={24} gutter={12}>
                  {manual &&
                    nextoccurrences?.map((item, index) => {
                      return (
                        <Col span={8}>
                          <p>
                            {index} : {item}
                          </p>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            ) : null}
            <div className="mb-10 justify-content-between">
              <h4>TimeZone</h4>
              <div>
                <SelectInput
                  onChange={(value) => {
                    settimezone(value);
                  }}
                  value={timezone}
                  options={[
                    { name: "UTC", value: "UTC" },
                    { name: "America/Los_Angeles", value: "America/Los_Angeles" },
                    { name: "CET", value: "CET" },
                    { name: "PST8PDT", value: "PST8PDT" },
                    { name: "Asia/Kolkata", value: "Asia/Kolkata" },
                    { name: "EST5EDT", value: "EST5EDT" },
                  ]}
                />
              </div>
            </div>
            <div className="mb-10 justify-content-between">
              <h4>Report Type</h4>
              <div>
                {/* <SelectInput
              placeholder="select a reporting timeline"
              onChange={(value) => {
                setreportType(value);
              }}
              value={reportType}
              options={[
                { name: "PostBack", value: "PostBack" },
                { name: "Live", value: "Live" },
                { name: "Daily", value: "Daily" },
                { name: "General", value: "general" },
              ]}
            /> */}
                <Radio.Group
                  onChange={(e) => setreportType(e.target.value)}
                  value={reportType}
                  className="mb-20"
                >
                  <Space direction="horizontal">
                    <Radio value={"PostBack"}>PostBack</Radio>
                    <Radio value={"Live"}>Live</Radio>
                    <Radio value={"Daily"}>Daily</Radio>
                    <Radio value={"general"}>General</Radio>
                  </Space>
                </Radio.Group>
              </div>
            </div>
            <div className="mb-10 justify-content-between">
              <h4>Report Timeline</h4>
              <div>
                <SelectInput
                  onChange={(value) => {
                    settimeline(value);
                  }}
                  value={timeline}
                  options={[
                    { name: `Today (${currentDate})`, value: "today" },
                    { name: `Yesterday (${date1})`, value: "yesterday" },
                    { name: `Day Before Yesterday (${date2})`, value: "day before yesterday" },
                    { name: `Last N Days (${date3} to ${currentDate})`, value: "last N days" },
                    { name: `Last N Hours (${date4} to ${currentDate2})`, value: "last N hours" },
                  ]}
                />
              </div>
            </div>
            {timeline === "last N days" || timeline === "last N hours" ? (
              <div className="mb-10 justify-content-between">
                {timeline === "last N days" ? <h4>Number of Days </h4> : <h4>Number of Hours </h4>}
                <div>
                  <LookTextInput
                    normalNumberInput
                    className="mb-0"
                    value={reporting_days}
                    onChange={(e) => setreporting_days(e)}
                    placeholder="Enter last N number of days for report"
                  />
                </div>
              </div>
            ) : null}

            <div className="mb-10 justify-content-between">
              <div className="mb-10 ">
                <h4> Ad Account ID's</h4>
                <div>
                  <Button className="w-100 text-left" onClick={handleAdAccountIds}>
                    <ActionIconRender iconType="plus" /> Add ad account ids to include
                  </Button>
                </div>
              </div>
              <div className="mb-10">
                {adAccountIds?.map((ids, index) => (
                  <Row span={24}>
                    <Col span={23}>
                      <LookTextInput
                        className="mb-0"
                        value={ids}
                        onChange={(e) => handleAdAccountIdChange(index, e.target.value)}
                        placeholder="Enter Ad account Id's that you want to include here"
                      />
                    </Col>
                    <Col span={1}>
                      <Button
                        onClick={() => handleAdAccountIdsRemove(index)}
                        type="link"
                        danger
                        icon={<ActionIconRender iconType="close" />}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className="mb-10 justify-content-between">
              <div className=" mb-10">
                <h4> Asset ID's</h4>
                <div>
                  <Button onClick={handleIds} style={{ width: "100%", textAlign: "left" }}>
                    <ActionIconRender iconType="plus" /> Add asset ids to include
                  </Button>
                </div>
              </div>
              <div className="mb-10">
                {Assetids?.map((ids, index) => (
                  <Row span={24}>
                    <Col span={23}>
                      <LookTextInput
                        className="mb-0"
                        value={ids}
                        onChange={(e) => handleIdChange(index, e.target.value)}
                        placeholder="Enter Asset Id's that you want to include here"
                      />
                    </Col>
                    <Col span={1}>
                      <Button
                        onClick={() => handleIdsRemove(index)}
                        type="link"
                        danger
                        icon={<ActionIconRender iconType="close" />}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className="mb-10 justify-content-between">
              <div className="mb-10">
                <h4>Name Filter</h4>
                <div>
                  <Button onClick={handleName} style={{ width: "100%", textAlign: "left" }}>
                    <ActionIconRender iconType="plus" /> Add full asset name or nomenclature to
                    include
                  </Button>
                </div>
              </div>
              <div className="mb-10">
                {Categoryname?.map((names, index) => (
                  <Row span={24}>
                    <Col span={21}>
                      {names?.or ? (
                        <div className="d-flex">
                          {names.or?.map((items, index2) => (
                            <div className="d-flex">
                              <LookTextInput
                                className="mb-0"
                                value={items}
                                onChange={(e) => handleNameChange(index, e.target.value, index2)}
                                placeholder="Enter here.."
                              />
                              <Button
                                onClick={() => handleNameRemove(index, index2)}
                                type="link"
                                danger
                                icon={<ActionIconRender iconType="close" />}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="d-flex">
                          <LookTextInput
                            className="mb-0"
                            value={names}
                            onChange={(e) => handleNameChange(index, e.target.value)}
                            placeholder="Enter here.."
                          />
                          <Button
                            onClick={() => handleNameRemove(index)}
                            type="link"
                            danger
                            icon={<ActionIconRender iconType="close" />}
                          />
                        </div>
                      )}
                    </Col>
                    <Col span={3}>
                      <Button onClick={() => handleORNameChange(index)} type="link">
                        <ActionIconRender iconType="plus" />
                        And
                      </Button>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>

            <div className="mb-10">
              <h4>Exclusion Filter</h4>
              <div>
                <Button onClick={handleExc} style={{ width: "100%", textAlign: "left" }}>
                  <ActionIconRender iconType="plus" /> Enter name or nomenclature or ID to exclude
                </Button>
              </div>
              <div style={{ marginTop: "5px" }}>
                {Exclusionname?.map((names, index) => (
                  <Row span={24}>
                    <Col span={21}>
                      {names?.or ? (
                        <div className="d-flex">
                          {names.or?.map((items, index2) => (
                            <div className="d-flex">
                              <LookTextInput
                                className="mb-0"
                                value={items}
                                onChange={(e) => handleExcChange(index, e.target.value, index2)}
                                placeholder="Enter here.."
                              />
                              <Button
                                onClick={() => handleExcRemove(index, index2)}
                                type="link"
                                danger
                                icon={<ActionIconRender iconType="close" />}
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="d-flex">
                          <LookTextInput
                            className="mb-0"
                            value={names}
                            onChange={(e) => handleExcChange(index, e.target.value)}
                            placeholder="Enter here.."
                          />
                          <Button
                            onClick={() => handleExcRemove(index)}
                            type="link"
                            danger
                            icon={<ActionIconRender iconType="close" />}
                          />
                        </div>
                      )}
                    </Col>
                    <Col span={3}>
                      <Button onClick={() => handleORExcChange(index)} type="link">
                        <ActionIconRender iconType="plus" />
                        And
                      </Button>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className="mb-10">
              <h4>Comparision</h4>
              <div>
                <Button onClick={handleComparison} style={{ width: "100%", textAlign: "left" }}>
                  <ActionIconRender iconType="plus" /> Create Comparision Statement
                </Button>
              </div>
              <div style={{ marginTop: "5px" }}>
                {comparison?.map((item, index) => (
                  <Row span={24}>
                    <Col span={3}>
                      <LookTextInput
                        className="mb-0"
                        normalNumberInput
                        value={item.value}
                        onChange={(e) => handleComparsionvalue(index, e)}
                        placeholder="Enter Value"
                      />
                    </Col>
                    <Col span={6}>
                      <SelectInput
                        placeholder="Select rule running frequency"
                        onChange={(value) => {
                          handleComparsionOperation(index, value);
                        }}
                        value={item.operator}
                        options={[
                          { name: "Spend", value: "spend" },
                          { name: "E ROAS", value: "ROI" },
                          { name: "Revenue", value: "revenue" },
                          { name: "Gross Margin", value: "profit" },
                          { name: "Status", value: "status" },
                          { name: "Clicks", value: "clicks" },
                          { name: "Impressions", value: "impressions" },
                          { name: "CPC", value: "CPC" },
                          { name: "CPR", value: "CPR" },
                          { name: "Conversions", value: "conversions" },
                          { name: "Budget", value: "budget" },
                          { name: "leads (V)", value: "leadsV" },
                          { name: "LP CTR", value: "LP_CTR" },
                          { name: "AD CTR", value: "AD_CTR" },
                          ...formulae_fields,
                        ]}
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        onClick={() => handleComparisionRemove(index)}
                        type="link"
                        danger
                        icon={<ActionIconRender iconType="close" />}
                      />
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className="d-flex mb-10 justify-content-between">
              <h4>Rule Conditions</h4>
              <div>
                <Button
                  onClick={() => {
                    handleRuleAddition();
                  }}
                  size="small"
                  className="ml-10"
                >
                  <ActionIconRender iconType="plus" /> Add "OR" type Rules
                </Button>
              </div>
            </div>
            {orType ? (
              <div className="mb-10">
                <div className="mb-10" style={{ border: "1px solid #404040", padding: "10px" }}>
                  {rules?.map((rule, index) => (
                    <div key={index}>
                      <div
                        className="mb-10"
                        style={{
                          // backgroundColor: "#202020",
                          border: "1px solid #404040",
                          padding: "10px",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <h4>Conditions</h4>

                          <div>
                            {/* <Button
                          onClick={handleRuleComparisionCondition}
                          size="small"
                          className="mb-10"
                          // type="link"
                          style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px" }}
                        >
                          <ActionIconRender iconType="plus" /> Add Comparision Conditions
                        </Button> */}
                            <Button
                              onClick={() => handleRuleConditionAddition(index)}
                              size="small"
                              className="mb-10"
                              style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px" }}
                            >
                              <ActionIconRender iconType="plus" /> Add Rule Conditions
                            </Button>
                            <Button
                              onClick={() => {
                                handleRemoveRuleSet(index);
                              }}
                              size="small"
                              style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px" }}
                              type="link"
                              danger={true}
                            >
                              Delete Rule Set
                            </Button>
                          </div>
                        </div>
                        {rule.all?.map((item, index2) => (
                          <RuleFields
                            key={index2}
                            rule={item}
                            handleRemove={() => handleRemoveRule(index, index2)}
                            handleRuleChange={(updatedRule) =>
                              handleRuleChange(index, updatedRule, index2)
                            }
                            platform={pltf}
                            comparison={comparison}
                            productId={productId}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="mb-10">
                <div className="mb-10 " style={{ border: "1px solid #404040", padding: "10px" }}>
                  <div className="d-flex justify-content-between">
                    <h4>Conditions</h4>
                    <div>
                      <Button
                        onClick={handleRuleComparisionCondition}
                        size="small"
                        className="mb-10"
                        // type="link"
                        style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px" }}
                      >
                        <ActionIconRender iconType="plus" /> Add Comparision Conditions
                      </Button>
                      <Button
                        onClick={handleRuleConditionAddition}
                        size="small"
                        className="mb-10"
                        // type="link"
                        style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px" }}
                      >
                        <ActionIconRender iconType="plus" /> Add Rule Conditions
                      </Button>
                    </div>
                  </div>

                  {rules?.map((rule, index) => (
                    <div key={index}>
                      <RuleFields
                        rule={rule}
                        handleRemove={() => handleRemoveRuleSet(index)}
                        handleRuleChange={(updatedRule) => handleRuleChange(index, updatedRule)}
                        platform={pltf}
                        comparison={comparison}
                        productId={productId}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {super_admin ? (
              <div className="d-flex mt-20">
                <h4>Exclude Pulse campaign from this rule?</h4>
                <Switch
                  style={{ marginLeft: "10px" }}
                  className="mr-8"
                  // style={{ backgroundColor: "#141414" }}
                  checked={exclude_pulse}
                  disabled={false}
                  onChange={(e) => setexclude_pulse(e)}
                />
              </div>
            ) : null}
            {/* <div className="mb-10 justify-content-between">
          <h4>Action on service down </h4>
          <Radio.Group
            onChange={(e) => setServicedown(e.target.value)}
            value={servicedown}
            className="mb-20"
          >
            <Space direction="horizontal">
              <Radio value={"alert"}>Alert only</Radio>
              <Radio value={"assetoff"}>Turn off assets</Radio>
              <Radio value={"ruleoff"}>Turn off Rule</Radio>
              <Radio value={"none"}>Do nothing</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="d-flex mb-10">
          <h4>Resume on service back</h4>
          <Switch
            style={{ marginLeft: "10px" }}
            // className="mr-8"
            // style={{ backgroundColor: "#141414" }}
            // checked={false}
            // disabled={false}
            // onChange={(e) => handleStatus(e, otherProps)}
          />
        </div> */}
          </Form>
          <div style={{ marginTop: "10%" }} className="d-flex mb-10 justify-content-between">
            <Button
              style={{ width: "100%" }}
              className="mr-10"
              type="primary"
              key="submit"
              onClick={updateRule}
            >
              Update Rule
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
