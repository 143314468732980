import { gql } from "@apollo/client";

export const ASSET_UPDATE_RESPONSE_RESPONSE = gql`
  fragment AssetUpdateResponse on AssetUpdateResponse {
    assetId
    success
    message
  }
`;

const AGENCY_RESPONSE = gql`
  fragment SasAgency on SasAgency {
    id
    name
    nickName
    status
    revShare
    platforms {
      id
      name
    }
  }
`;

const AGENCY_BY_ID_RESPONSE = gql`
  fragment SasAgencyNew on SasAgency {
    id
    name
    nickName
    status
    revShare
    platforms {
      id
      name
    }
    agencyFeeds {
      id
      name
      feedVendors {
        id
        name
      }
    }
  }
`;

export const GET_SAS_AGENCIES = gql`
  ${AGENCY_RESPONSE}
  query sasAgencies {
    sasAgencies {
      ...SasAgency
    }
  }
`;

export const GET_SAS_AGENCY_BY_ID = gql`
  ${AGENCY_BY_ID_RESPONSE}
  query sasAgency($id: ID!) {
    sasAgency(id: $id) {
      ...SasAgencyNew
    }
  }
`;

export const CREATE_SAS_AGENCY = gql`
  ${AGENCY_RESPONSE}
  mutation sasAgencyCreate(
    $id: ID!
    $name: String
    $nickName: String
    $platformId: [ID]
    $revShare: Int
    $status: SasStatus
    $vendorFeedIds: [vendorFeed]
  ) {
    sasAgencyCreate(
      id: $id
      name: $name
      nickName: $nickName
      platformId: $platformId
      revShare: $revShare
      status: $status
      vendorFeedIds: $vendorFeedIds
    ) {
      ...SasAgency
    }
  }
`;

export const ASSIGN_DOMAIN_TO_AGENCY = gql`
  ${AGENCY_RESPONSE}
  mutation sasAssignDomainToAgency(
    $agencyId: ID
    $domainId: [ID]
    $platformId: [ID]
    $vendorFeedIds: [vendorFeed]
  ) {
    sasAssignDomainToAgency(
      agencyId: $agencyId
      domainId: $domainId
      platformId: $platformId
      vendorFeedIds: $vendorFeedIds
    ) {
      ...SasAgency
    }
  }
`;

export const DELETE_SAS_AGENCY_DOMAIN = gql`
  mutation deleteAgencyDomain($agencyId: ID, $domainId: ID) {
    deleteAgencyDomain(agencyId: $agencyId, domainId: $domainId)
  }
`;

export const DELETE_SAS_AGENCY = gql`
  mutation sasAgencyDelete($id: [ID]) {
    sasAgencyDelete(id: $id)
  }
`;

export const UPDATE_SAS_AGENCY = gql`
  ${AGENCY_RESPONSE}
  mutation sasAgencyUpdate(
    $id: ID!
    $name: String
    $nickName: String
    $platformId: [ID]
    $revShare: Int
    $status: SasStatus
    $vendorFeedIds: [vendorFeed]
  ) {
    sasAgencyUpdate(
      id: $id
      name: $name
      nickName: $nickName
      platformId: $platformId
      revShare: $revShare
      status: $status
      vendorFeedIds: $vendorFeedIds
    ) {
      ...SasAgency
    }
  }
`;

const USER_RESPONSE = gql`
  fragment SasUser on SasUser {
    id
    name
    nickName
    status
    agency {
      id
      name
      nickName
    }
    platforms {
      id
      name
    }
  }
`;

export const GET_SAS_USERS = gql`
  ${USER_RESPONSE}
  query sasUsers($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    sasUsers(column: $column, order: $order, page: $page, search: $search, size: $size) {
      data {
        ...SasUser
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_SAS_USER = gql`
  ${USER_RESPONSE}
  query sasUser($id: ID!) {
    sasUser(id: $id) {
      ...SasUser
    }
  }
`;

export const GET_SAS_USER_BY_ID = gql`
  ${USER_RESPONSE}
  query sasUser($id: ID!) {
    sasUser(id: $id) {
      ...SasUser
    }
  }
`;

export const GET_SAS_USER_WITH_PLATFORM = gql`
  query sasUser($id: ID!) {
    sasUser(id: $id) {
      id
      name
      platforms {
        id
        name
      }
    }
  }
`;

export const GET_SAS_USER_BY_AGENCY_ID = gql`
  ${USER_RESPONSE}
  query sasUserByAgency(
    $agencyId: ID
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    sasUserByAgency(
      agencyId: $agencyId
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        ...SasUser
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CREATE_SAS_USER = gql`
  ${USER_RESPONSE}
  mutation sasUserCreate(
    $id: ID!
    $nickName: String
    $name: String
    $platformId: [ID]
    $status: SasStatus
  ) {
    sasUserCreate(
      id: $id
      nickName: $nickName
      name: $name
      platformId: $platformId
      status: $status
    ) {
      ...SasUser
    }
  }
`;

export const UPDATE_SAS_USER = gql`
  ${USER_RESPONSE}
  mutation sasUserUpdate(
    $id: ID!
    $nickName: String
    $name: String
    $platformId: [ID]
    $status: SasStatus
  ) {
    sasUserUpdate(
      id: $id
      nickName: $nickName
      name: $name
      platformId: $platformId
      status: $status
    ) {
      ...SasUser
    }
  }
`;

export const DELETE_SAS_USER = gql`
  mutation sasUserDelete($id: [ID]) {
    sasUserDelete(id: $id)
  }
`;

const LOOK_CATEGORIES_RESPONSE = gql`
  fragment LookCategory on LookCategory {
    id
    isSensitive
    name
    status
    domains {
      id
      name
    }
    lookVertical {
      id
      name
      isSensitive
    }
  }
`;

export const GET_LOOK_CATEGORIES = gql`
  ${LOOK_CATEGORIES_RESPONSE}
  query sasLookCategories {
    sasLookCategories {
      ...LookCategory
    }
  }
`;

export const GET_LOOK_CATEGORIES_DROPDOWN = gql`
  query sasLookCategories {
    sasLookCategories {
      id
      name
    }
  }
`;

export const CREATE_LOOK_CATEGORY = gql`
  ${LOOK_CATEGORIES_RESPONSE}
  mutation sasLookCategoryCreate(
    $isSensitive: Boolean
    $lookVerticalId: ID
    $name: [String]
    $status: SasStatus
  ) {
    sasLookCategoryCreate(
      isSensitive: $isSensitive
      lookVerticalId: $lookVerticalId
      name: $name
      status: $status
    ) {
      ...LookCategory
    }
  }
`;
export const UPDATE_LOOK_CATEGORY = gql`
  ${LOOK_CATEGORIES_RESPONSE}
  mutation sasLookCategoryUpdate(
    $id: ID!
    $isSensitive: Boolean
    $lookVerticalId: ID
    $name: String
    $status: SasStatus
  ) {
    sasLookCategoryUpdate(
      id: $id
      isSensitive: $isSensitive
      lookVerticalId: $lookVerticalId
      name: $name
      status: $status
    ) {
      ...LookCategory
    }
  }
`;

export const DELETE_LOOK_CATEGORY = gql`
  mutation sasLookCategoryDelete($id: [ID]) {
    sasLookCategoryDelete(id: $id)
  }
`;

const LOOK_VERTICAL_RESPONSE = gql`
  fragment LookVertical on LookVertical {
    id
    name
    isSensitive
  }
`;

export const GET_LOOK_VERTICALS = gql`
  ${LOOK_VERTICAL_RESPONSE}
  query sasLookVerticals {
    sasLookVerticals {
      ...LookVertical
    }
  }
`;

export const CREATE_LOOK_VERTICAL = gql`
  ${LOOK_VERTICAL_RESPONSE}
  mutation sasLookVerticalCreate($isSensitive: Boolean, $name: String) {
    sasLookVerticalCreate(isSensitive: $isSensitive, name: $name) {
      ...LookVertical
    }
  }
`;

export const UPDATE_LOOK_VERTICAL = gql`
  ${LOOK_VERTICAL_RESPONSE}
  mutation sasLookVerticalUpdate($id: ID!, $isSensitive: Boolean, $name: String) {
    sasLookVerticalUpdate(id: $id, isSensitive: $isSensitive, name: $name) {
      ...LookVertical
    }
  }
`;

export const DELETE_LOOK_VERTICAL = gql`
  mutation sasLookVerticalDelete($id: [ID!]) {
    sasLookVerticalDelete(id: $id)
  }
`;

const DOMAIN_RESPONSE = gql`
  fragment SasDomain on SasDomain {
    id
    name
    nickName
    url
    status
    language {
      id
      name
    }
    country {
      id
      name
    }
    domainPixels {
      id
      pixelName
      pixelValue
    }
    platforms {
      id
      name
    }
    feed {
      id
      name
    }
    categories {
      id
      name
    }
    vendor {
      id
      name
    }
    daCampaign
    daTracking
  }
`;

const SAS_DOMAIN_FOR_RESPONSE = gql`
  fragment SasDomain_Dropdown on SasDomain {
    id
    name
    url
  }
`;

export const GET_SAS_DOMAINS = gql`
  ${DOMAIN_RESPONSE}
  query sasDomains(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $platformId: ID
    $vendorId: ID
  ) {
    sasDomains(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      platformId: $platformId
      vendorId: $vendorId
    ) {
      data {
        ...SasDomain
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_SAS_DOMAINS_BY_CATEGORY = gql`
  ${DOMAIN_RESPONSE}
  query sasFetchDomainByCategory($categoryId: ID, $vendorId: ID, $platformId: ID) {
    sasFetchDomainByCategory(
      categoryId: $categoryId
      vendorId: $vendorId
      platformId: $platformId
    ) {
      ...SasDomain
    }
  }
`;

export const GET_SAS_DOMAINS_BY_CATEGORY_DROPDOWN = gql`
  query sasFetchDomainByCategory($categoryId: ID, $vendorId: ID, $platformId: ID) {
    sasFetchDomainByCategory(
      categoryId: $categoryId
      vendorId: $vendorId
      platformId: $platformId
    ) {
      id
      name
    }
  }
`;

export const GET_SAS_DOMAINS_BY_CATEGORY_FOR_LAUNCH = gql`
  ${SAS_DOMAIN_FOR_RESPONSE}
  query sasFetchDomainByCategory($categoryId: ID, $vendorId: ID, $platformId: ID) {
    sasFetchDomainByCategory(
      categoryId: $categoryId
      vendorId: $vendorId
      platformId: $platformId
    ) {
      ...SasDomain_Dropdown
    }
  }
`;

export const GET_SAS_COUNTRIES = gql`
  query SasCountries {
    SasCountries {
      id
      name
      iso_code
    }
  }
`;

export const GET_SAS_LANGUAGES = gql`
  query SasLanguages {
    SasLanguages {
      id
      name
      isoCode
    }
  }
`;

const TRACKING_URL_RESPONSE = gql`
  fragment TrackingUrl on TrackingUrl {
    id
    domain
    platforms {
      id
      name
      tracking_url_platform
    }
  }
`;

export const GET_SAS_TRACKING_URL = gql`
  ${TRACKING_URL_RESPONSE}
  query sasTrackingUrls {
    sasTrackingUrls {
      ...TrackingUrl
    }
  }
`;

export const CREATE_SAS_TRACKING_URL = gql`
  ${TRACKING_URL_RESPONSE}
  mutation sasTrackingUrlCreate($domain: String, $platformTrackingUrl: [platformTrackingUrlInput]) {
    sasTrackingUrlCreate(domain: $domain, platformTrackingUrl: $platformTrackingUrl) {
      ...TrackingUrl
    }
  }
`;

export const UPDATE_SAS_TRACKING_URL = gql`
  ${TRACKING_URL_RESPONSE}
  mutation sasTrackingUrlUpdate(
    $id: ID!
    $domain: String
    $platformTrackingUrl: [platformTrackingUrlInput]
  ) {
    sasTrackingUrlUpdate(id: $id, domain: $domain, platformTrackingUrl: $platformTrackingUrl) {
      ...TrackingUrl
    }
  }
`;

export const DELETE_SAS_TRACKING_URL = gql`
  mutation sasTrackingUrlDelete($id: [ID]) {
    sasTrackingUrlDelete(id: $id)
  }
`;

export const GET_DOMAINS_BY_AGENCY_ID = gql`
  ${DOMAIN_RESPONSE}
  query sasAgencyDomains(
    $agencyId: ID
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    sasAgencyDomains(
      agencyId: $agencyId
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        ...SasDomain
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CREATE_SAS_DOMAIN = gql`
  ${DOMAIN_RESPONSE}
  mutation sasDomainCreate(
    $feedId: ID
    $lookCategoryId: ID
    $name: String
    $nickName: String
    $status: SasStatus
    $url: String
    $vendorId: ID
    $platformId: [ID]
    $daCampaign: String
    $daTracking: String
    $countryId: ID
    $languageId: ID
  ) {
    sasDomainCreate(
      feedId: $feedId
      lookCategoryId: $lookCategoryId
      name: $name
      nickName: $nickName
      status: $status
      url: $url
      vendorId: $vendorId
      platformId: $platformId
      daCampaign: $daCampaign
      daTracking: $daTracking
      countryId: $countryId
      languageId: $languageId
    ) {
      ...SasDomain
    }
  }
`;

export const UPDATE_SAS_DOMAIN = gql`
  ${DOMAIN_RESPONSE}
  mutation sasDomainUpdate(
    $id: ID!
    $feedId: ID
    $lookCategoryId: ID
    $name: String
    $nickName: String
    $status: SasStatus
    $url: String
    $vendorId: ID
    $platformId: [ID]
    $daCampaign: String
    $daTracking: String
    $countryId: ID
    $languageId: ID
  ) {
    sasDomainUpdate(
      id: $id
      feedId: $feedId
      lookCategoryId: $lookCategoryId
      name: $name
      nickName: $nickName
      status: $status
      url: $url
      vendorId: $vendorId
      platformId: $platformId
      daCampaign: $daCampaign
      daTracking: $daTracking
      countryId: $countryId
      languageId: $languageId
    ) {
      ...SasDomain
    }
  }
`;

export const GET_SAS_VENDOR_FEED_DOMAIN = gql`
  ${DOMAIN_RESPONSE}
  query sasVendorFeedDomains($vendorFeedIds: [vendorFeed]) {
    sasVendorFeedDomains(vendorFeedIds: $vendorFeedIds) {
      ...SasDomain
    }
  }
`;

export const GET_SAS_DOMAIN_BY_VENDORFEED = gql`
  ${DOMAIN_RESPONSE}
  query sasFetchDomainByVendorFeed($feedId: ID, $vendorId: ID) {
    sasFetchDomainByVendorFeed(feedId: $feedId, vendorId: $vendorId) {
      ...SasDomain
    }
  }
`;

export const DELETE_SAS_DOMAIN = gql`
  mutation sasDomainDelete($id: [ID]) {
    sasDomainDelete(id: $id)
  }
`;

const FEED_RESPONSE = gql`
  fragment Feed on Feed {
    id
    name
    domain {
      id
      name
    }
    vendors {
      id
      name
    }
    feedVendors {
      id
      name
    }
  }
`;

export const GET_SAS_FEEDS = gql`
  ${FEED_RESPONSE}
  query sasFeeds {
    sasFeeds {
      ...Feed
    }
  }
`;

export const GET_SAS_FEEDS_DROPDOWN = gql`
  query sasFeeds {
    sasFeeds {
      id
      name
    }
  }
`;

export const GET_FEEDS_BY_VENDOR_ID = gql`
  ${FEED_RESPONSE}
  query sasVendorFeed($vendorId: ID) {
    sasVendorFeed(vendorId: $vendorId) {
      ...Feed
    }
  }
`;

export const CREATE_SAS_FEED = gql`
  ${FEED_RESPONSE}
  mutation sasFeedCreate($name: String, $vendorId: [ID]) {
    sasFeedCreate(name: $name, vendorId: $vendorId) {
      ...Feed
    }
  }
`;

export const UPDATE_SAS_FEED = gql`
  ${FEED_RESPONSE}
  mutation sasFeedUpdate($id: ID!, $name: String, $vendorId: [ID]) {
    sasFeedUpdate(id: $id, name: $name, vendorId: $vendorId) {
      ...Feed
    }
  }
`;

export const DELETE_SAS_FEED = gql`
  mutation sasFeedDelete($id: [ID]) {
    sasFeedDelete(id: $id)
  }
`;

const VENDOR_RESPONSE = gql`
  fragment Vendor on Vendor {
    description
    isMultiKeyword
    domains {
      id
      name
      status
      feed {
        id
        name
      }
      url
      vendor {
        id
        name
      }
    }
    feeds {
      id
      name
    }
    guidlines
    id
    name
    nickName
    status
    platforms {
      id
      name
      vendor_platform
    }
    website
  }
`;

const VENDOR_FOR_REDUCER_DROPDOWN_RESPONSE = gql`
  fragment Vendor_For_Reducer_dropdown on Vendor {
    id
    name
  }
`;

export const GET_SAS_VENDORS = gql`
  ${VENDOR_RESPONSE}
  query sasVendors {
    sasVendors {
      ...Vendor
    }
  }
`;

export const GET_SAS_VENDOR_FOR_REDUCER_DROPDOWN = gql`
  ${VENDOR_FOR_REDUCER_DROPDOWN_RESPONSE}
  query sasVendors {
    sasVendors {
      ...Vendor_For_Reducer_dropdown
    }
  }
`;

const PLATFORM_FOR_REDUCER_DROPDOWN_RESPONSE = gql`
  fragment Platform_For_Reducer_dropdown on Platform {
    id
    name
  }
`;

export const GET_SAS_PLATFORM_BY_USER_FOR_REDUCER_DROPDOWN = gql`
  ${PLATFORM_FOR_REDUCER_DROPDOWN_RESPONSE}
  query sasPlatformsByUser {
    sasPlatformsByUser {
      ...Platform_For_Reducer_dropdown
    }
  }
`;

const SAS_AGENCY_USER_FOR_REDUCER_DROPDOWN_RESPONSE = gql`
  fragment SasUser_For_Reducer_dropdown on SasUser {
    id
    name
  }
`;

export const GET_SAS_SAS_AGENCY_USER_BY_USER_FOR_REDUCER_DROPDOWN = gql`
  ${SAS_AGENCY_USER_FOR_REDUCER_DROPDOWN_RESPONSE}
  query sasAgencyUsers {
    sasAgencyUsers {
      ...SasUser_For_Reducer_dropdown
    }
  }
`;

export const CREATE_SAS_VENDOR = gql`
  ${VENDOR_RESPONSE}
  mutation sasVendorCreate(
    $description: String
    $feedId: [ID]
    $guidlines: String
    $name: String
    $isMultiKeyword: Boolean
    $platformUrl: [platformUrlInput]
    $status: SasStatus
    $website: String
    $nickName: String
  ) {
    sasVendorCreate(
      description: $description
      feedId: $feedId
      guidlines: $guidlines
      name: $name
      isMultiKeyword: $isMultiKeyword
      platformUrl: $platformUrl
      status: $status
      website: $website
      nickName: $nickName
    ) {
      ...Vendor
    }
  }
`;

export const UPDATE_SAS_VENDOR = gql`
  ${VENDOR_RESPONSE}
  mutation sasVendorUpdate(
    $id: ID!
    $description: String
    $feedId: [ID]
    $guidlines: String
    $name: String
    $isMultiKeyword: Boolean
    $platformUrl: [platformUrlInput]
    $status: SasStatus
    $website: String
    $nickName: String
  ) {
    sasVendorUpdate(
      id: $id
      description: $description
      feedId: $feedId
      guidlines: $guidlines
      name: $name
      isMultiKeyword: $isMultiKeyword
      platformUrl: $platformUrl
      status: $status
      website: $website
      nickName: $nickName
    ) {
      ...Vendor
    }
  }
`;

export const DELETE_SAS_VENDOR = gql`
  mutation sasVendorDelete($id: [ID]) {
    sasVendorDelete(id: $id)
  }
`;

const VENDOR_ACCOUNT_RESPONSE = gql`
  fragment sasVendorAccount on sasVendorAccount {
    apikey
    email
    id
    name
    notes
    partnerid
    password
    username
    vendor {
      id
      name
    }
    platforms {
      id
      name
    }
  }
`;

export const GET_SAS_VENDOR_ACCOUNTS = gql`
  ${VENDOR_ACCOUNT_RESPONSE}
  query sasVendorAccounts($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    sasVendorAccounts(column: $column, order: $order, page: $page, search: $search, size: $size) {
      data {
        ...sasVendorAccount
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_SAS_VENDOR_OFFERS = gql`
  query sasGetVendorOffers($isoCode: String!, $vendorAccountId: ID!) {
    sasGetVendorOffers(isoCode: $isoCode, vendorAccountId: $vendorAccountId) {
      id
      name
    }
  }
`;

export const CREATE_SAS_VENDOR_ACCOUNT = gql`
  ${VENDOR_ACCOUNT_RESPONSE}
  mutation sasVendorAccountCreate(
    $apikey: String
    $email: String
    $name: String
    $notes: String
    $partnerid: String
    $password: String
    $username: String
    $vendorId: ID
    $platformId: [ID]
  ) {
    sasVendorAccountCreate(
      apikey: $apikey
      email: $email
      name: $name
      notes: $notes
      partnerid: $partnerid
      password: $password
      username: $username
      vendorId: $vendorId
      platformId: $platformId
    ) {
      ...sasVendorAccount
    }
  }
`;

export const UPDATE_SAS_VENDOR_ACCOUNT = gql`
  ${VENDOR_ACCOUNT_RESPONSE}
  mutation sasVendorAccountUpdate(
    $id: ID!
    $apikey: String
    $email: String
    $name: String
    $notes: String
    $partnerid: String
    $password: String
    $username: String
    $vendorId: ID
    $platformId: [ID]
  ) {
    sasVendorAccountUpdate(
      id: $id
      apikey: $apikey
      email: $email
      name: $name
      notes: $notes
      partnerid: $partnerid
      password: $password
      username: $username
      vendorId: $vendorId
      platformId: $platformId
    ) {
      ...sasVendorAccount
    }
  }
`;

export const DELETE_SAS_VENDOR_ACCOUNT = gql`
  mutation sasVendorAccountDelete($id: [ID]) {
    sasVendorAccountDelete(id: $id)
  }
`;

const GDVALUE_RESPONSE = gql`
  fragment SasGdValue on SasGdValue {
    id
    gdValue
    domain {
      id
      name
    }
    platform {
      id
      name
    }
  }
`;

export const GET_SAS_GDVALUES = gql`
  ${GDVALUE_RESPONSE}
  query sasGdValues {
    sasGdValues {
      ...SasGdValue
    }
  }
`;

export const GET_SAS_GDVALUES_BY_PLATFORM_DOMAIN = gql`
  ${GDVALUE_RESPONSE}
  query sasGdValuesByPlatformAndDomain($domainId: ID, $platformId: ID) {
    sasGdValuesByPlatformAndDomain(domainId: $domainId, platformId: $platformId) {
      ...SasGdValue
    }
  }
`;

export const CREATE_SAS_GDVALUES = gql`
  ${GDVALUE_RESPONSE}
  mutation sasGdValueCreate($domainId: ID, $gdValue: String, $platformId: ID) {
    sasGdValueCreate(domainId: $domainId, gdValue: $gdValue, platformId: $platformId) {
      ...SasGdValue
    }
  }
`;

export const UPDATE_SAS_GDVALUES = gql`
  ${GDVALUE_RESPONSE}
  mutation sasGdValueUpdate($domainId: ID, $gdValue: String, $id: ID!) {
    sasGdValueUpdate(domainId: $domainId, gdValue: $gdValue, id: $id) {
      ...SasGdValue
    }
  }
`;

export const DELETE_SAS_GDVALUE = gql`
  mutation sasGdValueDelete($id: [ID]) {
    sasGdValueDelete(id: $id)
  }
`;

const VERTICAL_RESPONSE = gql`
  fragment Vertical on Vertical {
    id
    isSensitive
    name
  }
`;

export const GET_SAS_VERTICALS = gql`
  ${VERTICAL_RESPONSE}
  query sasVerticals {
    sasVerticals {
      ...Vertical
    }
  }
`;

export const GET_PAGED_SAS_VERTICALS = gql`
  ${VERTICAL_RESPONSE}
  query sasPagedVerticals($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    sasPagedVerticals(column: $column, order: $order, page: $page, search: $search, size: $size) {
      data {
        ...Vertical
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CREATE_SAS_VERTICAL = gql`
  ${VERTICAL_RESPONSE}
  mutation sasVerticalCreate($isSensitive: Boolean, $name: String) {
    sasVerticalCreate(isSensitive: $isSensitive, name: $name) {
      ...Vertical
    }
  }
`;

export const UPDATE_SAS_VERTICAL = gql`
  ${VERTICAL_RESPONSE}
  mutation sasVerticalUpdate($id: ID!, $isSensitive: Boolean, $name: String) {
    sasVerticalUpdate(id: $id, isSensitive: $isSensitive, name: $name) {
      ...Vertical
    }
  }
`;

export const DELETE_SAS_VERTICAL = gql`
  mutation sasVerticalDelete($id: [ID]) {
    sasVerticalDelete(id: $id)
  }
`;

const CATEGORIES_RESPONSE = gql`
  fragment SasCategory on SasCategory {
    id
    isSensitive
    name
    vertical {
      id
      name
    }
    domainCount
    setCount
  }
`;

export const GET_SAS_CATEGORIES = gql`
  ${CATEGORIES_RESPONSE}
  query sasCategories {
    sasCategories {
      ...SasCategory
    }
  }
`;

export const GET_SAS_CATEGORY_DROPDOWN = gql`
  query sasCategories($isRsfc: Boolean) {
    sasCategories(isRsfc: $isRsfc) {
      id
      name
    }
  }
`;

export const GET_PAGED_SAS_CATEGORIES = gql`
  ${CATEGORIES_RESPONSE}
  query sasPagedCategories(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $ids: [ID]
  ) {
    sasPagedCategories(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      ids: $ids
    ) {
      data {
        ...SasCategory
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_SAS_CATEGORIE = gql`
  ${CATEGORIES_RESPONSE}
  query sasCategory($id: ID!) {
    sasCategory(id: $id) {
      ...SasCategory
    }
  }
`;

export const CREATE_SAS_CATEGORY = gql`
  ${CATEGORIES_RESPONSE}
  mutation sasCategoryCreate($isSensitive: Boolean, $name: [String], $verticalId: ID) {
    sasCategoryCreate(isSensitive: $isSensitive, name: $name, verticalId: $verticalId) {
      ...SasCategory
    }
  }
`;

export const UPDATE_SAS_CATEGORY = gql`
  ${CATEGORIES_RESPONSE}
  mutation sasCategoryUpdate($id: ID!, $isSensitive: Boolean, $name: String, $verticalId: ID) {
    sasCategoryUpdate(id: $id, isSensitive: $isSensitive, name: $name, verticalId: $verticalId) {
      ...SasCategory
    }
  }
`;

export const DELETE_SAS_CATEGORY = gql`
  mutation sasCategoryDelete($id: [ID]) {
    sasCategoryDelete(id: $id)
  }
`;

export const SAS_CREATE_CAMPAIGN_FROM_CATEGORY = gql`
  mutation sasCampaignFromCategory(
    $categoryIds: [ID]!
    $platformId: ID!
    $countryId: ID!
    $languageId: ID!
    $device: SasDevice!
    $suffix: String!
    $domainId: [ID]
    $pixel: String!
  ) {
    sasCampaignFromCategory(
      categoryIds: $categoryIds
      platformId: $platformId
      countryId: $countryId
      languageId: $languageId
      device: $device
      suffix: $suffix
      domainId: $domainId
      pixel: $pixel
    )
  }
`;

const KEYWORDS_RESPONSE = gql`
  fragment Keyword on Keyword {
    id
    category {
      id
      name
    }
    isSensitive
    name
    status
    keywordSource {
      id
      sourceName
    }
    language {
      id
      isoCode
      name
    }
  }
`;
const KEYWORDS_DROPDOWN_RESPONSE = gql`
  fragment Keyword_Dropdown on Keyword {
    id
    name
  }
`;

export const GET_SAS_KEYWORDS = gql`
  ${KEYWORDS_RESPONSE}
  query sasKeywords(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $categoryId: [ID]
    $verticalId: ID
    $groupId: ID
    $setId: ID
    $ids: [ID]
  ) {
    sasKeywords(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      categoryId: $categoryId
      verticalId: $verticalId
      groupId: $groupId
      setId: $setId
      ids: $ids
    ) {
      data {
        ...Keyword
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_CATEGORY_BY_VERTICALID = gql`
  ${CATEGORIES_RESPONSE}
  query sasCategoriesByVerticalId($varticalId: ID) {
    sasCategoriesByVerticalId(varticalId: $varticalId) {
      ...SasCategory
    }
  }
`;

export const GET_CATEGORY_BY_IDS = gql`
  ${CATEGORIES_RESPONSE}
  query sasCategoriesByIds($id: [ID]) {
    sasCategoriesByIds(id: $id) {
      ...SasCategory
    }
  }
`;

export const GET_SAS_KEYWORDS_BY_CATEGORY = gql`
  ${KEYWORDS_RESPONSE}
  query sasKeywordByCategory($categoryId: ID!) {
    sasKeywordByCategory(categoryId: $categoryId) {
      ...Keyword
    }
  }
`;

export const GET_SAS_KEYWORDS_BY_IDS = gql`
  query sasKeywordByIds($id: [ID]) {
    sasKeywordByIds(id: $id) {
      id
      category {
        id
        name
      }
      isSensitive
      name

      keywordSource {
        id
        sourceName
      }
      language {
        id
        isoCode
        name
      }
    }
  }
`;

export const GET_SAS_KEYWORDS_BY_CATEGORY_DROPDOWN = gql`
  ${KEYWORDS_DROPDOWN_RESPONSE}
  query sasKeywordByCategory($categoryId: ID!) {
    sasKeywordByCategory(categoryId: $categoryId) {
      ...Keyword_Dropdown
    }
  }
`;

export const CREATE_SAS_KEYWORD = gql`
  ${KEYWORDS_RESPONSE}
  mutation sasKeywordCreate(
    $categoryId: ID
    $isSensitive: Boolean
    $name: [String]
    $status: SasStatus
    $keywordSourceId: ID
    $languageId: ID
  ) {
    sasKeywordCreate(
      categoryId: $categoryId
      isSensitive: $isSensitive
      name: $name
      status: $status
      keywordSourceId: $keywordSourceId
      languageId: $languageId
    ) {
      ...Keyword
    }
  }
`;

export const UPDATE_SAS_KEYWORD = gql`
  ${KEYWORDS_RESPONSE}
  mutation sasKeywordUpdate(
    $id: ID!
    $categoryId: ID
    $isSensitive: Boolean
    $name: String
    $status: SasStatus
    $keywordSourceId: ID
    $languageId: ID
  ) {
    sasKeywordUpdate(
      id: $id
      categoryId: $categoryId
      isSensitive: $isSensitive
      name: $name
      status: $status
      keywordSourceId: $keywordSourceId
      languageId: $languageId
    ) {
      ...Keyword
    }
  }
`;

export const DELETE_SAS_KEYWORD = gql`
  mutation sasKeywordDelete($id: [ID]) {
    sasKeywordDelete(id: $id)
  }
`;

export const ASSIGN_CATEGORY_TO_KEYWORDS = gql`
  mutation sasCategoryAssignKeyword($categoryId: ID, $keywordId: [ID]) {
    sasCategoryAssignKeyword(categoryId: $categoryId, keywordId: $keywordId)
  }
`;

export const GQL_SAS_ASSIGN_CATEGORY_TO_SITE = gql`
  mutation sasAssignCategoryToSite($lookCategoryId: ID!, $siteIds: [ID!]) {
    sasAssignCategoryToSite(lookCategoryId: $lookCategoryId, siteIds: $siteIds)
  }
`;

export const ASSIGN_CATEGORY_TO_DOMAINS = gql`
  mutation sasCategoryAssignDomain($lookCategoryId: ID, $domainId: [ID]) {
    sasCategoryAssignDomain(lookCategoryId: $lookCategoryId, domainId: $domainId)
  }
`;

export const ASSIGN_PLATFORM_TO_DOMAINS = gql`
  mutation sasPlatformAssignDomain($domainId: [ID], $platformId: [ID]) {
    sasPlatformAssignDomain(domainId: $domainId, platformId: $platformId)
  }
`;

export const ASSIGN_PIXEL_TO_DOMAINS = gql`
  mutation sasPixelAssignDomain($domainId: [ID], $pixelId: [ID]) {
    sasPixelAssignDomain(domainId: $domainId, pixelId: $pixelId)
  }
`;

export const UPDATE_CATEGORY_TO_DOMAINS = gql`
  mutation SasUpdateDomainCategory($domainId: ID!, $platformId: ID!, $categoryId: ID) {
    sasUpdateDomainCategory(domainId: $domainId, platformId: $platformId, categoryId: $categoryId)
  }
`;

export const SAS_CREATE_KEYWORD_CATEGORY_GROUP = gql`
  mutation sasCreateGroup($keywordCategory: [sasKeywordCategory], $name: String) {
    sasCreateGroup(keywordCategory: $keywordCategory, name: $name)
  }
`;

export const SAS_DELETE_KEYWORD_CATEGORY_GROUP = gql`
  mutation sasDeleteGroup($id: [ID]) {
    sasDeleteGroup(id: $id)
  }
`;

export const SAS_DELETE_KEYWORD_CATEGORY_SET = gql`
  mutation sasDeleteSets($id: [ID]) {
    sasDeleteSets(id: $id)
  }
`;

export const SAS_LANGUAGE_TRANSLATOR = gql`
  query translator_language_convertor($from: String, $text: String!, $to: String) {
    translator_language_convertor(from: $from, text: $text, to: $to)
  }
`;

export const GET_SAS_DOMAIN_BY_PIXELID = gql`
  ${DOMAIN_RESPONSE}
  query sasDomainByPixels(
    $column: String
    $order: order
    $page: Int
    $pixelId: ID
    $search: String
    $size: Int
  ) {
    sasDomainByPixels(
      column: $column
      order: $order
      page: $page
      pixelId: $pixelId
      search: $search
      size: $size
    ) {
      data {
        ...SasDomain
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

const PLATFORM_RESPONSE = gql`
  fragment Platform on Platform {
    id
    name
    type
    nickName
    vendor_platform
  }
`;

export const GET_SAS_PLATFORM = gql`
  ${PLATFORM_RESPONSE}
  query sasPlatforms {
    sasPlatforms {
      ...Platform
    }
  }
`;

export const CREATE_SAS_PLATFORM = gql`
  ${PLATFORM_RESPONSE}
  mutation sasPlatformCreate($name: String, $type: PlatformType, $nickName: String) {
    sasPlatformCreate(name: $name, type: $type, nickName: $nickName) {
      ...Platform
    }
  }
`;

export const UPDATE_SAS_PLATFORM = gql`
  ${PLATFORM_RESPONSE}
  mutation sasPlatformUpdate($id: ID, $name: String, $type: PlatformType, $nickName: String) {
    sasPlatformUpdate(id: $id, name: $name, type: $type, nickName: $nickName) {
      ...Platform
    }
  }
`;

export const DELETE_SAS_PLATFORM = gql`
  mutation sasPlatformDelete($id: [ID]) {
    sasPlatformDelete(id: $id)
  }
`;

const CONTENT_RESPONSE = gql`
  fragment SasContent on SasContent {
    id
    language {
      id
      isoCode
      name
    }
    name
    vendor {
      id
      name
    }
    contentCode
  }
`;

export const GET_SAS_CONTENT = gql`
  ${CONTENT_RESPONSE}
  query sasContents {
    sasContents {
      ...SasContent
    }
  }
`;

const CONTENT_FOR_LAUNCH_RESPONSE = gql`
  fragment SasContent on SasContent {
    id
    name
    contentCode
  }
`;

export const GET_SAS_CONTENT_FOR_LAUNCH = gql`
  ${CONTENT_FOR_LAUNCH_RESPONSE}
  query sasContents {
    sasContents {
      ...SasContent
    }
  }
`;

export const CREATE_SAS_CONTENT = gql`
  ${CONTENT_RESPONSE}
  mutation sasContentCreate($languageId: ID, $name: String, $vendorId: ID, $contentCode: String) {
    sasContentCreate(
      languageId: $languageId
      name: $name
      vendorId: $vendorId
      contentCode: $contentCode
    ) {
      ...SasContent
    }
  }
`;

export const UPDATE_SAS_CONTENT = gql`
  ${CONTENT_RESPONSE}
  mutation sasContentUpdate(
    $id: ID!
    $languageId: ID
    $name: String
    $vendorId: ID
    $contentCode: String
  ) {
    sasContentUpdate(
      id: $id
      languageId: $languageId
      name: $name
      vendorId: $vendorId
      contentCode: $contentCode
    ) {
      ...SasContent
    }
  }
`;

export const DELETE_SAS_CONTENT = gql`
  mutation sasContentDelete($id: [ID]) {
    sasContentDelete(id: $id)
  }
`;

const NATIVE_CAMPAIGN_RESPONSE = gql`
  fragment NcId on NcId {
    id
    campaignId
    name
    keywordCount
    url
    device
    trackingDomain
    status
    title
    description
    tagId
    labelValue
    createdAt
    isSetFixed
    content {
      id
      name
      contentCode
    }
    pixel {
      id
      pixelValue
      pixelName
    }
    domains {
      id
      name
      status
      weight
    }
    platform {
      id
      name
    }
    country {
      id
      name
      iso_code
    }
    language {
      id
      name
      isoCode
    }
    category {
      id
      isSensitive
      name
      vertical {
        id
        name
        isSensitive
      }
    }
    forPmax
  }
`;

export const GET_SAS_NATIVE_CAMPAIGN = gql`
  ${NATIVE_CAMPAIGN_RESPONSE}
  query sasNcIds(
    $column: String
    $order: order
    $page: Int
    $platformId: ID
    $search: String
    $size: Int
    $userId: ID
  ) {
    sasNcIds(
      column: $column
      order: $order
      page: $page
      platformId: $platformId
      search: $search
      size: $size
      userId: $userId
    ) {
      data {
        ...NcId
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_SAS_NC_BY_ID = gql`
  ${NATIVE_CAMPAIGN_RESPONSE}
  query sasNcId($id: ID!) {
    sasNcId(id: $id) {
      ...NcId
      channelId
    }
  }
`;

export const GET_SAS_NC_KEYWORDS_BY_ID = gql`
  query sasNcId($id: ID!) {
    sasNcId(id: $id) {
      keywords {
        id
        name
        status
      }
    }
  }
`;

export const GET_SAS_NC_CATEGORY_BY_IDS = gql`
  query sasNcIdByIds($id: [ID]) {
    sasNcIdByIds(id: $id) {
      id
      name
      category {
        name
        id
      }
    }
  }
`;
//forPmax is used for Performance max campaign is used below
export const CREATE_SAS_NATIVE_CAMPAIGN = gql`
  ${NATIVE_CAMPAIGN_RESPONSE}
  mutation sasNcIdCreate(
    $campaignId: String
    $domains: [nc_domains]
    $device: SasDevice
    $keywords: [nc_keywords]
    $categoryId: ID
    $name: String
    $platformId: ID
    $countryId: ID
    $languageId: ID
    $status: SasStatus
    $trackingDomain: String
    $keywordCount: Int
    $contentCode: String
    $pixel: String
    $title: String
    $site: String
    $siteId: ID
    $description: String
    $forPmax: Boolean
    $labelValue: String
    $isSetFixed: Boolean
    $tagId: String
  ) {
    sasNcIdCreate(
      campaignId: $campaignId
      domains: $domains
      keywords: $keywords
      device: $device
      categoryId: $categoryId
      name: $name
      platformId: $platformId
      countryId: $countryId
      languageId: $languageId
      status: $status
      trackingDomain: $trackingDomain
      isSetFixed: $isSetFixed
      keywordCount: $keywordCount
      contentCode: $contentCode
      pixel: $pixel
      title: $title
      site: $site
      description: $description
      siteId: $siteId
      forPmax: $forPmax
      labelValue: $labelValue
      tagId: $tagId
    ) {
      ...NcId
    }
  }
`;

export const UPDATE_SAS_NATIVE_CAMPAIGN = gql`
  ${NATIVE_CAMPAIGN_RESPONSE}
  mutation sasNcIdUpdate(
    $id: ID!
    $campaignId: String
    $domains: [nc_domains]
    $keywords: [nc_keywords]
    $device: SasDevice
    $categoryId: ID
    $name: String
    $platformId: ID
    $countryId: ID
    $languageId: ID
    $status: SasStatus
    $trackingDomain: String
    $keywordCount: Int
    $contentCode: String
    $pixel: String
    $title: String
    $description: String
    $forPmax: Boolean
    $labelValue: String
    $tagId: String
    $isSetFixed: Boolean
  ) {
    sasNcIdUpdate(
      id: $id
      campaignId: $campaignId
      domains: $domains
      keywords: $keywords
      device: $device
      categoryId: $categoryId
      name: $name
      platformId: $platformId
      countryId: $countryId
      languageId: $languageId
      status: $status
      trackingDomain: $trackingDomain
      keywordCount: $keywordCount
      contentCode: $contentCode
      pixel: $pixel
      title: $title
      description: $description
      forPmax: $forPmax
      labelValue: $labelValue
      tagId: $tagId
      isSetFixed: $isSetFixed
    ) {
      ...NcId
    }
  }
`;

const CAMPAIGN_KEYWORDS_RESPONSE = gql`
  fragment campaign_keywords on campaign_keywords {
    id
    name
    isForCMS
    status
    weight
  }
`;

export const GQL_SAS_KEYWORDS_BY_NC_ID = gql`
  ${CAMPAIGN_KEYWORDS_RESPONSE}
  query sasKeywordsByNcid($id: ID!) {
    sasKeywordsByNcid(id: $id) {
      ...campaign_keywords
    }
  }
`;

export const SAS_ASSIGN_KEYWORD_TO_CAMPAIGN = gql`
  ${CAMPAIGN_KEYWORDS_RESPONSE}
  mutation sasAssignKeywordCampaign($id: ID!, $keywords: [nc_keywords]) {
    sasAssignKeywordCampaign(id: $id, keywords: $keywords) {
      ...campaign_keywords
    }
  }
`;

export const DELETE_SAS_NATIVE_CAMPAIGN = gql`
  mutation sasNcIdDelete($id: [ID]) {
    sasNcIdDelete(id: $id)
  }
`;

export const CLONE_NATIVE_CAMPAIGN = gql`
  ${NATIVE_CAMPAIGN_RESPONSE}
  mutation sasNcIdClone(
    $id: [ID!]
    $trackingDomain: String
    $count: Int
    $pixel: String
    $categoryId: ID
  ) {
    sasNcIdClone(
      id: $id
      count: $count
      trackingDomain: $trackingDomain
      pixel: $pixel
      categoryId: $categoryId
    ) {
      ...NcId
    }
  }
`;

const URL_BUILDER_RESPONSE = gql`
  fragment urlBuilderData on urlBuilderData {
    categories {
      id
      name
    }
    countries {
      id
      name
      iso_code
    }
    device
    feeds {
      id
      name
      vendors {
        id
        name
      }
      feedVendors {
        id
        name
      }
    }
    languages {
      id
      name
      isoCode
    }
    platforms {
      id
      name
    }
    vendors {
      id
      name
    }
  }
`;

export const GET_URL_BUILDER_DATA = gql`
  ${URL_BUILDER_RESPONSE}
  query sasFetchDataUrlBuilder {
    sasFetchDataUrlBuilder {
      ...urlBuilderData
    }
  }
`;

export const CREATE_SAS_UNIQUE_ID = gql`
  mutation sasCreateuniqueId(
    $categoryId: ID
    $countryId: ID
    $device: SasDevice
    $domainId: ID
    $keywordsId: [ID]
    $languageId: ID
    $platformId: ID
    $adsetName: [String]
  ) {
    sasCreateuniqueId(
      categoryId: $categoryId
      countryId: $countryId
      device: $device
      domainId: $domainId
      keywordsId: $keywordsId
      languageId: $languageId
      platformId: $platformId
      adsetName: $adsetName
    ) {
      id
      category {
        id
        name
      }
      adsetName
      country {
        id
        name
        iso_code
      }
      device
      domain {
        id
        name
        nickName
        url
      }
      keyword {
        id
        name
      }
      language {
        id
        name
      }
      platform {
        id
        name
      }
    }
  }
`;

const SAS_BUY_DOMAINS_RESPONSE = gql`
  fragment sasBuyDomain on sasBuyDomain {
    id
    domain
    sasDomain {
      id
      name
    }
    vendor {
      id
      name
    }
    platform {
      id
      name
    }
    lookCategoryId
    status
    buy_message
    vendor_message
    daTrackingId
    daCampaignId
  }
`;

export const GET_SAS_BUY_DOMAINS = gql`
  ${SAS_BUY_DOMAINS_RESPONSE}
  query sasBuyDomains(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $vendorId: ID
    $type: sasPurchasedDomain
  ) {
    sasBuyDomains(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      vendorId: $vendorId
      type: $type
    ) {
      data {
        ...sasBuyDomain
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const ASSIGN_DOMAIN_TO_VENDOR_ACCOUNT = gql`
  mutation sasDomainAssignToVendorAccount($buyDomainId: [ID], $vendorAccountId: ID) {
    sasDomainAssignToVendorAccount(buyDomainId: $buyDomainId, vendorAccountId: $vendorAccountId)
  }
`;

export const CREATE_SAS_BUY_DOMAIN = gql`
  ${SAS_BUY_DOMAINS_RESPONSE}
  mutation sasBuyDomainCreate(
    $domain: [String]
    $platformId: ID
    $vendorId: ID
    $categoryId: ID
    $languageId: ID
    $countryId: ID
    $offerName: String
    $vendorAccountId: ID
  ) {
    sasBuyDomainCreate(
      domain: $domain
      platformId: $platformId
      vendorId: $vendorId
      categoryId: $categoryId
      countryId: $countryId
      languageId: $languageId
      offerName: $offerName
      vendorAccountId: $vendorAccountId
    ) {
      ...sasBuyDomain
    }
  }
`;

const PIXELS_RESPONSE = gql`
  fragment SasPixel on SasPixel {
    id
    accessToken
    bmName
    pixelDomains {
      id
      name
    }
    pixelName
    pixelValue
    platform {
      id
      name
    }
    sedoAlias
    vendorAccount {
      id
      name
    }
  }
`;

export const GET_SAS_PIXELS = gql`
  ${PIXELS_RESPONSE}
  query sasPixels {
    sasPixels {
      ...SasPixel
    }
  }
`;

export const GET_SAS_PIXELS_BY_PLATFORM = gql`
  ${PIXELS_RESPONSE}
  query sasPixelsByPlaform($platformId: ID) {
    sasPixelsByPlaform(platformId: $platformId) {
      ...SasPixel
    }
  }
`;

export const CREATE_SAS_PIXEL = gql`
  ${PIXELS_RESPONSE}
  mutation sasPixelCreate(
    $accessToken: String
    $bmName: String
    $domainId: [ID]
    $pixelName: String
    $pixelValue: String
    $platformId: ID
    $sedoAlias: String
    $vendorAccountId: ID
  ) {
    sasPixelCreate(
      accessToken: $accessToken
      bmName: $bmName
      domainId: $domainId
      pixelName: $pixelName
      pixelValue: $pixelValue
      platformId: $platformId
      sedoAlias: $sedoAlias
      vendorAccountId: $vendorAccountId
    ) {
      ...SasPixel
    }
  }
`;

export const UPDATE_SAS_PIXEL = gql`
  ${PIXELS_RESPONSE}
  mutation sasPixelUpdate(
    $id: ID!
    $accessToken: String
    $bmName: String
    $domainId: [ID]
    $pixelName: String
    $pixelValue: String
    $platformId: ID
    $sedoAlias: String
    $vendorAccountId: ID
  ) {
    sasPixelUpdate(
      id: $id
      accessToken: $accessToken
      bmName: $bmName
      domainId: $domainId
      pixelName: $pixelName
      pixelValue: $pixelValue
      platformId: $platformId
      sedoAlias: $sedoAlias
      vendorAccountId: $vendorAccountId
    ) {
      ...SasPixel
    }
  }
`;

export const DELETE_SAS_PIXEL = gql`
  mutation sasPixelDelete($id: [ID]) {
    sasPixelDelete(id: $id)
  }
`;

const SAS_REPORT_RESPONSE = gql`
  fragment SasReport on SasReport {
    clicks
    date
    device
    ad {
      adAccount {
        adAccountId
        id
      }
      adset {
        adsetId
        id
      }
      campaign {
        campaignId
        id
      }
      adId
      id
    }
    category {
      id
      name
    }
    keywordSource {
      id
      sourceName
    }
    country {
      id
      iso_code
      name
    }
    domain {
      id
      name
      url
    }
    estimate_revenue
    estimate_revenue_p
    clicksP
    eparamData {
      id
      adsetName
    }
    keyword {
      id
      name
    }
    platform {
      id
      name
    }
    searches
    sessions
    nativeCampaign {
      id
      campaignId
      name
    }
    placement {
      id
      siteName
      siteID
    }
    predictedRevenue
    tq
    vendor {
      id
      name
    }
    userId
    user {
      id
      name
    }
  }
`;

const SAS_REPORT_RESPONSE_CONDITIONAL = gql`
  fragment SasReport on SasReport {
    id
    clicks
    date @include(if: $isPerformance)
    device
    adAccountId
    adsetId
    campaignId
    adId
    ad {
      adAccount {
        adAccountId
        id
      }
      adset {
        adsetId
        id
      }
      campaign {
        campaignId
        id
      }
      adId
      id
    }
    category {
      id
      name
    }
    keywordSource {
      id
      sourceName
    }
    country {
      id
      iso_code
      name
    }
    domain {
      id
      name
      url
    }
    estimate_revenue
    eparamData {
      id
      adsetName
    }
    keyword {
      id
      name
    }
    platform {
      id
      name
    }
    searches
    sessions
    nativeCampaign {
      id
      campaignId
      name
    }
    placement {
      id
      siteName
      siteID
    }
    tq
    vendor {
      id
      name
    }
    userId
    user {
      id
      name
    }
  }
`;
export const GQL_CH_SAS_AD_REPORT = gql`
  ${SAS_REPORT_RESPONSE_CONDITIONAL}
  query ch_sasAdReports(
    $adAccountId: ID
    $adsetId: ID
    $campaignId: ID
    $from: Date!
    $groupBy: SasGroupBy!
    $ncId: ID
    $platformId: ID
    $timezone: String!
    $to: Date!
    $type: sasReportType!
    $userId: ID
    $vendorId: [ID]
    # Frontend Condtions
    $isPerformance: Boolean = false
  ) {
    ch_sasAdReports(
      adAccountId: $adAccountId
      adsetId: $adsetId
      campaignId: $campaignId
      from: $from
      groupBy: $groupBy
      ncId: $ncId
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
      userId: $userId
      vendorId: $vendorId
    ) {
      latestSyncTime
      reportPublished
      data {
        ...SasReport
      }
    }
  }
`;

export const GET_SAS_DAILY_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasDailyReports($from: Date, $to: Date, $userId: ID, $platformId: ID, $vendorId: ID) {
    sasDailyReports(
      from: $from
      to: $to
      platformId: $platformId
      userId: $userId
      vendorId: $vendorId
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_LIVE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasLiveReports($from: Date, $to: Date, $userId: ID, $platformId: ID, $vendorId: ID) {
    sasLiveReports(
      from: $from
      to: $to
      platformId: $platformId
      userId: $userId
      vendorId: $vendorId
    ) {
      data {
        ...SasReport
      }
      latestSyncTime
      reportPublished
    }
  }
`;

export const GET_SAS_CAMPAIGN_LIVE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasCampaignLiveReports(
    $from: Date
    $to: Date
    $userId: ID
    $platformId: ID
    $vendorId: ID
    $timezone: String
  ) {
    sasCampaignLiveReports(
      from: $from
      to: $to
      platformId: $platformId
      userId: $userId
      vendorId: $vendorId
      timezone: $timezone
    ) {
      data {
        ...SasReport
      }
      latestSyncTime
      reportPublished
    }
  }
`;

export const GET_SAS_POSTBACK_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasCampaignPostBackReports(
    $from: Date
    $to: Date
    $userId: ID
    $platformId: ID
    $vendorId: ID
    $timezone: String
    $type: sasReportType!
  ) {
    sasCampaignPostBackReports(
      from: $from
      to: $to
      platformId: $platformId
      userId: $userId
      vendorId: $vendorId
      timezone: $timezone
      type: $type
    ) {
      data {
        ...SasReport
      }
      latestSyncTime
      reportPublished
    }
  }
`;

export const GET_SAS_AD_DOMAIN_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasAdDomainReports(
    $from: Date
    $platformId: ID
    $timezone: String
    $to: Date
    $type: sasReportType!
    $userId: ID
    $vendorId: ID
    $ncId: ID
  ) {
    sasAdDomainReports(
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
      userId: $userId
      vendorId: $vendorId
      ncId: $ncId
    ) {
      ...SasReport
      discrepancy
    }
  }
`;

export const GET_SAS_AD_KEYWORD_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasAdKeywordReports(
    $from: Date!
    $platformId: ID
    $timezone: String
    $to: Date!
    $userId: ID
    $vendorId: [ID]
    $ncId: ID
    $type: sasReportType!
  ) {
    sasAdKeywordReports(
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      userId: $userId
      vendorId: $vendorId
      ncId: $ncId
      type: $type
    ) {
      countryName
      ...SasReport
    }
  }
`;

export const GET_SAS_DAILY_POSTBACK_DISCREPANCY_REPORT = gql`
  query sasDailyPostbackDiscrepancy(
    $assetId: ID
    $from: Date
    $groupBy: SasGroupBy!
    $timezone: String
    $to: Date
  ) {
    sasDailyPostbackDiscrepancy(
      assetId: $assetId
      from: $from
      groupBy: $groupBy
      timezone: $timezone
      to: $to
    ) {
      d_clicks
      d_estimate_revenue
      d_searches
      d_sessions
      date
      p_clicks
      p_estimate_revenue
      p_searches
      p_sessions
    }
  }
`;

export const GET_SAS_DISCREPANCY_REPORT_TYPE_DOMAIN = gql`
  query SasDomainDiscrepancy(
    $domainId: ID!
    $platformId: ID!
    $timezone: String!
    $to: Date!
    $from: Date!
  ) {
    sasDomainDiscrepancy(
      domainId: $domainId
      platformId: $platformId
      timezone: $timezone
      to: $to
      from: $from
    ) {
      d_clicks
      d_estimate_revenue
      d_searches
      d_sessions
      date
      p_clicks
      p_estimate_revenue
      p_searches
      p_sessions
    }
  }
`;

export const GET_SAS_DISCREPANCY_REPORT_TYPE_CATEGORY = gql`
  query SasCategoryDiscrepancy(
    $categoryId: ID!
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
  ) {
    sasCategoryDiscrepancy(
      categoryId: $categoryId
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
    ) {
      d_clicks
      d_estimate_revenue
      d_searches
      d_sessions
      date
      p_clicks
      p_estimate_revenue
      p_searches
      p_sessions
    }
  }
`;

export const GET_SAS_TIKTOK_DATE_REPORT = gql`
  query tiktok_getDateReport(
    $assetId: String
    $end: Date
    $hourlyType: tiktok_hourly_type
    $start: Date!
    $timezone: String
  ) {
    tiktok_getDateReport(
      assetId: $assetId
      end: $end
      hourlyType: $hourlyType
      start: $start
      timezone: $timezone
    ) {
      ad_id
      ad_name
      adgroup_id
      adgroup_name
      campaign_id
      campaign_name
      clicks
      conversion
      impressions
      reach
      report_time
      spend
    }
  }
`;

export const GET_SAS_KEYWORD_PERFORMANCE_REPORTS = gql`
  ${SAS_REPORT_RESPONSE}
  query sasAdKeywordPerformanceReport(
    $from: Date
    $isHourly: Boolean
    $to: Date
    $keywordId: ID
    $timezone: String
    $type: sasReportType!
  ) {
    sasAdKeywordPerformanceReport(
      from: $from
      isHourly: $isHourly
      to: $to
      keywordId: $keywordId
      timezone: $timezone
      type: $type
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_DOMAIN_PERFORMANCE_REPORTS = gql`
  ${SAS_REPORT_RESPONSE}
  query sasAdDomainPerformanceReport(
    $from: Date
    $to: Date
    $domainId: ID
    $timezone: String
    $type: sasReportType!
    $isHourly: Boolean
  ) {
    sasAdDomainPerformanceReport(
      from: $from
      to: $to
      domainId: $domainId
      timezone: $timezone
      type: $type
      isHourly: $isHourly
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_CAMPAIGN_DAILY_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasDailyCampaignReports(
    $from: Date
    $to: Date
    $userId: ID
    $platformId: ID
    $vendorId: ID
    $timezone: String
  ) {
    sasDailyCampaignReports(
      from: $from
      to: $to
      platformId: $platformId
      userId: $userId
      vendorId: $vendorId
      timezone: $timezone
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_PLACEMENT_LIVE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasPlacementLiveReports(
    $from: Date
    $to: Date
    $userId: ID
    $platformId: ID
    $vendorId: ID
    $campaignId: ID
  ) {
    sasPlacementLiveReports(
      campaignId: $campaignId
      from: $from
      to: $to
      platformId: $platformId
      userId: $userId
      vendorId: $vendorId
    ) {
      data {
        ...SasReport
      }
      latestSyncTime
    }
  }
`;

export const GET_SAS_PLACEMENT_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasPlacementReports(
    $campaignId: [ID]!
    $from: Date!
    $platformId: ID
    $timezone: String
    $to: Date!
    $type: sasReportType!
    $userId: ID
    $vendorId: ID
  ) {
    sasPlacementReports(
      campaignId: $campaignId
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
      userId: $userId
      vendorId: $vendorId
    ) {
      data {
        ...SasReport
      }
      latestSyncTime
    }
  }
`;

export const GET_SAS_KEYWORDS_LIVE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasKeywordCampaignLiveReports($campaignId: ID, $from: Date, $to: Date, $userId: ID) {
    sasKeywordCampaignLiveReports(campaignId: $campaignId, from: $from, to: $to, userId: $userId) {
      data {
        ...SasReport
      }
      latestSyncTime
    }
  }
`;

export const GET_SAS_KEYWORDS_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasKeywordCampaignReports(
    $campaignId: ID!
    $from: Date!
    $to: Date!
    $timezone: String
    $type: sasReportType!
    $userId: ID
    $assetId: String
    $assetType: SasGroupBy
  ) {
    sasKeywordCampaignReports(
      campaignId: $campaignId
      from: $from
      to: $to
      timezone: $timezone
      type: $type
      userId: $userId
      assetId: $assetId
      assetType: $assetType
    ) {
      data {
        ...SasReport
        clicksP
        estimate_revenue_p
        isKeywordAdded
        campignKeywordStatus
      }
    }
  }
`;

export const GET_SAS_DAILY_PERFORMANCE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasDailyPerformanceReport(
    $assetId: ID
    $from: Date
    $groupBy: SasGroupBy!
    $timezone: String
    $to: Date
  ) {
    sasDailyPerformanceReport(
      assetId: $assetId
      from: $from
      groupBy: $groupBy
      timezone: $timezone
      to: $to
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_LIVE_PERFORMANCE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasLivePerformanceReport(
    $assetId: String!
    $from: Date!
    $groupBy: SasGroupBy!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
  ) {
    sasLivePerformanceReport(
      assetId: $assetId
      from: $from
      groupBy: $groupBy
      timezone: $timezone
      to: $to
      type: $type
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_POSTBACK_DAILY_PERFORMANCE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasPostbackDailyPerformanceReport(
    $assetId: String!
    $from: Date!
    $groupBy: SasGroupBy!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
  ) {
    sasPostbackDailyPerformanceReport(
      assetId: $assetId
      from: $from
      groupBy: $groupBy
      timezone: $timezone
      to: $to
      type: $type
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_CATEGORIES_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasCategoriesReport(
    $from: Date
    $timezone: String
    $to: Date
    $type: sasReportType!
    $vendorId: [ID]
    $platformId: ID
  ) {
    sasCategoriesReport(
      from: $from
      timezone: $timezone
      to: $to
      type: $type
      vendorId: $vendorId
      platformId: $platformId
    ) {
      ...SasReport
      discrepancy
    }
  }
`;

const ADSET_REPORT_RESPONSE = gql`
  fragment ReportAdSetByName on ReportAdSetByName {
    adSetName
    clicksFB
    date
    impressionsFB
    leadsFB
    spendFB
  }
`;

export const GET_REPORT_BY_ADSET_NAME = gql`
  ${ADSET_REPORT_RESPONSE}
  query reportByAdSetName(
    $from: String!
    $to: String!
    $timezone: String!
    $userId: ID
    $vendorId: ID
  ) {
    reportByAdSetName(
      from: $from
      to: $to
      timezone: $timezone
      userId: $userId
      vendorId: $vendorId
    ) {
      ...ReportAdSetByName
    }
  }
`;

export const GET_REPORT_BY_ADSET_NAME_TIME = gql`
  ${ADSET_REPORT_RESPONSE}
  query reportByAdSetNameTime($from: String!, $to: String!, $timezone: String!, $userId: ID) {
    reportByAdSetNameTime(from: $from, to: $to, timezone: $timezone, userId: $userId) {
      ...ReportAdSetByName
    }
  }
`;

export const GET_REPORT_PER_ADSET_NAME = gql`
  ${ADSET_REPORT_RESPONSE}
  query reportPerAdSetName(
    $adSetName: String!
    $from: String!
    $to: String!
    $timezone: String!
    $userId: ID
  ) {
    reportPerAdSetName(
      adSetName: $adSetName
      from: $from
      to: $to
      timezone: $timezone
      userId: $userId
    ) {
      ...ReportAdSetByName
    }
  }
`;

export const GET_REPORT_PER_AD_SET_HOURLY = gql`
  ${ADSET_REPORT_RESPONSE}
  query reportPerAdSetHourly(
    $adSetName: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdSetHourly(
      adSetName: $adSetName
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportAdSetByName
    }
  }
`;

export const SITE_REPORT_BY_CAMPAIGN = gql`
  query native_SiteReportByCampaign(
    $from: String!
    $pCampaignId: ID!
    $platformId: ID!
    $to: String!
    $userId: ID
  ) {
    native_SiteReportByCampaign(
      from: $from
      pCampaignId: $pCampaignId
      platformId: $platformId
      to: $to
      userId: $userId
    ) {
      bids {
        bidModification
        site
      }
      data {
        bid
        clicks
        impressions
        objective
        pCampaignId
        reportTime
        sasId
        site
        siteId
        spent
        conversions
        cpc
        cpm
        ctr
        vCpm
        vCtr
        vImpressions
      }
      excludes
      includes
    }
  }
`;

export const GET_SAS_USER_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasUserReports(
    $from: Date
    $platformId: ID
    $to: Date
    $vendorId: [ID]
    $timezone: String
    $type: sasReportType
  ) {
    sasUserReports(
      from: $from
      platformId: $platformId
      to: $to
      vendorId: $vendorId
      timezone: $timezone
      type: $type
    ) {
      ...SasReport
      userName
    }
  }
`;

export const GET_SAS_REPORT_BY_USER_ID = gql`
  query reportByUser($from: String!, $timezone: String!, $to: String!, $userId: ID, $vendorId: ID) {
    reportByUser(from: $from, timezone: $timezone, to: $to, userId: $userId, vendorId: $vendorId) {
      clicksFB
      date
      impressionsFB
      leadsFB
      spendFB
      # user {
      #   id
      #   name
      # }
      userId
      name
    }
  }
`;

export const GET_SAS_KEYWORD_PERFORMANCE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasKeywordPerformanceReport($from: Date, $to: Date, $eparamId: ID) {
    sasKeywordPerformanceReport(from: $from, to: $to, eparamId: $eparamId) {
      ...SasReport
    }
  }
`;

export const GET_SAS_HOURLY_KEYWORD_PERFORMANCE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasHourlyKeywordPerformanceReport($from: Date, $to: Date, $eparamId: ID) {
    sasHourlyKeywordPerformanceReport(from: $from, to: $to, eparamId: $eparamId) {
      ...SasReport
    }
  }
`;

export const GET_SAS_USER_PERFORMANCE_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasUserPerformanceReports(
    $from: Date
    $to: Date
    $timezone: String
    $userId: ID
    $type: sasReportType
  ) {
    sasUserPerformanceReports(
      from: $from
      to: $to
      timezone: $timezone
      userId: $userId
      type: $type
    ) {
      ...SasReport
    }
  }
`;

export const UPDATE_SELECTED_AD_SET_STATUS_BY_NAME = gql`
  ${ASSET_UPDATE_RESPONSE_RESPONSE}
  mutation updateSelectedAdSetStatusByName($adSetFBNames: [String]!, $status: String!) {
    updateSelectedAdSetStatusByName(adSetFBNames: $adSetFBNames, status: $status) {
      ...AssetUpdateResponse
    }
  }
`;

const SAS_OLD_REPORT_RESPONSE = gql`
  fragment OldSasReport on OldSasReport {
    ad_clicks
    channel
    channel_name2
    channel_name3
    date
    estimated_revenue
    id
    impressions
    raw_revenue
    tq
  }
`;

export const GET_SAS_OLD_DAILY_REPORT = gql`
  ${SAS_OLD_REPORT_RESPONSE}
  query sasOldDailyReports($from: Date, $to: Date, $userId: ID) {
    sasOldDailyReports(from: $from, to: $to, userId: $userId) {
      ...OldSasReport
    }
  }
`;

export const SAS_REPORT_SYNC = gql`
  query sasReportSync($csvfileUrl: String, $reportType: reportType) {
    sasReportSync(csvfileUrl: $csvfileUrl, reportType: $reportType)
  }
`;

export const SAS_CAMPAIGN_DOMAIN_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasCampaignDomainReports(
    $from: Date
    $to: Date
    $campaignId: ID
    $timezone: String
    $type: sasReportType
    $assetId: String
    $assetType: SasGroupBy
  ) {
    sasCampaignDomainReports(
      from: $from
      to: $to
      campaignId: $campaignId
      timezone: $timezone
      type: $type
      assetId: $assetId
      assetType: $assetType
    ) {
      ...SasReport
      isDomainAdded
      clicksP
      estimate_revenue_p
      campignDomainStatus
      discrepancy
    }
  }
`;

const KEYWORD_SOURCES_RESPONSE = gql`
  fragment SasKeywordSource on SasKeywordSource {
    id
    keywordSourceType {
      id
      description
      name
    }
    sourceName
    sourceUrl
  }
`;

export const GET_SAS_KEYWORD_SOURCES = gql`
  ${KEYWORD_SOURCES_RESPONSE}
  query sasKeywordSources {
    sasKeywordSources {
      ...SasKeywordSource
    }
  }
`;

export const CREATE_SAS_KEYWORD_SOURCE = gql`
  ${KEYWORD_SOURCES_RESPONSE}
  mutation sasKeywordSourceCreate(
    $keywordSourceTypeId: ID
    $sourceName: String
    $sourceUrl: String
  ) {
    sasKeywordSourceCreate(
      keywordSourceTypeId: $keywordSourceTypeId
      sourceName: $sourceName
      sourceUrl: $sourceUrl
    ) {
      ...SasKeywordSource
    }
  }
`;

export const UPDATE_SAS_KEYWORD_SOURCE = gql`
  ${KEYWORD_SOURCES_RESPONSE}
  mutation sasKeywordSourceUpdate(
    $id: ID!
    $keywordSourceTypeId: ID
    $sourceName: String
    $sourceUrl: String
  ) {
    sasKeywordSourceUpdate(
      id: $id
      keywordSourceTypeId: $keywordSourceTypeId
      sourceName: $sourceName
      sourceUrl: $sourceUrl
    ) {
      ...SasKeywordSource
    }
  }
`;

export const DELETE_SAS_KEYWORD_SOURCE = gql`
  mutation sasKeywordSourceDelete($id: [ID]) {
    sasKeywordSourceDelete(id: $id)
  }
`;

const KEYWORD_SOURCE_TYPE_RESPONSE = gql`
  fragment SasKeywordSourceType on SasKeywordSourceType {
    id
    name
    description
  }
`;

export const GET_KEYWORD_SOURCE_TYPES = gql`
  ${KEYWORD_SOURCE_TYPE_RESPONSE}
  query sasKeywordSourceTypes {
    sasKeywordSourceTypes {
      ...SasKeywordSourceType
    }
  }
`;

export const CREATE_SAS_KEYWORD_SOURCE_TYPE = gql`
  ${KEYWORD_SOURCE_TYPE_RESPONSE}
  mutation sasKeywordSourceTypeCreate($name: String, $description: String) {
    sasKeywordSourceTypeCreate(name: $name, description: $description) {
      ...SasKeywordSourceType
    }
  }
`;

export const UPDATE_SAS_KEYWORD_SOURCE_TYPE = gql`
  ${KEYWORD_SOURCE_TYPE_RESPONSE}
  mutation sasKeywordSourceTypeUpdate($id: ID!, $name: String, $description: String) {
    sasKeywordSourceTypeUpdate(name: $name, description: $description, id: $id) {
      ...SasKeywordSourceType
    }
  }
`;

export const DELETE_SAS_KEYWORD_SOURCE_TYPE = gql`
  mutation sasKeywordSourceTypeDelete($id: [ID]) {
    sasKeywordSourceTypeDelete(id: $id)
  }
`;

const SAS_ADSET_GROUP_RESPONSE = gql`
  fragment SasGroupLaunch on SasGroupLaunch {
    id
    keywords {
      id
      name
    }
    name
  }
`;

export const GET_SAS_ADSET_GROUPS = gql`
  query sasAdsetGroups($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    sasAdsetGroups(column: $column, order: $order, page: $page, search: $search, size: $size) {
      data {
        id
        name
        keywords {
          id
          name
        }
        user {
          id
          name
        }
        createdAt
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_SAS_PAGED_ALL_SETS = gql`
  query sasPagedSets(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $categoryId: ID
  ) {
    sasPagedSets(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      categoryId: $categoryId
    ) {
      data {
        id
        name
        status
        isTest
        category {
          id
          name
        }
        keywords {
          id
          name
        }
        user {
          id
          name
        }
        language {
          id
          isoCode
          name
        }
        country {
          id
          name
        }
        domains {
          id
          name
        }
        platform {
          id
          name
        }
        createdAt
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const CREATE_SAS_SETS = gql`
  mutation sasCreateSets(
    $name: String!
    $categoryId: ID!
    $isPublic: Boolean
    $languageId: ID
    $countryId: ID
    $platformId: ID
    $isTest: Boolean
  ) {
    sasCreateSets(
      name: $name
      categoryId: $categoryId
      isPublic: $isPublic
      languageId: $languageId
      countryId: $countryId
      platformId: $platformId
      isTest: $isTest
    )
  }
`;

export const BULK_UPDATE_STATUS_SETS = gql`
  mutation sasUpdateBulkStatusSets(
    $categoryId: ID!
    $countryId: ID!
    $platformId: ID!
    $status: SasStatus!
  ) {
    sasUpdateBulkStatusSets(
      categoryId: $categoryId
      countryId: $countryId
      platformId: $platformId
      status: $status
    )
  }
`;

export const UPDATE_SAS_SETS = gql`
  mutation sasUpdateSets(
    $categoryId: ID!
    $countryId: ID
    $languageId: ID
    $id: ID!
    $isPublic: Boolean
    $name: String!
    $platformId: ID
    $isTest: Boolean
  ) {
    sasUpdateSets(
      name: $name
      categoryId: $categoryId
      isPublic: $isPublic
      id: $id
      languageId: $languageId
      countryId: $countryId
      platformId: $platformId
      isTest: $isTest
    )
  }
`;

export const SAS_ADD_KEYWORD_TO_SET = gql`
  mutation sasAddKeywordsToSets(
    $setId: ID!
    $keywords: [String!]
    $categoryId: ID
    $languageId: ID
  ) {
    sasAddKeywordsToSets(
      setId: $setId
      keywords: $keywords
      categoryId: $categoryId
      languageId: $languageId
    )
  }
`;

export const SAS_REMOVE_KEYWORD_FROM_SET = gql`
  mutation sasRemoveKeywordsFromSets($setId: ID!, $keywordId: [ID!]) {
    sasRemoveKeywordsFromSets(setId: $setId, keywordId: $keywordId)
  }
`;

export const SAS_UPDATE_SET_STATUS = gql`
  mutation sasUpdateSetStatus($setId: [ID]!, $status: SasStatus!) {
    sasUpdateSetStatus(setId: $setId, status: $status)
  }
`;

export const SAS_ADD_KEYWORD_TO_GROUP = gql`
  mutation sasAddKeywordsToGroup($groupId: ID!, $keywords: [String!]) {
    sasAddKeywordsToGroup(groupId: $groupId, keywords: $keywords)
  }
`;

export const GET_SAS_ADSET_GROUP_BY_ID = gql`
  ${SAS_ADSET_GROUP_RESPONSE}
  query sasAdsetGroup($id: ID!) {
    sasAdsetGroup(id: $id) {
      ...SasGroupLaunch
    }
  }
`;

export const REPORT_BY_NCID = gql`
  query reportByNCID(
    $from: String!
    $ncId: ID
    $productId: ID!
    $timezone: String!
    $to: String!
    $hasNcId: Boolean!
  ) {
    reportByNCID(
      from: $from
      ncId: $ncId
      productId: $productId
      timezone: $timezone
      to: $to
      hasNcId: $hasNcId
    ) {
      clicksFB
      id
      impressionsFB
      leadsFB
      spendFB
      name
      date
    }
  }
`;

export const NATIVE_DAILY_REPORT = gql`
  query native_DailyReport($from: String!, $platformId: ID!, $to: String!, $userId: ID) {
    native_DailyReport(from: $from, platformId: $platformId, to: $to, userId: $userId) {
      clicks
      conversions
      cpc
      cpm
      name
      ctr
      impressions
      objective
      pCampaignId
      reportTime
      sasId
      site
      siteId
      spent
      status
      vCpm
      vCtr
      vImpressions
      status
      bid
      dailyCap
    }
  }
`;

export const CREATIVE_BY_ADSETNAME = gql`
  query creativeByAdSetName($adSetName: String!, $page: Int!, $size: Int!) {
    creativeByAdSetName(adSetName: $adSetName, page: $page, size: $size) {
      data {
        adBody {
          id
          body
        }
        adTitle {
          id
          title
        }
        creativeImage {
          id
          imageUrl
          videoId
          s3Url
          imageHash
        }
      }
      totalCount
      totalPages
    }
  }
`;

export const GET_SAS_UNASSIGNED_DOMAIN = gql`
  query sasFetchUnAssignedDomain($agencyId: ID) {
    sasFetchUnAssignedDomain(agencyId: $agencyId) {
      id
      name
    }
  }
`;

export const GET_SAS_DAILY_CATEGORIES_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasDailyCategoriesReport($from: Date, $to: Date, $userId: ID, $categoryId: ID) {
    sasDailyCategoriesReport(from: $from, to: $to, userId: $userId, categoryId: $categoryId) {
      ...SasReport
    }
  }
`;

export const GET_SAS_LIVE_CATEGORIES_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasLiveCategoriesReport($from: Date, $to: Date, $userId: ID, $categoryId: ID) {
    sasLiveCategoriesReport(from: $from, to: $to, userId: $userId, categoryId: $categoryId) {
      data {
        ...SasReport
      }
      latestSyncTime
    }
  }
`;

export const GET_SAS_ADS_RPC_REPORT = gql`
  query sasAdsRpc($from: Date, $to: Date, $groupBy: SasGroupBy, $timezone: String, $vendorId: ID) {
    sasAdsRpc(from: $from, to: $to, groupBy: $groupBy, timezone: $timezone, vendorId: $vendorId) {
      adId
      campaignId
      adAccountId
      adsetId
      live_revenue: estimate_revenue
      live_clicks: clicks
    }
  }
`;

const REPORT_BY_CATEGORY_RESPONSE = gql`
  fragment ReportByCategory on ReportByCategory {
    clicksFB
    id
    impressionsFB
    leadsFB
    name
    spendFB
  }
`;

export const GET_REPORT_BY_CATEGORY = gql`
  ${REPORT_BY_CATEGORY_RESPONSE}
  query reportByCategory(
    $from: String!
    $to: String!
    $timezone: String!
    $productId: ID!
    $categoryId: ID
  ) {
    reportByCategory(
      from: $from
      to: $to
      timezone: $timezone
      productId: $productId
      categoryId: $categoryId
    ) {
      ...ReportByCategory
    }
  }
`;

const SAS_FB_REPORT_RESPONSE = gql`
  fragment SasFBReport on SasReport {
    id
    ncId
    clicks
    date
    device
    assetName
    nativeCampaign {
      id
      campaignId
      name
    }
    ad {
      adId
      id
      adset {
        adsetId
        id
      }
      campaign {
        campaignId
        id
      }
      adAccount {
        adAccountId
        id
      }
    }
    category {
      id
      name
    }
    country {
      id
      iso_code
      name
    }
    platform {
      id
      name
    }
    categoryId
    estimate_revenue
    searches
    sessions
    adAccountId
    adId
    adsetId
    campaignId
    discrepancy
  }
`;

export const SAS_REPORT_STATUS = gql`
  query sasReportStatus($type: String!, $vendorId: ID) {
    sasReportStatus(type: $type, vendorId: $vendorId) {
      latestSyncTime
      date
    }
  }
`;

export const SAS_VENDOR_LIVE_REPORT_SYNC = gql`
  mutation sasVendorLiveReportSync($from: Date, $timezone: String, $to: Date, $vendorId: ID) {
    sasVendorLiveReportSync(from: $from, timezone: $timezone, to: $to, vendorId: $vendorId)
  }
`;

export const GET_SAS_AD_POSTBACK_REPORT = gql`
  ${SAS_FB_REPORT_RESPONSE}
  query sasAdPostBackReports(
    $adAccountId: ID
    $campaignId: ID
    $adsetId: ID
    $from: Date
    $groupBy: SasGroupBy!
    $platformId: ID
    $to: Date
    $userId: ID
    $vendorId: [ID]
    $timezone: String
    $ncId: ID
  ) {
    sasAdPostBackReports(
      adAccountId: $adAccountId
      campaignId: $campaignId
      adsetId: $adsetId
      from: $from
      groupBy: $groupBy
      platformId: $platformId
      to: $to
      userId: $userId
      vendorId: $vendorId
      timezone: $timezone
      ncId: $ncId
    ) {
      data {
        ...SasFBReport
      }
      latestSyncTime
      reportPublished
    }
  }
`;

export const GET_CH_SAS_AD_REPORT = gql`
  ${SAS_FB_REPORT_RESPONSE}
  query ch_sasAdReports(
    $adAccountId: ID
    $adsetId: ID
    $campaignId: ID
    $from: Date!
    $groupBy: SasGroupBy!
    $ncId: ID
    $platformId: ID
    $timezone: String!
    $to: Date!
    $type: sasReportType!
    $userId: ID
    $vendorId: [ID]
  ) {
    ch_sasAdReports(
      adAccountId: $adAccountId
      adsetId: $adsetId
      campaignId: $campaignId
      from: $from
      groupBy: $groupBy
      ncId: $ncId
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
      userId: $userId
      vendorId: $vendorId
    ) {
      data {
        ...SasFBReport
        trendRpl
        benchMarkLpctr
        predictedRevenue
      }
      latestSyncTime
      reportPublished
    }
  }
`;

export const GET_SAS_ASSET_COUNTRIES = gql`
  query sasAssetCountries(
    $assetId: String!
    $assetType: SasGroupBy!
    $from: Date!
    $timezone: String!
    $to: Date!
  ) {
    sasAssetCountries(
      assetId: $assetId
      assetType: $assetType
      from: $from
      timezone: $timezone
      to: $to
    ) {
      assetId
      country {
        id
        iso_code
        name
      }
    }
  }
`;

export const GET_SAS_AD_LIVE_REPORT = gql`
  ${SAS_FB_REPORT_RESPONSE}
  query sasAdLiveReports(
    $adAccountId: ID
    $campaignId: ID
    $adsetId: ID
    $from: Date
    $groupBy: SasGroupBy!
    $platformId: ID
    $to: Date
    $userId: ID
    $vendorId: [ID]
    $timezone: String
  ) {
    sasAdLiveReports(
      adAccountId: $adAccountId
      campaignId: $campaignId
      adsetId: $adsetId
      from: $from
      groupBy: $groupBy
      platformId: $platformId
      to: $to
      userId: $userId
      vendorId: $vendorId
      timezone: $timezone
    ) {
      data {
        ...SasFBReport
      }
      latestSyncTime
      reportPublished
    }
  }
`;

export const GET_SAS_AD_DAILY_REPORT = gql`
  ${SAS_FB_REPORT_RESPONSE}
  query sasAdDailyReports(
    $adAccountId: ID
    $campaignId: ID
    $adsetId: ID
    $from: Date
    $groupBy: SasGroupBy!
    $platformId: ID
    $to: Date
    $userId: ID
    $vendorId: [ID]
    $timezone: String
  ) {
    sasAdDailyReports(
      adAccountId: $adAccountId
      campaignId: $campaignId
      adsetId: $adsetId
      from: $from
      groupBy: $groupBy
      platformId: $platformId
      to: $to
      userId: $userId
      vendorId: $vendorId
      timezone: $timezone
    ) {
      ...SasFBReport
    }
  }
`;

export const GET_SAS_KEYWORDS_IDEAS = gql`
  query g_keywordIdeasGet($data: G_KeyWordPlanner) {
    g_keywordIdeasGet(data: $data)
  }
`;
const KEYWORD_GEO_CONSTANT_RESPONSE = gql`
  fragment G_GeoConstant on G_GeoConstant {
    id
    canonical_name
    country_code
    gid
    name
    resource_name
    status
    target_type
  }
`;
const KEYWORD_LANGUAGE_CONSTANT_REPSONSE = gql`
  fragment G_LanguageConstant on G_LanguageConstant {
    id
    name
    gid
    resource_name
    targetable
    code
  }
`;
export const GET_SAS_KEYWORD_GEO_CONSTANTS_ALL = gql`
  ${KEYWORD_GEO_CONSTANT_RESPONSE}
  query g_geoConstantAll {
    g_geoConstantAll {
      ...G_GeoConstant
    }
  }
`;

export const GET_SAS_KEYWORD_LANGUAGE_ALL = gql`
  ${KEYWORD_LANGUAGE_CONSTANT_REPSONSE}
  query g_languageConstantAll {
    g_languageConstantAll {
      ...G_LanguageConstant
    }
  }
`;

export const GET_SAS_KEYWORD_LANGUAGE_SEARCH = gql`
  ${KEYWORD_LANGUAGE_CONSTANT_REPSONSE}

  query g_languageConstantSearch($text: String) {
    g_languageConstantSearch(text: $text) {
      ...G_LanguageConstant
    }
  }
`;
export const GET_SAS_KEYWORD_GEO_SEARCH = gql`
  ${KEYWORD_GEO_CONSTANT_RESPONSE}
  query g_geoConstantSearch($text: String) {
    g_geoConstantSearch(text: $text) {
      ...G_LanguageConstant
    }
  }
`;

export const FB_REDIRECT = gql`
  query fbRedirect($fbId: ID!, $redirectType: FBRedirectType!) {
    fbRedirect(fbId: $fbId, redirectType: $redirectType)
  }
`;
export const TIKTOK_REDIRECT = gql`
  query tiktok_redirect($asset_id: String, $type: tiktok_hourly_type) {
    tiktok_redirect(asset_id: $asset_id, type: $type) {
      data
    }
  }
`;

export const GET_LAUNCHED_CAMPAIGNS = gql`
  query launchedCampaigns($launchId: ID!) {
    launchedCampaigns(launchId: $launchId) {
      accountId
      createdBy
      createdDate
      fbCampaignId
      payload
      updatedDate
      id
    }
  }
`;

export const GET_LAUNCHED_TIKTOK_CAMPAIGNS = gql`
  query tiktokLaunchedCampaign($launchId: ID!) {
    tiktokLaunchedCampaign(launchId: $launchId) {
      accountId
      createdBy
      createdDate
      campaignId
      payload
      updatedDate
      id
    }
  }
`;

export const GET_LAUNCHED_ADSETS = gql`
  query launchedAdsets($campaignId: ID!) {
    launchedAdsets(campaignId: $campaignId) {
      accountId
      createdBy
      createdDate
      fbAdsetId
      payload
      updatedDate
      id
    }
  }
`;
export const GET_TIKTOK_LAUNCHED_ADSETS = gql`
  query tiktokLaunchedAdSet($launchId: ID!, $campaignId: ID!) {
    tiktokLaunchedAdSet(launchId: $launchId, campaignId: $campaignId) {
      accountId
      createdBy
      createdDate
      adsetId
      payload
      updatedDate
      id
    }
  }
`;

export const GET_LAUNCHED_ADS = gql`
  query launchedAds($adsetId: ID!) {
    launchedAds(adsetId: $adsetId) {
      accountId
      createdBy
      createdDate
      fbAdId
      payload
      updatedDate
      id
    }
  }
`;
export const GET_LAUNCHED_TIKTOK_ADS = gql`
  query tiktokLaunchedAds($launchId: ID!, $adsetId: ID!) {
    tiktokLaunchedAds(launchId: $launchId, adsetId: $adsetId) {
      advertiserId
      createdBy
      createdDate
      adId
      payload
      updatedDate
    }
  }
`;

export const SAS_DOMAIN_AGENCY_SYNC = gql`
  mutation sasDomainAgencySync(
    $buyDomainId: [ID]
    $feedId: ID
    $languageId: ID
    $countryId: ID
    $platformId: ID
  ) {
    sasDomainAgencySync(
      buyDomainId: $buyDomainId
      feedId: $feedId
      languageId: $languageId
      countryId: $countryId
      platformId: $platformId
    )
  }
`;

export const SAS_ASSIGN_TAG_CATEGORY = gql`
  mutation sasAssignTagCategory($tags: [String]!, $ncIds: [ID]!) {
    sasAssignTagCategory(tags: $tags, ncIds: $ncIds)
  }
`;

export const TABOOLA_BUDGET_UPDATE = gql`
  mutation Native_updateCampaignDailyCap(
    $cap: Int!
    $nativeUpdateCampaignDailyCapId: ID!
    $platform: native_Platforms!
  ) {
    native_updateCampaignDailyCap(
      cap: $cap
      id: $nativeUpdateCampaignDailyCapId
      platform: $platform
    ) {
      isActive
      message
      status
      successIds
    }
  }
`;

const ITEM_DAILY_REPORT_RESPONSE = gql`
  fragment NativeItemDailyReport on NativeItemDailyReport {
    campaignItemName
    clicks
    cpc
    cpm
    ctr
    impressions
    item
    pCampaignId
    reportTime
    sasId
    spent
    url
    status
    vCpm
    vCtr
    vImpressions
    id
  }
`;

export const GET_SAS_NATIVE_ITEM_DAILY_REPORT_BY_CAMPAIGN = gql`
  ${ITEM_DAILY_REPORT_RESPONSE}
  query native_ItemDailyReportByCampaign(
    $from: String!
    $pCampaignId: ID!
    $platformId: ID!
    $to: String!
    $userId: ID
  ) {
    native_ItemDailyReportByCampaign(
      from: $from
      to: $to
      pCampaignId: $pCampaignId
      platformId: $platformId
      userId: $userId
    ) {
      ...NativeItemDailyReport
    }
  }
`;

export const GET_SAS_NATIVE_ITEM_REPORT_PERFORMANCE = gql`
  ${ITEM_DAILY_REPORT_RESPONSE}
  query native_ItemReportPerformance($from: String!, $id: ID!, $platformId: ID!, $to: String!) {
    native_ItemReportPerformance(from: $from, id: $id, platformId: $platformId, to: $to) {
      ...NativeItemDailyReport
    }
  }
`;

const SAS_ASSET_UPDATE_RESPONSE = gql`
  fragment sasAssetUpdateResponse on sasAssetUpdateResponse {
    assetId
    isAdded
    message
    status
    success
    weight
  }
`;

export const SAS_CAMPAIGN_KEYWORD_ASSIGNMENT = gql`
  ${SAS_ASSET_UPDATE_RESPONSE}
  mutation sasCampaignKeywordsAssignment($input: [campaign_keywords_assignment]) {
    sasCampaignKeywordsAssignment(input: $input) {
      ...sasAssetUpdateResponse
    }
  }
`;

export const SAS_UPDATE_CAMPAGN_KEYWORD_STATUS = gql`
  ${SAS_ASSET_UPDATE_RESPONSE}
  mutation sasUpdateCampaignKeywordStatus($input: [campaign_keywords_update]) {
    sasUpdateCampaignKeywordStatus(input: $input) {
      ...sasAssetUpdateResponse
    }
  }
`;

export const SAS_CAMPAIGN_DOMAIN_ASSIGNMENT = gql`
  ${SAS_ASSET_UPDATE_RESPONSE}
  mutation sasCampaignDomainsAssignment($input: [campaign_domain_assignment]) {
    sasCampaignDomainsAssignment(input: $input) {
      ...sasAssetUpdateResponse
    }
  }
`;

export const SAS_UPDATE_CAMPAGN_DOMAIN_STATUS = gql`
  ${SAS_ASSET_UPDATE_RESPONSE}
  mutation sasUpdateCampaignDomainStatus($input: [campaign_domain_update]) {
    sasUpdateCampaignDomainStatus(input: $input) {
      ...sasAssetUpdateResponse
    }
  }
`;

export const SAS_NATIVE_UPDATE_STATUS = gql`
  mutation native_updateStatus(
    $ids: [ID]!
    $isActive: Boolean!
    $node: native_AssetNode
    $platform: native_Platforms!
  ) {
    native_updateStatus(ids: $ids, isActive: $isActive, node: $node, platform: $platform) {
      failedIds {
        campaignId
        status
      }
      message
      status
      successIds {
        campaignId
        status
      }
    }
  }
`;

export const GQL_NATIVE_UPDATE_CAMPAIGN_SITE_STATUS = gql`
  mutation native_updateCampaignSiteStatus(
    $campaignId: String!
    $isActive: Boolean!
    $platform: native_Platforms
    $sites: [ID]!
  ) {
    native_updateCampaignSiteStatus(
      campaignId: $campaignId
      isActive: $isActive
      platform: $platform
      sites: $sites
    ) {
      status
      message
    }
  }
`;

export const UPDATE_BULK_AD_SET_BUDGET = gql`
  mutation Tiktok_updateBudget($budget_params: tiktok_budget) {
    tiktok_updateBudget(budget_params: $budget_params) {
      failure
      success
    }
  }
`;

export const UPDATE_CAMPAIGN_BUDGET = gql`
  mutation tiktok_updateCampaignBudget($budget_params: tiktok_campaignBudget) {
    tiktok_updateCampaignBudget(budget_params: $budget_params) {
      message
      status
    }
  }
`;

export const UPDATE_BULK_ASSET_BUDGET_WITH_SPEND = gql`
  mutation updateFbAssetBudgetWithSpend($input: [BudgetWithSpendInput], $node: AssetStatusNode!) {
    updateFbAssetBudgetWithSpend(input: $input, node: $node) {
      assetId
      message
      success
    }
  }
`;

// native_updateCampaignSiteStatus(isActive:Boolean!,sites:[ID]!,platform:native_Platforms,campaignId:String!):NativeSiteUpdateResponse

// mutation update_site{
//   native_updateCampaignSiteStatus(sites:["graytv-ios-14news"],
//     isActive:true,platform:TABOOLA,
//     campaignId:"23735061"){
//     status
//     message
//   }
// }

// native_updateCampaignSiteBid(bid:String!,campaignId:String!,platform:native_Platforms!,sites:[ID]!):NativeSiteUpdateResponse
// mutation site_bid{
//   native_updateCampaignSiteBid(bid:"1.1",sites:["graytv-ios-14news"],campaignId:"23735061",
//   platform:TABOOLA){
//     status
//   }
// }

export const TABOOLA_UPDATE_CAMPAIGN_SITE_BID = gql`
  mutation native_updateCampaignSiteBid(
    $bid: String!
    $campaignId: String!
    $platform: native_Platforms!
    $sites: [ID]!
    $operation: native_SiteOperation!
  ) {
    native_updateCampaignSiteBid(
      bid: $bid
      sites: $sites
      campaignId: $campaignId
      platform: $platform
      operation: $operation
    ) {
      status
    }
  }
`;

const SAS_VENDOR_SITE_RESPONSE = gql`
  fragment sasVendorSite on sasVendorSite {
    id
    name
    firstSeen
    isImportant
    lastSeen
    status
    look_category {
      id
      name
    }
  }
`;

export const GQL_SAS_VENDOR_SITES = gql`
  ${SAS_VENDOR_SITE_RESPONSE}
  query sasVendorSites(
    $column: String
    $lookCategoryId: ID
    $order: order
    $page: Int
    $search: String
    $size: Int
    $vendorId: ID
  ) {
    sasVendorSites(
      column: $column
      lookCategoryId: $lookCategoryId
      order: $order
      page: $page
      search: $search
      size: $size
      vendorId: $vendorId
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...sasVendorSite
      }
    }
  }
`;

const SAS_WEBSITES_RESPONSE = gql`
  fragment sasWebsite on sasWebsite {
    id
    name
    url
  }
`;

export const GQL_SAS_PAGED_WEBSITES = gql`
  ${SAS_WEBSITES_RESPONSE}
  query sasPagedWebsites($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    sasPagedWebsites(column: $column, order: $order, page: $page, search: $search, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...sasWebsite
      }
    }
  }
`;

export const GQL_SAS_WEBSITES = gql`
  ${SAS_WEBSITES_RESPONSE}
  query sasWebsites {
    sasWebsites {
      message
      status
      data {
        ...sasWebsite
      }
    }
  }
`;

export const GQL_SAS_WEBSITE_CREATE = gql`
  ${SAS_WEBSITES_RESPONSE}
  mutation sasWebsiteCreate($name: String, $url: String) {
    sasWebsiteCreate(name: $name, url: $url) {
      message
      status
      data {
        ...sasWebsite
      }
    }
  }
`;

export const GQL_SAS_WEBSITE_UPDATE = gql`
  ${SAS_WEBSITES_RESPONSE}
  mutation sasWebsiteUpdate($id: ID!, $name: String, $url: String) {
    sasWebsiteUpdate(id: $id, name: $name, url: $url) {
      message
      status
      data {
        ...sasWebsite
      }
    }
  }
`;

export const GQL_SAS_WEBSITE_DELETE = gql`
  mutation sasWebsiteDelete($id: [ID]) {
    sasWebsiteDelete(id: $id)
  }
`;

export const GQL_SAS_PLACEMENT_PERFORMANCE_REPORTS = gql`
  ${SAS_REPORT_RESPONSE}
  query sasPlacementPerformanceReports(
    $campaignId: ID
    $from: Date!
    $siteId: ID
    $timezone: String
    $to: Date!
    $type: sasReportType!
  ) {
    sasPlacementPerformanceReports(
      campaignId: $campaignId
      from: $from
      siteId: $siteId
      timezone: $timezone
      to: $to
      type: $type
    ) {
      data {
        ...SasReport
      }
      latestSyncTime
      reportPublished
    }
  }
`;

const NATIVE_SPEND_RESPONSE = gql`
  fragment NativeSpendReport on NativeSpendReport {
    bid
    clicks
    conversions
    cpc
    cpm
    ctr
    impressions
    name
    objective
    pCampaignId
    reportTime
    sasId
    site
    siteId
    spent
    status
    vCpm
    vCtr
    vImpressions
  }
`;

export const GQL_NATIVE_SITE_REPORT_PERFORMANCE = gql`
  ${NATIVE_SPEND_RESPONSE}
  query native_SiteReportPerformance(
    $from: String!
    $pCampaignId: ID!
    $platformId: ID!
    $siteId: ID!
    $to: String!
  ) {
    native_SiteReportPerformance(
      from: $from
      pCampaignId: $pCampaignId
      platformId: $platformId
      siteId: $siteId
      to: $to
    ) {
      ...NativeSpendReport
    }
  }
`;

export const GQL_NATIVE_USER_REPORT = gql`
  query native_UserReport($from: String!, $platformId: ID!, $to: String!, $userId: ID) {
    native_UserReport(from: $from, platformId: $platformId, to: $to, userId: $userId) {
      spendReport {
        clicks
        cpc
        cpm
        ctr
        impressions
        conversions
        spent
        vCpm
        vCtr
      }
      userId
    }
  }
`;

export const GQL_SAS_AD_COUNTRY_REPORT = gql`
  ${SAS_REPORT_RESPONSE}
  query sasAdCountryReports(
    $adAccountId: ID
    $adsetId: ID
    $campaignId: ID
    $from: Date!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
  ) {
    sasAdCountryReports(
      adAccountId: $adAccountId
      adsetId: $adsetId
      campaignId: $campaignId
      from: $from
      timezone: $timezone
      to: $to
      type: $type
    ) {
      countryName
      discrepancy
      ...SasReport
    }
  }
`;

export const GQL_SAS_GENERATE_TRACKING_URLS = gql`
  mutation sasGenrateTrackingUrls($input: [nc_input]) {
    sasGenrateTrackingUrls(input: $input) {
      assetId
      id
      trackingUrl
    }
  }
`;

export const SAS_ASSIGN_SET_CAMPAIGN = gql`
  mutation sasAssignSetsToCampaign($campaignId: [ID]!, $setId: ID!) {
    sasAssignSetsToCampaign(campaignId: $campaignId, setId: $setId)
  }
`;

export const SAS_GET_ALL_SETS = gql`
  query sasGetAllSets($categoryId: ID) {
    sasGetAllSets(categoryId: $categoryId) {
      id
      name
    }
  }
`;

export const SAS_VENDOR_ACCONTS_REPORTS = gql`
  query SasVendorAccountReports($from: Date!, $to: Date!) {
    sasVendorAccountReports(from: $from, to: $to) {
      sessions
      searches
      estimate_revenue
      date
      clicks
      vendorAccount {
        id
        name
      }
    }
  }
`;

export const GET_SAS_DOMAIN_TRACKER_REPORTS = gql`
  ${SAS_REPORT_RESPONSE}
  query sasDomainTrackerReports(
    $from: Date!
    $to: Date!
    $domainId: ID!
    $timezone: String!
    $type: sasReportType!
  ) {
    sasDomainTrackerReports(
      from: $from
      to: $to
      domainId: $domainId
      timezone: $timezone
      type: $type
    ) {
      ...SasReport
    }
  }
`;

export const GET_SAS_CATEGORY_COUNTRY_REPORTS = gql`
  ${SAS_REPORT_RESPONSE}
  query sasCategoryCountryReports(
    $from: Date!
    $to: Date!
    $categoryId: ID!
    $timezone: String!
    $type: sasReportType!
  ) {
    sasCategoryCountryReports(
      from: $from
      to: $to
      categoryId: $categoryId
      timezone: $timezone
      type: $type
    ) {
      countryName
      ...SasReport
    }
  }
`;

export const GET_SAS_RSOC_REPORTS = gql`
  query adsense_Reporting($from: Date!, $to: Date!, $timezone: String) {
    adsense_Reporting(from: $from, to: $to, timezone: $timezone) {
      channelId
      channelName
      clicks
      country
      date
      device
      estimateRevenue
      impressions
    }
  }
`;

export const GET_SAS_RSOC_FINAL_REPORTS = gql`
  query finalReporting($from: Date!, $to: Date!, $timezone: String) {
    finalReporting(from: $from, to: $to, timezone: $timezone) {
      channelId
      channelName
      clicks
      country
      date
      device
      estimateRevenue
      impressions
    }
  }
`;

export const GET_SAS_RSOC_DRAWER_REPORTS = gql`
  query adsense_DrawerReporting(
    $assetId: String!
    $from: Date!
    $to: Date!
    $type: adsense_ReportingType!
    $timezone: String
  ) {
    adsense_DrawerReporting(
      assetId: $assetId
      from: $from
      to: $to
      type: $type
      timezone: $timezone
    ) {
      channelId
      channelName
      clicks
      country
      date
      device
      estimateRevenue
      impressions
    }
  }
`;

export const GET_RSOC_PERFORMANCE_REPORT = gql`
  query rsocPerfomanceDrawer(
    $channelId: String!
    $from: Date!
    $platformId: ID
    $timezone: String
    $to: Date!
    $isHourly: Boolean
  ) {
    rsocPerfomanceDrawer(
      channelId: $channelId
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      isHourly: $isHourly
    ) {
      channelId
      channelName
      clicks
      country
      date
      device
      estimateRevenue
      impressions
    }
  }
`;

export const GET_SAS_PAGED_CHANNELS = gql`
  query sasPagedChannels($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    sasPagedChannels(column: $column, order: $order, page: $page, search: $search, size: $size) {
      data {
        type
        channel
        isRestricted
        id
        name
      }
      message
      pageInfo {
        totalCount
        totalPages
      }
      status
    }
  }
`;
export const SAS_CHANNEL_DELETE = gql`
  mutation SasChannelDelete($id: [ID]) {
    sasChannelDelete(id: $id)
  }
`;
export const SAS_CHANNEL_UPDATE = gql`
  mutation SasChannelUpdate(
    $channel: String!
    $id: ID!
    $isRestricted: Boolean!
    $name: String
    $platformId: ID
    $type: sasChannelType
    $typeId: ID
  ) {
    sasChannelUpdate(
      channel: $channel
      id: $id
      isRestricted: $isRestricted
      name: $name
      platformId: $platformId
      type: $type
      typeId: $typeId
    ) {
      data {
        type
        channel
        isRestricted
        id
        name
      }
      message
      status
    }
  }
`;
export const SAS_CHANNEL_CREATE = gql`
  mutation SasChannelCreate(
    $channel: String!
    $isRestricted: Boolean!
    $name: String
    $platformId: ID
    $type: sasChannelType
    $typeId: ID
  ) {
    sasChannelCreate(
      channel: $channel
      isRestricted: $isRestricted
      name: $name
      platformId: $platformId
      type: $type
      typeId: $typeId
    ) {
      data {
        type
        channel
        isRestricted
        id
        name
      }
      message
      status
    }
  }
`;

export const SAS_GET_CHANNEL = gql`
  query SasChannel($id: ID!) {
    sasChannel(id: $id) {
      data {
        dataValue
        id
        isRestricted
        name
        channel
        type
      }
      message
      status
    }
  }
`;

export const SAS_PAGED_EXTERNAL_CHANNEL = gql`
  query sasPagedExternalChannels {
    sasPagedExternalChannels {
      data {
        category {
          id
          name
        }
        categoryId
        channel
        id
        isRestricted
        name
        platformId
        styleId
        vendor {
          id
          name
        }
        vendorId
      }
      message
      pageInfo {
        totalCount
        totalPages
      }
      status
    }
  }
`;
export const SAS_CREATE_EXTERNAL_CHANNEL = gql`
  mutation SasCreateExternalChannel(
    $name: String!
    $channel: ID!
    $styleId: ID!
    $isRestricted: Boolean!
    $categoryId: ID!
    $platformId: ID!
    $vendorId: ID!
  ) {
    sasCreateExternalChannel(
      name: $name
      channel: $channel
      styleId: $styleId
      isRestricted: $isRestricted
      categoryId: $categoryId
      platformId: $platformId
      vendorId: $vendorId
    ) {
      status
      data {
        id
        name
      }
      message
      isExist
    }
  }
`;
export const SAS_UPDATE_EXTERNAL_CHANNEL = gql`
  mutation sasUpdateExternalChannel(
    $id: ID
    $categoryId: ID
    $channel: ID
    $isRestricted: Boolean
    $name: String
    $platformId: ID
    $styleId: ID
    $vendorId: ID
  ) {
    sasUpdateExternalChannel(
      id: $id
      categoryId: $categoryId
      channel: $channel
      isRestricted: $isRestricted
      name: $name
      platformId: $platformId
      styleId: $styleId
      vendorId: $vendorId
    ) {
      data {
        categoryId
        channel
        id
        isRestricted
        name
        platformId
        styleId
        vendorId
      }
      message
      status
    }
  }
`;
export const SAS_DELETE_EXTERNAL_CHANNEL = gql`
  mutation sasDeleteExternalChannel($id: [ID!]!) {
    sasDeleteExternalChannel(id: $id)
  }
`;

export const GET_SAS_RSOC_CHANNEL_REPORTS = gql`
  query sasChannelData {
    sasReportingChannels {
      data {
        type
        typeValue
        name
      }
    }
  }
`;

const RSOC_REPORT_RESPONSE = gql`
  fragment rsocReport on rsocReport {
    adId
    adsetId
    campaignId
    categoryId
    clicks
    conversions
    countryId
    device
    channelId
    country
    estimateRevenue
    cIndex
    benchMarkRpl
    benchMarkLpctr
  }
`;

export const GET_SAS_RSOC_ADS_REPORTS = gql`
  ${RSOC_REPORT_RESPONSE}
  query rsocReport(
    $from: Date!
    $to: Date!
    $timezone: String
    $platformId: ID!
    $groupBy: SasGroupBy
  ) {
    rsocReport(
      from: $from
      to: $to
      timezone: $timezone
      platformId: $platformId
      groupBy: $groupBy
    ) {
      ...rsocReport
      ncid
    }
  }
`;

export const GET_SAS_RSOC_ADS_REPORTS_V2 = gql`
  ${RSOC_REPORT_RESPONSE}
  query rsocReportV2(
    $from: Date!
    $to: Date!
    $timezone: String
    $platformId: ID!
    $groupBy: SasGroupBy
  ) {
    rsocReportV2(
      from: $from
      to: $to
      timezone: $timezone
      platformId: $platformId
      groupBy: $groupBy
    ) {
      ...rsocReport
    }
  }
`;

export const GET_SAS_RSOC_ADSENSE_CHANNEL = gql`
  query adsense_Channel_Rpc($from: Date!, $to: Date!, $timezone: String) {
    adsense_Channel_Rpc(from: $from, to: $to, timezone: $timezone) {
      channelId
      channelName
      clicks
      country
      date
      device
      estimateRevenue
      impressions
    }
  }
`;

export const GET_SAS_RSOC_REPORT_STATUS = gql`
  query rsocReportstatus($platformId: ID!) {
    rsocReportstatus(platformId: $platformId)
  }
`;

export const GET_SAS_RSOC_KEYWORD_REPORTS = gql`
  query rsocKeywordReport(
    $assetId: String!
    $from: Date!
    $timezone: String
    $to: Date!
    $type: rsocReportType!
    $platformId: ID!
  ) {
    rsocKeywordReport(
      assetId: $assetId
      from: $from
      timezone: $timezone
      to: $to
      type: $type
      platformId: $platformId
    ) {
      clicks
      conversions
      keywords
    }
  }
`;

export const GET_SAS_RSOC_PERFORMANCE_REPORTS = gql`
  ${RSOC_REPORT_RESPONSE}
  query rsocDailyPerfomanceReport(
    $from: Date!
    $timezone: String
    $to: Date!
    $type: rsocReportType!
    $platformId: ID!
    $adId: String
    $adsetId: String
    $campaignId: String
  ) {
    rsocDailyPerfomanceReport(
      from: $from
      timezone: $timezone
      to: $to
      type: $type
      platformId: $platformId
      adId: $adId
      adsetId: $adsetId
      campaignId: $campaignId
    ) {
      ...rsocReport
      date
    }
  }
`;

// Recommendation rule
const RECOMENDATION_RULE = gql`
  fragment RC_data on RC_data {
    _id
    name
    rule
    description
    tableType
    reportType
    workspaceId
    agencyId
    userId
  }
`;

export const GET_RECOMMENDATION_RULE = gql`
  ${RECOMENDATION_RULE}
  query RC_get_RecomendationRule($reportType: reportTypes!, $tableType: tableTypes!) {
    RC_get_RecomendationRule(reportType: $reportType, tableType: $tableType) {
      status
      message
      method
      data {
        ...RC_data
      }
    }
  }
`;

export const CREATE_RECOMMENDATION_RULE = gql`
  mutation RC_create_Recomendation($data: RC_inputdata) {
    RC_create_Recomendation(data: $data) {
      status
      message
      method
      data {
        _id
        name
        description
        rule
        tableType
        reportType
        workspaceId
        agencyId
        userId
      }
    }
  }
`;
export const UPDATE_RECOMMENDATION_RULE = gql`
  mutation RC_update_Recomendation($id: ID!, $data: RC_inputdata) {
    RC_update_Recomendation(id: $id, data: $data) {
      status
      message
      method
      data {
        _id
        tableType
        rule
        name
        reportType
        workspaceId
        agencyId
        userId
      }
    }
  }
`;
export const GET_RECOMMENDATION_RULE_PER_PAGE = gql`
  ${RECOMENDATION_RULE}
  query RC_get_RecomendationRulePerPage(
    $reportType: reportTypes!
    $tableType: tableTypes!
    $pageNumber: Int
    $perPage: Int
  ) {
    RC_get_RecomendationRulePerPage(
      reportType: $reportType
      tableType: $tableType
      pageNumber: $pageNumber
      perPage: $perPage
    ) {
      status
      message
      method
      data {
        ...RC_data
      }
    }
  }
`;

export const GET_ALL_RECOMMENDATION_RULE = gql`
  ${RECOMENDATION_RULE}
  query RC_get_AllRecomendationRule(
    $pageNumber: Int
    $perPage: Int
    $reportType: reportTypes
    $tableType: tableTypes
  ) {
    RC_get_AllRecomendationRule(
      pageNumber: $pageNumber
      perPage: $perPage
      reportType: $reportType
      tableType: $tableType
    ) {
      status
      method
      message
      data {
        ...RC_data
      }
    }
  }
`;

export const DELETE_RECOMMENDATION_RULE = gql`
  mutation RC_delete_Recomendation($id: ID) {
    RC_delete_Recomendation(id: $id) {
      status
      method
      message
    }
  }
`;

export const FB360_VIEW_TAG = gql`
  query fb360_viewTag($asset: FB360_ASSET, $asset_id: [ID]) {
    fb360_viewTag(asset: $asset, asset_id: $asset_id)
  }
`;

export const SAS_CREATE_BENCHMARK_FROM_CATEGORY = gql`
  mutation SasCategoryBenchMarkCreate($platformInputs: [SasPlatformSet]!, $categoryIds: [ID!]) {
    sasCategoryBenchMarkCreate(platformInputs: $platformInputs, categoryIds: $categoryIds)
  }
`;

export const SAS_GET_BENCHMARK_FROM_CATEGORY = gql`
  query SasCategoryBenchMark($categoryId: ID!) {
    sasCategoryBenchMark(categoryId: $categoryId) {
      categoryId
      platformOutput {
        afdLpctr
        afdRpl
        country {
          id
          name
        }
        platform {
          id
          name
        }
        rsocLpctr
        rsocRpl
      }
    }
  }
`;

export const SAS_UPDATE_BENCHMARK_FROM_CATEGORY = gql`
  mutation SasCategoryBenchMarkUpdate($categoryId: ID!, $platformInputs: [SasPlatformSet]!) {
    sasCategoryBenchMarkUpdate(categoryId: $categoryId, platformInputs: $platformInputs) {
      categoryId
      platformOutput {
        afdLpctr
        afdRpl
        country {
          id
          name
        }
        platform {
          id
          name
        }
        rsocLpctr
        rsocRpl
      }
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_REPORT = gql`
  query SasCategoriesCountryReport(
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
  ) {
    sasCategoriesCountryReport(
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
    ) {
      estimate_revenue
      clicks
      clicksP
      estimate_revenue_p
      inline_link_clicks
      discrepancy
      impressions
      result
      spend
      category {
        id
        name
      }
      country {
        name
        id
      }
    }
  }
`;

export const SAS_GLOBAL_CATEGORIES_COUNTRY_REPORT = gql`
  query sasGlobalCategoriesCountryReport(
    $from: Date!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
  ) {
    sasGlobalCategoriesCountryReport(from: $from, timezone: $timezone, to: $to, type: $type)
  }
`;

export const SAS_GLOBAL_RSFC_REPORT = gql`
  query rsocCategoryCountryReports($from: Date!, $to: Date!, $timezone: String) {
    rsocCategoryCountryReports(from: $from, to: $to, timezone: $timezone) {
      categoryId
      categoryName
      country
      fbClicks
      fbEstimateRevenue
      fbImpressions
      gdnClicks
      gdnEstimateRevenue
      gdnImpressions
      ttClicks
      ttEstimateRevenue
      ttImpressions
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_WISE_DOMAIN_REPORT = gql`
  query sasCategoryCountryWiseDomainReport(
    $categoryId: ID!
    $countryId: ID!
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
    $all: Boolean
  ) {
    sasCategoryCountryWiseDomainReport(
      categoryId: $categoryId
      countryId: $countryId
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
      all: $all
    ) {
      clicksP
      clicks
      discrepancy
      sessions
      country {
        name
        id
      }
      domain {
        name
        id
        status
      }
      estimate_revenue
      estimate_revenue_p
    }
  }
`;

export const SAS_RSOC_CATEGORY_COUNTRY_PERFORMANCE_REPORT = gql`
  query rsocCategoryCountryPerformance(
    $categoryId: ID!
    $country: String!
    $from: Date!
    $isHourly: Boolean
    $platformId: ID
    $timezone: String
    $to: Date!
  ) {
    rsocCategoryCountryPerformance(
      categoryId: $categoryId
      country: $country
      from: $from
      isHourly: $isHourly
      platformId: $platformId
      timezone: $timezone
      to: $to
    ) {
      channelId
      channelName
      clicks
      country
      date
      device
      estimateRevenue
      impressions
    }
  }
`;

export const SAS_RSOC_CATEGORY_COUNTRY_WISE_KEYWORD_SET_REPORT = gql`
  query rsocCategoryCountryWiseKeywordSetReport(
    $all: Boolean
    $categoryId: ID!
    $country: String
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
  ) {
    rsocCategoryCountryWiseKeywordSetReport(
      all: $all
      categoryId: $categoryId
      country: $country
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
    ) {
      estimateRevenue
      clicks
      conversions
      keywordSets
    }
  }
`;

export const SAS_RSOC_CATEGORY_COUNTRY_WISE_KEYWORD_REPORT = gql`
  query rsocCategoryCountryWiseKeywordReport(
    $all: Boolean
    $from: Date!
    $kwsetId: ID!
    $platformId: ID!
    $timezone: String!
    $to: Date!
  ) {
    rsocCategoryCountryWiseKeywordReport(
      all: $all
      from: $from
      kwsetId: $kwsetId
      platformId: $platformId
      timezone: $timezone
      to: $to
    ) {
      clicks
      estimateRevenue
      country
      keyword
      conversions
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_ADACCOUNT_REPORT = gql`
  query SasCategoryCountryWiseAdAccountReport(
    $from: Date!
    $to: Date!
    $platformId: ID!
    $categoryId: ID!
    $countryId: ID!
    $timezone: String!
    $type: sasReportType!
  ) {
    sasCategoryCountryWiseAdAccountReport(
      from: $from
      to: $to
      platformId: $platformId
      categoryId: $categoryId
      countryId: $countryId
      timezone: $timezone
      type: $type
    ) {
      ad {
        adAccount {
          adAccountId
          adAccountName
        }
      }
      clicksP
      estimate_revenue
      estimate_revenue_p
      clicks
      sessions
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_DETAIL_REPORT_STATUS = gql`
  mutation sasUpdateDomainStatus($status: SasStatus!, $domainId: [ID]) {
    sasUpdateDomainStatus(status: $status, domainId: $domainId)
  }
`;

export const SAS_CATEGORY_COUNTRY_WISE_KEYWORD_SET_REPORT = gql`
  query sasCategoryCountryWiseKeywordSetReport(
    $categoryId: ID!
    $countryId: ID!
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
    $all: Boolean
  ) {
    sasCategoryCountryWiseKeywordSetReport(
      categoryId: $categoryId
      countryId: $countryId
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
      all: $all
    ) {
      clicksP
      clicks
      estimate_revenue_p
      estimate_revenue
      discrepancy
      countryName
      keywordSets {
        id
        name
        status
        domains {
          id
          name
        }
      }
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_WISE_VENDOR_REPORT = gql`
  query sasCategoryCountryWiseVendorReport(
    $from: Date!
    $to: Date!
    $platformId: ID!
    $categoryId: ID!
    $countryId: ID!
    $timezone: String!
    $type: sasReportType!
  ) {
    sasCategoryCountryWiseVendorReport(
      from: $from
      to: $to
      platformId: $platformId
      categoryId: $categoryId
      countryId: $countryId
      timezone: $timezone
      type: $type
    ) {
      clicksP
      clicks
      estimate_revenue_p
      estimate_revenue
      discrepancy
      countryName
      vendor {
        id
        name
      }
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_WISE_KEYWORD_REPORT = gql`
  query sasCategoryCountryWiseKeywordReport(
    $from: Date!
    $to: Date!
    $platformId: ID!
    $categoryId: ID!
    $countryId: ID!
    $timezone: String!
    $type: sasReportType!
  ) {
    sasCategoryCountryWiseKeywordReport(
      from: $from
      to: $to
      platformId: $platformId
      categoryId: $categoryId
      countryId: $countryId
      timezone: $timezone
      type: $type
    ) {
      clicksP
      clicks
      estimate_revenue_p
      estimate_revenue
      discrepancy
      countryName
      keyword {
        id
        name
      }
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_DOMAIN_PERFORMANCE_REPORT = gql`
  query sasCategoryCountryDomainPerformanceReport(
    $categoryId: ID!
    $countryId: ID!
    $domainId: ID
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
  ) {
    sasCategoryCountryDomainPerformanceReport(
      categoryId: $categoryId
      countryId: $countryId
      domainId: $domainId
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
    ) {
      clicksP
      clicks
      estimate_revenue_p
      estimate_revenue
      discrepancy
      countryName
      keyword {
        id
        name
      }
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_ASSET_PERFORMANCE_REPORT = gql`
  query sasCategoryCountryAssetPerformanceReport(
    $from: Date!
    $to: Date!
    $platformId: ID!
    $categoryId: ID!
    $countryId: ID!
    $timezone: String!
    $type: sasReportType!
    $domainId: ID
    $kwsetId: ID
    $keywordId: ID
    $all: Boolean
  ) {
    sasCategoryCountryAssetPerformanceReport(
      from: $from
      to: $to
      platformId: $platformId
      categoryId: $categoryId
      countryId: $countryId
      timezone: $timezone
      type: $type
      domainId: $domainId
      kwsetId: $kwsetId
      keywordId: $keywordId
      all: $all
    ) {
      clicksP
      clicks
      estimate_revenue_p
      estimate_revenue
      discrepancy
      countryName
      keyword {
        id
        name
      }
      keywordSets {
        id
        name
        domains {
          id
          name
        }
      }
      domain {
        name
        id
      }
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_KEYWORD_SET_REPORT_STATUS = gql`
  mutation SasUpdateSetStatus($setId: [ID]!, $status: SasStatus!) {
    sasUpdateSetStatus(setId: $setId, status: $status)
  }
`;

export const SAS_CATEGORY_COUNTRY_WISE_TRACKING_REPORT = gql`
  query sasCategoryCountryWiseTrackingReport(
    $from: Date!
    $to: Date!
    $platformId: ID!
    $categoryId: ID!
    $countryId: ID!
    $timezone: String!
    $type: sasReportType!
  ) {
    sasCategoryCountryWiseTrackingReport(
      from: $from
      to: $to
      platformId: $platformId
      categoryId: $categoryId
      countryId: $countryId
      timezone: $timezone
      type: $type
    ) {
      clicksP
      clicks
      estimate_revenue_p
      estimate_revenue
      discrepancy
      countryName
      nativeCampaign {
        id
        name
      }
    }
  }
`;

export const SAS_GET_BIGSPY_TOKEN = gql`
  query CtGetBigSpyToken {
    ctGetBigSpyToken {
      data {
        createdAt
        token
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;

export const SAS_CREATE_BIGSPY_TOKEN = gql`
  mutation CtAddBigSpyToken($token: String!) {
    ctAddBigSpyToken(token: $token) {
      data {
        createdAt
        token
        updatedAt
        updatedBy
      }
      message
      status
    }
  }
`;

export const SAS_VENDOR_REPORTS = gql`
  query SasVendorReport(
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
    $type: sasReportType!
  ) {
    sasVendorReport(
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
      type: $type
    ) {
      clicksP
      clicks
      estimate_revenue
      estimate_revenue_p
      discrepancy
      vendor {
        id
        name
      }
    }
  }
`;

export const SAS_CATEGORY_HISTORY_REPORT = gql`
  query sasCategoryHistoryReports(
    $categoryId: ID!
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
  ) {
    sasCategoryHistoryReports(
      categoryId: $categoryId
      from: $from
      platformId: $platformId
      timezone: $timezone
      to: $to
    ) {
      country {
        id
        name
      }
      clicks
      estimate_revenue
    }
  }
`;

export const SAS_RSOC_ASSETS_WISE_REPORT = gql`
  query RsocAssetWiseReport(
    $from: Date!
    $groupBy: SasGroupBy!
    $platformId: ID!
    $to: Date!
    $campaignId: String
    $adsetId: String
  ) {
    rsocAssetWiseReport(
      from: $from
      groupBy: $groupBy
      platformId: $platformId
      to: $to
      campaignId: $campaignId
      adsetId: $adsetId
    ) {
      adId
      adsetId
      benchMarkLpctr
      benchMarkRpl
      cIndex
      campaignId
      categoryId
      channelId
      clicks
      conversions
      country
      countryId
      date
      device
      estimateRevenue
      keywords
    }
  }
`;

export const SAS_ASSIGN_DOMAIN_TO_SETS = gql`
  mutation SasAssignDomainToSets($domainId: [ID!], $isAdding: Boolean!, $setId: ID!) {
    sasAssignDomainToSets(domainId: $domainId, isAdding: $isAdding, setId: $setId)
  }
`;

export const GET_SAS_CATEGORY_COUNTRY_DOMAIN = gql`
  query SasCategoryCountryDomain($categoryId: ID!, $countryId: ID!, $platformId: ID!) {
    sasCategoryCountryDomain(
      categoryId: $categoryId
      countryId: $countryId
      platformId: $platformId
    ) {
      id
      name
      status
    }
  }
`;

export const CREATE_SET_WITH_KEYWORDS = gql`
  mutation sasCreateSetsWithKeywords(
    $name: String!
    $categoryId: ID!
    $languageId: ID
    $countryId: ID
    $isPublic: Boolean
    $keywords: [String]
    $platformId: ID
  ) {
    sasCreateSetsWithKeywords(
      name: $name
      categoryId: $categoryId
      languageId: $languageId
      countryId: $countryId
      isPublic: $isPublic
      keywords: $keywords
      platformId: $platformId
    )
  }
`;

export const SAS_SYNC_CATEGORY_BENCHMARK = gql`
  query sasSyncCategoryBenchmark($day: SasDay!) {
    sasSyncCategoryBenchmark(day: $day) {
      data
      message
      status
    }
  }
`;

export const SAS_CATEGORY_COUNTRY_HOURLY_PERFORMANCE = gql`
  query SasCategoryCountryWiseHourlyPerformanceReports(
    $from: Date!
    $to: Date!
    $platformId: ID!
    $categoryId: ID!
    $countryId: ID!
    $timezone: String!
    $type: sasReportType!
    $isHourly: Boolean
  ) {
    sasCategoryCountryWiseHourlyPerformanceReports(
      from: $from
      to: $to
      platformId: $platformId
      categoryId: $categoryId
      countryId: $countryId
      timezone: $timezone
      type: $type
      isHourly: $isHourly
    ) {
      clicksP
      clicks
      date
      estimate_revenue
      estimate_revenue_p
    }
  }
`;

export const TIKTOK_UPDATE_COMPLIANCE = gql`
  mutation fb360_updateAdCompliance(
    $ad_id: [ID]
    $description: String
    $status: FB360_AdComplianceStatus
  ) {
    fb360_updateAdCompliance(ad_id: $ad_id, description: $description, status: $status) {
      ad_id
      description
      status
    }
  }
`;

export const SAS_CLONE_SETS = gql`
  mutation sasCloneSets($id: [ID]!, $platformId: ID!) {
    sasCloneSets(id: $id, platformId: $platformId)
  }
`;
