import {
  CRS_GET_PERSONA,
  CRS_GET_TONES,
  GQL_CRS_GET_PERSONAS,
  GQL_GET_CONFIGS,
} from "shared/gql/gqlSchema/crsGql";
import useApiToStoreQuery from "./useApiToStoreQuery";
import { GET_ALL_USERS_ID_NAME } from "shared/gql";

export default function useCrsApis() {
  const {
    apiCall: crsConfigsFetch = () => {},
    refetch: crsConfigsRefetch,
    setDataSource: crsConfigsSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_GET_CONFIGS,
    elementKey: "CRS_GetConfigs",
    storeKey: "crsConfigs",
    newResponse: true,
    objectType: true,
  });

  const {
    apiCall: crsUsersFetch = () => {},
    refetch: crsUsersRefetch,
    setDataSource: crsUsersSetDataSource,
  } = useApiToStoreQuery({ api: GET_ALL_USERS_ID_NAME, elementKey: "users", storeKey: "crsUsers" });

  const {
    apiCall: crsPersonaFetch = () => {},
    refetch: crsPersonaRefetch,
    setDataSource: crsPersonaSetDataSource,
  } = useApiToStoreQuery({
    api: CRS_GET_PERSONA,
    elementKey: "CRS_GetPersona",
    storeKey: "crsPersona",
    newResponse: true,
    objectType: true,
  });

  const {
    apiCall: crsToneFetch = () => {},
    refetch: crsToneRefetch,
    setDataSource: crsToneSetDataSource,
  } = useApiToStoreQuery({
    api: CRS_GET_TONES,
    elementKey: "CRS_GetTones",
    storeKey: "crsTones",
    newResponse: true,
  });

  const {
    apiCall: crsPersonasFetch = () => {},
    refetch: crsPersonasRefetch,
    setDataSource: crsPersonasSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_CRS_GET_PERSONAS,
    elementKey: "CRS_GetPersonas",
    storeKey: "crsPersonas",
    newResponse: true,
  });

  return {
    crsConfigsFetch,
    crsConfigsRefetch,
    crsConfigsSetDataSource,

    crsPersonaFetch,
    crsPersonaRefetch,
    crsPersonaSetDataSource,

    crsPersonasFetch,
    crsPersonasRefetch,
    crsPersonasSetDataSource,

    crsToneFetch,
    crsToneRefetch,
    crsToneSetDataSource,

    crsUsersFetch,
    crsUsersRefetch,
    crsUsersSetDataSource,
  };
}
