import React, { useEffect, useState } from "react";
import useUploadDocument from "./useUploadDocument";
import parse from "html-react-parser";
import LookButton from "components/LookButton";
import { Button, Col, Row, Form } from "antd";
import ReactQuillForm from "components/ReactQuillForm";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import {
  ADD_MOODBOARD_COMPLIANCE,
  GET_MOODBOARD_COMPLIANCE,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import Loader from "components/loader";
import { GET_BLOB_FOR_CREATIVES } from "shared/gql/gqlSchema/umsGql";
import { RECORD_ADDED } from "shared/enum/apiResponse";

function ComplianceForm(props) {
  const { productId, onCloseDrawer = () => {} } = props;
  const [documentCreative, setDocuments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const [mimeType, setMimeType] = useState();

  const getDocumentResponse = (docs) => {
    setValues((prevItems) => ({ ...prevItems, document: docs }));
  };
  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to the HTML document
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
  function base64ToImageUrl(base64String) {
    return `data:${mimeType};base64,${base64String}`;
  }
  const [getBlob, { loading: loading_blob }] = useCustomMutation(GET_BLOB_FOR_CREATIVES, {
    onCompleted: (e) => {
      const element = e?.getBlob;
      if (element) {
        for (let i = 0; i < element.length; i++) {
          const blob = element[i]?.blob;
          const imageUrl = base64ToImageUrl(blob, mimeType);
          downloadFile(imageUrl, `lookfinity_creatives.pdf`);
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });

  async function handleClickDownload(urls, mimeType) {
    let linkData = [];
    for (const fileUrl of urls) {
      try {
        const response = await fetch(fileUrl);
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          downloadFile(url, `lookfinity_creatives.pdf`);
        }
      } catch (error) {
        linkData.push(fileUrl);
      }
    }
    if (linkData?.length > 0) {
      setMimeType(mimeType);
      getBlob({ variables: { urls: linkData } });
    }
  }
  const { name, id, apiDataType, actionType = "" } = props;
  const { uploadView } = useUploadDocument({
    getDocumentResponse,
    setDocuments,
    handleClickDownload,
    document: documentCreative,
    isDocumentUploader: true,
  });
  function onSubmit() {
    moodBoardAddData({
      variables: {
        description: values?.description,
        productId,
        categoryName: name,
        categoryId: id,
        document: values?.document?.map((docs) => ({ url: docs?.public_url, name: docs?.path })),
      },
    });
  }

  useEffect(() => {
    moodBoardDataFetch({
      variables: { categoryId: id, productId },
    });
  }, []);
  const [moodBoardDataFetch, { loading: data_loading, refetch: data_refetch }] = useCustomLazyQuery(
    GET_MOODBOARD_COMPLIANCE,
    {
      onCompleted: (e) => {
        if (e?.ctGetCompliance?.data?.length > 0) {
          setDocuments(
            e?.ctGetCompliance?.data?.[0]?.documents?.map((doc) => ({ ...doc, path: doc?.name }))
          );
          setValues({ description: e?.ctGetCompliance?.data?.[0]?.description });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [moodBoardAddData, { loading, refetch }] = useCustomMutation(ADD_MOODBOARD_COMPLIANCE, {
    onCompleted: (e) => {
      // setData(e?.[apiDataType]);
      openNotification({ type: "success", message: RECORD_ADDED });
      setIsEdit(false);
      setValues({});
      setDocuments();
      // setIsCreateForm(false);
      data_refetch();
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  return loading ? (
    <Loader elementCenter />
  ) : (
    <div>
      <div className="drawer-reduce-header_footer_btn-scroll py-20 px-15">
        <div>{uploadView()}</div>

        <div className="d-flex mt-30 mb-10" style={{ alignItems: "center" }}>
          <div className="mr-20" style={{ fontWeight: "600" }}>
            Compliance Description
          </div>
          {!isEdit ? (
            <LookButton iconType="edit" onClick={() => setIsEdit(true)}>
              Edit
            </LookButton>
          ) : (
            <>
              <LookButton
                // iconType="edit"
                className="mr-10"
                onClick={() => {
                  setValues((prevItems) => ({ ...prevItems, description: "" }));
                  // setIsEdit(false);
                }}
              >
                Reset
              </LookButton>
              <LookButton onClick={() => setIsEdit(false)}>Save</LookButton>
            </>
          )}
        </div>
        {isEdit && (
          <>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item className="look-form-input">
                  <ReactQuillForm
                    placeholder="Enter description"
                    className="drawer-quill"
                    serviceName="Tracker"
                    value={values?.description}
                    onChange={(e) => {
                      setValues((prevItems) => ({ ...prevItems, description: e }));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {!isEdit && values?.description && parse(values?.description)}
      </div>
      <div className="drawer-footer-btn-content">
        <div className="flex-grow-1">
          <Row gutter={12}>
            <Col span={12}>
              <Button
                block
                onClick={() => {
                  setIsEdit(false);
                  setValues({});
                  onCloseDrawer();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                type="primary"
                htmlType="submit"
                className="ml-10 "
                onClick={() => {
                  onSubmit();
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default ComplianceForm;
