import { useRef, useState } from "react";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import MainFrom from "./MainForm";
import { useDispatch, useSelector } from "react-redux";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { CREATE_AGENCY, CREATE_WORKSPACE } from "shared/gql";
import { ME_DATA } from "actions/actionTypes";
import { openNotification } from "utils";

export default function CreateAgencyFormCombined(props) {
  const workspaceDefault = { is_personal: true, title: "", description: "" };

  const { refetch = () => {} } = props;
  const resetFormRef = useRef(() => {});
  const [workspaceValues, setWorkspaceValues] = useState(workspaceDefault);

  const history = useHistory();
  const dispatch = useDispatch();

  const meData = useSelector((state) => state?.userReducer?.me);
  const [CreateAgency, { loading: agencyLoading }] = useCustomMutation(CREATE_AGENCY, {
    onCompleted(e) {
      refetch();
      openNotification({ type: "success", message: "Agency added successfully" });
      dispatch({
        type: ME_DATA,
        payload: { ...meData, agency_id: e?.CreateAgency?.id, agency_name: e?.CreateAgency?.name },
      });

      if (workspaceValues?.title) {
        CreateWorkspace({ variables: { ...workspaceValues } });
      } else {
        resetFormRef.current();
        history.push(`/dashboard`);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: "Agency " + e.message });
    },
  });
  const [CreateWorkspace, { loading: workspaceLoading }] = useCustomMutation(CREATE_WORKSPACE, {
    onCompleted(e) {
      refetch();
      resetFormRef.current();
      history.push(`/dashboard`);
      openNotification({ type: "success", message: "Workspace added successfully" });
    },
    onError(e) {
      openNotification({ type: "error", message: "Workspace " + e.message });
    },
  });

  function onSubmit(values, { resetForm }) {
    const { name, email, contact_no, is_personal, title, description } = values;
    resetFormRef.current = resetForm;
    CreateAgency({ variables: { name, email, contact_no } });
    setWorkspaceValues({ is_personal, title, description });
  }

  const initialValues = { name: "", email: "", contact_no: "", ...workspaceDefault };

  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
    email: yup.string().email().required().label("Email"),
    contact_no: yup
      .string()
      .matches(/^[0-9]{10}$/, "Number Must be 10 numeric characters")
      .required()
      .label("Contact No."),
  });

  const formProps = { initialValues, validationSchema, onSubmit, agencyLoading };

  return (
    <>
      <MainFrom {...formProps} />
    </>
  );
}
