// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_creativeMediaCol__ekP0G {\n  min-width: 150px;\n  margin-right: 12px;\n  margin-bottom: 12px;\n  flex: 1 1;\n  max-width: 170px;\n}", "",{"version":3,"sources":["webpack://src/components/LaunchView/LaunchCreatives/LaunchCreativesMedia/Medias/styles.module.scss"],"names":[],"mappings":"AAAA;EAEE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;AAAF","sourcesContent":[".creativeMediaCol {\n  // flex: 0 0 170px;\n  min-width: 150px;\n  margin-right: 12px;\n  margin-bottom: 12px;\n  flex: 1;\n  max-width: 170px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"creativeMediaCol": "styles_creativeMediaCol__ekP0G"
};
export default ___CSS_LOADER_EXPORT___;
