import LookButton from "components/LookButton";
import { useLookModal } from "components/LookModal";
import React, { useEffect, useRef } from "react";
import PreviewThumbnail from "./PreviewThumbnail";

const VideoThumbnail = ({ creatiVideo_refetch, videoUrl, getBlob, loading, closePreviewModal }) => {
  const videoRef = useRef(null);
  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Preview",
    footer: false,
  });

  useEffect(() => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.poster = videoUrl;
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.autoplay = false;
    video.controls = true;
    video.style.width = "100%";
    video.style.maxHeight = "300px";
    videoRef.current = video;

    const videoContainer = document.getElementById("video-container");
    videoContainer.appendChild(videoRef.current);

    // Cleanup function to remove the video element on unmount
    return () => {
      videoContainer.removeChild(video);
      closeModal();
    };
  }, [videoUrl]);

  return (
    <div>
      <div id="container"></div>
      <div id="video-container"></div>
      <div className="w-full flex flex-end">
        <LookButton
          className=""
          tooltipTitle="Take snapshots"
          iconType="image"
          type="primary"
          onClick={() => openModal()}
        >
          Capture Thumbnail
        </LookButton>
        {visibleModal &&
          modal(
            <PreviewThumbnail
              creatiVideo_refetch={creatiVideo_refetch}
              videoRef={videoRef}
              loading={loading}
              closeModal={closeModal}
              getBlob={getBlob}
              closePreviewModal={closePreviewModal}
            />
          )}
      </div>
    </div>
  );
};

export default VideoThumbnail;
