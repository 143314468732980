import { useSelector } from "react-redux";
import {
  GET_AD_ACCOUNT_BY_USER_ID_NAME_CURRENCY,
  GET_F360_CONFIG,
  GET_LAUNCH_CONFIG,
  GET_TARGETING_COUNTRIES,
  GET_TRACKER_CATEGORIES,
  IS_CATEGORY_ASSIGNED,
} from "shared/gql/gqlSchema/f360Gql";
import { GET_VOLUUM_CATEGORY } from "shared/gql/gqlSchema/fb360Gql";
import {
  API_STORE_F360_CONFIG,
  API_STORE_F360_LAUNCH_CONFIG,
  API_STORE_F360_TARGETING_COUNTRIES,
  API_STORE_F360_TRACKER_CATEGORIES,
  API_STORE_F360_USER_AD_ACCOUNTS,
  API_STORE_F360_IS_CATEGORY_ASSIGNED,
  API_STORE_FB360_CATEGORIES,
} from "./keys";
import useApiToStoreQuery from "./useApiToStoreQuery";
import { GQL_VOLUUME_IS_CATEGORIES_ASSIGNED } from "shared/gql/gqlSchema/leadgenGql";
import { PLATFORM_ID_FACEBOOK } from "shared/SharedKeys";

export function useLeadGenApiParams() {
  const {
    apiCall: leadGenTrackerCategoriesFetch = () => {},
    refetch: leadGenTrackerCategoriesRefetch,
    setDataSource: leadGenTrackerCategoriesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_TRACKER_CATEGORIES,
    elementKey: "TrackerCategories",
    storeKey: "leadGenTrackerCategories",
  });
  const {
    apiCall: voluumIsCategoriesAssignedFetch = () => {},
    refetch: voluumIsCategoriesAssignedRefetch,
    setDataSource: voluumIsCategoriesAssignedSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_VOLUUME_IS_CATEGORIES_ASSIGNED,
    elementKey: "voluum_is_categories_assigned",
    storeKey: "voluum_is_categories_assigned",
    objectType: true,
    variables: { platform_id: PLATFORM_ID_FACEBOOK }, // its use in leadgen category report for check category assigned
  });

  const {
    apiCall: launchConfigFetch = () => {},
    refetch: launchConfigRefetch,
    setDataSource: launchConfigSetDataSource,
  } = useApiToStoreQuery({
    api: GET_LAUNCH_CONFIG,
    elementKey: "launchConfig",
    storeKey: "launchConfig",
    objectType: true,
  });

  const {
    apiCall: voluumCategoryFetch = () => {},
    refetch: voluumCategoryRefetch,
    setDataSource: voluumCategorySetDataSource,
  } = useApiToStoreQuery({
    api: GET_VOLUUM_CATEGORY,
    elementKey: "voluum_get_category",
    storeKey: "voluumCategories",
  });

  return {
    leadGenTrackerCategoriesFetch,
    leadGenTrackerCategoriesRefetch,
    leadGenTrackerCategoriesSetDataSource,

    launchConfigFetch,
    launchConfigRefetch,
    launchConfigSetDataSource,

    voluumCategoryFetch,
    voluumCategoryRefetch,
    voluumCategorySetDataSource,

    voluumIsCategoriesAssignedFetch,
    voluumIsCategoriesAssignedRefetch,
    voluumIsCategoriesAssignedSetDataSource,
  };
}

export default function useF360Apis(typeKey) {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  switch (typeKey) {
    case API_STORE_F360_USER_AD_ACCOUNTS:
      return {
        api: GET_AD_ACCOUNT_BY_USER_ID_NAME_CURRENCY,
        variables: { userId: user_id },
        elementKey: "adAccountsByUser",
        storeKey: "adAccount_IdNameCurrency",
      };

    case API_STORE_F360_LAUNCH_CONFIG:
      return {
        api: GET_LAUNCH_CONFIG,
        elementKey: "launchConfig",
        storeKey: "launchConfig",
        objectType: true,
      };

    case API_STORE_F360_TARGETING_COUNTRIES:
      return {
        api: GET_TARGETING_COUNTRIES,
        elementKey: "targetingCountries",
        storeKey: "targetingCountries",
      };

    case API_STORE_F360_TRACKER_CATEGORIES:
      return {
        api: GET_TRACKER_CATEGORIES,
        elementKey: "TrackerCategories",
        storeKey: "trackerCategories",
      };

    case API_STORE_F360_IS_CATEGORY_ASSIGNED:
      return {
        api: IS_CATEGORY_ASSIGNED,
        elementKey: "isCategoryAssigned",
        storeKey: "isCategoryAssigned",
        objectType: true,
      };

    case API_STORE_F360_CONFIG:
      return {
        api: GET_F360_CONFIG,
        elementKey: "f360Config",
        storeKey: "f360Config",
        objectType: true,
      };

    case API_STORE_FB360_CATEGORIES:
      return {
        api: GET_VOLUUM_CATEGORY,
        elementKey: "voluum_get_category",
        storeKey: "voluumCategories",
      };

    default:
      return false;
  }
}
