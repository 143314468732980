import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_10 } from "actions/actionTypes";
import { Button } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import moment from "moment";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { dateFormat } from "reducers/userSettings";
import { GET_RINGBA_CALLS_REPORT } from "shared/gql/gqlSchema/tiktok";
import { secondsToHMS } from "utils";

function RingbaReport(props) {
  const { asset, assetId, timezone: timeZoneProps } = props;
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_10;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const userSettings = useSelector((state) => state?.userSettings);
  const { mainSearchWithInclude } = userSettings || {};
  const defaultDate = [moment().subtract(7, "d").format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);
  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const [ringbaReport, setRingbaReport] = useState([]);
  let finalMergeData = [];

  const {
    data: data_GET_REPORT_DATA,
    loading: loading_GET_REPORT_DATA,
    error: error_GET_REPORT_DATA,
    refetch: refetch_GET_REPORT_DATA,
  } = useCustomQuery(GET_RINGBA_CALLS_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.voluum_ringba_calls?.[0]?.calls;
      if (element) {
        setRingbaReport(element);
      }
    },
    variables: {
      from: rangeDates[0],
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      timezone: timeZone?.value,
      to: rangeDates[1],
      asset_id: assetId,
      asset,
    },
  });

  const refetch = () => {
    refetch_GET_REPORT_DATA();
  };

  const loading = loading_GET_REPORT_DATA;

  const action = [
    {
      title: "Recording Url",
      dataIndex: "action",
      render: (value, actionProps) => {
        const { recording_url } = actionProps;
        return recording_url ? (
          <Button className="mr-10" type="link" onClick={() => window.open(recording_url)}>
            Redirect
          </Button>
        ) : (
          <Button className="mr-10" type="link" disabled>
            Unavailable
          </Button>
        );
      },
      width: 100,
      minWidth: 100,
    },
  ];

  const initialColumns = [
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 190,
      minWidth: 190,
      sorter: true,
      fixed: "left",
      searchType: "string",
    },

    {
      title: "Campaign Id",
      dataIndex: "campaign_id",
      key: "campaign_id",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      hidden: true,
      searchType: "string",
    },

    {
      title: "Adset Id",
      dataIndex: "ad_set_id",
      key: "ad_set_id",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      hidden: true,
      searchType: "string",
    },

    {
      title: "Ad Id",
      dataIndex: "ad_id",
      key: "ad_id",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      hidden: true,
      searchType: "string",
    },

    {
      title: "Ad Account Id",
      dataIndex: "ad_account_id",
      key: "ad_account_id",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      hidden: true,
      searchType: "number",
    },

    {
      title: "Connected Call Length",
      dataIndex: "connected_call_length",
      key: "connected_call_length",
      render: (value) => <LookTableColumnRender title={secondsToHMS(value)} />,
      width: 90,
      minWidth: 90,
      searchType: "number",
    },

    {
      title: "Has connected",
      dataIndex: "has_connected",
      key: "has_connected",
      render: (value) => <LookTableColumnRender title={value ? "True" : "False"} />,
      width: 90,
      minWidth: 90,
      searchType: "number",
    },

    {
      title: "Has converted",
      dataIndex: "has_converted",
      key: "has_converted",
      render: (value) => <LookTableColumnRender title={value ? "True" : "False"} />,
      width: 70,
      minWidth: 70,
      searchType: "number",
    },

    {
      title: "Has payout",
      dataIndex: "has_payout",
      key: "has_payout",
      render: (value) => <LookTableColumnRender title={value ? "True" : "False"} />,
      width: 70,
      minWidth: 70,
      searchType: "number",
    },

    {
      title: "Is Duplicate",
      dataIndex: "is_duplicate",
      key: "is_duplicate",
      render: (value) => <LookTableColumnRender title={value ? "True" : "False"} />,
      width: 70,
      minWidth: 70,
      searchType: "number",
    },

    {
      title: "Payout Amount",
      dataIndex: "payout_amount",
      key: "payout_amount",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 70,
      minWidth: 70,
      searchType: "number",
    },

    {
      title: "Report time",
      dataIndex: "report_time",
      key: "report_time",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      searchType: "number",
    },

    ...action,
  ];

  // if(!loading) {
  //   finalMergeData = ringbaReport?.map(item => {
  //     const {} = item

  //     return ({...item, })
  //   })
  // }

  const finalFilterData = outerFilterData(filterData(ringbaReport), {
    filterKeys: ["campaign_id", "campaign_name"],
  });

  const tableMainProps = {
    isSearchFilter: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },
    persistKey,
    exportCSV: true,
    isDateRange: true,
    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData },
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },
  };

  const debugContent = [{ isRow: true, contentObjects: [{ ringbaReport }, { finalFilterData }] }];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default RingbaReport;
