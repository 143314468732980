import { useRef, useState } from "react";
import * as yup from "yup";
import MainForm from "../MainForm";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { UPDATE_SAS_VERTICAL } from "shared/gql/gqlSchema/sasGql";

export default function UpdateForm(props) {
  const {
    id,
    isSensitive: propIsSensitive,
    name: propName,
    dataRender,
    onClose = () => {},
    setDataRender = () => {},
  } = props;

  const [isError, setIsError] = useState("");
  const resetFormRef = useRef(() => {});
  const clearError = () => {
    isError !== "" && setIsError("");
  };

  const [sasVerticalUpdate, { loading }] = useCustomMutation(UPDATE_SAS_VERTICAL, {
    onCompleted(e) {
      const elementData = e?.sasVerticalUpdate;
      const updatedItem = {
        ...elementData,
        key: elementData.id,
      };

      const temp = JSON.parse(JSON.stringify(dataRender));
      const updateIndex = dataRender.findIndex(({ id }) => id === elementData.id);
      temp[updateIndex] = updatedItem;
      setDataRender(temp);
      onClose();
      openNotification({ type: "success", message: "Record Update Successfully" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit(values, { setSubmitting, resetForm }) {
    const { isSensitive, name } = values;
    resetFormRef.current = resetForm;
    clearError();

    const capitalized = name
      .split(" ")
      .map((it) => it.charAt(0).toUpperCase() + it.slice(1))
      .join(" ");

    sasVerticalUpdate({
      variables: {
        id,
        ...values,
        isSensitive: Boolean(isSensitive),
        name: capitalized,
      },
    });
  }

  const initialValues = {
    name: propName,
    isSensitive: Number(propIsSensitive),
  };

  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
  });

  const formProps = { initialValues, validationSchema, onSubmit, isError, loading, onClose };
  return (
    <div>
      <MainForm btnText="Update" type="edit" {...formProps} />
    </div>
  );
}
