import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6,
} from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import {
  VSAdDaily,
  VSAdHourly,
  VSDevicesReport,
  VSMediumsReport,
  VSOperatingSystemsReport,
} from "pages/dashboardPages/VS/VSReports";
import VSCountriesReport from "../../../CountryReports";

// import Ads from "../../Ads";
// import Adsets from "../../AdSets";
// import Campaigns from "../../Campaigns";
function DetailView(props) {
  const { visible = false, name = "", onClose } = props;

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={name}
    >
      {visible && (
        <LookDrawerMainSideBar
          tabContent={[
            {
              hoverTitle: "Country",
              icon: () => <ActionIconRender iconType="global" />,
              component: (
                <VSCountriesReport
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              id: "Device-Report",
              hoverTitle: "Device Report",
              icon: () => <ActionIconRender iconType="apartment" />,
              component: (
                <VSDevicesReport
                  {...props}
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
                />
              ),
              noSpace: true,
            },
            {
              id: "OS-Report",
              hoverTitle: "OS Report",
              icon: () => <ActionIconRender iconType="laptop" />,
              component: (
                <VSOperatingSystemsReport
                  {...props}
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3}
                />
              ),
              noSpace: true,
            },
            {
              id: "Medium-Report",
              hoverTitle: "Medium Report",
              icon: () => <ActionIconRender iconType="global" />,
              component: (
                <VSMediumsReport
                  {...props}
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4}
                />
              ),
              noSpace: true,
            },
            // {
            //   id: "Hourly-Report",
            //   hoverTitle: "Hourly Report",
            //   icon: () => <ActionIconRender iconType="duration" />,
            //   component: (
            //     <VSAdHourly
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   id: "Daily-Report",
            //   hoverTitle: "Daily Report",
            //   icon: () => <ActionIconRender iconType="barChartLine" />,
            //   component: (
            //     <VSAdDaily
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   id: "Hour-of-the-Day-Report",
            //   hoverTitle: "Hour of the Day Report",
            //   icon: () => <ActionIconRender iconType="time-circle" />,
            //   component: (
            //     <VSHourOfDayReport
            //       {...props}
            //       performance
            //       persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7}
            //     />
            //   ),
            //   noSpace: true,
            // },
            // {
            //   hoverTitle: "Campiagn Report",
            //   icon: () => <ActionIconRender iconType="notification" />,
            //   component: <Campaigns {...props} />,
            //   noSpace: true,
            // },

            // {
            //   hoverTitle: "Ad Set Report",
            //   icon: () => <ActionIconRender iconType="notification" />,
            //   component: <Adsets {...props} />,
            //   noSpace: true,
            // },
            // {
            //   hoverTitle: "Ads Report",
            //   icon: () => <ActionIconRender iconType="notification" />,
            //   component: <Ads {...props} />,
            //   noSpace: true,
            // },
          ]}
        />
      )}
    </LookDrawer>
  );
}

export default DetailView;
