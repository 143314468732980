import useCampaignReportColumns from "./useCampaignReportColumns";
import useGDNColumnsPerformance from "./useGDNColumnsPerformance";
import useTiktokColums from "./useTiktokColumns";
import useTiktokColumnsPerformance from "./useTiktokColumnsPerformance";

export default function useSasColumns(props) {
  const { tiktokCampignColumns } = useTiktokColums(props);
  const { tiktokPerformanceColumns } = useTiktokColumnsPerformance(props);

  const { campaignReportColumns } = useCampaignReportColumns(props);

  const { gdnPerformanceColumns } = useGDNColumnsPerformance(props);

  return { campaignReportColumns, tiktokCampignColumns, tiktokPerformanceColumns, gdnPerformanceColumns };
}
