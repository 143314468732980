import { Form } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import { useLaunchContext } from "components/LaunchView/Context";
import { LAUNCH_DETAILS_STATE_NAME } from "components/LaunchView/LaunchKeys";
import { useLaunchDispatchHooks } from "hooks/dispatchHooks";
import Medias from "./Medias";

export default function LaunchCreativesMedia() {
  const { launchType } = useLaunchContext();
  const { details = {}, setDetails } = useLaunchDispatchHooks(launchType);
  return (
    <>
      <Form layout="vertical">
        <LookTextInput
          label="Launch Name"
          value={
            details[LAUNCH_DETAILS_STATE_NAME] ? details[LAUNCH_DETAILS_STATE_NAME] : undefined
          }
          onChange={(e) => setDetails(LAUNCH_DETAILS_STATE_NAME, e.target.value)}
        />
      </Form>
      <Medias />
    </>
  );
}
