import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LAUNCH_PLATFORM_DATA } from "shared/gql/gqlSchema/launchGql";
import SelectInput from "components/forms/SelectInput";
import {
  CREATIVE_TYPE,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import { PLATFORM_TYPE_FACEBOOK } from "shared/enum/launchKeysAndEnum";
import CreativeReportView from "./CreativeReportView";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_TIKTOK,
  PRODUCT_ID_LEADGEN,
  PRODUCT_ID_SAS,
  PRODUCT_ID_VS,
} from "shared/SharedKeys";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_9 } from "actions/actionTypes";

export default function CreativeReports(props) {
  const {
    selectedCreatives,
    isGlobalReport = false,
    filterData = [],
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_9,
  } = props;
  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);
  const [serviceType, setServiceType] = useState(productList?.[0]?.id);
  const [mergedReport, setMergedReport] = useState([]);
  const [creativeType, setCreativeType] = useState(CREATIVE_TYPE);
  const [templatePlatforms, setTemplatePlatforms] = useState(PLATFORM_TYPE_FACEBOOK?.toLowerCase());
  const isFbReport = templatePlatforms === PLATFORM_TYPE_FACEBOOK?.toLowerCase();
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const [appliedFilters, setAppliedFilters] = useState(isGlobalReport ? filterData : []);
  const [appliedSortings, setAppliedSortings] = useState([]);
  const [mergedDataforCSV, setMergedDataforCSV] = useState([]);

  const [platformFetch, { data: platformsData, loading: PLATFORM_LOADING }] =
    useCustomLazyQuery(LAUNCH_PLATFORM_DATA);

  useEffect(() => {
    platformFetch();
  }, []);

  const creativeTypesList = [
    { name: "Image", value: CREATIVE_TYPE_IMAGE },
    { name: "Video", value: CREATIVE_TYPE_VIDEO },
    { name: "Creative", value: CREATIVE_TYPE },
  ];

  const platforms = platformsData?.templatePlatforms || [];
  // const platformFilter = usePlatformFilter({ templatePlatforms, setTemplatePlatforms });
  const creativeTypeFilter = (
    <div className="d-flex">
      <div style={{ width: "180px" }} className="mr-10">
        <SelectInput
          name="platforms"
          value={templatePlatforms}
          className="mb-0 w-100"
          onChange={(e) => {
            setTemplatePlatforms(e);
            setMergedReport([]);
          }}
          options={platforms?.map(({ name, id }) => ({
            name: name?.toUpperCase(),
            value: name,
          }))}
        />
      </div>
      <div style={{ width: "180px" }}>
        <SelectInput
          name="creativeType"
          value={creativeType}
          className="mb-0 w-100"
          onChange={(e) => {
            setCreativeType(e);
            setMergedReport([]);
          }}
          options={creativeTypesList}
        />
      </div>

      {productList?.length > 1 && (
        <div style={{ width: "180px" }} className="ml-10 mr-10">
          <SelectInput
            search
            className="mb-0"
            placeholder="Select Service"
            name="selectedService"
            options={productList?.map((product) => ({
              value: product?.id,
              name: product?.name?.replace(/\s/g, ""),
            }))}
            value={serviceType}
            onChange={(e) => {
              setServiceType(e);
              setMergedReport([]);
              setAppliedFilters([]);
              setAppliedSortings([]);
            }}
          />
        </div>
      )}
    </div>
  );
  const spendVariables = {
    group_by: creativeType,
    product_id: serviceType,
    start: dateRangeTimezone?.rangeDates[0],
    end: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };
  const revVariables = {
    ...(parseInt(serviceType) === PRODUCT_ID_SAS || !isFbReport
      ? parseInt(serviceType) === PRODUCT_ID_LEADGEN
        ? {
            group_by: creativeType,
            productId: serviceType,
            start: dateRangeTimezone?.rangeDates[0],
            end: dateRangeTimezone?.rangeDates[1],
            timezone: dateRangeTimezone?.timeZone?.value,
            platform_id: PLATFORM_ID_TIKTOK,
          }
        : {
            groupBy: creativeType,
            productId: serviceType,
            from: dateRangeTimezone?.rangeDates[0],
            to: dateRangeTimezone?.rangeDates[1],
            timezone: dateRangeTimezone?.timeZone?.value,
            platformId: isFbReport ? PLATFORM_ID_FACEBOOK : PLATFORM_ID_TIKTOK,
          }
      : parseInt(serviceType) === PRODUCT_ID_VS
      ? {
          groupBy: creativeType,
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone?.value,
          platformId: PLATFORM_ID_FACEBOOK,
          isForecast: true,
        }
      : {
          group_by: creativeType,
          productId: serviceType,
          start: dateRangeTimezone?.rangeDates[0],
          end: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone?.value,
          platform_id: PLATFORM_ID_FACEBOOK,
        }),
  };
  const revCategoryVariables = {
    group_by: creativeType,
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
    platform_id: PLATFORM_ID_FACEBOOK,
  };
  const revPlatformTiktokVariables = {
    groupBy: creativeType,
    productId: serviceType,
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };

  return (
    <div>
      {!selectedCreatives && <DashboardHeader title="Creative Reports" />}

      <CreativeReportView
        creativeTypeFilter={creativeTypeFilter}
        creativeType={creativeType}
        isFbReport={isFbReport}
        spendVariables={spendVariables}
        revVariables={revVariables}
        serviceType={serviceType}
        isGlobalReport={isGlobalReport}
        mergedReport={mergedReport}
        setMergedReport={setMergedReport}
        revCategoryVariables={revCategoryVariables}
        revPlatformTiktokVariables={revPlatformTiktokVariables}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        appliedSortings={appliedSortings}
        setAppliedSortings={setAppliedSortings}
        revDataKey={
          parseInt(serviceType) === PRODUCT_ID_SAS || !isFbReport
            ? parseInt(serviceType) === PRODUCT_ID_LEADGEN
              ? "voluum_creativeReporting"
              : "sasCreativeReport"
            : "voluum_creativeReporting"
        }
        spendDataKey={isFbReport ? "fb360_creativeReporting" : "tiktok_getCreativeReport"}
        persistKey={persistKey}
        mergedDataforCSV={mergedDataforCSV}
        setMergedDataforCSV={setMergedDataforCSV}
      />
      {/* <LookDrawer {...drawerProps}>{visibleDrawer && <AdsDetails {...rowProps} />}</LookDrawer> */}
    </div>
  );
}
