import LookButton from "components/LookButton";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "cx";
import React from "react";
import { deepClone, numberParse, numCompactShort } from "utils";

function useCreativeColumns({ isFbReport = false, isLeadgen = false, isVS = false }) {
  const commonColumns = [
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (value) => <LookTableColumnRender title={`$ ` + numCompactShort(value, 2)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.spend - b?.spend,
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={`$ ` + numCompactShort(value, 2)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.revenue - b?.revenue,
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value, otherProps = 0) => {
        const gross_margin = `$ ${otherProps?.gross_margin}`;
        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(gross_margin) > 0,
                  "text-danger": parseFloat(gross_margin) < 0,
                })}
              >
                {gross_margin.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.gross_margin - b?.gross_margin,
    },

    {
      title: "ROAS",
      dataIndex: "roas",
      key: "roas",
      render: (value) => <LookTableColumnRender title={numCompactShort(value, 2) + " %"} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.roas - b?.roas,
    },
  ];
  const commonDetailedColumns = [
    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },

    {
      title: "AD CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
      searchType: "number",
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },
  ];
  const sasAdColumns = [
    {
      title: "Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.visits - b?.visits,
      searchType: "number",
    },
    {
      title: "CPR (V)",
      dataIndex: "CPR_V",
      key: "CPR_V",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_V - b?.CPR_V,
      searchType: "number",
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",
    },
    {
      title: "Leads (V)",
      dataIndex: "revClicks",
      key: "revClicks",
      render: (value, otherValues) => (
        <LookTableColumnRender
          title={
            // <span
            //   className={cx({
            //     "text-yellow": value - otherValues?.result * 1.5 > 0,
            //   })}
            // >
            value
            // </span>
          }
        />
      ),
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revClicks - b?.revClicks,
      searchType: "number",
    },
    {
      title: "RPV",
      dataIndex: "RPV",
      key: "RPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.RPV - b?.RPV,
      searchType: "number",
    },
  ];

  const leadgenAdColumns = [
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPC - b?.CPC,
      searchType: "number",
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR - b?.CPR,
      searchType: "number",
    },
    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.EPC - b?.EPC,
      searchType: "number",
    },
    {
      title: "EPV",
      dataIndex: "EPV",
      key: "EPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.EPV - b?.EPV,
      searchType: "number",
    },
    {
      title: "PAYOUT",
      dataIndex: "PAYOUT",
      key: "PAYOUT",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.PAYOUT - b?.PAYOUT,
      searchType: "number",
    },
  ];
  const adColumns = deepClone([
    {
      title: "AdId",
      dataIndex: "ad_id",
      key: "ad_id",
      render: (value, otherProps) => <LookTableColumnRender title={value || otherProps?.adId} />,
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
    {
      title: "AdName",
      dataIndex: "ad_name",
      key: "ad_name",
      render: (value, otherProps) => <LookTableColumnRender title={value || otherProps?.adName} />,
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
    {
      title: "PageId",
      dataIndex: "page_id",
      key: "page_id",
      render: (value, otherProps) => {
        return value ? (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">{value}</div>

            <div className="d-flex">
              <LookButton
                iconType="link"
                type="link"
                onClick={() => {
                  window.open(`http://facebook.com/${value}`);
                }}
              />
            </div>
          </div>
        ) : (
          "-"
        );
      },
      width: 130,
    },
    ...(isLeadgen
      ? [
          {
            title: "Landing Page",
            dataIndex: "landing_page",
            key: "landing_page",
            render: (value) => (
              <LookTableColumnRender
                title={
                  <a href={value} target="_blank" rel="noopener noreferrer">
                    {value}
                  </a>
                }
              />
            ),
            width: 90,
            minWidth: 90,
            fixed: "left",
          },
        ]
      : []),
    ...commonColumns,
    ...(isLeadgen ? leadgenAdColumns : sasAdColumns),
    ...commonDetailedColumns,
  ]);

  const platformColumns = deepClone([
    {
      title: "Platform",
      dataIndex: "platformName",
      key: "platformName",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value || otherProps?.platform_name} />
      ),
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
    ...commonColumns,
  ]);
  const categoryColumns = deepClone([
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
    ...(isLeadgen
      ? []
      : [
          {
            title: "Discrepancy",
            dataIndex: "disc",
            key: "disc",
            render: (value, otherProps) => (
              <LookTableColumnRender title={numberParse(value, { toFixed: true })} />
            ),
            width: 130,
            minWidth: 130,
            fixed: "left",
            sorter: (a, b) => a?.disc - b?.disc,
          },
        ]),
    ...(isVS
      ? [
          {
            title: "PPS",
            dataIndex: "PPS",
            key: "PPS",
            render: (value, otherProps) => (
              <LookTableColumnRender title={numberParse(value, { toFixed: true })} />
            ),
            width: 130,
            minWidth: 130,
            sorter: (a, b) => a?.PPS - b?.PPS,
          },
          {
            title: "IPP",
            dataIndex: "IPP",
            key: "IPP",
            render: (value, otherProps) => (
              <LookTableColumnRender title={numberParse(value, { toFixed: true })} />
            ),
            width: 130,
            minWidth: 130,
            sorter: (a, b) => a?.IPP - b?.IPP,
          },
          {
            title: "PRPM",
            dataIndex: "PRPM",
            key: "PRPM",
            render: (value, otherProps) => (
              <LookTableColumnRender title={numberParse(value, { toFixed: true })} />
            ),
            width: 130,
            minWidth: 130,
            sorter: (a, b) => a?.PRPM - b?.PRPM,
          },
        ]
      : []),
    ...commonColumns,
    ...commonDetailedColumns,
    ...(isLeadgen ? leadgenAdColumns : sasAdColumns),
  ]);
  const adAccountColumns = deepClone([
    {
      title: "AdAccount Name",
      dataIndex: "adaccount_name",
      key: "adaccount_name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
    {
      title: "AdAccount Id",
      dataIndex: "adaccount_id",
      key: "adaccount_id",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value || otherProps?.ad_account_id} />
      ),
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value?.map((data) => data?.name)?.join(", ")} />
      ),
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
    ...(isLeadgen
      ? []
      : [
          {
            title: "Discrepancy",
            dataIndex: "disc",
            key: "disc",
            render: (value, otherProps) => (
              <LookTableColumnRender title={numberParse(value, { toFixed: true })} />
            ),
            width: 130,
            minWidth: 130,
            fixed: "left",
            sorter: (a, b) => a?.disc - b?.disc,
          },
        ]),
    ...commonColumns,
    ...commonDetailedColumns,
    ...(isLeadgen ? leadgenAdColumns : sasAdColumns),
  ]);
  return { categoryColumns, platformColumns, adColumns, adAccountColumns };
}

export default useCreativeColumns;
