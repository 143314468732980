import { Button, Col, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import LookModal from "components/LookModal";

import React, { useState } from "react";

function AddName(props) {
  const { name, onClose = () => {}, modalProps, onSubmitAddName = () => {} } = props;
  const [value, setValue] = useState(name);
  const handleChange = (e) => {
    setValue(e);
  };
  return (
    <div>
      <LookModal {...modalProps}>
        {modalProps?.visible && (
          <>
            <LookTextInput
              name="name"
              label="Campaign Name"
              placeholder="Enter name"
              className="look-form-input"
              value={value}
              onChange={(e) => handleChange(e.target.value)}
            />
            <Row gutter={12}>
              <Col span={6}>
                <Button
                  block
                  className="submit-btn"
                  onClick={() => {
                    onClose();
                    setValue(name);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={() => onSubmitAddName(value)}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </>
        )}
      </LookModal>
    </div>
  );
}

export default AddName;
