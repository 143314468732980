import React, { useEffect, useState } from "react";
import { CREATIVE_TYPE_AD, CREATIVE_TYPE_AD_ACCOUNT } from "shared/SharedKeys/launchKeys";
import { numberParse, numCompactShort } from "utils";
import alasql from "alasql";
import { Col, Row, Tooltip } from "antd";
import { OverviewCard } from "pages/dashboardPages/mainDashboard/UserRevenuData/Cards";
import cx from "cx";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_TIKTOK,
  PRODUCT_ID_LEADGEN,
  PRODUCT_ID_SAS,
  PRODUCT_ID_VS,
} from "shared/SharedKeys";
import useCreativeDetailedReportingApiHooks from "../../CreativeHooks/useCreativeDetailedReportingApiHooks";
import {
  ADS_TYPE_ADACCOUNT,
  ADS_TYPE_ADACCOUNT_UNIQUE_KEY,
  ADS_TYPE_ADACCOUNT_UNIQUE_KEY_LEADGEN,
  ADS_TYPE_ADACCOUNT_UNIQUE_KEY_LEADGEN_SPEND,
  ADS_TYPE_CATEGORY,
  ADS_TYPE_CATEGORY_UNIQUE_KEY,
  ADS_TYPE_CONTENT,
  ADS_TYPE_DEFAULT,
  ADS_TYPE_DEFAULT_UNIQUE_KEY,
  ADS_TYPE_DEFAULT_UNIQUE_KEY_SAS,
  ADS_TYPE_PLATFORM,
  ADS_TYPE_PLATFORM_UNIQUE_KEY,
} from "../../creativesEnum";
import { negetiveNumCompactShort } from "utils/numFormatter";
import { CreativeReportingContext } from "./Context";
import CreativeReportingMainView from "./CreativeReportingMainView";
import session from "redux-persist/lib/storage/session";
import { SPEND_INCREASE_PERCENT_SAS } from "shared/enum/commonKeysandEnum";

export default function CreativeDeatiledReporting(props) {
  const {
    spendDetailedKey,
    revDetailedKey,
    isFbReport,
    revPlatformTiktokVariables,
    revVariables,
    spendVariables,
    filters = [],
    serviceType,
    revCategoryVariables,
    adsType,
    setAdsType = () => {},
    isGlobalReport = false,
  } = props;
  const [revDetailedReport, setRevDetailedReport] = useState([]);
  const [spendDetailedReport, setSpendDetailedReport] = useState([]);
  const isSAS = parseInt(serviceType) === PRODUCT_ID_SAS;
  const isLeadgen = parseInt(serviceType) === PRODUCT_ID_LEADGEN;
  const isVS = parseInt(serviceType) === PRODUCT_ID_VS;

  const [revPlatformDetailedReport, setRevPlatformDetailedReport] = useState({
    fb: [],
    tiktok: [],
  });
  const [spendPlatformDetailedReport, setSpendPlatformDetailedReport] = useState({
    fb: [],
    tiktok: [],
  });
  const [mergedDetailedReport, setMergedDetailedReport] = useState([]);
  let total_revenue = 0,
    total_spend = 0,
    total_revClicks = 0,
    total_inline_clicks = 0,
    total_impressions = 0,
    total_conversions = 0,
    total_googleClicks = 0;
  const isCategoryReport = adsType === ADS_TYPE_CATEGORY || adsType === ADS_TYPE_CONTENT;
  const isAdAccountReport = adsType === ADS_TYPE_ADACCOUNT;

  const onAdsTypeChange = (checked) => {
    setAdsType(checked);
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    setRevPlatformDetailedReport({ fb: [], tiktok: [] });
    setSpendPlatformDetailedReport({ fb: [], tiktok: [] });
    setMergedDetailedReport([]);
  };

  //hooks for all apis of detailed reporting
  const {
    revenueFbFetch,
    loading_rev_fb_detailed_reports,
    refetch_fb_rev,
    revenueTiktokFetch,
    loading_rev_tiktok_detailed_reports,
    refetch_tiktok_rev,
    spendFbFetch,
    loading_spend_fb_detailed_reports,
    refetch_spend_fb,
    spendTiktokFetch,
    loading_spend_tiktok_detailed_reports,
    refetch_spend_tiktok,
    revenueFbPlatformFetch,
    loading_rev_fb_platform_detailed_reports,
    refetch_fb_platform_rev,
    revenueFbCategoryFetch,
    loading_rev_fb_category_detailed_reports,
    refetch_fb_category_rev,
    revenueTiktokCategoryFetch,
    loading_rev_tiktok_category_detailed_reports,
    refetch_tiktok_category_rev,
    revenueTiktokPlatformFetch,
    loading_rev_tiktok_platform_detailed_reports,
    refetch_tiktok_platform_rev,
    spendTiktokCategoryFetch,
    loading_spend_tiktok_category_detailed_reports,
    refetch_spend_category_tiktok,
    spendTiktokPlatformFetch,
    loading_spend_tiktok_platform_detailed_reports,
    refetch_spend_tiktok_platform,
    spendFbCategoryFetch,
    loading_spend_fb_category_detailed_reports,
    refetch_spend_fb_category,
    spendFbPlatformFetch,
    loading_spend_fb_platform_detailed_reports,
    refetch_spend_fb_platform,
    spendAdAccountFetch,
    loading_adAccount_fb,
    refetch_adAccount_fb,
    revSasAdAccountTiktokFetch,
    spendAdAccountTiktokFetch,
    loading_adAccount_rev_tiktok,
    refetch_adAccount_rev_tiktok,
    loading_adAccount_tiktok,
    refetch_adAccount_tiktok,

    // VS revenue fetch
    revenueVSLiveAdFetch,
    loading_revenueVSLiveAdFetch,
    refetch_revenueVSLiveAdFetch,
    revenueVSLiveAdAccountFetch,
    loading_revenueVSLiveAdAccountFetch,
    refetch_revenueVSLiveAdAccountFetch,
    revenueVSLiveContnetFetch,
    loading_revenueVSLiveContnetFetch,
    refetch_revenueVSLiveContnetFetch,
    // loading_adAccount_tiktok,
  } = useCreativeDetailedReportingApiHooks({
    setSpendDetailedReport,
    setRevDetailedReport,
    setSpendPlatformDetailedReport,
    setRevPlatformDetailedReport,
    revDetailedKey,
    spendDetailedKey,
    revDetailedReport,
    spendDetailedReport,
  });

  const loading =
    loading_spend_fb_detailed_reports ||
    loading_rev_tiktok_detailed_reports ||
    loading_rev_fb_detailed_reports ||
    loading_spend_tiktok_detailed_reports ||
    loading_rev_fb_platform_detailed_reports ||
    loading_rev_fb_category_detailed_reports ||
    loading_rev_tiktok_platform_detailed_reports ||
    loading_spend_tiktok_category_detailed_reports ||
    loading_rev_tiktok_category_detailed_reports ||
    loading_spend_tiktok_platform_detailed_reports ||
    loading_spend_fb_category_detailed_reports ||
    loading_spend_fb_platform_detailed_reports ||
    loading_adAccount_fb ||
    loading_adAccount_tiktok ||
    loading_adAccount_rev_tiktok;

  const revDetailedVariables =
    isSAS || !isFbReport
      ? isLeadgen
        ? {
            ...revVariables,
            group_id: props?.group_id,
            asset_type: CREATIVE_TYPE_AD,
            platform_id: isFbReport ? PLATFORM_ID_FACEBOOK : PLATFORM_ID_TIKTOK,
          }
        : {
            ...revVariables,
            groupId: props?.group_id,
            platformId: isFbReport ? PLATFORM_ID_FACEBOOK : PLATFORM_ID_TIKTOK,
            productId: serviceType,
          }
      : {
          ...revVariables,
          group_id: props?.group_id,
          asset_type: CREATIVE_TYPE_AD,
          platform_id: PLATFORM_ID_FACEBOOK,
        };
  const spendDetailedVariables = isFbReport
    ? {
        ...spendVariables,
        group_id: props?.group_id,
        asset_type: CREATIVE_TYPE_AD,
      }
    : {
        ...spendVariables,
        group_id: props?.group_id,
      };
  const prepareVariables = (details, overrides = {}) => ({
    ...details,
    ...overrides,
  });
  /*
  Tiktok variables on basis of ad types and platform/filters
*/
  const prepareTiktokVariables = () =>
    prepareVariables(
      isSAS
        ? revDetailedVariables
        : { ...spendVariables, product_id: serviceType, groupId: props?.group_id }
    );

  /*
  Fb rev variables on basis of ad types and platform/filters
*/
  const prepareFbRevVariables = () => {
    const platform = isFbReport ? PLATFORM_ID_FACEBOOK : PLATFORM_ID_TIKTOK;
    return prepareVariables(
      adsType === ADS_TYPE_DEFAULT || isAdAccountReport
        ? revDetailedVariables
        : revCategoryVariables,
      {
        group_id: props?.group_id,
        ...((isCategoryReport || isAdAccountReport) && {
          asset_type: isCategoryReport ? CREATIVE_TYPE_AD : CREATIVE_TYPE_AD_ACCOUNT,
        }),
        platform_id: adsType !== ADS_TYPE_PLATFORM ? platform : [platform],
      }
    );
  };
  /*}
  Fb spend variables on basis of ad types and platform/filters
*/
  const prepareFbSpendVariables = () =>
    prepareVariables(spendDetailedVariables, { product_id: serviceType });

  const fbRevVariables = prepareFbRevVariables();
  const fbSpendVariables = prepareFbSpendVariables();
  const tiktokVariables = prepareTiktokVariables();

  const getUniqueKey = (getUniqueKeyProps = {}) => {
    const { isSpend = false, isRevenue = false } = getUniqueKeyProps;
    const defaultUniqueKey = isLeadgen
      ? ADS_TYPE_DEFAULT_UNIQUE_KEY
      : ADS_TYPE_DEFAULT_UNIQUE_KEY_SAS;

    if (adsType === ADS_TYPE_DEFAULT) {
      return !isFbReport || isSAS ? defaultUniqueKey : ADS_TYPE_DEFAULT_UNIQUE_KEY;
    } else if (isCategoryReport) {
      return ADS_TYPE_CATEGORY_UNIQUE_KEY;
    } else if (isAdAccountReport) {
      return isLeadgen
        ? isSpend
          ? ADS_TYPE_ADACCOUNT_UNIQUE_KEY_LEADGEN_SPEND
          : ADS_TYPE_ADACCOUNT_UNIQUE_KEY_LEADGEN
        : ADS_TYPE_ADACCOUNT_UNIQUE_KEY;
    } else {
      return ADS_TYPE_PLATFORM_UNIQUE_KEY;
    }
  };

  const handleUniqueRevkey = () => {
    return getUniqueKey({ isRevenue: true });
  };

  const handleUniqueSpendkey = () => {
    return adsType === ADS_TYPE_DEFAULT
      ? ADS_TYPE_DEFAULT_UNIQUE_KEY
      : getUniqueKey({ isSpend: true });
  };

  const handleUniqueReport = (platformDetailedReport, defaultReport) => {
    const fbData = platformDetailedReport?.["fb"]?.[0];
    const tiktokData = platformDetailedReport?.["tiktok"]?.[0];

    return adsType !== ADS_TYPE_PLATFORM ? defaultReport : [fbData, tiktokData].filter(Boolean);
  };

  const handleUniqueRevReport = () => {
    return handleUniqueReport(revPlatformDetailedReport, revDetailedReport);
  };

  const handleUniqueSpendReport = () => {
    return handleUniqueReport(spendPlatformDetailedReport, spendDetailedReport);
  };

  function handleSasData({
    spendUpdate,
    clicks,
    revClicks,
    impressions,
    searches,
    result,
    sessions,
    estimate_revenue,
  }) {
    const CPC = parseFloat(spendUpdate) / parseFloat(clicks);
    const CPR_V = parseFloat(spendUpdate) / parseFloat(revClicks);
    const CPR_FB = parseFloat(spendUpdate) / parseFloat(result);
    const CPV = parseFloat(spendUpdate) / parseFloat(sessions);
    const RPV = parseFloat(estimate_revenue) / parseFloat(clicks);
    // const CPL = parseFloat(spendUpdate) / parseFloat(clicks);
    const RPL = parseFloat(estimate_revenue) / parseFloat(revClicks);
    const AD_CTR = (parseFloat(clicks) / parseFloat(impressions)) * 100 || 0;
    const LP_CTR = (parseFloat(searches) / parseFloat(clicks)) * 100;
    const CR = (parseFloat(revClicks) / parseFloat(clicks)) * 100;
    const CPM = (parseFloat(spendUpdate) / parseFloat(impressions)) * 1000;
    const disc = sessions / clicks || 0;

    return { CPC, CPR_V, CPR_FB, CPV, RPV, RPL, AD_CTR, LP_CTR, CR, CPM, disc };
  }

  function handleLeadgenData({
    revenue,
    conversions,
    revClicks,
    inlineClicks,
    impressions,
    spendUpdate,
  }) {
    const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;
    const PAYOUT = parseFloat(revenue) / parseFloat(conversions);
    const CPV = parseFloat(spendUpdate) / parseFloat(inlineClicks);
    const EPV = parseFloat(revenue) / parseFloat(inlineClicks);
    const CPC = parseFloat(spendUpdate) / parseFloat(revClicks);
    const EPC = parseFloat(revenue) / parseFloat(revClicks);
    const AD_CTR = (parseFloat(inlineClicks) / parseFloat(impressions)) * 100 || 0;
    const LP_CTR = (parseFloat(revClicks) / parseFloat(inlineClicks)) * 100;
    const CPM = (parseFloat(spendUpdate) / parseFloat(impressions)) * 1000;
    const CPR = parseFloat(spendUpdate) / parseFloat(conversions);
    return { CR, PAYOUT, CPV, EPV, CPC, EPC, AD_CTR, LP_CTR, CPM, CPR };
  }

  function handleVsData(data) {
    const { revenue, pageviews, sessions, spendUpdate, result, inlineClicks, impressions, clicks } =
      data;
    const EPC = parseFloat(revenue) / parseFloat(sessions);
    const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
    const CPV = parseFloat(spendUpdate) / parseFloat(inlineClicks);
    const CTR = (parseFloat(inlineClicks) / parseFloat(impressions)) * 100;
    const CPR = parseFloat(spendUpdate) / parseFloat(result) || 0;
    const disc = parseFloat(sessions) / parseFloat(clicks) || 0;
    const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
    const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
    const PRPM =
      ((parseFloat(revenue) / parseFloat(sessions)) * 1000) /
      (parseFloat(impressions) / parseFloat(sessions) / 3);
    return { EPC, CPC, CPV, CTR, CPR, disc, PPS, IPP, PRPM };
  }

  useEffect(() => {
    const revKey = handleUniqueRevkey();
    const spendKey = handleUniqueSpendkey();
    const finalSpendReport = handleUniqueSpendReport();
    const finalRevReport = handleUniqueRevReport();

    const revenueKey = isVS && adsType === ADS_TYPE_CONTENT ? "content_id" : revKey;

    const alasqlQuery = `SELECT b.ad_name as adName, * FROM ? as a FULL OUTER JOIN ? as b ON a.${revenueKey} = b.${spendKey} `;

    if (!loading) {
      alasql
        .promise(
          alasqlQuery,
          //in case if tiktok we are getting adId from rev side and ad_id from spend side
          [finalRevReport, finalSpendReport]
        )
        .then((res) => {
          setMergedDetailedReport(res);
          console.log(res);
        })
        .catch(function (err) {
          console.log({ err });
        });
    }
  }, [
    spendDetailedReport,
    revDetailedReport,
    revPlatformDetailedReport,
    spendPlatformDetailedReport,
  ]);

  const finalMerge = mergedDetailedReport?.map((data, index) => {
    const {
      revenue = 0,
      spend = 0,
      ad_id = "",
      clicks = 0,
      searches = 0,
      impressions = 0,
      revClicks = 0,
      pageviews = 0,
      result = 0,
      estimate_revenue = 0,
      sessions = 0,
      inlineClicks = 0,
      conversions = 0,
    } = data;
    // increase 5% in spend
    const spendUpdate = (spend * SPEND_INCREASE_PERCENT_SAS).toFixed(2);
    const gross_margin = parseFloat(revenue) - parseFloat(spendUpdate);
    const ROAS = (parseFloat(gross_margin) / parseFloat(spendUpdate)) * 100 || 0;
    const serviceWiseData = isLeadgen
      ? handleLeadgenData({
          spendUpdate,
          clicks,
          revClicks,
          inlineClicks,
          impressions,
          searches,
          revenue,
          conversions,
        })
      : isVS
      ? handleVsData({
          revenue,
          sessions,
          spendUpdate,
          inlineClicks,
          impressions,
          clicks,
          result,
          pageviews,
        })
      : handleSasData({
          spendUpdate,
          clicks,
          revClicks,
          impressions,
          searches,
          result,
          sessions,
          estimate_revenue,
        });

    return {
      ...data,
      key: ad_id + index,
      roas: numberParse(ROAS, { toFixed: true }),
      revenue: numberParse(revenue, { toFixed: true }),
      spend: numberParse(spendUpdate, { toFixed: true }),
      gross_margin: numberParse(gross_margin, { toFixed: true }),
      ...serviceWiseData,
    };
  });

  for (const {
    spend = 0,
    revenue = 0,
    inlineClicks = 0,
    impressions = 0,
    conversions = 0,
    revClicks = 0,
  } of finalMerge) {
    total_revenue += parseFloat(revenue || 0);
    total_spend += parseFloat(spend || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_revClicks += parseFloat(revClicks || 0);
    total_inline_clicks += parseFloat(inlineClicks || 0);
    total_conversions += parseFloat(conversions || 0);
  }
  const total_gross_margin = parseFloat(total_revenue) - parseFloat(total_spend);
  const total_roas = (parseFloat(total_gross_margin) / parseFloat(total_spend)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_CPV = parseFloat(total_spend) / parseFloat(total_inline_clicks);
  const total_EPV = parseFloat(total_revenue) / parseFloat(total_inline_clicks);
  const total_CPC = parseFloat(total_spend) / parseFloat(total_revClicks);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_AD_CTR = (parseFloat(total_inline_clicks) / parseFloat(total_impressions)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_inline_clicks)) * 100;
  const total_CPM = (parseFloat(total_spend) / parseFloat(total_impressions)) * 1000;
  const total_CPR = parseFloat(total_spend) / parseFloat(total_conversions);
  const summaryData = {
    spend: numCompactShort(numberParse(total_spend, { toFixed: true }), 2),
    revenue: numCompactShort(numberParse(total_revenue, { toFixed: true }), 2),
    roas: numCompactShort(numberParse(total_roas, { toFixed: true }), 2),
    PROFIT: total_gross_margin.toFixed(2),
    PROFIT_COMPACT: negetiveNumCompactShort(total_gross_margin.toFixed(2), 2),
    CPC: numCompactShort(numberParse(total_CPC, { toFixed: true }), 2),
    EPC: numCompactShort(numberParse(total_EPC, { toFixed: true }), 2),
    LP_CTR: numCompactShort(numberParse(total_LP_CTR, { toFixed: true }), 2),
    AD_CTR: numCompactShort(numberParse(total_AD_CTR, { toFixed: true }), 2),
    CPM: numCompactShort(numberParse(total_CPM, { toFixed: true }), 2),
    PAYOUT: numCompactShort(numberParse(total_CPM, { toFixed: true }), 2),
    conversions: total_conversions,
    CR: numCompactShort(numberParse(total_CR, { toFixed: true }), 2),
    EPV: numCompactShort(numberParse(total_EPV, { toFixed: true }), 2),
    CPR: numCompactShort(numberParse(total_CPR, { toFixed: true }), 2),
    impressions: total_impressions,
  };
  const overview = [
    {
      iconType: "creditCard",
      title: "Spend",
      value: (
        <>
          <Tooltip title={summaryData?.spend}>{summaryData?.spend}</Tooltip>
        </>
      ),
    },
    {
      iconType: "dollar",
      title: "Revenue",
      value: (
        <>
          <Tooltip title={summaryData?.revenue}>{summaryData?.revenue}</Tooltip>
        </>
      ),
    },
    {
      iconType: "barChartLine",
      title: "Profit",
      value: (
        <>
          <Tooltip title={summaryData?.PROFIT_COMPACT}>
            <div
              className={cx({
                "text-danger": summaryData?.PROFIT_COMPACT?.replace(/[a-zA-Z]/g, "") < 0,
                "text-primary": summaryData?.PROFIT_COMPACT?.replace(/[a-zA-Z]/g, "") > 0,
              })}
            >
              {summaryData?.PROFIT_COMPACT}
            </div>
          </Tooltip>
        </>
      ),
      iconClassName: summaryData?.PROFIT > 0 ? "text-primary" : "text-danger",
    },
    {
      iconType: "rise",
      title: "ROAS",
      value: (
        <>
          <Tooltip title={summaryData?.roas}>{summaryData?.roas}</Tooltip>
        </>
      ),
    },
  ];
  const renderSkeleton = () => (
    <>
      <div>
        <Row gutter={[4, 20]}>
          {overview.map((data, index) => (
            <Col className="flex-grow-1" key={"overview" + index}>
              <OverviewCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
  const renderContent = () => (
    <>
      <div>
        <Row gutter={[4, 20]}>
          {overview.map((data, index) => (
            <Col className="flex-grow-1" key={"overview" + index}>
              <OverviewCard isSmall {...data} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  const contextData = {
    renderSkeleton,
    renderContent,
    onAdsTypeChange,
    loading,
    adsType,
    finalMerge,
    summaryData,
    spendPlatformDetailedReport,
    revPlatformDetailedReport,
    mergedDetailedReport,
    isGlobalReport,
    revPlatformTiktokVariables,
    group_id: props?.group_id,
    serviceType,
    spendDetailedVariables,
    fbRevVariables,
    fbSpendVariables,
    tiktokVariables,
    revenueTiktokPlatformFetch,
    spendTiktokPlatformFetch,
    revenueFbPlatformFetch,
    spendFbPlatformFetch,
    revSasAdAccountTiktokFetch,
    spendAdAccountTiktokFetch,
    revenueFbFetch,
    spendAdAccountFetch,
    refetch_adAccount_rev_tiktok,
    refetch_adAccount_fb,
    refetch_fb_rev,
    refetch_adAccount_tiktok,
    filters,
    setSpendDetailedReport,
    setRevDetailedReport,
    setSpendPlatformDetailedReport,
    setRevPlatformDetailedReport,
    refetch_spend_fb_platform,
    refetch_tiktok_platform_rev,
    refetch_spend_tiktok_platform,
    isFbReport,
    refetch_fb_platform_rev,
    refetch_tiktok_category_rev,
    refetch_spend_fb_category,
    refetch_fb_category_rev,
    refetch_spend_category_tiktok,
    revenueTiktokFetch,
    spendTiktokFetch,
    spendFbFetch,
    revenueTiktokCategoryFetch,
    spendTiktokCategoryFetch,
    revenueFbCategoryFetch,
    spendFbCategoryFetch,
    isLeadgen,
    isSAS,
    isVS,
    refetch_spend_fb,
    refetch_tiktok_rev,
    refetch_spend_tiktok,

    // VS revenue fetch
    revenueVSLiveAdFetch,
    loading_revenueVSLiveAdFetch,
    refetch_revenueVSLiveAdFetch,
    revenueVSLiveAdAccountFetch,
    loading_revenueVSLiveAdAccountFetch,
    refetch_revenueVSLiveAdAccountFetch,
    revenueVSLiveContnetFetch,
    loading_revenueVSLiveContnetFetch,
    refetch_revenueVSLiveContnetFetch,
  };

  return (
    <CreativeReportingContext.Provider value={contextData}>
      <CreativeReportingMainView />
    </CreativeReportingContext.Provider>
  );
}
