import {
  CONTENT_TYPE_ARTICLE,
  C_TYPE_ABO,
  C_TYPE_CBO,
  SERVICE_TYPE_LEAD_GEN,
  SERVICE_TYPE_SAS,
  SERVICE_TYPE_VS,
} from "shared/enum/launchKeysAndEnum";
import { CONTINUES_DRAFT } from "..";

function initialSetCampaign({
  targettingTemplateData,
  serviceType,
  contentContentData,
  bucketData,
  billingEvent,
  isSasKeywordGroup = false,
  isSasCampaign = false,
  inputs,
  isTiktokPlatformLaunch = false,
  selectedImage,
  adGroupDataSource,
  instantLaunch = false,
  setCampaignObject,
  isDraft,
  handlePlatforms,
  editCampaign = false,
  campaignObject,
  instantLaunchData,
  chooseDraftOrNew,
  handleDraft = () => {},
}) {
  let campaignMainData = [];
  let multiDeviceCampaigns = [];
  function handleMultiCampaigns(
    multiDevices,
    multiCountry,
    campaignMainData,
    targeting,
    multiDeviceCampaigns,
    campaignObject,
    handlePlatforms,
    handleMultiCountry,
    editCampaign // if we are editing targeting after campaign object has been initialized
  ) {
    if (multiDevices) {
      multiDeviceCampaigns = targeting?.template?.device_platforms?.flatMap((device) => {
        return targeting?.template?.user_os?.length > 0
          ? targeting?.template?.user_os?.flatMap((userOs) => {
              return campaignObject?.map((camps) => {
                const temp = targeting?.template;
                const { newFbArr, audience_network, instaArr, messengerArr } = handlePlatforms(
                  device,
                  {
                    facebook_positions: temp?.facebook_positions,
                    instagram_positions: temp?.instagram_positions,
                    messenger_positions: temp?.messenger_positions,
                    audience_network_positions: temp?.audience_network_positions,
                  },
                  targeting?.template?.publisher_platforms
                );
                const {
                  instagram_positions,
                  facebook_positions,
                  messenger_positions,
                  audience_network_positions,
                  ...templateWithoutManualProps
                } = targeting?.template;
                const manualProps = {
                  ...(newFbArr.length > 0 ? { facebook_positions: newFbArr } : {}),
                  ...(instaArr.length > 0 ? { instagram_positions: instaArr } : {}),
                  ...(messengerArr.length > 0 ? { messenger_positions: messengerArr } : {}),
                  ...(audience_network.length > 0
                    ? { audience_network_positions: audience_network }
                    : {}),
                };
                return {
                  ...camps,
                  budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
                  budget: targettingTemplateData?.budget,
                  objective: targettingTemplateData?.objective,
                  bidStrategy: targettingTemplateData?.bidStrategy,
                  adSets: camps?.adSets.map((adSet) => ({
                    ...adSet,
                    targeting: {
                      ...targeting,
                      template: {
                        ...templateWithoutManualProps,
                        device_platforms: [device],
                        user_os: [userOs],
                        ...manualProps,
                      },
                    },
                  })),
                };
              });
            })
          : campaignObject?.map((camps) => {
              const temp = targeting?.template;
              const { newFbArr, audience_network, instaArr, messengerArr } = handlePlatforms(
                device,
                {
                  facebook_positions: temp?.facebook_positions,
                  instagram_positions: temp?.instagram_positions,
                  messenger_positions: temp?.messenger_positions,
                  audience_network_positions: temp?.audience_network_positions,
                },
                targeting?.template?.publisher_platforms
              );
              const {
                instagram_positions,
                facebook_positions,
                messenger_positions,
                audience_network_positions,
                ...templateWithoutManualProps
              } = targeting?.template;
              const manualProps = {
                ...(newFbArr.length > 0 ? { facebook_positions: newFbArr } : {}),
                ...(instaArr.length > 0 ? { instagram_positions: instaArr } : {}),
                ...(messengerArr.length > 0 ? { messenger_positions: messengerArr } : {}),
                ...(audience_network.length > 0
                  ? { audience_network_positions: audience_network }
                  : {}),
              };
              return {
                ...camps,
                budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
                budget: targettingTemplateData?.budget,
                objective: targettingTemplateData?.objective,
                bidStrategy: targettingTemplateData?.bidStrategy,
                adSets: camps?.adSets.map((adSet) => ({
                  ...adSet,
                  targeting: {
                    ...targeting,
                    template: {
                      ...templateWithoutManualProps,
                      device_platforms: [device],
                      ...manualProps,
                    },
                  },
                })),
              };
            });
      });
    }
    if (targeting?.template?.geo_locations?.countries?.length > 0 && multiCountry) {
      campaignMainData.push(
        ...handleMultiCountry(
          multiDevices ? multiDeviceCampaigns : campaignObject,
          !multiDevices && editCampaign
        )
      );
    } else {
      campaignMainData.push(...multiDeviceCampaigns);
    }
  }

  function handleMultiCountry(campaignObject, isEdited) {
    const modifiedCampaigns = targeting?.template?.geo_locations?.countries.flatMap((country) => {
      multiDeviceCampaigns = campaignObject?.map((campaign) => ({
        ...campaign,
        budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
        budget: targettingTemplateData?.budget,
        objective: targettingTemplateData?.objective,
        bidStrategy: targettingTemplateData?.bidStrategy,
        adSets: campaign.adSets.map((adSet) => ({
          ...adSet,
          targeting: {
            ...(isEdited ? targeting : adSet?.targeting),
            isScheduled: targeting?.scheduledStartDate,
            template: {
              ...(isEdited ? targeting?.template : adSet?.targeting?.template),
              geo_locations: { ...targeting?.template?.geo_locations, countries: [country] },
            },
          },
        })),
      }));
      return multiDeviceCampaigns;
    });
    return modifiedCampaigns;
  }

  const targeting = {
    ...targettingTemplateData,
    scheduledStartDate: targettingTemplateData?.scheduledStartDate || "",
    scheduledEndDate: targettingTemplateData?.scheduledEndDate || "",
    platformId: targettingTemplateData?.platform?.local_id,
  };
  const multiDevices = targeting?.multiDevices,
    multiCountry = targeting?.multiCountry;
  /***
   * For VS SERVICE
   */
  if (serviceType === SERVICE_TYPE_VS) {
    if (instantLaunch) {
      let campaignInitialObject = [];
      if (chooseDraftOrNew === CONTINUES_DRAFT) {
        campaignInitialObject = handleDraft();
      } else {
        campaignInitialObject = [
          {
            content_type: CONTENT_TYPE_ARTICLE,
            name: "{vs}-{country}-{device}-{brand}-{user}-{article}-{alias}-{campaignid}",
            viewTitle: instantLaunchData?.title,
            objective: targettingTemplateData?.objective,
            // advertiserId: contentData?.advertiser?.id,
            id: instantLaunchData?.id, // NOT ADD IN PAYLOAD
            slug: instantLaunchData?.slug,
            conntentNikName: instantLaunchData?.nickname,
            language_code: instantLaunchData?.language_code,
            status: "PAUSED", // CURRENTLY FIXED
            specialAdCategories: ["NONE"],
            bidStrategy: targettingTemplateData?.bidStrategy,
            cType: C_TYPE_CBO, // NOT ADD IN PAYLOAD  ( CBO/ABO )
            budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
            budget: targettingTemplateData?.budget, // WILL REPLACE dail/Live
            adSets: [
              {
                name: "{vs}-{country}-{device}-{brand}-{user}-{article}-{alias}-{campaignid}-{adsetid}",
                isDynamicCreative: false,
                dof: "OPT_IN",
                billingEvent,
                status: "PAUSED",
                edited: false,
                targeting: { ...targeting, isScheduled: targeting?.scheduledStartDate },
                ads: [],
                isDynamicTemp: true,
                isNonDynamicTemp: true,
              },
            ],
          },
        ];
        for (let i = 0; i < inputs?.campaignCount - 1; i++) {
          campaignInitialObject.push(campaignInitialObject[0]);
        }
      }
      if (multiCountry || multiDevices) {
        handleMultiCampaigns(
          multiDevices,
          multiCountry,
          campaignMainData,
          targeting,
          multiDeviceCampaigns,
          campaignInitialObject,
          handlePlatforms,
          handleMultiCountry
        );
      } else {
        campaignMainData = campaignInitialObject;
      }
      // } else if (editCampaign && !instantLaunch) {
      //   if (multiCountry || multiDevices) {
      //     handleMultiCampaigns(
      //       multiDevices,
      //       multiCountry,
      //       campaignMainData,
      //       targeting,
      //       multiDeviceCampaigns,
      //       campaignObject,
      //       handlePlatforms,
      //       handleMultiCountry,
      //       editCampaign
      //     );
      //   } else {
      //     campaignObject?.map((camps) => {
      //       campaignMainData.push({
      //         ...camps,
      //         budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
      //         budget: targettingTemplateData?.budget,
      //         objective: targettingTemplateData?.objective,
      //         bidStrategy: targettingTemplateData?.bidStrategy,
      //         adSets: camps?.adSets?.map((adSet) => ({
      //           ...adSet,
      //           targeting: { ...targeting, isScheduled: targeting?.scheduledStartDate },
      //         })),
      //       });
      //     });
      //   }
      // }
    } else {
      contentContentData?.forEach((contentData) => {
        const campaignName = bucketData?.campaign_nomenclature;
        const campaignObject = {
          content_type: bucketData?.content_type,
          name: campaignName,
          viewTitle: contentData?.title,
          objective: targettingTemplateData?.objective,
          // advertiserId: contentData?.advertiser?.id,
          id: contentData?.id, // NOT ADD IN PAYLOAD
          slug: contentData?.slug,
          conntentNikName: contentData?.nickname,
          language_code: contentData?.language_code,
          status: "PAUSED", // CURRENTLY FIXED
          specialAdCategories: ["NONE"],
          bidStrategy: targettingTemplateData?.bidStrategy,
          cType: C_TYPE_CBO, // NOT ADD IN PAYLOAD  ( CBO/ABO )
          budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
          budget: targettingTemplateData?.budget, // WILL REPLACE dail/Live
          adSets: [
            {
              name: bucketData?.adset_nomenclature,
              isDynamicCreative: false,
              dof: "OPT_IN",
              billingEvent,
              status: "PAUSED",
              edited: false,
              targeting: { ...targeting, isScheduled: targeting?.scheduledStartDate },
              ads: [],
              isDynamicTemp: true,
              isNonDynamicTemp: true,
            },
          ],
        };
        if (multiCountry || multiDevices) {
          handleMultiCampaigns(
            multiDevices,
            multiCountry,
            campaignMainData,
            targeting,
            multiDeviceCampaigns,
            [campaignObject],
            handlePlatforms,
            handleMultiCountry,
            editCampaign
          );
        } else {
          campaignMainData.push(campaignObject);
        }
      });
    }
  }

  /***
   * For SAS SERVICE
   */
  if (serviceType === SERVICE_TYPE_SAS) {
    /***
     * This section handles the automatic/manual selection of creatives for Facebook platforms.
     */
    if (chooseDraftOrNew === CONTINUES_DRAFT) {
      const {
        name = "",
        isDynamicCreative = false,
        dof,
        status = "PAUSED",
        edited = false,
        isDynamicTemp = true,
        isNonDynamicTemp = true,
        ...restProps
      } = targeting;

      const finalTemplateData = isTiktokPlatformLaunch
        ? targeting
        : {
            name,
            isDynamicCreative,
            dof,
            billingEvent,
            status,
            edited,
            isDynamicTemp,
            isNonDynamicTemp,
            targeting: { ...restProps },
          };
      campaignMainData = handleDraft(finalTemplateData);
    } else if (isSasKeywordGroup || isSasCampaign) {
      // if (editCampaign) {
      //   if (multiCountry || multiDevices) {
      //     handleMultiCampaigns(
      //       multiDevices,
      //       multiCountry,
      //       campaignMainData,
      //       targeting,
      //       multiDeviceCampaigns,
      //       campaignObject,
      //       handlePlatforms,
      //       handleMultiCountry,
      //       editCampaign
      //     );
      //   } else if (isTiktokPlatformLaunch) {
      //     campaignObject?.map((camps) => {
      //       campaignMainData.push({
      //         ...camps,
      //         ...(targettingTemplateData?.budget < 50
      //           ? { budget: 50 }
      //           : { budget: targettingTemplateData?.budget }),
      //         budgetMode: targettingTemplateData?.budgetMode,
      //         adSets: camps?.adSets?.map((adSet) => ({
      //           ...adSet,
      //           ...targeting,
      //           ...(targeting?.budget < 20 ? { budget: 20 } : { budget: targeting?.budget }),
      //           isScheduled: targeting?.scheduledStartDate,
      //         })),
      //       });
      //     });
      //   } else {
      //     campaignObject?.map((camps) => {
      //       campaignMainData.push({
      //         ...camps,
      //         budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
      //         budget: targettingTemplateData?.budget,
      //         objective: targettingTemplateData?.objective,
      //         bidStrategy: targettingTemplateData?.bidStrategy,
      //         adSets: camps?.adSets?.map((adSet) => ({
      //           ...adSet,
      //           targeting: { ...targeting, isScheduled: targeting?.scheduledStartDate },
      //         })),
      //       });
      //     });
      //   }
      // } else {
      bucketData?.bucket_content?.forEach((contentData) => {
        var adSets = [];
        var ads = [];
        var adSetObj = {
          name: inputs?.utm_name + " " + contentData?.name,
          isDynamicCreative: false,
          dof: "OPT_IN",
          billingEvent,
          status: "PAUSED",
          edited: false,
          isDynamicTemp: true,
          isNonDynamicTemp: true,
          targeting: { ...targeting, isScheduled: targeting?.scheduledStartDate ? true : false },
          // ads,
        };

        if (inputs?.creative_auto_select && !isDraft) {
          function mergeArrays(array1, array2) {
            var mergedArray = [];
            var minLength = Math.min(array1.length, array2.length);

            for (var i = 0; i < minLength; i++) {
              mergedArray.push(array1[i], array2[i]);
            }

            // Add any remaining elements from the longer array
            if (array1.length > minLength) {
              mergedArray = mergedArray.concat(array1.slice(minLength));
            } else if (array2.length > minLength) {
              mergedArray = mergedArray.concat(array2.slice(minLength));
            }

            return mergedArray;
          }
          if (inputs?.nonAdGroup) {
            var selectedImages = selectedImage.filter((elem) => {
              return isSasCampaign
                ? contentData.sub_content_id === elem?.categoryId
                : contentData.content_id === elem?.keywordId;
            });

            var selectedArray1 = selectedImages[0]?.selectedImages || [];
            var selectedArray2 = inputs?.includeVideos
              ? selectedImages[0]?.selectedVideos || []
              : [];

            ads = mergeArrays(selectedArray1, selectedArray2);
          } else {
            ads = isSasCampaign
              ? adGroupDataSource?.filter(
                  (elem) => contentData?.sub_content_id === elem?.categoryId
                )?.[0]?.selectedGroups
              : adGroupDataSource?.filter(
                  (elem) => contentData?.content_id === elem?.keywordId
                )?.[0]?.selectedGroups;
          }
          const numCopies = Math.ceil(inputs?.ad_count * inputs?.adset_count) / ads?.length;
          let copiedArrays = [];
          for (let i = 0; i < numCopies; i++) {
            for (let j = 0; j < ads?.length; j++) copiedArrays.push(ads[j]);
          }

          console.log(copiedArrays);

          for (let j = 0; j < inputs?.adset_count; j++) {
            let newAds = [];
            newAds.push(
              ...copiedArrays.slice(j * inputs?.ad_count, inputs?.ad_count + j * inputs?.ad_count)
            );
            adSets.push({ ...adSetObj, ...(j > 0 && { isDuplicate: true }), ads: newAds });
          }
        } else {
          adSets.push({ ...adSetObj, ads });
        }

        let campaignObject = {
          content_type: bucketData?.content_type,
          creativeAutoSelect: inputs?.creative_auto_select || false,
          name: isSasKeywordGroup ? inputs?.utm_name + " " + contentData?.name : contentData?.name,
          edited: false,
          mainContentName: contentData?.name,
          viewTitle: contentData?.name,
          conntentNikName: contentData?.name,
          id: contentData?.content_id,
          categoryId: contentData?.sub_content_id,
          categoryName: contentData?.sub_content_name,
          status: "PAUSED",
          specialAdCategories: ["NONE"],
        };

        /*
        Tiktok Launch campaign object for SAS service
        */
        if (isTiktokPlatformLaunch) {
          campaignObject = {
            ...campaignObject,
            // bidStrategy: targettingTemplateData?.bidStrategy,
            ...(targettingTemplateData?.budget < 50
              ? { budget: 50 }
              : { budget: targettingTemplateData?.budget }),

            budgetMode: targettingTemplateData?.budgetMode,
            cType: C_TYPE_ABO, // NOT ADD IN PAYLOAD  ( CBO/ABO )
            // budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
            // budget: targettingTemplateData?.budget, // WILL REPLACE dail/Live
            adSets: [
              {
                name: contentData?.name,
                creativeMaterialMode: "CUSTOM",
                ...targeting,
                ...(targeting?.budget < 20 ? { budget: 20 } : { budget: targeting?.budget }),
                isDynamicTemp: true,
                isNonDynamicTemp: true,
                status: "PAUSED",
                edited: false,
                isScheduled: targeting?.scheduledStartDate,
                ads: [],
              },
            ],
          };
          campaignMainData.push(campaignObject);
        } else {
          campaignObject = {
            ...campaignObject,
            objective: targettingTemplateData?.objective,
            cType: C_TYPE_CBO, // NOT ADD IN PAYLOAD  ( CBO/ABO )
            bidStrategy: targettingTemplateData?.bidStrategy,
            budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
            budget: targettingTemplateData?.budget, // WILL REPLACE dail/Live
            adSets,
          };
          if (multiCountry || multiDevices) {
            handleMultiCampaigns(
              multiDevices,
              multiCountry,
              campaignMainData,
              targeting,
              multiDeviceCampaigns,
              [campaignObject],
              handlePlatforms,
              handleMultiCountry
            );
          } else {
            campaignMainData.push(campaignObject);
          }

          // if (multiCountry || multiDevices) {
          //   if (multiDevices) {
          //     multiDeviceCampaigns = targeting?.template?.device_platforms?.flatMap((device) => {
          //       return [campaignObject]?.map((camps) => {
          //         const temp = targeting?.template;
          //         const { newFbArr, audience_network, instaArr, messengerArr } = handlePlatforms(
          //           device,
          //           {
          //             facebook_positions: temp?.facebook_positions,
          //             instagram_positions: temp?.instagram_positions,
          //             messenger_positions: temp?.messenger_positions,
          //             audience_network_positions: temp?.audience_network_positions,
          //           }
          //         );
          //         const manualProps = {
          //           ...(newFbArr.length > 0 ? { facebook_positions: newFbArr } : {}),
          //           ...(instaArr.length > 0 ? { instagram_positions: instaArr } : {}),
          //           ...(messengerArr.length > 0 ? { messenger_positions: messengerArr } : {}),
          //           ...(audience_network.length > 0
          //             ? { audience_network_positions: audience_network }
          //             : {}),
          //         };
          //         return {
          //           ...camps,
          //           adSets: camps?.adSets.map((adSet) => ({
          //             ...adSet,
          //             targeting: {
          //               ...adSet?.targeting,
          //               template: {
          //                 ...adSet?.targeting?.template,
          //                 device_platforms: [device],
          //                 ...manualProps,
          //               },
          //             },
          //           })),
          //         };
          //       });
          //     });
          //     console.log({ multiDeviceCampaigns });
          //   }
          //   if (targeting?.template?.geo_locations?.countries?.length > 0) {
          //     campaignMainData.push(
          //       ...handleMultiCountry(multiDevices ? multiDeviceCampaigns : [campaignObject])
          //     );
          //   } else {
          //     campaignMainData.push(...multiDeviceCampaigns);
          //   }
          // } else {
          //   campaignMainData.push(campaignObject);
          // }
        }
      });
    }
  }
  /***
   * For LeadGen Service
   */
  if (serviceType === SERVICE_TYPE_LEAD_GEN) {
    if (instantLaunch) {
      if (chooseDraftOrNew === CONTINUES_DRAFT) {
        campaignMainData = handleDraft(targeting);
      } else {
        const campaignObject = {
          // bidStrategy: targettingTemplateData?.bidStrategy,
          ...(targettingTemplateData?.budget < 50
            ? { budget: 50 }
            : { budget: targettingTemplateData?.budget }),
          mainContentName: instantLaunchData?.name,
          viewTitle: instantLaunchData?.name,
          conntentNikName: instantLaunchData?.name,
          budgetMode: targettingTemplateData?.budgetMode,
          cType: C_TYPE_ABO, // NOT ADD IN PAYLOAD  ( CBO/ABO )
          content_type: "ARTICLE",
          objective: targettingTemplateData?.objective,
          name: instantLaunchData?.name,
          id: instantLaunchData?.id,
          categoryId: instantLaunchData?.id,
          categoryName: instantLaunchData?.name,
          alias: instantLaunchData?.alias,
          status: "PAUSED",
          adSets: [
            {
              name: instantLaunchData?.name,
              creativeMaterialMode: "CUSTOM",
              dof: "OPT_IN",
              ...targeting,
              ...(targeting?.budget < 20 ? { budget: 20 } : { budget: targeting?.budget }),
              status: "PAUSED",
              isDynamicTemp: true,
              isNonDynamicTemp: true,
              edited: false,
              isScheduled: targeting?.scheduledStartDate,
              ads: [],
            },
          ],
        };
        for (let i = 0; i < inputs?.campaignCount; i++) {
          campaignMainData.push(campaignObject);
        }
      }
    } else if (chooseDraftOrNew === CONTINUES_DRAFT) {
      const {
        name = "",
        isDynamicCreative = false,
        dof,
        status = "PAUSED",
        edited = false,
        isDynamicTemp = true,
        isNonDynamicTemp = true,
        ...restProps
      } = targeting;

      const finalTemplateData = isTiktokPlatformLaunch
        ? targeting
        : {
            name,
            isDynamicCreative,
            dof,
            billingEvent,
            status,
            edited,
            isDynamicTemp,
            isNonDynamicTemp,
            targeting: { ...restProps },
          };
      campaignMainData = handleDraft(finalTemplateData);
    } else {
      bucketData?.bucket_content?.forEach((contentData) => {
        const campaignObject = {
          content_type: bucketData?.content_type,
          name: contentData?.name,
          mainContentName: contentData?.name,
          viewTitle: contentData?.name,
          conntentNikName: contentData?.name,
          objective: targettingTemplateData?.objective,
          id: contentData?.content_id,
          categoryId: contentData?.sub_content_id,
          categoryName: contentData?.sub_content_name,
          status: "PAUSED",
          specialAdCategories: ["NONE"],
          bidStrategy: targettingTemplateData?.bidStrategy,
          cType: C_TYPE_CBO, // NOT ADD IN PAYLOAD  ( CBO/ABO )
          budget_type: targettingTemplateData?.budget_type, // NOT ADD IN PAYLOAD ( Daily / Lifetime )
          budget: targettingTemplateData?.budget, // WILL REPLACE dail/Live
          adSets: [
            {
              name: contentData?.name,
              isDynamicCreative: false,
              billingEvent,
              status: "PAUSED",
              edited: false,
              isDynamicTemp: true,
              dof: "OPT_IN",
              targeting: { ...targeting, isScheduled: targeting?.scheduledStartDate },
              ads: [],
            },
          ],
        };
        if (multiCountry || multiDevices) {
          handleMultiCampaigns(
            multiDevices,
            multiCountry,
            campaignMainData,
            targeting,
            multiDeviceCampaigns,
            [campaignObject],
            handlePlatforms,
            handleMultiCountry,
            editCampaign
          );
        } else {
          campaignMainData.push(campaignObject);
        }
      });
    }
  }
  setCampaignObject(campaignMainData);
}

export default initialSetCampaign;
