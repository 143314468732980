import { Form, Select, Button, Tooltip } from "antd";
import Loader from "../../../loader";
import LookDrawer from "../../../LookDrawer";
import { useState } from "react";
import CreateView from "../../view/MainForm";
import { PRODUCT_BIT } from "../../view/PageBits";
import ActionIconRender from "../../../ActionIconRender";
import { GET_CMS_ALL_PRODUCTS_ID_NAME } from "../../../../shared/gql/gqlSchema/cmsGql";
import { useCustomQuery } from "hooks/apolloClientHooks";

const { Option } = Select;

export default function ProductHandle(props) {
  const { value, onChange = () => {} } = props;
  const [formRenderType, setFormRenderType] = useState("");
  const [editId, setEditId] = useState();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const formType = PRODUCT_BIT;
  const {
    data = [],
    loading,
    error,
    refetch,
  } = useCustomQuery(GET_CMS_ALL_PRODUCTS_ID_NAME, {
    notifyOnNetworkStatusChange: true,
  });

  const showDrawer = (type, id) => {
    setFormRenderType(type);
    setVisibleDrawer(true);
    if (id) {
      setEditId(id);
    }
  };

  const onCloseDrawer = () => {
    setFormRenderType("");
    setVisibleDrawer(false);
    setEditId(null);
  };

  const drawerProps = {
    title: "Add New",
    icon: () => <ActionIconRender iconType="appstore" />,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 1200,
  };

  const formProps = {
    isNested: true,
    refetch,
    postType: formType,
  };

  const addFormProps = {
    ...formProps,
  };
  const editFormProps = {
    ...formProps,
    id: editId,
  };

  return (
    <div className="position-relative">
      {loading && <Loader elementCenter />}
      <LookDrawer {...drawerProps}>
        {visibleDrawer && formRenderType === "add" && (
          <CreateView view="create" {...addFormProps} />
        )}
      </LookDrawer>
      <Form layout="vertical">
        <div className="side-inp-btn-wrap">
          <div className="main-inp">
            <Form.Item className="look-form-input mb-0">
              <Select
                name="product"
                className="w-100"
                placeholder="Select Product"
                onChange={onChange}
                value={value}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={null}>None</Option>
                {data?.CMS_All_Products?.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Tooltip title="Add">
            <Button
              type="link"
              onClick={() => showDrawer("add")}
              icon={<ActionIconRender iconType="plus-circle" />}
            />
          </Tooltip>
        </div>
      </Form>

      {/* <pre>{JSON.stringify({ data }, null, 2)}</pre> */}
    </div>
  );
}
