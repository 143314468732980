import { Checkbox, Col, Image, Row } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";
import PopoverWrapper from "components/popoverWrapper";
import React, { Fragment, useState } from "react";
import {
  CREATIVE_TYPE_BODY,
  CREATIVE_TYPE_DESCRIPTION,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_TITLE,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import { MoreOutlined } from "@ant-design/icons";
import cx from "cx";

function AdgroupDetails(props) {
  const {
    isCreative = false,
    imageUrls = [],
    videoUrls = [],
    renderData = [],
    handleComplianceIcon = () => {},
    isComplianceModal = false,
    setSelectedCreatives = () => {},
    selectedCreatives = {},
    actionType,
    isImage = false,
    isVideo = false,
    setVisibleChildCompliantModal = () => {},
  } = props;

  const finalCreative = [...imageUrls, ...videoUrls];
  console.log(renderData);
  const [playModal, setPlayModal] = useState(false);
  const [rowProps, setRowProps] = useState();
  function handleComplianceKey() {
    switch (actionType) {
      case CREATIVE_TYPE_IMAGE:
        return "imageCompliance";
      case CREATIVE_TYPE_VIDEO:
        return "videoCompliance";
      case CREATIVE_TYPE_BODY:
        return "bodyCompliance";
      case CREATIVE_TYPE_TITLE:
        return "titleCompliance";
      case CREATIVE_TYPE_DESCRIPTION:
        return "descriptionCompliance";
      default:
        return "imageCompliance";
    }
  }

  const uniqueKey = handleComplianceKey();
  const onSelect = (id) => {
    const alreadyPresent = selectedCreatives?.[uniqueKey]
      ?.map((data) => data?.creativeId)
      ?.includes(id);
    if (alreadyPresent) {
      setSelectedCreatives((prev) => {
        return { ...prev, [uniqueKey]: prev?.uniqueKey?.filter((data) => data?.creativeId !== id) };
      });
    } else {
      setSelectedCreatives((prev) => {
        const prevData = prev?.[uniqueKey] || [];
        return {
          ...prev,
          [uniqueKey]: [
            ...prevData,
            { creativeId: id, complianceStatus: "", notes: "", actionType },
          ],
        };
      });
    }
  };
  const onSelectAll = (ids) => {
    setSelectedCreatives((prev) => {
      const prevData = prev?.[uniqueKey] ?? [];
      const allIdsPresent = ids.every((id) => prevData?.some((data) => data?.creativeId === id));
      if (allIdsPresent) {
        return { ...prev, [uniqueKey]: [] };
      } else {
        const updatedData = [
          ...prevData,
          ...ids.map((id) => ({ creativeId: id, complianceStatus: "", notes: "", actionType })),
        ];
        return { ...prev, [uniqueKey]: updatedData };
      }
    });
  };
  const checkBoxItems = (data) => {
    return (
      <div className="mr-8">
        <div className="d-flex align-items-center">
          <Checkbox
            className="creative-select mr-8"
            onChange={() => onSelect(data?.id)}
            checked={selectedCreatives?.[uniqueKey]
              ?.map((data) => data?.creativeId)
              ?.includes(data?.id)}
          />
          <ActionIconRender iconType={handleComplianceIcon(data?.complianceStatus || "PENDING")} />
        </div>
      </div>
    );
  };
  const actionItems = (id) => {
    const poverWrapperOptions = [
      {
        option: "edit",
        title: "View/ Edit Request",
        optionProps: {
          onClick: () => {
            onSelect(id);
            setVisibleChildCompliantModal(true);
          },
        },
      },
    ];
    return (
      <PopoverWrapper className="adgroup-action-item flex-center" options={poverWrapperOptions}>
        <MoreOutlined className="pop-info" />
      </PopoverWrapper>
    );
  };
  const hoverContent = (data) => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          {isComplianceModal && actionItems(data?.id)}
          <div
            onClick={() => {
              setPlayModal(true);
              setRowProps(data?.r2Url);
            }}
          >
            {data?.r2Url && data?.thumbUrl && (
              <>
                <LookButton
                  className="mr-8 ply-btn"
                  tooltipTitle="Play video"
                  block
                  iconType={"play"}
                  onClick={() => {
                    setPlayModal(true);
                    setRowProps(data?.r2Url);
                  }}
                ></LookButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const videoPlayAction = (data) => (
    <div
      className="video-play-action"
      onClick={() => {
        setPlayModal(true);
        setRowProps(data?.r2Url);
      }}
    >
      {data?.r2Url && data?.thumbUrl && (
        <>
          <LookButton
            className="mr-8 ply-btn"
            tooltipTitle="Play video"
            block
            iconType={"play"}
            onClick={() => {
              setPlayModal(true);
              setRowProps(data?.r2Url);
            }}
          ></LookButton>
        </>
      )}
    </div>
  );
  const finalRender = isCreative ? finalCreative : renderData;

  const imageUrl = (data) => (isImage ? data?.r2Url : data?.thumbUrl);
  const complianceCreativeView = (data) => (
    <div className="creative-complienceCreativeView p-0">
      <div className="header-content">
        {isComplianceModal && checkBoxItems(data)}
        <div className="action-wrapper">{actionItems(data?.id)}</div>
      </div>
      <div className="image-content-wrapper">
        <span
          className="bg-image"
          style={{
            background: `url("${imageUrl(data)}") center / cover no-repeat`,
          }}
        />
        <Image src={imageUrl(data)} preview={false} />
      </div>
      {videoPlayAction(data)}
    </div>
  );
  const drawerCreativeView = (data) => (
    <div className="creative-adgroup-card p-0">
      <Image src={isImage ? data?.r2Url : data?.thumbUrl} preview={false} />
    </div>
  );
  const Wrapper = isComplianceModal ? Fragment : Row;
  const wrapperProps = isComplianceModal ? {} : { gutter: 12 };

  const renderCreatives = () => (
    <Wrapper {...wrapperProps}>
      {finalCreative?.map((data, index) => {
        return isComplianceModal ? (
          complianceCreativeView(data)
        ) : (
          <Col key={"mediaItems" + index} xs={12} md={8} xl={4}>
            {drawerCreativeView(data)}
          </Col>
        );
      })}
    </Wrapper>
  );

  return (
    <div className="report-details">
      {playModal && (
        <>
          <LookModal
            title="Play Video"
            visible={playModal}
            onCancel={() => setPlayModal(false)}
            footer={false}
          >
            {playModal && <video className="w-100" src={rowProps?.s3Url} controls autoPlay />}
          </LookModal>
        </>
      )}
      {isComplianceModal && finalRender?.length > 0 && (
        <div className="d-flex" style={{ padding: "15px 0" }}>
          <Checkbox
            className="mr-8"
            onClick={() => {
              onSelectAll(finalRender?.map((data) => data?.id));
            }}
            checked={selectedCreatives?.[uniqueKey]?.length === finalRender?.length}
          />
          Select All
        </div>
      )}

      {isCreative ? (
        renderCreatives()
      ) : (
        <>
          {renderData?.map((data) => {
            return (
              <>
                <div className={cx("compliance-text-item", { isAction: isComplianceModal })}>
                  <div className="flex-grow-1 d-flex">
                    {isComplianceModal && checkBoxItems(data)}
                    <div className="flex-grow-1">
                      <p className="compliance-text">{data?.text}</p>
                    </div>
                  </div>
                  <div className="action-wrapper">{actionItems(data?.id)}</div>
                </div>
              </>
            );
          })}
        </>
      )}
    </div>
  );
}

export default AdgroupDetails;
