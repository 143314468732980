import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { I_CAMPAIGNS_LAUNCH } from "../../shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const Launch = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "iCampaigns-Launch" */ "pages/dashboardPages/iCampaigns/Launch")
  )
);

const iCampaignsRoutes = [
  route("/i-campaigns/launch", Launch, ROUTE_DASHBOARD, false, I_CAMPAIGNS_LAUNCH),
];

export default iCampaignsRoutes;
