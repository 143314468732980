import { gql } from "@apollo/client";

const MEDIA_LIST_RESPONSE = gql`
  fragment mediaListResponse on listResponse {
    data
    pageInfo {
      totalCount
      totalPages
    }
  }
`;

export const GET_S3_LISTMEDIA = gql`
  ${MEDIA_LIST_RESPONSE}
  query s3Listmedia($category: ID, $page: Int, $size: Int) {
    s3Listmedia(category: $category, page: $page, size: $size) {
      ...mediaListResponse
    }
  }
`;

/**
 * * id: ID
 * * category: String
 */

const FILE_CATEGORY_RESPONSE = gql`
  fragment fileCategory on fileCategory {
    id
    category
  }
`;

export const GET_FILE_CATEGORIES = gql`
  ${FILE_CATEGORY_RESPONSE}
  query fileCategories {
    fileCategories {
      ...fileCategory
    }
  }
`;

// For i-Task

export const TASK_SERVICE_UPLOAD_FILE = gql`
  mutation CreateAttachment($file_url: [String], $resource_id: ID, $resource_type: String) {
    CreateAttachment(
      file_url: $file_url
      resource_id: $resource_id
      resource_type: $resource_type
    ) {
      author {
        id
        user_name
      }
      createdAt
      file_url
      id
      resource_id
      resource_type
      updatedAt
    }
  }
`;

export const TASK_GET_ATTACHMENTS = gql`
  query ResourceAttachments($resource_id: Int, $resource_type: String) {
    ResourceAttachments(resource_id: $resource_id, resource_type: $resource_type) {
      author {
        id
        user_name
      }
      createdAt
      file_url
      id
      resource_id
      resource_type
      updatedAt
    }
  }
`;

export const TASK_SERVICE_UPDATE_FILE = gql`
  mutation UpdateAttachment($file_url: String, $resource_id: ID, $id: ID!, $resource_type: String) {
    UpdateAttachment(
      id: $id
      file_url: $file_url
      resource_id: $resource_id
      resource_type: $resource_type
    ) {
      author {
        id
        user_name
      }
      createdAt
      file_url
      id
      resource_id
      resource_type
      updatedAt
    }
  }
`;

export const TASK_SERVICE_DELETE_FILE = gql`
  mutation DeleteAttachment($id: [ID!]) {
    DeleteAttachment(id: $id)
  }
`;

export const GET_COMMENTS_BY_TYPE = gql`
  query ResourceComments(
    $column: String
    $order: order
    $page: Int!
    $resource_id: Int
    $resource_type: String
    $search: String
    $size: Int
  ) {
    ResourceComments(
      column: $column
      order: $order
      page: $page
      resource_id: $resource_id
      resource_type: $resource_type
      search: $search
      size: $size
    ) {
      data {
        id
        createdAt
        updatedAt
        comment
        author {
          id
          name
        }
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const TASK_SERVICE_CREATE_COMMENT = gql`
  mutation CreateComment($comment: String, $resource_id: ID, $resource_type: String) {
    CreateComment(comment: $comment, resource_id: $resource_id, resource_type: $resource_type) {
      author {
        id
        name
      }
      comment
      id
      resource_id
      resource_type
      createdAt
    }
  }
`;

export const TASK_SERVICE_UPDATE_COMMENT = gql`
  mutation UpdateComment($comment: String, $id: ID!, $resource_id: ID, $resource_type: String) {
    UpdateComment(
      comment: $comment
      id: $id
      resource_id: $resource_id
      resource_type: $resource_type
    ) {
      author {
        id
        name
      }
      comment
      createdAt
      updatedAt
      id
      resource_id
      resource_type
    }
  }
`;

export const TASK_SERVICE_DELETE_COMMENT = gql`
  mutation DeleteComment($id: [ID!]) {
    DeleteComment(id: $id)
  }
`;

export const S3_FILE_UPLOAD = gql`
  mutation s3fileUpload($file_name: String, $service_name: String) {
    s3fileUpload(file_name: $file_name, service_name: $service_name) {
      url
      path
    }
  }
`;

export const S3_FILE = gql`
  query s3file($path: String) {
    s3file(path: $path) {
      url
      path
    }
  }
`;

export const S3_MULTI_FILE_UPLOAD = gql`
  mutation s3MultiFileUpload(
    $category: String
    $file_name: [String]
    $is_personal: String
    $service_name: String
    $workspace_id: String
    $fileType: fileType
    $source_img_url: [String]
  ) {
    s3MultiFileUpload(
      category: $category
      file_name: $file_name
      is_personal: $is_personal
      service_name: $service_name
      workspace_id: $workspace_id
      fileType: $fileType
      source_img_url: $source_img_url
    ) {
      url
      public_url
      path
      file_name
    }
  }
`;

export const S3_MULTI_FILE = gql`
  query s3MultiFile($path: [String]) {
    s3MultiFile(path: $path) {
      file_name
      url
      path
      public_url
    }
  }
`;

export const IMAGE_PROGRESS = gql`
  mutation imageProcess($image_url: String, $sizes: [String]) {
    imageProcess(image_url: $image_url, sizes: $sizes) {
      message
      success
    }
  }
`;

/**
 ** type groupInput {
 * category_id: ID!
 * description_id: ID
 * image_id: ID
 * thumbnail_id: ID
 * title_id: ID
 * video_id: ID
 * }
 */

const CL_GROUP_RESPONSE = gql`
  fragment clGroups on clGroups {
    id
    category_id
    title {
      id
      title
    }
    body {
      id
      body
    }
    description {
      id
      description
    }
    image {
      id
      imageHash
      s3Url
    }
    video {
      id

      s3Url
      thumbUrl
    }
  }
`;
const CL_GROUP_DATA_RESPONSE = gql`
  ${CL_GROUP_RESPONSE}
  fragment clGroupData on clGroupData {
    creative {
      ...clGroups
    }
    tags {
      id
      tag
    }
  }
`;

export const GQL_CL_ADD_GROUP = gql`
  ${CL_GROUP_DATA_RESPONSE}
  mutation clAddGroups($inputs: [groupInput]!, $tags: [String]) {
    clAddGroups(inputs: $inputs, tags: $tags) {
      status
      message
      data {
        ...clGroupData
      }
    }
  }
`;
export const GQL_CL_DELETE_GROUPS = gql`
  ${CL_GROUP_RESPONSE}
  mutation clDeleteGroups(
    $categoryId: ID!
    $categoryName: String!
    $groupIds: [ID]!
    $productId: ID!
  ) {
    clDeleteGroups(
      categoryId: $categoryId
      categoryName: $categoryName
      groupIds: $groupIds
      productId: $productId
    ) {
      data {
        ...clGroups
      }
      status
      message
    }
  }
`;
export const GQL_CT_DELETE_GROUPS = gql`
  mutation ctDeleteCreativeGroups($categoryId: ID!, $groupObjectIds: [String]!, $productId: ID!) {
    ctDeleteCreativeGroups(
      categoryId: $categoryId
      groupObjectIds: $groupObjectIds
      productId: $productId
    ) {
      message
      status
    }
  }
`;

export const GQL_CL_CREATIVE_GROUP = gql`
  ${CL_GROUP_DATA_RESPONSE}
  query clPagedGroup(
    $categoryId: ID!
    $productId: ID!
    $inputs: PaginationInput!
    $subCategoryId: ID
    $createdByMe: Boolean
  ) {
    clPagedGroup(
      categoryId: $categoryId
      productId: $productId
      subCategoryId: $subCategoryId
      inputs: $inputs
      createdByMe: $createdByMe
    ) {
      message
      status
      data {
        ...clGroupData
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;
export const GQL_CHECK_CREATIVES = gql`
  ${CL_GROUP_RESPONSE}
  query clCheckCreatives(
    $categoryId: ID!
    $creativeIds: [ID]!
    $creativeType: CreativeType!
    $productId: ID!
  ) {
    clCheckCreatives(
      categoryId: $categoryId
      creativeIds: $creativeIds
      creativeType: $creativeType
      productId: $productId
    ) {
      data {
        ...clGroups
      }
      message

      status
    }
  }
`;
export const CREATIVE_IMAGE_RESPONSE = gql`
  fragment clCreativeImage on clCreativeImage {
    id
    imageHash
    s3Url
    pHash
  }
`;

export const LAUNCH_GET_ADGROUPS_FOR_KEYWORDS = gql`
  ${CL_GROUP_RESPONSE}

  query clGetCreatives($inputs: [keywordInputs]!, $adgroup: Boolean!) {
    clGetCreatives(inputs: $inputs, adgroup: $adgroup) {
      data {
        categoryId
        keywordId
        keywordName
        message
        categoryName

        keywordName
        selectedGroups {
          ...clGroups
        }
      }
    }
  }
`;

export const CL_VIDEO_RESPONSE = gql`
  fragment clCreativeVideo on clCreativeVideo {
    id
    fbVideoId
    s3Url
    thumbUrl
  }
`;
export const CT_VIDEO_RESPONSE = gql`
  fragment ctVideoComplianceObject on ctVideoComplianceObject {
    video {
      id
      r2Url
    }
  }
`;

export const GQL_CL_VIDEO_THUMBNAIL = gql`
  ${CL_VIDEO_RESPONSE}
  query clVideoThumbnail($categoryId: ID!, $productId: ID!) {
    clVideoThumbnail(categoryId: $categoryId, productId: $productId) {
      data {
        ...clCreativeVideo
      }
    }
  }
`;

export const GQL_CL_ADD_VIDEO = gql`
  ${CL_VIDEO_RESPONSE}
  mutation clAddVideos($categoryId: ID!, $productId: ID!, $videoUrl: String!) {
    clAddVideos(categoryId: $categoryId, productId: $productId, videoUrl: $videoUrl) {
      data {
        ...clCreativeVideo
      }
    }
  }
`;
export const GQL_CT_ADD_VIDEO_THUMBS = gql`
  ${CT_VIDEO_RESPONSE}
  mutation ctAddVideoThumbs($thumbUrl: String!, $videoId: ID!, $currentThumb: Boolean) {
    ctAddVideoThumbs(currentThumb: $currentThumb, thumbUrl: $thumbUrl, videoId: $videoId) {
      data {
        ...ctVideoComplianceObject
      }
    }
  }
`;

export const GQL_CL_UPDATE_VIDEO = gql`
  ${CL_VIDEO_RESPONSE}
  mutation clUpdateVideo(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $thumbUrl: String!
    $videoId: ID!
  ) {
    clUpdateVideo(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      thumbUrl: $thumbUrl
      videoId: $videoId
    ) {
      data {
        ...clCreativeVideo
      }
    }
  }
`;
export const GQL_CL_DELETE_VIDEOS = gql`
  ${CL_VIDEO_RESPONSE}
  mutation clDeleteVideos($categoryId: ID!, $productId: ID!, $videoIds: [ID]!) {
    clDeleteVideos(categoryId: $categoryId, productId: $productId, videoIds: $videoIds) {
      data {
        ...clCreativeVideo
      }
      message
      status
    }
  }
`;
export const GQL_CT_DELETE_VIDEOS = gql`
  mutation ctDeleteCreativeVideos(
    $categoryId: ID!
    $productId: ID!
    $videoIds: [String]!
    $forceDelete: Boolean!
  ) {
    ctDeleteCreativeVideos(
      categoryId: $categoryId
      productId: $productId
      videoIds: $videoIds
      forceDelete: $forceDelete
    ) {
      message
      status
    }
  }
`;
export const LAUNCH_GET_IMAGES_FOR_KEYWORDS = gql`
  ${CREATIVE_IMAGE_RESPONSE}
  ${CL_VIDEO_RESPONSE}
  query clGetCreatives($inputs: [keywordInputs]!, $adgroup: Boolean!) {
    clGetCreatives(inputs: $inputs, adgroup: $adgroup) {
      data {
        categoryId
        keywordId
        keywordName
        message
        categoryName

        selectedImages {
          ...clCreativeImage
        }
        selectedVideos {
          ...clCreativeVideo
        }

        keywordName
      }
    }
  }
`;
