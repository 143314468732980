import { Segmented, Steps } from "antd";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CREATIVE_REQUEST_UPDATE_STATUS,
  DELETE_CREATIVE_REQUESTS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import {
  MOODBOARD_CREATIVE_TYPE_REQUEST,
  STATUS_NEW_CD,
  STATUS_NEW_MB,
  USER_LEVEL_L1,
  USER_LEVEL_L2,
  USER_LEVEL_L3,
} from "./creativesEnum";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import useTemplateDrawer from "./CreativeHooks/useTemplateDrawer";
import useRequestViewDrawer from "./CreativeHooks/useRequestViewDrawer";
import useRequestDrawer from "./CreativeHooks/useRequestDrawer";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import { PRODUCT_ID_LEADGEN } from "shared/SharedKeys";
import LookModal from "components/LookModal";
import RequestStatusUpdate from "./RequestStatusUpdate";
import { RECORD_UPDATED } from "shared/enum/apiResponse";

function RequestList(props) {
  const {
    actionType,
    isMediaBuyer,
    isCreativeDirector,
    isGraphicDesigner,
    isBusinessDeveloper,
    requestListData,
    data_refetch,
    data_loading,
    creativesRequestFetch = () => {},
    requestListrefetch = () => {},
  } = props;
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const [createdByMe, setCreatedByMe] = useState("All");
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const [userLevel, setUserLevel] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const { filterData } = useLookTableFilterData(persistKey);
  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const { ctGetRequestStatusConfig = {} } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};

  // const { havePermission: isBusinessDeveloper } = usePermissionCheck(
  //   CREATIVE_TRACKING_BUSINESS_DEVELOPER
  // );
  // const { havePermission: isCreativeDirector } = usePermissionCheck(
  //   CREATIVE_TRACKING_CREATIVE_DIRECTOR
  // );
  // const { havePermission: isMediaBuyer } = usePermissionCheck(CREATIVE_TRACKING_MEDIA_BUYER);
  // const { havePermission: isContentWriter } = usePermissionCheck(CREATIVE_TRACKING_CONTENT_WRITER);
  // const { havePermission: isGraphicDesigner } = usePermissionCheck(
  //   CREATIVE_TRACKING_GRAPHIC_DESIGNER
  // );
  // const permissionProps = {
  //   isMediaBuyer,
  //   isCreativeDirector,
  //   isGraphicDesigner,
  // };
  const { requestStatusFetch = () => {} } = useApiToStoreContext();
  const isCreativeRequest = actionType === MOODBOARD_CREATIVE_TYPE_REQUEST;
  // Optimize hooks logic/ replace with componnets
  const { createRequestForm, createRequest } = useRequestDrawer();
  const { createTemplateForm, createTemplate } = useTemplateDrawer();
  const { createRequestFormView, createRequestView } = useRequestViewDrawer();
  const [updatedData, setUpdatedData] = useState();
  // const permissionProps = {
  //   isBusinessDeveloper,
  //   isCreativeDirector,
  //   isMediaBuyer,
  //   isContentWriter,
  //   isGraphicDesigner,
  // };
  useEffect(() => {
    requestStatusFetch();
    handleUser();
  }, []);

  function handleUser() {
    switch (true) {
      case isMediaBuyer:
        setUserLevel(USER_LEVEL_L1);
        break;
      case isCreativeDirector:
        setUserLevel(USER_LEVEL_L2);
        break;
      default:
        setUserLevel(USER_LEVEL_L3);
    }
  }
  const onSubmitDeleteModal = () => {
    // let ID = [];
    // deleteModalPropsData.forEach(({ id }) => ID.push(parseInt(id)));
    // taskServicetaskDelete({
    //   variables: {
    //     task_id: ID,
    //   },
    // });
    requestDelete({ variables: { requestIds: deleteModalPropsData?.map((data) => data?.id) } });
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };

  const onOpenDeleteModal = (data) => {
    setDeleteModalPropsData(data);
    setDeleteModalVisible(true);
  };

  const onOpenStatusModal = (rowProps) => {
    setStatusModalVisible(true);
    const status = rowProps?.map((data) => {
      const { requestStatus = "" } = data;
      return requestStatus && requestStatus.includes(".")
        ? requestStatus.split(".")[1]
        : requestStatus;
    });
    if (status?.every((element) => element === status?.[0])) {
      setUpdatedData({ data: rowProps, status, requestStatus: status?.[0] });
    } else {
      openNotification({ type: "error", message: "Please select status " });
    }
  };
  const onCloseStatusModal = (rowProps) => {
    setStatusModalVisible(false);
    setUpdatedData([]);
  };

  const onSubmitStatusModal = () => {
    updateRequest({
      variables: {
        requestIds: updatedData?.data?.map((data) => data?.id),
        status: updatedData?.status?.[0],
      },
    });
  };

  function handleStatus(userLevel, assignedStatus) {
    const visibleStatus = ctGetRequestStatusConfig?.[userLevel]?.statuses?.find(
      (status) => status?.assigned_status === assignedStatus
    )?.visible_status;
    return visibleStatus;
  }
  const commonProps = {
    handleStatus,
    requestType: actionType,
    userLevel,
    isCreativeDirector,
    isGraphicDesigner,
    isMediaBuyer,
  };

  const handleDisplayRequest = (otherprops) => {
    const { requestDetails, category, status, ...propswithOutRequest } = otherprops;
    const result = isBusinessDeveloper
      ? isBusinessDeveloper
      : otherprops?.requestStatus?.split(".")[1];

    switch (result) {
      case STATUS_NEW_CD:
      case STATUS_NEW_MB:
      case isBusinessDeveloper:
        if (isCreativeRequest) {
          createRequest({
            // isCreativeDirector,
            name: category?.name,
            ...commonProps,
            requestListrefetch: data_refetch,
            requestData: {
              ...requestDetails,
              categoryId: category?.productCategoryId,
              categoryName: category?.name,
              productId: category?.productId,
              ...propswithOutRequest,
            },
          });
        } else {
          createTemplate({
            ...commonProps,
            requestListrefetch: data_refetch,
            requestData: {
              ...requestDetails,
              ...propswithOutRequest,
              categoryId: category?.productCategoryId,
              categoryName: category?.name,
              productId: category?.productId,
            },
          });
        }
        break;

      default:
        const updatedTemplateUrls = requestDetails?.template_urls?.map((template) => {
          const {
            r_2_url,
            template_details,
            template_id,
            template_type,
            template_thumb = "",

            ...rest
          } = template;
          return {
            r2Url: r_2_url,
            templateDetails: template_details,
            templateId: template_id,
            templateType: template_type,
            templateThumb: template_thumb,
            ...rest,
          };
        });

        const updatedCreatives = requestDetails?.creatives?.map((creatives) => {
          const { r_2_url, current_thumb, ...rest } = creatives;
          const { r_2_url: url } = current_thumb || {};
          return { r2Url: r_2_url, current_thumb: { r2Url: url }, ...rest };
        });
        createRequestView({
          categoryId: category?.productCategoryId,
          requestListrefetch: data_refetch,
          categoryName: category?.name,
          productId: category?.productId,
          status: otherprops?.status,
          isCategory: true,
          ...commonProps,
          isCreativeRequest: isCreativeRequest,
          requestData: {
            ...requestDetails,
            categoryName: category?.name,
            productId: category?.productId,
            ...propswithOutRequest,
            templateUrls: updatedTemplateUrls,
            creatives: updatedCreatives,
            templateIds: propswithOutRequest?.templateIds?.map((template) => template?.id),
          },
        });
    }
  };
  // const [stepsScreen, setStepsScreen] = useState([
  //   { title: "RequestDetails" },
  //   { title: "Moodboard" },
  //   { title: "Creative Review" },
  // ]);
  const [requestDelete, { loading: deleteLoader }] = useCustomMutation(DELETE_CREATIVE_REQUESTS, {
    onCompleted(e) {
      const element = e?.ctDeleteRequests;
      setDeleteModalVisible(false);
      if (element.status === "SUCCESS") {
        data_refetch();

        setDeleteModalPropsData();
        openNotification({ type: "success", message: element.message });
      } else {
        openNotification({
          type: "error",
          message: "Record Not Deleted",
          description: element.message,
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Not Deleted",
        description: e.ctDeleteRequests.message,
      });
    },
  });

  const templateColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Variations",
      dataIndex: "variations",
      key: "variations",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
  ];
  const [updateRequest, { loading }] = useCustomMutation(CREATIVE_REQUEST_UPDATE_STATUS, {
    onCompleted(e) {
      const element = e?.ctBulkChangeStatus;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || RECORD_UPDATED,
          key: "success",
        });
        onCloseStatusModal();
        requestListrefetch();
      } else {
        openNotification({ type: "error", message: element?.message, key: "update-req-error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });
  const creativesColumns = [
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
    ...(productList?.[0]?.id === PRODUCT_ID_LEADGEN
      ? [
          {
            title: "Offer",
            dataIndex: "offer",
            key: "offer",
            render: (value) => <LookTableColumnRender title={value} />,
            width: 120,
            minWidth: 120,
          },
        ]
      : []),
  ];
  const initialColumns = [
    {
      title: "Request ID",
      dataIndex: "requestId",
      key: "requestId",
      render: (value, otherprops) => {
        return (
          <LookTableColumnRender
            title={value}
            isClick={() => {
              handleDisplayRequest(otherprops);
            }}
          />
        );
      },
      width: 120,
      minWidth: 120,
      fixed: "left",
      searchType: "number",
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value, otherProps) => <LookTableColumnRender title={value?.name} />,
      width: 120,
      minWidth: 120,
      searchType: "string",
    },
    ...(isCreativeRequest ? creativesColumns : templateColumns),

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => {
        let className = "";
        switch (value) {
          case "New":
            className = "text-blue";
            break;
          case "Assigned":
            className = "text-primary";
            break;
          case "Needs Changes":
            className = "text-danger";
            break;
          case "For approval":
            className = "text-gray-900";
            break;
          default:
            className = "";
        }
        return <LookTableColumnRender title={value} className={className} />;
      },
      width: 120,
      minWidth: 120,
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "assignTo",
      render: (value, otherProps) => <LookTableColumnRender title={otherProps?.assignTo?.name} />,
      width: 120,
      minWidth: 120,
    },

    {
      title: "Request Compliant",
      dataIndex: "requestCompliant",
      key: "requestCompliant",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={otherProps?.requestDetails?.compliance ? "Yes" : "No"}
          className={otherProps?.requestDetails?.compliance ? "text-primary" : "text-danger"}
        />
      ),
      width: 120,
      minWidth: 120,
    },

    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   nofilter: true,
    //   render: (action, actionProps) => {
    //     const poverWrapperOptions = [
    //       {
    //         option: "edit",
    //         title: "Edit",
    //         optionProps: {
    //           onClick: () => showDrawerUpdate(actionProps),
    //         },
    //       },
    //     ];
    //     return (
    //       <span className="d-table pointer">
    //         <PopoverWrapper options={poverWrapperOptions}>
    //           <EllipsisOutlined className="pop-info" />
    //         </PopoverWrapper>
    //       </span>
    //     );
    //   },
    //   fixed: "right",
    //   width: 100,
    // },
  ];

  useEffect(() => {
    creativesRequestFetch({
      variables: {
        createdByMe: createdByMe !== "All",
        endDate: dateRangeTimezone?.rangeDates[1],
        startDate: dateRangeTimezone?.rangeDates[0],
        requestType: actionType,
      },
    });
  }, [createdByMe, dateRangeTimezone]);

  const finalData = requestListData?.ctGetCreativeRequests?.data?.map((ele) => ({
    ...ele,
    key: ele?.id,
    createdDate: ele?.createdDate?.split(" ")?.[0],
    deadline:
      ele?.expectedDueDate !== null
        ? ele?.expectedDueDate?.split(" ")?.[0]
        : "Deadline has not been set",
    requestId: ele?.id,
    categoryId: ele?.category?.productCategoryId,
    productId: ele?.category?.productId,
    status: handleStatus(userLevel, ele?.requestStatus?.split(".")?.[1]),
    priority: ele?.priority?.split(".")?.[1],
  }));

  const finalFilterData = filterData(finalData)?.filter(({ requestId, categoryId }) => {
    const searchValue = search?.toLowerCase()?.trim() || "";
    const searchCheck =
      requestId?.toLowerCase()?.includes(searchValue) ||
      categoryId?.toLowerCase()?.includes(searchValue);
    if (searchValue ? searchCheck : true) {
      return true;
    }
    return false;
  });

  const tableMainProps = {
    isSearchFilter: true,
    tableProps: {
      initialColumns,
      recordData: finalFilterData,
    },
  };
  const onChange = (checked) => {
    setCreatedByMe(checked);
  };

  const bulkActions = [
    { option: "delete", title: "Delete", onClick: (e) => onOpenDeleteModal(e) },
    { option: "edit", title: "Status Update", onClick: (e) => onOpenStatusModal(e) },
  ];
  const rightOptions = (
    <>
      <Segmented
        className="custom-segmented"
        options={["All", "Raised by me"]}
        value={createdByMe}
        onChange={onChange}
      />
    </>
  );
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };
  const statusModalProps = {
    visible: statusModalVisible,
    onSubmit: onSubmitStatusModal,
    onCancel: onCloseStatusModal,
    loader: loading,
    okokText: "Submit",
  };

  return (
    <div>
      {isCreativeRequest ? createRequestForm() : createTemplateForm()}
      {/* {createRequestForm()} */}
      {createRequestFormView()}
      <LookModal {...statusModalProps}>
        {statusModalVisible && (
          <RequestStatusUpdate
            ctGetRequestStatusConfig={ctGetRequestStatusConfig}
            userLevel={userLevel}
            updatedData={updatedData}
            setUpdatedData={setUpdatedData}
            requestListrefetch={requestListrefetch}
          />
        )}
      </LookModal>
      <LookTableRecordDeleteModal {...deleteModalProps} />
      <LookTable
        loading={data_loading}
        {...tableMainProps}
        refetch={data_refetch}
        rightOptions={rightOptions}
        isDateRange
        bulkActions={bulkActions}
      />
    </div>
  );
}
export default RequestList;
