import { PieChartOutlined } from "@ant-design/icons";
// import { AdIcno } from "../../../components/svgComponents";

const dashboardPath = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: PieChartOutlined,
    subMenu: [
      {
        subMenuName: "Dashboard",
        subMenuLink: "/",
        subMenuIcon: PieChartOutlined,
      },
      // {
      //   subMenuName: "Scrape Ad",
      //   subMenuLink: "/scrape-ad-list",
      //   subMenuIcon: AdIcno,
      // },
      // {
      //   subMenuName: "Create Ad From Scrape Ad",
      //   subMenuLink: "/scrape-ad-create",
      //   subMenuIcon: AdIcno,
      //   hidden: true,
      // },
      // {
      //   subMenuName: "Agencies",
      //   subMenuLink: "/agencies",
      //   subMenuIcon: ShopOutlined,
      // },

      // {
      //   subMenuName: "Pages",
      //   subMenuLink: "/pages",
      //   subMenuIcon: FileDoneOutlined,
      // },
      // {
      //   subMenuName: "Help & Contact",
      //   subMenuLink: "/help-contact",
      //   subMenuIcon: InfoCircleOutlined,
      // },
    ],
  },
];

export default dashboardPath;
