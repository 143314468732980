import { Button, Col, Row, Select } from "antd";
import SelectInput from "components/forms/SelectInput";
import { useCustomMutation } from "hooks/apolloClientHooks";
import React from "react";
import { ADD_CT_TAGS } from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";

export default function TagModal({
  files,
  variables,
  creatives_refetch = () => {},
  setTags,
  tags = [],
  closeTagModal,
  selectedRowsClear,
  creativeType,
  tagsData = [],
  setTagsData = () => {},
}) {
  const [ctAddTags, { loading: loading_add_tag }] = useCustomMutation(ADD_CT_TAGS, {
    // variables: variables,
    onCompleted: (e) => {
      const element = e?.ctAddTags;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        creatives_refetch();
        selectedRowsClear();
        closeTagModal();
      } else if (element?.status === "FAILURE") {
        // selectedRowsClear();
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  function handleSaveTag() {
    ctAddTags({
      variables: {
        creativeIds: files?.map((files) => files?.id),
        creativeType,
        tags: [...tags, ...tagsData],
      },
    });
  }
  return (
    <div style={{ height: "30vh" }}>
      <Row gutter={24}>
        <Col span={24}>
          <b>Add Tags</b>
          <SelectInput
            className="mt-14 mb-2"
            mode="tags"
            placeholder="Type here to add new tags"
            value={tags}
            onChange={(e) => {
              setTags(e);
            }}
          />
          <Select
            className="mb-14"
            style={{ marginLeft: "-5px" }}
            mode="tags"
            showArrow={false}
            bordered={false}
            value={tagsData}
            onChange={(event) => {
              setTagsData(event);
            }}
          />
        </Col>
      </Row>

      <div className="bottom-buttons">
        <Row gutter={24} style={{ width: "100%" }}>
          <Col span={12}>
            <Button block className="submit-btn" onClick={() => closeTagModal()}>
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSaveTag}
              loading={loading_add_tag}
              // loading={loading_delete_tag || loading_add_tag || disabled}
            >
              Save
              {/* {loading_delete_tag || loading_add_tag || disabled ? <Loader /> : "Save"} */}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}
