import { useState } from "react";
import useCmsAddBanner from "../useCmsAddBanner";
import { openNotification } from "utils";
import LookButton from "components/LookButton";
import { Col, Row } from "antd";

export default function AddBanner(props) {
  const { type, typeId, onClose, setBannerImages = () => {} } = props;

  const [bannerImagesLocal, setBannerImagesLocal] = useState([{ image: "", lander_link: "" }]);

  const { bannerAddView, addBanners, loading_addCmsBanner } = useCmsAddBanner({
    type,
    bannerImages: bannerImagesLocal,
    setBannerImages: setBannerImagesLocal,
  });

  const submit = () => {
    addBanners(typeId, bannerImagesLocal, {
      onCompleted: (e) => {
        const element = e?.CMS_Create_Banner;
        if (element) {
          const newData = element.map(({ id, image, lander_link }) => ({ id, image, lander_link }));

          setBannerImages((pre) => [...pre, ...newData]);
          onClose();
        }
      },
      onError(e) {
        openNotification({
          type: "error",
          message: "Banners not added",
          description: e?.message,
        });
      },
    });
  };

  return (
    <div className="cms-add-banner-drawer">
      {bannerAddView()}
      <div className="footer-content">
        <div className="flex-grow-1">
          <Row gutter={12}>
            <Col span={12}>
              <LookButton block onClick={onClose}>
                Cancel
              </LookButton>
            </Col>
            <Col span={12}>
              <LookButton block onClick={submit} type="primary" loading={loading_addCmsBanner}>
                Save Banners
              </LookButton>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
