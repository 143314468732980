import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
} from "actions/actionTypes";
import UserReport from "./UserReport";
import { useState } from "react";
import { FILTER_TYPE_CATEGORY } from "pages/dashboardPages/Creatives/creativesEnum";
import { CreativeReports } from "pages/dashboardPages/Creatives";
import LanderReport from "./LanderReport";

function GlobalReportDetailView(props) {
  const { visible = false, onClose = () => {}, category = {} } = props;

  const [platformType, setPlatformType] = useState("All");

  const globalReportTab = [
    {
      hoverTitle: "User Report",
      icon: () => <ActionIconRender iconType="user" />,
      component: (
        <UserReport
          {...props}
          setPlatformType={setPlatformType}
          platformType={platformType}
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
        />
      ),
      noSpace: true,
    },
    {
      hoverTitle: "Lander Report",
      icon: () => <ActionIconRender iconType="lineChart" />,
      component: <LanderReport {...props} persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2} />,
      noSpace: true,
    },
    {
      noSpace: true,
      hoverTitle: "Creative Reporting",
      icon: () => <ActionIconRender iconType="image" />,
      component: (
        <CreativeReports
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3}
          isGlobalReport
          filterData={[
            {
              filterType: FILTER_TYPE_CATEGORY,
              spendApikey: "category_id",
              revApikey: "categoryId",
              id: [category?.id],
            },
          ]}
        />
      ),
    },
  ];
  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={`Detailed Report - ${category?.name}`}
    >
      {visible && <LookDrawerMainSideBar tabContent={globalReportTab} />}
    </LookDrawer>
  );
}

export default GlobalReportDetailView;
