import { Button, Switch, Tooltip } from "antd";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

import LookModal from "components/LookModal";
import LookTable from "components/LookTable";

import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useState, useImperativeHandle } from "react";
import {
  GQL_RE_DELETERULE,
  GQL_RE_GETRULE,
  GQL_RE_MANUALRUN,
  GQL_RE_UPDATERULE,
  GQL_RE_UPDATERULESTATUS,
} from "shared/gql/gqlSchema/ruleGql";
import { openNotification } from "utils";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import UpdateRuleForm from "../UpdateRules";
import NewRuleForm from "../NewRules";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import moment from "moment";
import RuleLinkedItems from "../RuleLinkedItems";
import RuleActivity from "../RuleActivityLogs";
import SelectInput from "components/forms/SelectInput";
import { useSelector } from "react-redux";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_TIKTOK,
  REPORT_TYPE_CAMPAIGN,
  RULE_PLATFORM_FB,
  RULE_PLATFORM_TIKTOK,
  RULE_PLATFORM_VAROZI,
  RULE_STATUS_NOT_INQUEUE,
  RULE_STATUS_PAUSED,
} from "shared/SharedKeys";
import { dateTimeFormatRuleEngine } from "reducers/userSettings";

export default function ExistingRule(props) {
  const { productId, platform, userId } = props;
  const [data, setdata] = useState([]);
  const [ruleType, setruleType] = useState("");
  const [idtype, setIdtype] = useState("");
  const [update, setupdate] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [AssetIds, setAssetIds] = useState(null);
  const [DrawerVisible, setDrawerVisible] = useState(false);
  const [AssetType, setAssetType] = useState("all");
  const [actionType, setactionType] = useState("all");
  const [platformType, setplatformType] = useState("all");
  // const super_admin = useSelector((state) => state?.userReducer?.me?.lf_superadmin);
  const search = useSelector(
    (state) => state?.userSettings?.mainSearch?.[USER_SETTING_TABLE_PERSIST_KEY_MAIN]?.search
  );

  const [clgetRule, { loading: loading_clgetRule, refetch: clruleFetch }] = useCustomLazyQuery(
    GQL_RE_GETRULE,
    {
      variables: {
        // groupBy: groupBy,
        // reportType: reportType,
        platform:
          platform.length > 1
            ? "all"
            : platform[0]?.id === PLATFORM_ID_FACEBOOK
            ? RULE_PLATFORM_FB
            : platform[0]?.id === PLATFORM_ID_TIKTOK
            ? RULE_PLATFORM_TIKTOK
            : RULE_PLATFORM_VAROZI,
        product: productId,
      },
      onCompleted: (e) => {
        const element = e?.re_getRule;
        if (element) {
          let res = [];
          for (let i in element) {
            res.push({
              key: element[i].id,
              id: element[i].id,
              actions: element[i].action,
              period: element[i].cron_command,
              name: element[i].name,
              rules: element[i].all,
              timezone: element[i].timezone,
              report_timeline: element[i].report_timeline,
              message: element[i].message,
              ids: element[i].ids,
              status: element[i].status,
              category_name: element[i].category_name,
              exclusion_name: element[i].exclusion_name,
              is_orType: element[i].is_orType,
              budget: element[i].budget,
              budget_type: element[i].budget_type,
              reporting_days: element[i].reporting_days,
              lifespan: element[i].lifespan,
              nextOccurrence:
                element[i].nextOccurrence === null
                  ? null
                  : moment(element[i].nextOccurrence).format(dateTimeFormatRuleEngine),
              previousOccurrence:
                element[i].previousOccurrence === null
                  ? null
                  : moment(element[i].previousOccurrence).format(dateTimeFormatRuleEngine),
              processed_count: element[i].processed_count,
              error_count: element[i].error_count,
              previousStatus: element[i].previousStatus,
              platform: element[i].platform,
              product: element[i].productId,
              groupBy: element[i].groupBy,
              reportType: element[i].report_type,
              created_at:
                element[i].created_at === null
                  ? null
                  : moment(element[i].created_at).format(dateTimeFormatRuleEngine),
              comparision: element[i].comparision,
              last_x_days: element[i].last_x_days,
              is_pulse: element[i].is_pulse,
              country: element[i].country,
              ad_account_ids: element[i].ad_account_ids,
              exclude_pulse: element[i].exclude_pulse,
              bid: element[i].bid,
            });
          }
          // console.log(res);
          setdata(res);
        }
      },
      onError(e) {
        setdata([]);
      },
    }
  );
  useEffect(() => {
    clgetRule();
  }, []);
  const finalDataWithGbyCheck =
    AssetType !== "all" ? data.filter(({ groupBy }) => groupBy === AssetType) : data;
  const finaDataWithActionCheck =
    actionType !== "all"
      ? finalDataWithGbyCheck.filter(({ actions }) => actions === actionType)
      : finalDataWithGbyCheck;
  const finalDatawithPlatformCheck =
    platform.length > 0 && platformType !== "all"
      ? finaDataWithActionCheck.filter(({ platform }) => platform === platformType)
      : finaDataWithActionCheck;

  const finaldatabysearch = search
    ? finalDatawithPlatformCheck.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
    : finalDatawithPlatformCheck;

  const [cldeleteRule, { loading: loading_DeleteGroups }] = useCustomMutation(GQL_RE_DELETERULE, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_deleteRule;
      if (element) {
        openNotification({ type: "success", message: "Rule deleted Successfully" });
        clgetRule();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [clupdateRuleStatus, { loading: loading_clupdateRuleStatus }] = useCustomMutation(
    GQL_RE_UPDATERULESTATUS,
    {
      variables: {},
      onCompleted: (e) => {
        const element = e?.re_updateRuleStatus;
        if (element) {
          openNotification({ type: "success", message: "Rule status updated Successfully" });
          clgetRule();
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [clmanualRunRule, { loading: manualrun_loading }] = useCustomLazyQuery(GQL_RE_MANUALRUN, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_updateRuleStatus;
      if (element) {
        openNotification({ type: "success", message: e?.message });
        clruleFetch();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const handleManualRun = (otherProps) => {
    clmanualRunRule({
      variables: {
        id: otherProps?.id,
        name: otherProps?.name,
      },
    });
  };
  const handleStatus = (e, otherProps) => {
    if (e) {
      clupdateRuleStatus({
        variables: {
          id: otherProps?.id,
          status: "NOTinqueue",
          groupBy: otherProps?.groupBy,
          rule_paused: false,
        },
      });
    } else {
      clupdateRuleStatus({
        variables: {
          id: otherProps?.id,
          status: "paused",
          groupBy: otherProps?.groupBy,
          rule_paused: true,
        },
      });
    }
  };

  const onOpenDeleteModal = (rowProps, rule_ids) => {
    rule_ids ? setDeleteModalPropsData(rule_ids) : setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    deleteModalPropsData.length > 0
      ? cldeleteRule({
          variables: {
            id: deleteModalPropsData[0],
            groupBy: REPORT_TYPE_CAMPAIGN,
            rule_ids: deleteModalPropsData,
          },
        })
      : cldeleteRule({
          variables: {
            id: deleteModalPropsData?.id,
            groupBy: deleteModalPropsData?.groupBy,
          },
        });
    setDeleteModalVisible(false);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  function showFilterSearch(data) {
    if (data) {
      setDrawerVisible(true);
      setruleType("add");
      setupdate(data);
    } else {
      setDrawerVisible(true);
      setruleType("add");
    }
  }
  function showIncFilterSearch() {
    setDrawerVisible(true);
    setruleType("add");
    setIdtype("inc");
  }
  function showExcFilterSearch() {
    setDrawerVisible(true);
    setruleType("add");
    setIdtype("exc");
  }

  function updateFilterSearch(data) {
    setDrawerVisible(true);
    setruleType("update");
    setupdate(data);
  }
  function onClose() {
    setDrawerVisible(false);
    setupdate(false);
  }

  const drawerProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 800,
    visible: DrawerVisible,
    onClose,
  };
  const initialColumns = [
    {
      title: "Rule name",
      dataIndex: "name",
      key: "name",
      searchable: true,
      searchType: "string",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={value}
          isClick={() => {
            updateFilterSearch(otherProps);
          }}
        />
      ),
      width: 160,
      minWidth: 160,
    },
    // {
    //   title: "Rule ID",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 160,
    //   minWidth: 160,

    // },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      width: 160,
      minWidth: 160,
      // render: (value, otherProps) => <LookTableColumnRender title={otherProps?.action} />,
    },
    {
      title: "Last Activity On",
      dataIndex: "previousOccurrence",
      key: "previousOccurrence",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Next Activity On",
      dataIndex: "nextOccurrence",
      key: "nextOccurrence",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Rule Action",
      dataIndex: "actions",
      key: "actions",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={
            value === "ACTIVE" || value === "ENABLE"
              ? "Turn On"
              : value === "PAUSED" || value === "DISABLE"
              ? "Turn Off"
              : value
          }
        />
      ),
      width: 90,
      minWidth: 160,
    },
    {
      title: "Previous Status",
      dataIndex: "previousStatus",
      key: "previousStatus",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Apply At",
      dataIndex: "groupBy",
      key: "groupBy",
      // searchable: true,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Actions",
      dataIndex: "action",
      nofilter: true,
      render: (value, otherProps) => {
        return (
          <>
            <Tooltip title="Copy Rule">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="copy" />}
                onClick={
                  () => showFilterSearch(otherProps)
                  // deleteRule(otherProps.name)
                }
              />
            </Tooltip>
            <Tooltip title="Manual Run">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="play" />}
                disabled={manualrun_loading}
                onClick={() => handleManualRun(otherProps)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="delete" />}
                onClick={
                  () => onOpenDeleteModal(otherProps)
                  // deleteRule(otherProps.name)
                }
              />
            </Tooltip>

            <Switch
              className="mr-8"
              style={{ backgroundColor: "#141414" }}
              checked={otherProps.status !== "paused"}
              onChange={(e) => handleStatus(e, otherProps)}
            />
          </>
        );
      },
      fixed: "right",
      width: 160,
    },
  ];
  const rightOptions = (
    <>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Status">
          <span>
            <SelectInput
              search
              name="status"
              className="mb-0"
              placeholder="Select status"
              value={AssetType}
              options={[
                { name: "All Assets", value: "all" },
                { name: "Campaign", value: "campaign" },
                { name: "Adset", value: "adset" },
                { name: "Ad", value: "ad" },
              ]}
              onChange={setAssetType}
            />
          </span>
        </Tooltip>
      </div>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Status">
          <span>
            <SelectInput
              search
              name="actions"
              className="mb-0"
              placeholder="Select status"
              value={actionType}
              options={[
                { name: "All Actions", value: "all" },
                {
                  name: "Turn On",
                  value: platformType === RULE_PLATFORM_TIKTOK ? "ENABLE" : "ACTIVE",
                },
                {
                  name: "Turn Off",
                  value: platformType === RULE_PLATFORM_TIKTOK ? "DISABLE" : "PAUSED",
                },
                { name: "Increase Budget", value: "INCREASE" },
                { name: "Decrease Budget", value: "DECREASE" },
              ]}
              onChange={setactionType}
            />
          </span>
        </Tooltip>
      </div>
      {platform.length > 1 && (
        <div className="mr-12" style={{ width: 150 }}>
          <Tooltip title="Platform">
            <span>
              <SelectInput
                search
                name="platform"
                className="mb-0"
                placeholder="Select Platform"
                value={platformType}
                options={[
                  { name: "All Platforms", value: "all" },
                  { name: "Facebook", value: "fb" },
                  { name: "Tiktok", value: "tiktok" },
                ]}
                onChange={setplatformType}
              />
            </span>
          </Tooltip>
        </div>
      )}
    </>
  );
  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => {
        const rule_ids = e.map((item) => item.id);
        clupdateRuleStatus({
          variables: {
            id: rule_ids[0],
            status: RULE_STATUS_NOT_INQUEUE,
            groupBy: REPORT_TYPE_CAMPAIGN,
            rule_paused: false,
            rule_ids: rule_ids,
          },
        });
      },
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => {
        const rule_ids = e.map((item) => item.id);
        clupdateRuleStatus({
          variables: {
            id: rule_ids[0],
            status: RULE_STATUS_PAUSED,
            groupBy: REPORT_TYPE_CAMPAIGN,
            rule_paused: true,
            rule_ids: rule_ids,
          },
        });
      },
    },
    {
      option: "delete",
      title: "Delete Rule",
      onClick: (e) => {
        const rule_ids = e.map((item) => item.id);
        onOpenDeleteModal(e, rule_ids);
      },
      // deleteRule(otherProps.name)
    },
  ];
  const tableProps = {
    isRowSelection: true,
    tableProps: { initialColumns, recordData: finaldatabysearch },
    rightOptions,
    bulkActions,
    isSearchFilter: true,
    loading: loading_clgetRule,
  };
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_DeleteGroups,
  };

  return (
    <>
      <DashboardHeader
        title="Rules"
        createBtn
        createBtnText="Add New Rule"
        createBtnType="add"
        // createBtnIconType=""
        createBtnHandler={showFilterSearch}
      />
      <LookTable {...tableProps} refetch={clruleFetch} />
      <LookTableRecordDeleteModal {...deleteModalProps} />
      {/* <div style={{ marginTop: "20px" }}>
        {ids == null ? (
          <Button key="submit" className="mr-6" type="primary" onClick={showFilterSearch}>
            Add New Rule
          </Button>
        ) : (
          <div>
            <Button key="submit" className="mr-6" type="primary" onClick={showIncFilterSearch}>
              Add Inclusion Filter Rule
            </Button>
            <Button key="submit" className="mr-6" type="primary" onClick={showExcFilterSearch}>
              Add Exclusion Filter Rule
            </Button>
          </div>
        )}
      </div> */}
      <div>
        <LookDrawer {...drawerProps}>
          {DrawerVisible &&
            (update && ruleType === "update" ? (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add/Update",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <UpdateRuleForm
                        close={() => onClose()}
                        data={update}
                        refetch={clruleFetch}
                        platform={platform}
                        productId={productId}
                      />
                    ),
                  },
                  // {
                  //   hoverTitle: "Rule Linked Items",
                  //   icon: () => <ActionIconRender iconType="ordered-list" />,
                  //   component: <RuleLinkedItems close={() => onClose()} ids={ids} />,
                  // },
                  {
                    hoverTitle: "Rule Linked Activity",
                    icon: () => <ActionIconRender iconType="notification" />,
                    component: <RuleActivity close={() => onClose()} rule_id={update?.id} />,
                  },
                ]}
              />
            ) : (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add/Update",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <NewRuleForm
                        close={() => onClose()}
                        {...props}
                        refetch={clruleFetch}
                        ids={AssetIds}
                        platform={platform}
                        idtype={idtype}
                        copy={update}
                      />
                    ),
                  },
                  // {
                  //   hoverTitle: "Rule Linked Items",
                  //   icon: () => <ActionIconRender iconType="ordered-list" />,
                  //   component: <RuleLinkedItems close={() => onClose()} ids={ids} />,
                  // },
                ]}
              />
            ))}
        </LookDrawer>
      </div>
    </>
  );
}
