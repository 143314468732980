import { UserOutlined, TeamOutlined } from "@ant-design/icons";
import { CustomeIconWrapper } from "..";
import { Spy } from "../../../components/svgComponents";
import ActionIconRender from "components/ActionIconRender";

const industryInsightsPath = [
  {
    name: "Industry Insights",
    link: "/industryInsights",
    icon: () => (
      <CustomeIconWrapper>
        <Spy />
      </CustomeIconWrapper>
    ),
    subMenu: [
      {
        subMenuName: "Ads",
        subMenuLink: "/ads",
        subMenuIcon: TeamOutlined,
      },
      {
        subMenuName: "Domains",
        subMenuLink: "/domains",
        subMenuIcon: UserOutlined,
      },
      {
        subMenuName: "Pages",
        subMenuLink: "/pages",
        subMenuIcon: TeamOutlined,
      },
      {
        subMenuName: "Category",
        subMenuLink: "/category",
        subMenuIcon: UserOutlined,
      },
      {
        subMenuName: "Notifications",
        subMenuIcon: UserOutlined,
        isCollapsible: true,
        subMenus: [
          {
            subMenuName: "All Notifications",
            subMenuLink: "/all-notification",
            subMenuIcon: () => <ActionIconRender iconType="notification" />,
          },
          {
            subMenuName: "Settings",
            subMenuLink: "/notification-settings",
            subMenuIcon: () => <ActionIconRender iconType="setting" />,
          },
        ],
      },
    ],
  },
];

export default industryInsightsPath;
