import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { RC_AGENCY_PERMISSION, SAS_USER } from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const RecommendedRule = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "RecommendedRule" */ "pages/dashboardPages/RecommendedRule")
  )
);

const recommendedRuleRoutes = [
  route("/recommended-rules/", RecommendedRule, ROUTE_DASHBOARD, true, [
    RC_AGENCY_PERMISSION,
    SAS_USER,
  ]),
];

export default recommendedRuleRoutes;
