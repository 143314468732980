import { Form, Button, Alert, Row, Col, Switch } from "antd";
import { Formik, ErrorMessage, Field } from "formik";
import LookTextInput from "../../../../../components/forms/LookTextInput";
import SelectInput from "../../../../../components/forms/SelectInput";
import Loader from "../../../../../components/loader";
import CategoriesSelection from "../../../../../components/PostHandle/SubComponents/CategoriesSelection";
import FileHandle from "../../../../../components/CmsComponents/FileHandle";

export default function MainForm(props) {
  const {
    allCountries = [],
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
    type = "add",
    categoriesProsp: { categories, setCategories },
    imageProps: { image = "", setImage = () => {} },
    bannerView,
  } = props;

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
          <Form layout="vertical">
            {isError && <Alert message={isError} type="error" className="mb-10" />}
            <Row gutter={12}>
              <Col span={16}>
                <div>
                  <LookTextInput
                    formik
                    name="name"
                    label="Name"
                    className="look-form-input"
                    placeholder="Enter Name"
                  />
                  <LookTextInput
                    formik
                    type="textarea"
                    name="description"
                    label="Description"
                    className="look-form-input"
                    placeholder="Enter description"
                  />
                </div>
              </Col>

              <Col span={8}>
                <FileHandle image={image} setImage={setImage} />
              </Col>
              <Col span={24}>
                <LookTextInput formik name="website" label="Website" placeholder="Enter website" />
              </Col>
              <Col span={24}>
                <Form.Item label="Country" className="look-form-input">
                  {/* <Field
                    as={Select}
                    mode="multiple"
                    name="country_id"
                    className="w-100"
                    placeholder="Select Country"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => {
                      setFieldValue("country_id", e);
                    }}
                  >
                    {allCountries?.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="country_id"
                    component="div"
                    className="text-left error-text"
                  /> */}

                  <SelectInput
                    multiple
                    className="mb-0"
                    formik
                    search
                    name="country_id"
                    value={values.country_id}
                    options={allCountries?.map(({ id, name }) => ({ name: name, value: id }))}
                    onChange={setFieldValue}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div className="flex-between mb-20">
                  <p className="mb-0">is_private</p>
                  <Switch
                    checked={values.is_private}
                    onChange={(e) => setFieldValue("is_private", e)}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="flex-between mb-20">
                  <p className="mb-0">is_brand</p>
                  <Switch
                    disabled={type === "edit"}
                    checked={values.is_brand}
                    onChange={(e) => setFieldValue("is_brand", e)}
                  />
                </div>
              </Col>
              <Col span={24}>
                <CategoriesSelection selectedCategories={categories} onChange={setCategories} />
              </Col>
            </Row>
            {bannerView}

            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                className="submit-btn"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <Loader /> : btnText}
              </Button>
            </Form.Item>
            {/* <pre>{JSON.stringify({ values }, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
      {/* <pre>{JSON.stringify({ allCountries }, null, 2)}</pre> */}
    </>
  );
}
