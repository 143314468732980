import { Form, Button, Row, Col } from "antd";
import { Formik } from "formik";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";

export default function MainForm(props) {
  const { initialValues, validationSchema, onSubmit, btnText = "Add", loading = false } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
        <Form layout="vertical">
          <h3>Agency</h3>
          <Row gutter={12}>
            <Col span={12}>
              <LookTextInput
                formik
                name="name"
                label={
                  <>
                    Agency name <span className="text-danger">*</span>
                  </>
                }
                className="look-form-input"
                placeholder="Enter the name of your company"
              />
            </Col>
            <Col span={12}>
              <LookTextInput
                formik
                name="email"
                label={
                  <>
                    Email <span className="text-danger">*</span>
                  </>
                }
                className="look-form-input"
                placeholder="Enter the Email"
              />
            </Col>
            <Col span={24}>
              <LookTextInput
                formik
                name="contact_no"
                label={
                  <>
                    Contact No <span className="text-danger">*</span>
                  </>
                }
                className="look-form-input"
                placeholder="Enter the Contact No."
              />
            </Col>
          </Row>

          <h3>Workspace</h3>
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="title"
                label="Workspace name"
                className="look-form-input"
                placeholder="Enter the workspace name "
              />
            </Col>
            <Col span={24}>
              <LookTextInput
                formik
                type="textarea"
                name="description"
                label="Workspace Description"
                className="look-form-input"
                placeholder="Enter the Description"
              />
            </Col>
          </Row>
          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
