import {
  MILESTONE_PENDING,
  REPORT_SCALABLE,
  USER_SETTING_DEFAULT_FILTER,
  USER_SETTING_DEFAULT_FILTER_BOOLEAN,
} from "actions/actionTypes";
import { Button, Popover } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import cx from "cx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { dateFormat } from "reducers/userSettings";

function LookTableDefaultFilter({
  filterColumns = [],
  persistKey,
  isScalableFilter = false,
  initialColumns = [],
}) {
  const dispatch = useDispatch();
  const {
    defaultFilter,
    defaultFilterBoolean,
    reportScalable = false,
    milestonePending = false,
    // isForMe = false,
  } = useSelector((state) => state?.userSettings) || {};
  const { [persistKey]: previousFilterState = {} } = defaultFilter || {};
  const filterColumnsState = defaultFilter?.[persistKey]?.filterColumns || [];
  const defaultFilterBooleanState = defaultFilterBoolean?.[persistKey] || [];

  const { [persistKey]: previousBooleanFilterState = {} } = defaultFilterBoolean || {};
  const filterBooleanColumnsState = defaultFilterBoolean?.[persistKey] || [];

  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const todayDate = moment().tz(dateRangeTimezone?.timeZone?.value).format(dateFormat);
  const isBothDatesToday =
    todayDate === dateRangeTimezone?.rangeDates[0] &&
    todayDate === dateRangeTimezone?.rangeDates[1];
  const [allColumnsTitle, setAllColumnsTitle] = useState([]);

  const filterColumnTitle = () => {
    const fTitle = initialColumns?.map(({ title }) => (title === "E ROAS" ? "ROAS" : title));
    setAllColumnsTitle(fTitle);
  };

  useEffect(() => {
    filterColumnTitle();
  }, [initialColumns]);

  // function isTodayDate() {
  //   return todayDate ? true : false;
  // }

  // "filterColumns": [
  //   {
  //     "selectedColumn": "PROFIT",
  //     "searchType": "number",
  //     "numberFilterType": ">",
  //     "numberFilterValue": "100"
  //   },
  //   {
  //     "selectedColumn": "estimate_revenue",
  //     "searchType": "number",
  //     "numberFilterType": "range",
  //     "numberFilterRangeMin": "10",
  //     "numberFilterRangeMax": "50"
  //   }
  // ]

  // const [extraFilter, setExtraFilter] = useState();

  // const CPLfilterContent = () => {
  //   const optionBtnData = [
  //     { name: "Above $0.50", type: "CPL_ABOVE_50" },
  //     { name: "Under $0.50", type: "CPL_UNDER_50" },
  //     { name: "Under $0.30", type: "CPL_UNDER_30" },
  //     { name: "Under $0.15", type: "CPL_UNDER_15" },
  //   ];

  //   return (
  //     <div className="look-table-rowSizeBtn">
  //       {optionBtnData.map(({ name, type }, index) => (
  //         <Button
  //           key={"optionBtnData" + index}
  //           size="small"
  //           block
  //           // type={cx({ primary: tableRowSize === type })}
  //           onClick={() => setExtraFilter(type)}
  //         >
  //           {name}
  //           {extraFilter === type && <ActionIconRender iconType="check" />}
  //         </Button>
  //       ))}
  //     </div>
  //   );
  // };

  // const RPLfilterContent = () => {
  //   const optionBtnData = [
  //     { name: ">= $1", type: "RPL_GREATER_THAN_OR_EQUALS_1" },
  //     { name: ">= $0.70 and < $1", type: "RPL_GREATER_THAN_OR_EQUALS_70_AND_LESS_THAN_1" },
  //     { name: ">= $0.50 and < $0.70", type: "RPL_GREATER_THAN_OR_EQUALS_50_AND_LESS_THAN_70" },
  //     { name: ">= $0.30 and < $0.50", type: "RPL_GREATER_THAN_OR_EQUALS_30_AND_LESS_THAN_50" },
  //   ];

  //   return (
  //     <div className="look-table-rowSizeBtn">
  //       {optionBtnData.map(({ name, type }, index) => (
  //         <Button
  //           key={"optionBtnData" + index}
  //           size="small"
  //           block
  //           // type={cx({ primary: tableRowSize === type })}
  //           onClick={() => setExtraFilter(type)}
  //         >
  //           {name}
  //           {extraFilter === type && <ActionIconRender iconType="check" />}
  //         </Button>
  //       ))}
  //     </div>
  //   );
  // };

  function handleFilterClick(newObject) {
    let temp = JSON.parse(JSON.stringify(filterColumnsState));

    const {
      selectedColumn,
      searchType,
      numberFilterType,
      numberFilterRangeMin,
      numberFilterRangeMax,
      numberFilterValue,
      label,
    } = newObject;

    const isIndexExists = temp?.findIndex((item) => item.selectedColumn === selectedColumn);
    if (isIndexExists !== -1) {
      if (numberFilterType === "range") {
        if (
          temp[isIndexExists]?.numberFilterRangeMin === numberFilterRangeMin &&
          temp[isIndexExists]?.numberFilterRangeMax === numberFilterRangeMax &&
          temp[isIndexExists]?.label === label
        ) {
          temp = temp.filter((item, ind) => ind !== isIndexExists);
        } else {
          temp[isIndexExists] = { ...newObject }; // Agr min = max, uss index ka tempObject delete ho jayega
        }
      } else {
        if (
          temp[isIndexExists]?.numberFilterValue === numberFilterValue &&
          temp[isIndexExists]?.label === label
        ) {
          temp = temp.filter((item, ind) => ind !== isIndexExists);
        } else {
          temp[isIndexExists] = { ...newObject }; // Agr number ki value same hai, uss index ka tempObject delete ho jayega
        }
      }
    } else {
      temp = [...temp, newObject];
    }

    dispatch({
      type: USER_SETTING_DEFAULT_FILTER,
      payload: {
        ...defaultFilter,
        [persistKey]: {
          ...defaultFilter?.[persistKey],
          filterColumns: [...temp], //CONDITION WILL ADD WITH CHECK ALREADY ADDED COLUMN ( selectedColumn )
        },
      },
    });
  }

  function handleReportScalable() {
    dispatch({ type: REPORT_SCALABLE, payload: !reportScalable });
  }

  function handleMilestonePending() {
    dispatch({ type: MILESTONE_PENDING, payload: !milestonePending });
  }

  function renderFilterItem(column, filterOption) {
    return (
      <div className="look-table-rowSizeBtn">
        {filterOption.map((data, index) => {
          const {
            label = "",
            searchType = "number",
            filterType: numberFilterType,
            value: numberFilterValue,
            rangeMin: numberFilterRangeMin,
            rangeMax: numberFilterRangeMax,
          } = data;

          const isExists = filterColumnsState?.findIndex(
            (item) => item.label === label && item.selectedColumn === column
          );

          const commonFilterObject = {
            label,
            numberFilterType,
            searchType,
            selectedColumn: column,
          };

          const filterObject =
            numberFilterType === "range"
              ? {
                  numberFilterRangeMin,
                  numberFilterRangeMax,
                  ...commonFilterObject,
                }
              : numberFilterType === "includeSearch"
              ? {
                  selectedColumn: column,
                  label,
                  includeInputs: numberFilterValue,
                  searchType: "string",
                }
              : {
                  numberFilterValue,
                  ...commonFilterObject,
                };

          return (
            <Button
              key={"optionBtnData" + index}
              size="small"
              block
              // type={cx({ primary: tableRowSize === type })}
              onClick={() => handleFilterClick(filterObject)}
            >
              {label}
              {isExists !== -1 && <ActionIconRender iconType="check" />}
            </Button>
          );
        })}
      </div>
    );
  }

  const clearDefaultFilters = () => {
    dispatch({ type: USER_SETTING_DEFAULT_FILTER, payload: {} });
    dispatch({ type: REPORT_SCALABLE, payload: false });
    dispatch({ type: MILESTONE_PENDING, payload: false });
    dispatch({ type: USER_SETTING_DEFAULT_FILTER_BOOLEAN, payload: {} });
  };

  const handleBooleanFilter = (column) => {
    let temp = JSON.parse(JSON.stringify(filterBooleanColumnsState));
    const isExistsIndex = temp.findIndex((data) => data === column);

    if (isExistsIndex !== -1) {
      temp.splice(isExistsIndex, 1);
    } else {
      temp.push(column);
    }

    dispatch({
      type: USER_SETTING_DEFAULT_FILTER_BOOLEAN,
      payload: { ...defaultFilterBoolean, [persistKey]: [...temp] },
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex mr-10 p-5">
        <LookButton
          tooltipTitle="All"
          tooltipPlacement="topLeft"
          className={cx("default-filter-btn-all ml-8", {
            isActive:
              isBothDatesToday && isScalableFilter
                ? [...filterColumnsState, ...defaultFilterBooleanState]?.length === 0 &&
                  !reportScalable
                : [...filterColumnsState, ...defaultFilterBooleanState]?.length === 0,
          })}
          size="small"
          style={{ fontSize: "12px" }}
          onClick={clearDefaultFilters}
        >
          All
        </LookButton>

        {filterColumns?.map(({ label, column, type = "", filterOptions = [] }) => {
          const isExists = filterOptions?.some((item) =>
            filterColumnsState?.find(
              (element) => item?.label === element?.label && column === element?.selectedColumn
            )
          );

          if (type === "boolean") {
            const isisExistsColumn =
              filterBooleanColumnsState.findIndex((data) => data === column) !== -1;

            return (
              <LookButton
                // tooltipTitle="Scalable"
                tooltipPlacement="topLeft"
                className={cx("default-filter-btn ml-8", { isSelected: isisExistsColumn })}
                size="small"
                style={{ fontSize: "12px" }}
                onClick={() => handleBooleanFilter(column)}
              >
                {label}
              </LookButton>
            );
          }

          return (
            <>
              {allColumnsTitle.includes(label) && (
                <Popover
                  content={renderFilterItem(column, filterOptions)}
                  trigger="click"
                  placement="bottom"
                >
                  <LookButton
                    tooltipTitle={label}
                    tooltipPlacement="topLeft"
                    size="small"
                    className={cx("default-filter-btn ml-8", { isSelected: isExists })}
                  >
                    {label}
                  </LookButton>
                </Popover>
              )}
            </>
          );
        })}
        {isBothDatesToday && isScalableFilter && (
          <LookButton
            tooltipTitle="Scalable"
            tooltipPlacement="topLeft"
            className={cx("default-filter-btn-all ml-8", { isActive: reportScalable })}
            size="small"
            style={{ fontSize: "12px" }}
            onClick={handleReportScalable}
          >
            Scalable
          </LookButton>
        )}

        {/* {isMeFilter && (
        <LookButton
          tooltipTitle="Me"
          tooltipPlacement="topLeft"
          className={cx("default-filter-btn-all ml-8", { isActive: isForMe })}
          size="small"
          style={{ fontSize: "12px" }}
          onClick={handleIsMeFilter}
        >
          Me
        </LookButton>
      )} */}
      </div>
    </div>
  );
}

export default LookTableDefaultFilter;
