import { Breadcrumb, Drawer } from "antd";
import LookDrawerHeaderContent from "./LookDrawerHeaderContent";
import cx from "classnames";
import { useState } from "react";

export function useLookDrawer(props = {}) {
  const {
    title: defaultTitle,
    textBreadcrumb: defaultTextBreadcrumb,
    size = 700,
    onOpen = () => {},
    onClose = () => {},
    noBodySpace = false,
    className = "",
    maskClosable = true,
  } = props;

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const openDrawer = (e) => {
    setVisibleDrawer(true);
    onOpen(e);
  };
  const closeDrawer = (e) => {
    setVisibleDrawer(false);
    onClose(e);
  };

  const [title, setTitle] = useState(defaultTitle);
  const [textBreadcrumb, setTextBreadcrumb] = useState(defaultTextBreadcrumb);
  const islabelTitle = title ? { title } : {};
  const islabelTextBreadcrumb = textBreadcrumb ? { textBreadcrumb } : {};
  const drawerProps = {
    className,
    ...islabelTitle,
    ...islabelTextBreadcrumb,
    // icon: (e) => <ActionIconRender {...e} iconType="select" />,
    visible: visibleDrawer,
    onClose: closeDrawer,
    size,
    noBodySpace,
    maskClosable,
  };

  function drawer(children) {
    return <LookDrawer {...drawerProps}>{children}</LookDrawer>;
  }
  return { setTitle, setTextBreadcrumb, drawer, openDrawer, closeDrawer, visibleDrawer };
}

export default function LookDrawer(props) {
  const {
    visible = false,
    title,
    textBreadcrumb = [],
    icon,
    onClose = () => {},
    children,
    placement = "right",
    size = 1100,
    className = "",
    extraProps = "",
    maskClosable = true,
    closable = false,
    noBodySpace = false,
  } = props;

  const isTextBreadcrum = () => (
    <Breadcrumb>
      {textBreadcrumb?.map((text, index) => (
        <Breadcrumb.Item
          className={cx("breadcrumb-first", {
            "breadcrumb-last": index + 1 === textBreadcrumb?.length,
          })}
          key={index + text}
        >
          {text}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );

  return (
    <Drawer
      className={cx("look-drawer", className, { "no-body-space": noBodySpace })}
      title={
        <LookDrawerHeaderContent title={title || isTextBreadcrum()} icon={icon} onClose={onClose} />
      }
      placement={placement}
      maskClosable={maskClosable}
      onClose={onClose}
      closable={closable}
      visible={visible}
      width={size}
      extra={<LookDrawerHeaderContent extraContent={extraProps} />}
    >
      {children}
    </Drawer>
  );
}
