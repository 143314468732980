import { Form, Row, Col, Tooltip, Button } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookTextInput from "components/forms/LookTextInput";
import LookDragableOrderList from "components/LookDragableOrderList";
import { useCMSPostHandle } from "components/PostHandle/view/Context";
import { useState } from "react";

function RenderInput(props) {
  const { placeholder = "", setMainData = () => {} } = props;

  const [field, setField] = useState("");
  function handleField(e) {
    setField(e.target.value);
  }

  function handleMainData() {
    if (field.trim()) {
      setField("");
      setMainData((previous) => [field.trim(), ...previous]);
    }
  }

  function handleFieldSubmit(e) {
    if (e.key === "Enter") {
      handleMainData();
    }
  }

  return (
    <div className="d-flex">
      <LookTextInput
        className="flex-grow-1 mb-0"
        value={field}
        onChange={handleField}
        onKeyDown={handleFieldSubmit}
        placeholder={placeholder}
      />
      <Tooltip title="Add">
        <Button
          type="link"
          onClick={handleMainData}
          icon={<ActionIconRender iconType="plus-circle" />}
        />
      </Tooltip>
    </div>
  );
}

export default function SidesHandle() {
  const {
    productPros = [],
    setProductProps = () => {},
    productCons = [],
    setProductCons = () => {},
  } = useCMSPostHandle();

  const dragCommonProps = { theme: "bordered-box", isEditable: true, isRemove: true };

  const prosDragProps = { ...dragCommonProps, value: productPros, onChange: setProductProps };
  const consDragProps = { ...dragCommonProps, value: productCons, onChange: setProductCons };

  return (
    <>
      <div>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col span={12}>
              <div>
                <RenderInput placeholder="Pros" setMainData={setProductProps} />
                <LookDragableOrderList {...prosDragProps} />
              </div>
            </Col>
            <Col span={12}>
              <div>
                <RenderInput placeholder="Cons" setMainData={setProductCons} />
                <LookDragableOrderList {...consDragProps} />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
