import { Switch } from "antd";

export default function SwitchHandle(props) {
  const { title = "", value = false, onChange = () => {} } = props;
  return (
    <div>
      <p>
        {title}: <Switch size="small" checked={value} onChange={onChange} />
      </p>
    </div>
  );
}
