export const SAS_BUY_DOMAIN_TYPE_ALL = "ALL";
export const SAS_BUY_DOMAIN_TYPE_ERROR = "ERROR";
export const SAS_BUY_DOMAIN_TYPE_SYNCED = "SYNCED";

export const SAS_REPORT_TYPE_POSTBACK = "PostBack";
export const SAS_REPORT_TYPE_LIVE = "Live";
export const SAS_REPORT_TYPE_DAILY = "Daily";

export const SAS_REPORT_TYPE_GDN = "GDN";

export const SAS_REPORT_LEVEL_ADACCOUNT = "adAccount";
export const SAS_REPORT_LEVEL_AD = "ad";
export const SAS_REPORT_LEVEL_ADSET = "adset";
export const SAS_REPORT_LEVEL_CAMPAIGN = "campaign";

export const SAS_REPORT_GROUP_BY_TYPE_ADACCOUNT = "adAccountId";
export const SAS_REPORT_GROUP_BY_TYPE_AD = "adId";
export const SAS_REPORT_GROUP_BY_TYPE_ADSET = "adsetId";
export const SAS_REPORT_GROUP_BY_TYPE_CAMPAIGN = "campaignId";

export const SAS_REPORT_ASSET_TYPE_ADACCOUNT = "ADACCOUNT";
export const SAS_REPORT_ASSET_TYPE_AD = "AD";
export const SAS_REPORT_ASSET_TYPE_ADSET = "ADSET";
export const SAS_REPORT_ASSET_TYPE_CAMPAIGN = "CAMPAIGN";

export const SAS_ASSET_TYPE_DOMAIN = "DOMAIN";
export const SAS_ASSET_TYPE_KEYWORD_SET = "KEYWORD_SET";
export const SAS_ASSET_TYPE_KEYWORD = "KEYWORD";

export const SAS_DISCREPANCY_REPORT_TYPE_CAMPAIGN = "categoryId";
export const SAS_DISCREPANCY_REPORT_TYPE_DOMAINS = "domainId";

export const SAS_TIKTOK_REPORT_GROUP_BY_AD = "AD";
export const SAS_TIKTOK_REPORT_GROUP_BY_ADGROUP = "ADGROUP";
export const SAS_TIKTOK_REPORT_GROUP_BY_CAMPAIGN = "CAMPAIGN";

export const SAS_REPORT_STATUS_TYPE_ACTIVE = "ACTIVE";
export const SAS_REPORT_STATUS_TYPE_PAUSED = "PAUSED";
export const SAS_REPORT_STATUS_TYPE_CAMPAIGN_PAUSED = "CAMPAIGN_PAUSED";
export const SAS_REPORT_STATUS_TYPE_REJECTED = "REJECTED";
export const SAS_TIKTOK_STATUS_TYPE_ENABLE = "ENABLE";
export const SAS_TIKTOK_STATUS_TYPE_DISABLE = "DISABLE";

export const FACEBOOK_SPEND_PLATFORM_ID = 3;
export const TABOOLA_SPEND_PLATFORM_ID = 1;

export const SAS_FACEBOOK_PLATFORM_ID = 1;
export const SAS_TABOOLA_PLATFORM_ID = 2;
export const SAS_TIKTOK_PLATFORM_ID = 7;
export const SAS_TIKTOK_PRODUCT_ID = 3;
export const SAS_GOOGLE_DISPLAY = 3;

export const SAS_CHANNEL_TYPE_AGENCY = "agency";
export const SAS_CHANNEL_TYPE_USER = "user";
export const SAS_CHANNEL_TYPE_WEBSITE = "website";

//Languages
export const SAS_LANGUAGE_TYPE_ENGLISH = "1";
export const SAS_LANGUAGE_TYPE_SPANISH = "2";

/**
 * ? SAS_SCALING_TEAM_IDS
 * * If agency id === 1 (IdeaClan) and current workspace included in SAS_SCALING_TEAM_WORKSPACE_IDS matches
 * * Then these users can take action in SAS reports
 */

export const SAS_SCALING_TEAM_IDS = [
  "1",
  "4",
  "177",
  "59",
  "93",
  "258",
  "257",
  "169", //Siddhant Arora
  "186",
  "380",
  "243",
  "253", // Uttam Chandel
  "142",
  "377",
  "27",
  "107",
  "256", // Ajay bhandari
  "242", // Anshul Roach
  "298", // Suraj
  "230", // Satyam
  "209", // Raghav Sharma
  "50", // Parminder
  "98", // priyanka
  "356", // Ajay kumar verma
  "217",
  "255", // Ishwarjot
  "254", // Vijay Parihar
  "286", // Aditi Tyagi
  "216",
  "108",
  "252",
  "204"
];

/**
 * ? SAS_DATA_TEAM_IDS
 * * If agency id !== 1 (IdeaClan) than SAS report actions are hidden
 * * If agency id === 1 (IdeaClan) and current workspace included in SAS_SCALING_TEAM_WORKSPACE_IDS matches
 * * Then these users cannot take action in SAS reports
 */

export const SAS_DATA_TEAM_IDS = ["205", "209", "50", "98", "255", "286", "386"];
export const SAS_SCALING_TEAM_AGENCY_ID = 1;
export const SAS_SCALING_TEAM_WORKSPACE_IDS = [100, 133]; // These ID's is used for Scaling team ( Search Atbitrage / Search RSFC )

export const COMPLIANCE_STATUS_NO = "NO";
export const COMPLIANCE_STATUS_EXCEPTION = "EXCEPTION";
export const COMPLIANCE_STATUS_PENDING = "PENDING";

// tiktok clone enums
export const TIKTOK_CLONE_SCHEDULE_FROM_NOW = "SCHEDULE_FROM_NOW";
export const TIKTOK_CLONE_SCHEDULE_START_END = "SCHEDULE_START_END";
export const TIKTOK_BUDGET_MODE_TOTAL = "BUDGET_MODE_TOTAL";
export const TIKTOK_BUDGET_MODE_DAY = "BUDGET_MODE_DAY";
export const TIKTOK_BUDGET_MODE_DYNAMIC_DAILY_BUDGET = "BUDGET_MODE_DYNAMIC_DAILY_BUDGET";
export const TIKTOK_BUDGET_MODE_INFINITE = "BUDGET_MODE_INFINITE";

//filtered campaign name
export const FILTERED_CAMPAIGN_NAME = "bbb-";

// BBB users
export const SAS_BBB_TEAM_IDS = ["1", "108", "59", "4"];
