import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { Tooltip, Switch, Button } from "antd";
import { useSelector } from "react-redux";
import alasql from "alasql";
import DetailView from "./DetailView";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useUpdateFbAssetBudget, { UPDATE_UPDATE_NODE_CAMPAIGN } from "hooks/useUpdateFbAssetBudget";
import useTiktokSpendReportsQuery from "hooks/tiktokHooks/useTiktokSpendReportsQuery";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
} from "shared/SharedKeys";
import {
  useNewLeadgenReportsQuery,
  useTiktokActivePauseStatusUpdateHooks,
} from "hooks/tiktokHooks";
import {
  TIKTOK_UPDATE_STATUS_NODE_AD,
  TIKTOK_UPDATE_STATUS_NODE_ADGROUP,
  TIKTOK_UPDATE_STATUS_NODE_CAMPAIGN,
  TIKTOK_UPDATE_STATUS_TYPE_ACTIVE,
  TIKTOK_UPDATE_STATUS_TYPE_DISABLE,
} from "hooks/tiktokHooks/useTiktokActivePauseStatusUpdateHooks";
import useTiktokVoluumColumns from "../useTiktokVoluumColumns";
import UpdateBulkAdSetBudget from "./UpdateBulkAdSetBudget";
import LookModal from "components/LookModal";
import SelectInput from "components/forms/SelectInput";
import {
  SAS_TIKTOK_STATUS_TYPE_DISABLE,
  SAS_TIKTOK_STATUS_TYPE_ENABLE,
} from "pages/dashboardPages/SAS/sasKeysAndEnum";
import { defaultFilterColumns } from "./defaultFilterColumns";
import { useLookTableParentContext } from "hooks/lookTableParentContext";

export default function TiktokLeadgenSpendRevenueReport(props) {
  const {
    adBy,
    tiktokAssetId,
    revenueVariables = {},
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    reportType,
    alasqlQuery,
    isPerformance = false,
    outerDate = false,
    ruleSetting,
  } = props;

  const { renderUpdateBudgetModal } = useUpdateFbAssetBudget(UPDATE_UPDATE_NODE_CAMPAIGN);

  const [mergedReport, setMergedReport] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [rowPropsData, setRowProps] = useState({});
  const [visibleBudgetDrawer, setVisibleBudgetDrawer] = useState(false);
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const userSettings = useSelector((state) => state?.userSettings);

  const { dateRangeTimezone, mainSearchWithInclude, searchFilter } = userSettings || {};

  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);
  const { selectedRowsClear } = useLookTableParentContext();

  const { [persistKey]: searchFilterState } = searchFilter || {};

  const dateRangeParamsTiktok = {
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone.value,
  };

  const isTiktokAssetId = tiktokAssetId ? { assetId: tiktokAssetId } : {};

  const isAdBy = adBy ? { adBy } : {};

  const { loading_revenue, refetch_revenue, revenueReport } = useNewLeadgenReportsQuery(
    reportType,
    {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      platform_id: 7,
      variables: {
        ...revenueVariables,
      },
    }
  );

  const { getSpendReport, loading_spend, refetch_spend, spendReport, setSpendReport } =
    useTiktokSpendReportsQuery(reportType, {
      ...dateRangeParamsTiktok,
      variables: { ...isTiktokAssetId, ...isAdBy },
    });

  useEffect(() => {
    getSpendReport();
  }, [
    dateRangeTimezone?.rangeDates[0],
    dateRangeTimezone?.rangeDates[1],
    dateRangeTimezone?.timeZone.value,
  ]);

  const refetch = () => {
    refetch_revenue();
    refetch_spend();
  };

  const loading = loading_revenue || loading_spend;

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };
  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };

  const onCloseDetailDrawer = () => {
    // setRowProps({});
    setVisibleDetailDrawer(false);
  };

  const isClick = isPerformance ? { callDetailView: showDrawerUpdate } : {};

  let node = "";

  if (reportType === REPORT_TYPE_CAMPAIGN) node = TIKTOK_UPDATE_STATUS_NODE_CAMPAIGN;
  if (reportType === REPORT_TYPE_AD_ADSET) node = TIKTOK_UPDATE_STATUS_NODE_ADGROUP;
  if (reportType === REPORT_TYPE_AD) node = TIKTOK_UPDATE_STATUS_NODE_AD;

  const {
    callStatusUpdateApi,
    callStatusUpdateSubmit,
    loading: TIKTOK_UPDATE_STATUS_LOADING,
  } = useTiktokActivePauseStatusUpdateHooks(node, { setSpendReport });

  const { tiktokVoluumColumns } = useTiktokVoluumColumns({
    ...isClick,
    reportType,
    callStatusUpdateApi,
    node,
  });

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        const { status, budget, adsetId, campaignId, adId } = actionProps;
        let id = "",
          redirectType = "";
        if (reportType === REPORT_TYPE_CAMPAIGN) {
          id = campaignId;
          redirectType = "campaign";
        }
        if (reportType === REPORT_TYPE_AD_ADSET) {
          id = adsetId;
          redirectType = "adset";
        }
        if (reportType === REPORT_TYPE_AD) {
          id = adId;
          redirectType = "ads";
        }

        if (status) {
          if (
            status === TIKTOK_UPDATE_STATUS_TYPE_ACTIVE ||
            status === TIKTOK_UPDATE_STATUS_TYPE_DISABLE
          ) {
            const callStatus =
              status === TIKTOK_UPDATE_STATUS_TYPE_ACTIVE
                ? TIKTOK_UPDATE_STATUS_TYPE_DISABLE
                : TIKTOK_UPDATE_STATUS_TYPE_ACTIVE;
            return (
              <div className="action-column d-flex justify-content-around">
                {adsetId && budget && (
                  <div className="actions flex-center">
                    <Tooltip title="Budget">
                      <span>${budget}</span>
                    </Tooltip>
                  </div>
                )}
                <Tooltip placement="left" title="Update Status">
                  <Switch
                    disabled={TIKTOK_UPDATE_STATUS_LOADING}
                    size="small"
                    checked={status !== TIKTOK_UPDATE_STATUS_TYPE_DISABLE}
                    onClick={() => callStatusUpdateSubmit(callStatus, [actionProps])}
                  />
                </Tooltip>

                {adsetId &&
                  (budget ? (
                    <Tooltip title="Update Budget">
                      <Button
                        className="ml-12 p-0 h-auto w-auto"
                        type="link"
                        onClick={() => showBudgetDrawer(actionProps)}
                        icon={<ActionIconRender iconType="dollar" />}
                      />
                    </Tooltip>
                  ) : (
                    <Button
                      className="ml-12 p-0 h-auto w-auto"
                      type="link"
                      icon={<ActionIconRender iconType="dollar" />}
                      disabled
                    />
                  ))}
                {/* {id ? (
                  <Tooltip title="Edit">
                    <Button
                      className="ml-12 p-0 h-auto w-auto"
                      type="link"
                      icon={<ActionIconRender iconType="view" />}
                      onClick={() =>
                        window.open(`/sas/reports/tiktok-${redirectType}/redirect/${id}`)
                      }
                    />
                  </Tooltip>
                ) : (
                  <Button
                    className="ml-12 p-0 h-auto w-auto"
                    type="link"
                    icon={<ActionIconRender iconType="view" />}
                    disabled
                  />
                )} */}
              </div>
            );
          }
        }
      },
      fixed: "right",
      width: 120,
      minWidth: 120,
    },
  ];

  const initialColumns = [...tiktokVoluumColumns, ...action];

  let finalMerge = [];
  let finalMergeData = [];

  useEffect(() => {
    if (!loading) {
      alasql
        .promise(alasqlQuery, [spendReport, revenueReport])
        .then((res) => {
          setMergedReport(res);
        })
        .catch(function (err) {});
    }
  }, [loading, revenueReport, spendReport]);

  finalMerge = mergedReport;
  if (!loading) {
    finalMerge?.forEach((element, index) => {
      const {
        spend = 0,
        clicksTiktok = 0,
        impressionsTiktok = 0,
        revenue = 0,
        clicks = 0,
        conversions = 0,
        name = "",
        adaccount_name = "",
      } = element;

      const spendUpdate = spend.toFixed(2);
      const PROFIT = parseFloat(revenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CPC = parseFloat(spendUpdate) / parseFloat(clicks);
      const CPR_V = parseFloat(spendUpdate) / parseFloat(clicks);

      const CPV = parseFloat(spendUpdate) / parseFloat(clicksTiktok);

      const AD_CTR = (parseFloat(clicksTiktok) / parseFloat(impressionsTiktok)) * 100 || 0;
      const LP_CTR = (parseFloat(clicks) / parseFloat(clicksTiktok)) * 100;
      const E_CPC = parseFloat(CPC) / parseFloat(LP_CTR);
      const CR = (parseFloat(conversions) / parseFloat(clicks)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsTiktok)) * 1000;

      finalMergeData.push({
        ...element,
        key: index,
        impressionsTiktok,
        spend: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        CPV: valueCheck(CPV),
        CPC: valueCheck(CPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        CPR_V: valueCheck(CPR_V),
        E_CPC: valueCheck(E_CPC),
        adaccountName: name || adaccount_name,
      });
    });
  }

  const searchCampaignObject =
    REPORT_TYPE_CAMPAIGN === reportType ? ["campaignId", "campaign_name"] : [];
  const searchAdSetObject = REPORT_TYPE_AD_ADSET === reportType ? ["adsetId", "adgroup_name"] : [];
  const searchAdObject = REPORT_TYPE_AD === reportType ? ["adId", "ad_name"] : [];
  const searchAdAccountObject =
    REPORT_TYPE_AD_ACCOUNT === reportType ? ["adAccountId", "revAdAccountId", "name"] : [];

  const finalMergeDataWithStatusCheck =
    selectedStatus === SAS_TIKTOK_STATUS_TYPE_ENABLE
      ? finalMergeData.filter(
          ({ status }) => status !== SAS_TIKTOK_STATUS_TYPE_DISABLE && status != undefined
        )
      : selectedStatus === SAS_TIKTOK_STATUS_TYPE_DISABLE
      ? finalMergeData.filter(
          ({ status }) => status === SAS_TIKTOK_STATUS_TYPE_DISABLE && status != undefined
        )
      : finalMergeData;

  const finalFilterData = outerFilterData(filterData(finalMergeDataWithStatusCheck), {
    filterKeys: [
      ...searchAdSetObject,
      ...searchCampaignObject,
      ...searchAdObject,
      ...searchAdAccountObject,
    ],
  });

  // const finalFilterData = filterData(finalMergeData);

  let total_spend = 0,
    total_clicksTiktok = 0,
    total_impressionsTiktok = 0,
    total_revenue = 0,
    total_clicks = 0,
    total_conversions = 0,
    total_sessions = 0,
    total_leadsFB = 0;

  for (const {
    spend = 0,
    clicksTiktok = 0,
    impressionsTiktok = 0,
    revenue = 0,
    clicks = 0,
    leadsFB = 0,
    conversions = 0,
    sessions = 0,
  } of finalFilterData) {
    total_spend += parseFloat(spend || 0);
    total_clicksTiktok += parseFloat(clicksTiktok || 0);
    total_impressionsTiktok += parseFloat(impressionsTiktok || 0);
    total_revenue += parseFloat(revenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_leadsFB += parseFloat(leadsFB || 0);
    total_conversions += parseFloat(conversions || 0);
    total_sessions += parseFloat(sessions || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spend);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spend)) * 100 || 0;
  const total_CPV = parseFloat(total_spend) / parseFloat(total_clicksTiktok);
  const total_CPC = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_AD_CTR =
    (parseFloat(total_clicksTiktok) / parseFloat(total_impressionsTiktok)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_clicks) / parseFloat(total_clicksTiktok)) * 100;
  const total_CPM = (parseFloat(total_spend) / parseFloat(total_impressionsTiktok)) * 1000;
  const total_CPR_V = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_clicks)) * 100;
  const total_E_CPC = parseFloat(total_CPC) / parseFloat(total_LP_CTR);

  const showBudgetDrawer = (rowProps = {}) => {
    setRowProps(rowProps);
    setVisibleBudgetDrawer(true);
  };
  const onCloseDrawerBudget = () => {
    setRowProps({});
    setVisibleBudgetDrawer(false);
    selectedRowsClear();
  };

  const summaryData = {
    spend: valueCheck(total_spend, true),
    clicksTiktok: valueCheck(total_clicksTiktok, true),
    impressionsTiktok: valueCheck(total_impressionsTiktok),
    revenue: valueCheck(total_revenue, true),
    clicks: valueCheck(total_clicks, true),
    leadsFB: valueCheck(total_leadsFB),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    CPV: valueCheck(total_CPV, true),
    CPC: valueCheck(total_CPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    conversions: valueCheck(total_conversions),
    sessions: valueCheck(total_sessions),
    CPR_V: valueCheck(total_CPR_V, true),
    E_CPC: valueCheck(total_E_CPC, true),
  };

  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => callStatusUpdateSubmit(TIKTOK_UPDATE_STATUS_TYPE_ACTIVE, e),
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => callStatusUpdateSubmit(TIKTOK_UPDATE_STATUS_TYPE_DISABLE, e),
    },
  ];

  const rightOptions = (
    <>
      {reportType !== REPORT_TYPE_AD_ACCOUNT && (
        <div className="mr-12" style={{ width: 150 }}>
          <Tooltip title="Status">
            <span>
              <SelectInput
                search
                name="status"
                className="mb-0"
                placeholder="Select status"
                value={selectedStatus}
                options={[
                  { name: "All", value: "all" },
                  { name: "Enabled", value: "ENABLE" },
                  { name: "Disabled", value: "DISABLE" },
                ]}
                onChange={setSelectedStatus}
              />
            </span>
          </Tooltip>
        </div>
      )}
    </>
  );

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    defaultFilterColumns,
    // status: tableStatus,
    bulkActions,
    filterProps: { subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value" },
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
    uniqueKey: "tiktok-report",
    rightOptions,
    ruleSetting,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ searchFilterState }] },
    {
      isRow: true,
      contentObjects: [{ finalMergeData }, { finalMergeDataWithStatusCheck }, { finalFilterData }],
    },
    { isRow: true, contentObjects: [{ revenueReport }, { spendReport }] },
  ];

  const drawerPropsUpdate = {
    title: "Performance",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };
  const modalUpdateBudgetPropsUpdate = {
    title: "Update AdSet Budget",
    visible: visibleBudgetDrawer,
    onCancel: onCloseDrawerBudget,
    footer: false,
  };

  return (
    <div>
      {renderUpdateBudgetModal()}
      {visibleBudgetDrawer && reportType === REPORT_TYPE_AD_ADSET && (
        <LookModal {...modalUpdateBudgetPropsUpdate}>
          <UpdateBulkAdSetBudget {...rowPropsData} onCloseDrawerBudget={onCloseDrawerBudget} />
        </LookModal>
      )}

      <DetailView
        {...drawerPropsUpdate}
        {...rowPropsData}
        reportType={reportType}
        outerDate={outerDate}
      />
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
