import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import queryString from "query-string";
import { useRouteMatch, useHistory, useLocation, useParams } from "react-router-dom";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { GENERATE_INVITATION } from "shared/gql";
import { openNotification } from "utils";

export default function AcceptInvitation(props) {
  const [isError, setIsError] = useState("");
  const resetFormRef = useRef(() => {});

  const routeMatch = useRouteMatch();
  const histparsedory = useHistory();
  const location = useLocation();
  const params = useParams();

  const parsed = queryString.parse(props.location.search);

  const [GenerateInvitation, { loading: agencyLoading }] = useCustomMutation(GENERATE_INVITATION, {
    onCompleted(e) {
      console.log("razat CreateAgency success**********", e);
      // resettingEffect();

      resetFormRef.current();
      openNotification({ type: "success", message: "Success" });
    },
    onError(e) {
      console.log("razat CreateAgency error**********", e.message);
      openNotification({ type: "error", message: "Agency " + e.message });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { setSubmitting, resetForm }) {
    const {} = values;
    resetFormRef.current = resetForm;
    // const input = { ...values };

    // console.log("razat f360 in", { input });

    // GenerateInvitation({
    //   variables: {
    //     agency_id: parseInt(parsed.agencyId),
    //   },
    // });

    clearError();

    // setTimeout(() => setSubmitting(false), 3000);
  }

  const initialValues = {
    // code: "",
  };

  const validationSchema = yup.object({
    // code: yup.string().required().label("Invite Code"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    agencyLoading,
  };

  return (
    <>
      <pre>
        {JSON.stringify(
          {
            routeMatch,
            // history,
            location,
            parsed,
            params,
            // props,
          },
          null,
          2
        )}
      </pre>
      <MainFrom {...formProps} />
    </>
  );
}
