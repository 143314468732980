import { useLookModal } from "components/LookModal";
import StructuredSnippetsForm from "./StructuredSnippetsForm";
import LookButton from "components/LookButton";
import { useState } from "react";
import { removeObjectByIndex } from "utils";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";

export default function StructuredSnippets(props) {
  const { structuralSnippets, setStructuralSnippets } = props;

  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Create structured snippets",
    onClose: () => setEditIndex(-1),
    width: 416,
  });

  const [deleteIndex, setDeleteIndex] = useState(-1);

  const [editIndex, setEditIndex] = useState(-1);

  function addNewSnippets(data) {
    setStructuralSnippets([...structuralSnippets, data]);
  }

  function updateSnippets(index, data) {
    let temp = JSON.parse(JSON.stringify(structuralSnippets));
    temp[index] = data;
    setStructuralSnippets(temp);
  }

  const onDelete = (index) => {
    let temp = JSON.parse(JSON.stringify(structuralSnippets));
    temp = removeObjectByIndex(temp, index);
    setStructuralSnippets(temp);
  };
  const deleteModalProps = {
    visible: deleteIndex !== -1,
    onCancel: () => setDeleteIndex(-1),
    onSubmit: () => {
      onDelete(deleteIndex);
      setDeleteIndex(-1);
    },
  };

  const renderList = () => (
    <div className="main-list">
      {structuralSnippets?.map((data, index) => {
        const valuesString = data.values.join(" ,");
        return (
          <div className="list-item">
            <div className="input-wrapper">
              <p className="text">
                Featured {data?.header}: {valuesString}
              </p>
            </div>
            <div className="action-wrapper">
              <LookButton
                iconType="edit"
                onClick={() => {
                  openModal();
                  setEditIndex(index);
                }}
              />
              <LookButton iconType="delete" onClick={() => setDeleteIndex(index)} />
            </div>
          </div>
        );
      })}
    </div>
  );
  return (
    <div>
      <LookTableRecordDeleteModal {...deleteModalProps} />
      {modal(
        visibleModal && (
          <StructuredSnippetsForm
            addNew={addNewSnippets}
            update={updateSnippets}
            close={closeModal}
            {...{ structuralSnippets, editIndex, setEditIndex }}
          />
        )
      )}
      <h2 className="font-size-16">
        Structured snippets
        <span>({structuralSnippets?.length}/30)</span>
      </h2>
      <div className="bordered-input-listing-form">
        <LookButton
          block
          className="border-0 text-left creation-button"
          iconType="plus"
          onClick={openModal}
        >
          Click here to add a new snippet
        </LookButton>
        {renderList()}
      </div>
    </div>
  );
}
