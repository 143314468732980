import Loader from "components/loader";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function GoogleCreativeImagesUploadWrapper(props) {
  const { children } = props;
  const { adlibCountryFetch, sasLanguagesFetch } = useApiToStoreContext();

  useEffect(() => {
    adlibCountryFetch();
    sasLanguagesFetch();
  }, []);

  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);

  const {
    adlibCountry = [],
    adlibCountry_loading = false,
    sasLanguages = [],
    sasLanguages_loading = false,
  } = userSettingData || {};

  if (
    (adlibCountry?.legnth === 0 && adlibCountry_loading) ||
    (sasLanguages?.legnth === 0 && sasLanguages_loading)
  ) {
    return <Loader />;
  }

  return children;
}
