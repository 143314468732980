export const BILLING_EVENT_ENUM = [
  { name: "App Installs", value: "APP_INSTALLS" },
  { name: "Clicks", value: "CLICKS" },
  { name: "Impressions", value: "IMPRESSIONS" },
  { name: "Link Clicks", value: "LINK_CLICKS" },
  { name: "Listing Interaction", value: "LISTING_INTERACTION" },
  { name: "Offer Claims", value: "OFFER_CLAIMS" },
  { name: "Page Likes", value: "PAGE_LIKES" },
  { name: "Post Enagemant", value: "POST_ENGAGEMENT" },
  { name: "Purchase", value: "PURCHASE" },
  { name: "Thruplay", value: "THRUPLAY" },
];

export const OPTIMIZATION_GOAL_ENUM = [
  { name: "Ad recall lift", value: "AD_RECALL_LIFT" },
  { name: "App installs", value: "APP_INSTALLS" },
  { name: "App installs and offsite conversions", value: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS" },
  { name: "Conversations", value: "CONVERSATIONS" },
  { name: "Derived events", value: "DERIVED_EVENTS" },
  { name: "Engaged users", value: "ENGAGED_USERS" },
  { name: "Event responses", value: "EVENT_RESPONSES" },
  { name: "Impressions", value: "IMPRESSIONS" },
  { name: "Landing page views", value: "LANDING_PAGE_VIEWS" },
  { name: "Lead generation", value: "LEAD_GENERATION" },
  { name: "Link clicks", value: "LINK_CLICKS" },
  { name: "None", value: "NONE" },
  { name: "Offsite conversions", value: "OFFSITE_CONVERSIONS" },
  { name: "Page likes", value: "PAGE_LIKES" },
  { name: "Post engagement", value: "POST_ENGAGEMENT" },
  { name: "Quality call", value: "QUALITY_CALL" },
  { name: "Quality lead", value: "QUALITY_LEAD" },
  { name: "Reach", value: "REACH" },
  { name: "Thruplay", value: "THRUPLAY" },
  { name: "Value", value: "VALUE" },
  { name: "Visit instagram profile", value: "VISIT_INSTAGRAM_PROFILE" },
];

export const CUSTOM_EVENT_ENUM = [
  { name: "Achievement unlocked", value: "ACHIEVEMENT_UNLOCKED" },
  { name: "Add payment info", value: "ADD_PAYMENT_INFO" },
  { name: "Add to cart", value: "ADD_TO_CART" },
  { name: "Add to wishlist", value: "ADD_TO_WISHLIST" },
  { name: "Complete registration", value: "COMPLETE_REGISTRATION" },
  { name: "Contact", value: "CONTACT" },
  { name: "Content view", value: "CONTENT_VIEW" },
  { name: "Customize product", value: "CUSTOMIZE_PRODUCT" },
  { name: "D2 retention", value: "D2_RETENTION" },
  { name: "D7 retention", value: "D7_RETENTION" },
  { name: "Donate", value: "DONATE" },
  { name: "Find location", value: "FIND_LOCATION" },
  { name: "Initiated checkout", value: "INITIATED_CHECKOUT" },
  { name: "Lead", value: "LEAD" },
  { name: "Level achieved", value: "LEVEL_ACHIEVED" },
  { name: "Listing interaction", value: "LISTING_INTERACTION" },
  { name: "Messaging conversation started 7d", value: "MESSAGING_CONVERSATION_STARTED_7D" },
  { name: "Other", value: "OTHER" },
  { name: "Purchase", value: "PURCHASE" },
  { name: "Rate", value: "RATE" },
  { name: "Schedule", value: "SCHEDULE" },
  { name: "Search", value: "SEARCH" },
  { name: "Service booking request", value: "SERVICE_BOOKING_REQUEST" },
  { name: "Spent credits", value: "SPENT_CREDITS" },
  { name: "Start trial", value: "START_TRIAL" },
  { name: "Submit application", value: "SUBMIT_APPLICATION" },
  { name: "Subscribe", value: "SUBSCRIBE" },
  { name: "Tutorial completion", value: "TUTORIAL_COMPLETION" },
];

export const ASSET_STATUS_ENUM = [
  { name: "Active", value: "ACTIVE" },
  { name: "Archived", value: "ARCHIVED" },
  { name: "Deleted", value: "DELETED" },
  { name: "Paused", value: "PAUSED" },
];

export const CAMPAIGN_OBJECTIVE_ENUM = [
  { name: "App installs", value: "APP_INSTALLS" },
  { name: "Brand awareness", value: "BRAND_AWARENESS" },
  { name: "Conversions", value: "CONVERSIONS" },
  { name: "Event responses", value: "EVENT_RESPONSES" },
  { name: "Lead generation", value: "LEAD_GENERATION" },
  { name: "Link clicks", value: "LINK_CLICKS" },
  { name: "Local awareness", value: "LOCAL_AWARENESS" },
  { name: "Messages", value: "MESSAGES" },
  { name: "Offer claims", value: "OFFER_CLAIMS" },
  { name: "Page likes", value: "PAGE_LIKES" },
  { name: "Post engagement", value: "POST_ENGAGEMENT" },
  { name: "Product catalog sales", value: "PRODUCT_CATALOG_SALES" },
  { name: "Reach", value: "REACH" },
  { name: "Store visits", value: "STORE_VISITS" },
  { name: "Video views", value: "VIDEO_VIEWS" },
];

export const LAUNCH_CAMPAIGN_OBJECTIVE_SALES_ENUM = [
  "CONVERSIONS",
  "PRODUCT_CATALOG_SALES",
  "MESSAGES",
];

export const CALL_TO_ACTION_ENUM = [
  { name: "Add to cart", value: "ADD_TO_CART" },
  { name: "Apply now", value: "APPLY_NOW" },
  { name: "Contact us", value: "CONTACT_US" },
  { name: "Follow page", value: "FOLLOW_PAGE" },
  { name: "Follow user", value: "FOLLOW_USER" },
  { name: "Get offer", value: "GET_OFFER" },
  { name: "Sign up", value: "SIGN_UP" },
];

export const COUNTRY_GROUPS_ENUM = [
  { name: "Africa", value: "africa" },
  { name: "Afta", value: "afta" },
  { name: "Android app store", value: "android_app_store" },
  { name: "Android free store", value: "android_free_store" },
  { name: "Apec", value: "apec" },
  { name: "Asia", value: "asia" },
  { name: "Caribbean", value: "caribbean" },
  { name: "Central america", value: "central_america" },
  { name: "Cisfta", value: "cisfta" },
  { name: "Eea", value: "eea" },
  { name: "Emerging markets", value: "emerging_markets" },
  { name: "Europe", value: "europe" },
  { name: "Gcc", value: "gcc" },
  { name: "Itunes app store", value: "itunes_app_store" },
  { name: "Mercosur", value: "mercosur" },
  { name: "Nafta", value: "nafta" },
  { name: "North america", value: "north_america" },
  { name: "Oceania", value: "oceania" },
  { name: "South america", value: "south_america" },
  { name: "Worldwide", value: "worldwide" },
];

export const PUBLISHER_PLATFORMS_ENUM = [
  { name: "Audience network", value: "audience_network" },
  { name: "Facebook", value: "facebook" },
  { name: "Instagram", value: "instagram" },
  { name: "Messenger", value: "messenger" },
];

export const STRATEGY_TYPE_ENUM = [
  { name: "Abo", value: "abo" },
  { name: "Bbo", value: "cbo" },
  { name: "Testing", value: "testing" },
  { name: "Winning", value: "winning" },
];
export const LAUNCH_COUNTRYGROUPS_ENUM = [
  { name: "Africa", value: "africa" },
  { name: "Afta", value: "afta" },
  { name: "Android App Store", value: "android_app_store" },
  { name: "Android Free Store", value: "android_free_store" },
  { name: "Apec", value: "apec" },
  { name: "Asia", value: "asia" },
  { name: "Caribbean", value: "caribbean" },
  { name: "Central America", value: "central_america" },
  { name: "Cisfta", value: "cisfta" },
  { name: "Eea", value: "eea" },
  { name: "Emerging_markets", value: "emerging_markets" },
  { name: "Europe", value: "europe" },
  { name: "Gcc", value: "gcc" },
  { name: "Itunes App Store", value: "itunes_app_store" },
  { name: "Mercosur", value: "mercosur" },
  { name: "Nafta", value: "nafta" },
  { name: "North America", value: "north_america" },
  { name: "Oceania", value: "oceania" },
  { name: "South America", value: "south_america" },
  { name: "Worldwide", value: "worldwide" },
];

export const LAUNCH_SPECIAL_AD_CATEGORIES = [
  {
    name: "Credit",
    value: "CREDIT",
  },
  {
    name: "Employment",
    value: "EMPLOYMENT",
  },
  {
    name: "Housing",
    value: "HOUSING",
  },
  {
    name: "Issues Elections Politics",
    value: "ISSUES_ELECTIONS_POLITICS",
  },
  {
    name: "None",
    value: "NONE",
  },
  {
    name: "Online Gambling and Gaming",
    value: "ONLINE_GAMBLING_AND_GAMING",
  },
];
export const LAUNCH_COUNTRIES_ENUM = [
  { value: "AF", name: "Afghanistan" },
  { value: "AL", name: "Albania" },
  { value: "DZ", name: "Algeria" },
  { value: "AS", name: "American Samoa" },
  { value: "AD", name: "Andorra" },
  { value: "AO", name: "Angola" },
  { value: "AI", name: "Anguilla" },
  { value: "AQ", name: "Antarctica" },
  { value: "AG", name: "Antigua and Barbuda" },
  { value: "AR", name: "Argentina" },
  { value: "AM", name: "Armenia" },
  { value: "AW", name: "Aruba" },
  { value: "AU", name: "Australia" },
  { value: "AT", name: "Austria" },
  { value: "AZ", name: "Azerbaijan" },
  { value: "BS", name: "Bahamas (the)" },
  { value: "BH", name: "Bahrain" },
  { value: "BD", name: "Bangladesh" },
  { value: "BB", name: "Barbados" },
  { value: "BY", name: "Belarus" },
  { value: "BE", name: "Belgium" },
  { value: "BZ", name: "Belize" },
  { value: "BJ", name: "Benin" },
  { value: "BM", name: "Bermuda" },
  { value: "BT", name: "Bhutan" },
  { value: "BO", name: "Bolivia (Plurinational State of)" },
  { value: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { value: "BA", name: "Bosnia and Herzegovina" },
  { value: "BW", name: "Botswana" },
  { value: "BV", name: "Bouvet Island" },
  { value: "BR", name: "Brazil" },
  { value: "IO", name: "British Indian Ocean Territory (the)" },
  { value: "BN", name: "Brunei Darussalam" },
  { value: "BG", name: "Bulgaria" },
  { value: "BF", name: "Burkina Faso" },
  { value: "BI", name: "Burundi" },
  { value: "CV", name: "Cabo Verde" },
  { value: "KH", name: "Cambodia" },
  { value: "CM", name: "Cameroon" },
  { value: "CA", name: "Canada" },
  { value: "KY", name: "Cayman Islands (the)" },
  { value: "CF", name: "Central African Republic (the)" },
  { value: "TD", name: "Chad" },
  { value: "CL", name: "Chile" },
  { value: "CN", name: "China" },
  { value: "CX", name: "Christmas Island" },
  { value: "CC", name: "Cocos (Keeling) Islands (the)" },
  { value: "CO", name: "Colombia" },
  { value: "KM", name: "Comoros (the)" },
  { value: "CD", name: "Congo (the Democratic Republic of the)" },
  { value: "CG", name: "Congo (the)" },
  { value: "CK", name: "Cook Islands (the)" },
  { value: "CR", name: "Costa Rica" },
  { value: "HR", name: "Croatia" },
  { value: "CU", name: "Cuba" },
  { value: "CW", name: "Curaçao" },
  { value: "CY", name: "Cyprus" },
  { value: "CZ", name: "Czechia" },
  { value: "CI", name: "Côte d'Ivoire" },
  { value: "DK", name: "Denmark" },
  { value: "DJ", name: "Djibouti" },
  { value: "DM", name: "Dominica" },
  { value: "DO", name: "Dominican Republic (the)" },
  { value: "EC", name: "Ecuador" },
  { value: "EG", name: "Egypt" },
  { value: "SV", name: "El Salvador" },
  { value: "GQ", name: "Equatorial Guinea" },
  { value: "ER", name: "Eritrea" },
  { value: "EE", name: "Estonia" },
  { value: "SZ", name: "Eswatini" },
  { value: "ET", name: "Ethiopia" },
  { value: "FK", name: "Falkland Islands (the) [Malvinas]" },
  { value: "FO", name: "Faroe Islands (the)" },
  { value: "FJ", name: "Fiji" },
  { value: "FI", name: "Finland" },
  { value: "FR", name: "France" },
  { value: "GF", name: "French Guiana" },
  { value: "PF", name: "French Polynesia" },
  { value: "TF", name: "French Southern Territories (the)" },
  { value: "GA", name: "Gabon" },
  { value: "GM", name: "Gambia (the)" },
  { value: "GE", name: "Georgia" },
  { value: "DE", name: "Germany" },
  { value: "GH", name: "Ghana" },
  { value: "GI", name: "Gibraltar" },
  { value: "GR", name: "Greece" },
  { value: "GL", name: "Greenland" },
  { value: "GD", name: "Grenada" },
  { value: "GP", name: "Guadeloupe" },
  { value: "GU", name: "Guam" },
  { value: "GT", name: "Guatemala" },
  { value: "GG", name: "Guernsey" },
  { value: "GN", name: "Guinea" },
  { value: "GW", name: "Guinea-Bissau" },
  { value: "GY", name: "Guyana" },
  { value: "HT", name: "Haiti" },
  { value: "HM", name: "Heard Island and McDonald Islands" },
  { value: "VA", name: "Holy See (the)" },
  { value: "HN", name: "Honduras" },
  { value: "HK", name: "Hong Kong" },
  { value: "HU", name: "Hungary" },
  { value: "IS", name: "Iceland" },
  { value: "IN", name: "India" },
  { value: "ID", name: "Indonesia" },
  { value: "IR", name: "Iran (Islamic Republic of)" },
  { value: "IQ", name: "Iraq" },
  { value: "IE", name: "Ireland" },
  { value: "IM", name: "Isle of Man" },
  { value: "IL", name: "Israel" },
  { value: "IT", name: "Italy" },
  { value: "JM", name: "Jamaica" },
  { value: "JP", name: "Japan" },
  { value: "JE", name: "Jersey" },
  { value: "JO", name: "Jordan" },
  { value: "KZ", name: "Kazakhstan" },
  { value: "KE", name: "Kenya" },
  { value: "KI", name: "Kiribati" },
  { value: "KP", name: "Korea (the Democratic People's Republic of)" },
  { value: "KR", name: "Korea (the Republic of)" },
  { value: "KW", name: "Kuwait" },
  { value: "KG", name: "Kyrgyzstan" },
  { value: "LA", name: "Lao People's Democratic Republic (the)" },
  { value: "LV", name: "Latvia" },
  { value: "LB", name: "Lebanon" },
  { value: "LS", name: "Lesotho" },
  { value: "LR", name: "Liberia" },
  { value: "LY", name: "Libya" },
  { value: "LI", name: "Liechtenstein" },
  { value: "LT", name: "Lithuania" },
  { value: "LU", name: "Luxembourg" },
  { value: "MO", name: "Macao" },
  { value: "MG", name: "Madagascar" },
  { value: "MW", name: "Malawi" },
  { value: "MY", name: "Malaysia" },
  { value: "MV", name: "Maldives" },
  { value: "ML", name: "Mali" },
  { value: "MT", name: "Malta" },
  { value: "MH", name: "Marshall Islands (the)" },
  { value: "MQ", name: "Martinique" },
  { value: "MR", name: "Mauritania" },
  { value: "MU", name: "Mauritius" },
  { value: "YT", name: "Mayotte" },
  { value: "MX", name: "Mexico" },
  { value: "FM", name: "Micronesia (Federated States of)" },
  { value: "MD", name: "Moldova (the Republic of)" },
  { value: "MC", name: "Monaco" },
  { value: "MN", name: "Mongolia" },
  { value: "ME", name: "Montenegro" },
  { value: "MS", name: "Montserrat" },
  { value: "MA", name: "Morocco" },
  { value: "MZ", name: "Mozambique" },
  { value: "MM", name: "Myanmar" },
  { value: "NA", name: "Namibia" },
  { value: "NR", name: "Nauru" },
  { value: "NP", name: "Nepal" },
  { value: "NL", name: "Netherlands (the)" },
  { value: "NC", name: "New Caledonia" },
  { value: "NZ", name: "New Zealand" },
  { value: "NI", name: "Nicaragua" },
  { value: "NE", name: "Niger (the)" },
  { value: "NG", name: "Nigeria" },
  { value: "NU", name: "Niue" },
  { value: "NF", name: "Norfolk Island" },
  { value: "MP", name: "Northern Mariana Islands (the)" },
  { value: "NO", name: "Norway" },
  { value: "OM", name: "Oman" },
  { value: "PK", name: "Pakistan" },
  { value: "PW", name: "Palau" },
  { value: "PS", name: "Palestine, State of" },
  { value: "PA", name: "Panama" },
  { value: "PG", name: "Papua New Guinea" },
  { value: "PY", name: "Paraguay" },
  { value: "PE", name: "Peru" },
  { value: "PH", name: "Philippines (the)" },
  { value: "PN", name: "Pitcairn" },
  { value: "PL", name: "Poland" },
  { value: "PT", name: "Portugal" },
  { value: "PR", name: "Puerto Rico" },
  { value: "QA", name: "Qatar" },
  { value: "MK", name: "Republic of North Macedonia" },
  { value: "RO", name: "Romania" },
  { value: "RU", name: "Russian Federation (the)" },
  { value: "RW", name: "Rwanda" },
  { value: "RE", name: "Réunion" },
  { value: "BL", name: "Saint Barthélemy" },
  { value: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "KN", name: "Saint Kitts and Nevis" },
  { value: "LC", name: "Saint Lucia" },
  { value: "MF", name: "Saint Martin (French part)" },
  { value: "PM", name: "Saint Pierre and Miquelon" },
  { value: "VC", name: "Saint Vincent and the Grenadines" },
  { value: "WS", name: "Samoa" },
  { value: "SM", name: "San Marino" },
  { value: "ST", name: "Sao Tome and Principe" },
  { value: "SA", name: "Saudi Arabia" },
  { value: "SN", name: "Senegal" },
  { value: "RS", name: "Serbia" },
  { value: "SC", name: "Seychelles" },
  { value: "SL", name: "Sierra Leone" },
  { value: "SG", name: "Singapore" },
  { value: "SX", name: "Sint Maarten (Dutch part)" },
  { value: "SK", name: "Slovakia" },
  { value: "SI", name: "Slovenia" },
  { value: "SB", name: "Solomon Islands" },
  { value: "SO", name: "Somalia" },
  { value: "ZA", name: "South Africa" },
  { value: "GS", name: "South Georgia and the South Sandwich Islands" },
  { value: "SS", name: "South Sudan" },
  { value: "ES", name: "Spain" },
  { value: "LK", name: "Sri Lanka" },
  { value: "SD", name: "Sudan (the)" },
  { value: "SR", name: "Suriname" },
  { value: "SJ", name: "Svalbard and Jan Mayen" },
  { value: "SE", name: "Sweden" },
  { value: "CH", name: "Switzerland" },
  { value: "SY", name: "Syrian Arab Republic" },
  { value: "TW", name: "Taiwan" },
  { value: "TJ", name: "Tajikistan" },
  { value: "TZ", name: "Tanzania, United Republic of" },
  { value: "TH", name: "Thailand" },
  { value: "TL", name: "Timor-Leste" },
  { value: "TG", name: "Togo" },
  { value: "TK", name: "Tokelau" },
  { value: "TO", name: "Tonga" },
  { value: "TT", name: "Trinidad and Tobago" },
  { value: "TN", name: "Tunisia" },
  { value: "TR", name: "Turkey" },
  { value: "TM", name: "Turkmenistan" },
  { value: "TC", name: "Turks and Caicos Islands (the)" },
  { value: "TV", name: "Tuvalu" },
  { value: "UG", name: "Uganda" },
  { value: "UA", name: "Ukraine" },
  { value: "AE", name: "United Arab Emirates (the)" },
  { value: "GB", name: "United Kingdom of Great Britain and Northern Ireland (the)" },
  { value: "UM", name: "United States Minor Outlying Islands (the)" },
  { value: "US", name: "United States of America (the)" },
  { value: "UY", name: "Uruguay" },
  { value: "UZ", name: "Uzbekistan" },
  { value: "VU", name: "Vanuatu" },
  { value: "VE", name: "Venezuela (Bolivarian Republic of)" },
  { value: "VN", name: "Viet Nam" },
  { value: "VG", name: "Virgin Islands (British)" },
  { value: "VI", name: "Virgin Islands (U.S.)" },
  { value: "WF", name: "Wallis and Futuna" },
  { value: "EH", name: "Western Sahara" },
  { value: "YE", name: "Yemen" },
  { value: "ZM", name: "Zambia" },
  { value: "ZW", name: "Zimbabwe" },
  { value: "AX", name: "Åland Islands" },
];
export const LAUNCH_CUSTOM_EVENT_ENUM = [
  { name: "Achievement Unlocked", value: "ACHIEVEMENT_UNLOCKED" },
  { name: "Add Payment Info", value: "ADD_PAYMENT_INFO" },
  { name: "Add to Cart", value: "ADD_TO_CART" },
  { name: "Add to Wishlist", value: "ADD_TO_WISHLIST" },
  { name: "Complete Registration", value: "COMPLETE_REGISTRATION" },
  { name: "Contact", value: "CONTACT" },
  { name: "Content View", value: "CONTENT_VIEW" },
  { name: "Customize Product", value: "CUSTOMIZE_PRODUCT" },
  { name: "D2 Retention", value: "D2_RETENTION" },
  { name: "D2 Retention", value: "D7_RETENTION" },
  { name: "Donate", value: "DONATE" },
  { name: "Find Location", value: "FIND_LOCATION" },
  { name: "Initiated Checkout", value: "INITIATED_CHECKOUT" },
  { name: "Lead", value: "LEAD" },
  { name: "Level Achieved", value: "LEVEL_ACHIEVED" },
  { name: "Listing Interaction", value: "LISTING_INTERACTION" },
  { name: "Messaging Conversation Started 7D", value: "MESSAGING_CONVERSATION_STARTED_7D" },
  { name: "Other", value: "OTHER" },
  { name: "Purchase", value: "PURCHASE" },
  { name: "Rate", value: "RATE" },
  { name: "Schedule", value: "SCHEDULE" },
  { name: "Search", value: "SEARCH" },
  { name: "Service Booking Request", value: "SERVICE_BOOKING_REQUEST" },
  { name: "Spent Credits", value: "SPENT_CREDITS" },
  { name: "Start Trial", value: "START_TRIAL" },
  { name: "Submit Application", value: "SUBMIT_APPLICATION" },
  { name: "Subscribe", value: "SUBSCRIBE" },
  { name: "Tutorial Completion", value: "TUTORIAL_COMPLETION" },
];

// export const TIKTOK_LAUNCH_SPECIAL_INDUSTRIES = [
//   { name: "CREDIT", value: "CREDIT" },
//   { name: "EMPLOYMENT", value: "EMPLOYMENT" },
//   { name: "HOUSING", value: "HOUSING" },
// ];
export const LAUNCH_SMART_CREATIVE_ENUM = "SMART_CREATIVE";
export const TIKTOK_LAUNCH_CREATIVE_MATERIAL_MODE = [
  { name: "CUSTOM", value: "CUSTOM" },
  { name: "SMART CREATIVE", value: "SMART_CREATIVE" },
];
export const TIKTOK_LAUNCH_OPTIMIZATION_EVENT_ENUM = [
  { value: "ADD_BILLING", name: "Add Billing" },
  { value: "BUTTON", name: "Button" },
  { value: "CONSULT", name: "Consult" },
  { value: "DOWNLOAD_START", name: "Download Start" },
  { value: "FORM", name: "Form" },
  { value: "ON_WEB_ADD_TO_WISHLIST", name: "Web Add to Wishlist" },
  { value: "ON_WEB_CART", name: "Web Cart" },
  { value: "ON_WEB_DETAIL", name: "Web Detail" },
  { value: "ON_WEB_ORDER", name: "Web Order" },
  { value: "ON_WEB_REGISTER", name: "Web Register" },
  { value: "ON_WEB_SEARCH", name: "Web Search" },
  { value: "ON_WEB_SUBSCRIBE", name: "Web Subscribe" },
  { value: "SHOPPING", name: "Shopping" },
];

export const LAUNCH_CUSTOM_CREATIVES = "CUSTOM";
export const HIGHEST_VOLUME_BID = "LOWEST_COST_WITHOUT_CAP";
export const SPONSORED_MESSAGES = "sponsored_messages";

//Adaccount custom type enums
export const LAUNCH_CUSTOM_CONVERSION_TYPE = "Custom Conversions";
export const LAUNCH_CUSTOM_EVENT_TYPE = "Custom Events";
