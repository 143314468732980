import React from "react";

export default function EllipsisLoader() {
  return (
    <div style={{ transform: "scale(0.6)" }}>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
