import { useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import moment from "moment";
import alasql from "alasql";
import cx from "classnames";
import { useSelector } from "react-redux";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import { REPORT_TYPE_AD_ADSET } from "shared/SharedKeys";
import useNewLeadgenReportsQuery from "hooks/tiktokHooks/useNewLeadgenReportsQuery";
import {
  LEADGEN_FACEBOOK_PLATFORM_ID,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
} from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import { useFacebookSpendReportsQuery } from "hooks/facebookHooks";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";

export default function PerformanceReport(props) {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const {
    adsetId = "",
    performanceType,
    timezone: timeZoneProps,
    isCampaignFiltered = false,
  } = props;
  const dateFormat = "YYYY-MM-DD";

  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });
  // const [spendReport, setSpendReport] = useState([]);
  // const [revenueReport, setRevenueReport] = useState([]);

  const defaultDate = [moment().subtract(7, "d").format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const spendDateRangeParams = {
    from: rangeDates[0],
    to: rangeDates[1],
    timezone: timeZone.value,
  };

  const revenueDateRangeParams = {
    from: rangeDates[0],
    to: rangeDates[1],
    timezone: timeZone.value,
  };

  // let reportSettings = {
  //   reportApi: GET_REPORT_PER_AD_SET_ID,
  //   reportApiKey: "reportPerAdSetId",
  //   revenueApi: GET_REVENUE_PER_AD_SET_ID,
  //   revenueApiKey: "revenuePerAdSetId",
  //   reportVariables: {
  //     ...dateTimezoneApiVariables,
  //     workspaceId,
  //     adSetId: adsetId,
  //   },
  //   revnueVariables: {
  //     ...dateTimezoneApiVariables,
  //     workspaceId,
  //     adSetId: adsetId,
  //   },
  // };

  // if (isCampaignFiltered) {
  //   reportSettings = {
  //     reportApi: REPORT_AD_SET_BY_CAMPAIGNS,
  //     reportApiKey: "reportAdSetByCampaigns",
  //     revenueApi: REVENUE_AD_SET_BY_CAMPAIGNS,
  //     revenueApiKey: "revenueAdSetByCampaigns",
  //     reportVariables: {
  //       ...dateTimezoneApiVariables,
  //       campaignIds: [props?.campaignId],
  //     },
  //     revnueVariables: {
  //       ...dateTimezoneApiVariables,
  //       campaignIds: [props?.campaignId],
  //     },
  //   };
  // }

  // const {
  //   loading: loading_GET_REPORT_PER_AD_SET_ID,
  //   error: error_GET_REPORT_PER_AD_SET_ID,
  //   refetch: refetch_GET_REPORT_PER_AD_SET_ID,
  // } = useCustomQuery(reportSettings.reportApi, {
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted: (e) => {
  //     const element = e?.[reportSettings.reportApiKey];
  //     if (element) {
  //       setSpendReport(element);
  //     }
  //   },
  //   variables: {
  //     ...reportSettings.reportVariables,
  //   },
  // });

  // const {
  //   loading: loading_GET_REVENUE_PER_AD_SET_ID,
  //   error: error_GET_REVENUE_PER_AD_SET_ID,
  //   refetch: refetch_GET_REVENUE_PER_AD_SET_ID,
  // } = useCustomQuery(reportSettings.revenueApi, {
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted: (e) => {
  //     const element = e?.[reportSettings.revenueApiKey];
  //     if (element) {
  //       setRevenueReport(element);
  //     }
  //   },
  //   variables: {
  //     ...reportSettings.revnueVariables,
  //   },
  // });

  const { loading_spend, refetch_spend, spendReport } = useFacebookSpendReportsQuery(
    REPORT_TYPE_AD_ADSET,
    {
      ...spendDateRangeParams,
      detailReportType: performanceType,
      isDetailView: true,
      variables: { adset_id: adsetId },
    }
  );

  const { loading_revenue, refetch_revenue, revenueReport } = useNewLeadgenReportsQuery(
    REPORT_TYPE_AD_ADSET,
    {
      ...revenueDateRangeParams,
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      performanceType,
      variables: {
        asset: LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
        asset_id: [adsetId],
      },
    }
  );

  const loading = loading_revenue || loading_spend;
  const refetch = () => {
    refetch_revenue();
    refetch_spend();
  };

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const initialColumns = [
    {
      title: "Date",
      dataIndex: "report_time",
      key: "report_time",
      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return <LookTableColumnRender title={value} isProfit={PROFIT} />;
      },
      fixed: "left",
      width: 150,
      minWidth: 150,
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revenue - b?.revenue,
      inChart: true,
    },
    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
      inChart: true,
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      // render: (value) => <LookTableColumnRender title={value} />,
      render: (value, otherProps) => {
        // const profit = `$${value.replace("-", "")}`;
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      inChart: true,
    },
    {
      title: "ROAS",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROI - b?.ROI,
      inChart: true,
    },
    {
      title: "Visits",
      dataIndex: "inlineClicks",
      key: "inlineClicks",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.inlineClicks - b?.inlineClicks,
    },
    {
      title: "Clicks",
      dataIndex: "revClicks",
      key: "revClicks",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.revClicks - b?.revClicks,
    },
    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
    },
    {
      title: "FB Results",
      dataIndex: "result",
      key: "result",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.result - b?.result,
      inChart: true,
    },
    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 110,
      minWidth: 110,
      sorter: (a, b) => a?.conversions - b?.conversions,
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
    },
    {
      title: "PAYOUT",
      dataIndex: "PAYOUT",
      key: "PAYOUT",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.PAYOUT - b?.PAYOUT,
    },
    {
      title: "RINGBA REVENUE",
      dataIndex: "payout_amount",
      key: "payout_amount",
      hidden: true,
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 140,
      minWidth: 140,
      sorter: (a, b) => a?.payout_amount - b?.payout_amount,
    },
    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
    },
    {
      title: "EPV",
      dataIndex: "EPV",
      key: "EPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPV - b?.EPV,
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPC - b?.CPC,
    },
    {
      title: "CPR",
      dataIndex: "CPR",
      key: "CPR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPR - b?.CPR,
    },
    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.EPC - b?.EPC,
    },
    {
      title: "AD_CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
    },
    {
      title: "LP_CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
    },
    {
      title: "V Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.visits - b?.visits,
    },
  ];

  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    finalMerge = alasql(
      "SELECT *,b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b ON a.report_time = b.report_time",
      [spendReport, revenueReport]
    );
    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        inlineClicks = 0,
        impressionsFB = 0,
        revenue = 0,
        payout_amount = 0,
        visits = 0,
        revClicks = 0,
        conversions = 0,
        result = 0,
      } = element;

      const addSpendPer = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;
      const spendUpdate = (spendFB * addSpendPer).toFixed(2);
      const combinedRevenue = revenue + payout_amount;
      const PROFIT = parseFloat(combinedRevenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;
      const PAYOUT = parseFloat(combinedRevenue) / parseFloat(conversions);
      const CPV = parseFloat(spendUpdate) / parseFloat(inlineClicks);
      const EPV = parseFloat(combinedRevenue) / parseFloat(inlineClicks);
      const CPC = parseFloat(spendUpdate) / parseFloat(revClicks);
      const EPC = parseFloat(combinedRevenue) / parseFloat(revClicks);
      const AD_CTR = (parseFloat(inlineClicks) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(inlineClicks)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const CPR = parseFloat(spendUpdate) / parseFloat(conversions);

      finalMergeData.push({
        ...element,
        key: index,
        revenue: combinedRevenue,
        visits,
        inlineClicks,
        impressionsFB,
        result,
        revClicks,
        conversions,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        PAYOUT: valueCheck(PAYOUT),
        CPV: valueCheck(CPV),
        EPV: valueCheck(EPV),
        CPC: valueCheck(CPC),
        EPC: valueCheck(EPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        CPR: valueCheck(CPR),
      });
    });
  }

  const finalFilterData = finalMergeData.filter(({ name = "", adsetId = "", status = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (
      name?.toLowerCase()?.includes(searchValue) ||
      adsetId?.toLowerCase()?.includes(searchValue) ||
      status?.toLowerCase()?.includes(searchValue)
    ) {
      return true;
    }
    return false;
  });

  let total_spendFB = 0,
    total_inlineClicks = 0,
    total_impressionsFB = 0,
    total_revenue = 0,
    total_payout_amount = 0,
    total_visits = 0,
    total_revClicks = 0,
    total_conversions = 0,
    total_result = 0;

  for (const {
    spendFB = 0,
    inlineClicks = 0,
    impressionsFB = 0,
    revenue = 0,
    visits = 0,
    revClicks = 0,
    conversions = 0,
    result = 0,
    payout_amount = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_inlineClicks += parseFloat(inlineClicks || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_revenue += parseFloat(revenue || 0);
    total_visits += parseFloat(visits || 0);
    total_revClicks += parseFloat(revClicks || 0);
    total_conversions += parseFloat(conversions || 0);
    total_result += parseFloat(result || 0);
    total_payout_amount += parseFloat(payout_amount || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spendFB);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spendFB)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_CPV = parseFloat(total_spendFB) / parseFloat(total_inlineClicks);
  const total_EPV = parseFloat(total_revenue) / parseFloat(total_inlineClicks);
  const total_CPC = parseFloat(total_spendFB) / parseFloat(total_revClicks);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_AD_CTR =
    (parseFloat(total_inlineClicks) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_inlineClicks)) * 100;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;
  const total_CPR = parseFloat(total_spendFB) / parseFloat(total_conversions);

  const summaryData = {
    spendFB: valueCheck(total_spendFB, true),
    inlineClicks: valueCheck(total_inlineClicks, true),
    impressionsFB: valueCheck(total_impressionsFB, true),
    revenue: valueCheck(total_revenue, true),
    visits: valueCheck(total_visits, true),
    revClicks: valueCheck(total_revClicks, true),
    conversions: valueCheck(total_conversions, true),
    result: valueCheck(total_result, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    PAYOUT: valueCheck(total_PAYOUT, true),
    CPV: valueCheck(total_CPV, true),
    EPV: valueCheck(total_EPV, true),
    CPC: valueCheck(total_CPC, true),
    EPC: valueCheck(total_EPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    CPR: valueCheck(total_CPR, true),
    payout_amount: valueCheck(total_payout_amount, true),
  };

  const tableMainProps = {
    exportCSV: true,
    persistKey,
    isLineChart: true,
    isRowSelection: false,
    isDateRange: true,
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },

    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
