import AdAccounts from "./AdAccounts";
import Campaigns from "./Campaigns";
import LookTabRouting from "components/LookTabRouting";
import ActionIconRender from "components/ActionIconRender";
import {
  VS_VIEW_AGENCY_REPORTING,
  VS_VIEW_REPORTING,
} from "shared/UserPermission/userPermissionList";
import AdSets from "./AdSets";
import Ads from "./Ads";
import Content from "./Content";
import VSUserReport from "./UserReport";
import Domains from "./Domains";
import DomainArticle from "./DomainArticle";
import DomainAdAccount from "./DomainAdAccount";

export default function VSfbReport() {
  const tabContent = [
    {
      link: "/user-report",
      route: "/user-report",
      title: "User",
      component: VSUserReport,
      icon: () => <ActionIconRender iconType="user" />,
      permission: VS_VIEW_AGENCY_REPORTING,
    },
    {
      link: "/ad-account",
      route: "/ad-account",
      title: "Ad Accounts",
      component: AdAccounts,
      icon: () => <ActionIconRender iconType="user" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/campaigns",
      route: "/campaigns",
      title: "Campaigns",
      component: Campaigns,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/ad-sets",
      route: "/ad-sets",
      title: "Ad Sets",
      component: AdSets,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/ads",
      route: "/ads",
      title: "Ads",
      component: Ads,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/content",
      route: "/content",
      title: "Content",
      component: Content,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/domain",
      route: "/domain",
      title: "Domain",
      component: Domains,
      icon: () => <ActionIconRender iconType="global" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/domain-article",
      route: "/domain-article",
      title: "Domain Article",
      component: DomainArticle,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: VS_VIEW_REPORTING,
    },
    {
      link: "/domain-adaccount",
      route: "/domain-adaccount",
      title: "Domain AdAccount",
      component: DomainAdAccount,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: VS_VIEW_REPORTING,
    },
  ];

  return (
    <div>
      <LookTabRouting dashboardHeader={{ title: "Facebook Reports" }} tabs={tabContent} />
    </div>
  );
}
