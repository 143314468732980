import { Form, Button, Alert, Row, Col, DatePicker, Switch } from "antd";
import { Formik } from "formik";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={16}>
              <LookTextInput
                formik
                type="number"
                name="budget"
                label="Budget (in USD)"
                className="look-form-input"
                placeholder="Enter budget"
                submitOnEnter
              />
            </Col>
            <Col span={8}>
              <div>
                <p>Is Life Time</p>
                <Switch
                  checked={values.isLifeTime}
                  onChange={(e) => setFieldValue("isLifeTime", e)}
                  style={{ border: "1px solid #D3D3D3" }}
                />
              </div>
            </Col>
          </Row>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
