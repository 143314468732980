import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { useEffect, useState } from "react";
import {
  GET_CH_SAS_AD_REPORT,
  GET_SAS_LIVE_PERFORMANCE_REPORT,
  GET_SAS_POSTBACK_DAILY_PERFORMANCE_REPORT,
} from "shared/gql/gqlSchema/sasGql";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
} from "shared/SharedKeys";
import {
  SAS_POSTBACK_REPORT_GROUP_BY_AD,
  SAS_POSTBACK_REPORT_GROUP_BY_AD_ACCOUNT,
  SAS_POSTBACK_REPORT_GROUP_BY_AD_ADSET,
  SAS_POSTBACK_REPORT_GROUP_BY_AD_CAMPAIGN,
} from "../sasHooksEnums";

export const SAS_FB_REPORT_QUERY_POSTBACK = "PostBack";
export const SAS_FB_REPORT_QUERY_DAILY = "Daily";
export const SAS_FB_REPORT_QUERY_LIVE = "Live";
export const SAS_REPORT_QUERY_PERFORMANCE = "Performance";
function groupBy(type) {
  switch (type) {
    case REPORT_TYPE_AD_ACCOUNT:
      return { groupBy: SAS_POSTBACK_REPORT_GROUP_BY_AD_ACCOUNT };
    case REPORT_TYPE_AD:
      return { groupBy: SAS_POSTBACK_REPORT_GROUP_BY_AD };
    case REPORT_TYPE_AD_ADSET:
      return { groupBy: SAS_POSTBACK_REPORT_GROUP_BY_AD_ADSET };
    case REPORT_TYPE_CAMPAIGN:
      return { groupBy: SAS_POSTBACK_REPORT_GROUP_BY_AD_CAMPAIGN };
    default: {
      return {};
    }
  }
}

export default function useSasReportsQuery(type, extraParams = {}) {
  const {
    queryType,
    variables,
    platformId,
    from,
    to,
    timezone,
    isPerformanceHourly = false,
    isPerformanceDaily = false,
    isPerformanceHourOfTheDay = false,
  } = extraParams;
  const [revenueReport, setRevenueReport] = useState([]);
  const [latestSyncTime, setLatestSyncTime] = useState("");

  const mainQuery = isPerformanceHourly
    ? { query: GET_SAS_LIVE_PERFORMANCE_REPORT, element: "sasLivePerformanceReport" }
    : isPerformanceDaily
    ? {
        query: GET_SAS_POSTBACK_DAILY_PERFORMANCE_REPORT,
        element: "sasPostbackDailyPerformanceReport",
      }
    : isPerformanceHourOfTheDay
    ? {
        query: GET_SAS_POSTBACK_DAILY_PERFORMANCE_REPORT,
        element: "sasPostbackDailyPerformanceReport",
      }
    : { query: GET_CH_SAS_AD_REPORT, element: "ch_sasAdReports" };

  const [fetchData, { loading: loading_revenue, refetch }] = useCustomLazyQuery(mainQuery?.query, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const reportElement =
        isPerformanceHourly || isPerformanceDaily
          ? e?.[mainQuery?.element]
          : e?.[mainQuery?.element]?.data;

      if (queryType === SAS_FB_REPORT_QUERY_DAILY) {
        setLatestSyncTime(e?.[mainQuery?.element]?.latestSyncTime);
      }

      if (reportElement) {
        setRevenueReport(reportElement);
      }
    },

    variables: {
      from,
      to,
      timezone,
      type: queryType,
      ...groupBy(type),
      platformId,
      ...variables,
    },
  });

  const refetch_revenue = () => {
    setRevenueReport([]);
    if (!isPerformanceHourOfTheDay) {
      refetch();
    }
  };
  useEffect(() => {
    if (!isPerformanceHourOfTheDay) {
      fetchData();
    }
  }, []);
  return {
    loading_revenue,
    refetch_revenue,
    revenueReport,
    setRevenueReport,
    latestSyncTime,
    setLatestSyncTime,
  };
}
