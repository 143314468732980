export const faqSASData = [
  {
    question: "What is Launch?",
    answer:
      "Launch is a feature that allows you to create and launch multiple campaigns consisting of multiple ad sets and ads for different platforms like Facebook and TikTok. Currently, this feature is available for VS, SAS (Group & Campaign), and Leadgen service.",
  },
  {
    question: "How can I start a Launch?",
    substeps: 12,
    answer: `To start a Launch, follow these steps:
1. Create a bucket.
2. Create a targeting template.
3. Add creatives for the content of the bucket.
4. Navigate to Launch:
    a. Choose the targeting template.
    b. Preview or edit the template.
    c. Fill the autoselect form accordingly.
    d. If you want to add ads automatically, turn on autoselect; otherwise, turn it off.
    e. You can edit ad sets and ads.
    f. The final step is to Launch.
    g. Choose your ad account and corresponding page and pixels, then launch the campaigns.
    `,
  },
  {
    question: "How can I add buckets?",
    substeps: 5,
    answer: `You can add buckets via Campaigns or Groups.
    Follow these steps:
    a. Navigate to Campaigns/Groups.
    b. You can add single or bulk campaigns to a bucket.
    c. You can also push existing campaigns into a bucket.
    `,
  },
  {
    question: "How can I add creatives?",
    substeps: 20,
    answer: `
    You can add creatives on bucket/launch level 
    Bucket level:
   1. For Group Launch:
    a. Navigate to the Groups section.
    b. In the table, locate the keyword you're interested in.
    c. Click on the keyword's name to open a drawer.
    d. Within the drawer, you'll find options to upload creatives.
  
  2. For Campaign Launch:
   a. Navigate to the Campaigns section.
   b. Identify the category for the campaigns you wish to launch.
   c. Go to the Categories section.
   d. Search for the relevant category and click on its name to open a drawer.
   e. Inside the drawer, you'll find options to upload creatives.

   Launch Level:

   a.Navigate to Launch-Buckets 
   b.In the table, locate the campaign you're interested in.
   c. Click on the campaign's name to open a drawer.
    d. Within the drawer, you'll find options to upload creatives.
     `,
  },
];
