import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import Launch from "components/Launch";
import { LAUNCH_TYPE_SAS } from "components/LaunchView/LaunchKeys";
import { LaunchMainPage } from "components/LaunchView/LaunchMainPage";
import {
  SAS_AGENCY_ADMIN,
  SAS_MANAGE_NATIVE_CAMPAIGN,
  SAS_PRODUCT_ADMIN,
  SAS_TEAM_ADMIN,
  SAS_USER,
  SAS_USER_AFD,
  SAS_USER_D2S,
  SAS_USER_RSFC,
  SAS_VENDOR_ACCOUNT,
  SAS_VIEW_CATEGORY,
  SAS_VIEW_DOMAIN,
  SAS_VIEW_KEYWORD,
  SAS_VIEW_NATIVE_CAMPAIGN,
  SAS_VIEW_USER,
  SAS_VIEW_WEBSITE,
  SAS_WORKSPACE_ADMIN,
  UMS_SUPER_ADMIN,
} from "../../shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";
import {
  SAS_REPORT_TYPE_DAILY,
  SAS_REPORT_TYPE_LIVE,
  SAS_REPORT_TYPE_POSTBACK,
} from "pages/dashboardPages/SAS/sasKeysAndEnum";
import FAQ from "components/FAQ";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_GOOGLE_DISPLAY,
  PLATFORM_ID_TABOOLA,
  PLATFORM_ID_TIKTOK,
  PRODUCT_ID_SAS,
  WORKSPACE_ID_RSOC,
  PRODUCT_ID_RSOC,
} from "shared/SharedKeys";
import { useSelector } from "react-redux";
import { usePermissionCheck } from "hooks";
import AddRule from "components/LookTable/RuleEngine";
import { VAROZI_WORKSPACE_ID } from "pages/dashboardPages/Varozi/keysAndEnum";
import ExistingStrategy from "components/LookTable/RuleEngine/ExistingStrategy";
import ExistingAdminRule from "components/LookTable/RuleEngine/AdminRules";
import ExternalChannel from "pages/dashboardPages/SAS/ExternalChannel";

const Domains = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Advertisers-Domains" */ "pages/dashboardPages/SAS/Advertisers/Domains"
    )
  )
);
const Feeds = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Feeds" */ "pages/dashboardPages/SAS/Advertisers/Feeds")
  )
);
const Vendor = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Vendor" */ "pages/dashboardPages/SAS/Advertisers/Vendor")
  )
);
const VendorAccounts = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VendorAccounts" */ "pages/dashboardPages/SAS/Advertisers/VendorAccounts"
    )
  )
);
const User = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Publishers-User" */ "pages/dashboardPages/SAS/Publishers/User")
  )
);
const Agency = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Publishers-Agency" */ "pages/dashboardPages/SAS/Publishers/Agency")
  )
);
const LookCategory = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "LookCategory" */ "pages/dashboardPages/SAS/Configuration/LookCategory"
    )
  )
);
const LookVertical = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "LookVertical" */ "pages/dashboardPages/SAS/Configuration/LookVertical"
    )
  )
);
const Platform = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Platform" */ "pages/dashboardPages/SAS/Configuration/Platform")
  )
);
const Content = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Content" */ "pages/dashboardPages/SAS/Configuration/Content")
  )
);
const Pixels = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Configuration-Pixels" */ "pages/dashboardPages/SAS/Configuration/Pixels"
    )
  )
);
const TrackingUrl = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TrackingUrl" */ "pages/dashboardPages/SAS/Configuration/TrackingUrl"
    )
  )
);
const BuyDomains = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "BuyDomains" */ "pages/dashboardPages/SAS/Tools/BuyDomains")
  )
);
const KeywordPlanner = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "KeywordPlanner" */ "pages/dashboardPages/SAS/Tools/KeywordPlanner")
  )
);
const URLBuilder = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "URLBuilder" */ "pages/dashboardPages/SAS/Tools/URLBuilder")
  )
);
const NativeCampaign = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "NativeCampaign" */ "pages/dashboardPages/SAS/NativeCampaign")
  )
);
const SasVendorSites = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "SasVendorSites" */ "pages/dashboardPages/SAS/SasVendorSites")
  )
);
const Website = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Website" */ "pages/dashboardPages/SAS/Website"))
);
const Channel = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Collections-Channel" */ "pages/dashboardPages/SAS/Channel")
  )
);
const Categories = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Collections-Categories" */ "pages/dashboardPages/SAS/Collections/Categories"
    )
  )
);
const Keywords = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Collections-Keywords" */ "pages/dashboardPages/SAS/Collections/Keywords"
    )
  )
);
const AdAccountReportsPostback = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdAccountReports" */ "pages/dashboardPages/SAS/Reports/FacebookReports/PostbackReport/AdAccountReports"
    )
  )
);
const AdAccountReportsLive = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdAccountReportsLive" */ "pages/dashboardPages/SAS/Reports/FacebookReports/LiveReport/AdAccountReportsLive"
    )
  )
);
const LiveFBReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookReports-LiveReport" */ "pages/dashboardPages/SAS/Reports/FacebookReports/LiveReport"
    )
  )
);
const DailyFBReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookReports-DailyReport" */ "pages/dashboardPages/SAS/Reports/FacebookReports/DailyReport"
    )
  )
);
const AdAccountReportsDaily = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AdAccountReportsDaily" */ "pages/dashboardPages/SAS/Reports/FacebookReports/DailyReport/AdAccountReportsDaily"
    )
  )
);
const DailyTaboolaReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TaboolaReports-DailyReport" */ "pages/dashboardPages/SAS/Reports/TaboolaReports/DailyReport"
    )
  )
);
const LiveTaboolaReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TaboolaReports-LiveReport" */ "pages/dashboardPages/SAS/Reports/TaboolaReports/LiveReport"
    )
  )
);
const PostbackTaboolaReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TaboolaReports-PostbackReport" */ "pages/dashboardPages/SAS/Reports/TaboolaReports/PostbackReport"
    )
  )
);
const DailyTiktokReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TiktokReports-DailyReport" */ "pages/dashboardPages/SAS/Reports/TiktokReports/DailyReport"
    )
  )
);
const LiveTiktokReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TiktokReports-LiveReport" */ "pages/dashboardPages/SAS/Reports/TiktokReports/LiveReport"
    )
  )
);
const PostbackTiktokReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TiktokReports-PostbackReport" */ "pages/dashboardPages/SAS/Reports/TiktokReports/PostbackReport"
    )
  )
);
const PostbackFBReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookReports-PostbackReport" */ "pages/dashboardPages/SAS/Reports/FacebookReports/PostbackReport"
    )
  )
);
const FBRedirect = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FBRedirect" */ "pages/dashboardPages/SAS/Reports/FacebookReports/FBRedirect"
    )
  )
);
const UserReportDaily = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "DailyReport-UserReportDaily" */ "pages/dashboardPages/SAS/Reports/FacebookReports/DailyReport/UserReportDaily"
    )
  )
);
const UserReportPostback = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookReports-UserReportPostback" */ "pages/dashboardPages/SAS/Reports/FacebookReports/PostbackReport/UserReportPostback"
    )
  )
);
const YahooReports = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "YahooReports" */ "pages/dashboardPages/SAS/Reports/YahooReports")
  )
);
const YahooDailyReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "YahooAllReports-dailyReports" */ "pages/dashboardPages/SAS/Reports/YahooReports/YahooAllReports/dailyReports"
    )
  )
);
const YahooLiveReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "YahooAllReports-liveReports" */ "pages/dashboardPages/SAS/Reports/YahooReports/YahooAllReports/liveReports"
    )
  )
);
const YahooPostbackReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "postbackReports" */ "pages/dashboardPages/SAS/Reports/YahooReports/YahooAllReports/postbackReports"
    )
  )
);
const GlobalReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "GlobalReport" */ "pages/dashboardPages/SAS/Reports/GlobalReports/GlobalReport"
    )
  )
);
const GlobalReportRsoc = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "GlobalReportRsoc" */ "pages/dashboardPages/SAS/Reports/GlobalReports/GlobalReportRsoc"
    )
  )
);
const UserReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "UserReport" */ "pages/dashboardPages/SAS/Reports/FacebookReports/PostbackReport/UserReport"
    )
  )
);
const TiktokUserReportDaily = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "UserReports-UserReportDaily" */ "pages/dashboardPages/SAS/Reports/TiktokReports/DailyReport/UserReports/UserReportDaily"
    )
  )
);
const TiktokUserReportPostback = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TiktokReports-UserReportPostback" */ "pages/dashboardPages/SAS/Reports/TiktokReports/PostbackReport/UserReportPostback"
    )
  )
);
const TiktokRedirect = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TiktokRedirect" */ "pages/dashboardPages/SAS/Reports/TiktokReports/TiktokRedirect"
    )
  )
);
const GoogleDisplayNativeReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "GoogleDisplayNativeReports" */ "pages/dashboardPages/SAS/Reports/GoogleDisplayNativeReports"
    )
  )
);
const VendorReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VendorAccountReports" */ "pages/dashboardPages/SAS/Reports/VendorAccountReports"
    )
  )
);
const YahooUserReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "YahooUserReport" */ "pages/dashboardPages/SAS/Reports/YahooReports/YahooUserReport"
    )
  )
);
const RsocReports = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "RsocReports" */ "pages/dashboardPages/SAS/Reports/RsocReports")
  )
);
const GdnRsocReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "GdnReports" */ "pages/dashboardPages/SAS/Reports/RsocReports/GdnReports"
    )
  )
);
const TiktokRsocReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TiktokReports" */ "pages/dashboardPages/SAS/Reports/RsocReports/TiktokReports"
    )
  )
);
const FacebookRsocReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "RsocReports-FacebookReport" */ "pages/dashboardPages/SAS/Reports/RsocReports/FacebookReport"
    )
  )
);
const FacebookNewRsocReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "RsocNewReports-FacebookReport" */ "pages/dashboardPages/SAS/Reports/RsocNewReports/FacebookReport"
    )
  )
);
const GdnNewRsocReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "New-GdnReports" */ "pages/dashboardPages/SAS/Reports/RsocNewReports/GdnReports"
    )
  )
);
const TiktokNewRsocReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "New-TiktokReports" */ "pages/dashboardPages/SAS/Reports/RsocNewReports/TiktokReports"
    )
  )
);

export default function useSasRoutes() {
  const { havePermission: isD2SWorkspacePermission } = usePermissionCheck(SAS_USER_D2S);
  const { havePermission: isRsfc } = usePermissionCheck(SAS_USER_RSFC);
  const platforms = useSelector((state) => state?.userReducer?.me?.platforms);
  const workspace = useSelector((state) => state?.userSettings?.selectedWorkspace);
  const isVaroziWorkspace = workspace === VAROZI_WORKSPACE_ID;
  const ruleSetting = {
    platform: platforms,
    // &&
    // (isVaroziWorkspace
    //   ? "varozi"
    //   : platforms[0]?.id === 1
    //   ? "fb"
    //   : platforms[0]?.id === 7
    //   ? "tiktok"
    //   : "taboola"),
    productId: workspace === WORKSPACE_ID_RSOC ? PRODUCT_ID_RSOC : PRODUCT_ID_SAS,
  };

  const isFBRoutes = !(isD2SWorkspacePermission || isRsfc)
    ? [
        route("/sas/reports/facebook-postback", PostbackFBReports, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_FACEBOOK,
          permission: [
            SAS_TEAM_ADMIN,
            SAS_AGENCY_ADMIN,
            SAS_WORKSPACE_ADMIN,
            SAS_USER,
            SAS_USER_AFD,
          ],
        }),

        route("/sas/reports/facebook-live", LiveFBReport, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_FACEBOOK,
          permission: [
            SAS_TEAM_ADMIN,
            SAS_AGENCY_ADMIN,
            SAS_WORKSPACE_ADMIN,
            SAS_USER,
            SAS_USER_AFD,
          ],
        }),

        route("/sas/reports/facebook-daily", DailyFBReport, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_FACEBOOK,
          permission: [
            SAS_TEAM_ADMIN,
            SAS_AGENCY_ADMIN,
            SAS_WORKSPACE_ADMIN,
            SAS_USER,
            SAS_USER_AFD,
          ],
        }),
      ]
    : [];

  const googleReportRouts = !(isD2SWorkspacePermission || isRsfc)
    ? [
        route(
          "/sas/reports/gdn-postback",
          (e) => (
            <GoogleDisplayNativeReports
              {...e}
              queryType={SAS_REPORT_TYPE_POSTBACK}
              title={"Postback Reports"}
            />
          ),
          ROUTE_DASHBOARD,
          false
        ),
        route(
          "/sas/reports/gdn-daily",
          (e) => (
            <GoogleDisplayNativeReports
              {...e}
              queryType={SAS_REPORT_TYPE_DAILY}
              title={"Daily Reports"}
            />
          ),
          ROUTE_DASHBOARD,
          false
        ),
        route(
          "/sas/reports/gdn-live",
          (e) => (
            <GoogleDisplayNativeReports
              {...e}
              queryType={SAS_REPORT_TYPE_LIVE}
              title={"Live Reports"}
            />
          ),
          ROUTE_DASHBOARD,
          false
        ),
      ]
    : [];

  const tikTokReportRouts = !(isD2SWorkspacePermission || isRsfc)
    ? [
        route("/sas/reports/tiktok-postback", PostbackTiktokReports, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_TIKTOK,
          permission: [
            SAS_TEAM_ADMIN,
            SAS_AGENCY_ADMIN,
            SAS_WORKSPACE_ADMIN,
            SAS_USER,
            SAS_USER_AFD,
          ],
        }),

        route("/sas/reports/tiktok-live", LiveTiktokReport, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_TIKTOK,
          permission: [
            SAS_TEAM_ADMIN,
            SAS_AGENCY_ADMIN,
            SAS_WORKSPACE_ADMIN,
            SAS_USER,
            SAS_USER_AFD,
          ],
        }),

        route("/sas/reports/tiktok-daily", DailyTiktokReport, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_TIKTOK,
          permission: [
            SAS_TEAM_ADMIN,
            SAS_AGENCY_ADMIN,
            SAS_WORKSPACE_ADMIN,
            SAS_USER,
            SAS_USER_AFD,
          ],
        }),
      ]
    : [];

  const isYahooReport = isD2SWorkspacePermission
    ? [
        route("/sas/yahoo/yahoo-user-reports", YahooUserReport, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_FACEBOOK,
          permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER_D2S],
        }),

        route("/sas/yahoo/yahoo-and-bing-reports", YahooReports, ROUTE_DASHBOARD, false, {
          platform: PLATFORM_ID_FACEBOOK,
          permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER_D2S],
        }),
      ]
    : [];

  const isRsocGlobalReport = isRsfc
    ? [route("/sas/global-report-rsoc", GlobalReportRsoc, ROUTE_DASHBOARD, false, [SAS_USER_RSFC])]
    : [route("/sas/global-report", GlobalReport, ROUTE_DASHBOARD, false, [SAS_USER])];

  const sasRoutes = [
    ...isRsocGlobalReport,
    route("/sas/collections/keyword", Keywords, ROUTE_DASHBOARD, false, SAS_VIEW_KEYWORD),
    route("/sas/collections/categories", Categories, ROUTE_DASHBOARD, false, SAS_VIEW_CATEGORY),
    route("/sas/users", () => <User fromOuter />, ROUTE_DASHBOARD, true, SAS_VIEW_USER),
    route("/sas/domains", () => <Domains fromOuter />, ROUTE_DASHBOARD, true, SAS_VIEW_DOMAIN),
    route("/sas/advertisers/domains", Domains, ROUTE_DASHBOARD, true, UMS_SUPER_ADMIN),
    route("/sas/advertisers/vendors", Vendor, ROUTE_DASHBOARD, true, UMS_SUPER_ADMIN),
    route(
      "/sas/advertisers/vendor-accounts",
      VendorAccounts,
      ROUTE_DASHBOARD,
      true,
      UMS_SUPER_ADMIN
    ),
    route("/sas/advertisers/feeds", Feeds, ROUTE_DASHBOARD, true, UMS_SUPER_ADMIN),
    route("/sas/publishers/user", User, ROUTE_DASHBOARD, true, SAS_AGENCY_ADMIN),
    route("/sas/publishers/agency", Agency, ROUTE_DASHBOARD, true, SAS_AGENCY_ADMIN),
    route("/sas/lookvertical", LookVertical, ROUTE_DASHBOARD, true, [UMS_SUPER_ADMIN]),
    route("/sas/lookcategory", LookCategory, ROUTE_DASHBOARD, true, [UMS_SUPER_ADMIN]),
    route("/sas/platform", Platform, ROUTE_DASHBOARD, true, [UMS_SUPER_ADMIN]),
    route("/sas/pixels", Pixels, ROUTE_DASHBOARD, true, [UMS_SUPER_ADMIN]),
    route("/sas/content", Content, ROUTE_DASHBOARD, true, [UMS_SUPER_ADMIN]),
    route("/sas/tracking-url", TrackingUrl, ROUTE_DASHBOARD, true, [UMS_SUPER_ADMIN]),
    route("/sas/campaigns", NativeCampaign, ROUTE_DASHBOARD, true, [
      SAS_VIEW_NATIVE_CAMPAIGN,
      SAS_MANAGE_NATIVE_CAMPAIGN,
    ]),
    route("/sas/channel", Channel, ROUTE_DASHBOARD, true, [SAS_AGENCY_ADMIN, SAS_USER]),
    route("/sas/external-channel", ExternalChannel, ROUTE_DASHBOARD, true, [
      SAS_AGENCY_ADMIN,
      SAS_USER,
    ]),
    route("/sas/url-builder", URLBuilder, ROUTE_DASHBOARD, false, SAS_VIEW_KEYWORD),
    route("/sas/keyword-planner", KeywordPlanner, ROUTE_DASHBOARD, false, SAS_VIEW_KEYWORD),
    route("/sas/buy-domains", BuyDomains, ROUTE_DASHBOARD, false, SAS_VIEW_KEYWORD),

    // route("/sas/daily-report", DailyReports, ROUTE_DASHBOARD, true, [
    //   SAS_USER,
    //   SAS_TEAM_ADMIN,
    //   SAS_AGENCY_ADMIN,
    //   SAS_WORKSPACE_ADMIN,
    // ]),
    // route("/sas/old-daily-report", DailyOldReports, ROUTE_DASHBOARD, true, [
    //   SAS_USER,
    //   SAS_TEAM_ADMIN,
    //   SAS_AGENCY_ADMIN,
    //   SAS_WORKSPACE_ADMIN,
    // ]),
    // route("/sas/live-report", LiveReports, ROUTE_DASHBOARD, true, [
    //   SAS_USER,
    //   SAS_TEAM_ADMIN,
    //   SAS_AGENCY_ADMIN,
    //   SAS_WORKSPACE_ADMIN,
    // ]),
    // route("/sas/campaign-report", CampaignReports, ROUTE_DASHBOARD, false, [
    //   SAS_USER,
    //   SAS_TEAM_ADMIN,
    //   SAS_AGENCY_ADMIN,
    //   SAS_WORKSPACE_ADMIN,
    // ]),

    // route("/sas/user-report", UserReports, ROUTE_DASHBOARD, true, [
    //   SAS_TEAM_ADMIN,
    //   SAS_AGENCY_ADMIN,
    //   SAS_WORKSPACE_ADMIN,
    // ]),
    route(
      "/sas/launch",
      () => <LaunchMainPage launchType={LAUNCH_TYPE_SAS} />,
      ROUTE_DASHBOARD,
      true
    ),

    route("/sas/launch-new", Launch, ROUTE_DASHBOARD, true),

    // route("/sas/categories-daily-report", CategoryDailyReports, ROUTE_DASHBOARD, true, [
    //   SAS_TEAM_ADMIN,
    //   SAS_AGENCY_ADMIN,
    //   SAS_WORKSPACE_ADMIN,
    //   F360_VIEW_USERS_REPORT,
    // ]),

    // route("/sas/categories-live-report", CategoryLiveReports, ROUTE_DASHBOARD, true, [
    //   SAS_TEAM_ADMIN,
    //   SAS_AGENCY_ADMIN,
    //   SAS_WORKSPACE_ADMIN,
    //   F360_VIEW_USERS_REPORT,
    // ]),

    route(
      "/sas/copilot/rules",
      () => {
        return <AddRule {...ruleSetting} />;
      },
      ROUTE_DASHBOARD,
      true
    ),
    route(
      "/sas/copilot/allrules",
      () => <ExistingAdminRule {...ruleSetting} />,
      ROUTE_DASHBOARD,
      true
    ),
    route(
      "/sas/copilot/strategy",
      () => {
        return <ExistingStrategy {...ruleSetting} />;
      },
      ROUTE_DASHBOARD,
      true
    ),
    // groupBy: REPORT_TYPE_AD,
    // reportType: REPORT_TYPE_GENERAL,
    // productId: PRODUCT_ID_LEADGEN,
    // platform: "fb",:platform/:productID
    route(
      "/sas/reports/facebook-postback/ad-account/:adAccountID",
      AdAccountReportsPostback,
      ROUTE_DASHBOARD,
      false,
      {
        platform: PLATFORM_ID_FACEBOOK,
        permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
      }
    ),

    route(
      "/sas/reports/facebook-live/ad-account/:adAccountID",
      AdAccountReportsLive,
      ROUTE_DASHBOARD,
      false,
      {
        platform: PLATFORM_ID_FACEBOOK,
        permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
      }
    ),

    route(
      "/sas/reports/facebook-daily/ad-account/:adAccountID",
      AdAccountReportsDaily,
      ROUTE_DASHBOARD,
      false,
      {
        platform: PLATFORM_ID_FACEBOOK,
        permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
      }
    ),

    route(
      "/sas/yahoo-and-bing-reports/postback-report/:vendorID",
      YahooPostbackReports,
      ROUTE_DASHBOARD,
      false,
      {
        platform: PLATFORM_ID_FACEBOOK,
        permission: [
          SAS_TEAM_ADMIN,
          SAS_AGENCY_ADMIN,
          SAS_WORKSPACE_ADMIN,
          // SAS_USER,
          SAS_USER_D2S,
        ],
      }
    ),

    route(
      "/sas/yahoo-and-bing-reports/live-report/:vendorID",
      YahooLiveReports,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),

    route(
      "/sas/yahoo-and-bing-reports/daily-report/:vendorID",
      YahooDailyReports,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),

    ...isYahooReport,

    route(
      "/sas/reports/facebook-daily/user-report/:userID",
      UserReportDaily,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),

    route(
      "/sas/reports/facebook-postback/user-report/:userID",
      UserReportPostback,
      ROUTE_DASHBOARD,
      false,
      {
        platform: PLATFORM_ID_FACEBOOK,
        permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
      }
    ),

    route(
      "/sas/reports/tiktok-daily/user-report/:userID",
      TiktokUserReportDaily,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),

    route(
      "/sas/reports/facebook-campaign/redirect/:id",
      () => <FBRedirect redirectType="campaigns" />,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),
    route(
      "/sas/reports/tiktok-campaign/redirect/:id",
      () => <TiktokRedirect redirectType="CAMPAIGN" />,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),

    route(
      "/sas/reports/facebook-adset/redirect/:id",
      () => <FBRedirect redirectType="adsets" />,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),
    route(
      "/sas/reports/tiktok-adset/redirect/:id",
      () => <TiktokRedirect redirectType="ADGROUP" />,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),

    route(
      "/sas/reports/facebook-ad/redirect/:id",
      () => <FBRedirect redirectType="ads" />,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),
    route(
      "/sas/reports/tiktok-ads/redirect/:id",
      () => <TiktokRedirect redirectType="AD" />,
      ROUTE_DASHBOARD,
      false,
      [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER]
    ),

    route("/sas/reports/user/:userID", UserReport, ROUTE_DASHBOARD, false, [
      SAS_TEAM_ADMIN,
      SAS_AGENCY_ADMIN,
      SAS_WORKSPACE_ADMIN,
      SAS_USER,
    ]),
    ...isFBRoutes,

    route("/sas/reports/taboola-postback", PostbackTaboolaReports, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_TABOOLA,
      permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
    }),

    route("/sas/reports/taboola-live", LiveTaboolaReport, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_TABOOLA,
      permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
    }),

    route("/sas/reports/taboola-daily", DailyTaboolaReport, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_TABOOLA,
      permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
    }),

    route(
      "/sas/reports/tiktok-postback/user-report/:userID",
      TiktokUserReportPostback,
      ROUTE_DASHBOARD,
      false,
      {
        platform: PLATFORM_ID_TIKTOK,
        permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER, SAS_USER_AFD],
      }
    ),

    ...tikTokReportRouts,

    route("/sas/vendor-sites", SasVendorSites, ROUTE_DASHBOARD, false, [
      SAS_TEAM_ADMIN,
      SAS_AGENCY_ADMIN,
      SAS_WORKSPACE_ADMIN,
      SAS_USER,
    ]),
    route("/sas/website", Website, ROUTE_DASHBOARD, false, [UMS_SUPER_ADMIN]),

    ...googleReportRouts,
    route("/sas/reports/vendor-report", VendorReport, ROUTE_DASHBOARD, false, [UMS_SUPER_ADMIN]),
    route("/sas/faq", FAQ, ROUTE_DASHBOARD, true, SAS_USER),
    route("/sas/reports/rsoc-report", RsocReports, ROUTE_DASHBOARD, false, [SAS_USER_RSFC]),
    route("/sas/reports/rsoc-ad-facebook-report", FacebookRsocReport, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_FACEBOOK,
      permission: [SAS_USER_RSFC],
    }),

    route("/sas/reports/rsoc-ad-gdn-report", GdnRsocReport, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_GOOGLE_DISPLAY,
      permission: [SAS_USER_RSFC],
    }),
    route("/sas/reports/rsoc-ad-tiktok-report", TiktokRsocReport, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_TIKTOK,
      permission: [SAS_USER_RSFC],
    }),

    route(
      "/sas/reports/new/rsoc-ad-facebook-report",
      FacebookNewRsocReport,
      ROUTE_DASHBOARD,
      false,
      {
        platform: PLATFORM_ID_FACEBOOK,
        permission: [SAS_USER_RSFC],
      }
    ),

    route("/sas/reports/new/rsoc-ad-gdn-report", GdnNewRsocReport, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_GOOGLE_DISPLAY,
      permission: [SAS_USER_RSFC],
    }),
    route("/sas/reports/new/rsoc-ad-tiktok-report", TiktokNewRsocReport, ROUTE_DASHBOARD, false, {
      platform: PLATFORM_ID_TIKTOK,
      permission: [SAS_USER_RSFC],
    }),
  ];
  return { sasRoutes };
}
