import {
  VS_ADVERTISER_FORCE_SET,
  VS_ADVERTISER_SELECTION,
  VS_ADVERTISER_SELECTION_WITH_ALL,
} from "actions/actionTypes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export function VSCheckDefalulSelectedAdvertiser(props) {
  const dispatch = useDispatch();

  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};

  const { selectedVSAdvertiser = "" } = useSelector((state) => state?.vsReducer) || {};

  useEffect(() => {
    if (selectedVSAdvertiser === "") {
      if (vsAdvertiser?.length > 0) {
        dispatch({ type: VS_ADVERTISER_FORCE_SET, payload: vsAdvertiser[0]?.id });
      }
    }
  }, [selectedVSAdvertiser, vsAdvertiser]);

  return null;
}

export default function useVSAdvertiserHandler() {
  const dispatch = useDispatch();
  function handleAdvertiserSelection(e, withAll = false) {
    if (withAll) {
      if (e === "all") {
        dispatch({ type: VS_ADVERTISER_SELECTION_WITH_ALL });
      } else {
        dispatch({ type: VS_ADVERTISER_SELECTION, payload: e });
      }
    } else {
      dispatch({ type: VS_ADVERTISER_FORCE_SET, payload: e });
    }
  }

  return { handleAdvertiserSelection };
}
