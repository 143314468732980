import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  TRACKER_USER,
  TRACKER_VIEW_ADVERTISER,
  TRACKER_VIEW_AFFILIATENETWORK,
  TRACKER_VIEW_CATEGORY,
  TRACKER_VIEW_DOMAIN,
  TRACKER_VIEW_NETWORKACCOUNT,
  TRACKER_VIEW_PATH,
  TRACKER_VIEW_RULE,
  TRACKER_VIEW_TRAFFICSOURCE,
} from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const TrackerAdvertiser = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Tracker-TrackerAdvertiser" */ "pages/dashboardPages/Tracker/TrackerAdvertiser"
    )
  )
);
const TrackerCategory = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Tracker-TrackerCategory" */ "pages/dashboardPages/Tracker/TrackerCategory"
    )
  )
);
const TrackerDomain = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Tracker-TrackerDomain" */ "pages/dashboardPages/Tracker/TrackerDomain"
    )
  )
);
const TrackerAffiliateNetwork = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Tracker-TrackerAffiliateNetwork" */ "pages/dashboardPages/Tracker/TrackerAffiliateNetwork"
    )
  )
);
const TrackerPath = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Tracker-TrackerPath" */ "pages/dashboardPages/Tracker/TrackerPath")
  )
);
const TrackerRule = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Tracker-TrackerRule" */ "pages/dashboardPages/Tracker/TrackerRule")
  )
);
const TrackerNetworkAccount = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TrackerNetworkAccount" */ "pages/dashboardPages/Tracker/TrackerNetworkAccount"
    )
  )
);
const TrackerTrafficSource = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TrackerTrafficSource" */ "pages/dashboardPages/Tracker/TrackerTrafficSource"
    )
  )
);
const TrackerOffers = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "TrackerOffers" */ "pages/dashboardPages/Tracker/TrackerOffers")
  )
);
const TrackerSubId = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "TrackerSubId" */ "pages/dashboardPages/Tracker/TrackerSubId")
  )
);
const TrackerCps = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "TrackerCps" */ "pages/dashboardPages/Tracker/TrackerCps")
  )
);
const TrackerCpsReporting = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TrackerReporting" */ "pages/dashboardPages/Tracker/TrackerReporting"
    )
  )
);

const trackerRoutes = [
  route("/tracker/advertiser", TrackerAdvertiser, ROUTE_DASHBOARD, true, TRACKER_VIEW_ADVERTISER),
  route("/tracker/category", TrackerCategory, ROUTE_DASHBOARD, true, TRACKER_VIEW_CATEGORY),
  route("/tracker/domain", TrackerDomain, ROUTE_DASHBOARD, true, TRACKER_VIEW_DOMAIN),
  // route("/tracker/lander", TrackerLander, ROUTE_DASHBOARD, true, TRACKER_VIEW_LANDER),
  route(
    "/tracker/affiliate-network",
    TrackerAffiliateNetwork,
    ROUTE_DASHBOARD,
    true,
    TRACKER_VIEW_AFFILIATENETWORK
  ),
  route("/tracker/path", TrackerPath, ROUTE_DASHBOARD, true, TRACKER_VIEW_PATH),
  route("/tracker/rule", TrackerRule, ROUTE_DASHBOARD, true, TRACKER_VIEW_RULE),
  route(
    "/tracker/network-account",
    TrackerNetworkAccount,
    ROUTE_DASHBOARD,
    true,
    TRACKER_VIEW_NETWORKACCOUNT
  ),
  route(
    "/tracker/traffic-source",
    TrackerTrafficSource,
    ROUTE_DASHBOARD,
    true,
    TRACKER_VIEW_TRAFFICSOURCE
  ),
  route("/tracker/subid", TrackerSubId, ROUTE_DASHBOARD, false, TRACKER_USER),
  route("/tracker/offers", TrackerOffers, ROUTE_DASHBOARD, true, TRACKER_VIEW_NETWORKACCOUNT),
  // route("/tracker/cta", TrackerCTA, ROUTE_DASHBOARD, true, TRACKER_VIEW_RULE),
  route("/tracker/campaign", TrackerCps, ROUTE_DASHBOARD, true, TRACKER_USER),
  route(
    "/tracker/cps-campaign-reporting",
    TrackerCpsReporting,
    ROUTE_DASHBOARD,
    true,
    TRACKER_USER
  ),

  // route("/tracker/campaign", TrackerCampaign, ROUTE_DASHBOARD, true, TRACKER_VIEW_RULE),
  // route("/tracker/cps", TrackerCps, ROUTE_DASHBOARD, true, TRACKER_VIEW_RULE),
];

export default trackerRoutes;
