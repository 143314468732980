import { Collapse, Popover, Checkbox, Tag } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import React, { useState } from "react";

// Unit Component
const RenderCollapsablePanel = ({
  title,
  selectedOptions = [],
  selectingOptions,
  selectedOptionsCallbackFn = () => {},
}) => {
  const [activeCollapseKey, setActiveCollapseKey] = useState();

  const onCollapsibleClick = (keys) => setActiveCollapseKey(keys[0]);

  const onCheckBoxChangeHandler = (checkedValue) => {
    selectedOptionsCallbackFn({ key: title, selected: checkedValue });
  };

  const getCheckedBoxValues = () => {
    if (selectedOptions?.length) {
      const val = selectedOptions?.find((it) => it?.key === title);
      if (val) return val?.selected;
    }
    return [];
  };

  const renderHeaderTitleAndBadge = () => {
    const selectedItemsNum = getCheckedBoxValues()?.length;
    return (
      <>
        {title}
        {selectedItemsNum > 0 ? <Tag className="ml-6 tagstyle">{selectedItemsNum}</Tag> : null}
      </>
    );
  };

  return (
    <Collapse
      activeKey={activeCollapseKey}
      expandIconPosition="end"
      collapsible="header"
      onChange={onCollapsibleClick}
      ghost
    >
      <Collapse.Panel header={renderHeaderTitleAndBadge()} key={title}>
        {selectingOptions?.length > 0 && (
          <Checkbox.Group onChange={onCheckBoxChangeHandler} value={getCheckedBoxValues()}>
            {selectingOptions?.map((it) => (
              <Checkbox className="checkbox ml-0" value={it?.value}>
                {it?.name}
              </Checkbox>
            ))}
          </Checkbox.Group>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

// Main index component
const CommentFilters = ({
  isPopoverOpened = false,
  filterOpenStatusHandler = () => {},
  selectedFilters = [],
  filteringOptions = [],
  onSubmitHandler = () => {},
  onResetHandler = () => {},
}) => {
  const [selectedItems, setSelectedItems] = useState(selectedFilters);

  const onResetClickHandler = () => {
    setSelectedItems([]);
    onResetHandler();
    onFilterCloseHandler();
  };

  const onApplyClickHandler = () => {
    onSubmitHandler(selectedItems);
    onFilterCloseHandler();
  };

  const onFilterOpenHandler = () => {
    filterOpenStatusHandler(true);
  };

  const onFilterCloseHandler = () => {
    filterOpenStatusHandler(false);
  };

  const getSelectedOptionsWithKey = (obj) => {
    setSelectedItems((prev) => {
      let removedElem = prev?.filter((it) => it?.key !== obj?.key);
      return [...removedElem, obj];
    });
  };

  const renderFilterContent = () => {
    return (
      filteringOptions?.length > 0 && (
        <>
          {filteringOptions?.map((it) => (
            <RenderCollapsablePanel
              title={it?.label}
              selectedOptions={selectedItems}
              selectingOptions={it?.options}
              selectedOptionsCallbackFn={getSelectedOptionsWithKey}
            />
          ))}
          <div className="filter-footer d-flex justify-content-between">
            <LookButton className="reset" onClick={onResetClickHandler}>
              Reset
            </LookButton>
            <LookButton type="primary" className="apply" onClick={onApplyClickHandler}>
              Apply
            </LookButton>
          </div>
        </>
      )
    );
  };

  const renderFilterTitle = () => (
    <div className="filter-popover">
      <div className="d-flex">Filter</div>
      <div className="d-flex">
        <ActionIconRender iconType="close" onClick={onFilterCloseHandler} />
      </div>
    </div>
  );

  return (
    <Popover
      placement="bottomRight"
      overlayClassName="popstyle"
      title={renderFilterTitle()}
      content={renderFilterContent()}
      open={isPopoverOpened}
    >
      <ActionIconRender
        iconType="filter"
        className={selectedFilters?.length > 0 || isPopoverOpened ? "text-primary" : null}
        onClick={onFilterOpenHandler}
      />
    </Popover>
  );
};

export default CommentFilters;
