import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Segmented,
  Select,
  Space,
  Switch,
} from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookTextInput from "components/forms/LookTextInput";
import { useLaunchContext } from "components/Launch/Context";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import LookDrawer from "components/LookDrawer";
import LookPagination from "components/LookPagination";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import cx from "cx";
import { Formik } from "formik";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { CreativeSelectionKey_Description } from "pages/dashboardPages/VS/VSContent/CreativesDrawer/AdGroup";
import { useEffect, useRef, useState } from "react";
import {
  CT_ADD_DESC,
  CT_DESC_BY_CATEGORY,
  GQL_ADD_FEATURED_DESC,
  GQL_CT_DELETE_DESCRIPTIONS,
} from "shared/gql/gqlSchema/creativeGql";
import { PlusOutlined } from "@ant-design/icons";
import { GQL_CHECK_CREATIVES, GQL_CL_DELETE_GROUPS } from "shared/gql/gqlSchema/mediaGql";
import { CREATIVE_TYPE_DESCRIPTION } from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";
import * as yup from "yup";
import useAiHooks from "../hooks/useAiHooks";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import { useSelector } from "react-redux";
import LookModal, { useLookModal } from "components/LookModal";
import TagModal from "../CreativeUpload/TagModal";
import { FEATURED, MY_DESC } from "pages/dashboardPages/Creatives/creativesEnum";
import {
  GQL_API_RESPONSE_STATUS_FAILURE,
  GQL_API_RESPONSE_STATUS_SUCCESS,
  GQL_API_RESPONSE_STATUS_WARNING,
} from "shared/SharedKeys";
import { RECORD_UPDATED } from "shared/enum/apiResponse";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import ForceDeleteModal from "../ForceDeleteModal";

function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    btnText = "Add",
    loading = false,
    label = "",
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="description"
                label={label}
                type="textarea"
                className="look-form-input"
                placeholder={"Enter the " + label?.toLowerCase()}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default function Description(props) {
  const {
    id,
    onClose = () => {},
    persistKey,
    groupSelection = false,
    selectedCreatives,
    handleSelectedCreatives,
    setSelectedData,
    selectedData,
    campaignIndex,
    adSetindex,
    launch,
    name = "",
    vertical,
    serviceType,
    isKeyword,
    category,
    nickname,
    language_code,
    productId,
    categoryId,
    categoryName,
    subCategory,
    subCategoryId,
    verticalId,
    loading_descriptionAIData,
    descriptionAIData,
    isTemp,
    findCommonDynamicAds = () => {},
  } = props;
  const { handleAdgroup, campaignObject } = useLaunchContext();
  const category_Id = categoryId;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRange } = userSettings || {};
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [tags, setTags] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [tagsData, setTagsData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [searchMode, setSearchMode] = useState("normal");
  const [visibleForceDeleteModal, setVisibleForceDeleteModal] = useState(false);
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const [showAddDesc, setShowAddDesc] = useState(false);
  const [createdbyMe, setCreatedByMe] = useState("All");

  const onChange = (checked) => {
    setCreatedByMe(checked);
  };
  const { Search } = Input;
  const label = "Description";

  const [recordData, setRecordData] = useState([]);
  const queryVars = {
    productId,
    categoryId: category_Id,
    createdByMe: createdbyMe === MY_DESC,
    page: currentPage,
    size: pageSize,
    // FOR FUTURE USE
    // fromDate: dateRange?.rangeDates[0],
    // toDate: dateRange?.rangeDates[1],
    featuredDescs: createdbyMe === FEATURED,
  };
  const variables = {
    productId,
    categoryId: category_Id,
    categoryName,
  };
  // let queryVars = {
  //   productId,
  //   // imageType: creativeType,

  //   createdByMe: createdbyMe,
  //   categoryId: categoryId,
  //   inputs: { page: currentPage, size: pageSize },
  // };
  // if (serviceType === SERVICE_TYPE_SAS) {
  //   queryVars = isKeyword
  //     ? {
  //         ...queryVars,
  //         subCategoryId: id,
  //         categoryId: category?.id,
  //       }
  //     : { ...queryVars, verticalId: vertical?.id };
  // }

  const [fetchDesc, { data, loading: loading_fetch, refetch }] = useCustomLazyQuery(
    CT_DESC_BY_CATEGORY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...queryVars,
      },
      onCompleted: (e) => {
        const element = e?.ctGetCreativeDescs;
        if (element?.status === "SUCCESS") {
          setTotalCount(e?.ctGetCreativeDescs?.pageInfo?.totalRecords);
          setRecordData(element?.data);
        } else {
          openNotification({ type: "error", message: e?.ctGetCreativeDescs?.message });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: "Failed to load Descriptions" });
      },
    }
  );
  useEffect(() => {
    fetchDesc();
  }, []);

  const [ctAddDescription, { loading: loading_update }] = useCustomMutation(CT_ADD_DESC, {
    variables: { productId },
    onCompleted: (e) => {
      const element = e?.ctAddCreativeDescs;

      const { status, data, message } = element;

      if (status === "SUCCESS") {
        const { addedCreatives, alreadyPresent } = data?.[0] || {};

        setShowAddDesc(false);
        resetFormRef.current();
        let messages = message;
        if (alreadyPresent?.length > 0) {
          // const finalData = { description: alreadyPresent[0] };
          // setRecordData([{ ...finalData }, ...recordData]);
          refetch();
          messages = `Creatives ${alreadyPresent[0]?.description} is already present`;
          openNotification({ type: "error", message: messages });
        } else {
          // const finalData = { description: addedCreatives?.[0] };

          // setRecordData([...recordData, { ...finalData }]);
          refetch();
          openNotification({ type: "success", message: messages });
        }
      } else {
        openNotification({ type: "error", message, key: "desc-creatives" });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Failed to add decription",
        key: "desc-creatives",
      });
    },
  });

  const resetFormRef = useRef(() => {});
  const [selectedDesc, setSelectedDesc] = useState([]);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => onOpenDeleteModal(e),
    },
    {
      option: "important",
      title: "Mark Favourite",
      onClick: (e) =>
        ctAddFeatured({
          variables: { descs: e, productId, categoryId, categoryName },
        }),
    },
  ];
  const [visibleDescDrawer, setVisibleDescDrawer] = useState(false);
  const onCloseDescDrawer = () => {
    setVisibleDescDrawer(false);
    setSelectedDesc([]);
  };
  const drawerAddDesc = {
    title: "Add Descriptions",
    icon: () => <ActionIconRender className="mr-10" iconType="font-colors" />,
    size: 1200,
    visible: visibleDescDrawer,
    onClose: onCloseDescDrawer,
  };
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const onSubmitDeleteModal = () => {
    deleteCall({
      variables: {
        descIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: false,
      },
    });
  };
  const onSubmitForceDeleteModal = () => {
    deleteCall({
      variables: {
        descIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: true,
      },
    });
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };
  const onCloseForceDeleteModal = () => {
    setDeleteModalPropsData([]);
    setVisibleForceDeleteModal(false);
  };
  const tagDataExtractor = (id) => {
    const tagsArray = recordData?.filter((data) => data?.description?.id === id)?.[0]?.description
      ?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };
  // let variables = {
  //   categoryId: id,
  //   categoryName: nickname ? `${nickname}-${language_code}` : name,
  // };

  // if (serviceType === SERVICE_TYPE_SAS) {
  //   variables = isKeyword
  //     ? {
  //         ...variables,
  //         subCategory: { subCategoryId: id, subCategoryName: name },
  //         categoryName: category?.name,
  //         categoryId: category?.id,
  //       }
  //     : { ...variables, verticalId: vertical?.id };
  // }
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;

    ctAddDescription({
      variables: { ...variables, descs: values?.description },
    });
  }
  const initialValues = { description: "" };
  const validationSchema = yup.object({
    description: yup.string().required().label(label),
  });
  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    onClose: () => {
      setShowAddDesc(false);
    },
    label,
  };

  const initialColumns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 130,
      minWidth: 130,
      fixed: "left",
    },
  ];

  const renderData = [];
  const descriptionData = [];

  recordData?.forEach((element, index) => {
    renderData.push({ ...element, key: index });
  });
  recordData?.forEach(({ description }, index) => {
    descriptionData.push(description);
  });

  const [deleteCall, { loading: deleteLoader }] = useCustomMutation(GQL_CT_DELETE_DESCRIPTIONS, {
    onCompleted(e) {
      const element = e?.ctDeleteCreativeDesc;
      setDeleteModalVisible(false);
      if (element?.status === "SUCCESS") {
        refetch();

        openNotification({ type: "success", message: element?.message });
        selectedRowsClear();
      } else if (element?.status === GQL_API_RESPONSE_STATUS_WARNING && isUmsAgencyAdmin) {
        setVisibleForceDeleteModal(true);
      } else {
        openNotification({
          type: "error",
          message: element?.message || "Failed to delete records",
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Failed to delete records",
      });
    },
  });
  const [ctAddFeatured, { loading: loading_featured }] = useCustomMutation(GQL_ADD_FEATURED_DESC, {
    onCompleted: (e) => {
      const element = e?.ctAddFeaturedCreativeDescs;
      if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
        openNotification({
          type: "success",
          message: element?.message || RECORD_UPDATED,
          key: "success",
        });
        refetch();
        closeTagModal();
        setTags([]);
      } else if (element?.status === GQL_API_RESPONSE_STATUS_FAILURE) {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  function selectedRowsClear() {
    setSelectedDesc([]);
    setTags([]);
  }
  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });
  const bulkActionProps = {
    selectedRows: selectedDesc,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const deleteModalProps = {
    title: "Delete Creatives",
    message: "Do you really want to delete this creatives?",
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };
  const forceDeleteModalProps = {
    title: "Delete Creatives",
    visible: visibleForceDeleteModal,
    onSubmit: onSubmitForceDeleteModal,
    onCancel: onCloseForceDeleteModal,
    okokText: "Delete",
    loader: deleteLoader,
  };
  const tableMainProps = {
    refetch,
    loading: loading_fetch,
    persistKey,
    tableProps: { initialColumns, recordData: renderData },
    filterProps: { persistSearch: false },
  };
  const debugContent = [
    {
      isRow: true,
      contentObjects: [
        { recordData },

        //  { data }
        { renderData },
      ],
    },
  ];
  function callApiforAi(selectedDesc) {
    ctAddDescription({
      variables: { ...variables, descriptions: selectedDesc },
    });
    onCloseDescDrawer();
  }
  const hooksProps = {
    loading_AIData: loading_descriptionAIData,
    creativesAIData: descriptionAIData,
    setSelectedData: setSelectedDesc,
    selectedData: selectedDesc,
    creativeSelectionKey: CreativeSelectionKey_Description,
    loading: loading_fetch,
    onClose,
    addData: callApiforAi,
    label: "descriptions",
  };
  const aiGeneratedDrawer = useAiHooks({ ...hooksProps });

  return (
    <div className={!groupSelection && !launch ? cx("p-20") : ""}>
      {visilbleTagModal &&
        tagModal(
          <TagModal
            variables={{
              productId,
              categoryId,
              categoryName,
            }}
            files={deleteModalPropsData}
            creatives_refetch={refetch}
            setTags={setTags}
            tagsData={tagsData}
            setTagsData={setTagsData}
            tags={tags}
            closeTagModal={closeTagModal}
            selectedRowsClear={selectedRowsClear}
            creativeType="DESCRIPTION"
          />
        )}
      <div className="d-flex justify-content-between mt-10">
        <div className={`d-flex creative-table-search ${groupSelection ? "mb-20" : ""}`}>
          <div
            className={cx("look-table-search", "d-flex")}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #404040",
            }}
          >
            {searchMode === "tags" ? (
              <Select
                mode={"tags"}
                allowClear={false}
                bordered={false}
                placeholder={"Search by tags"}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setSearchItem(e);
                }}
                maxTagCount="responsive"
                showArrow={false}
              />
            ) : (
              <Search
                className="creative-tag-search"
                placeholder={`Search by description`}
                suffix={null}
                onSearch={(e) => {
                  fetchDesc({
                    variables: {
                      search: e,
                    },
                  });
                }}
                onChange={(e) => {
                  setSearchItem(e?.target?.value);
                }}
                style={{
                  width: "100%",
                }}
              />
            )}
            {/* add tags icon */}
            <LookButton
              tooltipTitle={
                searchMode === "tags"
                  ? "Click here to switch to normal mode"
                  : "Click here to search"
              }
              className="filter-btn ml-8"
              iconType={searchMode === "tags" ? "close" : "tagSwitch"}
              onClick={() => {
                setSearchMode((prevMode) => (prevMode === "tags" ? "normal" : "tags"));
              }}
            />
            <LookButton
              tooltipTitle="Click here to search"
              className="filter-btn"
              iconType="search"
              onClick={() => {
                fetchDesc({
                  variables: {
                    ...(searchMode !== "tags" ? { search: searchItem } : { tags: searchItem }),
                  },
                });
              }}
            />
          </div>
          {/* <LookButton
            tooltipTitle="Refetch"
            className="filter-btn ml-8"
            size="small"
            iconType="reload"
            disabled={loading}
            iconTypeProps={{ spin: loading }}
            onClick={() => refetch()}
          /> */}
          <Segmented
            className="custom-segmented ml-8"
            options={["All", MY_DESC, FEATURED]}
            value={createdbyMe}
            onChange={onChange}
          />
        </div>
        <div className="d-flex">
          {/* <DateRangePersist isTimezone={false} /> */}

          <LookButton
            // tooltipTitle="Take snapshots"
            iconType="image"
            className="ml-12"
            onClick={() => {
              setVisibleDescDrawer(true);
            }}
          >
            AI Generated
          </LookButton>
        </div>
      </div>
      <div className={cx("creative-text-list-view", { launch, groupSelection })}>
        <>
          <LookDrawer {...drawerAddDesc}>{visibleDescDrawer && aiGeneratedDrawer}</LookDrawer>
          {(!launch || !groupSelection) && (
            <>
              <LookTableBulkAction {...bulkActionProps} />
              <LookTableRecordDeleteModal {...deleteModalProps} />
            </>
          )}
          {visibleForceDeleteModal && (
            <LookModal {...forceDeleteModalProps}>
              <ForceDeleteModal />
            </LookModal>
          )}
          {
            <div className="mt-20">
              {showAddDesc ? (
                <MainForm {...formProps} />
              ) : (
                <div
                  className={cx("look-radio-ad", "d-flex")}
                  style={{ alignItems: "center", color: "#696969" }}
                  onClick={() => setShowAddDesc(true)}
                >
                  <div className="mr-10">
                    <PlusOutlined />
                  </div>
                  <div>Click here to add a new descriptions...</div>
                </div>
              )}
            </div>
          }

          <Checkbox.Group
            onChange={(e) => {
              if (launch && !groupSelection) {
                const args = !isTemp ? [campaignIndex, adSetindex] : [];
                handleAdgroup({ description: e }, ...args);
              } else if (groupSelection) {
                handleSelectedCreatives(CreativeSelectionKey_Description, e);
                setSelectedData({
                  ...selectedData,
                  description: [
                    ...(selectedData?.description || []),
                    {
                      id: e,
                      body: renderData?.find((i) => i.description?.id === e)?.description,
                    },
                  ],
                });
              } else {
                setSelectedDesc(e);
              }
            }}
            value={
              groupSelection
                ? selectedCreatives?.[CreativeSelectionKey_Description]
                : launch
                ? campaignObject[campaignIndex]?.adSets[adSetindex]?.adCreatives?.description
                : selectedDesc
            }
            name={CreativeSelectionKey_Description}
            style={{ width: "100%" }}
          >
            {loading_fetch ? (
              <Loader center elementCenter title="Loading Descriptions..." />
            ) : (
              renderData?.map(({ description }) => (
                <div
                  className={cx("look-radio-ad flex justify-content-between", {
                    active:
                      groupSelection || launch
                        ? isTemp
                          ? findCommonDynamicAds(CreativeSelectionKey_Description, description?.id)
                          : selectedCreatives?.[CreativeSelectionKey_Description]?.includes(
                              description?.id
                            )
                        : selectedDesc?.includes(id),
                  })}
                >
                  <Checkbox
                    value={
                      groupSelection
                        ? description?.id
                        : launch
                        ? description?.description
                        : description?.id
                    }
                  >
                    {description?.description}
                  </Checkbox>
                  <div className="flex">
                    <ActionIconRender
                      style={{ marginRight: "8px", color: "white" }}
                      iconType="tagSwitch"
                      onClick={() => {
                        tagDataExtractor(description?.id);
                        openTagModal();
                        setDeleteModalPropsData([
                          { id: description?.id, tags: description?.tags || [] },
                        ]);
                      }}
                    />
                    <ActionIconRender
                      style={{ marginRight: "8px", color: "white" }}
                      iconType="delete"
                      onClick={() => onOpenDeleteModal(description?.id)}
                    />
                  </div>
                </div>
              ))
            )}

            {/* </div> */}
          </Checkbox.Group>
          {/* <LookTable {...tableMainProps} /> */}
        </>

        {/* !launch && (
            <>
              <Radio.Group
                onChange={(e) => {
                  handleSelectedCreatives(CreativeSelectionKey_Description, e.target.value);
                  setSelectedData({
                    ...selectedData,
                    description: {
                      id: e.target.value,
                      description: renderData?.find((i) => i.id === e.target.value)?.description,
                    },
                  });
                }}
                value={selectedCreatives?.[CreativeSelectionKey_Description]}
                name={CreativeSelectionKey_Description}
                style={{ width: "100%" }}
              >
                {renderData?.map(({ id, description }) => (
                  <div
                    className={cx("look-radio-ad", {
                      active: selectedCreatives?.[CreativeSelectionKey_Description] === id,
                    })}
                  >
                    <Radio value={id}>{description}</Radio>
                  </div>
                ))}

                {/* </div> */}
      </div>
      <div className={`p-8 look-bottom-pagination ${cx({ "mb-0": launch })}`}>
        {/* <Pagination
          defaultCurrent={1}
          total={totalCount}
          pageSize={pageSize}
          current={currentPage}
          onChange={(page, size) => handlePageChange(page, size)}
          pageSizeOptions={["10", "20", "50"]}
          showSizeChanger
        /> */}

        <LookPagination
          totalCount={totalCount}
          onChange={handlePageChange}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </div>
      <DebugPre content={debugContent} />
    </div>
  );
}
