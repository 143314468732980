import { useEffect, useState } from "react";
import alasql from "alasql";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import LookTable from "components/LookTable";
import {
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  VS_IS_FORECAST_SET,
  VS_IS_FORECAST_UNSET,
} from "actions/actionTypes";
import { VS_USER_LIVE_REPORT } from "shared/gql/gqlSchema/viralSparksGql";
import SelectInput from "components/forms/SelectInput";
import DebugPre from "components/DebugPre";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { API_STORE_VIRALSPARKS_ADVERTISE } from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
import useColumns from "../useColumns";
import { numberParse } from "utils";
import { Switch, Tooltip } from "antd";
import useVSAdvertiserHandler from "../../../useVSAdvertiserHandler";
import { useDispatch } from "react-redux";
import { GET_F360_USER_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";

export default function VSUserReport(props) {
  const params = useParams();
  const { userReport = false } = props;
  const userSettings = useSelector((state) => state?.userSettings);
  const { userSettingData } = userSettings || {};
  const { vsAdvertiser = [] } = userSettingData || {};
  const { columns } = useColumns({});
  const {
    selectedVSAdvertiserIsAll = true,
    selectedVSAdvertiser = "",
    vsIsForecast,
  } = useSelector((state) => state?.vsReducer) || {};

  const { apiCall: vsAdvertiserFetch = () => {}, refetch: vsAdvertiserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_ADVERTISE
  );
  const dispatch = useDispatch();
  const { dateRangeTimezone } = useSelector((state) => state?.userSettings) || {};
  const { handleAdvertiserSelection } = useVSAdvertiserHandler();
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const [spendReport, setSpendReport] = useState([]);
  const userIdForSpendReport = userReport ? { userId: params?.userId } : {};
  const isAdvertiser = selectedVSAdvertiserIsAll ? {} : { advertiser_id: selectedVSAdvertiser };
  const [userForecastReport, setUserForecastReport] = useState([]);
  const [userMinReport, setUserMinReport] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);

  useEffect(() => {
    vsAdvertiserFetch();
  }, []);

  const [
    vs_userLiveReportsFromTo,
    {
      data: data_GET_REPORT_DATA,
      loading: loading_GET_REPORT_DATA,
      refetch: refetch_GET_REPORT_DATA,
    },
  ] = useCustomLazyQuery(VS_USER_LIVE_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_userLiveReportsFromTo;
      if (element) {
        vsIsForecast
          ? setUserForecastReport(
              element?.map((element) => ({ ...element, revCampaignId: element?.campaign_id }))
            )
          : setUserMinReport(
              element?.map((element) => ({ ...element, revCampaignId: element?.campaign_id }))
            );
      }
    },
  });

  const {
    data: data_GET_REPORT_BY_USER,
    loading: loading_GET_REPORT_BY_USER,
    error: error_GET_REPORT_BY_USER,
    refetch: refetch_GET_REPORT_BY_USER,
  } = useCustomQuery(GET_F360_USER_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.fb360_userReporting;
      if (element) {
        setSpendReport(
          element?.map((item) => ({
            ...item,
            name: item?.campaign_name,
            campaignId: item?.campaign_id,
            adAccountId: item?.adaccount_id,
            clicksFB: item?.inline_link_clicks,
            spendFB: item?.spend,
          }))
        );
      }
    },
    variables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      is_detailed: true,
      // ...userIdForSpendReport,
    },
  });

  const loading = loading_GET_REPORT_DATA || loading_GET_REPORT_BY_USER;
  const refetch = () => {
    refetch_GET_REPORT_DATA();
    refetch_GET_REPORT_BY_USER();
    setUserMinReport([]);
    setUserForecastReport([]);
  };

  useEffect(() => {
    if (userForecastReport.length === 0 && vsIsForecast) {
      vs_userLiveReportsFromTo({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone.value,
          is_forecast: vsIsForecast,
          platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
          ...isAdvertiser,
        },
      });
    } else if (userMinReport.length === 0 && !vsIsForecast) {
      vs_userLiveReportsFromTo({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone.value,
          is_forecast: vsIsForecast,
          platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
          ...isAdvertiser,
        },
      });
    }
    setFirstFetch(false);
  }, [vsIsForecast]);

  useEffect(() => {
    if (!firstFetch) {
      setUserMinReport([]);
      setUserForecastReport([]);
      vs_userLiveReportsFromTo({
        variables: {
          from: dateRangeTimezone?.rangeDates[0],
          to: dateRangeTimezone?.rangeDates[1],
          timezone: dateRangeTimezone?.timeZone.value,
          is_forecast: vsIsForecast,
          platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
          ...isAdvertiser,
        },
      });
    }
  }, [dateRangeTimezone, selectedVSAdvertiser]);

  const initialColumns = [
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",

      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return <LookTableColumnRender title={value} isProfit={PROFIT} />;
      },
      width: 280,
      minWidth: 280,
      fixed: "left",
    },
    ...columns,
  ];

  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    let query = "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.user_id = b.userId";

    finalMerge = alasql(query, [vsIsForecast ? userForecastReport : userMinReport, spendReport]);

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        impressions = 0 | "0",
        result = 0,
        user_revenue = 0,
        sessions = 0 | "0",
        pageviews = 0 | "0",
        status = null,
        user_name = "",
        user = {},
      } = element;

      const spendUpdate = spendFB?.toFixed(2);
      // const revenueUpdate = (TotalRevenue * 0.94).toFixed(2);

      const revenueUpdate = user_revenue;
      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(revenueUpdate) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(result) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        key: index,
        TotalRevenue: numberParse(revenueUpdate),
        clicksFB,
        result,
        impressionsFB,
        spendFB: spendUpdate,
        PROFIT: numberParse(PROFIT),
        userName: user_name || user?.name,
        PROFIT_VIEW: numberParse(PROFIT, { toFixed: true }),
        CPC: numberParse(CPC),
        CPC_VIEW: numberParse(CPC, { toFixed: true, toFixedUpTo: 3 }),
        CPC_FB: numberParse(CPC_FB),
        CPC_FB_VIEW: numberParse(CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
        ROI: numberParse(ROI),
        ROI_VIEW: numberParse(ROI, { toFixed: true }),
        EPC: numberParse(EPC),
        EPC_VIEW: numberParse(EPC, { toFixed: true, toFixedUpTo: 3 }),
        CTR: numberParse(CTR),
        CTR_VIEW: numberParse(CTR, { toFixed: true }),
        DISC: numberParse(DISC),
        DISC_VIEW: numberParse(DISC, { toFixed: true }),
        IPP: numberParse(IPP),
        RPM: numberParse(RPM),
        PRPM: numberParse(PRPM),
        PRPM_VIEW: numberParse(PRPM, { toFixed: true }),
        CPR: numberParse(CPR),
        CPR_VIEW: numberParse(CPR, { toFixed: true }),
        PPS: numberParse(PPS),
        PPS_VIEW: numberParse(PPS, { toFixed: true }),
        CPM: numberParse(CPM),
        CPM_VIEW: numberParse(CPM, { toFixed: true }),
        status,
      });
    });
  }

  const finalFilterData = finalMergeData?.filter(({ userName = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (userName?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_result = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    result = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageviews = 0,
  } of finalFilterData) {
    total_spendFB += JSON.parse(spendFB || 0);
    total_clicksFB += JSON.parse(clicksFB || 0);
    total_impressionsFB += JSON.parse(impressionsFB || 0);
    total_result += JSON.parse(result || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageviews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicksFB;
  const total_ROI = (total_TotalRevenue / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicksFB / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_result;
  const total_DISC = total_sessions / total_clicksFB;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const summaryData = {
    TotalRevenue: numberParse(total_TotalRevenue, { toFixed: true }),
    spendFB: numberParse(total_spendFB, { toFixed: true }),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: numberParse(total_ROI, { toFixed: true }),
    EPC: numberParse(total_EPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC: numberParse(total_CPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC_FB: numberParse(total_CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    result: numberParse(total_result),
    CPR: numberParse(total_CPR, { toFixed: true }),
    sessions: numberParse(total_sessions),
    clicksFB: numberParse(total_clicksFB),
    DISC: numberParse(total_DISC, { toFixed: true }),
    IPP: numberParse(total_IPP, { toFixed: true }),
    RPM: numberParse(total_RPM, { toFixed: true }),
    PRPM: numberParse(total_PRPM, { toFixed: true }),
    PPS: numberParse(total_PPS, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
  };

  function handleForecast(e) {
    dispatch({ type: e ? VS_IS_FORECAST_SET : VS_IS_FORECAST_UNSET });
  }

  const rightOptions = (
    <>
      <div className="mr-12">
        <Tooltip
          title={`${vsIsForecast ? "Forecast" : "Min"} Revenue - Switch to ${
            vsIsForecast ? "Min" : "Forecast"
          }`}
        >
          <Switch checked={vsIsForecast} onChange={handleForecast} />
        </Tooltip>
      </div>
      <div style={{ width: 150 }}>
        <Tooltip title="Advertiser">
          <span>
            <SelectInput
              search
              name="selectedAdvertiser"
              className="mb-0"
              placeholder="Select Advertiser"
              value={selectedVSAdvertiserIsAll ? "all" : selectedVSAdvertiser}
              options={[
                { name: "All", value: "all" },
                ...vsAdvertiser?.map(({ id, name }) => ({ name, value: id })),
              ]}
              onChange={(e) => handleAdvertiserSelection(e, true)}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    rightOptions,
    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    // uniqueKey,
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ spendReport }] },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
