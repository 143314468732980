import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "../MainForm";
import moment from "moment";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { ADD_X_REVENUE } from "shared/gql/gqlSchema/leadgenGql";

export default function AddXRevenue(props) {
  const dateFormat = "YYYY-MM-DD";
  const { refetch = () => {} } = props;
  const defaultselectedDate = moment();
  const [isError, setIsError] = useState("");

  const [selectedDate, setSelectedDate] = useState(defaultselectedDate.format(dateFormat));

  const dateHandle = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const resetFormRef = useRef(() => {});
  const [addXRevenue, { loading }] = useCustomMutation(ADD_X_REVENUE, {
    onCompleted(e) {
      refetch();
      resetFormRef.current();
      openNotification({ type: "success", message: "Record Added Successfully" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const { xRevenue } = values;
    addXRevenue({
      variables: {
        ...values,
        date: selectedDate,
        xRevenue: parseFloat(xRevenue),
      },
    });
    clearError();
  }

  const initialValues = {
    xRevenue: "",
  };

  const validationSchema = yup.object({
    xRevenue: yup.string().required().label("X Revenue"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    selectedDate,
    dateHandle,
    defaultselectedDate,
  };

  return (
    <>
      <MainFrom {...formProps} />
    </>
  );
}
