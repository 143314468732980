export const ALL = "All";
export const ENABLE = "ENABLE";
export const DISABLE = "DISABLE";
export const BUY = "BUY";
export const ADD = "ADD";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

export const ENABLE_DISABLE_ENUM = [
  { name: "Active", value: ENABLE },
  { name: "Paused", value: DISABLE },
];

export const ALL_ENABLE_DISABLE_ENUM = [{ name: "All", value: ALL }, ...ENABLE_DISABLE_ENUM];

export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";

export const SPEND_INCREASE_PERCENT_SAS = 1.05;
export const SPEND_INCREASE_PERCENT_VS = 1.05;
export const SPEND_INCREASE_PERCENT_LEADGEN = 1.05;
