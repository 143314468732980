import { gql } from "@apollo/client";

const AWIC_COMPANIES_RESPONSE = gql`
  fragment AwicCompany on Awic_Company {
    id
    name
    address
    country {
      id
      name
    }
    tax_id
  }
`;

export const GET_CMS_AWIC_COMPANIES = gql`
  ${AWIC_COMPANIES_RESPONSE}
  query CMS_Awic_Companies {
    CMS_Awic_Companies {
      ...AwicCompany
    }
  }
`;

export const ADD_CMS_AWIC_COMPANY = gql`
  ${AWIC_COMPANIES_RESPONSE}
  mutation CMS_Create_Awic_Company(
    $address: String
    $country: String
    $name: String
    $tax_id: String
  ) {
    CMS_Create_Awic_Company(address: $address, country: $country, name: $name, tax_id: $tax_id) {
      ...AwicCompany
    }
  }
`;

export const UPDATE_CMS_AWIC_COMPANY = gql`
  ${AWIC_COMPANIES_RESPONSE}
  mutation CMS_Update_Awic_Company(
    $id: ID!
    $address: String
    $country: String
    $name: String
    $tax_id: String
  ) {
    CMS_Update_Awic_Company(
      id: $id
      address: $address
      country: $country
      name: $name
      tax_id: $tax_id
    ) {
      ...AwicCompany
    }
  }
`;

export const DELETE_CMS_AWIC_COMPANY = gql`
  mutation CMS_Delete_Awic_Company($id: [ID!]) {
    CMS_Delete_Awic_Company(id: $id)
  }
`;

const AWIC_NETWORKS_RESPONSE = gql`
  fragment AwicNetwork on Awic_Network {
    id
    name
    description
    type
    categories {
      id
      name
    }
  }
`;

export const GET_CMS_AWIC_NETWORKS = gql`
  ${AWIC_NETWORKS_RESPONSE}
  query CMS_Awic_Networks {
    CMS_Awic_Networks {
      ...AwicNetwork
    }
  }
`;

export const ADD_CMS_AWIC_NETWORK = gql`
  ${AWIC_NETWORKS_RESPONSE}
  mutation CMS_Create_Awic_Network(
    $description: String
    $name: String
    $type: Network_Type
    $category_id: [Int]
  ) {
    CMS_Create_Awic_Network(
      description: $description
      name: $name
      type: $type
      category_id: $category_id
    ) {
      ...AwicNetwork
    }
  }
`;

export const UPDATE_CMS_AWIC_NETWORK = gql`
  ${AWIC_NETWORKS_RESPONSE}
  mutation CMS_Update_Awic_Network(
    $description: String
    $id: ID!
    $name: String
    $type: Network_Type
    $category_id: [Int]
  ) {
    CMS_Update_Awic_Network(
      description: $description
      id: $id
      name: $name
      type: $type
      category_id: $category_id
    ) {
      ...AwicNetwork
    }
  }
`;

export const DELETE_CMS_AWIC_NETWORK = gql`
  mutation CMS_Delete_Awic_Network($id: [ID!]) {
    CMS_Delete_Awic_Network(id: $id)
  }
`;

const AWIC_NETWORK_ACCOUNTS_RESPONSE = gql`
  fragment AwicNetworkAccount on Awic_Network_Account {
    id
    name
    description
    email_id
    store_everflow
    domains {
      id
      name
    }
    awic_company {
      id
      name
    }
    awic_network {
      id
      name
    }
    awic_payment_detail {
      id
      name
    }
    awic_point_of_contact {
      id
      name
    }
  }
`;

export const GET_AWIC_NETWORK_ACCOUNTS = gql`
  ${AWIC_NETWORK_ACCOUNTS_RESPONSE}
  query CMS_Awic_Network_Accounts {
    CMS_Awic_Network_Accounts {
      ...AwicNetworkAccount
    }
  }
`;

export const ADD_AWIC_COMPANY_NETWORK_ACCOUNT = gql`
  ${AWIC_NETWORK_ACCOUNTS_RESPONSE}
  mutation CMS_Create_Awic_Network_Account(
    $company_id: Int
    $description: String
    $domain_id: [Int]
    $email_id: String
    $name: String
    $network_id: Int
    $payment_id: Int
    $poc_id: Int
    $store_everflow: String
  ) {
    CMS_Create_Awic_Network_Account(
      company_id: $company_id
      description: $description
      domain_id: $domain_id
      email_id: $email_id
      name: $name
      network_id: $network_id
      payment_id: $payment_id
      poc_id: $poc_id
      store_everflow: $store_everflow
    ) {
      ...AwicNetworkAccount
    }
  }
`;

export const UPDATE_AWIC_COMPANY_NETWORK_ACCOUNT = gql`
  ${AWIC_NETWORK_ACCOUNTS_RESPONSE}
  mutation CMS_Update_Awic_Network_Account(
    $id: ID!
    $company_id: Int
    $description: String
    $domain_id: [Int]
    $email_id: String
    $name: String
    $network_id: Int
    $payment_id: Int
    $poc_id: Int
    $store_everflow: String
  ) {
    CMS_Update_Awic_Network_Account(
      id: $id
      company_id: $company_id
      description: $description
      domain_id: $domain_id
      email_id: $email_id
      name: $name
      network_id: $network_id
      payment_id: $payment_id
      poc_id: $poc_id
      store_everflow: $store_everflow
    ) {
      ...AwicNetworkAccount
    }
  }
`;

export const DELETE_CMS_AWIC_NETWORK_ACCOUNT = gql`
  mutation CMS_Delete_Awic_Network_Account($id: [ID!]) {
    CMS_Delete_Awic_Network_Account(id: $id)
  }
`;

export const GET_AWIC_PAYMENT_DETAILS_ID_NAME = gql`
  query CMS_Awic_Payment_Details {
    CMS_Awic_Payment_Details {
      id
      name
    }
  }
`;

export const GET_AWIC_POINT_OF_CONTACTS_ID_NAME = gql`
  query CMS_Awic_Point_Of_Contacts {
    CMS_Awic_Point_Of_Contacts {
      id
      name
    }
  }
`;

export const GET_CMS_AWIC_COMPANIES_ID_NAME = gql`
  query CMS_Awic_Companies {
    CMS_Awic_Companies {
      id
      name
    }
  }
`;

export const GET_CMS_AWIC_NETWORKS_ID_NAME = gql`
  query CMS_Awic_Networks {
    CMS_Awic_Networks {
      id
      name
    }
  }
`;

const AWIC_PAYMENT_DETAILS_RESPONSE = gql`
  fragment AwicPaymentDetails on Awic_Payment_Details {
    id
    name
    bank_address
    bank_details
    beneficiary_name
    awic_companies {
      id
      name
    }
  }
`;

export const GET_AWIC_PAYMENT_DETAILS = gql`
  ${AWIC_PAYMENT_DETAILS_RESPONSE}
  query CMS_Awic_Payment_Details {
    CMS_Awic_Payment_Details {
      ...AwicPaymentDetails
    }
  }
`;

export const ADD_AWIC_COMPANY_PAYMENT_DETAILS = gql`
  ${AWIC_PAYMENT_DETAILS_RESPONSE}
  mutation CMS_Create_Awic_Payment_Details(
    $bank_address: String
    $bank_details: String
    $beneficiary_name: String
    $name: String
    $company_id: [Int]
  ) {
    CMS_Create_Awic_Payment_Details(
      bank_address: $bank_address
      bank_details: $bank_details
      beneficiary_name: $beneficiary_name
      name: $name
      company_id: $company_id
    ) {
      ...AwicPaymentDetails
    }
  }
`;

export const UPDATE_AWIC_COMPANY_PAYMENT_DETAILS = gql`
  ${AWIC_PAYMENT_DETAILS_RESPONSE}
  mutation CMS_Update_Awic_Payment_Details(
    $id: ID!
    $bank_address: String
    $bank_details: String
    $beneficiary_name: String
    $name: String
    $company_id: [Int]
  ) {
    CMS_Update_Awic_Payment_Details(
      id: $id
      bank_address: $bank_address
      bank_details: $bank_details
      beneficiary_name: $beneficiary_name
      name: $name
      company_id: $company_id
    ) {
      ...AwicPaymentDetails
    }
  }
`;

export const DELETE_CMS_AWIC_PAYMENT_DETAILS = gql`
  mutation CMS_Delete_Awic_Payment_Details($id: [ID!]) {
    CMS_Delete_Awic_Payment_Details(id: $id)
  }
`;

const AWIC_POINT_OF_CONTACTS_RESPONSE = gql`
  fragment AwicPointOfContact on Awic_Point_Of_Contact {
    id
    name
    email
    position
    awic_network {
      id
      name
    }
  }
`;

export const GET_AWIC_POINT_OF_CONTACTS = gql`
  ${AWIC_POINT_OF_CONTACTS_RESPONSE}
  query CMS_Awic_Point_Of_Contacts {
    CMS_Awic_Point_Of_Contacts {
      ...AwicPointOfContact
    }
  }
`;

export const ADD_AWIC_POINT_OF_CONTACT = gql`
  ${AWIC_POINT_OF_CONTACTS_RESPONSE}
  mutation CMS_Create_Awic_Point_Of_Contact(
    $email: String
    $name: String
    $network_id: Int
    $position: String
  ) {
    CMS_Create_Awic_Point_Of_Contact(
      email: $email
      name: $name
      network_id: $network_id
      position: $position
    ) {
      ...AwicPointOfContact
    }
  }
`;

export const UPDATE_AWIC_POINT_OF_CONTACT = gql`
  ${AWIC_POINT_OF_CONTACTS_RESPONSE}
  mutation CMS_Update_Awic_Point_Of_Contact(
    $id: ID!
    $email: String
    $name: String
    $network_id: Int
    $position: String
  ) {
    CMS_Update_Awic_Point_Of_Contact(
      id: $id
      email: $email
      name: $name
      network_id: $network_id
      position: $position
    ) {
      ...AwicPointOfContact
    }
  }
`;

export const DELETE_CMS_AWIC_POINT_OF_CONTACT = gql`
  mutation CMS_Delete_Awic_Point_Of_Contact($id: [ID!]) {
    CMS_Delete_Awic_Point_Of_Contact(id: $id)
  }
`;

const AWIC_STORE_EVERFLOW_RESPONSE = gql`
  fragment AwicStoreEverflow on Awic_Store_Everflow {
    store_id
    store_name
    everflow_data {
      awic_network_account {
        id
        name
      }
      country {
        id
        name
      }
      domain {
        id
        domain_url
        tracking_domain
      }
      everflow_domain
      everflow_offer
      weight
      is_active
    }
  }
`;

export const GET_CMS_AWIC_STORE_EVERFLOW = gql`
  ${AWIC_STORE_EVERFLOW_RESPONSE}
  query CMS_Awic_Store_Everflow($id: ID!) {
    CMS_Awic_Store_Everflow(id: $id) {
      ...AwicStoreEverflow
    }
  }
`;

// type everflow_input {
//   country_id: ID
//   domain_id: ID
//   everflow_domain: String
//   everflow_offer: String
//   network_account_id: ID
//   weight: String

export const UPDATE_CMS_AWIC_STORE_EVERFLOW = gql`
  ${AWIC_STORE_EVERFLOW_RESPONSE}
  mutation CMS_Update_Awic_Store_Everflow(
    $everflow_input: [everflow_input]
    $store_id: ID!
    $store_name: String
  ) {
    CMS_Update_Awic_Store_Everflow(
      everflow_input: $everflow_input
      store_id: $store_id
      store_name: $store_name
    ) {
      ...AwicStoreEverflow
    }
  }
`;

export const GET_AWIC_NETWORK_ACCOUNTS_ID_NAME = gql`
  query CMS_Awic_Network_Accounts {
    CMS_Awic_Network_Accounts {
      id
      name
    }
  }
`;
