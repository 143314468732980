import { UserOutlined } from "@ant-design/icons";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";

export default function useSrNoColumnHook(props = {}) {
  const {
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    srWidth = 60,
    isCollapsibleRow,
  } = props;

  const mainPaginationData =
    useSelector((state) => state?.userSettings?.mainPagination?.[persistKey]) || {};
  const { page, size } = mainPaginationData;

  const srNoColumn = {
    title: () => "Sr. No.",
    icon: UserOutlined,
    dataIndex: "srNo",
    key: "srNo",
    width: srWidth,
    minWidth: srWidth,
    render: (value, actionProps, index) => {
      const { children, isChild = false } = actionProps;
      const currentCount = (page - 1) * size + index + 1;
      return (
        <LookTableColumnRender
          className={
            isCollapsibleRow ? (isChild ? "pl-30" : children ? "pl-10" : "pl-25") : "pl-10"
          }
          title={isChild ? `${index + 1}` : currentCount}
        />
      );
    },
    fixed: "left",
  };

  return { srNoColumn };
}
