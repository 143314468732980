import { Button, Col, DatePicker, Form, Radio, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";
import { Formik } from "formik";
import React from "react";
import { COMMENTS_PRORITIES } from "shared/enum/commentsKeysAndEnum";

function ClickUpTaskForm(props) {
  const {
    workSpaceUsers,
    initialValues,
    validationSchema,
    handleSubmitForm = () => {},
    handleCloseModal = () => {},
    loadingNewComment = false,
    loadingClickUpTask = false,
  } = props;

  function submitData(values, { setSubmitting }) {
    handleSubmitForm(values);
  }
  return (
    <div>
      {loadingNewComment ? (
        <Row justify="center">
          <Col>
            <Loader />
          </Col>
        </Row>
      ) : (
        <Formik
          onSubmit={submitData}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm, touched, errors }) => (
            <Form layout="vertical">
              <Row gutter={12}>
                <Col span={24}>
                  <SelectInput
                    formik
                    search
                    name="assignedTo"
                    label="Assign to"
                    placeholder="Assign User"
                    // className="look-form-input"
                    style={{ marginBottom: "20px" }}
                    value={values?.assigned}
                    options={workSpaceUsers?.map(({ name, id }) => ({ name, value: id }))}
                    onChange={setFieldValue}
                  />
                </Col>
                <Col span={24}>
                  <LookTextInput
                    formik
                    rows={5}
                    className="mb-0"
                    type="textarea"
                    name="description"
                    label="Description"
                    // className="look-form-input"
                    style={{ marginBottom: "20px" }}
                    placeholder="Enter description"
                    value={values?.description}
                    onChange={setFieldValue}
                  />
                </Col>
                <Col span={24}>
                  <div className="mb-10 " style={{ marginBottom: "20px" }}>
                    <p>Priority</p>
                    <Radio.Group
                      onChange={(e) => setFieldValue("priority", e?.target?.value)}
                      value={values?.priority}
                      name="priority"
                    >
                      <span className="d-flex">
                        {COMMENTS_PRORITIES?.map(({ value, name, color }) => (
                          <Radio style={{ color }} value={value}>
                            {name}
                          </Radio>
                        ))}
                      </span>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="look-form-input">
                    <Form.Item
                      label="Expected Due Date"
                      validateStatus={
                        touched.expectedDueDate && errors.expectedDueDate ? "error" : ""
                      }
                      help={touched.expectedDueDate && errors.expectedDueDate}
                    >
                      <DatePicker
                        name="expectedDueDate"
                        style={{ width: "100%", borderColor: "none", backgroundColor: "none" }}
                        format="YYYY-MM-DD"
                        value={values?.expectedDueDate}
                        onChange={(date, dateString) => {
                          setFieldValue("expectedDueDate", date);
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item>
                    <Button block className="submit-btn" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      block
                      type="primary"
                      className="submit-btn"
                      onClick={handleSubmit}
                      disabled={loadingClickUpTask}
                      loading={loadingClickUpTask}
                    >
                      Create Task
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default ClickUpTaskForm;
