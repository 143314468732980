import { useEffect, useRef, useState } from "react";
import ClickAwayListener from "components/ClickAwayListener";
import LookModal from "components/LookModal";
import PopoverWrapper from "components/popoverWrapper";
import { MoreOutlined } from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";
import moment from "moment";
const { Skeleton, Image, Checkbox, Tooltip } = require("antd");
const { default: LookButton } = require("components/LookButton");
const { default: cx } = require("cx");
const {
  CREATIVE_TYPE_IMAGE,
  CREATIVE_MIME_TYPE_IMAGE,
  CREATIVE_TYPE_AUDIO,
  CREATIVE_TYPE_VIDEO,
  CREATIVE_MIME_TYPE_VIDEO,
  CREATIVE_MIME_TYPE_AUDIO,
} = require("shared/SharedKeys/launchKeys");
export default function MediaItem(props) {
  const {
    selected = false,
    showDrawer = () => {},
    onSelect,
    onOpenDeleteModal = () => {},
    refetch,
    uploading = false,
    creative = {},
    creativeType,
    setImages,
    handleClickDownload = () => {},
    onClick: onClickprops = () => {},
    isRequestView = false,
    isCreativeBuilder = false,
    canEditStatus = false,
    isTemplateForm = false,
    setCreativesImages = () => {},
    selectedData = [],
    tagDataExtractor = () => {},
    openTagModal = () => {},
    setDeleteModalPropsData = () => {},
    isMediaLibrary_cb = false,
    isMediaLibraryMain = false,
    handleSelectImage = () => {},
    handleMediaSelect = () => {},
    selectedDataId = "",
    handleSelectMedia = () => {},
    setModalUploadData = () => {},
    name = "",
    isMediaUploadModal = false,
    addMediaModalindex = 0,
    createdAt = "",
    creativeBuilderV2 = false,
  } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [playModal, setPlayModal] = useState(false);
  const { url = "", id = "", thumbUrl = "", tags = [], categoryId = [] } = creative;
  const disabled =
    !creativeBuilderV2 &&
    isCreativeBuilder &&
    selectedData?.length === 1 &&
    selectedData?.[0]?.id === id;

  const onClick = disabled ? () => {} : onClickprops;
  const audioRef = useRef(null);
  function playAudio() {
    setIsPlaying(true);
    audioRef?.current?.play();
  }
  // const isSelection = { onClick: onSelect };
  function pauseAudio() {
    if (isPlaying) {
      setIsPlaying(false);
      audioRef?.current?.pause();
    }
  }
  function handleCreatives() {
    let title,
      deleteTitle,
      mimeType = "";
    if (creativeType === CREATIVE_TYPE_IMAGE) {
      title = "Download Image";
      deleteTitle = "Delete Image";
      mimeType = CREATIVE_MIME_TYPE_IMAGE;
    } else if (creativeType === CREATIVE_TYPE_VIDEO) {
      title = "Download Video";
      deleteTitle = "Delete Video";
      mimeType = CREATIVE_MIME_TYPE_VIDEO;
    } else {
      deleteTitle = "Delete Audio";
      title = "Download Audio";
      mimeType = CREATIVE_MIME_TYPE_AUDIO;
    }
    return { title, mimeType, deleteTitle };
  }

  useEffect(() => {
    const audioElement = audioRef.current;
    function handleAudioEnded() {
      setIsPlaying(false);
    }
    audioElement?.addEventListener("ended", handleAudioEnded);
    return () => {
      audioElement?.removeEventListener("ended", handleAudioEnded); // Clean up the event listener
    };
  }, []);

  const popoverWrapperOptions = [
    {
      option: "download",
      title: handleCreatives()?.title,
      optionProps: {
        onClick: () => {
          handleClickDownload([url], handleCreatives()?.mimeType);
        },
      },
    },

    ...(isCreativeBuilder
      ? [
          {
            option: "notification",
            title: "Creative Builder",
            optionProps: {
              onClick: () => {
                !isRequestView && showDrawer({ ...creative, ...props });
              },
            },
          },
        ]
      : []),

    ...(!isTemplateForm && !isCreativeBuilder
      ? [
          {
            option: "tagSwitch",
            title: "Tags",
            optionProps: {
              onClick: () => {
                tagDataExtractor(id);
                openTagModal();
                setDeleteModalPropsData([{ id: id, tags: tags || [] }]);
              },
            },
          },
        ]
      : []),
    ...(!isTemplateForm
      ? [
          {
            option: "delete",
            title: handleCreatives()?.deleteTitle,
            optionProps: {
              onClick: () => {
                const updatedArray = categoryId?.map((element) => ({ ...element, id: id }));
                isMediaLibraryMain ? onOpenDeleteModal(updatedArray) : onOpenDeleteModal([id]);
              },
            },
          },
        ]
      : []),
  ];

  const audioHoverContent = () => (
    <div className="image-content-details  w-100">
      <div className="d-flex align-items-start justify-content-between  w-100 p-10">
        <div className="d-flex">
          <div className="checkbox-main  mr-10">
            <Checkbox
              onClick={() => {
                isCreativeBuilder
                  ? handleSelectImage(selectedDataId)
                  : handleSelectMedia(selectedDataId);
              }}
              checked={selected}
              disabled={disabled}
            />
          </div>
          {/* Audio descriptio */}
          <div className="audio-description">
            <div>{name}</div>
            <div>{createdAt}</div>
          </div>
          {/* End */}
        </div>

        <div className="d-flex align-items-center">
          <PopoverWrapper options={popoverWrapperOptions}>
            <MoreOutlined className="pop-info" />
          </PopoverWrapper>
          <LookButton
            className="border-0"
            block
            tooltipTitle="Play Audio" // creative type - hhh
            iconType="playButton"
            iconTypeProps={{ style: { fontSize: 20 } }}
            onClick={() => setPlayModal(true)}
          />
        </div>
      </div>
    </div>
  );
  const imageHoverContent = () => (
    <div className="imageHoverContent">
      <>
        <div className="image-content-details  w-100">
          <div className="d-flex justify-content-between  w-100">
            <div className="mr-15">
              <Checkbox
                onClick={() => {
                  isCreativeBuilder
                    ? handleSelectImage(selectedDataId)
                    : handleMediaSelect(selectedDataId);
                }}
                checked={selected}
                disabled={disabled}
              />
            </div>
            <PopoverWrapper options={popoverWrapperOptions}>
              <MoreOutlined className="pop-info" />
            </PopoverWrapper>
          </div>
        </div>
        <div className="file-name">
          <Tooltip title={name}> {name}</Tooltip>
        </div>
      </>
    </div>
  );
  const videoHoverContent = () => (
    <>
      <div className="image-content-details  w-100">
        <div className="d-flex justify-content-between  w-100">
          <div className="mr-15">
            <Checkbox
              onClick={() => {
                isCreativeBuilder
                  ? handleSelectImage(selectedDataId)
                  : handleMediaSelect(selectedDataId);
              }}
              checked={selected}
              disabled={disabled}
            />
          </div>
          <PopoverWrapper options={popoverWrapperOptions}>
            <MoreOutlined className="pop-info" />
          </PopoverWrapper>
        </div>

        <LookButton
          // size="small"
          className="border-0 m-0 position-absolute-center"
          block
          tooltipTitle="Play video"
          iconType="playButton"
          iconTypeProps={{ style: { fontSize: 30 } }}
          onClick={() => setPlayModal(true)}
        />
      </div>
      <div className="file-name">{name || ""}</div>
    </>
  );
  const renderImageView = () => (
    <div className={cx("image-hover-action-wrapper", { isRequestView })}>
      <Image src={url} preview={false} style={{ objectFit: "fill" }} />

      {isTemplateForm ? (
        <div className="hover-action">
          <LookButton
            size="small"
            style={{ border: "none" }}
            tooltipTitle="Delete Image"
            iconType="delete"
            onClick={(e) => {
              if (isMediaUploadModal && isTemplateForm) {
                setModalUploadData((prev) => {
                  return prev?.filter((value, i) => i !== addMediaModalindex);
                });
              } else {
                setCreativesImages([]);
              }
            }}
          />
        </div>
      ) : (
        <div className="creative-adgroup-card">
          <div className="hover-action">
            {/* video hover details  */}
            {imageHoverContent()}
            {/* end */}
          </div>
        </div>
      )}
    </div>
  );
  const renderVideoView = () => (
    <>
      {thumbUrl && (
        <>
          <Image style={{ objectFit: "fill" }} src={thumbUrl} preview={false} />
          {isTemplateForm ? (
            <div className="hover-action">
              <LookButton
                size="small"
                style={{ border: "none" }}
                tooltipTitle="Delete Video"
                iconType="delete"
                onClick={(e) => {
                  if (isMediaUploadModal && isTemplateForm) {
                    setModalUploadData((prev) => {
                      return prev?.filter((value, i) => i !== addMediaModalindex);
                    });
                  } else {
                    setCreativesImages([]);
                  }
                }}
              />
            </div>
          ) : (
            <div className="creative-adgroup-card video-card">
              <div className={cx("hover-action", { active: false })}>
                {/* video hover details  */}
                {videoHoverContent()}
                {/* end */}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
  const renderAudioView = () => (
    // to do : create Seprate component audio for this in future designerN
    <>
      <div className="audioplay video-card">
        <div className="hover-action">
          {isTemplateForm && (
            <LookButton
              size="small"
              style={{ border: "none" }}
              tooltipTitle="Delete Video"
              iconType="delete"
              onClick={(e) => {
                if (isMediaUploadModal && isTemplateForm) {
                  setModalUploadData((prev) => {
                    return prev?.filter((value, i) => i !== addMediaModalindex);
                  });
                } else {
                  setCreativesImages([]);
                }
              }}
            />
          )}
          {/*  hover details  */}
          {!isTemplateForm && audioHoverContent()}
          {/* end */}
        </div>
      </div>
      <div className="d-flex">
        <div className="icon-outer mr-10">
          <ActionIconRender iconType="music" className="font-size-16" />
        </div>
        <div>
          <div className="audio-description">
            <div>{name}</div>
            <div>{moment(createdAt).format("YYYY-MM-DD h:mmA")}</div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <ClickAwayListener onClickAway={() => pauseAudio()}>
        {uploading ? (
          <div className={cx("creative-image-selection ant-image-ration-1-1", { selected })}>
            <Skeleton.Image className="w-100 " style={{ height: "348px" }} active={true} />
          </div>
        ) : (
          <div
            className={cx("creative-image-video-parent", { activeBorder: selected })}
            style={{ lineHeight: "1" }}
            onClick={onClick}
          >
            <div
              className={cx("image-hover-action-wrapper", {
                "p-10": creativeType === CREATIVE_TYPE_AUDIO,
              })}
            >
              {creativeType === CREATIVE_TYPE_IMAGE && renderImageView()}
              {creativeType === CREATIVE_TYPE_VIDEO && renderVideoView()}
              {/* //to do : create Seprate component audio for this in future designerN */}
              {creativeType === CREATIVE_TYPE_AUDIO && renderAudioView()}
              {/* hover action on audio file */}
            </div>
          </div>
        )}
        {/* {!isMediaLibraryMain && (!isRequestView || canEditStatus) && !isTemplateForm && (
          <div className={cx("creative-edit", { request: true })}>
            {!isTemplateForm && (
              <div className="d-flex" onClick={onClick}>
                <Checkbox className="creative-select" disabled={disabled} checked={selected} />
              </div>
            )}

            <div className="d-flex">
              {isCreativeBuilder && !isMediaLibrary_cb ? (
                <PopoverWrapper options={popoverWrapperOptions}>
                  <ActionIconRender
                    iconType="more"
                    style={{ fontSize: "20px", marginTop: "5px", marginLeft: "10px" }}
                  />
                </PopoverWrapper>
              ) : (
                <>
                  {creativeType === CREATIVE_TYPE_AUDIO && (
                    <>
                      <audio ref={audioRef}>
                        <source src={url} type="audio/mpeg"></source>
                      </audio>
                      <LookButton
                        size="small"
                        tooltipTitle={isPlaying ? "Pause Audio" : "Play Audio"}
                        iconType={isPlaying ? "pause" : "play"}
                        style={{ border: "none" }}
                        onClick={() => {
                          isPlaying ? pauseAudio() : playAudio();
                        }}
                      />
                    </>
                  )}

                  {!isTemplateForm && (
                    <div>
                      <LookButton
                        size="small"
                        key="tagsButton"
                        tooltipTitle="Tags"
                        style={{ border: "none" }}
                        iconType="tagSwitch"
                        onClick={() => {
                          tagDataExtractor(id);
                          openTagModal();
                          setDeleteModalPropsData([{ id: id, tags: tags || [] }]);
                        }}
                      />
                      <LookButton
                        key="downloadButton"
                        size="small"
                        tooltipTitle={handleCreatives()?.title}
                        iconType="download"
                        style={{ border: "none" }}
                        onClick={() => handleClickDownload([url], handleCreatives()?.mimeType)}
                      />
                    </div>
                  )}

                  {!isTemplateForm && (
                    <LookButton
                      key="deleteButton"
                      size="small"
                      style={{ border: "none" }}
                      tooltipTitle="Delete Creatives"
                      iconType="delete"
                      onClick={(e) => {
                        isTemplateForm ? setCreativesImages([]) : onOpenDeleteModal([id]);
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )} */}
      </ClickAwayListener>
      {!isTemplateForm && playModal && (
        <LookModal
          title={`Play  ${creativeType === CREATIVE_TYPE_AUDIO ? "Audio" : "Video"}`}
          visible={playModal}
          onCancel={() => setPlayModal(false)}
          footer={false}
        >
          {playModal && creativeType === CREATIVE_TYPE_AUDIO ? (
            <div className="margin-0-auto text-center">
              <audio controls autoPlay>
                <source src={url} type="audio/mpeg" />
              </audio>
            </div>
          ) : (
            <video height={"300px"} className="w-100" src={url} controls autoPlay />
          )}
        </LookModal>
      )}
    </div>
  );
}
