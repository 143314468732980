import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  SCORECARD_VIEW_ADMIN,
  SCORECARD_VIEW_USER,
  UMS_SUPER_ADMIN,
} from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Dashboard = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Unicorn-Dashboard" */ "pages/dashboardPages/Unicorn/Dashboard")
  )
);
const CausativeMilestones = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "CausativeMilestones" */ "pages/dashboardPages/Unicorn/Milestones/CausativeMilestones"
    )
  )
);
const PromisedActions = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "PromisedActions" */ "pages/dashboardPages/Unicorn/Milestones/PromisedActions"
    )
  )
);
const StrategicOutcomes = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "StrategicOutcomes" */ "pages/dashboardPages/Unicorn/Milestones/StrategicOutcomes"
    )
  )
);
const VerifiedMilestones = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VerifyingMilestones" */ "pages/dashboardPages/Unicorn/Milestones/VerifyingMilestones"
    )
  )
);

function UnicornWrapper(props) {
  const { children = <></> } = props;
  const userSettings = useSelector((state) => state?.userSettings);
  const { isClickupUserLoggedIn = false } = userSettings || {};
  const { fetchAllLobs, unicornDepartmentsFetch } = useApiToStoreContext();

  useEffect(() => {
    // if (isClickupUserLoggedIn) {
    // }
    // if (SC_UnicornDepartments?.length === 0) {
    // }
    unicornDepartmentsFetch();
    fetchAllLobs();
  }, [isClickupUserLoggedIn]);

  return children;
}

export default function useUnicornRoutes() {
  const unicornRoutes = [
    route(
      "/unicorn/unicorn-dashboard",
      () => (
        <>
          <UnicornWrapper />
          <Dashboard />
        </>
      ),
      ROUTE_DASHBOARD,
      false,
      SCORECARD_VIEW_USER
    ),
    route(
      "/unicorn/strategic-outcomes",
      () => (
        <>
          {/* <UnicornWrapper /> */}
          <StrategicOutcomes />
        </>
      ),
      ROUTE_DASHBOARD,
      false,
      SCORECARD_VIEW_USER
    ),
    route(
      "/unicorn/verifying-milestones",
      () => (
        <>
          <UnicornWrapper />
          <VerifiedMilestones />
        </>
      ),
      ROUTE_DASHBOARD,
      false,
      SCORECARD_VIEW_USER
    ),
    route(
      "/unicorn/causative-milestones",
      () => (
        <>
          <UnicornWrapper />
          <CausativeMilestones />
        </>
      ),
      ROUTE_DASHBOARD,
      false,
      SCORECARD_VIEW_USER
    ),
    route(
      "/unicorn/promised-actions",
      () => (
        <>
          <UnicornWrapper />
          <PromisedActions />
        </>
      ),
      ROUTE_DASHBOARD,
      false,
      SCORECARD_VIEW_USER
    ),
  ];

  return { unicornRoutes };
}
