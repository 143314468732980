import { Button, Col, Row, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import { useTableContext } from "components/LookTable/fields";

export default function FilterFields(props) {
  const {
    remove,
    columnSelection = () => {},
    searchType,
    selectedColumn,
    includeInputs = [],
    excludeInputs = [],
    filterColumns = [],
    includeType = "AND",
    excludeType = "AND",
    numberFilterType,
    numberFilterValue,
    numberFilterRangeMin,
    numberFilterRangeMax,
    handleFilterOptions = () => {},
  } = props;
  const { columns: mainColumns, lastColumns = [] } = useTableContext();

  const columns = [...mainColumns, ...lastColumns];
  const stringInput = () => (
    <>
      <div style={{ width: 200 }}>
        <SelectInput
          mode="tags"
          className="mb-0"
          value={includeInputs}
          onChange={(e) => handleFilterOptions({ includeInputs: e })}
          placeholder="Include"
        />
      </div>
      <Tooltip title="Include Operation">
        <div style={{ width: 72 }}>
          <SelectInput
            onChange={(e) => handleFilterOptions({ includeType: e })}
            value={includeType}
            options={[
              { name: "AND", value: "AND" },
              { name: "OR", value: "OR" },
            ]}
          />
        </div>
      </Tooltip>
      <div style={{ width: 200 }}>
        <SelectInput
          mode="tags"
          className="mb-0"
          value={excludeInputs}
          onChange={(e) => handleFilterOptions({ excludeInputs: e })}
          placeholder="Exclude"
        />
      </div>
      {/* <div style={{ width: 72 }}>
        <SelectInput
          onChange={(e) => handleFilterOptions({ excludeType: e })}
          value={excludeType}
          options={[
            { name: "AND", value: "AND" },
            { name: "OR", value: "OR" },
          ]}
        />
      </div> */}
    </>
  );

  const numberInput = () => (
    <>
      <div style={{ width: 200 }}>
        <SelectInput
          className="mb-0"
          placeholder="Select operation"
          value={numberFilterType}
          onChange={(e) => handleFilterOptions({ numberFilterType: e })}
          options={[
            { name: ">", value: ">" },
            { name: "<", value: "<" },
            { name: ">=", value: ">=" },
            { name: "<=", value: "<=" },
            { name: "=", value: "===" },
            { name: "Range", value: "range" },
          ]}
        />
      </div>
      {numberFilterType ? (
        numberFilterType === "range" ? (
          <>
            <div className="d-flex" style={{ width: 200 }}>
              <LookTextInput
                className="mb-0"
                type="number"
                placeholder="Min no."
                value={numberFilterRangeMin}
                onChange={(e) => handleFilterOptions({ numberFilterRangeMin: e.target.value })}
              />

              <LookTextInput
                className="mb-0"
                type="number"
                placeholder="Max no."
                value={numberFilterRangeMax}
                onChange={(e) => handleFilterOptions({ numberFilterRangeMax: e.target.value })}
              />
            </div>
          </>
        ) : (
          <div style={{ width: 200 }}>
            <LookTextInput
              className="mb-0"
              type="number"
              placeholder="Enter no."
              value={numberFilterValue}
              onChange={(e) => handleFilterOptions({ numberFilterValue: e.target.value })}
            />
          </div>
        )
      ) : null}
    </>
  );
  const debugContent = [{ isRow: true, contentObjects: [{ props }] }];
  return (
    <div>
      <div className="d-flex mb-12">
        <div style={{ width: 200 }}>
          <SelectInput
            className="mb-0"
            search
            value={selectedColumn}
            onChange={columnSelection}
            placeholder="Select column"
            options={columns
              ?.filter(({ dataIndex }) => {
                if (selectedColumn === dataIndex) {
                  return true;
                }

                return filterColumns?.length > 0
                  ? filterColumns.every((e) => !(e?.selectedColumn === dataIndex))
                  : true;
              })
              ?.map(({ title, dataIndex, searchType = "string" }) => ({
                name: title,
                value: dataIndex,
              }))}
          />
        </div>
        {searchType === "string" && stringInput()}
        {searchType === "number" && numberInput()}
        <Button onClick={remove} type="link" danger icon={<ActionIconRender iconType="close" />} />
      </div>

      {/* <DebugPre content={debugContent} /> */}
    </div>
  );
}
