import React from "react";
import { useCreativeReportingContext } from "../Context";
import CreativeReportTableView from "./CreativeReportTableView";
import Header from "./Header";

function CreativeReportingMainView() {
  const { isGlobalReport = false } = useCreativeReportingContext();
  return (
    <>
      {!isGlobalReport && <Header />}
      <CreativeReportTableView />
    </>
  );
}

export default CreativeReportingMainView;
