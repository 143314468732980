import { useState } from "react";
import LookCheckedInput from "components/forms/LookCheckedInput";

const titleMockData = [
  {
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu lectus justo in pulvinar enim. Viverra feugiat morbi potenti aenean interdum in. Scelerisque viverra gravida pharetra, aliquet nisl. Eu, quis sed cras sollicitudin. Lectus pellentesque habitasse mi eget hendrerit elit. Libero tempor iaculis egestas etiam ultrices euismod malesuada pellentesque nunc. Mauris mauris nec congue sapien pellentesque...Still writing",
    checked: true,
  },
  {
    value:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare molestie tincidunt elementum neque, mi. Quam enim tincidunt elementum massa quam netus dignissim. Vel vitae ut urna lectus sed posuere tortor, malesuada. Malesuada volutpat sit facilisis habitasse sit eget scelerisque dictum tincidunt. Eget tellus vulputate pulvinar dignissim massa nunc lectus. Quisque et amet elementum pharetra mi, vestibulum, nulla ut felis. ",
  },
  { value: "Give your dog the attention that she always wanted!" },
  { value: "Give your dog an amazing treat for the good boy he is!", checked: true },
  {
    value:
      "Give your dog the attention that she always ve your dog the attention that she always wanted! Give your dog the attention that she always wanted! Giv our dog the attention that she always wanted!",
  },
  { value: "Give your dog an amazing treat for the good boy he is!" },
  { value: "Give your dog the attention that she always wanted!" },
  { value: "Give your dog an amazing treat for the good boy he is!" },
];

export default function LaunchCreativesMediaHeadline() {
  const [titleData, setTitleData] = useState(titleMockData);

  return (
    <>
      {titleData.map((data, index) => (
        <LookCheckedInput {...data} isEditable />
      ))}
    </>
  );
}
