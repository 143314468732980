import { gql } from "@apollo/client";

//* F360 ***************************

/**
 * * AssetUpdateResponse {
 * * success: Boolean
 * * message: String
 * }
 */

export const ASSET_UPDATE_RESPONSE_RESPONSE = gql`
  fragment AssetUpdateResponse on AssetUpdateResponse {
    assetId
    success
    message
  }
`;
