import { Button, Popover, Tooltip, Input, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import UserSelectPersist from "../../PersistComponents/UserSelectPersist";
import ColumnSettingContent from "./ColumnSettingContent";
import { DEFAULT_TABLE_ROW_SIZE_VALUE } from "reducers/userSettings";
import {
  USER_SETTING_MAIN_PAGINATION,
  USER_SETTING_MAIN_SEARCH,
  USER_SETTING_MAIN_SEARCH_WITH_INCLUDE_BIT,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import ActionIconRender from "components/ActionIconRender";
import { useContext, useState } from "react";
import { TableContext } from "../fields";
import LookTableFilterSearch from "../LookTableFilterSearch";
import cx from "cx";
import LookButton from "components/LookButton";
import { SAS_WORKSPACE_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import HourSelectPersist from "components/PersistComponents/HourSelectPersist";
import YearSelectPersist from "components/PersistComponents/YearSelectPersist";
import MonthSelectPersist from "components/PersistComponents/MonthSelectPersist";
import DateSelectPersist from "components/PersistComponents/DateSelectPersist";
import QuarterSelectPersist from "components/PersistComponents/QuarterSelectPersist";
import useCustomColumn from "./useCustomColumn";

export default function LookTableFilter(props) {
  const {
    columns: columnProp,
    chooseColumn = () => {},
    setColumns,
    handleRowSize = () => {},
    leftOptions,
    rightOptions,
    subPlaceholder = "",
    placeholder = "",
    refetch,
    bulkSelectionAction = {},
    uploadTableFile = {},
    loading = false,
    isDateRange = false,
    dateRangeProps = {},
    persistSearch = true,
    searchInclude = false,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    isUserSelection = false,
    isHourSelection = false,
    isYearSelection = false,
    isDateSelection = false,
    isMonthSelection = false,
    isQuarterSelection = false,
    disabledMonths = () => {},
    exportCSV = false,
    handleExportCSV = () => {},
    actionButtons = [],
    showResizeHideColumns = true,
    customColumnDefaultConfigKey,
  } = props;

  const persistPaginationKey = persistKey;
  const persistSearchKey = persistKey;

  const { renderCustomColumnModal, callCustomColumn, renderColumnSetShift } = useCustomColumn({
    customColumnDefaultConfigKey,
  });

  const {
    bulkSelectAction = () => {},
    bulkSelectTooltipText = "",
    btnText = "",
    isVisible = true,
  } = bulkSelectionAction;
  const { uploadFileAction = () => {}, uploadFileText = "" } = uploadTableFile;
  const { havePermission: isWorkspaceAdmin } = usePermissionCheck(SAS_WORKSPACE_ADMIN);
  const dispatch = useDispatch();
  const { tableRowSize = DEFAULT_TABLE_ROW_SIZE_VALUE } =
    useSelector((state) => state?.userSettings) || {};

  // const { resetTableConfiguration = () => {} } = useContext(TableContext);

  const {
    isLineChart = false,
    isVisibleChart = false,
    setIsVisibleChart = () => {},
    isSearchFilter = false,
    searchBorder = false,
    isCustomeView = false,
  } = useContext(TableContext) || {};

  const rowSizeContent = () => {
    const optionBtnData = [
      { name: "Small", type: "small" },
      { name: "Middle", type: "middle" },
      { name: "Large", type: "large" },
    ];

    return (
      <div className="look-table-rowSizeBtn">
        {optionBtnData.map(({ name, type }, index) => (
          <Button
            key={"optionBtnData" + index}
            size="small"
            block
            type={cx({ primary: tableRowSize === type })}
            onClick={() => handleRowSize(type)}
          >
            {name}
            {tableRowSize === type && <ActionIconRender iconType="check" />}
          </Button>
        ))}
      </div>
    );
  };

  const userSettings = useSelector((state) => state?.userSettings);

  const { mainSearchWithInclude } = userSettings || {};

  const mainSearch = useSelector((state) => state?.userSettings?.mainSearch);

  const mainSearchFinal = useSelector(
    (state) => state?.userSettings?.mainSearch?.[persistSearchKey]?.search
  );

  const mainPagination = useSelector((state) => state?.userSettings?.mainPagination);

  const [localSearchState, setLocalSearchState] = useState("");

  const handlePersistSearch = (e) => {
    setLocalSearchState(e.target.value);
  };

  /**
   *
   * {actionButtons} prop for adding new action buttons in table
   **/
  const renderButton = () => {
    return actionButtons?.map(({ iconType = "", onClick = () => {}, title = "" }) => {
      return (
        <LookButton
          tooltipTitle={title}
          className="filter-btn ml-8"
          size="small"
          iconType={iconType}
          onClick={onClick}
        />
      );
    });
  };

  const handleIncludeSearch = (e) =>
    dispatch({ type: USER_SETTING_MAIN_SEARCH_WITH_INCLUDE_BIT, payload: e.target.checked });

  const handlePersistSearchMain = (e, type) => {
    if (e.key === type) {
      dispatch({
        type: USER_SETTING_MAIN_PAGINATION,
        payload: {
          ...mainPagination,
          [persistPaginationKey]: { ...mainPagination?.[persistPaginationKey], page: 1 },
        },
      });

      dispatch({
        type: USER_SETTING_MAIN_SEARCH,
        payload: {
          ...mainSearch,
          [persistSearchKey]: { ...mainSearch?.[persistSearchKey], search: e.target.value },
        },
      });
      return null;
    }
    if (type === "onBlur") {
      if (e.target.value !== mainSearchFinal) {
        dispatch({
          type: USER_SETTING_MAIN_PAGINATION,
          payload: {
            ...mainPagination,
            [persistPaginationKey]: { ...mainPagination?.[persistPaginationKey], page: 1 },
          },
        });
        dispatch({
          type: USER_SETTING_MAIN_SEARCH,
          payload: {
            ...mainSearch,
            [persistSearchKey]: { ...mainSearch?.[persistSearchKey], search: e.target.value },
          },
        });
        return null;
      }
    }
  };

  const tableRowSizeAndColumnsAction = () => (
    <>
      <Popover content={rowSizeContent} trigger="click" placement="bottomRight">
        <LookButton
          tooltipTitle="Row Height"
          tooltipPlacement="topLeft"
          className="filter-btn ml-8"
          size="small"
          iconType="column-height"
        />
      </Popover>

      <Popover
        content={
          <ColumnSettingContent
            callCustomColumn={callCustomColumn}
            columns={columnProp}
            chooseColumn={chooseColumn}
            setColumns={setColumns}
            renderColumnSetShift={renderColumnSetShift}
          />
        }
        trigger="click"
        placement="bottomRight"
      >
        <LookButton
          tooltipTitle="Column To Visible"
          tooltipPlacement="topLeft"
          className="filter-btn ml-8"
          size="small"
          iconType="setting"
        />
      </Popover>
    </>
  );
  const lineChartAction = () => (
    <>
      <LookButton
        tooltipTitle={`Click to ${isVisibleChart ? "hide" : "show"} chart`}
        tooltipPlacement="topLeft"
        className="filter-btn ml-8"
        size="small"
        iconType={isVisibleChart ? "eye" : "eye-invisible"}
        onClick={() => setIsVisibleChart(!isVisibleChart)}
      />
    </>
  );
  return (
    <div>
      {renderCustomColumnModal()}
      <div className="LookTableFilter flex justify-content-between">
        <div className="flex">
          <div className="look-table-search">
            {persistSearch && (
              <div className="flex">
                {searchInclude && (
                  <Tooltip title={mainSearchWithInclude ? "Include Search" : "Not Include Search"}>
                    <Checkbox
                      className="mr-8"
                      style={{ marginLeft: -10 }}
                      checked={mainSearchWithInclude}
                      onChange={handleIncludeSearch}
                    />
                  </Tooltip>
                )}
                <Input
                  className={cx({ searchBorder })}
                  value={localSearchState}
                  onChange={handlePersistSearch}
                  onKeyDown={(e) => handlePersistSearchMain(e, "Enter")}
                  onBlur={(e) => handlePersistSearchMain(e, "onBlur")}
                  placeholder={placeholder !== "" ? placeholder : "Search " + subPlaceholder}
                  prefix={<ActionIconRender iconType="search" />}
                />
                {isSearchFilter && <LookTableFilterSearch />}
                {/* {ruleSetting && <AddRule {...ruleSetting} />} */}
              </div>
            )}
          </div>
          <div>{leftOptions && leftOptions}</div>
        </div>
        <div className="flex">
          {rightOptions && rightOptions}

          {isMonthSelection && (
            <div className="ml-8">
              <MonthSelectPersist disabledMonths={disabledMonths} persistKey={persistKey} />
            </div>
          )}

          {isDateSelection && (
            <div className="ml-8">
              <DateSelectPersist persistKey={persistKey} />
            </div>
          )}

          {isYearSelection && (
            <div className="ml-8">
              <YearSelectPersist persistKey={persistKey} />
            </div>
          )}

          {isHourSelection && (
            <div className="ml-8">
              <HourSelectPersist persistKey={persistKey} />
            </div>
          )}

          {isUserSelection && (
            <div className="ml-8">
              <UserSelectPersist persistKey={persistKey} />
            </div>
          )}
          {isDateRange && (
            <div className="ml-8">
              <DateRangePersist {...dateRangeProps} />
            </div>
          )}

          {isQuarterSelection && (
            <div className="ml-8">
              <QuarterSelectPersist persistKey={persistKey} />
            </div>
          )}

          {renderButton()}

          {Object.keys(uploadTableFile).length > 0 && isWorkspaceAdmin && (
            <LookButton
              tooltipTitle={uploadFileText}
              className="filter-btn ml-8"
              size="small"
              iconType="upload"
              onClick={() => uploadFileAction()}
            />
          )}

          {exportCSV && (
            <Tooltip placement="topLeft" title="Export">
              <Button
                className="filter-btn ml-8"
                size="small"
                onClick={handleExportCSV}
                disabled={loading}
              >
                <ActionIconRender iconType="download" />
              </Button>
            </Tooltip>
          )}

          {Array.isArray(bulkSelectionAction)
            ? bulkSelectionAction?.map((aData) => {
                const {
                  isVisible = false,
                  bulkSelectAction = () => {},
                  bulkSelectTooltipText = "",
                  btnText = "",
                  loading = false,
                } = aData;

                return isVisible ? (
                  <LookButton
                    tooltipTitle={bulkSelectTooltipText}
                    type="primary"
                    className={`ml-8 ${cx({ "bulk-select-btn": !btnText })}`}
                    size={btnText ? "middle" : "small"}
                    iconType={btnText ? "" : "plus"}
                    onClick={() => bulkSelectAction()}
                    loading={loading}
                  >
                    {btnText}
                  </LookButton>
                ) : null;
              })
            : Object.keys(bulkSelectionAction).length > 0 &&
              isVisible && (
                <LookButton
                  tooltipTitle={bulkSelectTooltipText}
                  type="primary"
                  className={`ml-8 ${cx({ "bulk-select-btn": !btnText })}`}
                  size={btnText ? "middle" : "small"}
                  iconType={btnText ? "" : "plus"}
                  onClick={() => bulkSelectAction()}
                  loading={bulkSelectionAction?.loading}
                >
                  {btnText}
                </LookButton>
              )}
          {refetch && (
            <LookButton
              tooltipTitle="Refetch"
              className="filter-btn ml-8"
              size="small"
              iconType="reload"
              disabled={loading}
              iconTypeProps={{ spin: loading }}
              onClick={() => refetch()}
            />
          )}

          {!isCustomeView && showResizeHideColumns && tableRowSizeAndColumnsAction()}
          {isLineChart && lineChartAction()}
        </div>
      </div>
    </div>
  );
}
