import LookDrawer from "components/LookDrawer";
import { usePermissionCheck } from "hooks";
import React, { useState } from "react";
import {
  CREATIVE_TRACKING_CONTENT_WRITER,
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  CREATIVE_TRACKING_MEDIA_BUYER,
} from "shared/UserPermission/userPermissionList";
import RequestView from "../RequestView";

function useRequestViewDrawer(props) {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const createRequestView = (data) => {
    setVisibleDrawer(true);
    setSelectedRowsData(data);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    setSelectedRowsData([]);
  };
  let breadCrumb = ["Creative Dashboard", "Creative Requests"];
  if (selectedRowsData?.id) {
    breadCrumb.push(`${selectedRowsData?.id}`);
  }
  const drawerProps = {
    textBreadcrumb: breadCrumb,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: "80%",
  };
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const { havePermission: isMediaBuyer } = usePermissionCheck(CREATIVE_TRACKING_MEDIA_BUYER);
  const { havePermission: isContentWriter } = usePermissionCheck(CREATIVE_TRACKING_CONTENT_WRITER);
  const { havePermission: isGraphicDesigner } = usePermissionCheck(
    CREATIVE_TRACKING_GRAPHIC_DESIGNER
  );
  const permissionProps = {
    isMediaBuyer,
    isCreativeDirector,
    isGraphicDesigner,
    isContentWriter,
  };

  function createRequestFormView() {
    return (
      <>
        <LookDrawer {...drawerProps} className="no-body-space">
          {visibleDrawer && (
            <RequestView
              {...props}
              {...selectedRowsData}
              setSelectedRowsData={setSelectedRowsData}
              onCloseDrawer={onCloseDrawer}
              {...permissionProps}
            />
          )}
          {/* {visibleDrawer && <RaiseRequestForm />} */}
        </LookDrawer>
      </>
    );
  }
  return { createRequestFormView, createRequestView };
}

export default useRequestViewDrawer;
