import { useEffect, useContext } from "react";
import { getCookie } from "../../utils";
import { AccountContext } from "../Account";

function RemberLogin() {
  const { logout } = useContext(AccountContext);

  function remberLogin() {
    // if (localStorage.getItem("remberLogin")) {
    //   if (!JSON.parse(localStorage.getItem("remberLogin"))) {
    //     if (sessionStorage.getItem("isLogin")) {
    //       if (!JSON.parse(sessionStorage.getItem("isLogin"))) {
    //         logout();
    //       }
    //     } else {
    //       logout();
    //     }
    //   }
    // }

    if (localStorage.getItem("remberLogin")) {
      if (!JSON.parse(localStorage.getItem("remberLogin"))) {
        if (getCookie("isLogin")) {
          if (!JSON.parse(getCookie("isLogin"))) {
            logout();
          }
        } else {
          logout();
        }
      }
    }
  }

  useEffect(() => remberLogin(), []);

  return null;
}

export default RemberLogin;
