import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2 } from "actions/actionTypes";
import { Segmented } from "antd";
import DebugPre from "components/DebugPre";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GET_F360_GLOBAL_LANDER_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import { TIKTOK_GLOBAL_REPORT } from "shared/gql/gqlSchema/tiktok";
import { PRODUCT_ID_LEADGEN } from "shared/SharedKeys";
import { numberParse, openNotification } from "utils";
function LanderReport(props) {
  const {
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
    timezone: timeZoneProps,
    category = {},
  } = props;

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const [landerSpendReport, setLanderSpendReport] = useState([]);
  const defaultDate = [dateRangeTimezone?.rangeDates[0], dateRangeTimezone?.rangeDates[1]];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);
  const [timeZone, setTimeZone] = useState({ value: dateRangeTimezone?.timeZone?.value });
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);

  const [platformType, setPlatformType] = useState("Facebook");

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  function getSpendApiData(platform) {
    switch (platform) {
      case "Facebook":
        return {
          api: GET_F360_GLOBAL_LANDER_REPORT,
          key: "fb360_globalLanderReport",
          variables: {},
        };

      case "Tiktok":
        return {
          api: TIKTOK_GLOBAL_REPORT,
          key: "tiktok_globalLanderReport",
          variables: {
            product_id: PRODUCT_ID_LEADGEN,
          },
        };

      default:
        return {};
    }
  }

  let spendReportVariables = getSpendApiData(platformType);

  let finalMergeData = [];

  const [
    fetchSpendReport,
    {
      loading: loading_LEADGEN_GLOBAL_USER_REPORT,
      error: error_LEADGEN_GLOBAL_USER_REPORT,
      refetch: refetch_LEADGEN_GLOBAL_USER_REPORT,
    },
  ] = useCustomLazyQuery(spendReportVariables?.api, {
    notifyOnNetworkStatusChange: true,
    variables: {
      start: rangeDates[0],
      end: rangeDates[1],
      timezone: timeZone.value,
      category_id: category?.id,
      ...spendReportVariables?.variables,
    },
    onCompleted: (e) => {
      const element = e?.[spendReportVariables?.key];
      if (element) {
        setLanderSpendReport(element);
      } else {
        setLanderSpendReport([]);
      }
    },
    onError: (e) => {
      openNotification({ type: "error", message: e?.message });
      setLanderSpendReport([]);
    },
  });

  useEffect(() => {
    fetchSpendReport();
  }, [platformType]);

  const refetch = () => {
    refetch_LEADGEN_GLOBAL_USER_REPORT();
  };

  const loading = loading_LEADGEN_GLOBAL_USER_REPORT;

  const initialColumns = [
    {
      title: "URL",
      dataIndex: "lander_url",
      key: "lander_url",
      render: (value, otherProps) => {
        return <LookTableColumnRender title={value} {...otherProps} />;
      },
      fixed: "left",
      width: 190,
      minWidth: 190,
      searchType: "string",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (value, otherProps) => (
        <LookTableColumnRender title={`$${numberParse(value, { toFixed: true })}`} />
      ),
      width: 170,
      minWidth: 130,
      sorter: (a, b) => a?.spend - b?.spend,
      searchType: "string",
    },

    {
      title: "Conversions",
      dataIndex: "conversions",
      key: "conversions",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.conversions - b?.conversions,
      searchType: "number",
    },

    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",
    },

    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressions - b?.impressions,
      searchType: "number",
    },
    {
      title: "Inline link clicks",
      dataIndex: "inline_link_clicks",
      key: "inline_link_clicks",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.inline_link_clicks - b?.inline_link_clicks,
      searchType: "number",
    },
    {
      title: "Reach",
      dataIndex: "reach",
      key: "reach",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.reach - b?.reach,
      searchType: "number",
    },
  ];

  if (!loading) {
    finalMergeData = landerSpendReport?.map((element, indx) => {
      const {
        spend = 0,
        spendTiktok = 0,
        inline_link_clicks = 0,
        clicks = 0,
        clicksTiktok = 0,
        impressionsFB = 0,
        impressions = 0,
        result = 0,
        conversions = 0,
        reach = 0,
      } = element;
      return {
        ...element,
        spend: spend || spendTiktok,
        inline_link_clicks: inline_link_clicks,
        clicks: clicks || clicksTiktok,
        impressions: impressionsFB || impressions,
        conversions: result || conversions,
        reach,
      };
    });
  }

  const finalFilterData = finalMergeData.filter(({ lander_url = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (lander_url === null || lander_url?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_clicks = 0,
    total_impressions = 0,
    total_spend = 0;

  for (const { spend = 0, clicks = 0, impressions = 0 } of finalFilterData) {
    total_impressions += parseFloat(impressions || 0);
    total_clicks += parseFloat(clicks || 0);
    total_spend += parseFloat(spend || 0);
  }

  const summaryData = {
    impressions: numberParse(total_impressions),
    spend: numberParse(total_spend, { toFixed: true }),
    clicks: numberParse(total_clicks),
  };

  const rightOptions = (
    <>
      <span className="mr-20">
        <Segmented
          options={["Facebook", "Tiktok"]}
          value={platformType}
          onChange={setPlatformType}
        />
      </span>
    </>
  );

  const tableMainProps = {
    persistKey,
    exportCSV: true,
    isDateRange: true,
    defaultPagination: true,
    refetch,
    loading,
    rightOptions,
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ landerSpendReport }, { finalFilterData }] },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default LanderReport;
