import * as types from "../actions/actionTypes";

export default function twitterReducer(state = { refetch_twitter: false }, action) {
  switch (action.type) {
    case types.TWITTER_PROFILE_REFETCH_TRUE:
      return { refetch_twitter: true };
    case types.TWITTER_PROFILE_REFETCH_FALSE:
      return { refetch_twitter: false };
    default:
      return state;
  }
}
