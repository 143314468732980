import React from "react";
import ActionIconRender from "components/ActionIconRender";
import Avatar from "antd/es/avatar/avatar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function ProfilePage() {
  const history = useHistory();
  const userSelector = useSelector((state) => state?.userReducer?.user);
  const nameArray = userSelector?.name?.split(" ");
  const userInitials = nameArray?.map((data) => data.charAt(0));
  const profilePic = useSelector((state) => state?.userReducer.me?.picture);

  return (
    <div
      className="title mb-12 pointer"
      style={{ width: "200px" }}
      onClick={() => history.push("/user/profile")}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {profilePic ? (
          <Avatar className="mr-6" src={profilePic}></Avatar>
        ) : (
          <Avatar className="mr-6">{userInitials}</Avatar>
        )}
        <span className="profile-name">{userSelector.name}</span>
      </div>
      <div>
        <ActionIconRender iconType="setting" style={{ fontSize: 16 }} />
      </div>
    </div>
  );
}
