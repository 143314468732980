import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { CREATE_SAS_KEYWORD } from "shared/gql/gqlSchema/sasGql";
import { Button, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import { openNotification } from "utils";
import useDispatchFunctionsHooks from "hooks/useDispatchFunctionsHooks";

export const useColumns = (category) => {
  const { clearBulkAction } = useDispatchFunctionsHooks();
  const [createSaSKeywords] = useCustomMutation(CREATE_SAS_KEYWORD, {
    onCompleted: () => {
      openNotification({ type: "success", description: "Keyword Created" });
      clearBulkAction();
    },
  });
  const initialColumns = [
    {
      title: "Keyword (by relevance)",
      dataIndex: "text",
      key: "text",
      searchable: true,
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 80,
      minWidth: 80,
    },
    {
      title: "Avg. monthly searches",
      dataIndex: "avgMonthlySearches",
      key: "avgMonthlySearches",
      searchable: true,
      render: (value) => <LookTableColumnRender title={value} />,
      sorter: (a, b) => Number(a?.avgMonthlySearches) - Number(b?.avgMonthlySearches),
      width: 60,
      minWidth: 60,
    },
    {
      title: "Competition",
      dataIndex: "competition",
      key: "competition",
      searchable: true,
      render: (value) => <LookTableColumnRender title={value} />,
      width: 40,
      minWidth: 40,
      // sorter: (a, b) => {
      //   return a?.competition?.toLowerCase().localeCompare(b?.competition?.toLowerCase());
      // },
    },
    {
      title: "Competition (index based)",
      dataIndex: "competitionIndex",
      key: "competitionIndex",
      searchable: true,
      render: (value) => <LookTableColumnRender title={value} />,
      sorter: (a, b) => Number(a?.competitionIndex) - Number(b?.competitionIndex),

      width: 70,
      minWidth: 70,
    },
    {
      title: "Top of page bid (low range)",
      dataIndex: "lowTopOfPageBidMicros",
      key: "lowTopOfPageBidMicros",
      searchable: true,
      render: (value) => <LookTableColumnRender title={"$" + value} />,
      sorter: (a, b) => Number(a?.lowTopOfPageBidMicros) - Number(b?.lowTopOfPageBidMicros),

      width: 60,
      minWidth: 60,
    },
    {
      title: "Top of page bid (low range)",
      dataIndex: "highTopOfPageBidMicros",
      key: "highTopOfPageBidMicros",
      searchable: true,
      render: (value) => <LookTableColumnRender title={"$" + value} />,
      sorter: (a, b) => Number(a?.highTopOfPageBidMicros) - Number(b?.highTopOfPageBidMicros),
      width: 60,
      minWidth: 60,
    },
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        return (
          <Tooltip title={"Add to Keywords"}>
            <Button
              type="link"
              onClick={() => {
                if (!category || category === "")
                  openNotification({
                    type: "error",
                    description: "Category not selected",
                    title: "Please select category",
                  });
                else
                  createSaSKeywords({
                    variables: {
                      name: [actionProps.text],
                      status: "ACTIVE",
                      isSensitive: false,
                      categoryId: category,
                      keywordSourceId: 5,
                    },
                  });
              }}
              icon={<ActionIconRender iconType="appstore-add" />}
            />
          </Tooltip>
        );
      },
      fixed: "right",
      width: 30,
    },
  ];
  return { initialColumns, createSaSKeywords };
};
