import { Card } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import cx from "cx";

export default function PlatformCards(props) {
  const { className = "", icon, setTemplatePlatforms = () => {}, setStep = () => {}, id } = props;
  // const { image = "", name = "", id } = dataItems || {};

  const debugContent = [{ isRow: true, contentObjects: [{ props }] }];

  return (
    <>
      <Card className={cx("card-vs-content theme-parent p-ch-0 mr-20", className)}>
        {/* <div className="position-relative hover-action-wrapper">
          <div className="image-view">
            <Image preview={false} src={image} />
          </div>
        </div> */}
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
            alignItems: "center",
            width: "200px",
            height: " 100px",
            justifyContent: "center",
          }}
          onClick={() => {
            setStep(1);
            setTemplatePlatforms(id);
          }}
        >
          <ActionIconRender iconType={icon} style={{ fontSize: "60px", color: "#10B044" }} />
        </div>
        <div className="content-btn flex justify-content-around" style={{ minHeight: 32 }}>
          <div className="flex-center">{icon.toUpperCase()}</div>
        </div>
      </Card>
    </>
  );
}
