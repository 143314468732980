import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { deepClone, openNotification } from "utils";
import { TIKTOK_REJECTED_ASSETS } from "shared/gql/gqlSchema/tiktok";
import SelectInput from "components/forms/SelectInput";
import {
  TIKTOK_ASSET_TYPE_AD,
  TIKTOK_ASSET_TYPE_ADGROUP,
  TIKTOK_HOURLY_TYPE_AD,
  TIKTOK_HOURLY_TYPE_ADGROUP,
} from "shared/enum/tiktokKeysAndEnum";
import { Button, Tooltip } from "antd";
import { useTiktokRedirect } from "../TiktokRedirect";
import ActionIconRender from "components/ActionIconRender";
import Loader from "components/loader";

function RejectedAssetsReport(props) {
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const [assetsData, setAssetsData] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  const [rangeDates, setRangeDates] = useState(dateRangeTimezone?.rangeDates);
  const [timeZone, setTimeZone] = useState(dateRangeTimezone?.timeZone);
  const [selectedAsset, setSelectedAsset] = useState(TIKTOK_ASSET_TYPE_ADGROUP);
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);
  let redirectType = "";
  if (selectedAsset === TIKTOK_ASSET_TYPE_ADGROUP) {
    redirectType = TIKTOK_HOURLY_TYPE_ADGROUP;
  }
  if (selectedAsset === TIKTOK_ASSET_TYPE_AD) {
    redirectType = TIKTOK_HOURLY_TYPE_AD;
  }
  const { tiktokRedirect = () => {}, loadingTiktokRedirect = false } = useTiktokRedirect({
    redirectType,
  });
  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  let finalFilterData = [];

  const { loading: loading_REJECTED_ASSETS_LISTING, refetch: refetch_REJECTED_ASSETS_LISTING } =
    useCustomQuery(TIKTOK_REJECTED_ASSETS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.tiktok_assetStatusReport;
        if (element) {
          setAssetsData(element);
        }
      },
      onError: (err) => {
        openNotification({ type: "error", message: err.message });
      },
      variables: {
        start: rangeDates[0],
        end: rangeDates[1],
        timeZone,
        asset_type: selectedAsset,
      },
    });

  const refetch = () => {
    refetch_REJECTED_ASSETS_LISTING();
  };
  const loading = loading_REJECTED_ASSETS_LISTING;

  const columnRender =
    selectedAsset === TIKTOK_ASSET_TYPE_ADGROUP
      ? [
          {
            title: "Adgroup Name",
            dataIndex: "adgroup_name",
            key: "adgroup_name",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            fixed: "left",
            width: 160,
            minWidth: 160,
            searchType: "string",
          },
          {
            title: "Adgroup Id",
            dataIndex: "adgroup_id",
            key: "adgroup_id",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 160,
            minWidth: 160,
            searchType: "string",
          },
        ]
      : [
          {
            title: "Ad name",
            dataIndex: "ad_name",
            key: "ad_name",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            fixed: "left",
            width: 160,
            minWidth: 160,
            searchType: "string",
          },
          {
            title: "Ad Id",
            dataIndex: "ad_id",
            key: "ad_id",
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 160,
            minWidth: 160,
            searchType: "string",
          },
        ];

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, otherProps) => {
        const { adgroup_id, ad_id } = otherProps;
        let id = "";
        if (selectedAsset === TIKTOK_ASSET_TYPE_ADGROUP) {
          id = adgroup_id;
        }
        if (selectedAsset === TIKTOK_ASSET_TYPE_AD) {
          id = ad_id;
        }
        return (
          <Tooltip title="Edit">
            <Button
              className="ml-12 p-0 h-auto w-auto"
              type="link"
              icon={<ActionIconRender iconType="view" />}
              onClick={() => {
                tiktokRedirect(id);
              }}
            />
          </Tooltip>
        );
      },
      fixed: "left",
      width: 90,
      minWidth: 90,
      searchType: "string",
    },
  ];

  const initialColumns = deepClone([
    ...columnRender,
    {
      title: "Account Id",
      dataIndex: "account_id",
      key: "account_id",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.account_id - b?.account_id,
      searchType: "string",
    },
    ...action,
  ]);

  if (!loading) {
    finalFilterData = outerFilterData(filterData(assetsData), {
      filterKeys: ["adgroup_name", "adgroup_id", "ad_name", "ad_id", "account_id"],
    });
  }
  const assetTypeList = [
    { name: "Adgroup", value: TIKTOK_ASSET_TYPE_ADGROUP },
    { name: "Ad", value: TIKTOK_ASSET_TYPE_AD },
  ];
  const rightOptions = (
    <SelectInput
      value={selectedAsset}
      className="mb-0 w-30"
      onChange={(e) => {
        setSelectedAsset(e);
      }}
      options={assetTypeList?.map(({ name, value }) => ({
        name,
        value,
      }))}
    />
  );

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    isRecommendation: true,
    defaultPagination: true,
    refetch,
    rightOptions,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData },
    persistKey,
    dateRangeProps: {
      onChange: handleDateRange,
    },
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ assetsData }, { finalFilterData }],
    },
  ];

  return (
    <div>
      {loadingTiktokRedirect && (
        <Loader title={"Redirecting to " + redirectType + "..."} bgBlur fullScreen center />
      )}
      {selectedAsset === TIKTOK_ASSET_TYPE_ADGROUP && <LookTable {...tableMainProps} />}
      {selectedAsset === TIKTOK_ASSET_TYPE_AD && <LookTable {...tableMainProps} />}
      <DebugPre content={debugContent} />
    </div>
  );
}

export default RejectedAssetsReport;
