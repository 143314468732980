import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import { DUPLICATE_CAMPS, GET_CAMPAIGN_DETAILS } from "shared/gql/gqlSchema/leadgenGql";
import { openNotification } from "utils";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { REPORT_TYPE_AD_ADSET } from "shared/SharedKeys";
import Loader from "components/loader";
import { FB360_GET_AD_ACCOUNTS_DROPDOWN } from "shared/gql/gqlSchema/fb360Gql";
import { LAUNCH_CUSTOM_CONVERSIONS_PIXELS } from "shared/gql/gqlSchema/launchGql";
import { Switch } from "antd";
import { GET_SAS_TRACKING_URL } from "shared/gql/gqlSchema/sasGql";

export default function DuplicateCampaigns(props) {
  const {
    status,
    reportType = "",
    onCloseCampaignModal = () => {},
    adsetId = "",
    adset_id = "",
    campaign_name: propName = "",
    productId,
    isLfLaunched = {},
  } = props;
  const propCampaignId = props.campaignId || props?.campaign_id;

  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [isError, setIsError] = useState("");
  const [campaignDetails, setCampaignDetails] = useState({});
  const [adaccountsData, setAdaccountsData] = useState([]);
  const [selectedAdaccount, setSelectedAdaccount] = useState("");
  const [pagesData, setPagesData] = useState({});
  const [isMultiAccountClone, setIsMultiAccountClone] = useState(false);
  const [sasTrackingUrls, setSasTrackingUrl] = useState([]);

  const [duplicateCampaigns, { loading }] = useCustomMutation(DUPLICATE_CAMPS, {
    onCompleted(e) {
      const element = e?.copyCampaignsAdsetsAds;
      onCloseCampaignModal();
      const { message = "", success = false, title = "" } = element || {};
      openNotification({
        type: success ? "success" : "error",
        message: success ? message : title || message,
      });
    },
    onError(e) {
      openNotification({
        type: "error",
        message: e.message,
      });
    },
  });
  const [fetch_campaignDetails, { loading: loading_fetchCampaignDetails }] = useCustomLazyQuery(
    GET_CAMPAIGN_DETAILS,
    {
      notifyOnNetworkStatusChange: true,

      onCompleted: (e) => {
        setCampaignDetails(e?.campaignDetails);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  const [fetch_adaccounts, { loading: loading_adaccounts }] = useCustomLazyQuery(
    FB360_GET_AD_ACCOUNTS_DROPDOWN,
    {
      notifyOnNetworkStatusChange: true,

      onCompleted: (e) => {
        setAdaccountsData(e?.fb360_adaccountGet);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );
  const [fetch_pages, { loading: loading_pages }] = useCustomLazyQuery(
    LAUNCH_CUSTOM_CONVERSIONS_PIXELS,
    {
      notifyOnNetworkStatusChange: true,

      onCompleted: (e) => {
        setPagesData(e?.customConversionsPixels);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  const [fetch_sasTrackingUrl, { loading: loading_fetch_sasTrackingUrl }] = useCustomLazyQuery(
    GET_SAS_TRACKING_URL,
    {
      notifyOnNetworkStatusChange: true,

      onCompleted: (e) => {
        setSasTrackingUrl(e?.sasTrackingUrls);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  useEffect(() => {
    if (reportType === REPORT_TYPE_AD_ADSET) {
      fetch_campaignDetails({ variables: { adsetId: adsetId || adset_id } });
    }
    if (isMultiAccountClone) {
      fetch_sasTrackingUrl();
      if (selectedAdaccount) {
        fetch_pages({
          variables: {
            accountId: "act_" + selectedAdaccount,
            product_id: productId,
            hard_sync: true,
          },
        });
      } else {
        fetch_adaccounts();
      }
    }
  }, [selectedAdaccount, isMultiAccountClone]);
  const resetFormRef = useRef(() => {});

  const clearError = () => {
    isError !== "" && setIsError("");
  };

  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const {
      count,
      budget,
      isLifeTime,
      status,
      name,
      budgetOptimization,
      adaccount,
      page,
      pixel,
      trackingUrl,
    } = values;
    const isMultiAdaccount =
      adaccount && page && pixel
        ? {
            multiAccountData: [
              {
                accountId: adaccount,
                pageId: page,
                pixelId: pixel,
                productId: productId,
                trackingUrl,
              },
            ],
          }
        : {};
    let submitObject = {
      ...isMultiAdaccount,
      cloneData: {
        camp: reportType === REPORT_TYPE_AD_ADSET ? campaignDetails?.campaignId : propCampaignId,
        count,
        status,
        name,
        startTime: startDateTime || null,
        endTime: endDateTime || null,
        budgetOptimization,
      },
      multiAccountClone: isMultiAccountClone,
    };
    if (isLifeTime) {
      submitObject.cloneData.lifetimeBudget = budget;
    } else {
      submitObject.cloneData.dailyBudget = budget;
    }
    duplicateCampaigns({
      variables: {
        ...submitObject,
      },
    });
    clearError();
  }

  const initialValues = {
    budget: "",
    isLifeTime: false,
    status,
    budgetOptimization: "",
    count: "",
    page: "",
    adaccount: "",
    pixel: "",
    trackingUrl: [],
    name: reportType === REPORT_TYPE_AD_ADSET ? campaignDetails?.campaignName : propName,
  };

  const adAccAndPageValidation = isMultiAccountClone
    ? {
        adaccount: yup.string().required().label("adaccount"),
        page: yup.string().required().label("page"),
        pixel: yup.string().required().label("pixel"),
        trackingUrl: yup
          .array()
          .of(yup.object())
          .min(1, "Please select at least one Tracking Url")
          .nullable()
          .label("Tracking Url"),
      }
    : {};

  const validationSchema = yup.object({
    budget: yup.number().required().label("budget"),
    count: yup.number().required().label("count"),
    ...adAccAndPageValidation,
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    btnText: "Create",
    reportType,
    startDateTime,
    setStartDateTime,
    endDateTime,
    setEndDateTime,
    adaccountsData,
    loading_adaccounts,
    setSelectedAdaccount,
    pagesData,
    loading_pages,
    isMultiAccountClone,
    setIsMultiAccountClone,
    sasTrackingUrls,
    loading_fetch_sasTrackingUrl,
  };

  return (
    <>
      {loading_fetchCampaignDetails ? (
        <Loader elementCenter className="mt-25" />
      ) : (
        <div>
          {isLfLaunched?.status && (
            <div className="d-flex align-items-end">
              <p className="mb-0 mr-8">Clone in</p>
              <Switch
                checkedChildren="Another"
                unCheckedChildren="Same"
                size="medium"
                checked={isMultiAccountClone}
                onChange={(e) => {
                  setIsMultiAccountClone(!isMultiAccountClone);
                }}
              />
              <p className="mb-0 ml-8">Account</p>
            </div>
          )}
          <MainFrom {...formProps} />
        </div>
      )}
    </>
  );
}
