import {
  GlobalOutlined,
  ApartmentOutlined,
  LaptopOutlined,
  MediumOutlined,
  HourglassOutlined,
  BarChartOutlined,
  FieldTimeOutlined,
  GroupOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_8,
} from "actions/actionTypes";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import VSCountryReport from "../../VSCountryReport";
import VSCreative from "../../VSCreative";
import VSDeviceReport from "../../VSDeviceReport";
import VSHourlyReport from "../../VSHourlyReport";
import VSHourOfDayReport from "../../VSHourOfDayReport";
import VSMediumReport from "../../VSMediumReport";
import VSOsReport from "../../VSOsReport";
import VSPerformance from "../../VSPerformanceReport";
import VSTags from "../../VSTags";
export default function VSCampaignReportDetailView(props) {
  const { visible = false, title = "", onClose, utmCampaign = "", adName = "" } = props;

  const creativeVisible = adName
    ? [
        {
          id: "Creatives",
          hoverTitle: "Creatives",
          icon: GroupOutlined,
          component: (
            <VSCreative
              {...props}
              performance
              persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_8}
            />
          ),
          noSpace: true,
        },
      ]
    : [];

  const tabContent = [
    {
      id: "CountryReport",
      hoverTitle: "Country Report",
      icon: GlobalOutlined,
      component: (
        <VSCountryReport
          {...props}
          performance
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
        />
      ),
      noSpace: true,
    },
    {
      id: "Device-Report",
      hoverTitle: "Device Report",
      icon: ApartmentOutlined,
      component: (
        <VSDeviceReport
          {...props}
          performance
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
        />
      ),
      noSpace: true,
    },
    {
      id: "OS-Report",
      hoverTitle: "OS Report",
      icon: LaptopOutlined,
      component: (
        <VSOsReport {...props} performance persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3} />
      ),
      noSpace: true,
    },
    {
      id: "Medium-Report",
      hoverTitle: "Medium Report",
      icon: MediumOutlined,
      component: (
        <VSMediumReport
          {...props}
          performance
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4}
        />
      ),
      noSpace: true,
    },
    {
      id: "Performance-Report",
      hoverTitle: "Performance Report",
      icon: BarChartOutlined,
      component: (
        <VSPerformance {...props} performance persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5} />
      ),
      noSpace: true,
    },
    {
      id: "Hourly-Report",
      hoverTitle: "Hourly Report",
      icon: HourglassOutlined,
      component: (
        <VSHourlyReport
          {...props}
          performance
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6}
        />
      ),
      noSpace: true,
    },
    {
      id: "Hour-of-the-Day-Report",
      hoverTitle: "Hour of the Day Report",
      icon: FieldTimeOutlined,
      component: (
        <VSHourOfDayReport
          {...props}
          performance
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7}
        />
      ),
      noSpace: true,
    },

    ...creativeVisible,
    {
      id: "Tags",
      hoverTitle: "Tags",
      icon: TagsOutlined,
      component: <VSTags {...props} />,
    },
  ];

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      // size={600}
      visible={visible}
      onClose={onClose}
      title={title + ": " + (utmCampaign || adName)}
    >
      {visible && <LookDrawerMainSideBar tabContent={tabContent} />}
    </LookDrawer>
  );
}
