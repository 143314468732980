import { Button, Switch, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import {
  UPDATE_SPEND_STATUS_TYPE_PAUSED,
  UPDATE_SPEND_STATUS_TYPE_ACTIVE,
} from "hooks/spendActivePauseStatusUpdateHooks";
import React from "react";
import { UPDATE_FB360_STATUS } from "shared/gql/gqlSchema/fb360Gql";
import { REPORT_TYPE_AD, REPORT_TYPE_AD_ADSET, REPORT_TYPE_CAMPAIGN } from "shared/SharedKeys";
import { fbAdsManagerRedirect, openNotification } from "utils";
import { fbGroupForStatus } from "../UpdateCampaignBudget/utils";

export function useUpdateFB360Status(
  node,
  { fbStatusCallBack = () => {}, setSpendReport = () => {}, lengthCheck = 15 }
) {
  const { selectedRowsClear } = useLookTableParentContext();

  function updateReport(currentData) {
    setSpendReport((pre) => {
      const temp = JSON.parse(JSON.stringify(pre)).map((tData) => {
        const newDataIndex = currentData?.findIndex(({ id }) => {
          if (node === REPORT_TYPE_CAMPAIGN) {
            return id === tData?.campaign_id;
          } else if (node === REPORT_TYPE_AD_ADSET) {
            return id === tData?.adset_id;
          } else if (node === REPORT_TYPE_AD) {
            return id === tData?.ad_id;
          }
          return false;
        });

        const isNewStatus =
          newDataIndex !== -1 ? { status: currentData[newDataIndex]?.status } : {};

        return { ...tData, ...isNewStatus };
      });

      return temp;
    });
  }

  const [updateFb360StatusApi, { loading }] = useCustomMutation(UPDATE_FB360_STATUS, {
    onCompleted: (e) => {
      const success = e?.fb360_bulkUpdateStatus?.success;
      const err = e?.fb360_bulkUpdateStatus?.error;
      if (success?.length > 0) {
        fbStatusCallBack(success);
        updateReport(success);
        selectedRowsClear();
        openNotification({
          type: "success",
          message: success?.map(({ message }) => message)?.join(","),
        });
      }
      if (err?.length > 0) {
        openNotification({
          type: "error",
          message: err?.map(({ message }) => message)?.join(","),
        });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function updateFb360Status(status, selectedData = []) {
    if (selectedData.length > lengthCheck) {
      return openNotification({ type: "warning", message: "Please select max 15 records" });
    }

    const payload = {
      asset: node?.toUpperCase(),
      updateArray: selectedData,
      status,
    };

    updateFb360StatusApi({ variables: payload });
  }

  return { updateFb360Status, updateFb360Status_loading: loading };
}

export function ActionsForFacebook(props) {
  const {
    reportType,
    showBudgetDrawer = () => {},
    setSpendReport = () => {},
    actionProps = {},
    isActionDisableForSAS = false, // check to disable action buttons for Data team members
    isSasAction = false,
  } = props;

  const {
    status,
    ad_id,
    campaign_id,
    adset_id,
    spendAdsetId,
    spendCampaignId,
    spendAdId,
    adAccountId,
    daily_budget = 0,
    lifetime_budget = 0,
  } = actionProps;

  const callStatus =
    status !== "PAUSED" ? UPDATE_SPEND_STATUS_TYPE_PAUSED : UPDATE_SPEND_STATUS_TYPE_ACTIVE;

  const { updateFb360Status, updateFb360Status_loading } = useUpdateFB360Status(reportType, {
    setSpendReport,
  });

  const spendAssetId = spendCampaignId || spendAdsetId || spendAdId;
  const revenueAssetId = campaign_id || adset_id || ad_id;

  return (
    <div className="action-column d-flex justify-content-around">
      {spendAssetId ? (
        <Switch
          disabled={updateFb360Status_loading || isActionDisableForSAS}
          size="small"
          checked={actionProps.status !== "PAUSED"}
          onClick={() => {
            const groupIds = fbGroupForStatus([actionProps], reportType);
            updateFb360Status(callStatus, groupIds);
          }}
        />
      ) : (
        <Switch disabled size="small" />
      )}

      {reportType !== REPORT_TYPE_AD &&
        (daily_budget || lifetime_budget ? (
          <Tooltip title="Update Budget">
            <Button
              className="ml-12 p-0 h-auto w-auto"
              type="link"
              onClick={() => showBudgetDrawer(actionProps)}
              icon={<ActionIconRender iconType="dollar" />}
              disabled={isActionDisableForSAS}
            />
          </Tooltip>
        ) : (
          <Button
            className="ml-12 p-0 h-auto w-auto"
            type="link"
            icon={<ActionIconRender iconType="dollar" />}
            disabled
          />
        ))}

      {isSasAction &&
        (revenueAssetId ? (
          <Tooltip title="Edit">
            <Button
              className="ml-12 p-0 h-auto w-auto"
              // disabled={isActionDisableForSAS}
              type="link"
              icon={<ActionIconRender iconType="view" />}
              onClick={() =>
                fbAdsManagerRedirect({
                  reportType: reportType,
                  adaccountId: adAccountId,
                  id: revenueAssetId,
                })
              }
            />
          </Tooltip>
        ) : (
          <Button
            className="ml-12 p-0 h-auto w-auto"
            type="link"
            icon={<ActionIconRender iconType="view" />}
            disabled
          />
        ))}
    </div>
  );
}
