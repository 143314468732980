import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import LookTextInput from "components/forms/LookTextInput";
import { useEffect, useRef, useState } from "react";
import { removeObjectByIndex } from "utils";

function ContentListItem(props) {
  const { itemValue = "", onUpdate = () => {}, onDelete = () => {} } = props;

  const editInputRef = useRef(null);
  const [value, setValue] = useState(itemValue);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (editMode) {
      editInputRef.current.focus();
    }
  }, [editMode]);

  const activeEditMode = () => {
    setEditMode(true);
  };

  const cancelEditMode = () => {
    setEditMode(false);
    setValue(itemValue);
  };

  const onSave = () => {
    onUpdate(value);
    setEditMode(false);
  };

  const actionButtons = editMode ? (
    <>
      <LookButton iconType="check" onClick={onSave} />
      <LookButton iconType="close" onClick={cancelEditMode} />
    </>
  ) : (
    <>
      <LookButton iconType="edit" onClick={activeEditMode} />
      <LookButton iconType="delete" onClick={onDelete} />
    </>
  );

  return (
    <div className="list-item">
      <div className="input-wrapper">
        {editMode ? (
          <LookTextInput
            ref={editInputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <p className="text">{itemValue}</p>
        )}
      </div>
      <div className="action-wrapper">{actionButtons}</div>
    </div>
  );
}

function Content(props) {
  const { label = "", maxCount = 1, min, name = "", value = [], handleFormInputs } = props;
  const [newValue, setNewValue] = useState("");

  const onAddNewItem = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (e.target.value.trim()) {
        onAddNew(e.target.value.trim());
      }
    }
  };

  const onAddNew = (latestValue) => {
    let temp = JSON.parse(JSON.stringify(value));
    temp.push(latestValue);
    handleFormInputs(name, temp);
    setNewValue("");
  };

  const onUpdate = (index, latestValue) => {
    let temp = JSON.parse(JSON.stringify(value));
    temp[index] = latestValue;
    handleFormInputs(name, temp);
  };

  const onDelete = (index) => {
    let temp = JSON.parse(JSON.stringify(value));
    temp = removeObjectByIndex(temp, index);
    handleFormInputs(name, temp);
  };

  const renderList = () => (
    <div className="bordered-input-listing-form">
      <div className="creation-input-wrapper">
        <ActionIconRender iconType="plus" />
        <LookTextInput
          placeholder={`Click here to add a new ${label?.toLowerCase()}`}
          disabled={maxCount <= value?.length}
          value={newValue}
          onKeyDown={onAddNewItem}
          onChange={(e) => setNewValue(e.target.value)}
        />
      </div>
      <div className="main-list">
        {value?.map((data, index) => (
          <ContentListItem
            key={"itemValue" + index}
            itemValue={data}
            itemIndex={index}
            {...{ onAddNew, onUpdate: (e) => onUpdate(index, e), onDelete: () => onDelete(index) }}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="mb-40">
      <h2 className="font-size-16 d-flex justify-content-between align-items-end">
        <span>
          {label}s&nbsp;
          <span className="font-size-14" style={{ opacity: 0.7 }}>
            ({value?.length}/{maxCount})
          </span>
          {min && <span className="text-danger">*</span>}
        </span>
        {min && <span style={{ fontSize: ".7rem", opacity: 0.7 }}>(Minimum {min} required)</span>}
      </h2>
      {renderList()}
    </div>
  );
}

export default function ContentTextData(props) {
  const { contents = [], handleFormInputs = () => {} } = props;

  return (
    <div>
      {contents?.map((data, index) => (
        <Content key={"contents" + index} {...data} {...{ handleFormInputs }} />
      ))}
    </div>
  );
}
