import moment from "moment";
import { setCookie, getCookie } from "./cookie";
import { numCommaSeparator, numCompactShort } from "./numFormatter";
import parse from "html-react-parser";
import { hslToHex, generateHexRandomColors } from "./colors";
import { fbAdsManagerRedirect } from "./reportUtils";
import { ClickToCopyButton } from "./clickToCopy";

export const scrollToTop = () => window.scrollTo({ top: 0 });
export const scrollToTopSmooth = () => window.scrollTo({ behavior: "smooth", top: 0 });

export const scrollToSection = (sectionId, extraProps = {}) => {
  const { relativeId = "", offSetTop } = extraProps;
  const id = document.getElementById(sectionId);
  const header = document.querySelector("header");
  if (relativeId) {
    const relativeElement = document.getElementById(relativeId);
    relativeElement?.scrollTo({
      behavior: "smooth",
      top: id?.offsetTop + offSetTop - header?.offsetHeight,
      left: 0,
    });
  } else {
    window.scrollTo({ behavior: "smooth", top: id.offsetTop - header?.offsetHeight, left: 0 });
  }
};

// * Typography
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}
export function valueArrayToNameValueArray(arrayData) {
  return arrayData.map((data) => ({
    name: capitalizeFirstLetter(data?.replaceAll("_", " ")?.toLowerCase()),
    value: data,
  }));
}

export const urlValidateRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export { default as passwordValidate } from "./passwordValidate";
export { default as urlYupValidate } from "./urlYupValidate";
export { default as useUser } from "./useUser";
export { default as openNotification } from "./openNotification";
export {
  setCookie,
  getCookie,
  numCommaSeparator,
  numCompactShort,
  hslToHex,
  generateHexRandomColors,
  fbAdsManagerRedirect,
  ClickToCopyButton,
};

export const renderError = (error) => <div className="text-left error-text">{parse(error)}</div>;

export function deepClone(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj; // Return the value if obj is not an object
  }

  if (moment.isMoment(obj)) {
    return moment(obj); // Clone the Moment object
  }

  const clone = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (typeof obj[key] === "function") {
      clone[key] = obj[key]; // Assign function reference
    } else {
      clone[key] = deepClone(obj[key]); // Recursively clone nested objects
    }
  }

  return clone;
}
export const anyTimeZoneToLocal = (inputDate, timeZone) => {
  const inputMoment = moment.tz(inputDate, timeZone?.value);
  const localMoment = inputMoment.local();
  return localMoment.format("YYYY-MM-DD HH:mm:ss");
};

export const numberParse = (value, extraprops = {}) => {
  const { toFixed = false, toFixedUpTo = 2 } = extraprops;

  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (toFixed) return valueFloat.toFixed(toFixedUpTo);
  return valueFloat;
};

export function detectValueType(value) {
  if (typeof value === "string") {
    return "string";
  }

  if (Array.isArray(value)) {
    return "array";
  }

  if (typeof value === "object" && value !== null) {
    return "object";
  }

  if (typeof value === "number") {
    return "number";
  }

  if (typeof value === "boolean") {
    return "boolean";
  }

  if (value === null) {
    return "null";
  }

  if (value === undefined) {
    return "undefined";
  }

  if (typeof value === "function") {
    return "function";
  }

  if (typeof value === "symbol") {
    return "symbol";
  }

  return "unknown";
}

// for sorting users , these priority user will come on top
export function sortUserList(priorityUsers, allUsers) {
  console.log({ priorityUsers, allUsers });
  // Create a Set of user IDs from priorityUsers
  const priorityUserIds = new Set(priorityUsers?.map((user) => user?.user_id));
  // Filter allUsers list to remove duplicates found in priorityUsers
  const filteredUsers = allUsers?.filter((user) => !priorityUserIds.has(user?.id));
  // filteredUsers should be less
  if (filteredUsers?.length !== allUsers?.length) {
    // Concatenate priorityUsers and the filtered allUsers
    const combinedList = priorityUsers?.concat(filteredUsers);
    return combinedList;
  }
  return allUsers;
}

export function removeObjectById(arr, id) {
  const index = arr.findIndex((obj) => obj.id === id); // Find index of the object to remove
  if (index !== -1) {
    arr.splice(index, 1); // Remove the object from the array
  }
  return arr; // Return the updated array
}

export function removeObjectByIndex(arr, index) {
  if (index !== -1) {
    arr.splice(index, 1); // Remove the object from the array
  }
  return arr; // Return the updated array
}

export function getQuarterEndDate(input) {
  const [year, q] = input.split("-Q");
  const quarterNumber = parseInt(q);

  // Start from the beginning of the year, then add the necessary months
  let date = moment(year).add((quarterNumber - 1) * 3, "months");

  // Now add 3 months for the start of the next quarter and subtract 1 day to get the end of this quarter
  return date.add(3, "months").subtract(1, "day").format("YYYY-MM-DD");
}

export function secondsToHMS(seconds) {
  if (seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  return 0;
}

export function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}
