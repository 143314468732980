import * as types from "../actions/actionTypes";

export default function callBackReducer(state = { refetch_google: false }, action) {
  switch (action.type) {
    case types.CALL_BACK_REFETCH_TRUE:
      return { refetch_onCallback: true };
    case types.CALL_BACK_REFETCH_FALSE:
      return { refetch_onCallback: false };
    default:
      return state;
  }
}
