import moment from "moment";
import _ from "lodash";

export default function useTimezones() {
  function timeZones() {
    const timeZones = moment.tz.names();
    const offsetTmz = [];

    for (const i in timeZones) {
      const tz = moment.tz(timeZones[i]).format("Z").replace(":00", "").replace(":30", ".5");
      let x = tz === 0 ? 0 : parseInt(tz).toFixed(2);

      const timeZone = {
        label: `(GMT${moment.tz(timeZones[i]).format("Z")})${timeZones[i]}`,
        value: `${timeZones[i]}`,
        time: `${x}`,
      };
      offsetTmz.push(timeZone);
    }

    return _.sortBy(offsetTmz, [
      function (el) {
        return -el.time;
      },
    ]);
  }
  return { timeZones: timeZones() };
}
