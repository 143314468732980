import { USER_SETTING_SEARCH_FILTER } from "actions/actionTypes";
import { Button, Form, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookModal from "components/LookModal";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTableContext } from "../fields";
import FilterFields from "./FilterFields";
import { useRouteMatch } from "react-router-dom";
import cx from "cx";

function LookTableFilterSearchForm(props) {
  const routeMatch = useRouteMatch();
  const { path } = routeMatch;
  const { close = () => {} } = props;
  const { persistKey, columns: mainColumns, lastColumns = [] } = useTableContext();
  const uniqueKey = path + persistKey;
  const dispatch = useDispatch();
  const { searchFilter } = useSelector((state) => state?.userSettings) || {};
  const { [uniqueKey]: previousFilterState } = searchFilter || {};
  const defaultFilterState = { filterColumns: [{}] };
  const [localFilter, setLocalFilter] = useState(previousFilterState || defaultFilterState);
  const { filterColumns } = localFilter;

  const columns = [...mainColumns, ...lastColumns];

  function reset() {
    close();
    setLocalFilter(defaultFilterState);
    dispatch({ type: USER_SETTING_SEARCH_FILTER, payload: { ...searchFilter, [uniqueKey]: {} } });
  }

  function handleFilter() {
    close();
    dispatch({
      type: USER_SETTING_SEARCH_FILTER,
      payload: { ...searchFilter, [uniqueKey]: { ...searchFilter?.[uniqueKey], ...localFilter } },
    });
  }

  function addNewFilter() {
    setLocalFilter((pre) => {
      const { filterColumns: filterColumnsOld = [] } = pre || {};
      return { ...setLocalFilter, filterColumns: [...filterColumnsOld, {}] };
    });
  }

  function handleFilterOptions(index, type = "", data) {
    const temp = JSON.parse(JSON.stringify(filterColumns));

    if (type === "columnSelection") {
      const columnIndex = columns.findIndex((e) => e.dataIndex === data);
      temp[index] = {
        ...temp[index],
        selectedColumn: data,
        searchType: columns[columnIndex]?.searchType,
      };
    }

    if (type === "filterColumns") {
      temp[index] = { ...temp[index], ...data };
    }
    setLocalFilter((pre) => ({ ...pre, filterColumns: temp }));
  }

  function handleDelete(index) {
    setLocalFilter((pre) => ({
      ...pre,
      filterColumns: filterColumns?.filter((item, ind) => index !== ind),
    }));
  }

  const debugContent = [
    { isRow: true, contentObjects: [{ localFilter }, { previousFilterState }] },
    { isRow: true, contentObjects: [{ columns }] },
  ];

  return (
    <div>
      <Form layout="vertical">
        <h3>Filters</h3>
        <div className="d-flex mb-10 justify-content-between">
          <div className="d-flex">
            <h4>SEARCH</h4>
            <Button onClick={addNewFilter} size="small" className="ml-10">
              <ActionIconRender iconType="plus" /> Add New
            </Button>
          </div>
          <div className="mr-50">
            <Button className="mr-10" key="submit" size="small" onClick={reset}>
              Reset Set
            </Button>
            <Button
              key="submit"
              className="mr-6"
              size="small"
              type="primary"
              onClick={handleFilter}
            >
              Set
            </Button>
          </div>
        </div>

        {filterColumns?.map((data, index) => (
          <FilterFields
            {...data}
            filterColumns={filterColumns}
            columnSelection={(e) => handleFilterOptions(index, "columnSelection", e)}
            handleFilterOptions={(e) => handleFilterOptions(index, "filterColumns", e)}
            remove={() => handleDelete(index)}
          />
        ))}
        <DebugPre content={debugContent} />
      </Form>
    </div>
  );
}

export default function LookTableFilterSearch() {
  const routeMatch = useRouteMatch();
  const { path } = routeMatch;
  const { persistKey } = useTableContext();
  const uniqueKey = path + persistKey;
  const { searchFilter } = useSelector((state) => state?.userSettings) || {};
  const { [uniqueKey]: previousFilterState } = searchFilter || {};
  const [visibleFilterSearch, setVisibleFilterSearch] = useState(false);

  function showFilterSearch() {
    setVisibleFilterSearch(true);
  }

  function onCloseFilterSearch() {
    setVisibleFilterSearch(false);
  }

  const modalProps = {
    visible: visibleFilterSearch,
    onCancel: onCloseFilterSearch,
    width: 700,
    footer: false,
  };

  return (
    <div>
      <div className="d-flex">
        <div>
          <Tooltip placement="topLeft" title="Filter">
            <Button
              onClick={showFilterSearch}
              size="small"
              className={cx("filter-btn multi-filter ml-8", {
                active: previousFilterState && previousFilterState?.filterColumns ? true : false,
              })}
            >
              <ActionIconRender iconType="filter" />
            </Button>
          </Tooltip>
        </div>

        <LookModal {...modalProps}>
          {visibleFilterSearch && <LookTableFilterSearchForm close={() => onCloseFilterSearch()} />}
        </LookModal>
      </div>
    </div>
  );
}
