import { Button, Col, Collapse, Form, Row, Tabs } from "antd";
import ActionIconRender from "components/ActionIconRender";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";
import ReactQuillForm from "components/ReactQuillForm";
import cx from "cx";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React, { useEffect, useRef, useState } from "react";
import {
  ADD_MOODBOARD_COPY_POINTS,
  GET_MOODBOARD_CTA,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import {
  GQL_ADD_CREATIVE_ANALYSIS,
  GQL_GET_CREATIVE_ANALYSIS,
} from "shared/gql/gqlSchema/creativeGql";
import { GQL_API_RESPONSE_STATUS_SUCCESS } from "shared/SharedKeys";
import { openNotification } from "utils";
import {
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_DESCRIPTION,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_LANGUAGE,
  MOODBOARD_TYPE_PAIN_POINTS,
  MOODBOARD_TYPE_SCRIPT,
} from "../../creativesEnum";
import AddMoodboardDataModal from "./AddMoodboardDataModal";
import ScriptDetailedTabs from "./ScriptDetailedTabs";
import SelectMoodboardDataModal from "./SelectMoodboardDataModal";

function MoodboardData(props) {
  const {
    actionType,
    id = [],
    name = [],
    productId,
    creativeId = "",
    placeHolderKey,
    loading = false,
    dataKey = "",
    apiKey = "",
    key = "",
    selectedItems,
    creativeType = "",
    setSelectedItems = () => {},
    creativeData,
    setCreativeData = () => {},
    categories = [],
    mutation = ADD_MOODBOARD_COPY_POINTS,
    apiDataType = "",
    serviceType = "",
  } = props;
  const [activeKey, setActiveKey] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  // This state is used to select data from moodboard
  const [selectVisibleModal, setSelectVisibleModal] = useState(false);
  // This state is used to add data from moodboard
  const [visibleMoodboardDataModal, setVisibleMoodboardDataModal] = useState(false);
  const [addMoodboardData, setAddMoodboardData] = useState({});
  const isDesc = actionType === MOODBOARD_TYPE_DESCRIPTION;
  // optimize the code in future
  const [moodBoardData, setMoodboardData] = useState([]);
  const resetFormRef = useRef(() => {});
  const [moodBoardAddData, { loading: loading_moodboardData, refetch }] = useCustomMutation(
    mutation,
    {
      onCompleted: (e) => {
        // setData(e?.[apiDataType]);
        const element = e?.[apiDataType];
        if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
          addCreativeAnalysis({
            variables: {
              creativeId,
              creativeType,
              requestInput: { ...finalCreativeData, [apiKey]: element?.data?.[0]?.id },
            },
          });
        } else {
          openNotification({ type: "error", message: element?.message });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const handleCollapseChange = (keys, data) => {
    setActiveKey(keys);
    setIsVisible(false);
  };

  /*
   * This function is to remove null data and script data whenever required from analysis part
   */
  function removeNullValues(obj) {
    let newObj = {};
    const {
      scriptId = {},
      cpId = {},
      ppId = {},
      angleId = {},
      hookId = {},
      ctaId = {},
      notes = "",
    } = obj || {};
    if (actionType == MOODBOARD_TYPE_SCRIPT) {
      newObj = { notes };
    } else {
      newObj = {
        ...(cpId !== null && { copyPoint: cpId.id }),
        ...(ppId !== null && { painPoint: ppId.id }),
        ...(angleId !== null && { angle: angleId.id }),
        ...(hookId !== null && { hook: hookId.id }),
        ...(ctaId !== null && { cta: ctaId.id }),
        ...(actionType === MOODBOARD_TYPE_DESCRIPTION &&
          scriptId !== null && { script: scriptId?.id }),
        notes,
      };
    }

    return newObj;
  }
  const finalCreativeData = removeNullValues(creativeData?.[0]);
  const [activeScriptTab, setActiveScriptTab] = useState("1");
  const [
    fetchCreativeAnalysis,
    { loading: loading_creative_analysis, refetch: refetch_creative_analysis },
  ] = useCustomLazyQuery(GQL_GET_CREATIVE_ANALYSIS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ctGetCreativeAnalysis;
      if (element?.status === "SUCCESS") {
        setCreativeData(element.data);
      } else {
        setCreativeData([]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    addCreativeAnalysis,
    { loading: loading_add_creative_analysis, refetch: refetch_add_creative_analysis },
  ] = useCustomMutation(GQL_ADD_CREATIVE_ANALYSIS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      // const element = e?.voluum_creativeReporting;
      const element = e?.ctAddCreativeAnalysis;
      if (element?.status === "SUCCESS") {
        openNotification({ type: "success", message: e?.ctAddCreativeAnalysis?.message });
        setActiveKey(null);
        setSelectedItems([]);
        setAddMoodboardData();
        setVisibleMoodboardDataModal(false);
        onCloseSelectMoodboardDrawer();
        refetch_creative_analysis();
        // setCreativeData((prev) => ({ ...prev, [dataKey]: element.data?.[0]?.[dataKey] }));
      } else {
        openNotification({ type: "error", message: e?.message });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const finalLoading = loading_creative_analysis;
  const finalData =
    creativeData?.[0]?.[dataKey] === null || creativeData?.length === 0
      ? []
      : [creativeData?.[0]?.[dataKey]];

  const [values, setValues] = useState({
    notes: creativeData?.[0]?.[dataKey] === null ? "" : creativeData?.[0]?.[dataKey],
  });
  useEffect(() => {
    fetchCreativeAnalysis({ variables: { creativeIds: [creativeId], creativeType } });
  }, []);

  const handleSubmit = () => {
    addCreativeAnalysis({
      variables: {
        creativeId,
        creativeType,
        requestInput: { ...finalCreativeData, [dataKey]: values?.notes },
      },
    });
  };
  const handleAddMoodboardData = (values, { resetForm }) => {
    resetFormRef.current = resetForm;
    moodBoardAddData({
      variables: {
        categoryId: addMoodboardData?.categoryId,
        categoryName: addMoodboardData?.categoryName,
        productId,
        name: values?.title,
        description: values?.description,
        ...(addMoodboardData?.language && { language: addMoodboardData?.language?.[0] }),
        ...(addMoodboardData?.copyPoints && { copyPoints: addMoodboardData?.copyPoints }),
        ...(addMoodboardData?.angles && { angles: addMoodboardData?.angles }),
        ...(addMoodboardData?.hooks && { hooks: addMoodboardData?.hooks }),
        ...(addMoodboardData?.painPoints && { painPoints: addMoodboardData?.painPoints }),
        ...(addMoodboardData?.ctas && { ctas: addMoodboardData?.ctas }),
      },
    });
  };

  const onCloseSelectMoodboardDrawer = () => {
    setSelectVisibleModal(false);
    setSelectedItems([]);
  };
  const onCloseMoodboardDataModal = () => {
    setVisibleMoodboardDataModal(false);
    setAddMoodboardData({});
    resetFormRef.current();
  };
  const showSelectDrawer = () => {
    setSelectVisibleModal(true);
    setIsVisible(false);
    setActiveKey(null);
  };
  const handleAddAnalysis = () => {
    addCreativeAnalysis({
      variables: {
        creativeId,
        creativeType,
        requestInput: { ...finalCreativeData, [apiKey]: selectedItems?.[0]?.id },
      },
    });
  };
  const selectModalProps = {
    title: `Select ${placeHolderKey}`,
    visible: selectVisibleModal,
    onCancel: onCloseSelectMoodboardDrawer,
    okokText: "Submit",
    footer: null,
    width: 720,
  };
  const addMoodboardDataModalProps = {
    visible: visibleMoodboardDataModal,
    onCancel: onCloseMoodboardDataModal,
    title: `Create new ${placeHolderKey}`,
    onSubmit: () => handleSubmit(),
    footer: null,
    width: 720,
  };

  const handleSelectedItems = (id) => {
    if (selectedItems?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedItems(selectedItems?.filter((img) => img?.id !== id));
    } else {
      setSelectedItems([
        {
          id: finalData?.filter((img) => img?.id === id)[0].id,
          // tags: creativeData?.filter((img) => img?.id === id)[0].tags,
        },
      ]);
    }
  };
  const onChangeScriptTabs = (key) => {
    setActiveScriptTab(key);
  };
  const ScriptDetailedTabsContent = [
    {
      key: "1",
      label: "Copy Points",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_COPY_POINTS}
          paramstype="copyPoints"
          label="Copy Points"
          data={finalData?.[0]?.["copyPoints"]}
        />
      ),
    },
    {
      key: "2",
      label: "Angles",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_ANGLES}
          paramstype="angles"
          label="Angles"
          data={finalData?.[0]?.["angles"]}
        />
      ),
    },
    {
      key: "3",
      label: "Hooks",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_HOOKS}
          paramstype="hooks"
          label="Hooks"
          data={finalData?.[0]?.["hooks"]}
        />
      ),
    },
    {
      key: "4",
      label: "Pain Points",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_PAIN_POINTS}
          paramstype="painPoints"
          label="Pain Points"
          data={finalData?.[0]?.["painPoints"]}
        />
      ),
    },
    {
      key: "5",
      label: "CTA",
      children: (
        <ScriptDetailedTabs
          type={MOODBOARD_TYPE_CTA}
          query={GET_MOODBOARD_CTA}
          paramstype="ctas"
          label="CTA"
          data={finalData?.[0]?.["ctas"]}
        />
      ),
    },
    {
      key: "6",
      label: "Language",
      children: (
        <ScriptDetailedTabs
          query={GET_MOODBOARD_CTA}
          type={MOODBOARD_TYPE_LANGUAGE}
          paramstype="language"
          label="Language"
          data={[finalData?.[0]?.["language"]]}
        />
      ),
    },
  ];
  const contentView = () => (
    <>
      {finalLoading ? (
        <Loader elementCenter center />
      ) : finalData?.length === 0 && !isDesc ? (
        <div className="text-center pt-100">
          <div style={{ transform: "translateX(20px)" }}>
            <ActionIconRender iconType="tablePlaceholder" />
          </div>
          <h3 style={{ fontSize: "1rem" }}>
            <b>This Image/Video has no {placeHolderKey}</b>
          </h3>
          <>
            <LookButton
              onClick={() => setVisibleMoodboardDataModal(true)}
              type="primary"
              className="submit-btn"
              size="small"
            >
              Add {placeHolderKey}
            </LookButton>
            <LookButton
              type="primary"
              className={cx("submit-btn", "ml-10")}
              size="small"
              onClick={() => showSelectDrawer()}
            >
              Select from Moodboard
            </LookButton>
          </>
        </div>
      ) : actionType !== MOODBOARD_TYPE_DESCRIPTION ? (
        <>
          {finalData?.map((moodBoardData, index) => (
            <>
              <div
                className={cx(
                  "moodboardData",
                  {
                    active: activeKey == index + "",
                  },
                  { copyPoint: actionType === MOODBOARD_TYPE_COPY_POINTS }
                )}
              >
                <div className="d-flex flex-grow-1">
                  <Collapse
                    className="flex-grow-1"
                    collapsible="header"
                    accordion
                    activeKey={activeKey}
                    onChange={(e) => handleCollapseChange(e, moodBoardData)}
                  >
                    <Collapse.Panel
                      header={moodBoardData?.name}
                      key={index.toString()}
                      collapsible={
                        actionType === MOODBOARD_TYPE_COPY_POINTS ||
                        (moodBoardData?.description === null &&
                          actionType !== MOODBOARD_TYPE_SCRIPT)
                          ? "disabled"
                          : undefined
                      }
                    >
                      <div>
                        {actionType !== MOODBOARD_TYPE_COPY_POINTS && (
                          <>
                            {(moodBoardData?.description !== null ||
                              actionType === MOODBOARD_TYPE_SCRIPT) && (
                              <>
                                <p className="moodboardDesc p-0">
                                  {isVisible
                                    ? moodBoardData?.description
                                    : moodBoardData?.description?.substring(0, 170)}
                                </p>
                                {!isVisible && moodBoardData?.description?.length > 190 && (
                                  <p
                                    className="moodboardDataDesc p-0"
                                    onClick={() => setIsVisible(true)}
                                  >
                                    ...more
                                  </p>
                                )}
                              </>
                            )}
                            {actionType === MOODBOARD_TYPE_SCRIPT && (
                              <div className="pr-10">
                                <Tabs
                                  className="ScriptDetailedTabs"
                                  defaultActiveKey="1"
                                  items={ScriptDetailedTabsContent}
                                  onChange={(e) => onChangeScriptTabs(e)}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item className="look-form-input">
                <ReactQuillForm
                  placeholder="Enter description"
                  className="drawer-quill"
                  serviceName="Tracker"
                  value={values?.notes}
                  onChange={(e) => {
                    setValues((prevItems) => ({ ...prevItems, notes: e }));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {isDesc && (
            <div className="creativeAnalysisBtn">
              <Row gutter={12}>
                <Col span={12}>
                  <Button
                    htmlType="cancel"
                    block
                    type="default"
                    className="submit-btn"
                    onClick={() => {
                      setValues({
                        notes:
                          creativeData?.[0]?.[dataKey] === null ? "" : creativeData?.[0]?.[dataKey],
                      });
                    }}
                    disabled={values?.notes === finalData?.[0]}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                    onClick={handleSubmit}
                    disabled={values?.notes === finalData?.[0]}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
  return (
    <>
      <div className={cx("creative-builder-moodboard", `${"isMoodboard"}`)}>
        <div className="content-view">{contentView()}</div>
      </div>
      {visibleMoodboardDataModal && (
        <LookModal {...addMoodboardDataModalProps}>
          {visibleMoodboardDataModal && (
            <AddMoodboardDataModal
              actionType={actionType}
              placeHolderKey={placeHolderKey}
              addMoodboardData={addMoodboardData}
              setAddMoodboardData={setAddMoodboardData}
              onClose={onCloseMoodboardDataModal}
              handleAddMoodboardData={handleAddMoodboardData}
              id={id}
              categories={categories}
              serviceType={serviceType}
              name={name}
              productId={productId}
              mutation={mutation}
              loading={loading_add_creative_analysis || loading_moodboardData}
            />
          )}
        </LookModal>
      )}

      {selectVisibleModal && (
        <LookModal {...selectModalProps}>
          {selectVisibleModal && (
            <SelectMoodboardDataModal
              id={id}
              name={name}
              handleAddAnalysis={handleAddAnalysis}
              loading={loading}
              apiKey={apiKey}
              actionType={actionType}
              placeHolderKey={placeHolderKey}
              productId={productId}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              creativeId={creativeId}
              creativeType={creativeType}
              addCreativeAnalysis={addCreativeAnalysis}
              dataKey={dataKey}
              onClose={onCloseSelectMoodboardDrawer}
            />
          )}
        </LookModal>
      )}
    </>
  );
}

export default MoodboardData;
