import { useLaunchContext } from "components/Launch/Context";

function MainCard(props) {
  const { name, value } = props;

  return (
    <div className="launch-preview-card">
      <p className="name">{name}</p>
      <p className="value">{value}</p>
    </div>
  );
}
export default function LaunchView(props) {
  const { campsData = {} } = props;
  const { bucketData } = useLaunchContext();

  // const {
  //   totalCampaigns = 0,
  //   totalAdsets = 0,
  //   totlaDynamic = 0,
  //   totalAds = 0,
  //   totlaBudget = 0,
  // } = campsData;

  let totalCampaigns = 0,
    totalAdsets = 0,
    totlaDynamic = 0,
    totalAds = 0,
    totlaBudget = 0;

  const campaigns = Array.isArray(campsData) ? campsData : [{ ...campsData }];

  campaigns?.forEach((camMapData) => {
    const {
      totalCampaigns: totalCampaignsMap = 0,
      totalAdsets: totalAdsetsMap = 0,
      totlaDynamic: totlaDynamicMap = 0,
      totalAds: totalAdsMap = 0,
      totlaBudget: totlaBudgetMap = 0,
    } = camMapData;

    totalCampaigns += totalCampaignsMap;
    totalAdsets += totalAdsetsMap;
    totlaDynamic += totlaDynamicMap;
    totalAds += totalAdsMap;
    totlaBudget += totlaBudgetMap;
  });

  const cardsData = [
    { name: "Ad accounts", value: 1 },
    { name: "Articles", value: bucketData?.bucket_content?.length || 1 },
    { name: "Campaign", value: totalCampaigns },
    { name: "Ad sets", value: totalAdsets },
    { name: "Dynamic Adsets", value: totlaDynamic },
    { name: "Ads", value: totalAds },
    { name: "Budget", value: totlaBudget },
  ];

  return (
    <>
      <div className="launch-preview-row">
        {cardsData?.map((data) => (
          <div className="launch-preview-col">
            <MainCard {...data} />
          </div>
        ))}
      </div>
    </>
  );
}
