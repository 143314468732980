import { gql } from "@apollo/client";
import { ASSET_UPDATE_RESPONSE_RESPONSE } from "./commonResponseFragements";

const CATEGORIES_RESPONSE = gql`
  fragment Category on category {
    id
    category_title
    is_sensitive
    notes
    description
    parent_category_id
    sub_category_title
  }
`;

export const ADD_CATEGORY = gql`
  ${CATEGORIES_RESPONSE}
  mutation createCategory(
    $title: String
    $is_sensitive: Int
    $description: String
    $notes: String
    $sub_category_title: String
    $parent_category_id: Int
    $workspace_id: Int
  ) {
    createCategory(
      title: $title
      is_sensitive: $is_sensitive
      description: $description
      notes: $notes
      sub_category_title: $sub_category_title
      parent_category_id: $parent_category_id
      workspace_id: $workspace_id
    ) {
      ...Category
    }
  }
`;

export const GET_PAGED_CATEGORIES = gql`
  ${CATEGORIES_RESPONSE}
  query categories(
    $agency_id: Int
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    categories(
      agency_id: $agency_id
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Category
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $category_id: Int
    $description: String
    $is_sensitive: Int
    $notes: String
    $title: String
  ) {
    updateCategory(
      category_id: $category_id
      description: $description
      is_sensitive: $is_sensitive
      notes: $notes
      title: $title
    ) {
      id
    }
  }
`;

export const DELETE_CATEGORIES = gql`
  mutation deleteCategory($category_id: Int) {
    deleteCategory(category_id: $category_id)
  }
`;

export const GET_PAGED_SUB_CATEGORIES = gql`
  ${CATEGORIES_RESPONSE}
  query subcategories(
    $agency_id: Int
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    subcategories(
      agency_id: $agency_id
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Category
      }
    }
  }
`;

export const UPDATE_SUB_CATEGORY = gql`
  mutation updateCategory(
    $category_id: Int
    $description: String
    $is_sensitive: Int
    $notes: String
    $sub_category_title: String
  ) {
    updateCategory(
      category_id: $category_id
      description: $description
      is_sensitive: $is_sensitive
      notes: $notes
      sub_category_title: $sub_category_title
    ) {
      id
    }
  }
`;

export const DELETE_SUB_CATEGORIES = gql`
  mutation subcategorydelete($sub_category_id: Int) {
    subcategorydelete(sub_category_id: $sub_category_id)
  }
`;

const CONTENTS_RESPONSE = gql`
  fragment Content on content {
    id
    title
    category_id
    category_name
    content_domains {
      id
      domain_id
    }
  }
`;

export const GET_PAGED_SEARCH_ARBITRAGE_CONTENT = gql`
  ${CONTENTS_RESPONSE}
  query getContents(
    $agency_id: Int
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    contents(
      agency_id: $agency_id
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Content
      }
    }
  }
`;

export const ADD_SEARCH_ARBITRAGE_CONTENT = gql`
  ${CONTENTS_RESPONSE}
  mutation createContent($title: String, $category_id: Int, $domain_id: [Int], $workspace_id: Int) {
    createContent(
      title: $title
      category_id: $category_id
      domain_id: $domain_id
      workspace_id: $workspace_id
    ) {
      ...Content
    }
  }
`;

export const UPDATE_SEARCH_ARBITRAGE_CONTENT = gql`
  ${CONTENTS_RESPONSE}
  mutation updateContent($content_id: Int, $title: String, $category_id: Int, $domain_id: [Int]) {
    updateContent(
      content_id: $content_id
      title: $title
      category_id: $category_id
      domain_id: $domain_id
    ) {
      ...Content
    }
  }
`;

export const DELETE_SEARCH_ARBITRAGE_CONTENT = gql`
  mutation deleteContent($content_id: Int) {
    deleteContent(content_id: $content_id)
  }
`;

export const DOMAIN_ASSIGNED = gql`
  mutation domainAssigned($domain: Int, $userId: [Int]) {
    domainAssigned(domain: $domain, userId: $userId) {
      id
      domain_id
      user {
        id
        name
      }
    }
  }
`;

export const GET_USERS_NAME_BY_AGENCY = gql`
  query agency($id: ID) {
    agency(id: $id) {
      id
      users {
        id
        name
        workspaces {
          id
          title
          description
        }
      }
    }
  }
`;

export const CATEGORY_DROPDOWN = gql`
  query categorydropdown($agency_id: Int, $workspace_id: Int) {
    categoriesDropDown(agency_id: $agency_id, workspace_id: $workspace_id) {
      id
      category_title
    }
  }
`;

export const DOMAIN_DROPDOWN = gql`
  query domainsdropdown($agency_id: Int, $workspace_id: Int) {
    search_arbitrage_domainsDropdown(agency_id: $agency_id, workspace_id: $workspace_id) {
      id
      name
    }
  }
`;

const SEARCH_ARBITRAGE_DOMAINS_RESPONSE = gql`
  fragment SearchArbitrageDomain on search_arbitragedomain {
    id
    name
    link_structure
    nickname
    notes
    prefix
    visibility
    domainAssigned {
      id
      user {
        id
        name
      }
    }
  }
`;

export const GET_PAGED_SEARCH_ARBITRAGE_DOMAINS = gql`
  ${SEARCH_ARBITRAGE_DOMAINS_RESPONSE}
  query search_arbitrage_domains(
    $agency_id: Int
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    search_arbitrage_domains(
      agency_id: $agency_id
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...SearchArbitrageDomain
      }
    }
  }
`;

export const ADD_SEARCH_ARBITRAGE_DOMAIN = gql`
  ${SEARCH_ARBITRAGE_DOMAINS_RESPONSE}
  mutation search_arbitrage_createDomain(
    $link_structure: String
    $name: String
    $nickname: String
    $notes: String
    $prefix: String
    $visibility: Int
    $workspace_id: Int
  ) {
    search_arbitrage_createDomain(
      link_structure: $link_structure
      name: $name
      nickname: $nickname
      notes: $notes
      prefix: $prefix
      visibility: $visibility
      workspace_id: $workspace_id
    ) {
      ...SearchArbitrageDomain
    }
  }
`;

export const UPDATE_SEARCH_ARBITRAGE_DOMAIN = gql`
  ${SEARCH_ARBITRAGE_DOMAINS_RESPONSE}
  mutation search_arbitrage_updateDomain(
    $domain: Int
    $link_structure: String
    $name: String
    $nickname: String
    $notes: String
    $prefix: String
    $visibility: Int
  ) {
    search_arbitrage_updateDomain(
      domain: $domain
      link_structure: $link_structure
      name: $name
      nickname: $nickname
      notes: $notes
      prefix: $prefix
      visibility: $visibility
    ) {
      ...SearchArbitrageDomain
    }
  }
`;

export const DELETE_SEARCH_ARBITRAGE_SELECTED_DOMAIN = gql`
  mutation search_arbitrage_deleteSelectedDomain($domain: [Int]) {
    search_arbitrage_deleteSelectedDomain(domain: $domain)
  }
`;

const KEYWORDS_RESPONSE = gql`
  fragment Keyword on keyword {
    id
    name
    user_id
    user {
      id
      name
    }
    keywordAssigned {
      id
      user_id
      user {
        id
        name
      }
    }
    is_imported
    category_id
    category_name
    sub_category_id
    sub_category_name
    is_tested
    status
    createdAt
    is_parent
    is_variation_required
    is_images_required
    is_relaunch_required
    is_super_scale
    test_result
    test_result_reason
    parent_keyword_id
    images {
      id
      images
      notes
    }
    captions {
      id
      caption
      notes
    }
    headlines {
      id
      headline
      notes
    }
    descriptions {
      id
      description
      notes
    }
    launch {
      id
      no_of_keywords
    }
  }
`;

export const GET_PAGED_KEYWORDS = gql`
  ${KEYWORDS_RESPONSE}
  query getKeywords(
    $agency_id: Int
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $type: Int
    $workspace_id: Int
  ) {
    keywords(
      agency_id: $agency_id
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      type: $type
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Keyword
      }
    }
  }
`;

export const ADD_KEYWORDS = gql`
  ${KEYWORDS_RESPONSE}
  mutation createKeyword($name: [String], $workspace_id: Int) {
    createKeyword(name: $name, workspace_id: $workspace_id) {
      ...Keyword
    }
  }
`;

export const UPDATE_KEYWORD = gql`
  ${KEYWORDS_RESPONSE}
  mutation updateKeyword(
    $keyword_id: Int
    $name: String
    $is_imported: Int
    $category_id: Int
    $sub_category_id: Int
    $is_tested: Int
    $status: Int
    $is_parent: Int
    $parent_keyword_id: Int
    $is_variation_required: Int
    $is_images_required: Int
    $is_super_scale: Int
    $is_relaunch_required: Int
    $test_result: String
    $test_result_reason: String
    $image_id: [Int]
    $headlines: [String]
    $descriptions: [String]
  ) {
    updateKeyword(
      keyword_id: $keyword_id
      name: $name
      is_imported: $is_imported
      category_id: $category_id
      sub_category_id: $sub_category_id
      is_tested: $is_tested
      status: $status
      is_parent: $is_parent
      parent_keyword_id: $parent_keyword_id
      is_variation_required: $is_variation_required
      is_images_required: $is_images_required
      is_super_scale: $is_super_scale
      is_relaunch_required: $is_relaunch_required
      test_result: $test_result
      test_result_reason: $test_result_reason
      image_id: $image_id
      headlines: $headlines
      descriptions: $descriptions
    ) {
      ...Keyword
    }
  }
`;

export const KEYWORD_ASSIGN = gql`
  mutation keywordAssigned($keyword_id: Int, $user_id: Int) {
    keywordAssigned(keyword_id: $keyword_id, user_id: $user_id) {
      id
      user_id
    }
  }
`;

export const DELETE_KEYWORD = gql`
  mutation deleteKeyword($keyword_id: Int) {
    deleteKeyword(keyword_id: $keyword_id)
  }
`;

const DAILY_REPORTING_RESPONSE = gql`
  fragment dailyReporting on dailyReporting {
    id
    channel
    channel_name2
    channel_name3
    date
    site
    impressions
    ad_clicks
    estimated_revenue
    tq
  }
`;

const DAILY_REPORTING_WITH_USER_RESPONSE = gql`
  ${DAILY_REPORTING_RESPONSE}
  fragment dailyReportingWithUser on dailyReporting {
    ...dailyReporting
    user_id
    user {
      name
    }
  }
`;

export const GET_DAILY_REPORTING_CHANNEL_WISE = gql`
  ${DAILY_REPORTING_RESPONSE}
  query dailyReportingChannelWise($channel: String, $from: Date, $to: Date, $workspace_id: Int) {
    dailyReportingChannelWise(
      channel: $channel
      from: $from
      to: $to
      workspace_id: $workspace_id
    ) {
      ...dailyReporting
    }
  }
`;

export const GET_DAILY_REPORTING = gql`
  ${DAILY_REPORTING_RESPONSE}
  query dailyReporting($page: Int, $limit: Int, $from: Date, $to: Date, $workspace_id: Int) {
    dailyReporting(page: $page, limit: $limit, from: $from, to: $to, workspace_id: $workspace_id) {
      ...dailyReporting
    }
  }
`;

export const GET_DAILY_REPORTING_USER_WISE = gql`
  ${DAILY_REPORTING_WITH_USER_RESPONSE}
  query dailyReportingUserWise($from: Date, $to: Date, $user_id: Int, $workspace_id: Int) {
    dailyReportingUserWise(from: $from, to: $to, user_id: $user_id, workspace_id: $workspace_id) {
      ...dailyReportingWithUser
    }
  }
`;

const REPORT_ADSET_BY_NAME_RESPONSE = gql`
  fragment ReportAdSetByName on ReportAdSetByName {
    adSetName
    clicksFB
    date
    impressionsFB
    leadsFB
    spendFB
  }
`;

export const GET_REPORT_PER_AD_SET_NAME = gql`
  ${REPORT_ADSET_BY_NAME_RESPONSE}
  query reportPerAdSetName(
    $adSetName: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdSetName(
      adSetName: $adSetName
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportAdSetByName
    }
  }
`;

export const GET_REPORT_BY_AS_SET_NAME = gql`
  ${REPORT_ADSET_BY_NAME_RESPONSE}
  query reportByAdSetName(
    $from: String!
    $to: String!
    $timezone: String!
    $userId: ID
    $vendorId: ID
  ) {
    reportByAdSetName(
      from: $from
      to: $to
      timezone: $timezone
      userId: $userId
      vendorId: $vendorId
    ) {
      ...ReportAdSetByName
    }
  }
`;

export const UPDATE_SELECTED_AD_SET_STATUS_BY_NAME = gql`
  ${ASSET_UPDATE_RESPONSE_RESPONSE}
  mutation updateSelectedAdSetStatusByName($adSetFBNames: [String]!, $status: String!) {
    updateSelectedAdSetStatusByName(adSetFBNames: $adSetFBNames, status: $status) {
      ...AssetUpdateResponse
    }
  }
`;

export const GET_REPORT_PER_AD_SET_HOURLY = gql`
  ${REPORT_ADSET_BY_NAME_RESPONSE}
  query reportPerAdSetHourly(
    $adSetName: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdSetHourly(
      adSetName: $adSetName
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportAdSetByName
    }
  }
`;

export const GET_REPORT_BY_AS_SET_NAME_TIME = gql`
  ${REPORT_ADSET_BY_NAME_RESPONSE}
  query reportByAdSetNameTime($from: String!, $to: String!, $timezone: String!, $userId: ID) {
    reportByAdSetName(from: $from, to: $to, timezone: $timezone, userId: $userId) {
      ...ReportAdSetByName
    }
  }
`;

const LIVE_REPORTING_RESPONSE = gql`
  fragment LiveReportingData on liveReportingData {
    id
    channel
    channel_name2
    channel_name3
    date
    impressions
    total_clicks
    estimated_revenue
    country
    prefix
    domain_name
  }
`;

export const GET_HOURLY_LIVE_REPORTING = gql`
  ${LIVE_REPORTING_RESPONSE}
  query hourlyliveReporting($start_date: Date, $channel_name2: String, $workspace_id: Int) {
    hourlyliveReporting(
      start_date: $start_date
      channel_name2: $channel_name2
      workspace_id: $workspace_id
    ) {
      latest_time
      data {
        ...LiveReportingData
      }
    }
  }
`;

export const GET_LIVE_REPORTING = gql`
  ${LIVE_REPORTING_RESPONSE}
  query liveReporting($from: Date, $to: Date, $workspace_id: Int, $user_id: Int) {
    liveReporting(from: $from, to: $to, workspace_id: $workspace_id, user_id: $user_id) {
      latest_time
      data {
        ...LiveReportingData
      }
    }
  }
`;

export const GET_DAILY_REPORTING_ALL_USER_WISE = gql`
  ${DAILY_REPORTING_WITH_USER_RESPONSE}
  query dailyReportingAllUserWise($from: Date, $to: Date, $workspace_id: Int) {
    dailyReportingAllUserWise(from: $from, to: $to, workspace_id: $workspace_id) {
      ...dailyReportingWithUser
    }
  }
`;

export const SAS_LAUNCH_CALL = gql`
  mutation launch(
    $bucket_id: ID
    $productId: ID!
    $launchAccountData: [LaunchAccountDataInput]!
    $media: [String]!
    $name: String!
    $video_thumbs: [String]
  ) {
    launch(
      bucket_id: $bucket_id
      launchAccountData: $launchAccountData
      media: $media
      name: $name
      productId: $productId
      video_thumbs: $video_thumbs
    )
  }
`;

const KEYWORD_ADSET_URL_RESPONSE = gql`
  fragment keywordAdsetUrl on keywordAdsetUrl {
    adset
    id
    keywordId
    keywordName
    url
  }
`;

export const SAS_ADSET_GENRATE = gql`
  ${KEYWORD_ADSET_URL_RESPONSE}
  mutation sasAdsetGenrate(
    $categoryId: ID!
    $countryId: ID!
    $device: SasDevice
    $domainId: ID!
    $keywordsId: [ID!]
    $languageId: ID!
    $platformId: ID!
    $vendorId: ID!
  ) {
    sasAdsetGenrate(
      categoryId: $categoryId
      countryId: $countryId
      device: $device
      domainId: $domainId
      keywordsId: $keywordsId
      languageId: $languageId
      platformId: $platformId
      vendorId: $vendorId
    ) {
      ...keywordAdsetUrl
    }
  }
`;

export const SAS_GET_ALL_GROUP = gql`
  query sasGetAllGroup {
    sasGetAllGroup {
      id
      name
    }
  }
`;

const GROUP_ADSET_URL_RESPONSE = gql`
  fragment groupAdsetUrl on groupAdsetUrl {
    categoryId
    categoryName
    verticalId
    keywords {
      adset
      id
      keywordId
      keywordName
      url
    }
  }
`;

export const SAS_ADSET_GENRATE_BY_GROUP = gql`
  ${GROUP_ADSET_URL_RESPONSE}

  mutation sasAdsetGenrateByGroup(
    $countryId: ID!
    $device: SasDevice
    $domainId: ID!
    $groupId: ID!
    $languageId: ID!
    $pixel: String
    $platformId: ID!
    $vendorId: ID!
  ) {
    sasAdsetGenrateByGroup(
      countryId: $countryId
      device: $device
      domainId: $domainId
      groupId: $groupId
      languageId: $languageId
      pixel: $pixel
      platformId: $platformId
      vendorId: $vendorId
    ) {
      ...groupAdsetUrl
    }
  }
`;
