export const isImageUrl = (url) => {
  return /\.(jpeg|jpg|gif|png|bmp)$/.test(url);
};

export const isVideoUrl = (url) => {
  return /\.(mp4|mkv|avi|mov|webm)$/.test(url);
};

export function getVideoFormat(url) {
  const extension = url.split(".").pop().split(/\#|\?/)[0];
  const videoFormats = ["mp4", "mkv", "avi", "mov", "webm"];
  if (videoFormats.includes(extension)) {
    return extension;
  } else {
    return "Unknown format";
  }
}
