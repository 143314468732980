import React, { useState, useEffect, Fragment, useImperativeHandle } from "react";
import { Table, Skeleton, Pagination } from "antd";
import {
  USER_SETTING_MAIN_PAGINATION,
  USER_SETTING_SORTER,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  USER_SETTING_TABLE_ROW_SIZE,
} from "actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { EditableRow, EditableCell, TableContext } from "./fields";
import cx from "classnames";
import { ResizableTitle } from "shared/TableUtils";
import LookTableBulkAction from "./LookTableBulkAction";
import LookTableFilter from "./LookTableFilter";
import { Excel } from "antd-table-saveas-excel";
import { useRouteMatch } from "react-router-dom";
import { DEFAULT_TABLE_ROW_SIZE_VALUE } from "reducers/userSettings";
import { META_TABLE_ROW_SIZE } from "shared/UserSettingsKeys";
import { GET_USER_CONGIFURATION, UPDATE_USER_CONGIFURATION } from "shared/gql/gqlSchema/umsGql";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import ActionIconRender from "components/ActionIconRender";
import RenderLineChart from "./RenderLineChart";
import {
  HEADER_HEIGHT,
  PAGINATION_WRAPPER_HEIGHT,
  TABLE_FILTER_HEIGHT,
  TABLE_HEADER_HEIGHTT,
  TABLE_SUMMARY_HEIGHTT,
} from "shared/Theme";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import useSrNoColumnHook from "hooks/columnsHooks/useSrNoColumnHook";
import LookTableDefaultFilter from "./LookTableFilterSearch/LookTableDefaultFilter";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import { arrayMove } from "@dnd-kit/sortable";
import { CURRENCY_TYPE_INR, CURRENCY_TYPE_USD } from "shared/SharedKeys";
import currencySymbolCheck from "utils/currencyCheck";
import { useGetCustomColumnData } from "./LookTableFilter/useCustomColumn";
import { detectValueType } from "utils";
import { STRING } from "shared/enum/typeOfEnum";

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: "grab",
      color: "#999",
    }}
  />
));

export default function LookTable(props) {
  const routeMatch = useRouteMatch();
  const defaultPageSizeOptions = ["10", "15", "20", "50", "100", "200", "500"];
  const { path } = routeMatch;
  const {
    isCustomColumn = false, // it's use for give feature for create custom column set
    showScrollbar = false,
    searchBorder = false,
    tableProps,
    dataSource,
    setDataSource = () => {},
    handleSave,
    leftOptions,
    rightOptions,
    isWorkspace = false,
    isMultiWorkspace = false,
    isWorkspaceWithAll = false,
    isDateRange = false,
    isSearchFilter = false,
    dateRangeProps = {},
    loading = false,
    refetch,
    bulkSelectionAction,
    uploadTableFile,
    isTableFilter = true,
    filterProps: filterPropsExtra = {},
    isRowSelection = true,
    handleBulkSelection = () => {},
    pageSizeOptions = defaultPageSizeOptions,
    defaultPaginationOnChange,
    defaultPageSize = 100,
    defaultPagination = false,
    defaultPaginationPageSize = 100,
    persistPagination = false,
    bulkActions = [],
    status,
    extraStatus,
    tableTimeStamp = "",
    contextData = {},
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    isUserSelection = false,
    isHourSelection = false,
    isMonthSelection = false,
    disabledMonths = () => {},
    isYearSelection = false,
    isDateSelection = false,
    isQuarterSelection = false,
    scrollX = 500,
    searchInclude = false,
    isEditable = false,
    exportCSV = false,
    uniqueKey = "",
    errorMessage = "",
    isLineChart = false,
    customeView = undefined,
    customeViewStyles = {},
    sizes = {},
    fixedScrollHeight, // for adding fixed height to the table row
    isSrNo = false,
    outerSort = false, // its use in handleTable function for sort save in redux level
    ruleSetting,
    rowSelectionProps = {},
    defaultFilterColumns,
    secondaryRightOptions,
    isScalableFilter = false,
    rowDragableElement = false, // this is use for drag rows
    rowDragableElementKey, // this is required when use rowDragableElement
    currencyType = CURRENCY_TYPE_USD,
    rowClassName = () => {},
    noDataPlaceholderText = "",
    srWidth = 60,
  } = props;

  const {
    initialColumns: initialColumnsProps = [],
    recordData = [],
    summaryData,
    lastColumns = [],
  } = tableProps || {};

  const isCollapsibleRow = recordData?.some((item) => item?.children?.length > 0);

  const { srNoColumn } = useSrNoColumnHook({ persistKey, srWidth, isCollapsibleRow });

  const srNoData = isSrNo ? [srNoColumn] : [];
  const {
    headerHeight = HEADER_HEIGHT,
    tableFilterHeight = TABLE_FILTER_HEIGHT,
    tableHeaderHeight = TABLE_HEADER_HEIGHTT,
    tableSummaryHeight = TABLE_SUMMARY_HEIGHTT,
    paginationWrapperHeight = PAGINATION_WRAPPER_HEIGHT,
    reduceExtraScrollHeightBy = 0,
  } = sizes;

  let actionColumn = [],
    initialColumns = initialColumnsProps?.map((d) => ({ ...d }));
  const actionColumnIndex = initialColumnsProps.findIndex((d) => d?.dataIndex === "action");

  if (actionColumnIndex !== -1) {
    actionColumn.push(initialColumnsProps[actionColumnIndex]);
    initialColumns.splice(actionColumnIndex, 1);
  }

  const isDefaultFilterColumnsHeight = defaultFilterColumns ? 34 : 0;

  const commonSkipHeight =
    headerHeight +
    tableFilterHeight +
    tableHeaderHeight +
    paginationWrapperHeight +
    reduceExtraScrollHeightBy +
    isDefaultFilterColumnsHeight;

  const {
    scrollHeight = fixedScrollHeight
      ? fixedScrollHeight
      : summaryData
      ? `calc(100vh - ${commonSkipHeight + tableSummaryHeight}px)`
      : `calc(100vh - ${commonSkipHeight}px)`,
  } = props;

  //Local States

  const parseJsonToColumn = (element) => {
    const fechedData = detectValueType(element) === STRING ? JSON.parse(element) : element;
    const newFinal = [...fechedData]?.filter(
      ({ dataIndex }) => initialColumns?.filter((ii) => ii.dataIndex === dataIndex).length > 0
    );

    newFinal.forEach((nn, index) => {
      const filtePreviousObject = initialColumns?.filter((ii) => ii?.dataIndex === nn.dataIndex);
      const { hidden = false } = newFinal[index];
      newFinal[index] = { ...filtePreviousObject[0], hidden };
    });

    const pendingData = initialColumns?.filter(
      (p) => !(newFinal?.filter((nn) => nn.dataIndex === p.dataIndex).length > 0)
    );

    const merged = [
      ...newFinal?.filter(({ dataIndex }) => dataIndex?.toLowerCase() !== "action"),
      ...pendingData,
      ...newFinal?.filter(({ dataIndex }) => dataIndex?.toLowerCase() === "action"),
    ];
    return sortFixedColumns(merged);
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [isVisibleChart, setIsVisibleChart] = useState(false);

  const tableDefaultConfigKey = uniqueKey
    ? "tableConfig" + persistKey + uniqueKey + path
    : "tableConfig" + path + persistKey;

  const customColumnDefaultConfigKey = tableDefaultConfigKey; // This key is use for custom column

  const {
    customColumnData = [],
    customColumnActiveIndex = -1,
    updateCurrentCustomColumn = () => {},
  } = useGetCustomColumnData(customColumnDefaultConfigKey);

  const isCustomColumnSelected = customColumnActiveIndex !== -1;

  const dispatch = useDispatch();

  const { tableRowSize = DEFAULT_TABLE_ROW_SIZE_VALUE } =
    useSelector((state) => state?.userSettings) || {};

  const [columns, setColumns] = useState(
    isCustomColumnSelected
      ? parseJsonToColumn(customColumnData[customColumnActiveIndex]?.columnList)
      : initialColumns
  );
  const [defaultColumn, setDefaultColumns] = useState(initialColumns); // This state is use for backup for custom column

  const handleColumn = (columnData, extraProps = {}) => {
    /**
     * This function get columnData list data and update main state setColumns
     * isColumnsInitial is boolean isuse when update setColumnsInitial
     */
    const { isUpdateDefaultColumns = false, defaultColumnData } = extraProps;

    setColumns(columnData); // defaultColumnData

    if (isUpdateDefaultColumns) {
      setDefaultColumns(defaultColumnData ? defaultColumnData : columnData);
    }
  };

  function sortFixedColumns(columnData = []) {
    // Custom sorting function
    function customSort(a, b) {
      // If 'fixed' is present, items with 'fixed' set to 'left' appear first
      if (a?.fixed === "left" && b?.fixed !== "left") {
        return -1;
      } else if (a?.fixed !== "left" && b?.fixed === "left") {
        return 1;
      }

      // If 'fixed' is the same or not present, maintain the original order
      return 0;
    }

    return columnData.sort(customSort);
  }

  const [userConfiguration, { loading: loading_GET_USER_CONGIFURATION }] = useCustomLazyQuery(
    GET_USER_CONGIFURATION,
    {
      onCompleted: (e) => {
        const element = e?.userConfiguration;
        if (element) {
          if (isCustomColumnSelected) {
            handleColumn(parseJsonToColumn(customColumnData[customColumnActiveIndex]?.columnList), {
              isUpdateDefaultColumns: true,
              defaultColumnData: parseJsonToColumn(element),
            });
          } else {
            handleColumn(parseJsonToColumn(element), { isUpdateDefaultColumns: true });
          }
        }
      },
    }
  );

  const [updateUserConfiguration] = useCustomMutation(UPDATE_USER_CONGIFURATION);

  const preSetColumn = () => userConfiguration({ variables: { meta_name: tableDefaultConfigKey } });

  const updateTableConfiguration = (latestColumn = columns) => {
    if (tableDefaultConfigKey) {
      const columnData = latestColumn.map(({ render, ...rest }) => rest);
      if (isCustomColumnSelected) {
        updateCurrentCustomColumn(customColumnActiveIndex, columnData);
      } else {
        updateUserConfiguration({
          variables: { meta_name: tableDefaultConfigKey, meta_value: JSON.stringify(columnData) },
        });
      }
    }
  };

  const resetTableConfiguration = () => {
    handleColumn(initialColumns, { isUpdateDefaultColumns: !isCustomColumnSelected });
    // TODO: Here add custom column condition
    if (tableDefaultConfigKey) {
      updateUserConfiguration({
        variables: { meta_name: tableDefaultConfigKey, meta_value: JSON.stringify(initialColumns) },
      });
    }
  };

  const componentBaseProps = { header: { cell: ResizableTitle } };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(dataSource.slice(), oldIndex, newIndex).filter((el) => !!el);
      console.log("Sorted items: ", newData);
      setDataSource(newData);
    }
  };
  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableBody = SortableContainer((props) => <tbody {...props} />);
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x?.[rowDragableElementKey] === restProps["data-row-key"]
    );

    return <SortableItem index={index} {...restProps} />;
  };

  const isRowDragable = rowDragableElement
    ? { body: { wrapper: DraggableContainer, row: DraggableBodyRow } }
    : {};

  const isRowDragableForEditable = rowDragableElement
    ? {
        body: {
          wrapper: DraggableContainer,
          row: (e) => (
            <EditableRow>
              <DraggableBodyRow {...e} />
            </EditableRow>
          ),
        },
      }
    : {};

  const components = isEditable
    ? {
        ...componentBaseProps,
        body: {
          row: (e) => (
            <EditableRow>
              <tr {...e} />
            </EditableRow>
          ),
          cell: EditableCell,
          ...isRowDragableForEditable?.body,
        },
      }
    : { ...componentBaseProps, ...isRowDragable };

  const handleResize =
    (index) =>
    (e, { size }) => {
      // setColumns((previousData) => {
      //   const nextColumns = [...previousData];
      //   const { minWidth = 100, width } = nextColumns[index];
      //   const updateWidth = minWidth <= size.width ? size.width : width;
      //   nextColumns[index] = { ...nextColumns[index], width: updateWidth };
      //   return nextColumns;
      // });

      const nextColumns = [...columns];
      const { minWidth = 100, width } = nextColumns[index];
      const updateWidth = minWidth <= size.width ? size.width : width;
      nextColumns[index] = { ...nextColumns[index], width: updateWidth };
      handleColumn(nextColumns, { isUpdateDefaultColumns: !isCustomColumnSelected });
    };

  const renderColumnsEditable = columns
    ?.filter(({ hidden = false }) => hidden === false)
    .map((col, index) => {
      const commonObjects = {
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResizeStart: (e, data) => {
            // console.log("onResizeStart", { e, data });
          },
          onResizeStop: (e, data) => {
            // console.log("onResizeStop", { e, data });
          },
          onResize: handleResize(index),
        }),
      };

      if (!col.editable) return { ...commonObjects };

      return {
        ...commonObjects,
        onCell: (record) => ({
          record,
          ...col,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type,
          dataList: col.dataList,
          setDataSource: setDataSource,
          dataSource: dataSource,
          handleSave: handleSave,
        }),
      };
    });

  //Normal

  const renderColumns = columns
    ?.filter(({ hidden = false }) => hidden === false)
    .map((col, index) => {
      const { render, sorter } =
        initialColumns?.filter((data) => data.dataIndex === col.dataIndex)[0] || {};

      const isRender = render ? { render } : {};
      const isSorter = sorter ? { sorter } : {};

      return {
        ...col,
        onHeaderCell: (column) => ({ width: column.width, onResize: handleResize(index) }),
        ...isRender,
        ...isSorter,
      };
    });

  // Here Internal Bulk selection handle with local state
  // states -> selectedRows, setSelectedRows

  function newBulkSelectChange(selectedRowKeys, selectedRowsCallBack) {
    setSelectedRows(selectedRowsCallBack);
    handleBulkSelection(selectedRowsCallBack);
  }

  function selectedRowsClear() {
    setSelectedRows([]);
  }

  useEffect(() => {
    //  preSetColumn fetch column config and set columns
    if (customeView === undefined) {
      tableDefaultConfigKey && preSetColumn();
    }

    return () => {};
  }, [tableDefaultConfigKey]);

  const rowSelection = {
    //NEW Bulk params
    selectedRowKeys: selectedRows.map(({ key }) => key),
    onChange: newBulkSelectChange,
    columnWidth: 32,
    ...rowSelectionProps,
  };

  const bulkActionProps = {
    //NEW Bulk parms
    selectedRows,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };

  const chooseColumn = (index, setHidden) => {
    const nextColumns = [...columns];
    nextColumns[index] = { ...nextColumns[index], hidden: setHidden };
    setColumns(nextColumns);
    handleColumn(nextColumns, { isUpdateDefaultColumns: !isCustomColumnSelected });
    updateTableConfiguration(nextColumns);
  };

  const handleRowSize = (size) => {
    dispatch({ type: USER_SETTING_TABLE_ROW_SIZE, payload: size });
    updateUserConfiguration({ variables: { meta_name: META_TABLE_ROW_SIZE, meta_value: size } });
  };

  const renderRowSkeleton = ({ header = false }) => {
    const Column = header ? "th" : "td";
    const size = header ? "small" : tableRowSize;
    return (
      <tr>
        {isRowSelection && (
          <Column style={{ width: 30, minWidth: 30, position: "sticky", left: 0 }}>
            <Skeleton.Input
              className={cx({ light: header })}
              style={{ width: 30, minWidth: 30 }}
              size={size}
            />
          </Column>
        )}
        {columns.map((data, index) => (
          <Column
            key={"renderRowSkeleton" + index}
            style={data?.fixed ? { position: "sticky", left: 32 } : {}}
          >
            <Skeleton.Input
              active
              style={{ width: "100%" }}
              className={cx("w-100", { light: header })}
              size={size}
            />
          </Column>
        ))}
      </tr>
    );
  };

  const renderSkeleton = () => {
    const lines = [];
    for (let i = 1; i <= 10; i++) {
      lines.push("");
    }
    return (
      <div className="overflow-auto p-2">
        <table className="w-100">
          <thead>{renderRowSkeleton({ header: true })}</thead>
          <tbody>
            {lines.map((data, index) => (
              <Fragment key={"lines" + index}>{renderRowSkeleton({ header: false })}</Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const mainSorter = useSelector((state) => state?.userSettings?.sorter);

  function handleTable(pagination, filters, sorter) {
    if (outerSort || persistPagination) {
      /**
       *
       * outerSort is boolean
       * if outerSort or persistPagination is true
       * than sort save in redux
       *
       */

      //THIS IS FOR OUTER SORT

      const orderName = (name) => {
        if (name === "ascend") return "ASC";
        if (name === "descend") return "DESC";
      };
      const order = sorter?.order ? { order: orderName(sorter?.order) } : {};
      dispatch({
        type: USER_SETTING_SORTER,
        payload: { ...mainSorter, [persistKey]: { column: sorter?.field, ...order } },
      });
    }
  }
  useEffect(() => {
    return dispatch({
      type: USER_SETTING_SORTER,
      payload: { ...mainSorter, [persistKey]: undefined },
    });
  }, []);
  const columnsData = isEditable ? renderColumnsEditable : renderColumns;

  const dataSourceRecord = isEditable ? dataSource : recordData;

  const renderSummaryContentItem = (dataIndex) => {
    const value = summaryData[dataIndex];

    if (
      dataIndex?.toLowerCase().includes("spend") ||
      dataIndex?.toLowerCase().includes("spendFB") ||
      dataIndex?.toLowerCase().includes("estimate_revenue") ||
      dataIndex?.toLowerCase().includes("predicted_revenue")
    ) {
      return (
        <>
          {currencySymbolCheck(currencyType)}
          {value}
        </>
      );
    }

    if (
      dataIndex?.toLowerCase() === "PROFIT"?.toLowerCase() ||
      dataIndex?.toLowerCase() === "LIVE_PROFIT"?.toLowerCase() ||
      dataIndex?.toLowerCase() === "predicted_margin"?.toLowerCase() ||
      dataIndex?.toLowerCase() === "gross_margin"?.toLowerCase() ||
      dataIndex?.toLowerCase() === "LIVE_margin"?.toLowerCase() ||
      dataIndex?.toLowerCase() === "PB_margin"?.toLowerCase() ||
      dataIndex?.toLowerCase() === "LIVE_gross_margin"?.toLowerCase()
    ) {
      return (
        <span
          className={cx(
            dataIndex?.toLowerCase() === "gross_margin" ||
              dataIndex?.toLowerCase() === "LIVE_gross_margin"
              ? {
                  "text-yellow": currencyType === CURRENCY_TYPE_USD && Number(value) > 0,
                  "text-primary": currencyType === CURRENCY_TYPE_INR && Number(value) > 0,
                  "text-danger": Number(value) < 0,
                }
              : { "text-primary": Number(value) > 0, "text-danger": Number(value) < 0 }
          )}
        >
          {/* added condition to display compacted profit values with color */}
          {currencySymbolCheck(currencyType)}
          {summaryData[dataIndex + "_COMPACT"]
            ? summaryData[dataIndex + "_COMPACT"]
            : value?.replace("-", "")}
        </span>
      );
    }

    if (
      dataIndex.toLowerCase() === "ROI".toLowerCase() ||
      dataIndex.toLowerCase() === "ROAS".toLowerCase() ||
      dataIndex.toLowerCase() === "predicted_ROAS".toLowerCase() ||
      dataIndex.includes("ROAS")
    ) {
      return <>{value}%</>;
    }
    return value;
  };

  const renderSummaryContent = () => {
    let count = isRowSelection ? 1 : 0;

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          {isRowSelection && <Table.Summary.Cell index={0} />}
          {isSrNo && <Table.Summary.Cell index={isRowSelection ? 1 : 0} />}
          {columnsData?.map(({ dataIndex = "" }, index) => {
            count++;
            return (
              <Table.Summary.Cell
                key={dataIndex}
                index={index + (isRowSelection ? 1 : 0) + (isSrNo ? 1 : 0)}
              >
                {index === 0 && <b>Total</b>}
                {renderSummaryContentItem(dataIndex)}
              </Table.Summary.Cell>
            );
          })}
          {lastColumns?.map((d, index) => {
            count++;
            return <Table.Summary.Cell key={index} index={count - 1}></Table.Summary.Cell>;
          })}
          {actionColumn?.map((d, index) => {
            count++;
            return <Table.Summary.Cell key={index} index={count - 1}></Table.Summary.Cell>;
          })}
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const isRowDragableProps = rowDragableElement ? { rowKey: rowDragableElementKey } : {};
  const isRowDragableColumn = rowDragableElement
    ? [
        {
          title: "Sort",
          dataIndex: "sort",
          width: 30,
          className: "drag-visible",
          render: (e, { isDisableDrag = false }) =>
            isDisableDrag ? (
              <MenuOutlined style={{ cursor: "disable", color: "#999" }} />
            ) : (
              <DragHandle />
            ),
        },
      ]
    : [];

  const allTableProps = {
    className: cx("row-selectionEnabled", { "d-none": loading || errorMessage }),
    size: tableRowSize,
    rowClassName: isEditable
      ? (e) => {
          const tempClassName = rowClassName(e);
          return cx("editable-row", tempClassName);
        }
      : rowClassName,
    rowSelection: isRowSelection && rowSelection,
    bordered: true,
    pagination: defaultPagination
      ? {
          pageSizeOptions: pageSizeOptions,
          defaultPageSize: defaultPaginationPageSize,
          size: "middle",
          className: "look-bottom-pagination",
          showSizeChanger: true,
          onChange: defaultPaginationOnChange,
        }
      : false,
    summary: () => (summaryData ? renderSummaryContent() : null),
    components,
    columns: [...isRowDragableColumn, ...srNoData, ...columnsData, ...lastColumns, ...actionColumn],
    dataSource: dataSourceRecord,
    onChange: handleTable,
    ...isRowDragableProps,
    expandIcon: ({ expanded, onExpand, record }) =>
      record.children != null ? (
        expanded ? (
          <ActionIconRender
            iconType="caret-down"
            style={{ float: "left" }}
            className="mr-2"
            onClick={(e) => onExpand(record, e)}
          />
        ) : (
          <ActionIconRender
            iconType="caret-right"
            style={{ float: "left" }}
            className="mr-2"
            onClick={(e) => onExpand(record, e)}
          />
        )
      ) : (
        <></>
      ),
    scroll: {
      y: isLineChart && isVisibleChart ? `calc(${scrollHeight} - 470px)` : scrollHeight,
      x: scrollX,
    },
  };

  const renderTableView = () => (
    <Table
      {...allTableProps}
      locale={{
        emptyText: (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: `calc( ${scrollHeight} - 90px)` }}
          >
            <div>
              <ActionIconRender iconType="tablePlaceholder" />
              <p className="tablePlaceholderTitle">{noDataPlaceholderText}</p>
            </div>
          </div>
        ),
      }}
    />
  );

  const handleExportCSV = () => {
    const excel = new Excel();
    const viewRecord = dataSourceRecord.map((data) => {
      let copy = {};
      Object.keys(data).forEach((a) => {
        if (data[a + "_VIEW"]) {
          copy[a] = data[a + "_VIEW"];
        } else {
          copy[a] = data[a];
        }
      });
      return copy;
    });

    excel
      ?.addSheet("test")
      ?.addColumns(
        columnsData
          ?.map(({ render, ...rest }) => ({ ...rest }))
          ?.filter(({ dataIndex = "" }) => dataIndex !== "action")
      )
      ?.addDataSource(summaryData ? [...viewRecord, { ...summaryData }] : dataSourceRecord, {
        str2Percent: true,
      })
      ?.saveAs("Excel.xlsx");
  };

  const filterProps = {
    handleRowSize,
    columns,
    setColumns,
    chooseColumn,
    loading,
    persistKey,
    exportCSV,
    handleExportCSV,
    ...filterPropsExtra,
  };

  const optionProps = {
    leftOptions,
    rightOptions,
    isWorkspace,
    isMultiWorkspace,
    isWorkspaceWithAll,
    searchInclude,
    isUserSelection,
    isHourSelection,
    isMonthSelection,
    disabledMonths,
    isYearSelection,
    isDateSelection,
    isQuarterSelection,
    isDateRange,
    dateRangeProps,
  };

  const mainPagination = useSelector((state) => state?.userSettings?.mainPagination);

  const mainPaginationPage = useSelector(
    (state) => state?.userSettings?.mainPagination?.[persistKey]?.page
  );

  const mainPaginationSize = useSelector(
    (state) => state?.userSettings?.mainPagination?.[persistKey]?.size
  );

  const mainPaginationTotal = useSelector(
    (state) => state?.userSettings?.mainPagination?.[persistKey]?.totalCount
  );
  useEffect(() => {
    if (!mainPaginationPage) {
      dispatch({
        type: USER_SETTING_MAIN_PAGINATION,
        payload: {
          ...mainPagination,
          [persistKey]: { ...mainPagination?.[persistKey], page: 1, size: 100 },
        },
      });
    }
  }, []);

  function handlePersistPagination(page, size) {
    dispatch({
      type: USER_SETTING_MAIN_PAGINATION,
      payload: { ...mainPagination, [persistKey]: { ...mainPagination?.[persistKey], page, size } },
    });
  }

  const contextProps = {
    // chartColumnKeys,
    ...tableProps,
    columns,
    defaultColumn,
    setColumns,
    contextData,
    updateTableConfiguration,
    resetTableConfiguration,
    isLineChart,
    isVisibleChart,
    setIsVisibleChart,
    persistKey,
    isSearchFilter,
    searchBorder,
    initialColumns,
    isCustomColumnSelected,
    isCustomeView: customeView ? true : false,
  };

  // Parent to child Ref
  // useImperativeHandle -> selectedRowsClear ( For clear inturnal bulk with context form anywhere )

  const { childRef } = useLookTableParentContext();

  useImperativeHandle(childRef, () => {
    return { ...childRef.current, selectedRowsClear };
  });

  const debugContent = [
    {
      isRow: true,
      contentObjects: [
        { customColumnActiveIndex, isCustomColumnSelected },
        { renderColumns },
        { columns },
        { defaultColumn },
      ],
    },
  ];
  return (
    <div className={cx({ isLineChart, showScrollbar })}>
      <div className="table-bottom-content">
        {status && (
          <p className="mb-0">
            <b>Status:</b> {status}
          </p>
        )}
        {extraStatus && <p className="mb-0">{extraStatus}</p>}
        {tableTimeStamp && <p className="mb-0">{tableTimeStamp}</p>}
      </div>

      <TableContext.Provider value={contextProps}>
        {isTableFilter && (
          <>
            <LookTableFilter
              ruleSetting={ruleSetting}
              {...filterProps}
              {...optionProps}
              refetch={refetch}
              bulkSelectionAction={bulkSelectionAction}
              uploadTableFile={uploadTableFile}
              isCustomColumn={isCustomColumn}
              customColumnDefaultConfigKey={customColumnDefaultConfigKey} // it's unique key use in column setting for custom column
            />
          </>
        )}
        {isLineChart && <RenderLineChart />}
        <LookTableBulkAction {...bulkActionProps} />
        {errorMessage && <p className="text-danger p-40">{errorMessage}</p>}
        {customeView ? (
          <>
            <div className="table-custome-view" style={{ ...customeViewStyles }}>
              {customeView}
            </div>
          </>
        ) : (
          <>
            <div className={defaultFilterColumns ? "flex-between" : "d-flex flex-end"}>
              {defaultFilterColumns && (
                <LookTableDefaultFilter
                  filterColumns={defaultFilterColumns}
                  isScalableFilter={isScalableFilter}
                  persistKey={persistKey}
                  initialColumns={initialColumns}
                />
              )}
              {secondaryRightOptions && <div>{secondaryRightOptions}</div>}
            </div>
            {loading && renderSkeleton()}
            {renderTableView()}
          </>
        )}
      </TableContext.Provider>
      {/* <DebugPre content={debugContent} /> */}
      {/* {!(defaultPagination || persistPagination) && (
        <div className="p-8 look-bottom-pagination">
          {totalRecord !== 0 && (
            <Pagination
              showSizeChanger
              pageSizeOptions={pageSizeOptions}
              defaultCurrent={1}
              current={pagination?.page && pagination?.page}
              total={totalRecord}
              defaultPageSize={defaultPageSize}
              onChange={(page, size) => handlePagination(page, size)}
            />
          )}
        </div>
      )} */}
      {persistPagination && (
        <div className="p-8 look-bottom-pagination">
          {mainPaginationTotal !== 0 && (
            <Pagination
              showSizeChanger
              pageSizeOptions={pageSizeOptions}
              defaultCurrent={1}
              current={mainPaginationPage && mainPaginationPage}
              total={mainPaginationTotal}
              defaultPageSize={defaultPageSize}
              pageSize={mainPaginationSize && mainPaginationSize}
              onChange={(page, size) => handlePersistPagination(page, size)}
            />
          )}
        </div>
      )}
    </div>
  );
}
