import { useSelector } from "react-redux";
import {
  GET_LOOK_CATEGORIES_DROPDOWN,
  GET_SAS_AGENCIES,
  GET_SAS_CATEGORIES,
  GET_SAS_COUNTRIES,
  GET_SAS_DOMAINS_BY_CATEGORY_DROPDOWN,
  GET_SAS_FEEDS,
  GET_SAS_FEEDS_DROPDOWN,
  GET_SAS_LANGUAGES,
  GET_SAS_PIXELS,
  GET_SAS_PLATFORM,
  GET_SAS_PLATFORM_BY_USER_FOR_REDUCER_DROPDOWN,
  GET_SAS_SAS_AGENCY_USER_BY_USER_FOR_REDUCER_DROPDOWN,
  GET_SAS_USER_BY_ID,
  GET_SAS_USER_WITH_PLATFORM,
  GET_SAS_VENDORS,
  GET_SAS_VENDOR_FOR_REDUCER_DROPDOWN,
  GET_SAS_VERTICALS,
  GQL_SAS_WEBSITES,
} from "shared/gql/gqlSchema/sasGql";

import {
  API_STORE_SAS_AGENCY_USERS,
  API_STORE_SAS_CATEGORIES,
  API_STORE_SAS_COUNTRIES,
  API_STORE_SAS_FEEDS,
  API_STORE_SAS_FEED_DATA,
  API_STORE_SAS_LANGUAGES,
  API_STORE_SAS_LOOK_CATEGORIES,
  API_STORE_SAS_PIXELS,
  API_STORE_SAS_PLATFORM,
  API_STORE_SAS_PLATFORM_BY_USER,
  API_STORE_SAS_USER,
  API_STORE_SAS_USER_WITH_PLATFORM,
  API_STORE_SAS_VENDOR_DATA,
  API_STORE_SAS_VENDOR_DROPDOWN,
  API_STORE_SAS_VERTICALS,
} from "./keys";

import useApiToStoreQuery from "./useApiToStoreQuery";

export function useSasApiParams() {
  const {
    apiCall: sasLookCategoriesFetch = () => {},
    refetch: sasLookCategoriesRefetch,
    setDataSource: sasLookCategoriesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_LOOK_CATEGORIES_DROPDOWN,
    elementKey: "sasLookCategories",
    storeKey: "sasLookCategories",
  });

  const {
    apiCall: sasCategoriesFetch = () => {},
    refetch: sasCategoriesRefetch,
    setDataSource: sasCategoriesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_SAS_CATEGORIES,
    elementKey: "sasCategories",
    storeKey: "sasCategories",
  });

  const {
    apiCall: sasLanguagesFetch = () => {},
    refetch: sasLanguagesRefetch,
    setDataSource: sasLanguagesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_SAS_LANGUAGES,
    elementKey: "SasLanguages",
    storeKey: "sasLanguages",
  });

  const {
    apiCall: sasCountriesFetch = () => {},
    refetch: sasCountriesRefetch,
    setDataSource: sasCountriesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_SAS_COUNTRIES,
    elementKey: "SasCountries",
    storeKey: "sasCountries",
  });

  const {
    apiCall: sasDomainByCategoryFetch = () => {},
    refetch: sasDomainByCategoryRefetch,
    setDataSource: sasDomainByCategorySetDataSource,
  } = useApiToStoreQuery({
    api: GET_SAS_DOMAINS_BY_CATEGORY_DROPDOWN,
    elementKey: "sasFetchDomainByCategory",
    storeKey: "sasDomainByCategory",
  });

  const {
    apiCall: sasAgencyUsersFetch = () => {},
    refetch: sasAgencyUsersRefetch,
    setDataSource: sasAgencyUsersSetDataSource,
  } = useApiToStoreQuery({
    api: GET_SAS_SAS_AGENCY_USER_BY_USER_FOR_REDUCER_DROPDOWN,
    elementKey: "sasAgencyUsers",
    storeKey: "sasAgencyUsers",
  });

  const {
    apiCall: sasWebsitesFetch = () => {},
    refetch: sasWebsitesRefetch,
    setDataSource: sasWebsitesSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_SAS_WEBSITES,
    elementKey: "sasWebsites",
    storeKey: "sasWebsites",
    newResponse: true,
  });
  const {
    apiCall: sasPlatformsFetch = () => {},
    refetch: sasPlatformsRefetch,
    setDataSource: sasPlatformsSetDataSource,
  } = useApiToStoreQuery({
    api: GET_SAS_PLATFORM,
    elementKey: "sasPlatforms",
    storeKey: "sasPlatforms",
  });
  const {
    apiCall: sasAgenciesFetch = () => {},
    refetch: sasAgenciesRefetch,
    setDataSource: sasAgenciesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_SAS_AGENCIES,
    elementKey: "sasAgencies",
    storeKey: "sasAgencies",
  });

  return {
    sasCategoriesFetch,
    sasCategoriesRefetch,
    sasCategoriesSetDataSource,

    sasLookCategoriesFetch,
    sasLookCategoriesRefetch,
    sasLookCategoriesSetDataSource,

    sasLanguagesFetch,
    sasLanguagesRefetch,
    sasLanguagesSetDataSource,

    sasDomainByCategoryFetch,
    sasDomainByCategoryRefetch,
    sasDomainByCategorySetDataSource,

    sasCountriesFetch,
    sasCountriesRefetch,
    sasCountriesSetDataSource,

    sasAgencyUsersFetch,
    sasAgencyUsersRefetch,
    sasAgencyUsersSetDataSource,

    sasWebsitesFetch,
    sasWebsitesRefetch,
    sasWebsitesSetDataSource,

    sasPlatformsFetch,
    sasPlatformsRefetch,
    sasPlatformsSetDataSource,

    sasAgenciesFetch,
    sasAgenciesRefetch,
    sasAgenciesSetDataSource,
  };
}

export default function useSasApis(typeKey) {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  switch (typeKey) {
    case API_STORE_SAS_PLATFORM_BY_USER:
      return {
        api: GET_SAS_PLATFORM_BY_USER_FOR_REDUCER_DROPDOWN,
        elementKey: "sasPlatformsByUser",
        storeKey: "sasPlatformsByUser",
      };

    case API_STORE_SAS_PLATFORM:
      return {
        api: GET_SAS_PLATFORM,
        elementKey: "sasPlatforms",
        storeKey: "sasPlatforms",
      };

    case API_STORE_SAS_LANGUAGES:
      return {
        api: GET_SAS_LANGUAGES,
        elementKey: "SasLanguages",
        storeKey: "sasLanguages",
      };

    case API_STORE_SAS_COUNTRIES:
      return {
        api: GET_SAS_COUNTRIES,
        elementKey: "SasCountries",
        storeKey: "sasCountries",
      };

    case API_STORE_SAS_FEEDS:
      return {
        api: GET_SAS_FEEDS_DROPDOWN,
        elementKey: "sasFeeds",
        storeKey: "sasFeeds",
      };

    case API_STORE_SAS_LOOK_CATEGORIES:
      return {
        api: GET_LOOK_CATEGORIES_DROPDOWN,
        elementKey: "sasLookCategories",
        storeKey: "sasLookCategories",
      };

    case API_STORE_SAS_CATEGORIES:
      return {
        api: GET_SAS_CATEGORIES,
        elementKey: "sasCategories",
        storeKey: "sasCategories",
      };

    case API_STORE_SAS_AGENCY_USERS:
      return {
        api: GET_SAS_SAS_AGENCY_USER_BY_USER_FOR_REDUCER_DROPDOWN,
        elementKey: "sasAgencyUsers",
        storeKey: "sasAgencyUsers_dropdown",
      };

    case API_STORE_SAS_PIXELS:
      return {
        api: GET_SAS_PIXELS,
        elementKey: "sasPixels",
        storeKey: "sasPixels",
      };

    case API_STORE_SAS_VERTICALS:
      return {
        api: GET_SAS_VERTICALS,
        elementKey: "sasVerticals",
        storeKey: "sasVerticals",
      };

    case API_STORE_SAS_VENDOR_DROPDOWN:
      return {
        api: GET_SAS_VENDOR_FOR_REDUCER_DROPDOWN,
        elementKey: "sasVendors",
        storeKey: "sasVendors_dropdown",
      };

    case API_STORE_SAS_VENDOR_DATA:
      return {
        api: GET_SAS_VENDORS,
        elementKey: "sasVendors",
        storeKey: "sasVendors",
      };

    case API_STORE_SAS_USER:
      return {
        api: GET_SAS_USER_BY_ID,
        elementKey: "sasUser",
        storeKey: "sasUser",
        variables: {
          id: user_id,
        },
        objectType: true,
      };

    case API_STORE_SAS_USER_WITH_PLATFORM:
      return {
        api: GET_SAS_USER_WITH_PLATFORM,
        elementKey: "sasUser",
        storeKey: "sasUser",
        variables: {
          id: user_id,
        },
        objectType: true,
      };

    case API_STORE_SAS_FEED_DATA:
      return {
        api: GET_SAS_FEEDS,
        elementKey: "sasFeeds",
        storeKey: "sasFeeds",
      };

    default:
      return false;
  }
}
