import { useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useState } from "react";
import { LAUNCH_TIKTOK_ADD_IDENTITY_NAME } from "shared/gql/gqlSchema/launchGql";
import { openNotification } from "utils";

export default function useAddNewIdentity() {
  // useEffect(() => {
  //   addNewIdentity();
  // }, []);

  const [addNewIdentity, { data: data_identity_id, loading: loading_identity_id }] =
    useCustomMutation(LAUNCH_TIKTOK_ADD_IDENTITY_NAME, {
      onCompleted: (e) => {
        const element = e?.addNewIdentityId;
        if (element?.status === "Successful") {
          // creatiVideo_refetch();

          openNotification({ type: "success", message: element?.message });
        } else {
          openNotification({ type: "error", message: element?.message });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.addNewIdentityId?.message });
      },
    });

  return { addNewIdentity, data_identity_id, loading_identity_id };
}
