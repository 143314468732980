import React, { useEffect, useState } from "react";
import SelectInput from "components/forms/SelectInput";
import { Col, Row, Slider } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import LookButton from "components/LookButton";

export default function CanvasActions({
  // backgroundColor,
  onColorChange,
  onTemplateChange,
  template,
  textFillColor,
  onTextFillColorChange,
  textArray,
  onTextChange,
  onShuffleImages,
  handleDownloadCanvas,
  handleUpload,
  uploadLoading,
  images,
  filteredTemplates,
  // onImagePositionChange,
  // onCreateTemplate,
}) {
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <LookTextInput
            className="mb-14"
            label="Text colour"
            type="color"
            value={textFillColor || "#ffffff"} // Use the new text color state
            onChange={onTextFillColorChange} // Handle the text color change
          />
        </Col>
        <Col span={12}>
          <div className=" w-100 d-flex justify-content-end">
            <LookButton onClick={handleDownloadCanvas} iconType="download" />
          </div>
        </Col>
        {/* <Col span={12}>
          <LookTextInput
            className="mb-m-18"
            label="Background colour"
            type="color"
            value={backgroundColor}
            onChange={onColorChange}
          />
        </Col> */}

        <Col span={16}>
          <SelectInput
            className="mb-m-18"
            multiple={false}
            placeholder="Select template"
            name="template"
            value={template}
            options={filteredTemplates?.map((data, index) => ({
              name: `Template ${index + 1} - ${data?.noOfImages} img`,
              value: index,
            }))}
            onChange={(e) => {
              onTemplateChange(e);
            }}
          />
        </Col>
        <Col span={8}>
          <LookButton className="mb-m-18" tooltipTitle="Shuffle images" onClick={onShuffleImages}>
            Shuffle Images
          </LookButton>
        </Col>
      </Row>
      <Row gutter={24}>
        {textArray.map((image, index) => (
          <>
            <Col span={24}>
              <LookTextInput
                name="domainName"
                label={`Text box ${index + 1}`}
                placeholder={`Text ${index + 1}`}
                className="look-form-input"
                value={image.text || ""}
                onChange={(e) => onTextChange(index, e.target.value)}
              />
            </Col>
          </>
        ))}
      </Row>
      <Col span={24}>
        <div className=" w-100 d-flex justify-content-center mt-18">
          <LookButton onClick={handleUpload} loading={uploadLoading}>
            Upload Collage
          </LookButton>
        </div>
      </Col>
    </div>
  );
}
