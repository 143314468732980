import { Form } from "antd";
import LookTextInput from "components/forms/LookTextInput";

export default function CampaingHandle(props) {
  const { onChange = () => {}, value } = props;

  return (
    <div className="position-relative">
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex-grow-1">
            <LookTextInput
              type="number"
              name="campaignId"
              label="Campign Id"
              className="look-form-input"
              placeholder="Campign Id"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
