import { Col, Row } from "antd";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { usePermissionCheck } from "hooks";
import React, { useState } from "react";
import {
  SCORECARD_VIEW_ADMIN,
  SCORECARD_VIEW_DEPARTMENT,
} from "shared/UserPermission/userPermissionList";
// import { CompanyGraph, DepartmentGraph, UserGraph } from "./Graphs";
import Overview from "./Overview";
import { useSelector } from "react-redux";
import LaunchGraph from "./Graphs";
import CreativeGroups from "./CreativeGroups";

function LaunchDashboard(props) {
  const [selectedPlatform, setSelectedPlatform] = useState("FACEBOOK");

  return (
    <div>
      <DashboardHeader title="Dashboard" />
      {/* <LookDrawer {...drawerProps}>
          {visibleCreateDrawer && (
            <LookDrawerMainSideBar
              tabContent={[
                {
                  hoverTitle: "Overview",
                  icon: () => <ActionIconRender iconType="pic-left" />,
                  component: addTargetComponent(),
                },
              ]}
            />
          )}
        </LookDrawer> */}
      <div className="m-15">
        <Row gutter={12}>
          <Col span={24}>
            <Overview
              setSelectedPlatform={setSelectedPlatform}
              selectedPlatform={selectedPlatform}
              {...props}
            />
          </Col>
          <Col span={24}>
            <LaunchGraph selectedPlatform={selectedPlatform} {...props} />
          </Col>

          {/* <Col span={24}>
            <CreativeGroups selectedPlatform={selectedPlatform} {...props} />
          </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default LaunchDashboard;
