import { gql } from "@apollo/client";

export const GET_AGENCY_CONGIFURATION = gql`
  query agencyConfiguration($meta_name: String!) {
    agencyConfiguration(meta_name: $meta_name)
  }
`;

export const GET_ALL_AGENCY_CONGIFURATION = gql`
  query agency_settings {
    timezone: agencyConfiguration(meta_name: "timezone")
    currency: agencyConfiguration(meta_name: "currency")
    iTaskTimezone: userConfiguration(meta_name: "iTaskTimezone")
  }
`;

export const GET_ALL_USER_CONGIFURATION = gql`
  query user_settings {
    timezone: userConfiguration(meta_name: "timezone")
    currency: userConfiguration(meta_name: "currency")
    iTaskTimezone: userConfiguration(meta_name: "iTaskTimezone")
    tableRowSize: userConfiguration(meta_name: "tableRowSize")
  }
`;

export const GET_USER_CONGIFURATION = gql`
  query userConfiguration($meta_name: String!) {
    userConfiguration(meta_name: $meta_name)
  }
`;

export const UPDATE_AGENCY_CONGIFURATION = gql`
  mutation agencyConfiguration($meta_name: String!, $meta_value: String!) {
    agencyConfiguration(meta_name: $meta_name, meta_value: $meta_value)
  }
`;

export const UPDATE_USER_CONGIFURATION = gql`
  mutation updateUserConfiguration($meta_name: String!, $meta_value: String!) {
    updateUserConfiguration(meta_name: $meta_name, meta_value: $meta_value)
  }
`;

export const ADD_PERMISSION = gql`
  mutation createPermission($name: String!, $description: String!) {
    CreatePermission(name: $name, description: $description) {
      name
    }
  }
`;

export const UPDATE_PERMISSION = gql`
  mutation updatePermission($id: ID!, $name: String!, $description: String) {
    UpdatePermission(id: $id, name: $name, description: $description) {
      id
      name
    }
  }
`;

export const DELETE_PERMISSION = gql`
  mutation deletePermission($id: ID!) {
    DeletePermission(id: $id)
  }
`;

export const GET_ALL_COUNTRY_CURRENCY = gql`
  query umsCountries {
    umsCountries {
      id
      currency_code
      currency_name
      iso_code
      name
    }
  }
`;

export const GQL_UMS_LANGUAGES = gql`
  query umsLanguages {
    umsLanguages {
      id
      name
      code
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query {
    permissions {
      id
      name
    }
  }
`;

export const GET_ROLES = gql`
  query {
    roles {
      id
      name
      description
      permissions {
        id
        name
      }
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation DeleteRole($id: ID!) {
    DeleteRole(id: $id)
  }
`;

export const UPDATE_ROLE_PERMISSIONS = gql`
  mutation UpdateRolePermissions($permissions: [ID], $role_id: ID!) {
    UpdateRolePermissions(permissions: $permissions, role_id: $role_id) {
      id
    }
  }
`;

export const ADD_ROLES = gql`
  mutation CreateRole($name: String!, $description: String) {
    CreateRole(name: $name, description: $description) {
      id
      name
      description
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($id: ID!, $name: String!, $description: String) {
    UpdateRole(id: $id, name: $name, description: $description) {
      id
      name
    }
  }
`;

export const ADD_DEPARTMENT = gql`
  mutation createDepartment($title: String!, $description: String) {
    CreateDepartment(title: $title, description: $description) {
      id
      title
      description
    }
  }
`;

export const UPDATE_DEPARTMENT = gql`
  mutation updateDepartment($id: ID!, $title: String, $description: String) {
    UpdateDepartment(id: $id, title: $title, description: $description) {
      id
      title
      description
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation deleteDepartment($id: ID!) {
    DeleteDepartment(id: $id)
  }
`;

export const PAGED_DEPARTMENTS_BY_AGENCY = gql`
  query agency($id: ID, $column: String, $order: order, $page: Int!, $search: String, $size: Int) {
    agency(id: $id) {
      id
      pagedDepartments(column: $column, order: $order, page: $page, search: $search, size: $size) {
        pageInfo {
          totalCount
          totalPages
        }
        data {
          id
          title
          description
        }
      }
    }
  }
`;

export const ASSIGN_MULTIPLE_AD_ACCOUNT_TO_USER = gql`
  mutation assignMultipleAdAccountsToUser($adAccountId: [ID]!, $userId: ID!, $workspaceId: ID!) {
    assignMultipleAdAccountsToUser(
      adAccountId: $adAccountId
      userId: $userId
      workspaceId: $workspaceId
    )
  }
`;

export const ADD_WORKSPACES_TO_USER = gql`
  mutation AddWorkspacesToUser($user_id: ID!, $workspaces: [ID]!) {
    AddWorkspacesToUser(user_id: $user_id, workspaces: $workspaces)
  }
`;

export const ADD_WORKSPACE_TO_USERS = gql`
  mutation AddWorkspaceToUsers($userId: [ID]!, $workspaceId: ID!) {
    AddWorkspaceToUsers(user_id: $userId, workspace_id: $workspaceId)
  }
`;

export const UPDATE_USER_ROLES = gql`
  mutation UpdateUserRoles($user_id: ID!, $roles: [ID]) {
    UpdateUserRoles(user_id: $user_id, roles: $roles) {
      id
      name
      description
      permissions {
        id
        name
      }
    }
  }
`;

export const GET_AD_ACCOUNT_ID_NAME = gql`
  query AD_ACCOUNT($page: Int!, $size: Int!) {
    adAccounts(page: $page, size: $size) {
      data {
        id
        accountId
        name
      }
    }
  }
`;

export const PAGED_USERS_BY_AGENCY = gql`
  query agency($id: ID, $column: String, $order: order, $page: Int!, $search: String, $size: Int) {
    agency(id: $id) {
      id
      pagedUsers(column: $column, order: $order, page: $page, search: $search, size: $size) {
        pageInfo {
          totalCount
          totalPages
        }
        data {
          id
          name
          email
          role
          picture
          workspaces {
            title
            id
            role
            teams {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const UMS_AGENCIES_RESPONSE = gql`
  fragment Agency on Agency {
    id
    name
    email
    contact_no
    products {
      id
    }
  }
`;

export const UMS_AGENCIES = gql`
  ${UMS_AGENCIES_RESPONSE}
  query agencies {
    agencies {
      ...Agency
    }
  }
`;

export const WORKSPACES_BY_AGENCY_ID_TITLE = gql`
  query agency($id: ID) {
    agency(id: $id) {
      id
      workspaces {
        id
        title
      }
    }
  }
`;

export const USERS_BY_AGENCY = gql`
  query agency($id: ID) {
    agency(id: $id) {
      id
      users {
        id
        name
        email
        role
        workspaces {
          title
          id
        }
      }
    }
  }
`;

export const ADD_USERS_TO_WORKSPACES = gql`
  mutation AddUsersToWorkspace($users: [ID], $workspace_id: ID!) {
    AddUsersToWorkspace(users: $users, workspace_id: $workspace_id)
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace(
    $title: String
    $description: String
    $product_id: [ID]
    $team_id: [ID]
    $user_id: [ID]
  ) {
    UpdateWorkspace(
      title: $title
      description: $description
      product_id: $product_id
      team_id: $team_id
      user_id: $user_id
    ) {
      id
      title
    }
  }
`;

export const ADD_WORKSPACE = gql`
  mutation createWorkspace(
    $is_personal: Boolean!
    $title: String!
    $description: String
    $product_id: [ID]
    $team_id: [ID]
  ) {
    CreateWorkspace(
      is_personal: $is_personal
      title: $title
      description: $description
      product_id: $product_id
      team_id: $team_id
    ) {
      id
      description
    }
  }
`;

// export const DELETE_WORKSPACE = gql`
//   mutation deleteWorkspace($id: ID!) {
//     DeleteWorkspace(id: $id)
//   }
// `;
export const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace {
    DeleteWorkspace
  }
`;
export const PAGED_WORKSPACES_BY_AGENCY = gql`
  query agency($id: ID, $column: String, $order: order, $page: Int!, $search: String, $size: Int) {
    agency(id: $id) {
      id
      pagedWorkspaces(column: $column, order: $order, page: $page, search: $search, size: $size) {
        pageInfo {
          totalCount
          totalPages
        }
        data {
          id
          title
          description
          users {
            id
            name
            role
          }
          teams {
            id
            name
          }
          products {
            id
            name
          }
        }
      }
    }
  }
`;

const UMS_PRODUCT_RESPONSE = gql`
  fragment Product on Product {
    id
    name
    description
    agencies {
      id
    }
    workspaces {
      id
      title
      users {
        id
        name
      }
    }
    services {
      id
    }
  }
`;

const UMS_PRODUCT_DROPDOWN_RESPONSE = gql`
  fragment Product_Dropdown on Product {
    id
    name
  }
`;

export const UMS_WORKSPACE_AGENCY_WISE_PRODUCTS = gql`
  query workspace($id: ID) {
    workspace(id: $id) {
      id
      agency {
        id
        products {
          id
          name
        }
      }
    }
  }
`;

export const UMS_WORKSPACE_WISE_PRODUCTS = gql`
  query workspace($id: ID) {
    workspace(id: $id) {
      id
      products {
        id
        name
      }
    }
  }
`;

// dms

export const DMS_GET_ACCOUNT_DROPDOWN = gql`
  query dms_get_accounts {
    dms_get_accounts {
      user_name
      id
    }
  }
`;

const DMS_ACCOUNT_RESPONSE = gql`
  fragment dms_account on dms_account {
    id
    admin_address1
    admin_city
    admin_country
    admin_email_address
    admin_first_name
    admin_last_name
    admin_organization_name
    admin_phone
    admin_postal_code
    admin_state_province
    aux_billing_address1
    aux_billing_city
    aux_billing_country
    aux_billing_email
    aux_billing_first_name
    aux_billing_last_name
    aux_billing_organization_name
    aux_billing_phone
    aux_billing_postal_code
    aux_billing_state_province
    description
    key
    registrant_address1
    registrant_city
    registrant_country
    registrant_email_address
    registrant_first_name
    registrant_last_name
    registrant_organization_name
    registrant_phone
    registrant_postal_code
    registrant_state_province
    tech_address1
    tech_city
    tech_country
    tech_email_address
    tech_first_name
    tech_last_name
    tech_organization_name
    tech_phone
    tech_postal_code
    tech_state_province
    user_name
  }
`;

export const GET_BLOB_FOR_CREATIVES = gql`
  mutation getBlob($urls: [String!]!) {
    getBlob(urls: $urls) {
      blob
      url
    }
  }
`;

export const UMS_WORKSPACE_WISE_USERS = gql`
  query workspace($id: ID) {
    workspace(id: $id) {
      users {
        id
        name
        email
        role
        platforms {
          name
          id
        }
        customRoles
        # workspaces {
        #   id
        #   title
        # }
      }
    }
  }
`;

export const UMS_PRODUCTS = gql`
  ${UMS_PRODUCT_RESPONSE}
  query products {
    products {
      ...Product
    }
  }
`;

export const UMS_PRODUCTS_IDS = gql`
  query products {
    products {
      id
      name
    }
  }
`;

const UMS_PRODUCTS_FOR_REDUCER_DROPDOWN_RESPONSE = gql`
  fragment products_For_Reducer_dropdown on Product {
    id
    name
  }
`;

export const UMS_PRODUCTS_FOR_REDUCER_DROPDOWN = gql`
  ${UMS_PRODUCTS_FOR_REDUCER_DROPDOWN_RESPONSE}
  query products {
    products {
      ...products_For_Reducer_dropdown
    }
  }
`;

export const UMS_AGENCY_PRODUCTS = gql`
  ${UMS_PRODUCT_RESPONSE}
  query agency($id: ID) {
    agency(id: $id) {
      products {
        ...Product
      }
    }
  }
`;

export const UMS_CREATE_PRODUCT = gql`
  ${UMS_PRODUCT_RESPONSE}
  mutation CreateProduct($description: String, $name: String!) {
    CreateProduct(description: $description, name: $name) {
      ...Product
    }
  }
`;

export const UMS_GET_WORKSPACE_PRODUCTS = gql`
  ${UMS_PRODUCT_DROPDOWN_RESPONSE}
  query workspace($id: ID) {
    workspace(id: $id) {
      products {
        ...Product_Dropdown
      }
    }
  }
`;

export const UMS_DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    DeleteProduct(id: $id)
  }
`;

export const UMS_ADD_PRODUCTS_TO_WORKSPACE = gql`
  mutation AddProductsToWorkspace($products: [ID]!) {
    AddProductsToWorkspace(products: $products) {
      id
    }
  }
`;

export const UMS_ASSIGN_PRODUCTS_TO_AGENCY = gql`
  mutation AssignProductsToAgency($agency_id: ID!, $products: [ID]) {
    AssignProductsToAgency(agency_id: $agency_id, products: $products) {
      id
    }
  }
`;

export const UMS_ASSIGN_SERVICES_TO_PRODUCTS = gql`
  mutation AssignServicesToProduct($product_id: ID!, $services: [ID]) {
    AssignServicesToProduct(product_id: $product_id, services: $services) {
      id
    }
  }
`;

export const UMS_SERVICES = gql`
  query services {
    services {
      id
      name
    }
  }
`;

const UMS_TEAM_RESPONSE = gql`
  fragment Team on Team {
    id
    name
    admins {
      id
      name
    }
    members {
      id
      name
      email
    }
    workspace {
      id
      title
    }
  }
`;

export const UMS_TEAMS = gql`
  ${UMS_TEAM_RESPONSE}
  query workspace($id: ID) {
    workspace(id: $id) {
      teams {
        ...Team
        members {
          id
          name
          email
          platforms {
            id
            name
          }
          role
        }
      }
      users {
        id
        name
      }
    }
  }
`;

// export const UMS_PAGED_TEAMS = gql`
//   ${UMS_TEAM_RESPONSE}
//   query Workspaces($column: String, $order: order, $page: Int!, $search: String, $size: Int) {
//     workspaces {
//       pagedUsers(column: $column, order: $order, page: $page, search: $search, size: $size) {
//         pageInfo {
//           totalPages
//           totalCount
//         }
//         data {
//           teams {
//             ...Team
//             members {
//               id
//               name
//               email
//               platforms {
//                 id
//                 name
//               }
//               role
//             }
//           }
//         }
//         users {
//           id
//           name
//         }
//       }
//     }
//   }
// `;

export const UMS_ADD_TEAM = gql`
  ${UMS_TEAM_RESPONSE}
  mutation CreateTeam($name: String!) {
    CreateTeam(name: $name) {
      ...Team
    }
  }
`;

export const UMS_DELETE_TEAM = gql`
  mutation DeleteTeam($id: ID!) {
    DeleteTeam(id: $id)
  }
`;

export const UMS_PERMISSIONS_BY_WORKSPACE = gql`
  query UmsPermissionByWorkspace($workspace_id: ID!) {
    umsPermissionByWorkspace(workspace_id: $workspace_id) {
      data
      message
      status
    }
  }
`;

export const UMS_CREATE_CUSTOME_ROLE = gql`
  mutation CreateCustomRole(
    $name: String!
    $workspace_id: ID!
    $service_permissions: [customRoleServiceObject]
  ) {
    CreateCustomRole(
      name: $name
      workspace_id: $workspace_id
      service_permissions: $service_permissions
    ) {
      status
      message
    }
  }
`;

export const UMS_CUSTOME_ROLES = gql`
  query UmsCustomRoles($workspace_id: ID!) {
    umsCustomRoles(workspace_id: $workspace_id) {
      data
      message
      status
    }
  }
`;

export const UMS_ASSIGN_CUSTOME_ROLE = gql`
  mutation UmsAssignCustomRoles($customRole_ids: [ID!]!, $user_id: ID!, $workspace_id: ID!) {
    umsAssignCustomRoles(
      customRole_ids: $customRole_ids
      user_id: $user_id
      workspace_id: $workspace_id
    )
  }
`;

export const UMS_ASSIGN_USERS_TO_TEAM = gql`
  mutation AddUsersToTeam($id: ID!, $users: [ID]!) {
    AddUsersToTeam(id: $id, users: $users)
  }
`;

export const UMS_ASSIGN_ADMINS_TO_TEAM = gql`
  mutation AddAdminsToTeam($id: ID!, $users: [ID]!) {
    AddAdminsToTeam(id: $id, users: $users)
  }
`;

export const GET_MY_PROXY = gql`
  query myProxy {
    myProxy {
      userName
      proxyStatus
    }
  }
`;

export const UMS_ADD_PROXY = gql`
  mutation addProxy($userName: String) {
    addProxy(userName: $userName) {
      userName
    }
  }
`;

export const UMS_REMOVE_USERS_FROM_WORKSPACE = gql`
  mutation RemoveUsersFromWorkspace($users: [ID]!) {
    RemoveUsersFromWorkspace(users: $users)
  }
`;

export const UMS_ASSIGN_PLATFORM_TO_USERS = gql`
  mutation AssignPlatformToUsers($platformId: [ID]!, $userId: ID!, $workspaceId: ID!) {
    AssignPlatformToUsers(platform_id: $platformId, user_id: $userId, workspace_id: $workspaceId)
  }
`;

export const UMS_REMOVE_USERS_FROM_SPECIFIC_WORKSPACE = gql`
  mutation RemoveUsersFromSpecificWorkspace($usersId: [ID]!, $workspaceId: ID!) {
    RemoveUsersFromSpecificWorkspace(users_id: $usersId, workspace_id: $workspaceId)
  }
`;

export const UMS_REMOVE_USERS_FROM_TEAM = gql`
  mutation RemoveUsersFromTeam($teamId: ID!, $users: [ID]!) {
    RemoveUsersFromTeam(id: $teamId, users: $users)
  }
`;

export const UMS_GET_ALL_USERS_FROM_WORKSPACE = gql`
  query agency($id: ID) {
    agency(id: $id) {
      users {
        id
        name
        email
      }
    }
  }
`;

export const UMS_WORKSPACE_UPDATE_USER_ROLE = gql`
  mutation UpdateRoleUserWorkspace($isAdmin: Boolean!, $userId: ID!, $workspaceId: ID!) {
    UpdateRoleUserWorkspace(is_admin: $isAdmin, user_id: $userId, workspace_id: $workspaceId)
  }
`;

export const UMS_TEAM_UPDATE_USER_ROLE = gql`
  mutation UpdateRoleUserTeam($isAdmin: Boolean!, $teamId: ID!, $userId: ID!) {
    UpdateRoleUserTeam(is_admin: $isAdmin, team_id: $teamId, user_id: $userId)
  }
`;

export const UMS_PLATFORMS = gql`
  query UmsPlatforms {
    umsPlatforms {
      id
      name
    }
  }
`;

const WORKSPACE_USERS_DROPDOWN_RESPONSE = gql`
  fragment Workspace on Workspace {
    users {
      id
      name
    }
  }
`;

export const GQL_GET_SELECTED_WORKSPACE_USERS_DROPDOWN = gql`
  ${WORKSPACE_USERS_DROPDOWN_RESPONSE}
  query workspace($id: ID) {
    workspace(id: $id) {
      ...Workspace
    }
  }
`;

export const GQL_GET_WORKSPACE_WISE_TEAM_DROPDOWN = gql`
  query workspace($id: ID) {
    workspace(id: $id) {
      teams{
        id
        name
      }
    }
  }
`;

export const GET_WORKSPACE_USERS = gql`
  query Workspace($workspaceId: ID) {
    workspace(id: $workspaceId) {
      users {
        id
        name
        role
      }
    }
  }
`;

export const GET_UMS_PERMISSION_USERS = gql`
  query umsPermissionsWiseUser($permissions: [String!]) {
    umsPermissionsWiseUser(permissions: $permissions)
  }
`;
