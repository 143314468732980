import { Popover, Collapse, Input, Spin, Row } from "antd";
import ActionIconRender from "components/ActionIconRender";
import { useEffect, useState, useMemo, useCallback } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { CustomeIconWrapper } from "shared/useDashboardRoutes";
import LookButton from "components/LookButton";
import { industryInsightsFilterPopupCategoryLabels as LABELS } from "shared/enum/industryInsightsKeysAndEnums";
import CategoryScroll from "./CategoryScroll";

const { Panel } = Collapse;

const FilterPopup = ({
  globalSelectedFilters,
  setGlobalSelectedFilters,
  filterCategoryData,
  isFilterActive,
  setIsFilterActive,
  setInfiniteScrollObject,
  setSearchObject,
  loader = false,
}) => {
  // component's react states
  const [categoriesData, setCategoriesData] = useState(filterCategoryData);
  const [searchFilterObject, setSearchFilterObject] = useState();
  const [popperVisible, setPopperVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(globalSelectedFilters);
  const [selectedFiltersArray, setSelectedFiltersArray] = useState([]);
  const [panelActiveKey, setPanelActiveKey] = useState(undefined);

  useEffect(() => {
    setCategoriesData(filterCategoryData);
  }, [filterCategoryData]);

  // variables that contain data for Countries, Domains & Pages' nested sub values
  const { launchCountries, keywordDomainsData, keywordPagesData } = categoriesData;

  // gets the count of selected filters for each category
  const getSelectedFilterCount = useCallback(
    (category) => {
      const categoryObject = selectedFilters[category];

      const selectedCount = Object.keys(categoryObject ?? {}).filter(
        (key) => categoryObject[key] === true
      ).length;

      return selectedCount;
    },
    [selectedFilters]
  );

  /**
   * object which displays the categories & its subvalues on UI
   * whenever the search value changes or user selects new value
   * this object is updated & displays updated(filtered or selected) data on UI
   */

  const filterObjects = useMemo(() => {
    // sets selected filters on top of array, followed by results from API
    const setSelectedFiltersOnTop = (wholeData, category) => {
      const selectedData = globalSelectedFilters[category] || [];

      const selectedIds = new Set(selectedData?.map((item) => item.id));
      const intersectingArray = wholeData.filter((item) => !selectedIds.has(item.id));

      const result =
        searchFilterObject && searchFilterObject?.searchValue
          ? [...intersectingArray, ...selectedData]
          : [...selectedData, ...intersectingArray];
      return result;
    };

    // filters out unqiue records by ID - since IDs cannot be same. any duplicates pushed into the state would be removed
    const filterUniqueById = (items) => {
      const uniqueIds = new Set();
      const uniqueItems = [];

      for (const item of items) {
        if (!uniqueIds.has(item.id)) {
          uniqueIds.add(item.id);
          uniqueItems.push(item);
        }
      }

      return uniqueItems;
    };

    // adds a check `isSelected: boolean` to each subvalue of the passed array if selected or unselected
    const handleSubvalueSelected = (arr, category, selectedFiltersObject) => {
      return arr.map((it) => {
        return {
          ...it,
          isSelected:
            (selectedFiltersObject &&
              selectedFiltersObject[category] &&
              selectedFiltersObject[category][it.value]) ??
            false,
        };
      });
    };

    // flow - first implement selection on ARRAY then implement SEARCH filter on that array

    // array of subvalues for each category that contains info about selection as well
    const finalCountriesData = handleSubvalueSelected(
      launchCountries,
      LABELS.COUNTRIES,
      selectedFilters
    );
    const finalDomainsData = handleSubvalueSelected(
      keywordDomainsData,
      LABELS.DOMAINS,
      selectedFilters
    );
    const finalPagesData = handleSubvalueSelected(keywordPagesData, LABELS.PAGES, selectedFilters);

    return [
      {
        id: 0,
        headerLabel: LABELS.COUNTRIES,
        subvalues:
          searchFilterObject && searchFilterObject.value !== ""
            ? filterUniqueById(
                setSelectedFiltersOnTop(finalCountriesData, LABELS.COUNTRIES)
              ).filter((it) =>
                it.name?.toLowerCase().includes(searchFilterObject?.searchValue?.toLowerCase())
              )
            : filterUniqueById(setSelectedFiltersOnTop(finalCountriesData, LABELS.COUNTRIES)),
        filterCount: getSelectedFilterCount(LABELS.COUNTRIES), // count for selected filters
      },
      {
        id: 1,
        headerLabel: LABELS.DOMAINS,
        subvalues: filterUniqueById(setSelectedFiltersOnTop(finalDomainsData, LABELS.DOMAINS)),
        filterCount: getSelectedFilterCount(LABELS.DOMAINS),
      },
      {
        id: 2,
        headerLabel: LABELS.PAGES,
        subvalues: filterUniqueById(setSelectedFiltersOnTop(finalPagesData, LABELS.PAGES)),
        filterCount: getSelectedFilterCount(LABELS.PAGES),
      },
    ];
  }, [
    keywordDomainsData,
    keywordPagesData,
    selectedFilters,
    launchCountries,
    getSelectedFilterCount,
    searchFilterObject,
  ]);

  // gets the count of selected filters for each category
  const isFilterApplied = useCallback(() => {
    const netFiltersLength = Object.values(LABELS)
      .map((it) => getSelectedFilterCount(it))
      .reduce((sum, current) => (sum += current));

    return netFiltersLength > 0 && isFilterActive;
  }, [getSelectedFilterCount, isFilterActive]);

  // opens & closes filter popup
  const handleFilterOpenChange = (newOpen) => {
    setPopperVisible(newOpen);
    if (!newOpen) {
      setSelectedFilters(globalSelectedFilters);
      setSearchObject({});
      setSearchFilterObject({});
      setPanelActiveKey(null);
    }
  };

  // resets all the filters applied
  const resetFilters = () => {
    setSelectedFilters({});
    setIsFilterActive(false);
    setGlobalSelectedFilters({});
    setPopperVisible(false);
    setPanelActiveKey(null);
  };

  // structured required payload for the API
  const iiAdsFilterVariables = Object.entries(selectedFilters).reduce((acc, [key, value]) => {
    acc[key] = Object.keys(value).filter((nestedKey) => value[nestedKey]);
    return acc;
  }, {});

  // applies the filter & refetches the API with new variables
  const applyFilterHandler = useCallback(() => {
    setGlobalSelectedFilters(
      Object.groupBy(selectedFiltersArray, ({ panelCategory }) => panelCategory)
    );
    setIsFilterActive(true);
    setPopperVisible(false);
    setSearchObject({});
    setSearchFilterObject({});
    setPanelActiveKey(undefined);
    setInfiniteScrollObject((state) => {
      return {
        ...state,
        page: null,
      };
    });
  }, [iiAdsFilterVariables, selectedFilters]);

  // title component of the popover
  const popoverTitle = () => {
    return (
      <div className="d-flex filter-popover-title">
        <div className="d-flex"> Filter </div>
        <div className="d-flex">
          <ActionIconRender
            iconType="close"
            onClick={() => {
              setPopperVisible(false);
            }}
          />
        </div>
      </div>
    );
  };

  // search icon in search input
  const searchIconPrefix = () => {
    return (
      <CustomeIconWrapper>
        <SearchOutlined />
      </CustomeIconWrapper>
    );
  };

  // haeder of each category's panel that also contains the filter count for each category
  const panelHeader = (item) => {
    return (
      <div>
        <div className="ii-panel-header">
          {item.headerLabel}
          {item.filterCount > 0 && <div className="panel-selected-count">{item.filterCount} X</div>}
        </div>
      </div>
    );
  };
  const keyDownHandler = (e) => {
    if (e.code === "Enter") {
      setSearchObject({ ...searchFilterObject, firstSearchPage: true });
      setInfiniteScrollObject((state) => {
        return {
          ...state,
          page: 1,
        };
      });
    }
  };

  const clearSearch = (clearObj = {}) => {
    setSearchObject({ ...clearObj, firstSearchPage: true });
    setInfiniteScrollObject((state) => {
      return {
        ...state,
        page: 1,
      };
    });
  };

  // all the content inside the popover
  const popoverContent = () => {
    return (
      <div
        className="mostly-customized-scrollbar"
        style={{ maxHeight: "400px", overflowY: "scroll" }}
      >
        <Collapse
          accordion
          className="collapse-wrapper"
          expandIconPosition="end"
          onChange={(e) => {
            setSearchFilterObject(null);
            setInfiniteScrollObject((state) => {
              return {
                ...state,
                page: 0,
              };
            });
            setPanelActiveKey(e);
            setSearchObject({});
          }}
          activeKey={panelActiveKey}
        >
          {filterObjects.map((item) => {
            return (
              <Panel
                header={panelHeader(item)}
                key={item.id}
                className="collapsable-item-wrapper"
                destroyInactivePanel
              >
                <Input
                  placeholder="search"
                  name={`${item.headerLabel}Search`}
                  className="filter-category-search-wrapper"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      clearSearch({
                        searchValue: e.target.value,
                        category: item.headerLabel,
                      });
                    }
                    setSearchFilterObject({
                      searchValue: e.target.value,
                      category: item.headerLabel,
                    });
                  }}
                  onKeyDown={keyDownHandler}
                  prefix={searchIconPrefix()}
                  value={searchFilterObject?.searchValue ?? ""}
                />
                <div className="mostly-customized-scrollbar">
                  <CategoryScroll
                    item={item}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    setInfiniteScrollObject={setInfiniteScrollObject}
                    setSearchObject={setSearchObject}
                    setSelectedFiltersArray={setSelectedFiltersArray}
                  />
                  {item.headerLabel !== LABELS.COUNTRIES && loader && (
                    <Row>
                      <Spin size="small" style={{ margin: "auto" }} />
                    </Row>
                  )}
                </div>
              </Panel>
            );
          })}
        </Collapse>
        <div className="filter-button-group">
          <LookButton onClick={resetFilters}>Reset</LookButton>
          <LookButton type="primary" onClick={applyFilterHandler}>
            Apply
          </LookButton>
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex align-items-center">
      <Popover
        placement="bottom"
        overlayClassName="popstyle popover-wrapper"
        title={popoverTitle()}
        content={popoverContent()}
        trigger="click"
        open={popperVisible}
        onOpenChange={handleFilterOpenChange}
      >
        <ActionIconRender
          iconType="filter"
          onClick={() => setPopperVisible((state) => !state)}
          className={isFilterApplied() ? "text-primary" : ""}
        />
      </Popover>
    </div>
  );
};

export default FilterPopup;
