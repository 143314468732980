import { useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { Menu, Layout, Collapse } from "antd";
import useTheme from "shared/Theme/useTheme";
import cx from "classnames";
import usePermissionCheck from "hooks/usePermissionCheck";

const { Sider } = Layout;
const { Panel } = Collapse;

function CollapseWraper({ title = "", icon: Icon, children }) {
  const renderTitle = (
    <span className="flex-grow-1 title">
      {Icon ? (
        <>
          <Icon />
          {title}
        </>
      ) : (
        title
      )}
    </span>
  );

  return (
    <Collapse
    //  defaultActiveKey={["1"]}
    >
      <Panel header={renderTitle} key="1">
        {children}
      </Panel>
    </Collapse>
  );
}

function RenderMenu(props) {
  const location = useLocation();
  const { havePlatformPermissionCheck } = usePermissionCheck();
  const {
    link,
    subMenuName,
    subMenuLink,
    subMenuIcon: Icon,
    hidden = false,
    isCollapsible = false,
    subMenus = [],
  } = props;

  const NavType = subMenuLink === "/" ? Link : NavLink;

  if (hidden) {
    return null;
  }
  if (isCollapsible) {
    const visibleTabs = subMenus?.filter(({ permission: routePermission }) => {
      return havePlatformPermissionCheck(routePermission);
    });

    if (visibleTabs?.length === 0) {
      return null;
    }
    return (
      <li className="ant-menu-item">
        <CollapseWraper title={subMenuName} icon={Icon}>
          {visibleTabs.map((data, index) => {
            return <RenderMenu key={"subMenus" + index} link={link} {...data} />;
          })}
        </CollapseWraper>
      </li>
    );
  }
  return (
    <li className="ant-menu-item">
      <NavType
        to={`${link}${subMenuLink}`}
        className={cx({
          active:
            (subMenuLink === "/" && location.pathname === "/dashboard") ||
            (subMenuLink === "/" && location.pathname === "/dashboard/"),
        })}
      >
        <Icon />
        {subMenuName}
      </NavType>
    </li>
  );
}

function ChildSidebar(props) {
  const themeLight = useTheme();

  const {
    isChildSidebar = false,
    mainLink,
    subMenus = [],
    title = "",
    icon: Icon,
    childSidebarSize = 200,
  } = props;
  useEffect(() => document.body.classList.add("isChildSideBar"), []);

  const { havePlatformPermissionCheck } = usePermissionCheck();

  const visibleTabs = subMenus?.filter(({ permission: routePermission }) => {
    return havePlatformPermissionCheck(routePermission);
  });

  return (
    <Sider
      width={childSidebarSize}
      id="child-menu"
      className={cx("child-sidebar")}
      collapsible
      collapsed={isChildSidebar}
    >
      <Menu className={cx("sidebar-menu position-relative", { "dark-sider": !themeLight })}>
        <Menu.Item
          key="1"
          className="title-menu side-menu-item flex space-between disabled-menu-item-clr"
          disabled
        >
          <div>{title}</div>
          <div>{Icon && <Icon style={{ margin: "0px" }} />}</div>
        </Menu.Item>
        {visibleTabs.map((data, index) => {
          return <RenderMenu key={"subMenus" + index} link={mainLink} {...data} />;
        })}
      </Menu>
      <div
        className="position-absolute w-100 project-info"
        style={{ bottom: 0, left: 0, lineHeight: "initial" }}
      >
        <div className="p-8 content">
          <p className="mb-4">{process.env.REACT_APP_NAME}</p>
          <p className="mb-0" style={{ fontSize: 12 }}>
            App Version: <span style={{ fontSize: ".7em" }}>{process.env.REACT_APP_VERSION}</span>
          </p>
        </div>
      </div>
    </Sider>
  );
}

export default ChildSidebar;
