import React, { useEffect, useState } from "react";
import useCustomSwitch from "hooks/useCustomSwitch";
import {
  GET_USERS_ALL_NOTIFICATIONS,
  MARK_USER_NOTIFICATION_SEEN,
} from "shared/gql/gqlSchema/notificationGql";
import { useCustomQuery, useCustomMutation, useCustomLazyQuery } from "hooks/apolloClientHooks";
import { Skeleton } from "antd";
import { RenderNotificationList } from "./RenderNotificationList";

const NotificationWrapper = (props) => {
  const { subs_data = {}, setNotificationCount = () => {} } = props;
  const { LookSwitch, checked } = useCustomSwitch(false, "", "");
  const [allNotifications, setAllNotifications] = useState([]);
  const { loading: isLoadingNotifications } = useCustomQuery(GET_USERS_ALL_NOTIFICATIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      page: 1,
      size: 1000,
    },
    onCompleted: (e) => {
      let listing = e?.RE_getNotificationList?.data?.result;
      if (listing?.length) {
        listing = listing?.map((it) => ({
          ...it,
          payload: JSON.parse(it?.payload),
        }));
        let finalres;
        if (subs_data?.notification) {
          finalres = [
            {
              id: subs_data?.notification?.id,
              payload: { message: JSON.parse(subs_data?.notification?.payload)?.message },
              service_name: subs_data?.notification?.service_name,
              seenStatus: false,
            },
            ...listing,
          ];
        } else {
          finalres = listing;
        }

        setAllNotifications(finalres);
      } else {
        if (subs_data?.notification) {
          setAllNotifications([
            {
              id: subs_data?.notification?.id,
              payload: { message: subs_data?.notification?.payload?.message },
              service_name: subs_data?.notification?.service_name,
              seenStatus: false,
            },
          ]);
        } else setAllNotifications([]);
      }
    },
  });

  const [markNotifySeen] = useCustomMutation(MARK_USER_NOTIFICATION_SEEN);

  useEffect(() => {
    if (subs_data?.notification && !isLoadingNotifications) {
      setAllNotifications((prev) => [
        {
          id: subs_data?.notification?.id,
          payload: { message: subs_data?.notification?.payload?.message },
          service_name: subs_data?.notification?.service_name,
          seenStatus: false,
        },
        ...prev,
      ]);
    }
    return () => {
      setNotificationCount(false);
    };
  }, [subs_data]);

  const RenderLoadingSkeleton = () => {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  };

  const onMarkAsReadHandler = (notifyId) => {
    markNotifySeen({
      variables: {
        notificationId: [notifyId],
      },
      onCompleted: () => {
        const obj = allNotifications?.map((it) => {
          if ("" + notifyId === "" + it?.id) {
            return { ...it, seen: true };
          } else return it;
        });
        setAllNotifications(obj);
      },
    });
  };

  const createActionableLink = (payload) => {
    if (payload?.commentUrl) {
      return payload?.commentUrl;
    }
    return null;
  };

  return (
    <>
      {/* TODO: This need to be done later because it is not feasible in this sprint
      <Row guttter={20} className="justify-content-between">
        <Col span={12}>
          <SelectInput selectClassName="mb-10 w-100" placeholder="All Notifications" disabled />
        </Col>

        <Col span={10}>
          <div className="d-flex align-items-center">
            <ActionIconRender className="mr-6" iconType="setting" />
            <Button block className="cancel-btn" disabled>
              Clear all
            </Button>
          </div>
        </Col>
      </Row> */}

      {/* TODO: This need to be done later because it is not feasible in this sprint
      <div className="d-flex align-items-center justify-content-between mb-16  pt-16 boderTop">
        <Segmented className="custom-segmented" options={["All", "@Mentions"]} />
        <div className="d-flex align-items-start">
          <p className="mr-4">{checked ? "Read" : "Unread"}</p>
          <LookSwitch checked={checked} />
        </div>
      </div> */}
      <div className="notifications-scrollbar">
        <div className="notifications-wrapper">
          {isLoadingNotifications && <RenderLoadingSkeleton />}
          {!isLoadingNotifications && (
            <RenderNotificationList
              allNotifications={allNotifications}
              createActionableLink={createActionableLink}
              onMarkAsReadHandler={onMarkAsReadHandler}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationWrapper;
