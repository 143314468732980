import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "../MainForm";
import { openNotification } from "../../../../../utils";
import { ADD_CMS_CATEGORIES } from "../../../../../shared/gql/gqlSchema/cmsGql";
import { useCustomMutation } from "hooks/apolloClientHooks";

export default function AddForm(props) {
  const { refetch = () => {}, allCategories = [], allDomains = [], onClose = () => {} } = props;
  const [image, setImage] = useState("");
  const [isError, setIsError] = useState("");
  const resetFormRef = useRef(() => {});
  const [addFBProfile, { loading }] = useCustomMutation(ADD_CMS_CATEGORIES, {
    onCompleted(e) {
      refetch();
      resetFormRef.current();
      openNotification({ type: "success", message: "Record Added Successfully" });
      onClose();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    const { parent_category_id, is_public, ...rest } = values;
    resetFormRef.current = resetForm;

    const isParent =
      parent_category_id === "no" ? {} : { parent_category_id: parseInt(parent_category_id) };
    addFBProfile({
      variables: {
        ...rest,
        ...isParent,
        is_private: !is_public,
        image,
      },
    });
    clearError();
  }

  const initialValues = {
    name: "",
    description: "",
    is_public: true,
    status: "",
    parent_category_id: "no",
  };

  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
    description: yup.string().required().label("Description"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    imageProps: {
      image,
      setImage,
    },
  };

  return (
    <>
      <MainFrom {...formProps} allCategories={allCategories} />
    </>
  );
}
