import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../shared/Account";

export function TempUserTokenFull() {
  const { getSession } = useContext(AccountContext);

  const [tUser, setUser] = useState("");

  useEffect(() => getSession().then(({ user }) => setUser(user)), []);

  return (
    <>
      <pre className="text-left">{JSON.stringify(tUser, null, 2)}</pre>
    </>
  );
}

function TempUserToken() {
  const { getSession } = useContext(AccountContext);

  const [tUser, setUser] = useState("");

  useEffect(() => getSession().then(({ user }) => setUser(user)), []);

  return (
    <>
      <p className="text-left" style={{ wordBreak: "break-all" }}>
        {`{ "Authorization": "` + tUser?.signInUserSession?.idToken?.jwtToken + `" }`}
      </p>
    </>
  );
}

export default TempUserToken;
