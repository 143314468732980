import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import queryString from "query-string";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { GENERATE_INVITATION } from "shared/gql";
import { openNotification } from "utils";

export default function Invitation(props) {
  const resetFormRef = useRef(() => {});
  const [inviteResponse, setInviteResponse] = useState(null);
  const [inviteCode, setInviteCode] = useState("");

  const parsed = queryString.parse(props.location.search);

  const [GenerateInvitation, { loading: agencyLoading }] = useCustomMutation(GENERATE_INVITATION, {
    onCompleted(e) {
      setInviteResponse(e);
      setInviteCode(e.GenerateInvitation.code);
      resetFormRef.current();
      openNotification({ type: "success", message: "Success" });
    },
    onError(e) {
      openNotification({ type: "error", message: "Agency " + e.message });
    },
  });

  function onSubmit(values, { resetForm }) {
    const {} = values;
    resetFormRef.current = resetForm;

    GenerateInvitation({ variables: { agency_id: parseInt(parsed.agencyId) } });
  }

  const initialValues = {
    // email: "",
  };

  const validationSchema = yup.object({
    // email: yup.string().email().required().label("Name"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    agencyLoading,
  };

  return (
    <>
      {inviteCode && <p>Invite Link: http://localhost:3000/accept_invitation/{inviteCode}</p>}
      <MainFrom {...formProps} />
    </>
  );
}
