import { gql } from "@apollo/client";
import { ASSET_UPDATE_RESPONSE_RESPONSE } from "./commonResponseFragements";

const REPORT_BY_AD_SET_RESPONSE = gql`
  fragment ReportByAdSet on ReportByAdSet {
    adsetId
    clicksFB
    dailyBudget
    date
    impressionsFB
    leadsFB
    lifeTimeBudget
    name
    objective
    spendFB
    status
  }
`;

const REVENUE_BY_AD_SET_RESPONSE = gql`
  fragment RevenueByAdSet on RevenueByAdSet {
    adsetId
    clicksVL
    conversionsVL
    date
    name
    revenueVL
    visitsVL
  }
`;

const REPORT_PER_AD_RESPONSE = gql`
  fragment ReportPerAd on ReportPerAd {
    adId
    clicksFB
    date
    impressionsFB
    leadsFB
    name
    objective
    spendFB
  }
`;

const REVENUE_PER_AD_RESPONSE = gql`
  fragment RevenuePerAd on RevenuePerAd {
    adId
    clicksVL
    conversionsVL
    date
    name
    revenueVL
    visitsVL
  }
`;

export const REPORT_AD_SET_BY_CAMPAIGNS = gql`
  ${REPORT_BY_AD_SET_RESPONSE}
  query reportAdSetByCampaigns(
    $campaignIds: [String]!
    $from: String!
    $timezone: String!
    $to: String!
  ) {
    reportAdSetByCampaigns(campaignIds: $campaignIds, from: $from, timezone: $timezone, to: $to) {
      ...ReportByAdSet
    }
  }
`;

export const REVENUE_AD_SET_BY_CAMPAIGNS = gql`
  ${REVENUE_BY_AD_SET_RESPONSE}
  query revenueAdSetByCampaigns(
    $campaignIds: [String]!
    $from: String!
    $timezone: String!
    $to: String!
  ) {
    revenueAdSetByCampaigns(campaignIds: $campaignIds, from: $from, timezone: $timezone, to: $to) {
      ...RevenueByAdSet
    }
  }
`;

export const RINGBA_REPORT = gql`
  query ringbaReport(
    $userId: ID
    $adAccountId: ID
    $timezone: String!
    $from: String!
    $to: String!
    $node: ReportTypeNode!
    $productId: Int
  ) {
    ringbaReport(
      userId: $userId
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      node: $node
      productId: $productId
    ) {
      id
      convertedCalls
      callCount
      payoutAmount

      avgHandleTimeSeconds
      blockedCalls
      callLengthSeconds
      connectedCallCount
      duplicateCalls
      endedCalls
      nonConnectedCallCount
      ringbaName: name
    }
  }
`;

export const GET_REPORT_BY_AD_SET = gql`
  ${REPORT_BY_AD_SET_RESPONSE}
  query reportByAdSet(
    $adAccountId: ID
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $productId: Int
    $vendorId: ID
  ) {
    reportByAdSet(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      vendorId: $vendorId
      productId: $productId
    ) {
      ...ReportByAdSet
    }
  }
`;

export const GET_REVENUE_BY_AD_SET = gql`
  ${REVENUE_BY_AD_SET_RESPONSE}
  query revenueByAdSet(
    $adAccountId: ID
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenueByAdSet(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...RevenueByAdSet
    }
  }
`;

export const GET_REPORT_PER_AD_SET_ID = gql`
  ${REPORT_BY_AD_SET_RESPONSE}
  query reportPerAdSetId(
    $adSetId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdSetId(
      adSetId: $adSetId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportByAdSet
    }
  }
`;

export const GET_REPORT_PER_AD_SET_ID_HOURLY = gql`
  ${REPORT_BY_AD_SET_RESPONSE}
  query reportPerAdSetIdHourly(
    $adSetId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdSetIdHourly(
      adSetId: $adSetId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportByAdSet
    }
  }
`;

export const GET_REVENUE_PER_AD_SET_ID = gql`
  ${REVENUE_BY_AD_SET_RESPONSE}
  query revenuePerAdSetId(
    $adSetId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenuePerAdSetId(
      adSetId: $adSetId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...RevenueByAdSet
    }
  }
`;

export const GET_REPORT_AD_ID = gql`
  ${REPORT_PER_AD_RESPONSE}
  query reportPerAdId(
    $adId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdId(adId: $adId, from: $from, timezone: $timezone, to: $to, userId: $userId) {
      ...ReportPerAd
    }
  }
`;

export const GET_REPORT_AD_ID_HOURLY = gql`
  ${REPORT_PER_AD_RESPONSE}
  query reportPerAdIdHourly(
    $adId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdIdHourly(adId: $adId, from: $from, timezone: $timezone, to: $to, userId: $userId) {
      ...ReportPerAd
    }
  }
`;

export const GET_REVENUE_AD_ID = gql`
  ${REVENUE_PER_AD_RESPONSE}
  query revenuePerAdId(
    $adId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenuePerAdId(adId: $adId, from: $from, timezone: $timezone, to: $to, userId: $userId) {
      ...RevenuePerAd
    }
  }
`;

export const GET_LEAD_GEN_PLATFORMS = gql`
  query BucketPlatforms {
    bucketPlatforms {
      name
      nickname
    }
  }
`;

const REPORT_BY_AD_RESPONSE = gql`
  fragment ReportByAd on ReportByAd {
    ad {
      adId
      name
      status
    }
    clicksFB
    impressionsFB
    objective
    spendFB
    leadsFB
  }
`;

export const GET_REPORT_BY_AD = gql`
  ${REPORT_BY_AD_RESPONSE}
  query reportByAd(
    $adAccountId: ID
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $productId: Int
    $vendorId: ID
  ) {
    reportByAd(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      productId: $productId
      vendorId: $vendorId
    ) {
      ...ReportByAd
    }
  }
`;

const REVENUE_BY_AD_RESPONSE = gql`
  fragment RevenueByAd on RevenueByAd {
    ad {
      adId
      name
      status
    }
    clicksVL
    conversionsVL
    revenueVL
    visitsVL
  }
`;

export const GET_REVENUE_BY_AD = gql`
  ${REVENUE_BY_AD_RESPONSE}
  query revenueByAd(
    $adAccountId: ID
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenueByAd(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...RevenueByAd
    }
  }
`;

export const GET_REPORT_AD_BY_CAMPAIGNS = gql`
  ${REPORT_BY_AD_RESPONSE}
  query reportAdByCampaigns(
    $campaignIds: [String]!
    $from: String!
    $timezone: String!
    $to: String!
  ) {
    reportAdByCampaigns(campaignIds: $campaignIds, from: $from, timezone: $timezone, to: $to) {
      ...ReportByAd
    }
  }
`;

export const GET_REPORT_AD_BY_ADSET = gql`
  ${REPORT_BY_AD_RESPONSE}
  query reportAdByAdSets($adSetIds: [String]!, $from: String!, $timezone: String!, $to: String!) {
    reportAdByAdSets(adSetIds: $adSetIds, from: $from, timezone: $timezone, to: $to) {
      ...ReportByAd
    }
  }
`;

export const GET_REVENUE_AD_BY_CAMPAIGNS = gql`
  ${REVENUE_BY_AD_RESPONSE}
  query revenueAdByCampaigns(
    $campaignIds: [String]!
    $from: String!
    $timezone: String!
    $to: String!
  ) {
    revenueAdByCampaigns(campaignIds: $campaignIds, from: $from, timezone: $timezone, to: $to) {
      ...RevenueByAd
    }
  }
`;

export const UPDATE_CAMPAIGN_BUDGET = gql`
  mutation updateCampaignBudget($budget: Int!, $campaignFBId: String!, $isLifeTime: Boolean!) {
    updateCampaignBudget(budget: $budget, campaignFBId: $campaignFBId, isLifeTime: $isLifeTime) {
      message
      success
    }
  }
`;

export const UPDATE_AD_SET_BUDGET = gql`
  mutation updateAdSetBudget($adSetFBId: String!, $budget: Int!, $isLifeTime: Boolean!) {
    updateAdSetBudget(adSetFBId: $adSetFBId, budget: $budget, isLifeTime: $isLifeTime) {
      message
      success
    }
  }
`;

export const DUPLICATE_ASSET = gql`
  mutation duplicateAsset($assetType: AssetType!, $fbId: String!) {
    duplicateAsset(assetType: $assetType, fbId: $fbId) {
      message
      success
      title
    }
  }
`;

export const DUPLICATE_CAMPS = gql`
  mutation copyCampaignsAdsetsAds(
    $cloneData: CloneDataInput!
    $multiAccountClone: Boolean
    $multiAccountData: [MultiAccountCloneInput]
  ) {
    copyCampaignsAdsetsAds(
      cloneData: $cloneData
      multiAccountClone: $multiAccountClone
      multiAccountData: $multiAccountData
    )
  }
`;

const USER_REPORT_RESPONSE = gql`
  fragment ReportByUser on ReportByUser {
    impressionsFB
    spendFB
    clicksFB
    leadsFB
    user {
      name
    }
    name
    userId
    date
  }
`;

const CATEGORY_REVENUE_REPSONSE = gql`
  fragment RevenueByCategory on RevenueByCategory {
    revenueVL
    visitsVL
    id
    clicksVL
    conversionsVL
    xRevenue
    name
  }
`;

const CATEGORY_REPORT_RESPONSE = gql`
  fragment ReportByCategory on ReportByCategory {
    clicksFB
    impressionsFB
    leadsFB
    spendFB
    id
    name
  }
`;
export const GET_REVENUE_BY_CATEGORY = gql`
  ${CATEGORY_REVENUE_REPSONSE}
  query revenueByCategory(
    $categoryId: ID
    $from: String!
    $productId: ID!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenueByCategory(
      categoryId: $categoryId
      from: $from
      productId: $productId
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...RevenueByCategory
    }
  }
`;
export const IS_CATEGORY_ASSIGNED = gql`
  ${CATEGORY_REVENUE_REPSONSE}
  query isCategoryAssigned {
    isCategoryAssigned
  }
`;

export const GET_REPORT_BY_USER = gql`
  ${USER_REPORT_RESPONSE}
  query reportByUser(
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $isLFBCAccount: Boolean
  ) {
    reportByUser(
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      isLFBCAccount: $isLFBCAccount
    ) {
      ...ReportByUser
    }
  }
`;

export const GET_REPORT_BY_CATEGORY = gql`
  ${CATEGORY_REPORT_RESPONSE}
  query reportByCategory(
    $categoryId: ID
    $from: String!
    $productId: ID!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportByCategory(
      categoryId: $categoryId
      from: $from
      productId: $productId
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportByCategory
    }
  }
`;

export const GET_REPORT_BY_USER_DASHBOARD = gql`
  ${USER_REPORT_RESPONSE}
  query reportByUser($from: String!, $timezone: String!, $to: String!, $userId: ID) {
    reportByUser(from: $from, timezone: $timezone, to: $to, userId: $userId) {
      ...ReportByUser
    }
  }
`;

export const GET_REPORT_PER_USER = gql`
  query reportPerUser($from: String!, $timezone: String!, $to: String!, $userId: ID!) {
    reportPerUser(from: $from, timezone: $timezone, to: $to, userId: $userId) {
      impressionsFB
      spendFB
      clicksFB
      leadsFB
      name
      userId
      date
    }
  }
`;

const USER_REVENUE_RESPONSE = gql`
  fragment RevenueByUser on RevenueByUser {
    revenueVL
    visitsVL
    clicksVL
    conversionsVL
    # user {
    #   name
    # }
    userId
    xRevenue
    date
  }
`;

export const GET_REVENUE_PER_USER = gql`
  ${USER_REVENUE_RESPONSE}
  query revenuePerUser($from: String!, $timezone: String!, $to: String!, $userId: ID!) {
    revenuePerUser(from: $from, timezone: $timezone, to: $to, userId: $userId) {
      ...RevenueByUser
    }
  }
`;

export const GET_REVENUE_BY_USER = gql`
  ${USER_REVENUE_RESPONSE}
  query revenueByUser(
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $isLFBCAccount: Boolean
  ) {
    revenueByUser(
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      isLFBCAccount: $isLFBCAccount
    ) {
      ...RevenueByUser
    }
  }
`;

export const GET_REVENUE_BY_USER_DASHBOARD = gql`
  ${USER_REVENUE_RESPONSE}
  query revenueByUser(
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $isLFBCAccount: Boolean
  ) {
    revenueByUser(
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      isLFBCAccount: $isLFBCAccount
    ) {
      ...RevenueByUser
    }
  }
`;

const REPORT_BY_AD_ACCOUNT_RESPONSE = gql`
  fragment ReportByAdAccount on ReportByAdAccount {
    impressionsFB
    clicksFB
    spendFB
    leadsFB
    adAccount {
      name
      fbAdAccountId
      timeZone
      currency
      f360AdAccountId
      aliasSub3
    }
  }
`;

export const GET_REPORT_BY_AD_ACCOUNT = gql`
  ${REPORT_BY_AD_ACCOUNT_RESPONSE}
  query reportByAdAccount(
    $userId: ID
    $from: String!
    $timezone: String!
    $to: String!
    $vendorId: ID
    $productId: Int
  ) {
    reportByAdAccount(
      userId: $userId
      from: $from
      to: $to
      timezone: $timezone
      vendorId: $vendorId
      productId: $productId
    ) {
      ...ReportByAdAccount
    }
  }
`;

const REPORT_PER_AD_ACCOUNT_RESPONSE = gql`
  fragment ReportPerAdAccount on ReportPerAdAccount {
    impressionsFB
    clicksFB
    spendFB
    leadsFB
    adAccountId
    date
  }
`;

export const GET_REPORT_PER_AD_ACCOUNT = gql`
  ${REPORT_PER_AD_ACCOUNT_RESPONSE}
  query reportPerAdAccount(
    $adAccountId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdAccount(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportPerAdAccount
    }
  }
`;

const REVENUE_BY_AD_ACCOUNT_RESPONSE = gql`
  fragment RevenueByAdAccount on RevenueByAdAccount {
    adAccount {
      name
      fbAdAccountId
      timeZone
      currency
      f360AdAccountId
      aliasSub3
    }
    clicksVL
    conversionsVL
    revenueVL
    visitsVL
  }
`;

export const GET_REVENUE_BY_AD_ACCOUNT = gql`
  ${REVENUE_BY_AD_ACCOUNT_RESPONSE}
  query revenueByAdAccount($from: String!, $timezone: String!, $to: String!, $userId: ID) {
    revenueByAdAccount(from: $from, to: $to, timezone: $timezone, userId: $userId) {
      ...RevenueByAdAccount
    }
  }
`;

export const SYNC_LAST_3Hr_INSIGHTS = gql`
  mutation syncLast3HrInsights($fbAdAccountIds: [String]!) {
    syncLast3HrInsights(fbAdAccountIds: $fbAdAccountIds)
  }
`;

const REVENUE_PER_AD_ACCOUNT_RESPONSE = gql`
  fragment RevenuePerAdAccount on RevenuePerAdAccount {
    adAccountId
    clicksVL
    conversionsVL
    revenueVL
    visitsVL
    date
  }
`;

export const GET_REVENUE_PER_AD_ACCOUNT = gql`
  ${REVENUE_PER_AD_ACCOUNT_RESPONSE}
  query revenuePerAdAccount(
    $adAccountId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenuePerAdAccount(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...RevenuePerAdAccount
    }
  }
`;

export const ADD_X_REVENUE = gql`
  mutation addXRevenue($categoryId: ID!, $date: String!, $notes: String, $xRevenue: Float!) {
    addXRevenue(categoryId: $categoryId, date: $date, notes: $notes, xRevenue: $xRevenue)
  }
`;

const REPORT_BY_CAMPAIGN_RESPONSE = gql`
  fragment ReportByCampaign on ReportByCampaign {
    campaignId
    clicksFB
    impressionsFB
    leadsFB
    name
    objective
    spendFB
    status
    dailyBudget
    lifeTimeBudget
    date
  }
`;

export const GET_REPORT_BY_CAMPAIGN = gql`
  ${REPORT_BY_CAMPAIGN_RESPONSE}
  query reportByCampaign(
    $adAccountId: ID
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $productId: Int
    $vendorId: ID
  ) {
    reportByCampaign(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      productId: $productId
      vendorId: $vendorId
    ) {
      ...ReportByCampaign
    }
  }
`;

export const GET_REPORT_PER_CAMPAIGN_ID = gql`
  ${REPORT_BY_CAMPAIGN_RESPONSE}
  query reportPerCampaignId(
    $campaignId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerCampaignId(
      campaignId: $campaignId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportByCampaign
    }
  }
`;

export const GET_REPORT_PER_CAMPAIGN_ID_HOURLY = gql`
  ${REPORT_BY_CAMPAIGN_RESPONSE}
  query reportPerCampaignIdHourly(
    $campaignId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerCampaignIdHourly(
      campaignId: $campaignId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportByCampaign
    }
  }
`;

const REVENUE_BY_CAMPAIGN_RESPONSE = gql`
  fragment RevenueByCampaign on RevenueByCampaign {
    campaignId
    clicksVL
    conversionsVL
    name
    revenueVL
    visitsVL
    date
  }
`;

export const GET_REVENUE_BY_CAMPAIGN = gql`
  ${REVENUE_BY_CAMPAIGN_RESPONSE}
  query revenueByCampaign(
    $adAccountId: ID
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenueByCampaign(
      adAccountId: $adAccountId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...RevenueByCampaign
    }
  }
`;

export const GET_REPORT_REVENUE_PER_CAMPAIGN_ID = gql`
  ${REVENUE_BY_CAMPAIGN_RESPONSE}
  query revenuePerCampaignId(
    $campaignId: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    revenuePerCampaignId(
      campaignId: $campaignId
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...RevenueByCampaign
    }
  }
`;

export const UPDATE_CAMPAIGN_STATUS = gql`
  ${ASSET_UPDATE_RESPONSE_RESPONSE}
  mutation updateCampaignStatus($campaignFBId: String!, $status: String!) {
    updateCampaignStatus(campaignFBId: $campaignFBId, status: $status) {
      ...AssetUpdateResponse
    }
  }
`;

const CMS_X_REVENUE_RESPONSE = gql`
  fragment XRevenue on XRevenue {
    xRevenue
    forDate
    createdOn
    updatedOn
    notes
    category {
      id
      name
    }
  }
`;

export const GET_X_REVENUE = gql`
  ${CMS_X_REVENUE_RESPONSE}
  query getXRevenue($from: String!, $to: String!) {
    getXRevenue(from: $from, to: $to) {
      ...XRevenue
    }
  }
`;

// backHour: Int
// createdOn: String
// id: ID
// isActive: Boolean
// minSpend: Float
// stopLoss: Float
// trackerCategory: TrackerCategory
// updatedOn: String

const STOP_LOSS_RESPONSE = gql`
  fragment StopLoss on StopLoss {
    backHour
    createdOn
    id
    isActive
    minSpend
    stopLoss
    trackerCategory {
      id
      name
    }
    updatedOn
  }
`;

export const GET_STOP_LOSS = gql`
  ${STOP_LOSS_RESPONSE}
  query CategoryStopLoss {
    CategoryStopLoss {
      ...StopLoss
    }
  }
`;

// type StopLossInput {
//   backHour: Int!
//   id: ID
//   isActive: Boolean!
//   minSpend: Float!
//   stopLoss: Float!
//   tCategoryId: ID!
// }

export const ADD_UPDATE_STOP_LOSS = gql`
  ${STOP_LOSS_RESPONSE}
  mutation saveUpdateCStopLoss($input: StopLossInput) {
    saveUpdateCStopLoss(input: $input) {
      ...StopLoss
    }
  }
`;

export const DELETE_STOP_LOSS = gql`
  mutation deleteCStopLoss($id: ID!) {
    deleteCStopLoss(id: $id)
  }
`;

const COMPLIANT_CREATIVE_RESPONSE = gql`
  fragment CompliantCreative on CompliantCreative {
    id
    adTitle {
      id
      title
      complianceStatus
    }
    adBody {
      id
      body
      complianceStatus
    }
    creativeImage {
      id
      imageUrl
      complianceStatus
    }
  }
`;

export const NON_REVIEWED_CREATIVES = gql`
  ${COMPLIANT_CREATIVE_RESPONSE}
  query nonReviewedCreatives($page: Int!, $size: Int!) {
    nonReviewedCreatives(page: $page, size: $size) {
      hasNext
      data {
        ...CompliantCreative
      }
    }
  }
`;

export const UPDATE_COMPLIANT_STATUS = gql`
  mutation updateCompliantStatus(
    $assetType: CreativeAssetType!
    $complianceStatus: ComplianceStatus!
    $creativeAssetId: ID!
  ) {
    updateCompliantStatus(
      assetType: $assetType
      complianceStatus: $complianceStatus
      creativeAssetId: $creativeAssetId
    ) {
      Id
      complianceStatus
      message
      success
    }
  }
`;
export const RE_SYNC_CATEGORY_DATA = gql`
  mutation reSyncCategoryData {
    reSyncCategoryData
  }
`;
export const GQL_ADD_LANDING_URL = gql`
  mutation addLandingURL($url: String!, $categoryId: ID!, $fbAccountId: String!) {
    addLandingURL(url: $url, categoryId: $categoryId, fbAccountId: $fbAccountId)
  }
`;

export const GET_CAMPAIGN_DETAILS = gql`
  query CampaignDetails($adsetId: ID!, $profileId: ID) {
    campaignDetails(adsetId: $adsetId, profileId: $profileId) {
      campaignId
      campaignName
    }
  }
`;

export const GQL_VOLUUME_IS_CATEGORIES_ASSIGNED = gql`
  query voluum_is_categories_assigned($platform_id: ID) {
    voluum_is_categories_assigned(platform_id: $platform_id)
  }
`;
export const GQL_LEADGEN_BULK_UPDATE_BID_AMOUNT = gql`
  mutation Fb360_bulkUpdateBidAmount($asset: FB360_ASSET, $updateArray: [FB360_updateBudgetinput]) {
    fb360_bulkUpdateBidAmount(asset: $asset, updateArray: $updateArray)
  }
`;

export const GQL_LEADGEN_AD_ACCCOUNT_MONTHLY_REPORT = gql`
  query Fb360_adaccountMonthlyReport($end: Date, $start: Date, $adaccount_id: [ID]) {
    fb360_adaccountMonthlyReport(end: $end, start: $start, adaccount_id: $adaccount_id) {
      spend
      last_month_spend
    }
  }
`;

export const GQL_LEADGEN_AD_ACCCOUNT_DAILY_REPORT = gql`
  query Fb360_adaccountDailyReport($end: Date, $start: Date, $adaccount_id: [ID]) {
    fb360_adaccountDailyReport(end: $end, start: $start, adaccount_id: $adaccount_id) {
      spend
      last_month_spend
    }
  }
`;

export const GQL_LEADGEN_AD_ACCCOUNT_BULK_SPENDCAP_UPDATE = gql`
  mutation Fb360_bulkUpdateSpendCap(
    $asset: FB360_ASSET
    $isRule: String
    $updateArray: [FB360_updateBudgetinput]
  ) {
    fb360_bulkUpdateSpendCap(asset: $asset, is_rule: $isRule, updateArray: $updateArray)
  }
`;
