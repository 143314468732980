import { Form, Row, Col, Button, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import { Fragment, useRef, useState } from "react";
import { openNotification } from "utils";
import DebugPre from "components/DebugPre";
import { useSelector } from "react-redux";
import LookButton from "components/LookButton";

function ClickToCopy({ copyText = "", tooltipText = "", successText = "" }) {
  const copyRef = useRef();
  const copyToClipboard = (e) => {
    copyRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    openNotification({
      type: "success",
      message: <b>Copied</b>,
      description: copyText,
      key: "vs-url-copy",
    });
  };
  const ToolTipWrap = tooltipText ? Tooltip : Fragment;
  const toolTipWrapProps = tooltipText ? { title: tooltipText } : {};
  /**
   * As Asked By Rahul Gupta for Pinterest Url
   */

  return (
    <>
      <div className="w-0 h-0 position-absolute opacity-0">
        <textarea ref={copyRef} value={copyText} />
      </div>
      {document.queryCommandSupported("copy") && (
        <ToolTipWrap {...toolTipWrapProps}>
          <LookButton
            tooltipTitle="Click to copy"
            onClick={copyToClipboard}
            className="border-0 copy-btn p-0 bg-transparent"
          >
            <CopyOutlined />
          </LookButton>
        </ToolTipWrap>
      )}
    </>
  );
}

function RenderCopyLink(props) {
  const { title = "", link = "", builderFields = {} } = props;
  const url = encodeURI(link);

  if (link) {
    return (
      <div className="d-flex pr-32">
        <p className="mr-16" style={{ minWidth: 100 }}>
          <b>{title}:</b>
        </p>
        <p className="mr-16">{link}</p>
        <div>
          <ClickToCopy copyText={builderFields?.userPlatform === "8" ? url : link} />
        </div>
      </div>
    );
  }
  return null;
}

export default function VsContentLinkBuilder(props) {
  const { advertiser, brand, slug, nickname, language_code, platFormUrl } = props;
  const { userSettingData } = useSelector((state) => state?.userSettings) || {};

  const capmaingName = "{user}-{country}-{article}-{adacc}-{campaignid}";
  const adSetName = "{user}-{country}-{article}-{adacc}-{campaignid}-{adsetid}";

  const [finalLink, setFinalLink] = useState("");
  const [capmaingNameLink, setCapmaingNameLink] = useState("");
  const [adSetNameLink, setAdSetName] = useState("");

  const {
    vsAdvertiser = [],
    vsAdvertiser_loading = false,
    vsLoginUser,
    vsLoginUser_loading = false,
    vsUserSettings = {},
    vsUserSettings_loding = false,
  } = userSettingData || {};

  const {
    // vsBrands = [],
    vsCountry = [],
    vsOperatingSystem = [],
    vsDevice = [],
  } = vsUserSettings || {};

  let advertiserPlatform, advertiserPlatformUrlStructure, advertiserBrand;

  if (advertiser) {
    const selectedAdvertiserIndex = vsAdvertiser.findIndex(({ id }) => id === advertiser.id);
    const selectedAdvertiser = vsAdvertiser[selectedAdvertiserIndex];
    advertiserPlatform = selectedAdvertiser?.platform_url;
    advertiserPlatformUrlStructure = advertiserPlatform?.url_structure;
    advertiserBrand = vsLoginUser?.brands?.filter(
      (data) => selectedAdvertiser?.brands?.filter((e) => data.id === e.id).length > 0
    );
  }

  const finalBrand = advertiser
    ? advertiserBrand?.filter(
        (data) => vsLoginUser?.brands?.filter((e) => data.id === e.id).length > 0
      )
    : vsLoginUser?.brands;

  let brandPlatforms = [];

  const [builderFields, setBuilderFields] = useState({
    brand: brand ? brand.id : finalBrand && finalBrand[0] ? finalBrand[0].id : null,
    country: "US",
    os: "",
    device: "mob",
    adacc: "",
    campaignid: "",
    adsetid: "",
    platforms: "",
    userPlatform: vsLoginUser?.platforms[0]?.id || "",
    epcKey: "",
  });
  const advistiserUrlFilter = platFormUrl
    ? platFormUrl?.filter((data) => data?.platform?.id === builderFields?.userPlatform)[0] || {}
    : {};
  function handleBuilderFields(name, value) {
    setBuilderFields((prev) => ({ ...prev, [name]: value }));
  }

  if (brand) {
    const selectedBrandIndex = finalBrand.findIndex(({ id }) => id === builderFields.brand);
    const selectedBrand = finalBrand[selectedBrandIndex];
    brandPlatforms = selectedBrand?.platforms;
  }

  function setter() {
    if (advistiserUrlFilter && advistiserUrlFilter?.url_structure) {
    } else {
      return openNotification({ type: "error", message: "Url Structure not found" });
    }
    if (advertiser) {
      let update_url_structure = advistiserUrlFilter?.url_structure;
      let campaign_url_structure = capmaingName;
      let ad_set_url_structure = adSetName;

      const selectedBrand = advertiserBrand?.filter(({ id }) => id === builderFields.brand);

      const website = selectedBrand[0]?.website;
      const host = new URL(website)?.host;

      if (host) {
        update_url_structure = update_url_structure.replaceAll(`{domain}`, host);
        campaign_url_structure = campaign_url_structure.replaceAll(`{domain}`, host);
        ad_set_url_structure = ad_set_url_structure.replaceAll(`{domain}`, host);
      }

      if (slug) {
        update_url_structure = update_url_structure.replaceAll(`{slug}`, slug);
        campaign_url_structure = campaign_url_structure.replaceAll(`{slug}`, slug);
        ad_set_url_structure = ad_set_url_structure.replaceAll(`{slug}`, slug);
      }

      update_url_structure = update_url_structure.replaceAll(`{brand}`, selectedBrand[0]?.nickname);
      campaign_url_structure = campaign_url_structure.replaceAll(
        `{brand}`,
        selectedBrand[0]?.nickname
      );
      ad_set_url_structure = ad_set_url_structure.replaceAll(`{brand}`, selectedBrand[0]?.nickname);

      update_url_structure = update_url_structure.replaceAll(
        `{agency}`,
        vsLoginUser?.agency?.nickname
      );
      campaign_url_structure = campaign_url_structure.replaceAll(
        `{agency}`,
        vsLoginUser?.agency?.nickname
      );
      ad_set_url_structure = ad_set_url_structure.replaceAll(
        `{agency}`,
        vsLoginUser?.agency?.nickname
      );
      update_url_structure = update_url_structure.replaceAll(`{user}`, vsLoginUser?.nickname);
      campaign_url_structure = campaign_url_structure.replaceAll(`{user}`, vsLoginUser?.nickname);
      ad_set_url_structure = ad_set_url_structure.replaceAll(`{user}`, vsLoginUser?.nickname);
      update_url_structure = update_url_structure.replaceAll(
        `{country}`,
        builderFields?.country?.toLocaleLowerCase()
      );
      campaign_url_structure = campaign_url_structure.replaceAll(
        `{country}`,
        builderFields?.country?.toLocaleLowerCase()
      );
      ad_set_url_structure = ad_set_url_structure.replaceAll(
        `{country}`,
        builderFields?.country?.toLocaleLowerCase()
      );
      update_url_structure = update_url_structure.replaceAll(`{device}`, builderFields?.device);
      campaign_url_structure = campaign_url_structure.replaceAll(`{device}`, builderFields?.device);
      ad_set_url_structure = ad_set_url_structure.replaceAll(`{device}`, builderFields?.device);
      update_url_structure = update_url_structure.replaceAll(`{adacc}`, builderFields?.adacc);
      campaign_url_structure = campaign_url_structure.replaceAll(`{adacc}`, builderFields?.adacc);
      ad_set_url_structure = ad_set_url_structure.replaceAll(`{adacc}`, builderFields?.adacc);
      update_url_structure = update_url_structure.replaceAll(
        `{campaignid}`,
        builderFields?.campaignid
      );
      campaign_url_structure = campaign_url_structure.replaceAll(
        `{campaignid}`,
        builderFields?.campaignid
      );
      ad_set_url_structure = ad_set_url_structure.replaceAll(
        `{campaignid}`,
        builderFields?.campaignid
      );
      update_url_structure = update_url_structure.replaceAll(`{adsetid}`, builderFields?.adsetid);
      campaign_url_structure = campaign_url_structure.replaceAll(
        `{adsetid}`,
        builderFields?.adsetid
      );
      ad_set_url_structure = ad_set_url_structure.replaceAll(`{adsetid}`, builderFields?.adsetid);
      update_url_structure = update_url_structure.replaceAll(`{article}`, nickname + language_code);
      campaign_url_structure = campaign_url_structure.replaceAll(
        `{article}`,
        nickname + language_code
      );
      ad_set_url_structure = ad_set_url_structure.replaceAll(`{article}`, nickname + language_code);

      if (builderFields?.epcKey && advistiserUrlFilter?.epc_key) {
        update_url_structure =
          update_url_structure + advistiserUrlFilter?.epc_key + builderFields?.epcKey;
      }
      setFinalLink(update_url_structure);
      setCapmaingNameLink(campaign_url_structure);
      setAdSetName(ad_set_url_structure);
    }
  }

  return (
    <>
      <RenderCopyLink title="Url" link={finalLink} builderFields={builderFields} />
      <RenderCopyLink title="Campaign" link={capmaingNameLink} builderFields={builderFields} />
      <RenderCopyLink title="Ad Set" link={adSetNameLink} builderFields={builderFields} />
      <Form layout="vertical" onFinish={setter}>
        {vsLoginUser?.platforms && vsLoginUser?.platforms?.length === 0 && (
          <p className="text-danger">No platforms found</p>
        )}
        {vsLoginUser?.platforms && vsLoginUser?.platforms?.length > 1 && (
          <SelectInput
            required={!builderFields.userPlatform}
            label="PlatForm"
            name="userPlatform"
            options={vsLoginUser?.platforms?.map(({ id, name }, index) => ({
              name: name,
              value: id,
            }))}
            value={builderFields.userPlatform}
            onChange={(e) => handleBuilderFields("userPlatform", e)}
          />
        )}
        {finalBrand?.length === 0 && <p className="text-danger">No brand found</p>}
        <SelectInput
          label="Brand"
          name="brand"
          search
          options={finalBrand?.map(({ id, name }) => ({ name: name, value: id }))}
          value={builderFields?.brand}
          onChange={(e) => {
            const currentBrand = finalBrand?.filter((thisData) => thisData.id === e)[0];
            if (
              currentBrand?.platforms &&
              currentBrand?.platforms[0] &&
              currentBrand?.platforms[0]?.id
            ) {
              setBuilderFields((prev) => ({
                ...prev,
                brand: e,
                platForm: currentBrand?.platforms[0]?.id,
              }));
            } else {
              handleBuilderFields("brand", e);
            }
          }}
        />

        {brand && (
          <SelectInput
            label="PlatForm"
            name="platForm"
            options={brandPlatforms.map(({ id, name }, index) => ({ name: name, value: id }))}
            value={builderFields.platForm}
            onChange={(e) => handleBuilderFields("platForm", e)}
          />
        )}

        <Row gutter={12}>
          <Col span={8}>
            <LookTextInput
              required
              customRules={[
                {
                  max: builderFields?.userPlatform === "8" ? 4 : 3,
                  message: `Ad account must be maximum ${
                    builderFields?.userPlatform === "8" ? 4 : 3
                  } characters.`,
                },
              ]}
              label="Ad Account"
              name="adacc"
              onChange={(e) => handleBuilderFields("adacc", e.target.value)}
            />
          </Col>
          <Col span={8}>
            <LookTextInput
              required
              customRules={[{ max: 5, message: "Campaign Id must be maximum 5 characters." }]}
              label="Campaign Id"
              name="campaignid"
              onChange={(e) => handleBuilderFields("campaignid", e.target.value)}
            />
          </Col>
          <Col span={8}>
            <LookTextInput
              required
              customRules={[{ max: 4, message: "Ad Set must be maximum 4 characters." }]}
              label="Ad Set Id"
              name="adsetid"
              onChange={(e) => handleBuilderFields("adsetid", e.target.value)}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              label="Device"
              name="device"
              options={vsDevice.map(({ name, nickname }) => ({ name, value: nickname }))}
              value={builderFields.device}
              onChange={(e) => handleBuilderFields("device", e)}
            />
          </Col>
          <Col span={8}>
            <SelectInput
              search
              label="Country"
              name="country"
              options={vsCountry.map(({ name, iso }, index) => ({
                name,
                value: iso,
              }))}
              value={builderFields.country}
              onChange={(e) => handleBuilderFields("country", e)}
            />
          </Col>
          <Col span={8}>
            <LookTextInput
              label="Epc Key"
              name="epcKey"
              onChange={(e) => handleBuilderFields("epcKey", e.target.value)}
            />
          </Col>
        </Row>

        <Button className="w-100" htmlType="submit" type="primary">
          Generate
        </Button>
        <DebugPre
          content={[
            {
              isRow: true,
              contentObjects: [
                {
                  advistiserUrlFilter,
                  builderFields,
                  finalBrand,
                  vsDevice,
                  vsOperatingSystem,
                  finalLink,
                  vsLoginUser,
                  advertiser,
                  advertiserPlatformUrlStructure,
                  advertiserBrand,
                  brand,
                  brandPlatforms,
                },
              ],
            },

            { isRow: true, contentObjects: [{ vsAdvertiser }] },
          ]}
        />
      </Form>
    </>
  );
}
