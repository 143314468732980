import { FacebookOutlined, UserOutlined, BranchesOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import { FB360_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";

export const useFb360Path = () => {
  // Manual user permission for launch of SAS
  const subMenu = [
    {
      subMenuName: "Profile",
      subMenuLink: "/profile",
      subMenuIcon: UserOutlined,
      permission: "fb360_view_test",
      // permission: "vs_view_agency_reporting",
      // permission: ,
    },
    {
      subMenuName: "Ad Accounts",
      subMenuLink: "/ad-accounts",
      subMenuIcon: UserOutlined,
      permission: "fb360_view_test",
    },
    {
      subMenuName: "Bussiness Manager",
      subMenuLink: "/bms",
      subMenuIcon: BranchesOutlined,
      permission: "fb360_view_bm",
      // permission: "vs_view_agency_reporting",
    },
    // {
    //   subMenuName: "Campaigns",
    //   subMenuLink: "/campaigns",
    //   subMenuIcon: UserOutlined,
    //   // permission: "vs_view_agency_reporting",
    // },
    // {
    //   subMenuName: "Adsets",
    //   subMenuLink: "/adsets",
    //   subMenuIcon: UserOutlined,
    //   // permission: "vs_view_agency_reporting",
    // },
    // {
    //   subMenuName: "Ads",
    //   subMenuLink: "/ads",
    //   subMenuIcon: UserOutlined,
    //   // permission: "vs_view_agency_reporting",
    // },
    {
      subMenuName: "Creatives",
      subMenuLink: "/creatives",
      subMenuIcon: UserOutlined,
      permission: FB360_AGENCY_ADMIN,
    },
    {
      subMenuName: "Reports",
      subMenuIcon: UserOutlined,
      permission: "fb360_view_test",
      isCollapsible: true,
      subMenus: [
        {
          subMenuName: "Reports",
          subMenuLink: "/reports",
          subMenuIcon: UserOutlined,
          // permission: "vs_view_agency_reporting",
        },
        // {
        //   subMenuName: "Daily",
        //   subMenuLink: "/daily",
        //   subMenuIcon: UserOutlined,
        //   // permission: "vs_view_agency_reporting",
        // },
        // {
        //   subMenuName: "Hourly",
        //   subMenuLink: "/hourly",
        //   subMenuIcon: UserOutlined,
        //   // permission: "vs_view_agency_reporting",
        // },
        // {
        //   subMenuName: "Hour of the day",
        //   subMenuLink: "/hour-of-the-day",
        //   subMenuIcon: UserOutlined,
        //   // permission: "vs_view_agency_reporting",
        // },
      ],
    },
    {
      subMenuName: "BM Reports",
      subMenuLink: "/bm-reports",
      subMenuIcon: UserOutlined,
      permission: FB360_AGENCY_ADMIN,
    },
    // },
  ];

  const fb360Path = [
    {
      name: "FB 360",
      link: "/fb360",
      icon: FacebookOutlined,
      subMenu,
    },
  ];
  return { fb360Path };
};

// const fb360Path = [
//   {
//     name: "FB 360",
//     link: "/fb360",
//     icon: FacebookOutlined,
//     subMenu: [
//       // {
//       //   subMenuName: "Profile",
//       //   subMenuLink: "/profile",
//       //   subMenuIcon: UserOutlined,
//       //   // permission: F360_MANAGE_CONNECT_ACCOUNT,
//       //   permission: "vs_view_agency_reporting",
//       // },
//       {
//         subMenuName: "Ad Accounts",
//         subMenuLink: "/ad-accounts",
//         subMenuIcon: UserOutlined,
//         permission: "vs_view_agency_reporting",
//       },
//       {
//         subMenuName: "Bussiness Manager",
//         subMenuLink: "/bms",
//         subMenuIcon: BranchesOutlined,
//         // permission: F360_MANAGE_CONNECT_ACCOUNT,
//         permission: "vs_view_agency_reporting",
//       },
//       {
//         subMenuName: "Campaigns",
//         subMenuLink: "/campaigns",
//         subMenuIcon: UserOutlined,
//         permission: "vs_view_agency_reporting",
//       },
//       {
//         subMenuName: "Adsets",
//         subMenuLink: "/adsets",
//         subMenuIcon: UserOutlined,
//         permission: "vs_view_agency_reporting",
//       },
//       {
//         subMenuName: "Ads",
//         subMenuLink: "/ads",
//         subMenuIcon: UserOutlined,
//         permission: "vs_view_agency_reporting",
//       },
//       {
//         subMenuName: "Reports",
//         subMenuIcon: UserOutlined,
//         permission: "vs_view_agency_reporting",
//         isCollapsible: true,
//         subMenus: [
//           {
//             subMenuName: "Reports",
//             subMenuLink: "/reports",
//             subMenuIcon: UserOutlined,
//             permission: "vs_view_agency_reporting",
//           },
//           {
//             subMenuName: "Daily",
//             subMenuLink: "/daily",
//             subMenuIcon: UserOutlined,
//             // permission: "vs_view_agency_reporting",
//           },
//           {
//             subMenuName: "Hourly",
//             subMenuLink: "/hourly",
//             subMenuIcon: UserOutlined,
//             // permission: "vs_view_agency_reporting",
//           },
//           {
//             subMenuName: "Hour of the day",
//             subMenuLink: "/hour-of-the-day",
//             subMenuIcon: UserOutlined,
//             // permission: "vs_view_agency_reporting",
//           },
//         ],
//       },
//       // },
//       // {
//       //   subMenuName: "Configuration",
//       //   subMenuLink: "/f-configuration",
//       //   subMenuIcon: SettingOutlined,
//       //   permission: F360_MANAGE_CONFIGURATION,
//       // },
//       // {
//       //   subMenuName: "Landing Urls",
//       //   subMenuLink: "/landing-urls",
//       //   subMenuIcon: PicRightOutlined,
//       //   permission: F360_VIEW_CAMPAIGNS,
//       // },
//     ],
//   },
// ];

export default useFb360Path;
