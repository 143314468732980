export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const AGENCY_INFO = "AGENCY_INFO";
export const DASHBOARD_REFRESHED = "DASHBOARD_REFRESHED";
export const ME_FETCHED = "ME_FETCHED";
export const ME_DATA = "ME_DATA";
export const WORKSPACE_PRODUCTS_DATA = "WORKSPACE_PRODUCTS_DATA";
export const WORKSPACE_PRODUCTS_DATA_CLEAR = "WORKSPACE_PRODUCTS_DATA_CLEAR";
export const F360_CONFIG = "F360_CONFIG";
export const F360_CONFIG_CLEAR = "F360_CONFIG_CLEAR";
export const TABLE_SELECTED_ROWS = "TABLE_SELECTED_ROWS";
export const TABLE_SELECTED_ROWS_CLEAR = "TABLE_SELECTED_ROWS_CLEAR";
export const USER_LOGIN_ERROR_COUNT = "USER_LOGIN_ERROR_COUNT";
export const USER_LOGIN_ERROR_COUNT_RESET = "USER_LOGIN_ERROR_COUNT_RESET";

//cms
export const CMS_DATA = "CMS_DATA";
export const CMS_SETTINGS = "CMS_SETTINGS";
export const CMS_IMAGE_RESIZE_LIST = "CMS_IMAGE_RESIZE_LIST";
export const CMS_SETTINGS_CLEAR = "CMS_SETTINGS_CLEAR";
export const CMS_ARTICLE_SETTINGS = "CMS_ARTICLE_SETTINGS";
export const CMS_ARTICLE_SETTINGS_CLEAR = "CMS_ARTICLE_SETTINGS_CLEAR";

//cms keys
export const CMS_DATA_KEY_PRODUCT_LIST = "productList";
export const CMS_DATA_KEY_BLOCK_LIST = "blockList";
export const CMS_DATA_KEY_STORE_LIST = "storeList";

//scorecard
export const SCORECARD_LOB_SELECTION = "setLob";
export const SCORECARD_DEPARTMENT_SELECTION = "setDepartment";
export const SC_SELECTED_ASSIGNED_DEPARTMENT = "SC_SELECTED_ASSIGNED_DEPARTMENT";

//unicorn
export const UNICORN_TEAM_SELECTION = "UNICORN_TEAM_SELECTION";
export const UNICORN_STATUS_SELECTION = "UNICORN_STATUS_SELECTION";
export const UNICORN_DEPARTMENT_SELECTION = "UNICORN_DEPARTMENT_SELECTION";
export const UNICORN_LOB_SELECTION = "UNICORN_LOB_SELECTION";
export const UNICORN_CLEAR_SETTINGS = "UNICORN_CLEAR_SETTINGS";

//sas
export const VERTICAL_WITH_ALL = "VERTICAL_WITH_ALL_SELECTION";
export const CATEGORY_WITH_ALL = "CATEGORY_WITH_ALL_SELECTION";

//userSetting
export const REPORT_SCALABLE = "REPORT_SCALABLE";
export const MILESTONE_PENDING = "MILESTONE_PENDING";
// export const IS_FOR_ME_FILTER = "IS_FOR_ME_FILTER"
export const CLICKUP_USER_LOGGED_IN = "CLICKUP_USER_LOGGED_IN";
export const USER_SETTING_DEBUG_MODE = "USER_SETTING_DEBUG_MODE";
export const WORKSPACE_SELECTION = "WORKSPACE_SELECTION";
export const MULTI_WORKSPACE_SELECTION = "MULTI_WORKSPACE_SELECTION";
export const WORKSPACE_SELECTION_WITH_ALL_FALSE = "WORKSPACE_SELECTION_WITH_ALL_FALSE";
export const WORKSPACE_WITH_ALL = "WORKSPACE_SWORKSPACE_WITH_ALLELECTION";
export const CLEAR_USER_SETTINGS = "CLEAR_USER_SETTINGS";
export const DATE_RANGE_TIMEZONE = "DATE_RANGE_TIMEZONE";
export const DATE_RANGE = "DATE_RANGE";
export const USER_SETTING_CURRENCY = "USER_SETTING_CURRENCY";
export const USER_SETTING_DEFAULT_TIMEZONE = "USER_SETTING_DEFAULT_TIMEZONE";
export const USER_SETTING_DEFAULT_I_TASK_TIMEZONE = "USER_SETTING_DEFAULT_I_TASK_TIMEZONE";
export const USER_SETTING_OBJECT = "USER_SETTING_OBJECT";
export const USER_SETTING_MAIN_SEARCH = "USER_SETTING_MAIN_SEARCH";
export const USER_SETTING_MAIN_BULK_ACTION = "USER_SETTING_MAIN_BULK_ACTION";
export const USER_SETTING_MAIN_BULK_ACTION_CLEAR = "USER_SETTING_MAIN_BULK_ACTION_CLEAR";
export const USER_SETTING_MAIN_SEARCH_CLEAR = "USER_SETTING_MAIN_SEARCH_CLEAR";
export const USER_SETTING_MAIN_SEARCH_WITH_INCLUDE_BIT =
  "USER_SETTING_MAIN_SEARCH_WITH_INCLUDE_BIT";
export const USER_SETTING_MAIN_PAGINATION = "USER_SETTING_MAIN_PAGINATION";
export const USER_SETTING_MAIN_PAGINATION_CLEAR = "USER_SETTING_MAIN_PAGINATION_CLEAR";
export const USER_SETTING_MAIN_USER = "USER_SETTING_MAIN_USER";
export const USER_SETTING_REPORT_FROM_HOUR = "USER_SETTING_REPORT_FROM_HOUR";
export const USER_SETTING_REPORT_FROM_HOUR_CLEAR = "USER_SETTING_REPORT_FROM_HOUR_CLEAR";
export const USER_SETTING_SCORECARD_BY_MONTH = "USER_SETTING_SCORECARD_BY_MONTH";
export const USER_SETTING_SCORECARD_BY_YEAR = "USER_SETTING_SCORECARD_BY_YEAR";
export const USER_SETTING_SCORECARD_BY_DATE = "USER_SETTING_SCORECARD_BY_DATE";
export const USER_SETTING_SCORECARD_BY_QUARTER = "USER_SETTING_SCORECARD_BY_QUARTER";

export const USER_SETTING_TABLE_PERSIST_KEY_MAIN = "main";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 = "level1";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2 = "level2";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3 = "level3";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4 = "level4";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5 = "level5";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6 = "level6";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7 = "level7";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_8 = "level8";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_9 = "level9";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_10 = "level10";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_11 = "level11";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_12 = "level12";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_13 = "level13";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_14 = "level14";
export const USER_SETTING_TABLE_PERSIST_KEY_LEVEL_15 = "level15";
export const USER_SETTING_MAIN_SEARCH_AND_PAGINATION_CLEAR =
  "USER_SETTING_MAIN_SEARCH_AND_PAGINATION_CLEAR";
export const USER_SETTING_CLEAR_THINGS_ON_ROUTE_CHANGES =
  "USER_SETTING_CLEAR_THINGS_ON_ROUTE_CHANGES";
export const USER_SETTING_SORTER = "USER_SETTING_SORTER";
export const USER_SETTING_SORTER_CLEAR = "USER_SETTING_SORTER_CLEAR";
export const USER_SETTING_TABLE_ROW_SIZE = "USER_SETTING_TABLE_ROW_SIZE";
export const USER_SETTING_THEME_LIGHT = "USER_SETTING_THEME_LIGHT";
export const USER_SETTING_AGENCY_USERS = "USER_SETTING_AGENCY_USERS";
export const USER_SETTING_DATA = "USER_SETTING_DATA";
export const USER_SETTING_VS_AGENCIES = "USER_SETTING_VS_AGENCIES";
export const USER_SETTING_CREATE_NEW = "USER_SETTING_CREATE_NEW";
export const USER_SETTING_SEARCH_FILTER = "USER_SETTING_SEARCH_FILTER"; // For custom filter
export const USER_SETTING_SET_COLUMNS = "USER_SETTING_SET_COLUMNS";
export const USER_SETTING_CCUSTOM_COLUMNS_SET = "USER_SETTING_CCUSTOM_COLUMNS_SET";
export const USER_SETTING_DEFAULT_FILTER = "USER_SETTING_DEFAULT_FILTER"; // For outer default dropdown filter
export const USER_SETTING_DEFAULT_FILTER_BOOLEAN = "USER_SETTING_DEFAULT_FILTER_BOOLEAN"; // For outer default dropdown filter

//vsReducer
export const VS_ADVERTISER_FORCE_SET = "USER_SETTING_VS_ADVERTISER_FORCE_SET";
export const VS_ADVERTISER_SELECTION = "USER_SETTING_VS_ADVERTISER_SELECTION";
export const VS_ADVERTISER_SELECTION_WITH_ALL = "USER_SETTING_VS_ADVERTISER_SELECTION_WITH_ALL";
export const VS_IS_FORECAST_SET = "VS_IS_FORECAST_SET";
export const VS_IS_FORECAST_UNSET = "VS_IS_FORECAST_UNSET";

//iCampaignsReducer
export const I_CAMPAIGNS_LAUNCH_AD_ACCOUNT_SELECTION = "I_CAMPAIGNS_LAUNCH_AD_ACCOUNT_SELECTION";
export const I_CAMPAIGNS_LAUNCH_RESPONSE_ID = "I_CAMPAIGNS_LAUNCH_RESPONSE_ID";
export const I_CAMPAIGNS_LAUNCH_CAMPAIGN_STATES = "I_CAMPAIGNS_LAUNCH_CAMPAIGN_STATES";
export const I_CAMPAIGNS_LAUNCH_AD_SET_STATES = "I_CAMPAIGNS_LAUNCH_AD_SET_STATES";
export const I_CAMPAIGNS_LAUNCH_LINK_DATA_STATES = "I_CAMPAIGNS_LAUNCH_LINK_DATA_STATES";
export const I_CAMPAIGNS_LAUNCH_TARGETING_STATES = "I_CAMPAIGNS_LAUNCH_TARGETING_STATES";
export const I_CAMPAIGNS_LAUNCH_CREATIVE_STATES = "I_CAMPAIGNS_LAUNCH_CREATIVE_STATES";
export const I_CAMPAIGNS_LAUNCH_AD_STATES = "I_CAMPAIGNS_LAUNCH_AD_STATES";
export const I_CAMPAIGNS_LAUNCH_CREATIVE_IMAGES = "I_CAMPAIGNS_LAUNCH_CREATIVE_IMAGES";

//media
export const USER_SETTING_MEDIA_CATRGORY = "USER_SETTING_MEDIA_CATRGORY";
export const USER_SETTING_MEDIA_ITEMS = "USER_SETTING_MEDIA_ITEMS";
export const USER_SETTING_MEDIA_ITEMS_NEXT_TOKEN = "USER_SETTING_MEDIA_ITEMS_NEXT_TOKEN";

//Launch
export const REDUCER_LAUNCH_DETAILS = "REDUCER_LAUNCH_DETAILS";
export const REDUCER_LAUNCH_TARGETING = "REDUCER_LAUNCH_TARGETING";
export const REDUCER_LAUNCH_STATES = "REDUCER_LAUNCH_STATES";
export const LAUNCH_PLATFORM_SELECTION = "REDUCER_LAUNCH_PLATFORM_SELECTION";

//G360

export const G360_PROFILE_REFETCH_TRUE = "REFETCH_GOOGLE_PROFILE_TRUE";
export const G360_PROFILE_REFETCH_FALSE = "REFETCH_GOOGLE_PROFILE_FALSE";

//Twitter
export const TWITTER_PROFILE_REFETCH_TRUE = "REFETCH_TWITTER_PROFILE_TRUE";
export const TWITTER_PROFILE_REFETCH_FALSE = "REFETCH_TWITTER_PROFILE_FALSE";

//Slack
export const SET_SLACK_CHANNEL_DATA = "SET_SLACK_CHANNEL_DATA";
export const FLUSH_SLACK_CHANNEL_DATA = "FLUSH_SLACK_CHANNEL_DATA";
export const SET_SLACK_CHANNEL_AUTH_CALLED = "SET_SLACK_CHANNEL_AUTH_CALLED";
export const SET_SLACK_CHANNEL_AUTH_SET_TEAM_ID = "SET_SLACK_CHANNEL_AUTH_SET_TEAM_ID";
export const SET_SLACK_CHANNEL_AUTH_DONE = "SET_SLACK_CHANNEL_AUTH_DONE";

//CallBack
export const CALL_BACK_REFETCH_TRUE = "CALL_BACK_REFETCH_TRUE";
export const CALL_BACK_REFETCH_FALSE = "CALL_BACK_REFETCH_FALSE";
