import { Button, Checkbox, Collapse, Pagination, Select } from "antd";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";
import React, { useEffect, useState } from "react";
import MainForm from "./MainForm";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import {
  MOODBOARD_CREATIVE_TYPE_REQUEST,
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_LANGUAGE,
  MOODBOARD_TYPE_PAIN_POINTS,
  MOODBOARD_TYPE_SCRIPT,
} from "../../creativesEnum";
import Loader from "components/loader";
import TextArea from "antd/lib/input/TextArea";
import { Col, Row, Tabs } from "antd/lib";
import ScriptTab from "./ScriptTab";
import {
  GET_MOODBOARD_ANGLES,
  GET_MOODBOARD_COPY_POINTS,
  GET_MOODBOARD_CTA,
  GET_MOODBOARD_HOOKS,
  GET_MOODBOARD_PAIN_POINTS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";

import LookButton from "components/LookButton";
import { useLookModal } from "components/LookModal";
import TagModal from "components/Launch/Creatives/CreativeUpload/TagModal";
import { Input } from "antd";
import { RECORD_ADDED } from "shared/enum/apiResponse";

function MoodBoard(props) {
  const [activeKey, setActiveKey] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [editActivekey, setEditActiveKey] = useState();
  const [values, setValues] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [moodboardError, setMoodboardError] = useState("");
  const [searchMode, setSearchMode] = useState("normal");
  const [searchItem, setSearchItem] = useState("");

  const { Search } = Input;
  const {
    actionType,
    query,
    apiDataType,
    id,
    name,
    productId,
    mutation,
    placeHolderKey,
    deleteQuery,
    isRequestView = false,
    isCreativeRequest = false,
    requestScripts = [],
    requestType,
    creativeType,
    scriptsFetch = () => {},
    isCreativeBuilder = false,
  } = props;
  const isCopyPoints = actionType === MOODBOARD_TYPE_COPY_POINTS;
  const handleCollapseChange = (keys, data) => {
    setActiveKey(keys);
    setIsVisible(false);
    setIsEdit(false);
    setValues((prev) => ({ ...prev, ...data, isEdited: false }));
    setEditActiveKey();
    setIsCreateForm(false);
  };
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData({ id: rowProps });
    setDeleteModalVisible(true);
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const onSubmitDeleteModal = () => {
    moodBoardDeleteData({
      variables: {
        categoryId: id,
        _ids: deleteModalPropsData?.id,
        productId,
      },
    });
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  const [isCreateForm, setIsCreateForm] = useState(false);
  const [data, setData] = useState([]);
  const [moodBoardDataFetch, { loading: data_loading, refetch: data_refetch }] = useCustomLazyQuery(
    query,
    {
      onCompleted: (e) => {
        if (actionType === MOODBOARD_TYPE_SCRIPT) {
          setData(
            e?.[apiDataType]?.data?.map((dataFetched) => ({
              ...dataFetched,
              angles: dataFetched?.angles?.map((angle) => angle?.id),
              painPoints: dataFetched?.painPoints.map((painPoint) => painPoint?.id),
              ctas: dataFetched?.ctas?.map((cta) => cta?.id),
              hooks: dataFetched?.hooks?.map((hook) => hook?.id),
              copyPoints: dataFetched?.copyPoints?.map((copyPoint) => copyPoint?.id),
              language: [dataFetched?.language?.code],
            }))
          );
          setTotalCount(e?.[apiDataType]?.pageInfo?.totalRecords);
        } else {
          setData(e?.[apiDataType]?.data);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message, key: "mb-res-err" });
      },
      variables: { categoryId: id, productId, page: currentPage, size: pageSize },
    }
  );

  const [moodBoardAddData, { loading, refetch }] = useCustomMutation(mutation, {
    onCompleted: (e) => {
      // setData(e?.[apiDataType]);
      setValues({});
      openNotification({ type: "success", message: RECORD_ADDED });
      setIsCreateForm(false);
      setMoodboardError();
      setIsEdit(false);
      data_refetch();
      isCreativeBuilder &&
        scriptsFetch({
          variables: {
            categoryId: id,
            productId: productId,
          },
        });
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
      setMoodboardError();
    },
    variables: {
      categoryId: id,
      categoryName: name,
      productId,
    },
  });
  const [moodBoardDeleteData, { loading: delete_loading, refetch: refetch_loading }] =
    useCustomMutation(deleteQuery, {
      onCompleted: (e) => {
        // setData(e?.[apiDataType]);
        setValues({});
        openNotification({ type: "success", message: "Record Deleted successfully" });
        setIsCreateForm(false);
        setIsEdit(false);
        data_refetch();
        onCloseDeleteModal();
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  const onChange = (key, data, isEdited) => {
    setActiveTab(key);
    // setValues({ ...data, isEdited: isEdited });
  };
  const tabProps = {
    id,
    name,
    productId,
    values,
    setValues,
    isCreateForm,
  };
  const tabContent = [
    {
      key: "1",
      label: "Copy Points",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_COPY_POINTS}
          query={GET_MOODBOARD_COPY_POINTS}
          apiType="ctGetCopyPoints"
          {...tabProps}
          paramstype="copyPoints"
          label="Copy Points"
        />
      ),
    },
    {
      key: "2",
      label: "Angles",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_ANGLES}
          query={GET_MOODBOARD_ANGLES}
          apiType="ctGetAngles"
          {...tabProps}
          paramstype="angles"
          label="Angles"
        />
      ),
    },
    {
      key: "3",
      label: "Hooks",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_HOOKS}
          query={GET_MOODBOARD_HOOKS}
          apiType="ctGetHooks"
          {...tabProps}
          paramstype="hooks"
          label="Hooks"
        />
      ),
    },
    {
      key: "4",
      label: "Pain Points",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_PAIN_POINTS}
          query={GET_MOODBOARD_PAIN_POINTS}
          apiType="ctGetPainPoints"
          {...tabProps}
          paramstype="painPoints"
          label="Pain Points"
        />
      ),
    },
    {
      key: "5",
      label: "CTA",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_CTA}
          query={GET_MOODBOARD_CTA}
          apiType="ctGetCTAs"
          {...tabProps}
          paramstype="ctas"
          label="CTA"
        />
      ),
    },
    {
      key: "6",
      label: "Language *",
      children: (
        <ScriptTab
          query={GET_MOODBOARD_CTA}
          type={MOODBOARD_TYPE_LANGUAGE}
          {...tabProps}
          paramstype="language"
          label="Language"
        />
      ),
    },
  ];
  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });

  // onClick={()=>{
  //   closeTagModal(()=>{

  //   })
  // }}
  function onSubmit() {
    if (values?.name) {
      setEditActiveKey();
      setActiveKey();
      moodBoardAddData({
        variables: {
          categoryId: id,
          categoryName: name,
          productId,
          name: values?.name,
          description: values?.description,
          ...(values?.id && { _id: values?.id }),
          ...(values?.language && { language: values?.language?.[0] }),
          ...(values?.copyPoints && { copyPoints: values?.copyPoints }),
          ...(values?.angles && { angles: values?.angles }),
          ...(values?.hooks && { hooks: values?.hooks }),
          ...(values?.painPoints && { painPoints: values?.painPoints }),
          ...(values?.ctas && { ctas: values?.ctas }),
        },
      });
    } else {
      let err = {};
      if (!values.name) {
        err.name = "field cannot be empty";
      }
      if (Object.keys(err).length === 0) {
        onSubmit();
      } else {
        setMoodboardError(err);
      }
    }
  }
  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => {
        onOpenDeleteModal(e?.map((data) => data?.id));
      },
    },

    {
      option: "tagSwitch",
      title: " Edit tags",
      onClick: (e) => {
        openTagModal();
        setDeleteModalPropsData(e?.map((data) => ({ id: data?.id, tags: data?.tags || [] })));
      },
    },
  ];

  useEffect(() => {
    moodBoardDataFetch();
  }, []);

  const onClose = () => {
    setValues({});
    setIsEdit(false);
    setEditActiveKey();
    setActiveKey();
    setIsCreateForm(false);
  };
  const formProps = {
    values,
    setValues,
    isCreateForm,
    setIsCreateForm,
    onClose,
    setActiveTab,
    activeTab,
    onChange,
    onSubmit,
    setIsEdit,
    isEdit,
    tabContent,
    actionType,
    activeKey,
    setActiveKey,
    placeHolderKey,
    isCopyPoints,
  };
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: delete_loading,
  };
  const handleSelectedItems = (id) => {
    if (selectedItems?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedItems(selectedItems?.filter((img) => img?.id !== id));
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          id: finalData?.filter((img) => img?.id === id)[0].id,

          tags: finalData?.filter((img) => img?.id === id)[0].tags,
        },
      ]);
    }
  };
  function selectedRowsClear() {
    setDeleteModalPropsData([]);
    setSelectedItems([]);
    setTags([]);
  }

  const bulkActionProps = {
    selectedRows: selectedItems,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const finalData =
    (isRequestView && requestType === MOODBOARD_CREATIVE_TYPE_REQUEST
      ? data?.filter((script) => requestScripts?.includes(script?.id))
      : data) || [];

  const tagDataExtractor = (id) => {
    const tagsArray = finalData?.filter((data) => data?.id === id)?.[0]?.tags;

    setTagsData(tagsArray?.map((data) => data?.name));
  };
  const searchView = () => (
    <>
      <div
        className={cx("look-table-search", "px-15 py-8", "d-flex")}
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #262626",
        }}
      >
        {searchMode === "tags" ? (
          <Select
            mode={"tags"}
            allowClear={false}
            bordered={false}
            placeholder={"Search by tags"}
            style={{ width: "200px" }}
            onChange={(e) => {
              setSearchItem(e);
            }}
            value={searchItem}
            maxTagCount="responsive"
            showArrow={false}
          />
        ) : (
          <Search
            className="creative-tag-search"
            placeholder={`Search by  ${placeHolderKey}`}
            suffix={null}
            onSearch={(e) => {
              moodBoardDataFetch({
                variables: {
                  search: e,
                },
              });
            }}
            onChange={(e) => {
              setSearchItem(e?.target?.value);
            }}
            style={{
              width: "100%",
            }}
            value={searchItem}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #262626",
          }}
        >
          {/* add tags icon */}
          <LookButton
            tooltipTitle={
              searchMode === "tags"
                ? "Click here to switch to normal mode"
                : "Click here to search by tags"
            }
            className="filter-btn ml-8"
            iconType={searchMode === "tags" ? "close" : "tagSwitch"}
            onClick={() => {
              setSearchMode((prevMode) => (prevMode === "tags" ? "normal" : "tags"));
              setSearchItem();
            }}
          />
          <LookButton
            tooltipTitle="Click here to search"
            className="filter-btn"
            iconType="search"
            onClick={() => {
              moodBoardDataFetch({
                variables: {
                  ...(searchMode !== "tags" ? { search: searchItem } : { tags: searchItem }),
                },
              });
            }}
          />
          <LookButton
            tooltipTitle="Refetch"
            className="filter-btn ml-8"
            size="small"
            iconType="reload"
            disabled={loading}
            iconTypeProps={{ spin: loading }}
            onClick={() => data_refetch()}
          />
        </div>
      </div>
      {!isRequestView && <MainForm {...formProps} />}
    </>
  );
  const contentView = () => (
    <>
      {finalData.length === 0 ? (
        <div className="text-center pt-100">
          <div style={{ transform: "translateX(20px)" }}>
            <ActionIconRender iconType="tablePlaceholder" />
          </div>
          <p style={{ fontSize: "1.5rem" }}>
            <b>No Data Found...</b>
          </p>
        </div>
      ) : (
        finalData?.map((moodBoardData, index) => (
          <>
            <div
              className={cx(
                "moodboardData",
                {
                  active: activeKey === index + "" || (isEdit && editActivekey === index),
                },
                { copyPoint: actionType === MOODBOARD_TYPE_COPY_POINTS }
              )}
            >
              <div className="d-flex flex-grow-1">
                {!isRequestView && (
                  <Checkbox
                    checked={
                      selectedItems?.filter((img) => img.id === moodBoardData?.id)?.length > 0
                    }
                    style={{ marginTop: "12px", marginLeft: "12px", marginRight: "0px" }}
                    onClick={() => handleSelectedItems(moodBoardData?.id)}
                  />
                )}

                <Collapse
                  className="flex-grow-1"
                  collapsible="header"
                  accordion
                  activeKey={activeKey}
                  onChange={(e) => handleCollapseChange(e, moodBoardData)}
                >
                  <Collapse.Panel
                    header={
                      isEdit && editActivekey === index ? (
                        <div>
                          <TextArea
                            autoSize
                            value={values?.name}
                            className={cx("moodBoard_form")}
                            placeholder={`Click here to Add New ${placeHolderKey}`}
                            onChange={(e) => {
                              // setActiveKey(index);
                              if (e.target.value.length < 100) {
                                setValues((prevItems) => ({
                                  ...prevItems,
                                  name: e.target.value,
                                }));
                              } else {
                                setMoodboardError((prev) => ({
                                  ...prev,
                                  name: "Word limit exceeded",
                                }));
                              }
                            }}
                          />
                          {moodboardError?.name && (
                            <span className="text-left error-text">{moodboardError?.name}</span>
                          )}
                          {!isCopyPoints && (
                            <>
                              <TextArea
                                autoSize
                                value={values?.description}
                                className={cx("moodBoard_form description")}
                                placeholder="Add Description"
                                onChange={(e) =>
                                  setValues((prevItems) => ({
                                    ...prevItems,
                                    description: e.target.value,
                                  }))
                                }
                              />
                              {actionType === MOODBOARD_TYPE_SCRIPT && (
                                <div style={{ paddingBottom: "20px" }}>
                                  <Tabs
                                    className={cx("scriptTabs", { isEdit })}
                                    defaultActiveKey="1"
                                    size="large"
                                    items={tabContent}
                                    onChange={onChange}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {isEdit && editActivekey === index && (
                            <div>
                              <Row justify="end">
                                <Col span={4}>
                                  <Button block onClick={onClose}>
                                    Cancel
                                  </Button>
                                </Col>
                                <Col span={4} className="mr-12">
                                  <Button
                                    block
                                    type="primary"
                                    htmlType="submit"
                                    className="ml-10 "
                                    onClick={onSubmit}
                                  >
                                    Submit
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </div>
                      ) : (
                        moodBoardData?.name
                      )
                    }
                    key={index.toString()}
                    collapsible={
                      actionType === MOODBOARD_TYPE_COPY_POINTS ||
                      (isEdit && editActivekey === index) ||
                      (moodBoardData?.description === null && actionType !== MOODBOARD_TYPE_SCRIPT)
                        ? "disabled"
                        : undefined
                    }
                  >
                    <div>
                      {actionType !== MOODBOARD_TYPE_COPY_POINTS && editActivekey !== index && (
                        <>
                          {(moodBoardData?.description !== null ||
                            actionType === MOODBOARD_TYPE_SCRIPT) && (
                            <>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#696969",
                                  marginBottom: "9px",
                                }}
                              >
                                {isVisible
                                  ? moodBoardData?.description
                                  : moodBoardData?.description?.substring(0, 170)}
                              </p>
                              {!isVisible && moodBoardData?.description?.length > 190 && (
                                <p
                                  style={{
                                    fontSize: "12px",
                                    marginBottom: "9px",
                                    color: "#3C89E8",
                                  }}
                                  onClick={() => setIsVisible(true)}
                                >
                                  ...more
                                </p>
                              )}
                            </>
                          )}
                          {actionType === MOODBOARD_TYPE_SCRIPT && (
                            <div style={{ paddingBottom: "20px" }}>
                              <Tabs
                                className="scriptTabs"
                                defaultActiveKey="1"
                                items={tabContent}
                                onChange={(e) => onChange(e, moodBoardData)}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </Collapse.Panel>
                </Collapse>
              </div>
              {!isRequestView && (
                <div className="mt-12 mr-12 d-flex">
                  <ActionIconRender
                    className="mr-10"
                    iconType="tagSwitch"
                    onClick={() => {
                      openTagModal();
                      tagDataExtractor(moodBoardData?.id);
                      setDeleteModalPropsData([
                        { id: moodBoardData?.id, tags: moodBoardData?.tags || [] },
                      ]);
                    }}
                  />
                  <ActionIconRender
                    className="mr-10"
                    iconType="edit"
                    onClick={() => {
                      setIsEdit(true);
                      setActiveKey();
                      setIsCreateForm(false);
                      setEditActiveKey(index);
                      setValues((prevItems) => ({
                        ...prevItems,
                        ...moodBoardData,
                        name: moodBoardData?.name,
                        description: moodBoardData?.description,
                        id: moodBoardData?.id,
                        isEdited: true,
                      }));
                    }}
                  />

                  <ActionIconRender
                    iconType="delete"
                    onClick={() => onOpenDeleteModal(moodBoardData?.id)}
                  />
                </div>
              )}
            </div>
          </>
        ))
      )}
    </>
  );

  return (
    <div className="w-100">
      {loading || data_loading ? (
        <Loader elementCenter />
      ) : (
        <>
          {visilbleTagModal &&
            tagModal(
              <TagModal
                variables={{
                  productId,
                  categoryId: id,
                  categoryName: name,
                }}
                files={deleteModalPropsData}
                creatives_refetch={data_refetch}
                setTags={setTags}
                tagsData={tagsData}
                setTagsData={setTagsData}
                tags={tags}
                closeTagModal={closeTagModal}
                selectedRowsClear={selectedRowsClear}
                creativeType={creativeType}
              />
            )}
          <LookTableRecordDeleteModal {...deleteModalProps} />
          <LookTableBulkAction {...bulkActionProps} />

          <div className={cx("creative-builder-moodboard", { isRequestView, isMoodboard: true })}>
            {isRequestView && requestType === MOODBOARD_CREATIVE_TYPE_REQUEST ? (
              <div className="title-header">
                <p>Script & Content</p>
              </div>
            ) : (
              <div className="search-header">{searchView()}</div>
            )}
            <div className="content-view">{contentView()}</div>
            <div className="p-8 h-initial w-initial look-bottom-pagination ">
              <Pagination
                defaultCurrent={1}
                total={totalCount}
                pageSize={pageSize}
                current={currentPage}
                onChange={(page, size) => handlePageChange(page, size)}
                pageSizeOptions={["10", "20", "50"]}
                showSizeChanger
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MoodBoard;
