import React from "react";
import { MOODBOARD_CREATIVE_TYPE_REQUEST } from "../creativesEnum";
import RequestList from "../RequestListing";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import useRequestDrawer from "../CreativeHooks/useRequestDrawer";
import {
  CREATIVE_TRACKING_BUSINESS_DEVELOPER,
  CREATIVE_TRACKING_CONTENT_WRITER,
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  CREATIVE_TRACKING_MEDIA_BUYER,
} from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import { FETCH_REQUEST_TRACKING } from "shared/gql/gqlSchema/creativeDashboardGql";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { openNotification } from "utils";

function CreativeRequestList() {
  const { createRequestForm, createRequest } = useRequestDrawer();
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const { havePermission: isBusinessDeveloper } = usePermissionCheck(
    CREATIVE_TRACKING_BUSINESS_DEVELOPER
  );
  const { havePermission: isMediaBuyer } = usePermissionCheck(CREATIVE_TRACKING_MEDIA_BUYER);
  const { havePermission: isContentWriter } = usePermissionCheck(CREATIVE_TRACKING_CONTENT_WRITER);
  const { havePermission: isGraphicDesigner } = usePermissionCheck(
    CREATIVE_TRACKING_GRAPHIC_DESIGNER
  );
  const permissionProps = {
    isMediaBuyer,
    isCreativeDirector,
    isGraphicDesigner,
    isContentWriter,
    isBusinessDeveloper,
  };
  const createBtnProps = {
    createBtn: true,
    createBtnText: "Raise Creative Request",
    createBtnHandler: () =>
      createRequest({
        //Will change props name in future
        isCategory: true,
        requestListrefetch: data_refetch,
        ...permissionProps,
        isCreativeRequest: true,
      }),
  };
  const [creativesRequestFetch, { data, loading: data_loading, refetch: data_refetch }] =
    useCustomLazyQuery(FETCH_REQUEST_TRACKING, {
      onCompleted: (e) => {},
      onError(e) {
        openNotification({ type: "error", message: e?.message, key: "mb-res-err" });
      },
    });

  return (
    <div>
      {createRequestForm()}
      {!isGraphicDesigner && <DashboardHeader title="Creative Request" {...createBtnProps} />}
      <RequestList
        actionType={MOODBOARD_CREATIVE_TYPE_REQUEST}
        {...permissionProps}
        creativesRequestFetch={creativesRequestFetch}
        data_refetch={data_refetch}
        requestListData={data}
        data_loading={data_loading}
      />
    </div>
  );
}
export default CreativeRequestList;
