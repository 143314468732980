import { LookTableColumnRender } from "../../../../../components/LookTable/LookTableColumnOptions";
import cx from "classnames";

const valueCheck = (value, checkTotal = false) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(3);
  return valueFloat;
};

const columns = [
  {
    title: "Utm Content",
    dataIndex: "utmContent",
    key: "utmContent",
    render: (value) => <LookTableColumnRender title={value} />,
    width: 170,
    minWidth: 130,
    hidden: true,
    // shouldCellUpdate: () => false,
  },
  {
    title: "Revenue",
    dataIndex: "TotalRevenue",
    key: "TotalRevenue",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.TotalRevenue - b?.TotalRevenue,
    inChart: true,
    // shouldCellUpdate: () => false,
  },
  // {
  //   title: "Spend",
  //   dataIndex: "spendFB",
  //   key: "spendFB",
  //   render: (value) => <LookTableColumnRender title={<>${valueCheck(value, true)}</>} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.spendFB - b?.spendFB,
  //   inChart: true,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "Gross Margin",
  //   dataIndex: "PROFIT",
  //   key: "PROFIT",
  //   render: (value, otherProps) => {
  //     const profit = `$${value?.toFixed(3)}`;

  //     return (
  //       <LookTableColumnRender
  //         title={
  //           <strong
  //             className={cx({
  //               "text-primary": parseFloat(value) > 0,
  //               "text-danger": parseFloat(value) < 0,
  //             })}
  //           >
  //             {profit?.replace("-", "")}
  //           </strong>
  //         }
  //       />
  //     );
  //   },
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.PROFIT - b?.PROFIT,
  //   inChart: true,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "ROI",
  //   dataIndex: "ROI",
  //   key: "ROI",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.ROI - b?.ROI,
  //   inChart: true,
  //   // shouldCellUpdate: () => false,
  // },
  {
    title: "EPC",
    dataIndex: "EPC",
    key: "EPC",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.EPC - b?.EPC,
    inChart: true,
    // shouldCellUpdate: () => false,
  },
  // {
  //   title: "CPC",
  //   dataIndex: "CPC",
  //   key: "CPC",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.CPC - b?.CPC,
  //   inChart: true,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "CPC(FB)",
  //   dataIndex: "CPC_FB",
  //   key: "CPC_FB",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.CPC_FB - b?.CPC_FB,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "CTR",
  //   dataIndex: "CTR",
  //   key: "CTR",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.CTR - b?.CTR,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "Results",
  //   dataIndex: "leadsFB",
  //   key: "leadsFB",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.leadsFB - b?.leadsFB,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "CPR",
  //   dataIndex: "CPR",
  //   key: "CPR",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.CPR - b?.CPR,
  //   // shouldCellUpdate: () => false,
  // },
  {
    title: "Sessions",
    dataIndex: "sessions",
    key: "sessions",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.sessions - b?.sessions,
    // shouldCellUpdate: () => false,
  },
  // {
  //   title: "Clicks",
  //   dataIndex: "clicksFB",
  //   key: "clicksFB",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.clicksFB - b?.clicksFB,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "Disc",
  //   dataIndex: "DISC",
  //   key: "DISC",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.DISC - b?.DISC,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "PPS",
  //   dataIndex: "PPS",
  //   key: "PPS",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.PPS - b?.PPS,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "PRPM",
  //   dataIndex: "PRPM",
  //   key: "PRPM",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.PRPM - b?.PRPM,
  //   // shouldCellUpdate: () => false,
  // },
  {
    title: "Impressions",
    dataIndex: "impressions",
    key: "impressions",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.impressions - b?.impressions,
    // shouldCellUpdate: () => false,
  },
  // {
  //   title: "CPM",
  //   dataIndex: "CPM",
  //   key: "CPM",
  //   render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
  //   width: 130,
  //   minWidth: 130,
  //   sorter: (a, b) => a?.CPM - b?.CPM,
  //   // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "Days",
  //   dataIndex: "Days",
  //   key: "Days",
  //   render: (value) => <LookTableColumnRender title={value} />,
  //   width: 130,
  //   minWidth: 130,
  // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "Start",
  //   dataIndex: "Start",
  //   key: "Start",
  //   render: (value) => <LookTableColumnRender title={value} />,
  //   width: 130,
  //   minWidth: 130,
  // shouldCellUpdate: () => false,
  // },
  // {
  //   title: "End",
  //   dataIndex: "End",
  //   key: "End",
  //   render: (value) => <LookTableColumnRender title={value} />,
  //   width: 130,
  //   minWidth: 130,
  // shouldCellUpdate: () => false,
  // },
];

export default columns;
