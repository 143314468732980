import { useRef, useState, Fragment } from "react";
import { Button, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { GENERATE_INVITATION } from "shared/gql";
import { openNotification } from "utils";
import Loader from "components/loader";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

function ClickToCopy({ copyText = "", tooltipText = "", successText = "" }) {
  const copyRef = useRef();
  const copyToClipboard = (e) => {
    copyRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    openNotification({ type: "success", message: successText });
  };
  const ToolTipWrap = tooltipText ? Tooltip : Fragment;
  return (
    <>
      <div className="w-0 h-0 position-absolute opacity-0">
        <textarea ref={copyRef} value={copyText} />
      </div>
      {document.queryCommandSupported("copy") && (
        <ToolTipWrap title={tooltipText}>
          <Button onClick={copyToClipboard} className="border-0 copy-btn p-0 bg-transparent">
            <CopyOutlined />
          </Button>
        </ToolTipWrap>
      )}
    </>
  );
}

export default function GeneratenviteCode() {
  const [inviteCode, setInviteCode] = useState("");
  const agency_id = useSelector((state) => state?.userReducer?.me?.agency_id);

  const [GenerateInvitation, { loading }] = useCustomMutation(GENERATE_INVITATION, {
    onCompleted(e) {
      setInviteCode(e.GenerateInvitation.code);
      openNotification({ type: "success", message: "New code created" });
    },
    onError(e) {
      openNotification({ type: "error", message: "Error:  " + e.message });
    },
  });

  function generateCode() {
    GenerateInvitation({ variables: { agency_id: parseInt(agency_id) } });
  }
  const copyLink = `${process.env.REACT_APP_BASE_URL}/accept_invitation/${inviteCode}`;

  return (
    <>
      <DashboardHeader title="Generate Code" />
      <div className="p-40">
        {inviteCode && (
          <div className="d-table mx-auto">
            <div className="flex mb-8 "></div>
            <div className="flex mb-30">
              <p className="mb-0 mr-16">
                <b>Invite Code link :</b> {copyLink}
              </p>
              <ClickToCopy
                successText="Invite code link copied!"
                tooltipText="Click to copy invite code link"
                copyText={copyLink}
              />
            </div>
          </div>
        )}
        <div style={{ maxWidth: 500 }} className="mx-auto">
          <Button
            htmlType="submit"
            block
            type="primary"
            className="submit-btn"
            onClick={generateCode}
            disabled={loading}
          >
            {loading ? <Loader /> : "Generate New Code"}
          </Button>
        </div>
      </div>
    </>
  );
}
