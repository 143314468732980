import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import {
  GQL_CMS_CREATE_ADVERTISER,
  GQL_CMS_DELETE_ADVERTISER,
  GQL_CMS_GET_ADVERTISER,
  GQL_CMS_UPDATE_ADVERTISER,
} from "shared/gql/gqlSchema/cmsGql";
import { openNotification } from "utils";

export default function useCmsAdvertiserApis() {
  const [
    callCmsFetchAdvertiser,
    { loading: loading_cmsFetchAdvertiser, refetch: refetch_cmsFetchAdvertiser },
  ] = useCustomLazyQuery(GQL_CMS_GET_ADVERTISER, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [callCmsCreateAdvertiser, { loading: loading_cmsCreateAdvertiser }] = useCustomMutation(
    GQL_CMS_CREATE_ADVERTISER,
    {
      onError(e) {
        openNotification({ type: "error", message: e.message, key: "cms-a-c-error" });
      },
    }
  );
  const [callCmsUpdateAdvertiser, { loading: loading_cmsUpdateAdvertiser }] = useCustomMutation(
    GQL_CMS_UPDATE_ADVERTISER,
    {
      onError(e) {
        openNotification({ type: "error", message: e.message, key: "cms-a-u-error" });
      },
    }
  );
  const [callCmsDeleteAdvertiser, { loading: loading_cmsDeleteAdvertiser }] = useCustomMutation(
    GQL_CMS_DELETE_ADVERTISER,
    {
      onError(e) {
        openNotification({ type: "error", message: e.message, key: "cms-a-u-error" });
      },
    }
  );

  return {
    callCmsFetchAdvertiser,
    loading_cmsFetchAdvertiser,
    refetch_cmsFetchAdvertiser,

    callCmsCreateAdvertiser,
    loading_cmsCreateAdvertiser,

    callCmsUpdateAdvertiser,
    loading_cmsUpdateAdvertiser,

    callCmsDeleteAdvertiser,
    loading_cmsDeleteAdvertiser,
  };
}
