import { Row, Col } from "antd";
import DebugPre from "components/DebugPre";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { AdCreativeCardSkeleton } from "components/cards/CreativeCards";
import { GET_FB360_CREATIVES } from "shared/gql/gqlSchema/fb360Gql";
import AdCreativeCard from "./AdCreativeCard";

export default function Creatives(props) {
  const { campaignId, adsetId, adId } = props;

  let apiObject = {};

  if (campaignId) {
    apiObject = {
      variables: {
        asset_id: [campaignId],
        asset: "CAMPAIGN",
      },
    };
  }
  if (adsetId) {
    apiObject = {
      variables: {
        asset_id: [adsetId],
        asset: "ADSET",
      },
    };
  }

  if (adId) {
    apiObject = {
      variables: {
        asset_id: [adId],
        asset: "AD",
      },
    };
  }

  const { data, loading, error, refetch } = useCustomQuery(GET_FB360_CREATIVES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...apiObject.variables,
    },
  });

  return (
    <div className="p-24">
      {loading ? (
        <Row gutter={24}>
          {[{}, {}, {}].map(() => (
            <Col span={8}>
              <AdCreativeCardSkeleton />
            </Col>
          ))}
        </Row>
      ) : (
        <Row gutter={12}>
          {data?.fb360_creatives?.map((data, index) => (
            <Col span={8} xs={24} md={12} lg={8} xl={6}>
              <AdCreativeCard className="mb-12" {...data} />
            </Col>
          ))}
        </Row>
      )}
      <DebugPre content={[{ contentObjects: [{ data }] }]} />
    </div>
  );
}
