import LookButton from "components/LookButton";
import CmsBannerListView from "../CmsBannerListView";
import useCmsBannerCrudHook from "../useCmsBannerCrudHook";
import { removeObjectByIndex } from "utils";

export default function useCmsAddBanner(props = {}) {
  const { type = "", bannerImages = [], setBannerImages = () => {}, bannerLabel = true } = props;

  const { addBanners, call_addCmsBanner, loading_addCmsBanner } = useCmsBannerCrudHook({ type });

  const removeItem = (index) => {
    let temp = JSON.parse(JSON.stringify(bannerImages));
    setBannerImages(removeObjectByIndex(temp, index));
  };

  const renderBannerList = () => (
    <div>
      {bannerImages?.map((data, index) => (
        <CmsBannerListView
          {...data}
          remove={() => removeItem(index)}
          currentIndex={index}
          key={"bannerImages" + index}
          isAddView
          bannerImages={bannerImages}
          setBannerImages={setBannerImages}
        />
      ))}
    </div>
  );

  const pushNew = () => setBannerImages([...bannerImages, { image: "", lander_link: "" }]);

  function bannerAddView() {
    return (
      <>
        <div className="cms-banner-header">
          {bannerLabel && (
            <>
              <p className="label">
                Banners <span>({bannerImages?.length})</span>
              </p>
              <div>
                <p>Ad. Banner</p>
              </div>
            </>
          )}
        </div>

        <div className="cms-banner-list-content">
          {renderBannerList()}
          <div className="banner-add">
            <div className="add-banner" onClick={pushNew}>
              + Add new banner
            </div>
          </div>
        </div>
      </>
    );
  }

  return { bannerAddView, addBanners, call_addCmsBanner, loading_addCmsBanner };
}
