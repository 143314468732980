import { Form, Button, Alert, Row, Col, Switch } from "antd";
import { Formik } from "formik";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";
import SelectInput from "components/forms/SelectInput";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
  } = props;

  const statusInputs = [
    { status: "ACTIVE", value: "ACTIVE" },
    { status: "PAUSED", value: "PAUSED" },
    { status: "INHERITED_FROM_SOURCE", value: "INHERITED_FROM_SOURCE" },
  ];
  const budgetInputs = [
    { budgetType: "ABO", value: "ABO" },
    { budgetType: "CBO", value: "CBO" },
  ];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={16}>
              <LookTextInput
                formik
                name="name"
                label="Name"
                className="look-form-input"
                placeholder="Enter name"
              />
            </Col>
            <Col span={16}>
              <LookTextInput
                formik
                type="number"
                name="budget"
                label="Budget (in USD)"
                className="look-form-input"
                placeholder="Enter budget"
              />
            </Col>
            <Col span={8}>
              <div>
                <p>Budget</p>
                <Switch
                  checkedChildren="Lifetime"
                  unCheckedChildren="Daily"
                  size="medium"
                  checked={values.isLifeTime}
                  onChange={(e) => {
                    setFieldValue("isLifeTime", e);
                  }}
                  style={{ border: "1px solid #D3D3D3" }}
                />
              </div>
            </Col>
          </Row>
          <Col span={16}>
            <SelectInput
              label="Budget Optimization"
              name="budgetOptimization"
              placeholder="Select Budget Optimization"
              onChange={(e) => {
                setFieldValue("budgetOptimization", e);
              }}
              options={budgetInputs?.map(({ budgetType, value }) => ({
                name: budgetType,
                value,
              }))}
              className="look-form-input"
            />
          </Col>
          <Col span={16}>
            <LookTextInput
              formik
              type="number"
              name="count"
              label="Count"
              className="look-form-input"
              placeholder="Enter count"
            />
          </Col>
          <Col span={16}>
            <SelectInput
              label="Status"
              placeholder="Select Status"
              name="status"
              onChange={(e) => setFieldValue("status", e)}
              options={statusInputs.map(({ status, value }) => ({
                name: status,
                value,
              }))}
              className="look-form-input"
            />
          </Col>
          <Col span={16}>
            <LookTextInput
              formik
              type="text"
              name="prefix"
              label="Prefix"
              className="look-form-input"
              placeholder="Enter Prefix"
            />
          </Col>
          <Col span={16}>
            <LookTextInput
              formik
              type="text"
              name="suffix"
              label="Suffix"
              className="look-form-input"
              placeholder="Enter Suffix"
            />
          </Col>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
