import ActionIconRender from "components/ActionIconRender";
import {
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_MEDIA_BUYER,
  CREATIVE_TRACKING_BUSINESS_DEVELOPER,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  UMS_AGENCY_ADMIN,
} from "../../UserPermission/userPermissionList";

const creativePath = [
  {
    name: "Creatives",
    link: "/creative",
    icon: (e) => <ActionIconRender iconType="build" {...e} />,
    subMenu: [
      // {
      //   subMenuName: "Dashboard",
      //   subMenuLink: "/creative-dashboard",
      //   subMenuIcon: () => <ActionIconRender iconType="file-done" />,
      //   // permission: I_CAMPAIGNS_LAUNCH,
      // },
      {
        subMenuName: "Categories",
        subMenuLink: "/creative-upload",
        subMenuIcon: () => <ActionIconRender iconType="creativeCategories" />,
        permission: [
          CREATIVE_TRACKING_CREATIVE_DIRECTOR,
          CREATIVE_TRACKING_MEDIA_BUYER,
          CREATIVE_TRACKING_BUSINESS_DEVELOPER,
          CREATIVE_TRACKING_GRAPHIC_DESIGNER,
          UMS_AGENCY_ADMIN,
        ],
      },
      {
        subMenuName: "Creatives Request",
        subMenuLink: "/creative-request",
        subMenuIcon: () => <ActionIconRender iconType="file-icon" />,
        permission: [
          CREATIVE_TRACKING_CREATIVE_DIRECTOR,
          CREATIVE_TRACKING_MEDIA_BUYER,
          CREATIVE_TRACKING_BUSINESS_DEVELOPER,
          CREATIVE_TRACKING_GRAPHIC_DESIGNER,
          UMS_AGENCY_ADMIN,
        ],
      },
      {
        subMenuName: "Template Request",
        subMenuLink: "/template-request",
        subMenuIcon: () => <ActionIconRender iconType="file-icon" />,
        permission: [
          CREATIVE_TRACKING_CREATIVE_DIRECTOR,
          CREATIVE_TRACKING_MEDIA_BUYER,
          CREATIVE_TRACKING_BUSINESS_DEVELOPER,
          CREATIVE_TRACKING_GRAPHIC_DESIGNER,
          UMS_AGENCY_ADMIN,
        ],
      },
      {
        subMenuName: "Creatives Builder",
        subMenuLink: "/creative-builder",
        subMenuIcon: () => <ActionIconRender iconType="notification" />,
        permission: [
          CREATIVE_TRACKING_CREATIVE_DIRECTOR,
          CREATIVE_TRACKING_MEDIA_BUYER,
          CREATIVE_TRACKING_BUSINESS_DEVELOPER,
          CREATIVE_TRACKING_GRAPHIC_DESIGNER,
          UMS_AGENCY_ADMIN,
        ],
      },
      {
        subMenuName: "Creative Reports",
        subMenuLink: "/creative-reports",
        subMenuIcon: () => <ActionIconRender iconType="creativeReport" />,
        // permission: I_CAMPAIGNS_LAUNCH,
      },
      {
        subMenuName: "Media Library",
        subMenuLink: "/media-library",
        subMenuIcon: () => <ActionIconRender iconType="folder" />,
        // permission: I_CAMPAIGNS_LAUNCH,
      },
    ],
  },
];

export default creativePath;
