import {
  MacCommandOutlined,
  PicLeftOutlined,
  AlignLeftOutlined,
  ProfileOutlined,
  GatewayOutlined,
  CodeSandboxOutlined,
  BlockOutlined,
  PicCenterOutlined,
  GlobalOutlined,
  FileDoneOutlined,
  UnorderedListOutlined,
  ShopOutlined,
  BookOutlined,
} from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";
import {
  CMS_MANAGE_AGENCY,
  CMS_MANAGE_BLOCK,
  CMS_MANAGE_BRAND,
  CMS_MANAGE_CATEGORY,
  CMS_MANAGE_DOMAIN,
  CMS_MANAGE_LIST,
  CMS_MANAGE_PRODUCT,
  CMS_MANAGE_REVIEW,
  CMS_MANAGE_STORE,
  CMS_MANAGE_STORY,
  CMS_MANAGE_USER,
} from "../../UserPermission/userPermissionList";

const cmsPath = [
  {
    name: "CMS",
    link: "/cms",
    icon: MacCommandOutlined,
    subMenu: [
      {
        subMenuName: "Stories",
        subMenuLink: "/stories",
        subMenuIcon: PicLeftOutlined,
        permission: CMS_MANAGE_STORY,
      },
      {
        subMenuName: "ListV2",
        subMenuLink: "/listsV2",
        subMenuIcon: PicLeftOutlined,
        permission: CMS_MANAGE_STORY,
      },
      // TODO: May be removed in futrue
      // {
      //   subMenuName: "ListV1",
      //   subMenuLink: "/listsV1",
      //   subMenuIcon: PicLeftOutlined,
      //   permission: CMS_MANAGE_STORY,
      // },
      {
        subMenuName: "ListV1",
        subMenuIcon: AlignLeftOutlined,
        isCollapsible: true,
        permission: CMS_MANAGE_LIST,
        subMenus: [
          {
            subMenuName: "Products",
            subMenuLink: "/products",
            subMenuIcon: CodeSandboxOutlined,
            permission: CMS_MANAGE_PRODUCT,
          },
          {
            subMenuName: "Block",
            subMenuLink: "/block",
            subMenuIcon: BlockOutlined,
            permission: CMS_MANAGE_BLOCK,
          },
          {
            subMenuName: "ListV1",
            subMenuLink: "/listsV1",
            subMenuIcon: ProfileOutlined,
            permission: CMS_MANAGE_LIST,
          },
        ],
      },
      {
        subMenuName: "Store",
        subMenuLink: "/store",
        subMenuIcon: ShopOutlined,
        permission: CMS_MANAGE_STORE,
      },
      {
        subMenuName: "Brands",
        subMenuLink: "/brands",
        subMenuIcon: BookOutlined,
        permission: CMS_MANAGE_BRAND,
      },
      {
        subMenuName: "Pages",
        subMenuLink: "/pages",
        subMenuIcon: PicCenterOutlined,
        permission: CMS_MANAGE_STORY,
      },
      {
        subMenuName: "Domains",
        subMenuLink: "/domain",
        subMenuIcon: GlobalOutlined,
        permission: CMS_MANAGE_DOMAIN,
      },
      {
        subMenuName: "Categories",
        subMenuLink: "/categories",
        subMenuIcon: UnorderedListOutlined,
        permission: CMS_MANAGE_CATEGORY,
      },
      {
        subMenuName: "Configuration",
        subMenuIcon: () => <ActionIconRender iconType="team" />,
        permission: CMS_MANAGE_DOMAIN,
        isCollapsible: true,
        subMenus: [
          {
            subMenuName: "Agencies",
            subMenuLink: "/agency",
            subMenuIcon: () => <ActionIconRender iconType="team" />,
            permission: CMS_MANAGE_DOMAIN,
          },
          {
            subMenuName: "Users",
            subMenuLink: "/user",
            subMenuIcon: () => <ActionIconRender iconType="user" />,
            permission: CMS_MANAGE_DOMAIN,
          },
        ],
      },
      // TODO: May be removed in future, as we are in development phase so we need these back.
      // {
      //   subMenuName: "Lists",
      //   subMenuIcon: AlignLeftOutlined,
      //   isCollapsible: true,
      //   permission: CMS_MANAGE_LIST,
      //   subMenus: [
      //     {
      //       subMenuName: "Products",
      //       subMenuLink: "/products",
      //       subMenuIcon: CodeSandboxOutlined,
      //       permission: CMS_MANAGE_PRODUCT,
      //     },
      //     {
      //       subMenuName: "Block",
      //       subMenuLink: "/block",
      //       subMenuIcon: BlockOutlined,
      //       permission: CMS_MANAGE_BLOCK,
      //     },
      //     {
      //       subMenuName: "Lists",
      //       subMenuLink: "/lists",
      //       subMenuIcon: ProfileOutlined,
      //       permission: CMS_MANAGE_LIST,
      //     },
      //   ],
      // },
      // {
      //   subMenuName: "Pros/Cons",
      //   subMenuLink: "/pros-cons",
      //   subMenuIcon: AppstoreOutlined,
      //   permission: CMS_MANAGE_PRODUCT,
      // },

      // {
      //   subMenuName: "Reviews",
      //   subMenuLink: "/reviews",
      //   subMenuIcon: FileDoneOutlined,
      //   permission: CMS_MANAGE_REVIEW,
      // },

      // {
      //   subMenuName: "Collections",
      //   subMenuIcon: GatewayOutlined,
      //   isCollapsible: true,
      //   permission: CMS_MANAGE_CATEGORY,
      //   subMenus: [
      //     {
      //       subMenuName: "Categories",
      //       subMenuLink: "/categories",
      //       subMenuIcon: UnorderedListOutlined,
      //       permission: CMS_MANAGE_CATEGORY,
      //     },
      // {
      //   subMenuName: "Brands",
      //   subMenuLink: "/brands",
      //   subMenuIcon: BookOutlined,
      //   permission: CMS_MANAGE_BRAND,
      // },
      // {
      //   subMenuName: "Store",
      //   subMenuLink: "/store",
      //   subMenuIcon: ShopOutlined,
      //   permission: CMS_MANAGE_STORE,
      // },
      //   ],
      // },
    ],
  },
];

export default cmsPath;
