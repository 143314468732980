import { Form, Button, Row, Col, DatePicker } from "antd";
import Loader from "components/loader";
import { Formik } from "formik";
import LookTextInput from "components/forms/LookTextInput";
import { useSelector } from "react-redux";
import DebugPre from "components/DebugPre";
import SelectInput from "components/forms/SelectInput";
import moment from "moment";

export default function MainForm(props) {
  const { voluumCategories = [], voluumCategories_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const dateFormat = "YYYY-MM-DD";
  const {
    initialValues,
    validationSchema,
    onSubmit,
    btnText = "Add",
    loading = false,
    selectedDate,
    setSelectedDate = () => {},
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <SelectInput
                formik
                search
                label="Categories"
                name="category_id"
                placeholder="Select category"
                value={values.category_id}
                onChange={setFieldValue}
                options={voluumCategories.map(({ id, name }) => ({ name: name, value: id }))}
                loader={voluumCategories?.length === 0 && voluumCategories_loading}
              />
            </Col>

            <Col span={24}>
              <LookTextInput
                formik
                name="revenue"
                label="X Revenue"
                className="look-form-input"
                placeholder="Enter The X-Revenue"
              />
            </Col>
            <Col span={24}>
              <Form.Item label="Date" className="look-form-input">
                <DatePicker
                  value={selectedDate ? moment(selectedDate, dateFormat) : null}
                  format={dateFormat}
                  onChange={(e, f) => setSelectedDate(f)}
                  disabledDate={(value) => value > moment(selectedDate)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <LookTextInput
                formik
                type="textarea"
                name="notes"
                label="Notes"
                className="look-form-input"
                placeholder="Enter The Notes"
              />
            </Col>
          </Row>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
          <DebugPre content={[{ contentObjects: [{ values }] }]} />
        </Form>
      )}
    </Formik>
  );
}
