import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { useEffect, useRef, useState } from "react";
import DebugPre from "components/DebugPre";
import { Col, Pagination, Row, Image, Skeleton, Checkbox, Segmented, Tooltip, Select } from "antd";
import LookButton from "components/LookButton";
import cx from "cx";
import LookDrawer from "components/LookDrawer";
import { CT_VIDEOS_BY_CATEGORY, GQL_ADD_FEATURED_VIDEOS } from "shared/gql/gqlSchema/creativeGql";
import useUploadFiles from "./useUploadFiles";
import UploadThumb from "../uploadThumb";
import { CreativeSelectionKey_Video } from "../AdGroup";
import LookModal from "components/LookModal";
import { GQL_CT_ADD_VIDEO_THUMBS, GQL_CT_DELETE_VIDEOS } from "shared/gql/gqlSchema/mediaGql";
import { useLookModal } from "components/LookModal";
import VideoThumbnail from "./VideoThumbnail";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import { CREATIVE_MIME_TYPE_VIDEO, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import { useLaunchContext } from "components/Launch/Context";
import ActionIconRender from "components/ActionIconRender";
import ComplianceModal from "pages/dashboardPages/Creatives/Compliance/ComplianceModal";
import {
  MEDIA_ADD_CREATIVE_VIDEO,
  UPDATE_VIDEO_COMPLANCE,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import TagModal from "./TagModal";
import PopoverWrapper from "components/popoverWrapper";
import {
  FEATURED,
  MY_VIDEOS,
  RECOMMENDED,
  SELECTED_VIDEOS,
} from "pages/dashboardPages/Creatives/creativesEnum";
import { RECORD_UPDATED } from "shared/enum/apiResponse";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import { GQL_API_RESPONSE_STATUS_WARNING } from "shared/SharedKeys";
import ForceDeleteModal from "../ForceDeleteModal";
import useRecommendedHooks from "../hooks/useRecommendedHooks";
import { useSelector } from "react-redux";
import moment from "moment";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import { dateFormat } from "reducers/userSettings";
import SimilarImages from "../SimilarImages";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";

function MediaItem(props) {
  const {
    categoryId,
    selected = false,
    groupSelection,
    onSelect,
    creatiVideo_refetch,
    productId,
    setCreativesImages,
    index,
    name,
    categoryName,
    uploading = false,
    creative = {},
    setVideos,
    onOpenDeleteModal,
    tags = [],
    launch,
    hasCreativeUploadPermission,
    onOpenCompliantModal = () => {},
    handleClickDownload = () => {},
    setVisibleCompliantModal,
    setisEdit,
    s3Url = "",
    thumbUrl,
    videoId,
    id,
    compliance,
    tagDataExtractor = () => {},
    openTagModal = () => {},
    setDeleteModalPropsData = () => {},
    ctAddFeatured,
    setComplianceValue = () => {},
  } = props;

  const imageRef = useRef(null);
  const [resolution, setResolution] = useState(null);
  const [visibleSimilarDrawer, setVisibleSimilarDrawer] = useState(false);
  const [activeSimilarTab, setActiveSimilarTab] = useState("similarImage");
  const similarTabContent = [
    {
      id: "similarImage",
      noSpace: true,
      hoverTitle: "Similar Creatives",
      icon: () => <ActionIconRender iconType="image" />,
      component: <SimilarImages videoId={videoId} />,
    },
  ];
  const onImgLoad = (e) => {
    //if img is loaded
    if (e.target.src && imageRef.current) {
      setResolution(`${imageRef.current?.naturalWidth}x${imageRef.current?.naturalHeight}`);
    }
  };
  const similarDrawerProps = {
    textBreadcrumb: ["Creative Dashboard / Creative Report / Similar Video"],
    visible: visibleSimilarDrawer,
    onClose: () => {
      setVisibleSimilarDrawer(false);
    },
    size: 1200,
  };
  const [clUpdateVideo, { loading: loading_clUpdateVideo }] = useCustomMutation(
    GQL_CT_ADD_VIDEO_THUMBS,
    {
      variables: { productId: productId, categoryId: categoryId, videoId: id, categoryName },
      onCompleted: (e) => {
        const element = e?.ctAddVideoThumbs?.data;
        if (element) {
          setCreativesImages((prev) => {
            const tempData = prev;
            tempData[index].thumbUrl = element[0].thumbUrl;
            return [...tempData];
          });
          openNotification({ type: "success", message: RECORD_UPDATED });
          closeModal();
          closePreviewModal();
          creatiVideo_refetch();
          prviewClose.close && prviewClose.close();
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const getImageResponse = (imagesData) => {
    clUpdateVideo({ variables: { thumbUrl: imagesData[0]?.public_url, videoId } });
  };

  const isSelection = { onClick: onSelect };

  const [visible, setVisible] = useState(false);
  const [prviewClose, setPreviewCloseModal] = useState({});

  const [playModal, setPlayModal] = useState(false);
  const onClose = () => {
    setVisible(false);
  };

  const closePreviewModal = (closePreviewM) => {
    closePreviewM && setPreviewCloseModal({ close: closePreviewM });
  };

  const { handleBlobUpload, uploadLoading } = useUploadFiles({ name: categoryName });

  const getBlob = async (blob) => {
    await handleBlobUpload(
      [blob],
      [s3Url.substring(s3Url.lastIndexOf("/") + 1)?.split(".")?.[0] + "thumb.png"]
    ).then((data) => {
      getImageResponse(data);
    });
  };

  const poverWrapperOptions = [
    {
      option: "notification",
      title: "Compliance",
      optionProps: {
        onClick: () => {
          onOpenCompliantModal([videoId]);
          setComplianceValue((prev) => ({
            ...prev,
            compliance: compliance?.complianceStatus,
            complianceDescription: compliance?.notes,
          }));
        },
      },
    },
    {
      option: "download",
      title: "Download Video",
      optionProps: {
        onClick: () => handleClickDownload([s3Url], CREATIVE_MIME_TYPE_VIDEO),
      },
    },
    {
      option: "play",
      title: "Play video",
      optionProps: {
        onClick: () => setPlayModal(true),
      },
    },
    {
      option: "upload",
      title: thumbUrl ? "Update Thumb" : "Upload Thumbnail",
      optionProps: {
        onClick: () => {
          setVisible(true);
        },
      },
    },
    {
      option: "tag",
      title: "Edit Tags",
      optionProps: {
        onClick: () => {
          setVideos([{ id: videoId, s3Url, tags: tags || [] }]);
          tagDataExtractor(videoId);
          openTagModal();
          setisEdit(true);
          setDeleteModalPropsData([{ id: videoId, tags: tags || [] }]);
        },
      },
    },
    {
      option: "important",
      title: "Favorite",
      optionProps: {
        onClick: () => {
          ctAddFeatured({
            variables: { videos: [videoId], productId, categoryId, categoryName },
          });
        },
      },
    },
    ...(!launch
      ? [
          {
            option: "delete",
            title: "Delete Video",
            optionProps: {
              onClick: () => {
                onOpenDeleteModal([videoId]);
              },
            },
          },
        ]
      : []),
    {
      option: "image",
      title: "Similar Videos",
      optionProps: {
        onClick: () => {
          setVisibleSimilarDrawer(true);
        },
      },
    },
  ];

  const complianceIcon = (status) => {
    switch (status) {
      case "YES":
        return "greenTick";
      case "NO":
        return "redCross";
      case "EXCEPTION":
        return "yellowTick";
      case "PARTIALLY":
        return "orangeTick";
      case "PENDING":
        return "grayTick";
      default:
        return "grayTick";
    }
  };

  const { modal, openModal, closeModal } = useLookModal({ title: "Generate thumb", footer: false });

  return (
    <>
      <LookDrawer size={700} visible={visible} onClose={onClose} textBreadcrumb={["Thumb"]}>
        {visible && (
          <div>
            <div className="mx-auto mb-22" style={{ maxWidth: 400 }}>
              {thumbUrl && (
                <div className="d-table mt-22 mx-auto">
                  <Image
                    style={{ maxHeight: 300, objectFit: "contain" }}
                    src={thumbUrl}
                    preview={false}
                  />
                </div>
              )}
            </div>
            <Row>
              <Col span={12}>
                <UploadThumb
                  id={id}
                  videoId={videoId}
                  videoUrl={s3Url}
                  thumbUrl={thumbUrl}
                  productId={productId}
                  categoryId={categoryId}
                  onClose={onClose}
                  creatiVideo_refetch={creatiVideo_refetch}
                  categoryName={categoryName}
                />
              </Col>
              <Col span={12} className="flex-center">
                <LookButton
                  className="mr-8"
                  tooltipTitle="Take snapshots"
                  iconType="image"
                  type="primary"
                  onClick={() => openModal()}
                >
                  Generate Thumb from Video
                </LookButton>
                {modal(
                  <VideoThumbnail
                    videoUrl={s3Url}
                    getBlob={getBlob}
                    loading={uploadLoading}
                    closePreviewModal={closePreviewModal}
                    creatiVideo_refetch={creatiVideo_refetch}
                  />
                )}
              </Col>
            </Row>
          </div>
        )}
      </LookDrawer>
      <LookDrawer {...similarDrawerProps}>
        {visibleSimilarDrawer && (
          <LookDrawerMainSideBar
            value={activeSimilarTab}
            onChange={setActiveSimilarTab}
            tabContent={similarTabContent}
          />
        )}
      </LookDrawer>
      {playModal && (
        <LookModal
          title="Play Video"
          visible={playModal}
          onCancel={() => setPlayModal(false)}
          footer={false}
        >
          {playModal && <video height={"300px"} className="w-100" src={s3Url} controls autoPlay />}
        </LookModal>
      )}
      {uploading ? (
        <div className="position-relative w-100">
          <div>
            <Skeleton.Image
              className="w-100 "
              style={{ width: "100%", height: "130px" }}
              active={true}
            />
          </div>
        </div>
      ) : (
        <div
          className={cx("creative-image-selection  ant-image-ration-1-1", {
            selected,
          })}
          {...isSelection}
        >
          <div className="position-relative w-100" style={{ paddingTop: uploading ? 0 : "70%" }}>
            {thumbUrl ? (
              <>
                <img
                  ref={imageRef}
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: "contain", top: 0, left: 0, background: "#000" }}
                  src={thumbUrl}
                  alt="no img"
                  onLoad={onImgLoad}
                />
                <span
                  className="position-absolute"
                  style={{ fontSize: "12px", bottom: "8px", right: "3px" }}
                >
                  {resolution}
                </span>
              </>
            ) : (
              <video
                className="position-absolute w-100 h-100"
                style={{ objectFit: "contain", top: 0, left: 0, background: "#000" }}
                src={s3Url}
              />
            )}
          </div>
        </div>
      )}
      <div className={cx("creative-edit", { launch })}>
        <>
          <div className="d-flex">
            <Checkbox className="creative-select" onChange={onSelect} checked={selected} />
          </div>
          {!uploading && (
            <div className="d-flex">
              <>
                <Tooltip title={compliance?.complianceStatus}>
                  <ActionIconRender
                    iconType={complianceIcon(compliance?.complianceStatus)}
                    style={{ margin: "3px 3px 0px 0px" }}
                  />
                </Tooltip>
                <Tooltip title="More">
                  <PopoverWrapper options={poverWrapperOptions}>
                    <ActionIconRender iconType="more" style={{ fontSize: "15px" }} />
                  </PopoverWrapper>
                </Tooltip>
              </>
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default function CreativeVideoUpload(props) {
  const {
    id,
    title = "",
    creativeType,
    groupSelection = false,
    selectedCreatives,
    handleSelectedCreatives,
    setSelectedData,
    selectedData,
    serviceType = "",
    isTiktokPlatformLaunch,
    // vertical,
    keyword_title,
    keyword_body,
    productId,
    findCommonDynamicAds = () => {},
    categoryId,
    categoryName,
    subCategory,
    subCategoryId,
    verticalId,
    adSetindex,
    launch,
    campaignIndex,
    isKeyword = false,
    hasCreativeUploadPermission = false,
    isTemp = false,
    handleClickDownload = () => {},
  } = props;
  const { handleAdgroup, campaignObject, removeDuplicatesforObj } = useLaunchContext();
  const [visibleForceDeleteModal, setVisibleForceDeleteModal] = useState(false);
  const [creativesImages, setCreativesImages] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [createdbyMe, setCreatedByMe] = useState("All");
  const [video, setVideos] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [visibleSimilarDrawer, setVisibleSimilarDrawer] = useState(false);
  const [tags, setTags] = useState([]);
  const [visibleCompliantModal, setVisibleCompliantModal] = useState(false);
  const [fileName, setFileName] = useState([]);
  const [complianceValue, setComplianceValue] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 4 }, () => 10));
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const defaultDate = [moment().subtract(7, "d").format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);
  const [timeZone, setTimeZone] = useState({ value: dateRangeTimezone?.timeZone?.value });
  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  const onChange = (checked) => {
    setCreativesImages([]);
    setCreatedByMe(checked);
  };

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  let queryVars = {
    productId,
    categoryId,

    page: currentPage,
    size: pageSize,
  };
  const onOpenCompliantModal = (id) => {
    setVisibleCompliantModal(true);
    setComplianceValue((prev) => ({ ...prev, id }));
  };

  const variables = {
    productId,
    categoryId,
    categoryName,
    imageType: creativeType,
    // ...subCategory,
    // ...(verticalId && { verticalId }),
  };
  const { creatives_loading, fetchFbCreatives, fetchTiktokCreatives } = useRecommendedHooks({
    queryVars: {
      category_id: categoryId,
      product_id: productId,
      page: currentPage,
      size: pageSize,
      start: rangeDates[0],
      end: rangeDates[1],
      timezone: timeZone?.value,
      group_by: CREATIVE_TYPE_VIDEO,
    },
    setCreatives: setCreativesImages,
    setTotalCount,
  });
  function handleCompliance(id) {
    ctVideoCompliance({
      variables: {
        categoryId,
        productId,
        videoIds: complianceValue?.id,
        complianceStatus: complianceValue?.compliance,
        notes: complianceValue?.complianceDescription,
      },
    });
  }

  // if (serviceType === SERVICE_TYPE_SAS) {
  //   queryVars = isKeyword
  //     ? {
  //         ...queryVars,
  //         subCategoryId: id,
  //         categoryId: category?.id,
  //       }
  //     : { ...queryVars, verticalId: vertical?.id };
  // }

  const [fetchVideos, { loading: creativesVideo_loading, refetch: creatiVideo_refetch }] =
    useCustomLazyQuery(CT_VIDEOS_BY_CATEGORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ctGetCreativeVideos?.data;
        setTotalCount(e?.ctGetCreativeVideos?.pageInfo?.totalRecords);
        if (element) {
          setCreativesImages(
            element?.map((data) => {
              const { video, ...propswithoutVideo } = data;
              return { ...propswithoutVideo, ...video };
            })
          );
          setTags([]);
        }
      },
      variables: { ...queryVars },
    });
  const selectedVideos =
    campaignObject?.[campaignIndex]?.adSets[adSetindex]?.adCreatives?.video || [];
  const [selectedImages, setSelectedImages] = useState(launch ? selectedVideos : []);
  const handleSelectImage = (id) => {
    let data;
    if (selectedImages?.map((i) => i.id === id)?.includes(true)) {
      const refilterData = selectedImages?.filter((img) => img?.id !== id);

      setSelectedImages(refilterData);
      data = refilterData;
    } else {
      setSelectedImages([
        ...selectedImages,
        {
          id: creativesImages.filter((img) => img?.id === id)[0]?.id,
          s3Url: creativesImages.filter((img) => img?.id === id)[0]?.r2Url,
          thumbUrl: creativesImages.filter((img) => img?.id === id)[0]?.currentThumb?.r2Url,
          tags: creativesImages.filter((img) => img?.id === id)[0]?.tags,
          name: creativesImages.filter((img) => img?.id === id)[0]?.name,
        },
      ]);
      data = [
        ...selectedImages,
        {
          id: creativesImages.filter((img) => img?.id === id)[0]?.id,
          s3Url: creativesImages.filter((img) => img?.id === id)[0]?.r2Url,
          thumbUrl: creativesImages.filter((img) => img?.id === id)[0]?.currentThumb?.r2Url,
          tags: creativesImages.filter((img) => img?.id === id)[0]?.tags,
          name: creativesImages.filter((img) => img?.id === id)[0]?.name,
        },
      ];
    }
    if (launch) {
      const args = !isTemp ? [campaignIndex, adSetindex] : [];
      handleAdgroup({ video: data }, ...args);
    }
  };
  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });

  const [ctAddVideos, { loading: loading_update, refetch: creativesImage_added_refetch }] =
    useCustomMutation(MEDIA_ADD_CREATIVE_VIDEO, {
      variables: variables,
      onCompleted: (e) => {
        const element = e?.ctAddCreativeVideos;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          creatiVideo_refetch();
          closeTagModal();
          setTags([]);
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [ctAddFeatured, { loading: loading_featured }] = useCustomMutation(
    GQL_ADD_FEATURED_VIDEOS,
    {
      onCompleted: (e) => {
        const element = e?.ctAddFeaturedCreativeVideos;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || RECORD_UPDATED,
            key: "success",
          });
          creatiVideo_refetch();
          closeTagModal();
          setTags([]);
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const getImageResponse = (videoData) => {
    ctAddVideos({
      variables: {
        videos: videoData?.map((data, index) => ({
          videoUrl: data?.videoUrl,
          thumbUrl: data?.thumbUrl,
          videoName: fileName[index],
          // tags: !tags?.commonTags?.length ? (tags?.length ? [...tags[index]] : []) : [],
        })),
        // commonTags: tags?.commonTags,
      },
    });
  };

  useEffect(() => {
    if (createdbyMe === RECOMMENDED) {
      fetchFbCreatives();
      fetchTiktokCreatives();
    } else {
      fetchVideos({
        variables: {
          createdByMe: createdbyMe === MY_VIDEOS,
          featuredVideos: createdbyMe === FEATURED,
        },
      });
    }
  }, [createdbyMe]);

  const debugContent = [{ isRow: true, contentObjects: [{ creativesImages }] }];
  const mediaItemProps = {
    groupSelection,
    creativeType,
    setVideos,
    openTagModal,
    launch,
    hasCreativeUploadPermission,
    handleClickDownload,
    complianceValue,
    setComplianceValue,
    visibleCompliantModal,
    setVisibleCompliantModal,
    handleCompliance,
  };

  const { uploadView, uploadLoading: s3Loading } = useUploadFiles({
    name: categoryName,
    setFileName,
    getImageResponse,
    creativeType,
    creatiVideo_refetch,
    videoCheck: true,
    loading: loading_update,
    setCreativesImages,
    setFile: setVideos,
    setIsUpload,
  });

  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const handleChange = (newValue) => {
    setTags(newValue);
  };

  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => onOpenDeleteModal(e?.map((img) => img?.id)),
    },
    {
      option: "tag",
      title: "Add tags",
      onClick: (e) => {
        setIsUpload(false);
        setVideos(e);
        openTagModal();
      },
    },
    {
      option: "download",
      title: "Download Creatives",
      onClick: (e) => {
        handleClickDownload(
          e?.map((data) => data?.s3Url),
          CREATIVE_MIME_TYPE_VIDEO
        );
      },
    },
    {
      option: "notification",
      title: "Compliance",
      onClick: (e) => {
        onOpenCompliantModal(e?.map((data) => data?.id));
      },
    },
    {
      option: "important",
      title: "Mark Favourite",
      onClick: (e) => {
        ctAddFeatured({
          variables: { videos: e?.map((data) => data?.id), productId, categoryId, categoryName },
        });
      },
    },
  ];
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const onSubmitDeleteModal = () => {
    deleteCall({
      variables: {
        videoIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: false,
      },
    });
  };
  const onSubmitForceDeleteModal = () => {
    deleteCall({
      variables: {
        videoIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: true,
      },
    });
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };
  const onCloseForceDeleteModal = () => {
    setDeleteModalPropsData([]);
    setVisibleForceDeleteModal(false);
  };
  function selectedRowsClear() {
    setSelectedImages([]);
  }
  const [deleteCall, { loading: deleteLoader }] = useCustomMutation(GQL_CT_DELETE_VIDEOS, {
    onCompleted(e) {
      const element = e?.ctDeleteCreativeVideos;
      setDeleteModalVisible(false);
      if (element?.status === "SUCCESS") {
        creatiVideo_refetch();
        openNotification({ type: "success", message: "Record Deleted Success" });
        selectedRowsClear();
      } else if (element?.status === GQL_API_RESPONSE_STATUS_WARNING && isUmsAgencyAdmin) {
        setVisibleForceDeleteModal(true);
      } else {
        openNotification({
          type: "error",
          message: element?.message,
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Deleted Not Success",
      });
    },
  });
  const onCloseCompliantModal = () => {
    setVisibleCompliantModal(false);
    setComplianceValue({});
    // setCampaignModalPropsData([]);
  };
  const [ctVideoCompliance, { loading: loading_Compliance_Add, refetch: refetch_Compliance_Add }] =
    useCustomMutation(UPDATE_VIDEO_COMPLANCE, {
      variables: variables,
      onCompleted: (e) => {
        const element = e?.ctUpdateVideoCompliance;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          setComplianceValue({});
          setVisibleCompliantModal(false);
          creatiVideo_refetch();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  const bulkActionProps = {
    selectedRows: selectedImages,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const deleteModalProps = {
    title: "Delete Creatives",
    message: "Do you really want to delete this creatives?",
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };
  const forceDeleteModalProps = {
    title: "Delete Creatives",
    visible: visibleForceDeleteModal,
    onSubmit: onSubmitForceDeleteModal,
    onCancel: onCloseForceDeleteModal,
    okokText: "Delete",
    loader: deleteLoader,
  };
  let ads = [];
  if (launch) {
    ads = campaignObject[campaignIndex]?.adSets[adSetindex]?.adCreatives?.video || [];
  }
  const finalVideos = launch
    ? createdbyMe === SELECTED_VIDEOS
      ? removeDuplicatesforObj(
          selectedVideos?.map((data) => ({
            ...data,
            r2Url: data?.s3Url,
            currentThumb: { r2Url: data?.thumbUrl },
          })),
          "id"
        )
      : !isTiktokPlatformLaunch
      ? creativesImages?.sort((a, b) => {
          const isPresentInAdsA = ads?.findIndex(({ id }) => id === a?.id) !== -1;
          const isPresentInAdsB = ads?.findIndex(({ id }) => id === b?.id) !== -1;

          if (isPresentInAdsA && !isPresentInAdsB) {
            return -1; // a comes before b
          } else if (!isPresentInAdsA && isPresentInAdsB) {
            return 1; // b comes before a
          }

          return 0; // no change in order
        })
      : creativesImages
    : creativesImages;
  const complianceModalProps = {
    visible: visibleCompliantModal,
    onCancel: onCloseCompliantModal,
    title: "Compliance Details",
    onSubmit: () => handleCompliance(),
    okText: "Submit",
  };
  const tagDataExtractor = (id) => {
    const tagsArray = finalVideos?.filter((data) => data?.id === id)?.[0]?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };

  return (
    <>
      <div className={!groupSelection && !launch ? cx("p-20") : ""}>
        <div className={cx("creatives-media-list", { launch })}>
          <div
            className={cx("d-flex mb-20", {
              " mb-20 mt-20": launch,
              " mt-20 mb-20": groupSelection,
            })}
          >
            <Tooltip title="Click search button to search">
              <ActionIconRender
                iconType="info-circle"
                className="mr-10 d-flex align-items-center"
              />
            </Tooltip>
            <div
              className={cx("look-table-search", "flex-between")}
              style={{
                border: "1px solid #404040",
              }}
            >
              <Select
                mode="tags"
                bordered={false}
                placeholder={"Search by tags"}
                style={{ width: "200px" }}
                onChange={handleChange}
                maxTagCount="responsive"
              />
              <LookButton
                tooltipTitle="Click here to search"
                className="filter-btn ml-8"
                iconType="search"
                onClick={() => {
                  fetchVideos({
                    variables: {
                      tags,
                      createdByMe: createdbyMe === MY_VIDEOS,
                      featuredVideos: createdbyMe === FEATURED,
                    },
                  });
                }}
              />
            </div>
            <Segmented
              className="custom-segmented ml-10"
              options={[
                "All",
                MY_VIDEOS,
                FEATURED,
                RECOMMENDED,
                ...(launch ? [SELECTED_VIDEOS] : []),
              ]}
              value={createdbyMe}
              onChange={onChange}
            />
            {createdbyMe === RECOMMENDED && (
              <DateRangePersist
                isTimezone
                {...{
                  noStore: true,
                  defaultDate: defaultDate,
                  defaultTimeZone: dateRangeTimezone?.timeZone?.value,
                  onChange: handleDateRange,
                }}
              />
            )}
          </div>

          <div
            className={cx("creative-media", { launch: launch && !groupSelection, groupSelection })}
          >
            <Row gutter={[24, 24]}>
              {
                <Col span={4} xs={12} md={8} xl={6}>
                  <div
                    className="creative-video"
                    onClick={() => {
                      if (!launch) setSelectedImages([]);
                    }}
                  >
                    {uploadView()}
                  </div>
                </Col>
              }
              {visibleForceDeleteModal && (
                <LookModal {...forceDeleteModalProps}>
                  <ForceDeleteModal />
                </LookModal>
              )}
              {!launch && (
                <>
                  <LookTableBulkAction {...bulkActionProps} />
                  <LookTableRecordDeleteModal {...deleteModalProps} />
                </>
              )}
              {(creativesVideo_loading || s3Loading || creatives_loading) &&
                skeletonSize?.map((e) => (
                  <Col span={4} xs={12} sm={8} md={6} xl={6}>
                    <div className={cx("creative-image-selection ant-image-ration-1-1")}>
                      <Skeleton.Node active={true} className="skeleton-video">
                        <ActionIconRender iconType="video-camera" style={{ fontSize: "30px" }} />
                      </Skeleton.Node>
                    </div>
                  </Col>
                ))}
              {finalVideos?.map((data, index) => {
                const selectedImg = selectedImages?.filter((video) => video?.id === data?.id);

                return (
                  <Col key={"mediaItems" + index} xs={12} md={8} xl={6}>
                    <MediaItem
                      {...mediaItemProps}
                      videoId={data?.id}
                      thumbUrl={data?.currentThumb?.r2Url}
                      s3Url={data?.r2Url}
                      name={data?.name}
                      compliance={data?.compliance}
                      productId={productId}
                      categoryId={categoryId}
                      serviceType={serviceType}
                      categoryName={categoryName}
                      creatiVideo_refetch={creatiVideo_refetch}
                      onOpenDeleteModal={onOpenDeleteModal}
                      onOpenCompliantModal={onOpenCompliantModal}
                      setisEdit={setisEdit}
                      tagDataExtractor={tagDataExtractor}
                      openTagModal={openTagModal}
                      ctAddFeatured={ctAddFeatured}
                      setComplianceValue={setComplianceValue}
                      setDeleteModalPropsData={setDeleteModalPropsData}
                      onSelect={() => {
                        if (groupSelection) {
                          handleSelectedCreatives(CreativeSelectionKey_Video, data?.id);
                          setSelectedData({
                            ...selectedData,
                            video: [
                              ...selectedData?.video,
                              {
                                id: data?.id,
                                s3Url: data?.s3Url,
                                thumbUrl: data?.currentThumb?.r2Url,
                              },
                            ],
                          });
                        } else {
                          if (launch) {
                            if (data?.currentThumb?.r2Url) {
                              handleSelectImage(data?.id);
                            } else {
                              openNotification({
                                type: "error",
                                message: "Please upload thumb url",
                              });
                            }
                          } else {
                            handleSelectImage(data?.id);
                          }
                        }
                      }}
                      selected={
                        groupSelection
                          ? selectedCreatives?.[CreativeSelectionKey_Video]?.includes(data?.id)
                          : launch
                          ? isTemp
                            ? findCommonDynamicAds(CREATIVE_TYPE_VIDEO, data?.id)
                            : campaignObject[campaignIndex]?.adSets[adSetindex]?.adCreatives?.video
                                ?.map((i) => i.id)
                                ?.includes(data?.id)
                          : selectedImg?.length > 0
                      }
                      setCreativesImages={setCreativesImages}
                      index={index}
                    />
                  </Col>
                );
              })}

              {visilbleTagModal &&
                tagModal(
                  <TagModal
                    files={video}
                    variables={variables}
                    creatives_refetch={creatiVideo_refetch}
                    setTags={setTags}
                    tagsData={tagsData}
                    setTagsData={setTagsData}
                    tags={tags}
                    closeTagModal={closeTagModal}
                    selectedRowsClear={selectedRowsClear}
                    creativeType={CREATIVE_TYPE_VIDEO}
                  />
                )}
            </Row>
          </div>

          <DebugPre content={debugContent} />
        </div>
        <LookModal {...complianceModalProps}>
          {visibleCompliantModal && (
            <ComplianceModal
              setComplianceValue={setComplianceValue}
              complianceValue={complianceValue}
            />
          )}
        </LookModal>
        {createdbyMe !== SELECTED_VIDEOS && (
          <div className="p-8 look-bottom-pagination">
            <Pagination
              defaultCurrent={1}
              total={totalCount}
              pageSize={pageSize}
              current={currentPage}
              onChange={(page, size) => handlePageChange(page, size)}
              pageSizeOptions={["10", "20", "50"]}
              showSizeChanger
            />
          </div>
        )}
      </div>
    </>
  );
}
