import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "../MainForm";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { VOLUUM_PUBLISHER_UPDATE } from "shared/gql/gqlSchema/fb360Gql";
import { PLATFORM_ID_FACEBOOK } from "shared/SharedKeys";

export default function UpdateForm(props) {
  const { refetch = () => {}, id: propId, token: propToken, user_id: propUserId } = props;

  const [voluum_publisher_update, { loading }] = useCustomMutation(VOLUUM_PUBLISHER_UPDATE, {
    onCompleted(e) {
      refetch();
      openNotification({ type: "success", message: "Record updated Successfully!" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit(values, { resetForm }) {
    voluum_publisher_update({
      variables: { ...values, id: propId },
    });
  }

  const initialValues = {
    token: propToken,
    platform_id: PLATFORM_ID_FACEBOOK,
    user_id: propUserId,
  };

  const validationSchema = yup.object({
    token: yup.string().required().label("Token"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
  };

  return (
    <>
      <MainFrom {...formProps} />
    </>
  );
}
