import { Result } from "antd";
import Loader from "components/loader";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { G360_CONNENCT_GOOGLE_ACCOUNT_BY_CODE } from "shared/gql/gqlSchema/g360Gql";
import { openNotification } from "utils";
export default function OAuthCallback() {
  const location = useLocation();
  const urlString = location?.search;
  const params = new URLSearchParams(urlString);
  let int = useRef();
  const [googleConnect] = useCustomMutation(G360_CONNENCT_GOOGLE_ACCOUNT_BY_CODE, {
    onCompleted: () => {
      clearTimeout(int.current);
      localStorage.setItem("code", true);
      window.close();
    },
    onError: () => {
      clearTimeout(int.current);
      int.current = setTimeout(() => window.close(), 5000);
      localStorage.setItem("code", false);
      openNotification({ type: "error", description: "Error Please Retry" });
    },
  });

  useEffect(() => {
    const cleanup = int.current;
    if (params.get("code")) {
      googleConnect({ variables: { code: params.get("code") } });
    }
    return () => clearTimeout(cleanup);
  }, []);
  return (
    <div className="h-100 dashboard-content flex-center">
      <Result
        icon={<Loader className="mb-10" center={true} largeLoader={true} />}
        subTitle={
          <h2 style={{ fontSize: "2.7vw" }}>
            Account sync in progress⚡️.... Redirecting Back.....
          </h2>
        }
      />
    </div>
  );
}
