import {
  GQL_TRACKER_ADVERTISERS_DROPDOWN,
  GQL_TRACKER_CATEGORY_FOR_DROPDOWN,
  GQL_TRACKER_CONFIG,
  GQL_TRACKER_COUNTRIES,
  GQL_TRACKER_CTA_FOR_DROPDOWN,
  GQL_TRACKER_DOMAINS_FOR_DROPDOWN,
  GQL_TRACKER_LANDERS_FOR_DROPDOWN,
  GQL_TRACKER_NETWORK_ACCOUNTS_FRO_DROPDOWN,
  GQL_TRACKER_OFFER_URL_TOKENS,
  GQL_TRACKER_PAGED_AFFILIATE_NETWORK_FOR_DROPDOWN,
  GQL_TRACKER_PAGED_OFFERS_FOR_DROPDOWN,
  GQL_TRACKER_PATHS_FOR_DROPDOWN,
  GQL_TRACKER_RULES_DROPDOWN,
  GQL_TRACKER_SUBIDS,
  GQL_TRACKER_TRAFFIC_SOURCE_FOR_DROPDOWN,
} from "shared/gql/gqlSchema/trackerGql";
import useApiToStoreQuery from "./useApiToStoreQuery";

export default function useTrackerApis() {
  const {
    apiCall: trackerPathFetch = () => {},
    refetch: trackerPathRefetch,
    setDataSource: trackerPathSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_PATHS_FOR_DROPDOWN,
    elementKey: "Tracker_Paths",
    storeKey: "Tracker_Paths",
    newResponse: true,
  });

  const {
    apiCall: trackerRuleFetch = () => {},
    refetch: trackerRuleRefetch,
    setDataSource: trackerRuleSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_RULES_DROPDOWN,
    elementKey: "Tracker_Rules",
    storeKey: "Tracker_Rules",
    newResponse: true,
  });

  const {
    apiCall: trackerNetworkAccountsFetch = () => {},
    refetch: trackerNetworkAccountsRefetch,
    setDataSource: trackerNetworkAccountsSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_NETWORK_ACCOUNTS_FRO_DROPDOWN,
    elementKey: "Tracker_NetworkAccounts",
    storeKey: "Tracker_NetworkAccounts",
    newResponse: true,
  });

  const {
    apiCall: trackerConfigFetch = () => {},
    refetch: trackerConfigRefetch,
    setDataSource: trackerConfigSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_CONFIG,
    elementKey: "Tracker_Config",
    storeKey: "Tracker_Config",
    newResponse: true,
    objectType: true,
  });

  const {
    apiCall: trackerOfferUrlTokensFetch = () => {},
    refetch: trackerOfferUrlTokensRefetch,
    setDataSource: trackerOfferUrlTokensSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_OFFER_URL_TOKENS,
    elementKey: "Tracker_OfferUrlTokens",
    storeKey: "Tracker_OfferUrlTokens",
    newResponse: true,
  });

  const {
    apiCall: trackerOffersFetch = () => {},
    refetch: trackerOffersRefetch,
    setDataSource: trackerOffersSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_PAGED_OFFERS_FOR_DROPDOWN,
    elementKey: "Tracker_Offers",
    storeKey: "Tracker_Offers",
    newResponse: true,
  });

  const {
    apiCall: trackerDomainsFetch = () => {},
    refetch: trackerDomainsRefetch,
    setDataSource: trackerDomainsSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_DOMAINS_FOR_DROPDOWN,
    elementKey: "Tracker_Domains",
    storeKey: "Tracker_Domains",
    newResponse: true,
  });

  const {
    apiCall: trackerCategoryFetch = () => {},
    refetch: trackerCategoryRefetch,
    setDataSource: trackerCategorySetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_CATEGORY_FOR_DROPDOWN,
    elementKey: "Tracker_Categories",
    storeKey: "Tracker_Categories",
    newResponse: true,
  });

  const {
    apiCall: trackerCountryFetch = () => {},
    refetch: trackerCountryRefetch,
    setDataSource: trackerCountrySetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_COUNTRIES,
    elementKey: "Tracker_Countries",
    storeKey: "Tracker_Countries",
    newResponse: true,
  });

  const {
    apiCall: trackerAffliateNetworkFetch = () => {},
    refetch: trackerAffliateNetworkRefetch,
    setDataSource: trackerAffliateNetworkSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_PAGED_AFFILIATE_NETWORK_FOR_DROPDOWN,
    elementKey: "Tracker_AffiliateNetworks",
    storeKey: "Tracker_AffiliateNetworks",
    newResponse: true,
  });

  const {
    apiCall: trackerAdvertisersFetch = () => {},
    refetch: trackerAdvertisersRefetch,
    setDataSource: trackerAdvertisersSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_ADVERTISERS_DROPDOWN,
    elementKey: "Tracker_Advertisers",
    storeKey: "Tracker_Advertisers",
    newResponse: true,
  });

  const {
    apiCall: trackerTrafficSourceFetch = () => {},
    refetch: trackerTrafficSourceRefetch,
    setDataSource: trackerTrafficSourceSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_TRAFFIC_SOURCE_FOR_DROPDOWN,
    elementKey: "Tracker_TrafficSources",
    storeKey: "Tracker_TrafficSources",
    newResponse: true,
  });

  const {
    apiCall: trackerCtaFetch = () => {},
    refetch: trackerCtaRefetch,
    setDataSource: trackerCtaSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_CTA_FOR_DROPDOWN,
    elementKey: "Tracker_CTAs",
    storeKey: "Tracker_CTAs",
    newResponse: true,
  });

  const {
    apiCall: trackerLanderFetch = () => {},
    refetch: trackerLanderRefetch,
    setDataSource: trackerLanderSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_LANDERS_FOR_DROPDOWN,
    elementKey: "Tracker_Landers",
    storeKey: "Tracker_Landers",
    newResponse: true,
  });

  const {
    apiCall: trackerSubIdsFetch = () => {},
    refetch: trackerSubIdsRefetch,
    setDataSource: trackerSubIdsSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_TRACKER_SUBIDS,
    elementKey: "Tracker_Subids",
    storeKey: "Tracker_Subids",
    newResponse: true,
  });

  return {
    trackerPathFetch,
    trackerPathRefetch,
    trackerPathSetDataSource,

    trackerRuleFetch,
    trackerRuleRefetch,
    trackerRuleSetDataSource,

    trackerNetworkAccountsFetch,
    trackerNetworkAccountsRefetch,
    trackerNetworkAccountsSetDataSource,

    trackerConfigFetch,
    trackerConfigRefetch,
    trackerConfigSetDataSource,

    trackerOfferUrlTokensFetch,
    trackerOfferUrlTokensRefetch,
    trackerOfferUrlTokensSetDataSource,

    trackerOffersFetch,
    trackerOffersRefetch,
    trackerOffersSetDataSource,

    trackerDomainsFetch,
    trackerDomainsRefetch,
    trackerDomainsSetDataSource,

    trackerCategoryFetch,
    trackerCategoryRefetch,
    trackerCategorySetDataSource,

    trackerCountryFetch,
    trackerCountryRefetch,
    trackerCountrySetDataSource,

    trackerAffliateNetworkFetch,
    trackerAffliateNetworkRefetch,
    trackerAffliateNetworkSetDataSource,

    trackerAdvertisersFetch,
    trackerAdvertisersRefetch,
    trackerAdvertisersSetDataSource,

    trackerTrafficSourceFetch,
    trackerTrafficSourceRefetch,
    trackerTrafficSourceSetDataSource,

    trackerCtaFetch,
    trackerCtaRefetch,
    trackerCtaSetDataSource,

    trackerLanderFetch,
    trackerLanderRefetch,
    trackerLanderSetDataSource,

    trackerSubIdsFetch,
    trackerSubIdsRefetch,
    trackerSubIdsSetDataSource,
  };
}
