import { Form, Button, Alert, Row, Col, Switch } from "antd";
import { Formik } from "formik";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";
import { Checkbox, Radio, Space } from "antd/lib";
import { useState } from "react";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
    // propSpendFB = "",
    // budget = "",
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {isError && <Alert message={isError} type="error" className="mb-10" />}

          <Row gutter={12}>
            <Col span={12}>
              <LookTextInput
                formik
                type="number"
                name="budget"
                label="Budget"
                className="look-form-input mt-15"
                placeholder="Enter budget"
                submitOnEnter
              />
            </Col>
          </Row>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn "
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
