import LookButton from "components/LookButton";
import useCmsBannerCrudHook from "../useCmsBannerCrudHook";
import CmsBannerListView from "../CmsBannerListView";
import { useState } from "react";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import { openNotification, removeObjectByIndex } from "utils";
import { useLookDrawer } from "components/LookDrawer";
import AddBanner from "./addBanner";

export default function useCmsUpdateBanner(props = {}) {
  const { type = "", typeId, bannerImages = [], setBannerImages = () => {} } = props;
  const {
    fetchBanners,
    call_fetchCmsBanner,
    loading_fetchCmsBanner,
    refetch_fetchCmsBanner,

    updateBanner,
    call_updateCmsBanner,
    loading_updateCmsBanner,
    refetch_updateCmsBanner,

    call_deleteCmsBanner,
    loading_deleteCmsBanner,
  } = useCmsBannerCrudHook({ type });

  const {
    drawer: newBanner_drawer,
    openDrawer: newBanner_openDrawer,
    closeDrawer: newBanner_closeDrawer,
    visibleDrawer: newBanner_visibleDrawer,
    setTextBreadcrumb: newBanner_setTextBreadcrumb,
  } = useLookDrawer({
    onOpen: (e) => {},
    onClose: () => {},
    noBodySpace: true,
    textBreadcrumb: ["Add New Banners"],
  });

  const [editIndex, setEditIndex] = useState();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    call_deleteCmsBanner({
      variables: { id: deleteModalPropsData },
      onCompleted: (e) => {
        const element = e?.CMS_Delete_Banner;
        if (element === "0") {
          openNotification({
            type: "error",
            message: "Banners not deleted",
            description: "Somthing went wrong",
          });
        }
        if (element === "1") {
          let temp = JSON.parse(JSON.stringify(bannerImages));

          const selectedIndex = temp.findIndex(({ id }) => id === deleteModalPropsData);

          if (selectedIndex !== -1) {
            temp = removeObjectByIndex(temp, selectedIndex);
            setBannerImages(temp);
          }
          onCloseDeleteModal();
          openNotification({ type: "success", message: "Banner deleted" });
        }
      },
    });
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  const renderBannerList = () => (
    <div>
      {bannerImages?.map((data, index) => (
        <CmsBannerListView
          {...data}
          typeId={typeId}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          updateBanner={updateBanner}
          remove={() => {}}
          currentIndex={index}
          key={"bannerImages" + index}
          bannerImages={bannerImages}
          setBannerImages={setBannerImages}
          loading_updateCmsBanner={loading_updateCmsBanner}
          onDelete={() => {
            onOpenDeleteModal(data?.id);
          }}
        />
      ))}
    </div>
  );

  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_deleteCmsBanner,
  };
  function bannerUpdateView() {
    return (
      <div>
        {newBanner_drawer(
          newBanner_visibleDrawer && (
            <AddBanner
              type={type}
              typeId={typeId}
              onClose={newBanner_closeDrawer}
              setBannerImages={setBannerImages}
            />
          )
        )}
        <LookTableRecordDeleteModal {...deleteModalProps} />
        <div className="cms-banner-header">
          <p className="label">
            Banners <span>({bannerImages?.length})</span>
          </p>
          <div className="banner-add">
            <LookButton iconType="plus" type="primary" onClick={newBanner_openDrawer}>
              Add new banner
            </LookButton>
          </div>
        </div>
        <div className="cms-banner-list-content">{renderBannerList()}</div>
      </div>
    );
  }

  return {
    fetchBanners,
    call_fetchCmsBanner,
    loading_fetchCmsBanner,
    refetch_fetchCmsBanner,

    bannerUpdateView,
    updateBanner,
    call_updateCmsBanner,
    loading_updateCmsBanner,
    refetch_updateCmsBanner,
  };
}
