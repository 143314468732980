import { gql } from "@apollo/client";

export const GET_DOMAIN_ACCOUNTS = gql`
  query domainAccounts {
    me {
      domainAccounts {
        id
        api_key
        api_user
        balance
        currency
        email
        notes
        nickname
        user_id
        username
        account_type
        status
      }
    }
  }
`;

export const GET_DNS_RECORDS = gql`
  query dnsrecord($domain: Int) {
    dnsRecords(domain: $domain) {
      id
      zone_id
      type
      ttl
      content
      proxied
    }
  }
`;

export const GET_NAMESERVER = gql`
  query nameserver($domain: Int) {
    nameserver(domain: $domain) {
      id
      nameserver1
      nameserver2
    }
  }
`;

export const DMS_ASSIGN_DOMAINS_TO_USERS = gql`
  mutation dmsDomainAssigned($domain_id: [Int], $status: Int, $user_id: [Int]) {
    dmsDomainAssigned(domain_id: $domain_id, status: $status, user_id: $user_id)
  }
`;

export const GET_All_DOMAINS = gql`
  query domainsAll(
    $column: String
    $filterByName: Int
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    domainsAll(
      column: $column
      filterByName: $filterByName
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        name
        account_type
        account_id
        domain_id
        address_id
        autorenew
        domain_expires
        domain_id
        notes
        privacy
        status

        user {
          id
          name
        }
        dmsDomainAssigned {
          id
          user_id
          user {
            id
            name
          }
        }
        whoisguard
        domaincloudflare {
          id
          name
          zone_id
        }
      }
    }
  }
`;

export const GET_DOMAINS = gql`
  query domains(
    $account: Int
    $column: String
    $filterByName: Int
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    domains(
      account: $account
      column: $column
      filterByName: $filterByName
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        id
        name
        account_type
        account_id
        domain_id
        address_id
        autorenew
        domain_expires
        domain_id
        notes
        privacy
        status
        type
        user {
          id
          name
        }
        dmsDomainAssigned {
          id
          user_id
          user {
            id
            name
          }
        }
        whoisguard

        domaincloudflare {
          id
          name
          zone_id
        }
      }
    }
  }
`;

export const CREATE_DOMAIN_ACCOUNT = gql`
  mutation createDomainAccount(
    $api_key: String
    $api_user: String
    $email: String
    $notes: String
    $registrar_id: Int
    $username: String
  ) {
    createDomainAccount(
      api_key: $api_key
      api_user: $api_user
      email: $email
      notes: $notes
      registrar_id: $registrar_id
      username: $username
    ) {
      api_key
    }
  }
`;

export const GET_REGISTRAR = gql`
  query registrar {
    registrar {
      id
      name
      description
      images
      type
      url
    }
  }
`;

export const SYNC_ADDRESS = gql`
  mutation syncAddress($account: Int) {
    syncAddress(account: $account)
  }
`;
export const SYNC_BALANCE = gql`
  mutation syncBalance($account: Int) {
    syncBalance(account: $account)
  }
`;
export const SYNC_DOMAINS = gql`
  mutation syncDomains($account: Int) {
    syncDomains(account: $account)
  }
`;

export const DMS_CREATE_ACCOUNT = gql`
  mutation dms_add_account($userAccount: dms_user_account) {
    dms_add_account(userAccount: $userAccount) {
      user_name
    }
  }
`;

const DMS_DOMAIN_RESPONSE = gql`
  fragment dms_domain on dms_domain {
    account_id
    auto_renew
    charged_amount
    created
    domain_id
    domain_name
    error
    expires
    id
    is_expired
    is_locked
    is_managed
    is_our_dns
    is_premium
    is_registered
    is_stale
    source
    user
  }
`;

export const DMS_GET_ACCOUNT = gql`
  query dms_get_accounts {
    dms_get_accounts {
      id
      user_name
      aux_billing_first_name
      aux_billing_last_name
    }
  }
`;

export const DMS_GET_DOMAINS = gql`
  ${DMS_DOMAIN_RESPONSE}
  query dms_get_domains($pageNumber: Int!, $pageSize: Int!, $search: String) {
    dms_get_domains(pageNumber: $pageNumber, pageSize: $pageSize, search: $search) {
      data {
        ...dms_domain
      }
      pageInfo {
        totalPages
        totalCount
      }
    }
  }
`;

export const DMS_REACTIVATE_DOMAIN = gql`
  mutation dms_reactivate_domain($account_id: ID!, $domainName: String!) {
    dms_reactivate_domain(account_id: $account_id, domainName: $domainName) {
      status
      message
      data {
        id
      }
    }
  }
`;

export const DMS_CREATE_DOMAIN = gql`
  ${DMS_DOMAIN_RESPONSE}
  mutation dms_buy_domain(
    $domainName: String!
    $id: ID!
    $isSas: Boolean! = false
    $nameServer: [String]!
    $sasObj: dms_sas_input
    $source: String!
  ) {
    dms_buy_domain(
      domainName: $domainName
      source: $source
      id: $id
      isSas: $isSas
      name_server: $nameServer
      sasObj: $sasObj
    ) {
      status
      message
      data {
        ...dms_domain
      }
    }
  }
`;

export const DMS_DOMAIN_AVAILABILITY_ACCOUNT = gql`
  fragment dms_domain_availability on dms_domain_availability {
    available
    domain
    is_premiumName
    premium_registration_price
    premium_renewal_price
    premium_restore_price
    premium_transfer_price
  }
`;

export const GQL_DMS_CHECK_DOMAIN_AVAILABILITY = gql`
  ${DMS_DOMAIN_AVAILABILITY_ACCOUNT}
  mutation dms_check_domain_availability($account_id: ID!, $domainName: String!) {
    dms_check_domain_availability(account_id: $account_id, domainName: $domainName) {
      status
      message
      data {
        ...dms_domain_availability
      }
    }
  }
`;

/**
 * In dms_get_dns
 * without pageNumber and pageSize will get all data
 */

export const GQL_DMS_GET_DNS = gql`
  query dms_get_dns(
    $column: String
    $order: order
    $pageNumber: Int
    $pageSize: Int
    $search: String
  ) {
    dms_get_dns(
      column: $column
      order: $order
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
    ) {
      message
      status
      pageInfo {
        totalCount
        totalPages
      }
      data {
        name
        description
      }
    }
  }
`;

export const DMS_DELETE_ACCOUNT = gql`
  mutation dms_delete_account($id: ID!) {
    dms_delete_account(id: $id) {
      user_name
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation Dms_update_account($accountId: ID!, $accountInput: dms_update_account) {
    dms_update_account(id: $accountId, accountInput: $accountInput) {
      user_name
    }
  }
`;

export const DMS_GET_TLD = gql`
  query dms_get_tlds {
    dms_get_tlds {
      id
      tld
    }
  }
`;

export const DMS_CREATE_TLD = gql`
  mutation dms_create_tlds($tld: String!) {
    dms_create_tlds(tld: $tld) {
      tld
      id
    }
  }
`;

export const DMS_DELETE_TLD = gql`
  mutation dms_delete_tlds($id: ID!) {
    dms_delete_tlds(id: $id) {
      id
      tld
    }
  }
`;

export const DMS_ADD_DOMAIN = gql`
  mutation dms_add_domain($domain: dms_domain_input) {
    dms_add_domain(domain: $domain) {
      message
      status
    }
  }
`;

export const DMS_CREATE_DNS = gql`
  mutation dms_create_dns($description: String, $name: String!) {
    dms_create_dns(description: $description, name: $name) {
      message
      status
      data {
        name
      }
    }
  }
`;

export const DMS_UPDATE_DOMAIN = gql`
  mutation dms_update_domain($domain: dms_domain_input) {
    dms_update_domain(domain: $domain) {
      message
      status
    }
  }
`;
