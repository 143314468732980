import { Button, Col, DatePicker, Divider, Form, InputNumber, Row, Select, Space } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  LAUNCH_PLATFORM_DATA,
  LAUNCH_TIKTOK_CITIES,
  LAUNCH_TIKTOK_COUNTRIES,
  LAUNCH_TIKTOK_LANGUAGES,
  LAUNCH_TIKTOK_STATES,
} from "shared/gql/gqlSchema/launchGql";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import Loader from "components/loader";
import { useSelector } from "react-redux";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import {
  TIKTOK_LAUNCH_CREATIVE_MATERIAL_MODE,
  TIKTOK_LAUNCH_OPTIMIZATION_EVENT_ENUM,
} from "shared/enum/iCampaignLaunchEnumArray";
import { C_TYPE_ABO } from "shared/enum/launchKeysAndEnum";

function TiktokForm(props) {
  const {
    launch = false,
    onClose = () => {},
    onSubmit = () => {},
    defaultData,
    tiktokinputs: inputs,
    setTiktokInputs: setInputs,
    setStep = () => {},
    cType,
    platformsData,
    showTargetingLaunch,
    setTargettingTemplateData,
    templatePlatforms,
    isSpecialIndustries = false,
  } = props;

  const [errors, setErrors] = useState({});
  const size = isSpecialIndustries ? 24 : 12;

  // const defaultselectedDate = moment().tz(dateRangeTimezone?.timeZone?.value).format(dateFormat);
  const {
    tiktokLaunchFetch = () => {},
    tiktokLaunchLoading,
    tiktokLaunchRefetch,
  } = useApiToStoreContext();

  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const dateFormat = "YYYY-MM-DD HH:mm";
  const [tiktokLanguage, setTiktokLanguage] = useState([]);
  const { data: Launch_countries, loading: LAUNCH_COUNTRIES_LOADING } =
    useCustomQuery(LAUNCH_TIKTOK_COUNTRIES);
  const [fetchStates, { data: Launch_provinces, loading: Launch_provinces_loading }] =
    useCustomLazyQuery(LAUNCH_TIKTOK_STATES, {
      notifyOnNetworkStatusChange: true,
    });
  const [fetchCities, { data: Launch_cities, loading: Launch_cities_loading }] = useCustomLazyQuery(
    LAUNCH_TIKTOK_CITIES,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const [fetchLanguages, { data: Launch_languages, loading: Launch_languages_loading }] =
    useCustomLazyQuery(LAUNCH_TIKTOK_LANGUAGES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        setTiktokLanguage(e?.tiktokLaunchLanguages);
      },
    });

  // const { data, loading } = useCustomQuery(LAUNCH_PLATFORM_DATA, {
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted: (e) => {
  //     setInputs({
  //       ...inputs,
  //       templatePlatform: data?.templatePlatforms?.filter((obj) => obj.name === templatePlatform)[0]
  //         ?.id,
  //     });
  //   },
  // });
  const { userSettingData = {}, selectedWorkspace } =
    useSelector((state) => state?.userSettings) || {};
  const { tiktokLaunchConfig } = userSettingData;

  // useEffect(() => {

  // }, []);
  useEffect(() => {
    tiktokLaunchFetch();
    fetchLanguages();
    if (countries?.length > 0)
      fetchStates({
        variables: {
          countryIds: countries?.map((i) => i?.id),
        },
      });
    if (states?.length > 0)
      fetchCities({
        variables: {
          provinceIds: states?.map((i) => i?.id),
        },
      });
  }, []);

  const {
    name,
    budgetMode,
    creativeMaterialMode,
    templatePlatform,
    householdIncome,
    placementType,
    spendingPower,
    promotionType,
    promotionTargetType,
    operatingSystems,
    bidType,
    conversionBidPrice,
    templategender,
    cta,
    billingEvent,
    // objective,
    countries,
    states,
    cities,
    scheduleEndTime,
    scheduleStartTime,
    scheduleType,

    ageGroups,
    optimizationGoal,
    optimizationEvent,
    pacing_targeting,
    // countryGroups,
    placement,
    locales,

    budget,
    bid,
    excludedCountries,
  } = inputs;
  console.log(inputs);

  const handleSubmit = (event) => {
    let err = {};

    // if (!name) {
    //   err.name = "Name is required";
    // }
    // // if (!objective) {
    // //   err.objective = "Objective is required";
    // // }
    // if (!cta) {
    //   err.cta = "Cta is required";
    // }
    // // if (!budget) {
    // //   err.budget = "Budget is required";
    // // }
    // if (!optimizationGoal) {
    //   err.optimizationGoal = "Optimization Goal is required";
    // }

    // // if (countries.length === 0) {
    // //   err.countries = "Countries is required";
    // // }
    // const dateFormat = "YYYY-MM-DD HH:mm";

    // const defaultDate = [
    //   moment().tz(dateRangeTimezone?.timeZone?.value).format(dateFormat),
    //   moment().tz(dateRangeTimezone?.timeZone?.value).format(dateFormat),
    // ];

    // if (launch && scheduleStartTime && scheduleStartTime < defaultDate[0]) {
    //   err.scheduleStartTime = "Selected Date is invalid ";
    // }

    // setErrors(err);
    if (Object.keys(err).length === 0) {
      onSubmit();
    }
  };

  function handleKeyInput(name, value) {
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  function handleInput(e) {
    handleKeyInput([e.target.name], e.target.value);
  }
  const { ad, adSet, campaign } = tiktokLaunchConfig || {};
  const { call_to_action, ad_format, identity_type } = ad || {};
  const {
    age_groups,
    bid_type,
    gender,
    household_income,
    operating_systems,
    optimization_goal,
    optimization_event,
    pacing,
    placement_type,
    placements,
    promotion_target_type,
    promotion_type,
    schedule_type,
    billing_event,
    spending_power,
  } = adSet || {};
  const { budget_mode, objective_type, special_industries } = campaign || {};
  function templateForm() {
    return (
      <>
        {}
        {!launch && (
          <SelectInput
            label="Platform"
            search
            // defaultValue={templatePlatform}
            value={templatePlatforms}
            disabled
            // onChange={(e) => handleKeyInput("templatePlatform", e)}
            options={platformsData?.templatePlatforms?.map(({ id, name }) => ({
              name,
              value: id,
            }))}
          />
        )}
        {!launch && (
          <LookTextInput
            label="Targeting Template Name"
            name="name"
            value={name}
            Label="Template Name"
            onChange={handleInput}
            disabled={launch}
            className="look-form-input"
            error={errors.name}
          />
        )}
        {/* {launch && (
          <SelectInput
            label="Creative Mode"
            search
            value={creativeMaterialMode}
            onChange={(e) => handleKeyInput("creativeMaterialMode", e)}
            options={TIKTOK_LAUNCH_CREATIVE_MATERIAL_MODE}
          />
        )} */}
        <SelectInput
          label="Billing Event"
          search
          value={billingEvent}
          onChange={(e) => handleKeyInput("billingEvent", e)}
          options={billing_event}
        />

        {launch ? (
          cType === C_TYPE_ABO && (
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <SelectInput
                  label="Budget Mode"
                  search
                  value={budgetMode}
                  onChange={(e) => handleKeyInput("budgetMode", e)}
                  options={budget_mode}
                />
              </Col>
              <Col span={12}>
                <p className="mb-8"> Budget</p>
                <div className="look-form-input">
                  <InputNumber
                    min={1}
                    value={budget}
                    controls={false}
                    onChange={(e) => handleKeyInput("budget", e)}
                    style={{ width: "100%", borderColor: "#404040" }}
                  />
                  {errors.budget && <span className="text-left error-text">{errors.budget}</span>}
                </div>
              </Col>
            </Row>
          )
        ) : (
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <SelectInput
                label="Budget Mode"
                search
                value={budgetMode}
                onChange={(e) => handleKeyInput("budgetMode", e)}
                options={budget_mode}
              />
            </Col>
            <Col span={12}>
              <p className="mb-8"> Budget</p>
              <div className="look-form-input">
                <InputNumber
                  min={1}
                  value={budget}
                  controls={false}
                  onChange={(e) => handleKeyInput("budget", e)}
                  style={{ width: "100%", borderColor: "#404040" }}
                />
                {errors.budget && <span className="text-left error-text">{errors.budget}</span>}
              </div>
            </Col>
          </Row>
        )}

        <Row gutter={[12, 12]}>
          <Col span={12}>
            <SelectInput
              label="Bid Type"
              search
              value={bidType}
              onChange={(e) => handleKeyInput("bidType", e)}
              options={bid_type}
            />
          </Col>
          {bidType === "BID_TYPE_CUSTOM" && (
            <Col span={12}>
              <LookTextInput
                label="Bid Price"
                name="bid"
                value={bid}
                onChange={(e) => {
                  setInputs({
                    ...inputs,
                    bid: e?.target?.value,
                    conversionBidPrice: 0,
                  });
                }}
              />
            </Col>
          )}
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <LookTextInput
              label="Conversion Bid Price"
              name="conversionBidPrice"
              value={conversionBidPrice}
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  bid: 0,
                  conversionBidPrice: e.target.value,
                });
              }}
            />

            {/* {errors.budget && <span className="text-left error-text">{errors.budget}</span>} */}
          </Col>
          <Col span={12}>
            <SelectInput
              label="Operating Systems"
              search
              multiple
              value={operatingSystems}
              onChange={(e) => handleKeyInput("operatingSystems", e)}
              options={operating_systems}
            />
          </Col>
        </Row>
        {launch && (
          <SelectInput
            label="Schedule Type"
            search
            value={scheduleType}
            onChange={(e) => handleKeyInput("scheduleType", e)}
            options={schedule_type}
          />
        )}
        {launch && scheduleType && (
          <>
            {/* <p>
              <b>Account Timezone based Date & Time: </b>
              {moment().tz(dateRangeTimezone?.timeZone?.value).format("YYYY-MM-DD HH:mm")}(
              {dateRangeTimezone?.timeZone?.value})
            </p> */}
            <div className="d-flex" style={{ gap: "20px" }}>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <Form.Item label="Scheduled Start Date" className="look-form-input">
                  <DatePicker
                    showTime={{
                      format: "HH:mm",
                    }}
                    //COMMENT  allowClear 22 June
                    // allowClear={false}

                    showNow={false}
                    defaultValue={moment().tz(dateRangeTimezone?.timeZone?.value)}
                    format="YYYY-MM-DD HH:mm"
                    // value direct pass remove moment wrapper
                    value={scheduleStartTime}
                    onChange={(date, dateString) => {
                      setInputs({ ...inputs, scheduleStartTime: date });
                    }}
                    // disabledDate={(current) =>
                    //   (current && current < moment().tz(dateRangeTimezone?.timeZone?.value)) ||
                    //   current > scheduleEndTime
                    // }
                    // disabledTime={(current) =>
                    //   (current && current < moment().tz(dateRangeTimezone?.timeZone?.value)) ||
                    //   current > scheduleEndTime
                    // }
                  />
                  <div>
                    {errors.scheduledStart_Date && (
                      <span className="text-left error-text">{errors.scheduledStart_Date}</span>
                    )}
                  </div>
                </Form.Item>
              </div>

              {scheduleType === "SCHEDULE_START_END" && (
                <Form.Item label="Scheduled End Date" formik className="look-form-input">
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    //COMMENT  allowClear 22 June
                    // showNow={false}
                    allowClear={false}
                    // disabledDate={
                    //   (value) =>
                    //     value &&
                    //     value.isBefore(
                    //       moment(scheduleStartTime)
                    //         .subtract(1, "day")
                    //         .tz(dateRangeTimezone?.timeZone?.value)
                    //     )
                    //   // value < moment(scheduleStartTime).tz(dateRangeTimezone?.timeZone?.value)
                    // }
                    // disabledTime={(value) => value < scheduleStartTime}
                    format="YYYY-MM-DD HH:mm"
                    // value direct pass remove moment wrapper
                    value={scheduleEndTime ? scheduleEndTime : undefined}
                    onChange={(date, dateString) => {
                      setInputs({ ...inputs, scheduleEndTime: date });
                    }}
                  />
                </Form.Item>
              )}
            </div>
          </>
        )}
        {/* <SelectInput
          label="Objective"
          search
          name="objective"
          value={objective}
          error={errors?.objective}
          onChange={(e) =>
            setInputs({
              ...inputs,
              objective: e,
            })
          }
          options={objective_type}
        /> */}
        <SelectInput
          label="Optimization Goal"
          search
          name="optimizationGoal"
          error={errors?.optimizationGoal}
          value={optimizationGoal}
          onChange={(e) => handleKeyInput("optimizationGoal", e)}
          options={optimization_goal}
        />
        <SelectInput
          label="Optimization Event"
          search
          name="optimizationEvent"
          // error={errors?.optimizationEvent}
          value={optimizationEvent}
          onChange={(e) => handleKeyInput("optimizationEvent", e)}
          options={TIKTOK_LAUNCH_OPTIMIZATION_EVENT_ENUM}
        />

        <SelectInput
          label="Pacing"
          search
          name="pacing"
          // error={errors?.optimizationEvent}
          value={pacing_targeting}
          onChange={(e) => handleKeyInput("pacing_targeting", e)}
          options={pacing}
        />
        <SelectInput
          label="CTA"
          search
          name="cta"
          value={cta}
          onChange={(e) => handleKeyInput("cta", e)}
          options={call_to_action}
          error={errors?.cta}
        />
      </>
    );
  }
  const [visible, setVisible] = useState(false);
  const [visibleStates, setVisibleStates] = useState(false);

  return (
    <>
      {tiktokLaunchLoading && LAUNCH_COUNTRIES_LOADING ? (
        <Loader />
      ) : (
        <Form layout="vertical" onFinish={handleSubmit}>
          <div className="overflow-auto p-20" style={{ height: "calc(100vh - 130px)" }}>
            <div>
              {templateForm()}
              <Row gutter={[12, 12]}>
                {/* <Col span={9}>{renderTemplateGender()}</Col> */}
                {(!isSpecialIndustries && launch) || !launch ? (
                  <Col span={size}>
                    <SelectInput
                      label="Age"
                      search
                      multiple
                      name="ageGroups"
                      value={ageGroups}
                      onChange={(e) => handleKeyInput("ageGroups", e)}
                      options={age_groups}
                    />
                  </Col>
                ) : null}
                <Col span={size}>
                  <SelectInput
                    label="Gender"
                    search
                    value={templategender}
                    onChange={(e) => handleKeyInput("templategender", e)}
                    options={gender}
                  />
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <p className="mb-8"> Countries</p>
                    <Select
                      className="look-form-input"
                      showSearch={true}
                      mode="multiple"
                      name="countries"
                      value={countries?.map((item) => item?.name)}
                      open={visible}
                      onDropdownVisibleChange={(e) => setVisible(e)}
                      onChange={(e) => {
                        handleKeyInput(
                          "countries",
                          e?.map((item) => ({
                            id: Launch_countries?.tiktokLaunchCountries?.filter(
                              (countries) => countries?.name === item
                            )[0]?.id,
                            name: item,
                            level: "countries",
                          }))
                        );
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Row gutter={[12, 12]}>
                            <Col span={12}>
                              <Button
                                htmlType="submit"
                                block
                                className="submit-btn"
                                onClick={() => {
                                  setVisible(false);
                                  handleKeyInput("countries", []);
                                }}
                              >
                                Cancel
                              </Button>
                            </Col>
                            <Col span={12}>
                              <Button
                                htmlType="submit"
                                block
                                type="primary"
                                className="submit-btn"
                                onClick={() => {
                                  setVisible(false);
                                  fetchStates({
                                    variables: {
                                      countryIds: countries?.map((i) => i?.id),
                                    },
                                  });
                                }}
                              >
                                Apply
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                      options={Launch_countries?.tiktokLaunchCountries?.map(({ id, name }) => ({
                        label: name,
                        value: name,
                      }))}
                    />
                  </div>
                </Col>

                {countries?.length > 0 && (
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <p className="mb-8"> States</p>
                    <Col span={8}>
                      <Select
                        label="States"
                        style={{ width: "250px" }}
                        className="look-form-input"
                        showSearch={true}
                        loading={Launch_provinces_loading}
                        mode="multiple"
                        name="states"
                        value={states?.map((item) => item?.name)}
                        open={visibleStates}
                        onDropdownVisibleChange={(e) => setVisibleStates(e)}
                        onChange={(e) => {
                          handleKeyInput(
                            "states",
                            e?.map((item) => ({
                              id: Launch_provinces?.tiktokLaunchProvince?.filter(
                                (states) => states?.name === item
                              )[0]?.id,
                              name: item,
                              parent_id: Launch_provinces?.tiktokLaunchProvince?.filter(
                                (states) => states?.name === item
                              )[0]?.parentId,
                              level: "states",
                            }))
                          );
                        }}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: "8px 0",
                              }}
                            />
                            <Space
                              style={{
                                padding: "0 8px 4px",
                              }}
                            >
                              <Button
                                htmlType="submit"
                                block
                                type="primary"
                                className="submit-btn"
                                onClick={() => {
                                  setVisibleStates(false);
                                  handleKeyInput("states", []);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                htmlType="submit"
                                block
                                type="primary"
                                className="submit-btn"
                                onClick={() => {
                                  setVisibleStates(false);
                                  fetchCities({
                                    variables: {
                                      provinceIds: states?.map((i) => i?.id),
                                    },
                                  });
                                }}
                              >
                                Ok
                              </Button>
                            </Space>
                          </>
                        )}
                        options={Launch_provinces?.tiktokLaunchProvince?.map(({ id, name }) => ({
                          label: name,
                          value: name,
                        }))}
                      />
                    </Col>
                  </div>
                )}
                {states?.length > 0 && (
                  <Col span={8}>
                    <SelectInput
                      label="Cities"
                      multiple
                      name="cities"
                      loading={Launch_cities_loading}
                      value={cities?.map((item) => item.id)}
                      onChange={(e) => {
                        handleKeyInput(
                          "cities",
                          e?.map((item) => ({
                            id: item,
                            level: "cities",
                            name: Launch_cities?.tiktokLaunchCities?.filter(
                              (cities) => cities?.id === item
                            )[0]?.name,
                            parent_id: Launch_cities?.tiktokLaunchCities?.filter(
                              (cities) => cities?.id === item
                            )[0]?.parentId,
                          }))
                        );
                      }}
                      options={Launch_cities?.tiktokLaunchCities?.map(({ id, name }) => ({
                        name: name,
                        value: id,
                      }))}
                    />
                  </Col>
                )}
              </Row>
              <SelectInput
                multiple
                label="Language"
                search
                value={locales}
                onChange={(e) => {
                  if (e?.includes("All")) {
                    handleKeyInput("locales", [...tiktokLanguage?.map(({ code }) => code)]);
                  } else {
                    handleKeyInput("locales", e);
                  }
                }}
                options={[
                  { name: "All", value: "All" },
                  ...tiktokLanguage?.map(({ code, name }) => ({
                    name,
                    value: code,
                  })),
                ]}
              />
              {/* <SelectInput
          label="Country Groups"
          search
          multiple
          name="countryGroups"
          value={countryGroups}
          onChange={(e) => handleKeyInput("countryGroups", e)}
          options={LAUNCH_COUNTRYGROUPS_ENUM}
        /> */}

              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <SelectInput
                    label="Household Income"
                    multiple
                    search
                    value={householdIncome}
                    onChange={(e) => handleKeyInput("householdIncome", e)}
                    options={household_income}
                  />
                </Col>
                <Col span={12}>
                  <SelectInput
                    label="Spending Power"
                    search
                    value={spendingPower}
                    onChange={(e) => handleKeyInput("spendingPower", e)}
                    options={spending_power}
                  />
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <SelectInput
                    label="Promotion Type"
                    search
                    value={promotionType}
                    onChange={(e) => handleKeyInput("promotionType", e)}
                    options={promotion_type}
                  />
                </Col>
                <Col span={12}>
                  <SelectInput
                    label="Promotion Target Type"
                    search
                    value={promotionTargetType}
                    onChange={(e) => handleKeyInput("promotionTargetType", e)}
                    options={promotion_target_type}
                  />
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <SelectInput
                    className="mb-0"
                    label="Placement Type"
                    search
                    value={placementType}
                    onChange={(e) => handleKeyInput("placementType", e)}
                    options={placement_type}
                  />
                </Col>
                {placementType === "PLACEMENT_TYPE_NORMAL" && (
                  <Col span={12}>
                    <SelectInput
                      label="Placements"
                      search
                      multiple
                      value={placement}
                      onChange={(e) => handleKeyInput("placement", e)}
                      options={placements}
                    />
                  </Col>
                )}
              </Row>
            </div>
          </div>
          <div className="p-20">
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Button
                  block
                  onClick={showTargetingLaunch ? () => setTargettingTemplateData(null) : onClose}
                >
                  {showTargetingLaunch ? "Reset" : "Cancel"}
                </Button>
              </Col>
              <Col span={12}>
                <Button block type="primary" htmlType="submit">
                  {showTargetingLaunch ? "Next" : "Submit"}
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </>
  );
}

export default TiktokForm;
