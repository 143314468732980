import { Button } from "antd";
import TabRender from "components/TabRender";
import { TAB_RENDER_KEY_THEME_BORDERED } from "components/TabRender/TabRenderKeys";
import LaunchCreativesMedia from "./LaunchCreativesMedia";
import LaunchCreativesMediaDescription from "./LaunchCreativesMediaDescription";
import LaunchCreativesMediaHeadline from "./LaunchCreativesMediaHeadline";
import LaunchCreativesMediaTitle from "./LaunchCreativesMediaTitle";

function ActionButtons(props) {
  const {} = props;
  return (
    <div>
      <Button></Button>
      <Button>Next</Button>
    </div>
  );
}

export default function LaunchCreatives() {
  const tabContent = [
    { id: "Media", title: "Media", component: <LaunchCreativesMedia /> },
    { id: "Title", title: "Title", component: <LaunchCreativesMediaTitle /> },
    { id: "Headline", title: "Headline", component: <LaunchCreativesMediaHeadline /> },
    { id: "Description", title: "Description", component: <LaunchCreativesMediaDescription /> },
  ];

  return (
    <div className="mb-24">
      <TabRender
        theme={TAB_RENDER_KEY_THEME_BORDERED}
        className="top-bar"
        tabContent={tabContent}
      />
    </div>
  );
}
