import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import {
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_AD,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET,
  LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN,
} from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import RingbaReport from "./RingbaReport";

function RingbaCallReport(props) {
  const {
    visible = false,
    onClose,
    adName = "",
    asset,
    adId,
    adsetName = "",
    adset_id,
    campaign_id,
    campaignName = "",
    adAccountName = "",
    adAccountId,
  } = props;

  let name = "",
    asset_id = "";

  if (asset === LEADGEN_VOLUUM_REPORT_ASSET_TYPE_AD) {
    name = adName;
    asset_id = adId;
  }

  if (asset === LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET) {
    name = adsetName;
    asset_id = adset_id;
  }

  if (asset === LEADGEN_VOLUUM_REPORT_ASSET_TYPE_CAMPAIGN) {
    name = campaignName;
    asset_id = campaign_id;
  }

  if (asset === LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT) {
    name = adAccountName;
    asset_id = adAccountId;
  }

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={`Ringba Call Report : ${name}`}
    >
      {visible && (
        <LookDrawerMainSideBar
          tabContent={[
            {
              hoverTitle: "Ringba Calls",
              icon: () => <ActionIconRender iconType="lineChart" />,
              component: <RingbaReport {...props} asset={asset} assetId={asset_id} />,
              noSpace: true,
            },
          ]}
        />
      )}
    </LookDrawer>
  );
}

export default RingbaCallReport;
