import DebugPre from "components/DebugPre";
import { useCustomMutation } from "hooks/apolloClientHooks";
import {
  GQL_API_RESPONSE_STATUS_FAILURE,
  GQL_API_RESPONSE_STATUS_SUCCESS,
} from "shared/SharedKeys";
import { openNotification } from "utils";
import * as yup from "yup";
import MainForm from "./MainForm";
import { SAS_UPDATE_EXTERNAL_CHANNEL } from "shared/gql/gqlSchema/sasGql";
import { useSelector } from "react-redux";
import { memo } from "react";

const UpdateForm = memo((props) => {
  const {
    onClose = () => {},
    refetch = () => {},
    id,
    name: propName,
    styleId: propStyleId,
    vendorId: propVendorId,
    platformId: propPlatformId,
    categoryId: propCategoryId,
    isRestricted: propIsRestricted,
    channel: propChannelId,
  } = props;

  const {
    sasPlatforms = [],
    sasCategories = [],
    sasVendors_dropdown = [],
  } = useSelector((state) => state?.userSettings?.userSettingData) || {};

  const [updateChannel, { loading }] = useCustomMutation(SAS_UPDATE_EXTERNAL_CHANNEL, {
    onCompleted(e) {
      const element = e?.sasUpdateExternalChannel;

      if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
        if (element) {
          openNotification({ type: "success", message: "Record Update Success" });
          refetch();
        }
      } else {
        openNotification({
          type: "error",
          message: element?.message,
          key: "tr-update-error-response",
        });
      }
      onClose();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit(values) {
    const { name, isRestricted, platformId, categoryId, channelId, vendorId, styleId } = values;

    const payload = {
      channel: channelId,
      isRestricted,
      name,
      platformId,
      categoryId,
      vendorId,
      styleId,
    };
    updateChannel({ variables: { ...payload, id } });
  }

  const initialValues = {
    name: propName,
    styleId: propStyleId,
    vendorId: propVendorId,
    platformId: propPlatformId,
    channelId: propChannelId,
    isRestricted: propIsRestricted,
    categoryId: propCategoryId,
  };
  const validationSchema = yup.object({ name: yup.string().trim().required().label("Name") });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    parentProps: props,
    sasPlatforms,
    sasCategories,
    sasVendors_dropdown,
  };
  const debugContent = [{ isRow: true, contentObjects: [{ props }] }];

  return (
    <>
      <MainForm btnText="Update" type="edit" {...formProps} />
      <DebugPre content={debugContent} />
    </>
  );
});

export default memo(UpdateForm);
