import {
  USER_SETTING_MAIN_PAGINATION,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import LookModal from "components/LookModal";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  INDUSTRY_INSIGHTS_ADD_DOMAINS,
  INDUSTRY_INSIGHTS_ADD_NOTIFICATION,
  INDUSTRY_INSIGHTS_ADS_COUNT,
  INDUSTRY_INSIGHTS_DELETE_DOMAINS,
  INDUSTRY_INSIGHTS_DOMAIN_FETCH_ALL_DATA,
  INDUSTRY_INSIGHTS_DOMAIN_UPDATE,
  INDUSTRY_INSIGHTS_GET_KEYWORDS_DOMAINS,
  INDUSTRY_INSIGHTS_UPDATE_DOMAINS_NOTIFICATION,
} from "shared/gql/gqlSchema/industryInsightsGql";
import { openNotification } from "utils";
import * as yup from "yup";
import MainForm from "./MainForm";
import { GET_VS_ALL_CONTENT_BY_ADVETTISER } from "shared/gql/gqlSchema/viralSparksGql";
import { GET_SAS_CATEGORIES } from "shared/gql/gqlSchema/sasGql";
import { GET_VOLUUM_CATEGORY } from "shared/gql/gqlSchema/fb360Gql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import { TRACKER_COUNTRIES_LIST } from "shared/gql/gqlSchema/trackerGql";
import { GQL_UMS_LANGUAGES } from "shared/gql/gqlSchema/umsGql";
import PopoverWrapper from "components/popoverWrapper";
import { EllipsisOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import NotifyMe from "../NotifyMePopup";
import {
  INDUSTRY_ISIGHTS_STATUS_ACTIVE,
  INDUSTRY_ISIGHTS_TYPE_DOMAIN,
} from "shared/enum/industryInsightsKeysAndEnums";
import { FAILURE, SUCCESS } from "shared/enum/commonKeysandEnum";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import moment from "moment";
import LookDrawer from "components/LookDrawer";
import Ads from "../Ads";
import { useDispatch } from "react-redux";
import { ENTITY_TYPE_DOMAIN } from "../industryInsightsEnum";
import DomainLoading from "./DomainLoading";

function Domains() {
  const dispatch = useDispatch();
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;

  const [keywordsDomainsData, setKeywordDomainsData] = useState([]);
  const [visibleAddDomainModal, setAddDomainVisibleModal] = useState(false);
  const [visibleNotifyMeModal, setVisibleNotifyMeModal] = useState(false);

  const resetFormRef = useRef(() => {});
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [notifyAbout, setNotifyAbout] = useState([]);
  const [prefrences, setPrefrences] = useState([]);
  const [defaultTime, setDefaultTime] = useState("02:00");
  const [rowData, setRowData] = useState({});
  const { selectedRowsClear } = useLookTableParentContext();
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowIds, setRowIds] = useState([]);
  const [visibleAdsDrawer, setVisibleAdsDrawer] = useState(false);
  const [domain, setDomain] = useState({});
  const currentSorter = useSelector((state) => state?.userSettings?.sorter?.[persistKey]);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const mainPagination = useSelector((state) => state?.userSettings?.mainPagination);
  const mainPaginationData = useSelector(
    (state) => state?.userSettings?.mainPagination?.[persistKey]
  );
  const sort = currentSorter?.order ? { ...currentSorter } : {};
  // GQL_UMS_LANGUAGES

  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);
  const currentProductId = productList?.[0]?.id;
  const apiPayload = {
    page: mainPaginationData?.page,
    size: mainPaginationData?.size,
    search,
    ...sort,
  };
  const [fetchVsContent, { loading: vs_content__loading, refetch: vs_content_refetch }] =
    useCustomLazyQuery(GET_VS_ALL_CONTENT_BY_ADVETTISER, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.vs_contentGetAllByAdvertiserId?.data?.map((element) => ({
          name: element?.nickname,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });

  const [fetchSasCategory, { loading: sas_loading, error: sas_error, refetch: sas_refetch }] =
    useCustomLazyQuery(GET_SAS_CATEGORIES, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.sasCategories?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });

  const [
    fetchLeadgen,
    { loading: leadgen_loading, error: leadgen_error, refetch: leadgen_refetch },
  ] = useCustomLazyQuery(GET_VOLUUM_CATEGORY, {
    notifyOnNetworkStatusChange: true,
    onCompleted(e) {
      const renderData = e?.voluum_get_category?.map((element) => ({
        name: element?.name,
        value: element?.id,
      }));
      setCategories(renderData);
    },
  });

  const { loading: loading_GET_Country_DATA, refetch: refetch_GET_Country_DATA } = useCustomQuery(
    TRACKER_COUNTRIES_LIST,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.umsCountries;
        if (element) {
          setCountries(element);
        }
      },
      variables: {},
    }
  );

  const { loading: loading_GET_LANGAUGE_DATA, refetch: refetch_GET_LAUNGUAGE_DATA } =
    useCustomQuery(GQL_UMS_LANGUAGES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.umsLanguages;
        if (element) {
          setLanguages(element);
        }
      },
      variables: {},
    });

  const [fetchAdsCount, { loading: loading_ADS_COUNT, refetch: refetch_ADS_COUNT }] =
    useCustomLazyQuery(INDUSTRY_INSIGHTS_ADS_COUNT, {
      notifyOnNetworkStatusChange: true,
    });

  const getCategories = () => {
    if (currentProductId == PRODUCT_ID_LEADGEN) fetchLeadgen();
    else if (currentProductId == PRODUCT_ID_SAS) fetchSasCategory();
    else if (currentProductId == PRODUCT_ID_VS) fetchVsContent();
  };

  const handleAdCount = (domains) => {
    fetchAdsCount({
      variables: { entityType: ENTITY_TYPE_DOMAIN, entityIds: domains?.map((data) => data?.id) },
      onCompleted: (e) => {
        const element = e?.cs_AdCount?.data;
        if (element?.length > 0) {
          let hashTable = {};
          for (let i = 0; i < element?.length; i++) {
            const data = element[i]?.entityId;
            hashTable[data] = element[i]?.adCount;
          }

          const updatedDomain = domains?.map((data) => {
            const element = data?.id;
            return { ...data, adCount: hashTable[element] || 0 };
          });
          setKeywordDomainsData(updatedDomain);
        } else {
          setKeywordDomainsData(domains);
        }
      },
      onError: () => {
        setKeywordDomainsData(domains);
        openNotification({ type: "error", message: "AdCount fetch failed" });
      },
    });
  };

  const {
    data,
    loading,
    refetch: refetch_GET_KEYWORDS_DOMAINS,
  } = useCustomQuery(INDUSTRY_INSIGHTS_GET_KEYWORDS_DOMAINS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ii_KeywordsDomains;
      if (element) {
        if (element?.status === SUCCESS) {
          handleAdCount(element?.data?.data);
        } else if (element?.status === FAILURE) {
          openNotification({ type: "error", message: element?.message, key: "error-add" });
        }
      }
    },
    onError: (err) => {
      openNotification({ type: "error", message: err.message });
    },
    variables: {
      ...apiPayload,
    },
  });
  const fetchDatakey = data?.ii_KeywordsDomains?.data;
  useEffect(() => {
    if (!loading && !loading_ADS_COUNT) {
      dispatch({
        type: USER_SETTING_MAIN_PAGINATION,
        payload: {
          ...mainPagination,
          [persistKey]: {
            ...mainPagination?.main,
            totalCount: fetchDatakey?.totalRecords,
          },
        },
      });
    }
  }, [loading, fetchDatakey, loading_ADS_COUNT]);

  const refetch = () => {
    refetch_GET_KEYWORDS_DOMAINS();
  };

  const [addDomain, { loading: loading_addDomain, refetch: addDomain_refetch }] = useCustomMutation(
    INDUSTRY_INSIGHTS_ADD_DOMAINS,
    {
      onCompleted: (e) => {
        const element = e?.ii_addKeywordsDomains;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch();
          onCloseAddDomainModal();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const [deleteDomain, { loading: loading_deleteDomain }] = useCustomMutation(
    INDUSTRY_INSIGHTS_DELETE_DOMAINS,
    {
      onCompleted: (e) => {
        const element = e?.ii_deleteKeywordsDomains;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch();
          setSelectedRows([]);
          selectedRowsClear();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const [addNotification, { loading: loading_addNotification, refetch: refetch_addNotification }] =
    useCustomMutation(INDUSTRY_INSIGHTS_ADD_NOTIFICATION, {
      onCompleted: (e) => {
        const element = e?.ii_addNotification;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch();
          onCloseNotifyMeFormModal();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [updateDomain, { loading: loading_updateDomain, refetch: refetch_updateDomain }] =
    useCustomMutation(INDUSTRY_INSIGHTS_DOMAIN_UPDATE, {
      onCompleted: (e) => {
        const element = e?.ii_updateKeywordsDomains;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch();
          onCloseNotifyMeFormModal();
          setSelectedRows([]);
          selectedRowsClear();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [
    updateNotification,
    { loading: loading_updateNotification, refetch: refetch_updateNotification },
  ] = useCustomMutation(INDUSTRY_INSIGHTS_UPDATE_DOMAINS_NOTIFICATION, {
    onCompleted: (e) => {
      const element = e?.ii_updateKeyDomNotification;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        selectedRowsClear();
        refetch();
        onCloseNotifyMeFormModal();
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps, { index }) => {
        const poverWrapperOptions = [
          {
            option: "spy",
            title: (
              <>
                Insights
                <Switch
                  size="small"
                  className="ml-4"
                  checked={actionProps?.staleStatus === INDUSTRY_ISIGHTS_STATUS_ACTIVE}
                  onChange={(e) => ""}
                />
              </>
            ),
            optionProps: {
              onClick: () =>
                updateDomain({
                  variables: {
                    keyDomIds: actionProps?.id,
                    stale: actionProps?.staleStatus === INDUSTRY_ISIGHTS_STATUS_ACTIVE,
                  },
                }),
            },
          },
          {
            option: "notification",
            title: "Notify me",
            optionProps: {
              onClick: () => showNotifyMeFormModal(actionProps),
            },
          },
          {
            option: "file-search",
            title: "View Ads",
            optionProps: {
              onClick: () => showDrawerAds(actionProps),
            },
          },
          {
            option: "delete",
            title: "Delete",
            optionProps: {
              onClick: () => deleteDomain({ variables: { keyDomIds: actionProps?.id } }),
            },
          },
        ];
        return (
          <>
            <PopoverWrapper options={poverWrapperOptions}>
              <EllipsisOutlined className="pop-info" />
            </PopoverWrapper>
          </>
        );
      },
      fixed: "right",
      width: 124,
      minWidth: 124,
    },
  ];
  const initialColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: true,
      searchType: "string",
    },
    {
      title: "Url",
      dataIndex: "url",
      key: "url",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: true,
      searchType: "string",
    },
    {
      title: "Pages",
      dataIndex: "pages",
      key: "pages",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 160,
      minWidth: 160,
      sorter: true,
      searchType: "number",
    },
    {
      title: "No. of ads",
      dataIndex: "adCount",
      key: "adCount",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: true,
      searchType: "number",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: true,
      searchType: "string",
    },

    {
      title: "Status",
      dataIndex: "staleStatus",
      key: "staleStatus",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: true,
      searchType: "string",
    },
    {
      title: "Fetch Data",
      dataIndex: "domainFetch",
      key: "domainFetch",
      render: (value, otherProps) => {
        return (
          <>
            <DomainLoading id={otherProps?.id} />
          </>
        );
      },
      width: 160,
      minWidth: 160,
      sorter: true,
      searchType: "string",
      fixed: "right",
    },
    ...action,
  ];

  const showNotifyMeFormModalForBulk = (e) => {
    const rowIds = e?.map((item) => item?.id);
    setRowIds(rowIds);
    setDefaultTime("02:00");
    setNotifyAbout([INDUSTRY_ISIGHTS_TYPE_DOMAIN]);
    setVisibleNotifyMeModal(true);
  };
  const bulkActions = [
    {
      option: "pause",
      title: "Insights Off",
      onClick: (e) => {
        const domainsIds = e?.map((item) => item?.id);
        updateDomain({
          variables: { keyDomIds: domainsIds, stale: true },
        });
      },
    },

    {
      option: "play",
      title: "Insights On",
      onClick: (e) => {
        const domainsIds = e?.map((item) => item?.id);
        updateDomain({
          variables: { keyDomIds: domainsIds, stale: false },
        });
      },
    },

    {
      option: "notification",
      title: "Notify me",
      onClick: (e) => showNotifyMeFormModalForBulk(e),
    },
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => {
        const domainsIds = e?.map((item) => item?.id);
        deleteDomain({ variables: { keyDomIds: domainsIds } });
      },
    },
  ];

  const showDrawerAds = (data) => {
    setVisibleAdsDrawer(true);
    setDomain({ Domain: { [data?.id]: true } });
  };
  const onCloseDrawerAds = () => {
    setVisibleAdsDrawer(false);
    setDomain([]);
  };

  const finalMergeData = [];
  keywordsDomainsData?.forEach((element, index) => {
    const {
      pages = 0,
      no_of_ads = 0,
      name = "",
      category = "",
      url = "",
      staleStatus = true,
    } = element;
    finalMergeData?.push({
      ...element,
      key: index,
      pages,
      no_of_ads,
      name,
      category,
      url,
      staleStatus: staleStatus ? "INACTIVE" : "ACTIVE",
    });
  });

  const dataRender = outerFilterData(filterData(finalMergeData), {
    filterKeys: ["name"],
  });

  const bulkActionProps = [
    {
      selectedRows: selectedRows,
      onDismiss: () => {
        setSelectedRows([]);
      },
      actionOptions: bulkActions,
    },
  ];

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    persistPagination: true,
    bulkSelectionAction: bulkActionProps,
    refetch,
    loading: loading || loading_ADS_COUNT,
    bulkActions,
    tableProps: {
      initialColumns,
      recordData: dataRender,
    },
    persistKey,
  };

  const showAddDomainModal = () => {
    getCategories();
    setAddDomainVisibleModal(true);
  };

  const onCloseAddDomainModal = () => {
    setAddDomainVisibleModal(false);
  };

  const createBtnProps = {
    createBtn: true,
    createBtnText: "Add New Domain",
    createBtnHandler: showAddDomainModal,
  };

  const addDomainModalProps = {
    title: "Add New Domain",
    visible: visibleAddDomainModal,
    footer: null,
    onCancel: onCloseAddDomainModal,
  };

  const initialValues = {
    advertiser: "",
    business: "",
    name: "",
    no_of_ads: "",
    url: "",
    categoryId: [],
    language_code: [],
  };
  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
    url: yup.string().required().label("URL"),
    categoryId: yup
      .array()
      .of(yup.object())
      .min(1, "Please select at least one Category")
      .nullable()
      .label("Category"),
  });
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const addDomainsPayload = {
      advertiser: values?.advertiser,
      business: values?.business,
      category: values?.categoryId,
      countries: values?.country,
      isDomain: true,
      ...(values?.language_code && { languages: values?.language_code }),
      name: values?.name,
      searchText: values?.url,
    };
    addDomain({ variables: addDomainsPayload });
  }

  const addDomainFormProps = {
    initialValues,
    validationSchema,
    onSubmit,
    loading: vs_content__loading || sas_loading || leadgen_loading || loading_addDomain,
    categories,
    countries,
    countryListLoading: loading_GET_Country_DATA,
    languages,
    languageListLoading: loading_GET_LANGAUGE_DATA,
    onClose: onCloseAddDomainModal,
  };
  function onNotifyFormValueSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;

    const rowDataIds = editMode
      ? { keyDomIds: rowData?.id || rowIds, toBeNotify: true }
      : { keyDomId: rowData?.id };
    const addNotificationVaribles = {
      day: values?.day,
      defaultFrequency: values?.frequency,
      time: defaultTime,
      notifyAbout: notifyAbout,
      preference: prefrences,
      searchType: INDUSTRY_ISIGHTS_TYPE_DOMAIN,
      ...rowDataIds,
    };
    editMode
      ? updateNotification({ variables: addNotificationVaribles })
      : addNotification({ variables: addNotificationVaribles });
  }

  const showNotifyMeFormModal = (rowData) => {
    let uniqueArray = [
      ...new Set([
        INDUSTRY_ISIGHTS_TYPE_DOMAIN,
        ...(rowData?.notificationDetails?.notifyAbout || []),
      ]),
    ];
    setNotifyAbout(uniqueArray);
    setPrefrences([...(rowData?.notificationDetails?.preference || [])]);
    setDefaultTime(rowData?.notificationDetails?.defaultSetting?.time || "02:00");
    setRowData(rowData);
    setVisibleNotifyMeModal(true);
  };

  const onCloseNotifyMeFormModal = () => {
    setVisibleNotifyMeModal(false);
    let defaultTime = editMode ? "" : "02:00";
    setDefaultTime(defaultTime);
    setNotifyAbout([]);
    setPrefrences([]);
    setRowData({});
    setRowIds([]);
  };

  const editMode = !(rowData?.notificationDetails === null);

  const notifyMeFormInitialValues = editMode
    ? {
        frequency: rowData?.notificationDetails?.defaultSetting?.default_frequency?.split(".")[1],
        day: rowData?.notificationDetails?.defaultSetting?.day,
        // notifyAbout: rowData?.notificationDetails?.notifyAbout,
        // preference: rowData?.notificationDetails?.preference,
      }
    : {
        frequency: [],
        day: [],
        notifyAbout: notifyAbout,
        preference: prefrences,
      };

  const notifyMeFormValidationSchema = yup.object({
    frequency: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least one Frequency")
      .nullable()
      .label("Frequency"),
    day: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least one Day")
      .nullable()
      .label("Day"),
  });
  const notifyMeFormProps = {
    initialValues: notifyMeFormInitialValues,
    validationSchema: notifyMeFormValidationSchema,
    onSubmit: onNotifyFormValueSubmit,
    onClose: onCloseNotifyMeFormModal,
    notifyAbout: notifyAbout,
    setNotifyAbout,
    defaultType: INDUSTRY_ISIGHTS_TYPE_DOMAIN,
    prefrences: prefrences,
    setPrefrences,
    defaultTime,
    setDefaultTime,
    btnText: editMode ? "Update" : "Add",
  };
  const notifyMeModalProps = {
    title: editMode ? "Edit frequency" : "Select frequency",
    visible: visibleNotifyMeModal,
    footer: null,
    onCancel: onCloseNotifyMeFormModal,
  };
  const drawerProps = {
    title: `${Object.keys(domain?.Domain || {})?.[0]}`,
    visible: visibleAdsDrawer,
    onClose: onCloseDrawerAds,
    size: 1094,
  };
  return (
    <div>
      <DashboardHeader title="Industry Insights" {...createBtnProps} />
      <LookTable {...tableMainProps} />
      {visibleAdsDrawer && (
        <LookDrawer {...drawerProps}>
          <Ads filteredData={domain} viewAdsProp />
        </LookDrawer>
      )}
      {visibleAddDomainModal && (
        <LookModal {...addDomainModalProps}>
          <MainForm {...addDomainFormProps} />
        </LookModal>
      )}

      {visibleNotifyMeModal && (
        <LookModal {...notifyMeModalProps}>
          <NotifyMe {...notifyMeFormProps} />
        </LookModal>
      )}
    </div>
  );
}

export default Domains;
