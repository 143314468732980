import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { usePermissionCheck } from "hooks";
import React, { useState } from "react";
import {
  CREATIVE_TRACKING_BUSINESS_DEVELOPER,
  CREATIVE_TRACKING_CONTENT_WRITER,
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  CREATIVE_TRACKING_MEDIA_BUYER,
} from "shared/UserPermission/userPermissionList";
import RaiseRequestForm from "../Category/RaiseRequest";

function useRequestDrawer() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const { havePermission: isBusinessDeveloper } = usePermissionCheck(
    CREATIVE_TRACKING_BUSINESS_DEVELOPER
  );
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const { havePermission: isMediaBuyer } = usePermissionCheck(CREATIVE_TRACKING_MEDIA_BUYER);
  const { havePermission: isContentWriter } = usePermissionCheck(CREATIVE_TRACKING_CONTENT_WRITER);
  const { havePermission: isGraphicDesigner } = usePermissionCheck(
    CREATIVE_TRACKING_GRAPHIC_DESIGNER
  );
  const permissionProps = {
    isMediaBuyer,
    isCreativeDirector,
    isGraphicDesigner,
    isContentWriter,
    isBusinessDeveloper,
  };
  const createRequest = (data) => {
    setVisibleDrawer(true);
    setSelectedRowsData(data);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    setSelectedRowsData([]);
  };
  let breadCrumb = ["Creative Dashboard", "Creative Requests"];
  if (selectedRowsData?.name) {
    breadCrumb.push(`${selectedRowsData?.name}`);
  }
  const drawerProps = {
    textBreadcrumb: breadCrumb,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 770,
  };

  function createRequestForm() {
    return (
      <>
        <LookDrawer {...drawerProps}>
          {visibleDrawer && (
            <LookDrawerMainSideBar
              tabContent={[
                {
                  hoverTitle: "Create request",
                  icon: () => <ActionIconRender iconType="pic-left" />,
                  noSpace: true,
                  component: (
                    <RaiseRequestForm
                      {...selectedRowsData}
                      isCreativeRequest
                      onCloseDrawer={onCloseDrawer}
                      {...permissionProps}
                    />
                  ),
                },
              ]}
            />
          )}
          {/* {visibleDrawer && <RaiseRequestForm />} */}
        </LookDrawer>
      </>
    );
  }
  return { createRequestForm, createRequest };
}

export default useRequestDrawer;
