import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "cx";

const valueCheck = (value, checkTotal = false) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(2);
  return valueFloat;
};

const columns = [
  {
    title: "Spend",
    dataIndex: "spend",
    key: "spend",
    render: (value) => <LookTableColumnRender title={<>${valueCheck(value, true)}</>} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.spend - b?.spend,
    searchType: "number",
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    key: "revenue",
    render: (value, otherProps) => <LookTableColumnRender title={`$${valueCheck(value, true)}`} />,
    width: 115,
    minWidth: 115,
    sorter: (a, b) => a?.revenue - b?.revenue,
    searchType: "number",
  },
  {
    title: "Gross Margin",
    dataIndex: "PROFIT",
    key: "PROFIT",
    render: (value, otherProps) => {
      const profit = `$${value.toFixed(2)}`;

      return (
        <LookTableColumnRender
          title={
            <strong
              className={cx({
                "text-primary": parseFloat(value) > 0,
                "text-danger": parseFloat(value) < 0,
              })}
            >
              {profit?.replace("-", "")}
            </strong>
          }
        />
      );
    },
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.PROFIT - b?.PROFIT,
    searchType: "number",
  },
  {
    title: "ROI",
    dataIndex: "ROI",
    key: "ROI",
    render: (value) => <LookTableColumnRender title={`${valueCheck(value, true)}%`} />,
    width: 130,
    minWidth: 130,
    sorter: (a, b) => a?.ROI - b?.ROI,
    searchType: "number",
  },
  {
    title: "clicks (T)",
    dataIndex: "clicksTiktok",
    key: "clicksTiktok",
    render: (value) => <LookTableColumnRender title={value} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.clicksTiktok - b?.clicksTiktok,
    searchType: "number",
  },
  {
    title: "Clicks (V)",
    dataIndex: "clicks",
    key: "clicks",
    render: (value) => <LookTableColumnRender title={value} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.clicks - b?.clicks,
    searchType: "number",
  },
  {
    title: "Searches",
    dataIndex: "searches",
    key: "searches",
    render: (value) => <LookTableColumnRender title={value} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.searches - b?.searches,
    searchType: "number",
  },

  {
    title: "Reach",
    dataIndex: "reach",
    key: "reach",
    render: (value) => <LookTableColumnRender title={value} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.reach - b?.reach,
    searchType: "number",
  },

  {
    title: "CPR (V)",
    dataIndex: "CPR_V",
    key: "CPR_V",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.CPR_V - b?.CPR_V,
    searchType: "number",
  },

  {
    title: "CPV",
    dataIndex: "CPV",
    key: "CPV",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 70,
    minWidth: 70,
    sorter: (a, b) => a?.CPV - b?.CPV,
    searchType: "number",
  },
  {
    title: "RPV",
    dataIndex: "RPV",
    key: "RPV",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 70,
    minWidth: 70,
    sorter: (a, b) => a?.RPV - b?.RPV,
    searchType: "number",
  },
  {
    title: "RPL",
    dataIndex: "RPL",
    key: "RPL",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 70,
    minWidth: 70,
    sorter: (a, b) => a?.RPL - b?.RPL,
    searchType: "number",
  },

  {
    title: "AD CTR",
    dataIndex: "AD_CTR",
    key: "AD_CTR",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
    searchType: "number",
  },
  {
    title: "LP CTR",
    dataIndex: "LP_CTR",
    key: "LP_CTR",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
    searchType: "number",
  },

  {
    title: "CR",
    dataIndex: "CR",
    key: "CR",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 70,
    minWidth: 70,
    sorter: (a, b) => a?.CR - b?.CR,
    searchType: "number",
  },
  {
    title: "Impressions",
    dataIndex: "impressionsTiktok",
    key: "impressionsTiktok",
    render: (value) => <LookTableColumnRender title={value} />,
    width: 100,
    minWidth: 100,
    sorter: (a, b) => a?.impressionsTiktok - b?.impressionsTiktok,
    searchType: "number",
  },

  {
    title: "CPM",
    dataIndex: "CPM",
    key: "CPM",
    render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
    width: 70,
    minWidth: 70,
    sorter: (a, b) => a?.CPM - b?.CPM,
    searchType: "number",
  },

  {
    title: "V Visits",
    dataIndex: "visits",
    key: "visits",
    render: (value) => <LookTableColumnRender title={value} />,
    width: 90,
    minWidth: 90,
    sorter: (a, b) => a?.visits - b?.visits,
    searchType: "number",
  },
];

export default columns;
