import route, { ROUTE_DASHBOARD } from "../route";
import { SERVICE_TYPE_LEAD_GEN } from "shared/enum/launchKeysAndEnum";
import { PRODUCT_ID_LEADGEN } from "shared/SharedKeys";
import GoogleLaunchV1 from "components/Launch/GoogleLaunchV1";
import GoogleLaunchListing, {
  GOOGLE_LAUNCH_LIST_TYPE_LAUNCHER,
  GOOGLE_LAUNCH_LIST_TYPE_SNIPPET,
} from "components/Launch/GoogleLaunchListing";

const serviceRoutes = [
  {
    serviceName: "Leadgen",
    serviceRoute: "leadgen",
    serviceType: SERVICE_TYPE_LEAD_GEN,
    productId: PRODUCT_ID_LEADGEN,
    // permission: F360_MANAGE_LEADGEN_REPORT,
  },
];

let googleLaunchRoutes = [];

serviceRoutes?.forEach(({ serviceRoute, serviceName, serviceType, productId, permission }) => {
  googleLaunchRoutes.push(
    route(
      `/${serviceRoute}/google-launch/campaign`,
      () => (
        <GoogleLaunchV1
          serviceName={serviceName}
          serviceType={serviceType}
          productId={productId}
          serviceRoute={serviceRoute}
        />
      ),
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/google-launch/snippet`,
      (e) => <GoogleLaunchListing {...e} listType={GOOGLE_LAUNCH_LIST_TYPE_SNIPPET} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/google-launch/list`,
      (e) => <GoogleLaunchListing {...e} listType={GOOGLE_LAUNCH_LIST_TYPE_LAUNCHER} />,
      ROUTE_DASHBOARD,
      false,
      permission
    )
  );
});

export default googleLaunchRoutes;
