import React, { useState, useContext } from "react";
import { Row, Checkbox, Collapse } from "antd";
import ActionIconRender from "components/ActionIconRender";
import { LookChartLine } from "components/LookChart";
import { LookChartPatternColors } from "components/LookChart/LookChartTheme";
import { TableContext } from "../fields";
import cx from "classnames";

const { Panel } = Collapse;

const valueCheck = (value, checkTotal = false) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(2);
  return valueFloat;
};

export default function RenderLineChart(props) {
  const {
    initialColumns = [],
    recordData = [],
    summaryData = {},
    isVisibleChart = false,
  } = useContext(TableContext) || {};

  let chartData = [];

  const chartColumns = initialColumns?.filter((data) => data.inChart);

  const [visibleColumns, setVisibleColumns] = useState(
    chartColumns.map((data) => ({ ...data, visible: true }))
  );

  recordData?.forEach((d) => {
    visibleColumns.forEach((dd) => {
      const { title = "", dataIndex, visible = true } = dd;
      if (visible) {
        chartData.push({ date: d.date, value: valueCheck(d[dataIndex]), type: title || dataIndex });
      }
    });
  });

  function handleVisibleCoulmns(colummn, index, isVisible) {
    const temp = [...visibleColumns];
    temp[index] = { ...temp[index], visible: isVisible };
    setVisibleColumns(temp);
  }

  const renderSummaryContentItem = (dataIndex) => {
    const value = summaryData[dataIndex];
    if (dataIndex.toLowerCase().includes("spend") || dataIndex.toLowerCase().includes("spendFB")) {
      return <>${value}</>;
    }
    if (dataIndex.toLowerCase() === "PROFIT".toLowerCase()) {
      return (
        <span
          className={cx({ "text-primary": Number(value) > 0, "text-danger": Number(value) < 0 })}
        >
          ${value.replace("-", "")}
        </span>
      );
    }
    if (dataIndex.toLowerCase() === "ROI".toLowerCase()) {
      return <>{value}%</>;
    }
    return value;
  };

  function isSummary(value) {
    if (typeof value === "number" || value) return true;
    return false;
  }

  return (
    <div>
      <Collapse
        activeKey={isVisibleChart ? "1" : "0"}
        className="performance-chart-colapse border-0"
      >
        <Panel key="1">
          <div>
            <Row className="px-6">
              {visibleColumns?.map((data, index) => {
                const { title = "", dataIndex, visible = true } = data;

                let iconName = "";
                if (dataIndex === "spend" || dataIndex === "spendFB") iconName = "creditCard";
                if (dataIndex === "PROFIT") iconName = "barChartLine";
                if (
                  dataIndex.toLowerCase() === "Revenue".toLowerCase() ||
                  dataIndex.toLowerCase() === "TotalRevenue".toLowerCase() ||
                  dataIndex.toLowerCase() === "estimated_revenue".toLowerCase() ||
                  dataIndex.toLowerCase() === "revenueVL".toLowerCase()
                ) {
                  iconName = "dollar";
                }
                if (dataIndex.toLowerCase() === "ROI".toLowerCase()) iconName = "rise";
                if (dataIndex.toLowerCase() === "leadsFB".toLowerCase())
                  iconName = "unordered-list";

                return (
                  <div
                    key={"visibleColumns" + index}
                    className="flex-col-var px-6"
                    style={{ "--width": `${100 / visibleColumns?.length}%` }}
                  >
                    <div
                      className={cx("LookChartLegendButton", { active: visible })}
                      style={{ "--itemColor": LookChartPatternColors[index] }}
                    >
                      <div className="d-flex">
                        <ActionIconRender className="mt-2 mr-10 text-primary" iconType={iconName} />
                        <div>
                          <p className="title mb-0">{title || dataIndex}</p>
                          {summaryData && isSummary(summaryData[dataIndex]) ? (
                            <p className="summaryData mb-0">
                              {renderSummaryContentItem(dataIndex)}
                            </p>
                          ) : null}

                          <Checkbox
                            checked={visible}
                            onClick={() => handleVisibleCoulmns(dataIndex, index, !visible)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Row>
            <LookChartLine data={chartData} visibleColumns={visibleColumns} isColorLimit />
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
