import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { usePermissionCheck } from "hooks";
import React, { useState } from "react";
import {
  CREATIVE_TYPE_AUDIO,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_TEXT,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import {
  CREATIVE_TRACKING_CONTENT_WRITER,
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  CREATIVE_TRACKING_MEDIA_BUYER,
} from "shared/UserPermission/userPermissionList";
import Media from "../MediaLibrary/Media";

export default function useMediaDrawer(props = {}) {
  const { finalCreativesData = [], visuals = [] } = props;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [rowProps, setRowProps] = useState({});

  const openMediaDrawer = (data) => {
    setVisibleDrawer(true);
    setRowProps(data);
  };
  const [isSearch, setIsSearch] = useState(false);
  const [searchItem, setSearchItem] = useState([]);
  const [searchMode, setSearchMode] = useState("tags");
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const { havePermission: isMediaBuyer } = usePermissionCheck(CREATIVE_TRACKING_MEDIA_BUYER);
  const { havePermission: isContentWriter } = usePermissionCheck(CREATIVE_TRACKING_CONTENT_WRITER);
  const { havePermission: isGraphicDesigner } = usePermissionCheck(
    CREATIVE_TRACKING_GRAPHIC_DESIGNER
  );
  const permissionProps = {
    isMediaBuyer,
    isCreativeDirector,
    isGraphicDesigner,
    isContentWriter,
    isSearch,
    setIsSearch,
    searchItem,
    setSearchItem,
    searchMode,
    setSearchMode,
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
    setRowProps({});
  };

  const tabContents = [
    ...(rowProps?.creativeType === CREATIVE_TYPE_VIDEO || rowProps?.isMediaLibrary
      ? [
          {
            noSpace: true,
            id: "Video",
            hoverTitle: "Video",
            icon: (e) => <ActionIconRender iconType="video-camera" {...e} />,
            component: (
              <Media
                {...rowProps}
                mediaType={CREATIVE_TYPE_VIDEO}
                creativeType={CREATIVE_TYPE_VIDEO}
                {...permissionProps}
                setRowProps={setRowProps}
                onCloseDrawer={onCloseDrawer}
                finalCreativesData={finalCreativesData}
                visuals={visuals}
              />
            ),
          },
        ]
      : []),
    ...(rowProps?.creativeType === CREATIVE_TYPE_IMAGE || rowProps?.isMediaLibrary
      ? [
          {
            noSpace: true,
            id: "Image",
            hoverTitle: "Image",
            icon: (e) => <ActionIconRender iconType="image" {...e} />,
            component: (
              <Media
                {...rowProps}
                mediaType={CREATIVE_TYPE_IMAGE}
                creativeType={CREATIVE_TYPE_IMAGE}
                {...permissionProps}
                setRowProps={setRowProps}
                finalCreativesData={finalCreativesData}
              />
            ),
          },
        ]
      : []),
    ...(rowProps?.creativeType === CREATIVE_TYPE_AUDIO || rowProps?.isMediaLibrary
      ? [
          {
            noSpace: true,
            id: "Audio",
            hoverTitle: "Audio",
            icon: (e) => <ActionIconRender iconType="music" {...e} />,
            component: (
              <Media
                {...rowProps}
                mediaType={CREATIVE_TYPE_AUDIO}
                creativeType={CREATIVE_TYPE_AUDIO}
                {...permissionProps}
                setRowProps={setRowProps}
                finalCreativesData={finalCreativesData}
              />
            ),
          },
        ]
      : []),
    ...(rowProps?.creativeType === CREATIVE_TYPE_TEXT || rowProps?.isMediaLibrary
      ? [
          {
            noSpace: true,
            id: "Texts",
            hoverTitle: "Texts",
            icon: (e) => <ActionIconRender iconType="font-size" {...e} />,
            component: (
              <Media
                {...rowProps}
                mediaType={CREATIVE_TYPE_TEXT}
                creativeType={CREATIVE_TYPE_TEXT}
                {...permissionProps}
                setRowProps={setRowProps}
                finalCreativesData={finalCreativesData}
              />
            ),
          },
        ]
      : []),
  ];

  const [activeTab, setActiveTab] = useState(tabContents?.[0]?.id);

  const drawerProps = {
    textBreadcrumb: [`${rowProps?.requestData?.categoryName}`, "Media library"],
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 1000,
  };

  function mediaDrawer(mediaDrawerProps = {}) {
    return (
      <>
        <LookDrawer {...drawerProps}>
          {visibleDrawer && (
            <LookDrawerMainSideBar
              tabContent={tabContents}
              value={activeTab}
              onChange={setActiveTab}
            />
          )}
        </LookDrawer>
      </>
    );
  }

  return { openMediaDrawer, mediaDrawer };
}
