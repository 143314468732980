import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { openNotification } from "utils";
import { useSelector } from "react-redux";
import DebugPre from "components/DebugPre";
import { GQL_GET_LAUNCH_CREATIVE_GROUPS } from "shared/gql/gqlSchema/launchGql";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import DetailView from "./DetailView";
import { Button, Tooltip } from "antd";

function CreativeGroups({ productId, selectedPlatform }) {
  const [dataRender, setDataRender] = useState([]);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRange } = userSettings || {};
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [rowPropsData, setRowProps] = useState({});

  let reportObject = {
    reportApi: GQL_GET_LAUNCH_CREATIVE_GROUPS,
    reportApiKey: "launchCreativeGroups",
    reportApiVariables: {
      platformType: selectedPlatform,
      startDate: dateRange?.rangeDates[0],
      endDate: dateRange?.rangeDates[1],
      productId,
    },
  };

  const { loading, refetch } = useCustomQuery(reportObject?.reportApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted(e) {
      const renderData = e?.[reportObject?.reportApiKey];
      if (renderData) {
        setDataRender(
          renderData?.map((item) => ({
            ...item,
            title: item?.group?.title?.title,
            body: item?.group?.body?.body,
            video: item?.group?.video,
            image: item?.group?.image,
            createdAt: item?.group?.createdAt,
          }))
        );
      }
    },
    onError: (e) => {
      openNotification({ type: "error", message: e?.message });
    },
    variables: {
      ...reportObject?.reportApiVariables,
    },
  });

  const initialColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      fixed: "left",
    },

    {
      title: "Body",
      dataIndex: "body",
      key: "body",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },

    {
      title: "Total Count",
      dataIndex: "totalCount",
      key: "totalCount",
      searchable: true,
      render: (value) => {
        return <LookTableColumnRender title={value} />;
      },
      width: 75,
      minWidth: 75,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (value, otherProps) => {
        return (
          <div className="action-column d-flex">
            <div className="more-actions flex-around">
              <Tooltip title="View media">
                <Button
                  className="ml-12 p-0 h-auto w-auto"
                  type="link"
                  onClick={() => showDrawer(otherProps)}
                  icon={<ActionIconRender iconType="view" />}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
      fixed: "right",
      width: 75,
      minWidth: 75,
    },
  ];

  const tableProps = {
    initialColumns,
    recordData: dataRender,
  };

  const showDrawer = (rowProps = {}) => {
    setRowProps({ ...rowProps });
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setRowProps({});
    setVisibleDrawer(false);
  };

  const detailViewDrawerProps = {
    title: "Overview",
    icon: () => <ActionIconRender className="mr-10" iconType="pic-left" />,
    size: 900,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
  };

  return (
    <div className="mt-20">
      <h1>Creative Groups</h1>
      <LookTable
        isSrNo
        isRowSelection={false}
        refetch={refetch}
        loading={loading}
        tableProps={tableProps}
        uniqueKey="department-score"
        fixedScrollHeight={200}
      />

      <LookDrawer {...detailViewDrawerProps}>
        {visibleDrawer && (
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: `Overview ${-rowPropsData?.title}`,
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: <DetailView {...rowPropsData} />,
              },
            ]}
          />
        )}
      </LookDrawer>
      <DebugPre content={[{ isRow: true, contentObjects: [{ dataRender }] }]} />
    </div>
  );
}

export default CreativeGroups;
