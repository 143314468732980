// Commnets enum
export const COMMENTS_ADSREPORT = "adsReport";
export const COMMENTS_ADSETREPORT = "adsetReport";
export const COMMENTS_CAMPAIGNREPORT = "campaignReport";
export const COMMENTS_USERREPORT = "userReport";
export const COMMENTS_CATEGORY_COUNTRY = "categoryCountry";

//Table enum
export const COMMENTS_FBDAILY = "fbDaily";
export const COMMENTS_FBLIVE = "fbLive";
export const COMMENTS_FBPOSTBACK = "fbPostback";
export const COMMENTS_LEADGEN = "LEADGEN";
export const COMMENTS_GLOBAL = "GLOBAL";

//prorities enum
export const COMMENTS_PRORITY_HIGH = 1;
export const COMMENTS_PRORITY_MEDIUM = 2;
export const COMMENTS_PRORITY_LOW = 3;

//prorities
export const COMMENTS_PRORITIES = [
  { name: "High", value: COMMENTS_PRORITY_HIGH, color: "#E84749" },
  { name: "Medium", value: COMMENTS_PRORITY_MEDIUM, color: "#D89614" },
  { name: "Low", value: COMMENTS_PRORITY_LOW, color: "#3C89E8" },
];
