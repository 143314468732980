import { FB360_GET_PROFILES } from "shared/gql/gqlSchema/fb360Gql";
import {
  GQL_BUCKETS_FOR_DROPDOWN,
  LAUNCH_GET_TEMPLATE_FOR_DROPDOWN,
  LAUNCH_GET_TIKTOK_AD_ACCOUNT,
  LAUNCH_TIKTOK_CONFIG,
  TARGETING_LOCALES,
} from "shared/gql/gqlSchema/launchGql";
import { API_STORE_BUCKETS, API_STORE_LAUNCH_TEMPLATE, API_STORE_TARGETING_LOCALES } from "./keys";
import useApiToStoreQuery from "./useApiToStoreQuery";

export function useLaunchApiParams() {
  const {
    apiCall: tiktokLaunchFetch = () => {},
    loading: tiktokLaunchLoading,
    refetch: tiktokLaunchRefetch,
    setDataSource: tiktokLaunchConfigSource,
  } = useApiToStoreQuery({
    api: LAUNCH_TIKTOK_CONFIG,
    elementKey: "tiktokLaunchConfig",
    storeKey: "tiktokLaunchConfig",
    objectType: true,
  });
  const {
    apiCall: fb360ProfileGetFetch = () => {},
    refetch: fb360ProfileGetRefetch,
    setDataSource: fb360ProfileGetSetDataSource,
  } = useApiToStoreQuery({
    api: FB360_GET_PROFILES,
    elementKey: "fb360_profileGet",
    storeKey: "fb360ProfileGet",
  });
  const {
    apiCall: tiktokGetAccountsFetch = () => {},
    refetch: tiktokGetAccountsRefetch,
    loading: tiktokGetAccountsLoading,
    setDataSource: tiktokGetAccountsSetDataSource,
  } = useApiToStoreQuery({
    api: LAUNCH_GET_TIKTOK_AD_ACCOUNT,
    elementKey: "tiktok_getAccounts",
    storeKey: "tiktokGetAccounts",
  });

  return {
    tiktokLaunchFetch,
    tiktokLaunchLoading,
    tiktokLaunchRefetch,
    tiktokLaunchConfigSource,

    fb360ProfileGetFetch,
    fb360ProfileGetRefetch,
    fb360ProfileGetSetDataSource,

    tiktokGetAccountsFetch,
    tiktokGetAccountsRefetch,
    tiktokGetAccountsSetDataSource,
    tiktokGetAccountsLoading,
  };
}
export default function useLaunchApis(typeKey) {
  switch (typeKey) {
    case API_STORE_TARGETING_LOCALES:
      return {
        api: TARGETING_LOCALES,
        elementKey: "targetingLocales",
        storeKey: "targetingLocales",
      };

    case API_STORE_BUCKETS:
      return {
        api: GQL_BUCKETS_FOR_DROPDOWN,
        elementKey: "buckets",
        storeKey: "buckets",
      };

    case API_STORE_LAUNCH_TEMPLATE:
      return {
        api: LAUNCH_GET_TEMPLATE_FOR_DROPDOWN,
        elementKey: "launchTemplates",
        storeKey: "launchTemplates",
      };

    default:
      return false;
  }
}
