import { Form, Select, Row, Col, Divider } from "antd";
import DebugPre from "../../../DebugPre";
import LookTextInput from "../../../forms/LookTextInput";

const { Option } = Select;

export default function StoreOfferLinksHandle(props) {
  const { stores = [], values, onChange = () => {} } = props;

  return (
    <div>
      <DebugPre content={[{ contentObjects: [{ stores }] }]} />
      {stores.map(({ name = "", id }, index) => (
        <div>
          {index !== 0 && <Divider className="my-16" />}
          <Form layout="vertical">
            <div className="d-flex">
              <div>
                <p className="text-nowrap pt-33 mr-12">
                  <b>{name}</b>
                </p>
              </div>
              <div className="flex-grow-1">
                <Row gutter={24}>
                  <Col span={10}>
                    <LookTextInput
                      label="Offer link"
                      name="offer_link"
                      value={values[id]?.offer_link}
                      onChange={(e) => onChange(e, id, index)}
                      placeholder="Enter offer link"
                      error={values[id]?.isValidLink ? (values[id]?.isValidLink ? "" : "few") : ""}
                    />
                  </Col>
                  <Col span={5}>
                    <LookTextInput
                      label="Offer Price"
                      type="number"
                      name="offer_price"
                      value={values[id]?.offer_price}
                      onChange={(e) => onChange(e, id, index)}
                    />
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Currency" className="look-form-input">
                      <Select
                        name="currency"
                        className="w-100"
                        placeholder="Currency"
                        onChange={(e) =>
                          onChange({ target: { name: "currency", value: e } }, id, index)
                        }
                        value={values[id]?.currency}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="USD">$</Option>
                        <Option value="Rupee">₹</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <LookTextInput
                      label="Strike Price"
                      type="number"
                      name="strike_price"
                      value={values[id]?.strike_price}
                      onChange={(e) => onChange(e, id, index)}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      ))}
    </div>
  );
}
