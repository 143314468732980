import { createContext, useContext } from "react";
import useApiToStore from ".";
import { API_STORE_BUCKETS, API_STORE_LAUNCH_TEMPLATE } from "./keys";
import { useLaunchApiParams } from "./useLaunchApis";
import { useSasApiParams } from "./useSasApis";
import useTrackerApis from "./useTrackerApis";
import { useLeadGenApiParams } from "./useF360Apis";
import { useAdLibraryApiParams } from "./useAdLibraryApis";
import useDmsApis from "./useDmsApis";
import { useUmsApiParams } from "./useUmsApis";
import useCrsApis from "./useCrsApis";
import { useCmsApiParams } from "./useCmsApis";
import useUnicornApiParams from "./useUnicornApis";
import useScorecardApiParams from "./useScorecardApis";
import useCreativetrackingApis from "./useCreativeTrackingApis";
import useGdnApis from "./useGdnApis";
import useGoogleLaunchApis from "./useGoogleLaunchApis";

export const ApiToStoreContext = createContext(null);

export function ApiToStoreContextProvider(props) {
  const { children } = props;

  //Launch
  const {
    apiCall: bucketFetch = () => {},
    refetch: bucketRefetch,
    setDataSource: bucketSetDataSource,
  } = useApiToStore(API_STORE_BUCKETS);
  const {
    apiCall: launchTemplatesFetch = () => {},
    refetch: launchTemplatesRefetch,
    setDataSource: launchTemplatesSetDataSource,
  } = useApiToStore(API_STORE_LAUNCH_TEMPLATE);

  //SAS

  const sasApis = useSasApiParams();

  //Tracker

  const trackerApis = useTrackerApis();
  const launchTiktokApis = useLaunchApiParams();

  //DMS

  const dmsApis = useDmsApis();

  //LeadGen
  const leadGenApis = useLeadGenApiParams();

  //Ad Lib

  const adLibraryApi = useAdLibraryApiParams();

  const umsApi = useUmsApiParams();

  const scorecardApis = useScorecardApiParams();

  const unicornApis = useUnicornApiParams();

  const crsApis = useCrsApis();

  const cmsApis = useCmsApiParams();

  // Creative Tracking
  const creativeTrackingApis = useCreativetrackingApis();

  // GDN Google

  const gdnApis = useGdnApis();

  const glApis = useGoogleLaunchApis();

  const values = {
    //tracker
    ...trackerApis,

    //Launch
    bucketFetch,
    bucketRefetch,
    bucketSetDataSource,

    launchTemplatesFetch,
    launchTemplatesRefetch,
    launchTemplatesSetDataSource,

    // google Launch
    ...gdnApis,

    ...glApis,

    //sas

    ...launchTiktokApis,
    ...sasApis,

    //Leadgen
    ...leadGenApis,

    //adLib
    ...adLibraryApi,

    //DMS
    ...dmsApis,

    //UMS
    ...umsApi,

    //CRS
    ...crsApis,

    //CMS
    ...cmsApis,

    //Scorecard
    ...scorecardApis,

    //Unicorn
    ...unicornApis,

    //Creativetracking
    ...creativeTrackingApis,
  };
  return <ApiToStoreContext.Provider value={values}>{children}</ApiToStoreContext.Provider>;
}

export function useApiToStoreContext() {
  const providerValues = useContext(ApiToStoreContext);
  return { ...providerValues };
}
