import { Form, Button, Alert, Row, Col, DatePicker } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";
import { Formik } from "formik";

export default function MainForm(props) {
  const dateFormat = "YYYY-MM-DD";
  const {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
    defaultselectedDate,
    dateHandle = () => {},
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                type="number"
                name="xRevenue"
                label="X Revenue"
                className="look-form-input"
                placeholder="Enter The X-Revenue"
              />
            </Col>
            <Col span={24}>
              <Form.Item label="Date" className="look-form-input">
                <DatePicker
                  defaultValue={defaultselectedDate}
                  format={dateFormat}
                  onChange={dateHandle}
                  disabledDate={(value) => value > defaultselectedDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
