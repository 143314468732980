import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  PLATFORM_TYPE_FACEBOOK,
  SERVICE_TYPE_LEAD_GEN,
  SERVICE_TYPE_VS,
} from "shared/enum/launchKeysAndEnum";
import { CT_GET_TEMPLATES } from "shared/gql/gqlSchema/creativeGql";
import {
  FETCH_TIKTOK_LAUNCH_TEMPLATE,
  GQL_ADD_ACCOUNT_CACHE,
  GQL_BUCKET,
  GQL_FETCH_AD_ACCOUNT_CACHE,
  GQL_LAUNCH_CATEGORY_TIKTOK_DRAFT,
  GQL_LAUNCH_DRAFT,
  GQL_LAUNCH_VS_BRAND_GET_BY_DOMAIN,
  GQL_SAVE_CATEGORY_TIKTOK_LAUNCH_DRAFT,
  GQL_SAVE_LAUNCH_DRAFT,
  LAUNCH_DATA_AD_ACCOUNT,
  LAUNCH_GET_TEMPLATE,
  LAUNCH_GET_TEMPLATE_FOR_DROPDOWN,
  LAUNCH_GET_TIKTOK_AD_ACCOUNT,
  LAUNCH_GET_TIKTOK_TEMPLATE_FOR_DROPDOWN,
  TIKTOK_LAUNCH_CALL,
} from "shared/gql/gqlSchema/launchGql";
import {
  LAUNCH_GET_ADGROUPS_FOR_KEYWORDS,
  LAUNCH_GET_IMAGES_FOR_KEYWORDS,
} from "shared/gql/gqlSchema/mediaGql";
import {
  GET_CATEGORY_BY_IDS,
  GET_SAS_KEYWORDS_BY_IDS,
  GET_SAS_TRACKING_URL,
} from "shared/gql/gqlSchema/sasGql";
import { SAS_LAUNCH_CALL } from "shared/gql/gqlSchema/searchArbitrageGql";
import { GET_TIKTOK_ACCOUNTS } from "shared/gql/gqlSchema/tiktok";
import {
  GET_VS_CONTENT_GET_BY_ID,
  GET_VS_PLATFORM_FOR_URL_STRUCTURE,
  VS_BRAND_BY_ADVERTISER,
} from "shared/gql/gqlSchema/viralSparksGql";
import { PLATFORM_ID_FACEBOOK, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import {
  CREATIVE_TYPE_BODY,
  CREATIVE_TYPE_DESCRIPTION,
  CREATIVE_TYPE_TITLE,
} from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";
import { CONTINUES_DRAFT, CONTINUES_NEW } from "..";

function useLaunchApiHooks({
  productId,
  serviceType,
  setInputs,
  instantLaunch,
  setVsUrlPlatform,
  setBucketContentData,
  bucketData,
  setBucketData,
  setAdAccountValues,
  setVsBrandByDomains,
  setOptionProps,
  adAccountValues,
  setVisibleTargetModal,
  setSelectedImage,
  isSasCampaign,
  setVisibleGroupModal,
  setAdGroupDataSource,
  initialSetCampaign,
  initialSetCampaignProps,
  setTargettingTemplateData,
  chooseDraftOrNew,
  isTiktokPlatformLaunch,
  inputs,
  serviceRoutes,
  setVisibleModal,
  saveDraft,
  setDraftData,
  setChooseDraftOrNew,
  launchId,
  bucketId,
  setTemplates,
  categoryName,
  setVisibleChooseAdsetModal,
  instantLaunchData,
}) {
  const history = useHistory();
  const [addAccountCache, { loading: adAccountCache_loading }] = useCustomMutation(
    GQL_ADD_ACCOUNT_CACHE,
    {
      onCompleted: (e) => {
        // openNotification({ type: "success", message: "Adaccount Cached Successfully" });
      },
      onError(e) {
        setVisibleModal(false);
        openNotification({ type: "error", message: e.message });
      },
    }
  );
  const [callTemplatesForDropdown, { loading: loading_templates, refetch: refetch_templates }] =
    useCustomLazyQuery(LAUNCH_GET_TEMPLATE_FOR_DROPDOWN, {
      onCompleted(e) {
        const element = e?.launchTemplates;
        if (element) {
          setTemplates(element);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
      variables: { productId },
    });
  const [fetchadAccountCache, { data: adAccountCache, loading: fetchadAccountCache_loading }] =
    useCustomLazyQuery(GQL_FETCH_AD_ACCOUNT_CACHE, {
      onCompleted: (e) => {
        const element = e?.adAccountCache?.payload;
        if (element) {
          delete element?.alias;
          setAdAccountValues({
            ...adAccountValues,
            ...element,
            customConversionId: element?.custom_conversion_id,
            customEventStr: element?.custom_event_str,
            customEventType: element?.custom_event_type,
            epcKey: element?.epc_key,
            identityId: element?.identity_id,
            identityType: element?.identity_type,
            pageId: element?.page_id,
            pixelValue: element?.pixel_value,
            campCounter: element?.camp_counter,
          });
        } else {
          delete element?.alias;
          setAdAccountValues({
            ...adAccountValues,
            ...element,
            pixelValue: "",
            pixel: "",
            customEventType: "",
            pageId: "",
            customConversionId: "",
            customEventStr: "",
            campCounter: "1",
            epcKey: "",
            identityType: "",
            identityId: "",
          });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    });
  const [
    fetchtikTokAdaccount,
    { data: data_adAccount, loading: loading_adAccount, refetch: refetch_adAccount },
  ] = useCustomLazyQuery(GET_TIKTOK_ACCOUNTS, {
    notifyOnNetworkStatusChange: true,
    // onCompleted: (e) => {
    //   setCustomConversionsList(e?.customConversionsPixels);
    //   // openNotification({ type: "success", message: "Pixel fetch Successful" });
    // },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    callTiktokTemplatesForDropdown,
    { loading: loading_tiktok_templates, refetch: refetch_tiktok_templates },
  ] = useCustomLazyQuery(LAUNCH_GET_TIKTOK_TEMPLATE_FOR_DROPDOWN, {
    onCompleted(e) {
      const element = e?.tiktokLaunchTemplates;

      if (element) {
        setTemplates(element);
        if (instantLaunch) {
          setAdAccountValues({
            ...adAccountValues,
            launchName: `Launch-${categoryName}`,
          });
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
    variables: { productId },
  });
  const [sasLaunch, { loading: sasLaunch_loading }] = useCustomMutation(SAS_LAUNCH_CALL, {
    onCompleted: (e) => {
      const element = e?.launch;
      if (element) {
        saveDraft(element);
      }
      history.push(`/${serviceRoutes}/launch/list`);
      openNotification({ type: "success", message: "Launch successfully queued" });
    },
    onError(e) {
      setVisibleModal(false);
      openNotification({ type: "error", message: e.message });
    },
    variables: { bucket_id: bucketId, productId: productId },
  });

  const [callTiktokLaunch, { loading: tiktokLaunch_loading }] = useCustomMutation(
    TIKTOK_LAUNCH_CALL,
    {
      onCompleted: (e) => {
        const element = e?.tiktokLaunch;
        if (element) {
          saveDraft(element);
        }
        history.push(`/${serviceRoutes}/launch/list`);
        openNotification({ type: "success", message: "Launch successfully queued" });
      },
      onError(e) {
        setVisibleModal(false);
        openNotification({ type: "error", message: e.message });
      },
      variables: { bucket_id: bucketId },
    }
  );
  const [callLaunchDraft, { loading: launchDraft_loading }] = useCustomLazyQuery(GQL_LAUNCH_DRAFT, {
    onCompleted: (e) => {
      if (productId === PRODUCT_ID_VS) {
        const element = e?.launchDraft;
        if (element?.draft) {
          if (element?.draft !== null) {
            if (
              element?.draft?.campaignObjectDraft &&
              element?.draft?.campaignObjectDraft?.length > 0
            ) {
              setDraftData(element);
              if (instantLaunch) {
                callContent({
                  variables: {
                    id: element?.draft?.campaignObjectDraft?.[0]?.id || instantLaunchData?.id,
                  },
                });
              }
            } else {
              setChooseDraftOrNew(CONTINUES_NEW);
              callContent({ variables: { id: instantLaunchData?.id } });
            }
          } else if (element?.draft === null) {
            setChooseDraftOrNew(CONTINUES_NEW);
            callContent({ variables: { id: instantLaunchData?.id } });
          } else {
            setVisibleChooseAdsetModal(false);
          }
          // if (element?.success) {
          //   console.log(element.message);
          // } else {
          //   openNotification({ type: "error", message: element.message });
          // }
        } else {
          setChooseDraftOrNew(CONTINUES_NEW);
          callContent({ variables: { id: instantLaunchData?.id } });
        }
      } else {
        const element = e?.launchDraft;
        if (element?.draft) {
          if (element?.draft !== null) {
            setDraftData(element);
            if (instantLaunch) {
              callContent({
                variables: { id: element?.draft?.campaignObjectDraft?.[0]?.id },
              });
            }
          } else if (element?.draft === null) {
            setChooseDraftOrNew(CONTINUES_NEW);
          } else {
            setVisibleChooseAdsetModal(false);
          }
          // if (element?.success) {
          //   console.log(element.message);
          // } else {
          //   openNotification({ type: "error", message: element.message });
          // }
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
    variables: {
      ...(bucketId !== "null" && { bucket_id: bucketId }),
      launch_id: launchId,
    },
  });

  const [callLaunchLeadGenDraft, { loading: launchLeadGenDraft_loading }] = useCustomLazyQuery(
    GQL_LAUNCH_CATEGORY_TIKTOK_DRAFT,
    {
      onCompleted: (e) => {
        const element = e?.launchLeadGenDraft;
        if (element) {
          setDraftData(element);
          if (element?.draft === null) {
            setChooseDraftOrNew(CONTINUES_NEW);
          }
          // if (element?.success) {
          //   console.log(element.message);
          // } else {
          //   openNotification({ type: "error", message: element.message });
          // }
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
      variables: {
        launch_id: launchId,
      },
    }
  );

  const [callSaveLaunchDraft, { loading: saveLaunchDraft_loading }] = useCustomMutation(
    GQL_SAVE_LAUNCH_DRAFT,
    {
      onCompleted: (e) => {
        const element = e?.saveLaunchDraft;
        if (element) {
          if (element?.success) {
            openNotification({ type: "success", message: "Draft is Saved" });
          } else {
            openNotification({ type: "error", message: element.message });
          }
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
      variables: { bucket_id: bucketId },
    }
  );

  const [callSaveLeadGenLaunchDraft, { loading: saveLeadGenLaunchDraft_loading }] =
    useCustomMutation(GQL_SAVE_CATEGORY_TIKTOK_LAUNCH_DRAFT, {
      onCompleted: (e) => {
        const element = e?.saveLeadGenLaunchDraft;
        if (element) {
          if (element?.success) {
            openNotification({ type: "success", message: "Draft is Saved" });
          } else {
            openNotification({ type: "error", message: element.message });
          }
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
      // variables: { bucket_id: bucketId },
    });

  const [
    launchDataAdAccountCall,
    {
      data: launchDataAdAccount_data,
      loading: launchDataAdAccount_loading,
      refetch: launchDataAdAccount_refetch,
    },
  ] = useCustomLazyQuery(LAUNCH_DATA_AD_ACCOUNT);

  const [vsBrandsFetch, { data: vsBrands }] = useCustomLazyQuery(VS_BRAND_BY_ADVERTISER, {
    onCompleted: () => {},
  });
  const [sasTrackingUrlFetch, { data: sasTrackingUrl, loading: sasTrackingLoading }] =
    useCustomLazyQuery(GET_SAS_TRACKING_URL);
  const [
    call_vs_platformUrlGet,
    { loading: loading_vs_platformUrlGet, refetch: refetch_vs_platformUrlGet },
  ] = useCustomLazyQuery(GET_VS_PLATFORM_FOR_URL_STRUCTURE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_platformUrlGet;
      if (element) {
        // console.log("content", { element });
        setVsUrlPlatform(element);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [callContent, { loading: loading_Content, refetch: refetch_Content }] = useCustomLazyQuery(
    GET_VS_CONTENT_GET_BY_ID,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.vs_contentGetById;
        if (element) {
          setBucketContentData(element);
          vsBrandsFetch({
            variables: {
              advertiser_id: element[0]?.advertiser?.id,
            },
          });
          call_vs_platformUrlGet({
            variables: {
              advertiser_id: element[0]?.advertiser?.id,
              // advertiser_id: "2",
              platform_id: PLATFORM_ID_FACEBOOK,
              platform_nickname: bucketData?.bucket_platform?.nickname || PLATFORM_TYPE_FACEBOOK,
            },
          });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [
    call_vsBrandGetByDomain,
    { loading: loading_vs_brandGetByDomain, refetch: refetch_vs_brandGetByDomain },
  ] = useCustomLazyQuery(GQL_LAUNCH_VS_BRAND_GET_BY_DOMAIN, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_brandGetByDomain;
      if (element) {
        setVsBrandByDomains(element);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [
    fetchTemplates,
    {
      data: creative_templates,
      loading: loading_creatives_templates,
      refetch: refetch_creatives_templates,
    },
  ] = useCustomLazyQuery(CT_GET_TEMPLATES, {
    notifyOnNetworkStatusChange: true,
    variables: { productId },
    onCompleted: (e) => {
      const element = e?.ct_getCommonTemplate?.data;
      setOptionProps((prevProps) => {
        return {
          ...prevProps,
          descriptions: element?.filter((data) => data?.creativeType === CREATIVE_TYPE_DESCRIPTION),
          titles: element?.filter((data) => data?.creativeType === CREATIVE_TYPE_TITLE),
          body: element?.filter((data) => data?.creativeType === CREATIVE_TYPE_BODY),
        };
      });
    },
    onError(e) {
      openNotification({ type: "error", message: e?.ct_getCommonTemplate?.message });
    },
  });
  // const [
  //   fetchTitles,
  //   {
  //     data: creatives_titles,
  //     loading: loading_creatives_titles,
  //     refetch: refetch_creatives_titles,
  //   },
  // ] = useCustomLazyQuery(CL_TITLES_TEMPLATES, {
  //   notifyOnNetworkStatusChange: true,
  //   // variables: { categoryId: id, productId: PRODUCT_ID_VS },
  //   variables: { productId },
  //   onCompleted: (e) => {
  //     const element = e?.clTitleTemplates?.data;
  //     setOptionProps((prevProps) => {
  //       return {
  //         ...prevProps,
  //         titles: element,
  //       };
  //     });
  //   },
  //   onError(e) {},
  // });
  // const [
  //   fetchBody,
  //   { data: creatives_body, loading: loading_creatives_body, refetch: refetch_creatives_body },
  // ] = useCustomLazyQuery(CL_BODIES_TEMPLATES, {
  //   notifyOnNetworkStatusChange: true,

  //   // variables: { categoryId: id, productId: PRODUCT_ID_VS },
  //   variables: { productId },
  //   onCompleted: (e) => {
  //     const element = e?.clBodyTemplates?.data;
  //     setOptionProps((prevProps) => {
  //       return {
  //         ...prevProps,
  //         body: element,
  //       };
  //     });
  //   },
  //   onError(e) {},
  // });
  // const [
  //   fetchDescription,
  //   {
  //     data: creatives_description,
  //     loading: loading_creatives_description,
  //     refetch: refetch_creatives_description,
  //   },
  // ] = useCustomLazyQuery(CL_GET_DESCRIPTIONTEMPLATES, {
  //   notifyOnNetworkStatusChange: true,
  //   // variables: { categoryId: id, productId: PRODUCT_ID_VS },
  //   variables: { productId },
  //   onCompleted: (e) => {
  //     const element = e?.clDescriptionTemplates?.data;
  //     setOptionProps((prevProps) => {
  //       return {
  //         ...prevProps,
  //         descriptions: element,
  //       };
  //     });
  //   },
  //   onError(e) {},
  // });

  const [callKeywords, { loading: loading_keyword, refetch: refetch_keyword }] = useCustomLazyQuery(
    GET_SAS_KEYWORDS_BY_IDS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.sasKeywordByIds;
        if (element) {
          setBucketContentData(element);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [callCategories, { loading: loading_category, refetch: refetch_category }] =
    useCustomLazyQuery(GET_CATEGORY_BY_IDS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.sasCategoriesByIds;
        if (element) {
          setBucketContentData(element);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  function replacePlaceholder(input, placeholder, replacement) {
    if (input && input.includes(placeholder)) {
      return input.replace(placeholder, replacement);
    }
    return input;
  }
  const [fetchCreatives_Images, { loading: loading_images, refetch: refetch_images }] =
    useCustomLazyQuery(LAUNCH_GET_IMAGES_FOR_KEYWORDS, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.clGetCreatives?.data;
        setVisibleGroupModal(false);
        setVisibleTargetModal(false);
        let creatives = [];
        const updatedData = element?.map((elem) => {
          let title = "";
          let body = "";
          let description = "";
          /***
           * If there are no images available for the specified category/keywords, we add it to the list of creatives.
           */

          if (
            inputs?.includeVideos
              ? elem?.selectedImages?.length + elem?.selectedVideos?.length === 0
              : elem?.selectedImages?.length?.length === 0
          ) {
            if (isSasCampaign) creatives.push(elem?.categoryName);
            else creatives.push(elem?.keywordName);
          }
          /***
           * This section is use to replace keywords/category with respective category/keyword name
           */

          if (!isSasCampaign) {
            title = replacePlaceholder(inputs?.title, "{Keywords}", elem?.keywordName);
            body = replacePlaceholder(inputs?.body, "{Keywords}", elem?.keywordName);
            description = replacePlaceholder(inputs?.description, "{Keywords}", elem?.keywordName);
          } else {
            title = replacePlaceholder(inputs?.title, "{category}", elem?.categoryName);
            body = replacePlaceholder(inputs?.body, "{category}", elem?.categoryName);
          }
          if (inputs?.description?.includes("{category}")) {
            description = inputs?.description?.replace("{category}", elem?.categoryName);
          } else {
            description = inputs?.description;
          }

          return {
            ...elem,
            selectedImages: elem?.selectedImages?.map((item) => ({
              image: item,
              id: item?.id,
              title: {
                title: title,
                // description : Know More
              },
              body: {
                body: body,
              },
              description: {
                description: description,
              },
            })),
            ...(inputs?.includeVideos && {
              selectedVideos: elem?.selectedVideos?.map((item) => ({
                video: item,
                id: item?.id,
                title: {
                  title: title,
                  // description : Know More
                },
                body: {
                  body: body,
                },
                description: {
                  description: description,
                },
              })),
            }),
          };
        });
        /***
         * This section handles the error scenario when there are no creatives available for any keyword/category.
         */
        if (creatives?.length > 0) {
          openNotification({
            type: "error",
            message: `Please add creatives for ${creatives.join(", ")}`,
          });
        } else {
          setSelectedImage((prevData) => {
            const newData = [...prevData, ...updatedData];

            return newData;
          });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
      // variables: { productId },
    });

  const [fetchCreatives_Groups, { loading: loading_adgroups, refetch: refetch_adgroups }] =
    useCustomLazyQuery(LAUNCH_GET_ADGROUPS_FOR_KEYWORDS, {
      //WILL REMOVE

      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.clGetCreatives?.data;
        let groups = [];
        element?.map((elem) => {
          /***
           * If there are no adgroups available for the specified category/keywords, we add it to the list of groups.
           */
          if (elem?.selectedGroups?.length === 0) {
            if (isSasCampaign) groups.push(elem?.categoryName);
            else groups.push(elem?.keywordName);
          }
        });
        /***
         * This section handles the error scenario when there are no creatives available for any keyword/category.
         */
        if (groups?.length > 0) {
          openNotification({
            type: "error",
            message: `Please add creatives for ${groups.join(", ")}`,
          });
        } else {
          setVisibleGroupModal(false);
          setVisibleTargetModal(false);
          setAdGroupDataSource(element);
          initialSetCampaign({ ...initialSetCampaignProps });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
      // variables: { productId },
    });

  const [callTemplate, { loading: loading_Template, refetch: refetch_Template }] =
    useCustomLazyQuery(LAUNCH_GET_TEMPLATE, {
      //WILL REMOVE
      skipError: true,
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.launchTemplates;
        if (element) {
          setTargettingTemplateData(element[0]);
          if (chooseDraftOrNew === "CONTINUES_DRAFT") setVisibleTargetModal(false);
          else if (productId === PRODUCT_ID_SAS && !isTiktokPlatformLaunch) {
            setVisibleGroupModal(true);
          } else {
            setVisibleTargetModal(false);
          }
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
      variables: { productId },
    });
  const [callBucket, { loading: loading_bucket, refetch: refetch_bucket }] = useCustomLazyQuery(
    GQL_BUCKET,
    {
      //WILL REMOVE
      skipError: true,

      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.bucket;

        if (element) {
          if (
            element.bucket_platform?.nickname === "tiktok" ||
            serviceType === SERVICE_TYPE_VS ||
            serviceType === SERVICE_TYPE_LEAD_GEN
          ) {
            setInputs({
              ...inputs,
              creative_auto_select: false,
            });
          }

          setBucketData(element);

          setAdAccountValues({
            ...adAccountValues,
            launchName: "Launch-" + element?.bucket_name,
          });
          callLaunchDraft({
            variables: { platform_type: element?.bucket_platform?.nickname.toUpperCase() },
          });
          /***
           * This section is to fetch templates based on platform
           */
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [
    fetchTiktokTemplate,
    { data: tiktokData, refetch: RefetchTiktokTemplate, loading: LoadingTiktokTemplate },
  ] = useCustomLazyQuery(FETCH_TIKTOK_LAUNCH_TEMPLATE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktokLaunchTemplates;
      if (element) {
        setTargettingTemplateData(element[0]);
        if (chooseDraftOrNew === "CONTINUES_DRAFT") setVisibleTargetModal(false);
        else if (productId === PRODUCT_ID_SAS && !isTiktokPlatformLaunch) {
          setVisibleGroupModal(true);
        } else {
          setVisibleTargetModal(false);
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
    variables: {
      productId,
    },
  });
  return {
    fetchTemplates,
    refetch_creatives_templates,
    adAccountCache_loading,
    addAccountCache,
    data_adAccount,
    fetchtikTokAdaccount,
    loading_adAccount,
    refetch_adAccount,
    callTiktokTemplatesForDropdown,
    loading_tiktok_templates,
    refetch_tiktok_templates,
    callTemplatesForDropdown,
    loading_templates,
    refetch_templates,
    fetchadAccountCache,
    adAccountCache,
    fetchadAccountCache_loading,
    vsBrands,
    vsBrandsFetch,
    sasTrackingUrlFetch,
    sasTrackingUrl,
    sasTrackingLoading,
    callContent,
    refetch_Content,
    loading_Content,
    call_vsBrandGetByDomain,
    loading_vs_brandGetByDomain,
    refetch_vs_brandGetByDomain,
    loading_creatives_templates,
    callKeywords,
    loading_keyword,
    refetch_keyword,
    callCategories,
    loading_category,
    refetch_category,
    fetchCreatives_Images,
    loading_images,
    refetch_images,
    fetchCreatives_Groups,
    loading_adgroups,
    refetch_adgroups,
    fetchTiktokTemplate,
    tiktokData,
    RefetchTiktokTemplate,
    LoadingTiktokTemplate,
    sasLaunch,
    sasLaunch_loading,
    callTiktokLaunch,
    tiktokLaunch_loading,
    callLaunchDraft,
    launchDraft_loading,
    callLaunchLeadGenDraft,
    launchLeadGenDraft_loading,
    callSaveLaunchDraft,
    saveLaunchDraft_loading,
    callSaveLeadGenLaunchDraft,
    saveLeadGenLaunchDraft_loading,
    launchDataAdAccountCall,
    launchDataAdAccount_data,
    launchDataAdAccount_loading,
    launchDataAdAccount_refetch,
    loading_vs_platformUrlGet,
    refetch_vs_platformUrlGet,
    callTemplate,
    loading_Template,
    refetch_Template,
    callBucket,
    loading_bucket,
    refetch_bucket,
  };
}

export default useLaunchApiHooks;
