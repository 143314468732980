import { gql } from "@apollo/client";

export const INDUSTRY_INSIGHTS_GET_KEYWORDS_DOMAINS = gql`
  query Ii_KeywordsDomains(
    $column: String
    $order: String
    $page: Int
    $search: String
    $size: Int
  ) {
    ii_KeywordsDomains(column: $column, order: $order, page: $page, search: $search, size: $size) {
      data {
        data {
          category
          id
          name
          url
          notificationDetails {
            agencyId
            authMail
            authUser
            createdAt
            defaultSetting {
              date
              day
              default_frequency
              time
            }
            id
            isDefault
            notifyAbout
            preference
            searchText {
              agencyId
              category {
                id
                name
              }
              countries
              createdAt
              languages
              name
              searchText
              searchType
              stale
              updatedAt
              userId
            }
            toBeNotify
            updatedAt
          }
          pages
          staleStatus
          toBeNotify
        }
        totalPages
        totalRecords
      }
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_ADD_DOMAINS = gql`
  mutation Ii_addKeywordsDomains(
    $advertiser: String
    $business: String
    $category: ii_Category
    $countries: [String]
    $isDomain: Boolean
    $languages: [String]
    $name: String
    $searchText: String
    $toBeNotify: Boolean
  ) {
    ii_addKeywordsDomains(
      advertiser: $advertiser
      business: $business
      category: $category
      countries: $countries
      isDomain: $isDomain
      languages: $languages
      name: $name
      searchText: $searchText
      toBeNotify: $toBeNotify
    ) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_DELETE_DOMAINS = gql`
  mutation Ii_deleteKeywordsDomains($keyDomIds: [String]) {
    ii_deleteKeywordsDomains(keyDomIds: $keyDomIds) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_ADD_PAGE = gql`
  mutation Ii_addPages($name: String, $pageId: String) {
    ii_addPages(name: $name, pageId: $pageId) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_GET_PAGES = gql`
  query Ii_Pages($column: String, $order: String, $page: Int, $search: String, $size: Int) {
    ii_Pages(column: $column, order: $order, page: $page, search: $search, size: $size) {
      data {
        totalPages
        totalRecords
        data {
          domainsKeywords
          id
          name
          notificationDetails {
            agencyId
            authMail
            authUser
            createdAt
            defaultSetting {
              date
              day
              default_frequency
              time
            }
            id
            isDefault
            notifyAbout
            preference
            searchText {
              agencyId
              category {
                name
                id
              }
              countries
              createdAt
              languages
              name
              searchText
              searchType
              stale
              updatedAt
              userId
            }
            toBeNotify
            updatedAt
          }
          pageId
          staleStatus
          toBeNotify
        }
      }
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_DELETE_PAGE = gql`
  mutation Ii_deletePages($pageIds: [String]) {
    ii_deletePages(pageIds: $pageIds) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_GET_NOTIFICATIONS = gql`
  query Ii_Notifications($fromDate: String, $toDate: String) {
    ii_Notifications(fromDate: $fromDate, toDate: $toDate) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_GET_CATEGORIES = gql`
  query Ii_Categories($fromDate: String, $toDate: String) {
    ii_Categories(fromDate: $fromDate, toDate: $toDate) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_DELETE_CATEGORY = gql`
  mutation Ii_deletePages($pageId: String) {
    ii_deletePages(pageId: $pageId) {
      data
      message
      status
    }
  }
`;

export const GET_FACEBOOK_INSIGHTS_AUTH_URL = gql`
  query ii_getAuthUrl($redirectUrl: String) {
    ii_getAuthUrl(redirectUrl: $redirectUrl)
  }
`;
export const INDUSTRY_INSIGHTS_CONNECT_ACCOUNT_BY_CODE = gql`
  query ii_signInAndSync($redirectUrl: String, $code: String) {
    ii_signInAndSync(redirectUrl: $redirectUrl, code: $code)
  }
`;

export const INDUSTRY_INSIGHTS_ADD_NOTIFICATION = gql`
  mutation Ii_addNotification(
    $toBeNotify: Boolean
    $time: String
    $searchType: II_NOTIFY_ABOUT
    $preference: [II_PREFERENCE]
    $notifyAbout: [II_NOTIFY_ABOUT]
    $keyDomId: String
    $isDefault: Boolean
    $day: DAY
    $defaultFrequency: II_FREQUENCY
    $date: String
  ) {
    ii_addNotification(
      toBeNotify: $toBeNotify
      time: $time
      searchType: $searchType
      preference: $preference
      notifyAbout: $notifyAbout
      keyDomId: $keyDomId
      isDefault: $isDefault
      day: $day
      defaultFrequency: $defaultFrequency
      date: $date
    ) {
      status
      message
      data
    }
  }
`;

export const INDUSTRY_INSIGHTS_DOMAIN_UPDATE = gql`
  mutation Ii_updateKeywordsDomains($keyDomIds: [String], $stale: Boolean) {
    ii_updateKeywordsDomains(keyDomIds: $keyDomIds, stale: $stale) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_PAGE_UPDATE = gql`
  mutation Ii_updatePages($stale: Boolean, $pageDbIds: [String]) {
    ii_updatePages(stale: $stale, pageDbIds: $pageDbIds) {
      status
      message
      data
    }
  }
`;

export const INDUSTRY_INSIGHTS_PAGE_NOTIFICATION_UPDATE = gql`
  mutation Ii_updatePageNotification(
    $date: String
    $day: String
    $defaultFrequency: II_FREQUENCY
    $notifyAbout: [II_NOTIFY_ABOUT]
    $pageIds: [String]
    $preference: [II_PREFERENCE]
    $time: String
    $toBeNotify: Boolean
  ) {
    ii_updatePageNotification(
      date: $date
      day: $day
      defaultFrequency: $defaultFrequency
      notifyAbout: $notifyAbout
      pageIds: $pageIds
      preference: $preference
      time: $time
      toBeNotify: $toBeNotify
    ) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_DEFAULT_NOTIFICATION = gql`
  query Ii_DefaultNotifications {
    ii_DefaultNotifications {
      data {
        isDefault
        notifyAbout
        preference
        toBeNotify
        updatedAt
        defaultSetting {
          date
          day
          default_frequency
          time
        }
      }
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_UPDATE_DOMAINS_NOTIFICATION = gql`
  mutation Ii_updateKeyDomNotification(
    $date: String
    $day: String
    $defaultFrequency: II_FREQUENCY
    $keyDomIds: [String]
    $notifyAbout: [II_NOTIFY_ABOUT]
    $preference: [II_PREFERENCE]
    $time: String
    $toBeNotify: Boolean
  ) {
    ii_updateKeyDomNotification(
      date: $date
      day: $day
      defaultFrequency: $defaultFrequency
      keyDomIds: $keyDomIds
      notifyAbout: $notifyAbout
      preference: $preference
      time: $time
      toBeNotify: $toBeNotify
    ) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_UPDATE_DEFAULT_NOTIFICATION = gql`
  mutation Ii_updateDefaultNotification(
    $date: String
    $day: String
    $defaultFrequency: II_FREQUENCY
    $notifyAbout: [II_NOTIFY_ABOUT]
    $preference: [II_PREFERENCE]
    $time: String
    $toBeNotify: Boolean
  ) {
    ii_updateDefaultNotification(
      date: $date
      day: $day
      defaultFrequency: $defaultFrequency
      notifyAbout: $notifyAbout
      preference: $preference
      time: $time
      toBeNotify: $toBeNotify
    ) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_DELETE_NOTIFICATIONS = gql`
  mutation Ii_deleteNotification($notificationId: String, $searchType: II_NOTIFY_ABOUT) {
    ii_deleteNotification(notificationId: $notificationId, searchType: $searchType) {
      data
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_ADS = gql`
  query cs_Ads(
    $adType: cs_AdTypeEnum
    $entityId: ID
    $entityType: cs_EntityEnum
    $fromDate: String!
    $page: Int
    $size: Int
    $toDate: String!
    $filters: AdFilters
    $search: String
  ) {
    cs_Ads(
      adType: $adType
      fromDate: $fromDate
      toDate: $toDate
      entityId: $entityId
      entityType: $entityType
      page: $page
      size: $size
      filters: $filters
      search: $search
    ) {
      code
      status
      message
      data {
        adId
        adCreationTime
        adDeliveryStartTime
        adDeliveryStopTime
        adCreativeType
        adDomain {
          domain
          category
          createdAt
          updatedAt
        }
        adBodies {
          value
        }
        adPage {
          fbPageId
          name
          pageProfilePictureUrl
          category {
            categoryId
            name
          }
          createdAt
          updatedAt
        }
        adSnapshotUrl
        languages {
          language
        }
        adCreative {
          title {
            value
            created_at
            updated_at
          }
          ctaText {
            value
            created_at
            updated_at
          }
          ctaType {
            value
            created_at
            updated_at
          }
          linkDescription {
            value
            created_at
            updated_at
          }
          images {
            url
          }
          videos {
            url
            thumbnail {
              url
            }
          }
          displayFormat
          linkUrl
          createdAt
          updatedAt
        }
        publisherPlatforms
        createdAt
        updatedAt
      }
      pageInfo {
        totalPages
        totalRecords
      }
    }
  }
`;

export const INDUSTRY_INSIGHTS_ADS_COUNT = gql`
  query cs_AdCount($entityIds: [ID]!, $entityType: cs_EntityEnum!) {
    cs_AdCount(entityIds: $entityIds, entityType: $entityType) {
      data {
        adCount
        entityId
      }
    }
  }
`;

export const INDUSTRY_INSIGHTS_SAVE_TO_CREATIVE_LIB = gql`
  mutation ctAddCreativeGroupsFromCS(
    $categoryId: ID!
    $categoryName: String
    $inputs: [CreativeGroupInputCS]!
    $productId: ID!
  ) {
    ctAddCreativeGroupsFromCS(
      categoryId: $categoryId
      categoryName: $categoryName
      inputs: $inputs
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
        }
        alreadyPresent {
          id
        }
        unableToAdd {
          error
        }
      }
      message
      status
    }
  }
`;

export const INDUSTRY_INSIGHTS_DOMAIN_FETCH_ALL_DATA = gql`
  mutation Ii_fetchAllData($keydomId: String) {
    ii_fetchAllData(keydomId: $keydomId)
  }
`;
