import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../shared/Account";

const useUser = () => {
  // const { getSession } = useContext(AccountContext);
  const { getAttributes } = useContext(AccountContext);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  async function loadUser() {
    try {
      const userAttributes = await getAttributes();
      const attributes = userAttributes.reduce((accumulator, current) => {
        return { ...accumulator, [current.Name]: current.Value };
      }, {});
      setUser({ ...attributes });
      setLoggedIn(true);
      setLoading(false);
    } catch (err) {
      setLoggedIn(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    loadUser();
  }, []);

  return { loading, loggedIn, setLoading, setLoggedIn, user, reloadUser: loadUser };
};

export default useUser;
