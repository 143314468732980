import { useContext } from "react";
import ThemeContext from "./context";
import { Switch } from "antd";
import cx from "classnames";

export function SideBarThemeToggle() {
  const { isThemeLight, toggleTheme } = useContext(ThemeContext);
  return (
    <div>
      Dark mode <Switch size="small" checked={!isThemeLight} onChange={toggleTheme} />
    </div>
  );
}

function ThemeToggle() {
  const { isThemeLight, toggleTheme } = useContext(ThemeContext);

  return (
    <div className="theme-toggle">
      <div className="toggle toggle--daynight">
        <button onClick={toggleTheme} className={cx("toggle--btn", { active: isThemeLight })}>
          <span className="toggle--feature"></span>
        </button>
      </div>
    </div>
  );
}
export default ThemeToggle;
