/**
 * UMS Permissions
 */

export const USERS_MANAGES_AGENCIES = "users_manage_agencies";
export const USERS_MANAGES_ROLES = "users_manage_roles";
export const USERS_MANAGES_USERS = "users_manage_users";
export const USERS_MANAGES_WORKSPACES = "users_manage_workspaces";
export const USERS_MANAGES_PRODUCTS = "users_manage_products";
export const UMS_SUPER_ADMIN = "ums_super_admin";
export const UMS_AGENCY_ADMIN = "ums_agency_admin";
// Team
export const UMS_TEAM_ADMIN = "ums_team_admin";
export const UMS_USER_VIEW = "users_view_users";

/**
 * F360 Permissions - Old
 */

export const F360_MANAGES_PROVIDES = "f360_manage_providers";
export const F360_MANAGES_PROFILES = "f360_manage_profiles";
export const F360_MANAGES_BMS = "f360_manage_bms";
export const F360_MANAGES_ACCOUNTS = "f360_manage_ad_accounts";
export const F360_MANAGES_PAGE = "f360_manage_pages";
export const F360_MANAGES_PIXELS = "f360_manage_pixels";
export const F360_MANAGES_CAMPAIGNS = "f360_manage_campaigns";
export const F360_MANAGES_CAMPAIGNS_AD_ACCOUNT = "f360_manage_campaigns_ad_account";
export const F360_MANAGES_CAMPAIGNS_AD_SET = "f360_manage_campaigns_ad_set";
export const F360_MANAGES_CAMPAIGNS_ADS = "f360_manage_campaigns_ads";
export const F360_REPORTS_ADMIN = "f360_reports_admin";
export const F360_MANAGE_CONNECT_ACCOUNT = "f360_view_test";

/**
 * F360 Permissions - New
 */

export const F360_VIEW_PROVIDERS = "f360_view_providers";
export const F360_VIEW_CAMPAIGNS = "f360_view_campaigns";
export const F360_MANAGE_CATEGORY = "f360_manage_category";
export const F360_VIEW_CATEGORY = "f360_view_category";
export const F360_VIEW_ASSET_PROFILES = "f360_view_asset_profiles";
export const F360_VIEW_ASSET_BMS = "f360_view_asset_bms";
export const F360_VIEW_ASSET_AD_ACCOUNTS = "f360_view_asset_ad_accounts";
export const F360_VIEW_ASSET_PAGES = "f360_view_asset_pages";
export const F360_VIEW_ASSET_PIXELS = "f360_view_asset_pixels";
export const F360_MANAGE_PROVIDERS = "f360_manage_providers";
export const F360_MANAGE_PROFILES = "f360_manage_profiles";
export const F360_MANAGE_BMS = "f360_manage_bms";
export const F360_MANAGE_AD_ACCOUNTS = "f360_manage_ad_accounts";
export const F360_MANAGE_PAGES = "f360_manage_pages";
export const F360_MANAGE_PIXELS = "f360_manage_pixels";
export const F360_VIEW_AGENCY_REPORT = "f360_view_agency_report";
export const F360_VIEW_USERS_REPORT = "f360_view_users_report";
export const F360_VIEW_TEAM_REPORT = "f360_view_team_report";
export const F360_VIEW_AGENCY_ASSETS = "f360_view_agency_assets";
//  export const F360_REPORTS_ADMIN = "f360_reports_admin"
export const F360_MANAGE_CONFIGURATION = "f360_manage_configuration";
export const F360_VIEW_WORKSPACE_REPORT = "f360_view_workspace_report";
export const F360_VIEW_WORKSPACE_USERS_REPORT = "f360_view_workspace_users_report";
export const F360_VIEW_WORKSPACE_TEAM_REPORT = "f360_view_workspace_team_report";
export const F360_VIEW_WORKSPACE_ASSETS = "f360_view_workspace_assets";
export const F360_ASSIGN_AD_ACCOUNTS = "f360_assign_ad_accounts";
export const F360_SYNC_ACTIONS = "f360_sync_actions";
export const F360_VIEW_TEAM_ASSETS = "f360_view_team_assets";
export const F360_VIEW_REPORT = "f360_view_report";
export const F360_MANAGE_REPORT = "f360_manage_report";
export const F360_TAKE_REPORT_ACTIONS = "f360_take_report_actions";

export const F360_VIEW_XREVENUE = "f360_view_xrevenue";
export const F360_VIEW_STOPLOSS = "f360_view_stoploss";
export const F360_VIEW_COMPLIANCE = "f360_view_compliance";
export const F360_MANAGE_XREVENUE = "f360_manage_xrevenue";
export const F360_MANAGE_STOPLOSS = "f360_manage_stoploss";
export const F360_MANAGE_COMPLIANCE = "f360_manage_compliance";
export const F360_SYNC_ADD_ACCOUNT_INFO = "f360_sync_ad_account info_action";
export const F360_SYNC_ADD_ACCOUNT_NAME = "f360_sync_ad_account_name_action";
export const F360_SYNC_ADD_ACCOUNT_DATA = "f360_sync_ad_account_data_action";
export const F360_SYNC_ADD_ACCOUNT_ARCHIVE = "f360_sync_ad_account_archive_action";
export const F360_SYNC_ADD_ACCOUNT_SPEND = "f360_sync_category_spend_action";

/**
 * DMS Permissions
 */

export const DOMAIN_MANAGE_ACCOUNT = "domain_manage_account";
export const DOMAIN_UPDATE_ACCOUNT = "domain_update_account";
export const DOMAIN_CREATE_ACCOUNT = "domain_create_account";
export const DOMAIN_DELETE_ACCOUNT = "domain_delete_account";
export const DOMAIN_VIEW_ACCOUNT = "domain_view_account";
export const DOMAIN_VIEW_PAYMENYT = "domain_view_payment";
export const DOMAIN_CREATE_PAYMENYT = "domain_create_payment";
export const DOMAIN_VIEW_ADDRESS = "domain_view_address";
export const DOMAIN_CREATE_ADDRESS = "domain_create_address";
export const DOMAIN_VIEW_DOMAIN = "domain_view_domain";
export const DOMAIN_CREATE_CUSTOM_DOMAIN = "domain_create_custom_domain";
export const DOMAIN_MANAGE_DOMAIN = "domain_manage_domain";
export const DOMAIN_CREATE_DOMAIN = "domain_create_domain";
export const DOMAIN_UPDATE_DOMAIN = "domain_update_domain";
export const DOMAIN_VIEW_NAMESERVER = "domain_view_nameserver";
export const DOMAIN_MANAGE_NAMESERVER = "domain_manage_nameserver";
export const DOMAIN_CREATE_NAMESERVER = "domain_create_nameserver";
export const DOMAIN_MANAGE_REGISTER = "domain_manage_registrar";
export const DOMAIN_CREATE_REGISTER = "domain_create_registrar";
export const DOMAIN_VIEW_REGISTER = "domain_view_registrar";
export const DOMAIN_CREATE_TLD = "domain_create_tld";
export const DOMAIN_MANAGE_TLD = "domain_manage_tld";
export const DOMAIN_VIEW_TLD = "domain_view_tld";

export const CLOUDFLARE_MANAGE_ACCOUNT = "cloudflare_manage_account";
export const CLOUDFLARE_VIEW_ACCOUNT = "cloudflare_view_account";
export const CLOUDFLARE_MANAGE_ACCOUNTS = "cloudflare_manage_accounts";
export const CLOUDFLARE_VIEW_ACCOUNTS = "cloudflare_view_accounts";
export const CLOUDFLARE_MANAGE_ZONES = "cloudflare_manage_zones";
export const CLOUDFLARE_VIEW_ZONES = "cloudflare_view_zones";
export const CLOUDFLARE_MANAGE_DNS_RECORDS = "cloudflare_manage_dns_records";
export const CLOUDFLARE_VIEW_DNS_RECORDS = "cloudflare_view_dns_records";
export const CLOUDFLARE_CREATE_ACCOUNT = "cloudflare_create_account";
export const CLOUDFLARE_CREATE_ZONE = "cloudflare_create_zone";
export const CLOUDFLARE_MANAGE_ZONE = "cloudflare_manage_zone";
export const CLOUDFLARE_UPDATE_ZONE = "cloudflare_update_zone";
export const CLOUDFLARE_DELETE_ZONE = "cloudflare_delete_zone";
export const CLOUDFLARE_MANAGE_DNS_RECORD = "cloudflare_manage_dns_record";
export const CLOUDFLARE_CREATE_DNS_RECORD = "cloudflare_create_dns_record";
export const CLOUDFLARE_UPDATE_DNS_RECORD = "cloudflare_update_dns_record";
export const CLOUDFLARE_DELETE_DNS_RECORD = "cloudflare_delete_dns_record";
export const CLOUDFLARE_MANAGE_CACHE = "cloudflare_manage_cache";
export const CLOUDFLARE_UPDATE_CACHE = "cloudflare_update_cache";
export const CLOUDFLARE_MANAGE_SSL = "cloudflare_manage_ssl";
export const CLOUDFLARE_UPDATE_SSL = "cloudflare_update_ssl";

/**
 * Viral Sparks Permissions
 */

export const VS_VIEW_ARTICLE = "vs_view_article";
export const VS_MANAGE_ARTICLE = "vs_manage_article";
export const VS_MEDIUM_ADMIN = "vs_medium_admin";
export const VS_AGENCY_REPORT_ADMIN = "vs_agency_report_admin";
export const VS_MANAGE_AGENCY = "vs_manage_agency";
export const VS_VIEW_AGENCY = "vs_view_agency";
export const VS_MANAGE_ADVERTISER = "vs_manage_advertiser";
export const VS_VIEW_ADVERTISER = "vs_view_advertiser";
export const VS_VIEW_ADVERTISER_PANEL = "vs_view_advertiser_panel";
export const VS_MANAGE_BRAND = "vs_manage_brand";
export const VS_VIEW_BRAND = "vs_view_brand";
export const VS_MANAGE_CONTENT = "vs_manage_content";
export const VS_VIEW_CONTENT = "vs_view_content";
export const VS_MANAGE_CGC = "vs_manage_cgc";
export const VS_VIEW_CGC = "vs_view_cgc";
export const VS_MANAGE_CG = "vs_manage_cg";
export const VS_VIEW_CG = "vs_view_cg";
export const VS_MANAGE_OS = "vs_manage_os";
export const VS_VIEW_OS = "vs_view_os";
export const VS_MANAGE_COUNTRY = "vs_manage_country";
export const VS_VIEW_COUNTRY = "vs_view_country";
export const VS_MANAGE_DEVICE = "vs_manage_device";
export const VS_VIEW_DEVICE = "vs_view_device";
export const VS_MANAGE_USER = "vs_manage_user";
export const VS_VIEW_ALL_USERS = "vs_view_all_users";
export const VS_VIEW_USER = "vs_view_user";
export const VS_VIEW_ALL_REPORTING = "vs_view_all_reporting";
export const VS_VIEW_REPORTING = "vs_view_reporting";
export const VS_VIEW_AGENCY_REPORTING = "vs_view_agency_reporting";
export const VS_MANAGE_REPORTING = "vs_manage_reporting";
export const VS_MANAGE_REVSHARE = "vs_manage_revshare";
export const VS_VIEW_REVSHARE = "vs_view_revshare";
export const VS_MANAGE_PLATFORM = "vs_manage_platform";
export const VS_VIEW_PLATFORM = "vs_view_platform";
export const VS_MANAGE_LANGUAGE = "vs_manage_language";
export const VS_VIEW_LANGUAGE = "vs_view_language";
export const VS_MANAGE_ASSIGN_PLATFORMS = "vs_manage_assign_platforms";
export const VS_MANAGE_ASSIGN_BRANDS = "vs_manage_assign_brands";
export const VS_VIEW_PLATFORMURL = "vs_view_platformUrl";
export const VS_MANAGE_PLATFORMURL = "vs_manage_platformUrl";
export const VS_VIEW_TEAM_USERS = "vs_view_team_users";
export const VS_VIEW_TEAM_REPORTING = "vs_view_team_reporting";
export const VS_VIEW_CAMPAIGNREPORTING = "vs_view_campaignReporting";
export const VS_VIEW_PERFORMANCE = "vs_view_performance";
/**
 * HRM Permissions
 */

export const HRMS_USER = "hrms_user";
export const HRMS_PRODUCT_ADMIN = "hrms_product_admin";
export const HRMS_VIEW_EVENT = "hrms_view_event";

/**
 * Search Arbitrage Permissions
 */

export const SEARCH_ARB_USER = "search_arb_user";

export const SAS_PRODUCT_ADMIN = "sas_product_admin";
export const SAS_MANAGE_AGENCY = "sas_manage_agency";
export const SAS_VIEW_AGENCY = "sas_view_agency";
export const SAS_MANAGE_USER = "sas_manage_user";
export const SAS_VIEW_USER = "sas_view_user";
export const SAS_MANAGE_CATEGORY = "sas_manage_category";
export const SAS_VIEW_CATEGORY = "sas_view_category";
export const SAS_MANAGE_DOMAIN = "sas_manage_domain";
export const SAS_VIEW_DOMAIN = "sas_view_domain";
export const SAS_MANAGE_FEED = "sas_manage_feed";
export const SAS_VIEW_FEED = "sas_view_feed";
export const SAS_MANAGE_VENDOR = "sas_manage_vendor";
export const SAS_VIEW_VENDOR = "sas_view_vendor";
export const SAS_MANAGE_KEYWORD = "sas_manage_keyword";
export const SAS_VIEW_KEYWORD = "sas_view_keyword";
export const SAS_VIEW_PLATFORM = "sas_view_platform";
export const SAS_VIEW_VERTICAL = "sas_view_vertical";
export const SAS_MANAGE_PLATFORM = "sas_manage_platform";
export const SAS_MANAGE_LOOK_CATEGORY = "sas_manage_look_category";
export const SAS_VIEW_LOOK_CATEGORY = "sas_view_look_category";
export const SAS_MANAGE_LOOK_VERTICAL = "sas_manage_look_vertical";
export const SAS_VIEW_LOOK_VERTICAL = "sas_view_look_vertical";
export const SAS_MANAGE_NATIVE_CAMPAIGN = "sas_manage_native_campaign";
export const SAS_VIEW_NATIVE_CAMPAIGN = "sas_view_native_campaign";
export const SAS_MANAGE_URL = "sas_manage_url";
export const SAS_USER = "sas_user";
export const SAS_USER_AFD = "sas_user_afd";
export const SAS_USER_D2S = "sas_user_d2s";
export const SAS_TEAM_ADMIN = "sas_team_admin";
export const SAS_AGENCY_ADMIN = "sas_agency_admin";
export const SAS_WORKSPACE_ADMIN = "sas_workspace_admin";
export const SAS_VENDOR_ACCOUNT = "sas_vendor_account";
export const SAS_VIEW_WEBSITE = "sas_view_website";
export const SAS_MANAGE_WEBSITE = "sas_manage_website";
export const SAS_USER_RSFC = "sas_user_rsfc";
/**
 * Leadgen Permissions
 */

export const LEAD_GEN_USER = "lead_gen_user";
export const F360_VIEW_LEADGEN_REPORT = "f360_view_leadgen_report";
export const F360_MANAGE_LEADGEN_REPORT = "f360_manage_leadgen_report";
export const USER_WITH_NO_ACTION = "no_action";
export const VOLUUM_VIEW_WORKSPACE_REPORTING = "voluum_view_workspace_reporting";
export const VOLUUM_VIEW_TEAM_REPORTING = "voluum_view_team_reporting";
export const VOLUUM_VIEW = "voluum_view";

/**
 * CMS Permissions
 */

export const CMS_MANAGE_CONTENT = "cms_manage_content";
export const CMS_MANAGE_BLOCK = "cms_manage_block";
export const CMS_MANAGE_CATEGORY = "cms_manage_category";
export const CMS_MANAGE_BRAND = "cms_manage_brand";
export const CMS_MANAGE_LIST = "cms_manage_list";
export const CMS_MANAGE_OFFER = "cms_manage_offer";
export const CMS_MANAGE_STORE = "cms_manage_store";
export const CMS_MANAGE_SIDES = "cms_manage_sides";
export const CMS_MANAGE_STORY = "cms_manage_story";
export const CMS_MANAGE_BUILDS = "cms_manage_builds";
export const CMS_MANAGE_PRODUCT = "cms_manage_product";
export const CMS_MANAGE_DOMAIN = "cms_manage_domain";
export const CMS_MANAGE_REVIEW = "cms_manage_review";
export const CMS_MANAGE_USER = "cms_manage_user";
export const CMS_MANAGE_AGENCY = "cms_manage_agency";

/**
 * CRS Permissions
 */

export const PERMISSION_CRS_VIEW = "crs_view";

/**
 * AWIC Permissions
 */

export const CMS_MANAGE_AWIC_NETWORK = "cms_manage_awic_network";
export const CMS_MANAGE_AWIC_COMPANY = "cms_manage_awic_company";
export const CMS_MANAGE_AWIC_PAYMENT = "cms_manage_awic_payment";
export const CMS_MANAGE_AWIC_NETWORK_ACCOUNT = "cms_manage_awic_network_account";
export const CMS_MANAGE_AWIC_POC = "cms_manage_awic_poc";
export const CMS_MANAGE_AWIC_STORE_EVERFLOW = "cms_manage_awic_store_everflow";

export const I_CAMPAIGNS_LAUNCH = "i_campaigns_launch";

/**
 * Ad Library
 */

export const ADLIB_VIEW_CARD = "adlib_view_card";
export const ADLIB_VIEW_COMPETITOR = "adlib_view_competitor";
export const ADLIB_VIEW_TASK = "adlib_view_task";
export const ADLIB_MANAGE_PAGE = "adlib_manage_page";
export const ADLIB_MANAGE_COMPETITOR = "adlib_manage_competitor";
export const ADLIB_VIEW_DOMAIN = "adlib_view_domain";
export const ADLIB_MANAGE_DOMAIN = "adlib_manage_domain";
export const ADLIB_VIEW_KEYWORD = "adlib_view_keyword";
export const ADLIB_VIEW_PAGE = "adlib_view_page";
export const ADLIB_VIEW_LANDING_PAGE = "adlib_view_landign_page";
export const ADLIB_MANAGE_LANDING_PAGE = "adlib_manage_landing_page";

/**
 * Twitter view
 */

export const TWITTER_VIEW = "twitter_view";

/**
 * Google - G360
 */

export const GOOGLE_VIEW_TEST = "g360_view_test";
export const GOOGLE_AGENCY_ADMIN = "g360_agency_reporting";

/**
 * Tracker
 */

export const TRACKER_WORKSPACE_ADMIN = "tracker_workspace_admin";
export const TRACKER_VIEW_ADVERTISER = "tracker_view_advertiser";
export const TRACKER_VIEW_AFFILIATENETWORK = "tracker_view_affiliatenetwork";
export const TRACKER_VIEW_AGENCY = "tracker_view_agency";
export const TRACKER_VIEW_CATEGORY = "tracker_view_category";
export const TRACKER_VIEW_COUNTRY = "tracker_view_country";
export const TRACKER_VIEW_DOMAIN = "tracker_view_domain";
export const TRACKER_VIEW_NETWORKACCOUNT = "tracker_view_networkaccount";
export const TRACKER_VIEW_PATH = "tracker_view_path";
export const TRACKER_VIEW_RULE = "tracker_view_rule";
export const TRACKER_VIEW_TIMEZONE = "tracker_view_timezone";
export const TRACKER_VIEW_TRAFFICSOURCE = "tracker_view_trafficsource";
export const TRACKER_AGENCY_MANAGER = "tracker_agency_manager";
export const TRACKER_AGENCY_ADMIN = "tracker_agency_admin";
export const TRACKER_VIEW_LANDER = "tracker_view_lander";
export const TRACKER_USER = "tracker_user";

/**
 * Tiktok
 */

export const TIKTOK_USER = "tiktok_user";
export const TIKTOK_WORKSPACE_ADMIN = "tiktok_workspace_admin";

export const PINTEREST_USER = "pinterest_user";

export const SCORECARD_VIEW_ADMIN = "scorecard_view_admin";
export const SCORECARD_VIEW_DEPARTMENT = "scorecard_view_department";
export const SCORECARD_VIEW_USER = "scorecard_view_user";
export const SCORECARD_VIEW_PRODUCT = "scorecard_view_product";

/**
 * DMS
 */

export const DMS_USER = "dms_user";
export const DMS_ADMIN = "dms_admin";

/**
 * Creative Tracking
 */

export const CREATIVE_TRACKING_CREATIVE_DIRECTOR = "creative_tracking_creative_director";
export const CREATIVE_TRACKING_MEDIA_BUYER = "creative_tracking_media_buyer";
export const CREATIVE_TRACKING_GRAPHIC_DESIGNER = "creative_tracking_graphic_designer";
export const CREATIVE_TRACKING_CONTENT_WRITER = "creative_tracking_content_writer";
export const CREATIVE_TRACKING_BUSINESS_DEVELOPER = "creative_tracking_business_developer";
export const CREATIVE_TRACKING_VIEW_AGENCY = "creative_tracking_view_agency";
export const CREATIVE_TRACKING_WORKSPACE_ADMIN = "creative_tracking_workspace_admin";
export const CREATIVE_TRACKING_AGENCY_ADMIN = "creative_tracking_view_agency";

/**
 * RECOMMENDATION ENGINE
 */
export const RC_AGENCY_PERMISSION = "RC_view_agency";

/**
 * FB-360
 */

export const FB360_REPORTING_VIEW = "fb360_view";
export const FB360_REPORTING_VIEW_AGENCY_MANAGER = "fb360_view_agency_manager";
export const FB360_REPORTING_VIEW_AGENCY = "fb360_view_agency_reporting";
export const FB360_REPORTING_VIEW_PRODUCT = "fb360_view_product_reporting";
export const FB360_REPORTING_VIEW_WORKSPACE = "fb360_view_workspace_reporting";
export const FB360_REPORTING_VIEW_TEAM = "fb360_view_team_reporting";
export const FB360_PROFILE_VIEW = "fb360_view_profile";
export const FB360_PROFILE_VIEW_ALL = "fb360_view_all_profile";
export const FB360_PROFILE_VIEW_AGENCY = "fb360_view_agency_profile";
export const FB360_PROFILE_VIEW_PRODUCT = "fb360_view_product_profile";
export const FB360_PROFILE_VIEW_WORKSPACE = "fb360_view_workspace_profile";
export const FB360_PROFILE_VIEW_TEAM = "fb360_view_team_profile";
export const FB360_PROFILE_MANAGE = "fb360_manage_profile";
export const FB360_BM_VIEW = "fb360_view_bm";
export const FB360_BM_VIEW_ALL = "fb360_view_all_bm";
export const FB360_BM_VIEW_AGENCY = "fb360_view_agency_bm";
export const FB360_BM_VIEW_PRODUCT = "fb360_view_product_bm";
export const FB360_BM_VIEW_WORKSPACE = "fb360_view_workspace_bm";
export const FB360_BM_VIEW_TEAM = "fb360_view_team_bm";
export const FB360_ADACCOUNT_VIEW = "fb360_view_adaccount";
export const FB360_ADACCOUNT_VIEW_ALL = "fb360_view_all_adaccount";
export const FB360_ADACCOUNT_VIEW_AGENCY = "fb360_view_agency_adaccount";
export const FB360_ADACCOUNT_VIEW_PRODUCT = "fb360_view_product_adaccount";
export const FB360_ADACCOUNT_VIEW_WORKSPACE = "fb360_view_workspace_adaccount";
export const FB360_ADACCOUNT_VIEW_TEAM = "fb360_view_team_adaccount";
export const FB360_ADSET_VIEW = "fb360_view_adset";
export const FB360_ADSET_VIEW_ALL = "fb360_view_all_adset";
export const FB360_ADSET_VIEW_AGENCY = "fb360_view_agency_adset";
export const FB360_ADSET_VIEW_PRODUCT = "fb360_view_product_adset";
export const FB360_ADSET_VIEW_WORKSPACE = "fb360_view_workspace_adset";
export const FB360_ADSET_VIEW_TEAM = "fb360_view_team_adset";
export const FB360_AD_VIEW = "fb360_view_ad";
export const FB360_AD_VIEW_ALL = "fb360_view_all_ad";
export const FB360_AD_VIEW_AGENCY = "fb360_view_agency_ad";
export const FB360_AD_VIEW_PRODUCT = "fb360_view_product_ad";
export const FB360_AD_VIEW_WORKSPACE = "fb360_view_workspace_ad";
export const FB360_AD_VIEW_TEAM = "fb360_view_team_ad";
export const FB360_CAMPAIGN_VIEW = "fb360_view_campaign";
export const FB360_CAMPAIGN_VIEW_ALL = "fb360_view_all_campaign";
export const FB360_CAMPAIGN_VIEW_AGENCY = "fb360_view_agency_campaign";
export const FB360_CAMPAIGN_VIEW_PRODUCT = "fb360_view_product_campaign";
export const FB360_CAMPAIGN_VIEW_WORKSPACE = "fb360_view_workspace_campaign";
export const FB360_CAMPAIGN_VIEW_TEAM = "fb360_view_team_campaign";
export const FB360_ADACCOUNT_WORKSPACE_ASSIGN = "fb360_manage_assign_workspace";
export const FB360_REPORTING_MANAGE = "fb360_manage_reporting";
export const FB360_URL_VIEW = "fb360_view_url";
export const FB360_ACTION_MANAGE = "fb360_manage_action";
export const FB360_USER_ACTION = "fb360_user_action";

export const FB360_AGENCY_ADMIN = "fb360_agency_admin";
export const FB360_TEAM_ADMIN = "fb360_team_admin";
export const FB360_WORKSPACE_ADMIN = "fb360_workspace_admin";
export const FB360_PRODUCT_ADMIN = "fb360_product_admin";
export const FB360_USER = "fb360_user";
export const FB360_VIEW_TEST = "fb360_view_test";
export const FB360_AGENCY_ASSIGN = "fb360_agency_assign";
export const FB360_USER_ASSIGN = "fb360_user_assign";
export const FB360_CREATIVE_VIEW_ALL = "fb360_creative_view_all";

export const PERMISSION_VOLUUM_VIEW = "voluum_view";

//CO-Poilet Permissions
export const CO_PILOT_VIEW_RULE = "view_rules";
