import { Collapse, Switch, Tooltip } from "antd";
import cx from "classnames";

const { Panel } = Collapse;

export default function LookCollapse(props) {
  const { colapseData = [], extraProps = "", className = "" } = props;

  /**
   * ? colapseData
   * * [{ title: "", component: LaunchTargeting } , {...}, .......]
   */

  return (
    <Collapse
      expandIconPosition="right"
      className={cx("look-collapse", className)}
      defaultActiveKey={["1"]}
    >
      {colapseData?.map((data, index) => {
        const { component: Component, title = "" } = data;
        return (
          <Panel
            header={<span className="title">{title}</span>}
            key={index + 1 + ""}
            extra={extraProps}
          >
            <Component />
          </Panel>
        );
      })}
    </Collapse>
  );
}
