import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useHistory } from "react-router-dom";
import {
  SERVICE_TYPE_LEAD_GEN,
  SERVICE_TYPE_SAS,
  SERVICE_TYPE_VS,
} from "shared/enum/launchKeysAndEnum";
import { LAUNCH_STATUS_HAVE_FAILURE, LAUNCH_STATUS_HAVE_FAILURE_RETRY } from "..";
import LookButton from "components/LookButton";
import { PRODUCT_ID_VS } from "shared/SharedKeys";

export default function useColumn(props = {}) {
  const { isTiktokPlatformLaunch, serviceType, templatePlatforms, showDrawer, productId } = props;
  const history = useHistory();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      searchable: true,
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDrawer(otherProps)} />
      ),
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    ...(isTiktokPlatformLaunch
      ? [
          {
            title: "Tiktok Error Message",
            dataIndex: "tiktokErrorMessage",
            key: "tiktokErrorMessage",
            searchable: true,
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 140,
            minWidth: 140,
          },
          {
            title: "Tiktok Error Title",
            dataIndex: "tiktokErrorTitle",
            key: "tiktokErrorTitle",
            searchable: true,
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 124,
            minWidth: 124,
          },
          {
            title: "Tiktok Error Details",
            dataIndex: "tiktokErrorDetails",
            key: "tiktokErrorDetails",
            searchable: true,
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 140,
            minWidth: 140,
          },
        ]
      : [
          {
            title: isTiktokPlatformLaunch ? "Tiktok Error Message" : "fbErrorMessage",
            dataIndex: isTiktokPlatformLaunch ? "tiktokErrorMessage" : "fbErrorMessage",
            key: isTiktokPlatformLaunch ? "tiktokErrorMessage" : "fbErrorMessage",
            searchable: true,
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 124,
            minWidth: 124,
          },
          {
            title: isTiktokPlatformLaunch ? "Tiktok Error Title" : "fbErrorTitle",
            dataIndex: isTiktokPlatformLaunch ? "tiktokErrorTitle" : "fbErrorTitle",
            key: isTiktokPlatformLaunch ? "tiktokErrorTitle" : "fbErrorTitle",
            searchable: true,
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 124,
            minWidth: 124,
          },
          {
            title: isTiktokPlatformLaunch ? "Tiktok Error Details" : "fbErrorDetails",
            dataIndex: isTiktokPlatformLaunch ? "tiktokErrorDetails" : "fbErrorDetails",
            key: isTiktokPlatformLaunch ? "tiktokErrorDetails" : "fbErrorDetails",
            searchable: true,
            render: (value, otherProps) => <LookTableColumnRender title={value} />,
            width: 124,
            minWidth: 124,
          },
        ]),
    {
      title: "message",
      dataIndex: "message",
      key: "message",
      searchable: true,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      searchable: true,
      render: (value, otherProps) => {
        // let message;

        // message = otherProps?.message === "Launch Failed" ? " FAILED" : "SUCCESS";

        return <LookTableColumnRender title={value?.replaceAll("LaunchStatus.", "")} />;
      },
      width: 124,
      minWidth: 124,
    },
    {
      title: "success",
      dataIndex: "success",
      key: "success",
      searchable: true,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        let serviceRoute = "";
        if (serviceType === SERVICE_TYPE_SAS) serviceRoute = "sas";
        if (serviceType === SERVICE_TYPE_VS) serviceRoute = "vs";
        if (serviceType === SERVICE_TYPE_LEAD_GEN) serviceRoute = "leadgen";

        const { message = "" } = actionProps;

        const isLaunchFailed =
          message === "Launch Failed" ? "?status=" + LAUNCH_STATUS_HAVE_FAILURE : "";

        const isLaunchRetryFailed =
          message === "Launch Failed" ? "?status=" + LAUNCH_STATUS_HAVE_FAILURE_RETRY : "";
        const launchVersion = productId === PRODUCT_ID_VS ? "launch-v2" : "launch";
        return (
          <span className="text-right d-block pointer">
            {isLaunchRetryFailed && (
              <LookButton
                tooltipTitle="Re-Try failed campaigns"
                className="h-auto"
                iconType="reload"
                onClick={() => {
                  if (productId === PRODUCT_ID_VS) {
                    if (actionProps?.isCategoryLaunch || actionProps?.bucket_id === null) {
                      history.push(
                        `/${serviceRoute}/re-${launchVersion}/category/` +
                          templatePlatforms +
                          "/" +
                          actionProps?.id +
                          isLaunchRetryFailed
                      );
                    }
                  } else {
                    if (actionProps?.isCategoryLaunch) {
                      history.push(
                        `/${serviceRoute}/re-launch/category/` +
                          templatePlatforms +
                          "/" +
                          actionProps.id +
                          isLaunchRetryFailed
                      );
                    } else {
                      history.push(
                        `/${serviceRoute}/launch/` +
                          actionProps?.bucket_id +
                          "/" +
                          actionProps.id +
                          isLaunchRetryFailed
                      );
                    }
                  }
                }}
              />
            )}

            <LookButton
              tooltipTitle="Re-Launch"
              className="h-auto"
              iconType="rocket"
              onClick={() => {
                const launchVersion = productId === PRODUCT_ID_VS ? "launch-v2" : "launch"; // its currently use in VS launch after some time may use inn other services

                if (actionProps?.isCategoryLaunch || actionProps?.bucket_id === null) {
                  history.push(
                    `/${serviceRoute}/re-${launchVersion}/category/` +
                      templatePlatforms +
                      "/" +
                      actionProps?.id +
                      isLaunchFailed
                  );
                } else {
                  history.push(
                    `/${serviceRoute}/${launchVersion}/` +
                      actionProps?.bucket_id +
                      "/" +
                      actionProps.id +
                      isLaunchFailed
                  );
                }
              }}
            />
          </span>
        );
      },
      fixed: "right",
      width: 110,
    },
  ];
  return { columns };
}
