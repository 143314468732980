import { Switch } from "antd";

export default function VisibilityHandle(props) {
  const { isPrivate = false, onChange = () => {} } = props;
  return (
    <div>
      <p>
        Visibility:{" "}
        <Switch size="small" checked={!isPrivate} onChange={() => onChange(!isPrivate)} />
      </p>
    </div>
  );
}
