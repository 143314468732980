import LookTable from "components/LookTable";
import React, { useEffect, useState } from "react";
import DebugPre from "components/DebugPre";
import { useColumns } from "../../../../Tools/KeywordPlanner/useColumns";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_10 } from "actions/actionTypes";
import { openNotification } from "utils";
import { GET_SAS_KEYWORDS_IDEAS } from "shared/gql/gqlSchema/sasGql";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import moment from "moment";
function KeywordTable(props) {
  const { name, category, isOpen = false, isSensitive } = props;
  const [pageToken, setPageToken] = useState("");
  const [keywords, setKeywords] = useState([]);
  let defaultDate = moment(new Date()).subtract("1", "month");

  const [getKeywordsIdeas, { loading: loading_GET_KEYWORD_IDEAS }] = useCustomLazyQuery(
    GET_SAS_KEYWORDS_IDEAS,
    {
      onCompleted: (e) => {
        setKeywords(e?.g_keywordIdeasGet?.results);
        setPageToken(e?.g_keywordIdeasGet?.nextPageToken);
      },
    }
  );
  useEffect(() => {
    getKeywordsIdeas({
      variables: {
        data: {
          aggregateMetrics: {
            aggregateMetricTypes: "DEVICE",
          },
          geoTargetConstants: ["geoTargetConstants/2840"],
          historicalMetricsOptions: {
            includeAverageCpc: true,
            yearMonthRange: {
              start: {
                month: defaultDate.format("MMMM")?.toString().toUpperCase(),
                year: defaultDate.format("YYYY")?.toString(),
              },
              end: {
                month: defaultDate.format("MMMM")?.toString().toUpperCase(),
                year: defaultDate.format("YYYY")?.toString(),
              },
            },
          },
          includeAdultKeywords: isSensitive,
          keywordPlanNetwork: "GOOGLE_SEARCH_AND_PARTNERS",
          keywordSeed: { keywords: [name] },
          language: "languageConstants/1000",
          pageSize: 500,
          pageToken: pageToken ?? "",
        },
      },
    });
  }, [name]);
  // const [isSearched, setIsSearched] = useState(false);

  const rowData = keywords.map((data, index) => ({ ...data, key: index }));
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_10;

  const { initialColumns, createSaSKeywords } = useColumns(category?.id);
  const tableProps = { initialColumns, recordData: rowData };

  const debugContent = [{ isRow: true, contentObjects: [{ keywords }] }];

  const bulkActions = [
    {
      option: "plus",
      title: `Add keyword to Keywords`,
      onClick: (e) => {
        if (!category || category === "") {
          openNotification({
            type: "error",
            description: "Category not selected",
            title: "Please select category",
          });
        } else {
          const text = e.map((el) => el.text);
          createSaSKeywords({
            variables: {
              name: text,
              status: "ACTIVE",
              isSensitive: isSensitive,
              categoryId: category.id,
              keywordSourceId: 5,
            },
          });
        }
      },
    },
  ];
  return (
    <>
      <LookTable
        isTableFilter={false}
        loading={loading_GET_KEYWORD_IDEAS}
        tableProps={tableProps}
        bulkActions={bulkActions}
      />

      <DebugPre content={debugContent} />
    </>
  );
}

export default KeywordTable;
