import LookButton from "components/LookButton";
import { registerDynamicTag } from "./bolt";
import { createGlobalStyle } from "styled-components";
import { CreateTag, UpdateAdvertiserTag } from "./serviceComponents/CreateTag";

import { detectValueType, removeObjectByIndex } from "utils";
import { Popover, Tooltip } from "antd";
import cx from "cx";
import useCmsAdvertiserApis from "./serviceComponents/useCmsAdvertiserApis";
import { useEffect, useState } from "react";
import {
  CMS_POST_CONTENT_VIEW_TYPE_CREATE,
  CMS_POST_CONTENT_VIEW_TYPE_EDIT,
} from "components/PostHandle";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import { useSelector } from "react-redux";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useLookModal } from "components/LookModal";
import { generateHexDistinctColors, hexToRgba } from "utils/colors";
import { CollapseWraper } from "components/PostHandleV2/SubComponents";

export const ContentTagListGlobalStyle = createGlobalStyle`

  .ql-editor {
    ${({ tagData, colorList }) =>
      tagData
        ?.map(
          (tag, index) => `
    ${tag?.tagName} {
      text-decoration: underline ${hexToRgba(colorList[index], 1)} 4px;
    }
  `
        )
        .join("\n")}
  }
`;
const PreviewTagData = ({ label, value }) => (
  <p className="text-no-wrap mb-0">
    <b>{label}:</b> <span style={{ opacity: 0.7 }}>{value}</span>
  </p>
);
export function CmsContentTagListHandlerView(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const { userSettingData } = userSettings || {};
  const { CMS_Domains = [] } = userSettingData || {};
  const { trackerAdvertisersFetch, trackerCountryFetch, trackerNetworkAccountsFetch } =
    useApiToStoreContext();
  const {
    isLink = false,
    slug,
    domains,
    mainFormId,
    articleType,
    view,
    cmsContentTagList = [],
    setCmsContentTagList = () => {},
    applyTag = () => {},
    removeCurrentTags = () => {},
    onDelete = () => {},
    listType,
  } = props;

  let link_of_articleData = [];

  let islink_of_article = {};
  if (isLink) {
    function parseLinkOfArticle(domainData = []) {
      return domainData?.map((domainMapData) => `https://${domainMapData?.domain_url}/${slug}`);
    }

    if (detectValueType(domains) === "string") {
      link_of_articleData = parseLinkOfArticle(CMS_Domains.filter((d) => domains === d.id));
    }
    if (detectValueType(domains) === "array") {
      link_of_articleData = parseLinkOfArticle(CMS_Domains.filter((d) => domains.includes(d.id)));
    }
    if (listType === "Product") {
      // link_of_article is an array of string
      islink_of_article = { link_of_article: link_of_articleData[0] };
    } else {
      islink_of_article = { link_of_article: link_of_articleData };
    }
  }

  const {
    callCmsFetchAdvertiser,
    loading_cmsFetchAdvertiser,
    refetch_cmsFetchAdvertiser,

    callCmsDeleteAdvertiser,
    loading_cmsDeleteAdvertiser,
  } = useCmsAdvertiserApis();

  const callTagData = () => {
    callCmsFetchAdvertiser({
      onCompleted: (e) => {
        const element = e?.CMS_Get_Advertiser;

        if (element) {
          setCmsContentTagList(element?.map((data) => ({ ...data, tagId: parseInt(data?.tagId) })));
        }
      },
      variables: { article_id: mainFormId, article_type: articleType },
    });
  };

  useEffect(() => {
    if (view === CMS_POST_CONTENT_VIEW_TYPE_EDIT) {
      callTagData();
    }
  }, []);

  // const colorList = generateHexRandomColors(10);
  const colorList = generateHexDistinctColors(40);

  const { Tracker_Countries, Tracker_Advertisers, Tracker_NetworkAccounts } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const content = (data) => {
    const { network_acc_id, advertiser_id, country } = data;
    const networkAccount = Tracker_NetworkAccounts?.find(({ id }) => id === network_acc_id);
    const advertiser = Tracker_Advertisers?.find(({ id }) => id === advertiser_id);
    const countryData = Tracker_Countries?.find(({ id }) => id === country);

    return (
      <>
        {[
          { label: "Network Account", value: networkAccount?.name },
          { label: "Advertiser Id", value: advertiser?.name },
          { label: "Country", value: countryData?.name || "none" },
        ].map((data, index) => (
          <PreviewTagData key={"PreviewTagData" + index} {...data} />
        ))}
      </>
    );
  };

  const mainTagList = cmsContentTagList?.map((data, index) => {
    const { tagId } = data;
    const tagName = `cta${tagId}`;
    const name = `CTA ${index + 1}`;
    return { ...data, tagName, name };
  });
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const onSubmitDeleteModal = () => {
    removeCurrentTags(deleteModalPropsData.tagName);

    callCmsDeleteAdvertiser({
      onCompleted: (e) => {
        const deletedIndex = mainTagList?.findIndex((d) => d?.id === deleteModalPropsData?.id);
        if (deletedIndex !== -1) {
          const selectedData = mainTagList[deletedIndex];
          removeCurrentTags(selectedData.tagName);
          setCmsContentTagList(removeObjectByIndex(cmsContentTagList, deletedIndex));

          setTimeout(() => {
            onDelete(selectedData.tagName);

            onCloseDeleteModal();
          }, 100);
        }
      },
      variables: { id: deleteModalPropsData?.id },
    });
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };

  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_cmsDeleteAdvertiser,
  };

  const localDelete = (tagName, index) => {
    removeCurrentTags(tagName);
    setCmsContentTagList(removeObjectByIndex(cmsContentTagList, index));
  };

  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Update Tag",
    footer: false,
  });

  useEffect(() => {
    trackerAdvertisersFetch();
    trackerCountryFetch();
    trackerNetworkAccountsFetch();
  }, []);

  const [contetData, setContetData] = useState({});

  const onUpdate = (currentContentData) => {
    openModal();
    setContetData(currentContentData);
  };

  const onCancelUpdate = () => {
    closeModal();
    setContetData({});
  };

  return (
    <>
      <ContentTagListGlobalStyle
        view={view}
        colorList={colorList}
        tagData={mainTagList}
        mainFormId={mainFormId}
      />
      <LookTableRecordDeleteModal {...deleteModalProps} />
      {visibleModal &&
        modal(
          visibleModal && (
            <UpdateAdvertiserTag
              {...{
                ...contetData,
                visibleModal,
                mainFormId,
                articleType,
                closeModal: onCancelUpdate,
                cmsContentTagList,
                setCmsContentTagList,
                islink_of_article,
              }}
            />
          )
        )}
      <div className="content-tag-element">
        <CreateTag
          {...{
            islink_of_article,
            view,
            mainFormId,
            articleType,
            cmsContentTagList,
            setCmsContentTagList,
          }}
        />
        {mainTagList.map((data, index) => {
          const { tagName, name, tagId, id } = data;

          return (
            <Popover overlayClassName="pointer-events-none" content={content(data)} title={name}>
              <div key={index} className="element" style={{ "--elemet-color": colorList[index] }}>
                <div className="content">
                  <Tooltip title="Click to update">
                    <p className="label" onClick={() => onUpdate({ ...data, index })}>
                      {name}
                    </p>
                  </Tooltip>
                  <div>
                    <LookButton
                      tooltipTitle="Apply tag"
                      type="link"
                      iconType="plus"
                      onClick={() => applyTag(tagName)}
                    />
                    <LookButton
                      type="link"
                      tooltipTitle="Remove all occurrences tags"
                      danger
                      iconType="close"
                      onClick={() => removeCurrentTags(tagName)}
                    />
                    <LookButton
                      type="link"
                      tooltipTitle="Delete tag"
                      danger
                      iconType="delete"
                      onClick={
                        () => localDelete(tagName, index)
                        // future
                        // view === CMS_POST_CONTENT_VIEW_TYPE_CREATE
                        //   ? localDelete(tagName, index)
                        //   : onOpenDeleteModal({ id, tagName })
                      }
                    />
                  </div>
                </div>
              </div>
            </Popover>
          );
        })}
      </div>
    </>
  );
}

export function CmsContentSelectedTagListHandlerView(props) {
  const { tags = [], removeTagItem = () => {} } = props;
  if (tags?.length === 0) return null;
  return (
    <>
      <h3>Applied Tags:</h3>
      <div className="content-selected-tag-element">
        {tags.map((item) => {
          return (
            <div className={cx("tag", item?.tagName)} key={item?.id}>
              {item?.text}
              <LookButton type="link" danger iconType="close" onClick={() => removeTagItem(item)} />
            </div>
          );
        })}
      </div>
    </>
  );
}

export function parseAndRegisterTags(content, quillRef) {
  // Convert the string content to a DOM structure
  // const quill = quillRef.current.getEditor();
  // quill.setContents([]); // Clear the editor

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  // Find all elements with a "data-id" attribute. This assumes that all custom tags have a "data-id" attribute
  const elements = doc.querySelectorAll("[data-id]");

  const idSetArray = [];

  elements.forEach((element, index) => {
    idSetArray.push(element.getAttribute("data-id"));
  });

  elements.forEach((element, index) => {
    const tagName = element.tagName.toLowerCase();
    registerDynamicTag({ tagName, id: idSetArray[index], loopIndex: index, idSetArray });
  });
}

// BELOW BACKUP CODE
// export function parseAndRegisterTagsInner(content, quillRef) {
//   // Convert the string content to a DOM structure
//   // const quill = quillRef.current.getEditor();
//   // quill.setContents([]); // Clear the editor

//   const parser = new DOMParser();
//   const doc = parser.parseFromString(content, "text/html");

//   // Find all elements with a "data-id" attribute. This assumes that all custom tags have a "data-id" attribute
//   const elements = doc.querySelectorAll("[data-id]");

//   const idSetArray = [];

//   elements.forEach((element, index) => {
//     idSetArray.push(element.getAttribute("data-id"));
//   });
//   console.log("****poijn inner", { idSetArray });
//   elements.forEach(async (element, index) => {
//     const tagName = element.tagName.toLowerCase();
//     await registerDynamicTag({ tagName, id: index });
//   });
// }
