import { Tooltip } from "antd";
import cx from "classnames";
import { Fragment } from "react";
import { DotIcon } from "components/svgComponents";
import ActionIconRender from "components/ActionIconRender";
import { v4 as uuid } from "uuid";
import { openNotification } from "utils";

const STATUS_WITH_ISSUE = "WITH_ISSUE";
const STATUS_DISAPPROVED = "DISAPPROVED";

function ProfitWrapper({ profit, children }) {
  return (
    <div className="flex">
      {parseFloat(profit) > 0 && (
        <ActionIconRender className="text-primary mr-8" iconType="caret-up" />
      )}
      {parseFloat(profit) < 0.0 && (
        <ActionIconRender className="text-danger mr-8" iconType="caret-down" />
      )}
      {parseFloat(profit) === 0 && <DotIcon className="text-gray ml-2 mr-10" />}
      <span style={{ width: "calc(100% - 24px)" }}>{children}</span>
    </div>
  );
}

export default function LookTableColumnRender({
  title = "",
  tooltip,
  className = "",
  isClick,
  isCopy,
  isProfit,
  tooltipPlacement,
  status = "",
  isEllipsis = true,
}) {
  const Wrapper = isProfit !== undefined ? ProfitWrapper : Fragment;
  const wrapperProp = isProfit !== undefined ? { profit: isProfit && isProfit } : { key: uuid() };

  const copyContent = (e) => {
    e.stopPropagation();
    const cb = navigator.clipboard;
    cb.writeText(title).then(() =>
      openNotification({ type: "success", message: `${title} copied!` })
    );
  };

  const parseStatusIcon = () =>
    status
      ? (status === STATUS_WITH_ISSUE || status === STATUS_DISAPPROVED) && (
          <ActionIconRender className="text-yellow mr-8" iconType="warning-filled" />
        )
      : null;

  return (
    <div>
      <Wrapper {...wrapperProp}>
        <Tooltip title={tooltip || title} placement={tooltipPlacement} mouseEnterDelay={1}>
          <div className={cx("d-flex align-items-center", { "pr-32": isCopy })}>
            {parseStatusIcon()}
            {isClick && <span className="click-td" onClick={isClick} />}
            {isCopy && (
              <span className="copy-click-td" onClick={(e) => copyContent(e)}>
                <ActionIconRender iconType="copy" />
              </span>
            )}
            <span
              className={cx("LookTableColumnRender", className, {
                "text-center": title === null || title === "",
                "normal-text": !isEllipsis,
              })}
            >
              {title === null || title === "" ? "-" : title}
            </span>
            {/* {isCopy && (
              <span className="ml-5" style={{ cursor: "pointer" }} onClick={(e) => copyContent(e)}>
                <ActionIconRender iconType="copy" />
              </span>
            )} */}
          </div>
        </Tooltip>
      </Wrapper>
    </div>
  );
}
