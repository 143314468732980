import { Tabs, Tooltip } from "antd";
// import uuid from "uuid/v4";
import { createGlobalStyle } from "styled-components";
const { TabPane } = Tabs;

export default function LookDrawerMainSideBar(props) {
  const { tabContent = [], value, onChange, tabPosition = "left" } = props;

  /**
   * ? tabContent
   * * [{ noSpace: Boolean, title: String, hoverTitle: String, icon: Component, component: Component } , {...}, .......]
   */

  function renderTabs() {
    return tabContent.map(
      ({ id, noSpace = false, title, hoverTitle = "", icon: Icon, component }, index) => (
        <TabPane
          style={noSpace ? { padding: 0 } : {}}
          tab={
            <Tooltip title={hoverTitle} placement="right">
              <span
                className="position-absolute  w-100 h-100 d-block"
                style={{ top: 0, left: 0 }}
              />
              {Icon && <Icon />}
              {title}
            </Tooltip>
          }
          key={id || "tabContent" + index}
        >
          {component}
        </TabPane>
      )
    );
  }

  let tabProps = {};

  if (onChange) {
    tabProps = {
      activeKey: value,
      onChange,
    };
  }
  return (
    <>
      <GlobalStyle />
      <Tabs className="look-drawer-tab main-side-bar" tabPosition={tabPosition} {...tabProps}>
        {renderTabs()}
      </Tabs>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
.ant-drawer-body{
  padding: 0;
}`;
