import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { UMS_GET_WORKSPACE_PRODUCTS } from "shared/gql/gqlSchema/umsGql";

export default function useWorkspaceProducts(props = {}) {
  const [
    fetchWorkspaceProducts,
    { loading: loading_workspaceProducts, refetch: refetch_workspaceProducts },
  ] = useCustomLazyQuery(UMS_GET_WORKSPACE_PRODUCTS, { notifyOnNetworkStatusChange: true });

  function callFetchWorkspaceProducts(
    workspaceId,
    { callBackResponseOnComplete = () => {}, callBackResponseOnError = () => {} }
  ) {
    fetchWorkspaceProducts({
      onCompleted(e) {
        const elemet = e?.workspace?.products;
        callBackResponseOnComplete(elemet);
      },
      onError(e) {
        callBackResponseOnError(e);
      },
      variables: { id: workspaceId },
    });
  }

  return {
    callFetchWorkspaceProducts,
    fetchWorkspaceProducts,
    loading_workspaceProducts,
    refetch_workspaceProducts,
  };
}
