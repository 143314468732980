import ActionIconRender from "components/ActionIconRender";
import {
  TRACKER_USER,
  TRACKER_VIEW_ADVERTISER,
  TRACKER_VIEW_AFFILIATENETWORK,
  TRACKER_VIEW_CATEGORY,
  TRACKER_VIEW_DOMAIN,
  TRACKER_VIEW_LANDER,
  TRACKER_VIEW_NETWORKACCOUNT,
  TRACKER_VIEW_PATH,
  TRACKER_VIEW_RULE,
  TRACKER_VIEW_TRAFFICSOURCE,
} from "shared/UserPermission/userPermissionList";

const trackerPath = [
  {
    name: "Tracker",
    link: "/tracker",
    icon: (e) => <ActionIconRender {...e} iconType="select" />,
    subMenu: [
      {
        subMenuName: "Category",
        subMenuLink: "/category",
        subMenuIcon: () => <ActionIconRender iconType="priority" />,
        permission: TRACKER_VIEW_CATEGORY,
      },
      {
        subMenuName: "Advertiser",
        subMenuLink: "/advertiser",
        subMenuIcon: () => <ActionIconRender iconType="advertiser" />,
        permission: TRACKER_VIEW_ADVERTISER,
      },
      {
        subMenuName: " Tracking Domains",
        subMenuLink: "/domain",
        subMenuIcon: () => <ActionIconRender iconType="global" />,
        permission: TRACKER_VIEW_DOMAIN,
      },
      // {
      //   subMenuName: "Lander",
      //   subMenuLink: "/lander",
      //   subMenuIcon: () => <ActionIconRender iconType="global" />,
      //   permission: TRACKER_VIEW_LANDER,
      // },
      {
        subMenuName: "Affiliate Network",
        subMenuLink: "/affiliate-network",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: TRACKER_VIEW_AFFILIATENETWORK,
      },
      // {
      //   subMenuName: "Path",
      //   subMenuLink: "/path",
      //   subMenuIcon: () => <ActionIconRender iconType="gateway" />,
      //   permission: TRACKER_VIEW_PATH,
      // },
      // {
      //   subMenuName: "Rule",
      //   subMenuLink: "/rule",
      //   subMenuIcon: () => <ActionIconRender iconType="audit" />,
      //   permission: TRACKER_VIEW_RULE,
      // },
      {
        subMenuName: "Network Account",
        subMenuLink: "/network-account",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: TRACKER_VIEW_NETWORKACCOUNT,
      },
      {
        subMenuName: "Traffic Source",
        subMenuLink: "/traffic-source",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: TRACKER_VIEW_TRAFFICSOURCE,
      },
      {
        subMenuName: "Sub Id",
        subMenuLink: "/subid",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: TRACKER_USER,
      },
      {
        subMenuName: "Offers",
        subMenuLink: "/offers",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: TRACKER_VIEW_NETWORKACCOUNT,
      },
      {
        subMenuName: "Campaign",
        subMenuLink: "/campaign",
        subMenuIcon: () => <ActionIconRender iconType="apartment" />,
        permission: TRACKER_USER,
      },
      {
        subMenuName: "CPS Reporting",
        subMenuLink: "/cps-campaign-reporting",
        subMenuIcon: () => <ActionIconRender iconType="lineChart" />,
        permission: TRACKER_USER,
      },
      // {
      //   subMenuName: "CPS Campaign",
      //   subMenuLink: "/cps",
      //   subMenuIcon: () => <ActionIconRender iconType="apartment" />,
      //   permission: TRACKER_VIEW_NETWORKACCOUNT,
      // },
      // {
      //   subMenuName: "CTA",
      //   subMenuLink: "/cta",
      //   subMenuIcon: () => <ActionIconRender iconType="apartment" />,
      //   permission: TRACKER_VIEW_NETWORKACCOUNT,
      // },
    ],
  },
];

export default trackerPath;
