import { useState } from "react";
import { mockMediaItems } from "./mockData";
import { Image } from "antd";
import styles from "./styles.module.scss";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import cx from "classnames";
import UploadMedia from "./UploadMedia";

function MediaItem(props) {
  const { image = "", selected = false, onClick = () => {} } = props;
  return (
    <div
      className={cx("creative-image-selection ant-image-ration-1-1", { selected })}
      onClick={onClick}
    >
      <Image className="" src={image} preview={false} />
      {selected && (
        <div className="position-absolute-center z-index-2 overlay">
          <ActionIconRender iconType="check" />
        </div>
      )}
    </div>
  );
}

export default function Medias() {
  const [mediaItems, setMediaItems] = useState(mockMediaItems);

  function handleImageSelection(index, selected) {
    const temp = JSON.parse(JSON.stringify(mediaItems));
    temp[index] = { ...temp[index], selected };
    setMediaItems(temp);
  }

  return (
    <div>
      <p>Image/Video</p>
      <div className="d-flex-wrap">
        <div className={styles.creativeMediaCol}>
          <UploadMedia />
        </div>
        {mediaItems.map((data, index) => {
          const { selected = false } = data;
          return (
            <div className={styles.creativeMediaCol} key={"mediaItems" + index}>
              <MediaItem {...data} onClick={() => handleImageSelection(index, !selected)} />
            </div>
          );
        })}
      </div>
      <DebugPre content={[{ isRow: true, contentObjects: [{ mediaItems }] }]} />
    </div>
  );
}
