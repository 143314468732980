import { useSelector } from "react-redux";
import Logout from "shared/logout";
import { SideBarThemeToggle } from "shared/Theme/themeToggle";
import GhostUserSelection from "components/GhostUserSelection";
import DebugModeSelection from "components/DebugModeSelection";
import { WorkspaceSelectPersist } from "components/PersistComponents";
import { useEffect } from "react";
import { API_STORE_UMS_USERS } from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";

import ProfilePage from "./ProfilePage";

export default function ProfileCardContent() {
  const { apiCall: usersFetch = () => {}, refetch: usersRefetch } =
    useApiToStore(API_STORE_UMS_USERS);
  const lf_superadmin = useSelector((state) => state?.userReducer.me?.lf_superadmin);
  const isPseudoUser = useSelector((state) => state?.userReducer.me?.isPseudoUser);
  // const profilePic = useSelector((state) => state?.userReducer.me?.picture);

  useEffect(() => {
    if (lf_superadmin) {
      usersFetch();
    }
  }, [lf_superadmin]);

  return (
    <div className="profileCardContnet">
      {/* <div className="title mb-12">
        {profilePic ? (
          <Avatar className="mr-6" src={profilePic}></Avatar>
        ) : (
          <Avatar className="mr-6">{userInitials}</Avatar>
        )}
        {userSelector?.name}
        <span className="ml-50">
          <ActionIconRender iconType="setting" />
        </span>
      </div> */}
      <ProfilePage />
      <WorkspaceSelectPersist />
      {(lf_superadmin || isPseudoUser) && (
        <div className="mb-10">
          <GhostUserSelection usersRefetch={usersRefetch} />
        </div>
      )}
      {(lf_superadmin || isPseudoUser) && (
        <div className="mb-10">
          <DebugModeSelection />
        </div>
      )}
      <div className="mb-15">
        <SideBarThemeToggle />
      </div>
      {/* <div className="mb-12">Workspaces</div> */}
      <Logout />
    </div>
  );
}
