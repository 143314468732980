import { useState } from "react";
import { Avatar, Popover } from "antd";
import ProfileCardContent from "./ProfileCardContent";
import { useSelector } from "react-redux";
import { ExitGhostUser } from "components/GhostUserSelection";
export default function ProfileCard() {
  const [visible, setVisible] = useState(false);
  const userSelector = useSelector((state) => state?.userReducer?.user);
  const nameArray = userSelector?.name?.split(" ");
  const userInitials = nameArray?.map((data) => data.charAt(0));
  const isPseudoUser = useSelector((state) => state?.userReducer.me?.isPseudoUser);

  return (
    <>
      {isPseudoUser && <ExitGhostUser />}
      <div className="profileCard">
        <Popover
          placement="rightBottom"
          visible={visible}
          onVisibleChange={(e) => setVisible(e)}
          content={<ProfileCardContent />}
          trigger="click"
        >
          <Avatar>{userInitials}</Avatar>
        </Popover>
      </div>
    </>
  );
}
