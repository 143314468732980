import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import alasql from "alasql";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useTiktokSpendReportsQuery from "hooks/tiktokHooks/useTiktokSpendReportsQuery";
import { REPORT_TYPE_AD, REPORT_TYPE_AD_ADSET, REPORT_TYPE_CAMPAIGN } from "shared/SharedKeys";
import moment from "moment";
import { dateFormat } from "reducers/userSettings";
import useLeadgenReportsQuery from "hooks/tiktokHooks/useLeadgenReportsQuery";
import useTiktokVoluumColumns from "pages/dashboardPages/TikTok/useTiktokVoluumColumns";

export default function PerformanceReport(props) {
  const {
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
    reportType,
    timezone: timeZoneProps,
    adId,
    adsetId,
    campaignId,
    outerDate = false,
    from,
    to,
    // isPerformance = false,
    isPerformanceHourly = false,
  } = props;

  function getAssetId(type) {
    switch (type) {
      case REPORT_TYPE_AD:
        return { assetId: adId };
      case REPORT_TYPE_AD_ADSET:
        return { assetId: adsetId };
      case REPORT_TYPE_CAMPAIGN:
        return { assetId: campaignId };

      default: {
        return {};
      }
    }
  }
  const assetId = getAssetId(reportType);

  function getAssetIdForRevenue(type) {
    switch (type) {
      case REPORT_TYPE_AD:
        return { ids: [adId] };
      case REPORT_TYPE_AD_ADSET:
        return { ids: [adsetId] };
      case REPORT_TYPE_CAMPAIGN:
        return { ids: [campaignId] };

      default: {
        return {};
      }
    }
  }
  const assetIdForRevenue = getAssetIdForRevenue(reportType);

  const [mergedReport, setMergedReport] = useState([]);

  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);

  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });

  const defaultDate = outerDate
    ? [from, to]
    : [moment().subtract(8, "d").format(dateFormat), moment().subtract(1, "d").format(dateFormat)];

  const [rangeDates, setRangeDates] = useState([...defaultDate]);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const dateRangeParamsSpend = { from: rangeDates[0], to: rangeDates[1], timezone: timeZone.value };
  const dateRangeParamsRevenue = {
    start_date: rangeDates[0],
    end_date: rangeDates[1],
    timezone: timeZone.value,
  };

  const { loading_revenue, refetch_revenue, revenueReport, setRevenueReport } =
    useLeadgenReportsQuery(reportType, {
      // isPerformance,
      isPerformanceHourly,
      ...dateRangeParamsRevenue,
      variables: { ...assetIdForRevenue },
      platform: 7,
    });

  const { getSpendReport, loading_spend, refetch_spend, spendReport, setSpendReport } =
    useTiktokSpendReportsQuery(reportType, {
      ...dateRangeParamsSpend,
      // isPerformance,
      isPerformanceHourly,
      variables: { ...assetId },
    });

  useEffect(() => {
    getSpendReport();
  }, [rangeDates[0], rangeDates[1], timeZone.value]);

  const refetch = () => {
    refetch_revenue();
    refetch_spend();
  };

  const loading = loading_revenue || loading_spend;

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const { tiktokVoluumPerformanceColumns } = useTiktokVoluumColumns({ reportType });

  const initialColumns = [...tiktokVoluumPerformanceColumns];

  let finalMerge = [];
  let finalMergeData = [];

  useEffect(() => {
    if (!loading) {
      alasql
        .promise("SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.report_time = b.report_time", [
          revenueReport,
          spendReport,
        ])
        .then((res) => {
          setMergedReport(res);
        })
        .catch(function (err) {});
    }
  }, [loading, revenueReport, spendReport]);

  finalMerge = mergedReport;
  if (!loading) {
    finalMerge?.forEach((element, index) => {
      const {
        spend = 0,
        clicksTikTok = 0,
        impressionsTiktok = 0,
        revenue = 0,
        searches = 0,
        clicks = 0,
      } = element;
      const spendUpdate = spend.toFixed(2);

      const PROFIT = parseFloat(revenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CPC = parseFloat(spendUpdate) / parseFloat(clicks);
      const CPR_V = parseFloat(spendUpdate) / parseFloat(clicks);
      const RPV = parseFloat(revenue) / parseFloat(clicksTikTok);
      const RPL = parseFloat(revenue) / parseFloat(clicks);
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksTikTok);
      const AD_CTR = (parseFloat(clicksTikTok) / parseFloat(impressionsTiktok)) * 100 || 0;
      const LP_CTR = (parseFloat(searches) / parseFloat(clicksTikTok)) * 100;
      const CR = (parseFloat(clicks) / parseFloat(clicksTikTok)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsTiktok)) * 1000;

      finalMergeData.push({
        ...element,
        key: index,
        impressionsTiktok,
        spend: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        CPV: valueCheck(CPV),
        CPC: valueCheck(CPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        RPL: valueCheck(RPL),
        CPR_V: valueCheck(CPR_V),
        RPV: valueCheck(RPV),
      });
    });
  }

  const searchCampaignObject =
    REPORT_TYPE_CAMPAIGN === reportType ? ["campaignId", "campaign_name", "categoryName"] : [];
  const searchAdSetObject =
    REPORT_TYPE_AD_ADSET === reportType ? ["adsetId", "adgroup_name", "categoryName"] : [];
  const searchAdObject = REPORT_TYPE_AD === reportType ? ["adId", "ad_name", "categoryName"] : [];
  const searchAdAccountObject =
    REPORT_TYPE_AD === reportType ? ["accountId", "name", "categoryName"] : [];

  const finalFilterData = outerFilterData(filterData(finalMergeData), {
    filterKeys: [
      ...searchAdSetObject,
      ...searchCampaignObject,
      ...searchAdObject,
      ...searchAdAccountObject,
    ],
  });

  let total_spend = 0,
    total_clicksTikTok = 0,
    total_impressionsTiktok = 0,
    total_revenue = 0,
    total_clicks = 0,
    total_searches = 0,
    total_sessions = 0,
    total_leadsFB = 0;

  for (const {
    spend = 0,
    clicksTikTok = 0,
    impressionsTiktok = 0,
    revenue = 0,
    clicks = 0,
    leadsFB = 0,
    searches = 0,
    sessions = 0,
  } of finalFilterData) {
    total_spend += parseFloat(spend || 0);
    total_clicksTikTok += parseFloat(clicksTikTok || 0);
    total_impressionsTiktok += parseFloat(impressionsTiktok || 0);
    total_revenue += parseFloat(revenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_leadsFB += parseFloat(leadsFB || 0);
    total_searches += parseFloat(searches || 0);
    total_sessions += parseFloat(sessions || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spend);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spend)) * 100 || 0;
  const total_CPV = parseFloat(total_spend) / parseFloat(total_clicksTikTok);
  const total_CPC = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_AD_CTR =
    (parseFloat(total_clicksTikTok) / parseFloat(total_impressionsTiktok)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_searches) / parseFloat(total_clicksTikTok)) * 100;
  const total_CPM = (parseFloat(total_spend) / parseFloat(total_impressionsTiktok)) * 1000;
  const total_CPR_V = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_RPV = parseFloat(total_revenue) / parseFloat(total_clicksTikTok);
  const total_RPL = parseFloat(total_revenue) / parseFloat(total_clicks);
  const total_CR = (parseFloat(total_clicks) / parseFloat(total_clicksTikTok)) * 100;

  const summaryData = {
    spend: valueCheck(total_spend, true),
    clicksTikTok: valueCheck(total_clicksTikTok, true),
    impressionsTiktok: valueCheck(total_impressionsTiktok),
    revenue: valueCheck(total_revenue, true),
    clicks: valueCheck(total_clicks, true),
    leadsFB: valueCheck(total_leadsFB),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    CPV: valueCheck(total_CPV, true),
    CPC: valueCheck(total_CPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    searches: valueCheck(total_searches),
    sessions: valueCheck(total_sessions),
    CPR_V: valueCheck(total_CPR_V, true),
    RPV: valueCheck(total_RPV, true),
    // CPL: valueCheck(total_CPL, true),
    RPL: valueCheck(total_RPL, true),
  };

  const tableMainProps = {
    exportCSV: true,
    persistKey,
    isLineChart: true,
    isRowSelection: false,
    isDateRange: true,
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },

    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
  };
  const debugContent = [
    {
      isRow: true,
    },
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ spendReport }, { revenueReport }] },
  ];
  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
