import { Col, Collapse, Pagination, Popover, Row, Tooltip, Checkbox } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookTable from "components/LookTable";
import React, { useEffect, useState } from "react";
import alasql from "alasql";
import {
  CREATIVE_TYPE,
  CREATIVE_TYPE_ALL_GROUPS,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_VIDEO,
  GROUP_TYPE_STATIC,
} from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";
import LookDrawer from "components/LookDrawer";
import CreativeDeatiledReporting from "./CreativeDetailedReportng";
import cx from "cx";
import LookModal from "components/LookModal";
import ComplianceModal from "../Compliance/ComplianceModal";
import {
  GQL_API_RESPONSE_STATUS_FAILURE,
  GQL_API_RESPONSE_STATUS_SUCCESS,
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_TIKTOK,
  PRODUCT_ID_LEADGEN,
  PRODUCT_ID_SAS,
  PRODUCT_ID_VS,
  REPORT_TYPE_LIVE,
} from "shared/SharedKeys";
import { useSelector } from "react-redux";
import LookButton from "components/LookButton";
import { PLATFORM_TYPE_FACEBOOK } from "shared/enum/launchKeysAndEnum";
import {
  CREATIVE_TRACKING_AGENCY_ADMIN,
  CREATIVE_TRACKING_CREATIVE_DIRECTOR,
  CREATIVE_TRACKING_GRAPHIC_DESIGNER,
  UMS_AGENCY_ADMIN,
  UMS_TEAM_ADMIN,
} from "shared/UserPermission/userPermissionList";
import { useApiToStore, usePermissionCheck } from "hooks";
import { API_STORE_VIRALSPARKS_ADVERTISE } from "hooks/useApiToStore/keys";
import {
  ADS_TYPE_CATEGORY,
  ADS_TYPE_CONTENT,
  ADS_TYPE_DEFAULT,
  FILTER_TYPE_CATEGORY,
  FILTER_TYPE_CREATOR,
  FILTER_TYPE_SUBIDS,
  FILTER_TYPE_USER,
  SORT_TYPE_ASC,
  SORT_TYPE_DESC,
} from "../creativesEnum";
import ColumnFilterModal from "./ColumnFilterModal";
import SelectInput from "components/forms/SelectInput";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import AdgroupDetailView from "./CreativeDetailedReportng/AdgroupDetailView";
import DebugPre from "components/DebugPre";
import LazyList from "./LazyList";
import LookTextInput from "components/forms/LookTextInput";
import CreativeAnalysis from "./CreativeAnalysis";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import ComplianceGroupModal from "pages/dashboardPages/Creatives/Compliance/ComplianceGroupModal";
import useCreativeReportingApiHooks from "../CreativeHooks/useCreativeReportingApiHooks";
import useCustomeView from "./useCustomeView";
import SimilarImages from "components/Launch/Creatives/SimilarImages";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import CreativeCompareModal from "./CreativeAnalysis/CreativeCompareModal";

function CreativeReportView(props) {
  const {
    revDataKey,
    spendDataKey,
    creativeTypeFilter,
    isGlobalReport = false,
    // templatePlatforms,
    // serviceType,
    creativeType,
    isFbReport = false,
    revVariables,
    spendVariables,
    serviceType,
    revCategoryVariables,
    revPlatformTiktokVariables,
    mergedReport,
    setMergedReport = () => {},
    appliedFilters,
    setAppliedFilters = () => {},
    appliedSortings,
    setAppliedSortings,
    persistKey = "",
    mergedDataforCSV = [],
    setMergedDataforCSV = () => {},
  } = props;

  /*
   * add enums & optimize in future for all files related to creative reporting
   */

  const dateFormat = "YYYY-MM-DD";
  const { Image } = require("antd");
  const [spendReport, setSpendReport] = useState([]);
  const [playModal, setPlayModal] = useState(false);
  const [revReport, setRevReport] = useState([]);
  const [users, setUsers] = useState([]);
  const [appliedColumnFilters, setAppliedColumnFilter] = useState({ filterColumns: [{}] });
  const [activeKey, setActiveKey] = useState(null);
  const [activeSortingKey, setActiveSortingKey] = useState(null);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleSimilarDrawer, setVisibleSimilarDrawer] = useState(false);
  const [rowProps, setRowProps] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [complianceValue, setComplianceValue] = useState({});
  const [visibleParentCompliantModal, setVisibleParentCompliantModal] = useState(false);
  const [visibleChildCompliantModal, setVisibleChildCompliantModal] = useState(false);
  const [selectedCreatives, setSelectedCreatives] = useState({});
  const [selectedBulkCreative, setSelectedBulkCreative] = useState([]);
  const [visibleCreativeCompareModal, setVisibleCreativeCompareModal] = useState(false);
  const [visibleCategoryModal, setVisibleCategoryModal] = useState(false);
  const [visibleCategoryCompliantModal, setVisibleCategoryCompliantModal] = useState(false);
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const myUsers = useSelector((state) => state?.userSettings?.userSettingData?.myUsers);
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [visibleUserModal, setVisibleUserModal] = useState(false);
  const [visibleColumnFilter, setVisibleColumnFilter] = useState(false);
  const [finalMerge, setFinalMerge] = useState([]);
  const [subIds, setSubIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [adsType, setAdsType] = useState(ADS_TYPE_DEFAULT);
  const [isFiltered, setIsFiltered] = useState(false);
  const isGroup = creativeType === CREATIVE_TYPE;
  // const [searchMode, setSearchMode] = useState({
  //   User: false,
  //   Creator: false,
  //   Categories: false,
  //   SubIds: false,
  // });
  const { myUsersFetch = () => {} } = useApiToStoreContext();
  const [updatedUser, setUpdateduser] = useState();
  const [activeParentTab, setActiveParentTab] = useState("creativeReporting");
  const [activeSimilarTab, setActiveSimilarTab] = useState("similarImage");
  const [filterCurrentPage, setFilterCurrentPage] = useState({
    User: 1,
    Creator: 1,
    Categories: 1,
    SubIds: 1,
  });
  const [initialPopoverVisible, setInitialPopoverVisible] = useState(false);
  const [sortingPopoverVisible, setSortingPopoverVisible] = useState(false);
  const [searchFilter, setSearchFilter] = useState({
    User: { searchMode: false, filterValue: "" },
    Creator: { searchMode: false, filterValue: "" },
    Categories: { searchMode: false, filterValue: "" },
    SubIds: { searchMode: false, filterValue: "" },
  });

  // all permissions
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const { havePermission: isCreativeAgencyAdmin } = usePermissionCheck(
    CREATIVE_TRACKING_AGENCY_ADMIN
  );
  const { havePermission: isGraphicDesigner } = usePermissionCheck(
    CREATIVE_TRACKING_GRAPHIC_DESIGNER
  );
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const { havePermission: isUmsTeamAdmin } = usePermissionCheck(UMS_TEAM_ADMIN);

  // handle compliance data by category
  const handleComplianceByCategory = (complianceData) => {
    const { data = [] } = complianceData || {};
    if (!isGroup) {
      if (creativeType === CREATIVE_TYPE_IMAGE) {
        const hasNonCompliant = data?.some(
          (ele) => ele?.imageCompliance?.[0]?.complianceStatus === "NO"
        );
        const hasCompliant = data?.some(
          (ele) => ele?.imageCompliance?.[0]?.complianceStatus === "YES"
        );
        setComplianceValue((prev) => ({
          ...prev,
          compliance: hasCompliant ? "YES" : hasNonCompliant ? "NO" : "PENDING",
          complianceDescription: data?.[0]?.imageCompliance?.[0]?.notes,
        }));
      } else if (creativeType === CREATIVE_TYPE_VIDEO) {
        const hasNonCompliant = data?.some(
          (ele) => ele?.videoCompliance?.[0]?.complianceStatus === "NO"
        );
        const hasCompliant = data?.some(
          (ele) => ele?.videoCompliance?.[0]?.complianceStatus === "YES"
        );
        setComplianceValue((prev) => ({
          ...prev,
          compliance: hasCompliant ? "YES" : hasNonCompliant ? "NO" : "PENDING",
          complianceDescription: data?.[0]?.videoCompliance?.[0]?.notes,
        }));
      }
    } else {
      function getComplianceStatusById(id, creative, key) {
        const result = [];
        if (creative?.id === id) {
          return creative?.[key]?.[0]?.complianceStatus;
        }
      }
      const { data = [] } = complianceData || {};
      const { groupCompliance = {} } = data?.[0];
      const groupType = rowProps?.creative_url_object?.groups?.[0]?.groupType?.toLowerCase();
      if (groupType?.toUpperCase() === GROUP_TYPE_STATIC) {
        setRowProps((prev) => {
          const { creative_url_object } = prev || {};
          const { groups = [] } = creative_url_object || {};

          const complianceObj = groupCompliance?.[groupType]?.[0];
          const {
            videos = [],
            images = [],
            titles = [],
            descriptions = [],
            bodies = [],
          } = groups?.[0] || [];
          const getUrlWithComplianceStatus = (urls, id, type) =>
            urls?.length > 0
              ? [
                  {
                    ...urls[0],
                    complianceStatus: getComplianceStatusById(
                      urls[0]?.id,
                      complianceObj?.[`${type}Id`],
                      `${type}Compliance`
                    ),
                  },
                ]
              : [];

          const getTitleWithComplianceStatus = (titles) =>
            titles?.length > 0
              ? [
                  {
                    ...titles[0],
                    complianceStatus: getComplianceStatusById(
                      titles[0]?.id,
                      complianceObj?.titleId,
                      "titleCompliance"
                    ),
                  },
                ]
              : [];

          const getDescriptionWithComplianceStatus = (descriptions) =>
            descriptions?.length > 0
              ? [
                  {
                    ...descriptions[0],
                    complianceStatus: getComplianceStatusById(
                      descriptions[0]?.id,
                      complianceObj?.descId,
                      "descriptionCompliance"
                    ),
                  },
                ]
              : [];

          const getBodyWithComplianceStatus = (bodies) =>
            bodies?.length > 0
              ? [
                  {
                    ...bodies[0],
                    complianceStatus: getComplianceStatusById(
                      bodies[0]?.id,
                      complianceObj?.bodyId,
                      "bodyCompliance"
                    ),
                  },
                ]
              : [];
          const updatedGroups = [
            {
              images: [
                {
                  urls: getUrlWithComplianceStatus(
                    images?.[0]?.urls,
                    images?.[0]?.urls?.[0]?.id,
                    "image"
                  ),
                },
              ],
              videos: [
                {
                  urls: getUrlWithComplianceStatus(
                    videos?.[0]?.urls,
                    videos?.[0]?.urls?.[0]?.id,
                    "video"
                  ),
                },
              ],
              titles: getTitleWithComplianceStatus(titles),
              descriptions: getDescriptionWithComplianceStatus(descriptions),
              bodies: getBodyWithComplianceStatus(bodies),
            },
          ];

          return {
            ...prev,
            creative_url_object: { groups: updatedGroups },
          };
        });
      }
    }
  };

  const handleCreativeIds = (data) => {
    if (isFbReport) {
      if (isGroup) {
        return data?.group_id;
      } else {
        if (creativeType === CREATIVE_TYPE_IMAGE) {
          return data?.creative_url_object?.images?.[0]?.urls?.[0]?.id;
        } else {
          return data?.creative_url_object?.videos?.[0]?.urls?.[0]?.id;
        }
      }
    } else {
      return data?.group_id;
    }
  };

  // apis and respective functions via hooks
  const {
    fetchVsContent,
    vs_content_data,
    vs_content_loading,
    vs_content_refetch,
    fetchSasCategory,
    sas_loading,
    fetchLeadgen,
    leadgen_loading,
    leadgen_data,
    leadgen_error,
    leadgen_refetch,
    getSubIds,
    loading_subid,
    refetch_subid,
    getUser,
    loading_user,
    refetch_user,
    getTeamUser,
    loading_team_user,
    getSources,
    getComplianceStatus,
    loading_compliance_status,
    refetch_compliance_status,
    updateUser,
    loading_update_user,
    fetchVsRev,
    vs_loading,
    refetch_vs_rev,
    updateCreativeCompliance,
    loading_add_compliance,
    revenueFbFetch,
    loading_rev_fb_reports,
    refetch_fb_rev,
    revenueTiktokFetch,
    loading_rev_tiktok_reports,
    refetch_tiktok_rev,
    spendFbFetch,
    loading_spend_fb__reports,
    refetch_spend_fb,
    spendTiktokFetch,
    loading_spend_tiktok_reports,
    refetch_spend_tiktok,
    getCommonCompliance,
    loading_compliance,
  } = useCreativeReportingApiHooks({
    setCategories,
    isGroup,
    setSubIds,
    setUsers,
    setFinalMerge,
    setUpdateduser,
    setRowProps,
    setVisibleUserModal,
    setRevReport,
    setTotalCount,
    setVisibleFilter,
    setActiveKey,
    setSpendReport,
    handleComplianceByCategory,
    setVisibleChildCompliantModal,
    setVisibleCategoryCompliantModal,
    setVisibleParentCompliantModal,
    spendDataKey,
    revDataKey,
    creativeType,
    rowProps,
    handleCreativeIds,
    finalMerge,
    isFbReport,
  });

  // drawer and modal functions
  const showDetailedReportsDrawer = (data) => {
    setVisibleDrawer(true);
    setRowProps(data);
  };
  const showSimilarImagesDrawer = (data) => {
    setVisibleSimilarDrawer(true);
    setRowProps(data);
  };
  const handleCategoryCompliantModal = (data) => {
    const { creative_url_object = {} } = data;
    const { groups = [] } = creative_url_object;

    getCommonCompliance({
      variables: {
        categoryId: complianceValue?.categoryId,
        creativeType: creativeType === CREATIVE_TYPE ? "GROUP" : creativeType,
        productId: serviceType,
        creativeIds: [
          {
            creativeId: handleCreativeIds(data),
            ...(isGroup && { groupType: isFbReport ? groups?.[0]?.groupType : GROUP_TYPE_STATIC }),
          },
        ],
      },
    });
  };
  const onCloseParentCompliantModal = () => {
    setVisibleParentCompliantModal(false);
    setComplianceValue({});
    setSelectedCreatives({});
    setVisibleCategoryCompliantModal(false);
  };
  const onOpenCategoryCompliantModal = (data) => {
    setRowProps(data);
    setVisibleCategoryCompliantModal(true);
  };
  const onCloseChildCompliantModal = () => {
    setVisibleChildCompliantModal(false);
    setComplianceValue({});
    setSelectedCreatives({});
  };
  const onCloseCategoryCompliantModal = () => {
    setVisibleCategoryCompliantModal(false);
    setComplianceValue({});
    setSelectedCreatives({});
  };
  const onCloseUserModal = () => {
    setVisibleUserModal(false);
    setUpdateduser();
  };
  const onCloseColumnFilterModal = () => {
    setVisibleColumnFilter(false);
  };

  const onCloseCreativeDrawer = () => {
    setVisibleDrawer(false);
    setRowProps({});
    setAdsType(ADS_TYPE_DEFAULT);
    setActiveParentTab("creativeReporting");
  };
  const onCloseSimilarDrawer = () => {
    setVisibleSimilarDrawer(false);
    setRowProps({});
    setAdsType(ADS_TYPE_DEFAULT);
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const { sasCategoriesFetch, voluumCategoryFetch: leadgenCategoryFetch } = useApiToStoreContext();

  const { sasCategories, voluumCategories } = useSelector(
    (state) => state?.userSettings?.userSettingData
  );

  const filterColumns = [
    { title: "Spend", searchType: "number", dataIndex: "spend", key: "spend" },
    // { title: "ROAS", searchType: "number", dataIndex: "roas", key: "roas" },
    // { title: "G Margin", searchType: "number", dataIndex: "g_margin", key: "g_margin" },
    { title: "Revenue", searchType: "number", dataIndex: "revenue", key: "revenue" },
  ];
  const handleComplianceIcon = (status) => {
    switch (status) {
      case "YES":
        return "greenTick";
      case "NO":
        return "redCross";
      case "EXCEPTION":
        return "yellowTick";
      case "PARTIALLY":
        return "orangeTick";
      case "PENDING":
        return "grayTick";
      default:
        return "grayTick";
    }
  };

  const handleCollapseChange = (keys, data) => {
    setActiveKey(keys);
    setSearchFilter({
      User: { searchMode: false, filterValue: "" },
      Creator: { searchMode: false, filterValue: "" },
      Categories: { searchMode: false, filterValue: "" },
      SubIds: { searchMode: false, filterValue: "" },
    });
    setFilterCurrentPage((prev) => ({
      User: 1,
      Creator: 1,
      Categories: 1,
      SubIds: 1,
    }));
  };

  const handleSortingChanges = (keys) => {
    setActiveSortingKey(keys);
  };

  //handling spend, rev api calls on basis of platforms
  const handleRevSpendApiCall = () => {
    const sortingObj = appliedSortings?.length > 0 ? { order: appliedSortings?.[0]?.id } : {};
    if (isFbReport) {
      if (parseInt(serviceType) === PRODUCT_ID_SAS) {
        revenueTiktokFetch({
          variables: {
            ...revVariables,
          },
        });
      } else if (parseInt(serviceType) === PRODUCT_ID_VS) {
        fetchVsRev({
          variables: {
            ...revVariables,
            advertiserId: 2, // added static value for now, will add tomorrow filters for advertser id
          },
        });
      } else {
        revenueFbFetch({
          variables: {
            ...revVariables,
          },
        });
      }
      spendFbFetch({
        variables: {
          ...spendVariables,
          product_id: serviceType,
          ...sortingObj,
        },
      });
    } else {
      if (parseInt(serviceType) === PRODUCT_ID_LEADGEN) {
        revenueFbFetch({
          variables: {
            ...revVariables,
          },
        });
      } else {
        revenueTiktokFetch({
          variables: {
            ...revVariables,
          },
        });
      }
      spendTiktokFetch({
        variables: {
          ...spendVariables,
          ...sortingObj,
        },
      });
    }
  };

  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const { apiCall: vsAdvertiserFetch = () => {}, refetch: vsAdvertiserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_ADVERTISE
  );

  // loading vars for reports
  const loading_fb = loading_rev_fb_reports || loading_spend_fb__reports || vs_loading;
  const loading_tiktok = loading_rev_tiktok_reports || loading_spend_tiktok_reports;
  // handling unique key for reports
  const fbprimaryKey =
    creativeType === CREATIVE_TYPE_IMAGE
      ? "image_group_id"
      : creativeType === CREATIVE_TYPE_VIDEO
      ? "video_group_id"
      : "creative_group_id";
  const tiktokSpendPrimaryKey =
    creativeType === CREATIVE_TYPE_VIDEO ? "ct_video_id" : "ct_group_id";
  const tiktokRevPrimaryKey =
    creativeType === CREATIVE_TYPE_VIDEO
      ? "ctVideoId"
      : creativeType === CREATIVE_TYPE_IMAGE
      ? "ctImageId"
      : "ctGroupId";
  const handleUniquekey = () => {
    if (adsType === ADS_TYPE_DEFAULT) {
      return isFbReport ? fbprimaryKey : tiktokSpendPrimaryKey;
    } else if (adsType === ADS_TYPE_CATEGORY || adsType === ADS_TYPE_CONTENT) {
      return "category_id";
    } else {
      return "platform_id";
    }
  };

  // All useEffects
  useEffect(() => {
    if (isCreativeDirector || isCreativeAgencyAdmin) {
      getUser({
        variables: {
          permissions: [CREATIVE_TRACKING_GRAPHIC_DESIGNER],
        },
      });
    }
    if (isCreativeDirector || isUmsAgencyAdmin) {
      getTeamUser({ variables: { id: serviceType } });
    }

    if (parseInt(serviceType) === PRODUCT_ID_LEADGEN) {
      getSubIds({
        variables: { platform_id: isFbReport ? PLATFORM_ID_FACEBOOK : PLATFORM_ID_TIKTOK },
      });
    }

    if (parseInt(serviceType) === PRODUCT_ID_VS) {
      vsAdvertiserFetch();
    } else if (parseInt(serviceType) === PRODUCT_ID_SAS) {
      sasCategoriesFetch();
    } else {
      leadgenCategoryFetch();
    }
  }, [serviceType]);

  useEffect(() => {
    if (appliedFilters?.filter((data) => data?.id?.length > 0).length === 0) {
      handleRevSpendApiCall();
    } else {
      handleFilter();
    }
  }, [serviceType, creativeType, isFbReport, dateRangeTimezone, isFiltered, appliedSortings]);
  useEffect(() => {
    if (mergedReport?.length > 0 && !loading_fb && !loading_tiktok && !finalMerge?.[0]?.hasSource) {
      // need to set data in merged report rather than finalmerge
      getSources({
        variables: {
          creativeIds: finalMerge?.map((data) => data?.group_id),
          creativeType: creativeType === CREATIVE_TYPE ? CREATIVE_TYPE_ALL_GROUPS : creativeType,
        },
      });
    }
  }, [finalMerge]);
  //here we are mapping the merge data for the pagination and CSV data export
  const dataMappingCallback = (data) => {
    let group_id;
    const {
      image_group_id = "",
      video_group_id = "",
      creative_group_id = "",
      spend = 0,
      ctVideoId = "",
      ctGroupId = "",
      ct_video_id = "",
      ct_group_id = "",
      clicks = "",
      creative_url_object = {},
    } = data;
    const updatedSpend = data?.spend * 1.05;
    const gross_margin =
      parseFloat(data?.revenue || data?.estimate_revenue || 0) - parseFloat(updatedSpend) || 0;
    const CPC = parseFloat(updatedSpend) / parseFloat(clicks);
    const ROAS = (parseFloat(gross_margin) / parseFloat(updatedSpend)) * 100 || 0;
    const creativeGroupName = creative_url_object?.groups?.[0]?.titles?.[0]?.text;

    if (isFbReport) {
      group_id =
        creativeType === CREATIVE_TYPE_IMAGE
          ? image_group_id
          : creativeType === CREATIVE_TYPE_VIDEO
          ? video_group_id
          : creative_group_id;
    } else {
      group_id =
        creativeType === CREATIVE_TYPE_VIDEO ? ctVideoId || ct_video_id : ctGroupId || ct_group_id;
    }
    return {
      ...data,
      spend: updatedSpend?.toFixed(2),
      roas: ROAS?.toFixed(2),
      revenue: data?.revenue?.toFixed(2),
      gross_margin: gross_margin?.toFixed(2),
      group_id,
      hasSource: false,
      CPC: clicks ? CPC?.toFixed(2) : 0,
      creative_group_name: creativeGroupName,
    };
  };

  useEffect(() => {
    const finalResMergeForCSV = mergedReport?.map(dataMappingCallback);
    setMergedDataforCSV(finalResMergeForCSV);
  }, [mergedReport]);

  useEffect(() => {
    const finalResMerge = mergedReport
      ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
      ?.map(dataMappingCallback);
    setFinalMerge(finalResMerge);
  }, [mergedReport, pageSize, currentPage]);

  useEffect(() => {
    const data = handleUniquekey();
    if (!(loading_fb || loading_tiktok) && spendReport?.length > 0) {
      alasql
        .promise(
          `SELECT b,
         * FROM ? as a
          LEFT JOIN ? as b
          ON a.${data}
         = b.${
           adsType === ADS_TYPE_DEFAULT
             ? !isFbReport || parseInt(serviceType) === PRODUCT_ID_SAS
               ? tiktokRevPrimaryKey
               : fbprimaryKey
             : data
         }`,
          [spendReport, revReport]
        )
        .then((res) => {
          setMergedReport(res);
          setTotalCount(res?.length);
        })
        .catch(function (err) {
          console.log({ err });
        });
    } else {
      setMergedReport([]);
      setTotalCount(0);
    }
  }, [loading_fb, loading_tiktok, spendReport, revReport, creativeType]);
  useEffect(() => {
    if (isUmsTeamAdmin) {
      myUsersFetch();
    }
  }, []);

  // refetch functions
  const refetch_fb = () => {
    setTotalCount(0);
    if (parseInt(serviceType) === PRODUCT_ID_SAS) {
      // if (parseInt(serviceType) === PRODUCT_ID_LEADGEN) {
      //   refetch_fb_rev();
      // } else
      refetch_tiktok_rev();
    } else {
      if (parseInt(serviceType) === PRODUCT_ID_VS) {
        refetch_vs_rev();
      } else {
        refetch_fb_rev();
      }
    }
    refetch_spend_fb();
  };
  const refetch_tiktok = () => {
    setTotalCount(0);
    if (parseInt(serviceType) === PRODUCT_ID_LEADGEN) {
      refetch_fb_rev();
    } else {
      refetch_tiktok_rev();
    }
    refetch_spend_tiktok();
  };

  // handling platforms icon view
  const handlePlatform = (platforms) => {
    const arr = [];
    platforms?.map(({ name }) => {
      if (name?.toLowerCase() === PLATFORM_TYPE_FACEBOOK) {
        arr.push(<ActionIconRender iconType="facebook-outlined" />);
      } else {
        arr.push(<ActionIconRender iconType="tiktok" />);
      }
    });
    return arr;
  };

  // table custome view
  const { customeView } = useCustomeView({
    finalMerge,
    isCreativeDirector,
    isUmsAgencyAdmin,
    isCreativeAgencyAdmin,
    setRowProps,
    setVisibleUserModal,
    showDetailedReportsDrawer,
    onOpenCategoryCompliantModal,
    isFbReport,
    creativeType,
    handlePlatform,
    setPlayModal,
    setRowProps,
    handleComplianceIcon,
    loading_fb,
    loading_tiktok,
    isGroup,
    isGlobalReport,
    showSimilarImagesDrawer,
    setSelectedBulkCreative,
    selectedBulkCreative,
  });
  const openFilter = () => {
    setVisibleFilter(true);
    setInitialPopoverVisible(false);
  };

  const popoverFilterOptions = (
    <div>
      <LookButton onClick={openFilter}>Filter</LookButton>
      {/* mb-2 is remover should add when column filter uncommented */}
      {/* <br />
      <LookButton
        onClick={() => {
          setVisibleColumnFilter(true);
          setInitialPopoverVisible(false);
        }}
      >
        Column Filter
      </LookButton> */}
    </div>
  );

  const sortingContent = [
    {
      dataKey: "Spend",
      value: [
        { id: SORT_TYPE_ASC, value: "Low to High" },
        { id: SORT_TYPE_DESC, value: "High to Low" },
      ],
    },
  ];

  const popoverSortingOptions = (
    <>
      {sortingContent?.map((data, index) => {
        return (
          <div
            className="mostly-customized-scrollbar"
            style={{ maxHeight: "400px", overflowY: "scroll" }}
            key={index}
          >
            <Collapse
              activeKey={activeSortingKey}
              collapsible="header"
              accordion
              style={{ border: "none", width: "200px" }}
              onChange={(e) => handleSortingChanges(e, data)}
            >
              <Collapse.Panel
                // className={beFilter?.length > 0 && "collapse-custom_header"}
                header={data["dataKey"]}
                key={index}
              >
                {data["value"]?.map((innerData, index) => {
                  const isSorted =
                    appliedSortings?.find((item) => item.sortType === data["dataKey"])?.id ===
                    innerData?.id;
                  return (
                    <>
                      <div key={index} className="text-overflow-ellipsis">
                        <Checkbox
                          onChange={(e) => {
                            setAppliedSortings((prev) => {
                              let finalSorting = [];
                              if (prev?.[0]?.id !== innerData?.id) {
                                finalSorting = [{ sortType: data["dataKey"], id: innerData?.id }];
                              }
                              return finalSorting;
                            });
                          }}
                          value={innerData?.value}
                          checked={isSorted}
                        >
                          <Tooltip title={innerData?.value}>{innerData?.value}</Tooltip>
                        </Checkbox>
                      </div>
                    </>
                  );
                })}
              </Collapse.Panel>
            </Collapse>
          </div>
        );
      })}
    </>
  );
  const handleFilter = () => {
    const spendFilterVariables = appliedFilters?.reduce((acc, data) => {
      if (data?.id?.length > 0) {
        acc.push({ [data?.spendApikey]: data.id });
      }
      return acc;
    }, []);

    const revFilterVariables = appliedFilters?.reduce((acc, data) => {
      if (data?.id?.length > 0) {
        acc.push({ [data?.revApikey]: data.id });
      }
      return acc;
    }, []);
    const sortingObj = appliedSortings?.length > 0 ? { order: appliedSortings?.[0]?.id } : {};
    setIsFiltered(spendFilterVariables.length > 0 || revFilterVariables.length > 0);
    if (isFbReport) {
      if (parseInt(serviceType) === PRODUCT_ID_SAS) {
        revenueTiktokFetch({
          variables: {
            ...revVariables,
            ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
            ...Object.assign({}, ...revFilterVariables),
          },
        });
      } else {
        revenueFbFetch({
          variables: { ...revVariables, ...Object.assign({}, ...spendFilterVariables) },
        });
      }
      spendFbFetch({
        variables: {
          ...spendVariables,
          ...sortingObj,
          ...Object.assign({}, ...spendFilterVariables),
        },
      });
    } else {
      spendTiktokFetch({
        variables: {
          ...spendVariables,
          ...sortingObj,
          ...Object.assign({}, ...spendFilterVariables),
        },
      });
      if (parseInt(serviceType) === PRODUCT_ID_SAS) {
        revenueTiktokFetch({
          variables: {
            ...revVariables,
            ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
            ...Object.assign({}, ...revFilterVariables),
          },
        });
      } else {
        revenueFbFetch({
          variables: { ...revVariables, ...Object.assign({}, ...spendFilterVariables) },
        });
      }
    }
  };

  function handleCreativeFilterData(data, dataKey) {
    return data?.slice(0, filterCurrentPage?.[dataKey] * 10);
  }
  const userData = isUmsTeamAdmin ? myUsers : users?.users;
  const handleCategories = (serviceType) => {
    switch (parseInt(serviceType)) {
      case PRODUCT_ID_LEADGEN:
        return voluumCategories;

      case PRODUCT_ID_SAS:
        return sasCategories;

      default:
        break;
    }
  };
  const finalCategories = handleCategories(serviceType);

  const creativeFilter = [
    ...(isUmsAgencyAdmin || isCreativeDirector || isUmsTeamAdmin
      ? [
          {
            value: searchFilter?.["User"]?.searchMode
              ? handleCreativeFilterData(
                  userData?.filter((data) =>
                    data?.name
                      ?.toLowerCase()
                      ?.includes(searchFilter?.["User"]?.filterValue.toLowerCase())
                  ),
                  "User"
                )
              : handleCreativeFilterData(userData, "User"),
            type: "be",
            key: "name",
            filterType: FILTER_TYPE_USER,
            dataKey: "User",
            revApikey: "userId",
            spendApikey: "user_id",
          },
        ]
      : []),
    ...(isCreativeDirector || isCreativeAgencyAdmin
      ? [
          {
            value: searchFilter?.["Creator"]?.searchMode
              ? handleCreativeFilterData(
                  users?.creator?.filter((data) =>
                    data?.name
                      ?.toLowerCase()
                      .includes(searchFilter?.["Creator"]?.filterValue.toLowerCase())
                  ),
                  "Creator"
                )
              : handleCreativeFilterData(users?.creator, "Creator"),
            type: "be",
            key: "name",
            filterType: FILTER_TYPE_CREATOR,
            dataKey: "Creator",
            revApikey: "creatorId",
            spendApikey: "creator_id",
          },
        ]
      : []),
    ...(parseInt(serviceType) === PRODUCT_ID_LEADGEN
      ? [
          {
            value: searchFilter?.["SubIds"]?.searchMode
              ? handleCreativeFilterData(
                  subIds?.filter((data) =>
                    data?.sub_id
                      ?.toLowerCase()
                      ?.includes(searchFilter?.["SubIds"]?.filterValue.toLowerCase())
                  ),
                  "SubIds"
                )
              : handleCreativeFilterData(subIds, "SubIds"),
            type: "be",
            filterType: FILTER_TYPE_SUBIDS,
            key: "sub_id",
            revApikey: "sub_id",
            spendApikey: "sub_id",
            dataKey: "SubIds",
          },
        ]
      : []),
    ...(parseInt(serviceType) === PRODUCT_ID_VS
      ? [{ value: vsAdvertiser, type: "be", key: "name", dataKey: "Advertiser" }]
      : []),
    {
      value: searchFilter?.["Categories"]?.searchMode
        ? handleCreativeFilterData(
            finalCategories?.filter((data) =>
              data?.name
                ?.toLowerCase()
                ?.includes(searchFilter?.["Categories"]?.filterValue.toLowerCase())
            ),
            "Categories"
          )
        : handleCreativeFilterData(finalCategories, "Categories"),
      type: "fe",
      key: "name",
      dataKey: "Categories",
      filterType: FILTER_TYPE_CATEGORY,
      revApikey: "categoryId",
      spendApikey: "category_id",
    },
  ];

  const finalContent = (
    <div>
      <div>
        {creativeFilter?.map((data, index) => {
          const beFilter =
            appliedFilters?.find((item) => item.filterType === data.filterType)?.id || [];
          return (
            <div
              className="mostly-customized-scrollbar"
              style={{ maxHeight: "400px", overflowY: "scroll" }}
              key={index}
            >
              <Collapse
                activeKey={activeKey}
                collapsible="header"
                accordion
                style={{ border: "none", width: "200px" }}
                onChange={(e) => handleCollapseChange(e, data)}
              >
                <Collapse.Panel
                  className={beFilter?.length > 0 && "collapse-custom_header"}
                  header={data["dataKey"]}
                  key={index}
                >
                  <div
                    className={cx("look-table-search", "d-flex")}
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #404040",
                    }}
                  >
                    <div className="d-flex ">
                      <LookButton
                        tooltipTitle="Click here to search"
                        className="filter-btn"
                        iconType="search"
                        style={{ marginLeft: "-10px" }}
                      />
                      <LookTextInput
                        name={data["dataKey"]}
                        className="searchCreative"
                        value={searchFilter?.[data["dataKey"]]?.filterValue || ""}
                        placeholder="Search "
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue === "") {
                            setFilterCurrentPage((prev) => ({ ...prev, [data["dataKey"]]: 1 }));
                          }
                          setSearchFilter((prev) => ({
                            ...prev,
                            [data["dataKey"]]: {
                              searchMode: inputValue !== "",
                              filterValue: inputValue,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <Checkbox.Group
                    className="mostly-customized-scrollbar"
                    style={{ maxHeight: "160px", overflowY: "scroll" }}
                    value={beFilter}
                  >
                    <LazyList
                      data={data}
                      dataKey={data?.dataKey}
                      setFilterCurrentPage={setFilterCurrentPage}
                      filterCurrentPage={filterCurrentPage}
                      setAppliedFilters={setAppliedFilters}
                    />
                  </Checkbox.Group>
                </Collapse.Panel>
              </Collapse>
            </div>
          );
        })}

        <div className="filter-footer">
          <LookButton
            className="reset"
            onClick={() => {
              setAppliedFilters([]);
              setActiveKey(null);
              setIsFiltered(false);
              handleRevSpendApiCall();
            }}
          >
            Reset
          </LookButton>
          &nbsp; &nbsp;
          <LookButton
            type="primary"
            className="apply"
            onClick={() => {
              handleFilter();
              setSpendReport([]);
              setRevReport([]);
            }}
          >
            Apply
          </LookButton>
        </div>
      </div>
    </div>
  );

  // left options for  filters
  const leftOptions = (
    <div className="d-flex align-items-center">
      <Popover
        placement="bottom"
        overlayClassName="popstyle"
        title={
          <div className="filter-popover">
            <div className="d-flex"> Filter </div>
            <div className="d-flex">
              <ActionIconRender
                iconType="close"
                onClick={() => {
                  setVisibleFilter(false);
                  setIsFiltered(false);
                  setAppliedFilters([]);
                  setActiveKey(null);
                }}
              />
            </div>
          </div>
        }
        content={finalContent}
        open={visibleFilter}
      >
        <Popover
          placement="bottom"
          content={popoverFilterOptions}
          open={initialPopoverVisible}
          onOpenChange={(visible) => setInitialPopoverVisible(visible)}
        >
          <ActionIconRender
            iconType="filter"
            className={isFiltered && "text-primary"}
            onClick={() => setInitialPopoverVisible(true)}
          />
        </Popover>
      </Popover>

      <Popover
        placement="bottom"
        title={
          <div className="filter-popover">
            <div className="d-flex">Sort </div>
            <div className="d-flex">
              <ActionIconRender
                iconType="close"
                onClick={() => {
                  setSortingPopoverVisible(false);
                  setActiveSortingKey(null);
                }}
              />
            </div>
          </div>
        }
        content={popoverSortingOptions}
        open={sortingPopoverVisible}
        onOpenChange={(visible) => setSortingPopoverVisible(visible)}
      >
        <ActionIconRender
          iconType="sort"
          className={appliedSortings?.length > 0 && "text-primary ml-8"}
          onClick={() => setSortingPopoverVisible(true)}
        />
      </Popover>
    </div>
  );

  function handleParentcompliance() {
    setVisibleChildCompliantModal(true);
  }

  function handleChildCompliance() {
    if (isGroup) {
      let resultObj = {};
      for (let key in selectedCreatives) {
        resultObj[key] = selectedCreatives[key]?.map((data) => ({
          ...data,
          complianceStatus: complianceValue?.compliance,
          notes: complianceValue?.complianceDescription,
        }));
      }
      updateAllCreativeCompliance(resultObj);
    } else {
      const apiVars =
        creativeType === CREATIVE_TYPE_IMAGE
          ? {
              imageCompliance: [
                {
                  creativeId: isFbReport
                    ? rowProps?.creative_url_object?.images?.[0]?.urls?.[0]?.id
                    : rowProps?.group_id,
                  complianceStatus: complianceValue?.compliance,
                  notes: complianceValue?.complianceDescription,
                },
              ],
            }
          : {
              videoCompliance: [
                {
                  creativeId: isFbReport
                    ? rowProps?.creative_url_object?.videos?.[0]?.urls?.[0]?.id
                    : rowProps?.group_id,
                  complianceStatus: complianceValue?.compliance,
                  notes: complianceValue?.complianceDescription,
                },
              ],
            };
      updateCreativeCompliance({
        variables: {
          complainceObject: apiVars,
          creativeType: creativeType,
          productId: serviceType,
          categoryIds: complianceValue?.categoryId,
        },
        onCompleted: (e) => {
          // const element = e?.voluum_creativeReporting;
          const element = e?.ctAddCreativeCompliance;
          if (element !== null) {
            getComplianceStatus({
              variables: {
                creativeIds: finalMerge?.map((data) => ({
                  creativeId: handleCreativeIds(data),
                  ...(creativeType === CREATIVE_TYPE && {
                    groupType: isFbReport
                      ? data?.creative_url_object?.groups?.[0]?.groupType || GROUP_TYPE_STATIC
                      : GROUP_TYPE_STATIC,
                  }),
                })),
                creativeType: creativeType === CREATIVE_TYPE ? "GROUP" : creativeType,
              },
            });
            setVisibleChildCompliantModal(false);
            setVisibleParentCompliantModal(false);
            setVisibleCategoryCompliantModal(false);
            setComplianceValue({});
            setSelectedCreatives({});
          }
        },
      });
    }
  }

  // uding promises to call multiple api in case of updating compliance in adgrpoups
  async function updateAllCreativeCompliance(resultObj) {
    let element;
    try {
      const promises = Object.keys(resultObj).map(async (key) => {
        const updatedArray = resultObj[key].map(({ actionType, ...rest }) => rest);
        await updateCreativeCompliance({
          variables: {
            complainceObject: { [key]: updatedArray },
            creativeType: resultObj[key]?.[0]?.actionType,
            productId: serviceType,
            categoryIds: complianceValue?.categoryId,
          },
          onCompleted: (e) => {
            element = e?.ctAddCreativeCompliance;
          },
        });
        return element;
      });

      const results = await Promise.all(promises);
      results.forEach((result, index) => {
        console.log(`Result for key ${Object.keys(resultObj)[index]}:`, result);
        if (result.status === GQL_API_RESPONSE_STATUS_FAILURE) {
          openNotification({
            type: "error",
            message: result.message,
            key: Object.keys(resultObj)[index],
          });
        }
      });
      getComplianceStatus({
        variables: {
          creativeIds: finalMerge?.map((data) => ({
            creativeId: handleCreativeIds(data),
            ...(creativeType === CREATIVE_TYPE && {
              groupType: isFbReport
                ? data?.creative_url_object?.groups?.[0]?.groupType || GROUP_TYPE_STATIC
                : GROUP_TYPE_STATIC,
            }),
          })),
          creativeType: creativeType === CREATIVE_TYPE ? "GROUP" : creativeType,
        },
      });
      setVisibleChildCompliantModal(false);
      setVisibleParentCompliantModal(false);
      setVisibleCategoryCompliantModal(false);
      setComplianceValue({});
      setSelectedCreatives({});
    } catch (error) {
      setComplianceValue({});
      setSelectedCreatives({});
      setVisibleChildCompliantModal(false);
      setVisibleParentCompliantModal(false);
      setVisibleCategoryCompliantModal(false);
      console.error("Error updating creative compliance:", error);
    }
  }
  function handleUpdateUser() {
    updateUser({
      variables: {
        creativeIds: [rowProps?.group_id],
        creativeType: creativeType === CREATIVE_TYPE ? CREATIVE_TYPE_ALL_GROUPS : creativeType,
        userId: updatedUser,
      },
    });
  }
  // function for handling column filter algo
  function handleColumnFilter() {
    const filteredMergeReport = mergedReport?.filter((data) => {
      let isNumberValid = [],
        isRangeValid = [];
      appliedColumnFilters.filterColumns?.forEach((filterData) => {
        const {
          selectedColumn,
          searchType,
          numberFilterType,
          numberFilterValue,
          numberFilterRangeMin,
          numberFilterRangeMax,
        } = filterData;
        if (selectedColumn) {
          if (searchType === "number") {
            if (
              numberFilterType === "<" ||
              numberFilterType === ">" ||
              numberFilterType === "<=" ||
              numberFilterType === ">=" ||
              numberFilterType === "==="
            ) {
              if (numberFilterValue) {
                let valid = false;
                if (numberFilterType === "<") {
                  if (Number(data?.[selectedColumn]) < Number(numberFilterValue)) {
                    valid = true;
                  }
                }
                if (numberFilterType === ">") {
                  if (Number(data?.[selectedColumn]) > Number(numberFilterValue)) {
                    valid = true;
                  }
                }
                if (numberFilterType === "<=") {
                  if (Number(data?.[selectedColumn]) <= Number(numberFilterValue)) {
                    valid = true;
                  }
                }
                if (numberFilterType === ">=") {
                  if (Number(data?.[selectedColumn]) >= Number(numberFilterValue)) {
                    valid = true;
                  }
                }
                if (numberFilterType === "===") {
                  if (Number(data?.[selectedColumn]) === Number(numberFilterValue)) {
                    valid = true;
                  }
                }
                isNumberValid.push(valid);
              }
            }
            if (numberFilterType === "range") {
              if (numberFilterRangeMin && numberFilterRangeMax) {
                let valid = false;
                if (
                  Number(data[selectedColumn]) >= Number(numberFilterRangeMin) &&
                  Number(data[selectedColumn]) <= Number(numberFilterRangeMax)
                ) {
                  valid = true;
                }
                isRangeValid.push(valid);
              }
            }
          }
        }
      });
      return isNumberValid?.every((a) => a) && isRangeValid?.every((a) => a);
    });
    setMergedReport(filteredMergeReport);
    setVisibleColumnFilter(false);
    // setAppliedColumnFilter({ filterColumns: [{}] });
  }

  // tabContents for detailed reporting
  const tabContent = [
    {
      id: "creativeReporting",
      noSpace: true,
      hoverTitle: "Creative Detailed Reporting",
      icon: () => <ActionIconRender iconType="image" />,
      component: (
        <CreativeDeatiledReporting
          onClose={onCloseCreativeDrawer}
          {...rowProps}
          revCategoryVariables={revCategoryVariables}
          revPlatformTiktokVariables={revPlatformTiktokVariables}
          creativeType={creativeType}
          isFbReport={isFbReport}
          serviceType={serviceType}
          revVariables={revVariables}
          spendVariables={spendVariables}
          filters={appliedFilters}
          adsType={adsType}
          isGlobalReport={isGlobalReport}
          setAdsType={setAdsType}
          spendDetailedKey={isFbReport ? "fb360_creativeDetailedReporting" : spendDataKey}
          revDetailedKey={
            parseInt(serviceType) === PRODUCT_ID_SAS || !isFbReport
              ? parseInt(serviceType) === PRODUCT_ID_LEADGEN
                ? "voluum_creativeDetailedReporting"
                : revDataKey
              : "voluum_creativeDetailedReporting"
          }
        />
      ),
    },
    ...(creativeType !== CREATIVE_TYPE
      ? [
          {
            id: "creativeAnalysis",
            noSpace: true,
            hoverTitle: "Creative Analysis",
            icon: () => <ActionIconRender iconType="search" />,
            component: activeParentTab === "creativeAnalysis" && (
              <CreativeAnalysis
                onClose={onCloseCreativeDrawer}
                {...rowProps}
                creativeType={creativeType}
                productId={parseInt(serviceType)}
                isFbReport={isFbReport}
                serviceType={serviceType}
                revVariables={revVariables}
                vsAdvertiser={vsAdvertiser}
                fetchVsContent={fetchVsContent}
                vsAdvertiser_loading={vsAdvertiser_loading}
                spendVariables={spendVariables}
                // categories={categories}
                spendDetailedKey={isFbReport ? "fb360_creativeDetailedReporting" : spendDataKey}
                revDetailedKey={
                  parseInt(serviceType) === PRODUCT_ID_SAS || !isFbReport
                    ? revDataKey
                    : "voluum_creativeDetailedReporting"
                }
              />
            ),
          },
        ]
      : []),
    ...(rowProps?.creative_url_object?.groups?.[0]?.groupType !== GROUP_TYPE_STATIC &&
    creativeType === CREATIVE_TYPE
      ? [
          {
            id: "adDetails",
            noSpace: true,
            hoverTitle: "Ad Details",
            icon: () => <ActionIconRender iconType="flag" />,
            component: activeParentTab === "adDetails" && (
              <AdgroupDetailView {...rowProps} isFbReport={isFbReport} />
            ),
          },
        ]
      : []),
  ];

  const similarTabContent = [
    {
      id: "similarImage",
      noSpace: true,
      hoverTitle: "Similar Creatives",
      icon: () => <ActionIconRender iconType="image" />,
      component: <SimilarImages videoId={rowProps?.group_id} />,
    },
  ];
  const bulkActions = [
    {
      option: "edit",
      title: "Compare Creative",
      onClick: () => {
        if (selectedBulkCreative?.[0]?.categoryId?.length > 1) {
          setVisibleCategoryModal(true);
        } else {
          setVisibleCreativeCompareModal(true);
        }
      },
    },
  ];
  const selectedRowsClear = () => {
    setSelectedBulkCreative([]);
    setVisibleCategoryModal(false);
    setVisibleCreativeCompareModal(false);
  };
  const bulkActionProps = {
    selectedRows: selectedBulkCreative,
    onDismiss: () => {
      selectedRowsClear();
    },
    actionOptions: bulkActions,
  };
  //  All Modal and Drawer props
  const parentComplianceModalProps = {
    visible: visibleParentCompliantModal,
    onCancel: onCloseParentCompliantModal,
    title: "Compliance Details",
    onSubmit: () => handleParentcompliance(),
    okText: "Submit",
    ...(isGroup && { footer: null }),
    // loader: loading_Compliance_Add,
  };
  const childComplianceModalProps = {
    visible: visibleChildCompliantModal,
    onCancel: onCloseChildCompliantModal,
    title: "Compliance Details",
    onSubmit: () => handleChildCompliance(),
    okText: "Submit",
    loader: loading_add_compliance,
  };
  const categoryModalProps = {
    visible: visibleCategoryCompliantModal,
    onCancel: onCloseCategoryCompliantModal,
    title: "Compliance Details",
    onSubmit: () => handleCategoryCompliantModal(rowProps),
    okText: "Submit",
    loader: loading_compliance,
  };
  const userModalProps = {
    visible: visibleUserModal,
    onCancel: onCloseUserModal,
    title: "Update Creator",
    onSubmit: () => handleUpdateUser(),
    okText: "Submit",
    // loader: loading_Compliance_Add,
  };
  const columnFilterModalProps = {
    visible: visibleColumnFilter,
    onCancel: onCloseColumnFilterModal,
    title: "Column Filter",
    onSubmit: () => handleColumnFilter(),
    okText: "Apply",
    // loader: loading_Compliance_Add,
  };
  const creativeFilterModalProps = {
    visible: visibleCreativeCompareModal || visibleCategoryModal,
    onCancel: selectedRowsClear,
    title: "Compare & Analyze",
    footer: null,
    width: 1000,

    // loader: loading_Compliance_Add,
  };
  const drawerProps = {
    // title: `Creative Detailed Reporting`,
    textBreadcrumb: [
      "Creative Detailed Reporting",
      rowProps?.category_name ||
        rowProps?.video_group_id ||
        rowProps?.image_group_id ||
        rowProps?.creative_group_id,
    ],
    visible: visibleDrawer,
    onClose: onCloseCreativeDrawer,
    size: 1200,
  };

  const similarDrawerProps = {
    // title: `Creative Detailed Reporting`,
    textBreadcrumb: [
      "Creative Report",
      rowProps?.category_name ||
        rowProps?.video_group_id ||
        rowProps?.image_group_id ||
        rowProps?.creative_group_id,
    ],
    visible: visibleSimilarDrawer,
    onClose: onCloseSimilarDrawer,
    size: 1200,
  };

  const initialColumns = [
    {
      title: "Creative Group Id ",
      dataIndex: "creative_group_id",
      key: "creative_group_id",
    },
    {
      title: "Creative Group Name ",
      dataIndex: "creative_group_name",
      key: "creative_group_name",
    },
    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
    },
    {
      title: "Revenue",
      dataIndex: "estimate_revenue",
      key: "estimate_revenue",
    },
    {
      title: "Gross Margin",
      dataIndex: "gross_margin",
      key: "gross_margin",
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      key: "roas",
    },
  ];

  return (
    <>
      <LookTableBulkAction {...bulkActionProps} />
      {(visibleCreativeCompareModal || visibleCategoryModal) && (
        <LookModal {...creativeFilterModalProps}>
          {(visibleCreativeCompareModal || visibleCategoryModal) && (
            <CreativeCompareModal
              selectedBulkCreative={selectedBulkCreative}
              creativeType={creativeType}
              productId={serviceType}
              visibleCategoryModal={visibleCategoryModal}
              selectedRowsClear={selectedRowsClear}
              setVisibleCategoryModal={setVisibleCategoryModal}
              setVisibleCreativeCompareModal={setVisibleCreativeCompareModal}
            />
          )}
        </LookModal>
      )}

      <LookModal {...columnFilterModalProps}>
        {visibleColumnFilter && (
          <ColumnFilterModal
            columns={filterColumns}
            appliedColumnFilters={appliedColumnFilters}
            setAppliedColumnFilter={setAppliedColumnFilter}
          />
        )}
      </LookModal>
      <LookModal {...userModalProps}>
        {visibleUserModal && (
          <>
            <p> Are you sure you want to change the creator of this creative?</p>
            <Row>
              <Col>
                <SelectInput
                  name="updateUser"
                  placeholder="Select user"
                  options={users?.creator?.map((data) => ({ ...data, value: data?.id }))}
                  value={updatedUser}
                  onChange={(e) => setUpdateduser(e)}
                />
              </Col>
            </Row>
          </>
        )}
      </LookModal>
      {visibleParentCompliantModal && (
        <LookModal {...parentComplianceModalProps}>
          {visibleParentCompliantModal &&
            (isGroup ? (
              <ComplianceGroupModal
                {...rowProps}
                handleComplianceIcon={handleComplianceIcon}
                isComplianceModal
                isFbReport={isFbReport}
                selectedCreatives={selectedCreatives}
                setSelectedCreatives={setSelectedCreatives}
                setVisibleChildCompliantModal={setVisibleChildCompliantModal}
              />
            ) : (
              <ComplianceModal
                setComplianceValue={setComplianceValue}
                complianceValue={complianceValue}
                {...rowProps}
                isFbReport={isFbReport}
              />
            ))}
        </LookModal>
      )}
      {visibleChildCompliantModal && (
        <LookModal {...childComplianceModalProps}>
          {visibleChildCompliantModal && (
            <ComplianceModal
              setComplianceValue={setComplianceValue}
              complianceValue={complianceValue}
              {...rowProps}
            />
          )}
        </LookModal>
      )}
      {visibleCategoryCompliantModal && (
        <LookModal {...categoryModalProps}>
          {visibleCategoryCompliantModal && (
            <div className="w-200">
              <SelectInput
                label="CategoryId"
                placeholder="Select Category"
                name="categoryId"
                options={rowProps?.categoryId?.map((data) => ({
                  name: data?.name,
                  value: data?.productCategoryId,
                }))}
                multiple
                value={complianceValue?.categoryId}
                onChange={(e) => {
                  setComplianceValue((prev) => ({ ...prev, categoryId: e }));
                }}
              />
            </div>
          )}
        </LookModal>
      )}
      <LookDrawer {...drawerProps}>
        {visibleDrawer && (
          <LookDrawerMainSideBar
            value={activeParentTab}
            onChange={setActiveParentTab}
            tabContent={tabContent}
          />
        )}
      </LookDrawer>
      <LookDrawer {...similarDrawerProps}>
        {visibleSimilarDrawer && (
          <LookDrawerMainSideBar
            value={activeSimilarTab}
            onChange={setActiveSimilarTab}
            tabContent={similarTabContent}
          />
        )}
      </LookDrawer>
      {playModal && (
        <>
          <LookModal
            title="Play Video"
            visible={playModal}
            onCancel={() => setPlayModal(false)}
            footer={false}
          >
            {playModal && <video className="w-100" src={rowProps?.s3Url} controls autoPlay />}
          </LookModal>
        </>
      )}
      <div>
        <LookTable
          persistKey
          rightOptions={creativeTypeFilter}
          leftOptions={isGlobalReport ? <></> : leftOptions}
          isRowSelection={false}
          tableProps={{
            isSearchFilter: true,
            recordData: mergedDataforCSV,
            initialColumns,
          }}
          refetch={isFbReport ? refetch_fb : refetch_tiktok}
          loading={loading_fb || loading_tiktok}
          customeView={customeView}
          isDateRange
          exportCSV
        />
        <div className="p-8 h-initial w-initial look-bottom-pagination ">
          <Pagination
            defaultCurrent={1}
            total={totalCount}
            pageSize={pageSize}
            current={currentPage}
            onChange={(page, size) => handlePageChange(page, size)}
            pageSizeOptions={["10", "20", "50"]}
            showSizeChanger
          />
        </div>
      </div>
      <DebugPre content={[{ isRow: true, contentObjects: [{ creativeFilter }] }]} />
    </>
  );
}

export default CreativeReportView;
