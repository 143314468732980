import ActionIconRender from "components/ActionIconRender";

import { useApiToStoreContext } from "hooks/useApiToStore/context";
import React from "react";
import { useSelector } from "react-redux";
import { MOODBOARD_TYPE_LANGUAGE } from "../../creativesEnum";

function ScriptDetailedTabs(props) {
  const { type, data } = props;

  const dataList = data;
  return (
    <div className="select_script">
      <div>
        <>
          {dataList?.length < 1 || dataList === undefined ? (
            <div className="text-center">
              <ActionIconRender iconType="tablePlaceholder" />
            </div>
          ) : (
            dataList?.map((data) => (
              <div className="scriptsSelectedData">
                <div style={{ width: "70%" }}>
                  {type === MOODBOARD_TYPE_LANGUAGE ? data?.code : data?.name}
                </div>
              </div>
            ))
          )}
        </>
      </div>
    </div>
  );
}

export default ScriptDetailedTabs;
