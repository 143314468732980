import { Form, Image, Tooltip } from "antd";
import FileHandle from "components/CmsComponents/FileHandle";
import LookButton from "components/LookButton";
import LookTextInput from "components/forms/LookTextInput";
import cx from "cx";
import { useEffect, useState } from "react";

export default function CmsBannerListView(props) {
  const {
    id,
    typeId,
    currentIndex,
    editIndex,
    setEditIndex,
    image = "",
    lander_link = "",
    setImage = () => {},
    setLink = () => {},
    isAddView = false,
    remove = () => {},
    onDelete = () => {},
    updateBanner = () => {},
    bannerImages = [],
    setBannerImages = () => {},
    loading_updateCmsBanner = false,
  } = props;

  const [imageFieldLocal, setImageFieldLocal] = useState(image);
  const [landerLinkFieldLocal, setLanderLinkFieldLocal] = useState(lander_link);

  const [isUpdateEditView, setIsUpdateEditView] = useState(false);

  const handleAddFields = (name, value) => {
    setBannerImages((pre) => {
      let temp = JSON.parse(JSON.stringify(pre));
      temp[currentIndex] = { ...temp[currentIndex], [name]: value };
      return temp;
    });
  };

  const setUpdateEditView = () => {
    setIsUpdateEditView(true);
    setEditIndex(currentIndex);
  };
  const cancelUpdateEditView = () => {
    setIsUpdateEditView(false);
    setImageFieldLocal(image);
    setLanderLinkFieldLocal(lander_link);
  };

  useEffect(() => {
    if (!isAddView && isUpdateEditView) {
      if (editIndex !== currentIndex) {
        cancelUpdateEditView();
      }
    }
  }, [editIndex]);

  const onSubmitEdit = () => {
    updateBanner(id, typeId, imageFieldLocal, landerLinkFieldLocal, {
      onCompleted: (e) => {
        const element = e?.CMS_Update_Banner;

        if (element) {
          setBannerImages((pre) => {
            let temp = JSON.parse(JSON.stringify(pre));

            temp[currentIndex] = {
              ...temp[currentIndex],
              image: element?.image,
              lander_link: element?.lander_link,
            };
            return temp;
          });
          cancelUpdateEditView();
        }
      },
    });
  };

  if (isAddView) {
    return (
      <div className="cms-banner-list-view">
        <div className="cms-banner-add-view">
          <>
            <div className="file-view">
              <FileHandle
                chooseNew
                resize={false}
                image={image}
                setImage={(e) => handleAddFields("image", e)}
              />
            </div>
            <Form layout="vertical">
              <LookTextInput
                label="Lander Url"
                placeholder="Enter Lander Url"
                value={lander_link}
                onChange={(e) => handleAddFields("lander_link", e.target.value)}
              />
            </Form>
            <div className="actions">
              <LookButton
                tooltipTitle="Remove"
                iconType="delete"
                onClick={remove}
                type="link"
                danger
              />
            </div>
          </>
        </div>
      </div>
    );
  }

  const updateEditView = () => (
    <div className="cms-banner-update-edit-view">
      <div className="file-view">
        <FileHandle
          chooseNew
          resize={false}
          image={imageFieldLocal}
          setImage={(e) => setImageFieldLocal(e)}
        />
      </div>
      <Form layout="vertical">
        <LookTextInput
          label="Lander Url"
          placeholder="Enter lander Url"
          value={landerLinkFieldLocal}
          onChange={(e) => setLanderLinkFieldLocal(e.target.value)}
        />
      </Form>
      <div className="actions">
        <LookButton
          iconType="check"
          tooltipTitle={
            image === imageFieldLocal && lander_link === landerLinkFieldLocal
              ? "Their is no change"
              : "Save"
          }
          onClick={onSubmitEdit}
          loading={loading_updateCmsBanner}
          disabled={image === imageFieldLocal && lander_link === landerLinkFieldLocal}
          type="link"
        />
        <LookButton
          tooltipTitle="Cancel Edit"
          iconType="close"
          onClick={cancelUpdateEditView}
          type="link"
          danger
        />
      </div>
    </div>
  );
  const updateView = () => (
    <div className="cms-banner-update-view">
      <div className="file-view">
        <Image className="view-image" src={image} preview={false} />
      </div>
      <Form layout="vertical">
        <div className="look-form-input">
          <Form.Item label="Lander Url">
            <Tooltip
              title={
                <>
                  <b>Lander Url: </b>
                  {lander_link}
                </>
              }
            >
              <p className="lander-url">{lander_link}</p>
            </Tooltip>
          </Form.Item>
        </div>
      </Form>
      <div>
        <LookButton iconType="edit" tooltipTitle="Edit" type="link" onClick={setUpdateEditView} />
        <LookButton iconType="delete" tooltipTitle="Delete" type="link" danger onClick={onDelete} />
      </div>
    </div>
  );

  return (
    <div className="cms-banner-list-view">
      <div className={cx({ "d-none": !isUpdateEditView })}>{updateEditView()}</div>
      <div className={cx({ "d-none": isUpdateEditView })}>{updateView()}</div>
      {/* <pre>Local {JSON.stringify({ imageFieldLocal, landerLinkFieldLocal }, null, 2)}</pre> */}
    </div>
  );
}
