import { useState } from "react";
import LookCheckedInput from "components/forms/LookCheckedInput";

const titleMockData = [
  { value: "Give your dog the attention that she always wanted!", checked: true },
  { value: "Give your dog an amazing treat for the good boy he is!" },
  { value: "Give your dog the attention that she always wanted!" },
  { value: "Give your dog an amazing treat for the good boy he is!", checked: true },
  {
    value:
      "Give your dog the attention that she always ve your dog the attention that she always wanted! Give your dog the attention that she always wanted! Giv our dog the attention that she always wanted!",
  },
  { value: "Give your dog an amazing treat for the good boy he is!" },
  { value: "Give your dog the attention that she always wanted!" },
  { value: "Give your dog an amazing treat for the good boy he is!" },
];

export default function LaunchCreativesMediaTitle() {
  const [titleData, setTitleData] = useState(titleMockData);

  return (
    <div style={{ maxWidth: 644 }}>
      {titleData.map((data, index) => (
        <LookCheckedInput {...data} isEditable />
      ))}
    </div>
  );
}
