import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "../MainForm";
import { openNotification, urlYupValidate } from "../../../../../utils";
import { ADD_CMS_BRAND } from "../../../../../shared/gql/gqlSchema/cmsGql";
import { useCustomMutation } from "hooks/apolloClientHooks";

export default function AddForm(props) {
  const { refetch = () => {}, allStores = [] } = props;
  const [image, setImage] = useState("");
  const [isError, setIsError] = useState("");
  const [categories, setCategories] = useState([]);
  const resetFormRef = useRef(() => {});
  const [CMS_CreateBrand, { loading }] = useCustomMutation(ADD_CMS_BRAND, {
    onCompleted(e) {
      refetch();
      resetFormRef.current();
      setCategories([]);
      openNotification({ type: "success", message: "Record Added Successfully" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    const { store_id } = values;
    resetFormRef.current = resetForm;

    CMS_CreateBrand({
      variables: {
        ...values,
        category_id: categories.map((i) => Number(i)),
        store_id: store_id.map((i) => Number(i)),
        image,
      },
    });
    clearError();
  }

  const initialValues = {
    description: "",
    image: "",
    is_private: false,
    is_store: false,
    name: "",
    status: "",
    website: "",
    store_id: [],
  };

  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
    description: yup.string().required().label("Description"),
    website: urlYupValidate.label("Website"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    categoriesProsp: {
      categories,
      setCategories,
    },
    imageProps: {
      image,
      setImage,
    },
  };

  return (
    <>
      <MainFrom {...formProps} allStores={allStores} />
    </>
  );
}
