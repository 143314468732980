import Modal from "antd/lib/modal/Modal";
import cx from "cx";
import { useState } from "react";

export function useLookModal(props = {}) {
  const {
    title = "",
    submitBtnText = "Submit",
    onSubmit = () => {},
    loader = false,
    onOpen = () => {},
    onClose = () => {},
    footer = true,
    width = 520,
    maskClosable = true,
    className = "",
    noContentSpace = false,
    disableClose = false,
  } = props;

  const [visibleModal, setVisibleModal] = useState(false);

  const openModal = (e) => {
    setVisibleModal(true);
    onOpen(e);
  };
  const closeModal = (e) => {
    if (!disableClose) {
      setVisibleModal(false);
      onClose(e);
    }
  };
  const modalProps = {
    visible: visibleModal,
    onSubmit: onSubmit,
    onCancel: closeModal,
    okokText: submitBtnText,
    loader,
    footer,
    title,
    width,
    maskClosable,
    className: cx(className, { noContentSpace }),
  };
  function modal(children) {
    return <Modal {...modalProps}>{children}</Modal>;
  }

  return { modal, visibleModal, openModal, closeModal };
}
export default function LookModal(props) {
  const {
    title,
    visible = false,
    onSubmit,
    disabled = false,
    onCancel = () => {},
    okokText = false,
    loader = false,
    width,
    children,
    noClose = false,
    className = "",
    footer,
    bodyStyle,
    type = "confirm",
    ...rest
  } = props;
  // if (!visible) return null;
  let bodyStyle2 = bodyStyle ? { bodyStyle } : {};
  return (
    <Modal
      {...bodyStyle2}
      className={cx(className, { noClose })}
      width={width}
      title={title}
      visible={visible}
      okText={okokText}
      okButtonProps={{ onClick: onSubmit, disabled: disabled || loader }}
      onCancel={onCancel}
      onCancelProps={{ disabled: loader }}
      footer={footer}
      type={type}
      {...rest}
    >
      {children}
    </Modal>
  );
}
