import { useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";

import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useColumns from "../useColumns";
import { G360_GET_USER_REPORT } from "shared/gql/gqlSchema/g360Gql";

export default function User(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);

  const [reporting, setReporting] = useState([]);
  const { columns } = useColumns();

  const {
    loading: loading_SPEND_ADGROUP,
    error: error_SPEND_ADGROUP,
    refetch: refetch_SPEND_ADGROUP,
  } = useCustomQuery(G360_GET_USER_REPORT, {
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone.value,
      is_detailed: true,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.g_userReporting;
      if (element) {
        const newArray = element?.map(({clicks:googleClicks,...rest})=>({googleClicks,...rest}))
        setReporting(newArray);
      }
    },
  });

  const loading = loading_SPEND_ADGROUP;

  function refetch() {
    refetch_SPEND_ADGROUP();
  }
  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const initialColumns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (value, otherProps) => <LookTableColumnRender title={value?.name} />,
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },

    ...columns,
  ];
  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    finalMerge = reporting;

    finalMerge?.forEach((element, index) => {
      const { cost = 0, revClicks = 0,revenue = 0,googleClicks = 0, impressions = 0, name = "", status = null, conversions = 0 } = element;
      const costUpdate = cost.toFixed(3);
      const CPC = parseFloat(costUpdate) / parseFloat(revClicks);
      const CTR = (parseFloat(revClicks) / parseFloat(impressions)) * 100 || 0;
      const CPM = (parseFloat(costUpdate) / parseFloat(impressions)) * 1000;

      const EPC = parseFloat(revenue) / parseFloat(revClicks);
      const PROFIT = parseFloat(revenue) - parseFloat(costUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(costUpdate)) * 100 || 0;
      const CPR_V = parseFloat(costUpdate) / parseFloat(revClicks);
      const PAYOUT = parseFloat(revenue) / parseFloat(conversions);
      const RPV = parseFloat(revenue) / parseFloat(googleClicks);
      const RPL = parseFloat(revenue) / parseFloat(revClicks);
      const CPV = parseFloat(costUpdate) / parseFloat(googleClicks);
      const CPR = parseFloat(costUpdate) / parseFloat(conversions);

      const AD_CTR = (parseFloat(googleClicks) / parseFloat(impressions)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(googleClicks)) * 100;
      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;


      finalMergeData.push({
        ...element,
        name: name || "",
        key: index,
        revClicks,
        impressions,
        revenue: valueCheck(revenue),
        cost: costUpdate,
        CPC: valueCheck(CPC),
        ROI: valueCheck(ROI),
        CPR_V: valueCheck(CPR_V),
        PROFIT: valueCheck(PROFIT),
        PAYOUT: valueCheck(PAYOUT),
        EPC: valueCheck(EPC),
        RPV: valueCheck(RPV),
        RPL: valueCheck(RPL),
        CPV: valueCheck(CPV),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CR: valueCheck(CR),
        CPC_VIEW: valueCheck(CPC, true),
        CTR: valueCheck(CTR),
        CTR_VIEW: valueCheck(CTR, true),
        CPM: valueCheck(CPM),
        CPM_VIEW: valueCheck(CPM, true),
        CPR: valueCheck(CPR),
        status,
      });
    });
  }

  const finalFilterData = filterData(finalMergeData).filter(
    ({ adgroup_name = "", adgroup_id = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";

      const searchCheck =
        adgroup_name?.toLowerCase()?.includes(searchValue) ||
        adgroup_id?.toLowerCase()?.includes(searchValue);

      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }
      return false;
    }
  );

let total_cost = 0,
    total_clicks = 0,
    total_impressions = 0,
    total_revenue = 0,
    total_conversions = 0,
    total_googleClicks = 0;

  for (const {  
    cost = 0,
    revClicks = 0,
    impressions = 0,
    revenue = 0,
    conversions = 0,
    googleClicks = 0, 
  } of finalMergeData) {
    total_cost += JSON.parse(cost || 0);
    total_clicks += JSON.parse(revClicks || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_revenue += parseFloat(revenue || 0);
    total_conversions += parseFloat(conversions || 0);
    total_googleClicks += parseFloat(googleClicks || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_cost);
  const total_CPC = total_cost / total_clicks;
  const total_CTR = (total_clicks / total_impressions) * 100;
  const total_CPM = (parseFloat(total_cost) / parseFloat(total_impressions)) * 1000;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_clicks);
  const total_CPR_V = parseFloat(total_cost) / parseFloat(total_conversions);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_cost)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_clicks)) * 100;
  const total_CPV = parseFloat(total_cost) / parseFloat(total_clicks);
  const total_AD_CTR = (parseFloat(total_googleClicks) / parseFloat(total_impressions)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_clicks) / parseFloat(total_googleClicks)) * 100;

  const summaryData = {
   cost: valueCheck(total_cost, true),
    PAYOUT: valueCheck(total_PAYOUT, true),
    CPC: valueCheck(total_CPC, true),
    EPC: valueCheck(total_EPC, true),
    CTR: valueCheck(total_CTR, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    CR: valueCheck(total_CR, true),
    revClicks: valueCheck(total_clicks, true),
    CPR_V: valueCheck(total_CPR_V, true),
    CPV: valueCheck(total_CPV, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    revenue: valueCheck(total_revenue, true),
    CPM: valueCheck(total_CPM, true),
  };

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },
    scrollHeight: "calc(100vh - 208px)",
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ g360Reporting: reporting }, { finalFilterData }],
    },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
