import { gql } from "@apollo/client";

export const SC_GET_COMPANY_SCORE = gql`
  query SC_getCompanyScore($type: period, $date: Date, $from: Date, $to: Date) {
    SC_getCompanyScore(type: $type, date: $date, from: $from, to: $to) {
      agencyId
      date
      profit_target
      actual_revenu
      spend
      type
      userId
      outcome
      curruntRunRate
      revenu_target
      requiredRunRate
      isLock
      TillProfit_target
    }
  }
`;

export const SC_GET_COMPANY_WEEKLY_SCORE = gql`
  query SC_getCompanyWeekScore($date: Date!) {
    SC_getCompanyWeekScore(date: $date) {
      date
      profit_target
      actual_revenu
      spend
      type
      curruntRunRate
      revenu_target
      requiredRunRate
      isLock
      TillProfit_target
    }
  }
`;

const DEPARTMENT_SCORE_RESPONSE = gql`
  fragment departmentScore on departmentScore {
    actual_revenu
    agency_id
    date
    department_id
    id
    name
    note
    profit_target
    revenu_target
    spend
    type
    types
    user_id
    value
    weeknumber
    TillProfit_target
    lobId
  }
`;

export const SC_GET_DEPARTMENT_WEEK_SCORE = gql`
  ${DEPARTMENT_SCORE_RESPONSE}
  query SC_getdepartmentweekScore($date: Date, $type: period, $weeknumber: String, $lob_id: ID) {
    SC_getdepartmentweekScore(date: $date, type: $type, weeknumber: $weeknumber, lob_id: $lob_id) {
      ...departmentScore
    }
  }
`;

export const SC_GET_LOB_WEEK_SCORE = gql`
  ${DEPARTMENT_SCORE_RESPONSE}
  query SC_getlobweekScore($date: Date, $type: period, $weeknumber: String) {
    SC_getlobweekScore(date: $date, type: $type, weeknumber: $weeknumber) {
      ...departmentScore
    }
  }
`;

export const SC_GET_USER_WEEK_SCORE = gql`
  ${DEPARTMENT_SCORE_RESPONSE}
  query SC_getuserweekScore(
    $date: Date
    $type: period
    $weeknumber: String
    $departmentId: ID
    $lob_id: ID
  ) {
    SC_getuserweekScore(
      date: $date
      type: $type
      weeknumber: $weeknumber
      departmentId: $departmentId
      lob_id: $lob_id
    ) {
      ...departmentScore
    }
  }
`;

const USER_SCORE_RESPONSE = gql`
  fragment userScore on userScore {
    actual_revenu
    agency_id
    date
    id
    name
    note
    outcome
    profit_target
    revenu_target
    spend
    type
    types
    user_id
    username
    value
    workspace_id
    curruntRunRate
    requiredRunRate
  }
`;

export const SC_GET_USER_SCORE = gql`
  ${USER_SCORE_RESPONSE}
  query SC_getUserscore($type: period, $date: Date!, $departmentId: ID, $userId: ID) {
    SC_getUserscore(type: $type, date: $date, departmentId: $departmentId, userId: $userId) {
      ...userScore
    }
  }
`;

export const SC_GET_USER_DASHBOARD_SCORE = gql`
  ${USER_SCORE_RESPONSE}
  query SC_getUserDashboardScore($type: period, $date: Date!, $user_id: ID, $departmentId: ID) {
    SC_getUserDashboardScore(
      type: $type
      date: $date
      user_id: $user_id
      departmentId: $departmentId
    ) {
      ...userScore
      TillProfit_target
    }
  }
`;

export const SC_GET_USER_PROMISE_REQUEST = gql`
  query SC_getUserRequest($date: Date) {
    SC_getUserRequest(date: $date) {
      name
      user_id
      profit_target
      request_target
      date
      actual_revenu
      spend
    }
  }
`;

export const SC_UPDATE_USER_PROMISE_TARGET = gql`
  mutation SC_updateUserPromise($date: Date, $note: String, $profit_target: Float, $userId: ID) {
    SC_updateUserPromise(date: $date, note: $note, profit_target: $profit_target, userId: $userId)
  }
`;

export const SC_GET_ALL_DEPARTMENT = gql`
  query SC_getAllDepartment($date: Date) {
    SC_getAllDepartment(date: $date) {
      actual_revenu
      company_Id
      id
      name
      profit_target
      revenu_target
      workspaceId
      spend
      users {
        user_id
        name
      }
    }
  }
`;

export const SC_GET_ALL_DEPARTMENT_DROPDOWN = gql`
  query SC_getAllDepartment {
    SC_getAllDepartment {
      id
      name
    }
  }
`;

export const SC_GET_ALL_DEPARTMENT_FOR_UNICORN_DROPDOWN = gql`
  query SC_getAllDepartmentForUnicorn {
    SC_getAllDepartmentForUnicorn {
      id
      name
    }
  }
`;

export const SC_GET_ASSIGNED_DEPARTMENTS_DROPDOWN = gql`
  query SC_get_assigned_departments {
    SC_get_assigned_departments {
      id
      name
    }
  }
`;

export const SC_GET_ALL_DEPARTMENT_BY_LOB = gql`
  query SC_getAllDepartmentsByLob($date: Date, $lob_id: ID) {
    SC_getAllDepartmentsByLob(date: $date, lob_id: $lob_id) {
      actual_profit_target
      actual_revenu
      company_Id
      curruntRunRate
      date
      id
      isLock
      lob_id
      name
      profit_target
      requiredRunRate
      revenu_target
      spend
      total_profit_target
    }
  }
`;

export const SC_GET_LOB_DAILY_SCORE = gql`
  query SC_get_lob_daily_score($date: Date!, $id: ID!) {
    SC_get_lob_daily_score(date: $date, id: $id) {
      actual_profit_target
      actual_revenu
      date
      id
      name
      profit_target
      revenu_target
      spend
      total_profit_target
    }
  }
`;

export const SC_GET_LOB_WEEKLY_SCORE_BY_ID = gql`
  query SC_getLobWeeklyScoreByLobId($date: Date!, $lobId: ID!) {
    SC_getLobWeeklyScoreByLobId(date: $date, lobId: $lobId) {
      actual_profit_target
      actual_revenu
      date
      id
      name
      profit_target
      revenu_target
      spend
      total_profit_target
    }
  }
`;

export const SC_GET_DEPARTMENT_DAILY_SCORE = gql`
  query SC_get_Department_daily_Score($date: Date!, $id: ID!) {
    SC_get_Department_daily_Score(date: $date, id: $id) {
      actual_revenu
      date
      department_id
      id
      name
      profit_target
      revenu_target
      spend
      type
      types
      user_id
      value
    }
  }
`;

export const SC_GET_DEPARTMENT_WEEKLY_SCORE_BY_ID = gql`
  query SC_getDepartmentWeeklyScoreByDepartmentId($date: Date!, $departmentId: ID!) {
    SC_getDepartmentWeeklyScoreByDepartmentId(date: $date, departmentId: $departmentId) {
      actual_revenu
      date
      department_id
      id
      name
      profit_target
      revenu_target
      spend
      type
      types
      user_id
      value
    }
  }
`;

export const SC_GET_DEPARTMENT_BY_LOB_DROPDOWN = gql`
  query SC_getAllDepartmentsByLob($lob_id: ID) {
    SC_getAllDepartmentsByLob(lob_id: $lob_id) {
      id
      name
    }
  }
`;

export const SC_GET_WEEK_DEPARTMENT_PERCENTAGE = gql`
  query SC_get_week_department_percentage($date: Date!, $id: ID!) {
    SC_get_week_department_percentage(date: $date, id: $id) {
      percentage
      week
      isLock
    }
  }
`;

export const SC_GET_ALL_LINE_OF_BUSINESS = gql`
  query SC_getAllLineofBusiness($date: Date) {
    SC_getAllLineofBusiness(date: $date) {
      actual_profit_target
      actual_revenu
      company_Id
      curruntRunRate
      date
      id
      isLock
      lob_id
      name
      profit_target
      requiredRunRate
      revenu_target
      spend
      total_profit_target
    }
  }
`;

export const SC_GET_DEPARTMENT_BY_USER = gql`
  query SC_getDepartmentByUser {
    SC_getDepartmentByUser {
      id
      name
    }
  }
`;

export const SC_GET_DEPARTMENT_FOR_COMPANY_ADMIN = gql`
  query SC_getDepartmentforCompanyAdmin(
    $from: Date
    $month: Date
    $to: Date
    $type: period
    $lob_id: ID
  ) {
    SC_getDepartmentforCompanyAdmin(
      from: $from
      month: $month
      to: $to
      type: $type
      lob_id: $lob_id
    ) {
      actual_revenu
      actual_profit_target
      id
      name
      profit_target
      revenu_target
      spend
      lob_id
      lineof_business {
        id
        name
      }
      curruntRunRate
      requiredRunRate
      TillProfit_target
      is_active
      channelName
    }
  }
`;

export const SC_GET_USER_FOR_COMPANY_ADMIN = gql`
  query SC_getUserforCompanyAdmin(
    $from: Date
    $month: Date
    $to: Date
    $type: period
    $departmentId: ID
    $lob_id: ID
  ) {
    SC_getUserforCompanyAdmin(
      from: $from
      month: $month
      to: $to
      type: $type
      departmentId: $departmentId
      lob_id: $lob_id
    ) {
      actual_profit_target
      actual_revenu
      curruntRunRate
      date
      departmentId
      name
      is_unicorn
      profit_target
      requiredRunRate
      revenu_target
      spend
      user_id
      TillProfit_target
      lastupdateDate
      lobId
      is_active
      userdepartmentId
      lob {
        id
        name
      }
      department {
        id
        name
      }
    }
  }
`;

export const SC_GET_DEPARTMENT_FOR_DEPARTMENT_ADMIN = gql`
  query SC_getDepartmentforDepartmentAdmin($from: Date, $month: Date, $to: Date, $type: period) {
    SC_getDepartmentforDepartmentAdmin(from: $from, month: $month, to: $to, type: $type) {
      actual_revenu
      actual_profit_target
      company_Id
      id
      name
      profit_target
      revenu_target
      curruntRunRate
      requiredRunRate
      spend
      users {
        user_id
        name
      }
      workspaceId
      TillProfit_target
    }
  }
`;

export const SC_GET_USER_FOR_DEPARTMENT_ADMIN = gql`
  query SC_getUserforDepartmentAdmin(
    $from: Date
    $month: Date
    $to: Date
    $type: period
    $departmentId: ID
  ) {
    SC_getUserforDepartmentAdmin(
      from: $from
      month: $month
      to: $to
      type: $type
      departmentId: $departmentId
    ) {
      actual_profit_target
      actual_revenu
      curruntRunRate
      date
      departmentId
      name
      profit_target
      requiredRunRate
      revenu_target
      spend
      user_id
      lobId
      TillProfit_target
      lastupdateDate
      is_active
    }
  }
`;

export const SC_CREATE_USER_SCORE = gql`
  mutation SC_createuserscore($data: [userdata]) {
    SC_createuserscore(data: $data)
  }
`;

export const SC_LOCK_PROMISE = gql`
  mutation SC_lock_promise($date: Date, $user_id: ID, $departmentId: ID) {
    SC_lock_promise(date: $date, user_id: $user_id, departmentId: $departmentId)
  }
`;

export const SC_UPDATE_USER_SCORE = gql`
  mutation SC_updateuserscore(
    $date: Date
    $note: String
    $revenue: Float!
    $spend: Float!
    $slacknote: String
    $user_id: ID
    $departmentId: ID
  ) {
    SC_updateuserscore(
      date: $date
      note: $note
      revenue: $revenue
      spend: $spend
      slacknote: $slacknote
      user_id: $user_id
      departmentId: $departmentId
    )
  }
`;

export const SC_BULK_UPDATE_DAY_START_USER_SCORE = gql`
  mutation SC_bulkUpdateDayStartUserScore(
    $bulkUpdates: [userScoreUpdateInput]
    $departmentId: ID!
    $user_id: ID
  ) {
    SC_bulkUpdateDayStartUserScore(
      bulkUpdates: $bulkUpdates
      departmentId: $departmentId
      user_id: $user_id
    )
  }
`;

export const SC_UPDATE_DAY_START_USER_SCORE = gql`
  mutation SC_updateDayStartuserscore(
    $date: Date
    $note: String
    $profit_target: Float
    $user_id: ID
    $departmentId: ID
  ) {
    SC_updateDayStartuserscore(
      date: $date
      note: $note
      profit_target: $profit_target
      user_id: $user_id
      departmentId: $departmentId
    )
  }
`;

export const SC_CREATE_DEPARTMENT_SCORE = gql`
  mutation SC_createdepartmentscore($data: [deparmentdata]) {
    SC_createdepartmentscore(data: $data)
  }
`;

export const SC_CREATE_PROMISE = gql`
  mutation SC_createPromise($data: [usersdata]) {
    SC_createPromise(data: $data)
  }
`;

export const SC_GET_DEPARTMENT_STATUS = gql`
  query SC_getStatus_departmentScore($date: Date, $department_id: ID) {
    SC_getStatus_departmentScore(date: $date, department_id: $department_id) {
      revenu_target
      actual_revenu
      spend
    }
  }
`;

export const SC_GET_USER_SCORE_STATUS = gql`
  query SC_getStatus_userScore($date: Date, $department_id: ID) {
    SC_getStatus_userScore(date: $date, department_id: $department_id) {
      revenu_target
      actual_revenu
      spend
    }
  }
`;

export const SC_GET_USERS_BY_DEPARTMENT = gql`
  query SC_getUsersByDepartment($id: ID, $date: Date) {
    SC_getUsersByDepartment(id: $id, date: $date) {
      departmentId
      name
      user_id
      workspaceId
      profit_target
      revenu_target
      isLock
    }
  }
`;

export const SC_GET_USERS_BY_DEPARTMENT_DROPDOWN = gql`
  query SC_getUsersByDepartment($id: ID) {
    SC_getUsersByDepartment(id: $id) {
      name
      id: user_id
    }
  }
`;

export const SC_CREATE_COMPANY = gql`
  mutation SC_createCompany($name: String!) {
    SC_createCompany(name: $name)
  }
`;

export const SC_GET_ALL_COMPANY = gql`
  query SC_getAllCompany {
    SC_getAllCompany {
      id
      name
    }
  }
`;

export const SC_GET_ALL_LOB_DROPDOWN = gql`
  query SC_getLineOfBusiness {
    SC_getLineOfBusiness {
      id
      name
    }
  }
`;

export const SC_GET_SLACK_CHANNEL_LIST = gql`
  query RE_getSlackChannelList {
    RE_getSlackChannelList {
      id
      name
    }
  }
`;

export const SC_CREATE_DEPARTMENT = gql`
  mutation SC_createDepartment(
    $name: String!
    $description: String
    $lineofbussiness_id: ID!
    $is_active: Boolean
  ) {
    SC_createDepartment(
      name: $name
      description: $description
      lineofbussiness_id: $lineofbussiness_id
      is_active: $is_active
    )
  }
`;

export const SC_UPDATE_DEPARTMENT = gql`
  mutation SC_updateDepartment(
    $id: ID!
    $name: String!
    $description: String
    $lineofbussiness_id: ID!
    $is_active: Boolean
    $channelName: String
  ) {
    SC_updateDepartment(
      id: $id
      name: $name
      description: $description
      lineofbussiness_id: $lineofbussiness_id
      is_active: $is_active
      channelName: $channelName
    )
  }
`;

export const SC_CREATE_USER = gql`
  mutation SC_createUser($users: [userinput]) {
    SC_createUser(users: $users)
  }
`;

export const SC_LOCK_MONTHLY_PROMISE = gql`
  mutation SC_lock_monthly_promise(
    $date: Date
    $period: period
    $type: type
    $promise: Float
    $asset_ids: [ID]
    $departmentId: ID
  ) {
    SC_lock_monthly_promise(
      date: $date
      period: $period
      type: $type
      promise: $promise
      asset_ids: $asset_ids
      departmentId: $departmentId
    )
  }
`;

export const SC_UNLOCK_MONTHLY_PROMISE = gql`
  mutation SC_unlock_monthly_promise(
    $date: Date
    $period: period
    $type: type
    $promise: Float
    $asset_ids: [ID]
    $departmentId: ID
  ) {
    SC_unlock_monthly_promise(
      date: $date
      period: $period
      type: $type
      promise: $promise
      asset_ids: $asset_ids
      departmentId: $departmentId
    )
  }
`;

export const SC_CREATE_LINE_OF_BUSINESS = gql`
  mutation SC_createLineBussiness($description: String, $name: String!, $working_days: Boolean) {
    SC_createLineBussiness(description: $description, name: $name, working_days: $working_days)
  }
`;

export const SC_GET_DEPARTMENT_SCORE_GRAPH = gql`
  query SC_getgraph_departmentScore($date: Date, $department_id: ID, $isprofit: Boolean) {
    SC_getgraph_departmentScore(date: $date, department_id: $department_id, isprofit: $isprofit) {
      types
      date
      value
    }
  }
`;

export const SC_GET_USER_SCORE_GRAPH = gql`
  query SC_getgraph_userScore($date: Date, $department_id: ID, $isbottom: Boolean) {
    SC_getgraph_userScore(date: $date, department_id: $department_id, isbottom: $isbottom) {
      types
      username
      value
    }
  }
`;

export const SC_GET_LOB_BAR_GRAPH = gql`
  query SC_getLobBargraphScore($date: Date!, $type: period!) {
    SC_getLobBargraphScore(date: $date, type: $type) {
      name
      types
      value
    }
  }
`;

export const SC_GET_DEPARTMENT_BAR_GRAPH = gql`
  query SC_getDepartmentBargraphScore($date: Date!, $type: period!) {
    SC_getDepartmentBargraphScore(date: $date, type: $type) {
      name
      types
      value
    }
  }
`;

export const SC_GET_COMPANY_LINE_GRAPH = gql`
  query SC_getCompanyLineGraphScore($date: Date!, $type: period!) {
    SC_getCompanyLineGraphScore(date: $date, type: $type) {
      date
      types
      value
    }
  }
`;

export const SC_GET_DEPARTMENT_LINE_GRAPH = gql`
  query SC_getDepartmentLineGraphScore($date: Date!, $departmentId: ID!, $type: period!) {
    SC_getDepartmentLineGraphScore(date: $date, departmentId: $departmentId, type: $type) {
      date
      types
      value
    }
  }
`;

export const SC_GET_USER_LINE_GRAPH = gql`
  query SC_getuserLineGraphScore($date: Date!, $departmentId: ID!, $type: period!) {
    SC_getuserLineGraphScore(date: $date, departmentId: $departmentId, type: $type) {
      date
      types
      value
    }
  }
`;

export const SC_GET_LOB_PIE_GRAPH = gql`
  query SC_getLobPieGraphScore($date: Date!, $lobId: ID!, $type: period!) {
    SC_getLobPieGraphScore(date: $date, lobId: $lobId, type: $type) {
      type
      value
    }
  }
`;

export const SC_GET_USER_PIE_GRAPH = gql`
  query SC_getUserPieGraphScore($date: Date!, $userId: ID!, $type: period!, $departmentId: ID!) {
    SC_getUserPieGraphScore(
      date: $date
      userId: $userId
      type: $type
      departmentId: $departmentId
    ) {
      type
      value
    }
  }
`;

export const SC_GET_OVERVIEW_PROMISE_TARGET = gql`
  query SC_getOverViewPromiseTarget($date: Date!, $departmentId: ID, $type: period!) {
    SC_getOverViewPromiseTarget(date: $date, departmentId: $departmentId, type: $type)
  }
`;

export const SC_GET_OVERVIEW_SPEND = gql`
  query SC_getOverViewSpend($date: Date!, $departmentId: ID, $type: period!) {
    SC_getOverViewSpend(date: $date, departmentId: $departmentId, type: $type)
  }
`;

export const SC_GET_OVERVIEW_REVENUE = gql`
  query SC_getOverViewRevenue($date: Date!, $departmentId: ID, $type: period!) {
    SC_getOverViewRevenue(date: $date, departmentId: $departmentId, type: $type)
  }
`;

export const SC_GET_OVERVIEW_GROSS_MARGIN = gql`
  query SC_getOverViewGrossMargin($date: Date!, $departmentId: ID, $type: period!) {
    SC_getOverViewGrossMargin(date: $date, departmentId: $departmentId, type: $type)
  }
`;

export const SC_GET_OVERVIEW_RUNRATE = gql`
  query SC_getOverViewRunRate($date: Date!, $departmentId: ID, $type: period!) {
    SC_getOverViewRunRate(date: $date, departmentId: $departmentId, type: $type)
  }
`;

export const SC_GET_DEPARTMENT_TOP_USER_GRAPH = gql`
  query SC_getDepartmentTopUsergraphScore($date: Date!, $departmentId: ID!, $type: period!) {
    SC_getDepartmentTopUsergraphScore(date: $date, departmentId: $departmentId, type: $type) {
      name
      types
      value
    }
  }
`;

export const SC_GET_DEPARTMENT_PIE_GRAPH = gql`
  query SC_getDepartmentPieGraphScore($date: Date!, $departmentId: ID!, $type: period!) {
    SC_getDepartmentPieGraphScore(date: $date, departmentId: $departmentId, type: $type) {
      type
      value
    }
  }
`;

export const SC_GET_USER_PROGRESS_GRAPH = gql`
  query SC_getgraph_userLevelScore(
    $type: period
    $date: Date!
    $weekNumber: String
    $isprofit: Boolean
    $user_id: ID
    $departmentId: ID
  ) {
    SC_getgraph_userLevelScore(
      type: $type
      date: $date
      weekNumber: $weekNumber
      isprofit: $isprofit
      user_id: $user_id
      departmentId: $departmentId
    ) {
      types
      username
      value
      date
    }
  }
`;

export const SC_GET_OVERVIEW_SCORE = gql`
  query SC_getoverview_Score($date: Date!, $type: period, $user_id: ID, $departmentId: ID) {
    SC_getoverview_Score(date: $date, type: $type, user_id: $user_id, departmentId: $departmentId) {
      actual_revenu
      curruntRunRate
      profit_target
      requiredRunRate
      revenu_target
      spend
      isLock
      note
      isTargetProvided
      isSetTarget
      isDrafted
      Total_Profit
      totalProfitPercent
      slacknote
    }
  }
`;

export const SC_IS_DEPARTMENT_ADMIN = gql`
  query SC_isDepartmentAdmin {
    SC_isDepartmentAdmin
  }
`;

export const SC_IS_VIEW_MILESTONES = gql`
  query SC_isViewMilestones {
    SC_isViewMilestones
  }
`;

export const SC_GET_LINE_OF_BUSINESS_SCORE = gql`
  query SC_getLine_of_bussiness_score($from: Date, $month: Date, $to: Date, $type: period) {
    SC_getLine_of_bussiness_score(from: $from, month: $month, to: $to, type: $type) {
      actual_profit_target
      actual_revenu
      company_Id
      curruntRunRate
      date
      id
      name
      profit_target
      requiredRunRate
      revenu_target
      spend
      total_profit_target
      TillProfit_target
    }
  }
`;

const VERIFYING_MILESTONE_RESPONSE = gql`
  fragment v_milestoneData on v_milestoneData {
    by_when
    name
    id
    status
    year
    quarter
    description
    task_id
    team_id
    team
    actual_milestone
    vlob {
      id
      name
    }
    vDepartment {
      id
      name
    }
    strategic {
      id
      name
    }
  }
`;

export const SC_GET_ALL_VERIFYING_MILESTONE = gql`
  ${VERIFYING_MILESTONE_RESPONSE}
  query SC_get_All_v_milestone(
    $department_id: ID
    $lob_id: ID
    $quarter: String
    $year: Date
    $status: [status]
    $team_id: ID
  ) {
    SC_get_All_v_milestone(
      department_id: $department_id
      lob_id: $lob_id
      quarter: $quarter
      year: $year
      status: $status
      team_id: $team_id
    ) {
      ...v_milestoneData
    }
  }
`;

export const SC_GET_VERIFYING_MILESTONE_BY_STRATEGIC_OUTCOME = gql`
  ${VERIFYING_MILESTONE_RESPONSE}
  query SC_get_v_milestone_by_strategic($strategic_id: ID, $quarter: String, $year: Date) {
    SC_get_v_milestone_by_strategic(strategic_id: $strategic_id, quarter: $quarter, year: $year) {
      ...v_milestoneData
    }
  }
`;

export const SC_GET_ALL_VERIFYING_MILESTONE_DROPDOWN = gql`
  query SC_get_All_v_milestone {
    SC_get_All_v_milestone {
      name
      id
      team_id
    }
  }
`;

export const SC_GET_ALL_USERS = gql`
  query SC_getAlluser {
    SC_getAlluser {
      name
      id
      user_id
    }
  }
`;

export const SC_CREATE_VERIFYING_MILESTONE = gql`
  mutation SC_create_v_milestone(
    $by_when: Date
    $department_id: ID!
    $lob_id: ID!
    $name: String!
    $status: status
    $description: String
    $team_id: ID!
    $quarter: String
    $year: Date
    $strategic_id: ID
  ) {
    SC_create_v_milestone(
      by_when: $by_when
      department_id: $department_id
      lob_id: $lob_id
      name: $name
      status: $status
      description: $description
      team_id: $team_id
      quarter: $quarter
      year: $year
      strategic_id: $strategic_id
    ) {
      message
      status
    }
  }
`;

export const SC_UPDATE_VERIFYING_MILESTONE = gql`
  mutation SC_update_v_milestone(
    $id: ID
    $by_when: Date
    $department_id: ID
    $lob_id: ID
    $name: String
    $status: status
    $description: String
    $quarter: String
    $year: Date
    $strategic_id: ID
    $actual_milestone: String
  ) {
    SC_update_v_milestone(
      id: $id
      by_when: $by_when
      department_id: $department_id
      lob_id: $lob_id
      name: $name
      status: $status
      description: $description
      quarter: $quarter
      year: $year
      strategic_id: $strategic_id
      actual_milestone: $actual_milestone
    ) {
      data {
        by_when
        department_id
        description
        id
        lob_id
        name
        quarter
        status
        year
      }
      message
      status
    }
  }
`;

const CAUSATIVE_MILESTONE_RESPONSE = gql`
  fragment c_milestoneData on c_milestoneData {
    accountable_department_id
    by_when
    description
    breakdown_counter
    id
    measured_by
    name
    quarter
    year
    status
    task_id
    team_id
    team
    totalPromise
    completePromise
    vcmilestone {
      id
      name
      vDepartment {
        id
        name
      }
      vlob {
        id
        name
      }
    }
  }
`;

export const SC_GET_ALL_CAUSATIVE_MILESTONE = gql`
  ${CAUSATIVE_MILESTONE_RESPONSE}
  query SC_get_All_c_milestone(
    $department_id: ID
    $lob_id: ID
    $quarter: String
    $year: Date
    $status: [status]
    $team_id: ID
  ) {
    SC_get_All_c_milestone(
      department_id: $department_id
      lob_id: $lob_id
      quarter: $quarter
      year: $year
      status: $status
      team_id: $team_id
    ) {
      ...c_milestoneData
    }
  }
`;

export const SC_C_MILESTONE_BY_V_MILESTONE_DROPDOWN = gql`
  query SC_get_c_milestone_by_v_milestone($vMilestone_id: ID!) {
    SC_get_c_milestone_by_v_milestone(vMilestone_id: $vMilestone_id) {
      id
      name
      team_id
    }
  }
`;

export const SC_C_MILESTONE_BY_V_MILESTONE = gql`
  ${CAUSATIVE_MILESTONE_RESPONSE}
  query SC_get_c_milestone_by_v_milestone($vMilestone_id: ID!, $department_id: ID) {
    SC_get_c_milestone_by_v_milestone(
      vMilestone_id: $vMilestone_id
      department_id: $department_id
    ) {
      ...c_milestoneData
    }
  }
`;

export const SC_GET_ALL_CAUSATIVE_MILESTONE_DROPDOWN = gql`
  query SC_get_All_c_milestone {
    SC_get_All_c_milestone {
      id
      name
    }
  }
`;

export const SC_CREATE_CAUSATIVE_MILESTONE = gql`
  mutation SC_create_c_milestone(
    $accountable_department_id: ID
    $by_when: Date
    $description: String
    $measured_by: String
    $name: String!
    $quarter: String
    $v_milestone_id: ID!
    $year: Date
    $status: status
    $team_id: ID!
  ) {
    SC_create_c_milestone(
      accountable_department_id: $accountable_department_id
      by_when: $by_when
      description: $description
      measured_by: $measured_by
      name: $name
      quarter: $quarter
      v_milestone_id: $v_milestone_id
      year: $year
      status: $status
      team_id: $team_id
    ) {
      message
      status
    }
  }
`;

export const SC_UPDATE_CAUSATIVE_MILESTONE = gql`
  mutation SC_update_c_milestone(
    $accountable_department_id: ID
    $by_when: Date
    $description: String
    $measured_by: String
    $name: String
    $quarter: String
    $v_milestone_id: ID
    $year: Date
    $status: status
    $id: ID
    $breakdownCounter: Int!
  ) {
    SC_update_c_milestone(
      accountable_department_id: $accountable_department_id
      by_when: $by_when
      description: $description
      measured_by: $measured_by
      name: $name
      quarter: $quarter
      v_milestone_id: $v_milestone_id
      year: $year
      status: $status
      id: $id
      breakdownCounter: $breakdownCounter
    ) {
      data {
        accountable_department_id
        breakdown_counter
        by_when
        description
        id
        measured_by
        name
        quarter
        status
        v_milestone_id
        year
      }
      message
      status
    }
  }
`;

export const SC_CREATE_PROMISED_ACTION = gql`
  mutation SC_create_promised_action(
    $action: String!
    $by_when: Date
    $by_whom: ID
    $c_milestone_id: ID!
    $description: String
    $status: status
    $to_whom: ID
    $v_milestone_id: ID!
    $team_id: ID!
    $dependent_due_date: Date
    $dependent_promise_action_id: ID
    $is_dependent: Boolean
    $year: Date
    $quarter: String
    $is_recurrence: Boolean
    $recurrence_type: recurrence_type
  ) {
    SC_create_promised_action(
      action: $action
      by_when: $by_when
      by_whom: $by_whom
      c_milestone_id: $c_milestone_id
      description: $description
      status: $status
      to_whom: $to_whom
      v_milestone_id: $v_milestone_id
      team_id: $team_id
      dependent_due_date: $dependent_due_date
      dependent_promise_action_id: $dependent_promise_action_id
      is_dependent: $is_dependent
      year: $year
      quarter: $quarter
      is_recurrence: $is_recurrence
      recurrence_type: $recurrence_type
    ) {
      message
      status
    }
  }
`;

export const SC_CREATE_PROMISED_SUBTASK = gql`
  mutation SC_create_promised_subtask(
    $action: String!
    $by_when: Date
    $by_whom: ID
    $description: String
    $status: status
    $to_whom: ID
    $team_id: ID!
    $dependent_due_date: Date
    $dependent_promise_subtask_id: ID
    $is_dependent: Boolean
    $year: Date
    $quarter: String
    $promise_action_id: ID!
    $is_recurrence: Boolean
    $recurrence_type: recurrence_type
  ) {
    SC_create_promised_subtask(
      action: $action
      by_when: $by_when
      by_whom: $by_whom
      description: $description
      status: $status
      to_whom: $to_whom
      team_id: $team_id
      dependent_due_date: $dependent_due_date
      dependent_promise_subtask_id: $dependent_promise_subtask_id
      is_dependent: $is_dependent
      year: $year
      quarter: $quarter
      promise_action_id: $promise_action_id
      is_recurrence: $is_recurrence
      recurrence_type: $recurrence_type
    ) {
      message
      status
    }
  }
`;

export const SC_UPDATE_PROMISED_SUBTASK = gql`
  mutation SC_update_promise_subtask(
    $action: String!
    $by_when: Date
    $by_whom: ID
    $breakdownCounter: Int!
    $description: String
    $status: status
    $to_whom: ID
    $dependent_due_date: Date
    $dependent_promise_subtask_id: ID
    $is_dependent: Boolean
    $year: Date
    $quarter: String
    $promise_action_id: ID!
    $id: ID!
    $is_recurrence: Boolean
    $recurrence_type: recurrence_type
  ) {
    SC_update_promise_subtask(
      action: $action
      by_when: $by_when
      by_whom: $by_whom
      breakdownCounter: $breakdownCounter
      description: $description
      status: $status
      to_whom: $to_whom
      dependent_due_date: $dependent_due_date
      dependent_promise_subtask_id: $dependent_promise_subtask_id
      is_dependent: $is_dependent
      year: $year
      quarter: $quarter
      promise_action_id: $promise_action_id
      id: $id
      is_recurrence: $is_recurrence
      recurrence_type: $recurrence_type
    ) {
      message
      status
      data {
        action
        breakdown_counter
        by_when
        by_whom
        dependent_due_date
        dependent_promise_subtask_id
        description
        id
        promise_action_id
        quarter
        status
        to_whom
        year
      }
    }
  }
`;

export const SC_UPDATE_PROMISED_ACTION = gql`
  mutation SC_update_promise_action(
    $action: String!
    $by_when: Date
    $by_whom: ID
    $c_milestone_id: ID!
    $description: String
    $status: status
    $to_whom: ID
    $v_milestone_id: ID!
    $id: ID!
    $breakdownCounter: Int!
    $dependent_due_date: Date
    $dependent_promise_action_id: ID
    $is_dependent: Boolean
    $year: Date
    $quarter: String
    $is_recurrence: Boolean
    $recurrence_type: recurrence_type
  ) {
    SC_update_promise_action(
      action: $action
      by_when: $by_when
      by_whom: $by_whom
      c_milestone_id: $c_milestone_id
      description: $description
      status: $status
      to_whom: $to_whom
      v_milestone_id: $v_milestone_id
      id: $id
      breakdownCounter: $breakdownCounter
      dependent_due_date: $dependent_due_date
      dependent_promise_action_id: $dependent_promise_action_id
      is_dependent: $is_dependent
      year: $year
      quarter: $quarter
      is_recurrence: $is_recurrence
      recurrence_type: $recurrence_type
    ) {
      data {
        action
        breakdown_counter
        by_when
        by_whom
        c_milestone_id
        dependent_due_date
        dependent_promise_action_id
        description
        id
        quarter
        status
        to_whom
        v_milestone_id
        year
      }
      message
      status
    }
  }
`;

const PROMISED_ACTION_RESPONSE = gql`
  fragment promise_actionData on promise_actionData {
    action
    by_when
    task_id
    team_id
    team
    quarter
    year
    breakdown_counter
    dependent_due_date
    is_dependent
    is_recurrence
    recurrence_type
    dependent_promise_action {
      id
      action
    }
    byuserData {
      id
      name
    }
    by_whom
    description
    id
    promiseAction_c_Data {
      id
      name
    }
    promiseAction_v_Data {
      id
      name
      vDepartment {
        id
        name
      }
      vlob {
        id
        name
      }
    }
    status
    to_user
    to_whom
    touserData {
      id
      name
    }
  }
`;

export const SC_GET_ALL_PROMISED_ACTION = gql`
  query SC_get_All_promise_action(
    $department_id: ID
    $lob_id: ID
    $quarter: String
    $year: Date
    $status: [status]
    $team_id: ID
    $page: ID
    $size: ID
    $search: String
    $user_id: ID
  ) {
    SC_get_All_promise_action(
      department_id: $department_id
      lob_id: $lob_id
      quarter: $quarter
      year: $year
      status: $status
      team_id: $team_id
      page: $page
      size: $size
      search: $search
      user_id: $user_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      promiseData {
        action
        by_when
        task_id
        team_id
        team
        quarter
        year
        breakdown_counter
        is_dependent
        is_recurrence
        recurrence_type
        dependent_promise_action {
          id
          action
          by_when
        }
        byuserData {
          id
          name
        }
        by_whom
        by_user
        description
        id
        promiseAction_c_Data {
          id
          name
        }
        promiseAction_v_Data {
          id
          name
          vDepartment {
            id
            name
          }
          vlob {
            id
            name
          }
        }
        status
        to_user
        to_whom
        touserData {
          id
          name
        }
        promiseSubTask {
          action
          breakdown_counter
          by_user
          by_when
          by_whom
          dependent_due_date
          dependent_promise_subtask {
            id
            action
          }
          description
          id
          is_dependent
          quarter
          status
          is_recurrence
          recurrence_type
          subbyuserData {
            id
            name
          }
          subtouserData {
            id
            name
          }
          task_id
          team
          team_id
          to_user
          to_whom
          year
        }
      }
    }
  }
`;

const PROMISE_SUBTASK_RESPONSE = gql`
  fragment promise_subTaskData on promise_subTaskData {
    action
    breakdown_counter
    by_user
    by_when
    by_whom
    dependent_promise_subtask {
      id
      action
      by_when
    }
    description
    id
    is_dependent
    quarter
    status
    subbyuserData {
      id
      name
    }
    subtouserData {
      id
      name
    }
    task_id
    team
    team_id
    to_user
    to_whom
    year
    is_recurrence
    recurrence_type
  }
`;

export const SC_GET_PROMISED_SUBTASK_BY_PROMISED_ACTION = gql`
  ${PROMISE_SUBTASK_RESPONSE}
  query SC_get_promiseSubtask_by_promiseAction(
    $department_id: ID
    $lob_id: ID
    $promise_action_id: ID!
    $quarter: String
    $status: [status]
    $team_id: ID
    $year: Date
    $page: ID
    $size: ID
    $search: String
    $user_id: ID
  ) {
    SC_get_promiseSubtask_by_promiseAction(
      department_id: $department_id
      lob_id: $lob_id
      promise_action_id: $promise_action_id
      quarter: $quarter
      status: $status
      team_id: $team_id
      year: $year
      page: $page
      size: $size
      search: $search
      user_id: $user_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      promiseSubTaskData {
        ...promise_subTaskData
      }
    }
  }
`;

export const SC_GET_PROMISED_SUBTASK_BY_PROMISED_ACTION_DROPDOWN = gql`
  query SC_get_promiseSubtask_by_promiseAction_dropdown($id: ID!) {
    SC_get_promiseSubtask_by_promiseAction_dropdown(id: $id) {
      action
      by_when
      id
    }
  }
`;

export const SC_GET_ALL_PROMISED_ACTION_DROPDOWN = gql`
  query SC_get_All_promise_action_dropdown {
    SC_get_All_promise_action_dropdown {
      id
      action
      by_when
    }
  }
`;

export const SC_GET_ALL_PROMISED_ACTION_BY_C_MILESTONE = gql`
  ${PROMISED_ACTION_RESPONSE}
  query SC_get_promiseAction_by_c_milestone($cMilestone_id: ID!, $department_id: ID) {
    SC_get_promiseAction_by_c_milestone(
      cMilestone_id: $cMilestone_id
      department_id: $department_id
    ) {
      ...promise_actionData
    }
  }
`;

export const SC_DELETE_V_MILESTONE = gql`
  mutation SC_delete_v_milestone($id: ID) {
    SC_delete_v_milestone(id: $id)
  }
`;

export const SC_DELETE_C_MILESTONE = gql`
  mutation SC_delete_c_milestone($id: ID) {
    SC_delete_c_milestone(id: $id)
  }
`;

export const SC_DELETE_PROMISE_ACTION = gql`
  mutation SC_delete_promise_action($id: ID) {
    SC_delete_promise_action(id: $id)
  }
`;

export const SC_ADD_WEEKWISE_DEPARTMENT_PERCENTAGE = gql`
  mutation SC_add_weekwise_department_percentage($data: weekpercentageData) {
    SC_add_weekwise_department_percentage(data: $data)
  }
`;

export const SC_UPDATE_LOCK_WEEKLY_PERCENTAGE = gql`
  mutation SC_update_weekly_percentage_lock($date: Date!, $department_id: ID!) {
    SC_update_weekly_percentage_lock(date: $date, department_id: $department_id)
  }
`;

export const SC_GET_CLICKUP_AUTH_CODE = gql`
  query SC_getClickupAuthorizationCode {
    SC_getClickupAuthorizationCode
  }
`;

export const CLICKUP_GET_AUTH_CODE = gql`
  query clickup_getAuthorizationCode {
    clickup_getAuthorizationCode
  }
`;

export const SC_CREATE_CLICKUP_API_TOKEN = gql`
  mutation SC_createClickupApiToken($code: String!) {
    SC_createClickupApiToken(code: $code)
  }
`;

export const CLICKUP_CREATE_API_TOKEN = gql`
  mutation clickup_createApiToken($code: String!) {
    clickup_createApiToken(code: $code)
  }
`;

export const SC_CHECK_CLICKUP_LOGIN_STATUS = gql`
  query SC_checkClickupLoginStatus {
    SC_checkClickupLoginStatus
  }
`;

export const CLICKUP_CHECK_LOGIN_STATUS = gql`
  query clickup_checkLoginStatus {
    clickup_checkLoginStatus
  }
`;

export const SC_BULK_UPDATE_VERIFYING_MILESTONE = gql`
  mutation SC_bulkUpdateVerifyMilestone($data: [verifyInputData], $status: status!) {
    SC_bulkUpdateVerifyMilestone(data: $data, status: $status)
  }
`;

export const SC_BULK_UPDATE_CAUSATIVE_MILESTONE = gql`
  mutation SC_bulkUpdateCausativeMilestone($data: [inputData], $status: status!) {
    SC_bulkUpdateCausativeMilestone(data: $data, status: $status)
  }
`;

export const SC_BULK_UPDATE_PROMISE_ACTION = gql`
  mutation SC_bulkUpdatePromiseAction($data: [inputData], $status: status!) {
    SC_bulkUpdatePromiseAction(data: $data, status: $status)
  }
`;

const MILESTONES_SUMMARY_RESPONSE = gql`
  fragment summary_data on summary_data {
    breakdown
    cancelled
    complete
    offtrack
    onhold
    ontrack
  }
`;

export const SC_GET_CAUSATIVE_MILESTONE_SUMMARY = gql`
  ${MILESTONES_SUMMARY_RESPONSE}
  query SC_causative_milestone_summary($quarter: String, $team_id: ID, $year: Date) {
    SC_causative_milestone_summary(quarter: $quarter, team_id: $team_id, year: $year) {
      ...summary_data
      total_causative_milestone
    }
  }
`;

export const SC_GET_PROMISED_ACTIONS_SUMMARY = gql`
  ${MILESTONES_SUMMARY_RESPONSE}
  query SC_promise_action_summary($quarter: String, $team_id: ID, $year: Date) {
    SC_promise_action_summary(quarter: $quarter, team_id: $team_id, year: $year) {
      ...summary_data
      total_promise_action
    }
  }
`;

export const SC_GET_USER_PROMISED_ACTIONS_SUMMARY = gql`
  query SC_user_promise_action_summary($quarter: String, $team_id: ID, $year: Date) {
    SC_user_promise_action_summary(quarter: $quarter, team_id: $team_id, year: $year) {
      dataValues {
        breakdown
        cancelled
        complete
        offtrack
        onhold
        ontrack
        total_user_task
        byuserData {
          name
        }
      }
    }
  }
`;

const STRATEGIC_OUTCOME_RESPONSE = gql`
  fragment strategicData on strategicData {
    id
    name
    team_id
    team
  }
`;

export const SC_GET_ALL_STRATEGIC_OUTCOMES = gql`
  ${STRATEGIC_OUTCOME_RESPONSE}
  query SC_get_all_strategic {
    SC_get_all_strategic {
      ...strategicData
    }
  }
`;

export const SC_CREATE_STRATEGIC_OUTCOME = gql`
  mutation SC_create_strategic($name: String!, $team_id: ID) {
    SC_create_strategic(name: $name, team_id: $team_id) {
      message
      status
    }
  }
`;

export const SC_UPDATE_STRATEGIC_OUTCOME = gql`
  mutation SC_update_strategic($id: ID!, $name: String!) {
    SC_update_strategic(id: $id, name: $name) {
      message
      status
    }
  }
`;

export const SC_DELETE_STRATEGIC_OUTCOME = gql`
  mutation SC_delete_strategic($id: ID) {
    SC_delete_strategic(id: $id)
  }
`;

export const SC_DELETE_PROMISE_SUBTASK = gql`
  mutation SC_delete_promise_subtask($id: ID) {
    SC_delete_promise_subtask(id: $id)
  }
`;

export const SC_UPDATE_USER = gql`
  mutation SC_updateUser(
    $department_id: ID!
    $user_id: ID!
    $is_active: Boolean
    $lob_id: ID
    $name: String
    $id: ID!
    $is_unicorn: Boolean
  ) {
    SC_updateUser(
      department_id: $department_id
      user_id: $user_id
      is_active: $is_active
      lob_id: $lob_id
      name: $name
      id: $id
      is_unicorn: $is_unicorn
    )
  }
`;
