import { Form, Input, Button } from "antd";

function CreateWorkspaceForm() {
  return (
    <Form layout="vertical">
      <Form.Item label="Workspace Name">
        <Input placeholder="Enter workspace name" />
      </Form.Item>
      <Form.Item label="Workspace Description">
        <Input placeholder="Enter workspace description" />
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ backgroundColor: "#52C41A", color: "#ffffff" }}
          className="light-bold-clr"
        >
          Create Workspace
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ backgroundColor: "#ffffff", color: "#52C41A", borderColor: "#52C41A" }}
          className="light-bold-clr"
        >
          Skip for now
        </Button>
      </Form.Item>
    </Form>
  );
}
export default CreateWorkspaceForm;
