import { Button, Col, Collapse, Image, Row, Table } from "antd";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";
import SelectInput from "components/forms/SelectInput";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { CREATIVE_TYPE_IMAGE } from "shared/SharedKeys/launchKeys";
import { GET_MOODBOARD_SCRIPTS } from "shared/gql/gqlSchema/creativeDashboardGql";
import {
  GQL_ADD_CREATIVE_ANALYSIS,
  GQL_GET_CREATIVE_ANALYSIS,
} from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";
import SelectMoodboardDataModal from "./SelectMoodboardDataModal";
import { MOODBOARD_TYPE_SCRIPT } from "../../creativesEnum";
import Loader from "components/loader";
import MainPointsModal from "./MainPointsModal";

function CreativeCompareModal(props) {
  const { Image } = require("antd");
  const {
    selectedBulkCreative = [],
    creativeType,
    visibleCategoryModal = false,
    setVisibleCreativeCompareModal = () => {},
    setVisibleCategoryModal = () => {},
    selectedRowsClear,
    productId,
  } = props;
  const numberOfCreatives = selectedBulkCreative?.length;
  const [activeKey, setActiveKey] = useState(null);
  const commonCategory = selectedBulkCreative?.[0]?.categoryId;
  const [creativeData, setCreativeData] = useState([]);
  const [selectedCreativeId, setSelectedCreativeId] = useState();
  const [visibleCreativePointsModal, setVisibleCreativePointsModal] = useState(false);
  const [selectVisibleModal, setSelectVisibleModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categories, setCategories] = useState(
    commonCategory?.length > 1 ? "" : commonCategory?.[0]?.productCategoryId
  );
  const handleCollapseChange = (keys, data) => {
    setActiveKey(keys);
  };
  // get api for analysis details
  const [
    fetchCreativeAnalysis,
    { loading: loading_creative_analysis, refetch: refetch_creative_analysis },
  ] = useCustomLazyQuery(GQL_GET_CREATIVE_ANALYSIS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ctGetCreativeAnalysis;
      if (element?.status === "SUCCESS") {
        setCreativeData(element.data);
      } else {
        setCreativeData([]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  // function is to render creative details like category,source
  const renderCreativeDetails = () => {
    let transposeData = {
      Category: [],
      Source: [],
      // Platform: [],
      ComplianceStatus: [],
      Spend: [],
      Revenue: [],
      GrossMargin: [],
    };

    selectedBulkCreative?.forEach((data) => {
      transposeData["Category"]?.push(
        data?.categoryId?.map(({ name }) => {
          return <span className="creative-details mr-10">{name}</span>;
        }) || ""
      );
      transposeData["Source"]?.push(
        <span className="creative-details mr-10">{data?.source}</span> || ""
      );
      transposeData["ComplianceStatus"]?.push(data?.complianceStatus || "");
      transposeData["Spend"]?.push(data?.spend || "");
      transposeData["Revenue"]?.push(data?.revenue || "");
      transposeData["GrossMargin"]?.push(data?.gross_margin || "");
    });

    return (
      <>
        {Object.keys(transposeData).map((key) => (
          <div key={key} className="d-flex custom-table-border ">
            <div className="pt-15 pb-10 categoryname">{key}</div>
            <div className="w-100">
              <Row>
                {transposeData[key]?.map((result, index) => (
                  <Col
                    className="pt-15 px-12 pb-10 borderRight-1"
                    key={index}
                    span={24 / numberOfCreatives}
                  >
                    {result}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ))}
      </>
    );
  };

  // get api for scripts
  const [
    scriptsFetch,
    { data: scripts_data, loading: scripts_data_loading, refetch: data_refetch },
  ] = useCustomLazyQuery(GET_MOODBOARD_SCRIPTS, {
    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "mb-res-err-scripts" });
    },
  });
  useEffect(() => {
    fetchCreativeAnalysis({
      variables: { creativeIds: selectedBulkCreative?.map((data) => data?.group_id), creativeType },
    });
  }, []);

  // function is manipulating creativeData with respect to selectedBulkCreative, in order to maintain data acc to creatives
  function alignCreatives() {
    const alignedArray1 = selectedBulkCreative.slice(); // Create a shallow copy of array1
    const alignedArray2 = [];

    const idMap = new Map();
    for (const obj of creativeData) {
      if (creativeType === CREATIVE_TYPE_IMAGE) {
        idMap.set(obj.imageId?.id, obj);
      } else {
        idMap.set(obj.videoId?.id, obj);
      }
    }
    for (const obj1 of alignedArray1) {
      const obj2 = idMap.get(obj1.group_id);
      if (obj2) {
        alignedArray2.push(obj2);
      } else {
        alignedArray2.push(null);
      }
    }

    return alignedArray2;
  }

  // function is to add common Button
  const renderBtn = (creativeDetails, isScript, addButtonLabel, index) => {
    return (
      <LookButton
        className="border-dotted"
        onClick={() => {
          isScript ? setSelectVisibleModal(true) : setVisibleCreativePointsModal(true);
          setSelectedCreativeId(selectedBulkCreative[index]?.group_id);
        }}
      >
        + Add {addButtonLabel}
      </LookButton>
    );
  };
  // function is to add Details
  const renderDetails = (addButtonLabel, isScript = false) => {
    const creativeDetails = alignCreatives();
    return (
      <>
        {creativeDetails.map((details, index) => {
          const points = isScript
            ? details?.scriptId || {}
            : details?.extendedFields?.main_points || [];
          const length = isScript ? Object.keys(points)?.length : points?.length;

          if (length > 0) {
            if (isScript) {
              const {
                name = "",
                description = "",
                copyPoints = [],
                angles = [],
                hooks = [],
                painPoints = [],
                language = "",
              } = points;

              return (
                <Col className="border" span={24 / numberOfCreatives}>
                  <div key={index}>
                    <h3 className="creative-compare-subtitle pb-15">Name: {name}</h3>
                    <>
                      <h3 className="creative-compare-subtitle">Description:</h3>
                      <p className="creative-compare-para">{description}</p>
                      <h3 className="creative-compare-subtitle">Copy Points:</h3>
                      <ul className="creative-compare-list">
                        {copyPoints.map((point, i) => (
                          <li key={i}>{point?.name}</li>
                        ))}
                      </ul>
                      <h3 className="creative-compare-subtitle">Angles:</h3>
                      <ul className="creative-compare-list">
                        {angles.map((angle, i) => (
                          <li key={i}>{angle?.name}</li>
                        ))}
                      </ul>
                      <h3 className="creative-compare-subtitle">Hooks:</h3>
                      <ul className="creative-compare-list">
                        {hooks.map((hook, i) => (
                          <li key={i}>{hook?.name}</li>
                        ))}
                      </ul>
                      <h3 className="creative-compare-subtitle">Pain Points:</h3>
                      <ul className="creative-compare-list">
                        {painPoints.map((point, i) => (
                          <li key={i}>{point?.name}</li>
                        ))}
                      </ul>
                      {language && (
                        <h3 className="creative-compare-subtitle">Language: {language?.name}</h3>
                      )}
                    </>
                  </div>
                </Col>
              );
            } else {
              return (
                <>
                  <Col className="border" span={24 / numberOfCreatives}>
                    {renderBtn(creativeDetails, isScript, addButtonLabel, index)}
                    {points?.map((data) => (
                      <span className="creative-details ml-10">{data}</span>
                    ))}
                  </Col>
                </>
              );
            }
          } else {
            return (
              <Col className="border" span={24 / numberOfCreatives}>
                {renderBtn(creativeDetails, isScript, addButtonLabel, index)}
              </Col>
            );
          }
        })}
      </>
    );
  };
  // function is to render script Details
  const renderScriptDetails = () => {
    return renderDetails("Script", true);
  };
  // function is to render main points Details
  const renderMainPointDetails = () => {
    return renderDetails("Main-Point");
  };
  // function is to render category Details
  const renderCategory = () => {
    return (
      <>
        <Col className="border" span={24 / numberOfCreatives}>
          <SelectInput
            label="Category"
            search
            value={categories}
            onChange={(e) => {
              setCategories(e);
            }}
            options={commonCategory?.map((category) => ({
              name: category?.name,
              value: category?.productCategoryId,
            }))}
          />
          <div className="py-20">
            <Row justify="end">
              <Col span={4} className="mr-10">
                <Button block onClick={selectedRowsClear}>
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setVisibleCreativeCompareModal(true);
                    setVisibleCategoryModal(false);
                    scriptsFetch({
                      variables: { categoryId: categories, productId },
                    });
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </>
    );
  };
  const [
    addCreativeAnalysis,
    { loading: loading_add_creative_analysis, refetch: refetch_add_creative_analysis },
  ] = useCustomMutation(GQL_ADD_CREATIVE_ANALYSIS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ctAddCreativeAnalysis;
      if (element?.status === "SUCCESS") {
        openNotification({ type: "success", message: e?.ctAddCreativeAnalysis?.message });
        refetch_creative_analysis();
        onCloseSelectMoodboardDrawer();
        clearMainPoints();
      } else {
        openNotification({ type: "error", message: e?.message });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  // function is to add analysis data
  const handleAddAnalysis = (isMainPoints = false) => {
    let variables = {
      creativeId: selectedCreativeId,
      creativeType,
      ...(isMainPoints
        ? { requestInput: { extendedFields: { mainPoints: [selectedItems] } } }
        : { requestInput: { script: selectedItems?.[0]?.id } }),
    };

    addCreativeAnalysis({
      variables,
    });
  };
  // function is to clear mainpoints modal data
  const clearMainPoints = () => {
    setVisibleCreativePointsModal(false);
    setSelectedItems([]);
  };
  // function is to clear scripts modal data
  const onCloseSelectMoodboardDrawer = () => {
    setSelectVisibleModal(false);
    setSelectedItems([]);
  };
  const selectModalProps = {
    title: `Select Scripts`,
    visible: selectVisibleModal,
    onCancel: onCloseSelectMoodboardDrawer,
    okokText: "Submit",
    footer: null,
    width: 720,
  };
  const mainPointModalProps = {
    title: `Add Main Points`,
    visible: visibleCreativePointsModal,
    onCancel: clearMainPoints,
    okokText: "Submit",
    onSubmit: () => handleAddAnalysis(true),
    width: 720,
  };

  return (
    <>
      {loading_creative_analysis ? (
        <Loader />
      ) : (
        <>
          {visibleCategoryModal ? (
            renderCategory()
          ) : (
            <div className="creative-comapare">
              <div className="d-flex">
                <div className="categoryname border">Creative</div>
                <div>
                  <Row>
                    {selectedBulkCreative?.map((creative, index) => {
                      const { image = {}, video = {} } = creative;
                      const renderImageView = () => {
                        const { s3Url = "" } = image;
                        return <Image src={s3Url} preview={false} className="comapareImg" />;
                      };
                      const renderVideoView = () => {
                        const { s3Url = "", thumbUrl = "" } = video;
                        return <Image src={thumbUrl} preview={false} className="comapareImg" />;
                      };
                      return (
                        <Col className="border" span={24 / numberOfCreatives}>
                          <div className="compare-image">
                            {creativeType === CREATIVE_TYPE_IMAGE
                              ? renderImageView()
                              : renderVideoView()}
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </div>

              {/* creative details section */}
              <Row>
                <Col className="collapse-main" span={24}>
                  <div className="collapse-detail">
                    <Collapse
                      className="flex-grow-1"
                      collapsible="header"
                      accordion
                      activeKey={activeKey}
                      onChange={handleCollapseChange}
                    >
                      <Collapse.Panel header="Creative Details">
                        <div>{renderCreativeDetails()}</div>
                      </Collapse.Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
              {/* scripts 
   & mainpints section */}
              <div className="d-flex">
                <div className="categoryname  border ">Scripts</div>
                <div className="w-100">
                  <Row>{renderScriptDetails()}</Row>
                </div>
              </div>
              <div className="d-flex">
                <div className="categoryname border ">MainPoints</div>
                <div className="w-100">
                  <Row>{renderMainPointDetails()}</Row>
                </div>
              </div>
            </div>
          )}
          {/* scripts modal section */}
          {selectVisibleModal && (
            <LookModal {...selectModalProps}>
              {selectVisibleModal && (
                <SelectMoodboardDataModal
                  id={[categories]}
                  // name={name}
                  handleAddAnalysis={handleAddAnalysis}
                  loading={loading_add_creative_analysis}
                  apiKey={`script`}
                  actionType={MOODBOARD_TYPE_SCRIPT}
                  placeHolderKey={`Script`}
                  productId={productId}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  creativeId={selectedCreativeId}
                  creativeType={creativeType}
                  addCreativeAnalysis={addCreativeAnalysis}
                  dataKey={`scriptId`}
                  onClose={onCloseSelectMoodboardDrawer}
                />
              )}
            </LookModal>
          )}
          {/* mainpoints  modal section */}
          <LookModal {...mainPointModalProps}>
            {visibleCreativePointsModal && (
              <MainPointsModal
                handleAddAnalysis={handleAddAnalysis}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                clearMainPoints={clearMainPoints}
              />
            )}
          </LookModal>
        </>
      )}
    </>
  );
}

export default CreativeCompareModal;
