import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useAwsAuth } from "shared/AwsAuth";
import { openNotification } from "utils";

const UN_AUTHORIZED_MESSAGE = "Context creation failed: UnAuthorized Access";
const UN_AUTHORIZED_GHOST_USER_MESSAGE = "Context creation failed: UnAuthorized Ghost User";

export const API_TYPE_DATA = "API_TYPE_DATA";
export const API_TYPE_PAGINATE = "API_TYPE_PAGINATE";

export const API_DATA_KEY_ID = "id";
export const API_DATA_KEY_INDEX = "index";

export function useCustomQuery(queryApi, params = {}) {
    const remberLogin = JSON.parse(localStorage.getItem("remberLogin"));
  const {
    onCompleted = () => {},
    onError = () => {},
    dataSourceDefault = [],
    addDataKey = true,
    dataKey = API_DATA_KEY_ID,
    apiKey,
    apiType = API_TYPE_DATA,
    withCache = false,
    skipError = false,
    ...rest
  } = params;
  const [dataSource, setDataSource] = useState(dataSourceDefault);
  const { refreshTokenIfNeeded, userLogOut = () => {} } = useAwsAuth();

  const errorParams = skipError ? { errorPolicy: "ignore" } : {};
  const extraParameters = { ...errorParams };
  if (!withCache) {
    extraParameters.fetchPolicy = "no-cache";
  }

  const queryParams = useQuery(queryApi, {
    ...extraParameters,
    ...rest,
    onCompleted: (e) => {
      setDataSource(dataSourceDefault);
      onCompleted(e);
      if (apiKey !== undefined && apiKey) {
        const element = e[apiKey];
        if (element) {
          if (typeof element === "number" || typeof element === "string") {
            setDataSource(element);
          } else if (typeof element === "object") {
            if (Array.isArray(element)) {
              const tempElementData = addDataKey
                ? element.map((elementData, index) => {
                    const elementKey =
                      dataKey === API_DATA_KEY_INDEX ? index : elementData[dataKey];
                    return { ...elementData, key: elementKey };
                  })
                : element;
              setDataSource(tempElementData);
            } else {
              setDataSource(element);
            }
          }
        }
      }
    },

    onError: (e) => {
      setDataSource(dataSourceDefault);
      const unAuthorizedErrorMessage = e?.networkError?.result?.errors[0]?.message;

      if (
        unAuthorizedErrorMessage &&
        (unAuthorizedErrorMessage === UN_AUTHORIZED_MESSAGE ||
          unAuthorizedErrorMessage === UN_AUTHORIZED_GHOST_USER_MESSAGE)
      ) {
        if (remberLogin) {
          refreshTokenIfNeeded({ forceRefresh: true });
        } else {
          openNotification({ type: "error", message: "Session Expired", key: "session-expire" });
          setTimeout(() => {
            userLogOut();
          }, 5000);
        }
        return;
      }
      onError(e);
    },
  });

  const { refetch, ...queryParamsRest } = queryParams;
  function refetchCustome(e) {
    setDataSource(dataSourceDefault);
    refetch(e);
  }
  return { ...queryParamsRest, refetch: refetchCustome, dataSource, setDataSource };
}

export function useCustomLazyQuery(queryApi, params = {}) {
  const remberLogin = JSON.parse(localStorage.getItem("remberLogin"));
  const {
    onCompleted = () => {},
    onError = () => {},
    withCache = false,
    skipError = false,
    ...rest
  } = params;
  const { refreshTokenIfNeeded, userLogOut = () => {} } = useAwsAuth();
  const errorParams = skipError ? { errorPolicy: "ignore" } : {};
  const extraParameters = { ...errorParams };
  if (!withCache) {
    extraParameters.fetchPolicy = "no-cache";
  }

  const [apiCall, queryParams] = useLazyQuery(queryApi, {
    ...extraParameters,
    ...rest,
    onCompleted: (e) => {
      onCompleted(e);
    },
    onError: (e) => {
      const unAuthorizedErrorMessage = e?.networkError?.result?.errors[0]?.message;
      if (
        unAuthorizedErrorMessage &&
        (unAuthorizedErrorMessage === UN_AUTHORIZED_MESSAGE ||
          unAuthorizedErrorMessage === UN_AUTHORIZED_GHOST_USER_MESSAGE)
      ) {
        if (remberLogin) {
          refreshTokenIfNeeded({ forceRefresh: true });
        } else {
          openNotification({ type: "error", message: "Session Expired", key: "session-expire" });
          setTimeout(() => {
            userLogOut();
          }, 5000);
        }
        return;
      }
      onError(e);
    },
  });
  return [apiCall, { ...queryParams }];
}

export function useCustomMutation(queryApi, params = {}) {
  const remberLogin = JSON.parse(localStorage.getItem("remberLogin"));
  const {
    onCompleted = () => {},
    onError = () => {},
    withCache = false,
    skipError = false,
    ...rest
  } = params;
  const { refreshTokenIfNeeded, userLogOut = () => {} } = useAwsAuth();

  const errorParams = skipError ? { errorPolicy: "ignore" } : {};
  const extraParameters = { ...errorParams };
  if (!withCache) {
    extraParameters.fetchPolicy = "no-cache";
  }
  const [apiCall, mutationParams] = useMutation(queryApi, {
    ...extraParameters,
    ...rest,
    onCompleted: (e) => {
      onCompleted(e);
    },
    onError: (e) => {
      const unAuthorizedErrorMessage = e?.networkError?.result?.errors[0]?.message;
      if (
        unAuthorizedErrorMessage &&
        (unAuthorizedErrorMessage === UN_AUTHORIZED_MESSAGE ||
          unAuthorizedErrorMessage === UN_AUTHORIZED_GHOST_USER_MESSAGE)
      ) {
        if (remberLogin) {
          refreshTokenIfNeeded({ forceRefresh: true });
        } else {
          openNotification({ type: "error", message: "Session Expired", key: "session-expire" });
          setTimeout(() => {
            userLogOut();
          }, 5000);
        }
        return;
      }
      onError(e);
    },
  });
  return [apiCall, { ...mutationParams }];
}
