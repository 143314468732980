import DebugPre from "components/DebugPre";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import LookModal from "components/LookModal";
import { useApiToStore } from "hooks";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { API_STORE_VIRALSPARKS_USER } from "hooks/useApiToStore/keys";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  C_TYPE_CBO,
  BUDGET_TYPE_DAILY,
  C_TYPE_ABO,
  SERVICE_TYPE_VS,
  CONTENT_TYPE_CAMPAIGN,
  SERVICE_TYPE_LEAD_GEN,
  CONTENT_TYPE_GROUPS,
  EUROPEAN_COUNTRIES,
  PLATFORM_TYPE_TIKTOK,
  PLATFORM_TYPE_FACEBOOK,
} from "shared/enum/launchKeysAndEnum";
import { LAUNCH_PLATFORM_DATA } from "shared/gql/gqlSchema/launchGql";
import { LaunchContext } from "./Context";
import LaunchMainView from "./LaunchMainView";
import SelectTargetingTemplate from "./SelectTargetingTemplate";
import { CONTENT_TYPE_ARTICLE } from "shared/enum/launchKeysAndEnum";
import { SERVICE_TYPE_SAS } from "shared/enum/launchKeysAndEnum";
import { CONTENT_TYPE_CATEGORY } from "shared/enum/launchKeysAndEnum";
import { CONTENT_TYPE_KEYWORD } from "shared/enum/launchKeysAndEnum";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import Loader from "components/loader";
import moment from "moment";
import { LAUNCH_SMART_CREATIVE_ENUM } from "shared/enum/iCampaignLaunchEnumArray";
import initialSetCampaign from "./LaunchSetupFunctions/initialCampaignSetup";
import useLaunchApiHooks from "./LaunchUtils/useLaunchApiHooks";
import { CHOOSE_EXISTING_TEMPLATE } from "shared/SharedKeys/launchKeys";
import { FB360_GET_AD_ACCOUNTS, FB360_GET_PROFILES } from "shared/gql/gqlSchema/fb360Gql";
import { useApolloClient } from "@apollo/client";
import { PRODUCT_ID_VS } from "shared/SharedKeys";
/**
 * * LAUNCH_STATUS_HAVE_FAILURE
 *
 * used when user relaunch failed launch
 * with this shows a button on top right
 * with this user filter failed campaigns
 */

export const LAUNCH_STATUS_HAVE_FAILURE = 1;
export const LAUNCH_STATUS_HAVE_FAILURE_RETRY = 2;

export const CONTINUES_DRAFT = "CONTINUES_DRAFT",
  CONTINUES_NEW = "CONTINUES_NEW",
  CONTINUES_RELAUNCH_FULL = "CONTINUES_RELAUNCH_FULL",
  CONTINUES_RELAUNCH_ONLY_FAILED = "CONTINUES_RELAUNCH_ONLY_FAILED";

export default function Launch(props) {
  function cloneMainCamaignObject(campObject) {
    const campaigns = Array.isArray(campObject) ? campObject : [{ ...campObject }];
    return campaigns?.map((data) => {
      const { adSets = [] } = data;

      return {
        ...data,
        adSets: adSets?.map((adSetData) => {
          const { ads = [] } = adSetData;

          return { ...adSetData, ads: ads?.map((adData) => ({ ...adData })) };
        }),
      };
    });
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dateFormat = "YYYY-MM-DD HH:mm";
  const history = useHistory();
  const params = useParams();
  const [campaignObject, setCampaignObject] = useState([]);
  const { selectedRowsClear } = useLookTableParentContext();
  const {
    serviceType,
    productId,
    instantLaunch,
    platform,
    instantLaunchData,
    setVisibleLaunchDrawer = () => {},
  } = props;
  // This state is to confirm either user want to split url and params or not
  const [urlSplited, setUrlSplited] = useState(true);
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const [leadgen_name, setLeadgen_name] = useState("");
  const [adSetwise, setAdsetWise] = useState(false);
  const [isDynamicCreatives, setIsDynamicCreatives] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [adGroupDataSource, setAdGroupDataSource] = useState([]);
  const [visibleGroupModal, setVisibleGroupModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedProfileIds, setSelectedProfileIds] = useState();
  const [visibleCategoryModal, setVisibleCategoryModal] = useState(instantLaunch ? true : false);
  const [visibleChooseAdsetModal, setVisibleChooseAdsetModal] = useState(true);
  const [isValidLaunch, setIsValidLaunch] = useState([
    { isValid: false, formDataIndex: 0, inValidAdAccountIndex: 0 },
  ]);

  const { userSettingData } = useSelector((state) => state?.userSettings) || {};
  const { launchConfig } = userSettingData || {};
  const ad_vs_nomenclature =
    "{user}-{country}-{article}-{adacc}-{campaignid}-{adsetid}{user}-{country}-{article}-{adacc}-{campaignid}-{adsetid}-{adid}";
  const { targeting } = launchConfig || {};
  const {
    facebook_positions: fbPosition = [],
    instagram_positions: instaPosition = [],
    audience_network_positions: audiencePosition = [],
    messenger_positions: messengerPosition = [],
  } = targeting || {};

  const [optionProps, setOptionProps] = useState({
    titles: [],
    descriptions: [],
    body: [],
  });
  const serviceRoutes =
    serviceType === SERVICE_TYPE_VS ? "vs" : serviceType === SERVICE_TYPE_SAS ? "sas" : "leadgen";

  /**
   * chooseDraftOrNew - CONTINUES_DRAFT/CONTINUES_NEW
   */
  const [chooseDraftOrNew, setChooseDraftOrNew] = useState("");
  const [multiAdaccount_data, setMultiAdaccount_data] = useState([{}]);
  const [chooseExistingTemplate, setChooseExistingTemplate] = useState("");
  const [profile_id, setProfileId] = useState(null);

  const { apiCall: vsLoginUserFetch = () => {}, refetch: vsLoginUserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_USER
  );

  const { data: platformsData, loading: PLATFORM_LOADING } = useCustomQuery(LAUNCH_PLATFORM_DATA, {
    onCompleted: (e) => {},
  });

  const [draftData, setDraftData] = useState(null);
  const [adGroupData, setAdGroupData] = useState({});
  const [vsUrlPlatform, setVsUrlPlatform] = useState([]);
  const [vsBrandByDomains, setVsBrandByDomains] = useState([]);
  const { vsLoginUser, vsLoginUser_loading = false } = userSettingData || {};
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [visibleTargetModal, setVisibleTargetModal] = useState(params?.launchId ? false : true);
  const [bucketData, setBucketData] = useState(null);
  const [contentContentData, setBucketContentData] = useState(null);
  const isSasKeywordGroup = bucketData?.content_type === CONTENT_TYPE_GROUPS;
  const isSasCampaign = bucketData?.content_type === CONTENT_TYPE_CAMPAIGN;
  const [selectedTarget, setSelectedTarget] = useState("");
  const [targettingTemplateData, setTargettingTemplateData] = useState(null);
  const [submission, setSubmission] = useState({});
  const billingEvent = "IMPRESSIONS";
  const [formData, setFormData] = useState([]);
  const isTiktokPlatformLaunch =
    (instantLaunch && platform === PLATFORM_TYPE_TIKTOK) ||
    bucketData?.bucket_platform?.nickname === "tiktok";
  const [inputs, setInputs] = useState({
    nonAdGroup: true,
    includeVideos: true,
    title: "",
    description: "",
    body: "",
    adset_count: 1,
    ad_count: 1,
    utm_name: "",
    creative_auto_select: true,
    campaignCount: 1,
  });
  const intialValues = {
    launchName: "",
    beneficiary: "",
    payor: "",
    adAccount: "",
    pixel: "",
    customEventType: "",
    pageId: "",
    customConversionId: "",
    customEventStr: "",
    alias: "",
    campCounter: "1",
    pixelValue: "",
    epcKey: "",
    identityType: "",
    identityId: "",
    tracking_url: "",
  };
  const [adAccountValues, setAdAccountValues] = useState(intialValues);

  const handlePlatforms = (e, object, publisherPlatforms) => {
    // [e.target.name], e.target.value

    const facebook_positions = object["facebook_positions"] || fbPosition?.map((i) => i?.value);
    const instagram_positions =
      object["instagram_positions"]?.length > 0
        ? object["instagram_positions"]
        : publisherPlatforms.includes("instagram") && instaPosition?.map((i) => i?.value);
    const messenger_positions = object["messenger_positions"];
    const audience_network_positions =
      object["audience_network_positions"]?.length > 0
        ? object["audience_network_positions"]
        : publisherPlatforms.includes("audience_network") && audiencePosition?.map((i) => i?.value);

    let newFbArr = [];

    newFbArr = e.includes("mobile")
      ? facebook_positions.filter((i) => {
          return i !== "instant_article";
        })
      : facebook_positions.filter((i) => {
          return i !== "right_hand_column";
        });

    let instaArr = [];
    instaArr = e.includes("desktop") ? [] : instagram_positions;

    let messengerArr = [];

    messengerArr = e.includes("desktop")
      ? messenger_positions.filter((i) => i !== "story")
      : messenger_positions.filter((i) => i !== "messenger_home");

    let audience_network = [];

    audience_network = e.includes("desktop") ? [] : audience_network_positions;

    return { newFbArr, audience_network, instaArr, messengerArr };
  };

  const initialSetCampaignProps = {
    targettingTemplateData,
    serviceType,
    contentContentData,
    bucketData,
    billingEvent,
    isSasKeywordGroup,
    isSasCampaign,
    inputs,
    isTiktokPlatformLaunch,
    selectedImage,
    adGroupDataSource,
    instantLaunch,
    setCampaignObject,
    isDraft: chooseDraftOrNew === CONTINUES_DRAFT,
    multiCountry: targettingTemplateData?.multiCountry,
    handlePlatforms,
    campaignObject,
    instantLaunchData,
    chooseDraftOrNew,
    handleDraft,
  };
  /***
   * This function is use to fetch all the apis defined in useLaunchApiHooks
   */
  const {
    fetchTemplates,
    addAccountCache,
    loading_creatives_templates,
    refetch_creatives_templates,
    data_adAccount,
    fetchtikTokAdaccount,
    loading_adAccount,
    refetch_adAccount,
    callTiktokTemplatesForDropdown,
    loading_tiktok_templates,
    refetch_tiktok_templates,
    callTemplatesForDropdown,
    loading_templates,
    refetch_templates,
    fetchadAccountCache,
    adAccountCache,
    vsBrands,
    vsBrandsFetch,
    sasTrackingUrlFetch,
    sasTrackingUrl,
    sasTrackingLoading,
    callContent,
    call_vsBrandGetByDomain,
    callKeywords,
    callCategories,
    fetchCreatives_Images,
    fetchCreatives_Groups,
    loading_adgroups,
    fetchTiktokTemplate,
    LoadingTiktokTemplate,
    sasLaunch,
    sasLaunch_loading,
    callTiktokLaunch,
    tiktokLaunch_loading = false,
    launchDraft_loading,
    callLaunchLeadGenDraft,
    callSaveLaunchDraft,
    callSaveLeadGenLaunchDraft,
    launchDataAdAccountCall,
    launchDataAdAccount_data,
    launchDataAdAccount_loading,
    launchDataAdAccount_refetch,
    callTemplate,
    loading_Template,
    callBucket,
    loading_bucket,
    launchLeadGenDraft_loading,
    callLaunchDraft,
  } = useLaunchApiHooks({
    productId,
    serviceType,
    setInputs,
    instantLaunch,
    setVsUrlPlatform,
    setBucketContentData,
    bucketData,
    setBucketData,
    setAdAccountValues,
    setVsBrandByDomains,
    setOptionProps,
    adAccountValues,
    setVisibleTargetModal,
    setSelectedImage,
    isSasCampaign,
    setVisibleGroupModal,
    setAdGroupDataSource,
    initialSetCampaign,
    initialSetCampaignProps,
    setTargettingTemplateData,
    chooseDraftOrNew,
    isTiktokPlatformLaunch,
    inputs,
    serviceRoutes,
    setVisibleModal,
    saveDraft,
    setDraftData,
    setChooseDraftOrNew,
    launchId: params?.launchId,
    bucketId: params?.bucketId,
    setTemplates,
    setVisibleChooseAdsetModal,
    platformsData,
  });
  /***
   * This function is use to save draft
   */
  function saveDraft(launch_id) {
    if (serviceType === SERVICE_TYPE_LEAD_GEN && instantLaunch) {
      callSaveLeadGenLaunchDraft({
        variables: {
          draft: {
            campaignObjectDraft: campaignObject,
            selectedTargetDraft: selectedTarget,
            targettingTemplateDataDraft: targettingTemplateData,
          },
          launchId: launch_id,
          categoryId: instantLaunchData?.id,
        },
      });
    } else {
      callSaveLaunchDraft({
        variables: {
          draft: {
            campaignObjectDraft: campaignObject,
            targettingTemplateDataDraft: targettingTemplateData,
            // for future use

            // ...(!selectedTarget
            //   ? { targettingTemplateDataDraft: targettingTemplateData }
            //   : { selectedTargetDraft: selectedTarget }),
          },
          platform_type: isTiktokPlatformLaunch ? "TIKTOK" : "FACEBOOK",
          ...(launch_id && {
            launch_id: isTiktokPlatformLaunch
              ? launch_id
              : launch_id?.match(/ObjectId\('([^']+)'\)/g)?.map((item) => {
                  return item?.match(/ObjectId\('([^']+)'\)/)[1];
                }),
          }),
          ...(instantLaunch &&
            productId === PRODUCT_ID_VS && { content_id: instantLaunchData?.id }),
        },
      });
    }
  }

  /***
   * For Fb launch
   */
  // const queryVars = {
  //   productId,
  //   categoryId,
  //   createdByMe: createdbyMe,
  //   inputs: { page: currentPage, size: pageSize },
  //   ...subCategoryId,
  //   ...(verticalId && { verticalId }),
  // };
  useEffect(() => {
    if (instantLaunch) {
      if (serviceType === SERVICE_TYPE_LEAD_GEN) {
        callLaunchLeadGenDraft();
      } else if (serviceType === SERVICE_TYPE_VS) {
        if (params?.launchId) {
          callLaunchDraft({ variables: { platform_type: platform?.toUpperCase() } });
        } else if (instantLaunchData?.id) {
          callLaunchDraft({
            variables: {
              content_id: instantLaunchData?.id,
              platform_type: PLATFORM_TYPE_FACEBOOK.toUpperCase(),
            },
          });
        } else {
          callContent({ variables: { id: instantLaunchData?.id } });
        }
      }
    }
  }, [instantLaunch, chooseDraftOrNew]);

  useEffect(() => {
    if (params?.bucketId) {
      callBucket({ variables: { bucket_id: params?.bucketId } });
    }
    //  else if (params?.categoryId) {
    //   callTiktokTemplatesForDropdown();
    // }
  }, [params?.bucketId]);

  /**
   * For keyword group Launch, we are using these apis acc to selection of nonadgroup field
   */

  useEffect(() => {
    if (bucketData?.bucket_content && bucketData?.bucket_content?.length > 0) {
      if (serviceType === SERVICE_TYPE_VS) {
        if (bucketData?.content_type === CONTENT_TYPE_ARTICLE) {
          callContent({
            variables: { id: bucketData?.bucket_content?.map(({ content_id }) => content_id) },
          });
        }
        call_vsBrandGetByDomain({ variables: { domain: bucketData?.domain } });
      }
      if (serviceType === SERVICE_TYPE_SAS) {
        if (bucketData?.content_type === CONTENT_TYPE_CATEGORY) {
          callCategories({
            variables: { id: bucketData?.bucket_content?.map(({ content_id }) => content_id) },
          });
        }
        if (bucketData?.content_type === CONTENT_TYPE_KEYWORD) {
          callKeywords({
            variables: {
              id: bucketData?.bucket_content?.map(({ sub_content_id }) => sub_content_id),
            },
          });
        }
      }
    }
  }, [bucketData]);

  useEffect(() => {
    if (visibleTargetModal) {
      if (isTiktokPlatformLaunch) {
        refetch_tiktok_templates();
      } else if (bucketData?.bucket_platform?.nickname === "facebook") {
        refetch_templates();
      }
    }
  }, [visibleTargetModal]);

  /*
  Setting initial campaign object 
  */
  // if (bucketData?.adSet_count > 1) {
  //   campa
  //   copyCampaign(campaignObject?.campaignIndex);
  // }

  /***
   * This function is for campaign, adsets and ads nomenclature
   */
  function parseNomenclature(name, extraKeys = {}) {
    const {
      conntentNikName,
      campaignIndex,
      adSetIndex,
      adsIndex,
      alias = "",
      domainNickName = "",

      geo_locations,
      isManual,
      device_platforms,
    } = extraKeys;
    let parseName = name;

    if (isManual || device_platforms?.length > 0) {
      if (device_platforms?.length > 1) {
        /**
         * if mobile and desktop both selected
         */
        parseName = parseName?.replaceAll(`{device}`, "a");
      } else {
        if (device_platforms?.[0] === "mobile") {
          parseName = parseName?.replaceAll(`{device}`, "m");
        }
        if (device_platforms?.[0] === "desktop") {
          parseName = parseName?.replaceAll(`{device}`, "d");
        }
      }
    } else {
      parseName = parseName?.replaceAll(`{device}`, "a");
    }

    //CURRENT VS

    // WILL ADD CONTITION

    parseName = parseName?.replaceAll(`{user}`, vsLoginUser?.nickname);
    if (conntentNikName) {
      parseName = parseName?.replaceAll(`{article}`, conntentNikName);
    }

    if (campaignIndex) {
      parseName = parseName?.replaceAll(`{campaignid}`, "c" + campaignIndex);
    }
    if (adSetIndex) {
      parseName = parseName?.replaceAll(`{adsetid}`, "a" + adSetIndex);
    }
    if (adsIndex) {
      parseName = parseName?.replaceAll(`{adid}`, "v" + adsIndex);
    }
    if (alias) {
      parseName = parseName?.replaceAll(`{alias}`, alias);
    }

    if (geo_locations) {
      if (geo_locations?.countries?.length > 1 || geo_locations?.country_groups?.length > 0) {
        parseName = parseName?.replaceAll(`{country}`, "ww");
      } else {
        parseName = parseName?.replaceAll(`{country}`, geo_locations?.countries?.[0]);
      }
    }

    // if (targettingTemplateData?.platform?.name) {
    //   parseName = parseName?.replaceAll(`{device}`, targettingTemplateData?.platform?.name);
    // }

    if (serviceType === SERVICE_TYPE_VS) {
      if (vsBrandByDomains[0]?.nickname) {
        parseName = parseName?.replaceAll(`{brand}`, vsBrandByDomains[0]?.nickname);
      }
      if (instantLaunch && domainNickName) {
        parseName = parseName?.replaceAll(`{brand}`, domainNickName);
      }
    }

    return parseName?.toLowerCase();
  }

  const media = [];
  const mediaVideoThumb = [];
  const mediaVideo = [];

  /***
   * This function is to set campaign objects for tiktok
   */

  function generateTiktokCampaignObject(
    advertiserId,
    pixel,
    identityId,
    identityType,
    pageId,
    campCounter = 1,
    url
  ) {
    let totalCampaigns = 0,
      totalAdsets = 0,
      totlaDynamic = 0,
      totalAds = 0,
      totlaBudget = 0;
    let isPage_Id = false;

    const campaigns = campaignObject?.map((campaignObjectData, campaignIndex) => {
      const { name, cType, adSets, objectiveType, specialIndustries, id, budget, budgetMode } =
        campaignObjectData;
      totalCampaigns++;
      // const links = url;
      const isCampaignLevelBudget =
        cType === C_TYPE_CBO
          ? { budget: budget || 50, budgetMode }
          : { budgetMode: "BUDGET_MODE_INFINITE" };

      const adSetDataArray = adSets?.map((adSetDataObject, adSetIndex) => {
        const {
          ads = {},
          adCreatives = {},
          cta,
          budgetMode,
          budget,
          bid,
          template = {},
          bidType,
          conversionBidPrice,
          scheduleEndTime,
          scheduleStartTime,
          scheduleType,
          creativeMaterialMode,
          interestCategoryIds,
          deeplink,
          appName,
          deeplinkType,
          avatarIconWebUri,
        } = adSetDataObject;
        totalAdsets++;

        const {
          age_groups,
          pacing,
          household_income,
          operating_systems,
          placement_type,
          billing_event,
          placements,
          promotion_target_type,
          promotion_type,
          spending_power,
          optimizationEvent,
          optimizationGoal,
          languages,
        } = template;
        if (promotion_target_type === "INSTANT_PAGE") {
          isPage_Id = true;
        }

        if (creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM) {
          totlaDynamic++;
        }
        const isAdSetLevelBudget = cType === C_TYPE_ABO ? { budget: budget || 20 } : {};
        const isAdSetLevelBudgetMode = cType === C_TYPE_ABO ? { budgetMode } : {};

        const { title, image, video } = adCreatives;

        let titles = [];
        title?.map((item) => {
          return titles.push({ title: item });
        });

        const mainUrl = bucketData?.urls?.filter(({ content_id }) => content_id === id)[0]?.url;

        // const link = instantLaunch && url ? url : mainUrl + pixel;
        const link = instantLaunch ? {} : mainUrl + pixel;
        let mediaInfoList = [];

        if (image?.length > 0) {
          image?.forEach(({ s3Url, name }) => {
            media.push({ imageUrl: s3Url, ...(name && { imageName: name }) });
          });

          mediaInfoList = [
            {
              mediaInfo: {
                imageInfo: image?.map(({ s3Url }) => ({
                  webUri: s3Url,
                })),
              },
            },
          ];
        } else if (video?.length > 0) {
          video?.forEach(({ s3Url, name, thumbUrl }) => {
            mediaVideo.push({
              videoUrl: s3Url,
              ...(name && { videoName: name }),
            });
            media.push({ imageUrl: thumbUrl });
            mediaInfoList.push({
              mediaInfo: { imageInfo: [{ webUri: thumbUrl }], videoInfo: { videoId: s3Url } },
            });
          });
        }
        const commonObject = {
          dynamicCreatives: {
            titleList: titles,
            commonMaterial: {
              identityId,
              identityType,
              isSmartCreative: creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM,
              adName: "",
            },
            ...(promotion_target_type === "INSTANT_PAGE"
              ? { pageList: [pageId] }
              : instantLaunch
              ? { landingPageUrls: [] }
              : { landingPageUrls: [{ landingPageUrl: link }] }),
            ...(cta && { callToActionList: [{ callToAction: cta }] }),
            mediaInfoList,
          },
        };

        const adGroupAds = ads?.map((adGroupAdsDataObject, adsIndex) => {
          const { title, image, video, id } = adGroupAdsDataObject;
          if (image && creativeMaterialMode === "CUSTOM") {
            media.push({ imageUrl: image?.s3Url, ...(image?.name && { imageName: image?.name }) });
          }

          const isImageObject = image
            ? {
                creatives: {
                  adFormat: "SINGLE_IMAGE",
                  adText: title?.title,
                  avatarIconWebUri,
                  ...(cta && { callToAction: cta }),
                  deeplink,
                  deeplinkType,
                  appName,
                  adName: "",
                  identityId,
                  identityType,
                  creativeGroupId: id,
                  ...(promotion_target_type === "INSTANT_PAGE"
                    ? pageId
                    : instantLaunch
                    ? {}
                    : // ? { landingPageUrls: [{ landingPageUrl: link }] }
                      { landingPageUrl: link }),

                  imageUrls: [image?.s3Url],

                  // landingPageUrl: link,
                },
              }
            : {};
          if (video && creativeMaterialMode === "CUSTOM") {
            mediaVideo.push({
              videoUrl: video?.s3Url,
              ...(video?.name && { videoName: video?.name }),
            });
            media.push({ imageUrl: video?.thumbUrl });
          }
          const isVideObject = video
            ? {
                creatives: {
                  videoUrl: video?.s3Url,
                  ...(video?.thumbUrl && { imageUrls: [video?.thumbUrl] }),
                  adFormat: "SINGLE_VIDEO",
                  adText: title?.title,
                  avatarIconWebUri,
                  ...(cta && { callToAction: cta }),
                  deeplink,
                  adName: "",
                  deeplinkType,
                  identityId,
                  creativeGroupId: id,
                  identityType,
                  ...(promotion_target_type === "INSTANT_PAGE"
                    ? pageId
                    : instantLaunch
                    ? {}
                    : // ? { landingPageUrls: [{ landingPageUrl: link }] }
                      { landingPageUrl: link }),
                },
              }
            : {};
          if (avatarIconWebUri) media.push(avatarIconWebUri);

          const renderAdGroupAdsData = { ...isImageObject, ...isVideObject };
          // AD
          return renderAdGroupAdsData;
        });
        const adsType =
          creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM
            ? { ads: [commonObject] }
            : { ads: adGroupAds };

        totalAds = totalAds + adsType?.ads?.length;
        const countries = template?.location?.filter((item) => item?.level === "countries") || [];

        const states = template?.location?.filter((item) => item?.level === "states") || [];
        const cities = template?.location?.filter((item) => item?.level === "cities") || [];

        const finalLocationId = countries?.flatMap((item) => {
          const existingIndex = states?.findIndex((state) => item?.id === state?.parent_id);
          if (existingIndex === -1) {
            return item?.id;
          } else {
            return states
              ?.filter((state) => state?.parent_id === item?.id)
              ?.flatMap((state) => {
                const isExistingIndex = cities?.findIndex((city) => city?.parent_id === state?.id);
                if (isExistingIndex === -1) {
                  return state?.id;
                } else {
                  return cities
                    ?.filter((city) => city?.parent_id === state?.id)
                    ?.flatMap((city) => city?.id);
                }
              });
          }
        });

        const renderAdsetData = {
          adgroupName: name,
          creativeMaterialMode,
          ...(optimizationGoal && { optimizationGoal }),
          // adgroupName: name,
          ...(bidType && { bidType }),
          ...(conversionBidPrice && { conversionBidPrice }),
          pixelId: pixel,
          pacing,
          ...(optimizationEvent && { optimizationEvent }),
          operatingSystems: operating_systems,
          ...(scheduleType && { scheduleType }),
          ...(interestCategoryIds && { interestCategoryIds }),
          ...(billingEvent && { billingEvent: billing_event }),
          // ...template,

          locationIds: finalLocationId,
          ...(!specialIndustries?.length > 0 && { ageGroups: age_groups }),
          spendingPower: spending_power,
          promotionType: promotion_type,
          ...(promotion_target_type && { promotionTargetType: promotion_target_type }),
          ...(household_income?.length > 0 && { householdIncome: household_income }),
          placementType: placement_type,
          ...(placements?.length > 0 && { placements: placements }),
          languages,
          // advertiserId,
          // name: adsetName,
          // cta,

          // ...(bid && { bid }),
          ...(cType === C_TYPE_ABO && bid && { bidPrice: parseFloat(bid) }),

          // targeting: AdSetTargeting,
          scheduleStartTime: scheduleStartTime
            ? moment(scheduleStartTime)?.format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          scheduleEndTime: scheduleEndTime
            ? moment(scheduleEndTime)?.format("YYYY-MM-DD HH:mm:ss")
            : undefined,
          ...isAdSetLevelBudget,
          ...isAdSetLevelBudgetMode,
          ...adsType,
        };
        // delete renderAdsetData?.location;
        // AD SET
        return renderAdsetData;
      });
      const renderCamapignData = {
        campaignName:
          serviceType !== SERVICE_TYPE_LEAD_GEN
            ? `${name} ${campaignIndex + campCounter}`
            : `${leadgen_name}-c${campaignIndex + campCounter}`,
        budgetOptimizeOn: cType === C_TYPE_CBO ? true : false,
        objectiveType,
        ...(specialIndustries?.length > 0 && { specialIndustries }),
        ...isCampaignLevelBudget,

        adSets: adSetDataArray,
      };

      // CAMPAIGN
      return renderCamapignData;
    });

    return {
      campaigns,
      totalCampaigns,
      totalAdsets,
      totlaDynamic,
      totalAds,
      totlaBudget,
      isPage_Id,
    };
  }
  /***
   * This function is to set campaign objects for fb platform.
   */
  function generateCampaignObject(
    adAccountNikName = "",
    pageId,
    pixel,
    epcKey,
    campCounter = 1,
    url,
    beneficiary,
    payor,
    launchStatus = "PAUSED",
    alias,
    domainNickName
  ) {
    let totalCampaigns = 0,
      totalAdsets = 0,
      totlaDynamic = 0,
      totalAds = 0,
      totlaBudget = 0,
      showBeneficiary = false,
      isCountryGroup = false;
    const edited = campaignObject?.[0]?.edited;
    let campaigns;
    if (!isTiktokPlatformLaunch) {
      campaigns = campaignObject?.map((campaignObjectData, campaignIndex) => {
        const {
          name,
          objective,
          cType,
          budget_type,
          budget,
          adSets,
          conntentNikName,
          language_code,
          specialAdCategories,
          specialAdCategoryCountry,
          bidStrategy: campbidStrategy,
          slug,
          id,
        } = campaignObjectData;
        totalCampaigns++;
        let finalSpecialAdCategoryCountry = specialAdCategoryCountry;
        const links =
          serviceType === SERVICE_TYPE_LEAD_GEN &&
          url?.filter((item) => item?.name === name)?.map((i) => i?.links);
        /***
         * This section is manipulating the url in launch level in case of Leadgen or SAS service
         */

        const campaignLevelBudgetType =
          budget_type === BUDGET_TYPE_DAILY ? { dailyBudget: budget } : { lifetimeBudget: budget };
        const isCampaignLevelBudget =
          cType === C_TYPE_CBO ? { ...campaignLevelBudgetType, bidStrategy: campbidStrategy } : {};
        if (cType === C_TYPE_CBO) {
          totlaBudget = totlaBudget + budget;
        }

        const adSetDataArray = adSets?.map((adSetDataObject, adSetIndex) => {
          const {
            ads = [],
            adCreatives = {},
            isDynamicCreative = false,
            name: adsetName,
            targeting = {},
            billingEvent,
            dof,
          } = adSetDataObject;
          totalAdsets++;
          if (isDynamicCreative) {
            totlaDynamic++;
          }
          if (cType === C_TYPE_ABO) {
            totlaBudget = totlaBudget + budget;
          }
          const {
            cta,
            budget: adSetBudget,
            budget_type: adSetBudget_type,
            bidStrategy,
            bid,
            template = {},
            isManual,
            destinationType,
          } = targeting;

          const {
            age_min,
            age_max,
            genders,
            excluded_geo_locations,
            publisher_platforms,
            device_platforms = [],
            messenger_positions = [],
            audience_network_positions = [],
            instagram_positions = [],
            facebook_positions = [],
            locales,
            user_os,
            geo_locations,
          } = template;

          const manualProps =
            isManual || device_platforms?.length > 0
              ? {
                  devicePlatforms: device_platforms,
                  facebookPositions: facebook_positions,
                  messengerPositions: messenger_positions,
                  audienceNetworkPositions: audience_network_positions,
                  instagramPositions: instagram_positions,
                  userOs: user_os,
                }
              : {};

          const adSetLevelIsManulAndDevice = { isManual, device_platforms };

          const AdSetTargeting = {
            ageMin: age_min,
            ageMax: age_max,
            genders: genders?.[0] === "All" ? [] : genders,
            // geoLocations: geo_locations,
            ...(geo_locations?.countries?.length > 0
              ? { geoLocations: geo_locations }
              : geo_locations?.country_groups?.length > 0 && {
                  geoLocations: { countryGroups: geo_locations?.country_groups },
                }),
            excludedGeoLocations:
              serviceType === SERVICE_TYPE_LEAD_GEN
                ? {
                    ...excluded_geo_locations,
                    regions: excluded_geo_locations?.regions?.map((item) => ({
                      key: item?.key,
                    })),
                  }
                : excluded_geo_locations,
            publisherPlatforms: publisher_platforms,
            locales: locales?.map((v) => parseInt(v)),
            // ...(country_groups?.length > 0 && { countryGroups: country_groups }),
            ...manualProps,
          };

          const adSetLevelBudgetType =
            adSetBudget_type === BUDGET_TYPE_DAILY
              ? { dailyBudget: adSetBudget }
              : { lifetimeBudget: adSetBudget };
          const isAdSetLevelBudget =
            cType === C_TYPE_ABO ? { ...adSetLevelBudgetType, bidStrategy } : {};

          const { title, body, description, image, video } = adCreatives;
          let images = [];
          let titles = [];
          let bodies = [];
          let videos = [];
          let descriptions = [];
          image?.map(({ s3Url }) => {
            if (isDynamicCreative) {
              media.push(s3Url);
            }
            return images.push({ media_url: s3Url });
          });

          video?.map(({ s3Url, thumbUrl }) => {
            if (isDynamicCreative) {
              media.push(s3Url);
              if (thumbUrl) {
                mediaVideoThumb.push(thumbUrl);
              }
            }
            return videos.push({ media_url: s3Url, thumbnail_url: thumbUrl });
          });
          title?.map((item) => {
            return titles.push({ text: item });
          });
          body?.map((item) => {
            return bodies.push({ text: item });
          });
          description?.map((item) => {
            return descriptions.push({ text: item });
          });

          const ispixelType = pixel ? epcKey + pixel : "";
          let mainUrl = instantLaunch
            ? vsUrlPlatform?.[0]?.url_structure
            : bucketData?.urls?.filter(({ content_id }) => content_id === id)[0]?.url;
          let parseUrl = mainUrl ? new URL(mainUrl) : "";

          /***
           * For SAS service we replace host,pixel of url on launch level
           */

          if (serviceType === SERVICE_TYPE_SAS && parseUrl) {
            const queryParams = new URLSearchParams(parseUrl.search);
            if (queryParams.get("pixel") && pixel) {
              queryParams.set("pixel", pixel);
              parseUrl.search = queryParams.toString();
            }
            mainUrl = parseUrl.toString();
            // mainUrl = pixel ? mainUrl.replace(/pixel=[^&]+/, "pixel=" + pixel) : mainUrl;
          }
          if (
            (serviceType === SERVICE_TYPE_SAS || serviceType === SERVICE_TYPE_VS) &&
            url &&
            mainUrl
          ) {
            if (url.includes("https://")) {
              mainUrl = mainUrl.replace(/^(https?:\/\/)[^/]+/, `${url}`); // Replace mainUrl with the value of url when url includes "https://"
            } else {
              mainUrl = mainUrl.replace(/^(https?:\/\/)[^/]+/, `$1${url}`);
            }
          }
          const link =
            serviceType === SERVICE_TYPE_LEAD_GEN
              ? links?.[0]
              : serviceType === SERVICE_TYPE_SAS
              ? urlSplited
                ? parseUrl?.origin + parseUrl?.pathname
                : mainUrl
              : mainUrl + ispixelType;
          const searchString = parseUrl && parseUrl.search;
          const searchWithoutQuestionMark =
            searchString && searchString.startsWith("?") ? searchString.slice(1) : searchString;
          const url_tags =
            serviceType === SERVICE_TYPE_LEAD_GEN
              ? url?.filter((item) => item?.name === name)?.map((i) => i?.params)?.[0]
              : serviceType === SERVICE_TYPE_SAS && urlSplited
              ? searchWithoutQuestionMark
              : "";
          let mediaSelection,
            adFormats = [];
          if (image?.length > 0) {
            image?.forEach(({ s3Url }) => {
              media.push(s3Url);
            });
            mediaSelection = { images: image?.map(({ s3Url }) => ({ media_url: s3Url })) };
            adFormats = ["SINGLE_IMAGE"];
          } else if (video?.length > 0) {
            video?.forEach(({ s3Url, thumbUrl }) => {
              media.push(s3Url);
              mediaVideoThumb.push(thumbUrl);
            });
            mediaSelection = { videos };
            adFormats = ["SINGLE_VIDEO"];
          }
          let adName = "";

          if (serviceType === SERVICE_TYPE_VS) {
            adName = parseNomenclature(
              instantLaunch ? ad_vs_nomenclature : bucketData?.ad_nomenclature,
              {
                conntentNikName: conntentNikName + language_code,
                campaignIndex: campaignIndex + campCounter,
                adSetIndex: adSetIndex + 1,
                adsIndex: 1,
                alias,
                domainNickName,

                geo_locations,
                ...adSetLevelIsManulAndDevice,
              }
            )?.replaceAll("{adacc}", adAccountNikName);
          }
          if (serviceType === SERVICE_TYPE_SAS) {
            adName = isSasKeywordGroup
              ? `${inputs.utm_name} ${name}-c${campaignIndex + campCounter}-a${
                  campCounter + adSetIndex
                }-v1`
              : `${name}-c${campaignIndex + campCounter}-a${campCounter + adSetIndex}-v1`;
          }
          if (serviceType === SERVICE_TYPE_LEAD_GEN) {
            adName = `${leadgen_name}-c${campaignIndex + campCounter}-a${adSetIndex + 1}-v1`;
          }
          const commonObject = {
            status: launchStatus,
            name: adName,
            creative: {
              ...(dof && {
                degreesOfFreedomSpec: {
                  creativeFeaturesSpec: {
                    standardEnhancements: {
                      enrollStatus: dof,
                    },
                  },
                },
              }),
              assetFeedSpec: {
                titles: titles,
                bodies: bodies,
                descriptions: descriptions,
                callToActionTypes: cta,
                linkUrls: [{ website_url: link }],
                adFormats,
                ...mediaSelection,
              },
              ...(url_tags && { url_tags }),
            },
          };

          const adGroupAds = ads?.map((adGroupAdsDataObject, adsIndex) => {
            const {
              title = {},
              body = {},
              description = {},
              image = {},
              video = {},
              id,
            } = adGroupAdsDataObject;
            // console.log(adGroupAdsDataObject);

            let adName = "";

            if (serviceType === SERVICE_TYPE_VS) {
              adName = parseNomenclature(
                instantLaunch ? ad_vs_nomenclature : bucketData?.ad_nomenclature,
                {
                  conntentNikName: conntentNikName + language_code,
                  campaignIndex: campaignIndex + campCounter,
                  adSetIndex: adSetIndex + campCounter,
                  adsIndex: adsIndex + campCounter,
                  alias,
                  domainNickName,

                  geo_locations,
                  ...adSetLevelIsManulAndDevice,
                }
              )?.replaceAll("{adacc}", adAccountNikName);
            }
            if (serviceType === SERVICE_TYPE_SAS) {
              adName = `${name}-c${campaignIndex + campCounter}-a${adSetIndex + 1}-v${
                adsIndex + 1
              }`;
            }
            if (serviceType === SERVICE_TYPE_LEAD_GEN) {
              adName = `${leadgen_name}-c${campaignIndex + campCounter}-a${adSetIndex + 1}-v${
                adsIndex + 1
              }`;
            }
            const commonObject = {
              status: launchStatus,
              name: adName,
            };

            const commonData = {};

            if (image && !isDynamicCreative) {
              media.push(image?.s3Url);
            }

            const isImageObject =
              image && Object.keys(image)?.length !== 0
                ? {
                    ...commonObject,
                    ...(inputs?.creative_auto_select && inputs?.nonAdGroup
                      ? { creativeImageId: id }
                      : { creativeGroupId: id, creativeImageId: image?.id }),
                    creative: {
                      ...(url_tags && { url_tags }),
                      ...(dof && {
                        degreesOfFreedomSpec: {
                          creativeFeaturesSpec: {
                            standardEnhancements: {
                              enrollStatus: dof,
                            },
                          },
                        },
                      }),
                      objectStorySpec: {
                        pageId,
                        linkData: {
                          ...commonData,

                          media_url: image?.s3Url,
                          message: body?.body,
                          name: title?.title,
                          description: description?.description,
                          link: link,
                          callToAction: { type: cta, value: link },
                        },
                      },
                    },
                  }
                : {};
            if (video && Object.keys(video)?.length !== 0 && !isDynamicCreative) {
              media.push(video?.s3Url);
              mediaVideoThumb.push(video?.thumbUrl);
            }
            const isVideObject =
              video && Object.keys(video)?.length !== 0
                ? {
                    ...commonObject,
                    ...(inputs?.creative_auto_select && inputs?.nonAdGroup
                      ? { creativeVideoId: id }
                      : { creativeGroupId: id, creativeVideoId: video?.id }),

                    creative: {
                      ...(dof && {
                        degreesOfFreedomSpec: {
                          creativeFeaturesSpec: {
                            standardEnhancements: {
                              enrollStatus: dof,
                            },
                          },
                        },
                      }),
                      objectStorySpec: {
                        pageId,

                        videoData: {
                          ...commonData,
                          media_url: video?.s3Url,
                          imageUrl: video?.thumbUrl,
                          message: body?.body,
                          title: title?.title,
                          linkDescription: description?.description,
                          // link: link,
                          callToAction: { type: cta, value: { link: link } },
                        },
                      },
                      ...(url_tags && { url_tags }),
                    },
                  }
                : {};

            const renderAdGroupAdsData = { ...isImageObject, ...isVideObject };
            // AD
            return renderAdGroupAdsData;
          });
          const adsType = isDynamicCreative ? { ads: [commonObject] } : { ads: adGroupAds };

          totalAds = totalAds + adsType?.ads?.length;
          let adset_Name;
          if (serviceType === SERVICE_TYPE_VS) {
            adset_Name = parseNomenclature(adsetName, {
              conntentNikName: conntentNikName + language_code,
              campaignIndex: campaignIndex + campCounter,
              adSetIndex: adSetIndex + 1,
              alias,
              domainNickName,

              geo_locations,
              ...adSetLevelIsManulAndDevice,
            })?.replaceAll("{adacc}", adAccountNikName);
          } else if (serviceType === SERVICE_TYPE_SAS) {
            // adset_Name = edited
            //   ? `${name}-a${campCounter + adSetIndex}`
            //   : `${adsetName}-c${campaignIndex + campCounter}-a${campCounter + adSetIndex}`;

            // QUICK-FIX
            adset_Name = edited
              ? `${name}-a${campCounter + adSetIndex}`
              : `${name}-c${campaignIndex + campCounter}-a${campCounter + adSetIndex}`;
          }
          if (
            AdSetTargeting?.geoLocations?.countries?.some((item) =>
              EUROPEAN_COUNTRIES.includes(item)
            ) ||
            AdSetTargeting?.geoLocations?.countryGroups?.includes("worldwide")
          ) {
            showBeneficiary = true;
          }
          if (AdSetTargeting?.geoLocations?.countryGroups?.length > 0) {
            isCountryGroup = true;
          }
          const renderAdsetData = {
            name:
              serviceType !== SERVICE_TYPE_LEAD_GEN
                ? adset_Name
                : `${leadgen_name}-c${campaignIndex + campCounter}-a${adSetIndex + 1}`,
            status: launchStatus,
            isDynamicCreative,
            optimizationGoal: targeting?.optimizationGoal,
            ...(beneficiary &&
              (AdSetTargeting?.geoLocations?.countries?.some((item) =>
                EUROPEAN_COUNTRIES.includes(item)
              ) ||
                AdSetTargeting?.geoLocations?.countryGroups?.includes("worldwide")) && {
                dsaBeneficiary: beneficiary,
              }),
            ...(payor &&
              (AdSetTargeting?.geoLocations?.countries?.some((item) =>
                EUROPEAN_COUNTRIES.includes(item)
              ) ||
                AdSetTargeting?.geoLocations?.countryGroups?.includes("worldwide")) && {
                dsaPayor: payor,
              }),

            bidAmount:
              cType === C_TYPE_ABO
                ? bidStrategy && bidStrategy !== "LOWEST_COST_WITHOUT_CAP"
                  ? bid
                  : undefined
                : campbidStrategy && campbidStrategy !== "LOWEST_COST_WITHOUT_CAP"
                ? bid
                : undefined,
            targeting: AdSetTargeting,
            startTime: targeting?.scheduledStartDate
              ? moment(targeting?.scheduledStartDate)?.format(dateFormat)
              : undefined,
            endTime: targeting?.scheduledEndDate
              ? moment(targeting?.scheduledEndDate)?.format(dateFormat)
              : undefined,
            ...isAdSetLevelBudget,
            ...adsType,
            billingEvent,
            destinationType,
          };
          // AD SET
          return renderAdsetData;
        });

        const campaignLevelIsManulAndDevice = {
          isManual: adSets[0]?.targeting?.isManual,
          device_platforms: adSets[0]?.targeting?.template?.device_platforms,
        };

        const renderCamapignData = {
          name:
            serviceType !== SERVICE_TYPE_LEAD_GEN
              ? parseNomenclature(name, {
                  conntentNikName: conntentNikName + language_code,
                  campaignIndex: campaignIndex + campCounter,
                  alias,
                  domainNickName,

                  geo_locations: adSets?.[0]?.targeting?.template?.geo_locations,
                  ...campaignLevelIsManulAndDevice,
                })?.replaceAll("{adacc}", adAccountNikName)
              : `${leadgen_name}-c${campaignIndex + campCounter}`,
          objective,
          status: launchStatus,
          ...isCampaignLevelBudget,
          adSets: adSetDataArray,
          specialAdCategories,
          specialAdCategoryCountry: finalSpecialAdCategoryCountry,
        };
        // CAMPAIGN
        return renderCamapignData;
      });
    }

    return {
      campaigns,
      totalCampaigns,
      totalAdsets,
      totlaDynamic,
      totalAds,
      totlaBudget,
      showBeneficiary,
      isCountryGroup,
    };
  }

  /***
   * This function is use to copy campaigns
   */
  function copyCampaign(camapignCopyIndex, count) {
    let copyDataData = campaignObject[camapignCopyIndex];
    let temp = cloneMainCamaignObject(campaignObject);

    let newData = campaignObject.slice(0, camapignCopyIndex + 1);

    // temp.slice(camapignCopyIndex + 1).forEach((data) => {
    //   newData.push(data);
    // });
    // let camps = newData;
    for (let i = 0; i < count; i++) {
      newData.push({ isDuplicate: true, ...copyDataData });
    }
    temp.slice(camapignCopyIndex + 1).forEach((data) => {
      newData.push(data);
    });

    setCampaignObject(newData);
  }

  /***
   * This function is to remove duplicate campaigns
   */
  function removeCampaign(camapignIndexs, isBulk = false) {
    let temp = cloneMainCamaignObject(campaignObject);

    if (!isBulk) {
      setCampaignObject(removeObjectByIndex(temp, camapignIndexs));
    } else {
      temp = temp?.filter((d, i) => {
        const isRemoveIndex = camapignIndexs.findIndex((fd) => fd === i);

        if (isRemoveIndex !== -1 && d?.isDuplicate) {
          return false;
        }
        return true;
      });
      setCampaignObject(temp);
    }
    selectedRowsClear();
  }

  /***
   * This function is to copy adset
   */
  function copyAdset(campaignIndex, adSetIndex) {
    let copyDataData = campaignObject[campaignIndex]?.adSets[adSetIndex];

    let temp = campaignObject[campaignIndex]?.adSets;
    let campaign = cloneMainCamaignObject(campaignObject);
    // let currentAdset = temp[campaignIndex]?.adSets || [];
    let newData = campaignObject[campaignIndex]?.adSets.slice(0, adSetIndex + 1);
    newData.push({ isDuplicate: true, ...copyDataData });
    temp.slice(adSetIndex + 1).forEach((data) => {
      newData.push(data);
    });
    campaign[campaignIndex] = { ...campaign[campaignIndex], adSets: newData };

    setCampaignObject(campaign);
  }

  /***
   * This function is to remove duplicate adsets
   */
  function removeAdSet(campaignIndex, adSetIndex, isBulk = false) {
    let temp = cloneMainCamaignObject(campaignObject);
    if (!isBulk) {
      temp[campaignIndex].adSets = removeObjectByIndex(temp[campaignIndex]?.adSets, adSetIndex);
    } else {
      temp[campaignIndex].adSets = temp[campaignIndex].adSets?.filter((d, i) => {
        const isRemoveIndex = adSetIndex.findIndex((fd) => fd === i);

        if (isRemoveIndex !== -1 && d?.isDuplicate) {
          return false;
        }
        return true;
      });
    }
    setCampaignObject(temp);
    selectedRowsClear();
  }

  function handleCampaignObject(campaignIndex, object, firstIndexObjectData = {}) {
    let temp = cloneMainCamaignObject(campaignObject);
    const firstKey = Object.keys(object)?.[0];
    let isSpecialAdCategories =
      firstKey === "specialAdCategories" &&
      !object[firstKey].includes("NONE") &&
      object[firstKey]?.length > 0;
    let finalObject = object;
    if (firstKey === "specialAdCategoryCountry") {
      const country = removeDuplicates(object[firstKey]);
      finalObject = { specialAdCategoryCountry: country };
    }
    if (
      isSpecialAdCategories &&
      (temp[campaignIndex]?.specialAdCategoryCountry === undefined ||
        temp[campaignIndex]?.specialAdCategoryCountry?.length === 0)
    ) {
      const country = temp[campaignIndex]?.adSets?.flatMap(
        ({ targeting }) => targeting?.template?.geo_locations?.countries
      );
      finalObject = { ...object, specialAdCategoryCountry: removeDuplicates(country) };
    }
    temp[campaignIndex] = { ...temp[campaignIndex], ...finalObject };
    temp[0] = { ...temp[0], ...firstIndexObjectData };
    setCampaignObject(temp);
  }

  function handleCampaignAdsetAdset(campaignIndex, adSetIndex, object, specialAdCategoryCountry) {
    let temp = cloneMainCamaignObject(campaignObject);

    if (specialAdCategoryCountry) {
      const country = removeDuplicates(temp[campaignIndex]?.specialAdCategoryCountry);
      temp[campaignIndex] = { ...temp[campaignIndex], ...{ specialAdCategoryCountry: country } };
    }

    let currentAdset = temp[campaignIndex]?.adSets || [];

    let currentAdsetObject = currentAdset[adSetIndex] || {};
    currentAdsetObject = { ...currentAdsetObject, ...object };
    currentAdset[adSetIndex] = currentAdsetObject;

    temp[campaignIndex] = { ...temp[campaignIndex], adSets: currentAdset };

    setCampaignObject(temp);
  }

  function handleCampaignAdPush(
    object,

    campaignIndex,
    adSetIndex,
    isTiktokAvatar = false
  ) {
    let temp = cloneMainCamaignObject(campaignObject);

    if (typeof campaignIndex === "undefined" && typeof adSetIndex === "undefined") {
      const finalCampaign = temp?.map((campaigns) => {
        return {
          ...campaigns,
          adSets:
            campaigns?.adSets?.map((adSets) => {
              let ads = adSets?.ads || [];
              return {
                ...adSets,
                isDynamicCreative: false,
                ads: [...ads, object],
              };
            }) || [],
        };
      });
      setCampaignObject(finalCampaign);
    } else {
      let currentAdset = temp[campaignIndex]?.adSets || [];
      let currentAdsetObject = currentAdset[adSetIndex] || {};
      if (isTiktokAvatar) {
        let currentAdsObject = currentAdset[adSetIndex]?.ads;
        currentAdsObject = currentAdsObject?.map((ads) => ({ ...ads, ...object }));
        currentAdsetObject = { ...currentAdsetObject, isTemp: false, ads: currentAdsObject };
      } else {
        currentAdsetObject = {
          ...currentAdsetObject,
          isNonDynamicTemp: false,
          ads: [...currentAdsetObject?.ads, object],
        };
      }
      currentAdset[adSetIndex] = currentAdsetObject;

      temp[campaignIndex] = { ...temp[campaignIndex] };
      setCampaignObject(temp);
    }
  }

  function removeObjectById(arr, id) {
    const index = arr.findIndex((obj) => obj.id === id); // Find index of the object to remove
    if (index !== -1) {
      arr.splice(index, 1); // Remove the object from the array
    }
    return arr; // Return the updated array
  }

  function removeObjectByIndex(arr, index) {
    if (index !== -1) {
      arr.splice(index, 1); // Remove the object from the array
    }
    return arr; // Return the updated array
  }

  function handleAdgroup(object, campaignIndex, adSetIndex) {
    let temp = cloneMainCamaignObject(campaignObject);
    if (typeof campaignIndex === "undefined" && typeof adSetIndex === "undefined") {
      const finalCampaign = (temp || []).map((campaigns) => ({
        ...campaigns,
        adSets: (campaigns.adSets || []).map((adSets) => {
          const adCreatives = { ...adSets?.adCreatives, ...object };

          if (adCreatives.image?.length > 0 && object?.video?.length > 0) {
            delete adCreatives.image;
          }
          if (adCreatives.video?.length > 0 && object?.image?.length > 0) {
            delete adCreatives.video;
          }

          return { ...adSets, isDynamicCreative: true, adCreatives };
        }),
      }));

      setCampaignObject(finalCampaign);
    } else {
      let currentAdset = temp[campaignIndex]?.adSets || [];

      let currentAdsetObject = currentAdset[adSetIndex] || {};
      let adCreatives = currentAdsetObject?.adCreatives;

      if (adCreatives?.image?.length > 0 && object?.video?.length > 0) delete adCreatives.image;
      if (adCreatives?.video?.length > 0 && object?.image?.length > 0) delete adCreatives.video;
      currentAdsetObject = {
        ...currentAdsetObject,
        isDynamicTemp: false,
        adCreatives: { ...adCreatives, ...object },
      };
      currentAdset[adSetIndex] = currentAdsetObject;
      temp[campaignIndex] = { ...temp[campaignIndex], adSets: currentAdset };
      // console.log(temp, "result");
      setCampaignObject(temp);
    }
  }

  function handleCampaignAdRemoveAll() {
    const camp = campaignObject?.flatMap((camps) => ({
      ...camps,
      adSets: camps?.adSets?.map((adSet) => ({
        ...adSet,
        ads: [],
        adCreatives: [],
      })),
    }));
    setCampaignObject(camp);
  }

  function handleCampaignAdRemove(id, isAll = false, campaignIndex, adSetIndex) {
    let temp = cloneMainCamaignObject(campaignObject);
    if (typeof campaignIndex === "undefined" && typeof adSetIndex === "undefined") {
      const finalCampaign = temp?.map((campaign) => ({
        ...campaign,
        adSets: campaign?.adSets?.map((adSet) => {
          const ads = isAll
            ? (adSet.ads || []).filter((data) => data?.id !== id)
            : removeObjectById(adSet.ads || [], id);

          return {
            ...adSet,
            ads,
          };
        }),
      }));
      setCampaignObject(finalCampaign);
    } else {
      let currentAdset = temp[campaignIndex]?.adSets || [];

      let currentAdsetObject = currentAdset[adSetIndex] || {};
      // currentAdsetObject = { ...currentAdsetObject, ads: [...currentAdsetObject?.ads, object] };

      let { ads = [] } = currentAdsetObject;

      /**
       * isAll use for remove all same selected ads by id
       * isAll pass when click on checkbox in card
       *
       * else remove one at a time when click on decrement button on counter in card
       */
      if (isAll) {
        currentAdsetObject = {
          ...currentAdsetObject,
          isNonDynamicTemp: false,
          ads: ads?.filter((data) => data?.id !== id),
        };
      } else {
        currentAdsetObject = {
          ...currentAdsetObject,
          isNonDynamicTemp: false,
          ads: removeObjectById(ads, id),
        };
      }
      currentAdset[adSetIndex] = currentAdsetObject;
      temp[campaignIndex] = { ...temp[campaignIndex] };

      setCampaignObject(temp);
    }
  }

  const [isInitialSetupDone, setIsInitialSetupDone] = useState(false);

  function capmaignTemplateChange() {
    /**
     * This function call when isInitialSetupDone === true
     * this use for push template to draft object data
     */

    let newCampaignObject = [];

    campaignObject?.forEach((data) => {
      const {} = data;

      const objectData = {
        ...data,
        objective: targettingTemplateData?.objective,
        bidStrategy: targettingTemplateData?.bidStrategy,
        budget_type: targettingTemplateData?.budget_type,
        budget: targettingTemplateData?.budget,
        adSets: data?.adSets?.map((adSetsData) => {
          const targeting = {
            ...targettingTemplateData,
            scheduledStartDate: "",
            scheduledEndDate: "",
            platformId: targettingTemplateData?.platform?.local_id,
          };

          return {
            ...adSetsData,
            targeting: { ...targeting, isScheduled: targeting?.scheduledStartDate },
          };
        }),
      };
      newCampaignObject.push(objectData);
    });

    setCampaignObject(newCampaignObject);
  }
  const [isTargetEdited, setIsTargetEdited] = useState(false);
  /***
   * This function handles the initial state of a campaign, determining whether it is in a draft or new launch status.
   */
  function handleDraft(targeting) {
    let campaignDraft = [];
    const mapAdsetObject = (adSetData) => {
      if (isTiktokPlatformLaunch) {
        // const { targeting = {} } = adSetData;
        const { scheduleStartTime = "", scheduleEndTime = "", ...restTargeting } = adSetData;
        const isStartData = adSetData?.scheduleStartTime
          ? { scheduleStartTime: moment(adSetData?.scheduleStartTime) }
          : {};
        const isEndData = adSetData?.scheduleEndTime
          ? { scheduleEndTime: moment(adSetData?.scheduleEndTime) }
          : {};
        return {
          ...adSetData,
          ...restTargeting,
          ...isStartData,
          ...isEndData,
        };
      } else {
        const { targeting = {} } = adSetData;
        const { scheduledStartDate = "", scheduledEndDate = "", ...restTargeting } = targeting;

        const isStartData = scheduledStartDate
          ? { scheduledStartDate: moment(scheduledStartDate) }
          : {};

        const isEndData = scheduledEndDate ? { scheduledEndDate: moment(scheduledEndDate) } : {};

        return {
          ...adSetData,
          targeting: {
            ...restTargeting,
            ...isStartData,
            ...isEndData,
          },
        };
      }
    };
    if (
      draftData?.success_camps_indexes &&
      draftData?.success_camps_indexes?.length > 0 &&
      queryParams?.get("status")
    ) {
      if (JSON.parse(queryParams?.get("status")) === LAUNCH_STATUS_HAVE_FAILURE) {
        const finalData = draftData?.draft?.campaignObjectDraft;
        const newData = finalData?.map((data, index) => {
          const { adSets = [] } = data;
          return {
            isSuccessCampaign: draftData?.success_camps_indexes?.includes(index),
            ...data,
            adSets: adSets.map((adSetData) => mapAdsetObject(adSetData)),
          };
        });

        campaignDraft = newData;
      }
      if (JSON.parse(queryParams?.get("status")) === LAUNCH_STATUS_HAVE_FAILURE_RETRY) {
        const finalData = draftData?.draft?.campaignObjectDraft;
        const newData = finalData
          ?.filter((data, index) => !draftData?.success_camps_indexes?.includes(index))
          .map((data, index) => {
            const { adSets = [] } = data;
            return {
              ...data,
              isDuplicate: undefined,
              adSets: adSets.map((adSetData) => mapAdsetObject(adSetData)),
            };
          });

        campaignDraft = newData;
      }
    } else {
      const finalDraftData = draftData?.draft?.campaignObjectDraft;
      campaignDraft = finalDraftData?.map((data, index) => {
        const { adSets = [] } = data;
        return {
          ...data,
          adSets: adSets?.map((adSetData) => {
            const {
              ads = [],
              adCreatives = [],
              isDynamicCreative = false,
              creativeMaterialMode,
              ...adSetswithOutAds
            } = adSetData || [];
            const finalTargeting = targeting ? targeting : adSetswithOutAds;
            return mapAdsetObject({
              ...finalTargeting,
              ads,
              adCreatives,
              isDynamicCreative,
              creativeMaterialMode,
            });
          }),
        };
      });
    }
    return campaignDraft;
  }
  const draftByService = () => {
    if (isInitialSetupDone && targettingTemplateData) {
      if (isTargetEdited && !(loading_Template || LoadingTiktokTemplate)) {
        // capmaignTemplateChange();
      }
    } else {
      /**
       * * adSetData
       * parse START/END Date by momnet
       */
      setCampaignObject(handleDraft());

      // setIsInitialSetupDone(true);
    }
  };
  useEffect(() => {
    if (chooseDraftOrNew === CONTINUES_DRAFT) {
      draftByService();
    }
  }, [chooseDraftOrNew]);

  useEffect(() => {
    if (chooseDraftOrNew === CONTINUES_DRAFT) {
      // if (!instantLaunch) draftByService();
    } else if (
      chooseExistingTemplate === CHOOSE_EXISTING_TEMPLATE &&
      campaignObject?.length === 0
    ) {
      /***
       * This section handles the initial state of a campaign for the SAS service type, based on whether it is set to auto select or manual.
       */
      if (serviceType === SERVICE_TYPE_SAS) {
        if (bucketData && targettingTemplateData) {
          if (
            inputs?.creative_auto_select &&
            campaignObject?.length === 0 &&
            (selectedImage?.length > 0 || adGroupDataSource?.length > 0)
          ) {
            initialSetCampaign({ ...initialSetCampaignProps });
          } else if (
            !inputs?.creative_auto_select &&
            !visibleTargetModal &&
            campaignObject?.length === 0
          ) {
            initialSetCampaign({ ...initialSetCampaignProps });
          }
        }
      }
      if (serviceType === SERVICE_TYPE_VS) {
        if (
          (campaignObject?.length === 0 &&
            bucketData &&
            contentContentData &&
            targettingTemplateData &&
            !visibleTargetModal) ||
          (instantLaunch && !visibleTargetModal)
        ) {
          initialSetCampaign({ ...initialSetCampaignProps });
        }
      }
      if (serviceType === SERVICE_TYPE_LEAD_GEN) {
        if (
          bucketData &&
          targettingTemplateData &&
          !visibleTargetModal &&
          campaignObject?.length === 0
        ) {
          initialSetCampaign({ ...initialSetCampaignProps });
        } else if (
          instantLaunch &&
          targettingTemplateData &&
          !visibleTargetModal &&
          campaignObject?.length === 0
        ) {
          initialSetCampaign({ ...initialSetCampaignProps });
        }
      }
    }
  }, [
    chooseDraftOrNew,
    bucketData,
    contentContentData,
    targettingTemplateData,
    selectedImage,
    adGroupDataSource,
    inputs,
    visibleTargetModal,
    isTargetEdited,
  ]);

  function handleSelectTarget(e) {
    setSelectedTarget(e);
  }
  function submitGroupModal() {
    if (visibleGroupModal && (isSasKeywordGroup || isSasCampaign)) {
      if (inputs?.creative_auto_select) {
        const creative_inputs = bucketData?.bucket_content?.map((elem) => ({
          categoryId: elem?.sub_content_id,
          ...(isSasCampaign && { categoryName: elem?.sub_content_name }),
          productId: productId,
          ...(isSasCampaign ? {} : { keywordName: elem?.name }),
          ...(isSasCampaign ? {} : { keywordId: elem?.content_id }),
          creativeCount: inputs?.ad_count * inputs?.adset_count,
        }));
        /***
         * This section fetches images/adgroups based on nonAdgroup field.
         */
        if (inputs?.nonAdGroup) {
          fetchCreatives_Images({
            variables: { inputs: creative_inputs, adgroup: !inputs?.nonAdGroup },
          });
        } else {
          fetchCreatives_Groups({
            variables: { inputs: creative_inputs, adgroup: !inputs?.nonAdGroup },
          });
        }
      } else {
        setVisibleGroupModal(false);
        setVisibleTargetModal(false);
      }
    }
  }

  function submitSelectTarget() {
    /***
     * This section fetches templates based on the platform.
     */
    if (isTiktokPlatformLaunch) {
      fetchTiktokTemplate({ variables: { templateId: selectedTarget } });
    } else {
      /***
       * For SAS campaigns/groups launched with auto-select, we fetch creatives here. Otherwise, we fetch templates.
       */
      callTemplate({ variables: { templateId: selectedTarget } });
    }
  }

  const closeTargetModal = () => setVisibleTargetModal(false);
  const onCancel = () => {
    setVisibleTargetModal(false);

    if (chooseDraftOrNew !== CONTINUES_DRAFT) {
      history.goBack();
    }
  };

  const addNewBucketModalProps = {
    title: "Launch",
    visible: visibleTargetModal,
    footer: null,
    onCancel: onCancel,

    maskClosable: false,
    loader: loading_Template,
    disabled: chooseDraftOrNew === "",
  };
  /**
   * This function is to choose Draft
   */
  function chooseDraft() {
    closeTargetModal();
    setChooseDraftOrNew(CONTINUES_DRAFT);
    if (draftData?.draft?.selectedTargetDraft) {
      setSelectedTarget(draftData?.draft?.selectedTargetDraft);
      if (isTiktokPlatformLaunch) {
        fetchTiktokTemplate({
          variables: {
            templateId: draftData?.draft?.selectedTargetDraft,
          },
        });
      } else {
        callTemplate({ variables: { templateId: draftData?.draft?.selectedTargetDraft } });
      }
    } else {
      setTargettingTemplateData(draftData?.draft?.targettingTemplateDataDraft);
    }
  }
  function chooseContinuseNew() {
    setChooseDraftOrNew(CONTINUES_NEW);
    setVisibleChooseAdsetModal(true);
  }

  function chooseAdsetTemplate() {
    setVisibleChooseAdsetModal(false);
    setChooseExistingTemplate(CHOOSE_EXISTING_TEMPLATE);
    if (isTiktokPlatformLaunch) {
      callTiktokTemplatesForDropdown();
    } else {
      callTemplatesForDropdown();
    }
  }

  /**
   * chooseReLaunchDraft - CONTINUES_RELAUNCH_FULL/CONTINUES_RELAUNCH_ONLY_FAILED
   */

  useEffect(() => {
    if (!launchDraft_loading && params?.launchId && draftData) {
      chooseDraft();
    }
  }, [launchDraft_loading, draftData]);

  /**
   * * filterCampaignShowsOnlyFaild
   * this function alows to filter campings on relaunch time
   * by this only shows only failed campaigns
   *
   */

  const [copySuccessCampaign, setCopySuccessCampaign] = useState([]);

  function filterCampaignShowsOnlyFailed() {
    const tempData = cloneMainCamaignObject(campaignObject);

    const successData = tempData?.filter(({ isSuccessCampaign = false }) => isSuccessCampaign);
    const failedData = tempData?.filter(({ isSuccessCampaign = false }) => !isSuccessCampaign);
    setCopySuccessCampaign(successData);
    setCampaignObject(failedData);
  }

  function getBackFilteredSuccessCampaigns() {
    const tempData = [...copySuccessCampaign];

    setCopySuccessCampaign([]);
    setCampaignObject((pre) => [...tempData, ...pre]);
  }

  useEffect(() => {
    const adAccountData = data_adAccount?.tiktok_getAccounts?.map((item) => item);
    if (isTiktokPlatformLaunch) {
      setMultiAdaccount_data(adAccountData);
    }
  }, [data_adAccount]);

  function callEditTemplate() {
    setVisibleTargetModal(true);
  }
  const client = useApolloClient();
  const fetchData = async (PAYLOAD) => {
    try {
      const response = await client.query({
        query: FB360_GET_AD_ACCOUNTS,
        variables: { profile_id: PAYLOAD },
      });

      return response.data?.fb360_adaccountGet;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const fetchAllData = (profile_id) => {
    const dataPromises = profile_id
      ?.filter((profileId) => !multiAdaccount_data.some(({ id }) => id === profileId))
      ?.map((payload) => fetchData(payload));

    Promise.all(dataPromises)
      .then((allData) => {
        const payloadResponsePairs = profile_id
          ?.filter((profileId) => !multiAdaccount_data.some(({ id }) => id === profileId))
          ?.map((payload, index) => {
            return {
              id: payload,
              res: allData[index],
            };
          });

        setMultiAdaccount_data((prevItems) => [...prevItems, ...payloadResponsePairs]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const [
    getFacebookProfiles,
    { data: profileData, refetch: profileRefetch, loading: profileLoading },
  ] = useCustomLazyQuery(FB360_GET_PROFILES, {
    onCompleted: (e) => {
      setProfileId([e?.fb360_profileGet[0]?.id]);
      setSelectedProfileIds([e?.fb360_profileGet[0]?.id]);
      fetchAllData([e?.fb360_profileGet[0]?.id]);
    },
  });

  const contextData = {
    bucketData,
    chooseDraftOrNew,
    productId,
    campaignObject,
    fetchtikTokAdaccount,
    data_adAccount,
    fetchAllData,
    urlSplited,
    setUrlSplited,
    getFacebookProfiles,
    profileLoading,
    profileRefetch,
    profileData,
    loading_adAccount,
    handleCampaignObject,
    copyCampaign,
    removeCampaign,
    handleCampaignAdsetAdset,
    handleCampaignAdPush,
    handleCampaignAdRemove,
    contentContentData,
    handleSelectTarget,
    selectedTarget,
    setCampaignObject,
    refetch_adAccount,
    copyAdset,
    removeAdSet,
    handleAdgroup,
    setAdGroupData,
    adGroupData,
    launchDataAdAccount_data,
    submitLaunch,
    isTiktokPlatformLaunch,
    vsUrlPlatform,
    generateCampaignObject,
    generateTiktokCampaignObject,
    saveDraft,
    visibleGroupModal,
    leadgen_name,
    targettingTemplateData,
    setLeadgen_name,
    serviceType,
    chooseDraft,
    visibleModal,
    setVisibleModal,
    chooseContinuseNew,
    callEditTemplate,
    launchDraft_loading,
    draftData,
    copySuccessCampaign,
    filterCampaignShowsOnlyFailed,
    getBackFilteredSuccessCampaigns,
    loading_adgroups,
    loading_tiktok_templates,
    templates,
    formData,
    setFormData,
    setVisibleTargetModal,
    loading_templates,
    fetchadAccountCache,
    adAccountCache,
    sasLaunch_loading,
    tiktokLaunch_loading,
    launchDataAdAccountCall,
    launchDataAdAccount_loading,
    launchDataAdAccount_refetch,
    loading_bucket,
    inputs,
    removeDuplicatesforObj,
    chooseAdsetTemplate,
    setInputs,
    platformsData,
    submitTiktokLaunch,
    sasTrackingUrlFetch,
    sasTrackingLoading,
    sasTrackingUrl,
    setAdAccountValues,
    adAccountValues,
    isValidLaunch,
    setIsValidLaunch,
    visibleTargetModal,
    fetchTemplates,
    loading_creatives_templates,
    refetch_creatives_templates,
    optionProps,
    setOptionProps,
    selectedImage,
    adGroupDataSource,
    isSasCampaign,
    setTargettingTemplateData,
    setVisibleGroupModal,
    submitGroupModal,
    chooseExistingTemplate,
    setIsTargetEdited,
    isTargetEdited,
    multiAdaccount_data,
    setChooseExistingTemplate,
    setMultiAdaccount_data,
    vsBrandsFetch,
    visibleChooseAdsetModal,
    setVisibleChooseAdsetModal,
    vsBrands,
    selectedProfileIds,
    setSelectedProfileIds,
    instantLaunch,
    visibleCategoryModal,
    setVisibleCategoryModal,
    initialSetCampaignProps,
    profile_id,
    setProfileId,
    selectedCampaigns,
    setSelectedCampaigns,
    adSetwise,
    handlePlatforms,
    setAdsetWise,
    isDynamicCreatives,
    setIsDynamicCreatives,
    submission,
    setSubmission,
    instantLaunchData,
    advertiserId: instantLaunchData?.advertiser?.id,
    launchLeadGenDraft_loading,
    handleCampaignAdRemoveAll,
    setVisibleLaunchDrawer,
  };

  useEffect(() => {
    // WILL ADD VIRAL_SPARKS CONDITION HERE
    vsLoginUserFetch();
    // THIS IS USED IN Nomenclature for USER NIKNAME
  }, []);

  /**
   * This function is use for final Launch and caching adaccount for fb platform
   */
  function submitLaunch(adAccountDetails, launchName, camps) {
    let variables = adAccountDetails?.map((adAccount, index) => {
      const { customConversionId, customEventStr, ...restadAccount } = adAccount;
      delete restadAccount?.pixelValue;
      const updatedCampaignObject = cloneMainCamaignObject(camps?.[index]);
      console.log({ updatedCampaignObject });
      const newCampaignObj = updatedCampaignObject?.map?.((item) => {
        if (
          item?.specialAdCategories?.includes("NONE") ||
          item?.specialAdCategories?.length === 0
        ) {
          return { ...item, specialAdCategories: [], specialAdCategoryCountry: [] };
        } else {
          return item;
        }
      });
      // const iscustomConversionId = customConversionId !== undefined ? { customConversionId } : {};
      // const iscustomEvent = customEventStr !== undefined ? { customEventStr } : {};
      const customConversions =
        customConversionId && !customEventStr ? { customConversionId } : { customEventStr };

      const finalObject = {
        // launchAccountData: {
        //   ...restadAccount,
        //   timezone: dateRangeTimezone.timeZone.value,
        //   ...customConversions,
        // },
        camps: newCampaignObj,
        ...restadAccount,
        timezone: dateRangeTimezone.timeZone.value,
        ...customConversions,
      };
      return finalObject;
    });
    // addAccountCache({
    //   variables: {
    //     ad_account: adAccountValues?.adAccount,
    //     product_id: productId,
    //     payload: adAccountValues,
    //   },
    // });
    sasLaunch({
      variables: {
        launchAccountData: variables,
        name: launchName,
        media: removeDuplicates(media),
        video_thumbs: removeDuplicates(mediaVideoThumb),
      },
    });
  }
  /**
   * This function is use for final Launch for tiktok platform
   */
  function submitTiktokLaunch(adAccountDetails, launchName, campaignObject) {
    const advertisersData = adAccountDetails?.map((launchData) => {
      const { advertiserId, pixelId, identityType, identityId } = launchData;
      return {
        advertiserId,
        pixelId,
        identityType,
        identityId,
        ...(launchData?.url && { trackingUrl: [launchData?.url] }),
      };
    });

    const updatedCampaignObject = cloneMainCamaignObject(campaignObject);
    const newCampaignObj = updatedCampaignObject?.map?.((item) => {
      return {
        ...item,
        adSets: item?.adSets?.map?.((adSet) => {
          if (adSet?.creativeMaterialMode === "CUSTOM")
            return {
              ...adSet,
              ads: {
                // advertiserId: adAccount,
                creatives: adSet?.ads?.map(({ creatives }) => ({ ...creatives })),
              },
            };
          else {
            return {
              ...adSet,
              ads: {
                dynamicCreatives: adSet?.ads?.[0]?.dynamicCreatives,
              },
            };
          }
        }),
      };
    });

    const finalObject = {
      name: launchName,
      ImageMedia: removeDuplicatesforObj(media, "imageUrl"),
      advertisersData,
      bucketId: bucketData?.id,
      camps: newCampaignObj,
      VideoMedia: removeDuplicatesforObj(mediaVideo, "videoUrl"),
      productId,
      ...(instantLaunch && { isCategoryLaunch: true }),
    };

    callTiktokLaunch({ variables: { ...finalObject } });
  }
  function removeDuplicates(arr) {
    return [...new Set(arr.filter((value) => value !== undefined))];
  }
  function removeDuplicatesforObj(arr, prop) {
    const uniqueSet = new Set();
    return arr.filter((obj) => {
      const value = obj[prop];
      if (!uniqueSet.has(value)) {
        uniqueSet.add(value);
        return true;
      }
      return false;
    });
  }

  // const finalCampaignObject = isTiktokPlatformLaunch
  //   ? generateTiktokCampaignObject()?.campaigns
  //   : generateCampaignObject()?.campaigns;
  const debugContent = [
    {
      isRow: true,
      contentObjects: [
        { isTargetEdited, selectedTarget, chooseDraftOrNew, campaignObject },
        // { vsUrlPlatform, vsBrandByDomains, finalCampaignObject },
        draftData && { draftData },
      ],
    },

    {
      isRow: true,
      contentObjects: [{ bucketData }, { contentContentData }, { targettingTemplateData }],
    },
    {
      isRow: true,
      contentObjects: [{ launchDataAdAccount_data }, { targettingTemplateData }, { vsLoginUser }],
    },
  ];

  return (
    <LaunchContext.Provider value={contextData}>
      {/* <pre>{JSON.stringify({ location, queryParams, t: queryParams.get("status") }, null, 2)}</pre> */}
      {(params?.launchId && (launchDraft_loading || loading_bucket)) ||
        (instantLaunch && (launchDraft_loading || launchLeadGenDraft_loading) && (
          <div>
            <Loader elementCenter bgBlur />
          </div>
        ))}

      <LookModal {...addNewBucketModalProps}>
        {visibleTargetModal && (
          <SelectTargetingTemplate submitSelectTarget={submitSelectTarget} onCancel={onCancel} />
        )}
      </LookModal>
      {!instantLaunch && <DashboardHeader title="Launch" />}
      <LaunchMainView
        serviceType={serviceType}
        launchId={params?.launchId}
        categoryName={params?.categoryName}
        addAccountCache={addAccountCache}
      />
      <DebugPre content={debugContent} />
    </LaunchContext.Provider>
  );
}
