import { Steps } from "antd";

export default function MainSteper(props) {
  const { step = 0 } = props;
  const stepsData = [
    {
      title: "Campaign settings",
      description: "Set you campaigns goal, type and conversion goal.",
    },
    {
      title: "Targeting",
      description: "To reach the right people, start by defining key settings for your campaign.",
    },
    {
      title: "Bidding & Budget",
      description:
        "Customise your campaign's bidding and budget strategies to gain optimal performance.",
    },
    {
      title: "Ad group & Ad",
      description: "Increase engagement by carefully creating Adgroups and Ads for maximum Impact.",
    },
  ];

  return (
    <div id="look-stepper">
      <Steps current={step} items={stepsData} />
    </div>
  );
}
