import { Route, useParams } from "react-router-dom";
import AdAccounts from "../Reports/FacebookReports/AdAccounts";
import CampaignsData from "../Reports/FacebookReports/Campaigns";
import AdSets from "../Reports/FacebookReports/AdSets";
import Ads from "../Reports/FacebookReports/Ads";
import ActionIconRender from "components/ActionIconRender";
import {
  VOLUUM_VIEW_TEAM_REPORTING,
  VOLUUM_VIEW_WORKSPACE_REPORTING,
} from "shared/UserPermission/userPermissionList";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

export default function UserReports() {
  const params = useParams();
  const basePath = "/leadgen/reports/user";

  const accountTabContent = [
    {
      id: "adAccounts",
      link: `/${params?.userID}/ad-accounts`,
      route: `/:userID/ad-accounts`,
      title: "Ad Account",
      component: AdAccounts,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW_WORKSPACE_REPORTING, VOLUUM_VIEW_TEAM_REPORTING],
    },
    {
      id: "campaigns",
      link: `/${params?.userID}/campaigns`,
      route: `/:userID/campaigns`,
      title: "Campaigns",
      component: CampaignsData,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW_WORKSPACE_REPORTING, VOLUUM_VIEW_TEAM_REPORTING],
    },
    {
      id: "adSets",
      link: `/${params?.userID}/ad-sets`,
      route: `/:userID/ad-sets`,
      title: "Ad Sets",
      component: AdSets,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW_WORKSPACE_REPORTING, VOLUUM_VIEW_TEAM_REPORTING],
    },
    {
      id: "ads",
      link: `/${params?.userID}/ads`,
      route: `/:userID/ads`,
      title: "Ads",
      component: Ads,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW_WORKSPACE_REPORTING, VOLUUM_VIEW_TEAM_REPORTING],
    },
  ];

  const headerProps = {
    title: "User Reports",
    linkTabs: accountTabContent,
    basePath,
  };

  return (
    <div>
      <DashboardHeader {...headerProps} />
      {accountTabContent.map(({ link = "/", route = "/", component: Component }, index) => (
        <Route key={index} path={basePath + route} component={() => <Component />} />
      ))}
    </div>
  );
}
