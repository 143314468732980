import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useApiToStore, usePermissionCheck } from "hooks";
import { useCustomQuery } from "hooks/apolloClientHooks";
import {
  API_STORE_F360_TARGETING_COUNTRIES,
  API_STORE_FB360_CATEGORIES,
} from "hooks/useApiToStore/keys";
import { valueCheck } from "pages/dashboardPages/mainDashboard/UserRevenuData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { VOLUUM_GET_X_REVENUE } from "shared/gql/gqlSchema/fb360Gql";
import {
  F360_MANAGE_XREVENUE,
  USER_WITH_NO_ACTION,
} from "shared/UserPermission/userPermissionList";
import DebugPre from "../../../../components/DebugPre";
import DashboardHeader from "../../../../components/layouts/DashboardLayout/DashboardHeader";
import LookDrawer from "../../../../components/LookDrawer";
import LookTable from "../../../../components/LookTable";
import { LookTableColumnRender } from "../../../../components/LookTable/LookTableColumnOptions";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "../leadgenKeysAndEnums";
import AddXRevenue from "./AddXRevenue";

export default function XRevenue() {
  const { apiCall: targetingFetch = () => {}, refetch: targetingRefetch } = useApiToStore(
    API_STORE_F360_TARGETING_COUNTRIES
  );
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const { filterData } = useLookTableFilterData(persistKey);
  const userSettings = useSelector((state) => state?.userSettings);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const [xRevenueData, setXRevenueData] = useState([]);
  const { havePermission: isWithoutAction } = usePermissionCheck(USER_WITH_NO_ACTION);
  const { apiCall: voluumCategoryFetch = () => {} } = useApiToStore(API_STORE_FB360_CATEGORIES);

  const { data, loading, error, refetch } = useCustomQuery(VOLUUM_GET_X_REVENUE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.voluum_get_XRevenue;
      if (element) {
        setXRevenueData(
          element?.map((element) => ({
            ...element,
            categoryName: element?.category?.name,
            key: element.id,
          }))
        );
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
    },
  });

  const showDrawer = () => {
    setVisibleDrawer(true);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const drawerProps = {
    title: "Add X-Revenue",
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 900,
  };

  const createBtnProps = {
    createBtn:
      permissions?.findIndex((name) => name === F360_MANAGE_XREVENUE) !== -1 && !isWithoutAction,
    createBtnText: "Add X-Revenue",
    createBtnHandler: () => showDrawer(),
  };

  const initialColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 120,
      minWidth: 120,
    },
    {
      title: "X-Revenue",
      dataIndex: "x_revenue",
      key: "x_revenue",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      minWidth: 170,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 300,
      minWidth: 300,
    },
  ];

  const finalFilterData = filterData(xRevenueData).filter(({ categoryName = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    const searchCheck = categoryName?.toLowerCase()?.includes(searchValue);
    if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
      return true;
    }
    return false;
  });

  let total_xRevenue = 0;

  for (const { x_revenue = 0 } of finalFilterData) {
    total_xRevenue += parseFloat(x_revenue || 0);
  }
  const summaryData = {
    x_revenue: valueCheck(total_xRevenue, true),
  };

  const tableMainProps = {
    isDateRange: true,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    refetch,
    defaultPagination: true,
  };

  const debugContent = [{ contentObjects: [{ loading, error, data }] }];

  useEffect(() => {
    if (visibleDrawer) {
      // targetingFetch();
      voluumCategoryFetch();
    }
  }, [visibleDrawer]);

  return (
    <div>
      <LookDrawer {...drawerProps}>
        {visibleDrawer && (
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Overview",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: <AddXRevenue refetch={refetch} close={onCloseDrawer} />,
              },
            ]}
          />
        )}
      </LookDrawer>
      <DashboardHeader title="X-Revenue" {...createBtnProps} />
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
