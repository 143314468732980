import { Form, Button, Row, Col } from "antd";
import { Formik } from "formik";
import Loader from "components/loader";
import SelectInput from "components/forms/SelectInput";
import LookTextInput from "components/forms/LookTextInput";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { GET_SAS_VERTICALS } from "shared/gql/gqlSchema/sasGql";

export default function MainForm(props) {
  const {
    id: propId = "",
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    btnText = "Add",
    loading = false,
  } = props;

  const {
    data: VERTICAL_DATA = [],
    loading: VERTICAL_LOADING,
    error: VERTICAL_ERROR,
  } = useCustomQuery(GET_SAS_VERTICALS, {
    notifyOnNetworkStatusChange: true,
  });

  const allVerticals = VERTICAL_DATA?.sasVerticals;

  if (VERTICAL_LOADING) {
    return <Loader className="text-center" />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="name"
                label="Name"
                type={propId ? "text" : "textarea"}
                className="look-form-input"
                placeholder="Enter the name"
              />
            </Col>
            <Col span={24}>
              <Form.Item label="Vertical" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Vertical"
                  name="verticalId"
                  value={values.verticalId}
                  options={allVerticals?.map(({ id, name }) => ({ name: name, value: id }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Is Sensitive" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  name="isSensitive"
                  value={values.isSensitive}
                  options={[
                    { id: 0, name: "False" },
                    { id: 1, name: "True" },
                  ].map(({ id, name }) => ({ name: name, value: id }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
