import {
  LAUNCH_TYPE_LEADGEN,
  LAUNCH_TYPE_SAS,
  LAUNCH_TYPE_VS,
} from "components/LaunchView/LaunchKeys";

export default function getLaunchTitle(launchType) {
  switch (launchType) {
    case LAUNCH_TYPE_LEADGEN:
      return "Leadgen";
    case LAUNCH_TYPE_SAS:
      return "SAS";
    case LAUNCH_TYPE_VS:
      return "Viral Sparks";
    default:
      return "";
  }
}
