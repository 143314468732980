import useTiktokVoluumColums from "./useTiktokVoluumColums";
import useTiktokVoluumPerformanceColumns from "./useTiktokVoluumPerformanceColumns";

export default function useTiktokVoluumColumns(props) {
  const { tiktokVoluumColumns } = useTiktokVoluumColums(props);

  const { tiktokVoluumPerformanceColumns } = useTiktokVoluumPerformanceColumns(props);

  return { tiktokVoluumColumns, tiktokVoluumPerformanceColumns };
}
