import { useState } from "react";
import alasql from "alasql";
import { useSelector } from "react-redux";
import {
  VS_LIVE_PERFORMANCE_REPORT_DAILY,
  VS_LIVE_PERFORMANCE_REPORT_HOURLY,
} from "shared/gql/gqlSchema/viralSparksGql";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import DebugPre from "components/DebugPre";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import columns from "./columns";
import LookTable from "components/LookTable";
import { useFacebookSpendReportsQuery } from "hooks/facebookHooks";
import {
  PLATFORM_ID_FACEBOOK,
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_PERFORMANCE_IS_DAILY,
  REPORT_TYPE_PERFORMANCE_IS_HOURLY,
} from "shared/SharedKeys";
import { numberParse } from "utils";

export default function VSPerformanceReport(props) {
  const {
    adAccount_id,
    adAccountId,
    adId,
    campaignId,
    adsetId,
    timezone: timeZoneProps,
    selectedAdvertiser: propSelectedAdvertiser,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    type,
    isHourly = false,
  } = props;

  const { dateRangeTimezone } = useSelector((state) => state?.userSettings) || {};

  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });
  const defaultDate = [dateRangeTimezone?.rangeDates[0], dateRangeTimezone?.rangeDates[1]];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const [performanceReport, setPerformanceReport] = useState([]);

  let assetId = {};

  if (type === REPORT_TYPE_AD_ACCOUNT) {
    assetId = {
      adaccount_id: adAccount_id || adAccountId,
    };
  }

  if (type === REPORT_TYPE_CAMPAIGN) {
    assetId = {
      campaign_id: campaignId,
    };
  }

  if (type === REPORT_TYPE_AD_ADSET) {
    assetId = {
      adset_id: adsetId,
    };
  }

  if (type === REPORT_TYPE_AD) {
    assetId = {
      ad_id: adId,
    };
  }

  let spendQueryDateVariables = {
    from: rangeDates[0],
    timezone: timeZone?.value,
    to: rangeDates[1],
  };

  const queryVariables = {
    advertiser_id: propSelectedAdvertiser,
    from: rangeDates[0],
    timezone: timeZone?.value,
    to: rangeDates[1],
    platform_id: PLATFORM_ID_FACEBOOK,
    ...assetId,
  };

  // if (campaignId || campaign_id) {
  //   queryVariables.campaign_id = campaignId || campaign_id;
  //   spendQuery = GET_REPORT_PER_CAMPAIGN_ID_HOURLY;
  //   spendQueryName = "reportPerCampaignIdHourly";
  //   spendVariable = { campaignId: campaignId };
  // } else if (adAccountId || adAccount_id) {
  //   queryVariables.adaccountId = adAccountId || adAccount_id; //.split("act_")[1] || null;
  //   spendQuery = GET_REPORT_PER_AD_ACCOUNT_HOURLY;
  //   spendQueryName = "reportPerAdAccountHourly";
  //   spendVariable = { adAccountId: "act_" + queryVariables?.adaccountId };
  // } else if (adsetId || adset_id) {
  //   queryVariables.adset_id = adsetId || adset_id;
  //   spendQuery = GET_REPORT_PER_AD_SET_ID_HOURLY;
  //   spendQueryName = "reportPerAdSetIdHourly";
  //   spendVariable = { adSetId: adsetId };
  //   spendQueryDateVariables = {
  //     ...spendQueryDateVariables,
  //     from: spendQueryDateVariables.from + " 00:00:00",
  //     to: spendQueryDateVariables.to + " 23:59:59",
  //   };
  // } else if (adId || ad_id) {
  //   queryVariables.ad_id = adId || ad_id;
  //   spendQuery = GET_REPORT_AD_ID_HOURLY;
  //   spendQueryName = "reportPerAdIdHourly";
  //   spendVariable = { adId: adId };
  //   spendQueryDateVariables = {
  //     ...spendQueryDateVariables,
  //     from: spendQueryDateVariables.from + " 00:00:00",
  //     to: spendQueryDateVariables.to + " 23:59:59",
  //   };
  // }

  // isHourly

  let reportObject = {
    revenueApi: VS_LIVE_PERFORMANCE_REPORT_DAILY,
    revenueApiKey: "vs_performanceLiveReportsDay",
  };

  if (isHourly) {
    reportObject = {
      revenueApi: VS_LIVE_PERFORMANCE_REPORT_HOURLY,
      revenueApiKey: "vs_performanceLiveReportsHour",
    };
  }

  //Revenue Api
  const { loading: loading_GET_REPORT_DATA, refetch: refetch_GET_REPORT_DATA } = useCustomQuery(
    reportObject?.revenueApi,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[reportObject?.revenueApiKey];
        if (element) {
          setPerformanceReport(element);
        }
      },
      variables: queryVariables,
    }
  );

  //Spend Api
  const { loading_spend, refetch_spend, spendReport } = useFacebookSpendReportsQuery(type, {
    ...spendQueryDateVariables,
    detailReportType: isHourly
      ? REPORT_TYPE_PERFORMANCE_IS_HOURLY
      : REPORT_TYPE_PERFORMANCE_IS_DAILY,
    isDetailView: true,
    variables: { ...assetId },
  });

  const loading = loading_GET_REPORT_DATA || loading_spend;
  const refetch = () => {
    refetch_GET_REPORT_DATA();
    refetch_spend();
  };

  const initialColumns = [
    {
      title: "Date",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (value, { PROFIT }) => <LookTableColumnRender title={value} isProfit={PROFIT} />,
      fixed: "left",
      width: 200,
      minWidth: 200,
    },
    ...columns,
  ];

  let finalMerge = [];
  let finalMergeData = [];

  if (!loading) {
    finalMerge = alasql(
      "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.report_time = b.report_time",
      [performanceReport, spendReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        result = 0,
        impressions = 0 | "0",
        sessions = 0 | "0",
        pageViews = 0 | "0",
        user_revenue = 0,
        report_time = "",
      } = element;

      const spendUpdate = spendFB?.toFixed(2);
      const revenueUpdate = user_revenue;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(revenueUpdate) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(result) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageViews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;

      finalMergeData.push({
        ...element,
        TotalRevenue: numberParse(revenueUpdate),
        clicksFB,
        result,
        impressionsFB,
        key: index,
        spendFB: spendUpdate,
        PROFIT: numberParse(PROFIT),
        CPC: numberParse(CPC, { toFixed: true, toFixedUpTo: 3 }),
        CPC_FB: numberParse(CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
        ROI: numberParse(ROI),
        EPC: numberParse(EPC, { toFixed: true, toFixedUpTo: 3 }),
        CTR: numberParse(CTR),
        DISC: numberParse(DISC),
        IPP: numberParse(IPP),
        RPM: numberParse(RPM),
        PRPM: numberParse(PRPM),
        CPR: numberParse(CPR),
        PPS: numberParse(PPS),
        CPM: numberParse(CPM),
        dateTime: report_time,
      });
    });
  }
  const finalFilterData = finalMergeData
    ?.filter(({ dateTime = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";
      if (dateTime?.toLowerCase()?.includes(searchValue)) {
        return true;
      }
      return false;
    })
    .sort(function (a, b) {
      let keyA = new Date(a.dateTime);
      let keyB = new Date(b.dateTime);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

  let total_spend = 0,
    total_clicks = 0,
    total_impressionsFB = 0,
    total_result = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    result = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageviews = 0,
  } of finalFilterData) {
    total_spend += parseFloat(spendFB || 0);
    total_clicks += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_result += parseFloat(result || 0);
    total_impressions += parseFloat(impressions || 0);
    total_TotalRevenue += parseFloat(TotalRevenue || 0);
    total_sessions += parseFloat(sessions || 0);
    total_pageViews += parseFloat(pageviews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spend;
  const total_CPC = total_spend / total_sessions;
  const total_CPC_FB = total_spend / total_clicks;
  const total_ROI = (total_TotalRevenue / total_spend) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicks / total_impressionsFB) * 100;
  const total_CPR = total_spend / total_result;
  const total_DISC = total_sessions / total_clicks;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spend) / parseFloat(total_impressionsFB)) * 1000;

  const summaryData = {
    TotalRevenue: numberParse(total_TotalRevenue, { toFixed: true }),
    spend: numberParse(total_spend, { toFixed: true }),
    PROFIT: numberParse(total_PROFIT, { toFixed: true }),
    ROI: numberParse(total_ROI, { toFixed: true }),
    EPC: numberParse(total_EPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC: numberParse(total_CPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC_FB: numberParse(total_CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    result: numberParse(total_result),
    CPR: numberParse(total_CPR, { toFixed: true }),
    sessions: numberParse(total_sessions),
    clicksFB: numberParse(total_clicks),
    DISC: numberParse(total_DISC, { toFixed: true }),
    IPP: numberParse(total_IPP, { toFixed: true }),
    RPM: numberParse(total_RPM, { toFixed: true }),
    PRPM: numberParse(total_PRPM, { toFixed: true }),
    PPS: numberParse(total_PPS, { toFixed: true }),
    impressionsFB: numberParse(total_impressionsFB),
    CPM: numberParse(total_CPM, { toFixed: true }),
  };

  const tableMainProps = {
    isLineChart: true,
    exportCSV: true,
    persistKey,
    isRowSelection: false,
    isDateRange: true,
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },

    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
  };

  const debugContent = [
    // { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ performanceReport }, { spendReport }] },
  ];
  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
