import {
  USER_SETTING_SCORECARD_BY_QUARTER,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import { DatePicker, Form, Tooltip } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { dateQuarterFormat } from "reducers/userSettings";

export default function QuarterSelectPersist(props) {
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;
  const dispatch = useDispatch();
  const quarterInput = useSelector((state) => state?.userSettings?.quarterInput);
  const selectedQuarter =
    quarterInput && quarterInput[persistKey] ? quarterInput[persistKey] : null;

  function onQuarterChange(e) {
    dispatch({
      type: USER_SETTING_SCORECARD_BY_QUARTER,
      payload: { ...quarterInput, [persistKey]: e },
    });
  }

  return (
    <div>
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex">
            <Form.Item className="mb-0 ml-10">
              <Tooltip title="Select Quarter">
                <DatePicker
                  style={{ width: 150 }}
                  value={selectedQuarter ? moment(selectedQuarter, dateQuarterFormat) : null}
                  onChange={(e, f) => onQuarterChange(f)}
                  allowClear={false}
                  picker="quarter"
                />
              </Tooltip>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
