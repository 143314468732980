import ActionIconRender from "components/ActionIconRender";
import { VaroziIcon } from "components/svgComponents";
import { VAROZI_WORKSPACE_ID } from "pages/dashboardPages/Varozi/keysAndEnum";
import { useSelector } from "react-redux";
import { CustomeIconWrapper } from "..";

export default function useVaroziPath() {
  const workspace = useSelector((state) => state?.userSettings?.selectedWorkspace);

  const isVaroziWorkspace = workspace === VAROZI_WORKSPACE_ID;

  const varoziPath = [
    {
      name: "Amalfa",
      link: "/varozi",
      icon: () => (
        <CustomeIconWrapper className="size-17">
          <VaroziIcon style={{ transform: "rotate(180deg)" }} />
        </CustomeIconWrapper>
      ),
      subMenu: [
        {
          subMenuName: "Reports",
          subMenuLink: "/reports",
          subMenuIcon: (e) => <ActionIconRender iconType="lineChart" {...e} />,
          isVisible: isVaroziWorkspace,
        },
      ].filter(({ isVisible = true }) => isVisible),
    },
  ];

  return { varoziPath };
}
