import { Checkbox, Col, Pagination, Row, Segmented, Select, Switch } from "antd";
import { useLaunchContext } from "components/Launch/Context";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import LookCreatives from "components/LookCreatives";
import { PlusOutlined } from "@ant-design/icons";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import cx from "cx";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";

import { useEffect, useState } from "react";
import { GQL_CT_DELETE_GROUPS } from "shared/gql/gqlSchema/mediaGql";
import { openNotification } from "utils";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import {
  ADD_CREATIVE_GROUPS,
  GET_CREATIVE_GROUPS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import TagModal from "../CreativeUpload/TagModal";
import { useLookModal } from "components/LookModal";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { SELECTED_ADGROUPS } from "pages/dashboardPages/Creatives/creativesEnum";

export const CreativeSelectionKey_Image = "image_id";
export const CreativeSelectionKey_Description = "description_id";
export const CreativeSelectionKey_Thumbnail = "thumbnail_id";
export const CreativeSelectionKey_Title = "title_id";
export const CreativeSelectionKey_Video = "video_id";
export const CreativeSelectionKey_Body = "body_id";

export default function AdGroup(props) {
  const {
    productId,
    categoryId,
    categoryName,
    isTiktokPlatformLaunch,
    subCategoryId,
    verticalId,
    keyword_id,
    keyword_name,
    serviceType,
    adSetindex,
    groupSelection,
    campaignIndex,
    launch,
    isTemp,
    oneToOneAds,
  } = props;
  const {
    campaignObject,
    handleCampaignAdPush,
    handleCampaignAdRemove,
    handleCampaignAdRemoveAll,
    removeDuplicatesforObj,
  } = useLaunchContext();
  const [visibleCreativeDrawer, setVisibleCreativeDrawer] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [createdbyMe, setCreatedByMe] = useState("All");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [adGroupDataSource, setAdGroupDataSource] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [tags, setTags] = useState([]);
  const isAutoSelect = campaignObject?.[campaignIndex]?.creativeAutoSelect;
  // state for bulk selection of adgroups
  const [selectedAdgroups, setSelectedAdgroups] = useState([]);
  const selectedAds = campaignObject?.[campaignIndex]?.adSets?.[adSetindex]?.ads || [];

  const onChange = (checked) => {
    setCreatedByMe(checked);
  };
  const handleChange = (newValue) => {
    setTags(newValue);
  };

  const handleSelection = (id) => {
    if (selectedAdgroups?.includes(id)) {
      setSelectedAdgroups((prev) => prev?.filter((data) => data !== id));
    } else {
      setSelectedAdgroups((prev) => [...prev, id]);
    }
  };
  const showCreativeDrawer = (rowProps, isEdited) => {
    if (isEdited) {
      setSelectedCreatives({
        description_id: [rowProps?.description?.id],
        image_id: [rowProps?.image?.id],
        title_id: [rowProps?.title?.id],
        video_id: [rowProps?.video?.id],
        body_id: [rowProps?.body?.id],
      });
      setSelectedData({
        body: [rowProps?.body],
        description: [rowProps?.description],
        image: [rowProps?.image],
        video: [rowProps?.video],
        title: [rowProps?.title],
        id: [rowProps?.id],
      });
    }

    setVisibleCreativeDrawer(true);
  };

  const onCloseCreativeDrawer = () => {
    setSelectedCreatives({
      body_id: [],
      description_id: [],
      image_id: [],
      title_id: [],
      video_id: [],
    });
    setSelectedData({
      body: [],
      description: [],
      image: [],
      title: [],
      video: [],
    });
    setVisibleCreativeDrawer(false);
  };

  const creativeDrawerProps = {
    visible: visibleCreativeDrawer,
    onClose: onCloseCreativeDrawer,
    size: 1200,
  };
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  // let queryVars = {
  //   categoryId: props?.id,
  //   productId,
  //   inputs: { page: currentPage, size: pageSize },
  //   createdByMe: createdbyMe,
  // };

  // if (serviceType === SERVICE_TYPE_SAS) {
  //   queryVars = isKeyword
  //     ? {
  //         ...queryVars,
  //         subCategoryId: props?.id,
  //         categoryId: category?.id,
  //       }
  //     : { ...queryVars };
  // }
  const queryVars = {
    productId,
    categoryId,
    createdByMe: createdbyMe === "My Adgroups",
    page: currentPage,
    size: pageSize,
  };

  const [getAdgroups, { loading, refetch }] = useCustomLazyQuery(GET_CREATIVE_GROUPS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ctGetCreativeGroups;
      if (element?.status === "SUCCESS") {
        const finalData = element?.data?.map((data) => {
          const { group = {} } = data || {};
          const { bodyId, imageId, videoId, titleId, descId, id, tags = [] } = group;
          const groupData = {
            body: bodyId,
            tags,
            title: titleId,
            description: descId,
            ...(videoId && {
              video: {
                id,
                thumbUrl: videoId?.currentThumb?.r2Url,
                s3Url: videoId?.r2Url ?? null,
              },
            }),
            ...(imageId && {
              image: {
                ...imageId,
                s3Url: imageId?.r2Url ?? null,
              },
            }),
            id,
          };
          return groupData;
        });
        setAdGroupDataSource(finalData);

        setTotalCount(e?.ctGetCreativeGroups?.pageInfo?.totalRecords);
      } else {
        openNotification({ type: "error", message: element?.message, key: "getGroup-failedError" });
      }
      // const element = e?.clPagedGroup;
      // if (element) {
      //   if (element?.status === "SUCCESS") {
      //     setAdGroupDataSource(element?.data);
      //   }
      //   if (element?.status === "FAILURE") {
      //     openNotification({ type: "error", message: e?.message, key: "fetch-error" });
      //   }
      // }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "getGroup-error" });
    },
  });
  useEffect(() => {
    if (isAutoSelect) {
      setAdGroupDataSource(campaignObject?.[campaignIndex]?.adSets?.[adSetindex]?.ads);
    } else {
      getAdgroups({
        variables: {
          ...queryVars,
        },
      });
    }
  }, [currentPage, pageSize, refetch]);

  const [ctAddGroups, { loading: loading_ctAddGroups }] = useCustomMutation(ADD_CREATIVE_GROUPS, {
    onCompleted: (e) => {
      const element = e?.ctAddCreativeGroups;
      const { status, data, message } = element;

      if (status === "SUCCESS") {
        const { addedCreatives, alreadyPresent, unableToAdd } = data?.[0] || {};
        // setVisibleCreativeDrawer(false)
        onCloseCreativeDrawer();
        let newMessage = message;
        if (alreadyPresent?.length > 0) {
          newMessage = `Creatives is already present`;
          openNotification({
            type: "error",
            message: newMessage,
            key: "addGroup-alreadyPresentError",
          });
          refetch();
        } else if (addedCreatives?.length > 0) {
          refetch();
          // setAdGroupDataSource([...adGroupDataSource, ...addedCreatives]);
        } else if (unableToAdd?.[0]?.error) {
          openNotification({
            type: "error",
            message: unableToAdd?.[0]?.error,
            key: "addGroup-failedError",
          });
        }
      } else {
        openNotification({ type: "error", message, key: "addGroup-failedError" });
      }
      // if (element) {
      //   setAdGroupDataSource([element, ...adGroupDataSource]);
      //   openNotification({ type: "success", message: "Record added" });
      //   onCloseCreativeDrawer();
      //   refetch();
      // }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "addgroup-error" });
    },
  });
  const variables = {
    productId,
    categoryId,
    categoryName,
  };

  const bulkActions = [
    {
      option: "tagSwitch",
      title: "Edit Tags",
      onClick: () => {
        openTagModal();
      },
    },
  ];

  const [callDeleteGroups, { loading: loading_DeleteGroups }] = useCustomMutation(
    GQL_CT_DELETE_GROUPS,
    {
      onCompleted: (e) => {
        const element = e?.ctDeleteCreativeGroups;
        if (element?.status === "SUCCESS") {
          refetch();
          onCloseDeleteModal();
          openNotification({ type: "success", message: element?.message });
        } else {
          openNotification({ type: "error", message: element?.message });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: "Something is wrong, please try again" });
      },
      variables: { productId, categoryId },
    }
  );

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    callDeleteGroups({ variables: { groupObjectIds: [deleteModalPropsData?.id] } });
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  /**
   * selectedCreatives state is for Add Group
   */

  const [selectedCreatives, setSelectedCreatives] = useState({
    description_id: [],
    image_id: [],
    thumbnail_id: [],
    title_id: [],
    video_id: [],
    body_id: [],
  });

  const [selectedData, setSelectedData] = useState({
    body: [],
    description: [],
    image: [],
    video: [],
    title: [],
  });

  /**
   *
   * @param {*} key
   * @param {*} id
   */

  function handleSelectedCreatives(key, id) {
    if (key === "image_id" || key === "video_id") {
      if (selectedCreatives?.[key]?.map((data) => data === id)?.includes(true)) {
        setSelectedCreatives({
          ...selectedCreatives,
          [key]: [...selectedCreatives?.[key]?.filter((key_id) => key_id !== id)],
        });
      } else {
        setSelectedCreatives({ ...selectedCreatives, [key]: [...selectedCreatives?.[key], id] });
      }
    } else {
      setSelectedCreatives({ ...selectedCreatives, [key]: id });
    }
    // if (key === "image_id") {
    //   selectedCreatives?.video_id
    //     ? setSelectedCreatives({ ...selectedCreatives, [key]: id, video_id: "" })
    //     : setSelectedCreatives({ ...selectedCreatives, [key]: id });
    // } else if (key === "video_id") {
    //   selectedCreatives?.image_id
    //     ? setSelectedCreatives({ ...selectedCreatives, [key]: id, image_id: "" })
    //     : setSelectedCreatives({ ...selectedCreatives, [key]: id });
    // } else
  }
  function createCombinations(options, title, body, description, type) {
    const combinations = [];

    for (const option of options) {
      for (const ttl of title) {
        for (const bd of body.length > 0 ? body : [null]) {
          for (const desc of description.length > 0 ? description : [null]) {
            let obj = {
              ...(type === CREATIVE_TYPE_IMAGE && { imageId: option }),
              ...(type === CREATIVE_TYPE_VIDEO && { videoId: option }),
              titleId: ttl,
              ...(bd !== null ? { bodyId: bd } : {}),
              ...(desc !== null ? { descriptionId: desc } : {}),
              // product_id: productId,
              // category_id: categoryId,
              // category_name: categoryName,
              // ...keyword_id,
              // ...keyword_name,
            };
            combinations.push(obj);
          }
        }
      }
    }

    return combinations;
  }
  // The combinations array now contains all possible combinations of elements.

  function submitAdGroupData() {
    const { image_id, video_id, description_id, body_id, title_id } = selectedCreatives;
    const { id } = selectedData;
    let finalData = [];
    if (image_id?.length > 0) {
      finalData.push(
        ...createCombinations(image_id, title_id, body_id, description_id, CREATIVE_TYPE_IMAGE)
      );
    }
    if (video_id.length > 0) {
      finalData.push(
        ...createCombinations(video_id, title_id, body_id, description_id, CREATIVE_TYPE_VIDEO)
      );
    }
    if (categoryId) {
      ctAddGroups({
        variables: {
          ...variables,
          inputs: finalData,
        },
      });
    }
  }

  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_DeleteGroups,
  };
  const creativeProps = {
    selectedCreatives: selectedCreatives,
    handleSelectedCreatives: handleSelectedCreatives,
    setSelectedData: setSelectedData,
    selectedData: selectedData,
    adGroupDataSource: adGroupDataSource,
    onOpenDeleteModal: onOpenDeleteModal,
  };
  let adSet = [];
  let ads = [];
  if (launch) {
    // Use the ads from the specific adSet
    if (isTemp) {
      ads = campaignObject[0]?.adSets[0]?.ads?.map((i) => i?.id) || [];
      function findCommonAds(arrays) {
        const commonElements = {};

        if (arrays.length === 0) {
          return commonElements;
        }
        for (const element of arrays[0]) {
          const minCount = Math.min(
            ...arrays.map((arr) => arr.filter((el) => el === element).length)
          );

          if (minCount > 0) {
            commonElements[element] = minCount;
          }
        }
        return commonElements;
      }
      let campAds = [];
      for (const campaign of campaignObject) {
        for (const adSet of campaign.adSets) {
          campAds.push(adSet?.ads?.map((ad) => ad?.id));
        }
      }
      const commonAds = findCommonAds(campAds);
      ads = campaignObject[0]?.adSets[0]?.ads?.filter((item) => {
        const id = item.id;
        if (commonAds[id] > 0) {
          commonAds[id]--;
          return true;
        }
        return false;
      });
    } else {
      adSet = campaignObject[campaignIndex]?.adSets[adSetindex];
      ads = adSet?.ads || [];
    }
  }
  const finalAdgroupData = launch
    ? createdbyMe === SELECTED_ADGROUPS
      ? removeDuplicatesforObj(selectedAds, "id")
      : !isTiktokPlatformLaunch
      ? isAutoSelect
        ? adGroupDataSource?.filter(
            (item, index, self) => index === self.findIndex((obj) => obj?.id === item?.id)
          )
        : adGroupDataSource
            ?.filter((item, index, self) => index === self.findIndex((obj) => obj?.id === item?.id))
            ?.sort((a, b) => {
              const isPresentInAdsA = ads?.findIndex(({ id }) => id === a?.id) !== -1;
              const isPresentInAdsB = ads?.findIndex(({ id }) => id === b?.id) !== -1;
              if (isPresentInAdsA && !isPresentInAdsB) {
                return -1; // a comes before b
              } else if (!isPresentInAdsA && isPresentInAdsB) {
                return 1; // b comes before
              }

              return 0; // no change in order
            })
      : adGroupDataSource
    : adGroupDataSource;

  const bulkActionProps = {
    selectedRows: selectedAdgroups,
    onDismiss: () => {
      setSelectedAdgroups([]);
    },
    actionOptions: bulkActions,
  };
  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });

  return (
    <div className={!groupSelection ? cx("p-20") : ""}>
      <div className={`d-flex mb-20 `}>
        <Segmented
          className="custom-segmented"
          options={["All", "My Adgroups", ...(launch ? [SELECTED_ADGROUPS] : [])]}
          value={createdbyMe}
          onChange={onChange}
        />
        <div
          className={cx(
            "look-table-search d-flex align-items-center justify-content-between ml-15"
          )}
          style={{
            border: "1px solid #404040", // TODO this will replace with class
          }}
        >
          <Select
            mode="tags"
            bordered={false}
            placeholder={"Search by tags"}
            style={{ width: "200px" }}
            onChange={handleChange}
            maxTagCount="responsive"
          />
          <LookButton
            tooltipTitle="Click here to search"
            className="filter-btn ml-8"
            iconType="search"
            onClick={() => {
              getAdgroups({
                variables: {
                  ...queryVars,
                  tags,
                },
              });
            }}
          />
        </div>

        {launch && (
          <LookButton
            iconType="delete"
            danger
            tooltipTitle="All creatives will be deleted from all campaigns"
            onClick={() => handleCampaignAdRemoveAll()}
          ></LookButton>
        )}
      </div>
      {visilbleTagModal &&
        tagModal(
          <TagModal
            variables={{
              productId,
              categoryId,
              categoryName,
            }}
            files={
              selectedAdgroups?.length === 0
                ? deleteModalPropsData
                : selectedAdgroups?.map((data) => ({
                    id: data,
                  }))
            }
            creatives_refetch={refetch}
            setTags={setTags}
            setTagsData={setTagsData}
            tags={tags}
            tagsData={tagsData}
            closeTagModal={closeTagModal}
            selectedRowsClear={() => setTags([])}
            creativeType="GROUP"
          />
        )}

      {launch && (
        <span className={cx("adgroup-count-title", { "text-danger": ads?.length > 5 })}>
          AdCopy ( {ads?.length}/5 )
        </span>
      )}
      <LookTableRecordDeleteModal {...deleteModalProps} />
      {!launch && (
        <>
          <LookTableBulkAction {...bulkActionProps} />
        </>
      )}
      <LookCreatives
        {...creativeProps}
        {...props}
        {...creativeDrawerProps}
        serviceType={serviceType}
        productId={productId}
        isTiktokPlatformLaunch={isTiktokPlatformLaunch}
        groupSelection
        submitView={
          <>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <LookButton
                  onClick={onCloseCreativeDrawer}
                  block
                  className="submit-btn"
                  size="large"
                >
                  Cancel
                </LookButton>
              </Col>
              <Col span={12}>
                <LookButton
                  disabled={
                    !loading_ctAddGroups &&
                    selectedCreatives?.title_id?.length !== 0 &&
                    (selectedCreatives?.image_id?.length !== 0 ||
                      selectedCreatives?.video_id?.length !== 0)
                      ? false
                      : true
                  }
                  onClick={submitAdGroupData}
                  block
                  type="primary"
                  className="submit-btn"
                  size="large"
                >
                  Submit
                </LookButton>
              </Col>
            </Row>
          </>
        }
      />
      <div className={cx("px-15 creative-text-adgroup ", { launch })}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Row gutter={[15, 15]} style={{ height: "395px" }}>
              <Col xs={12} sm={12} md={8} xl={6}>
                <div
                  className="h-100 d-flex"
                  style={{
                    border: "1px solid #595959",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "30%",
                      width: "50%",
                      borderRadius: "5px",
                      backgroundColor: "#303030",
                      border: "1px solid #404040",
                      borderStyle: "dashed",
                      flexDirection: "column",
                    }}
                    onClick={showCreativeDrawer}
                  >
                    <div>
                      <PlusOutlined style={{ fontSize: "32px" }} />
                    </div>
                    <div className="mt-10">Upload</div>
                  </div>
                </div>
              </Col>

              {finalAdgroupData?.map((data) => {
                const adGroupData = data;
                const count = launch ? ads?.filter(({ id }) => id === adGroupData?.id) : 0;
                const isSelected = launch
                  ? ads?.findIndex(({ id }) => id === adGroupData?.id) !== -1
                  : selectedAdgroups?.includes(adGroupData?.id);
                const args = !isTemp ? [campaignIndex, adSetindex] : [];
                const poverWrapperOptions = [
                  {
                    option: "edit",
                    title: "Edit Group",
                    optionProps: {
                      onClick: () => showCreativeDrawer(data, true),
                    },
                  },
                  ...(launch
                    ? []
                    : [
                        {
                          option: "delete",
                          title: "Delete Group",
                          optionProps: {
                            onClick: () => onOpenDeleteModal(data),
                          },
                        },
                      ]),
                  {
                    option: "tagSwitch",
                    title: "Edit Tags",
                    optionProps: {
                      onClick: () => {
                        setTags(data?.tags?.map((tag) => tag?.name));
                        openTagModal();
                        setDeleteModalPropsData([{ id: data?.id, tags: data?.tags || [] }]);
                      },
                    },
                  },
                ];
                return (
                  <Col xs={12} sm={12} md={8} xl={6}>
                    {launch ? (
                      <CardCreativeAdGroup
                        {...adGroupData}
                        selected={isSelected}
                        poverWrapperOptions={poverWrapperOptions}
                        // editButton={
                        //   <LookButton
                        //     className="h-initial w-initial"
                        //     type="link"
                        //     tooltipTitle="Edit Group"
                        //     iconType="edit"
                        //     onClick={() => showCreativeDrawer(data, true)}
                        //   />
                        // }
                        selectedCallback={() => {
                          if (isSelected) {
                            handleCampaignAdRemove(adGroupData?.id, true, ...args);
                          } else {
                            if (adGroupData?.video && adGroupData?.video?.thumbUrl) {
                              handleCampaignAdPush(adGroupData, ...args);
                            } else if (adGroupData?.image) {
                              handleCampaignAdPush(adGroupData, ...args);
                            } else {
                              openNotification({
                                type: "error",
                                message: "Please upload thumb url",
                              });
                            }
                          }
                        }}
                        isCta={adSet?.targeting?.cta ? adSet?.targeting?.cta : undefined}
                        counter={
                          isSelected ? (
                            <div className="launch-selected-adgroup-counter">
                              <LookButton
                                className="minus"
                                iconType="minus"
                                onClick={() =>
                                  handleCampaignAdRemove(adGroupData?.id, false, ...args)
                                }
                              />
                              <span className="count">{count?.length}</span>
                              <LookButton
                                className="plus"
                                iconType="plus"
                                onClick={() => {
                                  const args = !isTemp ? [campaignIndex, adSetindex] : [];
                                  if (adGroupData?.video && adGroupData?.video?.thumbUrl) {
                                    handleCampaignAdPush(adGroupData, ...args);
                                  } else if (adGroupData?.image) {
                                    handleCampaignAdPush(adGroupData, ...args);
                                  } else {
                                    openNotification({
                                      type: "error",
                                      message: "Please upload thumb url",
                                    });
                                  }
                                }}
                              />
                            </div>
                          ) : undefined
                        }
                        // selection={
                        //   !isSelected ? (
                        //     <>
                        //       <div
                        //         onClick={() => {
                        //           const args = !isTemp ? [campaignIndex, adSetindex] : [];
                        //           if (adGroupData?.video && adGroupData?.video?.thumbUrl) {
                        //             handleCampaignAdPush(adGroupData, ...args);
                        //           } else if (adGroupData?.image) {
                        //             handleCampaignAdPush(adGroupData, ...args);
                        //           } else {
                        //             openNotification({
                        //               type: "error",
                        //               message: "Please upload thumb url",
                        //             });
                        //           }
                        //         }}
                        //         className="position-absolute w-100 h-100 pointer"
                        //         style={{ top: 0, left: 0, opacity: 0.3, zIndex: 2 }}
                        //       ></div>
                        //     </>
                        //   ) : undefined
                        // }
                      />
                    ) : (
                      <div clssName="d-flex">
                        <CardCreativeAdGroup
                          {...data}
                          {...adGroupData}
                          isCreativeLibrary
                          selected={isSelected}
                          poverWrapperOptions={poverWrapperOptions}
                          selectedCallback={() => handleSelection(adGroupData?.id)}

                          // editButton={
                          //   <LookButton
                          //     className="h-initial w-initial"
                          //     type="link"
                          //     tooltipTitle="Edit Group"
                          //     iconType="edit"
                          //     onClick={() => showCreativeDrawer(data, true)}
                          //   />
                          // }
                          // deleteButton={
                          //   <LookButton
                          //     className="h-initial w-initial"
                          //     type="link"
                          //     danger
                          //     tooltipTitle="Delete Group"
                          //     iconType="delete"
                          //     onClick={() => onOpenDeleteModal(data)}
                          //   />
                          // }
                          // tagButton={
                          //   <LookButton
                          //     className="h-initial w-initial"
                          //     iconType="tagSwitch"
                          //     tooltipTitle="Edit Tags"
                          //     onClick={() => {
                          //       setTags(data?.tags?.map((tag) => tag?.name));
                          //       openTagModal();
                          //       setDeleteModalPropsData([{ id: data?.id, tags: data?.tags || [] }]);
                          //     }}
                          //   />
                          // }
                        />
                      </div>
                    )}
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </div>
      {createdbyMe !== SELECTED_ADGROUPS && (
        <div className="p-8 h-initial w-initial look-bottom-pagination ">
          <Pagination
            defaultCurrent={1}
            total={totalCount}
            pageSize={pageSize}
            current={currentPage}
            onChange={(page, size) => handlePageChange(page, size)}
            pageSizeOptions={["10", "20", "50"]}
            showSizeChanger
          />
        </div>
      )}

      {/* <DebugPre content={debugContent} /> */}
    </div>
  );
}
