import { Col, Form, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import ContentTextData from "./ContentTextData";
import DrawerFooterBotton from "components/Button/DrawerFooterBotton";
import { useEffect, useMemo, useState } from "react";
import StructuredSnippets from "./StructuredSnippets";
import DebugPre from "components/DebugPre";
import { useGoogleLaunchV1Context } from "components/Launch/GoogleLaunchV1/Context";
import SelectInput from "components/forms/SelectInput";
import { GL_STATUS_ENUM, GL_STATUS_PAUSED } from "shared/enum/launchKeysAndEnum";

/**
 * Right now we are not using image upload functionality but we have implemented it,
 * if in future we want to use it all we have to do is uncomment the code
 */

export default function AdForm(props) {
  const { close = () => {}, adsData, editIndex } = props;

  const isEditMode = editIndex !== -1;

  const {
    pushNewAd = () => {},
    handleAdLevelData = () => {},
    // imageArray,
    // setImageArray,
  } = useGoogleLaunchV1Context();

  // const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 4 }, () => 10));
  // const [imageData, setImageData] = useState([]);

  const defaultFormInputs = {
    finalUrls: "",
    status: GL_STATUS_PAUSED,
    headlines: [],
    descriptions: [],
    callouts: [],
    structuralSnippets: [],
  };
  const [formInputs, setFormInputs] = useState(defaultFormInputs);
  useEffect(() => {
    if (editIndex !== -1) {
      setFormInputs(adsData[editIndex]);
    } else {
      setFormInputs(defaultFormInputs);
    }
  }, [editIndex]);
  const { status, finalUrls, headlines, descriptions, callouts, structuralSnippets } = formInputs;

  function handleFormInputs(name, value) {
    setFormInputs((previous) => ({ ...previous, [name]: value }));
  }

  const renderStatus = () => (
    <SelectInput
      label="Status"
      value={status}
      onChange={(e) => handleFormInputs("status", e)}
      options={GL_STATUS_ENUM}
    />
  );

  const renderFinalUrl = () => (
    <>
      <h3>Final URL</h3>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={18}>
            <LookTextInput
              required
              requiredLabel="Final URL"
              label="Final URL"
              name="finalUrls"
              value={finalUrls}
              onChange={(e) => handleFormInputs("finalUrls", e.target.value)}
            />
          </Col>
          <Col span={6}>{renderStatus()}</Col>
        </Row>
        {/* Currently removed display path */}
        {/* <Row gutter={25} className="position-relative mb-40">
          <Col span={12}>
            <LookTextInput
              className="mb-0"
              label="Display path"
              name="displayPath"
              placeholder="Enter value"
            />
          </Col>
          <span className="position-absolute-x-center bottom-0 mb-5">/</span>
          <Col span={12}>
            <LookTextInput
              className="mb-0"
              label={<>&nbsp;</>}
              name="displayPath"
              placeholder="Enter value"
            />
          </Col>
        </Row> */}
      </Form>
    </>
  );

  // const handleDelete = (id) => {
  //   const finalData = removeObjectByIndex(imageArray, id);
  //   setImageArray(finalData);
  // };

  // const getImageResponse = (imageUploadData) => {
  //   const newImages = imageUploadData?.map((data) => ({ url: data?.url, imageType: "IMAGE_JPEG" }));
  //   setImageArray((prev) => [...prev, ...newImages]);
  // };

  // const { uploadView, uploadLoading } = useUploadFiles({
  //   getImageResponse,
  //   creativeType: CREATIVE_TYPE_IMAGE,
  // });

  // const renderImagesData = () =>
  //   uploadLoading
  //     ? skeletonSize?.map((e) => (
  //         <div>
  //           <Skeleton.Image className="w-100 h-100" />
  //         </div>
  //       ))
  //     : imageArray?.map((data, index) => (
  //         <div style={{ margin: "0 8px" }}>
  //           <Image src={data?.url} preview={false} style={{ objectFit: "fill" }} />
  //           <LookButton onClick={() => handleDelete(index)}>
  //             <ActionIconRender iconType="delete" />
  //           </LookButton>
  //         </div>
  //       ));

  const onSubmitCreate = () => {
    pushNewAd(formInputs);
    close();
  };
  const onSubmitUpdate = () => {
    handleAdLevelData(formInputs, { adIndex: editIndex });
    close();
  };

  const isValid = useMemo(() => {
    return finalUrls && headlines?.length > 2 && descriptions?.length > 1;
  }, [finalUrls, headlines, descriptions]);

  const submitActions = [
    {
      label: "Cancel",
      onClick: () => close(),
      size: "large",
    },
    {
      label: isEditMode ? "Update" : "Save",
      type: "primary",
      size: "large",
      disabled: !isValid,
      onClick: () => (isEditMode ? onSubmitUpdate() : onSubmitCreate()),
    },
  ];

  /**
   * For responsive search ads, Google Ads allows up to 15 headlines and 4 descriptions to be tested at once.
   */

  // const mediaUploadView = () => (
  //   <div className="d-flex">
  //     <div style={{ height: "141px", width: "141px", margin: "0 10px" }}>
  //       {/* upload button */}
  //       {uploadView()}
  //     </div>
  //     <div className="d-flex" style={{ height: "141px", width: "141px" }}>
  //       {/* image render */}
  //       {renderImagesData()}
  //     </div>
  //   </div>
  // );

  const debugContent = [{ isRow: true, contentObjects: [{ formInputs }] }];
  return (
    <div>
      {/* style={{ height: `calc(100vh - 130px)` }} added because this will not use in other place */}
      <div className="overflow-auto p-20" style={{ height: `calc(100vh - 130px)` }}>
        {renderFinalUrl()}
        <ContentTextData
          {...{
            handleFormInputs,
            contents: [
              {
                label: "Headline",
                maxCount: 15,
                min: 3,
                name: "headlines",
                value: headlines,
              },
              {
                label: "Description",
                maxCount: 4,
                min: 2,
                name: "descriptions",
                value: descriptions,
              },
            ],
          }}
        />
        {/*{mediaUploadView()}*/}
        <ContentTextData
          {...{
            handleFormInputs,
            contents: [{ label: "Callout", maxCount: 4, name: "callouts", value: callouts }],
          }}
        />
        <StructuredSnippets
          {...{
            structuralSnippets,
            setStructuralSnippets: (e) => handleFormInputs("structuralSnippets", e),
          }}
        />
        <DebugPre content={debugContent} />
      </div>
      <DrawerFooterBotton buttons={submitActions} />
    </div>
  );
}
