import { Checkbox, Tooltip } from "antd";
import { useEffect, useRef } from "react";

const LazyList = (props) => {
  const {
    data = {},
    dataKey = "",
    setFilterCurrentPage,
    filterCurrentPage,
    setAppliedFilters = () => {},
  } = props;
  const lastElementRef = useRef();

  const handleData = (index) => {
    setFilterCurrentPage((prev) => {
      prev[dataKey] = prev[dataKey] + 1;
      return { ...prev };
    });
  };

  useEffect(() => {
    const handleIntersection = (entry) => {
      if (entry.isIntersecting && data.value.length) {
        // setComment((prev) => [...prev, ...Array.from({ length: 10 })]);
        handleData(data.value.length - 1); // Notify when last element is seen
      }
    };

    const options = {
      root: document.querySelector("#scrollArea"),
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = (entries) => {
      entries?.forEach((entry) => {
        if (entry.target === lastElementRef.current) {
          handleIntersection(entry);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    lastElementRef.current && observer.observe(lastElementRef.current);

    return () => {
      lastElementRef.current && observer.unobserve(lastElementRef.current);
    };
  }, [filterCurrentPage]);

  return (
    <>
      {data["value"]?.map((innerData, index) => {
        const finalKey =
          data["key"] === "sub_id" ? innerData?.sub_id : innerData?.id || innerData?.value;

        return (
          <>
            <div
              key={index}
              ref={index === data.value.length - 1 ? lastElementRef : null}
              className="text-overflow-ellipsis"
            >
              <Checkbox
                value={finalKey}
                onChange={(e) => {
                  setAppliedFilters((prev) => {
                    const updatedData = prev?.find((item) => item?.filterType === data?.filterType);
                    let updatedInnerData = [];
                    if (updatedData) {
                      updatedInnerData = [
                        {
                          ...updatedData,
                          id: e.target.checked
                            ? [...updatedData?.id, e.target.value]
                            : updatedData?.id?.filter((data) => data !== e.target.value),
                        },
                      ];
                    } else {
                      updatedInnerData = [
                        {
                          filterType: data["filterType"],
                          spendApikey: data["spendApikey"],
                          revApikey: data["revApikey"],
                          id: [e.target.value],
                        },
                      ];
                    }
                    return [
                      ...prev.filter((item) => item.filterType !== data.filterType),
                      ...updatedInnerData,
                    ];
                  });
                }}
              >
                <Tooltip title={innerData?.[data["key"]]}>{innerData?.[data["key"]]}</Tooltip>
              </Checkbox>
            </div>
          </>
        );
      })}
    </>
  );
};

export default LazyList;
