import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4 } from "actions/actionTypes";
import LookTable from "components/LookTable";
import React, { useEffect } from "react";
import { REPORT_TYPE_LIVE } from "shared/SharedKeys";
import { useCreativeReportingContext } from "../../Context";

function AdAccountReports({ adAccountColumns }) {
  const {
    finalMerge,
    summaryData,
    loading,
    revSasAdAccountTiktokFetch,
    spendAdAccountTiktokFetch,
    revenueFbFetch,
    spendAdAccountFetch,
    isSAS,
    isVS,
    tiktokVariables,
    fbRevVariables,
    fbSpendVariables,
    isFbReport = false,
    isLeadgen,
    spendDetailedVariables,
    filters,
    isGlobalReport,
    setSpendDetailedReport,
    setRevDetailedReport,
    refetch_adAccount_rev_tiktok,
    refetch_adAccount_fb,
    refetch_fb_rev,
    refetch_adAccount_tiktok,

    revenueVSLiveAdAccountFetch,
    loading_revenueVSLiveAdAccountFetch,
    refetch_revenueVSLiveAdAccountFetch,
  } = useCreativeReportingContext();
  const tableAdAccountProps = {
    initialColumns: adAccountColumns,
    recordData: finalMerge,
    summaryData,
  };
  const refetch_fb_AdAccount = () => {
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    if (isSAS) {
      refetch_adAccount_rev_tiktok();
      refetch_adAccount_fb();
    }
    if (isVS) {
      refetch_adAccount_fb();
      refetch_revenueVSLiveAdAccountFetch();
    } else {
      refetch_adAccount_fb();
      refetch_fb_rev();
    }
  };
  const refetch_tiktok_AdAccount = () => {
    setSpendDetailedReport([]);
    setRevDetailedReport([]);
    if (isSAS) {
      refetch_adAccount_tiktok();
      refetch_adAccount_rev_tiktok();
    } else {
      refetch_fb_rev();
      refetch_adAccount_tiktok();
    }
  };
  useEffect(() => {
    if (filters?.length > 0) {
      setSpendDetailedReport([]);
      setRevDetailedReport([]);
      const spendFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.spendApikey]: data.id } : null
      );
      const revFilterVariables = filters.map((data) =>
        data?.id?.length > 0 ? { [data?.revApikey]: data.id } : null
      );
      if (isFbReport) {
        if (isSAS) {
          revSasAdAccountTiktokFetch({
            variables: {
              ...tiktokVariables,
              ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
              ...Object.assign({}, ...revFilterVariables),
            },
          });
        } else if (isVS) {
          revenueVSLiveAdAccountFetch({
            variables: {
              ...fbRevVariables,
              group_by: fbRevVariables?.groupBy,
              is_forecast: fbRevVariables?.isForecast,
              ...Object.assign({}, ...spendFilterVariables),
            },
          });
        } else {
          revenueFbFetch({
            variables: {
              ...fbRevVariables,
              ...Object.assign({}, ...spendFilterVariables),
            },
          });
        }
        spendAdAccountFetch({
          variables: {
            ...fbSpendVariables,
            ...Object.assign({}, ...spendFilterVariables),
          },
        });
      } else {
        if (isLeadgen) {
          revenueFbFetch({
            variables: fbRevVariables,
            ...Object.assign({}, ...revFilterVariables),
          });
        } else {
          spendAdAccountTiktokFetch({
            variables: tiktokVariables,
            ...Object.assign({}, ...spendFilterVariables),
          });
        }
        revSasAdAccountTiktokFetch({
          variables: tiktokVariables,
          ...(isGlobalReport && { type: REPORT_TYPE_LIVE }),
          ...Object.assign({}, ...revFilterVariables),
        });
      }
    } else {
      if (isFbReport) {
        if (isSAS) {
          revSasAdAccountTiktokFetch({ variables: tiktokVariables });
        } else if (isVS) {
          revenueVSLiveAdAccountFetch({
            variables: {
              ...fbRevVariables,
              group_by: fbRevVariables?.groupBy,
              is_forecast: fbRevVariables?.isForecast,
            },
          });
        } else {
          revenueFbFetch({ variables: fbRevVariables });
        }
        spendAdAccountFetch({ variables: fbSpendVariables });
      } else {
        if (isLeadgen) {
          revenueFbFetch({ variables: fbRevVariables });
        } else {
          revSasAdAccountTiktokFetch({
            variables: tiktokVariables,
          });
        }
        spendAdAccountTiktokFetch({ variables: spendDetailedVariables });
      }
    }
  }, []);

  return (
    <div>
      <LookTable
        tableProps={tableAdAccountProps}
        refetch={isFbReport ? refetch_fb_AdAccount : refetch_tiktok_AdAccount}
        loading={loading}
        defaultPagination
        persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4}
        sizes={{ reduceExtraScrollHeightBy: 131 }} //used to reduce table height because summary card was taking the extra space at top
      />
    </div>
  );
}

export default AdAccountReports;
