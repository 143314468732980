import { Form, Button, Row, Col } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";
import { Formik } from "formik";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useEffect } from "react";
import { useSelector } from "react-redux";
export default function MainForm(props) {
  const { initialValues, validationSchema, onSubmit, btnText = "Update", loading = false } = props;
  const { umsCurrentWorkspaceUsersFetch = () => {} } = useApiToStoreContext();
  const workSpaceUsers = useSelector(
    (state) => state?.userSettings?.userSettingData?.umsCurrentWorkspaceUsers
  );

  useEffect(() => {
    umsCurrentWorkspaceUsersFetch();
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form layout="vertical">
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item label="User" className="look-form-input">
                  <SelectInput
                    className="mb-0"
                    formik
                    search
                    placeholder="Select user"
                    name="user_id"
                    value={values.user_id}
                    options={workSpaceUsers?.map(({ id, name }) => ({
                      name: name,
                      value: id,
                    }))}
                    onChange={setFieldValue}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <LookTextInput formik name="token" label="Token" placeholder="Enter token" />
              </Col>
            </Row>
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                className="submit-btn"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <Loader /> : btnText}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </>
  );
}
