import * as types from "../actions/actionTypes";

export default function scorecardReducer(state = { lob: "all", department: "all" }, action) {
  switch (action.type) {
    case types.SCORECARD_LOB_SELECTION:
      return { ...state, lob: action.payload };
    case types.SCORECARD_DEPARTMENT_SELECTION:
      return { ...state, department: action.payload };
    case types.SC_SELECTED_ASSIGNED_DEPARTMENT:
      return { ...state, selectedAssignedDepartment: action.payload };;
    default:
      return state;
  }
}
