import ActionIconRender from "components/ActionIconRender";
import {
  SAS_AGENCY_ADMIN,
  SAS_MANAGE_NATIVE_CAMPAIGN,
  SAS_PRODUCT_ADMIN,
  SAS_TEAM_ADMIN,
  SAS_USER,
  SAS_USER_AFD,
  SAS_USER_D2S,
  SAS_USER_RSFC,
  SAS_VENDOR_ACCOUNT,
  SAS_VIEW_CATEGORY,
  SAS_VIEW_DOMAIN,
  SAS_VIEW_KEYWORD,
  SAS_VIEW_NATIVE_CAMPAIGN,
  SAS_VIEW_USER,
  SAS_VIEW_WEBSITE,
  SAS_WORKSPACE_ADMIN,
  UMS_SUPER_ADMIN,
} from "../../UserPermission/userPermissionList";
import { useSelector } from "react-redux";
import useLaunchpathsObject from "./useLaunchpathsObject";
import { VAROZI_WORKSPACE_ID } from "pages/dashboardPages/Varozi/keysAndEnum";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_GOOGLE_DISPLAY,
  PLATFORM_ID_TABOOLA,
  PLATFORM_ID_TIKTOK,
  PRODUCT_ID_SAS,
} from "shared/SharedKeys";
import { usePermissionCheck } from "hooks";
import useRsocPathsObject from "./hooks/rsocPath";
import useRsocNewPathsObject from "./hooks/newRsocPath";

export default function useSasPath() {
  const agency_id = useSelector((state) => state?.userReducer?.me?.agency_id);

  const { launchpathsObject } = useLaunchpathsObject({ permission: SAS_USER });
  const { havePermission: isD2SWorkspacePermission } = usePermissionCheck(SAS_USER_D2S);
  const { havePermission: isRsfc } = usePermissionCheck(SAS_USER_RSFC);
  const { havePermission: isSasUser } = usePermissionCheck(SAS_USER);
  const { rsocpathsObject } = useRsocPathsObject({ permission: SAS_USER_RSFC });
  const isRsocRoutes = [rsocpathsObject] || [];
  const workspace = useSelector((state) => state?.userSettings?.selectedWorkspace);

  const isVaroziWorkspace = workspace === VAROZI_WORKSPACE_ID;

  const isLaunchRoutes = agency_id === 1 ? [launchpathsObject] : [];

  // :platform/:productID

  const isFBReport = !(isD2SWorkspacePermission || isRsfc)
    ? [
        {
          subMenuName: "Facebook Report",
          subMenuIcon: () => <ActionIconRender iconType="facebook-outlined" />,
          permission: SAS_VIEW_KEYWORD,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Postback Report",
              subMenuLink: "/reports/facebook-postback",
              subMenuIcon: () => <ActionIconRender iconType="barChartLine" />,
              permission: {
                platform: PLATFORM_ID_FACEBOOK,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
            {
              subMenuName: "Live Report",
              subMenuLink: "/reports/facebook-live",
              subMenuIcon: () => <ActionIconRender iconType="duration" />,
              permission: {
                platform: PLATFORM_ID_FACEBOOK,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
            {
              subMenuName: "Daily Report",
              subMenuLink: "/reports/facebook-daily",
              subMenuIcon: () => <ActionIconRender iconType="lineChart" />,
              permission: {
                platform: PLATFORM_ID_FACEBOOK,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
          ],
        },
      ]
    : [];

  const tikTokReports = !(isD2SWorkspacePermission || isRsfc)
    ? [
        {
          subMenuName: "Tiktok Report",
          subMenuIcon: () => <ActionIconRender iconType="tiktok" />,
          permission: SAS_VIEW_KEYWORD,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Postback Report",
              subMenuLink: "/reports/tiktok-postback",
              subMenuIcon: () => <ActionIconRender iconType="barChartLine" />,
              permission: {
                platform: PLATFORM_ID_TIKTOK,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
            {
              subMenuName: "Live Report",
              subMenuLink: "/reports/tiktok-live",
              subMenuIcon: () => <ActionIconRender iconType="duration" />,
              permission: {
                platform: PLATFORM_ID_TIKTOK,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
            {
              subMenuName: "Daily Report",
              subMenuLink: "/reports/tiktok-daily",
              subMenuIcon: () => <ActionIconRender iconType="lineChart" />,
              permission: {
                platform: PLATFORM_ID_TIKTOK,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
          ],
        },
      ]
    : [];

  const googleReports = !(isD2SWorkspacePermission || isRsfc)
    ? [
        {
          subMenuName: "GDN  Report",
          subMenuIcon: () => <ActionIconRender iconType="google" />,
          permission: SAS_VIEW_KEYWORD,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Postback Report",
              subMenuLink: "/reports/gdn-postback",
              subMenuIcon: () => <ActionIconRender iconType="barChartLine" />,
              permission: {
                platform: PLATFORM_ID_GOOGLE_DISPLAY,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_D2S,
                ],
              },
            },
            {
              subMenuName: "Live Report",
              subMenuLink: "/reports/gdn-live",
              subMenuIcon: () => <ActionIconRender iconType="duration" />,
              permission: {
                platform: PLATFORM_ID_GOOGLE_DISPLAY,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_D2S,
                ],
              },
            },
            {
              subMenuName: "Daily Report",
              subMenuLink: "/reports/gdn-daily",
              subMenuIcon: () => <ActionIconRender iconType="lineChart" />,
              permission: {
                platform: PLATFORM_ID_GOOGLE_DISPLAY,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_D2S,
                ],
              },
            },
          ],
        },
      ]
    : [];

  const isYahooReport = isD2SWorkspacePermission
    ? [
        {
          subMenuName: "Yahoo Reports",
          subMenuIcon: () => <ActionIconRender iconType="yahoo" />,
          isCollapsible: true,
          // permission: [],
          subMenus: [
            {
              subMenuName: "Yahoo and Bing Report",
              subMenuLink: "/yahoo/yahoo-and-bing-reports",
              subMenuIcon: () => <ActionIconRender iconType="yahoo" />,
              permission: {
                platform: PLATFORM_ID_FACEBOOK,
                permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN, SAS_USER_D2S],
              },
            },
            {
              subMenuName: "Yahoo user report",
              subMenuLink: "/yahoo/yahoo-user-reports",
              subMenuIcon: () => <ActionIconRender iconType="yahoo" />,
              permission: {
                platform: PLATFORM_ID_FACEBOOK,
                permission: [SAS_TEAM_ADMIN, SAS_AGENCY_ADMIN, SAS_WORKSPACE_ADMIN],
              },
            },
          ],
        },
      ]
    : [];

  const isSasUserReport = isSasUser
    ? [
        {
          subMenuName: "Configuration",
          subMenuIcon: () => <ActionIconRender iconType="setting" />,
          permission: [UMS_SUPER_ADMIN],
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Platforms",
              subMenuLink: "/platform",
              subMenuIcon: () => <ActionIconRender iconType="cluster" />,
              permission: [SAS_AGENCY_ADMIN, SAS_PRODUCT_ADMIN],
            },
            {
              subMenuName: "Verticals",
              subMenuLink: "/lookvertical",
              subMenuIcon: () => <ActionIconRender iconType="vertical" />,
              permission: [SAS_AGENCY_ADMIN, SAS_PRODUCT_ADMIN],
            },
            {
              subMenuName: "Categories",
              subMenuLink: "/lookcategory",
              subMenuIcon: () => <ActionIconRender iconType="priority" />,
              permission: [SAS_AGENCY_ADMIN, SAS_PRODUCT_ADMIN],
            },
            {
              subMenuName: "Pixels",
              subMenuLink: "/pixels",
              subMenuIcon: () => <ActionIconRender iconType="appstore" />,
              permission: [SAS_AGENCY_ADMIN, SAS_PRODUCT_ADMIN],
            },
            {
              subMenuName: "Content",
              subMenuLink: "/content",
              subMenuIcon: () => <ActionIconRender iconType="content" />,
              permission: [SAS_AGENCY_ADMIN, SAS_PRODUCT_ADMIN],
            },
            {
              subMenuName: "Tracking URL",
              subMenuLink: "/tracking-url",
              subMenuIcon: () => <ActionIconRender iconType="link-icon" />,
              permission: [SAS_AGENCY_ADMIN, SAS_PRODUCT_ADMIN],
            },
            {
              subMenuName: "Website",
              subMenuLink: "/website",
              subMenuIcon: () => <ActionIconRender iconType="link-icon" />,
              permission: [SAS_VIEW_WEBSITE],
            },
          ],
        },
        {
          subMenuName: "Advertisers",
          subMenuIcon: () => <ActionIconRender iconType="advertiser" />,
          permission: UMS_SUPER_ADMIN,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Domains",
              subMenuLink: "/advertisers/domains",
              subMenuIcon: () => <ActionIconRender iconType="global" />,
              permission: SAS_AGENCY_ADMIN,
            },
            {
              subMenuName: "Vendors",
              subMenuLink: "/advertisers/vendors",
              subMenuIcon: () => <ActionIconRender iconType="user" />,
              permission: SAS_AGENCY_ADMIN,
            },
            {
              subMenuName: "Vendor Accounts",
              subMenuLink: "/advertisers/vendor-accounts",
              subMenuIcon: () => <ActionIconRender iconType="team" />,
              permission: SAS_VENDOR_ACCOUNT,
            },
            {
              subMenuName: "Feeds",
              subMenuLink: "/advertisers/feeds",
              subMenuIcon: () => <ActionIconRender iconType="pic-left" />,
              permission: SAS_AGENCY_ADMIN,
            },
          ],
        },
        {
          subMenuName: "Vendors Report",
          subMenuIcon: () => <ActionIconRender iconType="collection" />,
          subMenuLink: "/reports/vendor-report",
          permission: [UMS_SUPER_ADMIN],
        },
      ]
    : [];
  const isRsocGlobalReport = isRsfc
    ? [
        {
          subMenuName: "Global Report",
          subMenuIcon: () => <ActionIconRender iconType="barChartLine" />,
          subMenuLink: "/global-report-rsoc",
          permission: [SAS_USER_RSFC],
        },
      ]
    : [
        {
          subMenuName: "Global Report",
          subMenuIcon: () => <ActionIconRender iconType="barChartLine" />,
          subMenuLink: "/global-report",
          permission: [SAS_USER],
        },
      ];
  const sasPath = [
    {
      name: "SAS",
      link: "/sas",
      icon: (e) => <ActionIconRender {...e} iconType="file-search" />,
      isVisible: isVaroziWorkspace,
      subMenu: [
        ...isRsocGlobalReport,
        {
          subMenuName: "Collections",
          subMenuIcon: () => <ActionIconRender iconType="collection" />,
          permission: [SAS_VIEW_KEYWORD, SAS_VIEW_CATEGORY],
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Keywords",
              subMenuLink: "/collections/keyword",
              subMenuIcon: () => <ActionIconRender iconType="keywordIcon" />,
              permission: SAS_VIEW_KEYWORD,
            },
            {
              subMenuName: "Categories",
              subMenuLink: "/collections/categories",
              subMenuIcon: () => <ActionIconRender iconType="priority" />,
              permission: SAS_VIEW_CATEGORY,
            },
          ],
        },
        {
          subMenuName: "Co-Pilot",
          subMenuIcon: () => <ActionIconRender iconType="copilot" />,
          isCollapsible: true,
          permission: [SAS_USER],
          subMenus: [
            {
              subMenuName: "Rules",
              subMenuLink: "/copilot/rules",
              subMenuIcon: () => <ActionIconRender iconType="rules" />,
            },
            {
              subMenuName: "All Rules",
              subMenuLink: "/copilot/allrules",
              subMenuIcon: () => <ActionIconRender iconType="rules" />,
            },
            {
              subMenuName: "Strategy",
              subMenuLink: "/copilot/strategy",
              subMenuIcon: () => <ActionIconRender iconType="tag" />,
              permission: [UMS_SUPER_ADMIN],
            },
          ],
        },
        {
          subMenuName: "Users",
          subMenuLink: "/users",
          subMenuIcon: () => <ActionIconRender iconType="user" />,
          permission: SAS_VIEW_USER,
        },
        {
          subMenuName: "Domains",
          subMenuLink: "/domains",
          subMenuIcon: () => <ActionIconRender iconType="global" />,
          permission: SAS_VIEW_DOMAIN,
        },

        {
          subMenuName: "Publishers",
          subMenuIcon: () => <ActionIconRender iconType="team" />,
          permission: SAS_AGENCY_ADMIN,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Agencies",
              subMenuLink: "/publishers/agency",
              subMenuIcon: () => <ActionIconRender iconType="team" />,
              permission: SAS_AGENCY_ADMIN,
            },
            {
              subMenuName: "Users",
              subMenuLink: "/publishers/user",
              subMenuIcon: () => <ActionIconRender iconType="user" />,
              permission: SAS_AGENCY_ADMIN,
            },
          ],
        },

        {
          subMenuName: "Campaigns",
          subMenuLink: "/campaigns",
          subMenuIcon: () => <ActionIconRender iconType="notification" />,
          permission: [SAS_VIEW_NATIVE_CAMPAIGN, SAS_MANAGE_NATIVE_CAMPAIGN],
        },
        {
          subMenuName: "Channel",
          subMenuLink: "/channel",
          subMenuIcon: () => <ActionIconRender iconType="collage" />,
          permission: [SAS_AGENCY_ADMIN],
        },
        {
          subMenuName: "External Channel",
          subMenuLink: "/external-channel",
          subMenuIcon: () => <ActionIconRender iconType="collage" />,
          permission: [SAS_USER_RSFC],
        },
        {
          subMenuName: "Tools",
          subMenuIcon: () => <ActionIconRender iconType="tool" />,
          permission: SAS_VIEW_KEYWORD,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "URL Builder",
              subMenuLink: "/url-builder",
              subMenuIcon: () => <ActionIconRender iconType="tool" />,
              permission: SAS_VIEW_KEYWORD,
            },
            {
              subMenuName: "Keyword Planner",
              subMenuLink: "/keyword-planner",
              subMenuIcon: () => <ActionIconRender iconType="keywordIcon" />,
              permission: SAS_VIEW_KEYWORD,
            },
            {
              subMenuName: "Buy Domains",
              subMenuLink: "/buy-domains",
              subMenuIcon: () => <ActionIconRender iconType="global" />,
              permission: SAS_VIEW_KEYWORD,
            },
            {
              subMenuName: "Vendor Sites",
              subMenuLink: "/vendor-sites",
              subMenuIcon: () => <ActionIconRender iconType="global" />,
              permission: SAS_VIEW_KEYWORD,
            },
          ],
        },

        // {
        //   subMenuName: "Category Report",
        //   subMenuIcon: () => <ActionIconRender iconType="priority" />,
        //   permission: SAS_VIEW_KEYWORD,
        //   isCollapsible: true,
        //   subMenus: [
        //     {
        //       subMenuName: "Daily Report",
        //       subMenuLink: "/categories-daily-report",
        //       subMenuIcon: () => <ActionIconRender iconType="barChartLine" />,
        //       permission: [
        //         SAS_TEAM_ADMIN,
        //         SAS_AGENCY_ADMIN,
        //         SAS_WORKSPACE_ADMIN,
        //         F360_VIEW_USERS_REPORT,
        //       ],
        //     },
        //     {
        //       subMenuName: "Live Report",
        //       subMenuLink: "/categories-live-report",
        //       subMenuIcon: () => <ActionIconRender iconType="duration" />,
        //       permission: [
        //         SAS_TEAM_ADMIN,
        //         SAS_AGENCY_ADMIN,
        //         SAS_WORKSPACE_ADMIN,
        //         F360_VIEW_USERS_REPORT,
        //       ],
        //     },
        //   ],
        // },

        ...isFBReport,
        ...isYahooReport,
        ...isSasUserReport,
        {
          subMenuName: "Taboola Report",
          subMenuIcon: () => <ActionIconRender iconType="taboola" />,
          permission: SAS_VIEW_KEYWORD,
          isCollapsible: true,
          subMenus: [
            {
              subMenuName: "Postback Report",
              subMenuLink: "/reports/taboola-postback",
              subMenuIcon: () => <ActionIconRender iconType="barChartLine" />,
              permission: {
                platform: PLATFORM_ID_TABOOLA,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
            {
              subMenuName: "Live Report",
              subMenuLink: "/reports/taboola-live",
              subMenuIcon: () => <ActionIconRender iconType="duration" />,
              permission: {
                platform: PLATFORM_ID_TABOOLA,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
            {
              subMenuName: "Daily Report",
              subMenuLink: "/reports/taboola-daily",
              subMenuIcon: () => <ActionIconRender iconType="lineChart" />,
              permission: {
                platform: PLATFORM_ID_TABOOLA,
                permission: [
                  SAS_TEAM_ADMIN,
                  SAS_AGENCY_ADMIN,
                  SAS_WORKSPACE_ADMIN,
                  SAS_USER,
                  SAS_USER_AFD,
                ],
              },
            },
          ],
        },
        ...tikTokReports,

        ...googleReports,

        ...isLaunchRoutes,
        {
          subMenuName: "RSoC Report",
          subMenuIcon: () => <ActionIconRender iconType="desktop" />,
          subMenuLink: "/reports/rsoc-report",
          permission: [SAS_USER_RSFC],
        },
        ...isRsocRoutes,
        // ...isRsocNewRoutes,
        // ...faqSubMenuPath,

        // {
        //   subMenuName: "Facebook Report",
        //   subMenuLink: "/reports/facebook",
        //   subMenuIcon: () => <ActionIconRender iconType="facebook" />,
        //   permission: SAS_VIEW_KEYWORD,
        // },

        // - UNCOMMENT
        // {
        //   subMenuName: "Launch",
        //   subMenuLink: "/launch",
        //   subMenuIcon: HeatMapOutlined,
        // },
        // {
        //   subMenuName: "Insights",
        //   subMenuLink: "/insights",
        //   subMenuIcon: StockOutlined,
        // },
        // {
        //   subMenuName: "FileTest",
        //   subMenuLink: "/file",
        //   subMenuIcon: StockOutlined,
        //   permission: SEARCH_ARB_USER,
        // },
      ].filter(({ isVisible }) => !isVisible),
    },
  ];
  return { sasPath };
}
