import { useState } from "react";
import ActionIconRender from "../../../../ActionIconRender";
import { Row, Col, Button, Badge } from "antd";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import cx from "classnames";
import { useSelector } from "react-redux";
import {
  CMS_DATA_KEY_BLOCK_LIST,
  CMS_DATA_KEY_PRODUCT_LIST,
} from "../../../../../actions/actionTypes";
import { LIST_BIT } from "../../PageBits";

export default function View(props) {
  const {
    image = "",
    title,
    content = "",
    description = "",
    allCategories = [],
    category_id = [],
    product_id = [],
    block_id = [],
    bottom_line = "",
    postType = "",
  } = props;
  const cmsData = useSelector((state) => state?.cmsReducer?.cmsData);

  let selectedProductsList = [];
  let selectedBlockList = [];
  if (postType === LIST_BIT) {
    product_id &&
      product_id?.forEach((id) => {
        const dataItem = cmsData[CMS_DATA_KEY_PRODUCT_LIST]?.filter((data) => data.id === id)[0];
        dataItem && selectedProductsList.push(dataItem);
      });

    block_id &&
      block_id?.forEach((id) => {
        const dataItem = cmsData[CMS_DATA_KEY_BLOCK_LIST]?.filter((data) => data.id === id)[0];
        dataItem && selectedBlockList.push(dataItem);
      });
  }
  let categories = [];

  allCategories?.forEach((e) => {
    categories.push({ id: e?.id, name: e.name });

    e?.children_category?.forEach((e) => {
      categories.push({ id: e?.id, name: e.name, isChildrenCategory: true });
    });
  });

  const visibleCategoriesName = categories.filter(
    (data) =>
      category_id?.filter((category_id_data) => parseInt(data?.id) === category_id_data).length > 0
  );

  const [isDesktopView, setIsDesktopView] = useState(true);

  const renderRightSide = () => {
    const lines = [];
    for (let i = 1; i <= 5; i++) {
      lines.push(i);
    }
    return lines.map((data, index) => (
      <div key={"lines" + index} className="mb-12">
        <Row gutter={15}>
          <Col span={7}>
            <div className="cms-image-placeholder">
              <div className="wrapper">
                <ActionIconRender iconType="image" />
              </div>
            </div>
          </Col>
          <Col span={17}>
            <p>Lorem Ipsume title {data}</p>
          </Col>
        </Row>
      </div>
    ));
  };

  const renderContent = () => (
    <div className="render-content">
      {image && <img src={image} alt={title} className="w-100 mb-10" />}
      <h2 className="main-title">{title}</h2>
      {visibleCategoriesName && visibleCategoriesName.length > 0 && (
        <div className="mb-20">
          {visibleCategoriesName?.map(({ name }) => (
            <Badge count={name} className="mr-6" />
          ))}
        </div>
      )}
      {content && parse(content)}
      {description && parse(description)}
      {/* {content && ReactHtmlParser(content)}
      {description && ReactHtmlParser(description)} */}
      {selectedProductsList &&
        selectedProductsList?.length > 0 &&
        selectedProductsList?.map(({ name = "", image = "", description = "" }, index) => (
          <div>
            <h1>
              {index + 1}. {name}
            </h1>
            {image && <img src={image} className="w-100 mb-16" alt={name} />}

            <div>{description && parse(description)}</div>
            {/* <div>{description && ReactHtmlParser(description)}</div> */}
          </div>
        ))}
      {selectedBlockList &&
        selectedBlockList?.length > 0 &&
        selectedBlockList?.map(({ title = "", image = "", description = "" }, index) => (
          <div>
            <h1>
              {index + 1}. {title}
            </h1>
            {image && <img src={image} className="w-100 mb-16" alt={title} />}
            <div>{description && parse(description)}</div>
            {/* <div>{description && ReactHtmlParser(description)}</div> */}
          </div>
        ))}
      {bottom_line && parse(bottom_line)}
      {/* {bottom_line && ReactHtmlParser(bottom_line)} */}
    </div>
  );

  const desktopView = () => (
    <div className="desktop-view">
      <div className="preview-header">
        <div className="logo">LOGO</div>
        <div className="preview-menus">
          <p>Home</p>
          <p>About</p>
          <p>Contact</p>
        </div>
      </div>
      <Row gutter={20}>
        <Col span={17}>{renderContent()}</Col>
        <Col span={7}>{renderRightSide()}</Col>
      </Row>
    </div>
  );
  const mobileView = () => (
    <div className="mobile-view">
      <div className="preview-header">
        <div className="logo">LOGO</div>
        <div className="preview-menus">
          <ActionIconRender iconType="menu" />
        </div>
      </div>
      {renderContent()}
    </div>
  );

  return (
    <>
      <div>
        {/* <Row>
          <Col span={12}>
            <pre style={{ height: 600 }}>
              {JSON.stringify({ selectedProductsList, selectedBlockList, props }, null, 2)}
            </pre>
          </Col>
          <Col span={12}>
            <pre style={{ height: 600 }}>{JSON.stringify({ cmsData }, null, 2)}</pre>
          </Col>
        </Row> */}
        <div className="post-preview-action text-center">
          <Button
            type="link"
            className={cx({ active: isDesktopView })}
            onClick={() => setIsDesktopView(true)}
            icon={<ActionIconRender iconType="desktop" />}
          />
          <Button
            type="link"
            className={cx({ active: !isDesktopView })}
            onClick={() => setIsDesktopView(false)}
            icon={<ActionIconRender iconType="mobile" />}
          />
        </div>

        <div className="post-preview">{isDesktopView ? desktopView() : mobileView()}</div>
        {/* <pre>{JSON.stringify({ props }, null, 2)}</pre> */}
      </div>
    </>
  );
}
