import { Form, Select, Button, Tooltip } from "antd";
import { useState } from "react";
import AddForm from "pages/dashboardPages/Cms/Brands/AddForm";
import { useCustomQuery } from "hooks/apolloClientHooks";
import Loader from "components/loader";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import { GET_CMS_BRANDS_ID_NAME, GET_CMS_STORE_ID_NAME } from "shared/gql/gqlSchema/cmsGql";

const { Option } = Select;

export default function BrandHandle(props) {
  const { value, onChange = () => {} } = props;
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const {
    data = [],
    loading,
    error,
    refetch,
  } = useCustomQuery(GET_CMS_BRANDS_ID_NAME, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: data_GET_CMS_STORE_ID_NAME = [],
    loading: loading_GET_CMS_STORE_ID_NAME,
    error: error_GET_CMS_STORE_ID_NAME,
    refetch: refetch_GET_CMS_STORE_ID_NAME,
  } = useCustomQuery(GET_CMS_STORE_ID_NAME, {
    notifyOnNetworkStatusChange: true,
  });

  const showDrawer = () => {
    setVisibleDrawer(true);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const drawerProps = {
    title: "Add New Brand",
    icon: () => <ActionIconRender iconType="appstore" />,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 700,
  };

  return (
    <div className="position-relative">
      {loading && <Loader elementCenter />}
      <LookDrawer {...drawerProps}>
        <AddForm refetch={refetch} allStores={data_GET_CMS_STORE_ID_NAME?.CMS_Stores} />
      </LookDrawer>
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex-grow-1">
            <Form.Item className="look-form-input mb-0">
              {/* <Select
                name="roles"
                className="w-100"
                placeholder="Select brand"
                onChange={onChange}
                value={value}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {data?.CMS_Brands?.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select> */}
              <SelectInput
                className="mb-0"
                placeholder="Select brand"
                search
                value={value}
                name="roles"
                options={data?.CMS_Brands?.map(({ id, name }) => ({ name: name, value: id }))}
                onChange={onChange}
              />
            </Form.Item>
          </div>
          <Tooltip title="Add">
            <Button
              type="link"
              onClick={showDrawer}
              icon={<ActionIconRender iconType="plus-circle" />}
            />
          </Tooltip>
        </div>
      </Form>
    </div>
  );
}
