import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { PLATFORM_TYPE_TIKTOK } from "shared/enum/launchKeysAndEnum";
import { TIKTOK_USER } from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const TiktokLandingUrls = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "LandingUrls" */ "pages/dashboardPages/TikTok/LandingUrls")
  )
);
const TrackerCategory = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Leadgen-B-TrackerCategory" */ "pages/dashboardPages/Leadgen/TrackerCategory"
    )
  )
);
const TiktokAccounts = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "TikTok-Accounts" */ "pages/dashboardPages/TikTok/Accounts")
  )
);
const TiktokBCMembers = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "BCMembers" */ "pages/dashboardPages/TikTok/BCMembers")
  )
);
const TiktokLeadgenReports = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "TikTok-Reports" */ "pages/dashboardPages/TikTok/Reports")
  )
);
const TiktokUsers = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "TikTok-Users" */ "pages/dashboardPages/TikTok/Users")
  )
);

const tiktokRoutes = [
  route("/tiktok/users", TiktokUsers, ROUTE_DASHBOARD, true, TIKTOK_USER),
  route("/tiktok/accounts", TiktokAccounts, ROUTE_DASHBOARD, true, TIKTOK_USER),
  route("/tiktok/tiktok-reports", TiktokLeadgenReports, ROUTE_DASHBOARD, false, TIKTOK_USER),
  route("/tiktok/bc-members", TiktokBCMembers, ROUTE_DASHBOARD, true, TIKTOK_USER),
  route("/tiktok/bc-members", TiktokBCMembers, ROUTE_DASHBOARD, true, TIKTOK_USER),
  route(
    "/tiktok/category",
    () => <TrackerCategory platformType={PLATFORM_TYPE_TIKTOK} />,
    ROUTE_DASHBOARD,
    true,
    TIKTOK_USER
  ),
  route("/tiktok/landing-urls", TiktokLandingUrls, ROUTE_DASHBOARD, true, TIKTOK_USER),
];

export default tiktokRoutes;
