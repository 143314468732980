import { Tabs, Tooltip } from "antd";
import cx from "classnames";
import { TAB_RENDER_KEY_THEME_DEFAULT } from "./TabRenderKeys";
const { TabPane } = Tabs;

export default function TabRender(props) {
  const {
    tabContent = [],
    theme = TAB_RENDER_KEY_THEME_DEFAULT,
    className = "",
    contentClassName = "",
    tabClassName = "",
    fullBtn = false,
    value,
    onChange,
    size,
    noOperations = false,
  } = props;

  function renderTabs() {
    return tabContent.map(({ id, title, icon: Icon, component, hoverTitle, disabled = false }) => (
      <TabPane
        tab={
          <>
            <Tooltip title={hoverTitle}>
              {Icon && <Icon style={{ marginRight: title ? undefined : 0 }} />}
              {title}
            </Tooltip>
          </>
        }
        className={tabClassName}
        key={id}
        disabled={disabled}
      >
        <div className={contentClassName}>{component}</div>
      </TabPane>
    ));
  }

  let tabProps = {};

  if (onChange) {
    tabProps = {
      activeKey: value,
      onChange,
    };
  }
  return (
    <Tabs
      {...tabProps}
      className={cx("look-tab look-tab-" + theme, className, {
        fullBtn,
        "no-operations": noOperations,
      })}
      size={size}
    >
      {renderTabs()}
    </Tabs>
  );
}
