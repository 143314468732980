import cx from "classnames";
import { Button, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";

function RenderActionOptions(props) {
  const { title, option, selectedRows = [], onClick = () => {}, loading = false } = props;
  return (
    <div className="px-2">
      <Tooltip title={title} placement="bottom">
        <Button
          loading={loading}
          icon={<ActionIconRender iconType={option} />}
          onClick={() => onClick(selectedRows)}
        ></Button>
      </Tooltip>
    </div>
  );
}

export default function LookTableBulkAction(props) {
  const {
    actionOptions = [],
    selectedRows = [],
    onDismiss = () => {},
    label = "Selected Rows",
  } = props;

  // NEW Bulk conditions
  const mainSelectedRows = selectedRows;
  const selectedDataLength = mainSelectedRows?.length || 0;
  const active = selectedDataLength > 0 && actionOptions.length > 0;

  return (
    <div className={cx("LookTableBulkAction", { active })}>
      <div className="main-content">
        <div className="holder">
          <div className="content left-content">
            <p className="mb-0">
              <b>{label}:</b> {selectedDataLength}
            </p>
          </div>
          <div className="content action-content">
            <div className="d-flex align-items-center">
              {actionOptions.map((data, index) => (
                <RenderActionOptions
                  key={"actionOptions" + index}
                  {...data}
                  selectedRows={selectedRows}
                />
              ))}
            </div>
          </div>
          <div className="content right-content"></div>
          {active && (
            <Button className="dismiss" onClick={onDismiss}>
              <ActionIconRender iconType="close" />
              Dismiss
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
