import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GET_F360_COUNTRY_REPORT } from "shared/gql/gqlSchema/fb360Gql";

function CountryReport(props) {
  const {
    adAccountId,
    assetId,
    type,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
    timezone: timeZoneProps,
  } = props;

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const dateFormat = "YYYY-MM-DD";
  const [countryReport, setCountryReport] = useState([]);
  const defaultDate = [
    moment().subtract(8, "d").format(dateFormat),
    moment().subtract(1, "d").format(dateFormat),
  ];
  const [rangeDates, setRangeDates] = useState([...defaultDate]);
  const handleDateRange = (e) => {
    setRangeDates(e.rangeDates);
  };

  let finalMergeData = [];

  const {
    data: data_GET_REPORT_DATA,
    loading: loading_GET_REPORT_DATA,
    error: error_GET_REPORT_DATA,
    refetch: refetch_GET_REPORT_DATA,
  } = useCustomQuery(GET_F360_COUNTRY_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.fb360_countryReporting?.reports;
      if (element) {
        setCountryReport(element);
      }
    },
    variables: {
      start: rangeDates[0],
      end: rangeDates[1],
      adaccount_id: adAccountId,
      asset_id: assetId,
      asset: type,
    },
  });

  const refetch = () => {
    refetch_GET_REPORT_DATA();
  };

  const loading = loading_GET_REPORT_DATA;

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const initialColumns = [
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      sorter: true,
      fixed: "left",
    },

    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (value) => <LookTableColumnRender title={`$${valueCheck(value, true)}`} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spend - b?.spend,
    },

    {
      title: "Inline Link Clicks",
      dataIndex: "inline_link_clicks",
      key: "inline_link_clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.inline_link_clicks - b?.inline_link_clicks,
    },

    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicks - b?.clicks,
    },

    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
    },

    {
      title: "Results",
      dataIndex: "result",
      key: "result",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.result - b?.result,
    },
  ];

  if (!loading) {
    finalMergeData = countryReport?.map((element, indx) => {
      const { spend = 0, clicks = 0 } = element;
      return {
        ...element,
      };
    });
  }

  const finalFilterData = finalMergeData.filter(({ country }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (country?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_spend = 0,
    total_clicks = 0,
    total_result = 0,
    total_inline_link_clicks = 0,
    total_impressionsFB = 0;

  for (const {
    spend = 0,
    clicks = 0,
    result = 0,
    inline_link_clicks = 0,
    impressionsFB = 0,
  } of finalFilterData) {
    total_spend += parseFloat(spend || 0);
    total_clicks += parseFloat(clicks || 0);
    total_inline_link_clicks += parseFloat(inline_link_clicks || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_result += parseFloat(result || 0);
  }

  const summaryData = {
    spend: valueCheck(total_spend, true),
    clicks: valueCheck(total_clicks),
    inline_link_clicks: valueCheck(total_inline_link_clicks),
    impressionsFB: valueCheck(total_impressionsFB),
    result: valueCheck(total_result),
  };

  const tableMainProps = {
    persistKey,
    exportCSV: true,
    isDateRange: true,

    defaultPagination: true,
    refetch,
    loading,
    dateRangeProps: {
      noStore: true,
      defaultDate: defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
  };

  const debugContent = [{ isRow: true, contentObjects: [{ countryReport }, { finalFilterData }] }];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default CountryReport;
