import ActionIconRender from "components/ActionIconRender";
import cx from "cx";

const { Tooltip, Button } = require("antd");
const { default: openNotification } = require("./openNotification");
const { Fragment, useRef } = require("react");

export function ClickToCopyButton({
  copyText = "",
  tooltipText = "",
  successText = "",
  className = "",
}) {
  const copyRef = useRef();
  const copyToClipboard = (e) => {
    copyRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    openNotification({ type: "success", message: successText });
  };
  const ToolTipWrap = tooltipText ? Tooltip : Fragment;
  return (
    <>
      <div className="w-0 h-0 position-absolute opacity-0">
        <textarea ref={copyRef} value={copyText} />
      </div>
      {document.queryCommandSupported("copy") && (
        <ToolTipWrap title={tooltipText}>
          <Button
            onClick={copyToClipboard}
            className={cx("border-0 copy-btn p-0 bg-transparent", className)}
          >
            <ActionIconRender iconType="copy" />
          </Button>
        </ToolTipWrap>
      )}
    </>
  );
}
