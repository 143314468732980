import { useEffect, useState } from "react";
import { DatePicker, Button, Tooltip } from "antd";
import moment from "moment";
// import SelectTimeZone from "../SelectTimeZone";

import { useDispatch, useSelector } from "react-redux";
import { DATE_RANGE, DATE_RANGE_TIMEZONE } from "actions/actionTypes";
import SelectTimeZone from "../../SelectTimeZone";
import ActionIconRender from "components/ActionIconRender";
import { defaultTimeZone as defaultTimeZone_reducer } from "reducers/userSettings";
import cx from "cx";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";

const { RangePicker } = DatePicker;

export default function DateRangePersist(props) {
  const dateFormat = "YYYY-MM-DD";

  const dispatch = useDispatch();
  const currentDateRange = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const currentDateRangeNew = useSelector((state) => state?.userSettings?.dateRange);

  //* if we want date range picker without timezone, then we have to initialise the current date with currentDateRangeNew
  //* currentDateRange is for default functionality (with timezone)

  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const currentReducerTimezone = currentDateRange?.timeZone;

  const {
    isTimezone = true,
    defaultDate = [moment().format(dateFormat), moment().format(dateFormat)],
    defaultTimeZone = currentReducerTimezone?.value || defaultTimeZone_reducer,
    onChange = () => {},
    noStore = false,
    disabledTimeZone = false,
    disableFutureDates = true,
    skipReducerTimeZoneChange = false,
  } = props;

  const [open, setOpen] = useState(false);

  const [rangeDates, setRangeDates] = useState(
    !noStore
      ? !isTimezone
        ? currentDateRangeNew?.rangeDates
        : currentDateRange?.rangeDates || defaultDate
      : defaultDate
  );

  const [rangeDatesNew, setRangeDatesNew] = useState(defaultDate);

  // const [timeZone, setTimeZone] = useState(
  //   !noStore
  //     ? currentDateRange
  //       ? currentDateRange?.timeZone || { value: defaultTimeZone }
  //       : { value: defaultTimeZone }
  //     : { value: defaultTimeZone }
  // );

  const [timeZone, setTimeZone] = useState({ value: defaultTimeZone });

  const [timeZoneNew, setTimeZoneNew] = useState({ value: defaultTimeZone });

  useEffect(() => {
    if (!(currentDateRange && noStore)) {
      setRangeDatesNew(rangeDates);
      if (isTimezone) {
        if (skipReducerTimeZoneChange) {
          dispatch({
            type: DATE_RANGE_TIMEZONE,
            payload: { rangeDates, timeZone: currentReducerTimezone },
          });
        } else {
          dispatch({ type: DATE_RANGE_TIMEZONE, payload: { rangeDates, timeZone } });
        }
      } else {
        dispatch({ type: DATE_RANGE, payload: { rangeDates } });
      }
    }
  }, []);

  const timeZoneProps = {
    value: timeZone,
    disabled: disabledTimeZone,
    onChange: (e) => {
      setTimeZone(e);
      setRangeDatesNew([
        moment(rangeDates[0]).tz(timeZoneNew.value).format(dateFormat),
        moment(rangeDates[1]).tz(timeZoneNew.value).format(dateFormat),
      ]);
    },
  };

  const setResponse = () => {
    setRangeDatesNew(rangeDates);
    setTimeZoneNew(timeZone);
    setOpen(false);
    onChange({ rangeDates, timeZone });
    if (!noStore) {
      if (isTimezone) {
        if (skipReducerTimeZoneChange) {
          dispatch({
            type: DATE_RANGE_TIMEZONE,
            payload: { rangeDates, timeZone: currentReducerTimezone },
          });
        } else {
          dispatch({ type: DATE_RANGE_TIMEZONE, payload: { rangeDates, timeZone } });
        }
      } else {
        dispatch({ type: DATE_RANGE, payload: { rangeDates } });
      }
    }
  };
  const closeResponse = () => {
    setRangeDates(rangeDatesNew);
    setTimeZone(timeZoneNew);
    setOpen(false);
  };

  const DATE_PREVIOUS = "previous";
  const DATE_NEXT = "next";

  const handlePreviousNextData = (type) => {
    let newData = [];
    if (type === DATE_PREVIOUS) {
      newData = [
        moment(rangeDates[0]).subtract(1, "days").format(dateFormat),
        moment(rangeDates[1]).subtract(1, "days").format(dateFormat),
      ];
    }
    if (type === DATE_NEXT) {
      const nextDate1 = moment(rangeDates[0]).add(1, "days");
      const nextDate2 = moment(rangeDates[1]).add(1, "days");
      const endOfDay = moment().endOf("day");
      const dateCondition1 = nextDate1 < endOfDay;
      const dateCondition2 = nextDate2 < endOfDay;

      newData = [
        dateCondition1 ? nextDate1.format(dateFormat) : rangeDates[0],
        dateCondition2 ? nextDate2.format(dateFormat) : rangeDates[1],
      ];
    }

    setRangeDates(newData);
    setRangeDatesNew(newData);
    onChange({ rangeDates: newData, timeZone });
    if (!noStore) {
      if (skipReducerTimeZoneChange) {
        dispatch({
          type: DATE_RANGE_TIMEZONE,
          payload: { rangeDates: newData, timeZone: currentReducerTimezone },
        });
      } else {
        dispatch({ type: DATE_RANGE_TIMEZONE, payload: { rangeDates: newData, timeZone } });
      }
    }
    if (!isTimezone) {
      dispatch({ type: DATE_RANGE, payload: { rangeDates: newData } });
    }
  };

  const ranges = {
    Today: [moment().tz(timeZoneNew.value), moment().tz(timeZoneNew.value)],
    Yesterday: [
      moment().tz(timeZoneNew.value).subtract(1, "days"),
      moment().tz(timeZoneNew.value).subtract(1, "days"),
    ],
    "Last 7 Days": [
      moment().tz(timeZoneNew.value).subtract(7, "d"),
      moment().tz(timeZoneNew.value),
    ],
    "Last 30 Days": [
      moment().tz(timeZoneNew.value).subtract(30, "d"),
      moment().tz(timeZoneNew.value),
    ],
    "This Month": [moment().tz(timeZoneNew.value).startOf("month"), moment().tz(timeZoneNew.value)],
    "Last Month": [
      moment().tz(timeZoneNew.value).subtract(1, "months").startOf("month"),
      moment().tz(timeZoneNew.value).subtract(1, "months").endOf("month"),
    ],
  };

  const rangesWithoutTimezone = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(7, "d"), moment()],
    "This Month": [moment().startOf("month"), moment()],
    // "Last 30 Days": [moment().subtract(30, "d"), moment()],
    "Last Month": [
      moment().subtract(1, "months").startOf("month"),
      moment().subtract(1, "months").endOf("month"),
    ],
  };

  const renderExtraFooter = () => (
    <div>
      <div
        className={`d-flex align-items-center justify-content-between ${cx({
          "flex-end": !isTimezone,
        })}`}
      >
        {isTimezone && (
          <div>
            <SelectTimeZone {...timeZoneProps} />
          </div>
        )}
        <div>
          <Button onClick={closeResponse}>Close</Button>
          <Button type="primary" className="ml-8" onClick={setResponse}>
            Set
          </Button>
        </div>
      </div>
    </div>
  );

  // const disableDates = (current) => {
  //   return current && current > moment().endOf("day");
  // };

  const disableDates = (current) => {
    if (LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)) {
      const fixedDate = moment("2023-06-17", "YYYY-MM-DD").tz(timeZoneNew.value); // Fixed date: June 17, 2023
      return (
        (current && current < fixedDate.startOf("day")) ||
        (current && current > moment().endOf("day"))
      );
    }
    return current && current > moment().endOf("day");
  };

  return (
    <div>
      <div className="flex">
        <RangePicker
          className="look-persist-date-range"
          defaultValue={[moment(rangeDates[0], dateFormat), moment(rangeDates[1], dateFormat)]}
          value={[moment(rangeDates[0], dateFormat), moment(rangeDates[1], dateFormat)]}
          onChange={(e, c) => {
            setRangeDates(c);
          }}
          disabledDate={disableFutureDates ? (current) => disableDates(current) : undefined}
          open={open}
          onOpenChange={() => setOpen(true)}
          ranges={LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)? {} : isTimezone ? ranges : rangesWithoutTimezone}
          renderExtraFooter={open && renderExtraFooter}
        />
        <Tooltip placement="topLeft" title="Previous Date">
          <Button
            size="small"
            className="filter-btn ml-8"
            onClick={() => handlePreviousNextData(DATE_PREVIOUS)}
          >
            <ActionIconRender iconType="left" />
          </Button>
        </Tooltip>
        <Tooltip placement="topLeft" title="Next Date">
          <Button
            size="small"
            className="filter-btn ml-8"
            onClick={() => handlePreviousNextData(DATE_NEXT)}
          >
            <ActionIconRender iconType="right" />
          </Button>
        </Tooltip>
      </div>

      {/* <pre>{JSON.stringify({ rangeDates, rangeDatesNew, timeZoneNew }, null, 2)}</pre>
      <pre>{JSON.stringify({ currentDateRange }, null, 2)}</pre> */}
    </div>
  );
}
