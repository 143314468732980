import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";

export const LookConfirmationModal = (props) => {
  const {
    visible = false,
    closable = false,
    footer = false,
    onCancel = () => {},
    confirmationProps = {},
  } = props;
  const { title = "Title", description = "Description" } = confirmationProps;

  const {
    oKText = " Yes, Leave",
    handleOk = () => {},
    cancelText = "No",
    handelCancel = () => {},
  } = confirmationProps?.buttonProps || {};

  return (
    <LookModal
      visible={visible}
      onCancel={onCancel}
      closable={closable}
      footer={footer}
      className="confirmation-body"
    >
      {visible && (
        <>
          <div className="confirmation-container">
            <ActionIconRender className="icon" iconType="Disclaimer-outlined"></ActionIconRender>
            <div className="confirmation-msg">
              <div className="title">{title}</div>
              <div className="description">{description}</div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center mt-24">
            <LookButton onClick={handelCancel}>{cancelText}</LookButton>
            <LookButton
              className="ml-12 bg-transparent confirm-button"
              danger
              type={""}
              onClick={handleOk}
            >
              {oKText}
            </LookButton>
          </div>
        </>
      )}
    </LookModal>
  );
};
