import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { GET_LAUNCHED_ADS, GET_LAUNCHED_TIKTOK_ADS } from "shared/gql/gqlSchema/sasGql";

function Ads(props) {
  const [ads, setAds] = useState();
  const { isTiktokPlatformLaunch, launchId, id } = props;

  const [
    fetchFbAds,
    {
      data: data_GET_ADS_DATA,
      loading: loading_GET_ADSET_DATA,
      error: error_GET_ADS_DATA,
      refetch: refetch_GET_ADSET_DATA,
    },
  ] = useCustomLazyQuery(GET_LAUNCHED_ADS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.launchedAds;
      if (element) {
        setAds(element);
      }
    },
  });
  const [
    fetchTiktokAds,
    {
      data: data_GET_TIKTOK_ADS_DATA,
      loading: loading_GET_TIKTOK_ADSET_DATA,
      error: error_GET_TIKTOK_ADS_DATA,
      refetch: refetch_GET_TIKTOK_ADSET_DATA,
    },
  ] = useCustomLazyQuery(GET_LAUNCHED_TIKTOK_ADS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktokLaunchedAds;
      if (element) {
        setAds(element);
      }
    },
  });
  useEffect(() => {
    if (isTiktokPlatformLaunch) {
      fetchTiktokAds({
        variables: {
          launchId,
          adsetId: id,
        },
      });
    } else {
      fetchFbAds({
        variables: {
          adsetId: id,
        },
      });
    }
  }, [isTiktokPlatformLaunch]);
  const initialColumns = [
    {
      title: "Account ID",
      dataIndex: "advertiserId",
      key: "advertiserId",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: true,
      fixed: "left",
    },

    {
      title: "Ads ID",
      dataIndex: "AdId",
      key: "AdId",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={isTiktokPlatformLaunch ? otherProps?.adId : otherProps?.fbAdId}
        />
      ),
      width: 90,
      minWidth: 90,
      sorter: (a, b) => {
        if (isTiktokPlatformLaunch) return a?.adId - b?.adId;
        else return a?.fbAdId - b?.fbAdId;
      },
    },

    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.createdDate - b?.createdDate,
    },
  ];
  const tableMainProps = {
    refetch: isTiktokPlatformLaunch ? refetch_GET_TIKTOK_ADSET_DATA : refetch_GET_ADSET_DATA,
    loading: loading_GET_ADSET_DATA || loading_GET_TIKTOK_ADSET_DATA,
    tableProps: { initialColumns, recordData: ads },
    isRowSelection: false,
  };

  return (
    <div>
      <LookTable {...tableMainProps} isSrNo />
    </div>
  );
}

export default Ads;
