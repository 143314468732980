export const INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_DAILY = "DAILY";
export const INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_MONTHLY = "MONTHLY";
export const INDUSTRY_ISIGHTS_DEFAULT_FREQUENCY_WEEKLY = "WEEKLY";

export const industryInsightsNotifyAbout = [
  { label: "AD", value: "AD" },
  { label: "DOMAIN", value: "DOMAIN" },
  { label: "PAGE", value: "PAGE" },
];

export const INDUSTRY_ISIGHTS_PREFERENCES_SYSTEM = "SYSTEM";
export const INDUSTRY_ISIGHTS_PREFERENCES_SLACK = "SLACK";
export const INDUSTRY_ISIGHTS_PREFERENCES_EMAIL = "EMAIL";

export const industryInsightsNotifyPrefrences = [
  { label: "EMAIL", value: "EMAIL" },
  { label: "SLACK", value: "SLACK" },
  { label: "SYSTEM", value: "SYSTEM" },
];

export const INDUSTRY_ISIGHTS_TYPE_AD = "AD";
export const INDUSTRY_ISIGHTS_TYPE_DOMAIN = "DOMAIN";
export const INDUSTRY_ISIGHTS_TYPE_PAGE = "PAGE";

export const industryInsightsFrequencies = [
  { name: "DAILY", value: "DAILY" },
  { name: "MONTHLY", value: "MONTHLY" },
  { name: "WEEKLY", value: "WEEKLY" },
];

export const industryInsightsDays = [
  { name: "Monday", value: "MONDAY" },
  { name: "Tuesday", value: "TUESDAY" },
  { name: "Wednesday", value: "WEDNESDAY" },
  { name: "Thursday", value: "THURSDAY" },
  { name: "Friday", value: "FRIDAY" },
  { name: "Saturday", value: "SATURDAY" },
  { name: "Sunday", value: "SUNDAY" },
];

export const industryInsightsDefaultNotifyAbout = [
  {
    name: "Ads",
    value: INDUSTRY_ISIGHTS_TYPE_AD,
    description: "Get notified when system found new ads of Domain/page.",
  },
  {
    name: "Page",
    value: INDUSTRY_ISIGHTS_TYPE_PAGE,
    description: "Get notified when system found new pages of Domain.",
  },
  {
    name: "Domain",
    value: INDUSTRY_ISIGHTS_TYPE_DOMAIN,
    description: "Get notified when system found new domains on page",
  },
];

export const industryInsightsDefaultNotificationPreferences = [
  {
    name: "System",
    value: INDUSTRY_ISIGHTS_PREFERENCES_SYSTEM,
    description: "Receive notifications directly on your desktop for real-time updates.",
  },
  {
    name: "Email",
    value: INDUSTRY_ISIGHTS_PREFERENCES_EMAIL,
    description: "Stay updated with notifications sent directly to your email inbox.",
  },
  {
    name: "Slack integrations",
    value: INDUSTRY_ISIGHTS_PREFERENCES_SLACK,
    description:
      "Integrate notifications seamlessly into your Slack workspace for efficient collaboration.",
  },
  // {
  //   name: "Lookfinity mobile app",
  //   value: INDUSTRY_ISIGHTS_TYPE_DOMAIN,
  //   description: "Get instant updates on your mobile app wherever you go.",
  // },
];

export const industryInsightsFilterPopupCategoryLabels = {
  COUNTRIES: "Country",
  DOMAINS: "Domain",
  PAGES: "Pages",
};
export const INDUSTRY_ISIGHTS_STATUS_ACTIVE = "ACTIVE";
export const INDUSTRY_ISIGHTS_STATUS_INACTIVE = "INACTIVE";
