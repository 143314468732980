import { Skeleton } from "antd";
import cx from "cx";
import ActionIconRender from "../../../../../components/ActionIconRender";

export function OverviewCard(props) {
  const {
    skeleton = false,
    iconType = "",
    iconClassName = "",
    title = "",
    value = "" || <></>,
    valueBy = "",
    isSmall = false,
  } = props;

  function renderSkeleton() {
    return (
      <>
        <div className="mr-8">
          <Skeleton.Input
            active
            className="light"
            style={{ width: 30, minWidth: 18 }}
            size={"small"}
          />
        </div>
        <div>
          <div className="mb-12">
            <Skeleton.Input
              active
              className="light"
              style={{ width: 40, minWidth: 18 }}
              size={"small"}
            />
          </div>
          <div className="mb-10">
            <Skeleton.Input active style={{ width: 70, minWidth: 40 }} size={"small"} />
          </div>
          {/* <div className="">
            <Skeleton.Input
              active
              className="light"
              style={{ width: 40, minWidth: 18, height: 16 }}
              size={"small"}
            />
          </div> */}
        </div>
      </>
    );
  }

  function iconColor(title) {
    let color = "";

    switch (title) {
      case "Spend":
      case "Ads":
      case "Consumed":
        color = "#E84749";
        break;
      case "Revenue":
      case "Signed Out":
      case "On Track":
      case "Remaining":
      case "Campaign":
        color = "#E8B339";
        break;
      case "Signed In":
      case "Gross Margin":
      case "Assigned":
      case "Completed":
        color = "#10B004";
        break;
      case "Working Hours":
      case "Total":
      case "Run rate":
      case "Launches":
      case "Fiscal year":
        color = "#854ECA";
        break;
      default:
        break;
    }

    return color;
  }

  return (
    <div className={`overview-card ${cx(isSmall ? "overview-card-sm" : "overview-card-lg")}`}>
      {skeleton ? (
        renderSkeleton()
      ) : isSmall ? (
        <>
          {iconType && (
            <div className="d-flex">
              <span style={{ color: iconColor(title) }} className="icon mr-10">
                <ActionIconRender iconType={iconType} className={iconClassName} />
              </span>
              <div>
                <span className="title">{title}</span>
                <p className="value">{value}</p>
                <p className="valueBy">{valueBy}</p>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {iconType && (
            <div>
              <span className="icon mr-10 text-primary">
                <ActionIconRender iconType={iconType} className={iconClassName} />
              </span>
            </div>
          )}
          <div>
            <p className="title">{title}</p>
            <p className="value">{value}</p>
            <p className="valueBy">{valueBy}</p>
          </div>
        </>
      )}
    </div>
  );
}

export function OverviewCalculatedCard(props) {
  const { skeleton = false, title = "", value = "" || <></> } = props;
  function renderSkeleton() {
    return (
      <>
        <div className="mb-8">
          <Skeleton.Input
            active
            className="light"
            style={{ width: 40, minWidth: 18 }}
            size={"small"}
          />
        </div>
        <div className="mb-12">
          <Skeleton.Input active style={{ width: 60, minWidth: 18 }} size={"small"} />
        </div>
      </>
    );
  }
  return (
    <div className="overview-card overview-card-xs">
      {skeleton ? (
        renderSkeleton()
      ) : (
        <>
          <p className="title">{title}</p>
          <p className="value">{value}</p>
        </>
      )}
    </div>
  );
}
