import React, { useEffect, useState } from "react";
import { Image } from "antd";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import ActionIconRender from "components/ActionIconRender";
import {
  PLATFORM_TYPE_FACEBOOK,
  PLATFORM_TYPE_INSTAGRAM,
  PLATFORM_TYPE_MESSENGER,
  PLATFORM_TYPE_TIKTOK,
} from "shared/enum/launchKeysAndEnum";
import { ADS_TYPE_CARAOUSEL, ADS_TYPE_STATIC, DYNAMIC_ADS_KEY } from "../industryInsightsEnum";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LookButton from "components/LookButton";
import { useRef } from "react";
import LookModal from "components/LookModal";
import AddToCreativeLib from "./AddToCreativeLib";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import moment from "moment";

export default function AdsDetailedDrawer(props) {
  const {
    adCreative = [],
    adCreativeType = "image",
    adCreationTime = "",
    adDeliveryStopTime = "",
    adDeliveryStartTime = "",
    adPage = {},
    adDomain = [],
    adBodies = [],
    languages = [],
    adsType = ADS_TYPE_STATIC,
    publisherPlatforms = [],
    getActiveDays = () => {},
  } = props;
  const [visibleCategoryModal, setVisibleCategoryModal] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const currentDate = moment().format("YYYY-MM-DD");
  const isAdActive = moment(adDeliveryStopTime)?.isSameOrAfter(currentDate, "day");

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const staticView = () => {
    const adsData = adCreative?.[0];
    const title = { title: adsData?.title?.value };
    const body = { body: adBodies?.[0]?.value };
    const description = { description: adsData?.linkDescription?.value };
    const image = { s3Url: adsData?.images?.[0]?.url };
    const ctaType = adsData?.ctaType;
    const video = {
      s3Url: adsData?.videos?.[0]?.url,
      thumbUrl: adsData?.videos?.[0]?.thumbnail?.url,
    };

    return (
      <>
        <div className="creative-report-card">
          <div className="position-relative">
            <div className="creative-report-card-image">
              <CardCreativeAdGroup
                title={title}
                body={body}
                description={description}
                image={image}
                isGroup
                ctaType={ctaType}
                ctaLink={adsData?.linkUrl}
                video={video}
                isDetailedView
                groupType={adsType}
                onlyContent
                adDomain={adDomain?.[0]}
                adPage={adPage}
                isIndustryInsights
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  const dynamicView = () => {
    return (
      <>
        <div className="creative-report-card">
          <div className="position-relative">
            <div className="creative-report-card-image">
              <div className="p-10 w-100">
                <div className="d-flex flex-grow-1">
                  <div className="d-flex flex-grow-1 ">
                    <div>
                      <Image preview={false} style={{ width: 40, height: 40, borderRadius: 6 }} />
                    </div>
                    <div className="flex-grow-1 ml-10">
                      <h3 className="mb-0">{adPage?.name}</h3>
                      <div> {adsType} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Slider asNavFor={nav2} ref={(slider) => (sliderRef1 = slider)}>
            {adCreative?.map((adDetails) => {
              const title = { title: adDetails?.title?.value };
              const body = { body: adDetails?.body?.value };
              const image = { s3Url: adDetails?.images?.[0]?.url };
              const video = {
                s3Url: adDetails?.videos?.[0]?.url,
                thumbUrl: adDetails?.videos?.[0]?.thumbnail?.url,
              };
              const description = { description: adDetails?.linkDescription?.value };
              const ctaType = adDetails?.ctaType;
              return (
                <>
                  <CardCreativeAdGroup
                    title={title}
                    isDynamic
                    body={body}
                    description={description}
                    image={image}
                    isGroup
                    ctaType={ctaType}
                    isIndustryInsights
                    ctaLink={adDetails?.linkUrl}
                    video={video}
                    onlyContent
                    adPage={adPage}
                  />
                </>
              );
            })}
          </Slider>
          <Slider
            asNavFor={nav1}
            ref={(slider) => (sliderRef2 = slider)}
            slidesToShow={3}
            slidesToScroll={1}
            focusOnSelect={true}
            centerMode={true}
            centerPadding="0px"
          >
            {adCreative?.map((adDetails, index) => (
              <div key={index}>
                <Image
                  src={
                    adDetails?.videos?.length
                      ? adDetails?.videos?.[0]?.thumbnail?.url
                      : adDetails?.images?.[0]?.url
                  }
                  preview={false}
                />
              </div>
            ))}
          </Slider>
        </div>
      </>
    );
  };
  const multiImagesView = () => {
    const adDetails = adCreative?.[0];
    const title = { title: adDetails?.title?.value };
    const body = { body: adDetails?.body?.value };
    const multiImages = adDetails?.images?.map((data) => ({
      s3Url: data?.url,
    }));
    const multiVideos = adDetails?.videos?.map((data) => ({
      s3Url: data?.url,
      thumbUrl: data?.thumbnail?.url,
    }));
    const ctaType = adDetails?.ctaType;
    const description = { description: adDetails?.linkDescription?.value };
    return (
      <>
        <div className="creative-report-card">
          <div className="position-relative">
            <div className="creative-report-card-image">
              <CardCreativeAdGroup
                title={title}
                body={body}
                description={description}
                isGroup
                ctaType={ctaType}
                ctaLink={adDetails?.linkUrl}
                isMultiCreatives
                isIndustryInsights
                isDetailedView
                multiImages={multiImages}
                multiVideos={multiVideos}
                groupType={adsType}
                onlyContent
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  const handleDisplayView = () => {
    if (
      adCreativeType === CREATIVE_TYPE_IMAGE?.toLowerCase() ||
      adCreativeType === CREATIVE_TYPE_VIDEO?.toLowerCase() ||
      adCreative?.length === 1
    ) {
      return staticView();
    } else if (adCreativeType === DYNAMIC_ADS_KEY || adCreativeType === ADS_TYPE_CARAOUSEL) {
      return dynamicView();
    } else {
      return multiImagesView();
    }
  };
  const handleDate = (dates) => {
    const date = new Date(dates);
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return formattedDate;
  };
  const onCancel = () => {
    setVisibleCategoryModal(false);
  };
  const addToCreativeLibProps = {
    title: "Save to Creative Library",
    visible: visibleCategoryModal,
    onCancel: onCancel,
    maskClosable: false,
    footer: null,
  };
  const message = !adDeliveryStopTime ? `since ${getActiveDays({ adDeliveryStartTime })} days` : "";

  return (
    <>
      {visibleCategoryModal && (
        <LookModal {...addToCreativeLibProps}>
          <AddToCreativeLib
            visibleCategoryModal={visibleCategoryModal}
            adCreative={adCreative}
            onCancel={onCancel}
            adBodies={adBodies}
          />
        </LookModal>
      )}
      <div className="d-flex  align-items-stretch">
        <div className="box w-40 mb-0  mr-15">{handleDisplayView()}</div>
        <div className="box w-60  ad-details ">
          <p className="para d-flex space-between">
            <span>Page name:</span>

            <span>
              <span className="text-primary mr-2">{isAdActive ? "Active" : "Inactive"}</span>
              {message}
            </span>
          </p>
          <div className="id-details">
            <h3>{adPage.name}</h3>
            <p>ID: {adPage.fbPageId}</p>
          </div>

          <div className="dates d-flex">
            {/* <div className="dates-inner">
              <p>Ad Creation Date</p>
              <p>
                <ActionIconRender iconType="calendar" /> {handleDate(adCreationTime)}
              </p>
            </div> */}
            <div className="dates-inner date-middle">
              <p>Ad Start Date</p>
              <p>
                <ActionIconRender iconType="calendar" /> {handleDate(adDeliveryStartTime)}
              </p>
            </div>
            <div className="dates-inner">
              <p>Ad End Date</p>
              <p>
                <ActionIconRender iconType="calendar" />{" "}
                {adDeliveryStopTime ? handleDate(adDeliveryStopTime) : "-"}
              </p>
            </div>
          </div>
          {adsType === ADS_TYPE_STATIC && (
            <div className="save-btn-bottom">
              <LookButton
                className="w-100 mb-24 ant-btn-primary"
                type="button"
                onClick={() => {
                  setVisibleCategoryModal(true);
                }}
              >
                Save to creative library
              </LookButton>
            </div>
          )}

          <div className="domain">
            <h3>Domain Name</h3>
            <ul>
              {adDomain?.map((data) => (
                <li>{data?.domain}</li>
              ))}
            </ul>
          </div>

          {languages?.length && (
            <div className="language">
              <h3>Language</h3>
              <ul>
                {languages?.map((data) => (
                  <li>{data?.language}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="platform">
            <h3>Platforms</h3>
            <div className="icons d-flex">
              {publisherPlatforms.includes(PLATFORM_TYPE_FACEBOOK) && (
                <ActionIconRender className=" icon-bk mr-4" iconType="facebook-outlined" />
              )}
              {publisherPlatforms.includes(PLATFORM_TYPE_TIKTOK) && (
                <ActionIconRender className="icon-bk mr-4" iconType="tiktok" />
              )}
              {publisherPlatforms?.includes(PLATFORM_TYPE_INSTAGRAM) && (
                <ActionIconRender className="icon-bk mr-4" iconType="instagram" />
              )}
              {publisherPlatforms?.includes(PLATFORM_TYPE_MESSENGER) && (
                <ActionIconRender className="icon-bk mr-4" iconType="message" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
