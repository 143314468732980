import { Alert, Col, Form } from "antd";
import { useSelector } from "react-redux";
import SelectInput from "../../../forms/SelectInput";
import Loader from "../../../loader";
import { LIST_BIT } from "components/PostHandle/view/PageBits";
import { useEffect, useState } from "react";
import { useApiToStoreContext } from "hooks/useApiToStore/context";

export default function LanguageHandle(props) {
  const { language = [], onChange = () => {} } = props;
  const { umsLanguagesFetch } = useApiToStoreContext();
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);

  const { umsLanguages, umsLanguages_loading } = userSettingData || {};

  useEffect(() => {
    umsLanguagesFetch();
  }, []);

  return (
    <div className="position-relative">
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex-grow-1">
            <Col span={24}>
              <Form.Item className="look-form-input">
                <SelectInput
                  className="mb-0"
                  search
                  placeholder="Select Language"
                  name="languageId"
                  value={language}
                  options={umsLanguages?.map(({ id, name }) => ({
                    name: name,
                    value: id,
                  }))}
                  onChange={onChange}
                  showArrow={true}
                  disabled={umsLanguages_loading}
                />
              </Form.Item>
            </Col>
          </div>
        </div>
      </Form>
    </div>
  );
}
