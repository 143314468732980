import { Col, Form, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import { Formik } from "formik";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React from "react";
import { useSelector } from "react-redux";
import { GET_SAS_CATEGORIES } from "shared/gql/gqlSchema/sasGql";
import { GET_VS_ALL_CONTENT_BY_ADVETTISER } from "shared/gql/gqlSchema/viralSparksGql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import MediaItem from "./MediaItem";
import LookButton from "components/LookButton";
import Loader from "components/loader";
import { GET_VOLUUM_CATEGORY } from "shared/gql/gqlSchema/fb360Gql";

function TemplateForm(props) {
  const {
    initialValues,
    onSubmit,
    isRequestView,
    validationSchema = () => {},
    uploadView = () => {},
    setCreativeType = () => {},
    selectedImages,
    creativeType,
    creativesImages,
    uploadLoading,
    onCloseAddTemplate = () => {},
    handleSelectImage = () => {},
    setCreativesImages = () => {},
    categories,
    loading_Add = false,
    setCategories = () => {},
    errors,
  } = props;
  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);

  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};

  const [fetchVsContent, { loading: vs_content__loading, vs_content_refetch }] = useCustomLazyQuery(
    GET_VS_ALL_CONTENT_BY_ADVETTISER,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.vs_contentGetAllByAdvertiserId?.data?.map((element) => ({
          name: element?.nickname,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    }
  );
  const [fetchSasCategory, { loading: sas_loading, error, refetch }] = useCustomLazyQuery(
    GET_SAS_CATEGORIES,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.sasCategories?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    }
  );
  const [fetchLeadgen, { loading: leadgen_loading, leadgen_error, leadgen_refetch }] =
    useCustomLazyQuery(GET_VOLUUM_CATEGORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.voluum_get_category?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });
  const creativeTypeOptions = [
    {
      name: "Image",
      value: CREATIVE_TYPE_IMAGE,
    },
    {
      name: "Video",
      value: CREATIVE_TYPE_VIDEO,
    },
  ];

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form layout="vertical">
            <>
              {!isRequestView && (
                <>
                  <Row gutter={12}>
                    <Col span={24}>
                      <SelectInput
                        formik
                        name="productId"
                        label="Product"
                        optional
                        placeholder="Select products"
                        className="look-form-input"
                        value={values?.productId?.toString()}
                        onChange={(name, value) => {
                          setFieldValue("productId", parseInt(value));
                          if (parseInt(value) === PRODUCT_ID_SAS) {
                            fetchSasCategory();
                          } else if (parseInt(value) === PRODUCT_ID_LEADGEN) {
                            fetchLeadgen();
                          }
                        }}
                        options={productList?.map((prod) => ({
                          name: prod?.name,
                          value: prod?.id,
                        }))}
                      />
                    </Col>
                  </Row>
                  {values?.productId === PRODUCT_ID_VS && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          formik
                          name="advertiser"
                          label="Advertiser"
                          required
                          placeholder="Select Advertiser"
                          search
                          className="look-form-input"
                          onChange={(name, value) => {
                            setFieldValue("advertiser", value);

                            fetchVsContent({ variables: { advertiser_id: value } });
                          }}
                          options={vsAdvertiser?.map(({ id, name }) => ({ name, value: id }))}
                        />
                      </Col>
                    </Row>
                  )}
                  {values?.productId && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          formik
                          name="categoryId"
                          required
                          search
                          label="Category"
                          placeholder="Select category"
                          className="look-form-input"
                          loader={vs_content__loading || sas_loading || leadgen_loading}
                          onChange={(name, value) => {
                            setFieldValue("categoryId", value);
                            setFieldValue(
                              "categoryName",
                              categories?.find((category) => category?.value === value)?.name
                            );
                          }}
                          options={categories}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}

              <Row gutter={12}>
                <Col span={24}>
                  <LookTextInput
                    formik
                    name="templateName"
                    required
                    label="Template Name"
                    placeholder="Enter Template name"
                    className="look-form-input"
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <SelectInput
                    formik
                    name="creativeType"
                    label="Creative Type"
                    placeholder="Select Creative Type"
                    search
                    required
                    className="look-form-input"
                    // value={values?.creativeType}
                    options={creativeTypeOptions}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                      setCreativesImages([]);
                      setCreativeType(value);
                    }}
                  />
                </Col>
              </Row>
              {values?.creativeType && creativesImages?.length === 0 && (
                <>
                  <div style={{ height: "150px" }} className="look-form-input">
                    {uploadLoading ? <Loader elementCenter /> : uploadView()}
                  </div>
                  {errors && (
                    <span
                      style={{ display: "flex", justifyContent: "center" }}
                      className="text-left error-text"
                    >
                      {errors}
                    </span>
                  )}
                </>
              )}
              {creativesImages?.map((data, index) => {
                // const selectedImg = selectedImages?.filter((img) => img.id === data?.creative?.id);
                return (
                  <Row>
                    <Col span={4} key={"mediaItems" + index} xs={12} sm={8} md={6} xl={4}>
                      <div style={{ margin: "0 150px", width: "150px" }}>
                        <MediaItem
                          // Spread the 'data' object as separate props
                          {...data}
                          creativeType={creativeType}
                          creative={{
                            id: index,
                            thumbUrl: data?.thumbUrl,
                            url: creativeType === CREATIVE_TYPE_IMAGE ? data?.url : data?.video_url,
                          }}
                          isTemplateForm
                          setCreativesImages={setCreativesImages}
                          // onSelect={() => {
                          //   handleSelectImage(index);
                          // }}
                          // selected={selectedImg.length > 0}
                        />
                      </div>
                    </Col>
                  </Row>
                );
              })}

              {/* {skeletonSize?.map((e) => (
                <Col span={4} xs={12} sm={8} md={6} xl={4}>
                  <div className={cx("creative-image-selection ant-image-ration-1-1")}>
                    <Skeleton.Image
                      className="w-100"
                      style={{ width: "100%", height: "348px" }}
                      active={true}
                    />
                  </div>
                </Col>
              ))} */}
              <Row gutter={12}>
                <Col span={24}>
                  <LookTextInput
                    formik
                    type="textarea"
                    name="api"
                    label="API"
                    required
                    placeholder="Enter api"
                    className="look-form-input"
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <LookTextInput
                    formik
                    type="textarea"
                    required
                    name="sourceCode"
                    label="Source Code"
                    placeholder="Enter source code"
                    className="look-form-input"
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <LookTextInput
                    formik
                    name="description"
                    label="Description"
                    placeholder="Enter description"
                    className="look-form-input"
                    optional
                  />
                </Col>
              </Row>
              <Form.Item>
                <Row gutter={12}>
                  <Col span={12}>
                    <LookButton htmlType="cancel" block type="default" onClick={onCloseAddTemplate}>
                      Cancel
                    </LookButton>
                  </Col>
                  <Col span={12}>
                    <LookButton
                      htmlType="submit"
                      block
                      type="primary"
                      onClick={handleSubmit}
                      loading={loading_Add}
                    >
                      Add Template
                    </LookButton>
                  </Col>
                </Row>
              </Form.Item>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TemplateForm;
