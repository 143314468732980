import { useSelector } from "react-redux";
import { GET_ALL_USERS_ID_NAME, GET_ME_USERS } from "shared/gql";
import { CRS_GET_CONTINENTS } from "shared/gql/gqlSchema/crsGql";
import { USERS_BY_AGENCY_ID_NAME } from "shared/gql/gqlSchema/iTaskGql";
import {
  GET_ALL_COUNTRY_CURRENCY,
  GQL_GET_SELECTED_WORKSPACE_USERS_DROPDOWN,
  GQL_GET_WORKSPACE_USERS_DROPDOWN,
  UMS_PLATFORMS,
  GQL_GET_WORKSPACE_WISE_TEAM_DROPDOWN,
  GQL_UMS_LANGUAGES,
  UMS_PRODUCTS_FOR_REDUCER_DROPDOWN,
  UMS_SERVICES,
} from "shared/gql/gqlSchema/umsGql";
import {
  API_STORE_UMS_PRODUCTS,
  API_STORE_UMS_SERVICES,
  API_STORE_UMS_USERS,
  API_STORE_UMS_USERS_BY_AGENCY,
} from "./keys";
import useApiToStoreQuery from "./useApiToStoreQuery";

export function useUmsApiParams() {
  const userSettings = useSelector((state) => state?.userSettings);
  const agency_id = useSelector((state) => state?.userReducer?.me?.agency_id);
  const { selectedWorkspace } = userSettings;
  const {
    apiCall: umsCountriesFetch = () => {},
    refetch: umsCountriesRefetch,
    setDataSource: umsCountriesSetDataSource,
  } = useApiToStoreQuery({
    api: GET_ALL_COUNTRY_CURRENCY,
    elementKey: "umsCountries",
    storeKey: "umsCountries",
  });

  const {
    apiCall: umsLanguagesFetch = () => {},
    refetch: umsLanguagesRefetch,
    setDataSource: umsLanguagesSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_UMS_LANGUAGES,
    elementKey: "umsLanguages",
    storeKey: "umsLanguages",
  });
  const {
    apiCall: umsContinentsFetch = () => {},
    refetch: umsContinentsRefetch,
    setDataSource: umsContinentSetDataSource,
  } = useApiToStoreQuery({
    api: CRS_GET_CONTINENTS,
    elementKey: "umsContinents",
    storeKey: "umsContinents",
  });

  const {
    apiCall: umsCurrentWorkspaceUsersFetch = () => {},
    refetch: umsCurrentWorkspaceUsersRefetch,
    setDataSource: umsCurrentWorkspaceUsersSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_GET_SELECTED_WORKSPACE_USERS_DROPDOWN,
    elementKey: "workspace",
    elementKeyLevel2: "users",
    storeKey: "umsCurrentWorkspaceUsers",
    variables: { id: selectedWorkspace },
  });

  const {
    apiCall: umsCurrentWorkspaceTeamsFetch = () => {},
    refetch: umsCurrentWorkspaceTeamsRefetch,
    setDataSource: umsCurrentWorkspaceTeamsSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_GET_WORKSPACE_WISE_TEAM_DROPDOWN,
    elementKey: "workspace",
    elementKeyLevel2: "teams",
    storeKey: "umsCurrentWorkspaceTeams",
    variables: { id: selectedWorkspace },
  });

  const {
    apiCall: umsPlatformsFetch = () => {},
    refetch: umsPlatformsRefetch,
    setDataSource: umsPlatformsSetDataSource,
  } = useApiToStoreQuery({
    api: UMS_PLATFORMS,
    elementKey: "umsPlatforms",
    storeKey: "umsPlatforms",
  });

  const {
    apiCall: myUsersFetch = () => {},
    refetch: myUsersRefetch,
    setDataSource: myUsersSetDataSource,
  } = useApiToStoreQuery({
    api: GET_ME_USERS,
    elementKey: "me",
    elementKeyLevel2: "users",
    storeKey: "myUsers",
  });

  return {
    umsCountriesFetch,
    umsCountriesRefetch,
    umsCountriesSetDataSource,

    umsLanguagesFetch,
    umsLanguagesRefetch,
    umsLanguagesSetDataSource,

    umsCurrentWorkspaceUsersFetch,
    umsCurrentWorkspaceUsersRefetch,
    umsCurrentWorkspaceUsersSetDataSource,

    umsContinentsFetch,
    umsContinentsRefetch,
    umsContinentSetDataSource,

    myUsersFetch,
    myUsersRefetch,
    myUsersSetDataSource,

    umsCurrentWorkspaceTeamsFetch,
    umsCurrentWorkspaceTeamsRefetch,
    umsCurrentWorkspaceTeamsSetDataSource,

    umsPlatformsFetch,
    umsPlatformsRefetch,
    umsPlatformsSetDataSource,
  };
}

export default function useUmsApis(typeKey) {
  const agency_id = useSelector((state) => state?.userReducer?.me?.agency_id);
  switch (typeKey) {
    case API_STORE_UMS_PRODUCTS:
      return {
        api: UMS_PRODUCTS_FOR_REDUCER_DROPDOWN,
        elementKey: "products",
        storeKey: "products_dropdown",
      };

    case API_STORE_UMS_SERVICES:
      return { api: UMS_SERVICES, elementKey: "services", storeKey: "services" };

    case API_STORE_UMS_USERS:
      return { api: GET_ALL_USERS_ID_NAME, elementKey: "users", storeKey: "users" };

    case API_STORE_UMS_USERS_BY_AGENCY:
      return {
        api: USERS_BY_AGENCY_ID_NAME,
        elementKey: "agency",
        elementKeyLevel2: "users",
        storeKey: "agencyUsers",
        variables: { id: agency_id },
      };

    default:
      return false;
  }
}
