import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { Row, Col } from "antd";
import LookTable from "components/LookTable";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { useState } from "react";
import { CREATIVE_BY_ADNAME } from "shared/gql/gqlSchema/viralSparksGql";
import CardCreative from "./CardCreative";
import { useVSCreativeContext, VSCreativeContext } from "./Context";

export default function VSCreativeCard(props) {
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;
  const { showDrawerUpdate, canManage } = useVSCreativeContext();

  const { adName } = props;

  const [dataRender, setDataRender] = useState([]);

  const {
    data = [],
    loading,
    error,
    refetch,
  } = useCustomQuery(CREATIVE_BY_ADNAME, {
    notifyOnNetworkStatusChange: true,
    variables: {
      adName,
    },
    onCompleted(e) {
      const renderData = e?.creativeByAdName?.map((element) => ({ ...element, key: element.id }));
      setDataRender(renderData);
    },
  });

  function renderCard() {
    return (
      <>
        <LookTable
          customeView={
            <VSCreativeContext.Provider
              value={{
                mainData: dataRender || [],
              }}
            >
              <Row gutter={12}>
                {dataRender?.map((data, index) => (
                  <Col key={"VSCreativeCard" + index} xs={24} md={12} lg={8} xl={6}>
                    <CardCreative className="mb-12" {...data} />
                  </Col>
                ))}
              </Row>
            </VSCreativeContext.Provider>
          }
          refetch={refetch}
          loading={loading}
          persistKey={persistKey}
        />
      </>
    );
  }

  return <div className="px-16">{renderCard()}</div>;
}
