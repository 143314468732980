import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { VAROZI_WORKSPACE_ID } from "pages/dashboardPages/Varozi/keysAndEnum";
import { useSelector } from "react-redux";
import route, { ROUTE_DASHBOARD } from "../route";

const VaroziReports = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Varozi-Reports" */ "pages/dashboardPages/Varozi/Reports")
  )
);

export default function useVaroziRoutes() {
  const workspace = useSelector((state) => state?.userSettings?.selectedWorkspace);

  const isVaroziWorkspace = workspace === VAROZI_WORKSPACE_ID;

  const isVaroziRoute = isVaroziWorkspace
    ? [route("/varozi/reports", VaroziReports, ROUTE_DASHBOARD, false)]
    : [];

  const varoziRoutes = [...isVaroziRoute];

  return { varoziRoutes };
}
