import { Tabs } from "antd";
import cx from "cx";
import React, { useState } from "react";
import {
  ADD_MOODBOARD_ANGLES,
  ADD_MOODBOARD_COPY_POINTS,
  ADD_MOODBOARD_CTA,
  ADD_MOODBOARD_HOOKS,
  ADD_MOODBOARD_PAIN_POINTS,
  ADD_MOODBOARD_SCRIPTS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { CREATIVE_TYPE_IMAGE } from "shared/SharedKeys/launchKeys";
import {
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_DESCRIPTION,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_PAIN_POINTS,
  MOODBOARD_TYPE_SCRIPT,
} from "../../creativesEnum";
import MoodboardData from "./MoodboardData";

function CreativeAnalysis(props) {
  const {
    group_id = "",
    creativeType = "",
    categoryId = [],
    productId,
    categories = [],
    serviceType = "",
  } = props;
  const [creativeData, setCreativeData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeTab, setActiveTab] = useState(creativeType === CREATIVE_TYPE_IMAGE ? "3" : "1");
  const onTabsChange = (key) => {
    setActiveTab(key);
  };

  const generateMoodboardData = ({
    tabId,
    actionType,
    placeHolderKey,
    dataKey,
    mutation,
    apiDataType,
  }) => (
    <MoodboardData
      actionType={actionType}
      dataKey={dataKey}
      placeHolderKey={placeHolderKey}
      id={categoryId?.map((data) => data?.productCategoryId)}
      name={categoryId?.map((data) => data?.name)}
      productId={productId}
      apiKey={tabId}
      serviceType={serviceType}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      creativeId={group_id}
      creativeType={creativeType}
      creativeData={creativeData}
      setCreativeData={setCreativeData}
      categories={categories}
      mutation={mutation}
      apiDataType={apiDataType}
    />
  );
  const tabContent = [
    ...(creativeType === CREATIVE_TYPE_IMAGE
      ? [
          {
            key: "3",
            label: "Angles",
            children:
              activeTab === "3" &&
              generateMoodboardData({
                tabId: "angle",
                actionType: MOODBOARD_TYPE_ANGLES,
                placeHolderKey: "Angles",
                dataKey: "angleId",
                mutation: ADD_MOODBOARD_ANGLES,
                apiDataType: "ctAddAngle",
              }),
          },
        ]
      : [
          {
            key: "1",
            label: `Scripts`,
            children:
              activeTab === "1" &&
              generateMoodboardData({
                tabId: "script",
                actionType: MOODBOARD_TYPE_SCRIPT,
                placeHolderKey: "Script",
                dataKey: "scriptId",
                mutation: ADD_MOODBOARD_SCRIPTS,
                apiDataType: "ctAddScript",
              }),
          },
          {
            key: "2",
            label: `Copy Points`,
            children:
              activeTab === "2" &&
              generateMoodboardData({
                tabId: "copyPoint",
                actionType: MOODBOARD_TYPE_COPY_POINTS,
                placeHolderKey: "Copy Point",
                dataKey: "cpId",
                mutation: ADD_MOODBOARD_COPY_POINTS,
                apiDataType: "ctAddCopyPoint",
              }),
          },
          {
            key: "4",
            label: "Hooks",
            children:
              activeTab === "4" &&
              generateMoodboardData({
                tabId: "hook",
                actionType: MOODBOARD_TYPE_HOOKS,
                placeHolderKey: "Hooks",
                dataKey: "hookId",
                mutation: ADD_MOODBOARD_HOOKS,
                apiDataType: "ctAddHook",
              }),
          },
          {
            key: "5",
            label: "Cta",
            children:
              activeTab === "5" &&
              generateMoodboardData({
                tabId: "cta",
                actionType: MOODBOARD_TYPE_CTA,
                placeHolderKey: "Cta",
                dataKey: "ctaId",
                mutation: ADD_MOODBOARD_CTA,
                apiDataType: "ctAddCTA",
              }),
          },
          {
            key: "6",
            label: "Pain Points",
            children:
              activeTab === "6" &&
              generateMoodboardData({
                tabId: "painPoint",
                actionType: MOODBOARD_TYPE_PAIN_POINTS,
                placeHolderKey: "Pain Points",
                dataKey: "ppId",
                mutation: ADD_MOODBOARD_PAIN_POINTS,
                apiDataType: "ctAddPainPoint",
              }),
          },
        ]),

    {
      key: "7",
      label: "Description",
      children:
        activeTab === "7" &&
        generateMoodboardData({
          tabId: "description",
          actionType: MOODBOARD_TYPE_DESCRIPTION,
          placeHolderKey: "Description",
          dataKey: "notes",
        }),
    },
  ];

  return (
    <div>
      <Tabs
        className={cx("scriptTabs look-drawer-top-tab", { isEdit: true })}
        defaultActiveKey="1"
        size="large"
        items={tabContent}
        onChange={onTabsChange}
      />
    </div>
  );
}

export default CreativeAnalysis;
