import { createGlobalStyle } from "styled-components";
import maskImage from "../../assets/images/Maskimg.svg";
import MaskDarkImage from "../../assets/images/MaskDarkimg.svg";

const GlobalStyle = createGlobalStyle`
.imgdiv {
  background-image: url(${maskImage});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.imgDarkdiv {
  background-image: url(${MaskDarkImage});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

`;

export default GlobalStyle;
