import { Modal } from "antd";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";

export default function DeleteModal(props) {
  const {
    title = "Delete Record",
    message = "Do you really want to delete this?",
    visible = false,
    onSubmit = () => {},
    onCancel = () => {},
    loader = false,
    danger = true,
    okText = "Delete",
    loaderBtn = false,
    customView = false,
    categoriesList = [],
    setSelectedCategory = () => {},
    selectedCategory = {},
  } = props;
  if (!visible) return null;

  function isObjectBlank(obj) {
    return Object.entries(obj).length === 0;
  }
  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      okButtonProps={{
        onClick: onSubmit,
        disabled: loader || loaderBtn || (customView && isObjectBlank(selectedCategory)),
        danger: danger,
        loading: loaderBtn,
      }}
      onCancel={onCancel}
      onCancelProps={{ disabled: loader || loaderBtn }}
      cancelButtonProps={{ disabled: loader || loaderBtn }}
    >
      {customView ? (
        <>
          <p>Please select the category from where you want to delete this media.</p>
          <SelectInput
            name="categoryData"
            label="Delete from"
            loader={loader}
            required
            search
            placeholder="Select Your Category"
            options={categoriesList?.map(({ name, productCategoryId }) => ({
              name,
              value: productCategoryId,
            }))}
            onChange={(e) => {
              const selectedData = categoriesList?.find((item) => item?.productCategoryId === e);
              setSelectedCategory(selectedData);
            }}
          />
        </>
      ) : (
        <p>{message}</p>
      )}
      {loader && <Loader />}
    </Modal>
  );
}
