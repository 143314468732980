import { Link, useRouteMatch } from "react-router-dom";
import { Menu, Tooltip } from "antd";
import cx from "classnames";
import logo from "assets/images/logo-icon.svg";
import styled from "styled-components";
import ProfileCard from "./ProfileCard";
import { useSelector } from "react-redux";
import ActionIconRender from "components/ActionIconRender";
import Notification from "shared/Notification/Notification";
import { usePermissionCheck } from "hooks";
import { CacheBuster } from "components/CacheBuster";

function RenderMenu(props) {
  const routeMatch = useRouteMatch();
  const { link, icon: Icon, subMenu = [], name } = props;
  const { havePlatformPermissionCheck } = usePermissionCheck();

  const visibleTabs = subMenu?.filter(({ permission: routePermission }) =>
    havePlatformPermissionCheck(routePermission)
  );
  if (visibleTabs.length === 0) return null;

  const nestedSubmenu = visibleTabs[0].isCollapsible
    ? visibleTabs[0]?.subMenus?.filter(({ permission: routePermission }) =>
        havePlatformPermissionCheck(routePermission)
      )
    : [];

  const splitRoute = routeMatch?.path.split("/");
  const isActive = "/" + splitRoute[1] === link;
  return (
    <li className="ant-menu-item side-menu-item no-mp">
      <Tooltip title={name} placement="right">
        <Link
          to={`${link}${
            visibleTabs[0].isCollapsible
              ? nestedSubmenu[0]?.subMenuLink
              : visibleTabs[0].subMenuLink
          }`}
          className={cx({ active: isActive })}
        >
          <div className="side-menu-item">
            <Icon className="side-menu-item-img" />
          </div>
        </Link>
      </Tooltip>
    </li>
  );
}

export default function MainSidebar(props) {
  const {
    mainMenus = [],
    childSidebarSize,
    isChildSidebar = false,
    toggleChildSidebar = () => {},
  } = props;

  const isPseudoUser = useSelector((state) => state?.userReducer.me?.isPseudoUser);
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <div className={cx("side-menu-wrapper", { isPseudoUser })}>
            <SiderToggleBtn
              className={cx("SiderToggleBtn", { out: isChildSidebar })}
              onClick={toggleChildSidebar}
              openPosition={isChildSidebar ? 0 : childSidebarSize}
            >
              <Tooltip title={"Collapse Sidebar"} placement="bottom">
                <ActionIconRender
                  style={isChildSidebar ? { transform: "scale(-1)" } : {}}
                  iconType="left"
                />
              </Tooltip>
            </SiderToggleBtn>
            <Menu id="main-menu" className="side-menu" style={{ width: "auto" }} mode="inline">
              <li className="ant-menu-item logo-icon side-menu-item no-mp">
                <Link to={"/dashboard"}>
                  <div className="side-menu-item">
                    <img src={logo} alt="logo" />
                  </div>
                </Link>
              </li>
              {mainMenus.map((data, index) => (
                <RenderMenu key={"mainMenus" + index} {...data} />
              ))}
            </Menu>
            <Notification />
            <ProfileCard />
          </div>
        );
      }}
    </CacheBuster>
  );
}

const SiderToggleBtn = styled("button")`
  left: calc(100% + ${({ openPosition }) => openPosition}px);
`;
