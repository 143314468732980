export const campaignObjectiveData = [
  {
    name: "Sales",
    description: "Drive sales online, in app, by phone, or in store.",
    commingSoon: true,
  },
  {
    name: "Leads",
    description: "Get leads and other conversions by encouraging customers to take action.",
    commingSoon: true,
  },
  {
    name: "Website traffic",
    description: "Get the right people to visit your website.",
    commingSoon: true,
  },
  {
    name: "App promotion",
    description: "Get more installs, engagement and pre-registration for your app.",
    commingSoon: true,
  },
  {
    name: "Drive sales online, in app, by phone, or in store",
    description: "Reach a broad audience and build interest in your products or brand.",
    commingSoon: true,
  },
  {
    name: "Local store visits and promotions",
    description: "Drive visits to local stores, including restaurants and dealerships.",
    commingSoon: true,
  },
  {
    active: true,
    name: "Create a campaign without a goal's guidance",
    description: "Choose a campaign type first, without a recommendation based on your objective.",
  },
];

export const campaignTypeData = [
  {
    active: true,
    name: "Search",
    description: "Get in front of high-intent customers at the right time on Google Search.",
  },
  {
    name: "Performance Max",
    description: "Reach audiences across all of Google with a single campaign.",
    commingSoon: true,
  },
  {
    name: "Display",
    description: "Reach customers across 3 million sites and apps with engaging creative.",
    commingSoon: true,
  },
  {
    name: "Shopping",
    description: "Showcase your products to shoppers as they explore what to buy.",
    commingSoon: true,
  },
  {
    name: "Video",
    description: "Reach viewers on YouTube and get conversions.",
    commingSoon: true,
  },
  {
    name: "App",
    description: "Drive downloads and grow engagement for your app.",
    commingSoon: true,
  },
  {
    name: "Smart",
    description: "Reach customers with a one-stop solution built for small businesses.",
    commingSoon: true,
  },
  {
    name: "Demand Gen",
    description: "Run ads on YouTube, Gmail, Discover, and more.",
    commingSoon: true,
  },
];

export const googleLaunchMatchTypeData = [
  { name: "Broad", value: "BROAD" },
  { name: "Phrase", value: "PHRASE" },
  { name: "Exact", value: "EXACT" },
];

export const googleLaunchManualBiddingStrategy = "manual";
export const googleLaunchManualBiddingStrategyEmum = [
  { name: "Manual", value: googleLaunchManualBiddingStrategy },
];
