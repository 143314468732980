import { Button, Col, Input, Pagination, Radio, Row, Segmented, Select } from "antd";
import cx from "cx";
import React, { useEffect, useState } from "react";
import {
  CREATIVE_TYPE_AUDIO,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_TEXT,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import MediaUpload from "./MediaUpload";
import {
  MEDIA_ADD_AUDIO_CLIP,
  MEDIA_ADD_CREATIVE_IMAGE,
  MEDIA_ADD_CREATIVE_VIDEO,
  MEDIA_ADD_IMAGE_CLIP,
  MEDIA_ADD_TEXT,
  MEDIA_ADD_VIDEO_CLIP,
  MEDIA_DELETE_IMAGE_CLIPS,
  MEDIA_DELETE_VIDEO_CLIPS,
  MEDIA_GET_IMAGE_CLIPS,
  MEDIA_GET_VIDEO_CLIPS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import AudioUpload from "./AudioUpload";

import { useSelector } from "react-redux";
import SelectInput from "components/forms/SelectInput";

import LookButton from "components/LookButton";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import { useLookModal } from "components/LookModal";
import AddMediaModal from "../../MediaLibraryNew/AddMediaModal";
import TextUpload from "./TextUpload";
import * as yup from "yup";
import { openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";
import useUploadFiles from "components/Launch/Creatives/CreativeUpload/useUploadFiles";

export default function Media(props) {
  const {
    mediaType = "",
    setDataBus = () => {},
    uniqueId,
    isRequestView = false,
    requestData,
    uniqueName,
    userLevel,
    setSelectedRowsData = () => {},
    allowedStatus,
    setAllowedStatus = () => {},
    isSearch,
    setIsSearch = () => {},
    searchItem,
    setSearchItem = () => {},
    searchMode,
    setSearchMode = () => {},
    isMediaLibraryMain = false,
    isMediaDrawer = false,
    isMediaLibrary = false,
    isCreativeBuilder = false,
    isMediaLibrary_cb = false,
    creativeBuilderV2 = false,
    onCloseDrawer = () => {},
    finalCreativesData = [],
    creativeByVisual = false,
    visuals = [],
  } = props;

  const [creativeType, setCreativeType] = useState(props?.mediaType || props?.creativeType);
  const initialValue =
    isCreativeBuilder || isMediaLibrary_cb || isMediaLibrary
      ? {}
      : creativeType === CREATIVE_TYPE_VIDEO
      ? {
          productId: "",
          categoryId: "",
        }
      : creativeType === CREATIVE_TYPE_TEXT
      ? {
          productId: "",
          categoryId: "",
          text: "",
        }
      : creativeType === CREATIVE_TYPE_IMAGE
      ? {
          productId: "",
          categoryId: "",
        }
      : creativeType === CREATIVE_TYPE_AUDIO
      ? {
          productId: "",
          categoryId: "",
        }
      : {};

  const textSchema = yup.object({
    categoryId: yup.string().required().label("Category"),
    text: yup.string().required().label("Enter Text"),
    productId: yup.string().required().label("Product"),
  });

  const audioSchema = yup.object({
    categoryId: yup.string().required().label("Category"),
    productId: yup.string().required().label("Product"),
  });

  const videoSchema = yup.object({
    categoryId: yup.string().required().label("Category"),
    productId: yup.string().required().label("Product"),
  });

  const imageSchema = yup.object({
    categoryId: yup.string().required().label("Category"),
    productId: yup.string().required().label("Product"),
  });

  const getValidationSchema = (inputType) => {
    switch (inputType) {
      case CREATIVE_TYPE_TEXT:
        return textSchema;
      case CREATIVE_TYPE_AUDIO:
        return audioSchema;
      case CREATIVE_TYPE_VIDEO:
        return videoSchema;
      case CREATIVE_TYPE_IMAGE:
        return imageSchema;
      default:
        return yup.object(); // Default schema if input type is not recognized
    }
  };
  const validationSchema =
    isCreativeBuilder || isMediaLibrary_cb || isMediaLibrary
      ? null
      : getValidationSchema(creativeType);

  // const [intialValues, setInitialValues] = useState(initialValue);

  const [creativesImages, setCreativesImages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dateRange, setRangeDates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modalUploadData, setModalUploadData] = useState([]);
  const [refetchTrigger, setRefetchTrigger] = useState("");
  const [refetchFlag, setRefetchFlag] = useState(false);

  // const [mediaTypeState, setMediaType] = useState(CREATIVE_TYPE_IMAGE);

  const onChangeMediaType = (e) => {
    setSearchMode((prevMode) => (prevMode === "tags" ? "normal" : "tags"));
    setIsSearch(false);
    setCreativeType(e.target.value);
    setModalUploadData([]);
  };

  const mediaTypeName = (mediaType) => {
    const mediaTypeMap = {
      [CREATIVE_TYPE_VIDEO]: "Video",
      [CREATIVE_TYPE_AUDIO]: "Audio",
      [CREATIVE_TYPE_IMAGE]: "Image",
      [CREATIVE_TYPE_TEXT]: "Text",
    };

    return mediaTypeMap[mediaType] || "Unknown";
  };

  const { modal, openModal, visibleModal, closeModal } = useLookModal({
    title: `Upload ${mediaTypeName(creativeType)} `,
    footer: false,
  });

  useEffect(() => {
    if (mediaType === "") {
      setCreativeType(props?.creativeType);
    } else {
      setCreativeType(props?.mediaType);
    }
  }, [props?.mediaType, props?.creativeType]);

  const handleCloseDrawer = () => {
    setModalUploadData([]);
    closeModal();
  };

  ///////////////////////////////<<<<<<<MAIN MEDIA APIS>>>>>>>>////////////////////////////

  const [ctAddText, { loading: loading_ADD_TEXT }] = useCustomMutation(MEDIA_ADD_TEXT, {
    onCompleted: (e) => {
      const element = e?.ctAddText?.data;
      // setRecordData([...element, ...recordData]);
      // setshowAddText(false);
      // resetFormRef.current();
      // refetch();
      setRefetchTrigger(creativeType);
      openNotification({ type: "success", message: creativeType + " added" });
      handleCloseDrawer();
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const addApis =
    creativeType === CREATIVE_TYPE_IMAGE ? MEDIA_ADD_IMAGE_CLIP : MEDIA_ADD_VIDEO_CLIP;

  const [ctAddMedia, { loading: loading_Add, refetch: creativesTemplate_refetch }] =
    useCustomMutation(addApis, {
      onCompleted: (e) => {
        const element =
          creativeType === CREATIVE_TYPE_IMAGE ? e?.ctAddImageClip : e?.ctAddVideoClip;

        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          setRefetchTrigger(creativeType);
          setCreativesImages((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems.push(...element?.data);
            return updatedItems;
          });
          handleCloseDrawer();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [ctAddAudio, { loading: loading_Audio, refetch: audio_refetch }] = useCustomMutation(
    MEDIA_ADD_AUDIO_CLIP,
    {
      onCompleted: (e) => {
        const element = e?.ctAddAudioClip;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          setRefetchTrigger(creativeType);
          handleCloseDrawer();
          // refetch_GET_MEDIA_DATA();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const loading = loading_ADD_TEXT || loading_Add;

  const getImageResponse = (imagesData) => {
    setModalUploadData(imagesData);
  };

  const { uploadView, uploadLoading } = useUploadFiles({
    creativeType,
    getImageResponse,
    loading: loading_Add,
    isMediaLibraryMain:
      isMediaLibraryMain || isCreativeBuilder || isMediaLibrary_cb || isMediaLibrary,
    // setSelectedImages,
    setCreativesImages,
  });

  const handleMediaSubmit = (values) => {
    const { categoryId = "", productId = "", categoryName = "" } = requestData || {};
    const variables =
      isMediaLibrary || isCreativeBuilder || isMediaLibrary_cb
        ? { productId, categoryId, categoryName }
        : {
            productId: values?.productId,
            categoryId: values?.categoryId,
            categoryName: values?.categoryName,
          };
    if (creativeType === CREATIVE_TYPE_TEXT) {
      ctAddText({
        variables: { ...variables, texts: values?.text },
      });
    } else if (creativeType === CREATIVE_TYPE_VIDEO) {
      const updatedData = modalUploadData?.map((obj) => {
        const { file_name, tags, ...rest } = obj;
        return { name: file_name, ...rest };
      });
      ctAddMedia({
        variables: { ...variables, r2Urls: updatedData },
      });
    } else if (creativeType === CREATIVE_TYPE_AUDIO) {
      const updatedData = modalUploadData?.map((obj) => {
        const { file_name, public_url } = obj;
        return { name: file_name, audioUrl: public_url };
      });
      ctAddAudio({
        variables: { ...variables, r2Urls: updatedData },
      });
    } else if (creativeType === CREATIVE_TYPE_IMAGE) {
      const updatedData = modalUploadData?.map((obj) => {
        const { file_name, public_url } = obj;
        return { name: file_name, imageUrl: public_url };
      });
      ctAddMedia({
        variables: { ...variables, r2Urls: updatedData },
      });
    }
  };

  const [createdByMe, setCreatedByMe] = useState("All");
  const { Search } = Input;

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  // const onChange = (value) => {
  //   setCreativeType(value);
  //   setCreativesImages([]);
  // };
  const handleCreatedByMe = (value) => {
    setCreatedByMe(value);
  };

  const { ctGetRequestStatusConfig = {} } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  // function handleChangeStatus(assignedStatus, userLevel) {
  //   const index = ctGetRequestStatusConfig?.[userLevel]?.statuses?.findIndex(
  //     (status) => status?.assigned_status === assignedStatus
  //   );
  //   const allowedIndex = ctGetRequestStatusConfig?.[userLevel]?.allowed_statuses
  //     ?.filter((allowed) => allowed?.from === index)
  //     ?.map((item) => item?.to)
  //     ?.push(index);

  //   const statuses = allowedIndex?.map(
  //     (items) => ctGetRequestStatusConfig?.[userLevel]?.statuses?.[items]
  //   );

  //   return statuses;
  // }
  function handleChangeStatus(assignedStatus) {
    const config = ctGetRequestStatusConfig?.[userLevel];

    if (!config) {
      return null;
    }

    const { statuses, allowed_statuses } = config;

    const index = statuses.findIndex((status) => status?.assigned_status === assignedStatus);

    if (index === -1) {
      return null;
    }

    const allowedIndex = allowed_statuses
      .filter((allowed) => allowed?.from === index.toString())
      .map((item) => item?.to);

    allowedIndex.push(index);

    const resultingStatuses = allowedIndex.map((items) => statuses[items]);

    setAllowedStatus(resultingStatuses);
  }
  const requestStatus = requestData?.requestStatus;

  const splitResult =
    requestStatus && requestStatus.includes(".") ? requestStatus.split(".")[1] : requestStatus;

  useEffect(() => {
    handleChangeStatus(splitResult);
  }, []);

  const handleDateRange = (e) => {
    setRangeDates(e.rangeDates);
  };

  const dateRangeProps = {
    noStore: true,
    disabledTimeZone: true,
    onChange: handleDateRange,
    isTimezone: false,
  };

  const searchView = () => {
    return (
      <div className="d-flex">
        {searchMode === "tags" ? (
          <Select
            className="mb-4"
            mode={"tags"}
            allowClear={false}
            bordered={false}
            placeholder={"Search by tags"}
            // TO DO THIS WILL BE REPLACED
            style={{ width: "200px", border: "1px solid #acacac", borderRadius: "6px" }}
            onChange={(e) => {
              setSearchItem(e);
            }}
            maxTagCount="responsive"
            showArrow={false}
          />
        ) : (
          <Search
            className="creative-tag-search mb-4"
            placeholder={`Search by name`}
            suffix={null}
            onSearch={() => {
              setIsSearch(true);
            }}
            onChange={(e) => {
              setSearchItem(e?.target?.value);
            }}
            style={{
              width: "100%",
            }}
          />
        )}
        {/* add tags icon */}
        <LookButton
          tooltipTitle={
            searchMode === "tags" ? "Click here to switch to normal mode" : "Click here to search"
          }
          className="filter-btn ml-8"
          iconType={searchMode === "tags" ? "close" : "tagSwitch"}
          onClick={() => {
            setSearchMode((prevMode) => (prevMode === "tags" ? "normal" : "tags"));
            setIsSearch(false);
          }}
        />
        <LookButton
          tooltipTitle="Click here to search"
          className="filter-btn"
          iconType="search"
          onClick={() => {
            setIsSearch(true);
          }}
        />
      </div>
    );
  };

  const handleRefetch = () => {
    setRefetchFlag(!refetchFlag);
  };

  const renderHeader = () => (
    <div
      className={cx(
        {
          "d-flex px-20 py-15  media-v2 flex-wrap align-items-center justify-content-between":
            !creativeByVisual,
        },
        {
          "d-flex px-10  pt-12 pb-16 pt-12 media-v2 flex-wrap align-items-center justify-content-between":
            creativeByVisual,
        }
      )}
    >
      {/* <div className="d-flex px-20 py-15 media-v2 flex-wrap align-items-center justify-content-between"> */}
      <div className="line-height-1">
        {/* <LookButton
   tooltipTitle="Refetch"
   className="filter-btn ml-8"
   size="small"
   iconType="reload"
   disabled={loading}
   iconTypeProps={{ spin: loading }}
   onClick={() => refetch()}
 /> */}
        {creativeType && searchView()}

        {/* <div className="ml-8">
      <Segmented
        className="custom-segmented"
        options={[CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO]}
        value={creativeType}
        onChange={onChange}
      />
    </div> */}
      </div>

      <div className={`d-flex align-items-center`}>
        {isMediaLibraryMain && (
          <div className="look-form-input mb-0 d-flex">
            {!isMediaDrawer && (
              <Radio.Group className="mr-10" onChange={onChangeMediaType} defaultValue={mediaType}>
                <Radio.Button value="IMAGE">Image</Radio.Button>
                <Radio.Button value="VIDEO">Video</Radio.Button>
                <Radio.Button value="AUDIO">Audio</Radio.Button>
                <Radio.Button value="TEXT">Text</Radio.Button>
              </Radio.Group>
            )}
          </div>
        )}

        <DateRangePersist {...dateRangeProps} />
        <LookButton
          iconType="plus"
          tooltipTitle="Upload"
          type="primary"
          size="middle"
          onClick={() => openModal()}
          className="text-center mr-10"
        >
          Upload
        </LookButton>
        {isRequestView && (
          <div className="ml-10" style={{ width: "130px" }}>
            <SelectInput
              name="status"
              options={
                allowedStatus?.map((status, index) => ({
                  value: status?.assigned_status,
                  name: status?.visible_status,
                })) || []
              }
              value={splitResult}
              onChange={(e) =>
                setSelectedRowsData((prevItems) => ({
                  ...prevItems,

                  requestData: {
                    ...prevItems.requestData,
                    requestStatus: e,
                    requestVisibleStatus: allowedStatus?.find(
                      (status) => status === status?.assigned_status
                    ),
                  },
                }))
              }
            />
          </div>
        )}

        {!isRequestView && (
          <>
            {/* {mediaType === CREATIVE_TYPE_AUDIO && searchView()} */}
            <Segmented
              className="custom-segmented"
              options={
                mediaType === CREATIVE_TYPE_AUDIO ? ["All", "My Audio"] : ["All", "My Creatives"]
              }
              value={createdByMe}
              onChange={handleCreatedByMe}
            />
          </>
        )}

        {isMediaLibraryMain && (
          <LookButton
            className="filter-btn ml-8"
            tooltipTitle="Refetch"
            iconType="reload"
            iconTypeProps={{ spin: false, style: { fontSize: 16 } }}
            onClick={handleRefetch}
          />
        )}
      </div>
    </div>
  );
  return (
    <div className={cx("creative-builder-media-list", { isRequestView })}>
      <div className="media-masnory-gallery">
        {renderHeader()}
        {creativeType === CREATIVE_TYPE_TEXT ? (
          <TextUpload
            mediaType={mediaType}
            {...props}
            isMediaLibraryMain={isMediaLibraryMain}
            isMediaDrawer={isMediaDrawer}
            setCreativesImages={setCreativesImages}
            creativesImages={creativesImages}
            refetchTrigger={refetchTrigger}
            dateRange={dateRange}
            createdByMe={createdByMe !== "All"}
            setIsSearch={setIsSearch}
            searchItem={searchItem}
            setSearchItem={setSearchItem}
            searchMode={searchMode}
            isSearch={isSearch}
            handleRefetch={handleRefetch}
            refetchFlag={refetchFlag}
            currentPage={currentPage}
            pageSize={pageSize}
            setTotalCount={setTotalCount}
          />
        ) : creativeType === CREATIVE_TYPE_AUDIO ? (
          <AudioUpload
            creativeType={creativeType}
            {...props}
            setTotalCount={setTotalCount}
            createdByMe={createdByMe !== "All"}
            setDataBus={setDataBus}
            uniqueId={uniqueId}
            creativesImages={creativesImages}
            setCreativesImages={setCreativesImages}
            currentPage={currentPage}
            pageSize={pageSize}
            uniqueName={uniqueName}
            setIsSearch={setIsSearch}
            searchItem={searchItem}
            setSearchItem={setSearchItem}
            searchMode={searchMode}
            isSearch={isSearch}
            isMediaDrawer={isMediaDrawer}
            isMediaLibraryMain={isMediaLibraryMain}
            modalUploadData={modalUploadData}
            setModalUploadData={setModalUploadData}
            refetchTrigger={refetchTrigger}
            dateRange={dateRange}
            handleRefetch={handleRefetch}
            refetchFlag={refetchFlag}
            finalCreativesData={finalCreativesData}
          />
        ) : (
          <MediaUpload
            uniqueId={uniqueId}
            isMediaLibraryMain={isMediaLibraryMain}
            setDataBus={setDataBus}
            creativeType={creativeType}
            setTotalCount={setTotalCount}
            creativesImages={creativesImages}
            setCreativesImages={setCreativesImages}
            pageSize={pageSize}
            currentPage={currentPage}
            {...props}
            setIsSearch={setIsSearch}
            isSearch={isSearch}
            searchItem={searchItem}
            setSearchItem={setSearchItem}
            searchMode={searchMode}
            createdByMe={createdByMe !== "All"}
            splitResult={splitResult}
            addApis={
              creativeType === CREATIVE_TYPE_IMAGE
                ? isRequestView
                  ? MEDIA_ADD_CREATIVE_IMAGE
                  : MEDIA_ADD_IMAGE_CLIP
                : isRequestView
                ? MEDIA_ADD_CREATIVE_VIDEO
                : MEDIA_ADD_VIDEO_CLIP
            }
            deleteApis={
              creativeType === CREATIVE_TYPE_IMAGE
                ? MEDIA_DELETE_IMAGE_CLIPS
                : MEDIA_DELETE_VIDEO_CLIPS
            }
            isMediaDrawer={isMediaDrawer}
            modalUploadData={modalUploadData}
            setModalUploadData={setModalUploadData}
            refetchTrigger={refetchTrigger}
            dateRange={dateRange}
            handleRefetch={handleRefetch}
            refetchFlag={refetchFlag}
            finalCreativesData={finalCreativesData}
            visuals={visuals}
          />
        )}
      </div>
      {visibleModal &&
        modal(
          <AddMediaModal
            mediaType={creativeType}
            mediaTypeName={mediaTypeName}
            categories={categories}
            setCategories={setCategories}
            intialValues={initialValue}
            validationSchema={validationSchema}
            handleMediaSubmit={handleMediaSubmit}
            handleCloseDrawer={handleCloseDrawer}
            uploadView={uploadView}
            uploadLoading={uploadLoading}
            modalUploadData={modalUploadData}
            setModalUploadData={setModalUploadData}
            loading={loading}
            isMediaLibrary={isMediaLibrary}
            isCreativeBuilder={isCreativeBuilder}
            isMediaLibrary_cb={isMediaLibrary_cb}
          />
        )}

      {
        <div
          className={cx(
            { "p-8 look-bottom-pagination": !creativeByVisual },
            { "text-right mt-20": creativeByVisual }
          )}
        >
          <Pagination
            defaultCurrent={1}
            total={totalCount}
            pageSize={5}
            current={currentPage}
            onChange={(page, size) => handlePageChange(page, size)}
            pageSizeOptions={["10", "20", "50"]}
            showSizeChanger
            hideOnSinglePage
          />
        </div>
      }
    </div>
  );
}
