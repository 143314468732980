import { Form, Button, Alert, Row, Col, Radio, Space, InputNumber } from "antd";
import Loader from "components/loader";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";

export default function MainForm(props) {
  const {
    onSubmit,
    btnText = "Add",
    loading = false,
    bidValue = 0,
    setTemp,
    temp,
    value,
    setValue,
    setTableData,
    tableData,
  } = props;

  const by_number = value === 1;

  const initialColumns = [
    {
      title: "Adset",
      dataIndex: "adsetName",
      key: "adsetName",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 60,
      minWidth: 60,
    },
    {
      title: "Bid",
      dataIndex: "bid_amount",
      key: "bid_amount",
      width: 40,
      minWidth: 40,
    },
    {
      title: "Updated Bid",
      dataIndex: "increaseBy",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={
            by_number
              ? value
              : (otherProps?.bid_amount + otherProps?.bid_amount * (value / 100)).toFixed(2)
          }
        />
      ),
      key: "increaseBy",
      width: 40,
      minWidth: 40,
    },
  ];

  const tableProps = {
    initialColumns,
    recordData: tableData,
  };

  return (
    <Form layout="vertical">
      <Row gutter={12}>
        <Radio.Group
          onChange={(e) => {
            setTemp((prev) =>
              e?.target?.value === 1
                ? prev
                : {
                    total: (bidValue + bidValue * (prev / 100)).toFixed(2),
                    input: prev,
                  }
            );
            setValue(e.target.value);
          }}
          value={value}
        >
          <Space direction="horizontal">
            <Radio value={1}> By Number </Radio>
            <Radio value={2}> By Percentage</Radio>
          </Space>
        </Radio.Group>
      </Row>
      <Col span={24}>
        <div className="look-form-input mt-15">
          <InputNumber
            name="bid"
            min={by_number ? 0 : -999}
            label="Bid"
            controls={false}
            style={{ width: "200px" }}
            placeholder="Enter Bid Amount"
            // disabled={by_budget_equals_spend}
            onChange={(e) => {
              const bid = e ? e : 0;
              setTableData(tableData?.map((item) => ({ ...item, increaseBy: bid })));
              setTemp(bid);
            }}
          />
        </div>
      </Col>

      <Col span={24} className="mb-20">
        <LookTable tableProps={tableProps} isTableFilter={false} isRowSelection={false} />
      </Col>

      <Form.Item>
        <Button
          htmlType="submit"
          block
          type="primary"
          className="submit-btn"
          onClick={onSubmit}
          disabled={!temp || loading}
        >
          {loading ? <Loader /> : btnText}
        </Button>
      </Form.Item>
    </Form>
  );
}
