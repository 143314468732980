import { useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";
import DetailView from "./DetailView";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useColumns from "../useColumns";
import { G360_GET_CUSTOMER_REPORTING } from "shared/gql/gqlSchema/g360Gql";
import { REPORT_TYPE_AD_ACCOUNT, REPORT_TYPE_CUSTOMER } from "shared/SharedKeys";
import alasql from "alasql";
import { useNewLeadgenReportsQuery } from "hooks/tiktokHooks";
import { LEADGEN_GOOGLE_PLATFORM_ID } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import { numberParse } from "utils";

export default function Customer(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude, selectedWorkspace } = userSettings || {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);

  const [spendReport, setSpendReport] = useState([]);
  const { columns } = useColumns();

  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const [rowPropsData, setRowProps] = useState({});
  const { performanceType } = props;

  const dateRangeParams = {
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone.value,
  };

  const {
    loading: loading_SPEND_CUSTOMER,
    error: error_SPEND_CUSTOMER,
    refetch: refetch_SPEND_CUSTOMER,
  } = useCustomQuery(G360_GET_CUSTOMER_REPORTING, {
    variables: {
      ...dateRangeParams,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.g_customerReporting;
      if (element) {
        setSpendReport(element);
      }
    },
  });

  const { loading_revenue, refetch_revenue, revenueReport } = useNewLeadgenReportsQuery(
    REPORT_TYPE_AD_ACCOUNT,
    {
      ...dateRangeParams,
      platform_id: LEADGEN_GOOGLE_PLATFORM_ID,
    }
  );

  const loading = loading_SPEND_CUSTOMER || loading_revenue;

  function refetch() {
    refetch_SPEND_CUSTOMER();
    refetch_revenue();
  }

  const initialColumns = [
    {
      title: "Customer Name",
      dataIndex: "adAccountName",
      key: "adAccountName",
      render: (value, otherProps) => (
        <LookTableColumnRender
          {...otherProps}
          isClick={() => showDrawerUpdate(otherProps)}
          title={value}
        />
      ),
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: "Customer Id",
      dataIndex: "customer_id",
      key: "customer_id",
      render: (value, otherProps) => (
        <LookTableColumnRender
          {...otherProps}
          isClick={() => showDrawerUpdate(otherProps)}
          title={value}
        />
      ),
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    ...columns,
  ];
  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    finalMerge = alasql(
      "SELECT * , a.clicks as googleClicks, b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b ON a.customer_id = b.revAdAccountId",
      [spendReport, revenueReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        cost = 0,
        googleClicks = 0,
        impressions: googleImpressions = 0,
        name = "",
        adaccount_name = "",
        revenue = 0,
        revClicks = 0,
        conversions = 0,
        impressions = 0,
        status = null,
      } = element;
      const costUpdate = cost.toFixed(3);

      const CPC = parseFloat(costUpdate) / parseFloat(googleClicks);
      const CTR = (parseFloat(googleClicks) / parseFloat(impressions)) * 100 || 0;
      const CPM = (parseFloat(costUpdate) / parseFloat(impressions)) * 1000;
      const EPC = parseFloat(revenue) / parseFloat(revClicks);
      const PROFIT = parseFloat(revenue) - parseFloat(costUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(costUpdate)) * 100 || 0;
      const CPR_V = parseFloat(costUpdate) / parseFloat(revClicks);
      const PAYOUT = parseFloat(revenue) / parseFloat(conversions);
      const RPV = parseFloat(revenue) / parseFloat(googleClicks);
      const RPL = parseFloat(revenue) / parseFloat(revClicks);
      const CPV = parseFloat(costUpdate) / parseFloat(googleClicks);
      const CPR = parseFloat(costUpdate) / parseFloat(conversions);

      const AD_CTR = (parseFloat(googleClicks) / parseFloat(googleImpressions)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(googleClicks)) * 100;
      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;

      finalMergeData.push({
        ...element,
        adAccountName: name || adaccount_name,
        key: index,
        revClicks,
        impressions,
        revenue: numberParse(revenue),
        cost: costUpdate,
        CPC: numberParse(CPC),
        ROI: numberParse(ROI),
        CPR_V: numberParse(CPR_V),
        PROFIT: numberParse(PROFIT),
        PAYOUT: numberParse(PAYOUT),
        EPC: numberParse(EPC),
        RPV: numberParse(RPV),
        RPL: numberParse(RPL),
        CPV: numberParse(CPV),
        AD_CTR: numberParse(AD_CTR),
        LP_CTR: numberParse(LP_CTR),
        CR: numberParse(CR),
        CPC_VIEW: numberParse(CPC, { toFixed: true }),
        CTR: numberParse(CTR),
        CTR_VIEW: numberParse(CTR, { toFixed: true }),
        CPM: numberParse(CPM),
        CPM_VIEW: numberParse(CPM, { toFixed: true }),
        CPR: numberParse(CPR),
        status,
      });
    });
  }

  const finalFilterData = filterData(finalMergeData).filter(
    ({ adAccountName = "", revAdAccountId = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";

      const searchCheck =
        adAccountName?.toLowerCase()?.includes(searchValue) ||
        revAdAccountId?.toLowerCase()?.includes(searchValue);

      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }
      return false;
    }
  );

  let total_cost = 0,
    total_clicks = 0,
    total_impressions = 0,
    total_revenue = 0,
    total_conversions = 0,
    total_googleClicks = 0;

  for (const {
    cost = 0,
    revClicks = 0,
    impressions = 0,
    revenue = 0,
    conversions = 0,
    googleClicks = 0,
  } of finalMergeData) {
    total_cost += JSON.parse(cost || 0);
    total_clicks += JSON.parse(revClicks || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_revenue += parseFloat(revenue || 0);
    total_conversions += parseFloat(conversions || 0);
    total_googleClicks += parseFloat(googleClicks || 0);
  }
  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_cost);
  const total_CPC = total_cost / total_clicks;
  const total_CTR = (total_clicks / total_impressions) * 100;
  const total_CPM = (parseFloat(total_cost) / parseFloat(total_impressions)) * 1000;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_clicks);
  const total_CPR_V = parseFloat(total_cost) / parseFloat(total_conversions);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_cost)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_clicks)) * 100;
  const total_CPV = parseFloat(total_cost) / parseFloat(total_clicks);
  const total_AD_CTR = (parseFloat(total_googleClicks) / parseFloat(total_impressions)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_clicks) / parseFloat(total_googleClicks)) * 100;

  const summaryData = {
    cost: numberParse(total_cost, { toFixed: true }),
    PAYOUT: numberParse(total_PAYOUT, { toFixed: true }),
    CPC: numberParse(total_CPC, { toFixed: true }),
    EPC: numberParse(total_EPC, { toFixed: true }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: numberParse(total_ROI, { toFixed: true }),
    CR: numberParse(total_CR, { toFixed: true }),
    revClicks: numberParse(total_clicks),
    CPR_V: numberParse(total_CPR_V, { toFixed: true }),
    CPV: numberParse(total_CPV, { toFixed: true }),
    LP_CTR: numberParse(total_LP_CTR, { toFixed: true }),
    AD_CTR: numberParse(total_AD_CTR, { toFixed: true }),
    revenue: numberParse(total_revenue, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
  };

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },
    scrollHeight: "calc(100vh - 208px)",
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
  };
  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone.value,
      workspaceId: selectedWorkspace,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setVisibleDetailDrawer(false);
  };
  const drawerdetailsViewPropsUpdate = {
    title: "Customer",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ revenueReport }, { spendReport }, { finalFilterData }],
    },
  ];

  return (
    <div>
      <DetailView
        {...drawerdetailsViewPropsUpdate}
        {...rowPropsData}
        reportType={REPORT_TYPE_AD_ACCOUNT}
        performanceType={REPORT_TYPE_CUSTOMER}
      />
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
