const image1 =
  "https://images.unsplash.com/photo-1657116992792-4b3e8c1d833e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";
const image2 =
  "https://images.unsplash.com/photo-1656666394166-9db249a4bd61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";
const image3 =
  "https://images.unsplash.com/photo-1657060701302-50b66b2e0647?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";
const image4 =
  "https://images.unsplash.com/photo-1657116992792-4b3e8c1d833e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";
const image5 =
  "https://images.unsplash.com/photo-1633476607489-703cae9685c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";
const image6 =
  "https://images.unsplash.com/photo-1657049863891-7ea43ac0092f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";
const image7 =
  "https://images.unsplash.com/photo-1655743851335-7770b475e05a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";
const image8 =
  "https://images.unsplash.com/photo-1657088746570-0218626e8f55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=80";

export const mockMediaItems = [
  { image: image1 },
  { image: image2, selected: true },
  { image: image3 },
  { image: image4 },
  { image: image5, selected: true },
  { image: image6 },
  { image: image7 },
  { image: image8 },
];
