import { Steps } from "antd";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { LAUNCH_SMART_CREATIVE_ENUM } from "shared/enum/iCampaignLaunchEnumArray";
import { SERVICE_TYPE_LEAD_GEN } from "shared/enum/launchKeysAndEnum";
import { CHOOSE_EXISTING_TEMPLATE, CREATE_NEW_TEMPLATE } from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";
import { CONTINUES_DRAFT } from "..";
import { useLaunchContext } from "../Context";
import LaunchCampaignCreationView from "./LaunchCampaignCreationView";
import AdAccount from "./LaunchCampaignCreationView/AdAccount";
import LaunchModal from "./LaunchCampaignCreationView/LaunchModal";
import UpdateForm from "./LaunchCampaignCreationView/UpdateAdset/UpdateForm";

export default function LaunchMainView(props) {
  const { serviceType, launchId, addAccountCache } = props;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const [formStep, setFormStep] = useState(0);
  const {
    saveDraft,
    campaignObject,
    leadgen_name,
    isTiktokPlatformLaunch,
    isCategoryLaunch,
    targettingTemplateData,
    visibleTargetModal,
    isTargetEdited,
    chooseExistingTemplate,
    loading_Templates,
    isValidLaunch,
    submission,
    formData,
    chooseDraftOrNew,
  } = useLaunchContext();
  const targetingTemplate = {
    targeting: targettingTemplateData,
  };

  const isValidAdset = (otherProps) => {
    const isDynamic = isTiktokPlatformLaunch
      ? otherProps?.creativeMaterialMode === LAUNCH_SMART_CREATIVE_ENUM
      : otherProps?.isDynamicCreative;
    if (isDynamic) {
      if (isTiktokPlatformLaunch) {
        return (
          (otherProps?.adCreatives?.image?.length || otherProps?.adCreatives?.video?.length) > 0 &&
          otherProps?.adCreatives?.title?.length > 0
        );
      } else
        return (
          (otherProps?.adCreatives?.image?.length || otherProps?.adCreatives?.video?.length) > 0 &&
          otherProps?.adCreatives?.body?.length > 0 &&
          otherProps?.adCreatives?.title?.length > 0
        );
    } else {
      return otherProps?.ads?.length > 0;
    }
  };
  function isValidDate(date) {
    const dateFormat = "YYYY-MM-DD HH:mm";
    const timzoneDateTime = moment().tz(dateRangeTimezone?.timeZone?.value).format(dateFormat);
    const timzoneDateTimeMomentWrap = moment(timzoneDateTime);
    return moment(date).isAfter(timzoneDateTimeMomentWrap);
  }
  const isValid = (adSets) => {
    return adSets
      ?.map((item) => {
        const isValidAdsetObject = isValidAdset(item);
        const scheduledStartDate = item?.targeting?.scheduledStartDate;
        const isValidScheduledStartDate = isTiktokPlatformLaunch
          ? scheduledStartDate
          : !scheduledStartDate || isValidDate(scheduledStartDate);

        // return isValidAdsetObject && isValidScheduledStartDate;
        return isValidAdsetObject;
      })
      .includes(false);
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modalProps = {
    isModalVisible,
    setIsModalVisible,
    showModal,
    handleOk,
    handleCancel,
    formData: formData,
    // url: url,
  };

  return (
    <div>
      {isModalVisible && <LaunchModal {...modalProps} />}
      <Steps className="m-30" style={{ width: "90%", cursor: "pointer" }} current={formStep}>
        <Steps.Step title="Targeting" onClick={() => setFormStep(0)} />
        <Steps.Step
          title="Profiles & AdAccounts"
          onClick={() =>
            isTargetEdited ||
            chooseExistingTemplate === CHOOSE_EXISTING_TEMPLATE ||
            chooseDraftOrNew === CONTINUES_DRAFT
              ? //    &&
                // typeof targettingTemplateData !== "undefined"
                setFormStep(1)
              : {}
          }
        />
        <Steps.Step
          title="Campaigns & Creatives"
          // onClick={() => (isValidLaunch?.length > 0 ? setFormStep(2) : {})}
        />
      </Steps>
      {formStep === 0 ? (
        <>
          {!visibleTargetModal &&
            (targettingTemplateData !== null || chooseExistingTemplate === CREATE_NEW_TEMPLATE ? (
              <UpdateForm
                showTargetingLaunch
                setFormStep={setFormStep}
                targetingtemplate={
                  isTiktokPlatformLaunch ? targettingTemplateData : targetingTemplate
                }
              />
            ) : (
              <Loader />
            ))}
        </>
      ) : formStep === 2 ? (
        <>
          <LaunchCampaignCreationView
            isValid={isValid}
            isValidAdset={isValidAdset}
            isValidDate={isValidDate}
            setFormStep={setFormStep}
            setIsModalVisible={setIsModalVisible}
          />
        </>
      ) : (
        <AdAccount
          formStep={formStep}
          setFormStep={setFormStep}
          addAccountCache={addAccountCache}
        />
      )}
      {!launchId && formStep === 2 && (
        <LookButton
          onClick={() => saveDraft()}
          type="primary"
          iconType="folder-open"
          className="launch-save-draft-btn"
          tooltipTitle="Save Draft"
          tooltipPlacement="left"
        />
      )}
    </div>
  );
}
