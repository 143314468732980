import { gql } from "@apollo/client";

export const TARGETING_LOCALES = gql`
  query targetingLocales {
    targetingLocales {
      key
      name
    }
  }
`;
export const LAUNCH_TIKTOK_CONFIG = gql`
  query tiktokLaunchConfig {
    tiktokLaunchConfig {
      ad
      adSet
      campaign
    }
  }
`;
export const LAUNCH_TIKTOK_COUNTRIES = gql`
  query tiktokLaunchCountries($locationId: ID) {
    tiktokLaunchCountries(locationId: $locationId) {
      id
      level
      name
      parentId
      regionCode
      supportBelow18
    }
  }
`;
export const LAUNCH_TIKTOK_STATES = gql`
  query tiktokLaunchProvince($countryIds: [ID]) {
    tiktokLaunchProvince(countryIds: $countryIds) {
      id
      level
      name
      parentId
      regionCode
      supportBelow18
    }
  }
`;
export const LAUNCH_TIKTOK_CITIES = gql`
  query tiktokLaunchCities($provinceIds: [ID]) {
    tiktokLaunchCities(provinceIds: $provinceIds) {
      id
      level
      name
      parentId
      regionCode
      supportBelow18
    }
  }
`;
export const LAUNCH_TIKTOK_LANGUAGES = gql`
  query tiktokLaunchLanguages {
    tiktokLaunchLanguages {
      code
      name
    }
  }
`;

export const LAUNCH_DATA_AD_ACCOUNT = gql`
  query launchDataAdAccount($domain: String!) {
    launchDataAdAccount(domain: $domain) {
      domain
      adAccountsData {
        adAccount
        # bm
        alias
        currency
        name
        page
        pixels {
          # bm
          id
          name
          pixelCreationDate
          pixelId
        }
      }
    }
  }
`;

export const LAUNCH_PLATFORM_DATA = gql`
  query templatePlatforms {
    templatePlatforms {
      id
      local_id
      name
    }
  }
`;
export const LAUNCH_LEAD_GEN_TRACKING_URL = gql`
  query trackerUrl($ad_account: ID, $landing_url: String) {
    trackerUrl(ad_account: $ad_account, landing_url: $landing_url) {
      ad_account
      tracker_url
      landing_url
    }
  }
`;

export const LAUNCH_TIKTOK_ADD_IDENTITY_NAME = gql`
  mutation addNewIdentityId($advertiserIds: [String]!, $displayName: String!, $imageUri: String!) {
    addNewIdentityId(
      advertiserIds: $advertiserIds
      displayName: $displayName
      imageUri: $imageUri
    ) {
      ids
      message
      status
    }
  }
`;
export const IDENTITY_RESPONSE = gql`
  fragment Identities on Identities {
    displayName
    identityId
  }
`;

export const LAUNCH_TIKTOK_GET_IDENTITY_NAME = gql`
  ${IDENTITY_RESPONSE}
  query tiktokLaunchIdentityId($advertiserId: String!, $identityType: IDENTITY_TYPE!) {
    tiktokLaunchIdentityId(advertiserId: $advertiserId, identityType: $identityType) {
      ...Identities
    }
  }
`;
export const LAUNCH_LEAD_GEN_ADD_TRACKING_URL = gql`
  mutation addTrackerUrl($ad_account: ID!, $landing_url: String!, $tracker_url: String!) {
    addTrackerUrl(ad_account: $ad_account, landing_url: $landing_url, tracker_url: $tracker_url) {
      ad_account
      tracker_url
      landing_url
    }
  }
`;

export const LAUNCH_REQUESTS = gql`
  query launchRequests(
    $bucket_id: ID
    $from_date: String!
    $id: ID
    $to_date: String!
    $product_id: ID!
  ) {
    launchRequests(
      bucket_id: $bucket_id
      from_date: $from_date
      id: $id
      to_date: $to_date
      product_id: $product_id
    ) {
      id
      bucket_id
      fbErrorDetails
      fbErrorMessage
      fbErrorTitle
      message
      name
      payload
      status
      success
    }
  }
`;
export const LAUNCH_PAGE_REQUESTS = gql`
  query tiktokGetPages($advertiserId: ID!) {
    tiktokGetPages(advertiserId: $advertiserId)
  }
`;

export const LAUNCH_TIKTOK_REQUESTS = gql`
  query tiktokLaunchRequests($bucket_id: ID, $from_date: String!, $id: ID, $to_date: String!) {
    tiktokLaunchRequests(bucket_id: $bucket_id, from_date: $from_date, id: $id, to_date: $to_date) {
      id
      bucket_id
      tiktokErrorDetails
      tiktokErrorMessage
      tiktokErrorTitle
      message
      name
      payload
      status
      success
      isCategoryLaunch
    }
  }
`;
export const LAUNCH_ADD_TRACKER_URL = gql`
  mutation addTrackerUrl($ad_account: ID!, $urls: [LandingTracker]!) {
    addTrackerUrl(ad_account: $ad_account, urls: $urls)
  }
`;

export const LAUNCH_TARGETING_FETCH_CITY = gql`
  query searchTargeting(
    $countryCode: String
    $locationTypes: [LocationType]!
    $query: [String]!
    $targetingSearchType: TargetingSearchType!
  ) {
    searchTargeting(
      countryCode: $countryCode
      locationTypes: $locationTypes
      query: $query
      targetingSearchType: $targetingSearchType
    )
  }
`;
const LAUNCH_TEMPLATE_RESPONSE = gql`
  fragment LaunchTemplate on LaunchTemplate {
    id
    name
    isPersonal
    createdBy
    createdDate
    template
  }
`;
const LAUNCH_TIKTOK_TEMPLATE_RESPONSE = gql`
  fragment TiktokLaunchTemplate on TiktokLaunchTemplate {
    id
    name
    # isPersonal
    # createdBy
    # createdDate
    template
    pacing
    bidPrice
    bidType
    budget
    budgetMode
    conversionBidPrice
    cta
    id
    platform {
      id
      name
    }
    name
    objective
    optimizationGoal
    optimizationEvent
  }
`;

export const LAUNCH_CUSTOM_CONVERSIONS_PIXELS = gql`
  query customConversionsPixels(
    $accountId: String!
    $product_id: ID!
    $hard_sync: Boolean
    $profileId: String
  ) {
    customConversionsPixels(
      accountId: $accountId
      product_id: $product_id
      hard_sync: $hard_sync
      profileId: $profileId
    ) {
      pixels {
        id
        name
      }
      customEvents
      customConversions {
        id
        name
        pixel {
          id
          name
        }
      }
      pages {
        id
        name
      }
    }
  }
`;
export const CREATE_LAUNCH_TEMPLATE = gql`
  ${LAUNCH_TEMPLATE_RESPONSE}
  mutation addLaunchTemplate($template_input: LaunchTemplateInput!) {
    addLaunchTemplate(template_input: $template_input) {
      ...LaunchTemplate
    }
  }
`;
export const CREATE_TIKTOK_LAUNCH_TEMPLATE = gql`
  ${LAUNCH_TIKTOK_TEMPLATE_RESPONSE}
  mutation tiktokAddLaunchTemplate($templateInput: TiktokLaunchTemplateInput!) {
    tiktokAddLaunchTemplate(templateInput: $templateInput) {
      ...TiktokLaunchTemplate
    }
  }
`;
export const FETCH_TIKTOK_LAUNCH_TEMPLATE = gql`
  ${LAUNCH_TIKTOK_TEMPLATE_RESPONSE}
  query tiktokLaunchTemplates($platformId: ID, $productId: ID!, $templateId: ID) {
    tiktokLaunchTemplates(platformId: $platformId, productId: $productId, templateId: $templateId) {
      ...TiktokLaunchTemplate
    }
  }
`;
const BUCKETS_RESPONSE_FOR_DROPDOWN = gql`
  fragment Bucket on Bucket {
    id
    bucket_name
    domain
  }
`;

export const GQL_BUCKETS_FOR_DROPDOWN = gql`
  ${BUCKETS_RESPONSE_FOR_DROPDOWN}
  query buckets($content_type: ID, $product_id: ID, $domain: String) {
    buckets(content_type: $content_type, product_id: $product_id, domain: $domain) {
      ...Bucket
    }
  }
`;

export const LAUNCH_GET_TEMPLATE = gql`
  query launchTemplates($platformId: ID, $templateId: ID, $productId: ID) {
    launchTemplates(platformId: $platformId, templateId: $templateId, productId: $productId) {
      bidStrategy
      createdBy
      createdDate
      budget_type
      budget
      bid
      description
      destinationType
      id
      name
      objective
      platform {
        id
        name
      }
      optimizationGoal
      scheduledEndDate
      scheduledStartDate
      template
      cta
    }
  }
`;

export const LAUNCH_GET_TEMPLATE_FOR_DROPDOWN = gql`
  query launchTemplates($platformId: ID, $templateId: ID, $productId: ID) {
    launchTemplates(platformId: $platformId, templateId: $templateId, productId: $productId) {
      id
      name
    }
  }
`;
export const LAUNCH_GET_TIKTOK_TEMPLATE_FOR_DROPDOWN = gql`
  query tiktokLaunchTemplates($platformId: ID, $templateId: ID, $productId: ID!) {
    tiktokLaunchTemplates(platformId: $platformId, templateId: $templateId, productId: $productId) {
      id
      name
    }
  }
`;
export const TIKTOK_LAUNCH_CALL = gql`
  mutation tiktokLaunch(
    $ImageMedia: [launchImageObject]
    $VideoMedia: [launchVideoObject]
    $advertisersData: [TiktokAdvertiserData]!
    $bucketId: ID
    $camps: [TiktokCampaignInput]!
    $name: String!
    $productId: ID
    $isCategoryLaunch: Boolean
  ) {
    tiktokLaunch(
      bucketId: $bucketId
      camps: $camps
      advertisersData: $advertisersData
      ImageMedia: $ImageMedia
      VideoMedia: $VideoMedia
      name: $name
      productId: $productId
      isCategoryLaunch: $isCategoryLaunch
    )
  }
`;

export const LAUNCH_GET_TIKTOK_PIXELS = gql`
  query tiktokLaunchPixels($advertiserId: ID!) {
    tiktokLaunchPixels(advertiserId: $advertiserId) {
      pixelId
      pixelName
    }
  }
`;
export const LAUNCH_GET_TIKTOK_AD_ACCOUNT = gql`
  query tiktok_getAccounts {
    tiktok_getAccounts {
      account_id
      name
    }
  }
`;

export const BUCKETS_RESPONSE = gql`
  fragment Bucket on Bucket {
    id
    bucket_name
    content_type
    product_id
    campaign_count
    adset_count
    ad_count
    bucket_status
    start_date
    end_date
    created_by
    creative_auto_select
    bucket_content {
      content_id
      name
      sub_content_id
      sub_content_name
      url
    }
    bucket_language {
      language
      language_code
    }
    campaign_nomenclature
    adset_nomenclature
    ad_nomenclature
    tags
    bucket_platform {
      name
      nickname
    }
    urls {
      content_id
      url
    }
    domain
    urls {
      content_id
      url
    }
  }
`;

export const GQL_BUCKET = gql`
  ${BUCKETS_RESPONSE}
  query bucket($bucket_id: ID!) {
    bucket(bucket_id: $bucket_id) {
      ...Bucket
    }
  }
`;

export const GQL_ADD_BUCKET = gql`
  ${BUCKETS_RESPONSE}
  mutation addBucket(
    $bucket_input: bucketAddInput!
    $bucket_platform: BucketPlatformInput
    $contents: [ContentInput]
    $urls: [ContentUrlInput]
  ) {
    addBucket(
      bucket_input: $bucket_input
      bucket_platform: $bucket_platform
      contents: $contents
      urls: $urls
    ) {
      message
      success
      data {
        ...Bucket
      }
    }
  }
`;

export const GQL_FETCH_AD_ACCOUNT_CACHE = gql`
  query adAccountCache($ad_account: ID, $product_id: ID, $platform_type: PLATFORM_TYPE) {
    adAccountCache(
      ad_account: $ad_account
      product_id: $product_id
      platform_type: $platform_type
    ) {
      ad_account
      created_at
      created_by
      id
      payload
      product_id
      updated_at
    }
  }
`;

export const GQL_ADD_ACCOUNT_CACHE = gql`
  mutation addAdAccountCache(
    $ad_accounts_data: [FBLaunchAdAccountData]!
    $platform_type: PLATFORM_TYPE!
    $product_id: ID!
  ) {
    addAdAccountCache(
      ad_accounts_data: $ad_accounts_data
      platform_type: $platform_type
      product_id: $product_id
    ) {
      id
    }
  }
`;

export const GQL_ADD_CONTENT_TO_NEW_BUCKET = gql`
  ${BUCKETS_RESPONSE}
  mutation addContentToNewBucket(
    $bucket_name: String!
    $content_type: CONTENT_TYPE!
    $contents: [ContentInput]!
    $product_id: ID!
  ) {
    addContentToNewBucket(
      bucket_name: $bucket_name
      content_type: $content_type
      contents: $contents
      product_id: $product_id
    ) {
      message
      success
      data {
        ...Bucket
      }
    }
  }
`;

export const GQL_ADD_CONTENT_TO_EXISTING_BUCKET = gql`
  ${BUCKETS_RESPONSE}
  mutation addContentToExistingBucket($bucket_id: ID!, $contents: [ContentInput]!) {
    addContentToExistingBucket(bucket_id: $bucket_id, contents: $contents) {
      message
      success
      data {
        ...Bucket
      }
    }
  }
`;

export const DELETE_LAUNCH_TEMPLATE = gql`
  mutation deleteLaunchTemplate($id: ID!, $platform_type: PLATFORM_TYPE!) {
    deleteLaunchTemplate(id: $id, platform_type: $platform_type) {
      message
      success
    }
  }
`;

export const DUPLICATE_LAUNCH_TEMPLATE = gql`
  mutation duplicateLaunchTemplate($template_id: ID!, $platform_type: PLATFORM_TYPE!) {
    duplicateLaunchTemplate(template_id: $template_id, platform_type: $platform_type) {
      id
    }
  }
`;

export const GET_BUCKET = gql`
  query GetBuckets(
    $page: Int!
    $size: Int!
    $search: String
    $column: String
    $order: ORDER
    $product_id: ID!
    $platformId: ID
  ) {
    getBuckets(
      page: $page
      size: $size
      search: $search
      column: $column
      order: $order
      product_id: $product_id
      platformId: $platformId
    ) {
      data {
        id
        bucket_name
        content_type
        product_id
        campaign_count
        adset_count
        ad_count
        bucket_status
        start_date
        end_date
        bucket_platform {
          name
          nickname
        }
        created_by
        bucket_content {
          content_id
        }
        bucket_language {
          language
          language_code
        }
        campaign_nomenclature
        adset_nomenclature
        ad_nomenclature
        tags
      }
      page_info {
        total_count
        total_pages
      }
    }
  }
`;
//    contents:[ContentInput], urls:[ContentUrlInput]):BucketResponse!

export const UPDATE_BUCKET_BY_ID = gql`
  mutation UpdateBucket(
    $bucketId: ID!
    $bucketInput: bucketUpdateInput
    $bucket_platform: BucketPlatformInput
    $contents: [ContentInput]
    $urls: [ContentUrlInput]
  ) {
    updateBucket(
      bucket_id: $bucketId
      bucket_input: $bucketInput
      bucket_platform: $bucket_platform
      contents: $contents
      urls: $urls
    ) {
      message
      success
    }
  }
`;

export const DELETE_BUCKET_BY_ID = gql`
  mutation DeleteBucket($bucketId: ID!) {
    deleteBucket(bucket_id: $bucketId) {
      message
      success
      data {
        id
        bucket_name
      }
    }
  }
`;

export const DUPLICATE_BUCKET = gql`
  mutation DuplicateBucket($bucketId: ID!) {
    duplicateBucket(bucket_id: $bucketId) {
      data {
        id
        bucket_name
      }
      message
      success
    }
  }
`;

export const DELETE_CONTENT_FROM_BUCKET = gql`
  mutation RemoveContentFromBucket($bucketId: ID!, $contentIds: [String]!) {
    removeContentFromBucket(bucket_id: $bucketId, content_ids: $contentIds) {
      data {
        bucket_content {
          content_id
          name
          product_id
        }
        id
      }
      message
      success
    }
  }
`;

export const GQL_CUSTOM_CONVERSIONS = gql`
  query customConversions($accountId: String!) {
    customConversions(accountId: $accountId) {
      id
      name
      pixel {
        id
        name
      }
    }
  }
`;

export const GQL_LAUNCH_DRAFT = gql`
  query launchDraft(
    $bucket_id: ID
    $launch_id: ID
    $platform_type: PLATFORM_TYPE!
    $content_id: ID
  ) {
    launchDraft(
      bucket_id: $bucket_id
      launch_id: $launch_id
      platform_type: $platform_type
      content_id: $content_id
    ) {
      success_camps_indexes
      bucket_id
      draft
      launch_id
      user_id
    }
  }
`;

export const GQL_LAUNCH_CATEGORY_TIKTOK_DRAFT = gql`
  query launchLeadGenDraft($launch_id: ID) {
    launchLeadGenDraft(launch_id: $launch_id) {
      success_camps_indexes
      bucket_id
      draft
      launch_id
      user_id
    }
  }
`;

export const GQL_SAVE_LAUNCH_DRAFT = gql`
  mutation saveLaunchDraft(
    $bucket_id: ID
    $draft: JSON
    $launch_id: [ID]
    $content_id: ID
    $platform_type: PLATFORM_TYPE!
  ) {
    saveLaunchDraft(
      bucket_id: $bucket_id
      draft: $draft
      launch_id: $launch_id
      content_id: $content_id
      platform_type: $platform_type
    ) {
      id
      draft
      message
      success
      title
    }
  }
`;
export const GQL_SAVE_CATEGORY_TIKTOK_LAUNCH_DRAFT = gql`
  mutation saveLeadGenLaunchDraft($draft: JSON!, $launchId: [ID], $categoryId: ID!) {
    saveLeadGenLaunchDraft(draft: $draft, launchId: $launchId, categoryId: $categoryId) {
      id
      draft
      message
      success
      title
    }
  }
`;
export const ADD_URL_FOR_BUCKET = gql`
  mutation addURLForBucketContent($bucket_id: ID!, $contents: [ContentUrlInput]) {
    addURLForBucketContent(bucket_id: $bucket_id, contents: $contents) {
      data {
        id
        bucket_name
      }
      message
      success
    }
  }
`;

export const GQL_LAUNCH_VS_BRAND_GET_BY_DOMAIN = gql`
  query vs_brandGetByDomain($domain: [String]!) {
    vs_brandGetByDomain(domain: $domain) {
      nickname
      website
    }
  }
`;

export const GQL_CHECK_LAST_SYNCED_TOKEN = gql`
  query checkLastSyncedToken($accountId: String!) {
    checkLastSyncedToken(accountId: $accountId)
  }
`;

export const GQL_GET_LAUNCH_USER_STATS = gql`
  query launchUserStats(
    $endDate: String!
    $productId: Int!
    $platformType: PLATFORM_TYPE!
    $startDate: String!
  ) {
    launchUserStats(
      endDate: $endDate
      productId: $productId
      platformType: $platformType
      startDate: $startDate
    ) {
      adCount
      launchCount
      user {
        id
        name
      }
    }
  }
`;

export const GQL_GET_LAUNCHED_BY_DATE = gql`
  query launchedByDate(
    $endDate: String!
    $platformType: PLATFORM_TYPE!
    $productId: Int!
    $selectedDate: String!
  ) {
    launchedByDate(
      endDate: $endDate
      productId: $productId
      platformType: $platformType
      selectedDate: $selectedDate
    ) {
      totalAds
      totalCampaigns
      totalLaunches
    }
  }
`;

export const GQL_GET_LAUNCH_CREATIVE_GROUPS = gql`
  query launchCreativeGroups(
    $endDate: String!
    $productId: Int!
    $platformType: PLATFORM_TYPE!
    $startDate: String!
  ) {
    launchCreativeGroups(
      endDate: $endDate
      productId: $productId
      platformType: $platformType
      startDate: $startDate
    ) {
      group {
        id
        title {
          id
          title
        }
        category_id
        body {
          body
          id
        }
        video {
          id
          s3Url
          thumbUrl
        }
        image {
          id
          imageHash
          pHash
          s3Url
        }
        createdAt
      }
      totalCount
    }
  }
`;

export const TIKTOK_LANDING_URLS = gql`
  query Tiktok_pagedLandingPage(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    tiktok_pagedLandingPage(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        category_id
        category_name
        id
        url
      }
      message
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const TIKTOK_ASSIGN_CATEGORY = gql`
  mutation Tiktok_assignCategoryToLandingPage(
    $categoryId: ID!
    $url: String!
    $categoryName: String!
  ) {
    tiktok_assignCategoryToLandingPage(
      categoryId: $categoryId
      url: $url
      categoryName: $categoryName
    ) {
      message
      status
    }
  }
`;

export const GET_CLONE_CAMP_LIST = gql`
  query clonedCampaigns($fromDate: String, $page: Int, $size: Int, $toDate: String) {
    clonedCampaigns(fromDate: $fromDate, page: $page, size: $size, toDate: $toDate) {
      accountId
      budget
      budgetOptimization
      budgetType
      cloned
      cloneOf
      createdDate
      fbCampaignId
      id
      currency
      name
      scheduledEndTime
      scheduledStartTime
      status
      updatedDate
    }
  }
`;

export const RETURN_LAUNCHED_ASSETS = gql`
  query returnLaunchedAssets($assetType: AssetEnum!, $ids: [ID]!) {
    returnLaunchedAssets(assetType: $assetType, ids: $ids)
  }
`;
