import { Form } from "antd";
import SelectInput from "components/forms/SelectInput";
import React from "react";

function RequestStatusUpdate(props) {
  const {
    ctGetRequestStatusConfig = {},
    userLevel,
    updatedData,
    setUpdatedData = () => {},
  } = props;

  function handleChangeStatus(assignedStatus) {
    const config = ctGetRequestStatusConfig?.[userLevel];
    if (!config) {
      return null;
    }
    const { statuses, allowed_statuses } = config;
    const index = statuses.findIndex((status) => status?.assigned_status === assignedStatus);
    if (index === -1) {
      return null;
    }
    const allowedIndex = allowed_statuses
      .filter((allowed) => allowed?.from === index.toString())
      .map((item) => item?.to);
    allowedIndex.push(index);
    const resultingStatuses = allowedIndex.map((items) => statuses[items]);
    return resultingStatuses;
  }
  const allowedData = handleChangeStatus(updatedData?.requestStatus);
  return (
    <div>
      <Form layout="vertical">
        <SelectInput
          name="status"
          label="Request Status"
          options={
            allowedData?.map((status, index) => ({
              value: status?.assigned_status,
              name: status?.visible_status,
            })) || []
          }
          value={updatedData?.status?.[0]}
          onChange={(e) => setUpdatedData((prevItems) => ({ ...prevItems, status: [e] }))}
        />
      </Form>
    </div>
  );
}

export default RequestStatusUpdate;
