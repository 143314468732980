import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import cx from "cx";
import React, { useEffect, useRef, useState } from "react";
import SelectInput from "components/forms/SelectInput";
import CanvasComponent from "./CanvasComponent";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { COLLAGE_GET_TEMPLATE } from "shared/gql/gqlSchema/collageGql";
import { templates } from "./contant";

export default function Collage({ files, getImageResponse }) {
  const [backgroundColor, setBackgroundColor] = useState("#ffffff"); // Default white background
  const canvasRef = useRef(null);
  const [images, setImages] = useState([]);
  const [filesBlob, setFilesBlob] = useState([]);

  // const [templates, setTemplate] = useState([]);

  // const { data, loading, error, refetch } = useCustomQuery(COLLAGE_GET_TEMPLATE, {
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted(e) {
  //     const element = e?.cl_getAllTemplates;
  //     if (element) {
  //       setTemplate(element);
  //     }
  //   },
  //   onError(e) {
  //     openNotification({ type: "error", message: e.message });
  //   },
  // });

  //future use

  const handleFileBlob = async () => {
    const loadImage = async (image) => {
      try {
        const response = await fetch(image?.s3Url + "?nocache=true");
        if (!response.ok) {
          return {};
        }
        const blob = await response.blob();
        if (blob) {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              resolve({ img, xOffset: 0, yOffset: 0 });
            };
            img.onerror = (error) => reject(error);
            img.src = URL.createObjectURL(blob);
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    const loadedImages = await Promise.all(
      files.map(async (image) => {
        const img = await loadImage(image);
        return img;
      })
    );
    // Here, we are checking whether the image blob has been generated successfully or not.
    const filteredImages = loadedImages?.filter((img) => img ?? false);
    const unFilteredImages = loadedImages?.filter((img) => img ?? true);
    unFilteredImages?.length &&
      openNotification({ type: "error", message: "Something went wrong!!" });
    setFilesBlob(filteredImages);
  };

  useEffect(() => {
    handleFileBlob();
  }, []);

  return (
    <CanvasComponent
      filesBlob={filesBlob}
      files={files}
      templates={templates}
      getImageResponse={getImageResponse}
    />
  );
}
