import { gql } from "@apollo/client";

const TASK_SERVICE_RESPONSE = gql`
  fragment TaskServiceResponseFields on taskServicetasks {
    id
    reporter_id
    title
    priority {
      id
      name
    }
    description
    due_date
    status {
      id
      name
    }
    comments {
      author {
        id
        name
      }
      comment
      id
      resource_id
      resource_type
      createdAt
      updatedAt
    }
    is_recurring
    is_deligate
    recurring_frequency
    created_by
    milestone
    priority_id
    workspace_id
    created_user {
      id
      name
    }
    reporter_user {
      id
      name
    }
    taskAssigns {
      id
      task_id
      deligated_to
      deligated_user {
        id
        name
      }
    }
    rocks {
      id
      title
    }
  }

  fragment TaskServiceResponse on taskServicetasks {
    ...TaskServiceResponseFields
    sub_task {
      ...TaskServiceResponseFields
    }
  }
`;

export const GET_SERVICE_TASKS = gql`
  ${TASK_SERVICE_RESPONSE}
  query taskServicetasks(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
    $rock_id: ID
  ) {
    taskServicetasks(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
      rock_id: $rock_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...TaskServiceResponse
      }
    }
  }
`;

export const TASK_SERVICE_CREATE_TASK = gql`
  ${TASK_SERVICE_RESPONSE}
  mutation taskServicetaskCreate(
    $is_deligate: Int
    $deligated_to: Int
    $reporter_id: Int
    $title: String
    $milestone: String
    $description: String
    $due_date: Date
    $status_id: Int
    $is_recurring: Int
    $recurring_frequency: Int
    $workspace_id: Int
    $priority_id: Int
    $rock_id: Int
    $sub_task: [Object]
    $parent_task_id: Int
  ) {
    taskServicetaskCreate(
      is_deligate: $is_deligate
      deligated_to: $deligated_to
      reporter_id: $reporter_id
      title: $title
      milestone: $milestone
      description: $description
      due_date: $due_date
      status_id: $status_id
      is_recurring: $is_recurring
      recurring_frequency: $recurring_frequency
      workspace_id: $workspace_id
      priority_id: $priority_id
      rock_id: $rock_id
      sub_task: $sub_task
      parent_task_id: $parent_task_id
    ) {
      ...TaskServiceResponse
    }
  }
`;

export const TASK_SERVICE_TASK_UPDATE = gql`
  ${TASK_SERVICE_RESPONSE}
  mutation taskServicetaskUpdate(
    $is_deligate: Int
    $deligated_to: Int
    $reporter_id: Int
    $title: String
    $description: String
    $milestone: String
    $due_date: Date
    $status_id: Int
    $is_recurring: Int
    $recurring_frequency: Int
    $priority_id: Int
    $task_id: Int
    $workspace_id: Int
  ) {
    taskServicetaskUpdate(
      is_deligate: $is_deligate
      deligated_to: $deligated_to
      reporter_id: $reporter_id
      title: $title
      description: $description
      milestone: $milestone
      due_date: $due_date
      status_id: $status_id
      is_recurring: $is_recurring
      recurring_frequency: $recurring_frequency
      priority_id: $priority_id
      task_id: $task_id
      workspace_id: $workspace_id
    ) {
      ...TaskServiceResponse
    }
  }
`;

export const TASK_SERVICE_TASK_DELETE = gql`
  mutation taskServicetaskdelete($task_id: [Int]) {
    taskServicetaskDelete(task_id: $task_id)
  }
`;

export const TASK_SERVICE_STATUS = gql`
  query taskservicestatus($type: tmsStatus, $workspace_id: Int) {
    taskServiceStatus(type: $type, workspace_id: $workspace_id) {
      id
      name
      is_default
      dark_theme_color
      light_theme_color
    }
  }
`;

export const TASK_SERVICE_PRIORITY = gql`
  query taskServicePriority {
    taskServicePriority {
      id
      name
      light_theme_color
      dark_theme_color
      icon
      is_default
    }
  }
`;

const ISSUE_SERVICE_RESPONSE = gql`
  fragment TaskServiceIssues on taskServiceissues {
    id
    type
    assigned_to
    reporter_id
    title
    description
    status {
      id
      name
    }
    reporter_user {
      id
      name
    }
    created_by
    created_user {
      id
      name
    }
    department_id
  }
`;

export const GET_SERVICE_ISSUES = gql`
  ${ISSUE_SERVICE_RESPONSE}
  query taskserviceissuesGet(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
    $rock_id: ID
  ) {
    taskServiceissues(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
      rock_id: $rock_id
    ) {
      data {
        ...TaskServiceIssues
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const TASK_SERVICE_CREATE_ISSUE = gql`
  ${ISSUE_SERVICE_RESPONSE}
  mutation taskServiceissueCreate(
    $assigned_to: Int
    $reporter_id: Int
    $title: String
    $description: String
    $status_id: Int
    $type: Int
    $rock_id: Int
    $workspace_id: Int
  ) {
    taskServiceissueCreate(
      assigned_to: $assigned_to
      reporter_id: $reporter_id
      title: $title
      description: $description
      status_id: $status_id
      type: $type
      rock_id: $rock_id
      workspace_id: $workspace_id
    ) {
      ...TaskServiceIssues
    }
  }
`;

export const TASK_SERVICE_ISSUE_UPDATE = gql`
  ${ISSUE_SERVICE_RESPONSE}
  mutation taskServiceissueUpdate(
    $issue_id: Int
    $assigned_to: Int
    $reporter_id: Int
    $title: String
    $description: String
    $type: Int
    $rock_id: Int
    $status_id: Int
  ) {
    taskServiceissueUpdate(
      issue_id: $issue_id
      assigned_to: $assigned_to
      reporter_id: $reporter_id
      title: $title
      description: $description
      type: $type
      rock_id: $rock_id
      status_id: $status_id
    ) {
      ...TaskServiceIssues
    }
  }
`;

export const TASK_SERVICE_ISSUE_DELETE = gql`
  mutation taskServiceissueDelete($issue_id: [Int]) {
    taskServiceissueDelete(issue_id: $issue_id)
  }
`;

const MEETING_SERVICE_RESPONSE = gql`
  fragment TaskServiceMeetings on taskServicemeeting {
    id
    accountable_id
    accountable_user {
      id
      name
    }
    title
    description
    date
    duration
    time
    venue
    mom
    status {
      id
      name
    }
    created_by
    meeting_frequency_id
    meeting_frequency {
      id
      period
    }
    created_user {
      id
      name
    }
    meeting_participants {
      id
      participant_id
      participant_user {
        id
        name
      }
    }
  }
`;

export const GET_SERVICE_MEETINGS = gql`
  ${MEETING_SERVICE_RESPONSE}
  query taskServicemeetings(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    taskServicemeetings(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...TaskServiceMeetings
      }
    }
  }
`;

export const TASK_SERVICE_MEETING_CREATE = gql`
  ${MEETING_SERVICE_RESPONSE}
  mutation taskServicemeetingCreate(
    $date: Date
    $description: String
    $meeting_frequency_id: Int
    $participants_id: [Int]
    $status_id: Int
    $time: Time
    $title: String
    $venue: String
    $duration: String
    $accountable_id: Int
    $mom: String
    $workspace_id: Int
  ) {
    taskServicemeetingCreate(
      date: $date
      description: $description
      meeting_frequency_id: $meeting_frequency_id
      participants_id: $participants_id
      status_id: $status_id
      time: $time
      title: $title
      venue: $venue
      duration: $duration
      accountable_id: $accountable_id
      mom: $mom
      workspace_id: $workspace_id
    ) {
      ...TaskServiceMeetings
    }
  }
`;

export const TASK_SERVICE_MEETING_UPDATE = gql`
  ${MEETING_SERVICE_RESPONSE}
  mutation taskServicemeetingUpdate(
    $meeting_id: Int
    $date: Date
    $description: String
    $venue: String
    $duration: String
    $accountable_id: Int
    $mom: String
    $meeting_frequency_id: Int
    $participants_id: [Int]
    $status_id: Int
    $time: Time
    $title: String
  ) {
    taskServicemeetingUpdate(
      meeting_id: $meeting_id
      date: $date
      description: $description
      venue: $venue
      duration: $duration
      accountable_id: $accountable_id
      mom: $mom
      meeting_frequency_id: $meeting_frequency_id
      participants_id: $participants_id
      status_id: $status_id
      time: $time
      title: $title
    ) {
      ...TaskServiceMeetings
    }
  }
`;

export const TASK_SERVICE_MEETING_DELETE = gql`
  mutation meetingDelete($meeting_id: [Int]) {
    taskServicemeetingDelete(meeting_id: $meeting_id)
  }
`;

export const TASK_MEETING_FREQUENCY = gql`
  query meetingFrequency {
    taskServicemeetingFrequency {
      id
      name
      period
    }
  }
`;

const ROCKS_SERVICE_RESPONSE = gql`
  fragment TaskServiceRocks on taskServicerock {
    id
    description
    accountable_id
    accountable_user {
      id
      name
    }
    created_user {
      id
      name
    }
    created_user {
      id
      name
    }
    department_id
    due_date
    milestone
    title
    user_id
    status {
      id
      name
    }
    rock_frequency {
      id
      value
    }
    issues {
      id
      title
    }
    tasks {
      id
      title
    }
    is_company_rock
    rockableIssueTask {
      id
      rock_id
      rockableissuetask_id
      rockableissuetask_type
    }
  }
`;

export const GET_SERVICE_ROCK = gql`
  ${ROCKS_SERVICE_RESPONSE}
  query taskservicerock(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $workspace_id: Int
  ) {
    taskServicerock(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      workspace_id: $workspace_id
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...TaskServiceRocks
      }
    }
  }
`;

export const TASK_SERVICE_ROCK_CREATE = gql`
  ${ROCKS_SERVICE_RESPONSE}
  mutation taskservicerockCreate(
    $due_date: Date
    $accountable_id: Int
    $description: String
    $status_id: Int
    $title: String
    $workspace_id: Int
    $is_company_rock: Int
  ) {
    taskServicerockCreate(
      due_date: $due_date
      accountable_id: $accountable_id
      description: $description
      is_company_rock: $is_company_rock
      status_id: $status_id
      title: $title
      workspace_id: $workspace_id
    ) {
      ...TaskServiceRocks
    }
  }
`;

export const TASK_SERVICE_ROCK_UPDATE = gql`
  ${ROCKS_SERVICE_RESPONSE}
  mutation taskServicerockUpdate(
    $rock_id: Int
    $description: String
    $status_id: Int
    $title: String
    $due_date: Date
    $accountable_id: Int
    $is_company_rock: Int
  ) {
    taskServicerockUpdate(
      rock_id: $rock_id
      description: $description
      status_id: $status_id
      title: $title
      due_date: $due_date
      accountable_id: $accountable_id
      is_company_rock: $is_company_rock
    ) {
      ...TaskServiceRocks
    }
  }
`;

export const TASK_ROCK_FREQUENCY = gql`
  query taskservicerockFrequency {
    taskServicerockFrequency {
      id
      value
    }
  }
`;

export const TASK_SERVICE_ROCK_DELETE = gql`
  mutation taskservicerockDelete($rock_id: [Int]) {
    taskServicerockDelete(rock_id: $rock_id)
  }
`;

export const USERS_BY_AGENCY_ID_NAME = gql`
  query agency($id: ID) {
    agency(id: $id) {
      id
      users {
        id
        name
      }
    }
  }
`;

export const GET_TASK_CORE_VALUES = gql`
  query taskCoreValues {
    taskCoreValues {
      id
      meaning
      user {
        name
      }
      value
    }
  }
`;

export const CREATE_TASK_CORE_VALUES = gql`
  mutation taskCoreValuesCreate($meaning: String, $value: String) {
    taskCoreValuesCreate(meaning: $meaning, value: $value) {
      id
    }
  }
`;

export const UDPATE_TASK_CORE_VALUES = gql`
  mutation taskCoreValuesUpdate($core_value_id: Int, $meaning: String, $value: String) {
    taskCoreValuesUpdate(core_value_id: $core_value_id, meaning: $meaning, value: $value) {
      id
    }
  }
`;

export const DELETE_TASK_CORE_VALUES = gql`
  mutation taskCoreValuesDelete($core_value_id: [Int]) {
    taskCoreValuesDelete(core_value_id: $core_value_id)
  }
`;
