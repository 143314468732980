import * as yup from "yup";

const passwordValidate = yup
  .string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    "Password must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  )
  .required();

export default passwordValidate;
