import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5,
} from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import { useLaunchContext } from "components/Launch/Context";
import AdGroup from "components/Launch/Creatives/AdGroup";
import Body from "components/Launch/Creatives/Body";
import CreativeUpload, { CreativeVideoUpload } from "components/Launch/Creatives/CreativeUpload";
import Description from "components/Launch/Creatives/Description";
import Title from "components/Launch/Creatives/Title";
import Loader from "components/loader";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import TabRender from "components/TabRender";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";

import React, { useEffect, useMemo, useState } from "react";
import { CONTENT_TYPE_CAMPAIGN, SERVICE_TYPE_SAS } from "shared/enum/launchKeysAndEnum";
import { GET_AI_CREATIVES } from "shared/gql/gqlSchema/creativeGql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS } from "shared/SharedKeys";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";

function AdgroupSelection(props) {
  const {
    campaignIndex,
    adSetindex,
    isKeyword,
    groupSelection = false,
    visible = false,
    dynamic = false,
    selectedData,
    submitView,
    isTiktokPlatformLaunch,
    isTemp = false,
    oneToOneAds = false,
  } = props;
  const { serviceType, productId, campaignObject } = useLaunchContext();
  const subCategoryId = isKeyword ? { subCategoryId: campaignObject[campaignIndex]?.id } : {};
  const verticalId = campaignObject[campaignIndex]?.categoryId;
  const categoryId =
    productId === PRODUCT_ID_LEADGEN ||
    (productId === PRODUCT_ID_SAS &&
      (campaignObject[campaignIndex]?.content_type === CONTENT_TYPE_CAMPAIGN || isKeyword))
      ? campaignObject[campaignIndex]?.categoryId
      : campaignObject[campaignIndex]?.id;
  const categoryName =
    productId === PRODUCT_ID_LEADGEN ||
    (productId === PRODUCT_ID_SAS &&
      (campaignObject[campaignIndex]?.content_type === CONTENT_TYPE_CAMPAIGN || isKeyword))
      ? campaignObject[campaignIndex]?.categoryName
      : campaignObject[campaignIndex]?.name;
  const keyword_id = isKeyword ? { keyword_id: campaignObject[campaignIndex]?.id } : {};
  const keyword_name = isKeyword ? { keyword_name: campaignObject[campaignIndex]?.name } : {};
  const [fetchAI_Adgroups, { data, loading, error, refetch }] = useCustomLazyQuery(
    GET_AI_CREATIVES,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.AI_adgroup_data;
      },
      // onError: (e) => {
      //   openNotification({
      //     type: "error",
      //     message: "Descriptions fetch failed ",
      //   });
      // },
    }
  );
  const subCategory =
    isKeyword && serviceType === SERVICE_TYPE_SAS
      ? {
          subCategory: {
            subCategoryId: campaignObject[campaignIndex]?.id,
            subCategoryName: campaignObject[campaignIndex]?.name,
          },
        }
      : {};
  const findCommonDynamicAds = useMemo(() => {
    if (isTemp) {
      return (creativeType, id) => {
        let isCreativeMatch = false;
        campaignObject.forEach((campaign) => {
          campaign.adSets?.forEach((adSet) => {
            adSet.adCreatives?.[creativeType.toLowerCase()]?.forEach((creatives) => {
              const creativeTypeId = creatives.id;
              if (creativeTypeId === id) {
                isCreativeMatch = true;
              }
            });
          });
        });
        return isCreativeMatch;
      };
    } else {
      return null; // or some default value if needed
    }
  }, [isTemp, campaignObject]);
  const commonProps = {
    launch: true,
    campaignIndex,
    adSetindex,
    serviceType,
    productId,
    subCategoryId,
    categoryId,
    subCategory,
    verticalId,
    categoryName,
    isKeyword,
    isTemp,
    oneToOneAds,
    findCommonDynamicAds,
    isTiktokPlatformLaunch,
  };

  const tabContent = [
    {
      id: "Creative-Upload",
      // title: groupSelection ? `Creative ${hoverTitleAppend}` : undefined,
      hoverTitle: `Creative Upload`,
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="picture" {...e} />,
      component: <CreativeUpload creativeType={CREATIVE_TYPE_IMAGE} {...commonProps} />,
    },
    // {
    //   id: "Thumb-Upload",
    //   // title: groupSelection ? `Thumb ${hoverTitleAppend}` : undefined,
    //   hoverTitle: `Thumb ${hoverTitleAppend}`,
    //   icon: (e) => <ActionIconRender iconType="picture" {...e} />,
    //   component: <CreativeUpload {...props} creativeType={CREATIVE_TYPE_THUMBNAIL} />,
    // },
    {
      id: "Video-Upload",
      // title: groupSelection ? `Video ${hoverTitleAppend}` : undefined,
      hoverTitle: `Video Upload`,
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="video-camera" {...e} />,
      component: <CreativeVideoUpload {...commonProps} creativeType={CREATIVE_TYPE_VIDEO} />,
    },
    {
      id: "Title",
      // title: groupSelection ? `Title ${hoverTitleAppend}` : undefined,
      hoverTitle: `Title Upload`,
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="font-size" {...e} />,
      component: (
        <Title
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
          {...commonProps}
          titleAIData={data}
        />
      ),
    },
    {
      id: "Body",
      // title: groupSelection ? `Body ${hoverTitleAppend}` : undefined,
      hoverTitle: `Body Upload`,
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="font-colors" {...e} />,
      component: (
        <Body
          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3}
          {...commonProps}
          bodyAIData={data}
        />
      ),
    },
    {
      id: "Description",
      // title: groupSelection ? `Description ${hoverTitleAppend}` : undefined,
      hoverTitle: `Description Upload`,
      noSpace: true,
      icon: () => <ActionIconRender iconType="align-center" />,
      component: (
        <Description
          launch
          // groupSelection

          persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5}
          {...commonProps}
          descriptionAIData={data}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchAI_Adgroups({
      variables: {
        param: categoryName,
      },
    });
  }, []);

  const [activeTab, setActiveTab] = useState(tabContent[0]?.id);

  return (
    <div style={{ height: "100%", overflowY: "hidden" }}>
      {loading ? (
        <Loader elementCenter />
      ) : dynamic ? (
        <TabRender
          className="top-bar"
          fullBtn
          contentClassName="px-20"
          tabContent={tabContent}
          value={activeTab}
          onChange={setActiveTab}
        />
      ) : !groupSelection ? (
        <AdGroup
          {...props}
          keyword_id={keyword_id}
          launch
          keyword_name={keyword_name}
          {...commonProps}
        />
      ) : (
        <div className="d-flex h-100" style={{ flexWrap: "wrap" }}>
          <div
            style={{
              flex: "0 0 33%",
              maxWidth: "33%",
              borderRight: "1px solid #424242",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <CardCreativeAdGroup {...selectedData} />
          </div>
          <div style={{ flex: "0 0 67%", maxWidth: "67%" }}>
            <TabRender
              className="top-bar creative-drawer-tab"
              fullBtn
              contentClassName="px-20"
              tabContent={tabContent}
              value={activeTab}
              onChange={setActiveTab}
            />
            <div className="px-12">{submitView}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdgroupSelection;
