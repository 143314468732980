import { useState } from "react";
import { Popover, Button } from "antd";
import classes from "./style.module.scss";
import cx from "classnames";
import ActionIconRender from "../ActionIconRender";

function PopoverContent(props) {
  const { options = [], optionTrigger = () => {} } = props;

  return (
    <div>
      {options.map(({ option, title, optionProps, customColor }, index) => {
        return (
          <div
            key={"options" + index}
            className={cx({ "mt-10": index !== 0 })}
            onClick={optionTrigger}
          >
            <Button
              block
              className={cx(classes.optionBtn, "text-left p-0 border-0")}
              {...optionProps}
            >
              <span className="mr-10">
                <ActionIconRender iconType={option} style={{ color: customColor }} />
              </span>
              {title}
            </Button>
          </div>
        );
      })}
    </div>
  );
}

function PopoverWrapper(props) {
  const { className = "", children, options = [] } = props;
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      className={className}
      content={<PopoverContent options={options} optionTrigger={() => setVisible(false)} />}
      visible={visible}
      onVisibleChange={(e) => setVisible(e)}
      trigger="click"
      placement="bottomRight"
    >
      <div className="d-table">{children}</div>
    </Popover>
  );
}

export default PopoverWrapper;
