import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import {
  GET_TIKTOK_REVENUE_CATEGORY_REPORT,
  GET_TIKTOK_REVENUE_CREATIVE_REPORT,
  GET_TIKTOK_REVENUE_PLATFORM_REPORT,
  GET_TIKTOK_SPEND_CATEGORY_REPORT,
  GET_TIKTOK_SPEND_CREATIVE_REPORT,
  GET_TIKTOK_SPEND_PLATFORM_REPORT,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import {
  GET_SAS_ADACCOUNT_REPORTS,
  GET_TIKTOK_ADACCOUNT_REPORTS,
  GQL_FB360_CREATIVE_ADACCOUNT_REPORTING,
  GQL_FB360_DETAILED_CATEGORY_SPEND_REPORTING,
  GQL_FB360_DETAILED_PLATFORM_SPEND_REPORTING,
  GQL_FB360_DETAILED_SPEND_REPORTING,
  GQL_VOLUUM_CATEGORY_REVENUE_REPORTING,
  GQL_VOLUUM_DETAILED_REVENUE_REPORTING,
  GQL_VOLUUM_PLATFORM_REVENUE_REPORTING,
} from "shared/gql/gqlSchema/creativeGql";
import {
  GQL_VS_CREATIVE_LIVE_ADS_REPORT,
  GQL_VS_CREATIVE_LIVE_AD_ACCOUNT_REPORT,
  GQL_VS_CREATIVE_LIVE_CONTENT_REPORT,
} from "shared/gql/gqlSchema/viralSparksGql";
import { openNotification } from "utils";

function useCreativeDetailedReportingApiHooks({
  setSpendDetailedReport,
  setRevDetailedReport,
  revDetailedKey,
  spendDetailedKey,
  revDetailedReport,
  spendDetailedReport,
  setSpendPlatformDetailedReport,
  setRevPlatformDetailedReport,
}) {
  const [
    revenueVSLiveAdFetch,
    { loading: loading_revenueVSLiveAdFetch, refetch: refetch_revenueVSLiveAdFetch },
  ] = useCustomLazyQuery(GQL_VS_CREATIVE_LIVE_ADS_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_creativeLiveAdsReports;
      if (element !== null || element?.length > 0) {
        setRevDetailedReport(element?.map((data) => ({ ...data, revenue: data?.user_revenue })));
      } else {
        setRevDetailedReport([]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [
    revenueVSLiveAdAccountFetch,
    { loading: loading_revenueVSLiveAdAccountFetch, refetch: refetch_revenueVSLiveAdAccountFetch },
  ] = useCustomLazyQuery(GQL_VS_CREATIVE_LIVE_AD_ACCOUNT_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_creativeLiveAdAccountReports;
      if (element !== null || element?.length > 0) {
        setRevDetailedReport(element?.map((data) => ({ ...data, revenue: data?.user_revenue })));
      } else {
        setRevDetailedReport([]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [
    revenueVSLiveContnetFetch,
    { loading: loading_revenueVSLiveContnetFetch, refetch: refetch_revenueVSLiveContnetFetch },
  ] = useCustomLazyQuery(GQL_VS_CREATIVE_LIVE_CONTENT_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_creativeLiveContentReports;
      if (element !== null || element?.length > 0) {
        setRevDetailedReport(element?.map((data) => ({ ...data, revenue: data?.user_revenue })));
      } else {
        setRevDetailedReport([]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [revenueFbFetch, { loading: loading_rev_fb_detailed_reports, refetch: refetch_fb_rev }] =
    useCustomLazyQuery(GQL_VOLUUM_DETAILED_REVENUE_REPORTING, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[revDetailedKey];
        if (element !== null || element?.length > 0) {
          setRevDetailedReport(
            element?.map((data) => ({
              ...data,
              revenue: data?.revenue + data?.payout_amount,
              revClicks: data?.clicks,
            }))
          );
        } else {
          setRevDetailedReport([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [
    revenueTiktokFetch,
    { loading: loading_rev_tiktok_detailed_reports, refetch: refetch_tiktok_rev },
  ] = useCustomLazyQuery(GET_TIKTOK_REVENUE_CREATIVE_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[revDetailedKey];
      if (element !== null) {
        setRevDetailedReport(
          element?.map((item) => ({
            ...item,
            revenue: item?.estimate_revenue || item?.revenue,
            revClicks: item?.clicks,
          }))
        );
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [spendFbFetch, { loading: loading_spend_fb_detailed_reports, refetch: refetch_spend_fb }] =
    useCustomLazyQuery(GQL_FB360_DETAILED_SPEND_REPORTING, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[spendDetailedKey];
        if (element !== null) {
          setSpendDetailedReport(
            element?.map((data) => ({ ...data, inlineClicks: data?.inline_link_clicks }))
          );
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  const [
    spendTiktokFetch,
    { loading: loading_spend_tiktok_detailed_reports, refetch: refetch_spend_tiktok },
  ] = useCustomLazyQuery(GET_TIKTOK_SPEND_CREATIVE_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[spendDetailedKey];
      if (element !== null) {
        setSpendDetailedReport(
          element?.map((data) => ({
            ...data,
            conversions: data?.conversion,
          }))
        );
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    revenueFbPlatformFetch,
    { loading: loading_rev_fb_platform_detailed_reports, refetch: refetch_fb_platform_rev },
  ] = useCustomLazyQuery(GQL_VOLUUM_PLATFORM_REVENUE_REPORTING, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.voluum_creativePlatformReporting;
      if (element !== null) {
        console.log(revDetailedReport);
        setRevPlatformDetailedReport((prev) => ({
          tiktok: prev?.tiktok || [],
          fb: element?.map((data) => ({
            ...data,
            revenue: data?.revenue + data?.payout_amount,
          })),
        }));
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    revenueFbCategoryFetch,
    { loading: loading_rev_fb_category_detailed_reports, refetch: refetch_fb_category_rev },
  ] = useCustomLazyQuery(GQL_VOLUUM_CATEGORY_REVENUE_REPORTING, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.voluum_creativeCategoryReporting;
      if (element !== null) {
        setRevDetailedReport(
          element?.map((data) => ({
            ...data,
            revenue: data?.revenue + data?.payout_amount,
            category_id: data?.category?.id,
            revClicks: data?.clicks,
          }))
        );
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    revenueTiktokCategoryFetch,
    { loading: loading_rev_tiktok_category_detailed_reports, refetch: refetch_tiktok_category_rev },
  ] = useCustomLazyQuery(GET_TIKTOK_REVENUE_CATEGORY_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.sasCreativeCategoryReport;
      if (element !== null) {
        setRevDetailedReport(
          element?.map((item) => ({
            ...item,
            revenue: item?.estimate_revenue || item?.revenue,
            category_id: item?.categoryId,
            revClicks: item?.clicks,
          }))
        );
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    revenueTiktokPlatformFetch,
    { loading: loading_rev_tiktok_platform_detailed_reports, refetch: refetch_tiktok_platform_rev },
  ] = useCustomLazyQuery(GET_TIKTOK_REVENUE_PLATFORM_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      console.log(revDetailedReport);
      const element = e?.sasCreativePlatformReport;
      if (element !== null) {
        setRevPlatformDetailedReport((prev) => ({
          fb: prev?.fb || [],
          tiktok: element?.map((item) => ({
            ...item,
            revenue: item?.estimate_revenue || item?.revenue,
            platform_id: item?.platformId,
          })),
        }));
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    spendTiktokCategoryFetch,
    {
      loading: loading_spend_tiktok_category_detailed_reports,
      refetch: refetch_spend_category_tiktok,
    },
  ] = useCustomLazyQuery(GET_TIKTOK_SPEND_CATEGORY_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktok_getCreativeCategoryReport;
      if (element?.data !== null) {
        setSpendDetailedReport(element?.data);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    spendTiktokPlatformFetch,
    {
      loading: loading_spend_tiktok_platform_detailed_reports,
      refetch: refetch_spend_tiktok_platform,
    },
  ] = useCustomLazyQuery(GET_TIKTOK_SPEND_PLATFORM_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      console.log(spendDetailedReport);
      const element = e?.tiktok_getCreativePlatformReport;
      if (element?.data !== null) {
        setSpendPlatformDetailedReport((prev) => ({ fb: prev?.fb, tiktok: [...element?.data] }));
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    spendFbCategoryFetch,
    { loading: loading_spend_fb_category_detailed_reports, refetch: refetch_spend_fb_category },
  ] = useCustomLazyQuery(GQL_FB360_DETAILED_CATEGORY_SPEND_REPORTING, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.fb360_creativeCategoryReporting;
      if (element !== null) {
        setSpendDetailedReport(
          element?.map((data) => ({ ...data, inlineClicks: data?.inline_link_clicks }))
        );
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    spendFbPlatformFetch,
    { loading: loading_spend_fb_platform_detailed_reports, refetch: refetch_spend_fb_platform },
  ] = useCustomLazyQuery(GQL_FB360_DETAILED_PLATFORM_SPEND_REPORTING, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      console.log(spendDetailedReport);
      const element = e?.fb360_creativePlatformReporting;
      if (element !== null) {
        setSpendPlatformDetailedReport((prev) => ({ tiktok: prev?.tiktok, fb: element }));
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [spendAdAccountFetch, { loading: loading_adAccount_fb, refetch: refetch_adAccount_fb }] =
    useCustomLazyQuery(GQL_FB360_CREATIVE_ADACCOUNT_REPORTING, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.fb360_creativeAdaccountReporting;
        if (element !== null) {
          setSpendDetailedReport(element);
        } else {
          setSpendDetailedReport([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  const [
    spendAdAccountTiktokFetch,
    { loading: loading_adAccount_tiktok, refetch: refetch_adAccount_tiktok },
  ] = useCustomLazyQuery(GET_TIKTOK_ADACCOUNT_REPORTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktok_getCreativeAdaccountReport;
      if (element !== null) {
        setSpendDetailedReport(element);
      } else {
        setSpendDetailedReport([]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [
    revSasAdAccountTiktokFetch,
    { loading: loading_adAccount_rev_tiktok, refetch: refetch_adAccount_rev_tiktok },
  ] = useCustomLazyQuery(GET_SAS_ADACCOUNT_REPORTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.sasCreativeAdaccountReport;
      if (element !== null) {
        setRevDetailedReport(element);
      } else {
        setRevDetailedReport([]);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  return {
    revSasAdAccountTiktokFetch,
    spendAdAccountTiktokFetch,
    loading_adAccount_rev_tiktok,
    refetch_adAccount_rev_tiktok,
    loading_adAccount_tiktok,
    refetch_adAccount_tiktok,
    spendAdAccountFetch,
    loading_adAccount_fb,
    refetch_adAccount_fb,
    revenueFbFetch,
    loading_rev_fb_detailed_reports,
    refetch_fb_rev,
    revenueTiktokFetch,
    loading_rev_tiktok_detailed_reports,
    refetch_tiktok_rev,
    spendFbFetch,
    loading_spend_fb_detailed_reports,
    refetch_spend_fb,
    spendTiktokFetch,
    loading_spend_tiktok_detailed_reports,
    refetch_spend_tiktok,
    revenueFbPlatformFetch,
    loading_rev_fb_platform_detailed_reports,
    refetch_fb_platform_rev,
    revenueFbCategoryFetch,
    loading_rev_fb_category_detailed_reports,
    refetch_fb_category_rev,
    revenueTiktokCategoryFetch,
    loading_rev_tiktok_category_detailed_reports,
    refetch_tiktok_category_rev,
    revenueTiktokPlatformFetch,
    loading_rev_tiktok_platform_detailed_reports,
    refetch_tiktok_platform_rev,
    spendTiktokCategoryFetch,
    loading_spend_tiktok_category_detailed_reports,
    refetch_spend_category_tiktok,
    spendTiktokPlatformFetch,
    loading_spend_tiktok_platform_detailed_reports,
    refetch_spend_tiktok_platform,
    spendFbCategoryFetch,
    loading_spend_fb_category_detailed_reports,
    refetch_spend_fb_category,
    spendFbPlatformFetch,
    loading_spend_fb_platform_detailed_reports,
    refetch_spend_fb_platform,

    // VS - Revenue Fetch
    revenueVSLiveAdFetch,
    loading_revenueVSLiveAdFetch,
    refetch_revenueVSLiveAdFetch,
    revenueVSLiveAdAccountFetch,
    loading_revenueVSLiveAdAccountFetch,
    refetch_revenueVSLiveAdAccountFetch,
    revenueVSLiveContnetFetch,
    loading_revenueVSLiveContnetFetch,
    refetch_revenueVSLiveContnetFetch,
  };
}

export default useCreativeDetailedReportingApiHooks;
