import alasql from "alasql";
import { valueCheck } from "..";
import { Col, Row, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { OverviewCalculatedCard, OverviewCard } from "../Cards";
import cx from "classnames";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { F360_REPORTS_ADMIN } from "shared/UserPermission/userPermissionList";
import DebugPre from "components/DebugPre";
import { numCompactShort, openNotification } from "utils";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import { GET_VOLUUM_USER_REPORT } from "shared/gql/gqlSchema/tiktok";
import { useState } from "react";
import { GET_F360_USER_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";

export default function LeadGenRevenueData() {
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userId = useSelector((state) => state?.userReducer?.me?.user_id);
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const [userReport, setUserReport] = useState([]);
  const [spendReport, setSpendReport] = useState([]);

  const isAdmin = permissions?.filter((name) => name === F360_REPORTS_ADMIN).length > 0;
  // const apiProps = isAdmin ? {} : { userId };

  const {
    data: data_GET_REPORT_BY_USER,
    loading: loading_GET_REPORT_BY_USER,
    error: error_GET_REPORT_BY_USER,
    refetch: refetch_GET_REPORT_BY_USER,
  } = useCustomQuery(GET_F360_USER_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.fb360_userReporting;
      if (element) {
        setSpendReport(
          element?.map((item) => ({
            ...item,
            name: item?.campaign_name,
            campaignId: item?.campaign_id,
            adAccountId: item?.adaccount_id,
            clicksFB: item?.clicks,
            spendFB: item?.spend,
          }))
        );
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
    variables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      is_detailed: false,
      // ...apiProps,
    },
  });

  const {
    data: data_GET_REVENUE_BY_USER,
    loading: loading_GET_REVENUE_BY_USER,
    error: error_GET_REVENUE_BY_USER,
    refetch: refetch_GET_REVENUE_BY_USER,
  } = useCustomQuery(GET_VOLUUM_USER_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.voluum_get_user_report;
      if (element) {
        setUserReport(element);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      is_detailed: false,
      // ...apiProps,
    },
  });

  const loading = loading_GET_REPORT_BY_USER || loading_GET_REVENUE_BY_USER;

  let finalMerge = [];
  let finalMergeData = [];

  if (!loading) {
    finalMerge = alasql(
      "SELECT *, b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b ON a.userId = b.user_id",
      [spendReport, userReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        spend = 0,
        clicksFB = 0,
        impressionsFB = 0,
        revenue = 0,
        visits = 0,
        revClicks = 0,
        conversions = 0,
        leadsFB = 0,
        user,
        x_revenue = 0,
        payout_amount = 0,
      } = element;

      const addSpendPer =
        LEADGEN_OUTSIDE_ADVERTISER.includes(userId)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;
      const spendUpdate = (spend * addSpendPer).toFixed(2);

      const updatedRevenu =
        parseFloat(revenue) + parseFloat(x_revenue ?? 0) + parseFloat(payout_amount ?? 0);

      const PROFIT = parseFloat(updatedRevenu) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;
      const PAYOUT = parseFloat(updatedRevenu) / parseFloat(conversions);
      const CPV = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const EPV = parseFloat(updatedRevenu) / parseFloat(clicksFB);
      const CPC = parseFloat(spendUpdate) / parseFloat(revClicks);
      const EPC = parseFloat(updatedRevenu) / parseFloat(revClicks);
      const AD_CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(clicksFB)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;

      finalMergeData.push({
        ...element,
        key: index,
        revenue: updatedRevenu,
        visits,
        clicksFB,
        impressionsFB,
        leadsFB,
        revClicks,
        conversions,
        spend: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI),
        CR: valueCheck(CR),
        PAYOUT: valueCheck(PAYOUT),
        CPV: valueCheck(CPV),
        EPV: valueCheck(EPV),
        CPC: valueCheck(CPC),
        EPC: valueCheck(EPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        username: user?.name,
      });
    });
  }

  let total_spend = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_revenue = 0,
    total_revClicks = 0,
    total_conversions = 0;

  for (const {
    spend = 0,
    clicksFB = 0,
    impressionsFB = 0,
    revenue = 0,
    revClicks = 0,
    conversions = 0,
  } of finalMergeData) {
    total_spend += parseFloat(spend || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_revenue += parseFloat(revenue || 0);
    total_revClicks += parseFloat(revClicks || 0);
    total_conversions += parseFloat(conversions || 0);
  }

  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_spend);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_spend)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_AD_CTR = (parseFloat(total_clicksFB) / parseFloat(total_impressionsFB)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_clicksFB)) * 100;

  const overview = [
    {
      iconType: "creditCard",
      title: "Spend",
      value: (
        <>
          <Tooltip title={valueCheck(total_spend, true, 2)}>
            {"$" + numCompactShort(valueCheck(total_spend, true), 2)}
          </Tooltip>
        </>
      ),
      // valueBy: "per day",
    },
    {
      iconType: "barChartLine",
      title: "Gross Margin",
      value: (
        <Tooltip title={total_PROFIT.toFixed(2)}>
          <span
            className={cx({
              "text-primary": total_PROFIT > 0,
              "text-danger": total_PROFIT < 0,
            })}
          >
            ${numCompactShort(total_PROFIT.toFixed(1).replace("-", ""), 2)}
          </span>
        </Tooltip>
      ),
      iconClassName: total_PROFIT > -0.01 ? "" : "text-danger",
      // valueBy: "per day",
    },
    {
      iconType: "dollar",
      title: "Revenue",
      value: (
        <>
          <Tooltip title={valueCheck(total_revenue, true, 2)}>
            {"$" + numCompactShort(valueCheck(total_revenue, true), 2)}
          </Tooltip>
        </>
      ),
      // valueBy: "per day",
    },
    {
      iconType:
        valueCheck(total_ROI, true, 2) == "0.00"
          ? "line"
          : valueCheck(total_ROI, true, 2) > 0
          ? "rise"
          : "fall",
      title: "ROI",
      value: <>{valueCheck(total_ROI, true, 2)}%</>,
      iconClassName: valueCheck(total_ROI, true, 2) > -0.01 ? "" : "text-danger",
      // valueBy: "per day",
    },
  ];

  const overviewCalculatedData = [
    { title: "Clicks", value: numCompactShort(valueCheck(total_clicksFB, true), 2) },
    { title: "Conversion", value: numCompactShort(valueCheck(total_conversions, true), 2) },
    { title: "CR", value: numCompactShort(valueCheck(total_CR, true), 2) },
    { title: "LP CTR", value: numCompactShort(valueCheck(total_LP_CTR, true), 2) },
    { title: "AD CTR", value: numCompactShort(valueCheck(total_AD_CTR, true), 2) },
    { title: "EPC", value: numCompactShort(valueCheck(total_EPC, true), 2) },
  ];

  const renderSkeleton = () => (
    <>
      <div>
        <Row gutter={28}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Row gutter={21}>
          {overviewCalculatedData.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overviewCalculatedData.length}
              xl={24 / overviewCalculatedData.length}
              key={"overviewCalculatedData" + index}
            >
              <OverviewCalculatedCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
  const renderContent = () => (
    <>
      <div>
        <Row gutter={28}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard {...data} isSmall />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Row gutter={21}>
          {overviewCalculatedData.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overviewCalculatedData.length}
              xl={24 / overviewCalculatedData.length}
              key={"overviewCalculatedData" + index}
            >
              <OverviewCalculatedCard {...data} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  return (
    <div>
      {loading ? renderSkeleton() : renderContent()}
      <DebugPre
        content={[
          { isRow: true, contentObjects: [{ userReport }, { spendReport }] },
          {
            isRow: true,
            contentObjects: [{ data_GET_REPORT_BY_USER }, { data_GET_REVENUE_BY_USER }],
          },
        ]}
      />
    </div>
  );
}
