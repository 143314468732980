import { PicLeftOutlined, RocketOutlined } from "@ant-design/icons";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_GOOGLE_DISPLAY,
  PLATFORM_ID_TIKTOK,
} from "shared/SharedKeys";

export default function useRsocPathsObject(props = {}) {
  const { permission } = props;
  const rsocpathsObject = {
    subMenuName: "AD New Reports",
    subMenuIcon: RocketOutlined,
    permission,
    isCollapsible: true,
    subMenus: [
      {
        subMenuName: "Facebook Report",
        subMenuLink: "/reports/rsoc-ad-facebook-report",
        subMenuIcon: PicLeftOutlined,
        permission: { permission, platform: PLATFORM_ID_FACEBOOK },
      },
      {
        subMenuName: "Tiktok Report",
        subMenuLink: "/reports/rsoc-ad-tiktok-report",
        subMenuIcon: PicLeftOutlined,
        permission: { permission, platform: PLATFORM_ID_TIKTOK },
      },
      {
        subMenuName: "Gdn Report",
        subMenuLink: "/reports/rsoc-ad-gdn-report",
        subMenuIcon: PicLeftOutlined,
        permission: { permission, platform: PLATFORM_ID_GOOGLE_DISPLAY },
      },
    ],
  };
  return { rsocpathsObject };
}
