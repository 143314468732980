import { Alert, Form, Select } from "antd";
import { ErrorMessage, Field } from "formik";
import cx from "classnames";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import parse from "html-react-parser";
import { memo } from "react";
// import DebugPre from "../../DebugPre";
const { Option } = Select;

function SelectInput(props) {
  const {
    label,
    value,
    name = "",
    error,
    removeIcon,
    onChange = () => {},
    onClick = () => {},
    multiple = false,
    disabled = false,
    options = [],
    className = "",
    selectClassName = "",
    onInputKeyDown = () => {},
    style = {},
    search = false,
    placeholder = "",
    onBlur = () => {},
    showAction = [],
    ref,
    formik = false,
    mode,
    required = false,
    loader = false,
    loading = false,
    refetch,
    showArrow = true,
    size = "middle",
    dropdownRender,
    dropdownClassName,
    optional = false,
    maxTagCount = "",
    maxTagTextLength,
    isAlert = false,
    alertMessage = "",
  } = props;

  const isOptional = optional ? <span className="optional-text"> (Optional)</span> : undefined;
  const renderLabel = label ? (
    <>
      {label}
      {isOptional}
    </>
  ) : undefined;

  const basicProps = {
    mode,
    removeIcon,
    className: cx("w-100", selectClassName),
    onChange: formik ? (e, b) => onChange(name, e, b) : onChange,
    onClick,
    onBlur,
    onInputKeyDown,
    value,
    placeholder,
    showArrow,
    size,
    dropdownRender,
    dropdownClassName,
    maxTagCount,
    maxTagTextLength,
  };

  const modeProps = multiple ? { mode: "multiple" } : {};
  const disableProps = disabled || loader ? { disabled } : {};

  const searchProps = search
    ? {
        showSearch: true,
        optionFilterProp: "children",
        filterOption: (input, option) => {
          return option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
        },
      }
    : {};

  const selectProps = { ...basicProps, ...searchProps, ...modeProps, ...disableProps };

  function renderOptions() {
    return options?.map(({ name = "", options: childrenOption, value }, index) => {
      if (childrenOption) {
        return (
          <Select.OptGroup label={name} key={"options" + index}>
            {childrenOption?.map((data, indexChild) => {
              return (
                <Option key={index + "options" + indexChild} value={data?.value}>
                  {data?.name}
                </Option>
              );
            })}
          </Select.OptGroup>
        );
      }

      return (
        <Option key={"options" + index} value={value}>
          {name}
        </Option>
      );
    });
  }

  const temp = label || name;

  const customErrorMessage =
    required && !formik
      ? { message: `${temp?.charAt(0)?.toUpperCase() + temp?.slice(1)} is required.` }
      : {};

  const withRequired =
    required && !formik ? { rules: [{ required, ...customErrorMessage }], name } : {};

  const refetchButton = refetch
    ? [
        {
          tooltipTitle: "Refetch",
          iconType: "reload",
          iconTypeProps: { spin: loading },
          onClick: () => refetch(),
        },
      ]
    : [];

  const actionButtons = [...refetchButton];

  const renderActionButtons = () =>
    actionButtons?.length > 0 ? (
      <div className="action-btn">
        {actionButtons.map((buttonData) => (
          <LookButton className="filter-btn ml-8" size="small" {...buttonData} />
        ))}
      </div>
    ) : null;

  const renderError = (error) => <div className="text-left error-text">{parse(error)}</div>;
  return (
    <div className={cx("look-form-input look-form-select", className)}>
      <Form.Item
        label={
          label ? (
            <>
              {renderLabel} {required && formik && <span className="text-danger">*</span>}
            </>
          ) : undefined
        }
        {...withRequired}
      >
        {formik ? (
          <>
            <Field
              className={cx("w-100", selectClassName)}
              style={style}
              {...selectProps}
              as={Select}
            >
              {renderOptions()}
            </Field>
            <ErrorMessage name={name} component="div" className="text-left error-text" />
          </>
        ) : (
          <Select ref={ref} showAction={showAction} {...selectProps}>
            {renderOptions()}
          </Select>
        )}
      </Form.Item>
      {renderActionButtons()}

      {loader && (
        <Loader
          elementCenter={!label ? true : false}
          elementBottomCenter={label ? true : false}
          className={cx({ "pb-1": label })}
        />
      )}
      {!formik && error && renderError(error)}
      {isAlert && <Alert message={alertMessage} type="warning" showIcon className="mb-12" />}
    </div>
  );
}

export default memo(SelectInput);
