import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  VS_VIEW_AGENCY,
  VS_VIEW_ADVERTISER_PANEL,
  VS_VIEW_BRAND,
  VS_VIEW_CONTENT,
  VS_VIEW_PLATFORM,
  VS_VIEW_REPORTING,
  VS_VIEW_USER,
  VS_VIEW_PERFORMANCE,
} from "../../shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";
import AddRule from "components/LookTable/RuleEngine";
import { PRODUCT_ID_VS, RULE_PLATFORM_FB } from "shared/SharedKeys";
import ExistingAdminRule from "components/LookTable/RuleEngine/AdminRules";
import AdAccountReports from "pages/dashboardPages/VS/VSReports/VSReports/FacebookReport/AdAccountReports";

const VSAdvertisers = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "VSAdvertisers" */ "pages/dashboardPages/VS/VSAdvertisers")
  )
);
const VSAgencies = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "VSAgencies" */ "pages/dashboardPages/VS/VSAgencies")
  )
);
const VSBrands = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "VSBrands" */ "pages/dashboardPages/VS/VSBrands"))
);
const VSContent = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "VSContent" */ "pages/dashboardPages/VS/VSContent"))
);
const VSPlatform = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "VSPlatform" */ "pages/dashboardPages/VS/VSPlatform")
  )
);
const VSUsers = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "VSUsers" */ "pages/dashboardPages/VS/VSUsers"))
);
const VSCampaignReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSCampaignReport" */ "pages/dashboardPages/VS/VSReports/VSCampaignReport"
    )
  )
);
const VSCountryReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSCountryReport" */ "pages/dashboardPages/VS/VSReports/VSCountryReport"
    )
  )
);
const VSOsReport = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "VSOsReport" */ "pages/dashboardPages/VS/VSReports/VSOsReport")
  )
);
const VSDeviceReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSDeviceReport" */ "pages/dashboardPages/VS/VSReports/VSDeviceReport"
    )
  )
);
const VSMediumReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSMediumReport" */ "pages/dashboardPages/VS/VSReports/VSMediumReport"
    )
  )
);
const VSUserReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSUsersReport" */ "pages/dashboardPages/VS/VSReports/VSUsersReport"
    )
  )
);
const VSArticleReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSArticleReport" */ "pages/dashboardPages/VS/VSReports/VSArticleReport"
    )
  )
);
const VSfbReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSReports-FacebookReport" */ "pages/dashboardPages/VS/VSReports/VSReports/FacebookReport"
    )
  )
);
const VSPinterestReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "PinterestReport" */ "pages/dashboardPages/VS/VSReports/VSReports/PinterestReport"
    )
  )
);
const VSTwitterReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TwitterReport" */ "pages/dashboardPages/VS/VSReports/VSReports/TwitterReport"
    )
  )
);
const VSTaboolaReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "TaboolaReport" */ "pages/dashboardPages/VS/VSReports/VSReports/TaboolaReport"
    )
  )
);
const VSGoogleReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "GoogleReport" */ "pages/dashboardPages/VS/VSReports/VSReports/GoogleReport"
    )
  )
);
const VSUsersReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSLiveUsersReport" */ "pages/dashboardPages/VS/VSReports/VSLiveUsersReport"
    )
  )
);
const VSArticlesReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "VSLiveArticleReport" */ "pages/dashboardPages/VS/VSReports/VSLiveArticleReport"
    )
  )
);
const VSCountriesReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "CountryReports" */ "pages/dashboardPages/VS/VSReports/VSReports/CountryReports"
    )
  )
);
const VSOperatingSystemsReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "OsReports" */ "pages/dashboardPages/VS/VSReports/VSReports/OsReports"
    )
  )
);
const VSMediumsReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "MediumReports" */ "pages/dashboardPages/VS/VSReports/VSReports/MediumReports"
    )
  )
);
const VSDevicesReport = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "DeviceReports" */ "pages/dashboardPages/VS/VSReports/VSReports/DeviceReports"
    )
  )
);

const ruleSetting = {
  platform: RULE_PLATFORM_FB,
  productId: PRODUCT_ID_VS,
};
const virlSparksRoutes = [
  route("/vs/advertisers", VSAdvertisers, ROUTE_DASHBOARD, true, VS_VIEW_ADVERTISER_PANEL),
  route("/vs/agencies", VSAgencies, ROUTE_DASHBOARD, true, VS_VIEW_AGENCY),
  route("/vs/brands", VSBrands, ROUTE_DASHBOARD, true, VS_VIEW_BRAND),
  route("/vs/users", VSUsers, ROUTE_DASHBOARD, true, VS_VIEW_USER),
  route("/vs/platform", VSPlatform, ROUTE_DASHBOARD, true, VS_VIEW_PLATFORM),
  route("/vs/content", VSContent, ROUTE_DASHBOARD, true, VS_VIEW_CONTENT),
  route("/vs/copilot/rules", () => <AddRule {...ruleSetting} />, ROUTE_DASHBOARD, true),
  route(
    "/vs/copilot/allrules",
    () => <ExistingAdminRule {...ruleSetting} />,
    ROUTE_DASHBOARD,
    true
  ),
  route(
    "/vs/reports/facebook/ad-account/:accID",
    AdAccountReports,
    ROUTE_DASHBOARD,
    false,
    VS_VIEW_REPORTING
  ),
  route("/vs/report/campaign", VSCampaignReport, ROUTE_DASHBOARD, true, VS_VIEW_REPORTING),
  route("/vs/report/country", VSCountryReport, ROUTE_DASHBOARD, true, VS_VIEW_REPORTING),
  route("/vs/report/os", VSOsReport, ROUTE_DASHBOARD, true, VS_VIEW_REPORTING),
  route("/vs/report/device", VSDeviceReport, ROUTE_DASHBOARD, true, VS_VIEW_REPORTING),
  route("/vs/report/medium", VSMediumReport, ROUTE_DASHBOARD, true, VS_VIEW_REPORTING),
  route("/vs/report/user", VSUserReport, ROUTE_DASHBOARD, true, VS_VIEW_PERFORMANCE),
  route("/vs/report/article", VSArticleReport, ROUTE_DASHBOARD, true, VS_VIEW_REPORTING),
  route("/vs/reports/facebook", VSfbReport, ROUTE_DASHBOARD, false, VS_VIEW_REPORTING),
  // route("/vs/reports/facebook-old", VSfbReportOld, ROUTE_DASHBOARD, false, VS_VIEW_REPORTING),
  route("/vs/reports/pinterest", VSPinterestReport, ROUTE_DASHBOARD, false, VS_VIEW_REPORTING),
  route("/vs/reports/twitter", VSTwitterReport, ROUTE_DASHBOARD, false, VS_VIEW_REPORTING),
  route("/vs/reports/taboola", VSTaboolaReport, ROUTE_DASHBOARD, false, VS_VIEW_REPORTING),
  route("/vs/reports/google", VSGoogleReport, ROUTE_DASHBOARD, false, VS_VIEW_REPORTING),

  route("/vs/report/performance/users", VSUsersReport, ROUTE_DASHBOARD, true, VS_VIEW_PERFORMANCE),
  // route("/vs/faq", FAQ, ROUTE_DASHBOARD, true, VS_VIEW_REPORTING),
  route(
    "/vs/report/performance/articles",
    VSArticlesReport,
    ROUTE_DASHBOARD,
    true,
    VS_VIEW_PERFORMANCE
  ),
  route(
    "/vs/report/performance/countries",
    VSCountriesReport,
    ROUTE_DASHBOARD,
    true,
    VS_VIEW_PERFORMANCE
  ),
  route(
    "/vs/report/performance/operating-systems",
    VSOperatingSystemsReport,
    ROUTE_DASHBOARD,
    true,
    VS_VIEW_PERFORMANCE
  ),
  route(
    "/vs/report/performance/mediums",
    VSMediumsReport,
    ROUTE_DASHBOARD,
    true,
    VS_VIEW_PERFORMANCE
  ),
  route(
    "/vs/report/performance/devices",
    VSDevicesReport,
    ROUTE_DASHBOARD,
    true,
    VS_VIEW_PERFORMANCE
  ),

  // route("/vs/reports/pinterest2", VSPinterest2Report, ROUTE_DASHBOARD, false, VS_VIEW_REPORTING),
];

export default virlSparksRoutes;
