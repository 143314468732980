import { Alert, Form } from "antd";
import { useSelector } from "react-redux";
import SelectInput from "../../../forms/SelectInput";
import Loader from "../../../loader";
import { LIST_BIT } from "components/PostHandle/view/PageBits";
import { useEffect } from "react";

export default function DomiansHandle(props) {
  const { domains = [], onChange = () => {}, listType, postType } = props;
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);

  const { CMS_Domains = [], CMS_Domains_loading } = userSettingData || {};

  const options = CMS_Domains?.map(({ id, name }) => ({ name, value: id }));

  const selectProps = {
    multiple: listType === "Product" && postType === LIST_BIT ? false : true,
    search: true,
    value: domains,
    onChange,
    options,
  };
  useEffect(() => {
    if (listType === "Product" && postType === LIST_BIT) {
      if (domains && domains?.length > 1) {
        onChange(domains[0]);
      }
    }
  }, [listType, postType]);

  const loading = CMS_Domains_loading && CMS_Domains?.length === 0;

  return (
    <div className="position-relative">
      {loading && <Loader elementCenter />}
      {!CMS_Domains_loading && CMS_Domains?.length === 0 && (
        <Alert
          message={
            <>
              No Domain is assigned to you,
              <br />
              Please connect to your Admin
            </>
          }
          type="warning"
          showIcon
          className="mb-12"
        />
      )}
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex-grow-1">
            <SelectInput {...selectProps} />
          </div>
        </div>
      </Form>
    </div>
  );
}
