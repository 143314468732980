import { Button, Collapse, Dropdown, Input, Pagination, Segmented, Switch, Tooltip } from "antd";

import { useEffect, useState } from "react";

import ActionIconRender from "components/ActionIconRender";
import moment from "moment";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import {
  GQL_RE_GETUSERACTIVITY,
  GQL_RE_GETUSERACTIVITYBYASSET,
} from "shared/gql/gqlSchema/ruleGql";
import { useSelector } from "react-redux";
import LookTable from "components/LookTable";
import { isNull } from "lodash";
import Loader from "components/loader";
import DateRangePersist from "components/PersistComponents/DateRangePersist";

export default function RuleActivity(props) {
  const { close = () => {}, rule_id, user_id } = props;

  const [data, setdata] = useState([]);
  const [search, setsearch] = useState();
  const [icon, seticon] = useState("up");
  const [selectedindex, setselectedindex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState();
  const [tabledata, setTabledata] = useState(null);
  const [timeZone, setTimeZone] = useState("UTC");
  const defaultDate = [moment().utc().format("YYYY-MM-DD"), moment().utc().format("YYYY-MM-DD")];

  const [rangeDates, setRangeDates] = useState([...defaultDate]);
  const { Panel } = Collapse;
  // const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const [cl_get_user_activity, { loading: loading_cl_getactivity, refetch: cl_acitvity_Refetch }] =
    useCustomLazyQuery(GQL_RE_GETUSERACTIVITY, {
      variables: {
        page: currentPage,
        perPage: pageSize,
      },
      onCompleted: (e) => {
        const element = e?.re_getuserActivity;
        if (element) {
          setdata(element.activity);
          setTotalCount(element.totalDocuments);
        }
      },
      onError(e) {
        setdata([]);
      },
    });
  const [
    cl_get_user_activity_ByAsset,
    { loading: loading_cl_getactivity_ByAsset, refetch: clacitvityRefetchByAsset },
  ] = useCustomLazyQuery(GQL_RE_GETUSERACTIVITYBYASSET, {
    variables: {
      page: currentPage,
      perPage: pageSize,
    },
    onCompleted: (e) => {
      const element = e?.re_getuserActivityByAsset;

      if (element) {
        setdata(element.activity);
        setTotalCount(element.totalDocuments);
      }
    },
    onError(e) {
      setdata([]);
    },
  });

  useEffect(() => {
    cl_get_user_activity({
      variables: { rule_id: rule_id, from: rangeDates[0], to: rangeDates[1], user_id: user_id },
    });
  }, [rangeDates]);

  function handlePersistSearchMain(e) {
    setsearch(e.target.value);
    cl_get_user_activity_ByAsset({ variables: { rule_id: rule_id, asset_id: [e.target.value] } });
  }
  function handlePersistSearch(e) {
    if (e.target.value != "") {
      setsearch(e.target.value);
      cl_get_user_activity_ByAsset({ variables: { rule_id: rule_id, asset_id: [e.target.value] } });
    } else {
      setsearch(e.target.value);
      cl_get_user_activity({
        variables: { rule_id: rule_id, from: rangeDates[0], to: rangeDates[1] },
      });
    }
  }
  function dropdown(data, index) {
    if (index != selectedindex) {
      setTabledata(data);
      setselectedindex(index);
      if (icon === "up") {
        seticon("down");
      }
    } else {
      if (icon === "up") {
        seticon("down");
      } else {
        seticon("up");
        setTabledata(null);
        setselectedindex(null);
      }
    }
  }
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  const initialColumns = [
    {
      title: "Asset name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Asset ID",
      dataIndex: "id",
      key: "id",
      //   width: 160,
      //   minWidth: 160,
      // render: (value, otherProps) => <LookTableColumnRender title={otherProps?.action} />,
    },
  ];
  const tableProps = { initialColumns, recordData: tabledata };
  return (
    <>
      <div className="heading">
        <p className="p-0 mb-10 title">Recent rule activity</p>
      </div>
      <div className="recent-actions-box">
        <div className="d-flex mb-10 justify-content-between">
          <div className="d-flex look-table-search crs-recent-search-input">
            <Input
              style={{ width: "40%" }}
              value={search}
              onChange={(e) => handlePersistSearch(e)}
              onKeyDown={(e) => handlePersistSearchMain(e, "Enter")}
              // onBlur={(e) => handlePersistSearchMain(e, "onBlur")}
              placeholder={"Search by asset id"}
              prefix={<ActionIconRender iconType="search" />}
            />
            <div className="ml-20">
              <DateRangePersist
                noStore={true}
                defaultDate={defaultDate}
                timeZoneProps={timeZone}
                onChange={handleDateRange}
              />
            </div>
          </div>
          <Button onClick={() => cl_acitvity_Refetch()}>
            <ActionIconRender iconType="reload" />
          </Button>

          {/* <Segmented
            className="segment-container"
            value={gby}
            options={["All", "Campaign", "Adset", "Ad"]}
            onChange={(e) => handlegby(e)}
          /> */}
        </div>
        {(loading_cl_getactivity || loading_cl_getactivity_ByAsset) && (
          <Loader center={true} title="loading activities..." />
        )}
        <div className="recent-actions-box">
          <div className="position-relative">
            <div className="recent-action position-relative">
              {data &&
                data.map((item, index) => {
                  const isTableVisible = index === selectedindex;
                  return (
                    <div className="mt-10 recent-action-tag-box">
                      <div className="d-flex  justify-content-between">
                        <div className="d-flex  justify-content-start">
                          <p className="recent-action-title">
                            {item.action === "PAUSED" ||
                            item.action === "INCREASE" ||
                            item.action === "DECREASE" ? (
                              <ActionIconRender
                                iconType="price-list"
                                style={{ color: "#F37370" }}
                              />
                            ) : (
                              <ActionIconRender
                                iconType="file-changes"
                                style={{ color: "#E8D639" }}
                              />
                            )}{" "}
                            {item?.activity && item?.activity?.message}
                          </p>

                          {/* <p
                            style={{ fontSize: "16px", color: "#888888", "white-space": "nowrap" }}
                          >
                            , rule id: {item?.rule_id}
                          </p> */}
                        </div>
                        <p className="date">
                          {moment(item?.created_at).format("YYYY-MM-DD, HH:mm:ss")}
                        </p>
                      </div>

                      {item?.activity?.ids && item.activity.ids?.length > 0 && (
                        <>
                          <div className=" rule-action-tags">
                            <p className="tag" onClick={() => dropdown(item?.activity?.ids, index)}>
                              {item?.activity?.ids?.length} {item?.groupBy}{" "}
                              {icon === "up" ? (
                                <ActionIconRender iconType="up" />
                              ) : (
                                <ActionIconRender iconType="down" />
                              )}
                            </p>
                            {/* <p className="tag">{item?.groupby}</p> */}
                            <p className="tag">{item?.name}</p>
                          </div>
                          <div style={{ display: isTableVisible ? "block" : "none" }}>
                            <LookTable
                              isRowSelection={false}
                              isTableFilter={false}
                              tableProps={tableProps}
                              //   loading={loading_clgetRule} refetch={clruleFetch}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="p-8 h-initial w-initial mu-10 ">
            <Pagination
              defaultCurrent={1}
              total={totalCount}
              pageSize={pageSize}
              current={currentPage}
              onChange={(page, size) => handlePageChange(page, size)}
              pageSizeOptions={["10", "20", "50"]}
              showSizeChanger
            />
          </div>
        </div>
      </div>
    </>
  );
}
