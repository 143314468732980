import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { Tooltip, Switch } from "antd";
import { useSelector } from "react-redux";
import alasql from "alasql";
import { useParams } from "react-router";
import DetailView from "./DetailView";
import {
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  VS_IS_FORECAST_SET,
  VS_IS_FORECAST_UNSET,
} from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { UMS_SUPER_ADMIN } from "shared/UserPermission/userPermissionList";
import SelectInput from "components/forms/SelectInput";
import {
  MULTI_SEARCH_FILTER_TYPE_NUMBER,
  useLookTableFilterData,
} from "components/LookTable/LookTableFilterSearch/functions";
import { useApiToStore } from "hooks";
import {
  API_STORE_VIRALSPARKS_ADVERTISE,
  API_STORE_VIRALSPARKS_ALL_USERS,
  API_STORE_VIRALSPARKS_PLATFORM,
} from "hooks/useApiToStore/keys";
import useVSAdvertiserHandler from "../../../useVSAdvertiserHandler";
import { useDispatch } from "react-redux";
import {
  VS_CAMPAIGN_GROUP_REPORTS,
  VS_CAMPAIGN_LIVE_REPORT,
} from "shared/gql/gqlSchema/viralSparksGql";
import useColumns from "../useColumns";
import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookModal from "components/LookModal";
import {
  GET_F360_CAMPAIGN_GROUPED_REPORT,
  GET_F360_CAMPAIGN_REPORT,
} from "shared/gql/gqlSchema/fb360Gql";
import DuplicateCampaigns from "pages/dashboardPages/SAS/Reports/FacebookReports/DuplicateCampaigns";
import { PRODUCT_ID_VS, REPORT_TYPE_CAMPAIGN, RULE_PLATFORM_FB } from "shared/SharedKeys";
import RuleLinkedItems from "components/LookTable/RuleEngine/RuleLinkedItems";
import NewRuleForm from "components/LookTable/RuleEngine/NewRules";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import {
  ActionsForFacebook,
  useUpdateFB360Status,
} from "components/ReportUtils/ActionsForFacebook";
import UpdateCampaignBudget from "components/ReportUtils/UpdateCampaignBudget";
import { fbGroupForStatus } from "components/ReportUtils/UpdateCampaignBudget/utils";
import { numberParse } from "utils";
import { RETURN_LAUNCHED_ASSETS } from "shared/gql/gqlSchema/launchGql";
import {
  SAS_REPORT_ASSET_TYPE_CAMPAIGN,
  SAS_REPORT_STATUS_TYPE_ACTIVE,
  SAS_REPORT_STATUS_TYPE_PAUSED,
} from "pages/dashboardPages/SAS/sasKeysAndEnum";
import BulkDetailView from "./BulkDetailView";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import { ADBY_REPORT_TYPE_ADACCOUNT } from "hooks/facebookHooks/useFacebookSpendReportsQuery";

export default function Campaigns(props) {
  const { isAdAccountFiltered = false, isBulkAdAccountFiltered = false, bulkProps = [] } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const { selectedRowsClear } = useLookTableParentContext();
  const [spendReport, setSpendReport] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const withUser = params?.userID ? { userId: params?.userID } : {};
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);
  const [selectedUser, setSelectedUser] = useState("all");
  const [campaignForecastReport, setCampaignForecastReport] = useState([]);
  const [campaignMinReport, setCampaignMinReport] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [ruleSetting, setRuleSetting] = useState(null);
  const [visible, setVisible] = useState(false);
  const [mergedReport, setMergedReport] = useState([]);
  const [finalMergedReport, setFinalMergedReport] = useState([]);
  const [isMergedReportFetched, setIsMergedReportFetched] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const { updateFb360Status } = useUpdateFB360Status(REPORT_TYPE_CAMPAIGN, {
    setSpendReport,
  });

  const { vsAdvertiser = [] } = userSettingData || {};
  const [visibleBudgetDrawer, setVisibleBudgetDrawer] = useState(false);
  const [visibleBulkBudgetDrawer, setVisibleBulkBudgetDrawer] = useState(false);
  const [campaignModalVisible, setCampaignModalVisible] = useState(false);

  const showBudgetDrawer = (e) => {
    setRowProps({ details: [e], reportType: REPORT_TYPE_CAMPAIGN, is_bulk: false, refetch });
    setVisibleBudgetDrawer(true);
  };
  const onCloseDrawerBudget = () => {
    setRowProps({});
    setVisibleBudgetDrawer(false);
  };
  const showBudgetBulkDrawer = (e) => {
    setRowProps({ details: e, reportType: REPORT_TYPE_CAMPAIGN, is_bulk: true, refetch });
    setVisibleBulkBudgetDrawer(true);
  };
  const onCloseDrawerBulkBudget = () => {
    setRowProps({});
    setVisibleBulkBudgetDrawer(false);
  };

  const {
    selectedVSAdvertiserIsAll = true,
    selectedVSAdvertiser = "",
    vsIsForecast,
    vs_users = [],
  } = useSelector((state) => state?.vsReducer) || {};

  const isAdvertiser = selectedVSAdvertiserIsAll ? {} : { advertiser_id: selectedVSAdvertiser };
  const isVendor = selectedVSAdvertiserIsAll ? {} : { vendor_id: selectedVSAdvertiser };
  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => (
        <ActionsForFacebook
          reportType={REPORT_TYPE_CAMPAIGN}
          actionProps={actionProps}
          showBudgetDrawer={showBudgetDrawer}
          setSpendReport={setSpendReport}
        />
      ),
      fixed: "right",
      width: 100,
      minWidth: 100,
    },
  ];

  const lastColumns = [
    {
      title: "Budget",
      dataIndex: "daily_budget",
      key: "daily_budget",
      render: (value, actionProps) => {
        const { campaign_id, adset_id, daily_budget, lifetime_budget, campaignId, adsetId } =
          actionProps;

        return (
          <div className="action-column d-flex justify-content-around">
            {(campaign_id || adset_id || campaignId || adsetId) && (
              <div className="actions flex-center">
                <Tooltip
                  title={
                    daily_budget !== 0
                      ? "Daily Budget"
                      : lifetime_budget !== 0
                      ? "Lifetime Budget"
                      : "ABO"
                  }
                >
                  <span>
                    {(daily_budget === 0 || daily_budget === null || daily_budget === undefined) &&
                    (lifetime_budget === 0 ||
                      lifetime_budget === undefined ||
                      lifetime_budget === null) ? (
                      <span className="table-tag">ABO</span>
                    ) : (
                      "$" + (daily_budget || lifetime_budget)
                    )}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
      fixed: "right",
      width: 100,
      minWidth: 100,
      defaultSortOrder: "descend",
      searchType: MULTI_SEARCH_FILTER_TYPE_NUMBER,
      sorter: (a, b) => a?.budgetForSorter - b?.budgetForSorter,
    },
  ];

  function handleForecast(e) {
    dispatch({ type: e ? VS_IS_FORECAST_SET : VS_IS_FORECAST_UNSET });
  }

  const isUser = selectedUser === "all" ? {} : { userId: selectedUser };
  const isWorkspaceAdmin =
    permissions.filter((name) => name === UMS_SUPER_ADMIN).length > 0 ? true : false;

  const { apiCall: vsPlatformsFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_PLATFORM);
  const { apiCall: vsAdvertiserFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ADVERTISE);

  const { apiCall: vsUsersFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ALL_USERS);

  const { handleAdvertiserSelection } = useVSAdvertiserHandler();
  const withAccount = params?.accID ? { adAccountId: params?.accID } : {};
  const { columns, status } = useColumns({
    node: REPORT_TYPE_CAMPAIGN,
    timeZone: dateRangeTimezone,
  });

  useEffect(() => {
    if (isWorkspaceAdmin) {
      vsUsersFetch();
    }
    vsPlatformsFetch();
    vsAdvertiserFetch();
  }, []);

  const spendCommonVariables = {
    start: dateRangeTimezone?.rangeDates[0],
    end: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };

  const revenueCommonVariables = {
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone.value,
  };

  let reportSettings = {
    spendApi: GET_F360_CAMPAIGN_REPORT,
    spendApiKey: "fb360_campaignReporting",
    revenueApi: VS_CAMPAIGN_LIVE_REPORT,
    revenueApiKey: "vs_campaignLiveReportsFromTo",
  };

  if (params?.accID || isBulkAdAccountFiltered) {
    reportSettings = {
      spendApi: GET_F360_CAMPAIGN_GROUPED_REPORT,
      spendApiKey: "fb360_campaignGroupedReporting",
      revenueApi: VS_CAMPAIGN_GROUP_REPORTS,
      revenueApiKey: "vs_campaignGroupReportsFromTo",
      spendExtraVariables: {
        asset_id: isBulkAdAccountFiltered
          ? bulkProps?.map(({ adAccountId }) => adAccountId)
          : [params?.accID],
        asset_type: ADBY_REPORT_TYPE_ADACCOUNT,
      },
      revenueExtraVariables: {
        asset: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: isBulkAdAccountFiltered
          ? bulkProps?.map(({ adAccountId }) => adAccountId)
          : [params?.accID],
      },
    };
  }

  const { loading: loading_GET_REPORT_BY_CAMPAIGN, refetch: refetch_GET_REPORT_BY_CAMPAIGN } =
    useCustomQuery(reportSettings?.spendApi, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[reportSettings?.spendApiKey];
        if (element) {
          setSpendReport(
            element?.map((item) => ({
              ...item,
              spendCampaignId: item?.campaign_id,
              spendAccountId: item?.adaccount_id,
              clicksFB: item?.inline_link_clicks,
              spendFB: item?.spend,
            }))
          );
        }
      },
      variables: {
        ...isVendor,
        ...spendCommonVariables,
        ...reportSettings?.spendExtraVariables,
      },
    });

  const [
    vs_campaignLiveReportsFromTo,
    {
      loading: loading_GET_REVENUE_BY_CAMPAIGN,

      refetch: refetch_GET_REVENUE_BY_CAMPAIGN,
    },
  ] = useCustomLazyQuery(reportSettings?.revenueApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[reportSettings?.revenueApiKey];
      if (element) {
        vsIsForecast
          ? setCampaignForecastReport(
              element?.map((element) => ({ ...element, revCampaignId: element?.campaign_id }))
            )
          : setCampaignMinReport(
              element?.map((element) => ({ ...element, revCampaignId: element?.campaign_id }))
            );
      }
    },
  });

  const [launched_assets, { loading: loading_launched_assets, refetch: refetch_launched_assets }] =
    useCustomLazyQuery(RETURN_LAUNCHED_ASSETS);

  const loading = loading_GET_REPORT_BY_CAMPAIGN || loading_GET_REVENUE_BY_CAMPAIGN;

  function refetch() {
    setCampaignMinReport([]);
    setCampaignForecastReport([]);
    refetch_GET_REPORT_BY_CAMPAIGN();
    refetch_GET_REVENUE_BY_CAMPAIGN();
  }

  useEffect(() => {
    if (!visibleDetailDrawer) {
      if (campaignForecastReport.length === 0 && vsIsForecast) {
        vs_campaignLiveReportsFromTo({
          variables: {
            ...revenueCommonVariables,
            ...reportSettings?.revenueExtraVariables,
            is_forecast: vsIsForecast,
            platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
            ...isAdvertiser,
            ...(isUser || withUser),
          },
        });
      } else if (campaignMinReport.length === 0 && !vsIsForecast) {
        vs_campaignLiveReportsFromTo({
          variables: {
            ...revenueCommonVariables,
            ...reportSettings?.revenueExtraVariables,
            is_forecast: vsIsForecast,
            platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
            ...isAdvertiser,
            ...(isUser || withUser),
          },
        });
      }
      setFirstFetch(false);
    }
  }, [vsIsForecast]);

  useEffect(() => {
    if (!visibleDetailDrawer) {
      if (!firstFetch) {
        setCampaignMinReport([]);
        setCampaignForecastReport([]);
        vs_campaignLiveReportsFromTo({
          variables: {
            ...revenueCommonVariables,
            ...reportSettings?.revenueExtraVariables,
            is_forecast: vsIsForecast,
            platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
            ...isAdvertiser,
            ...(isUser || withUser),
          },
        });
      }
    }
  }, [dateRangeTimezone, selectedUser, selectedVSAdvertiser]);

  const initialColumns = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      render: (value, otherProps) => (
        <LookTableColumnRender isClick={() => showDrawerUpdate(otherProps)} title={value} />
      ),
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: "Campaign Id",
      dataIndex: "campaignId",
      key: "campaignId",
      render: (value, otherProps) => (
        <LookTableColumnRender isClick={() => showDrawerUpdate(otherProps)} title={value} />
      ),
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    {
      title: "LF Launched",
      dataIndex: "isLfLaunched",
      key: "isLfLaunched",
      render: (value, other) => {
        return (
          <LookTableColumnRender
            className={value?.status ? "text-primary" : "text-danger"}
            title={value?.status ? "Yes" : "No"}
          />
        );
      },
      width: 90,
      minWidth: 90,
      searchType: "string",
    },
    ...columns,
    ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];

  useEffect(() => {
    if (!loading) {
      alasql(
        "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.campaign_id = b.campaign_id",
        [vsIsForecast ? campaignForecastReport : campaignMinReport, spendReport],
        function (fbResponse) {
          setMergedReport(fbResponse);
        }
      );
    }
  }, [loading, vsIsForecast, campaignForecastReport, spendReport]);

  useEffect(() => {
    if (mergedReport.length > 0) {
      if (!isMergedReportFetched) {
        launched_assets({
          variables: {
            assetType: SAS_REPORT_ASSET_TYPE_CAMPAIGN,
            ids: mergedReport
              ?.filter(({ campaign_id }) => campaign_id ?? false)
              ?.map((data) => data?.campaign_id),
          },
          onCompleted: (e) => {
            const launchedAssetsResponse = e?.returnLaunchedAssets;
            setIsMergedReportFetched(true);
            setFinalMergedReport(
              mergedReport?.map((item) => {
                let assets = {};
                if (launchedAssetsResponse?.length > 0) {
                  const currentIndex = launchedAssetsResponse?.findIndex(
                    ({ id }) => id === item?.campaign_id
                  );
                  if (currentIndex !== -1) {
                    assets = launchedAssetsResponse[currentIndex];
                  }
                }
                return {
                  ...item,
                  isLfLaunched: assets,
                };
              })
            );
          },
          onError: (e) => {
            setFinalMergedReport(mergedReport);
          },
        });
      } else {
        setFinalMergedReport((prev) => {
          const updatedData = [...prev];
          const temp = updatedData?.map((item) => {
            const tempData = mergedReport?.find((it) => it?.campaign_id === item?.campaign_id);
            return { ...tempData, isLfLaunched: item?.isLfLaunched };
          });
          return temp;
        });
      }
    }
  }, [mergedReport]);

  // some more conditions may here in future for comment service
  finalMerge = finalMergedReport;

  if (!loading) {
    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        impressions = 0 | "0",
        result = 0,
        user_revenue = 0,
        sessions = 0 | "0",
        pageviews = 0 | "0",
        status = null,
        campaign_name = "",
        spendCampaignId,
        revCampaignId,
        daily_budget,
      } = element;

      const spendUpdate = spendFB?.toFixed(2);
      // const revenueUpdate = (TotalRevenue * 0.94).toFixed(2);

      const revenueUpdate = user_revenue;
      const budgetForSorter =
        daily_budget === null || daily_budget === undefined ? -1 : daily_budget;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(revenueUpdate) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(result) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        key: index,
        campaignName: campaign_name || "",
        campaignId: spendCampaignId || revCampaignId,
        TotalRevenue: numberParse(revenueUpdate),
        clicksFB,
        result,
        impressionsFB,
        budgetForSorter,
        spendFB: spendUpdate,
        PROFIT: numberParse(PROFIT),
        PROFIT_VIEW: numberParse(PROFIT, { toFixed: true }),
        CPC: numberParse(CPC),
        CPC_VIEW: numberParse(CPC, { toFixed: true, toFixedUpTo: 3 }),
        CPC_FB: numberParse(CPC_FB),
        CPC_FB_VIEW: numberParse(CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
        ROI: numberParse(ROI),
        ROI_VIEW: numberParse(ROI, { toFixed: true }),
        EPC: numberParse(EPC),
        EPC_VIEW: numberParse(EPC, { toFixed: true, toFixedUpTo: 3 }),
        CTR: numberParse(CTR),
        CTR_VIEW: numberParse(CTR, { toFixed: true }),
        DISC: numberParse(DISC),
        DISC_VIEW: numberParse(DISC, { toFixed: true }),
        IPP: numberParse(IPP),
        RPM: numberParse(RPM),
        PRPM: numberParse(PRPM),
        PRPM_VIEW: numberParse(PRPM, { toFixed: true }),
        CPR: numberParse(CPR),
        CPR_VIEW: numberParse(CPR, { toFixed: true }),
        PPS: numberParse(PPS),
        PPS_VIEW: numberParse(PPS, { toFixed: true }),
        CPM: numberParse(CPM),
        CPM_VIEW: numberParse(CPM, { toFixed: true }),
        status,
      });
    });
  }

  const finalFilterDataWithNameFilter = filterData(finalMergeData).filter(
    ({ campaignName = "", campaignId = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";

      const searchCheck =
        campaignName?.toLowerCase()?.includes(searchValue) ||
        campaignId?.toLowerCase()?.includes(searchValue);

      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }
      return false;
    }
  );

  const finalFilterData =
    selectedStatus === SAS_REPORT_STATUS_TYPE_ACTIVE
      ? finalFilterDataWithNameFilter.filter(
          ({ status }) => status !== SAS_REPORT_STATUS_TYPE_PAUSED && status != undefined
        )
      : selectedStatus === SAS_REPORT_STATUS_TYPE_PAUSED
      ? finalFilterDataWithNameFilter.filter(
          ({ status }) => status === SAS_REPORT_STATUS_TYPE_PAUSED && status != undefined
        )
      : finalFilterDataWithNameFilter;

  let total_spendFB = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_result = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    result = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageviews = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicksFB += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_result += parseFloat(result || 0);
    total_impressions += parseFloat(impressions || 0);
    total_TotalRevenue += parseFloat(TotalRevenue || 0);
    total_sessions += parseFloat(sessions || 0);
    total_pageViews += parseFloat(pageviews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicksFB;
  const total_ROI = (total_TotalRevenue / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicksFB / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_result;
  const total_DISC = total_sessions / total_clicksFB;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setVisibleDetailDrawer(false);
  };

  const modalUpdateBudgetPropsUpdate = {
    title: "Update Campaign Budget",
    okokText: "Update Budget",
    visible: visibleBudgetDrawer,
    onCancel: onCloseDrawerBudget,
    footer: false,
  };

  const modalUpdateBulkBudgetPropsUpdate = {
    title: "Update Campaign Budget",
    visible: visibleBulkBudgetDrawer,
    onCancel: onCloseDrawerBulkBudget,
    footer: false,
  };
  const drawerPropsUpdate = {
    title: "Ad Account",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const summaryData = {
    TotalRevenue: numberParse(total_TotalRevenue, { toFixed: true }),
    spendFB: numberParse(total_spendFB, { toFixed: true }),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: numberParse(total_ROI, { toFixed: true }),
    EPC: numberParse(total_EPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC: numberParse(total_CPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC_FB: numberParse(total_CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    result: numberParse(total_result),
    CPR: numberParse(total_CPR, { toFixed: true }),
    sessions: numberParse(total_sessions),
    clicksFB: numberParse(total_clicksFB),
    DISC: numberParse(total_DISC, { toFixed: true }),
    IPP: numberParse(total_IPP, { toFixed: true }),
    RPM: numberParse(total_RPM, { toFixed: true }),
    PRPM: numberParse(total_PRPM, { toFixed: true }),
    PPS: numberParse(total_PPS, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
  };

  const rightOptions = (
    <>
      {/* <div className="mr-12">
        <Tooltip
          title={`${vsIsForecast ? "Forecast" : "Min"} Revenue - Switch to ${
            vsIsForecast ? "Min" : "Forecast"
          }`}
        >
          <Switch checked={vsIsForecast} onChange={handleForecast} />
        </Tooltip>
      </div> */}

      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Status">
          <span>
            <SelectInput
              search
              name="status"
              className="mb-0"
              placeholder="Select status"
              value={selectedStatus}
              options={[
                { name: "All-Status", value: "all" },
                { name: "Active", value: "ACTIVE" },
                { name: "Paused", value: "PAUSED" },
              ]}
              onChange={setSelectedStatus}
            />
          </span>
        </Tooltip>
      </div>

      {isWorkspaceAdmin && (
        <div className="mr-12" style={{ width: 150 }}>
          <Tooltip title="User">
            <span>
              <SelectInput
                search
                name="selectedUser"
                className="mb-0"
                placeholder="Select User"
                value={selectedUser}
                options={[
                  { name: "All", value: "all" },
                  ...vs_users?.map(({ id, name }) => ({ name, value: id })),
                ]}
                onChange={setSelectedUser}
              />
            </span>
          </Tooltip>
        </div>
      )}

      <div style={{ width: 150 }}>
        <Tooltip title="Advertiser">
          <span>
            <SelectInput
              search
              name="selectedAdvertiser"
              className="mb-0"
              placeholder="Select Advertiser"
              value={selectedVSAdvertiserIsAll ? "all" : selectedVSAdvertiser}
              options={[
                { name: "All", value: "all" },
                ...vsAdvertiser?.map(({ id, name }) => ({ name, value: id })),
              ]}
              onChange={(e) => handleAdvertiserSelection(e, true)}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );

  const [rowBulkPropsData, setRowBulkPropsData] = useState();
  const [visibleBulkDetailDrawer, setVisibleBulkDetailDrawer] = useState();

  const showBulkDetailDrawer = (rowProps = []) => {
    setRowBulkPropsData(rowProps);
    selectedRowsClear();
    setVisibleBulkDetailDrawer(true);
  };

  const onCloseBulkDetailDrawer = () => {
    setRowBulkPropsData([]);
    setVisibleBulkDetailDrawer(false);
  };

  const bulkDetailViewProps = {
    title: "Campaign",
    visible: visibleBulkDetailDrawer,
    onClose: onCloseBulkDetailDrawer,
    size: 1000,
  };

  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_CAMPAIGN);
        updateFb360Status("ACTIVE", groupedIds);
      },
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_CAMPAIGN);
        updateFb360Status("PAUSED", groupedIds);
      },
    },

    {
      option: "dollar",
      title: "Budget Update",
      onClick: (e) => showBudgetBulkDrawer(e, "facebook-postback-campaign"),
    },
    {
      option: "duplicate",
      title: "Duplicate Campaign",
      onClick: (e) => showCampaignModal(e),
    },
    {
      option: "rules",
      title: "Rule-Engine",
      onClick: (e) => {
        const assetIds = e?.map((item) => {
          return { id: item.campaign_id, name: item.campaignName };
        });

        setRuleSetting({
          report_type: "general",
          groupBy: REPORT_TYPE_CAMPAIGN,
          platform: RULE_PLATFORM_FB,
          product: PRODUCT_ID_VS,
          data: assetIds,
        });
        setVisible(true);
      },
    },
    {
      option: "unordered-list",
      title: "Detailed report",
      onClick: (e) => {
        // e - contains selected items' array
        showBulkDetailDrawer(e);
      },
    },
  ];

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },
    tableProps: { initialColumns, lastColumns, recordData: finalFilterData, summaryData },
    loading: loading || loading_launched_assets,
    refetch,
    defaultPagination: true,
    rightOptions,
    status,
    bulkActions,
  };

  const debugContent = [
    // { isRow: true, contentObjects: [{ finalFilterData }] },
    {
      isRow: true,
      contentObjects: [
        { vsCampaign: vsIsForecast ? campaignForecastReport : campaignMinReport },
        { spendReport },
        { mergedReport },
        { finalFilterData },
      ],
    },
  ];

  const showCampaignModal = (rowData = []) => {
    setCampaignModalVisible(true);
    setRowProps(rowData?.[0]);
  };

  const onCloseCampaignModal = () => {
    setCampaignModalVisible(false);
  };

  const campaignModalProps = {
    visible: campaignModalVisible,
    onCancel: onCloseCampaignModal,
    title: "Duplicate Campaign",
    okokText: "Create",
    footer: false,
    width: 1000,
  };
  function onClose() {
    setRuleSetting(null);
    setVisible(false);
  }
  const ruledraweProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 800,
    visible,
    onClose,
  };
  return (
    <div>
      <DetailView {...drawerPropsUpdate} {...rowPropsData} />
      <BulkDetailView {...bulkDetailViewProps} bulkProps={rowBulkPropsData} />
      {visibleBudgetDrawer && (
        <LookModal {...modalUpdateBudgetPropsUpdate}>
          <UpdateCampaignBudget {...rowPropsData} refetch={refetch} close={onCloseDrawerBudget} />
        </LookModal>
      )}

      {visibleBulkBudgetDrawer && (
        <LookModal {...modalUpdateBulkBudgetPropsUpdate}>
          <UpdateCampaignBudget
            {...rowPropsData}
            refetch={refetch}
            close={onCloseDrawerBulkBudget}
          />
        </LookModal>
      )}
      <LookModal {...campaignModalProps}>
        {campaignModalVisible && (
          <DuplicateCampaigns
            {...rowPropsData}
            onCloseCampaignModal={onCloseCampaignModal}
            reportType={REPORT_TYPE_CAMPAIGN}
            productId={PRODUCT_ID_VS}
          />
        )}
      </LookModal>
      {ruleSetting && (
        <LookDrawer {...ruledraweProps}>
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Add/Update",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: (
                  <NewRuleForm
                    close={() => onClose()}
                    // {...props}
                    productId={ruleSetting?.product}
                    platform={ruleSetting?.platform}
                    grpby={ruleSetting?.groupBy}
                    report_type={ruleSetting?.report_type}
                    idtype={"inc"}
                    ids={ruleSetting?.data?.map(({ id }) => id)}
                    // copy={update}
                  />
                ),
              },
              {
                hoverTitle: "Rule Linked Items",
                icon: () => <ActionIconRender iconType="ordered-list" />,
                component: <RuleLinkedItems close={() => onClose()} ids={ruleSetting?.data} />,
              },
            ]}
          />
        </LookDrawer>
      )}
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
