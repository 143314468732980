import { Form } from "antd";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { openNotification } from "utils";
import { MOODBOARD_TYPE_LANGUAGE } from "../../creativesEnum";
import Loader from "components/loader";

function ScriptTab(props) {
  const {
    type,
    query,
    values,
    apiType,
    id,
    name,
    productId,
    paramstype,
    label = "",
    setValues = () => {},
    isRequestForm,
    isCreateForm,
  } = props;
  const [tabData, setTabData] = useState([]);
  const { umsLanguagesFetch = () => {} } = useApiToStoreContext();
  const { userSettingData } = useSelector((state) => state?.userSettings) || {};
  const { umsLanguages } = userSettingData || {};
  const [moodBoardDataFetch, { loading: data_loading, refetch: data_refetch }] = useCustomLazyQuery(
    query,
    {
      onCompleted: (e) => {
        setTabData(e?.[apiType]?.data);
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  useEffect(() => {
    if (!isRequestForm) {
      if (type !== MOODBOARD_TYPE_LANGUAGE) {
        moodBoardDataFetch({
          variables: {
            categoryId: id,
            productId,
            page: 1,
            size: 100,
          },
        });
      } else {
        umsLanguagesFetch();
      }
    }
  }, []);
  const finalData =
    type === MOODBOARD_TYPE_LANGUAGE
      ? umsLanguages?.map((data) => ({ id: data?.code, name: data?.name }))
      : tabData;
  const dataList = values?.[paramstype];
  return (
    <div className="select_script">
      <Form layout="vertical">
        {(values?.isEdited || isCreateForm) && (
          <SelectInput
            placeHolder="Select values"
            multiple={type !== MOODBOARD_TYPE_LANGUAGE}
            label={label}
            value={values?.paramstype}
            loading={data_loading}
            options={finalData?.map((data) => ({
              name: data?.name,
              value: data?.id,
            }))}
            onChange={(e) => {
              setValues((prevItems) => ({
                ...prevItems,
                [paramstype]: type !== MOODBOARD_TYPE_LANGUAGE ? e : [e],
              }));
            }}
          />
        )}
      </Form>
      <div>
        {data_loading ? (
          <Loader elementCenter />
        ) : (
          <>
            {dataList?.length < 1 || dataList === undefined ? (
              <div className="text-center">
                <ActionIconRender iconType="tablePlaceholder" />
              </div>
            ) : (
              dataList?.map((data) => (
                <div className="scriptsSelectedData">
                  <div style={{ width: "70%" }}>
                    {isRequestForm && type !== MOODBOARD_TYPE_LANGUAGE
                      ? data?.name
                      : finalData?.find((tabsData) => data === tabsData?.id)?.name}
                  </div>
                  {(values?.isEdited || isCreateForm) && (
                    <ActionIconRender
                      iconType="close"
                      onClick={() =>
                        setValues((prevItems) => ({
                          ...prevItems,
                          [paramstype]: prevItems?.[paramstype]?.filter(
                            (paramsData) => data !== paramsData
                          ),
                        }))
                      }
                    />
                  )}
                </div>
              ))
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ScriptTab;
