import { TASK_SERVICE_PRIORITY, TASK_SERVICE_STATUS } from "shared/gql/gqlSchema/iTaskGql";
import {
  API_STORE_I_TASK_SERVICE_PRIORITY,
  API_STORE_I_TASK_SERVICE_STATUS,
  API_STORE_I_TASK_SERVICE_STATUS_ROCK,
} from "./keys";

export default function useITaskApis(typeKey) {
  switch (typeKey) {
    case API_STORE_I_TASK_SERVICE_PRIORITY:
      return {
        api: TASK_SERVICE_PRIORITY,
        elementKey: "taskServicePriority",
        storeKey: "taskServicePriority",
      };

    case API_STORE_I_TASK_SERVICE_STATUS:
      return {
        api: TASK_SERVICE_STATUS,
        elementKey: "taskServiceStatus",
        storeKey: "taskServiceStatus",
      };

    case API_STORE_I_TASK_SERVICE_STATUS_ROCK:
      return {
        api: TASK_SERVICE_STATUS,
        elementKey: "taskServiceStatus",
        storeKey: "rockstaskServiceStatus",
        variables: { type: "ROCK" },
      };

    default:
      return false;
  }
}
