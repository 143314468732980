import { DatePicker } from "antd";
import moment from "moment";

export default function PublishDateHandle(props) {
  const { publishDate, onChange } = props;
  const dateFormat = "YYYY-MM-DD";

  function dateHandle(date, dateString) {
    onChange(dateString);
  }
  return (
    <div>
      <DatePicker
        value={publishDate ? moment(publishDate, dateFormat) : null}
        defaultValue={moment(publishDate, dateFormat)}
        format={dateFormat}
        onChange={dateHandle}
      />
    </div>
  );
}
