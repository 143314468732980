import { TwitterOutlined } from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";
import { USERS_MANAGES_USERS } from "../../UserPermission/userPermissionList";

const twitterPath = [
  {
    name: "Twitter",
    link: "/twitter",
    icon: TwitterOutlined,
    subMenu: [
      {
        subMenuName: "Users",
        subMenuLink: "/users",
        subMenuIcon: () => <ActionIconRender iconType="user" />,
        permission: USERS_MANAGES_USERS,
      },
      {
        subMenuName: "Ad Accounts",
        subMenuLink: "/ad-accounts",
        subMenuIcon: () => <ActionIconRender iconType="team" />,
        permission: USERS_MANAGES_USERS,
      },
      {
        subMenuName: "Campaigns",
        subMenuLink: "/campaigns",
        subMenuIcon: () => <ActionIconRender iconType="notification" />,
        permission: USERS_MANAGES_USERS,
      },
      {
        subMenuName: "Funding Sources",
        subMenuLink: "/funding-sources",
        subMenuIcon: () => <ActionIconRender iconType="source" />,
        permission: USERS_MANAGES_USERS,
      },
      {
        subMenuName: "Adsets",
        subMenuLink: "/adsets",
        subMenuIcon: () => <ActionIconRender iconType="adIcon" />,
        permission: USERS_MANAGES_USERS,
      },
    ],
  },
];

export default twitterPath;
