import { Link } from "react-router-dom";
import cx from "classnames";
import useTheme from "shared/Theme/useTheme";
import ActionIconRender from "components/ActionIconRender";

function PrivacyTermsCall(props) {
  const themeLight = useTheme();
  const { className = "", style = {} } = props;

  return (
    <div className={cx("vertical-bottom", className)} style={style}>
      <div className="flex-between">
        <p className={themeLight ? "second-clr" : "second-dark-clr"}>
          <Link
            to="/privacy-policy"
            className={cx("text-line", themeLight ? "second-clr" : "second-dark-clr")}
          >
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link
            to="/terms-Of-service"
            className={cx("text-line", themeLight ? "second-clr" : "second-dark-clr")}
          >
            Terms of Service
          </Link>
        </p>
        <p className="text-primary">
          <ActionIconRender iconType="question-filled" style={{ marginRight: "5px" }} />
          Help
        </p>
      </div>
    </div>
  );
}

export default PrivacyTermsCall;
