import { gql } from "@apollo/client";

const ADLIB_COMPETITORS_RESPONSE = gql`
  fragment adlib_competitor on adlib_competitor {
    id
    name
  }
`;

export const ADLIB_COMPETITORS_GET_ALL = gql`
  ${ADLIB_COMPETITORS_RESPONSE}
  query adlib_competitors_get_all {
    adlib_competitors_get_all {
      ...adlib_competitor
    }
  }
`;

export const ADLIB_COMPETITORS_CREATE = gql`
  ${ADLIB_COMPETITORS_RESPONSE}
  mutation adlib_competitor_create($name: String!) {
    adlib_competitor_create(name: $name) {
      ...adlib_competitor
    }
  }
`;

export const ADLIB_COMPETITORS_UPDATE = gql`
  ${ADLIB_COMPETITORS_RESPONSE}
  mutation adlib_competitor_update($id: ID!, $name: String!) {
    adlib_competitor_update(id: $id, name: $name) {
      ...adlib_competitor
    }
  }
`;

export const ADLIB_COMPETITORS_DELETE = gql`
  mutation adlib_competitor_remove($id: ID!) {
    adlib_competitor_remove(id: $id)
  }
`;

const ADLIB_KEYWORD_RESPONSE = gql`
  fragment adlib_keyword on adlib_keyword {
    id
    keyword
    counter
    country_id
    category_id
    sas_keyword_id
    keyword
    counter
    vertical_id
    country {
      id
      name
    }
    category {
      category_id
      name
    }
    first_seen
    last_seen
  }
`;

export const ADLIB_KEYWORD_GET_ALL = gql`
  ${ADLIB_KEYWORD_RESPONSE}
  query adlib_keyword_get_all(
    $column: String
    $country_id: ID
    $domain_id: ID
    $order: order
    $page: Int
    $page_id: ID
    $search: String
    $size: Int
  ) {
    adlib_keyword_get_all(
      column: $column
      country_id: $country_id
      domain_id: $domain_id
      order: $order
      page: $page
      page_id: $page_id
      search: $search
      size: $size
    ) {
      data {
        ...adlib_keyword
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;
const ADLIB_DOMAIN_RESPONSE = gql`
  fragment adlib_domain on adlib_domain {
    country_position
    country_query_name
    country_separator
    country_type
    id
    keyword_position
    keyword_separator
    keyword_type
    product_id
    category_id
    sas_feed_id
    sas_vendor_id
    url
    cards {
      link_url
    }
  }
`;

export const ADLIB_DOMAIN_GET_ALL = gql`
  ${ADLIB_DOMAIN_RESPONSE}
  query adlib_domain_get_all(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $keyword_id: ID
  ) {
    adlib_domain_get_all(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      keyword_id: $keyword_id
    ) {
      data {
        ...adlib_domain
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const ADLIB_DOMAIN_DROPDOWN = gql`
  query adlib_domain_dropdown {
    adlib_domain_dropdown {
      id
      url
    }
  }
`;

const ADLIB_PAGE_RESPONSE = gql`
  fragment adlib_page on adlib_page {
    id
    name
    page_fb_id
    page_url
    profile_picture
    status
    competitor {
      id
      name
    }
    country_id
    country {
      name
    }
  }
`;

export const ADLIB_PAGE_GET_ALL = gql`
  ${ADLIB_PAGE_RESPONSE}
  query adlib_page_get_all(
    $column: String
    $domain_id: ID
    $keyword_id: ID
    $landing_page_id: ID
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    adlib_page_get_all(
      column: $column
      domain_id: $domain_id
      keyword_id: $keyword_id
      landing_page_id: $landing_page_id
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        ...adlib_page
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;
// adlib_page_resync(
//   competitor_id: ID
//   country_id: ID
//   id: ID
//   name: String
//   no_of_ads: Int
//   page_fb_id: ID
//   page_url: String
//   product_id: ID
//   profile_picture: String
//   status: ADLIB_PAGE_STATUS
//   ): Boolean

export const ADLIB_PAGE_SYNC = gql`
  mutation adlib_page_resync($id: ID, $no_of_ads: Int) {
    adlib_page_resync(id: $id, no_of_ads: $no_of_ads)
  }
`;

export const ADLIB_DOMAIN_SYNC = gql`
  mutation adlib_domain_resync($country_id: ID, $domain_id: ID, $no_of_ads: Int) {
    adlib_domain_resync(country_id: $country_id, domain_id: $domain_id, no_of_ads: $no_of_ads)
  }
`;

export const ADLIB_KEYWORD_SYNC = gql`
  query adlib_keyword_resync {
    adlib_keyword_resync
  }
`;
export const ADLIB_DOMAINS_SYNC = gql`
  mutation adlib_extension_keyword($ids: [ID]) {
    adlib_extension_keyword(ids: $ids)
  }
`;

export const ADLIB_PAGE_DROPDOWN = gql`
  query adlib_page_dropdown {
    adlib_page_dropdown {
      id
      name
    }
  }
`;

export const ADLIB_PAGE_CREATE = gql`
  mutation adlib_page_create(
    $competitor_id: ID
    $country_id: ID!
    $no_of_ads: Int!
    $page_fb_id: ID!
  ) {
    adlib_page_create(
      competitor_id: $competitor_id
      country_id: $country_id
      no_of_ads: $no_of_ads
      page_fb_id: $page_fb_id
    )
  }
`;

export const ADLIB_PAGE_DELETE = gql`
  mutation adlib_page_delete($id: [ID]!) {
    adlib_page_delete(id: $id)
  }
`;

export const ADLIB_PAGE_UPDATE = gql`
  ${ADLIB_PAGE_RESPONSE}
  mutation adlib_page_update($id: ID!, $page_fb_id: ID!, $country_id: ID) {
    adlib_page_update(id: $id, page_fb_id: $page_fb_id, country_id: $country_id) {
      ...adlib_page
    }
  }
`;

export const ADLIB_DOMAIN_CREATE = gql`
  mutation adlib_domain_create($country_id: Int!, $domain_search: String!, $no_of_ads: Int!) {
    adlib_domain_create(
      country_id: $country_id
      domain_search: $domain_search
      no_of_ads: $no_of_ads
    )
  }
`;

export const ADLIB_DOMAIN_UPDATE = gql`
  ${ADLIB_DOMAIN_RESPONSE}
  mutation adlib_domain_update(
    $country_position: String
    $country_query_name: String
    $country_separator: String
    $country_type: ADLIB_DOMAIN_COUNTRY_TYPE
    $id: ID!
    $keyword_position: String
    $keyword_separator: String
    $keyword_type: ADLIB_DOMAIN_KEYWORD_TYPE
    $product_id: ID
    $category_id: ID
    $sas_feed_id: ID
    $sas_vendor_id: ID
  ) {
    adlib_domain_update(
      country_position: $country_position
      country_query_name: $country_query_name
      country_separator: $country_separator
      country_type: $country_type
      id: $id
      keyword_position: $keyword_position
      keyword_separator: $keyword_separator
      keyword_type: $keyword_type
      product_id: $product_id
      category_id: $category_id
      sas_feed_id: $sas_feed_id
      sas_vendor_id: $sas_vendor_id
    ) {
      ...adlib_domain
    }
  }
`;

// adlib_id: String
// body: String
// country_id: Int
// description: String
// id: ID!
// image_url: String
// keyword: String
// lander_id: Int
// link_url: String
// page: adlib_page
// page_id: String
// sas_export_status: Boolean
// title: String
// type: ADLIB_CARD_TYPE
// video_thumb_url: String
// video_url: String

const ADLIB_CARD_RESPONSE = gql`
  fragment adlib_card on adlib_card {
    adlib_id
    body
    country_id
    description
    id

    # lander_id
    link_url
    # page {
    #   id
    #   name
    # }
    # page_id
    sas_export_status
    title
    card_images_videos {
      card_id
      id
      image_url
      type
      video_thumb_url
      video_url
    }
    page {
      name
      profile_picture
    }
  }
`;

export const ADLIB_CARD_BY_KEYWORD = gql`
  ${ADLIB_CARD_RESPONSE}
  query adlib_card_by_keyword(
    $column: String
    $keyword_id: ID
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    adlib_card_by_keyword(
      column: $column
      keyword_id: $keyword_id
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...adlib_card
      }
    }
  }
`;

export const ADLIB_CARD_GET_ALL = gql`
  ${ADLIB_CARD_RESPONSE}
  query adlib_card_get_all(
    $column: String
    $domain_id: ID
    $keyword_id: ID
    $page: Int
    $page_id: ID
    $search: String
    $size: Int
  ) {
    adlib_card_get_all(
      column: $column
      domain_id: $domain_id
      keyword_id: $keyword_id
      page: $page
      page_id: $page_id
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...adlib_card
      }
    }
  }
`;

export const ADLIB_ASSIGN_CATEGORY_TO_KEYWORDS = gql`
  mutation adlib_keyword_category_assign($category_id: ID, $category_name: String, $id: [ID]!) {
    adlib_keyword_category_assign(category_id: $category_id, category_name: $category_name, id: $id)
  }
`;

export const ADLIB_GET_ALL_COUNTRIES = gql`
  query adlib_country_get_all {
    adlib_country_get_all {
      id
      iso_code
      name
    }
  }
`;

const ADLIB_LANDING_PAGE_RESPONSE = gql`
  fragment adlib_landing_page on adlib_landing_page {
    id
    #  category_id
    counter
    #  domain_id
    lander_url
    latest_sync_counter
  }
`;

export const ADLIB_LANDING_PAGES = gql`
  ${ADLIB_LANDING_PAGE_RESPONSE}
  query adlib_landing_page_get_all(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $from: Date
    $to: Date
    $timezone: String
  ) {
    adlib_landing_page_get_all(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      from: $from
      to: $to
      timezone: $timezone
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...adlib_landing_page
      }
    }
  }
`;

export const ADLIB_LANDING_PAGE_CARDS = gql`
  ${ADLIB_CARD_RESPONSE}
  query adlib_card_by_landing_page($landing_page_id: [ID]) {
    adlib_card_by_landing_page(landing_page_id: $landing_page_id) {
      ...adlib_card
    }
  }
`;

export const ADLIB_KEYWORD_FETCH_BY_PAGE_DOMAIN = gql`
  query adlib_keyword_fetch_by_PageDomain($domain_id: ID, $page_id: ID) {
    adlib_keyword_fetch_by_PageDomain(domain_id: $domain_id, page_id: $page_id)
  }
`;

export const ADLIB_LANDING_PAGE_VS_CATEGORY_ASSIGN = gql`
  mutation adlib_landing_page_vs_category_assign($params: [landing_page_vs_category]) {
    adlib_landing_page_vs_category_assign(params: $params)
  }
`;
