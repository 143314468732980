import {
  UserOutlined,
  TeamOutlined,
  CalendarOutlined,
  ApartmentOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { CustomeIconWrapper } from "..";
import { HRIcon } from "../../../components/svgComponents";
import {
  HRMS_PRODUCT_ADMIN,
  HRMS_USER,
  HRMS_VIEW_EVENT,
} from "../../UserPermission/userPermissionList";

const hrmsPath = [
  {
    name: "HRMS",
    link: "/hrms",
    icon: () => (
      <CustomeIconWrapper>
        <HRIcon />
      </CustomeIconWrapper>
    ),
    subMenu: [
      {
        subMenuName: "Dashboard",
        subMenuLink: "/my-dashboard",
        subMenuIcon: TeamOutlined,
        permission: HRMS_USER,
      },
      {
        subMenuName: "Employees",
        subMenuLink: "/employees",
        subMenuIcon: UserOutlined,
        permission: HRMS_PRODUCT_ADMIN,
      },
      {
        subMenuName: "Exited Employees",
        subMenuLink: "/exit-employees",
        subMenuIcon: UsergroupDeleteOutlined,
        permission: HRMS_PRODUCT_ADMIN,
      },
      {
        subMenuName: "Departments",
        subMenuLink: "/departments",
        subMenuIcon: ApartmentOutlined,
        permission: HRMS_PRODUCT_ADMIN,
      },
      {
        subMenuName: "Attendance",
        subMenuLink: "/attendance",
        subMenuIcon: CalendarOutlined,
        permission: HRMS_PRODUCT_ADMIN,
      },
      {
        subMenuName: "Leaves",
        subMenuLink: "/leaves",
        subMenuIcon: TeamOutlined,
        permission: HRMS_PRODUCT_ADMIN,
      },
      {
        subMenuName: "Events",
        subMenuLink: "/events",
        subMenuIcon: TeamOutlined,
        permission: HRMS_VIEW_EVENT,
      },
    ],
  },
];

export default hrmsPath;
