import { useCustomMutation } from "hooks/apolloClientHooks";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { GQL_TIKTOK_UPDATE_STATUS } from "shared/gql/gqlSchema/tiktok";
import { openNotification } from "utils";

export const TIKTOK_UPDATE_STATUS_TYPE_ACTIVE = "ENABLE";
export const TIKTOK_UPDATE_STATUS_TYPE_DISABLE = "DISABLE";

export const TIKTOK_UPDATE_STATUS_NODE_CAMPAIGN = "CAMPAIGN";
export const TIKTOK_UPDATE_STATUS_NODE_ADGROUP = "ADGROUP";
export const TIKTOK_UPDATE_STATUS_NODE_AD = "AD";

export default function useTiktokActivePauseStatusUpdateHooks(
  node,
  {
    statusCallBack = () => {},
    setSpendReport = () => {},
    // lengthCheck = 15
  }
) {
  const { selectedRowsClear } = useLookTableParentContext();

  function updateReport(e) {
    setSpendReport((pre) => {
      const temp = JSON.parse(JSON.stringify(pre)).map((tData) => {
        const newDataIndex = e?.findIndex(({ id }) => {
          if (node === TIKTOK_UPDATE_STATUS_NODE_CAMPAIGN) {
            return id === tData?.campaignId;
          } else if (node === TIKTOK_UPDATE_STATUS_NODE_ADGROUP) {
            return id === tData?.adsetId;
          } else if (node === TIKTOK_UPDATE_STATUS_NODE_AD) {
            return id === tData?.adId;
          }
          return false;
        });

        const isNewStatus = newDataIndex !== -1 ? { status: e[newDataIndex]?.status } : {};

        return { ...tData, ...isNewStatus };
      });

      return temp;
    });
  }

  const [tiktok_updateStatus, { loading }] = useCustomMutation(GQL_TIKTOK_UPDATE_STATUS, {
    onCompleted(e) {
      const element = e?.tiktok_updateStatus;
      if (element) {
        statusCallBack(element?.data);
        updateReport(element?.data);

        // This is new bulk clear
        selectedRowsClear();
      }
      openNotification({
        type: "success",
        message: element?.data?.map(({ id, status }) => (
          <div>
            <p className="mb-0">{id}</p>
            <p>{status}</p>
          </div>
        )),
      });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function callStatusUpdateSubmit(status, selectedData = []) {
    let params = [];
    selectedData?.forEach(({ campaignId, adsetId, adId, is_aco }) => {
      if (node === TIKTOK_UPDATE_STATUS_NODE_CAMPAIGN) {
        campaignId && params.push({ id: campaignId });
      } else if (node === TIKTOK_UPDATE_STATUS_NODE_ADGROUP) {
        adsetId && params.push({ id: adsetId });
      } else if (node === TIKTOK_UPDATE_STATUS_NODE_AD) {
        adId && params.push({ id: adId, is_aco });
      }
    });
    // if (ids.length > lengthCheck) {
    //   return openNotification({ type: "warning", message: "Please select max 15 records" });
    // }

    const payload = { params, status, type: node };

    tiktok_updateStatus({ variables: payload });
  }

  return { callStatusUpdateApi: tiktok_updateStatus, callStatusUpdateSubmit, loading };
}
