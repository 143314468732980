import { gql } from "@apollo/client";

export const SUBSCRIPTION_NOTIFICATION_ADDED = gql`
  subscription notification {
    notification {
      id
      payload
      service_name
      userId
    }
  }
`;
