import AdAccounts from "./AdAccounts";
import CampaignsData from "./Campaigns";
import AdSets from "./AdSets";
import Ads from "./Ads";
import UserReport from "./UserReport";
import Category from "./CategoryReport";
import {
  VOLUUM_VIEW,
  VOLUUM_VIEW_TEAM_REPORTING,
  VOLUUM_VIEW_WORKSPACE_REPORTING,
} from "shared/UserPermission/userPermissionList";
import LookTabRouting from "components/LookTabRouting";
import ActionIconRender from "components/ActionIconRender";
import { useSelector } from "react-redux";
import UbIdReport from "./UbId";
import CategoryAssignCheck from "./CategoryAssignCheck";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";

export default function FacebookReports() {
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);

  const isSubIdReport = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
    ? [
        {
          link: "/sub-id",
          route: "/sub-id",
          title: "SubId",
          component: UbIdReport,
          icon: () => <ActionIconRender iconType="notification" />,
          permission: [VOLUUM_VIEW],
        },
      ]
    : [];

  const tabContent = [
    {
      link: "/user",
      route: "/user",
      title: "User",
      component: UserReport,
      icon: () => <ActionIconRender iconType="user" />,
      permission: [VOLUUM_VIEW_WORKSPACE_REPORTING, VOLUUM_VIEW_TEAM_REPORTING],
    },
    {
      link: "/ad-account",
      route: "/ad-account",
      title: "Ad Accounts",
      component: AdAccounts,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW],
    },
    {
      link: "/campaigns",
      route: "/campaigns",
      title: "Campaigns",
      component: CampaignsData,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW],
    },
    {
      link: "/ad-sets",
      route: "/ad-sets",
      title: "Ad Sets",
      component: AdSets,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW],
    },
    {
      link: "/ads",
      route: "/ads",
      title: "Ads",
      component: Ads,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW],
    },
    {
      link: "/category",
      route: "/category",
      title: "Category",
      component: Category,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: [VOLUUM_VIEW],
    },
    ...isSubIdReport,
  ];

  return (
    <div>
      <CategoryAssignCheck
        renderItem={<LookTabRouting dashboardHeader={{ title: "Reports" }} tabs={tabContent} />}
      />
    </div>
  );
}
