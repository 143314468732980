import { createContext, useContext, useState, useEffect, useRef } from "react";
import { Select, Input, Form, DatePicker, Radio, Switch, InputNumber, Checkbox } from "antd";
import moment from "moment";
import cx from "classnames";
import ActionIconRender from "../ActionIconRender";
const { Group } = Radio;

const EditableContext = createContext(null);
export const TableContext = createContext(null);

export function useTableContext() {
  const providerValues = useContext(TableContext);
  return { ...providerValues };
}

export const EditableRow = ({ children }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>{children}</EditableContext.Provider>
    </Form>
  );
};

export const SwitchButton = ({ switchOptions, setDataSource, dataSource, dataIndex, record }) => {
  const [tabPosition, setTabPosition] = useState(record?.status);

  function handleChange(e) {
    setTabPosition(e.target.value);
    setDataSource(
      dataSource.map((item) =>
        item.id === record.id ? { ...item, [dataIndex]: e.target.value } : item
      )
    );
  }

  return (
    <Form.Item
      style={{
        margin: 0,
      }}
      name={dataIndex}
    >
      <div className="custom-switch">
        <Group
          buttonStyle="solid"
          value={tabPosition}
          options={switchOptions}
          optionType="button"
          onChange={handleChange}
        />
      </div>
    </Form.Item>
  );
};

export const SwitchButtonType2 = ({
  switchOptions,
  setDataSource,
  dataSource,
  dataIndex,
  record,
}) => {
  function handleChange(e) {
    setDataSource(
      dataSource.map((item) =>
        item.id === record.id
          ? { ...item, [dataIndex]: e ? switchOptions[0] : switchOptions[1] }
          : item
      )
    );
  }

  return (
    <Form.Item
      style={{
        margin: 0,
      }}
      name={dataIndex}
    >
      <div className="custom-switch">
        <Switch
          checkedChildren={switchOptions[0]}
          unCheckedChildren={switchOptions[1]}
          checked={record?.status === switchOptions[0]}
          onChange={handleChange}
        />
      </div>
    </Form.Item>
  );
};

export const EditableCell = (props) => {
  const {
    title,
    editable,
    children,
    dataIndex,
    type = "input",
    selectionIconsType,
    setDataSource = () => {},
    dataSource,
    record,
    handleSave,
    className = "",
    ...restProps
  } = props;

  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const table = useContext(TableContext);
  const { contextData } = table;

  const { Option } = Select;
  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (el) => {
    try {
      if (type === "text") {
        setEditing(!editing);
      }
      form.setFieldsValue({
        [dataIndex]: el,
      });
      handleSave({ ...record, [dataIndex]: el });
      // console.log("Individual Item", { ...record });
    } catch (errInfo) {
      // console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  function renderSelectionIcon(selectionValue) {
    let icon = { iconType: "", className: "" };

    if (selectionValue === "Medium") icon = { iconType: "equal", className: "text-yellow" };
    if (selectionValue === "Highest" || selectionValue === "Urgent") {
      icon = { iconType: "double-up", className: "text-danger" };
    }
    if (selectionValue === "High") icon = { iconType: "up", className: "text-danger" };
    if (selectionValue === "Low") icon = { iconType: "down", className: "text-primary" };
    if (selectionValue === "Lowest" || selectionValue === "Not Urgent") {
      icon = { iconType: "double-down", className: "text-primary" };
    }

    // if (selectionValue === "To Do") icon = { iconType: "dot", className: "text-gray" };
    // if (selectionValue === "In Progress") icon = { iconType: "dot", className: "text-blue" };
    // if (selectionValue === "Done") icon = { iconType: "dot", className: "text-primary" };

    return icon?.iconType ? (
      <span className={cx("mr-4", icon?.className)}>
        <ActionIconRender iconType={icon?.iconType} />
      </span>
    ) : null;
  }
  function statusText(selectionValue) {
    let span = { color: "" };

    if (selectionValue === "To Do") span = { color: "gray" };
    if (selectionValue === "Hold") span = { color: "gray" };
    if (selectionValue === "In Progress") span = { color: "blue" };
    if (selectionValue === "Cancelled") span = { color: "red" };
    if (selectionValue === "Done") span = { color: "green" };
    if (selectionValue === "Closed") span = { color: "green" };
    if (selectionValue === "Open") span = { color: "blue" };
    if (selectionValue === "Off Track") span = { color: "gray" };
    if (selectionValue === "On Track") span = { color: "blue" };
    if (selectionValue === "Completed") span = { color: "green" };

    return (
      <span className={cx("task-status", "status-color-" + span?.color)}>{selectionValue}</span>
    );
  }

  if (editable && type === "select") {
    const filterOptions = selectionIconsType
      ? {}
      : {
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
        };

    childNode = (
      <Select className="w-100" onChange={save} value={record?.[dataIndex]} {...filterOptions}>
        {contextData?.editSelectFieldData[dataIndex]?.map(({ id, name }) =>
          selectionIconsType ? (
            <Option key={id} value={parseInt(id)}>
              {selectionIconsType && selectionIconsType !== "status" && renderSelectionIcon(name)}
              {selectionIconsType && selectionIconsType === "status" ? statusText(name) : name}
            </Option>
          ) : (
            <Option key={id} value={parseInt(id)}>
              {name}
            </Option>
          )
        )}
      </Select>
    );
  }

  if (editable && type === "text") {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          className="editable-input"
          ref={inputRef}
          onPressEnter={(val) => save(val.target.value)}
          onBlur={(val) => save(val.target.value)}
        />
      </Form.Item>
    ) : (
      <div
        className="cursor-pointer text-input-type"
        onClick={toggleEdit}
        style={{ minHeight: 20 }}
      >
        <span className="LookTableColumnRender">{children}</span>
      </div>
    );
  }

  if (editable && type === "currency") {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <InputNumber
          className="editable-input w-100"
          ref={inputRef}
          onChange={(val) => save(val)}
          controls={false}
          // onPressEnter={(val) => save(val.target.value)}
          // onBlur={(val) => save(val.target.value)}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        />
      </Form.Item>
    ) : (
      <div
        className="cursor-pointer text-input-type"
        onClick={toggleEdit}
        style={{ minHeight: 20 }}
      >
        <span className="LookTableColumnRender">{children}</span>
      </div>
    );
  }

  if (editable && type === "date") {
    childNode = (
      <DatePicker
        className="no-date-clear"
        value={moment(record?.due_date, "YYYY-MM-DD")}
        format="YYYY-MM-DD"
        onChange={(e, f) => {
          save(f);
        }}
        style={{ width: "100%" }}
      />
    );
  }

  if (editable && type === "switch") {
    childNode = (
      <SwitchButton
        switchOptions={contextData?.switchFieldData[dataIndex]}
        dataIndex={dataIndex}
        setDataSource={setDataSource}
        dataSource={dataSource}
        record={record}
      />
    );
  }

  if (editable && type === "checkbox") {
    childNode = <Checkbox checked={record[dataIndex]} onChange={(e) => save(e.target.checked)} />;
  }
  if (editable && type === "switch-type2") {
    childNode = (
      <SwitchButtonType2
        switchOptions={contextData?.switchType2FieldData[dataIndex]}
        dataIndex={dataIndex}
        setDataSource={setDataSource}
        dataSource={dataSource}
        record={record}
      />
    );
  }

  return (
    <td {...restProps} className={cx(className, { editable })}>
      {childNode}
    </td>
  );
};
