import {
  SC_GET_ALL_DEPARTMENT_DROPDOWN,
  SC_GET_ASSIGNED_DEPARTMENTS_DROPDOWN,
  SC_GET_USERS_BY_DEPARTMENT_DROPDOWN,
} from "shared/gql/gqlSchema/scorecardGql";
import useApiToStoreQuery from "./useApiToStoreQuery";

export default function useScorecardApiParams() {
  const {
    apiCall: SC_AssignedDepartmentsFetch = () => {},
    refetch: SC_AssignedDepartmentsRefetch,
    setDataSource: SC_AssignedDepartmentsSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_ASSIGNED_DEPARTMENTS_DROPDOWN,
    elementKey: "SC_get_assigned_departments",
    storeKey: "SC_assignedDepartments",
  });

  const {
    apiCall: SC_allDepartmentsFetch = () => {},
    refetch: SC_allDepartmentsRefetch,
    setDataSource: SC_allDepartmentsSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_ALL_DEPARTMENT_DROPDOWN,
    elementKey: "SC_getAllDepartment",
    storeKey: "SC_AllDepartments",
  });

  const {
    apiCall: fetchUsersByDepartment = () => {},
    refetch: fetchUsersByDepartmentRefetch,
    setDataSource: fetchUsersByDepartmentSetDataSource,
  } = useApiToStoreQuery({
    api: SC_GET_USERS_BY_DEPARTMENT_DROPDOWN,
    elementKey: "SC_getUsersByDepartment",
    storeKey: "SC_usersByDepartment",
  });

  return {
    SC_AssignedDepartmentsFetch,
    SC_AssignedDepartmentsRefetch,
    SC_AssignedDepartmentsSetDataSource,

    SC_allDepartmentsFetch,
    SC_allDepartmentsRefetch,
    SC_allDepartmentsSetDataSource,

    fetchUsersByDepartment,
    fetchUsersByDepartmentRefetch,
    fetchUsersByDepartmentSetDataSource,
  };
}
