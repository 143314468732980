export const PRODUCT_ID_TRACKER = 1;
export const PRODUCT_ID_LEADGEN = 1;
export const PRODUCT_ID_VS = 2;
export const PRODUCT_ID_SAS = 3;
export const PRODUCT_ID_STAGING_VS = 1;
//only for rule engine
export const PRODUCT_ID_RSOC = 4;
export const RULE_PLATFORM_FB = "fb";
export const RULE_PLATFORM_TIKTOK = "tiktok";
export const RULE_PLATFORM_TABOOLA = "taboola";
export const RULE_PLATFORM_VAROZI = "varozi";
export const RULE_STATUS_NOT_INQUEUE = "NOTinqueue";
export const RULE_STATUS_PAUSED = "paused";

export const PLATFORM_ID_FACEBOOK = 1;
export const PLATFORM_ID_TABOOLA = 2;
export const PLATFORM_ID_GOOGLE_DISPLAY = 3;
export const PLATFORM_ID_OUTBRAIN = 4;
export const PLATFORM_ID_GOOGLE_SEARCH = 5;
export const PLATFORM_ID_TIKTOK = 7;
export const PLATFORM_ID_YAHOO_GEMINI = 8;
export const PLATFORM_ID_REVCONTENT = 9;
export const PLATFORM_ID_MGID = 10;
export const PLATFORM_ID_SNAPCHAT = 11;
export const PLATFORM_ID_GOOGLE_DISCOVERY = 12;
export const PLATFORM_ID_FACEBOOK_DISPLAY = 13;

export const VENDOR_ID_CODE_FUEL = 2;
export const VENDOR_ID_MNET = 3;
export const VENDOR_ID_RISE_CODE = 17;
export const VENDOR_ID_OB_MEDIA = 7;
export const VENDOR_ID_SEDO = 4;
export const VENDOR_ID_DOMAIN_ACTIVE = 5;
export const VENDOR_ID_TONIC = 6;
export const VENDOR_ID_BODIS = 12;
export const VENDOR_ID_AM_PRODUCTION = 19;
export const VENDOR_ID_VAROZI = 100;

export const REPORT_TYPE_AD_ACCOUNT = "adAccount";
export const REPORT_TYPE_AD = "ad";
export const REPORT_TYPE_AD_ADSET = "adset";
export const REPORT_TYPE_CAMPAIGN = "campaign";
export const REPORT_TYPE_CUSTOMER = "customer";
export const REPORT_TYPE_ADGROUP = "adgroup";
export const REPORT_TYPE_USER = "user";

export const REPORT_TYPE_POSTBACK = "PostBack";
export const REPORT_TYPE_LIVE = "Live";
export const REPORT_TYPE_HOURLY = "Hourly";
export const REPORT_TYPE_DAILY = "Daily";
export const REPORT_TYPE_GENERAL = "general";

export const REPORT_TYPE_IS_HOURLY = "isHourly";
export const REPORT_TYPE_IS_DAILY = "isDaily";
export const REPORT_TYPE_IS_HOUR_OF_THE_DAY = "isHourOfTheDay";

export const REPORT_TYPE_PERFORMANCE_IS_HOURLY = "hourly-performance";
export const REPORT_TYPE_PERFORMANCE_IS_DAILY = "daily-performance";
export const REPORT_TYPE_PERFORMANCE_IS_HOUR_OF_THE_DAY = "hourOfTheDay-performance";

export const REPORT_TYPE_AD_ACCOUNT_PERFORMANCE = "adAccount-performance";
export const REPORT_TYPE_AD_PERFORMANCE = "ad-performance";
export const REPORT_TYPE_AD_ADSET_PERFORMANCE = "adset-performance";
export const REPORT_TYPE_CAMPAIGN_PERFORMANCE = "campaign-performance";

//GQL API STATUS RESPONSE ENUM
export const GQL_API_RESPONSE_STATUS_SUCCESS = "SUCCESS";
export const GQL_API_RESPONSE_STATUS_FAILURE = "FAILURE";
export const GQL_API_RESPONSE_STATUS_WARNING = "WARNING";

//FORM TYPE

export const FORM_TYPE_ADD = "add";
export const FORM_TYPE_UPDATE = "update";

//CURRENCY TYPES
export const CURRENCY_TYPE_INR = "INR";
export const CURRENCY_TYPE_INR_SYMBOL = "₹";

export const CURRENCY_TYPE_USD = "USD";
export const CURRENCY_TYPE_USD_SYMBOL = "$";

//Workspace

export const WORKSPACE_ID_HRMS = 126;
export const WORKSPACE_ID_RSOC = 133;
export const LF_ADMIN_EMAIL = "admin@lookfinity.com";

//Budget tags keys
export const REPORT_SAS_RSOC_FB_TAG_TYPE_CAMPAIGN = "sas-facebook-rsoc-campaign";
export const REPORT_SAS_RSOC_FB_TAG_TYPE_ADSET = "sas-facebook-rsoc-adset";
export const REPORT_SAS_RSOC_FB_TAG_TYPE_Ad = "sas-facebook-rsoc-ad";

export const REPORT_SAS_FB_POSTBACK_TAG_TYPE_CAMPAIGN = "sas-facebook-postback-campaign";
export const REPORT_SAS_FB_POSTBACK_TAG_TYPE_ADSET = "sas-facebook-postback-adset";
export const REPORT_SAS_FB_LIVE_TAG_TYPE_CAMPAIGN = "sas-facebook-live-campaign";
export const REPORT_SAS_FB_LIVE_TAG_TYPE_ADSET = "sas-facebook-live-adset";
export const REPORT_SAS_FB_DAILY_TAG_TYPE_CAMPAIGN = "sas-facebook-daily-campaign";
export const REPORT_SAS_FB_DAILY_TAG_TYPE_ADSET = "sas-facebook-daily-adset";

export const REPORT_VOLUUM_HOURLY_REPORT = "voluum_hourly_report";
export const REPORT_VOLUUM_DAILY_REPORT = "voluum_daily_report";
export const REPORT_VOLUUM_HOUR_OF_THE_DAY_REPORT = "voluum_hour_of_day_report";

export const AD_ACCOUNT = "adAccount";
export const CAMPAIGN = "campaign";
export const AD_ADSET = "adset";
export const AD = "ad";

export const VS_KEY_CAMPAIGN = "CAMPAIGN";

//vs nomenclature
export const NOMENCLATURE_VS_CAMPAIGN_LEVEL =
  "{vs}-{country}-{device}-{brand}-{article}-{user}-{alias}-{campaignid}-{pixel}";
export const NOMENCLATURE_VS_ADSET_LEVEL =
  "{vs}-{country}-{device}-{brand}-{article}-{user}-{alias}-{campaignid}-{adsetid}-{pixel}";
export const NOMENCLATURE_VS_AD_LEVEL =
  "{vs}-{country}-{device}-{brand}-{article}-{user}-{alias}-{campaignid}-{adsetid}-{adid}";
