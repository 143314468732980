import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { INDUSTRY_INSIGHTS_GET_CATEGORIES } from "shared/gql/gqlSchema/industryInsightsGql";
import { openNotification } from "utils";
import PopoverWrapper from "components/popoverWrapper";
import { EllipsisOutlined } from "@ant-design/icons";
import { FAILURE, SUCCESS } from "shared/enum/commonKeysandEnum";
import moment from "moment";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
// import MainForm from "./MainForm";

function Category() {
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const dateFormat = "YYYY-MM-DD";
  const defaultDateDaily = [moment().format(dateFormat), moment().format(dateFormat)];
  const [rangeDates, setRangeDates] = useState(defaultDateDaily);
  const [timeZone, setTimeZone] = useState(dateRangeTimezone?.timeZone);
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };

  const [categoriesData, setCategoriesData] = useState([]);

  const { loading: loading_GET_KEYWORDS_DOMAINS, refetch: refetch_GET_KEYWORDS_DOMAINS } =
    useCustomQuery(INDUSTRY_INSIGHTS_GET_CATEGORIES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ii_Categories;
        if (element) {
          if (element?.status === SUCCESS) {
            setCategoriesData(element?.data);
          } else if (element?.status === FAILURE) {
            openNotification({ type: "error", message: element?.message, key: "error-add" });
          }
        }
      },
      onError: (err) => {
        openNotification({ type: "error", message: err.message });
      },
      variables: {
        fromDate: rangeDates[0],
        toDate: rangeDates[1],
      },
    });
  const loading = loading_GET_KEYWORDS_DOMAINS;

  const refetch = () => {
    refetch_GET_KEYWORDS_DOMAINS();
  };

  //this will be used in future
  // const action = [
  //   {
  //     title: "Action",
  //     dataIndex: "action",
  //     nofilter: true,
  //     render: (action, actionProps, { index }) => {
  //       const poverWrapperOptions = [
  //         {
  //           option: "file-search",
  //           title: "View Ads",
  //           optionProps: {
  //             // onClick: () => showDrawerResync(actionProps),
  //           },
  //         },
  //       ];
  //       return (
  //         <>
  //           <PopoverWrapper options={poverWrapperOptions}>
  //             <EllipsisOutlined className="pop-info" />
  //           </PopoverWrapper>
  //         </>
  //       );
  //     },
  //     fixed: "right",
  //     width: 124,
  //     minWidth: 124,
  //   },
  // ];
  const initialColumns = [
    {
      title: "Name",
      dataIndex: "category",
      key: "category",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      sorter: (a, b) => a?.category?.localeCompare(b?.category),
      searchType: "string",
    },
    {
      title: "No. of ads",
      dataIndex: "no_of_ads",
      key: "no_of_ads",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.no_of_ads - b?.no_of_ads,
      searchType: "number",
    },
    {
      title: "No. of domains",
      dataIndex: "doms",
      key: "doms",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.doms - b?.doms,
      searchType: "number",
    },
    {
      title: "No. of Pages",
      dataIndex: "pages",
      key: "pages",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.pages - b?.pages,
      searchType: "number",
    },
    // ...action,
  ];
  const finalMergeData = [];
  categoriesData?.forEach((element, index) => {
    const { pages = 0, doms = 0, no_of_ads = 0 } = element;

    finalMergeData.push({
      ...element,
      key: index,
      pages,
      doms,
      no_of_ads,
    });
  });

  const dataRender = outerFilterData(filterData(finalMergeData), {
    filterKeys: ["category"],
  });

  const tableMainProps = {
    isSrNo: true,
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    isRecommendation: true,
    defaultPagination: true,
    isRowSelection: false,

    refetch,
    loading,
    tableProps: {
      initialColumns,
      recordData: dataRender,
    },
    persistKey,
    dateRangeProps: {
      noStore: true,
      defaultDate: rangeDates,
      onChange: handleDateRange,
    },
  };

  return (
    <div>
      <DashboardHeader title="Industry Insights" />
      <LookTable {...tableMainProps} />
    </div>
  );
}

export default Category;
