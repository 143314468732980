const { useState, useEffect } = require("react");
const { useGoogleLaunchV1Context } = require("../../Context");
const { useHistory } = require("react-router-dom/cjs/react-router-dom.min");
const { useApiToStoreContext } = require("hooks/useApiToStore/context");
const { useSelector } = require("react-redux");
const { G360_GET_ADACCOUNT } = require("shared/gql/gqlSchema/g360Gql");
const { useCustomLazyQuery } = require("hooks/apolloClientHooks");
const { openNotification } = require("utils");
const { default: LookModal } = require("components/LookModal");
const { Form } = require("antd");
const { default: SelectInput } = require("components/forms/SelectInput");

export default function AdAccountModal(props) {
  const { adAccountObject, setAdAccountObject } = useGoogleLaunchV1Context();
  const [visibleModal, setVisibleModal] = useState(true); // keeping it true because we want to open modal automatically when user comes to this page
  const [adAccountData, setAdAccountData] = useState({ profileId: "", adAccountId: "" }); // final data state
  const [adAccountArray, setAdAccountArray] = useState([]);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { gdnUserProfilesFetch } = useApiToStoreContext(); //getting the api from conext
  const { g_userProfileGetAll: gdnProfileData, g_userProfileGetAll_loading } = useSelector(
    /**
     * extracting profiles data from redux state
     */
    (state) => state?.userSettings?.userSettingData
  );

  useEffect(() => {
    /**
     * using this effect for calling get GDN profiles API from context(useApiToStoreContext)
     *  */
    gdnUserProfilesFetch();
  }, []);

  const [adAccountDataFetch, { loading: loading_adAccount }] = useCustomLazyQuery(
    G360_GET_ADACCOUNT,
    {
      notifyOnNetworkChange: true,
      onCompleted: (e) => {
        const element = e?.g_customerUser;
        if (element?.length > 0) {
          setAdAccountArray(element);
          setErrors({});
        } else {
          setErrors((prev) => ({
            ...prev,
            adAccount:
              "Ad Accounts not found for this profile, please contact your admin or change profile",
          }));
        }
      },
      onError: (e) => {
        openNotification({ type: "error", message: e?.message, key: "adaccountFetchError" });
      },
    }
  );

  const onsubmitModal = () => {
    setVisibleModal(false);
    setAdAccountObject((pre) => ({
      ...pre,
      accountId: adAccountData?.adAccountId,
      profileId: adAccountData?.profileId,
    }));
  };

  const closeAdAccountModal = () => {
    setVisibleModal(false);
    history.goBack();
  };

  const modalProps = {
    title: "Ad Account",
    visible: visibleModal,
    onCancel: closeAdAccountModal,
    okokText: "Go to create campaign",
    onSubmit: onsubmitModal,
    disabled: adAccountData?.adAccountId === "" || adAccountData?.profileId === "", // keeping the submit button disabled till both values are selected by user
  };

  return (
    <LookModal {...modalProps}>
      <Form layout="vertical">
        <SelectInput
          name="googleProfile"
          label="Google Profile"
          loader={g_userProfileGetAll_loading}
          required
          search
          value={adAccountData?.profileId ? adAccountData.profileId : null}
          placeholder="Select Your Google Profile"
          options={gdnProfileData?.map(({ name, id }) => ({
            name,
            value: id,
          }))}
          onChange={(e) => {
            setAdAccountData((prev) => ({ ...prev, profileId: e, adAccountId: "" }));
            adAccountDataFetch({ variables: { profile_id: e } });
          }}
        />

        {adAccountData?.profileId && (
          <SelectInput
            name="adAccount"
            label="Ad Account"
            placeholder="Select Your Ad Account"
            loader={loading_adAccount}
            required
            search
            value={adAccountData?.adAccountId ? adAccountData?.adAccountId : undefined}
            options={adAccountArray?.map(({ name, customer_id }) => ({
              name,
              value: customer_id,
            }))}
            onChange={(e) => setAdAccountData((prev) => ({ ...prev, adAccountId: e }))}
          />
        )}
        {errors?.adAccount && <span className="error-text">{errors?.adAccount}</span>}
      </Form>
    </LookModal>
  );
}
