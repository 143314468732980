import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "cx";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
} from "shared/SharedKeys";
const valueCheck = (value, checkTotal = false) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(2);
  return valueFloat;
};
export default function useCampaignReportColumns(props) {
  const { callDetailView = () => {}, reportType = "" } = props || {};

  let nameTitle = "";

  if (REPORT_TYPE_AD_ACCOUNT === reportType) {
    nameTitle = "Ad Account";
  }
  if (REPORT_TYPE_AD === reportType) {
    nameTitle = "Ad";
  }
  if (REPORT_TYPE_AD_ADSET === reportType) {
    nameTitle = "Adset";
  }
  if (REPORT_TYPE_CAMPAIGN === reportType) {
    nameTitle = "Campaign";
  }

  const campaignReportColumns = [
    {
      title: cx({ [nameTitle]: nameTitle }, "Name"),
      dataIndex: "name",
      key: "name",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => callDetailView(otherProps)} />
      ),
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: "Campaign Id",
      dataIndex: "campaignId",
      key: "campaignId",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (value) => <LookTableColumnRender title={value} />,
      hidden: true,
      width: 190,
      minWidth: 190,
      searchType: "string",
    },

    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (value) => <LookTableColumnRender title={value?.iso_code} />,
      hidden: true,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.country - b?.country,
      searchType: "string",
    },

    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      render: (value) => <LookTableColumnRender title={value} />,
      hidden: true,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.device - b?.device,
      searchType: "string",
    },

    {
      title: "Revenue",
      dataIndex: "estimate_revenue",
      key: "estimate_revenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.estimate_revenue - b?.estimate_revenue,
      searchType: "number",
    },
    {
      title: "Spend",
      dataIndex: "spendFB",
      key: "spendFB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spendFB - b?.spendFB,
      searchType: "number",
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value = 0, otherProps) => {
        // const profit = `$${value.replace("-", "")}`;
        const profit = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      searchType: "number",
    },

    {
      title: "ROI",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROI - b?.ROI,
      searchType: "number",
    },

    {
      title: "Sessions",
      dataIndex: "clicksFB",
      key: "clicksFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicksFB - b?.clicksFB,
      searchType: "number",
    },

    {
      title: "Searches",
      dataIndex: "searches",
      key: "searches",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.searches - b?.searches,
      searchType: "number",
    },

    {
      title: "Leads (V)",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",
    },

    {
      title: "Leads (FB)",
      dataIndex: "leadsFB",
      key: "leadsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.leadsFB - b?.leadsFB,
      searchType: "number",
    },

    {
      title: "CPR (V)",
      dataIndex: "CPR_V",
      key: "CPR_V",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_V - b?.CPR_V,
      searchType: "number",
    },

    {
      title: "CPR (FB)",
      dataIndex: "CPR_FB",
      key: "CPR_FB",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_FB - b?.CPR_FB,
      searchType: "number",
    },

    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },
    {
      title: "RPV",
      dataIndex: "RPV",
      key: "RPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPV - b?.RPV,
      searchType: "number",
    },
    {
      title: "RPL",
      dataIndex: "RPL",
      key: "RPL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPL - b?.RPL,
      searchType: "number",
    },

    {
      title: "AD CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressionsFB",
      key: "impressionsFB",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsFB - b?.impressionsFB,
      searchType: "number",
    },

    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },

    {
      title: "V Visits",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",
    },
    {
      title: "Life Time Budget",
      dataIndex: "lifeTimeBudget",
      key: "lifeTimeBudget",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 140,
      minWidth: 140,
      sorter: (a, b) => a?.lifeTimeBudget - b?.lifeTimeBudget,
      searchType: "number",
    },
  ];

  return { campaignReportColumns };
}
