import {
  USER_SETTING_MAIN_PAGINATION,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import LookModal from "components/LookModal";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  INDUSTRY_INSIGHTS_ADD_NOTIFICATION,
  INDUSTRY_INSIGHTS_ADD_PAGE,
  INDUSTRY_INSIGHTS_ADS_COUNT,
  INDUSTRY_INSIGHTS_DELETE_PAGE,
  INDUSTRY_INSIGHTS_GET_PAGES,
  INDUSTRY_INSIGHTS_PAGE_NOTIFICATION_UPDATE,
  INDUSTRY_INSIGHTS_PAGE_UPDATE,
} from "shared/gql/gqlSchema/industryInsightsGql";
import { openNotification } from "utils";
import * as yup from "yup";
import AddPageForm from "./AddPageForm";
import PopoverWrapper from "components/popoverWrapper";
import { EllipsisOutlined } from "@ant-design/icons";
import { FAILURE, SUCCESS } from "shared/enum/commonKeysandEnum";
import {
  INDUSTRY_ISIGHTS_STATUS_ACTIVE,
  INDUSTRY_ISIGHTS_TYPE_PAGE,
} from "shared/enum/industryInsightsKeysAndEnums";
import NotifyMe from "../NotifyMePopup";
import { Switch } from "antd";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import LookDrawer from "components/LookDrawer";
import Ads from "../Ads";
import { ENTITY_TYPE_PAGE } from "../industryInsightsEnum";

function Pages() {
  const dispatch = useDispatch();
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;

  const [pagesData, setPagesData] = useState([]);
  const [visibleAddPageModal, setAddPageVisibleModal] = useState(false);
  const resetFormRef = useRef(() => {});
  const [visibleNotifyMeModal, setVisibleNotifyMeModal] = useState(false);
  const [notifyAbout, setNotifyAbout] = useState([]);
  const [defaultTime, setDefaultTime] = useState("02:00");
  const [prefrences, setPrefrences] = useState([]);
  const [rowData, setRowData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const { selectedRowsClear } = useLookTableParentContext();
  const [rowIds, setRowIds] = useState([]);
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);
  const currentSorter = useSelector((state) => state?.userSettings?.sorter?.[persistKey]);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const mainPagination = useSelector((state) => state?.userSettings?.mainPagination);
  const mainPaginationData = useSelector(
    (state) => state?.userSettings?.mainPagination?.[persistKey]
  );
  const [visibleAdsDrawer, setVisibleAdsDrawer] = useState(false);
  const [pages, setPages] = useState({});

  const sort = currentSorter?.order ? { ...currentSorter } : {};
  const apiPayload = {
    page: mainPaginationData?.page,
    size: mainPaginationData?.size,
    search,
    ...sort,
  };
  const {
    data,
    loading,
    refetch: refetch_GET_PAGES,
  } = useCustomQuery(INDUSTRY_INSIGHTS_GET_PAGES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ii_Pages;
      if (element) {
        if (element?.status === SUCCESS) {
          handleAdCount(element?.data?.data);
        } else if (element?.status === FAILURE) {
          openNotification({ type: "error", message: element?.message, key: "error-add" });
        }
      }
    },
    onError: (err) => {
      openNotification({ type: "error", message: err.message });
    },
    variables: {
      ...apiPayload,
    },
  });
  const [fetchAdsCount, { loading: loading_ADS_COUNT, refetch: refetch_ADS_COUNT }] =
    useCustomLazyQuery(INDUSTRY_INSIGHTS_ADS_COUNT, {
      notifyOnNetworkStatusChange: true,
    });
  const handleAdCount = (pages) => {
    fetchAdsCount({
      variables: { entityType: ENTITY_TYPE_PAGE, entityIds: pages?.map((data) => data?.pageId) },
      onCompleted: (e) => {
        const element = e?.cs_AdCount?.data;
        if (element?.length) {
          let hashTable = {};
          for (let i = 0; i < element?.length; i++) {
            const data = element[i]?.entityId;
            hashTable[data] = element[i]?.adCount;
          }
          const updatedPages = pages?.map((data) => ({
            ...data,
            adCount: hashTable[data?.pageId] || 0,
          }));
          setPagesData(updatedPages);
        } else {
          setPagesData(pages);
        }
      },
      onError: () => {
        setPagesData(pages);
        openNotification({ type: "error", message: "AdCount fetch failed" });
      },
    });
  };
  const fetchDatakey = data?.ii_Pages?.data;
  useEffect(() => {
    if (!loading) {
      dispatch({
        type: USER_SETTING_MAIN_PAGINATION,
        payload: {
          ...mainPagination,
          [persistKey]: {
            ...mainPagination?.main,
            totalCount: fetchDatakey?.totalRecords,
          },
        },
      });
    }
  }, [loading, fetchDatakey]);
  const refetch = () => {
    refetch_GET_PAGES();
  };
  const showDrawerAds = (data) => {
    setVisibleAdsDrawer(true);
    setPages({ Pages: { [data?.pageId]: true } });
  };
  const onCloseDrawerAds = () => {
    setVisibleAdsDrawer(false);
    setPages([]);
  };

  const [addPage, { loading: loading_addPage, refetch: refetch_addPage }] = useCustomMutation(
    INDUSTRY_INSIGHTS_ADD_PAGE,
    {
      onCompleted: (e) => {
        const element = e?.ii_addPages;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch();
          onCloseAddPageModal();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const [deletePage, { loading: loading_deletePage }] = useCustomMutation(
    INDUSTRY_INSIGHTS_DELETE_PAGE,
    {
      onCompleted: (e) => {
        const element = e?.ii_deletePages;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          selectedRowsClear();
          refetch();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  const [addNotification, { loading: loading_addNotification, refetch: refetch_addNotification }] =
    useCustomMutation(INDUSTRY_INSIGHTS_ADD_NOTIFICATION, {
      onCompleted: (e) => {
        const element = e?.ii_addNotification;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch();
          onCloseNotifyMeFormModal();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [updatePage, { loading: loading_updatePage, refetch: refetch_updatePage }] =
    useCustomMutation(INDUSTRY_INSIGHTS_PAGE_UPDATE, {
      onCompleted: (e) => {
        const element = e?.ii_updatePages;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          selectedRowsClear();
          refetch();
          onCloseNotifyMeFormModal();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const [
    updateNotification,
    { loading: loading_updateNotification, refetch: refetch_updateNotification },
  ] = useCustomMutation(INDUSTRY_INSIGHTS_PAGE_NOTIFICATION_UPDATE, {
    onCompleted: (e) => {
      const element = e?.ii_updatePageNotification;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        refetch();
        selectedRowsClear();
        onCloseNotifyMeFormModal();
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps, { index }) => {
        const poverWrapperOptions = [
          {
            option: "spy",
            title: (
              <>
                Insights
                <Switch
                  size="small"
                  className="ml-4"
                  checked={actionProps?.staleStatus === INDUSTRY_ISIGHTS_STATUS_ACTIVE}
                />
              </>
            ),
            optionProps: {
              onClick: () =>
                updatePage({
                  variables: {
                    pageDbIds: actionProps?.id,
                    stale: actionProps?.staleStatus === INDUSTRY_ISIGHTS_STATUS_ACTIVE,
                  },
                }),
            },
          },
          {
            option: "notification",
            title: "Notify me",
            optionProps: {
              onClick: () => showNotifyMeFormModal(actionProps),
            },
          },
          {
            option: "file-search",
            title: "View Ads",
            optionProps: {
              onClick: () => showDrawerAds(actionProps),
            },
          },
          {
            option: "delete",
            title: "Delete",
            optionProps: {
              onClick: () => deletePage({ variables: { pageIds: [actionProps?.id] } }),
            },
          },
        ];
        return (
          <>
            <PopoverWrapper options={poverWrapperOptions}>
              <EllipsisOutlined className="pop-info" />
            </PopoverWrapper>
          </>
        );
      },
      fixed: "right",
      width: 124,
      minWidth: 124,
    },
  ];
  const initialColumns = [
    {
      title: "Page Name",
      dataIndex: "name",
      key: "name",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
      searchType: "string",
      sorter: true,
    },
    {
      title: "Page Id",
      dataIndex: "pageId",
      key: "pageId",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 160,
      minWidth: 160,
      searchType: "string",
      sorter: true,
    },
    {
      title: "No. of ads",
      dataIndex: "adCount",
      key: "adCount",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: true,
      searchType: "number",
    },
    {
      title: "No. of domains",
      dataIndex: "domainsKeywords",
      key: "domainsKeywords",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      sorter: true,
      searchType: "number",
    },
    {
      title: "Status",
      dataIndex: "staleStatus",
      key: "staleStatus",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 120,
      minWidth: 120,
      searchType: "string",
      sorter: true,
    },
    ...action,
  ];
  const finalMergeData = [];

  pagesData.map((element, index) => {
    const {
      pageId = "",
      no_of_ads = 0,
      domainsKeywords = 0,
      name = "",
      staleStatus = true,
      id = "",
    } = element;
    finalMergeData?.push({
      ...element,
      key: id,
      pageId,
      no_of_ads,
      domainsKeywords,
      name,
      staleStatus: staleStatus ? "INACTIVE" : "ACTIVE",
    });
  });

  const dataRender = outerFilterData(filterData(finalMergeData), {
    filterKeys: ["name"],
  });

  const showNotifyMeFormModalForBulk = (e) => {
    const rowIds = e?.map((item) => item?.id);
    setRowIds(rowIds);
    setDefaultTime("02:00");
    setNotifyAbout([INDUSTRY_ISIGHTS_TYPE_PAGE]);
    setVisibleNotifyMeModal(true);
  };

  const handleBulkInsights = (e, extraProps) => {
    const pageIds = e?.map((item) => item?.id);
    const onInsights = extraProps === "off";
    updatePage({
      variables: { pageDbIds: pageIds, stale: onInsights ? true : false },
    });
  };

  const bulkActions = [
    {
      option: "spy",
      title: "Insights On",
      onClick: (e) => {
        handleBulkInsights(e);
      },
    },
    {
      option: "spy",
      title: "Insights Off",
      onClick: (e) => {
        handleBulkInsights(e, "off");
      },
    },

    {
      option: "notification",
      title: "Notify me",
      onClick: (e) => showNotifyMeFormModalForBulk(e),
    },
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => {
        const pageIds = e?.map((item) => item?.id);
        deletePage({ variables: { pageIds: pageIds } });
      },
    },
  ];

  const bulkActionProps = [
    {
      selectedRows: selectedRows,
      onDismiss: () => {
        setSelectedRows([]);
      },
      actionOptions: bulkActions,
    },
  ];
  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    isRecommendation: true,
    persistPagination: true,
    bulkSelectionAction: bulkActionProps,
    bulkActions,
    refetch,
    loading: loading_ADS_COUNT || loading,
    tableProps: {
      initialColumns,
      recordData: dataRender,
    },
    persistKey,
  };

  const showAddPageModal = () => {
    setAddPageVisibleModal(true);
  };

  const onCloseAddPageModal = () => {
    setAddPageVisibleModal(false);
  };

  const createBtnProps = {
    createBtn: true,
    createBtnText: "Add New Page",
    createBtnHandler: showAddPageModal,
  };

  const addPageModalProps = {
    title: "Add New Page",
    visible: visibleAddPageModal,
    footer: null,
    onCancel: onCloseAddPageModal,
  };

  const initialValues = { page_name: "", page_id: "" };
  const validationSchema = yup.object({
    page_name: yup.string().required().label("Page Name"),
    page_id: yup
      .number()
      .typeError("Please enter a valid number")
      .required("This field is required")
      .integer("Please enter an integer")
      .label("Page ID"),
  });
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;

    const addPageVaribales = {
      name: values?.page_name,
      pageId: values?.page_id,
    };

    addPage({ variables: addPageVaribales });
  }
  function onNotifyFormValueSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;

    const rowDataIds = editMode
      ? { pageIds: rowData?.id || rowIds, toBeNotify: true }
      : { keyDomId: rowData?.id };
    const addNotificationVaribles = {
      day: values?.day,
      defaultFrequency: values?.frequency,
      time: defaultTime,
      notifyAbout: notifyAbout,
      preference: prefrences,
      searchType: INDUSTRY_ISIGHTS_TYPE_PAGE,
      ...rowDataIds,
    };
    editMode
      ? updateNotification({ variables: addNotificationVaribles })
      : addNotification({ variables: addNotificationVaribles });
  }

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    onClose: onCloseAddPageModal,
  };

  const showNotifyMeFormModal = (rowData) => {
    let uniqueArray = [
      ...new Set([
        INDUSTRY_ISIGHTS_TYPE_PAGE,
        ...(rowData?.notificationDetails?.notifyAbout || []),
      ]),
    ];
    setNotifyAbout(uniqueArray);
    setPrefrences([...(rowData?.notificationDetails?.preference || [])]);
    setDefaultTime(rowData?.notificationDetails?.defaultSetting?.time || "02:00");
    setRowData(rowData);
    setVisibleNotifyMeModal(true);
  };

  const onCloseNotifyMeFormModal = () => {
    setVisibleNotifyMeModal(false);
    let defaultTime = editMode ? "" : "02:00";
    setDefaultTime(defaultTime);
    setNotifyAbout([]);
    setPrefrences([]);
    setRowData({});
    setRowIds([]);
  };

  const editMode = !(rowData?.notificationDetails === null);

  const notifyMeFormInitialValues = editMode
    ? {
        frequency: rowData?.notificationDetails?.defaultSetting?.default_frequency?.split(".")[1],
        day: rowData?.notificationDetails?.defaultSetting?.day,
      }
    : {
        frequency: [],
        day: [],
        notifyAbout: notifyAbout,
        preference: prefrences,
      };
  const notifyMeFormValidationSchema = yup.object({
    frequency: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least one Frequency")
      .nullable()
      .label("Frequency"),
    day: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least one Day")
      .nullable()
      .label("Day"),
  });
  const notifyMeFormProps = {
    initialValues: notifyMeFormInitialValues,
    validationSchema: notifyMeFormValidationSchema,
    onSubmit: onNotifyFormValueSubmit,
    onClose: onCloseNotifyMeFormModal,
    notifyAbout,
    setNotifyAbout,
    defaultType: INDUSTRY_ISIGHTS_TYPE_PAGE,
    prefrences,
    setPrefrences,
    defaultTime,
    setDefaultTime,
    btnText: editMode ? "Update" : "Add",
    loading: editMode ? loading_updateNotification : loading_addNotification,
  };
  const notifyMeModalProps = {
    title: editMode ? "Edit frequency" : "Select frequency",
    visible: visibleNotifyMeModal,
    footer: null,
    onCancel: onCloseNotifyMeFormModal,
  };
  const drawerProps = {
    title: `${Object.keys(pages?.Pages || {})?.[0]}`,
    visible: visibleAdsDrawer,
    onClose: onCloseDrawerAds,
    size: 1094,
  };
  return (
    <div>
      <DashboardHeader title="Industry Insights" {...createBtnProps} />
      <LookTable {...tableMainProps} />
      {visibleAdsDrawer && (
        <LookDrawer {...drawerProps}>
          <Ads filteredData={pages} viewAdsProp />
        </LookDrawer>
      )}
      {visibleAddPageModal && (
        <LookModal {...addPageModalProps}>
          <AddPageForm {...formProps} />
        </LookModal>
      )}

      {visibleNotifyMeModal && (
        <LookModal {...notifyMeModalProps}>
          <NotifyMe {...notifyMeFormProps} />
        </LookModal>
      )}
    </div>
  );
}

export default Pages;
