import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLaunchContext } from "components/Launch/Context";
import { PRODUCT_ID_LEADGEN } from "shared/SharedKeys";
import TiktokForm from "components/LaunchTarget/TiktokForm";
import FacebookForm from "components/LaunchTarget/FacebookForm";
import initialSetCampaign from "components/Launch/LaunchSetupFunctions/initialCampaignSetup";
import { CREATE_NEW_TEMPLATE } from "shared/SharedKeys/launchKeys";
import { CONTINUES_DRAFT } from "components/Launch";
function UpdateForm(props) {
  const {
    chooseDraftOrNew,
    handleCampaignAdsetAdset = () => {},
    campaignObject,
    productId,
    isTiktokPlatformLaunch,
    setTargettingTemplateData,
    platformsData,
    initialSetCampaignProps,
    setIsTargetEdited = () => {},
    chooseExistingTemplate,
    selectedTarget,
    setChooseExistingTemplate = () => {},
  } = useLaunchContext();
  const {
    onClose = () => {},
    platformId,
    targetingtemplate,
    campaignIndex,
    setFormStep = () => {},
    showTargetingLaunch = false,
  } = props;
  const {
    cType,
    advertiserId,
    bidStrategy: campaignBidStrategy,
  } = showTargetingLaunch ? targetingtemplate || {} : campaignObject[campaignIndex] || {};
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const dateFormat = "YYYY-MM-DD HH:mm";
  const defaultselectedDate = moment().tz(dateRangeTimezone?.timeZone?.value);
  // const defaultData = moment(defaultselectedDate);
  // const defaultData = defaultselectedDate.format(dateFormat);
  const excludedOptions =
    targetingtemplate?.targeting?.template?.excluded_geo_locations?.regions || [];
  const includedOptions = targetingtemplate?.targeting?.template?.geo_locations?.regions || [];
  const [excludedRegion, setExcludedRegion] = useState(excludedOptions);
  const [includedRegion, setIncludedRegion] = useState(includedOptions);
  const tiktokTargeting = { ...targetingtemplate, ...targetingtemplate?.template };
  const [tiktokinputs, setTiktokInputs] = useState({
    name: tiktokTargeting?.name,
    budgetMode: tiktokTargeting?.budgetMode,
    templatePlatform: tiktokTargeting?.platform?.name,
    householdIncome: tiktokTargeting?.template?.household_income || [],
    placementType: tiktokTargeting?.template?.placement_type,
    spendingPower: tiktokTargeting?.template?.spending_power,
    promotionType: tiktokTargeting?.template?.promotion_type,
    promotionTargetType: tiktokTargeting?.template?.promotion_target_type || "",
    operatingSystems: tiktokTargeting?.template?.operating_systems,
    bidType: tiktokTargeting?.bidType,
    creativeMaterialMode: tiktokTargeting?.creativeMaterialMode || "CUSTOM",
    conversionBidPrice: tiktokTargeting?.conversionBidPrice,
    templategender: tiktokTargeting?.template?.gender,
    cta: tiktokTargeting?.cta,
    objective: tiktokTargeting?.objective,
    billingEvent: tiktokTargeting?.billing_event,
    countries: tiktokTargeting?.location?.filter((item) => item?.level === "countries") || [],
    states: tiktokTargeting?.location?.filter((item) => item?.level === "states") || [],
    cities: tiktokTargeting?.location?.filter((item) => item?.level === "cities") || [],
    ageGroups: tiktokTargeting?.age_groups || [],
    optimizationGoal: tiktokTargeting?.optimizationGoal || [],
    optimizationEvent: tiktokTargeting?.optimizationEvent || [],
    placement: tiktokTargeting?.placements || [],
    pacing_targeting: tiktokTargeting?.pacing,
    locales: tiktokTargeting?.languages || [],
    budget: tiktokTargeting?.budget,
    bid: tiktokTargeting?.bidPrice,
    scheduleType: tiktokTargeting?.scheduleType,
    scheduleStartTime: tiktokTargeting?.scheduleStartTime
      ? moment(tiktokTargeting?.scheduleStartTime)
      : defaultselectedDate,
    scheduleEndTime: tiktokTargeting?.scheduleEndTime
      ? moment(tiktokTargeting?.scheduleEndTime)
      : undefined,
  });
  const fbData = {
    name: targetingtemplate?.targeting?.name,
    description: "",
    multiCountry: targetingtemplate?.targeting?.multiCountry,
    multiDevices: targetingtemplate?.targeting?.multiDevices,
    isDynamicCreative: targetingtemplate?.isDynamicCreative,
    budgetTypes: targetingtemplate?.targeting?.budget_type,
    destinationType: targetingtemplate?.targeting?.destinationType,
    templatePlatform: targetingtemplate?.targeting?.platformId,
    isManual:
      targetingtemplate?.targeting?.template?.publisher_platforms?.length > 0 ||
      targetingtemplate?.targeting?.template?.device_platforms?.length > 0,
    cta: targetingtemplate?.targeting?.cta,
    gender: targetingtemplate?.targeting?.template?.genders?.[0] || "All",
    bidStrategy: targetingtemplate?.targeting?.bidStrategy,
    bid: targetingtemplate?.targeting?.bid,
    optimizationGoal: targetingtemplate?.targeting?.optimizationGoal,
    countryGroups:
      targetingtemplate?.targeting?.template?.geo_locations?.countries?.length > 0
        ? []
        : targetingtemplate?.targeting?.template?.geo_locations?.country_groups || ["worldwide"],
    countries: targetingtemplate?.targeting?.template?.geo_locations?.countries,
    excludedCountries: targetingtemplate?.targeting?.template?.excluded_geo_locations?.countries,
    publisherPlatforms: targetingtemplate?.targeting?.template?.publisher_platforms || [],
    devicePlatforms: targetingtemplate?.targeting?.template?.device_platforms || [],
    age: targetingtemplate?.targeting?.template?.age_min
      ? [
          targetingtemplate?.targeting?.template?.age_min,
          targetingtemplate?.targeting?.template?.age_max,
        ]
      : [18, 65],
    objective: targetingtemplate?.targeting?.objective,
    budget: targetingtemplate?.targeting?.budget,
    locales: targetingtemplate?.targeting?.template?.locales,
    scheduledEnd_Date: targetingtemplate?.targeting?.scheduledEndDate
      ? moment(targetingtemplate?.targeting?.scheduledEndDate)
      : undefined,
    scheduledStart_Date: targetingtemplate?.targeting?.scheduledStartDate
      ? moment(targetingtemplate?.targeting?.scheduledStartDate)
      : undefined,
    excludedRegions: targetingtemplate?.targeting?.template?.excluded_geo_locations?.regions || [],
    includedRegions: targetingtemplate?.targeting?.template?.geo_locations?.regions || [],
    pixelType: targetingtemplate?.targeting?.pixelType,
    facebookPositions: targetingtemplate?.targeting?.template?.facebook_positions,
    userOs:
      targetingtemplate?.targeting?.template?.user_os?.length > 0
        ? targetingtemplate?.targeting?.template?.user_os
        : ["All"],
    instagramPositions: targetingtemplate?.targeting?.template?.instagram_positions,
    audienceNetworkPositions: targetingtemplate?.targeting?.template?.audience_network_positions,
    messengerPositions: targetingtemplate?.targeting?.template?.messenger_positions,
  };
  const [fbinputs, setFbInputs] = useState(fbData);
  const [fbInitialStringCopy, setFbInitialStringCopy] = useState(JSON.stringify(fbData));
  // useEffect(() => {
  //   var temp = JSON.stringify(fbinputs);
  //   if (temp !== fbInitialStringCopy) {
  //     setIsPreviewTemplateEdited(true);
  //   } else {
  //     setIsPreviewTemplateEdited(false);
  //   }
  // }, [fbinputs]);
  const {
    name,
    isManual,
    multiCountry,
    multiDevices,
    templatePlatform,
    description,
    budgetTypes,
    gender,
    cta,
    objective,
    countries,
    publisherPlatforms,
    scheduledEnd_Date,
    scheduledStart_Date,
    devicePlatforms,
    age,
    pixelType,
    optimizationGoal,
    bidStrategy,
    countryGroups,
    locales,
    facebookPositions,
    instagramPositions,
    audienceNetworkPositions,
    messengerPositions,
    budget,
    bid,
    destinationType,
    isDynamicCreative,
    excludedCountries,
    regions,
    excludedRegions,
    includedRegions,
    userOs,
  } = fbinputs;
  function handlePublisherPlatforms(publisherPlatforms) {
    let finalPublisherPlatforms = publisherPlatforms;
    if (
      publisherPlatforms?.includes("audience_network") &&
      audienceNetworkPositions?.length === 0
    ) {
      finalPublisherPlatforms = finalPublisherPlatforms?.filter(
        (platform) => platform !== "audience_network"
      );
    }
    if (publisherPlatforms?.includes("messenger") && messengerPositions?.length === 0) {
      finalPublisherPlatforms = finalPublisherPlatforms?.filter(
        (platform) => platform !== "messenger"
      );
    }
    if (publisherPlatforms?.includes("instagram") && instagramPositions?.length === 0) {
      finalPublisherPlatforms = finalPublisherPlatforms?.filter(
        (platform) => platform !== "instagram"
      );
    }
    return finalPublisherPlatforms;
  }
  function onSubmit() {
    let finalSubmission;
    const manualProps = isManual
      ? {
          device_platforms: devicePlatforms,
          publisher_platforms: handlePublisherPlatforms(publisherPlatforms),
          user_os: userOs?.includes("All") ? [] : userOs,
          messenger_positions: publisherPlatforms?.includes("messenger") ? messengerPositions : [],
          audience_network_positions: publisherPlatforms?.includes("audience_network")
            ? audienceNetworkPositions
            : [],
          instagram_positions: publisherPlatforms?.includes("instagram") ? instagramPositions : [],
          facebook_positions: publisherPlatforms?.includes("facebook") ? facebookPositions : [],
          // countryGroups,
        }
      : {};
    if (isTiktokPlatformLaunch) {
      const {
        budgetMode,
        householdIncome,
        placementType,
        spendingPower,
        promotionType,
        promotionTargetType,
        operatingSystems,
        bidType,
        conversionBidPrice,
        templategender,
        countries,
        states,
        cities,
        ageGroups,
        optimizationGoal,
        creativeMaterialMode,
        scheduleEndTime,
        scheduleStartTime,
        scheduleType,
        optimizationEvent,
        cta,
        name,
        placement,
        locales,
        pacing_targeting,
        billingEvent,
        budget,
        bid,
      } = tiktokinputs;
      finalSubmission = {
        bidType,
        name,
        edited: true,
        ...(cta && { cta }),
        budget,
        budgetMode,
        ...(conversionBidPrice &&
          conversionBidPrice > 0 && { conversionBidPrice: parseFloat(conversionBidPrice) }),
        ...(bid && bid > 0 && { bidPrice: parseFloat(bid) }),
        creativeMaterialMode,
        template: {
          age_groups: ageGroups,
          gender: templategender,
          household_income: householdIncome,
          languages: locales,
          location: countries
            .concat(states)
            .concat(cities)
            ?.map((item) => {
              const { parent_id, ...rest } = item;
              return { ...rest, parentId: parent_id };
            }),
          operating_systems: operatingSystems,
          optimizationEvent,
          optimizationGoal,
          pacing: pacing_targeting,
          placement_type: placementType,
          placements: placement,
          promotion_target_type: promotionTargetType,
          spending_power: spendingPower,
          promotion_type: promotionType,
          billing_event: billingEvent,
        },
        scheduleEndTime: scheduleEndTime,
        scheduleStartTime: scheduleStartTime,
        scheduleType,
      };
    } else {
      finalSubmission = {
        name,
        isDynamicCreative,
        targeting: {
          productId: productId,
          description,
          edited: true,
          platformId: templatePlatform,
          bid: parseFloat(bid),
          budget_type: budgetTypes,
          budget,
          cta,
          isManual,
          destinationType,
          scheduledEndDate: scheduledEnd_Date,
          scheduledStartDate: scheduledStart_Date,
          optimizationGoal,
          objective,
          bidStrategy,
          // dsaBeneficiary: beneficiary,
          // dsaPayor: payor,
          pixelType,
          isPersonal: false,
          isScheduled: scheduledStart_Date ? true : false,
          template: {
            age_min: age[0],
            age_max: age[1],
            genders: gender === "All" ? [] : [gender],
            ...(countries?.length > 0
              ? { geo_locations: { countries, regions: includedRegions } }
              : countryGroups?.length > 0 && {
                  geo_locations: { country_groups: countryGroups, regions: includedRegions },
                }),
            locales: locales,
            excluded_geo_locations: {
              countries: excludedCountries,
              ...(productId === PRODUCT_ID_LEADGEN && { regions: excludedRegions }),
            },
            ...manualProps,
          },
        },
      };
    }
    if (showTargetingLaunch) {
      const finalTemplateData = isTiktokPlatformLaunch
        ? {
            ...finalSubmission,

            platform: targetingtemplate?.platform,
          }
        : {
            name,
            multiCountry,
            multiDevices,
            isDynamicCreative,
            productId: productId,
            description,
            edited: true,
            platform: targetingtemplate?.targeting?.platform,
            bid: parseFloat(bid),
            budget_type: budgetTypes,
            budget,
            dof: "OPT_IN",
            cta,
            isManual,
            scheduledEndDate: scheduledEnd_Date,
            scheduledStartDate: scheduledStart_Date,
            optimizationGoal,
            objective,
            bidStrategy,
            pixelType,
            destinationType,
            isPersonal: false,
            isScheduled: scheduledStart_Date ? true : false,
            template: {
              age_min: age[0],
              age_max: age[1],
              genders: gender === "All" ? [] : [gender],
              ...(countries?.length > 0
                ? {
                    geo_locations: {
                      countries,
                      ...(productId === PRODUCT_ID_LEADGEN &&
                        includedRegions && { regions: includedRegions }),
                    },
                  }
                : countryGroups?.length > 0 && {
                    geo_locations: {
                      country_groups: countryGroups,
                      ...(includedRegions && { regions: includedRegions }),
                    },
                  }),
              locales: locales,
              excluded_geo_locations: {
                countries: excludedCountries,
                ...(productId === PRODUCT_ID_LEADGEN &&
                  excludedRegions && { regions: excludedRegions }),
              },
              ...manualProps,
            },
          };
      setIsTargetEdited(true);
      setTargettingTemplateData(finalTemplateData);
      if (
        campaignObject?.length > 0 ||
        (chooseExistingTemplate === CREATE_NEW_TEMPLATE && chooseDraftOrNew !== CONTINUES_DRAFT)
      ) {
        initialSetCampaign({
          ...initialSetCampaignProps,
          targettingTemplateData: finalTemplateData,
        });
        setFormStep(1);

        //JUGGAD ADDED  setTimeout - for quick fix have to remove this
        // whsen set setIsTargetEdited and setTargettingTemplateData that render and initialSetCampaign does't work
      }
    } else {
      let country;
      if (
        !campaignObject[campaignIndex]?.specialAdCategories?.includes("NONE") &&
        countries?.length > 0
      ) {
        country = campaignObject[campaignIndex]?.specialAdCategoryCountry;
        country.push(...countries);
      }
      handleCampaignAdsetAdset(
        campaignIndex,
        targetingtemplate?.adSetindex,
        finalSubmission,
        country
      );
      onClose();
    }
  }
  const formProps = {
    onClose: onClose,
    onSubmit: onSubmit,
    inputs: fbinputs,
    tiktokinputs: tiktokinputs,
    setTiktokInputs: setTiktokInputs,
    setInputs: setFbInputs,
    dateFormat: dateFormat,
    // defaultData: defaultData,
    defaultselectedDate: defaultselectedDate,
    productId: productId,
    showTargetingLaunch: showTargetingLaunch,
    setTargettingTemplateData: setTargettingTemplateData,
    templatePlatforms: isTiktokPlatformLaunch
      ? targetingtemplate?.platform?.id
      : targetingtemplate?.targeting?.platform?.id,
    // countries_group={countries_group}
    platformsData: platformsData,
    setFbInitialStringCopy,
  };
  return (
    <>
      {isTiktokPlatformLaunch ? (
        <TiktokForm
          {...props}
          {...formProps}
          launch
          cType={cType}
          isSpecialIndustries={campaignObject?.[campaignIndex]?.specialIndustries?.length > 0}
        />
      ) : (
        <FacebookForm
          {...props}
          {...formProps}
          excludedRegion={excludedRegion}
          includedRegion={includedRegion}
          cType={cType}
          advertiserId={advertiserId}
          setExcludedRegion={setExcludedRegion}
          setIncludedRegion={setIncludedRegion}
          launch
          setChooseExistingTemplate={setChooseExistingTemplate}
          platformId={platformId}
          campaignBidStrategy={campaignBidStrategy}
        />
      )}
    </>
  );
}
export default UpdateForm;
