import { Form, Row, Col, Radio, DatePicker, Collapse, Tabs, Checkbox, Select, Tooltip } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";
import { Formik } from "formik";
import ActionIconRender from "components/ActionIconRender";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import useUploadDocument from "../../CreateMoodBoard/useUploadDocument";
import { useState } from "react";
import parse from "html-react-parser";
import LookButton from "components/LookButton";
import ReactQuillForm from "components/ReactQuillForm";
import cx from "cx";
import ScriptTab from "../../CreateMoodBoard/ScriptTab";
import {
  MOODBOARD_TYPE_ANGLES,
  MOODBOARD_TYPE_COPY_POINTS,
  MOODBOARD_TYPE_CTA,
  MOODBOARD_TYPE_HOOKS,
  MOODBOARD_TYPE_LANGUAGE,
  MOODBOARD_TYPE_PAIN_POINTS,
} from "pages/dashboardPages/Creatives/creativesEnum";
import {
  GET_MOODBOARD_ANGLES,
  GET_MOODBOARD_COPY_POINTS,
  GET_MOODBOARD_CTA,
  GET_MOODBOARD_HOOKS,
  GET_MOODBOARD_PAIN_POINTS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import { useSelector } from "react-redux";

export default function MainForm(props) {
  const {
    initialValues,
    formValues,
    id,
    isCreativeDirector = false,
    isCreativeRequest,
    productId,
    onSubmit = () => {},
    isBusinessDeveloper,
    onCloseDrawer = () => {},
    validationSchema,
    loading = false,
    requestStep = false,
    creativesStep = false,
    umsCountries,
    umsLanguages,
    agencyUsers,
    scripts_data,
    cta_data,
    productList,
    handleRequestForm = () => {},
    setVisibleCompliantModal = () => {},
    document,
    setDocuments,
    scripts_data_loading = false,
    cta_data_loading = false,
    isCategory,
    scriptsFetch,
    fetchVsContent = () => {},
    ctaFetch,
    categories,
    getDocumentResponse = () => {},
    fetchSasCategory = () => {},
    isRequestView,
    vs_content_loading,
    setFileNames = () => {},
    sas_loading,
    fetchLeadgen = () => {},
    leadgen_loading,
    painPointsFetch = () => {},
    hooksFetch = () => {},
    anglesFetch = () => {},
    copyPointsFetch = () => {},
    hooks_data,
    hooks_data_loading,
    angles_data,
    angles_data_loading,
    painPoints_data,
    painPoints_data_loading,
    copyPoints_data,
    copyPoints_data_loading,
  } = props;

  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeKey, setActiveKey] = useState(null);
  const [selectedRows, setSelectedRowsData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const tabProps = {
    id,
    productId,
    values: selectedRows,
    isRequestForm: true,
    activeKey,
  };

  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const onChange = (key, data) => {
    setActiveTab(key);
    // setValues({ ...data, isEdited: isEdited });
  };
  const editableCreativesList = [
    { name: "Video/Image", value: "Video/Image" },
    { name: "Audio", value: "Audio" },
    { name: "Script", value: "Script" },
  ];
  const tabContent = [
    {
      key: "1",
      label: "Copy Points",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_COPY_POINTS}
          query={GET_MOODBOARD_COPY_POINTS}
          apiType="ctGetCopyPoints"
          {...tabProps}
          paramstype="copyPoints"
          label="Copy Points"
        />
      ),
    },
    {
      key: "2",
      label: "Angles",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_ANGLES}
          query={GET_MOODBOARD_ANGLES}
          apiType="ctGetAngles"
          {...tabProps}
          paramstype="angles"
          label="Angles"
        />
      ),
    },
    {
      key: "3",
      label: "Hooks",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_HOOKS}
          query={GET_MOODBOARD_HOOKS}
          apiType="ctGetHooks"
          {...tabProps}
          paramstype="hooks"
          label="Hooks"
        />
      ),
    },
    {
      key: "4",
      label: "Pain Points",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_PAIN_POINTS}
          query={GET_MOODBOARD_PAIN_POINTS}
          apiType="ctGetPainPoints"
          {...tabProps}
          paramstype="painPoints"
          label="Pain Points"
        />
      ),
    },
    {
      key: "5",
      label: "CTA",
      children: (
        <ScriptTab
          type={MOODBOARD_TYPE_CTA}
          query={GET_MOODBOARD_CTA}
          apiType="ctGetCTA"
          {...tabProps}
          paramstype="ctas"
          label="CTA"
        />
      ),
    },
    {
      key: "6",
      label: "Language",
      children: (
        <ScriptTab
          query={GET_MOODBOARD_CTA}
          type={MOODBOARD_TYPE_LANGUAGE}
          {...tabProps}
          paramstype="language"
          label="Language"
        />
      ),
    },
  ];

  const handleCollapseChange = (keys, data) => {
    setActiveKey(keys?.[0]);
    setIsVisible(false);
    setSelectedRowsData({
      ...data,
      language: [data?.language?.code],
      angle: data?.angles?.map((angle) => angle?.id),
    });
  };
  const { uploadView, commonLoading } = useUploadDocument({
    getDocumentResponse,
    setFileNames,
    setDocuments,
    document,
    actionType: "REQUESTFORM",
  });

  function creativeDetailsForm() {
    const creativeTypeOptions = [
      {
        name: "Image",
        value: CREATIVE_TYPE_IMAGE,
      },
      {
        name: "Video",
        value: CREATIVE_TYPE_VIDEO,
      },
    ];
    const aspectRatioOptions = [
      {
        name: "16:9",
        value: "16:9",
      },
      {
        name: "9:16",
        value: "9:16",
      },
      {
        name: "4:5",
        value: "4:5",
      },
      {
        name: "1:1",
        value: "1:1",
      },
      {
        name: "3:2",
        value: "3:2",
      },
    ];

    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form layout="vertical">
              <Row gutter={12}>
                <Col span={24}>
                  <LookTextInput
                    formik
                    name="requestName"
                    label="Request Name"
                    placeholder="Enter Request Name"
                    className="look-form-input"
                  />
                </Col>
              </Row>
              {isCategory && (
                <>
                  <Row gutter={12}>
                    <Col span={24}>
                      <SelectInput
                        formik
                        name="productId"
                        label="Product *"
                        search
                        placeholder="Select products"
                        className="look-form-input"
                        value={values?.productId?.toString()}
                        onChange={(name, value) => {
                          setFieldValue("productId", parseInt(value));
                          if (parseInt(value) === PRODUCT_ID_SAS) {
                            fetchSasCategory();
                          } else if (parseInt(value) === PRODUCT_ID_LEADGEN) {
                            fetchLeadgen();
                          }
                        }}
                        options={productList?.map((prod) => ({
                          name: prod?.name,
                          value: prod?.id,
                        }))}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {
                <>
                  {((isCategory && values?.productId === PRODUCT_ID_VS) ||
                    productId === PRODUCT_ID_LEADGEN) && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          formik
                          name="advertiser"
                          label="Advertiser"
                          placeholder="Select Advertiser"
                          className="look-form-input"
                          search
                          loader={vsAdvertiser_loading}
                          value={values?.advertiser}
                          onChange={(name, value) => {
                            setFieldValue("advertiser", value);
                            if (values?.productId === PRODUCT_ID_VS) {
                              fetchVsContent({ variables: { advertiser_id: value } });
                            }
                          }}
                          options={vsAdvertiser?.map(({ id, name }) => ({ name, value: id }))}
                        />
                      </Col>
                    </Row>
                  )}
                  {isCategory &&
                    ((values?.advertiser && values?.productId === PRODUCT_ID_VS) ||
                      values?.productId) && (
                      <Row gutter={12}>
                        <Col span={24}>
                          <SelectInput
                            formik
                            name="categoryId"
                            label="Category *"
                            search
                            placeholder="Select Category"
                            className="look-form-input"
                            options={categories}
                            value={values?.categoryId}
                            loader={vs_content_loading || sas_loading || leadgen_loading}
                            onChange={(name, value) => {
                              setFieldValue(name, value);
                              setFieldValue(
                                "categoryName",
                                categories?.find((category) => category?.value === value)?.name
                              );
                              scriptsFetch({
                                variables: {
                                  categoryId: value,
                                  productId: values?.productId,
                                },
                              });
                              ctaFetch({
                                variables: {
                                  categoryId: value,
                                  productId: values?.productId,
                                },
                              });
                              painPointsFetch({
                                variables: {
                                  categoryId: value,
                                  productId: values?.productId,
                                },
                              });
                              anglesFetch({
                                variables: {
                                  categoryId: value,
                                  productId: values?.productId,
                                },
                              });
                              copyPointsFetch({
                                variables: {
                                  categoryId: value,
                                  productId: values?.productId,
                                },
                              });
                              hooksFetch({
                                variables: {
                                  categoryId: value,
                                  productId: values?.productId,
                                },
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                </>
              }
              {isCreativeRequest && (
                <>
                  {/* <Row gutter={12}>
                    <Col span={24}>
                      <SelectInput
                        formik
                        name="advertiser"
                        label="Advertiser"
                        placeholder="Select Advertiser"
                        className="look-form-input"
                        onChange={setFieldValue}
                        //   options={}
                      />
                    </Col>
                  </Row> */}
                  {(values?.productId === PRODUCT_ID_LEADGEN ||
                    productId === PRODUCT_ID_LEADGEN) && (
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          formik
                          name="offer"
                          label="Offer"
                          search
                          placeholder="Select Offer"
                          className="look-form-input"
                          onChange={setFieldValue}
                          //   options={}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row gutter={12}>
                    <Col span={24}>
                      <LookTextInput
                        formik
                        name="targetedAudience"
                        label="Targeted Audience"
                        placeholder="Enter Target Audience"
                        className="look-form-input"
                      />
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <SelectInput
                        formik
                        name="country"
                        label="Country"
                        placeholder="Select Country"
                        search
                        className="look-form-input"
                        onChange={setFieldValue}
                        value={values?.country}
                        options={umsCountries?.map((data) => ({
                          name: data?.name,
                          value: data?.id,
                        }))}
                      />
                    </Col>
                    <Col span={12}>
                      <SelectInput
                        formik
                        name="language"
                        label="Language"
                        search
                        placeholder="Select Language"
                        className="look-form-input"
                        onChange={setFieldValue}
                        value={values?.language}
                        options={umsLanguages?.map((data) => ({
                          name: data?.name,
                          value: data?.id,
                        }))}
                      />
                    </Col>
                  </Row>
                  <Row gutter={12}>
                    <Col span={12}>
                      <SelectInput
                        formik
                        name="cta"
                        search
                        label="CTA"
                        placeholder="Select CTA"
                        loader={cta_data_loading}
                        className="look-form-input"
                        value={values?.cta}
                        onChange={setFieldValue}
                        options={cta_data?.ctGetCTAs?.data?.map(({ id, name }, index) => ({
                          name: name,
                          value: id,
                        }))}
                      />
                    </Col>

                    <Col span={12}>
                      <LookTextInput
                        formik
                        type="number"
                        name="variations"
                        min={0}
                        label="Variations"
                        placeholder="Select Variations"
                        className="look-form-input"
                        //   options={}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row gutter={12}>
                <Col span={24}>
                  <SelectInput
                    formik
                    name="creativeType"
                    label="Creative Type *"
                    placeholder="Select Creative Type"
                    search
                    className="look-form-input"
                    value={values?.creativeType}
                    options={creativeTypeOptions}
                    onChange={setFieldValue}
                  />
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={24}>
                  <SelectInput
                    formik
                    name="aspectRatio"
                    search
                    label="Aspect Ratio *"
                    placeholder="Select Aspect Ratio"
                    className="look-form-input"
                    value={values?.aspectRatio}
                    options={aspectRatioOptions}
                    onChange={setFieldValue}
                  />
                </Col>
              </Row>
              {!isCreativeRequest && (
                <div className="mb-20 ">
                  <p>Editable</p>
                  <Checkbox.Group
                    onChange={(e) => setFieldValue("editable", e)}
                    value={values?.editable}
                  >
                    {editableCreativesList?.map((data) => (
                      <Checkbox value={data?.value}>{data?.name}</Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              )}
              <Row gutter={12}>
                <Col span={24}>
                  <LookTextInput
                    formik
                    name="referenceLink"
                    label="Reference Link"
                    placeholder="Enter Reference Link"
                    className="look-form-input"
                  />
                </Col>
              </Row>
              {isCreativeRequest &&
                (values?.creativeType !== CREATIVE_TYPE_IMAGE ? (
                  <>
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          label="Scripts"
                          formik
                          multiple
                          placeholder={"Scripts"}
                          name="scripts"
                          search
                          className="look-form-input"
                          value={values?.scripts}
                          loader={scripts_data_loading}
                          options={scripts_data?.ctGetScripts?.data?.map(({ id, name }, index) => ({
                            name: name,
                            value: id,
                          }))}
                          onChange={setFieldValue}
                        />
                      </Col>
                    </Row>

                    <div className="mb-20">
                      {scripts_data?.ctGetScripts?.data
                        ?.filter((data) => values?.scripts?.includes(data?.id))
                        ?.map((moodBoardData, index) => (
                          <div key={index} className={cx("moodboardData  ant-css-overide")}>
                            <div className="d-flex flex-grow-1">
                              <Collapse
                                className="flex-grow-1"
                                collapsible="header"
                                accordion
                                activeKey={activeKey}
                                onChange={(e) => handleCollapseChange(e, moodBoardData)}
                              >
                                <Collapse.Panel header={moodBoardData?.name} key={index.toString()}>
                                  <>
                                    {moodBoardData?.description !== null && (
                                      <>
                                        <p className="moodBoardDesc">
                                          {isVisible
                                            ? moodBoardData?.description
                                            : moodBoardData?.description?.substring(0, 170)}
                                        </p>
                                        {!isVisible && moodBoardData?.description?.length > 190 && (
                                          <p
                                            className="moodBoardDesc"
                                            onClick={() => setIsVisible(true)}
                                          >
                                            ...more
                                          </p>
                                        )}
                                      </>
                                    )}
                                    <div className="d-flex" style={{ alignItems: "center" }}>
                                      <div style={{ paddingBottom: "20px" }}>
                                        <Tabs
                                          className={cx("scriptTabs", { isEdit })}
                                          defaultActiveKey="1"
                                          items={tabContent}
                                          onChange={(e) => onChange(e, moodBoardData)}
                                        />
                                      </div>
                                    </div>
                                  </>
                                </Collapse.Panel>
                              </Collapse>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          label="Hooks"
                          formik
                          multiple
                          placeholder={"Hooks"}
                          name="hooks"
                          search
                          className="look-form-input"
                          value={values?.hooks}
                          loader={hooks_data_loading}
                          options={hooks_data?.ctGetHooks?.data?.map(({ id, name }, index) => ({
                            name: name,
                            value: id,
                          }))}
                          onChange={setFieldValue}
                        />
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          label="Copy Points"
                          formik
                          multiple
                          placeholder={"Copy Points"}
                          name="copyPoints"
                          search
                          className="look-form-input"
                          value={values?.copyPoints}
                          loader={copyPoints_data_loading}
                          options={copyPoints_data?.ctGetCopyPoints?.data?.map(
                            ({ id, name }, index) => ({
                              name: name,
                              value: id,
                            })
                          )}
                          onChange={setFieldValue}
                        />
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          label="Pain Points"
                          formik
                          multiple
                          placeholder={"Pain Points"}
                          name="painPoints"
                          search
                          className="look-form-input"
                          value={values?.painPoints}
                          loader={painPoints_data_loading}
                          options={painPoints_data?.ctGetPainPoints?.data?.map(
                            ({ id, name }, index) => ({
                              name: name,
                              value: id,
                            })
                          )}
                          onChange={setFieldValue}
                        />
                      </Col>
                    </Row>
                    <Row gutter={12}>
                      <Col span={24}>
                        <SelectInput
                          label="Angles"
                          formik
                          multiple
                          placeholder={"Angles"}
                          name="angles"
                          search
                          className="look-form-input"
                          value={values?.angles}
                          loader={angles_data_loading}
                          options={angles_data?.ctGetAngles?.data?.map(({ id, name }, index) => ({
                            name: name,
                            value: id,
                          }))}
                          onChange={setFieldValue}
                        />
                      </Col>
                    </Row>
                  </>
                ))}

              {uploadView()}
              <div className="d-flex mt-30 mb-10" style={{ alignItems: "center" }}>
                <div className="mr-20" style={{ fontWeight: "600" }}>
                  Description
                </div>
                <LookButton
                  iconType={isEdit ? "plus" : "edit"}
                  onClick={() => setIsEdit((pre) => !pre)}
                >
                  {isEdit ? "Save" : "Edit"}
                </LookButton>
              </div>
              {isEdit && (
                <>
                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item className="look-form-input">
                        <ReactQuillForm
                          placeholder="Enter description"
                          className="drawer-quill"
                          serviceName="Tracker"
                          defaultFormat={[
                            "header",
                            "font",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "list",
                            "align",
                            "bullet",
                            "indent",
                          ]}
                          isDefaultModule
                          value={values?.description}
                          onChange={(e) => {
                            setFieldValue("description", e);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {!isEdit && values?.description && parse(values?.description)}

              <Form.Item>
                <Row gutter={12}>
                  <Col span={12}>
                    <LookButton
                      htmlType="cancel"
                      block
                      type="default"
                      onClick={() => {
                        if (isBusinessDeveloper) {
                          setVisibleCompliantModal(true);
                        } else {
                          onCloseDrawer();
                        }
                      }}
                      disabled={loading || commonLoading}
                    >
                      {loading ? <Loader /> : isBusinessDeveloper ? "Non Compliant" : "Cancel"}
                    </LookButton>
                  </Col>
                  <Col span={12}>
                    <LookButton
                      htmlType="submit"
                      block
                      type="primary"
                      onClick={() => {
                        if (isBusinessDeveloper) {
                          setVisibleCompliantModal(true);
                        } else {
                          handleSubmit();
                        }
                      }}
                      disabled={loading || commonLoading}
                    >
                      {loading ? <Loader /> : isBusinessDeveloper ? "Compliant" : "Next"}
                    </LookButton>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </>
    );
  }
  function requestDetailsForm() {
    const contentData = [
      { label: "Category", value: formValues?.categoryName },
      {
        label: "Advertiser",
        value: vsAdvertiser.find((data) => data?.id === formValues?.advertiser)?.name,
      },
      { label: "Offer", value: formValues?.offer },
      { label: "Creative type", value: formValues?.creativeType },
      { label: "Aspect ratio", value: formValues?.aspectRatio },
      { label: "Ref.link", value: formValues?.referenceLink, type: "LINK" },
      { label: "Attachments", value: formValues?.document, type: "DOCUMENT" },
      {
        label: "Country",
        value: umsCountries?.find((country) => country?.id === formValues?.country)?.name,
      },
      {
        label: "Language",
        value: umsLanguages?.find((lang) => lang?.id === formValues?.language)?.name,
      },
      { label: "Variations", value: formValues?.variations },
      { label: "Deadline", value: formValues?.deadline },
      { label: "Priority", value: formValues?.priority },

      { label: "Targeted audience", value: formValues?.targetedAudience },
      {
        label: "CTA",
        value: cta_data?.ctGetCTAs?.data?.find((cta) => cta.id === formValues?.cta)?.name,
        type: "TAG",
      },
      {
        label: "Editable",
        value: formValues?.editable?.map((x) => x),
        type: "TAG",
      },
    ];
    const priorityList = [
      { name: "High", value: "HIGH" },
      { name: "Medium", value: "MEDIUM" },
      { name: "Low", value: "LOW" },
    ];

    return (
      <>
        {/* <pre>{JSON.stringify(formValues, null, 2)}</pre> */}
        <div
          className="px-10 py-15 mb-30"
          style={{ border: "1px solid #404040", borderRadius: "6px", backgroundColor: "#303030" }}
        >
          <div className="d-flex mb-15" style={{ alignItems: "center" }}>
            <div className="mr-15" style={{ fontWeight: "600", fontSize: "16px" }}>
              {formValues?.requestName}
              {/* {isCreativeRequest ? "Creative Details" : "Template Details"} */}
            </div>
            <div>
              <ActionIconRender iconType="edit" />
            </div>
          </div>
          <Row gutter={[12, 12]}>
            {contentData
              ?.filter?.((data) => data?.value || data?.value?.length > 0)
              ?.map(({ label, value, type }) => {
                let remainingItems = [];
                if (type === "DOCUMENT") {
                  remainingItems = value?.slice(2);
                }
                const url =
                  type === "LINK" && !value.startsWith("http:") && !value.startsWith("https:")
                    ? `//${value}`
                    : value;

                return (
                  <Col span={8}>
                    <div className={cx("text-container mr-5", { flex: type === "DOCUMENT" })}>
                      {label}:
                      {type === "TAG" ? (
                        <Select
                          className="selectFilter select-tag-view w-100"
                          mode="tags"
                          value={value}
                          removeIcon
                          onChange={() => ({})}
                          maxTagCount={2}
                          showArrow={false}
                        />
                      ) : type === "DOCUMENT" ? (
                        <div className="pl-5 d-flex">
                          {value.map((data, index) => (
                            <div
                              className="ml-5"
                              style={{
                                border: "1px solid #504f4f",
                                borderRadius: "4px",
                                background: "#404040",
                                padding: "6px 4px",
                              }}
                            >
                              {index < 2 ? (
                                <Tooltip title={data?.name}>
                                  <ActionIconRender
                                    style={{ fontSize: "13px" }}
                                    iconType="file-icon"
                                  />
                                </Tooltip>
                              ) : index === 2 ? (
                                <Tooltip
                                  title={remainingItems.map((item) => item?.name)?.join(", ")}
                                >
                                  <div style={{ fontSize: "13px", color: "white" }}>
                                    +{value.length - 2}
                                  </div>
                                </Tooltip>
                              ) : null}
                              {/* <ActionIconRender style={{ fontSize: "13px" }} iconType="file-icon" /> */}
                            </div>
                          ))}
                        </div>
                      ) : type === "LINK" ? (
                        <a href={url} target="_blank" rel="noreferrer">
                          {value}
                        </a>
                      ) : (
                        value
                      )}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
        {!isRequestView && (
          <>
            <div className="mr-15 mb-10" style={{ fontWeight: "600", fontSize: "16px" }}>
              Request Details
            </div>
            <Formik initialValues={initialValues} onSubmit={handleRequestForm} enableReinitialize>
              {({ handleSubmit, values, setFieldValue }) => (
                <Form layout="vertical">
                  <Row gutter={12}>
                    <Col span={24}>
                      <SelectInput
                        formik
                        search
                        name="assigned"
                        label="Assign to"
                        placeholder="Assign User"
                        className="look-form-input"
                        value={values?.assigned}
                        options={agencyUsers?.map(({ name, id }) => ({ name, value: id }))}
                        onChange={setFieldValue}
                      />
                    </Col>
                  </Row>
                  {isCreativeDirector && (
                    <>
                      <div className="mb-10 ">
                        <p>Priority</p>
                        <Radio.Group
                          onChange={(e) => setFieldValue("priority", e?.target?.value)}
                          value={values?.priority}
                          name="gender"
                        >
                          <span className="d-flex">
                            {priorityList.map(({ value, name }) => (
                              <Radio value={value}>{name}</Radio>
                            ))}
                          </span>
                        </Radio.Group>
                      </div>
                      <Form.Item label="Expected due date" className="look-form-input">
                        <DatePicker
                          showTime={{ format: "HH:mm" }}
                          showNow={false}
                          format="YYYY-MM-DD HH:mm"
                          value={values?.expectedDueDate}
                          onChange={(date, dateString) => {
                            setFieldValue("expectedDueDate", date);
                          }}
                        />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item>
                    <Row gutter={12}>
                      <Col span={12}>
                        <LookButton
                          block
                          onClick={() => {
                            if (isBusinessDeveloper) {
                              setVisibleCompliantModal(true);
                            } else {
                              onCloseDrawer();
                            }
                          }}
                          // loading={loading}
                        >
                          {loading ? <Loader /> : isBusinessDeveloper ? "Non Compliant" : "Cancel"}
                        </LookButton>
                      </Col>
                      <Col span={12}>
                        <LookButton
                          htmlType="submit"
                          block
                          type="primary"
                          onClick={() => {
                            if (isBusinessDeveloper) {
                              setVisibleCompliantModal(true);
                            } else {
                              handleSubmit();
                            }
                          }}
                          // loading={loading}
                        >
                          {loading ? (
                            <Loader />
                          ) : isBusinessDeveloper ? (
                            "Compliant"
                          ) : (
                            "Raise Request"
                          )}
                        </LookButton>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </>
        )}
      </>
    );
  }
  return (
    <>
      {creativesStep && creativeDetailsForm()}
      {requestStep && requestDetailsForm()}
      {/* <pre>{JSON.stringify(formValues, null, 2)}</pre> */}
    </>
  );
}
