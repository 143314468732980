import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PLATFORM_TYPE_FACEBOOK, SERVICE_TYPE_SAS } from "shared/enum/launchKeysAndEnum";
import { CREATE_LAUNCH_TEMPLATE } from "shared/gql/gqlSchema/launchGql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import { CREATE_TIKTOK_LAUNCH_TEMPLATE } from "shared/gql/gqlSchema/launchGql";
import { openNotification } from "utils";
import MainForm from "../MainForm";

function AddForm(props) {
  const {
    onClose = () => {},
    refetch,
    tiktokrefetch = () => {},
    productId,
    platformsData,
    step,
    setStep = () => {},
  } = props;
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};
  const dateFormat = "YYYY-MM-DD";
  const defaultselectedDate = moment(dateRangeTimezone?.rangeDates[0]);
  const [excludedRegion, setExcludedRegion] = useState([]);
  const [includedRegion, setIncludedRegion] = useState([]);
  const defaultData = defaultselectedDate.format(dateFormat);
  // const { apiCall: launchConfigFetch = () => {}, refetch: launchConfigRefetch } = useApiToStore(
  //   API_STORE_F360_LAUNCH_CONFIG
  // );
  // const { apiCall: targetingCountriesFetch = () => {}, refetch: targetingCountriesRefetch } =
  //   useApiToStore(API_STORE_F360_TARGETING_COUNTRIES);

  // const { apiCall: targetingLocalesFetch = () => {}, refetch: targetingLocalesRefetch } =
  //   useApiToStore(API_STORE_TARGETING_LOCALES);

  // useEffect(() => {
  //   launchConfigFetch();
  //   targetingCountriesFetch();
  //   targetingLocalesFetch();
  // }, []);

  const [templatePlatforms, setTemplatePlatforms] = useState("");

  const [fbinputs, setFbInputs] = useState({
    name: "",
    description: "",
    excludedRegions: [],
    regions: [],
    includedRegions: [],
    budgetTypes: "DAILY",
    cta: "",
    // templatePlatform: platformsData?.templatePlatforms?.filter(
    //   (item) => item.name === "facebook"
    // )[0]?.id,
    isManual: false,

    gender: "All",
    bidStrategy: "",
    optimizationGoal: "",
    countries: [],
    publisherPlatforms: [],
    devicePlatforms: [],
    age: [18, 65],
    countryGroups: ["worldwide"],
    objective: "",
    budget: 0,
    locales: [],
    excludedCountries: [],
    userOs: ["All"],
    destinationType: "WEBSITE",
    // scheduledEnd_Date: defaultData,
    // scheduledStart_Date: defaultData,
    bid: 0,
    facebookPositions: [],
    instagramPositions: [],
    audienceNetworkPositions: [],
    messengerPositions: [],
  });
  const [tiktokinputs, setTiktokInputs] = useState({
    name: "",
    budgetMode: [],
    // templatePlatform: [],
    householdIncome: [],
    placementType: "",
    spendingPower: "",
    promotionType: "",
    promotionTargetType: "",
    operatingSystems: [],
    bidType: "",
    conversionBidPrice: 0,
    templategender: [],
    cta: "",
    objective: "",
    countries: [],
    states: [],
    cities: [],
    ageGroups: [],
    billingEvent: "",
    optimizationGoal: "",
    pacing_targrting: "",
    // countryGroups,
    placement: [],
    locales: [1001],

    budget: parseFloat(0),
    bid: "",
  });

  const {
    name,
    isManual,
    templatePlatform,
    description,
    budgetTypes,
    gender,
    cta,
    objective,
    countries,
    publisherPlatforms,
    scheduledEnd_Date,
    scheduledStart_Date,
    devicePlatforms,
    age,
    optimizationGoal,
    bidStrategy,
    countryGroups,
    locales,
    facebookPositions,
    instagramPositions,
    audienceNetworkPositions,
    messengerPositions,
    budget,
    bid,
    excludedRegions,
    regions,
    includedRegions,
    destinationType,
    userOs,
    excludedCountries,
  } = fbinputs;
  const manualProps = isManual
    ? {
        publisherPlatforms,
        userOs: userOs?.includes("All") ? [] : userOs,
        messengerPositions: publisherPlatforms.includes("messenger") ? messengerPositions : [],
        audienceNetworkPositions: publisherPlatforms.includes("audience_network")
          ? audienceNetworkPositions
          : [],
        instagramPositions: publisherPlatforms.includes("instagram") ? instagramPositions : [],
        facebookPositions: publisherPlatforms.includes("facebook") ? facebookPositions : [],
        countryGroups,
      }
    : {};

  function onSubmit() {
    // const finalRegion = region
    //   ?.filter((i) => regions?.includes(i.key))
    //   ?.map((i) => {
    //     return { key: i.key, name: i.name };
    //   });

    // const finalExcludedRegion = region
    //   ?.filter((i) => excludedRegions?.includes(i.key))
    //   ?.map((i) => {
    //     return { key: i.key, name: i.name };
    //   });

    // const finalSubmission = {
    //   name,
    //   productId: productId,
    //   description,
    //   cta,
    //   platformId: templatePlatform,
    //   optimizationGoal,
    //   bid: parseFloat(bid),
    //   objective,
    //   budget_type: budgetTypes,
    //   budget,
    //   bidStrategy,
    //   scheduledEndDate: scheduledEnd_Date,
    //   scheduledStartDate: scheduledStart_Date,
    //   template: {
    //     ageMin: age[0],
    //     ageMax: age[1],
    //     genders: gender === "All" ? [] : [gender],
    //     geoLocations: {
    //       countries,
    //       // ...(productId === PRODUCT_ID_LEADGEN && { regions: finalRegion }),
    //     },
    //     locales: locales,
    //     excludedGeoLocations: {
    //       countries: excludedCountries,
    //       ...(productId === PRODUCT_ID_LEADGEN && { regions: excludedRegions }),
    //     },
    //     devicePlatforms,
    //     countryGroups,
    //     ...manualProps,
    //   },
    // };
    if (
      platformsData?.templatePlatforms?.filter((item) => item?.id === templatePlatforms)[0]
        ?.name === PLATFORM_TYPE_FACEBOOK
    ) {
      const finalSubmission = {
        name,
        productId: productId,
        description,
        cta,
        platformId: templatePlatforms,
        optimizationGoal,
        bid: parseFloat(bid),
        objective,
        budget_type: budgetTypes,
        budget,
        bidStrategy,
        destinationType,
        scheduledEndDate: scheduledEnd_Date,
        scheduledStartDate: scheduledStart_Date,
        template: {
          ageMin: age[0],
          ageMax: age[1],
          genders: gender === "All" ? [] : [gender],
          ...(countries?.length > 0
            ? { geoLocations: { countries, regions: includedRegions } }
            : countryGroups?.length > 0 && {
                geoLocations: { countryGroups: countryGroups, regions: includedRegions },
              }),
          locales: locales,
          excludedGeoLocations: { countries: excludedCountries, regions: excludedRegions },
          devicePlatforms,
          ...manualProps,
        },
      };

      // Submit the form data
      addLaunchTemplate({ variables: { template_input: finalSubmission } });
    } else {
      const {
        name,
        budgetMode,
        templatePlatform,
        billingEvent,
        householdIncome,
        placementType,
        spendingPower,
        promotionType,
        promotionTargetType,
        operatingSystems,
        bidType,
        conversionBidPrice,
        templategender,
        cta,
        objective,
        countries,
        states,
        cities,
        ageGroups,
        optimizationGoal,
        optimizationEvent,
        // countryGroups,
        placement,
        pacing_targeting,
        locales,

        budget,
        bid,
      } = tiktokinputs;

      // const finalLocationId = countries?.flatMap((item) => {
      //   const existingIndex = states?.findIndex((states) => item?.id === states?.parentId);
      //   if (existingIndex === -1) {
      //     return { id: item?.id, level: "countries", name: item?.name, parentId: "" };
      //   } else {
      //     return states
      //       ?.filter((i) => i?.parentId === item?.id)
      //       ?.flatMap((states) => {
      //         const isExistingIndex = cities?.findIndex(
      //           (cities) => cities?.parentId === states?.id
      //         );
      //         if (isExistingIndex === -1) {
      //           return {
      //             id: states?.id,
      //             level: "states",
      //             name: states?.name,
      //             parentId: states?.parentId,
      //           };
      //         } else {
      //           return cities
      //             ?.filter((item) => item?.parentId === states?.id)
      //             ?.flatMap((cities) => ({
      //               id: cities?.id,
      //               level: "cities",
      //               parentId: cities?.parentId,
      //               name: cities?.name,
      //             }));
      //         }
      //       });
      //   }
      // });

      const finalSubmission = {
        name,
        productId: productId,
        ...(cta && { cta }),
        budgetMode,
        bidType,
        platformId: templatePlatforms,
        optimizationGoal,
        // objective,
        budget,
        pacing: pacing_targeting,
        optimizationEvent,
        ...(conversionBidPrice &&
          conversionBidPrice > 0 && { conversionBidPrice: parseFloat(conversionBidPrice) }),
        ...(bid && bid > 0 && { bidPrice: parseFloat(bid) }),
        template: {
          ...(householdIncome?.length > 0 && { householdIncome }),
          ageGroups,
          gender: templategender,
          ...(placementType && { placementType }),
          ...(spendingPower && { spendingPower }),
          ...(promotionType && { promotionType }),
          ...(placement && { placements: placement }),
          ...(promotionTargetType && { promotionTargetType }),
          ...(billingEvent && { billingEvent }),
          ...(operatingSystems && { operatingSystems }),
          location: countries
            .concat(states)
            .concat(cities)
            ?.map((item) => {
              const { parent_id, ...rest } = item;
              return { ...rest, parentId: parent_id };
            }),
          languages: locales,
        },
      };
      addTikTokLaunchTemplate({ variables: { templateInput: finalSubmission } });
    }
  }
  const [addLaunchTemplate] = useMutation(CREATE_LAUNCH_TEMPLATE, {
    onCompleted: () => {
      openNotification({ type: "success", message: "Template Added" });
      refetch();
      onClose();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });
  const [addTikTokLaunchTemplate] = useMutation(CREATE_TIKTOK_LAUNCH_TEMPLATE, {
    onCompleted: () => {
      openNotification({ type: "success", message: "Template Added" });
      tiktokrefetch();
      onClose();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  return (
    <div>
      <MainForm
        onClose={onClose}
        onSubmit={onSubmit}
        excludedRegion={excludedRegion}
        setExcludedRegion={setExcludedRegion}
        defaultData={defaultData}
        inputs={fbinputs}
        setInputs={setFbInputs}
        tiktokinputs={tiktokinputs}
        setTiktokInputs={setTiktokInputs}
        dateFormat={dateFormat}
        productId={productId}
        defaultselectedDate={defaultselectedDate}
        templatePlatforms={templatePlatforms}
        setTemplatePlatforms={setTemplatePlatforms}
        platformsData={platformsData}
        step={step}
        setStep={setStep}
        includedRegion={includedRegion}
        setIncludedRegion={setIncludedRegion}

        // targetingLocales={targetingLocales}
        // facebook_positions={facebook_positions}
        // publisher_platforms={publisher_platforms}
        // instagram_positions={instagram_positions}
        // messenger_positions={messenger_positions}
        // audience_network_positions={audience_network_positions}
        // device_platforms={device_platforms}
        // targetingCountries_loading={targetingCountriesRefetch}
        // targetingCountries={targetingCountries}
        // targetingLocales_loading={targetingLocales_loading}
        // launchConfig_loading={launchConfig_loading}
        // countries_group={countries_group}
      />
    </div>
  );
}

export default AddForm;
