import { Col, Form, Radio, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import CampaignObjectiveAndCampaignType from "./CampaignObjectiveAndCampaignType";
import { useGoogleLaunchV1Context } from "../../Context";
import { CONVERSION_ACTION_TYPE_ENUM, GL_STATUS_ENUM } from "shared/enum/launchKeysAndEnum";
import SelectInput from "components/forms/SelectInput";

export default function CampainSettingsTab() {
  const { campaignObject, handleCampaignLevelData, setLaunchName, launchName } =
    useGoogleLaunchV1Context();

  const rendeNameFields = () => (
    <Form layout="vertical">
      {/**
       * Reasion of campaignObject?.[0]?.name
       * Google launch V1 support only 1 campaign
       *
       */}
      <div>
        <Row gutter={12}>
          <Col xs={24} sm={8} md={10}>
            <LookTextInput
              label="Launch Name"
              required
              requiredLabel="Launch Name"
              name="launchName"
              placeholder="Enter launch name"
              value={launchName}
              onChange={(e) => setLaunchName(e?.target?.value)}
            />
          </Col>
          <Col xs={24} sm={8} md={10}>
            <LookTextInput
              label="Campaign name"
              name="name"
              required
              requiredLabel="Campaign name"
              placeholder="Enter campaign name"
              value={campaignObject?.[0]?.name}
              onChange={(e) => {
                const { name, value } = e?.target;
                handleCampaignLevelData({ [name]: value });
              }}
            />
          </Col>
          <Col xs={24} sm={8} md={4}>
            <SelectInput
              label="Status"
              value={campaignObject?.[0]?.status}
              onChange={(e) => handleCampaignLevelData({ status: e })}
              options={GL_STATUS_ENUM}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );

  const renderConversionAction = () => (
    <div>
      <h3 className="mt-40">Select the conversion action</h3>
      <Radio.Group
        onChange={(e) => handleCampaignLevelData({ conversionType: e.target.value })}
        value={campaignObject?.[0]?.conversionType}
      >
        {CONVERSION_ACTION_TYPE_ENUM?.map(({ name, value }, index) => (
          <Radio value={value} key={"CONVERSION_ACTION_TYPE_ENUM" + index}>
            {name}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );

  return (
    <div className="content">
      {rendeNameFields()}
      <CampaignObjectiveAndCampaignType />
      {renderConversionAction()}
    </div>
  );
}
