import { createContext, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AWS from "aws-sdk/global";
import UserPool from "../UserPool";
import { AccountContext } from "../Account";
import moment from "moment";
import { USER_LOGIN } from "../../actions/actionTypes";
import { openNotification, useUser } from "../../utils";

export const AwsAuthContext = createContext(null);

const cognitoUser = UserPool.getCurrentUser();

export function useAwsAuth() {
  const providerValues = useContext(AwsAuthContext);
  return { ...providerValues };
}

export default function AwsAuth(props) {
  const awsAuthRef = useRef();

  const { children } = props;
  const { loggedIn } = useUser();

  const { logout } = useContext(AccountContext);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.userReducer?.user);

  function userLogOut() {
    awsAuthRef?.current?.redirect("/");
    logout();
    // window.location.reload();
    // setTimeout(() => {
    localStorage.clear();
    // }, 100);
  }

  const timeLimit = 5;
  // const timeLimit = 2;

  const USER_POOL_REGION = process.env.REACT_APP_COGNITO_USER_POOL_REGION,
    USER_POOL_USER_ID = process.env.REACT_APP_COGNITO_USER_POOL_USER_ID,
    USER_POOL_IdentityPoolId = process.env.REACT_APP_COGNITO_USER_POOL_IdentityPoolId;

  function refreshTokenIfNeeded(exrtaProps) {
    const { forceRefresh = false } = exrtaProps || {};

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, result) {
        if (result) {
          var refresh_token = result.getRefreshToken();

          const expTime = result.idToken.payload.exp;

          const diff = moment(expTime).diff(moment().unix()) / 60;
          // console.log("********UUU refreshTokenIfNeeded", {
          //   refresh_token,
          //   expTime,
          //   diff,
          //   jwtToken: user.jwtToken,
          //   result,
          // });

          const remberLogin = JSON.parse(localStorage.getItem("remberLogin"));
          if (!remberLogin) {
            if (diff < timeLimit) {
              openNotification({
                type: "error",
                message: "Session Expired",
                key: "session-expire",
              });
              setTimeout(() => {
                userLogOut();
              }, 1000);
              return;
            }
          }

          if (forceRefresh || diff < timeLimit) {
            cognitoUser.refreshSession(refresh_token, (err, session) => {
              if (err) {
                // console.log(err);
                userLogOut();
              } else {
                AWS.config.region = USER_POOL_REGION;
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                  IdentityPoolId: USER_POOL_IdentityPoolId,
                  Logins: {
                    ["cognito-idp." + USER_POOL_REGION + ".amazonaws.com/" + USER_POOL_USER_ID]:
                      result?.getIdToken()?.getJwtToken(),
                  },
                });
                AWS.config.credentials.params.Logins[
                  "cognito-idp." + USER_POOL_REGION + ".amazonaws.com/" + USER_POOL_USER_ID
                ] = session.getIdToken().getJwtToken();

                AWS.config.credentials.refresh((err) => {
                  if (err) {
                    // console.log(err);
                  } else {
                    dispatch({
                      type: USER_LOGIN,
                      payload: { ...user, jwtToken: session.getIdToken().getJwtToken() },
                    });
                  }
                });
              }
            });
          }
        }
      });
    }
  }
  useEffect(() => {
    refreshTokenIfNeeded();
  }, []);

  useEffect(() => {
    let timer;
    if (loggedIn) {
      timer = setInterval(() => {
        // getMe();
        refreshTokenIfNeeded();
      }, 1000 * 60);
    }
    return () => clearInterval(timer);
  }, [loggedIn, refreshTokenIfNeeded]);

  const providerValues = { awsAuthRef, refreshTokenIfNeeded, userLogOut };

  return <AwsAuthContext.Provider value={providerValues}>{children}</AwsAuthContext.Provider>;
}
