import { Popover, Switch } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import LookButton from "components/LookButton";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { CHAT_GPT_API } from "shared/gql/gqlSchema/chatGPTGql";
import { openNotification } from "utils";
import EllipsisLoader from "components/loader/ellipsisLoader";

const ChatGPTpopup = forwardRef((props, ref) => {
  const { isContinueChat, checked } = props;
  const [message, setMessage] = useState("");
  const [query, setQuery] = useState([]);
  const [contextId, setContextId] = useState();
  // const [clicked, setClicked] = useState(false);
  const myFunction = () => {
    console.log("Child function called");
    setQuery([]);
  };
  useImperativeHandle(ref, () => ({
    myFunction,
  }));
  const [callChatGPT, { loading: loading_chatGpt, refetch: refetch_chatGpt }] = useCustomLazyQuery(
    CHAT_GPT_API,
    {
      notifyOnNetworkStatusChange: true,

      onCompleted: (e) => {
        const element = e?.AI_response?.response;
        if (element) {
          setContextId(e?.AI_response?.id);
          setQuery((prevItems) => {
            return prevItems.map((item) => {
              if (item.send === query[query.length - 1]?.send) {
                return { ...item, recieve: element };
              } else {
                return item;
              }
            });
          });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setMessage("");
      },
    }
  );
  const chatViewRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  const scrollToBottom = () => {
    if (chatViewRef.current) {
      chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight;
    }
  };

  const handleOnClick = () => {
    if (!isContinueChat && !checked)
      setQuery([{ send: message, recieve: "Something Went wrong!" }]);
    else setQuery([...query, { send: message, recieve: "Something Went wrong!" }]);
    setMessage("");
    let variables = {
      text: message,
    };
    if (isContinueChat) {
      variables = {
        ...variables,
        contextId: contextId,
      };
    }
    callChatGPT({
      variables: variables,
    });
  };

  const handleKeyDown = (e) => {
    if (e.shiftKey && e.keyCode === 13) {
      setMessage(e.target.value);
    } else if (e.key === "Enter") {
      e.preventDefault();
      handleOnClick();
    }
  };

  return (
    <div className="chat">
      <div
        ref={chatViewRef}
        className="px-16"
        style={{ overflow: "auto", overflowY: "scroll", height: "425px", margin: "0 -16px" }}
      >
        {query?.map((item, index) => {
          const { send, recieve } = item;
          return (
            <>
              <div className="chat-message">
                <p className="message-text">{send}</p>
              </div>

              {recieve && (
                <div className="chat-message received">
                  <p className="message-text-recieved">
                    {loading_chatGpt && index === query.length - 1 ? (
                      <EllipsisLoader />
                    ) : (
                      <ReactMarkdown>{recieve}</ReactMarkdown>
                    )}
                  </p>
                </div>
              )}
            </>
          );
        })}
      </div>
      <div className="send-message">
        <LookTextInput
          type="textarea"
          name="inputText"
          value={message}
          className="message-input mb-0"
          placeholder="Type your message..."
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />

        <LookButton
          iconType="send"
          type="primary"
          className="send-button"
          onClick={() => handleOnClick()}
        />
      </div>
    </div>
  );
});
export default ChatGPTpopup;
