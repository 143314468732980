import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import axios from "axios";
import cx from "classnames";
import { v4 as uuidv4, v4 as uuid } from "uuid";

import ReactQuill, { Quill } from "lib/react-quill-with-table/lib/index";
// import ReactQuill, { Quill } from "lib/react-quill-with-table/dist/react-quill.js";
// import ReactQuill, { Quill } from "react-quill-with-table";
// import ReactQuill, { Quill } from "react-quill";
import QuillBetterTable from "lib/quill-better-table/quill-better-table.js";
// import BlotFormatter from "quill-blot-formatter";
import BlotFormatter from "lib/quill-blot-formatter/dist/index.js";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { IMAGE_PROGRESS, S3_MULTI_FILE, S3_MULTI_FILE_UPLOAD } from "shared/gql/gqlSchema/mediaGql";
import { registerDynamicTag } from "./bolt";
import DebugPre from "components/DebugPre";
import { parseAndRegisterTags } from "./reactQuillFormTagsConfigs";
import LookButton from "components/LookButton";
// import { setEditorContents } from "lib/react-quill-with-table/lib/mixin";

function setEditorContents(editor, value) {
  if (editor && typeof editor.getSelection === "function") {
    const sel = editor.getSelection();
    if (typeof value === "string") {
      editor.setContents(editor.clipboard.convert({ html: value }));
    }
    // If you need to restore the selection after setting the content, do it here.
    if (sel) {
      editor.setSelection(sel);
    }
  }
}

Quill.register("modules/better-table", QuillBetterTable);
Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

function ReactQuillForm(props, ref) {
  const {
    onChange = () => {},
    loading = false,
    value = "",
    serviceName = "CMS",
    className = "",
    placeholder = "",
    contentElemetTags: tags = [],
    setContentElemetTags: setTags = () => {},
    isDefaultModule = false,
    defaultFormat = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "align",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
    ],
  } = props;
  const wordId = uuid();
  const quillRef = useRef();
  const [uploadingFileLoader, setUploadFileLoader] = useState(false);

  const [imageProcess, { loading: loading_IMAGE_PROGRESS }] = useCustomMutation(IMAGE_PROGRESS, {
    onCompleted(e) {
      // openNotification({
      //   type: "success",
      //   message: e?.imageProcess?.message,
      // });
    },
    onError(e) {
      // openNotification({ type: "error", message: e.message });
    },
  });

  const [selectedFile, setSelectedFile] = useState();

  const [
    s3MultiFile,
    {
      data: data_S3_MULTI_FILE = [],
      loading: loading_S3_MULTI_FILE,
      error: error_S3_MULTI_FILE,
      refetch: refetch__S3_MULTI_FILE,
    },
  ] = useCustomLazyQuery(S3_MULTI_FILE, {
    notifyOnNetworkStatusChange: true,
    onCompleted(e) {
      // console.log({ quillRef, e });
      const range = quillRef?.current?.getEditorSelection();

      if (e?.s3MultiFile && e?.s3MultiFile[0]) {
        quillRef.current
          .getEditor()
          .insertEmbed(range?.index, "image", e?.s3MultiFile[0]?.public_url);

        imageProcess({
          variables: {
            image_url: e?.s3MultiFile[0]?.public_url,
            sizes: ["480", "800"],
          },
        });
      }
    },
    onError: (e) => e.message && openNotification({ type: "error", message: e.message }),
  });

  const [s3MultiFileUpload, { loading: loading_S3_MULTI_FILE_UPLOAD }] = useCustomMutation(
    S3_MULTI_FILE_UPLOAD,
    {
      onCompleted(e) {
        // openNotification({
        //   type: "success",
        //   message: "Generate Upload Url Success",
        // });
        setUploadFileLoader(true);
        fileUploading(e?.s3MultiFileUpload);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  function fileUploading(fileData) {
    const requestPromise = [];

    const uploadPaths = [];

    fileData.forEach(({ url, path }, index) => {
      uploadPaths.push(path);
      var config = {
        method: "put",
        url: url,
        headers: {
          "Content-Type": "",
        },
        // data: selectedFile[index],
        data: selectedFile,
      };
      requestPromise.push(
        axios(config)
          .then(function (response) {
            // openNotification({
            //   type: "success",
            //   message: "Upload File To Server Success",
            // });
            // setUploadFileLoader(false);
            // uploadoadFile_Stage3(path);
            setUploadFileLoader(false);
          })
          .catch(function (error) {
            openNotification({ type: "error", message: error.message });
            setUploadFileLoader(false);
          })
      );
    });

    Promise.all(requestPromise)
      .then((e) => {
        s3MultiFile({
          variables: {
            path: uploadPaths,
          },
        });
      })
      .catch((e) => {
        console.log("Promise catch", e);
      });
  }

  const uploadFunction = async (file, fileName) => {
    s3MultiFileUpload({
      variables: {
        file_name: [fileName],
        service_name: serviceName,
      },
    });
  };

  const imageHandler = async (e) => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      var file = input.files[0];
      var formData = new FormData();

      formData.append("image", file);

      var fileName = file.name;
      setSelectedFile(file);
      // const res = await this.uploadFiles(file, fileName, quillObj);
      const res = await uploadFunction(file, fileName);
    };
  };

  function imageHandlerForDrag(dataUrl, type, imageData) {
    imageData
      .minify({
        // maxWidth: 320,
        // maxHeight: 320,
        quality: 0.7,
      })
      .then(async (miniImageData) => {
        const blob = miniImageData.toBlob();
        const fileName = imageData?.name;
        const file = miniImageData.toFile(fileName);
        setSelectedFile(file);
        const res = await uploadFunction(file, fileName);
        // this.setState({ image: { type, dataUrl, blob, file } })
      });
  }

  function wordSet() {
    const mainQuill = quillRef.current;
    let mainText = mainQuill?.getEditor()?.getText() || "";

    if (document) {
      document.getElementById(wordId).innerHTML = mainText?.split(/\s+/)?.length - 1;
    }
  }

  useEffect(() => {
    if (value?.length > 0) {
      onChange(value);
    } else {
      console.log("value: %o", value);
    }
  }, [value]);

  useEffect(() => {
    wordSet();
  }, [value, quillRef]);

  useEffect(() => {}, []);

  function addTable() {
    quillRef?.current?.getEditor()?.getModule("better-table")?.insertTable(2, 2);
  }

  /**
   * Below Tag functions defined
   */

  function handleApplyTagFromSelectedText(dynamicTagName) {
    const quill = quillRef?.current?.getEditor();
    const range = quill?.getSelection(true);

    if (range && range.length !== 0) {
      const selectedText = quill.getText(range.index, range.length).trim();

      const selectedTag = dynamicTagName.trim();
      const uniqueID = uuidv4();
      // Check if the selected text is a valid tag name (no spaces or special characters)
      if (/^[a-zA-Z]+[0-9]*$/.test(selectedTag)) {
        registerDynamicTag({
          // id: uniqueID,
          tagName: selectedTag,
          idCallBack: (e) =>
            setTags((prev) => [...prev, { id: e, text: selectedText, tagName: selectedTag }]),
        });
        quill.formatText(range.index, range.length, selectedTag, uniqueID);
      } else {
        alert("Invalid tag name!");
      }
    }
  }

  function handleRemoveTag(item) {
    const quill = quillRef.current.getEditor();

    // Remove the tag from Quill editor based on its unique attribute
    quill.root.querySelectorAll(`[data-id="${item.id}"]`).forEach((node) => {
      node.outerHTML = node.innerHTML; // This will remove the tag but keep its inner content
    });

    // Remove from tags list
    setTags((prev) => prev.filter((textItem) => textItem.id !== item.id));
  }

  function handleRemoveAllOccurrences(tagName) {
    const quill = quillRef.current.getEditor();

    quill.root.querySelectorAll(tagName).forEach((node) => {
      node.outerHTML = node.innerHTML; // This will remove the tag but keep its inner content
    });

    // Update the editor content state to reflect the changes
    onChange(quill.root.innerHTML);

    // Remove from tags list
    setTags((prev) => prev.filter((item) => item?.tagName !== tagName));
  }

  function parseQuillContentAndUpdateList() {
    const quill = quillRef?.current?.getEditor();
    const nodes = quill?.root?.querySelectorAll("[data-id]");
    const parsedTexts = nodes
      ? Array?.from(nodes).map((node) => {
          return {
            id: node.getAttribute("data-id"),
            text: node.innerText,
            tagName: node.tagName.toLowerCase(), // captures the dynamic tag name
          };
        })
      : [];
    setTags(parsedTexts);
  }

  const [isNeedCallParseContent, setIsNeedCallParseContent] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsNeedCallParseContent(true);
    }
  }, [loading]);
  useEffect(() => {
    if (!isNeedCallParseContent) {
    }
    parseAndRegisterTags(value, quillRef);
    setEditorContents(value);
  }, [value]);

  useImperativeHandle(ref, () => ({
    handleApplyTagFromSelectedText,
    handleRemoveTag,
    handleRemoveAllOccurrences,
    parseQuillContentAndUpdateList,
  }));

  const mainModule = {
    toolbar: {
      container: [
        [{ header: [] }],
        [
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          { color: ["#F00", "#0F0", "#00F", "#000", "#FFF", ""] },
        ],

        ...(!isDefaultModule
          ? [
              [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
              [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
              ["link", "image", "table"],
              ["clean"],
            ]
          : [["link"]]),
      ],
      handlers: { image: imageHandler, table: addTable },
    },
    table: false, // disable table module

    // With this can cutome upload

    imageDropAndPaste: {
      image: false,
      handler: imageHandlerForDrag,
    },
    blotFormatter: {},
    "better-table": {
      operationMenu: {
        items: {
          unmergeCells: {
            text: "Another unmerge cells name",
          },
        },
      },
    },

    keyboard: {
      bindings: QuillBetterTable?.keyboardBindings,
    },
  };

  const modules = useMemo(
    () => ({
      ...mainModule,
    }),
    []
  );

  const commonLoading =
    loading_S3_MULTI_FILE_UPLOAD || uploadingFileLoader || loading_S3_MULTI_FILE;

  /*
   * loading_S3_MULTI_FILE_UPLOAD for Genrating upload url
   * uploadingFileLoader for Uploading File
   * loading_S3_MULTI_FILE for Genrating final url
   */

  const insertTextAtCursor = (text) => {
    const quill = quillRef?.current?.getEditor();
    const range = quill?.getSelection();

    if (range) {
      quill?.insertText(range.index, text);
    }
  };
  const pushKeywords = (value) => {
    const selectedOption = value;
    insertTextAtCursor(`{{${selectedOption}}}`);
  };

  return (
    <>
      <div className="position-relative">
        {commonLoading && (
          <div class="quill-image-loader progress">
            <div class="indeterminate"></div>
          </div>
        )}

        <ReactQuill
          placeholder={placeholder}
          className={cx(className)}
          ref={quillRef}
          theme="snow"
          modules={modules}
          // modules={mainModule}
          formats={defaultFormat}
          value={value}
          onChange={(e) => {
            wordSet();
            onChange(e);
            parseQuillContentAndUpdateList();
          }}
        />

        <LookButton
          className="quill-keywords"
          tooltipTitle={<LookButton onClick={() => pushKeywords("KEYWORD")}>Keyword</LookButton>}
          iconType="tag"
        />

        <div className="quill-word-count">
          <p className="mb-0">
            Words:
            <span id={wordId} />
          </p>
        </div>
        <DebugPre content={[{ isRow: true, contentObjects: [{ value }] }]} />
      </div>
    </>
  );
}

export default forwardRef(ReactQuillForm);
