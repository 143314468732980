import LeadGenRevenueData from "./LeadGenRevenueData";
import VSRevenueData from "./VSRevenueData";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import DebugPre from "components/DebugPre";
import SASDashboardData from "./SASDashboardData";

export const valueCheck = (value, checkTotal = false, toFixed = 1) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(toFixed);
  return valueFloat;
};

export default function UserRevenuData(props) {
  const { isLeadgen = false, isVS = false, isSAS = false } = props;
  let list = [];
  if (isLeadgen) list = [...list, { title: "Leadgen", isLeadgen }];
  if (isVS) list = [...list, { title: "Viral Sparks", isVS }];
  if (isSAS) list = [...list, { title: "Search Arbitrage", isSAS }];
  return (
    <div className="p-28">
      <DebugPre content={[{ contentObjects: [{ list }] }]} />
      <div className="d-flex justify-content-between mb-21">
        <div>
          <p className="dashboard-main-title">Overview</p>
        </div>
        <div className="d-flex">
          <div className="ml-16">
            <DateRangePersist />
          </div>
        </div>
      </div>
      {list.map((data) => (
        <>
          {list.length > 1 && <h2>{data?.title}</h2>}
          {data?.isLeadgen && <LeadGenRevenueData />}
          {data?.isVS && <VSRevenueData />}
          {data?.isSAS && <SASDashboardData />}
        </>
      ))}
    </div>
  );
}
