import cx from "cx";

export default function LookTableTitleWrapper({
  icon: Icon,
  title = "",
  isClick,
  className = "",
  isEllipsis = true,
}) {
  return (
    <div className="flex">
      {Icon && <Icon className="mr-4" />}
      {isClick && <span className="click-td" onClick={isClick} />}
      <div
        className={cx("LookTableColumnRender", className, {
          "text-center": title === null || title === "" || title === "-" || title.length === 0,
          "normal-text": !isEllipsis,
        })}
      >
        {title === null || title === "" || title.length === 0 ? "-" : title}
      </div>
    </div>
  );
}
