import { useState } from "react";
import { Button, Form, Select, Tooltip } from "antd";
import CreateView from "../../view/MainForm";
import { useCustomQuery } from "hooks/apolloClientHooks";
import Loader from "components/loader";
import LookDrawer from "components/LookDrawer";
import { LIST_BIT } from "components/PostHandle/view/PageBits";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import { GET_ALL_CMS_LISTS_ID_NAME } from "shared/gql/gqlSchema/cmsGql";

const { Option } = Select;

export default function ListHandle(props) {
  const { lists = [], onChange = () => {}, type } = props;

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const {
    data = [],
    loading,
    error,
    refetch,
  } = useCustomQuery(GET_ALL_CMS_LISTS_ID_NAME, {
    notifyOnNetworkStatusChange: true,
    variables: {
      type,
    },
  });

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const drawerProps = {
    title: "Add",
    icon: () => <ActionIconRender iconType="appstore" />,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 1200,
    className: "no-body-space",
  };
  const formProps = {
    isNested: true,
    refetch,
    postType: LIST_BIT,
  };

  const addFormProps = {
    ...formProps,
  };
  return (
    <div className="position-relative cpm-list-handle">
      {loading && <Loader elementCenter />}
      <LookDrawer {...drawerProps}>
        {visibleDrawer && <CreateView view="create" {...addFormProps} />}
      </LookDrawer>

      <Form layout="vertical">
        <div className="d-flex  mb-24">
          <div className="flex-grow-1">
            <Form.Item className="look-form-input mb-0">
              {/* <Select
                mode="multiple"
                name="list"
                className="w-100"
                placeholder="Select List"
                onChange={onChange}
                value={lists}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {data?.CMS_Type_Of_List?.map(({ id, title }) => (
                  <Option key={id} value={id}>
                    {title}
                  </Option>
                ))}
              </Select> */}

              <SelectInput
                multiple
                className="mb-0"
                style={{ width: 150 }}
                placeholder="Select List"
                search
                name="list"
                value={lists}
                options={data?.CMS_Type_Of_List?.map(({ id, title }) => ({
                  name: title,
                  value: id,
                }))}
                onChange={onChange}
              />
            </Form.Item>
          </div>
          <div>
            <Tooltip title="Add">
              <Button
                type="link"
                onClick={showDrawer}
                icon={<ActionIconRender iconType="plus-circle" />}
              />
            </Tooltip>
          </div>
        </div>
      </Form>
      {/* <pre>{JSON.stringify({ data }, null, 2)}</pre> */}
    </div>
  );
}
