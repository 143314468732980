import { REPORT_TYPE_AD, REPORT_TYPE_AD_ADSET, REPORT_TYPE_CAMPAIGN } from "shared/SharedKeys";

export function fbAdsManagerRedirect({ reportType, adaccountId, id }) {
  const mainUrl = `https://business.facebook.com/adsmanager/manage?act=${adaccountId}`;

  if (reportType === REPORT_TYPE_CAMPAIGN) {
    return window.open(`${mainUrl}&selected_campaign_ids=${id}`);
  } else if (reportType === REPORT_TYPE_AD_ADSET) {
    return window.open(`${mainUrl}&selected_adset_ids=${id}`);
  } else if (reportType === REPORT_TYPE_AD) {
    return window.open(`${mainUrl}&selected_ad_ids=${id}`);
  }
  return null;
}

export function reportDateWiseSort(reportData = []) {
  return reportData?.sort(function (a, b) {
    let keyA = new Date(a?.date);
    let keyB = new Date(b?.date);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
}
