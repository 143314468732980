import { USER_SETTING_SCORECARD_BY_QUARTER } from "actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { defaultQuarterKeys } from "reducers/userSettings";

export default function useUnicornReducerDateSetter() {
  const dispatch = useDispatch();
  const quarterInput = useSelector((state) => state?.userSettings?.quarterInput);
  function checkUnicornDate() {
    const isValidCheck = Object.keys(defaultQuarterKeys).every(function (key, index) {
      if (!quarterInput?.[key]) {
        return false;
      }
      return true;
    });

    if (!isValidCheck) {
      dispatch({
        type: USER_SETTING_SCORECARD_BY_QUARTER,
        payload: { ...defaultQuarterKeys },
      });
    }
  }

  return { checkUnicornDate };
}
