import { useEffect, useRef, useState } from "react";
import MainForm from "./Mainform";
import * as yup from "yup";
import { Steps } from "antd";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useSelector } from "react-redux";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import {
  CREATE_REQUEST_TRACKING,
  GET_MOODBOARD_ANGLES,
  GET_MOODBOARD_COPY_POINTS,
  GET_MOODBOARD_CTA,
  GET_MOODBOARD_HOOKS,
  GET_MOODBOARD_PAIN_POINTS,
  GET_MOODBOARD_SCRIPTS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import {
  MOODBOARD_CREATIVE_TYPE_REQUEST,
  MOODBOARD_TEMPLATE_TYPE_REQUEST,
  STATUS_ASSIGNED_CD,
  STATUS_NEW_MB,
} from "../../creativesEnum";
import { GET_VS_ALL_CONTENT_BY_ADVETTISER } from "shared/gql/gqlSchema/viralSparksGql";
import { GET_SAS_CATEGORIES } from "shared/gql/gqlSchema/sasGql";
import ComplianceModal from "../../Compliance/ComplianceModal";
import LookModal from "components/LookModal";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import { API_STORE_VIRALSPARKS_ADVERTISE } from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
import { GET_VOLUUM_CATEGORY } from "shared/gql/gqlSchema/fb360Gql";
export default function RaiseRequestForm(props) {
  const {
    // id: categoryId,
    // name: categoryName,
    // productId,
    isCreativeRequest = false,
    isCategory = false,
    onCloseDrawer = () => {},
    requestData = {},
    isRequestView = false,
    isCreativeDirector,
    requestListrefetch = () => {},
    isBusinessDeveloper,
  } = props;

  const [formSteps, setformSteps] = useState(isRequestView ? 1 : 0);
  const [complianceValue, setComplianceValue] = useState({});
  const [visibleCompliantModal, setVisibleCompliantModal] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const { apiCall: vsAdvertiserFetch = () => {}, refetch: vsAdvertiserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_ADVERTISE
  );
  const {
    requestDetails,
    category,
    status,
    aspect_ratio,
    request_id,
    request_name = "",
    created_date,
    creative_type,
    targeted_audience,
    document: propDocument,
    ...propswithOutRequest
  } = requestData;
  const [document, setDocuments] = useState(propDocument || []);
  const [formValues, setFormValues] = useState({
    ...propswithOutRequest,
    scripts:
      creative_type === CREATIVE_TYPE_VIDEO
        ? requestData?.scripts?.map((script) => script?.id)
        : undefined,
    angles: creative_type === CREATIVE_TYPE_IMAGE ? requestData?.scripts?.[0]?.angles : undefined,
    hooks: creative_type === CREATIVE_TYPE_IMAGE ? requestData?.scripts?.[0]?.hooks : undefined,
    copyPoints:
      creative_type === CREATIVE_TYPE_IMAGE ? requestData?.scripts?.[0]?.copyPoints : undefined,
    painPoints:
      creative_type === CREATIVE_TYPE_IMAGE ? requestData?.scripts?.[0]?.painPoints : undefined,
    aspectRatio: aspect_ratio,
    category: requestData?.categoryId,
    requestId: request_id,
    requestName: request_name,
    creativeType: creative_type,
    targetedAudience: targeted_audience,
    categoryName: requestData?.categoryName,
    referenceLink: requestData?.reference_link,
    assigned: requestData?.assignTo?.user_id.toString(),
    document:
      requestData?.document?.map((docs, index) => ({
        url: docs?.url,
        name: fileNames[index],
      })) || [],
  });

  const { umsCurrentWorkspaceUsersFetch = () => {} } = useApiToStoreContext();
  const users = useSelector((state) => state?.userReducer?.me);
  const [categories, setCategories] = useState([]);
  // const { havePermission: isBusinessDeveloper } = usePermissionCheck(
  //   CREATIVE_TRACKING_BUSINESS_DEVELOPER
  // );
  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);
  const [fetchVsContent, { vs_content_data, loading: vs_content_loading, vs_content_refetch }] =
    useCustomLazyQuery(GET_VS_ALL_CONTENT_BY_ADVETTISER, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.vs_contentGetAllByAdvertiserId?.data?.map((element) => ({
          name: element?.nickname,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });
  const [fetchSasCategory, { data = [], loading: sas_loading, error, refetch }] =
    useCustomLazyQuery(GET_SAS_CATEGORIES, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.sasCategories?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });
  const [fetchLeadgen, { leadgen_data = [], leadgen_loading, leadgen_error, leadgen_refetch }] =
    useCustomLazyQuery(GET_VOLUUM_CATEGORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.voluum_get_category?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });
  const resetFormRef = useRef(() => {});
  const [raiseRequest, { loading }] = useCustomMutation(CREATE_REQUEST_TRACKING, {
    onCompleted(e) {
      const element = e?.ctAddRequest;
      if (element?.status === "SUCCESS") {
        openNotification({
          type: "success",
          message: element?.message || "Record added successfully",
          key: "success",
        });
        resetFormRef.current();
        setFormValues({});
        setDocuments([]);
        onCloseDrawer();
        requestListrefetch();
      } else {
        openNotification({ type: "error", message: element?.message, key: "add-req-error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });
  function onSubmit(values, { resetForm }) {
    setformSteps(1);
    setFormValues((formValue) => ({
      ...formValue,
      ...values,
    }));
  }
  function handleStatus(assigned) {
    if (assigned && isCreativeDirector && users?.user_id !== assigned) {
      return STATUS_ASSIGNED_CD;
    } else {
      return STATUS_NEW_MB;
    }
  }
  function handleRequestForm(values) {
    const {
      advertiser,
      assigned,
      offer,
      priority,
      expectedDueDate,
      categoryId,
      categoryName,
      productId,
      requestStatus,
      angles,
      copyPoints,
      hooks,
      painPoints,
      ...requestDetailProps
    } = values;
    const finalScriptData =
      values?.creativeType === CREATIVE_TYPE_IMAGE
        ? [{ angles, hooks, painPoints, copyPoints, ctas: values?.ctas }]
        : scripts_data?.ctGetScripts?.data
            ?.filter((script) => values?.scripts?.includes(script?.id))
            ?.map(({ angles, copyPoints, ctas, hooks, pain_points, id }) => ({
              angles: angles?.map((angle) => angle?.id),
              copy_points: copyPoints?.map((copy_point) => copy_point?.id),
              ctas: ctas?.map((cta) => cta?.id),
              hooks: hooks?.map((hook) => hook?.id),
              id,
              pain_points: pain_points?.map((pain_point) => pain_point?.id),
            }));

    const variables = {
      requestInput: {
        advertiser,
        assignTo: {
          // userId: isMediaBuyer ? 69 : parseInt(assigned),
          userId: parseInt(assigned),
          name: umsCurrentWorkspaceUsers?.find((user) => user?.id === assigned)?.name,
          // name: isMediaBuyer
          //   ? "Daizy Saini"
          //   : umsCurrentWorkspaceUsers?.find((user) => user?.id === assigned)?.name,
        },
        assignBy: {
          userId: parseInt(users?.user_id),
          name: users?.name,
        },
        offer,
        expectedDueDate,
        priority,
        categoryId,
        categoryName,
        productId: productId.toString(),
        requestStatus: isBusinessDeveloper ? requestStatus?.split(".")[1] : handleStatus(assigned),
        requestType: isCreativeRequest
          ? MOODBOARD_CREATIVE_TYPE_REQUEST
          : MOODBOARD_TEMPLATE_TYPE_REQUEST,
        requestDetails: {
          ...requestDetailProps,
          ...(complianceValue && isBusinessDeveloper && { ...complianceValue }),
          document,
          scripts: finalScriptData,
        },
        ...(requestData?.id && { id: requestData?.id }),
      },
    };
    raiseRequest({
      variables,
    });
  }
  const sharedSchema = {
    creativeType: yup.string().required().label("Creative Type "),
    aspectRatio: yup.string().required().label("Aspect Ratio "),
  };
  const categorySharedSchema = {
    productId: yup.string().required().label("Product"),
    categoryId: yup.string().required().label("Category"),
  };
  const getDocumentResponse = (docs) => {
    setFormValues((prevItems) => ({
      ...prevItems,
      document: [
        ...prevItems?.document,
        ...docs?.map((documents, index) => ({ url: documents?.url, name: fileNames[index] })),
      ],
    }));
  };
  const validationSchema = isCategory
    ? yup.object({
        ...sharedSchema,
        ...categorySharedSchema,
      })
    : !isCreativeRequest
    ? yup.object({
        ...sharedSchema,
        editable: yup.array().of(yup.string().required()).required().label("Editable"),
      })
    : yup.object({
        ...sharedSchema,
        // scripts: yup.array().of(yup.string().required()).required().label("Scripts"),
      });
  const { umsLanguagesFetch = () => {}, umsCountriesFetch = () => {} } = useApiToStoreContext();
  const { userSettingData } = useSelector((state) => state?.userSettings) || {};
  const { umsLanguages, umsCountries, umsCurrentWorkspaceUsers } = userSettingData || {};
  const [
    scriptsFetch,
    { data: scripts_data, loading: scripts_data_loading, refetch: data_refetch },
  ] = useCustomLazyQuery(GET_MOODBOARD_SCRIPTS, {
    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "mb-res-err-scripts" });
    },
  });
  const [ctaFetch, { data: cta_data, loading: cta_data_loading, refetch: cta_data_refetch }] =
    useCustomLazyQuery(GET_MOODBOARD_CTA, {
      onCompleted: (e) => {},
      onError(e) {
        openNotification({ type: "error", message: e?.message, key: "mb-res-err-cta" });
      },
    });
  const [
    copyPointsFetch,
    { data: copyPoints_data, loading: copyPoints_data_loading, refetch: copyPoints_data_refetch },
  ] = useCustomLazyQuery(GET_MOODBOARD_COPY_POINTS, {
    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "mb-res-err-cp" });
    },
  });
  const [
    hooksFetch,
    { data: hooks_data, loading: hooks_data_loading, refetch: hooks_data_refetch },
  ] = useCustomLazyQuery(GET_MOODBOARD_HOOKS, {
    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "mb-res-err-hooks" });
    },
  });
  const [
    anglesFetch,
    { data: angles_data, loading: angles_data_loading, refetch: angles_data_refetch },
  ] = useCustomLazyQuery(GET_MOODBOARD_ANGLES, {
    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "mb-res-err-angles" });
    },
  });
  const [
    painPointsFetch,
    { data: painPoints_data, loading: painPoints_data_loading, refetch: painPoints_data_refetch },
  ] = useCustomLazyQuery(GET_MOODBOARD_PAIN_POINTS, {
    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "mb-res-err-pp" });
    },
  });
  useEffect(() => {
    umsLanguagesFetch();
    umsCountriesFetch();
    vsAdvertiserFetch();
    umsCurrentWorkspaceUsersFetch();
    let variables = { categoryId: requestData?.categoryId, productId: requestData?.productId };
    if (isCreativeRequest && !isCategory) {
      scriptsFetch({
        variables,
      });
      ctaFetch({
        variables,
      });
      copyPointsFetch({
        variables,
      });
      anglesFetch({
        variables,
      });
      hooksFetch({
        variables,
      });
      painPointsFetch({
        variables,
      });
    }
  }, []);
  // will destructure
  const initialValues = {
    requestName: formValues?.requestName,
    categoryId: formValues?.categoryId,
    categoryName: formValues?.categoryName,
    productId: formValues?.productId,
    advertiser: formValues?.advertiser,
    offer: formValues?.offer,
    targetedAudience: formValues?.targetedAudience,
    country: formValues?.country || "235", // USA country ID
    language: formValues?.language || "1", // English language ID
    cta: formValues?.cta,
    variations: formValues?.variations,
    creativeType: formValues?.creativeType,
    aspectRatio: formValues?.aspectRatio,
    referenceLink: formValues?.referenceLink,
    scripts: formValues?.scripts,
    description: formValues?.description,
    editable: formValues?.editable,
    expectedDueDate: formValues?.expectedDueDate,
    assigned: formValues?.assigned,
    priority: formValues?.priority,
    compliance: formValues?.compliance,
    hooks: formValues?.hooks,
    painPoints: formValues?.painPoints,
    copyPoints: formValues?.copyPoints,
    angles: formValues?.angles,
  };

  const formProps = {
    initialValues,
    propDocument,
    onSubmit,
    setFormValues,
    formValues,
    hooks_data,
    hooks_data_loading,
    angles_data,
    angles_data_loading,
    painPoints_data,
    painPoints_data_loading,
    copyPoints_data,
    copyPoints_data_loading,
    validationSchema,
    umsCountries,
    agencyUsers: umsCurrentWorkspaceUsers,
    categories,
    umsLanguages,
    scripts_data,
    cta_data,
    cta_data_loading,
    handleRequestForm,
    setDocuments,
    document,
    scriptsFetch,
    copyPointsFetch,
    hooksFetch,
    painPointsFetch,
    anglesFetch,
    ctaFetch,
    productList,
    fetchVsContent,
    setVisibleCompliantModal,
    vs_content_data,
    fetchSasCategory,
    getDocumentResponse,
    setFileNames,
    fileNames,
    onCloseDrawer,
    scripts_data_loading,
    vs_content_loading,
    sas_loading,
    loading,
    fetchLeadgen,
    leadgen_loading,
  };
  const onCloseCompliantModal = () => {
    setVisibleCompliantModal(false);
    setComplianceValue({});
    // setCampaignModalPropsData([]);
  };
  const complianceModalProps = {
    visible: visibleCompliantModal,
    onCancel: onCloseCompliantModal,
    title: "Compliance Details",
    onSubmit: () => handleRequestForm(formValues),
    okText: "Submit",
  };
  return (
    <>
      <LookModal {...complianceModalProps}>
        {visibleCompliantModal && (
          <ComplianceModal
            setComplianceValue={setComplianceValue}
            complianceValue={complianceValue}
          />
        )}
      </LookModal>
      {!isRequestView && (
        <Steps className="m-30" style={{ width: "90%", cursor: "pointer" }} current={formSteps}>
          <Steps.Step
            title={isCreativeRequest ? "Creative Details" : "Template Details"}
            onClick={() => setformSteps(0)}
          />
          <Steps.Step title="Request Details" />
        </Steps>
      )}
      <div className="px-20 py-15">
        {formSteps === 0 ? (
          <MainForm {...formProps} creativesStep {...props} />
        ) : (
          <MainForm {...formProps} requestStep {...props} />
        )}
      </div>
    </>
  );
}
