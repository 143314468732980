export const templates = [
  // With text
  {
    width: 800,
    height: 600,
    name: "Template 1",
    noOfImages: 4,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 380,
          height: 285,
        },
        {
          type: "image",
          x_cordinate: 400,
          y_cordinate: 10,
          width: 390,
          height: 285,
        },
        { type: "image", x_cordinate: 10, y_cordinate: 305, width: 380, height: 285 },
        { type: "image", x_cordinate: 400, y_cordinate: 305, width: 390, height: 285 },
        {
          type: "box",
          x_cordinate: 35,
          y_cordinate: 240,
          width: 330,
          height: 45,
          color: "black",
          transparency: 0.4,
        },
        {
          type: "box",
          x_cordinate: 430,
          y_cordinate: 240,
          width: 330,
          height: 45,
          color: "black",
          transparency: 0.4,
        },
        {
          type: "box",
          x_cordinate: 35,
          y_cordinate: 535,
          width: 330,
          height: 45,
          color: "black",
          transparency: 0.4,
        },
        {
          type: "box",
          x_cordinate: 430,
          y_cordinate: 535,
          width: 330,
          height: 45,
          color: "black",
          transparency: 0.4,
        },
        {
          type: "text",
          x_cordinate: 45,
          y_cordinate: 270,
          text: "Your text here...",
        },
        {
          type: "text",
          x_cordinate: 440,
          y_cordinate: 270,
          text: "Your text here...",
        },
        {
          type: "text",
          x_cordinate: 55,
          y_cordinate: 565,
          text: "Your text here...",
        },
        {
          type: "text",
          x_cordinate: 450,
          y_cordinate: 565,
          text: "Your text here...",
        },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 2",
    noOfImages: 3,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        { type: "image", x_cordinate: 10, y_cordinate: 10, width: 780, height: 285 },
        { type: "image", x_cordinate: 10, y_cordinate: 305, width: 385, height: 285 },
        { type: "image", x_cordinate: 405, y_cordinate: 305, width: 385, height: 285 },
        {
          type: "box",
          x_cordinate: 50,
          y_cordinate: 20,
          width: 700,
          height: 40,
          color: "black",
          transparency: 0.3,
        },
        {
          type: "text",
          x_cordinate: 80,
          y_cordinate: 46,
          text: "Your text here...",
          transparency: 0.5,
        },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 3",
    noOfImages: 3,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        { type: "image", x_cordinate: 10, y_cordinate: 10, width: 780, height: 285 },
        { type: "image", x_cordinate: 10, y_cordinate: 305, width: 385, height: 285 },
        { type: "image", x_cordinate: 405, y_cordinate: 305, width: 385, height: 285 },
        {
          type: "box",
          x_cordinate: 50,
          y_cordinate: 535,
          width: 700,
          height: 40,
          color: "black",
          transparency: 0.3,
        },
        {
          type: "text",
          x_cordinate: 80,
          y_cordinate: 562,
          text: "Your text here...",
          transparency: 0.5,
        },
      ],
    },
  },
  // without text
  {
    width: 800,
    height: 600,
    name: "Template 6",
    noOfImages: 4,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 508,
          height: 580,
        },
        { type: "image", x_cordinate: 530, y_cordinate: 10, width: 260, height: 187 },
        { type: "image", x_cordinate: 530, y_cordinate: 206, width: 260, height: 187 },
        { type: "image", x_cordinate: 530, y_cordinate: 403, width: 260, height: 187 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 3",
    noOfImages: 4,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        { type: "image", x_cordinate: 10, y_cordinate: 10, width: 780, height: 285 },
        { type: "image", x_cordinate: 10, y_cordinate: 305, width: 256, height: 285 },
        { type: "image", x_cordinate: 276, y_cordinate: 305, width: 256, height: 285 },
        { type: "image", x_cordinate: 542, y_cordinate: 305, width: 246, height: 285 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 3",
    noOfImages: 4,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 380,
          height: 335,
        },
        {
          type: "image",
          x_cordinate: 400,
          y_cordinate: 10,
          width: 390,
          height: 235,
        },
        { type: "image", x_cordinate: 10, y_cordinate: 355, width: 380, height: 235 },
        { type: "image", x_cordinate: 400, y_cordinate: 255, width: 390, height: 335 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 4",
    noOfImages: 4,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 380,
          height: 285,
        },
        {
          type: "image",
          x_cordinate: 400,
          y_cordinate: 10,
          width: 390,
          height: 285,
        },
        { type: "image", x_cordinate: 10, y_cordinate: 305, width: 380, height: 285 },
        { type: "image", x_cordinate: 400, y_cordinate: 305, width: 390, height: 285 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 5",
    noOfImages: 3,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 254,
          height: 580,
        },
        { type: "image", x_cordinate: 274, y_cordinate: 10, width: 257, height: 580 },
        { type: "image", x_cordinate: 540, y_cordinate: 10, width: 250, height: 580 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 6",
    noOfImages: 3,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 508,
          height: 580,
        },
        { type: "image", x_cordinate: 530, y_cordinate: 10, width: 260, height: 285 },
        { type: "image", x_cordinate: 530, y_cordinate: 305, width: 260, height: 285 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 7",
    noOfImages: 5,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 254,
          height: 285,
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 305,
          width: 254,
          height: 285,
        },
        { type: "image", x_cordinate: 274, y_cordinate: 10, width: 257, height: 580 },
        { type: "image", x_cordinate: 540, y_cordinate: 10, width: 250, height: 285 },
        { type: "image", x_cordinate: 540, y_cordinate: 305, width: 250, height: 285 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 8",
    noOfImages: 5,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 254,
          height: 580,
        },
        { type: "image", x_cordinate: 274, y_cordinate: 10, width: 257, height: 285 },
        { type: "image", x_cordinate: 274, y_cordinate: 305, width: 257, height: 285 },
        { type: "image", x_cordinate: 540, y_cordinate: 10, width: 250, height: 285 },
        { type: "image", x_cordinate: 540, y_cordinate: 305, width: 250, height: 285 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 9",
    noOfImages: 6,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 254,
          height: 285,
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 305,
          width: 254,
          height: 285,
        },
        { type: "image", x_cordinate: 274, y_cordinate: 10, width: 257, height: 285 },
        { type: "image", x_cordinate: 274, y_cordinate: 305, width: 257, height: 285 },
        { type: "image", x_cordinate: 540, y_cordinate: 10, width: 250, height: 285 },
        { type: "image", x_cordinate: 540, y_cordinate: 305, width: 250, height: 285 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 10",
    noOfImages: 3,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        { type: "image", x_cordinate: 10, y_cordinate: 10, width: 780, height: 285 },
        { type: "image", x_cordinate: 10, y_cordinate: 305, width: 385, height: 285 },
        { type: "image", x_cordinate: 405, y_cordinate: 305, width: 385, height: 285 },
      ],
    },
  },
  {
    width: 800,
    height: 600,
    name: "Template 11",
    noOfImages: 3,
    template_features: {
      template: [
        {
          type: "box",
          x_cordinate: 0,
          y_cordinate: 0,
          width: 800,
          height: 600,
          color: "#ffff",
        },
        {
          type: "image",
          x_cordinate: 10,
          y_cordinate: 10,
          width: 780,
          height: 190,
        },
        { type: "image", x_cordinate: 10, y_cordinate: 210, width: 780, height: 190 },
        { type: "image", x_cordinate: 10, y_cordinate: 410, width: 780, height: 180 },
      ],
    },
  },
];

export const tempImages = [
  {
    id: "363",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/APl94_1690199594151.jpg",
  },
  {
    id: "362",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/OrowJ_1690198814153.jpg",
  },
  {
    id: "361",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/GMG7A_1690198814152.jpg",
  },
  {
    id: "360",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/5IbBj_1690198814152.jpg",
  },
  {
    id: "359",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/Ya1PH_1690198814151.jpg",
  },
  {
    id: "350",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/Mveo1_1690177797460.png",
  },
  {
    id: "344",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/3GYgT_1689944327752.modified-image-1689944326949",
  },
  {
    id: "384",
    s3Url:
      "https://lf-creative-library.s3.ap-south-1.amazonaws.com/agency_1/worsp-en/image/3GYgT_1689944327752.modified-image-1689944326949",
  },
];
