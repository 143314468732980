import { Avatar, Divider, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import moment from "moment";
import { useEffect, useState } from "react";
import { SAS_COMMENT_SERVICE_PLATFORMS_ENUM } from "shared/enum/platformsKeyAndEnum";
import { openNotification } from "utils";

const calculateTimeAgo = (timestamp) => {
  const now = new Date();
  const createdDate = new Date(timestamp);
  const timeDifference = now - createdDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let newTimeAgo = "";

  if (days > 0) {
    newTimeAgo = days === 1 ? "yesterday" : `${days} days ago`;
  } else if (hours > 0) {
    newTimeAgo = hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    newTimeAgo = minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    newTimeAgo = "just now";
  }

  return newTimeAgo;
};

const openNewTab = (taskId) => {
  if (taskId === null || "") {
    openNotification({ type: "error", message: "There is no click up task for this comment" });
  } else {
    let newPageUrl = `https://app.clickup.com/t/${taskId}`;
    window.open(newPageUrl, "_blank");
  }
};

export default function MessageCard(props) {
  const {
    date,
    user,
    handleButtonClick = () => {},
    handleEditClick = () => {},
    message = "",
    from = "",
    to = "",
    totalReply = 0,
    timeZone = "",
    handleLikeDislike = () => {},
    handleDelete = () => {},
    copyToClipboard = () => {},
    isReplies = false,
    index = 0,
    like_dislikeInfo = [],
    totalLike = 0,
    totalDislike = 0,
    loading = false,
    meData,
    taskId,
    handleMarkAsRead = () => {},
    isSeen = false,
    handleDeleteLoading = false,
    platform,
    team,
    isEdit = false,
    isEditModeOn = false,
  } = props;
  const nameArray = user?.name?.split(" ");
  const userInitials = nameArray?.map((data) => data.charAt(0));
  const findCurrentUser = like_dislikeInfo?.find((item) => item?.userId == meData?.user_id);
  const [isLike, setIsLike] = useState(findCurrentUser?.isLike);
  const [isDisLike, setIsDisLike] = useState(findCurrentUser?.isDisLike);
  const dateFormat = "YYYY-MM-DD";
  const formattedFromDate = moment(from).format(dateFormat);
  const formattedToDate = moment(to).format(dateFormat);
  const isEditDeletePermissionAllowed = +meData?.user_id === +user?.id;

  useEffect(() => {
    setIsLike(findCurrentUser?.isLike);
  }, [findCurrentUser?.isLike]);

  useEffect(() => {
    setIsDisLike(findCurrentUser?.isDisLike);
  }, [findCurrentUser?.isDisLike]);

  const renderTeamName = () => {
    if (team?.length) {
      return team?.map((it) => " - " + it?.name);
    }
    return null;
  };

  const renderPlatformIcon = () => {
    if (platform && platform?.length) {
      const iconMappedPlatforms = platform.map((it) => ({
        ...it,
        icon: SAS_COMMENT_SERVICE_PLATFORMS_ENUM.find((its) => its.value === it?.id)?.icon,
      }));
      return iconMappedPlatforms.map((it) => (
        <Tooltip title={it?.name}>
          <ActionIconRender iconType={it?.icon} />
        </Tooltip>
      ));
    } else {
      return null;
    }
  };

  const handleEditMessage = () => {
    handleEditClick(props);
  };

  const renderEditStatus = () => {
    if (isEditModeOn) {
      return <p>(editing)</p>;
    }
    if (isEdit) {
      return <p>(edited)</p>;
    }
    return null;
  };

  return (
    <>
      <div className="title-container">
        <div className="title">
          <Avatar className="cs-avtar-iconStyle">{userInitials}</Avatar>
          <div className="name-date">
            <div className="row">
              <span className="name">{user?.name}</span>
              <span className="teamname mr-2">{renderTeamName()}</span>
              <span className="platformname"> {renderPlatformIcon()}</span>
            </div>
            <div className="date-container">
              <span className="date">
                {formattedFromDate} - {formattedToDate} ({timeZone})
              </span>
              <span className="date"></span>
            </div>
          </div>
        </div>
        <div className=" sr-no red-dot-div">
          {isSeen ? <></> : !isReplies && <div className="red-dot"></div>}#{index + 1}
          {renderEditStatus()}
        </div>
      </div>
      <pre className="description">{message}</pre>
      <div className="footer">
        <div className="footer-actions">
          <div className="like-dislike">
            <span className="like">
              <LookButton
                className="cs-border-none-button"
                onClick={() => {
                  setIsLike(true);
                  handleLikeDislike(props, "like", index);
                }}
                //here we are disbling this buttton acc to requirment, it is use only one time
                disabled={loading || findCurrentUser?.isLike || isLike}
              >
                <ActionIconRender iconType={isLike ? "like" : "like-outlined"} />
              </LookButton>
              <span className="mb-0 ml-4">{totalLike}</span>
            </span>
            <span className="dislike">
              <LookButton
                className="cs-border-none-button"
                onClick={() => {
                  setIsDisLike(true);
                  handleLikeDislike(props, "dislike", index);
                }}
                //here we are disbling this buttton acc to requirment, it is use only one time
                disabled={loading || findCurrentUser?.isDisLike || isDisLike}
              >
                <ActionIconRender iconType={isDisLike ? "dislike-filled" : "dislike"} />
              </LookButton>

              <span className="mb-0 ml-4">{totalDislike}</span>
            </span>
          </div>
          {!isReplies && (
            <div className="replies">
              <span onClick={() => handleButtonClick(props)}>
                <span className="mb-0">{totalReply}</span>
                <span className="mb-0 mr-2 ml-2">Replies</span>
              </span>
            </div>
          )}
          <div className="task">
            {taskId ? (
              <span
                onClick={() => {
                  openNewTab(taskId);
                }}
              >
                <ActionIconRender iconType="click-up" />
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="message-time">{calculateTimeAgo(date)}</div>
      </div>
      <div className="message-hidden-buttons-div">
        <Tooltip title="Copy Comment link">
          <LookButton
            onClick={() => copyToClipboard(props?.id)}
            className="mr-0 mb-0 cs-border-none-button"
            disabled={handleDeleteLoading}
          >
            <ActionIconRender iconType="link-icon" />
          </LookButton>
        </Tooltip>

        {isEditDeletePermissionAllowed && (
          <>
            <Divider className="mb-0  mt-8" type="vertical" />
            <Tooltip title="Delete Comment">
              <LookButton
                onClick={() => {
                  handleDelete(props);
                }}
                className="mr-0 mb-0 cs-border-none-button"
                disabled={handleDeleteLoading}
              >
                <ActionIconRender iconType="delete" />
              </LookButton>
            </Tooltip>
          </>
        )}

        {!isReplies && (
          <>
            <Divider className="mb-0  mt-8" type="vertical" />
            <Tooltip title="Open Replies">
              <LookButton
                onClick={() => handleButtonClick(props)}
                className="mr-0 mb-0 cs-border-none-button"
                disabled={handleDeleteLoading}
              >
                <ActionIconRender iconType="rollback" />
              </LookButton>
            </Tooltip>
          </>
        )}

        {isEditDeletePermissionAllowed && (
          <>
            <Divider className="mb-0  mt-8" type="vertical" />
            <Tooltip title="Edit Comment">
              <LookButton onClick={handleEditMessage} className="mr-0 mb-0 cs-border-none-button">
                <ActionIconRender iconType="edit" />
              </LookButton>
            </Tooltip>
          </>
        )}

        {!isReplies && (
          <>
            <Divider className="mb-0  mt-8" type="vertical" />
            <LookButton
              onClick={() => handleMarkAsRead(props?.id)}
              className="mr-0 mb-0 cs-border-none-button"
              disabled={handleDeleteLoading}
            >
              <p className="mb-0">Mark as Unread</p>
            </LookButton>
          </>
        )}
      </div>
    </>
  );
}
