import { LockOutlined, UserOutlined } from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";
import {
  CREATIVE_TRACKING_VIEW_AGENCY,
  DMS_ADMIN,
  DMS_USER,
  UMS_AGENCY_ADMIN,
  UMS_SUPER_ADMIN,
} from "shared/UserPermission/userPermissionList";
const toolsPath = [
  {
    name: "Tools",
    link: "/dms",
    icon: (e) => <ActionIconRender iconType="global" {...e} />,
    subMenu: [
      {
        subMenuName: "DMS",
        subMenuIcon: () => <ActionIconRender iconType="collection" />,
        // permission: [SAS_VIEW_KEYWORD, SAS_VIEW_CATEGORY],
        isCollapsible: true,
        permission: [UMS_SUPER_ADMIN, DMS_USER],
        subMenus: [
          {
            subMenuName: "Accounts",
            subMenuLink: "/accounts",
            subMenuIcon: UserOutlined,
            permission: UMS_SUPER_ADMIN,
          },
          {
            subMenuName: "Domains",
            subMenuLink: "/domains",
            subMenuIcon: LockOutlined,
            permission: DMS_USER,
          },
        ],
      },

      {
        subMenuName: "S2S",
        subMenuIcon: () => <ActionIconRender iconType="collection" />,
        // permission: [SAS_VIEW_KEYWORD, SAS_VIEW_CATEGORY],
        isCollapsible: true,
        permission: UMS_SUPER_ADMIN,
        subMenus: [
          {
            subMenuName: "Pixels",
            subMenuLink: "/pixels",
            subMenuIcon: UserOutlined,
            permission: UMS_SUPER_ADMIN,
          },
          {
            subMenuName: "Line of business",
            subMenuLink: "/line-of-business",
            subMenuIcon: UserOutlined,
            permission: UMS_SUPER_ADMIN,
          },
          {
            subMenuName: "Business Manager",
            subMenuLink: "/business-manager",
            subMenuIcon: UserOutlined,
            permission: UMS_SUPER_ADMIN,
          },
          {
            subMenuName: "Verticals",
            subMenuLink: "/verticals",
            subMenuIcon: UserOutlined,
            permission: UMS_SUPER_ADMIN,
          },
        ],
      },
      {
        subMenuName: "BigSpy",
        subMenuLink: "/bigspy",
        subMenuIcon: () => <ActionIconRender iconType="search" />,
        permission: CREATIVE_TRACKING_VIEW_AGENCY,
      },
    ],
  },
];

export default toolsPath;
