import { CMS_POST_TYPE_LIST, CMS_POST_TYPE_STORY } from "components/PostHandle";

export const STORY_BIT = CMS_POST_TYPE_STORY,
  PRODUCT_BIT = "Product",
  LIST_BIT = CMS_POST_TYPE_LIST,
  BLOCK_BIT = "Block",
  PAGE_BIT = "Page",
  NETWORK_BIT = "Network",
  REVIEW_BIT = "Review",
  CMS_LIST_TYPE_BLOCK = "Block",
  CMS_LIST_TYPE_PRODUCT = "Product",
  CMS_LIST_TYPES = [
    { name: "Block", value: CMS_LIST_TYPE_BLOCK },
    { name: "Product", value: CMS_LIST_TYPE_PRODUCT },
  ];
