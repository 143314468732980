import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useSelector } from "react-redux";
import DetailView from "./DetailView";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useColumns from "../useColumns";
import {
  G360_BULK_BUDGET_UPDATE,
  G360_BULK_MAX_CONVERSION,
  G360_BULK_NAME_UPDATE,
  G360_BULK_TARGET_CPA_UPDATE,
  G360_BULK_TARGET_ROAS_UPDATE,
  G360_GET_CAMPAIGN_REPORTING,
} from "shared/gql/gqlSchema/g360Gql";
import { Button, Col, Form, Row, Switch, Tooltip } from "antd";
import useGoogleBulkStatusUpdateMuatation, {
  groupForStatusGoogle,
} from "hooks/googleHooks/useGoogleStatusUpdateQuery";
import {
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
} from "shared/SharedKeys";
import alasql from "alasql";
import { useNewLeadgenReportsQuery } from "hooks/tiktokHooks";
import {
  LEADGEN_GOOGLE_PLATFORM_ID,
  LEADGEN_REPORT_ASSET_TYPE_ADGROUP,
  LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN,
} from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import ActionIconRender from "components/ActionIconRender";
import LookModal from "components/LookModal";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";
import { openNotification } from "utils";
import {
  GL_STATUS_ENUM,
  GL_STATUS_PAUSED,
  MAXIMIZE_CONVERSIONS,
  TARGET_CPA,
  TARGET_ROAS,
} from "shared/enum/launchKeysAndEnum";

export default function Campaigns(props) {
  const { performanceType = "", revAdAccountId: customer_id = "", actionType = "CAMPAIGN" } = props;
  const [nameModalVisible, setNameModalVisible] = useState(false);
  const [editInput, setEditInput] = useState({});
  const [currentValues, setCurrentValues] = useState({});
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude, selectedWorkspace } = userSettings || {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);

  const dateRangeParams = {
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone.value,
  };

  function getExtraVariables() {
    let extraParams = {};

    if (performanceType) {
      extraParams.spendVariables = {
        asset: performanceType.toUpperCase(),
        assetId: customer_id,
      };
      extraParams.revenueVariables = {
        asset: "AD_ACCOUNT",
        asset_id: customer_id,
      };
    }
    return extraParams;
  }

  const extraParams = getExtraVariables();

  const { loading_revenue, refetch_revenue, revenueReport } = useNewLeadgenReportsQuery(
    REPORT_TYPE_CAMPAIGN,
    {
      ...dateRangeParams,
      ...extraParams?.revenueVariables,
      platform_id: LEADGEN_GOOGLE_PLATFORM_ID,
    }
  );

  const [spendReport, setSpendReport] = useState([]);
  const { columns } = useColumns();
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const [rowPropsData, setRowProps] = useState({});
  const {
    loading: loading_SPEND_ADGROUP,
    error: error_SPEND_ADGROUP,
    refetch: refetch_SPEND_ADGROUP,
  } = useCustomQuery(G360_GET_CAMPAIGN_REPORTING, {
    variables: {
      ...dateRangeParams,
      ...extraParams?.spendVariables,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.g_campaignReporting;
      if (element) {
        setSpendReport(element);
      }
    },
  });

  const loading = loading_SPEND_ADGROUP || loading_revenue;

  function refetch() {
    refetch_SPEND_ADGROUP();
    refetch_revenue();
  }
  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  const reportType = "CAMPAIGN";

  const settingsRedirect = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const onOpenNameModal = (rowProps) => {
    setRowProps(rowProps);
    setNameModalVisible(true);
  };

  const onCloseNameModal = () => {
    setRowProps({});
    setEditInput({});
    setNameModalVisible(false);
  };

  const { updateBulkStatus, loading_update } =
    useGoogleBulkStatusUpdateMuatation(refetch_SPEND_ADGROUP);

  const action =
    // spendActions && isActionVisibleForSAS
    !performanceType
      ? [
          {
            title: "Action",
            dataIndex: "action",
            nofilter: true,
            render: (action, actionProps) => {
              const { status, campaign_id, adgroup_id, customer_id, ocid } = actionProps;
              const callStatus =
                status !== GL_STATUS_PAUSED ? GL_STATUS_PAUSED : GL_STATUS_ENUM[0].value;
              return (
                <div className="action-column d-flex justify-content-around">
                  <div className="more-actions flex-around">
                    {reportType !== REPORT_TYPE_AD_ACCOUNT && (
                      <>
                        <Tooltip title="Redirect">
                          <Button
                            onClick={() => {
                              const campaignUrl = `https://ads.google.com/aw/overview?__e=${customer_id}&campaignId=${campaign_id}`;
                              const isOcidUrl = ocid
                                ? campaignUrl.concat(`&ocid=`, ocid)
                                : campaignUrl;
                              const isAdgroupUrl = adgroup_id
                                ? isOcidUrl.concat(`&adGroupId=`, adgroup_id)
                                : isOcidUrl;
                              settingsRedirect(isAdgroupUrl);
                            }}
                            type="link"
                            icon={<ActionIconRender iconType="setting" />}
                            disabled={!customer_id}
                          />
                        </Tooltip>
                        <Tooltip title="Edit">
                          <Button
                            onClick={() => {
                              onOpenNameModal(actionProps);
                            }}
                            type="link"
                            icon={<ActionIconRender iconType="edit" />}
                          />
                        </Tooltip>
                      </>
                    )}

                    {campaign_id || adgroup_id ? (
                      <Tooltip title="Update Status">
                        <Switch
                          disabled={loading_update}
                          size="small"
                          checked={actionProps.status !== GL_STATUS_PAUSED}
                          onClick={() => {
                            const updateArray = groupForStatusGoogle([actionProps], reportType);
                            updateBulkStatus({
                              variables: {
                                asset:
                                  reportType === REPORT_TYPE_AD_ADSET
                                    ? LEADGEN_REPORT_ASSET_TYPE_ADGROUP
                                    : LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN,
                                updateArray,
                                status: callStatus,
                              },
                            });
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Switch disabled size="small" />
                    )}
                  </div>
                </div>
              );
            },

            fixed: "right",
            width: 130,
            minWidth: 130,
          },
        ]
      : [];

  const initialColumns = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      render: (value, otherProps) => (
        <LookTableColumnRender
          {...otherProps}
          isClick={() => showDrawerUpdate(otherProps)}
          title={value}
        />
      ),
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: "Campaign Id",
      dataIndex: "campaign_id",
      key: "campaign_id",
      render: (value, otherProps) => (
        <LookTableColumnRender
          {...otherProps}
          isClick={() => showDrawerUpdate(otherProps)}
          title={value}
        />
      ),
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    {
      title: "Serving Status",
      dataIndex: "serving_status",
      key: "serving_status",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "string",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "string",
    },
    ...columns,
    ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];
  if (!loading) {
    finalMerge = alasql(
      "SELECT * , a.clicks as googleClicks, b.clicks as revClicks FROM ? as a FULL OUTER JOIN ? as b ON a.campaign_id = b.campaign_id",
      [spendReport, revenueReport]
    );

    finalMerge?.forEach((element, index) => {
      const {
        cost = 0,
        visits = 0,
        googleClicks = 0,
        impressions: googleImpressions = 0,
        name = "",
        campaign_name = "",
        revenue = 0,
        revClicks = 0,
        conversions = 0,
        impressions = 0,
        status = null,
      } = element;

      const costUpdate = cost.toFixed(3);

      const CPC = parseFloat(costUpdate) / parseFloat(googleClicks);
      const CTR = (parseFloat(googleClicks) / parseFloat(impressions)) * 100 || 0;
      const CPM = (parseFloat(costUpdate) / parseFloat(impressions)) * 1000;
      const EPC = parseFloat(revenue) / parseFloat(revClicks);
      const PROFIT = parseFloat(revenue) - parseFloat(costUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(costUpdate)) * 100 || 0;
      const CPR_V = parseFloat(costUpdate) / parseFloat(revClicks);
      const PAYOUT = parseFloat(revenue) / parseFloat(conversions);
      const RPV = parseFloat(revenue) / parseFloat(googleClicks);
      const RPL = parseFloat(revenue) / parseFloat(revClicks);
      const CPV = parseFloat(costUpdate) / parseFloat(googleClicks);
      const CPR = parseFloat(costUpdate) / parseFloat(conversions);

      const AD_CTR = (parseFloat(googleClicks) / parseFloat(googleImpressions)) * 100 || 0;
      const LP_CTR = (parseFloat(revClicks) / parseFloat(googleClicks)) * 100;
      const CR = (parseFloat(conversions) / parseFloat(revClicks)) * 100;

      finalMergeData.push({
        ...element,
        campaignName: name || campaign_name,
        key: index,
        googleImpressions,
        revClicks,
        visits,
        googleClicks,
        cost: costUpdate,
        PROFIT: valueCheck(PROFIT),
        revenue: valueCheck(revenue),
        CPC: valueCheck(CPC),
        CPC_VIEW: valueCheck(CPC, true),
        CTR: valueCheck(CTR),
        CTR_VIEW: valueCheck(CTR, true),
        RPL: valueCheck(RPL),
        CPM: valueCheck(CPM),
        ROI: valueCheck(ROI),
        RPV: valueCheck(RPV),
        CPR_V: valueCheck(CPR_V),
        CPV: valueCheck(CPV),
        EPC: valueCheck(EPC),
        PAYOUT: valueCheck(PAYOUT),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CR: valueCheck(CR),
        CPR: valueCheck(CPR),
        CPM_VIEW: valueCheck(CPM, true),
        status,
      });
    });
  }
  const finalFilterData = filterData(finalMergeData).filter(
    ({ campaignName = "", campaign_id = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";

      const searchCheck =
        campaignName?.toLowerCase()?.includes(searchValue) ||
        campaign_id?.toLowerCase()?.includes(searchValue);

      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }
      return false;
    }
  );

  let total_cost = 0,
    total_revClicks = 0,
    total_impressions = 0,
    total_revenue = 0,
    total_conversions = 0,
    total_googleClicks = 0,
    total_googleImpressions = 0;

  for (const {
    cost = 0,
    revClicks = 0,
    impressions = 0,
    googleImpressions = 0,
    revenue = 0,
    conversions = 0,
    googleClicks = 0,
  } of finalMergeData) {
    total_googleImpressions += parseFloat(googleImpressions || 0);
    total_cost += JSON.parse(cost || 0);
    total_revClicks += JSON.parse(revClicks || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_revenue += parseFloat(revenue || 0);
    total_conversions += parseFloat(conversions || 0);
    total_googleClicks += parseFloat(googleClicks || 0);
  }
  const total_PROFIT = parseFloat(total_revenue) - parseFloat(total_cost);
  const total_CPC = total_cost / total_revClicks;
  const total_CTR = (total_revClicks / total_impressions) * 100;
  const total_CPM = (parseFloat(total_cost) / parseFloat(total_impressions)) * 1000;
  const total_PAYOUT = parseFloat(total_revenue) / parseFloat(total_conversions);
  const total_EPC = parseFloat(total_revenue) / parseFloat(total_revClicks);
  const total_CPR_V = parseFloat(total_cost) / parseFloat(total_conversions);
  const total_ROI = (parseFloat(total_PROFIT) / parseFloat(total_cost)) * 100 || 0;
  const total_CR = (parseFloat(total_conversions) / parseFloat(total_revClicks)) * 100;
  const total_CPV = parseFloat(total_cost) / parseFloat(total_revClicks);
  const total_AD_CTR = (parseFloat(total_googleClicks) / parseFloat(total_impressions)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_revClicks) / parseFloat(total_googleClicks)) * 100;
  const summaryData = {
    cost: valueCheck(total_cost, true),
    revenue: valueCheck(total_revenue, true),
    PAYOUT: valueCheck(total_PAYOUT, true),
    CPC: valueCheck(total_CPC, true),
    EPC: valueCheck(total_EPC, true),
    CTR: valueCheck(total_CTR, true),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: valueCheck(total_ROI, true),
    googleImpressions: valueCheck(total_googleImpressions, true),
    CR: valueCheck(total_CR, true),
    revClicks: valueCheck(total_revClicks, true),
    CPR_V: valueCheck(total_CPR_V, true),
    CPV: valueCheck(total_CPV, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    googleClicks: valueCheck(total_googleClicks, true),
    conversions: valueCheck(total_conversions, true),
    CPM: valueCheck(total_CPM, true),
  };

  const [g_bulkNameUpdate, { loading: g_bulkNameUpdate_loading }] = useCustomMutation(
    G360_BULK_NAME_UPDATE,
    {
      onCompleted() {
        openNotification({ type: "success", message: "Name updated successfully!" });
        onCloseNameModal();
        setEditInput({});
        refetch();
      },
      onError(e) {
        openNotification({ type: "error", message: "Something went wrong!" });
      },
    }
  );

  const modalProps = {
    visible: nameModalVisible,
    onCancel: onCloseNameModal,
    footer: false,
    loader: g_bulkNameUpdate_loading,
    title: rowPropsData?.name,
  };

  const bulkActions = {
    bulkActions: [
      {
        option: "play",
        title: "Active",
        onClick: (e) => {
          const updateArray = groupForStatusGoogle(e, reportType);
          updateBulkStatus({
            variables: {
              asset: reportType,
              updateArray,
              status: "ENABLED",
            },
          });
        },
      },
      {
        option: "pause",
        title: "Pause",
        onClick: (e) => {
          const updateArray = groupForStatusGoogle(e, reportType);
          updateBulkStatus({
            variables: {
              asset: reportType,
              updateArray,
              status: "PAUSED",
            },
          });
        },
      },
    ],
  };

  useEffect(() => {
    if (nameModalVisible) {
      setEditInput({
        budget: rowPropsData?.budget,
        cpa: rowPropsData?.target_cpa,
        name: rowPropsData?.name,
        targetRoas: "",
        maxConversion: rowPropsData?.bidding_strategy_type,
      });

      setCurrentValues({
        budget: rowPropsData?.budget,
        cpa: rowPropsData?.target_cpa,
        name: rowPropsData?.name,
      });
    }
  }, [nameModalVisible]);

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },
    scrollHeight: "calc(100vh - 208px)",
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    ...bulkActions,
    defaultPagination: true,
  };
  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone.value,
      workspaceId: selectedWorkspace,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setVisibleDetailDrawer(false);
  };
  const drawerdetailsViewPropsUpdate = {
    title: "Campaign",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  function handleAsset(rowProps) {
    const { customer_id, campaign_id, adgroup_id } = rowProps;

    switch (actionType) {
      case LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN:
        return [{ campaign_id: [campaign_id], customer_id }];

      case LEADGEN_REPORT_ASSET_TYPE_ADGROUP:
        return [{ adgroup_id: [adgroup_id], customer_id }];

      default:
        return [{}];
    }
  }

  function onSubmitUpdateName() {
    g_bulkNameUpdate({
      variables: {
        updateArray: handleAsset(rowPropsData),
        asset: actionType,
        name: editInput?.name,
      },
    });
  }

  const [g_bulkTargetRoasUpdate, { loading: g_bulkTargetRoasUpdate_loading }] = useCustomMutation(
    G360_BULK_TARGET_ROAS_UPDATE,
    {
      onCompleted() {
        openNotification({ type: "success", message: "Target ROAS updated successfully!" });
        onCloseNameModal();
        setEditInput({});
        refetch();
      },
      onError(e) {
        openNotification({ type: "error", message: "Something went wrong!" });
      },
    }
  );

  function onSubmitUpdateTargetRoas() {
    g_bulkTargetRoasUpdate({
      variables: {
        updateArray: handleAsset(rowPropsData),
        asset: actionType,
        value: parseFloat(editInput?.targetRoas),
      },
    });
  }

  const [g_bulkTargetCpaUpdate, { loading: g_bulkTargetCpaUpdate_loading }] = useCustomMutation(
    G360_BULK_TARGET_CPA_UPDATE,
    {
      onCompleted() {
        openNotification({ type: "success", message: "CPA target updated successfully!" });
        onCloseNameModal();
        setEditInput({});
        refetch();
      },
      onError(e) {
        openNotification({ type: "error", message: "Something went wrong!" });
      },
    }
  );

  function onSubmitUpdateCpaTarget() {
    g_bulkTargetCpaUpdate({
      variables: {
        updateArray: handleAsset(rowPropsData),
        asset: actionType,
        value: parseFloat(editInput?.cpa),
      },
    });
  }

  const [g_bulkMaximumConversion, { loading: g_bulkMaximumConversion_loading }] = useCustomMutation(
    G360_BULK_MAX_CONVERSION,
    {
      onCompleted() {
        openNotification({ type: "success", message: "Max conversion updated successfully!" });
        onCloseNameModal();
        setEditInput({});
        refetch();
      },
      onError(e) {
        openNotification({ type: "error", message: "Something went wrong!" });
      },
    }
  );

  function onSubmitMaxConversion() {
    g_bulkMaximumConversion({
      variables: {
        updateArray: handleAsset(rowPropsData),
        asset: actionType,
        value: 0,
      },
    });
  }

  const [g_bulkBudgetUpdate, { loading: g_bulkBudgetUpdate_loading }] = useCustomMutation(
    G360_BULK_BUDGET_UPDATE,
    {
      onCompleted() {
        openNotification({ type: "success", message: "Budget updated successfully!" });
        onCloseNameModal();
        setEditInput({});
        refetch();
      },
      onError(e) {
        openNotification({ type: "error", message: "Something went wrong!" });
      },
    }
  );

  function onSubmitUpdateBudget() {
    g_bulkBudgetUpdate({
      variables: {
        updateArray: handleAsset(rowPropsData),
        budgetType: "budget",
        value: parseFloat(editInput?.budget),
      },
    });
  }

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ revenueReport }, { spendReport }, { finalFilterData }],
    },
  ];

  return (
    <div>
      <DetailView
        {...drawerdetailsViewPropsUpdate}
        {...rowPropsData}
        performanceType={REPORT_TYPE_CAMPAIGN}
        reportType={REPORT_TYPE_CAMPAIGN}
      />
      <LookModal {...modalProps}>
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                label={`Name - ${currentValues?.name}`}
                className="mb-0"
                value={editInput?.name}
                placeholder="Enter name"
                onChange={(e) => setEditInput({ ...editInput, name: e.target.value })}
              />
            </Col>
            <Col span={24}>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  className="submit-btn mt-20"
                  onClick={onSubmitUpdateName}
                  disabled={g_bulkNameUpdate_loading}
                >
                  {g_bulkNameUpdate_loading ? <Loader /> : "Submit"}
                </Button>
              </Form.Item>
            </Col>

            {rowPropsData?.bidding_strategy_type === TARGET_CPA && (
              <>
                <Col span={24}>
                  <LookTextInput
                    label={`Target CPA - $${currentValues?.cpa}`}
                    className="mb-0"
                    value={editInput?.cpa}
                    placeholder="Enter Target CPA"
                    onChange={(e) => setEditInput({ ...editInput, cpa: e.target.value })}
                  />
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      className="submit-btn mt-20"
                      onClick={onSubmitUpdateCpaTarget}
                      disabled={g_bulkTargetCpaUpdate_loading}
                    >
                      {g_bulkTargetCpaUpdate_loading ? <Loader /> : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}

            {(rowPropsData?.bidding_strategy_type === TARGET_ROAS ||
              rowPropsData?.bidding_strategy_type === MAXIMIZE_CONVERSIONS) && (
              <>
                <Col span={24}>
                  <LookTextInput
                    label="Target ROAS"
                    className="mb-0"
                    value={editInput?.targetRoas}
                    placeholder="Target ROAS"
                    onChange={(e) => setEditInput({ ...editInput, targetRoas: e.target.value })}
                  />
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      className="submit-btn mt-20"
                      onClick={onSubmitUpdateTargetRoas}
                      disabled={g_bulkTargetRoasUpdate_loading}
                    >
                      {g_bulkTargetRoasUpdate_loading ? <Loader /> : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={8} className="mt-20">
                  <span>Maximum Conversion</span>
                </Col>
                <Col span={16}>
                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      className="submit-btn mt-10"
                      onClick={onSubmitMaxConversion}
                      disabled={
                        rowPropsData?.bidding_strategy_type === MAXIMIZE_CONVERSIONS ||
                        g_bulkMaximumConversion_loading
                      }
                    >
                      {g_bulkMaximumConversion_loading ? <Loader /> : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}

            {actionType === "CAMPAIGN" && (
              <>
                <Col span={24}>
                  <LookTextInput
                    label={`Budget - $${currentValues?.budget}`}
                    className="mb-0"
                    value={editInput?.budget}
                    placeholder="Budget"
                    onChange={(e) => setEditInput({ ...editInput, budget: e.target.value })}
                  />
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      className="submit-btn mt-20"
                      onClick={onSubmitUpdateBudget}
                      disabled={g_bulkBudgetUpdate_loading}
                    >
                      {g_bulkBudgetUpdate_loading ? <Loader /> : "Submit"}
                    </Button>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </LookModal>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
