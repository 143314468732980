import {
  USER_SETTING_SCORECARD_BY_DATE,
  USER_SETTING_SCORECARD_BY_MONTH,
} from "actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { defaultDateKeys, defaultMonthKeys } from "reducers/userSettings";

export default function useScorecardReducerDateSetter() {
  const dispatch = useDispatch();
  const yearMonthInput = useSelector((state) => state?.userSettings?.yearMonthInput);
  const dateInput = useSelector((state) => state?.userSettings?.dateInput);

  function checkScorecardDate() {
    const isValidMonthCheck = Object.keys(defaultMonthKeys).every(function (key, index) {
      if (!yearMonthInput?.[key]) {
        return false;
      }
      return true;
    });

    const isValidDateCheck = Object.keys(defaultDateKeys).every(function (key, index) {
      if (!dateInput?.[key]) {
        return false;
      }
      return true;
    });

    if (!isValidMonthCheck) {
      dispatch({
        type: USER_SETTING_SCORECARD_BY_MONTH,
        payload: { ...defaultMonthKeys },
      });
    }

    if (!isValidDateCheck) {
      dispatch({
        type: USER_SETTING_SCORECARD_BY_DATE,
        payload: { ...defaultDateKeys },
      });
    }
  }

  return { checkScorecardDate };
}
