import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { VS_DEVICE_REPORT, VS_REVENUE_CRON_STATUS } from "shared/gql/gqlSchema/viralSparksGql";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import LookTable from "components/LookTable";
import DebugPre from "components/DebugPre";
import SelectInput from "components/forms/SelectInput";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import Loader from "components/loader";

import moment from "moment";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useVSAdvertiserHandler, {
  VSCheckDefalulSelectedAdvertiser,
} from "../useVSAdvertiserHandler";
import { API_STORE_VIRALSPARKS_ADVERTISE } from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";

function MainReport(props) {
  const { handleAdvertiserSelection } = useVSAdvertiserHandler();
  const { selectedVSAdvertiser = "" } = useSelector((state) => state?.vsReducer) || {};
  const {
    performance = false,
    utmc_id,
    selectedAdvertiser: propSelectedAdvertiser,
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  } = props;
  const { filterData } = useLookTableFilterData(persistKey);
  const {
    data: data_VS_REVENUE_CRON_STATUS,
    loading: loading_VS_REVENUE_CRON_STATUS,
    error: error_VS_REVENUE_CRON_STATUS,
    refetch: refetch_VS_REVENUE_CRON_STATUS,
  } = useCustomQuery(VS_REVENUE_CRON_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: { id: 3 },
  });
  const cronTableStatus = (
    <div className="d-inline-flex">
      <p className="mb-0">
        Revenue Synced&nbsp;
        {moment
          .utc(data_VS_REVENUE_CRON_STATUS?.vs_cronStatus?.cron_status_time)
          .from(moment().tz("UTC"))}
      </p>
    </div>
  );
  const userSettings = useSelector((state) => state?.userSettings);
  const { userSettingData } = userSettings || {};
  const { vsAdvertiser = [], vsAdvertiser_loading = false } = userSettingData || {};

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const [report, setReport] = useState([]);
  const {
    loading: loading_VS_FIND_VS_COUNTRY_REPORT,
    error: error_VS_FIND_VS_COUNTRY_REPORT,
    refetch: refetch_GET_REPORT_DATA,
  } = useCustomQuery(VS_DEVICE_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_deviceReportsFromTo;

      if (element) {
        setReport(element);
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      utmc_id: utmc_id || undefined,
      advertiser_id: performance ? propSelectedAdvertiser : selectedVSAdvertiser,
    },
  });

  const loading = loading_VS_FIND_VS_COUNTRY_REPORT;
  const refetch = () => {
    refetch_GET_REPORT_DATA();
    refetch_VS_REVENUE_CRON_STATUS();
  };
  const initialColumns = [
    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.country?.localeCompare(b?.country),
      searchType: "string",
    },
    {
      title: "Revenue",
      dataIndex: "TotalRevenue",
      key: "TotalRevenue",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.TotalRevenue - b?.TotalRevenue,
      searchType: "number",
    },

    {
      title: "EPC",
      dataIndex: "EPC",
      key: "EPC",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.EPC - b?.EPC,
      searchType: "number",
    },

    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",
    },

    {
      title: "PPS",
      dataIndex: "PPS",
      key: "PPS",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PPS - b?.PPS,
      searchType: "number",
    },
    {
      title: "PRPM",
      dataIndex: "PRPM",
      key: "PRPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.PRPM - b?.PRPM,
      searchType: "number",
    },
  ];

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(3);
    return valueFloat;
  };

  let finalMerge = [];
  let finalMergeData = [];

  if (!loading) {
    finalMerge = report;

    finalMerge?.forEach((element, index) => {
      const {
        impressions = 0 | "0",
        sessions = 0 | "0",
        pageviews = 0 | "0",
        utmCampaign,
        adName = "",
        user_revenue = 0,
      } = element;

      const revenueUpdate = user_revenue;

      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        utmCampaign: utmCampaign || adName,
        TotalRevenue: valueCheck(revenueUpdate),
        key: index,
        EPC: valueCheck(EPC),
        IPP: valueCheck(IPP),
        RPM: valueCheck(RPM),
        PRPM: valueCheck(PRPM),
        PPS: valueCheck(PPS),
      });
    });
  }
  const finalFilterData = filterData(finalMergeData)?.filter(({ utmCampaign = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (utmCampaign?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageViews = 0,
  } of finalFilterData) {
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageViews || 0);
  }

  const total_EPC = total_TotalRevenue / total_sessions;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;

  const summaryData = {
    TotalRevenue: valueCheck(total_TotalRevenue, true),
    EPC: valueCheck(total_EPC, true),
    sessions: valueCheck(total_sessions, true),
    IPP: valueCheck(total_IPP, true),
    RPM: valueCheck(total_RPM, true),
    PRPM: valueCheck(total_PRPM, true),
    PPS: valueCheck(total_PPS, true),
  };

  const rightOptions = performance ? undefined : (
    <div style={{ width: 150 }}>
      <SelectInput
        search
        name="selectedAdvertiser"
        className="mb-0"
        placeholder="Select Advertiser"
        value={selectedVSAdvertiser}
        options={vsAdvertiser?.map(({ id, name }) => ({ name, value: id }))}
        onChange={(e) => handleAdvertiserSelection(e)}
      />
    </div>
  );

  const tableMainProps = {
    isSearchFilter: true,
    status: cronTableStatus,
    exportCSV: true,
    rightOptions,
    persistKey,
    isRowSelection: !performance,
    isDateRange: true,

    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
  };

  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ report }] },
  ];
  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default function VSDeviceReport(props) {
  const { apiCall: vsAdvertiserFetch = () => {}, refetch: vsAdvertiserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_ADVERTISE
  );
  const { performance = false } = props;
  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const debugContent = [{ isRow: true, contentObjects: [{ vsAdvertiser_loading, vsAdvertiser }] }];
  const headerProps = { title: "Device Report" };
  useEffect(() => {
    if (!performance) {
      vsAdvertiserFetch();
    }
  }, [performance]);
  return (
    <div>
      {performance ? (
        <>
          <MainReport {...props} />
        </>
      ) : (
        <>
          <VSCheckDefalulSelectedAdvertiser />
          <DashboardHeader {...headerProps} />
          {vsAdvertiser?.length === 0 && vsAdvertiser_loading ? <Loader center /> : <MainReport />}
        </>
      )}

      <DebugPre content={debugContent} />
    </div>
  );
}
