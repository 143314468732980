import LookButton from "components/LookButton";
import React, { useEffect, useRef } from "react";

const PreviewThumbnail = ({
  creatiVideo_refetch,
  videoRef,
  loading,
  closeModal,
  getBlob = () => {},
  closePreviewModal,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    return () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, []);

  const resolveBlob = () => {
    return new Promise((resolve, reject) => {
      const canvas = canvasRef.current;
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob); // Resolve the Promise with the blob
        } else {
          reject(new Error("Failed to save thumbnail.")); // Reject the Promise with an error
        }
      }, "image/png");
    });
  };

  async function saveThumbnail() {
    try {
      const thumbnailBlob = await resolveBlob();
      await getBlob(thumbnailBlob);
    } catch (error) {
      console.error(error); // Handle the error
    }
  }
  return (
    <>
      <canvas
        ref={canvasRef}
        className="m-auto d-table"
        style={{ maxWidth: 400, maxHeight: 400 }}
      />

      <div className="mt-18 m-auto d-flex justify-content-end">
        <LookButton
          className=""
          tooltipTitle=""
          iconType="close"
          type="primary"
          danger
          onClick={() => closeModal()}
        >
          Cancel
        </LookButton>
        <LookButton
          className="ml-8"
          tooltipTitle="Take snapshots"
          iconType="image"
          type="primary"
          onClick={saveThumbnail}
          loading={loading}
        >
          Save
        </LookButton>
      </div>
    </>
  );
};

export default PreviewThumbnail;
