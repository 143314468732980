import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { VOLUUM_GET_PUBLISHERS } from "shared/gql/gqlSchema/fb360Gql";
import { openNotification } from "utils";
import UpdateForm from "./UpdateForm";

function Publishers() {
  const [publishers, setPublishers] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDrawerUpdate, setVisibleDrawerUpdate] = useState(false);
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;

  const { loading, refetch } = useCustomQuery(VOLUUM_GET_PUBLISHERS, {
    onCompleted: (e) => {
      const element = e?.voluum_get_publishers?.data;
      setPublishers(element);
    },
    onError: (e) => {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps(rowProps);
    setVisibleDrawerUpdate(true);
  };
  const onCloseDrawerUpdate = () => {
    setRowProps({});
    setVisibleDrawerUpdate(false);
  };

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      render: (value, actionProps) => {
        return (
          <ActionIconRender
            onClick={() => showDrawerUpdate(actionProps)}
            className="pop-info"
            iconType="edit"
          />
        );
      },
      width: 100,
      minWidth: 100,
    },
  ];

  const initialColumns = [
    {
      title: "Publisher name",
      dataIndex: "publisher_name",
      key: "publisher_name",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (value) => <LookTableColumnRender title={value?.name} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Has Token",
      dataIndex: "has_token",
      key: "has_token",
      render: (value) => <LookTableColumnRender title={value ? "True" : "False"} />,
      width: 124,
      minWidth: 124,
    },
    ...action,
  ];

  const tableProps = {
    initialColumns,
    recordData: publishers,
  };

  const tableMainProps = {
    isSearchFilter: true,
    searchInclude: true,
    persistKey,
    exportCSV: true,
    refetch,
    loading,
    tableProps,
  };

  const drawerPropsUpdate = {
    title: "Update Publisher",
    icon: () => <ActionIconRender iconType="pic-left" />,
    visible: visibleDrawerUpdate,
    onClose: onCloseDrawerUpdate,
    size: 700,
  };

  return (
    <div>
      <DashboardHeader title="Publishers" />
      <LookDrawer {...drawerPropsUpdate}>
        {visibleDrawerUpdate && (
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Overview",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: <UpdateForm {...rowPropsData} />,
              },
            ]}
          />
        )}
      </LookDrawer>

      <LookTable {...tableMainProps} />
    </div>
  );
}

export default Publishers;
