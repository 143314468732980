import { useEffect, useState } from "react";
import alasql from "alasql";
import columns from "./columns";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useCustomQuery } from "hooks/apolloClientHooks";
import LookTable from "components/LookTable";
import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import {
  GET_REPORT_BY_USER,
  VS_REVENUE_CRON_STATUS,
  VS_USER_PERFORMANCE_REPORT,
} from "shared/gql/gqlSchema/viralSparksGql";
import SelectInput from "components/forms/SelectInput";
import DebugPre from "components/DebugPre";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import Loader from "components/loader";
import {
  API_STORE_VIRALSPARKS_ADVERTISE,
  API_STORE_VIRALSPARKS_PLATFORM,
} from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
import { SPEND_INCREASE_PERCENT_VS } from "shared/enum/commonKeysandEnum";

function MainReport(props) {
  const params = useParams();
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const { userReport = false } = props;
  const userSettings = useSelector((state) => state?.userSettings);
  const { userSettingData } = userSettings || {};
  const { vsAdvertiser = [], vsPlatforms = [] } = userSettingData || {};

  const [selectedPlatform, setSelectedPlatform] = useState("all");
  const [selectedAdvertiser, setSelectedAdvertiser] = useState("all");
  const { selectedWorkspace, dateRangeTimezone } =
    useSelector((state) => state?.userSettings) || {};

  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);

  const {
    data: data_VS_REVENUE_CRON_STATUS,
    loading: loading_VS_REVENUE_CRON_STATUS,
    error: error_VS_REVENUE_CRON_STATUS,
    refetch: refetch_VS_REVENUE_CRON_STATUS,
  } = useCustomQuery(VS_REVENUE_CRON_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: { id: 1 },
  });

  const [nickNameReport, setNickNameReport] = useState([]);
  const [spendReport, setSpendReport] = useState([]);
  const userIdForSpendReport = userReport ? { userId: params?.userId } : {};
  const isPlatform = selectedPlatform === "all" ? {} : { platform_id: selectedPlatform };
  const isAdvertiser = selectedAdvertiser === "all" ? {} : { advertiser_id: selectedAdvertiser };

  const {
    data: data_GET_REPORT_DATA,
    loading: loading_GET_REPORT_DATA,
    error: error_GET_REPORT_DATA,
    refetch: refetch_GET_REPORT_DATA,
  } = useCustomQuery(VS_USER_PERFORMANCE_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.vs_performaceReportsUser;
      if (element) {
        setNickNameReport(element);
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      ...isAdvertiser,
      ...isPlatform,
    },
  });

  const {
    data: data_GET_REPORT_BY_USER,
    loading: loading_GET_REPORT_BY_USER,
    error: error_GET_REPORT_BY_USER,
    refetch: refetch_GET_REPORT_BY_USER,
  } = useCustomQuery(GET_REPORT_BY_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.reportByUser;
      if (element) {
        setSpendReport(element);
      }
    },
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      workspaceId: selectedWorkspace,
      ...userIdForSpendReport,
    },
  });

  const loading = loading_GET_REPORT_DATA || loading_GET_REPORT_BY_USER;
  const refetch = () => {
    refetch_GET_REPORT_DATA();
    refetch_GET_REPORT_BY_USER();
    refetch_VS_REVENUE_CRON_STATUS();
  };

  const initialColumns = [
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",

      render: (value, otherProps) => {
        const { PROFIT } = otherProps;
        return (
          <LookTableColumnRender
            title={value}
            isProfit={PROFIT}
            // isClick={() => showDrawerUpdate(otherProps)}
          />
        );
      },
      width: 280,
      minWidth: 280,
      fixed: "left",
    },
    ...columns,
  ];

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(3);
    return valueFloat;
  };

  let finalMerge = [];
  let finalMergeData = [];
  // Left join because of different brands..from fb combined spend of all brands
  if (!loading) {
    let query = "SELECT * FROM ? as a LEFT JOIN ? as b ON a.user_id = b.userId";

    finalMerge = alasql(query, [nickNameReport, spendReport]);

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        leadsFB = 0,
        impressions = 0 | "0",
        sessions = 0 | "0",
        pageviews = 0 | "0",
        utm_campaign,
        adName = "",
        agency_revenue = 0,
        user_revenue = 0,
      } = element;

      const spendUpdate = (spendFB * SPEND_INCREASE_PERCENT_VS).toFixed(3);
      // const revenueUpdate = (TotalRevenue * 0.94).toFixed(2);

      const revenueUpdate = user_revenue;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(leadsFB) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        utmCampaign: utm_campaign || adName,
        TotalRevenue: valueCheck(revenueUpdate),
        jugaad: parseFloat(agency_revenue), // will remove in future
        clicksFB,
        leadsFB,
        impressionsFB,
        key: index,
        spendFB: spendUpdate,
        PROFIT: valueCheck(PROFIT),
        PROFIT_VIEW: valueCheck(PROFIT, true),
        CPC: valueCheck(CPC),
        CPC_VIEW: valueCheck(CPC, true),
        CPC_FB: valueCheck(CPC_FB),
        CPC_FB_VIEW: valueCheck(CPC_FB, true),
        ROI: valueCheck(ROI),
        ROI_VIEW: valueCheck(ROI, true),
        EPC: valueCheck(EPC),
        EPC_VIEW: valueCheck(EPC, true),
        CTR: valueCheck(CTR),
        CTR_VIEW: valueCheck(CTR, true),
        DISC: valueCheck(DISC),
        DISC_VIEW: valueCheck(DISC, true),
        IPP: valueCheck(IPP),
        RPM: valueCheck(RPM),
        PRPM: valueCheck(PRPM),
        PRPM_VIEW: valueCheck(PRPM, true),
        CPR: valueCheck(CPR),
        CPR_VIEW: valueCheck(CPR, true),
        PPS: valueCheck(PPS),
        PPS_VIEW: valueCheck(PPS, true),
        CPM: valueCheck(CPM),
        CPM_VIEW: valueCheck(CPM, true),
      });
    });
  }

  const finalFilterData = finalMergeData?.filter(({ utmCampaign = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (utmCampaign?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });

  let total_spendFB = 0,
    total_jugaad = 0,
    total_clicksFB = 0,
    total_impressionsFB = 0,
    total_leadsFB = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    leadsFB = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageViews = 0,
    jugaad = 0,
  } of finalFilterData) {
    total_spendFB += JSON.parse(spendFB || 0);
    total_clicksFB += JSON.parse(clicksFB || 0);
    total_impressionsFB += JSON.parse(impressionsFB || 0);
    total_leadsFB += JSON.parse(leadsFB || 0);
    total_impressions += JSON.parse(impressions || 0);
    total_TotalRevenue += JSON.parse(TotalRevenue || 0);
    total_sessions += JSON.parse(sessions || 0);
    total_pageViews += JSON.parse(pageViews || 0);
    total_jugaad += jugaad; // will remove in Future
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicksFB;
  const total_ROI = (total_PROFIT / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicksFB / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_leadsFB;
  const total_DISC = total_sessions / total_clicksFB;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const summaryData = {
    TotalRevenue: valueCheck(total_TotalRevenue, true),
    spendFB: valueCheck(total_spendFB, true),
    PROFIT: total_PROFIT.toFixed(3),
    ROI: valueCheck(total_ROI, true),
    EPC: valueCheck(total_EPC, true),
    CPC: valueCheck(total_CPC, true),
    CPC_FB: valueCheck(total_CPC_FB, true),
    CTR: valueCheck(total_CTR, true),
    leadsFB: valueCheck(total_leadsFB, true),
    CPR: valueCheck(total_CPR, true),
    sessions: valueCheck(total_sessions, true),
    clicksFB: valueCheck(total_clicksFB, true),
    DISC: valueCheck(total_DISC, true),
    IPP: valueCheck(total_IPP, true),
    RPM: valueCheck(total_RPM, true),
    PRPM: valueCheck(total_PRPM, true),
    PPS: valueCheck(total_PPS, true),
    jugaad: valueCheck(total_jugaad, true),
    CPM: valueCheck(total_CPM, true),
  };
  const tableStatus = (
    <div className="d-inline-flex" style={{ fontSize: 12 }}>
      <p className="mb-0">
        Revenue Synced&nbsp;
        {data_VS_REVENUE_CRON_STATUS?.cron_status_time}
        {moment
          .utc(data_VS_REVENUE_CRON_STATUS?.vs_cronStatus?.cron_status_time)
          .from(moment().tz("UTC"))}
      </p>
    </div>
  );

  const tableMainProps = {
    exportCSV: true,
    isDateRange: true,
    status: tableStatus,
    // rightOptions,

    defaultPagination: true,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    // uniqueKey,
  };

  const debugContent = [
    // { isRow: true, contentObjects: [{ vsPlatforms }] },
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ nickNameReport }, { spendReport }] },
  ];
  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({ ...rowProps });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => setVisibleDetailDrawer(false);

  const drawerdetailsViewPropsUpdate = {
    title: "Performance",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  return (
    <div>
      <LookTable
        {...tableMainProps}
        rightOptions={
          <>
            {vsPlatforms && vsPlatforms?.length > 1 && (
              <div className="mr-12" style={{ width: 150 }}>
                <SelectInput
                  search
                  name="selectedPlatform"
                  className="mb-0"
                  placeholder="Select Platform"
                  value={selectedPlatform}
                  options={[
                    { name: "All", value: "all" },
                    ...vsPlatforms?.map(({ id, name }) => ({ name, value: id })),
                  ]}
                  onChange={setSelectedPlatform}
                />
              </div>
            )}
            <div style={{ width: 150 }}>
              <SelectInput
                search
                name="selectedAdvertiser"
                className="mb-0"
                placeholder="Select Advertiser"
                value={selectedAdvertiser}
                options={[
                  { name: "All", value: "all" },
                  ...vsAdvertiser?.map(({ id, name }) => ({ name, value: id })),
                ]}
                onChange={setSelectedAdvertiser}
              />
            </div>
          </>
        }
      />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default function VSUsersReport() {
  const { apiCall: vsPlatformsFetch = () => {}, refetch: vsPlatformsRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_PLATFORM
  );
  const { apiCall: vsAdvertiserFetch = () => {}, refetch: vsAdvertiserRefetch } = useApiToStore(
    API_STORE_VIRALSPARKS_ADVERTISE
  );
  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const debugContent = [{ isRow: true, contentObjects: [{ vsAdvertiser_loading, vsAdvertiser }] }];
  const headerProps = { title: "User Report" };

  useEffect(() => {
    vsPlatformsFetch();
    vsAdvertiserFetch();
  }, []);

  return (
    <div>
      <DashboardHeader {...headerProps} />
      {vsAdvertiser?.length === 0 && vsAdvertiser_loading ? <Loader center /> : <MainReport />}
      <DebugPre content={debugContent} />
    </div>
  );
}
