import useApiToStoreQuery from "./useApiToStoreQuery";
import {
  G360_GET_COUNTRIES,
  G360_GET_LANGUAGES,
  GQL_GL_LAUNCH_CONFIG,
} from "shared/gql/gqlSchema/googleLaunch";

export default function useGoogleLaunchApis() {
  const {
    apiCall: googleLaunchConfigFetch = () => {},
    loading: googleLaunchConfigLoading,
    refetch: googleLaunchConfigRefetch,
    setDataSource: googleLaunchConfigSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_GL_LAUNCH_CONFIG,
    elementKey: "gl_launchConfig",
    storeKey: "googleLaunchConfig",
    objectType: true,
  });

  const {
    apiCall: googleLaunchCountriesFetch = () => {},
    loading: googleLaunchCountriesLoading,
    refetch: googleLaunchCountriesRefetch,
    setDataSource: googleLaunchCountriesSetDataSource,
  } = useApiToStoreQuery({
    api: G360_GET_COUNTRIES,
    elementKey: "gl_getCountries",
    storeKey: "googleLaunchCountries",
  });

  const {
    apiCall: googleLaunchLanguagesFetch = () => {},
    loading: googleLaunchLanguagesLoading,
    refetch: googleLaunchLanguagesRefetch,
    setDataSource: googleLaunchLanguagesSetDataSource,
  } = useApiToStoreQuery({
    api: G360_GET_LANGUAGES,
    elementKey: "gl_getLanguages",
    storeKey: "googleLaunchLanguages",
  });

  return {
    googleLaunchConfigFetch,
    googleLaunchConfigLoading,
    googleLaunchConfigRefetch,
    googleLaunchConfigSetDataSource,

    googleLaunchCountriesFetch,
    googleLaunchCountriesLoading,
    googleLaunchCountriesRefetch,
    googleLaunchCountriesSetDataSource,

    googleLaunchLanguagesFetch,
    googleLaunchLanguagesLoading,
    googleLaunchLanguagesRefetch,
    googleLaunchLanguagesSetDataSource,
  };
}
