import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  CMS_MANAGE_BLOCK,
  CMS_MANAGE_CATEGORY,
  CMS_MANAGE_DOMAIN,
  CMS_MANAGE_LIST,
  CMS_MANAGE_PRODUCT,
  CMS_MANAGE_STORY,
} from "../../shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const ListsV2 = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "ListsV2" */ "pages/dashboardPages/Cms/ListsV2"))
);
const ListsV1 = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "ListsV1" */ "pages/dashboardPages/Cms/Lists"))
);
const Store = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Store" */ "pages/dashboardPages/Cms/Store"))
);
const Brands = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Brands" */ "pages/dashboardPages/Cms/Brands"))
);
const DomainListing = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "DomainListing" */ "pages/dashboardPages/Cms/DomainListing")
  )
);
const Stories = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Stories" */ "pages/dashboardPages/Cms/Stories"))
);
const Products = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Products" */ "pages/dashboardPages/Cms/Products"))
);
const Block = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Block" */ "pages/dashboardPages/Cms/Block"))
);
const Categories = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Cms-Categories" */ "pages/dashboardPages/Cms/Categories")
  )
);
const Pages = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "Pages" */ "pages/dashboardPages/Cms/Pages"))
);
const ProsCons = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "ProsCons" */ "pages/dashboardPages/Cms/ProsCons"))
);
const User = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Cms-User" */ "pages/dashboardPages/Cms/Configuration/User")
  )
);
const Agency = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Cms-Agency" */ "pages/dashboardPages/Cms/Configuration/Agency")
  )
);

const cmsRoutes = [
  route("/cms/stories", Stories, ROUTE_DASHBOARD, false, CMS_MANAGE_STORY),
  route("/cms/pages", Pages, ROUTE_DASHBOARD, false, CMS_MANAGE_STORY),
  route("/cms/products", Products, ROUTE_DASHBOARD, false, CMS_MANAGE_PRODUCT),
  route("/cms/listsV2", ListsV2, ROUTE_DASHBOARD, false, CMS_MANAGE_LIST),
  route("/cms/listsV1", ListsV1, ROUTE_DASHBOARD, false, CMS_MANAGE_LIST),
  route("/cms/store", Store, ROUTE_DASHBOARD, false, CMS_MANAGE_LIST),
  route("/cms/brands", Brands, ROUTE_DASHBOARD, false, CMS_MANAGE_LIST),
  route("/cms/block", Block, ROUTE_DASHBOARD, false, CMS_MANAGE_BLOCK),
  route("/cms/categories", Categories, ROUTE_DASHBOARD, false, CMS_MANAGE_CATEGORY),
  route("/cms/domain", DomainListing, ROUTE_DASHBOARD, false, CMS_MANAGE_DOMAIN),
  route("/cms/pros-cons", ProsCons, ROUTE_DASHBOARD, false, CMS_MANAGE_PRODUCT),
  route("/cms/user", User, ROUTE_DASHBOARD, true, CMS_MANAGE_DOMAIN),
  route("/cms/agency", Agency, ROUTE_DASHBOARD, true, CMS_MANAGE_DOMAIN),
];

export default cmsRoutes;
