import { gql } from "@apollo/client";

export const TAG_ADD_TAGS = gql`
  mutation Tag_addTags(
    $tags: [String]
    $identifier: [String]
    $type: String
    $is_for_all: Boolean
  ) {
    tag_addTags(tags: $tags, identifier: $identifier, type: $type, is_for_all: $is_for_all)
  }
`;

export const TAG_DELETE_TAGS = gql`
  mutation Tag_deleteTags($identifier: String, $tags: String) {
    tag_deleteTags(identifier: $identifier, tags: $tags)
  }
`;

export const TAG_GET_IDENTIFIER_TAG = gql`
  query Tag_getTags($type: String, $identifier: [String]) {
    tag_getTags(type: $type, identifier: $identifier) {
      user_id
      identifier
      last_edit
      tags
      type
      id
    }
  }
`;

export const TAG_GET_ALL_USER_TAG = gql`
  query Tag_getUserTags($type: String, $identifier: [String], $not_budget: Boolean) {
    tag_getUserTags(type: $type, identifier: $identifier, not_budget: $not_budget) {
      user_id
      identifier
      last_edit
      tags
      type
      id
    }
  }
`;

export const TAG_ONLY_TAGS = gql`
  query tag_onlyTags($type: String) {
    tag_onlyTags(type: $type)
  }
`;

export const TAG_GET_IDENTIFIERS = gql`
  query Query($type: String, $tags: [String]) {
    tag_getIdentifiers(type: $type, tags: $tags)
  }
`;
