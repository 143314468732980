import { Result } from "antd";
import Loader from "components/loader";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { INDUSTRY_INSIGHTS_CONNECT_ACCOUNT_BY_CODE } from "shared/gql/gqlSchema/industryInsightsGql";
import { openNotification } from "utils";
export default function OAuthFbInsightsCallback() {
  const location = useLocation();
  const [adaccounts, setAdaccounts] = useState([]);
  const urlString = location?.search;
  const params = new URLSearchParams(urlString);
  const url = window.location.origin;
  var redirectUri;
  if (url.includes("localhost")) {
    redirectUri = "localhost";
  } else if (url.includes("dashboard")) {
    redirectUri = "dashboard";
  } else if (url.includes("staging")) {
    redirectUri = "staging";
  } else if (url.includes("sandbox")) {
    redirectUri = "sandbox";
  }
  let int = useRef();
  const [fbConnect, { loading }] = useCustomMutation(INDUSTRY_INSIGHTS_CONNECT_ACCOUNT_BY_CODE, {
    onCompleted: (e) => {
      console.log({ e });
      setAdaccounts(
        e?.ii_signInAndSync?.map((el, i) => {
          return { ...el, key: i };
        })
      );
      clearTimeout(int.current);
      localStorage.setItem("code", true);
    },
    onError: () => {
      clearTimeout(int.current);
      int.current = setTimeout(() => window.close(), 8000);
      localStorage.setItem("code", false);
      openNotification({ type: "error", description: "Error Please Retry" });
    },
  });

  useEffect(() => {
    const cleanup = int.current;
    if (params.get("code")) {
      fbConnect({ variables: { code: params.get("code"), redirectUrl: redirectUri } });
    }
    return () => clearTimeout(cleanup);
  }, []);
  return (
    <div className="h-100 dashboard-content flex-center">
      {loading ? (
        <Result
          icon={<Loader className="mb-10" center={true} largeLoader={true} />}
          subTitle={<h2 style={{ fontSize: "2.7vw" }}>Accounts Loading⚡️.... Please Wait.....</h2>}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
