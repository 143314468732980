import { useState, Fragment } from "react";
import { Row, Col, Button, Tooltip } from "antd";
import { useSelector } from "react-redux";
import ActionIconRender from "../ActionIconRender";
import { ClickToCopyButton } from "utils";

function Content(props) {
  const { isRow = false, contentObjects = [], maxHeight = 500 } = props;
  const [visible, setVisible] = useState(true);

  const Wrapper = isRow ? Row : Fragment;
  const ContentWrapper = isRow ? Col : Fragment;

  return (
    <div>
      <Tooltip title={`Click to ${visible ? "hide" : "show"}`} placement="left">
        <Button
          type="link"
          icon={<ActionIconRender iconType={visible ? "eye" : "eye-invisible"} />}
          size="small"
          onClick={() => setVisible((pre) => !pre)}
        />
      </Tooltip>
      <ClickToCopyButton
        successText="Object copied!"
        tooltipText="Click to copy object"
        copyText={JSON.stringify(contentObjects, null, 2)}
      />

      {visible && (
        <Wrapper>
          {contentObjects?.map((data, index) => {
            const isContentWrapperProps = isRow
              ? { key: index, span: 24 / contentObjects?.length }
              : {};

            return (
              <ContentWrapper {...isContentWrapperProps}>
                <ClickToCopyButton
                  successText="Object copied!"
                  tooltipText="Click to copy object"
                  copyText={JSON.stringify({ ...data }, null, 2)}
                />
                <pre style={{ maxHeight }}>{JSON.stringify({ ...data }, null, 2)}</pre>
              </ContentWrapper>
            );
          })}
        </Wrapper>
      )}
    </div>
  );
}

export default function DebugPre(props) {
  const { content = [] } = props;
  const { lf_superadmin, isPseudoUser } = useSelector((state) => state?.userReducer.me) || {};
  const { debugMode = false } = useSelector((state) => state?.userSettings) || {};
  if (!((lf_superadmin || isPseudoUser) && debugMode)) return null;
  return (
    <div>
      {content?.map((data, index) => (
        <Content key={"content" + index} {...data} />
      ))}
    </div>
  );
}
