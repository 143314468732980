import { Alert, Button, Card, Col, Divider, Modal, Row, Skeleton } from "antd";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useMemo, useState } from "react";
import {
  ADD_MEDIA_FROM_TEMPLATE,
  CREATIVE_BUILDER_FETCH_TEMPLATES,
  GET_TEMPLATE_VARIATIONS_FROM_TEMPLATE,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import AudioPlayer from "./AudioPlayer";
import {
  CREATIVE_TYPE_AUDIO,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_TEXT,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import useMediaDrawer from "pages/dashboardPages/Creatives/CreativeHooks/useMediaDrawer";
import { openNotification } from "utils";
import { CREATE_MEDIA_FROM_TEMPLATE } from "shared/gql/gqlSchema/creativeDashboardGql";
import LookButton from "components/LookButton";
import EditVideo from "components/Launch/Creatives/GoogleCreativeVideoTrimming/EditVideo";
import useMoodBoardDrawer from "pages/dashboardPages/Creatives/CreativeHooks/useMoodBoardDrawer";
import LookDrawer from "components/LookDrawer";
import CreativesModal from "./CreativesModal";
import { truncate } from "lodash";

function UpdateCreativeBuilder(props) {
  const {
    id = "",
    categoryId = [],
    r2Url = "",
    templateType = "",
    setFinalVariantsData = () => {},
    finalVariantsData,
    creativeCount = "",
    creativeType = "",
    user = {},
    name,
    onClose = () => {},
    mutationData,
    setActiveParentTab = () => {},
    setCurrentRowData = () => {},
    setShowVariantState = () => {},
  } = props;

  const [creativeTemplates, setCreativeTemplates] = useState([]);
  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 3 }, () => 10));
  const [texts, setTexts] = useState([]);
  const [media, setMedia] = useState([]);
  const [audio, setAudio] = useState([]);
  const { openMediaDrawer, mediaDrawer } = useMediaDrawer();
  const [finalJsonData, setFinalJsonData] = useState([]);
  const [finalObjectForAddMedia, setFinalObjectForAddMedia] = useState([]);
  const [payloadForAddMedia, setPayloadForAddMedia] = useState([]);
  const [videoData, setVideoData] = useState("");
  const [trimmingDuration, setTrimmingDuration] = useState("");
  const [openPintura, setPinturaOpen] = useState(false);
  const [pinturaConfirmLoading, setPinturaConfirmLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState("");
  const { createMoodBoard, createForm } = useMoodBoardDrawer();

  const pinturaShowModal = (data, index) => {
    setResult("");
    setVideoData({ ...data, pinturaIndex: index });
    setTrimmingDuration(data?.duration);
    setPinturaOpen(true);
    setLoading(true);
  };
  const pinturaHandleCancel = () => {
    setPinturaOpen(false);
    setSelectedVideo([]);
    setVideoData("");
    setResult("");
  };

  const {
    loading: CREATIVE_BUILDER_FETCH_TEMPLATES_templates_loading,
    refetch: CREATIVE_BUILDER_FETCH_TEMPLATES_templates_refetch,
  } = useCustomQuery(CREATIVE_BUILDER_FETCH_TEMPLATES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ctGetCreativeTemplates?.data;
      if (element) {
        setTexts([]);
        setMedia([]);
        setAudio([]);
        setCreativeTemplates(element);
      }
    },
    variables: {
      templateId: id,
    },
  });

  const elementsData = useMemo(() => {
    try {
      return creativeTemplates?.map((item) => JSON.parse(item?.templateDetails?.source_code));
    } catch (error) {
      openNotification({
        type: "error",
        key: "creativeTemplates-error",
        message: "Something is wrong, Please add new template",
      });
    }
  }, [creativeTemplates]);

  const apiData = creativeTemplates?.[0]?.templateDetails?.modifications;
  let newData;
  if (apiData) {
    newData = mergeData(apiData, elementsData?.[0]?.elements);
  }

  function mergeData(modifications, secondData) {
    let finalData = [];
    for (const key in modifications) {
      const value = modifications[key];
      const matchingData = secondData?.find((item) => item.name === key);
      if (matchingData && elementsData?.length > 0) {
        matchingData.urlData = value.value;
        matchingData.id = value.id;

        function cleanInput(input) {
          if (typeof input !== "string") {
            // If not a string, convert it to one
            input = String(input);
          }
          // Use regex to remove non-numeric characters except for '.' and '/'
          return input.replace(/[^0-9/.]/g, "");
        }
        if (!("duration" in matchingData) || matchingData.duration === null) {
          if (!("time" in matchingData)) {
            matchingData.duration = parseFloat(cleanInput(elementsData?.[0]?.duration))?.toFixed(1);
          } else {
            matchingData.duration = (
              parseFloat(cleanInput(elementsData?.[0]?.duration)) -
              parseFloat(matchingData?.time.slice(0, -2))
            ).toFixed(1);
          }
        } else if (matchingData.time === "0 s") {
          matchingData.duration = parseFloat(cleanInput(elementsData?.[0]?.duration)).toFixed(1);
        }
      }
      finalData.push({ ...matchingData });
    }
    return finalData;
  }

  const separatedData = useMemo(() => {
    return newData?.reduce(
      (result, element) => {
        if (
          element.type === CREATIVE_TYPE_VIDEO?.toLowerCase() ||
          element.type === CREATIVE_TYPE_IMAGE?.toLowerCase()
        ) {
          result.videosAndImages.push([element]);
        } else if (element.type === CREATIVE_TYPE_AUDIO?.toLowerCase()) {
          result.audio.push([element]);
        } else if (element.type === CREATIVE_TYPE_TEXT?.toLowerCase()) {
          result.texts.push([element]);
        }
        return result;
      },
      { videosAndImages: [], texts: [], audio: [] }
    );
  }, [elementsData]);

  useEffect(() => {
    if (separatedData) {
      setTexts(separatedData.texts);
      setMedia(separatedData.videosAndImages);
      setAudio(separatedData.audio);
    }
  }, [separatedData]);

  //   const foundArray = media.find((array) =>
  //     dataBus.every((obj) => array.some((item) => item.id === obj.uniqueId))
  //   );
  //   if (foundArray) {
  //     dataBus.forEach((obj) => {
  //       if (!foundArray.some((item) => item.id === obj.id)) {
  //         foundArray.push(obj);
  //       }
  //     });
  //   }
  //   const foundSecondArray = audio.find((array) =>
  //     dataBus.every((obj) => array.some((item) => item.id === obj.uniqueId))
  //   );
  //   if (foundSecondArray) {
  //     dataBus.forEach((obj) => {
  //       if (!foundSecondArray.some((item) => item.id === obj.id)) {
  //         foundSecondArray.push(obj);
  //       }
  //     });
  //   }

  //   const foundThirdArray = texts.find((array) =>
  //     dataBus.every((obj) => array.some((item) => item.id === obj.uniqueId))
  //   );
  //   if (foundThirdArray) {
  //     dataBus.forEach((obj) => {
  //       if (!foundThirdArray.some((item) => item.id === obj.id)) {
  //         foundThirdArray.push(obj);
  //       }
  //     });
  //   }
  // }, [dataBus, media, audio, texts]);

  function extractData(data, nameField, urlField) {
    const separatedData = [];

    for (const innerArray of data) {
      const resultArray = [];

      for (const item of innerArray) {
        if (item[nameField] && (item[urlField] || item.s3Url)) {
          resultArray.push({ name: item[nameField], urlData: item[urlField] || item.s3Url });
        }
      }
      separatedData.push(resultArray);
    }
    return separatedData;
  }

  function extractDataForAddMedia(data, nameField, urlField, typeField, id) {
    const separatedData = [];

    for (const innerArray of data) {
      const resultArray = [];

      for (const item of innerArray) {
        if (item[nameField] && (item[urlField] || item.s3Url)) {
          resultArray.push({
            name: item[nameField],
            urlData: item[urlField] || item.s3Url,
            type: item[typeField],
            id: item[id],
          });
        }
      }
      separatedData.push(resultArray);
    }
    return separatedData;
  }

  useEffect(() => {
    const mediaDataForAddMedia = extractDataForAddMedia(media, "name", "urlData", "type", "id");
    const audioDataForAddMedia = extractDataForAddMedia(audio, "name", "urlData", "type", "id");
    const textsDataForAddMedia = extractDataForAddMedia(texts, "name", "text", "type", "id");
    const generateCombinations = (arrays, index = 0, currentCombination = {}) => {
      if (index === arrays.length) {
        combinations.push({ ...currentCombination });
      } else {
        const currentArray = arrays[index];
        for (const item of currentArray) {
          const name = item.name;
          const type = item.type;
          const id = item.id;
          currentCombination[name] = { urlData: item.urlData, type, id };
          generateCombinations(arrays, index + 1, currentCombination);
          delete currentCombination[name];
        }
      }
    };

    const arrays = [...mediaDataForAddMedia, ...audioDataForAddMedia, ...textsDataForAddMedia];
    const combinations = [];
    generateCombinations(arrays);
    // setFinalObjectForAddMedia(combinations)
    const result = combinations?.map((item) => {
      const organizedData = {
        audioClipIds: [],
        imageClipIds: [],
        videoClipIds: [],
        textClipIds: [],
      };

      for (const key in item) {
        if (item[key].type === CREATIVE_TYPE_AUDIO?.toLowerCase()) {
          organizedData.audioClipIds.push(item[key].id);
        } else if (item[key].type === CREATIVE_TYPE_IMAGE?.toLowerCase()) {
          organizedData.imageClipIds.push(item[key].id);
        } else if (item[key].type === CREATIVE_TYPE_VIDEO?.toLowerCase()) {
          organizedData.videoClipIds.push(item[key].id);
        } else if (item[key].type === CREATIVE_TYPE_TEXT?.toLowerCase()) {
          organizedData.textClipIds.push(item[key].id);
        }
      }

      return organizedData;
    });
    setFinalObjectForAddMedia(result);
  }, [media, audio, texts]);

  useEffect(() => {
    const mediaData = extractData(media, "name", "urlData");
    const audioData = extractData(audio, "name", "urlData");
    const textsData = extractData(texts, "name", "text");
    const generateCombinations = (arrays, index = 0, currentCombination = {}) => {
      if (index === arrays.length) {
        combinations.push({ ...currentCombination });
      } else {
        const currentArray = arrays[index];
        for (const item of currentArray) {
          currentCombination[item.name] = item.urlData;
          generateCombinations(arrays, index + 1, currentCombination);
          delete currentCombination[item.name];
        }
      }
    };
    const arrays = [...mediaData, ...audioData, ...textsData];
    const combinations = [];
    generateCombinations(arrays);
    const mergedArray = combinations?.map((item, index) => ({
      ...item,
      clips: finalObjectForAddMedia[index],
    }));
    const jsonObjects = mergedArray?.map((obj) => JSON.stringify(obj));
    setFinalJsonData(jsonObjects);
  }, [media, audio, texts, finalObjectForAddMedia]);

  const [postFinalJson, { loading: loading_CREATE_MEDIA }] = useCustomMutation(
    CREATE_MEDIA_FROM_TEMPLATE,
    {
      variables: {
        templateId: id,
        mediaType: templateType,
        modifications: finalJsonData,
      },
      onCompleted: (e) => {
        openNotification({ type: "success", message: "Creatives Generate Successfully" });
        const element = e?.ctCreateMediaFromTemplate?.data;
        if (element) {
          const finalData = element?.map((ele) => {
            const { __typename, ...props } = ele;
            return { ...props };
          });
          setShowVariantState(true);
          setActiveParentTab("temporary-creative");
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  function submitFormData() {
    postFinalJson();
  }
  function submitAddMediaData() {
    const resultArray = payloadForAddMedia?.map((obj) => {
      const { mediaId, mediaUrl, modification, ...rest } = obj;
      return { ...rest, creatomateId: mediaId, creatomateUrl: mediaUrl, ...modification };
    });
    addMedia({ variables: { modifications: resultArray } });
  }

  const [addMedia, { loading: loading_ADD_MEDIA }] = useCustomMutation(ADD_MEDIA_FROM_TEMPLATE, {
    variables: {
      templateId: id,
      mediaType: templateType,
    },
    onCompleted: (e) => {
      const element = e?.ctAddMediaFromTemplate;
      if (element?.status === "SUCCESS") {
        openNotification({ type: "success", message: "Media Added Successfully" });

        // showModal();
        getVariants();
        // setMutationData([]);
      } else {
        openNotification({ type: "error", message: element?.message });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [
    getVariants,
    { loading: GET_TEMPLATE_VARIATIONS_loading, refetch: GET_TEMPLATE_VARIATIONS_refetch },
  ] = useCustomLazyQuery(GET_TEMPLATE_VARIATIONS_FROM_TEMPLATE, {
    notifyOnNetworkStatusChange: true,
    variables: { templateId: id },
    onCompleted: (e) => {
      const element = e?.ctGetTemplateVariations;
      if (element?.status === "SUCCESS") {
        setFinalVariantsData(element?.data);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const modalProps = {
    mutationData,
    finalVariantsData,
    // setMutationData,
    templateType,
    isPreview: true,
    active: "InProgress",
  };
  const pinturaModalProps = {
    ...props,
  };

  return (
    <>
      {mediaDrawer()}
      {createForm()}
      <div className="custom-row">
        <Row>
          <Col span={16} className="left-column">
            <Row>
              {media?.length > 0 && (
                <Col span={24}>
                  <p className="mb-5 mt-6">
                    <ActionIconRender iconType="play" /> <b>Media</b>
                  </p>
                </Col>
              )}

              {CREATIVE_BUILDER_FETCH_TEMPLATES_templates_loading &&
                skeletonSize?.map((e) => (
                  <Col style={{ margin: "36.8px 0 0 16px" }} span={4} xs={12} sm={8} md={6} xl={4}>
                    <div className={cx("creative-image-selection ant-image-ration-1-1")}>
                      <Skeleton.Image
                        className="w-100"
                        style={{ width: "100%", height: "100px" }}
                        active={true}
                      />
                    </div>
                  </Col>
                ))}
              <Col span={24}>
                {media?.map((mediaData, mediaIndex) => (
                  <Row key={mediaIndex}>
                    {mediaData?.map((data, index) => {
                      return (
                        <>
                          <Row key={data?.id} className="align-items-center">
                            {index > 0 ? (
                              <></>
                            ) : (
                              <Col
                                span={6}
                                xs={12}
                                sm={8}
                                md={6}
                                xl={9}
                                style={{ marginRight: "16px", cursor: "pointer" }}
                                onClick={() =>
                                  openMediaDrawer({
                                    creativeType:
                                      data?.type === CREATIVE_TYPE_IMAGE?.toLowerCase()
                                        ? CREATIVE_TYPE_IMAGE
                                        : CREATIVE_TYPE_VIDEO,
                                    setMedia: setMedia,
                                    mediaIndex: mediaIndex,
                                    isCreativeBuilder: true,
                                    isMediaLibrary_cb: true,
                                    media: media,
                                    mediaName: `${data?.name}`,
                                    mediaDuration: `${data?.duration}`,
                                    requestData: {
                                      categoryId: categoryId?.[0]?.productCategoryId,
                                      productId: categoryId?.[0]?.productId,
                                      categoryName: categoryId?.[0]?.name,
                                    },
                                  })
                                }
                              >
                                <div>
                                  {data?.type.toUpperCase() === CREATIVE_TYPE_IMAGE ? (
                                    <p className="mb-0">{data?.name}</p>
                                  ) : (
                                    <p className="mb-0">
                                      {`${data?.name}: ${data?.duration}${
                                        data?.duration?.charAt(data?.duration?.length - 1) === "s"
                                          ? ""
                                          : "s"
                                      }`}
                                    </p>
                                  )}
                                  <div className="add-more-section-media">
                                    <div className="add-more-section-media-contect">
                                      <ActionIconRender
                                        iconType="plus-circle"
                                        style={{ fontSize: "22px" }}
                                      />
                                      <h5>Add more</h5>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                            <div
                              span={24}
                              xs={12}
                              sm={8}
                              md={8}
                              xl={9}
                              style={{
                                width: 100,
                                height: 100,
                                borderRadius: "8px",
                                margin: "16px",
                                cursor: "pointer",
                              }}
                              className="image-hover-action-wrapper"
                            >
                              <div
                                style={{
                                  marginTop: "16px",
                                  width: "100px",
                                  height: "100px",
                                  // display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                                className="image-hover-action-wrapper"
                              >
                                {data.type === "image" ? (
                                  <div style={{ height: "100px", width: "100px" }}>
                                    <img
                                      src={data?.urlData || data?.s3Url}
                                      alt={"altText"}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                    {mediaData?.length > 1 ? (
                                      <LookButton
                                        className="hover-action"
                                        size="small"
                                        style={{ border: "none" }}
                                        tooltipTitle="Delete Image"
                                        iconType="delete-filled"
                                        onClick={() => {
                                          setMedia((media) => {
                                            const updatedItems = [...media];
                                            updatedItems[mediaIndex] = media?.[mediaIndex]?.filter(
                                              (mediaData) => mediaData?.id !== data?.id
                                            );

                                            return updatedItems;
                                          });
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    <div style={{ height: "100px", width: "100px" }}>
                                      <video
                                        src={data?.urlData || data?.s3Url}
                                        controls
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>

                                    <div className="hover-action">
                                      {mediaData?.length > 1 ? (
                                        <LookButton
                                          size="small"
                                          style={{ border: "none" }}
                                          tooltipTitle="Delete Image"
                                          iconType="delete-filled"
                                          onClick={() => {
                                            setMedia((media) => {
                                              const updatedItems = [...media];
                                              updatedItems[mediaIndex] = media?.[
                                                mediaIndex
                                              ]?.filter((mediaData) => mediaData?.id !== data?.id);
                                              return updatedItems;
                                            });
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      <LookButton
                                        size="small"
                                        style={{ border: "none" }}
                                        // className="w-100 hover-edit-button-creative-builder"
                                        iconType="edit"
                                        onClick={() => pinturaShowModal(data, mediaIndex)}
                                      ></LookButton>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Row>
                        </>
                      );
                    })}
                  </Row>
                ))}
              </Col>
            </Row>
            <Row>
              {audio?.length > 0 && (
                <Col span={24}>
                  <p className="mt-5">
                    <ActionIconRender iconType="music" /> <b>Audio</b>
                  </p>
                </Col>
              )}

              {CREATIVE_BUILDER_FETCH_TEMPLATES_templates_loading &&
                skeletonSize?.map((e) => (
                  <Col style={{ margin: "36.8px 0 0 16px" }} span={4} xs={12} sm={8} md={6} xl={4}>
                    <div className={cx("creative-image-selection ant-image-ration-1-1")}>
                      <Skeleton.Node
                        className="w-100"
                        active={truncate}
                        style={{ width: "100%", height: "50px" }}
                      >
                        <ActionIconRender
                          style={{ fontSize: "22px", marginRight: "5px" }}
                          iconType="music"
                        />
                      </Skeleton.Node>
                    </div>
                  </Col>
                ))}
              <Col span={24}>
                {audio?.map((audioData, audioIndex) => (
                  <Row key={audioIndex}>
                    {audioData?.map((data, index) => {
                      return (
                        <>
                          <Row key={data?.id}>
                            {index > 0 ? (
                              <></>
                            ) : (
                              <Col
                                span={12}
                                xs={12}
                                sm={8}
                                md={6}
                                xl={11}
                                style={{
                                  cursor: audioData.length > 2 ? "not-allowed" : "pointer",
                                }}
                                onClick={() =>
                                  openMediaDrawer({
                                    creativeType: CREATIVE_TYPE_AUDIO,
                                    audio: audio,
                                    setAudio: setAudio,
                                    audioIndex: audioIndex,
                                    isCreativeBuilder: true,
                                    isMediaLibrary_cb: true,
                                    audioName: `${data?.name}`,
                                    audioDuration: `${data?.duration}`,
                                    requestData: {
                                      categoryId: categoryId?.[0]?.productCategoryId,
                                      productId: categoryId?.[0]?.productId,
                                      categoryName: categoryId?.[0]?.name,
                                    },
                                  })
                                }
                              >
                                <div>
                                  {data?.name}:&nbsp;&nbsp; {data?.duration}s
                                  <div className="add-more-section-audio">
                                    <div className="add-more-section-audio-content">
                                      <Row>
                                        <ActionIconRender
                                          iconType="plus-circle"
                                          style={{ fontSize: "22px", marginRight: "5px" }}
                                        />
                                        <h4>Add new</h4>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                            <div
                              span={12}
                              xs={12}
                              sm={8}
                              md={8}
                              xl={9}
                              style={{
                                width: 100,
                                borderRadius: "8px",
                                margin: "32px 16px 16px 16px",
                                cursor: "pointer",
                              }}
                              className="hover-col-creative-builder-audio"
                            >
                              <div>
                                {/* <>
                                  <div className="custom-audio-player">
                                    <audio
                                      className="audio-player-creative-builder"
                                      id={elementId}
                                      // ref={audioRef}
                                    >
                                      <source src={data.urlData || data.s3Url} type="audio/mpeg" />
                                      Your browser does not support the audio element.
                                    </audio>
                                    <div className="main-controls-div">
                                      <div className="controls">
                                        <span>{formatTime(duration)} s</span>
                                      </div>
                                      <div className="controls-icons">
                                        <LookButton
                                          size="small"
                                          tooltipTitle={isPlaying ? "Pause Audio" : "Play Audio"}
                                          iconType={isPlaying ? "pause" : "play"}
                                          style={{ border: "none" }}
                                          onClick={() => {
                                            isPlaying
                                              ? pauseAudio(elementId)
                                              : playAudio(elementId);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </> */}
                                <AudioPlayer
                                  audioUrl={data?.urlData || data?.s3Url}
                                  durations={data?.duration}
                                  id={data?.id}
                                  key={index}
                                  audioData={audioData}
                                  audioIndex={audioIndex}
                                  index={index}
                                  setAudio={setAudio}
                                />
                              </div>
                            </div>
                          </Row>
                        </>
                      );
                    })}
                  </Row>
                ))}
              </Col>
            </Row>

            <Row>
              {texts?.length > 0 && (
                <Col span={24}>
                  <p className="mt-5">
                    <ActionIconRender iconType="font-size" />
                    &nbsp;<b>Text</b>
                  </p>
                </Col>
              )}

              {CREATIVE_BUILDER_FETCH_TEMPLATES_templates_loading &&
                skeletonSize?.map((e) => (
                  <Col style={{ margin: "36.8px 0 0 16px" }} span={4} xs={12} sm={8} md={6} xl={4}>
                    <div className={cx("creative-image-selection ant-image-ration-1-1")}>
                      <Skeleton.Node
                        className="w-100"
                        active={truncate}
                        style={{ width: "100%", height: "150px" }}
                      >
                        <ActionIconRender
                          style={{ fontSize: "22px", marginRight: "5px" }}
                          iconType="font-size"
                        />
                      </Skeleton.Node>
                    </div>
                  </Col>
                ))}
              <Col span={24}>
                {texts?.map((textData, textIndex) =>
                  textData?.map((data, index) => {
                    return (
                      <Row>
                        {index > 0 ? (
                          <></>
                        ) : (
                          <Col
                            span={6}
                            xs={24}
                            sm={24}
                            md={24}
                            xl={24}
                            style={{ marginRight: "16px", cursor: "pointer" }}
                            onClick={() =>
                              openMediaDrawer({
                                creativeType: CREATIVE_TYPE_TEXT,
                                textIndex: textIndex,
                                setTexts: setTexts,
                                texts: texts,
                                textName: `${data?.name}`,
                                isCreativeBuilder: true,
                                isMediaLibrary_cb: true,
                                requestData: {
                                  categoryId: categoryId?.[0]?.productCategoryId,
                                  productId: categoryId?.[0]?.productId,
                                  categoryName: categoryId?.[0]?.name,
                                },
                              })
                            }
                            disabled={index < 2}
                          >
                            <div className="mt-5">
                              <p className="mb-0">{data?.name}</p>
                              <div className="add-more-section-text">
                                <div className="add-more-section-text-content">
                                  <ActionIconRender
                                    iconType="plus-circle"
                                    style={{ fontSize: "22px" }}
                                  />
                                  <h5>Click here to add</h5>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )}
                        <Col
                          span={24}
                          xs={24}
                          sm={24}
                          md={24}
                          xl={24}
                          className="hover-col-creative-builder-texts"
                        >
                          <>
                            <Card
                              style={{
                                width: 664,
                                height: 44,
                                borderRadius: "8px",
                                background: "transparent",
                                padding: "0 !important",
                                marginTop: "5px ",
                              }}
                              className="creative-builder-texts-card-class"
                            >
                              {/* <h3>{data?.name}</h3> */}
                              <p>{data.text?.slice(0, 108)}</p>
                            </Card>
                            {textData?.length > 1 ? (
                              <LookButton
                                className="hover-delete-button-creative-builder-texts"
                                size="small"
                                style={{ border: "none" }}
                                tooltipTitle="Delete Image"
                                iconType="delete-filled"
                                onClick={() => {
                                  setTexts((text) => {
                                    const updatedItems = [...text];
                                    updatedItems[textIndex] = text?.[textIndex]?.filter(
                                      (textData) => textData?.id !== data?.id
                                    );
                                    return updatedItems;
                                  });
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        </Col>
                      </Row>
                    );
                  })
                )}
              </Col>
            </Row>
          </Col>
          <Divider span={1} type="verticle" className="vertical-divider" />
          <Col span={7} className="right-column">
            <h4>Properties</h4>
            {creativeType === CREATIVE_TYPE_VIDEO ? (
              <video className="w-100 media-player" src={r2Url} controls />
            ) : (
              <img className="w-100 media-player" alt="template_image" src={r2Url} controls />
            )}
            <Row>
              <Col span={22}>
                <p>Name: {name}</p>
              </Col>
              <Col span={2}>
                <ActionIconRender iconType="important" theme="outlined" />
              </Col>
            </Row>
            <Row>
              <b>
                <p className="mb-0">Template Categeory :</p>
              </b>
              <p className="mb-0">{categoryId?.[0]?.name}</p>
            </Row>

            <Row className="bordered-row" style={{ padding: "10px" }}>
              <Col span={11}>
                <Row className="text-center">Created by</Row>
                <Row className="text-center">
                  <b>{user?.name}</b>
                </Row>
              </Col>
              <Divider type="vertical" style={{ height: "50px" }} />
              <Col span={11} className="px-3">
                <Row className="text-center">Creative Count</Row>
                <Row className="text-center">
                  <b>{creativeCount}</b>
                </Row>
              </Col>
            </Row>
            {props?.categoryId?.length > 0 && (
              <Button
                className="mt-10"
                style={{ width: "100%" }}
                onClick={() =>
                  createMoodBoard({
                    id: categoryId?.[0].productCategoryId,
                    productId: categoryId?.[0]?.productId,
                    name: categoryId?.[0]?.name,
                    isRequestView: true,
                  })
                }
              >
                {" "}
                View Moodboard
              </Button>
            )}
          </Col>
        </Row>
      </div>

      <Row gutter={15} style={{ backgroundColor: "#262626" }}>
        <Col span={11} style={{ padding: "8px" }}>
          <Button block onClick={() => onClose()}>
            Cancel
          </Button>
        </Col>
        <Col span={12} style={{ padding: "8px" }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            className="ml-10 "
            loading={loading_CREATE_MEDIA}
            disabled={loading_CREATE_MEDIA}
            onClick={() => {
              submitFormData();
            }}
          >
            Generate Creatives
          </Button>
        </Col>
      </Row>

      {openPintura && (
        <Modal
          title="Trimmer"
          open={openPintura}
          onOk={pinturaHandleCancel}
          confirmLoading={pinturaConfirmLoading}
          onCancel={pinturaHandleCancel}
          width={1000}
          footer={null}
        >
          <EditVideo
            props={pinturaModalProps}
            videoData={videoData}
            setPinturaOpen={setPinturaOpen}
            trimmingDuration={trimmingDuration}
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            loading={loading}
            setLoading={setLoading}
            openPintura={openPintura}
            result={result}
            setMedia={setMedia}
            setResult={setResult}
            categoryId={categoryId?.[0]?.productCategoryId}
            productId={categoryId?.[0]?.productId}
            categoryName={categoryId?.[0]?.name}
          />
        </Modal>
      )}
    </>
  );
}

export default UpdateCreativeBuilder;
