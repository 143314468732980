import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Slider,
  Switch,
  Tooltip,
} from "antd/lib";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import React, { useEffect, useState } from "react";
import {
  BID_STRATEGY_LIST,
  C_TYPE_ABO,
  LAUNCH_CALL_TO_ACTION_ENUM,
} from "shared/enum/launchKeysAndEnum";
import moment from "moment";
import { LAUNCH_TARGETING_FETCH_CITY } from "shared/gql/gqlSchema/launchGql";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import {
  HIGHEST_VOLUME_BID,
  LAUNCH_COUNTRIES_ENUM,
  SPONSORED_MESSAGES,
} from "shared/enum/iCampaignLaunchEnumArray";
import Loader from "components/loader";
import { useSelector } from "react-redux";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS } from "shared/SharedKeys";
import {
  API_STORE_F360_LAUNCH_CONFIG,
  API_STORE_F360_TARGETING_COUNTRIES,
  API_STORE_TARGETING_LOCALES,
} from "hooks/useApiToStore/keys";
import { useApiToStore } from "hooks";
import cx from "cx";

function FacebookForm(props) {
  const {
    launch = false,
    cType = "ABO",
    campaignBidStrategy,
    onClose = () => {},
    onSubmit = () => {},
    inputs,
    setInputs = () => {},
    templatePlatforms,
    platformsData,
    excludedRegion,
    setExcludedRegion = () => {},
    includedRegion,
    setIncludedRegion = () => {},
    productId,
    setFbInitialStringCopy,
    setTargettingTemplateData,
    showTargetingLaunch,
  } = props;
  const [errors, setErrors] = useState({});
  const [isIncluded, setIsIncluded] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { apiCall: launchConfigFetch = () => {}, refetch: launchConfigRefetch } = useApiToStore(
    API_STORE_F360_LAUNCH_CONFIG
  );

  const { apiCall: targetingCountriesFetch = () => {}, refetch: targetingCountriesRefetch } =
    useApiToStore(API_STORE_F360_TARGETING_COUNTRIES);

  const { apiCall: targetingLocalesFetch = () => {}, refetch: targetingLocalesRefetch } =
    useApiToStore(API_STORE_TARGETING_LOCALES);

  useEffect(() => {
    launchConfigFetch();
    targetingCountriesFetch();
    targetingLocalesFetch();

    const fbData = {
      ...inputs,
      facebookPositions: facebookPositions?.length
        ? facebookPositions
        : facebook_positions.map((i) => i.value),
      instagramPositions: instagramPositions?.length
        ? instagramPositions
        : devicePlatforms?.includes("mobile")
        ? instagram_positions.map((i) => i.value)
        : [],
      audienceNetworkPositions: audienceNetworkPositions?.length
        ? audienceNetworkPositions
        : devicePlatforms?.includes("mobile") && audience_network_positions.map((i) => i.value),
      messengerPositions: messengerPositions?.length
        ? messengerPositions
        : messenger_positions.map((i) => i.value),
      templatePlatform: templatePlatforms,
    };
    setInputs(fbData);
    if (showTargetingLaunch) {
      setFbInitialStringCopy(JSON.stringify(fbData));
    }
  }, []);
  const { userSettingData, selectedWorkspace } = useSelector((state) => state?.userSettings) || {};
  const {
    launchConfig,
    targetingLocales = [],
    targetingLocales_loading = false,
  } = userSettingData || {};

  const { targeting, ad_set = {}, campaign = {}, validation = {} } = launchConfig || {};
  const { objective: objective_config = [] } = campaign;
  const {
    optimization_goal: optimization_goal_config = [],
    destination_type: destination_type_config = [],
  } = ad_set;

  const {
    countries_group = [],
    device_platforms = [],
    publisher_platforms = [],
    facebook_positions = [],
    instagram_positions = [],
    audience_network_positions = [],
    messenger_positions = [],
    user_os = [],
  } = targeting || {};
  const [validatedOptions, setValidatedOptions] = useState({
    optimizationGoal: optimization_goal_config,
  });

  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings || {};

  const getValidatedOptions = (objectiveType) => {
    const validatedData = validation?.filter((data) => data?.["OBJECTIVE"] === objectiveType);
    if (validatedData?.length > 0) {
      const keys = Object.keys(validatedData[0]?.["LOCATION"])?.filter((data) => data !== "NONE");

      setValidatedOptions((prev) => ({
        ...prev,
        destinations: keys?.map((key) => ({ name: key, value: key })),
      }));
    }
  };
  const getValidatedGoals = (locations) => {
    const validatedData = validation?.filter((data) => data?.["OBJECTIVE"] === objective);
    if (validatedData?.length > 0) {
      const keys = Object.keys(validatedData[0]?.["LOCATION"]);
      const locationKey = keys?.filter((key) => key === locations);
      const finalOptimizationGoalOptions = validatedData[0]?.["LOCATION"]?.[locationKey]?.map(
        (data) => ({ name: Object.keys(data)?.[0], value: data[Object.keys(data)?.[0]] })
      );
      setValidatedOptions((prev) => ({ ...prev, optimizationGoal: finalOptimizationGoalOptions }));
    }
  };
  const [fetchCities, { loading: fetchCities_loading, refetch: fetchCities_refetch }] =
    useCustomLazyQuery(LAUNCH_TARGETING_FETCH_CITY, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.searchTargeting;
        if (element) {
          setSearchValue("");
          if (isIncluded) {
            const filteredIncludedCountries = countries?.filter(
              (countryCode) => !element?.some((data) => data?.country_code === countryCode)
            );
            const updatedIncludedRegions = element?.map((data) => ({
              name: data?.query,
              key: data?.key,
              country_code: data?.country_code,
            }));
            const updatedExcludedRegions = excludedRegions?.filter(
              (excludedItem) =>
                !updatedIncludedRegions?.some((elem) => elem.key === excludedItem?.key)
            );
            setInputs({
              ...inputs,
              countries: filteredIncludedCountries,
              includedRegions: [...updatedIncludedRegions, ...includedRegions]?.filter(
                (item, index, self) => index === self.findIndex((o) => o?.key === item?.key)
              ),
              excludedRegions: updatedExcludedRegions,
            });

            setIncludedRegion((prev) => [...prev, ...updatedIncludedRegions]);
          } else {
            const filteredExcludedCountries = excludedCountries?.filter(
              (countryCode) => !element?.some((data) => data?.country_code === countryCode)
            );
            const updatedExcludedRegions = element?.map((data) => ({
              name: data?.query,
              key: data?.key,
              country_code: data?.country_code,
            }));
            const updatedIncludedRegions = includedRegions?.filter(
              (includedItem) => !element?.some((elem) => elem.key === includedItem?.key)
            );
            setInputs({
              ...inputs,
              excludedCountries: filteredExcludedCountries,
              includedRegions: updatedIncludedRegions,
              excludedRegions: [...updatedExcludedRegions, ...excludedRegions]?.filter(
                (item, index, self) => index === self.findIndex((o) => o?.key === item?.key)
              ),
            });
            setExcludedRegion((prev) => [...prev, ...updatedExcludedRegions]);
          }
        }
      },
    });
  function renderGender() {
    const genderList = [
      { name: "All", value: "All" },
      { name: "Male", value: 1 },
      { name: "Female", value: 2 },
    ];
    return (
      <div className="mb-10 ">
        <p>Gender</p>
        <Radio.Group onChange={handleInput} value={gender} name="gender">
          <span className="d-flex">
            {genderList.map(({ value, name }) => (
              <Radio value={value}>{name}</Radio>
            ))}
          </span>
        </Radio.Group>
      </div>
    );
  }

  function renderBidStrategy() {
    return (
      <div className="mb-10">
        <SelectInput
          label="Campaign Bid Strategy"
          name="bidStrategy"
          value={bidStrategy}
          className="mb-0"
          error={errors?.bidStrategy}
          onChange={(e) => handleKeyInput("bidStrategy", e)}
          options={BID_STRATEGY_LIST?.map(({ name, value }) => ({ name, value }))}
        />
      </div>
    );
  }
  function templateForm() {
    const budgetType = [
      { name: "Daily", value: "DAILY" },
      { name: "LifeTime", value: "LIFE_TIME" },
    ];

    return (
      <>
        {!launch && (
          <SelectInput
            label="Platform"
            search
            disabled
            value={templatePlatforms}
            // onChange={(e) => handleKeyInput("templatePlatform", e)}
            options={platformsData?.templatePlatforms?.map(({ id, name }) => ({
              name,
              value: id,
            }))}
          />
        )}
        {!launch && (
          <LookTextInput
            label="Targeting Template Name"
            name="name"
            value={name}
            onChange={handleInput}
            disabled={launch}
            className="look-form-input"
            error={errors.name}
          />
        )}

        {JSON.stringify(inputs?.isDynamicCreative) && (
          <div className="d-flex">
            <p className="mr-12">Is Dynamic</p>
            <Switch
              checked={inputs.isDynamicCreative}
              onChange={(e) => handleKeyInput("isDynamicCreative", e)}
            />
          </div>
        )}
        {cType === "ABO" && (
          <>
            <p className="mt-20"> Budget (in $)</p>
            <Radio.Group
              name="budgetTypes"
              value={budgetTypes}
              onChange={handleInput}
              className="mb-10 "
            >
              {budgetType.map(({ value, name }) => (
                <Radio value={value}>{name}</Radio>
              ))}
            </Radio.Group>
            <div className="look-form-input">
              <Form.Item name="budget">
                <InputNumber
                  min={1}
                  value={budget}
                  controls={false}
                  onChange={(e) => handleKeyInput("budget", e)}
                  style={{ width: "100%", borderColor: "#404040" }}
                />
                {errors.budget && <span className="text-left error-text">{errors.budget}</span>}
              </Form.Item>
            </div>
          </>
        )}

        {launch ? (
          <Row gutter={[12, 12]}>
            {cType === C_TYPE_ABO && <Col span={12}>{renderBidStrategy()}</Col>}

            {cType === C_TYPE_ABO ? (
              <Col span={12}>
                {bidStrategy && bidStrategy !== "LOWEST_COST_WITHOUT_CAP" && (
                  <LookTextInput label="Bid" name="bid" value={bid} onChange={handleInput} />
                )}
              </Col>
            ) : (
              <Col span={12}>
                {campaignBidStrategy && campaignBidStrategy !== "LOWEST_COST_WITHOUT_CAP" && (
                  <LookTextInput label="Bid" name="bid" value={bid} onChange={handleInput} />
                )}
              </Col>
            )}
          </Row>
        ) : (
          <Row gutter={[12, 12]}>
            <Col span={12}>{renderBidStrategy()}</Col>
            <Col span={12}>
              {bidStrategy && bidStrategy !== "LOWEST_COST_WITHOUT_CAP" && (
                <LookTextInput label="Bid" name="bid" value={bid} onChange={handleInput} />
              )}
            </Col>
          </Row>
        )}
        {launch && (
          <>
            {/* <p>
              <b>Account Timezone based Date & Time: </b>
              {moment().tz(dateRangeTimezone?.timeZone?.value).format("YYYY-MM-DD HH:mm")}(
              {dateRangeTimezone?.timeZone?.value})
            </p> */}
            <div className="d-flex" style={{ gap: "20px" }}>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <Form.Item label="Scheduled Start Date" className="look-form-input">
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    showNow={false}
                    format="YYYY-MM-DD HH:mm"
                    value={scheduledStart_Date}
                    onChange={(date, dateString) => {
                      setInputs({ ...inputs, scheduledStart_Date: date });
                    }}
                    // disabledDate={(current) =>
                    //   (current && current < moment().tz(dateRangeTimezone?.timeZone?.value)) ||
                    //   current > scheduledEnd_Date
                    // }
                  />
                  <div>
                    {errors.scheduledStart_Date && (
                      <span className="text-left error-text">{errors.scheduledStart_Date}</span>
                    )}
                  </div>
                </Form.Item>
              </div>
              <div>
                {/* {JSON.stringify({ scheduledEnd_Date })} */}
                <Form.Item label="Scheduled End Date" formik className="look-form-input">
                  <DatePicker
                    showTime={{ format: "HH:mm" }}
                    showNow={false}
                    // disabledDate={
                    //   (value) =>
                    //     value &&
                    //     value.isBefore(
                    //       moment(scheduledEnd_Date)
                    //         .subtract(1, "day")
                    //         .tz(dateRangeTimezone?.timeZone?.value)
                    //     )
                    //   // value < moment(scheduleStartTime).tz(dateRangeTimezone?.timeZone?.value)
                    // }
                    format="YYYY-MM-DD HH:mm"
                    // value direct pass remove moment wrapper
                    value={scheduledEnd_Date}
                    onChange={(date, dateString) => {
                      setInputs({ ...inputs, scheduledEnd_Date: date });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </>
        )}

        {(!launch || showTargetingLaunch) && (
          <SelectInput
            label="Campaign Objective"
            search
            name="objective"
            value={objective}
            error={errors?.objective}
            onChange={(e) => {
              getValidatedOptions(e);
              setInputs({
                ...inputs,
                objective: e,
                destinationType: "",
                optimizationGoal: "",
              });
            }}
            options={objective_config}
          />
        )}
        {objective && (
          <SelectInput
            label="Conversion Location"
            search
            name="destinationType"
            // error={errors?.countries}
            value={destinationType}
            onChange={(e) => {
              getValidatedGoals(e);
              setInputs({
                ...inputs,
                destinationType: e,
              });
            }}
            options={validatedOptions?.destinations || []}
          />
        )}

        <SelectInput
          label="Performance Goal"
          search
          name="optimizationGoal"
          error={errors?.optimizationGoal}
          value={optimizationGoal}
          onChange={(e) => handleKeyInput("optimizationGoal", e)}
          options={validatedOptions?.optimizationGoal || []}
        />

        <SelectInput
          label="CTA"
          search
          name="cta"
          value={cta}
          onChange={(e) => handleKeyInput("cta", e)}
          options={LAUNCH_CALL_TO_ACTION_ENUM?.map(({ name, value }) => ({
            name,
            value,
          }))}
          error={errors?.cta}
        />
      </>
    );
  }

  function platforms() {
    return (
      <>
        <div className="mb-10">
          <p>Publisher Platform</p>
          <Checkbox.Group
            options={publisher_platforms?.map(({ name, value }) => ({ label: name, value }))}
            value={publisherPlatforms}
            onChange={(e) => {
              handleKeyInput("publisherPlatforms", e);
            }}
          />
        </div>
        {errors.publisherPlatforms && (
          <span className="text-left error-text">{errors.publisherPlatforms}</span>
        )}
      </>
    );
  }

  // fb insta messenger positions on the basis of device platforms
  const handlePlatforms = (e) => {
    let newFbArr;
    if (e.length === 1) {
      newFbArr = e.includes("mobile")
        ? facebook_positions.filter((i) => {
            return i.value !== "instant_article";
          })
        : facebook_positions.filter((i) => {
            return i.value !== "right_hand_column";
          });
    }
    let instaArr;
    instaArr = e.includes("desktop") ? [] : instagram_positions.map((i) => i.value);

    let messengerArr;
    let modifiedMessengerPositions =
      productId !== PRODUCT_ID_SAS && bidStrategy === HIGHEST_VOLUME_BID
        ? messenger_positions?.filter((i) => i.value !== SPONSORED_MESSAGES)
        : messenger_positions;

    messengerArr = e.includes("desktop")
      ? modifiedMessengerPositions?.filter((i) => i.value !== "story")
      : modifiedMessengerPositions?.filter((i) => i.value !== "messenger_home");

    let audience_network;

    audience_network = e.includes("desktop") ? [] : audience_network_positions.map((i) => i.value);

    setInputs({
      ...inputs,
      devicePlatforms: e,
      facebookPositions:
        newFbArr?.length > 0
          ? newFbArr?.map((i) => i.value)
          : facebook_positions.map((i) => i.value),
      instagramPositions: instaArr?.length > 0 ? instaArr : [],
      messengerPositions:
        e.length === 1
          ? messengerArr?.map((i) => i.value)
          : modifiedMessengerPositions?.map((i) => i.value),
      audienceNetworkPositions: audience_network?.length > 0 ? audience_network : [],
    });
  };

  function devices() {
    return (
      <>
        <div className="mb-10">
          <Tooltip
            placement="topLeft"
            title="This feature will enable you to multiply campaigns by device."
          >
            <div className="d-flex">
              <p className="mr-12">{showTargetingLaunch ? "Multi" : ""} Device</p>
              {showTargetingLaunch && (
                <div className="d-flex">
                  <Switch
                    checked={inputs?.multiDevices}
                    onChange={(e) => handleKeyInput("multiDevices", e)}
                  />
                </div>
              )}
            </div>
          </Tooltip>
          <Checkbox.Group
            value={devicePlatforms}
            options={device_platforms?.map(({ name, value }) => ({ label: name, value }))}
            onChange={(e) => {
              handleKeyInput("devicePlatforms", e);
              handlePlatforms(e);
            }}
          />
        </div>
        {errors.devicePlatforms && (
          <span className="text-left error-text">{errors.devicePlatforms}</span>
        )}
      </>
    );
  }
  function mobileDevices() {
    return (
      <>
        <div className="mb-10">
          <p>Mobile Os</p>
          <Checkbox.Group
            value={userOs}
            options={[
              ...user_os?.map(({ name, value }) => ({ label: name, value })),
              { label: "All", value: "All" },
            ]}
            onChange={(e) => {
              if (userOs?.includes("All"))
                handleKeyInput(
                  "userOs",
                  e.filter((item) => item !== "All")
                );
              else if (e?.includes("All")) {
                handleKeyInput("userOs", ["All"]);
              } else {
                handleKeyInput("userOs", e);
              }
            }}
          />
        </div>
        {/* {errors.devicePlatforms && (
          <span className="text-left error-text">{errors.devicePlatforms}</span>
        )} */}
      </>
    );
  }

  const {
    name,
    isManual,
    budgetTypes,
    gender,
    cta,
    objective,
    countries,
    countryGroups,
    publisherPlatforms,
    scheduledEnd_Date,
    scheduledStart_Date,
    devicePlatforms,
    age,
    optimizationGoal,
    bidStrategy,
    userOs,
    // countryGroups,
    locales,
    facebookPositions,
    instagramPositions,
    audienceNetworkPositions,
    messengerPositions,
    budget,
    bid,
    excludedRegions,
    regions,
    includedRegions,
    destinationType,
    excludedCountries,
    multiCountry,
  } = inputs;
  const handleSubmit = (event) => {
    let err = {};

    // if (!name) {
    //   err.name = "Name is required";
    // }
    if (!objective) {
      err.objective = "Objective is required";
    }
    if (!cta) {
      err.cta = "Cta is required";
    }
    if (!budget) {
      err.budget = "Budget is required";
    }
    if (!optimizationGoal) {
      err.optimizationGoal = "Optimization Goal is required";
    }
    if (isManual) {
      if (publisherPlatforms.length === 0) {
        err.publisherPlatforms = "Publisher Platform is required";
      }
      if (devicePlatforms.length === 0) {
        err.devicePlatforms = "Device Platform is required";
      }
    }
    if (!bidStrategy) {
      err.bidStrategy = "Bid Strategy is required";
    }
    // if (countries.length === 0) {
    //   err.countries = "Countries is required";
    // }
    const dateFormat = "YYYY-MM-DD HH:mm";

    const defaultDate = [
      moment().tz(dateRangeTimezone?.timeZone?.value).format(dateFormat),
      moment().tz(dateRangeTimezone?.timeZone?.value).format(dateFormat),
    ];

    if (launch && scheduledStart_Date && scheduledStart_Date < defaultDate[0]) {
      err.scheduledStart_Date = "Selected Date is invalid ";
    }

    setErrors(err);
    console.log(err);
    if (Object.keys(err).length === 0) {
      onSubmit();
    }
  };

  function handleKeyInput(name, value) {
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  function handleInput(e) {
    handleKeyInput([e.target.name], e.target.value);
  }

  const marks = {
    13: "13",
    18: "18",
    25: "25",
    35: "35",
    45: "45",
    65: "65+",
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit}>
        <div className={cx("container", { showTargetingLaunch })}>
          <div className={cx("left", { showTargetingLaunch })}>
            {templateForm()}

            <SelectInput
              label="Country Groups"
              search
              multiple
              name="countryGroups"
              // error={errors?.countries}
              value={countryGroups}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  countryGroups: e,
                  countries: [],
                })
              }
              options={countries_group}
            />
            <Row gutter={[12, 12]}>
              <Col span={12}>
                {showTargetingLaunch && (
                  <Tooltip
                    placement="topLeft"
                    title="This feature will enable you to multiply campaigns by country."
                  >
                    <div className="d-flex align-items-center">
                      <p className="mt-6 mr-12 mb-6">Multiple Countries</p>
                      <Switch
                        checked={multiCountry}
                        onChange={(e) => handleKeyInput("multiCountry", e)}
                      />
                    </div>
                  </Tooltip>
                )}
                <SelectInput
                  search
                  multiple
                  label={showTargetingLaunch ? null : "Countries"}
                  name="countries"
                  error={errors?.countries}
                  value={countries}
                  onChange={(e) => {
                    const isIncludedCountriesRegions = includedRegions?.filter(
                      (data) => !e?.includes(data?.country_code)
                    );
                    setInputs({
                      ...inputs,
                      countryGroups: [],
                      countries: e,
                      includedRegions: isIncludedCountriesRegions,
                    });
                  }}
                  options={LAUNCH_COUNTRIES_ENUM.filter(
                    (item) => !excludedCountries?.includes(item.value)
                  )}
                />
              </Col>

              <Col span={12}>
                <div className={showTargetingLaunch ? "mt-6" : ""}>
                  <SelectInput
                    label="Excluded Countries"
                    multiple
                    search
                    name="excludedCountries"
                    value={excludedCountries}
                    onChange={(e) => {
                      const isExcludedCountriesRegions = excludedRegions?.filter(
                        (data) => !e?.includes(data?.country_code)
                      );
                      setInputs({
                        ...inputs,
                        excludedCountries: e,
                        excludedRegions: isExcludedCountriesRegions,
                      });
                    }}
                    options={LAUNCH_COUNTRIES_ENUM.filter(
                      (item) => !countries?.includes(item.value)
                    )}
                  />
                </div>
              </Col>
            </Row>
            {productId === PRODUCT_ID_LEADGEN && (
              <>
                <div>
                  <p> Excluded Regions</p>
                  <div className="d-flex position-relative mb-24">
                    <Select
                      showSearch
                      className="w-100"
                      mode="multiple"
                      placeholder={"Press enter to search"}
                      onSearch={(value) => setSearchValue(value)}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      searchValue={searchValue}
                      filterOption={false}
                      notFoundContent={null}
                      value={excludedRegions?.map((item) => item.key)}
                      // loading={fetchCities_loading}
                      onChange={(e) => {
                        setSearchValue("");
                        const updatedExcludedRegions = e?.map((item) => {
                          const isExistingIndex = excludedRegions?.findIndex(
                            (excludedItem) => excludedItem?.key === item
                          );

                          if (isExistingIndex === -1) {
                            return {
                              key: item,
                              name: excludedRegion?.filter((i) => i.key === item)[0]?.name,
                              country_code: excludedRegion?.filter((i) => i.key === item)[0]
                                ?.country_code,
                            };
                          }
                          return excludedRegions[isExistingIndex];
                        });
                        const updatedIncludedRegions = includedRegions?.filter(
                          (includedItem) => !e?.includes(includedItem?.key)
                        );
                        setInputs({
                          ...inputs,
                          excludedRegions: updatedExcludedRegions,
                          includedRegions: updatedIncludedRegions,
                        });
                      }}
                      onInputKeyDown={(e) => {
                        if (isIncluded) {
                          setIsIncluded(false);
                        }
                        if (e.key === "Enter") {
                          e.preventDefault();
                          const query = e.target.value.split(/[,!;]+/).filter(Boolean);
                          fetchCities({
                            variables: {
                              locationTypes: ["region"],
                              query,
                              targetingSearchType: "adgeolocation",
                            },
                          });
                        }
                      }}
                      options={excludedRegion
                        ?.filter(
                          (item, index, self) =>
                            index === self.findIndex((o) => o?.key === item?.key)
                        )
                        ?.map((item) => {
                          return { label: item?.name, value: item?.key };
                        })}
                    />
                    {fetchCities_loading && !isIncluded && <Loader rightCenter spiner />}
                  </div>
                </div>

                <div>
                  <p> Included Regions</p>
                  <div className="d-flex position-relative mb-24">
                    <Select
                      showSearch
                      onSearch={(value) => setSearchValue(value)}
                      className="w-100"
                      mode="multiple"
                      placeholder={"Press enter to search"}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      notFoundContent={null}
                      searchValue={searchValue}
                      value={includedRegions?.map((item) => item?.key)}
                      // loading={fetchCities_loading}
                      onChange={(e) => {
                        setSearchValue("");
                        const updatedIncludedRegions = e?.map((item) => {
                          const isExistingIndex = includedRegions?.findIndex(
                            (includedItem) => includedItem?.key === item
                          );
                          if (isExistingIndex === -1) {
                            return {
                              key: item,
                              name: includedRegion?.filter((i) => i.key === item)[0]?.name,
                            };
                          }
                          return includedRegions?.[isExistingIndex];
                        });
                        const updatedExcludedRegions = excludedRegions?.filter(
                          (excludedItem) => !e?.includes(excludedItem?.key)
                        );

                        setInputs({
                          ...inputs,
                          includedRegions: updatedIncludedRegions,
                          excludedRegions: updatedExcludedRegions,
                        });
                      }}
                      onInputKeyDown={(e) => {
                        if (!isIncluded) {
                          setIsIncluded(true);
                        }
                        if (e.key === "Enter") {
                          e.preventDefault();
                          const query = e.target.value.split(/[,!;]+/).filter(Boolean);
                          fetchCities({
                            variables: {
                              locationTypes: ["region"],
                              query,
                              targetingSearchType: "adgeolocation",
                            },
                          });
                        }
                      }}
                      options={includedRegion
                        ?.filter(
                          (item, index, self) =>
                            index === self.findIndex((o) => o?.key === item?.key)
                        )
                        ?.map((item) => {
                          return { label: item?.name, value: item?.key };
                        })}
                    />
                    {fetchCities_loading && isIncluded && <Loader rightCenter spiner />}
                  </div>
                </div>
              </>

              //   </Col>
              // </Row>
            )}
            <SelectInput
              multiple
              label="Language"
              search
              value={locales}
              onChange={(e) => handleKeyInput("locales", e)}
              options={targetingLocales?.map(({ key, name }) => ({ name, value: parseInt(key) }))}
              loader={targetingLocales_loading && targetingLocales?.length === 0}
            />
          </div>
          <div className={cx("right", { showTargetingLaunch })}>
            <Row gutter={[12, 12]}>
              <Col span={9}>{renderGender()}</Col>
              <Col span={12}>
                <div>
                  <p>AGE</p>
                  <Slider
                    min={13}
                    max={65}
                    defaultValue={[18, 65]}
                    marks={marks}
                    className="ageSlider"
                    onChange={(e) => {
                      handleKeyInput("age", e);
                    }}
                    range
                    value={age}
                  />
                </div>
              </Col>
            </Row>

            <h3>Placements</h3>
            <div className="mb-12">
              <div className="d-flex">
                <p className="mr-12">Manual</p>
                <Switch checked={inputs.isManual} onChange={(e) => handleKeyInput("isManual", e)} />
              </div>
              {isManual && (
                <>
                  <Row gutter={[12, 12]}>
                    <Col span={12}>{devices()}</Col>

                    <Col span={12}>{platforms()}</Col>
                  </Row>
                  <Row gutter={[12, 12]}>{mobileDevices()}</Row>
                  <Row gutter={12}>
                    {inputs?.publisherPlatforms?.includes("facebook") && (
                      <>
                        <Col span={8}>
                          <p>Facebook Positions</p>
                          <Checkbox.Group
                            value={facebookPositions}
                            onChange={(e) => handleKeyInput("facebookPositions", e)}
                            options={facebook_positions?.map(({ name, value }) => ({
                              label: name,
                              value,
                            }))}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "10px",
                            }}
                          />
                        </Col>
                      </>
                    )}

                    {inputs.publisherPlatforms?.includes("instagram") && (
                      <>
                        <Col span={8}>
                          <p>Instagram Positions</p>
                          <Checkbox.Group
                            value={instagramPositions}
                            onChange={(e) => handleKeyInput("instagramPositions", e)}
                            options={instagram_positions?.map(({ name, value }) => ({
                              label: name,
                              value,
                            }))}
                            style={{ display: "flex", flexDirection: "column" }}
                          />
                        </Col>
                      </>
                    )}

                    {inputs.publisherPlatforms?.includes("messenger") && (
                      <>
                        <Col span={8}>
                          <p>Messenger Positions</p>
                          <Checkbox.Group
                            value={messengerPositions}
                            onChange={(e) => handleKeyInput("messengerPositions", e)}
                            options={messenger_positions?.map(({ name, value }) => ({
                              label: name,
                              value,
                            }))}
                            style={{ display: "flex", flexDirection: "column" }}
                          />
                        </Col>
                      </>
                    )}

                    {inputs.publisherPlatforms?.includes("audience_network") && (
                      <>
                        <Col span={8}>
                          <p>Audience Network Positions </p>
                          <Checkbox.Group
                            value={audienceNetworkPositions}
                            onChange={(e) => handleKeyInput("audienceNetworkPositions", e)}
                            options={audience_network_positions?.map(({ name, value }) => ({
                              label: name,
                              value,
                            }))}
                            style={{ display: "flex", flexDirection: "column" }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </>
              )}
            </div>
            <LookTextInput
              className="mb-0"
              type="textarea"
              name="description"
              label="Description"
              // className="look-form-input"
              placeholder="Enter description"
              onChange={handleInput}
            />
          </div>
        </div>
        <div className="p-20">
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Button
                block
                onClick={
                  showTargetingLaunch
                    ? () => {
                        setTargettingTemplateData(null);
                      }
                    : onClose
                }
              >
                {showTargetingLaunch ? "Reset" : "Cancel"}
              </Button>
            </Col>
            <Col span={12}>
              <Button block type="primary" htmlType="submit">
                {showTargetingLaunch ? "Next" : "Submit"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      {/* )} */}
    </>
  );
}

export default FacebookForm;
