import { Card, Image, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookButton from "components/LookButton";
import cx from "cx";

export default function CardLGCompliance(props) {
  const {
    renderIndex,
    setUpdateObject = () => {},
    setUpdateIndex = () => {},
    className = "",
    adTitle,
    adBody,
    creativeImage,
    updateCompliantStatus,
  } = props;

  const { id: adTitleId, title, complianceStatus: adTitleStatus } = adTitle || {};
  const { id: adBodyId, body, complianceStatus: adBodyStatus } = adBody || {};
  const {
    id: creativeImageId,
    imageUrl,
    complianceStatus: creativeImageStatus,
  } = creativeImage || {};

  const ASSET_TYPE_BODY = "AD_BODY";
  const ASSET_TYPE_IMAGE_VIDEO = "AD_IMAGE_VIDEO";
  const ASSET_TYPE_TITLE = "AD_TITLE";

  const COMPLIANCE_STATUS_APPROVED = "APPROVED";
  const COMPLIANCE_STATUS_REJECTED = "REJECTED";

  const actionButtons = (params) => {
    const {
      currentStatus = "",
      assetType = "",
      checkCall = () => {},
      closeCall = () => {},
    } = params || {};
    let currentObject;

    if (assetType === ASSET_TYPE_BODY) currentObject = "adBody";
    if (assetType === ASSET_TYPE_IMAGE_VIDEO) currentObject = "creativeImage";
    if (assetType === ASSET_TYPE_TITLE) currentObject = "adTitle";
    return (
      <div className="action-buttons-wrapper">
        <div className="flex action-buttons">
          {currentStatus && currentStatus === COMPLIANCE_STATUS_APPROVED ? (
            <Tooltip title="Approved">
              <ActionIconRender className="text-primary" iconType="check-circle-filled" />
            </Tooltip>
          ) : (
            <LookButton
              tooltipTitle="Approve"
              iconType="check-circle"
              type="link"
              onClick={() => {
                setUpdateObject(currentObject);
                setUpdateIndex(renderIndex);
                checkCall();
              }}
            />
          )}
          {currentStatus && currentStatus === COMPLIANCE_STATUS_REJECTED ? (
            <Tooltip title="Rejected">
              <ActionIconRender className="text-danger" iconType="close-circle-filled" />
            </Tooltip>
          ) : (
            <LookButton
              tooltipTitle="Reject"
              iconType="close-circle"
              type="link"
              danger
              onClick={() => {
                setUpdateObject(currentObject);
                setUpdateIndex(renderIndex);
                closeCall();
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const titleContent = () => (
    <div className="d-flex p-12">
      <p className="title-text flex-grow-1 mb-0">
        <Tooltip title={title}>{title}</Tooltip>
      </p>

      {actionButtons({
        currentStatus: adTitleStatus,
        assetType: ASSET_TYPE_TITLE,
        checkCall: () =>
          updateCompliantStatus({
            variables: {
              assetType: ASSET_TYPE_TITLE,
              complianceStatus: COMPLIANCE_STATUS_APPROVED,
              creativeAssetId: adTitleId,
            },
          }),
        closeCall: () =>
          updateCompliantStatus({
            variables: {
              assetType: ASSET_TYPE_TITLE,
              complianceStatus: COMPLIANCE_STATUS_REJECTED,
              creativeAssetId: adTitleId,
            },
          }),
      })}
    </div>
  );

  const bodyContent = () => (
    <div className="body-content">
      <p className="body-text">
        <Tooltip title={body}>{body}</Tooltip>
      </p>
      {actionButtons({
        currentStatus: adBodyStatus,
        assetType: ASSET_TYPE_BODY,
        checkCall: () =>
          updateCompliantStatus({
            variables: {
              assetType: ASSET_TYPE_BODY,
              complianceStatus: COMPLIANCE_STATUS_APPROVED,
              creativeAssetId: adBodyId,
            },
          }),
        closeCall: () =>
          updateCompliantStatus({
            variables: {
              assetType: ASSET_TYPE_BODY,
              complianceStatus: COMPLIANCE_STATUS_REJECTED,
              creativeAssetId: adBodyId,
            },
          }),
      })}
    </div>
  );

  const imageContent = () => (
    <div className="image-wrapper">
      <Image src={imageUrl} preview={false} />
      {actionButtons({
        assetType: ASSET_TYPE_IMAGE_VIDEO,
        currentStatus: creativeImageStatus,
        checkCall: () =>
          updateCompliantStatus({
            variables: {
              assetType: ASSET_TYPE_IMAGE_VIDEO,
              complianceStatus: COMPLIANCE_STATUS_APPROVED,
              creativeAssetId: creativeImageId,
            },
          }),
        closeCall: () =>
          updateCompliantStatus({
            variables: {
              assetType: ASSET_TYPE_IMAGE_VIDEO,
              complianceStatus: COMPLIANCE_STATUS_REJECTED,
              creativeAssetId: creativeImageId,
            },
          }),
      })}
    </div>
  );

  const debugContent = [
    { isRow: true, contentObjects: [{ adTitleStatus, adBodyStatus, creativeImageStatus }] },
  ];

  return (
    <Card className={cx("card-lg-compliance theme-parent p-ch-0", className)}>
      <div>
        <div className="p-18">
          <div className="d-flex">
            <div className="mr-12">
              <div className="temp-circle" />
              {/* <Image  /> */}
            </div>
            <div className="flex-grow-1">
              <h2 className="title-head">Facebook Profiles</h2>
              <p className="mb-8">Sponsored</p>
            </div>
          </div>
          {bodyContent()}
        </div>
        <DebugPre content={debugContent} />
        {imageContent()}
        {titleContent()}
      </div>
    </Card>
  );
}
