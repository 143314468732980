import { useContext, useEffect } from "react";
import ThemeContext from "./context";

function useTheme() {
  const { isThemeLight } = useContext(ThemeContext);
  return isThemeLight;
}
export default useTheme;

export function useLightBody() {
  const BODY_CLASS_LIST = document?.body?.classList;
  useEffect(() => {
    BODY_CLASS_LIST.add("light-body");
    return () => BODY_CLASS_LIST.remove("light-body");
  });
}
