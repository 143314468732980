import { Form, Button, Alert, Row, Col } from "antd";
import { Formik } from "formik";
import Loader from "components/loader";
import LookTextInput from "components/forms/LookTextInput";

export default function AddPageForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    isError,
    btnText = "Add",
    loading = false,
  } = props;

  if (loading) {
    return <Loader className="text-center" />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {/* <pre> {JSON.stringify({ initialValues, values }, null, 2)} </pre> */}
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="page_name"
                label="Page Name"
                className="look-form-input"
                placeholder="Enter Name"
              />
            </Col>
            <Col span={24}>
              <LookTextInput
                formik
                name="page_id"
                label="Page ID"
                className="look-form-input"
                placeholder="Enter page id"
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
