import { gql } from "@apollo/client";

export const COLLAGE_GET_TEMPLATE = gql`
  query cl_getAllTemplates {
    cl_getAllTemplates {
      height
      id
      name
      s3_url
      template_features
      width
    }
  }
`;
