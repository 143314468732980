export const TIKTOK_BALANCE_TRANSFER_RECHARGE = "RECHARGE";
export const TIKTOK_BALANCE_TRANSFER_REFUND = "REFUND";

export const TIKTOK_BALANCE_TRANSFER_ENUM = [
  { name: "Recharge", value: TIKTOK_BALANCE_TRANSFER_RECHARGE },
  { name: "Refund", value: TIKTOK_BALANCE_TRANSFER_REFUND },
];

export const TIKTOK_ASSET_TYPE_ADGROUP = "ADGROUP";
export const TIKTOK_ASSET_TYPE_AD = "AD";

export const TIKTOK_HOURLY_TYPE_AD = "AD";
export const TIKTOK_HOURLY_TYPE_ADGROUP = "ADGROUP";
export const TIKTOK_HOURLY_TYPE_CAMPAIGN = "CAMPAIGN";
