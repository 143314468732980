import { useState } from "react";
import MainFrom from "./MainForm";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { UPDATE_FB360_BUDGET } from "shared/gql/gqlSchema/fb360Gql";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { TAG_ADD_TAGS } from "shared/gql/gqlSchema/tagsGql";
import { fbGroupForBudget } from "./utils";

export default function UpdateCampaignBudget(props) {
  const {
    reportType,
    details,
    is_bulk,
    priceSymbol,
    refetch = () => {},
    close = () => {},
    tagType,
    isAddTag = false,
  } = props;
  const { selectedRowsClear } = useLookTableParentContext();

  let { daily_budget, lifetime_budget } = details?.[0] || {};

  const [value, setValue] = useState(1);
  const [temp, setTemp] = useState(0);
  const by_number = value === 1;
  const isBudgetEqualsSpend = value === 3;

  const [addSASTags, { loading: tag_loading = false }] = useCustomMutation(TAG_ADD_TAGS);

  const [updateCampaignBudget, { loading }] = useCustomMutation(UPDATE_FB360_BUDGET, {
    onCompleted(e) {
      refetch();
      setTemp(0);
      const success = e?.fb360_bulkUpdateBudget?.success;
      const error = e?.fb360_bulkUpdateBudget?.error;
      selectedRowsClear();
      if (success?.length > 0) {
        if (isAddTag) {
          addSASTags({
            variables: {
              identifier: success?.map(({ id }) => id),
              tags: "Budget Updated",
              type: tagType,
            },
            onCompleted: (e) => {
              refetch();
              close();
              openNotification({
                type: "success",
                message: success?.map(({ message }) => message)?.join(","),
              });
            },
            onError(e) {
              refetch();
              close();
              openNotification({
                type: "success",
                message: success?.map(({ message }) => message)?.join(","),
              });
            },
          });
        } else {
          close();
          openNotification({
            type: "success",
            message: success?.map(({ message }) => message)?.join(","),
          });
        }
      }
      if (error?.length > 0) {
        openNotification({
          type: "error",
          message: error?.map(({ message }) => message)?.join(","),
        });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit(values) {
    const updateArray = fbGroupForBudget(
      details,
      reportType,
      by_number,
      isBudgetEqualsSpend,
      is_bulk,
      temp
    );
    updateCampaignBudget({ variables: { asset: reportType.toUpperCase(), updateArray } });
  }
  let budget = 0;
  if (!is_bulk) {
    if (daily_budget !== null && lifetime_budget !== null) {
      if (daily_budget > lifetime_budget) {
        budget = daily_budget;
      } else if (daily_budget < lifetime_budget) {
        budget = lifetime_budget;
      }
    }
    if (daily_budget && (lifetime_budget === undefined || lifetime_budget === null)) {
      budget = daily_budget;
    }
    if ((daily_budget === undefined || daily_budget === null) && lifetime_budget) {
      budget = lifetime_budget;
    }
  }

  const formProps = {
    onSubmit,
    loading: loading || tag_loading,
    btnText: "Update",
    budgetValue: budget,
    is_bulk,
    value,
    setValue,
    temp,
    setTemp,
    priceSymbol,
    spendFB: details?.[0]?.spendFB,
  };

  return (
    <>
      <MainFrom {...formProps} />
    </>
  );
}
