import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "cx";
import { numberParse } from "utils";

export default function useColumns() {
  const columns = [
    {
      title: "Spend",
      dataIndex: "cost",
      key: "cost",
      render: (value) => <LookTableColumnRender title={<>${numberParse(value, {toFixed: true, toFixedUpTo: 3})}</>} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.cost - b?.cost,
      inChart: true,
      searchType: "number",
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "revenue",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revenue - b?.revenue,
      searchType: "number",
    },
    {
      title: "Gross Margin",
      dataIndex: "PROFIT",
      key: "PROFIT",
      render: (value = 0, otherProps) => {
        const profit = `$${value.toFixed(2)}`;
        return (
          <LookTableColumnRender
            title={
              <strong
                className={cx({
                  "text-primary": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {profit.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.PROFIT - b?.PROFIT,
      searchType: "number",
    },
    {
      title: "V Visits",
      dataIndex: "visits",
      key: "visits",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.visits - b?.visits,
      searchType: "number",
    },
    {
      title: "Clicks (G)",
      dataIndex: "googleClicks",
      key: "googleClicks",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.googleClicks - b?.googleClicks,
      searchType: "number",
    },
    {
      title: "Clicks (V)",
      dataIndex: "revClicks",
      key: "revClicks",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.revClicks - b?.revClicks,
      searchType: "number",
    },
    {
      title: "Impression",
      dataIndex: "impressions",
      key: "Impression",
      render: (value) => <LookTableColumnRender title={numberParse(value)} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.impressions - b?.impressions,
      searchType: "number",
    },
    {
      title: "CPC",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPC - b?.CPC,
      inChart: true,
      searchType: "number",
    },
    {
      title: "CTR",
      dataIndex: "CTR",
      key: "CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CTR - b?.CTR,
      inChart: true,
      searchType: "number",
    },
    // {
    //   title: "Impressions",
    //   dataIndex: "googleImpressions",
    //   key: "googleImpressions",
    //   render: (value) => <LookTableColumnRender title={value} />,
    //   width: 100,
    //   minWidth: 100,
    //   sorter: (a, b) => a?.googleImpressions - b?.googleImpressions,
    //   searchType: "number",
    // },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 130,
      minWidth: 130,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },
    ///
    {
      title: "ROAS",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3}) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROI - b?.ROI,
      searchType: "number",
    },
    {
      title: "CPR (V)",
      dataIndex: "CPR_V",
      key: "CPR_V",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_V - b?.CPR_V,
      searchType: "number",
    },
    {
      title: "RPV",
      dataIndex: "RPV",
      key: "RPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPV - b?.RPV,
      searchType: "number",
    },
    {
      title: "RPL",
      dataIndex: "RPL",
      key: "RPL",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPL - b?.RPL,
      searchType: "number",
    },
    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "AD CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, {toFixed: true, toFixedUpTo: 3})} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },
  ];
  return { columns };
}
