import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { Button, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import SelectInput from "components/forms/SelectInput";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  LEADGEN_REPORT_UPDATE_SPEND,
  LEADGEN_REPORT_UPDATE_SPEND_7_PER,
} from "shared/enum/reportsConfig";
import {
  FB360_GET_AD_ACCOUNTS_DROPDOWN,
  GET_FB360_AD_VIDEO_URL,
  GET_FB360_VIDEO_REPORT,
  GET_VOLUUM_CATEGORY_DROPDOWN,
} from "shared/gql/gqlSchema/fb360Gql";
import { LEADGEN_OUTSIDE_ADVERTISER } from "shared/UserPermission/userId";
import { numberParse } from "utils";

export default function FacebookVideoAnalysis() {
  const [videoAnalysisReport, setVideoAnalysisReport] = useState([]);
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const userSettings = useSelector((state) => state?.userSettings);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);
  const { filterData } = useLookTableFilterData(persistKey);
  const [categoryData, setCategoryData] = useState([]);
  const [adaccountData, setAdaccountData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedAdaccount, setSelectedAdaccount] = useState([]);
  const [selectedUser, setSelectedUser] = useState("all");

  const isCategoryId = selectedCategory !== "all" ? { category_id: selectedCategory } : {};
  const isAdaccountId = selectedAdaccount?.length > 0 ? { adaccount_ids: selectedAdaccount } : {};
  const isUserId = selectedUser !== "all" ? { user_ids: selectedUser } : {};

  const { umsCurrentWorkspaceUsersFetch = () => {} } = useApiToStoreContext();
  const workSpaceUsers = useSelector(
    (state) => state?.userSettings?.userSettingData?.umsCurrentWorkspaceUsers
  );

  useEffect(() => {
    umsCurrentWorkspaceUsersFetch();
  }, []);

  let finalMergeData = [];

  const { loading, refetch } = useCustomQuery(GET_FB360_VIDEO_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.fb360_videoReporting;
      setVideoAnalysisReport(element);
    },
    variables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      ...isCategoryId,
      ...isAdaccountId,
      ...isUserId,
    },
  });

  const { loading: GET_VOLUUM_CATEGORY_DROPDOWN_loading } = useCustomQuery(
    GET_VOLUUM_CATEGORY_DROPDOWN,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.voluum_get_category;
        setCategoryData(element);
      },
    }
  );

  const { loading: FB360_GET_AD_ACCOUNTS_DATA_loading } = useCustomQuery(
    FB360_GET_AD_ACCOUNTS_DROPDOWN,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.fb360_adaccountGet;
        setAdaccountData(element);
      },
    }
  );

  const [getVideoUrl, { loading: GET_FB360_AD_VIDEO_URL_loading }] = useCustomLazyQuery(
    GET_FB360_AD_VIDEO_URL,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.fb360_adVideoUrl;
        if (element) {
          const { video_url = "", video_id, duration } = element;
          setVideoAnalysisReport((prev) =>
            prev?.map((item) => {
              let generatedUrl = "",
                videoDuration = "";
              if (item?.video_id === video_id) {
                generatedUrl = video_url;
                videoDuration = duration;
              }
              return { ...item, generatedUrl, videoDuration };
            })
          );
        }
      },
    }
  );

  const handleGetVideoUrl = (actionProps) => {
    const { video_id = "" } = actionProps;
    getVideoUrl({
      variables: {
        video_id,
      },
    });
  };

  const actions = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => {
        const { video_id } = actionProps;
        return (
          <>
            {video_id && (
              <>
                <Tooltip title="Get URL">
                  <Button
                    type="link"
                    onClick={() => handleGetVideoUrl(actionProps)}
                    disabled={GET_FB360_AD_VIDEO_URL_loading}
                    icon={<ActionIconRender iconType="view" />}
                  />
                </Tooltip>
              </>
            )}
          </>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const lastColumns = [
    {
      title: "URL",
      dataIndex: "generatedUrl",
      key: "generatedUrl",
      nofilter: true,
      width: 110,
      minWidth: 110,
      render: (value) => {
        return value ? (
          <Tooltip title={value}>
            <Button size="small" onClick={() => window.open(value, "_blank")}>
              Visit URL
            </Button>
          </Tooltip>
        ) : (
          "-"
        );
      },
      fixed: "right",
    },
  ];

  const initialColumns = [
    {
      title: "Video Name",
      dataIndex: "video_id",
      key: "video_id",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
    },
    {
      title: "Amount spent ($)",
      dataIndex: "finalSpend",
      key: "finalSpend",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Revenue ($)",
      dataIndex: "finalRevenue",
      key: "finalRevenue",
      render: (value) => (
        <LookTableColumnRender title={`$${numberParse(value, { toFixed: true })}`} />
      ),
      width: 124,
      minWidth: 124,
    },
    {
      title: "Hook rate (%)",
      dataIndex: "hookRate",
      key: "hookRate",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Hold rate (%)",
      dataIndex: "holdRate",
      key: "holdRate",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Average watch time (sec)",
      dataIndex: "video_avg_time",
      key: "video_avg_time",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "25% Video Views",
      dataIndex: "video_p25_views",
      key: "video_p25_views",
      render: (value, otherProps) => (
        <LookTableColumnRender title={`${value} (${otherProps?.video_p25_views_per}%)`} />
      ),
      width: 124,
      minWidth: 124,
    },
    {
      title: "50% Video Views",
      dataIndex: "video_p50_views",
      key: "video_p50_views",
      render: (value, otherProps) => (
        <LookTableColumnRender title={`${value} (${otherProps?.video_p50_views_per}%)`} />
      ),
      width: 124,
      minWidth: 124,
    },
    {
      title: "75% Video Views",
      dataIndex: "video_p75_views",
      key: "video_p75_views",
      render: (value, otherProps) => (
        <LookTableColumnRender title={`${value} (${otherProps?.video_p75_views_per}%)`} />
      ),
      width: 124,
      minWidth: 124,
    },
    {
      title: "CTR (%)",
      dataIndex: "CTR",
      key: "CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "CPC ($)",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "CVR",
      dataIndex: "CVR",
      key: "CVR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "ROI (%)",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Duration",
      dataIndex: "videoDuration",
      key: "videoDuration",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    ...actions,
  ];

  if (!loading) {
    finalMergeData = videoAnalysisReport?.map((element, indx) => {
      const {
        spend = 0,
        impressions = 0,
        revenue = 0,
        payout_amount = 0,
        video_3_sec_views = 0,
        video_thruplay = 0,
        clicks = 0,
        result = 0,
        video_avg_time = 0,
        video_p25_views = 0,
        video_p50_views = 0,
        video_p75_views = 0,
        video_views = 0,
      } = element;

      const addSpendPer = LEADGEN_OUTSIDE_ADVERTISER.includes(user_id)
        ? LEADGEN_REPORT_UPDATE_SPEND_7_PER
        : LEADGEN_REPORT_UPDATE_SPEND;
      const spendUpdate = (spend * addSpendPer).toFixed(2);
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressions)) * 1000;
      const finalRevenue = revenue + payout_amount;
      const hookRate = (video_3_sec_views / impressions) * 100 || 0;
      const holdRate = (video_thruplay / impressions) * 100 || 0;
      const CTR = (clicks / impressions) * 100 || 0;
      const CPC = parseFloat(spendUpdate) / parseFloat(clicks);
      const CVR = parseFloat(result) / parseFloat(clicks);
      const PROFIT = parseFloat(finalRevenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const video_p25_views_per = (video_p25_views / video_views) * 100 || 0;
      const video_p50_views_per = (video_p50_views / video_views) * 100 || 0;
      const video_p75_views_per = (video_p75_views / video_views) * 100 || 0;

      return {
        ...element,
        video_avg_time,
        video_p25_views,
        video_p50_views,
        video_p75_views,
        video_p25_views_per: numberParse(video_p25_views_per, { toFixed: true }),
        video_p50_views_per: numberParse(video_p50_views_per, { toFixed: true }),
        video_p75_views_per: numberParse(video_p75_views_per, { toFixed: true }),
        finalRevenue,
        finalSpend: spendUpdate,
        hookRate: numberParse(hookRate, { toFixed: true }),
        holdRate: numberParse(holdRate, { toFixed: true }),
        CPM: numberParse(CPM, { toFixed: true }),
        CTR: numberParse(CTR, { toFixed: true }),
        CPC: numberParse(CPC, { toFixed: true }),
        CVR: numberParse(CVR, { toFixed: true }),
        ROI: numberParse(ROI, { toFixed: true }),
      };
    });
  }

  const finalFilterData = filterData(finalMergeData).filter(({ video_id = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    const searchCheck = video_id?.toLowerCase()?.includes(searchValue);
    if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
      return true;
    }
    return false;
  });

  let total_finalRevenue = 0,
    total_finalSpend = 0,
    total_clicks = 0,
    total_result = 0,
    total_impressions = 0,
    total_video_3_sec_views = 0,
    total_video_thruplay = 0;

  for (const {
    finalRevenue = 0,
    finalSpend = 0,
    clicks = 0,
    result = 0,
    video_3_sec_views = 0,
    impressions = 0,
    video_thruplay = 0,
  } of finalFilterData) {
    total_finalRevenue += parseFloat(finalRevenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_result += parseFloat(result || 0);
    total_video_3_sec_views += parseFloat(video_3_sec_views || 0);
    total_impressions += parseFloat(impressions || 0);
    total_video_thruplay += parseFloat(video_thruplay || 0);
    total_finalSpend += parseFloat(finalSpend || 0);
  }

  const total_hookRate =
    (parseFloat(total_video_3_sec_views) / parseFloat(total_impressions)) * 100 || 0;
  const total_holdRate =
    (parseFloat(total_video_thruplay) / parseFloat(total_impressions)) * 100 || 0;
  const total_CTR = (parseFloat(total_clicks) / parseFloat(total_impressions)) * 100 || 0;
  const total_CPM = (parseFloat(total_finalSpend) / parseFloat(total_impressions)) * 1000;
  const total_CPC = parseFloat(total_finalSpend) / parseFloat(total_clicks);
  const total_CVR = parseFloat(total_result) / parseFloat(total_clicks);
  const total_PROFIT = parseFloat(total_finalRevenue) - parseFloat(total_finalSpend);
  const total_ROI = parseFloat(total_PROFIT) - parseFloat(total_finalSpend);

  const summaryData = {
    finalRevenue: numberParse(total_finalRevenue, { toFixed: true }),
    finalSpend: numberParse(total_finalSpend, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
    hookRate: numberParse(total_hookRate, { toFixed: true }),
    holdRate: numberParse(total_holdRate, { toFixed: true }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    CPC: numberParse(total_CPC, { toFixed: true }),
    CVR: numberParse(total_CVR, { toFixed: true }),
    // CPA: numberParse(total_CPA, { toFixed: true }),
    ROI: numberParse(total_ROI, { toFixed: true }),
    clicks: numberParse(total_clicks),
    result: numberParse(total_result),
  };

  const rightOptions = (
    <>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Ad account">
          <span>
            <SelectInput
              search
              name="selectedAdaccount"
              className="mb-0"
              placeholder="Select Ad account"
              value={selectedAdaccount}
              multiple
              loader={FB360_GET_AD_ACCOUNTS_DATA_loading}
              options={adaccountData?.map(({ adaccount_id, name }) => ({
                name,
                value: adaccount_id,
              }))}
              onChange={setSelectedAdaccount}
            />
          </span>
        </Tooltip>
      </div>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="User">
          <span>
            <SelectInput
              search
              name="selectedUser"
              className="mb-0"
              placeholder="Select User"
              value={selectedUser}
              options={[
                { name: "All", value: "all" },
                ...workSpaceUsers?.map(({ id, name }) => ({
                  name,
                  value: id,
                })),
              ]}
              onChange={setSelectedUser}
            />
          </span>
        </Tooltip>
      </div>
      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Category">
          <span>
            <SelectInput
              search
              name="selectedCategory"
              className="mb-0"
              placeholder="Select Category"
              value={selectedCategory}
              loader={GET_VOLUUM_CATEGORY_DROPDOWN_loading}
              options={[
                { name: "All", value: "all" },
                ...categoryData?.map(({ id, name }) => ({ name, value: id })),
              ]}
              onChange={setSelectedCategory}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );

  const tableMainProps = {
    loading,
    refetch,
    isDateRange: true,
    exportCSV: true,
    isSearchFilter: true,
    tableProps: {
      initialColumns,
      recordData: finalFilterData,
      summaryData,
      lastColumns,
    },
    rightOptions,
    defaultPagination: true,
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ finalFilterData }],
    },
  ];

  return (
    <>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </>
  );
}
