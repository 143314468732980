import { Form, Button, Alert, Row, Col, Switch } from "antd";
import { Formik, ErrorMessage, Field } from "formik";
import LookTextInput from "../../../../../components/forms/LookTextInput";
import SelectInput from "../../../../../components/forms/SelectInput";
import Loader from "../../../../../components/loader";
import FileHandle from "../../../../../components/CmsComponents/FileHandle";

export default function MainForm(props) {
  const {
    allCategories = [],
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
    type = "add",
    imageProps: { image = "", setImage = () => {} },
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={16}>
              <div>
                <LookTextInput
                  formik
                  name="name"
                  label="Name"
                  className="look-form-input"
                  placeholder="Enter name"
                />
                <LookTextInput
                  formik
                  type="textarea"
                  name="description"
                  label="Description"
                  className="look-form-input"
                  placeholder="Enter Description"
                />
              </div>
            </Col>
            <Col span={8}>
              <FileHandle image={image} setImage={setImage} />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <div>
                <Form.Item label="Parent Category" className="look-form-input">
                  {/* <Field
                    as={Select}
                    name="parent_category_id"
                    className="w-100"
                    placeholder="Select Parent Category"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => {
                      setFieldValue("parent_category_id", e);
                    }}
                  >
                    <Option value="no">No Parent</Option>
                    {allCategories?.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="parent_category_id"
                    component="div"
                    className="text-left error-text"
                  /> */}

                  <SelectInput
                    className="mb-0"
                    formik
                    search
                    placeholder="Select Parent Category"
                    name="parent_category_id"
                    value={values.parent_category_id}
                    options={[{ name: "No Parent", id: "no" }, ...allCategories]?.map(
                      ({ id, name }) => ({ name: name, value: id })
                    )}
                    onChange={setFieldValue}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex-between pt-30 mb-20">
                <p className="mb-0">Is Public</p>
                <Switch
                  checked={values?.is_public}
                  onChange={(e) => setFieldValue("is_public", e)}
                />
              </div>
            </Col>
          </Row>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
          {/* <pre>{JSON.stringify({ values }, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
}
