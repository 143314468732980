import { gql } from "@apollo/client";

export const GET_TIKTOK_LOGIN_URL = gql`
  query tiktok_getLoginurl {
    tiktok_getLoginurl
  }
`;

const TIKTOK_USER_RESPONSE = gql`
  fragment tiktok_user on tiktok_user {
    create_time
    display_name
    email
    id
    tiktok_user_id
  }
`;

export const GET_TIKTOK_USERS = gql`
  ${TIKTOK_USER_RESPONSE}
  query tiktok_getUsers {
    tiktok_getUsers {
      ...tiktok_user
    }
  }
`;

export const TIKTOK_GET_REPORT_STATUS = gql`
  query tiktok_getReportStatus {
    tiktok_getReportStatus
  }
`;

const TIKTOK_ACCOUNT_RESPONSE = gql`
  fragment tiktok_account on tiktok_account {
    id
    name
    status
    timezone
    currency
    create_time
    country
    account_id
    available_balance
    balance_allocate
    balance_reminder
    bc_id
    rejection_reason
  }
`;

export const GET_TIKTOK_ACCOUNTS = gql`
  ${TIKTOK_ACCOUNT_RESPONSE}
  query tiktok_getAccounts($userId: ID, $include_suspended: Boolean) {
    tiktok_getAccounts(userId: $userId, include_suspended: $include_suspended) {
      ...tiktok_account
    }
  }
`;

export const GQL_TIKTOK_BALANCE_ALLOCATE = gql`
  mutation tiktok_balanceAllocate(
    $account_id: String!
    $bc_id: String!
    $cash_amount: Float!
    $transfer_type: tiktok_balanceTransfer!
  ) {
    tiktok_balanceAllocate(
      account_id: $account_id
      bc_id: $bc_id
      cash_amount: $cash_amount
      transfer_type: $transfer_type
    )
  }
`;

const TIKTOK_ACCOUNT_REPORT_RESPONSE = gql`
  fragment tiktok_accountreport on tiktok_accountreport {
    adAccountId: account_id
    clicksTiktok: clicks
    country
    currency
    impressionsTiktok: impressions
    name
    reach
    spend
    status
    timezone
  }
`;

export const GET_TIKTOK_ACCOUNT_REPORT = gql`
  ${TIKTOK_ACCOUNT_REPORT_RESPONSE}
  query tiktok_getAccountReport($end: Date, $start: Date!, $timezone: String, $user_id: ID) {
    tiktok_getAccountReport(end: $end, start: $start, timezone: $timezone, user_id: $user_id) {
      ...tiktok_accountreport
      conversionTiktok: conversion
    }
  }
`;

const TIKTOK_CAMPAIGN_REPORT_RESPONSE = gql`
  fragment tiktok_campaignreport on tiktok_campaignreport {
    campaignId: campaign_id
    campaign_name
    clicksTiktok: clicks
    impressionsTiktok: impressions
    reach
    spend
    conversionTiktok: conversion
    status
    budget
    budget_optimize_on
    secondary_status
  }
`;

export const GET_TIKTOK_CAMPAIGN_REPORT = gql`
  ${TIKTOK_CAMPAIGN_REPORT_RESPONSE}
  query tiktok_getCampaignReport($end: Date, $start: Date!, $timezone: String, $user_id: ID) {
    tiktok_getCampaignReport(end: $end, start: $start, timezone: $timezone, user_id: $user_id) {
      ...tiktok_campaignreport
      is_pulse
      account_id
    }
  }
`;

const TIKTOK_AD_REPORT_RESPONSE = gql`
  fragment tiktok_adreport on tiktok_adreport {
    adId: ad_id
    ad_name
    clicksTiktok: clicks
    impressionsTiktok: impressions
    reach
    spend
    conversionTiktok: conversion
    status
    compliance
    is_aco
    secondary_status
  }
`;

export const GET_TIKTOK_AD_REPORT = gql`
  ${TIKTOK_AD_REPORT_RESPONSE}
  query tiktok_getAdReport(
    $adBy: tiktok_ad_by_type
    $assetId: String
    $end: Date
    $start: Date!
    $timezone: String
    $user_id: ID
  ) {
    tiktok_getAdReport(
      adBy: $adBy
      assetId: $assetId
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
    ) {
      ...tiktok_adreport
    }
  }
`;

const TIKTOK_ADGROUP_REPORT_RESPONSE = gql`
  fragment tiktok_adgroupreport on tiktok_adgroupreport {
    adsetId: adgroup_id
    adgroup_name
    clicksTiktok: clicks
    impressionsTiktok: impressions
    reach
    spend
    conversionTiktok: conversion
    status
    budget
    secondary_status
  }
`;

export const GET_TIKTOK_ADGROUP_REPORT = gql`
  ${TIKTOK_ADGROUP_REPORT_RESPONSE}
  query tiktok_getAdgroupReport(
    $assetId: String
    $end: Date
    $start: Date!
    $timezone: String
    $user_id: ID
  ) {
    tiktok_getAdgroupReport(
      assetId: $assetId
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
    ) {
      ...tiktok_adgroupreport
    }
  }
`;

const TIKTOK_HOURLY_REPORT_RESPONSE = gql`
  fragment tiktok_hourlyreport on tiktok_hourlyreport {
    adId: ad_id
    ad_name
    adsetId: adgroup_id
    adgroup_name
    campaignId: campaign_id
    campaign_name
    clicksTiktok: clicks
    conversionTiktok: conversion
    impressionsTiktok: impressions
    reach
    report_time
    spend
  }
`;

export const GET_TIKTOK_ADGROUP_HOURLY_REPORT = gql`
  ${TIKTOK_HOURLY_REPORT_RESPONSE}
  query tiktok_getHourlyReport(
    $assetId: String
    $end: Date
    $hourlyType: tiktok_hourly_type
    $start: Date!
    $timezone: String
  ) {
    tiktok_getHourlyReport(
      assetId: $assetId
      end: $end
      hourlyType: $hourlyType
      start: $start
      timezone: $timezone
    ) {
      ...tiktok_hourlyreport
    }
  }
`;

export const GET_TIKTOK_PERFORMANCE_REPORT = gql`
  ${TIKTOK_HOURLY_REPORT_RESPONSE}
  query tiktok_getDailyPerformanceReport(
    $assetId: String
    $end: Date
    $reportType: tiktok_hourly_type
    $start: Date!
    $timezone: String
  ) {
    tiktok_getDailyPerformanceReport(
      assetId: $assetId
      end: $end
      reportType: $reportType
      start: $start
      timezone: $timezone
    ) {
      ...tiktok_hourlyreport
    }
  }
`;

export const GET_TIKTOK_USER_REPORT = gql`
  query tiktok_getUserReport($end: Date, $start: Date!, $timezone: String) {
    tiktok_getUserReport(end: $end, start: $start, timezone: $timezone) {
      clicksTiktok: clicks
      conversion
      impressionsTiktok: impressions
      reach
      spend
      userId: user_id
      user {
        id
        name
      }
    }
  }
`;

export const GQL_TIKTOK_UPDATE_STATUS = gql`
  mutation tiktok_updateStatus(
    $params: [tiktok_statusParams!]!
    $status: statuses!
    $type: tiktok_hourly_type!
  ) {
    tiktok_updateStatus(params: $params, status: $status, type: $type) {
      data {
        id
        status
      }
      status
    }
  }
`;

export const TIKTOK_UPDATE_CAMPAIGN_BUDGET = gql`
  mutation Tiktok_updateCampaignBudget($budget_params: tiktok_budget) {
    tiktok_updateCampaignBudget(budget_params: $budget_params) {
      success
      failure
    }
  }
`;

export const TIKTOK_SYNC_AD_ACCOUNT_USER = gql`
  mutation tiktok_syncUserAccounts($accounts: [String], $tiktok_user_id: ID) {
    tiktok_syncUserAccounts(accounts: $accounts, tiktok_user_id: $tiktok_user_id) {
      status
      message
    }
  }
`;

export const TIKTOK_SAVE_AUTH = gql`
  mutation tiktok_saveAuth($code: String!) {
    tiktok_saveAuth(code: $code)
  }
`;

export const TIKTOK_UNASSIGN_USER_ACCOUNT = gql`
  mutation tiktok_unAssignUserAccount($account_id: [String!]) {
    tiktok_unAssignUserAccount(account_id: $account_id)
  }
`;

export const GET_VOLUUM_REPORT_BY_GROUP = gql`
  query voluum_revenue_by_group(
    $to: Date
    $from: Date
    $timezone: String
    $type: String
    $platform_id: ID
  ) {
    voluum_revenue_by_group(
      to: $to
      from: $from
      timezone: $timezone
      type: $type
      platform_id: $platform_id
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

const VOLUME_REPORT_RESPONSE = gql`
  fragment voluum_report on voluum_report {
    revAdAccountId: ad_account_id
    ad_id
    ad_set_id
    campaign_id
    clicks
    conversions
    report_time
    revenue
    user_id
    visits
    call_count
    converted_calls
    payout_amount
    avg_handle_time
    blocked_calls
    call_length
    connected_call_count
    duplicate_calls
    ended_calls
    non_connected_call_count
  }
`;

export const GET_VOLUUM_CAMPAIGN_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_campaign_reporting(
    $asset: voluum_asset
    $asset_id: [ID]
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
    $user_id: ID
  ) {
    voluum_campaign_reporting(
      asset: $asset
      asset_id: $asset_id
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
      user_id: $user_id
    ) {
      ...voluum_report
      campaign_name
    }
  }
`;

export const GET_VOLUUM_AD_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_ad_reporting(
    $asset: voluum_asset
    $asset_id: [ID]
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
    $user_id: ID
  ) {
    voluum_ad_reporting(
      asset: $asset
      asset_id: $asset_id
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
      user_id: $user_id
    ) {
      ...voluum_report
    }
  }
`;

export const GET_VOLUUM_ADSET_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_ad_set_reporting(
    $asset: voluum_asset
    $asset_id: [ID]
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
    $user_id: ID
  ) {
    voluum_ad_set_reporting(
      asset: $asset
      asset_id: $asset_id
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
      user_id: $user_id
    ) {
      ...voluum_report
      adset_name
    }
  }
`;

export const GET_VOLUUM_ADACCOUNT_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_ad_account_reporting(
    $asset: voluum_asset
    $asset_id: [ID]
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
    $user_id: ID
  ) {
    voluum_ad_account_reporting(
      asset: $asset
      asset_id: $asset_id
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
      user_id: $user_id
    ) {
      ...voluum_report
      adaccount_name
    }
  }
`;

export const GET_VOLUUM_SUBID_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_subid_reporting($from: Date, $platform_id: ID, $timezone: String, $to: Date) {
    voluum_subid_reporting(from: $from, platform_id: $platform_id, timezone: $timezone, to: $to) {
      sub_id
      ...voluum_report
    }
  }
`;

export const GET_VOLUUM_DAILY_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_daily_report(
    $asset: voluum_asset!
    $asset_id: [ID]!
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
  ) {
    voluum_daily_report(
      asset: $asset
      asset_id: $asset_id
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
    ) {
      ...voluum_report
    }
  }
`;

export const GET_VOLUUM_HOURLY_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_hourly_report(
    $asset: voluum_asset!
    $asset_id: [ID]!
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
  ) {
    voluum_hourly_report(
      asset: $asset
      asset_id: $asset_id
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
    ) {
      ...voluum_report
    }
  }
`;

export const GET_RINGBA_CALLS_REPORT = gql`
  query voluum_ringba_calls(
    $asset: voluum_asset
    $asset_id: [ID]
    $from: Date!
    $platform_id: ID
    $timezone: String!
    $to: Date!
  ) {
    voluum_ringba_calls(
      asset: $asset
      asset_id: $asset_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
    ) {
      calls {
        ad_account_id
        ad_id
        ad_set_id
        campaign_id
        campaign_name
        connected_call_length
        has_connected
        has_converted
        has_payout
        is_duplicate
        payout_amount
        recording_url
        report_time
        time_to_call
      }
    }
  }
`;

export const GET_VOLUUM_HOUR_OF_THE_DAY_REPORT = gql`
  ${VOLUME_REPORT_RESPONSE}
  query voluum_hour_of_day_report(
    $asset: voluum_asset!
    $asset_id: [ID]!
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
  ) {
    voluum_hour_of_day_report(
      asset: $asset
      asset_id: $asset_id
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
    ) {
      ...voluum_report
    }
  }
`;

export const GET_VOLUUM_PERFORMANCE_REPORT = gql`
  query voluum_get_report(
    $to: Date
    $from: Date
    $timezone: String
    $type: String
    $ids: [String]
    $platform_id: ID
  ) {
    voluum_get_report(
      to: $to
      from: $from
      timezone: $timezone
      platform_id: $platform_id
      type: $type
      ids: $ids
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const GET_VOLUUM_AD_HOURLY_REPORT = gql`
  query voluum_adHourlyReport(
    $to: Date
    $from: Date
    $timezone: String
    $ad_ids: [String]
    $platform_id: ID
  ) {
    voluum_adHourlyReport(
      to: $to
      from: $from
      timezone: $timezone
      platform_id: $platform_id
      ad_ids: $ad_ids
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const GET_VOLUUM_CAMPAIGN_HOURLY_REPORT = gql`
  query voluum_campaignHourlyReport(
    $to: Date
    $from: Date
    $timezone: String
    $platform_id: ID
    $campaign_ids: [String]
  ) {
    voluum_campaignHourlyReport(
      to: $to
      from: $from
      timezone: $timezone
      platform_id: $platform_id
      campaign_ids: $campaign_ids
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const GET_VOLUUM_ADSET_HOURLY_REPORT = gql`
  query voluum_add_setHourlyReport(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $adset_ids: [String]
  ) {
    voluum_add_setHourlyReport(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      adset_ids: $adset_ids
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const GET_VOLUUM_ADACCOUNT_HOURLY_REPORT = gql`
  query voluum_adAccountHourlyReport(
    $to: Date
    $from: Date
    $timezone: String
    $platform_id: ID
    $adaccount_ids: [String]
  ) {
    voluum_adAccountHourlyReport(
      to: $to
      from: $from
      timezone: $timezone
      platform_id: $platform_id
      adaccount_ids: $adaccount_ids
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const GET_VOLUUM_ADSET_BY_CAMPAIGN_REPORT = gql`
  query voluum_get_adset_by_campaign(
    $to: Date
    $from: Date
    $timezone: String
    $platform_id: ID
    $campaign_id: String
  ) {
    voluum_get_adset_by_campaign(
      to: $to
      from: $from
      timezone: $timezone
      platform_id: $platform_id
      campaign_id: $campaign_id
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const GET_VOLUUM_AD_BY_CAMPAIGN_REPORT = gql`
  query voluum_get_ad_by_campaign(
    $to: Date
    $from: Date
    $timezone: String
    $platform_id: ID
    $campaign_id: String
  ) {
    voluum_get_ad_by_campaign(
      to: $to
      from: $from
      timezone: $timezone
      platform_id: $platform_id
      campaign_id: $campaign_id
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const GET_VOLUUM_AD_BY_ADSET_REPORT = gql`
  query voluum_get_ad_by_ad_set(
    $to: Date
    $from: Date
    $timezone: String
    $platform_id: ID
    $ad_set_id: String
  ) {
    voluum_get_ad_by_ad_set(
      to: $to
      from: $from
      timezone: $timezone
      platform_id: $platform_id
      ad_set_id: $ad_set_id
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      visits
    }
  }
`;

export const TIKTOK_CREATE_BULK_AD_ACCOUNT = gql`
  mutation tiktok_createBulkAdAccount($adAccount_info: adAccount_params!) {
    tiktok_createBulkAdAccount(adAccount_info: $adAccount_info) {
      message
      status
    }
  }
`;

const TIKTOK_BC_MEMBER_RESPONSE = gql`
  fragment tiktok_bcMember on tiktok_bcMember {
    email
    finance_role
    id
    name
    user_role
  }
`;

export const TIKTOK_PAGED_BC_MEMBERS = gql`
  ${TIKTOK_BC_MEMBER_RESPONSE}
  query tiktok_pagedBcMembers(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    tiktok_pagedBcMembers(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        ...tiktok_bcMember
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_VOLUUM_USER_REPORT = gql`
  query voluum_get_user_report(
    $to: Date
    $platform_id: ID
    $from: Date!
    $timezone: String
    $is_detailed: Boolean!
    $is_lfbc: Boolean
  ) {
    voluum_get_user_report(
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
      is_detailed: $is_detailed
      is_lfbc: $is_lfbc
    ) {
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      user_id
      visits
      x_revenue
      call_count
      converted_calls
      payout_amount
      avg_handle_time
      blocked_calls
      call_length
      connected_call_count
      duplicate_calls
      ended_calls
      non_connected_call_count
    }
  }
`;

export const TIKTOK_SYNC_BC_MEMBERS = gql`
  mutation tiktok_syncBcMembers($bc_id: String!) {
    tiktok_syncBcMembers(bc_id: $bc_id)
  }
`;

export const TIKTOK_SYNC_BALANCE_STATUS = gql`
  mutation tiktok_syncBalanceStatus {
    tiktok_syncBalanceStatus
  }
`;

const TIKTOK_USER_REPORT_RESPONSE = gql`
  fragment tiktokUserReport on tiktokUserReport {
    clicksTiktok: clicks
    conversion
    impressions
    reach
    report_time
    spend
    user_id
  }
`;

export const TIKTOK_GET_USER_PERFORMANCE_REPORT = gql`
  ${TIKTOK_USER_REPORT_RESPONSE}
  query tiktok_getUserPerformanceReport($end: Date, $start: Date!, $timezone: String, $userId: ID) {
    tiktok_getUserPerformanceReport(
      end: $end
      start: $start
      timezone: $timezone
      userId: $userId
    ) {
      ...tiktokUserReport
    }
  }
`;

export const TIKTOK_GET_CATEGORY_REPORT = gql`
  query tiktok_getCategoryReport($end: Date, $start: Date, $timezone: String, $productId: ID) {
    tiktok_getCategoryReport(end: $end, start: $start, timezone: $timezone, productId: $productId) {
      category_id
      clicksTiktok: clicks
      conversion
      impressionsTiktok: impressions
      reach
      spend
    }
  }
`;

export const GET_VOLUUM_CATEGORY_REPORT = gql`
  query voluum_category_report(
    $to: Date!
    $platform_id: ID
    $from: Date!
    $timezone: String!
    $user_id: ID
  ) {
    voluum_category_report(
      to: $to
      platform_id: $platform_id
      from: $from
      timezone: $timezone
      user_id: $user_id
    ) {
      category {
        id
        name
      }
      ad_account_id
      ad_id
      ad_set_id
      campaign_id
      clicks
      conversions
      report_time
      revenue
      x_revenue
      user_id
      visits
      call_count
      converted_calls
      payout_amount
      avg_handle_time
      blocked_calls
      call_length
      connected_call_count
      duplicate_calls
      ended_calls
      non_connected_call_count
    }
  }
`;

export const TIKTOK_CLONE_CAMPAIGNS = gql`
  mutation tiktokCloneCampaigns(
    $launchName: String!
    $tiktokCloneObject: [TiktokObject]!
    $numberOfClones: Int!
    $startDate: String!
    $endDate: String
    $scheduleType: TIKTOK_SCHEDULE_TYPE!
  ) {
    tiktokCloneCampaigns(
      launchName: $launchName
      tiktokCloneObject: $tiktokCloneObject
      scheduleType: $scheduleType
      endDate: $endDate
      startDate: $startDate
      numberOfClones: $numberOfClones
    ) {
      data
      message
      status
    }
  }
`;

export const TIKTOK_CLONE_ADSETS = gql`
  mutation tiktokCloneAdsets(
    $adsetIds: [TiktokAdsetObject]!
    $numberOfClones: Int!
    $suffix: String
    $startDate: String!
    $endDate: String
    $scheduleType: TIKTOK_SCHEDULE_TYPE!
  ) {
    tiktokCloneAdsets(
      adsetIds: $adsetIds
      suffix: $suffix
      scheduleType: $scheduleType
      endDate: $endDate
      startDate: $startDate
      numberOfClones: $numberOfClones
    ) {
      data
      message
      status
    }
  }
`;

export const TIKTOK_CREATIVES = gql`
  query Tiktok_getAssetCreatives($asset: tiktok_asset, $asset_id: [ID]) {
    tiktok_getAssetCreatives(asset: $asset, asset_id: $asset_id) {
      title
      video_thumb_url
      video_url
    }
  }
`;

export const TIKTOK_CREATE_PULSE_CAMPAIGN = gql`
  mutation tiktok_createPulseCampaign($fields: [tiktok_pulse_input]) {
    tiktok_createPulseCampaign(fields: $fields)
  }
`;

export const TIKTOK_CHECK_EXISTING_PULSE_CAMPAIGN = gql`
  query tiktok_checkExistingPulseCampaign($fields: [tiktok_pulse_input]) {
    tiktok_checkExistingPulseCampaign(fields: $fields) {
      account_id
      campaign_id
      campaign_name
      category_id
      country_id
      nc_id
    }
  }
`;

export const TIKTOK_REJECTED_ASSETS = gql`
  query tiktok_assetStatusReport(
    $start: Date!
    $asset_type: tiktok_reviewAsset
    $end: Date
    $timezone: String
  ) {
    tiktok_assetStatusReport(
      start: $start
      asset_type: $asset_type
      end: $end
      timezone: $timezone
    ) {
      account_id
      ad_id
      ad_name
      adgroup_id
      adgroup_name
    }
  }
`;

export const TIKTOK_GLOBAL_REPORT = gql`
  query tiktok_globalLanderReport(
    $category_id: ID!
    $end: Date
    $product_id: ID!
    $start: Date!
    $timezone: String
  ) {
    tiktok_globalLanderReport(
      category_id: $category_id
      end: $end
      product_id: $product_id
      start: $start
      timezone: $timezone
    ) {
      clicksTiktok: clicks
      conversions
      impressions
      lander_url
      reach
      spendTiktok: spend
    }
  }
`;

export const GET_TIKTOK_VIDEO_ANALYSIS_REPORT = gql`
  query tiktok_videoAnalysisReport($end: Date, $start: Date!, $timezone: String) {
    tiktok_videoAnalysisReport(end: $end, start: $start, timezone: $timezone) {
      ad_id
      ad_name
      adaccount_id
      adaccount_name
      category_id
      category_name
      ct_group_id
      average_video_play
      ct_video_id
      impressions
      landing_page
      platform_id
      platform_name
      reach
      title
      ct_title_id
      clicks
      revenue
      spend
      conversion
      video_duration
      video_id
      video_name
      video_thruplay
      video_thumb_url
      video_url
      video_views_p25
      video_views_p50
      video_views_p75
      video_watched_2s
      video_watched_6s
    }
  }
`;
