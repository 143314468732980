import { Form, Button, Row, Col, Switch } from "antd";
import Loader from "components/loader";
import { Formik } from "formik";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    btnText = "Add",
    loading = false,
    sasPlatforms = [],
    sasCategories = [],
    sasVendors_dropdown = [],
  } = props;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="name"
                label="Name"
                disabled
                className="look-form-input"
                placeholder="Enter the name"
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="channelId"
                label="Channel Id"
                disabled
                className="look-form-input"
                placeholder="Enter Channel ID"
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="styleId"
                label="Style Id"
                className="look-form-input"
                placeholder="Enter style ID"
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={24}>
              <SelectInput
                label="Vendor"
                name="vendorId"
                placeholder="Select vendor"
                search
                formik
                value={values.vendorId}
                onChange={setFieldValue}
                options={sasVendors_dropdown.map(({ name, id }) => ({ name, value: id }))}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <p>Is Restricted</p>
            </Col>
            <Col span={6}>
              <Switch
                checkedChildren="On"
                unCheckedChildren="Off"
                size="medium"
                checked={values?.isRestricted}
                onClick={(e) => setFieldValue("isRestricted", e)}
              />
            </Col>
          </Row>
          <Col span={24}>
            <SelectInput
              label="Platform"
              search
              formik
              name="platformId"
              value={values.platformId}
              onChange={setFieldValue}
              options={sasPlatforms.map(({ name, id }) => ({ name, value: id }))}
            />
          </Col>
          <Col span={24}>
            <SelectInput
              label="Category"
              name="categoryId"
              search
              formik
              value={values.categoryId}
              onChange={setFieldValue}
              options={sasCategories.map(({ name, id }) => ({ name, value: id }))}
            />
          </Col>
          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
