import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { LAUNCH_TYPE_LEADGEN } from "components/LaunchView/LaunchKeys";
import {
  FacebookReports,
  GoogleReports,
  TiktokReports,
  GlobalReports,
} from "../../pages/dashboardPages/Leadgen"; // TODO: move to lazy load
import {
  GOOGLE_VIEW_TEST,
  PERMISSION_VOLUUM_VIEW,
  VOLUUM_VIEW,
  VOLUUM_VIEW_TEAM_REPORTING,
  VOLUUM_VIEW_WORKSPACE_REPORTING,
} from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";
import { PRODUCT_ID_LEADGEN } from "shared/SharedKeys";

const AdAccountReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "FacebookReports-AdAccountReports" */ "pages/dashboardPages/Leadgen/Reports/FacebookReports/AdAccountReports"
    )
  )
);
const XRevenue = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Leadgen-XRevenue" */ "pages/dashboardPages/Leadgen/XRevenue")
  )
);
const UserReports = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Leadgen-UserReports" */ "pages/dashboardPages/Leadgen/UserReports")
  )
);
const SubIdReports = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Leadgen-SubIdReports" */ "pages/dashboardPages/Leadgen/SubIdListing/SubIdReports"
    )
  )
);
const Publishers = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Leadgen-Publishers" */ "pages/dashboardPages/Leadgen/Publishers")
  )
);
const LandingUrls = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Leadgen-LandingUrls" */ "pages/dashboardPages/Leadgen/LandingUrls")
  )
);
const VideoAnalysis = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Leadgen-VideoAnalysis" */ "pages/dashboardPages/Leadgen/VideoAnalysis"
    )
  )
);
const TrackerCategory = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Leadgen-A-TrackerCategory" */ "pages/dashboardPages/Leadgen/TrackerCategory"
    )
  )
);
const LaunchMainPage = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Leadgen-LaunchMainPage" */ "components/LaunchView/LaunchMainPage")
  )
);
const ExistingAdminRule = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "Leadgen-AdminRules" */ "components/LookTable/RuleEngine/AdminRules"
    )
  )
);
const AddRule = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Leadgen-RuleEngine" */ "components/LookTable/RuleEngine")
  )
);

export default function useleadgenRoutes() {
  const ruleSetting = {
    platform: "fb",
    productId: PRODUCT_ID_LEADGEN,
  };

  const leadgenRoutes = [
    route("/leadgen/reports/ad-account/:accID", AdAccountReports, ROUTE_DASHBOARD, false, [
      VOLUUM_VIEW,
    ]),
    route("/leadgen/reports/user/:userID", UserReports, ROUTE_DASHBOARD, false, [
      VOLUUM_VIEW_WORKSPACE_REPORTING,
      VOLUUM_VIEW_TEAM_REPORTING,
    ]),
    route("/leadgen/copilot/rules", () => <AddRule {...ruleSetting} />, ROUTE_DASHBOARD, true),
    route(
      "/leadgen/copilot/allrules",
      () => <ExistingAdminRule {...ruleSetting} />,
      ROUTE_DASHBOARD,
      true
    ),
    route("/leadgen/global-reports", GlobalReports, ROUTE_DASHBOARD, false, VOLUUM_VIEW),
    route("/leadgen/facebook-reports", FacebookReports, ROUTE_DASHBOARD, false, VOLUUM_VIEW),
    route("/leadgen/google-reports", GoogleReports, ROUTE_DASHBOARD, false, GOOGLE_VIEW_TEST),
    route("/leadgen/tiktok-reports", TiktokReports, ROUTE_DASHBOARD, false, VOLUUM_VIEW),
    route("/leadgen/publishers", Publishers, ROUTE_DASHBOARD, false, VOLUUM_VIEW),
    route("/leadgen/x-revenue", XRevenue, ROUTE_DASHBOARD, true, PERMISSION_VOLUUM_VIEW),
    route("/leadgen/landing-urls", LandingUrls, ROUTE_DASHBOARD, true, PERMISSION_VOLUUM_VIEW),
    route("/leadgen/category", TrackerCategory, ROUTE_DASHBOARD, true, PERMISSION_VOLUUM_VIEW),
    route("/leadgen/subid", SubIdReports, ROUTE_DASHBOARD, true, PERMISSION_VOLUUM_VIEW),
    // route("/leadgen/compliance", LGCompliance, ROUTE_DASHBOARD, true, [F360_VIEW_COMPLIANCE]),
    route(
      "/leadgen/launch",
      () => <LaunchMainPage launchType={LAUNCH_TYPE_LEADGEN} />,
      ROUTE_DASHBOARD,
      true
    ),
    route("/leadgen/video-analysis", VideoAnalysis, ROUTE_DASHBOARD, false, PERMISSION_VOLUUM_VIEW),
  ];
  return { leadgenRoutes };
}
