import { gql } from "@apollo/client";

export const GET_FACEBOOK_AUTH_URL = gql`
  query fb360_getAuthUrl($redirect_url: FB360!) {
    fb360_getAuthUrl(redirect_url: $redirect_url)
  }
`;
export const FB360_CONNECT_ACCOUNT_BY_CODE = gql`
  mutation fb360_signInAndSync($code: String!, $redirect_url: FB360!) {
    fb360_signInAndSync(code: $code, redirect_url: $redirect_url)
  }
`;

export const FB360_GET_PROFILES = gql`
  query fb360_profileGet {
    fb360_profileGet {
      id
      name
      picture
      profile_id
    }
  }
`;

const FB360_AD_ACCOUNT_RESPONSE = gql`
  fragment FB360_Adaccount on FB360_Adaccount {
    adaccount_id
    alias
    amount_spent
    balance
    created_time
    currency
    id
    name
    spend_cap
    timezone_name
    is_sync_on
    user {
      id
      name
    }
    last_updated_time
    report_updated_time
    is_lfbc
    token_type
  }
`;

export const FB360_GET_AD_ACCOUNTS = gql`
  ${FB360_AD_ACCOUNT_RESPONSE}
  query fb360_adaccountGet($profile_id: ID, $end: Date, $start: Date) {
    fb360_adaccountGet(profile_id: $profile_id, end: $end, start: $start) {
      ...FB360_Adaccount
      last_month_spend
      spend
      account_status
    }
  }
`;

export const FB360_GET_AD_ACCOUNTS_DROPDOWN = gql`
  query fb360_adaccountGet {
    fb360_adaccountGet {
      adaccount_id
      name
    }
  }
`;

const FB360_CAMPAIGN_RESPONSE = gql`
  fragment FB360_Campaign on FB360_Campaign {
    adaccount_id
    adaccount_name
    campaign_id
    created_time
    daily_budget
    effective_status
    name
    status
    updated_time
  }
`;

export const FB360_GET_CAMPAIGNS = gql`
  ${FB360_CAMPAIGN_RESPONSE}
  query fb360_campaignGet {
    fb360_campaignGet {
      ...FB360_Campaign
    }
  }
`;

const FB360_ADSET_RESPONSE = gql`
  fragment FB360_Adset on FB360_Adset {
    adaccount_id
    adaccount_name
    adset_id
    campaign_id
    campaign_name
    created_time
    effective_status
    name
    status
    updated_time
  }
`;

export const FB360_GET_ADSETS = gql`
  ${FB360_ADSET_RESPONSE}
  query fb360_adsetGet {
    fb360_adsetGet {
      ...FB360_Adset
    }
  }
`;

const FB360_ADS_RESPONSE = gql`
  fragment FB360_Ad on FB360_Ad {
    ad_id
    adaccount_id
    adaccount_name
    adset_id
    adset_name
    campaign_id
    campaign_name
    created_time
    creative_id
    effective_status
    name
    status
    updated_time
  }
`;

export const FB360_GET_ADS = gql`
  ${FB360_ADS_RESPONSE}
  query fb360_adsGet {
    fb360_adsGet {
      ...FB360_Ad
    }
  }
`;
export const FB360_GET_ADS_CHEATER = gql`
  query fb360_cheater($end: Date!, $start: Date!, $status: FB360_STATUS) {
    fb360_cheater(end: $end, start: $start, status: $status) {
      ad_id
      adaccount_id
      adaccount_name
      # created_time
      # creative_id
      # effective_status
      name
      # status
      # updated_time
    }
  }
`;

export const FB360_GET_AD_CHEATER_CREATIVES = gql`
  query fb360_cheater_creatives($ad_id: ID!) {
    fb360_cheater_creatives(ad_id: $ad_id) {
      users {
        id
        name
      }
      creatives {
        id
        iframe
      }
    }
  }
`;

const F360_REPORT_RESPONSE = gql`
  fragment FB360_Reporting on FB360_Reporting {
    clicks
    effective_status
    impressionsFB: impressions
    inline_link_clicks
    objective
    report_time
    result
    spend
    status
    adaccount_id
    ad_compliant_status
  }
`;

export const GET_F360_CAMPAIGN_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignReporting(
    $end: Date
    $start: Date
    $timezone: String
    $user_id: ID
    $is_pulse: Boolean
    $vendor_id: ID
  ) {
    fb360_campaignReporting(
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
      is_pulse: $is_pulse
      vendor_id: $vendor_id
    ) {
      campaign_id
      campaign_name
      daily_budget
      lifetime_budget
      ...FB360_Reporting
      is_pulse
      created_time
    }
  }
`;

export const GET_F360_ADGROUP_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetReporting(
    $end: Date
    $start: Date
    $timezone: String
    $user_id: ID
    $vendor_id: ID
  ) {
    fb360_adsetReporting(
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
      vendor_id: $vendor_id
    ) {
      adset_id
      adset_name
      daily_budget
      lifetime_budget
      ...FB360_Reporting
      bid_amount
      bid_strategy
      created_time
    }
  }
`;

export const GET_F360_AD_ACCOUNT_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adaccountReporting(
    $end: Date
    $start: Date
    $timezone: String
    $user_id: ID
    $vendor_id: ID
  ) {
    fb360_adaccountReporting(
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
      vendor_id: $vendor_id
    ) {
      adaccount_name
      alias
      last_updated_time
      report_updated_time
      ...FB360_Reporting
    }
  }
`;

export const GET_FB360_VIDEO_REPORT = gql`
  query fb360_videoReporting(
    $end: Date
    $start: Date
    $category_id: ID
    $timezone: String
    $adaccount_ids: [ID]
    $user_ids: [ID]
  ) {
    fb360_videoReporting(
      end: $end
      start: $start
      category_id: $category_id
      timezone: $timezone
      adaccount_ids: $adaccount_ids
      user_ids: $user_ids
    ) {
      video_id
      video_p25_views
      video_p50_views
      video_p75_views
      video_p95_views
      video_p100_views
      video_avg_time
      video_3_sec_views
      payout_amount
      revenue
      spend
      clicks
      impressions
      result
      video_thruplay
      video_views
    }
  }
`;

export const GET_FB360_AD_VIDEO_URL = gql`
  query fb360_adVideoUrl($video_id: ID) {
    fb360_adVideoUrl(video_id: $video_id) {
      duration
      video_url
      video_id
    }
  }
`;

export const GET_F360_SUBID_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_subIdReporting($end: Date, $start: Date, $timezone: String) {
    fb360_subIdReporting(end: $end, start: $start, timezone: $timezone) {
      adaccount_name
      sub_id
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_AD_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adReporting(
    $end: Date
    $start: Date
    $timezone: String
    $user_id: ID
    $vendor_id: ID
  ) {
    fb360_adReporting(
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
      vendor_id: $vendor_id
    ) {
      ad_id
      ad_name
      ...FB360_Reporting
      created_time
    }
  }
`;

export const GET_F360_DAILY_CAMPAIGN_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignDay($end: Date, $start: Date, $timezone: String) {
    fb360_campaignDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DAILY_ADGROUP_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetDay($end: Date, $start: Date, $timezone: String) {
    fb360_adsetDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DAILY_AD_ACCOUNT_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adaccountDay($end: Date, $start: Date, $timezone: String) {
    fb360_adaccountDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DAILY_AD_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adDay($end: Date, $start: Date, $timezone: String) {
    fb360_adDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOURLY_CAMPAIGN_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignHour($end: Date, $start: Date, $timezone: String) {
    fb360_campaignHour(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOURLY_ADGROUP_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetHour($end: Date, $start: Date, $timezone: String) {
    fb360_adsetHour(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOURLY_AD_ACCOUNT_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adaccountHour($end: Date, $start: Date, $timezone: String) {
    fb360_adaccountHour(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOURLY_AD_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adHour($end: Date, $start: Date, $timezone: String) {
    fb360_adHour(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOUR_OF_THE_DAY_CAMPAIGN_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignHourOfDay($end: Date, $start: Date, $timezone: String) {
    fb360_campaignHourOfDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOUR_OF_THE_DAY_ADGROUP_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetHourOfDay($end: Date, $start: Date, $timezone: String) {
    fb360_adsetHourOfDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOUR_OF_THE_DAY_AD_ACCOUNT_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adaccountHourOfDay($end: Date, $start: Date, $timezone: String) {
    fb360_adaccountHourOfDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_HOUR_OF_THE_DAY_AD_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adHourOfDay($end: Date, $start: Date, $timezone: String) {
    fb360_adHourOfDay(end: $end, start: $start, timezone: $timezone) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_AD_ACCOUNT_HOUR_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adaccountDetailHour(
    $end: Date!
    $start: Date!
    $timezone: String
    $adaccount_id: ID!
  ) {
    fb360_adaccountDetailHour(
      end: $end
      start: $start
      timezone: $timezone
      adaccount_id: $adaccount_id
    ) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_AD_ACCOUNT_DAILY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adaccountDetailDay(
    $end: Date!
    $start: Date!
    $timezone: String
    $adaccount_id: ID!
  ) {
    fb360_adaccountDetailDay(
      end: $end
      start: $start
      timezone: $timezone
      adaccount_id: $adaccount_id
    ) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_AD_ACCOUNT_HOUR_OF_DAY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adaccountDetailHourOfDay(
    $end: Date!
    $start: Date!
    $timezone: String
    $adaccount_id: ID!
  ) {
    fb360_adaccountDetailHourOfDay(
      end: $end
      start: $start
      timezone: $timezone
      adaccount_id: $adaccount_id
    ) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_CAMPAIGN_HOUR_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignDetailHour($end: Date!, $start: Date!, $timezone: String, $campaign_id: ID!) {
    fb360_campaignDetailHour(
      end: $end
      start: $start
      timezone: $timezone
      campaign_id: $campaign_id
    ) {
      campaign_id
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_CAMPAIGN_DAILY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignDetailDay($end: Date!, $start: Date!, $timezone: String, $campaign_id: ID!) {
    fb360_campaignDetailDay(
      end: $end
      start: $start
      timezone: $timezone
      campaign_id: $campaign_id
    ) {
      campaign_id
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_CAMPAIGN_HOUR_OF_DAY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignDetailHourOfDay(
    $end: Date!
    $start: Date!
    $timezone: String
    $campaign_id: ID!
  ) {
    fb360_campaignDetailHourOfDay(
      end: $end
      start: $start
      timezone: $timezone
      campaign_id: $campaign_id
    ) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_ADSET_HOUR_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetDetailHour($end: Date!, $start: Date!, $timezone: String, $adset_id: ID!) {
    fb360_adsetDetailHour(end: $end, start: $start, timezone: $timezone, adset_id: $adset_id) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_ADSET_DAILY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetDetailDay($end: Date!, $start: Date!, $timezone: String, $adset_id: ID!) {
    fb360_adsetDetailDay(end: $end, start: $start, timezone: $timezone, adset_id: $adset_id) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_ADSET_HOUR_OF_DAY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetDetailHourOfDay($end: Date!, $start: Date!, $timezone: String, $adset_id: ID!) {
    fb360_adsetDetailHourOfDay(end: $end, start: $start, timezone: $timezone, adset_id: $adset_id) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_AD_HOUR_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adDetailHour($end: Date!, $start: Date!, $timezone: String, $ad_id: ID!) {
    fb360_adDetailHour(end: $end, start: $start, timezone: $timezone, ad_id: $ad_id) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_AD_DAILY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adDetailDay($end: Date!, $start: Date!, $timezone: String, $ad_id: ID!) {
    fb360_adDetailDay(end: $end, start: $start, timezone: $timezone, ad_id: $ad_id) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_DETAIL_AD_HOUR_OF_DAY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adDetailHourOfDay($end: Date!, $start: Date!, $timezone: String, $ad_id: ID!) {
    fb360_adDetailHourOfDay(end: $end, start: $start, timezone: $timezone, ad_id: $ad_id) {
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_AD_GROUPED_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adGroupedReporting(
    $end: Date!
    $start: Date!
    $timezone: String
    $asset_id: [ID!]
    $asset_type: FB360_ASSET!
  ) {
    fb360_adGroupedReporting(
      end: $end
      start: $start
      timezone: $timezone
      asset_id: $asset_id
      asset_type: $asset_type
    ) {
      ad_id
      ad_name
      daily_budget
      lifetime_budget
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_ADSET_GROUPED_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_adsetGroupedReporting(
    $end: Date!
    $start: Date!
    $timezone: String
    $asset_id: [ID!]
    $asset_type: FB360_ASSET!
  ) {
    fb360_adsetGroupedReporting(
      end: $end
      start: $start
      timezone: $timezone
      asset_id: $asset_id
      asset_type: $asset_type
    ) {
      adset_id
      adset_name
      daily_budget
      lifetime_budget
      bid_amount
      bid_strategy
      ...FB360_Reporting
    }
  }
`;

export const GET_F360_CAMPAIGN_GROUPED_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_campaignGroupedReporting(
    $end: Date!
    $start: Date!
    $timezone: String
    $asset_id: [ID!]
    $asset_type: FB360_ASSET!
  ) {
    fb360_campaignGroupedReporting(
      end: $end
      start: $start
      timezone: $timezone
      asset_id: $asset_id
      asset_type: $asset_type
    ) {
      campaign_id
      campaign_name
      daily_budget
      lifetime_budget
      bid_amount
      bid_strategy
      ...FB360_Reporting
    }
  }
`;

export const ASSIGN_F360_USER_TO_ADACCOUNTS = gql`
  mutation Fb360_assignUserWorkspaceAdaccount(
    $user_id: ID
    $workspace_id: ID
    $adaccount_id: [ID]
  ) {
    fb360_assignUserWorkspaceAdaccount(
      user_id: $user_id
      workspace_id: $workspace_id
      adaccount_id: $adaccount_id
    )
  }
`;
export const ASSIGN_ADACCOUNTS_WORKSPACE = gql`
  mutation Fb360_assignUserWorkspaceAdaccounts($workspace_id: ID, $adaccount_id: [ID]) {
    fb360_assignUserWorkspaceAdaccounts(workspace_id: $workspace_id, adaccount_id: $adaccount_id)
  }
`;

export const DELETE_FB360_PROFILE = gql`
  mutation Fb360_profileDelete($profileId: ID!) {
    fb360_profileDelete(profile_id: $profileId)
  }
`;

export const UPDATE_FB360_STATUS = gql`
  mutation fb360_bulkUpdateStatus(
    $asset: FB360_ASSET
    $updateArray: [FB360_updateArray]
    $status: FB360_STATUS
  ) {
    fb360_bulkUpdateStatus(asset: $asset, updateArray: $updateArray, status: $status)
  }
`;

export const UPDATE_FB360_BUDGET = gql`
  mutation fb360_bulkUpdateBudget($asset: FB360_ASSET, $updateArray: [FB360_updateBudgetinput]) {
    fb360_bulkUpdateBudget(asset: $asset, updateArray: $updateArray)
  }
`;

export const GET_FB360_BM = gql`
  query Fb360_businessGet {
    fb360_businessGet {
      name
      token_added
      status
      bm_created_on
      bm_updated_on
      bm_fb_id
      id
    }
  }
`;

export const REMOVE_FB360_ADACCOUNTS = gql`
  mutation Fb360_removeAdaccountUser($adaccount_id: ID!) {
    fb360_removeAdaccountUser(adaccount_id: $adaccount_id)
  }
`;

export const FB360_ALIAS_ASSIGN = gql`
  mutation Fb360_setAdaccountAlias($alias: String!, $adaccount_id: ID) {
    fb360_setAdaccountAlias(alias: $alias, adaccount_id: $adaccount_id)
  }
`;

export const FB360_ADD_BM_TOKEN = gql`
  mutation Fb360_addBmToken($bmToken: String!, $bmId: ID) {
    fb360_addBmToken(bm_token: $bmToken, bm_id: $bmId)
  }
`;

export const FB360_ASSET_SYNC = gql`
  mutation fb360_campaignSync($adaccount_id: [ID]) {
    fb360_campaignSync(adaccount_id: $adaccount_id)
  }
`;

export const FB360_REPORT_SYNC = gql`
  mutation fb360_reportsSync($end: Date, $start: Date, $adaccount_id: [ID]) {
    fb360_reportsSync(end: $end, start: $start, adaccount_id: $adaccount_id)
  }
`;

export const FB360_UPDATE_ADACCOUNT_VENDOR = gql`
  mutation fb360_updateAdaccountVendor($vendor_id: ID!, $adaccount_id: [ID!]) {
    fb360_updateAdaccountVendor(vendor_id: $vendor_id, adaccount_id: $adaccount_id)
  }
`;

export const FB360_VENDOR_LISTING = gql`
  query fb360_vendorListing {
    fb360_vendorListing {
      id
      name
    }
  }
`;

export const GET_F360_COUNTRY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_countryReporting(
    $adaccount_id: ID
    $asset: FB360_ASSET
    $asset_id: ID
    $end: Date
    $start: Date
  ) {
    fb360_countryReporting(
      adaccount_id: $adaccount_id
      asset: $asset
      asset_id: $asset_id
      end: $end
      start: $start
    ) {
      reports {
        ...FB360_Reporting
        country
      }
      timezone
    }
  }
`;

export const GET_F360_COUNTRY_REGION_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_countryRegionReporting(
    $adaccount_id: ID
    $asset: FB360_ASSET
    $asset_id: ID
    $end: Date
    $start: Date
  ) {
    fb360_countryRegionReporting(
      adaccount_id: $adaccount_id
      asset: $asset
      asset_id: $asset_id
      end: $end
      start: $start
    ) {
      reports {
        ...FB360_Reporting
        country
        region
      }
      timezone
    }
  }
`;

export const GET_F360_PLACEMENT_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_placementReporting(
    $adaccount_id: ID
    $asset: FB360_ASSET
    $asset_id: ID
    $end: Date
    $start: Date
  ) {
    fb360_placementReporting(
      adaccount_id: $adaccount_id
      asset: $asset
      asset_id: $asset_id
      end: $end
      start: $start
    ) {
      reports {
        ...FB360_Reporting
        platform_position
        publisher_platform
      }
      timezone
    }
  }
`;

export const GET_F360_REGION_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_regionReporting(
    $adaccount_id: ID
    $asset: FB360_ASSET
    $asset_id: ID
    $end: Date
    $start: Date
  ) {
    fb360_regionReporting(
      adaccount_id: $adaccount_id
      asset: $asset
      asset_id: $asset_id
      end: $end
      start: $start
    ) {
      reports {
        ...FB360_Reporting
        region
      }
      timezone
    }
  }
`;

export const GET_F360_DEVICE_OS_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_deviceOsReporting(
    $adaccount_id: ID
    $asset: FB360_ASSET
    $asset_id: ID
    $end: Date
    $start: Date
  ) {
    fb360_deviceOsReporting(
      adaccount_id: $adaccount_id
      asset: $asset
      asset_id: $asset_id
      end: $end
      start: $start
    ) {
      reports {
        ...FB360_Reporting
        device_platform
        impression_device
      }
      timezone
    }
  }
`;

export const GET_F360_PLACEMENT_DEVICE_OS_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_placementDeviceOsReporting(
    $adaccount_id: ID
    $asset: FB360_ASSET
    $asset_id: ID
    $end: Date
    $start: Date
  ) {
    fb360_placementDeviceOsReporting(
      adaccount_id: $adaccount_id
      asset: $asset
      asset_id: $asset_id
      end: $end
      start: $start
    ) {
      reports {
        ...FB360_Reporting
        device_platform
        platform_position
        publisher_platform
      }
      timezone
    }
  }
`;

export const GET_F360_USER_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_userReporting(
    $end: Date
    $start: Date
    $timezone: String
    $is_detailed: Boolean
    $is_self: Boolean
    $is_lfbc: Boolean
  ) {
    fb360_userReporting(
      end: $end
      start: $start
      timezone: $timezone
      is_detailed: $is_detailed
      is_self: $is_self
      is_lfbc: $is_lfbc
    ) {
      user_id
      userId: user_id
      user {
        id
        name
      }
      ...FB360_Reporting
    }
  }
`;

export const GET_FB360_CREATIVES = gql`
  query fb360_creatives($asset: FB360_ASSET, $asset_id: [ID]) {
    fb360_creatives(asset: $asset, asset_id: $asset_id)
  }
`;

export const GET_VOLUUM_URL_CATEGORIES = gql`
  query voluum_get_url_categories($platform_id: ID) {
    voluum_get_url_categories(platform_id: $platform_id) {
      ad_account_id
      category {
        name
        id
      }
      url
    }
  }
`;

export const VOLUUM_ASSIGN_CATEGORY_TO_LANDING_URLS = gql`
  mutation voluum_assign_categories($category_id: ID!, $updateFields: [voluum_account_url]) {
    voluum_assign_categories(category_id: $category_id, updateFields: $updateFields)
  }
`;

export const GET_VOLUUM_CATEGORY = gql`
  query voluum_get_category {
    voluum_get_category {
      id
      name
      alias
    }
  }
`;

export const GET_VOLUUM_CATEGORY_DROPDOWN = gql`
  query voluum_get_category {
    voluum_get_category {
      id
      name
    }
  }
`;

export const VOLUUM_ADD_CATEGORY = gql`
  mutation voluum_add_category($name: String, $alias: String) {
    voluum_add_category(name: $name, alias: $alias) {
      name
      alias
      id
    }
  }
`;

export const VOLUUM_UPDATE_CATEGORY = gql`
  mutation voluum_update_category($id: ID, $name: String) {
    voluum_update_category(id: $id, name: $name) {
      name
      id
    }
  }
`;

export const FB360_INSERT_TAG = gql`
  mutation fb360_insertTag(
    $ad_id: [ID]
    $adset_id: [ID]
    $campaign_id: [ID]
    $strategy_tag: String
  ) {
    fb360_insertTag(
      ad_id: $ad_id
      adset_id: $adset_id
      campaign_id: $campaign_id
      strategy_tag: $strategy_tag
    )
  }
`;

export const RULE_ENGINE_GET_TAGS = gql`
  query re_getTag {
    re_getTag {
      s_tag
      name
    }
  }
`;

export const GET_F360_CATEGORY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_categoryReporting(
    $end: Date
    $start: Date
    $timezone: String
    $user_id: ID
    $product_id: Int
  ) {
    fb360_categoryReporting(
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
      product_id: $product_id
    ) {
      ...FB360_Reporting
      category {
        id
        name
      }
    }
  }
`;

export const GET_F360_VS_CATEGORY_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_categoryReporting(
    $end: Date
    $start: Date
    $timezone: String
    $user_id: ID
    $product_id: Int
  ) {
    fb360_categoryReporting(
      end: $end
      start: $start
      timezone: $timezone
      user_id: $user_id
      product_id: $product_id
    ) {
      ...FB360_Reporting
      vs_category {
        id
        nickname
      }
    }
  }
`;

export const VOLUUM_GET_X_REVENUE = gql`
  query voluum_get_XRevenue($to: Date, $platform_id: ID, $from: Date) {
    voluum_get_XRevenue(to: $to, platform_id: $platform_id, from: $from) {
      category {
        id
        name
      }
      date
      notes
      platform_id
      x_revenue
    }
  }
`;

export const VOLUUM_ADD_X_REVENUE = gql`
  mutation voluum_add_XRevenue(
    $category_id: ID!
    $date: Date!
    $notes: String
    $platform_id: ID!
    $revenue: Float
  ) {
    voluum_add_XRevenue(
      category_id: $category_id
      date: $date
      notes: $notes
      platform_id: $platform_id
      revenue: $revenue
    ) {
      category {
        id
        name
      }
      date
      notes
      platform_id
      x_revenue
    }
  }
`;

export const VOLUUM_GET_SUBIDS = gql`
  query voluum_get_subids($from: Date, $platform_id: ID, $to: Date) {
    voluum_get_subids(from: $from, platform_id: $platform_id, to: $to) {
      sub_id
      user {
        id
        name
      }
    }
  }
`;

export const VOLUUM_GET_PUBLISHERS = gql`
  query voluum_get_publishers {
    voluum_get_publishers {
      message
      status
      data {
        id
        is_admin
        platform {
          id
          name
        }
        platform_id
        publisher_name
        token
        user_id
        has_token
      }
    }
  }
`;

export const VOLUUM_PUBLISHER_UPDATE = gql`
  mutation voluum_publisher_update($id: ID, $platform_id: ID, $token: String!, $user_id: ID) {
    voluum_publisher_update(id: $id, platform_id: $platform_id, token: $token, user_id: $user_id) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const FB360_CHECK_EXISTING_PULSE_CAMPAIGN = gql`
  query Fb360_checkExistingPulseCampaign($fields: [FB360_pulse_input]) {
    fb360_checkExistingPulseCampaign(fields: $fields) {
      campaign_id
      campaign_name
      ncid
      adaccount_id
    }
  }
`;

export const FB360_CREATE_PULSE_CAMPAIGN = gql`
  mutation Fb360_createPulseCampaign($fields: [FB360_pulse_input]) {
    fb360_createPulseCampaign(fields: $fields)
  }
`;

export const LEADGEN_GLOBAL_REPORT = gql`
  query leadgen_globalReport($from: Date!, $timezone: String!, $to: Date!) {
    leadgen_globalReport(from: $from, timezone: $timezone, to: $to) {
      category {
        id
        name
      }
      f_clicks
      f_impressions
      f_inline_link_clicks
      f_results
      f_spend
      f_rev_clicks
      f_rev_conversions
      f_rev_revenue
      f_rev_visits
      landing_url
      t_clicks
      t_conversions
      t_impressions
      t_reach
      t_spend
      t_rev_clicks
      t_rev_conversions
      t_rev_revenue
      t_rev_visits
      rev_x_revenue
      f_rev_payout_amount
      t_rev_payout_amount
    }
  }
`;

export const LEADGEN_GLOBAL_USER_REPORT = gql`
  query leadgen_globalUserReport(
    $category_id: ID!
    $from: Date!
    $platform_id: ID
    $timezone: String!
    $to: Date!
  ) {
    leadgen_globalUserReport(
      category_id: $category_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
    ) {
      f_clicks
      f_impressions
      f_inline_link_clicks
      f_results
      f_spend
      landing_url
      f_rev_clicks
      f_rev_conversions
      f_rev_revenue
      f_rev_visits
      t_clicks
      t_conversions
      t_impressions
      t_reach
      t_spend
      t_rev_clicks
      t_rev_conversions
      t_rev_revenue
      t_rev_visits
      rev_x_revenue
      f_rev_payout_amount
      t_rev_payout_amount
      user {
        id
        name
        user_name
      }
    }
  }
`;

export const GET_F360_GLOBAL_LANDER_REPORT = gql`
  ${F360_REPORT_RESPONSE}
  query fb360_globalLanderReport(
    $category_id: [ID]
    $end: Date!
    $start: Date!
    $timezone: String
  ) {
    fb360_globalLanderReport(
      category_id: $category_id
      end: $end
      start: $start
      timezone: $timezone
    ) {
      lander_url
      result
      ...FB360_Reporting
    }
  }
`;

export const Voluum_BM_REVENUE_REPORT = gql`
  query voluum_bm_revenue($start: Date, $end: Date, $timezone: String, $isAll: Boolean) {
    voluum_bm_revenue(start: $start, end: $end, timezone: $timezone, is_all: $isAll) {
      revenue
      bm_name
      bm_id
      payout_amount
    }
  }
`;

export const FB360_BM_REPORT = gql`
  query fb360_bmReport($start: Date, $end: Date, $timezone: String, $isAll: Boolean) {
    fb360_bmReport(start: $start, end: $end, timezone: $timezone, is_all: $isAll) {
      spend
      bm_name
      bm_id
    }
  }
`;
