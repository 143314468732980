import { Col, Form, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import AdListing from "./AdListing";
import { useGoogleLaunchV1Context } from "../../Context";
import SelectInput from "components/forms/SelectInput";
import { GL_STATUS_ENUM } from "shared/enum/launchKeysAndEnum";

export default function AdGroupAdTab() {
  const { handleAdgroupLevelData, campaignObject } = useGoogleLaunchV1Context();

  const status = () => (
    <SelectInput
      label="Status"
      value={campaignObject?.[0]?.adGroups?.[0]?.status}
      onChange={(e) => handleAdgroupLevelData({ status: e })}
      options={GL_STATUS_ENUM}
    />
  );

  const adGroupName = () => (
    <Form layout="vertical">
      <Row gutter={12}>
        <Col span={12}>
          <LookTextInput
            label="Ad group name"
            name="adGroupName"
            required
            requiredLabel="Ad group name"
            placeholder="Enter ad group name"
            value={campaignObject?.[0]?.adGroups?.[0]?.name}
            onChange={(e) => handleAdgroupLevelData({ name: e.target.value })}
          />
        </Col>
        <Col span={12}>{status()}</Col>
      </Row>
    </Form>
  );

  return (
    <div>
      <div className="px-20">{adGroupName()}</div>
      <AdListing />
    </div>
  );
}
