import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SAS_CATEGORY_HISTORY_REPORT } from "shared/gql/gqlSchema/sasGql";
import { numberParse } from "utils";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import SelectInput from "components/forms/SelectInput";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import moment from "moment";

function CategoryHistoryReport(props) {
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, userSettingData } = userSettings || {};
  const { sasPlatforms = [], sasPlatforms_loading = false } = userSettingData || {};
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN, id = "" } = props;
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const [categoryHistoryReport, setCategoryHistoryReport] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  //   const [rangeDates, setRangeDates] = useState([dateRangeTimezone?.rangeDates]);
  const dateFormat = "YYYY-MM-DD";
  const [rangeDates, setRangeDates] = useState([
    moment().subtract(1, "months").format(dateFormat),
    moment().format(dateFormat),
  ]);
  const [timeZone, setTimeZone] = useState(dateRangeTimezone?.timeZone);
  const { filterData } = useLookTableFilterData(persistKey);
  const [platformId, setPlatformId] = useState("1");
  const { sasPlatformsFetch } = useApiToStoreContext();

  useEffect(() => {
    sasPlatformsFetch();
  }, []);

  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  let finalMergeData = [];

  const {
    loading: loading_SAS_CATEGORY_HISTORY_REPORT,
    refetch: refetch_SAS_CATEGORY_HISTORY_REPORT,
  } = useCustomQuery(SAS_CATEGORY_HISTORY_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.sasCategoryHistoryReports;
      if (element) {
        setCategoryHistoryReport(element);
      }
    },
    variables: {
      from: rangeDates[0],
      to: rangeDates[1],
      timezone: timeZone?.value,
      platformId,
      categoryId: id,
    },
  });

  const refetch = () => {
    refetch_SAS_CATEGORY_HISTORY_REPORT();
  };
  const loading = loading_SAS_CATEGORY_HISTORY_REPORT;

  const initialColumns = [
    {
      title: "Country Name",
      dataIndex: "countryName",
      key: "countryName",
      render: (value, otherProps) => <LookTableColumnRender title={otherProps?.country?.name} />,
      fixed: "left",
      width: 190,
      minWidth: 190,
      sorter: (a, b) => a?.countryName - b?.countryName,
      searchType: "string",
    },
    {
      title: "Revenue",
      dataIndex: "estimate_revenue",
      key: "estimate_revenue",
      render: (value, otherProps) => (
        <LookTableColumnRender title={`$${numberParse(value, { toFixed: true })}`} />
      ),
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.estimate_revenue - b?.estimate_revenue,
      searchType: "number",
    },
    {
      title: "Leads V",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",
    },
    {
      title: "RPL",
      dataIndex: "RPL",
      key: "RPL",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 120,
      minWidth: 120,
      sorter: (a, b) => a?.RPL - b?.RPL,
      searchType: "number",
    },
  ];

  if (!loading) {
    finalMergeData = categoryHistoryReport?.map((element, indx) => {
      const { estimate_revenue = 0, clicks = 0, country = {} } = element;
      const RPL = parseFloat(estimate_revenue) / parseFloat(clicks);
      return {
        ...element,
        RPL: numberParse(RPL),
        countryName: country?.name,
        clicks,
        estimate_revenue,
      };
    });
  }

  const finalFilterData = filterData(finalMergeData)?.filter(({ countryName = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    if (countryName?.toLowerCase()?.includes(searchValue)) {
      return true;
    }
    return false;
  });
  let total_estimate_revenue = 0,
    total_clicks = 0,
    total_searches = 0,
    total_sessions = 0;

  for (const { estimate_revenue = 0, clicks = 0, searches = 0, sessions = 0 } of finalFilterData) {
    total_estimate_revenue += parseFloat(estimate_revenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_searches += parseFloat(searches || 0);
    total_sessions += parseFloat(sessions || 0);
  }
  const total_RPL = parseFloat(total_estimate_revenue) / parseFloat(total_clicks);

  const summaryData = {
    estimate_revenue: numberParse(total_estimate_revenue, { toFixed: true }),
    clicks: numberParse(total_clicks),
    searches: numberParse(total_searches),
    sessions: numberParse(total_sessions),
    RPL: numberParse(total_RPL, { toFixed: true }),
  };

  const rightOptions = (
    <>
      <SelectInput
        search
        name="platformId"
        className="mb-0"
        placeHolder="Select platform"
        value={platformId}
        loading={sasPlatforms_loading}
        options={sasPlatforms?.map(({ name, id }) => ({ name, value: id })) || []}
        onChange={(e) => setPlatformId(e)}
      />
    </>
  );

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isWorkspace: false,
    isDateRange: true,
    isRecommendation: true,
    defaultPagination: true,
    rightOptions,
    refetch,
    loading,
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    persistKey,
    dateRangeProps: {
      noStore: true,
      defaultDate: rangeDates,
      defaultTimeZone: timeZone?.value,
      onChange: handleDateRange,
    },
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ categoryHistoryReport }, { finalMergeData }],
    },
  ];

  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}

export default CategoryHistoryReport;
