import { Button, Col, Form, Row } from "antd";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";
import { useApiToStore } from "hooks";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import {
  API_STORE_FB360_CATEGORIES,
  API_STORE_VIRALSPARKS_ADVERTISE,
} from "hooks/useApiToStore/keys";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  GQL_API_RESPONSE_STATUS_SUCCESS,
  PRODUCT_ID_LEADGEN,
  PRODUCT_ID_SAS,
  PRODUCT_ID_VS,
} from "shared/SharedKeys";
import { INDUSTRY_INSIGHTS_SAVE_TO_CREATIVE_LIB } from "shared/gql/gqlSchema/industryInsightsGql";
import { GET_VS_ALL_CONTENT_BY_ADVETTISER } from "shared/gql/gqlSchema/viralSparksGql";
import { openNotification } from "utils";

function AddToCreativeLib(props) {
  const { onCancel = () => {}, adCreative = [], adBodies = [] } = props;
  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);
  const [categories, setCategories] = useState([]);
  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const { apiCall: voluumCategoryFetch = () => {} } = useApiToStore(API_STORE_FB360_CATEGORIES);
  const { apiCall: vsAdvertiserFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ADVERTISE);
  const {
    sasCategories = [],
    sasCategories_loading = false,
    voluumCategories = [],
    voluumCategories_loading = false,
  } = useSelector((state) => state?.userSettings?.userSettingData);
  const [formData, setFormData] = useState({ productId: productList?.[0]?.id });
  const { sasCategoriesFetch } = useApiToStoreContext();
  const [fetchVsContent, { vs_content_data, loading: vs_content_loading, vs_content_refetch }] =
    useCustomLazyQuery(GET_VS_ALL_CONTENT_BY_ADVETTISER, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.vs_contentGetAllByAdvertiserId?.data?.map((element) => ({
          name: element?.nickname,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });
  useEffect(() => {
    voluumCategoryFetch();
    sasCategoriesFetch();
    vsAdvertiserFetch();
  }, []);
  const [ctAddGroups, { loading: loading_ctAddGroups }] = useCustomMutation(
    INDUSTRY_INSIGHTS_SAVE_TO_CREATIVE_LIB,
    {
      onCompleted: (e) => {
        const element = e?.ctAddCreativeGroupsFromCS;
        const { status, data, message } = element;
        if (status === GQL_API_RESPONSE_STATUS_SUCCESS) {
          const { alreadyPresent, unableToAdd } = data?.[0] || {};

          let newMessage = message;
          if (alreadyPresent?.length > 0) {
            newMessage = `Creative is already present`;
            openNotification({
              type: "error",
              message: newMessage,
              key: "addGroup-alreadyPresentError",
            });
            onCancel();
          } else if (unableToAdd?.[0]?.error) {
            openNotification({
              type: "error",
              message: unableToAdd?.[0]?.error,
              key: "addGroup-failedError",
            });
            onCancel();
          } else {
            openNotification({
              type: "success",
              message: message,
              key: "addGroup-success",
            });
            onCancel();
          }
        } else {
          openNotification({ type: "error", message, key: "addGroup-failedError" });
          onCancel();
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message, key: "addgroup-error" });
        onCancel();
      },
    }
  );
  const onSubmit = () => {
    const { productId = "", categoryId = "", categoryName = "" } = formData;
    const { title = {}, linkDescription = {}, images = [], videos = [] } = adCreative?.[0] || {};
    const body = adBodies?.[0]?.value || "";
    const videoData =
      videos.length > 0
        ? { video: { videoUrl: videos[0]?.url, thumbUrls: videos[0]?.thumbnail?.url } }
        : {};
    const imageData = images.length > 0 ? { image: images[0]?.url } : {};

    ctAddGroups({
      variables: {
        productId,
        categoryId,
        categoryName,
        inputs: [
          {
            title: title?.value || "",
            body,
            description: linkDescription?.value || "",
            ...imageData,
            ...videoData,
          },
        ],
      },
    });
  };

  const handleCategories = (serviceType) => {
    switch (parseInt(serviceType)) {
      case PRODUCT_ID_LEADGEN:
        return voluumCategories;
      case PRODUCT_ID_SAS:
        return sasCategories;
      case PRODUCT_ID_VS:
        return categories;

      default:
        break;
    }
  };
  const finalCategories = handleCategories(formData?.productId);
  return (
    <div>
      <Form layout="vertical">
        <Row gutter={12}>
          <Col span={24}>
            <SelectInput
              name="product"
              label="Product"
              placeholder="Select Product"
              className="look-form-input"
              search
              value={formData?.productId}
              onChange={(name, value) => {
                setFormData((prev) => ({ ...prev, productId: value }));
              }}
              options={productList?.map((data) => ({ name: data?.name, value: data?.id }))}
            />
          </Col>
        </Row>
        {formData?.productId === PRODUCT_ID_VS && (
          <Row gutter={12}>
            <Col span={24}>
              <SelectInput
                name="advertiser"
                label="Advertiser"
                placeholder="Select Advertiser"
                className="look-form-input"
                search
                loader={vsAdvertiser_loading}
                // value={addMoodboardData?.advertiser}
                onChange={(name, value) => {
                  setFormData((prev) => ({ ...prev, advertiser: value }));
                  fetchVsContent({ variables: { advertiser_id: value } });
                }}
                options={vsAdvertiser?.map(({ id, name }) => ({ name, value: id }))}
              />
            </Col>
          </Row>
        )}
        {((formData?.advertiser && formData?.productId === PRODUCT_ID_VS) ||
          formData?.productId) && (
          <Row gutter={12}>
            <Col span={24}>
              <SelectInput
                name="categoryId"
                label="Category"
                search
                placeholder="Select Category"
                className="look-form-input"
                options={finalCategories?.map(({ name, id }) => ({ name, value: id }))}
                loader={vs_content_loading || sasCategories_loading || voluumCategories_loading}
                onChange={(name, value) => {
                  setFormData((prev) => ({
                    ...prev,
                    categoryId: name,
                    categoryName: finalCategories?.find((category) => category?.id === name)?.name,
                  }));
                }}
              />
            </Col>
          </Row>
        )}

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                className="submit-btn"
                onClick={onSubmit}
                disabled={loading_ctAddGroups}
              >
                {loading_ctAddGroups ? <Loader /> : "Add"}
              </Button>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Button block className="submit-btn" onClick={onCancel}>
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AddToCreativeLib;
