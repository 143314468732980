import React, { useMemo, useState } from "react";
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { DragHandle, SortableItem, SortableOverlay } from "./components";

export function SortableList({ items, onChange, renderItem }) {
  const [active, setActive] = useState();
  const activeItem = useMemo(() => items?.find((item) => item.id === active?.id), [active, items]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        <div className="SortableList" role="application">
          {items.map((item, index) => (
            <React.Fragment key={item.id}>{renderItem(item, index)}</React.Fragment>
          ))}
        </div>
      </SortableContext>
      <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
    </DndContext>
  );
}

// export function SortableList({ items, onChange, renderItem, idType = "id" }) {
//   const [active, setActive] = useState();

//   const activeItem = useMemo(
//     () =>
//       items.find((item) => {
//         return active && active[idType] && active[idType] ? item[idType] === active[idType] : false;
//       }),
//     [active, items]
//   );
//   const sensors = useSensors(
//     useSensor(PointerSensor),
//     useSensor(KeyboardSensor, {
//       coordinateGetter: sortableKeyboardCoordinates,
//     })
//   );

//   return (
//     <DndContext
//       sensors={sensors}
//       onDragStart={({ active }) => {
//         setActive(active);
//       }}
//       onDragEnd={({ active, over }) => {
//         if (over && active[idType] !== over[idType]) {
//           const activeIndex = items.findIndex(({ [idType]: id }) => id === active[idType]);
//           const overIndex = items.findIndex(({ [idType]: id }) => id === over[idType]);

//           onChange(arrayMove(items, activeIndex, overIndex));
//         }
//         setActive(null);
//       }}
//       onDragCancel={() => {
//         setActive(null);
//       }}
//     >
//       <SortableContext items={items}>
//         <ul className="SortableList" role="application">
//           {items.map((item) => (
//             <React.Fragment key={item[idType]}>{renderItem(item)}</React.Fragment>
//           ))}
//         </ul>
//       </SortableContext>
//       <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
//     </DndContext>
//   );
// }

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
