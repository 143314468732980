import React from "react";
import { Form, Input, InputNumber } from "antd";
import cx from "classnames";
import { ErrorMessage, Field } from "formik";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
const { TextArea, Password } = Input;

const LookTextInput = React.forwardRef((props, ref) => {
  const {
    className = "",
    label,
    required = false,
    requiredLabel = "",
    customRules = [],
    matrialInput = false,
    name,
    type = "text",
    placeholder = "",
    disabled = false,
    error = "",
    value,
    onChange = () => {},
    onClick = () => {},
    onKeyDown,
    onBlur,
    autoSize,
    formik = false,
    inputClassName = "",
    style = {},
    inputStyle = {},
    onChangeFormikForce, //Here function is acting as boolean
    number = false,
    normalNumberInput = false,
    onlyInteger = false,
    currency = false,
    min,
    max,
    rows,
    parser,
    size = "middle",
    optional = false,
    submitOnEnter = false,
  } = props;

  const isOptional = optional ? <span className="optional-text"> (Optional)</span> : undefined;

  const renderLabel = label ? (
    <>
      {label}
      {isOptional}
    </>
  ) : undefined;

  let RenderInput = Input;
  if (type === "password") RenderInput = Password;
  else if (type === "textarea") RenderInput = TextArea;
  if (number || currency || normalNumberInput) RenderInput = InputNumber;

  /**
   * normalNumberInput boolean use for number input without parser
   */

  // We can't add  (type === "number") RenderInput = InputNumber; Because this used in all project
  // InputNumber return direct value , and other inputs returns in e?.target.value
  // So added number prop as boolen

  // else if (type === "number") RenderInput = InputNumber;

  const currencyProps = currency
    ? { formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
    : {};

  const numberProps =
    number || currency
      ? {
          parser: parser //* if parser is sent from FE
            ? parser
            : (e) => (onlyInteger ? e.replace(/[^0-9]/g, "") : e.replace(/[^0-9.]/g, "")),
          ...currencyProps,
        }
      : {};

  const commonInputsProps = {
    type,
    name,
    disabled,
    placeholder,
    ref,
    rows,
    className: inputClassName,
    style: inputStyle,
    ...numberProps,
    size,
  };

  const isFormkiOnChange = onChangeFormikForce ? { onChange: onChangeFormikForce } : {};
  const formikInputsProps = { ...commonInputsProps, as: RenderInput, ...isFormkiOnChange };

  const normalInputsProps = {
    ...commonInputsProps,
    value,
    onChange,
    onClick,
    onKeyDown,
    onBlur,
    autoSize,
    min,
    max,
    rows,
  };

  // const temp = label || name;

  // const customErrorMessage = {
  //   message: `${temp?.charAt(0)?.toUpperCase() + temp?.slice(1)} is required.`,
  // };

  const withRequired =
    required && !formik
      ? {
          rules: [{ required, message: `${requiredLabel || name} is required` }, ...customRules],
          name,
        }
      : {};

  const renderError = (error) => (
    <div className="text-left error-text">
      {parse(error)}
      {/* {ReactHtmlParser(error)} */}
    </div>
  );

  const handleKeyPress = (e) => {
    // Prevent form submission when Enter key is pressed
    if (e.key === "Enter" && !submitOnEnter && type !== "textarea") {
      e.preventDefault();
    }
  };

  return (
    <div className={cx("look-form-input", className, { matrialInput })}>
      <Form.Item
        label={
          label ? (
            <>
              {renderLabel} {required && formik && <span className="text-danger">*</span>}
            </>
          ) : undefined
        }
        style={style}
        {...withRequired}
      >
        {formik ? (
          <>
            <Field {...formikInputsProps} onKeyPress={handleKeyPress} />
            <ErrorMessage name={name} children={(error) => renderError(error)} />
          </>
        ) : (
          <RenderInput {...normalInputsProps} />
        )}
      </Form.Item>
      {!formik && error && renderError(error)}
    </div>
  );
});

export default LookTextInput;
