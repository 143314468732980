import { useEffect, useRef } from "react";
import { COUNTRY_TYPE_FILTER, PAGES_TYPE_FILTER } from "../../industryInsightsEnum";

const { Checkbox } = require("antd");

const CategoryScroll = ({
  item,
  setSelectedFilters = () => {},
  setInfiniteScrollObject,
  setSearchObject,
  selectedFilters,
  setSelectedFiltersArray,
}) => {
  const scrollRef = useRef();

  useEffect(() => {
    const handleIntersection = () => {
      setInfiniteScrollObject((state) => {
        return {
          category: item.headerLabel,
          page: state.page + 1,
        };
      });
      // sets firstSearchPage false - when the user enters a search value & the first page appears, its value changes to true
      // & when the page changes to 2 or more, the value becomes false.
      setSearchObject((state) => {
        return {
          ...state,
          firstSearchPage: false,
        };
      });
    };
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleIntersection();
        }
      },
      { root: null, rootMargin: "0px", threshold: 0 }
    );

    scrollRef?.current && observer?.observe(scrollRef?.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="filter-category-subvalues" id={`${item.headerLabel}-filter-category-subvalues`}>
      {item.subvalues?.map((check, index) => {
        return (
          <Checkbox
            key={check.id}
            className="filter-item-checkbox"
            onChange={(e) => {
              setSelectedFilters((prevItems) => {
                return {
                  ...prevItems,
                  [item.headerLabel]: {
                    ...prevItems[item.headerLabel],
                    ...(item.headerLabel === COUNTRY_TYPE_FILTER
                      ? { [check.value]: e.target.checked }
                      : item.headerLabel === PAGES_TYPE_FILTER
                      ? { [check.pageId]: e.target.checked }
                      : { [check.id]: e.target.checked }),
                  },
                };
              });
              const checkedItem = {
                ...check,
                panelCategory: item.headerLabel,
              };
              e.target.checked
                ? setSelectedFiltersArray((state) => [...state, checkedItem])
                : setSelectedFiltersArray((state) =>
                    state.filter((it) => it.id !== checkedItem.id)
                  );
            }}
            checked={
              selectedFilters?.[item.headerLabel]
                ? selectedFilters[item.headerLabel][check?.value] ||
                  selectedFilters[item.headerLabel][check?.id] ||
                  selectedFilters[item.headerLabel][check?.pageId]
                : false
            }
          >
            {check.name}
          </Checkbox>
        );
      })}
      <div className={item.headerLabel} ref={scrollRef} style={{ heigt: "20px" }} />
    </div>
  );
};

export default CategoryScroll;
