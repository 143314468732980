import { Form, Button, Alert, Row, Col, Switch } from "antd";
import { Formik } from "formik";
import Loader from "components/loader";
import { InputNumber, Radio, Space } from "antd/lib";
import { useState } from "react";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    btnText = "Add",
    loading = false,
    budgetValue,
  } = props;
  const [value, setValue] = useState(1);
  const [input, setInput] = useState({
    total: 0,
    budget: 0,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Radio.Group
              onChange={(e) => {
                setValue(e.target.value);
                setInput({
                  ...input,
                  total:
                    e.target.value === 1
                      ? input.budget > 0
                        ? input.budget
                        : 0
                      : (budgetValue + budgetValue * (input.budget / 100)).toFixed(2),
                });
              }}
              value={value}
            >
              <Space direction="horizontal">
                <Radio value={1}> By Number </Radio>
                <Radio value={2}> By Percentage</Radio>
              </Space>
            </Radio.Group>
          </Row>

          <InputNumber
            name="budget"
            min={value === 1 ? 0 : -999}
            label="Budget"
            controls={false}
            style={{ width: "200px" }}
            className="look-form-input mt-15"
            placeholder="Enter budget"
            onChange={(e) => {
              setInput({
                ...input,
                total:
                  value === 2
                    ? (budgetValue + budgetValue * (parseInt(e) / 100)).toFixed(2)
                    : parseInt(e),
                budget: parseInt(e),
              });
            }}
          />

          <div>Previous Budget: ${budgetValue}</div>
          <>
            <div className="mb-50" style={{ opacity: input?.budget?.toString() ? 1 : 0 }}>
              <p>
                Updated Budget:{" "}
                {!isNaN(input?.budget) ? <>${input.total ? input.total : 0}</> : "-"}
              </p>
            </div>
          </>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              type="primary"
              className="submit-btn "
              onClick={handleSubmit}
              disabled={isNaN(input?.budget)}
            >
              {loading ? <Loader /> : btnText}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
}
