import { Form, Radio, Switch } from "antd";
import SelectInput from "components/forms/SelectInput";
import { googleLaunchMatchTypeData } from "../../googleLaunchConfig";
import { useSelector } from "react-redux";
import { useGoogleLaunchV1Context } from "../../Context";
import LookTextInput from "components/forms/LookTextInput";

export default function TargettingsTab() {
  //currently saving the keywords, negativeKeywords and match type data in a state as an array, we have to parse it according to JSON later

  const { googleLaunchCountries = [], googleLaunchLanguages = [] } = useSelector(
    (state) => state?.userSettings?.userSettingData
  );
  const {
    targetingObject,
    handleTargetingLevelData,
    matchType,
    setMatchType,
    keywords,
    setKeywords,
    trackingUrl,
    setTrackingUrl,
  } = useGoogleLaunchV1Context();
  const {
    negativeKeywords = [],
    languageConstant = [],
    locationInfoGeoTargetConstant = [],
  } = targetingObject;

  const renderTrackingUrl = () => (
    <div className="w-50">
      <h3>Tracking URL</h3>
      <LookTextInput
        required
        requiredLabel="Tracking Url"
        label="Tracking Url"
        name="trackingUrl"
        value={trackingUrl}
        onChange={(e) => setTrackingUrl(e?.target?.value)}
      />
    </div>
  );
  const renderLocation = () => (
    <div className="w-30">
      <h3>Location</h3>
      <SelectInput
        multiple
        label="Select locations to target"
        name="location"
        search
        value={locationInfoGeoTargetConstant}
        placeholder="All countries and territories (default)"
        options={googleLaunchCountries?.map(({ name, criteriaId }) => ({
          name,
          value: criteriaId?.toString(),
        }))}
        onChange={(e) => handleTargetingLevelData("locationInfoGeoTargetConstant", e)}
      />
    </div>
  );

  const renderLanguage = () => (
    <div className="w-50">
      <h3>Languages</h3>
      <div className="d-flex">
        <p className="mb-2 mr-5">Multiply by location</p>
        <Switch />
      </div>
      <SelectInput
        multiple
        label="Select the languages your customers speak"
        name="language"
        search
        value={languageConstant}
        options={googleLaunchLanguages?.map(({ languageName, criteriaId }) => ({
          name: languageName,
          value: criteriaId?.toString(),
        }))}
        onChange={(e) => handleTargetingLevelData("languageConstant", e)}
      />
    </div>
  );

  const renderKeywords = () => (
    <div className="w-50">
      <div className="mb-2">
        <h3 className="mb-2">Keywords</h3>
        <p>Match type</p>
        <Radio.Group value={matchType} onChange={(e) => setMatchType(e.target.value)}>
          {googleLaunchMatchTypeData.map(({ name, value }) => (
            <Radio value={value}>{name}</Radio>
          ))}
        </Radio.Group>
      </div>
      <SelectInput
        mode="tags"
        label="Keywords"
        name="keywords"
        placeholder="Enter keywords"
        value={keywords}
        onChange={(e) => setKeywords(e)}
      />
      <SelectInput
        mode="tags"
        label="Negative keywords"
        name="negativeKeywords"
        value={negativeKeywords}
        placeholder="Enter negative keywords"
        onChange={(e) => handleTargetingLevelData("negativeKeywords", e)}
      />
    </div>
  );
  // location, language,keywords code here
  return (
    <div className="content">
      <Form layout="vertical">
        {renderLocation()}
        {renderLanguage()}
        {renderTrackingUrl()}
        {renderKeywords()}
      </Form>
    </div>
  );
}
