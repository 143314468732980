import { Form, Button, Alert, Row, Col } from "antd";
import { Formik } from "formik";
import Loader from "components/loader";
import SelectInput from "components/forms/SelectInput";
import LookTextInput from "components/forms/LookTextInput";

export default function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    isError,
    btnText = "Add",
    loading = false,
    categories = [],
    countries = [],
    countryListLoading = false,
    languages = [],
  } = props;

  if (loading) {
    return <Loader className="text-center" />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {/* <pre> {JSON.stringify({ initialValues, values }, null, 2)} </pre> */}
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="name"
                label="Name"
                className="look-form-input"
                placeholder="Enter Name"
              />
            </Col>
            <Col span={24}>
              <LookTextInput
                formik
                name="url"
                label="URL"
                className="look-form-input"
                placeholder="Enter url"
              />
            </Col>
            <Col span={24}>
              <Form.Item label="Category" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Category"
                  name="categoryId"
                  value={values?.categoryId?.value}
                  options={categories?.map(({ value, name }) => ({ name, value }))}
                  onChange={(name, value) => {
                    const categoryName = categories?.find((item) => item?.value === value);
                    setFieldValue(name, { name: categoryName?.name, categoryId: value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <LookTextInput
                formik
                name="advertiser"
                label="Advertiser (optional)"
                className="look-form-input"
                placeholder="Enter Advertiser"
              />
            </Col>
            <Col span={24}>
              <LookTextInput
                formik
                name="business"
                label="Business (optional)"
                className="look-form-input"
                placeholder="Enter Business"
              />
            </Col>
            <Col span={12}>
              <Form.Item label="Country (optional)" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  multiple
                  placeholder="Select Country"
                  name="country"
                  value={values?.country}
                  options={countries?.map(({ name, iso_code }) => ({
                    name: name,
                    value: iso_code,
                  }))}
                  onChange={setFieldValue}
                  disabled={countryListLoading}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Language" className="look-form-input">
                <SelectInput
                  formik
                  search
                  multiple
                  placeholder="Select language"
                  name="language_code"
                  value={values?.language_code}
                  options={languages?.map(({ name, code }) => ({ name, value: code }))}
                  onChange={setFieldValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
