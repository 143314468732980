import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "../MainForm";
import { openNotification } from "utils";
import moment from "moment";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { useSelector } from "react-redux";
import cx from "cx";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "../../leadgenKeysAndEnums";
import { VOLUUM_ADD_X_REVENUE } from "shared/gql/gqlSchema/fb360Gql";
import { dateFormat } from "reducers/userSettings";

export default function AddXRevenue(props) {
  const user_name = useSelector((state) => state?.userReducer?.me?.name);
  const { refetch = () => {}, close = () => {} } = props;

  const [selectedDate, setSelectedDate] = useState(moment().subtract(1, "days").format(dateFormat));

  const resetFormRef = useRef(() => {});
  const [voluum_add_XRevenue, { loading }] = useCustomMutation(VOLUUM_ADD_X_REVENUE, {
    onCompleted(e) {
      refetch();
      close();
      resetFormRef.current();
      openNotification({ type: "success", message: "Record Added Successfully!" });
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const { revenue, notes } = values;
    voluum_add_XRevenue({
      variables: {
        ...values,
        date: selectedDate,
        revenue: parseFloat(revenue),
        notes: notes ? cx(notes, "-", user_name) : user_name,
        platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
      },
    });
  }

  const initialValues = { xRevenue: "", notes: "", category_id: undefined };

  const validationSchema = yup.object({
    revenue: yup.string().required().label("X Revenue"),
    category_id: yup.string().required().label("Category"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    loading,
    selectedDate,
    setSelectedDate,
  };

  return (
    <>
      <MainFrom {...formProps} />
    </>
  );
}
