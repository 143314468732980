import DebugPre from "components/DebugPre";
import {
  ADS_TYPE_ADACCOUNT,
  ADS_TYPE_CATEGORY,
  ADS_TYPE_CONTENT,
  ADS_TYPE_DEFAULT,
  ADS_TYPE_PLATFORM,
} from "pages/dashboardPages/Creatives/creativesEnum";
import React from "react";
import { useCreativeReportingContext } from "../../Context";
import AdAccountReports from "../AdAccountReports";
import AdReports from "../AdReports";
import CategoryReports from "../CategoryReports";
import PlatformReports from "../PlatformReports";
import useCreativeColumns from "./useCreativeColumns";

function CreativeReportTableView(props) {
  const {
    renderContent = () => {},
    renderSkeleton = () => {},
    loading = false,
    adsType = ADS_TYPE_DEFAULT,
    finalMerge,
    isFbReport,
    isVS,
    isLeadgen,
    spendPlatformDetailedReport,
    revPlatformDetailedReport,
    mergedDetailedReport,
  } = useCreativeReportingContext();
  const {
    adColumns = [],
    categoryColumns = [],
    platformColumns = [],
    adAccountColumns = [],
  } = useCreativeColumns({ isFbReport, isLeadgen, isVS });

  return (
    <>
      {loading ? renderSkeleton() : renderContent()}
      {adsType === ADS_TYPE_DEFAULT && <AdReports adColumns={adColumns} />}
      {(adsType === ADS_TYPE_CATEGORY || adsType === ADS_TYPE_CONTENT) && (
        <CategoryReports categoryColumns={categoryColumns} />
      )}
      {adsType === ADS_TYPE_PLATFORM && <PlatformReports platformColumns={platformColumns} />}
      {adsType === ADS_TYPE_ADACCOUNT && <AdAccountReports adAccountColumns={adAccountColumns} />}

      <DebugPre
        content={[
          {
            isRow: true,
            contentObjects: [{ finalMerge }, { mergedDetailedReport }],
          },
          {
            isRow: true,
            contentObjects: [{ spendPlatformDetailedReport }, { revPlatformDetailedReport }],
          },
        ]}
      />
    </>
  );
}

export default CreativeReportTableView;
