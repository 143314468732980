import useUploadFiles from "components/Launch/Creatives/CreativeUpload/useUploadFiles";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import {
  MEDIA_ADD_AUDIO_CLIP,
  MEDIA_DELETE_AUDIO_CLIPS,
  MEDIA_GET_AUDIO_CLIPS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import { CREATIVE_MIME_TYPE_IMAGE, CREATIVE_TYPE_AUDIO } from "shared/SharedKeys/launchKeys";
import { Col, Row, Skeleton } from "antd";
import MediaItem from "../../Category/CreativeBuilder/MediaItem";
import cx from "cx";
import ActionIconRender from "components/ActionIconRender";
import { GET_BLOB_FOR_CREATIVES } from "shared/gql/gqlSchema/umsGql";
import TagModal from "components/Launch/Creatives/CreativeUpload/TagModal";
import { useLookModal } from "components/LookModal";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import DeleteModal from "../DeleteModal";

export default function AudioUpload(props) {
  const {
    isRequestView,
    isGraphicDesigner = false,
    isMediaLibraryMain = false,
    setRowProps = () => {},
    setAudio = () => {},
    audioIndex,
    audio,
    isMediaLibrary,
    isCreativeBuilder = false,
    creativesImages,
    setCreativesImages = () => {},
    setTotalCount = () => {},
    currentPage,
    pageSize,
    createdByMe = false,
    isMediaLibrary_cb = false,
    isSearch,
    searchItem,
    searchMode = "",
    setIsSearch = () => {},
    setSearchItem = () => {},
    requestData = {},
    modalUploadData = [],
    setModalUploadData = () => {},
    refetchTrigger = "",
    dateRange = [],
    refetchFlag,
  } = props;

  const { categoryId = "", productId = "", categoryName = "" } = props?.requestData || {};
  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 8 }, () => 10));
  const [fileName, setFileName] = useState("");
  const { creativeType = "", audioName = "", audioDuration = "" } = props;
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [selectedAudioData, setSelectedAudioData] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [tags, setTags] = useState([]);
  const [mimeType, setMimeType] = useState();
  const [selectedCategory, setSelectedCategory] = useState({});

  function selectedRowsClear() {
    setSelectedAudioData([]);
  }

  const [ctAddMedia, { loading: loading_Add, refetch: creativesTemplate_refetch }] =
    useCustomMutation(MEDIA_ADD_AUDIO_CLIP, {
      onCompleted: (e) => {
        const element = e?.ctAddAudioClip;
        if (element?.status === "SUCCESS") {
          openNotification({
            type: "success",
            message: element?.message || "Record added successfully",
            key: "success",
          });
          refetch_GET_MEDIA_DATA();
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });

  const getImageResponse = (imagesData) => {
    const submitObj = {
      ...(categoryId && { categoryId }),
      ...(categoryName & { categoryName }),
      r2Urls: imagesData.map((data, index) => ({
        audioUrl: data?.public_url,
        name: fileName[index],
      })),
      ...(productId && { productId }),
    };

    ctAddMedia({ variables: { ...submitObj } });
  };
  const [mediaDelete, { loading: deleteLoader }] = useCustomMutation(MEDIA_DELETE_AUDIO_CLIPS, {
    onCompleted(e) {
      refetch_GET_MEDIA_DATA();
      setDeleteModalVisible(false);
      if (e?.ctDeleteAudioClip?.status === "SUCCESS") {
        openNotification({ type: "success", message: "Record Deleted Success" });
      } else {
        openNotification({
          type: "error",
          message: "Record Deleted Not Success",
          description: e.message,
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Record Deleted Not Success",
        description: e.message,
      });
    },
  });
  const { uploadView, uploadLoading } = useUploadFiles({
    getImageResponse,
    isCreativeTracking: true,
    creativeType: CREATIVE_TYPE_AUDIO,
    loading: loading_Add,
    setFileName,
    setCreativesImages,
  });
  const handleSelectImage = (id, index) => {
    if (audio?.[audioIndex]?.map((i) => i?.id === id)?.includes(true)) {
      setAudio((prevMedia) => {
        const updatedMedia = [...prevMedia];
        const mediaIndexItems = updatedMedia[audioIndex] || [];
        updatedMedia[audioIndex] = mediaIndexItems.filter((img) => img?.id !== id);
        return updatedMedia;
      });
      setRowProps((rowProps) => {
        const updatedMedia = [...rowProps?.audio];
        const mediaIndexItems = updatedMedia[audioIndex] || [];
        updatedMedia[audioIndex] = mediaIndexItems?.filter((img) => img?.id !== id);
        return { ...rowProps, audio: updatedMedia };
      });
    } else {
      setAudio((prevMedia) => {
        const updatedMedia = [...prevMedia];
        const mediaIndexItems = updatedMedia[audioIndex] || [];
        updatedMedia[audioIndex] = [
          ...mediaIndexItems,
          {
            id: creativesImages?.filter((img) => img?.id === id)[0]?.id,
            urlData: creativesImages?.filter((img) => img?.id === id)[0]?.r2Url,
            type: creativeType?.toLowerCase(),
            name: audioName,
            duration: audioDuration,
          },
        ];
        return updatedMedia;
      });
      setRowProps((rowProps) => {
        const updatedMedia = [...rowProps?.audio];
        const mediaIndexItems = updatedMedia[audioIndex] || [];
        updatedMedia[audioIndex] = [
          ...mediaIndexItems,
          {
            id: creativesImages?.filter((img) => img?.id === id)[0]?.id,
            s3Url: creativesImages?.filter((img) => img?.id === id)[0]?.r2Url,
            type: creativeType?.toLowerCase(),
          },
        ];
        return { ...rowProps, audio: updatedMedia };
      });
    }
  };
  const handleSelectMedia = (id) => {
    if (selectedAudioData?.map((i) => i?.id === id)?.includes(true)) {
      setSelectedAudioData(selectedAudioData?.filter((audio) => audio?.id !== id));
    } else {
      setSelectedAudioData([
        ...selectedAudioData,
        {
          id: creativesImages.filter((audio) => audio?.id === id)[0].id,
          s3Url: creativesImages.filter((audio) => audio?.id === id)[0]?.s3Url,
        },
      ]);
    }
  };

  const [
    fetchAudio,
    {
      loading: loading_GET_MEDIA_DATA,
      error: error_GET_MEDIA_DATA,
      refetch: refetch_GET_MEDIA_DATA,
    },
  ] = useCustomLazyQuery(MEDIA_GET_AUDIO_CLIPS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ctGetAudioClips;
      if (element?.status === "SUCCESS") {
        setCreativesImages(element?.data);
        setTotalCount(element?.pageInfo?.totalRecords);
        setIsSearch(false);
      } else if (element?.status === "FAILURE") {
        openNotification({ type: "error", message: element?.message, key: "error" });
        setIsSearch(false);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
      setIsSearch(false);
    },
    variables: {
      ...(categoryId && { categoryId }),
      ...(categoryName & { categoryName }),
      ...(productId && { productId }),
      page: currentPage,
      size: pageSize,
      createdByMe,
      ...(isMediaLibraryMain && { isGlobal: true }),
      toDate: dateRange[1],
      fromDate: dateRange[0],
    },
  });
  const [getBlob, { loading: loading_blob }] = useCustomMutation(GET_BLOB_FOR_CREATIVES, {
    onCompleted: (e) => {
      const element = e?.getBlob;
      if (element) {
        for (let i = 0; i < element.length; i++) {
          const blob = element[i]?.blob;
          const imageUrl = base64ToImageUrl(blob, mimeType);
          downloadFile(imageUrl, `lookfinity_creatives.mp3`);
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });
  function base64ToImageUrl(base64String) {
    return `data:${mimeType};base64,${base64String}`;
  }

  async function handleClickDownload(urls, mimeType) {
    let linkData = [];
    for (const fileUrl of urls) {
      try {
        const response = await fetch(fileUrl);
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          downloadFile(url, `lookfinity_creatives.mp3`);
        }
      } catch (error) {
        linkData.push(fileUrl);
      }
    }
    if (linkData?.length > 0) {
      setMimeType(mimeType);
      getBlob({ variables: { urls: linkData } });
    }
  }
  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to the HTML document
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
  const tagDataExtractor = (id) => {
    const tagsArray = creativesImages?.filter((data) => data?.id === id)?.[0]?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };

  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const onSubmitDeleteModal = () => {
    if (isCreativeBuilder || isMediaLibrary) {
      mediaDelete({
        variables: {
          _ids: deleteModalPropsData,
          categoryId,
          productId,
          forceDelete: false,
        },
      });
    } else if (isMediaLibraryMain) {
      mediaDelete({
        variables: {
          _ids: selectedCategory?.id,
          categoryId: selectedCategory?.productCategoryId,
          productId: selectedCategory?.productId,
          forceDelete: false,
        },
      });
    }
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setSelectedCategory({});
    setDeleteModalVisible(false);
  };

  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
    categoriesList: deleteModalPropsData,
    setSelectedCategory: setSelectedCategory,
    customView: isMediaLibraryMain ? true : false,
    selectedCategory: selectedCategory,
  };
  useEffect(() => {
    if (isSearch) {
      fetchAudio({
        variables: {
          ...(searchMode !== "tags" ? { search: searchItem } : { tags: searchItem }),
        },
      });
    }
  }, [searchMode, isSearch, searchItem]);
  useEffect(() => {
    fetchAudio();
  }, [createdByMe, refetchTrigger, refetchFlag]);

  const bulkActions = [
    // {
    //   option: "delete",
    //   title: "Delete",
    //   onClick: (e) => {
    //     onOpenDeleteModal(e?.map((audio) => audio?.id));
    //   },
    // },
    // {
    //   option: "download",
    //   title: "Download",
    //   onClick: (e) => {
    //     handleClickDownload(
    //       e?.map((data) => data?.s3Url),
    //       CREATIVE_MIME_TYPE_IMAGE
    //     );
    //   },
    // },
    {
      option: "tagSwitch",
      title: "Edit tags",
      onClick: (e) => {
        openTagModal();
        setDeleteModalPropsData(e?.map((data) => ({ id: data?.id, tags: data?.tags || [] })));
      },
    },
  ];

  const bulkActionProps = {
    selectedRows: selectedAudioData,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };

  return (
    <div className="content-view">
      {visilbleTagModal &&
        tagModal(
          <TagModal
            variables={{
              productId,
              categoryId,
              categoryName,
            }}
            files={deleteModalPropsData}
            creatives_refetch={refetch_GET_MEDIA_DATA}
            setTags={setTags}
            tags={tags}
            tagsData={tagsData}
            setTagsData={setTagsData}
            closeTagModal={closeTagModal}
            selectedRowsClear={selectedRowsClear}
            creativeType="AUDIO_CLIP"
          />
        )}

      <LookTableBulkAction {...bulkActionProps} label="Selected Audio" />

      <Row gutter={[15, 15]}>
        {/* {!isMediaLibraryMain && (
          <Col span={6}>{(isGraphicDesigner || !isRequestView) && uploadView()}</Col>
        )} */}
        {(creativesImages?.length === 0 || creativesImages === undefined) &&
        (!isGraphicDesigner || !isRequestView) &&
        !isMediaLibrary &&
        !loading_Add &&
        !loading_GET_MEDIA_DATA &&
        !isCreativeBuilder ? (
          <div className="text-center margin-0-auto">
            <div style={{ transform: "translateX(20px)" }}>
              <ActionIconRender iconType="tablePlaceholder" />
            </div>
            <p className="no-text">
              <b>No Media Found...</b>
            </p>
          </div>
        ) : (
          creativesImages?.map((data, index) => {
            const selectedImg =
              isMediaLibrary || isMediaLibraryMain
                ? selectedAudioData?.filter((audio) => audio?.id === data?.id)
                : audio?.[audioIndex]?.filter((audio) => audio.id === data?.id);
            return (
              <Col span={12} key={"mediaItems" + data?.id}>
                <MediaItem
                  {...data}
                  tagDataExtractor={tagDataExtractor}
                  isMediaLibrary_cb={isMediaLibrary_cb}
                  tags={tags}
                  creativeType={CREATIVE_TYPE_AUDIO}
                  setTags={setTags}
                  openTagModal={openTagModal}
                  setDeleteModalPropsData={setDeleteModalPropsData}
                  creative={{
                    id: data?.id,
                    thumbUrl: data?.thumbUrl,
                    tags: data?.tags,
                    url: data?.r2Url,
                    categoryId: data?.categoryId,
                  }}
                  handleClickDownload={handleClickDownload}
                  onOpenDeleteModal={onOpenDeleteModal}
                  // onClick={() => {
                  //   isMediaLibrary ? handleSelectMedia(data?.id) : handleSelectImage(data?.id);
                  // }}
                  // onSelect={() => {
                  //   isMediaLibrary ? handleSelectMedia(data?.id) : handleSelectImage(data?.id);
                  // }}
                  selected={selectedImg?.length > 0}
                  isCreativeBuilder={isCreativeBuilder}
                  selectedAudioData={data}
                  selectedData={audio?.[audioIndex]}
                  isMediaLibraryMain={isMediaLibraryMain}
                  handleSelectImage={handleSelectImage}
                  isMediaLibrary={isMediaLibrary}
                  selectedDataId={data?.id}
                  handleSelectMedia={handleSelectMedia}
                  modalUploadData={modalUploadData}
                  setModalUploadData={setModalUploadData}
                  createdAt={data?.createdAt}
                />
              </Col>
            );
          })
        )}
        {(loading_Add || loading_GET_MEDIA_DATA || uploadLoading) && (
          <>
            {skeletonSize?.map((e) => (
              <Col span={12}>
                <div className="d-flex justify-content-around ">
                  <Skeleton.Avatar active={true} />
                  <Skeleton.Input active={true} />
                </div>
              </Col>
            ))}
          </>
        )}
      </Row>
      <DeleteModal {...deleteModalProps} />
    </div>
  );
}
