import { Form, Button, Alert } from "antd";
import { Formik } from "formik";
import Loader from "../../components/loader";

export default function MainForm(props) {
  const { initialValues, validationSchema, onSubmit, isError, loading = false } = props;

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form layout="vertical">
            {isError && <Alert message={isError} type="error" className="mb-10" />}

            <h3>Accept Invite</h3>
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                className="submit-btn"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <Loader /> : "Accept"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </>
  );
}
