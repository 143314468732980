import { Avatar } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import LookTextInput from "components/forms/LookTextInput";
import Loader from "components/loader";
import { useCustomMutation } from "hooks/apolloClientHooks";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { requestAddedItemsData, requestItemsData } from "shared/enum/crsKeysAndEnum";
import { ADD_CREATIVE_COMMENT } from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import { COMMENT_CHANGE_TYPE_COMMENT } from "../creativesEnum";
import { useSelector } from "react-redux";

async function formatDateTime(date) {
  const dateTime = moment(date);

  // Format time with AM/PM
  const formattedTime = dateTime.format("h:mm A");

  // Determine if the date is yesterday
  const isDateYesterday = dateTime.isSame(moment().subtract(1, "day"), "day");

  // Format date as "yesterday" or a simple date string
  const formattedDate = isDateYesterday ? "Yesterday" : dateTime.format("MM/DD/YYYY");
  return { formattedDate, formattedTime };
}

function CreativeCommentItem(props) {
  const {
    colorItems,
    details: comment,
    changeType,
    date,
    user,
    creativeRequestId,
    user_name,
  } = props;
  const assigned_to = creativeRequestId?.assignTo?.name;
  const user_id = useSelector((state) => state?.userReducer?.me?.user_id);
  const nameArray = user?.name?.split(" ");

  // const commentArray = comment?.split(" ");
  // const itemDataIndex = colorItems?.findIndex((enumData) => enumData?.key === action_type);
  // const { name = "", color = "" } = itemDataIndex !== -1 ? colorItems[itemDataIndex] : {};
  const userInitials = nameArray?.map((data) => data.charAt(0));
  const [formattedDateTime, setFormattedDateTime] = useState({
    formattedDate: "",
    formattedTime: "",
  });
  const getDateTime = async () => {
    const dateTime = await formatDateTime(date);
    setFormattedDateTime(dateTime);
  };

  useEffect(() => {
    getDateTime();
  }, [date]);

  return (
    <>
      {changeType === COMMENT_CHANGE_TYPE_COMMENT
        ? comment.map((data) => {
            return (
              <div className="comment-item">
                <Avatar style={{ verticalAlign: "middle" }} size="large">
                  {userInitials}
                </Avatar>
                <div className="comment-box">
                  <p className="name">{user?.name}</p>
                  <div className="comment">
                    <p className="mb-0">{data}</p>
                  </div>
                  <p className="date">{`${formattedDateTime?.formattedTime}, ${formattedDateTime?.formattedDate} `}</p>
                </div>
              </div>
            );
          })
        : comment.map((data) => {
            return (
              <div className="comment-item">
                <div className="comment-box">
                  <div className="crs-user-simple-text">
                    <span className="crs-user-name">{user?.name}</span>
                    <span className="crs-status">{changeType}</span>{" "}
                    {/*give color in style={{color:color}} in upper span */}
                    {data}
                    {/* {assigned_to} */}
                  </div>
                  <p className="date">{`${formattedDateTime?.formattedTime}, ${formattedDateTime?.formattedDate} `}</p>
                </div>
              </div>
            );
          })}
    </>
  );
}
export default function CreativeCommentForm(props) {
  const {
    // selectedRequestData = {},
    allComments,
    setAllComments,
    loading = false,
    refetch = () => {},
  } = props;
  const { categoryId = "", productId = "", id = "" } = props?.requestData;
  // const { id } = selectedRequestData;
  const [comment, setComment] = useState("");
  const chatContainerRef = useRef(null);
  const colorItems = [...requestItemsData, ...requestAddedItemsData];
  const users = useSelector((state) => state?.userReducer?.me);

  useEffect(() => {
    // Scroll to the bottom of the chat container
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [allComments]);

  const [addNewComment, { loading: newCommentLoading }] = useCustomMutation(ADD_CREATIVE_COMMENT, {
    onCompleted: (e) => {
      const element = e?.ctAddRequestHistory?.data;
      if (element) {
        setAllComments((prev) => [...prev, ...element]);
        setComment("");
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const handleComment = () => {
    addNewComment({
      variables: {
        requestId: id,
        comments: comment,
        productId,
        categoryId,
        userName: users?.name,
      },
    });
  };
  const finalComments = allComments.filter(({ details }) => details.length > 0);

  return (
    <div className="CRSCommentsForm">
      <LookButton
        tooltipTitle="Refetch Chat"
        tooltipPlacement="left"
        className="reload-chat"
        iconType="reload"
        iconTypeProps={{ spin: loading }}
        onClick={() => refetch()}
      />
      <div ref={chatContainerRef} className="comment-view position-relative">
        {loading ? (
          <Loader title="Fetching comments..." elementCenter center />
        ) : finalComments?.length === 0 ? (
          <div className="text-center pt-100">
            <div style={{ transform: "translateX(20px)" }}>
              <ActionIconRender iconType="tablePlaceholder" />
            </div>
            <p style={{ fontSize: "1.5rem" }}>
              <b>No comment Found...</b>
            </p>
          </div>
        ) : (
          finalComments?.map((data) => <CreativeCommentItem colorItems={colorItems} {...data} />)
        )}
      </div>
      <div className="input-view">
        <div className="comment-input-wrapper">
          <div className="input-holder">
            <LookTextInput
              placeholder="Add Comments"
              type="textarea"
              value={comment}
              Loader={newCommentLoading}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <LookButton type="primary" iconType="send-45degree" onClick={handleComment} />
        </div>
      </div>
    </div>
  );
}
