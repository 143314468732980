import { Row, Col } from "antd";
import { CardLGCompliance } from "components/LookCards";
import { useLGComplianceContext } from "../../Context";

export default function LGComplianceList() {
  const {
    mainData = [],
    updateCompliantStatus,
    setUpdateObject,
    setUpdateIndex,
  } = useLGComplianceContext();

  function renderCard() {
    return (
      <>
        <Row gutter={12}>
          {mainData?.map((data, index) => (
            <Col key={"VSContentsListCard" + index} xs={24} md={12} lg={8} xxl={6}>
              <CardLGCompliance
                renderIndex={index}
                setUpdateObject={setUpdateObject}
                setUpdateIndex={setUpdateIndex}
                updateCompliantStatus={updateCompliantStatus}
                className="mb-12"
                {...data}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  }

  return <div className="px-16">{renderCard()}</div>;
}
