//Launch Type Keys
export const LAUNCH_TYPE_LEADGEN = "LAUNCH_TYPE_LEADGEN";
export const LAUNCH_TYPE_SAS = "LAUNCH_TYPE_SAS";
export const LAUNCH_TYPE_VS = "LAUNCH_TYPE_VS";

//Launch Parent state key
export const LAUNCH_PARENT_STATE_DETAILS = "details";
export const LAUNCH_PARENT_STATE_TARGETING = "targeting";

//Launch Details State Keys
export const LAUNCH_DETAILS_STATE_NAME = "name";

//Launch Targetng State Keys
export const LAUNCH_TARGETING_STATE_GENDER = "gender";
export const LAUNCH_TARGETING_STATE_AGE = "age";
export const LAUNCH_TARGETING_STATE_CTA = "cta";
export const LAUNCH_TARGETING_STATE_OPTIMIZATION = "optimization";
export const LAUNCH_TARGETING_STATE_PLATFORM = "platform";
export const LAUNCH_TARGETING_STATE_COUNTRY = "country";
export const LAUNCH_TARGETING_STATE_LANGUAGE = "language";
export const LAUNCH_TARGETING_STATE_PLACEMENT_FEEDS = "feeds";
export const LAUNCH_TARGETING_STATE_PLACEMENT_STORIES_AND_REELS = "storiesAndReals";
export const LAUNCH_TARGETING_STATE_PLACEMENT_ADD_AND_SITES = "appsAndSites";
