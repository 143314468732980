import ActionIconRender from "components/ActionIconRender";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { GET_LAUNCHED_ADSETS, GET_TIKTOK_LAUNCHED_ADSETS } from "shared/gql/gqlSchema/sasGql";
import Ads from "../Ads";

function Adset(props) {
  const [adSets, setAdsets] = useState();
  const [visibleAdsDrawer, setVisibleAdsDrawer] = useState();
  const [rowPropsData, setRowProps] = useState();
  const { isTiktokPlatformLaunch, launchId } = props;
  const showAdsDrawer = (rowProps) => {
    setRowProps(rowProps);
    setVisibleAdsDrawer(true);
  };
  const onCloseAdsetDrawer = () => {
    setRowProps();
    setVisibleAdsDrawer(false);
  };

  const [
    fetchFbAdset,
    {
      data: data_GET_FB_ADSET_DATA,
      loading: loading_GET_FB_ADSET_DATA,
      error: error_GET_FB_ADSET_DATA,
      refetch: refetch_GET_FB_ADSET_DATA,
    },
  ] = useCustomLazyQuery(GET_LAUNCHED_ADSETS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.launchedAdsets;
      if (element) {
        setAdsets(element);
      }
    },
  });
  const [
    fetchTiktokAdset,
    {
      data: data_GET_TIKTOK_ADSET_DATA,
      loading: loading_GET_TIKTOK_ADSET_DATA,
      error: error_GET_TIKTOK_ADSET_DATA,
      refetch: refetch_GET_TIKTOK_ADSET_DATA,
    },
  ] = useCustomLazyQuery(GET_TIKTOK_LAUNCHED_ADSETS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktokLaunchedAdSet;
      if (element) {
        setAdsets(element);
      }
    },
  });
  useEffect(() => {
    if (isTiktokPlatformLaunch) {
      fetchTiktokAdset({
        variables: {
          launchId,
          campaignId: props?.id,
        },
      });
    } else {
      fetchFbAdset({
        variables: {
          campaignId: props?.id,
        },
      });
    }
  }, [isTiktokPlatformLaunch]);
  const initialColumns = [
    {
      title: "Account ID",
      dataIndex: "accountId",
      key: "accountId",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showAdsDrawer(otherProps)} />
      ),
      width: 90,
      minWidth: 90,
      sorter: true,
      fixed: "left",
    },

    {
      title: "Adset ID",
      dataIndex: "AdsetId",
      key: "AdsetId",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={isTiktokPlatformLaunch ? otherProps?.adsetId : otherProps?.fbAdsetId}
          isClick={() => showAdsDrawer(otherProps)}
        />
      ),
      width: 90,
      minWidth: 90,
    },

    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.countryName - b?.countryName,
    },
  ];
  const tableMainProps = {
    refetch: isTiktokPlatformLaunch ? refetch_GET_TIKTOK_ADSET_DATA : refetch_GET_FB_ADSET_DATA,
    loading: loading_GET_FB_ADSET_DATA || loading_GET_TIKTOK_ADSET_DATA,
    tableProps: { initialColumns, recordData: adSets },
    isRowSelection: false,
  };
  const drawerAdsProps = {
    title: "Ads",
    icon: () => <ActionIconRender className="mr-10" iconType="notification" />,
    size: 900,
    visible: visibleAdsDrawer,
    onClose: onCloseAdsetDrawer,
  };
  return (
    <div>
      <LookDrawer {...drawerAdsProps}>
        {visibleAdsDrawer && (
          <LookDrawerMainSideBar
            tabContent={[
              {
                hoverTitle: "Ads",
                icon: () => <ActionIconRender iconType="pic-left" />,
                component: (
                  <Ads
                    onClose={onCloseAdsetDrawer}
                    {...rowPropsData}
                    launchId={props?.launchId}
                    isTiktokPlatformLaunch={isTiktokPlatformLaunch}
                  />
                ),
              },
            ]}
          />
        )}
      </LookDrawer>
      <LookTable {...tableMainProps} isSrNo />
    </div>
  );
}

export default Adset;
