import { Fragment } from "react";
import { Button, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";

export default function LookButton(props) {
  const {
    children,
    tooltipTitle,
    tooltipPlacement,
    tooltipDefaultOpen = false,
    className = "",
    prefixCls = "",
    size,
    icon,
    iconType = "",
    iconTypeProps = {},
    type,
    onClick = () => {},
    ghost = false,
    danger = false,
    block = false,
    loading = false,
    ...rest
  } = props;

  const buttonProps = {
    className: cx(className),
    prefixCls,
    size,
    type,
    ghost,
    danger,
    block,
    onClick,
    loading,
    icon: iconType ? <ActionIconRender iconType={iconType} {...iconTypeProps} /> : icon,
    ...rest,
  };

  const renderButton = () =>
    children ? <Button {...buttonProps}>{children}</Button> : <Button {...buttonProps} />;

  const tooltipProps = tooltipTitle
    ? { title: tooltipTitle, placement: tooltipPlacement, defaultOpen: tooltipDefaultOpen }
    : {};
  const TooltipWrapper = tooltipTitle ? Tooltip : Fragment;

  return <TooltipWrapper {...tooltipProps}>{renderButton(renderButton)}</TooltipWrapper>;
}
