import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { usePermissionCheck } from "hooks";
import { GeneratenviteCode } from "../../pages";
import {
  USERS_MANAGES_AGENCIES,
  USERS_MANAGES_PRODUCTS,
  USERS_MANAGES_ROLES,
  USERS_MANAGES_USERS,
  USERS_MANAGES_WORKSPACES,
  USER_WITH_NO_ACTION,
} from "../../shared/UserPermission/userPermissionList";

import route, { ROUTE_DASHBOARD } from "../route";

const Profile = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-Profile" */ "pages/dashboardPages/configuration/Profile"
    )
  )
);
const Agency = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-Agency" */ "pages/dashboardPages/configuration/Agency"
    )
  )
);
const UserManagement = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-UserManagement" */ "pages/dashboardPages/configuration/UserManagement"
    )
  )
);
const Permissions = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-Permissions" */ "pages/dashboardPages/configuration/Permissions"
    )
  )
);
const Roles = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "configuration-Roles" */ "pages/dashboardPages/configuration/Roles")
  )
);
const UMSProducts = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-UMSProducts" */ "pages/dashboardPages/configuration/UMSProducts"
    )
  )
);
const UMSAgencies = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-UMSAgencies" */ "pages/dashboardPages/configuration/UMSAgencies"
    )
  )
);
const UMSWorkspaceManage = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-UMSWorkspaceManage" */ "pages/dashboardPages/configuration/UMSWorkspaceManage"
    )
  )
);
const UMSTeams = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "configuration-UMSTeams" */ "pages/dashboardPages/configuration/UMSTeams"
    )
  )
);

export default function useConfigurationRoutes() {
  const { havePermission: isViewOnly } = usePermissionCheck(USER_WITH_NO_ACTION);

  const isViewOnlyRoute = isViewOnly
    ? []
    : [
        route("/configuration/agency", Agency, ROUTE_DASHBOARD, false, USERS_MANAGES_AGENCIES),
        route(
          "/configuration/agencies",
          UMSAgencies,
          ROUTE_DASHBOARD,
          false,
          USERS_MANAGES_AGENCIES
        ),
        route("/configuration/user-management", UserManagement, ROUTE_DASHBOARD, false, [
          USERS_MANAGES_USERS,
          USERS_MANAGES_WORKSPACES,
        ]),
        // route("/configuration/billing", Billing),
        // route("/configuration/security", Security),
        // route("/configuration/products", Products),
        // route("/configuration/notifications", Notifications),
        // route("/configuration/audit-logs", AuditLogs),
        // route("/configuration/integrations", Integrations),
        // route("/configuration/settings", Settings),
        route(
          "/configuration/permissions",
          Permissions,
          ROUTE_DASHBOARD,
          true,
          USERS_MANAGES_USERS
        ),
        // route("/configuration/services", Services),
        route("/configuration/roles", Roles, ROUTE_DASHBOARD, true, USERS_MANAGES_ROLES),
        route(
          "/configuration/products",
          UMSProducts,
          ROUTE_DASHBOARD,
          true,
          USERS_MANAGES_PRODUCTS
        ),
        route("/configuration/teams", UMSTeams, ROUTE_DASHBOARD, true),

        route(
          "/configuration/workspace-manage",
          UMSWorkspaceManage,
          ROUTE_DASHBOARD,
          true,
          USERS_MANAGES_WORKSPACES
        ),
        route(
          "/configuration/generate-invite-code",
          GeneratenviteCode,
          ROUTE_DASHBOARD,
          true,
          USERS_MANAGES_USERS
        ),
        // route("/configuration/launch-proxy", LaunchProxy, ROUTE_DASHBOARD, false),
      ];

  const configurationRoutes = [
    route("/configuration/profile", Profile, ROUTE_DASHBOARD, false),
    ...isViewOnlyRoute,
  ];

  return { configurationRoutes };
}
