import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { usePermissionCheck } from "hooks";
import { useSelector } from "react-redux";
import {
  SCORECARD_VIEW_ADMIN,
  SCORECARD_VIEW_PRODUCT,
  SCORECARD_VIEW_USER,
} from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const AllCompany = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "AllCompany" */ "pages/dashboardPages/Scorecard/AllCompany")
  )
);
const AllDepartment = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "AllDepartment" */ "pages/dashboardPages/Scorecard/AllDepartment")
  )
);
const AllLineOfBusiness = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "AllLineOfBusiness" */ "pages/dashboardPages/Scorecard/AllLineOfBusiness"
    )
  )
);
const AllUser = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "AllUser" */ "pages/dashboardPages/Scorecard/AllUser")
  )
);
const Dashboard = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Scorecard-Dashboard" */ "pages/dashboardPages/Scorecard/Dashboard")
  )
);
const MyReports = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "MyReports" */ "pages/dashboardPages/Scorecard/MyReports")
  )
);
const DashboardNew = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "DashboardNew" */ "pages/dashboardPages/Scorecard/DashboardNew")
  )
);

export default function useScorecardRoutes() {
  const { isDepartmentAdmin = false } = useSelector((state) => state?.userReducer.me) || {};
  const { havePermission: isCompanyAdmin } = usePermissionCheck(SCORECARD_VIEW_ADMIN);
  const { havePermission: isProductAdmin } = usePermissionCheck(SCORECARD_VIEW_PRODUCT);

  const isCompanyAndDepartmentRoute =
    isDepartmentAdmin || isCompanyAdmin || isProductAdmin
      ? [
          route("/scorecard/department", AllDepartment, ROUTE_DASHBOARD, false),
          route("/scorecard/scorecard-user", AllUser, ROUTE_DASHBOARD, false),
        ]
      : [];

  const isUserAndDepartmentReportRoute = !(isCompanyAdmin || isProductAdmin)
    ? [route("/scorecard/my-report", MyReports, ROUTE_DASHBOARD, false)]
    : [];

  const scorecardRoutes = [
    route("/scorecard/scorecard-dashboard", Dashboard, ROUTE_DASHBOARD, true, SCORECARD_VIEW_USER),
    route(
      "/scorecard/new-scorecard-dashboard",
      DashboardNew,
      ROUTE_DASHBOARD,
      true,
      SCORECARD_VIEW_USER
    ),
    route("/scorecard/company", AllCompany, ROUTE_DASHBOARD, false, SCORECARD_VIEW_ADMIN),
    route(
      "/scorecard/line-of-business",
      AllLineOfBusiness,
      ROUTE_DASHBOARD,
      false,
      SCORECARD_VIEW_ADMIN
    ),
    ...isCompanyAndDepartmentRoute,
    ...isUserAndDepartmentReportRoute,
  ];

  return { scorecardRoutes };
}
