import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import alasql from "alasql";
import {
  GET_SAS_REPORT_BY_USER_ID,
  GET_SAS_USER_REPORT,
  GQL_NATIVE_USER_REPORT,
} from "shared/gql/gqlSchema/sasGql";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import DebugPre from "components/DebugPre";
import { Col, Row, Tooltip } from "antd";
import { numCompactShort } from "utils";
import cx from "cx";
import { OverviewCalculatedCard, OverviewCard } from "../../Cards";
import { GET_TIKTOK_USER_REPORT } from "shared/gql/gqlSchema/tiktok";
import {
  SAS_FACEBOOK_PLATFORM_ID,
  SAS_GOOGLE_DISPLAY,
  SAS_TABOOLA_PLATFORM_ID,
  SAS_TIKTOK_PLATFORM_ID,
  TABOOLA_SPEND_PLATFORM_ID,
} from "pages/dashboardPages/SAS/sasKeysAndEnum";
import {
  VENDOR_ID_AM_PRODUCTION,
  VENDOR_ID_BODIS,
  VENDOR_ID_CODE_FUEL,
  VENDOR_ID_DOMAIN_ACTIVE,
  VENDOR_ID_MNET,
  VENDOR_ID_OB_MEDIA,
  VENDOR_ID_RISE_CODE,
  VENDOR_ID_SEDO,
  VENDOR_ID_TONIC,
} from "shared/SharedKeys";
import LookButton from "components/LookButton";
import { G360_GET_USER_REPORT } from "shared/gql/gqlSchema/g360Gql";
import { GET_F360_USER_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import { SPEND_INCREASE_PERCENT_SAS } from "shared/enum/commonKeysandEnum";

const D2S_WORKSPACE = 138;

function SASRevenueData(props, childRef) {
  const { id: propPlatformId } = props;
  const [userReports, setUserReports] = useState([]);
  const [spendReport, setSpendReport] = useState([]);
  const userSettings = useSelector((state) => state?.userSettings);
  const { selectedWorkspace } = userSettings || {};

  const yahooVendors = [
    VENDOR_ID_CODE_FUEL,
    VENDOR_ID_MNET,
    VENDOR_ID_RISE_CODE,
    VENDOR_ID_OB_MEDIA,
    VENDOR_ID_AM_PRODUCTION,
  ];

  const googleVendors = [VENDOR_ID_SEDO, VENDOR_ID_DOMAIN_ACTIVE, VENDOR_ID_BODIS, VENDOR_ID_TONIC];

  const { multiSelectedWorkspace, dateRangeTimezone } =
    useSelector((state) => state?.userSettings) || {};

  let revenueObject = {
    revenueApi: GET_SAS_USER_REPORT,
    revenueApiKey: "sasUserReports",
    revenueVariables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone?.value,
      platformId: propPlatformId,
      vendorId: selectedWorkspace === D2S_WORKSPACE ? yahooVendors : googleVendors,
    },
  };

  function getSpendReportApiObject(platform) {
    let spendObject = {};

    switch (platform) {
      case SAS_FACEBOOK_PLATFORM_ID:
        spendObject = {
          spendApi: GET_F360_USER_REPORT,
          spendApiKey: "fb360_userReporting",
          spendVariables: {
            start: dateRangeTimezone?.rangeDates[0],
            end: dateRangeTimezone?.rangeDates[1],
            timezone: dateRangeTimezone?.timeZone?.value,
            is_detailed: false,
          },
          spendObjectString: "spend",
          clicksObjectString: "clicks",
          leadsObjectString: "leadsFB",
        };
        break;

      case SAS_TIKTOK_PLATFORM_ID:
        spendObject = {
          spendApi: GET_TIKTOK_USER_REPORT,
          spendApiKey: "tiktok_getUserReport",
          spendVariables: {
            start: dateRangeTimezone?.rangeDates[0],
            end: dateRangeTimezone?.rangeDates[1],
            timezone: dateRangeTimezone?.timeZone?.value,
          },
          spendObjectString: "spend",
          clicksObjectString: "clicksTiktok",
          leadsObjectString: "conversion",
        };
        break;

      case SAS_TABOOLA_PLATFORM_ID:
        spendObject = {
          spendApi: GQL_NATIVE_USER_REPORT,
          spendApiKey: "native_UserReport",
          spendVariables: {
            from: dateRangeTimezone?.rangeDates[0],
            to: dateRangeTimezone?.rangeDates[1],
            platformId: TABOOLA_SPEND_PLATFORM_ID,
          },
          spendObjectString: "spent",
          clicksObjectString: "clicks",
          leadsObjectString: "conversions",
        };
        break;

      case SAS_GOOGLE_DISPLAY:
        spendObject = {
          spendApi: G360_GET_USER_REPORT,
          spendApiKey: "g_userReporting",
          spendVariables: {
            from: dateRangeTimezone?.rangeDates[0],
            to: dateRangeTimezone?.rangeDates[1],
            timezone: dateRangeTimezone?.timeZone?.value,
          },
          spendObjectString: "cost",
          clicksObjectString: "clicks",
          leadsObjectString: "conversions",
        };
        break;

      default:
        spendObject = {};
        break;
    }

    return spendObject;
  }

  let spendObject = getSpendReportApiObject(Number(propPlatformId));

  const {
    data: data_GET_USER_REVENUE_REPORT,
    loading: loading_GET_USER_REVENUE_REPORT,
    error: error_GET_USER_REVENUE_REPORT,
    refetch: refetch_GET_USER_REVENUE_REPORT,
  } = useCustomQuery(revenueObject?.revenueApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[revenueObject?.revenueApiKey];
      if (element) {
        setUserReports(element);
      }
    },
    variables: {
      ...revenueObject?.revenueVariables,
    },
  });

  const [
    getSpendReport,
    {
      data: data_GET_REPORT_BY_USER,
      loading: loading_GET_REPORT_BY_USER,
      error: error_GET_REPORT_BY_USER,
      refetch: refetch_GET_REPORT_BY_USER,
    },
  ] = useCustomLazyQuery(spendObject?.spendApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element =
        Number(propPlatformId) === SAS_TABOOLA_PLATFORM_ID
          ? e?.[spendObject?.spendApiKey]?.map((item) => ({
              ...item?.spendReport,
              userId: item?.userId,
            }))
          : e?.[spendObject?.spendApiKey];
      if (element) {
        setSpendReport(
          element?.map((item) => {
            const {
              [spendObject.spendObjectString]: spend = 0,
              [spendObject.clicksObjectString]: click = 0,
              [spendObject.leadsObjectString]: leads = 0,
            } = item;
            return {
              ...item,
              spends: spend,
              spendsClick: click,
              spendsLeads: leads,
            };
          })
        );
      }
    },
    variables: {
      ...spendObject?.spendVariables,
    },
  });

  useEffect(() => {
    if (spendObject) {
      getSpendReport();
    }
  }, []);

  const refetch = () => {
    refetch_GET_REPORT_BY_USER();
    refetch_GET_USER_REVENUE_REPORT();
  };

  const loading = loading_GET_USER_REVENUE_REPORT || loading_GET_REPORT_BY_USER;

  // useImperativeHandle(childRef, () => {
  //   return { refetch, loading };
  // });

  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };

  let finalMerge = [];
  let finalMergeData = [];

  if (!loading) {
    let query = "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.user.id = b.userId";

    finalMerge = alasql(query, [userReports, spendReport]);

    finalMerge?.forEach((element, indx) => {
      const { estimate_revenue = 0, clicks = 0, spends = 0 } = element;

      const spendUpdate =
        Number(propPlatformId) === SAS_FACEBOOK_PLATFORM_ID
          ? spends * SPEND_INCREASE_PERCENT_SAS
          : spends;

      const PROFIT = parseFloat(estimate_revenue) - parseFloat(spendUpdate);
      const RPL_DATA = (estimate_revenue / clicks).toFixed(2) || 0;
      const RPM_DATA = (estimate_revenue / clicks) * 1000;
      const RPL = parseFloat(RPL_DATA) || 0;
      const RPM = parseFloat(RPM_DATA) || 0;
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;

      finalMergeData.push({
        ...element,
        spends: spendUpdate,
        RPM: valueCheck(RPM),
        RPL: valueCheck(RPL),
        PROFIT: valueCheck(PROFIT),
        ROI: valueCheck(ROI).toFixed(2),
      });
    });
  }

  let total_sessions = 0,
    total_clicks = 0,
    total_revenue = 0,
    total_spends = 0,
    total_spendsLeads = 0,
    total_spendsClick = 0;

  for (const {
    clicks = 0,
    estimate_revenue = 0,
    sessions = 0,
    spends = 0,
    spendsLeads = 0,
    spendsClick = 0,
  } of finalMergeData) {
    total_sessions += JSON.parse(sessions || 0);
    total_clicks += JSON.parse(clicks || 0);
    total_revenue += JSON.parse(estimate_revenue || 0);
    total_spends += JSON.parse(spends || 0);
    total_spendsLeads += JSON.parse(spendsLeads || 0);
    total_spendsClick += JSON.parse(spendsClick || 0);
  }

  const TOTAL_RPL = total_revenue / total_clicks;
  const TOTAL_RPM = (total_revenue / total_clicks) * 1000;
  const TOTAL_PROFIT = total_revenue - total_spends;
  const TOTAL_ROI = (TOTAL_PROFIT / total_spends) * 100 || 0;

  const overview = [
    {
      iconType: "creditCard",
      title: "Spend",
      value: (
        <>
          <Tooltip title={valueCheck(total_spends, true, 2)}>
            {"$" + numCompactShort(valueCheck(total_spends, true), 2)}
          </Tooltip>
        </>
      ),
    },
    {
      iconType: "barChartLine",
      title: "Gross Margin",
      value: (
        <Tooltip title={TOTAL_PROFIT.toFixed(2)}>
          <span
            className={cx({
              "text-primary": TOTAL_PROFIT > 0,
              "text-danger": TOTAL_PROFIT < 0,
            })}
          >
            ${numCompactShort(TOTAL_PROFIT.toFixed(1).replace("-", ""), 2)}
          </span>
        </Tooltip>
      ),
      iconClassName: TOTAL_PROFIT > -0.01 ? "" : "text-danger",
    },
    {
      iconType: "dollar",
      title: "Revenue",
      value: (
        <>
          <Tooltip title={valueCheck(total_revenue, true, 2)}>
            {"$" + numCompactShort(valueCheck(total_revenue, true), 2)}
          </Tooltip>
        </>
      ),
    },
    {
      iconType:
        valueCheck(TOTAL_ROI, true, 2) == "0.00"
          ? "line"
          : valueCheck(TOTAL_ROI, true, 2) > 0
          ? "rise"
          : "fall",
      title: "ROI",
      value: <>{valueCheck(TOTAL_ROI, true, 2)}%</>,
      iconClassName: valueCheck(TOTAL_ROI, true, 2) > -0.01 ? "" : "text-danger",
    },
  ];

  const overviewCalculatedData = [
    { title: "Clicks (P)", value: numCompactShort(valueCheck(total_spendsClick, true), 2) },
    { title: "Clicks (V)", value: numCompactShort(valueCheck(total_sessions, true), 2) },
    { title: "Leads (V)", value: numCompactShort(valueCheck(total_clicks, true), 2) },
    { title: "Leads (P)", value: numCompactShort(valueCheck(total_spendsLeads, true), 2) },
    { title: "RPL", value: numCompactShort(valueCheck(TOTAL_RPL, true), 2) },
    { title: "RPM", value: numCompactShort(valueCheck(TOTAL_RPM, true), 2) },
  ];

  const renderSkeleton = () => (
    <>
      <div>
        <Row gutter={28}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Row gutter={21}>
          {overviewCalculatedData.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overviewCalculatedData.length}
              xl={24 / overviewCalculatedData.length}
              key={"overviewCalculatedData" + index}
            >
              <OverviewCalculatedCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  const renderContent = () => (
    <>
      <div>
        <Row gutter={28}>
          {overview.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overview.length}
              xl={24 / overview.length}
              key={"overview" + index}
            >
              <OverviewCard {...data} isSmall />
            </Col>
          ))}
        </Row>
      </div>
      <div>
        <Row gutter={21}>
          {overviewCalculatedData.map((data, index) => (
            <Col
              className="mb-20"
              xs={48 / overviewCalculatedData.length}
              xl={24 / overviewCalculatedData.length}
              key={"overviewCalculatedData" + index}
            >
              <OverviewCalculatedCard {...data} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  const debugContent = [
    { contentObjects: [{ multiSelectedWorkspace }] },
    { isRow: true, contentObjects: [{ userReports }, { spendReport }] },
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
  ];

  return (
    <div>
      <div className="d-flex flex-end m-10">
        <Tooltip title="Refetch">
          <LookButton onClick={refetch} size="small" iconType="reload" className="mr-20" />
        </Tooltip>
      </div>
      {loading ? renderSkeleton() : renderContent()}
      <DebugPre content={debugContent} />
    </div>
  );
}

// export default forwardRef(SASRevenueData);

export default SASRevenueData;
