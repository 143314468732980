export function groupForStatus(inputArray, reportType) {
  const res = [];
  reportType = reportType + "_id";
  for (let i of inputArray) {
    if (i.adaccount_id === null || i.adaccount_id === undefined) {
      continue;
    }
    const has = res.findIndex((el) => el.adaccount_id === i.adaccount_id);
    if (has === -1) {
      res.push({
        adaccount_id: i.adaccount_id,
        [reportType]: [i[reportType]],
      });
    } else {
      res[has] = {
        adaccount_id: i.adaccount_id,
        [reportType]: [...res[has][reportType], i[reportType]],
      };
    }
  }
  return res;
}

export function groupForBudget(inputArray, reportType, by_number, is_bulk, value) {
  const res = [];
  reportType = reportType + "_id";
  for (let i of inputArray) {
    if (i.adaccount_id === null || i.adaccount_id === undefined) continue;

    const type =
      i?.daily_budget !== 0
        ? "daily_budget"
        : i?.lifetime_budget !== 0
        ? "lifetime_budget"
        : undefined;

    if (type === undefined) continue;
    const has = res.findIndex((el) => el.adaccount_id === i.adaccount_id);
    if (has === -1) {
      res.push({
        adaccount_id: i.adaccount_id,
        [reportType]: [
          {
            id: i[reportType],
            type,
            value: by_number
              ? Math.floor(value)
              : Math.floor(i[type] + i[type] * ((is_bulk ? value : value?.input) / 100)),
          },
        ],
      });
    } else {
      res[has] = {
        ...res[has],
        adaccount_id: i.adaccount_id,
        [reportType]: [
          ...res[has][reportType],
          {
            id: i[reportType],
            type,
            value: by_number
              ? Math.floor(value)
              : Math.floor(i[type] + i[type] * ((is_bulk ? value : value?.input) / 100)),
          },
        ],
      };
    }
  }
  return res;
}

export function groupForBid(inputArray, reportType, by_number, is_bulk, value) {
  const res = [];
  reportType = reportType + "_id";
  for (let i of inputArray) {
    if (i.adaccount_id === null || i.adaccount_id === undefined || i.bid_amount === 0) continue;

    const type = "bid_amount";

    if (type === undefined) continue;
    const has = res.findIndex((el) => el.adaccount_id === i.adaccount_id);
    if (has === -1) {
      res.push({
        adaccount_id: i.adaccount_id,
        [reportType]: [
          {
            id: i[reportType],
            type,
            value: by_number
              ? value
              : parseFloat(
                  (i[type] + i[type] * ((is_bulk ? value : value?.input) / 100))?.toFixed(2)
                ),
          },
        ],
      });
    } else {
      res[has] = {
        ...res[has],
        adaccount_id: i.adaccount_id,
        [reportType]: [
          ...res[has][reportType],
          {
            id: i[reportType],
            type,
            value: by_number
              ? value
              : parseFloat(
                  (i[type] + i[type] * ((is_bulk ? value : value?.input) / 100))?.toFixed(2)
                ),
          },
        ],
      };
    }
  }
  return res;
}

//// bid_amount = 0
