import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import packageJson from "../../package.json";

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    if (a === b) continue;
    return a > b || isNaN(b);
  }
  return false;
};

const RefreshContext = React.createContext();

console.log("Clearing cache and hard reloading...");

// Define the provider component
const RefreshProvider = ({ children }) => {
  const [count, setCount] = useState(0);
  const [selectTime, setSelectTime] = useState("Later");
  const calculateDelay = (optionValue) => {
    if (optionValue === "1H") {
      return 60 * 60 * 1000; // 1 hour in milliseconds
    } else if (optionValue === "12H") {
      const now = new Date();
      const tonight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 20, 0, 0); // 8 PM
      return tonight.getTime() - now.getTime();
    } else if (optionValue === "1D") {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0); // Start of the next day
      return tomorrow.getTime() - Date.now();
    }
    return 0;
  };

  const refreshCacheAndReload = () => {
    const delay = selectTime === "Later" ? 0 : calculateDelay(selectTime);
    setTimeout(() => {
      setCount(1); // Update count after the specified delay
    }, delay);
  };

  const handleSubmit = useCallback(() => {
    console.log("Clearing cache and hard reloading...");

    setSelectTime("Later");
    setCount(0);
    if (caches) {
      caches.keys().then((names) => {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload(true);
  }, []);
  const contextValue = {
    refreshCacheAndReload,
    count,
    setCount,
    handleSubmit,
    selectTime,
    setSelectTime,
  };
  return <RefreshContext.Provider value={contextValue}>{children}</RefreshContext.Provider>;
};

const CacheBuster = ({ children, refreshCacheAndReload }) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);
  const location = useLocation();

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          setIsLatestVersion(false);
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          setIsLatestVersion(true);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred while fetching meta.json:", error);
        // Handle the error here, such as displaying an error message or performing alternative actions.
        setLoading(false);
      });
  }, [location.pathname]);

  return (
    <RefreshProvider refreshCacheAndReload={refreshCacheAndReload}>
      <RefreshContext.Consumer>
        {(context) => (
          <>
            {children({
              loading,
              isLatestVersion,
              refreshCacheAndReload: context.refreshCacheAndReload,
            })}
          </>
        )}
      </RefreshContext.Consumer>
    </RefreshProvider>
  );
};

export { CacheBuster, RefreshContext, RefreshProvider };
