import * as types from "../actions/actionTypes";

export default function slackReducer(
  state = { slack_channels: [], isChannelAuthCalled: false, channelCallTeamId: "" },
  action
) {
  switch (action.type) {
    case types.SET_SLACK_CHANNEL_DATA:
      return { ...state, slack_channels: action.payload };
    case types.FLUSH_SLACK_CHANNEL_DATA:
      return { ...state, slack_channels: [] };

    case types.SET_SLACK_CHANNEL_AUTH_CALLED:
      return { ...state, isChannelAuthCalled: true };

    case types.SET_SLACK_CHANNEL_AUTH_SET_TEAM_ID:
      return { ...state, channelCallTeamId: action.payload };
    case types.SET_SLACK_CHANNEL_AUTH_DONE:
      return { ...state, isChannelAuthCalled: false, channelCallTeamId: "" };

    default:
      return state;
  }
}
