import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import LookDrawer from "components/LookDrawer";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { GET_CREATIVES_BY_USERS } from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import AdsByCreatives from "./AdsByCreatives";

function Dashboard() {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [rowProps, setRowProps] = useState();
  const [usersFetch, { data, loading, refetch }] = useCustomLazyQuery(GET_CREATIVES_BY_USERS, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (e) => {},
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const showCreativesDrawer = (e) => {
    setVisibleDrawer(true);
    setRowProps(e);
  };
  const onClose = () => {
    setVisibleDrawer(false);
    setRowProps();
  };
  useEffect(() => {
    usersFetch();
  }, []);
  const initialColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      searchable: true,
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={otherProps?.user}
          isClick={() => showCreativesDrawer(otherProps)}
        />
      ),
      sorter: true,
      width: 50,
      minWidth: 50,
    },
    {
      title: "Username",
      dataIndex: "name",
      key: "name",
      searchable: true,
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showCreativesDrawer(otherProps)} />
      ),
      fixed: "left",
      sorter: true,
      width: 100,
      minWidth: 100,
    },
    {
      title: "Creatives Count",
      dataIndex: "creative_count",
      key: "creative_count",
      searchable: true,
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={otherProps?.creativesPresent?.length}
          isClick={() => showCreativesDrawer(otherProps)}
        />
      ),

      fixed: "left",
      sorter: true,
      width: 124,
      minWidth: 124,
    },
  ];
  const tableProps = {
    initialColumns,
    recordData: data?.clCreativeUserMedia?.map((element) => ({ ...element, key: element.id })),
  };
  const drawerProps = {
    textBreadcrumb: [rowProps?.name, "Creatives"],
    size: 900,
    visible: visibleDrawer,
    onClose: onClose,
  };

  return (
    <div>
      <DashboardHeader title="Creatives Dashboard" />
      <LookDrawer {...drawerProps}>{visibleDrawer && <AdsByCreatives {...rowProps} />}</LookDrawer>

      <LookTable
        persistPagination
        tableProps={tableProps}
        refetch={refetch}
        loading={loading}
        isRowSelection={false}
      />
    </div>
  );
}

export default Dashboard;
