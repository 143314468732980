import AdSetReport from "..";
import AdsReport from "../../Ads";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
} from "actions/actionTypes";
import { Tooltip } from "antd";

export default function BulkDetailView(props) {
  const { visible = false, onClose, bulkProps = [] } = props;

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={
        <Tooltip
          title={
            <ul className="mt-10">
              {bulkProps?.map(({ adsetName, ind }) => (
                <li key={ind}>{adsetName}</li>
              ))}
            </ul>
          }
        >
          <span>Adset Detailed Report</span>
        </Tooltip>
      }
    >
      {visible && (
        <LookDrawerMainSideBar
          tabContent={[
            {
              hoverTitle: "Ads Report",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <AdsReport
                  isBulkAdsetFiltered
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
                  {...props}
                />
              ),
              noSpace: true,
            },
          ]}
        />
      )}
    </LookDrawer>
  );
}
