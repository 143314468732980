import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";

import route, { ROUTE_DASHBOARD } from "../route";
import LaunchListing from "components/Launch/LaunchListing";
import {
  PLATFORM_TYPE_FACEBOOK,
  PLATFORM_TYPE_TIKTOK,
  SERVICE_TYPE_LEAD_GEN,
  SERVICE_TYPE_SAS,
  SERVICE_TYPE_VS,
} from "shared/enum/launchKeysAndEnum";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import LaunchTarget from "components/LaunchTarget";
import {
  PERMISSION_VOLUUM_VIEW,
  SAS_USER,
  VS_VIEW_REPORTING,
} from "shared/UserPermission/userPermissionList";
import LaunchDashboard from "components/LaunchDashboard";
import CloneListing from "components/Launch/CloneList";
import FAQ from "components/FAQ";

const Launch = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "components-Launch" */ "components/Launch"))
);
const LaunchNew = lazy(() =>
  loadbleLazy(() => import(/* webpackChunkName: "components-LaunchV2" */ "components/LaunchV2"))
);
const BucketEdit = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "components-Edit" */ "pages/dashboardPages/Launch/Bucket/Edit")
  )
);
const BucketList = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "components-List" */ "pages/dashboardPages/Launch/Bucket/List")
  )
);

const serviceRoutes = [
  {
    serviceRoute: "vs",
    serviceType: SERVICE_TYPE_VS,
    productId: PRODUCT_ID_VS,
    permission: VS_VIEW_REPORTING,
  },
  {
    serviceRoute: "sas",
    serviceType: SERVICE_TYPE_SAS,
    productId: PRODUCT_ID_SAS,
    permission: SAS_USER,
  },
  {
    serviceRoute: "leadgen",
    serviceType: SERVICE_TYPE_LEAD_GEN,
    productId: PRODUCT_ID_LEADGEN,
    permission: PERMISSION_VOLUUM_VIEW,
  },
];

let launchRoutes = [];

serviceRoutes?.forEach(({ serviceRoute, serviceType, productId, permission }) => {
  launchRoutes.push(
    route(
      `/${serviceRoute}/launch/bucket/:bucketId`,
      () => <BucketEdit serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch/faq/`,
      () => <FAQ serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch/bucket`,
      () => <BucketList serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch/launch-dashboard`,
      () => <LaunchDashboard serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch/target`,
      () => <LaunchTarget serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      true,
      permission
    ),
    route(
      `/${serviceRoute}/launch/list`,
      () => <LaunchListing serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      true,
      permission
    ),
    route(
      `/${serviceRoute}/launch/clone-list`,
      () => <CloneListing serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      true,
      permission
    ),
    route(
      `/${serviceRoute}/re-launch/category/:platform/:launchId?`,
      () => (
        <Launch
          instantLaunch
          serviceType={serviceType}
          productId={productId}
          platform={
            serviceType === SERVICE_TYPE_LEAD_GEN ? PLATFORM_TYPE_TIKTOK : PLATFORM_TYPE_FACEBOOK
          }
        />
      ),
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch/category/:categoryName/:categoryId?`,
      () => <Launch isCategoryLaunch serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch/:bucketId/:launchId`,
      () => <Launch serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch/:bucketId?`,
      () => <Launch serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),

    //Launch version-2 routes

    route(
      `/${serviceRoute}/re-launch-v2/category/:platform/:launchId?`,
      () => (
        <LaunchNew
          instantLaunch
          serviceType={serviceType}
          productId={productId}
          platform={
            serviceType === SERVICE_TYPE_LEAD_GEN ? PLATFORM_TYPE_TIKTOK : PLATFORM_TYPE_FACEBOOK
          }
        />
      ),
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch-v2/category/:categoryName/:categoryId?`,
      () => <LaunchNew isCategoryLaunch serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch-v2/:bucketId/:launchId`,
      () => <LaunchNew serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    ),
    route(
      `/${serviceRoute}/launch-v2/:bucketId?`,
      () => <LaunchNew serviceType={serviceType} productId={productId} />,
      ROUTE_DASHBOARD,
      false,
      permission
    )
  );
});

// const launchRoutes = [
//   //VS
//   route(
//     "/vs/launch/bucket/:bucketId",
//     () => <BucketEdit serviceType={SERVICE_TYPE_VS} productId={PRODUCT_ID_VS} />,
//     ROUTE_DASHBOARD,
//     false
//   ),
//   route(
//     "/vs/launch/bucket",
//     () => <BucketList serviceType={SERVICE_TYPE_VS} productId={PRODUCT_ID_VS} />,
//     ROUTE_DASHBOARD,
//     false
//   ),
//   route(
//     "/vs/launch/target",
//     () => <LaunchTarget serviceType={SERVICE_TYPE_VS} productId={PRODUCT_ID_VS} />,
//     ROUTE_DASHBOARD,
//     true
//   ),
//   route(
//     "/vs/launch/list",
//     () => <LaunchListing serviceType={SERVICE_TYPE_VS} productId={PRODUCT_ID_VS} />,
//     ROUTE_DASHBOARD,
//     true
//   ),
//   route(
//     "/vs/launch/:bucketId",
//     () => <Launch serviceType={SERVICE_TYPE_VS} productId={PRODUCT_ID_VS} />,
//     ROUTE_DASHBOARD,
//     false
//   ),

//   //SAS
//   route(
//     "/sas/launch/bucket/:bucketId",
//     () => <BucketEdit serviceType={SERVICE_TYPE_SAS} productId={PRODUCT_ID_SAS} />,
//     ROUTE_DASHBOARD,
//     false
//   ),
//   route(
//     "/sas/launch/bucket",
//     () => <BucketList serviceType={SERVICE_TYPE_SAS} productId={PRODUCT_ID_SAS} />,
//     ROUTE_DASHBOARD,
//     false
//   ),
//   route(
//     "/sas/launch/target",
//     () => <LaunchTarget serviceType={SERVICE_TYPE_SAS} productId={PRODUCT_ID_SAS} />,
//     ROUTE_DASHBOARD,
//     false
//   ),
//   route(
//     "/sas/launch/list",
//     () => <LaunchListing serviceType={SERVICE_TYPE_SAS} productId={PRODUCT_ID_SAS} />,
//     ROUTE_DASHBOARD,
//     true
//   ),
//   route(
//     "/sas/launch/:bucketId",
//     () => <Launch serviceType={SERVICE_TYPE_SAS} productId={PRODUCT_ID_SAS} />,
//     ROUTE_DASHBOARD,
//     false
//   ),
// ];

export default launchRoutes;
