import { Checkbox, Col, Row } from "antd";
import LookButton from "components/LookButton";
import { useLookModal } from "components/LookModal";
import SelectInput from "components/forms/SelectInput";
import cx from "cx";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LAUNCH_COUNTRIES_ENUM } from "shared/enum/iCampaignLaunchEnumArray";
import { removeObjectByIndex } from "utils";

function FilterForm(props) {
  const { filterData = [], setFilterData = () => {}, closeModal = () => {} } = props;
  const [filterDataLocal, setFilterDataLocal] = useState(filterData);
  const [active, setActive] = useState("device");

  const { userSettingData } = useSelector((state) => state?.userSettings) || {};
  const { launchConfig } = userSettingData || {};
  const { targeting } = launchConfig || {};
  const { device_platforms = [], user_os = [], countries_group = [] } = targeting || {};

  console.log({ device_platforms, countries_group, user_os });

  const handleFilter = (name, value) => {
    const existedDataInde = filterDataLocal.findIndex(({ filterName }) => filterName === name);

    if (existedDataInde !== -1) {
      let temp = JSON.parse(JSON.stringify(filterDataLocal));
      temp[existedDataInde] = { ...temp[existedDataInde], value };
      setFilterDataLocal(temp);
    } else {
      setFilterDataLocal((pre) => [...pre, { filterName: name, value }]);
    }
  };

  const countrySelectionView = () => {
    const currentCountryObject =
      filterDataLocal?.find(({ filterName }) => filterName === "country") || {};

    return (
      <>
        <div className="position-relative">
          <SelectInput
            className="multi-select-hide-tags"
            multiple
            name="country"
            value={currentCountryObject?.value}
            onChange={(e) => handleFilter("country", e)}
            // options={countries_group}
            options={LAUNCH_COUNTRIES_ENUM}
          />
          <span className="select-custom-placeholder">Select countries</span>
        </div>
        <div className="ant-select w-100 ant-select-multiple">
          <div class="ant-select-selector">
            <div className="ant-select-selection-overflow overflow-auto" style={{ maxHeight: 300 }}>
              {currentCountryObject?.value?.map((d, index) => {
                const currentObject = LAUNCH_COUNTRIES_ENUM?.find(
                  (countryData) => countryData?.value === d
                );

                return (
                  <div class="ant-select-selection-overflow-item">
                    <span class="ant-select-selection-item" title={currentObject?.name}>
                      <span class="ant-select-selection-item-content">{currentObject?.name}</span>
                      <LookButton
                        style={{ width: "auto", height: "auto" }}
                        iconTypeProps={{ style: { fontSize: 10 } }}
                        className="h-auto w-auto border-0"
                        size="small"
                        iconType="close"
                        onClick={() =>
                          handleFilter(
                            "country",
                            removeObjectByIndex(currentCountryObject?.value, index)
                          )
                        }
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const deviceSelectionView = () => {
    const currentDeviceObject =
      filterDataLocal?.find(({ filterName }) => filterName === "devicePlatforms") || {};
    const currentOSObject =
      filterDataLocal?.find(({ filterName }) => filterName === "userOs") || {};
    return (
      <>
        <div className="mb-10">
          <p>Devices</p>
          <Checkbox.Group
            value={currentDeviceObject?.value}
            options={device_platforms?.map(({ name, value }) => ({ label: name, value }))}
            onChange={(e) => handleFilter("devicePlatforms", e)}
          />
        </div>
        <div className="mb-10">
          <p>Mobile Os</p>
          <Checkbox.Group
            value={currentOSObject?.value}
            options={[
              ...user_os?.map(({ name, value }) => ({ label: name, value })),
              { label: "All", value: "All" },
            ]}
            onChange={(e) => {
              if (e.length > 1) {
                handleFilter(
                  "userOs",
                  e.filter((item) => item !== "All")
                );
              } else {
                handleFilter("userOs", e);
              }
            }}
          />
        </div>
      </>
    );
    // device_platforms;

    // user_os;
  };

  const filterTabOptions = [
    { id: "device", label: "Device", content: deviceSelectionView() },
    { id: "country", label: "Country", content: countrySelectionView() },
  ];

  const activeIndex = filterTabOptions?.findIndex(({ id }) => id === active);

  const onApply = () => {
    setFilterData(filterDataLocal);
    closeModal();
  };
  const onReset = () => {
    setFilterData([]);
    setFilterDataLocal([]);
    closeModal();
  };

  return (
    <>
      <div className="m-filter-container">
        <div className="tab-options">
          {filterTabOptions?.map(({ id, label }) => (
            <div className="tab-element">
              <LookButton
                block
                className={cx("outer-btn", { active: id === active })}
                onClick={() => setActive(id)}
              >
                {label}
              </LookButton>
            </div>
          ))}
        </div>
        <div className="tab-content">
          <div className="content-wrapper">
            {activeIndex !== -1 && filterTabOptions[activeIndex]?.content}
          </div>
        </div>
      </div>
      <div className="filter-modal-footer-btn-content">
        <div>
          <Row gutter={10}>
            <Col span={12}>
              <LookButton block onClick={onReset}>
                Reset
              </LookButton>
            </Col>
            <Col span={12}>
              <LookButton block onClick={onApply}>
                Apply
              </LookButton>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default function useUpdateAdsetFilter(props = {}) {
  const { filterData = [], setFilterData = () => {} } = props;

  const { launchConfigFetch } = useApiToStoreContext();
  useEffect(() => {
    launchConfigFetch();
  }, []);

  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Adset Filter",
    footer: false,
    width: 600,
    noContentSpace: true,
  });

  function filterView() {
    return modal(visibleModal && <FilterForm {...{ filterData, setFilterData, closeModal }} />);
  }

  return { openFilter: openModal, filterView };
}
