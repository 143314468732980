import LookButton from "components/LookButton";
import { Badge, Button, Col, Popover, Row, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { RefreshContext } from "components/CacheBuster";
import SelectInput from "components/forms/SelectInput";
import NotificationWrapper from "./NotificationWrapper";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { GET_USER_NOTIFICATION_COUNT } from "shared/gql/gqlSchema/notificationGql";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_NOTIFICATION_ADDED } from "shared/gql/gqlSchema/subscriptionGql";
import { openNotification } from "utils";

export default function Notification(props) {
  const [visible, setVisible] = useState(false);
  const { count, setCount, handleSubmit, selectTime, setSelectTime } = useContext(RefreshContext);
  const { data: subs_data } = useSubscription(SUBSCRIPTION_NOTIFICATION_ADDED);
  const [notificationCount, setNotificationCount] = useState(false);
  const [fetchCount, { loading }] = useCustomLazyQuery(GET_USER_NOTIFICATION_COUNT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      setNotificationCount(e?.RE_getUserNotication);
    },
  });

  const handleUpdate = () => {
    handleSubmit(); // Call the original handleSubmit function from RefreshContext
    setVisible(false); // Close the popover after triggering the update
  };

  const updateTime = [
    { name: "Try in an hour", value: "1H" },
    { name: "Try tonight", value: "12H" },
    { name: "Try tomorrow", value: "1D" },
  ];

  useEffect(() => {
    if (!loading && subs_data?.notification) {
      const { service_name = "", payload = "" } = subs_data?.notification;
      if (service_name !== "Rule Engine") {
        openNotification({
          type: "info",
          message: service_name,
          description: JSON.parse(payload)?.message,
        });
      }
    } else {
      fetchCount();
    }
  }, [subs_data]);

  return (
    <div style={{ position: "absolute", bottom: 60, left: 7, cursor: "pointer", zIndex: 2 }}>
      <Popover
        placement="rightBottom"
        open={visible}
        onOpenChange={(e) => setVisible(e)}
        destroyTooltipOnHide
        content={
          <>
            {count ? (
              <>
                <div className="title mb-12 pointer" style={{ width: "300px" }}>
                  <h3 style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>
                    Exciting Update Available Now!
                  </h3>
                  <p>
                    Discover our latest app update packed with amazing features, improved
                    performance, and bug fixes. Upgrade today to enjoy an even better user
                    experience!
                  </p>
                </div>
                <Row gutter={12} className="flex-center">
                  <Col span={12}>
                    <div style={{ filter: "contrast(0.2)" }}>
                      <SelectInput
                        className="mb-0"
                        value={selectTime}
                        options={updateTime}
                        onChange={(e) => {
                          setSelectTime(e);
                          setCount(0);
                          setVisible(false);
                        }}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <Button
                      block
                      type="primary"
                      onClick={handleUpdate}
                      // disabled={loading}
                    >
                      Update Now!
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <NotificationWrapper
                subs_data={subs_data}
                setNotificationCount={setNotificationCount}
              />
            )}
          </>
        }
        trigger="click"
      >
        <Space size="middle">
          <Badge dot={notificationCount}>
            <LookButton iconType="bell" className="send-button" />
          </Badge>
        </Space>
      </Popover>
    </div>
  );
}
