import { Col, Row, Space, Tabs, Tooltip } from "antd";
import Search from "antd/lib/transfer/search";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { OverviewCard } from "pages/dashboardPages/mainDashboard/UserRevenuData/Cards";
import React, { useEffect, useState } from "react";
import { GET_MOODBOARD_DASHBOARD_OVERVIEW } from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import DashboardTabs from "./DashboardTabs";

function MoodBoardDashboard(props) {
  const { id, name, productId } = props;
  const [activeTab, setActiveTab] = useState("1");

  const [overviewData, setOverviewData] = useState({});
  const [
    moodBoardOverviewFetch,
    { loading: data_overView_loading, refetch: data_overView_refetch },
  ] = useCustomLazyQuery(GET_MOODBOARD_DASHBOARD_OVERVIEW, {
    onCompleted: (e) => {
      setOverviewData(e?.ctGetMoodBoardOverview?.data?.[0]);
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "mb-res-err" });
    },
  });
  const onChange = (key, data, isEdited) => {
    setActiveTab(key);
  };
  const {
    angle = {},
    hook = {},
    script = {},
    copyPoint = {},
    cta = {},
    painPoint = {},
  } = overviewData || {};

  const tabContent = [
    {
      key: "1",
      label: (
        <span>
          <ActionIconRender iconType="rectangleList" />
          Copy Points
        </span>
      ),
      children: <DashboardTabs data={copyPoint?.trendingCopyPoints} />,
    },
    {
      key: "2",
      label: (
        <span>
          <ActionIconRender iconType="sunAngle" />
          Angles
        </span>
      ),
      children: <DashboardTabs data={angle?.trendingAngles} />,
    },
    {
      key: "3",
      label: (
        <span>
          <ActionIconRender iconType="hook" />
          Hooks
        </span>
      ),
      children: <DashboardTabs data={hook?.trendingHooks} />,
    },
    {
      key: "4",
      label: (
        <span>
          <ActionIconRender iconType="pain" />
          Pain points
        </span>
      ),
      children: <DashboardTabs data={painPoint?.trendingPainPoint} />,
    },
    {
      key: "5",
      label: (
        <span>
          <ActionIconRender iconType="cta" />
          CTA
        </span>
      ),
      children: <DashboardTabs data={cta?.trendingCtas} />,
    },
  ];

  const overview = [
    {
      iconType: "rectangleList",
      title: "CopyPoints",
      value: (
        <>
          <Tooltip title={copyPoint?.totalCount}>{copyPoint?.totalCount}</Tooltip>
        </>
      ),
    },
    {
      iconType: "sunAngle",
      title: "Hooks",
      value: (
        <>
          <Tooltip title={hook?.totalCount}>{hook?.totalCount}</Tooltip>
        </>
      ),
    },
    {
      iconType: "cta",
      title: "CTA",
      value: (
        <>
          <Tooltip title={cta?.totalCount}>{cta?.totalCount}</Tooltip>
        </>
      ),
    },
    {
      iconType: "script-outline",
      title: "Scripts",
      value: (
        <>
          <Tooltip title={script?.totalCount}>{script?.totalCount}</Tooltip>
        </>
      ),
    },
    {
      iconType: "pain",
      title: "Pain point",
      value: (
        <>
          <Tooltip title={painPoint?.totalCount}>{painPoint?.totalCount}</Tooltip>
        </>
      ),
    },
    {
      iconType: "sunAngle",
      title: "Angles",
      value: (
        <>
          <Tooltip title={angle?.totalCount}>{angle?.totalCount}</Tooltip>
        </>
      ),
    },
  ];
  const renderSkeleton = () => (
    <>
      <div>
        <Row gutter={[4, 20]}>
          {overview.map((data, index) => (
            <Col className="flex-grow-1" key={"overview" + index}>
              <OverviewCard skeleton />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
  useEffect(() => {
    moodBoardOverviewFetch({ variables: { categoryId: id, productId: productId } });
  }, []);

  const renderContent = () => (
    <>
      <div>
        <Row gutter={[4, 20]}>
          {overview.map((data, index) => (
            <Col
              className="flex-grow-1"
              // xs={48 / (overview.length - 1)}

              key={"overview" + index}
            >
              <OverviewCard isSmall {...data} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );

  return (
    <div className="px-15">
      <div className={cx("look-table-search", "py-8")}>
        <Space direction="vertical" style={{ width: "80%" }}>
          <Search placeholder="Search" />
        </Space>
      </div>
      <p style={{ fontWeight: "600" }}> Overview </p>
      {data_overView_loading ? renderSkeleton() : renderContent()}
      <div className="mb-30">
        <p style={{ fontWeight: "600" }}> Trending</p>

        <Tabs
          className={cx("scriptTabs")}
          defaultActiveKey="1"
          items={tabContent}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default MoodBoardDashboard;
