import * as types from "../actions/actionTypes";

const meDefault = { userLoading: false },
  loginErrorCountDefault = 1;

const defaultInitilization = {
  me: meDefault,
  workSpaceProducts: [],
  loginErrorCount: loginErrorCountDefault,
};
export default function userReducer(state = defaultInitilization, action) {
  switch (action.type) {
    case types.USER_LOGIN:
      return { ...state, user: action.payload };
    case types.USER_LOGOUT:
      return { ...defaultInitilization };
    case types.AGENCY_INFO:
      return { ...state, agency: action.payload };
    case types.DASHBOARD_REFRESHED:
      return { ...state, dashboard: action.payload };
    case types.ME_DATA:
      return { ...state, me: action.payload };
    case types.WORKSPACE_PRODUCTS_DATA:
      return { ...state, workSpaceProducts: action.payload };
    case types.WORKSPACE_PRODUCTS_DATA_CLEAR:
      return { ...state, workSpaceProducts: [] };
    case types.USER_LOGIN_ERROR_COUNT:
      return { ...state, loginErrorCount: action.payload };
    case types.USER_LOGIN_ERROR_COUNT_RESET:
      return { ...state, loginErrorCount: loginErrorCountDefault };
    default:
      return state;
  }
}
