import useApiToStoreQuery from "./useApiToStoreQuery";
import { GQL_DMS_GET_DNS } from "shared/gql/gqlSchema/dmsGql";

export default function useDmsApis() {
  const {
    apiCall: dmsGetDnsFetch = () => {},
    refetch: dmsGetDnsRefetch,
    setDataSource: dmsGetDnsSetDataSource,
  } = useApiToStoreQuery({
    api: GQL_DMS_GET_DNS,
    elementKey: "dms_get_dns",
    storeKey: "dmsGetDns",
    newResponse: true,
  });

  return {
    dmsGetDnsFetch,
    dmsGetDnsRefetch,
    dmsGetDnsSetDataSource,
  };
}
