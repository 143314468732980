import { UNICORN_STATUS_SELECTION } from "actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";

export default function useUnicornReducerStatusSetter() {
  const dispatch = useDispatch();
  const { selectedUnicornStatus } = useSelector((state) => state?.unicornReducer);
  function checkUnicornStatus() {
    const isStatusString = typeof selectedUnicornStatus === "string";
    if (isStatusString) {
      dispatch({
        type: UNICORN_STATUS_SELECTION,
        payload: [],
      });
    }
  }

  return { checkUnicornStatus };
}
