import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import LookTable from "components/LookTable";
import useColumn from "./useColumn";
import { useGoogleLaunchV1Context } from "components/Launch/GoogleLaunchV1/Context";
import { useMemo, useState } from "react";
import LookButton from "components/LookButton";
import { useLookDrawer } from "components/LookDrawer";
import AdForm from "./AdForm";

export default function AdListing(props) {
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;
  const drawerType = "Adgroup & Ads";
  const [editIndex, setEdiIndex] = useState(-1);
  const {
    drawer: ad_drawer,
    setTextBreadcrumb: ad_setTextBreadcrumb,
    openDrawer: ad_openDrawer,
    closeDrawer: ad_closeDrawer,
    visibleDrawer: ad_visibleDrawer,
  } = useLookDrawer({ className: "no-body-space", size: 636, onClose: () => setEdiIndex(-1) });

  const { serviceName, campaignObject = [] } = useGoogleLaunchV1Context();

  const createNewAd = () => {
    ad_setTextBreadcrumb([serviceName, drawerType, "Create Ad"]);
    ad_openDrawer();
  };

  const editAd = (index) => {
    ad_setTextBreadcrumb([serviceName, drawerType, "Edit Ad"]);
    ad_openDrawer();
    setEdiIndex(index);
  };

  const { columns } = useColumn({ editAd });
  /**
   * In google launch V1 only having 1 Campaign and 1 Adgroup
   * So get ads from -> campaignObject?.[0]?.adGroups?.[0]
   */
  const adsData = campaignObject?.[0]?.adGroups?.[0]?.ads;

  const dataSource = useMemo(() => {
    let tempData = [];

    if (adsData?.length > 0) {
      /**
       * here manipulate and make adList data from adsData
       */
      adsData?.forEach((data, index) => {
        const { headlines = [], descriptions = [], finalUrls = "" } = data;

        tempData.push({
          key: index,
          index,
          headlines: `${headlines?.length}/15`,
          desctiption: `${descriptions?.length}/04`,
          finalUrls,
        });
      });
    }
    return tempData;
  }, [adsData]);

  const tableProps = { initialColumns: columns, recordData: dataSource };

  const renderHeaderContent = () => {
    /**
     * renderHeaderContent
     * here show ad title
     * and Create new Ad action
     */

    return (
      <div className="flex justify-content-between">
        <div>
          <h3>Ads</h3>
          <p>Here you can add and manage Ads</p>
        </div>
        <div>
          <LookButton iconType="plus" onClick={createNewAd}>
            Create new Ad
          </LookButton>
        </div>
      </div>
    );
  };
  return (
    <>
      {ad_drawer(
        ad_visibleDrawer && (
          <>
            <AdForm close={ad_closeDrawer} {...{ adsData, editIndex }} />
          </>
        )
      )}
      <div className="px-20">{renderHeaderContent()}</div>
      <LookTable
        isSrNo
        isTableFilter={false}
        sizes={{ reduceExtraScrollHeightBy: 312 }}
        tableProps={tableProps}
        persistKey={persistKey}
        isRowSelection={false}
      />
    </>
  );
}
