import { MoreOutlined } from "@ant-design/icons";
import { Checkbox, Col, Image, Row, Tooltip, Select, Badge } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookButton from "components/LookButton";
import { LookConfirmationModal } from "components/LookConfirmationModal";
import LookDrawer from "components/LookDrawer";
import LookModal from "components/LookModal";
import Loader from "components/loader";
import PopoverWrapper from "components/popoverWrapper";
import cx from "cx";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React, { useEffect, useState } from "react";
import { GQL_CREATIVE_SIMILAR_VIDEO, GQL_MERGE_CREATIVES } from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";
function MediaItem(props) {
  const {
    selected = false,
    onSelect,
    s3Url = "",
    thumbUrl,
    name = "",
    similarityPercentage = 0,
    original = false,
    current = false,
  } = props;

  const isSelection = { onClick: onSelect };

  const [visible, setVisible] = useState(false);

  const [playModal, setPlayModal] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const videoHoverContent = () => (
    <>
      <div className="image-content-details  w-100">
        <div className="d-flex justify-content-between  w-100">
          <div className="mr-15">
            <Checkbox onClick={onSelect} checked={selected} />
          </div>
        </div>
        <LookButton
          className="border-0 m-0 position-absolute-center"
          block
          tooltipTitle="Play video"
          iconType="playButton"
          iconTypeProps={{ style: { fontSize: 30 } }}
          onClick={() => setPlayModal(true)}
        />
      </div>

      <div className="file-name d-flex align-items-center justify-content-between w-100">
        <div>
          <Tooltip title={name} className="text-ellipsis">
            {name}
          </Tooltip>
          <p>{similarityPercentage} % similar of Original video</p>
        </div>
        <Tooltip title={name} className="mr-20">
          <ActionIconRender iconType="info-circle" />
        </Tooltip>
      </div>
    </>
  );
  return (
    <>
      <LookDrawer size={700} visible={visible} onClose={onClose} textBreadcrumb={["Thumb"]}>
        {visible && (
          <div>
            <div className="mx-auto mb-22" style={{ maxWidth: 400 }}>
              {thumbUrl && (
                <div className="d-table mt-22 mx-auto">
                  <Image
                    style={{ maxHeight: 300, objectFit: "contain" }}
                    src={thumbUrl}
                    preview={false}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </LookDrawer>
      {playModal && (
        <LookModal
          title="Play Video"
          visible={playModal}
          onCancel={() => setPlayModal(false)}
          footer={false}
        >
          {playModal && <video height={"300px"} className="w-100" src={s3Url} controls autoPlay />}
        </LookModal>
      )}
      {(original || current) && (
        <Badge.Ribbon
          text={original ? "Original" : "Current"}
          color={original ? "green" : "volcano"}
        >
          <div className="image-hover-action-wrapper no-hover">
            <div
              className={cx(
                "creative-image-selection borderRadius-12 border-0 ant-image-ration-1-1",
                {
                  selected,
                }
              )}
              {...isSelection}
            >
              <div className="position-relative w-100 paddingTop-70">
                {thumbUrl ? (
                  <>
                    <img
                      className="position-absolute w-100 h-100 img-video-placement"
                      src={thumbUrl}
                      alt="no img"
                    />
                  </>
                ) : (
                  <video
                    className="position-absolute w-100 h-100 img-video-placement"
                    src={s3Url}
                  />
                )}
              </div>
            </div>

            <div className="creative-adgroup-card  video-card">
              <div className={cx("hover-action", { active: false })}>
                {/* video hover details  */}
                {videoHoverContent()}
                {/* end */}
              </div>
            </div>
          </div>
        </Badge.Ribbon>
      )}
    </>
  );
}
export default function SimilarImages(props) {
  const { videoId = "" } = props;
  const [creativeImages, setCreativesImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [
    fetchVideos,
    { data: videos_data, loading: creativesVideo_loading, refetch: creatiVideo_refetch },
  ] = useCustomLazyQuery(GQL_CREATIVE_SIMILAR_VIDEO, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ct_getSimilarVideos?.data;

      if (element) {
        setCreativesImages(
          element?.map((data) => {
            const { video, ...propswithoutVideo } = data;
            return { ...propswithoutVideo, ...video };
          })
        );
        setTags([]);
      }
    },
    variables: { videoId },
  });
  const [mergeVideos, { loading: creativesMerge_loading, refetch: creativesMerge_refetch }] =
    useCustomMutation(GQL_MERGE_CREATIVES, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ct_getSimilarVideos?.data;

        if (element) {
          setSelectedImages([]);
          creatiVideo_refetch();
        } else {
          openNotification({ type: "error", message: e?.ct_getSimilarVideos?.message });
        }
      },
    });
  useEffect(() => {
    fetchVideos();
  }, []);
  const handleChange = (newValue) => {
    setTags(newValue);
  };
  const hanleConfirmationOk = () => {
    const videos = [
      ...new Set([...selectedImages, videos_data?.ct_getSimilarVideos?.originalVideo?.id]),
    ];

    mergeVideos({
      variables: {
        videoIds: videos,
        forceMerge: false,
      },
    });
  };

  const confirmationModalProps = {
    visible: confirmationModal,
    confirmationProps: {
      oKText: "Yes",
      title: "Merge Video",
      description: `Merging these videos with the original will combine all interactions and statistics. The duplicate will be deleted permanently.
       Are you sure you want to proceed ?`,

      buttonProps: {
        handleOk: hanleConfirmationOk,
        handelCancel: () => {
          setConfirmationModal(false);
          setSelectedImages([]);
        },
      },
    },
  };

  //for Future use

  // const [dateRange, setRangeDates] = useState([]);
  // const handleDateRange = (e) => {
  //   setRangeDates(e.rangeDates);
  // };
  // const dateRangeProps = {
  //   noStore: true,
  //   disabledTimeZone: true,
  //   onChange: handleDateRange,
  //   isTimezone: false,
  // };
  return (
    <div>
      <LookConfirmationModal {...confirmationModalProps} />
      {/* code here */}
      <div
        className="d-flex 
       mt-20 mr-10"
      >
        <div className="d-flex align-items-center  justify-content-between  w-100 px-20 py-20">
          <div className="d-flex">
            <Tooltip title="Click search button to search">
              <ActionIconRender
                iconType="info-circle"
                className="mr-10 d-flex align-items-center"
              />
            </Tooltip>
            <div
              className="look-table-search flex"
              style={{
                border: "1px solid #404040",
              }}
            >
              <Select
                mode="tags"
                bordered={false}
                placeholder="Search by tags"
                style={{ width: "200px" }}
                onChange={handleChange}
                maxTagCount="responsive"
              />
              <LookButton
                tooltipTitle="Click here to search"
                className="filter-btn ml-8"
                iconType="search"
                onClick={() => {
                  fetchVideos({
                    variables: {
                      tags,
                    },
                  });
                }}
              />
            </div>
          </div>
          {selectedImages?.length > 0 && (
            <LookButton
              className="ml-10 icon-rotate-90"
              iconType="vertical"
              onClick={() => {
                setConfirmationModal(true);
              }}
            >
              Manual Merge
            </LookButton>
          )}
        </div>
        {/* for future use */}
        {/* <DateRangePersist {...dateRangeProps} />
        <LookButton
          tooltipTitle="Refetch"
          className="filter-btn ml-8"
          size="small"
          iconType="reload"
          disabled={loading}
          iconTypeProps={{ spin: loading }}
          onClick={() => refetch()}
        /> */}
      </div>

      <Row gutter={12} className="p-20">
        {creativesVideo_loading ? (
          <Loader elementCenter />
        ) : (
          creativeImages?.map((data, index) => {
            const selectedImg = selectedImages?.includes(data?.id);
            const video = videos_data?.ct_getSimilarVideos;
            const originalVideo = video?.originalVideo?.id === data?.id;
            const currentVideo = video?.currentVideo?.id === data?.id;
            return (
              <Col span={6} key={"mediaItems" + index} xs={12} md={8} xl={6} sm={4}>
                <MediaItem
                  videoId={data?.id}
                  thumbUrl={data?.currentThumb?.r2Url}
                  s3Url={data?.r2Url}
                  name={data?.name}
                  similarityPercentage={data?.similarityPercentage}
                  index={index}
                  original={originalVideo}
                  current={currentVideo}
                  onSelect={() =>
                    setSelectedImages((selectedImg) => {
                      if (selectedImg?.includes(data?.id)) {
                        return selectedImg?.filter((id) => id !== data?.id);
                      } else {
                        return [...selectedImg, data?.id];
                      }
                    })
                  }
                  selected={selectedImg}
                />
              </Col>
            );
          })
        )}
      </Row>
    </div>
  );
}
