import { gql } from "@apollo/client";

const CREATIVE_TEMPLATE_RESPONSE = gql`
  fragment ctCreativeTemplates on ctCreativeTemplates {
    categoryId {
      name
      productCategoryId
      productId
    }
    user {
      id
      name
    }

    id
    r2Url
    templateDetails
    templateType
    templateThumb
    name
    creativeCount
  }
`;

const CREATIVE_REQUEST_RESPONSE = gql`
  fragment ctCreativeRequests on ctCreativeRequests {
    advertiser
    assignTo
    category {
      name
      productCategoryId
      productId
    }
    requestStatus
    createdDate
    expectedDueDate
    id
    createdBy
    offer
    priority
    requestDetails
    templateIds
    updatedDate
  }
`;

const MOODBOARD_SCRIPT_RESPONSE = gql`
  fragment ctScript on ctScript {
    angles {
      createdAt
      description
      id
      name
      updatedAt
      tags {
        id
        name
      }
    }
    copyPoints {
      createdAt
      id
      name
      updatedAt
      tags {
        id
        name
      }
    }
    createdAt
    ctas {
      createdAt
      description
      id
      name
      updatedAt
      tags {
        id
        name
      }
    }
    description
    hooks {
      createdAt
      description
      id
      name
      updatedAt
      tags {
        id
        name
      }
    }
    id
    language {
      code
      id
      name
    }
    name
    painPoints {
      createdAt
      description
      id
      name
      updatedAt
      tags {
        id
        name
      }
    }
    tags {
      name
      id
    }
    updatedAt
  }
`;

export const GET_CREATIVES_BY_USERS = gql`
  query clCreativeUserMedia {
    clCreativeUserMedia {
      creativesPresent {
        id
        s3Url
      }
      name
      user
    }
  }
`;
export const GET_AD_BY_USERS = gql`
  query clGetAdIds($creativeIds: [CreativeMediaIds]!, $platformType: PLATFORM_TYPE!) {
    clGetAdIds(creativeIds: $creativeIds, platformType: $platformType) {
      ads
      creativeId
    }
  }
`;
// export const GET_AD_BY_USERS = gql`
//   query clGetAdIds($creativeIds: [CreativeMediaIds]!, $platformType: PLATFORM_TYPE!) {
//     clGetAdIds(creativeIds: $creativeIds, platformType: $platformType) {
//       ads
//       creativeId
//     }
//   }
// `;
export const GET_CREATIVE_REPORTS = gql`
  query clCreativeSpendRevenue(
    $platformIds: [ID]
    $productIds: [ID]
    $creativeIds: [CreativeMediaIds]
    $from_date: Datetime
    $to_date: Datetime
  ) {
    clCreativeSpendRevenue(
      platformIds: $platformIds
      productIds: $productIds
      creativeIds: $creativeIds
      from_date: $from_date
      to_date: $to_date
    )
  }
`;

export const GET_MOODBOARD_ANGLES = gql`
  query ctGetAngles(
    $categoryId: ID!
    $productId: ID!
    $ids: [ID]
    $order: ORDER_TYPE
    $fromDate: String
    $column: String
    $page: Int
    $search: String
    $tags: [String]
    $size: Int
    $toDate: String
  ) {
    ctGetAngles(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      order: $order
      fromDate: $fromDate
      column: $column
      page: $page
      search: $search
      tags: $tags
      size: $size
      toDate: $toDate
    ) {
      data {
        createdAt
        description
        id
        name
        updatedAt
        tags {
          id
          name
        }
      }
      message
      status
      pageInfo {
        totalPages
        totalRecords
      }
    }
  }
`;

// export const GET_MOODBOARD_COMPLIANCE = gql`
//   ${MOODBOARD_RESPONSE}
//   query ctGetAngles(_ids:[ID], $categoryId: ID!, $name: String) {
//     ctGetAngles(_ids: $_ids, categoryId: $categoryId, name: $name) {
//       category {
//         ...ctCategory
//       }
//       id
//       name
//       description
//       updatedAt
//       createdAt
//     }
//   }
// `;

export const GET_MOODBOARD_CTA = gql`
  query ctGetCTAs(
    $categoryId: ID!
    $productId: ID!
    $ids: [ID]
    $column: String
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetCTAs(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      column: $column
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      data {
        createdAt
        description
        id
        name
        updatedAt
        tags {
          id
          name
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;

export const GET_MOODBOARD_COPY_POINTS = gql`
  query ctGetCopyPoints(
    $categoryId: ID!
    $productId: ID!
    $ids: [ID]
    $column: String
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetCopyPoints(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      column: $column
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      data {
        createdAt
        id
        name
        updatedAt
        tags {
          id
          name
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;

export const GET_MOODBOARD_SCRIPTS = gql`
  ${MOODBOARD_SCRIPT_RESPONSE}
  query ctGetScripts(
    $categoryId: ID!
    $productId: ID!
    $ids: [ID]
    $column: String
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetScripts(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      column: $column
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      data {
        ...ctScript
      }
    }
  }
`;

export const GET_MOODBOARD_HOOKS = gql`
  query ctGetHooks(
    $categoryId: ID!
    $productId: ID!
    $ids: [ID]
    $column: String
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetHooks(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      column: $column
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      data {
        createdAt
        description
        id
        name
        updatedAt
        tags {
          id
          name
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;

export const GET_MOODBOARD_COMPLIANCE = gql`
  query CtGetCompliance($categoryId: ID!, $productId: ID!) {
    ctGetCompliance(categoryId: $categoryId, productId: $productId) {
      data {
        createdAt
        description
        documents
        updatedAt
      }
      message
      status
    }
  }
`;
export const DELETE_CREATIVE_REQUESTS = gql`
  mutation ctDeleteRequests($requestIds: [ID]!) {
    ctDeleteRequests(requestIds: $requestIds) {
      message
      status
    }
  }
`;

export const GET_MOODBOARD_PAIN_POINTS = gql`
  query ctGetPainPoints(
    $categoryId: ID!
    $productId: ID!
    $ids: [ID]
    $column: String
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
  ) {
    ctGetPainPoints(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      column: $column
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
    ) {
      data {
        createdAt
        description
        id
        name
        updatedAt
        tags {
          id
          name
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;

export const ADD_MOODBOARD_COPY_POINTS = gql`
  mutation ctAddCopyPoint(
    $_id: ID
    $categoryId: ID!
    $name: String!
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddCopyPoint(
      _id: $_id
      categoryId: $categoryId
      name: $name
      categoryName: $categoryName
      productId: $productId
    ) {
      data {
        id
        name
        updatedAt
        createdAt
        tags {
          id
          name
        }
      }
      message
      status
    }
  }
`;

export const ADD_MOODBOARD_ANGLES = gql`
  mutation ctAddAngle(
    $_id: ID
    $categoryId: ID!
    $name: String!
    $description: String
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddAngle(
      _id: $_id
      categoryId: $categoryId
      name: $name
      description: $description
      categoryName: $categoryName
      productId: $productId
    ) {
      data {
        id
        name
        updatedAt
        createdAt
        tags {
          id
          name
        }
      }
      message
      status
    }
  }
`;
export const ADD_MOODBOARD_COMPLIANCE = gql`
  mutation ctAddCompliance(
    $categoryId: ID!
    $description: String
    $document: [Document]
    $productId: ID!
    $categoryName: String!
  ) {
    ctAddCompliance(
      categoryId: $categoryId
      description: $description
      document: $document
      productId: $productId
      categoryName: $categoryName
    ) {
      data {
        createdAt
        description
        documents
        updatedAt
      }
      message
      status
    }
  }
`;
export const ADD_MOODBOARD_HOOKS = gql`
  mutation ctAddHook(
    $_id: ID
    $categoryId: ID!
    $name: String!
    $description: String
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddHook(
      _id: $_id
      categoryId: $categoryId
      name: $name
      description: $description
      categoryName: $categoryName
      productId: $productId
    ) {
      data {
        id
        name
        updatedAt
        createdAt
        tags {
          id
          name
        }
      }
      message
      status
    }
  }
`;

export const ADD_MOODBOARD_SCRIPTS = gql`
  ${MOODBOARD_SCRIPT_RESPONSE}
  mutation ctAddScript(
    $_id: ID
    $categoryId: ID!
    $categoryName: String!
    $angles: [ID]
    $copyPoints: [ID]
    $ctas: [ID]
    $description: String
    $hooks: [ID]
    $language: String!
    $name: String!
    $painPoints: [ID]
    $productId: ID!
  ) {
    ctAddScript(
      _id: $_id
      categoryId: $categoryId
      angles: $angles
      description: $description
      copyPoints: $copyPoints
      ctas: $ctas
      hooks: $hooks
      language: $language
      name: $name
      painPoints: $painPoints
      categoryName: $categoryName
      productId: $productId
    ) {
      data {
        ...ctScript
      }
      message
      status
    }
  }
`;
export const ADD_MOODBOARD_CTA = gql`
  mutation ctAddCTA(
    $_id: ID
    $categoryId: ID!
    $name: String!
    $description: String
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddCTA(
      _id: $_id
      categoryId: $categoryId
      name: $name
      description: $description
      categoryName: $categoryName
      productId: $productId
    ) {
      data {
        id
        name
        updatedAt
        createdAt
        tags {
          id
          name
        }
      }
      message
      status
    }
  }
`;
export const ADD_MOODBOARD_PAIN_POINTS = gql`
  mutation ctAddPainPoint(
    $_id: ID
    $categoryId: ID!
    $name: String!
    $description: String
    $categoryName: String!
    $productId: ID!
  ) {
    ctAddPainPoint(
      _id: $_id
      categoryId: $categoryId
      name: $name
      description: $description
      categoryName: $categoryName
      productId: $productId
    ) {
      data {
        id
        name
        updatedAt
        createdAt
        tags {
          id
          name
        }
      }
      message
      status
    }
  }
`;

export const ADD_OFFER = gql`
  mutation CtAddOffer(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $advertiser: String
    $notes: String
    $offerLink: String
    $offerName: String!
  ) {
    ctAddOffer(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      advertiser: $advertiser
      notes: $notes
      offerLink: $offerLink
      offerName: $offerName
    ) {
      data {
        advertiser
        createdBy
        createdDate
        name
        updatedDate
      }
      message
      status
    }
  }
`;
export const DELETE_MOODBOARD_CTA = gql`
  mutation CtDeleteCTA($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteCTA(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data {
        createdAt
        description
        id
        name
        updatedAt
      }
      message
      status
    }
  }
`;

export const DELETE_MOODBOARD_HOOKS = gql`
  mutation CtDeleteHook($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteHook(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data {
        createdAt
        description
        id
        name
        updatedAt
      }
      message
      status
    }
  }
`;

export const DELETE_MOODBOARD_ANGLE = gql`
  mutation CtDeleteAngle($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteAngle(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data {
        createdAt
        description
        id
        name
        updatedAt
      }
      message
      status
    }
  }
`;
export const DELETE_MOODBOARD_SCRIPTS = gql`
  mutation CtDeleteScript($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteScript(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      message
      status
      data {
        id
        name
      }
    }
  }
`;

export const DELETE_MOODBOARD_COPYPOINT = gql`
  mutation CtDeleteCopyPoint($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteCopyPoint(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data {
        createdAt
        id
        name
        updatedAt
      }
      message
      status
    }
  }
`;
export const DELETE_MOODBOARD_PAINPOINT = gql`
  mutation CtDeletePainPoint($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeletePainPoint(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data {
        createdAt
        description
        id
        name
        updatedAt
      }
      message
      status
    }
  }
`;

export const DELETE_MOODBOARD_SCRIPT_ELEMENT = gql`
  mutation CtDeleteScriptElement($_ids: ID!, $categoryId: ID!, $productId: ID!) {
    ctDeleteScriptElement(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data {
        id
        name
        description
      }
      message
      status
    }
  }
`;
export const GET_MOODBOARD_DASHBOARD_OVERVIEW = gql`
  query ctGetMoodBoardOverview($categoryId: ID!, $productId: ID!) {
    ctGetMoodBoardOverview(categoryId: $categoryId, productId: $productId) {
      data {
        angle {
          totalCount
          trendingAngles {
            createdAt
            description
            id
            name
            updatedAt
          }
        }
        hook {
          totalCount
          trendingHooks {
            createdAt
            description
            id
            name
            updatedAt
          }
        }
        compliance {
          createdAt
          description
          documents
          updatedAt
        }
        copyPoint {
          totalCount
          trendingCopyPoints {
            createdAt
            id
            name
            updatedAt
          }
        }
        cta {
          totalCount
          trendingCtas {
            createdAt
            description
            id
            name
            updatedAt
          }
        }
        script {
          totalCount
        }
        painPoint {
          totalCount
          trendingPainPoints {
            createdAt
            description
            id
            name
            updatedAt
          }
        }
      }
      message
      status
    }
  }
`;

export const FETCH_REQUEST_TRACKING = gql`
  ${CREATIVE_REQUEST_RESPONSE}
  query ctGetCreativeRequests(
    $createdByMe: Boolean
    $endDate: String
    $requestId: ID
    $startDate: String
    $requestType: REQUEST_TYPE
  ) {
    ctGetCreativeRequests(
      createdByMe: $createdByMe
      endDate: $endDate
      requestId: $requestId
      startDate: $startDate
      requestType: $requestType
    ) {
      message
      status
      data {
        ...ctCreativeRequests
      }
    }
  }
`;
export const FETCH_TEMPLATE_TRACKING = gql`
  ${CREATIVE_TEMPLATE_RESPONSE}
  query ctGetCreativeTemplates(
    $endDate: String
    $startDate: String
    $categoryId: ID!
    $productId: ID!
    $templateId: ID
  ) {
    ctGetCreativeTemplates(
      endDate: $endDate
      startDate: $startDate
      productId: $productId
      categoryId: $categoryId
      templateId: $templateId
    ) {
      message
      status
      data {
        ...ctCreativeTemplates
      }
    }
  }
`;
export const ADD_TEMPLATE_TRACKING = gql`
  ${CREATIVE_TEMPLATE_RESPONSE}
  mutation ctAddCreativeTemplates(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $templateUrls: [Template]!
  ) {
    ctAddCreativeTemplates(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      templateUrls: $templateUrls
    ) {
      data {
        ...ctCreativeTemplates
      }
      message
      status
    }
  }
`;
export const CREATE_REQUEST_TRACKING = gql`
  ${CREATIVE_REQUEST_RESPONSE}
  mutation ctAddRequest($requestInput: RequestInput!) {
    ctAddRequest(requestInput: $requestInput) {
      data {
        ...ctCreativeRequests
      }
      message
      status
    }
  }
`;
export const UPDATE_IMAGE_COMPLANCE = gql`
  mutation ctUpdateImageCompliance(
    $categoryId: ID!
    $complianceStatus: COMPLIANCE_STATUS!
    $imageIds: [String]!
    $notes: String
    $productId: ID!
  ) {
    ctUpdateImageCompliance(
      categoryId: $categoryId
      complianceStatus: $complianceStatus
      imageIds: $imageIds
      notes: $notes
      productId: $productId
    ) {
      data {
        categoryId {
          productCategoryId
          productId
          name
        }
        complianceStatus
        image {
          id
          r2Url
        }
      }
      message
      status
    }
  }
`;
export const ADD_CT_TAGS = gql`
  mutation ctAddTags($creativeIds: [ID]!, $creativeType: CREATIVE_TYPE!, $tags: [String]!) {
    ctAddTags(creativeIds: $creativeIds, tags: $tags, creativeType: $creativeType) {
      data
      message
      status
    }
  }
`;
export const UPDATE_VIDEO_COMPLANCE = gql`
  mutation ctUpdateVideoCompliance(
    $categoryId: ID!
    $complianceStatus: COMPLIANCE_STATUS!
    $videoIds: [String]!
    $notes: String
    $productId: ID!
  ) {
    ctUpdateVideoCompliance(
      categoryId: $categoryId
      complianceStatus: $complianceStatus
      videoIds: $videoIds
      notes: $notes
      productId: $productId
    ) {
      data {
        complianceStatus
        video {
          id
          r2Url
        }
      }
      message
      status
    }
  }
`;
export const CREATIVE_TRACKING_STATUS_CONFIG = gql`
  query ctGetRequestStatusConfig {
    ctGetRequestStatusConfig
  }
`;

export const CREATIVE_BUILDER_UPLOAD_TEMPLATE = gql`
  ${CREATIVE_TEMPLATE_RESPONSE}
  mutation ctAddCreativeTemplates(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $templateUrls: [Template]!
  ) {
    ctAddCreativeTemplates(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      templateUrls: $templateUrls
    ) {
      message
      status
      data {
        ...ctCreativeTemplates
      }
    }
  }
`;
export const CREATIVE_BUILDER_FETCH_TEMPLATES = gql`
  ${CREATIVE_TEMPLATE_RESPONSE}
  query ctGetCreativeTemplates(
    $categoryId: ID
    $endDate: String
    $productId: ID
    $startDate: String
    $templateId: ID
  ) {
    ctGetCreativeTemplates(
      categoryId: $categoryId
      templateId: $templateId
      productId: $productId
      endDate: $endDate
      startDate: $startDate
    ) {
      message
      status
      data {
        ...ctCreativeTemplates
      }
    }
  }
`;

export const CREATIVE_REQUEST_UPDATE_STATUS = gql`
  mutation ctBulkChangeStatus($requestIds: [ID], $status: REQUEST_STATUS) {
    ctBulkChangeStatus(requestIds: $requestIds, status: $status) {
      message
      status
    }
  }
`;

export const MEDIA_ADD_AUDIO_CLIP = gql`
  mutation CtAddAudioClip(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $r2Urls: [AudioClipInput]!
  ) {
    ctAddAudioClip(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      r2Urls: $r2Urls
    ) {
      data {
        id
        r2Url
        name
      }
      message
      status
    }
  }
`;

export const MEDIA_ADD_IMAGE_CLIP = gql`
  mutation CtAddImageClip(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $r2Urls: [ImageClipInput]!
    $id: ID
  ) {
    ctAddImageClip(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      r2Urls: $r2Urls
      _id: $id
    ) {
      data {
        id
        r2Url
      }
      message
      status
    }
  }
`;

//new api for media image upload
export const MEDIA_ADD_CREATIVE_IMAGE = gql`
  mutation CtAddCreativeImages(
    $categoryId: ID!
    $categoryName: String!
    $images: [ctImageInputObject]!
    $productId: ID!
  ) {
    ctAddCreativeImages(
      categoryId: $categoryId
      categoryName: $categoryName
      images: $images
      productId: $productId
    ) {
      data {
        addedCreatives {
          id
          r2Url
        }
        alreadyPresentCreatives {
          id
          r2Url
        }
        failedToAddCreatives
      }
      message
      status
    }
  }
`;
export const MEDIA_ADD_CREATIVE_VIDEO = gql`
  mutation ctAddCreativeVideos(
    $categoryId: ID!
    $categoryName: String!
    $videos: [VideoObject]!
    $productId: ID!
  ) {
    ctAddCreativeVideos(
      categoryId: $categoryId
      categoryName: $categoryName
      videos: $videos
      productId: $productId
    ) {
      data {
        currentThumb {
          id
          r2Url
          tags {
            id
            name
            tagColor
            universal
          }
        }
        id
        r2Url
        tags {
          id
          name
          tagColor
          universal
        }
        allThumbs {
          id
          r2Url
          tags {
            id
            name
            tagColor
            universal
          }
        }
      }
      message
      status
    }
  }
`;

export const MEDIA_ADD_VIDEO_CLIP = gql`
  mutation CtAddVideoClip(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $r2Urls: [VideoClipInput]!
    $currentThumb: ID
    $id: ID
  ) {
    ctAddVideoClip(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      r2Urls: $r2Urls
      currentThumb: $currentThumb
      _id: $id
    ) {
      data {
        id
        r2Url
        currentThumb {
          id
          r2Url
        }
      }
      message
      status
    }
  }
`;

// export const MEDIA_GET_AUDIO_CLIPS = gql`
//   query CtGetAudioClips($categoryId: ID!, $productId: ID!, $ids: [ID], $createdByMe: Boolean) {
//     ctGetAudioClips(
//       categoryId: $categoryId
//       productId: $productId
//       _ids: $ids
//       createdByMe: $createdByMe
//     ) {
//       data {
//         id
//         name
//         r2Url
//       }
//       message
//       status
//     }
//   }
// `;
export const MEDIA_GET_AUDIO_CLIPS = gql`
  query ctGetAudioClips(
    $productId: ID
    $ids: [ID]
    $categoryId: ID
    $column: String
    $createdByMe: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
    $isGlobal: Boolean
  ) {
    ctGetAudioClips(
      productId: $productId
      _ids: $ids
      categoryId: $categoryId
      column: $column
      createdByMe: $createdByMe
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
      isGlobal: $isGlobal
    ) {
      data {
        id
        name
        # categoryId {
        #   productId
        #   createdAt
        #   name
        #   productCategoryId
        # }
        createdAt
        r2Url
        tags {
          id
          name
        }
        categoryId {
          productCategoryId
          productId
          name
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;

// export const MEDIA_GET_VIDEO_CLIPS = gql`
//   query CtGetVideoClips($categoryId: ID!, $productId: ID!, $ids: [ID]) {
//     ctGetVideoClips(categoryId: $categoryId, productId: $productId, _ids: $ids) {
//       data {
//         id
//         r2Url
//         current_thumb {
//           id
//           r2Url
//         }
//       }
//       message
//       status
//     }
//   }
// `;
export const MEDIA_GET_VIDEO_CLIPS = gql`
  query ctGetVideoClips(
    $productId: ID
    $ids: [ID]
    $categoryId: ID
    $column: String
    $createdByMe: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
    $isGlobal: Boolean
  ) {
    ctGetVideoClips(
      productId: $productId
      _ids: $ids
      categoryId: $categoryId
      column: $column
      createdByMe: $createdByMe
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
      isGlobal: $isGlobal
    ) {
      data {
        id
        r2Url
        name
        currentThumb {
          id
          r2Url
        }
        tags {
          id
          name
        }
        categoryId {
          productCategoryId
          productId
          name
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;
// export const MEDIA_GET_IMAGE_CLIPS = gql`
//   query CtGetImageClips($categoryId: ID!, $productId: ID!, $ids: [ID], $createdByMe: Boolean) {
//     ctGetImageClips(
//       categoryId: $categoryId
//       productId: $productId
//       _ids: $ids
//       createdByMe: $createdByMe
//     ) {
//       data {
//         id
//         r2Url
//       }
//       message
//       status
//     }
//   }
// `;
export const MEDIA_GET_IMAGE_CLIPS = gql`
  query ctGetImageClips(
    $categoryId: ID
    $productId: ID
    $ids: [ID]
    $column: String
    $createdByMe: Boolean
    $fromDate: String
    $page: Int
    $order: ORDER_TYPE
    $search: String
    $size: Int
    $toDate: String
    $tags: [String]
    $isGlobal: Boolean
  ) {
    ctGetImageClips(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      column: $column
      createdByMe: $createdByMe
      fromDate: $fromDate
      page: $page
      order: $order
      search: $search
      size: $size
      toDate: $toDate
      tags: $tags
      isGlobal: $isGlobal
    ) {
      data {
        id
        r2Url
        name
        createdAt
        tags {
          id
          name
        }
        categoryId {
          productCategoryId
          productId
          name
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }

      status
    }
  }
`;

export const CREATE_MEDIA_FROM_TEMPLATE = gql`
  mutation CtCreateMediaFromTemplate(
    $mediaType: MEDIA_TYPE!
    $modifications: [JSON]!
    $templateId: ID
    $productId: ID
    $categoryId: ID
  ) {
    ctCreateMediaFromTemplate(
      mediaType: $mediaType
      modifications: $modifications
      templateId: $templateId
      productId: $productId
      categoryId: $categoryId
    ) {
      data {
        mediaId
        mediaUrl
        modification
        status
      }
      status
      message
    }
  }
`;
export const MEDIA_DELETE_IMAGE_CLIPS = gql`
  mutation CtDeleteImageClip($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteImageClip(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data
      message
      status
    }
  }
`;
export const MEDIA_DELETE_VIDEO_CLIPS = gql`
  mutation CtDeleteVideoClip($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteVideoClip(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data
      message
      status
    }
  }
`;
export const MEDIA_DELETE_AUDIO_CLIPS = gql`
  mutation CtDeleteAudioClip($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteAudioClip(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data
      message
      status
    }
  }
`;

export const MEDIA_ADD_TEXT = gql`
  mutation ctAddText(
    $categoryId: ID!
    $categoryName: String!
    $productId: ID!
    $texts: [String]!
    $_id: ID
  ) {
    ctAddText(
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
      texts: $texts
      _id: $_id
    ) {
      data {
        id
        name
        createdAt
        createdBy
      }
      message
      status
    }
  }
`;

// export const MEDIA_GET_TEXT = gql`
//   query ctGetTexts($categoryId: ID!, $productId: ID!, $_ids: [ID], $createdByMe: Boolean) {
//     ctGetTexts(
//       categoryId: $categoryId
//       productId: $productId
//       _ids: $_ids
//       createdByMe: $createdByMe
//     ) {
//       data {
//         id
//         name
//         createdBy
//         createdAt
//         hash
//       }
//       message
//       status
//     }
//   }
// `;
export const MEDIA_GET_TEXT = gql`
  query ctGetTexts(
    $categoryId: ID
    $productId: ID
    $ids: [ID]
    $column: String
    $createdByMe: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $search: String
    $size: Int
    $tags: [String]
    $toDate: String
    $isGlobal: Boolean
  ) {
    ctGetTexts(
      categoryId: $categoryId
      productId: $productId
      _ids: $ids
      column: $column
      createdByMe: $createdByMe
      fromDate: $fromDate
      order: $order
      page: $page
      search: $search
      size: $size
      tags: $tags
      toDate: $toDate
      isGlobal: $isGlobal
    ) {
      data {
        id
        name
        tags {
          id
          name
          tagColor
        }
        categoryId {
          productCategoryId
          productId
          name
        }
        hash
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;

export const MEDIA_DELETE_TEXT = gql`
  mutation ctDeleteText($_ids: [ID]!, $categoryId: ID!, $productId: ID!) {
    ctDeleteText(_ids: $_ids, categoryId: $categoryId, productId: $productId) {
      data
      message
      status
    }
  }
`;

export const ADD_CREATIVE_COMMENT = gql`
  mutation ctAddRequestHistory(
    $categoryId: ID!
    $comments: [String]!
    $productId: ID!
    $requestId: ID!
    $userName: String!
  ) {
    ctAddRequestHistory(
      categoryId: $categoryId
      comments: $comments
      productId: $productId
      requestId: $requestId
      userName: $userName
    ) {
      data {
        id
        details
        timestamp
        user {
          id
          name
        }
        category {
          name
        }
        creativeRequestId {
          assignTo
        }
        changeType
      }
      message
      status
    }
  }
`;

export const GET_CREATIVE_COMMENT = gql`
  query CtGetCreativeRequestHistory($categoryId: ID!, $productId: ID!, $requestId: ID!) {
    ctGetCreativeRequestHistory(
      categoryId: $categoryId
      productId: $productId
      requestId: $requestId
    ) {
      data {
        id
        details
        timestamp
        user {
          id
          name
        }
        category {
          name
        }
        creativeRequestId {
          assignTo
        }
        changeType
      }
      message
      status
    }
  }
`;

export const ADD_MEDIA_FROM_TEMPLATE = gql`
  mutation CtAddMediaFromTemplate(
    $mediaType: MEDIA_TYPE!
    $modifications: [MediaDetails]!
    $templateId: ID
    $categoryId: ID
    $productId: ID
    $categoryName: String
  ) {
    ctAddMediaFromTemplate(
      mediaType: $mediaType
      modifications: $modifications
      templateId: $templateId
      categoryId: $categoryId
      categoryName: $categoryName
      productId: $productId
    ) {
      data
      message
      status
    }
  }
`;

export const CT_DELETE_TEMPLATES = gql`
  mutation ctDeleteTemplates($templateIds: [ID]!) {
    ctDeleteTemplates(templateIds: $templateIds) {
      data
      message
      status
    }
  }
`;

export const GET_TEMPLATE_VARIATIONS_FROM_TEMPLATE = gql`
  query CtGetTemplateVariations($templateId: ID!, $createdByMe: Boolean) {
    ctGetTemplateVariations(templateId: $templateId, createdByMe: $createdByMe) {
      message
      status
      data {
        failedVariations {
          id
          creatomateUrl
        }
        inProgressVariations {
          id
          creatomateUrl
        }
        successfulVariations {
          id
          creatomateUrl
        }
      }
    }
  }
`;

export const GET_VARIATIONS_STATUS = gql`
  query CtUpdateVariationUrls($templateId: ID!, $categoryId: ID, $productId: ID) {
    ctUpdateVariationUrls(templateId: $templateId, categoryId: $categoryId, productId: $productId) {
      data {
        id
        status
        url
        modification
      }
      message
      status
    }
  }
`;
export const ADD_CREATIVE_GROUPS = gql`
  mutation CtAddCreativeGroups(
    $categoryId: ID!
    $inputs: [CreativeGroupInput]!
    $productId: ID!
    $categoryName: String
  ) {
    ctAddCreativeGroups(
      categoryId: $categoryId
      inputs: $inputs
      productId: $productId
      categoryName: $categoryName
    ) {
      data {
        addedCreatives {
          bodyId {
            body
            createdBy
            id
          }
          createdBy
          descId {
            createdBy
            description
            id
          }
          imageId {
            createdBy
            id
            r2Url
          }
          platformId {
            createdDate
            id
            name
            updatedDate
          }
          tags {
            createdBy
            createdDate
            id
            name
            tagColor
            universal
          }
          titleId {
            createdBy
            id
            title
          }
          videoId {
            allThumbs {
              id
              r2Url
            }
            createdBy
            currentThumb {
              id
              r2Url
            }
            id
            name
            r2Url
          }
        }
        alreadyPresent {
          bodyId {
            body
            createdBy
            id
          }

          createdBy
          descId {
            createdBy
            description
            id
          }
          imageId {
            createdBy
            id
            r2Url
          }
          platformId {
            createdDate
            id
            name
            updatedDate
          }
          tags {
            createdBy
            createdDate
            id
            name
            tagColor
            universal
          }
          titleId {
            createdBy
            id
            title
          }
          videoId {
            allThumbs {
              id
              r2Url
            }
            createdBy
            currentThumb {
              id
              r2Url
            }
            id
            name
            r2Url
          }
        }
        unableToAdd {
          error
          groupErrorObject {
            body_id
            description_id
            image_id
            title_id
            video_id
          }
        }
      }
      message
      status
    }
  }
`;

export const GET_CREATIVE_GROUPS = gql`
  query ctGetCreativeGroups(
    $categoryId: ID!
    $column: String
    $createdByMe: Boolean
    $featuredGroups: Boolean
    $fromDate: String
    $order: ORDER_TYPE
    $page: Int
    $productId: ID!
    $search: String
    $size: Int
    $toDate: String
    $tags: [String]
  ) {
    ctGetCreativeGroups(
      categoryId: $categoryId
      column: $column
      createdByMe: $createdByMe
      featuredGroups: $featuredGroups
      fromDate: $fromDate
      order: $order
      page: $page
      productId: $productId
      search: $search
      size: $size
      toDate: $toDate
      tags: $tags
    ) {
      data {
        compliance {
          body {
            body {
              body
              id
            }
            complianceStatus
          }
          categoryId {
            name
            productCategoryId
            productId
          }
          complianceStatus
          desc {
            complianceStatus
            description {
              description
              id
            }
          }
          image {
            complianceStatus
            image {
              id
              r2Url
            }
          }
          title {
            complianceStatus
            title {
              id
              title
            }
          }
          video {
            complianceStatus
            video {
              id
              name
              r2Url
            }
          }
        }
        group {
          bodyId {
            body
            id
          }
          descId {
            description
            id
          }
          imageId {
            id
            r2Url
          }
          platformId {
            id
            name
          }
          tags {
            id
            name
            tagColor
            universal
          }
          titleId {
            id
            title
          }
          id
          videoId {
            allThumbs {
              id
              r2Url
            }

            currentThumb {
              id
              r2Url
            }
            id
            name
            r2Url
          }
        }
      }
      message
      pageInfo {
        totalPages
        totalRecords
      }
      status
    }
  }
`;

export const DELETE_CREATIVE_GROUPS = gql`
  mutation ctDeleteCreativeGroups(
    $categoryId: ID!
    $groupObjectIds: [String]!
    $productId: ID!
    $forceDelete: Boolean!
  ) {
    ctDeleteCreativeGroups(
      categoryId: $categoryId
      groupObjectIds: $groupObjectIds
      productId: $productId
      forceDelete: $forceDelete
    ) {
      data
      message
      status
    }
  }
`;

export const GET_TIKTOK_SPEND_CREATIVE_REPORT = gql`
  query tiktok_getCreativeReport(
    $start: Date!
    $end: Date
    $group_by: creative_fetch_type
    $timezone: String
    $product_id: ID!
    $category_id: [ID!]
    $creator_id: [ID!]
    $user_id: [ID!]
    $group_id: String
    $order: order
  ) {
    tiktok_getCreativeReport(
      start: $start
      end: $end
      group_by: $group_by
      timezone: $timezone
      product_id: $product_id
      category_id: $category_id
      creator_id: $creator_id
      group_id: $group_id
      user_id: $user_id
      order: $order
    ) {
      ad_id
      category_id
      category_name
      conversion
      ct_group_id
      ct_title_id
      ct_video_id
      video_thumb_url
      average_video_play
      video_url
      impressions
      reach
      spend
      title
      clicks
      ad_name
      landing_page
    }
  }
`;
export const GET_TIKTOK_SPEND_CATEGORY_REPORT = gql`
  query tiktok_getCreativeCategoryReport(
    $start: Date!
    $end: Date
    $group_by: creative_fetch_type!
    $timezone: String
    $product_id: ID!
    $category_id: [ID!]
    $creator_id: [ID!]
    $user_id: [ID!]
    $group_id: String!
  ) {
    tiktok_getCreativeCategoryReport(
      start: $start
      end: $end
      group_by: $group_by
      timezone: $timezone
      product_id: $product_id
      category_id: $category_id
      creator_id: $creator_id
      group_id: $group_id
      user_id: $user_id
    ) {
      data {
        category_id
        category_name
        spend
      }
    }
  }
`;
export const GET_TIKTOK_SPEND_PLATFORM_REPORT = gql`
  query tiktok_getCreativePlatformReport(
    $start: Date!
    $end: Date
    $group_by: creative_fetch_type!
    $timezone: String
    $product_id: ID!
    $category_id: [ID!]
    $creator_id: [ID!]
    $user_id: [ID!]
    $group_id: String!
  ) {
    tiktok_getCreativePlatformReport(
      start: $start
      end: $end
      group_by: $group_by
      timezone: $timezone
      product_id: $product_id
      category_id: $category_id
      creator_id: $creator_id
      group_id: $group_id
      user_id: $user_id
    ) {
      data {
        spend
        platform_id
        platform_name
      }
    }
  }
`;
export const GET_TIKTOK_REVENUE_CREATIVE_REPORT = gql`
  query sasCreativeReport(
    $from: Date!
    $platformId: ID!
    $timezone: String!
    $to: Date!
    $groupBy: sasCreativeType
    $groupId: String
    $productId: ID!
    $categoryId: [ID]
    $creatorId: [ID]
    $userId: [ID]
    $type: sasReportType
  ) {
    sasCreativeReport(
      from: $from
      platformId: $platformId
      productId: $productId
      timezone: $timezone
      to: $to
      groupBy: $groupBy
      groupId: $groupId
      categoryId: $categoryId
      creatorId: $creatorId
      userId: $userId
      type: $type
    ) {
      adId
      ctGroupId
      ctImageId
      ctVideoId
      estimate_revenue
      sessions
      searches
      clicks
    }
  }
`;
export const GET_TIKTOK_REVENUE_CATEGORY_REPORT = gql`
  query sasCreativeCategoryReport(
    $from: Date!
    $timezone: String!
    $to: Date!
    $platformId: ID!
    $groupBy: sasCreativeType!
    $groupId: String!
    $productId: ID!
    $categoryId: [ID]
    $creatorId: [ID]
    $userId: [ID]
  ) {
    sasCreativeCategoryReport(
      from: $from
      productId: $productId
      timezone: $timezone
      to: $to
      groupBy: $groupBy
      groupId: $groupId
      categoryId: $categoryId
      creatorId: $creatorId
      userId: $userId
      platformId: $platformId
    ) {
      adId
      ctGroupId
      ctImageId
      ctVideoId
      categoryId
      categoryName
      estimate_revenue
      sessions
      searches
    }
  }
`;
export const GET_TIKTOK_REVENUE_PLATFORM_REPORT = gql`
  query sasCreativePlatformReport(
    $from: Date!
    $timezone: String!
    $to: Date!
    $groupBy: sasCreativeType!
    $groupId: String!
    $productId: ID!
    $categoryId: [ID]
    $creatorId: [ID]
    $userId: [ID]
  ) {
    sasCreativePlatformReport(
      from: $from
      productId: $productId
      timezone: $timezone
      to: $to
      groupBy: $groupBy
      groupId: $groupId
      categoryId: $categoryId
      creatorId: $creatorId
      userId: $userId
    ) {
      adId
      ctGroupId
      ctImageId
      ctVideoId
      estimate_revenue
      platformId
      platformName
      sessions
      searches
    }
  }
`;
export const GET_TIKTOK_REVENUE_ADACCOUNT_REPORT = gql`
  query sasCreativeAdaccountReport(
    $categoryId: [ID]
    $creatorId: [ID]
    $from: Date!
    $groupBy: sasCreativeType
    $groupId: String
    $platformId: ID!
    $productId: ID!
    $timezone: String!
    $to: Date!
    $userId: [ID]
  ) {
    sasCreativeAdaccountReport(
      from: $from
      platformId: $platformId
      productId: $productId
      timezone: $timezone
      to: $to
      groupBy: $groupBy
      groupId: $groupId
      categoryId: $categoryId
      creatorId: $creatorId
      userId: $userId
    ) {
      adAccountId
      estimate_revenue
    }
  }
`;
export const GET_CT_SOURCES = gql`
  query ctGetSource($creativeIds: [ID]!, $creativeType: MEDIA_TYPE!) {
    ctGetSource(creativeIds: $creativeIds, creativeType: $creativeType) {
      data {
        categoryId {
          name
          productCategoryId
        }
        userCount
        user {
          id
          name
        }
        id
        r2Url
        source
        platformId {
          name
        }
      }
      message
      status
    }
  }
`;
export const CT_UPDATE_USER = gql`
  mutation ctUpdateUser($creativeType: MEDIA_TYPE!, $creativeIds: [ID]!, $userId: Int!) {
    ctUpdateUser(creativeIds: $creativeIds, creativeType: $creativeType, userId: $userId) {
      data {
        id
        user {
          id
          name
        }
        source
      }
      message
      status
    }
  }
`;

export const CREATIVE_BUILDER_ELEVENLABS_GET_VOICEOVERS = gql`
  query CtGetElevenLabsVoiceOver {
    ctGetElevenLabsVoiceOver {
      code
      data {
        category
        highQualityBaseModelIds
        id
        labels
        name
        previewUrl
        voiceId
      }
      message
      status
    }
  }
`;

export const CREATIVE_BUILDER_ELEVENLABS_CREATE_VOICEOVER = gql`
  mutation CtCreateTextToSpeech($scriptId: ID!, $text: String!, $voiceIds: [String]!) {
    ctCreateTextToSpeech(scriptId: $scriptId, text: $text, voiceIds: $voiceIds) {
      code
      data
      message
      status
    }
  }
`;
