import { Form, Button } from "antd";
import { Formik } from "formik";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";

export default function MainForm(props) {
  const { initialValues, validationSchema, onSubmit, loading = false } = props;

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Form layout="vertical">
            <h3>Invite User</h3>
            <Form.Item>
              <Button
                htmlType="submit"
                block
                type="primary"
                className="submit-btn"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <Loader /> : "Invite"}
              </Button>
              <Link to={"/dashboard"}>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  disabled={loading}
                >
                  {loading ? <Loader /> : "Go To Dashboard"}
                </Button>
              </Link>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </>
  );
}
