import { Col, Row } from "antd";
import SelectionCardView from "./SelectionCardView";
import {
  campaignObjectiveData,
  campaignTypeData,
} from "components/Launch/GoogleLaunchV1/googleLaunchConfig";

export default function CampaignObjectiveAndCampaignType() {
  const campaignObjective = () => (
    <div>
      <h3>What's your campaign objective?</h3>
      <p>
        Choose an objective to tailor your experience to the campaign's objectives and settings.
      </p>
      <Row gutter={[15, 15]}>
        {campaignObjectiveData?.map((data, index) => (
          <Col key={"campaignObjectiveData" + index} lg={6} xs={24} md={8} sm={12}>
            <SelectionCardView {...data} />
          </Col>
        ))}
      </Row>
    </div>
  );
  const campaignType = () => (
    <div>
      <h3 className="mt-40">Select a campaign type</h3>
      <Row gutter={[15, 15]}>
        {campaignTypeData?.map((data, index) => (
          <Col key={"campaignObjectiveData" + index} lg={6} xs={24} md={8} sm={12}>
            <SelectionCardView {...data} />
          </Col>
        ))}
      </Row>
    </div>
  );

  return (
    <>
      {campaignObjective()}
      {campaignType()}
    </>
  );
}
// row styling and responsivness card code is here
