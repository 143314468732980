import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "cx";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_POSTBACK,
} from "shared/SharedKeys";
import { numberParse } from "utils";

export default function useTiktokColumnsPerformance(props) {
  const { callDetailView = () => {}, reportType = "", queryType = "" } = props || {};

  let idTitle = "";
  let idObject = "";

  const predictionCheck =
    (REPORT_TYPE_CAMPAIGN === reportType || REPORT_TYPE_AD_ADSET === reportType) &&
    queryType === REPORT_TYPE_POSTBACK;

  const predictionColumns = predictionCheck
    ? [
        {
          title: "P Revenue",
          dataIndex: "predictedRevenue",
          key: "predictedRevenue",
          render: (value) => (
            <LookTableColumnRender title={`$${numberParse(value, { toFixed: true })}`} />
          ),
          width: 90,
          minWidth: 90,
          sorter: (a, b) => a?.predictedRevenue - b?.predictedRevenue,
          searchType: "number",
        },
        {
          title: "P Margin",
          dataIndex: "predicted_margin",
          key: "predicted_margin",
          render: (value = 0, otherProps) => {
            const predicted_margin = `$${value.toFixed(2)}`;
            return (
              <LookTableColumnRender
                title={
                  <strong
                    className={cx({
                      "text-primary": parseFloat(value) > 0,
                      "text-danger": parseFloat(value) < 0,
                    })}
                  >
                    {predicted_margin.replace("-", "")}
                  </strong>
                }
              />
            );
          },
          width: 90,
          minWidth: 90,
          sorter: (a, b) => a?.predicted_margin - b?.predicted_margin,
          searchType: "number",
        },
        {
          title: "P ROAS",
          dataIndex: "predicted_ROAS",
          key: "predicted_ROAS",
          render: (value) => (
            <LookTableColumnRender title={numberParse(value, { toFixed: true })} />
          ),
          width: 90,
          minWidth: 90,
          sorter: (a, b) => a?.predicted_ROAS - b?.predicted_ROAS,
          searchType: "number",
        },
      ]
    : [];

  if (REPORT_TYPE_AD_ACCOUNT === reportType) {
    idTitle = "Ad Account Id";
    idObject = "accountId";
  }
  if (REPORT_TYPE_AD === reportType) {
    idTitle = "Ad Id";
    idObject = "adId";
  }
  if (REPORT_TYPE_AD_ADSET === reportType) {
    idTitle = "Ad Group Id";
    idObject = "adsetId";
  }
  if (REPORT_TYPE_CAMPAIGN === reportType) {
    idTitle = "campaignId";
    idObject = "campaignId";
  }

  const tiktokPerformanceColumns = [
    {
      title: "Date",
      dataIndex: "report_time",
      key: "report_time",
      render: (value, otherProps) => <LookTableColumnRender title={value || otherProps?.date} />,
      fixed: "left",
      width: 225,
      minWidth: 225,
      searchType: "string",
    },
    {
      title: idTitle,
      dataIndex: idObject,
      key: idObject,
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },

    {
      title: "E Revenue",
      dataIndex: "estimate_revenue",
      key: "estimate_revenue",
      render: (value) => (
        <LookTableColumnRender title={`$${numberParse(value, { toFixed: true })}`} />
      ),
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.estimate_revenue - b?.estimate_revenue,
      searchType: "number",
    },

    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spend - b?.spend,
      searchType: "number",
    },
    {
      title: "G Margin",
      dataIndex: "gross_margin",
      key: "gross_margin",
      render: (value = 0, otherProps) => {
        // const gross_margin = `$${value.replace("-", "")}`;
        const gross_margin = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-yellow": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {gross_margin.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.gross_margin - b?.gross_margin,
      searchType: "number",
    },
    {
      title: "ROAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (value) => (
        <LookTableColumnRender title={numberParse(value, { toFixed: true }) + "%"} />
      ),
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROAS - b?.ROAS,
      searchType: "number",
    },
    ...predictionColumns,
    {
      title: "clicks (T)",
      dataIndex: "clicksTiktok",
      key: "clicksTiktok",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicksTiktok - b?.clicksTiktok,
      searchType: "number",
    },
    {
      title: "Clicks (V)",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",
    },
    {
      title: "Searches",
      dataIndex: "searches",
      key: "searches",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.searches - b?.searches,
      searchType: "number",
    },

    {
      title: "Reach",
      dataIndex: "reach",
      key: "reach",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.reach - b?.reach,
      searchType: "number",
    },

    {
      title: "CPR (V)",
      dataIndex: "CPR_V",
      key: "CPR_V",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_V - b?.CPR_V,
      searchType: "number",
    },

    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },
    {
      title: "RPV",
      dataIndex: "RPV",
      key: "RPV",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPV - b?.RPV,
      searchType: "number",
    },
    {
      title: "RPL",
      dataIndex: "RPL",
      key: "RPL",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPL - b?.RPL,
      searchType: "number",
    },

    {
      title: "AD CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressionsTiktok",
      key: "impressionsTiktok",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsTiktok - b?.impressionsTiktok,
      searchType: "number",
    },

    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },

    {
      title: "V Visits",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",
    },
    {
      title: "Life Time Budget",
      dataIndex: "lifeTimeBudget",
      key: "lifeTimeBudget",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 140,
      minWidth: 140,
      sorter: (a, b) => a?.lifeTimeBudget - b?.lifeTimeBudget,
      searchType: "number",
    },
    {
      title: "Conversions (T)",
      dataIndex: "conversion",
      key: "conversion",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 140,
      minWidth: 140,
      sorter: (a, b) => a?.conversion - b?.conversion,
      searchType: "number",
    },
  ];

  return { tiktokPerformanceColumns };
}
