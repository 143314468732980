export const CRS_USER_TYPE_REQUESTER_INITIAL = "REQUESTER-INITIAL";
export const CRS_USER_TYPE_REQUESTER = "REQUESTER";
export const CRS_USER_TYPE_CONTENT_LEAD = "CONTENT_LEAD";
export const CRS_USER_TYPE_WRITER = "WRITER";

export const CRS_TARGETED_GROUP_ALL_AGE_GROUP = "ALL_AGE_GROUPS";
export const CRS_TARGETED_GROUP_THIRTEEN_SEVENTEEN = "THIRTEEN_SEVENTEEN";
export const CRS_TARGETED_GROUP_EIGHTEEN_TWENTYFOUR = "EIGHTEEN_TWENTYFOUR";
export const CRS_TARGETED_GROUP_TWENTYFIVE_THIRTYFOUR = "TWENTYFIVE_THIRTYFOUR";
export const CRS_TARGETED_GROUP_THIRTFIVE_FORTYFOUR = "THIRTFIVE_FORTYFOUR";
export const CRS_TARGETED_GROUP_FOURTYFIVE_FIFTYFOUR = "FOURTYFIVE_FIFTYFOUR";
export const CRS_TARGETED_GROUP_FIFTYFIVE_SIXTYFOUR = "FIFTYFIVE_SIXTYFOUR";
export const CRS_TARGETED_GROUP_SIXTYFIVEPLUS = "SIXTYFIVEPLUS";

export const CRS_AGE_GROUP_ENUM = [
  { name: "All Ages Groups", value: CRS_TARGETED_GROUP_ALL_AGE_GROUP },
  { name: "13-17", value: CRS_TARGETED_GROUP_THIRTEEN_SEVENTEEN },
  { name: "18-24", value: CRS_TARGETED_GROUP_EIGHTEEN_TWENTYFOUR },
  { name: "25-34", value: CRS_TARGETED_GROUP_TWENTYFIVE_THIRTYFOUR },
  { name: "35-44", value: CRS_TARGETED_GROUP_THIRTFIVE_FORTYFOUR },
  { name: "45-54", value: CRS_TARGETED_GROUP_FOURTYFIVE_FIFTYFOUR },
  { name: "55-64", value: CRS_TARGETED_GROUP_FIFTYFIVE_SIXTYFOUR },
  { name: "65+", value: CRS_TARGETED_GROUP_SIXTYFIVEPLUS },
];

export const CRS_ARTICLE_CATEGORY_FACE = "FACE";
export const CRS_ARTICLE_CATEGORY_MONEY_FACE = "MONEY_FACE";
export const CRS_ARTICLE_CATEGORY_MONEY_HIDDEN = "MONEY_HIDDEN";

// Gender
export const CRS_GENDER_MALE = "MALE";
export const CRS_GENDER_FEMALE = "FEMALE";
export const CRS_GENDER_NONE = "NONE";

export const CRS_GENDER_ENUM = [
  { name: "Male", value: CRS_GENDER_MALE },
  { name: "Female", value: CRS_GENDER_FEMALE },
  { name: "All", value: CRS_GENDER_NONE },
];

export const CRS_ARTICLE_CATEGORY_ENUM = [
  { name: "Face", value: CRS_ARTICLE_CATEGORY_FACE },
  { name: "Money + Face", value: CRS_ARTICLE_CATEGORY_MONEY_FACE },
  { name: "Money + Hidden", value: CRS_ARTICLE_CATEGORY_MONEY_HIDDEN },
];
export const CRS_REQUEST_OBJECTIVE_SALES = "SALES";
export const CRS_REQUEST_OBJECTIVE_BRANDING = "BRANDING";

export const CRS_REQUEST_OBJECTIVE_ENUM = [
  { name: "Sales", value: CRS_REQUEST_OBJECTIVE_SALES },
  { name: "Branding", value: CRS_REQUEST_OBJECTIVE_BRANDING },
];

export const CRS_REQUEST_PRIORITY_HIGH = "HIGH";
export const CRS_REQUEST_PRIORITY_LOW = "LOW";
export const CRS_REQUEST_PRIORITY_MEDIUM = "MEDIUM";

export const CRS_REQUEST_PRIORITY = [
  CRS_REQUEST_PRIORITY_HIGH,
  CRS_REQUEST_PRIORITY_LOW,
  CRS_REQUEST_PRIORITY_MEDIUM,
];

export const CRS_REQUEST_PRIORITY_ENUMS = [
  { name: "High", value: CRS_REQUEST_PRIORITY_HIGH, color: "#E84749" },
  { name: "Medium", value: CRS_REQUEST_PRIORITY_MEDIUM, color: "#D89614" },
  { name: "Low", value: CRS_REQUEST_PRIORITY_LOW, color: "#3C89E8" },
];

export const CRS_CLICKBAIT_SLIDER_ENUMS = [
  { name: "Low", value: "LOW" },
  { name: "Medium", value: "MEDIUM" },
  { name: "High", value: "HIGH" },
];

export const CRS_ARTICLE_TYPE_LISTICLE = "LISTICLE";
export const CRS_ARTICLE_TYPE_STORY_BASED = "STORY_BASED";

// LISTICLE
export const CRS_ARTICLE_TYPE_LISTICLE_PRODUCT_COMPARISON_LISTICLE = "PRODUCT_COMPARISON_LISTICLE";
export const CRS_ARTICLE_TYPE_LISTICLE_LONG_FORM_MONEY_ARTICLE = "LONG_FORM_MONEY_ARTICLE";
export const CRS_ARTICLE_TYPE_LISTICLE_PRODUCT_LISTICLE = "PRODUCT_LISTICLE";
export const CRS_ARTICLE_TYPE_LISTICLE_BRAND_LISTICLE = "BRAND_LISTICLE";

// STORY
export const CRS_ARTICLE_TYPE_STORY_BASED_PRODUCT_REVIEW = "PRODUCT_REVIEW";
export const CRS_ARTICLE_TYPE_STORY_BASED_FACE_ARTICLE = "FACE_ARTICLE";
export const CRS_ARTICLE_TYPE_STORY_BASED_BLOG_PARAPHRASE = "BLOG_PARAPHRASE";
export const CRS_ARTICLE_TYPE_STORY_BASED_COMPARISON = "COMPARISON";
export const CRS_ARTICLE_TYPE_STORY_BASED_VIRAL_FACE = "VIRAL_FACE";
export const CRS_ARTICLE_TYPE_STORY_BASED_BLOG = "BLOG";

export const CRS_ARTICLE_TYPE_LISTICLE_ENUMS = [
  {
    name: "Product comparison - listicle",
    value: CRS_ARTICLE_TYPE_LISTICLE_PRODUCT_COMPARISON_LISTICLE,
  },
  { name: "Brand listicle", value: CRS_ARTICLE_TYPE_LISTICLE_BRAND_LISTICLE },
  { name: "Long form money article", value: CRS_ARTICLE_TYPE_LISTICLE_LONG_FORM_MONEY_ARTICLE },
  { name: "Product listicle", value: CRS_ARTICLE_TYPE_LISTICLE_PRODUCT_LISTICLE },
];
export const CRS_ARTICLE_TYPE_STORY_BASED_ENUMS = [
  { name: "Product review", value: CRS_ARTICLE_TYPE_STORY_BASED_PRODUCT_REVIEW },
  { name: "Comparison", value: CRS_ARTICLE_TYPE_STORY_BASED_COMPARISON },
  { name: "Viral face", value: CRS_ARTICLE_TYPE_STORY_BASED_VIRAL_FACE },
  { name: "Blog paraphrase", value: CRS_ARTICLE_TYPE_STORY_BASED_BLOG_PARAPHRASE },
  { name: "Face article", value: CRS_ARTICLE_TYPE_STORY_BASED_FACE_ARTICLE },
  { name: "Blog", value: CRS_ARTICLE_TYPE_STORY_BASED_BLOG },
];

export const CRS_ARTICLE_TYPE_ENUMS = [
  {
    name: "Listicle",
    options: [...CRS_ARTICLE_TYPE_LISTICLE_ENUMS],
  },
  {
    name: "Article (story)",
    options: [...CRS_ARTICLE_TYPE_STORY_BASED_ENUMS],
  },
];

export const CRS_ARTICLE_TYPE_COMBINED_ENUMS = [
  ...CRS_ARTICLE_TYPE_LISTICLE_ENUMS,
  ...CRS_ARTICLE_TYPE_STORY_BASED_ENUMS,
];
export const CRS_REQUEST_STATUS_ASSIGNED = "ASSIGNED";
export const CRS_REQUEST_STATUS_IN_REVIEW = "REQUEST_IN_REVIEW";
export const CRS_REQUEST_STATUS_PENDING = "PENDING";
export const CRS_REQUEST_STATUS_SUBMITTED = "SUBMITTED";

export const CRS_REQUEST_STATUS_ENUMS = [
  { name: "Assigned", value: CRS_REQUEST_STATUS_ASSIGNED },
  { name: "In Review", value: CRS_REQUEST_STATUS_IN_REVIEW },
  { name: "Pending", value: CRS_REQUEST_STATUS_PENDING },
  { name: "Submitted", value: CRS_REQUEST_STATUS_SUBMITTED },
];

export const CRS_READABILITY_GRADE_EIGHTH_CLASS = "EIGHTH_CLASS";
export const CRS_READABILITY_GRADE_FIFTH_CLASS = "FIFTH_CLASS";
export const CRS_READABILITY_GRADE_FOURTH_CLASS = "FOURTH_CLASS";
export const CRS_READABILITY_GRADE_SIXTH_CLASS = "SIXTH_CLASS";
export const CRS_READABILITY_GRADE_TENTH_CLASS = "TENTH_CLASS";
export const CRS_READABILITY_GRADE_ENUMS = [
  { name: "Eight Class", value: CRS_READABILITY_GRADE_EIGHTH_CLASS },
  { name: "Fifth Class", value: CRS_READABILITY_GRADE_FIFTH_CLASS },
  { name: "Fourth Class", value: CRS_READABILITY_GRADE_FOURTH_CLASS },
  { name: "Sixth Class", value: CRS_READABILITY_GRADE_SIXTH_CLASS },
  { name: "Tenth Class", value: CRS_READABILITY_GRADE_TENTH_CLASS },
];

export const CRS_REQUEST_TYPE = {
  APPROVED_L1: "APPROVED_L1",
  APPROVED_L2: "APPROVED_L2",
  ARTICLE_IN_REVIEW: "ARTICLE_IN_REVIEW",
  ASSIGNED: "ASSIGNED",
  CHANGES_REQUESTED: "CHANGES_REQUESTED",
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  PUBLISHED: "PUBLISHED",
  REQUEST_IN_REVIEW: "REQUEST_IN_REVIEW",
  SUBMITTED: "SUBMITTED",
  UPDATED: "UPDATED",
  REVIEWED: "REVIEWED",
};

export const CRS_REQUEST_LANGUAGE_ENGLISH = "ENGLISH";
export const CRS_REQUEST_LANGUAGE_HINDI = "HINDI";

export const requestItemsData = [
  {
    name: "Pending",
    value: 0,
    iconType: "clock",
    color: "#E89A3C",
    key: CRS_REQUEST_TYPE?.PENDING,
  },
  {
    name: "Request review",
    value: 0,
    iconType: "price-list",
    color: "#E8D639",
    key: CRS_REQUEST_TYPE?.REQUEST_IN_REVIEW,
  },
  {
    name: "Assigned",
    value: 0,
    iconType: "user-assigned",
    color: "#138585",
    key: CRS_REQUEST_TYPE?.ASSIGNED,
  },
  {
    name: "Article in review",
    value: 0,
    iconType: "file-search",
    color: "#ACACAC",
    key: CRS_REQUEST_TYPE?.ARTICLE_IN_REVIEW,
  },
  {
    name: "Changes required",
    value: 0,
    iconType: "file-changes",
    color: "#F37370",
    key: CRS_REQUEST_TYPE?.CHANGES_REQUESTED,
  },
  {
    name: "Submitted",
    value: 0,
    iconType: "approval",
    color: "#49AA19",
    key: CRS_REQUEST_TYPE?.APPROVED_L2,
  },
  {
    name: "Approval",
    value: 0,
    iconType: "approval",
    color: "#49AA19",
    key: CRS_REQUEST_TYPE?.APPROVED_L1,
  },
  {
    name: "Published",
    value: 0,
    iconType: "send-new",
    color: "#854ECA",
    key: CRS_REQUEST_TYPE?.PUBLISHED,
  },
];

export const requestAddedItemsData = [
  {
    name: "Request updated",
    value: 0,
    iconType: "clock",
    color: "#D89614",
    key: CRS_REQUEST_TYPE?.UPDATED,
  },
  {
    name: "Request reviewed",
    value: 0,
    iconType: "price-list",
    color: "#E8D639",
    key: CRS_REQUEST_TYPE?.REVIEWED,
  },
];

export const CRS_STATUS_ACTIONS_APPROVE = "APPROVE";
export const CRS_STATUS_ACTIONS_CHANGES_REQUESTED = "CHANGES_REQUESTED";
export const CRS_STATUS_ACTIONS_DRAFT = "DRAFT";
export const CRS_STATUS_ACTIONS_SUBMIT = "SUBMIT";

export const CRS_L2_USER = [
  { id: "141", name: "Surabhi" },
  { id: "280", name: "Sakshi Bhatt" },
  { id: "168", name: "Saubhagya Verma" },
  { id: "193", name: "Kunjana Duggal" },
  { id: "132", name: "Ankit" },
  { id: "146", name: "Bindita" },
  { id: "250", name: "Sakshi Mehta" },
  { id: "331", name: "Abhishek Wadhwa" },
];

export const CRS_L3_USER = [
  { id: "248", name: "Preety Sharma" },
  { id: "226", name: "Shweta Mehra" },
  { id: "340", name: "Nishant Chaudhary" },
];

// Prompts enum

export const COUNTRIES = "countries";
export const LANGUAGE = "language";
export const WEBSITES = "websites";
export const INPUT = "input";
export const TEXT = "text";
