import { Button, Card, Image, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookButton from "components/LookButton";
import cx from "cx";

export default function CardCreative(props) {
  const { className = "", storyId = "", ...dataItems } = props;

  const { creative = {} } = dataItems || {};

  const bodyContent = () => (
    <div className="body-content">
      <p className="body-text">
        <Tooltip title={creative?.adBody?.body}>{creative?.adBody?.body}</Tooltip>
      </p>
    </div>
  );

  const imageContent = () => (
    <div className="image-wrapper">
      <Image src={creative?.creativeImage?.s3Url || creative?.creativeImage?.imageUrl} preview={false} />
    </div>
  );

  const titleContent = () => (
    <div className="d-flex p-12">
      <p className="title-text flex-grow-1 mb-0">
        <Tooltip title={creative?.adTitle?.title}>{creative?.adTitle?.title}</Tooltip>
      </p>
    </div>
  );

  const handleOpenCurrentCreative = () => {
    window.open(`http://facebook.com/${storyId}`);
  };

  const debugContent = [{ isRow: true, contentObjects: [{ props }] }];
  return (
    <>
      <Card className={cx("card-lg-compliance theme-parent p-ch-0", className)}>
        <div>
          <div className="p-18">
            <div className="d-flex">
              <div className="mr-12">
                <div className="temp-circle" />
              </div>
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between">
                  <div>
                    <h2 className="title-head">Facebook Profiles</h2>
                    <p className="mb-8">Sponsored</p>
                  </div>
                  <LookButton
                    tooltipTitle="View"
                    type="link"
                    icon={<ActionIconRender iconType="send" />}
                    onClick={handleOpenCurrentCreative}
                  >
                  </LookButton>
                </div>
              </div>
            </div>
            {bodyContent()}
          </div>
          <DebugPre content={debugContent} />
          {imageContent()}
          {titleContent()}
        </div>
      </Card>
    </>
  );
}
