import {
  LAUNCH_PARENT_STATE_DETAILS,
  LAUNCH_PARENT_STATE_TARGETING,
} from "components/LaunchView/LaunchKeys";
import * as types from "../actions/actionTypes";

export default function launchReducer(state = {}, action) {
  function handleLaunchTypeStates(stateKey) {
    const previousKeyData = state[action?.launchType] ? { ...state[action?.launchType] } : {};
    const previousTargeting =
      state[action?.launchType] && state[action?.launchType][stateKey]
        ? { ...state[action?.launchType][stateKey] }
        : {};
    return {
      ...state,
      [action?.launchType]: {
        ...previousKeyData,
        [stateKey]: { ...previousTargeting, ...action?.payload },
      },
    };
  }

  switch (action.type) {
    case types.REDUCER_LAUNCH_STATES:
      return { ...state, ...action?.payload };

    case types.REDUCER_LAUNCH_DETAILS:
      return handleLaunchTypeStates(LAUNCH_PARENT_STATE_DETAILS);

    case types.REDUCER_LAUNCH_TARGETING:
      return handleLaunchTypeStates(LAUNCH_PARENT_STATE_TARGETING);
    case types.LAUNCH_PLATFORM_SELECTION:
      return { ...state, templatePlatform: action?.payload };

    default:
      return state;
  }
}
