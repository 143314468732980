import React from "react";
import PopoverWrapper from "components/popoverWrapper";
import { EllipsisOutlined } from "@ant-design/icons";
import moment from "moment";
import ActionIconRender from "components/ActionIconRender";
import { Link } from "react-router-dom";

const NotificationMessageCard = ({
  id,
  title,
  message,
  datetime,
  actionableLink = null,
  seenStatus,
  onMarkAsReadCallbackFn,
  payload,
}) => {
  const markNotificationRead = (e) => {
    e?.stopPropagation();
    if (!seenStatus) onMarkAsReadCallbackFn(id);
  };

  const poverWrapperOptions = [
    {
      option: "Mark as read",
      title: "Mark as read",
      optionProps: {
        onClick: markNotificationRead,
      },
    },
  ];

  const renderedDateTime = () => {
    return moment(datetime).startOf("minute").fromNow();
  };

  const RenderNotificationCard = () => (
    <div className="notifications-text">
      <div className=" d-flex justify-content-between">
        <div className="service-name">{title}</div>
        {!seenStatus && (
          <PopoverWrapper options={poverWrapperOptions}>
            <EllipsisOutlined className="pop-info" />
          </PopoverWrapper>
        )}
      </div>
      <div className="d-flex">
        <div className="mr-8">
          <ActionIconRender iconType={seenStatus ? "notifyWhiteDot" : "notifyDot"} />
        </div>
        <div>
          <p className="text mb-0">{message}</p>
          <p className="time mt-0">{renderedDateTime()}</p>
        </div>
      </div>
    </div>
  );

  if (actionableLink) {
    return (
      <Link style={{ color: "#fff" }} to={actionableLink} onClick={markNotificationRead}>
        <RenderNotificationCard />
      </Link>
    );
  }

  return <RenderNotificationCard />;
};

export default NotificationMessageCard;
