import SelectInput from "components/forms/SelectInput";
import { useCustomMutation } from "hooks/apolloClientHooks";
import {
  GQL_RE_ADDSTRATEGY,
  GQL_RE_ADDTAGS,
  GQL_RE_UPDATESTRATEGY,
} from "shared/gql/gqlSchema/ruleGql";
import { openNotification } from "utils";
import LookTextInput from "components/forms/LookTextInput";
import { Button, Col, Form, Radio, Row, Space, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ActionIconRender from "components/ActionIconRender";

export default function NewStrategyForm(props) {
  const { close = () => {}, refetch, platform, productId, strategy } = props;

  const [name, setname] = useState();
  const [country, setCountry] = useState([]);
  const [tag, settag] = useState();
  useEffect(() => {
    if (strategy) {
      setname(strategy.name);
      setCountry(strategy.country);
      settag(strategy.s_tag);
    }
  }, [strategy]);

  const [claddStrategy] = useCustomMutation(GQL_RE_ADDSTRATEGY, {
    variables: {
      platform: platform,
      product: productId,
    },
    onCompleted: (e) => {
      const element = e?.re_addStrategy;
      if (element) {
        openNotification({ type: "success", message: "Stratgey Added Successfully" });
        refetch();
        close();
      }
    },

    onError(e) {
      if (name == null) {
        openNotification({ type: "error", message: "Strategy name is required" });
      } else {
        openNotification({ type: "error", message: e?.message });
      }
    },
  });

  const [clupdateStrategy] = useCustomMutation(GQL_RE_UPDATESTRATEGY, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_updateStrategy;
      if (element) {
        openNotification({ type: "success", message: "Stratgey Updated Successfully" });
        refetch();
      }
    },
    onError(e) {
      if (name == null) {
        openNotification({ type: "error", message: "Strategy name is required" });
      } else {
        openNotification({ type: "error", message: e?.message });
      }
    },
  });
  function addStrategy() {
    claddStrategy({
      variables: {
        name: name,
        country: country,
        s_tag: tag,
      },
    });
  }
  function updateStrategy() {
    clupdateStrategy({
      variables: {
        name: name,
        country: country,
        s_tag: tag,
        strategy_id: strategy?.id,
      },
    });
  }

  const handleCountry = () => {
    setCountry([...country, ""]);
  };
  const handleCountryChange = (index, value) => {
    const updatedList = [...country];
    updatedList[index] = value;
    setCountry(updatedList);
  };

  const handleCountryRemove = (index) => {
    const updatedList = [...country];
    updatedList.splice(index, 1);
    setCountry(updatedList);
  };

  return (
    <div className="mt-20">
      <Form layout="vertical">
        {!strategy && <h3>General Details</h3>}
        <div className="mb-10 justify-content-between">
          <h4>Name </h4>
          <div>
            <LookTextInput
              className="mb-0"
              value={name}
              onChange={(e) => setname(e.target.value)}
              placeholder="Enter Unique Rule Name"
            />
          </div>
        </div>
        <div className="mb-10 justify-content-between">
          <div className="mb-10">
            <h4>Country</h4>
            <div>
              <Button onClick={handleCountry} style={{ width: "100%", textAlign: "left" }}>
                <ActionIconRender iconType="plus" /> Add country
              </Button>
            </div>
          </div>
          <div className="mb-10">
            <Row span={24}>
              {country?.map((items, index) => (
                <Col span={12}>
                  <Row span={24}>
                    <Col span={22}>
                      <SelectInput
                        className="mb-5"
                        onChange={(value) => handleCountryChange(index, value)}
                        value={items}
                        options={[
                          { name: "Australia", value: "Australia" },
                          { name: "USA", value: "USA" },
                          { name: "New Zealand", value: "New Zealand" },
                          { name: "Canada", value: "Canada" },
                          { name: "India", value: "India" },
                          { name: "England", value: "England" },
                        ]}
                      />
                    </Col>

                    <Col span={1}>
                      <Button
                        onClick={() => handleCountryRemove(index)}
                        type="link"
                        danger
                        icon={<ActionIconRender iconType="close" />}
                      />
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        </div>
        <div className="mb-10 justify-content-between">
          <h4>Strategy Tag </h4>
          <LookTextInput
            className="mb-0"
            value={tag}
            onChange={(e) => settag(e.target.value)}
            placeholder="Enter Unique Rule Name"
          />
        </div>
      </Form>
      {strategy ? (
        <div className="d-flex mt-20 mb-10 justify-content-between">
          <Button
            style={{ width: "100%" }}
            className="mr-6"
            key="submit"
            type="primary"
            onClick={updateStrategy}
          >
            Update Strategy
          </Button>
        </div>
      ) : (
        <div style={{ marginTop: "10%" }} className="d-flex mb-10 justify-content-between">
          <Button
            style={{ width: "100%" }}
            className="mr-6"
            key="submit"
            type="primary"
            onClick={addStrategy}
          >
            Add Strategy
          </Button>
        </div>
      )}
    </div>
  );
}
