import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

export const MULTI_SEARCH_FILTER_TYPE_NUMBER = "number";
export const MULTI_SEARCH_FILTER_TYPE_STRING = "string";

export function useLookTableFilterData(persistKey) {
  const routeMatch = useRouteMatch();
  const { path } = routeMatch;
  const uniqueKey = path + persistKey;

  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const userSettings = useSelector((state) => state?.userSettings);
  const { searchFilter, defaultFilter, defaultFilterBoolean } = userSettings || {};
  const { [uniqueKey]: searchFilterState } = searchFilter || {};
  const { [persistKey]: defaultFilterState } = defaultFilter || {};

  const filterBooleanColumnsState = defaultFilterBoolean?.[persistKey] || [];

  function outerFilterData(mainData, extraProps = {}) {
    const searchValue = search?.toLowerCase().trim() || "";
    const { filterKeys = [] } = extraProps;

    const filterData = mainData?.filter((nData) => {
      const valid = [];
      if (filterKeys?.length > 0 && searchValue) {
        filterKeys.forEach((item) => {
          if (nData[item] && nData[item]?.toLowerCase()?.includes(searchValue)) {
            valid.push(true);
          }
        });
      } else {
        valid.push(true);
      }
      return valid?.length > 0;
    });
    return filterData;
  }

  function filterData(mainData) {
    const { filterColumns: customeFilterColumns = [] } = searchFilterState || {};
    const { filterColumns: defaultFilterColumns = [] } = defaultFilterState || {};

    const filterColumns = [...customeFilterColumns, ...defaultFilterColumns];

    return mainData?.filter((data) => {
      let isStringIncludesValid = true,
        isStringExcludesValid = true,
        isNumberValid = [],
        isRangeValid = [];
      if (filterColumns && filterColumns.length > 0) {
        filterColumns.forEach((filterData) => {
          const {
            selectedColumn,
            searchType,
            includeInputs,
            excludeInputs,
            includeType = "AND",
            excludeType = "AND",
            numberFilterType,
            numberFilterValue,
            numberFilterRangeMin,
            numberFilterRangeMax,
          } = filterData;

          if (selectedColumn) {
            if (searchType === "string") {
              if (includeInputs) {
                isStringIncludesValid =
                  includeInputs.length > 0
                    ? includeType === "AND"
                      ? includeInputs.every((e) =>
                          data[selectedColumn]?.toLowerCase()?.includes(e?.toLowerCase())
                        )
                      : includeInputs.filter((e) =>
                          data[selectedColumn]?.toLowerCase()?.includes(e?.toLowerCase())
                        ).length > 0
                    : true;
              }
              if (excludeInputs) {
                isStringExcludesValid =
                  excludeInputs.length > 0
                    ? excludeInputs.every(
                        (e) => !data[selectedColumn]?.toLowerCase()?.includes(e?.toLowerCase())
                      )
                    : true;
              }
            }
            if (searchType === "number") {
              if (
                numberFilterType === "<" ||
                numberFilterType === ">" ||
                numberFilterType === "<=" ||
                numberFilterType === ">=" ||
                numberFilterType === "==="
              ) {
                if (numberFilterValue) {
                  let valid = false;
                  if (numberFilterType === "<") {
                    if (Number(data[selectedColumn]) < Number(numberFilterValue)) {
                      valid = true;
                    }
                  }
                  if (numberFilterType === ">") {
                    if (Number(data[selectedColumn]) > Number(numberFilterValue)) {
                      valid = true;
                    }
                  }
                  if (numberFilterType === "<=") {
                    if (Number(data[selectedColumn]) <= Number(numberFilterValue)) {
                      valid = true;
                    }
                  }
                  if (numberFilterType === ">=") {
                    if (Number(data[selectedColumn]) >= Number(numberFilterValue)) {
                      valid = true;
                    }
                  }
                  if (numberFilterType === "===") {
                    if (Number(data[selectedColumn]) === Number(numberFilterValue)) {
                      valid = true;
                    }
                  }
                  isNumberValid.push(valid);
                }
              }
              if (numberFilterType === "range") {
                if (numberFilterRangeMin && numberFilterRangeMax) {
                  let valid = false;
                  if (
                    Number(data[selectedColumn]) >= Number(numberFilterRangeMin) &&
                    Number(data[selectedColumn]) <= Number(numberFilterRangeMax)
                  ) {
                    valid = true;
                  }
                  isRangeValid.push(valid);
                }
              }
            }
          }

          // isValid = true;
        });
      }
      return (
        isStringIncludesValid &&
        isStringExcludesValid &&
        isNumberValid?.every((a) => a) &&
        isRangeValid?.every((a) => a)
      );
    });
  }

  /**
   * ? search, searchFilter, defaultFilter
   * These three are state dependencies
   */

  /**
   * ? filterData
   * It's main filter fuction apply for multi filter columns
   *
   * ? outerFilterData
   * It's filter function apply for outer search filed
   */

  return {
    search,
    searchFilter,
    defaultFilter,
    filterBooleanColumnsState,
    filterData,
    outerFilterData,
  };
}
