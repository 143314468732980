import { Card, Divider, Image, Button, Carousel, Tooltip } from "antd";
import DebugPre from "../../../DebugPre";
import ActionIconRender from "../../../ActionIconRender";
import { useRef, Fragment, useState } from "react";
import { openNotification } from "utils";
import LookButton from "components/LookButton";
import { CopyOutlined } from "@ant-design/icons";
import logo from "../../../../assets/images/logo-icon.svg";
import cx from "cx";
import { getVideoFormat, isImageUrl, isVideoUrl } from "utils/mediaUtils";
import LookDrawer from "components/LookDrawer";
import LookModal, { useLookModal } from "components/LookModal";

function ClickToCopy({ copyText = "", tooltipText = "", successText = "" }) {
  const copyRef = useRef();
  const copyToClipboard = (e) => {
    copyRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    openNotification({
      type: "success",
      message: <b>Copied</b>,
      description: copyText,
      key: "vs-url-copy",
    });
  };
  const ToolTipWrap = tooltipText ? Tooltip : Fragment;
  const toolTipWrapProps = tooltipText ? { title: tooltipText } : {};
  return (
    <>
      <div className="w-0 h-0 position-absolute opacity-0">
        <textarea ref={copyRef} value={copyText} />
      </div>
      {document.queryCommandSupported("copy") && (
        <ToolTipWrap {...toolTipWrapProps}>
          <LookButton
            tooltipTitle="Click to copy"
            onClick={copyToClipboard}
            className="border-0 copy-btn p-0 bg-transparent"
          >
            <CopyOutlined />
          </LookButton>
        </ToolTipWrap>
      )}
    </>
  );
}

export default function AdCreativeCard(props) {
  const { adBody, adTitle, creativeImage, className = "" } = props;
  const { body = "" } = adBody || {};
  const { title = "" } = adTitle || {};
  const { imageUrl, s3Url, videoId } = creativeImage || {};
  const { modal, visibleModal, openModal } = useLookModal({
    title: "Preview",
    footer: false,
  });
  const url = s3Url || imageUrl;
  const videoRef = useRef(null);
  const download = (e) => {
    const url = e.target.href;
    const fileName = url?.substring(url?.lastIndexOf("/") + 1);

    fetch(url, { method: "GET", headers: {} })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const titleContent = () => (
    <div className="d-flex p-12">
      <p className="title-text flex-grow-1 mb-0">
        <Tooltip title={title}>{title}</Tooltip>
      </p>
      <ClickToCopy copyText={title} />
    </div>
  );

  const bodyContent = () => (
    <div className="body-content position-relative">
      <p className="body-text">
        <Tooltip title={body}>{body}</Tooltip>
      </p>
      <ClickToCopy copyText={body} />
    </div>
  );

  const [playModal, setPlayModal] = useState(false);

  const renderCreativeOprnButton = (url, type) => (
    <div className="hover-action h-100">
      <LookButton type="primary" onClick={() => setPlayModal(true)}>
        Open {type}
      </LookButton>
    </div>
  );

  const renderCreative = () => {
    if (url) {
      if (isImageUrl(url)) {
        return (
          <>
            <Image src={url} preview={false} />
            {renderCreativeOprnButton(url, "Image")}
          </>
        );
      } else if (isVideoUrl(url)) {
        return (
          <>
            <div className="video-wrapper">
              <video>
                <source src={url} type={`video/${getVideoFormat(url)}`} />
              </video>
            </div>
            {renderCreativeOprnButton(url, "Video")}
          </>
        );
      }
    } else {
      return (
        <div className="no-creative-found">
          <p>There is no creative found</p>.
        </div>
      );
    }
  };

  const imageContent = () => (
    <>
      <div className="image-wrapper image-hover-action-wrapper">{renderCreative()}</div>
    </>
  );

  const debugContent = [{ isRow: true, contentObjects: [{}] }];

  return (
    <>
      <Card className={cx("card-lg-compliance theme-parent p-ch-0", className)}>
        <div>
          <div className="p-18">
            <div className="d-flex">
              <div className="mr-12">
                <img
                  className="profileImage"
                  src={logo}
                  // onError={(e) => setProfilePic(logo)}
                  alt={"creative_image"}
                />
              </div>
              <div className="flex-grow-1">
                <h2 className="title-head">{title}</h2>
                <p className="mb-8">Sponsored</p>
              </div>
            </div>
            {bodyContent()}
          </div>
          <DebugPre content={debugContent} />
          {imageContent()}
          {titleContent()}
        </div>
      </Card>{" "}
      {
        <LookModal
          title={"Preview"}
          visible={playModal}
          onCancel={() => {
            isVideoUrl(url) && videoRef?.current?.pause();
            setPlayModal(false);
          }}
          footer={false}
        >
          {playModal && (
            <>
              {isImageUrl(url) && (
                <img
                  className=" w-100 h-100"
                  style={{ objectFit: "contain", top: 0, left: 0, background: "#000" }}
                  src={url}
                  alt="no img"
                />
              )}

              {isVideoUrl(url) && (
                <video
                  ref={videoRef}
                  height={"300px"}
                  className="w-100"
                  src={s3Url}
                  controls
                  autoPlay
                />
              )}
            </>
          )}
        </LookModal>
      }
    </>
  );
}
