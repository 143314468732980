import ActionIconRender from "components/ActionIconRender";
import { PERMISSION_CRS_VIEW } from "shared/UserPermission/userPermissionList";

const crsPath = [
  {
    name: "CRS",
    link: "/crs",
    icon: (e) => <ActionIconRender {...e} iconType="crs-icon" />,
    subMenu: [
      {
        subMenuName: "Dashboard",
        subMenuLink: "/crs-dashboard",
        subMenuIcon: (e) => <ActionIconRender {...e} iconType="crs-icon" />,
        permission: PERMISSION_CRS_VIEW,
      },
      {
        subMenuName: "Article Requests",
        subMenuLink: "/article-requests",
        subMenuIcon: (e) => <ActionIconRender {...e} iconType="crs-icon" />,
        permission: PERMISSION_CRS_VIEW,
      },
      // {
      //   subMenuName: "Creatives",
      //   subMenuLink: "/creatives",
      //   subMenuIcon: (e) => <ActionIconRender {...e} iconType="crs-icon" />,
      //   // permission: [],
      // },
      {
        subMenuName: "CRS Settings",
        subMenuLink: "/crs-settings",
        subMenuIcon: (e) => <ActionIconRender {...e} iconType="crs-icon" />,
        permission: PERMISSION_CRS_VIEW,
      },
    ],
  },
];

export default crsPath;
