import useSasApis from "./useSasApis";
import useAdLibraryApis from "./useAdLibraryApis";
import useApiToStoreQuery from "./useApiToStoreQuery";
import useF360Apis from "./useF360Apis";
import useITaskApis from "./useITaskApis";
import useMediaApis from "./useMediaApis";
import useUmsApis from "./useUmsApis";
import useViralSparksApis from "./useViralSparksApis";
import useSlackApis from "./useSlackApis";
import useCmsApis from "./useCmsApis";
import useLaunchApis from "./useLaunchApis";

export default function useApiToStore(typeKey, extraProps = {}) {
  const f360Apis = useF360Apis(typeKey);
  const umsApis = useUmsApis(typeKey);
  const mediaApis = useMediaApis(typeKey);
  const iTask = useITaskApis(typeKey);
  const adLibraryApis = useAdLibraryApis(typeKey);
  const viralSparksApis = useViralSparksApis(typeKey);
  const sasApis = useSasApis(typeKey);
  const slackApis = useSlackApis(typeKey);
  const cmsApis = useCmsApis(typeKey);
  const launchApis = useLaunchApis(typeKey);

  function selectData() {
    if (typeof cmsApis === "object") {
      return cmsApis;
    }

    if (typeof umsApis === "object") {
      return umsApis;
    }
    if (typeof sasApis === "object") {
      return sasApis;
    }
    if (typeof f360Apis === "object") {
      return f360Apis;
    }
    if (typeof launchApis === "object") {
      return launchApis;
    }
    if (typeof mediaApis === "object") {
      return mediaApis;
    }
    if (typeof iTask === "object") {
      return iTask;
    }
    if (typeof adLibraryApis === "object") {
      return adLibraryApis;
    }
    if (typeof viralSparksApis === "object") {
      return viralSparksApis;
    }
    if (typeof slackApis === "object") {
      return slackApis;
    }

    return {};
  }

  const apiData = useApiToStoreQuery({ ...selectData(), extraProps });

  return apiData;
}
