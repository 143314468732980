import { Form, Row } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import { useLaunchContext } from "components/Launch/Context";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function AddTiktokFields(props) {
  const { campaignIndex, adSetindex } = props;
  const { handleCampaignAdsetAdset, campaignObject } = useLaunchContext();
  const [inputs, setInputs] = useState({
    deepLink: campaignObject?.[campaignIndex]?.adSets?.[adSetindex]?.deeplink || "",
    deepLinkType: campaignObject?.[campaignIndex]?.adSets?.[adSetindex]?.deeplinkType || [],
    appName: campaignObject?.[campaignIndex]?.adSets?.[adSetindex]?.appName || "",
  });
  const { deepLink, deepLinkType, appName } = inputs;
  const { tiktokLaunchFetch = () => {}, tiktokLaunchRefetch } = useApiToStoreContext();
  const userSettings = useSelector((state) => state?.userSettings);
  const { userSettingData = {}, selectedWorkspace } =
    useSelector((state) => state?.userSettings) || {};
  const { tiktokLaunchConfig } = userSettingData;
  const { ad } = tiktokLaunchConfig || {};
  useEffect(() => {
    tiktokLaunchFetch();
  }, []);

  return (
    <div>
      <Form layout="vertical">
        <Form.Item>
          <LookTextInput
            value={appName}
            name="appName"
            label="App Name"
            placeholder="Enter app Name"
            onChange={(e) => {
              setInputs({
                ...inputs,
                appName: e?.target?.value,
              });
              handleCampaignAdsetAdset(campaignIndex, adSetindex, { appName: e?.target?.value });
            }}
          />

          <SelectInput
            label="Deep Link Type"
            search
            placeholder="Select link type"
            name="deepLinkType"
            value={deepLinkType}
            options={ad?.deeplink_type}
            onChange={(e) => {
              setInputs({
                ...inputs,
                deepLinkType: e,
              });
              handleCampaignAdsetAdset(campaignIndex, adSetindex, { deeplinkType: e });
            }}
          />

          <LookTextInput
            value={deepLink}
            name="deepLink"
            label="Deep Link"
            placeholder="Enter link"
            onChange={(e) => {
              setInputs({
                ...inputs,
                deepLink: e?.target?.value,
              });
              handleCampaignAdsetAdset(campaignIndex, adSetindex, { deeplink: e?.target?.value });
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddTiktokFields;
