import { USER_SETTING_OBJECT } from "actions/actionTypes";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  defaultDate,
  defaultITaskTimeZone,
  defaultTimeZone,
  DEFAULT_TABLE_ROW_SIZE_VALUE,
} from "reducers/userSettings";
import { GET_ALL_USER_CONGIFURATION } from "shared/gql/gqlSchema/umsGql";
import {
  DEFAULT_CURRENCY,
  DEFAULT_I_TASK_TIMEZONE,
  DEFAULT_TIMEZONE,
  META_TABLE_ROW_SIZE,
} from "shared/UserSettingsKeys";

export default function CallTimezone() {
  const dispatch = useDispatch();
  const dateRangeTimezone = useSelector((state) => state?.userSettings?.dateRangeTimezone);
  const { data, loading } = useCustomQuery(GET_ALL_USER_CONGIFURATION, {
    onCompleted: (e) => {
      dispatch({
        type: USER_SETTING_OBJECT,
        payload: {
          dateRangeTimezone: {
            rangeDates: dateRangeTimezone?.rangeDates || defaultDate,
            timeZone: { value: e[DEFAULT_TIMEZONE] || defaultTimeZone },
          },
          [DEFAULT_CURRENCY]: e[DEFAULT_CURRENCY],
          defaultTimezone: { value: e[DEFAULT_TIMEZONE] || defaultTimeZone },
          defaultITaskTimeZone: { value: e[DEFAULT_I_TASK_TIMEZONE] || defaultITaskTimeZone },
          [META_TABLE_ROW_SIZE]: e[META_TABLE_ROW_SIZE] || DEFAULT_TABLE_ROW_SIZE_VALUE,
        },
      });
    },
  });

  return null;
}
