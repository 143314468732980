import { USER_SETTING_MAIN_USER, USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import { Form, Select, Tooltip } from "antd";
import { useApiToStore } from "hooks";
import { API_STORE_UMS_USERS_BY_AGENCY } from "hooks/useApiToStore/keys";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const { Option } = Select;

export default function UserSelectPersist(props) {
  const { apiCall: agencyUsersFetch = () => {}, refetch: agencyUsersRefetch } = useApiToStore(
    API_STORE_UMS_USERS_BY_AGENCY
  );
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;
  const dispatch = useDispatch();
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);

  const mainUser = useSelector((state) => state?.userSettings?.mainUser);

  const selectedUser = mainUser && mainUser[persistKey] ? mainUser[persistKey] : null;

  function handle(e) {
    dispatch({ type: USER_SETTING_MAIN_USER, payload: { ...mainUser, [persistKey]: e } });
  }

  useEffect(() => {
    agencyUsersFetch();
  }, []);
  return (
    <div style={{ width: 200 }}>
      <Form layout="vertical">
        <div className="d-flex">
          <div className="flex-grow-1">
            <Form.Item className="look-form-input mb-0">
              <Tooltip title="">
                <Select
                  name="user"
                  className="w-100"
                  placeholder="Select User"
                  onChange={handle}
                  showSearch
                  value={selectedUser}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={"all"}>All</Option>
                  {userSettingData?.agencyUsers?.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
                {/* <SelectInput
              className="mb-0"
              search
              name="user"
              placeholder="Select User"
              onChange={handle}
              value={selectedUser}
              options={userSettingData?.agencyUsers?.map(({ id, name }) => ({ name, value: id }))}
            /> */}
              </Tooltip>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
