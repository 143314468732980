import { gql } from "@apollo/client";
import { ASSET_UPDATE_RESPONSE_RESPONSE } from "./commonResponseFragements";

// const VS_AGENCY_RESPONSE = gql`
//   fragment VSAgency on VSAgency {
//     id
//     revenueShare
//     agencyName
//     userDefaultIncentivePercentage
//     umsAgencyId
//     userDefaultShare
//     userDefaultThreshold
//     nickName
//     status
//     mediums {
//       id
//       medium
//     }
//   }
// `;

/** 
*? type VSAgency {
**  default_revshare: String!
**  id: Int!
**  is_advance_revshare: Boolean!
**  is_reseller: Boolean!
**  name: String!
**  nickname: String!
**  status: VSSTATUS!
**  user_default_revshare: String!
**  }

*/

const VS_AGENCY_RESPONSE = gql`
  fragment VSAgency on VSAgency {
    default_revshare
    id
    is_advance_revshare
    is_reseller
    name
    nickname
    status
    user_default_revshare
    platforms {
      id
    }
    brands {
      id
    }
    advertisers {
      id
    }
  }
`;

const VS_AGENCY_RESPONSE_FOR_REDUCER = gql`
  fragment VSAgencyReducer on VSAgency {
    id
    name
  }
`;

export const GET_VS_ALL_AGENCY = gql`
  ${VS_AGENCY_RESPONSE}
  query vs_agencyGetAll {
    vs_agencyGetAll {
      ...VSAgency
    }
  }
`;
export const GET_VS_ALL_AGENCY_FOR_REDUCER = gql`
  ${VS_AGENCY_RESPONSE_FOR_REDUCER}
  query vs_agencyGetAll {
    vs_agencyGetAll {
      ...VSAgencyReducer
    }
  }
`;

export const ADD_VS_AGENCY = gql`
  ${VS_AGENCY_RESPONSE}
  mutation vs_agencyCreate(
    $default_revshare: String!
    $id: ID!
    $is_advance_revshare: Boolean!
    $is_reseller: Boolean!
    $name: String!
    $nickname: String
    $status: VSSTATUS!
    $user_default_revshare: String!
  ) {
    vs_agencyCreate(
      id: $id
      default_revshare: $default_revshare
      is_advance_revshare: $is_advance_revshare
      is_reseller: $is_reseller
      name: $name
      nickname: $nickname
      status: $status
      user_default_revshare: $user_default_revshare
    ) {
      ...VSAgency
    }
  }
`;

export const UPDATE_VS_AGENCY = gql`
  ${VS_AGENCY_RESPONSE}
  mutation vs_agencyUpdate(
    $default_revshare: String!
    $id: ID!
    $is_advance_revshare: Boolean!
    $is_reseller: Boolean!
    $name: String!
    $nickname: String
    $status: VSSTATUS!
    $user_default_revshare: String!
  ) {
    vs_agencyUpdate(
      id: $id
      default_revshare: $default_revshare
      is_advance_revshare: $is_advance_revshare
      is_reseller: $is_reseller
      name: $name
      nickname: $nickname
      status: $status
      user_default_revshare: $user_default_revshare
    ) {
      ...VSAgency
    }
  }
`;

export const DELETE_VS_AGENCY = gql`
  mutation vs_agencyDelete($id: [ID!]!) {
    vs_agencyDelete(id: $id)
  }
`;

export const VS_ASSIGN_PLATFORMS_TO_AGENCY = gql`
  mutation vs_agencyWithPlatforms($agency_id: ID!, $platform_id: [ID!]!) {
    vs_agencyWithPlatforms(agency_id: $agency_id, platform_id: $platform_id)
  }
`;

export const VS_ASSIGN_ADVERTISERS_TO_AGENCY = gql`
  mutation vs_agencyWithAdvertisers($advertiser_id: [ID!]!, $agency_id: ID!) {
    vs_agencyWithAdvertisers(advertiser_id: $advertiser_id, agency_id: $agency_id)
  }
`;

export const VS_ASSIGN_BRANDS_TO_AGENCY = gql`
  mutation vs_agencyWithBrands($agency_id: ID!, $brand_id: [ID!]!) {
    vs_agencyWithBrands(agency_id: $agency_id, brand_id: $brand_id)
  }
`;

const VS_BRAND_RESPONSE = gql`
  fragment VSBrand on VSBrand {
    id
    logo
    name
    nickname
    notes
    status
    vs_rev_share
    website
    tracking_id
    platforms {
      id
      name
      platform_url {
        url_structure
      }
    }
    advertiser {
      id
    }
  }
`;

export const VS_BRAND_BY_ADVERTISER = gql`
  query vs_brandGetByAdvertiser($advertiser_id: ID!) {
    vs_brandGetByAdvertiser(advertiser_id: $advertiser_id) {
      name
      nickname
      website
    }
  }
`;

const VS_BRAND_FOR_REDUX_RESPONSE = gql`
  fragment VSBrandRedux on VSBrand {
    id
    name
    nickname
    website
    platforms {
      id
      name
      platform_url {
        url_structure
      }
    }
    advertiser {
      id
    }
  }
`;

export const GET_VS_ALL_BRAND = gql`
  ${VS_BRAND_RESPONSE}
  query vs_brandGetAll {
    vs_brandGetAll {
      ...VSBrand
    }
  }
`;

export const ADD_VS_BRAND = gql`
  ${VS_BRAND_RESPONSE}
  mutation vs_brandCreate(
    $advertiser_id: ID!
    $logo: String!
    $name: String!
    $nickname: String
    $notes: String!
    $status: VSSTATUS!
    $tracking_id: String
    $vs_rev_share: String!
    $website: String!
  ) {
    vs_brandCreate(
      advertiser_id: $advertiser_id
      logo: $logo
      name: $name
      nickname: $nickname
      notes: $notes
      status: $status
      tracking_id: $tracking_id
      vs_rev_share: $vs_rev_share
      website: $website
    ) {
      ...VSBrand
    }
  }
`;

export const UPDATE_VS_BRAND = gql`
  ${VS_BRAND_RESPONSE}
  mutation vs_brandUpdate(
    $id: ID!
    $advertiser_id: ID!
    $logo: String!
    $name: String!
    $nickname: String
    $notes: String!
    $status: VSSTATUS!
    $tracking_id: String
    $vs_rev_share: String!
    $website: String!
  ) {
    vs_brandUpdate(
      id: $id
      advertiser_id: $advertiser_id
      logo: $logo
      name: $name
      nickname: $nickname
      notes: $notes
      status: $status
      tracking_id: $tracking_id
      vs_rev_share: $vs_rev_share
      website: $website
    ) {
      ...VSBrand
    }
  }
`;

export const DELETE_VS_BRAND = gql`
  mutation vs_brandDelete($id: [ID!]!) {
    vs_brandDelete(id: $id)
  }
`;

export const VS_ASSIGN_PLATFORMS_TO_BRAND = gql`
  mutation vs_brandWithPlatforms($brand_id: ID!, $platform_id: [ID!]!) {
    vs_brandWithPlatforms(brand_id: $brand_id, platform_id: $platform_id)
  }
`;

const VS_LANGUAGE_RESPONSE = gql`
  fragment VSLanguage on VSLanguage {
    id
    name
    nickname
  }
`;

export const GET_VS_LANGUAGE = gql`
  ${VS_LANGUAGE_RESPONSE}
  query vs_languagesGetAll {
    vs_languagesGetAll {
      ...VSLanguage
    }
  }
`;

const VS_ADVERTISER_RESPONSE = gql`
  fragment VSAdvertiser on VSAdvertiser {
    id
    name
    platform_url {
      url_structure
    }
    brands {
      id
    }
  }
`;

export const GET_VS_ALL_ADVERTISER = gql`
  ${VS_ADVERTISER_RESPONSE}
  query vs_advertiserGetAll {
    vs_advertiserGetAll {
      ...VSAdvertiser
    }
  }
`;

export const ADD_VS_ADVERTISER = gql`
  ${VS_ADVERTISER_RESPONSE}
  mutation vs_advertiserCreate($name: String!) {
    vs_advertiserCreate(name: $name) {
      ...VSAdvertiser
    }
  }
`;

export const UPDATE_VS_ADVERTISER = gql`
  ${VS_ADVERTISER_RESPONSE}
  mutation vs_advertiserUpdate($id: ID!, $name: String) {
    vs_advertiserUpdate(id: $id, name: $name) {
      ...VSAdvertiser
    }
  }
`;

export const DELETE_VS_ADVERTISER = gql`
  mutation vs_advertiserDelete($id: [ID!]!) {
    vs_advertiserDelete(id: $id)
  }
`;
/**
cms_list_id: ID
description: String!
id: ID!
image: String!
language_code: String!
nickname: String
slug: String!
title: String!

*/

const VS_CONTENT_RESPONSE = gql`
  fragment VSContent on VSContent {
    cms_list_id
    description
    id
    image
    language_code
    nickname
    slug
    title
    advertiser {
      id
      name
      brands {
        website
      }
    }
    brand {
      id
      name
    }
    language {
      id
    }
  }
`;

export const GET_VS_ALL_CONTENT = gql`
  ${VS_CONTENT_RESPONSE}
  query vs_contentGetAll($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    vs_contentGetAll(column: $column, order: $order, page: $page, search: $search, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...VSContent
      }
    }
  }
`;

export const GET_VS_ALL_CONTENT_ID_NAME = gql`
  query vs_contentGetAll_special(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    vs_contentGetAll_special(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      data {
        id
        nickname
        language_code
        title
      }
    }
  }
`;

export const GET_VS_CONTENT_GET_BY_ID = gql`
  ${VS_CONTENT_RESPONSE}
  query vs_contentGetById($id: [ID!]!) {
    vs_contentGetById(id: $id) {
      ...VSContent
    }
  }
`;

export const GET_VS_ALL_CONTENT_BY_ADVETTISER = gql`
  ${VS_CONTENT_RESPONSE}
  query vs_contentGetAllByAdvertiserId(
    $advertiser_id: ID
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    vs_contentGetAllByAdvertiserId(
      advertiser_id: $advertiser_id
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...VSContent
      }
    }
  }
`;

export const ADD_VS_CONTENT = gql`
  ${VS_CONTENT_RESPONSE}
  mutation vs_contentCreate(
    $advertiser_id: ID
    $brand_id: ID
    $cms_list_id: ID
    $description: String!
    $image: String!
    $language_code: String!
    $language_id: ID
    $nickname: String
    $slug: String!
    $title: String!
  ) {
    vs_contentCreate(
      advertiser_id: $advertiser_id
      brand_id: $brand_id
      cms_list_id: $cms_list_id
      description: $description
      image: $image
      language_code: $language_code
      language_id: $language_id
      nickname: $nickname
      slug: $slug
      title: $title
    ) {
      ...VSContent
    }
  }
`;

export const UPDATE_VS_CONTENT = gql`
  ${VS_CONTENT_RESPONSE}
  mutation vs_contentUpdate(
    $advertiser_id: ID
    $brand_id: ID
    $cms_list_id: ID
    $description: String!
    $id: ID!
    $image: String!
    $language_code: String
    $language_id: ID
    $nickname: String
    $slug: String!
    $title: String!
  ) {
    vs_contentUpdate(
      advertiser_id: $advertiser_id
      brand_id: $brand_id
      cms_list_id: $cms_list_id
      description: $description
      id: $id
      image: $image
      language_code: $language_code
      language_id: $language_id
      nickname: $nickname
      slug: $slug
      title: $title
    ) {
      ...VSContent
    }
  }
`;

// id: ID!
// name: String!
// nickname: String!
// type: VSPLATFORMTYPE!

// enum VSPLATFORMTYPE {
//   Display
//   Native
//   Search
//   Social
//   }

const VS_PLATFORM_RESPONSE = gql`
  fragment VSPlatform on VSPlatform {
    id
    name
    nickname
    type
  }
`;

export const GET_VS_ALL_PLATFORM = gql`
  ${VS_PLATFORM_RESPONSE}
  query vs_platformGetAll {
    vs_platformGetAll {
      ...VSPlatform
    }
  }
`;

// const VS_PLATFORM_RESPONSE_FOR_REDUCER_DROPDOWN_RESPONSE = gql`
//   fragment VSPlatform_For_Reducer_dropdown on VSPlatform {
//     id
//     name
//   }
// `;

// export const GET_VS_ALL_PLATFORM_FOR_REDUCER_DROPDOWN_RESPONSE = gql`
//   ${VS_PLATFORM_RESPONSE_FOR_REDUCER_DROPDOWN_RESPONSE}
//   query vs_platformGetAll {
//     vs_platformGetAll {
//       ...VSPlatform_For_Reducer_dropdown
//     }
//   }
// `;

export const ADD_VS_PLATFORM = gql`
  ${VS_PLATFORM_RESPONSE}
  mutation vs_platformCreate($name: String!, $nickname: String, $type: VSPLATFORMTYPE!) {
    vs_platformCreate(name: $name, nickname: $nickname, type: $type) {
      ...VSPlatform
    }
  }
`;

export const UPDATE_VS_PLATFORM = gql`
  ${VS_PLATFORM_RESPONSE}
  mutation vs_platformUpdate($id: ID!, $name: String!, $nickname: String, $type: VSPLATFORMTYPE!) {
    vs_platformUpdate(id: $id, name: $name, nickname: $nickname, type: $type) {
      ...VSPlatform
    }
  }
`;

export const DELETE_VS_PLATFORM = gql`
  mutation vs_platformDelete($id: [ID!]!) {
    vs_platformDelete(id: $id)
  }
`;

export const GET_VS_PLATFORM_FOR_URL_STRUCTURE = gql`
  query vs_platformUrlGet(
    $advertiser_id: ID
    $brand_id: ID
    $platform_id: ID
    $platform_nickname: String
  ) {
    vs_platformUrlGet(
      advertiser_id: $advertiser_id
      brand_id: $brand_id
      platform_id: $platform_id
      platform_nickname: $platform_nickname
    ) {
      epc_key
      url_structure
      platform {
        id
      }
      campaign_name
      adset_name
      ad_name
    }
  }
`;

// id: ID!
// name: String!
// nickname: String
// rev_share: String!
// status: VSSTATUS!

const VS_USER_RESPONSE = gql`
  fragment VSUser on VSUser {
    id
    name
    nickname
    rev_share
    status
    brands {
      id
      name
      website
      nickname
    }
    agency {
      id
      nickname
    }
    platforms {
      id
      name
    }
  }
`;

export const GET_VS_ALL_VS_USER = gql`
  ${VS_USER_RESPONSE}
  query vs_userGetAll {
    vs_userGetAll {
      ...VSUser
    }
  }
`;

export const GET_VS_ALL_USER_DROPDOWN = gql`
  query vs_userGetAll {
    vs_userGetAll {
      id
      name
    }
  }
`;

export const GET_VS_USER = gql`
  ${VS_USER_RESPONSE}
  query vs_userGetById($id: ID!) {
    vs_userGetById(id: $id) {
      ...VSUser
    }
  }
`;

export const ADD_VS_USER = gql`
  ${VS_USER_RESPONSE}
  mutation vs_userCreate(
    $agency_id: ID!
    $id: ID!
    $name: String!
    $nickname: String
    $rev_share: String!
    $status: VSSTATUS!
  ) {
    vs_userCreate(
      agency_id: $agency_id
      id: $id
      name: $name
      nickname: $nickname
      rev_share: $rev_share
      status: $status
    ) {
      ...VSUser
    }
  }
`;

export const UPDATE_VS_USER = gql`
  ${VS_USER_RESPONSE}
  mutation vs_userUpdate(
    $agency_id: ID!
    $id: ID!
    $name: String!
    $nickname: String
    $rev_share: String!
    $status: VSSTATUS!
  ) {
    vs_userUpdate(
      agency_id: $agency_id
      id: $id
      name: $name
      nickname: $nickname
      rev_share: $rev_share
      status: $status
    ) {
      ...VSUser
    }
  }
`;

export const VS_ASSIGN_BRAND_TO_USER = gql`
  mutation vs_userWithBrands($brand_id: [ID!]!, $user_id: ID!) {
    vs_userWithBrands(brand_id: $brand_id, user_id: $user_id)
  }
`;

export const VS_ASSIGN_PLATFORMS_TO_USER = gql`
  mutation vs_userWithPlatforms($platform_id: [ID!]!, $user_id: ID!) {
    vs_userWithPlatforms(platform_id: $platform_id, user_id: $user_id)
  }
`;

const VS_COUNTRY_GROUP_RESPONSE = gql`
  fragment VSCountryGroup on VSCountryGroup {
    id
    name
    nickname
  }
`;

export const GET_VS_COUNTRY_GROUP = gql`
  ${VS_COUNTRY_GROUP_RESPONSE}
  query vs_countryGroupGetAll {
    vs_countryGroupGetAll {
      ...VSCountryGroup
    }
  }
`;

const VS_COUNTRY_RESPONSE = gql`
  fragment VSCountry on VSCountry {
    id
    is_group
    iso
    name
  }
`;

export const GET_VS_COUNTRY = gql`
  ${VS_COUNTRY_RESPONSE}
  query vs_countryGetAll {
    vs_countryGetAll {
      ...VSCountry
    }
  }
`;

const VS_OPERATING_SYSTEM_RESPONSE = gql`
  fragment VSOperatingSystem on VSOperatingSystem {
    id
    name
    nickname
  }
`;

export const GET_VS_OPERATING_SYSTEM = gql`
  ${VS_OPERATING_SYSTEM_RESPONSE}
  query vs_operatingSystemGetAll {
    vs_operatingSystemGetAll {
      ...VSOperatingSystem
    }
  }
`;

const VS_DEVICE_RESPONSE = gql`
  fragment VSDevice on VSDevice {
    id
    name
    nickname
  }
`;

export const GET_VS_DEVICE = gql`
  ${VS_DEVICE_RESPONSE}
  query vs_deviceGetAll {
    vs_deviceGetAll {
      ...VSDevice
    }
  }
`;

//Will Remove
export const GET_ALL_AGENCY = gql`
  ${VS_AGENCY_RESPONSE}
  query getAllAgency {
    getAllAgency {
      ...VSAgency
    }
  }
`;

const VS_MEDIUM_RESPONSE = gql`
  fragment VSMedium_Response on VSMedium {
    id
    medium
  }
`;

export const ADD_VS_MEDIUM_GQL = gql`
  ${VS_MEDIUM_RESPONSE}
  mutation addVSMedium($name: String!) {
    addVSMedium(name: $name) {
      ...VSMedium_Response
    }
  }
`;

export const GET_ALL_VS_MEDIUM_GQL = gql`
  ${VS_MEDIUM_RESPONSE}
  query getAllMediums($userId: ID) {
    getAllMediums(userId: $userId) {
      ...VSMedium_Response
    }
  }
`;

export const ASSIGN_VS_MEDIUM_TO_USERGQL = gql`
  mutation assignUserMedium($mediumIds: [ID]!, $userId: ID!) {
    assignUserMedium(mediumIds: $mediumIds, userId: $userId)
  }
`;

export const GET_ALL_VS_USERS = gql`
  ${VS_MEDIUM_RESPONSE}
  query {
    getAllUsers {
      id
      name
      umsUserId
      nickName
      agency {
        id
        agencyName
      }
      brands {
        revenueShare
      }
      revShare
      incentiveThreshold
      incentivePercentage
      status
      mediums {
        ...VSMedium_Response
      }
    }
  }
`;

const REPORT_AGG_RESPONSE = gql`
  fragment ReportAgg on ReportAgg {
    date
    agencyRevenue
    TotalRevenue
    mediaBuyerUtm
    impressions
    mediaBuyerUtm
    pageViews
    sessions
    utmCampaign
    utmContent
    utmMedium
    utmSource
    utmTerm
    country
  }
`;

export const FIND_DATA_BY_NICK_NAME = gql`
  ${REPORT_AGG_RESPONSE}
  query findDataByNickName(
    $endDate: String!
    $isAgency: Boolean
    $startDate: String!
    $timeZone: String!
    $utmMedium: String
  ) {
    findDataByNickName(
      startDate: $startDate
      endDate: $endDate
      timeZone: $timeZone
      utmMedium: $utmMedium
      isAgency: $isAgency
    ) {
      ...ReportAgg
    }
  }
`;

export const VS_AGENCY_REPORT_BY_NICK_NAME = gql`
  ${REPORT_AGG_RESPONSE}
  query VSAgencyReportByNickName(
    $agencyNickName: String!
    $endDate: String!
    $startDate: String!
    $timeZone: String!
    $utmMedium: String
  ) {
    VSAgencyReportByNickName(
      agencyNickName: $agencyNickName
      endDate: $endDate
      startDate: $startDate
      timeZone: $timeZone
      utmMedium: $utmMedium
    ) {
      ...ReportAgg
    }
  }
`;

export const VS_REVENUE_PER_AD_NAME = gql`
  ${REPORT_AGG_RESPONSE}
  query revenuePerAdName(
    $endDate: String!
    $startDate: String!
    $timeZone: String!
    $userId: ID
    $utmCampaign: String!
    $utmMedium: String!
  ) {
    revenuePerAdName(
      endDate: $endDate
      startDate: $startDate
      timeZone: $timeZone
      userId: $userId
      utmCampaign: $utmCampaign
      utmMedium: $utmMedium
    ) {
      ...ReportAgg
    }
  }
`;

const REPORT_AD_BY_NAME_RESPONSE = gql`
  fragment ReportAdByName on ReportAdByName {
    date
    adName
    clicksFB
    impressionsFB
    leadsFB
    spendFB
    status
  }
`;

const REPORT_AD_BY_NAME_RESPONSE_FOR_CAMPAIGN = gql`
  fragment ReportAdByName on ReportAdByName {
    adName
    clicksFB
    impressionsFB
    leadsFB
    spendFB
    status
  }
`;

export const GET_REPORT_BY_AD_NAME = gql`
  ${REPORT_AD_BY_NAME_RESPONSE_FOR_CAMPAIGN}
  query reportByAdName(
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
    $vendorId: ID
  ) {
    reportByAdName(
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
      vendorId: $vendorId
    ) {
      ...ReportAdByName
    }
  }
`;
export const GET_FB_360_REPORT_BY_AD_NAME = gql`
  query fb360_adReportingByName(
    $asset_id: [ID]
    $end: Date
    $start: Date
    $timezone: String
    $user_id: ID
  ) {
    fb360_adReportingByName(
      start: $start
      timezone: $timezone
      end: $end
      user_id: $user_id
      asset_id: $asset_id
    ) {
      ad_name
      clicks
      impressions
      spend
      result
      adaccount_id
    }
  }
`;

export const GET_REPORT_PER_AD_NAME = gql`
  ${REPORT_AD_BY_NAME_RESPONSE}
  query reportPerAdName(
    $adName: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdName(adName: $adName, from: $from, timezone: $timezone, to: $to, userId: $userId) {
      ...ReportAdByName
    }
  }
`;

export const GET_UTM_SOURCES = gql`
  query utmSources {
    utmSources
  }
`;

export const UPDATE_SELECTED_ADS_STATUS_BY_NAME = gql`
  ${ASSET_UPDATE_RESPONSE_RESPONSE}
  mutation updateSelectedAdsStatusByName($adsFBNames: [String]!, $status: String!) {
    updateSelectedAdsStatusByName(adsFBNames: $adsFBNames, status: $status) {
      ...AssetUpdateResponse
    }
  }
`;

export const FB360_BULK_UPDATE_STATUS_BY_NAME = gql`
  mutation fb360_bulkUpdateStatusByName(
    $asset: FB360_ASSET
    $is_rule: String
    $status: FB360_STATUS
    $updateArray: [FB360_updateArray]
  ) {
    fb360_bulkUpdateStatusByName(
      asset: $asset
      is_rule: $is_rule
      status: $status
      updateArray: $updateArray
    )
  }
`;

export const GET_REPORT_PER_AD_NAME_HOURLY = gql`
  ${REPORT_AD_BY_NAME_RESPONSE}
  query reportPerAdNameHourly(
    $adName: String!
    $from: String!
    $timezone: String!
    $to: String!
    $userId: ID
  ) {
    reportPerAdNameHourly(
      adName: $adName
      from: $from
      timezone: $timezone
      to: $to
      userId: $userId
    ) {
      ...ReportAdByName
    }
  }
`;

export const VS_REVENUE_PER_AD_NAME_HOURLY = gql`
  ${REPORT_AGG_RESPONSE}
  query revenuePerAdNameHourly(
    $endDate: String!
    $startDate: String!
    $timeZone: String!
    $userId: ID
    $utmCampaign: String!
    $utmMedium: String!
  ) {
    revenuePerAdNameHourly(
      endDate: $endDate
      startDate: $startDate
      timeZone: $timeZone
      userId: $userId
      utmCampaign: $utmCampaign
      utmMedium: $utmMedium
    ) {
      ...ReportAgg
    }
  }
`;

export const VS_REVENUE_FIND_DATA_BY_USER_ID = gql`
  ${REPORT_AGG_RESPONSE}
  query findDataByUserId(
    $endDate: String
    $startDate: String
    $timeZone: String
    $userId: String!
    $utmMedium: String
  ) {
    findDataByUserId(
      endDate: $endDate
      startDate: $startDate
      timeZone: $timeZone
      userId: $userId
      utmMedium: $utmMedium
    ) {
      ...ReportAgg
    }
  }
`;

export const VS_FIND_VS_COUNTRY_REPORT = gql`
  ${REPORT_AGG_RESPONSE}
  query findVSCountryReport(
    $endDate: String!
    $startDate: String!
    $timeZone: String!
    $userId: ID
    $utmCampaign: String!
  ) {
    findVSCountryReport(
      endDate: $endDate
      startDate: $startDate
      timeZone: $timeZone
      userId: $userId
      utmCampaign: $utmCampaign
    ) {
      ...ReportAgg
    }
  }
`;

export const FIND_VS_REVENUE_BY_USER = gql`
  ${REPORT_AGG_RESPONSE}
  query findVSRevenueByUser(
    $endDate: String!
    $startDate: String!
    $timeZone: String!
    $userId: String
  ) {
    findVSRevenueByUser(
      endDate: $endDate
      startDate: $startDate
      timeZone: $timeZone
      userId: $userId
    ) {
      ...ReportAgg
    }
  }
`;

const GET_ADVERTISERS_RESPONSE = gql`
  fragment GetAllAdvertisers on Advertisers {
    id
    advertiserName
    accountsEmail
    paymentFrequency
    contactPerson {
      id
      name
      email
      skype
      phone
    }
    notes
  }
`;

export const GET_ALL_ADVERTISERS = gql`
  ${GET_ADVERTISERS_RESPONSE}
  query getAllAdvertisers {
    getAllAdvertisers {
      ...GetAllAdvertisers
    }
  }
`;

export const CREATE_ADVERTISERS = gql`
  ${GET_ADVERTISERS_RESPONSE}
  mutation createAdvertisers($input: AdvertisersInput, $contactPerson: ContactPersonInput) {
    createAdvertisers(input: $input, contactPerson: $contactPerson) {
      ...GetAllAdvertisers
    }
  }
`;

export const UPDATE_ADVERTISERS = gql`
  ${GET_ADVERTISERS_RESPONSE}
  mutation updateAdvertisers(
    $id: ID
    $advertisers: AdvertisersInput
    $contactPerson: ContactPersonInput
    $payment: ID
  ) {
    updateAdvertisers(
      id: $id
      advertisers: $advertisers
      contactPerson: $contactPerson
      payment: $payment
    ) {
      ...GetAllAdvertisers
    }
  }
`;

export const DELETE_ADVERTISERS = gql`
  mutation deleteAdvertisers($id: [ID]) {
    deleteAdvertisers(id: $id)
  }
`;

export const GET_ALL_BRANDS_IN_AGENCY = gql`
  query getAllBrands {
    getAllBrands {
      id
      brandName
      revenueShare
    }
  }
`;

export const ASSIGN_BRAND_TO_AGENCY = gql`
  mutation assignBrandToAgency(
    $agencyId: ID!
    $revShare: Float!
    $brandId: ID!
    $fromDate: String!
  ) {
    assignBrandToAgency(
      agencyId: $agencyId
      revShare: $revShare
      brandId: $brandId
      fromDate: $fromDate
    )
  }
`;

export const UPDATE_BRAND_IN_AGENCY = gql`
  mutation addUpdateAgencyBrandRevShare(
    $revShare: Float!
    $agencyId: ID!
    $brandId: ID!
    $fromDate: String!
  ) {
    addUpdateAgencyBrandRevShare(
      revShare: $revShare
      agencyId: $agencyId
      brandId: $brandId
      fromDate: $fromDate
    )
  }
`;

export const ADD_VIRAL_SPARKS_AGENCY = gql`
  mutation createAgency($input: AgencyInput!) {
    createAgency(input: $input) {
      id
    }
  }
`;

const BRAND_RESPONSE = gql`
  fragment Brand on Brand {
    id
    brandName
    advertisers {
      id
      advertiserName
    }
    nickName
    logo
    brandRootUrl
    revenueShare
    status
    notes
    linkStructure
  }
`;

export const GET_ALL_BRANDS = gql`
  ${BRAND_RESPONSE}
  query getAllBrands {
    getAllBrands {
      ...Brand
    }
  }
`;

export const CREATE_BRAND = gql`
  ${BRAND_RESPONSE}
  mutation createBrand($input: BrandInput, $advertisersId: ID) {
    createBrand(input: $input, advertisersId: $advertisersId) {
      ...Brand
    }
  }
`;

export const UPDATE_BRAND = gql`
  ${BRAND_RESPONSE}
  mutation updateBrand($id: ID!, $brand: BrandInput!, $advertiserId: ID!) {
    updateBrand(id: $id, brand: $brand, advertiserId: $advertiserId) {
      ...Brand
    }
  }
`;

export const DELETE_BRANDS = gql`
  mutation deleteBrand($id: [ID]) {
    deleteBrand(id: $id)
  }
`;

export const GET_BRANDS_BY_AGENCY_ID = gql`
  query brandsByAgencyId($id: ID!) {
    brandsByAgencyId(id: $id) {
      id
      brandName
      brandRootUrl
      logo
      notes
      revenueShare
      status
      linkStructure
      nickName
      assignedRevShare
      fromDate
    }
  }
`;

export const UPDATE_VIRAL_SPARKS_AGENCY = gql`
  mutation updateAgency($agency: AgencyInput!, $id: ID) {
    updateAgency(agency: $agency, id: $id) {
      id
      nickName
      agencyName
    }
  }
`;

export const DELETE_VIRAL_SPARKS_AGENCY = gql`
  mutation deleteAgency($id: [ID]) {
    deleteAgency(id: $id)
  }
`;

export const GET_ALL_VS_AGENCY = gql`
  query agencies {
    agencies {
      id
      name
    }
  }
`;

export const FIND_BY_DOMAIN_NAME = gql`
  query findByDomainName($domain: String) {
    findByDomainName(domain: $domain) {
      reportId
      domain
      utmCampaign
      utmSource
      utmMedium
      utmTerm
      utmContent
      TotalRevenue
      impressions
      pageViews
      sessions
      date
    }
  }
`;

export const GET_LOOKER_DATA = gql`
  query getLookerData($endDate: String, $startDate: String, $timeZone: String) {
    getLookerData(endDate: $endDate, startDate: $startDate, timeZone: $timeZone) {
      campaignName
      impressions
      pageViewsPerSessions
      paidImpressions
      revenue
      sessions
    }
  }
`;

export const ASSIGN_BRAND_TO_USER_BULK = gql`
  mutation assignBrandToUserBulk(
    $brandId: ID!
    $fromDate: String
    $revShare: Float!
    $toDate: String
    $userIds: [ID]!
  ) {
    assignBrandToUserBulk(
      brandId: $brandId
      fromDate: $fromDate
      revShare: $revShare
      toDate: $toDate
      userIds: $userIds
    )
  }
`;

export const UPDATE_BRAND_IN_USER = gql`
  mutation addUpdateUserBrandRevShare(
    $userId: ID!
    $revShare: Float!
    $brandId: ID!
    $fromDate: String!
  ) {
    addUpdateUserBrandRevShare(
      userId: $userId
      revShare: $revShare
      brandId: $brandId
      fromDate: $fromDate
    )
  }
`;

export const ADD_VS_USER_OLD = gql`
  mutation createUser($user: UserInput) {
    createUser(user: $user) {
      id
      name
    }
  }
`;

export const GET_BRANDS_BY_USER_ID = gql`
  query brandsByUserId($id: ID!) {
    brandsByUserId(id: $id) {
      id
      brandName
      brandRootUrl
      logo
      notes
      revenueShare
      status
      linkStructure
      nickName
      assignedRevShare
      fromDate
    }
  }
`;

export const UPDATE_VS_USER_OLD = gql`
  mutation updateUser($id: ID, $input: UserInput) {
    updateUser(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_VS_USER_OLD = gql`
  mutation deleteUser($id: [ID]) {
    deleteUser(id: $id)
  }
`;

export const DELETE_VS_USER = gql`
  mutation vs_userDelete($id: [ID!]!) {
    vs_userDelete(id: $id)
  }
`;

export const GET_UMS_VS_USERS = gql`
  query {
    users {
      id
      name
    }
  }
`;

export const GET_AGENCY_BY_ID = gql`
  query getAgencyById {
    getAgencyById {
      umsAgencyId
      agencyName
      userDefaultShare
      userDefaultThreshold
      userDefaultIncentivePercentage
      status
      revenueShare
    }
  }
`;

export const GET_VS_CONTENTS = gql`
  query getVSContents {
    getVSContents {
      id
      description
      image
      language
      title
      nickName
      link
      brands {
        brandName
      }
    }
  }
`;

export const DELETE_VS_CONTENT = gql`
  mutation deleteContentVS($id: [ID]) {
    deleteContentVS(id: $id)
  }
`;

export const CREATE_UPDATE_VSCONTENT = gql`
  mutation createUpdateContentVS($advertiserId: ID, $brandId: ID, $input: ContentInput!) {
    createUpdateContentVS(advertiserId: $advertiserId, brandId: $brandId, input: $input) {
      id
      description
      image
      language
      title
      nickName
      link
      brands {
        brandName
      }
    }
  }
`;

export const VS_URL_BUILDER_SETTINGS_DATA = gql`
  ${VS_OPERATING_SYSTEM_RESPONSE}
  ${VS_DEVICE_RESPONSE}
  ${VS_COUNTRY_RESPONSE}
  query vs_user_settings_data {
    vsOperatingSystem: vs_operatingSystemGetAll {
      ...VSOperatingSystem
    }
    vsDevice: vs_deviceGetAll {
      ...VSDevice
    }
    vsCountry: vs_countryGetAll {
      ...VSCountry
    }
  }
`;

//------------------NEW SCHEMA------------------

// agency_revenue: String
// impressions: String
// pageviews: String
// sessions: String
// total_revenue: String
// user_revenue: String
// utm_campaign: String
// utmc_id: String
// vs_revenue: String

const VS_CAMPAIGN_REPORT_RESPONSE = gql`
  fragment VSCampaignReports on VSCampaignReports {
    impressions
    pageviews
    sessions
    user_revenue
    utm_campaign
    utmc_id
    brand_name
    ad_id
    campaign_id
    ad_set_id
  }
`;

export const VS_CAMPAIGN_REPORT = gql`
  ${VS_CAMPAIGN_REPORT_RESPONSE}
  query vs_campaignReportsFromTo(
    $from: Date
    $timezone: String
    $to: Date
    $advertiser_id: ID
    $platform_id: ID
    $user_id: [ID]
    $is_forecast: Boolean
  ) {
    vs_campaignReportsFromTo(
      from: $from
      timezone: $timezone
      to: $to
      advertiser_id: $advertiser_id
      platform_id: $platform_id
      user_id: $user_id
      is_forecast: $is_forecast
    ) {
      ...VSCampaignReports
    }
  }
`;

const VS_COUNTRY_REPORT_RESPONSE = gql`
  fragment VSCountryReports on VSCountryReports {
    brand_name
    country
    impressions
    pageviews
    sessions
    user_revenue
  }
`;

export const VS_COUNTRY_REPORT = gql`
  ${VS_COUNTRY_REPORT_RESPONSE}
  query vs_countryReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $utmc_id: ID
    $user_id: [ID]
  ) {
    vs_countryReportsFromTo(
      advertiser_id: $advertiser_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      utmc_id: $utmc_id
      user_id: $user_id
    ) {
      ...VSCountryReports
    }
  }
`;

const VS_DEVICE_REPORT_RESPONSE = gql`
  fragment VSDeviceReports on VSDeviceReports {
    brand_name
    device
    impressions
    pageviews
    sessions
    user_revenue
  }
`;

export const VS_DEVICE_REPORT = gql`
  ${VS_DEVICE_REPORT_RESPONSE}
  query vs_deviceReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $utmc_id: ID
    $user_id: [ID]
  ) {
    vs_deviceReportsFromTo(
      advertiser_id: $advertiser_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      utmc_id: $utmc_id
      user_id: $user_id
    ) {
      ...VSDeviceReports
    }
  }
`;

const VS_OS_REPORT_RESPONSE = gql`
  fragment VSOsReports on VSOsReports {
    brand_name
    os
    impressions
    pageviews
    sessions
    user_revenue
  }
`;

export const VS_OS_REPORT = gql`
  ${VS_OS_REPORT_RESPONSE}
  query vs_osReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $utmc_id: ID
    $user_id: [ID]
  ) {
    vs_osReportsFromTo(
      advertiser_id: $advertiser_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      utmc_id: $utmc_id
      user_id: $user_id
    ) {
      ...VSOsReports
    }
  }
`;

const VS_MEDIUM_REPORT_RESPONSE = gql`
  fragment VSMediumReports on VSMediumReports {
    brand_name
    utm_medium
    impressions
    pageviews
    sessions
    user_revenue
  }
`;

export const VS_MEDIUM_REPORT_REPORT = gql`
  ${VS_MEDIUM_REPORT_RESPONSE}
  query vs_mediumReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $utmc_id: ID
    $user_id: [ID]
  ) {
    vs_mediumReportsFromTo(
      advertiser_id: $advertiser_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      utmc_id: $utmc_id
      user_id: $user_id
    ) {
      ...VSMediumReports
    }
  }
`;

const VS_PERFORMANCE_REPORT_RESPONSE = gql`
  fragment VSPerformanceReports on VSPerformanceReports {
    brand_name
    impressions
    pageviews
    report_time
    sessions
    user_revenue
    utm_campaign
    utmc_id
    user_name
    user_id
    content_id
    content_nickname
    language_code
    title
  }
`;

export const VS_PERFORMANCE_REPORT_HOUR = gql`
  ${VS_PERFORMANCE_REPORT_RESPONSE}
  query vs_performaceReportsHour(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $utmc_id: ID
    $user_id: [ID]
  ) {
    vs_performaceReportsHour(
      advertiser_id: $advertiser_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      utmc_id: $utmc_id
      user_id: $user_id
    ) {
      ...VSPerformanceReports
    }
  }
`;

export const VS_PERFORMANCE_REPORT_DAILY = gql`
  ${VS_PERFORMANCE_REPORT_RESPONSE}
  query vs_performaceReportsDay(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $utmc_id: ID
    $user_id: [ID]
  ) {
    vs_performaceReportsDay(
      advertiser_id: $advertiser_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      utmc_id: $utmc_id
      user_id: $user_id
    ) {
      ...VSPerformanceReports
    }
  }
`;

export const VS_PERFORMANCE_REPORT_HOUR_OF_THE_DAY = gql`
  ${VS_PERFORMANCE_REPORT_RESPONSE}
  query vs_performaceReportsHourOfDay(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $utmc_id: ID
    $user_id: [ID]
  ) {
    vs_performaceReportsHourOfDay(
      advertiser_id: $advertiser_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      utmc_id: $utmc_id
      user_id: $user_id
    ) {
      ...VSPerformanceReports
    }
  }
`;

export const VS_PERFORMANCE_LIVE_REPORT_HOUR_OF_THE_DAY = gql`
  query vs_performanceLiveReportsHourOfDay(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $advertiser_id: ID
    $ad_id: ID
    $adaccount_id: ID
    $campaign_id: ID
    $adset_id: ID
    $user_id: [ID]
  ) {
    vs_performanceLiveReportsHourOfDay(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      campaign_id: $campaign_id
      adset_id: $adset_id
      user_id: $user_id
    ) {
      ad_id
      adaccount_id
      adset_id
      brand_name
      campaign_id
      content_id
      content_nickname
      impressions
      language_code
      pageviews
      report_time
      sessions
      title
      user_id
      user_name
      user_revenue
    }
  }
`;

export const CREATIVE_BY_ADNAME = gql`
  query creativeByAdName($adName: String!) {
    creativeByAdName(adName: $adName) {
      storyId
      creative {
        adBody {
          id
          body
        }
        adTitle {
          id
          title
        }
        creativeImage {
          id
          imageUrl
          videoId
          s3Url
          imageHash
        }
      }
    }
  }
`;
export const VS_REVENUE_CRON_STATUS = gql`
  query vs_cronStatus($id: ID!) {
    vs_cronStatus(id: $id) {
      id
      type
      cron_hit_time
      cron_status_time
      is_updated
    }
  }
`;

export const VS_USER_PERFORMANCE_REPORT = gql`
  ${VS_PERFORMANCE_REPORT_RESPONSE}
  query vs_performaceReportsUser(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $brand_id: ID
    $user_id: [ID]
  ) {
    vs_performaceReportsUser(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      brand_id: $brand_id
      user_id: $user_id
    ) {
      agency_revenue
      ...VSPerformanceReports
    }
  }
`;

export const VS_ARTICLE_PERFORMANCE_REPORT = gql`
  ${VS_PERFORMANCE_REPORT_RESPONSE}
  query vs_performaceReportsArticle(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
    $is_forecast: Boolean
  ) {
    vs_performaceReportsArticle(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      user_id: $user_id
      is_forecast: $is_forecast
    ) {
      ...VSPerformanceReports
    }
  }
`;

const REPORT_AD_BY_USER_RESPONSE = gql`
  fragment ReportByUser on ReportByUser {
    name
    clicksFB
    impressionsFB
    leadsFB
    spendFB
    userId
  }
`;

export const GET_REPORT_BY_USER = gql`
  ${REPORT_AD_BY_USER_RESPONSE}
  query reportByUser($from: String!, $timezone: String!, $to: String!, $userId: ID) {
    reportByUser(from: $from, timezone: $timezone, to: $to, userId: $userId) {
      ...ReportByUser
    }
  }
`;

const VS_AD_ACCOUNT_REPORT_RESPONSE = gql`
  fragment VSLiveAdaccountReport on VSLiveAdaccountReport {
    adaccount_id
    brand_name
    impressions
    pageviews
    sessions
    user_revenue
  }
`;

export const VS_AD_ACCOUNT_LIVE_REPORT = gql`
  ${VS_AD_ACCOUNT_REPORT_RESPONSE}
  query vs_adAccountLiveReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
    $is_forecast: Boolean
  ) {
    vs_adAccountLiveReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      user_id: $user_id
      is_forecast: $is_forecast
    ) {
      ...VSLiveAdaccountReport
    }
  }
`;

const VS_LIVE_CAMPAIGN_REPORT_RESPONSE = gql`
  fragment VSLiveCampaignReport on VSLiveCampaignReport {
    brand_name
    campaign_id
    impressions
    pageviews
    sessions
    user_revenue
  }
`;

export const VS_CAMPAIGN_LIVE_REPORT = gql`
  ${VS_LIVE_CAMPAIGN_REPORT_RESPONSE}
  query vs_campaignLiveReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $adaccount_id: ID
    $user_id: [ID]
    $is_forecast: Boolean
  ) {
    vs_campaignLiveReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      user_id: $user_id
      adaccount_id: $adaccount_id
      is_forecast: $is_forecast
    ) {
      ...VSLiveCampaignReport
    }
  }
`;

export const VS_CAMPAIGN_GROUP_REPORTS = gql`
  ${VS_LIVE_CAMPAIGN_REPORT_RESPONSE}
  query vs_campaignGroupReportsFromTo(
    $advertiser_id: ID
    $asset: ASSET_TYPE
    $asset_id: [ID]
    $from: Date
    $is_forecast: Boolean
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_campaignGroupReportsFromTo(
      advertiser_id: $advertiser_id
      asset: $asset
      asset_id: $asset_id
      from: $from
      is_forecast: $is_forecast
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLiveCampaignReport
    }
  }
`;

export const VS_USER_LIVE_REPORT = gql`
  query vs_userLiveReportsFromTo(
    $adaccount_id: ID
    $advertiser_id: ID
    $from: Date
    $is_forecast: Boolean
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_userLiveReportsFromTo(
      adaccount_id: $adaccount_id
      advertiser_id: $advertiser_id
      from: $from
      is_forecast: $is_forecast
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      brand_name
      impressions
      pageviews
      sessions
      user_id
      user_name
      user_revenue
    }
  }
`;

const VS_LIVE_ADSET_REPORT_RESPONSE = gql`
  fragment VSLiveAdsetReport on VSLiveAdsetReport {
    adset_id
    brand_name
    impressions
    pageviews
    sessions
    user_revenue
  }
`;

export const VS_ADSET_LIVE_REPORT = gql`
  ${VS_LIVE_ADSET_REPORT_RESPONSE}
  query vs_adsetLiveReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
    $is_forecast: Boolean
    $campaign_id: ID
    $adaccount_id: ID
  ) {
    vs_adsetLiveReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      user_id: $user_id
      is_forecast: $is_forecast
      campaign_id: $campaign_id
      adaccount_id: $adaccount_id
    ) {
      ...VSLiveAdsetReport
    }
  }
`;

export const VS_ADSET_GROUP_REPORTS = gql`
  ${VS_LIVE_ADSET_REPORT_RESPONSE}
  query vs_adsetGroupReportsFromTo(
    $advertiser_id: ID
    $asset: ASSET_TYPE
    $asset_id: [ID]
    $from: Date
    $is_forecast: Boolean
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_adsetGroupReportsFromTo(
      advertiser_id: $advertiser_id
      asset: $asset
      asset_id: $asset_id
      from: $from
      is_forecast: $is_forecast
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLiveAdsetReport
    }
  }
`;

const VS_LIVE_AD_REPORT_RESPONSE = gql`
  fragment VSLiveAdReport on VSLiveAdReport {
    ad_id
    brand_name
    impressions
    pageviews
    sessions
    user_revenue
    utm_content
  }
`;

export const VS_AD_LIVE_REPORT = gql`
  ${VS_LIVE_AD_REPORT_RESPONSE}
  query vs_adLiveReportsFromTo(
    $advertiser_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
    $adaccount_id: ID
    $campaign_id: ID
    $adset_id: ID
    $is_forecast: Boolean
  ) {
    vs_adLiveReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      user_id: $user_id
      is_forecast: $is_forecast
      adaccount_id: $adaccount_id
      campaign_id: $campaign_id
      adset_id: $adset_id
    ) {
      ...VSLiveAdReport
    }
  }
`;

export const VS_AD_GROUP_REPORTS = gql`
  ${VS_LIVE_AD_REPORT_RESPONSE}
  query vs_adGroupReportsFromTo(
    $advertiser_id: ID
    $asset: ASSET_TYPE
    $asset_id: [ID]
    $from: Date
    $is_forecast: Boolean
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_adGroupReportsFromTo(
      advertiser_id: $advertiser_id
      asset: $asset
      asset_id: $asset_id
      from: $from
      is_forecast: $is_forecast
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLiveAdReport
    }
  }
`;

const PINTEREST_AD_ACCOUNT_REPORT_RESPONSE = gql`
  fragment pinterest_accountreport on pinterest_accountreport {
    account_id
    clicks
    conversion
    country
    currency
    impressions
    name
    spend
  }
`;

export const PINTEREST_GET_ACCOUNT_REPORT = gql`
  ${PINTEREST_AD_ACCOUNT_REPORT_RESPONSE}
  query pinterest_getAccountReport($end: Date, $start: Date!, $timezone: String) {
    pinterest_getAccountReport(end: $end, start: $start, timezone: $timezone) {
      ...pinterest_accountreport
    }
  }
`;

const PINTEREST_AD_GROUP_REPORT_RESPONSE = gql`
  fragment pinterest_adgroupreport on pinterest_adgroupreport {
    adgroup_id
    adgroup_name
    clicks
    conversion
    impressions
    spend
  }
`;

export const PINTEREST_GET_AD_GROUP_REPORT = gql`
  ${PINTEREST_AD_GROUP_REPORT_RESPONSE}
  query pinterest_getAdgroupReport($assetId: ID, $end: Date, $start: Date!, $timezone: String) {
    pinterest_getAdgroupReport(assetId: $assetId, end: $end, start: $start, timezone: $timezone) {
      ...pinterest_adgroupreport
    }
  }
`;

const PINTEREST_CAMPAIGN_REPORT_RESPONSE = gql`
  fragment pinterest_campaignreport on pinterest_campaignreport {
    campaign_id
    campaign_name
    clicks
    conversion
    impressions
    spend
  }
`;

export const PINTEREST_GET_CAMPAIGN_REPORT = gql`
  ${PINTEREST_CAMPAIGN_REPORT_RESPONSE}
  query pinterest_getCampaignReport($end: Date, $start: Date!, $timezone: String) {
    pinterest_getCampaignReport(end: $end, start: $start, timezone: $timezone) {
      ...pinterest_campaignreport
    }
  }
`;

export const PINTEREST_CAMPAIGN_GET_REPORT = gql`
  query pinterest_getCampaignReport($end: Date, $start: Date!, $timezone: String) {
    pinterest_getCampaignReport(end: $end, start: $start, timezone: $timezone) {
      campaign_id
      adName: campaign_name
      clicksFB: clicks
      conversion
      impressionsFB: impressions
      spendFB: spend
    }
  }
`;

const PINTEREST_AD_REPORT_RESPONSE = gql`
  fragment pinterest_adreport on pinterest_adreport {
    ad_id
    ad_name
    clicks
    conversion
    impressions
    spend
  }
`;

export const PINTEREST_GET_AD_REPORT = gql`
  ${PINTEREST_AD_REPORT_RESPONSE}
  query pinterest_getAdReport(
    $adBy: pinterest_ad_by_type
    $assetId: ID
    $end: Date
    $start: Date!
    $timezone: String
  ) {
    pinterest_getAdReport(
      adBy: $adBy
      assetId: $assetId
      end: $end
      start: $start
      timezone: $timezone
    ) {
      ...pinterest_adreport
    }
  }
`;

const VS_LIVE_PERFORMANCE_RESPONSE = gql`
  fragment VSLivePerformanceReports on VSLivePerformanceReports {
    user_revenue
    sessions
    pageviews
    impressions
  }
`;

export const VS_USERS_PERFORMANCE_REVENUE_REPORT = gql`
  ${VS_LIVE_PERFORMANCE_RESPONSE}
  query Vs_performanceLiveReportsUser(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $advertiser_id: ID
    $brand_id: ID
    $user_id: [ID]
  ) {
    vs_performanceLiveReportsUser(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      brand_id: $brand_id
      user_id: $user_id
    ) {
      ...VSLivePerformanceReports
      user_name
      user_id
    }
  }
`;

export const VS_ARTICLE_PERFORMANCE_REVENUE_REPORT = gql`
  ${VS_LIVE_PERFORMANCE_RESPONSE}
  query Vs_performanceLiveReportsArticle(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $advertiser_id: ID
    $adaccount_id: ID
    $ad_id: ID
    $campaign_id: ID
    $adset_id: ID
    $user_id: [ID]
  ) {
    vs_performanceLiveReportsArticle(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      adaccount_id: $adaccount_id
      ad_id: $ad_id
      campaign_id: $campaign_id
      adset_id: $adset_id
      user_id: $user_id
    ) {
      ...VSLivePerformanceReports
      title
      content_id
      content_nickname
      language_code
    }
  }
`;

export const VS_AD_COUNTRY_REPORT = gql`
  ${VS_COUNTRY_REPORT_RESPONSE}
  query Vs_adCountryReportsFromTo(
    $from: Date
    $to: Date
    $platform_id: ID
    $timezone: String
    $advertiser_id: ID
    $adset_id: ID
    $ad_id: ID
    $adaccount_id: ID
    $campaign_id: ID
    $user_id: [ID]
  ) {
    vs_adCountryReportsFromTo(
      from: $from
      to: $to
      platform_id: $platform_id
      timezone: $timezone
      advertiser_id: $advertiser_id
      adset_id: $adset_id
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      campaign_id: $campaign_id
      user_id: $user_id
    ) {
      ...VSCountryReports
    }
  }
`;

export const VS_AD_MEDIUM_REPORT = gql`
  query Vs_adMediumReportsFromTo(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $ad_id: ID
    $advertiser_id: ID
    $adset_id: ID
    $campaign_id: ID
    $adaccount_id: ID
    $user_id: [ID]
  ) {
    vs_adMediumReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      ad_id: $ad_id
      advertiser_id: $advertiser_id
      adset_id: $adset_id
      campaign_id: $campaign_id
      adaccount_id: $adaccount_id
      user_id: $user_id
    ) {
      utm_medium
      brand_name
      user_revenue
      sessions
      pageviews
      impressions
    }
  }
`;

export const VS_AD_OS_REPORT = gql`
  query Vs_adOsReportsFromTo(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $advertiser_id: ID
    $ad_id: ID
    $adaccount_id: ID
    $adset_id: ID
    $campaign_id: ID
    $user_id: [ID]
  ) {
    vs_adOsReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      adset_id: $adset_id
      campaign_id: $campaign_id
      user_id: $user_id
    ) {
      os
      brand_name
      user_revenue
      sessions
      pageviews
      impressions
    }
  }
`;

export const VS_AD_DEVICE_REPORT = gql`
  query Vs_adDeviceReportsFromTo(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $advertiser_id: ID
    $ad_id: ID
    $campaign_id: ID
    $adaccount_id: ID
    $adset_id: ID
    $user_id: [ID]
  ) {
    vs_adDeviceReportsFromTo(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      ad_id: $ad_id
      campaign_id: $campaign_id
      adaccount_id: $adaccount_id
      adset_id: $adset_id
      user_id: $user_id
    ) {
      device
      brand_name
      user_revenue
      sessions
      pageviews
      impressions
    }
  }
`;

export const VS_LIVE_PERFORMANCE_REPORT_DAILY = gql`
  query vs_performanceLiveReportsDay(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $advertiser_id: ID
    $ad_id: ID
    $adaccount_id: ID
    $adset_id: ID
    $campaign_id: ID
    $user_id: [ID]
  ) {
    vs_performanceLiveReportsDay(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      adset_id: $adset_id
      campaign_id: $campaign_id
      user_id: $user_id
    ) {
      ad_id
      adaccount_id
      adset_id
      agency_revenue
      brand_id
      brand_name
      campaign_id
      content_id
      content_nickname
      impressions
      language_code
      pageviews
      report_time
      sessions
      title
      user_id
      user_name
      user_revenue
    }
  }
`;

export const VS_LIVE_PERFORMANCE_REPORT_HOURLY = gql`
  query Vs_performanceLiveReportsHourly(
    $from: Date
    $to: Date
    $timezone: String
    $platform_id: ID
    $advertiser_id: ID
    $ad_id: ID
    $adaccount_id: ID
    $adset_id: ID
    $campaign_id: ID
    $user_id: [ID]
  ) {
    vs_performanceLiveReportsHour(
      from: $from
      to: $to
      timezone: $timezone
      platform_id: $platform_id
      advertiser_id: $advertiser_id
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      adset_id: $adset_id
      campaign_id: $campaign_id
      user_id: $user_id
    ) {
      ad_id
      adaccount_id
      adset_id
      agency_revenue
      brand_id
      brand_name
      campaign_id
      content_id
      content_nickname
      impressions
      language_code
      pageviews
      report_time
      sessions
      title
      user_id
      user_name
      user_revenue
    }
  }
`;

export const UPDATE_BULK_AD_SET_BUDGET = gql`
  mutation updateFbAssetBudget(
    $isLifeTime: Boolean!
    $budget: Int!
    $fbIds: [String]!
    $node: AssetStatusNode!
  ) {
    updateFbAssetBudget(isLifeTime: $isLifeTime, budget: $budget, fbIds: $fbIds, node: $node) {
      message
      success
    }
  }
`;
export const ASSET_STATUS_NODE_ENUM = [
  { name: "AD", value: "AD" },
  { name: "ADSET", value: "ADSET" },
  { name: "CAMPAIGN", value: "CAMPAIGN" },
];

export const VS_GET_BRAND_USER_AGENCY = gql`
  query Vs_brandUserAgency($user_id: ID!) {
    vs_brandUserAgency(user_id: $user_id) {
      id
      name
    }
  }
`;

const VS_LIVE_CREATIVE_REPORT = gql`
  fragment VSLiveCreativeReport on VSLiveCreativeReport {
    ad_id
    ad_account_id: adaccount_id
    content_id
    creative_group_id
    image_group_id
    impressions
    pageviews
    sessions
    user_revenue
    video_group_id
  }
`;

export const GQL_VS_CREATIVE_LIVE_ADS_REPORT = gql`
  ${VS_LIVE_CREATIVE_REPORT}
  query vs_creativeLiveAdsReports(
    $advertiser_id: ID
    $from: Date
    $group_by: CREATIVEGROUP!
    $group_id: ID!
    $is_forecast: Boolean
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_creativeLiveAdsReports(
      advertiser_id: $advertiser_id
      from: $from
      group_by: $group_by
      group_id: $group_id
      is_forecast: $is_forecast
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLiveCreativeReport
    }
  }
`;

export const GQL_VS_CREATIVE_LIVE_AD_ACCOUNT_REPORT = gql`
  ${VS_LIVE_CREATIVE_REPORT}
  query vs_creativeLiveAdAccountReports(
    $advertiser_id: ID
    $from: Date
    $group_by: CREATIVEGROUP!
    $group_id: ID!
    $is_forecast: Boolean
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_creativeLiveAdAccountReports(
      advertiser_id: $advertiser_id
      from: $from
      group_by: $group_by
      group_id: $group_id
      is_forecast: $is_forecast
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLiveCreativeReport
    }
  }
`;

export const GQL_VS_CREATIVE_LIVE_CONTENT_REPORT = gql`
  ${VS_LIVE_CREATIVE_REPORT}
  query vs_creativeLiveContentReports(
    $advertiser_id: ID
    $from: Date
    $group_by: CREATIVEGROUP!
    $group_id: ID!
    $is_forecast: Boolean
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_creativeLiveContentReports(
      advertiser_id: $advertiser_id
      from: $from
      group_by: $group_by
      group_id: $group_id
      is_forecast: $is_forecast
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLiveCreativeReport
    }
  }
`;

const VS_PERFORMANCE_DOMAIN_REPORT = gql`
  fragment VSLivePerformanceReports on VSLivePerformanceReports {
    ad_id
    adaccount_id
    adset_id
    agency_revenue
    brand_id
    brand_name
    campaign_id
    content_id
    content_nickname
    impressions
    language_code
    pageviews
    report_time
    sessions
    title
    user_id
    user_name
    user_revenue
  }
`;

export const GQL_VS_PERFORMANCE_DOMAIN_REPORTS = gql`
  ${VS_PERFORMANCE_DOMAIN_REPORT}
  query vs_performanceDomainReports(
    $ad_id: ID
    $adaccount_id: ID
    $adset_id: ID
    $advertiser_id: ID
    $campaign_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_performanceDomainReports(
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      adset_id: $adset_id
      advertiser_id: $advertiser_id
      campaign_id: $campaign_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLivePerformanceReports
    }
  }
`;

export const GQL_VS_PERFORMANCE_DOMAIN_ARTICLE_REPORTS = gql`
  ${VS_PERFORMANCE_DOMAIN_REPORT}
  query vs_performanceDomainArticleReports(
    $ad_id: ID
    $adaccount_id: ID
    $adset_id: ID
    $advertiser_id: ID
    $campaign_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_performanceDomainArticleReports(
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      adset_id: $adset_id
      advertiser_id: $advertiser_id
      campaign_id: $campaign_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLivePerformanceReports
    }
  }
`;

export const GQL_VS_PERFORMANCE_DOMAIN_ADACCOUNT_REPORTS = gql`
  ${VS_PERFORMANCE_DOMAIN_REPORT}
  query vs_performanceDomainAdaccountReports(
    $ad_id: ID
    $adaccount_id: ID
    $adset_id: ID
    $advertiser_id: ID
    $campaign_id: ID
    $from: Date
    $platform_id: ID
    $timezone: String
    $to: Date
    $user_id: [ID]
  ) {
    vs_performanceDomainAdaccountReports(
      ad_id: $ad_id
      adaccount_id: $adaccount_id
      adset_id: $adset_id
      advertiser_id: $advertiser_id
      campaign_id: $campaign_id
      from: $from
      platform_id: $platform_id
      timezone: $timezone
      to: $to
      user_id: $user_id
    ) {
      ...VSLivePerformanceReports
    }
  }
`;
