import { Select, Radio } from "antd";
import SelectInput from "../../../forms/SelectInput";

const { Option } = Select;

export default function TagsHandle(props) {
  const { tags = [], onChange = () => {} } = props;

  return (
    <div>
      <Select
        mode="tags"
        placeholder="Please select"
        value={tags}
        onChange={(e) => {
          onChange(e);
        }}
        style={{ width: "100%" }}
      >
        {tags.map((data, index) => (
          <Option key={"tags" + index} selected value={data}>
            {data}
          </Option>
        ))}
      </Select>

      {/* <SelectInput
        style={{ width: 150 }}
        name="reporter_id"
        value={tags}
        options={tags.map(({ data, index }) => ({ name: data, value: data }))}
        onChange={onChange}
      /> */}
    </div>
  );
}
