import { useCustomQuery } from "hooks/apolloClientHooks";
import { LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN, LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT, LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";
import { useState } from "react";
import {
  GET_VOLUUM_DAILY_REPORT,
  GET_VOLUUM_HOUR_OF_THE_DAY_REPORT,
  GET_VOLUUM_HOURLY_REPORT,
} from "shared/gql/gqlSchema/tiktok";
import {
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_ADGROUP,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_IS_DAILY,
  REPORT_TYPE_IS_HOURLY,
  REPORT_VOLUUM_DAILY_REPORT,
  REPORT_VOLUUM_HOUR_OF_THE_DAY_REPORT,
  REPORT_VOLUUM_HOURLY_REPORT,
} from "shared/SharedKeys";


export default function useGooglePerformanceReportsQuery(reportType, extraParams = {}) {
  const {
    variables,
    from,
    to,
    timezone,
    type = "",
    platform_id,
    customer_id,
    campaign_id = "",
    adgroup_id = "",
    adaccount_id = "",
  } = extraParams;
  const [revenueReport, setRevenueReport] = useState([]);

  let queryObject = {};

  let query_id = "";

  let queryType = "";


  if (reportType === REPORT_TYPE_CAMPAIGN) {
    queryType = LEADGEN_REPORT_ASSET_TYPE_CAMPAIGN;
    query_id = campaign_id;
  } else if (reportType === REPORT_TYPE_ADGROUP) {
    query_id = adgroup_id;
    queryType = LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADSET;
  } else if (reportType === REPORT_TYPE_AD_ACCOUNT) {
    query_id = customer_id;
    queryType = LEADGEN_VOLUUM_REPORT_ASSET_TYPE_ADACCOUNT;
  }
  queryObject =
    type === REPORT_TYPE_IS_HOURLY
      ? {
          query: GET_VOLUUM_HOURLY_REPORT,
          element: REPORT_VOLUUM_HOURLY_REPORT,
          variables: {
            asset: queryType,
            asset_id: [query_id],
          },
        }
      : type === REPORT_TYPE_IS_DAILY
      ? {
          query: GET_VOLUUM_DAILY_REPORT,
          element: REPORT_VOLUUM_DAILY_REPORT,
          variables: {
            asset: queryType,
            asset_id: [query_id],
          },
        }
      : {
          query: GET_VOLUUM_HOUR_OF_THE_DAY_REPORT,
          element: REPORT_VOLUUM_HOUR_OF_THE_DAY_REPORT,
          variables: {
            asset: queryType,
            asset_id: [query_id],
          },
        };

  const { loading: loading_revenue, refetch } = useCustomQuery(queryObject?.query, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const reportElement = e?.[queryObject?.element];

      if (reportElement) {
        setRevenueReport(reportElement);
      }
    },

    variables: {
      from,
      to,
      timezone,
      platform_id,
      ...variables,
      ...queryObject?.variables,
    },
  });

  const refetch_revenue = () => {
    setRevenueReport([]);
    refetch();
  };

  return {
    loading_revenue,
    refetch_revenue,
    revenueReport,
    setRevenueReport,
  };
}
