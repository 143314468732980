import { Button, Col, Divider, Form, Row, Select, Space } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import { useLaunchContext } from "components/Launch/Context";
import LookButton from "components/LookButton";
import React, { useEffect, useState } from "react";
import {
  PLATFORM_TYPE_FACEBOOK,
  PLATFORM_TYPE_TIKTOK,
  SERVICE_TYPE_LEAD_GEN,
  SERVICE_TYPE_SAS,
  SERVICE_TYPE_VS,
  TIKTOK_AVATAR_URL,
} from "shared/enum/launchKeysAndEnum";
import { useSelector } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import MultiAdAccountForm from "./MultiAdAccountForm";
import cx from "cx";
import Loader from "components/loader";
import { PlusOutlined } from "@ant-design/icons";
import AvatarUpload from "components/Launch/Creatives/AvatarUpload";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import LookDrawer from "components/LookDrawer";
import ActionIconRender from "components/ActionIconRender";
import useAddNewIdentity from "components/Launch/launchHooks/useAddNewIdentity";
import SelectInput from "components/forms/SelectInput";

function AdAccount(props) {
  const { setFormStep = () => {}, addAccountCache = () => {} } = props;

  const {
    fetchAllData,
    getFacebookProfiles,
    profileData,
    instantLaunch,
    bucketData,
    serviceType,
    isTiktokPlatformLaunch,
    fetchtikTokAdaccount,
    productId,
    formData,
    setFormData,
    selectedProfileIds,
    setSelectedProfileIds,
    profile_id,
    setProfileId,
    generateTiktokCampaignObject,
    sasTrackingUrlFetch,
    sasTrackingLoading,
    sasTrackingUrl,
    adAccountValues,
    campaignObject,
    setAdAccountValues,
    generateCampaignObject,
    vsBrandsFetch,
    contentContentData,
    multiAdaccount_data = [],
    isValidLaunch,
    setIsValidLaunch,
    setSubmission,
    advertiserId,
    instantLaunchData,
  } = useLaunchContext();
  const { launchName, alias, campCounter } = adAccountValues;
  const { addNewIdentity, data_identity_id, loading_identity_id } = useAddNewIdentity();
  const [activeAdaccount, setActiveAdaccount] = useState({ index: 0, adAccountIndex: 0 });
  const [identityData, setIdentityData] = useState();
  const [rowProps, setRowProps] = useState({});
  const [tiktokIdentityAvatar, setTiktokIdentityAvatar] = useState("");
  const [visibleCreativeDrawer, setVisibleCreativeDrawer] = useState(false);

  const [expandedIndex, setExpandedIndex] = useState(0);
  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
      setActiveAdaccount();
    } else {
      setExpandedIndex(index);
      setActiveAdaccount({
        index: index,
        adAccountIndex: 0,
      });
    }
  };
  const showCreativeDrawer = (e, advertiserId) => {
    setRowProps({ displayname: e, advertiserId });
    setVisibleCreativeDrawer(true);
  };

  const { tiktokLaunchFetch = () => {}, tiktokLaunchRefetch } = useApiToStoreContext();
  const { userSettingData = {}, selectedWorkspace } =
    useSelector((state) => state?.userSettings) || {};
  const { tiktokLaunchConfig } = userSettingData;
  const { ad } = tiktokLaunchConfig || {};

  const isPageId = generateTiktokCampaignObject()?.isPage_Id;
  const { showBeneficiary: show_beneficiary } = generateCampaignObject();

  const handleValidLaunch = () => {
    const invalidAccounts = [];
    if (!isTiktokPlatformLaunch) {
      formData?.forEach(({ adAccount }, formDataIndex) => {
        adAccount?.forEach((item, inValidAdAccountIndex) => {
          const { pageId, pixel, customEventType, tracking_url, url } = item;
          let validAdaccountData;
          if (serviceType === SERVICE_TYPE_SAS) {
            validAdaccountData = pageId && pixel?.length > 0 && customEventType;
          } else if (serviceType === SERVICE_TYPE_VS) {
            validAdaccountData = pageId && customEventType;
          } else if (serviceType === SERVICE_TYPE_LEAD_GEN) {
            validAdaccountData =
              pageId &&
              customEventType &&
              url?.length === bucketData?.bucket_content?.length &&
              url?.every((obj) => obj?.links && obj?.params);
          }
          if (!validAdaccountData) {
            invalidAccounts.push({
              isValid: false,
              formDataIndex,
              inValidAdAccountIndex,
            });
          }
        });
      });

      return invalidAccounts;
    } else {
      formData?.forEach((adAccountDetails, formDataIndex) => {
        const { pixel, identityType, identityId, tracking_url } = adAccountDetails;
        const isCustomizedUser = identityType === "CUSTOMIZED_USER";
        // const hasIdentityId = !!identityId;
        const hasPixel = !!pixel;
        const hasTrackingUrl = !!tracking_url;
        const isValidIdentityType =
          !isCustomizedUser || (!!identityType && !!pixel && !!identityId);
        const isInvalidForCategoryLaunch = isCustomizedUser
          ? !isValidIdentityType || !hasTrackingUrl
          : !identityType || !hasPixel || !hasTrackingUrl;

        const isInvalidForNonCategoryLaunch = isCustomizedUser
          ? !isValidIdentityType
          : !hasPixel || !identityType;

        if (
          instantLaunch && serviceType === SERVICE_TYPE_LEAD_GEN
            ? isInvalidForCategoryLaunch
            : isInvalidForNonCategoryLaunch
        ) {
          invalidAccounts.push({
            isValid: false,
            formDataIndex,
          });
        }
      });

      return invalidAccounts;
    }
  };

  useEffect(() => {
    if (formData?.length > 0) {
      const invalidAccounts = handleValidLaunch();
      setIsValidLaunch(invalidAccounts);
    }
  }, [formData]);

  useEffect(() => {
    if (serviceType === SERVICE_TYPE_SAS) {
      if (isTiktokPlatformLaunch) {
        tiktokLaunchFetch();
      } else {
        sasTrackingUrlFetch();
      }
    }
    if (serviceType === SERVICE_TYPE_LEAD_GEN) {
      if (instantLaunch) {
        tiktokLaunchFetch();
      }
    }

    // if (serviceType === SERVICE_TYPE_VS) {
    //   vsBrandsFetch({
    //     variables: {
    //       advertiser_id: instantLaunch ? advertiserId : contentContentData[0]?.advertiser?.id,
    //     },
    //   });
    // }
  }, []);

  function onSubmit() {
    const data = isTiktokPlatformLaunch
      ? formData?.[activeAdaccount?.adAccountIndex]
      : formData?.[activeAdaccount?.index]?.adAccount?.[activeAdaccount?.adAccountIndex];

    const pixels = data?.customConversions?.pixels?.filter((item) => {
      const exactPixelValue = data?.pixel?.includes(item?.id);
      return exactPixelValue;
    });

    const platform_type = isTiktokPlatformLaunch
      ? PLATFORM_TYPE_TIKTOK?.toUpperCase()
      : PLATFORM_TYPE_FACEBOOK.toUpperCase();

    const cacheJson = formData?.[0]?.adAccount?.map((item) => {
      const updatedItem = { ...item, pixels: pixels };
      return {
        id: item?.adAccount,
        payload: updatedItem,
      };
    });
    const idMap = new Map();
    const firstOccuranceData = cacheJson?.filter((obj) => {
      if (!idMap.has(obj?.id)) {
        idMap.set(obj?.id, true);
        return true;
      }
      return false;
    });

    if (firstOccuranceData?.length > 0) {
      addAccountCache({
        variables: {
          ad_accounts_data: firstOccuranceData,
          platform_type,
          product_id: productId,
        },
      });
    }
    if (isTiktokPlatformLaunch) {
      const finalSubmissions = formData?.map((submissionData) => {
        let parseUrl = "";

        if (instantLaunch) {
          parseUrl = new URL(submissionData?.tracking_url);
          const { categoryId = "", alias = "" } = campaignObject?.[0];
          const finalAlias = instantLaunchData?.alias || alias;
          parseUrl.searchParams.set("category_id", instantLaunchData?.id || categoryId);
          if (finalAlias) {
            parseUrl.searchParams.set("category_name", finalAlias);
          }
        }
        return {
          advertiserId: submissionData?.adAccount,
          identityType: submissionData?.identityType,
          identityId: submissionData?.identityId,
          pixelId: submissionData?.pixel,
          ...(submissionData?.pageId && { pageId: submissionData?.pageId }),
          ...(submissionData?.tracking_url && {
            url: parseUrl.toString(),
          }),
        };
      });

      setSubmission({
        finalSubmissions: finalSubmissions,
        name: launchName,
        pixel: formData?.[0]?.pixel,
        campCounter,
      });
    } else {
      function createSubmissionObject(item, submissionData, pixelValue) {
        return {
          accountId: "act_" + item?.adAccount,
          profileId: submissionData?.profile_id,
          pageId: item?.pageId,
          ...(item?.pixel?.[0] && { pixelId: item?.pixel?.[0] }),
          customEventType: item?.customEventType,
          currency: multiAdaccount_data
            ?.filter(({ id }) => id === submissionData?.profile_id)?.[0]
            ?.res.find((ad_account) => ad_account?.adaccount_id === item?.adAccount)?.currency,
          customConversionId: item?.customConversionId,
          customEventStr: item?.customEventStr,
          pixelValue,
        };
      }

      const finalSubmissions = formData?.flatMap((submissionData) =>
        submissionData?.adAccount?.flatMap((item) =>
          item?.pixelValue?.length > 0
            ? item?.pixelValue?.map((pixelValue) =>
                createSubmissionObject(item, submissionData, pixelValue)
              )
            : createSubmissionObject(item, submissionData, null)
        )
      );

      setSubmission({
        finalSubmissions: finalSubmissions,
        name: launchName,
        alias,
      });
    }
    // showModal();
    setFormStep(2);
  }

  const adAccountDetails = (inValidIndex, index, item) => {
    const finalData = !isTiktokPlatformLaunch ? item?.adAccount : item;
    return (
      <div key={index} className="launch_profiles">
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {!isTiktokPlatformLaunch && (
              <div
                className={cx("adAccount", {
                  expanded: expandedIndex === index,
                  inValid: inValidIndex?.length > 0,
                })}
                onClick={() => {
                  handleToggle(index);
                }}
              >
                {expandedIndex === index ? (
                  <UpOutlined className="mr-10" />
                ) : (
                  <DownOutlined className="mr-10" />
                )}
                <p className="mb-0">
                  {
                    profileData?.fb360_profileGet?.filter(({ id }) => id === item?.profile_id)?.[0]
                      ?.name
                  }
                </p>
              </div>
            )}
            {(expandedIndex === index || isTiktokPlatformLaunch) && (
              <div
                className={cx("adAccount_list", {
                  active: activeAdaccount?.index === index,
                  inValid: !isTiktokPlatformLaunch && inValidIndex?.length > 0,
                  isTiktokPlatformLaunch,
                })}
              >
                <div className="add_adAccount">
                  <div>Ad Accounts</div>
                  <div
                    style={{
                      alignItems: "center",
                      boxShadow: "4px 0px 5px 0px rgba(0, 0, 0, 0.10)",
                      borderBottom: "1px solid  #404040 ",
                    }}
                    onClick={() => {
                      if (isTiktokPlatformLaunch) {
                        setFormData((prevItems) => {
                          const updatedItems = [...prevItems];
                          updatedItems.push({ adAccount: "" });

                          return updatedItems;
                        });
                        setActiveAdaccount({
                          index: index,
                          adAccountIndex: formData?.length,
                        });
                      } else {
                        setFormData((prevItems) => {
                          const updatedItems = [...prevItems];
                          const updatedItem = { ...updatedItems[index] };
                          const updatedAdAccounts = [...updatedItem.adAccount];
                          updatedAdAccounts.push({ adAccount: "", isDuplicate: false });
                          updatedItem.adAccount = updatedAdAccounts;
                          updatedItems[index] = updatedItem;

                          return updatedItems;
                        });
                      }
                    }}
                  >
                    <LookButton iconType="plus" className="adAccount-btn" />
                    Add new AdAccount
                  </div>
                </div>
                {finalData?.map((adAccountDetails, adAccountIndex) => {
                  let inValidAdAccountIndex = !isTiktokPlatformLaunch
                    ? inValidIndex?.findIndex(
                        (item) => adAccountIndex === item?.inValidAdAccountIndex
                      )
                    : isValidLaunch?.findIndex(
                        ({ formDataIndex }) => formDataIndex === adAccountIndex
                      );

                  return (
                    <div
                      className={cx("adAccountSelection", {
                        active: activeAdaccount?.adAccountIndex === adAccountIndex,
                        inValid: inValidAdAccountIndex !== -1,
                      })}
                    >
                      <div
                        // className={`adAccountSelection ${
                        //   activeAdaccount === item?.adAccount ? "active" : ""
                        // }`}
                        style={{
                          alignItems: "center",
                        }}
                        className="d-flex flex-grow-1"
                      >
                        <div className="d-flex">
                          <span className="mr-4" style={{ minWidth: 20 }}>
                            {adAccountIndex + 1}.
                          </span>
                        </div>

                        <div
                          className="flex-grow-1"
                          onClick={() =>
                            setActiveAdaccount({
                              index: index,
                              adAccountIndex: adAccountIndex,
                            })
                          }
                        >
                          <MultiAdAccountForm
                            index={index}
                            inValidAdAccountIndex={inValidAdAccountIndex !== -1}
                            isPageId={isPageId}
                            sasTrackingLoading={sasTrackingLoading}
                            sasTrackingUrl={sasTrackingUrl}
                            data={adAccountDetails}
                            setFormData={setFormData}
                            profileData={profileData}
                            show_beneficiary={show_beneficiary}
                            adAccountIndex={adAccountIndex}
                            ad={ad}
                            profileId={item?.profile_id}
                            showAdaccountList
                            bucketData={bucketData}
                            multiAdaccount_data={multiAdaccount_data}
                            adAccountsData={
                              isTiktokPlatformLaunch
                                ? multiAdaccount_data
                                : multiAdaccount_data?.filter(
                                    ({ id }) => id === item?.profile_id
                                  )?.[0]?.res
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <LookButton
                          iconType="edit"
                          className="adAccount-btn"
                          onClick={() =>
                            setActiveAdaccount({
                              index: index,
                              adAccountIndex: adAccountIndex,
                            })
                          }
                        />
                        <LookButton
                          iconType="copy"
                          className="adAccount-btn"
                          onClick={() => {
                            setActiveAdaccount({
                              index: index,
                              adAccountIndex: adAccountIndex + 1,
                            });

                            // Add the cloned data to the formData state as a new item
                            if (isTiktokPlatformLaunch) {
                              setFormData((prevItems) => {
                                const updatedItems = [...prevItems];
                                const clonedData = {
                                  ...updatedItems[adAccountIndex],
                                  isDuplicate: true,
                                };
                                updatedItems.push(clonedData);

                                return updatedItems;
                              });
                            } else {
                              setFormData((prevItems) => {
                                const updatedItems = [...prevItems];
                                const updatedItem = { ...updatedItems[index] };
                                const updatedAdAccounts = [...updatedItem.adAccount];
                                const clonedData = {
                                  ...updatedAdAccounts[adAccountIndex],
                                  isDuplicate: true,
                                };
                                updatedAdAccounts.push(clonedData);
                                updatedItem.adAccount = updatedAdAccounts;
                                updatedItems[index] = updatedItem;

                                return updatedItems;
                              });
                            }
                          }}
                        />
                        <LookButton
                          iconType="delete"
                          className="adAccount-btn"
                          disabled={
                            isTiktokPlatformLaunch
                              ? formData?.length === 1
                              : formData[index]?.adAccount?.length === 1
                          }
                          onClick={() => {
                            if (isTiktokPlatformLaunch) {
                              setFormData((prevItems) => {
                                const updatedItems = [...prevItems];

                                updatedItems.splice(adAccountIndex, 1);

                                return updatedItems;
                              });
                              setActiveAdaccount({
                                index: index,
                                adAccountIndex: adAccountIndex - 1,
                              });
                            } else {
                              setActiveAdaccount({
                                index: index,
                                adAccountIndex: adAccountIndex - 1,
                              });
                              setFormData((prevItems) => {
                                const updatedItems = [...prevItems];
                                const updatedItem = { ...updatedItems[index] };
                                const updatedAdAccounts = [...updatedItem.adAccount];
                                updatedAdAccounts.splice(adAccountIndex, 1);
                                updatedItem.adAccount = updatedAdAccounts;
                                updatedItems[index] = updatedItem;

                                return updatedItems;
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  useEffect(() => {
    if (isTiktokPlatformLaunch) {
      fetchtikTokAdaccount({ variables: { include_suspended: false } });
    } else if (!profileData) {
      getFacebookProfiles();
    }
  }, []);

  // useEffect(() => {

  useEffect(() => {
    if (multiAdaccount_data.length > 0) {
      // Create a new array of updated items
      let finalUpdatedItems;
      if (!isTiktokPlatformLaunch) {
        const updatedItems = multiAdaccount_data
          ?.filter?.(({ id }) => profile_id?.some((profileId) => id === profileId))
          ?.map(({ id, res }) => ({
            profile_id: id,
            adAccount: [
              {
                adAccount: res?.[0]?.adaccount_id,
                url: [],
                countries: [],
                multiCountries: false,
                campaignStatus: "PAUSED",
                alias: res?.[0]?.alias,
              },
            ],
          }));

        // Filter the formData array based on conditions and create finalUpdatedItems
        finalUpdatedItems = updatedItems?.flatMap?.((item1) => {
          const matchingItem = formData?.find((item2) => item2?.profile_id === item1?.profile_id);

          if (matchingItem) {
            // Merge the properties from item1 into matchingItem

            return matchingItem;
          } else {
            return item1;
          }
        });
      } else {
        finalUpdatedItems = [
          { adAccount: multiAdaccount_data?.[0]?.account_id, isDuplicate: false },
        ];
      }

      // Update the state with the finalUpdatedItems
      setFormData(finalUpdatedItems);
    }
  }, [multiAdaccount_data, profile_id]);

  const onCloseCreativeDrawer = () => {
    setRowProps({});
    setVisibleCreativeDrawer(false);
  };
  const creativeDrawerProps = {
    visible: visibleCreativeDrawer,
    onClose: onCloseCreativeDrawer,
    textBreadcrumb: ["Add Avatar"],
    size: 1200,
  };
  useEffect(() => {
    if (data_identity_id?.addNewIdentityId?.status === "Successful") {
      onCloseCreativeDrawer();
      setIdentityData({});
      setTiktokIdentityAvatar();
    }
  }, [data_identity_id]);
  function onSubmitAddIdentity() {
    addNewIdentity({
      variables: {
        displayName: rowProps?.displayname,
        advertiserIds: rowProps?.advertiserId,
        imageUri: tiktokIdentityAvatar,
      },
    });
  }

  // function getUniqueListBy(arr, key) {
  //   return [...new Map(arr.map((item) => [item[key], item])).values()];
  // }
  const [open, setOpen] = useState(false);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const size = 24;
  return (
    <div className="m-10">
      <>
        <LookDrawer {...creativeDrawerProps} className="no-body-space">
          {visibleCreativeDrawer && (
            <LookDrawerMainSideBar
              tabContent={[
                {
                  hoverTitle: "Add Creatives",
                  icon: () => <ActionIconRender iconType="pic-left" />,
                  noSpace: true,
                  component: (
                    <AvatarUpload
                      launch
                      {...rowProps}
                      campaignIndex="0"
                      productId={productId}
                      groupSelection
                      onClose={onCloseCreativeDrawer}
                      setTiktokIdentityAvatar={setTiktokIdentityAvatar}
                      tiktokIdentityAvatar={tiktokIdentityAvatar}
                      onSubmitAddIdentity={onSubmitAddIdentity}
                      loading_identity_id={loading_identity_id}
                    />
                  ),
                },
              ]}
            />
          )}
        </LookDrawer>
        <Form layout="vertical" onFinish={onSubmit}>
          <div className="d-flex flex-wrap">
            <div className="launch-ad-profile">
              <div className="launch-adaccount">
                <Row gutter={[12, 12]}>
                  <Col span={size}>
                    <LookTextInput
                      name="launchName"
                      label="Launch Name"
                      size="large"
                      className="look-form-input"
                      value={launchName}
                      onChange={(e) =>
                        setAdAccountValues({
                          ...adAccountValues,
                          launchName: e?.target?.value,
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={[12, 12]}>
                  <Col span={size}>
                    <LookTextInput
                      name="campCounter"
                      label="Camp Counter"
                      size="large"
                      className="look-form-input"
                      value={campCounter}
                      onChange={(e) =>
                        setAdAccountValues({
                          ...adAccountValues,
                          campCounter: e?.target?.value,
                        })
                      }
                    />
                  </Col>
                </Row>

                {isTiktokPlatformLaunch && (
                  <>
                    <span className="text-left error-text">
                      * Please choose identity name, adaccounts and avatar to create the identity
                      ID.
                    </span>
                    <Row gutter={[12, 12]}>
                      <Col span={8}>
                        <div style={{ width: 0, height: 0, opacity: 0 }}>
                          <input name="identityName"></input>
                        </div>
                        <LookTextInput
                          name="identityName"
                          label="Identity Name"
                          size="large"
                          className="look-form-input"
                          placeholder="Please enter identity name"
                          value={identityData?.identityName}
                          onChange={(e) => {
                            setIdentityData({ ...identityData, identityName: e.target.value });
                          }}
                        />
                      </Col>
                      <Col span={10}>
                        <SelectInput
                          search
                          multiple
                          label="Ad Account"
                          // loading={f360AdAccount_loading}
                          // refetch={f360AdAccount_refetch}
                          size="large"
                          placeholder="Select AdAccount"
                          name="adAccount"
                          value={identityData?.advertiserIds}
                          options={
                            multiAdaccount_data?.length === 0
                              ? []
                              : multiAdaccount_data?.map((item) => ({
                                  name: item?.name,
                                  value: item?.account_id,
                                }))
                          }
                          onChange={(e) => {
                            setIdentityData({ ...identityData, advertiserIds: e });
                          }}
                        />
                      </Col>
                      <Col span={4}>
                        <LookButton
                          className={cx("identity-button", "mt-26")}
                          icon={<PlusOutlined />}
                          size="large"
                          loading={loading_identity_id}
                          disabled={
                            !identityData?.identityName ||
                            !identityData?.advertiserIds ||
                            loading_identity_id
                          }
                          onClick={() =>
                            //temporary adding default avataar and disableing the drawer opening

                            // showCreativeDrawer(
                            //   identityData?.identityName,
                            //   identityData?.advertiserIds
                            // )
                            addNewIdentity({
                              variables: {
                                displayName: identityData?.identityName,
                                advertiserIds: identityData?.advertiserIds,
                                imageUri: TIKTOK_AVATAR_URL,
                              },
                            })
                          }
                        >
                          Add avatar
                        </LookButton>
                      </Col>
                    </Row>
                  </>
                )}
                {!isTiktokPlatformLaunch && (
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <p className="mb-5">Profiles</p>
                      <Select
                        mode="multiple"
                        className="look-form-input"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select Profile"
                        filterOption={filterOption}
                        open={open}
                        onDropdownVisibleChange={(e) => {
                          setOpen(e);
                          if (e === false) setSelectedProfileIds(profile_id);
                        }}
                        value={selectedProfileIds}
                        onChange={(e) => {
                          setSelectedProfileIds(e);
                        }}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: "8px 0",
                              }}
                            />
                            <Space
                              style={{
                                padding: "0 8px 4px",
                              }}
                            >
                              <Button
                                type="primary"
                                onClick={() => {
                                  setProfileId(selectedProfileIds);
                                  fetchAllData(selectedProfileIds);
                                  setOpen(false);
                                }}
                              >
                                Okay
                              </Button>
                            </Space>
                          </>
                        )}
                        options={profileData?.fb360_profileGet?.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        }))}
                      />
                    </Col>
                  </Row>
                )}
              </div>
              {!isTiktokPlatformLaunch && <p className="mb-10"> Profiles & Ad accounts</p>}
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  {formData?.length === 0 ? (
                    <Loader />
                  ) : !isTiktokPlatformLaunch ? (
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        {formData?.map((item, index) => {
                          const inValidIndex = isValidLaunch?.filter(
                            ({ formDataIndex }) => formDataIndex === index
                          );
                          return adAccountDetails(inValidIndex, index, item);
                        })}
                      </Col>
                    </Row>
                  ) : (
                    <>{adAccountDetails(0, 0, formData)}</>
                  )}
                </Col>
              </Row>
            </div>
            <div
              className="launch-ad-profile"
              // todo this will be removed
              style={{ borderRight: "none" }}
            >
              {activeAdaccount ? (
                <MultiAdAccountForm
                  key={
                    isTiktokPlatformLaunch
                      ? formData?.[activeAdaccount?.adAccountIndex]
                      : formData?.[activeAdaccount?.index]?.adAccount?.[
                          activeAdaccount?.adAccountIndex
                        ]
                  }
                  isPageId={isPageId}
                  profileId={formData?.[activeAdaccount?.index]?.profile_id}
                  sasTrackingLoading={sasTrackingLoading}
                  sasTrackingUrl={sasTrackingUrl}
                  data={
                    isTiktokPlatformLaunch
                      ? formData?.[activeAdaccount?.adAccountIndex]
                      : formData?.[activeAdaccount?.index]?.adAccount?.[
                          activeAdaccount?.adAccountIndex
                        ]
                  }
                  adAccountIndex={activeAdaccount?.adAccountIndex}
                  setFormData={setFormData}
                  index={activeAdaccount?.index}
                  profileData={profileData}
                  show_beneficiary={show_beneficiary}
                  ad={ad}
                  bucketData={bucketData}
                  multiAdaccount_data={multiAdaccount_data}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item>
                <Button onClick={() => setFormStep(0)} block className="submit-btn" size="large">
                  Previous Page
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={isValidLaunch?.length > 0}
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    </div>
  );
}

export default AdAccount;
