import ActionIconRender from "components/ActionIconRender";
import React from "react";

export const faqSubMenuPath = [
  {
    subMenuName: "FAQ",
    subMenuLink: "/faq",
    subMenuIcon: () => <ActionIconRender iconType="question" />,
  },
];
