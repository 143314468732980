import { Line } from "@ant-design/charts";
import { useSelector } from "react-redux";
import { LookChartPatternColors } from "../LookChartTheme";

export default function LookChartLine(props) {
  const { isThemeLight } = useSelector((state) => state?.userSettings) || {};
  const {
    isColorLimit = false,
    visibleColumns = [],
    data,
    xField = "date",
    yField = "value",
    seriesField = "type",
  } = props;

  const visibleColorsList = isColorLimit
    ? LookChartPatternColors?.filter((d, i) => {
        const { visible } = visibleColumns[i] || {};

        if (visible) return true;
        return false;
      })
    : LookChartPatternColors;

  // const fields = ["PROFIT", "ROI"];
  // let tt = [];

  // data.forEach((d) =>
  //   fields.forEach((dd) => tt.push({ date: d.date, value: JSON.parse(d[dd]), type: dd }))
  // );

  // const darkThemeSettings = isThemeLight ? {} : { theme: "dark" };

  const themeConfig = isThemeLight
    ? { backgroundColor: "#ffffff" }
    : { backgroundColor: "#1e272e" };

  const config = {
    height: 400,
    legend: false,
    renderer: "svg",
    smooth: true,
    padding: 40,
    data,
    xField,
    yField,
    seriesField,
    // theme: isThemeLight ? "light" : "dark",
    // defaultColor: "dark",
    // theme: "dark",
    theme: {
      styleSheet: { ...themeConfig },
      colors10: visibleColorsList,
      colors20: visibleColorsList,
    },
    // ...darkThemeSettings,
  };

  return (
    <>
      <Line {...config} />

      {/* <DebugPre content={debugContent} /> */}
    </>
  );
}
