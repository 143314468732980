import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { PINTEREST_USER } from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const PinterestUsers = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Pinterest-Users" */ "pages/dashboardPages/Pinterest/Users")
  )
);
const PinterestAccounts = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Pinterest-Accounts" */ "pages/dashboardPages/Pinterest/Accounts")
  )
);

const pinterestRoutes = [
  route("/pinterest/users", PinterestUsers, ROUTE_DASHBOARD, true, PINTEREST_USER),
  route("/pinterest/accounts", PinterestAccounts, ROUTE_DASHBOARD, true, PINTEREST_USER),
];

export default pinterestRoutes;
