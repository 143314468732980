import { Form, Button, Tooltip } from "antd";
import Loader from "../loader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ActionIconRender from "../ActionIconRender";
import SelectInput from "../forms/SelectInput";
import { ME_DATA, USER_SETTING_DATA } from "actions/actionTypes";

export function ExitGhostUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const meData = useSelector((state) => state?.userReducer?.me);

  function exit() {
    dispatch({
      type: ME_DATA,
      payload: { ...meData, ["pseudo-user"]: null, permissions: [], isPseudoUser: false },
    });
    history?.push("/");
  }

  return (
    <Tooltip title="Exit from ghost user" placement="right">
      <Button className="ghost-exit-btn p-0" type="link" onClick={exit}>
        <ActionIconRender iconType="logout" />
      </Button>
    </Tooltip>
  );
}

export default function GhostUserSelection(props) {
  const { usersRefetch } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const meData = useSelector((state) => state?.userReducer?.me);
  const { "pseudo-user": pseudoUser } = meData || {};
  const { userSettingData } = useSelector((state) => state?.userSettings) || {};
  const { users = [], users_loading = false } = userSettingData || {};

  function handle(e) {
    dispatch({
      type: ME_DATA,
      payload: { ...meData, ["pseudo-user"]: e, isPseudoUser: true, permissions: [] },
    });
    dispatch({ type: USER_SETTING_DATA, payload: { users, users_loading } });
    history.push("/");
  }

  return (
    <div className="position-relative">
      {users && users?.length === 0 && users_loading && <Loader elementCenter />}
      <Form layout="vertical">
        <div className="d-flex" style={{ minWidth: 132 }}>
          <div className="flex-grow-1">
            <SelectInput
              refetch={usersRefetch}
              loading={users_loading}
              className="mb-0"
              search
              label="Users List"
              value={pseudoUser}
              placeholder="Ghost user"
              onChange={handle}
              options={users
                ?.filter(({ is_active = true }) => is_active)
                ?.map(({ id, name }) => ({ name, value: id }))}
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
