import { Form, Select, Button, Tooltip } from "antd";
import LookDrawer from "../../../LookDrawer";
import { useState } from "react";
import MainForm from "../../view/MainForm";
import StoreAddForm from "pages/dashboardPages/Cms/Store/AddForm";
import { PRODUCT_BIT, BLOCK_BIT } from "../../view/PageBits";
import ActionIconRender from "../../../ActionIconRender";
import CMSSortableItem, { Wrapper } from "../CMSSortableItem";
import { useDispatch, useSelector } from "react-redux";
import { CMS_DATA, CMS_DATA_KEY_BLOCK_LIST, CMS_DATA_KEY_PRODUCT_LIST } from "actions/actionTypes";
import {
  GET_CMS_ALL_BLOCKS_ID_NAME_DESCRIPTION,
  GET_CMS_ALL_PRODUCTS_ID_NAME_DESCRIPTION,
  GET_CMS_COUNTRIES,
  GET_CMS_STORE_ID_NAME,
} from "shared/gql/gqlSchema/cmsGql";
import { useCustomQuery } from "hooks/apolloClientHooks";
import { SortableList } from "components/SortableList";
import Loader from "components/loader";

const { Option } = Select;

export default function SelectStoreHandle(props) {
  const { value = [], onChange = () => {}, type = "", mode, placeholder = "" } = props;
  const dispatch = useDispatch();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [formRenderType, setFormRenderType] = useState("");
  const [editId, setEditId] = useState();

  const cmsData = useSelector((state) => state?.cmsReducer?.cmsData);

  let fetchApi = "",
    dataKey = "",
    formType = "",
    reducer = "";

  if (type === "product") {
    fetchApi = GET_CMS_ALL_PRODUCTS_ID_NAME_DESCRIPTION;
    dataKey = "CMS_All_Products";
    formType = PRODUCT_BIT;
    reducer = CMS_DATA_KEY_PRODUCT_LIST;
  }
  if (type === "block") {
    fetchApi = GET_CMS_ALL_BLOCKS_ID_NAME_DESCRIPTION;
    dataKey = "CMS_All_Blocks";
    formType = BLOCK_BIT;
    reducer = CMS_DATA_KEY_BLOCK_LIST;
  }

  if (type === "store") {
    fetchApi = GET_CMS_STORE_ID_NAME;
    dataKey = "CMS_Stores";
    reducer = CMS_DATA_KEY_BLOCK_LIST;
  }

  const {
    data = [],
    loading,
    error,
    refetch,
  } = useCustomQuery(fetchApi, {
    notifyOnNetworkStatusChange: true,
    variables: {},
    onCompleted: (e) => {
      dispatch({
        type: CMS_DATA,
        payload: { ...cmsData, [reducer]: e?.[dataKey] },
      });
    },
  });

  const customProps = {};
  if (mode) {
    customProps.mode = mode;
  }

  const handleSelect = (e) => {
    onChange(e);
  };

  const removeItem = (removeIndex) => {
    const refreshValue = value?.filter((data, index) => removeIndex !== index);
    onChange(refreshValue);
  };

  const dragItems = () => {
    return (
      <div className="drag-item-wrapper">
        <SortableList
          items={value?.map((data) => ({ id: data }))}
          onChange={(e) => onChange(e?.map((d) => d?.id))}
          renderItem={(item, index) => {
            const itemData = (data && data[dataKey]?.filter(({ id }) => id === item?.id)[0]) || {};
            const CMSSortableItemProps = {
              index,
              itemData,
              remove: () => removeItem(index),
              editItem: type === "store" ? null : () => showDrawer("edit", item?.id),
            };
            return (
              <SortableList.Item id={item.id}>
                <CMSSortableItem key={`item-${item}`} {...CMSSortableItemProps} />
              </SortableList.Item>
            );
          }}
        />
      </div>
    );
  };

  const showDrawer = (type, id) => {
    setFormRenderType(type);
    setVisibleDrawer(true);
    if (id) {
      setEditId(id);
    }
  };

  const onCloseDrawer = () => {
    setFormRenderType("");
    setVisibleDrawer(false);
    setEditId(null);
  };

  const drawerProps = {
    title: "Add",
    icon: () => <ActionIconRender iconType="appstore" />,
    visible: visibleDrawer,
    onClose: onCloseDrawer,
    size: 1200,
    className: "no-body-space",
  };

  const formProps = {
    isNested: true,
    refetch,
    postType: formType,
  };

  const addFormProps = {
    ...formProps,
  };
  const editFormProps = {
    ...formProps,
    id: editId,
  };

  const {
    data: data_GET_CMS_COUNTRIES = [],
    loading: loading_GET_CMS_COUNTRIES,
    error: error_GET_CMS_COUNTRIES,
    refetch: refetch_GET_CMS_COUNTRIES,
  } = useCustomQuery(GET_CMS_COUNTRIES, {
    notifyOnNetworkStatusChange: true,
  });
  return (
    <>
      <LookDrawer {...drawerProps}>
        {visibleDrawer &&
          formRenderType === "add" &&
          (type === "store" ? (
            <StoreAddForm
              close={onCloseDrawer}
              refetch={refetch}
              allCountries={data_GET_CMS_COUNTRIES?.CMS_Countries}
            />
          ) : (
            <MainForm view="create" close={onCloseDrawer} {...addFormProps} />
          ))}
        {visibleDrawer && formRenderType === "edit" && (
          <MainForm view="edit" close={onCloseDrawer} {...editFormProps} />
        )}
      </LookDrawer>
      <Wrapper
        className="position-relative cpm-drag-list-handle"
        placeholder={value.length > 0 ? placeholder : ""}
      >
        {loading && <Loader elementCenter />}
        <div className="d-flex align-items-center mb-24">
          <div className="flex-grow-1">
            <Form layout="vertical">
              <Form.Item className="look-form-input mb-0">
                <Select
                  // mode="multiple"
                  {...customProps}
                  name="list"
                  className="w-100"
                  placeholder={placeholder}
                  onChange={handleSelect}
                  value={value}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cmsData &&
                    cmsData[reducer] &&
                    cmsData[reducer]?.map(({ id, name = "", title = "" }) => (
                      <Option key={id} value={id}>
                        {name || title}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
          <div>
            <Tooltip title="Add">
              <Button
                type="link"
                onClick={() => showDrawer("add")}
                icon={<ActionIconRender iconType="plus-circle" />}
              />
            </Tooltip>
          </div>
        </div>
        {dragItems()}
      </Wrapper>
    </>
  );
}
