import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Switch } from "antd";

const useCustomSwitch = (
  initialState = false,
  checkedChildren = "All",
  unCheckedChildren = "Specific"
) => {
  const [checked, setChecked] = useState(initialState);

  const handleChecked = (e) => {
    setChecked(e);
  };

  useEffect(() => {
    setChecked(initialState);
  }, [initialState]);

  const LookSwitch = ({ checked }) => {
    const handleChange = (e) => {
      handleChecked(e);
    };

    return (
      <Switch
        className="mr-8"
        style={{ backgroundColor: "#141414" }}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        checked={checked}
        onChange={handleChange}
      />
    );
  };

  LookSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
  };

  return { LookSwitch, checked, setChecked };
};

export default useCustomSwitch;
