import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import alasql from "alasql";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1 } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import useSasColumns from "pages/dashboardPages/SAS/useSasColumns";
import { REPORT_TYPE_AD, REPORT_TYPE_AD_ADSET, REPORT_TYPE_CAMPAIGN } from "shared/SharedKeys";
import moment from "moment";
import { dateFormat } from "reducers/userSettings";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import {
  G360_DAILY_REPORTING,
  G360_HOURLY_REPORTING,
  G360_HOUROFDAY_REPORTING,
} from "shared/gql/gqlSchema/g360Gql";
import { useSelector } from "react-redux";
import useGooglePerformanceReportsQuery from "hooks/googleHooks/useGooglePerformanceReportsQuery";
export default function PreformanceReport(props) {
  const {
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
    reportType,
    performanceType,
    queryType,
    timezone: timeZoneProps,
    customer_id,
    campaign_id,
    ad_set_id : adgroup_id,
    outerDate = false,
    from,
    to,
    type,
  } = props;
  const asset_id =
    performanceType === "customer"
      ? customer_id
      : performanceType === "adgroup"
      ? adgroup_id
      : performanceType === "campaign"
      ? campaign_id
      : 0;
  const [spendReport, setSpendReport] = useState([]);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone } = userSettings;
  const [
    getHourlySpendReport,
    {
      loading: loading_GET_HOURLY_REPORT,
      error: error_GET_HOURLY_REPORT,
      refetch: refetch_HOURLY_REPORT,
    },
  ] = useCustomLazyQuery(G360_HOURLY_REPORTING, {
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone.value,
      asset_id,
      asset: performanceType?.toUpperCase(),
    },
    onCompleted: (e) => {
      const spend = e?.g_hourlyReporting;
      setSpendReport(spend);
    },
  });
  const [
    getDailySpendReport,
    { loading: loading_DAILY_REPORT, error: error_DAILY_REPORT, refetch: refetch_DAILY_REPORT },
  ] = useCustomLazyQuery(G360_DAILY_REPORTING, {
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone.value,
      asset_id,
      asset: performanceType?.toUpperCase(),
    },
    onCompleted: (e) => {
      const spend = e?.g_dailyReporting;
      setSpendReport(spend);
    },
  });
  const [
    getHourOfTheDayReport,
    {
      loading: loading_HOUROFTHEDAY_REPORT,
      error: error_HOUROFTHEDAY_REPORT,
      refetch: refetch_HOUROFTHEDAY_REPORT,
    },
  ] = useCustomLazyQuery(G360_HOUROFDAY_REPORTING, {
    variables: {
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
      timezone: dateRangeTimezone?.timeZone.value,
      asset_id,
      asset: performanceType?.toUpperCase(),
    },
    onCompleted: (e) => {
      const spend = e?.g_hourOfDayReporting;
      setSpendReport(spend);
    },
  });
  const isPerformanceDaily = type === "isDaily";

  const [mergedReport, setMergedReport] = useState([]);
  const { outerFilterData, filterData } = useLookTableFilterData(persistKey);
  const [timeZone, setTimeZone] = useState({ value: timeZoneProps });
  const defaultDate = outerDate
    ? [from, to]
    : [moment().subtract(8, "d").format(dateFormat), moment().subtract(1, "d").format(dateFormat)];
  const defaultDateWeekly = [
    moment().subtract(7, "d").format(dateFormat),
    moment().format(dateFormat),
  ];
  const [rangeDates, setRangeDates] = useState(
    isPerformanceDaily ? [...defaultDateWeekly] : [...defaultDate]
  );
  const handleDateRange = (e) => {
    setTimeZone(e.timeZone);
    setRangeDates(e.rangeDates);
  };
  const dateRangeParams = {
    from: rangeDates[0],
    to: rangeDates[1],
    timezone: timeZone.value,
  };

  // NEW VOLUUM API

  const { loading_revenue, refetch_revenue, revenueReport, setRevenueReport } =
    useGooglePerformanceReportsQuery(reportType, {
      ...dateRangeParams,
      type,
      adgroup_id,
      platform_id: 3,
    });

  const refetch = () => {
    if (type === "isDaily") {
      refetch_revenue();
      refetch_DAILY_REPORT();
    } else if (type === "isHourly") {
      refetch_revenue();
      refetch_HOURLY_REPORT();
    } else if (type === "isHourOfTheDay") {
      refetch_revenue();
      refetch_HOUROFTHEDAY_REPORT();
    }
  };
  const loading =
    loading_revenue ||
    loading_GET_HOURLY_REPORT ||
    loading_DAILY_REPORT ||
    loading_HOUROFTHEDAY_REPORT;
  const valueCheck = (value, checkTotal = false) => {
    const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
    const valueFloat = parseFloat(valueCheck);
    if (checkTotal) return valueFloat.toFixed(2);
    return valueFloat;
  };
  const { gdnPerformanceColumns } = useSasColumns({ reportType });
  const initialColumns = [...gdnPerformanceColumns];
  let finalMerge = [];
  let finalMergeData = [];
  useEffect(() => {
    if (!loading) {
      alasql
        .promise(
          "SELECT b.clicks as googleClicks , * FROM ? as a FULL OUTER JOIN ? as b ON a.report_time = b.report_time",
          [revenueReport, spendReport]
        )
        .then((res) => {
          setMergedReport(res);
        })
        .catch(function (err) {
          console.log({ err });
        });
    }
  }, [loading, spendReport, revenueReport]);
  useEffect(() => {
    if (type === "isDaily") {
      getDailySpendReport();
    } else if (type === "isHourly") {
      getHourlySpendReport();
    } else if (type === "isHourOfTheDay") {
      getHourOfTheDayReport();
    }
  }, []);
  finalMerge = mergedReport;
  if (!loading) {
    finalMerge?.forEach((element, index) => {
      const {
        cost = 0,
        revenue = 0,
        googleClicks = 0,
        impressions = 0,
        estimate_revenue = 0,
        searches = 0,
        clicks = 0,
        sessions = 0,
        campaign_id,
        campaignId,
        adgroup_id,
        adsetId,
      } = element;
      const spendUpdate = cost.toFixed(2);
      const gross_margin = parseFloat(estimate_revenue) - parseFloat(spendUpdate);
      const ROAS = (parseFloat(gross_margin) / parseFloat(spendUpdate)) * 100 || 0;
      const CPC = parseFloat(spendUpdate) / parseFloat(clicks);
      const CPR_V = parseFloat(spendUpdate) / parseFloat(clicks);
      const RPV = parseFloat(estimate_revenue) / parseFloat(googleClicks);
      const RPL = parseFloat(estimate_revenue) / parseFloat(clicks);
      const CPV = parseFloat(spendUpdate) / parseFloat(googleClicks);
      const AD_CTR = (parseFloat(googleClicks) / parseFloat(impressions)) * 100 || 0;
      const LP_CTR = (parseFloat(searches) / parseFloat(googleClicks)) * 100;
      const CR = (parseFloat(clicks) / parseFloat(googleClicks)) * 100;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressions)) * 1000;
      finalMergeData.push({
        ...element,
        key: index,
        spend: spendUpdate,
        googleClicks,
        revenue,
        clicks,
        searches,
        impressions,
        sessions,
        gross_margin: valueCheck(gross_margin),
        ROAS: valueCheck(ROAS),
        CR: valueCheck(CR),
        CPV: valueCheck(CPV),
        CPC: valueCheck(CPC),
        AD_CTR: valueCheck(AD_CTR),
        LP_CTR: valueCheck(LP_CTR),
        CPM: valueCheck(CPM),
        RPL: valueCheck(RPL),
        CPR_V: valueCheck(CPR_V),
        RPV: valueCheck(RPV),
        campaign_id: campaign_id || campaignId,
        adgroup_id: adgroup_id || adsetId,
      });
    });
  }
  const searchCampaignObject =
    REPORT_TYPE_CAMPAIGN === reportType ? ["campaignId", "campaign_name", "categoryName"] : [];
  const searchAdSetObject =
    REPORT_TYPE_AD_ADSET === reportType ? ["adsetId", "adgroup_name", "categoryName"] : [];
  const searchAdObject = REPORT_TYPE_AD === reportType ? ["adId", "ad_name", "categoryName"] : [];
  const searchAdAccountObject =
    REPORT_TYPE_AD === reportType ? ["accountId", "name", "categoryName"] : [];
  const finalFilterData = outerFilterData(filterData(finalMergeData), {
    filterKeys: [
      ...searchAdSetObject,
      ...searchCampaignObject,
      ...searchAdObject,
      ...searchAdAccountObject,
    ],
  });
  const finalFilterDataWithDateSorted = finalFilterData?.sort(function (a, b) {
    let keyA = new Date(a.date);
    let keyB = new Date(b.date);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
  let total_spend = 0,
    total_googleClicks = 0,
    total_impressions = 0,
    total_revenue = 0,
    total_estimate_revenue = 0,
    total_clicks = 0,
    total_searches = 0,
    total_sessions = 0;
  for (const {
    spend = 0,
    googleClicks = 0,
    impressions = 0,
    estimate_revenue = 0,
    clicks = 0,
    searches = 0,
    sessions = 0,
    revenue = 0,
  } of finalFilterData) {
    total_spend += parseFloat(spend || 0);
    total_googleClicks += parseFloat(googleClicks || 0);
    total_impressions += parseFloat(impressions || 0);
    total_estimate_revenue += parseFloat(estimate_revenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_searches += parseFloat(searches || 0);
    total_sessions += parseFloat(sessions || 0);
    total_revenue += parseFloat(revenue || 0);
  }
  const total_gross_margin = parseFloat(total_estimate_revenue) - parseFloat(total_spend);
  const total_ROAS = (parseFloat(total_gross_margin) / parseFloat(total_spend)) * 100 || 0;
  const total_CPV = parseFloat(total_spend) / parseFloat(total_googleClicks);
  const total_CPC = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_AD_CTR = (parseFloat(total_googleClicks) / parseFloat(total_impressions)) * 100 || 0;
  const total_LP_CTR = (parseFloat(total_searches) / parseFloat(total_googleClicks)) * 100;
  const total_CPM = (parseFloat(total_spend) / parseFloat(total_impressions)) * 1000;
  const total_CPR_V = parseFloat(total_spend) / parseFloat(total_clicks);
  const total_RPV = parseFloat(total_estimate_revenue) / parseFloat(total_googleClicks);
  const total_RPL = parseFloat(total_estimate_revenue) / parseFloat(total_clicks);
  const total_CR = (parseFloat(total_clicks) / parseFloat(total_googleClicks)) * 100;
  const summaryData = {
    spend: valueCheck(total_spend, true),
    googleClicks: valueCheck(total_googleClicks, true),
    impressions: valueCheck(total_impressions),
    estimate_revenue: valueCheck(total_estimate_revenue, true),
    clicks: valueCheck(total_clicks, true),
    gross_margin: total_gross_margin.toFixed(2),
    revenue: valueCheck(total_revenue, true),
    ROAS: valueCheck(total_ROAS, true),
    CR: valueCheck(total_CR, true),
    CPV: valueCheck(total_CPV, true),
    CPC: valueCheck(total_CPC, true),
    AD_CTR: valueCheck(total_AD_CTR, true),
    LP_CTR: valueCheck(total_LP_CTR, true),
    CPM: valueCheck(total_CPM, true),
    searches: valueCheck(total_searches),
    sessions: valueCheck(total_sessions),
    CPR_V: valueCheck(total_CPR_V, true),
    RPV: valueCheck(total_RPV, true),
    // CPL: valueCheck(total_CPL, true),
    RPL: valueCheck(total_RPL, true),
  };
  const tableMainProps = {
    exportCSV: true,
    persistKey,
    isLineChart: true,
    isRowSelection: false,
    isDateRange: true,
    dateRangeProps: {
      noStore: true,
      defaultDate: isPerformanceDaily ? defaultDateWeekly : defaultDate,
      defaultTimeZone: timeZoneProps,
      onChange: handleDateRange,
    },
    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
    uniqueKey: queryType,
  };
  const debugContent = [
    { isRow: true, contentObjects: [{ finalMerge }, { finalMergeData }] },
    { isRow: true, contentObjects: [{ revenueReport }] },
  ];
  return (
    <div>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
