export const CREATIVE_TYPE_IMAGE = "IMAGE";
export const CREATIVE_TYPE_THUMBNAIL = "THUMBNAIL";
export const CREATIVE_TYPE_VIDEO = "VIDEO";
export const CREATIVE_TYPE_TITLE = "TITLE";
export const CREATIVE_TYPE_BODY = "BODY";
export const CREATIVE_TYPE_DESCRIPTION = "DESCRIPTION";
export const CREATIVE_TYPE_UPDATE = "UPDATE";
export const CREATIVE_TYPE_DELETE = "DELETE";
export const CHOOSE_EXISTING_TEMPLATE = "EXISTING_TEMPLATE";
export const CREATE_NEW_TEMPLATE = "CREATE_NEW_TEMPLATE";
export const CREATIVE_TYPE = "CREATIVE";
export const CREATIVE_TYPE_ALL_GROUPS = "ALL_GROUPS";
// New keys added
export const CREATIVE_MIME_TYPE_IMAGE = "image/png";
export const CREATIVE_MIME_TYPE_VIDEO = "video/mp4";
export const CREATIVE_MIME_TYPE_AUDIO = "audio/mp3";
export const CREATIVE_MIME_TYPE_DOCUMENT = "application/pdf";
export const CREATIVE_TYPE_AUDIO = "AUDIO";
export const CREATIVE_TYPE_TEXT = "TEXT";
export const CREATIVE_TYPE_AD = "AD";
export const CREATIVE_TYPE_AD_ACCOUNT = "AD_ACCOUNT";

//GROUP TYPE ENUM
export const GROUP_TYPE_STATIC = "STATIC";
export const GROUP_TYPE_DYNAMIC = "DYNAMIC";
export const GROUP_TYPE_CAROUSEL = "CAROUSEL";

//IDENTITY TYPE
export const IDENTITY_TYPE_CUSTOMIZED_USER = "CUSTOMIZED_USER";

//SPECIAL AD CATEGORIES
export const SPECIAL_AD_CATEGORIES_CREDIT = "CREDIT";
export const SPECIAL_AD_CATEGORIES_EMPLOYMENT = "EMPLOYMENT";
export const SPECIAL_AD_CATEGORIES_HOUSING = "HOUSING";
export const SPECIAL_AD_CATEGORIES_ISSUES_ELECTIONS_POLITICS = "ISSUES_ELECTIONS_POLITICS";
export const SPECIAL_AD_CATEGORIES_NONE = "NONE";
export const SPECIAL_AD_CATEGORIES_ONLINE_GAMBLING_AND_GAMING = "ONLINE_GAMBLING_AND_GAMING";

export const specialAdCategoriesOptions = [
  { name: "Credit", value: SPECIAL_AD_CATEGORIES_CREDIT },
  { name: "Employment", value: SPECIAL_AD_CATEGORIES_EMPLOYMENT },
  { name: "Hounsing", value: SPECIAL_AD_CATEGORIES_HOUSING },
  { name: "Issues Elections Poltics", value: SPECIAL_AD_CATEGORIES_ISSUES_ELECTIONS_POLITICS },
  { name: "None", value: SPECIAL_AD_CATEGORIES_NONE },
  { name: "Online Gambling and Gaming", value: SPECIAL_AD_CATEGORIES_ONLINE_GAMBLING_AND_GAMING },
];
