import { Button, Tooltip, Switch } from "antd";
import ActionIconRender from "components/ActionIconRender";
import { SortableList } from "components/SortableList";
import styled from "styled-components";

export const Wrapper = styled("div")`
  .ant-select {
    &.ant-select-show-search {
      &:not(.ant-select-open) {
        &::before {
          position: absolute;
          top: 50%;
          left: 11px;
          transform: translateY(-50%);
          z-index: 999;
          overflow: hidden;
          color: #bfbfbf;
          white-space: nowrap;
          text-overflow: ellipsis;
          pointer-events: none;
          content: ${({ placeholder }) => "'" + placeholder + "'"};
        }
      }
    }
  }
`;

const CMSSortableItem = (props) => {
  const { itemData, remove = () => {}, editItem, switchAction } = props;

  return (
    <li className="cms-drag-select-item">
      <div className="item-contnet">
        <SortableList.DragHandle className="main-content">
          <p className="value">
            <ActionIconRender iconType="threedot" />
            {itemData?.name || itemData?.title}
          </p>
        </SortableList.DragHandle>
        <div className="actions">
          {editItem && (
            <Tooltip title="Edit">
              <Button
                className="h-auto"
                type="link"
                onClick={editItem}
                icon={<ActionIconRender iconType="edit" />}
              />
            </Tooltip>
          )}
          {switchAction && (
            <Tooltip title="Is Menu?">
              <Switch
                className="h-auto"
                checkedChildren="True"
                unCheckedChildren="False"
                onChange={switchAction}
              />
            </Tooltip>
          )}
          <Tooltip title="Remove">
            <Button
              className="h-auto"
              type="link"
              onClick={remove}
              icon={<ActionIconRender iconType="close" />}
            />
          </Tooltip>
        </div>
      </div>
    </li>
  );
};

export default CMSSortableItem;
