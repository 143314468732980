import SelectInput from "components/forms/SelectInput";
import { useLaunchContext } from "../Context";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import { Button, Col, Divider, Form, Input, Row, Select, Space, Switch } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { CT_ADD_TEMPLATES } from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { PRODUCT_ID_SAS } from "shared/SharedKeys";
import {
  CREATE_NEW_TEMPLATE,
  CREATIVE_TYPE_BODY,
  CREATIVE_TYPE_DESCRIPTION,
  CREATIVE_TYPE_TITLE,
} from "shared/SharedKeys/launchKeys";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function SelectTargetingTemplate(props) {
  const {
    // Launch
    handleSelectTarget,
    selectedTarget,
    launchDraft_loading,
    chooseDraftOrNew = "",
    chooseDraft,
    chooseContinuseNew,
    draftData,
    chooseAdsetTemplate,
    visibleTargetModal,
    setVisibleTargetModal,
    templates,
    loading_templates,
    isTiktokPlatformLaunch,
    loading_bucket,
    loading_tiktok_templates,
    visibleGroupModal,
    handleSubmit,
    setVisibleCategoryModal,
    visibleChooseAdsetModal,
    setVisibleChooseAdsetModal,
    inputs,
    setInputs,
    launchLeadGenDraft_loading = false,
    submitGroupModal,
    setVisibleGroupModal,
    refetch_creatives_templates,
    // Creatives
    fetchTemplates,
    loading_adgroups,
    loading_images,
    productId,
    setChooseExistingTemplate,
    setVisibleLaunchDrawer,
    // Other
    optionProps,
    visibleCategoryModal,
  } = useLaunchContext();
  const { submitSelectTarget = () => {}, onCancel = () => {} } = props;
  const history = useHistory();
  useEffect(() => {
    if (visibleGroupModal) {
      fetchTemplates({
        variables: {
          creativeType: [CREATIVE_TYPE_TITLE, CREATIVE_TYPE_BODY, CREATIVE_TYPE_DESCRIPTION],
        },
      });
    }
  }, [visibleGroupModal]);

  const {
    nonAdGroup,
    title,
    description,
    body,
    adset_count,
    ad_count,
    creative_auto_select,
    utm_name,
    campaignCount,
  } = inputs;
  const inputRef = useRef(null);
  const [creatives_props, setCreatives_props] = useState({
    title: "",
    body: "",
    description: "",
  });
  let temp = "";
  const urlTags = () => {
    return (
      <div>
        {[{ name: "{Keywords}" }, { name: "{category}" }].map(({ name }) => (
          <LookButton
            className="tag-btn mr-5 mb-5"
            size="small"
            onClick={() => {
              if (temp) {
                const newValue =
                  creatives_props.title.substring(0, temp) +
                  name +
                  creatives_props.title.substring(temp, creatives_props.title?.length);
                setCreatives_props({
                  ...creatives_props,
                  title: newValue,
                });
              } else if (temp === 0) {
                const newValue = name + creatives_props.title;
                setCreatives_props({
                  ...creatives_props,
                  title: newValue,
                });
              } else {
                const newValue = creatives_props.title + name;
                setCreatives_props({
                  ...creatives_props,
                  title: newValue,
                });
              }
            }}
          >
            {name}
          </LookButton>
        ))}
      </div>
    );
  };
  const urlTags1 = () => {
    return (
      <div>
        {[{ name: "{Keywords}" }, { name: "{category}" }].map(({ name }) => (
          <LookButton
            className="tag-btn mr-5 mb-5"
            size="small"
            onClick={() => {
              if (temp) {
                const newValue =
                  creatives_props.body.substring(0, temp) +
                  name +
                  creatives_props.body.substring(temp, creatives_props.body?.length);
                setCreatives_props({
                  ...creatives_props,
                  body: newValue,
                });
              } else if (temp === 0) {
                const newValue = name + creatives_props.body;
                setCreatives_props({
                  ...creatives_props,
                  body: newValue,
                });
              } else {
                const newValue = creatives_props.body + name;
                setCreatives_props({
                  ...creatives_props,
                  body: newValue,
                });
              }
            }}
          >
            {name}
          </LookButton>
        ))}
      </div>
    );
  };
  const urlTags2 = () => {
    return (
      <div>
        {[{ name: "{Keywords}" }, { name: "{category}" }].map(({ name }) => (
          <LookButton
            className="tag-btn mr-5 mb-5"
            size="small"
            onClick={() => {
              if (temp) {
                const newValue =
                  creatives_props.description.substring(0, temp) +
                  name +
                  creatives_props.description.substring(temp, creatives_props.description?.length);
                setCreatives_props({
                  ...creatives_props,
                  description: newValue,
                });
              } else if (temp === 0) {
                const newValue = name + creatives_props.description;
                setCreatives_props({
                  ...creatives_props,
                  description: newValue,
                });
              } else {
                const newValue = creatives_props.description + name;
                setCreatives_props({
                  ...creatives_props,
                  description: newValue,
                });
              }
            }}
          >
            {name}
          </LookButton>
        ))}
      </div>
    );
  };
  // const onNameChange = (event) => {
  //   setCreatives_props({ title: event.target.value });
  // };
  const [addNewTemplates, { loading: loading_titles }] = useCustomMutation(CT_ADD_TEMPLATES, {
    onCompleted: (e) => {
      const element = e?.ct_addCommonTemplate?.data;
      if (element) {
        setCreatives_props({});
        refetch_creatives_templates();
        openNotification({ type: "success", message: "Record added" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.ct_addCommonTemplate?.message });
    },
  });

  if (
    launchDraft_loading ||
    loading_bucket ||
    loading_images ||
    loading_adgroups ||
    launchLeadGenDraft_loading
  )
    return <Loader />;
  function renderSelectInput() {
    if (visibleGroupModal) {
      return (
        <div>
          <Form layout="vertical" onFinish={handleSubmit}>
            <div className="d-flex flex-between">
              <div className="d-flex">
                <p className="mr-12">Non Adgroup</p>
                <Switch
                  checked={inputs.nonAdGroup}
                  onChange={(e) => setInputs({ ...inputs, nonAdGroup: e })}
                />
              </div>
              {inputs?.nonAdGroup && (
                <div className="d-flex">
                  <p className="mr-12">Include Videos</p>
                  <Switch
                    checked={inputs.includeVideos}
                    onChange={(e) => setInputs({ ...inputs, includeVideos: e })}
                  />
                </div>
              )}
              <div className="d-flex">
                <p className="mr-12">Creatives Auto select</p>
                <Switch
                  checked={inputs.creative_auto_select}
                  onChange={(e) => setInputs({ ...inputs, creative_auto_select: e })}
                />
              </div>
            </div>
            {nonAdGroup && creative_auto_select && (
              <>
                <div className="look-form-input mb-8">
                  <Form.Item label="Title">
                    <Select
                      style={{ width: "100%" }}
                      value={title}
                      onChange={(e) => setInputs({ ...inputs, title: e })}
                      placeholder="Select title"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <div style={{ padding: "0 12px 12px" }}>
                            <div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <Input
                                    placeholder="Please enter item"
                                    ref={inputRef}
                                    value={creatives_props?.title}
                                    onChange={(e) => setCreatives_props({ title: e.target.value })}
                                  />
                                </div>
                                <Button
                                  type="text"
                                  className="identity-button"
                                  icon={<PlusOutlined />}
                                  onClick={() => {
                                    setCreatives_props({});
                                    addNewTemplates({
                                      variables: {
                                        productId: productId,
                                        creative: creatives_props?.title,
                                        creativeType: CREATIVE_TYPE_TITLE,
                                      },
                                    });
                                  }}
                                >
                                  Add title
                                </Button>
                              </div>
                              <div className="d-flex mt-10">
                                <Button type="text" className="tag-btn mr-5 mb-5">
                                  {urlTags()}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      options={optionProps?.titles?.map(({ id, creative }) => ({
                        label: creative,
                        value: creative,
                      }))}
                    />
                  </Form.Item>
                </div>
                <div className="look-form-input mb-8">
                  <Form.Item label="Body">
                    <Select
                      style={{ width: "100%" }}
                      value={body}
                      onChange={(e) => setInputs({ ...inputs, body: e })}
                      placeholder="Select body"
                      search
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <div style={{ padding: "0 12px 12px" }}>
                            <div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <Input
                                    placeholder="Please enter item"
                                    ref={inputRef}
                                    value={creatives_props?.body}
                                    onChange={(e) => setCreatives_props({ body: e?.target?.value })}
                                  />
                                </div>
                                <Button
                                  type="text"
                                  className="identity-button"
                                  icon={<PlusOutlined />}
                                  onClick={() => {
                                    setCreatives_props({});
                                    addNewTemplates({
                                      variables: {
                                        productId: productId,
                                        creative: creatives_props?.body,
                                        creativeType: CREATIVE_TYPE_BODY,
                                      },
                                    });
                                  }}
                                >
                                  Add body
                                </Button>
                              </div>
                              <div className="d-flex mt-10">
                                <Button type="text" className="tag-btn mr-5 mb-5">
                                  {urlTags1()}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      options={optionProps?.body?.map(({ id, creative }) => ({
                        label: creative,
                        value: creative,
                      }))}
                    />
                  </Form.Item>
                </div>
                <div className="look-form-input mb-8">
                  <Form.Item label="Description">
                    <Select
                      style={{
                        width: "100%",
                      }}
                      value={description}
                      onChange={(e) => setInputs({ ...inputs, description: e })}
                      placeholder="Select Description"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <div style={{ padding: "0 12px 12px" }}>
                            <div>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <Input
                                    placeholder="Please enter item"
                                    ref={inputRef}
                                    value={creatives_props?.description}
                                    onChange={(e) => {
                                      setCreatives_props({
                                        description: e?.target?.value,
                                      });
                                    }}
                                  />
                                </div>
                                <Button
                                  type="text"
                                  className="identity-button"
                                  icon={<PlusOutlined />}
                                  onClick={() => {
                                    addNewTemplates({
                                      variables: {
                                        productId: productId,
                                        creative: creatives_props?.description,
                                        creativeType: CREATIVE_TYPE_DESCRIPTION,
                                      },
                                    });
                                    setCreatives_props({});
                                  }}
                                >
                                  Add description
                                </Button>
                              </div>
                              <div className="d-flex mt-10">
                                <Button type="text" className="tag-btn mr-5 mb-5">
                                  {urlTags2()}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      options={optionProps?.descriptions?.map(({ id, creative }) => ({
                        label: creative,
                        value: creative,
                      }))}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            <LookTextInput
              label="UTM"
              name="utm_name"
              value={utm_name}
              onChange={(e) => setInputs({ ...inputs, utm_name: e.target.value })}
              className="mb-8"
            />
            <Row gutter={12}>
              <Col span={12}>
                <LookTextInput
                  label="Adset per Campaign"
                  name="adset_count"
                  number
                  value={adset_count}
                  onChange={(e) => setInputs({ ...inputs, adset_count: parseInt(e) })}
                  className="mb-0"
                />
              </Col>
              <Col span={12}>
                <LookTextInput
                  label="Ads per Adsets"
                  name="ad_count"
                  value={ad_count}
                  number
                  onChange={(e) => setInputs({ ...inputs, ad_count: parseInt(e) })}
                  className="mb-0"
                />
              </Col>
            </Row>
            <div className="p-20">
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <Button
                    block
                    onClick={() => {
                      // setTemplatePreviewModal(true);
                      setVisibleGroupModal(false);
                      setVisibleChooseAdsetModal(true);
                    }}
                  >
                    Back
                  </Button>
                </Col>
                <Col span={12}>
                  <Button block type="primary" htmlType="submit" onClick={submitGroupModal}>
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      );
    }
    // else if (templatePreviewModal) {
    //   return (
    //     <UpdateForm
    //       targetingtemplate={
    //         isTiktokPlatformLaunch || isCategoryLaunch ? targettingTemplateData : targetingTemplate
    //       }
    //     />
    //   );
    // }
    else if (visibleTargetModal && visibleCategoryModal) {
      return (
        <>
          <p>How many campaigns do you want to launch?</p>
          <LookTextInput
            name="campaignCount"
            number
            value={campaignCount}
            onChange={(e) => setInputs({ campaignCount: parseInt(e) })}
            className="mb-0"
          />
          <div className="p-20">
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Button
                  block
                  onClick={() => {
                    setVisibleTargetModal(false);
                    setVisibleLaunchDrawer(false);
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setVisibleCategoryModal(false);
                    setVisibleChooseAdsetModal(true);
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </>
      );
    } else if (visibleTargetModal && visibleChooseAdsetModal) {
      return (
        <>
          <div className="flex-center">
            <LookButton type="primary" onClick={() => chooseAdsetTemplate()}>
              Continue with existing template
            </LookButton>
            <span className="px-4" />
            <LookButton
              type="primary"
              onClick={() => {
                setVisibleChooseAdsetModal(false);
                setChooseExistingTemplate(CREATE_NEW_TEMPLATE);
                if (productId === PRODUCT_ID_SAS && !isTiktokPlatformLaunch) {
                  setVisibleGroupModal(true);
                } else {
                  setVisibleTargetModal(false);
                }
              }}
            >
              Create new template
            </LookButton>
          </div>
          <div className="py-20">
            <Row justify="end">
              <Col span={4} className="mr-10">
                <Button block onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setVisibleChooseAdsetModal(false);
                    setVisibleTargetModal(false);
                  }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </>
      );
    } else if (
      visibleTargetModal &&
      !visibleGroupModal &&
      !visibleCategoryModal &&
      !visibleChooseAdsetModal
    )
      return (
        <>
          <SelectInput
            search
            className="mb-0"
            label="Select Adset Template"
            name="selectedTarget"
            loader={
              templates?.length === 0 && isTiktokPlatformLaunch
                ? loading_tiktok_templates
                : loading_templates
            }
            options={templates?.map(({ id, name }) => ({ name, value: id }))}
            value={selectedTarget}
            onChange={handleSelectTarget}
          />
          <div className="py-20">
            <Row justify="end">
              <Col span={4} className="mr-10">
                <Button block onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  onClick={submitSelectTarget}
                  disabled={!selectedTarget}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </>
      );
  }

  if (draftData !== null) {
    if (
      chooseDraftOrNew === "" &&
      draftData?.draft?.campaignObjectDraft
      // && draftData?.draft?.selectedTargetDraft
    )
      return (
        <>
          <div className="flex-center">
            <LookButton type="primary" onClick={chooseDraft}>
              Continue editing previous draft
            </LookButton>
            <span className="px-4" />
            <LookButton type="primary" onClick={chooseContinuseNew}>
              Create new launch
            </LookButton>
          </div>
          <div className="py-20">
            <Row justify="end">
              <Col span={4} className="mr-10">
                <Button block onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col span={4}>
                <Button block type="primary" htmlType="submit" onClick={submitSelectTarget}>
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </>
      );
    else {
      return <>{renderSelectInput()}</>;
    }
  }
  return <>{renderSelectInput()}</>;
}
