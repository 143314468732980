import { G360_GET_ALL_PROFILE, G360_GET_ALL_USER_PROFILE } from "shared/gql/gqlSchema/g360Gql";
import useApiToStoreQuery from "./useApiToStoreQuery";

export default function useGdnApis() {
  const {
    apiCall: gdnProfilesFetch = () => {},
    loading: gdnProfilesLoading,
    refetch: gdnProfilesRefetch,
    setDataSource: gdnProfilesConfigSource,
  } = useApiToStoreQuery({
    api: G360_GET_ALL_PROFILE,
    elementKey: "g_profileGetAll",
    storeKey: "g_profileGetAll",
  });

  const {
    apiCall: gdnUserProfilesFetch = () => {},
    loading: gdnUserProfilesLoading,
    refetch: gdnUserProfilesRefetch,
    setDataSource: gdnUserProfilesConfigSource,
  } = useApiToStoreQuery({
    api: G360_GET_ALL_USER_PROFILE,
    elementKey: "g_userProfileGetAll",
    storeKey: "g_userProfileGetAll",
  });

  return {
    gdnProfilesFetch,
    gdnProfilesLoading,
    gdnProfilesRefetch,
    gdnProfilesConfigSource,
    gdnUserProfilesFetch,
    gdnUserProfilesLoading,
    gdnUserProfilesRefetch,
    gdnUserProfilesConfigSource,
  };
}
