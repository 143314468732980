import Customer from "./Customer";
import Campaigns from "./Campaigns";
import User from "./User";
import LookTabRouting from "components/LookTabRouting";
import ActionIconRender from "components/ActionIconRender";
import { GOOGLE_AGENCY_ADMIN, GOOGLE_VIEW_TEST } from "shared/UserPermission/userPermissionList";
import Adgroups from "./Adgroup";

export default function GoogleReports() {
  const tabContent = [
    {
      link: "/user",
      route: "/user",
      title: "User",
      component: User,
      icon: () => <ActionIconRender iconType="user" />,
      permission: GOOGLE_AGENCY_ADMIN,
    },
    {
      link: "/customer",
      route: "/customer",
      title: "Customer",
      component: Customer,
      icon: () => <ActionIconRender iconType="user" />,
      permission: GOOGLE_VIEW_TEST,
    },
    {
      link: "/campaigns",
      route: "/campaigns",
      title: "Campaigns",
      component: Campaigns,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: GOOGLE_VIEW_TEST,
    },
    {
      link: "/adgroups",
      route: "/adgroups",
      title: "Ad Group",
      component: Adgroups,
      icon: () => <ActionIconRender iconType="adIcon" />,
      permission: GOOGLE_VIEW_TEST,
    },
  ];

  return (
    <div>
      <LookTabRouting dashboardHeader={{ title: "Google Reports" }} tabs={tabContent} />
    </div>
  );
}
