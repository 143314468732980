import cx from "classnames";
import { Button, Switch, Tooltip } from "antd";
import { useContext } from "react";
import ActionIconRender from "components/ActionIconRender";
import { TableContext } from "components/LookTable/fields";
import { SortableList } from "components/SortableList";
import LookButton from "components/LookButton";

const MainItem = (props) => {
  const {
    filterTitle = "",
    itemIndex,
    title,
    icon: Icon,
    fixed,
    hidden = false,
    chooseColumn,
  } = props;

  return (
    <div disabled={true} style={{ zIndex: 1040 }} className={cx("flex-between mt-4")}>
      <SortableList.DragHandle>
        <span>
          {Icon && <Icon className="mr-4" />}
          {filterTitle ? filterTitle : title}
        </span>
      </SortableList.DragHandle>
      <Switch
        size="small"
        checked={!hidden}
        disabled={fixed}
        onChange={(e) => {
          chooseColumn(itemIndex, !hidden);
        }}
      />
    </div>
  );
};

export default function ColumnSettingContent(props) {
  const { columns, chooseColumn, setColumns, callCustomColumn, renderColumnSetShift } = props;

  const {
    updateTableConfiguration = () => {},
    resetTableConfiguration = () => {},
    isCustomColumnSelected,
  } = useContext(TableContext);

  return (
    <div className="table-sortable-column-wrapper">
      <div
        disabled={true}
        style={{ zIndex: 1040 }}
        className={cx("flex-between border b-1 b-light")}
      >
        <div>
          <b>Columns</b>
        </div>
        <div>
          <LookButton
            className="border-0"
            tooltipTitle="Add new column set"
            tooltipPlacement="left"
            onClick={callCustomColumn}
            iconType="plus"
            size="small"
          />
          {!isCustomColumnSelected && (
            <Tooltip placement="topLeft" title="Reset">
              <Button
                type="link"
                // className="ml-8"
                size="small"
                icon={<ActionIconRender iconType="rollback" />}
                onClick={resetTableConfiguration}
              />
            </Tooltip>
          )}
        </div>
      </div>
      {renderColumnSetShift()}
      <div className="column-list">
        <SortableList
          items={columns?.map((data) => ({ ...data, id: data?.dataIndex }))}
          onChange={(e) => {
            updateTableConfiguration(e);
            setColumns(e);
          }}
          // idType="dataIndex"
          renderItem={(item, index) => {
            return !item?.nofilter ? (
              item?.fixed ? (
                <MainItem {...item} />
              ) : (
                <SortableList.Item
                  id={item.dataIndex}
                  //  idType="dataIndex"
                >
                  {/* <MainItem {...item} itemIndex={index} chooseColumn={chooseColumn} /> */}
                  {/* <SortableList.DragHandle> */}
                  <MainItem {...item} itemIndex={index} chooseColumn={chooseColumn} />
                  {/* </SortableList.DragHandle> */}
                </SortableList.Item>
              )
            ) : (
              <></>
            );
          }}
        />
      </div>
    </div>
  );
}
