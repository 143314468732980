import ActionIconRender from "components/ActionIconRender";
import {
  SCORECARD_VIEW_ADMIN,
  SCORECARD_VIEW_USER,
  UMS_SUPER_ADMIN,
} from "shared/UserPermission/userPermissionList";
import { CustomeIconWrapper } from "..";
import { MilestoneIcon, UnicornIcon } from "../../../components/svgComponents";

export default function useUnicornPath() {
  const unicornPath = [
    {
      name: "Unicorn",
      link: "/unicorn",
      icon: () => (
        <CustomeIconWrapper>
          <UnicornIcon />
        </CustomeIconWrapper>
      ),
      subMenu: [
        {
          subMenuName: "Dashboard",
          subMenuLink: "/unicorn-dashboard",
          subMenuIcon: () => <ActionIconRender iconType="dashboard" />,
          permission: SCORECARD_VIEW_USER,
        },
        {
          subMenuName: "Strategic Outcomes",
          subMenuLink: "/strategic-outcomes",
          subMenuIcon: () => (
            <CustomeIconWrapper>
              <MilestoneIcon />
            </CustomeIconWrapper>
          ),
          permission: SCORECARD_VIEW_USER,
        },
        {
          subMenuName: "Verifying Milestones",
          subMenuLink: "/verifying-milestones",
          subMenuIcon: () => (
            <CustomeIconWrapper>
              <MilestoneIcon />
            </CustomeIconWrapper>
          ),
          permission: SCORECARD_VIEW_USER,
        },
        {
          subMenuName: "Causative Milestones",
          subMenuLink: "/causative-milestones",
          subMenuIcon: () => (
            <CustomeIconWrapper>
              <MilestoneIcon />
            </CustomeIconWrapper>
          ),
          permission: SCORECARD_VIEW_USER,
        },
        {
          subMenuName: "Promised Actions",
          subMenuLink: "/promised-actions",
          subMenuIcon: () => (
            <CustomeIconWrapper>
              <MilestoneIcon />
            </CustomeIconWrapper>
          ),
          permission: SCORECARD_VIEW_USER,
        },
      ],
    },
  ];

  return { unicornPath };
}
