import {
  RocketOutlined,
  RocketFilled,
  RocketTwoTone,
  MediumOutlined,
  BuildOutlined,
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  DeleteOutlined,
  DeleteFilled,
  CopyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  EnterOutlined,
  DribbbleSquareOutlined,
  ReloadOutlined,
  UndoOutlined,
  RollbackOutlined,
  PartitionOutlined,
  StarOutlined,
  DollarCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  MessageOutlined,
  BarChartOutlined,
  RiseOutlined,
  CreditCardOutlined,
  FallOutlined,
  LineOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  PlusCircleFilled,
  PlusSquareOutlined,
  PlusSquareFilled,
  MinusCircleOutlined,
  MinusOutlined,
  LogoutOutlined,
  DesktopOutlined,
  LaptopOutlined,
  MobileOutlined,
  TabletOutlined,
  FileImageOutlined,
  FileImageFilled,
  FilePdfOutlined,
  FileWordOutlined,
  FilePptOutlined,
  FileZipOutlined,
  FileOutlined,
  FileDoneOutlined,
  MenuOutlined,
  UserOutlined,
  UserAddOutlined,
  LockOutlined,
  BellOutlined,
  SettingOutlined,
  GroupOutlined,
  DoubleRightOutlined,
  UpOutlined,
  DownOutlined,
  CalendarOutlined,
  ApiOutlined,
  HomeOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  FlagOutlined,
  FlagFilled,
  SyncOutlined,
  InfoCircleOutlined,
  InfoCircleFilled,
  ArrowRightOutlined,
  AppstoreOutlined,
  AppstoreAddOutlined,
  PicLeftOutlined,
  ExportOutlined,
  HourglassOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  CloseCircleFilled,
  HistoryOutlined,
  ThunderboltOutlined,
  PushpinOutlined,
  DownloadOutlined,
  SearchOutlined,
  ColumnHeightOutlined,
  CodeOutlined,
  CodepenOutlined,
  AudioMutedOutlined,
  BranchesOutlined,
  ShareAltOutlined,
  FacebookOutlined,
  BookOutlined,
  InstagramOutlined,
  TwitterOutlined,
  UploadOutlined,
  CloudUploadOutlined,
  QuestionCircleOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  QuestionCircleFilled,
  SendOutlined,
  PaperClipOutlined,
  EllipsisOutlined,
  FacebookFilled,
  TeamOutlined,
  NotificationOutlined,
  CarryOutOutlined,
  ArrowLeftOutlined,
  GlobalOutlined,
  HeatMapOutlined,
  LeftOutlined,
  RightOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  FolderOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  FileSearchOutlined,
  PicCenterOutlined,
  AlignLeftOutlined,
  FilterOutlined,
  WarningOutlined,
  WarningFilled,
  LinkOutlined,
  DatabaseOutlined,
  UngroupOutlined,
  ToolOutlined,
  LayoutOutlined,
  SecurityScanOutlined,
  SmileOutlined,
  FontSizeOutlined,
  FontColorsOutlined,
  AlignCenterOutlined,
  KeyOutlined,
  GoldOutlined,
  DeploymentUnitOutlined,
  ForkOutlined,
  ClusterOutlined,
  ContainerOutlined,
  LineChartOutlined,
  SelectOutlined,
  ApartmentOutlined,
  GatewayOutlined,
  AuditOutlined,
  TagsOutlined,
  SlackOutlined,
  GoogleOutlined,
  MoreOutlined,
  YahooOutlined,
  FolderOpenOutlined,
  UserDeleteOutlined,
  UsergroupDeleteOutlined,
  DashboardOutlined,
  NodeIndexOutlined,
  ImportOutlined,
  CompressOutlined,
  ScheduleOutlined,
  RobotOutlined,
  AlertOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  StrikethroughOutlined,
  CommentOutlined,
  LikeFilled,
  DislikeOutlined,
  LikeOutlined,
  DislikeFilled,
  FileTextOutlined,
  ChromeOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
  FundOutlined,
  HighlightOutlined,
  ScissorOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import {
  ApplicationBlockIcno,
  DotIcon,
  EyeLightIcon,
  MegaphoneIcno,
  TimeCircleIcno,
  TikTokIcon,
  LinkIcon,
  ContentIcon,
  AdvertiserIcon,
  TaboolaIcon,
  AdIcon,
  PinterestIcon,
  KeywordIcon,
  ViralSparksIcon,
  TableContentPlaceholder,
  VaroziIcon,
  MilestoneIcon,
  CRSIcon,
  PriceListIcon,
  UserAssigned,
  FileChangesRequired,
  Approval,
  SendNew,
  ClickupIcon,
  BlockQuoteIcon,
  RectangleList,
  SunAngle,
  Hook,
  Pain,
  ScriptOutline,
  Compliance,
  CTA,
  Music,
  Media,
  UploadFileCreative,
  ClickUp,
  RuleIcon,
  CoPilotIcon,
  CreativeCategories,
  CreativeReport,
  GreenTick,
  GrayTick,
  RedCross,
  YellowTick,
  OrangeTick,
  TagSwitch,
  PlayButton,
  CmsPublishPlane,
  LaunchList,
  LaunchTargeting,
  ThreeDot,
  Audio,
  Sort,
  Chrome,
  Notify,
  Script,
  Variable,
  Microphone,
  NotifyWhite,
  Spy,
  File,
  GroupedImages,
  GroupedVideos,
} from "../svgComponents";
import cx from "classnames";

function CustomeIconWrapper({ children, className = "", ...rest }) {
  return (
    <span role="img" aria-label="eye" className={cx("anticon", className)} {...rest}>
      {children}
    </span>
  );
}

export default function ActionIconRender(props) {
  const { iconType, className = "", style = {}, spin = false, ...rest } = props;

  function selectIcon() {
    switch (iconType) {
      case "rocket":
        return RocketOutlined;
      case "bold":
        return BoldOutlined;
      case "italic":
        return ItalicOutlined;
      case "underline":
        return UnderlineOutlined;
      case "strike-through":
        return StrikethroughOutlined;
      case "block-quote":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <BlockQuoteIcon />
          </CustomeIconWrapper>
        );
      case "click-up":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ClickUp />
          </CustomeIconWrapper>
        );
      case "code":
        return CodeOutlined;
      case "like":
        return LikeFilled;
      case "dislike":
        return DislikeOutlined;
      case "dislike-filled":
        return DislikeFilled;
      case "rocket-filled":
        return RocketFilled;
      case "rocket-two-tone":
        return RocketTwoTone;
      case "message":
        return MessageOutlined;
      case "medium":
        return MediumOutlined;
      case "build":
        return BuildOutlined;
      case "view":
        return EyeOutlined;
      case "eye":
        return EyeOutlined;
      case "eye-invisible":
        return EyeInvisibleOutlined;
      case "comment-message":
        return CommentOutlined;
      case "collage":
        return CompressOutlined;
      case "like-outlined":
        return LikeOutlined;
      case "Disclaimer-outlined":
        return ExclamationCircleOutlined;
      case "eye-light":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <EyeLightIcon />
          </CustomeIconWrapper>
        );
      case "time-circle":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <TimeCircleIcno />
          </CustomeIconWrapper>
        );
      case "control-outlined":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ControlOutlined />
          </CustomeIconWrapper>
        );
      case "deploymentUnit": //used in SAS Channel tab icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <DeploymentUnitOutlined />
          </CustomeIconWrapper>
        );
      case "mega-phone":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <MegaphoneIcno />
          </CustomeIconWrapper>
        );
      case "aplication-block":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ApplicationBlockIcno />
          </CustomeIconWrapper>
        );
      case "tiktok":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <TikTokIcon />
          </CustomeIconWrapper>
        );
      case "node-index":
        return NodeIndexOutlined;
      case "link-icon":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <LinkIcon />
          </CustomeIconWrapper>
        );
      case "content":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ContentIcon />
          </CustomeIconWrapper>
        );

      case "advertiser":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <AdvertiserIcon />
          </CustomeIconWrapper>
        );
      case "tablePlaceholder":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <TableContentPlaceholder />
          </CustomeIconWrapper>
        );

      case "taboola":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <TaboolaIcon />
          </CustomeIconWrapper>
        );

      case "adIcon":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <AdIcon />
          </CustomeIconWrapper>
        );

      case "pinterest":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <PinterestIcon />
          </CustomeIconWrapper>
        );

      case "keywordIcon":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <KeywordIcon />
          </CustomeIconWrapper>
        );

      case "vsIcon":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ViralSparksIcon />
          </CustomeIconWrapper>
        );

      case "varoziIcon":
        return (e) => {
          <CustomeIconWrapper {...e}>
            <VaroziIcon />
          </CustomeIconWrapper>;
        };

      case "crs-icon":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <CRSIcon />
          </CustomeIconWrapper>
        );
      case "price-list":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <PriceListIcon />
          </CustomeIconWrapper>
        );
      case "user-assigned":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <UserAssigned />
          </CustomeIconWrapper>
        );
      case "file-changes":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <FileChangesRequired />
          </CustomeIconWrapper>
        );
      case "approval":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Approval />
          </CustomeIconWrapper>
        );
      case "send-new":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <SendNew />
          </CustomeIconWrapper>
        );

      case "clickupIcon":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ClickupIcon />
          </CustomeIconWrapper>
        );

      case "rectangleList":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <RectangleList />
          </CustomeIconWrapper>
        );

      case "sunAngle":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <SunAngle />
          </CustomeIconWrapper>
        );
      case "hook":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Hook />
          </CustomeIconWrapper>
        );
      case "pain":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Pain />
          </CustomeIconWrapper>
        );
      case "script-outline":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ScriptOutline />
          </CustomeIconWrapper>
        );
      case "chrome":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Chrome />
          </CustomeIconWrapper>
        );
      case "compliance":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Compliance />
          </CustomeIconWrapper>
        );
      case "cta":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <CTA />
          </CustomeIconWrapper>
        );
      case "media":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Media />
          </CustomeIconWrapper>
        );
      case "music":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Music />
          </CustomeIconWrapper>
        );
      case "uploadCreative": // creative tracking
        return (e) => (
          <CustomeIconWrapper {...e}>
            <UploadFileCreative />
          </CustomeIconWrapper>
        );
      case "creativeCategories": // creative tracking => categories tab
        return (e) => (
          <CustomeIconWrapper {...e}>
            <CreativeCategories />
          </CustomeIconWrapper>
        );
      case "creativeReport": // creative tracking => creative report tab
        return (e) => (
          <CustomeIconWrapper {...e}>
            <CreativeReport />
          </CustomeIconWrapper>
        );
      case "greenTick": // creative tracking => creative report card green tick
        return (e) => (
          <CustomeIconWrapper {...e}>
            <GreenTick />
          </CustomeIconWrapper>
        );
      case "grayTick": // creative tracking => creative report card gray tick
        return (e) => (
          <CustomeIconWrapper {...e}>
            <GrayTick />
          </CustomeIconWrapper>
        );
      case "yellowTick": // creative tracking => creative report card yellow tick
        return (e) => (
          <CustomeIconWrapper {...e}>
            <YellowTick />
          </CustomeIconWrapper>
        );
      case "orangeTick": // creative tracking => creative report card orange tick
        return (e) => (
          <CustomeIconWrapper {...e}>
            <OrangeTick />
          </CustomeIconWrapper>
        );

      case "redCross": // creative tracking => creative report card red cross
        return (e) => (
          <CustomeIconWrapper {...e}>
            <RedCross />
          </CustomeIconWrapper>
        );

      case "threedot": // Cms
        return (e) => (
          <CustomeIconWrapper {...e}>
            <ThreeDot />
          </CustomeIconWrapper>
        );
      case "tagSwitch": // creative  => search switch button for tags
        return (e) => (
          <CustomeIconWrapper {...e}>
            <TagSwitch />
          </CustomeIconWrapper>
        );
      case "playButton": // creative  => play button using in creative reporting
        return (e) => (
          <CustomeIconWrapper {...e}>
            <PlayButton />
          </CustomeIconWrapper>
        );
      case "aeroPlane": // creative  => search switch button for tags
        return (e) => (
          <CustomeIconWrapper {...e}>
            <CmsPublishPlane />
          </CustomeIconWrapper>
        );
      case "launchList": // launch  => launch listing icon using in listing path
        return (e) => (
          <CustomeIconWrapper {...e}>
            <LaunchList />
          </CustomeIconWrapper>
        );
      case "launchTargeting": // launch  => launch targeting icon using in listing path
        return (e) => (
          <CustomeIconWrapper {...e}>
            <LaunchTargeting />
          </CustomeIconWrapper>
        );
      case "audio": // media library Drawer audio icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Audio />
          </CustomeIconWrapper>
        );
      case "sort": // media library sort icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Sort />
          </CustomeIconWrapper>
        );

      case "notifyDot": // media library sort icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Notify />
          </CustomeIconWrapper>
        );

      case "notifyWhiteDot": // media library sort icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <NotifyWhite />
          </CustomeIconWrapper>
        );

      case "script": //creative-builder script icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Script />
          </CustomeIconWrapper>
        );

      case "variable": //creative-builder variables icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Variable />
          </CustomeIconWrapper>
        );

      case "microphone": //creative-builder script icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Microphone />
          </CustomeIconWrapper>
        );

      case "spy": //industry insights route icon
        return (e) => (
          <CustomeIconWrapper {...e}>
            <Spy />
          </CustomeIconWrapper>
        );

      case "file":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <File />
          </CustomeIconWrapper>
        );

      case "images-group":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <GroupedImages />
          </CustomeIconWrapper>
        );
      case "videos-group":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <GroupedVideos />
          </CustomeIconWrapper>
        );

      case "google":
        return GoogleOutlined;
      case "disable":
        return StopOutlined;
      case "barChartLine":
        return BarChartOutlined;
      case "facebook-outlined":
        return FacebookOutlined;
      case "lineChart":
        return LineChartOutlined;
      case "rise":
        return RiseOutlined;
      case "fall":
        return FallOutlined;
      case "line":
        return LineOutlined;
      case "creditCard":
        return CreditCardOutlined;
      case "credit-card":
        return CreditCardOutlined;
      case "dollar":
        return DollarCircleOutlined;
      case "importIcon":
        return ImportOutlined;
      case "done":
        return CheckOutlined;
      case "check":
        return CheckOutlined;
      case "check-circle":
        return CheckCircleOutlined;
      case "check-circle-filled":
        return CheckCircleFilled;
      case "close":
        return CloseOutlined;
      case "close-circle":
        return CloseCircleOutlined;
      case "close-circle-filled":
        return CloseCircleFilled;
      case "alert-outlined":
        return AlertOutlined;
      case "edit":
        return EditOutlined;
      case "delete":
        return DeleteOutlined;
      case "home":
        return HomeOutlined;
      case "delete-filled":
        return DeleteFilled;
      case "copy":
      case "duplicate":
        return CopyOutlined;
      case "reload":
        return ReloadOutlined;
      case "undo":
        return UndoOutlined;
      case "rollback":
        return RollbackOutlined;
      case "sync":
        return SyncOutlined;
      case "priority":
        return PartitionOutlined;
      case "important":
        return StarOutlined;
      case "addSubtaks":
        return (e) => <EnterOutlined {...e} style={{ transform: "scaleX(-1)", ...e.style }} />;
      case "plus":
        return PlusOutlined;
      case "plus-circle":
        return PlusCircleOutlined;
      case "plus-circle-filled":
        return PlusCircleFilled;
      case "plus-square":
        return PlusSquareOutlined;
      case "plus-square-filled":
        return PlusSquareFilled;
      case "minus":
        return MinusOutlined;
      case "minus-circle":
        return MinusCircleOutlined;
      case "pause":
        return PauseOutlined;
      case "play":
        return PlayCircleOutlined;
      case "logout":
        return LogoutOutlined;
      case "desktop":
        return DesktopOutlined;
      case "arrow-left":
        return ArrowLeftOutlined;
      case "duration":
        return HourglassOutlined;
      case "clock":
        return ClockCircleOutlined;
      case "pin":
        return PushpinOutlined;
      case "time-frame":
        return HistoryOutlined;
      case "laptop":
        return LaptopOutlined;
      case "mobile":
        return MobileOutlined;
      case "tablet":
        return TabletOutlined;
      case "file-text":
        return FileTextOutlined;
      case "chrome-outlined":
        return ChromeOutlined;
      case "image":
        return FileImageOutlined;
      case "image-filled":
        return FileImageFilled;
      case "pdf-file":
        return FilePdfOutlined;
      case "word-file":
        return FileWordOutlined;
      case "ppt-file":
        return FilePptOutlined;
      case "zip-file":
        return FileZipOutlined;
      case "file-icon":
        return FileOutlined;
      case "file-done":
        return FileDoneOutlined;
      case "menu":
        return MenuOutlined;
      case "user":
        return UserOutlined;
      case "user-add":
        return UserAddOutlined;
      case "user-switch":
        return UserSwitchOutlined;
      case "usergroup-add":
        return UsergroupAddOutlined;
      case "usergroup-delete":
        return UsergroupDeleteOutlined;
      case "lock":
        return LockOutlined;
      case "bell":
        return BellOutlined;
      case "setting":
        return SettingOutlined;
      case "group":
        return GroupOutlined;
      case "double-up":
        return (e) => (
          <DoubleRightOutlined {...e} style={{ transform: "rotate(-90deg)", ...e.style }} />
        );
      case "double-down":
        return (e) => (
          <DoubleRightOutlined {...e} style={{ transform: "rotate(90deg)", ...e.style }} />
        );
      case "up":
        return UpOutlined;
      case "down":
        return DownOutlined;
      case "equal":
        return (e) => <PauseOutlined {...e} style={{ transform: "rotate(90deg)", ...e.style }} />;
      case "dot":
        return DotIcon;
      case "calendar":
        return CalendarOutlined;
      case "api":
        return ApiOutlined;
      case "flag":
        return FlagOutlined;
      case "flag-filled":
        return FlagFilled;
      case "info-circle":
        return InfoCircleOutlined;
      case "info-circle-filled":
        return InfoCircleFilled;
      case "arrow-right":
        return ArrowRightOutlined;
      case "appstore":
        return AppstoreOutlined;
      case "appstore-add":
        return AppstoreAddOutlined;
      case "pic-left":
        return PicLeftOutlined;
      case "left":
        return LeftOutlined;
      case "right":
        return RightOutlined;
      case "export":
        return ExportOutlined;
      case "download":
        return DownloadOutlined;
      case "upload":
        return UploadOutlined;
      case "question":
        return QuestionCircleOutlined;
      case "thunderbolt":
        return ThunderboltOutlined;
      case "search":
        return SearchOutlined;
      case "caret-down":
        return CaretDownOutlined;
      case "caret-up":
        return CaretUpOutlined;
      case "caret-right":
        return CaretRightOutlined;
      case "column-height":
        return ColumnHeightOutlined;
      case "codepen":
        return CodepenOutlined;
      case "branches":
        return BranchesOutlined;
      case "share-alt":
        return ShareAltOutlined;
      case "facebook":
        return FacebookOutlined;
      case "instagram":
        return InstagramOutlined;
      case "twitter":
        return TwitterOutlined;
      case "book":
        return BookOutlined;
      case "question-filled":
        return QuestionCircleFilled;
      case "send":
        return SendOutlined;
      case "send-45degree":
        return (e) => <SendOutlined {...e} style={{ transform: "rotate(-45deg)", ...e.style }} />;
      case "mute":
        return AudioMutedOutlined;
      case "paperclip":
        return PaperClipOutlined;
      case "ellipsis":
        return EllipsisOutlined;
      case "vertical-ellipsis":
        return (e) => (
          <EllipsisOutlined {...e} style={{ transform: "rotate(-90deg)", ...e.style }} />
        );
      case "yahoo":
        return YahooOutlined;
      case "team":
        return TeamOutlined;
      case "notification":
        return NotificationOutlined;
      case "carry-out":
        return CarryOutOutlined;
      case "global":
        return GlobalOutlined;
      case "heatmap":
        return HeatMapOutlined;
      case "ordered-list":
        return OrderedListOutlined;
      case "unordered-list":
        return UnorderedListOutlined;
      case "folder":
        return FolderOutlined;
      case "dribble":
        return DribbbleSquareOutlined;
      case "picture":
        return PictureOutlined;
      case "video-camera":
        return VideoCameraOutlined;
      case "file-search":
        return FileSearchOutlined;
      case "pic-center":
        return PicCenterOutlined;
      case "align-left":
        return AlignLeftOutlined;
      case "filter":
        return FilterOutlined;
      case "rules":
        return RuleIcon;
      case "copilot":
        return (e) => (
          <CustomeIconWrapper {...e}>
            <CoPilotIcon />
          </CustomeIconWrapper>
        );
      case "update-Payor/Beneficiary":
        return UserOutlined;
      case "warning":
        return WarningOutlined;
      case "warning-filled":
        return WarningFilled;
      case "link":
        return LinkOutlined;
      case "database":
        return DatabaseOutlined;
      case "ungroup":
        return UngroupOutlined;
      case "tool":
        return ToolOutlined;
      case "layout":
        return LayoutOutlined;
      case "security-scan":
        return SecurityScanOutlined;
      case "smile":
        return SmileOutlined;
      case "font-size":
        return FontSizeOutlined;
      case "font-colors":
        return FontColorsOutlined;
      case "align-center":
        return AlignCenterOutlined;
      case "key-outlined":
        return KeyOutlined;
      case "collection":
        return GoldOutlined;
      case "source":
        return DeploymentUnitOutlined;
      case "vertical":
        return ForkOutlined;
      case "cluster":
        return ClusterOutlined;
      case "container":
        return ContainerOutlined;
      case "tag":
        return TagsOutlined;
      case "select":
        return SelectOutlined;
      case "apartment":
        return ApartmentOutlined;
      case "gateway":
        return GatewayOutlined;
      case "audit":
        return AuditOutlined;
      case "slack":
        return SlackOutlined;
      case "cloudupload":
        return CloudUploadOutlined;
      case "more":
        return MoreOutlined;
      case "folder-open":
        return FolderOpenOutlined;
      case "unlink":
        return UserDeleteOutlined;
      case "dashboard":
        return DashboardOutlined;
      case "schedule":
        return ScheduleOutlined;
      case "exclamation-circle-outlined":
        return ExclamationCircleOutlined;
      case "creative":
        return HighlightOutlined;
      case "research":
        return FundOutlined;
      case "launch":
        return RocketOutlined;
      case "save":
        return SaveOutlined;
      case "scissor-outlined":
        return ScissorOutlined;
      default:
        return () => <></>;
    }
  }

  const Component = selectIcon();
  return <Component className={className} style={style} spin={spin} {...rest} />;
}
