import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import route, { ROUTE_DASHBOARD } from "../route";
import { PERMISSION_CRS_VIEW } from "shared/UserPermission/userPermissionList";

const CRSDashboard = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "CRSDashboard" */ "pages/dashboardPages/CRS/CRSDashboard")
  )
);
const CRSArticleRequests = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "CRSArticleRequests" */ "pages/dashboardPages/CRS/CRSArticleRequests"
    )
  )
);
const CRSCreatives = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "CRSCreatives" */ "pages/dashboardPages/CRS/CRSCreatives")
  )
);
const CRSSettings = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "CRSSettings" */ "pages/dashboardPages/CRS/CRSSettings")
  )
);

const crsRoutes = [
  route("/crs/crs-dashboard", CRSDashboard, ROUTE_DASHBOARD, false, PERMISSION_CRS_VIEW),
  route("/crs/article-requests", CRSArticleRequests, ROUTE_DASHBOARD, false, PERMISSION_CRS_VIEW),
  route("/crs/creatives", CRSCreatives, ROUTE_DASHBOARD, false, PERMISSION_CRS_VIEW),
  route("/crs/crs-settings", CRSSettings, ROUTE_DASHBOARD, false, PERMISSION_CRS_VIEW),
];

export default crsRoutes;
