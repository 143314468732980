import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7,
} from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import { useLaunchContext } from "components/Launch/Context";
import AdGroup from "components/Launch/Creatives/AdGroup";
import AvatarUpload from "components/Launch/Creatives/AvatarUpload";
import Body from "components/Launch/Creatives/Body";
import CreativeUpload, { CreativeVideoUpload } from "components/Launch/Creatives/CreativeUpload";
import Description from "components/Launch/Creatives/Description";
import GoogleCreativeImagesUpload from "components/Launch/Creatives/GoogleCreativeImagesUpload";
import GoogleCreativeImagesUploadWrapper from "components/Launch/Creatives/GoogleCreativeImagesUpload/GoogleCreativeImagesUploadWrapper";
import Title from "components/Launch/Creatives/Title";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import TabRender from "components/TabRender";
import { usePermissionCheck } from "hooks";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import BenchmarkForm from "pages/dashboardPages/SAS/Collections/Categories/Category/BenchmarkForm/benchmarkForm";
import CategoryHistoryReport from "pages/dashboardPages/SAS/Collections/Categories/Category/HistoryReport";
import UpdateForm from "pages/dashboardPages/SAS/Collections/Categories/Verticals/UpdateForm";

import { useEffect, useState } from "react";
import {
  CONTENT_TYPE_CAMPAIGN,
  SERVICE_TYPE_LEAD_GEN,
  SERVICE_TYPE_SAS,
  SERVICE_TYPE_VS,
} from "shared/enum/launchKeysAndEnum";
import { GET_AI_CREATIVES } from "shared/gql/gqlSchema/creativeGql";
import { GET_BLOB_FOR_CREATIVES } from "shared/gql/gqlSchema/umsGql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS } from "shared/SharedKeys";
import {
  CREATIVE_MIME_TYPE_IMAGE,
  CREATIVE_TYPE_IMAGE,
  CREATIVE_TYPE_VIDEO,
} from "shared/SharedKeys/launchKeys";
import { SAS_WORKSPACE_ADMIN } from "shared/UserPermission/userPermissionList";
import { openNotification } from "utils";

export default function LookCreatives(props) {
  const { havePermission: isWorkSpaceAdmin } = usePermissionCheck(SAS_WORKSPACE_ADMIN);
  const {
    visible = false,
    launch = false,
    campaignIndex,
    adSetindex,
    onClose = () => {},
    title = "",
    groupSelection = false,
    isKeyword = false,
    submitView,
    isTiktokPlatformLaunch = false,
    selectedData,
    serviceType,
    productId,
    id,
    category,
    nickname,
    language_code,
    name,
    vertical,
    hasCreativeUploadPermission = false,
    content_type,
    bucketEdit = false,
  } = props;

  const [activeTab, setActiveTab] = useState("Creative-Upload");
  const { campaignObject } = useLaunchContext();
  const [mimeType, setMimeType] = useState();

  //download creatives
  function base64ToImageUrl(base64String) {
    return `data:${mimeType};base64,${base64String}`;
  }
  function downloadFile(url, filename) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to the HTML document
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  }
  const [getBlob, { loading: loading_blob }] = useCustomMutation(GET_BLOB_FOR_CREATIVES, {
    onCompleted: (e) => {
      const element = e?.getBlob;
      if (element) {
        for (let i = 0; i < element.length; i++) {
          const blob = element[i]?.blob;
          const imageUrl = base64ToImageUrl(blob, mimeType);
          downloadFile(
            imageUrl,
            `lookfinity_creatives.${mimeType === CREATIVE_MIME_TYPE_IMAGE ? "jpeg" : "mp4"}`
          );
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message, key: "creative-upload-error" });
    },
  });
  async function handleClickDownload(urls, mimeType) {
    let linkData = [];
    for (const fileUrl of urls) {
      try {
        const response = await fetch(fileUrl);
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          downloadFile(
            url,
            `lookfinity_creatives.${mimeType === CREATIVE_MIME_TYPE_IMAGE ? "jpeg" : "mp4"}`
          );
        }
      } catch (error) {
        linkData.push(fileUrl);
      }
    }
    if (linkData?.length > 0) {
      setMimeType(mimeType);
      getBlob({ variables: { urls: linkData } });
    }
  }

  let categoryId =
    (serviceType === SERVICE_TYPE_SAS && (isKeyword || content_type === CONTENT_TYPE_CAMPAIGN)) ||
    serviceType === SERVICE_TYPE_LEAD_GEN
      ? category?.id
      : id;
  let subCategoryId = isKeyword ? { subCategoryId: id } : {};
  let verticalId = vertical?.id;
  let categoryName =
    (serviceType === SERVICE_TYPE_SAS && (isKeyword || content_type === CONTENT_TYPE_CAMPAIGN)) ||
    serviceType === SERVICE_TYPE_LEAD_GEN
      ? category?.name
      : nickname
      ? `${nickname}${language_code}`
      : name;
  let subCategory =
    isKeyword && serviceType === SERVICE_TYPE_SAS
      ? { subCategory: { subCategoryId: id, subCategoryName: name } }
      : {};
  let keyword_title = isKeyword && serviceType === SERVICE_TYPE_SAS ? `Search for {Keyword} ` : {};
  let keyword_body = isKeyword && serviceType === SERVICE_TYPE_SAS ? `Search for {Keyword}` : {};
  let keyword_id = isKeyword ? { keyword_id: id } : {};
  let keyword_name = isKeyword ? { keyword_name: name } : {};

  if (launch) {
    subCategoryId = isKeyword ? { subCategoryId: campaignObject[campaignIndex]?.id } : {};
    verticalId = campaignObject[campaignIndex]?.categoryId;
    categoryId =
      productId === PRODUCT_ID_LEADGEN ||
      (productId === PRODUCT_ID_SAS &&
        (campaignObject[campaignIndex]?.content_type === CONTENT_TYPE_CAMPAIGN || isKeyword))
        ? campaignObject[campaignIndex]?.categoryId
        : campaignObject[campaignIndex]?.id;
    categoryName =
      productId === PRODUCT_ID_LEADGEN ||
      (productId === PRODUCT_ID_SAS &&
        (campaignObject[campaignIndex]?.content_type === CONTENT_TYPE_CAMPAIGN || isKeyword))
        ? campaignObject[campaignIndex]?.categoryName
        : campaignObject[campaignIndex]?.name;
    keyword_id = isKeyword ? { keyword_id: campaignObject[campaignIndex]?.id } : {};
    keyword_name = isKeyword ? { keyword_name: campaignObject[campaignIndex]?.name } : {};
    subCategory =
      isKeyword && serviceType === SERVICE_TYPE_SAS
        ? {
            subCategory: {
              subCategoryId: campaignObject[campaignIndex]?.id,
              subCategoryName: campaignObject[campaignIndex]?.name,
            },
          }
        : {};
  }
  const [fetchAI_Adgroups, { data, loading, error, refetch }] = useCustomLazyQuery(
    GET_AI_CREATIVES,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.AI_adgroup_data;
      },
      // onError: (e) => {
      //   openNotification({
      //     type: "error",
      //     message: "Descriptions fetch failed ",
      //   });
      // },
    }
  );
  const [activeTabs, setActiveTabs] = useState("Title");
  useEffect(() => {
    if (activeTab === "Creatives") {
      fetchAI_Adgroups({
        variables: {
          ...(isKeyword && serviceType === SERVICE_TYPE_SAS
            ? { param: name }
            : { param: categoryName }),
        },
      });
    }
  }, [activeTab]);

  const overView =
    !groupSelection && !bucketEdit
      ? [
          {
            id: "Overview",
            hoverTitle: "Overview",
            icon: () => <ActionIconRender iconType="pic-left" />,
            component: <UpdateForm {...props} />,
          },
          {
            id: "Benchmark-Update",
            title: groupSelection ? `Benchmark Update` : undefined,
            hoverTitle: `Benchmark Update`,
            noSpace: true,
            icon: (e) => <ActionIconRender iconType="dollar" {...e} />,
            component: activeTab === "Benchmark-Update" && (
              <>
                <BenchmarkForm isUpdate={true} {...props} onClose={onClose} activeTab={activeTab} />
              </>
            ),
          },
          {
            id: "History-Report",
            hoverTitle: "History Report",
            icon: (e) => <ActionIconRender iconType="clock" {...e} />,
            component: (
              <CategoryHistoryReport
                {...props}
                persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_7}
              />
            ),
          },
        ]
      : [];

  const hoverTitleAppend = groupSelection ? "" : "Upload";

  const isAdGroup = groupSelection
    ? []
    : [
        {
          id: "AdGroup",
          hoverTitle: `AdCopy ${hoverTitleAppend}`,
          icon: () => <ActionIconRender iconType="font-colors" />,
          noSpace: true,
          component: activeTab === "AdGroup" && (
            <AdGroup
              {...props}
              serviceType={serviceType}
              productId={productId}
              subCategoryId={subCategoryId}
              categoryId={categoryId}
              verticalId={verticalId}
              categoryName={categoryName}
              keyword_id={keyword_id}
              keyword_name={keyword_name}
              subCategory={subCategory}
              isTiktokPlatformLaunch={isTiktokPlatformLaunch}
            />
          ),
        },
      ];

  const isGoogleImages = groupSelection
    ? []
    : [
        {
          id: "GoogleCreativeImagesUpload",
          title: groupSelection ? `Google Images ${hoverTitleAppend}` : undefined,
          hoverTitle: `Google Images ${hoverTitleAppend}`,
          icon: (e) => <ActionIconRender iconType="google" {...e} />,
          component: activeTab === "GoogleCreativeImagesUpload" && (
            <GoogleCreativeImagesUploadWrapper>
              <GoogleCreativeImagesUpload
                {...props}
                creativeType={CREATIVE_TYPE_IMAGE}
                serviceType={serviceType}
                productId={productId}
                subCategoryId={subCategoryId}
                categoryId={categoryId}
                verticalId={verticalId}
                categoryName={categoryName}
                subCategory={subCategory}
              />
            </GoogleCreativeImagesUploadWrapper>
          ),
        },
      ];
  const tabContents = [
    {
      id: "Title",
      title: `Headline`,
      hoverTitle: `Headline`,
      icon: (e) => <ActionIconRender iconType="font-size" {...e} serviceType={serviceType} />,
      component: (
        <Title
          {...props}
          persistKey={
            groupSelection
              ? USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1
              : USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2
          }
          serviceType={serviceType}
          productId={productId}
          subCategoryId={subCategoryId}
          isKeyword={isKeyword}
          categoryId={categoryId}
          verticalId={verticalId}
          categoryName={
            serviceType === SERVICE_TYPE_VS || (serviceType === SERVICE_TYPE_SAS && !isKeyword)
              ? title || categoryName
              : categoryName
          }
          subCategory={subCategory}
          titleAIData={data}
          loading_titleAIData={loading}
          error={error}
        />
      ),
    },
    {
      id: "Body",
      title: `Primary text `,
      hoverTitle: `Primary text`,
      icon: (e) => <ActionIconRender iconType="font-colors" {...e} />,
      component: (
        <Body
          {...props}
          persistKey={
            groupSelection
              ? USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3
              : USER_SETTING_TABLE_PERSIST_KEY_LEVEL_4
          }
          serviceType={serviceType}
          isKeyword={isKeyword}
          productId={productId}
          subCategoryId={subCategoryId}
          categoryId={categoryId}
          categoryName={
            serviceType === SERVICE_TYPE_VS || (serviceType === SERVICE_TYPE_SAS && !isKeyword)
              ? title || categoryName
              : categoryName
          }
          subCategory={subCategory}
          bodyAIData={data}
          loading_bodyAIData={loading}
          error_bodyAIData={error}
        />
      ),
    },
    {
      id: "Description",
      title: `Description`,
      hoverTitle: `Description `,
      icon: () => <ActionIconRender iconType="align-center" />,
      component: (
        <Description
          {...props}
          persistKey={
            groupSelection
              ? USER_SETTING_TABLE_PERSIST_KEY_LEVEL_5
              : USER_SETTING_TABLE_PERSIST_KEY_LEVEL_6
          }
          serviceType={serviceType}
          productId={productId}
          isKeyword={isKeyword}
          subCategoryId={subCategoryId}
          categoryId={categoryId}
          verticalId={verticalId}
          categoryName={categoryName}
          subCategory={subCategory}
          error={error}
          loading_descriptionAIData={loading}
          descriptionAIData={data}
        />
      ),
    },
  ];

  const creativesOtherProps = groupSelection
    ? tabContents
    : [
        {
          id: "Creatives",
          // title: groupSelection ? `Headline ${hoverTitleAppend}` : undefined,
          hoverTitle: `Adgroup Upload`,
          noSpace: true,
          icon: (e) => <ActionIconRender iconType="font-size" {...e} serviceType={serviceType} />,
          component: (
            <TabRender
              className="no-tab-space"
              fullBtn
              tabContent={tabContents}
              value={activeTabs}
              onChange={setActiveTabs}
            />
          ),
        },
      ];
  const isAvatar = [
    {
      id: "Avatar",
      title: groupSelection ? `Creative ${hoverTitleAppend}` : undefined,
      hoverTitle: `Avatar ${hoverTitleAppend}`,
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="user" {...e} />,
      component: (
        <AvatarUpload
          {...props}
          serviceType={serviceType}
          productId={productId}
          subCategoryId={subCategoryId}
          categoryId={categoryId}
          verticalId={verticalId}
          categoryName={categoryName}
          keyword_id={keyword_id}
          keyword_name={keyword_name}
        />
      ),
    },
  ];

  const tabContent = [
    {
      id: "Creative-Upload",
      title: groupSelection ? `Creative ${hoverTitleAppend}` : undefined,
      hoverTitle: `Creative ${hoverTitleAppend}`,
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="images-group" {...e} />,
      component: activeTab === "Creative-Upload" && (
        <CreativeUpload
          {...props}
          isKeyword={isKeyword}
          creativeType={CREATIVE_TYPE_IMAGE}
          serviceType={serviceType}
          productId={productId}
          subCategoryId={subCategoryId}
          categoryId={categoryId}
          verticalId={verticalId}
          categoryName={categoryName}
          subCategory={subCategory}
          keyword_title={keyword_title}
          keyword_body={keyword_body}
          handleClickDownload={handleClickDownload}
        />
      ),
    },
    {
      id: "Video-Upload",
      title: groupSelection ? `Video ${hoverTitleAppend}` : undefined,
      hoverTitle: `Video ${hoverTitleAppend}`,
      noSpace: true,
      icon: (e) => <ActionIconRender iconType="videos-group" {...e} />,
      component: activeTab === "Video-Upload" && (
        <CreativeVideoUpload
          {...props}
          isKeyword={isKeyword}
          keyword_title={keyword_title}
          keyword_body={keyword_body}
          creativeType={CREATIVE_TYPE_VIDEO}
          serviceType={serviceType}
          productId={productId}
          subCategoryId={subCategoryId}
          categoryId={categoryId}
          verticalId={verticalId}
          categoryName={categoryName}
          subCategory={subCategory}
          handleClickDownload={handleClickDownload}
        />
      ),
    },
    // in future for template making
    // {
    //   id: "Collage-Template-Maker",
    //   title: groupSelection ? `Collage Maker` : undefined,
    //   hoverTitle: `Collage Maker`,
    //   icon: (e) => <ActionIconRender iconType="compree-outlined" {...e} />,
    //   component: (
    //     <CollageView
    //       {...props}
    //       isKeyword={isKeyword}
    //       keyword_title={keyword_title}
    //       keyword_body={keyword_body}
    //       creativeType={CREATIVE_TYPE_VIDEO}
    //       serviceType={serviceType}
    //       productId={productId}
    //       subCategoryId={subCategoryId}
    //       categoryId={categoryId}
    //       verticalId={verticalId}
    //       categoryName={categoryName}
    //       subCategory={subCategory}
    //     />
    //   ),
    // },
    ...(serviceType === SERVICE_TYPE_SAS && !groupSelection && !isKeyword ? isAvatar : []),
    // ...(!isKeyword ? creativesOtherProps : groupSelection ? creativesOtherProps : []),
    ...creativesOtherProps,
    ...isAdGroup,
    ...isGoogleImages,
    ...(productId === PRODUCT_ID_SAS ? overView : []),
  ];

  const selectedTab = tabContent.filter(({ id }) => id === activeTab)[0];
  const finalData = {
    body: selectedData?.body?.[0],
    title: selectedData?.title?.[0],
    ...(selectedData?.video?.length === 0 && { image: selectedData?.image?.[0] }),
    description: selectedData?.description?.[0],
    ...(selectedData?.image?.[0] && { video: selectedData?.video?.[0] }),
  };

  return (
    <LookDrawer
      size={1200}
      visible={visible}
      onClose={onClose}
      textBreadcrumb={[
        groupSelection ? "Ad Group" : `${categoryName}`,
        title,
        selectedTab?.hoverTitle,
      ]}
    >
      <div className="creatives-drawer">
        {visible && (
          <>
            {groupSelection ? (
              <div className="d-flex h-100" style={{ flexWrap: "wrap" }}>
                {/* remove inline css later */}
                <div
                  style={{
                    flex: "0 0 33%",
                    maxWidth: "33%",
                    borderRight: "1px solid #424242",
                    alignItems: "center",
                    padding: " 0px 10px 10px 10px",
                  }}
                >
                  <CardCreativeAdGroup {...finalData} />
                </div>
                <div style={{ flex: "0 0 67%", maxWidth: "67%" }}>
                  <TabRender
                    className="top-bar creative-drawer-tab"
                    fullBtn
                    contentClassName="px-20"
                    tabContent={tabContent}
                    value={activeTab}
                    onChange={setActiveTab}
                  />
                  <div className="px-12">{submitView}</div>
                </div>
              </div>
            ) : (
              <LookDrawerMainSideBar
                value={activeTab}
                onChange={setActiveTab}
                tabContent={tabContent}
              />
            )}
          </>
        )}
      </div>
    </LookDrawer>
  );
}
