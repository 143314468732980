import { createContext, useContext, useRef } from "react";

export const LookTableParentContext = createContext(null);

export function useLookTableParentContext() {
  const providerValues = useContext(LookTableParentContext);
  return { ...providerValues };
}

export function LookTableParentContextProvider(props) {
  const { children } = props;

  const childRef = useRef();
  // const ruleEgineChildRef = useRef();
  const selectedRowsClear = () => {
    // For clear internal bulk with context form anywhere
    childRef?.current?.selectedRowsClear();
  };

  // const callBulkRuleData = (selectedRecordData, extraProps = {}) => {
  //   /**
  //    * passes record data use in AddRule Compoent
  //    *
  //    */

  //   ruleEgineChildRef?.current?.callBulkRuleData(selectedRecordData, extraProps);
  // };

  const contextValues = { childRef, selectedRowsClear };

  return (
    <LookTableParentContext.Provider value={contextValues}>
      {children}
    </LookTableParentContext.Provider>
  );
}

export const LookTableParentRuleEngineContext = createContext(null);

export function useLookTableParentRuleEngin() {
  const providerValues = useContext(LookTableParentRuleEngineContext);
  return { ...providerValues };
}

export function LookTableParentRuleEngineContextProvider(props) {
  const { children } = props;

  const ruleEgineChildRef = useRef();

  const callBulkRuleData = (selectedRecordData, extraProps = {}) => {
    /**
     * passes record data use in AddRule Compoent
     *
     */
    ruleEgineChildRef?.current?.callBulkRuleData(selectedRecordData, extraProps);
  };

  const contextValues = { ruleEgineChildRef, callBulkRuleData };

  return (
    <LookTableParentRuleEngineContext.Provider value={contextValues}>
      {children}
    </LookTableParentRuleEngineContext.Provider>
  );
}
