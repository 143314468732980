import { useEffect, useRef, useState } from "react";
import { Checkbox, Tooltip, Button } from "antd";
import LookTextInput from "../LookTextInput";
import ActionIconRender from "components/ActionIconRender";
import cx from "classnames";

function RenderInput(props) {
  const { value, onChange = () => {}, extitEditMode = () => {} } = props;

  const inpRef = useRef(null);

  const [field, setField] = useState(value);
  function handleField(e) {
    setField(e.target.value);
  }
  useEffect(() => {
    inpRef.current.focus();
  }, []);
  function handleMainData() {
    if (field.trim()) {
      setField("");
      onChange(field.trim());
      extitEditMode();
    }
  }

  function handleFieldSubmit(e) {
    if (e.key === "Enter") {
      handleMainData();
    }
  }
  return (
    <div className="d-flex">
      <LookTextInput
        type="textarea"
        className="flex-grow-1 mb-0"
        ref={inpRef}
        {...props}
        onChange={handleField}
        onKeyDown={handleFieldSubmit}
      />
      <div>
        <Tooltip title="Set">
          <Button
            className="w-auto h-auto"
            type="link"
            onClick={handleMainData}
            icon={<ActionIconRender iconType="check" />}
          />
        </Tooltip>
        <Tooltip title="Cancel">
          <Button
            className="w-auto h-auto"
            type="link"
            danger
            onClick={extitEditMode}
            icon={<ActionIconRender iconType="close" />}
          />
        </Tooltip>
      </div>
    </div>
  );
}

export default function LookCheckedInput(props) {
  const { theme = "bordered", checked = false, value = "", isEditable = false } = props;
  const [editMode, setEditMode] = useState(false);

  return (
    <div className={cx("look-checked-input", `theme-${theme}`, { active: checked })}>
      <div className="d-flex">
        <div className="selection">
          <Checkbox checked={checked} />
        </div>
        <div className="content flex-grow-1">
          {isEditable && editMode ? (
            <RenderInput value={value} extitEditMode={() => setEditMode(false)} />
          ) : (
            <p className="mb-0 ">{value}</p>
          )}
        </div>
        {isEditable && !editMode && (
          <div className="d-flex actions">
            {isEditable && (
              <Tooltip title="Edit">
                <Button
                  className="w-auto h-auto"
                  type="link"
                  onClick={() => setEditMode(true)}
                  icon={<ActionIconRender iconType="edit" />}
                />
              </Tooltip>
            )}
            <Tooltip title="Close">
              <Button
                className="w-auto h-auto"
                type="link"
                icon={<ActionIconRender iconType="close" />}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}
