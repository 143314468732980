import { USER_SETTING_CREATE_NEW } from "actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import DashboardHeader from "../layouts/DashboardLayout/DashboardHeader";

export default function LookTabRouting(props) {
  const { tabs = [], dashboardHeader, stateLevel = false, activeTab, setActiveTab } = props;
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();

  let basePath = routeMatch.path;
  if (dashboardHeader && dashboardHeader?.basePath) {
    basePath = dashboardHeader?.basePath;
  }
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const visibleTabs = tabs?.filter(({ permission: tabPermission }) => {
    if (!tabPermission) return true;
    if (typeof tabPermission === "object") {
      const checkPermission = permissions?.filter((name) => {
        return tabPermission?.filter((data) => name === data).length > 0;
      });
      if (checkPermission && checkPermission?.length && checkPermission?.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (permissions?.filter((name) => name === tabPermission).length > 0) return true;
    }
    return false;
  });
  const parentRouteProps = {
    exact: true,
    path: basePath,
    component: () => <Redirect to={basePath + visibleTabs[0]?.link} />,
  };

  const isMultiCreateButton = dashboardHeader?.createMultiBtns
    ? { createMultiBtns: dashboardHeader?.createMultiBtns }
    : {};

  const headerProps = dashboardHeader
    ? stateLevel
      ? {
          title: dashboardHeader?.title,
          tabContent: visibleTabs,
          basePath,
          activeTab,
          setActiveTab,
          /**
           * pass here activeTab and setActiveTad
           */
        }
      : { title: dashboardHeader?.title, linkTabs: visibleTabs, basePath, ...isMultiCreateButton }
    : {};

  function handleCreate(status) {
    dispatch({ type: USER_SETTING_CREATE_NEW, payload: status });
  }

  function openCreateDrawer() {
    handleCreate(true);
  }
  function closeCreateDrawer() {
    handleCreate(false);
  }

  if (stateLevel) {
    return (
      <>
        {visibleTabs.map(({ id, createBtnText, component: Component }, index) => {
          const createProps = createBtnText
            ? { createBtn: true, createBtnText, createBtnHandler: openCreateDrawer }
            : {};
          return (
            <>
              {id === activeTab && (
                <>
                  {dashboardHeader && <DashboardHeader {...headerProps} {...createProps} />}
                  <Component
                    headerTabs={visibleTabs}
                    closeCreateDrawer={closeCreateDrawer}
                    basePath={basePath}
                  />
                </>
              )}
            </>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Route {...parentRouteProps} />
      {visibleTabs.map(
        ({ route = "/", createBtnText, exact = false, component: Component }, index) => {
          const createProps = createBtnText
            ? { createBtn: true, createBtnText, createBtnHandler: openCreateDrawer }
            : {};
          const tabRoutesProps = {
            key: index,
            path: basePath + route,
            exact,
            component: () => (
              <>
                {dashboardHeader && <DashboardHeader {...headerProps} {...createProps} />}
                <Component
                  headerTabs={visibleTabs}
                  closeCreateDrawer={closeCreateDrawer}
                  basePath={basePath}
                />
              </>
            ),
          };
          return <Route {...tabRoutesProps} />;
        }
      )}
    </>
  );
}
