import { CREATIVE_TRACKING_STATUS_CONFIG } from "shared/gql/gqlSchema/creativeDashboardGql";
import useApiToStoreQuery from "./useApiToStoreQuery";

export default function useCreativetrackingApis() {
  const {
    apiCall: requestStatusFetch = () => {},
    loading: requestStatusLoading,
    refetch: requestStatusRefetch,
    setDataSource: requestStatusConfigSource,
  } = useApiToStoreQuery({
    api: CREATIVE_TRACKING_STATUS_CONFIG,
    elementKey: "ctGetRequestStatusConfig",
    storeKey: "ctGetRequestStatusConfig",
    objectType: true,
  });

  return {
    requestStatusFetch,
    requestStatusLoading,
    requestStatusRefetch,
    requestStatusConfigSource,
  };
}
