import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "../MainForm";
import { openNotification, urlYupValidate } from "../../../../../utils";
import { ADD_CMS_STORE } from "../../../../../shared/gql/gqlSchema/cmsGql";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { CMS_BANNER_TYPE_STORE } from "components/CmsComponents/CMSBanner/useCmsBannerCrudHook";
import useCmsAddBanner from "components/CmsComponents/CMSBanner/useCmsAddBanner";

export default function AddForm(props) {
  const { refetch = () => {}, allCountries = [], onClose = () => {} } = props;
  const [image, setImage] = useState("");
  const [isError, setIsError] = useState("");
  const [categories, setCategories] = useState([]);
  const resetFormRef = useRef(() => {});

  const [bannerImages, setBannerImages] = useState([]);
  const { bannerAddView, addBanners, loading_addCmsBanner } = useCmsAddBanner({
    type: CMS_BANNER_TYPE_STORE,
    bannerImages,
    setBannerImages,
  });

  const onDone = () => {
    refetch();

    setCategories([]);
    onClose();
    openNotification({ type: "success", message: "Record Added Successfully" });
  };

  const [CMS_CreateStore, { loading }] = useCustomMutation(ADD_CMS_STORE, {
    onCompleted(e) {
      const element = e?.CMS_CreateStore;
      if (bannerImages && bannerImages?.length > 0) {
        addBanners(element?.id, bannerImages, {
          onCompleted: (e) => {
            const element = e?.CMS_Create_Banner;
            if (element) {
              resetFormRef.current();
              onDone();
            }
          },
          onError(e) {
            resetFormRef.current();
            openNotification({
              type: "error",
              message: "Banners not added",
              description: e?.message,
            });
            onDone();
          },
        });

        return null;
      }
      resetFormRef.current();
      onDone();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    const { country_id } = values;
    resetFormRef.current = resetForm;

    CMS_CreateStore({
      variables: {
        ...values,
        category_id: categories.map((i) => Number(i)),
        country_id: country_id.map((i) => Number(i)),
        image,
      },
    });
    clearError();
  }

  const initialValues = {
    description: "",
    image: "",
    is_private: false,
    is_brand: false,
    name: "",
    status: "",
    website: "",
    // category_id: [],
    country_id: [],
  };

  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
    description: yup.string().required().label("Description"),
    website: urlYupValidate.label("Website"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading: loading || loading_addCmsBanner,
    categoriesProsp: {
      categories,
      setCategories,
    },
    imageProps: {
      image,
      setImage,
    },
  };

  return (
    <>
      <MainFrom
        {...formProps}
        type="add"
        allCountries={allCountries}
        bannerView={bannerAddView()}
      />
    </>
  );
}
