import { Checkbox, Col, Divider, Row, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import PopoverWrapper from "components/popoverWrapper";
import cx from "cx";
import React from "react";
import {
  CREATIVE_TYPE,
  CREATIVE_TYPE_VIDEO,
  GROUP_TYPE_STATIC,
} from "shared/SharedKeys/launchKeys";
import { MoreOutlined } from "@ant-design/icons";
import { numberParse, numCompactShort, openNotification } from "utils";

function useCustomeView(props) {
  const {
    finalMerge = [],
    isCreativeDirector = false,
    isUmsAgencyAdmin = false,
    isCreativeAgencyAdmin = false,
    setRowProps = () => {},
    setVisibleUserModal = () => {},
    showDetailedReportsDrawer = () => {},
    onOpenCategoryCompliantModal = () => {},
    isFbReport = false,
    creativeType,
    isGroup = false,
    setPlayModal = () => {},
    handlePlatform = () => {},
    handleComplianceIcon = () => {},
    loading_fb = false,
    loading_tiktok = false,
    isGlobalReport = false,
    showSimilarImagesDrawer = () => {},
    setSelectedBulkCreative = () => {},
    selectedBulkCreative,
  } = props;

  const handleCheckbox = (data) => {
    const { group_id = "" } = data;
    const commonCategories = data?.categoryId || [];
    // setting limit for creative analysis

    setSelectedBulkCreative((prev) => {
      const indexToUpdate = prev?.findIndex((prevItem) => prevItem?.group_id === group_id);

      if (indexToUpdate === -1) {
        // logic to find common categories
        if (selectedBulkCreative.length >= 3) {
          openNotification({
            type: "error",
            message: "Maximum limit has been reached",
          });
          return prev;
        } else {
          const { image, video, groupType } = handleCreativesUrl(data);
          let commonCategoryIds = commonCategories.slice();
          for (const creativesData of prev) {
            const categories = creativesData?.categoryId || [];
            commonCategoryIds = categories.filter(
              (category) =>
                commonCategories?.findIndex(
                  (dataCategory) => dataCategory?.productCategoryId === category?.productCategoryId
                ) !== -1
            );
          }
          if (commonCategoryIds.length === 0 && prev?.length > 0) {
            openNotification({
              type: "error",
              message: "Please choose creatives within the same category.",
            });
            return prev;
          }

          const updatedData = {
            ...data,
            image,
            video,
            groupType,
            categoryId: commonCategoryIds,
          };
          return [...prev, updatedData];
        }
      } else {
        // deselect the data
        const updatedPrev = [...prev];
        updatedPrev.splice(indexToUpdate, 1);
        return updatedPrev;
      }
    });
  };

  // function is to handle creatives urls for diff apis
  const handleCreativesUrl = (data) => {
    const { creative_url_object = {} } = data;
    const { groups = [] } = creative_url_object;
    const {
      images = [],
      videos = [],
      titles = [],
      bodies = [],
      descriptions = [],
      groupType = "",
    } = groups?.[0] || {};
    const title = isFbReport
      ? groupType === GROUP_TYPE_STATIC
        ? { title: titles?.[0]?.text }
        : { title: `Headline: ${titles?.length}` }
      : { title: `Headline: ${data?.title}` };
    const body = isFbReport
      ? groupType === GROUP_TYPE_STATIC
        ? { body: bodies?.[0]?.text }
        : { body: `Primary Text:${bodies?.length}` }
      : { body: "Primary Text: N/A" };
    const description = isFbReport
      ? groupType === GROUP_TYPE_STATIC
        ? { description: descriptions?.[0]?.text }
        : { description: `Description: ${descriptions?.length}` }
      : {
          description: "Description: N/A",
        };
    const image = {
      s3Url: creative_url_object?.images?.[0]?.urls?.[0]?.r2Url || images?.[0]?.urls?.[0]?.r2Url,
    };
    const video = {
      s3Url: isFbReport
        ? creative_url_object?.videos?.[0]?.urls?.[0]?.r2Url || videos?.[0]?.urls?.[0]?.r2Url
        : data?.video_url,
      thumbUrl: isFbReport
        ? videos?.[0]?.urls?.[0]?.thumbUrl || creative_url_object?.videos?.[0]?.urls?.[0]?.thumbUrl
        : data?.video_thumb_url,
    };
    return { title, body, description, image, video, groupType };
  };
  const customeView =
    loading_fb || loading_tiktok ? (
      <Loader elementCenter />
    ) : finalMerge?.length > 0 ? (
      <div className="px-20 py-15 ">
        <Row gutter={12}>
          {finalMerge?.map((data) => {
            const reportData = [
              { label: "Spend", value: numCompactShort(numberParse(data?.spend), 2) },
              { label: "Revenue", value: numCompactShort(numberParse(data?.revenue), 2) },
              { label: "G Margin", value: numCompactShort(numberParse(data?.gross_margin), 2) },
              { label: "ROAS", value: numCompactShort(numberParse(data?.roas), 2) },
            ];
            const ReportCardDataList = ({ reportData }) => {
              return (
                <div className="creative-report-card-list">
                  {reportData.map((data, index) => (
                    <div className="d-flex" key={index}>
                      <div className="creative-report-card-list-child">
                        <p className="mb-0 secondory" style={{ fontSize: "12px" }}>
                          {data?.label}
                        </p>
                        <h5
                          className={cx({
                            "text-primary": data.label === "G Margin" && parseFloat(data.value) > 0,
                            "text-danger": data.label === "G Margin" && parseFloat(data.value) <= 0,
                          })}
                        >
                          {data.label === "ROAS" ? `${data.value}%` : `$${data.value}`}
                        </h5>
                      </div>
                      {index < reportData.length - 1 && (
                        <Divider type="vertical" style={{ height: "29px" }} />
                      )}
                    </div>
                  ))}
                </div>
              );
            };
            const poverWrapperOptions = [
              ...((isCreativeDirector || isUmsAgencyAdmin || isCreativeAgencyAdmin) &&
              creativeType !== CREATIVE_TYPE &&
              !isGlobalReport
                ? [
                    {
                      option: "edit",
                      title: "Update User",
                      optionProps: {
                        onClick: () => {
                          setVisibleUserModal(true);
                          setRowProps(data);
                        },
                      },
                    },
                  ]
                : []),
              {
                option: "flag",
                title: "Report",
                optionProps: {
                  onClick: () => data?.hasSource && showDetailedReportsDrawer(data),
                },
              },
              ...(creativeType === CREATIVE_TYPE_VIDEO
                ? [
                    {
                      option: "image",
                      title: "Similar Videos",
                      optionProps: {
                        onClick: () => showSimilarImagesDrawer(data),
                      },
                    },
                  ]
                : []),
              ...(isGlobalReport
                ? []
                : [
                    {
                      option: "edit",
                      title: "Update Compliance",
                      optionProps: {
                        onClick: () => onOpenCategoryCompliantModal(data),
                      },
                    },
                  ]),
            ];

            // will optimize this later
            const { title, body, image, description, video, groupType } = handleCreativesUrl(data);
            const playVideo = () => (
              <div
                className="d-flex align-items-center"
                onClick={() => {
                  setPlayModal(true);
                  setRowProps(video);
                }}
              >
                {video?.s3Url && (
                  <>
                    <LookButton
                      className="mr-8 ply-btn"
                      tooltipTitle="Play video"
                      block
                      iconType={isGroup ? "play" : "playButton"}
                      onClick={() => {
                        setPlayModal(true);
                        setRowProps(video);
                      }}
                    >
                      {isGroup && <span> Play first Video</span>}
                    </LookButton>
                  </>
                )}
              </div>
            );
            const selected =
              selectedBulkCreative?.filter((creatives) => data?.group_id === creatives?.group_id)
                ?.length > 0;
            const hoverContent = () => (
              <div className="h-100">
                <div className="d-flex align-items-center justify-content-between w-100 mb-10">
                  {!isGroup && (
                    <div className="id-wrap" style={{ left: 0, zIndex: 2 }}>
                      <Checkbox onChange={() => handleCheckbox(data)} checked={selected} />
                    </div>
                  )}
                  <div className="ml-5" style={{ left: 0, zIndex: 2 }}>
                    <div className="d-flex">
                      {!isGroup && playVideo()}
                      {!isGroup && (
                        <PopoverWrapper options={poverWrapperOptions}>
                          <MoreOutlined className="pop-info" />
                        </PopoverWrapper>
                      )}
                    </div>
                  </div>
                </div>

                <div className="content-inner-details">
                  <div className="flex-grow-1">
                    <ul>
                      <li>
                        Avg Video Play <span> {data?.average_video_play || "N/A"}</span>
                      </li>
                      <li>
                        Source <span>{data?.source || "N/A"}</span>
                      </li>
                      <li>
                        Category
                        <span>
                          {data?.categoryId?.map((categoryData, index) => (
                            <div key={index}>{categoryData?.name}</div>
                          )) || "N/A"}
                        </span>
                      </li>
                      {(isCreativeDirector || isCreativeAgencyAdmin) && (
                        <li>
                          Creator <span>{data?.user?.name || "N/A"}</span>
                        </li>
                      )}
                      <li>
                        Paltforms
                        <span>
                          {handlePlatform(data?.platformId)?.map((data, index) => (
                            <div key={index}>{data}</div>
                          ))}
                        </span>
                      </li>
                      <li>
                        Users
                        <span>{data?.userCount}</span>
                      </li>
                      {creativeType === CREATIVE_TYPE_VIDEO && (
                        <li>
                          Creative Type
                          <span>
                            {data?.creative_url_object?.videos?.[0]?.videoType || GROUP_TYPE_STATIC}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                  {isGroup && playVideo()}
                  {/* <div className=" mt-5">
                    <LookButton
                      type="secondory"
                      className="border-white"
                      block
                      onClick={() => showDetailedReportsDrawer(data)}
                    >
                      <ActionIconRender iconType="barChartLine" />
                      Detailed report
                    </LookButton>
                  </div> */}
                </div>
              </div>
            );
            return (
              <>
                <Col xs={24} md={12} lg={8} xl={8} xxl={6}>
                  <div className="creative-report-card">
                    <div className="position-relative">
                      <div className="creative-report-card-image">
                        <CardCreativeAdGroup
                          title={title}
                          body={body}
                          description={description}
                          image={image}
                          video={video}
                          groupType={groupType}
                          onlyContent
                          hoverContent={hoverContent}
                          poverWrapperOptions={poverWrapperOptions}
                          isGroup={isGroup}
                          isGlobalReport={isGlobalReport}
                        />
                      </div>
                    </div>

                    <div className="creative-report-card-win-div">
                      {/* need to be fixed  */}
                      <div
                        className="font-12"
                        style={{
                          padding: "0px 5px",
                        }}
                      >
                        CPC :
                        {/* <span className="green text-primary"> Data coming </span> when data came kindly use this span*/}
                        <span> {data?.CPC || "N/A"} </span>
                      </div>
                      <div
                        className="d-flex .align-items-baseline  justify-content-around"
                        style={{
                          width: "50px",
                        }}
                      >
                        <Tooltip title={data?.complianceStatus || "PENDING"}>
                          <ActionIconRender
                            iconType={handleComplianceIcon(data?.complianceStatus || "PENDING")}
                            style={{ margin: "3px 3px 0px 0px" }}
                          />
                        </Tooltip>
                        {/* <ActionIconRender iconType="important" /> */}
                      </div>
                    </div>

                    <ReportCardDataList reportData={reportData} />
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
      </div>
    ) : (
      <div className="text-center pt-100" style={{ marginLeft: "200px" }}>
        <div style={{ transform: "translateX(20px)" }}>
          <ActionIconRender iconType="tablePlaceholder" />
        </div>
        <p style={{ fontSize: "1.5rem" }}>
          <b>No Data Found...</b>
        </p>
      </div>
    );
  return { customeView };
}

export default useCustomeView;
