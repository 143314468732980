import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { useState } from "react";
import DebugPre from "components/DebugPre";
import { Button, Checkbox, Col, Form, Image, Pagination, Row, Segmented, Skeleton } from "antd";
import cx from "cx";
import { CT_ADD_AVATAR, CT_GET_AVATAR } from "shared/gql/gqlSchema/creativeGql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS } from "shared/SharedKeys";
import { useLaunchContext } from "components/Launch/Context";
import useUploadFiles from "../CreativeUpload/useUploadFiles";
import { CONTENT_TYPE_CAMPAIGN } from "shared/enum/launchKeysAndEnum";
import { useLookModal } from "components/LookModal";
import Tag from "../CreativeUpload/Tag";
import { SUCCESS } from "shared/enum/commonKeysandEnum";

// export { default as CreativeVideoUpload } from "../CreativeUpload/CreativeVideoUpload";

function MediaItem(props) {
  const { selected = false, groupSelection, onSelect, r2Url = "" } = props;
  const isSelection = groupSelection ? { onClick: onSelect } : {};

  return (
    <>
      <div className={cx("creative-image-selection ant-image-ration-1-1", { selected })}>
        <Image className="" src={r2Url} preview={false} />
      </div>
      <div className="creative-edit">
        <Checkbox className="creative-select" onChange={groupSelection ? onSelect : {}} />
      </div>
    </>
  );
}

// removed adding tags for now, leaving it commented so that we can directly use it again if required in future
export default function AvatarUpload(props) {
  const {
    // title = "",
    creativeType,
    groupSelection = false,
    // selectedCreatives,
    // setSelectedData,
    // selectedData,
    // handleSelectedCreatives = () => {},
    categoryId,
    productId,
    subCategory,
    subCategoryId,
    verticalId,
    categoryName,
    setTiktokIdentityAvatar,
    campaignIndex,
    adSetindex,
    isLaunchCampaign,
    launch,
    onSubmitAddIdentity,
    loading_identity_id,
    tiktokIdentityAvatar,
    onClose,
  } = props;

  const { campaignObject, handleCamaignAdsetAdSet } = useLaunchContext();

  // for tiktoklaunch
  const category_Id =
    isLaunchCampaign || setTiktokIdentityAvatar || launch
      ? productId === PRODUCT_ID_LEADGEN
        ? campaignObject[campaignIndex]?.categoryId
        : campaignObject[campaignIndex]?.categoryId
      : categoryId;
  const category_Name =
    isLaunchCampaign || setTiktokIdentityAvatar || launch
      ? productId === PRODUCT_ID_LEADGEN
        ? campaignObject[campaignIndex]?.categoryName
        : campaignObject[campaignIndex]?.categoryName
      : categoryName;

  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 4 }, () => 10));
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [avatars, setAvatars] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  // const [tags, setTags] = useState();
  const [handleFileUpload, setHandleFileUpload] = useState(() => {});
  const [fileName, setFileName] = useState([]);
  const [createdbyMe, setCreatedByMe] = useState("All");

  const onChange = (checked) => {
    setCreatedByMe(checked);
  };

  const [creativesImages, setCreativesImages] = useState([]);

  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  // const {
  //   modal: tagModal,
  //   visibleModal: visilbleTagModal,
  //   openModal: openTagModal,
  //   closeModal: closeTagModal,
  // } = useLookModal({
  //   title: "Add tags",
  //   footer: false,
  //   width: "50vw",
  //   onClose: () => {
  //     setTags([]);
  //   },
  // });

  let queryVars = {
    productId,
    categoryId: category_Id,
    createdByMe: createdbyMe === "My Avatars",
    page: currentPage,
    size: pageSize,
  };
  if (productId === PRODUCT_ID_SAS && launch) {
    if (campaignObject[campaignIndex]?.content_type === CONTENT_TYPE_CAMPAIGN) {
      queryVars = {
        ...queryVars,
        categoryId: campaignObject[campaignIndex]?.categoryId,
      };
    }
  }
  const variables = {
    productId,
    categoryId: category_Id,
    categoryName: category_Name,
  };

  const { loading: creativesImages_loading, refetch: creativesImage_refetch } = useCustomQuery(
    CT_GET_AVATAR,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ctGetCreativeAvatars?.data;
        if (element) {
          setCreativesImages(element);
          setTotalCount(e?.clPagedAvatars?.pageInfo?.totalCount);
        }
      },
      variables: { ...queryVars },
    }
  );

  const [AvatarAddImages, { loading: loading_add }] = useCustomMutation(CT_ADD_AVATAR, {
    variables: variables,
    onCompleted: (e) => {
      const element = e?.ctAddCreativeAvatars;
      if (element) {
        if (element?.status === SUCCESS) {
          // closeTagModal();
          creativesImage_refetch();
          openNotification({ type: "success", message: "Record added", key: "success-add" });
        } else if (element?.status === "FAILURE") {
          openNotification({ type: "error", message: element?.message, key: "error-add" });
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });

  const getImageResponse = (imagesData) => {
    AvatarAddImages({
      variables: {
        avatars: imagesData?.map((data, index) => ({
          imageUrl: data?.public_url,
          imageName: fileName?.[index],
        })),
      },
    });
  };

  const debugContent = [{ isRow: true, contentObjects: [{ creativesImages }] }];

  const mediaItemProps = { groupSelection, creativeType };

  const { uploadView, uploadLoading } = useUploadFiles({
    isAvatar: true,
    name: categoryName,
    getImageResponse,
    creativeType,
    creativesImage_refetch,
    setCreativesImages,
    // openTagModal,
    setFile: setAvatars,
    setIsUpload,
    setHandleFileUpload,
    setFileName,
  });

  return (
    <div className={!groupSelection && !launch ? cx("p-20") : ""}>
      <div className="d-flex mb-20">
        <Segmented
          className="custom-segmented"
          options={["All", "My Avatars"]}
          value={createdbyMe}
          onChange={onChange}
        />
      </div>
      <div className={cx("creative-media", { launch: launch && !groupSelection, groupSelection })}>
        <Row className="h-initial" gutter={[24, 24]}>
          {
            <Col span={4} xs={12} sm={8} md={6} xl={4}>
              <div
                style={{ height: "100%", minHeight: "200px" }}
                onClick={() => {
                  if (!launch) setAvatars([]);
                }}
              >
                {uploadView()}
              </div>
            </Col>
          }
          {(creativesImages_loading || loading_add || uploadLoading) &&
            skeletonSize?.map((e) => (
              <Col span={4} xs={12} sm={8} md={6} xl={4}>
                <Skeleton.Image
                  className="w-100"
                  style={{ width: "165px", height: "196px" }}
                  active={true}
                />
              </Col>
            ))}
          {creativesImages?.map((data, index) => {
            return (
              <>
                <Col span={4} key={"mediaItems" + index} xs={12} sm={8} md={6} xl={4}>
                  <MediaItem
                    {...data}
                    {...props}
                    {...mediaItemProps}
                    onSelect={() => {
                      if (!isLaunchCampaign) {
                        setTiktokIdentityAvatar(data?.r2Url);
                      } else {
                        //if avatar is on campaign level
                        handleCamaignAdsetAdSet(campaignIndex, adSetindex, {
                          avatarIconWebUri: data?.r2Url,
                        });
                      }
                    }}
                    selected={
                      !isLaunchCampaign
                        ? data?.creative?.s3Url === tiktokIdentityAvatar
                        : //if avatar is on campaign level
                          data?.creative?.s3Url ===
                          campaignObject?.[campaignIndex]?.adSets[adSetindex]?.avatarIconWebUri
                    }
                  />
                </Col>
              </>
            );
          })}
        </Row>
      </div>
      {!isLaunchCampaign && groupSelection && (
        <div className="px-12">
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" size="large" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                block
                size="large"
                onClick={onSubmitAddIdentity}
                disabled={loading_identity_id || !tiktokIdentityAvatar}
              >
                Save
              </Button>
              <Form.Item></Form.Item>
            </Col>
          </Row>
        </div>
      )}
      {/* {visilbleTagModal &&
        tagModal(
          <Tag
            files={avatars}
            variables={variables}
            creatives_refetch={creativesImage_refetch}
            isUpload={isUpload}
            handleFileUpload={handleFileUpload}
            setTags={setTags}
            closeTagModal={closeTagModal}
          />
        )} */}
      <div className="p-8 look-bottom-pagination">
        <Pagination
          defaultCurrent={1}
          total={totalCount}
          pageSize={pageSize}
          current={currentPage}
          onChange={(page, size) => handlePageChange(page, size)}
          pageSizeOptions={["10", "20", "50"]}
          showSizeChanger
        />
      </div>
      <DebugPre content={debugContent} />
    </div>
  );
}
