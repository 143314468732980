import {
  ADLIB_DOMAIN_DROPDOWN,
  ADLIB_GET_ALL_COUNTRIES,
  ADLIB_PAGE_DROPDOWN,
} from "shared/gql/gqlSchema/adLibraryGql";
import {
  API_STORE_ADLIB_COUNTRIES,
  API_STORE_ADLIB_DOMAIN_DROPDOWN,
  API_STORE_ADLIB_PAGE_DROPDOWN,
} from "./keys";
import useApiToStoreQuery from "./useApiToStoreQuery";

export function useAdLibraryApiParams() {
  const {
    apiCall: adlibCountryFetch = () => {},
    refetch: adlibCountryRefetch,
    setDataSource: adlibCountrySetDataSource,
  } = useApiToStoreQuery({
    api: ADLIB_GET_ALL_COUNTRIES,
    elementKey: "adlib_country_get_all",
    storeKey: "adlibCountry",
  });

  return {
    adlibCountryFetch,
    adlibCountryRefetch,
    adlibCountrySetDataSource,
  };
}

export default function useAdLibraryApis(typeKey) {
  switch (typeKey) {
    case API_STORE_ADLIB_DOMAIN_DROPDOWN:
      return {
        api: ADLIB_DOMAIN_DROPDOWN,
        elementKey: "adlib_domain_dropdown",
        storeKey: "adlib_domain_dropdown",
      };

    case API_STORE_ADLIB_PAGE_DROPDOWN:
      return {
        api: ADLIB_PAGE_DROPDOWN,
        elementKey: "adlib_page_dropdown",
        storeKey: "adlib_page_dropdown",
      };

    case API_STORE_ADLIB_COUNTRIES:
      return {
        api: ADLIB_GET_ALL_COUNTRIES,
        elementKey: "adlib_country_get_all",
        storeKey: "adlibCountry",
      };

    default:
      return false;
  }
}
