import { useState } from "react";
import { Select } from "antd";
import moment from "moment";
import { useTimezones } from "hooks";

const { Option } = Select;

export default function SelectTimeZone(props) {
  const { timeZones } = useTimezones();
  const { value: propValue, onChange, disabled = false } = props;
  const [timeZone, setTimeZone] = useState(propValue?.value ? propValue.value : "");

  function hanfleSelect(e) {
    setTimeZone(e);
    const filterValue = timeZones.filter(({ value }) => value === e);
    onChange(filterValue[0]);
  }

  return (
    <>
      <Select
        disabled={disabled}
        style={{ minWidth: 260 }}
        showSearch
        className="w-100"
        placeholder="Select Time Zone"
        value={timeZone}
        ranges={{
          Today: [moment(), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
        }}
        onChange={(e) => hanfleSelect(e)}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {timeZones.map(({ label, value }, index) => (
          <Option key={"timeZone" + index} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </>
  );
}
