import { useRef, useState } from "react";
import MainFrom from "./MainForm";
import { GQL_LEADGEN_BULK_UPDATE_BID_AMOUNT } from "shared/gql/gqlSchema/leadgenGql";
import { openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { groupForBid } from "pages/dashboardPages/Varozi/VaroziUtils";

export default function UpdateAdSetBid(props) {
  const { asset = "", details = [], close = () => {}, is_bulk } = props;
  const resetFormRef = useRef(() => {});
  const [value, setValue] = useState(1);
  const [temp, setTemp] = useState(0);
  const [tableData, setTableData] = useState(details || []);
  let { bid_amount } = details?.[0] || {};
  const by_number = value === 1;

  const [updateAdSetBid, { loading }] = useCustomMutation(GQL_LEADGEN_BULK_UPDATE_BID_AMOUNT, {
    onCompleted(e) {
      // resetFormRef.current();
      const element = e?.fb360_bulkUpdateBidAmount;
      if (element?.success?.length > 0) {
        openNotification({
          type: "success",
          message: element?.success?.map(({ message }) => <p className="mb-0">{message}</p>),
        });
      }

      if (element?.error?.length > 0) {
        openNotification({
          type: "error",
          message: element?.error?.map(({ message }) => <p className="mb-0">{message}</p>),
        });
      }
      close();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit() {
    const groupedIds = groupForBid(details, "adset", by_number, is_bulk, temp);

    // console.log({ value, temp, groupedIds });

    if (groupedIds.length) {
      updateAdSetBid({
        variables: {
          asset: asset,
          updateArray: groupedIds,
        },
      });
    }
  }

  let currentBidValue = 0;
  if (!is_bulk) {
    if (bid_amount !== null) {
      currentBidValue = bid_amount;
    }
  }

  const formProps = {
    onSubmit,
    loading,
    btnText: "Update",
    value,
    setValue,
    temp,
    setTemp,
    setTableData,
    tableData,
    bidValue: currentBidValue,
  };

  return (
    <>
      <MainFrom {...formProps} />
      {/* <pre>{JSON.stringify({ props }, null, 2)}</pre> */}
    </>
  );
}
