import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React from "react";
import {
  CT_UPDATE_USER,
  GET_CT_SOURCES,
  GET_TIKTOK_REVENUE_CREATIVE_REPORT,
  GET_TIKTOK_SPEND_CREATIVE_REPORT,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import {
  GET_COMMON_COMPLIANCE,
  GQL_ADD_COMMON_COMPLIANCE,
  GQL_FB360_SPEND_REPORTING,
  GQL_GET_COMPLIANCE_STATUS,
  GQL_PRODUCT_WISE_USER,
  GQL_VOLUUM_CREATIVE_REVENUE_REPORTING,
  GQL_VS_CREATIVE_REVENUE_REPORTING,
} from "shared/gql/gqlSchema/creativeGql";
import { GET_VOLUUM_CATEGORY, VOLUUM_GET_SUBIDS } from "shared/gql/gqlSchema/fb360Gql";
import { GET_SAS_CATEGORIES } from "shared/gql/gqlSchema/sasGql";
import { GET_UMS_PERMISSION_USERS } from "shared/gql/gqlSchema/umsGql";
import { GET_VS_ALL_CONTENT_BY_ADVETTISER } from "shared/gql/gqlSchema/viralSparksGql";
import { CREATIVE_TYPE, GROUP_TYPE_STATIC } from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";

function useCreativeReportingApiHooks({
  setCategories,
  isGroup,
  setSubIds,
  setUsers,
  setFinalMerge,
  setUpdateduser,
  setRowProps,
  setVisibleUserModal,
  setRevReport,
  setTotalCount,
  setVisibleFilter,
  setActiveKey,
  setSpendReport,
  handleComplianceByCategory,
  setVisibleChildCompliantModal,
  setVisibleCategoryCompliantModal,
  spendDataKey,
  revDataKey,
  creativeType,
  rowProps,
  handleCreativeIds,
  setVisibleParentCompliantModal,
  finalMerge,
  isFbReport,
}) {
  const [
    fetchVsContent,
    { vs_content_data, loading: vs_content_loading, refetch: vs_content_refetch },
  ] = useCustomLazyQuery(GET_VS_ALL_CONTENT_BY_ADVETTISER, {
    notifyOnNetworkStatusChange: true,
    onCompleted(e) {
      const renderData = e?.vs_contentGetAllByAdvertiserId?.data?.map((element) => ({
        name: element?.nickname,
        value: element?.id,
        key: element?.id,
      }));
      setCategories(renderData);
    },
  });
  const [fetchSasCategory, { data = [], loading: sas_loading, error, refetch }] =
    useCustomLazyQuery(GET_SAS_CATEGORIES, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.sasCategories?.map((element, index) => ({
          name: element?.name,
          value: element?.id,
          key: element?.id,
        }));
        setCategories(renderData);
      },
    });
  const [fetchVsRev, { loading: vs_loading, refetch: refetch_vs_rev }] = useCustomLazyQuery(
    GQL_VS_CREATIVE_REVENUE_REPORTING,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.vs_creativeLiveReportsFromTo?.map((element, index) => ({
          ...element,
          revenue: element?.user_revenue,
        }));
        setRevReport(renderData);
      },
    }
  );
  const [
    fetchLeadgen,
    { leadgen_data = [], loading: leadgen_loading, error: leadgen_error, refetch: leadgen_refetch },
  ] = useCustomLazyQuery(GET_VOLUUM_CATEGORY, {
    notifyOnNetworkStatusChange: true,
    onCompleted(e) {
      const renderData = e?.voluum_get_category?.map((element) => ({
        name: element?.name,
        value: element?.id,
        key: element?.id,
      }));
      setCategories(renderData);
    },
  });
  const [getSubIds, { loading: loading_subid, refetch: refetch_subid }] = useCustomLazyQuery(
    VOLUUM_GET_SUBIDS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // const element = e?.fb360_creativeReporting;
        const element = e?.voluum_get_subids;
        if (element !== null) {
          setSubIds(element);
        } else {
          setSubIds([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setSubIds([]);
      },
    }
  );
  const [getUser, { loading: loading_user, refetch: refetch_user }] = useCustomLazyQuery(
    GET_UMS_PERMISSION_USERS,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // const element = e?.voluum_creativeReporting;
        const element = e?.umsPermissionsWiseUser;
        if (element !== null) {
          setUsers((prev) => ({
            ...prev,
            creator: element?.creative_tracking_graphic_designer,
          }));
        } else {
          setUsers([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setUsers([]);
      },
    }
  );
  const [getTeamUser, { loading: loading_team_user, refetch: refetch_team_user }] =
    useCustomLazyQuery(GQL_PRODUCT_WISE_USER, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // const element = e?.voluum_creativeReporting;
        const element = e?.product;
        if (element !== null) {
          setUsers((prev) => ({
            ...prev,
            users: element?.users,
          }));
        } else {
          setUsers([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setUsers([]);
      },
    });

  const [getSources, { loading: loading_source, refetch: refetch_source }] = useCustomLazyQuery(
    GET_CT_SOURCES,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // const element = e?.fb360_creativeReporting;
        const element = e?.ctGetSource;
        if (element?.data !== null) {
          setFinalMerge((prevFinalMerge) =>
            prevFinalMerge?.map((data) => {
              const matchingIndex = element?.data?.findIndex((el) => el?.id === data?.group_id);
              if (matchingIndex !== -1) {
                return { ...data, ...element?.data?.[matchingIndex], hasSource: true };
              } else {
                return { ...data, hasSource: true };
              }
            })
          );
          getComplianceStatus({
            variables: {
              creativeIds: finalMerge
                ?.map((data) => {
                  const creativeId = handleCreativeIds(data);
                  if (creativeId !== undefined) {
                    return {
                      creativeId: creativeId,
                      ...(creativeType === CREATIVE_TYPE && {
                        groupType: isFbReport
                          ? data?.creative_url_object?.groups?.[0]?.groupType || GROUP_TYPE_STATIC
                          : GROUP_TYPE_STATIC,
                      }),
                    };
                  } else {
                    return null;
                  }
                })
                .filter((data) => data !== null),
              creativeType: creativeType === CREATIVE_TYPE ? "GROUP" : creativeType,
            },
          });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [
    getComplianceStatus,
    { loading: loading_compliance_status, refetch: refetch_compliance_status },
  ] = useCustomLazyQuery(GQL_GET_COMPLIANCE_STATUS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.ct_getComplianceStatus;
      if (element?.data !== null) {
        setFinalMerge((prevFinalMerge) =>
          prevFinalMerge?.map((data) => {
            const matchingIndex = element?.data?.findIndex(
              (el) => el?.creativeId === data?.group_id
            );
            if (matchingIndex !== -1) {
              return {
                ...data,
                complianceStatus: element?.data?.[matchingIndex]?.status,
                hasSource: true,
              };
            } else {
              return { ...data, hasSource: true };
            }
          })
        );
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [updateUser, { loading: loading_update_user }] = useCustomMutation(CT_UPDATE_USER, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      // const element = e?.voluum_creativeReporting;
      const element = e?.ctUpdateUser?.data;
      if (element !== null) {
        setFinalMerge((elem) => {
          const indexToUpdate = elem?.findIndex((data) => data?.group_id === rowProps?.group_id);
          if (indexToUpdate !== -1) {
            const updatedData = {
              ...elem[indexToUpdate],
              user: element?.[0]?.user,
            };
            const newArray = [...elem];
            newArray[indexToUpdate] = updatedData;
            return newArray;
          } else {
            return elem;
          }
        });
        setVisibleUserModal(false);
        setRowProps([]);

        setUpdateduser();
      } else {
        openNotification({ type: "error", message: element?.message, key: "error" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
      setRevReport([]);
      setTotalCount(0);
    },
  });
  const [updateCreativeCompliance, { loading: loading_add_compliance }] = useCustomMutation(
    GQL_ADD_COMMON_COMPLIANCE,
    {
      notifyOnNetworkStatusChange: true,

      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const [revenueFbFetch, { loading: loading_rev_fb_reports, refetch: refetch_fb_rev }] =
    useCustomLazyQuery(GQL_VOLUUM_CREATIVE_REVENUE_REPORTING, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // const element = e?.voluum_creativeReporting;
        const element = e?.[revDataKey];
        if (element !== null) {
          setRevReport(
            element?.map((data) => ({ ...data, revenue: data?.revenue + data?.payout_amount }))
          );
          setVisibleFilter(false);
          setActiveKey(null);
        } else {
          setRevReport([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setRevReport([]);
        setTotalCount(0);
      },
    });
  const [revenueTiktokFetch, { loading: loading_rev_tiktok_reports, refetch: refetch_tiktok_rev }] =
    useCustomLazyQuery(GET_TIKTOK_REVENUE_CREATIVE_REPORT, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // const element = e?.voluum_creativeReporting;
        const element = e?.[revDataKey];
        if (element === null || element?.length === 0) {
          setRevReport([]);
        } else {
          setVisibleFilter(false);
          setActiveKey(null);
          setRevReport(
            element?.map((data) => ({ ...data, revenue: data?.estimate_revenue || data?.revenue }))
          );
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setRevReport([]);
        setTotalCount(0);
      },
    });
  const [spendFbFetch, { loading: loading_spend_fb__reports, refetch: refetch_spend_fb }] =
    useCustomLazyQuery(GQL_FB360_SPEND_REPORTING, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.[spendDataKey];
        if (element !== null) {
          setSpendReport(element);
          setVisibleFilter(false);
          setActiveKey(null);
        } else {
          setSpendReport([]);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
        setSpendReport([]);
        setTotalCount(0);
      },
    });
  const [
    spendTiktokFetch,
    { loading: loading_spend_tiktok_reports, refetch: refetch_spend_tiktok },
  ] = useCustomLazyQuery(GET_TIKTOK_SPEND_CREATIVE_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      // const element = e?.fb360_creativeReporting;

      const element = e?.[spendDataKey];
      if (element === null || element?.length === 0) {
        setSpendReport([]);
      } else {
        setSpendReport(element);
        setVisibleFilter(false);
        setActiveKey(null);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
      setSpendReport([]);
      setTotalCount(0);
    },
  });
  const [getCommonCompliance, { loading: loading_compliance, refetch: refetch_compliance }] =
    useCustomLazyQuery(GET_COMMON_COMPLIANCE, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        // const element = e?.fb360_creativeReporting;
        const element = e?.ct_getCommonCompliance;
        if (element?.data !== null) {
          if (isGroup) {
            handleComplianceByCategory(element);
            setVisibleParentCompliantModal(true);
          } else {
            handleComplianceByCategory(element);
            setVisibleChildCompliantModal(true);
          }

          setVisibleCategoryCompliantModal(false);
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    });
  return {
    fetchVsContent,
    vs_content_data,
    vs_content_loading,
    vs_content_refetch,
    fetchSasCategory,
    sas_loading,
    fetchLeadgen,
    leadgen_loading,
    leadgen_data,
    leadgen_error,
    leadgen_refetch,
    getSubIds,
    loading_subid,
    refetch_subid,
    getUser,
    loading_user,
    refetch_user,
    getTeamUser,
    loading_team_user,
    getSources,
    getComplianceStatus,
    loading_compliance_status,
    refetch_compliance_status,
    updateUser,
    loading_update_user,
    updateCreativeCompliance,
    loading_add_compliance,
    revenueFbFetch,
    loading_rev_fb_reports,
    refetch_fb_rev,
    revenueTiktokFetch,
    loading_rev_tiktok_reports,
    refetch_tiktok_rev,
    spendFbFetch,
    loading_spend_fb__reports,
    refetch_spend_fb,
    spendTiktokFetch,
    loading_spend_tiktok_reports,
    refetch_spend_tiktok,
    getCommonCompliance,
    loading_compliance,
    fetchVsRev,
    vs_loading,
    refetch_vs_rev,
  };
}

export default useCreativeReportingApiHooks;
