import { useSelector } from "react-redux";
import { detectValueType } from "utils";

export function permissionCheck(userHavePermissions, permissionAllows) {
  /**
   * userHavePermissions it's me api data
   */
  let havePermission = false;

  if (detectValueType(permissionAllows) === "array") {
    havePermission = userHavePermissions?.some((fromAll) =>
      permissionAllows?.some((fromCurrent) => fromAll === fromCurrent)
    );
  } else {
    havePermission = userHavePermissions?.findIndex((name) => name === permissionAllows) !== -1;
  }

  return havePermission;
}

export function platformCheck(userHavePlatforms, platformAllows) {
  let havePermission = false;

  if (detectValueType(platformAllows) === "array") {
    havePermission = userHavePlatforms?.some((userPlatform) =>
      platformAllows?.some((allowedPlatform) => userPlatform === allowedPlatform?.id)
    );
  } else {
    havePermission =
      userHavePlatforms?.findIndex((platform) => platform?.id === platformAllows) !== -1;
  }

  return havePermission;
}
export function platformAndPermissionCheck(
  userHavePermissions = [],
  userHavePlatforms = [],
  platformPermissionSetAllowed
) {
  if (!platformPermissionSetAllowed) return true;
  if (
    detectValueType(platformPermissionSetAllowed) === "string" ||
    detectValueType(platformPermissionSetAllowed) === "array"
  ) {
    return permissionCheck(userHavePermissions, platformPermissionSetAllowed);
  }
  if (detectValueType(platformPermissionSetAllowed) === "object") {
    const { permission, platform } = platformPermissionSetAllowed;

    return (
      permissionCheck(userHavePermissions, permission) && platformCheck(userHavePlatforms, platform)
    );
  }

  return false;
}

export default function usePermissionCheck(currentPermissons) {
  /**
   * currentPermissons is optional now
   * it's required when use havePermission
   */
  const { permissions, platforms } = useSelector((state) => state?.userReducer?.me) || {};

  function havePlatformPermissionCheck(e) {
    /**
     * e -> have platform or permission data set
     */
    return platformAndPermissionCheck(permissions, platforms, e);
  }

  return {
    havePermission: platformAndPermissionCheck(permissions, platforms, currentPermissons),
    havePlatformPermissionCheck,
  };
}
