import { Alert, Checkbox, Col, Form, Row } from "antd";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ADD_MEDIA_FROM_TEMPLATE } from "shared/gql/gqlSchema/creativeDashboardGql";
import { GET_VOLUUM_CATEGORY } from "shared/gql/gqlSchema/fb360Gql";
import { GET_SAS_CATEGORIES } from "shared/gql/gqlSchema/sasGql";
import { GET_VS_ALL_CONTENT_BY_ADVETTISER } from "shared/gql/gqlSchema/viralSparksGql";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import { CREATIVE_TYPE_IMAGE } from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";

function TemplateVariations(props) {
  const {
    templateData,
    disabled,
    reloadMedia = () => {},
    templateType,
    showVariants,
    activeTab,
    refetch = () => {},
    setVisibleDrawer = () => {},
    setUpdatedData = () => {},
    id,
    loading = false,
    setActiveParentTab = () => {},
    mediaType = "",
    createMediaData = [],
    setCreateMediaData = () => {},
    categoryId = "",
    categoryName = "",
    productId = "",
    creativeBuilderV2 = false,
    onCloseDrawer = () => {},
    updatedData = [],
  } = props;

  const { Image } = require("antd");
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [values, setValues] = useState({});
  const [categories, setCategories] = useState();
  const { vsAdvertiser = [], vsAdvertiser_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};
  const productList = useSelector((state) => state?.userReducer?.workSpaceProducts);
  const [fetchVsContent, { vs_content_data, loading: vs_content_loading, vs_content_refetch }] =
    useCustomLazyQuery(GET_VS_ALL_CONTENT_BY_ADVETTISER, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.vs_contentGetAllByAdvertiserId?.data?.map((element) => ({
          name: element?.nickname,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });

  const creativeBuilderVariables = creativeBuilderV2 ? { categoryId, categoryName, productId } : {};
  const [addMedia, { loading: loading_ADD_MEDIA }] = useCustomMutation(ADD_MEDIA_FROM_TEMPLATE, {
    variables: {
      ...creativeBuilderVariables,
      templateId: id,
      mediaType: templateType || mediaType,
    },
    onCompleted: (e) => {
      const element = e?.ctAddMediaFromTemplate;
      if (element?.status === "SUCCESS") {
        openNotification({ type: "success", message: "Media Added Successfully" });
        setSelectedMedia([]);
        setVisibleDrawer(false);
        setVisibleModal(false);
        setActiveParentTab("creative-preview");
        setUpdatedData([]);
        onCloseDrawer();
      } else {
        openNotification({ type: "error", message: element?.message });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });
  const [fetchSasCategory, { data = [], loading: sas_loading, error, refetch: sas_refetch }] =
    useCustomLazyQuery(GET_SAS_CATEGORIES, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.sasCategories?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });
  const [fetchLeadgen, { leadgen_data = [], leadgen_loading, leadgen_error, leadgen_refetch }] =
    useCustomLazyQuery(GET_VOLUUM_CATEGORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted(e) {
        const renderData = e?.voluum_get_category?.map((element) => ({
          name: element?.name,
          value: element?.id,
        }));
        setCategories(renderData);
      },
    });
  const handleCheckboxChange = (mediaId) => {
    if (selectedMedia?.includes(mediaId)) {
      setSelectedMedia(selectedMedia?.filter((id) => id !== mediaId));
    } else {
      setSelectedMedia([...selectedMedia, mediaId]);
    }
  };

  const bulkActions = [
    {
      option: "plus-square",
      title: "Save Creatives",
      onClick: (e) => {
        if (props?.categoryId?.[0]?.productCategoryId !== "0") {
          const finalData = templateData?.filter((data) => selectedMedia?.includes(data?.id));
          addMedia({
            variables: {
              modifications: finalData?.map((media) => ({
                ...media?.modification,
                videoClipIds: creativeBuilderV2
                  ? media?.modification?.videoClipIds?.map((item) => item.id)
                  : undefined,
                creatomateUrl: media?.url,
                creatomateId: media?.id,
                status: media?.status,
              })),
            },
          });
        } else {
          setVisibleModal(true);
        }
      },
    },
  ];
  function selectedRowsClear() {
    setSelectedMedia([]);
    setVisibleModal(false);
  }

  const bulkActionProps = {
    selectedRows: selectedMedia,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  function onSubmitModal() {
    const { productId = "", categoryId = "", categoryName = "" } = values;
    const finalData = templateData?.filter((data) => selectedMedia?.includes(data?.mediaId));
    addMedia({
      variables: {
        modifications: finalData?.map((media) => ({
          ...media?.modification,
          creatomateUrl: media?.mediaUrl,
          creatomateId: media?.mediaId,
          status: media?.status,
        })),

        ...(productId && { productId, categoryId, categoryName }),
      },
    });
  }
  function onCloseModal() {
    setVisibleModal(false);
    setSelectedMedia([]);
    setValues({});
  }
  const modalProps = {
    visible: visibleModal,
    onSubmit: onSubmitModal,
    onCancel: onCloseModal,
    okokText: "Submit",
    loader: loading_ADD_MEDIA,
    title: "Save Creatives",
  };

  const handleRefetch = () => {
    refetch();
  };

  return (
    <div>
      <Row justify="space-between">
        <Col span={12}>
          <LookButton
            tooltipTitle="Refetch"
            className="filter-btn ml-8"
            size="small"
            iconType="reload"
            iconTypeProps={{ spin: disabled }}
            onClick={handleRefetch}
          />
        </Col>
        {showVariants && (
          <Col span={12}>
            {creativeBuilderV2 ? (
              <Alert
                message="Please save and check your variants in your respective category. These variations will be automatically deleted after 1 day."
                type="info"
                showIcon
                style={{ marginBottom: "10px" }}
              />
            ) : (
              <Alert
                message=" All the temporary variations will be removed after 1 day."
                type="info"
                showIcon
                style={{ marginBottom: "10px" }}
              />
            )}
          </Col>
        )}
      </Row>

      <LookModal {...modalProps}>
        {visibleModal && (
          <Form layout="vertical">
            <Row gutter={12}>
              <Col span={24}>
                <SelectInput
                  name="productId"
                  label="Product"
                  search
                  placeholder="Select products"
                  className="look-form-input"
                  value={values?.productId?.toString()}
                  onChange={(value) => {
                    setValues((prev) => ({ ...prev, productId: parseInt(value) }));

                    if (parseInt(value) === PRODUCT_ID_SAS) {
                      fetchSasCategory();
                    } else if (parseInt(value) === PRODUCT_ID_LEADGEN) {
                      fetchLeadgen();
                    }
                  }}
                  options={productList?.map(({ name, id }) => ({ name, value: id }))}
                />
              </Col>
            </Row>
            {values?.productId === PRODUCT_ID_VS && (
              <Row gutter={12}>
                <Col span={24}>
                  <SelectInput
                    name="advertiser"
                    label="Advertiser"
                    placeholder="Select Advertiser"
                    className="look-form-input"
                    search
                    loader={vsAdvertiser_loading}
                    value={values?.advertiser?.toString()}
                    onChange={(value) => {
                      setValues((prev) => ({ ...prev, advertiser: parseInt(value) }));
                      if (values?.productId === PRODUCT_ID_VS) {
                        fetchVsContent({ variables: { advertiser_id: value } });
                      }
                    }}
                    options={vsAdvertiser?.map(({ id, name }) => ({ name, value: id }))}
                  />
                </Col>
              </Row>
            )}
            <Row gutter={12}>
              <Col span={24}>
                <SelectInput
                  name="categoryId"
                  label="Category"
                  search
                  placeholder="Select Category"
                  className="look-form-input"
                  options={categories}
                  value={values?.categoryId?.toString()}
                  loader={vs_content_loading || sas_loading || leadgen_loading}
                  onChange={(value) => {
                    setValues((prev) => ({
                      ...prev,
                      categoryId: parseInt(value),
                      categoryName: categories?.find((category) => category?.value === value)?.name,
                    }));
                  }}
                />
              </Col>
            </Row>
          </Form>
        )}
      </LookModal>
      <LookTableBulkAction {...bulkActionProps} label="Selected Images" />
      <Row gutter={15}>
        {loading ? (
          <div className="min-h-100-vh">
            <Loader elementCenter />
          </div>
        ) : templateData?.length === 0 || templateData === undefined ? (
          <div className="text-center" style={{ marginLeft: "300px" }}>
            <div style={{ transform: "translateX(20px)" }}>
              <ActionIconRender iconType="tablePlaceholder" />
            </div>
            <p style={{ fontSize: "1.5rem" }}>
              <b>No Variations Found...</b>
            </p>
          </div>
        ) : (
          templateData?.map((item, index) => {
            return (
              <Col span={8} key={index} style={{ padding: "1rem" }}>
                {templateType === CREATIVE_TYPE_IMAGE ? (
                  <Image
                    src={!showVariants ? item?.creatomateUrl : item?.url}
                    key={!showVariants ? item?.id : item.id}
                    preview={false}
                    style={{ objectFit: "fill" }}
                  />
                ) : (
                  <video
                    className="w-100"
                    height="190"
                    muted
                    controls
                    src={!showVariants ? item?.creatomateUrl : item?.url}
                    key={!showVariants ? item?.id : item.id}
                  ></video>
                )}
                {showVariants && activeTab === "Successful" && (
                  <div className="main-controls-div">
                    <h4> Creatomate Variant - {index + 1}</h4>
                    <Checkbox
                      className="creative-select"
                      onChange={() => handleCheckboxChange(item?.id)}
                      checked={selectedMedia?.includes(item?.id)}
                    />
                  </div>
                )}
              </Col>
            );
          })
        )}
      </Row>
    </div>
  );
}

export default TemplateVariations;
