export function hslToHex(h, s, l) {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0;
  let g = 0;
  let b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255).toString(16);
  g = Math.round((g + m) * 255).toString(16);
  b = Math.round((b + m) * 255).toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
}

export function generateHexRandomColors(n) {
  const saturation = 100;
  const lightness = 50;
  const hueDivision = 360 / n;
  const colors = [];

  for (let i = 0; i < n; i++) {
    const hue = i * hueDivision;
    colors.push(hslToHex(hue, saturation, lightness));
  }

  return colors;
}

export function generateHexDistinctColors(n) {
  const hues = [];
  const colors = [];

  // Defining step sizes for hue, saturation, and lightness
  const hueStep = 137.508; // Use the golden ratio for better distribution of colors
  const satSteps = [80, 90, 100];
  const lightSteps = [40, 50, 60];

  // Adjust the starting hue to avoid red hues
  let startingHue = 0;
  const excludedHues = [0, 15, 30, 345, 330]; // Red hues and similar shades
  for (let hue of excludedHues) {
    if (startingHue >= hue) {
      startingHue += hueStep;
    }
  }

  for (let i = 0; i < n; i++) {
    let hue = (startingHue + i * hueStep) % 360; // Keep the hue within [0, 360]
    if (hues.indexOf(hue) === -1) hues.push(hue);

    let sat = satSteps[i % satSteps.length];
    let light = lightSteps[i % lightSteps.length];

    colors.push(hslToHex(hue, sat, light));
  }

  return colors;
}

export function hexToRgba(hex, alpha = 1) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
