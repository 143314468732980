import { Button, Switch, Tooltip } from "antd";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

import LookModal from "components/LookModal";
import LookTable from "components/LookTable";

import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useState, useImperativeHandle } from "react";
import {
  GQL_RE_COPY_RULES,
  GQL_RE_DELETERULE,
  GQL_RE_GETRULEBYSTRATEGY,
  GQL_RE_MANUALRUN,
  GQL_RE_UPDATERULESTATUS,
} from "shared/gql/gqlSchema/ruleGql";
import { openNotification } from "utils";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import UpdateRuleForm from "../UpdateRules";
import NewRuleForm from "../NewRules";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import moment from "moment";
import RuleLinkedItems from "../RuleLinkedItems";
import RuleActivity from "../RuleActivityLogs";
import NewStrategyForm from "../NewStrategies";
import SelectInput from "components/forms/SelectInput";
import { USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2 } from "actions/actionTypes";

export default function UpdateStrategy(props) {
  const {
    persistKey = USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
    close = () => {},
    refetch,
    platform,
    productId,
    strategy,
    allStrategies,
  } = props;
  const filteredAllStrategies = allStrategies.filter((item) => item.value !== strategy?.id);
  const [data, setdata] = useState([]);
  const [Do, setDo] = useState("");
  const [update, setupdate] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [select, setSelect] = useState();
  const [categorySelect, setCategorySelect] = useState(false);
  const [selectedRuleIds, setSelectRuleids] = useState([]);
  const [
    clgetRuleByStrategy,
    { loading: loading_clgetRuleByStartegy, refetch: clruleFetchByStrategy },
  ] = useCustomLazyQuery(GQL_RE_GETRULEBYSTRATEGY, {
    variables: {
      strategy_id: strategy?.id,
      platform: platform,
      product: productId,
    },
    onCompleted: (e) => {
      const element = e?.re_getRulebyStartegy;
      if (element) {
        let res = [];
        for (let i in element) {
          res.push({
            key: element[i].id,
            id: element[i].id,
            actions: element[i].action,
            period: element[i].cron_command,
            name: element[i].name,
            rules: element[i].all,
            timezone: element[i].timezone,
            report_timeline: element[i].report_timeline,
            message: element[i].message,
            ids: element[i].ids,
            status: element[i].status,
            category_name: element[i].category_name,
            exclusion_name: element[i].exclusion_name,
            is_orType: element[i].is_orType,
            budget: element[i].budget,
            reporting_days: element[i].reporting_days,
            lifespan: element[i].lifespan,
            nextOccurrence:
              element[i].nextOccurrence === null
                ? null
                : moment(element[i].nextOccurrence).format("YYYY-MM-DD, HH:mm:ss"),
            previousOccurrence:
              element[i].previousOccurrence === null
                ? null
                : moment(element[i].previousOccurrence).format("YYYY-MM-DD, HH:mm:ss"),
            processed_count: element[i].processed_count,
            error_count: element[i].error_count,
            previousStatus: element[i].previousStatus,
            platform: element[i].platform,
            product: element[i].productId,
            groupBy: element[i].groupBy,
            reportType: element[i].report_type,
            created_at:
              element[i].created_at === null
                ? null
                : moment(element[i].created_at).format("YYYY-MM-DD, HH:mm:ss"),
            strategy_id: element[i].strategy_id,
          });
        }
        // console.log(res);
        setdata(res);
      }
    },
    onError(e) {
      setdata([]);
    },
  });
  useEffect(() => {
    clgetRuleByStrategy();
  }, []);

  const [cldeleteRule, { loading: loading_DeleteGroups }] = useCustomMutation(GQL_RE_DELETERULE, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_deleteRule;
      if (element) {
        openNotification({ type: "success", message: "Rule deleted Successfully" });
        clruleFetchByStrategy();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [clcopyRules, { loading: loading_copyRules }] = useCustomMutation(GQL_RE_COPY_RULES, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_copyRules;
      if (element) {
        openNotification({ type: "success", message: "Rule copied Successfully" });
        clruleFetchByStrategy();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  function ruleDuplicte() {
    clcopyRules({
      variables: {
        strategy_id: select,
        rule_ids: selectedRuleIds,
      },
    });
    setCategorySelect(false);
  }
  const [clupdateRuleStatus] = useCustomMutation(GQL_RE_UPDATERULESTATUS, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_updateRuleStatus;
      if (element) {
        openNotification({ type: "success", message: "Rule status updated Successfully" });
        clruleFetchByStrategy();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const [clmanualRunRule, { loading: manualrun_loading }] = useCustomLazyQuery(GQL_RE_MANUALRUN, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.re_updateRuleStatus;
      if (element) {
        openNotification({ type: "success", message: e?.message });
        clruleFetchByStrategy();
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const handleManualRun = (otherProps) => {
    clmanualRunRule({
      variables: {
        id: otherProps?.id,
        name: otherProps?.name,
      },
    });
  };
  const handleStatus = (e, otherProps) => {
    if (e) {
      clupdateRuleStatus({
        variables: {
          id: otherProps?.id,
          name: otherProps?.name,
          status: "NOTinqueue",
          groupBy: otherProps?.groupBy,
        },
      });
    } else {
      clupdateRuleStatus({
        variables: {
          id: otherProps?.id,
          name: otherProps?.name,
          status: "paused",
          groupBy: otherProps?.groupBy,
        },
      });
    }
  };

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    cldeleteRule({
      variables: {
        id: deleteModalPropsData?.id,
        name: deleteModalPropsData?.name,
        groupBy: deleteModalPropsData?.groupBy,
      },
    });
    setDeleteModalVisible(false);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData(null);
    setDeleteModalVisible(false);
  };

  function showFilterSearch(data) {
    if (data) {
      setVisible(true);
      setDo("add");
      setupdate(data);
    } else {
      setVisible(true);
      setDo("add");
    }
  }

  function updateFilterSearch(data) {
    setVisible(true);
    setDo("update");
    setupdate(data);
  }
  function onClose() {
    setVisible(false);
    setupdate(false);
  }

  const drawerProps = {
    title: "Co-Pilot",
    icon: () => <ActionIconRender className="mr-10" iconType="cluster" />,
    size: 800,
    visible,
    onClose,
  };
  const initialColumns = [
    {
      title: "Rule name",
      dataIndex: "name",
      key: "name",
      searchable: true,
      searchType: "string",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={value}
          isClick={() => {
            updateFilterSearch(otherProps);
          }}
        />
      ),
      width: 160,
      minWidth: 160,
    },
    // {
    //   title: "Rule ID",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 160,
    //   minWidth: 160,

    // },
    {
      title: "Applied On",
      dataIndex: "created_at",
      key: "created_at",
      width: 160,
      minWidth: 160,
      // render: (value, otherProps) => <LookTableColumnRender title={otherProps?.action} />,
    },
    {
      title: "Last Activity On",
      dataIndex: "previousOccurrence",
      key: "previousOccurrence",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Next Activity On",
      dataIndex: "nextOccurrence",
      key: "nextOccurrence",
      width: 160,
      minWidth: 160,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
    },
    {
      title: "Rule Action",
      dataIndex: "actions",
      key: "actions",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={
            value === "ACTIVE" || value === "ENABLE"
              ? "Turn On"
              : value === "PAUSED" || value === "DISABLE"
              ? "Turn Off"
              : value
          }
        />
      ),
      width: 90,
      minWidth: 160,
    },
    {
      title: "Previous Status",
      dataIndex: "previousStatus",
      key: "previousStatus",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Current Status",
      dataIndex: "status",
      key: "status",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Apply At",
      dataIndex: "groupBy",
      key: "groupBy",
      // searchable: true,
      width: 90,
      minWidth: 160,
    },
    {
      title: "Actions",
      dataIndex: "action",
      nofilter: true,
      render: (value, otherProps) => {
        return (
          <>
            <Tooltip title="Copy Rule">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="copy" />}
                onClick={
                  () => showFilterSearch(otherProps)
                  // deleteRule(otherProps.name)
                }
              />
            </Tooltip>
            <Tooltip title="Manual Run">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="play" />}
                disabled={manualrun_loading}
                onClick={() => handleManualRun(otherProps)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                className="h-auto"
                type="link"
                icon={<ActionIconRender iconType="delete" />}
                onClick={
                  () => onOpenDeleteModal(otherProps)
                  // deleteRule(otherProps.name)
                }
              />
            </Tooltip>

            <Switch
              className="mr-8"
              style={{ backgroundColor: "#141414" }}
              checked={otherProps.status !== "paused"}
              onChange={(e) => handleStatus(e, otherProps)}
            />
          </>
        );
      },
      fixed: "right",
      width: 160,
    },
  ];

  const bulkActions = [
    {
      option: "duplicate",
      title: "Duplicate Rules",
      onClick: (e) => {
        setCategorySelect(true);
        const ids = e.map((item) => item.id);
        setSelectRuleids(ids);
      },
    },
  ];
  const tableProps = {
    persistKey,
    tableProps: { initialColumns, recordData: data },
    bulkActions,
  };
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: loading_DeleteGroups,
  };
  const onClosebulkModal = () => {
    setCategorySelect(false);
    // setCampaignModalPropsData([]);
  };

  const bulkModalProps = {
    visible: categorySelect,
    onCancel: onClosebulkModal,
    title: "Duplicate Rules In..",
    okokText: "Create",
    footer: false,
  };
  return (
    <>
      <DashboardHeader
        title="Rules"
        createBtn
        createBtnText="Add New Rule"
        createBtnType="add"
        // createBtnIconType=""
        createBtnHandler={showFilterSearch}
      />
      <LookTable
        {...tableProps}
        loading={loading_clgetRuleByStartegy}
        refetch={clgetRuleByStrategy}
      />
      <NewStrategyForm
        // close={() => close()}
        {...props}
        refetch={refetch}
        strategy={strategy}
      />
      <LookTableRecordDeleteModal {...deleteModalProps} />
      <LookModal {...bulkModalProps}>
        {categorySelect && (
          <div>
            <SelectInput
              search
              name="copy rules in"
              className="mb-0"
              placeholder="select startegy where you want to copy"
              value={select}
              options={[...filteredAllStrategies]}
              onChange={setSelect}
            />
            <div style={{ marginTop: "20px" }} className="d-flex mb-10 justify-content-center">
              <Button
                style={{ width: "50%" }}
                className="mr-6"
                key="submit"
                type="primary"
                onClick={() => ruleDuplicte()}
              >
                Duplicate
              </Button>
            </div>
          </div>
        )}
      </LookModal>
      <div>
        <LookDrawer {...drawerProps}>
          {visible &&
            (update && Do === "update" ? (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add/Update",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <UpdateRuleForm
                        close={() => onClose()}
                        data={update}
                        refetch={clruleFetchByStrategy}
                        platform={platform}
                        strategy_id={strategy.id}
                        productId={productId}
                      />
                    ),
                  },
                  // {
                  //   hoverTitle: "Rule Linked Items",
                  //   icon: () => <ActionIconRender iconType="ordered-list" />,
                  //   component: <RuleLinkedItems close={() => onClose()} ids={ids} />,
                  // },
                  {
                    hoverTitle: "Rule Linked Activity",
                    icon: () => <ActionIconRender iconType="notification" />,
                    component: <RuleActivity close={() => onClose()} rule_id={update?.id} />,
                  },
                ]}
              />
            ) : (
              <LookDrawerMainSideBar
                tabContent={[
                  {
                    hoverTitle: "Add/Update",
                    icon: () => <ActionIconRender iconType="pic-left" />,
                    component: (
                      <NewRuleForm
                        close={() => onClose()}
                        {...props}
                        refetch={clruleFetchByStrategy}
                        platform={platform}
                        copy={update}
                        strategy_id={strategy.id}
                      />
                    ),
                  },
                  // {
                  //   hoverTitle: "Rule Linked Items",
                  //   icon: () => <ActionIconRender iconType="ordered-list" />,
                  //   component: <RuleLinkedItems close={() => onClose()} ids={ids} />,
                  // },
                ]}
              />
            ))}
        </LookDrawer>
      </div>
    </>
  );
}
