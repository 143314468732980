import {
  USER_SETTING_MAIN_PAGINATION,
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
} from "actions/actionTypes";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import LookDrawer from "components/LookDrawer";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { useLookTableParentContext } from "hooks/lookTableParentContext";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { openNotification } from "utils";
import AddForm from "./AddForm";
import { useApiToStoreContext } from "hooks/useApiToStore/context";
import {
  SAS_DELETE_EXTERNAL_CHANNEL,
  SAS_PAGED_EXTERNAL_CHANNEL,
} from "shared/gql/gqlSchema/sasGql";
import { Button, Tooltip } from "antd";
import UpdateForm from "./Update";
import { useApiToStore, usePermissionCheck } from "hooks";
import { SAS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { API_STORE_SAS_VENDOR_DROPDOWN } from "hooks/useApiToStore/keys";

export default function ExternalChannel(props) {
  const dispatch = useDispatch();
  const { persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN } = props;
  const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);
  const [visibleUpdateDrawer, setVisibleUpdateDrawer] = useState(false);
  const [externalChannel, setExternalChannel] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  const { havePermission: isSasAgencyAdmin } = usePermissionCheck(SAS_AGENCY_ADMIN);
  const { sasPlatformsFetch, sasCategoriesFetch } = useApiToStoreContext();
  const { apiCall: vendorDataFetch = () => {} } = useApiToStore(API_STORE_SAS_VENDOR_DROPDOWN);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const mainPagination = useSelector((state) => state?.userSettings?.mainPagination);
  const mainPaginationData = useSelector(
    (state) => state?.userSettings?.mainPagination?.[persistKey]
  );
  useEffect(() => {
    if (visibleAddDrawer || visibleUpdateDrawer) {
      sasPlatformsFetch();
      sasCategoriesFetch();
      vendorDataFetch();
    }
  }, [visibleAddDrawer, visibleUpdateDrawer]);

  const { selectedRowsClear } = useLookTableParentContext();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);

  const currentSorter = useSelector((state) => state?.userSettings?.sorter?.[persistKey]);
  const sort = currentSorter?.order ? { ...currentSorter } : {};
  const apiPayload = {
    page: mainPaginationData?.page - 1,
    size: mainPaginationData?.size,
    search,
    ...sort,
  };
  const { data, loading, refetch } = useCustomQuery(SAS_PAGED_EXTERNAL_CHANNEL, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.sasPagedExternalChannels?.data?.map((element) => ({
        ...element,
        key: element.id,
      }));
      if (element) {
        setExternalChannel(element);
      }
    },
    variables: { ...apiPayload },
  });

  const [channelDelete, { loading: deleteLoader }] = useCustomMutation(
    SAS_DELETE_EXTERNAL_CHANNEL,
    {
      onCompleted(e) {
        refetch();
        setDeleteModalVisible(false);
        if (e?.sasDeleteExternalChannel) {
          openNotification({ type: "success", message: "Record Deleted Success" });
        } else {
          openNotification({
            type: "error",
            message: "Record Deleted Not Success",
            description: e.message,
          });
        }
        selectedRowsClear();
      },
      onError(e) {
        openNotification({
          type: "error",
          message: "Record Deleted Not Success",
          description: e.message,
        });
      },
    }
  );

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    let channelId = deleteModalPropsData?.id;
    channelDelete({ variables: { id: channelId } });
  };

  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };

  const fetchDatakey = data?.sasPagedExternalChannels;
  useEffect(() => {
    if (!loading) {
      dispatch({
        type: USER_SETTING_MAIN_PAGINATION,
        payload: {
          ...mainPagination,
          [persistKey]: {
            ...mainPagination?.[persistKey],
            totalCount: fetchDatakey?.pageInfo?.totalCount,
          },
        },
      });
    }
  }, [loading, fetchDatakey]);

  const showAddDrawer = () => {
    setVisibleAddDrawer(true);
  };
  const onCloseAddDrawer = () => {
    setVisibleAddDrawer(false);
  };

  const showUpdateDrawer = (rowProps = {}) => {
    setVisibleUpdateDrawer(true);
    setRowProps(rowProps);
  };
  const onCloseUpdateDrawer = () => {
    setRowProps({});
    setVisibleUpdateDrawer(false);
  };
  const action = isSasAgencyAdmin
    ? [
        {
          title: "Action",
          dataIndex: "action",
          nofilter: true,
          render: (action, otherProps) => {
            return (
              <>
                <Tooltip title="Edit">
                  <Button
                    className="ml-12 p-0 h-auto w-auto"
                    type="link"
                    icon={<ActionIconRender iconType="edit" />}
                    onClick={() => {
                      showUpdateDrawer(otherProps);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    className="ml-12 p-0 h-auto w-auto"
                    type="link"
                    icon={<ActionIconRender iconType="delete" />}
                    onClick={() => {
                      onOpenDeleteModal(otherProps);
                    }}
                  />
                </Tooltip>
              </>
            );
          },
          fixed: "right",
          width: 124,
          minWidth: 124,
        },
      ]
    : [];

  const initialColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      searchable: true,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Channel Id",
      dataIndex: "channel",
      key: "channel",
      searchable: true,
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Playform",
      dataIndex: "platformId",
      key: "platformId",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value, otherProps) => <LookTableColumnRender title={value?.name} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Restricted",
      dataIndex: "isRestricted",
      key: "isRestricted",
      render: (value, otherProps) => (
        <LookTableColumnRender
          title={
            value ? (
              <ActionIconRender iconType="greenTick" />
            ) : (
              <ActionIconRender iconType="redCross" />
            )
          }
        />
      ),
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Style Id",
      dataIndex: "styleId",
      key: "styleId",
      render: (value, otherProps) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },
    {
      title: "Vendor Id",
      dataIndex: "vendor",
      key: "vendor",
      render: (value, otherProps) => <LookTableColumnRender title={value?.name} />,
      fixed: "left",
      width: 124,
      minWidth: 124,
    },

    ...action,
  ];

  const tableProps = { initialColumns, recordData: externalChannel };

  const createBtnProps = {
    createBtn: true,
    createBtnText: "Add Channel",
    createBtnHandler: showAddDrawer,
  };

  const drawerAddProps = {
    title: "Add New Channel",
    icon: (e) => <ActionIconRender {...e} iconType="select" />,
    visible: visibleAddDrawer,
    onClose: onCloseAddDrawer,
    size: 700,
  };

  const drawerPropsUpdate = {
    title: "Update Channel",
    icon: (e) => <ActionIconRender {...e} iconType="edit" />,
    visible: visibleUpdateDrawer,
    onClose: onCloseUpdateDrawer,
    size: 700,
  };

  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };

  const bulkActions = [{ option: "delete", title: "Delete", onClick: onOpenDeleteModal }];

  const debugContent = [{ isRow: true, contentObjects: [{ externalChannel }] }];
  return (
    <>
      <DashboardHeader title="Channel" {...createBtnProps} />
      <LookTableRecordDeleteModal {...deleteModalProps} />
      <LookDrawer {...drawerAddProps}>
        <AddForm
          refetch={refetch}
          onClose={onCloseAddDrawer}
          dataSource={externalChannel}
          setDataSource={setExternalChannel}
        />
      </LookDrawer>
      <LookDrawer {...drawerPropsUpdate}>
        {visibleUpdateDrawer && (
          <UpdateForm
            onClose={onCloseUpdateDrawer}
            visible={visibleUpdateDrawer}
            refetch={refetch}
            {...rowPropsData}
          />
        )}
      </LookDrawer>
      <LookTable
        persistKey={persistKey}
        persistPagination
        bulkActions={bulkActions}
        refetch={refetch}
        loading={loading}
        tableProps={tableProps}
      />
      <DebugPre content={debugContent} />
    </>
  );
}
