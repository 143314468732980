import { Button, Col, Image, Row } from "antd";
import axios from "axios";
import ActionIconRender from "components/ActionIconRender";
import cx from "cx";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import { useRef, useState } from "react";
import { S3_MULTI_FILE, S3_MULTI_FILE_UPLOAD } from "shared/gql/gqlSchema/mediaGql";
import { CREATIVE_TYPE_AUDIO, CREATIVE_TYPE_THUMBNAIL } from "shared/SharedKeys/launchKeys";
import { CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import { openNotification } from "utils";
import { PlusOutlined } from "@ant-design/icons";
import LookButton from "components/LookButton";
function MediaItem(props) {
  const { r2Url = "", selected = false, onClick = () => {} } = props;
  return (
    <div
      className={cx("creative-image-selection ant-image-ration-1-1", { selected })}
      onClick={onClick}
    >
      <Image className="" src={r2Url} preview={false} />
      {selected && (
        <div className="position-absolute-center z-index-2 overlay">
          <ActionIconRender iconType="check" />
        </div>
      )}
    </div>
  );
}
export default function useUploadFiles(props = {}) {
  const {
    // image, setImage = () => {},
    // videoCheck = false,
    name = "",
    creativeType,
    serviceType = "CREATIVE",
    disabled = false,
    getImageResponse = () => {},
    creativesImage_refetch,
    creatiVideo_refetch,
    isAvatar,
    loading = false,
    setCreativesImages = () => {},
    openTagModal = () => {},
    setFile = () => {},
    setIsUpload = () => {},
    setHandleFileUpload = () => {},
    withTag = false,
    setFileName = () => {},
    isCreativeTracking = false,
    isMediaLibraryMain = false,
    isCreativeBuilderV2 = false,
    className = "",
    isCommonLoading = true,
    isForBgMusic = false,
    isForThumbnail = false,
  } = props;
  const video = creativeType === CREATIVE_TYPE_VIDEO;
  const audio = creativeType === CREATIVE_TYPE_AUDIO;
  const [image, setImage] = useState("");
  const [uploadingFileLoader, setUploadFileLoader] = useState(false);
  const [linkObj, setLinkObj] = useState();
  const [creativeLinks, setCreativeLinks] = useState();
  const [
    s3MultiFile,
    {
      data: data_S3_MULTI_FILE = [],
      loading: loading_S3_MULTI_FILE,
      error: error_S3_MULTI_FILE,
      refetch: refetch__S3_MULTI_FILE,
    },
  ] = useCustomLazyQuery(S3_MULTI_FILE, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      setImage("");
      if (video) {
        getImageResponse(linkObj);
      } else if (isMediaLibraryMain) {
        getImageResponse(linkObj);
      } else {
        getImageResponse(e?.s3MultiFile);
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const [s3MultiFileUpload, { loading: loading_S3_MULTI_FILE_UPLOAD }] = useCustomMutation(
    S3_MULTI_FILE_UPLOAD,
    {
      onCompleted(e) {
        setUploadFileLoader(true);
      },
      onError(e) {
        openNotification({ type: "error", message: e.message });
      },
    }
  );
  const s3FinalUploadedData = (uploadPaths) => {
    return new Promise((resolve, reject) => {
      s3MultiFile({ variables: { path: uploadPaths } })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // const [onProgress, setProgress] = useState();
  async function fileUploading(fileData, selectedFile) {
    const requestPromise = [];
    const uploadPaths = [];
    // upload video, images and thumbnail files to their corresponding s3Link
    fileData?.forEach(({ url, path }, index) => {
      uploadPaths.push(path);
      var config = {
        method: "put",
        url: url,
        headers: { "Content-Type": "" },
        data: selectedFile[index],
        // onUploadProgress: (progressEvent) => {
        //   const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        //   setProgress(progress);
        // },
      };
      requestPromise.push(
        axios(config)
          .then(function (response) {})
          .catch(function (error) {
            openNotification({ type: "error", message: error.message });
          })
      );
    });
    return await Promise.all(requestPromise)
      .then(async (e) => {
        setUploadFileLoader(false);
        const finalData = await s3FinalUploadedData(uploadPaths);
        setCreativeLinks(finalData);
        return finalData;
      })
      .catch((e) => {
        setUploadFileLoader(false);
      });
  }
  // returns s3Links on the basis of file name, category and type
  const s3FileUploadStage2 = (fixedNames, fileNames, thumbType = null) => {
    return new Promise((resolve, reject) => {
      s3MultiFileUpload({
        variables: {
          file_name: fixedNames || fileNames, // if no fixedName then use fileNames
          service_name: isAvatar ? "AVATAR" : serviceType,
          category: name,
          fileType: thumbType || creativeType, // if thumbType is not given then use creativeType
        },
      })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  /**
   * upload images, videos, thumbnail images in three stages -
   * get s3Liks
   * upload files corresponding to r2Urls using axios
   * get uploaded links
   * add creative to the corresponding type
   */
  async function uploadFile_Stage1(selectedFileObject, fixedNames = null, thumbnailBlobs) {
    try {
      let links;
      let fileData;
      let thumbnailNames; // thumbnail names
      const linksObj = [];
      // Get file names for images/videos
      const fileNames = fixedNames
        ? fixedNames
        : Object.values(selectedFileObject).map((value) => value?.name);
      setFileName(fileNames);
      if (video) {
        const timestamp = Date.now(); // Get the current timestamp
        const thumnailName = `thumbnail-${timestamp}`; // Unique thumbnail name with timestamp
        thumbnailNames = fileNames.map((e, i) => `${thumnailName}-${i}.png`); // get file name for thumbnail
      }
      // get s3Links
      const [fileUrlData, s3ThumbnailLinks] = await Promise.all([
        s3FileUploadStage2(fixedNames, fileNames), // get s3Links for video/images
        video ? s3FileUploadStage2(fixedNames, thumbnailNames, CREATIVE_TYPE_THUMBNAIL) : null, // get s3Links for thumbnail
      ]);
      if (fileUrlData && video) {
        fileUrlData?.data?.s3MultiFileUpload.forEach((e, i) => {
          const file_name = removeTextAfterLastDot(e?.file_name);
          const obj = { videoUrl: e?.public_url, file_name: file_name };
          if (s3ThumbnailLinks && s3ThumbnailLinks.data?.s3MultiFileUpload[i]) {
            obj.thumbUrl = s3ThumbnailLinks.data.s3MultiFileUpload[i].public_url;
          }
          //HERE WILL ADD TAGS
          obj.tags = [];
          linksObj.push(obj);
        });
        // combine s3Links for both video and thumbnail
        links = [
          ...fileUrlData?.data?.s3MultiFileUpload,
          ...s3ThumbnailLinks?.data?.s3MultiFileUpload,
        ];
        // combine file and blob data of vido and thumbnail
        fileData = [...selectedFileObject, ...thumbnailBlobs];
        setLinkObj(linksObj); // arrya of object which contains vidoUrl and thumbUrl objects
      } else {
        //IT'S USED FOR GETTING FILE NAME IT WILL UPDATE AFTER CHANGING THIS API
        if (isMediaLibraryMain) {
          links = [...fileUrlData?.data?.s3MultiFileUpload];
          fileData = [...selectedFileObject];
          setLinkObj(fileUrlData?.data?.s3MultiFileUpload);
        } else {
          links = [...fileUrlData?.data?.s3MultiFileUpload];
          fileData = [...selectedFileObject];
        }
      }
      const fileUploaded = await fileUploading(links, fileData);
      return fileUploaded?.data?.s3MultiFile;
    } catch (e) {
      console.log({ e }); //
    }
  }
  async function handleBlobUpload(blob, name) {
    const data = await uploadFile_Stage1(blob, name);
    return data;
  }
  const canvasRef = useRef();
  const videoRef = useRef();
  const setupVideoElement = (src) => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.src = src;
      videoElement.muted = true;
      videoElement.oncanplaythrough = () => resolve(videoElement);
      videoElement.onerror = (error) => reject(error);
      videoElement.play();
    });
  };
  const captureCanvasFrame = (videoElement, canvasElement) => {
    return new Promise((resolve, reject) => {
      const canvasContext = canvasElement.getContext("2d");
      canvasContext.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
      canvasElement.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          openNotification({
            type: "error",
            message: "Something is wrong, Please add video again",
          });
          reject();
        }
      });
    });
  };
  const generateThumbnail = async (file) => {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const videoElement = await setupVideoElement(event.target.result);
          const canvasElement = canvasRef.current;
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;
          const capturedBlob = await captureCanvasFrame(videoElement, canvasElement);
          // Clean up video element
          videoElement.pause();
          videoElement.src = "";
          videoElement.load();
          const thumbnailUrl = URL.createObjectURL(capturedBlob);
          resolve({ blob: capturedBlob, thumbnailUrl });
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  function getImageUrl(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const blob = new Blob([event.target.result], { type: file.type });
        const thumnbUrl = URL.createObjectURL(blob);
        resolve(thumnbUrl);
      };
      reader.readAsArrayBuffer(file);
    });
  }
  const generateThumbnails = async (files) => {
    const thumbnailPromises = Object.values(files).map(async (file) => {
      const thumbnail = await generateThumbnail(file);
      return {
        thumbnailBlob: thumbnail?.blob,
        thumbnailObjectUrl: {
          thumbUrl: thumbnail?.thumbnailUrl,
          r2Url: thumbnail?.thumbnailUrl,
          tags: [],
          fileName: removeTextAfterLastDot(file?.name),
        },
      };
    });
    const thumbnailsData = await Promise.all(thumbnailPromises);
    const thumbnailBlobs = thumbnailsData.map((data) => data?.thumbnailBlob);
    const thumbnailObjectUrls = thumbnailsData.map((data) => data?.thumbnailObjectUrl);
    return { thumbnailBlobs, thumbnailObjectUrls };
  };

  function removeTextAfterLastDot(filename) {
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      return filename.substring(0, lastDotIndex);
    } else {
      // No dot found in the filename, return the original filename
      return filename;
    }
  }
  const handleUpload = async (e) => {
    const files = e.target.files;
    let thumbnailObjectUrl = [];

    const fileNames = Object.values(files).map((value) => value?.name);
    setFileName(fileNames);
    openTagModal();

    if (files?.length === 0) {
      return null;
    }
    if (withTag) {
      setIsUpload(true);
      setFile((prev) => []);
    }
    if (video) {
      const { thumbnailBlobs, thumbnailObjectUrls } = await generateThumbnails(files);
      thumbnailObjectUrl = thumbnailObjectUrls;
      // setCreativesImages((prev) => [...thumbnailObjectUrl, ...prev]);
      if (withTag) {
        setHandleFileUpload((prev) => () => uploadFile_Stage1(files, null, thumbnailBlobs));
      } else {
        uploadFile_Stage1(files, null, thumbnailBlobs);
      }
    } else {
      for (const [key, file] of Object.entries(files)) {
        const thumbnail = await getImageUrl(file);
        thumbnailObjectUrl.push({
          r2Url: thumbnail,
          tags: [],
          fileName: removeTextAfterLastDot(file?.name),
        });
      }
      if (withTag) {
        setHandleFileUpload((prev) => () => uploadFile_Stage1(files));
      } else {
        uploadFile_Stage1(files);
      }
      // setCreativesImages((prev) => [...thumbnailObjectUrl, ...prev]);
    }
    setFile((prev) => thumbnailObjectUrl);
  };
  const commonLoading =
    loading || loading_S3_MULTI_FILE_UPLOAD || uploadingFileLoader || loading_S3_MULTI_FILE;
  /*
   * loading_S3_MULTI_FILE_UPLOAD for Genrating upload url
   * uploadingFileLoader for Uploading File
   * loading_S3_MULTI_FILE for Genrating final url
   */
  const imageInputProps = { accept: "image/png, image/gif, image/jpeg, image/jpg" };
  const videoInputProps = { accept: "video/mp4,video/x-m4v,video/*" };
  const audioInputProps = { accept: "audio/mpeg" };
  const inputProps = video ? videoInputProps : audio ? audioInputProps : imageInputProps;
  function uploadView() {
    if (isCreativeBuilderV2) {
      return (
        <>
          {isForBgMusic && (
            <label disabled={commonLoading || disabled} className="isCreativeBuilderV2-upload-btn">
              <ActionIconRender
                spin={commonLoading && isCommonLoading}
                iconType={commonLoading && isCommonLoading ? "reload" : "upload"}
              />

              <span>Upload background music</span>
              <input
                type="file"
                disabled={commonLoading || disabled}
                multiple
                required
                onChange={(event) => {
                  handleUpload(event);
                }}
                {...inputProps}
              />
            </label>
          )}
          {isForThumbnail && (
            <label disabled={commonLoading || disabled} className="isCreativeBuilderV2-upload-btn">
              <ActionIconRender
                spin={commonLoading && isCommonLoading}
                iconType={commonLoading && isCommonLoading ? "reload" : "upload"}
              />

              <span>Upload Thumbnail</span>
              <input
                type="file"
                disabled={commonLoading || disabled}
                multiple
                required
                onChange={(event) => {
                  handleUpload(event);
                }}
                {...inputProps}
              />
            </label>
          )}
        </>
      );
    }
    return (
      <>
        <div style={{ height: "100%" }}>
          {/* {commonLoading && (
            <div className="progress">
              <div className="indeterminate"></div>
            </div>
          )} */}
          {image ? (
            <Row gutter={[12, 12]}>
              {video ? (
                <>
                  {image.map((data, index) => {
                    return (
                      <Col key={"mediaItems" + index} xs={12} md={8} xl={4}>
                        <video className="w-100" src={data?.public_url} />
                      </Col>
                    );
                  })}
                </>
              ) : (
                <>
                  {image.map((data, index) => {
                    return (
                      <Col key={"mediaItems" + index} xs={12} md={8} xl={4}>
                        <MediaItem {...data} r2Url={data?.public_url} />
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          ) : (
            <label
              className={cx("cms-image-selection", className, {
                "cursor-no-drop": commonLoading || disabled,
                "creatives-image": !video,
                "creatives-video": video,
              })}
            >
              {/* <ActionIconRender iconType="image" /> */}
              {isCreativeTracking ? (
                <div
                  className="h-100 d-flex"
                  style={{
                    border: "2px dashed #404040",
                    padding: "20.5px 42.5px 18.917px 42.5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <ActionIconRender style={{ fontSize: "50px" }} iconType="uploadCreative" />
                    </div>
                    <div className="mt-10" style={{ textAlign: "center", fontSize: "20px" }}>
                      {creativeType === CREATIVE_TYPE_AUDIO
                        ? "Upload Audio"
                        : creativeType === CREATIVE_TYPE_VIDEO
                        ? "Upload Video"
                        : "Upload Image"}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="h-100 d-flex"
                  style={{
                    border: isMediaLibraryMain ? " " : "1px solid #595959",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "6px",
                  }}
                >
                  <div
                    className="d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "50%",
                      width: "60%",
                      borderRadius: "5px",
                      backgroundColor: isMediaLibraryMain ? " " : "#303030",
                      border: isMediaLibraryMain ? " " : "1px solid #404040",
                      borderStyle: isMediaLibraryMain ? " " : "dashed",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <PlusOutlined style={{ fontSize: "16px" }} />
                    </div>
                    <div className="mt-10">Upload</div>
                  </div>
                </div>
              )}
              <input
                type="file"
                disabled={commonLoading || disabled}
                multiple
                required
                // onChange={fileSelect}
                onChange={(event) => {
                  handleUpload(event);
                }}
                {...inputProps}
              />
              <video ref={videoRef} style={{ width: "0px", height: "0px" }} />
              <canvas ref={canvasRef} style={{ width: "0px", height: "0px" }} />
            </label>
          )}
          {image && (
            <>
              <Button className="px-0" type="link" onClick={() => setImage("")}>
                Remove this image
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
  return {
    uploadView,
    handleUpload,
    handleBlobUpload,
    uploadLoading: commonLoading,
    uploadFile_Stage1,
    creativeLinks,
  };
}
