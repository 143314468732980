export function numCompactShort(num, digits = 0) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value)?.toFixed(digits)?.replace(rx, "$1") + si[i]?.symbol;
}

export function numCommaSeparator(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//in this function we just add new varible named as absNum for absolute value and in last we justify the result acc to num
export function negetiveNumCompactShort(num, digits = 0) {
  var absNum = Math.abs(num);

  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;

  for (i = si.length - 1; i > 0; i--) {
    if (absNum >= si[i].value) {
      break;
    }
  }

  var result = (absNum / si[i].value)?.toFixed(digits)?.replace(rx, "$1") + si[i]?.symbol;
  return num < 0 ? `-${result}` : result;
}
