import { Button, Form } from "antd";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IMAGE_PROGRESS } from "../../../shared/gql/gqlSchema/mediaGql";
import { openNotification } from "../../../utils";
import SelectInput from "../../forms/SelectInput";

export default function ResizeCall(props) {
  const { image = "", imagesSizesRefetch = () => {} } = props;
  const { cmsImageSizes = [], cmsImageSizes_loading = false } =
    useSelector((state) => state?.userSettings?.userSettingData) || {};

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const allImageSizes = cmsImageSizes?.map((size) => size?.sizes);
    setSelected(allImageSizes);
  }, [cmsImageSizes]);

  const [imageProcess, { loading: loading_IMAGE_PROGRESS }] = useCustomMutation(IMAGE_PROGRESS, {
    onCompleted(e) {
      openNotification({
        type: "success",
        message: e?.imageProcess?.message,
      });
      setSelected([]);
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onResizeSubmit() {
    imageProcess({ variables: { image_url: image, sizes: selected } });
  }

  return (
    <div>
      {loading_IMAGE_PROGRESS && (
        <div class="progress">
          <div class="indeterminate"></div>
        </div>
      )}
      <div className="d-flex">
        <div className="flex-grow-1 pr-8">
          <Form.Item className="look-form-input mb-0">
            <SelectInput
              refetch={imagesSizesRefetch}
              loading={cmsImageSizes_loading}
              loader={cmsImageSizes?.length === 0 && cmsImageSizes_loading}
              className="mb-0"
              search
              multiple
              name="domains"
              placeholder="Resize Image"
              onChange={setSelected}
              value={selected}
              options={cmsImageSizes?.map(({ id, sizes }) => ({ name: sizes, value: sizes }))}
            />
          </Form.Item>
        </div>
        <div className="pt-4">
          <Button
            type="primary"
            size="small"
            disabled={loading_IMAGE_PROGRESS || selected?.length === 0}
            onClick={onResizeSubmit}
          >
            Resize
          </Button>
        </div>
      </div>
    </div>
  );
}
