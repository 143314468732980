import { Tag, Tooltip } from "antd";
import ActionIconRender from "components/ActionIconRender";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import cx from "cx";
import {
  SAS_FB_REPORT_QUERY_DAILY,
  SAS_FB_REPORT_QUERY_LIVE,
  SAS_FB_REPORT_QUERY_POSTBACK,
} from "hooks/sasHooks/useSasReportsQuery";
import moment from "moment";
import { dateFormat } from "reducers/userSettings";
import {
  REPORT_TYPE_AD,
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_CAMPAIGN,
} from "shared/SharedKeys";
const valueCheck = (value, checkTotal = false) => {
  const valueCheck = value ? (value == "NaN" || value == "Infinity" ? "0" : value) : "0";
  const valueFloat = parseFloat(valueCheck);
  if (checkTotal) return valueFloat.toFixed(2);
  return valueFloat;
};
export default function useTiktokColums(props) {
  const { callDetailView, reportType = "", queryType = "", onCloseDelete = () => {} } = props || {};

  let nameTitle = "";
  let nameObject = "";
  let idTitle = "";
  let idObject = "";

  if (REPORT_TYPE_AD_ACCOUNT === reportType) {
    nameTitle = "Ad Account";
    nameObject = "name";
    idTitle = "Ad Account Id";
    idObject = "adAccountId";
  }
  if (REPORT_TYPE_AD === reportType) {
    nameTitle = "Ad";
    nameObject = "ad_name";
    idTitle = "Ad Id";
    idObject = "adId";
  }
  if (REPORT_TYPE_AD_ADSET === reportType) {
    nameTitle = "Adset";
    nameObject = "adgroup_name";
    idTitle = "Ad Group Id";
    idObject = "adsetId";
  }
  if (REPORT_TYPE_CAMPAIGN === reportType) {
    nameTitle = "Campaign";
    nameObject = "campaign_name";
    idTitle = "campaignId";
    idObject = "campaignId";
  }

  const isPredictedRevenue =
    queryType === SAS_FB_REPORT_QUERY_POSTBACK
      ? [
          {
            title: "P Revenue",
            dataIndex: "predicted_revenue",
            key: "predicted_revenue",
            render: (value) => <LookTableColumnRender title={`$${valueCheck(value, true)}`} />,
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.predicted_revenue - b?.predicted_revenue,
            searchType: "number",
          },
        ]
      : [];

  const isPredictedRPL =
    queryType === SAS_FB_REPORT_QUERY_POSTBACK
      ? [
          {
            title: "P RPL",
            dataIndex: "predicted_RPL",
            key: "predicted_RPL",
            render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.predicted_RPL - b?.predicted_RPL,
            searchType: "number",
          },
        ]
      : [];

  const isPredictedColumns =
    queryType === SAS_FB_REPORT_QUERY_POSTBACK
      ? [
          {
            title: "Disc",
            dataIndex: "discrepancy",
            key: "discrepancy",
            render: (value) => (
              <LookTableColumnRender title={valueCheck(value * 100, true) + "%"} /> //* discrepancy will always be multiplied by 100 in column view
            ),
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.discrepancy - b?.discrepancy,
            searchType: "number",
          },
          {
            title: "P Margin",
            dataIndex: "predicted_margin",
            key: "predicted_margin",
            render: (value = 0, otherProps) => {
              const predicted_margin = `$${value.toFixed(2)}`;
              return (
                <LookTableColumnRender
                  title={
                    <strong
                      className={cx({
                        "text-primary": parseFloat(value) > 0,
                        "text-danger": parseFloat(value) < 0,
                      })}
                    >
                      {predicted_margin.replace("-", "")}
                    </strong>
                  }
                />
              );
            },
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.predicted_margin - b?.predicted_margin,
            searchType: "number",
          },

          {
            title: "P ROAS",
            dataIndex: "predicted_ROAS",
            key: "predicted_ROAS",
            render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.predicted_ROAS - b?.predicted_ROAS,
            searchType: "number",
          },

          {
            title: "Benchmark RPL",
            dataIndex: "benchMarkRpl",
            key: "benchMarkRpl",
            render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.benchMarkRpl - b?.benchMarkRpl,
            searchType: "number",
          },
          {
            title: "Trending RPL",
            dataIndex: "trendRpl",
            key: "trendRpl",
            render: (value, actionProps) => (
              <LookTableColumnRender
                className={value > actionProps?.benchMarkRpl ? "text-primary" : "text-danger"}
                title={valueCheck(value, true)}
              />
            ),
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.trendRpl - b?.trendRpl,
            searchType: "number",
          },
          {
            title: "Benchmark LPCTR",
            dataIndex: "benchMarkLpctr",
            key: "benchMarkLpctr",
            render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.benchMarkLpctr - b?.benchMarkLpctr,
            searchType: "number",
          },
          {
            title: "Benchmark CPC",
            dataIndex: "benchMark_CPC",
            key: "benchMark_CPC",
            render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
            width: 90,
            minWidth: 90,
            sorter: (a, b) => a?.benchMark_CPC - b?.benchMark_CPC,
            searchType: "number",
          },
        ]
      : [];

  const isBudgetTags =
    (reportType === REPORT_TYPE_CAMPAIGN || reportType === REPORT_TYPE_AD_ADSET) &&
    queryType === SAS_FB_REPORT_QUERY_POSTBACK
      ? [
          {
            title: "Budget tag",
            dataIndex: "budgetTag",
            key: "budgetTag",
            render: (value) => {
              return (
                <LookTableColumnRender
                  tooltip={
                    value?.last_edit &&
                    moment(value?.last_edit).format(dateFormat + " hh:mm") + " UTC"
                  }
                  title={
                    value?.tags?.length > 0
                      ? value?.tags?.map((d) => <Tag color="green">{d}</Tag>)
                      : undefined
                  }
                />
              );
            },
            width: 124,
            minWidth: 124,
            sorter: (a, b) => {
              var oneYearFromNow = new Date();
              oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);
              const distantFuture = oneYearFromNow;
              let dateA = a?.budgetTag?.last_edit
                ? new Date(a?.budgetTag?.last_edit)
                : distantFuture;
              let dateB = b?.budgetTag?.last_edit
                ? new Date(b?.budgetTag?.last_edit)
                : distantFuture;
              return dateB - dateA;
            },
            searchType: "number",
          },
        ]
      : [];

  const isUserTags =
    (reportType === REPORT_TYPE_CAMPAIGN || reportType === REPORT_TYPE_AD_ADSET) &&
    queryType === SAS_FB_REPORT_QUERY_POSTBACK
      ? [
          {
            title: "tags",
            dataIndex: "tags",
            key: "tags",
            render: (value, rowProps) => {
              return (
                <LookTableColumnRender
                  title={
                    value?.tags?.length > 0
                      ? value?.tags?.map((d) => {
                          return (
                            <Tag
                              color="green"
                              key={d}
                              onClose={() => {
                                onCloseDelete(d, value);
                              }}
                              closable={true}
                            >
                              {d}
                            </Tag>
                          );
                        })
                      : undefined
                  }
                />
              );
            },
            width: 250,
            minWidth: 250,
          },
        ]
      : [];

  const tiktokCampignColumns = [
    {
      title: cx({ [nameTitle]: nameTitle }, "Name"),
      dataIndex: nameObject,
      key: nameObject,
      render: (value, otherProps) => {
        return reportType !== REPORT_TYPE_CAMPAIGN ? (
          <div onClick={callDetailView ? () => callDetailView(otherProps) : undefined}>
            {otherProps?.secondary_status ===
            (reportType === REPORT_TYPE_AD_ADSET
              ? "ADGROUP_STATUS_AUDIT_DENY"
              : "AD_STATUS_AUDIT_DENY") ? (
              <div className="d-flex align-items-center">
                <Tooltip title={otherProps?.secondary_status}>
                  <ActionIconRender className="mr-6" style={{ color: "red" }} iconType="warning" />
                </Tooltip>
                <span
                  className={cx("LookTableColumnRender", {
                    "text-center": value === null || value === "",
                  })}
                >
                  {value}
                </span>
              </div>
            ) : (
              <span
                className={cx("LookTableColumnRender", {
                  "text-center": value === null || value === "",
                })}
              >
                {value}
              </span>
            )}
          </div>
        ) : (
          <LookTableColumnRender
            title={value}
            isClick={callDetailView ? () => callDetailView(otherProps) : undefined}
          />
        );
      },
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: idTitle,
      dataIndex: idObject,
      key: idObject,
      render: (value) => <LookTableColumnRender title={value} />,
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    {
      title: "Category",
      dataIndex: "categoryName",
      key: "categoryName",
      render: (value) => <LookTableColumnRender title={value} />,
      hidden: true,
      width: 190,
      minWidth: 190,
      searchType: "string",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 190,
      minWidth: 190,
      searchType: "string",
    },

    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (value) => <LookTableColumnRender title={value?.iso_code} />,
      hidden: true,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.country - b?.country,
      searchType: "string",
    },

    {
      title: "Device",
      dataIndex: "device",
      key: "device",
      render: (value) => <LookTableColumnRender title={value} />,
      hidden: true,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.device - b?.device,
      searchType: "string",
    },

    {
      title: "E Revenue",
      dataIndex: "estimate_revenue",
      key: "estimate_revenue",
      render: (value) => <LookTableColumnRender title={`$${valueCheck(value, true)}`} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.estimate_revenue - b?.estimate_revenue,
      searchType: "number",
    },

    ...isPredictedRevenue,

    {
      title: "Spend",
      dataIndex: "spend",
      key: "spend",
      render: (value) => <LookTableColumnRender title={`$${valueCheck(value, true)}`} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.spend - b?.spend,
      searchType: "number",
    },
    {
      title: "G Margin",
      dataIndex: "gross_margin",
      key: "gross_margin",
      render: (value = 0, otherProps) => {
        // const gross_margin = `$${value.replace("-", "")}`;
        const gross_margin = `$${value.toFixed(2)}`;
        // const tooltip = `$${value}`;
        return (
          <LookTableColumnRender
            // tooltip={tooltip}
            title={
              <strong
                className={cx({
                  "text-yellow": parseFloat(value) > 0,
                  "text-danger": parseFloat(value) < 0,
                })}
              >
                {gross_margin.replace("-", "")}
              </strong>
            }
          />
        );
      },
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.gross_margin - b?.gross_margin,
      searchType: "number",
    },

    {
      title: "ROAS",
      dataIndex: "ROAS",
      key: "ROAS",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true) + "%"} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.ROAS - b?.ROAS,
      searchType: "number",
    },

    ...isUserTags,

    ...isPredictedColumns,

    {
      title: "clicks (T)",
      dataIndex: "clicksTiktok",
      key: "clicksTiktok",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicksTiktok - b?.clicksTiktok,
      searchType: "number",
    },
    {
      title: "Clicks (V)",
      dataIndex: "clicks",
      key: "clicks",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.clicks - b?.clicks,
      searchType: "number",
    },
    {
      title: "Conversions (T)",
      dataIndex: "conversionTiktok",
      key: "conversionTiktok",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 140,
      minWidth: 140,
      sorter: (a, b) => a?.conversionTiktok - b?.conversionTiktok,
      searchType: "number",
    },
    {
      title: "Searches",
      dataIndex: "searches",
      key: "searches",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.searches - b?.searches,
      searchType: "number",
    },

    {
      title: "Reach",
      dataIndex: "reach",
      key: "reach",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.reach - b?.reach,
      searchType: "number",
    },

    {
      title: "CPR (V)",
      dataIndex: "CPR_V",
      key: "CPR_V",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.CPR_V - b?.CPR_V,
      searchType: "number",
    },

    {
      title: "CPV",
      dataIndex: "CPV",
      key: "CPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPV - b?.CPV,
      searchType: "number",
    },
    {
      title: "RPV",
      dataIndex: "RPV",
      key: "RPV",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPV - b?.RPV,
      searchType: "number",
    },
    ...isPredictedRPL,
    {
      title: "RPL",
      dataIndex: "RPL",
      key: "RPL",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.RPL - b?.RPL,
      searchType: "number",
    },

    {
      title: "AD CTR",
      dataIndex: "AD_CTR",
      key: "AD_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.AD_CTR - b?.AD_CTR,
      searchType: "number",
    },
    {
      title: "LP CTR",
      dataIndex: "LP_CTR",
      key: "LP_CTR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.LP_CTR - b?.LP_CTR,
      searchType: "number",
    },

    {
      title: "CR",
      dataIndex: "CR",
      key: "CR",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CR - b?.CR,
      searchType: "number",
    },
    {
      title: "Impressions",
      dataIndex: "impressionsTiktok",
      key: "impressionsTiktok",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a?.impressionsTiktok - b?.impressionsTiktok,
      searchType: "number",
    },

    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={valueCheck(value, true)} />,
      width: 70,
      minWidth: 70,
      sorter: (a, b) => a?.CPM - b?.CPM,
      searchType: "number",
    },

    {
      title: "V Visits",
      dataIndex: "sessions",
      key: "sessions",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 90,
      minWidth: 90,
      sorter: (a, b) => a?.sessions - b?.sessions,
      searchType: "number",
    },
    {
      title: "Life Time Budget",
      dataIndex: "lifeTimeBudget",
      key: "lifeTimeBudget",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 140,
      minWidth: 140,
      sorter: (a, b) => a?.lifeTimeBudget - b?.lifeTimeBudget,
      searchType: "number",
    },
    ...isBudgetTags,
  ];

  return { tiktokCampignColumns };
}
