import { Popover, Switch } from "antd";
import LookButton from "components/LookButton";
import cx from "cx";
import React, { useRef, useState } from "react";
import ChatGPTpopup from ".";
import { useLocation } from "react-router-dom";

function ChatGpt(props) {
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const handleChat = (e) => {
    setChecked(e);
  };

  const childRef = useRef(null);

  const handleNewChat = () => {
    childRef.current.myFunction();
  };
  function content() {
    return (
      <>
        <div className={cx({ "d-none": checked })}>
          <ChatGPTpopup ref={childRef} />
        </div>
        <div className={cx({ "d-none": !checked })}>
          <ChatGPTpopup isContinueChat={true} ref={childRef} />
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        <Popover
          content={content}
          title={
            <div
              className="d-flex"
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <div>LookfinityGPT</div>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <p className="mr-8 mb-0"> Let's continue </p>
                <Switch
                  className="mr-8"
                  style={{ backgroundColor: "#141414" }}
                  checked={checked}
                  onChange={handleChat}
                />
                <LookButton
                  iconType="plus"
                  tooltipTitle="New Chat"
                  type="primary"
                  size="small"
                  onClick={handleNewChat}
                />
              </div>
            </div>
          }
          trigger="click"
          placement={location?.pathname?.includes("launch") ? "leftBottom" : "topRight"}

          // onVisibleChange={() => setQuery([])}
        >
          <span
            style={{
              display: "block",
              textDecoration: "none",
              position: "fixed",
              bottom: 11,
              right: 10,
              zIndex: 999,
            }}
          >
            <LookButton
              tooltipTitle="LookfinityGPT"
              tooltipPlacement="left"
              iconType="message"
              type="primary"
            />
          </span>
        </Popover>
      </div>
    </>
  );
}

export default ChatGpt;
