import RuleFields from "../RuleHandler/index";
import SelectInput from "components/forms/SelectInput";
import { useCustomLazyQuery, useCustomMutation } from "hooks/apolloClientHooks";
import {
  GQL_RE_ADDUSERPLATFORMRULE,
  GQL_RE_UPDATERULE,
  GQL_SAS_CONTRIES,
} from "shared/gql/gqlSchema/ruleGql";
import { openNotification } from "utils";
import LookTextInput from "components/forms/LookTextInput";
import { useSelector } from "react-redux";
import { Button, Col, Form, Radio, Row, Space, Switch, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ActionIconRender from "components/ActionIconRender";
import moment from "moment";
import parser from "cron-parser";
import {
  PLATFORM_ID_FACEBOOK,
  PLATFORM_ID_TIKTOK,
  RULE_PLATFORM_FB,
  RULE_PLATFORM_TIKTOK,
  RULE_PLATFORM_VAROZI,
} from "shared/SharedKeys";

export default function PulseRuleForm(props) {
  const { close = () => {}, productId, userId, refetch, ids, platform, copy, grpby } = props;
  const [action, setAction] = useState();
  const [period, setperiod] = useState();
  const [life, setlife] = useState("manual");
  const [name, setname] = useState();
  const [timeline, settimeline] = useState();
  const [groupBy, setgroupBy] = useState(grpby ? grpby : "campaign");
  const [pltf, setpltf] = useState("fb");
  const deafult_tz = useSelector(
    (state) => state?.userSettings?.dateRangeTimezone?.timeZone?.value
  );
  const [id, setid] = useState();
  // const super_admin = useSelector((state) => state?.userReducer?.me?.lf_superadmin);
  const [timezone, settimezone] = useState(deafult_tz);
  const [reporting_days, setreporting_days] = useState();
  const [manual, setmanual] = useState();
  const [message, setmessage] = useState();
  const [budget_type, setbudget_type] = useState();
  const [nextoccurrences, setnextOccurrences] = useState([]);
  const [country, setCountry] = useState([]);
  const [selected_country, setselected_country] = useState();
  //   const [category, setCategory] = useState([]);
  //   const [selected_category, setselected_category] = useState();

  const cronOption = [
    { name: "5 minutes", value: "*/5 * * * *" },
    { name: "15 minutes", value: "*/15 * * * *" },
    { name: "30 minutes", value: "*/30 * * * *" },
    { name: "1 hour", value: "0 * * * *" },
    { name: "2 hours", value: "0 */2 * * *" },
    { name: "4 hours", value: "0 */4 * * *" },
    { name: "8 hours", value: "0 */8 * * *" },
    { name: "Custom", value: "Custom" },
  ];
  useEffect(() => {
    if (copy && copy.rules) {
      setname(copy.name);
      setAction(copy.actions);
      for (let i of cronOption) {
        if (toString(copy.period) === toString(i.value)) {
          setperiod(copy.period);
          break;
        } else {
          setmanual(copy.period);
          setperiod("Custom");
        }
      }
      setid(copy.id);
      // settimezone(copy.timezone);
      settimeline(copy.report_timeline);
      setmessage(copy.message);
      setbudget_type(copy.budget_type);
      setreporting_days(copy.reporting_days);
      setgroupBy(copy.groupBy);
      setlife(copy.lifespan);
      setpltf(copy.platform);
      setselected_country(copy.country);
      //   setselected_category(copy.category);
    }
    clgetCountry();
    // clgetCategory();
  }, []);

  const [claddRule] = useCustomMutation(GQL_RE_ADDUSERPLATFORMRULE, {
    variables: {
      platform:
        platform.length > 1
          ? pltf
          : platform[0]?.id === PLATFORM_ID_FACEBOOK
          ? RULE_PLATFORM_FB
          : platform[0]?.id === PLATFORM_ID_TIKTOK
          ? RULE_PLATFORM_TIKTOK
          : RULE_PLATFORM_VAROZI,
      product: productId,
    },
    onCompleted: (e) => {
      const element = e?.re_addUserPlatformRule;
      if (element) {
        openNotification({ type: "success", message: "Rule Added Successfully" });
        //if there is no bulkaction then only refetch
        (!ids || ids.length < 1) && refetch();
        close();
      }
    },
    onError(e) {
      if (name == null) {
        openNotification({ type: "error", message: "Rule name is required" });
      } else {
        openNotification({ type: "error", message: e?.message });
      }
    },
  });
  const [clupdateRule] = useCustomMutation(GQL_RE_UPDATERULE, {
    variables: {
      platform: pltf,
      product: productId,
    },
    onCompleted: (e) => {
      const element = e?.re_updateRule;
      if (element) {
        openNotification({ type: "success", message: "Rule Added Successfully" });
        //if there is no bulkaction then only refetch
        (!ids || ids.length < 1) && refetch();
        close();
      }
    },
    onError(e) {
      if (name == null) {
        openNotification({ type: "error", message: "Rule name is required" });
      } else {
        openNotification({ type: "error", message: e?.message });
      }
    },
  });
  const [clgetCountry] = useCustomLazyQuery(GQL_SAS_CONTRIES, {
    variables: {},
    onCompleted: (e) => {
      const element = e?.SasCountries;
      if (element) {
        setCountry(element);
      }
    },
  });
  //   const [clgetCategory] = useCustomLazyQuery(GQL_SAS_CATEGORIES, {
  //     variables: {},
  //     onCompleted: (e) => {
  //       const element = e?.sasCategories;
  //       if (element) {
  //         setCategory(element);
  //       }
  //     },
  //   });
  function addRule() {
    claddRule({
      variables: {
        groupBy: groupBy,
        reportType: "general",
        userId: userId,
        all: [{ fact: "information", operator: "equal", value: "ACTIVE", path: "$.status" }],
        action: action,
        cronCommand: manual ? manual : period,
        name: name,
        timezone: timezone,
        report_timeline: timeline,
        message: message,
        budget_type: budget_type,
        reporting_days: reporting_days,
        is_pulse: true,
        country: selected_country,
        // category: selected_category,
      },
    });
  }
  function updateRule() {
    clupdateRule({
      variables: {
        id: id,
        groupBy: groupBy,
        reportType: "general",
        userId: userId,
        all: [{ fact: "information", operator: "equal", value: "ACTIVE", path: "$.status" }],
        action: action,
        cronCommand: manual ? manual : period,
        name: name,
        timezone: timezone,
        report_timeline: timeline,
        message: message,
        budget_type: budget_type,
        reporting_days: reporting_days,
        is_pulse: true,
        country: selected_country,
        // category: selected_category,
      },
    });
  }

  const handleManualInputChange = (e) => {
    setmanual(e.target.value);
  };
  function cronDetails() {
    if (nextoccurrences.length > 8) {
      setnextOccurrences([]);
    }
    const newOccurrences = [];
    const interval = manual
      ? parser.parseExpression(manual, {
          tz: "utc",
          currentDate: new Date(),
        })
      : "* * * * *";
    for (let i = 0; i < 10; i++) {
      const occurrence = interval.next().toDate();
      const dat = moment(occurrence).format("YYYY-MM-DD, HH:mm:ss");
      newOccurrences.push(dat);
    }
    setnextOccurrences((prevOccurrences) => [...prevOccurrences, ...newOccurrences]);
  }

  const currentDate = moment()
    .tz(timezone ? timezone : "utc")
    .format("YYYY-MM-DD");
  const date1 = moment()
    .tz(timezone ? timezone : "utc")
    .subtract(1, "days")
    .format("YYYY-MM-DD");
  const date2 = moment()
    .tz(timezone ? timezone : "utc")
    .subtract(2, "days")
    .format("YYYY-MM-DD");
  const date3 = moment()
    .tz(timezone ? timezone : "utc")
    .subtract(reporting_days ? reporting_days : 0, "days")
    .format("YYYY-MM-DD");
  const currentDate2 = moment()
    .tz(timezone ? timezone : "utc")
    .format("YYYY-MM-DD hh:mm:ss");
  const date4 = moment()
    .tz(timezone ? timezone : "utc")
    .subtract(reporting_days ? reporting_days : 0, "hour")
    .format("YYYY-MM-DD hh:mm:ss");

  return (
    <>
      <Form layout="vertical">
        <div className="mb-10 justify-content-between">
          <h4>Name </h4>
          <div>
            <LookTextInput
              className="mb-0"
              value={name}
              onChange={(e) => setname(e.target.value)}
              placeholder="Enter Unique Rule Name"
            />
          </div>
        </div>
        {platform.length > 1 && (
          <div className="mb-10 justify-content-between">
            <h4>Select Platform </h4>
            <Radio.Group onChange={(e) => setpltf(e.target.value)} value={pltf} className="mb-20">
              <Space direction="horizontal">
                <Radio value={"fb"}>Facebook</Radio>
                <Radio value={"tiktok"}>Tiktok</Radio>
              </Space>
            </Radio.Group>
          </div>
        )}
        <div className="mb-10 justify-content-between">
          <h4>Action </h4>
          <div>
            {pltf === RULE_PLATFORM_FB || pltf === RULE_PLATFORM_VAROZI ? (
              <SelectInput
                onChange={(value) => setAction(value)}
                value={action}
                options={[
                  { name: "Notification", value: "notification" },
                  { name: "Turn On", value: "ACTIVE" },
                  { name: "Turn Off", value: "PAUSED" },
                ]}
              />
            ) : pltf === RULE_PLATFORM_TIKTOK ? (
              <SelectInput
                onChange={(value) => setAction(value)}
                value={action}
                options={[
                  { name: "Notification", value: "notification" },
                  { name: "Turn On ", value: "ENABLE" },
                  { name: "Turn Off", value: "DISABLE" },
                ]}
              />
            ) : null}
          </div>
        </div>
        {action === "notification" ? (
          <div className="mb-10 justify-content-between">
            <h4>Custom Message </h4>
            <div>
              <LookTextInput
                className="mb-0"
                value={message}
                onChange={(e) => setmessage(e.target.value)}
                placeholder="Add a new custom message"
              />
            </div>
          </div>
        ) : null}
        <div className="mb-10 justify-content-between">
          <h4>Apply On </h4>
          <Radio.Group
            onChange={(e) => setgroupBy(e.target.value)}
            value={groupBy}
            className="mb-20"
          >
            <Space direction="horizontal">
              <Radio value={"campaign"}>Campaigns</Radio>
              <Radio value={"adset"}>Adsets</Radio>
              <Radio value={"ad"}>Ads</Radio>
            </Space>
          </Radio.Group>
        </div>

        <div className="mb-10">
          <Row span={24} gutter={12}>
            <Col span={12}>
              <h4>Rule Frequency </h4>
              <div>
                <SelectInput
                  placeholder="Select rule running frequency"
                  onChange={(value) => {
                    setperiod(value);
                  }}
                  value={period}
                  options={[
                    { name: "5 minutes", value: "*/5 * * * *" },
                    { name: "15 minutes", value: "*/15 * * * *" },
                    { name: "30 minutes", value: "*/30 * * * *" },
                    { name: "1 hour", value: "0 * * * *" },
                    { name: "2 hours", value: "0 */2 * * *" },
                    { name: "4 hours", value: "0 */4 * * *" },
                    { name: "8 hours", value: "0 */8 * * *" },
                    { name: "12 hours", value: "0 */12 * * *" },
                    { name: "Custom", value: "Custom" },
                  ]}
                />
              </div>
            </Col>
            <Col span={12}>
              <h4>Lifespan of Rule </h4>
              <div>
                <SelectInput
                  placeholder="Select a lifespan of rule"
                  onChange={(value) => {
                    setlife(value);
                  }}
                  value={life}
                  options={[
                    { name: "6 hours", value: 0.25 },
                    { name: "8 hours", value: 0.33 },
                    { name: "12 hours", value: 0.5 },
                    { name: "1 Day", value: 1 },
                    { name: "2 Days", value: 2 },
                    { name: "Until manually turned off", value: "manual" },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </div>
        {period === "Custom" ? (
          <div className=" mb-10 ">
            <h4>Custom Cron </h4>
            <Row span={24} gutter={12}>
              <Col span={21}>
                <LookTextInput
                  className="mb-0"
                  value={manual}
                  onChange={handleManualInputChange}
                  placeholder="Enter your own cron command"
                />
              </Col>
              <Col span={3}>
                <Button onClick={() => cronDetails()}>
                  Details <ActionIconRender iconType="down" />
                </Button>
              </Col>
            </Row>
            <Row span={24} gutter={12}>
              {manual &&
                nextoccurrences?.map((item, index) => {
                  return (
                    <Col span={8}>
                      <p>
                        {index} : {item}
                      </p>
                    </Col>
                  );
                })}
            </Row>
          </div>
        ) : null}
        <div className="mb-10 justify-content-between">
          <h4>Reporting TimeZone</h4>
          <div>
            <SelectInput
              onChange={(value) => {
                settimezone(value);
              }}
              value={timezone}
              options={[
                { name: "UTC", value: "UTC" },
                { name: "America/Los_Angeles", value: "America/Los_Angeles" },
                { name: "CET", value: "CET" },
                { name: "PST8PDT", value: "PST8PDT" },
                { name: "Asia/Kolkata", value: "Asia/Kolkata" },
              ]}
            />
          </div>
        </div>
        <div className="mb-10 justify-content-between">
          <h4>Report Timeline</h4>
          <div>
            <SelectInput
              placeholder="select a reporting timeline"
              onChange={(value) => {
                settimeline(value);
              }}
              value={timeline}
              options={[
                { name: `Today (${currentDate})`, value: "today" },
                { name: `Yesterday (${date1})`, value: "yesterday" },
                { name: `Day Before Yesterday (${date2})`, value: "day before yesterday" },
                { name: `Last N Days (${date3} to ${currentDate})`, value: "last N days" },
                { name: `Last N Hours (${date4} to ${currentDate2})`, value: "last N hours" },
              ]}
            />
          </div>
        </div>
        {timeline === "last N days" || timeline === "last N hours" ? (
          <div className="mb-10 justify-content-between">
            {timeline === "last N days" ? <h4>Number of Days </h4> : <h4>Number of Hours </h4>}
            <div>
              <LookTextInput
                normalNumberInput
                className="mb-0"
                value={reporting_days}
                onChange={(e) => setreporting_days(e)}
                placeholder="Enter last N number of days for report"
              />
            </div>
          </div>
        ) : null}
        <div className="mb-10 justify-content-between">
          <h4>Country</h4>
          <div>
            <SelectInput
              onChange={(value) => {
                setselected_country(value);
              }}
              placeholder="Select Countries"
              value={selected_country}
              options={country}
              search={true}
              multiple={true}
            />
          </div>
        </div>

        {/* <div className="mb-10 justify-content-between">
          <h4>Category</h4>
          <div>
            <SelectInput
              onChange={(value) => {
                setselected_category(value);
              }}
              placeholder="Select Categories"
              value={selected_category}
              options={category}
              search={true}
              multiple={true}
            />
          </div>
        </div> */}
      </Form>
      <div style={{ marginTop: "10%" }} className="d-flex mb-10 justify-content-between">
        {copy && copy.name ? (
          <Button className="mr-6 w-100" key="submit" type="primary" onClick={updateRule}>
            Update Rule
          </Button>
        ) : (
          <Button
            style={{ width: "100%" }}
            className="mr-6"
            key="submit"
            type="primary"
            onClick={addRule}
          >
            Add Rule
          </Button>
        )}
      </div>
    </>
  );
}
