import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import { USERS_MANAGES_USERS } from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const Configuration = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Slack-Configuration" */ "pages/dashboardPages/Slack/Configuration")
  )
);
const Subscriptions = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "Slack-Subscriptions" */ "pages/dashboardPages/Slack/Subscriptions")
  )
);

const slackRoutes = [
  route("/slack/workspaces", Configuration, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
  route("/slack/subs", Subscriptions, ROUTE_DASHBOARD, true, USERS_MANAGES_USERS),
  // route("/slack/redirect-cahnel-auth", OAuthSlackChannel, ROUTE_DEFAULT),
];

export default slackRoutes;
