import NotificationMessageCard from "./MessageNotifyCard";

export const RenderNotificationList = ({
  allNotifications,
  createActionableLink,
  onMarkAsReadHandler,

}) => {
  return allNotifications?.length ? (
    allNotifications.map((it) => (
      <NotificationMessageCard
        key={it.id}
        {...it}
        title={it?.service_name}
        message={it?.payload?.message}
        datetime={it?.createdAt || new Date()}
        actionableLink={createActionableLink(it?.payload)}
        seenStatus={it?.seen}
        onMarkAsReadCallbackFn={onMarkAsReadHandler}
        payload={it?.payload}
      />
    ))
  ) : (
    <div className="text-center">No New Notifications</div>
  );
};
