import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import React, { useState } from "react";
import TemplateUpload from "./TemplateUpload";
import { usePermissionCheck } from "hooks";
import { CREATIVE_TRACKING_CREATIVE_DIRECTOR } from "shared/UserPermission/userPermissionList";

function CreativeBuilder() {
  const { havePermission: isCreativeDirector } = usePermissionCheck(
    CREATIVE_TRACKING_CREATIVE_DIRECTOR
  );
  const [visibleModal, setVisibleModal] = useState(false);

  const openCreateModal = () => {
    setVisibleModal(true);
  };

  const createBtnProps = {
    createBtn: isCreativeDirector ? true : false,
    createBtnText: "Upload Template",
    createBtnHandler: openCreateModal,
  };
  return (
    <div>
      <DashboardHeader title="Creative Builder" {...createBtnProps} />
      <div className="vh-100-reduce-header">
        <TemplateUpload
          isCreativeBuilder
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
        />
      </div>
    </div>
  );
}

export default CreativeBuilder;
