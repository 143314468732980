import { Button, Switch, Tooltip } from "antd";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";

import LookTable from "components/LookTable";

import { useEffect, useState } from "react";

import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";

import ActionIconRender from "components/ActionIconRender";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import moment from "moment";
import { use } from "react-dom-factories";

export default function RuleLinkedItems(props) {
  const { ids } = props;
  const [data, setdata] = useState([]);
  useEffect(() => {
    if (ids) {
      setdata(ids);
    } else {
      setdata([]);
    }
  }, [ids]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const handleStatus = (e, otherProps) => {
    // setChecked(e);
  };

  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalVisible(true);
  };

  const onSubmitDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const onCloseDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const initialColumns = [
    {
      title: "Asset name",
      dataIndex: "name",
      key: "name",
      searchable: true,
      //   render: (value, otherProps) => (
      //     <LookTableColumnRender
      //       title={value}
      //       isClick={() => {
      //         updateFilterSearch(otherProps);
      //       }}
      //     />
      //   ),
    },
    {
      title: "Asset ID",
      dataIndex: "id",
      key: "id",
      //   width: 160,
      //   minWidth: 160,
      // render: (value, otherProps) => <LookTableColumnRender title={otherProps?.action} />,
    },

    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   nofilter: true,
    //   render: (value, otherProps) => {
    //     return (
    //       <>
    //         <Tooltip title="Delete">
    //           <Button
    //             className="h-auto"
    //             type="link"
    //             icon={<ActionIconRender iconType="delete" />}
    //             onClick={
    //               () => onOpenDeleteModal(otherProps)
    //               // deleteRule(otherProps.name)
    //             }
    //           />
    //         </Tooltip>
    //       </>
    //     );
    //   },
    //   fixed: "right",
    //   width: 120,
    // },
  ];

  const tableProps = { initialColumns, recordData: data };
  const deleteModalProps = {
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    // loader: loading_DeleteGroups,
  };

  return (
    <>
      <DashboardHeader title="Assets" />
      <LookTable
        tableProps={tableProps}
        //   loading={loading_clgetRule} refetch={clruleFetch}
      />
      <LookTableRecordDeleteModal {...deleteModalProps} />
    </>
  );
}
