/**
 * This High Order Component will use to make a component lazy load, it will provide a wrapper to handle catch cases
 */
const loadbleLazy = (componentImport) => {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => resolve(component))
      .catch((error) => {
        // If we face chunkLoadError which means chunk is not present then forcefully reload the window
        if (error?.name === "ChunkLoadError") {
          return window.location.reload();
        }
        reject(error);
      });
  });
};

export default loadbleLazy;
