import {
  REDUCER_LAUNCH_DETAILS,
  REDUCER_LAUNCH_STATES,
  REDUCER_LAUNCH_TARGETING,
} from "actions/actionTypes";
import {
  LAUNCH_PARENT_STATE_DETAILS,
  LAUNCH_PARENT_STATE_TARGETING,
} from "components/LaunchView/LaunchKeys";
import { useDispatch, useSelector } from "react-redux";

export default function useLaunchDispatchHooks(launchType) {
  const launchTypeData = useSelector((state) => state?.launchReducer?.[launchType]) || {};
  const {
    [LAUNCH_PARENT_STATE_DETAILS]: details = {},
    [LAUNCH_PARENT_STATE_TARGETING]: targeting = {},
  } = launchTypeData || {};
  const dispatch = useDispatch();

  function setReducerData(type, key, value) {
    dispatch({ type, launchType, payload: { [key]: value } });
  }

  function setDetails(key, value) {
    setReducerData(REDUCER_LAUNCH_DETAILS, key, value);
  }

  function setTargeting(key, value) {
    setReducerData(REDUCER_LAUNCH_TARGETING, key, value);

    // dispatch({
    //   type: REDUCER_LAUNCH_STATES,
    //   payload: { [launchType]: { ...launchTypeData, targeting: { ...targeting, [key]: value } } },
    // });
  }

  return { details, setDetails, targeting, setTargeting };
}
