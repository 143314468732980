import * as types from "../actions/actionTypes";

export default function tableConfigReducer(state = { tableSelectedRows: [] }, action) {
  switch (action.type) {
    case types.TABLE_SELECTED_ROWS:
      return { ...state, tableSelectedRows: action.payload };
    case types.TABLE_SELECTED_ROWS_CLEAR:
      return { ...state, tableSelectedRows: [] };
    default:
      return state;
  }
}
