import ActionIconRender from "components/ActionIconRender";
import cx from "cx";

export default function SelectionCardView(props) {
  const { name = "", description = "", active = false, commingSoon = false } = props;
  return (
    <div className={cx("selection-card-view", { active, commingSoon })}>
      {active && <ActionIconRender iconType="check-circle-filled" />}

      <div className="d-flex align-items-baseline">
        <div className="mr-8">
          <ActionIconRender iconType="rectangleList" />
        </div>

        <div>
          <h3 className="font-size-14">{name}</h3>
          <p className="para">{description}</p>
          {commingSoon && (
            <p className="commingSoonText">
              <span>Coming Soon</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
// selection Card html here
