import { useState } from "react";
import Wrapper from "./components/Wrapper";
import { CreateAgencyFormCombined } from "./components/AgencyForms";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function CreateAgency() {
  const [step, setStep] = useState(1);
  const [phase, setPhase] = useState(1);
  const agency_id = useSelector((state) => state?.userReducer?.me?.agency_id);
  const nextPhase = () => {
    setStep((previous) => (phase === 1 ? 1 : previous === 3 ? 1 : previous + 1));
    setPhase((previous) => (previous === 4 ? 1 : previous + 1));
  };

  const FORMTITLE = ["Create Agency", "Contact Information", "Create Workspace", "Add User"];

  if (agency_id) {
    return <Redirect to="/" />;
  }
  return (
    <>
      {/* <button onClick={nextPhase} style={{ position: "fixed", top: 10, right: 10, zIndex: 9999 }}>
        Temp Button Step: {step} Phase: {phase}
      </button> */}
      <Wrapper step={step} formTitle={FORMTITLE[phase - 1]}>
        <CreateAgencyFormCombined />
      </Wrapper>
    </>
  );
}
export default CreateAgency;
