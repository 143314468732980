import TabRender from "components/TabRender";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import {
  STATUS_FAILED,
  STATUS_PLANNED,
  STATUS_RENDERING,
  STATUS_SUCCEDDED,
  STATUS_TRANSCRIBING,
} from "pages/dashboardPages/Creatives/creativesEnum";
import React, { useEffect, useState } from "react";
import {
  GET_TEMPLATE_VARIATIONS_FROM_TEMPLATE,
  GET_VARIATIONS_STATUS,
} from "shared/gql/gqlSchema/creativeDashboardGql";
import { openNotification } from "utils";
import TemplateVariations from "../TemplateVariations";

export default function CreativesModal(props) {
  const {
    id = "",
    mutationData,
    setUpdatedData = () => {},
    showVariants = false,
    setMutationData = () => {},
    updatedData = [],
    setActiveParentTab = () => {},
    mediaType = "",
    createMediaData = [],
    setCreateMediaData = () => {},
    creativeBuilderV2 = false,
    onCloseDrawer = () => {},
    categoryId = "",
    productId = "",
  } = props;

  const [activeTab, setActiveTab] = useState("InProgress");

  const [skeletonSize, setSkeletonSize] = useState(Array.from({ length: 3 }, () => 10));

  const [getTemplateVariants, { loading: GET_TEMPLATE_loading, refetch: GET_TEMPLATE_refetch }] =
    useCustomLazyQuery(GET_TEMPLATE_VARIATIONS_FROM_TEMPLATE, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (e) => {
        const element = e?.ctGetTemplateVariations;
        if (element?.status === "SUCCESS") {
          setMutationData(element?.data);
        }
      },
      variables: { templateId: id },
    });

  const [
    getVariantsStatus,
    { loading: GET_VARIATIONS_STATUS_loading, refetch: GET_VARIATIONS_STATUS_refetch },
  ] = useCustomLazyQuery(GET_VARIATIONS_STATUS, {
    notifyOnNetworkStatusChange: true,

    onCompleted: (e) => {
      const element = e?.ctUpdateVariationUrls;
      if (element?.status === "SUCCESS") {
        if (creativeBuilderV2) {
          setUpdatedData(element?.data);
        } else {
          setUpdatedData();
          setUpdatedData(element?.data);
        }
      }
    },
    onError(e) {
      openNotification({ type: "error", message: e?.message });
    },
  });
  const refetch = GET_TEMPLATE_refetch;
  const data_successful = showVariants
    ? updatedData?.filter((data) => data?.status === STATUS_SUCCEDDED)
    : mutationData?.[0]?.successfulVariations;
  const data_inprogress = showVariants
    ? updatedData?.filter(
        (data) =>
          data?.status === STATUS_PLANNED ||
          data?.status === STATUS_RENDERING ||
          data?.status === STATUS_TRANSCRIBING
      )
    : mutationData?.[0]?.inProgressVariations;
  const data_failed = showVariants
    ? updatedData?.filter((data) => data?.status === STATUS_FAILED)
    : mutationData?.[0]?.inProgressVariations;

  const tabContent = [
    {
      id: "InProgress",
      title: "In Progress Variations",
      hoverTitle: "In Progress Variations",
      noSpace: true,
      component: activeTab === "InProgress" && (
        <TemplateVariations
          loading={GET_VARIATIONS_STATUS_loading}
          activeTab={activeTab}
          {...props}
          refetch={GET_VARIATIONS_STATUS_refetch}
          showVariants={showVariants}
          templateData={data_inprogress}
          mediaType={mediaType}
          createMediaData={createMediaData}
          setCreateMediaData={setCreateMediaData}
          onCloseDrawer={onCloseDrawer}
        />
      ),
    },
    {
      id: "Successful",
      title: "Successful Variations",
      hoverTitle: "Successful Variations",
      noSpace: true,
      component: activeTab === "Successful" && (
        <TemplateVariations
          activeTab={activeTab}
          {...props}
          refetch={GET_VARIATIONS_STATUS_refetch}
          showVariants={showVariants}
          templateData={data_successful}
          setActiveParentTab={setActiveParentTab}
          createMediaData={createMediaData}
          setCreateMediaData={setCreateMediaData}
          creativeBuilderV2={creativeBuilderV2}
          updatedData={updatedData}
        />
      ),
    },
    {
      id: "Failed",
      title: "Failed Variations",
      hoverTitle: "Failed Variations",
      noSpace: true,
      component: activeTab === "Failed" && (
        <>
          <TemplateVariations
            loading={GET_VARIATIONS_STATUS_loading}
            activeTab={activeTab}
            {...props}
            refetch={GET_VARIATIONS_STATUS_refetch}
            showVariants={showVariants}
            templateData={data_failed}
          />
        </>
      ),
    },
  ];
  const reloadMedia = () => {
    getVariantsStatus({
      variables: {
        templateId: id,
        categoryId: creativeBuilderV2 ? categoryId : undefined,
        productId: creativeBuilderV2 ? productId : undefined,
      },
    });
  };
  useEffect(() => {
    const success = updatedData?.every(
      (data) => data?.status === STATUS_SUCCEDDED || data?.status === STATUS_FAILED
    );
    let timeoutId;
    if (showVariants && !success && activeTab === "InProgress") {
      timeoutId = setTimeout(function () {
        reloadMedia();
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [updatedData, showVariants, activeTab]);

  useEffect(() => {
    if (!showVariants) {
      getTemplateVariants();
    } else if (showVariants) {
      reloadMedia();
    }
  }, [showVariants]);

  return (
    <div>
      {!showVariants ? (
        <TemplateVariations
          {...props}
          showVariants={showVariants}
          templateData={data_successful}
          refetch={refetch}
        />
      ) : (
        <TabRender
          className="top-bar"
          fullBtn
          tabContent={tabContent}
          value={activeTab}
          onChange={setActiveTab}
        />
      )}
    </div>
  );
}
