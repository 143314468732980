import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import LookTextInput from "components/forms/LookTextInput";
import { useCustomLazyQuery } from "hooks/apolloClientHooks";
import { LAUNCH_LEAD_GEN_TRACKING_URL } from "shared/gql/gqlSchema/launchGql";
import { AutoComplete } from "antd";
function LeadgenUrlDropdown(props) {
  const { setFormData, url, otherProps, adAccountIndex, index, data } = props;
  const [
    trackingUrlFetch,
    { data: trackingUrl_data, loading: trackingUrl_loading, refetch: trackingUrl_refetch },
  ] = useCustomLazyQuery(LAUNCH_LEAD_GEN_TRACKING_URL);

  useEffect(() => {
    if (otherProps?.adAccount) {
      trackingUrlFetch({
        variables: {
          ad_account: "act_" + data?.adAccount,
          landing_url: otherProps?.name,
        },
      });
    }
  }, [data?.adAccount]);
  const options = trackingUrl_data?.trackerUrl || [];
  // const [customUrl, setCustomUrl] = useState(url?.links);
  const [urlOptions, setUrlOptions] = useState(options);

  const handleUrlParamsChange = (newValue) => {
    setFormData((prevItems) => {
      const updatedItems = [...prevItems];
      const updatedItem = { ...updatedItems[index] };
      const updatedAdAccounts = [...updatedItem?.adAccount];
      let updatedUrlItems = [];
      if (updatedAdAccounts[adAccountIndex]?.url?.length === 0) {
        updatedUrlItems.push({
          index: otherProps?.urlIndex,
          links: newValue,
          name: otherProps?.name,
        });

        updatedItem.adAccount = updatedAdAccounts;
      } else {
        updatedUrlItems = updatedAdAccounts[adAccountIndex]?.url?.map((item) =>
          item?.index === otherProps?.urlIndex
            ? { ...item, links: newValue, name: otherProps?.name }
            : item
        );

        const matchingItemIndex = updatedAdAccounts[adAccountIndex]?.url?.find(
          (item) => item?.index === otherProps?.urlIndex
        );

        if (!matchingItemIndex) {
          updatedUrlItems?.push({
            index: otherProps?.urlIndex,
            links: newValue,
            name: otherProps?.name,
          });

          // updatedItems.push(updatedItem);
        }
      }
      updatedAdAccounts[adAccountIndex] = {
        ...updatedAdAccounts[adAccountIndex],
        url: updatedUrlItems,
      };
      updatedItem.adAccount = updatedAdAccounts;
      updatedItems[index] = updatedItem;
      return updatedItems;
    });
  };
  return (
    <div>
      {/* <Select
        showSearch
        name="url"
        size="large"
        style={{ width: "100%", marginTop: "0", marginBottom: "15px" }}
        value={url?.links}
        onChange={(e) => handleUrlParamsChange(e)}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 12px 12px" }}>
              <LookTextInput
                value={customUrl}
                className="my-12"
                onChange={(e) => setCustomUrl(e.target.value)}
              />
              <Button
                type="primary"
                className="w-100  my-10"
                icon={<PlusOutlined />}
                onClick={() => {
                  handleUrlParamsChange(customUrl);
                  setCustomUrl("");
                }}
              >
                Add new url
              </Button>
            </Space>
          </>
        )}
        options={options
          ?.filter((item) => item?.tracker_url)
          ?.map((tracker_url) => ({
            label: tracker_url,
            value: tracker_url,
          }))}
      /> */}
      <AutoComplete
        options={urlOptions
          ?.filter((item) => item?.tracker_url)
          ?.map((tracker_url) => ({
            value: tracker_url,
          }))}
        value={url?.links}
        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     handleUrlParamsChange(customUrl);
        //   }
        // }}
        className="auto-complete-btn"
        onChange={(e) => handleUrlParamsChange(e)}
        onSelect={(e) => handleUrlParamsChange(e)}
        onSearch={(text) =>
          setUrlOptions(
            text
              ? urlOptions?.filter((data) =>
                  data?.tracker_url?.toLowerCase()?.includes(text?.toLowerCase())
                )
              : options
          )
        }
        placeholder="Enter tracking url"
      />
    </div>
  );
}

LeadgenUrlDropdown.propTypes = {
  landingUrl: PropTypes.string.isRequired,
  adAccount: PropTypes.string,
  adAccountIndex: PropTypes.string,
  index: PropTypes.string,
  setFormData: PropTypes.func.isRequired,
  url: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  otherProps: PropTypes.object.isRequired,
};

export default LeadgenUrlDropdown;
