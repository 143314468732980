import { useRef, useState } from "react";
import * as yup from "yup";
import MainForm from "../MainForm";
import { useCustomMutation } from "hooks/apolloClientHooks";
import { openNotification } from "utils";
import { UPDATE_SAS_KEYWORD } from "shared/gql/gqlSchema/sasGql";

export default function UpdateForm(props) {
  const {
    id,
    isSensitive: propIsSensitive,
    name: propName,
    category: propCategory,
    status: propStatus,
    language: propLanguage,
    keywordSource: propKeywordSource,
    dataRender,
    onClose = () => {},
    setDataRender = () => {},
  } = props;

  const [isError, setIsError] = useState("");
  const resetFormRef = useRef(() => {});
  const clearError = () => {
    isError !== "" && setIsError("");
  };

  const [sasKeywordUpdate, { loading }] = useCustomMutation(UPDATE_SAS_KEYWORD, {
    onCompleted(e) {
      openNotification({ type: "success", message: "Record Update Successfully" });
      const elementData = e?.sasKeywordUpdate;

      const updatedItem = {
        ...elementData,
        key: elementData.id,
      };

      const temp = JSON.parse(JSON.stringify(dataRender));
      const updateIndex = dataRender.findIndex(({ id }) => id === elementData.id);
      temp[updateIndex] = updatedItem;
      setDataRender(temp);
      onClose();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit(values, { setSubmitting, resetForm }) {
    const { isSensitive, name } = values;
    resetFormRef.current = resetForm;
    clearError();

    const mainValue = name.replace("&", "and");

    sasKeywordUpdate({
      variables: {
        id,
        ...values,
        isSensitive: Boolean(isSensitive),
        name: mainValue,
      },
    });
  }

  const initialValues = {
    name: propName,
    isSensitive: Number(propIsSensitive),
    categoryId: propCategory?.id,
    status: propStatus,
    keywordSourceId: propKeywordSource?.id,
    languageId: propLanguage?.id,
  };

  const validationSchema = yup.object({
    name: yup.string().required().label("Name"),
  });

  const formProps = { initialValues, validationSchema, onSubmit, isError, loading, id, onClose };
  return (
    <div>
      <MainForm btnText="Update" type="edit" {...formProps} />
    </div>
  );
}
