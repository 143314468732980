import { Card, Divider, Row, Col, Skeleton } from "antd";

const LineDivider = () => (
  <Divider className="w-initial" style={{ marginLeft: -24, marginRight: -24 }} />
);

export default function AdCreativeCardSkeleton() {
  return (
    <Card className="ad-library-card h-100">
      <div className="flex-between">
        <div>
          <div>
            <Skeleton.Input
              className="mr-6"
              active
              style={{ width: 20, height: 15 }}
              size="small"
            />
            <Skeleton.Input active style={{ width: 100, height: 15 }} size="small" />
          </div>
        </div>
        <div>
          <Skeleton.Input className="mr-6" active style={{ width: 20, height: 15 }} size="small" />
          <Skeleton.Input active style={{ width: 20, height: 15 }} size="small" />
        </div>
      </div>
      <Skeleton.Input active style={{ width: 220, height: 15 }} size="small" />
      <Skeleton.Input active style={{ width: 185, height: 15 }} size="small" />
      <LineDivider />
      <div>
        <Skeleton.Input active style={{ width: 60, height: 15 }} size="small" />
        <p>
          <Skeleton.Input active style={{ width: 40, height: 10 }} size="small" />
        </p>
        <p>
          <Skeleton.Input className="w-100" active style={{ height: 15 }} size="small" />
          <Skeleton.Input className="w-100" active style={{ height: 15 }} size="small" />
          <Skeleton.Input active style={{ width: 100, height: 15 }} size="small" />
        </p>
        <div className="mb-16">
          <Skeleton.Image className="w-100" style={{ width: "100%" }} />
        </div>
        <Row gutter={24} className="mb-8">
          <Col span={16}>
            <Skeleton.Input className="w-100" active style={{ height: 15 }} size="small" />
            <Skeleton.Input active style={{ width: 100, height: 15 }} size="small" />
          </Col>
          <Col span={8}>
            <Skeleton.Input className="w-100" active style={{ height: 30 }} size="small" />
          </Col>
        </Row>
        <LineDivider />
        <Skeleton.Input className="w-100" active style={{ height: 30 }} size="small" />
      </div>
    </Card>
  );
}
