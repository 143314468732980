import { Form, Button, Alert, Row, Col, Checkbox, TimePicker } from "antd";
import { Formik } from "formik";
import Loader from "components/loader";
import SelectInput from "components/forms/SelectInput";
import {
  industryInsightsDays,
  industryInsightsFrequencies,
  industryInsightsNotifyAbout,
  industryInsightsNotifyPrefrences,
} from "shared/enum/industryInsightsKeysAndEnums";
import moment from "moment";
import LookButton from "components/LookButton";

export default function NotifyMe(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    isError,
    btnText = "",
    loading = false,
    setNotifyAbout = () => {},
    defaultType = "",
    defaultTime = "",
    setDefaultTime = () => {},
    setPrefrences = () => {},
    prefrences = [],
    notifyAbout = [],
  } = props;
  if (loading) {
    return <Loader className="text-center" />;
  }

  const onChange = (checkedValues) => {
    setNotifyAbout(checkedValues);
  };

  const onPrefrencesHandelChange = (checkedValues) => {
    setPrefrences(checkedValues);
  };

  const handleTimeChange = (time, timeString) => {
    setDefaultTime(timeString);
  };

  const DefaultTimeFormat = "HH:mm";

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, resetForm }) => (
        <Form layout="vertical">
          {/* <pre> {JSON.stringify({ initialValues, values }, null, 2)} </pre> */}
          {isError && <Alert message={isError} type="error" className="mb-10" />}
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item label="Frequency" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Frequency"
                  name="frequency"
                  value={values?.frequency}
                  options={industryInsightsFrequencies?.map(({ value, name }) => ({
                    name,
                    value,
                  }))}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Day" className="look-form-input">
                <SelectInput
                  className="mb-0"
                  formik
                  search
                  placeholder="Select Day"
                  name="day"
                  value={values?.day}
                  options={industryInsightsDays?.map(({ value, name }) => ({
                    name,
                    value,
                  }))}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <p>Default Time</p>
              <div className="">
                <TimePicker
                  onChange={handleTimeChange}
                  defaultValue={moment(defaultTime, DefaultTimeFormat)}
                  format={DefaultTimeFormat}
                />
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-2 mt-10">
                <p>Notify About</p>
                <Checkbox.Group
                  onChange={onChange}
                  className="width-hundred-percent"
                  defaultValue={notifyAbout}
                >
                  <Row>
                    {industryInsightsNotifyAbout.map((item) => (
                      <Col span={8}>
                        <Checkbox formik value={item.value} disabled={item.value === defaultType}>
                          {item.label}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </div>
            </Col>
            <Col span={24}>
              <div className="mb-2 mt-10">
                <p>Preferences</p>
                <Checkbox.Group
                  onChange={onPrefrencesHandelChange}
                  className="width-hundred-percent"
                  defaultValue={prefrences}
                >
                  <Row>
                    {industryInsightsNotifyPrefrences.map((item) => (
                      <Col span={8}>
                        <Checkbox value={item.value} disabled={item.value === defaultType}>
                          {item.label}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <LookButton
                  loading={loading}
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </LookButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
