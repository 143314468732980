import React, { useEffect, useState } from "react";
import AddTag from "./AddTag";

export default function Tag({
  files,
  variables,
  creatives_refetch,
  handleFileUpload,
  setTags,
  closeTagModal = () => {},
  selectedRowsClear,
}) {
  const [images, setImages] = useState(files);

  useEffect(() => {
    const cleanedData = files?.map((item) => {
      const cleanedItem = item;
      if (item?.tags?.length) {
        cleanedItem.tags = item?.tags?.map((tag) => {
          return { id: tag?.id || "", tag: tag?.tag || "" };
        });
      }
      return cleanedItem;
    });
    setImages(cleanedData);
  }, [files]);

  return (
    <AddTag
      files={images}
      variables={variables}
      creatives_refetch={creatives_refetch}
      handleFileUpload={handleFileUpload}
      setTags={setTags}
      closeTagModal={closeTagModal}
      selectedRowsClear={selectedRowsClear}
    />
  );
}
