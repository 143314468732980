import LookTabRouting from "components/LookTabRouting";
import ActionIconRender from "components/ActionIconRender";
import { TIKTOK_USER, TIKTOK_WORKSPACE_ADMIN } from "shared/UserPermission/userPermissionList";

import {
  REPORT_TYPE_AD_ACCOUNT,
  REPORT_TYPE_CAMPAIGN,
  REPORT_TYPE_AD_ADSET,
  REPORT_TYPE_AD,
  PRODUCT_ID_LEADGEN,
  REPORT_TYPE_GENERAL,
} from "shared/SharedKeys";
import TiktokLeadgenSpendRevenueReport from "../TiktokLeadgenSpendRevenueReport";
import { TIKTOK_VOLUUM_REPORT_QUERY } from "hooks/tiktokHooks/useLeadgenReportsQuery";
import TiktokLeadgenUserReports from "./UserReports";
import RejectedAssetsReport from "pages/dashboardPages/SAS/Reports/TiktokReports/RejectedAssetsReport";

export default function TiktokReports() {
  const tabContent = [
    {
      link: "/user-report",
      route: "/user-report",
      title: "User Report",
      component: TiktokLeadgenUserReports,
      icon: () => <ActionIconRender iconType="user" />,
      permission: TIKTOK_WORKSPACE_ADMIN,
    },
    {
      link: "/ad-account",
      route: "/ad-account",
      title: "Ad Account",
      component: () => (
        <TiktokLeadgenSpendRevenueReport
          reportType={REPORT_TYPE_AD_ACCOUNT}
          queryType={TIKTOK_VOLUUM_REPORT_QUERY}
          alasqlQuery="SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.adAccountId = b.revAdAccountId"
        />
      ),
      icon: () => <ActionIconRender iconType="notification" />,
      permission: TIKTOK_USER,
    },
    {
      link: "/campaign",
      route: "/campaign",
      title: "Campaign",
      component: () => (
        <TiktokLeadgenSpendRevenueReport
          isPerformance
          outerDate
          reportType={REPORT_TYPE_CAMPAIGN}
          queryType={TIKTOK_VOLUUM_REPORT_QUERY}
          alasqlQuery="SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.campaignId = b.campaignId"
          ruleSetting={{
            groupBy: REPORT_TYPE_CAMPAIGN,
            reportType: REPORT_TYPE_GENERAL,
            productId: PRODUCT_ID_LEADGEN,
            platform: "tiktok",
          }}
        />
      ),
      icon: () => <ActionIconRender iconType="notification" />,
      permission: TIKTOK_USER,
    },
    {
      link: "/adset",
      route: "/adset",
      title: "Adset",
      component: () => (
        <TiktokLeadgenSpendRevenueReport
          isPerformance
          outerDate
          reportType={REPORT_TYPE_AD_ADSET}
          queryType={TIKTOK_VOLUUM_REPORT_QUERY}
          alasqlQuery="SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.adsetId = b.adsetId"
          ruleSetting={{
            groupBy: REPORT_TYPE_AD_ADSET,
            reportType: REPORT_TYPE_GENERAL,
            productId: PRODUCT_ID_LEADGEN,
            platform: "tiktok",
          }}
        />
      ),
      icon: () => <ActionIconRender iconType="notification" />,
      permission: TIKTOK_USER,
    },
    {
      link: "/ad",
      route: "/ad",
      title: "Ad",
      component: () => (
        <TiktokLeadgenSpendRevenueReport
          isPerformance
          outerDate
          reportType={REPORT_TYPE_AD}
          queryType={TIKTOK_VOLUUM_REPORT_QUERY}
          alasqlQuery="SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.adId = b.adId"
          ruleSetting={{
            groupBy: REPORT_TYPE_AD,
            reportType: REPORT_TYPE_GENERAL,
            productId: PRODUCT_ID_LEADGEN,
            platform: "tiktok",
          }}
        />
      ),
      icon: () => <ActionIconRender iconType="notification" />,
      permission: TIKTOK_USER,
    },
    {
      link: "/rejectedAssets",
      route: "/rejectedAssets",
      title: "Rejected Assets",
      component: () => <RejectedAssetsReport />,
      icon: () => <ActionIconRender iconType="notification" />,
      permission: TIKTOK_USER,
    },
  ];

  return (
    <div>
      <LookTabRouting dashboardHeader={{ title: "Report" }} tabs={tabContent} />
    </div>
  );
}
//
