import { gql } from "@apollo/client";

const TRACKER_CONFIG_RESPONSE = gql`
  fragment Tracker_Config on Tracker_Config {
    Countries {
      id
      iso_code
      name
    }
    Currencies {
      id
      code
      name
      symbol
    }
  }
`;

export const GQL_TRACKER_CONFIG = gql`
  ${TRACKER_CONFIG_RESPONSE}
  query Tracker_Config {
    Tracker_Config {
      message
      status
      data {
        ...Tracker_Config
      }
    }
  }
`;

const TRACKER_CATEGORY_RESPONSE = gql`
  fragment Tracker_Category on Tracker_Category {
    id
    name
    advertisers {
      id
      name
    }
    offers {
      id
      name
    }
  }
`;

export const GQL_TRACKER_PAGED_CATEGORY = gql`
  ${TRACKER_CATEGORY_RESPONSE}
  query Tracker_PagedCategories(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedCategories(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_Category
      }
    }
  }
`;

const TRACKER_CATEGORY_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_Category on Tracker_Category {
    id
    name
  }
`;

export const GQL_TRACKER_CATEGORY_FOR_DROPDOWN = gql`
  ${TRACKER_CATEGORY_RESPONSE_FOR_DROPDOWN}
  query Tracker_Categories {
    Tracker_Categories {
      data {
        ...Tracker_Category
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_ADD_CATEGORY = gql`
  ${TRACKER_CATEGORY_RESPONSE}
  mutation Tracker_CreateCategory($name: String!) {
    Tracker_CreateCategory(name: $name) {
      data {
        ...Tracker_Category
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_UPDATE_CATEGORY = gql`
  ${TRACKER_CATEGORY_RESPONSE}
  mutation Tracker_UpdateCategory($id: ID!, $name: String) {
    Tracker_UpdateCategory(id: $id, name: $name) {
      data {
        ...Tracker_Category
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_DELETE_CATEGORY = gql`
  mutation Tracker_DeleteCategory($id: [ID!]) {
    Tracker_DeleteCategory(id: $id)
  }
`;

const TRACKER_ADVERTISER_RESPONSE = gql`
  fragment Tracker_Advertiser on Tracker_Advertiser {
    id
    name
    guidelines
    sensitivity_level
    website
    category {
      id
      name
    }
    countries
    states
  }
`;

export const GQL_TRACKER_PAGED_ADVERTISERS = gql`
  ${TRACKER_ADVERTISER_RESPONSE}
  query Tracker_PagedAdvertisers(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedAdvertisers(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_Advertiser
      }
    }
  }
`;

export const GQL_TRACKER_ADVERTISERS = gql`
  ${TRACKER_ADVERTISER_RESPONSE}
  query Tracker_Advertisers {
    Tracker_Advertisers {
      data {
        ...Tracker_Advertiser
      }
      message
      status
    }
  }
`;

const TRACKER_ADVERTISER_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_Advertiser on Tracker_Advertiser {
    id
    name
  }
`;

export const GQL_TRACKER_ADVERTISERS_DROPDOWN = gql`
  ${TRACKER_ADVERTISER_RESPONSE_FOR_DROPDOWN}
  query Tracker_Advertisers {
    Tracker_Advertisers {
      message
      status
      data {
        ...Tracker_Advertiser
      }
    }
  }
`;

export const GQL_TRACKER_ADD_ADVERTISER = gql`
  ${TRACKER_ADVERTISER_RESPONSE}
  mutation Tracker_CreateAdvertiser(
    $category_id: ID!
    $guidelines: String
    $name: String!
    $sensitivity_level: TRACKER_SENSITIVITY_LEVEL
    $website: String
    $countries: Object
    $states: Object
  ) {
    Tracker_CreateAdvertiser(
      category_id: $category_id
      guidelines: $guidelines
      name: $name
      sensitivity_level: $sensitivity_level
      website: $website
      countries: $countries
      states: $states
    ) {
      data {
        ...Tracker_Advertiser
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_UPDATE_ADVERTISER = gql`
  ${TRACKER_ADVERTISER_RESPONSE}
  mutation Tracker_UpdateAdvertiser(
    $category_id: ID
    $guidelines: String
    $id: ID!
    $name: String
    $sensitivity_level: TRACKER_SENSITIVITY_LEVEL
    $website: String
    $countries: Object
    $states: Object
  ) {
    Tracker_UpdateAdvertiser(
      category_id: $category_id
      guidelines: $guidelines
      id: $id
      name: $name
      sensitivity_level: $sensitivity_level
      website: $website
      countries: $countries
      states: $states
    ) {
      data {
        ...Tracker_Advertiser
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_DELETE_ADVERTISER = gql`
  mutation Tracker_DeleteAdvertiser($id: [ID!]) {
    Tracker_DeleteAdvertiser(id: $id)
  }
`;

const TRACKER_DOMAIN_RESPONSE = gql`
  fragment Tracker_Domain on Tracker_Domain {
    id
    name
    status
    url
    # agency {
    #   id
    #   advertisers {
    #     id
    #     name
    #   }
    #   affiliate_networks {
    #     id
    #     name
    #   }
    #   campaigns {
    #     id
    #     name
    #   }
    #   domains {
    #     id
    #     name
    #   }
    #   landers {
    #     id
    #     name
    #   }
    #   network_accounts {
    #     id
    #     name
    #   }
    #   offers {
    #     id
    #     name
    #   }
    #   subidgroups {
    #     id
    #     name
    #   }
    #   referralsubids {
    #     id
    #     name
    #   }
    #   tracking_tokens {
    #     id
    #     name
    #   }
    #   traffic_sources {
    #     id
    #     name
    #   }
    # }
  }
`;

export const GQL_TRACKER_PAGED_DOMAIN = gql`
  ${TRACKER_DOMAIN_RESPONSE}
  query Tracker_PagedDomains(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedDomains(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      status
      message
      data {
        ...Tracker_Domain
      }
    }
  }
`;

const TRACKER_DOMAIN_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_Domain on Tracker_Domain {
    id
    name
    url
  }
`;

export const GQL_TRACKER_DOMAINS_FOR_DROPDOWN = gql`
  ${TRACKER_DOMAIN_RESPONSE_FOR_DROPDOWN}
  query Tracker_Domains {
    Tracker_Domains {
      status
      message
      data {
        ...Tracker_Domain
      }
    }
  }
`;

export const GQL_TRACKER_ADD_DOMAIN = gql`
  mutation Tracker_CreateDomain($name: String!, $url: String, $status: Tracker_Domain_Status) {
    Tracker_CreateDomain(name: $name, url: $url, status: $status) {
      data {
        id
        name
        status
        url
      }
      status
      message
    }
  }
`;

export const GQL_TRACKER_UPDATE_DOMAIN = gql`
  mutation Tracker_UpdateDomain(
    $id: ID!
    $name: String!
    $url: String
    $status: Tracker_Domain_Status
  ) {
    Tracker_UpdateDomain(id: $id, name: $name, url: $url, status: $status) {
      data {
        id
        name
        status
        url
      }
      status
      message
    }
  }
`;

export const GQL_TRACKER_DELETE_DOMAIN = gql`
  mutation Tracker_DeleteDomain($id: [ID]!) {
    Tracker_DeleteDomain(id: $id)
  }
`;

const TRACKER_LANDER_RESPONSE = gql`
  fragment Tracker_Lander on Tracker_Lander {
    agency {
      id
    }
    id
    country {
      id
      iso_code
      name
    }
    lander_url
    name
    paths {
      id
      name
    }
    safe_offer_url
    status
    workspace {
      id
    }
    no_of_cta
    notes
    domain {
      id
      name
    }
    google_signature
  }
`;

const TRACKER_LANDER_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_Lander on Tracker_Lander {
    id
    name
    no_of_cta
  }
`;

export const GQL_TRACKER_PAGED_LANDERS = gql`
  ${TRACKER_LANDER_RESPONSE}
  query Tracker_PagedLanders(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedLanders(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_Lander
      }
    }
  }
`;

export const GQL_TRACKER_LANDERS_FOR_DROPDOWN = gql`
  ${TRACKER_LANDER_RESPONSE_FOR_DROPDOWN}
  query Tracker_Landers {
    Tracker_Landers {
      data {
        ...Tracker_Lander
      }
    }
  }
`;

export const GQL_TRACKER_ADD_LANDER = gql`
  ${TRACKER_LANDER_RESPONSE}
  mutation Tracker_CreateLander(
    $country_id: ID
    $domain_id: ID
    $lander_url: String
    $landing_tracking_script: String
    $name: String!
    $no_of_cta: Int
    $notes: String
    $safe_offer_url: String
    $status: Tracker_LanderStatus
    $google_signature: String
  ) {
    Tracker_CreateLander(
      country_id: $country_id
      domain_id: $domain_id
      lander_url: $lander_url
      landing_tracking_script: $landing_tracking_script
      name: $name
      google_signature: $google_signature
      no_of_cta: $no_of_cta
      notes: $notes
      safe_offer_url: $safe_offer_url
      status: $status
    ) {
      data {
        ...Tracker_Lander
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_UPDATE_LANDER = gql`
  ${TRACKER_LANDER_RESPONSE}
  mutation Tracker_UpdateLander(
    $id: ID!
    $country_id: ID
    $domain_id: ID
    $lander_url: String
    $landing_tracking_script: String
    $name: String!
    $no_of_cta: Int
    $notes: String
    $safe_offer_url: String
    $status: Tracker_LanderStatus
    $google_signature: String
  ) {
    Tracker_UpdateLander(
      id: $id
      country_id: $country_id
      domain_id: $domain_id
      lander_url: $lander_url
      landing_tracking_script: $landing_tracking_script
      name: $name
      no_of_cta: $no_of_cta
      notes: $notes
      safe_offer_url: $safe_offer_url
      status: $status
      google_signature: $google_signature
    ) {
      data {
        ...Tracker_Lander
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_DELETE_LANDER = gql`
  mutation Tracker_DeleteLander($id: [ID!]) {
    Tracker_DeleteLander(id: $id)
  }
`;

const TRACKER_AFFILIATE_NETWORK_RESPONSE = gql`
  fragment Tracker_AffiliateNetwork on Tracker_AffiliateNetwork {
    id
    name
    accept_duplicate_postbacks_pixels
    append_click_id_to_offer_url
    category {
      id
      name
    }
    country {
      id
      name
      iso_code
    }
    currency {
      id
      code
      name
      symbol
    }
    description
    network_accounts {
      id
      name
    }
    notes
    only_accept_postback_from_whitelist_ip
    payment_frequency
    postback_method
    postback_whitelist_ip
    sensitivity_level
    timezone
    base_url_structure

    trackingTokens {
      name
      token
      placeholder
    }
    clickid_key
    subid_key
  }
`;

export const GQL_TRACKER_PAGED_AFFILIATE_NETWORK = gql`
  ${TRACKER_AFFILIATE_NETWORK_RESPONSE}
  query Tracker_PagedAffiliateNetworks(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedAffiliateNetworks(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      message
      status
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_AffiliateNetwork
      }
    }
  }
`;

export const GQL_TRACKER_AFFILIATE_NETWORK = gql`
  ${TRACKER_AFFILIATE_NETWORK_RESPONSE}
  query Tracker_AffiliateNetwork($id: ID!) {
    Tracker_AffiliateNetwork(id: $id) {
      message
      status
      data {
        ...Tracker_AffiliateNetwork
      }
    }
  }
`;

const TRACKER_AFFILIATE_NETWORK_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_AffiliateNetwork on Tracker_AffiliateNetwork {
    id
    name
    base_url_structure
  }
`;

export const GQL_TRACKER_PAGED_AFFILIATE_NETWORK_FOR_DROPDOWN = gql`
  ${TRACKER_AFFILIATE_NETWORK_RESPONSE_FOR_DROPDOWN}
  query Tracker_AffiliateNetworks {
    Tracker_AffiliateNetworks {
      message
      status
      data {
        ...Tracker_AffiliateNetwork
      }
    }
  }
`;

export const GQL_TRACKER_ADD_AFFILIATE_NETWORK = gql`
  ${TRACKER_AFFILIATE_NETWORK_RESPONSE}
  mutation Tracker_CreateAffiliateNetwork(
    $accept_duplicate_postbacks_pixels: Boolean
    $append_click_id_to_offer_url: Boolean
    $category_id: ID
    $country_id: ID
    $currency_id: ID
    $description: String
    $name: String!
    $notes: String
    $only_accept_postback_from_whitelist_ip: Boolean
    $payment_frequency: Tracker_AffiliateNetwork_PaymentFrequency
    $postback_method: TRACKER_POSTBACK_METHOD
    $postback_whitelist_ip: String
    $sensitivity_level: TRACKER_SENSITIVITY_LEVEL
    $timezone: String
    $token_params: [Tracker_Token_Params]
    $base_url_structure: String
    $clickid_key: String
    $subid_key: String
  ) {
    Tracker_CreateAffiliateNetwork(
      accept_duplicate_postbacks_pixels: $accept_duplicate_postbacks_pixels
      append_click_id_to_offer_url: $append_click_id_to_offer_url
      category_id: $category_id
      country_id: $country_id
      currency_id: $currency_id
      description: $description
      name: $name
      notes: $notes
      only_accept_postback_from_whitelist_ip: $only_accept_postback_from_whitelist_ip
      payment_frequency: $payment_frequency
      postback_method: $postback_method
      postback_whitelist_ip: $postback_whitelist_ip
      sensitivity_level: $sensitivity_level
      timezone: $timezone
      token_params: $token_params
      base_url_structure: $base_url_structure
      clickid_key: $clickid_key
      subid_key: $subid_key
    ) {
      message
      status
      data {
        ...Tracker_AffiliateNetwork
      }
    }
  }
`;

export const GQL_TRACKER_UPDATE_AFFILIATE_NETWORK = gql`
  ${TRACKER_AFFILIATE_NETWORK_RESPONSE}
  mutation Tracker_UpdateAffiliateNetwork(
    $accept_duplicate_postbacks_pixels: Boolean
    $append_click_id_to_offer_url: Boolean
    $category_id: ID
    $country_id: ID
    $currency_id: ID
    $description: String
    $id: ID!
    $name: String
    $notes: String
    $only_accept_postback_from_whitelist_ip: Boolean
    $payment_frequency: Tracker_AffiliateNetwork_PaymentFrequency
    $postback_method: TRACKER_POSTBACK_METHOD
    $postback_whitelist_ip: String
    $sensitivity_level: TRACKER_SENSITIVITY_LEVEL
    $timezone: String
    $token_params: [Tracker_Token_Params]
    $base_url_structure: String
    $clickid_key: String
    $subid_key: String
  ) {
    Tracker_UpdateAffiliateNetwork(
      accept_duplicate_postbacks_pixels: $accept_duplicate_postbacks_pixels
      append_click_id_to_offer_url: $append_click_id_to_offer_url
      category_id: $category_id
      country_id: $country_id
      currency_id: $currency_id
      description: $description
      id: $id
      name: $name
      notes: $notes
      only_accept_postback_from_whitelist_ip: $only_accept_postback_from_whitelist_ip
      payment_frequency: $payment_frequency
      postback_method: $postback_method
      postback_whitelist_ip: $postback_whitelist_ip
      sensitivity_level: $sensitivity_level
      timezone: $timezone
      token_params: $token_params
      base_url_structure: $base_url_structure
      clickid_key: $clickid_key
      subid_key: $subid_key
    ) {
      message
      status
      data {
        ...Tracker_AffiliateNetwork
      }
    }
  }
`;

export const GQL_TRACKER_DELETE_AFFILIATE_NETWORK = gql`
  mutation Tracker_DeleteAffiliateNetwork($id: [ID!]) {
    Tracker_DeleteAffiliateNetwork(id: $id)
  }
`;

const TRACKER_COUNTRIES_RESPONSE = gql`
  fragment Tracker_Country on Tracker_Country {
    id
    iso_code
    name
  }
`;

export const GQL_TRACKER_COUNTRIES = gql`
  ${TRACKER_COUNTRIES_RESPONSE}
  query Tracker_Countries {
    Tracker_Countries {
      message
      status
      data {
        ...Tracker_Country
      }
    }
  }
`;

const TRACKER_RULE_RESPONSE = gql`
  fragment Tracker_Rule on Tracker_Rule {
    id
    name
    campaign {
      id
      name
    }
    conditions
    paths {
      id
      name
    }
    status
  }
`;

const TRACKER_RULE_RESPONSE_DROPDOWN = gql`
  fragment Tracker_Rule on Tracker_Rule {
    id
    name
  }
`;

export const GQL_TRACKER_RULES = gql`
  ${TRACKER_RULE_RESPONSE}
  query Tracker_Rules {
    Tracker_Rules {
      message
      status
      data {
        ...Tracker_Rule
      }
    }
  }
`;

export const GQL_TRACKER_RULES_DROPDOWN = gql`
  ${TRACKER_RULE_RESPONSE_DROPDOWN}
  query Tracker_Rules {
    Tracker_Rules {
      message
      status
      data {
        ...Tracker_Rule
      }
    }
  }
`;

export const GQL_TRACKER_ADD_RULE = gql`
  ${TRACKER_RULE_RESPONSE}
  mutation Tracker_CreateRule(
    $conditions: String
    $name: String!
    $path_id: [ID]
    $status: Boolean
  ) {
    Tracker_CreateRule(conditions: $conditions, name: $name, path_id: $path_id, status: $status) {
      message
      status
      data {
        ...Tracker_Rule
      }
    }
  }
`;

export const GQL_TRACKER_UPDATE_RULE = gql`
  ${TRACKER_RULE_RESPONSE}
  mutation Tracker_UpdateRule(
    $conditions: String
    $id: ID!
    $name: String!
    $path_id: [ID]
    $status: Boolean
  ) {
    Tracker_UpdateRule(
      conditions: $conditions
      id: $id
      name: $name
      path_id: $path_id
      status: $status
    ) {
      message
      status
      data {
        ...Tracker_Rule
      }
    }
  }
`;

export const GQL_TRACKER_DELETE_RULE = gql`
  mutation Tracker_DeleteRule($id: [ID!]) {
    Tracker_DeleteRule(id: $id)
  }
`;

const TRACKER_PAGE_RESPONSE = gql`
  fragment Tracker_Path on Tracker_Path {
    id
    name
    campaign {
      id
      name
    }
    ctas {
      id
      name
    }
    is_active
    is_default
    landers {
      id
      name
    }
    offer_redirect_method
    rule {
      id
      name
    }
    weight
  }
`;

export const GQL_TRACKER_PATHS = gql`
  ${TRACKER_PAGE_RESPONSE}
  query Tracker_Paths {
    Tracker_Paths {
      message
      status
      data {
        ...Tracker_Path
      }
    }
  }
`;
const TRACKER_PAGE_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_Path on Tracker_Path {
    id
    name
  }
`;

export const GQL_TRACKER_PATHS_FOR_DROPDOWN = gql`
  ${TRACKER_PAGE_RESPONSE_FOR_DROPDOWN}
  query Tracker_Paths {
    Tracker_Paths {
      message
      status
      data {
        ...Tracker_Path
      }
    }
  }
`;

export const GQL_TRACKER_ADD_PAGE = gql`
  ${TRACKER_PAGE_RESPONSE}
  mutation Tracker_CreatePath(
    $is_active: Boolean
    $is_default: Boolean
    $name: String!
    $offer_redirect_method: String
    $rule_id: ID!
    $weight: Int
  ) {
    Tracker_CreatePath(
      is_active: $is_active
      is_default: $is_default
      name: $name
      offer_redirect_method: $offer_redirect_method
      rule_id: $rule_id
      weight: $weight
    ) {
      message
      status
      data {
        ...Tracker_Path
      }
    }
  }
`;

export const GQL_TRACKER_UPDATE_PAGE = gql`
  ${TRACKER_PAGE_RESPONSE}
  mutation Tracker_UpdatePath(
    $id: ID!
    $is_active: Boolean
    $is_default: Boolean
    $name: String
    $offer_redirect_method: String
    $weight: Int
  ) {
    Tracker_UpdatePath(
      id: $id
      is_active: $is_active
      is_default: $is_default
      name: $name
      offer_redirect_method: $offer_redirect_method
      weight: $weight
    ) {
      message
      status
      data {
        ...Tracker_Path
      }
    }
  }
`;

export const GQL_TRACKER_DELETE_PATH = gql`
  mutation Tracker_DeletePath($id: [ID!]) {
    Tracker_DeletePath(id: $id)
  }
`;

const TRACKER_NETWORK_ACCOUNT_RESPONSE = gql`
  fragment Tracker_NetworkAccount on Tracker_NetworkAccount {
    id
    name
    username
    email
    nickname
    affiliate_network {
      id
      name
    }
    # agency {
    #   id
    #   name
    # }
    clickid_key
    custom_key
    custom_key_value
    notes
    offers {
      id
      name
    }
    is_postback_placed
    # payment_profile_id
    # payment_profile_name
    # poc_id
    status
    subid_key
    currency {
      code
      name
      id
      symbol
    }
    tracking_postback_method
    base_url_structure
    cms_domains
  }
`;

export const GQL_TRACKER_NETWORK_ACCOUNTS = gql`
  ${TRACKER_NETWORK_ACCOUNT_RESPONSE}
  query Tracker_NetworkAccounts {
    Tracker_NetworkAccounts {
      message
      status
      data {
        ...Tracker_NetworkAccount
      }
    }
  }
`;

export const GQL_TRACKER_NETWORK_ACCOUNT = gql`
  query Data($id: ID!) {
    Tracker_NetworkAccount(id: $id) {
      data {
        subid_key
        clickid_key
        base_url_structure
      }
    }
  }
`;

const TRACKER_NETWORK_ACCOUNT_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_NetworkAccount on Tracker_NetworkAccount {
    id
    name
  }
`;

export const GQL_TRACKER_NETWORK_ACCOUNTS_FRO_DROPDOWN = gql`
  ${TRACKER_NETWORK_ACCOUNT_RESPONSE_FOR_DROPDOWN}
  query Tracker_NetworkAccounts {
    Tracker_NetworkAccounts {
      message
      status
      data {
        ...Tracker_NetworkAccount
      }
    }
  }
`;

export const GQL_TRACKER_ADD_NETWORK_ACCOUNT = gql`
  ${TRACKER_NETWORK_ACCOUNT_RESPONSE}
  mutation Tracker_CreateNetworkAccount(
    $affiliate_network_id: ID!
    $clickid_key: String
    $custom_key: String
    $custom_key_value: String
    $email: String
    $name: String!
    $nickname: String
    $notes: String
    # $payment_profile_id: ID
    # $payment_profile_name: String
    # $poc_id: ID
    $status: Tracker_Network_Status
    $subid_key: String
    $username: String
    $is_postback_placed: Boolean
    $currency_id: ID
    $base_url_structure: String!
    $tracking_postback_method: TRACKER_POSTBACK_METHOD
    $cms_domains: [Object]
  ) {
    Tracker_CreateNetworkAccount(
      affiliate_network_id: $affiliate_network_id
      clickid_key: $clickid_key
      custom_key: $custom_key
      custom_key_value: $custom_key_value
      email: $email
      name: $name
      nickname: $nickname
      notes: $notes
      # payment_profile_id: $payment_profile_id
      # payment_profile_name: $payment_profile_name
      # poc_id: $poc_id
      status: $status
      subid_key: $subid_key
      username: $username
      is_postback_placed: $is_postback_placed
      currency_id: $currency_id
      tracking_postback_method: $tracking_postback_method
      base_url_structure: $base_url_structure
      cms_domains: $cms_domains
    ) {
      message
      status
      data {
        ...Tracker_NetworkAccount
      }
    }
  }
`;

export const GQL_TRACKER_UPDATE_NETWORK_ACCOUNT = gql`
  ${TRACKER_NETWORK_ACCOUNT_RESPONSE}
  mutation Tracker_UpdateNetworkAccount(
    $affiliate_network_id: ID!
    $clickid_key: String
    $custom_key: String
    $custom_key_value: String
    $email: String
    $id: ID!
    $name: String
    $nickname: String
    $notes: String
    # $payment_profile_id: ID
    # $payment_profile_name: String
    # $poc_id: ID
    $status: Tracker_Network_Status
    $subid_key: String
    $username: String
    $is_postback_placed: Boolean
    $currency_id: ID
    $tracking_postback_method: TRACKER_POSTBACK_METHOD
    $base_url_structure: String!
    $cms_domains: [Object]
  ) {
    Tracker_UpdateNetworkAccount(
      affiliate_network_id: $affiliate_network_id
      clickid_key: $clickid_key
      custom_key: $custom_key
      custom_key_value: $custom_key_value
      email: $email
      id: $id
      name: $name
      nickname: $nickname
      notes: $notes
      # payment_profile_id: $payment_profile_id
      # payment_profile_name: $payment_profile_name
      # poc_id: $poc_id
      status: $status
      subid_key: $subid_key
      username: $username
      is_postback_placed: $is_postback_placed
      currency_id: $currency_id
      tracking_postback_method: $tracking_postback_method
      base_url_structure: $base_url_structure
      cms_domains: $cms_domains
    ) {
      message
      status
      data {
        ...Tracker_NetworkAccount
      }
    }
  }
`;

export const GQL_TRACKER_DELETE_NETWORK_ACCOUNT = gql`
  mutation Tracker_DeleteNetworkAccount($id: [ID!]) {
    Tracker_DeleteNetworkAccount(id: $id)
  }
`;

const TRACKER_TRAFFIC_SOURCE_RESPONSE = gql`
  fragment Tracker_TrafficSource on Tracker_TrafficSource {
    id
    name
    description
    campaigns {
      id
      name
    }
    status
    website
    notes
    is_impression_tracking
    currency {
      id
      name
      code
    }
    trackingTokens {
      name
      token
      placeholder
    }
  }
`;

const TRACKER_TRAFFIC_SOURCE_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_TrafficSource on Tracker_TrafficSource {
    id
    name
    trackingTokens {
      name
      token
      placeholder
    }
  }
`;

export const GQL_TRACKER_PAGED_TRAFFIC_SOURCE = gql`
  ${TRACKER_TRAFFIC_SOURCE_RESPONSE}
  query Tracker_PagedTrafficSources(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedTrafficSources(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      message
      status
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_TrafficSource
      }
    }
  }
`;

export const GQL_TRACKER_TRAFFIC_SOURCE_FOR_DROPDOWN = gql`
  ${TRACKER_TRAFFIC_SOURCE_RESPONSE_FOR_DROPDOWN}
  query Tracker_TrafficSources {
    Tracker_TrafficSources {
      message
      status
      data {
        ...Tracker_TrafficSource
      }
    }
  }
`;

export const GQL_TRACKER_ADD_TRAFFIC_SOURCE = gql`
  ${TRACKER_TRAFFIC_SOURCE_RESPONSE}
  mutation Tracker_CreateTrafficSource(
    $currency_id: ID
    $description: String
    $name: String!
    $notes: String
    $status: Tracker_Network_Status
    $token_params: [Tracker_Token_Params]
    $website: String
  ) {
    Tracker_CreateTrafficSource(
      currency_id: $currency_id
      description: $description
      name: $name
      notes: $notes
      status: $status
      token_params: $token_params
      website: $website
    ) {
      message
      status
      data {
        ...Tracker_TrafficSource
      }
    }
  }
`;

export const GQL_TRACKER_UPDATE_TRAFFIC_SOURCE = gql`
  ${TRACKER_TRAFFIC_SOURCE_RESPONSE}
  mutation Tracker_UpdateTrafficSource(
    $id: ID!
    $currency_id: ID
    $description: String
    $name: String!
    $notes: String
    $status: Tracker_Network_Status
    $token_params: [Tracker_Token_Params]
    $website: String
  ) {
    Tracker_UpdateTrafficSource(
      id: $id
      currency_id: $currency_id
      description: $description
      name: $name
      notes: $notes
      status: $status
      token_params: $token_params
      website: $website
    ) {
      message
      status
      data {
        ...Tracker_TrafficSource
      }
    }
  }
`;

export const GQL_TRACKER_DELETE_TRAFFIC_SOURCE = gql`
  mutation Tracker_DeleteTrafficSource($id: [ID!]) {
    Tracker_DeleteTrafficSource(id: $id)
  }
`;

const TRACKER_TRACKING_TOKEN_RESPONSE = gql`
  fragment Tracker_TrackingToken on Tracker_TrackingToken {
    id
    name
    placeholder
    token
  }
`;

export const GQL_TRACKER_PAGED_TRACKING_TOKEN = gql`
  ${TRACKER_TRACKING_TOKEN_RESPONSE}
  query Tracker_PagedTrackingTokens(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedTrackingTokens(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      message
      status
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_TrackingToken
      }
    }
  }
`;

export const GQL_TRACKER_ADD_TRACKING_TOKEN = gql`
  ${TRACKER_TRACKING_TOKEN_RESPONSE}
  mutation Tracker_CreateTrackingToken(
    $name: String!
    $placeholder: String!
    $token: String!
    $tokeneable_id: ID!
    $tokeneable_type: String!
  ) {
    Tracker_CreateTrackingToken(
      name: $name
      placeholder: $placeholder
      token: $token
      tokeneable_id: $tokeneable_id
      tokeneable_type: $tokeneable_type
    ) {
      message
      status
      data {
        ...Tracker_TrackingToken
      }
    }
  }
`;

export const GQL_TRACKER_UPDATE_TRACKING_TOKEN = gql`
  ${TRACKER_TRACKING_TOKEN_RESPONSE}
  mutation Tracker_UpdateTrackingToken(
    $id: ID!
    $name: String
    $placeholder: String
    $token: String
  ) {
    Tracker_UpdateTrackingToken(id: $id, name: $name, placeholder: $placeholder, token: $token) {
      message
      status
      data {
        ...Tracker_TrackingToken
      }
    }
  }
`;

export const GQL_TRACKER_DELETE_TRACKING_TOKEN = gql`
  mutation Tracker_DeleteTrackingToken($id: [ID!]) {
    Tracker_DeleteTrackingToken(id: $id)
  }
`;

const TRACKER_SUBIDS_RESPONSE = gql`
  fragment Tracker_Subid on Tracker_Subid {
    id
    name
    is_public
  }
`;

export const GQL_TRACKER_PAGED_SUBID = gql`
  ${TRACKER_SUBIDS_RESPONSE}
  query Tracker_PagedSubId(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedSubId(column: $column, order: $order, page: $page, search: $search, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_Subid
      }
    }
  }
`;

export const GQL_TRACKER_SUBIDS = gql`
  query Tracker_Subids {
    Tracker_Subids {
      data {
        id
        name
      }
    }
  }
`;

export const GQL_TRACKER_URL = gql`
  query Tracker_OfferReferrals($offerId: String) {
    Tracker_OfferReferrals(offerId: $offerId) {
      status
      message
      data {
        referralUrl
        id
      }
    }
  }
`;

export const GQL_TRACKER_ADD_SUBID = gql`
  ${TRACKER_SUBIDS_RESPONSE}
  mutation Tracker_CreateSubid($is_public: Boolean!, $name: String!) {
    Tracker_CreateSubid(is_public: $is_public, name: $name) {
      data {
        ...Tracker_Subid
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_UPDATE_SUBID = gql`
  ${TRACKER_SUBIDS_RESPONSE}
  mutation Tracker_UpdateSubid($id: ID!, $is_public: Boolean, $name: String) {
    Tracker_UpdateSubid(id: $id, is_public: $is_public, name: $name) {
      data {
        ...Tracker_Subid
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_DELETE_SUBID = gql`
  mutation Tracker_DeleteSubid($id: [ID!]) {
    Tracker_DeleteSubid(id: $id)
  }
`;

const TRACKER_SUBIDS_GROUP_RESPONSE = gql`
  fragment Tracker_SubidGroup on Tracker_SubidGroup {
    id
    name
    referralsubids {
      id
      name
    }
  }
`;

export const GQL_TRACKER_PAGED_SUBID_GROUP = gql`
  ${TRACKER_SUBIDS_GROUP_RESPONSE}
  query Tracker_PagedSubidgroups(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedSubidgroups(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      data {
        ...Tracker_SubidGroup
      }
    }
  }
`;

export const GQL_TRACKER_ADD_SUBID_GROUP = gql`
  ${TRACKER_SUBIDS_GROUP_RESPONSE}
  mutation Tracker_CreateSubidGroup($name: String!, $subid_id: [ID]) {
    Tracker_CreateSubidGroup(name: $name, subid_id: $subid_id) {
      data {
        ...Tracker_SubidGroup
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_UPDATE_SUBID_GROUP = gql`
  ${TRACKER_SUBIDS_GROUP_RESPONSE}
  mutation Tracker_UpdateSubidGroup($id: ID!, $name: String, $subid_id: [ID]) {
    Tracker_UpdateSubidGroup(id: $id, name: $name, subid_id: $subid_id) {
      data {
        ...Tracker_SubidGroup
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_DELETE_SUBID_GROUP = gql`
  mutation Tracker_DeleteSubidGroup($id: [ID!]) {
    Tracker_DeleteSubidGroup(id: $id)
  }
`;

// ## Offers  ---------------------------------------

const TRACKER_OFFER_RESPONSE = gql`
  fragment Tracker_Offer on Tracker_Offer {
    id
    name
    advertiser {
      id
      name
    }
    category {
      id
      name
    }
    country {
      id
      iso_code
      name
    }
    # currency {
    #   code
    #   name
    #   id
    #   symbol
    # }
    # domain {
    #   id
    #   name
    # }
    network_account {
      id
      name
    }
    notes
    offer_url
    payout_type
    status
    # timezone
    # tracking_postback_method
  }
`;

export const GQL_TRACKER_PAGED_OFFERS = gql`
  ${TRACKER_OFFER_RESPONSE}
  query Tracker_PagedOffers(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
  ) {
    Tracker_PagedOffers(column: $column, order: $order, page: $page, search: $search, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      message
      status
      data {
        ...Tracker_Offer
      }
    }
  }
`;

const TRACKER_OFFER_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_Offer on Tracker_Offer {
    id
    name
  }
`;

export const GQL_TRACKER_PAGED_OFFERS_FOR_DROPDOWN = gql`
  ${TRACKER_OFFER_RESPONSE_FOR_DROPDOWN}
  query Tracker_Offers {
    Tracker_Offers {
      message
      status
      data {
        ...Tracker_Offer
      }
    }
  }
`;

export const GQL_TRACKER_ADD_OFFER = gql`
  ${TRACKER_OFFER_RESPONSE}
  mutation Tracker_CreateOffer(
    $advertiser_id: ID!
    $country_id: ID
    # $currency_id: ID
    # $domain_id: ID
    $name: String!
    $network_account_id: ID!
    $notes: String
    $offer_url: String
    $payout_type: Tracker_Payout_Type
    $status: Tracker_Network_Status # $timezone: String # $tracking_postback_method: TRACKER_POSTBACK_METHOD
  ) {
    Tracker_CreateOffer(
      advertiser_id: $advertiser_id
      country_id: $country_id
      # currency_id: $currency_id
      # domain_id: $domain_id
      name: $name
      network_account_id: $network_account_id
      notes: $notes
      offer_url: $offer_url
      payout_type: $payout_type
      status: $status # timezone: $timezone # tracking_postback_method: $tracking_postback_method
    ) {
      data {
        ...Tracker_Offer
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_UPDATE_OFFER = gql`
  ${TRACKER_OFFER_RESPONSE}
  mutation Tracker_UpdateOffer(
    $country_id: ID
    # $currency_id: ID
    # $domain_id: ID
    $id: ID!
    $name: String
    $notes: String
    $offer_url: String
    $payout_type: Tracker_Payout_Type
    $status: Tracker_Network_Status # $timezone: String # $tracking_postback_method: TRACKER_POSTBACK_METHOD
  ) {
    Tracker_UpdateOffer(
      country_id: $country_id
      # currency_id: $currency_id
      # domain_id: $domain_id
      id: $id
      name: $name
      notes: $notes
      offer_url: $offer_url
      payout_type: $payout_type
      status: $status # timezone: $timezone # tracking_postback_method: $tracking_postback_method
    ) {
      data {
        ...Tracker_Offer
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_DELETE_OFFER = gql`
  mutation Tracker_DeleteOffer($id: [ID!]) {
    Tracker_DeleteOffer(id: $id)
  }
`;

const TRACKER_OFFER_URL_TOKENS_RESPONSE = gql`
  fragment Tracker_OfferUrlToken on Tracker_OfferUrlToken {
    id
    name
  }
`;

export const GQL_TRACKER_OFFER_URL_TOKENS = gql`
  ${TRACKER_OFFER_URL_TOKENS_RESPONSE}
  query Tracker_OfferUrlTokens {
    Tracker_OfferUrlTokens {
      message
      status
      data {
        ...Tracker_OfferUrlToken
      }
    }
  }
`;

const TRACKER_CTA_RESPONSE = gql`
  fragment Tracker_CTA on Tracker_CTA {
    id
    name
    offers {
      id
      name
      subidctas {
        id
        name
      }
    }
    offersubids {
      id
      name
    }
    paths {
      id
      name
    }
    subidOffers {
      id
      name
    }
  }
`;

const TRACKER_CTA_RESPONSE_FOR_DROPDOWN = gql`
  fragment Tracker_CTA on Tracker_CTA {
    id
    name
  }
`;

export const GQL_TRACKER_PAGED_CTA = gql`
  ${TRACKER_CTA_RESPONSE}
  query Tracker_PagedCTA($column: String, $order: order, $page: Int, $search: String, $size: Int) {
    Tracker_PagedCTA(column: $column, order: $order, page: $page, search: $search, size: $size) {
      pageInfo {
        totalCount
        totalPages
      }
      message
      status
      data {
        ...Tracker_CTA
      }
    }
  }
`;

export const GQL_TRACKER_CTA_FOR_DROPDOWN = gql`
  ${TRACKER_CTA_RESPONSE_FOR_DROPDOWN}
  query Tracker_CTAs {
    Tracker_CTAs {
      message
      status
      data {
        ...Tracker_CTA
      }
    }
  }
`;

export const GQL_TRACKER_DELETE_CTA = gql`
  mutation Tracker_DeleteCTA($id: [ID!]) {
    Tracker_DeleteCTA(id: $id)
  }
`;

export const GQL_TRACKER_ADD_CTA = gql`
  ${TRACKER_CTA_RESPONSE}
  mutation Tracker_CreateCTA($name: String!, $offersubids: [Tracker_Offersubids], $path_id: [ID]) {
    Tracker_CreateCTA(name: $name, offersubids: $offersubids, path_id: $path_id) {
      data {
        ...Tracker_CTA
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_UPDATE_CTA = gql`
  ${TRACKER_CTA_RESPONSE}
  mutation Tracker_UpdateCTA(
    $id: ID!
    $name: String!
    $offersubids: [Tracker_Offersubids]
    $path_id: [ID]
  ) {
    Tracker_UpdateCTA(id: $id, name: $name, offersubids: $offersubids, path_id: $path_id) {
      data {
        ...Tracker_CTA
      }
      message
      status
    }
  }
`;

export const GQL_TRACKER_BULK_CREATE_TRACKING_TOKEN = gql`
  ${TRACKER_TRACKING_TOKEN_RESPONSE}
  mutation Tracker_BulkCreateTrackingToken($tracking_array: [Tracker_TrackingArgs]) {
    Tracker_BulkCreateTrackingToken(tracking_array: $tracking_array) {
      data {
        ...Tracker_TrackingToken
      }
      message
      status
    }
  }
`;

const TRACKER_CAMPAIGN_RESPONSE = gql`
  fragment Tracker_Campaign on Tracker_Campaign {
    id
    name
    campaign_destination
    country {
      id
      iso_code
      name
    }
    default_redirect_method
    notes
    paths {
      id
      name
    }
    trafficsource {
      id
      name
    }
    transition_type
  }
`;

const TRACKER_CTA_COMPLETE_RESPONSE_FOR_CAMPAIGN = gql`
  fragment Tracker_CTA on Tracker_CTA {
    id
    name
    offers {
      id
      name
      subidctas {
        id
        name
      }
    }
  }
`;
const TRACKER_PATH_COMPLETE_RESPONSE = gql`
  ${TRACKER_CTA_COMPLETE_RESPONSE_FOR_CAMPAIGN}
  fragment Tracker_Path on Tracker_Path {
    ctas {
      ...Tracker_CTA
    }
    id
    is_active
    is_default
    is_weight_optimization
    landers {
      id
      name
      no_of_cta
      # ctas {
      #   ...Tracker_CTA
      # }
    }
    name
    offer_redirect_method
    path_destination
    smart_rotation
    weight
    weight_methods
    weight_optimization
  }
`;

const TRACKER_CAMPAIGN_COMPLETE_RESPONSE = gql`
  ${TRACKER_PATH_COMPLETE_RESPONSE}
  fragment Tracker_Campaign on Tracker_Campaign {
    id
    name
    campaign_destination
    country {
      id
      iso_code
      name
    }
    default_redirect_method
    notes
    paths {
      ...Tracker_Path
    }
    rules {
      id
      name

      conditions
      status
      paths {
        ...Tracker_Path
      }
    }
    traffic_source {
      id
      name
    }
    transition_type
  }
`;
export const GQL_TRACKER_CAMPAIGN = gql`
  query Tracker_Campaign($id: ID!) {
    Tracker_Campaign(id: $id)
  }
`;

export const GQL_TRACKER_PAGED_CAMPAIGN = gql`
  query Tracker_PagedCampaigns(
    $column: String
    $order: order
    $page: Int
    $search: String
    $size: Int
    $type: Tracker_Campaign_Type
  ) {
    Tracker_PagedCampaigns(
      column: $column
      order: $order
      page: $page
      search: $search
      size: $size
      type: $type
    ) {
      pageInfo {
        totalCount
        totalPages
      }
      message
      status
      data
    }
  }
`;

export const GQL_TRACKER_CREATE_CAMPAIGN = gql`
  mutation Tracker_CreateCampaign(
    $campaign_destination: Tracker_Campaign_Destination!
    $country: ID!
    $default_redirect_method: Tracker_Redirect_Method
    $name: String!
    $notes: String
    $paths: [Tracker_Campaign_Path_Params]
    $rules: [Tracker_Campaign_Rule_Params]
    $trafficsource: Tracker_input_trafficsource
    $transition_type: Tracker_Transition_Type
    $type: Tracker_Campaign_Type
  ) {
    Tracker_CreateCampaign(
      campaign_destination: $campaign_destination
      country: $country
      default_redirect_method: $default_redirect_method
      name: $name
      notes: $notes
      paths: $paths
      rules: $rules
      trafficsource: $trafficsource
      transition_type: $transition_type
      type: $type
    )
  }
`;

export const GQL_TRACKER_UPDATE_CAMPAIGN = gql`
  mutation Tracker_UpdateCampaign($data: Object, $type: Tracker_Campaign_Type) {
    Tracker_UpdateCampaign(data: $data, type: $type)
  }
`;

export const GQL_TRACKER_DELETE_CAMPAIGN = gql`
  mutation Tracker_DeleteCampaign($id: [ID!]) {
    Tracker_DeleteCampaign(id: $id)
  }
`;

export const TRACKER_COUNTRIES_LIST = gql`
  query umsCountries {
    umsCountries {
      id
      iso_code
      name
    }
  }
`;

export const TRACKER_STATES_LIST = gql`
  query umsStates {
    umsStates {
      id
      iso_code
      name
    }
  }
`;

export const CPS_TRACKER_ADVERTISER_LIST = gql`
  query data {
    Tracker_Advertisers {
      data {
        offers {
          is_default
          id
          name
        }
        countries
        states
        id
        name
      }
    }
  }
`;

export const CPS_TRACKER_CREATE_CAMPAIGN = gql`
  mutation Tracker_CreateCampaign(
    $campaign_destination: Tracker_Campaign_Destination!
    $name: String!
    $tracking_domain: ID
    $country: ID
    $default_redirect_method: Tracker_Redirect_Method
    $paths: [Tracker_Campaign_Path_Params]
    $trafficsource: Tracker_input_trafficsource
    $transition_type: Tracker_Transition_Type
    $type: Tracker_Campaign_Type
    $advertiser: ID
  ) {
    Tracker_CreateCampaign(
      campaign_destination: $campaign_destination
      name: $name
      tracking_domain: $tracking_domain
      country: $country
      default_redirect_method: $default_redirect_method
      paths: $paths
      trafficsource: $trafficsource
      transition_type: $transition_type
      type: $type
      advertiser: $advertiser
    )
  }
`;

export const CPS_TRACKER_OFFERS = gql`
  query Tracker_Offers($advertiser_id: String) {
    Tracker_Offers(advertiser_id: $advertiser_id) {
      status
      message
      data {
        id
        name
      }
    }
  }
`;

export const CPS_TRACKER_MAIN_REPORTING = gql`
  query Tracker_Reports($from: Date!, $timezone: String!, $to: Date!, $campaignId: ID) {
    Tracker_Reports(from: $from, timezone: $timezone, to: $to, campaignId: $campaignId) {
      data {
        blocked_clicks
        camapaign_name
        campaign_id
        passed_clicks
      }
      message
      status
    }
  }
`;
