import ActionIconRender from "components/ActionIconRender";
import { useCustomMutation } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { INDUSTRY_INSIGHTS_DOMAIN_FETCH_ALL_DATA } from "shared/gql/gqlSchema/industryInsightsGql";
import { openNotification } from "utils";

function DomainLoading(props) {
  const { id } = props;
  const [fetchAllData, { loading: loading_fetchAllData }] = useCustomMutation(
    INDUSTRY_INSIGHTS_DOMAIN_FETCH_ALL_DATA,
    {
      onCompleted: (e) => {
        const message = e?.ii_fetchAllData;
        openNotification({
          type: "success",
          message: message || "Data is Fetching",
          key: "success",
        });
      },

      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );

  return (
    <div>
      <ActionIconRender
        spin={loading_fetchAllData}
        iconType="reload"
        onClick={() => {
          fetchAllData({ variables: { keydomId: id } });
        }}
      />
    </div>
  );
}

export default DomainLoading;
