import { Form, Select, Input, Button } from "antd";

const { Option } = Select;

function CreateAgencyForm() {
  return (
    <Form layout="vertical">
      <Form.Item label="Company Name">
        <Input placeholder="Enter the name of your company" />
      </Form.Item>
      <div className="flex-between">
        <Form.Item label="Your Role" className="w-48">
          <Select
            placeholder="Select your role"
            // onChange={handleChange}
          >
            <Option value="Developer">Developer</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Team Size" className="w-48">
          <Select
            placeholder="Select your team size"
            // onChange={handleChange}
          >
            <Option value="12">12</Option>
          </Select>
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          type="link"
          block
          style={{ backgroundColor: "#52C41A", color: "#ffffff" }}
          className="light-bold-clr"
        >
          Create Agency
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CreateAgencyForm;
