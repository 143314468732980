import DebugPre from "components/DebugPre";
import DashboardHeader from "components/layouts/DashboardLayout/DashboardHeader";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import { useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NON_REVIEWED_CREATIVES, UPDATE_COMPLIANT_STATUS } from "shared/gql/gqlSchema/leadgenGql";
import { openNotification } from "utils";
import { LGComplianceContext } from "../Context";
import LGComplianceList from "./LGComplianceList";

export default function LGCompliance() {
  const [updateIndex, setUpdateIndex] = useState(null);
  const [updateObject, setUpdateObject] = useState("");
  const [mainData, setMainData] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [pagination, setPagination] = useState({ page: 0, size: 52 });
  const selectedWorkspace = useSelector((state) => state?.userSettings?.selectedWorkspace);
  const { data, loading, error, refetch } = useCustomQuery(NON_REVIEWED_CREATIVES, {
    notifyOnNetworkStatusChange: true,
    variables: { page: pagination?.page, size: pagination?.size, workspaceId: selectedWorkspace },
    onCompleted: (e) => {
      const elemnet = e?.nonReviewedCreatives?.data;

      if (elemnet) {
        setMainData(elemnet);
        setHasNext(e?.nonReviewedCreatives?.hasNext);
      }
    },
    onError: (e) => {
      setMainData([]);
      setHasNext(false);
      openNotification({ type: "error", message: e?.message });
    },
  });

  const [updateCompliantStatus, { loading: loading_UPDATE_COMPLIANT_STATUS }] = useCustomMutation(
    UPDATE_COMPLIANT_STATUS,
    {
      onCompleted: (e) => {
        const element = e?.updateCompliantStatus;

        if (element?.success) {
          const dataCopy = JSON.parse(JSON.stringify(mainData));

          const currentData = dataCopy[updateIndex];
          const currentObjectData = currentData?.[updateObject];

          dataCopy[updateIndex] = {
            ...dataCopy[updateIndex],
            [updateObject]: {
              ...currentObjectData,
              complianceStatus: element?.complianceStatus,
            },
          };

          setMainData(dataCopy);
        }
        openNotification({
          type: element?.success ? "success" : "error",
          message: element?.message,
        });
      },
      onError(e) {
        setUpdateIndex(null);
        setUpdateObject("");
        openNotification({ type: "error", message: e.message });
      },
    }
  );

  const debugContent = [{ isRow: true, contentObjects: [{ loading, data }, { mainData }] }];

  const PAGE_TYPE_BACK = "back";
  const PAGE_TYPE_NEXT = "next";

  function handlePage(type) {
    if (type === PAGE_TYPE_BACK) {
      setPagination((e) => ({ ...e, page: e?.page - 1 }));
      return null;
    }
    if (type === PAGE_TYPE_NEXT) {
      setPagination((e) => ({ ...e, page: e?.page + 1 }));
      return null;
    }
    setPagination((e) => ({ ...e, page: type }));
  }

  const paginationNumbers = Array.from(Array(pagination.page).keys());

  return (
    <>
      <DashboardHeader title="Compliance" />
      <div className="back-next-pagination">
        <LookButton
          disabled={pagination.page === 0 || loading}
          onClick={() => handlePage(PAGE_TYPE_BACK)}
        >
          Back
        </LookButton>
        {paginationNumbers.length > 0 &&
          paginationNumbers.map((data) => (
            <LookButton onClick={() => handlePage(data)}>{data + 1}</LookButton>
          ))}
        <LookButton
          disabled={!hasNext || loading}
          danger
          onClick={() => handlePage(PAGE_TYPE_NEXT)}
        >
          Next
        </LookButton>
      </div>
      {loading_UPDATE_COMPLIANT_STATUS && <Loader fullScreen />}
      <div className="overflow-auto" style={{ maxHeight: "calc(100vh - 100px)" }}>
        <LGComplianceContext.Provider
          value={{ mainData, updateCompliantStatus, setUpdateIndex, setUpdateObject }}
        >
          {loading ? <Loader fullScreen /> : <LGComplianceList />}
        </LGComplianceContext.Provider>
      </div>
      <DebugPre
        content={[
          {
            isRow: true,
            contentObjects: [{ updateObject, updateIndex, hasNext, pagination, paginationNumbers }],
          },
        ]}
      />
      <DebugPre content={debugContent} />
    </>
  );
}
