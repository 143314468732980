import { USER_SETTING_CCUSTOM_COLUMNS_SET } from "actions/actionTypes";
import { Col, Dropdown, Form, Row, Switch, Tooltip } from "antd";
import LookButton from "components/LookButton";
import { useLookModal } from "components/LookModal";
import { useTableContext } from "components/LookTable/fields";
import LookTextInput from "components/forms/LookTextInput";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { deepClone } from "utils";

function CustomColumnForm(props) {
  const { customColumnDefaultConfigKey, closeModal = () => {} } = props;
  const { initialColumns, setColumns } = useTableContext();
  const [inputs, setInputs] = useState({ name: "" });
  const [mainColumn, setMainColumn] = useState(initialColumns);

  const { customColumns } = useSelector((state) => state?.userSettings) || {};

  const { [customColumnDefaultConfigKey]: currentCustomColumns } = customColumns || {};
  const { name } = inputs;
  const dispatch = useDispatch();

  function handleInputs({ name, value }) {
    setInputs({ ...inputs, [name]: value });
  }

  function handleTextInput(e) {
    handleInputs({ name: e.target.name, value: e.target.value });
  }

  function createCustomColumnSet() {
    const { customColumnData = [] } = currentCustomColumns || {};

    const customColumnConfig = {
      ...currentCustomColumns,
      activeIndex: customColumnData?.length,
      customColumnData: [...customColumnData, { name, columnList: mainColumn }],
    };

    dispatch({
      type: USER_SETTING_CCUSTOM_COLUMNS_SET,
      payload: { ...customColumns, [customColumnDefaultConfigKey]: customColumnConfig },
    });
    setColumns(deepClone(mainColumn));
    closeModal();
  }

  function onSubmit() {
    if (name) {
      createCustomColumnSet();
    }
  }
  const handelCustomHidden = (index, value) => {
    let temp = deepClone(mainColumn);
    temp[index] = { ...temp[index], hidden: value };
    setMainColumn(temp);
  };

  const renderColumnList = () => (
    <div className="column-list">
      {mainColumn?.map(({ title, hidden = false, fixed }, index) => (
        <div>
          <div className="customColumItem">
            <p>{title}</p>
            <Switch
              size="small"
              checked={fixed && fixed === "left" ? true : !hidden}
              disabled={fixed && fixed === "left"}
              onClick={() => handelCustomHidden(index, !hidden)}
            />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="custom-column-form">
      <Form layout="vertical">
        <LookTextInput
          className="mb-12"
          label="Set Name"
          name="name"
          placeholder="Enter name"
          value={name}
          onChange={handleTextInput}
        />
        {renderColumnList()}
        <Row gutter={12}>
          <Col span={12}>
            <LookButton block onClick={closeModal}>
              Cancel
            </LookButton>
          </Col>
          <Col span={12}>
            <LookButton block type="primary" disabled={!name} onClick={onSubmit}>
              Submit
            </LookButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export function useGetCustomColumnData(customColumnDefaultConfigKey) {
  const { customColumns } = useSelector((state) => state?.userSettings) || {};
  const { [customColumnDefaultConfigKey]: currentCustomColumns } = customColumns || {};
  const { customColumnData = [], activeIndex = -1 } = currentCustomColumns || {};
  const dispatch = useDispatch();
  const updateCurrentCustomColumn = (index, columnData) => {
    const { customColumnData = [] } = currentCustomColumns || {};

    let temp = deepClone(customColumnData);
    temp[index].columnList = columnData;

    const customColumnConfig = { ...currentCustomColumns, customColumnData: temp };
    dispatch({
      type: USER_SETTING_CCUSTOM_COLUMNS_SET,
      payload: { ...customColumns, [customColumnDefaultConfigKey]: customColumnConfig },
    });
  };
  return { customColumnData, customColumnActiveIndex: activeIndex, updateCurrentCustomColumn };
}

export default function useCustomColumn(props = {}) {
  const { customColumnDefaultConfigKey } = props;
  const { setColumns, defaultColumn, initialColumns } = useTableContext();
  const { modal, visibleModal, openModal, closeModal } = useLookModal({
    title: "Create new set",
    width: 418,
  });
  const { customColumns } = useSelector((state) => state?.userSettings) || {};

  const { [customColumnDefaultConfigKey]: currentCustomColumns } = customColumns || {};

  const dispatch = useDispatch();

  const setSelectedColumnSet = (index = -1) => {
    const customColumnConfig = { ...currentCustomColumns, activeIndex: parseInt(index) };

    dispatch({
      type: USER_SETTING_CCUSTOM_COLUMNS_SET,
      payload: { ...customColumns, [customColumnDefaultConfigKey]: customColumnConfig },
    });
  };

  function parseJson(element = []) {
    const fechedData = element;
    const newFinal = [...fechedData]?.filter(
      ({ dataIndex }) => initialColumns?.filter((ii) => ii.dataIndex === dataIndex).length > 0
    );

    newFinal.forEach((nn, index) => {
      const filtePreviousObject = initialColumns?.filter((ii) => ii?.dataIndex === nn.dataIndex);
      const { hidden = false } = newFinal[index];
      newFinal[index] = { ...filtePreviousObject[0], hidden };
    });

    const pendingData = initialColumns?.filter(
      (p) => !(newFinal?.filter((nn) => nn.dataIndex === p.dataIndex).length > 0)
    );

    const merged = [
      ...newFinal?.filter(({ dataIndex }) => dataIndex?.toLowerCase() !== "action"),
      ...pendingData,
      ...newFinal?.filter(({ dataIndex }) => dataIndex?.toLowerCase() === "action"),
    ];
    return merged;
  }

  const renderColumnSetShift = () => {
    const { customColumnData = [], activeIndex = -1 } = currentCustomColumns || {};
    const items = [
      { label: "Default", key: -1 },
      ...customColumnData?.map(({ name }, index) => ({ label: name, key: index })),
    ];
    const currentActiveIndex = activeIndex;
    const onClick = ({ key }) => {
      const currentIndex = parseInt(key);
      setSelectedColumnSet(key);
      if (currentIndex === -1) {
        // here set from BE config

        setColumns(deepClone(defaultColumn));
      } else {
        setColumns(parseJson(customColumnData[currentIndex].columnList));
      }
    };
    const activeName =
      currentActiveIndex === -1 ? "Default" : customColumnData[currentActiveIndex]?.name;
    return (
      <Dropdown
        className="customColumTrigger"
        overlayClassName="customColumOverlay"
        menu={{ items, onClick }}
      >
        <Tooltip title={activeName}>
          <a onClick={(e) => e.preventDefault()}>
            <span>Column Set: </span>
            {activeName}
          </a>
        </Tooltip>
      </Dropdown>
    );
  };
  const renderCustomColumnModal = () => (
    <>
      {modal(
        visibleModal && (
          <CustomColumnForm
            closeModal={closeModal}
            customColumnDefaultConfigKey={customColumnDefaultConfigKey}
          />
        )
      )}
    </>
  );
  return { renderCustomColumnModal, callCustomColumn: openModal, renderColumnSetShift };
}
