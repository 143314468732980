import { Button, Col, Form } from "antd";
import ActionIconRender from "components/ActionIconRender";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import LookButton from "components/LookButton";
import React from "react";

function ColumnFilterModal(props) {
  const { columns, setAppliedColumnFilter = () => {}, appliedColumnFilters = [] } = props;

  const addNewFilter = () => {
    setAppliedColumnFilter((prev) => ({
      ...prev,
      filterColumns: [...prev?.filterColumns, { selectedColumn: "" }],
    }));
  };
  const numberFilterTypeColumns = [
    { name: "> (Greater than)", value: ">" },
    { name: "< (Less than)", value: "<" },
    { name: ">= (Greater equals)", value: ">=" },
    { name: "<= (Less equals)", value: "<=" },
    { name: "Customs Range", value: "range" },
  ];
  const handleFilter = (key, value, index) => {
    setAppliedColumnFilter((prev) => {
      const updatedFilterColumns = [
        ...prev.filterColumns.slice(0, index),
        { ...prev.filterColumns?.[index], [key]: value },
        ...prev.filterColumns.slice(index + 1),
      ];

      return { ...prev, filterColumns: updatedFilterColumns };
    });
  };
  return (
    <div>
      <Form layout="vertical">
        {appliedColumnFilters?.filterColumns?.map((data, index) => {
          return (
            <div className="d-flex mb-12">
              <div style={{ width: 200 }}>
                <Col>
                  <SelectInput
                    name="filterColumns"
                    options={columns?.map((data) => ({
                      name: data?.title,
                      value: data?.dataIndex,
                    }))}
                    value={data?.selectedColumn}
                    placeholder="Select Columns"
                    onChange={(e) =>
                      setAppliedColumnFilter((prev) => {
                        const matchingData = columns?.find((data) => data?.dataIndex === e);
                        const updatedFilterColumns = [
                          ...prev.filterColumns.slice(0, index),
                          { selectedColumn: e, searchType: matchingData?.searchType },
                          ...prev.filterColumns.slice(index + 1),
                        ];

                        return { ...prev, filterColumns: updatedFilterColumns };
                      })
                    }
                  />
                </Col>
              </div>
              {data?.selectedColumn && (
                <div style={{ width: 200 }}>
                  <Col>
                    <SelectInput
                      name="numberFilterType"
                      placeholder="Select operation"
                      options={numberFilterTypeColumns}
                      value={data?.numberFilterType}
                      onChange={(e) => handleFilter("numberFilterType", e, index)}
                    />
                  </Col>
                </div>
              )}
              {data?.numberFilterType &&
                (data?.numberFilterType === "range" ? (
                  <>
                    <Col>
                      <LookTextInput
                        name="numberFilterRangeMin"
                        className="mb-0"
                        type="number"
                        placeholder="Min no."
                        value={data?.numberFilterRangeMin}
                        onChange={(e) =>
                          handleFilter("numberFilterRangeMin", e.target.value, index)
                        }
                      />
                    </Col>
                    <Col>
                      <LookTextInput
                        name="numberFilterRangeMax"
                        className="mb-0"
                        type="number"
                        placeholder="Max no."
                        value={data?.numberFilterRangeMax}
                        onChange={(e) =>
                          handleFilter("numberFilterRangeMax", e.target.value, index)
                        }
                      />
                    </Col>
                  </>
                ) : (
                  <Col>
                    <LookTextInput
                      name="numberFilterValue"
                      type="number"
                      value={data?.numberFilterValue}
                      onChange={(e) => handleFilter("numberFilterValue", e.target.value, index)}
                    />
                  </Col>
                ))}
              <LookButton
                iconType="delete"
                onClick={() =>
                  //change this delete fun
                  setAppliedColumnFilter((prev) => {
                    const updatedFilterColumns = prev.filterColumns?.filter(
                      (data, dataIndex) => dataIndex !== index
                    );

                    return { ...prev, filterColumns: updatedFilterColumns };
                  })
                }
              />
            </div>
          );
        })}
      </Form>
      <Button onClick={() => addNewFilter()} size="small" className="ml-10">
        <ActionIconRender iconType="plus" /> Add New
      </Button>
    </div>
  );
}

export default ColumnFilterModal;
