import cx from "classnames";
import { DotIcon } from "components/svgComponents";

const statusTypeDefault = [
  { status: 0, statusText: "Active", colorClassName: "text-primary" },
  { status: 1, statusText: "In Active", colorClassName: "text-danger" },
];

export default function LookTableColumnStatus(props) {
  const { status, statusType = statusTypeDefault } = props;
  const renderStatsData = statusType?.filter(
    (statusTypeProps) => status === statusTypeProps.status
  );

  return (
    <div>
      {renderStatsData.length > 0 ? (
        <>
          <DotIcon className={cx("mr-8", renderStatsData[0]?.colorClassName)} />
          {renderStatsData[0]?.statusText}
        </>
      ) : (
        "-"
      )}
    </div>
  );
}
