import { PicLeftOutlined, RocketOutlined } from "@ant-design/icons";
import ActionIconRender from "components/ActionIconRender";

export default function useLaunchpathsObject(props = {}) {
  const { permission = "" } = props;
  const launchpathsObject = {
    subMenuName: "Launch",
    subMenuIcon: RocketOutlined,
    permission,
    isCollapsible: true,
    subMenus: [
      {
        subMenuName: "Bucket",
        subMenuLink: "/launch/bucket",
        subMenuIcon: () => <ActionIconRender iconType="notification" />,
        permission,
      },
      {
        subMenuName: "Dashboard",
        subMenuLink: "/launch/launch-dashboard",
        subMenuIcon: PicLeftOutlined,
        permission,
      },
      {
        subMenuName: "Targeting",
        subMenuLink: "/launch/target",
        subMenuIcon: () => <ActionIconRender iconType="launchTargeting" />,
        permission,
      },
      {
        subMenuName: "List",
        subMenuLink: "/launch/list",
        subMenuIcon: () => <ActionIconRender iconType="launchList" />,
        permission,
      },
      {
        subMenuName: "Clone List",
        subMenuLink: "/launch/clone-list",
        subMenuIcon: () => <ActionIconRender iconType="duplicate" />,
        permission,
      },
      {
        subMenuName: "FAQ",
        subMenuLink: "/launch/faq",
        subMenuIcon: () => <ActionIconRender iconType="duplicate" />,
        permission,
      },
    ],
  };
  return { launchpathsObject };
}
