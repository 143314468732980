import { Row, Col, Image } from "antd";
import check from "../../../../assets/images/check.svg";
import PrivacyTermsCall from "../../../../components/privacyTermsCall";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo-light.svg";
import flagLogo from "assets/images/flag-name.png";
import cx from "classnames";
import useTheme from "../../../../shared/Theme/useTheme";
import Logout from "../../../../shared/logout";

function StepCard(props) {
  const themeLight = useTheme();
  const { title, dec, count = 1, currentStep = 1 } = props;

  const circleStyle =
    currentStep > count
      ? { backgroundColor: "#ffffff", padding: "6px 10px" }
      : themeLight
      ? {}
      : { backgroundColor: "#3C8618", borderColor: "#3C8618" };

  return (
    <>
      {count !== 1 && <div className="vertical-line" />}
      <div className="d-flex">
        <div className="circle-div" style={circleStyle}>
          {currentStep > count ? <Image src={check} /> : count}
        </div>
        <div className="text-left">
          <p
            className="mb-0 bold-clr"
            style={{ color: themeLight ? "#389E0D" : "#595959", fontSize: "16px" }}
          >
            {title}
          </p>
          <p className="mb-0 white-clr" style={themeLight ? {} : { color: "#8FD460" }}>
            {dec}
          </p>
        </div>
      </div>
    </>
  );
}

function Wrapper(props) {
  const themeLight = useTheme();
  const { children, step = 1, formTitle = "" } = props;
  const stepsInfo = [
    {
      title: "Create Agency",
      dec: "Enter agency details",
    },
    {
      title: "Create Workspace",
      dec: "Enter workspace details",
    },
    // {
    //   title: "Add User",
    //   dec: "Enter details of a user",
    // },
  ];

  return (
    <Row className="public-form-wrapper-row">
      <Col span={8} className={themeLight ? "bg-clr" : "bg-dark-clr"}>
        <div className={cx("p-100-75", themeLight ? "imgdiv" : "imgDarkdiv")}>
          <div className="text-left">
            {/* <Link to="/"> */}
            <img className="form-logo" src={logo} alt="logo" />
            {/* </Link> */}
          </div>
          <div className="vertical-center" style={{ marginLeft: "-3px" }}>
            {stepsInfo.map((data, index) => (
              <StepCard key={index} {...data} count={index + 1} currentStep={step} />
            ))}
            <div className="mt-12">
              <Logout className="ant-btn-primary" />
            </div>
          </div>
        </div>
      </Col>
      <Col span={16} className={cx({ "dark-mode": !themeLight })}>
        <div className="p-50-100" style={{ paddingRight: "200px" }}>
          <div className="ta-left vertical-center" style={{ right: "200px", left: "100px" }}>
            <h3 style={{ color: "#389E0D" }}>Step {step}</h3>
            {formTitle && <h1 className={cx({ "white-clr": !themeLight })}>{formTitle}</h1>}
            {children}
          </div>
          <PrivacyTermsCall style={{ right: "200px" }} />
        </div>
      </Col>
    </Row>
  );
}
export default Wrapper;
