import AdSetReport from "../../AdSets";
import AdsReport from "../../Ads";
import CampaignReport from "../../Campaigns";
import LookDrawer from "components/LookDrawer";
import LookDrawerMainSideBar from "components/LookDrawer/LookDrawerMainSideBar";
import ActionIconRender from "components/ActionIconRender";
import {
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2,
  USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3,
} from "actions/actionTypes";
import { Tooltip } from "antd";

export default function BulkDetailView(props) {
  const { visible = false, onClose, bulkProps = [] } = props;

  return (
    <LookDrawer
      className="no-body-space"
      size={1540}
      visible={visible}
      onClose={onClose}
      title={
        <Tooltip
          title={
            <ul className="mt-10">
              {bulkProps?.map(({ adAccountName, ind }) => (
                <li key={ind}>{adAccountName}</li>
              ))}
            </ul>
          }
        >
          <span>Ad account Detailed Report</span>
        </Tooltip>
      }
    >
      {visible && (
        <LookDrawerMainSideBar
          tabContent={[
            {
              hoverTitle: "Campaign Report",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <CampaignReport
                  isBulkAdAccountFiltered
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_1}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Ad Set Report",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <AdSetReport
                  isBulkAdAccountFiltered
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_2}
                  {...props}
                />
              ),
              noSpace: true,
            },
            {
              hoverTitle: "Ads Report",
              icon: () => <ActionIconRender iconType="notification" />,
              component: (
                <AdsReport
                  isBulkAdAccountFiltered
                  performance
                  persistKey={USER_SETTING_TABLE_PERSIST_KEY_LEVEL_3}
                  {...props}
                />
              ),
              noSpace: true,
            },
          ]}
        />
      )}
    </LookDrawer>
  );
}
