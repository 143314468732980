import { Col, Image, Row } from "antd";
import ActionIconRender from "components/ActionIconRender";
import CardCreativeAdGroup from "components/LookCards/LaunchCards/CardCreativeAdGroup";
import Loader from "components/loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MoreOutlined } from "@ant-design/icons";
import React from "react";
import PopoverWrapper from "components/popoverWrapper";
import { ADS_TYPE_CARAOUSEL, ADS_TYPE_STATIC, DYNAMIC_ADS_KEY } from "../industryInsightsEnum";
import {
  PLATFORM_TYPE_FACEBOOK,
  PLATFORM_TYPE_INSTAGRAM,
  PLATFORM_TYPE_MESSENGER,
  PLATFORM_TYPE_TIKTOK,
} from "shared/enum/launchKeysAndEnum";
import { CREATIVE_TYPE_IMAGE, CREATIVE_TYPE_VIDEO } from "shared/SharedKeys/launchKeys";
import moment from "moment";
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
};
function useCustomeView(props) {
  const {
    adsData = [],
    loading = false,
    setVisibleDrawer = false,
    setRowProps = () => {},
    adsType = ADS_TYPE_STATIC,
    getActiveDays = () => {},
    setVisibleCategoryModal = () => {},
  } = props;

  const handleCopy = (data) => {
    let tempData = document.createElement("textarea");
    tempData.value = data;
    document.body.appendChild(tempData);
    tempData.select();
    document.execCommand("copy");
    document.body.removeChild(tempData);
  };
  const handleAdDetails = (adsInfo) => {
    setVisibleDrawer(true);
    setRowProps(adsInfo);
  };

  // handle footer of ads
  const renderCardFooter = ({
    adId,
    adDeliveryStopTime = "",
    adDeliveryStartTime = "",
    publisherPlatforms = [],
  }) => {
    const currentDate = moment().format("YYYY-MM-DD");
    const isAdActive = moment(adDeliveryStopTime)?.isSameOrAfter(currentDate, "day");
    const message = !adDeliveryStopTime
      ? `since ${getActiveDays({ adDeliveryStartTime })} days`
      : " ";
    return (
      <div className="d-flex justify-content-between borderAdd">
        <div>
          Ad ID : {adId}
          <p>
            <span className="text-primary">{isAdActive ? "Inactive" : "Active,"}</span> {message}
          </p>
          {/* <p>EU transparency.</p> */}
        </div>
        <div className="icons d-flex">
          {publisherPlatforms?.includes(PLATFORM_TYPE_FACEBOOK) && (
            <ActionIconRender className="icon-bk mr-4" iconType="facebook-outlined" />
          )}
          {publisherPlatforms?.includes(PLATFORM_TYPE_TIKTOK) && (
            <ActionIconRender className="icon-bk mr-4" iconType="tiktok" />
          )}
          {publisherPlatforms?.includes(PLATFORM_TYPE_INSTAGRAM) && (
            <ActionIconRender className="icon-bk mr-4" iconType="instagram" />
          )}
          {publisherPlatforms?.includes(PLATFORM_TYPE_MESSENGER) && (
            <ActionIconRender className="icon-bk mr-4" iconType="message" />
          )}
        </div>
      </div>
    );
  };

  // handle static ads view
  const staticView = ({
    title,
    body,
    description,
    image,
    video,
    poverWrapperOptions,
    adsInfo,
    ctaType,
    adPage,
    ctaLink,
    adDomain = [],
  }) => {
    return (
      // to do this temp solution flex-grow added will change later
      <div className="creative-report-card creative-ii flex-grow-1">
        <div className="position-relative">
          <div className="creative-report-card-image">
            <CardCreativeAdGroup
              title={title}
              body={body}
              description={description}
              image={image}
              video={video}
              isGroup
              ctaType={ctaType}
              selectedCallback={() => handleAdDetails(adsInfo)}
              groupType={adsType}
              onlyContent
              ctaLink={ctaLink}
              adPage={adPage}
              isIndustryInsights
              adDomain={adDomain?.[0]}
              poverWrapperOptions={poverWrapperOptions}
            />
          </div>
        </div>
      </div>
    );
  };
  // handle dynamic or caraousel ads view
  const dynamicView = ({ adCreative, poverWrapperOptions, adsInfo, adPage }) => {
    return (
      <>
        <div className="creative-report-card creative-ii flex-grow-1">
          <div className="position-relative">
            <div className="creative-report-card-image">
              <div className="p-10 w-100">
                <div className="d-flex flex-grow-1">
                  <div className="d-flex flex-grow-1 ">
                    <div>
                      <Image
                        src={adPage?.pageProfilePictureUrl}
                        preview={false}
                        style={{ width: 40, height: 40, borderRadius: 6 }}
                      />
                    </div>
                    <div className="flex-grow-1 ml-10">
                      <h3 className="mb-0">{adPage?.name}</h3>
                      <div> {adsType} </div>
                    </div>
                    <div className="ml-5">
                      <PopoverWrapper options={poverWrapperOptions}>
                        <MoreOutlined className="pop-info" />
                      </PopoverWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Slider {...settings}>
            {adCreative?.map((adDetails, poverWrapperOptions) => {
              const title = { title: adDetails?.title?.value };
              const body = { body: adDetails?.body?.value };
              const image = { s3Url: adDetails?.images?.[0]?.url };
              const video = {
                s3Url: adDetails?.videos?.[0]?.url,
                thumbUrl: adDetails?.videos?.[0]?.thumbnail?.url,
              };
              const ctaType = adDetails?.ctaType;
              const description = { description: adDetails?.linkDescription?.value };
              return (
                <>
                  <CardCreativeAdGroup
                    title={title}
                    isDynamic
                    body={body}
                    description={description}
                    image={image}
                    isGroup
                    ctaLink={adDetails?.linkUrl}
                    video={video}
                    ctaType={ctaType}
                    isIndustryInsights
                    selectedCallback={() => handleAdDetails(adsInfo)}
                    onlyContent
                    poverWrapperOptions={poverWrapperOptions}
                    // hoverContent={hoverContent}
                  />
                </>
              );
            })}
          </Slider>
        </div>
      </>
    );
  };
  // handle multi images ads view
  const multiImagesView = ({
    title,
    body,
    description,
    multiImages,
    poverWrapperOptions,
    multiVideos,
    adPage,
    ctaLink,
  }) => {
    return (
      <div>
        <div className="creative-report-card creative-ii flex-grow-1">
          <div className="position-relative">
            <div className="creative-report-card-image">
              <CardCreativeAdGroup
                title={title}
                body={body}
                description={description}
                isGroup
                isMultiCreatives
                multiImages={multiImages}
                multiVideos={multiVideos}
                groupType={adsType}
                isIndustryInsights
                ctaLink={ctaLink}
                onlyContent
                adPage={adPage}
                poverWrapperOptions={poverWrapperOptions}
                // hoverContent={hoverContent}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const customeView = loading ? (
    <Loader />
  ) : adsData?.length > 0 ? (
    <div className="p-20">
      <Row gutter={[15, 15]}>
        {adsData?.map((adsInfo) => {
          const adDetails = adsInfo?.adCreative?.[0];
          const adCreativeType = adsInfo?.adCreativeType;
          const title = { title: adDetails?.title?.value };
          const body = { body: adsInfo?.adBodies?.[0]?.value };
          const image = { s3Url: adDetails?.images?.[0]?.url };
          const ctaType = adDetails?.ctaType;
          const {
            adDeliveryStopTime = "",
            adDeliveryStartTime = "",
            publisherPlatforms = [],
            adId = "",
            adPage = {},
            adDomain = [],
          } = adsInfo;
          const multiImages = adDetails?.images?.map((data) => ({
            s3Url: data?.url || "",
          }));
          const multiVideos = adDetails?.videos?.map((data) => ({
            s3Url: data?.url,
            thumbUrl: adDetails?.videos?.[0]?.thumbnail?.url,
          }));
          const video = {
            s3Url: adDetails?.videos?.[0]?.url || "",
            thumbUrl: adDetails?.videos?.[0]?.thumbnail?.url || "",
          };
          const description = { description: adDetails?.linkDescription?.value };
          const poverWrapperOptions = [
            ...(adsType === ADS_TYPE_STATIC
              ? [
                  {
                    option: "edit",
                    title: "Save Creative",
                    optionProps: {
                      onClick: () => {
                        setVisibleCategoryModal(true);
                        setRowProps(adsInfo);
                      },
                    },
                  },
                ]
              : []),
            {
              option: "link",
              title: "Copy Ad Link",
              optionProps: {
                onClick: () => {
                  handleCopy(adsInfo?.adSnapshotUrl);
                },
              },
            },
            {
              option: "copy",
              title: "Copy Ad Id",
              optionProps: {
                onClick: () => {
                  handleCopy(adsInfo?.adId);
                },
              },
            },
            {
              option: "file-search",
              title: "Detailed View",
              optionProps: {
                onClick: () => {
                  handleAdDetails(adsInfo);
                },
              },
            },
          ];

          // handle which ads to be displayed on the basis of adstype
          const handleDisplayView = () => {
            if (
              adCreativeType === CREATIVE_TYPE_IMAGE?.toLowerCase() ||
              adCreativeType === CREATIVE_TYPE_VIDEO?.toLowerCase()
            ) {
              return staticView({
                title,
                body,
                description,
                image,
                video,
                poverWrapperOptions,
                adsInfo,
                ctaType,
                adPage,
                ctaLink: adDetails?.linkUrl,
                adDomain,
              });
            } else if (
              adCreativeType === DYNAMIC_ADS_KEY ||
              adCreativeType === ADS_TYPE_CARAOUSEL
            ) {
              return dynamicView({
                adCreative: adsInfo?.adCreative,
                poverWrapperOptions,
                adsInfo,
                adPage,
              });
            } else {
              return multiImagesView({
                multiImages,
                multiVideos,
                title,
                body,
                description,
                image,
                poverWrapperOptions,
                adsInfo,
                adPage,
                ctaLink: adDetails?.linkUrl,
              });
            }
          };
          return (
            <Col xs={24} md={12} lg={8} xl={8} xxl={6}>
              <div className="d-flex h-100" style={{ flexDirection: "column" }}>
                {handleDisplayView()}
                {renderCardFooter({
                  adId,
                  adDeliveryStopTime,
                  adDeliveryStartTime,
                  publisherPlatforms,
                })}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  ) : (
    <div className="text-center pt-100">
      <div style={{ transform: "translateX(20px)" }}>
        <ActionIconRender iconType="tablePlaceholder" />
      </div>
      <p style={{ fontSize: "1.5rem" }}>
        <b>No Data Found...</b>
      </p>
    </div>
  );
  return { customeView };
}
export default useCustomeView;
