import { createContext } from "react";
import Pool from "../UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { useDispatch } from "react-redux";
import {
  CLEAR_USER_SETTINGS,
  CMS_SETTINGS_CLEAR,
  F360_CONFIG_CLEAR,
  FLUSH_SLACK_CHANNEL_DATA,
  UNICORN_CLEAR_SETTINGS,
  USER_LOGOUT,
  WORKSPACE_PRODUCTS_DATA_CLEAR,
} from "../../actions/actionTypes";

const AccountContext = createContext();

function Account(props) {
  const { children } = props;
  const dispatch = useDispatch();

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      // const RefreshToken = new CognitoRefreshToken({ RefreshToken: user.tokens.refreshToken });
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject(err);
          } else {
            await resolve({ user, ...session });
            // resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  const getAttributes = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                resolve(attributes);
              }
            });
          }
        });
      } else {
        reject();
      }
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: WORKSPACE_PRODUCTS_DATA_CLEAR });
      dispatch({ type: CLEAR_USER_SETTINGS });
      dispatch({ type: UNICORN_CLEAR_SETTINGS });

      user.globalSignOut({
        onFailure: (e) => {
          // console.log(e);
        },
        onSuccess: (r) => {
          // console.log("Logout success" + r);
        },
      });
      user.signOut();
    }
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
  };
  return (
    <AccountContext.Provider value={{ authenticate, getSession, getAttributes, logout }}>
      {children}
    </AccountContext.Provider>
  );
}
export { Account, AccountContext };
