import { Form, Switch } from "antd";
import LookTextInput from "components/forms/LookTextInput";
import SelectInput from "components/forms/SelectInput";
import { useGoogleLaunchV1Context } from "../../Context";
import { BIDDING_STRATEGY_ENUM } from "shared/enum/launchKeysAndEnum";
import {
  googleLaunchManualBiddingStrategy,
  googleLaunchManualBiddingStrategyEmum,
} from "../../googleLaunchConfig";
import { useMemo } from "react";

export default function BiddingBudgetTab() {
  const { handleCampaignLevelData, campaignObject } = useGoogleLaunchV1Context();
  const { amountMicros, enhancedCpcEnabled, biddingStrategy, bids } = campaignObject?.[0] || {};

  const getBidLabel = useMemo(() => {
    if (biddingStrategy !== googleLaunchManualBiddingStrategy) {
      const getIndex = BIDDING_STRATEGY_ENUM.findIndex((e) => e.value === biddingStrategy);
      if (getIndex !== -1) {
        return BIDDING_STRATEGY_ENUM?.[getIndex]?.label;
      }
      return "";
    }
    return "";
  }, [biddingStrategy]);

  const strategyBasedContent = () =>
    biddingStrategy === googleLaunchManualBiddingStrategy ? (
      <div className="d-flex">
        <p className="mb-2 mr-5">Enhanced CPC Enabled (optional)</p>
        <Switch
          checked={enhancedCpcEnabled}
          onChange={(e) => handleCampaignLevelData({ enhancedCpcEnabled: e })}
        />
      </div>
    ) : (
      <>
        <LookTextInput
          number
          label={`Target ${getBidLabel}`}
          name="bids"
          value={bids}
          min={0}
          onChange={(e) => handleCampaignLevelData({ bids: parseInt(e) })}
        />
      </>
    );

  const renderBidding = () => (
    <div className="w-50">
      <h3>Bidding</h3>
      <SelectInput
        label="Bidding strategy"
        name="biddingStrategy"
        value={biddingStrategy}
        onChange={(e) => handleCampaignLevelData({ biddingStrategy: e })}
        options={[...googleLaunchManualBiddingStrategyEmum, ...BIDDING_STRATEGY_ENUM]}
      />
      {strategyBasedContent()}
    </div>
  );

  const renderBudget = () => (
    <div className="w-50">
      <h3>
        Budget <span className="text-danger">*</span>
      </h3>
      <LookTextInput
        number
        name="amountMicros"
        min={0}
        value={amountMicros}
        placeholder="Set your average daily budget for this campaign"
        onChange={(e) => {
          handleCampaignLevelData({
            // multipling with 1000000, at final object generated
            amountMicros: e,
          });
        }}
      />
    </div>
  );
  return (
    <div className="content">
      <Form layout="vertical">
        {renderBidding()}
        {renderBudget()}
      </Form>
    </div>
  );
}
