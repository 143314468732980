import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

function DashboardTabs(props) {
  return (
    <div className="mb-15">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {/* {props?.data?.map((moodboardData) => {
            return (
              <div className="p-10">
                <div style={{ border: "1px solid#303030", borderRadius: "6px", padding: "10px" }}>
                  {moodboardData}
                </div>
              </div>
            );
          })} */}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export default DashboardTabs;
