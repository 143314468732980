import { gql } from "@apollo/client";

export const GET_USER_NOTIFICATION_COUNT = gql`
  query RE_getUserNotication {
    RE_getUserNotication
  }
`;

export const GET_USERS_ALL_NOTIFICATIONS = gql`
  query RE_getNotificationList($page: Int, $size: Int) {
    RE_getNotificationList(page: $page, size: $size) {
      data {
        result {
          service_name
          seen
          payload
          id
          createdAt
        }
        pageInfo
      }
    }
  }
`;

export const MARK_USER_NOTIFICATION_SEEN = gql`
  mutation RE_notificationSeen($notificationId: [ID]!) {
    RE_notificationSeen(notificationId: $notificationId)
  }
`;
