import { Image, Card, Tooltip, Checkbox, Button } from "antd";
import cx from "classnames";
import placeholder from "assets/images/placeholder.png";
import { VsContentLinkBuilder } from "components/forms/LinkBuilderForm";
import LookModal from "components/LookModal";
import { useState } from "react";
import LookButton from "components/LookButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CardViralsparksContent(props) {
  const {
    className = "",
    showDrawerUpdate = () => {},
    showCreativeDrawer = () => {},
    showLaunchDrawer = () => {},
    platFormUrl,
    canManage = false,
    onSelectBulk = () => {},
    selectedRows,
    bulkChecked = false,
    onSelectCreateNewBucket = () => {},
    onSelectPushArticalToBucket = () => {},
    hasCreativeUploadPermission,
    noHoverActions = false,
    ...dataItems
  } = props;

  const {
    image = "",
    title = "",
    language_code = "",
    advertiser,
    brand,
    slug,
    nickname,
    id = "",
  } = dataItems || {};

  const [linkBuilderModalVisible, setLinkBuilderModalVisible] = useState(false);
  const modalProps = {
    visible: linkBuilderModalVisible,
    onCancel: () => setLinkBuilderModalVisible(false),
    onSubmit: () => console.log("ok"),
    okokText: "Link",
    footer: false,
  };
  const history = useHistory();
  const serviceRoute = "vs";
  const debugContent = [{ isRow: true, contentObjects: [{ props }] }];
  const modalFormProps = { advertiser, brand, slug, nickname, language_code, platFormUrl };

  return (
    <>
      <LookModal {...modalProps}>
        {linkBuilderModalVisible && <VsContentLinkBuilder {...modalFormProps} />}
      </LookModal>

      <Card className={cx("card-vs-content theme-parent p-ch-0", className)}>
        <div className="position-relative hover-action-wrapper">
          <div className="position-absolute mt-5 ml-5" style={{ left: 0, zIndex: 2 }}>
            <Checkbox checked={bulkChecked} onClick={onSelectBulk} />
          </div>
          <div className="image-view">
            <Image preview={false} src={image || placeholder} />
          </div>

          {!noHoverActions && (
            <div className="hover-action">
              <div className="d-flex align-items-center">
                <div>
                  <LookButton
                    type="primary"
                    iconType="link"
                    onClick={() => setLinkBuilderModalVisible(true)}
                  >
                    Url
                  </LookButton>
                </div>
                <div>
                  <LookButton type="primary" iconType="plus" onClick={onSelectCreateNewBucket}>
                    Create Bucket
                  </LookButton>
                  <LookButton type="primary" iconType="send" onClick={onSelectPushArticalToBucket}>
                    Add To Bucket
                  </LookButton>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="content-info">
          <div className="d-flex">
            <div className="pl-8">
              <Tooltip title={title}>
                <h2 className="title mb-4 mr-10" style={{ minHeight: 57 }}>
                  {title}
                </h2>
              </Tooltip>
            </div>
          </div>
        </div>

        {!noHoverActions && (
          <div className="content-btn">
            <span className="text-uppercase">{language_code}</span>
            <span>
              {nickname}
              {language_code}
            </span>
            <a target="_blank" rel="noreferrer" href={advertiser?.brands[0]?.website + "/" + slug}>
              Preview
            </a>
            <LookButton
              tooltipTitle="Creatives"
              type="link"
              iconType="picture"
              onClick={() => showCreativeDrawer(dataItems)}
            />
            <LookButton
              tooltipTitle="Launch"
              type="link"
              iconType="rocket"
              onClick={
                () =>
                  showLaunchDrawer({
                    instantLaunchData: {
                      ...dataItems,
                      ad_nomenclature:
                        "vs-{country}-{article}-{brand}-{user}-{campaignid}-{adsetid}-{adid}",
                    },
                  })
                // <Launch serviceType={SERVICE_TYPE_VS} categoryName={}/>
              }
            />

            {canManage && (
              <LookButton
                tooltipTitle="Edit"
                type="link"
                iconType="setting"
                onClick={() => showDrawerUpdate(dataItems)}
              />
            )}
          </div>
        )}
        {/* <DebugPre content={debugContent} /> */}
      </Card>
    </>
  );
}
