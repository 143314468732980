import { Button, Col, Form, Row } from "antd";
import LookButton from "components/LookButton";
import LookModal from "components/LookModal";
import LookTextInput from "components/forms/LookTextInput";
import React, { useState } from "react";

export default function DuplicateCampaign(props) {
  const { closeDuplicateModel = () => {}, visible, onSubmit = () => {} } = props;

  const [value, setValue] = useState(0);

  return (
    <LookModal {...props}>
      {visible && (
        <>
          <Form layout="vertical">
            <LookTextInput
              name="duplicateCount"
              label="No. of Duplicates"
              placeholder="Enter number of duplicates you want"
              className="look-form-input"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />

            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Button
                  block
                  className="submit-btn"
                  onClick={(e) => {
                    closeDuplicateModel();
                    setValue(0);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <LookButton
                  type="primary"
                  htmlType="submit"
                  block
                  className="submit-btn"
                  iconType="copy"
                  onClick={(e) => onSubmit(value)}
                >
                  Duplicate
                </LookButton>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </LookModal>
  );
}
