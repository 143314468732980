import { Form, Button, Row } from "antd";
import Loader from "components/loader";
import { InputNumber, Radio, Space } from "antd/lib";

export default function MainForm(props) {
  const {
    onSubmit,
    btnText = "Add",
    loading = false,
    budgetValue = 0,
    temp,
    setTemp,
    is_bulk,
    value,
    setValue,
    priceSymbol = "$",
    spendFB,
  } = props;

  const by_number = value === 1;
  const by_percentage = value === 2;
  const by_budget_equals_spend = value === 3;

  return (
    <Form layout="vertical">
      <Row gutter={12}>
        <Radio.Group
          onChange={(e) => {
            if (!is_bulk) {
              setTemp((prev) =>
                e?.target?.value === 1
                  ? prev?.input
                  : e?.target?.value === 2
                  ? {
                      total: (budgetValue + budgetValue * (prev / 100)).toFixed(2),
                      input: prev,
                    }
                  : spendFB
              );
            }
            setValue(e.target.value);
          }}
          value={value}
        >
          <Space direction="horizontal">
            <Radio value={1}> By Number </Radio>
            <Radio value={2}> By Percentage</Radio>
            <Radio value={3}> Budget = Spend</Radio>
          </Space>
        </Radio.Group>
      </Row>

      {!by_budget_equals_spend && (
        <div className="look-form-input mt-15">
          <InputNumber
            name="budget"
            min={by_number ? 0 : -999}
            label="Budget"
            controls={false}
            style={{ width: "200px" }}
            placeholder="Enter budget"
            disabled={by_budget_equals_spend}
            onChange={(e) => {
              const bud = e ? e : 0;
              if (is_bulk) {
                setTemp(bud);
              } else {
                setTemp((prev) =>
                  by_number
                    ? bud
                    : by_budget_equals_spend
                    ? spendFB
                    : {
                        input: bud,
                        total: by_number
                          ? bud
                          : (budgetValue + budgetValue * (bud / 100)).toFixed(2),
                      }
                );
              }
            }}
          />
        </div>
      )}

      {!is_bulk ? (
        <div className="mt-20">
          <div>
            Previous Budget: {priceSymbol}
            {budgetValue}
          </div>

          <div className="mb-50">
            <p>
              Updated Budget:
              {
                <>
                  {priceSymbol}
                  {(temp?.total ? temp?.total : temp) ?? 0}
                </>
              }
            </p>
          </div>
        </div>
      ) : (
        <div className="mb-50">
          {by_number || by_percentage ? (
            <>
              <p>{by_number ? "Value" : "Percentage"}:</p>
              {by_number ? priceSymbol + temp : temp + "%"}
            </>
          ) : null}
        </div>
      )}

      <Form.Item>
        <Button
          htmlType="submit"
          block
          type="primary"
          className="submit-btn "
          onClick={onSubmit}
          disabled={
            by_budget_equals_spend
              ? false
              : by_number
              ? temp <= 0
              : is_bulk
              ? temp === 0
              : temp.input === 0
          }
        >
          {loading ? <Loader /> : btnText}
        </Button>
      </Form.Item>
    </Form>
  );
}
