import { useRef, useState } from "react";
import * as yup from "yup";
import MainFrom from "./MainForm";
import { UPDATE_AD_SET_BUDGET, UPDATE_CAMPAIGN_BUDGET } from "shared/gql/gqlSchema/leadgenGql";
import { openNotification } from "utils";
import { useCustomMutation } from "hooks/apolloClientHooks";

import { REPORT_TYPE_AD_ADSET } from "shared/SharedKeys";
import { UPDATE_BULK_AD_SET_BUDGET } from "shared/gql/gqlSchema/sasGql";

export default function UpdateBulkAdSetBudget(props) {
  const {
    refetch = () => {},
    spendFB: propSpendFB,
    adsetId,

    onCloseDrawerBudget = () => {},
  } = props;
  const [isError, setIsError] = useState("");

  const resetFormRef = useRef(() => {});

  const [updateAdSetBudget, { loading }] = useCustomMutation(UPDATE_BULK_AD_SET_BUDGET, {
    onCompleted(e) {
      // resetFormRef.current();
      const element = e?.tiktok_updateBudget;

      if (element?.success?.length > 0) {
        openNotification({
          type: "success",
          message: `These Id's Budget Updated Successfully ${element?.success?.map(
            (i, index) => i
          )}`,
        });
      }

      if (element?.failure?.length > 0) {
        openNotification({
          type: "error",
          message: `These Id's Budget Updated Successfully ${element?.failure?.map(
            (i, index) => i
          )}`,
        });
      }
      onCloseDrawerBudget();
    },
    onError(e) {
      openNotification({ type: "error", message: "Budget Update failed" });
    },
  });

  const clearError = () => {
    isError !== "" && setIsError("");
  };
  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    const { budget } = values;

    const budget_params = {
      ids: adsetId,
      budget,
    };
    const submitValues = {
      budget_params,
    };

    console.log(submitValues);

    updateAdSetBudget({
      variables: {
        ...submitValues,
      },
    });
    clearError();
  }

  const initialValues = {
    budget: 0,

    // isBudgetSpend: budget === propSpendFB,
  };

  const validationSchema = yup.object({
    budget: yup.number().required().label("budget"),
  });

  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    isError,
    loading,
    propSpendFB,
    btnText: "Update",
  };

  return (
    <>
      <MainFrom {...formProps} />
      {/* <pre>{JSON.stringify({ props }, null, 2)}</pre> */}
    </>
  );
}
