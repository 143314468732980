import React from "react";

function ForceDeleteModal() {
  return (
    <div>
      Creative is already being used in group.Are you sure you want to delete this creative?
    </div>
  );
}

export default ForceDeleteModal;
