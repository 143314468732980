import { Button, Checkbox, Col, Form, Input, Row, Segmented, Select, Typography } from "antd";
import ActionIconRender from "components/ActionIconRender";
import DebugPre from "components/DebugPre";
import LookTextInput from "components/forms/LookTextInput";
import { useLaunchContext } from "components/Launch/Context";
import Loader from "components/loader";
import LookButton from "components/LookButton";
import LookDrawer from "components/LookDrawer";
import LookPagination from "components/LookPagination";
import LookTableBulkAction from "components/LookTable/LookTableBulkAction";
import { LookTableRecordDeleteModal } from "components/LookTable/LookTableModals";
import cx from "cx";
import { Formik } from "formik";
import { useCustomLazyQuery, useCustomMutation, useCustomQuery } from "hooks/apolloClientHooks";
import { CreativeSelectionKey_Title } from "pages/dashboardPages/VS/VSContent/CreativesDrawer/AdGroup";
import { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  CT_ADD_TITLES,
  CT_GET_TITLES,
  GQL_ADD_FEATURED_TITLES,
  GQL_CT_DELETE_TITLES,
} from "shared/gql/gqlSchema/creativeGql";
import { openNotification } from "utils";
import * as yup from "yup";
import useAiHooks from "../hooks/useAiHooks";
import DateRangePersist from "components/PersistComponents/DateRangePersist";
import { useSelector } from "react-redux";
import LookModal, { useLookModal } from "components/LookModal";
import TagModal from "../CreativeUpload/TagModal";
import { FEATURED, MY_TITLES } from "pages/dashboardPages/Creatives/creativesEnum";
import {
  GQL_API_RESPONSE_STATUS_FAILURE,
  GQL_API_RESPONSE_STATUS_SUCCESS,
  GQL_API_RESPONSE_STATUS_WARNING,
} from "shared/SharedKeys";
import { RECORD_UPDATED } from "shared/enum/apiResponse";
import { UMS_AGENCY_ADMIN } from "shared/UserPermission/userPermissionList";
import { usePermissionCheck } from "hooks";
import ForceDeleteModal from "../ForceDeleteModal";

function MainForm(props) {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    onClose = () => {},
    btnText = "Add",
    loading = false,
    label = "",
  } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <LookTextInput
                formik
                name="title"
                label={label}
                type="textarea"
                className="look-form-input"
                placeholder={"Enter the " + label?.toLowerCase()}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item>
                <Button block className="submit-btn" onClick={onClose}>
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="submit-btn"
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={loading}
                >
                  {loading ? <Loader /> : btnText}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default function Title(props) {
  const {
    launch = false,
    onClose = () => {},
    groupSelection = false,
    selectedCreatives,
    handleSelectedCreatives,
    setSelectedData,
    selectedData,
    campaignIndex,
    adSetindex,
    productId,
    categoryId,
    findCommonDynamicAds = () => {},
    categoryName,
    subCategoryId,
    verticalId,
    titleAIData,
    loading_titleAIData,
    isTemp,
  } = props;
  const { handleAdgroup, campaignObject } = useLaunchContext();
  const category_Id = categoryId;
  const [showAddTitle, setShowAddTitle] = useState(false);
  const [searchMode, setSearchMode] = useState("normal");
  const label = "Title";
  const [tagsData, setTagsData] = useState([]);
  const [tags, setTags] = useState([]);
  const [createdbyMe, setCreatedByMe] = useState("All");
  const [visibleForceDeleteModal, setVisibleForceDeleteModal] = useState(false);
  const { Search } = Input;

  const onChange = (checked) => {
    setCreatedByMe(checked);
  };

  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRange } = userSettings || {};
  const [searchItem, setSearchItem] = useState("");
  const [recordData, setRecordData] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [deleteModalPropsData, setDeleteModalPropsData] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const { Paragraph } = Typography;
  const onOpenDeleteModal = (rowProps) => {
    setDeleteModalPropsData(rowProps);
    setDeleteModalVisible(true);
  };
  const bulkActions = [
    {
      option: "delete",
      title: "Delete",
      onClick: (e) => onOpenDeleteModal(e),
    },
    {
      option: "important",
      title: "Mark Favourite",
      onClick: (e) =>
        ctAddFeatured({
          variables: { titles: e, productId, categoryId, categoryName },
        }),
    },
  ];
  const { havePermission: isUmsAgencyAdmin } = usePermissionCheck(UMS_AGENCY_ADMIN);
  const onSubmitDeleteModal = () => {
    deleteCall({
      variables: {
        titleIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: false,
      },
    });
  };
  const onSubmitForceDeleteModal = () => {
    deleteCall({
      variables: {
        titleIds: deleteModalPropsData,
        categoryId,
        productId,
        forceDelete: true,
      },
    });
  };
  const onCloseDeleteModal = () => {
    setDeleteModalPropsData([]);
    setDeleteModalVisible(false);
  };
  const onCloseForceDeleteModal = () => {
    setDeleteModalPropsData([]);
    setVisibleForceDeleteModal(false);
  };
  const queryVars = {
    productId,
    categoryId: category_Id,
    createdByMe: createdbyMe === MY_TITLES,
    featuredTitles: createdbyMe === FEATURED,
    page: currentPage,
    size: pageSize,
    // FOR FUTURE USE
    // fromDate: dateRange?.rangeDates[0],
    // toDate: dateRange?.rangeDates[1],
  };
  const variables = {
    productId,
    categoryId: category_Id,
    categoryName,
  };
  const [visibleTitleDrawer, setVisibleTitleDrawer] = useState(false);
  const onCloseTitleDrawer = () => {
    setVisibleTitleDrawer(false);
    setSelectedTitles([]);
  };

  const [fetchTitle, { data, loading: loading_fetch, refetch }] = useCustomLazyQuery(
    CT_GET_TITLES,
    {
      notifyOnNetworkStatusChange: true,
      // variables: { categoryId: id, productId: PRODUCT_ID_VS },
      variables: {
        ...queryVars,
      },
      onCompleted: (e) => {
        const element = e?.ctGetCreativeTitles;

        if (element?.status === "SUCCESS") {
          setRecordData(element?.data);
          setTotalCount(e?.ctGetCreativeTitles?.pageInfo?.totalRecords);
        } else {
          openNotification({ type: "error", message: e?.ctGetCreativeTitles?.message });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: "Something is wrong, Unable to fetch titles" });
      },
    }
  );

  const [ctAddTitle, { loading: loading_update }] = useCustomMutation(CT_ADD_TITLES, {
    variables: { productId },
    onCompleted: (e) => {
      const element = e?.ctAddCreativeTitles;
      const { status, data, message } = element;

      if (status === "SUCCESS") {
        const { addedCreatives, alreadyPresent } = data?.[0] || {};

        setShowAddTitle(false);
        resetFormRef.current();
        let messages = message;
        if (alreadyPresent?.length > 0) {
          // const finalData = { title: alreadyPresent[0] };
          refetch();
          messages = `Creatives ${alreadyPresent[0]?.title} is already present`;
          openNotification({ type: "error", message: messages });
        } else {
          // const finalData = { title: addedCreatives?.[0] };
          refetch();
          // setRecordData([{ ...finalData }, ...recordData]);
          openNotification({ type: "success", message: messages });
        }
      } else {
        openNotification({ type: "error", message, key: "desc-creatives" });
      }
    },
    onError(e) {
      openNotification({ type: "error", message: "Failed to add titles", key: "title-creatives" });
    },
  });
  const [ctAddFeatured, { loading: loading_featured }] = useCustomMutation(
    GQL_ADD_FEATURED_TITLES,
    {
      onCompleted: (e) => {
        const element = e?.ctAddFeaturedCreativeTitles;
        if (element?.status === GQL_API_RESPONSE_STATUS_SUCCESS) {
          openNotification({
            type: "success",
            message: element?.message || RECORD_UPDATED,
            key: "success",
          });
          refetch();
          closeTagModal();
          setTags([]);
        } else if (element?.status === GQL_API_RESPONSE_STATUS_FAILURE) {
          openNotification({ type: "error", message: element?.message, key: "error" });
        }
      },
      onError(e) {
        openNotification({ type: "error", message: e?.message });
      },
    }
  );
  const resetFormRef = useRef(() => {});

  // let variables = {
  //   productId,
  //   categoryId: id,
  //   categoryName: title ? title : name,
  // };

  // if (serviceType === SERVICE_TYPE_SAS) {
  //   variables = isKeyword
  //     ? {
  //         ...variables,
  //         subCategory: { subCategoryId: id, subCategoryName: name },
  //         categoryName: category?.name,
  //         categoryId: category?.id,
  //       }
  //     : { ...variables, verticalId: vertical?.id };
  // }

  function onSubmit(values, { resetForm }) {
    resetFormRef.current = resetForm;
    ctAddTitle({
      variables: { ...variables, titles: [values?.title] },
    });
  }
  const tagDataExtractor = (id) => {
    const tagsArray = recordData?.filter((data) => data?.title?.id === id)?.[0]?.title?.tags;
    setTagsData(tagsArray?.map((data) => data?.name));
  };
  const initialValues = { title: "" };
  const validationSchema = yup.object({
    title: yup.string().required().label(label),
  });
  const formProps = {
    initialValues,
    validationSchema,
    onSubmit,
    onClose: () => {
      setShowAddTitle(false);
    },
    label,
  };

  // const initialColumns = [
  //   {
  //     title: "Title",
  //     dataIndex: "title",
  //     key: "title",
  //     render: (value) => <LookTableColumnRender title={value} />,
  //     width: 130,
  //     minWidth: 130,
  //     fixed: "left",
  //   },
  // ];

  const renderData = [];
  recordData?.forEach((element, index) => {
    renderData.push({ ...element, key: index });
  });

  const [deleteCall, { loading: deleteLoader }] = useCustomMutation(GQL_CT_DELETE_TITLES, {
    onCompleted(e) {
      const element = e?.ctDeleteCreativeTitles;
      setDeleteModalVisible(false);
      if (element?.status === "SUCCESS") {
        refetch();
        openNotification({
          type: "success",
          message: element?.message || "Record Deleted Success",
          key: "title-creatives",
        });
        selectedRowsClear();
      } else if (element?.status === GQL_API_RESPONSE_STATUS_WARNING && isUmsAgencyAdmin) {
        setVisibleForceDeleteModal(true);
      } else {
        openNotification({
          type: "error",
          message: element?.message || "Failed to delete records",
          key: "title-creatives",
        });
      }
    },
    onError(e) {
      openNotification({
        type: "error",
        message: "Failed to delete records",
        key: "title-creatives",
      });
    },
  });
  function selectedRowsClear() {
    setSelectedTitles([]);
    setTags([]);
  }

  const {
    modal: tagModal,
    visibleModal: visilbleTagModal,
    openModal: openTagModal,
    closeModal: closeTagModal,
  } = useLookModal({
    title: "Add tags",
    footer: false,
    width: "50vw",
    onClose: () => {
      setTags([]);
    },
  });
  const bulkActionProps = {
    selectedRows: selectedTitles,
    onDismiss: selectedRowsClear,
    actionOptions: bulkActions,
  };
  const deleteModalProps = {
    title: "Delete Creatives",
    message: "Do you really want to delete this creatives?",
    visible: deleteModalVisible,
    onSubmit: onSubmitDeleteModal,
    onCancel: onCloseDeleteModal,
    loader: deleteLoader,
  };
  const forceDeleteModalProps = {
    title: "Delete Creatives",
    visible: visibleForceDeleteModal,
    onSubmit: onSubmitForceDeleteModal,
    onCancel: onCloseForceDeleteModal,
    okokText: "Delete",
    loader: deleteLoader,
  };

  useEffect(() => {
    fetchTitle();
  }, []);

  const debugContent = [
    { isRow: true, contentObjects: [{ recordData }, { data }, { renderData }] },
  ];
  const drawerAdsProps = {
    title: "Add Titles",
    icon: () => <ActionIconRender className="mr-10" iconType="font-colors" />,
    size: 1200,
    visible: visibleTitleDrawer,
    onClose: onCloseTitleDrawer,
  };
  function callApiforAi(selectedTitle) {
    ctAddTitle({
      variables: { ...variables, titles: selectedTitle },
    });
    onCloseTitleDrawer();
  }
  const hooksProps = {
    loading_AIData: loading_titleAIData,
    creativesAIData: titleAIData,
    setSelectedData: setSelectedTitles,
    selectedData: selectedTitles,
    creativeSelectionKey: CreativeSelectionKey_Title,
    loading: loading_fetch,
    onClose,
    addData: callApiforAi,
    label: "headlines",
  };
  const aiGeneratedDrawer = useAiHooks({ ...hooksProps });
  return (
    <div className={!groupSelection && !launch ? cx("p-20") : ""}>
      {visilbleTagModal &&
        tagModal(
          <TagModal
            variables={{
              productId,
              categoryId,
              categoryName,
            }}
            files={deleteModalPropsData}
            creatives_refetch={refetch}
            setTags={setTags}
            setTagsData={setTagsData}
            tags={tags}
            tagsData={tagsData}
            closeTagModal={closeTagModal}
            selectedRowsClear={selectedRowsClear}
            creativeType="TITLE"
          />
        )}
      <div
        className={
          !groupSelection ? cx("d-flex justify-content-between align-items-center mt-10") : "mt-20"
        }
      >
        <div className={`d-flex creative-table-search ${groupSelection ? "mb-20" : ""}`}>
          <div
            className={cx("look-table-search", "d-flex")}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #404040",
            }}
          >
            {searchMode === "tags" ? (
              <Select
                mode={"tags"}
                allowClear={false}
                bordered={false}
                placeholder={"Search by tags"}
                style={{ width: "200px" }}
                onChange={(e) => {
                  setSearchItem(e);
                }}
                maxTagCount="responsive"
                showArrow={false}
              />
            ) : (
              <Search
                className="creative-tag-search"
                placeholder={`Search by  title`}
                suffix={null}
                onSearch={(e) => {
                  fetchTitle({
                    variables: {
                      search: e,
                    },
                  });
                }}
                onChange={(e) => {
                  setSearchItem(e?.target?.value);
                }}
                style={{
                  width: "100%",
                }}
              />
            )}
            {/* add tags icon */}
            <LookButton
              tooltipTitle={
                searchMode === "tags"
                  ? "Click here to switch to normal mode"
                  : "Click here to search by tags"
              }
              className="filter-btn ml-8"
              iconType={searchMode === "tags" ? "close" : "tagSwitch"}
              onClick={() => {
                setSearchMode((prevMode) => (prevMode === "tags" ? "normal" : "tags"));
              }}
            />
            <LookButton
              tooltipTitle="Click here to search"
              className="filter-btn"
              iconType="search"
              onClick={() => {
                fetchTitle({
                  variables: {
                    ...(searchMode !== "tags" ? { search: searchItem } : { tags: searchItem }),
                  },
                });
              }}
            />
          </div>
          <Segmented
            className="custom-segmented ml-8"
            options={["All", MY_TITLES, FEATURED]}
            value={createdbyMe}
            onChange={onChange}
          />
        </div>
        <div className="d-flex">
          {/* <DateRangePersist isTimezone={false} /> */}
          {/* {!groupSelection && !launch && ( */}
          <LookButton
            // tooltipTitle="Take snapshots"
            iconType="image"
            className="mr-2"
            onClick={() => {
              setVisibleTitleDrawer(true);
            }}
          >
            AI Generated
          </LookButton>
          {/* )} */}
        </div>
      </div>
      <div className={cx("creative-text-list-view", { launch, groupSelection })}>
        {
          <>
            <LookDrawer {...drawerAdsProps}>{visibleTitleDrawer && aiGeneratedDrawer}</LookDrawer>
            {(!launch || !groupSelection) && (
              <>
                <LookTableBulkAction {...bulkActionProps} />
                <LookTableRecordDeleteModal {...deleteModalProps} />
              </>
            )}
            {visibleForceDeleteModal && (
              <LookModal {...forceDeleteModalProps}>
                <ForceDeleteModal />
              </LookModal>
            )}
            {
              <div className="mt-20">
                {showAddTitle ? (
                  <MainForm {...formProps} />
                ) : (
                  <div
                    className={cx("look-radio-ad", "d-flex")}
                    style={{ alignItems: "center", color: "#696969" }}
                    onClick={() => setShowAddTitle(true)}
                  >
                    <div className="mr-10">
                      <PlusOutlined />
                    </div>
                    <div>Click here to add a new title...</div>
                  </div>
                )}
              </div>
            }
            <Checkbox.Group
              onChange={(e) => {
                if (launch && !groupSelection) {
                  const args = !isTemp ? [campaignIndex, adSetindex] : [];
                  handleAdgroup({ title: e }, ...args);
                } else if (groupSelection) {
                  handleSelectedCreatives(CreativeSelectionKey_Title, e);
                  setSelectedData({
                    ...selectedData,
                    title: e?.map((title_id) => ({
                      id: title_id,
                      title: renderData?.find((data) => data?.title?.id === title_id)?.title?.title,
                    })),
                  });
                } else {
                  setSelectedTitles(e);
                }
              }}
              value={
                groupSelection
                  ? selectedCreatives?.[CreativeSelectionKey_Title]
                  : launch
                  ? campaignObject[campaignIndex]?.adSets[adSetindex]?.adCreatives?.title
                  : selectedTitles
              }
              name={CreativeSelectionKey_Title}
              style={{ width: "100%" }}
            >
              {loading_fetch ? (
                <Loader center elementCenter title="Loading Titles..." />
              ) : (
                renderData?.map(({ title }) => (
                  <div
                    className={cx("look-radio-ad flex justify-content-between", {
                      active:
                        groupSelection || launch
                          ? isTemp
                            ? findCommonDynamicAds(CreativeSelectionKey_Title, title?.id)
                            : selectedCreatives?.[CreativeSelectionKey_Title]?.includes(title?.id)
                          : selectedTitles?.includes(title?.id),
                    })}
                  >
                    <Checkbox
                      value={groupSelection ? title?.id : launch ? title?.title : title?.id}
                    >
                      <Paragraph
                        style={{ color: "#e8e8e8" }}
                        ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                      >
                        {title?.title}
                      </Paragraph>
                    </Checkbox>
                    <div className="flex">
                      <ActionIconRender
                        style={{ marginRight: "8px", color: "white" }}
                        iconType="tagSwitch"
                        onClick={() => {
                          tagDataExtractor(title?.id);
                          openTagModal();
                          setDeleteModalPropsData([{ id: title?.id, tags: title?.tags || [] }]);
                        }}
                      />
                      <ActionIconRender
                        style={{ marginRight: "8px", color: "white" }}
                        iconType="delete"
                        onClick={() => onOpenDeleteModal(title?.id)}
                      />
                    </div>
                  </div>
                ))
              )}
            </Checkbox.Group>
          </>

          //  : (
          //   !launch && (
          //     <Radio.Group
          //       onChange={(e) => {
          //         handleSelectedCreatives(CreativeSelectionKey_Title, e.target.value);
          //         setSelectedData({
          //           ...selectedData,
          //           title: [
          //             ...selectedData?.title,
          //             {
          //               id: e.target.value,
          //               title: renderData?.find((i) => i.id === e.target.value)?.title,
          //             },
          //           ],
          //         });
          //       }}
          //       value={selectedCreatives?.[CreativeSelectionKey_Title]}
          //       name={CreativeSelectionKey_Title}
          //       style={{ width: "100%" }}
          //     >
          //       {renderData?.map(({ id, title }) => (
          //         <div
          //           className={cx("look-radio-ad", {
          //             active: selectedCreatives?.[CreativeSelectionKey_Title] === id,
          //           })}
          //         >
          //           <Radio value={id}>{title}</Radio>
          //         </div>
          //       ))}
          //     </Radio.Group>
          //   )
          // )
        }
      </div>
      <div className={`p-8 look-bottom-pagination ${cx({ "mb-15": launch })}`}>
        <LookPagination
          totalCount={totalCount}
          onChange={handlePageChange}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </div>
      <DebugPre content={debugContent} />
    </div>
  );
}
