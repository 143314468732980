import loadbleLazy from "utils/loadbleLazyWrapper";
import { lazy } from "react";
import {
  HRMS_PRODUCT_ADMIN,
  HRMS_USER,
  HRMS_VIEW_EVENT,
} from "shared/UserPermission/userPermissionList";
import route, { ROUTE_DASHBOARD } from "../route";

const HRMSevent = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "HRMSEvent" */ "pages/dashboardPages/HRMS/HRMSEvent")
  )
);
const HRMSAttendance = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "HRMSAttendance" */ "pages/dashboardPages/HRMS/HRMSAttendance")
  )
);
const HRMSDashboard = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "HRMSDashboard" */ "pages/dashboardPages/HRMS/HRMSDashboard")
  )
);
const HRMSDepartment = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "HRMSDepartment" */ "pages/dashboardPages/HRMS/HRMSDepartment")
  )
);
const HRMSEmployee = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "HRMSEmployee" */ "pages/dashboardPages/HRMS/HRMSEmployee")
  )
);
const HRMSExitEmployee = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "HRMSExitedEmployee" */ "pages/dashboardPages/HRMS/HRMSExitedEmployee"
    )
  )
);
const HRMSLeave = lazy(() =>
  loadbleLazy(() =>
    import(/* webpackChunkName: "HRMSLeave" */ "pages/dashboardPages/HRMS/HRMSLeave")
  )
);
const RedirectFromSupervisor = lazy(() =>
  loadbleLazy(() =>
    import(
      /* webpackChunkName: "RedirectFromSupervisor" */ "pages/dashboardPages/HRMS/HRMSDashboard/ApplyLeave/RedirectFromSupervisor"
    )
  )
);

const hrmsRoutes = [
  route("/hrms/my-dashboard", HRMSDashboard, ROUTE_DASHBOARD, false, HRMS_USER),
  route("/hrms/attendance", HRMSAttendance, ROUTE_DASHBOARD, false, HRMS_PRODUCT_ADMIN),
  route("/hrms/employees", HRMSEmployee, ROUTE_DASHBOARD, false, HRMS_PRODUCT_ADMIN),
  route("/hrms/exit-employees", HRMSExitEmployee, ROUTE_DASHBOARD, false, HRMS_PRODUCT_ADMIN),
  route("/hrms/departments", HRMSDepartment, ROUTE_DASHBOARD, false, HRMS_PRODUCT_ADMIN),
  route("/hrms/leaves", HRMSLeave, ROUTE_DASHBOARD, false, HRMS_PRODUCT_ADMIN),
  route("/hrms/events", HRMSevent, ROUTE_DASHBOARD, false, HRMS_VIEW_EVENT),
  route("/hrms/leave-request/:leaveId", RedirectFromSupervisor, ROUTE_DASHBOARD, false, HRMS_USER),
];

export default hrmsRoutes;
