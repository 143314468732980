import Quill from "quill";
import { v4 as uuidv4 } from "uuid";
let Inline = Quill.import("blots/inline");

export const registeredTags = new Set();
const registeredBlotNames = new Set();
export function ensureRegistered(tagName, blotClass) {
  if (!registeredTags.has(tagName)) {
    Quill.register(blotClass);
    registeredTags.add(tagName);
  }
}

export function registerDynamicTag({ tagName, id, idCallBack = () => {} }) {
  let index = 0;
  class DynamicTagBlot extends Inline {
    // static idPass = id;
    static create(value) {
      // const uniqueID = new Date().getTime().toString();
      const uniqueID = uuidv4();
      idCallBack(uniqueID);

      let node = document.createElement(tagName);
      node.setAttribute("data-id", uniqueID);
      index = 0;
      return node;
    }
  }

  DynamicTagBlot.blotName = tagName;
  DynamicTagBlot.tagName = tagName;

  Quill.register(DynamicTagBlot);
}

export function registerDynamicTagFromBackend({ tagName, loopIndex, idSetArray }) {
  let index = 0;
  // let current = 0;
  // debugger;

  class DynamicTagBlot2 extends Inline {
    static create(value) {
      // console.log("****poijn wr3243343432", { index, current, tagName });

      // if (index === 0 && current === 1) {
      //   index = 1;
      // }

      let node = document.createElement(tagName);

      if (loopIndex !== 0 && index === 0) {
        index++;
      }

      node.setAttribute("data-id", idSetArray[index]);

      // if (index === 0) {
      //   current++;
      // }
      if (idSetArray?.length - 1 === index || idSetArray?.length === index) {
        index = 0;
        // current = 0;
      } else {
        index++;
      }
      return node;
    }
  }
  DynamicTagBlot2.blotName = tagName;
  DynamicTagBlot2.tagName = tagName;

  Quill.register(DynamicTagBlot2);
}

// function createDynamicTagBlotClass(uniqueID, tagName) {
//   class DynamicTagBlot extends Quill.import("blots/inline") {
//     static blotName = tagName;
//     static tagName = tagName;

//     static id = uniqueID;

//     static create() {
//       const node = super.create();
//       node.setAttribute("data-id", DynamicTagBlot.id); // Using uniqueID from closure

//       return node;
//     }
//   }

//   return DynamicTagBlot;
// }

// export function registerDynamicTag({ tagName, id, idCallBack = () => {} }) {
//   // debugger;

//   if (!registeredBlotNames.has(tagName)) {
//     const DynamicTagBlot = createDynamicTagBlotClass(id, tagName);
//     Quill.register(DynamicTagBlot);
//     registeredBlotNames.add(tagName);
//   }

//   idCallBack(id); // If you still want to use the callback
// }
