import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { openNotification } from "utils";
import { PRODUCT_ID_LEADGEN, PRODUCT_ID_SAS, PRODUCT_ID_VS } from "shared/SharedKeys";
import {
  CREATE_LAUNCH_TEMPLATE,
  LAUNCH_PLATFORM_DATA,
  CREATE_TIKTOK_LAUNCH_TEMPLATE,
} from "shared/gql/gqlSchema/launchGql";
import moment from "moment";

import { PLATFORM_TYPE_FACEBOOK, SERVICE_TYPE_SAS } from "shared/enum/launchKeysAndEnum";
import { LAUNCH_COUNTRIES_ENUM } from "shared/enum/iCampaignLaunchEnumArray";
import TiktokForm from "../TiktokForm";
import FacebookForm from "../FacebookForm";

function UpdateForm(props) {
  const {
    onClose = () => {},
    template,
    scheduledEndDate,
    scheduledStartDate,
    id,
    refetch,
    tiktokrefetch = () => {},
    productId,
    platformsData,
    tiktokProps,
    templatePlatforms,
    scheduleType,
  } = props;

  const excludedOptions = template?.excluded_geo_locations?.regions || [];
  const includedOptions = template?.geo_locations?.regions || [];
  const [excludedRegion, setExcludedRegion] = useState(excludedOptions);
  const [includedRegion, setIncludedRegion] = useState(includedOptions);

  const dateFormat = "YYYY-MM-DD";
  const defaultselectedDate = moment();
  // const defaultData = defaultselectedDate.format(dateFormat);
  const [tiktokinputs, setTiktokInputs] = useState({
    name: tiktokProps?.name,
    budgetMode: tiktokProps?.budgetMode || [],
    templatePlatform: templatePlatforms,
    householdIncome: tiktokProps?.template?.household_income || [],
    placementType: tiktokProps?.template?.placement_type || "",
    spendingPower: tiktokProps?.template?.spending_power || "",
    promotionType: tiktokProps?.template?.promotion_type || "",
    promotionTargetType: tiktokProps?.template?.promotion_target_type,
    operatingSystems: tiktokProps?.template?.operating_systems || [],
    bidType: tiktokProps?.bidType,
    conversionBidPrice: tiktokProps?.conversionBidPrice,
    templategender: tiktokProps?.template?.gender,
    cta: tiktokProps?.cta,
    billingEvent: tiktokProps?.template?.billing_event,
    countries: tiktokProps?.template?.location?.filter((item) => item?.level === "countries") || [],
    // .concat(
    //   tiktokProps?.template?.location
    //     ?.filter((obj, index, self) => {
    //       return (
    //         obj?.level === "states" &&
    //         index === self.findIndex((el) => el.parent_id === obj.parent_id)
    //       );
    //     })
    //     ?.map((item) => ({
    //       id: item?.parent_id,
    //       level: "countries",
    //       parent_id: "",
    //     }))
    // ) || [],
    states: tiktokProps?.template?.location?.filter((item) => item?.level === "states") || [],
    // .concat(
    //   tiktokProps?.template?.location
    //     ?.filter((obj, index, self) => {
    //       return (
    //         obj?.level === "cities" &&
    //         index === self.findIndex((el) => el.parent_id === obj.parent_id)
    //       );
    //     })
    //     ?.map((item) => ({
    //       id: item?.parent_id,
    //       level: "states",
    //       parent_id: "",
    //     }))
    // ) || [],
    cities: tiktokProps?.template?.location?.filter((item) => item?.level === "cities") || [],
    ageGroups: tiktokProps?.template?.age_groups || [],
    optimizationGoal: tiktokProps?.optimizationGoal || [],
    // countryGroups,
    placement: tiktokProps?.template?.placements || [],
    locales: tiktokProps?.template?.languages || [],
    budget: tiktokProps?.budget,
    bid: tiktokProps?.bidPrice,
    pacing_targeting: tiktokProps?.pacing || "",
    optimizationEvent: tiktokProps?.optimizationEvent,
  });

  const [fbinputs, setFbInputs] = useState({
    name: props?.name,
    description: "",
    budgetTypes: props?.budget_type,
    templatePlatform: templatePlatforms,
    isManual: template?.publisher_platforms?.length > 0 || template?.device_platforms?.length > 0,
    cta: props.cta,
    gender: template?.genders?.[0] || "All",
    bidStrategy: props?.bidStrategy,
    optimizationGoal: props?.optimizationGoal,
    publisherPlatforms: template?.publisher_platforms,
    devicePlatforms: template?.device_platforms,
    age: template?.age_min ? [template.age_min, template.age_max] : [(13, 65)],
    countryGroups:
      template?.geo_locations?.countries?.length > 0
        ? []
        : template?.geo_locations?.country_groups || ["worldwide"],
    objective: props?.objective,
    destinationType: props?.destinationType,
    budget: props?.budget,
    locales: template?.locales || [1001],

    countries: template?.geo_locations?.countries,
    //  LAUNCH_COUNTRIES_ENUM.filter(({ value }) =>
    //   template?.geo_locations?.countries?.includes(value)
    // ).map((i) => i.value),

    excludedCountries: template?.excluded_geo_locations?.countries,
    // LAUNCH_COUNTRIES_ENUM.filter(({ value }) =>
    //   template?.excluded_geo_locations?.countries?.includes(value)
    // ).map((i) => i.value),

    scheduledEnd_Date: scheduledEndDate,
    scheduledStart_Date: scheduledStartDate,

    bid: props?.bid,
    excludedRegions: template?.excluded_geo_locations?.regions || [],
    includedRegions: template?.geo_locations?.regions || [],
    userOs: template?.user_os?.length > 0 ? template?.user_os : ["All"],
    // regions: [],
    facebookPositions: template?.facebook_positions,
    instagramPositions: template?.instagram_positions,
    audienceNetworkPositions: template?.audience_network_positions,
    messengerPositions: template?.messenger_positions,
  });
  const {
    name,
    isManual,
    description,
    budgetTypes,
    gender,
    templatePlatform,
    cta,
    objective,
    countries,
    excludedCountries,
    publisherPlatforms,
    scheduledEnd_Date,
    scheduledStart_Date,
    devicePlatforms,
    age,
    optimizationGoal,
    bidStrategy,
    countryGroups,
    locales,
    facebookPositions,
    instagramPositions,
    audienceNetworkPositions,
    messengerPositions,
    budget,
    bid,
    regions,
    destinationType,
    userOs,
    includedRegions,
    excludedRegions,
  } = fbinputs;

  const manualProps = isManual
    ? {
        publisherPlatforms,
        userOs: userOs?.includes("All") ? [] : userOs,
        messengerPositions: publisherPlatforms?.includes("messenger") ? messengerPositions : [],
        audienceNetworkPositions: publisherPlatforms?.includes("audience_network")
          ? audienceNetworkPositions
          : [],
        instagramPositions: publisherPlatforms?.includes("instagram") ? instagramPositions : [],
        facebookPositions: publisherPlatforms?.includes("facebook") ? facebookPositions : [],
        // countryGroups,
      }
    : {};
  const [addTikTokLaunchTemplate] = useMutation(CREATE_TIKTOK_LAUNCH_TEMPLATE, {
    onCompleted: () => {
      openNotification({ type: "success", message: "Template Updated" });
      tiktokrefetch();
      onClose();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  function onSubmit() {
    // const finalRegion = region
    //   ?.filter((i) => regions?.includes(i.key))
    //   ?.map((i) => {
    //     return { key: i.key };
    //   });

    // const finalExcludedRegion = region
    //   ?.filter((i) => excludedRegions?.includes(i.key))
    //   ?.map((i) => {
    //     return { key: i.key };
    //   });

    // console.log({ finalSubmission });
    if (
      platformsData?.templatePlatforms?.filter((item) => item?.id === templatePlatforms)[0]
        ?.name === PLATFORM_TYPE_FACEBOOK
    ) {
      const finalSubmission = {
        name,
        productId: productId,
        description,
        platformId: templatePlatforms,
        bid: parseFloat(bid),
        budget_type: budgetTypes,
        budget,
        id,
        cta,
        // scheduledEndDate: scheduledEnd_Date,
        // scheduledStartDate: scheduledStart_Date,
        optimizationGoal,
        objective,
        bidStrategy,
        isPersonal: false,
        destinationType,
        template: {
          ageMin: age[0],
          ageMax: age[1],
          genders: gender === "All" ? [] : [gender],
          ...(countries?.length > 0
            ? { geoLocations: { countries, regions: includedRegions } }
            : countryGroups?.length > 0 && {
                geoLocations: { countryGroups: countryGroups, regions: includedRegions },
              }),
          // geoLocations: { countries },
          locales: locales,
          excludedGeoLocations: { countries: excludedCountries, regions: excludedRegions },
          devicePlatforms,
          ...manualProps,
        },
      };

      addLaunchTemplate({ variables: { template_input: finalSubmission } });
    } else {
      const {
        name,
        budgetMode,
        templatePlatform,
        billingEvent,
        householdIncome,
        placementType,
        spendingPower,
        promotionType,
        promotionTargetType,
        operatingSystems,
        bidType,
        conversionBidPrice,
        templategender,
        cta,
        countries,
        states,
        cities,
        ageGroups,
        optimizationGoal,
        objective,
        pacing_targeting,
        optimizationEvent,

        // countryGroups,
        placement,
        locales,

        budget,
        bid,
      } = tiktokinputs;
      // const finalLocationId = countries?.flatMap((item) => {
      //   const existingIndex = states?.findIndex((states) => item?.id === states?.parent_id);
      //   if (existingIndex === -1) {
      //     return { id: item?.id, level: "countries", name: item?.name, parent_id: "" };
      //   } else {
      //     return states
      //       ?.filter((i) => i?.parent_id === item?.id)
      //       ?.flatMap((states) => {
      //         const isExistingIndex = cities?.findIndex(
      //           (cities) => cities?.parent_id === states?.id
      //         );
      //         if (isExistingIndex === -1) {
      //           return {
      //             id: states?.id,
      //             level: "states",
      //             name: states?.name,
      //             parent_id: states?.parent_id,
      //           };
      //         } else {
      //           return cities
      //             ?.filter((item) => item?.parent_id === states?.id)
      //             ?.flatMap((cities) => ({
      //               id: cities?.id,
      //               level: "cities",
      //               parent_id: cities?.parent_id,
      //               name: cities?.name,
      //             }));
      //         }
      //       });
      //   }
      // });
      const finalSubmission = {
        name,
        id: tiktokProps?.id,
        productId: productId,
        ...(cta && { cta }),
        budgetMode,

        bidType,
        ...(conversionBidPrice &&
          conversionBidPrice > 0 && { conversionBidPrice: parseFloat(conversionBidPrice) }),
        platformId: templatePlatforms,
        optimizationGoal,
        optimizationEvent,
        pacing: pacing_targeting,
        ...(bid && bid > 0 && { bidPrice: parseFloat(bid) }),
        // objective,
        budget,
        template: {
          ...(householdIncome?.length > 0 && { householdIncome }),
          ageGroups,
          gender: templategender,
          ...(placementType && { placementType }),
          ...(spendingPower && { spendingPower }),
          ...(promotionType && { promotionType }),
          ...(placement?.length > 0 && { placements: placement }),
          ...(promotionTargetType && { promotionTargetType }),
          ...(operatingSystems && { operatingSystems }),
          ...(billingEvent && { billingEvent }),

          location: countries
            .concat(states)
            .concat(cities)
            ?.map((item) => {
              const { parent_id, ...rest } = item;
              return { ...rest, parentId: parent_id };
            }),
          languages: locales,
        },
      };
      addTikTokLaunchTemplate({ variables: { templateInput: finalSubmission } });
    }
  }
  const [addLaunchTemplate] = useMutation(CREATE_LAUNCH_TEMPLATE, {
    onCompleted: () => {
      openNotification({ type: "success", message: "Template Updated Successfully" });
      refetch();
      onClose();
    },
    onError(e) {
      openNotification({ type: "error", message: e.message });
    },
  });

  const formProps = {
    onClose: onClose,
    onSubmit: onSubmit,
    inputs: fbinputs,
    tiktokinputs: tiktokinputs,
    setTiktokInputs: setTiktokInputs,
    setInputs: setFbInputs,
    dateFormat: dateFormat,
    setExcludedRegion: setExcludedRegion,
    defaultselectedDate: defaultselectedDate,
    // countries_group={countries_group}
    platformsData: platformsData,
  };

  return (
    <>
      {platformsData?.templatePlatforms?.filter((item) => item?.id === templatePlatforms)[0]
        ?.name === "tiktok" ? (
        <TiktokForm {...props} {...formProps} />
      ) : (
        <FacebookForm
          {...props}
          {...formProps}
          includedRegion={includedRegion}
          excludedRegion={excludedRegion}
          setIncludedRegion={setIncludedRegion}
        />
      )}
    </>
  );
}
export default UpdateForm;
