import React, { useState } from "react";
import { Button, Select, Input } from "antd";
import ActionIconRender from "components/ActionIconRender";
import SelectInput from "components/forms/SelectInput";
import LookTextInput from "components/forms/LookTextInput";
import formulaeEnum, { RULE_ENGINE_FORMULAE_TYPE_RULE } from "./formulaeEnum";

export default function RuleFields(props) {
  const { rule, handleRemove, handleRuleChange, platform, comparison, productId } = props;
  const handleColumnChange = (value) => {
    handleRuleChange({ ...rule, path: value });
  };
  const handleComparisionColumnChange = (value) => {
    handleRuleChange({ ...rule, value: { ...rule.value, path: value } });
  };
  const handleOperatorChange = (value) => {
    handleRuleChange({ ...rule, operator: value });
  };

  const handleValueChange = (e) => {
    if (rule.path === "$.status") {
      handleRuleChange({ ...rule, value: e });
    } else {
      handleRuleChange({ ...rule, value: parseFloat(e) });
    }
  };
  const formulae_fields = formulaeEnum(productId, RULE_ENGINE_FORMULAE_TYPE_RULE);
  return (
    <div>
      <div className="d-flex">
        <div style={{ width: 150 }}>
          <SelectInput
            className="mb-0"
            value={rule.path}
            onChange={handleColumnChange}
            placeholder="Select Rule"
            options={[
              { name: "Spend", value: "$.spend" },
              { name: "E ROAS", value: "$.ROI" },
              { name: "Revenue", value: "$.revenue" },
              { name: "Gross Margin", value: "$.profit" },
              { name: "Status", value: "$.status" },
              { name: "Clicks", value: "$.clicks" },
              { name: "Impressions", value: "$.impressions" },
              { name: "CPC", value: "$.CPC" },
              { name: "CPR", value: "$.CPR" },
              { name: "Conversions", value: "$.conversions" },
              { name: "Budget", value: "$.budget" },
              { name: "leads (V)", value: "$.leadsV" },
              { name: "LP CTR", value: "$.LP_CTR" },
              { name: "AD CTR", value: "$.AD_CTR" },
              ...formulae_fields,
            ]}
          />
        </div>
        <>
          {rule.path ? (
            rule.path === "$.status" ? (
              <>
                <div style={{ width: 150 }}>
                  <SelectInput
                    className="mb-0"
                    value={rule.operator}
                    onChange={handleOperatorChange}
                    placeholder="Select Operator"
                    options={[
                      { name: "Greater Than", value: "greaterThanInclusive" },
                      { name: "Less Than", value: "lessThanInclusive" },
                      { name: "Equal to", value: "equal" },
                      { name: "Not Equal to", value: "notEqual" },
                    ]}
                  />
                </div>
                <div style={{ width: 150 }}>
                  {platform === "fb" || platform === "varozi" ? (
                    <SelectInput
                      className="mb-0"
                      value={rule.value}
                      onChange={handleValueChange}
                      placeholder="Select Operator"
                      options={[
                        { name: "Active", value: "ACTIVE" },
                        { name: "Paused", value: "PAUSED" },
                      ]}
                    />
                  ) : platform === "tiktok" ? (
                    <SelectInput
                      className="mb-0"
                      value={rule.value}
                      onChange={handleValueChange}
                      placeholder="Select Operator"
                      options={[
                        { name: "Enable", value: "ENABLE" },
                        { name: "Disable", value: "DISABLE" },
                      ]}
                    />
                  ) : platform === "taboola" ? (
                    <SelectInput
                      className="mb-0"
                      value={rule.value}
                      onChange={handleValueChange}
                      placeholder="Select Operator"
                      options={[
                        { name: "Running", value: "RUNNING" },
                        { name: "Paused", value: "PAUSED" },
                      ]}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div style={{ width: 150 }}>
                  <SelectInput
                    className="mb-0"
                    value={rule.operator}
                    onChange={handleOperatorChange}
                    placeholder="Select Operator"
                    options={[
                      { name: "Greater Than", value: "greaterThanInclusive" },
                      { name: "Less Than", value: "lessThanInclusive" },
                      { name: "Equal to", value: "equal" },
                      { name: "Not Equal to", value: "notEqual" },
                    ]}
                  />
                </div>
                {comparison.length > 0 && rule.value?.fact ? (
                  <div style={{ width: 150 }}>
                    <SelectInput
                      className="mb-0"
                      value={rule.value?.path}
                      onChange={handleComparisionColumnChange}
                      placeholder="Select Comparision"
                      options={comparison.map((item, index) => {
                        return {
                          name: `Comparative${index + 1}`,
                          value: `$.comparision${index + 1}`,
                        };
                      })}
                    />
                  </div>
                ) : (
                  <div style={{ width: 150 }}>
                    <LookTextInput
                      normalNumberInput
                      className="mb-0"
                      value={rule.value}
                      onChange={(e) => handleValueChange(e)}
                      placeholder="Enter Value"
                    />
                  </div>
                )}
              </>
            )
          ) : null}
        </>
        <Button
          onClick={handleRemove}
          type="link"
          danger
          icon={<ActionIconRender iconType="close" />}
        />
      </div>
    </div>
  );
}
