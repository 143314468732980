import { useEffect, useState } from "react";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { Tooltip, Switch } from "antd";
import { useSelector } from "react-redux";
import alasql from "alasql";
import cx from "classnames";
import { useParams } from "react-router";
import DetailView from "./DetailView";
import {
  USER_SETTING_TABLE_PERSIST_KEY_MAIN,
  VS_IS_FORECAST_SET,
  VS_IS_FORECAST_UNSET,
} from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import { useCustomLazyQuery, useCustomQuery } from "hooks/apolloClientHooks";
import ActionIconRender from "components/ActionIconRender";
import { UMS_SUPER_ADMIN } from "shared/UserPermission/userPermissionList";
import SelectInput from "components/forms/SelectInput";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useApiToStore } from "hooks";
import {
  API_STORE_VIRALSPARKS_ADVERTISE,
  API_STORE_VIRALSPARKS_ALL_USERS,
  API_STORE_VIRALSPARKS_PLATFORM,
} from "hooks/useApiToStore/keys";
import { VS_AD_GROUP_REPORTS, VS_AD_LIVE_REPORT } from "shared/gql/gqlSchema/viralSparksGql";
import useVSAdvertiserHandler from "../../../useVSAdvertiserHandler";
import { useDispatch } from "react-redux";
import useColumns from "../useColumns";
import { GET_F360_AD_GROUPED_REPORT, GET_F360_AD_REPORT } from "shared/gql/gqlSchema/fb360Gql";
import { addAccountGroup } from "../VsReportUtils";
import {
  ActionsForFacebook,
  useUpdateFB360Status,
} from "components/ReportUtils/ActionsForFacebook";
import { REPORT_TYPE_AD } from "shared/SharedKeys";
import { fbGroupForStatus } from "components/ReportUtils/UpdateCampaignBudget/utils";
import { numberParse } from "utils";
import {
  ADBY_REPORT_TYPE_ADACCOUNT,
  ADBY_REPORT_TYPE_ADSET,
  ADBY_REPORT_TYPE_CAMPAIGN,
} from "hooks/facebookHooks/useFacebookSpendReportsQuery";
import {
  SAS_REPORT_STATUS_TYPE_ACTIVE,
  SAS_REPORT_STATUS_TYPE_PAUSED,
} from "pages/dashboardPages/SAS/sasKeysAndEnum";
import { LEADGEN_FACEBOOK_PLATFORM_ID } from "pages/dashboardPages/Leadgen/leadgenKeysAndEnums";

export default function Ads(props) {
  const {
    isCampaignPerformanceReport = false,
    isAdsetPerformanceReport = false,
    isBulkAdsetFiltered = false,
    isBulkCampaignFiltered = false,
    isBulkAdAccountFiltered = false,
    isAdAccountFiltered = false,
    campaignId,
    adsetId,
    bulkProps = [],
  } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const [spendReport, setSpendReport] = useState([]);
  const [rowPropsData, setRowProps] = useState({});
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false);
  const userSettings = useSelector((state) => state?.userSettings);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const { filterData } = useLookTableFilterData(persistKey);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const withUser = params?.userID ? { userId: params?.userID } : {};
  const permissions = useSelector((state) => state?.userReducer?.me?.permissions);
  const userSettingData = useSelector((state) => state?.userSettings?.userSettingData);
  const [selectedUser, setSelectedUser] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [campaignForecastReport, setCampaignForecastReport] = useState([]);
  const [campaignMinReport, setCampaignMinReport] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const { vsAdvertiser = [] } = userSettingData || {};
  const { columns, status } = useColumns({ node: REPORT_TYPE_AD, timeZone: dateRangeTimezone });

  const {
    selectedVSAdvertiserIsAll = true,
    selectedVSAdvertiser = "",
    vsIsForecast,
    vs_users = [],
  } = useSelector((state) => state?.vsReducer) || {};

  const isAdvertiser = selectedVSAdvertiserIsAll ? {} : { advertiser_id: selectedVSAdvertiser };
  const isVendor = selectedVSAdvertiserIsAll ? {} : { vendor_id: selectedVSAdvertiser };

  function handleForecast(e) {
    dispatch({ type: e ? VS_IS_FORECAST_SET : VS_IS_FORECAST_UNSET });
  }

  const isUser = selectedUser === "all" ? {} : { userId: selectedUser };
  const isWorkspaceAdmin =
    permissions.filter((name) => name === UMS_SUPER_ADMIN).length > 0 ? true : false;
  const { apiCall: vsAdvertiserFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ADVERTISE);

  const { apiCall: vsUsersFetch = () => {} } = useApiToStore(API_STORE_VIRALSPARKS_ALL_USERS);

  const { updateFb360Status } = useUpdateFB360Status(REPORT_TYPE_AD, {
    setSpendReport,
  });

  const { handleAdvertiserSelection } = useVSAdvertiserHandler();
  const withAccount = params?.accID ? { adAccountId: params?.accID } : {};

  useEffect(() => {
    if (!(isCampaignPerformanceReport || isAdsetPerformanceReport)) {
      if (isWorkspaceAdmin) {
        vsUsersFetch();
      }
      vsAdvertiserFetch();
    }
  }, []);

  const spendCommonVariables = {
    start: dateRangeTimezone?.rangeDates[0],
    end: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone?.value,
  };

  const revenueCommonVariables = {
    from: dateRangeTimezone?.rangeDates[0],
    to: dateRangeTimezone?.rangeDates[1],
    timezone: dateRangeTimezone?.timeZone.value,
  };

  let reportSettings = {
    spendApi: GET_F360_AD_REPORT,
    spendApiKey: "fb360_adReporting",
    revenueApi: VS_AD_LIVE_REPORT,
    revenueApiKey: "vs_adLiveReportsFromTo",
  };

  if (
    params?.accID ||
    isBulkAdAccountFiltered ||
    isCampaignPerformanceReport ||
    isAdsetPerformanceReport ||
    isBulkCampaignFiltered ||
    isBulkAdsetFiltered
  ) {
    reportSettings = {
      spendApi: GET_F360_AD_GROUPED_REPORT,
      spendApiKey: "fb360_adGroupedReporting",
      revenueApi: VS_AD_GROUP_REPORTS,
      revenueApiKey: "vs_adGroupReportsFromTo",
    };
  }

  function getSpendAssetParams() {
    if (isCampaignPerformanceReport || isBulkCampaignFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_CAMPAIGN,
        asset_id: isCampaignPerformanceReport
          ? [props?.campaignId]
          : bulkProps?.map(({ campaignId }) => campaignId),
      };
    } else if (isAdsetPerformanceReport || isBulkAdsetFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_ADSET,
        asset_id: isAdsetPerformanceReport
          ? [props?.adsetId]
          : bulkProps?.map(({ adsetId }) => adsetId),
      };
    } else if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset_type: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return {
        asset_type: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: [params?.accID],
      };
    } else {
      return {};
    }
  }
  function getRevenueAssetParams() {
    if (isCampaignPerformanceReport || isBulkCampaignFiltered) {
      return {
        asset: ADBY_REPORT_TYPE_CAMPAIGN,
        asset_id: isCampaignPerformanceReport
          ? [props?.campaignId]
          : bulkProps?.map(({ campaignId }) => campaignId),
      };
    } else if (isAdsetPerformanceReport || isBulkAdsetFiltered) {
      return {
        asset: ADBY_REPORT_TYPE_ADSET,
        asset_id: isAdsetPerformanceReport
          ? [props?.adsetId]
          : bulkProps?.map(({ adsetId }) => adsetId),
      };
    } else if (isAdAccountFiltered || isBulkAdAccountFiltered) {
      return {
        asset: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: isAdAccountFiltered
          ? [props?.adAccountId]
          : bulkProps?.map(({ adAccountId }) => adAccountId),
      };
    } else if (params?.accID) {
      return {
        asset: ADBY_REPORT_TYPE_ADACCOUNT,
        asset_id: [params?.accID],
      };
    } else {
      return {};
    }
  }

  const spendExtraParams = getSpendAssetParams();
  const revenueExtraParams = getRevenueAssetParams();

  const {
    loading: loading_GET_REPORT_BY_AD,
    error: error_GET_REPORT_BY_AD,
    refetch: refetch_GET_REPORT_BY_AD,
  } = useCustomQuery(reportSettings?.spendApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[reportSettings?.spendApiKey];
      if (element) {
        setSpendReport(
          element?.map((item) => ({
            ...item,
            spendAdId: item?.ad_id,
            spendAccountId: item?.adaccount_id,
            clicksFB: item?.inline_link_clicks,
            spendFB: item?.spend,
          }))
        );
      }
    },
    variables: {
      ...isVendor,
      ...spendCommonVariables,
      ...spendExtraParams,
    },
  });

  const [
    vs_adLiveReportsFromTo,
    { loading: loading_GET_REVENUE_BY_AD_ACCOUNT, refetch: refetch_GET_REVENUE_BY_AD_ACCOUNT },
  ] = useCustomLazyQuery(reportSettings?.revenueApi, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.[reportSettings?.revenueApiKey];
      if (element) {
        vsIsForecast
          ? setCampaignForecastReport(element?.map((item) => ({ ...item, revAdId: item?.ad_id })))
          : setCampaignMinReport(element?.map((item) => ({ ...item, revAdId: item?.ad_id })));
      }
    },
    variables: {
      ...revenueCommonVariables,
      ...revenueExtraParams,
    },
  });

  const loading = loading_GET_REPORT_BY_AD || loading_GET_REVENUE_BY_AD_ACCOUNT;

  function refetch() {
    setCampaignMinReport([]);
    setCampaignForecastReport([]);
    refetch_GET_REPORT_BY_AD();
    refetch_GET_REVENUE_BY_AD_ACCOUNT();
  }

  useEffect(() => {
    if (campaignForecastReport.length === 0 && vsIsForecast) {
      vs_adLiveReportsFromTo({
        variables: {
          is_forecast: vsIsForecast,
          platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
          ...revenueCommonVariables,
          ...reportSettings?.revenueExtraVariables,
          ...isAdvertiser,
          ...(isUser || withUser),
        },
      });
    } else if (campaignMinReport.length === 0 && !vsIsForecast) {
      vs_adLiveReportsFromTo({
        variables: {
          is_forecast: vsIsForecast,
          ...revenueCommonVariables,
          ...reportSettings?.revenueExtraVariables,
          platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
          ...isAdvertiser,
          ...(isUser || withUser),
        },
      });
    }
    setFirstFetch(false);
  }, [vsIsForecast]);

  useEffect(() => {
    if (!firstFetch) {
      setCampaignMinReport([]);
      setCampaignForecastReport([]);
      vs_adLiveReportsFromTo({
        variables: {
          is_forecast: vsIsForecast,
          platform_id: LEADGEN_FACEBOOK_PLATFORM_ID,
          ...revenueCommonVariables,
          ...reportSettings?.revenueExtraVariables,
          ...isAdvertiser,
          ...(isUser || withUser),
        },
      });
    }
  }, [dateRangeTimezone, selectedUser, selectedVSAdvertiser]);

  const action = [
    {
      title: "Action",
      dataIndex: "action",
      nofilter: true,
      render: (action, actionProps) => (
        <ActionsForFacebook
          reportType={REPORT_TYPE_AD}
          actionProps={actionProps}
          setSpendReport={setSpendReport}
        />
      ),
      fixed: "right",
      width: 130,
      minWidth: 130,
    },
  ];

  const initialColumns = [
    {
      title: "Ad Name",
      dataIndex: "adName",
      key: "adName",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDrawerUpdate(otherProps)} />
      ),
      fixed: "left",
      width: 300,
      minWidth: 300,
      searchType: "string",
    },
    {
      title: "Ad Id",
      dataIndex: "ad_id",
      key: "ad_id",
      render: (value, otherProps) => (
        <LookTableColumnRender title={value} isClick={() => showDrawerUpdate(otherProps)} />
      ),
      width: 170,
      hidden: true,
      minWidth: 170,
      searchType: "number",
    },
    ...columns,
    ...action,
  ];

  let finalMerge = [];
  let finalMergeData = [];

  if (!loading) {
    let query = "SELECT * FROM ? as a FULL OUTER JOIN ? as b ON a.ad_id = b.ad_id";
    finalMerge = alasql(query, [
      vsIsForecast ? campaignForecastReport : campaignMinReport,
      spendReport,
    ]);

    finalMerge?.forEach((element, index) => {
      const {
        spendFB = 0,
        clicksFB = 0,
        impressionsFB = 0,
        impressions = 0 | "0",
        result = 0,
        user_revenue = 0,
        status = null,
        sessions = 0 | "0",
        pageviews = 0 | "0",
        ad_name = "",
        spendAdId,
        revAdId,
      } = element;

      const spendUpdate = spendFB?.toFixed(2);
      // const revenueUpdate = (TotalRevenue * 0.94).toFixed(2);

      const revenueUpdate = user_revenue;

      const PROFIT = parseFloat(revenueUpdate) - parseFloat(spendUpdate);
      const CPC = parseFloat(spendUpdate) / parseFloat(sessions);
      const CPC_FB = parseFloat(spendUpdate) / parseFloat(clicksFB);
      const ROI = (parseFloat(revenueUpdate) / parseFloat(spendUpdate)) * 100 || 0;
      const EPC = parseFloat(revenueUpdate) / parseFloat(sessions) || 0;
      const CTR = (parseFloat(clicksFB) / parseFloat(impressionsFB)) * 100 || 0;
      const CPR = parseFloat(spendUpdate) / parseFloat(result) || 0;
      const DISC = parseFloat(sessions) / parseFloat(clicksFB) || 0;
      const IPP = parseFloat(impressions) / parseFloat(pageviews) || 0;
      const PPS = parseFloat(impressions) / parseFloat(sessions) / 3;
      const RPM = (parseFloat(revenueUpdate) / parseFloat(impressions)) * 1000 || 0;
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressionsFB)) * 1000;
      const PRPM =
        ((parseFloat(revenueUpdate) / parseFloat(sessions)) * 1000) /
        (parseFloat(impressions) / parseFloat(sessions) / 3);

      finalMergeData.push({
        ...element,
        adName: ad_name || "",
        key: index,
        TotalRevenue: numberParse(revenueUpdate),
        adId: spendAdId || revAdId,
        clicksFB,
        result,
        impressionsFB,
        spendFB: spendUpdate,
        PROFIT: numberParse(PROFIT),
        PROFIT_VIEW: numberParse(PROFIT, { toFixed: true }),
        CPC: numberParse(CPC),
        CPC_VIEW: numberParse(CPC, { toFixed: true, toFixedUpTo: 3 }),
        CPC_FB: numberParse(CPC_FB),
        CPC_FB_VIEW: numberParse(CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
        ROI: numberParse(ROI),
        ROI_VIEW: numberParse(ROI, { toFixed: true }),
        EPC: numberParse(EPC),
        EPC_VIEW: numberParse(EPC, { toFixed: true, toFixedUpTo: 3 }),
        CTR: numberParse(CTR),
        CTR_VIEW: numberParse(CTR, { toFixed: true }),
        DISC: numberParse(DISC),
        DISC_VIEW: numberParse(DISC, { toFixed: true }),
        IPP: numberParse(IPP),
        RPM: numberParse(RPM),
        PRPM: numberParse(PRPM),
        PRPM_VIEW: numberParse(PRPM, { toFixed: true }),
        CPR: numberParse(CPR),
        CPR_VIEW: numberParse(CPR, { toFixed: true }),
        PPS: numberParse(PPS),
        PPS_VIEW: numberParse(PPS, { toFixed: true }),
        CPM: numberParse(CPM),
        CPM_VIEW: numberParse(CPM, { toFixed: true }),
        status,
      });
    });
  }

  const finalFilterDataWithNameFilter = filterData(finalMergeData).filter(
    ({ adName = "", adId = "" }) => {
      const searchValue = search?.toLowerCase().trim() || "";

      const searchCheck =
        adName?.toLowerCase()?.includes(searchValue) || adId?.toLowerCase()?.includes(searchValue);

      if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
        return true;
      }
      return false;
    }
  );

  const finalFilterData =
    selectedStatus === SAS_REPORT_STATUS_TYPE_ACTIVE
      ? finalFilterDataWithNameFilter.filter(
          ({ status }) => status !== SAS_REPORT_STATUS_TYPE_PAUSED && status != undefined
        )
      : selectedStatus === SAS_REPORT_STATUS_TYPE_PAUSED
      ? finalFilterDataWithNameFilter.filter(
          ({ status }) => status === SAS_REPORT_STATUS_TYPE_PAUSED && status != undefined
        )
      : finalFilterDataWithNameFilter;

  let total_spendFB = 0,
    total_clicks = 0,
    total_impressionsFB = 0,
    total_result = 0,
    total_impressions = 0,
    total_TotalRevenue = 0,
    total_sessions = 0,
    total_pageViews = 0;

  for (const {
    spendFB = 0,
    clicksFB = 0,
    impressionsFB = 0,
    result = 0,
    impressions = 0,
    TotalRevenue = 0,
    sessions = 0,
    pageviews = 0,
  } of finalFilterData) {
    total_spendFB += parseFloat(spendFB || 0);
    total_clicks += parseFloat(clicksFB || 0);
    total_impressionsFB += parseFloat(impressionsFB || 0);
    total_result += parseFloat(result || 0);
    total_impressions += parseFloat(impressions || 0);
    total_TotalRevenue += parseFloat(TotalRevenue || 0);
    total_sessions += parseFloat(sessions || 0);
    total_pageViews += parseFloat(pageviews || 0);
  }

  const total_PROFIT = total_TotalRevenue - total_spendFB;
  const total_CPC = total_spendFB / total_sessions;
  const total_CPC_FB = total_spendFB / total_clicks;
  const total_ROI = (total_TotalRevenue / total_spendFB) * 100;
  const total_EPC = total_TotalRevenue / total_sessions;
  const total_CTR = (total_clicks / total_impressionsFB) * 100;
  const total_CPR = total_spendFB / total_result;
  const total_DISC = total_sessions / total_clicks;
  const total_IPP = total_impressions / total_pageViews;
  const total_RPM = (total_TotalRevenue / total_impressions) * 1000;
  const total_PRPM =
    ((total_TotalRevenue / total_sessions) * 1000) / (total_impressions / total_sessions / 3);
  const total_PPS = total_impressions / total_sessions / 3;
  const total_CPM = (parseFloat(total_spendFB) / parseFloat(total_impressionsFB)) * 1000;

  const showDrawerUpdate = (rowProps = {}) => {
    setRowProps({
      timezone: dateRangeTimezone?.timeZone.value,
      ...rowProps,
      from: dateRangeTimezone?.rangeDates[0],
      to: dateRangeTimezone?.rangeDates[1],
    });
    setVisibleDetailDrawer(true);
  };
  const onCloseDetailDrawer = () => {
    setVisibleDetailDrawer(false);
  };
  const drawerPropsUpdate = {
    title: "Ad Account",
    visible: visibleDetailDrawer,
    onClose: onCloseDetailDrawer,
    size: 1000,
  };

  const summaryData = {
    TotalRevenue: numberParse(total_TotalRevenue, { toFixed: true }),
    spendFB: numberParse(total_spendFB, { toFixed: true }),
    PROFIT: total_PROFIT.toFixed(2),
    ROI: numberParse(total_ROI, { toFixed: true }),
    EPC: numberParse(total_EPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC: numberParse(total_CPC, { toFixed: true, toFixedUpTo: 3 }),
    CPC_FB: numberParse(total_CPC_FB, { toFixed: true, toFixedUpTo: 3 }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    result: numberParse(total_result),
    CPR: numberParse(total_CPR, { toFixed: true }),
    sessions: numberParse(total_sessions),
    clicksFB: numberParse(total_clicks),
    DISC: numberParse(total_DISC, { toFixed: true }),
    IPP: numberParse(total_IPP, { toFixed: true }),
    RPM: numberParse(total_RPM, { toFixed: true }),
    PRPM: numberParse(total_PRPM, { toFixed: true }),
    PPS: numberParse(total_PPS, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
  };

  const rightOptions = (
    <>
      {/* <div className="mr-12">
        <Tooltip
          title={`${vsIsForecast ? "Forecast" : "Min"} Revenue - Switch to ${
            vsIsForecast ? "Min" : "Forecast"
          }`}
        >
          <Switch checked={vsIsForecast} onChange={handleForecast} />
        </Tooltip>
      </div> */}

      <div className="mr-12" style={{ width: 150 }}>
        <Tooltip title="Status">
          <span>
            <SelectInput
              search
              name="status"
              className="mb-0"
              placeholder="Select status"
              value={selectedStatus}
              options={[
                { name: "All-Status", value: "all" },
                { name: "Active", value: "ACTIVE" },
                { name: "Paused", value: "PAUSED" },
              ]}
              onChange={setSelectedStatus}
            />
          </span>
        </Tooltip>
      </div>

      {isWorkspaceAdmin && (
        <div className="mr-12" style={{ width: 150 }}>
          <Tooltip title="User">
            <span>
              <SelectInput
                search
                name="selectedUser"
                className="mb-0"
                placeholder="Select User"
                value={selectedUser}
                options={[
                  { name: "All", value: "all" },
                  ...vs_users?.map(({ id, name }) => ({ name, value: id })),
                ]}
                onChange={setSelectedUser}
              />
            </span>
          </Tooltip>
        </div>
      )}

      <div style={{ width: 150 }}>
        <Tooltip title="Advertiser">
          <span>
            <SelectInput
              search
              name="selectedAdvertiser"
              className="mb-0"
              placeholder="Select Advertiser"
              value={selectedVSAdvertiserIsAll ? "all" : selectedVSAdvertiser}
              options={[
                { name: "All", value: "all" },
                ...vsAdvertiser?.map(({ id, name }) => ({ name, value: id })),
              ]}
              onChange={(e) => handleAdvertiserSelection(e, true)}
            />
          </span>
        </Tooltip>
      </div>
    </>
  );

  const bulkActions = [
    {
      option: "play",
      title: "Active",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_AD);
        updateFb360Status("ACTIVE", groupedIds);
      },
    },
    {
      option: "pause",
      title: "Pause",
      onClick: (e) => {
        const groupedIds = fbGroupForStatus(e, REPORT_TYPE_AD);
        updateFb360Status("PAUSED", groupedIds);
      },
    },
  ];

  const tableMainProps = {
    isSearchFilter: true,
    exportCSV: true,
    isDateRange: true,
    searchInclude: true,
    filterProps: {
      subPlaceholder: mainSearchWithInclude ? "includes value" : "excludes value",
    },

    tableProps: { initialColumns, recordData: finalFilterData, summaryData },
    loading,
    refetch,
    defaultPagination: true,
    rightOptions: !(isCampaignPerformanceReport || isAdsetPerformanceReport) ? rightOptions : null,
    status,
    bulkActions,
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [
        { vsCampaign: vsIsForecast ? campaignForecastReport : campaignMinReport },
        { spendReport },
        { finalFilterData },
      ],
    },
  ];

  return (
    <div>
      <DetailView {...drawerPropsUpdate} {...rowPropsData} />
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </div>
  );
}
