import { USER_SETTING_TABLE_PERSIST_KEY_MAIN } from "actions/actionTypes";
import DebugPre from "components/DebugPre";
import LookTable from "components/LookTable";
import { LookTableColumnRender } from "components/LookTable/LookTableColumnOptions";
import { useLookTableFilterData } from "components/LookTable/LookTableFilterSearch/functions";
import { useCustomQuery } from "hooks/apolloClientHooks";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { GET_TIKTOK_VIDEO_ANALYSIS_REPORT } from "shared/gql/gqlSchema/tiktok";
import { numberParse } from "utils";

export default function TiktokVideoAnalysis() {
  const [videoAnalysisReport, setVideoAnalysisReport] = useState([]);
  const persistKey = USER_SETTING_TABLE_PERSIST_KEY_MAIN;
  const userSettings = useSelector((state) => state?.userSettings);
  const search = useSelector((state) => state?.userSettings?.mainSearch?.[persistKey]?.search);
  const { dateRangeTimezone, mainSearchWithInclude } = userSettings || {};
  const { filterData } = useLookTableFilterData(persistKey);

  let finalMergeData = [];

  const { loading, refetch } = useCustomQuery(GET_TIKTOK_VIDEO_ANALYSIS_REPORT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (e) => {
      const element = e?.tiktok_videoAnalysisReport;
      if (element) {
        setVideoAnalysisReport(element);
      }
    },
    variables: {
      start: dateRangeTimezone?.rangeDates[0],
      end: dateRangeTimezone?.rangeDates[1],
    },
  });

  const initialColumns = [
    {
      title: "Video Name",
      dataIndex: "video_name",
      key: "video_name",
      render: (value) => <LookTableColumnRender title={value} />,
      fixed: "left",
      width: 160,
      minWidth: 160,
    },
    {
      title: "Amount spent ($)",
      dataIndex: "finalSpend",
      key: "finalSpend",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Revenue ($)",
      dataIndex: "finalRevenue",
      key: "finalRevenue",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },

    {
      title: "Video Length (sec)",
      dataIndex: "video_duration",
      key: "video_duration",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },

    {
      title: "CPM",
      dataIndex: "CPM",
      key: "CPM",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },

    {
      title: "1st Frame Retention",
      dataIndex: "firstFrameRetention",
      key: "firstFrameRetention",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },

    {
      title: "Hook rate (%)",
      dataIndex: "hookRate",
      key: "hookRate",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Hold rate (%)",
      dataIndex: "holdRate",
      key: "holdRate",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "Average watch time (sec)",
      dataIndex: "average_video_play",
      key: "average_video_play",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "25% Video Views",
      dataIndex: "video_views_p25",
      key: "video_views_p25",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "50% Video Views",
      dataIndex: "video_views_p50",
      key: "video_views_p50",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "75% Video Views",
      dataIndex: "video_views_p75",
      key: "video_views_p75",
      render: (value) => <LookTableColumnRender title={value} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "CTR (%)",
      dataIndex: "CTR",
      key: "CTR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "CPC ($)",
      dataIndex: "CPC",
      key: "CPC",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    {
      title: "CVR",
      dataIndex: "CVR",
      key: "CVR",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
    // {
    //   title: "CPA",
    //   dataIndex: "CPA",
    //   key: "CPA",
    //   render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
    //   width: 124,
    //   minWidth: 124,
    // },
    {
      title: "ROI (%)",
      dataIndex: "ROI",
      key: "ROI",
      render: (value) => <LookTableColumnRender title={numberParse(value, { toFixed: true })} />,
      width: 124,
      minWidth: 124,
    },
  ];

  if (!loading) {
    finalMergeData = videoAnalysisReport?.map((element, indx) => {
      const {
        spend = 0,
        impressions = 0,
        revenue = 0,
        video_duration = 0,
        video_watched_6s = 0,
        video_thruplay = 0,
        clicks = 0,
        conversion = 0,
        average_video_play = 0,
        video_views = 0,
        video_views_p25 = 0,
        video_views_p50 = 0,
        video_views_p75 = 0,
        video_watched_2s = 0,
      } = element;

      const spendUpdate = spend.toFixed(2);
      const CPM = (parseFloat(spendUpdate) / parseFloat(impressions)) * 1000;
      const finalRevenue = revenue;

      const firstFrameRetention = video_watched_2s / impressions;
      const hookRate = (video_watched_6s / impressions) * 100 || 0;
      const holdRate = (video_thruplay / impressions) * 100 || 0;
      const CTR = (clicks / impressions) * 100 || 0;
      const CPC = parseFloat(spendUpdate) / parseFloat(clicks);
      const CVR = parseFloat(conversion) / parseFloat(clicks);
      const PROFIT = parseFloat(finalRevenue) - parseFloat(spendUpdate);
      const ROI = (parseFloat(PROFIT) / parseFloat(spendUpdate)) * 100 || 0;
      const video_p25_views_per = (video_views_p25 / video_views) * 100 || 0;
      const video_p50_views_per = (video_views_p50 / video_views) * 100 || 0;
      const video_p75_views_per = (video_views_p75 / video_views) * 100 || 0;

      return {
        ...element,
        average_video_play,
        video_views_p25,
        video_views_p50,
        video_views_p75,
        video_p25_views_per: numberParse(video_p25_views_per, { toFixed: true }),
        video_p50_views_per: numberParse(video_p50_views_per, { toFixed: true }),
        video_p75_views_per: numberParse(video_p75_views_per, { toFixed: true }),
        finalRevenue,
        video_duration,
        finalSpend: spendUpdate,
        hookRate: numberParse(hookRate, { toFixed: true }),
        holdRate: numberParse(holdRate, { toFixed: true }),
        firstFrameRetention: numberParse(firstFrameRetention, { toFixed: true }),
        CPM: numberParse(CPM, { toFixed: true }),
        CTR: numberParse(CTR, { toFixed: true }),
        CPC: numberParse(CPC, { toFixed: true }),
        CVR: numberParse(CVR, { toFixed: true }),
        ROI: numberParse(ROI, { toFixed: true }),
      };
    });
  }

  const finalFilterData = filterData(finalMergeData)?.filter(({ video_name = "" }) => {
    const searchValue = search?.toLowerCase().trim() || "";
    const searchCheck = video_name?.toLowerCase()?.includes(searchValue);
    if (searchValue ? (mainSearchWithInclude ? searchCheck : !searchCheck) : true) {
      return true;
    }
    return false;
  });

  let total_finalRevenue = 0,
    total_finalSpend = 0,
    total_clicks = 0,
    total_conversion = 0,
    total_impressions = 0,
    total_video_3_sec_views = 0,
    total_video_thruplay = 0;

  for (const {
    finalRevenue = 0,
    finalSpend = 0,
    clicks = 0,
    conversion = 0,
    video_3_sec_views = 0,
    impressions = 0,
    video_thruplay = 0,
  } of finalFilterData) {
    total_finalRevenue += parseFloat(finalRevenue || 0);
    total_clicks += parseFloat(clicks || 0);
    total_conversion += parseFloat(conversion || 0);
    total_video_3_sec_views += parseFloat(video_3_sec_views || 0);
    total_impressions += parseFloat(impressions || 0);
    total_video_thruplay += parseFloat(video_thruplay || 0);
    total_finalSpend += parseFloat(finalSpend || 0);
  }

  const total_hookRate =
    (parseFloat(total_video_3_sec_views) / parseFloat(total_impressions)) * 100 || 0;
  const total_holdRate =
    (parseFloat(total_video_thruplay) / parseFloat(total_impressions)) * 100 || 0;
  const total_CTR = (parseFloat(total_clicks) / parseFloat(total_impressions)) * 100 || 0;
  const total_CPM = (parseFloat(total_finalSpend) / parseFloat(total_impressions)) * 1000;
  const total_CPC = parseFloat(total_finalSpend) / parseFloat(total_clicks);
  const total_CVR = parseFloat(total_conversion) / parseFloat(total_clicks);
  const total_PROFIT = parseFloat(total_finalRevenue) - parseFloat(total_finalSpend);
  const total_ROI = parseFloat(total_PROFIT) - parseFloat(total_finalSpend);

  const summaryData = {
    finalRevenue: numberParse(total_finalRevenue, { toFixed: true }),
    finalSpend: numberParse(total_finalSpend, { toFixed: true }),
    CPM: numberParse(total_CPM, { toFixed: true }),
    hookRate: numberParse(total_hookRate, { toFixed: true }),
    holdRate: numberParse(total_holdRate, { toFixed: true }),
    CTR: numberParse(total_CTR, { toFixed: true }),
    CPC: numberParse(total_CPC, { toFixed: true }),
    CVR: numberParse(total_CVR, { toFixed: true }),
    // CPA: numberParse(total_CPA, { toFixed: true }),
    ROI: numberParse(total_ROI, { toFixed: true }),
    clicks: numberParse(total_clicks),
    conversion: numberParse(total_conversion),
  };

  const tableMainProps = {
    loading,
    refetch,
    isDateRange: true,
    exportCSV: true,
    isSearchFilter: true,
    tableProps: {
      initialColumns,
      recordData: finalFilterData,
      summaryData,
    },
    defaultPagination: true,
  };

  const debugContent = [
    {
      isRow: true,
      contentObjects: [{ finalFilterData }],
    },
  ];

  return (
    <>
      <LookTable {...tableMainProps} />
      <DebugPre content={debugContent} />
    </>
  );
}
