export function addAccountGroup(inputArray, adAccountIdName, idName) {
  const result = inputArray.reduce((acc, props) => {
    if (props[adAccountIdName] === undefined || props[idName] === undefined) {
      return acc;
    }
    const existingAccount = acc.find(
      (account) => account[adAccountIdName] === props[adAccountIdName]
    );
    if (existingAccount) {
      existingAccount[idName].push(props[idName]);
    } else {
      acc.push({ [adAccountIdName]: props[adAccountIdName], [idName]: [props[idName]] });
    }
    return acc;
  }, []);

  return result;
}
